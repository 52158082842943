// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#outlet{
    width: 100%;
    padding-left: 120px;
    padding-right: 10px ;
}

@media screen and (max-width: 700px){
    #layout{
        display: none;
    }
    #outlet{
        margin-left: 0px;
    }
}`, "",{"version":3,"sources":["webpack://./src/layouts/layout.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI;QACI,aAAa;IACjB;IACA;QACI,gBAAgB;IACpB;AACJ","sourcesContent":["\n#outlet{\n    width: 100%;\n    padding-left: 120px;\n    padding-right: 10px ;\n}\n\n@media screen and (max-width: 700px){\n    #layout{\n        display: none;\n    }\n    #outlet{\n        margin-left: 0px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
