export const users = {
  AUTH_REQUEST: "@api/AUTH_REQUEST",
  AUTH_SUCCESS: "@api/AUTH_SUCCESS",
  AUTH_FAILURE: "@api/AUTH_FAILURE",

  SELECT_USER: "SELECT_USER",

  UPDATE_BRAND: "@api/UPDATE_BRAND",

  GET_REPORT_COUNTING: "@api/users.GET_REPORT_COUNTING",
  POST_REPORT_COUNTING: "@api/POST_REPORT_COUNTING",

  UPDATE_USER_REQUEST: "@api/UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "@api/UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "@api/UPDATE_USER_FAILURE",

  GRAPH_REQUEST: "@api/GRAPH_REQUEST",
  GRAPH_SUCCESS: "@api/GRAPH_SUCCESS",
  GRAPH_FAILURE: "@api/GRAPH_FAILURE",

  GET_MASSIVE_REPORTS_STATUS_REQUEST: "@api/GET_MASSIVE_REPORTS_STATUS_REQUEST",
  GET_MASSIVE_REPORTS_STATUS_SUCCESS: "@api/GET_MASSIVE_REPORTS_STATUS_SUCCESS",
  GET_MASSIVE_REPORTS_STATUS_FAILURE: "@api/GET_MASSIVE_REPORTS_STATUS_FAILURE",

  MASS_EMAIL_REQUEST: "@api/MASS_EMAIL_REQUEST",
  MASS_EMAIL_SUCCESS: "@api/MASS_EMAIL_SUCCESS",

  XLSX_PORTAL_REQUEST: "@api/XLSX_PORTAL_REQUEST",
  XLSX_PORTAL_SUCCESS: "@api/XLSX_PORTAL_SUCCESS",
  XLSX_PORTAL_FAILURE: "@api/XLSX_PORTAL_FAILURE",

  HELP_CENTER_REQUEST: "@api/HELP_CENTER_REQUEST",
  HELP_CENTER_SUCCESS: "@api/HELP_CENTER_SUCCESS",
  HELP_CENTER_FAILURE: "@api/HELP_CENTER_FAILURE",

  INVOICE_USER_REQUEST: "@api/users.INVOICE_USER_REQUEST",
  INVOICE_USER_SUCCESS: "@api/users.INVOICE_USER_SUCCESS",
  INVOICE_USER_FAILURE: "@api/users.INVOICE_USER_FAILURE",

  POST_REGISTER_REQUEST: "@api/POST_REGISTER_REQUEST",
  POST_REGISTER_SUCCESS: "@api/POST_REGISTER_SUCCESS",
  POST_REGISTER_FAILURE: "@api/POST_REGISTER_FAILURE",

  GET_SHOW_REQUEST: "@api/GET_SHOW_REQUEST",
  GET_SHOW_SUCCESS: "@api/GET_SHOW_SUCCESS",
  GET_SHOW_FAILURE: "@api/GET_SHOW_FAILURE",

  CHECK_BRAND_REQUEST: "@api/CHECK_BRAND_REQUEST",
  CHECK_BRAND_SUCCESS: "@api/CHECK_BRAND_SUCCESS",
  CHECK_BRAND_FAILURE: "@api/CHECK_BRAND_FAILURE",

  GET_USERS_REQUEST: "@api/GET_USERS_REQUEST",
  GET_USERS_SUCCESS: "@api/GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "@api/GET_USERS_FAILURE",

  GET_USER_BRANDS_REQUEST: "@api/GET_USER_BRANDS_REQUEST",
  GET_USER_BRANDS_SUCCESS: "@api/GET_USER_BRANDS_SUCCESS",
  GET_USER_BRANDS_FAILURE: "@api/GET_USER_BRANDS_FAILURE",

  PATH_ALERT_FREQUENCY_REQUEST: "@api/PATH_ALERT_FREQUENCY_REQUEST",
  PATH_ALERT_FREQUENCY_SUCCESS: "@api/PATH_ALERT_FREQUENCY_SUCCESS",
  PATH_ALERT_FREQUENCY_FAILURE: "@api/PATH_ALERT_FREQUENCY_FAILURE",

  GET_ALERT_FREQUENCY_REQUEST: "@api/GET_ALERT_FREQUENCY_REQUEST",
  GET_ALERT_FREQUENCY_SUCCESS: "@api/GET_ALERT_FREQUENCY_SUCCESS",
  GET_ALERT_FREQUENCY_FAILURE: "@api/GET_ALERT_FREQUENCY_FAILURE",

  GET_DASHBOARD_REQUEST: "@api/GET_DASHBOARD_REQUEST",
  GET_DASHBOARD_SUCCESS: "@api/GET_DASHBOARD_SUCCESS",
  GET_DASHBOARD_FAILURE: "@api/GET_DASHBOARD_FAILURE",

  GET_ALL_DEVICES_REQUEST: "@api/GET_ALL_DEVICES_REQUEST",
  GET_ALL_DEVICES_SUCCESS: "@api/GET_ALL_DEVICEs_SUCCESS",
  GET_ALL_DEVICES_FAILURE: "@api/GET_ALL_DEVICES_FAILURE",

  GET_CAPACITY_REQUEST: "@api/GET_CAPACITY_REQUEST",
  GET_CAPACITY_SUCCESS: "@api/GET_CAPACITY_SUCCESS",
  GET_CAPACITY_FAILURE: "@api/GET_CAPACITY_FAILURE",

  GET_INVOICE_VALUES_REQUEST: "@api/GET_INVOICE_VALUES_REQUEST",
  GET_INVOICE_VALUES_SUCCESS: "@api/GET_INVOICE_VALUES_SUCCESS",
  GET_INVOICE_VALUES_FAILURE: "@api/GET_INVOICE_VALUES_FAILURE",

  SEND_EMAIL_REQUEST: "@api/SEND_EMAIL_REQUEST",
  SEND_EMAIL_SUCCESS: "@api/SEND_EMAIL_SUCCESS",
  SEND_EMAIL_FAILURE: "@api/SEND_EMAIL_FAILURE",

  RECOVER_PASSWORD_REQUEST: "@api/RECOVER_PASSWORD_REQUEST",
  RECOVER_PASSWORD_SUCCESS: "@api/RECOVER_PASSWORD_SUCCESS",
  RECOVER_PASSWORD_FAILURE: "@api/RECOVER_PASSWORD_FAILURE",

  CANCEL_PLAN_REQUEST: "@api/CANCEL_PLAN_REQUEST",
  CANCEL_PLAN_SUCCESS: "@api/CANCEL_PLAN_SUCCESS",
  CANCEL_PLAN_FAILURE: "@api/CANCEL_PLAN_FAILURE",

  UPDATE_USER_EMAIL_REQUEST: "@api/UPDATE_USER_EMAIL_REQUEST",
  UPDATE_USER_EMAIL_SUCCESS: "@api/UPDATE_USER_EMAIL_SUCCESS",
  UPDATE_USER_EMAIL_FAILURE: "@api/UPDATE_USER_EMAIL_FAILURE",

  GET_ALL_DEVICES_FROM_USER_REQUEST: "api/GET_ALL_DEVICES_FROM_USER_REQUEST",
  GET_ALL_DEVICES_FROM_USER_SUCCESS: "api/GET_ALL_DEVICES_FROM_USER_SUCCESS",
  GET_ALL_DEVICES_FROM_USER_FAILURE: "api/GET_ALL_DEVICES_FROM_USER_FAILURE",

  GET_ALL_DELETED_DEVICES_REQUEST: "@api/GET_ALL_DELETED_DEVICES_REQUEST",
  GET_ALL_DELETED_DEVICES_SUCCESS: "@api/GET_ALL_DELETED_DEVICES_SUCCESS",
  GET_ALL_DELETED_DEVICES_FAILURE: "@api/GET_ALL_DELETED_DEVICES_FAILURE",

  POST_USE_DATE_REPORT_SUCCESS: "@api/POST_USE_DATE_REPORT_SUCCESS",

  UPDATE_EMAIL_CAPACITY_DEVICE_REQUEST:
    "@api/UPDATE_EMAIL_CAPACITY_DEVICE_REQUEST",
  UPDATE_EMAIL_CAPACITY_DEVICE_SUCCESS:
    "@api/UPDATE_EMAIL_CAPACITY_DEVICE_SUCCESS",
  UPDATE_EMAIL_CAPACITY_DEVICE_FAILURE:
    "@api/UPDATE_EMAIL_CAPACITY_DEVICE_FAILURE",

  DELETE_USER_REQUEST: "@api/DELETE_USER_REQUEST",
  DELETE_USER_SUCCESS: "@api/DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE: "@api/DELETE_USER_FAILURE",

  GET_BRAND_INFO: "@api/GET_BRAND_INFO",

  GET_USER_LOGO: "@api/GET_USER_LOGO",

  UPDATE_LOGO: "@api/UPDATE_LOGO",

  MASS_EMAIL_AMOUNT_PERCENTAGE: "@api/MASS_EMAIL_AMOUNT_PERCENTAGE",
};

export const investment = {
  GET_INVESTMENT_REQUEST: "@api/GET_INVESTMENT_REQUEST",
  GET_INVESTMENT_SUCCESS: "@api/GET_INVESTMENT_SUCCESS",
  GET_INVESTMENT_FAILURE: "@api/GET_INVESTMENT_FAILURE",
};

export const generation = {
  GET_GENERATION_REQUEST: "@api/GET_GENERATION_REQUEST",
  GET_GENERATION_SUCCESS: "@api/GET_GENERATION_SUCCESS",
  GET_GENERATION_FAILURE: "@api/GET_GENERATION_FAILURE",

  GET_GENERATION_ALERTS_REQUEST: "@api/GET_GENERATION_ALERTS_REQUEST",
  GET_GENERATION_ALERTS_SUCCESS: "@api/GET_GENERATION_ALERTS_SUCCESS",
  GET_GENERATION_ALERTS_FAILURE: "@api/GET_GENERATION_ALERTS_FAILURE",

  SEND_EMAIL_TO_DEVICE_REQUEST: "@api/SEND_EMAIL_TO_DEVICE_REQUEST",
  SEND_EMAIL_TO_DEVICE_SUCCESS: "@api/SEND_EMAIL_TO_DEVICE_SUCCESS",
  SEND_EMAIL_TO_DEVICE_FAILURE: "@api/SEND_EMAIL_TO_DEVICE_FAILURE",

  UPDATE_EMAIL_REQUEST: "@api/UPDATE_EMAIL_REQUEST",
  UPDATE_EMAIL_SUCCESS: "@api/UPDATE_EMAIL_SUCCESS",
  UPDATE_EMAIL_FAILURE: "@api/UPDATE_EMAIL_FAILURE",

  GET_GENERAL_REPORT_REQUEST: "@api/GET_GENERAL_REPORT_REQUEST",
  GET_GENERAL_REPORT_SUCCESS: "@api/GET_GENERAL_REPORT_SUCCESS",
  GET_GENERAL_REPORT_FAILURE: "@api/GET_GENERAL_REPORT_FAILURE",
};

export const devices = {
  DEVICE_RECOVER_REQUEST: "@api/DEVICE_RECOVER_REQUEST",
  DEVICE_RECOVER_SUCCESS: "@api/DEVICE_RECOVER_SUCCESS",
  DEVICE_RECOVER_FAILURE: "@api/DEVICE_RECOVER_FAILURE",

  GET_CLIENT_REPORT_DATA_REQUEST: "@api/GET_CLIENT_REPORT_DATA_REQUEST",
  GET_CLIENT_REPORT_DATA_SUCCESS: "@api/GET_CLIENT_REPORT_DATA_SUCCESS",
  GET_CLIENT_REPORT_DATA_FAILURE: "@api/GET_CLIENT_REPORT_DATA_FAILURE",

  GET_DEVICES_REQUEST: "@api/GET_DEVICES_REQUEST",
  GET_DEVICES_SUCCESS: "@api/GET_DEVICES_SUCCESS",
  GET_DEVICES_FAILURE: "@api/GET_DEVICES_FAILURE",

  GET_ALL_DEVICES_REQUEST: "@api/GET_ALL_DEVICES_REQUEST",
  GET_ALL_DEVICES_SUCCESS: "@api/GET_ALL_DEVICES_SUCCESS",
  GET_ALL_DEVICES_FAILURE: "@api/GET_ALL_DEVICES_FAILURE",

  GET_CAPACITY_DEVICE_REQUEST: "@api/GET_CAPACITY_DEVICE_REQUEST",
  GET_CAPACITY_DEVICE_SUCCESS: "@api/GET_CAPACITY_DEVICE_SUCCESS",
  GET_CAPACITY_DEVICE_FAILURE: "@api/GET_CAPACITY_DEVICE_FAILURE",

  GET_DEVICES_ALERTS_REQUEST: "@api/GET_DEVICES_ALERTS_REQUEST",
  GET_DEVICES_ALERTS_SUCCESS: "@api/GET_DEVICES_ALERTS_SUCCESS",
  GET_DEVICES_ALERTS_FAILURE: "@api/GET_DEVICES_ALERTS_FAILURE",

  GET_ALL_DEVICES_GENERATION_REQUEST: "@api/GET_ALL_DEVICES_GENERATION_REQUEST",
  GET_ALL_DEVICES_GENERATION_SUCCESS: "@api/GET_ALL_DEVICES_GENERATION_SUCCESS",
  GET_ALL_DEVICES_GENERATION_FAILURE: "@api/GET_ALL_DEVICES_GENERATION_FAILURE",

  POST_DEVICE_REQUEST: "@api/POST_DEVICE_REQUEST",
  POST_DEVICE_SUCCESS: "@api/POST_DEVICE_SUCCESS",
  POST_DEVICE_FAILURE: "@api/POST_DEVICE_FAILURE",

  DELETE_DEVICE_REQUEST: "@api/DELETE_DEVICE_REQUEST",
  DELETE_DEVICE_SUCCESS: "@api/DELETE_DEVICE_SUCCESS",
  DELETE_DEVICE_FAILURE: "@api/DELETE_DEVICE_FAILURE",

  GET_BIG_NUMBER_REQUEST: "@api/GET_BIG_NUMBER_REQUEST",
  GET_BIG_NUMBER_SUCCESS: "@api/GET_BIG_NUMBER_SUCCESS",
  GET_BIG_NUMBER_FAILURE: "@api/GET_BIG_NUMBER_FAILURE",

  GET_GENREALDAYLASTHOUR_REQUEST: "@api/GET_GENREALDAYLASTHOUR_REQUEST",
  GET_GENREALDAYLASTHOUR_SUCCESS: "@api/GET_GENREALDAYLASTHOUR_SUCCESS",
  GET_GENREALDAYLASTHOUR_FAILURE: "@api/GET_GENREALDAYLASTHOUR_FAILURE",

  GET_GENREALDAYDEVICELASTHOUR_REQUEST:
    "@api/GET_GENREALDAYDEVICELASTHOUR_REQUEST",

  GET_GENREALDAYDEVICELASTHOUR_SUCCESS:
    "@api/GET_GENREALDAYDEVICELASTHOUR_SUCCESS",

  GET_GENREALDAYDEVICELASTHOUR_FAILURE:
    "@api/GET_GENREALDAYDEVICELASTHOUR_FAILURE",
};

export const toast = {
  TOAST_SUCCESS: "@system/TOAST_SUCCESS",
  TOAST_FAILURE: "@system/TOAST_FAILURE",
};
