// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
@media screen and (max-width: 1000px){
    #labelingModals{
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/sidebar/side.css"],"names":[],"mappings":";AACA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":["\n@media screen and (max-width: 1000px){\n    #labelingModals{\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
