// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#index{
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100%;
    height: 100%;
    padding: 10px 0px 10px 0px;
    gap: 20px;
}

#list-usins{
    margin-top: 80px;
    width: 100% 
}

#modal {
    display: none;
}

@media screen and (max-width: 700px){
    #index{
        width: 100%;  
  
    }
    #list-usins{
        margin-top: 90vh;
        width: 100% 
    }
    
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/index.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;IACtB,cAAc;IACd,WAAW;IACX,YAAY;IACZ,0BAA0B;IAC1B,SAAS;AACb;;AAEA;IACI,gBAAgB;IAChB;AACJ;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,WAAW;;IAEf;IACA;QACI,gBAAgB;QAChB;IACJ;;AAEJ","sourcesContent":["\n#index{\n    display: flex;\n    flex-direction: column;\n    overflow: auto;\n    width: 100%;\n    height: 100%;\n    padding: 10px 0px 10px 0px;\n    gap: 20px;\n}\n\n#list-usins{\n    margin-top: 80px;\n    width: 100% \n}\n\n#modal {\n    display: none;\n}\n\n@media screen and (max-width: 700px){\n    #index{\n        width: 100%;  \n  \n    }\n    #list-usins{\n        margin-top: 90vh;\n        width: 100% \n    }\n    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
