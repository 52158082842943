const citiesData = 
        [
          {
            ic_uuid: "bfa1e512-a5e9-4df0-a584-63fe03f43c41",
            ic_lat: -110109,
            ic_lon: -687463,
            ic_city: "Brasiléia",
            ic_states: "ACRE",
            ic_january: 4.655,
            ic_february: 4.653,
            ic_march: 4.268,
            ic_april: 4.566,
            ic_may: 3.945,
            ic_june: 4.079,
            ic_july: 4.285,
            ic_august: 4.858,
            ic_september: 5.102,
            ic_october: 5.105,
            ic_november: 5.157,
            ic_december: 4.77,
            ic_yearly: 4.62,
          },
          {
            ic_uuid: "8e6ac8be-d0b7-460e-9e45-f3add3841b07",
            ic_lat: -167621,
            ic_lon: -494384,
            ic_city: "Abadia de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.511,
            ic_february: 5.57,
            ic_march: 5.239,
            ic_april: 5.111,
            ic_may: 4.812,
            ic_june: 4.564,
            ic_july: 4.712,
            ic_august: 5.583,
            ic_september: 5.496,
            ic_october: 5.551,
            ic_november: 5.422,
            ic_december: 5.507,
            ic_yearly: 5.256,
          },
          {
            ic_uuid: "e5a3ce56-dd01-45a8-9a7d-583ace6a407c",
            ic_lat: -184833,
            ic_lon: -473925,
            ic_city: "Abadia dos Dourados",
            ic_states: "MINAS GERAIS",
            ic_january: 5.631,
            ic_february: 6.006,
            ic_march: 5.178,
            ic_april: 5.077,
            ic_may: 4.69,
            ic_june: 4.412,
            ic_july: 4.733,
            ic_august: 5.666,
            ic_september: 5.65,
            ic_october: 5.722,
            ic_november: 5.489,
            ic_december: 5.808,
            ic_yearly: 5.339,
          },
          {
            ic_uuid: "c6162170-951f-41c4-9cec-45a422f8877d",
            ic_lat: -161974,
            ic_lon: -487061,
            ic_city: "Abadiânia",
            ic_states: "GOIÁS",
            ic_january: 5.411,
            ic_february: 5.599,
            ic_march: 5.127,
            ic_april: 5.09,
            ic_may: 4.876,
            ic_june: 4.657,
            ic_july: 4.922,
            ic_august: 5.789,
            ic_september: 5.612,
            ic_october: 5.55,
            ic_november: 5.127,
            ic_december: 5.416,
            ic_yearly: 5.265,
          },
          {
            ic_uuid: "2a485b7b-216b-415c-80af-b366f89fe145",
            ic_lat: -191522,
            ic_lon: -454557,
            ic_city: "Abaeté",
            ic_states: "MINAS GERAIS",
            ic_january: 5.975,
            ic_february: 6.166,
            ic_march: 5.256,
            ic_april: 5.056,
            ic_may: 4.581,
            ic_june: 4.372,
            ic_july: 4.602,
            ic_august: 5.517,
            ic_september: 5.705,
            ic_october: 5.723,
            ic_november: 5.399,
            ic_december: 5.778,
            ic_yearly: 5.344,
          },
          {
            ic_uuid: "8a4dcb4c-b0dd-4d9e-be68-ed6015c2e93a",
            ic_lat: -17222,
            ic_lon: -488792,
            ic_city: "Abaetetuba",
            ic_states: "PARÁ",
            ic_january: 4.558,
            ic_february: 4.439,
            ic_march: 4.535,
            ic_april: 4.542,
            ic_may: 4.744,
            ic_june: 4.939,
            ic_july: 5.079,
            ic_august: 5.353,
            ic_september: 5.412,
            ic_october: 5.288,
            ic_november: 5.098,
            ic_december: 4.765,
            ic_yearly: 4.896,
          },
          {
            ic_uuid: "33a90574-ef7f-47cd-9e8a-1e89cd745ed8",
            ic_lat: -73609,
            ic_lon: -390485,
            ic_city: "Abaiara",
            ic_states: "CEARÁ",
            ic_january: 6.031,
            ic_february: 5.945,
            ic_march: 5.895,
            ic_april: 5.645,
            ic_may: 5.176,
            ic_june: 4.924,
            ic_july: 5.227,
            ic_august: 5.963,
            ic_september: 6.568,
            ic_october: 6.561,
            ic_november: 6.619,
            ic_december: 6.281,
            ic_yearly: 5.903,
          },
          {
            ic_uuid: "0160e21e-5055-47d6-82dd-e9031cde6cf3",
            ic_lat: -132507,
            ic_lon: -416647,
            ic_city: "Abaíra",
            ic_states: "BAHIA",
            ic_january: 6.099,
            ic_february: 6.129,
            ic_march: 5.593,
            ic_april: 4.828,
            ic_may: 4.271,
            ic_june: 3.996,
            ic_july: 4.226,
            ic_august: 4.956,
            ic_september: 5.795,
            ic_october: 5.904,
            ic_november: 5.694,
            ic_december: 6.291,
            ic_yearly: 5.315,
          },
          {
            ic_uuid: "880f4e85-beaf-49fd-8acd-c1b50ceb5249",
            ic_lat: -87238,
            ic_lon: -391146,
            ic_city: "Abaré",
            ic_states: "BAHIA",
            ic_january: 6.467,
            ic_february: 6.203,
            ic_march: 6.264,
            ic_april: 5.619,
            ic_may: 4.863,
            ic_june: 4.464,
            ic_july: 4.648,
            ic_august: 5.388,
            ic_september: 6.188,
            ic_october: 6.467,
            ic_november: 6.683,
            ic_december: 6.617,
            ic_yearly: 5.823,
          },
          {
            ic_uuid: "61849d07-9454-45f7-b64d-4878a91be7bd",
            ic_lat: -23304,
            ic_lon: -503129,
            ic_city: "Abatiá",
            ic_states: "PARANÁ",
            ic_january: 5.863,
            ic_february: 5.848,
            ic_march: 5.223,
            ic_april: 4.625,
            ic_may: 3.701,
            ic_june: 3.382,
            ic_july: 3.586,
            ic_august: 4.575,
            ic_september: 4.722,
            ic_october: 5.369,
            ic_november: 5.981,
            ic_december: 6.364,
            ic_yearly: 4.937,
          },
          {
            ic_uuid: "af121f77-b043-41e0-8915-c87b09698aa9",
            ic_lat: -276131,
            ic_lon: -510237,
            ic_city: "Abdon Batista",
            ic_states: "SANTA CATARINA",
            ic_january: 6.017,
            ic_february: 5.544,
            ic_march: 4.862,
            ic_april: 3.946,
            ic_may: 2.944,
            ic_june: 2.558,
            ic_july: 2.83,
            ic_august: 3.663,
            ic_september: 3.871,
            ic_october: 4.789,
            ic_november: 5.993,
            ic_december: 6.224,
            ic_yearly: 4.437,
          },
          {
            ic_uuid: "c3aaeed9-7695-447f-b2f9-22d2286f1ec7",
            ic_lat: -49537,
            ic_lon: -483937,
            ic_city: "Abel Figueiredo",
            ic_states: "PARÁ",
            ic_january: 4.439,
            ic_february: 4.576,
            ic_march: 4.643,
            ic_april: 4.636,
            ic_may: 4.679,
            ic_june: 5.043,
            ic_july: 5.2,
            ic_august: 5.547,
            ic_september: 5.332,
            ic_october: 4.937,
            ic_november: 4.765,
            ic_december: 4.59,
            ic_yearly: 4.866,
          },
          {
            ic_uuid: "b12a9e2f-6fa8-4688-9fe9-c14851706edc",
            ic_lat: -265721,
            ic_lon: -523233,
            ic_city: "Abelardo Luz",
            ic_states: "SANTA CATARINA",
            ic_january: 6.152,
            ic_february: 5.612,
            ic_march: 5.002,
            ic_april: 4.126,
            ic_may: 3.223,
            ic_june: 2.761,
            ic_july: 3.033,
            ic_august: 3.935,
            ic_september: 4.129,
            ic_october: 5.091,
            ic_november: 6.058,
            ic_december: 6.295,
            ic_yearly: 4.618,
          },
          {
            ic_uuid: "3407893c-74fe-4492-9950-508678556bf6",
            ic_lat: -203001,
            ic_lon: -424747,
            ic_city: "Abre Campo",
            ic_states: "MINAS GERAIS",
            ic_january: 5.785,
            ic_february: 6.035,
            ic_march: 5.068,
            ic_april: 4.505,
            ic_may: 3.871,
            ic_june: 3.672,
            ic_july: 3.824,
            ic_august: 4.599,
            ic_september: 4.925,
            ic_october: 5.084,
            ic_november: 4.883,
            ic_december: 5.658,
            ic_yearly: 4.826,
          },
          {
            ic_uuid: "d4080f71-1e18-4a24-8017-e333dd44a034",
            ic_lat: -79038,
            ic_lon: -349073,
            ic_city: "Abreu e Lima",
            ic_states: "PERNAMBUCO",
            ic_january: 5.716,
            ic_february: 5.881,
            ic_march: 5.834,
            ic_april: 5.136,
            ic_may: 4.471,
            ic_june: 4.161,
            ic_july: 4.269,
            ic_august: 4.998,
            ic_september: 5.406,
            ic_october: 5.686,
            ic_november: 5.985,
            ic_december: 5.966,
            ic_yearly: 5.292,
          },
          {
            ic_uuid: "59b1a3b3-ef56-486e-bb08-cc13a111ae71",
            ic_lat: -96222,
            ic_lon: -491565,
            ic_city: "Abreulândia",
            ic_states: "TOCANTINS",
            ic_january: 4.928,
            ic_february: 4.933,
            ic_march: 4.741,
            ic_april: 4.746,
            ic_may: 4.954,
            ic_june: 5.076,
            ic_july: 5.352,
            ic_august: 5.983,
            ic_september: 5.606,
            ic_october: 5.169,
            ic_november: 5.068,
            ic_december: 4.947,
            ic_yearly: 5.125,
          },
          {
            ic_uuid: "0b79c948-7be7-4484-bf52-ece424555405",
            ic_lat: -203595,
            ic_lon: -431442,
            ic_city: "Acaiaca",
            ic_states: "MINAS GERAIS",
            ic_january: 5.611,
            ic_february: 5.922,
            ic_march: 5.0,
            ic_april: 4.459,
            ic_may: 3.831,
            ic_june: 3.625,
            ic_july: 3.827,
            ic_august: 4.674,
            ic_september: 4.893,
            ic_october: 4.973,
            ic_november: 4.777,
            ic_december: 5.448,
            ic_yearly: 4.753,
          },
          {
            ic_uuid: "a6c93dc1-c9eb-4a1c-a1da-600a818ef20f",
            ic_lat: -49475,
            ic_lon: -475008,
            ic_city: "Açailândia",
            ic_states: "MARANHÃO",
            ic_january: 4.437,
            ic_february: 4.639,
            ic_march: 4.669,
            ic_april: 4.759,
            ic_may: 4.664,
            ic_june: 4.964,
            ic_july: 5.017,
            ic_august: 5.397,
            ic_september: 5.321,
            ic_october: 4.929,
            ic_november: 4.712,
            ic_december: 4.595,
            ic_yearly: 4.842,
          },
          {
            ic_uuid: "ec6bb3d3-bae3-4a07-a9a0-ed241f45ad27",
            ic_lat: -11658,
            ic_lon: -380201,
            ic_city: "Acajutiba",
            ic_states: "BAHIA",
            ic_january: 6.105,
            ic_february: 5.883,
            ic_march: 5.729,
            ic_april: 4.806,
            ic_may: 4.071,
            ic_june: 3.87,
            ic_july: 4.039,
            ic_august: 4.424,
            ic_september: 5.279,
            ic_october: 5.624,
            ic_november: 6.002,
            ic_december: 6.22,
            ic_yearly: 5.171,
          },
          {
            ic_uuid: "f5700c3f-9360-4c43-8d62-af1bfe7d93ae",
            ic_lat: -19597,
            ic_lon: -482001,
            ic_city: "Acará",
            ic_states: "PARÁ",
            ic_january: 4.635,
            ic_february: 4.539,
            ic_march: 4.602,
            ic_april: 4.596,
            ic_may: 4.734,
            ic_june: 4.916,
            ic_july: 5.071,
            ic_august: 5.358,
            ic_september: 5.434,
            ic_october: 5.257,
            ic_november: 4.991,
            ic_december: 4.772,
            ic_yearly: 4.909,
          },
          {
            ic_uuid: "a3a31659-0bb1-4741-8487-7ab5462024a8",
            ic_lat: -42212,
            ic_lon: -387058,
            ic_city: "Acarape",
            ic_states: "CEARÁ",
            ic_january: 5.127,
            ic_february: 5.185,
            ic_march: 5.124,
            ic_april: 4.695,
            ic_may: 4.962,
            ic_june: 4.871,
            ic_july: 5.321,
            ic_august: 6.002,
            ic_september: 6.264,
            ic_october: 6.066,
            ic_november: 5.955,
            ic_december: 5.346,
            ic_yearly: 5.41,
          },
          {
            ic_uuid: "106322ed-86d4-4731-b161-a040c6e038cf",
            ic_lat: -28881,
            ic_lon: -401186,
            ic_city: "Acaraú",
            ic_states: "CEARÁ",
            ic_january: 5.494,
            ic_february: 5.4,
            ic_march: 5.15,
            ic_april: 4.576,
            ic_may: 5.12,
            ic_june: 5.221,
            ic_july: 5.48,
            ic_august: 5.988,
            ic_september: 6.139,
            ic_october: 6.073,
            ic_november: 6.331,
            ic_december: 5.954,
            ic_yearly: 5.577,
          },
          {
            ic_uuid: "fba59bd7-71f9-44eb-9fff-9debee6b5c9b",
            ic_lat: -64353,
            ic_lon: -366409,
            ic_city: "Acari",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.011,
            ic_february: 5.976,
            ic_march: 5.986,
            ic_april: 5.604,
            ic_may: 5.033,
            ic_june: 4.644,
            ic_july: 4.88,
            ic_august: 5.626,
            ic_september: 6.244,
            ic_october: 6.502,
            ic_november: 6.51,
            ic_december: 6.114,
            ic_yearly: 5.761,
          },
          {
            ic_uuid: "263d3016-f1de-49c9-be19-faf11416c8df",
            ic_lat: -82176,
            ic_lon: -410821,
            ic_city: "Acauã",
            ic_states: "PIAUÍ",
            ic_january: 6.014,
            ic_february: 5.767,
            ic_march: 5.768,
            ic_april: 5.39,
            ic_may: 5.082,
            ic_june: 4.995,
            ic_july: 5.35,
            ic_august: 6.164,
            ic_september: 6.601,
            ic_october: 6.628,
            ic_november: 6.469,
            ic_december: 6.199,
            ic_yearly: 5.869,
          },
          {
            ic_uuid: "e418f769-088e-49c6-805f-2247c1291273",
            ic_lat: -31867,
            ic_lon: -541619,
            ic_city: "Aceguá",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.568,
            ic_february: 5.883,
            ic_march: 5.027,
            ic_april: 3.75,
            ic_may: 2.671,
            ic_june: 2.261,
            ic_july: 2.486,
            ic_august: 3.112,
            ic_september: 3.826,
            ic_october: 5.176,
            ic_november: 6.454,
            ic_december: 7.008,
            ic_yearly: 4.518,
          },
          {
            ic_uuid: "48aafdea-cef5-47c4-a34c-5a04cb8ffbc2",
            ic_lat: -60895,
            ic_lon: -394484,
            ic_city: "Acopiara",
            ic_states: "CEARÁ",
            ic_january: 5.52,
            ic_february: 5.652,
            ic_march: 5.586,
            ic_april: 5.248,
            ic_may: 5.041,
            ic_june: 5.003,
            ic_july: 5.352,
            ic_august: 6.141,
            ic_september: 6.527,
            ic_october: 6.458,
            ic_november: 6.328,
            ic_december: 5.792,
            ic_yearly: 5.721,
          },
          {
            ic_uuid: "04c069c4-633c-48d5-9c17-564d22635a3c",
            ic_lat: -151945,
            ic_lon: -563637,
            ic_city: "Acorizal",
            ic_states: "MATO GROSSO",
            ic_january: 5.431,
            ic_february: 5.372,
            ic_march: 5.17,
            ic_april: 4.956,
            ic_may: 4.477,
            ic_june: 4.461,
            ic_july: 4.598,
            ic_august: 5.435,
            ic_september: 5.119,
            ic_october: 5.305,
            ic_november: 5.61,
            ic_december: 5.72,
            ic_yearly: 5.138,
          },
          {
            ic_uuid: "3ecf64bb-18f0-4b21-b3eb-864f90cadc97",
            ic_lat: -100755,
            ic_lon: -670531,
            ic_city: "Acrelândia",
            ic_states: "ACRE",
            ic_january: 4.521,
            ic_february: 4.522,
            ic_march: 4.247,
            ic_april: 4.371,
            ic_may: 3.96,
            ic_june: 4.084,
            ic_july: 4.284,
            ic_august: 4.807,
            ic_september: 4.915,
            ic_october: 5.008,
            ic_november: 4.885,
            ic_december: 4.574,
            ic_yearly: 4.515,
          },
          {
            ic_uuid: "8b3bcb47-360b-4af8-b0eb-593e4c6078c0",
            ic_lat: -173964,
            ic_lon: -503753,
            ic_city: "Acreúna",
            ic_states: "GOIÁS",
            ic_january: 5.658,
            ic_february: 5.77,
            ic_march: 5.245,
            ic_april: 5.085,
            ic_may: 4.762,
            ic_june: 4.385,
            ic_july: 4.685,
            ic_august: 5.551,
            ic_september: 5.359,
            ic_october: 5.471,
            ic_november: 5.531,
            ic_december: 5.704,
            ic_yearly: 5.267,
          },
          {
            ic_uuid: "f17da0e7-cbff-4e53-ab46-7c4f2c09e6c2",
            ic_lat: -55784,
            ic_lon: -369115,
            ic_city: "Açu",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.937,
            ic_february: 6.055,
            ic_march: 5.998,
            ic_april: 5.76,
            ic_may: 5.369,
            ic_june: 4.977,
            ic_july: 5.27,
            ic_august: 5.935,
            ic_september: 6.344,
            ic_october: 6.501,
            ic_november: 6.512,
            ic_december: 6.029,
            ic_yearly: 5.89,
          },
          {
            ic_uuid: "1aa6aae8-8c7d-4929-bf34-9c1932469b49",
            ic_lat: -190673,
            ic_lon: -425495,
            ic_city: "Açucena",
            ic_states: "MINAS GERAIS",
            ic_january: 5.686,
            ic_february: 5.916,
            ic_march: 4.958,
            ic_april: 4.499,
            ic_may: 3.795,
            ic_june: 3.644,
            ic_july: 3.787,
            ic_august: 4.439,
            ic_september: 4.87,
            ic_october: 4.997,
            ic_november: 4.66,
            ic_december: 5.481,
            ic_yearly: 4.728,
          },
          {
            ic_uuid: "f10b6eb1-fa7c-4507-bd9a-15436f587971",
            ic_lat: -216825,
            ic_lon: -510741,
            ic_city: "Adamantina",
            ic_states: "SÃO PAULO",
            ic_january: 5.954,
            ic_february: 5.944,
            ic_march: 5.48,
            ic_april: 4.872,
            ic_may: 4.004,
            ic_june: 3.673,
            ic_july: 3.88,
            ic_august: 4.829,
            ic_september: 4.891,
            ic_october: 5.567,
            ic_november: 6.06,
            ic_december: 6.352,
            ic_yearly: 5.125,
          },
          {
            ic_uuid: "0a2d327f-0a73-4408-a5b6-7907c56ab61d",
            ic_lat: -164165,
            ic_lon: -501683,
            ic_city: "Adelândia",
            ic_states: "GOIÁS",
            ic_january: 5.507,
            ic_february: 5.587,
            ic_march: 5.213,
            ic_april: 5.134,
            ic_may: 4.827,
            ic_june: 4.599,
            ic_july: 4.794,
            ic_august: 5.637,
            ic_september: 5.436,
            ic_october: 5.558,
            ic_november: 5.429,
            ic_december: 5.55,
            ic_yearly: 5.273,
          },
          {
            ic_uuid: "16559594-f977-4c55-bec6-2d2578b4233a",
            ic_lat: -21233,
            ic_lon: -496455,
            ic_city: "Adolfo",
            ic_states: "SÃO PAULO",
            ic_january: 5.907,
            ic_february: 5.925,
            ic_march: 5.297,
            ic_april: 4.829,
            ic_may: 3.997,
            ic_june: 3.766,
            ic_july: 3.987,
            ic_august: 4.86,
            ic_september: 5.001,
            ic_october: 5.632,
            ic_november: 5.967,
            ic_december: 6.215,
            ic_yearly: 5.115,
          },
          {
            ic_uuid: "3cea1ef0-8b00-40a0-8c5b-7492726f28eb",
            ic_lat: -246611,
            ic_lon: -489926,
            ic_city: "Adrianópolis",
            ic_states: "PARANÁ",
            ic_january: 5.429,
            ic_february: 5.338,
            ic_march: 4.678,
            ic_april: 3.9,
            ic_may: 3.094,
            ic_june: 2.672,
            ic_july: 2.762,
            ic_august: 3.69,
            ic_september: 3.849,
            ic_october: 4.415,
            ic_november: 5.233,
            ic_december: 5.687,
            ic_yearly: 4.229,
          },
          {
            ic_uuid: "bbdd6a7c-6ea0-4adb-9a68-a2861e54ae94",
            ic_lat: -105357,
            ic_lon: -381158,
            ic_city: "Adustina",
            ic_states: "BAHIA",
            ic_january: 6.114,
            ic_february: 5.845,
            ic_march: 5.74,
            ic_april: 5.065,
            ic_may: 4.186,
            ic_june: 3.881,
            ic_july: 3.979,
            ic_august: 4.486,
            ic_september: 5.338,
            ic_october: 5.629,
            ic_november: 6.119,
            ic_december: 6.172,
            ic_yearly: 5.213,
          },
          {
            ic_uuid: "7a224e2e-43d8-4c43-a17c-96fb3b883b7c",
            ic_lat: -77512,
            ic_lon: -376367,
            ic_city: "Afogados da Ingazeira",
            ic_states: "PERNAMBUCO",
            ic_january: 6.198,
            ic_february: 6.15,
            ic_march: 6.076,
            ic_april: 5.703,
            ic_may: 4.966,
            ic_june: 4.54,
            ic_july: 4.676,
            ic_august: 5.511,
            ic_september: 6.359,
            ic_october: 6.498,
            ic_november: 6.717,
            ic_december: 6.388,
            ic_yearly: 5.815,
          },
          {
            ic_uuid: "d413abac-90ef-4655-a4e4-ca0f89ba5da4",
            ic_lat: -54927,
            ic_lon: -365078,
            ic_city: "Afonso Bezerra",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.752,
            ic_february: 5.874,
            ic_march: 5.773,
            ic_april: 5.514,
            ic_may: 5.162,
            ic_june: 4.777,
            ic_july: 5.039,
            ic_august: 5.745,
            ic_september: 6.127,
            ic_october: 6.352,
            ic_november: 6.384,
            ic_december: 5.873,
            ic_yearly: 5.698,
          },
          {
            ic_uuid: "6f1307df-dd83-40af-b56d-ef0ef1da65bb",
            ic_lat: -200783,
            ic_lon: -411264,
            ic_city: "Afonso Cláudio",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.881,
            ic_february: 6.134,
            ic_march: 5.07,
            ic_april: 4.566,
            ic_may: 3.827,
            ic_june: 3.591,
            ic_july: 3.683,
            ic_august: 4.389,
            ic_september: 4.721,
            ic_october: 4.872,
            ic_november: 4.786,
            ic_december: 5.515,
            ic_yearly: 4.753,
          },
          {
            ic_uuid: "07853a57-057e-4abf-9213-0f4932682607",
            ic_lat: -41319,
            ic_lon: -433252,
            ic_city: "Afonso Cunha",
            ic_states: "MARANHÃO",
            ic_january: 5.064,
            ic_february: 5.231,
            ic_march: 5.109,
            ic_april: 5.086,
            ic_may: 5.0,
            ic_june: 5.22,
            ic_july: 5.404,
            ic_august: 5.993,
            ic_september: 6.378,
            ic_october: 6.3,
            ic_november: 5.95,
            ic_december: 5.485,
            ic_yearly: 5.519,
          },
          {
            ic_uuid: "fcd8a6f9-e7ff-4895-8a79-7c706c2f509b",
            ic_lat: -85118,
            ic_lon: -410099,
            ic_city: "Afrânio",
            ic_states: "PERNAMBUCO",
            ic_january: 6.134,
            ic_february: 5.822,
            ic_march: 5.769,
            ic_april: 5.266,
            ic_may: 5.022,
            ic_june: 4.894,
            ic_july: 5.254,
            ic_august: 6.117,
            ic_september: 6.635,
            ic_october: 6.604,
            ic_november: 6.453,
            ic_december: 6.166,
            ic_yearly: 5.845,
          },
          {
            ic_uuid: "4484a453-1fd0-48c3-902f-a2ce851f5442",
            ic_lat: -1552,
            ic_lon: -503865,
            ic_city: "Afuá",
            ic_states: "PARÁ",
            ic_january: 4.388,
            ic_february: 4.061,
            ic_march: 3.959,
            ic_april: 3.992,
            ic_may: 4.425,
            ic_june: 4.681,
            ic_july: 4.841,
            ic_august: 5.247,
            ic_september: 5.497,
            ic_october: 5.397,
            ic_november: 5.226,
            ic_december: 4.698,
            ic_yearly: 4.701,
          },
          {
            ic_uuid: "8200c053-0c1e-4b9a-bec0-e1a991abc8bc",
            ic_lat: -84601,
            ic_lon: -35945,
            ic_city: "Agrestina",
            ic_states: "PERNAMBUCO",
            ic_january: 5.956,
            ic_february: 5.851,
            ic_march: 5.955,
            ic_april: 5.234,
            ic_may: 4.367,
            ic_june: 3.969,
            ic_july: 4.082,
            ic_august: 4.727,
            ic_september: 5.494,
            ic_october: 5.796,
            ic_november: 6.189,
            ic_december: 6.064,
            ic_yearly: 5.307,
          },
          {
            ic_uuid: "d8f004ba-3b09-425a-bc26-3ad08ea1a14a",
            ic_lat: -5798,
            ic_lon: -426585,
            ic_city: "Agricolândia",
            ic_states: "PIAUÍ",
            ic_january: 4.961,
            ic_february: 5.238,
            ic_march: 5.213,
            ic_april: 5.113,
            ic_may: 5.214,
            ic_june: 5.431,
            ic_july: 5.684,
            ic_august: 6.244,
            ic_september: 6.596,
            ic_october: 6.362,
            ic_november: 5.993,
            ic_december: 5.593,
            ic_yearly: 5.637,
          },
          {
            ic_uuid: "2730ad67-2583-4e13-b29c-f729efd99313",
            ic_lat: -274092,
            ic_lon: -498225,
            ic_city: "Agrolândia",
            ic_states: "SANTA CATARINA",
            ic_january: 5.635,
            ic_february: 5.355,
            ic_march: 4.608,
            ic_april: 3.663,
            ic_may: 2.884,
            ic_june: 2.36,
            ic_july: 2.536,
            ic_august: 3.322,
            ic_september: 3.517,
            ic_october: 4.108,
            ic_november: 5.405,
            ic_december: 5.774,
            ic_yearly: 4.098,
          },
          {
            ic_uuid: "98950568-f8e5-4fbc-aa54-fcc7f39267bb",
            ic_lat: -272666,
            ic_lon: -497084,
            ic_city: "Agronômica",
            ic_states: "SANTA CATARINA",
            ic_january: 5.663,
            ic_february: 5.358,
            ic_march: 4.645,
            ic_april: 3.649,
            ic_may: 2.863,
            ic_june: 2.326,
            ic_july: 2.491,
            ic_august: 3.318,
            ic_september: 3.53,
            ic_october: 4.156,
            ic_november: 5.424,
            ic_december: 5.809,
            ic_yearly: 4.102,
          },
          {
            ic_uuid: "d31e4002-6d39-4d09-b72f-3ac3053c498b",
            ic_lat: -68055,
            ic_lon: -50485,
            ic_city: "Água Azul do Norte",
            ic_states: "PARÁ",
            ic_january: 4.677,
            ic_february: 4.715,
            ic_march: 4.66,
            ic_april: 4.556,
            ic_may: 4.731,
            ic_june: 4.906,
            ic_july: 4.958,
            ic_august: 5.475,
            ic_september: 5.249,
            ic_october: 4.875,
            ic_november: 4.725,
            ic_december: 4.552,
            ic_yearly: 4.84,
          },
          {
            ic_uuid: "aeb5485c-cda4-4771-be6f-b4e6b7254de6",
            ic_lat: -140515,
            ic_lon: -521605,
            ic_city: "Água Boa",
            ic_states: "MATO GROSSO",
            ic_january: 5.272,
            ic_february: 5.536,
            ic_march: 5.184,
            ic_april: 5.236,
            ic_may: 4.99,
            ic_june: 4.729,
            ic_july: 5.076,
            ic_august: 5.565,
            ic_september: 5.449,
            ic_october: 5.467,
            ic_november: 5.294,
            ic_december: 5.397,
            ic_yearly: 5.266,
          },
          {
            ic_uuid: "3268b012-650c-41b2-a795-757435362e50",
            ic_lat: -179918,
            ic_lon: -42381,
            ic_city: "Água Boa",
            ic_states: "MINAS GERAIS",
            ic_january: 6.049,
            ic_february: 6.294,
            ic_march: 5.275,
            ic_april: 4.628,
            ic_may: 3.98,
            ic_june: 3.76,
            ic_july: 3.929,
            ic_august: 4.637,
            ic_september: 5.156,
            ic_october: 5.383,
            ic_november: 4.937,
            ic_december: 5.789,
            ic_yearly: 4.985,
          },
          {
            ic_uuid: "e91ed219-f319-4642-be2b-498462f72859",
            ic_lat: -92624,
            ic_lon: -379383,
            ic_city: "Água Branca",
            ic_states: "ALAGOAS",
            ic_january: 6.272,
            ic_february: 6.053,
            ic_march: 5.996,
            ic_april: 5.295,
            ic_may: 4.453,
            ic_june: 3.982,
            ic_july: 4.09,
            ic_august: 4.805,
            ic_september: 5.716,
            ic_october: 6.028,
            ic_november: 6.576,
            ic_december: 6.388,
            ic_yearly: 5.471,
          },
          {
            ic_uuid: "0f9b2314-e1bb-4bd9-b5fa-d6f67d612b8e",
            ic_lat: -75119,
            ic_lon: -37636,
            ic_city: "Água Branca",
            ic_states: "PARAÍBA",
            ic_january: 6.136,
            ic_february: 6.077,
            ic_march: 6.081,
            ic_april: 5.805,
            ic_may: 5.096,
            ic_june: 4.763,
            ic_july: 5.014,
            ic_august: 5.808,
            ic_september: 6.494,
            ic_october: 6.557,
            ic_november: 6.679,
            ic_december: 6.281,
            ic_yearly: 5.899,
          },
          {
            ic_uuid: "3b8214d3-8e9f-4fe3-a834-60bd82cb2a14",
            ic_lat: -58919,
            ic_lon: -426341,
            ic_city: "Água Branca",
            ic_states: "PIAUÍ",
            ic_january: 5.044,
            ic_february: 5.261,
            ic_march: 5.26,
            ic_april: 5.119,
            ic_may: 5.227,
            ic_june: 5.414,
            ic_july: 5.676,
            ic_august: 6.196,
            ic_september: 6.567,
            ic_october: 6.364,
            ic_november: 5.996,
            ic_december: 5.619,
            ic_yearly: 5.645,
          },
          {
            ic_uuid: "adae2414-7c98-4b96-879c-d00a2b559703",
            ic_lat: -204457,
            ic_lon: -528794,
            ic_city: "Água Clara",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.718,
            ic_february: 5.885,
            ic_march: 5.446,
            ic_april: 4.846,
            ic_may: 4.097,
            ic_june: 3.814,
            ic_july: 4.016,
            ic_august: 4.866,
            ic_september: 4.984,
            ic_october: 5.427,
            ic_november: 5.843,
            ic_december: 6.231,
            ic_yearly: 5.098,
          },
          {
            ic_uuid: "81e7bfb5-2404-4eb5-bb80-db96f7f7a3a9",
            ic_lat: -20058,
            ic_lon: -481073,
            ic_city: "Água Comprida",
            ic_states: "MINAS GERAIS",
            ic_january: 5.684,
            ic_february: 5.985,
            ic_march: 5.171,
            ic_april: 5.034,
            ic_may: 4.302,
            ic_june: 3.948,
            ic_july: 4.265,
            ic_august: 5.127,
            ic_september: 5.057,
            ic_october: 5.519,
            ic_november: 5.731,
            ic_december: 5.977,
            ic_yearly: 5.15,
          },
          {
            ic_uuid: "181ebedd-6f53-4a54-9190-ae926871caec",
            ic_lat: -269989,
            ic_lon: -515533,
            ic_city: "Água Doce",
            ic_states: "SANTA CATARINA",
            ic_january: 5.713,
            ic_february: 5.309,
            ic_march: 4.9,
            ic_april: 4.023,
            ic_may: 3.077,
            ic_june: 2.729,
            ic_july: 2.948,
            ic_august: 3.842,
            ic_september: 4.044,
            ic_october: 4.874,
            ic_november: 5.964,
            ic_december: 6.093,
            ic_yearly: 4.46,
          },
          {
            ic_uuid: "94ee3010-b499-4141-a688-b9a3443e50f6",
            ic_lat: -28409,
            ic_lon: -421192,
            ic_city: "Água Doce do Maranhão",
            ic_states: "MARANHÃO",
            ic_january: 5.507,
            ic_february: 5.345,
            ic_march: 5.201,
            ic_april: 4.953,
            ic_may: 5.073,
            ic_june: 5.232,
            ic_july: 5.457,
            ic_august: 6.029,
            ic_september: 6.15,
            ic_october: 5.951,
            ic_november: 6.28,
            ic_december: 5.988,
            ic_yearly: 5.597,
          },
          {
            ic_uuid: "145a5344-550b-4427-afca-68d5292a5a02",
            ic_lat: -185487,
            ic_lon: -409858,
            ic_city: "Água Doce do Norte",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.904,
            ic_february: 6.059,
            ic_march: 5.2,
            ic_april: 4.546,
            ic_may: 3.803,
            ic_june: 3.569,
            ic_july: 3.662,
            ic_august: 4.194,
            ic_september: 4.784,
            ic_october: 4.916,
            ic_november: 4.87,
            ic_december: 5.694,
            ic_yearly: 4.767,
          },
          {
            ic_uuid: "6d33ed0b-6f13-47d2-87b1-55252c6fdee4",
            ic_lat: -118623,
            ic_lon: -387642,
            ic_city: "Água Fria",
            ic_states: "BAHIA",
            ic_january: 6.077,
            ic_february: 5.819,
            ic_march: 5.666,
            ic_april: 4.727,
            ic_may: 3.981,
            ic_june: 3.707,
            ic_july: 3.978,
            ic_august: 4.467,
            ic_september: 5.317,
            ic_october: 5.524,
            ic_november: 5.827,
            ic_december: 6.038,
            ic_yearly: 5.094,
          },
          {
            ic_uuid: "c82a6266-3e26-449b-8405-9ec7c61d404e",
            ic_lat: -149814,
            ic_lon: -477827,
            ic_city: "Água Fria de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.537,
            ic_february: 5.796,
            ic_march: 5.249,
            ic_april: 5.247,
            ic_may: 4.975,
            ic_june: 4.767,
            ic_july: 5.053,
            ic_august: 5.958,
            ic_september: 5.85,
            ic_october: 5.65,
            ic_november: 5.027,
            ic_december: 5.499,
            ic_yearly: 5.384,
          },
          {
            ic_uuid: "2a3ad7f9-8a97-4e4a-a0ce-af7cdd3d55e6",
            ic_lat: -180797,
            ic_lon: -487643,
            ic_city: "Água Limpa",
            ic_states: "GOIÁS",
            ic_january: 5.625,
            ic_february: 5.819,
            ic_march: 5.248,
            ic_april: 5.148,
            ic_may: 4.696,
            ic_june: 4.424,
            ic_july: 4.61,
            ic_august: 5.415,
            ic_september: 5.38,
            ic_october: 5.616,
            ic_november: 5.591,
            ic_december: 5.764,
            ic_yearly: 5.278,
          },
          {
            ic_uuid: "a87f0d5c-78ce-4615-b0b7-4f7d03abd5ef",
            ic_lat: -62039,
            ic_lon: -382945,
            ic_city: "Água Nova",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.936,
            ic_february: 6.026,
            ic_march: 6.02,
            ic_april: 5.699,
            ic_may: 5.285,
            ic_june: 4.992,
            ic_july: 5.324,
            ic_august: 6.047,
            ic_september: 6.445,
            ic_october: 6.572,
            ic_november: 6.616,
            ic_december: 6.156,
            ic_yearly: 5.926,
          },
          {
            ic_uuid: "47979763-f623-46b5-8385-d915d02b8421",
            ic_lat: -87065,
            ic_lon: -355266,
            ic_city: "Água Preta",
            ic_states: "PERNAMBUCO",
            ic_january: 5.711,
            ic_february: 5.636,
            ic_march: 5.733,
            ic_april: 5.001,
            ic_may: 4.289,
            ic_june: 3.929,
            ic_july: 3.968,
            ic_august: 4.56,
            ic_september: 5.357,
            ic_october: 5.612,
            ic_november: 5.981,
            ic_december: 5.961,
            ic_yearly: 5.145,
          },
          {
            ic_uuid: "e6a7fae8-5106-4369-97dc-aeb810790139",
            ic_lat: -281677,
            ic_lon: -520315,
            ic_city: "Água Santa",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.221,
            ic_february: 5.733,
            ic_march: 4.939,
            ic_april: 4.017,
            ic_may: 3.009,
            ic_june: 2.513,
            ic_july: 2.852,
            ic_august: 3.646,
            ic_september: 3.869,
            ic_october: 5.011,
            ic_november: 6.185,
            ic_december: 6.506,
            ic_yearly: 4.542,
          },
          {
            ic_uuid: "e8a1a3a0-cd5f-444b-959c-c21513c6573b",
            ic_lat: -220577,
            ic_lon: -469739,
            ic_city: "Aguaí",
            ic_states: "SÃO PAULO",
            ic_january: 5.624,
            ic_february: 5.836,
            ic_march: 5.124,
            ic_april: 4.743,
            ic_may: 3.96,
            ic_june: 3.693,
            ic_july: 3.889,
            ic_august: 4.782,
            ic_september: 4.975,
            ic_october: 5.561,
            ic_november: 5.788,
            ic_december: 6.034,
            ic_yearly: 5.001,
          },
          {
            ic_uuid: "7c6c649c-49b5-4041-84f2-87b60c9ed35e",
            ic_lat: -209444,
            ic_lon: -453918,
            ic_city: "Aguanil",
            ic_states: "MINAS GERAIS",
            ic_january: 5.584,
            ic_february: 5.763,
            ic_march: 4.917,
            ic_april: 4.745,
            ic_may: 4.091,
            ic_june: 3.889,
            ic_july: 4.191,
            ic_august: 5.038,
            ic_september: 5.219,
            ic_october: 5.43,
            ic_november: 5.185,
            ic_december: 5.637,
            ic_yearly: 4.974,
          },
          {
            ic_uuid: "35f70137-e073-4a39-b50f-678682aa8c02",
            ic_lat: -91117,
            ic_lon: -371229,
            ic_city: "Águas Belas",
            ic_states: "PERNAMBUCO",
            ic_january: 6.227,
            ic_february: 6.01,
            ic_march: 6.015,
            ic_april: 5.299,
            ic_may: 4.403,
            ic_june: 3.952,
            ic_july: 4.174,
            ic_august: 4.795,
            ic_september: 5.722,
            ic_october: 6.112,
            ic_november: 6.56,
            ic_december: 6.407,
            ic_yearly: 5.473,
          },
          {
            ic_uuid: "6656ece8-2432-420a-95b1-8672360862be",
            ic_lat: -219324,
            ic_lon: -46718,
            ic_city: "Águas da Prata",
            ic_states: "SÃO PAULO",
            ic_january: 5.419,
            ic_february: 5.621,
            ic_march: 4.937,
            ic_april: 4.664,
            ic_may: 3.972,
            ic_june: 3.729,
            ic_july: 3.923,
            ic_august: 4.826,
            ic_september: 4.971,
            ic_october: 5.39,
            ic_november: 5.524,
            ic_december: 5.742,
            ic_yearly: 4.893,
          },
          {
            ic_uuid: "3cc6846c-c977-4293-9ad9-3445eff961a3",
            ic_lat: -270759,
            ic_lon: -529813,
            ic_city: "Águas de Chapecó",
            ic_states: "SANTA CATARINA",
            ic_january: 6.244,
            ic_february: 5.824,
            ic_march: 5.208,
            ic_april: 4.104,
            ic_may: 3.115,
            ic_june: 2.599,
            ic_july: 2.888,
            ic_august: 3.773,
            ic_september: 4.009,
            ic_october: 5.166,
            ic_november: 6.209,
            ic_december: 6.581,
            ic_yearly: 4.643,
          },
          {
            ic_uuid: "b0d7f4be-ba33-41e6-b9c8-71055fee265a",
            ic_lat: -224738,
            ic_lon: -466318,
            ic_city: "Águas de Lindóia",
            ic_states: "SÃO PAULO",
            ic_january: 5.53,
            ic_february: 5.716,
            ic_march: 5.105,
            ic_april: 4.697,
            ic_may: 3.926,
            ic_june: 3.634,
            ic_july: 3.838,
            ic_august: 4.691,
            ic_september: 4.933,
            ic_october: 5.486,
            ic_november: 5.662,
            ic_december: 5.952,
            ic_yearly: 4.931,
          },
          {
            ic_uuid: "459da109-5567-465f-9edb-f8cb256cd98f",
            ic_lat: -228817,
            ic_lon: -492425,
            ic_city: "Águas de Santa Bárbara",
            ic_states: "SÃO PAULO",
            ic_january: 5.559,
            ic_february: 5.751,
            ic_march: 5.135,
            ic_april: 4.648,
            ic_may: 3.712,
            ic_june: 3.466,
            ic_july: 3.65,
            ic_august: 4.635,
            ic_september: 4.711,
            ic_october: 5.387,
            ic_november: 5.875,
            ic_december: 6.181,
            ic_yearly: 4.893,
          },
          {
            ic_uuid: "b5834494-f861-49de-b503-314bf8fe2417",
            ic_lat: -225982,
            ic_lon: -478738,
            ic_city: "Águas de São Pedro",
            ic_states: "SÃO PAULO",
            ic_january: 5.385,
            ic_february: 5.627,
            ic_march: 5.004,
            ic_april: 4.626,
            ic_may: 3.745,
            ic_june: 3.501,
            ic_july: 3.645,
            ic_august: 4.684,
            ic_september: 4.776,
            ic_october: 5.351,
            ic_november: 5.575,
            ic_december: 5.971,
            ic_yearly: 4.824,
          },
          {
            ic_uuid: "fec4efd6-0206-4b67-931a-28f6c1a3c6d9",
            ic_lat: -170807,
            ic_lon: -409387,
            ic_city: "Águas Formosas",
            ic_states: "MINAS GERAIS",
            ic_january: 6.198,
            ic_february: 6.439,
            ic_march: 5.458,
            ic_april: 4.719,
            ic_may: 3.909,
            ic_june: 3.521,
            ic_july: 3.72,
            ic_august: 4.405,
            ic_september: 5.074,
            ic_october: 5.324,
            ic_november: 5.208,
            ic_december: 6.159,
            ic_yearly: 5.011,
          },
          {
            ic_uuid: "3e601136-1833-4c0a-b4ae-b16078bb1e68",
            ic_lat: -268798,
            ic_lon: -528572,
            ic_city: "Águas Frias",
            ic_states: "SANTA CATARINA",
            ic_january: 6.223,
            ic_february: 5.747,
            ic_march: 5.168,
            ic_april: 4.138,
            ic_may: 3.158,
            ic_june: 2.656,
            ic_july: 2.941,
            ic_august: 3.821,
            ic_september: 4.061,
            ic_october: 5.153,
            ic_november: 6.158,
            ic_december: 6.466,
            ic_yearly: 4.641,
          },
          {
            ic_uuid: "b64ebcf9-cbdc-4d36-8b1e-2560b957763a",
            ic_lat: -157414,
            ic_lon: -482597,
            ic_city: "Águas Lindas de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.387,
            ic_february: 5.543,
            ic_march: 5.057,
            ic_april: 4.93,
            ic_may: 4.86,
            ic_june: 4.759,
            ic_july: 4.979,
            ic_august: 5.931,
            ic_september: 5.679,
            ic_october: 5.524,
            ic_november: 5.12,
            ic_december: 5.385,
            ic_yearly: 5.263,
          },
          {
            ic_uuid: "5f7370ab-f788-4444-8e59-16579d6ccc74",
            ic_lat: -276968,
            ic_lon: -488248,
            ic_city: "Águas Mornas",
            ic_states: "SANTA CATARINA",
            ic_january: 5.395,
            ic_february: 5.081,
            ic_march: 4.404,
            ic_april: 3.636,
            ic_may: 2.984,
            ic_june: 2.564,
            ic_july: 2.681,
            ic_august: 3.386,
            ic_september: 3.423,
            ic_october: 3.962,
            ic_november: 5.169,
            ic_december: 5.553,
            ic_yearly: 4.02,
          },
          {
            ic_uuid: "e1ac0995-6ba8-482b-8ec4-db94b355caf2",
            ic_lat: -157466,
            ic_lon: -414584,
            ic_city: "Águas Vermelhas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.955,
            ic_february: 6.099,
            ic_march: 5.363,
            ic_april: 4.69,
            ic_may: 4.063,
            ic_june: 3.64,
            ic_july: 3.866,
            ic_august: 4.523,
            ic_september: 5.459,
            ic_october: 5.557,
            ic_november: 5.271,
            ic_december: 6.011,
            ic_yearly: 5.042,
          },
          {
            ic_uuid: "29b8f068-64e4-4b03-80f3-dab03e0dbdc6",
            ic_lat: -296452,
            ic_lon: -532519,
            ic_city: "Agudo",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.332,
            ic_february: 5.88,
            ic_march: 4.982,
            ic_april: 3.841,
            ic_may: 2.808,
            ic_june: 2.348,
            ic_july: 2.583,
            ic_august: 3.347,
            ic_september: 3.686,
            ic_october: 4.884,
            ic_november: 6.236,
            ic_december: 6.742,
            ic_yearly: 4.472,
          },
          {
            ic_uuid: "390ce38a-9e5c-4745-96fa-cb109a73295f",
            ic_lat: -224699,
            ic_lon: -489867,
            ic_city: "Agudos",
            ic_states: "SÃO PAULO",
            ic_january: 5.506,
            ic_february: 5.795,
            ic_march: 5.198,
            ic_april: 4.723,
            ic_may: 3.819,
            ic_june: 3.505,
            ic_july: 3.725,
            ic_august: 4.681,
            ic_september: 4.777,
            ic_october: 5.465,
            ic_november: 5.82,
            ic_december: 6.138,
            ic_yearly: 4.929,
          },
          {
            ic_uuid: "120f20d0-96ac-41ab-8a4a-5daf9f192685",
            ic_lat: -259904,
            ic_lon: -493347,
            ic_city: "Agudos do Sul",
            ic_states: "PARANÁ",
            ic_january: 5.381,
            ic_february: 5.084,
            ic_march: 4.502,
            ic_april: 3.696,
            ic_may: 2.976,
            ic_june: 2.624,
            ic_july: 2.825,
            ic_august: 3.736,
            ic_september: 3.751,
            ic_october: 4.337,
            ic_november: 5.177,
            ic_december: 5.548,
            ic_yearly: 4.136,
          },
          {
            ic_uuid: "fdeeabf0-e258-41bd-9291-067daf585f30",
            ic_lat: -189851,
            ic_lon: -40744,
            ic_city: "Águia Branca",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.009,
            ic_february: 6.089,
            ic_march: 5.277,
            ic_april: 4.582,
            ic_may: 3.881,
            ic_june: 3.627,
            ic_july: 3.658,
            ic_august: 4.228,
            ic_september: 4.692,
            ic_october: 4.925,
            ic_november: 4.874,
            ic_december: 5.748,
            ic_yearly: 4.799,
          },
          {
            ic_uuid: "f67ce588-8134-48f8-8ca0-e2010dfbbc0a",
            ic_lat: -70922,
            ic_lon: -381684,
            ic_city: "Aguiar",
            ic_states: "PARAÍBA",
            ic_january: 6.327,
            ic_february: 6.166,
            ic_march: 6.228,
            ic_april: 5.897,
            ic_may: 5.27,
            ic_june: 4.904,
            ic_july: 5.192,
            ic_august: 5.93,
            ic_september: 6.553,
            ic_october: 6.666,
            ic_november: 6.845,
            ic_december: 6.506,
            ic_yearly: 6.041,
          },
          {
            ic_uuid: "db038752-4349-4703-9e73-a3707c30af9d",
            ic_lat: -65601,
            ic_lon: -474771,
            ic_city: "Aguiarnópolis",
            ic_states: "TOCANTINS",
            ic_january: 4.818,
            ic_february: 4.901,
            ic_march: 4.894,
            ic_april: 4.916,
            ic_may: 4.935,
            ic_june: 5.221,
            ic_july: 5.42,
            ic_august: 5.884,
            ic_september: 5.903,
            ic_october: 5.347,
            ic_november: 4.861,
            ic_december: 4.773,
            ic_yearly: 5.156,
          },
          {
            ic_uuid: "89bac3c3-8f68-4d29-899c-1f840154563a",
            ic_lat: -195012,
            ic_lon: -41075,
            ic_city: "Aimorés",
            ic_states: "MINAS GERAIS",
            ic_january: 6.226,
            ic_february: 6.33,
            ic_march: 5.345,
            ic_april: 4.718,
            ic_may: 4.003,
            ic_june: 3.666,
            ic_july: 3.808,
            ic_august: 4.448,
            ic_september: 4.891,
            ic_october: 5.087,
            ic_november: 5.093,
            ic_december: 5.912,
            ic_yearly: 4.961,
          },
          {
            ic_uuid: "1ab884f5-ccf7-40a2-b31c-a8741c2d24f8",
            ic_lat: -141274,
            ic_lon: -39894,
            ic_city: "Aiquara",
            ic_states: "BAHIA",
            ic_january: 5.906,
            ic_february: 5.987,
            ic_march: 5.578,
            ic_april: 4.574,
            ic_may: 3.931,
            ic_june: 3.528,
            ic_july: 3.716,
            ic_august: 4.264,
            ic_september: 5.005,
            ic_october: 5.176,
            ic_november: 5.351,
            ic_december: 6.011,
            ic_yearly: 4.919,
          },
          {
            ic_uuid: "55067bb7-5cd4-42fb-9c26-98487571636b",
            ic_lat: -65693,
            ic_lon: -401218,
            ic_city: "Aiuaba",
            ic_states: "CEARÁ",
            ic_january: 5.504,
            ic_february: 5.436,
            ic_march: 5.513,
            ic_april: 5.174,
            ic_may: 5.072,
            ic_june: 5.117,
            ic_july: 5.454,
            ic_august: 6.244,
            ic_september: 6.568,
            ic_october: 6.45,
            ic_november: 6.312,
            ic_december: 5.785,
            ic_yearly: 5.719,
          },
          {
            ic_uuid: "ef2f8870-9207-4214-92a5-5487863d580e",
            ic_lat: -219741,
            ic_lon: -446046,
            ic_city: "Aiuruoca",
            ic_states: "MINAS GERAIS",
            ic_january: 5.332,
            ic_february: 5.646,
            ic_march: 4.87,
            ic_april: 4.472,
            ic_may: 3.812,
            ic_june: 3.611,
            ic_july: 3.848,
            ic_august: 4.706,
            ic_september: 4.943,
            ic_october: 5.235,
            ic_november: 5.118,
            ic_december: 5.529,
            ic_yearly: 4.76,
          },
          {
            ic_uuid: "921d92c2-c68a-4fcc-9b37-bffc5bf4aaa7",
            ic_lat: -28239,
            ic_lon: -537709,
            ic_city: "Ajuricaba",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.418,
            ic_february: 6.002,
            ic_march: 5.104,
            ic_april: 4.02,
            ic_may: 3.085,
            ic_june: 2.531,
            ic_july: 2.821,
            ic_august: 3.605,
            ic_september: 3.945,
            ic_october: 5.092,
            ic_november: 6.181,
            ic_december: 6.604,
            ic_yearly: 4.617,
          },
          {
            ic_uuid: "d2db809b-c560-44c1-b81a-4534a751b05e",
            ic_lat: -221715,
            ic_lon: -446417,
            ic_city: "Alagoa",
            ic_states: "MINAS GERAIS",
            ic_january: 5.1,
            ic_february: 5.348,
            ic_march: 4.554,
            ic_april: 4.316,
            ic_may: 3.754,
            ic_june: 3.57,
            ic_july: 3.789,
            ic_august: 4.662,
            ic_september: 4.836,
            ic_october: 5.009,
            ic_november: 4.798,
            ic_december: 5.225,
            ic_yearly: 4.58,
          },
          {
            ic_uuid: "e381989d-ead1-4151-9dca-71ad75607234",
            ic_lat: -70411,
            ic_lon: -356271,
            ic_city: "Alagoa Grande",
            ic_states: "PARAÍBA",
            ic_january: 5.571,
            ic_february: 5.66,
            ic_march: 5.659,
            ic_april: 5.174,
            ic_may: 4.581,
            ic_june: 4.046,
            ic_july: 4.139,
            ic_august: 4.868,
            ic_september: 5.389,
            ic_october: 5.688,
            ic_november: 5.935,
            ic_december: 5.75,
            ic_yearly: 5.205,
          },
          {
            ic_uuid: "e3d0d515-30a5-4ac6-a0e6-590646c816c1",
            ic_lat: -70608,
            ic_lon: -357639,
            ic_city: "Alagoa Nova",
            ic_states: "PARAÍBA",
            ic_january: 5.599,
            ic_february: 5.726,
            ic_march: 5.716,
            ic_april: 5.282,
            ic_may: 4.617,
            ic_june: 4.098,
            ic_july: 4.171,
            ic_august: 4.947,
            ic_september: 5.436,
            ic_october: 5.731,
            ic_november: 6.0,
            ic_december: 5.81,
            ic_yearly: 5.261,
          },
          {
            ic_uuid: "133d7a51-36b4-4587-a189-0aee9dde5ffc",
            ic_lat: -69474,
            ic_lon: -355338,
            ic_city: "Alagoinha",
            ic_states: "PARAÍBA",
            ic_january: 5.484,
            ic_february: 5.604,
            ic_march: 5.631,
            ic_april: 5.168,
            ic_may: 4.563,
            ic_june: 4.117,
            ic_july: 4.201,
            ic_august: 4.956,
            ic_september: 5.375,
            ic_october: 5.603,
            ic_november: 5.856,
            ic_december: 5.648,
            ic_yearly: 5.184,
          },
          {
            ic_uuid: "d267219b-1a2e-493a-9213-05d4c5082f61",
            ic_lat: -84669,
            ic_lon: -367791,
            ic_city: "Alagoinha",
            ic_states: "PERNAMBUCO",
            ic_january: 6.24,
            ic_february: 5.917,
            ic_march: 5.948,
            ic_april: 5.366,
            ic_may: 4.389,
            ic_june: 3.905,
            ic_july: 4.037,
            ic_august: 4.822,
            ic_september: 5.76,
            ic_october: 6.133,
            ic_november: 6.621,
            ic_december: 6.437,
            ic_yearly: 5.465,
          },
          {
            ic_uuid: "5fa37fbb-2375-4730-ad8c-26fe3d70a4c8",
            ic_lat: -70087,
            ic_lon: -40937,
            ic_city: "Alagoinha do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.546,
            ic_february: 5.428,
            ic_march: 5.636,
            ic_april: 5.298,
            ic_may: 5.085,
            ic_june: 5.052,
            ic_july: 5.471,
            ic_august: 6.225,
            ic_september: 6.65,
            ic_october: 6.457,
            ic_november: 6.394,
            ic_december: 6.012,
            ic_yearly: 5.771,
          },
          {
            ic_uuid: "ae7d9fd5-7db6-475b-9b76-4a0915efb83c",
            ic_lat: -12134,
            ic_lon: -384211,
            ic_city: "Alagoinhas",
            ic_states: "BAHIA",
            ic_january: 6.073,
            ic_february: 5.877,
            ic_march: 5.716,
            ic_april: 4.712,
            ic_may: 3.907,
            ic_june: 3.672,
            ic_july: 3.906,
            ic_august: 4.38,
            ic_september: 5.214,
            ic_october: 5.494,
            ic_november: 5.831,
            ic_december: 6.145,
            ic_yearly: 5.077,
          },
          {
            ic_uuid: "edaa50a6-6a6b-44ac-8b35-c7a27a41bce4",
            ic_lat: -235508,
            ic_lon: -478984,
            ic_city: "Alambari",
            ic_states: "SÃO PAULO",
            ic_january: 5.474,
            ic_february: 5.688,
            ic_march: 4.997,
            ic_april: 4.454,
            ic_may: 3.573,
            ic_june: 3.324,
            ic_july: 3.423,
            ic_august: 4.421,
            ic_september: 4.564,
            ic_october: 5.127,
            ic_november: 5.643,
            ic_december: 6.051,
            ic_yearly: 4.728,
          },
          {
            ic_uuid: "31e2f88a-a447-465d-9c78-26a394778db4",
            ic_lat: -222023,
            ic_lon: -466143,
            ic_city: "Albertina",
            ic_states: "MINAS GERAIS",
            ic_january: 5.357,
            ic_february: 5.52,
            ic_march: 4.867,
            ic_april: 4.599,
            ic_may: 3.905,
            ic_june: 3.668,
            ic_july: 3.889,
            ic_august: 4.757,
            ic_september: 4.949,
            ic_october: 5.407,
            ic_november: 5.52,
            ic_december: 5.732,
            ic_yearly: 4.848,
          },
          {
            ic_uuid: "cfe5b109-1687-4983-896c-09c3758e2338",
            ic_lat: -24067,
            ic_lon: -444185,
            ic_city: "Alcântara",
            ic_states: "MARANHÃO",
            ic_january: 5.017,
            ic_february: 4.96,
            ic_march: 4.8,
            ic_april: 4.694,
            ic_may: 4.841,
            ic_june: 5.038,
            ic_july: 5.196,
            ic_august: 5.645,
            ic_september: 5.916,
            ic_october: 5.725,
            ic_november: 5.617,
            ic_december: 5.364,
            ic_yearly: 5.235,
          },
          {
            ic_uuid: "d76a890d-ae9c-49a5-9918-f95d5de98bfe",
            ic_lat: -35838,
            ic_lon: -405436,
            ic_city: "Alcântaras",
            ic_states: "CEARÁ",
            ic_january: 4.959,
            ic_february: 5.134,
            ic_march: 4.996,
            ic_april: 4.696,
            ic_may: 4.959,
            ic_june: 5.062,
            ic_july: 5.339,
            ic_august: 6.079,
            ic_september: 6.569,
            ic_october: 6.478,
            ic_november: 6.23,
            ic_december: 5.487,
            ic_yearly: 5.499,
          },
          {
            ic_uuid: "282a4297-7b7c-4233-b458-2cb1dc6faa9b",
            ic_lat: -77371,
            ic_lon: -360514,
            ic_city: "Alcantil",
            ic_states: "PARAÍBA",
            ic_january: 5.857,
            ic_february: 5.813,
            ic_march: 5.792,
            ic_april: 5.295,
            ic_may: 4.681,
            ic_june: 4.158,
            ic_july: 4.37,
            ic_august: 4.994,
            ic_september: 5.639,
            ic_october: 5.924,
            ic_november: 6.186,
            ic_december: 5.902,
            ic_yearly: 5.384,
          },
          {
            ic_uuid: "71e0dc56-d95f-426c-ae16-e064133af432",
            ic_lat: -18326,
            ic_lon: -537047,
            ic_city: "Alcinópolis",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.645,
            ic_february: 5.785,
            ic_march: 5.447,
            ic_april: 5.093,
            ic_may: 4.506,
            ic_june: 4.215,
            ic_july: 4.366,
            ic_august: 5.207,
            ic_september: 5.235,
            ic_october: 5.505,
            ic_november: 5.807,
            ic_december: 5.96,
            ic_yearly: 5.231,
          },
          {
            ic_uuid: "289fc1dc-e2ae-44ed-afba-1db0c299c0ca",
            ic_lat: -175199,
            ic_lon: -392039,
            ic_city: "Alcobaça",
            ic_states: "BAHIA",
            ic_january: 6.436,
            ic_february: 6.458,
            ic_march: 5.532,
            ic_april: 4.55,
            ic_may: 3.893,
            ic_june: 3.622,
            ic_july: 3.82,
            ic_august: 4.517,
            ic_september: 5.08,
            ic_october: 5.445,
            ic_november: 5.561,
            ic_december: 6.42,
            ic_yearly: 5.111,
          },
          {
            ic_uuid: "505b37d0-0595-4a3f-b051-1653a373dc5c",
            ic_lat: -46266,
            ic_lon: -434693,
            ic_city: "Aldeias Altas",
            ic_states: "MARANHÃO",
            ic_january: 4.96,
            ic_february: 5.074,
            ic_march: 5.116,
            ic_april: 5.051,
            ic_may: 5.042,
            ic_june: 5.202,
            ic_july: 5.457,
            ic_august: 6.111,
            ic_september: 6.398,
            ic_october: 6.157,
            ic_november: 5.85,
            ic_december: 5.416,
            ic_yearly: 5.486,
          },
          {
            ic_uuid: "a80a3446-8284-4553-a097-5e634ad7a781",
            ic_lat: -276583,
            ic_lon: -547653,
            ic_city: "Alecrim",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.492,
            ic_february: 5.982,
            ic_march: 5.161,
            ic_april: 4.075,
            ic_may: 3.088,
            ic_june: 2.521,
            ic_july: 2.838,
            ic_august: 3.648,
            ic_september: 4.037,
            ic_october: 5.188,
            ic_november: 6.24,
            ic_december: 6.712,
            ic_yearly: 4.665,
          },
          {
            ic_uuid: "b5151605-a1a5-4827-9c37-a5b784fc5708",
            ic_lat: -207585,
            ic_lon: -415386,
            ic_city: "Alegre",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.138,
            ic_february: 6.333,
            ic_march: 5.13,
            ic_april: 4.475,
            ic_may: 3.864,
            ic_june: 3.665,
            ic_july: 3.765,
            ic_august: 4.513,
            ic_september: 5.005,
            ic_october: 5.135,
            ic_november: 4.987,
            ic_december: 5.707,
            ic_yearly: 4.893,
          },
          {
            ic_uuid: "ac62b952-cfe6-4415-8283-510899ae7f6d",
            ic_lat: -297891,
            ic_lon: -558025,
            ic_city: "Alegrete",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.878,
            ic_february: 6.146,
            ic_march: 5.287,
            ic_april: 4.112,
            ic_may: 2.966,
            ic_june: 2.458,
            ic_july: 2.75,
            ic_august: 3.516,
            ic_september: 4.155,
            ic_october: 5.421,
            ic_november: 6.532,
            ic_december: 7.106,
            ic_yearly: 4.777,
          },
          {
            ic_uuid: "b6ec266d-24b2-410e-82f8-dee1bf921b9f",
            ic_lat: -72424,
            ic_lon: -408569,
            ic_city: "Alegrete do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.611,
            ic_february: 5.488,
            ic_march: 5.613,
            ic_april: 5.273,
            ic_may: 5.169,
            ic_june: 5.082,
            ic_july: 5.5,
            ic_august: 6.075,
            ic_september: 6.602,
            ic_october: 6.433,
            ic_november: 6.399,
            ic_december: 6.017,
            ic_yearly: 5.772,
          },
          {
            ic_uuid: "46aaa3e5-2456-489c-8d4b-47e56e5d813f",
            ic_lat: -278349,
            ic_lon: -540562,
            ic_city: "Alegria",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.476,
            ic_february: 6.003,
            ic_march: 5.142,
            ic_april: 4.09,
            ic_may: 3.107,
            ic_june: 2.579,
            ic_july: 2.875,
            ic_august: 3.691,
            ic_september: 3.994,
            ic_october: 5.2,
            ic_november: 6.261,
            ic_december: 6.706,
            ic_yearly: 4.677,
          },
          {
            ic_uuid: "de48398c-e744-45db-a75c-154be598eebe",
            ic_lat: -218802,
            ic_lon: -42718,
            ic_city: "Além Paraíba",
            ic_states: "MINAS GERAIS",
            ic_january: 5.801,
            ic_february: 6.073,
            ic_march: 5.093,
            ic_april: 4.448,
            ic_may: 3.624,
            ic_june: 3.392,
            ic_july: 3.553,
            ic_august: 4.298,
            ic_september: 4.634,
            ic_october: 4.931,
            ic_november: 4.874,
            ic_december: 5.685,
            ic_yearly: 4.701,
          },
          {
            ic_uuid: "57b028e4-6b65-48af-9027-e3f9338b69c3",
            ic_lat: -19466,
            ic_lon: -547388,
            ic_city: "Alenquer",
            ic_states: "PARÁ",
            ic_january: 4.506,
            ic_february: 4.582,
            ic_march: 4.431,
            ic_april: 4.311,
            ic_may: 4.452,
            ic_june: 4.672,
            ic_july: 4.677,
            ic_august: 5.199,
            ic_september: 5.202,
            ic_october: 5.238,
            ic_november: 5.143,
            ic_december: 4.587,
            ic_yearly: 4.75,
          },
          {
            ic_uuid: "a176ea3b-a289-42d1-813e-35730f447d8a",
            ic_lat: -64057,
            ic_lon: -380145,
            ic_city: "Alexandria",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.107,
            ic_february: 6.068,
            ic_march: 6.127,
            ic_april: 5.801,
            ic_may: 5.273,
            ic_june: 4.971,
            ic_july: 5.295,
            ic_august: 6.055,
            ic_september: 6.488,
            ic_october: 6.632,
            ic_november: 6.694,
            ic_december: 6.231,
            ic_yearly: 5.979,
          },
          {
            ic_uuid: "a9fac92a-02cf-456b-8a32-91924b09b6cd",
            ic_lat: -160839,
            ic_lon: -48508,
            ic_city: "Alexânia",
            ic_states: "GOIÁS",
            ic_january: 5.425,
            ic_february: 5.542,
            ic_march: 5.024,
            ic_april: 5.039,
            ic_may: 4.837,
            ic_june: 4.646,
            ic_july: 4.94,
            ic_august: 5.819,
            ic_september: 5.567,
            ic_october: 5.559,
            ic_november: 5.104,
            ic_december: 5.445,
            ic_yearly: 5.246,
          },
          {
            ic_uuid: "0871af5a-540e-4412-88fe-afecc50c329c",
            ic_lat: -214261,
            ic_lon: -459481,
            ic_city: "Alfenas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.689,
            ic_february: 5.865,
            ic_march: 4.968,
            ic_april: 4.634,
            ic_may: 3.966,
            ic_june: 3.673,
            ic_july: 3.977,
            ic_august: 4.864,
            ic_september: 5.072,
            ic_october: 5.457,
            ic_november: 5.351,
            ic_december: 5.702,
            ic_yearly: 4.935,
          },
          {
            ic_uuid: "cfb00883-b95a-434c-87ae-669ee18bb633",
            ic_lat: -206401,
            ic_lon: -407546,
            ic_city: "Alfredo Chaves",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.968,
            ic_february: 6.199,
            ic_march: 4.978,
            ic_april: 4.332,
            ic_may: 3.713,
            ic_june: 3.548,
            ic_july: 3.608,
            ic_august: 4.299,
            ic_september: 4.748,
            ic_october: 4.861,
            ic_november: 4.77,
            ic_december: 5.542,
            ic_yearly: 4.714,
          },
          {
            ic_uuid: "7b703ed4-2b74-458b-865b-07b65a99e307",
            ic_lat: -219532,
            ic_lon: -514144,
            ic_city: "Alfredo Marcondes",
            ic_states: "SÃO PAULO",
            ic_january: 6.012,
            ic_february: 6.047,
            ic_march: 5.519,
            ic_april: 4.877,
            ic_may: 3.965,
            ic_june: 3.606,
            ic_july: 3.843,
            ic_august: 4.803,
            ic_september: 4.879,
            ic_october: 5.575,
            ic_november: 6.146,
            ic_december: 6.452,
            ic_yearly: 5.144,
          },
          {
            ic_uuid: "fe28fb4c-62d1-40f2-94e4-875eced7da40",
            ic_lat: -21154,
            ic_lon: -437721,
            ic_city: "Alfredo Vasconcelos",
            ic_states: "MINAS GERAIS",
            ic_january: 5.447,
            ic_february: 5.764,
            ic_march: 4.809,
            ic_april: 4.3,
            ic_may: 3.729,
            ic_june: 3.568,
            ic_july: 3.768,
            ic_august: 4.651,
            ic_september: 4.865,
            ic_october: 5.088,
            ic_november: 4.838,
            ic_december: 5.5,
            ic_yearly: 4.694,
          },
          {
            ic_uuid: "6c0fc439-bdec-41e2-81a5-1a22a30fb217",
            ic_lat: -277006,
            ic_lon: -493278,
            ic_city: "Alfredo Wagner",
            ic_states: "SANTA CATARINA",
            ic_january: 5.748,
            ic_february: 5.343,
            ic_march: 4.703,
            ic_april: 3.793,
            ic_may: 2.942,
            ic_june: 2.472,
            ic_july: 2.683,
            ic_august: 3.454,
            ic_september: 3.57,
            ic_october: 4.179,
            ic_november: 5.539,
            ic_december: 5.893,
            ic_yearly: 4.193,
          },
          {
            ic_uuid: "9b1b06a5-c4a8-4686-9277-2a4bf9601655",
            ic_lat: -68995,
            ic_lon: -360113,
            ic_city: "Algodão de Jandaíra",
            ic_states: "PARAÍBA",
            ic_january: 5.705,
            ic_february: 5.75,
            ic_march: 5.779,
            ic_april: 5.325,
            ic_may: 4.762,
            ic_june: 4.229,
            ic_july: 4.387,
            ic_august: 5.14,
            ic_september: 5.658,
            ic_october: 5.999,
            ic_november: 6.181,
            ic_december: 5.923,
            ic_yearly: 5.403,
          },
          {
            ic_uuid: "52b58350-1e8e-4323-85bd-a73aeb831721",
            ic_lat: -74302,
            ic_lon: -34906,
            ic_city: "Alhandra",
            ic_states: "PARAÍBA",
            ic_january: 5.748,
            ic_february: 5.984,
            ic_march: 6.002,
            ic_april: 5.293,
            ic_may: 4.701,
            ic_june: 4.291,
            ic_july: 4.339,
            ic_august: 5.142,
            ic_september: 5.553,
            ic_october: 5.877,
            ic_november: 6.015,
            ic_december: 6.049,
            ic_yearly: 5.416,
          },
          {
            ic_uuid: "7fa785a5-cb17-44a6-affb-d0ca63dd6320",
            ic_lat: -76042,
            ic_lon: -352299,
            ic_city: "Aliança",
            ic_states: "PERNAMBUCO",
            ic_january: 5.486,
            ic_february: 5.642,
            ic_march: 5.747,
            ic_april: 5.135,
            ic_may: 4.479,
            ic_june: 4.08,
            ic_july: 4.189,
            ic_august: 4.915,
            ic_september: 5.399,
            ic_october: 5.623,
            ic_november: 5.937,
            ic_december: 5.823,
            ic_yearly: 5.205,
          },
          {
            ic_uuid: "c2a417c1-4b86-476c-868a-48ca95b2e1fe",
            ic_lat: -11306,
            ic_lon: -489365,
            ic_city: "Aliança do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 5.206,
            ic_february: 5.293,
            ic_march: 4.927,
            ic_april: 4.996,
            ic_may: 5.011,
            ic_june: 4.966,
            ic_july: 5.322,
            ic_august: 5.93,
            ic_september: 5.739,
            ic_october: 5.467,
            ic_november: 5.26,
            ic_december: 5.274,
            ic_yearly: 5.283,
          },
          {
            ic_uuid: "2ff8c78c-565f-497e-8ce2-860dd471ac63",
            ic_lat: -147093,
            ic_lon: -396418,
            ic_city: "Almadina",
            ic_states: "BAHIA",
            ic_january: 5.609,
            ic_february: 5.675,
            ic_march: 5.257,
            ic_april: 4.418,
            ic_may: 3.749,
            ic_june: 3.435,
            ic_july: 3.619,
            ic_august: 4.059,
            ic_september: 4.71,
            ic_october: 4.902,
            ic_november: 5.065,
            ic_december: 5.828,
            ic_yearly: 4.694,
          },
          {
            ic_uuid: "bea1cd5c-b113-49d3-a59f-210e88533739",
            ic_lat: -115678,
            ic_lon: -471662,
            ic_city: "Almas",
            ic_states: "TOCANTINS",
            ic_january: 5.649,
            ic_february: 5.532,
            ic_march: 5.191,
            ic_april: 5.235,
            ic_may: 5.285,
            ic_june: 5.163,
            ic_july: 5.421,
            ic_august: 6.08,
            ic_september: 6.125,
            ic_october: 5.746,
            ic_november: 5.421,
            ic_december: 5.575,
            ic_yearly: 5.535,
          },
          {
            ic_uuid: "980ad943-df96-4832-a73f-954963a7454c",
            ic_lat: -15213,
            ic_lon: -52582,
            ic_city: "Almeirim",
            ic_states: "PARÁ",
            ic_january: 4.52,
            ic_february: 4.575,
            ic_march: 4.579,
            ic_april: 4.534,
            ic_may: 4.689,
            ic_june: 4.902,
            ic_july: 4.86,
            ic_august: 5.09,
            ic_september: 5.076,
            ic_october: 5.168,
            ic_november: 5.032,
            ic_december: 4.618,
            ic_yearly: 4.803,
          },
          {
            ic_uuid: "e7664ee8-b190-4006-8c95-920bb3058fcd",
            ic_lat: -161789,
            ic_lon: -406945,
            ic_city: "Almenara",
            ic_states: "MINAS GERAIS",
            ic_january: 6.162,
            ic_february: 6.376,
            ic_march: 5.523,
            ic_april: 4.838,
            ic_may: 4.012,
            ic_june: 3.573,
            ic_july: 3.735,
            ic_august: 4.262,
            ic_september: 5.081,
            ic_october: 5.215,
            ic_november: 5.327,
            ic_december: 6.163,
            ic_yearly: 5.022,
          },
          {
            ic_uuid: "05c09944-4a58-4f91-a724-b396c8f88ecf",
            ic_lat: -61479,
            ic_lon: -377639,
            ic_city: "Almino Afonso",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.147,
            ic_february: 6.065,
            ic_march: 6.124,
            ic_april: 5.882,
            ic_may: 5.406,
            ic_june: 5.07,
            ic_july: 5.368,
            ic_august: 6.057,
            ic_september: 6.503,
            ic_october: 6.627,
            ic_november: 6.651,
            ic_december: 6.163,
            ic_yearly: 6.005,
          },
          {
            ic_uuid: "d43fae6f-ee58-4d06-bef6-cb69ef40e08f",
            ic_lat: -253193,
            ic_lon: -493042,
            ic_city: "Almirante Tamandaré",
            ic_states: "PARANÁ",
            ic_january: 5.314,
            ic_february: 5.213,
            ic_march: 4.619,
            ic_april: 3.809,
            ic_may: 3.035,
            ic_june: 2.741,
            ic_july: 2.924,
            ic_august: 3.918,
            ic_september: 3.914,
            ic_october: 4.438,
            ic_november: 5.291,
            ic_december: 5.625,
            ic_yearly: 4.237,
          },
          {
            ic_uuid: "658a35fd-3a8f-434d-aa76-71253d368044",
            ic_lat: -281154,
            ic_lon: -529147,
            ic_city: "Almirante Tamandaré do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.338,
            ic_february: 5.916,
            ic_march: 5.018,
            ic_april: 4.078,
            ic_may: 3.076,
            ic_june: 2.552,
            ic_july: 2.865,
            ic_august: 3.643,
            ic_september: 3.883,
            ic_october: 5.094,
            ic_november: 6.255,
            ic_december: 6.631,
            ic_yearly: 4.613,
          },
          {
            ic_uuid: "27b9cd2a-390d-4bac-a367-890aac47097e",
            ic_lat: -177289,
            ic_lon: -494813,
            ic_city: "Aloândia",
            ic_states: "GOIÁS",
            ic_january: 5.674,
            ic_february: 5.779,
            ic_march: 5.313,
            ic_april: 5.134,
            ic_may: 4.706,
            ic_june: 4.424,
            ic_july: 4.624,
            ic_august: 5.489,
            ic_september: 5.361,
            ic_october: 5.608,
            ic_november: 5.588,
            ic_december: 5.717,
            ic_yearly: 5.285,
          },
          {
            ic_uuid: "f99e27b5-6bb9-422d-a04d-367e2653eeff",
            ic_lat: -189745,
            ic_lon: -419704,
            ic_city: "Alpercata",
            ic_states: "MINAS GERAIS",
            ic_january: 6.009,
            ic_february: 6.161,
            ic_march: 5.322,
            ic_april: 4.687,
            ic_may: 3.885,
            ic_june: 3.698,
            ic_july: 3.787,
            ic_august: 4.502,
            ic_september: 4.948,
            ic_october: 5.177,
            ic_november: 4.965,
            ic_december: 5.853,
            ic_yearly: 4.916,
          },
          {
            ic_uuid: "6c61d151-6033-4484-9efd-f447c92d0dfd",
            ic_lat: -272506,
            ic_lon: -530346,
            ic_city: "Alpestre",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.296,
            ic_february: 5.844,
            ic_march: 5.169,
            ic_april: 4.084,
            ic_may: 3.111,
            ic_june: 2.612,
            ic_july: 2.889,
            ic_august: 3.743,
            ic_september: 4.018,
            ic_october: 5.172,
            ic_november: 6.204,
            ic_december: 6.611,
            ic_yearly: 4.646,
          },
          {
            ic_uuid: "43347ec6-c8d0-4c82-b776-c9f7f65e05f0",
            ic_lat: -208635,
            ic_lon: -463882,
            ic_city: "Alpinópolis",
            ic_states: "MINAS GERAIS",
            ic_january: 5.685,
            ic_february: 5.805,
            ic_march: 4.957,
            ic_april: 4.737,
            ic_may: 4.143,
            ic_june: 3.954,
            ic_july: 4.207,
            ic_august: 5.079,
            ic_september: 5.224,
            ic_october: 5.507,
            ic_november: 5.383,
            ic_december: 5.66,
            ic_yearly: 5.028,
          },
          {
            ic_uuid: "88b1d894-4deb-4929-97f8-12b603d4dbef",
            ic_lat: -98672,
            ic_lon: -560871,
            ic_city: "Alta Floresta",
            ic_states: "MATO GROSSO",
            ic_january: 4.81,
            ic_february: 4.813,
            ic_march: 4.674,
            ic_april: 4.574,
            ic_may: 4.554,
            ic_june: 4.693,
            ic_july: 4.804,
            ic_august: 5.384,
            ic_september: 5.13,
            ic_october: 5.229,
            ic_november: 5.023,
            ic_december: 4.988,
            ic_yearly: 4.89,
          },
          {
            ic_uuid: "f1314025-752e-41eb-9c11-19df88afd14f",
            ic_lat: -119287,
            ic_lon: -619958,
            ic_city: "Alta Floresta D'Oeste",
            ic_states: "RONDÔNIA",
            ic_january: 4.414,
            ic_february: 4.386,
            ic_march: 4.494,
            ic_april: 4.536,
            ic_may: 4.106,
            ic_june: 4.323,
            ic_july: 4.457,
            ic_august: 4.981,
            ic_september: 4.986,
            ic_october: 5.163,
            ic_november: 5.008,
            ic_december: 4.76,
            ic_yearly: 4.635,
          },
          {
            ic_uuid: "827a0ec7-2e2d-4839-a5f0-55f68d5c4dcd",
            ic_lat: -20524,
            ic_lon: -490589,
            ic_city: "Altair",
            ic_states: "SÃO PAULO",
            ic_january: 5.811,
            ic_february: 6.038,
            ic_march: 5.219,
            ic_april: 4.93,
            ic_may: 4.221,
            ic_june: 3.945,
            ic_july: 4.177,
            ic_august: 5.114,
            ic_september: 5.091,
            ic_october: 5.577,
            ic_november: 5.837,
            ic_december: 6.178,
            ic_yearly: 5.178,
          },
          {
            ic_uuid: "b84249af-ddc7-480a-85a3-4278ebce9bcb",
            ic_lat: -32045,
            ic_lon: -522104,
            ic_city: "Altamira",
            ic_states: "PARÁ",
            ic_january: 4.137,
            ic_february: 4.315,
            ic_march: 4.323,
            ic_april: 4.331,
            ic_may: 4.35,
            ic_june: 4.567,
            ic_july: 4.511,
            ic_august: 4.794,
            ic_september: 4.667,
            ic_october: 4.535,
            ic_november: 4.546,
            ic_december: 4.162,
            ic_yearly: 4.437,
          },
          {
            ic_uuid: "e8a09e17-55e6-4f73-8842-1c1b1d277092",
            ic_lat: -41664,
            ic_lon: -45471,
            ic_city: "Altamira do Maranhão",
            ic_states: "MARANHÃO",
            ic_january: 4.576,
            ic_february: 4.771,
            ic_march: 4.86,
            ic_april: 4.895,
            ic_may: 4.785,
            ic_june: 4.841,
            ic_july: 5.052,
            ic_august: 5.482,
            ic_september: 5.795,
            ic_october: 5.368,
            ic_november: 4.973,
            ic_december: 4.765,
            ic_yearly: 5.014,
          },
          {
            ic_uuid: "5f1e4051-d1dc-420f-9461-6463089799df",
            ic_lat: -247988,
            ic_lon: -527133,
            ic_city: "Altamira do Paraná",
            ic_states: "PARANÁ",
            ic_january: 6.259,
            ic_february: 5.576,
            ic_march: 5.299,
            ic_april: 4.423,
            ic_may: 3.437,
            ic_june: 3.036,
            ic_july: 3.233,
            ic_august: 4.258,
            ic_september: 4.452,
            ic_october: 5.295,
            ic_november: 6.107,
            ic_december: 6.399,
            ic_yearly: 4.814,
          },
          {
            ic_uuid: "f72e893e-608c-4786-b8a1-992c9077d0a1",
            ic_lat: -69988,
            ic_lon: -397359,
            ic_city: "Altaneira",
            ic_states: "CEARÁ",
            ic_january: 5.774,
            ic_february: 5.707,
            ic_march: 5.766,
            ic_april: 5.411,
            ic_may: 5.214,
            ic_june: 5.084,
            ic_july: 5.397,
            ic_august: 6.139,
            ic_september: 6.624,
            ic_october: 6.552,
            ic_november: 6.473,
            ic_december: 6.083,
            ic_yearly: 5.852,
          },
          {
            ic_uuid: "d38d3b8e-cd16-460c-8647-a15c94a8bb5a",
            ic_lat: -212492,
            ic_lon: -461391,
            ic_city: "Alterosa",
            ic_states: "MINAS GERAIS",
            ic_january: 5.513,
            ic_february: 5.798,
            ic_march: 4.837,
            ic_april: 4.597,
            ic_may: 3.979,
            ic_june: 3.761,
            ic_july: 4.046,
            ic_august: 5.026,
            ic_september: 5.109,
            ic_october: 5.407,
            ic_november: 5.205,
            ic_december: 5.501,
            ic_yearly: 4.898,
          },
          {
            ic_uuid: "3d65c777-0b1f-4f64-b2a0-4b69c982be49",
            ic_lat: -84878,
            ic_lon: -360595,
            ic_city: "Altinho",
            ic_states: "PERNAMBUCO",
            ic_january: 5.916,
            ic_february: 5.817,
            ic_march: 5.881,
            ic_april: 5.21,
            ic_may: 4.377,
            ic_june: 3.934,
            ic_july: 4.015,
            ic_august: 4.67,
            ic_september: 5.441,
            ic_october: 5.752,
            ic_november: 6.202,
            ic_december: 6.047,
            ic_yearly: 5.272,
          },
          {
            ic_uuid: "ac2f58fb-a860-478c-927a-53886087fd8e",
            ic_lat: -210218,
            ic_lon: -473716,
            ic_city: "Altinópolis",
            ic_states: "SÃO PAULO",
            ic_january: 5.557,
            ic_february: 5.791,
            ic_march: 4.96,
            ic_april: 4.785,
            ic_may: 4.171,
            ic_june: 3.961,
            ic_july: 4.186,
            ic_august: 5.091,
            ic_september: 5.079,
            ic_october: 5.477,
            ic_november: 5.551,
            ic_december: 5.802,
            ic_yearly: 5.034,
          },
          {
            ic_uuid: "a4e76503-d29c-4062-8811-f1581f392081",
            ic_lat: -287773,
            ic_lon: -529897,
            ic_city: "Alto Alegre",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.352,
            ic_february: 5.925,
            ic_march: 5.076,
            ic_april: 3.961,
            ic_may: 3.017,
            ic_june: 2.447,
            ic_july: 2.736,
            ic_august: 3.532,
            ic_september: 3.859,
            ic_october: 5.036,
            ic_november: 6.293,
            ic_december: 6.691,
            ic_yearly: 4.577,
          },
          {
            ic_uuid: "b3b41b18-b6b0-4f54-8894-99ae753d0ec2",
            ic_lat: 29882,
            ic_lon: -613076,
            ic_city: "Alto Alegre",
            ic_states: "RORAIMA",
            ic_january: 4.535,
            ic_february: 4.727,
            ic_march: 4.945,
            ic_april: 4.761,
            ic_may: 4.389,
            ic_june: 4.596,
            ic_july: 4.748,
            ic_august: 5.148,
            ic_september: 5.444,
            ic_october: 5.304,
            ic_november: 4.98,
            ic_december: 4.63,
            ic_yearly: 4.85,
          },
          {
            ic_uuid: "ce04ccd8-146c-4e48-90aa-b9252e009af3",
            ic_lat: -21581,
            ic_lon: -501639,
            ic_city: "Alto Alegre",
            ic_states: "SÃO PAULO",
            ic_january: 5.886,
            ic_february: 5.955,
            ic_march: 5.354,
            ic_april: 4.821,
            ic_may: 3.967,
            ic_june: 3.661,
            ic_july: 3.867,
            ic_august: 4.802,
            ic_september: 4.916,
            ic_october: 5.616,
            ic_november: 6.022,
            ic_december: 6.3,
            ic_yearly: 5.097,
          },
          {
            ic_uuid: "ed1b9536-3caf-491d-b802-46b0eb0d2f79",
            ic_lat: -42134,
            ic_lon: -444464,
            ic_city: "Alto Alegre do Maranhão",
            ic_states: "MARANHÃO",
            ic_january: 4.742,
            ic_february: 4.878,
            ic_march: 4.953,
            ic_april: 4.85,
            ic_may: 4.845,
            ic_june: 5.081,
            ic_july: 5.353,
            ic_august: 5.951,
            ic_september: 6.303,
            ic_october: 5.923,
            ic_november: 5.483,
            ic_december: 5.118,
            ic_yearly: 5.29,
          },
          {
            ic_uuid: "5caa3f36-e38e-46e1-980c-cc8adb99e855",
            ic_lat: -36673,
            ic_lon: -458425,
            ic_city: "Alto Alegre do Pindaré",
            ic_states: "MARANHÃO",
            ic_january: 4.531,
            ic_february: 4.778,
            ic_march: 4.853,
            ic_april: 4.847,
            ic_may: 4.649,
            ic_june: 4.816,
            ic_july: 4.916,
            ic_august: 5.316,
            ic_september: 5.575,
            ic_october: 5.197,
            ic_november: 4.895,
            ic_december: 4.739,
            ic_yearly: 4.926,
          },
          {
            ic_uuid: "6369a739-99a6-42b8-a551-8ff4b931b372",
            ic_lat: -121405,
            ic_lon: -618379,
            ic_city: "Alto Alegre dos Parecis",
            ic_states: "RONDÔNIA",
            ic_january: 4.455,
            ic_february: 4.395,
            ic_march: 4.426,
            ic_april: 4.487,
            ic_may: 4.087,
            ic_june: 4.311,
            ic_july: 4.454,
            ic_august: 4.996,
            ic_september: 5.019,
            ic_october: 5.132,
            ic_november: 4.964,
            ic_december: 4.762,
            ic_yearly: 4.624,
          },
          {
            ic_uuid: "359db8c2-e26e-4a34-909a-e2594a35dcc2",
            ic_lat: -173158,
            ic_lon: -532185,
            ic_city: "Alto Araguaia",
            ic_states: "MATO GROSSO",
            ic_january: 5.472,
            ic_february: 5.571,
            ic_march: 5.141,
            ic_april: 5.095,
            ic_may: 4.633,
            ic_june: 4.403,
            ic_july: 4.547,
            ic_august: 5.414,
            ic_september: 5.314,
            ic_october: 5.249,
            ic_november: 5.417,
            ic_december: 5.598,
            ic_yearly: 5.154,
          },
          {
            ic_uuid: "8f81900d-5d0a-4106-ac83-aa11d84d4384",
            ic_lat: -274338,
            ic_lon: -519049,
            ic_city: "Alto Bela Vista",
            ic_states: "SANTA CATARINA",
            ic_january: 6.309,
            ic_february: 5.736,
            ic_march: 5.108,
            ic_april: 4.043,
            ic_may: 3.069,
            ic_june: 2.529,
            ic_july: 2.858,
            ic_august: 3.719,
            ic_september: 3.973,
            ic_october: 5.066,
            ic_november: 6.262,
            ic_december: 6.58,
            ic_yearly: 4.604,
          },
          {
            ic_uuid: "716e6735-15d2-4380-b053-2a5c7f24da86",
            ic_lat: -116721,
            ic_lon: -513776,
            ic_city: "Alto Boa Vista",
            ic_states: "MATO GROSSO",
            ic_january: 5.128,
            ic_february: 5.211,
            ic_march: 4.995,
            ic_april: 4.823,
            ic_may: 4.895,
            ic_june: 4.803,
            ic_july: 5.072,
            ic_august: 5.624,
            ic_september: 5.421,
            ic_october: 5.273,
            ic_november: 5.188,
            ic_december: 5.333,
            ic_yearly: 5.147,
          },
          {
            ic_uuid: "dda86e70-b396-4675-bb29-ff8bbbf1f07c",
            ic_lat: -204315,
            ic_lon: -418741,
            ic_city: "Alto Caparaó",
            ic_states: "MINAS GERAIS",
            ic_january: 5.571,
            ic_february: 5.789,
            ic_march: 4.797,
            ic_april: 4.332,
            ic_may: 3.822,
            ic_june: 3.616,
            ic_july: 3.785,
            ic_august: 4.47,
            ic_september: 4.863,
            ic_october: 4.933,
            ic_november: 4.673,
            ic_december: 5.381,
            ic_yearly: 4.669,
          },
          {
            ic_uuid: "f0b7f9ad-b9c3-4d46-8411-ae57fd34e074",
            ic_lat: -52931,
            ic_lon: -367616,
            ic_city: "Alto do Rodrigues",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.844,
            ic_february: 5.905,
            ic_march: 5.866,
            ic_april: 5.52,
            ic_may: 5.215,
            ic_june: 4.804,
            ic_july: 5.058,
            ic_august: 5.692,
            ic_september: 6.151,
            ic_october: 6.371,
            ic_november: 6.395,
            ic_december: 6.049,
            ic_yearly: 5.739,
          },
          {
            ic_uuid: "fb30f0ea-da72-4fbf-9538-ecefced3bdf3",
            ic_lat: -293802,
            ic_lon: -513135,
            ic_city: "Alto Feliz",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.085,
            ic_february: 5.59,
            ic_march: 4.715,
            ic_april: 3.779,
            ic_may: 2.808,
            ic_june: 2.356,
            ic_july: 2.59,
            ic_august: 3.283,
            ic_september: 3.544,
            ic_october: 4.577,
            ic_november: 6.002,
            ic_december: 6.379,
            ic_yearly: 4.309,
          },
          {
            ic_uuid: "60ea93de-38d3-4eb2-84ee-a3587c24ecca",
            ic_lat: -169467,
            ic_lon: -535276,
            ic_city: "Alto Garças",
            ic_states: "MATO GROSSO",
            ic_january: 5.498,
            ic_february: 5.526,
            ic_march: 5.1,
            ic_april: 5.037,
            ic_may: 4.646,
            ic_june: 4.393,
            ic_july: 4.569,
            ic_august: 5.329,
            ic_september: 5.276,
            ic_october: 5.285,
            ic_november: 5.457,
            ic_december: 5.635,
            ic_yearly: 5.146,
          },
          {
            ic_uuid: "6342cd3a-09af-43d1-b281-30859a7e8864",
            ic_lat: -141985,
            ic_lon: -493368,
            ic_city: "Alto Horizonte",
            ic_states: "GOIÁS",
            ic_january: 5.471,
            ic_february: 5.65,
            ic_march: 5.319,
            ic_april: 5.192,
            ic_may: 4.991,
            ic_june: 4.723,
            ic_july: 5.127,
            ic_august: 5.746,
            ic_september: 5.652,
            ic_october: 5.708,
            ic_november: 5.275,
            ic_december: 5.447,
            ic_yearly: 5.359,
          },
          {
            ic_uuid: "2ae179f7-ba1e-4ab9-bdd7-94c42f99cdaf",
            ic_lat: -204213,
            ic_lon: -419673,
            ic_city: "Alto Jequitibá",
            ic_states: "MINAS GERAIS",
            ic_january: 5.609,
            ic_february: 5.862,
            ic_march: 4.894,
            ic_april: 4.41,
            ic_may: 3.788,
            ic_june: 3.592,
            ic_july: 3.761,
            ic_august: 4.434,
            ic_september: 4.751,
            ic_october: 4.882,
            ic_november: 4.656,
            ic_december: 5.377,
            ic_yearly: 4.668,
          },
          {
            ic_uuid: "b49a47f9-71b0-4279-a07d-9c090e07cce5",
            ic_lat: -52567,
            ic_lon: -422099,
            ic_city: "Alto Longá",
            ic_states: "PIAUÍ",
            ic_january: 5.208,
            ic_february: 5.306,
            ic_march: 5.357,
            ic_april: 5.143,
            ic_may: 5.156,
            ic_june: 5.299,
            ic_july: 5.601,
            ic_august: 6.204,
            ic_september: 6.562,
            ic_october: 6.463,
            ic_november: 6.242,
            ic_december: 5.804,
            ic_yearly: 5.696,
          },
          {
            ic_uuid: "58906292-c2be-4eba-ac22-08e9d8ab135e",
            ic_lat: -145141,
            ic_lon: -564781,
            ic_city: "Alto Paraguai",
            ic_states: "MATO GROSSO",
            ic_january: 5.225,
            ic_february: 5.187,
            ic_march: 5.027,
            ic_april: 4.889,
            ic_may: 4.517,
            ic_june: 4.528,
            ic_july: 4.714,
            ic_august: 5.493,
            ic_september: 5.156,
            ic_october: 5.249,
            ic_november: 5.412,
            ic_december: 5.479,
            ic_yearly: 5.073,
          },
          {
            ic_uuid: "21f09f50-0eab-4e97-92bf-d92c0130783a",
            ic_lat: -235086,
            ic_lon: -53729,
            ic_city: "Alto Paraíso",
            ic_states: "PARANÁ",
            ic_january: 6.183,
            ic_february: 5.95,
            ic_march: 5.418,
            ic_april: 4.469,
            ic_may: 3.548,
            ic_june: 3.184,
            ic_july: 3.369,
            ic_august: 4.318,
            ic_september: 4.592,
            ic_october: 5.429,
            ic_november: 6.154,
            ic_december: 6.581,
            ic_yearly: 4.933,
          },
          {
            ic_uuid: "bccf8ffb-cd84-4ed8-b3cf-f36b143c6555",
            ic_lat: -97147,
            ic_lon: -633193,
            ic_city: "Alto Paraíso",
            ic_states: "RONDÔNIA",
            ic_january: 4.301,
            ic_february: 4.376,
            ic_march: 4.326,
            ic_april: 4.375,
            ic_may: 4.015,
            ic_june: 4.324,
            ic_july: 4.422,
            ic_august: 4.854,
            ic_september: 4.855,
            ic_october: 5.026,
            ic_november: 4.768,
            ic_december: 4.519,
            ic_yearly: 4.514,
          },
          {
            ic_uuid: "5a074c89-d5a4-41d4-915f-701cf8c30e09",
            ic_lat: -1414,
            ic_lon: -475177,
            ic_city: "Alto Paraíso de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.238,
            ic_february: 5.38,
            ic_march: 4.924,
            ic_april: 4.993,
            ic_may: 4.99,
            ic_june: 5.032,
            ic_july: 5.472,
            ic_august: 6.22,
            ic_september: 5.858,
            ic_october: 5.354,
            ic_november: 4.675,
            ic_december: 5.158,
            ic_yearly: 5.275,
          },
          {
            ic_uuid: "36b6aef1-10de-434d-948b-f6f615878e72",
            ic_lat: -231317,
            ic_lon: -523193,
            ic_city: "Alto Paraná",
            ic_states: "PARANÁ",
            ic_january: 5.987,
            ic_february: 5.886,
            ic_march: 5.426,
            ic_april: 4.631,
            ic_may: 3.661,
            ic_june: 3.324,
            ic_july: 3.565,
            ic_august: 4.514,
            ic_september: 4.725,
            ic_october: 5.43,
            ic_november: 6.163,
            ic_december: 6.416,
            ic_yearly: 4.977,
          },
          {
            ic_uuid: "0b804582-fbe1-4b82-a6c7-e51f3dc8c357",
            ic_lat: -91103,
            ic_lon: -459336,
            ic_city: "Alto Parnaíba",
            ic_states: "MARANHÃO",
            ic_january: 5.344,
            ic_february: 5.246,
            ic_march: 5.111,
            ic_april: 5.172,
            ic_may: 5.286,
            ic_june: 5.306,
            ic_july: 5.459,
            ic_august: 6.174,
            ic_september: 6.401,
            ic_october: 5.812,
            ic_november: 5.376,
            ic_december: 5.408,
            ic_yearly: 5.508,
          },
          {
            ic_uuid: "05c7c226-db44-446f-89e4-fb420ab6843c",
            ic_lat: -240229,
            ic_lon: -534405,
            ic_city: "Alto Piquiri",
            ic_states: "PARANÁ",
            ic_january: 6.163,
            ic_february: 5.849,
            ic_march: 5.358,
            ic_april: 4.459,
            ic_may: 3.531,
            ic_june: 3.109,
            ic_july: 3.309,
            ic_august: 4.319,
            ic_september: 4.545,
            ic_october: 5.367,
            ic_november: 6.155,
            ic_december: 6.471,
            ic_yearly: 4.886,
          },
          {
            ic_uuid: "9a4445dd-c8ce-49db-b363-d667a480b537",
            ic_lat: -210286,
            ic_lon: -434071,
            ic_city: "Alto Rio Doce",
            ic_states: "MINAS GERAIS",
            ic_january: 5.51,
            ic_february: 5.848,
            ic_march: 4.876,
            ic_april: 4.279,
            ic_may: 3.668,
            ic_june: 3.512,
            ic_july: 3.689,
            ic_august: 4.56,
            ic_september: 4.816,
            ic_october: 5.014,
            ic_november: 4.715,
            ic_december: 5.515,
            ic_yearly: 4.667,
          },
          {
            ic_uuid: "cd8b2423-3507-4d9e-b553-b58df8efc58d",
            ic_lat: -190578,
            ic_lon: -410168,
            ic_city: "Alto Rio Novo",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.993,
            ic_february: 6.101,
            ic_march: 5.19,
            ic_april: 4.546,
            ic_may: 3.874,
            ic_june: 3.552,
            ic_july: 3.668,
            ic_august: 4.303,
            ic_september: 4.851,
            ic_october: 5.057,
            ic_november: 4.926,
            ic_december: 5.724,
            ic_yearly: 4.816,
          },
          {
            ic_uuid: "376a0f08-2c3a-4cd3-acac-1bfe8bf78916",
            ic_lat: -55093,
            ic_lon: -382746,
            ic_city: "Alto Santo",
            ic_states: "CEARÁ",
            ic_january: 5.748,
            ic_february: 5.882,
            ic_march: 5.902,
            ic_april: 5.551,
            ic_may: 5.26,
            ic_june: 5.03,
            ic_july: 5.368,
            ic_august: 6.041,
            ic_september: 6.446,
            ic_october: 6.587,
            ic_november: 6.499,
            ic_december: 6.019,
            ic_yearly: 5.861,
          },
          {
            ic_uuid: "d5041f8f-5c16-4773-b7e8-606ac3771e6f",
            ic_lat: -178302,
            ic_lon: -532824,
            ic_city: "Alto Taquari",
            ic_states: "MATO GROSSO",
            ic_january: 5.426,
            ic_february: 5.512,
            ic_march: 5.077,
            ic_april: 4.912,
            ic_may: 4.492,
            ic_june: 4.285,
            ic_july: 4.435,
            ic_august: 5.302,
            ic_september: 5.222,
            ic_october: 5.188,
            ic_november: 5.351,
            ic_december: 5.603,
            ic_yearly: 5.067,
          },
          {
            ic_uuid: "7853764e-7387-4ae6-90d2-d36185b8fc55",
            ic_lat: -238764,
            ic_lon: -538963,
            ic_city: "Altônia",
            ic_states: "PARANÁ",
            ic_january: 6.207,
            ic_february: 5.866,
            ic_march: 5.331,
            ic_april: 4.423,
            ic_may: 3.48,
            ic_june: 3.089,
            ic_july: 3.277,
            ic_august: 4.3,
            ic_september: 4.547,
            ic_october: 5.368,
            ic_november: 6.12,
            ic_december: 6.482,
            ic_yearly: 4.874,
          },
          {
            ic_uuid: "b351b900-ee57-4106-95ad-e21b210c7fff",
            ic_lat: -50393,
            ic_lon: -424615,
            ic_city: "Altos",
            ic_states: "PIAUÍ",
            ic_january: 5.151,
            ic_february: 5.249,
            ic_march: 5.324,
            ic_april: 5.075,
            ic_may: 5.103,
            ic_june: 5.27,
            ic_july: 5.567,
            ic_august: 6.184,
            ic_september: 6.539,
            ic_october: 6.43,
            ic_november: 6.184,
            ic_december: 5.681,
            ic_yearly: 5.646,
          },
          {
            ic_uuid: "3d7cde88-2596-4d3d-b9a5-a549a2d7009a",
            ic_lat: -23531,
            ic_lon: -47255,
            ic_city: "Alumínio",
            ic_states: "SÃO PAULO",
            ic_january: 5.505,
            ic_february: 5.74,
            ic_march: 5.036,
            ic_april: 4.443,
            ic_may: 3.634,
            ic_june: 3.373,
            ic_july: 3.481,
            ic_august: 4.48,
            ic_september: 4.605,
            ic_october: 5.174,
            ic_november: 5.643,
            ic_december: 6.096,
            ic_yearly: 4.768,
          },
          {
            ic_uuid: "91479d5a-8869-4aac-bf32-24399ace3131",
            ic_lat: -32277,
            ic_lon: -648011,
            ic_city: "Alvarães",
            ic_states: "AMAZONAS",
            ic_january: 4.312,
            ic_february: 4.573,
            ic_march: 4.518,
            ic_april: 4.301,
            ic_may: 3.999,
            ic_june: 4.276,
            ic_july: 4.364,
            ic_august: 4.963,
            ic_september: 5.035,
            ic_october: 5.017,
            ic_november: 4.775,
            ic_december: 4.503,
            ic_yearly: 4.553,
          },
          {
            ic_uuid: "3a983f9d-cfa6-4227-987b-4e103249aae3",
            ic_lat: -194179,
            ic_lon: -417321,
            ic_city: "Alvarenga",
            ic_states: "MINAS GERAIS",
            ic_january: 5.73,
            ic_february: 5.871,
            ic_march: 4.916,
            ic_april: 4.441,
            ic_may: 3.736,
            ic_june: 3.556,
            ic_july: 3.647,
            ic_august: 4.305,
            ic_september: 4.77,
            ic_october: 4.869,
            ic_november: 4.763,
            ic_december: 5.517,
            ic_yearly: 4.677,
          },
          {
            ic_uuid: "5eac6037-85a3-49e5-ba69-af77414565ea",
            ic_lat: -20321,
            ic_lon: -499109,
            ic_city: "Álvares Florence",
            ic_states: "SÃO PAULO",
            ic_january: 5.844,
            ic_february: 5.934,
            ic_march: 5.32,
            ic_april: 4.92,
            ic_may: 4.225,
            ic_june: 3.933,
            ic_july: 4.21,
            ic_august: 5.069,
            ic_september: 5.114,
            ic_october: 5.655,
            ic_november: 5.927,
            ic_december: 6.181,
            ic_yearly: 5.194,
          },
          {
            ic_uuid: "e8bb9d23-25c9-4871-add0-832b36a4ed86",
            ic_lat: -220769,
            ic_lon: -514727,
            ic_city: "Álvares Machado",
            ic_states: "SÃO PAULO",
            ic_january: 5.989,
            ic_february: 6.013,
            ic_march: 5.468,
            ic_april: 4.821,
            ic_may: 3.929,
            ic_june: 3.594,
            ic_july: 3.827,
            ic_august: 4.769,
            ic_september: 4.874,
            ic_october: 5.566,
            ic_november: 6.106,
            ic_december: 6.478,
            ic_yearly: 5.119,
          },
          {
            ic_uuid: "a8e23984-3657-4b95-a9c3-ed011067f0c3",
            ic_lat: -22079,
            ic_lon: -497207,
            ic_city: "Álvaro de Carvalho",
            ic_states: "SÃO PAULO",
            ic_january: 5.711,
            ic_february: 5.867,
            ic_march: 5.278,
            ic_april: 4.787,
            ic_may: 3.896,
            ic_june: 3.627,
            ic_july: 3.826,
            ic_august: 4.783,
            ic_september: 4.856,
            ic_october: 5.587,
            ic_november: 5.964,
            ic_december: 6.249,
            ic_yearly: 5.036,
          },
          {
            ic_uuid: "e30f2863-808c-4242-8de5-85a706c474ba",
            ic_lat: -224439,
            ic_lon: -497627,
            ic_city: "Alvinlândia",
            ic_states: "SÃO PAULO",
            ic_january: 5.635,
            ic_february: 5.805,
            ic_march: 5.2,
            ic_april: 4.728,
            ic_may: 3.823,
            ic_june: 3.521,
            ic_july: 3.767,
            ic_august: 4.76,
            ic_september: 4.811,
            ic_october: 5.486,
            ic_november: 5.847,
            ic_december: 6.188,
            ic_yearly: 4.964,
          },
          {
            ic_uuid: "c345549e-00bb-4681-a300-16b804300c59",
            ic_lat: -201102,
            ic_lon: -430539,
            ic_city: "Alvinópolis",
            ic_states: "MINAS GERAIS",
            ic_january: 5.5,
            ic_february: 5.783,
            ic_march: 4.809,
            ic_april: 4.408,
            ic_may: 3.773,
            ic_june: 3.628,
            ic_july: 3.833,
            ic_august: 4.6,
            ic_september: 4.894,
            ic_october: 4.931,
            ic_november: 4.668,
            ic_december: 5.338,
            ic_yearly: 4.68,
          },
          {
            ic_uuid: "728cae2d-5e22-4cc6-8131-1fb9d51791a8",
            ic_lat: -299919,
            ic_lon: -510813,
            ic_city: "Alvorada",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.369,
            ic_february: 5.761,
            ic_march: 4.81,
            ic_april: 3.78,
            ic_may: 2.765,
            ic_june: 2.303,
            ic_july: 2.512,
            ic_august: 3.172,
            ic_september: 3.561,
            ic_october: 4.729,
            ic_november: 6.187,
            ic_december: 6.612,
            ic_yearly: 4.38,
          },
          {
            ic_uuid: "23230ba5-20c3-4f39-aef0-cbb5af00de1c",
            ic_lat: -124789,
            ic_lon: -491253,
            ic_city: "Alvorada",
            ic_states: "TOCANTINS",
            ic_january: 5.424,
            ic_february: 5.437,
            ic_march: 5.154,
            ic_april: 5.143,
            ic_may: 5.013,
            ic_june: 4.88,
            ic_july: 5.188,
            ic_august: 5.719,
            ic_september: 5.781,
            ic_october: 5.583,
            ic_november: 5.211,
            ic_december: 5.357,
            ic_yearly: 5.324,
          },
          {
            ic_uuid: "a430bc59-5a3c-42ef-a65d-d163a53fada0",
            ic_lat: -187338,
            ic_lon: -433642,
            ic_city: "Alvorada de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.68,
            ic_february: 5.963,
            ic_march: 4.961,
            ic_april: 4.515,
            ic_may: 3.959,
            ic_june: 3.774,
            ic_july: 3.948,
            ic_august: 4.714,
            ic_september: 5.108,
            ic_october: 5.244,
            ic_november: 4.799,
            ic_december: 5.439,
            ic_yearly: 4.842,
          },
          {
            ic_uuid: "e53dd45b-aabe-4dde-8a7b-1b312c68b118",
            ic_lat: -84442,
            ic_lon: -438652,
            ic_city: "Alvorada do Gurguéia",
            ic_states: "PIAUÍ",
            ic_january: 5.537,
            ic_february: 5.313,
            ic_march: 5.302,
            ic_april: 5.367,
            ic_may: 5.328,
            ic_june: 5.285,
            ic_july: 5.57,
            ic_august: 6.184,
            ic_september: 6.552,
            ic_october: 6.192,
            ic_november: 5.805,
            ic_december: 5.573,
            ic_yearly: 5.667,
          },
          {
            ic_uuid: "b668f1da-4a5c-42dc-9839-dd2a54a1eab6",
            ic_lat: -144844,
            ic_lon: -464943,
            ic_city: "Alvorada do Norte",
            ic_states: "GOIÁS",
            ic_january: 6.082,
            ic_february: 6.127,
            ic_march: 5.568,
            ic_april: 5.492,
            ic_may: 5.045,
            ic_june: 4.867,
            ic_july: 5.214,
            ic_august: 5.871,
            ic_september: 5.962,
            ic_october: 5.876,
            ic_november: 5.401,
            ic_december: 5.953,
            ic_yearly: 5.621,
          },
          {
            ic_uuid: "46300433-a0a5-4123-a946-ea657333f784",
            ic_lat: -227817,
            ic_lon: -512301,
            ic_city: "Alvorada do Sul",
            ic_states: "PARANÁ",
            ic_january: 6.059,
            ic_february: 6.006,
            ic_march: 5.457,
            ic_april: 4.771,
            ic_may: 3.777,
            ic_june: 3.405,
            ic_july: 3.649,
            ic_august: 4.569,
            ic_september: 4.664,
            ic_october: 5.475,
            ic_november: 6.166,
            ic_december: 6.508,
            ic_yearly: 5.042,
          },
          {
            ic_uuid: "87e1a771-dbc2-46f8-b8f2-e83550265721",
            ic_lat: -113467,
            ic_lon: -622852,
            ic_city: "Alvorada D'Oeste",
            ic_states: "RONDÔNIA",
            ic_january: 4.463,
            ic_february: 4.467,
            ic_march: 4.48,
            ic_april: 4.547,
            ic_may: 4.068,
            ic_june: 4.343,
            ic_july: 4.484,
            ic_august: 4.924,
            ic_september: 4.905,
            ic_october: 5.079,
            ic_november: 5.0,
            ic_december: 4.738,
            ic_yearly: 4.625,
          },
          {
            ic_uuid: "6f805987-9fc7-49c9-a546-df045280f048",
            ic_lat: 36531,
            ic_lon: -614185,
            ic_city: "Amajari",
            ic_states: "RORAIMA",
            ic_january: 4.863,
            ic_february: 4.997,
            ic_march: 5.21,
            ic_april: 5.124,
            ic_may: 4.742,
            ic_june: 4.83,
            ic_july: 4.977,
            ic_august: 5.432,
            ic_september: 5.755,
            ic_october: 5.535,
            ic_november: 5.237,
            ic_december: 4.844,
            ic_yearly: 5.129,
          },
          {
            ic_uuid: "a8e935c7-f9d4-490d-9500-9d882f831dfc",
            ic_lat: -231062,
            ic_lon: -552258,
            ic_city: "Amambai",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.915,
            ic_february: 5.584,
            ic_march: 5.227,
            ic_april: 4.364,
            ic_may: 3.477,
            ic_june: 3.171,
            ic_july: 3.307,
            ic_august: 4.336,
            ic_september: 4.589,
            ic_october: 5.291,
            ic_november: 5.87,
            ic_december: 6.414,
            ic_yearly: 4.795,
          },
          {
            ic_uuid: "98466092-53b8-4957-8f69-4d123a5c3313",
            ic_lat: 20523,
            ic_lon: -50796,
            ic_city: "Amapá",
            ic_states: "AMAPÁ",
            ic_january: 4.256,
            ic_february: 4.117,
            ic_march: 4.11,
            ic_april: 4.171,
            ic_may: 4.581,
            ic_june: 4.833,
            ic_july: 5.105,
            ic_august: 5.477,
            ic_september: 5.544,
            ic_october: 5.44,
            ic_november: 5.211,
            ic_december: 4.655,
            ic_yearly: 4.792,
          },
          {
            ic_uuid: "c013d98f-1cef-4a0e-8e0b-1b06cd0a37a1",
            ic_lat: -16756,
            ic_lon: -460028,
            ic_city: "Amapá do Maranhão",
            ic_states: "MARANHÃO",
            ic_january: 4.557,
            ic_february: 4.361,
            ic_march: 4.242,
            ic_april: 4.267,
            ic_may: 4.417,
            ic_june: 4.712,
            ic_july: 4.862,
            ic_august: 5.414,
            ic_september: 5.69,
            ic_october: 5.376,
            ic_november: 5.164,
            ic_december: 4.743,
            ic_yearly: 4.817,
          },
          {
            ic_uuid: "37c728a0-602d-44d2-b97f-2724a635533f",
            ic_lat: -230947,
            ic_lon: -52787,
            ic_city: "Amaporã",
            ic_states: "PARANÁ",
            ic_january: 5.964,
            ic_february: 5.87,
            ic_march: 5.385,
            ic_april: 4.548,
            ic_may: 3.638,
            ic_june: 3.315,
            ic_july: 3.511,
            ic_august: 4.458,
            ic_september: 4.696,
            ic_october: 5.434,
            ic_november: 6.139,
            ic_december: 6.421,
            ic_yearly: 4.948,
          },
          {
            ic_uuid: "414fbd43-eb6c-4933-a68e-b4f0f841f3a8",
            ic_lat: -83773,
            ic_lon: -354503,
            ic_city: "Amaraji",
            ic_states: "PERNAMBUCO",
            ic_january: 5.54,
            ic_february: 5.539,
            ic_march: 5.632,
            ic_april: 4.872,
            ic_may: 4.166,
            ic_june: 3.763,
            ic_july: 3.772,
            ic_august: 4.353,
            ic_september: 5.107,
            ic_october: 5.412,
            ic_november: 5.821,
            ic_december: 5.826,
            ic_yearly: 4.983,
          },
          {
            ic_uuid: "caffd954-30bf-4134-b91e-72f9ae497222",
            ic_lat: -308761,
            ic_lon: -522514,
            ic_city: "Amaral Ferrador",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.291,
            ic_february: 5.669,
            ic_march: 4.753,
            ic_april: 3.696,
            ic_may: 2.71,
            ic_june: 2.271,
            ic_july: 2.456,
            ic_august: 3.094,
            ic_september: 3.573,
            ic_october: 4.734,
            ic_november: 6.107,
            ic_december: 6.665,
            ic_yearly: 4.335,
          },
          {
            ic_uuid: "91c93aa0-6d84-4410-9318-d67d1008f4d4",
            ic_lat: -139279,
            ic_lon: -492983,
            ic_city: "Amaralina",
            ic_states: "GOIÁS",
            ic_january: 5.412,
            ic_february: 5.584,
            ic_march: 5.135,
            ic_april: 5.213,
            ic_may: 4.985,
            ic_june: 4.689,
            ic_july: 5.114,
            ic_august: 5.9,
            ic_september: 5.668,
            ic_october: 5.58,
            ic_november: 5.21,
            ic_december: 5.381,
            ic_yearly: 5.323,
          },
          {
            ic_uuid: "6f1799f4-7db9-46ae-a400-da6d8bcb1367",
            ic_lat: -62449,
            ic_lon: -428508,
            ic_city: "Amarante",
            ic_states: "PIAUÍ",
            ic_january: 5.062,
            ic_february: 5.214,
            ic_march: 5.204,
            ic_april: 5.137,
            ic_may: 5.263,
            ic_june: 5.406,
            ic_july: 5.66,
            ic_august: 6.155,
            ic_september: 6.56,
            ic_october: 6.272,
            ic_november: 6.001,
            ic_december: 5.51,
            ic_yearly: 5.62,
          },
          {
            ic_uuid: "53f8345a-5087-4c98-b990-175381ff229f",
            ic_lat: -55695,
            ic_lon: -467477,
            ic_city: "Amarante do Maranhão",
            ic_states: "MARANHÃO",
            ic_january: 4.592,
            ic_february: 4.771,
            ic_march: 4.749,
            ic_april: 4.843,
            ic_may: 4.835,
            ic_june: 5.122,
            ic_july: 5.353,
            ic_august: 5.762,
            ic_september: 5.744,
            ic_october: 5.308,
            ic_november: 4.958,
            ic_december: 4.798,
            ic_yearly: 5.07,
          },
          {
            ic_uuid: "fdf286f5-56a4-4d96-abb6-c94a81c1742f",
            ic_lat: -130319,
            ic_lon: -395972,
            ic_city: "Amargosa",
            ic_states: "BAHIA",
            ic_january: 5.894,
            ic_february: 5.933,
            ic_march: 5.637,
            ic_april: 4.612,
            ic_may: 4.05,
            ic_june: 3.66,
            ic_july: 3.849,
            ic_august: 4.37,
            ic_september: 5.174,
            ic_october: 5.317,
            ic_november: 5.499,
            ic_december: 5.934,
            ic_yearly: 4.994,
          },
          {
            ic_uuid: "8c2e0c52-b131-4ce5-883d-b69129b158e7",
            ic_lat: -33632,
            ic_lon: -681995,
            ic_city: "Amaturá",
            ic_states: "AMAZONAS",
            ic_january: 4.346,
            ic_february: 4.71,
            ic_march: 4.511,
            ic_april: 4.362,
            ic_may: 3.972,
            ic_june: 4.163,
            ic_july: 4.114,
            ic_august: 4.852,
            ic_september: 5.048,
            ic_october: 5.016,
            ic_november: 4.772,
            ic_december: 4.457,
            ic_yearly: 4.527,
          },
          {
            ic_uuid: "fb66356b-45d6-4b33-8593-151f3818d11c",
            ic_lat: -123976,
            ic_lon: -387535,
            ic_city: "Amélia Rodrigues",
            ic_states: "BAHIA",
            ic_january: 6.082,
            ic_february: 5.88,
            ic_march: 5.688,
            ic_april: 4.655,
            ic_may: 3.982,
            ic_june: 3.621,
            ic_july: 3.824,
            ic_august: 4.322,
            ic_september: 5.212,
            ic_october: 5.493,
            ic_november: 5.746,
            ic_december: 6.128,
            ic_yearly: 5.053,
          },
          {
            ic_uuid: "6404ef76-1f50-472d-a587-470d9138d609",
            ic_lat: -114434,
            ic_lon: -414393,
            ic_city: "América Dourada",
            ic_states: "BAHIA",
            ic_january: 6.343,
            ic_february: 6.144,
            ic_march: 5.911,
            ic_april: 5.07,
            ic_may: 4.544,
            ic_june: 4.42,
            ic_july: 4.576,
            ic_august: 5.306,
            ic_september: 6.153,
            ic_october: 6.37,
            ic_november: 6.211,
            ic_december: 6.366,
            ic_yearly: 5.618,
          },
          {
            ic_uuid: "4257c7fd-3f7e-4da4-834e-5e4a779b4886",
            ic_lat: -227378,
            ic_lon: -473335,
            ic_city: "Americana",
            ic_states: "SÃO PAULO",
            ic_january: 5.626,
            ic_february: 5.762,
            ic_march: 5.118,
            ic_april: 4.607,
            ic_may: 3.804,
            ic_june: 3.558,
            ic_july: 3.719,
            ic_august: 4.675,
            ic_september: 4.855,
            ic_october: 5.445,
            ic_november: 5.679,
            ic_december: 6.108,
            ic_yearly: 4.913,
          },
          {
            ic_uuid: "7caefd99-7f61-4dd1-8f17-b154829c2b27",
            ic_lat: -16253,
            ic_lon: -499799,
            ic_city: "Americano do Brasil",
            ic_states: "GOIÁS",
            ic_january: 5.423,
            ic_february: 5.502,
            ic_march: 5.148,
            ic_april: 5.065,
            ic_may: 4.84,
            ic_june: 4.629,
            ic_july: 4.834,
            ic_august: 5.692,
            ic_september: 5.491,
            ic_october: 5.523,
            ic_november: 5.264,
            ic_december: 5.436,
            ic_yearly: 5.237,
          },
          {
            ic_uuid: "3feb098f-bc0c-4587-a53e-331f423fcb5d",
            ic_lat: -217292,
            ic_lon: -481151,
            ic_city: "Américo Brasiliense",
            ic_states: "SÃO PAULO",
            ic_january: 5.661,
            ic_february: 5.932,
            ic_march: 5.147,
            ic_april: 4.72,
            ic_may: 4.021,
            ic_june: 3.771,
            ic_july: 3.978,
            ic_august: 4.832,
            ic_september: 4.948,
            ic_october: 5.54,
            ic_november: 5.778,
            ic_december: 5.998,
            ic_yearly: 5.027,
          },
          {
            ic_uuid: "06786296-8d7b-4861-80bc-cd848b055ca0",
            ic_lat: -20299,
            ic_lon: -497319,
            ic_city: "Américo de Campos",
            ic_states: "SÃO PAULO",
            ic_january: 5.849,
            ic_february: 5.889,
            ic_march: 5.279,
            ic_april: 4.935,
            ic_may: 4.237,
            ic_june: 3.934,
            ic_july: 4.197,
            ic_august: 5.087,
            ic_september: 5.104,
            ic_october: 5.646,
            ic_november: 5.892,
            ic_december: 6.164,
            ic_yearly: 5.184,
          },
          {
            ic_uuid: "d23c5dac-c6d7-4d6b-9f01-b9b022b7e9de",
            ic_lat: -273611,
            ic_lon: -531835,
            ic_city: "Ametista do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.339,
            ic_february: 5.895,
            ic_march: 5.148,
            ic_april: 4.088,
            ic_may: 3.107,
            ic_june: 2.567,
            ic_july: 2.89,
            ic_august: 3.731,
            ic_september: 3.978,
            ic_october: 5.168,
            ic_november: 6.219,
            ic_december: 6.613,
            ic_yearly: 4.645,
          },
          {
            ic_uuid: "6dfe01c3-0728-4599-93ae-3d2c901d8828",
            ic_lat: -33632,
            ic_lon: -398296,
            ic_city: "Amontada",
            ic_states: "CEARÁ",
            ic_january: 5.306,
            ic_february: 5.363,
            ic_march: 5.209,
            ic_april: 4.755,
            ic_may: 5.133,
            ic_june: 5.116,
            ic_july: 5.27,
            ic_august: 6.044,
            ic_september: 6.449,
            ic_october: 6.378,
            ic_november: 6.277,
            ic_december: 5.659,
            ic_yearly: 5.58,
          },
          {
            ic_uuid: "4f8b1c6a-6c75-48df-b47c-2f18675c1cc1",
            ic_lat: -1662,
            ic_lon: -510948,
            ic_city: "Amorinópolis",
            ic_states: "GOIÁS",
            ic_january: 5.555,
            ic_february: 5.621,
            ic_march: 5.304,
            ic_april: 5.269,
            ic_may: 4.874,
            ic_june: 4.479,
            ic_july: 4.694,
            ic_august: 5.459,
            ic_september: 5.38,
            ic_october: 5.572,
            ic_november: 5.451,
            ic_december: 5.573,
            ic_yearly: 5.269,
          },
          {
            ic_uuid: "5b33379f-7fa1-46fc-b584-91c35718df40",
            ic_lat: -75674,
            ic_lon: -37063,
            ic_city: "Amparo",
            ic_states: "PARAÍBA",
            ic_january: 6.21,
            ic_february: 6.099,
            ic_march: 6.065,
            ic_april: 5.705,
            ic_may: 4.959,
            ic_june: 4.491,
            ic_july: 4.7,
            ic_august: 5.466,
            ic_september: 6.248,
            ic_october: 6.479,
            ic_november: 6.603,
            ic_december: 6.309,
            ic_yearly: 5.778,
          },
          {
            ic_uuid: "cd66486e-d2af-4fe5-a973-fb31b56fbf66",
            ic_lat: -227093,
            ic_lon: -467724,
            ic_city: "Amparo",
            ic_states: "SÃO PAULO",
            ic_january: 5.5,
            ic_february: 5.768,
            ic_march: 5.079,
            ic_april: 4.66,
            ic_may: 3.881,
            ic_june: 3.566,
            ic_july: 3.784,
            ic_august: 4.68,
            ic_september: 4.905,
            ic_october: 5.501,
            ic_november: 5.688,
            ic_december: 6.073,
            ic_yearly: 4.924,
          },
          {
            ic_uuid: "8d96956f-ae26-4f24-b0dc-32a02a6be8b8",
            ic_lat: -101349,
            ic_lon: -369284,
            ic_city: "Amparo de São Francisco",
            ic_states: "SERGIPE",
            ic_january: 6.221,
            ic_february: 6.007,
            ic_march: 5.932,
            ic_april: 5.205,
            ic_may: 4.363,
            ic_june: 4.123,
            ic_july: 4.185,
            ic_august: 4.773,
            ic_september: 5.552,
            ic_october: 5.852,
            ic_november: 6.291,
            ic_december: 6.284,
            ic_yearly: 5.399,
          },
          {
            ic_uuid: "0d8f5215-abfd-4594-8794-ed02f8a325a6",
            ic_lat: -205055,
            ic_lon: -428012,
            ic_city: "Amparo do Serra",
            ic_states: "MINAS GERAIS",
            ic_january: 5.566,
            ic_february: 5.98,
            ic_march: 5.006,
            ic_april: 4.432,
            ic_may: 3.785,
            ic_june: 3.559,
            ic_july: 3.786,
            ic_august: 4.582,
            ic_september: 4.874,
            ic_october: 5.071,
            ic_november: 4.846,
            ic_december: 5.506,
            ic_yearly: 4.749,
          },
          {
            ic_uuid: "2103bff7-d189-47a2-be87-28e005c5107f",
            ic_lat: -259173,
            ic_lon: -534691,
            ic_city: "Ampére",
            ic_states: "PARANÁ",
            ic_january: 6.407,
            ic_february: 5.822,
            ic_march: 5.225,
            ic_april: 4.227,
            ic_may: 3.296,
            ic_june: 2.861,
            ic_july: 3.116,
            ic_august: 4.079,
            ic_september: 4.266,
            ic_october: 5.247,
            ic_november: 6.122,
            ic_december: 6.484,
            ic_yearly: 4.763,
          },
          {
            ic_uuid: "e6a068a1-5319-45ae-971f-1a528d558376",
            ic_lat: -96853,
            ic_lon: -363081,
            ic_city: "Anadia",
            ic_states: "ALAGOAS",
            ic_january: 5.928,
            ic_february: 5.819,
            ic_march: 5.794,
            ic_april: 5.058,
            ic_may: 4.2,
            ic_june: 3.903,
            ic_july: 3.962,
            ic_august: 4.551,
            ic_september: 5.381,
            ic_october: 5.702,
            ic_november: 6.236,
            ic_december: 6.275,
            ic_yearly: 5.234,
          },
          {
            ic_uuid: "2a290e3f-c669-45f2-9a84-b616625a5315",
            ic_lat: -146143,
            ic_lon: -41139,
            ic_city: "Anagé",
            ic_states: "BAHIA",
            ic_january: 6.246,
            ic_february: 6.238,
            ic_march: 5.753,
            ic_april: 4.936,
            ic_may: 4.309,
            ic_june: 3.892,
            ic_july: 4.11,
            ic_august: 4.707,
            ic_september: 5.626,
            ic_october: 5.808,
            ic_november: 5.7,
            ic_december: 6.321,
            ic_yearly: 5.304,
          },
          {
            ic_uuid: "99c3352a-22c7-4971-b6c1-e089538ac567",
            ic_lat: -246453,
            ic_lon: -531337,
            ic_city: "Anahy",
            ic_states: "PARANÁ",
            ic_january: 6.325,
            ic_february: 5.638,
            ic_march: 5.303,
            ic_april: 4.408,
            ic_may: 3.47,
            ic_june: 3.056,
            ic_july: 3.245,
            ic_august: 4.262,
            ic_september: 4.487,
            ic_october: 5.266,
            ic_november: 6.095,
            ic_december: 6.452,
            ic_yearly: 4.834,
          },
          {
            ic_uuid: "1431fa11-6361-40a9-8e80-33e09575054c",
            ic_lat: -9972,
            ic_lon: -499358,
            ic_city: "Anajás",
            ic_states: "PARÁ",
            ic_january: 4.216,
            ic_february: 4.1,
            ic_march: 4.125,
            ic_april: 4.176,
            ic_may: 4.567,
            ic_june: 4.723,
            ic_july: 4.961,
            ic_august: 5.157,
            ic_september: 5.245,
            ic_october: 5.141,
            ic_november: 5.058,
            ic_december: 4.481,
            ic_yearly: 4.662,
          },
          {
            ic_uuid: "5fcc52e2-129d-40bb-99b7-79ca917b3499",
            ic_lat: -32649,
            ic_lon: -446167,
            ic_city: "Anajatuba",
            ic_states: "MARANHÃO",
            ic_january: 4.711,
            ic_february: 4.894,
            ic_march: 4.898,
            ic_april: 4.773,
            ic_may: 4.841,
            ic_june: 5.025,
            ic_july: 5.206,
            ic_august: 5.809,
            ic_september: 6.037,
            ic_october: 5.725,
            ic_november: 5.399,
            ic_december: 5.097,
            ic_yearly: 5.201,
          },
          {
            ic_uuid: "41d160ec-a9ff-4857-a9df-501c7e9be73a",
            ic_lat: -221294,
            ic_lon: -476623,
            ic_city: "Analândia",
            ic_states: "SÃO PAULO",
            ic_january: 5.495,
            ic_february: 5.782,
            ic_march: 5.072,
            ic_april: 4.665,
            ic_may: 3.907,
            ic_june: 3.702,
            ic_july: 3.852,
            ic_august: 4.827,
            ic_september: 4.913,
            ic_october: 5.494,
            ic_november: 5.645,
            ic_december: 5.901,
            ic_yearly: 4.938,
          },
          {
            ic_uuid: "63b67f38-c429-4d6f-9dab-c368a9c23c6d",
            ic_lat: -35674,
            ic_lon: -613968,
            ic_city: "Anamã",
            ic_states: "AMAZONAS",
            ic_january: 4.214,
            ic_february: 4.287,
            ic_march: 4.369,
            ic_april: 4.077,
            ic_may: 4.022,
            ic_june: 4.505,
            ic_july: 4.533,
            ic_august: 5.152,
            ic_september: 5.08,
            ic_october: 5.009,
            ic_november: 4.752,
            ic_december: 4.337,
            ic_yearly: 4.528,
          },
          {
            ic_uuid: "1bb311dc-74b2-49ea-afaa-93dd110009d1",
            ic_lat: -63648,
            ic_lon: -480738,
            ic_city: "Ananás",
            ic_states: "TOCANTINS",
            ic_january: 4.525,
            ic_february: 4.699,
            ic_march: 4.622,
            ic_april: 4.657,
            ic_may: 4.843,
            ic_june: 5.142,
            ic_july: 5.33,
            ic_august: 5.826,
            ic_september: 5.673,
            ic_october: 4.991,
            ic_november: 4.608,
            ic_december: 4.484,
            ic_yearly: 4.95,
          },
          {
            ic_uuid: "3b0fa737-63f1-40be-9588-cf47260d23a1",
            ic_lat: -13643,
            ic_lon: -483747,
            ic_city: "Ananindeua",
            ic_states: "PARÁ",
            ic_january: 4.293,
            ic_february: 4.279,
            ic_march: 4.274,
            ic_april: 4.311,
            ic_may: 4.598,
            ic_june: 4.936,
            ic_july: 5.038,
            ic_august: 5.221,
            ic_september: 5.29,
            ic_october: 5.064,
            ic_november: 4.926,
            ic_december: 4.539,
            ic_yearly: 4.731,
          },
          {
            ic_uuid: "811b9397-d283-4e5a-be7c-e64b13f56edd",
            ic_lat: -163285,
            ic_lon: -489534,
            ic_city: "Anápolis",
            ic_states: "GOIÁS",
            ic_january: 5.368,
            ic_february: 5.524,
            ic_march: 5.039,
            ic_april: 5.015,
            ic_may: 4.783,
            ic_june: 4.635,
            ic_july: 4.896,
            ic_august: 5.745,
            ic_september: 5.537,
            ic_october: 5.489,
            ic_november: 5.123,
            ic_december: 5.361,
            ic_yearly: 5.21,
          },
          {
            ic_uuid: "b4e6283d-6795-41c7-bb8d-71fc921c7c46",
            ic_lat: -34702,
            ic_lon: -512007,
            ic_city: "Anapu",
            ic_states: "PARÁ",
            ic_january: 4.296,
            ic_february: 4.314,
            ic_march: 4.389,
            ic_april: 4.385,
            ic_may: 4.374,
            ic_june: 4.638,
            ic_july: 4.705,
            ic_august: 4.854,
            ic_september: 4.684,
            ic_october: 4.478,
            ic_november: 4.398,
            ic_december: 4.209,
            ic_yearly: 4.477,
          },
          {
            ic_uuid: "db42f325-3bd4-4b60-a94b-2f9126109487",
            ic_lat: -36744,
            ic_lon: -431093,
            ic_city: "Anapurus",
            ic_states: "MARANHÃO",
            ic_january: 5.198,
            ic_february: 5.3,
            ic_march: 5.252,
            ic_april: 5.129,
            ic_may: 5.039,
            ic_june: 5.215,
            ic_july: 5.389,
            ic_august: 5.954,
            ic_september: 6.304,
            ic_october: 6.257,
            ic_november: 6.007,
            ic_december: 5.559,
            ic_yearly: 5.55,
          },
          {
            ic_uuid: "60d2ca68-89aa-4808-b83d-2e9126af445b",
            ic_lat: -204827,
            ic_lon: -558108,
            ic_city: "Anastácio",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.82,
            ic_february: 5.851,
            ic_march: 5.403,
            ic_april: 4.816,
            ic_may: 3.923,
            ic_june: 3.658,
            ic_july: 3.817,
            ic_august: 4.659,
            ic_september: 4.847,
            ic_october: 5.558,
            ic_november: 6.064,
            ic_december: 6.34,
            ic_yearly: 5.063,
          },
          {
            ic_uuid: "8e404216-2cc7-4a9e-a8ba-b938ac50f79e",
            ic_lat: -221856,
            ic_lon: -527195,
            ic_city: "Anaurilândia",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.902,
            ic_february: 5.838,
            ic_march: 5.41,
            ic_april: 4.737,
            ic_may: 3.825,
            ic_june: 3.44,
            ic_july: 3.64,
            ic_august: 4.545,
            ic_september: 4.798,
            ic_october: 5.454,
            ic_november: 6.032,
            ic_december: 6.418,
            ic_yearly: 5.003,
          },
          {
            ic_uuid: "fa9ee835-1b19-4158-b44f-68287f2e63a6",
            ic_lat: -20796,
            ic_lon: -406429,
            ic_city: "Anchieta",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.404,
            ic_february: 6.641,
            ic_march: 5.396,
            ic_april: 4.683,
            ic_may: 3.9,
            ic_june: 3.664,
            ic_july: 3.735,
            ic_august: 4.49,
            ic_september: 4.938,
            ic_october: 5.233,
            ic_november: 5.152,
            ic_december: 5.973,
            ic_yearly: 5.018,
          },
          {
            ic_uuid: "887f069f-2558-4d44-8244-b02ceeff7af0",
            ic_lat: -265387,
            ic_lon: -533324,
            ic_city: "Anchieta",
            ic_states: "SANTA CATARINA",
            ic_january: 6.264,
            ic_february: 5.779,
            ic_march: 5.114,
            ic_april: 4.146,
            ic_may: 3.22,
            ic_june: 2.739,
            ic_july: 3.024,
            ic_august: 3.909,
            ic_september: 4.176,
            ic_october: 5.147,
            ic_november: 6.088,
            ic_december: 6.376,
            ic_yearly: 4.665,
          },
          {
            ic_uuid: "dd6c1b95-f366-4e43-9335-a615be822ede",
            ic_lat: -127952,
            ic_lon: -413286,
            ic_city: "Andaraí",
            ic_states: "BAHIA",
            ic_january: 5.78,
            ic_february: 5.946,
            ic_march: 5.578,
            ic_april: 4.678,
            ic_may: 4.086,
            ic_june: 3.777,
            ic_july: 3.896,
            ic_august: 4.513,
            ic_september: 5.382,
            ic_october: 5.334,
            ic_november: 5.186,
            ic_december: 5.696,
            ic_yearly: 4.988,
          },
          {
            ic_uuid: "ee0cddd9-7c70-44ba-b7f0-212d6e2ed948",
            ic_lat: -230537,
            ic_lon: -502308,
            ic_city: "Andirá",
            ic_states: "PARANÁ",
            ic_january: 5.85,
            ic_february: 5.923,
            ic_march: 5.233,
            ic_april: 4.687,
            ic_may: 3.71,
            ic_june: 3.42,
            ic_july: 3.633,
            ic_august: 4.59,
            ic_september: 4.72,
            ic_october: 5.392,
            ic_november: 5.903,
            ic_december: 6.406,
            ic_yearly: 4.956,
          },
          {
            ic_uuid: "0200fd0c-d584-4173-b4c1-42a65c5702e3",
            ic_lat: -103436,
            ic_lon: -398338,
            ic_city: "Andorinha",
            ic_states: "BAHIA",
            ic_january: 6.112,
            ic_february: 5.981,
            ic_march: 5.824,
            ic_april: 4.92,
            ic_may: 4.253,
            ic_june: 3.936,
            ic_july: 4.092,
            ic_august: 4.694,
            ic_september: 5.746,
            ic_october: 5.845,
            ic_november: 6.2,
            ic_december: 6.129,
            ic_yearly: 5.311,
          },
          {
            ic_uuid: "f2f3013a-6b89-4b1e-8d93-66eed04c639d",
            ic_lat: -2207,
            ic_lon: -465728,
            ic_city: "Andradas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.274,
            ic_february: 5.457,
            ic_march: 4.798,
            ic_april: 4.557,
            ic_may: 3.913,
            ic_june: 3.692,
            ic_july: 3.88,
            ic_august: 4.779,
            ic_september: 4.931,
            ic_october: 5.327,
            ic_november: 5.387,
            ic_december: 5.543,
            ic_yearly: 4.795,
          },
          {
            ic_uuid: "503ec224-3073-444a-8e73-6cbb6f5ee407",
            ic_lat: -208952,
            ic_lon: -51379,
            ic_city: "Andradina",
            ic_states: "SÃO PAULO",
            ic_january: 5.957,
            ic_february: 5.921,
            ic_march: 5.398,
            ic_april: 4.909,
            ic_may: 4.107,
            ic_june: 3.798,
            ic_july: 3.984,
            ic_august: 4.961,
            ic_september: 4.979,
            ic_october: 5.642,
            ic_november: 6.034,
            ic_december: 6.373,
            ic_yearly: 5.172,
          },
          {
            ic_uuid: "62fbb800-4a37-42f9-be91-23c8feddb1b9",
            ic_lat: -286288,
            ic_lon: -515802,
            ic_city: "André da Rocha",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.105,
            ic_february: 5.653,
            ic_march: 4.837,
            ic_april: 3.887,
            ic_may: 2.932,
            ic_june: 2.464,
            ic_july: 2.8,
            ic_august: 3.502,
            ic_september: 3.764,
            ic_october: 4.856,
            ic_november: 6.132,
            ic_december: 6.403,
            ic_yearly: 4.445,
          },
          {
            ic_uuid: "b1f06b21-308d-481f-b712-ffb5b73ea3e1",
            ic_lat: -217416,
            ic_lon: -443121,
            ic_city: "Andrelândia",
            ic_states: "MINAS GERAIS",
            ic_january: 5.433,
            ic_february: 5.814,
            ic_march: 4.904,
            ic_april: 4.367,
            ic_may: 3.74,
            ic_june: 3.581,
            ic_july: 3.753,
            ic_august: 4.675,
            ic_september: 4.904,
            ic_october: 5.156,
            ic_november: 5.113,
            ic_december: 5.56,
            ic_yearly: 4.75,
          },
          {
            ic_uuid: "01c91efc-2cf1-4d83-b82a-6c1b69e7959c",
            ic_lat: -234922,
            ic_lon: -484143,
            ic_city: "Angatuba",
            ic_states: "SÃO PAULO",
            ic_january: 5.451,
            ic_february: 5.624,
            ic_march: 4.993,
            ic_april: 4.42,
            ic_may: 3.512,
            ic_june: 3.266,
            ic_july: 3.369,
            ic_august: 4.384,
            ic_september: 4.539,
            ic_october: 5.059,
            ic_november: 5.645,
            ic_december: 6.068,
            ic_yearly: 4.694,
          },
          {
            ic_uuid: "cd21cded-887e-4eee-ad9e-d9e0d4fd07a9",
            ic_lat: -17726,
            ic_lon: -42273,
            ic_city: "Angelândia",
            ic_states: "MINAS GERAIS",
            ic_january: 5.837,
            ic_february: 6.095,
            ic_march: 5.028,
            ic_april: 4.428,
            ic_may: 3.872,
            ic_june: 3.715,
            ic_july: 3.935,
            ic_august: 4.646,
            ic_september: 5.218,
            ic_october: 5.356,
            ic_november: 4.862,
            ic_december: 5.626,
            ic_yearly: 4.885,
          },
          {
            ic_uuid: "00a7a7d5-f43a-47a3-a7a6-97e8199440d4",
            ic_lat: -221531,
            ic_lon: -537712,
            ic_city: "Angélica",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.848,
            ic_february: 5.833,
            ic_march: 5.394,
            ic_april: 4.662,
            ic_may: 3.76,
            ic_june: 3.414,
            ic_july: 3.596,
            ic_august: 4.535,
            ic_september: 4.764,
            ic_october: 5.423,
            ic_november: 6.028,
            ic_december: 6.454,
            ic_yearly: 4.976,
          },
          {
            ic_uuid: "79830302-20fe-4aa5-a6d1-e42b7feb42f8",
            ic_lat: -88882,
            ic_lon: -36288,
            ic_city: "Angelim",
            ic_states: "PERNAMBUCO",
            ic_january: 5.827,
            ic_february: 5.699,
            ic_march: 5.757,
            ic_april: 5.127,
            ic_may: 4.264,
            ic_june: 3.81,
            ic_july: 3.928,
            ic_august: 4.546,
            ic_september: 5.384,
            ic_october: 5.673,
            ic_november: 6.208,
            ic_december: 6.15,
            ic_yearly: 5.198,
          },
          {
            ic_uuid: "59bc921b-a06a-4101-bcff-c640bdc92885",
            ic_lat: -275709,
            ic_lon: -489883,
            ic_city: "Angelina",
            ic_states: "SANTA CATARINA",
            ic_january: 5.414,
            ic_february: 5.143,
            ic_march: 4.447,
            ic_april: 3.632,
            ic_may: 2.958,
            ic_june: 2.513,
            ic_july: 2.667,
            ic_august: 3.372,
            ic_september: 3.404,
            ic_october: 3.97,
            ic_november: 5.087,
            ic_december: 5.579,
            ic_yearly: 4.016,
          },
          {
            ic_uuid: "7e727954-9967-4b12-bb3d-cd3ea7e6b894",
            ic_lat: -120091,
            ic_lon: -447001,
            ic_city: "Angical",
            ic_states: "BAHIA",
            ic_january: 6.388,
            ic_february: 6.187,
            ic_march: 5.878,
            ic_april: 5.59,
            ic_may: 5.333,
            ic_june: 5.141,
            ic_july: 5.435,
            ic_august: 6.029,
            ic_september: 6.201,
            ic_october: 6.257,
            ic_november: 5.948,
            ic_december: 6.304,
            ic_yearly: 5.891,
          },
          {
            ic_uuid: "6e93ae95-44b7-4736-8e01-c88aa26cdca4",
            ic_lat: -60883,
            ic_lon: -427404,
            ic_city: "Angical do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.06,
            ic_february: 5.204,
            ic_march: 5.208,
            ic_april: 5.113,
            ic_may: 5.199,
            ic_june: 5.359,
            ic_july: 5.665,
            ic_august: 6.239,
            ic_september: 6.546,
            ic_october: 6.251,
            ic_november: 5.975,
            ic_december: 5.466,
            ic_yearly: 5.607,
          },
          {
            ic_uuid: "96057311-769e-48b2-bb5b-c923cac9f1dd",
            ic_lat: -63922,
            ic_lon: -478615,
            ic_city: "Angico",
            ic_states: "TOCANTINS",
            ic_january: 4.607,
            ic_february: 4.809,
            ic_march: 4.745,
            ic_april: 4.8,
            ic_may: 4.915,
            ic_june: 5.223,
            ic_july: 5.394,
            ic_august: 5.869,
            ic_september: 5.675,
            ic_october: 4.996,
            ic_november: 4.654,
            ic_december: 4.556,
            ic_yearly: 5.02,
          },
          {
            ic_uuid: "c3454da3-f5e2-482b-b2a1-6dbd76307a40",
            ic_lat: -56583,
            ic_lon: -366097,
            ic_city: "Angicos",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.916,
            ic_february: 5.909,
            ic_march: 5.982,
            ic_april: 5.649,
            ic_may: 5.265,
            ic_june: 4.812,
            ic_july: 5.085,
            ic_august: 5.814,
            ic_september: 6.269,
            ic_october: 6.479,
            ic_november: 6.445,
            ic_december: 6.014,
            ic_yearly: 5.803,
          },
          {
            ic_uuid: "b7403ba7-0193-4ccf-b565-4724950aade2",
            ic_lat: -230016,
            ic_lon: -443199,
            ic_city: "Angra dos Reis",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.356,
            ic_february: 5.642,
            ic_march: 4.638,
            ic_april: 4.245,
            ic_may: 3.482,
            ic_june: 3.228,
            ic_july: 3.255,
            ic_august: 4.017,
            ic_september: 4.031,
            ic_october: 4.402,
            ic_november: 4.552,
            ic_december: 5.156,
            ic_yearly: 4.334,
          },
          {
            ic_uuid: "2f89688c-11c6-48d1-90dd-22b97161c578",
            ic_lat: -121519,
            ic_lon: -392441,
            ic_city: "Anguera",
            ic_states: "BAHIA",
            ic_january: 5.992,
            ic_february: 5.852,
            ic_march: 5.663,
            ic_april: 4.641,
            ic_may: 3.934,
            ic_june: 3.591,
            ic_july: 3.828,
            ic_august: 4.376,
            ic_september: 5.162,
            ic_october: 5.37,
            ic_november: 5.642,
            ic_december: 5.977,
            ic_yearly: 5.003,
          },
          {
            ic_uuid: "82b8a034-23c9-4645-b723-89a745519b8c",
            ic_lat: -231951,
            ic_lon: -519158,
            ic_city: "Ângulo",
            ic_states: "PARANÁ",
            ic_january: 5.926,
            ic_february: 5.843,
            ic_march: 5.312,
            ic_april: 4.65,
            ic_may: 3.675,
            ic_june: 3.382,
            ic_july: 3.553,
            ic_august: 4.482,
            ic_september: 4.692,
            ic_october: 5.387,
            ic_november: 6.046,
            ic_december: 6.382,
            ic_yearly: 4.944,
          },
          {
            ic_uuid: "516d553e-e93c-4012-8630-94c698c86d40",
            ic_lat: -18337,
            ic_lon: -48219,
            ic_city: "Anhanguera",
            ic_states: "GOIÁS",
            ic_january: 5.633,
            ic_february: 5.923,
            ic_march: 5.208,
            ic_april: 5.137,
            ic_may: 4.699,
            ic_june: 4.41,
            ic_july: 4.64,
            ic_august: 5.561,
            ic_september: 5.515,
            ic_october: 5.69,
            ic_november: 5.568,
            ic_december: 5.814,
            ic_yearly: 5.317,
          },
          {
            ic_uuid: "5bdf010b-ef15-4f7f-b88e-aac4e01a581a",
            ic_lat: -22789,
            ic_lon: -48127,
            ic_city: "Anhembi",
            ic_states: "SÃO PAULO",
            ic_january: 5.672,
            ic_february: 5.919,
            ic_march: 5.219,
            ic_april: 4.608,
            ic_may: 3.752,
            ic_june: 3.494,
            ic_july: 3.614,
            ic_august: 4.594,
            ic_september: 4.78,
            ic_october: 5.463,
            ic_november: 5.908,
            ic_december: 6.268,
            ic_yearly: 4.941,
          },
          {
            ic_uuid: "a7fafd4f-d822-475b-a0ee-c4dd80721e07",
            ic_lat: -22295,
            ic_lon: -513869,
            ic_city: "Anhumas",
            ic_states: "SÃO PAULO",
            ic_january: 5.956,
            ic_february: 5.979,
            ic_march: 5.422,
            ic_april: 4.77,
            ic_may: 3.874,
            ic_june: 3.566,
            ic_july: 3.776,
            ic_august: 4.738,
            ic_september: 4.852,
            ic_october: 5.526,
            ic_november: 6.149,
            ic_december: 6.475,
            ic_yearly: 5.09,
          },
          {
            ic_uuid: "e4e4ddf0-11cf-43ee-89b9-856b741ec673",
            ic_lat: -16459,
            ic_lon: -499589,
            ic_city: "Anicuns",
            ic_states: "GOIÁS",
            ic_january: 5.466,
            ic_february: 5.65,
            ic_march: 5.211,
            ic_april: 5.154,
            ic_may: 4.812,
            ic_june: 4.614,
            ic_july: 4.816,
            ic_august: 5.702,
            ic_september: 5.471,
            ic_october: 5.55,
            ic_november: 5.351,
            ic_december: 5.526,
            ic_yearly: 5.277,
          },
          {
            ic_uuid: "32d3ab05-1819-4ed1-82dc-f8c170e898ec",
            ic_lat: -91861,
            ic_lon: -430498,
            ic_city: "Anísio de Abreu",
            ic_states: "PIAUÍ",
            ic_january: 6.039,
            ic_february: 5.734,
            ic_march: 5.643,
            ic_april: 5.437,
            ic_may: 5.258,
            ic_june: 5.263,
            ic_july: 5.649,
            ic_august: 6.389,
            ic_september: 6.671,
            ic_october: 6.476,
            ic_november: 6.202,
            ic_december: 6.062,
            ic_yearly: 5.902,
          },
          {
            ic_uuid: "bf134eb4-4849-4c63-a3dd-f00020a1bace",
            ic_lat: -276902,
            ic_lon: -511276,
            ic_city: "Anita Garibaldi",
            ic_states: "SANTA CATARINA",
            ic_january: 6.11,
            ic_february: 5.619,
            ic_march: 4.917,
            ic_april: 4.0,
            ic_may: 2.981,
            ic_june: 2.575,
            ic_july: 2.878,
            ic_august: 3.683,
            ic_september: 3.906,
            ic_october: 4.889,
            ic_november: 6.13,
            ic_december: 6.389,
            ic_yearly: 4.506,
          },
          {
            ic_uuid: "a4b37d30-b8df-40f6-be46-36556caf1741",
            ic_lat: -279017,
            ic_lon: -491321,
            ic_city: "Anitápolis",
            ic_states: "SANTA CATARINA",
            ic_january: 5.384,
            ic_february: 5.11,
            ic_march: 4.39,
            ic_april: 3.684,
            ic_may: 2.899,
            ic_june: 2.534,
            ic_july: 2.728,
            ic_august: 3.473,
            ic_september: 3.514,
            ic_october: 4.157,
            ic_november: 5.389,
            ic_december: 5.721,
            ic_yearly: 4.082,
          },
          {
            ic_uuid: "3e24978d-747d-4222-bb6e-41dc19c8f0be",
            ic_lat: -37464,
            ic_lon: -616579,
            ic_city: "Anori",
            ic_states: "AMAZONAS",
            ic_january: 4.134,
            ic_february: 4.271,
            ic_march: 4.316,
            ic_april: 4.031,
            ic_may: 3.958,
            ic_june: 4.364,
            ic_july: 4.49,
            ic_august: 5.084,
            ic_september: 4.994,
            ic_october: 4.926,
            ic_november: 4.667,
            ic_december: 4.318,
            ic_yearly: 4.463,
          },
          {
            ic_uuid: "1a0b7223-bbfc-4dc2-8877-44081e606d5a",
            ic_lat: -289702,
            ic_lon: -520106,
            ic_city: "Anta Gorda",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.064,
            ic_february: 5.672,
            ic_march: 4.786,
            ic_april: 3.822,
            ic_may: 2.842,
            ic_june: 2.388,
            ic_july: 2.691,
            ic_august: 3.421,
            ic_september: 3.696,
            ic_october: 4.703,
            ic_november: 6.067,
            ic_december: 6.401,
            ic_yearly: 4.38,
          },
          {
            ic_uuid: "a073f8be-3748-4a72-96c4-3be27134708f",
            ic_lat: -104132,
            ic_lon: -383323,
            ic_city: "Antas",
            ic_states: "BAHIA",
            ic_january: 6.126,
            ic_february: 5.865,
            ic_march: 5.792,
            ic_april: 5.079,
            ic_may: 4.231,
            ic_june: 3.872,
            ic_july: 4.023,
            ic_august: 4.54,
            ic_september: 5.365,
            ic_october: 5.594,
            ic_november: 6.125,
            ic_december: 6.129,
            ic_yearly: 5.228,
          },
          {
            ic_uuid: "abbf11aa-bb9f-467e-b088-34832d0081ee",
            ic_lat: -254391,
            ic_lon: -487195,
            ic_city: "Antonina",
            ic_states: "PARANÁ",
            ic_january: 5.038,
            ic_february: 4.924,
            ic_march: 4.288,
            ic_april: 3.609,
            ic_may: 2.956,
            ic_june: 2.571,
            ic_july: 2.57,
            ic_august: 3.318,
            ic_september: 3.209,
            ic_october: 3.671,
            ic_november: 4.641,
            ic_december: 5.106,
            ic_yearly: 3.825,
          },
          {
            ic_uuid: "13df2829-ac1b-4894-9ee0-a9f3f31fca0d",
            ic_lat: -67747,
            ic_lon: -399871,
            ic_city: "Antonina do Norte",
            ic_states: "CEARÁ",
            ic_january: 5.54,
            ic_february: 5.583,
            ic_march: 5.706,
            ic_april: 5.351,
            ic_may: 5.155,
            ic_june: 4.98,
            ic_july: 5.343,
            ic_august: 6.151,
            ic_september: 6.544,
            ic_october: 6.487,
            ic_november: 6.355,
            ic_december: 5.912,
            ic_yearly: 5.759,
          },
          {
            ic_uuid: "b35d7213-240c-4a75-b0f9-579fad32ee2d",
            ic_lat: -72195,
            ic_lon: -441935,
            ic_city: "Antônio Almeida",
            ic_states: "PIAUÍ",
            ic_january: 5.073,
            ic_february: 5.053,
            ic_march: 4.973,
            ic_april: 5.135,
            ic_may: 5.303,
            ic_june: 5.414,
            ic_july: 5.616,
            ic_august: 6.135,
            ic_september: 6.608,
            ic_october: 6.127,
            ic_november: 5.64,
            ic_december: 5.278,
            ic_yearly: 5.53,
          },
          {
            ic_uuid: "edca8b1b-3411-423d-a0a7-c7631e00909d",
            ic_lat: -124339,
            ic_lon: -391179,
            ic_city: "Antônio Cardoso",
            ic_states: "BAHIA",
            ic_january: 6.077,
            ic_february: 5.95,
            ic_march: 5.71,
            ic_april: 4.723,
            ic_may: 4.043,
            ic_june: 3.655,
            ic_july: 3.895,
            ic_august: 4.474,
            ic_september: 5.211,
            ic_october: 5.451,
            ic_november: 5.679,
            ic_december: 6.007,
            ic_yearly: 5.073,
          },
          {
            ic_uuid: "ddc699ce-27c5-407c-b980-7cd49c44ebe5",
            ic_lat: -213215,
            ic_lon: -437455,
            ic_city: "Antônio Carlos",
            ic_states: "MINAS GERAIS",
            ic_january: 5.381,
            ic_february: 5.718,
            ic_march: 4.773,
            ic_april: 4.258,
            ic_may: 3.683,
            ic_june: 3.536,
            ic_july: 3.726,
            ic_august: 4.605,
            ic_september: 4.832,
            ic_october: 5.047,
            ic_november: 4.797,
            ic_december: 5.516,
            ic_yearly: 4.656,
          },
          {
            ic_uuid: "5028910f-a4e1-40b1-b9cd-8bffba32201a",
            ic_lat: -275196,
            ic_lon: -487664,
            ic_city: "Antônio Carlos",
            ic_states: "SANTA CATARINA",
            ic_january: 5.416,
            ic_february: 5.138,
            ic_march: 4.441,
            ic_april: 3.627,
            ic_may: 2.99,
            ic_june: 2.546,
            ic_july: 2.657,
            ic_august: 3.351,
            ic_september: 3.396,
            ic_october: 3.94,
            ic_november: 5.124,
            ic_december: 5.563,
            ic_yearly: 4.016,
          },
          {
            ic_uuid: "8109ea2e-efd8-4514-a15c-127de2adf078",
            ic_lat: -196538,
            ic_lon: -428783,
            ic_city: "Antônio Dias",
            ic_states: "MINAS GERAIS",
            ic_january: 5.67,
            ic_february: 5.982,
            ic_march: 4.918,
            ic_april: 4.529,
            ic_may: 3.854,
            ic_june: 3.671,
            ic_july: 3.866,
            ic_august: 4.593,
            ic_september: 4.938,
            ic_october: 5.081,
            ic_november: 4.673,
            ic_december: 5.514,
            ic_yearly: 4.774,
          },
          {
            ic_uuid: "3ae0f683-0c08-4e00-b407-e95bfc856b85",
            ic_lat: -105722,
            ic_lon: -402758,
            ic_city: "Antônio Gonçalves",
            ic_states: "BAHIA",
            ic_january: 5.974,
            ic_february: 5.833,
            ic_march: 5.758,
            ic_april: 4.726,
            ic_may: 4.004,
            ic_june: 3.729,
            ic_july: 3.96,
            ic_august: 4.54,
            ic_september: 5.572,
            ic_october: 5.632,
            ic_november: 5.851,
            ic_december: 5.908,
            ic_yearly: 5.124,
          },
          {
            ic_uuid: "9572abcc-099a-4e45-a96f-f07d6df5f966",
            ic_lat: -22191,
            ic_lon: -559479,
            ic_city: "Antônio João",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 6.001,
            ic_february: 5.659,
            ic_march: 5.315,
            ic_april: 4.538,
            ic_may: 3.616,
            ic_june: 3.344,
            ic_july: 3.423,
            ic_august: 4.452,
            ic_september: 4.705,
            ic_october: 5.392,
            ic_november: 5.881,
            ic_december: 6.282,
            ic_yearly: 4.884,
          },
          {
            ic_uuid: "87d09403-274b-453e-b6ca-420f7e2813bf",
            ic_lat: -62141,
            ic_lon: -378837,
            ic_city: "Antônio Martins",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.057,
            ic_february: 6.043,
            ic_march: 6.041,
            ic_april: 5.795,
            ic_may: 5.33,
            ic_june: 4.986,
            ic_july: 5.328,
            ic_august: 6.037,
            ic_september: 6.498,
            ic_october: 6.636,
            ic_november: 6.64,
            ic_december: 6.195,
            ic_yearly: 5.965,
          },
          {
            ic_uuid: "be850a00-e802-42ea-b73b-22c463fddbb6",
            ic_lat: -259809,
            ic_lon: -501976,
            ic_city: "Antônio Olinto",
            ic_states: "PARANÁ",
            ic_january: 5.425,
            ic_february: 5.071,
            ic_march: 4.597,
            ic_april: 3.62,
            ic_may: 2.905,
            ic_june: 2.536,
            ic_july: 2.772,
            ic_august: 3.753,
            ic_september: 3.842,
            ic_october: 4.463,
            ic_november: 5.366,
            ic_december: 5.633,
            ic_yearly: 4.165,
          },
          {
            ic_uuid: "ec502301-0508-4f75-9a07-0fff629414d7",
            ic_lat: -28857,
            ic_lon: -512887,
            ic_city: "Antônio Prado",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.1,
            ic_february: 5.704,
            ic_march: 4.838,
            ic_april: 3.858,
            ic_may: 2.87,
            ic_june: 2.421,
            ic_july: 2.739,
            ic_august: 3.457,
            ic_september: 3.706,
            ic_october: 4.805,
            ic_november: 6.171,
            ic_december: 6.45,
            ic_yearly: 4.427,
          },
          {
            ic_uuid: "95f55ee5-e763-4e5c-8a5a-9095cc8c18c2",
            ic_lat: -210197,
            ic_lon: -421113,
            ic_city: "Antônio Prado de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.868,
            ic_february: 5.985,
            ic_march: 4.923,
            ic_april: 4.393,
            ic_may: 3.772,
            ic_june: 3.611,
            ic_july: 3.732,
            ic_august: 4.539,
            ic_september: 4.884,
            ic_october: 5.021,
            ic_november: 4.79,
            ic_december: 5.57,
            ic_yearly: 4.757,
          },
          {
            ic_uuid: "0d431d73-6581-44e4-8862-6ef669aefd80",
            ic_lat: -67851,
            ic_lon: -380806,
            ic_city: "Aparecida",
            ic_states: "PARAÍBA",
            ic_january: 6.101,
            ic_february: 6.125,
            ic_march: 6.226,
            ic_april: 5.882,
            ic_may: 5.307,
            ic_june: 4.995,
            ic_july: 5.21,
            ic_august: 6.019,
            ic_september: 6.517,
            ic_october: 6.66,
            ic_november: 6.809,
            ic_december: 6.399,
            ic_yearly: 6.021,
          },
          {
            ic_uuid: "d4f01d16-4c23-4338-a77c-a1ca5aeed815",
            ic_lat: -2285,
            ic_lon: -452329,
            ic_city: "Aparecida",
            ic_states: "SÃO PAULO",
            ic_january: 5.364,
            ic_february: 5.624,
            ic_march: 4.809,
            ic_april: 4.381,
            ic_may: 3.611,
            ic_june: 3.386,
            ic_july: 3.513,
            ic_august: 4.478,
            ic_september: 4.594,
            ic_october: 5.033,
            ic_november: 5.082,
            ic_december: 5.661,
            ic_yearly: 4.628,
          },
          {
            ic_uuid: "d0ff2044-ec5b-4258-ade6-16636e963d92",
            ic_lat: -168203,
            ic_lon: -492473,
            ic_city: "Aparecida de Goiânia",
            ic_states: "GOIÁS",
            ic_january: 5.46,
            ic_february: 5.56,
            ic_march: 5.166,
            ic_april: 5.06,
            ic_may: 4.738,
            ic_june: 4.528,
            ic_july: 4.751,
            ic_august: 5.635,
            ic_september: 5.568,
            ic_october: 5.617,
            ic_november: 5.42,
            ic_december: 5.561,
            ic_yearly: 5.256,
          },
          {
            ic_uuid: "93dbaf85-65f1-4e90-b45e-d1ffc5bcf189",
            ic_lat: -182945,
            ic_lon: -51152,
            ic_city: "Aparecida do Rio Doce",
            ic_states: "GOIÁS",
            ic_january: 5.54,
            ic_february: 5.68,
            ic_march: 5.258,
            ic_april: 5.028,
            ic_may: 4.544,
            ic_june: 4.299,
            ic_july: 4.478,
            ic_august: 5.419,
            ic_september: 5.226,
            ic_october: 5.449,
            ic_november: 5.608,
            ic_december: 5.751,
            ic_yearly: 5.19,
          },
          {
            ic_uuid: "0e377827-6d19-4089-a3a5-7bfded153f77",
            ic_lat: -99528,
            ic_lon: -47972,
            ic_city: "Aparecida do Rio Negro",
            ic_states: "TOCANTINS",
            ic_january: 5.196,
            ic_february: 5.199,
            ic_march: 5.007,
            ic_april: 5.012,
            ic_may: 5.116,
            ic_june: 5.062,
            ic_july: 5.315,
            ic_august: 6.06,
            ic_september: 5.854,
            ic_october: 5.435,
            ic_november: 5.144,
            ic_december: 5.208,
            ic_yearly: 5.301,
          },
          {
            ic_uuid: "d241ce02-de8d-417c-b78b-231f25bb1180",
            ic_lat: -200877,
            ic_lon: -510966,
            ic_city: "Aparecida do Taboado",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.765,
            ic_february: 5.967,
            ic_march: 5.433,
            ic_april: 4.976,
            ic_may: 4.228,
            ic_june: 3.905,
            ic_july: 4.097,
            ic_august: 4.983,
            ic_september: 4.967,
            ic_october: 5.622,
            ic_november: 5.993,
            ic_december: 6.288,
            ic_yearly: 5.186,
          },
          {
            ic_uuid: "252f0d4b-6f8e-4672-add1-0f30078d9877",
            ic_lat: -204492,
            ic_lon: -508839,
            ic_city: "Aparecida d'Oeste",
            ic_states: "SÃO PAULO",
            ic_january: 5.851,
            ic_february: 5.982,
            ic_march: 5.367,
            ic_april: 4.873,
            ic_may: 4.195,
            ic_june: 3.887,
            ic_july: 4.109,
            ic_august: 5.052,
            ic_september: 5.061,
            ic_october: 5.698,
            ic_november: 6.008,
            ic_december: 6.346,
            ic_yearly: 5.202,
          },
          {
            ic_uuid: "d8d6d923-85e2-451a-ad03-e34e8b3ad747",
            ic_lat: -216257,
            ic_lon: -42102,
            ic_city: "Aperibé",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.144,
            ic_february: 6.299,
            ic_march: 5.204,
            ic_april: 4.484,
            ic_may: 3.741,
            ic_june: 3.473,
            ic_july: 3.609,
            ic_august: 4.348,
            ic_september: 4.807,
            ic_october: 5.157,
            ic_november: 5.087,
            ic_december: 6.004,
            ic_yearly: 4.863,
          },
          {
            ic_uuid: "8f64cb7f-1e6d-4586-9668-adbea9c370b2",
            ic_lat: -211528,
            ic_lon: -415697,
            ic_city: "Apiacá",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.204,
            ic_february: 6.4,
            ic_march: 5.207,
            ic_april: 4.524,
            ic_may: 3.855,
            ic_june: 3.579,
            ic_july: 3.677,
            ic_august: 4.464,
            ic_september: 4.891,
            ic_october: 5.169,
            ic_november: 5.026,
            ic_december: 5.917,
            ic_yearly: 4.909,
          },
          {
            ic_uuid: "ba544335-f22b-431c-8a4b-6a2c42a8ebd2",
            ic_lat: -95402,
            ic_lon: -574591,
            ic_city: "Apiacás",
            ic_states: "MATO GROSSO",
            ic_january: 4.522,
            ic_february: 4.555,
            ic_march: 4.48,
            ic_april: 4.436,
            ic_may: 4.348,
            ic_june: 4.608,
            ic_july: 4.754,
            ic_august: 5.214,
            ic_september: 4.957,
            ic_october: 5.044,
            ic_november: 4.824,
            ic_december: 4.752,
            ic_yearly: 4.708,
          },
          {
            ic_uuid: "930a8ea0-612f-409d-9393-c1ab4d7a9d2c",
            ic_lat: -245113,
            ic_lon: -488447,
            ic_city: "Apiaí",
            ic_states: "SÃO PAULO",
            ic_january: 5.205,
            ic_february: 5.204,
            ic_march: 4.471,
            ic_april: 3.876,
            ic_may: 3.127,
            ic_june: 2.828,
            ic_july: 2.922,
            ic_august: 3.864,
            ic_september: 3.897,
            ic_october: 4.375,
            ic_november: 5.008,
            ic_december: 5.492,
            ic_yearly: 4.189,
          },
          {
            ic_uuid: "2c360623-1bdf-4027-ad57-fc447982a37e",
            ic_lat: -15291,
            ic_lon: -450779,
            ic_city: "Apicum-Açu",
            ic_states: "MARANHÃO",
            ic_january: 4.976,
            ic_february: 4.698,
            ic_march: 4.536,
            ic_april: 4.415,
            ic_may: 4.654,
            ic_june: 4.943,
            ic_july: 5.091,
            ic_august: 5.546,
            ic_september: 5.929,
            ic_october: 5.684,
            ic_november: 5.576,
            ic_december: 5.313,
            ic_yearly: 5.113,
          },
          {
            ic_uuid: "6c49a77c-3999-4189-8ecd-5980d56ff6ac",
            ic_lat: -27038,
            ic_lon: -493889,
            ic_city: "Apiúna",
            ic_states: "SANTA CATARINA",
            ic_january: 5.444,
            ic_february: 5.185,
            ic_march: 4.56,
            ic_april: 3.669,
            ic_may: 2.965,
            ic_june: 2.437,
            ic_july: 2.579,
            ic_august: 3.314,
            ic_september: 3.397,
            ic_october: 3.917,
            ic_november: 5.136,
            ic_december: 5.479,
            ic_yearly: 4.007,
          },
          {
            ic_uuid: "0a555255-cb4d-44f3-a432-d048dae2a702",
            ic_lat: -56539,
            ic_lon: -377949,
            ic_city: "Apodi",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.977,
            ic_february: 6.158,
            ic_march: 6.07,
            ic_april: 5.766,
            ic_may: 5.353,
            ic_june: 5.038,
            ic_july: 5.332,
            ic_august: 5.969,
            ic_september: 6.366,
            ic_october: 6.585,
            ic_november: 6.535,
            ic_december: 6.169,
            ic_yearly: 5.943,
          },
          {
            ic_uuid: "285efcb9-614a-4480-8aec-707ce4d62e94",
            ic_lat: -116607,
            ic_lon: -380806,
            ic_city: "Aporá",
            ic_states: "BAHIA",
            ic_january: 6.105,
            ic_february: 5.883,
            ic_march: 5.729,
            ic_april: 4.806,
            ic_may: 4.071,
            ic_june: 3.87,
            ic_july: 4.039,
            ic_august: 4.424,
            ic_september: 5.279,
            ic_october: 5.624,
            ic_november: 6.002,
            ic_december: 6.22,
            ic_yearly: 5.171,
          },
          {
            ic_uuid: "5e9a3afe-68d3-4f80-9b64-1320198c9558",
            ic_lat: -18965,
            ic_lon: -519259,
            ic_city: "Aporé",
            ic_states: "GOIÁS",
            ic_january: 5.647,
            ic_february: 5.846,
            ic_march: 5.344,
            ic_april: 4.946,
            ic_may: 4.386,
            ic_june: 4.172,
            ic_july: 4.353,
            ic_august: 5.218,
            ic_september: 5.118,
            ic_october: 5.462,
            ic_november: 5.658,
            ic_december: 6.044,
            ic_yearly: 5.183,
          },
          {
            ic_uuid: "e5ac217f-6d9a-4355-854f-37e015d0d6fe",
            ic_lat: -138559,
            ic_lon: -397447,
            ic_city: "Apuarema",
            ic_states: "BAHIA",
            ic_january: 5.685,
            ic_february: 5.717,
            ic_march: 5.389,
            ic_april: 4.434,
            ic_may: 3.833,
            ic_june: 3.368,
            ic_july: 3.551,
            ic_august: 4.13,
            ic_september: 4.821,
            ic_october: 5.056,
            ic_november: 5.322,
            ic_december: 5.875,
            ic_yearly: 4.765,
          },
          {
            ic_uuid: "f0a6962d-649d-4399-ba3e-3103981364f9",
            ic_lat: -235504,
            ic_lon: -514639,
            ic_city: "Apucarana",
            ic_states: "PARANÁ",
            ic_january: 5.849,
            ic_february: 5.699,
            ic_march: 5.193,
            ic_april: 4.547,
            ic_may: 3.624,
            ic_june: 3.337,
            ic_july: 3.522,
            ic_august: 4.465,
            ic_september: 4.667,
            ic_october: 5.319,
            ic_november: 5.922,
            ic_december: 6.222,
            ic_yearly: 4.864,
          },
          {
            ic_uuid: "4d8d3b70-feff-4ee0-8e99-e8d9e9ae896e",
            ic_lat: -71984,
            ic_lon: -598852,
            ic_city: "Apuí",
            ic_states: "AMAZONAS",
            ic_january: 4.062,
            ic_february: 4.358,
            ic_march: 4.298,
            ic_april: 4.041,
            ic_may: 3.962,
            ic_june: 4.481,
            ic_july: 4.542,
            ic_august: 4.96,
            ic_september: 4.731,
            ic_october: 4.765,
            ic_november: 4.647,
            ic_december: 4.351,
            ic_yearly: 4.433,
          },
          {
            ic_uuid: "ddc9dcaa-a239-4902-884e-22051cd9daf9",
            ic_lat: -39455,
            ic_lon: -394362,
            ic_city: "Apuiarés",
            ic_states: "CEARÁ",
            ic_january: 5.144,
            ic_february: 5.337,
            ic_march: 5.25,
            ic_april: 4.804,
            ic_may: 5.079,
            ic_june: 4.942,
            ic_july: 5.25,
            ic_august: 5.974,
            ic_september: 6.468,
            ic_october: 6.305,
            ic_november: 6.058,
            ic_december: 5.5,
            ic_yearly: 5.509,
          },
          {
            ic_uuid: "8c69660d-ef2f-4a19-830a-c6c72d306920",
            ic_lat: -102785,
            ic_lon: -370151,
            ic_city: "Aquidabã",
            ic_states: "SERGIPE",
            ic_january: 5.953,
            ic_february: 5.805,
            ic_march: 5.747,
            ic_april: 5.064,
            ic_may: 4.202,
            ic_june: 3.983,
            ic_july: 4.061,
            ic_august: 4.627,
            ic_september: 5.374,
            ic_october: 5.717,
            ic_november: 6.25,
            ic_december: 6.162,
            ic_yearly: 5.245,
          },
          {
            ic_uuid: "2a639ae1-1515-4f99-8ee1-f0f5634b175d",
            ic_lat: -20467,
            ic_lon: -557872,
            ic_city: "Aquidauana",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.82,
            ic_february: 5.732,
            ic_march: 5.406,
            ic_april: 4.82,
            ic_may: 3.905,
            ic_june: 3.664,
            ic_july: 3.818,
            ic_august: 4.677,
            ic_september: 4.863,
            ic_october: 5.53,
            ic_november: 6.069,
            ic_december: 6.335,
            ic_yearly: 5.053,
          },
          {
            ic_uuid: "7d1e3db1-f278-4c88-bb60-271457399ebc",
            ic_lat: -38997,
            ic_lon: -383899,
            ic_city: "Aquiraz",
            ic_states: "CEARÁ",
            ic_january: 5.805,
            ic_february: 5.895,
            ic_march: 5.672,
            ic_april: 5.011,
            ic_may: 5.283,
            ic_june: 5.255,
            ic_july: 5.403,
            ic_august: 5.782,
            ic_september: 5.987,
            ic_october: 6.222,
            ic_november: 6.367,
            ic_december: 6.073,
            ic_yearly: 5.729,
          },
          {
            ic_uuid: "09baaaee-9a8d-4130-b189-0d5366f0a34f",
            ic_lat: -271592,
            ic_lon: -521427,
            ic_city: "Arabutã",
            ic_states: "SANTA CATARINA",
            ic_january: 6.156,
            ic_february: 5.642,
            ic_march: 5.103,
            ic_april: 4.034,
            ic_may: 3.099,
            ic_june: 2.603,
            ic_july: 2.878,
            ic_august: 3.772,
            ic_september: 3.994,
            ic_october: 5.036,
            ic_november: 6.15,
            ic_december: 6.401,
            ic_yearly: 4.572,
          },
          {
            ic_uuid: "a2f22c10-7f89-417b-a321-e6b8bd17da4b",
            ic_lat: -68521,
            ic_lon: -353793,
            ic_city: "Araçagi",
            ic_states: "PARAÍBA",
            ic_january: 5.642,
            ic_february: 5.687,
            ic_march: 5.818,
            ic_april: 5.322,
            ic_may: 4.703,
            ic_june: 4.22,
            ic_july: 4.358,
            ic_august: 5.085,
            ic_september: 5.524,
            ic_october: 5.814,
            ic_november: 6.062,
            ic_december: 5.902,
            ic_yearly: 5.345,
          },
          {
            ic_uuid: "1621acee-a15e-4afd-9784-87b1304be225",
            ic_lat: -19196,
            ic_lon: -442497,
            ic_city: "Araçaí",
            ic_states: "MINAS GERAIS",
            ic_january: 5.983,
            ic_february: 6.241,
            ic_march: 5.351,
            ic_april: 5.016,
            ic_may: 4.442,
            ic_june: 4.202,
            ic_july: 4.539,
            ic_august: 5.327,
            ic_september: 5.703,
            ic_october: 5.747,
            ic_november: 5.471,
            ic_december: 5.848,
            ic_yearly: 5.323,
          },
          {
            ic_uuid: "3c43de9a-6958-4b27-9f0e-42d876fab4b2",
            ic_lat: -1091,
            ic_lon: -370683,
            ic_city: "Aracaju",
            ic_states: "SERGIPE",
            ic_january: 6.277,
            ic_february: 6.384,
            ic_march: 6.079,
            ic_april: 5.09,
            ic_may: 4.429,
            ic_june: 4.068,
            ic_july: 4.192,
            ic_august: 4.826,
            ic_september: 5.592,
            ic_october: 6.073,
            ic_november: 6.356,
            ic_december: 6.584,
            ic_yearly: 5.496,
          },
          {
            ic_uuid: "34007448-2fb2-4ad0-b29d-9aa606dbf1fe",
            ic_lat: -234371,
            ic_lon: -470612,
            ic_city: "Araçariguama",
            ic_states: "SÃO PAULO",
            ic_january: 5.41,
            ic_february: 5.69,
            ic_march: 4.987,
            ic_april: 4.434,
            ic_may: 3.62,
            ic_june: 3.334,
            ic_july: 3.451,
            ic_august: 4.454,
            ic_september: 4.529,
            ic_october: 5.069,
            ic_november: 5.505,
            ic_december: 6.005,
            ic_yearly: 4.707,
          },
          {
            ic_uuid: "6d736ae1-b645-4651-a48c-30bef1d6dbef",
            ic_lat: -122144,
            ic_lon: -38202,
            ic_city: "Araças",
            ic_states: "BAHIA",
            ic_january: 6.035,
            ic_february: 5.882,
            ic_march: 5.722,
            ic_april: 4.678,
            ic_may: 3.988,
            ic_june: 3.797,
            ic_july: 3.943,
            ic_august: 4.41,
            ic_september: 5.208,
            ic_october: 5.528,
            ic_november: 5.88,
            ic_december: 6.158,
            ic_yearly: 5.102,
          },
          {
            ic_uuid: "391074a5-6219-4520-a54c-9f93a0007592",
            ic_lat: -45587,
            ic_lon: -377682,
            ic_city: "Aracati",
            ic_states: "CEARÁ",
            ic_january: 6.075,
            ic_february: 6.128,
            ic_march: 6.031,
            ic_april: 5.404,
            ic_may: 5.348,
            ic_june: 5.102,
            ic_july: 5.43,
            ic_august: 6.047,
            ic_september: 6.222,
            ic_october: 6.347,
            ic_november: 6.42,
            ic_december: 6.346,
            ic_yearly: 5.908,
          },
          {
            ic_uuid: "1233f831-296b-4df9-8095-ee8f84b8f38f",
            ic_lat: -144284,
            ic_lon: -414651,
            ic_city: "Aracatu",
            ic_states: "BAHIA",
            ic_january: 6.365,
            ic_february: 6.355,
            ic_march: 5.684,
            ic_april: 4.903,
            ic_may: 4.386,
            ic_june: 3.931,
            ic_july: 4.197,
            ic_august: 4.867,
            ic_september: 5.774,
            ic_october: 5.97,
            ic_november: 5.832,
            ic_december: 6.413,
            ic_yearly: 5.39,
          },
          {
            ic_uuid: "6961d554-8d06-4603-8243-731b270c4d6e",
            ic_lat: -212081,
            ic_lon: -504405,
            ic_city: "Araçatuba",
            ic_states: "SÃO PAULO",
            ic_january: 5.966,
            ic_february: 5.993,
            ic_march: 5.402,
            ic_april: 4.881,
            ic_may: 4.044,
            ic_june: 3.741,
            ic_july: 3.973,
            ic_august: 4.892,
            ic_september: 4.925,
            ic_october: 5.639,
            ic_november: 6.113,
            ic_december: 6.342,
            ic_yearly: 5.159,
          },
          {
            ic_uuid: "d44e8dd6-c30c-4e84-ac73-bc7496e21b5b",
            ic_lat: -113333,
            ic_lon: -389599,
            ic_city: "Araci",
            ic_states: "BAHIA",
            ic_january: 6.191,
            ic_february: 5.96,
            ic_march: 5.791,
            ic_april: 4.902,
            ic_may: 4.125,
            ic_june: 3.844,
            ic_july: 4.005,
            ic_august: 4.495,
            ic_september: 5.354,
            ic_october: 5.627,
            ic_november: 6.007,
            ic_december: 6.123,
            ic_yearly: 5.202,
          },
          {
            ic_uuid: "c89a8552-d4e6-4477-8d20-03dc337b98dd",
            ic_lat: -213451,
            ic_lon: -43374,
            ic_city: "Aracitaba",
            ic_states: "MINAS GERAIS",
            ic_january: 5.507,
            ic_february: 5.837,
            ic_march: 4.857,
            ic_april: 4.247,
            ic_may: 3.629,
            ic_june: 3.47,
            ic_july: 3.618,
            ic_august: 4.415,
            ic_september: 4.693,
            ic_october: 4.972,
            ic_november: 4.722,
            ic_december: 5.484,
            ic_yearly: 4.621,
          },
          {
            ic_uuid: "af5ce57c-6ee0-446f-8ebb-824f9396efd2",
            ic_lat: -43691,
            ic_lon: -388128,
            ic_city: "Aracoiaba",
            ic_states: "CEARÁ",
            ic_january: 5.258,
            ic_february: 5.324,
            ic_march: 5.334,
            ic_april: 4.82,
            ic_may: 4.924,
            ic_june: 4.754,
            ic_july: 5.168,
            ic_august: 5.886,
            ic_september: 6.208,
            ic_october: 6.141,
            ic_november: 6.012,
            ic_december: 5.483,
            ic_yearly: 5.443,
          },
          {
            ic_uuid: "5827a4b8-817c-49ee-98fb-90a6d67308df",
            ic_lat: -77833,
            ic_lon: -350831,
            ic_city: "Araçoiaba",
            ic_states: "PERNAMBUCO",
            ic_january: 5.733,
            ic_february: 5.874,
            ic_march: 5.903,
            ic_april: 5.15,
            ic_may: 4.456,
            ic_june: 4.116,
            ic_july: 4.291,
            ic_august: 5.022,
            ic_september: 5.449,
            ic_october: 5.732,
            ic_november: 5.994,
            ic_december: 6.038,
            ic_yearly: 5.313,
          },
          {
            ic_uuid: "daaa7e3d-3054-4f39-8c12-807e06f996b4",
            ic_lat: -235034,
            ic_lon: -47617,
            ic_city: "Araçoiaba da Serra",
            ic_states: "SÃO PAULO",
            ic_january: 5.5,
            ic_february: 5.726,
            ic_march: 5.039,
            ic_april: 4.44,
            ic_may: 3.604,
            ic_june: 3.358,
            ic_july: 3.453,
            ic_august: 4.456,
            ic_september: 4.609,
            ic_october: 5.171,
            ic_november: 5.629,
            ic_december: 6.085,
            ic_yearly: 4.756,
          },
          {
            ic_uuid: "74e30c3e-b564-462f-bef5-e626e4371348",
            ic_lat: -198205,
            ic_lon: -402768,
            ic_city: "Aracruz",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.092,
            ic_february: 6.266,
            ic_march: 5.357,
            ic_april: 4.517,
            ic_may: 3.902,
            ic_june: 3.625,
            ic_july: 3.701,
            ic_august: 4.279,
            ic_september: 4.717,
            ic_october: 4.864,
            ic_november: 4.932,
            ic_december: 5.766,
            ic_yearly: 4.835,
          },
          {
            ic_uuid: "ad56b41f-fc9b-4424-80a0-9913c2472a4a",
            ic_lat: -163568,
            ic_lon: -496808,
            ic_city: "Araçu",
            ic_states: "GOIÁS",
            ic_january: 5.498,
            ic_february: 5.555,
            ic_march: 5.163,
            ic_april: 5.146,
            ic_may: 4.88,
            ic_june: 4.58,
            ic_july: 4.816,
            ic_august: 5.751,
            ic_september: 5.487,
            ic_october: 5.555,
            ic_november: 5.324,
            ic_december: 5.454,
            ic_yearly: 5.267,
          },
          {
            ic_uuid: "423c7db4-5366-48bb-8450-df375afb247d",
            ic_lat: -168528,
            ic_lon: -420641,
            ic_city: "Araçuaí",
            ic_states: "MINAS GERAIS",
            ic_january: 6.358,
            ic_february: 6.492,
            ic_march: 5.566,
            ic_april: 4.858,
            ic_may: 4.16,
            ic_june: 3.899,
            ic_july: 4.103,
            ic_august: 4.831,
            ic_september: 5.47,
            ic_october: 5.723,
            ic_november: 5.466,
            ic_december: 6.198,
            ic_yearly: 5.26,
          },
          {
            ic_uuid: "783fcdec-2f43-49fc-85da-d4e6678afdcf",
            ic_lat: -159033,
            ic_lon: -52245,
            ic_city: "Aragarças",
            ic_states: "GOIÁS",
            ic_january: 5.473,
            ic_february: 5.603,
            ic_march: 5.201,
            ic_april: 5.294,
            ic_may: 4.941,
            ic_june: 4.585,
            ic_july: 4.721,
            ic_august: 5.489,
            ic_september: 5.292,
            ic_october: 5.489,
            ic_november: 5.43,
            ic_december: 5.545,
            ic_yearly: 5.256,
          },
          {
            ic_uuid: "09b6088d-9798-4c30-a579-70934467d883",
            ic_lat: -169091,
            ic_lon: -49448,
            ic_city: "Aragoiânia",
            ic_states: "GOIÁS",
            ic_january: 5.524,
            ic_february: 5.574,
            ic_march: 5.276,
            ic_april: 5.115,
            ic_may: 4.778,
            ic_june: 4.518,
            ic_july: 4.721,
            ic_august: 5.534,
            ic_september: 5.448,
            ic_october: 5.556,
            ic_november: 5.427,
            ic_december: 5.513,
            ic_yearly: 5.249,
          },
          {
            ic_uuid: "657d2d5f-19a9-4e4d-9d57-638dc8a0f56f",
            ic_lat: -71674,
            ic_lon: -485274,
            ic_city: "Aragominas",
            ic_states: "TOCANTINS",
            ic_january: 4.645,
            ic_february: 4.77,
            ic_march: 4.58,
            ic_april: 4.69,
            ic_may: 4.919,
            ic_june: 5.176,
            ic_july: 5.22,
            ic_august: 5.849,
            ic_september: 5.643,
            ic_october: 5.022,
            ic_november: 4.686,
            ic_december: 4.595,
            ic_yearly: 4.983,
          },
          {
            ic_uuid: "24af9c4f-0f3c-49c9-90b0-5bd9db4c2c93",
            ic_lat: -8808,
            ic_lon: -495573,
            ic_city: "Araguacema",
            ic_states: "TOCANTINS",
            ic_january: 4.904,
            ic_february: 4.977,
            ic_march: 4.814,
            ic_april: 4.766,
            ic_may: 4.949,
            ic_june: 5.08,
            ic_july: 5.252,
            ic_august: 5.874,
            ic_september: 5.562,
            ic_october: 5.107,
            ic_november: 5.032,
            ic_december: 4.964,
            ic_yearly: 5.106,
          },
          {
            ic_uuid: "94301b6e-ae61-41a7-b31b-f6862234bdea",
            ic_lat: -129273,
            ic_lon: -498314,
            ic_city: "Araguaçu",
            ic_states: "TOCANTINS",
            ic_january: 5.322,
            ic_february: 5.422,
            ic_march: 5.074,
            ic_april: 5.096,
            ic_may: 5.031,
            ic_june: 4.812,
            ic_july: 5.278,
            ic_august: 5.795,
            ic_september: 5.682,
            ic_october: 5.491,
            ic_november: 5.224,
            ic_december: 5.352,
            ic_yearly: 5.298,
          },
          {
            ic_uuid: "d3bc5d93-324e-4ee4-8117-bf8ee6257756",
            ic_lat: -157241,
            ic_lon: -518378,
            ic_city: "Araguaiana",
            ic_states: "MATO GROSSO",
            ic_january: 5.588,
            ic_february: 5.724,
            ic_march: 5.255,
            ic_april: 5.336,
            ic_may: 4.904,
            ic_june: 4.503,
            ic_july: 4.717,
            ic_august: 5.263,
            ic_september: 5.358,
            ic_october: 5.517,
            ic_november: 5.403,
            ic_december: 5.578,
            ic_yearly: 5.262,
          },
          {
            ic_uuid: "760d455f-2269-45a5-8dfa-92f239e470c8",
            ic_lat: -71928,
            ic_lon: -482048,
            ic_city: "Araguaína",
            ic_states: "TOCANTINS",
            ic_january: 4.657,
            ic_february: 4.828,
            ic_march: 4.604,
            ic_april: 4.765,
            ic_may: 4.914,
            ic_june: 5.199,
            ic_july: 5.282,
            ic_august: 5.908,
            ic_september: 5.711,
            ic_october: 5.069,
            ic_november: 4.728,
            ic_december: 4.623,
            ic_yearly: 5.024,
          },
          {
            ic_uuid: "be9460e0-58ac-4bf1-b917-f0f7b9a920d8",
            ic_lat: -168569,
            ic_lon: -530317,
            ic_city: "Araguainha",
            ic_states: "MATO GROSSO",
            ic_january: 5.423,
            ic_february: 5.623,
            ic_march: 5.285,
            ic_april: 5.209,
            ic_may: 4.786,
            ic_june: 4.47,
            ic_july: 4.598,
            ic_august: 5.479,
            ic_september: 5.372,
            ic_october: 5.325,
            ic_november: 5.561,
            ic_december: 5.702,
            ic_yearly: 5.236,
          },
          {
            ic_uuid: "e9405100-ddac-4f96-bfb6-3ad160c87954",
            ic_lat: -29507,
            ic_lon: -456655,
            ic_city: "Araguanã",
            ic_states: "MARANHÃO",
            ic_january: 4.565,
            ic_february: 4.75,
            ic_march: 4.64,
            ic_april: 4.68,
            ic_may: 4.719,
            ic_june: 4.86,
            ic_july: 4.989,
            ic_august: 5.445,
            ic_september: 5.646,
            ic_october: 5.367,
            ic_november: 5.061,
            ic_december: 4.735,
            ic_yearly: 4.955,
          },
          {
            ic_uuid: "aaeab442-08a4-48df-9f3c-1206ed383dbd",
            ic_lat: -65799,
            ic_lon: -486427,
            ic_city: "Araguanã",
            ic_states: "TOCANTINS",
            ic_january: 4.768,
            ic_february: 4.828,
            ic_march: 4.759,
            ic_april: 4.719,
            ic_may: 4.882,
            ic_june: 5.144,
            ic_july: 5.275,
            ic_august: 5.806,
            ic_september: 5.585,
            ic_october: 5.025,
            ic_november: 4.737,
            ic_december: 4.597,
            ic_yearly: 5.01,
          },
          {
            ic_uuid: "c627f267-5251-4bf5-adee-017632b259a2",
            ic_lat: -150913,
            ic_lon: -506319,
            ic_city: "Araguapaz",
            ic_states: "GOIÁS",
            ic_january: 5.442,
            ic_february: 5.609,
            ic_march: 5.384,
            ic_april: 5.262,
            ic_may: 4.919,
            ic_june: 4.6,
            ic_july: 4.837,
            ic_august: 5.514,
            ic_september: 5.431,
            ic_october: 5.588,
            ic_november: 5.395,
            ic_december: 5.427,
            ic_yearly: 5.284,
          },
          {
            ic_uuid: "a9d63a1b-678f-4334-a99c-059e9b79e256",
            ic_lat: -18646,
            ic_lon: -481938,
            ic_city: "Araguari",
            ic_states: "MINAS GERAIS",
            ic_january: 5.575,
            ic_february: 5.92,
            ic_march: 5.176,
            ic_april: 5.148,
            ic_may: 4.636,
            ic_june: 4.422,
            ic_july: 4.601,
            ic_august: 5.586,
            ic_september: 5.508,
            ic_october: 5.683,
            ic_november: 5.552,
            ic_december: 5.775,
            ic_yearly: 5.299,
          },
          {
            ic_uuid: "4fda6fa3-1892-437b-a95a-1352e58fa414",
            ic_lat: -5647,
            ic_lon: -481236,
            ic_city: "Araguatins",
            ic_states: "TOCANTINS",
            ic_january: 4.615,
            ic_february: 4.748,
            ic_march: 4.887,
            ic_april: 4.893,
            ic_may: 4.839,
            ic_june: 5.116,
            ic_july: 5.228,
            ic_august: 5.743,
            ic_september: 5.476,
            ic_october: 5.047,
            ic_november: 4.854,
            ic_december: 4.693,
            ic_yearly: 5.012,
          },
          {
            ic_uuid: "845c0fee-3155-4871-a804-2c9c61df6938",
            ic_lat: -28913,
            ic_lon: -419053,
            ic_city: "Araioses",
            ic_states: "MARANHÃO",
            ic_january: 5.563,
            ic_february: 5.375,
            ic_march: 5.243,
            ic_april: 4.95,
            ic_may: 5.131,
            ic_june: 5.311,
            ic_july: 5.51,
            ic_august: 6.152,
            ic_september: 6.298,
            ic_october: 6.181,
            ic_november: 6.368,
            ic_december: 6.073,
            ic_yearly: 5.679,
          },
          {
            ic_uuid: "4b438c7f-2abf-4988-9105-072d5dfdb336",
            ic_lat: -229427,
            ic_lon: -556322,
            ic_city: "Aral Moreira",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.974,
            ic_february: 5.621,
            ic_march: 5.297,
            ic_april: 4.384,
            ic_may: 3.45,
            ic_june: 3.157,
            ic_july: 3.259,
            ic_august: 4.304,
            ic_september: 4.603,
            ic_october: 5.317,
            ic_november: 5.904,
            ic_december: 6.347,
            ic_yearly: 4.801,
          },
          {
            ic_uuid: "5a384b30-4c0e-40b6-b5d3-4c26470c637b",
            ic_lat: -120888,
            ic_lon: -384973,
            ic_city: "Aramari",
            ic_states: "BAHIA",
            ic_january: 6.073,
            ic_february: 5.877,
            ic_march: 5.716,
            ic_april: 4.712,
            ic_may: 3.907,
            ic_june: 3.672,
            ic_july: 3.906,
            ic_august: 4.38,
            ic_september: 5.214,
            ic_october: 5.494,
            ic_november: 5.831,
            ic_december: 6.145,
            ic_yearly: 5.077,
          },
          {
            ic_uuid: "790f7a7a-4bbf-4b82-8606-b2c6bc1ae5fb",
            ic_lat: -309097,
            ic_lon: -51505,
            ic_city: "Arambaré",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.478,
            ic_february: 5.814,
            ic_march: 4.881,
            ic_april: 3.737,
            ic_may: 2.771,
            ic_june: 2.263,
            ic_july: 2.474,
            ic_august: 3.095,
            ic_september: 3.6,
            ic_october: 4.747,
            ic_november: 6.175,
            ic_december: 6.686,
            ic_yearly: 4.393,
          },
          {
            ic_uuid: "55125555-7b5e-42e1-91ce-aeae95c7d600",
            ic_lat: -48882,
            ic_lon: -460087,
            ic_city: "Arame",
            ic_states: "MARANHÃO",
            ic_january: 4.535,
            ic_february: 4.695,
            ic_march: 4.805,
            ic_april: 4.698,
            ic_may: 4.737,
            ic_june: 4.93,
            ic_july: 5.175,
            ic_august: 5.576,
            ic_september: 5.698,
            ic_october: 5.178,
            ic_november: 4.76,
            ic_december: 4.665,
            ic_yearly: 4.954,
          },
          {
            ic_uuid: "55a5d276-79ec-4ee5-b4dd-2324a093a748",
            ic_lat: -200886,
            ic_lon: -477876,
            ic_city: "Aramina",
            ic_states: "SÃO PAULO",
            ic_january: 5.659,
            ic_february: 5.973,
            ic_march: 5.18,
            ic_april: 5.044,
            ic_may: 4.331,
            ic_june: 4.117,
            ic_july: 4.359,
            ic_august: 5.276,
            ic_september: 5.214,
            ic_october: 5.626,
            ic_november: 5.676,
            ic_december: 5.965,
            ic_yearly: 5.202,
          },
          {
            ic_uuid: "ff730ec9-a3a0-4da3-85f0-5f163105d02a",
            ic_lat: -23134,
            ic_lon: -490539,
            ic_city: "Arandu",
            ic_states: "SÃO PAULO",
            ic_january: 5.537,
            ic_february: 5.673,
            ic_march: 5.039,
            ic_april: 4.555,
            ic_may: 3.671,
            ic_june: 3.388,
            ic_july: 3.551,
            ic_august: 4.559,
            ic_september: 4.718,
            ic_october: 5.298,
            ic_november: 5.79,
            ic_december: 6.137,
            ic_yearly: 4.826,
          },
          {
            ic_uuid: "bbf92986-d887-4a86-862d-8faf495746de",
            ic_lat: -219107,
            ic_lon: -442559,
            ic_city: "Arantina",
            ic_states: "MINAS GERAIS",
            ic_january: 5.421,
            ic_february: 5.749,
            ic_march: 4.86,
            ic_april: 4.352,
            ic_may: 3.73,
            ic_june: 3.553,
            ic_july: 3.683,
            ic_august: 4.617,
            ic_september: 4.811,
            ic_october: 5.038,
            ic_november: 4.999,
            ic_december: 5.454,
            ic_yearly: 4.689,
          },
          {
            ic_uuid: "e30f65f6-7623-4507-9d95-5e06e78c924c",
            ic_lat: -226722,
            ic_lon: -444445,
            ic_city: "Arapeí",
            ic_states: "SÃO PAULO",
            ic_january: 5.497,
            ic_february: 5.845,
            ic_march: 4.741,
            ic_april: 4.23,
            ic_may: 3.592,
            ic_june: 3.369,
            ic_july: 3.462,
            ic_august: 4.308,
            ic_september: 4.519,
            ic_october: 4.903,
            ic_november: 4.912,
            ic_december: 5.711,
            ic_yearly: 4.591,
          },
          {
            ic_uuid: "6ddb5b84-1ed7-4589-b0c1-ad8601f34e3d",
            ic_lat: -97553,
            ic_lon: -366618,
            ic_city: "Arapiraca",
            ic_states: "ALAGOAS",
            ic_january: 6.047,
            ic_february: 5.817,
            ic_march: 5.833,
            ic_april: 5.096,
            ic_may: 4.246,
            ic_june: 3.958,
            ic_july: 4.072,
            ic_august: 4.644,
            ic_september: 5.404,
            ic_october: 5.764,
            ic_november: 6.285,
            ic_december: 6.289,
            ic_yearly: 5.288,
          },
          {
            ic_uuid: "dc7d6116-2b4b-4367-bc77-54d39dcd5a44",
            ic_lat: -7655,
            ic_lon: -490641,
            ic_city: "Arapoema",
            ic_states: "TOCANTINS",
            ic_january: 4.677,
            ic_february: 4.805,
            ic_march: 4.665,
            ic_april: 4.617,
            ic_may: 4.922,
            ic_june: 5.076,
            ic_july: 5.145,
            ic_august: 5.888,
            ic_september: 5.6,
            ic_october: 5.037,
            ic_november: 4.702,
            ic_december: 4.618,
            ic_yearly: 4.979,
          },
          {
            ic_uuid: "37785f08-b183-4bf2-94c0-b3314eec9244",
            ic_lat: -206691,
            ic_lon: -425182,
            ic_city: "Araponga",
            ic_states: "MINAS GERAIS",
            ic_january: 5.641,
            ic_february: 5.862,
            ic_march: 4.936,
            ic_april: 4.439,
            ic_may: 3.852,
            ic_june: 3.688,
            ic_july: 3.858,
            ic_august: 4.639,
            ic_september: 4.986,
            ic_october: 5.159,
            ic_november: 4.872,
            ic_december: 5.582,
            ic_yearly: 4.793,
          },
          {
            ic_uuid: "fc44298a-8a4b-4fcb-936f-57cf278cc62d",
            ic_lat: -234158,
            ic_lon: -514263,
            ic_city: "Arapongas",
            ic_states: "PARANÁ",
            ic_january: 5.864,
            ic_february: 5.743,
            ic_march: 5.202,
            ic_april: 4.587,
            ic_may: 3.634,
            ic_june: 3.371,
            ic_july: 3.564,
            ic_august: 4.476,
            ic_september: 4.677,
            ic_october: 5.331,
            ic_november: 5.949,
            ic_december: 6.21,
            ic_yearly: 4.884,
          },
          {
            ic_uuid: "e70c6f8a-f906-40d3-9224-fc1311c2ea40",
            ic_lat: -184362,
            ic_lon: -491851,
            ic_city: "Araporã",
            ic_states: "MINAS GERAIS",
            ic_january: 5.621,
            ic_february: 5.806,
            ic_march: 5.206,
            ic_april: 5.055,
            ic_may: 4.628,
            ic_june: 4.353,
            ic_july: 4.526,
            ic_august: 5.405,
            ic_september: 5.293,
            ic_october: 5.645,
            ic_november: 5.681,
            ic_december: 5.796,
            ic_yearly: 5.251,
          },
          {
            ic_uuid: "4fcc6249-f060-49e5-a992-9ad060c845b1",
            ic_lat: -241412,
            ic_lon: -49824,
            ic_city: "Arapoti",
            ic_states: "PARANÁ",
            ic_january: 5.637,
            ic_february: 5.635,
            ic_march: 5.037,
            ic_april: 4.405,
            ic_may: 3.529,
            ic_june: 3.132,
            ic_july: 3.406,
            ic_august: 4.342,
            ic_september: 4.562,
            ic_october: 5.109,
            ic_november: 5.894,
            ic_december: 6.151,
            ic_yearly: 4.736,
          },
          {
            ic_uuid: "91c28659-4dd6-4413-a4d3-9de05cac471f",
            ic_lat: -19035,
            ic_lon: -461549,
            ic_city: "Arapuá",
            ic_states: "MINAS GERAIS",
            ic_january: 5.716,
            ic_february: 6.046,
            ic_march: 5.05,
            ic_april: 5.006,
            ic_may: 4.57,
            ic_june: 4.333,
            ic_july: 4.673,
            ic_august: 5.641,
            ic_september: 5.642,
            ic_october: 5.552,
            ic_november: 5.175,
            ic_december: 5.599,
            ic_yearly: 5.25,
          },
          {
            ic_uuid: "f0ca3aba-982e-4876-937f-d12ce5abffb6",
            ic_lat: -243136,
            ic_lon: -517861,
            ic_city: "Arapuã",
            ic_states: "PARANÁ",
            ic_january: 6.051,
            ic_february: 5.771,
            ic_march: 5.242,
            ic_april: 4.527,
            ic_may: 3.513,
            ic_june: 3.138,
            ic_july: 3.367,
            ic_august: 4.355,
            ic_september: 4.528,
            ic_october: 5.255,
            ic_november: 6.092,
            ic_december: 6.348,
            ic_yearly: 4.849,
          },
          {
            ic_uuid: "b6bad123-25b3-42fe-9109-92d701213f03",
            ic_lat: -154645,
            ic_lon: -583429,
            ic_city: "Araputanga",
            ic_states: "MATO GROSSO",
            ic_january: 5.311,
            ic_february: 5.103,
            ic_march: 4.919,
            ic_april: 4.701,
            ic_may: 4.075,
            ic_june: 4.188,
            ic_july: 4.298,
            ic_august: 5.103,
            ic_september: 5.016,
            ic_october: 5.175,
            ic_november: 5.39,
            ic_december: 5.437,
            ic_yearly: 4.893,
          },
          {
            ic_uuid: "7d93e914-46b2-453b-bda8-92af26cd4c62",
            ic_lat: -263759,
            ic_lon: -487192,
            ic_city: "Araquari",
            ic_states: "SANTA CATARINA",
            ic_january: 5.29,
            ic_february: 5.134,
            ic_march: 4.443,
            ic_april: 3.628,
            ic_may: 2.978,
            ic_june: 2.457,
            ic_july: 2.54,
            ic_august: 3.191,
            ic_september: 3.295,
            ic_october: 3.922,
            ic_november: 4.948,
            ic_december: 5.338,
            ic_yearly: 3.93,
          },
          {
            ic_uuid: "f9e49b52-4dbb-4a37-9e8b-156f2ab2d982",
            ic_lat: -68285,
            ic_lon: -357555,
            ic_city: "Arara",
            ic_states: "PARAÍBA",
            ic_january: 5.757,
            ic_february: 5.801,
            ic_march: 5.899,
            ic_april: 5.337,
            ic_may: 4.779,
            ic_june: 4.29,
            ic_july: 4.476,
            ic_august: 5.243,
            ic_september: 5.688,
            ic_october: 5.88,
            ic_november: 6.143,
            ic_december: 5.887,
            ic_yearly: 5.431,
          },
          {
            ic_uuid: "cbad1be1-0bd9-45c1-8664-9f2bdaef2b9d",
            ic_lat: -289361,
            ic_lon: -494922,
            ic_city: "Araranguá",
            ic_states: "SANTA CATARINA",
            ic_january: 5.901,
            ic_february: 5.469,
            ic_march: 4.667,
            ic_april: 3.86,
            ic_may: 3.02,
            ic_june: 2.461,
            ic_july: 2.695,
            ic_august: 3.401,
            ic_september: 3.564,
            ic_october: 4.406,
            ic_november: 5.791,
            ic_december: 6.19,
            ic_yearly: 4.286,
          },
          {
            ic_uuid: "8361fdf9-4366-4bd1-afd6-c9b525bb16fc",
            ic_lat: -21785,
            ic_lon: -481784,
            ic_city: "Araraquara",
            ic_states: "SÃO PAULO",
            ic_january: 5.637,
            ic_february: 5.873,
            ic_march: 5.127,
            ic_april: 4.719,
            ic_may: 3.992,
            ic_june: 3.72,
            ic_july: 3.944,
            ic_august: 4.849,
            ic_september: 4.954,
            ic_october: 5.533,
            ic_november: 5.754,
            ic_december: 6.0,
            ic_yearly: 5.009,
          },
          {
            ic_uuid: "f308c7e3-944c-4ca4-a6e0-9f12a5c162ce",
            ic_lat: -223577,
            ic_lon: -473846,
            ic_city: "Araras",
            ic_states: "SÃO PAULO",
            ic_january: 5.561,
            ic_february: 5.811,
            ic_march: 5.123,
            ic_april: 4.649,
            ic_may: 3.866,
            ic_june: 3.629,
            ic_july: 3.788,
            ic_august: 4.755,
            ic_september: 4.894,
            ic_october: 5.496,
            ic_november: 5.682,
            ic_december: 6.04,
            ic_yearly: 4.941,
          },
          {
            ic_uuid: "22854938-c37e-4a79-8694-685d98a240db",
            ic_lat: -47461,
            ic_lon: -408313,
            ic_city: "Ararendá",
            ic_states: "CEARÁ",
            ic_january: 4.985,
            ic_february: 5.084,
            ic_march: 5.171,
            ic_april: 4.733,
            ic_may: 4.819,
            ic_june: 4.819,
            ic_july: 5.356,
            ic_august: 6.164,
            ic_september: 6.67,
            ic_october: 6.564,
            ic_november: 6.338,
            ic_december: 5.612,
            ic_yearly: 5.526,
          },
          {
            ic_uuid: "f33c2e28-2b6e-48f7-b936-48c366f3f812",
            ic_lat: -34554,
            ic_lon: -447771,
            ic_city: "Arari",
            ic_states: "MARANHÃO",
            ic_january: 4.755,
            ic_february: 4.932,
            ic_march: 4.911,
            ic_april: 4.846,
            ic_may: 4.859,
            ic_june: 5.039,
            ic_july: 5.235,
            ic_august: 5.766,
            ic_september: 6.056,
            ic_october: 5.702,
            ic_november: 5.331,
            ic_december: 5.076,
            ic_yearly: 5.209,
          },
          {
            ic_uuid: "2f3b1ec7-3b4a-45ad-b2fd-37cee12c26f0",
            ic_lat: -296173,
            ic_lon: -509296,
            ic_city: "Araricá",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.081,
            ic_february: 5.596,
            ic_march: 4.652,
            ic_april: 3.732,
            ic_may: 2.789,
            ic_june: 2.338,
            ic_july: 2.549,
            ic_august: 3.224,
            ic_september: 3.497,
            ic_october: 4.557,
            ic_november: 5.979,
            ic_december: 6.375,
            ic_yearly: 4.281,
          },
          {
            ic_uuid: "23e291cb-2dc1-49c7-a509-6b29d9bd1662",
            ic_lat: -72136,
            ic_lon: -401362,
            ic_city: "Araripe",
            ic_states: "CEARÁ",
            ic_january: 5.471,
            ic_february: 5.45,
            ic_march: 5.496,
            ic_april: 5.236,
            ic_may: 5.08,
            ic_june: 5.118,
            ic_july: 5.47,
            ic_august: 6.209,
            ic_september: 6.553,
            ic_october: 6.445,
            ic_november: 6.383,
            ic_december: 5.948,
            ic_yearly: 5.738,
          },
          {
            ic_uuid: "ed8ea53f-569c-4c6e-97a7-4e0b150bc8a2",
            ic_lat: -75708,
            ic_lon: -40496,
            ic_city: "Araripina",
            ic_states: "PERNAMBUCO",
            ic_january: 5.701,
            ic_february: 5.442,
            ic_march: 5.545,
            ic_april: 5.141,
            ic_may: 4.84,
            ic_june: 4.659,
            ic_july: 5.048,
            ic_august: 5.919,
            ic_september: 6.435,
            ic_october: 6.439,
            ic_november: 6.4,
            ic_december: 6.064,
            ic_yearly: 5.636,
          },
          {
            ic_uuid: "a302aa10-5f48-41b4-9079-3826617cf081",
            ic_lat: -228701,
            ic_lon: -42333,
            ic_city: "Araruama",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.268,
            ic_february: 6.473,
            ic_march: 5.247,
            ic_april: 4.518,
            ic_may: 3.652,
            ic_june: 3.394,
            ic_july: 3.425,
            ic_august: 4.257,
            ic_september: 4.518,
            ic_october: 5.155,
            ic_november: 5.296,
            ic_december: 6.115,
            ic_yearly: 4.86,
          },
          {
            ic_uuid: "9531af77-5b5c-4876-a346-ed585ebac9ac",
            ic_lat: -65346,
            ic_lon: -357409,
            ic_city: "Araruna",
            ic_states: "PARAÍBA",
            ic_january: 5.777,
            ic_february: 5.853,
            ic_march: 5.939,
            ic_april: 5.41,
            ic_may: 4.877,
            ic_june: 4.406,
            ic_july: 4.588,
            ic_august: 5.299,
            ic_september: 5.772,
            ic_october: 5.929,
            ic_november: 6.118,
            ic_december: 5.82,
            ic_yearly: 5.482,
          },
          {
            ic_uuid: "26004181-0c6a-4912-a9bd-e0dc78bd935b",
            ic_lat: -23932,
            ic_lon: -525025,
            ic_city: "Araruna",
            ic_states: "PARANÁ",
            ic_january: 6.08,
            ic_february: 5.755,
            ic_march: 5.355,
            ic_april: 4.545,
            ic_may: 3.581,
            ic_june: 3.189,
            ic_july: 3.405,
            ic_august: 4.363,
            ic_september: 4.611,
            ic_october: 5.349,
            ic_november: 6.13,
            ic_december: 6.453,
            ic_yearly: 4.901,
          },
          {
            ic_uuid: "57f8d978-a370-42be-8231-9c4882f8b6f5",
            ic_lat: -152656,
            ic_lon: -394193,
            ic_city: "Arataca",
            ic_states: "BAHIA",
            ic_january: 5.596,
            ic_february: 5.748,
            ic_march: 5.305,
            ic_april: 4.447,
            ic_may: 3.793,
            ic_june: 3.383,
            ic_july: 3.597,
            ic_august: 4.11,
            ic_september: 4.69,
            ic_october: 4.871,
            ic_november: 4.971,
            ic_december: 5.817,
            ic_yearly: 4.694,
          },
          {
            ic_uuid: "b4bf23f6-0a2e-445a-abfa-5dd12ae62357",
            ic_lat: -273983,
            ic_lon: -522979,
            ic_city: "Aratiba",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.327,
            ic_february: 5.867,
            ic_march: 5.139,
            ic_april: 4.052,
            ic_may: 3.06,
            ic_june: 2.547,
            ic_july: 2.872,
            ic_august: 3.725,
            ic_september: 3.981,
            ic_october: 5.108,
            ic_november: 6.264,
            ic_december: 6.602,
            ic_yearly: 4.629,
          },
          {
            ic_uuid: "4bdc2d6a-c517-4579-9f9e-85190bc57616",
            ic_lat: -44127,
            ic_lon: -390474,
            ic_city: "Aratuba",
            ic_states: "CEARÁ",
            ic_january: 5.402,
            ic_february: 5.393,
            ic_march: 5.316,
            ic_april: 4.796,
            ic_may: 5.028,
            ic_june: 4.931,
            ic_july: 5.363,
            ic_august: 6.214,
            ic_september: 6.633,
            ic_october: 6.477,
            ic_november: 6.322,
            ic_december: 5.675,
            ic_yearly: 5.629,
          },
          {
            ic_uuid: "cdda0d7a-4645-457f-8e94-59482779d477",
            ic_lat: -130773,
            ic_lon: -390019,
            ic_city: "Aratuípe",
            ic_states: "BAHIA",
            ic_january: 6.106,
            ic_february: 6.003,
            ic_march: 5.775,
            ic_april: 4.638,
            ic_may: 4.023,
            ic_june: 3.616,
            ic_july: 3.827,
            ic_august: 4.422,
            ic_september: 5.155,
            ic_october: 5.351,
            ic_november: 5.677,
            ic_december: 6.096,
            ic_yearly: 5.058,
          },
          {
            ic_uuid: "3fcc25ef-f293-494e-bedc-5bfee2a14f4d",
            ic_lat: -112652,
            ic_lon: -376179,
            ic_city: "Arauá",
            ic_states: "SERGIPE",
            ic_january: 6.226,
            ic_february: 6.071,
            ic_march: 5.925,
            ic_april: 4.906,
            ic_may: 4.221,
            ic_june: 3.967,
            ic_july: 4.061,
            ic_august: 4.543,
            ic_september: 5.303,
            ic_october: 5.661,
            ic_november: 6.122,
            ic_december: 6.295,
            ic_yearly: 5.275,
          },
          {
            ic_uuid: "edd888c7-3a66-4f4b-beb4-c4277d79dcd0",
            ic_lat: -255864,
            ic_lon: -494052,
            ic_city: "Araucária",
            ic_states: "PARANÁ",
            ic_january: 5.4,
            ic_february: 5.132,
            ic_march: 4.494,
            ic_april: 3.759,
            ic_may: 3.01,
            ic_june: 2.699,
            ic_july: 2.87,
            ic_august: 3.832,
            ic_september: 3.845,
            ic_october: 4.424,
            ic_november: 5.296,
            ic_december: 5.612,
            ic_yearly: 4.198,
          },
          {
            ic_uuid: "3bb840fb-a2e9-40e5-acbf-d6d260ee7dbe",
            ic_lat: -199451,
            ic_lon: -451676,
            ic_city: "Araújos",
            ic_states: "MINAS GERAIS",
            ic_january: 5.828,
            ic_february: 6.031,
            ic_march: 5.12,
            ic_april: 4.966,
            ic_may: 4.346,
            ic_june: 4.191,
            ic_july: 4.394,
            ic_august: 5.315,
            ic_september: 5.516,
            ic_october: 5.605,
            ic_november: 5.272,
            ic_december: 5.679,
            ic_yearly: 5.189,
          },
          {
            ic_uuid: "3ef6a974-87dc-4948-a6b4-c048b98c995f",
            ic_lat: -19585,
            ic_lon: -469416,
            ic_city: "Araxá",
            ic_states: "MINAS GERAIS",
            ic_january: 5.503,
            ic_february: 5.909,
            ic_march: 4.923,
            ic_april: 4.884,
            ic_may: 4.472,
            ic_june: 4.244,
            ic_july: 4.479,
            ic_august: 5.512,
            ic_september: 5.455,
            ic_october: 5.638,
            ic_november: 5.348,
            ic_december: 5.666,
            ic_yearly: 5.17,
          },
          {
            ic_uuid: "d04bab8e-a660-4144-be13-770c3c90c179",
            ic_lat: -213595,
            ic_lon: -469405,
            ic_city: "Arceburgo",
            ic_states: "MINAS GERAIS",
            ic_january: 5.554,
            ic_february: 5.759,
            ic_march: 5.031,
            ic_april: 4.834,
            ic_may: 4.075,
            ic_june: 3.862,
            ic_july: 4.068,
            ic_august: 4.958,
            ic_september: 5.023,
            ic_october: 5.502,
            ic_november: 5.646,
            ic_december: 5.819,
            ic_yearly: 5.011,
          },
          {
            ic_uuid: "0d069eda-813c-4d85-ade7-2d7fbf2ef9d8",
            ic_lat: -217733,
            ic_lon: -504664,
            ic_city: "Arco-Íris",
            ic_states: "SÃO PAULO",
            ic_january: 5.917,
            ic_february: 5.93,
            ic_march: 5.349,
            ic_april: 4.757,
            ic_may: 3.952,
            ic_june: 3.642,
            ic_july: 3.875,
            ic_august: 4.814,
            ic_september: 4.835,
            ic_october: 5.574,
            ic_november: 6.049,
            ic_december: 6.368,
            ic_yearly: 5.088,
          },
          {
            ic_uuid: "13a3862a-fc5e-47b3-9e1a-acae616b5046",
            ic_lat: -202868,
            ic_lon: -455377,
            ic_city: "Arcos",
            ic_states: "MINAS GERAIS",
            ic_january: 5.806,
            ic_february: 5.952,
            ic_march: 5.09,
            ic_april: 4.894,
            ic_may: 4.332,
            ic_june: 4.061,
            ic_july: 4.354,
            ic_august: 5.213,
            ic_september: 5.426,
            ic_october: 5.532,
            ic_november: 5.232,
            ic_december: 5.734,
            ic_yearly: 5.136,
          },
          {
            ic_uuid: "ced0c143-0dde-4bae-b529-29f0be17befa",
            ic_lat: -84156,
            ic_lon: -370581,
            ic_city: "Arcoverde",
            ic_states: "PERNAMBUCO",
            ic_january: 6.137,
            ic_february: 6.076,
            ic_march: 6.101,
            ic_april: 5.575,
            ic_may: 4.698,
            ic_june: 4.205,
            ic_july: 4.373,
            ic_august: 5.185,
            ic_september: 6.091,
            ic_october: 6.353,
            ic_november: 6.641,
            ic_december: 6.426,
            ic_yearly: 5.655,
          },
          {
            ic_uuid: "08f721be-dfd8-4a8e-a4aa-f388edfa2d8e",
            ic_lat: -213577,
            ic_lon: -461425,
            ic_city: "Areado",
            ic_states: "MINAS GERAIS",
            ic_january: 5.56,
            ic_february: 5.767,
            ic_march: 4.904,
            ic_april: 4.616,
            ic_may: 3.966,
            ic_june: 3.703,
            ic_july: 3.999,
            ic_august: 4.957,
            ic_september: 5.061,
            ic_october: 5.405,
            ic_november: 5.254,
            ic_december: 5.594,
            ic_yearly: 4.899,
          },
          {
            ic_uuid: "b8f7db50-a96c-49a0-8d25-36678fcf3378",
            ic_lat: -222383,
            ic_lon: -43105,
            ic_city: "Areal",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.84,
            ic_february: 6.111,
            ic_march: 5.126,
            ic_april: 4.479,
            ic_may: 3.643,
            ic_june: 3.404,
            ic_july: 3.537,
            ic_august: 4.353,
            ic_september: 4.666,
            ic_october: 5.049,
            ic_november: 5.046,
            ic_december: 5.797,
            ic_yearly: 4.754,
          },
          {
            ic_uuid: "138e4b6c-d1fc-455a-a84c-bdab6b3f0300",
            ic_lat: -220283,
            ic_lon: -489117,
            ic_city: "Arealva",
            ic_states: "SÃO PAULO",
            ic_january: 5.788,
            ic_february: 5.979,
            ic_march: 5.281,
            ic_april: 4.828,
            ic_may: 3.916,
            ic_june: 3.687,
            ic_july: 3.872,
            ic_august: 4.77,
            ic_september: 4.879,
            ic_october: 5.65,
            ic_november: 5.999,
            ic_december: 6.312,
            ic_yearly: 5.08,
          },
          {
            ic_uuid: "f0045f66-9888-4ac0-82ea-5bf3c75a49a0",
            ic_lat: -69654,
            ic_lon: -357026,
            ic_city: "Areia",
            ic_states: "PARAÍBA",
            ic_january: 5.721,
            ic_february: 5.854,
            ic_march: 5.797,
            ic_april: 5.339,
            ic_may: 4.702,
            ic_june: 4.152,
            ic_july: 4.297,
            ic_august: 5.027,
            ic_september: 5.591,
            ic_october: 5.865,
            ic_november: 6.153,
            ic_december: 5.956,
            ic_yearly: 5.371,
          },
          {
            ic_uuid: "41dcd358-8f6f-4cae-bb79-d298cbd46ba3",
            ic_lat: -49529,
            ic_lon: -371255,
            ic_city: "Areia Branca",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.991,
            ic_february: 5.874,
            ic_march: 5.831,
            ic_april: 5.482,
            ic_may: 5.268,
            ic_june: 4.912,
            ic_july: 5.16,
            ic_august: 5.717,
            ic_september: 6.188,
            ic_october: 6.088,
            ic_november: 6.254,
            ic_december: 6.187,
            ic_yearly: 5.746,
          },
          {
            ic_uuid: "d57efaa3-cdf8-40f7-be8a-8c2926b48d89",
            ic_lat: -107602,
            ic_lon: -373114,
            ic_city: "Areia Branca",
            ic_states: "SERGIPE",
            ic_january: 5.996,
            ic_february: 5.891,
            ic_march: 5.789,
            ic_april: 4.934,
            ic_may: 4.129,
            ic_june: 3.797,
            ic_july: 3.92,
            ic_august: 4.426,
            ic_september: 5.184,
            ic_october: 5.575,
            ic_november: 6.071,
            ic_december: 6.136,
            ic_yearly: 5.154,
          },
          {
            ic_uuid: "22a7df71-b619-4534-80b7-6548b5b12334",
            ic_lat: -71238,
            ic_lon: -36945,
            ic_city: "Areia de Baraúnas",
            ic_states: "PARAÍBA",
            ic_january: 6.079,
            ic_february: 6.135,
            ic_march: 6.101,
            ic_april: 5.758,
            ic_may: 5.109,
            ic_june: 4.589,
            ic_july: 4.934,
            ic_august: 5.72,
            ic_september: 6.356,
            ic_october: 6.488,
            ic_november: 6.585,
            ic_december: 6.244,
            ic_yearly: 5.842,
          },
          {
            ic_uuid: "2da2ce8a-af76-4b15-949d-2435b7286bff",
            ic_lat: -70499,
            ic_lon: -359251,
            ic_city: "Areial",
            ic_states: "PARAÍBA",
            ic_january: 5.831,
            ic_february: 5.85,
            ic_march: 5.908,
            ic_april: 5.39,
            ic_may: 4.787,
            ic_june: 4.255,
            ic_july: 4.45,
            ic_august: 5.157,
            ic_september: 5.682,
            ic_october: 5.971,
            ic_november: 6.2,
            ic_december: 5.966,
            ic_yearly: 5.454,
          },
          {
            ic_uuid: "06035e90-a0ef-4572-8145-eb91e35b6f0f",
            ic_lat: -225791,
            ic_lon: -446996,
            ic_city: "Areias",
            ic_states: "SÃO PAULO",
            ic_january: 5.549,
            ic_february: 5.718,
            ic_march: 4.881,
            ic_april: 4.418,
            ic_may: 3.728,
            ic_june: 3.475,
            ic_july: 3.579,
            ic_august: 4.462,
            ic_september: 4.626,
            ic_october: 5.09,
            ic_november: 5.137,
            ic_december: 5.781,
            ic_yearly: 4.704,
          },
          {
            ic_uuid: "04aedd1d-5cd0-40b6-a9eb-d6ed805d8f6a",
            ic_lat: -22668,
            ic_lon: -486649,
            ic_city: "Areiópolis",
            ic_states: "SÃO PAULO",
            ic_january: 5.66,
            ic_february: 5.845,
            ic_march: 5.231,
            ic_april: 4.658,
            ic_may: 3.81,
            ic_june: 3.549,
            ic_july: 3.722,
            ic_august: 4.713,
            ic_september: 4.821,
            ic_october: 5.487,
            ic_november: 5.896,
            ic_december: 6.223,
            ic_yearly: 4.968,
          },
          {
            ic_uuid: "5f873d74-167d-46c5-a936-2fc012d9bd44",
            ic_lat: -144628,
            ic_lon: -568417,
            ic_city: "Arenápolis",
            ic_states: "MATO GROSSO",
            ic_january: 5.272,
            ic_february: 5.211,
            ic_march: 4.963,
            ic_april: 4.826,
            ic_may: 4.495,
            ic_june: 4.47,
            ic_july: 4.645,
            ic_august: 5.368,
            ic_september: 5.104,
            ic_october: 5.247,
            ic_november: 5.36,
            ic_december: 5.414,
            ic_yearly: 5.031,
          },
          {
            ic_uuid: "ba9b6c92-6882-4c5f-9740-2c0266a7de5e",
            ic_lat: -163861,
            ic_lon: -515597,
            ic_city: "Arenópolis",
            ic_states: "GOIÁS",
            ic_january: 5.542,
            ic_february: 5.674,
            ic_march: 5.336,
            ic_april: 5.263,
            ic_may: 4.882,
            ic_june: 4.565,
            ic_july: 4.626,
            ic_august: 5.471,
            ic_september: 5.38,
            ic_october: 5.566,
            ic_november: 5.507,
            ic_december: 5.568,
            ic_yearly: 5.282,
          },
          {
            ic_uuid: "fedcceaa-752b-4645-8890-de60ea01b3dc",
            ic_lat: -6197,
            ic_lon: -351628,
            ic_city: "Arês",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.93,
            ic_february: 6.135,
            ic_march: 6.15,
            ic_april: 5.536,
            ic_may: 5.041,
            ic_june: 4.503,
            ic_july: 4.664,
            ic_august: 5.467,
            ic_september: 5.918,
            ic_october: 6.181,
            ic_november: 6.293,
            ic_december: 6.102,
            ic_yearly: 5.66,
          },
          {
            ic_uuid: "ec925d0b-750a-4fb1-847f-731b81707bcb",
            ic_lat: -216088,
            ic_lon: -428295,
            ic_city: "Argirita",
            ic_states: "MINAS GERAIS",
            ic_january: 5.762,
            ic_february: 5.992,
            ic_march: 5.073,
            ic_april: 4.333,
            ic_may: 3.633,
            ic_june: 3.406,
            ic_july: 3.57,
            ic_august: 4.356,
            ic_september: 4.719,
            ic_october: 5.016,
            ic_november: 4.822,
            ic_december: 5.676,
            ic_yearly: 4.697,
          },
          {
            ic_uuid: "3a4b7503-6791-4eb1-b788-a92264b9dcf3",
            ic_lat: -17867,
            ic_lon: -42557,
            ic_city: "Aricanduva",
            ic_states: "MINAS GERAIS",
            ic_january: 5.84,
            ic_february: 6.139,
            ic_march: 5.129,
            ic_april: 4.496,
            ic_may: 3.894,
            ic_june: 3.757,
            ic_july: 3.945,
            ic_august: 4.72,
            ic_september: 5.228,
            ic_october: 5.329,
            ic_november: 4.835,
            ic_december: 5.638,
            ic_yearly: 4.913,
          },
          {
            ic_uuid: "b1c8e6d2-e814-4f7d-a1c1-02e8026983d1",
            ic_lat: -159109,
            ic_lon: -461081,
            ic_city: "Arinos",
            ic_states: "MINAS GERAIS",
            ic_january: 6.119,
            ic_february: 6.38,
            ic_march: 5.528,
            ic_april: 5.445,
            ic_may: 5.053,
            ic_june: 4.784,
            ic_july: 5.166,
            ic_august: 5.944,
            ic_september: 6.037,
            ic_october: 5.953,
            ic_november: 5.416,
            ic_december: 5.934,
            ic_yearly: 5.647,
          },
          {
            ic_uuid: "1f086005-549d-44c4-a8cd-92547ae2b299",
            ic_lat: -101727,
            ic_lon: -594573,
            ic_city: "Aripuanã",
            ic_states: "MATO GROSSO",
            ic_january: 4.42,
            ic_february: 4.428,
            ic_march: 4.575,
            ic_april: 4.397,
            ic_may: 4.268,
            ic_june: 4.578,
            ic_july: 4.626,
            ic_august: 5.072,
            ic_september: 5.035,
            ic_october: 5.086,
            ic_november: 4.868,
            ic_december: 4.773,
            ic_yearly: 4.677,
          },
          {
            ic_uuid: "4ab81de6-ce8b-4054-89fb-9bbc92363f1b",
            ic_lat: -99061,
            ic_lon: -63033,
            ic_city: "Ariquemes",
            ic_states: "RONDÔNIA",
            ic_january: 4.302,
            ic_february: 4.315,
            ic_march: 4.285,
            ic_april: 4.416,
            ic_may: 3.982,
            ic_june: 4.332,
            ic_july: 4.396,
            ic_august: 4.74,
            ic_september: 4.854,
            ic_october: 4.967,
            ic_november: 4.728,
            ic_december: 4.616,
            ic_yearly: 4.494,
          },
          {
            ic_uuid: "5e8ee39e-3596-4cfa-b01a-03f71c28dcd2",
            ic_lat: -211876,
            ic_lon: -487908,
            ic_city: "Ariranha",
            ic_states: "SÃO PAULO",
            ic_january: 5.814,
            ic_february: 5.95,
            ic_march: 5.194,
            ic_april: 4.821,
            ic_may: 4.1,
            ic_june: 3.852,
            ic_july: 4.073,
            ic_august: 4.954,
            ic_september: 5.062,
            ic_october: 5.596,
            ic_november: 5.863,
            ic_december: 6.144,
            ic_yearly: 5.119,
          },
          {
            ic_uuid: "7bb2c24c-7a13-44e2-9132-25bc03b07589",
            ic_lat: -243862,
            ic_lon: -515844,
            ic_city: "Ariranha do Ivaí",
            ic_states: "PARANÁ",
            ic_january: 6.055,
            ic_february: 5.71,
            ic_march: 5.212,
            ic_april: 4.523,
            ic_may: 3.499,
            ic_june: 3.067,
            ic_july: 3.348,
            ic_august: 4.35,
            ic_september: 4.532,
            ic_october: 5.223,
            ic_november: 6.1,
            ic_december: 6.308,
            ic_yearly: 4.827,
          },
          {
            ic_uuid: "23ad904f-df4e-42cc-81d1-4b415044e322",
            ic_lat: -227533,
            ic_lon: -418849,
            ic_city: "Armação dos Búzios",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.495,
            ic_february: 6.775,
            ic_march: 5.379,
            ic_april: 4.74,
            ic_may: 3.791,
            ic_june: 3.45,
            ic_july: 3.535,
            ic_august: 4.463,
            ic_september: 4.743,
            ic_october: 5.435,
            ic_november: 5.57,
            ic_december: 6.388,
            ic_yearly: 5.064,
          },
          {
            ic_uuid: "233d4ce4-e072-4eae-b500-43e55fe2a31f",
            ic_lat: -282453,
            ic_lon: -490219,
            ic_city: "Armazém",
            ic_states: "SANTA CATARINA",
            ic_january: 5.742,
            ic_february: 5.304,
            ic_march: 4.574,
            ic_april: 3.814,
            ic_may: 2.993,
            ic_june: 2.571,
            ic_july: 2.763,
            ic_august: 3.508,
            ic_september: 3.618,
            ic_october: 4.427,
            ic_november: 5.672,
            ic_december: 5.994,
            ic_yearly: 4.248,
          },
          {
            ic_uuid: "c066bbdb-9745-46b8-8624-4a6a00b79acc",
            ic_lat: -63169,
            ic_lon: -401656,
            ic_city: "Arneiroz",
            ic_states: "CEARÁ",
            ic_january: 5.392,
            ic_february: 5.434,
            ic_march: 5.441,
            ic_april: 5.19,
            ic_may: 5.087,
            ic_june: 5.032,
            ic_july: 5.45,
            ic_august: 6.213,
            ic_september: 6.523,
            ic_october: 6.41,
            ic_november: 6.276,
            ic_december: 5.749,
            ic_yearly: 5.683,
          },
          {
            ic_uuid: "780cd8c5-99cd-4ad1-bd02-556624a930f2",
            ic_lat: -61106,
            ic_lon: -417825,
            ic_city: "Aroazes",
            ic_states: "PIAUÍ",
            ic_january: 5.186,
            ic_february: 5.189,
            ic_march: 5.15,
            ic_april: 5.196,
            ic_may: 5.207,
            ic_june: 5.257,
            ic_july: 5.591,
            ic_august: 6.191,
            ic_september: 6.607,
            ic_october: 6.395,
            ic_november: 6.169,
            ic_december: 5.686,
            ic_yearly: 5.652,
          },
          {
            ic_uuid: "ed4eef03-5a65-4b65-a0fd-56caade1738e",
            ic_lat: -75452,
            ic_lon: -357068,
            ic_city: "Aroeiras",
            ic_states: "PARAÍBA",
            ic_january: 5.718,
            ic_february: 5.74,
            ic_march: 5.791,
            ic_april: 5.254,
            ic_may: 4.549,
            ic_june: 4.045,
            ic_july: 4.245,
            ic_august: 4.957,
            ic_september: 5.545,
            ic_october: 5.827,
            ic_november: 6.059,
            ic_december: 5.911,
            ic_yearly: 5.303,
          },
          {
            ic_uuid: "ab8a23c7-b504-4300-b87c-5efbb536ee8a",
            ic_lat: -72773,
            ic_lon: -415652,
            ic_city: "Aroeiras do Itaim",
            ic_states: "PIAUÍ",
            ic_january: 5.502,
            ic_february: 5.479,
            ic_march: 5.609,
            ic_april: 5.329,
            ic_may: 5.248,
            ic_june: 5.243,
            ic_july: 5.59,
            ic_august: 6.207,
            ic_september: 6.686,
            ic_october: 6.509,
            ic_november: 6.297,
            ic_december: 5.941,
            ic_yearly: 5.803,
          },
          {
            ic_uuid: "b5509945-2fee-40a3-b250-4e5e8491eeec",
            ic_lat: -66518,
            ic_lon: -425349,
            ic_city: "Arraial",
            ic_states: "PIAUÍ",
            ic_january: 5.237,
            ic_february: 5.332,
            ic_march: 5.266,
            ic_april: 5.277,
            ic_may: 5.293,
            ic_june: 5.294,
            ic_july: 5.648,
            ic_august: 6.151,
            ic_september: 6.492,
            ic_october: 6.422,
            ic_november: 6.119,
            ic_december: 5.705,
            ic_yearly: 5.686,
          },
          {
            ic_uuid: "9e46bf02-35f5-452a-9fa5-642ef55a09b7",
            ic_lat: -229779,
            ic_lon: -420271,
            ic_city: "Arraial do Cabo",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.428,
            ic_february: 6.727,
            ic_march: 5.378,
            ic_april: 4.644,
            ic_may: 3.73,
            ic_june: 3.409,
            ic_july: 3.489,
            ic_august: 4.378,
            ic_september: 4.696,
            ic_october: 5.367,
            ic_november: 5.638,
            ic_december: 6.397,
            ic_yearly: 5.023,
          },
          {
            ic_uuid: "0e391bc2-b9e4-48c6-b60d-d5019f139ed1",
            ic_lat: -129228,
            ic_lon: -469485,
            ic_city: "Arraias",
            ic_states: "TOCANTINS",
            ic_january: 5.689,
            ic_february: 5.59,
            ic_march: 5.221,
            ic_april: 5.255,
            ic_may: 5.21,
            ic_june: 5.037,
            ic_july: 5.426,
            ic_august: 6.033,
            ic_september: 6.007,
            ic_october: 5.774,
            ic_november: 5.385,
            ic_december: 5.682,
            ic_yearly: 5.526,
          },
          {
            ic_uuid: "0eb4b826-32d0-4b4b-96c5-b8f251ee59aa",
            ic_lat: -29401,
            ic_lon: -519449,
            ic_city: "Arroio do Meio",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.087,
            ic_february: 5.721,
            ic_march: 4.803,
            ic_april: 3.778,
            ic_may: 2.806,
            ic_june: 2.333,
            ic_july: 2.581,
            ic_august: 3.296,
            ic_september: 3.565,
            ic_october: 4.639,
            ic_november: 6.065,
            ic_december: 6.449,
            ic_yearly: 4.344,
          },
          {
            ic_uuid: "d73738ad-1fe7-4425-bec8-bef9cc5c4297",
            ic_lat: -314426,
            ic_lon: -524222,
            ic_city: "Arroio do Padre",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.131,
            ic_february: 5.546,
            ic_march: 4.69,
            ic_april: 3.643,
            ic_may: 2.66,
            ic_june: 2.244,
            ic_july: 2.468,
            ic_august: 3.053,
            ic_september: 3.523,
            ic_october: 4.743,
            ic_november: 6.009,
            ic_december: 6.504,
            ic_yearly: 4.268,
          },
          {
            ic_uuid: "3cab1536-93bd-479b-8dfb-e809a67ba67a",
            ic_lat: -295444,
            ic_lon: -4989,
            ic_city: "Arroio do Sal",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 5.837,
            ic_february: 5.387,
            ic_march: 4.55,
            ic_april: 3.841,
            ic_may: 2.948,
            ic_june: 2.43,
            ic_july: 2.63,
            ic_august: 3.299,
            ic_september: 3.514,
            ic_october: 4.364,
            ic_november: 5.737,
            ic_december: 6.076,
            ic_yearly: 4.218,
          },
          {
            ic_uuid: "1be7c96f-00c0-47dd-83cf-b492a59c0ee6",
            ic_lat: -293336,
            ic_lon: -530864,
            ic_city: "Arroio do Tigre",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.319,
            ic_february: 5.975,
            ic_march: 5.053,
            ic_april: 3.913,
            ic_may: 2.918,
            ic_june: 2.422,
            ic_july: 2.687,
            ic_august: 3.455,
            ic_september: 3.766,
            ic_october: 4.982,
            ic_november: 6.289,
            ic_december: 6.75,
            ic_yearly: 4.544,
          },
          {
            ic_uuid: "fe92c8f7-6421-4d83-9bb8-2916029e8413",
            ic_lat: -30088,
            ic_lon: -517279,
            ic_city: "Arroio dos Ratos",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.413,
            ic_february: 5.824,
            ic_march: 4.875,
            ic_april: 3.793,
            ic_may: 2.755,
            ic_june: 2.318,
            ic_july: 2.526,
            ic_august: 3.204,
            ic_september: 3.623,
            ic_october: 4.84,
            ic_november: 6.207,
            ic_december: 6.698,
            ic_yearly: 4.423,
          },
          {
            ic_uuid: "beb1c2d6-15e0-4be8-82b7-029ce8944f9c",
            ic_lat: -322331,
            ic_lon: -530866,
            ic_city: "Arroio Grande",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.376,
            ic_february: 5.607,
            ic_march: 4.788,
            ic_april: 3.635,
            ic_may: 2.629,
            ic_june: 2.219,
            ic_july: 2.383,
            ic_august: 2.998,
            ic_september: 3.612,
            ic_october: 4.9,
            ic_november: 6.201,
            ic_december: 6.727,
            ic_yearly: 4.34,
          },
          {
            ic_uuid: "c54cddfd-350e-4310-9ac4-40e3f41f627f",
            ic_lat: -269261,
            ic_lon: -513412,
            ic_city: "Arroio Trinta",
            ic_states: "SANTA CATARINA",
            ic_january: 5.69,
            ic_february: 5.25,
            ic_march: 4.802,
            ic_april: 3.933,
            ic_may: 3.016,
            ic_june: 2.7,
            ic_july: 2.925,
            ic_august: 3.833,
            ic_september: 3.995,
            ic_october: 4.792,
            ic_november: 5.845,
            ic_december: 6.02,
            ic_yearly: 4.4,
          },
          {
            ic_uuid: "2975d472-2d5e-4416-852d-1a1fce0046f6",
            ic_lat: -225732,
            ic_lon: -471731,
            ic_city: "Artur Nogueira",
            ic_states: "SÃO PAULO",
            ic_january: 5.575,
            ic_february: 5.823,
            ic_march: 5.148,
            ic_april: 4.652,
            ic_may: 3.859,
            ic_june: 3.58,
            ic_july: 3.757,
            ic_august: 4.72,
            ic_september: 4.888,
            ic_october: 5.504,
            ic_november: 5.715,
            ic_december: 6.159,
            ic_yearly: 4.948,
          },
          {
            ic_uuid: "f28bfa40-184a-49b2-819d-76a244a9e4eb",
            ic_lat: -149263,
            ic_lon: -510808,
            ic_city: "Aruanã",
            ic_states: "GOIÁS",
            ic_january: 5.534,
            ic_february: 5.68,
            ic_march: 5.419,
            ic_april: 5.343,
            ic_may: 4.934,
            ic_june: 4.583,
            ic_july: 4.859,
            ic_august: 5.421,
            ic_september: 5.5,
            ic_october: 5.579,
            ic_november: 5.441,
            ic_december: 5.479,
            ic_yearly: 5.314,
          },
          {
            ic_uuid: "be8bfa6b-b76e-4992-9fdd-8047586ba71f",
            ic_lat: -23397,
            ic_lon: -463204,
            ic_city: "Arujá",
            ic_states: "SÃO PAULO",
            ic_january: 5.163,
            ic_february: 5.461,
            ic_march: 4.66,
            ic_april: 4.131,
            ic_may: 3.365,
            ic_june: 3.138,
            ic_july: 3.22,
            ic_august: 4.166,
            ic_september: 4.196,
            ic_october: 4.689,
            ic_november: 4.989,
            ic_december: 5.593,
            ic_yearly: 4.398,
          },
          {
            ic_uuid: "f3b9d7fe-e54e-4677-a9d6-8ea907e5a5aa",
            ic_lat: -270753,
            ic_lon: -524547,
            ic_city: "Arvoredo",
            ic_states: "SANTA CATARINA",
            ic_january: 6.233,
            ic_february: 5.742,
            ic_march: 5.126,
            ic_april: 4.06,
            ic_may: 3.117,
            ic_june: 2.622,
            ic_july: 2.918,
            ic_august: 3.775,
            ic_september: 4.014,
            ic_october: 5.103,
            ic_november: 6.17,
            ic_december: 6.44,
            ic_yearly: 4.61,
          },
          {
            ic_uuid: "35a0d7c9-c9e3-4e14-8709-48d525dbffcb",
            ic_lat: -288742,
            ic_lon: -521785,
            ic_city: "Arvorezinha",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.087,
            ic_february: 5.689,
            ic_march: 4.786,
            ic_april: 3.855,
            ic_may: 2.874,
            ic_june: 2.412,
            ic_july: 2.719,
            ic_august: 3.474,
            ic_september: 3.72,
            ic_october: 4.776,
            ic_november: 6.098,
            ic_december: 6.46,
            ic_yearly: 4.413,
          },
          {
            ic_uuid: "4eabfc76-1695-45f7-8b84-a39c8d1987d0",
            ic_lat: -269553,
            ic_lon: -493787,
            ic_city: "Ascurra",
            ic_states: "SANTA CATARINA",
            ic_january: 5.444,
            ic_february: 5.185,
            ic_march: 4.56,
            ic_april: 3.669,
            ic_may: 2.965,
            ic_june: 2.437,
            ic_july: 2.579,
            ic_august: 3.314,
            ic_september: 3.397,
            ic_october: 3.917,
            ic_november: 5.136,
            ic_december: 5.479,
            ic_yearly: 4.007,
          },
          {
            ic_uuid: "3ff64b54-8171-47f1-b0aa-c7c0f19def0b",
            ic_lat: -20159,
            ic_lon: -507269,
            ic_city: "Aspásia",
            ic_states: "SÃO PAULO",
            ic_january: 5.779,
            ic_february: 5.991,
            ic_march: 5.376,
            ic_april: 4.902,
            ic_may: 4.233,
            ic_june: 3.977,
            ic_july: 4.173,
            ic_august: 5.09,
            ic_september: 5.067,
            ic_october: 5.691,
            ic_november: 5.953,
            ic_december: 6.265,
            ic_yearly: 5.208,
          },
          {
            ic_uuid: "9f16b5f5-41f2-4f37-93f5-014c772b7eb1",
            ic_lat: -233701,
            ic_lon: -508464,
            ic_city: "Assaí",
            ic_states: "PARANÁ",
            ic_january: 5.896,
            ic_february: 5.768,
            ic_march: 5.256,
            ic_april: 4.606,
            ic_may: 3.673,
            ic_june: 3.367,
            ic_july: 3.571,
            ic_august: 4.518,
            ic_september: 4.686,
            ic_october: 5.283,
            ic_november: 5.977,
            ic_december: 6.306,
            ic_yearly: 4.909,
          },
          {
            ic_uuid: "044a7598-8133-4c7e-a0b4-3ef2031ab61f",
            ic_lat: -68713,
            ic_lon: -398711,
            ic_city: "Assaré",
            ic_states: "CEARÁ",
            ic_january: 5.654,
            ic_february: 5.662,
            ic_march: 5.718,
            ic_april: 5.337,
            ic_may: 5.23,
            ic_june: 5.086,
            ic_july: 5.39,
            ic_august: 6.142,
            ic_september: 6.555,
            ic_october: 6.548,
            ic_november: 6.421,
            ic_december: 6.004,
            ic_yearly: 5.812,
          },
          {
            ic_uuid: "3e0b79a7-e601-4b0f-9aec-3279ab77572b",
            ic_lat: -226604,
            ic_lon: -504187,
            ic_city: "Assis",
            ic_states: "SÃO PAULO",
            ic_january: 5.724,
            ic_february: 5.803,
            ic_march: 5.235,
            ic_april: 4.668,
            ic_may: 3.776,
            ic_june: 3.476,
            ic_july: 3.688,
            ic_august: 4.656,
            ic_september: 4.759,
            ic_october: 5.437,
            ic_november: 5.988,
            ic_december: 6.288,
            ic_yearly: 4.958,
          },
          {
            ic_uuid: "909d4096-0495-480a-b902-c51ad40c0d7d",
            ic_lat: -109394,
            ic_lon: -695658,
            ic_city: "Assis Brasil",
            ic_states: "ACRE",
            ic_january: 4.678,
            ic_february: 4.661,
            ic_march: 4.231,
            ic_april: 4.532,
            ic_may: 3.917,
            ic_june: 4.026,
            ic_july: 4.283,
            ic_august: 4.947,
            ic_september: 5.136,
            ic_october: 5.092,
            ic_november: 5.102,
            ic_december: 4.771,
            ic_yearly: 4.615,
          },
          {
            ic_uuid: "2d0943e6-6746-411c-a0ac-f5fb19cf195d",
            ic_lat: -244173,
            ic_lon: -535218,
            ic_city: "Assis Chateaubriand",
            ic_states: "PARANÁ",
            ic_january: 6.302,
            ic_february: 5.689,
            ic_march: 5.244,
            ic_april: 4.396,
            ic_may: 3.45,
            ic_june: 3.055,
            ic_july: 3.233,
            ic_august: 4.242,
            ic_september: 4.514,
            ic_october: 5.3,
            ic_november: 6.141,
            ic_december: 6.523,
            ic_yearly: 4.841,
          },
          {
            ic_uuid: "972eb834-552b-41b2-bfed-357eb1bbbc92",
            ic_lat: -7075,
            ic_lon: -367314,
            ic_city: "Assunção",
            ic_states: "PARAÍBA",
            ic_january: 5.964,
            ic_february: 5.959,
            ic_march: 5.919,
            ic_april: 5.518,
            ic_may: 4.909,
            ic_june: 4.412,
            ic_july: 4.659,
            ic_august: 5.437,
            ic_september: 6.081,
            ic_october: 6.314,
            ic_november: 6.427,
            ic_december: 6.099,
            ic_yearly: 5.642,
          },
          {
            ic_uuid: "1864d3b4-17c2-409f-90a1-563c247b9b0e",
            ic_lat: -58654,
            ic_lon: -410392,
            ic_city: "Assunção do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.358,
            ic_february: 5.266,
            ic_march: 5.399,
            ic_april: 5.112,
            ic_may: 5.108,
            ic_june: 5.165,
            ic_july: 5.533,
            ic_august: 6.284,
            ic_september: 6.706,
            ic_october: 6.631,
            ic_november: 6.511,
            ic_december: 5.908,
            ic_yearly: 5.749,
          },
          {
            ic_uuid: "8ac06eb0-8374-49cf-af9b-09d77a7060d5",
            ic_lat: -213189,
            ic_lon: -428576,
            ic_city: "Astolfo Dutra",
            ic_states: "MINAS GERAIS",
            ic_january: 5.855,
            ic_february: 6.096,
            ic_march: 5.111,
            ic_april: 4.43,
            ic_may: 3.71,
            ic_june: 3.524,
            ic_july: 3.7,
            ic_august: 4.459,
            ic_september: 4.852,
            ic_october: 5.171,
            ic_november: 5.009,
            ic_december: 5.733,
            ic_yearly: 4.804,
          },
          {
            ic_uuid: "fd42d464-0883-4059-a2c1-f8c6bc247dde",
            ic_lat: -232323,
            ic_lon: -516672,
            ic_city: "Astorga",
            ic_states: "PARANÁ",
            ic_january: 5.918,
            ic_february: 5.78,
            ic_march: 5.311,
            ic_april: 4.692,
            ic_may: 3.687,
            ic_june: 3.387,
            ic_july: 3.597,
            ic_august: 4.534,
            ic_september: 4.718,
            ic_october: 5.407,
            ic_november: 6.076,
            ic_december: 6.387,
            ic_yearly: 4.958,
          },
          {
            ic_uuid: "4f29b677-da60-45b1-8c09-f4accc7bf13e",
            ic_lat: -95123,
            ic_lon: -360088,
            ic_city: "Atalaia",
            ic_states: "ALAGOAS",
            ic_january: 5.873,
            ic_february: 5.794,
            ic_march: 5.793,
            ic_april: 5.099,
            ic_may: 4.245,
            ic_june: 3.92,
            ic_july: 4.003,
            ic_august: 4.579,
            ic_september: 5.379,
            ic_october: 5.612,
            ic_november: 6.115,
            ic_december: 6.132,
            ic_yearly: 5.212,
          },
          {
            ic_uuid: "0289c2c5-79a3-4f6b-8765-97e75b1621a4",
            ic_lat: -231522,
            ic_lon: -520555,
            ic_city: "Atalaia",
            ic_states: "PARANÁ",
            ic_january: 5.917,
            ic_february: 5.821,
            ic_march: 5.323,
            ic_april: 4.647,
            ic_may: 3.67,
            ic_june: 3.364,
            ic_july: 3.563,
            ic_august: 4.479,
            ic_september: 4.682,
            ic_october: 5.396,
            ic_november: 6.079,
            ic_december: 6.368,
            ic_yearly: 4.942,
          },
          {
            ic_uuid: "2f891a4c-2d72-4d0e-8a32-0a897721e690",
            ic_lat: -43678,
            ic_lon: -701911,
            ic_city: "Atalaia do Norte",
            ic_states: "AMAZONAS",
            ic_january: 4.335,
            ic_february: 4.674,
            ic_march: 4.492,
            ic_april: 4.388,
            ic_may: 3.984,
            ic_june: 4.114,
            ic_july: 4.082,
            ic_august: 4.797,
            ic_september: 5.085,
            ic_october: 4.906,
            ic_november: 4.845,
            ic_december: 4.441,
            ic_yearly: 4.512,
          },
          {
            ic_uuid: "a831f4bb-283e-4dda-aa8a-927c23ff5033",
            ic_lat: -274224,
            ic_lon: -497793,
            ic_city: "Atalanta",
            ic_states: "SANTA CATARINA",
            ic_january: 5.675,
            ic_february: 5.383,
            ic_march: 4.642,
            ic_april: 3.674,
            ic_may: 2.89,
            ic_june: 2.355,
            ic_july: 2.54,
            ic_august: 3.338,
            ic_september: 3.531,
            ic_october: 4.152,
            ic_november: 5.445,
            ic_december: 5.805,
            ic_yearly: 4.119,
          },
          {
            ic_uuid: "ce47a2b9-6606-40a2-89e1-83ee168bc786",
            ic_lat: -180443,
            ic_lon: -411152,
            ic_city: "Ataléia",
            ic_states: "MINAS GERAIS",
            ic_january: 6.007,
            ic_february: 6.181,
            ic_march: 5.259,
            ic_april: 4.547,
            ic_may: 3.828,
            ic_june: 3.518,
            ic_july: 3.63,
            ic_august: 4.22,
            ic_september: 4.77,
            ic_october: 5.053,
            ic_november: 4.933,
            ic_december: 5.781,
            ic_yearly: 4.81,
          },
          {
            ic_uuid: "adc5110d-375d-47c4-971f-7ba9d3d3545f",
            ic_lat: -231175,
            ic_lon: -465567,
            ic_city: "Atibaia",
            ic_states: "SÃO PAULO",
            ic_january: 5.452,
            ic_february: 5.712,
            ic_march: 4.989,
            ic_april: 4.496,
            ic_may: 3.692,
            ic_june: 3.456,
            ic_july: 3.567,
            ic_august: 4.562,
            ic_september: 4.676,
            ic_october: 5.253,
            ic_november: 5.506,
            ic_december: 6.027,
            ic_yearly: 4.782,
          },
          {
            ic_uuid: "2e5bf5b7-cf42-4e5f-99e0-de0c033b990a",
            ic_lat: -209135,
            ic_lon: -411989,
            ic_city: "Atilio Vivacqua",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.255,
            ic_february: 6.492,
            ic_march: 5.256,
            ic_april: 4.555,
            ic_may: 3.885,
            ic_june: 3.652,
            ic_july: 3.717,
            ic_august: 4.471,
            ic_september: 4.943,
            ic_october: 5.156,
            ic_november: 5.082,
            ic_december: 5.903,
            ic_yearly: 4.947,
          },
          {
            ic_uuid: "3cf6c2b5-0b6c-4232-b903-f0aa4f774e35",
            ic_lat: -5469,
            ic_lon: -478866,
            ic_city: "Augustinópolis",
            ic_states: "TOCANTINS",
            ic_january: 4.618,
            ic_february: 4.812,
            ic_march: 4.851,
            ic_april: 4.888,
            ic_may: 4.832,
            ic_june: 5.145,
            ic_july: 5.273,
            ic_august: 5.726,
            ic_september: 5.536,
            ic_october: 5.14,
            ic_november: 4.897,
            ic_december: 4.736,
            ic_yearly: 5.038,
          },
          {
            ic_uuid: "3b6a70fa-1459-4d3d-b20f-94de418cfa34",
            ic_lat: -1022,
            ic_lon: -466459,
            ic_city: "Augusto Corrêa",
            ic_states: "PARÁ",
            ic_january: 4.821,
            ic_february: 4.513,
            ic_march: 4.291,
            ic_april: 4.344,
            ic_may: 4.585,
            ic_june: 4.807,
            ic_july: 4.939,
            ic_august: 5.339,
            ic_september: 5.676,
            ic_october: 5.56,
            ic_november: 5.48,
            ic_december: 5.14,
            ic_yearly: 4.958,
          },
          {
            ic_uuid: "6e11ff71-3654-49bb-9b5f-3b4c71ed3e11",
            ic_lat: -181002,
            ic_lon: -442659,
            ic_city: "Augusto de Lima",
            ic_states: "MINAS GERAIS",
            ic_january: 6.142,
            ic_february: 6.474,
            ic_march: 5.508,
            ic_april: 5.249,
            ic_may: 4.746,
            ic_june: 4.413,
            ic_july: 4.835,
            ic_august: 5.433,
            ic_september: 5.917,
            ic_october: 5.962,
            ic_november: 5.37,
            ic_december: 5.823,
            ic_yearly: 5.489,
          },
          {
            ic_uuid: "e1c162ea-dcb4-4305-87d6-4be974eeaa94",
            ic_lat: -285177,
            ic_lon: -539888,
            ic_city: "Augusto Pestana",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.48,
            ic_february: 6.054,
            ic_march: 5.172,
            ic_april: 4.028,
            ic_may: 3.059,
            ic_june: 2.5,
            ic_july: 2.793,
            ic_august: 3.572,
            ic_september: 3.966,
            ic_october: 5.085,
            ic_november: 6.297,
            ic_december: 6.714,
            ic_yearly: 4.643,
          },
          {
            ic_uuid: "12cd2b1f-4636-4ff0-831f-a3cde2cb94d3",
            ic_lat: -5854,
            ic_lon: -373109,
            ic_city: "Augusto Severo",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.036,
            ic_february: 6.093,
            ic_march: 6.105,
            ic_april: 5.825,
            ic_may: 5.383,
            ic_june: 4.972,
            ic_july: 5.278,
            ic_august: 5.973,
            ic_september: 6.432,
            ic_october: 6.551,
            ic_november: 6.588,
            ic_december: 6.089,
            ic_yearly: 5.944,
          },
          {
            ic_uuid: "4fe25ca1-b125-4848-b868-82560c4bb3d9",
            ic_lat: -276941,
            ic_lon: -52051,
            ic_city: "Áurea",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.21,
            ic_february: 5.784,
            ic_march: 5.0,
            ic_april: 4.042,
            ic_may: 3.063,
            ic_june: 2.55,
            ic_july: 2.899,
            ic_august: 3.698,
            ic_september: 3.95,
            ic_october: 5.034,
            ic_november: 6.182,
            ic_december: 6.484,
            ic_yearly: 4.575,
          },
          {
            ic_uuid: "0e8a8750-ae12-48a4-83b8-735e4507e0af",
            ic_lat: -143181,
            ic_lon: -393227,
            ic_city: "Aurelino Leal",
            ic_states: "BAHIA",
            ic_january: 5.887,
            ic_february: 5.83,
            ic_march: 5.503,
            ic_april: 4.516,
            ic_may: 3.84,
            ic_june: 3.508,
            ic_july: 3.743,
            ic_august: 4.246,
            ic_september: 4.918,
            ic_october: 5.143,
            ic_november: 5.262,
            ic_december: 6.02,
            ic_yearly: 4.868,
          },
          {
            ic_uuid: "c2285e31-5654-4ed8-b887-3e9bba5bc7a2",
            ic_lat: -20686,
            ic_lon: -505549,
            ic_city: "Auriflama",
            ic_states: "SÃO PAULO",
            ic_january: 5.93,
            ic_february: 5.951,
            ic_march: 5.39,
            ic_april: 4.887,
            ic_may: 4.15,
            ic_june: 3.869,
            ic_july: 4.091,
            ic_august: 4.977,
            ic_september: 5.065,
            ic_october: 5.65,
            ic_november: 5.999,
            ic_december: 6.272,
            ic_yearly: 5.186,
          },
          {
            ic_uuid: "ee2071af-c34c-42a7-ac3a-63739f4a1f26",
            ic_lat: -166777,
            ic_lon: -504645,
            ic_city: "Aurilândia",
            ic_states: "GOIÁS",
            ic_january: 5.667,
            ic_february: 5.707,
            ic_march: 5.385,
            ic_april: 5.226,
            ic_may: 4.831,
            ic_june: 4.589,
            ic_july: 4.724,
            ic_august: 5.54,
            ic_september: 5.424,
            ic_october: 5.614,
            ic_november: 5.628,
            ic_december: 5.642,
            ic_yearly: 5.332,
          },
          {
            ic_uuid: "fc332832-0e1d-4fb7-b2f5-bd7d7d727c03",
            ic_lat: -69339,
            ic_lon: -389745,
            ic_city: "Aurora",
            ic_states: "CEARÁ",
            ic_january: 5.939,
            ic_february: 5.939,
            ic_march: 5.859,
            ic_april: 5.61,
            ic_may: 5.2,
            ic_june: 4.977,
            ic_july: 5.299,
            ic_august: 6.029,
            ic_september: 6.503,
            ic_october: 6.55,
            ic_november: 6.636,
            ic_december: 6.217,
            ic_yearly: 5.897,
          },
          {
            ic_uuid: "53eac0f7-ae91-4845-8386-3768bf452274",
            ic_lat: -273103,
            ic_lon: -4963,
            ic_city: "Aurora",
            ic_states: "SANTA CATARINA",
            ic_january: 5.64,
            ic_february: 5.314,
            ic_march: 4.614,
            ic_april: 3.638,
            ic_may: 2.86,
            ic_june: 2.341,
            ic_july: 2.519,
            ic_august: 3.34,
            ic_september: 3.533,
            ic_october: 4.156,
            ic_november: 5.415,
            ic_december: 5.764,
            ic_yearly: 4.095,
          },
          {
            ic_uuid: "762cf2ed-8ec7-46fd-ad08-bd32a4f374fd",
            ic_lat: -21334,
            ic_lon: -475603,
            ic_city: "Aurora do Pará",
            ic_states: "PARÁ",
            ic_january: 4.538,
            ic_february: 4.557,
            ic_march: 4.602,
            ic_april: 4.541,
            ic_may: 4.676,
            ic_june: 4.894,
            ic_july: 5.018,
            ic_august: 5.29,
            ic_september: 5.377,
            ic_october: 5.212,
            ic_november: 4.958,
            ic_december: 4.649,
            ic_yearly: 4.859,
          },
          {
            ic_uuid: "b207048d-2af4-4001-a3d2-feeaa01e44f4",
            ic_lat: -127109,
            ic_lon: -46408,
            ic_city: "Aurora do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 5.917,
            ic_february: 5.811,
            ic_march: 5.491,
            ic_april: 5.367,
            ic_may: 5.242,
            ic_june: 5.046,
            ic_july: 5.337,
            ic_august: 5.94,
            ic_september: 6.101,
            ic_october: 5.795,
            ic_november: 5.441,
            ic_december: 5.875,
            ic_yearly: 5.614,
          },
          {
            ic_uuid: "d1c2c7a4-4b20-44b4-a308-b621a2406e64",
            ic_lat: -35861,
            ic_lon: -59126,
            ic_city: "Autazes",
            ic_states: "AMAZONAS",
            ic_january: 4.047,
            ic_february: 4.211,
            ic_march: 4.114,
            ic_april: 3.877,
            ic_may: 3.969,
            ic_june: 4.359,
            ic_july: 4.392,
            ic_august: 5.034,
            ic_september: 5.137,
            ic_october: 4.982,
            ic_november: 4.783,
            ic_december: 4.292,
            ic_yearly: 4.433,
          },
          {
            ic_uuid: "208c9fcf-20dd-4e3e-a60e-19d0bfc483df",
            ic_lat: -221571,
            ic_lon: -49331,
            ic_city: "Avaí",
            ic_states: "SÃO PAULO",
            ic_january: 5.651,
            ic_february: 5.865,
            ic_march: 5.217,
            ic_april: 4.729,
            ic_may: 3.863,
            ic_june: 3.58,
            ic_july: 3.784,
            ic_august: 4.686,
            ic_september: 4.821,
            ic_october: 5.568,
            ic_november: 5.867,
            ic_december: 6.203,
            ic_yearly: 4.986,
          },
          {
            ic_uuid: "db7d050d-c1ba-4412-a7e4-eea1ee096c3a",
            ic_lat: -214589,
            ic_lon: -499513,
            ic_city: "Avanhandava",
            ic_states: "SÃO PAULO",
            ic_january: 5.878,
            ic_february: 5.94,
            ic_march: 5.348,
            ic_april: 4.851,
            ic_may: 3.977,
            ic_june: 3.709,
            ic_july: 3.95,
            ic_august: 4.811,
            ic_september: 4.937,
            ic_october: 5.629,
            ic_november: 6.023,
            ic_december: 6.316,
            ic_yearly: 5.114,
          },
          {
            ic_uuid: "fff71acd-687b-4359-b180-c252064a6edf",
            ic_lat: -231072,
            ic_lon: -489255,
            ic_city: "Avaré",
            ic_states: "SÃO PAULO",
            ic_january: 5.527,
            ic_february: 5.671,
            ic_march: 5.042,
            ic_april: 4.541,
            ic_may: 3.654,
            ic_june: 3.418,
            ic_july: 3.539,
            ic_august: 4.569,
            ic_september: 4.736,
            ic_october: 5.301,
            ic_november: 5.786,
            ic_december: 6.091,
            ic_yearly: 4.823,
          },
          {
            ic_uuid: "e0a5614f-ec00-4baa-87e3-46a8b946726e",
            ic_lat: -36088,
            ic_lon: -553204,
            ic_city: "Aveiro",
            ic_states: "PARÁ",
            ic_january: 4.219,
            ic_february: 4.406,
            ic_march: 4.343,
            ic_april: 4.166,
            ic_may: 4.233,
            ic_june: 4.626,
            ic_july: 4.614,
            ic_august: 5.244,
            ic_september: 5.223,
            ic_october: 5.33,
            ic_november: 5.121,
            ic_december: 4.634,
            ic_yearly: 4.68,
          },
          {
            ic_uuid: "1774f8e1-65a7-4731-bdfe-69158b05d832",
            ic_lat: -101347,
            ic_lon: -439496,
            ic_city: "Avelino Lopes",
            ic_states: "PIAUÍ",
            ic_january: 6.081,
            ic_february: 5.79,
            ic_march: 5.684,
            ic_april: 5.528,
            ic_may: 5.391,
            ic_june: 5.258,
            ic_july: 5.54,
            ic_august: 6.235,
            ic_september: 6.485,
            ic_october: 6.329,
            ic_november: 6.014,
            ic_december: 6.053,
            ic_yearly: 5.866,
          },
          {
            ic_uuid: "07cfc177-9534-4938-a245-876f80c50a41",
            ic_lat: -164677,
            ic_lon: -497583,
            ic_city: "Avelinópolis",
            ic_states: "GOIÁS",
            ic_january: 5.513,
            ic_february: 5.576,
            ic_march: 5.203,
            ic_april: 5.187,
            ic_may: 4.845,
            ic_june: 4.568,
            ic_july: 4.788,
            ic_august: 5.724,
            ic_september: 5.444,
            ic_october: 5.574,
            ic_november: 5.333,
            ic_december: 5.493,
            ic_yearly: 5.271,
          },
          {
            ic_uuid: "0c0844eb-49d5-41e4-9a5e-00c25ed0cf45",
            ic_lat: -28366,
            ic_lon: -440564,
            ic_city: "Axixá",
            ic_states: "MARANHÃO",
            ic_january: 4.485,
            ic_february: 4.462,
            ic_march: 4.451,
            ic_april: 4.394,
            ic_may: 4.514,
            ic_june: 4.781,
            ic_july: 5.022,
            ic_august: 5.569,
            ic_september: 5.939,
            ic_october: 5.706,
            ic_november: 5.367,
            ic_december: 4.89,
            ic_yearly: 4.965,
          },
          {
            ic_uuid: "b8992f27-99d7-4505-af0c-ec0b4ba2dbc6",
            ic_lat: -5618,
            ic_lon: -477721,
            ic_city: "Axixá do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 4.575,
            ic_february: 4.717,
            ic_march: 4.785,
            ic_april: 4.824,
            ic_may: 4.804,
            ic_june: 5.11,
            ic_july: 5.279,
            ic_august: 5.794,
            ic_september: 5.559,
            ic_october: 5.117,
            ic_november: 4.885,
            ic_december: 4.673,
            ic_yearly: 5.01,
          },
          {
            ic_uuid: "06f6b946-0cdd-4bde-8f6d-c508995b6327",
            ic_lat: -72048,
            ic_lon: -477614,
            ic_city: "Babaçulândia",
            ic_states: "TOCANTINS",
            ic_january: 4.879,
            ic_february: 4.961,
            ic_march: 4.939,
            ic_april: 4.912,
            ic_may: 5.092,
            ic_june: 5.326,
            ic_july: 5.332,
            ic_august: 5.828,
            ic_september: 6.022,
            ic_october: 5.32,
            ic_november: 4.87,
            ic_december: 4.766,
            ic_yearly: 5.187,
          },
          {
            ic_uuid: "6abaa7dc-b95d-4a6d-995f-54a02391bb5e",
            ic_lat: -42249,
            ic_lon: -447835,
            ic_city: "Bacabal",
            ic_states: "MARANHÃO",
            ic_january: 4.714,
            ic_february: 4.875,
            ic_march: 4.895,
            ic_april: 4.902,
            ic_may: 4.84,
            ic_june: 4.977,
            ic_july: 5.243,
            ic_august: 5.775,
            ic_september: 6.147,
            ic_october: 5.751,
            ic_november: 5.335,
            ic_december: 5.035,
            ic_yearly: 5.207,
          },
          {
            ic_uuid: "6ca46973-f44e-496c-bf42-6ee806ffbb0c",
            ic_lat: -29765,
            ic_lon: -443142,
            ic_city: "Bacabeira",
            ic_states: "MARANHÃO",
            ic_january: 4.683,
            ic_february: 4.676,
            ic_march: 4.568,
            ic_april: 4.528,
            ic_may: 4.691,
            ic_june: 4.993,
            ic_july: 5.184,
            ic_august: 5.795,
            ic_september: 6.059,
            ic_october: 5.779,
            ic_november: 5.518,
            ic_december: 5.098,
            ic_yearly: 5.131,
          },
          {
            ic_uuid: "1e43890b-6b42-4607-b32d-91428e390ece",
            ic_lat: -17257,
            ic_lon: -4514,
            ic_city: "Bacuri",
            ic_states: "MARANHÃO",
            ic_january: 4.708,
            ic_february: 4.532,
            ic_march: 4.316,
            ic_april: 4.228,
            ic_may: 4.504,
            ic_june: 4.813,
            ic_july: 4.987,
            ic_august: 5.459,
            ic_september: 5.728,
            ic_october: 5.432,
            ic_november: 5.334,
            ic_december: 4.987,
            ic_yearly: 4.919,
          },
          {
            ic_uuid: "ffa7f4a5-7ea1-4401-903b-b34b496275e4",
            ic_lat: -27078,
            ic_lon: -44738,
            ic_city: "Bacurituba",
            ic_states: "MARANHÃO",
            ic_january: 4.818,
            ic_february: 4.904,
            ic_march: 4.805,
            ic_april: 4.822,
            ic_may: 4.825,
            ic_june: 5.09,
            ic_july: 5.183,
            ic_august: 5.692,
            ic_september: 5.849,
            ic_october: 5.653,
            ic_november: 5.527,
            ic_december: 5.189,
            ic_yearly: 5.196,
          },
          {
            ic_uuid: "6f06a737-d299-4b75-b39f-c1b52c4e8b0a",
            ic_lat: -20918,
            ic_lon: -494449,
            ic_city: "Bady Bassitt",
            ic_states: "SÃO PAULO",
            ic_january: 5.785,
            ic_february: 5.87,
            ic_march: 5.167,
            ic_april: 4.819,
            ic_may: 4.096,
            ic_june: 3.854,
            ic_july: 4.12,
            ic_august: 5.001,
            ic_september: 5.079,
            ic_october: 5.606,
            ic_november: 5.91,
            ic_december: 6.142,
            ic_yearly: 5.121,
          },
          {
            ic_uuid: "2d228399-82f3-4aae-9a2a-f7a9e4b08b7e",
            ic_lat: -219575,
            ic_lon: -448878,
            ic_city: "Baependi",
            ic_states: "MINAS GERAIS",
            ic_january: 5.49,
            ic_february: 5.794,
            ic_march: 5.025,
            ic_april: 4.637,
            ic_may: 3.929,
            ic_june: 3.685,
            ic_july: 3.88,
            ic_august: 4.787,
            ic_september: 5.089,
            ic_october: 5.418,
            ic_november: 5.345,
            ic_december: 5.687,
            ic_yearly: 4.897,
          },
          {
            ic_uuid: "76d19c1c-048a-4b5b-8656-fe76efc46597",
            ic_lat: -31331,
            ic_lon: -541069,
            ic_city: "Bagé",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.482,
            ic_february: 5.941,
            ic_march: 5.013,
            ic_april: 3.774,
            ic_may: 2.733,
            ic_june: 2.308,
            ic_july: 2.565,
            ic_august: 3.211,
            ic_september: 3.835,
            ic_october: 5.162,
            ic_november: 6.442,
            ic_december: 6.95,
            ic_yearly: 4.535,
          },
          {
            ic_uuid: "e1707c60-4c9c-4149-922f-f6a75643ee0a",
            ic_lat: -19009,
            ic_lon: -501991,
            ic_city: "Bagre",
            ic_states: "PARÁ",
            ic_january: 4.802,
            ic_february: 4.721,
            ic_march: 4.807,
            ic_april: 4.804,
            ic_may: 4.867,
            ic_june: 4.992,
            ic_july: 5.087,
            ic_august: 5.413,
            ic_september: 5.505,
            ic_october: 5.502,
            ic_november: 5.387,
            ic_december: 5.005,
            ic_yearly: 5.074,
          },
          {
            ic_uuid: "f3ec6fa0-99e0-4900-a08d-a67b8d3cec5d",
            ic_lat: -66904,
            ic_lon: -349335,
            ic_city: "Baía da Traição",
            ic_states: "PARAÍBA",
            ic_january: 6.155,
            ic_february: 6.156,
            ic_march: 6.229,
            ic_april: 5.601,
            ic_may: 5.053,
            ic_june: 4.532,
            ic_july: 4.688,
            ic_august: 5.559,
            ic_september: 6.004,
            ic_october: 6.288,
            ic_november: 6.501,
            ic_december: 6.308,
            ic_yearly: 5.756,
          },
          {
            ic_uuid: "fb486507-08d6-4b5d-beb6-2f343ae7fc16",
            ic_lat: -63712,
            ic_lon: -350071,
            ic_city: "Baía Formosa",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.928,
            ic_february: 6.071,
            ic_march: 6.111,
            ic_april: 5.543,
            ic_may: 4.982,
            ic_june: 4.543,
            ic_july: 4.7,
            ic_august: 5.479,
            ic_september: 5.879,
            ic_october: 6.08,
            ic_november: 6.204,
            ic_december: 6.031,
            ic_yearly: 5.629,
          },
          {
            ic_uuid: "9c82929e-02b4-42a4-8047-0b451c171936",
            ic_lat: -123076,
            ic_lon: -445386,
            ic_city: "Baianópolis",
            ic_states: "BAHIA",
            ic_january: 6.264,
            ic_february: 6.056,
            ic_march: 5.682,
            ic_april: 5.539,
            ic_may: 5.281,
            ic_june: 5.138,
            ic_july: 5.441,
            ic_august: 6.089,
            ic_september: 6.263,
            ic_october: 6.126,
            ic_november: 5.758,
            ic_december: 6.153,
            ic_yearly: 5.816,
          },
          {
            ic_uuid: "db1c281d-1479-4fbb-a8f5-af652712655a",
            ic_lat: -27906,
            ic_lon: -496698,
            ic_city: "Baião",
            ic_states: "PARÁ",
            ic_january: 4.461,
            ic_february: 4.578,
            ic_march: 4.548,
            ic_april: 4.645,
            ic_may: 4.674,
            ic_june: 4.858,
            ic_july: 4.948,
            ic_august: 5.262,
            ic_september: 5.083,
            ic_october: 4.894,
            ic_november: 4.659,
            ic_december: 4.392,
            ic_yearly: 4.75,
          },
          {
            ic_uuid: "e0d01cf0-69ad-4ceb-a7c9-87804924734e",
            ic_lat: -119561,
            ic_lon: -401687,
            ic_city: "Baixa Grande",
            ic_states: "BAHIA",
            ic_january: 5.93,
            ic_february: 5.866,
            ic_march: 5.723,
            ic_april: 4.619,
            ic_may: 3.929,
            ic_june: 3.62,
            ic_july: 3.762,
            ic_august: 4.384,
            ic_september: 5.262,
            ic_october: 5.356,
            ic_november: 5.594,
            ic_december: 5.918,
            ic_yearly: 4.997,
          },
          {
            ic_uuid: "c8964c38-847d-440e-8273-4a944a0e2867",
            ic_lat: -78599,
            ic_lon: -452098,
            ic_city: "Baixa Grande do Ribeiro",
            ic_states: "PIAUÍ",
            ic_january: 5.02,
            ic_february: 5.08,
            ic_march: 4.921,
            ic_april: 5.183,
            ic_may: 5.302,
            ic_june: 5.333,
            ic_july: 5.535,
            ic_august: 6.034,
            ic_september: 6.478,
            ic_october: 5.915,
            ic_november: 5.316,
            ic_december: 5.154,
            ic_yearly: 5.439,
          },
          {
            ic_uuid: "4aa84310-5f06-4e62-8b7d-3a815039fb07",
            ic_lat: -67203,
            ic_lon: -38714,
            ic_city: "Baixio",
            ic_states: "CEARÁ",
            ic_january: 6.073,
            ic_february: 6.049,
            ic_march: 6.029,
            ic_april: 5.715,
            ic_may: 5.24,
            ic_june: 5.053,
            ic_july: 5.357,
            ic_august: 6.065,
            ic_september: 6.467,
            ic_october: 6.635,
            ic_november: 6.727,
            ic_december: 6.282,
            ic_yearly: 5.974,
          },
          {
            ic_uuid: "3d1bbff4-016d-43ff-b479-3673b2dde057",
            ic_lat: -195218,
            ic_lon: -410113,
            ic_city: "Baixo Guandu",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.226,
            ic_february: 6.33,
            ic_march: 5.345,
            ic_april: 4.718,
            ic_may: 4.003,
            ic_june: 3.666,
            ic_july: 3.808,
            ic_august: 4.448,
            ic_september: 4.891,
            ic_october: 5.087,
            ic_november: 5.093,
            ic_december: 5.912,
            ic_yearly: 4.961,
          },
          {
            ic_uuid: "0f9df4a7-6a7b-41f1-babc-a3b19778a779",
            ic_lat: -219,
            ic_lon: -493569,
            ic_city: "Balbinos",
            ic_states: "SÃO PAULO",
            ic_january: 5.807,
            ic_february: 5.956,
            ic_march: 5.294,
            ic_april: 4.773,
            ic_may: 3.931,
            ic_june: 3.643,
            ic_july: 3.899,
            ic_august: 4.798,
            ic_september: 4.925,
            ic_october: 5.623,
            ic_november: 5.955,
            ic_december: 6.247,
            ic_yearly: 5.071,
          },
          {
            ic_uuid: "2c40c964-a0b4-4032-ad42-d00329945731",
            ic_lat: -19288,
            ic_lon: -43957,
            ic_city: "Baldim",
            ic_states: "MINAS GERAIS",
            ic_january: 5.949,
            ic_february: 6.238,
            ic_march: 5.369,
            ic_april: 4.995,
            ic_may: 4.44,
            ic_june: 4.166,
            ic_july: 4.51,
            ic_august: 5.312,
            ic_september: 5.716,
            ic_october: 5.774,
            ic_november: 5.505,
            ic_december: 5.85,
            ic_yearly: 5.319,
          },
          {
            ic_uuid: "1a3e5f33-eeb3-4d35-ae45-2f96a1514935",
            ic_lat: -161975,
            ic_lon: -525444,
            ic_city: "Baliza",
            ic_states: "GOIÁS",
            ic_january: 5.522,
            ic_february: 5.633,
            ic_march: 5.249,
            ic_april: 5.319,
            ic_may: 4.881,
            ic_june: 4.549,
            ic_july: 4.67,
            ic_august: 5.438,
            ic_september: 5.424,
            ic_october: 5.462,
            ic_november: 5.534,
            ic_december: 5.629,
            ic_yearly: 5.276,
          },
          {
            ic_uuid: "468d5a42-ede5-4610-af39-d6d3e78d590c",
            ic_lat: -289811,
            ic_lon: -494241,
            ic_city: "Balneário Arroio do Silva",
            ic_states: "SANTA CATARINA",
            ic_january: 6.033,
            ic_february: 5.549,
            ic_march: 4.688,
            ic_april: 3.903,
            ic_may: 3.022,
            ic_june: 2.456,
            ic_july: 2.688,
            ic_august: 3.394,
            ic_september: 3.585,
            ic_october: 4.489,
            ic_november: 5.867,
            ic_december: 6.292,
            ic_yearly: 4.331,
          },
          {
            ic_uuid: "23507b41-ac08-4992-a557-13596ee939a5",
            ic_lat: -264602,
            ic_lon: -486127,
            ic_city: "Balneário Barra do Sul",
            ic_states: "SANTA CATARINA",
            ic_january: 5.527,
            ic_february: 5.355,
            ic_march: 4.619,
            ic_april: 3.722,
            ic_may: 3.003,
            ic_june: 2.486,
            ic_july: 2.562,
            ic_august: 3.208,
            ic_september: 3.389,
            ic_october: 4.066,
            ic_november: 5.142,
            ic_december: 5.588,
            ic_yearly: 4.056,
          },
          {
            ic_uuid: "b39c6739-cfa8-4714-8c39-f74dbb091a98",
            ic_lat: -269931,
            ic_lon: -486356,
            ic_city: "Balneário Camboriú",
            ic_states: "SANTA CATARINA",
            ic_january: 5.697,
            ic_february: 5.419,
            ic_march: 4.618,
            ic_april: 3.702,
            ic_may: 3.02,
            ic_june: 2.514,
            ic_july: 2.643,
            ic_august: 3.245,
            ic_september: 3.52,
            ic_october: 4.161,
            ic_november: 5.321,
            ic_december: 5.747,
            ic_yearly: 4.134,
          },
          {
            ic_uuid: "9a611b93-ee19-4847-a43d-08a45113a9e0",
            ic_lat: -291532,
            ic_lon: -495845,
            ic_city: "Balneário Gaivota",
            ic_states: "SANTA CATARINA",
            ic_january: 6.112,
            ic_february: 5.594,
            ic_march: 4.753,
            ic_april: 3.944,
            ic_may: 2.985,
            ic_june: 2.475,
            ic_july: 2.672,
            ic_august: 3.358,
            ic_september: 3.585,
            ic_october: 4.569,
            ic_november: 5.966,
            ic_december: 6.347,
            ic_yearly: 4.363,
          },
          {
            ic_uuid: "c2f5b8fc-dae2-441c-8b16-d687e4c1290b",
            ic_lat: -267698,
            ic_lon: -486767,
            ic_city: "Balneário Piçarras",
            ic_states: "SANTA CATARINA",
            ic_january: 5.735,
            ic_february: 5.436,
            ic_march: 4.669,
            ic_april: 3.682,
            ic_may: 3.027,
            ic_june: 2.52,
            ic_july: 2.617,
            ic_august: 3.263,
            ic_september: 3.501,
            ic_october: 4.249,
            ic_november: 5.405,
            ic_december: 5.786,
            ic_yearly: 4.157,
          },
          {
            ic_uuid: "79cc84a9-9ee6-4a21-8eb3-460d38c63262",
            ic_lat: -302424,
            ic_lon: -502341,
            ic_city: "Balneário Pinhal",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.441,
            ic_february: 5.788,
            ic_march: 4.791,
            ic_april: 3.861,
            ic_may: 2.798,
            ic_june: 2.35,
            ic_july: 2.519,
            ic_august: 3.142,
            ic_september: 3.567,
            ic_october: 4.744,
            ic_november: 6.172,
            ic_december: 6.672,
            ic_yearly: 4.404,
          },
          {
            ic_uuid: "edac8735-78ff-4013-809e-8634357858be",
            ic_lat: -288049,
            ic_lon: -492411,
            ic_city: "Balneário Rincão",
            ic_states: "SANTA CATARINA",
            ic_january: 6.016,
            ic_february: 5.499,
            ic_march: 4.629,
            ic_april: 3.858,
            ic_may: 3.001,
            ic_june: 2.481,
            ic_july: 2.682,
            ic_august: 3.403,
            ic_september: 3.6,
            ic_october: 4.501,
            ic_november: 5.863,
            ic_december: 6.218,
            ic_yearly: 4.312,
          },
          {
            ic_uuid: "86bab27c-aef2-4d21-8f48-919414d32f16",
            ic_lat: -255808,
            ic_lon: -496295,
            ic_city: "Balsa Nova",
            ic_states: "PARANÁ",
            ic_january: 5.406,
            ic_february: 5.178,
            ic_march: 4.584,
            ic_april: 3.797,
            ic_may: 3.048,
            ic_june: 2.742,
            ic_july: 2.925,
            ic_august: 3.911,
            ic_september: 3.927,
            ic_october: 4.494,
            ic_november: 5.357,
            ic_december: 5.645,
            ic_yearly: 4.251,
          },
          {
            ic_uuid: "acd7b1ac-2f7b-460d-ab85-85f5a5076e48",
            ic_lat: -20735,
            ic_lon: -495839,
            ic_city: "Bálsamo",
            ic_states: "SÃO PAULO",
            ic_january: 5.843,
            ic_february: 5.878,
            ic_march: 5.196,
            ic_april: 4.905,
            ic_may: 4.154,
            ic_june: 3.898,
            ic_july: 4.144,
            ic_august: 5.051,
            ic_september: 5.077,
            ic_october: 5.613,
            ic_november: 5.907,
            ic_december: 6.158,
            ic_yearly: 5.152,
          },
          {
            ic_uuid: "4e614a3c-1169-4e56-ba7c-40dd10282f29",
            ic_lat: -75326,
            ic_lon: -460375,
            ic_city: "Balsas",
            ic_states: "MARANHÃO",
            ic_january: 4.96,
            ic_february: 5.067,
            ic_march: 5.072,
            ic_april: 5.108,
            ic_may: 5.183,
            ic_june: 5.296,
            ic_july: 5.338,
            ic_august: 5.803,
            ic_september: 6.399,
            ic_october: 5.717,
            ic_november: 5.177,
            ic_december: 5.082,
            ic_yearly: 5.35,
          },
          {
            ic_uuid: "2292217e-d0b7-4648-8f5e-a77f83b72232",
            ic_lat: -200171,
            ic_lon: -459758,
            ic_city: "Bambuí",
            ic_states: "MINAS GERAIS",
            ic_january: 5.765,
            ic_february: 6.036,
            ic_march: 5.0,
            ic_april: 4.945,
            ic_may: 4.408,
            ic_june: 4.185,
            ic_july: 4.457,
            ic_august: 5.457,
            ic_september: 5.489,
            ic_october: 5.534,
            ic_november: 5.178,
            ic_december: 5.613,
            ic_yearly: 5.172,
          },
          {
            ic_uuid: "677215ed-5ac7-4df8-ab53-1b0d7bfe780b",
            ic_lat: -53049,
            ic_lon: -389135,
            ic_city: "Banabuiú",
            ic_states: "CEARÁ",
            ic_january: 5.586,
            ic_february: 5.641,
            ic_march: 5.78,
            ic_april: 5.293,
            ic_may: 5.175,
            ic_june: 4.974,
            ic_july: 5.332,
            ic_august: 6.066,
            ic_september: 6.473,
            ic_october: 6.462,
            ic_november: 6.378,
            ic_december: 5.884,
            ic_yearly: 5.754,
          },
          {
            ic_uuid: "4856d10a-a1f1-4b3f-91a7-11581a50869b",
            ic_lat: -226824,
            ic_lon: -443285,
            ic_city: "Bananal",
            ic_states: "SÃO PAULO",
            ic_january: 5.595,
            ic_february: 5.95,
            ic_march: 4.832,
            ic_april: 4.276,
            ic_may: 3.585,
            ic_june: 3.389,
            ic_july: 3.444,
            ic_august: 4.294,
            ic_september: 4.52,
            ic_october: 4.91,
            ic_november: 4.955,
            ic_december: 5.719,
            ic_yearly: 4.622,
          },
          {
            ic_uuid: "4f03d533-28a5-4004-939b-391fa59e593f",
            ic_lat: -67444,
            ic_lon: -356309,
            ic_city: "Bananeiras",
            ic_states: "PARAÍBA",
            ic_january: 5.685,
            ic_february: 5.831,
            ic_march: 5.889,
            ic_april: 5.346,
            ic_may: 4.816,
            ic_june: 4.375,
            ic_july: 4.473,
            ic_august: 5.238,
            ic_september: 5.722,
            ic_october: 5.893,
            ic_november: 6.128,
            ic_december: 5.884,
            ic_yearly: 5.44,
          },
          {
            ic_uuid: "b8e56b19-fd0c-44df-bf42-4ef8f100d31c",
            ic_lat: -158839,
            ic_lon: -405611,
            ic_city: "Bandeira",
            ic_states: "MINAS GERAIS",
            ic_january: 6.08,
            ic_february: 6.209,
            ic_march: 5.526,
            ic_april: 4.821,
            ic_may: 3.986,
            ic_june: 3.6,
            ic_july: 3.752,
            ic_august: 4.331,
            ic_september: 5.047,
            ic_october: 5.164,
            ic_november: 5.18,
            ic_december: 6.02,
            ic_yearly: 4.976,
          },
          {
            ic_uuid: "dcc485b9-5577-4d3e-a452-926470717c71",
            ic_lat: -217313,
            ic_lon: -463837,
            ic_city: "Bandeira do Sul",
            ic_states: "MINAS GERAIS",
            ic_january: 5.352,
            ic_february: 5.624,
            ic_march: 4.841,
            ic_april: 4.554,
            ic_may: 3.951,
            ic_june: 3.752,
            ic_july: 3.989,
            ic_august: 4.894,
            ic_september: 5.058,
            ic_october: 5.377,
            ic_november: 5.23,
            ic_december: 5.547,
            ic_yearly: 4.848,
          },
          {
            ic_uuid: "74efaa24-84c1-4cc4-a6db-5d6665978b13",
            ic_lat: -26771,
            ic_lon: -536418,
            ic_city: "Bandeirante",
            ic_states: "SANTA CATARINA",
            ic_january: 6.262,
            ic_february: 5.78,
            ic_march: 5.086,
            ic_april: 4.066,
            ic_may: 3.148,
            ic_june: 2.653,
            ic_july: 2.938,
            ic_august: 3.785,
            ic_september: 4.07,
            ic_october: 5.121,
            ic_november: 6.036,
            ic_december: 6.377,
            ic_yearly: 4.61,
          },
          {
            ic_uuid: "a16243fb-0fa1-45a9-9183-8f253200a0bd",
            ic_lat: -199214,
            ic_lon: -54364,
            ic_city: "Bandeirantes",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.635,
            ic_february: 5.576,
            ic_march: 5.255,
            ic_april: 4.825,
            ic_may: 4.108,
            ic_june: 3.868,
            ic_july: 4.055,
            ic_august: 4.841,
            ic_september: 4.968,
            ic_october: 5.412,
            ic_november: 5.775,
            ic_december: 6.045,
            ic_yearly: 5.03,
          },
          {
            ic_uuid: "6be9d4c4-95b5-4ad9-a83b-df111d7e0571",
            ic_lat: -231083,
            ic_lon: -503708,
            ic_city: "Bandeirantes",
            ic_states: "PARANÁ",
            ic_january: 5.872,
            ic_february: 5.906,
            ic_march: 5.276,
            ic_april: 4.682,
            ic_may: 3.707,
            ic_june: 3.408,
            ic_july: 3.622,
            ic_august: 4.597,
            ic_september: 4.723,
            ic_october: 5.418,
            ic_november: 5.974,
            ic_december: 6.403,
            ic_yearly: 4.966,
          },
          {
            ic_uuid: "2de8551e-336f-49a8-a471-28f852698139",
            ic_lat: -77604,
            ic_lon: -485775,
            ic_city: "Bandeirantes do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 4.728,
            ic_february: 4.793,
            ic_march: 4.689,
            ic_april: 4.682,
            ic_may: 4.947,
            ic_june: 5.119,
            ic_july: 5.189,
            ic_august: 5.906,
            ic_september: 5.667,
            ic_october: 5.036,
            ic_november: 4.71,
            ic_december: 4.569,
            ic_yearly: 5.003,
          },
          {
            ic_uuid: "0dd56034-3288-4414-977a-e162e12dc44f",
            ic_lat: -73517,
            ic_lon: -504077,
            ic_city: "Bannach",
            ic_states: "PARÁ",
            ic_january: 4.624,
            ic_february: 4.66,
            ic_march: 4.588,
            ic_april: 4.545,
            ic_may: 4.704,
            ic_june: 4.996,
            ic_july: 4.966,
            ic_august: 5.573,
            ic_september: 5.207,
            ic_october: 4.796,
            ic_november: 4.669,
            ic_december: 4.548,
            ic_yearly: 4.823,
          },
          {
            ic_uuid: "5050c7e3-81ba-4105-b23b-a073ae2321ca",
            ic_lat: -10583,
            ic_lon: -386131,
            ic_city: "Banzaê",
            ic_states: "BAHIA",
            ic_january: 6.174,
            ic_february: 5.945,
            ic_march: 5.876,
            ic_april: 5.054,
            ic_may: 4.241,
            ic_june: 3.864,
            ic_july: 4.039,
            ic_august: 4.605,
            ic_september: 5.457,
            ic_october: 5.717,
            ic_november: 6.205,
            ic_december: 6.194,
            ic_yearly: 5.281,
          },
          {
            ic_uuid: "1304bffa-65de-4b54-864f-ea07d84c5853",
            ic_lat: -293764,
            ic_lon: -514993,
            ic_city: "Barão",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.143,
            ic_february: 5.64,
            ic_march: 4.72,
            ic_april: 3.782,
            ic_may: 2.8,
            ic_june: 2.36,
            ic_july: 2.61,
            ic_august: 3.29,
            ic_september: 3.556,
            ic_october: 4.636,
            ic_november: 6.042,
            ic_december: 6.429,
            ic_yearly: 4.334,
          },
          {
            ic_uuid: "92d5573d-5e31-4e20-88ab-3351ddfe0c56",
            ic_lat: -236289,
            ic_lon: -495638,
            ic_city: "Barão de Antonina",
            ic_states: "SÃO PAULO",
            ic_january: 5.819,
            ic_february: 5.888,
            ic_march: 5.224,
            ic_april: 4.541,
            ic_may: 3.54,
            ic_june: 3.19,
            ic_july: 3.406,
            ic_august: 4.411,
            ic_september: 4.592,
            ic_october: 5.257,
            ic_november: 5.9,
            ic_december: 6.371,
            ic_yearly: 4.845,
          },
          {
            ic_uuid: "ee0682b0-c43b-4024-963c-e2368ec1a6b7",
            ic_lat: -199394,
            ic_lon: -434759,
            ic_city: "Barão de Cocais",
            ic_states: "MINAS GERAIS",
            ic_january: 5.433,
            ic_february: 5.715,
            ic_march: 4.761,
            ic_april: 4.368,
            ic_may: 3.84,
            ic_june: 3.674,
            ic_july: 3.894,
            ic_august: 4.718,
            ic_september: 4.952,
            ic_october: 5.004,
            ic_november: 4.767,
            ic_december: 5.26,
            ic_yearly: 4.699,
          },
          {
            ic_uuid: "07af021d-8cae-40e7-a85f-aaba16c7298b",
            ic_lat: -276213,
            ic_lon: -523803,
            ic_city: "Barão de Cotegipe",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.197,
            ic_february: 5.763,
            ic_march: 5.0,
            ic_april: 4.06,
            ic_may: 3.075,
            ic_june: 2.594,
            ic_july: 2.945,
            ic_august: 3.725,
            ic_september: 3.96,
            ic_october: 5.073,
            ic_november: 6.208,
            ic_december: 6.481,
            ic_yearly: 4.59,
          },
          {
            ic_uuid: "222a440e-32e3-4be0-aa83-6c614a5ae23e",
            ic_lat: -67564,
            ic_lon: -430269,
            ic_city: "Barão de Grajaú",
            ic_states: "MARANHÃO",
            ic_january: 5.24,
            ic_february: 5.306,
            ic_march: 5.265,
            ic_april: 5.183,
            ic_may: 5.21,
            ic_june: 5.34,
            ic_july: 5.568,
            ic_august: 6.089,
            ic_september: 6.583,
            ic_october: 6.356,
            ic_november: 5.995,
            ic_december: 5.565,
            ic_yearly: 5.641,
          },
          {
            ic_uuid: "7a3b0c29-12c4-44b8-9b0d-fa3c9a7fafe6",
            ic_lat: -161971,
            ic_lon: -559666,
            ic_city: "Barão de Melgaço",
            ic_states: "MATO GROSSO",
            ic_january: 5.638,
            ic_february: 5.56,
            ic_march: 5.345,
            ic_april: 5.139,
            ic_may: 4.42,
            ic_june: 4.369,
            ic_july: 4.453,
            ic_august: 5.428,
            ic_september: 5.072,
            ic_october: 5.318,
            ic_november: 5.611,
            ic_december: 5.743,
            ic_yearly: 5.175,
          },
          {
            ic_uuid: "85134263-24be-4dbc-ae74-ebedcfc464c5",
            ic_lat: -212449,
            ic_lon: -422375,
            ic_city: "Barão de Monte Alto",
            ic_states: "MINAS GERAIS",
            ic_january: 6.07,
            ic_february: 6.299,
            ic_march: 5.197,
            ic_april: 4.532,
            ic_may: 3.836,
            ic_june: 3.605,
            ic_july: 3.714,
            ic_august: 4.51,
            ic_september: 4.92,
            ic_october: 5.208,
            ic_november: 5.039,
            ic_december: 5.923,
            ic_yearly: 4.904,
          },
          {
            ic_uuid: "8be3062d-8485-470d-be54-e7979a52d161",
            ic_lat: -303896,
            ic_lon: -517389,
            ic_city: "Barão do Triunfo",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.174,
            ic_february: 5.62,
            ic_march: 4.731,
            ic_april: 3.76,
            ic_may: 2.756,
            ic_june: 2.356,
            ic_july: 2.545,
            ic_august: 3.196,
            ic_september: 3.57,
            ic_october: 4.727,
            ic_november: 6.115,
            ic_december: 6.547,
            ic_yearly: 4.341,
          },
          {
            ic_uuid: "f13e1523-fa8b-455c-82af-aa4195725229",
            ic_lat: -66425,
            ic_lon: -36255,
            ic_city: "Baraúna",
            ic_states: "PARAÍBA",
            ic_january: 5.872,
            ic_february: 5.853,
            ic_march: 5.907,
            ic_april: 5.504,
            ic_may: 4.872,
            ic_june: 4.496,
            ic_july: 4.706,
            ic_august: 5.393,
            ic_september: 5.946,
            ic_october: 6.215,
            ic_november: 6.25,
            ic_december: 5.94,
            ic_yearly: 5.579,
          },
          {
            ic_uuid: "2a6fb497-5252-4edb-9079-941ece7624d0",
            ic_lat: -50702,
            ic_lon: -376132,
            ic_city: "Baraúna",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.762,
            ic_february: 5.807,
            ic_march: 5.868,
            ic_april: 5.524,
            ic_may: 5.31,
            ic_june: 5.099,
            ic_july: 5.408,
            ic_august: 5.956,
            ic_september: 6.367,
            ic_october: 6.416,
            ic_november: 6.375,
            ic_december: 6.021,
            ic_yearly: 5.826,
          },
          {
            ic_uuid: "74c842dd-4cd8-4e3e-8edd-2400a25b3c5f",
            ic_lat: -212219,
            ic_lon: -437706,
            ic_city: "Barbacena",
            ic_states: "MINAS GERAIS",
            ic_january: 5.447,
            ic_february: 5.764,
            ic_march: 4.809,
            ic_april: 4.3,
            ic_may: 3.729,
            ic_june: 3.568,
            ic_july: 3.768,
            ic_august: 4.651,
            ic_september: 4.865,
            ic_october: 5.088,
            ic_november: 4.838,
            ic_december: 5.5,
            ic_yearly: 4.694,
          },
          {
            ic_uuid: "9dc29337-1ebd-4d18-a217-843ef583f25a",
            ic_lat: -73008,
            ic_lon: -393088,
            ic_city: "Barbalha",
            ic_states: "CEARÁ",
            ic_january: 5.905,
            ic_february: 5.833,
            ic_march: 5.802,
            ic_april: 5.449,
            ic_may: 5.087,
            ic_june: 4.923,
            ic_july: 5.185,
            ic_august: 5.965,
            ic_september: 6.527,
            ic_october: 6.508,
            ic_november: 6.567,
            ic_december: 6.221,
            ic_yearly: 5.831,
          },
          {
            ic_uuid: "ae26af41-6f87-47e6-8b71-53abe4240e3b",
            ic_lat: -212661,
            ic_lon: -499522,
            ic_city: "Barbosa",
            ic_states: "SÃO PAULO",
            ic_january: 5.926,
            ic_february: 6.01,
            ic_march: 5.391,
            ic_april: 4.828,
            ic_may: 3.993,
            ic_june: 3.709,
            ic_july: 3.949,
            ic_august: 4.812,
            ic_september: 4.93,
            ic_october: 5.646,
            ic_november: 6.072,
            ic_december: 6.383,
            ic_yearly: 5.137,
          },
          {
            ic_uuid: "4ba57a78-85b6-4ca6-8c9e-57f5a254cf70",
            ic_lat: -240338,
            ic_lon: -520044,
            ic_city: "Barbosa Ferraz",
            ic_states: "PARANÁ",
            ic_january: 6.135,
            ic_february: 5.81,
            ic_march: 5.298,
            ic_april: 4.582,
            ic_may: 3.576,
            ic_june: 3.185,
            ic_july: 3.412,
            ic_august: 4.357,
            ic_september: 4.595,
            ic_october: 5.274,
            ic_november: 6.111,
            ic_december: 6.44,
            ic_yearly: 4.898,
          },
          {
            ic_uuid: "48d2ebf0-d62b-4e2b-8dd4-fafb702e470b",
            ic_lat: -15123,
            ic_lon: -486199,
            ic_city: "Barcarena",
            ic_states: "PARÁ",
            ic_january: 4.431,
            ic_february: 4.429,
            ic_march: 4.49,
            ic_april: 4.602,
            ic_may: 4.73,
            ic_june: 4.948,
            ic_july: 5.053,
            ic_august: 5.295,
            ic_september: 5.385,
            ic_october: 5.246,
            ic_november: 5.157,
            ic_december: 4.704,
            ic_yearly: 4.872,
          },
          {
            ic_uuid: "17138067-dfc4-4fda-8a2d-2c23c16ea2a4",
            ic_lat: -59506,
            ic_lon: -35928,
            ic_city: "Barcelona",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.75,
            ic_february: 5.789,
            ic_march: 5.798,
            ic_april: 5.491,
            ic_may: 4.976,
            ic_june: 4.544,
            ic_july: 4.731,
            ic_august: 5.372,
            ic_september: 5.796,
            ic_october: 5.997,
            ic_november: 6.126,
            ic_december: 5.776,
            ic_yearly: 5.512,
          },
          {
            ic_uuid: "1c200208-8d47-43a6-826d-1bbd8bf83dae",
            ic_lat: -9837,
            ic_lon: -629315,
            ic_city: "Barcelos",
            ic_states: "AMAZONAS",
            ic_january: 4.524,
            ic_february: 4.669,
            ic_march: 4.526,
            ic_april: 4.228,
            ic_may: 3.933,
            ic_june: 4.349,
            ic_july: 4.401,
            ic_august: 4.958,
            ic_september: 5.169,
            ic_october: 5.046,
            ic_november: 4.835,
            ic_december: 4.719,
            ic_yearly: 4.613,
          },
          {
            ic_uuid: "886c6a21-3951-49c4-87db-4fc1ab9153f5",
            ic_lat: -220734,
            ic_lon: -487442,
            ic_city: "Bariri",
            ic_states: "SÃO PAULO",
            ic_january: 5.802,
            ic_february: 5.988,
            ic_march: 5.264,
            ic_april: 4.729,
            ic_may: 3.891,
            ic_june: 3.652,
            ic_july: 3.849,
            ic_august: 4.726,
            ic_september: 4.894,
            ic_october: 5.602,
            ic_november: 5.94,
            ic_december: 6.291,
            ic_yearly: 5.053,
          },
          {
            ic_uuid: "062d0bda-a95c-4af1-8a0f-a3e15cc0e902",
            ic_lat: -110863,
            ic_lon: -431463,
            ic_city: "Barra",
            ic_states: "BAHIA",
            ic_january: 6.403,
            ic_february: 6.279,
            ic_march: 6.109,
            ic_april: 5.725,
            ic_may: 5.261,
            ic_june: 5.116,
            ic_july: 5.302,
            ic_august: 5.895,
            ic_september: 6.341,
            ic_october: 6.508,
            ic_november: 6.273,
            ic_december: 6.426,
            ic_yearly: 5.969,
          },
          {
            ic_uuid: "394cefa7-5736-4879-910f-ee9996fcf8f5",
            ic_lat: -266545,
            ic_lon: -534405,
            ic_city: "Barra Bonita",
            ic_states: "SANTA CATARINA",
            ic_january: 6.302,
            ic_february: 5.761,
            ic_march: 5.081,
            ic_april: 4.094,
            ic_may: 3.161,
            ic_june: 2.679,
            ic_july: 2.966,
            ic_august: 3.831,
            ic_september: 4.102,
            ic_october: 5.136,
            ic_november: 6.06,
            ic_december: 6.4,
            ic_yearly: 4.631,
          },
          {
            ic_uuid: "e6fa5001-ce57-40f1-82cb-385536e2ec5e",
            ic_lat: -224913,
            ic_lon: -485588,
            ic_city: "Barra Bonita",
            ic_states: "SÃO PAULO",
            ic_january: 5.667,
            ic_february: 5.965,
            ic_march: 5.298,
            ic_april: 4.704,
            ic_may: 3.814,
            ic_june: 3.538,
            ic_july: 3.73,
            ic_august: 4.677,
            ic_september: 4.852,
            ic_october: 5.56,
            ic_november: 5.994,
            ic_december: 6.314,
            ic_yearly: 5.009,
          },
          {
            ic_uuid: "04fc26bc-0ab3-4ace-8957-fc6d3c791865",
            ic_lat: -136273,
            ic_lon: -413306,
            ic_city: "Barra da Estiva",
            ic_states: "BAHIA",
            ic_january: 6.147,
            ic_february: 6.161,
            ic_march: 5.598,
            ic_april: 4.685,
            ic_may: 4.171,
            ic_june: 3.834,
            ic_july: 3.987,
            ic_august: 4.742,
            ic_september: 5.721,
            ic_october: 5.905,
            ic_november: 5.661,
            ic_december: 6.274,
            ic_yearly: 5.241,
          },
          {
            ic_uuid: "3fe2b150-f668-4299-96bb-cb912f3eeded",
            ic_lat: -65194,
            ic_lon: -421067,
            ic_city: "Barra D'Alcântara",
            ic_states: "PIAUÍ",
            ic_january: 5.18,
            ic_february: 5.217,
            ic_march: 5.173,
            ic_april: 5.142,
            ic_may: 5.212,
            ic_june: 5.254,
            ic_july: 5.614,
            ic_august: 6.179,
            ic_september: 6.595,
            ic_october: 6.296,
            ic_november: 6.11,
            ic_december: 5.68,
            ic_yearly: 5.638,
          },
          {
            ic_uuid: "a20ce1a4-47c1-47ba-a175-e56c27d5ca03",
            ic_lat: -84212,
            ic_lon: -356588,
            ic_city: "Barra de Guabiraba",
            ic_states: "PERNAMBUCO",
            ic_january: 5.69,
            ic_february: 5.658,
            ic_march: 5.735,
            ic_april: 5.034,
            ic_may: 4.23,
            ic_june: 3.823,
            ic_july: 3.885,
            ic_august: 4.505,
            ic_september: 5.22,
            ic_october: 5.584,
            ic_november: 6.079,
            ic_december: 6.014,
            ic_yearly: 5.122,
          },
          {
            ic_uuid: "842a3418-6ea8-4e0f-aab0-4b5deb07bd16",
            ic_lat: -67186,
            ic_lon: -360674,
            ic_city: "Barra de Santa Rosa",
            ic_states: "PARAÍBA",
            ic_january: 5.719,
            ic_february: 5.766,
            ic_march: 5.83,
            ic_april: 5.35,
            ic_may: 4.787,
            ic_june: 4.337,
            ic_july: 4.463,
            ic_august: 5.175,
            ic_september: 5.717,
            ic_october: 5.994,
            ic_november: 6.171,
            ic_december: 5.915,
            ic_yearly: 5.435,
          },
          {
            ic_uuid: "87ffd928-06d6-4fe6-ba1a-3f7b321e6791",
            ic_lat: -75178,
            ic_lon: -359958,
            ic_city: "Barra de Santana",
            ic_states: "PARAÍBA",
            ic_january: 5.796,
            ic_february: 5.759,
            ic_march: 5.79,
            ic_april: 5.27,
            ic_may: 4.617,
            ic_june: 4.047,
            ic_july: 4.261,
            ic_august: 4.956,
            ic_september: 5.585,
            ic_october: 5.869,
            ic_november: 6.158,
            ic_december: 5.905,
            ic_yearly: 5.334,
          },
          {
            ic_uuid: "b1302abb-4851-4f1a-8fb7-a31afdffa03a",
            ic_lat: -94027,
            ic_lon: -355104,
            ic_city: "Barra de Santo Antônio",
            ic_states: "ALAGOAS",
            ic_january: 6.085,
            ic_february: 6.032,
            ic_march: 6.105,
            ic_april: 5.18,
            ic_may: 4.414,
            ic_june: 4.082,
            ic_july: 4.194,
            ic_august: 4.858,
            ic_september: 5.555,
            ic_october: 5.923,
            ic_november: 6.381,
            ic_december: 6.403,
            ic_yearly: 5.434,
          },
          {
            ic_uuid: "e42d646d-ccbe-4d5f-9613-b554a1b78522",
            ic_lat: -187553,
            ic_lon: -408968,
            ic_city: "Barra de São Francisco",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.124,
            ic_february: 6.202,
            ic_march: 5.337,
            ic_april: 4.627,
            ic_may: 3.88,
            ic_june: 3.616,
            ic_july: 3.658,
            ic_august: 4.22,
            ic_september: 4.771,
            ic_october: 4.952,
            ic_november: 4.949,
            ic_december: 5.818,
            ic_yearly: 4.846,
          },
          {
            ic_uuid: "2a645628-9e55-468a-aaa8-658619633cec",
            ic_lat: -98389,
            ic_lon: -35906,
            ic_city: "Barra de São Miguel",
            ic_states: "ALAGOAS",
            ic_january: 6.125,
            ic_february: 6.105,
            ic_march: 6.021,
            ic_april: 5.137,
            ic_may: 4.364,
            ic_june: 3.993,
            ic_july: 4.159,
            ic_august: 4.768,
            ic_september: 5.496,
            ic_october: 5.906,
            ic_november: 6.322,
            ic_december: 6.382,
            ic_yearly: 5.398,
          },
          {
            ic_uuid: "789b92b4-1274-4b89-8f1f-04baadaebc69",
            ic_lat: -77527,
            ic_lon: -363166,
            ic_city: "Barra de São Miguel",
            ic_states: "PARAÍBA",
            ic_january: 6.003,
            ic_february: 5.906,
            ic_march: 5.926,
            ic_april: 5.46,
            ic_may: 4.788,
            ic_june: 4.321,
            ic_july: 4.457,
            ic_august: 5.17,
            ic_september: 5.855,
            ic_october: 6.122,
            ic_november: 6.35,
            ic_december: 6.091,
            ic_yearly: 5.538,
          },
          {
            ic_uuid: "db3bccdb-1544-409e-a615-b96036f27cc8",
            ic_lat: -150706,
            ic_lon: -571882,
            ic_city: "Barra do Bugres",
            ic_states: "MATO GROSSO",
            ic_january: 5.371,
            ic_february: 5.276,
            ic_march: 5.09,
            ic_april: 4.904,
            ic_may: 4.398,
            ic_june: 4.42,
            ic_july: 4.527,
            ic_august: 5.223,
            ic_september: 5.119,
            ic_october: 5.329,
            ic_november: 5.523,
            ic_december: 5.573,
            ic_yearly: 5.063,
          },
          {
            ic_uuid: "f332b746-206a-4e96-b0ea-46739721f48f",
            ic_lat: -244727,
            ic_lon: -490242,
            ic_city: "Barra do Chapéu",
            ic_states: "SÃO PAULO",
            ic_january: 5.211,
            ic_february: 5.162,
            ic_march: 4.552,
            ic_april: 3.906,
            ic_may: 3.138,
            ic_june: 2.815,
            ic_july: 2.904,
            ic_august: 3.881,
            ic_september: 3.933,
            ic_october: 4.465,
            ic_november: 5.104,
            ic_december: 5.51,
            ic_yearly: 4.215,
          },
          {
            ic_uuid: "8a7dcbec-e4aa-457a-be67-de76de363a4c",
            ic_lat: -148659,
            ic_lon: -405794,
            ic_city: "Barra do Choça",
            ic_states: "BAHIA",
            ic_january: 5.911,
            ic_february: 5.975,
            ic_march: 5.429,
            ic_april: 4.606,
            ic_may: 3.996,
            ic_june: 3.55,
            ic_july: 3.787,
            ic_august: 4.286,
            ic_september: 5.219,
            ic_october: 5.421,
            ic_november: 5.285,
            ic_december: 5.988,
            ic_yearly: 4.954,
          },
          {
            ic_uuid: "e27ccde7-acbe-47da-8ea6-36a610585650",
            ic_lat: -54972,
            ic_lon: -452489,
            ic_city: "Barra do Corda",
            ic_states: "MARANHÃO",
            ic_january: 4.719,
            ic_february: 4.885,
            ic_march: 4.927,
            ic_april: 4.829,
            ic_may: 4.909,
            ic_june: 5.106,
            ic_july: 5.381,
            ic_august: 5.925,
            ic_september: 6.107,
            ic_october: 5.538,
            ic_november: 5.185,
            ic_december: 4.922,
            ic_yearly: 5.203,
          },
          {
            ic_uuid: "5c1b4a3f-5d69-408b-8b9b-f2306e89a199",
            ic_lat: -158895,
            ic_lon: -52272,
            ic_city: "Barra do Garças",
            ic_states: "MATO GROSSO",
            ic_january: 5.473,
            ic_february: 5.603,
            ic_march: 5.201,
            ic_april: 5.294,
            ic_may: 4.941,
            ic_june: 4.585,
            ic_july: 4.721,
            ic_august: 5.489,
            ic_september: 5.292,
            ic_october: 5.489,
            ic_november: 5.43,
            ic_december: 5.545,
            ic_yearly: 5.256,
          },
          {
            ic_uuid: "b05ad441-3b99-4cea-8805-bb74977d6ca7",
            ic_lat: -271931,
            ic_lon: -537113,
            ic_city: "Barra do Guarita",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.407,
            ic_february: 5.867,
            ic_march: 5.152,
            ic_april: 4.025,
            ic_may: 3.093,
            ic_june: 2.515,
            ic_july: 2.857,
            ic_august: 3.711,
            ic_september: 3.998,
            ic_october: 5.166,
            ic_november: 6.158,
            ic_december: 6.552,
            ic_yearly: 4.625,
          },
          {
            ic_uuid: "75490ea6-6887-4177-97e2-b51449a76959",
            ic_lat: -231165,
            ic_lon: -501846,
            ic_city: "Barra do Jacaré",
            ic_states: "PARANÁ",
            ic_january: 5.834,
            ic_february: 5.934,
            ic_march: 5.211,
            ic_april: 4.662,
            ic_may: 3.714,
            ic_june: 3.414,
            ic_july: 3.625,
            ic_august: 4.57,
            ic_september: 4.724,
            ic_october: 5.385,
            ic_november: 5.912,
            ic_december: 6.409,
            ic_yearly: 4.949,
          },
          {
            ic_uuid: "ccd87289-9c84-40cf-a4cf-0952dc959513",
            ic_lat: -118104,
            ic_lon: -420593,
            ic_city: "Barra do Mendes",
            ic_states: "BAHIA",
            ic_january: 6.218,
            ic_february: 6.064,
            ic_march: 5.897,
            ic_april: 5.086,
            ic_may: 4.615,
            ic_june: 4.382,
            ic_july: 4.636,
            ic_august: 5.391,
            ic_september: 6.195,
            ic_october: 6.283,
            ic_november: 6.055,
            ic_december: 6.25,
            ic_yearly: 5.59,
          },
          {
            ic_uuid: "2d94f933-c44c-4942-93ac-93317107f229",
            ic_lat: -76963,
            ic_lon: -47678,
            ic_city: "Barra do Ouro",
            ic_states: "TOCANTINS",
            ic_january: 4.922,
            ic_february: 5.052,
            ic_march: 5.007,
            ic_april: 4.915,
            ic_may: 5.114,
            ic_june: 5.219,
            ic_july: 5.246,
            ic_august: 5.889,
            ic_september: 5.955,
            ic_october: 5.311,
            ic_november: 4.943,
            ic_december: 4.822,
            ic_yearly: 5.2,
          },
          {
            ic_uuid: "2c2f5687-f52d-4e07-9d0e-4126fffffd90",
            ic_lat: -22472,
            ic_lon: -438273,
            ic_city: "Barra do Piraí",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.782,
            ic_february: 6.049,
            ic_march: 4.941,
            ic_april: 4.35,
            ic_may: 3.589,
            ic_june: 3.347,
            ic_july: 3.417,
            ic_august: 4.247,
            ic_september: 4.491,
            ic_october: 4.897,
            ic_november: 5.007,
            ic_december: 5.713,
            ic_yearly: 4.653,
          },
          {
            ic_uuid: "2c71d93a-e515-4246-b440-72c345fbb6eb",
            ic_lat: -302033,
            ic_lon: -575502,
            ic_city: "Barra do Quaraí",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 7.068,
            ic_february: 6.317,
            ic_march: 5.335,
            ic_april: 4.17,
            ic_may: 2.992,
            ic_june: 2.434,
            ic_july: 2.822,
            ic_august: 3.584,
            ic_september: 4.312,
            ic_october: 5.567,
            ic_november: 6.615,
            ic_december: 7.267,
            ic_yearly: 4.874,
          },
          {
            ic_uuid: "187d43f5-2328-4a91-978f-6ef38a815bbe",
            ic_lat: -302944,
            ic_lon: -513018,
            ic_city: "Barra do Ribeiro",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.526,
            ic_february: 5.802,
            ic_march: 4.842,
            ic_april: 3.803,
            ic_may: 2.785,
            ic_june: 2.33,
            ic_july: 2.544,
            ic_august: 3.168,
            ic_september: 3.615,
            ic_october: 4.805,
            ic_november: 6.191,
            ic_december: 6.676,
            ic_yearly: 4.424,
          },
          {
            ic_uuid: "7f0bd2d6-a8e5-4715-a051-b46e44754853",
            ic_lat: -274073,
            ic_lon: -524088,
            ic_city: "Barra do Rio Azul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.226,
            ic_february: 5.791,
            ic_march: 5.105,
            ic_april: 4.096,
            ic_may: 3.07,
            ic_june: 2.537,
            ic_july: 2.891,
            ic_august: 3.741,
            ic_september: 4.003,
            ic_october: 5.115,
            ic_november: 6.243,
            ic_december: 6.566,
            ic_yearly: 4.615,
          },
          {
            ic_uuid: "ac2689da-6562-41bc-9e99-6c62c8a25c54",
            ic_lat: -142097,
            ic_lon: -396002,
            ic_city: "Barra do Rocha",
            ic_states: "BAHIA",
            ic_january: 5.89,
            ic_february: 5.944,
            ic_march: 5.579,
            ic_april: 4.572,
            ic_may: 3.859,
            ic_june: 3.52,
            ic_july: 3.773,
            ic_august: 4.294,
            ic_september: 4.973,
            ic_october: 5.157,
            ic_november: 5.299,
            ic_december: 5.953,
            ic_yearly: 4.901,
          },
          {
            ic_uuid: "b33ea4b8-7106-4846-9c3f-605868bd92ab",
            ic_lat: -24756,
            ic_lon: -485049,
            ic_city: "Barra do Turvo",
            ic_states: "SÃO PAULO",
            ic_january: 5.254,
            ic_february: 5.204,
            ic_march: 4.461,
            ic_april: 3.765,
            ic_may: 3.037,
            ic_june: 2.62,
            ic_july: 2.696,
            ic_august: 3.545,
            ic_september: 3.588,
            ic_october: 4.149,
            ic_november: 4.896,
            ic_december: 5.459,
            ic_yearly: 4.056,
          },
          {
            ic_uuid: "48f9e583-17a5-4b06-ab0c-fcd0da21bebd",
            ic_lat: -109001,
            ic_lon: -370326,
            ic_city: "Barra dos Coqueiros",
            ic_states: "SERGIPE",
            ic_january: 6.277,
            ic_february: 6.384,
            ic_march: 6.079,
            ic_april: 5.09,
            ic_may: 4.429,
            ic_june: 4.068,
            ic_july: 4.192,
            ic_august: 4.826,
            ic_september: 5.592,
            ic_october: 6.073,
            ic_november: 6.356,
            ic_december: 6.584,
            ic_yearly: 5.496,
          },
          {
            ic_uuid: "796dc33e-29e6-4a21-ac49-08c0be5d3166",
            ic_lat: -279209,
            ic_lon: -530395,
            ic_city: "Barra Funda",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.391,
            ic_february: 5.963,
            ic_march: 5.095,
            ic_april: 4.105,
            ic_may: 3.087,
            ic_june: 2.555,
            ic_july: 2.899,
            ic_august: 3.655,
            ic_september: 3.937,
            ic_october: 5.134,
            ic_november: 6.278,
            ic_december: 6.671,
            ic_yearly: 4.648,
          },
          {
            ic_uuid: "b701a59f-8751-4f85-8aae-be232846ec44",
            ic_lat: -202873,
            ic_lon: -430405,
            ic_city: "Barra Longa",
            ic_states: "MINAS GERAIS",
            ic_january: 5.629,
            ic_february: 5.932,
            ic_march: 4.981,
            ic_april: 4.5,
            ic_may: 3.809,
            ic_june: 3.628,
            ic_july: 3.812,
            ic_august: 4.664,
            ic_september: 4.936,
            ic_october: 5.009,
            ic_november: 4.765,
            ic_december: 5.457,
            ic_yearly: 4.76,
          },
          {
            ic_uuid: "10644bc1-5250-4da8-b3b1-daf69320eb16",
            ic_lat: -225486,
            ic_lon: -441756,
            ic_city: "Barra Mansa",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.631,
            ic_february: 5.952,
            ic_march: 4.903,
            ic_april: 4.345,
            ic_may: 3.596,
            ic_june: 3.388,
            ic_july: 3.429,
            ic_august: 4.35,
            ic_september: 4.563,
            ic_october: 4.964,
            ic_november: 5.005,
            ic_december: 5.7,
            ic_yearly: 4.652,
          },
          {
            ic_uuid: "4f5ba15c-1143-49e9-bac8-e310b10953fd",
            ic_lat: -266374,
            ic_lon: -486938,
            ic_city: "Barra Velha",
            ic_states: "SANTA CATARINA",
            ic_january: 5.702,
            ic_february: 5.47,
            ic_march: 4.705,
            ic_april: 3.78,
            ic_may: 3.037,
            ic_june: 2.516,
            ic_july: 2.604,
            ic_august: 3.226,
            ic_september: 3.447,
            ic_october: 4.175,
            ic_november: 5.293,
            ic_december: 5.716,
            ic_yearly: 4.139,
          },
          {
            ic_uuid: "7d8ed44f-f359-4e6b-8aea-b40bbefd5a5b",
            ic_lat: -262507,
            ic_lon: -536328,
            ic_city: "Barracão",
            ic_states: "PARANÁ",
            ic_january: 6.337,
            ic_february: 5.812,
            ic_march: 5.172,
            ic_april: 4.136,
            ic_may: 3.214,
            ic_june: 2.763,
            ic_july: 3.052,
            ic_august: 3.964,
            ic_september: 4.225,
            ic_october: 5.215,
            ic_november: 6.044,
            ic_december: 6.363,
            ic_yearly: 4.692,
          },
          {
            ic_uuid: "0518ad25-6eb8-4150-a9f1-885fb221acb6",
            ic_lat: -276743,
            ic_lon: -51459,
            ic_city: "Barracão",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.219,
            ic_february: 5.736,
            ic_march: 4.966,
            ic_april: 4.018,
            ic_may: 3.019,
            ic_june: 2.572,
            ic_july: 2.885,
            ic_august: 3.709,
            ic_september: 3.931,
            ic_october: 4.978,
            ic_november: 6.181,
            ic_december: 6.494,
            ic_yearly: 4.559,
          },
          {
            ic_uuid: "0dbd69f3-7d29-4f1e-9ad6-ea27ff769689",
            ic_lat: -42451,
            ic_lon: -422925,
            ic_city: "Barras",
            ic_states: "PIAUÍ",
            ic_january: 5.321,
            ic_february: 5.365,
            ic_march: 5.24,
            ic_april: 4.981,
            ic_may: 5.061,
            ic_june: 5.25,
            ic_july: 5.58,
            ic_august: 6.209,
            ic_september: 6.475,
            ic_october: 6.435,
            ic_november: 6.323,
            ic_december: 5.787,
            ic_yearly: 5.669,
          },
          {
            ic_uuid: "54865c45-e0dc-4ae6-8e8c-b55e9b196d91",
            ic_lat: -42896,
            ic_lon: -386432,
            ic_city: "Barreira",
            ic_states: "CEARÁ",
            ic_january: 5.358,
            ic_february: 5.414,
            ic_march: 5.311,
            ic_april: 4.841,
            ic_may: 5.045,
            ic_june: 4.897,
            ic_july: 5.295,
            ic_august: 5.973,
            ic_september: 6.287,
            ic_october: 6.207,
            ic_november: 6.098,
            ic_december: 5.582,
            ic_yearly: 5.526,
          },
          {
            ic_uuid: "5b81a367-bb94-45de-9fb6-e7c3c1623f2a",
            ic_lat: -121443,
            ic_lon: -449972,
            ic_city: "Barreiras",
            ic_states: "BAHIA",
            ic_january: 6.363,
            ic_february: 6.128,
            ic_march: 5.805,
            ic_april: 5.554,
            ic_may: 5.299,
            ic_june: 5.13,
            ic_july: 5.481,
            ic_august: 6.071,
            ic_september: 6.254,
            ic_october: 6.264,
            ic_november: 5.878,
            ic_december: 6.248,
            ic_yearly: 5.873,
          },
          {
            ic_uuid: "a91c7488-0abf-4bbe-bbbe-5e1e82492095",
            ic_lat: -99239,
            ic_lon: -454735,
            ic_city: "Barreiras do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.844,
            ic_february: 5.735,
            ic_march: 5.481,
            ic_april: 5.339,
            ic_may: 5.426,
            ic_june: 5.2,
            ic_july: 5.253,
            ic_august: 5.885,
            ic_september: 6.285,
            ic_october: 6.135,
            ic_november: 5.692,
            ic_december: 5.84,
            ic_yearly: 5.676,
          },
          {
            ic_uuid: "1884879e-efa4-4c2a-a30d-f92e1a46e5ca",
            ic_lat: -27992,
            ic_lon: -570683,
            ic_city: "Barreirinha",
            ic_states: "AMAZONAS",
            ic_january: 4.12,
            ic_february: 4.222,
            ic_march: 4.159,
            ic_april: 3.962,
            ic_may: 4.08,
            ic_june: 4.645,
            ic_july: 4.557,
            ic_august: 5.221,
            ic_september: 5.119,
            ic_october: 5.216,
            ic_november: 5.049,
            ic_december: 4.515,
            ic_yearly: 4.572,
          },
          {
            ic_uuid: "12328388-1c8a-4d39-95bf-e84dca650bc6",
            ic_lat: -27525,
            ic_lon: -428278,
            ic_city: "Barreirinhas",
            ic_states: "MARANHÃO",
            ic_january: 5.139,
            ic_february: 5.099,
            ic_march: 4.914,
            ic_april: 4.75,
            ic_may: 4.897,
            ic_june: 4.969,
            ic_july: 5.207,
            ic_august: 5.958,
            ic_september: 6.346,
            ic_october: 6.127,
            ic_november: 6.008,
            ic_december: 5.648,
            ic_yearly: 5.422,
          },
          {
            ic_uuid: "1a1b2360-e269-408b-9c6c-a999db6b0cdb",
            ic_lat: -88165,
            ic_lon: -351835,
            ic_city: "Barreiros",
            ic_states: "PERNAMBUCO",
            ic_january: 5.832,
            ic_february: 5.917,
            ic_march: 6.001,
            ic_april: 5.075,
            ic_may: 4.332,
            ic_june: 4.095,
            ic_july: 4.173,
            ic_august: 4.855,
            ic_september: 5.566,
            ic_october: 5.894,
            ic_november: 6.129,
            ic_december: 6.176,
            ic_yearly: 5.337,
          },
          {
            ic_uuid: "81a9d041-376a-438d-a7a1-a64a153773a0",
            ic_lat: -205536,
            ic_lon: -485702,
            ic_city: "Barretos",
            ic_states: "SÃO PAULO",
            ic_january: 5.823,
            ic_february: 6.035,
            ic_march: 5.242,
            ic_april: 4.874,
            ic_may: 4.23,
            ic_june: 3.967,
            ic_july: 4.204,
            ic_august: 5.097,
            ic_september: 5.081,
            ic_october: 5.594,
            ic_november: 5.798,
            ic_december: 6.157,
            ic_yearly: 5.175,
          },
          {
            ic_uuid: "ab5fc4cb-781b-4c58-bb81-a87a684eb285",
            ic_lat: -21194,
            ic_lon: -48164,
            ic_city: "Barrinha",
            ic_states: "SÃO PAULO",
            ic_january: 5.757,
            ic_february: 6.017,
            ic_march: 5.187,
            ic_april: 4.836,
            ic_may: 4.143,
            ic_june: 3.843,
            ic_july: 4.096,
            ic_august: 4.939,
            ic_september: 4.978,
            ic_october: 5.556,
            ic_november: 5.844,
            ic_december: 6.082,
            ic_yearly: 5.106,
          },
          {
            ic_uuid: "c788558e-ce6b-4336-97b5-6c71c960f49e",
            ic_lat: -7177,
            ic_lon: -387794,
            ic_city: "Barro",
            ic_states: "CEARÁ",
            ic_january: 5.927,
            ic_february: 5.911,
            ic_march: 5.843,
            ic_april: 5.55,
            ic_may: 5.119,
            ic_june: 4.895,
            ic_july: 5.231,
            ic_august: 5.936,
            ic_september: 6.567,
            ic_october: 6.578,
            ic_november: 6.7,
            ic_december: 6.242,
            ic_yearly: 5.875,
          },
          {
            ic_uuid: "92cc4fa6-b50d-459b-ac40-f5fd47be57bf",
            ic_lat: -117609,
            ic_lon: -419058,
            ic_city: "Barro Alto",
            ic_states: "BAHIA",
            ic_january: 6.283,
            ic_february: 6.122,
            ic_march: 5.915,
            ic_april: 5.076,
            ic_may: 4.604,
            ic_june: 4.381,
            ic_july: 4.631,
            ic_august: 5.333,
            ic_september: 6.157,
            ic_october: 6.297,
            ic_november: 6.057,
            ic_december: 6.28,
            ic_yearly: 5.595,
          },
          {
            ic_uuid: "7ab4d599-beee-4abf-a7ea-b3dd2a9315fc",
            ic_lat: -149681,
            ic_lon: -48913,
            ic_city: "Barro Alto",
            ic_states: "GOIÁS",
            ic_january: 5.303,
            ic_february: 5.547,
            ic_march: 5.163,
            ic_april: 5.158,
            ic_may: 4.956,
            ic_june: 4.688,
            ic_july: 4.83,
            ic_august: 5.899,
            ic_september: 5.66,
            ic_october: 5.582,
            ic_november: 5.139,
            ic_december: 5.356,
            ic_yearly: 5.273,
          },
          {
            ic_uuid: "42c2964f-5d00-4fba-ac02-f3e6742b8660",
            ic_lat: -58171,
            ic_lon: -42515,
            ic_city: "Barro Duro",
            ic_states: "PIAUÍ",
            ic_january: 4.975,
            ic_february: 5.206,
            ic_march: 5.193,
            ic_april: 5.107,
            ic_may: 5.219,
            ic_june: 5.403,
            ic_july: 5.673,
            ic_august: 6.237,
            ic_september: 6.627,
            ic_october: 6.396,
            ic_november: 6.07,
            ic_december: 5.595,
            ic_yearly: 5.642,
          },
          {
            ic_uuid: "208bd213-adf4-4e59-980c-dba193c3e4ff",
            ic_lat: -147952,
            ic_lon: -394764,
            ic_city: "Barro Preto",
            ic_states: "BAHIA",
            ic_january: 5.669,
            ic_february: 5.718,
            ic_march: 5.353,
            ic_april: 4.513,
            ic_may: 3.86,
            ic_june: 3.492,
            ic_july: 3.693,
            ic_august: 4.144,
            ic_september: 4.734,
            ic_october: 4.91,
            ic_november: 5.103,
            ic_december: 5.878,
            ic_yearly: 4.756,
          },
          {
            ic_uuid: "223f4941-4855-4d6b-bbc5-f89292cb57a5",
            ic_lat: -115277,
            ic_lon: -390779,
            ic_city: "Barrocas",
            ic_states: "BAHIA",
            ic_january: 6.132,
            ic_february: 5.918,
            ic_march: 5.805,
            ic_april: 4.863,
            ic_may: 4.085,
            ic_june: 3.816,
            ic_july: 4.035,
            ic_august: 4.501,
            ic_september: 5.391,
            ic_october: 5.618,
            ic_november: 5.958,
            ic_december: 6.059,
            ic_yearly: 5.182,
          },
          {
            ic_uuid: "ebc43ed3-e287-412d-881e-c060bf3496c3",
            ic_lat: -98345,
            ic_lon: -487256,
            ic_city: "Barrolândia",
            ic_states: "TOCANTINS",
            ic_january: 4.956,
            ic_february: 5.013,
            ic_march: 4.73,
            ic_april: 4.852,
            ic_may: 4.978,
            ic_june: 5.098,
            ic_july: 5.325,
            ic_august: 5.999,
            ic_september: 5.622,
            ic_october: 5.138,
            ic_november: 5.028,
            ic_december: 4.937,
            ic_yearly: 5.14,
          },
          {
            ic_uuid: "4a28e275-b664-4a06-a9d4-ebc5078f4d1b",
            ic_lat: -30209,
            ic_lon: -411362,
            ic_city: "Barroquinha",
            ic_states: "CEARÁ",
            ic_january: 5.319,
            ic_february: 5.132,
            ic_march: 4.932,
            ic_april: 4.792,
            ic_may: 5.042,
            ic_june: 5.28,
            ic_july: 5.561,
            ic_august: 6.17,
            ic_september: 6.4,
            ic_october: 6.408,
            ic_november: 6.363,
            ic_december: 5.852,
            ic_yearly: 5.604,
          },
          {
            ic_uuid: "fe332969-8227-4d45-8b7b-4ad7d2cf5632",
            ic_lat: -29093,
            ic_lon: -525829,
            ic_city: "Barros Cassal",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.234,
            ic_february: 5.775,
            ic_march: 4.933,
            ic_april: 3.898,
            ic_may: 2.905,
            ic_june: 2.426,
            ic_july: 2.69,
            ic_august: 3.448,
            ic_september: 3.725,
            ic_october: 4.827,
            ic_november: 6.173,
            ic_december: 6.537,
            ic_yearly: 4.464,
          },
          {
            ic_uuid: "119cf58d-38ea-40c3-8f99-81f90c7535e4",
            ic_lat: -211911,
            ic_lon: -439724,
            ic_city: "Barroso",
            ic_states: "MINAS GERAIS",
            ic_january: 5.524,
            ic_february: 5.815,
            ic_march: 4.88,
            ic_april: 4.366,
            ic_may: 3.782,
            ic_june: 3.64,
            ic_july: 3.813,
            ic_august: 4.732,
            ic_september: 4.925,
            ic_october: 5.162,
            ic_november: 4.948,
            ic_december: 5.561,
            ic_yearly: 4.762,
          },
          {
            ic_uuid: "dabd8dbf-318b-43e8-b0b3-01b9d7c856db",
            ic_lat: -235062,
            ic_lon: -468794,
            ic_city: "Barueri",
            ic_states: "SÃO PAULO",
            ic_january: 5.274,
            ic_february: 5.565,
            ic_march: 4.769,
            ic_april: 4.246,
            ic_may: 3.481,
            ic_june: 3.203,
            ic_july: 3.31,
            ic_august: 4.277,
            ic_september: 4.311,
            ic_october: 4.81,
            ic_november: 5.254,
            ic_december: 5.794,
            ic_yearly: 4.525,
          },
          {
            ic_uuid: "09300af0-e224-4e2f-99d1-459c439e9e05",
            ic_lat: -219214,
            ic_lon: -507361,
            ic_city: "Bastos",
            ic_states: "SÃO PAULO",
            ic_january: 5.911,
            ic_february: 5.895,
            ic_march: 5.378,
            ic_april: 4.74,
            ic_may: 3.957,
            ic_june: 3.638,
            ic_july: 3.854,
            ic_august: 4.84,
            ic_september: 4.838,
            ic_october: 5.537,
            ic_november: 5.999,
            ic_december: 6.312,
            ic_yearly: 5.075,
          },
          {
            ic_uuid: "45d2f80d-26c6-4465-b350-1df9a172aa79",
            ic_lat: -217164,
            ic_lon: -524225,
            ic_city: "Bataguassu",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.83,
            ic_february: 5.917,
            ic_march: 5.443,
            ic_april: 4.798,
            ic_may: 3.964,
            ic_june: 3.587,
            ic_july: 3.749,
            ic_august: 4.726,
            ic_september: 4.817,
            ic_october: 5.459,
            ic_november: 6.104,
            ic_december: 6.41,
            ic_yearly: 5.067,
          },
          {
            ic_uuid: "3a0721f9-f705-45eb-aed7-bb3b771a4535",
            ic_lat: -96735,
            ic_lon: -371279,
            ic_city: "Batalha",
            ic_states: "ALAGOAS",
            ic_january: 6.067,
            ic_february: 5.785,
            ic_march: 5.811,
            ic_april: 5.152,
            ic_may: 4.279,
            ic_june: 3.975,
            ic_july: 4.097,
            ic_august: 4.724,
            ic_september: 5.504,
            ic_october: 5.731,
            ic_november: 6.323,
            ic_december: 6.261,
            ic_yearly: 5.309,
          },
          {
            ic_uuid: "c6158781-0d2e-44b4-aa57-547b4997a68d",
            ic_lat: -40227,
            ic_lon: -42079,
            ic_city: "Batalha",
            ic_states: "PIAUÍ",
            ic_january: 5.319,
            ic_february: 5.462,
            ic_march: 5.389,
            ic_april: 5.186,
            ic_may: 5.209,
            ic_june: 5.309,
            ic_july: 5.58,
            ic_august: 6.088,
            ic_september: 6.453,
            ic_october: 6.49,
            ic_november: 6.288,
            ic_december: 5.713,
            ic_yearly: 5.708,
          },
          {
            ic_uuid: "f5fe25b5-b31d-45aa-9b16-c161a2a8fae0",
            ic_lat: -208933,
            ic_lon: -475925,
            ic_city: "Batatais",
            ic_states: "SÃO PAULO",
            ic_january: 5.624,
            ic_february: 5.822,
            ic_march: 5.052,
            ic_april: 4.812,
            ic_may: 4.178,
            ic_june: 3.965,
            ic_july: 4.187,
            ic_august: 5.109,
            ic_september: 5.088,
            ic_october: 5.512,
            ic_november: 5.638,
            ic_december: 5.892,
            ic_yearly: 5.073,
          },
          {
            ic_uuid: "1dff4851-2280-4932-8019-ddf0cdcf8faa",
            ic_lat: -222949,
            ic_lon: -532709,
            ic_city: "Batayporã",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.905,
            ic_february: 5.825,
            ic_march: 5.37,
            ic_april: 4.681,
            ic_may: 3.774,
            ic_june: 3.416,
            ic_july: 3.59,
            ic_august: 4.531,
            ic_september: 4.787,
            ic_october: 5.439,
            ic_november: 6.057,
            ic_december: 6.394,
            ic_yearly: 4.981,
          },
          {
            ic_uuid: "ba958f13-de2e-40c5-aa17-ebd06519140e",
            ic_lat: -43264,
            ic_lon: -388815,
            ic_city: "Baturité",
            ic_states: "CEARÁ",
            ic_january: 5.043,
            ic_february: 5.069,
            ic_march: 5.123,
            ic_april: 4.61,
            ic_may: 4.801,
            ic_june: 4.582,
            ic_july: 5.104,
            ic_august: 5.836,
            ic_september: 6.136,
            ic_october: 5.991,
            ic_november: 5.895,
            ic_december: 5.308,
            ic_yearly: 5.291,
          },
          {
            ic_uuid: "ac27d19a-2ad9-4867-80c9-645aa00b7e8e",
            ic_lat: -22325,
            ic_lon: -490876,
            ic_city: "Bauru",
            ic_states: "SÃO PAULO",
            ic_january: 5.657,
            ic_february: 5.852,
            ic_march: 5.226,
            ic_april: 4.721,
            ic_may: 3.865,
            ic_june: 3.561,
            ic_july: 3.787,
            ic_august: 4.691,
            ic_september: 4.81,
            ic_october: 5.573,
            ic_november: 5.92,
            ic_december: 6.282,
            ic_yearly: 4.995,
          },
          {
            ic_uuid: "625cecfe-a2e6-4de4-9fa1-2a3c72b42003",
            ic_lat: -71282,
            ic_lon: -349256,
            ic_city: "Bayeux",
            ic_states: "PARAÍBA",
            ic_january: 5.822,
            ic_february: 5.975,
            ic_march: 6.033,
            ic_april: 5.353,
            ic_may: 4.761,
            ic_june: 4.289,
            ic_july: 4.405,
            ic_august: 5.197,
            ic_september: 5.627,
            ic_october: 5.919,
            ic_november: 6.194,
            ic_december: 6.099,
            ic_yearly: 5.473,
          },
          {
            ic_uuid: "dc29d5ed-f4df-4939-9e98-3a202e48c961",
            ic_lat: -209495,
            ic_lon: -484795,
            ic_city: "Bebedouro",
            ic_states: "SÃO PAULO",
            ic_january: 5.822,
            ic_february: 6.053,
            ic_march: 5.198,
            ic_april: 4.806,
            ic_may: 4.177,
            ic_june: 3.89,
            ic_july: 4.158,
            ic_august: 4.994,
            ic_september: 5.05,
            ic_october: 5.577,
            ic_november: 5.824,
            ic_december: 6.087,
            ic_yearly: 5.136,
          },
          {
            ic_uuid: "97679092-f2e1-48fb-8635-59d141a5aa2f",
            ic_lat: -41778,
            ic_lon: -381274,
            ic_city: "Beberibe",
            ic_states: "CEARÁ",
            ic_january: 5.947,
            ic_february: 6.05,
            ic_march: 5.806,
            ic_april: 5.217,
            ic_may: 5.346,
            ic_june: 5.31,
            ic_july: 5.553,
            ic_august: 6.103,
            ic_september: 6.084,
            ic_october: 6.431,
            ic_november: 6.431,
            ic_december: 6.181,
            ic_yearly: 5.872,
          },
          {
            ic_uuid: "60d1eddf-1965-4a96-aedb-72864a82fd51",
            ic_lat: -30504,
            ic_lon: -401675,
            ic_city: "Bela Cruz",
            ic_states: "CEARÁ",
            ic_january: 5.371,
            ic_february: 5.256,
            ic_march: 5.052,
            ic_april: 4.557,
            ic_may: 5.142,
            ic_june: 5.257,
            ic_july: 5.499,
            ic_august: 6.043,
            ic_september: 6.305,
            ic_october: 6.197,
            ic_november: 6.279,
            ic_december: 5.832,
            ic_yearly: 5.566,
          },
          {
            ic_uuid: "f6446678-3ff1-4589-bd74-774eaba4a0e4",
            ic_lat: -221078,
            ic_lon: -565267,
            ic_city: "Bela Vista",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 6.097,
            ic_february: 5.71,
            ic_march: 5.295,
            ic_april: 4.55,
            ic_may: 3.558,
            ic_june: 3.223,
            ic_july: 3.32,
            ic_august: 4.313,
            ic_september: 4.638,
            ic_october: 5.382,
            ic_november: 5.955,
            ic_december: 6.401,
            ic_yearly: 4.87,
          },
          {
            ic_uuid: "024b20cb-3980-4267-8674-f0f73bc8226c",
            ic_lat: -258846,
            ic_lon: -53673,
            ic_city: "Bela Vista da Caroba",
            ic_states: "PARANÁ",
            ic_january: 6.414,
            ic_february: 5.899,
            ic_march: 5.284,
            ic_april: 4.237,
            ic_may: 3.293,
            ic_june: 2.833,
            ic_july: 3.11,
            ic_august: 4.06,
            ic_september: 4.265,
            ic_october: 5.277,
            ic_november: 6.179,
            ic_december: 6.49,
            ic_yearly: 4.778,
          },
          {
            ic_uuid: "6bab8d96-175f-450e-b72e-f6e09f188d89",
            ic_lat: -169697,
            ic_lon: -489517,
            ic_city: "Bela Vista de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.566,
            ic_february: 5.713,
            ic_march: 5.231,
            ic_april: 5.107,
            ic_may: 4.778,
            ic_june: 4.572,
            ic_july: 4.737,
            ic_august: 5.603,
            ic_september: 5.541,
            ic_october: 5.625,
            ic_november: 5.43,
            ic_december: 5.592,
            ic_yearly: 5.291,
          },
          {
            ic_uuid: "8dc872e4-63fd-4a64-af57-fb580b80938d",
            ic_lat: -198307,
            ic_lon: -430925,
            ic_city: "Bela Vista de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.628,
            ic_february: 5.939,
            ic_march: 4.974,
            ic_april: 4.501,
            ic_may: 3.852,
            ic_june: 3.634,
            ic_july: 3.89,
            ic_august: 4.654,
            ic_september: 4.961,
            ic_october: 5.05,
            ic_november: 4.739,
            ic_december: 5.465,
            ic_yearly: 4.774,
          },
          {
            ic_uuid: "03f2e3f3-7110-49a6-98c5-0ef4b7d0b6fb",
            ic_lat: -37407,
            ic_lon: -452496,
            ic_city: "Bela Vista do Maranhão",
            ic_states: "MARANHÃO",
            ic_january: 4.643,
            ic_february: 4.8,
            ic_march: 4.838,
            ic_april: 4.827,
            ic_may: 4.755,
            ic_june: 4.97,
            ic_july: 5.041,
            ic_august: 5.532,
            ic_september: 5.837,
            ic_october: 5.45,
            ic_november: 5.06,
            ic_december: 4.962,
            ic_yearly: 5.059,
          },
          {
            ic_uuid: "8d9915f9-681a-4fcb-88c3-fc5a9ac7d0d3",
            ic_lat: -229941,
            ic_lon: -511931,
            ic_city: "Bela Vista do Paraíso",
            ic_states: "PARANÁ",
            ic_january: 5.974,
            ic_february: 5.896,
            ic_march: 5.345,
            ic_april: 4.711,
            ic_may: 3.742,
            ic_june: 3.443,
            ic_july: 3.65,
            ic_august: 4.589,
            ic_september: 4.728,
            ic_october: 5.424,
            ic_november: 6.107,
            ic_december: 6.433,
            ic_yearly: 5.004,
          },
          {
            ic_uuid: "e1f33250-ffc6-4025-ac1a-c77c6dc36595",
            ic_lat: -79885,
            ic_lon: -418679,
            ic_city: "Bela Vista do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.747,
            ic_february: 5.636,
            ic_march: 5.62,
            ic_april: 5.316,
            ic_may: 5.262,
            ic_june: 5.232,
            ic_july: 5.55,
            ic_august: 6.271,
            ic_september: 6.591,
            ic_october: 6.564,
            ic_november: 6.316,
            ic_december: 6.006,
            ic_yearly: 5.843,
          },
          {
            ic_uuid: "2377b46f-fe39-45ff-b2bb-90d3b60a6077",
            ic_lat: -262751,
            ic_lon: -504668,
            ic_city: "Bela Vista do Toldo",
            ic_states: "SANTA CATARINA",
            ic_january: 5.382,
            ic_february: 5.049,
            ic_march: 4.501,
            ic_april: 3.519,
            ic_may: 2.858,
            ic_june: 2.398,
            ic_july: 2.65,
            ic_august: 3.632,
            ic_september: 3.769,
            ic_october: 4.328,
            ic_november: 5.355,
            ic_december: 5.573,
            ic_yearly: 4.085,
          },
          {
            ic_uuid: "5f9cab48-8f1c-4417-9a95-142dd4dd9cd2",
            ic_lat: -31604,
            ic_lon: -435083,
            ic_city: "Belágua",
            ic_states: "MARANHÃO",
            ic_january: 4.758,
            ic_february: 4.814,
            ic_march: 4.846,
            ic_april: 4.776,
            ic_may: 4.918,
            ic_june: 5.177,
            ic_july: 5.367,
            ic_august: 5.981,
            ic_september: 6.297,
            ic_october: 6.113,
            ic_november: 5.776,
            ic_december: 5.217,
            ic_yearly: 5.337,
          },
          {
            ic_uuid: "1715949e-6a29-4a40-bae9-4ed6f4b2c715",
            ic_lat: -95709,
            ic_lon: -364907,
            ic_city: "Belém",
            ic_states: "ALAGOAS",
            ic_january: 5.989,
            ic_february: 5.835,
            ic_march: 5.777,
            ic_april: 5.115,
            ic_may: 4.198,
            ic_june: 3.834,
            ic_july: 3.925,
            ic_august: 4.551,
            ic_september: 5.377,
            ic_october: 5.718,
            ic_november: 6.331,
            ic_december: 6.314,
            ic_yearly: 5.247,
          },
          {
            ic_uuid: "2e419603-e535-4229-9616-bccaa7521f20",
            ic_lat: -14562,
            ic_lon: -484905,
            ic_city: "Belém",
            ic_states: "PARÁ",
            ic_january: 4.441,
            ic_february: 4.403,
            ic_march: 4.414,
            ic_april: 4.549,
            ic_may: 4.714,
            ic_june: 4.968,
            ic_july: 5.078,
            ic_august: 5.299,
            ic_september: 5.382,
            ic_october: 5.251,
            ic_november: 5.118,
            ic_december: 4.754,
            ic_yearly: 4.864,
          },
          {
            ic_uuid: "85a46136-42b2-48d4-9e4d-b013df4c9c80",
            ic_lat: -67435,
            ic_lon: -355172,
            ic_city: "Belém",
            ic_states: "PARAÍBA",
            ic_january: 5.611,
            ic_february: 5.677,
            ic_march: 5.739,
            ic_april: 5.246,
            ic_may: 4.769,
            ic_june: 4.304,
            ic_july: 4.372,
            ic_august: 5.13,
            ic_september: 5.593,
            ic_october: 5.783,
            ic_november: 5.968,
            ic_december: 5.699,
            ic_yearly: 5.324,
          },
          {
            ic_uuid: "60a9c742-f986-45cb-907b-b492e189f259",
            ic_lat: -86255,
            ic_lon: -358338,
            ic_city: "Belém de Maria",
            ic_states: "PERNAMBUCO",
            ic_january: 5.912,
            ic_february: 5.839,
            ic_march: 5.886,
            ic_april: 5.175,
            ic_may: 4.354,
            ic_june: 3.907,
            ic_july: 3.955,
            ic_august: 4.624,
            ic_september: 5.386,
            ic_october: 5.736,
            ic_november: 6.191,
            ic_december: 6.112,
            ic_yearly: 5.256,
          },
          {
            ic_uuid: "54d122e5-6a0c-4949-914f-dc9db64fcb47",
            ic_lat: -87509,
            ic_lon: -389626,
            ic_city: "Belém de São Francisco",
            ic_states: "PERNAMBUCO",
            ic_january: 6.383,
            ic_february: 6.281,
            ic_march: 6.225,
            ic_april: 5.562,
            ic_may: 4.768,
            ic_june: 4.302,
            ic_july: 4.535,
            ic_august: 5.25,
            ic_september: 6.107,
            ic_october: 6.299,
            ic_november: 6.594,
            ic_december: 6.639,
            ic_yearly: 5.746,
          },
          {
            ic_uuid: "6ced47cf-5f06-4370-aeb5-e04b838c7371",
            ic_lat: -61856,
            ic_lon: -375351,
            ic_city: "Belém do Brejo do Cruz",
            ic_states: "PARAÍBA",
            ic_january: 6.094,
            ic_february: 6.127,
            ic_march: 6.106,
            ic_april: 5.866,
            ic_may: 5.345,
            ic_june: 4.972,
            ic_july: 5.261,
            ic_august: 5.947,
            ic_september: 6.501,
            ic_october: 6.603,
            ic_november: 6.609,
            ic_december: 6.2,
            ic_yearly: 5.97,
          },
          {
            ic_uuid: "fbe58e82-06d6-4701-8e87-5a1b9ecb348f",
            ic_lat: -73669,
            ic_lon: -409691,
            ic_city: "Belém do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.608,
            ic_february: 5.531,
            ic_march: 5.56,
            ic_april: 5.281,
            ic_may: 5.185,
            ic_june: 5.152,
            ic_july: 5.509,
            ic_august: 6.205,
            ic_september: 6.676,
            ic_october: 6.495,
            ic_november: 6.382,
            ic_december: 6.029,
            ic_yearly: 5.801,
          },
          {
            ic_uuid: "88a66627-ee03-4eea-9f90-8a6009e14578",
            ic_lat: -227645,
            ic_lon: -433996,
            ic_city: "Belford Roxo",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.848,
            ic_february: 6.049,
            ic_march: 4.899,
            ic_april: 4.321,
            ic_may: 3.585,
            ic_june: 3.336,
            ic_july: 3.325,
            ic_august: 4.174,
            ic_september: 4.353,
            ic_october: 4.909,
            ic_november: 4.925,
            ic_december: 5.658,
            ic_yearly: 4.615,
          },
          {
            ic_uuid: "e9a0a8b9-05d6-44b3-b276-8aebaffb5f0d",
            ic_lat: -219445,
            ic_lon: -434088,
            ic_city: "Belmiro Braga",
            ic_states: "MINAS GERAIS",
            ic_january: 5.433,
            ic_february: 5.774,
            ic_march: 4.801,
            ic_april: 4.186,
            ic_may: 3.542,
            ic_june: 3.367,
            ic_july: 3.507,
            ic_august: 4.279,
            ic_september: 4.513,
            ic_october: 4.814,
            ic_november: 4.724,
            ic_december: 5.413,
            ic_yearly: 4.53,
          },
          {
            ic_uuid: "63482b61-d3f8-44c8-b9d8-419403a06db1",
            ic_lat: -158613,
            ic_lon: -388761,
            ic_city: "Belmonte",
            ic_states: "BAHIA",
            ic_january: 6.32,
            ic_february: 6.469,
            ic_march: 5.776,
            ic_april: 4.834,
            ic_may: 4.145,
            ic_june: 3.766,
            ic_july: 4.005,
            ic_august: 4.785,
            ic_september: 5.304,
            ic_october: 5.694,
            ic_november: 5.781,
            ic_december: 6.435,
            ic_yearly: 5.276,
          },
          {
            ic_uuid: "3207fc26-a8ad-49bb-8f91-655f18a5a22a",
            ic_lat: -268435,
            ic_lon: -535763,
            ic_city: "Belmonte",
            ic_states: "SANTA CATARINA",
            ic_january: 6.32,
            ic_february: 5.764,
            ic_march: 5.069,
            ic_april: 4.091,
            ic_may: 3.162,
            ic_june: 2.68,
            ic_july: 2.977,
            ic_august: 3.823,
            ic_september: 4.095,
            ic_october: 5.146,
            ic_november: 6.018,
            ic_december: 6.431,
            ic_yearly: 4.631,
          },
          {
            ic_uuid: "6978069f-fea1-4e92-897c-caf631f81d07",
            ic_lat: -150338,
            ic_lon: -412655,
            ic_city: "Belo Campo",
            ic_states: "BAHIA",
            ic_january: 6.147,
            ic_february: 6.146,
            ic_march: 5.536,
            ic_april: 4.821,
            ic_may: 4.268,
            ic_june: 3.812,
            ic_july: 4.102,
            ic_august: 4.765,
            ic_september: 5.668,
            ic_october: 5.737,
            ic_november: 5.588,
            ic_december: 6.169,
            ic_yearly: 5.23,
          },
          {
            ic_uuid: "2c5f690e-0637-4e4e-80e0-94aa2a2aa49c",
            ic_lat: -199111,
            ic_lon: -439273,
            ic_city: "Belo Horizonte",
            ic_states: "MINAS GERAIS",
            ic_january: 5.799,
            ic_february: 5.982,
            ic_march: 5.154,
            ic_april: 4.775,
            ic_may: 4.17,
            ic_june: 4.063,
            ic_july: 4.282,
            ic_august: 5.145,
            ic_september: 5.535,
            ic_october: 5.659,
            ic_november: 5.349,
            ic_december: 5.639,
            ic_yearly: 5.129,
          },
          {
            ic_uuid: "92e0b74d-7c42-4e47-9d3b-ba7e51d64b0d",
            ic_lat: -83369,
            ic_lon: -364224,
            ic_city: "Belo Jardim",
            ic_states: "PERNAMBUCO",
            ic_january: 6.046,
            ic_february: 5.83,
            ic_march: 5.805,
            ic_april: 5.262,
            ic_may: 4.341,
            ic_june: 3.826,
            ic_july: 3.974,
            ic_august: 4.663,
            ic_september: 5.61,
            ic_october: 5.972,
            ic_november: 6.382,
            ic_december: 6.206,
            ic_yearly: 5.326,
          },
          {
            ic_uuid: "b5ec6714-9795-40ed-b8f2-bf231541fd6f",
            ic_lat: -98232,
            ic_lon: -372773,
            ic_city: "Belo Monte",
            ic_states: "ALAGOAS",
            ic_january: 6.106,
            ic_february: 5.948,
            ic_march: 5.903,
            ic_april: 5.211,
            ic_may: 4.36,
            ic_june: 4.066,
            ic_july: 4.154,
            ic_august: 4.787,
            ic_september: 5.56,
            ic_october: 5.804,
            ic_november: 6.341,
            ic_december: 6.25,
            ic_yearly: 5.374,
          },
          {
            ic_uuid: "82cb2901-6915-421a-9bdd-7dff6920a2f3",
            ic_lat: -192204,
            ic_lon: -424832,
            ic_city: "Belo Oriente",
            ic_states: "MINAS GERAIS",
            ic_january: 5.885,
            ic_february: 6.193,
            ic_march: 5.212,
            ic_april: 4.684,
            ic_may: 3.917,
            ic_june: 3.733,
            ic_july: 3.886,
            ic_august: 4.546,
            ic_september: 4.955,
            ic_october: 5.121,
            ic_november: 4.863,
            ic_december: 5.709,
            ic_yearly: 4.892,
          },
          {
            ic_uuid: "5a3d0c7f-ce99-46a2-8dfa-5489a882c1a8",
            ic_lat: -204082,
            ic_lon: -440278,
            ic_city: "Belo Vale",
            ic_states: "MINAS GERAIS",
            ic_january: 5.79,
            ic_february: 5.963,
            ic_march: 5.113,
            ic_april: 4.792,
            ic_may: 4.149,
            ic_june: 4.025,
            ic_july: 4.196,
            ic_august: 5.074,
            ic_september: 5.342,
            ic_october: 5.49,
            ic_november: 5.25,
            ic_december: 5.608,
            ic_yearly: 5.066,
          },
          {
            ic_uuid: "3fe1363c-801d-4f68-b64d-a3d10706b746",
            ic_lat: -26365,
            ic_lon: -549378,
            ic_city: "Belterra",
            ic_states: "PARÁ",
            ic_january: 4.441,
            ic_february: 4.447,
            ic_march: 4.346,
            ic_april: 4.132,
            ic_may: 4.193,
            ic_june: 4.493,
            ic_july: 4.438,
            ic_august: 5.026,
            ic_september: 5.045,
            ic_october: 5.242,
            ic_november: 5.203,
            ic_december: 4.591,
            ic_yearly: 4.633,
          },
          {
            ic_uuid: "2fe7ce1b-3b46-4987-a4e5-7dc5933e4a45",
            ic_lat: -54568,
            ic_lon: -423598,
            ic_city: "Beneditinos",
            ic_states: "PIAUÍ",
            ic_january: 5.227,
            ic_february: 5.326,
            ic_march: 5.292,
            ic_april: 5.2,
            ic_may: 5.229,
            ic_june: 5.372,
            ic_july: 5.629,
            ic_august: 6.141,
            ic_september: 6.552,
            ic_october: 6.45,
            ic_november: 6.25,
            ic_december: 5.819,
            ic_yearly: 5.707,
          },
          {
            ic_uuid: "1019451e-5692-4573-9f57-30e894d33518",
            ic_lat: -72197,
            ic_lon: -445544,
            ic_city: "Benedito Leite",
            ic_states: "MARANHÃO",
            ic_january: 5.027,
            ic_february: 5.089,
            ic_march: 5.019,
            ic_april: 5.118,
            ic_may: 5.278,
            ic_june: 5.385,
            ic_july: 5.607,
            ic_august: 6.032,
            ic_september: 6.558,
            ic_october: 6.04,
            ic_november: 5.573,
            ic_december: 5.312,
            ic_yearly: 5.503,
          },
          {
            ic_uuid: "f942b9a4-e348-47fa-803a-2acf0ffedeed",
            ic_lat: -267815,
            ic_lon: -493597,
            ic_city: "Benedito Novo",
            ic_states: "SANTA CATARINA",
            ic_january: 5.276,
            ic_february: 4.992,
            ic_march: 4.449,
            ic_april: 3.626,
            ic_may: 2.983,
            ic_june: 2.488,
            ic_july: 2.621,
            ic_august: 3.353,
            ic_september: 3.342,
            ic_october: 3.842,
            ic_november: 5.004,
            ic_december: 5.308,
            ic_yearly: 3.94,
          },
          {
            ic_uuid: "1df0ee94-5c2e-427c-9106-4a69568b1541",
            ic_lat: -13622,
            ic_lon: -482438,
            ic_city: "Benevides",
            ic_states: "PARÁ",
            ic_january: 4.317,
            ic_february: 4.251,
            ic_march: 4.291,
            ic_april: 4.342,
            ic_may: 4.603,
            ic_june: 4.913,
            ic_july: 5.052,
            ic_august: 5.294,
            ic_september: 5.344,
            ic_october: 5.123,
            ic_november: 4.964,
            ic_december: 4.549,
            ic_yearly: 4.754,
          },
          {
            ic_uuid: "9a3b6149-63f8-48a2-9f0b-2e76615d7abf",
            ic_lat: -43781,
            ic_lon: -700346,
            ic_city: "Benjamin Constant",
            ic_states: "AMAZONAS",
            ic_january: 4.368,
            ic_february: 4.713,
            ic_march: 4.528,
            ic_april: 4.406,
            ic_may: 4.025,
            ic_june: 4.148,
            ic_july: 4.116,
            ic_august: 4.857,
            ic_september: 5.071,
            ic_october: 4.912,
            ic_november: 4.856,
            ic_december: 4.438,
            ic_yearly: 4.537,
          },
          {
            ic_uuid: "dc6d87ff-caf4-4053-b709-3e80a17131ee",
            ic_lat: -27509,
            ic_lon: -526,
            ic_city: "Benjamin Constant do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.264,
            ic_february: 5.786,
            ic_march: 5.084,
            ic_april: 4.101,
            ic_may: 3.107,
            ic_june: 2.623,
            ic_july: 2.928,
            ic_august: 3.759,
            ic_september: 3.991,
            ic_october: 5.114,
            ic_november: 6.18,
            ic_december: 6.533,
            ic_yearly: 4.623,
          },
          {
            ic_uuid: "d6507d84-3aed-4535-bdc9-ce4e8f5d403a",
            ic_lat: -21271,
            ic_lon: -508119,
            ic_city: "Bento de Abreu",
            ic_states: "SÃO PAULO",
            ic_january: 5.917,
            ic_february: 5.933,
            ic_march: 5.422,
            ic_april: 4.869,
            ic_may: 4.014,
            ic_june: 3.741,
            ic_july: 3.937,
            ic_august: 4.859,
            ic_september: 4.915,
            ic_october: 5.605,
            ic_november: 6.036,
            ic_december: 6.298,
            ic_yearly: 5.129,
          },
          {
            ic_uuid: "bc841bae-8375-4627-9005-e9d42744a8a3",
            ic_lat: -56924,
            ic_lon: -358207,
            ic_city: "Bento Fernandes",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.768,
            ic_february: 5.749,
            ic_march: 5.773,
            ic_april: 5.495,
            ic_may: 5.034,
            ic_june: 4.632,
            ic_july: 4.847,
            ic_august: 5.52,
            ic_september: 5.899,
            ic_october: 6.071,
            ic_november: 6.172,
            ic_december: 5.77,
            ic_yearly: 5.561,
          },
          {
            ic_uuid: "77473698-ee30-4839-97e3-f86ce5e1bd70",
            ic_lat: -291667,
            ic_lon: -515169,
            ic_city: "Bento Gonçalves",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.196,
            ic_february: 5.772,
            ic_march: 4.778,
            ic_april: 3.838,
            ic_may: 2.834,
            ic_june: 2.386,
            ic_july: 2.657,
            ic_august: 3.4,
            ic_september: 3.655,
            ic_october: 4.789,
            ic_november: 6.166,
            ic_december: 6.563,
            ic_yearly: 4.419,
          },
          {
            ic_uuid: "002255f4-2b6c-4e95-868c-de7960f4233b",
            ic_lat: -24495,
            ic_lon: -447908,
            ic_city: "Bequimão",
            ic_states: "MARANHÃO",
            ic_january: 4.786,
            ic_february: 4.683,
            ic_march: 4.602,
            ic_april: 4.476,
            ic_may: 4.59,
            ic_june: 4.896,
            ic_july: 5.005,
            ic_august: 5.536,
            ic_september: 5.796,
            ic_october: 5.523,
            ic_november: 5.401,
            ic_december: 5.165,
            ic_yearly: 5.038,
          },
          {
            ic_uuid: "b8562ba7-ccfe-47fd-baa5-d4067f4530ca",
            ic_lat: -169522,
            ic_lon: -424645,
            ic_city: "Berilo",
            ic_states: "MINAS GERAIS",
            ic_january: 6.285,
            ic_february: 6.478,
            ic_march: 5.509,
            ic_april: 4.784,
            ic_may: 4.116,
            ic_june: 3.924,
            ic_july: 4.118,
            ic_august: 4.898,
            ic_september: 5.463,
            ic_october: 5.698,
            ic_november: 5.284,
            ic_december: 6.031,
            ic_yearly: 5.216,
          },
          {
            ic_uuid: "969d3e1d-de1d-4aa1-a00c-78df72e07523",
            ic_lat: -156105,
            ic_lon: -417435,
            ic_city: "Berizal",
            ic_states: "MINAS GERAIS",
            ic_january: 6.12,
            ic_february: 6.289,
            ic_march: 5.426,
            ic_april: 4.777,
            ic_may: 4.086,
            ic_june: 3.75,
            ic_july: 3.972,
            ic_august: 4.699,
            ic_september: 5.582,
            ic_october: 5.688,
            ic_november: 5.338,
            ic_december: 6.095,
            ic_yearly: 5.152,
          },
          {
            ic_uuid: "db9cb0df-2d90-425d-9fc2-4360e7df783a",
            ic_lat: -64538,
            ic_lon: -385502,
            ic_city: "Bernardino Batista",
            ic_states: "PARAÍBA",
            ic_january: 5.976,
            ic_february: 5.895,
            ic_march: 5.814,
            ic_april: 5.509,
            ic_may: 5.084,
            ic_june: 4.92,
            ic_july: 5.291,
            ic_august: 6.049,
            ic_september: 6.495,
            ic_october: 6.605,
            ic_november: 6.701,
            ic_december: 6.145,
            ic_yearly: 5.874,
          },
          {
            ic_uuid: "7672a147-48d5-4a5e-837f-e285fc483533",
            ic_lat: -23013,
            ic_lon: -494739,
            ic_city: "Bernardino de Campos",
            ic_states: "SÃO PAULO",
            ic_january: 5.635,
            ic_february: 5.776,
            ic_march: 5.163,
            ic_april: 4.654,
            ic_may: 3.741,
            ic_june: 3.427,
            ic_july: 3.642,
            ic_august: 4.637,
            ic_september: 4.729,
            ic_october: 5.391,
            ic_november: 5.887,
            ic_december: 6.308,
            ic_yearly: 4.916,
          },
          {
            ic_uuid: "7945b4fc-e1a5-4335-a194-6f1a7d3be1b5",
            ic_lat: -46271,
            ic_lon: -447611,
            ic_city: "Bernardo do Mearim",
            ic_states: "MARANHÃO",
            ic_january: 4.703,
            ic_february: 4.814,
            ic_march: 4.888,
            ic_april: 4.845,
            ic_may: 4.818,
            ic_june: 5.059,
            ic_july: 5.294,
            ic_august: 5.821,
            ic_september: 6.141,
            ic_october: 5.676,
            ic_november: 5.264,
            ic_december: 4.938,
            ic_yearly: 5.189,
          },
          {
            ic_uuid: "6d513178-59b5-4d0c-a4fb-5bb6f3e46525",
            ic_lat: -78809,
            ic_lon: -488958,
            ic_city: "Bernardo Sayão",
            ic_states: "TOCANTINS",
            ic_january: 4.706,
            ic_february: 4.803,
            ic_march: 4.693,
            ic_april: 4.709,
            ic_may: 4.973,
            ic_june: 5.128,
            ic_july: 5.217,
            ic_august: 5.917,
            ic_september: 5.655,
            ic_october: 5.117,
            ic_november: 4.775,
            ic_december: 4.645,
            ic_yearly: 5.028,
          },
          {
            ic_uuid: "110a8f8d-f16c-4a59-91e1-9eeefd5b14cc",
            ic_lat: -23849,
            ic_lon: -4614,
            ic_city: "Bertioga",
            ic_states: "SÃO PAULO",
            ic_january: 4.878,
            ic_february: 5.134,
            ic_march: 4.228,
            ic_april: 3.803,
            ic_may: 3.18,
            ic_june: 2.885,
            ic_july: 2.967,
            ic_august: 3.68,
            ic_september: 3.591,
            ic_october: 3.946,
            ic_november: 4.353,
            ic_december: 4.996,
            ic_yearly: 3.97,
          },
          {
            ic_uuid: "0289c594-2c56-4a6e-a78d-dbf27fa24515",
            ic_lat: -76338,
            ic_lon: -439501,
            ic_city: "Bertolínia",
            ic_states: "PIAUÍ",
            ic_january: 5.25,
            ic_february: 5.213,
            ic_march: 5.121,
            ic_april: 5.175,
            ic_may: 5.303,
            ic_june: 5.371,
            ic_july: 5.602,
            ic_august: 6.225,
            ic_september: 6.56,
            ic_october: 6.253,
            ic_november: 5.75,
            ic_december: 5.501,
            ic_yearly: 5.61,
          },
          {
            ic_uuid: "1757eaa5-4cd7-4a70-a85a-5635f68fa66a",
            ic_lat: -170595,
            ic_lon: -405803,
            ic_city: "Bertópolis",
            ic_states: "MINAS GERAIS",
            ic_january: 6.111,
            ic_february: 6.247,
            ic_march: 5.445,
            ic_april: 4.667,
            ic_may: 3.835,
            ic_june: 3.48,
            ic_july: 3.692,
            ic_august: 4.373,
            ic_september: 5.057,
            ic_october: 5.308,
            ic_november: 5.293,
            ic_december: 6.182,
            ic_yearly: 4.974,
          },
          {
            ic_uuid: "bca0e429-9588-4104-beb4-f9d940ac1f1c",
            ic_lat: -38991,
            ic_lon: -613621,
            ic_city: "Beruri",
            ic_states: "AMAZONAS",
            ic_january: 4.149,
            ic_february: 4.179,
            ic_march: 4.18,
            ic_april: 3.897,
            ic_may: 3.817,
            ic_june: 4.158,
            ic_july: 4.308,
            ic_august: 4.943,
            ic_september: 4.957,
            ic_october: 4.874,
            ic_november: 4.612,
            ic_december: 4.271,
            ic_yearly: 4.362,
          },
          {
            ic_uuid: "0c80a9a6-7f6a-4656-b65d-418b8a7199d8",
            ic_lat: -82735,
            ic_lon: -380365,
            ic_city: "Betânia",
            ic_states: "PERNAMBUCO",
            ic_january: 6.355,
            ic_february: 6.137,
            ic_march: 6.128,
            ic_april: 5.639,
            ic_may: 4.728,
            ic_june: 4.233,
            ic_july: 4.516,
            ic_august: 5.273,
            ic_september: 6.238,
            ic_october: 6.494,
            ic_november: 6.766,
            ic_december: 6.517,
            ic_yearly: 5.752,
          },
          {
            ic_uuid: "18cebb36-416e-4ecf-ad77-8681ba256fab",
            ic_lat: -81442,
            ic_lon: -407992,
            ic_city: "Betânia do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.951,
            ic_february: 5.704,
            ic_march: 5.807,
            ic_april: 5.331,
            ic_may: 5.043,
            ic_june: 4.926,
            ic_july: 5.311,
            ic_august: 6.178,
            ic_september: 6.65,
            ic_october: 6.663,
            ic_november: 6.517,
            ic_december: 6.197,
            ic_yearly: 5.857,
          },
          {
            ic_uuid: "c948a681-da30-4efb-9e5b-e52fc7ef45e3",
            ic_lat: -199673,
            ic_lon: -442011,
            ic_city: "Betim",
            ic_states: "MINAS GERAIS",
            ic_january: 5.869,
            ic_february: 6.035,
            ic_march: 5.189,
            ic_april: 4.859,
            ic_may: 4.238,
            ic_june: 4.097,
            ic_july: 4.351,
            ic_august: 5.198,
            ic_september: 5.506,
            ic_october: 5.604,
            ic_november: 5.323,
            ic_december: 5.625,
            ic_yearly: 5.158,
          },
          {
            ic_uuid: "d7749c8f-7382-41fb-874e-4926f072f0e4",
            ic_lat: -82332,
            ic_lon: -357963,
            ic_city: "Bezerros",
            ic_states: "PERNAMBUCO",
            ic_january: 5.637,
            ic_february: 5.641,
            ic_march: 5.641,
            ic_april: 5.025,
            ic_may: 4.331,
            ic_june: 3.931,
            ic_july: 4.042,
            ic_august: 4.699,
            ic_september: 5.401,
            ic_october: 5.705,
            ic_november: 6.009,
            ic_december: 5.863,
            ic_yearly: 5.161,
          },
          {
            ic_uuid: "83d39fde-7a7d-456d-b940-53754b393c06",
            ic_lat: -216024,
            ic_lon: -437577,
            ic_city: "Bias Fortes",
            ic_states: "MINAS GERAIS",
            ic_january: 5.352,
            ic_february: 5.703,
            ic_march: 4.748,
            ic_april: 4.182,
            ic_may: 3.626,
            ic_june: 3.446,
            ic_july: 3.601,
            ic_august: 4.405,
            ic_september: 4.635,
            ic_october: 4.819,
            ic_november: 4.603,
            ic_december: 5.392,
            ic_yearly: 4.543,
          },
          {
            ic_uuid: "e1128cac-0906-42bb-acf4-6394ae67186e",
            ic_lat: -217236,
            ic_lon: -430563,
            ic_city: "Bicas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.734,
            ic_february: 5.992,
            ic_march: 4.983,
            ic_april: 4.268,
            ic_may: 3.582,
            ic_june: 3.341,
            ic_july: 3.556,
            ic_august: 4.3,
            ic_september: 4.646,
            ic_october: 4.944,
            ic_november: 4.771,
            ic_december: 5.567,
            ic_yearly: 4.64,
          },
          {
            ic_uuid: "198993ef-b3d5-4186-8986-cfe275ec05ac",
            ic_lat: -274965,
            ic_lon: -486602,
            ic_city: "Biguaçu",
            ic_states: "SANTA CATARINA",
            ic_january: 5.668,
            ic_february: 5.317,
            ic_march: 4.562,
            ic_april: 3.717,
            ic_may: 3.025,
            ic_june: 2.545,
            ic_july: 2.674,
            ic_august: 3.378,
            ic_september: 3.457,
            ic_october: 4.108,
            ic_november: 5.326,
            ic_december: 5.739,
            ic_yearly: 4.126,
          },
          {
            ic_uuid: "6236b4df-8579-4422-a87c-83c07ce904cf",
            ic_lat: -214044,
            ic_lon: -504751,
            ic_city: "Bilac",
            ic_states: "SÃO PAULO",
            ic_january: 5.978,
            ic_february: 5.964,
            ic_march: 5.379,
            ic_april: 4.857,
            ic_may: 4.006,
            ic_june: 3.7,
            ic_july: 3.951,
            ic_august: 4.862,
            ic_september: 4.893,
            ic_october: 5.596,
            ic_november: 6.051,
            ic_december: 6.301,
            ic_yearly: 5.128,
          },
          {
            ic_uuid: "1e25e220-a7e1-4a7c-8884-c263f56db976",
            ic_lat: -187696,
            ic_lon: -454981,
            ic_city: "Biquinhas",
            ic_states: "MINAS GERAIS",
            ic_january: 6.027,
            ic_february: 6.266,
            ic_march: 5.353,
            ic_april: 5.183,
            ic_may: 4.688,
            ic_june: 4.39,
            ic_july: 4.765,
            ic_august: 5.589,
            ic_september: 5.816,
            ic_october: 5.847,
            ic_november: 5.46,
            ic_december: 5.791,
            ic_yearly: 5.431,
          },
          {
            ic_uuid: "4299adae-9c65-4399-8481-d4b4d1971a31",
            ic_lat: -212915,
            ic_lon: -503436,
            ic_city: "Birigui",
            ic_states: "SÃO PAULO",
            ic_january: 5.959,
            ic_february: 5.979,
            ic_march: 5.362,
            ic_april: 4.848,
            ic_may: 4.037,
            ic_june: 3.735,
            ic_july: 3.971,
            ic_august: 4.847,
            ic_september: 4.933,
            ic_october: 5.632,
            ic_november: 6.078,
            ic_december: 6.349,
            ic_yearly: 5.144,
          },
          {
            ic_uuid: "80847cb1-04ac-48cb-9ec6-4e2fe2d6ca73",
            ic_lat: -235703,
            ic_lon: -460411,
            ic_city: "Biritiba-Mirim",
            ic_states: "SÃO PAULO",
            ic_january: 5.328,
            ic_february: 5.641,
            ic_march: 4.685,
            ic_april: 4.159,
            ic_may: 3.36,
            ic_june: 3.073,
            ic_july: 3.216,
            ic_august: 4.096,
            ic_september: 4.12,
            ic_october: 4.679,
            ic_november: 5.054,
            ic_december: 5.655,
            ic_yearly: 4.422,
          },
          {
            ic_uuid: "86bc8712-23c3-4fbb-b9d2-6e45081b6667",
            ic_lat: -116215,
            ic_lon: -388054,
            ic_city: "Biritinga",
            ic_states: "BAHIA",
            ic_january: 6.143,
            ic_february: 5.899,
            ic_march: 5.722,
            ic_april: 4.84,
            ic_may: 4.037,
            ic_june: 3.781,
            ic_july: 3.989,
            ic_august: 4.453,
            ic_september: 5.328,
            ic_october: 5.555,
            ic_november: 5.89,
            ic_december: 6.06,
            ic_yearly: 5.142,
          },
          {
            ic_uuid: "59d1c8a6-106e-4925-8171-e3c608aa59ad",
            ic_lat: -261612,
            ic_lon: -515522,
            ic_city: "Bituruna",
            ic_states: "PARANÁ",
            ic_january: 5.54,
            ic_february: 5.251,
            ic_march: 4.688,
            ic_april: 3.747,
            ic_may: 2.948,
            ic_june: 2.563,
            ic_july: 2.829,
            ic_august: 3.831,
            ic_september: 3.93,
            ic_october: 4.67,
            ic_november: 5.622,
            ic_december: 5.839,
            ic_yearly: 4.288,
          },
          {
            ic_uuid: "18c875bc-ea7d-45d6-8f10-8d40dd3ef8f8",
            ic_lat: -26916,
            ic_lon: -490713,
            ic_city: "Blumenau",
            ic_states: "SANTA CATARINA",
            ic_january: 5.476,
            ic_february: 5.239,
            ic_march: 4.538,
            ic_april: 3.668,
            ic_may: 3.019,
            ic_june: 2.485,
            ic_july: 2.629,
            ic_august: 3.266,
            ic_september: 3.381,
            ic_october: 3.833,
            ic_november: 5.034,
            ic_december: 5.511,
            ic_yearly: 4.007,
          },
          {
            ic_uuid: "3f4bf006-ca32-4f44-884b-065482fe7eef",
            ic_lat: -185394,
            ic_lon: -402928,
            ic_city: "Boa Esperança",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.08,
            ic_february: 6.133,
            ic_march: 5.276,
            ic_april: 4.568,
            ic_may: 3.856,
            ic_june: 3.543,
            ic_july: 3.653,
            ic_august: 4.268,
            ic_september: 4.833,
            ic_october: 5.067,
            ic_november: 5.021,
            ic_december: 5.901,
            ic_yearly: 4.85,
          },
          {
            ic_uuid: "4707944b-623f-464f-8124-55e220fe2c34",
            ic_lat: -210932,
            ic_lon: -455616,
            ic_city: "Boa Esperança",
            ic_states: "MINAS GERAIS",
            ic_january: 5.53,
            ic_february: 5.84,
            ic_march: 4.932,
            ic_april: 4.685,
            ic_may: 4.064,
            ic_june: 3.835,
            ic_july: 4.114,
            ic_august: 5.012,
            ic_september: 5.142,
            ic_october: 5.452,
            ic_november: 5.251,
            ic_december: 5.609,
            ic_yearly: 4.955,
          },
          {
            ic_uuid: "aee9c5a8-dc45-4f0b-b7d0-b9788a1c9d63",
            ic_lat: -242472,
            ic_lon: -52788,
            ic_city: "Boa Esperança",
            ic_states: "PARANÁ",
            ic_january: 6.173,
            ic_february: 5.741,
            ic_march: 5.251,
            ic_april: 4.458,
            ic_may: 3.513,
            ic_june: 3.139,
            ic_july: 3.37,
            ic_august: 4.285,
            ic_september: 4.542,
            ic_october: 5.345,
            ic_november: 6.088,
            ic_december: 6.494,
            ic_yearly: 4.867,
          },
          {
            ic_uuid: "2a2f04f5-8d00-45d8-8bc2-f202715ed5df",
            ic_lat: -256329,
            ic_lon: -532113,
            ic_city: "Boa Esperança do Iguaçu",
            ic_states: "PARANÁ",
            ic_january: 6.365,
            ic_february: 5.803,
            ic_march: 5.29,
            ic_april: 4.263,
            ic_may: 3.265,
            ic_june: 2.844,
            ic_july: 3.087,
            ic_august: 4.066,
            ic_september: 4.239,
            ic_october: 5.263,
            ic_november: 6.154,
            ic_december: 6.48,
            ic_yearly: 4.76,
          },
          {
            ic_uuid: "0112958c-8976-4c86-9b4d-e12a88deb7f5",
            ic_lat: -219923,
            ic_lon: -48391,
            ic_city: "Boa Esperança do Sul",
            ic_states: "SÃO PAULO",
            ic_january: 5.662,
            ic_february: 5.824,
            ic_march: 5.139,
            ic_april: 4.709,
            ic_may: 3.939,
            ic_june: 3.682,
            ic_july: 3.892,
            ic_august: 4.831,
            ic_september: 4.896,
            ic_october: 5.575,
            ic_november: 5.787,
            ic_december: 6.118,
            ic_yearly: 5.005,
          },
          {
            ic_uuid: "d86f9726-4782-4fe5-a6df-407e39114db2",
            ic_lat: -44081,
            ic_lon: -421222,
            ic_city: "Boa Hora",
            ic_states: "PIAUÍ",
            ic_january: 5.272,
            ic_february: 5.335,
            ic_march: 5.293,
            ic_april: 5.035,
            ic_may: 5.123,
            ic_june: 5.295,
            ic_july: 5.556,
            ic_august: 6.113,
            ic_september: 6.473,
            ic_october: 6.372,
            ic_november: 6.281,
            ic_december: 5.746,
            ic_yearly: 5.658,
          },
          {
            ic_uuid: "b4ef8389-782d-44eb-9a46-3fe32b686dcf",
            ic_lat: -143642,
            ic_lon: -402061,
            ic_city: "Boa Nova",
            ic_states: "BAHIA",
            ic_january: 5.913,
            ic_february: 5.966,
            ic_march: 5.492,
            ic_april: 4.634,
            ic_may: 4.087,
            ic_june: 3.629,
            ic_july: 3.865,
            ic_august: 4.393,
            ic_september: 5.188,
            ic_october: 5.41,
            ic_november: 5.401,
            ic_december: 6.005,
            ic_yearly: 4.999,
          },
          {
            ic_uuid: "549114ab-946a-4772-a719-4a27df67a2b9",
            ic_lat: -74206,
            ic_lon: -382181,
            ic_city: "Boa Ventura",
            ic_states: "PARAÍBA",
            ic_january: 6.207,
            ic_february: 6.182,
            ic_march: 6.215,
            ic_april: 5.885,
            ic_may: 5.207,
            ic_june: 4.792,
            ic_july: 5.057,
            ic_august: 5.862,
            ic_september: 6.541,
            ic_october: 6.6,
            ic_november: 6.802,
            ic_december: 6.492,
            ic_yearly: 5.987,
          },
          {
            ic_uuid: "f9d070d3-8cae-4149-9365-1d6ae146dcf7",
            ic_lat: -248773,
            ic_lon: -51543,
            ic_city: "Boa Ventura de São Roque",
            ic_states: "PARANÁ",
            ic_january: 5.822,
            ic_february: 5.522,
            ic_march: 4.993,
            ic_april: 4.287,
            ic_may: 3.363,
            ic_june: 2.948,
            ic_july: 3.272,
            ic_august: 4.25,
            ic_september: 4.418,
            ic_october: 5.105,
            ic_november: 5.897,
            ic_december: 6.111,
            ic_yearly: 4.666,
          },
          {
            ic_uuid: "028822c5-ceeb-443c-85e7-06b4de81471c",
            ic_lat: -5113,
            ic_lon: -39734,
            ic_city: "Boa Viagem",
            ic_states: "CEARÁ",
            ic_january: 5.278,
            ic_february: 5.391,
            ic_march: 5.465,
            ic_april: 5.004,
            ic_may: 4.969,
            ic_june: 4.913,
            ic_july: 5.238,
            ic_august: 5.968,
            ic_september: 6.494,
            ic_october: 6.402,
            ic_november: 6.309,
            ic_december: 5.699,
            ic_yearly: 5.594,
          },
          {
            ic_uuid: "4f31d950-75c1-4070-a201-73538ab60bc1",
            ic_lat: -72598,
            ic_lon: -362369,
            ic_city: "Boa Vista",
            ic_states: "PARAÍBA",
            ic_january: 5.812,
            ic_february: 5.78,
            ic_march: 5.878,
            ic_april: 5.356,
            ic_may: 4.708,
            ic_june: 4.191,
            ic_july: 4.428,
            ic_august: 5.083,
            ic_september: 5.75,
            ic_october: 6.053,
            ic_november: 6.264,
            ic_december: 6.003,
            ic_yearly: 5.442,
          },
          {
            ic_uuid: "4227c863-e05c-401c-b518-ce20ca7aa7f7",
            ic_lat: 28231,
            ic_lon: -606762,
            ic_city: "Boa Vista",
            ic_states: "RORAIMA",
            ic_january: 4.597,
            ic_february: 4.712,
            ic_march: 4.946,
            ic_april: 4.79,
            ic_may: 4.426,
            ic_june: 4.599,
            ic_july: 4.69,
            ic_august: 5.147,
            ic_september: 5.527,
            ic_october: 5.431,
            ic_november: 5.114,
            ic_december: 4.662,
            ic_yearly: 4.886,
          },
          {
            ic_uuid: "d9a96d03-c384-464a-bfae-c6bf2cfe16e3",
            ic_lat: -254312,
            ic_lon: -534121,
            ic_city: "Boa Vista da Aparecida",
            ic_states: "PARANÁ",
            ic_january: 6.169,
            ic_february: 5.68,
            ic_march: 5.199,
            ic_april: 4.242,
            ic_may: 3.29,
            ic_june: 2.868,
            ic_july: 3.094,
            ic_august: 4.123,
            ic_september: 4.279,
            ic_october: 5.232,
            ic_november: 6.075,
            ic_december: 6.342,
            ic_yearly: 4.716,
          },
          {
            ic_uuid: "c981e913-8599-4e1c-a1b7-3340a9d6ccc2",
            ic_lat: -276676,
            ic_lon: -533106,
            ic_city: "Boa Vista das Missões",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.38,
            ic_february: 5.902,
            ic_march: 5.046,
            ic_april: 4.077,
            ic_may: 3.116,
            ic_june: 2.585,
            ic_july: 2.937,
            ic_august: 3.707,
            ic_september: 3.981,
            ic_october: 5.139,
            ic_november: 6.222,
            ic_december: 6.642,
            ic_yearly: 4.645,
          },
          {
            ic_uuid: "b602b6c8-0101-45fc-9279-8ba4acc6d7e4",
            ic_lat: -276697,
            ic_lon: -541087,
            ic_city: "Boa Vista do Buricá",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.532,
            ic_february: 5.998,
            ic_march: 5.163,
            ic_april: 4.096,
            ic_may: 3.125,
            ic_june: 2.557,
            ic_july: 2.864,
            ic_august: 3.7,
            ic_september: 4.0,
            ic_october: 5.227,
            ic_november: 6.268,
            ic_december: 6.692,
            ic_yearly: 4.685,
          },
          {
            ic_uuid: "d6906d83-1713-4079-b247-6896cbda727b",
            ic_lat: -285795,
            ic_lon: -538113,
            ic_city: "Boa Vista do Cadeado",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.456,
            ic_february: 6.046,
            ic_march: 5.125,
            ic_april: 4.03,
            ic_may: 3.054,
            ic_june: 2.492,
            ic_july: 2.788,
            ic_august: 3.564,
            ic_september: 3.953,
            ic_october: 5.071,
            ic_november: 6.3,
            ic_december: 6.719,
            ic_yearly: 4.633,
          },
          {
            ic_uuid: "8498e0ba-e261-408f-9d34-2b7abb652e6d",
            ic_lat: -17765,
            ic_lon: -463006,
            ic_city: "Boa Vista do Gurupi",
            ic_states: "MARANHÃO",
            ic_january: 4.417,
            ic_february: 4.347,
            ic_march: 4.317,
            ic_april: 4.395,
            ic_may: 4.521,
            ic_june: 4.769,
            ic_july: 4.957,
            ic_august: 5.354,
            ic_september: 5.589,
            ic_october: 5.302,
            ic_november: 5.117,
            ic_december: 4.743,
            ic_yearly: 4.819,
          },
          {
            ic_uuid: "49092641-1817-4371-945f-fef333021339",
            ic_lat: -288187,
            ic_lon: -533866,
            ic_city: "Boa Vista do Incra",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.344,
            ic_february: 5.963,
            ic_march: 5.076,
            ic_april: 3.946,
            ic_may: 3.011,
            ic_june: 2.438,
            ic_july: 2.768,
            ic_august: 3.524,
            ic_september: 3.889,
            ic_october: 5.0,
            ic_november: 6.233,
            ic_december: 6.664,
            ic_yearly: 4.571,
          },
          {
            ic_uuid: "f92a4e49-d4fd-4d93-94f9-f9fed83c3321",
            ic_lat: -29745,
            ic_lon: -575878,
            ic_city: "Boa Vista do Ramos",
            ic_states: "AMAZONAS",
            ic_january: 3.918,
            ic_february: 4.054,
            ic_march: 3.994,
            ic_april: 3.739,
            ic_may: 3.817,
            ic_june: 4.381,
            ic_july: 4.379,
            ic_august: 5.034,
            ic_september: 5.058,
            ic_october: 4.993,
            ic_november: 4.808,
            ic_december: 4.324,
            ic_yearly: 4.375,
          },
          {
            ic_uuid: "35c9ab30-fa1f-450d-9a1b-7332fa0e2392",
            ic_lat: -293567,
            ic_lon: -516702,
            ic_city: "Boa Vista do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.205,
            ic_february: 5.667,
            ic_march: 4.763,
            ic_april: 3.781,
            ic_may: 2.812,
            ic_june: 2.346,
            ic_july: 2.6,
            ic_august: 3.304,
            ic_september: 3.56,
            ic_october: 4.641,
            ic_november: 6.086,
            ic_december: 6.423,
            ic_yearly: 4.349,
          },
          {
            ic_uuid: "c1cf48c9-ee7a-42c0-a450-35739e0f5052",
            ic_lat: -126606,
            ic_lon: -406089,
            ic_city: "Boa Vista do Tupim",
            ic_states: "BAHIA",
            ic_january: 5.906,
            ic_february: 5.987,
            ic_march: 5.637,
            ic_april: 4.678,
            ic_may: 4.067,
            ic_june: 3.774,
            ic_july: 3.877,
            ic_august: 4.523,
            ic_september: 5.362,
            ic_october: 5.354,
            ic_november: 5.377,
            ic_december: 5.832,
            ic_yearly: 5.031,
          },
          {
            ic_uuid: "04ab7c36-d88f-4061-a442-cdda7c798aea",
            ic_lat: -96435,
            ic_lon: -362128,
            ic_city: "Boca da Mata",
            ic_states: "ALAGOAS",
            ic_january: 5.84,
            ic_february: 5.697,
            ic_march: 5.751,
            ic_april: 4.981,
            ic_may: 4.144,
            ic_june: 3.773,
            ic_july: 3.881,
            ic_august: 4.431,
            ic_september: 5.303,
            ic_october: 5.606,
            ic_november: 6.198,
            ic_december: 6.154,
            ic_yearly: 5.147,
          },
          {
            ic_uuid: "bf900a89-06f7-4d3a-a3cf-50ab22650794",
            ic_lat: -87427,
            ic_lon: -673924,
            ic_city: "Boca do Acre",
            ic_states: "AMAZONAS",
            ic_january: 4.371,
            ic_february: 4.527,
            ic_march: 4.246,
            ic_april: 4.331,
            ic_may: 3.92,
            ic_june: 4.144,
            ic_july: 4.374,
            ic_august: 4.931,
            ic_september: 5.086,
            ic_october: 4.968,
            ic_november: 4.761,
            ic_december: 4.548,
            ic_yearly: 4.517,
          },
          {
            ic_uuid: "e776c7a9-ad76-48bd-aa91-5508f25d9187",
            ic_lat: -69456,
            ic_lon: -413237,
            ic_city: "Bocaina",
            ic_states: "PIAUÍ",
            ic_january: 5.527,
            ic_february: 5.423,
            ic_march: 5.478,
            ic_april: 5.305,
            ic_may: 5.135,
            ic_june: 5.105,
            ic_july: 5.529,
            ic_august: 6.261,
            ic_september: 6.696,
            ic_october: 6.453,
            ic_november: 6.358,
            ic_december: 5.922,
            ic_yearly: 5.766,
          },
          {
            ic_uuid: "9c081f73-8735-446e-b4e5-4c3bd2a1fc3c",
            ic_lat: -22136,
            ic_lon: -485179,
            ic_city: "Bocaina",
            ic_states: "SÃO PAULO",
            ic_january: 5.629,
            ic_february: 5.887,
            ic_march: 5.196,
            ic_april: 4.682,
            ic_may: 3.891,
            ic_june: 3.673,
            ic_july: 3.847,
            ic_august: 4.736,
            ic_september: 4.88,
            ic_october: 5.586,
            ic_november: 5.848,
            ic_december: 6.166,
            ic_yearly: 5.002,
          },
          {
            ic_uuid: "79996109-84d9-466c-ad47-3831ebac69d1",
            ic_lat: -221702,
            ic_lon: -443976,
            ic_city: "Bocaina de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.121,
            ic_february: 5.57,
            ic_march: 4.634,
            ic_april: 4.191,
            ic_may: 3.648,
            ic_june: 3.482,
            ic_july: 3.541,
            ic_august: 4.487,
            ic_september: 4.586,
            ic_october: 4.75,
            ic_november: 4.601,
            ic_december: 5.321,
            ic_yearly: 4.494,
          },
          {
            ic_uuid: "27d9e18b-30b8-4adc-abbc-4791dcbb911e",
            ic_lat: -27746,
            ic_lon: -499427,
            ic_city: "Bocaina do Sul",
            ic_states: "SANTA CATARINA",
            ic_january: 5.659,
            ic_february: 5.338,
            ic_march: 4.588,
            ic_april: 3.682,
            ic_may: 2.876,
            ic_june: 2.439,
            ic_july: 2.658,
            ic_august: 3.425,
            ic_september: 3.59,
            ic_october: 4.198,
            ic_november: 5.516,
            ic_december: 5.822,
            ic_yearly: 4.149,
          },
          {
            ic_uuid: "32ffbe08-521b-49ea-85b7-fea6baf460d0",
            ic_lat: -17114,
            ic_lon: -438108,
            ic_city: "Bocaiúva",
            ic_states: "MINAS GERAIS",
            ic_january: 6.194,
            ic_february: 6.495,
            ic_march: 5.515,
            ic_april: 5.26,
            ic_may: 4.711,
            ic_june: 4.444,
            ic_july: 4.844,
            ic_august: 5.586,
            ic_september: 6.025,
            ic_october: 5.971,
            ic_november: 5.463,
            ic_december: 6.036,
            ic_yearly: 5.545,
          },
          {
            ic_uuid: "4b8a4a27-963f-4174-a025-127219a1cb0b",
            ic_lat: -252071,
            ic_lon: -491146,
            ic_city: "Bocaiúva do Sul",
            ic_states: "PARANÁ",
            ic_january: 5.313,
            ic_february: 5.195,
            ic_march: 4.573,
            ic_april: 3.826,
            ic_may: 3.073,
            ic_june: 2.754,
            ic_july: 2.94,
            ic_august: 3.903,
            ic_september: 3.854,
            ic_october: 4.398,
            ic_november: 5.239,
            ic_december: 5.608,
            ic_yearly: 4.223,
          },
          {
            ic_uuid: "f94d56e1-dc85-4141-ad39-7992c7ea90ec",
            ic_lat: -5987,
            ic_lon: -364128,
            ic_city: "Bodó",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.996,
            ic_february: 6.021,
            ic_march: 6.012,
            ic_april: 5.575,
            ic_may: 5.178,
            ic_june: 4.805,
            ic_july: 5.084,
            ic_august: 5.857,
            ic_september: 6.33,
            ic_october: 6.537,
            ic_november: 6.532,
            ic_december: 6.06,
            ic_yearly: 5.832,
          },
          {
            ic_uuid: "d61281a9-aa98-4bfb-8be8-edcb94ad13f4",
            ic_lat: -7778,
            ic_lon: -399341,
            ic_city: "Bodocó",
            ic_states: "PERNAMBUCO",
            ic_january: 5.931,
            ic_february: 5.724,
            ic_march: 5.817,
            ic_april: 5.269,
            ic_may: 4.742,
            ic_june: 4.382,
            ic_july: 4.717,
            ic_august: 5.608,
            ic_september: 6.304,
            ic_october: 6.46,
            ic_november: 6.536,
            ic_december: 6.276,
            ic_yearly: 5.647,
          },
          {
            ic_uuid: "ae723a3e-4542-44b2-a09c-d9bcaf9c8a2f",
            ic_lat: -205519,
            ic_lon: -566749,
            ic_city: "Bodoquena",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.785,
            ic_february: 5.576,
            ic_march: 5.321,
            ic_april: 4.767,
            ic_may: 3.765,
            ic_june: 3.559,
            ic_july: 3.702,
            ic_august: 4.582,
            ic_september: 4.798,
            ic_october: 5.449,
            ic_november: 5.878,
            ic_december: 6.159,
            ic_yearly: 4.945,
          },
          {
            ic_uuid: "ddac7f94-f4d1-417f-9e3b-cc861f7bc2f0",
            ic_lat: -23102,
            ic_lon: -48258,
            ic_city: "Bofete",
            ic_states: "SÃO PAULO",
            ic_january: 5.491,
            ic_february: 5.706,
            ic_march: 5.015,
            ic_april: 4.566,
            ic_may: 3.661,
            ic_june: 3.447,
            ic_july: 3.554,
            ic_august: 4.53,
            ic_september: 4.68,
            ic_october: 5.237,
            ic_november: 5.678,
            ic_december: 6.033,
            ic_yearly: 4.8,
          },
          {
            ic_uuid: "d0051e21-1690-450d-bcfe-8c6eb6732a22",
            ic_lat: -23286,
            ic_lon: -47679,
            ic_city: "Boituva",
            ic_states: "SÃO PAULO",
            ic_january: 5.589,
            ic_february: 5.814,
            ic_march: 5.101,
            ic_april: 4.534,
            ic_may: 3.689,
            ic_june: 3.422,
            ic_july: 3.542,
            ic_august: 4.526,
            ic_september: 4.701,
            ic_october: 5.28,
            ic_november: 5.721,
            ic_december: 6.124,
            ic_yearly: 4.837,
          },
          {
            ic_uuid: "29b95738-9a60-45bb-9846-7e8d374e4197",
            ic_lat: -91696,
            ic_lon: -36686,
            ic_city: "Bom Conselho",
            ic_states: "PERNAMBUCO",
            ic_january: 6.063,
            ic_february: 5.901,
            ic_march: 5.895,
            ic_april: 5.207,
            ic_may: 4.247,
            ic_june: 3.81,
            ic_july: 3.986,
            ic_august: 4.614,
            ic_september: 5.569,
            ic_october: 5.996,
            ic_november: 6.554,
            ic_december: 6.43,
            ic_yearly: 5.356,
          },
          {
            ic_uuid: "86713f9f-5928-485f-9c83-4beb48a16174",
            ic_lat: -197302,
            ic_lon: -452517,
            ic_city: "Bom Despacho",
            ic_states: "MINAS GERAIS",
            ic_january: 5.871,
            ic_february: 6.133,
            ic_march: 5.195,
            ic_april: 4.958,
            ic_may: 4.432,
            ic_june: 4.226,
            ic_july: 4.507,
            ic_august: 5.386,
            ic_september: 5.54,
            ic_october: 5.624,
            ic_november: 5.378,
            ic_december: 5.712,
            ic_yearly: 5.247,
          },
          {
            ic_uuid: "decb343c-0e33-491e-b518-8b222f506239",
            ic_lat: -35417,
            ic_lon: -456064,
            ic_city: "Bom Jardim",
            ic_states: "MARANHÃO",
            ic_january: 4.526,
            ic_february: 4.811,
            ic_march: 4.861,
            ic_april: 4.834,
            ic_may: 4.726,
            ic_june: 4.851,
            ic_july: 4.975,
            ic_august: 5.405,
            ic_september: 5.613,
            ic_october: 5.324,
            ic_november: 4.996,
            ic_december: 4.802,
            ic_yearly: 4.977,
          },
          {
            ic_uuid: "8c15d90f-7825-40a5-b526-8a6c9e1a3735",
            ic_lat: -7799,
            ic_lon: -35588,
            ic_city: "Bom Jardim",
            ic_states: "PERNAMBUCO",
            ic_january: 5.629,
            ic_february: 5.586,
            ic_march: 5.659,
            ic_april: 5.149,
            ic_may: 4.407,
            ic_june: 4.034,
            ic_july: 4.149,
            ic_august: 4.814,
            ic_september: 5.444,
            ic_october: 5.659,
            ic_november: 5.95,
            ic_december: 5.824,
            ic_yearly: 5.192,
          },
          {
            ic_uuid: "b5ac0475-7f0d-49dc-aada-0531ef65777c",
            ic_lat: -22155,
            ic_lon: -424255,
            ic_city: "Bom Jardim",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.712,
            ic_february: 5.952,
            ic_march: 4.846,
            ic_april: 4.232,
            ic_may: 3.517,
            ic_june: 3.289,
            ic_july: 3.466,
            ic_august: 4.165,
            ic_september: 4.467,
            ic_october: 4.762,
            ic_november: 4.68,
            ic_december: 5.462,
            ic_yearly: 4.546,
          },
          {
            ic_uuid: "0664b478-ac31-4754-8cfe-c1c296fca1d5",
            ic_lat: -283381,
            ic_lon: -496377,
            ic_city: "Bom Jardim da Serra",
            ic_states: "SANTA CATARINA",
            ic_january: 5.55,
            ic_february: 5.305,
            ic_march: 4.648,
            ic_april: 3.86,
            ic_may: 3.008,
            ic_june: 2.656,
            ic_july: 2.884,
            ic_august: 3.674,
            ic_september: 3.876,
            ic_october: 4.75,
            ic_november: 5.884,
            ic_december: 6.146,
            ic_yearly: 4.354,
          },
          {
            ic_uuid: "4f6a5016-acfb-4b54-8be1-cad78f8b875d",
            ic_lat: -162001,
            ic_lon: -521759,
            ic_city: "Bom Jardim de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.517,
            ic_february: 5.652,
            ic_march: 5.213,
            ic_april: 5.271,
            ic_may: 4.864,
            ic_june: 4.519,
            ic_july: 4.639,
            ic_august: 5.4,
            ic_september: 5.36,
            ic_october: 5.489,
            ic_november: 5.483,
            ic_december: 5.604,
            ic_yearly: 5.251,
          },
          {
            ic_uuid: "7d37845d-67bb-420f-b08e-cd5402fddcf4",
            ic_lat: -219484,
            ic_lon: -441889,
            ic_city: "Bom Jardim de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.405,
            ic_february: 5.711,
            ic_march: 4.837,
            ic_april: 4.314,
            ic_may: 3.716,
            ic_june: 3.535,
            ic_july: 3.674,
            ic_august: 4.592,
            ic_september: 4.795,
            ic_october: 4.989,
            ic_november: 4.94,
            ic_december: 5.398,
            ic_yearly: 4.659,
          },
          {
            ic_uuid: "95eef91e-ddab-424d-88cc-e4a56688cf65",
            ic_lat: -68164,
            ic_lon: -386457,
            ic_city: "Bom Jesus",
            ic_states: "PARAÍBA",
            ic_january: 6.084,
            ic_february: 6.083,
            ic_march: 6.054,
            ic_april: 5.766,
            ic_may: 5.289,
            ic_june: 5.069,
            ic_july: 5.352,
            ic_august: 6.064,
            ic_september: 6.492,
            ic_october: 6.58,
            ic_november: 6.745,
            ic_december: 6.366,
            ic_yearly: 5.995,
          },
          {
            ic_uuid: "3e654dd7-4e01-4323-bd14-fb58caef3ae4",
            ic_lat: -90717,
            ic_lon: -443593,
            ic_city: "Bom Jesus",
            ic_states: "PIAUÍ",
            ic_january: 5.691,
            ic_february: 5.5,
            ic_march: 5.481,
            ic_april: 5.431,
            ic_may: 5.365,
            ic_june: 5.265,
            ic_july: 5.566,
            ic_august: 6.185,
            ic_september: 6.509,
            ic_october: 6.141,
            ic_november: 5.762,
            ic_december: 5.69,
            ic_yearly: 5.715,
          },
          {
            ic_uuid: "81570965-f856-4611-8873-0042a557be81",
            ic_lat: -59869,
            ic_lon: -355795,
            ic_city: "Bom Jesus",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.917,
            ic_february: 5.919,
            ic_march: 5.98,
            ic_april: 5.516,
            ic_may: 4.974,
            ic_june: 4.522,
            ic_july: 4.691,
            ic_august: 5.394,
            ic_september: 5.812,
            ic_october: 6.045,
            ic_november: 6.237,
            ic_december: 5.941,
            ic_yearly: 5.579,
          },
          {
            ic_uuid: "bdc4da27-e0f8-45c3-b602-c4855c7d4f6f",
            ic_lat: -286702,
            ic_lon: -504299,
            ic_city: "Bom Jesus",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.03,
            ic_february: 5.617,
            ic_march: 4.78,
            ic_april: 3.895,
            ic_may: 2.922,
            ic_june: 2.475,
            ic_july: 2.805,
            ic_august: 3.547,
            ic_september: 3.79,
            ic_october: 4.885,
            ic_november: 6.08,
            ic_december: 6.354,
            ic_yearly: 4.432,
          },
          {
            ic_uuid: "03cea63e-91f8-4985-bd32-0038703b81c4",
            ic_lat: -267331,
            ic_lon: -523923,
            ic_city: "Bom Jesus",
            ic_states: "SANTA CATARINA",
            ic_january: 6.195,
            ic_february: 5.549,
            ic_march: 5.016,
            ic_april: 4.119,
            ic_may: 3.205,
            ic_june: 2.754,
            ic_july: 3.019,
            ic_august: 3.904,
            ic_september: 4.121,
            ic_october: 5.096,
            ic_november: 6.088,
            ic_december: 6.283,
            ic_yearly: 4.612,
          },
          {
            ic_uuid: "82891133-53aa-4660-98b1-38cbd92af31b",
            ic_lat: -13251,
            ic_lon: -434111,
            ic_city: "Bom Jesus da Lapa",
            ic_states: "BAHIA",
            ic_january: 6.618,
            ic_february: 6.657,
            ic_march: 6.06,
            ic_april: 5.722,
            ic_may: 5.132,
            ic_june: 4.91,
            ic_july: 5.161,
            ic_august: 5.651,
            ic_september: 6.072,
            ic_october: 6.425,
            ic_november: 5.957,
            ic_december: 6.488,
            ic_yearly: 5.904,
          },
          {
            ic_uuid: "a9b8fe35-76a5-4cb2-9a0c-57fd24b59482",
            ic_lat: -210153,
            ic_lon: -465178,
            ic_city: "Bom Jesus da Penha",
            ic_states: "MINAS GERAIS",
            ic_january: 5.607,
            ic_february: 5.77,
            ic_march: 4.933,
            ic_april: 4.687,
            ic_may: 4.09,
            ic_june: 3.897,
            ic_july: 4.142,
            ic_august: 5.09,
            ic_september: 5.174,
            ic_october: 5.495,
            ic_november: 5.333,
            ic_december: 5.611,
            ic_yearly: 4.986,
          },
          {
            ic_uuid: "2b433825-c204-4a8c-887e-5318fcea41ab",
            ic_lat: -14374,
            ic_lon: -405049,
            ic_city: "Bom Jesus da Serra",
            ic_states: "BAHIA",
            ic_january: 5.931,
            ic_february: 5.933,
            ic_march: 5.497,
            ic_april: 4.567,
            ic_may: 4.069,
            ic_june: 3.682,
            ic_july: 3.823,
            ic_august: 4.436,
            ic_september: 5.327,
            ic_october: 5.48,
            ic_november: 5.448,
            ic_december: 6.012,
            ic_yearly: 5.017,
          },
          {
            ic_uuid: "45e4da83-894e-480c-b560-911ac3a0ddf3",
            ic_lat: -44836,
            ic_lon: -468536,
            ic_city: "Bom Jesus das Selvas",
            ic_states: "MARANHÃO",
            ic_january: 4.579,
            ic_february: 4.763,
            ic_march: 4.849,
            ic_april: 4.781,
            ic_may: 4.736,
            ic_june: 4.871,
            ic_july: 5.061,
            ic_august: 5.35,
            ic_september: 5.493,
            ic_october: 5.157,
            ic_november: 4.774,
            ic_december: 4.72,
            ic_yearly: 4.928,
          },
          {
            ic_uuid: "6b91a5bb-2ca9-4e05-b164-6f3b7a12de0e",
            ic_lat: -182177,
            ic_lon: -497404,
            ic_city: "Bom Jesus de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.67,
            ic_february: 5.642,
            ic_march: 5.115,
            ic_april: 5.019,
            ic_may: 4.573,
            ic_june: 4.321,
            ic_july: 4.584,
            ic_august: 5.476,
            ic_september: 5.296,
            ic_october: 5.504,
            ic_november: 5.556,
            ic_december: 5.733,
            ic_yearly: 5.207,
          },
          {
            ic_uuid: "c4decf5a-913f-4d4f-8250-ce62073c965e",
            ic_lat: -197067,
            ic_lon: -434743,
            ic_city: "Bom Jesus do Amparo",
            ic_states: "MINAS GERAIS",
            ic_january: 5.591,
            ic_february: 5.908,
            ic_march: 4.96,
            ic_april: 4.557,
            ic_may: 3.919,
            ic_june: 3.792,
            ic_july: 4.072,
            ic_august: 4.858,
            ic_september: 5.137,
            ic_october: 5.222,
            ic_november: 4.896,
            ic_december: 5.437,
            ic_yearly: 4.862,
          },
          {
            ic_uuid: "51eb9959-91d4-4adc-9280-18f03944efce",
            ic_lat: -12171,
            ic_lon: -515036,
            ic_city: "Bom Jesus do Araguaia",
            ic_states: "MATO GROSSO",
            ic_january: 5.138,
            ic_february: 5.217,
            ic_march: 4.963,
            ic_april: 4.937,
            ic_may: 4.985,
            ic_june: 4.858,
            ic_july: 5.164,
            ic_august: 5.612,
            ic_september: 5.345,
            ic_october: 5.29,
            ic_november: 5.214,
            ic_december: 5.237,
            ic_yearly: 5.163,
          },
          {
            ic_uuid: "ce46cc8b-ca07-4e13-904c-c03230b176fd",
            ic_lat: -198363,
            ic_lon: -42318,
            ic_city: "Bom Jesus do Galho",
            ic_states: "MINAS GERAIS",
            ic_january: 5.789,
            ic_february: 6.014,
            ic_march: 5.025,
            ic_april: 4.609,
            ic_may: 3.94,
            ic_june: 3.726,
            ic_july: 3.866,
            ic_august: 4.545,
            ic_september: 4.905,
            ic_october: 5.077,
            ic_november: 4.93,
            ic_december: 5.735,
            ic_yearly: 4.847,
          },
          {
            ic_uuid: "e9ad9d91-02ad-4775-984f-f6f2d0681f97",
            ic_lat: -211453,
            ic_lon: -416826,
            ic_city: "Bom Jesus do Itabapoana",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.112,
            ic_february: 6.253,
            ic_march: 5.127,
            ic_april: 4.49,
            ic_may: 3.861,
            ic_june: 3.622,
            ic_july: 3.719,
            ic_august: 4.488,
            ic_september: 4.899,
            ic_october: 5.097,
            ic_november: 4.972,
            ic_december: 5.8,
            ic_yearly: 4.87,
          },
          {
            ic_uuid: "5eb61064-31ff-47b7-a1b7-1df2c6431f0a",
            ic_lat: -211246,
            ic_lon: -416731,
            ic_city: "Bom Jesus do Norte",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.112,
            ic_february: 6.253,
            ic_march: 5.127,
            ic_april: 4.49,
            ic_may: 3.861,
            ic_june: 3.622,
            ic_july: 3.719,
            ic_august: 4.488,
            ic_september: 4.899,
            ic_october: 5.097,
            ic_november: 4.972,
            ic_december: 5.8,
            ic_yearly: 4.87,
          },
          {
            ic_uuid: "5dcf6bd8-e793-4778-b72b-25e1513b6201",
            ic_lat: -266932,
            ic_lon: -530972,
            ic_city: "Bom Jesus do Oeste",
            ic_states: "SANTA CATARINA",
            ic_january: 6.138,
            ic_february: 5.65,
            ic_march: 5.073,
            ic_april: 4.094,
            ic_may: 3.174,
            ic_june: 2.684,
            ic_july: 2.998,
            ic_august: 3.853,
            ic_september: 4.115,
            ic_october: 5.096,
            ic_november: 6.039,
            ic_december: 6.347,
            ic_yearly: 4.605,
          },
          {
            ic_uuid: "80738d9d-9cac-42fb-80a7-6677388a608b",
            ic_lat: -261963,
            ic_lon: -535959,
            ic_city: "Bom Jesus do Sul",
            ic_states: "PARANÁ",
            ic_january: 6.449,
            ic_february: 5.768,
            ic_march: 5.22,
            ic_april: 4.206,
            ic_may: 3.244,
            ic_june: 2.784,
            ic_july: 3.087,
            ic_august: 4.036,
            ic_september: 4.246,
            ic_october: 5.244,
            ic_november: 6.122,
            ic_december: 6.426,
            ic_yearly: 4.736,
          },
          {
            ic_uuid: "6818820b-ae8a-457f-a50a-53484d68e46d",
            ic_lat: -50428,
            ic_lon: -486051,
            ic_city: "Bom Jesus do Tocantins",
            ic_states: "PARÁ",
            ic_january: 4.415,
            ic_february: 4.511,
            ic_march: 4.605,
            ic_april: 4.605,
            ic_may: 4.679,
            ic_june: 5.02,
            ic_july: 5.112,
            ic_august: 5.501,
            ic_september: 5.317,
            ic_october: 4.84,
            ic_november: 4.79,
            ic_december: 4.487,
            ic_yearly: 4.824,
          },
          {
            ic_uuid: "a1f33b1a-639e-4d84-ae8b-a028efa19b63",
            ic_lat: -89635,
            ic_lon: -481654,
            ic_city: "Bom Jesus do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 5.088,
            ic_february: 5.15,
            ic_march: 4.954,
            ic_april: 4.887,
            ic_may: 5.1,
            ic_june: 5.151,
            ic_july: 5.269,
            ic_august: 5.978,
            ic_september: 5.8,
            ic_october: 5.316,
            ic_november: 5.099,
            ic_december: 5.028,
            ic_yearly: 5.235,
          },
          {
            ic_uuid: "e80fa0e9-0ce9-42c5-86ad-db6547a04109",
            ic_lat: -231361,
            ic_lon: -464679,
            ic_city: "Bom Jesus dos Perdões",
            ic_states: "SÃO PAULO",
            ic_january: 5.396,
            ic_february: 5.726,
            ic_march: 4.938,
            ic_april: 4.493,
            ic_may: 3.675,
            ic_june: 3.416,
            ic_july: 3.529,
            ic_august: 4.533,
            ic_september: 4.604,
            ic_october: 5.221,
            ic_november: 5.467,
            ic_december: 6.019,
            ic_yearly: 4.751,
          },
          {
            ic_uuid: "7f486c54-391c-4380-a951-528f22615984",
            ic_lat: -43734,
            ic_lon: -450399,
            ic_city: "Bom Lugar",
            ic_states: "MARANHÃO",
            ic_january: 4.624,
            ic_february: 4.848,
            ic_march: 4.856,
            ic_april: 4.868,
            ic_may: 4.793,
            ic_june: 4.94,
            ic_july: 5.185,
            ic_august: 5.676,
            ic_september: 5.95,
            ic_october: 5.498,
            ic_november: 5.092,
            ic_december: 4.934,
            ic_yearly: 5.105,
          },
          {
            ic_uuid: "e4ffbf3d-bc76-4624-ab23-eee74b6b093e",
            ic_lat: -294861,
            ic_lon: -513552,
            ic_city: "Bom Princípio",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.094,
            ic_february: 5.642,
            ic_march: 4.743,
            ic_april: 3.774,
            ic_may: 2.812,
            ic_june: 2.337,
            ic_july: 2.554,
            ic_august: 3.238,
            ic_september: 3.524,
            ic_october: 4.564,
            ic_november: 6.044,
            ic_december: 6.417,
            ic_yearly: 4.312,
          },
          {
            ic_uuid: "22c3a267-93f6-4a71-8432-515e63b7b098",
            ic_lat: -31912,
            ic_lon: -416444,
            ic_city: "Bom Princípio do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.284,
            ic_february: 5.241,
            ic_march: 5.157,
            ic_april: 4.913,
            ic_may: 5.15,
            ic_june: 5.31,
            ic_july: 5.446,
            ic_august: 6.039,
            ic_september: 6.495,
            ic_october: 6.601,
            ic_november: 6.418,
            ic_december: 5.703,
            ic_yearly: 5.646,
          },
          {
            ic_uuid: "b245f98c-ef5c-4255-ac65-772c4f049c77",
            ic_lat: -275403,
            ic_lon: -538721,
            ic_city: "Bom Progresso",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.432,
            ic_february: 5.929,
            ic_march: 5.151,
            ic_april: 4.049,
            ic_may: 3.111,
            ic_june: 2.56,
            ic_july: 2.912,
            ic_august: 3.708,
            ic_september: 4.009,
            ic_october: 5.213,
            ic_november: 6.242,
            ic_december: 6.632,
            ic_yearly: 4.662,
          },
          {
            ic_uuid: "e700494a-d447-445f-9078-231fab5b5537",
            ic_lat: -22468,
            ic_lon: -461444,
            ic_city: "Bom Repouso",
            ic_states: "MINAS GERAIS",
            ic_january: 5.137,
            ic_february: 5.456,
            ic_march: 4.696,
            ic_april: 4.425,
            ic_may: 3.782,
            ic_june: 3.528,
            ic_july: 3.779,
            ic_august: 4.731,
            ic_september: 4.865,
            ic_october: 5.248,
            ic_november: 5.223,
            ic_december: 5.546,
            ic_yearly: 4.701,
          },
          {
            ic_uuid: "325475bd-c545-411c-abff-5add5542d1b5",
            ic_lat: -277995,
            ic_lon: -494874,
            ic_city: "Bom Retiro",
            ic_states: "SANTA CATARINA",
            ic_january: 5.688,
            ic_february: 5.31,
            ic_march: 4.595,
            ic_april: 3.746,
            ic_may: 2.919,
            ic_june: 2.482,
            ic_july: 2.694,
            ic_august: 3.475,
            ic_september: 3.61,
            ic_october: 4.211,
            ic_november: 5.525,
            ic_december: 5.839,
            ic_yearly: 4.175,
          },
          {
            ic_uuid: "e4b20fdb-8ed3-4787-a5f8-ed9fde8d6d1f",
            ic_lat: -295972,
            ic_lon: -519375,
            ic_city: "Bom Retiro do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.245,
            ic_february: 5.817,
            ic_march: 4.845,
            ic_april: 3.797,
            ic_may: 2.801,
            ic_june: 2.325,
            ic_july: 2.548,
            ic_august: 3.275,
            ic_september: 3.615,
            ic_october: 4.669,
            ic_november: 6.156,
            ic_december: 6.558,
            ic_yearly: 4.387,
          },
          {
            ic_uuid: "9f66b717-5d79-4d72-83e5-52ad2a170c58",
            ic_lat: -210334,
            ic_lon: -447541,
            ic_city: "Bom Sucesso",
            ic_states: "MINAS GERAIS",
            ic_january: 5.452,
            ic_february: 5.739,
            ic_march: 4.87,
            ic_april: 4.613,
            ic_may: 3.963,
            ic_june: 3.807,
            ic_july: 4.042,
            ic_august: 4.963,
            ic_september: 5.172,
            ic_october: 5.324,
            ic_november: 5.118,
            ic_december: 5.542,
            ic_yearly: 4.884,
          },
          {
            ic_uuid: "ea869d5a-fcd5-4d36-8cf7-7749cb0ee454",
            ic_lat: -64422,
            ic_lon: -379237,
            ic_city: "Bom Sucesso",
            ic_states: "PARAÍBA",
            ic_january: 6.144,
            ic_february: 6.105,
            ic_march: 6.139,
            ic_april: 5.828,
            ic_may: 5.325,
            ic_june: 5.04,
            ic_july: 5.326,
            ic_august: 6.042,
            ic_september: 6.481,
            ic_october: 6.623,
            ic_november: 6.726,
            ic_december: 6.288,
            ic_yearly: 6.006,
          },
          {
            ic_uuid: "067f78ea-6f29-46dd-8877-5d0498d292fc",
            ic_lat: -237068,
            ic_lon: -517675,
            ic_city: "Bom Sucesso",
            ic_states: "PARANÁ",
            ic_january: 5.857,
            ic_february: 5.753,
            ic_march: 5.242,
            ic_april: 4.542,
            ic_may: 3.565,
            ic_june: 3.258,
            ic_july: 3.466,
            ic_august: 4.439,
            ic_september: 4.633,
            ic_october: 5.249,
            ic_november: 5.98,
            ic_december: 6.26,
            ic_yearly: 4.854,
          },
          {
            ic_uuid: "f33a577e-0bf2-4ebf-96c8-b1e6b2add57a",
            ic_lat: -24318,
            ic_lon: -491439,
            ic_city: "Bom Sucesso de Itararé",
            ic_states: "SÃO PAULO",
            ic_january: 5.309,
            ic_february: 5.357,
            ic_march: 4.692,
            ic_april: 4.102,
            ic_may: 3.316,
            ic_june: 3.018,
            ic_july: 3.197,
            ic_august: 4.206,
            ic_september: 4.283,
            ic_october: 4.732,
            ic_november: 5.373,
            ic_december: 5.819,
            ic_yearly: 4.45,
          },
          {
            ic_uuid: "1e589482-d166-4104-ade4-54dc2f718f25",
            ic_lat: -260736,
            ic_lon: -528357,
            ic_city: "Bom Sucesso do Sul",
            ic_states: "PARANÁ",
            ic_january: 6.218,
            ic_february: 5.696,
            ic_march: 5.22,
            ic_april: 4.241,
            ic_may: 3.283,
            ic_june: 2.812,
            ic_july: 3.099,
            ic_august: 4.083,
            ic_september: 4.245,
            ic_october: 5.21,
            ic_november: 6.162,
            ic_december: 6.41,
            ic_yearly: 4.723,
          },
          {
            ic_uuid: "958a1eb7-25f7-4f3d-8f83-c8ad353b4f02",
            ic_lat: -271387,
            ic_lon: -48515,
            ic_city: "Bombinhas",
            ic_states: "SANTA CATARINA",
            ic_january: 5.855,
            ic_february: 5.52,
            ic_march: 4.762,
            ic_april: 3.79,
            ic_may: 3.058,
            ic_june: 2.552,
            ic_july: 2.681,
            ic_august: 3.326,
            ic_september: 3.551,
            ic_october: 4.334,
            ic_november: 5.525,
            ic_december: 5.914,
            ic_yearly: 4.239,
          },
          {
            ic_uuid: "cdd36bdb-a70e-492f-b3fe-76609abec2ee",
            ic_lat: -203307,
            ic_lon: -442369,
            ic_city: "Bonfim",
            ic_states: "MINAS GERAIS",
            ic_january: 5.768,
            ic_february: 5.93,
            ic_march: 5.062,
            ic_april: 4.756,
            ic_may: 4.192,
            ic_june: 4.005,
            ic_july: 4.23,
            ic_august: 5.135,
            ic_september: 5.375,
            ic_october: 5.493,
            ic_november: 5.239,
            ic_december: 5.588,
            ic_yearly: 5.064,
          },
          {
            ic_uuid: "ecffb148-db18-4e83-bb97-ab447d15edc5",
            ic_lat: 33613,
            ic_lon: -598337,
            ic_city: "Bonfim",
            ic_states: "RORAIMA",
            ic_january: 4.755,
            ic_february: 4.844,
            ic_march: 5.136,
            ic_april: 5.029,
            ic_may: 4.645,
            ic_june: 4.855,
            ic_july: 4.897,
            ic_august: 5.324,
            ic_september: 5.746,
            ic_october: 5.655,
            ic_november: 5.315,
            ic_december: 4.813,
            ic_yearly: 5.085,
          },
          {
            ic_uuid: "c4f5585b-60f9-47aa-9afe-6b6fbb1771cc",
            ic_lat: -91602,
            ic_lon: -428836,
            ic_city: "Bonfim do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.965,
            ic_february: 5.73,
            ic_march: 5.644,
            ic_april: 5.451,
            ic_may: 5.269,
            ic_june: 5.235,
            ic_july: 5.63,
            ic_august: 6.384,
            ic_september: 6.602,
            ic_october: 6.497,
            ic_november: 6.236,
            ic_december: 6.094,
            ic_yearly: 5.895,
          },
          {
            ic_uuid: "599422fd-894b-4890-866a-e38970f4ef35",
            ic_lat: -166177,
            ic_lon: -48962,
            ic_city: "Bonfinópolis",
            ic_states: "GOIÁS",
            ic_january: 5.456,
            ic_february: 5.623,
            ic_march: 5.163,
            ic_april: 5.065,
            ic_may: 4.843,
            ic_june: 4.577,
            ic_july: 4.839,
            ic_august: 5.679,
            ic_september: 5.513,
            ic_october: 5.578,
            ic_november: 5.226,
            ic_december: 5.375,
            ic_yearly: 5.245,
          },
          {
            ic_uuid: "e41bc381-e8d6-4692-af6d-8c4d2495eb72",
            ic_lat: -16566,
            ic_lon: -459881,
            ic_city: "Bonfinópolis de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 6.149,
            ic_february: 6.402,
            ic_march: 5.476,
            ic_april: 5.407,
            ic_may: 4.895,
            ic_june: 4.693,
            ic_july: 5.043,
            ic_august: 5.9,
            ic_september: 5.841,
            ic_october: 5.997,
            ic_november: 5.415,
            ic_december: 5.904,
            ic_yearly: 5.594,
          },
          {
            ic_uuid: "239413f6-1f05-4af0-a0c4-60f6c0e5ac57",
            ic_lat: -127073,
            ic_lon: -418289,
            ic_city: "Boninal",
            ic_states: "BAHIA",
            ic_january: 6.115,
            ic_february: 6.086,
            ic_march: 5.758,
            ic_april: 4.958,
            ic_may: 4.499,
            ic_june: 4.255,
            ic_july: 4.485,
            ic_august: 5.222,
            ic_september: 5.986,
            ic_october: 6.168,
            ic_november: 5.827,
            ic_december: 6.27,
            ic_yearly: 5.469,
          },
          {
            ic_uuid: "13b2226d-313f-4a9f-939c-797f52e252f3",
            ic_lat: -119672,
            ic_lon: -41265,
            ic_city: "Bonito",
            ic_states: "BAHIA",
            ic_january: 5.871,
            ic_february: 5.779,
            ic_march: 5.532,
            ic_april: 4.543,
            ic_may: 3.981,
            ic_june: 3.705,
            ic_july: 3.876,
            ic_august: 4.604,
            ic_september: 5.492,
            ic_october: 5.64,
            ic_november: 5.605,
            ic_december: 5.898,
            ic_yearly: 5.044,
          },
          {
            ic_uuid: "e19d94d0-d92a-465a-aa0d-25001f248931",
            ic_lat: -211265,
            ic_lon: -56484,
            ic_city: "Bonito",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.819,
            ic_february: 5.63,
            ic_march: 5.305,
            ic_april: 4.694,
            ic_may: 3.737,
            ic_june: 3.465,
            ic_july: 3.633,
            ic_august: 4.537,
            ic_september: 4.766,
            ic_october: 5.4,
            ic_november: 5.956,
            ic_december: 6.16,
            ic_yearly: 4.925,
          },
          {
            ic_uuid: "e829fbe7-e8b7-4b53-9607-f1d7f369fc49",
            ic_lat: -13678,
            ic_lon: -473069,
            ic_city: "Bonito",
            ic_states: "PARÁ",
            ic_january: 4.387,
            ic_february: 4.243,
            ic_march: 4.267,
            ic_april: 4.34,
            ic_may: 4.562,
            ic_june: 4.836,
            ic_july: 5.002,
            ic_august: 5.304,
            ic_september: 5.465,
            ic_october: 5.239,
            ic_november: 5.021,
            ic_december: 4.634,
            ic_yearly: 4.775,
          },
          {
            ic_uuid: "00e36720-260b-457a-b7ee-9e3aaa44acb1",
            ic_lat: -84721,
            ic_lon: -357295,
            ic_city: "Bonito",
            ic_states: "PERNAMBUCO",
            ic_january: 5.796,
            ic_february: 5.738,
            ic_march: 5.791,
            ic_april: 5.083,
            ic_may: 4.288,
            ic_june: 3.815,
            ic_july: 3.9,
            ic_august: 4.576,
            ic_september: 5.289,
            ic_october: 5.659,
            ic_november: 6.145,
            ic_december: 6.003,
            ic_yearly: 5.174,
          },
          {
            ic_uuid: "5ed78103-655c-4171-bb14-0fb82f06550e",
            ic_lat: -153214,
            ic_lon: -447572,
            ic_city: "Bonito de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 6.337,
            ic_february: 6.511,
            ic_march: 5.707,
            ic_april: 5.629,
            ic_may: 5.054,
            ic_june: 4.862,
            ic_july: 5.241,
            ic_august: 5.941,
            ic_september: 6.135,
            ic_october: 6.073,
            ic_november: 5.447,
            ic_december: 5.988,
            ic_yearly: 5.744,
          },
          {
            ic_uuid: "e9d9f140-a502-4400-9c5f-af1724f76bc3",
            ic_lat: -73138,
            ic_lon: -385136,
            ic_city: "Bonito de Santa Fé",
            ic_states: "PARAÍBA",
            ic_january: 5.99,
            ic_february: 6.032,
            ic_march: 5.998,
            ic_april: 5.637,
            ic_may: 5.088,
            ic_june: 4.895,
            ic_july: 5.182,
            ic_august: 5.941,
            ic_september: 6.625,
            ic_october: 6.67,
            ic_november: 6.799,
            ic_december: 6.402,
            ic_yearly: 5.938,
          },
          {
            ic_uuid: "a670c347-34e8-48b1-b2c5-94f57dc144ef",
            ic_lat: -136509,
            ic_lon: -498342,
            ic_city: "Bonópolis",
            ic_states: "GOIÁS",
            ic_january: 5.37,
            ic_february: 5.579,
            ic_march: 5.228,
            ic_april: 5.216,
            ic_may: 4.966,
            ic_june: 4.768,
            ic_july: 5.118,
            ic_august: 5.716,
            ic_september: 5.709,
            ic_october: 5.555,
            ic_november: 5.211,
            ic_december: 5.4,
            ic_yearly: 5.32,
          },
          {
            ic_uuid: "a5d33f09-81a6-4086-acb1-7f88f1f59006",
            ic_lat: -74826,
            ic_lon: -361323,
            ic_city: "Boqueirão",
            ic_states: "PARAÍBA",
            ic_january: 5.877,
            ic_february: 5.884,
            ic_march: 5.869,
            ic_april: 5.388,
            ic_may: 4.733,
            ic_june: 4.183,
            ic_july: 4.374,
            ic_august: 5.019,
            ic_september: 5.702,
            ic_october: 5.957,
            ic_november: 6.231,
            ic_december: 6.062,
            ic_yearly: 5.44,
          },
          {
            ic_uuid: "c62ea7ff-5e44-47a9-98bf-a47c0f30aaca",
            ic_lat: -293051,
            ic_lon: -524289,
            ic_city: "Boqueirão do Leão",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.117,
            ic_february: 5.692,
            ic_march: 4.843,
            ic_april: 3.844,
            ic_may: 2.854,
            ic_june: 2.398,
            ic_july: 2.651,
            ic_august: 3.397,
            ic_september: 3.644,
            ic_october: 4.75,
            ic_november: 6.116,
            ic_december: 6.488,
            ic_yearly: 4.4,
          },
          {
            ic_uuid: "0c9270ca-d2fe-4060-b68b-a054edd511ba",
            ic_lat: -44886,
            ic_lon: -421048,
            ic_city: "Boqueirão do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.226,
            ic_february: 5.309,
            ic_march: 5.308,
            ic_april: 5.047,
            ic_may: 5.153,
            ic_june: 5.319,
            ic_july: 5.551,
            ic_august: 6.136,
            ic_september: 6.491,
            ic_october: 6.411,
            ic_november: 6.27,
            ic_december: 5.718,
            ic_yearly: 5.662,
          },
          {
            ic_uuid: "8f468637-1e10-4449-84b2-0a5233fb2baf",
            ic_lat: -111463,
            ic_lon: -376206,
            ic_city: "Boquim",
            ic_states: "SERGIPE",
            ic_january: 6.101,
            ic_february: 6.004,
            ic_march: 5.854,
            ic_april: 4.954,
            ic_may: 4.198,
            ic_june: 3.96,
            ic_july: 4.059,
            ic_august: 4.568,
            ic_september: 5.315,
            ic_october: 5.638,
            ic_november: 6.037,
            ic_december: 6.141,
            ic_yearly: 5.236,
          },
          {
            ic_uuid: "9f93075f-ac20-4fdb-9149-ff3a8ca86e3b",
            ic_lat: -12821,
            ic_lon: -427327,
            ic_city: "Boquira",
            ic_states: "BAHIA",
            ic_january: 6.656,
            ic_february: 6.526,
            ic_march: 6.059,
            ic_april: 5.506,
            ic_may: 5.015,
            ic_june: 4.908,
            ic_july: 5.106,
            ic_august: 5.787,
            ic_september: 6.211,
            ic_october: 6.436,
            ic_november: 6.121,
            ic_december: 6.559,
            ic_yearly: 5.908,
          },
          {
            ic_uuid: "77d4168c-efe5-44a9-8de4-515fa1417837",
            ic_lat: -222695,
            ic_lon: -505446,
            ic_city: "Borá",
            ic_states: "SÃO PAULO",
            ic_january: 5.823,
            ic_february: 5.84,
            ic_march: 5.308,
            ic_april: 4.742,
            ic_may: 3.872,
            ic_june: 3.561,
            ic_july: 3.791,
            ic_august: 4.759,
            ic_september: 4.814,
            ic_october: 5.468,
            ic_november: 5.965,
            ic_december: 6.291,
            ic_yearly: 5.019,
          },
          {
            ic_uuid: "3602dccb-35de-44ed-98c3-7f3548d11bf1",
            ic_lat: -221931,
            ic_lon: -487812,
            ic_city: "Boracéia",
            ic_states: "SÃO PAULO",
            ic_january: 5.772,
            ic_february: 5.967,
            ic_march: 5.278,
            ic_april: 4.715,
            ic_may: 3.877,
            ic_june: 3.597,
            ic_july: 3.773,
            ic_august: 4.635,
            ic_september: 4.816,
            ic_october: 5.57,
            ic_november: 5.939,
            ic_december: 6.314,
            ic_yearly: 5.021,
          },
          {
            ic_uuid: "eed4f305-b467-48d3-b33e-78137bf22f33",
            ic_lat: -43919,
            ic_lon: -595878,
            ic_city: "Borba",
            ic_states: "AMAZONAS",
            ic_january: 3.987,
            ic_february: 4.153,
            ic_march: 4.12,
            ic_april: 3.985,
            ic_may: 3.958,
            ic_june: 4.296,
            ic_july: 4.419,
            ic_august: 5.045,
            ic_september: 5.076,
            ic_october: 4.97,
            ic_november: 4.655,
            ic_december: 4.284,
            ic_yearly: 4.412,
          },
          {
            ic_uuid: "00ab5b58-6546-4400-b27d-83c2f766e07f",
            ic_lat: -68024,
            ic_lon: -35619,
            ic_city: "Borborema",
            ic_states: "PARAÍBA",
            ic_january: 5.669,
            ic_february: 5.79,
            ic_march: 5.802,
            ic_april: 5.29,
            ic_may: 4.698,
            ic_june: 4.194,
            ic_july: 4.325,
            ic_august: 5.118,
            ic_september: 5.598,
            ic_october: 5.838,
            ic_november: 6.043,
            ic_december: 5.855,
            ic_yearly: 5.352,
          },
          {
            ic_uuid: "e574cb80-7bda-4e52-bce6-15d9eaee2513",
            ic_lat: -216218,
            ic_lon: -490745,
            ic_city: "Borborema",
            ic_states: "SÃO PAULO",
            ic_january: 5.84,
            ic_february: 5.96,
            ic_march: 5.248,
            ic_april: 4.78,
            ic_may: 3.988,
            ic_june: 3.747,
            ic_july: 3.951,
            ic_august: 4.828,
            ic_september: 4.965,
            ic_october: 5.581,
            ic_november: 5.882,
            ic_december: 6.199,
            ic_yearly: 5.081,
          },
          {
            ic_uuid: "2924b174-18b7-4d03-af13-da5a72447dfe",
            ic_lat: -222712,
            ic_lon: -461657,
            ic_city: "Borda da Mata",
            ic_states: "MINAS GERAIS",
            ic_january: 5.231,
            ic_february: 5.572,
            ic_march: 4.879,
            ic_april: 4.554,
            ic_may: 3.886,
            ic_june: 3.584,
            ic_july: 3.869,
            ic_august: 4.76,
            ic_september: 4.973,
            ic_october: 5.372,
            ic_november: 5.401,
            ic_december: 5.653,
            ic_yearly: 4.811,
          },
          {
            ic_uuid: "4eeabfb7-f598-4e50-acf2-5aedfee3c0c1",
            ic_lat: -22569,
            ic_lon: -489709,
            ic_city: "Borebi",
            ic_states: "SÃO PAULO",
            ic_january: 5.529,
            ic_february: 5.753,
            ic_march: 5.174,
            ic_april: 4.682,
            ic_may: 3.817,
            ic_june: 3.552,
            ic_july: 3.71,
            ic_august: 4.679,
            ic_september: 4.784,
            ic_october: 5.441,
            ic_november: 5.89,
            ic_december: 6.166,
            ic_yearly: 4.931,
          },
          {
            ic_uuid: "59f01e6e-014a-4ca5-9357-77043c3bd35e",
            ic_lat: -239371,
            ic_lon: -515879,
            ic_city: "Borrazópolis",
            ic_states: "PARANÁ",
            ic_january: 5.884,
            ic_february: 5.715,
            ic_march: 5.235,
            ic_april: 4.542,
            ic_may: 3.546,
            ic_june: 3.229,
            ic_july: 3.454,
            ic_august: 4.436,
            ic_september: 4.588,
            ic_october: 5.241,
            ic_november: 6.038,
            ic_december: 6.29,
            ic_yearly: 4.85,
          },
          {
            ic_uuid: "17cbacaf-0ec1-4196-86e9-b2921b530dbe",
            ic_lat: -287296,
            ic_lon: -54904,
            ic_city: "Bossoroca",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.598,
            ic_february: 6.117,
            ic_march: 5.254,
            ic_april: 4.026,
            ic_may: 3.068,
            ic_june: 2.515,
            ic_july: 2.843,
            ic_august: 3.599,
            ic_september: 4.058,
            ic_october: 5.196,
            ic_november: 6.419,
            ic_december: 6.865,
            ic_yearly: 4.713,
          },
          {
            ic_uuid: "b3b8dca9-f4f8-4753-ba0d-0978676312db",
            ic_lat: -216416,
            ic_lon: -463914,
            ic_city: "Botelhos",
            ic_states: "MINAS GERAIS",
            ic_january: 5.406,
            ic_february: 5.666,
            ic_march: 4.855,
            ic_april: 4.565,
            ic_may: 3.988,
            ic_june: 3.777,
            ic_july: 4.015,
            ic_august: 4.906,
            ic_september: 5.081,
            ic_october: 5.402,
            ic_november: 5.258,
            ic_december: 5.551,
            ic_yearly: 4.873,
          },
          {
            ic_uuid: "0c52db2a-bfa1-4426-94fb-c757969d1ec2",
            ic_lat: -228842,
            ic_lon: -484441,
            ic_city: "Botucatu",
            ic_states: "SÃO PAULO",
            ic_january: 5.499,
            ic_february: 5.81,
            ic_march: 5.126,
            ic_april: 4.625,
            ic_may: 3.747,
            ic_june: 3.49,
            ic_july: 3.653,
            ic_august: 4.638,
            ic_september: 4.801,
            ic_october: 5.417,
            ic_november: 5.812,
            ic_december: 6.102,
            ic_yearly: 4.893,
          },
          {
            ic_uuid: "c87f1385-4fbd-4218-b625-7c7c97ff5556",
            ic_lat: -168662,
            ic_lon: -430089,
            ic_city: "Botumirim",
            ic_states: "MINAS GERAIS",
            ic_january: 5.927,
            ic_february: 6.281,
            ic_march: 5.203,
            ic_april: 4.622,
            ic_may: 4.191,
            ic_june: 3.971,
            ic_july: 4.268,
            ic_august: 5.088,
            ic_september: 5.633,
            ic_october: 5.745,
            ic_november: 5.165,
            ic_december: 5.862,
            ic_yearly: 5.163,
          },
          {
            ic_uuid: "7e2824ee-9b8f-4cfd-9005-ddc15534f378",
            ic_lat: -133796,
            ic_lon: -425245,
            ic_city: "Botuporã",
            ic_states: "BAHIA",
            ic_january: 6.601,
            ic_february: 6.525,
            ic_march: 5.961,
            ic_april: 5.425,
            ic_may: 4.948,
            ic_june: 4.782,
            ic_july: 4.99,
            ic_august: 5.682,
            ic_september: 6.181,
            ic_october: 6.375,
            ic_november: 5.936,
            ic_december: 6.504,
            ic_yearly: 5.826,
          },
          {
            ic_uuid: "74f5cdde-00fe-4048-b18b-8cb3491bceb3",
            ic_lat: -272012,
            ic_lon: -490693,
            ic_city: "Botuverá",
            ic_states: "SANTA CATARINA",
            ic_january: 5.345,
            ic_february: 5.134,
            ic_march: 4.422,
            ic_april: 3.603,
            ic_may: 2.989,
            ic_june: 2.463,
            ic_july: 2.635,
            ic_august: 3.306,
            ic_september: 3.351,
            ic_october: 3.778,
            ic_november: 4.972,
            ic_december: 5.41,
            ic_yearly: 3.951,
          },
          {
            ic_uuid: "70882cb9-a1f7-4cd0-a617-1598e8617646",
            ic_lat: -283664,
            ic_lon: -537724,
            ic_city: "Bozano",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.451,
            ic_february: 6.011,
            ic_march: 5.145,
            ic_april: 3.992,
            ic_may: 3.052,
            ic_june: 2.5,
            ic_july: 2.796,
            ic_august: 3.576,
            ic_september: 3.947,
            ic_october: 5.074,
            ic_november: 6.233,
            ic_december: 6.665,
            ic_yearly: 4.62,
          },
          {
            ic_uuid: "3ae49cfa-2fdc-44e4-900e-920a6c704940",
            ic_lat: -282686,
            ic_lon: -491705,
            ic_city: "Braço do Norte",
            ic_states: "SANTA CATARINA",
            ic_january: 5.701,
            ic_february: 5.239,
            ic_march: 4.522,
            ic_april: 3.797,
            ic_may: 2.973,
            ic_june: 2.528,
            ic_july: 2.735,
            ic_august: 3.501,
            ic_september: 3.607,
            ic_october: 4.38,
            ic_november: 5.649,
            ic_december: 5.976,
            ic_yearly: 4.217,
          },
          {
            ic_uuid: "02c6a2fd-c333-4bb9-91ff-86e9e6851b53",
            ic_lat: -273591,
            ic_lon: -498826,
            ic_city: "Braço do Trombudo",
            ic_states: "SANTA CATARINA",
            ic_january: 5.635,
            ic_february: 5.355,
            ic_march: 4.608,
            ic_april: 3.663,
            ic_may: 2.884,
            ic_june: 2.36,
            ic_july: 2.536,
            ic_august: 3.322,
            ic_september: 3.517,
            ic_october: 4.108,
            ic_november: 5.405,
            ic_december: 5.774,
            ic_yearly: 4.098,
          },
          {
            ic_uuid: "21dee6bf-7346-4079-9667-cbc039ffffef",
            ic_lat: -276178,
            ic_lon: -53741,
            ic_city: "Braga",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.475,
            ic_february: 5.942,
            ic_march: 5.151,
            ic_april: 4.055,
            ic_may: 3.13,
            ic_june: 2.581,
            ic_july: 2.935,
            ic_august: 3.693,
            ic_september: 4.009,
            ic_october: 5.183,
            ic_november: 6.244,
            ic_december: 6.632,
            ic_yearly: 4.669,
          },
          {
            ic_uuid: "5f7d9e25-47bf-4b7b-948f-e8eadc4a980c",
            ic_lat: -1053,
            ic_lon: -46771,
            ic_city: "Bragança",
            ic_states: "PARÁ",
            ic_january: 4.661,
            ic_february: 4.352,
            ic_march: 4.18,
            ic_april: 4.194,
            ic_may: 4.475,
            ic_june: 4.747,
            ic_july: 4.857,
            ic_august: 5.265,
            ic_september: 5.566,
            ic_october: 5.459,
            ic_november: 5.303,
            ic_december: 4.987,
            ic_yearly: 4.837,
          },
          {
            ic_uuid: "9c3cda5e-370a-467b-86bf-1460ad06b0ca",
            ic_lat: -229532,
            ic_lon: -465423,
            ic_city: "Bragança Paulista",
            ic_states: "SÃO PAULO",
            ic_january: 5.409,
            ic_february: 5.707,
            ic_march: 4.972,
            ic_april: 4.53,
            ic_may: 3.721,
            ic_june: 3.454,
            ic_july: 3.621,
            ic_august: 4.616,
            ic_september: 4.736,
            ic_october: 5.303,
            ic_november: 5.576,
            ic_december: 6.034,
            ic_yearly: 4.807,
          },
          {
            ic_uuid: "bfec6e0f-121c-4d05-a8c6-54e2b97ebb07",
            ic_lat: -248178,
            ic_lon: -531222,
            ic_city: "Braganey",
            ic_states: "PARANÁ",
            ic_january: 6.31,
            ic_february: 5.608,
            ic_march: 5.237,
            ic_april: 4.358,
            ic_may: 3.434,
            ic_june: 3.015,
            ic_july: 3.243,
            ic_august: 4.245,
            ic_september: 4.461,
            ic_october: 5.223,
            ic_november: 6.059,
            ic_december: 6.415,
            ic_yearly: 4.801,
          },
          {
            ic_uuid: "7cc8d6b9-dc8d-4860-a91a-b5f13c9f68ff",
            ic_lat: -92339,
            ic_lon: -360164,
            ic_city: "Branquinha",
            ic_states: "ALAGOAS",
            ic_january: 5.781,
            ic_february: 5.755,
            ic_march: 5.725,
            ic_april: 5.079,
            ic_may: 4.217,
            ic_june: 3.814,
            ic_july: 3.893,
            ic_august: 4.505,
            ic_september: 5.392,
            ic_october: 5.643,
            ic_november: 6.139,
            ic_december: 6.108,
            ic_yearly: 5.171,
          },
          {
            ic_uuid: "77698920-b6eb-452b-be49-61d31d9f3500",
            ic_lat: -208424,
            ic_lon: -432409,
            ic_city: "Brás Pires",
            ic_states: "MINAS GERAIS",
            ic_january: 5.488,
            ic_february: 5.858,
            ic_march: 4.81,
            ic_april: 4.304,
            ic_may: 3.69,
            ic_june: 3.551,
            ic_july: 3.749,
            ic_august: 4.583,
            ic_september: 4.787,
            ic_october: 5.002,
            ic_november: 4.68,
            ic_december: 5.388,
            ic_yearly: 4.657,
          },
          {
            ic_uuid: "d98aca8d-7d1a-4dd2-94fe-dd8f206a59e6",
            ic_lat: -32979,
            ic_lon: -525393,
            ic_city: "Brasil Novo",
            ic_states: "PARÁ",
            ic_january: 4.119,
            ic_february: 4.21,
            ic_march: 4.264,
            ic_april: 4.271,
            ic_may: 4.252,
            ic_june: 4.494,
            ic_july: 4.469,
            ic_august: 4.739,
            ic_september: 4.582,
            ic_october: 4.526,
            ic_november: 4.457,
            ic_december: 4.154,
            ic_yearly: 4.378,
          },
          {
            ic_uuid: "e81a0d2e-af9f-43c9-8923-a52d64eac561",
            ic_lat: -212549,
            ic_lon: -520369,
            ic_city: "Brasilândia",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.847,
            ic_february: 5.956,
            ic_march: 5.424,
            ic_april: 4.851,
            ic_may: 4.063,
            ic_june: 3.72,
            ic_july: 3.875,
            ic_august: 4.81,
            ic_september: 4.93,
            ic_october: 5.576,
            ic_november: 6.021,
            ic_december: 6.341,
            ic_yearly: 5.118,
          },
          {
            ic_uuid: "2d5a3437-a892-4d13-8d50-40fd89c5e0a9",
            ic_lat: -169976,
            ic_lon: -460123,
            ic_city: "Brasilândia de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 6.185,
            ic_february: 6.373,
            ic_march: 5.473,
            ic_april: 5.44,
            ic_may: 4.953,
            ic_june: 4.658,
            ic_july: 5.027,
            ic_august: 5.786,
            ic_september: 5.827,
            ic_october: 5.941,
            ic_november: 5.493,
            ic_december: 5.953,
            ic_yearly: 5.593,
          },
          {
            ic_uuid: "73191593-02cd-4745-b0ba-bba97adccf8c",
            ic_lat: -241982,
            ic_lon: -53528,
            ic_city: "Brasilândia do Sul",
            ic_states: "PARANÁ",
            ic_january: 6.253,
            ic_february: 5.812,
            ic_march: 5.283,
            ic_april: 4.399,
            ic_may: 3.49,
            ic_june: 3.074,
            ic_july: 3.268,
            ic_august: 4.268,
            ic_september: 4.537,
            ic_october: 5.357,
            ic_november: 6.141,
            ic_december: 6.53,
            ic_yearly: 4.868,
          },
          {
            ic_uuid: "80c75426-df9c-45fe-8944-71bf1786e4de",
            ic_lat: -83896,
            ic_lon: -484826,
            ic_city: "Brasilândia do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 4.819,
            ic_february: 4.909,
            ic_march: 4.732,
            ic_april: 4.709,
            ic_may: 4.928,
            ic_june: 5.17,
            ic_july: 5.263,
            ic_august: 5.882,
            ic_september: 5.699,
            ic_october: 5.125,
            ic_november: 4.789,
            ic_december: 4.782,
            ic_yearly: 5.067,
          },
          {
            ic_uuid: "d0a11d36-1197-4cc8-8dcf-8a0a74a4190a",
            ic_lat: -41341,
            ic_lon: -417862,
            ic_city: "Brasileira",
            ic_states: "PIAUÍ",
            ic_january: 5.353,
            ic_february: 5.459,
            ic_march: 5.397,
            ic_april: 5.154,
            ic_may: 5.238,
            ic_june: 5.335,
            ic_july: 5.614,
            ic_august: 6.147,
            ic_september: 6.536,
            ic_october: 6.538,
            ic_november: 6.378,
            ic_december: 5.75,
            ic_yearly: 5.742,
          },
          {
            ic_uuid: "5d466e6e-1223-43a1-8e96-9ab4a06c3050",
            ic_lat: -157804,
            ic_lon: -479304,
            ic_city: "Brasília",
            ic_states: "DISTRITO FEDERAL",
            ic_january: 5.332,
            ic_february: 5.644,
            ic_march: 5.0,
            ic_april: 4.965,
            ic_may: 4.798,
            ic_june: 4.719,
            ic_july: 5.019,
            ic_august: 5.903,
            ic_september: 5.712,
            ic_october: 5.545,
            ic_november: 5.017,
            ic_december: 5.362,
            ic_yearly: 5.252,
          },
          {
            ic_uuid: "a91733be-13e5-4e6f-a2bf-e510359dee40",
            ic_lat: -162119,
            ic_lon: -44428,
            ic_city: "Brasília de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 6.243,
            ic_february: 6.515,
            ic_march: 5.602,
            ic_april: 5.523,
            ic_may: 4.989,
            ic_june: 4.709,
            ic_july: 5.033,
            ic_august: 5.882,
            ic_september: 5.962,
            ic_october: 5.977,
            ic_november: 5.437,
            ic_december: 6.095,
            ic_yearly: 5.664,
          },
          {
            ic_uuid: "9aa5fb73-d1fd-4ebb-a517-1ba589675ddf",
            ic_lat: -121211,
            ic_lon: -580029,
            ic_city: "Brasnorte",
            ic_states: "MATO GROSSO",
            ic_january: 4.894,
            ic_february: 4.841,
            ic_march: 4.81,
            ic_april: 4.722,
            ic_may: 4.519,
            ic_june: 4.526,
            ic_july: 4.671,
            ic_august: 5.191,
            ic_september: 5.069,
            ic_october: 5.265,
            ic_november: 5.091,
            ic_december: 5.187,
            ic_yearly: 4.899,
          },
          {
            ic_uuid: "677d7ddd-6c45-4f54-a872-ed3d1f600481",
            ic_lat: -214995,
            ic_lon: -503179,
            ic_city: "Braúna",
            ic_states: "SÃO PAULO",
            ic_january: 5.952,
            ic_february: 5.974,
            ic_march: 5.342,
            ic_april: 4.848,
            ic_may: 3.988,
            ic_june: 3.692,
            ic_july: 3.934,
            ic_august: 4.839,
            ic_september: 4.901,
            ic_october: 5.584,
            ic_november: 6.049,
            ic_december: 6.308,
            ic_yearly: 5.118,
          },
          {
            ic_uuid: "5bcbf457-5e90-49a1-8d17-3d73565152ad",
            ic_lat: -190566,
            ic_lon: -427103,
            ic_city: "Braúnas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.821,
            ic_february: 6.057,
            ic_march: 5.102,
            ic_april: 4.625,
            ic_may: 3.919,
            ic_june: 3.73,
            ic_july: 3.901,
            ic_august: 4.572,
            ic_september: 4.935,
            ic_october: 5.135,
            ic_november: 4.835,
            ic_december: 5.583,
            ic_yearly: 4.851,
          },
          {
            ic_uuid: "5242eaf8-8a1c-4c26-b863-bb55a99269e4",
            ic_lat: -164316,
            ic_lon: -493857,
            ic_city: "Brazabrantes",
            ic_states: "GOIÁS",
            ic_january: 5.47,
            ic_february: 5.56,
            ic_march: 5.185,
            ic_april: 5.118,
            ic_may: 4.872,
            ic_june: 4.584,
            ic_july: 4.823,
            ic_august: 5.767,
            ic_september: 5.532,
            ic_october: 5.55,
            ic_november: 5.297,
            ic_december: 5.471,
            ic_yearly: 5.269,
          },
          {
            ic_uuid: "2f0c7f67-e1f8-4819-a374-86c6a840efea",
            ic_lat: -224705,
            ic_lon: -456134,
            ic_city: "Brazópolis",
            ic_states: "MINAS GERAIS",
            ic_january: 5.359,
            ic_february: 5.663,
            ic_march: 4.882,
            ic_april: 4.547,
            ic_may: 3.81,
            ic_june: 3.555,
            ic_july: 3.804,
            ic_august: 4.66,
            ic_september: 4.925,
            ic_october: 5.365,
            ic_november: 5.378,
            ic_december: 5.769,
            ic_yearly: 4.81,
          },
          {
            ic_uuid: "d1b8dc2f-647c-4736-8dea-5bb31d86b60a",
            ic_lat: -90281,
            ic_lon: -365679,
            ic_city: "Brejão",
            ic_states: "PERNAMBUCO",
            ic_january: 6.108,
            ic_february: 5.855,
            ic_march: 5.858,
            ic_april: 5.192,
            ic_may: 4.242,
            ic_june: 3.732,
            ic_july: 3.87,
            ic_august: 4.531,
            ic_september: 5.51,
            ic_october: 5.901,
            ic_november: 6.509,
            ic_december: 6.346,
            ic_yearly: 5.304,
          },
          {
            ic_uuid: "8458d333-b456-45c0-ad89-534c1226d902",
            ic_lat: -2015,
            ic_lon: -412924,
            ic_city: "Brejetuba",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.618,
            ic_february: 5.924,
            ic_march: 4.895,
            ic_april: 4.439,
            ic_may: 3.765,
            ic_june: 3.515,
            ic_july: 3.636,
            ic_august: 4.31,
            ic_september: 4.633,
            ic_october: 4.74,
            ic_november: 4.654,
            ic_december: 5.37,
            ic_yearly: 4.625,
          },
          {
            ic_uuid: "b88b769b-06ca-40c2-bc46-b69b604571e9",
            ic_lat: -73474,
            ic_lon: -372868,
            ic_city: "Brejinho",
            ic_states: "PERNAMBUCO",
            ic_january: 6.072,
            ic_february: 5.998,
            ic_march: 6.036,
            ic_april: 5.708,
            ic_may: 5.026,
            ic_june: 4.648,
            ic_july: 4.989,
            ic_august: 5.717,
            ic_september: 6.387,
            ic_october: 6.545,
            ic_november: 6.647,
            ic_december: 6.217,
            ic_yearly: 5.833,
          },
          {
            ic_uuid: "94257a4c-16a8-4d82-9714-94f5382d9081",
            ic_lat: -61954,
            ic_lon: -353561,
            ic_city: "Brejinho",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.895,
            ic_february: 5.964,
            ic_march: 6.078,
            ic_april: 5.478,
            ic_may: 4.922,
            ic_june: 4.482,
            ic_july: 4.579,
            ic_august: 5.332,
            ic_september: 5.788,
            ic_october: 6.015,
            ic_november: 6.25,
            ic_december: 5.974,
            ic_yearly: 5.563,
          },
          {
            ic_uuid: "c7b4c0f9-2fd6-4395-bade-b15a2ed17b39",
            ic_lat: -110209,
            ic_lon: -485683,
            ic_city: "Brejinho de Nazaré",
            ic_states: "TOCANTINS",
            ic_january: 5.234,
            ic_february: 5.261,
            ic_march: 4.986,
            ic_april: 5.098,
            ic_may: 5.074,
            ic_june: 5.032,
            ic_july: 5.191,
            ic_august: 5.863,
            ic_september: 5.799,
            ic_october: 5.492,
            ic_november: 5.193,
            ic_december: 5.191,
            ic_yearly: 5.284,
          },
          {
            ic_uuid: "a7c1dbad-8b29-4bdc-90f9-d978f232487a",
            ic_lat: -36784,
            ic_lon: -42753,
            ic_city: "Brejo",
            ic_states: "MARANHÃO",
            ic_january: 5.251,
            ic_february: 5.4,
            ic_march: 5.367,
            ic_april: 5.133,
            ic_may: 5.133,
            ic_june: 5.301,
            ic_july: 5.511,
            ic_august: 6.031,
            ic_september: 6.415,
            ic_october: 6.385,
            ic_november: 6.092,
            ic_december: 5.579,
            ic_yearly: 5.633,
          },
          {
            ic_uuid: "f23a4798-a4f0-46df-887c-84a22e53712e",
            ic_lat: -21166,
            ic_lon: -501849,
            ic_city: "Brejo Alegre",
            ic_states: "SÃO PAULO",
            ic_january: 5.903,
            ic_february: 5.982,
            ic_march: 5.366,
            ic_april: 4.835,
            ic_may: 3.982,
            ic_june: 3.695,
            ic_july: 3.935,
            ic_august: 4.798,
            ic_september: 4.876,
            ic_october: 5.62,
            ic_november: 6.054,
            ic_december: 6.359,
            ic_yearly: 5.117,
          },
          {
            ic_uuid: "99a58a58-a70c-4b4f-a50a-b4fdbda94669",
            ic_lat: -81442,
            ic_lon: -36371,
            ic_city: "Brejo da Madre de Deus",
            ic_states: "PERNAMBUCO",
            ic_january: 5.957,
            ic_february: 5.893,
            ic_march: 5.853,
            ic_april: 5.401,
            ic_may: 4.602,
            ic_june: 4.193,
            ic_july: 4.315,
            ic_august: 5.05,
            ic_september: 5.76,
            ic_october: 5.991,
            ic_november: 6.283,
            ic_december: 6.129,
            ic_yearly: 5.452,
          },
          {
            ic_uuid: "4f60dd60-da64-4452-9c3c-96789a3c4316",
            ic_lat: -43306,
            ic_lon: -455841,
            ic_city: "Brejo de Areia",
            ic_states: "MARANHÃO",
            ic_january: 4.545,
            ic_february: 4.758,
            ic_march: 4.823,
            ic_april: 4.883,
            ic_may: 4.761,
            ic_june: 4.915,
            ic_july: 5.097,
            ic_august: 5.535,
            ic_september: 5.745,
            ic_october: 5.299,
            ic_november: 4.893,
            ic_december: 4.737,
            ic_yearly: 4.999,
          },
          {
            ic_uuid: "52f51b2f-135a-4a49-af2e-f25640c68419",
            ic_lat: -63423,
            ic_lon: -374946,
            ic_city: "Brejo do Cruz",
            ic_states: "PARAÍBA",
            ic_january: 6.18,
            ic_february: 6.237,
            ic_march: 6.16,
            ic_april: 5.857,
            ic_may: 5.351,
            ic_june: 4.951,
            ic_july: 5.224,
            ic_august: 5.915,
            ic_september: 6.475,
            ic_october: 6.637,
            ic_november: 6.653,
            ic_december: 6.276,
            ic_yearly: 5.993,
          },
          {
            ic_uuid: "c96859d3-31ef-4e04-a80e-c15a4edd9b9d",
            ic_lat: -82078,
            ic_lon: -428356,
            ic_city: "Brejo do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.635,
            ic_february: 5.618,
            ic_march: 5.521,
            ic_april: 5.366,
            ic_may: 5.305,
            ic_june: 5.252,
            ic_july: 5.541,
            ic_august: 6.359,
            ic_september: 6.513,
            ic_october: 6.457,
            ic_november: 6.117,
            ic_december: 5.921,
            ic_yearly: 5.8,
          },
          {
            ic_uuid: "dfefb862-dc0a-4fc2-aaae-58d346f9ae00",
            ic_lat: -63711,
            ic_lon: -378256,
            ic_city: "Brejo dos Santos",
            ic_states: "PARAÍBA",
            ic_january: 6.136,
            ic_february: 6.131,
            ic_march: 6.104,
            ic_april: 5.81,
            ic_may: 5.312,
            ic_june: 4.993,
            ic_july: 5.301,
            ic_august: 6.024,
            ic_september: 6.553,
            ic_october: 6.639,
            ic_november: 6.693,
            ic_december: 6.278,
            ic_yearly: 5.998,
          },
          {
            ic_uuid: "620f8f1d-e975-4714-bac2-23dbf843d87e",
            ic_lat: -104302,
            ic_lon: -364614,
            ic_city: "Brejo Grande",
            ic_states: "SERGIPE",
            ic_january: 6.151,
            ic_february: 6.169,
            ic_march: 6.059,
            ic_april: 5.039,
            ic_may: 4.366,
            ic_june: 4.106,
            ic_july: 4.158,
            ic_august: 4.855,
            ic_september: 5.535,
            ic_october: 6.017,
            ic_november: 6.358,
            ic_december: 6.473,
            ic_yearly: 5.441,
          },
          {
            ic_uuid: "0ebacd8c-5895-4a89-8391-c7cba83e38e0",
            ic_lat: -56987,
            ic_lon: -484027,
            ic_city: "Brejo Grande do Araguaia",
            ic_states: "PARÁ",
            ic_january: 4.598,
            ic_february: 4.745,
            ic_march: 4.745,
            ic_april: 4.706,
            ic_may: 4.715,
            ic_june: 5.037,
            ic_july: 5.239,
            ic_august: 5.685,
            ic_september: 5.433,
            ic_october: 4.909,
            ic_november: 4.727,
            ic_december: 4.54,
            ic_yearly: 4.923,
          },
          {
            ic_uuid: "c9cd6a92-7224-4d9f-b716-085605ec90df",
            ic_lat: -7489,
            ic_lon: -389864,
            ic_city: "Brejo Santo",
            ic_states: "CEARÁ",
            ic_january: 6.099,
            ic_february: 6.057,
            ic_march: 6.018,
            ic_april: 5.682,
            ic_may: 5.048,
            ic_june: 4.725,
            ic_july: 5.003,
            ic_august: 5.758,
            ic_september: 6.511,
            ic_october: 6.521,
            ic_november: 6.662,
            ic_december: 6.342,
            ic_yearly: 5.869,
          },
          {
            ic_uuid: "65e19385-0b87-4583-af43-209ae73d9f1d",
            ic_lat: -13104,
            ic_lon: -397942,
            ic_city: "Brejões",
            ic_states: "BAHIA",
            ic_january: 5.828,
            ic_february: 5.899,
            ic_march: 5.557,
            ic_april: 4.479,
            ic_may: 3.922,
            ic_june: 3.576,
            ic_july: 3.754,
            ic_august: 4.322,
            ic_september: 5.114,
            ic_october: 5.272,
            ic_november: 5.41,
            ic_december: 5.903,
            ic_yearly: 4.92,
          },
          {
            ic_uuid: "a67dd927-5b4c-4091-bf0d-655dfbd492f5",
            ic_lat: -124863,
            ic_lon: -439616,
            ic_city: "Brejolândia",
            ic_states: "BAHIA",
            ic_january: 6.455,
            ic_february: 6.293,
            ic_march: 5.815,
            ic_april: 5.625,
            ic_may: 5.259,
            ic_june: 5.097,
            ic_july: 5.363,
            ic_august: 5.885,
            ic_september: 6.156,
            ic_october: 6.312,
            ic_november: 5.813,
            ic_december: 6.306,
            ic_yearly: 5.865,
          },
          {
            ic_uuid: "eb9e41bf-47f8-4a03-851e-91e0d651f8dc",
            ic_lat: -37723,
            ic_lon: -495738,
            ic_city: "Breu Branco",
            ic_states: "PARÁ",
            ic_january: 4.324,
            ic_february: 4.528,
            ic_march: 4.562,
            ic_april: 4.627,
            ic_may: 4.574,
            ic_june: 4.8,
            ic_july: 4.897,
            ic_august: 5.2,
            ic_september: 4.942,
            ic_october: 4.592,
            ic_november: 4.483,
            ic_december: 4.21,
            ic_yearly: 4.645,
          },
          {
            ic_uuid: "72d7690e-ea5c-418b-ab74-ed2d93cd3ab6",
            ic_lat: -16807,
            ic_lon: -504795,
            ic_city: "Breves",
            ic_states: "PARÁ",
            ic_january: 4.397,
            ic_february: 4.32,
            ic_march: 4.304,
            ic_april: 4.415,
            ic_may: 4.598,
            ic_june: 4.829,
            ic_july: 4.916,
            ic_august: 5.229,
            ic_september: 5.145,
            ic_october: 5.055,
            ic_november: 4.97,
            ic_december: 4.522,
            ic_yearly: 4.725,
          },
          {
            ic_uuid: "68909e4b-9dbf-40ad-8f7d-736ad0ee1260",
            ic_lat: -152429,
            ic_lon: -511654,
            ic_city: "Britânia",
            ic_states: "GOIÁS",
            ic_january: 5.639,
            ic_february: 5.729,
            ic_march: 5.44,
            ic_april: 5.338,
            ic_may: 4.953,
            ic_june: 4.645,
            ic_july: 4.83,
            ic_august: 5.509,
            ic_september: 5.529,
            ic_october: 5.557,
            ic_november: 5.422,
            ic_december: 5.581,
            ic_yearly: 5.348,
          },
          {
            ic_uuid: "718b3615-271a-4d7a-83f4-6e74dac14079",
            ic_lat: -295506,
            ic_lon: -51595,
            ic_city: "Brochier",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.183,
            ic_february: 5.743,
            ic_march: 4.821,
            ic_april: 3.786,
            ic_may: 2.793,
            ic_june: 2.326,
            ic_july: 2.532,
            ic_august: 3.214,
            ic_september: 3.533,
            ic_october: 4.564,
            ic_november: 6.092,
            ic_december: 6.481,
            ic_yearly: 4.339,
          },
          {
            ic_uuid: "cda5cf58-b423-486f-b384-07102ebdb56c",
            ic_lat: -20985,
            ic_lon: -476576,
            ic_city: "Brodowski",
            ic_states: "SÃO PAULO",
            ic_january: 5.625,
            ic_february: 5.796,
            ic_march: 5.075,
            ic_april: 4.808,
            ic_may: 4.133,
            ic_june: 3.931,
            ic_july: 4.146,
            ic_august: 5.016,
            ic_september: 5.026,
            ic_october: 5.482,
            ic_november: 5.644,
            ic_december: 5.913,
            ic_yearly: 5.05,
          },
          {
            ic_uuid: "ceff23d5-a97a-486b-97de-f2dd7cbf68be",
            ic_lat: -222799,
            ic_lon: -481255,
            ic_city: "Brotas",
            ic_states: "SÃO PAULO",
            ic_january: 5.573,
            ic_february: 5.817,
            ic_march: 5.147,
            ic_april: 4.682,
            ic_may: 3.881,
            ic_june: 3.611,
            ic_july: 3.804,
            ic_august: 4.764,
            ic_september: 4.872,
            ic_october: 5.503,
            ic_november: 5.762,
            ic_december: 6.041,
            ic_yearly: 4.955,
          },
          {
            ic_uuid: "86bdaf3e-d1b5-42c2-b5f1-7e85a87605f6",
            ic_lat: -119951,
            ic_lon: -426302,
            ic_city: "Brotas de Macaúbas",
            ic_states: "BAHIA",
            ic_january: 6.309,
            ic_february: 6.147,
            ic_march: 5.842,
            ic_april: 5.444,
            ic_may: 5.035,
            ic_june: 4.919,
            ic_july: 5.15,
            ic_august: 5.94,
            ic_september: 6.428,
            ic_october: 6.272,
            ic_november: 5.848,
            ic_december: 6.259,
            ic_yearly: 5.799,
          },
          {
            ic_uuid: "de839b50-107a-47e6-9556-daa3126b2d4b",
            ic_lat: -201515,
            ic_lon: -44201,
            ic_city: "Brumadinho",
            ic_states: "MINAS GERAIS",
            ic_january: 5.831,
            ic_february: 5.928,
            ic_march: 5.076,
            ic_april: 4.759,
            ic_may: 4.194,
            ic_june: 4.006,
            ic_july: 4.243,
            ic_august: 5.143,
            ic_september: 5.407,
            ic_october: 5.534,
            ic_november: 5.253,
            ic_december: 5.59,
            ic_yearly: 5.08,
          },
          {
            ic_uuid: "46f83204-ddbe-4393-906c-719ce82cee69",
            ic_lat: -142026,
            ic_lon: -416699,
            ic_city: "Brumado",
            ic_states: "BAHIA",
            ic_january: 6.404,
            ic_february: 6.397,
            ic_march: 5.75,
            ic_april: 4.963,
            ic_may: 4.38,
            ic_june: 3.989,
            ic_july: 4.287,
            ic_august: 4.958,
            ic_september: 5.755,
            ic_october: 6.063,
            ic_november: 5.901,
            ic_december: 6.532,
            ic_yearly: 5.448,
          },
          {
            ic_uuid: "3d110ae2-958a-44ab-a076-b491879a1fcf",
            ic_lat: -273118,
            ic_lon: -508367,
            ic_city: "Brunópolis",
            ic_states: "SANTA CATARINA",
            ic_january: 5.721,
            ic_february: 5.401,
            ic_march: 4.749,
            ic_april: 3.873,
            ic_may: 2.96,
            ic_june: 2.573,
            ic_july: 2.839,
            ic_august: 3.709,
            ic_september: 3.878,
            ic_october: 4.657,
            ic_november: 5.803,
            ic_december: 6.103,
            ic_yearly: 4.355,
          },
          {
            ic_uuid: "7176e0fa-5f46-481a-acee-06e587bd6412",
            ic_lat: -270982,
            ic_lon: -489111,
            ic_city: "Brusque",
            ic_states: "SANTA CATARINA",
            ic_january: 5.446,
            ic_february: 5.23,
            ic_march: 4.537,
            ic_april: 3.611,
            ic_may: 2.996,
            ic_june: 2.469,
            ic_july: 2.628,
            ic_august: 3.241,
            ic_september: 3.36,
            ic_october: 3.839,
            ic_november: 4.988,
            ic_december: 5.498,
            ic_yearly: 3.987,
          },
          {
            ic_uuid: "db4e4122-7955-4c57-96c5-e1a9554bd0fc",
            ic_lat: -224388,
            ic_lon: -463495,
            ic_city: "Bueno Brandão",
            ic_states: "MINAS GERAIS",
            ic_january: 5.436,
            ic_february: 5.7,
            ic_march: 5.028,
            ic_april: 4.647,
            ic_may: 3.929,
            ic_june: 3.635,
            ic_july: 3.867,
            ic_august: 4.738,
            ic_september: 4.971,
            ic_october: 5.438,
            ic_november: 5.564,
            ic_december: 5.803,
            ic_yearly: 4.896,
          },
          {
            ic_uuid: "292c4257-22cf-4060-ae04-b4f7a4312177",
            ic_lat: -178749,
            ic_lon: -441779,
            ic_city: "Buenópolis",
            ic_states: "MINAS GERAIS",
            ic_january: 6.112,
            ic_february: 6.433,
            ic_march: 5.373,
            ic_april: 5.153,
            ic_may: 4.661,
            ic_june: 4.44,
            ic_july: 4.784,
            ic_august: 5.48,
            ic_september: 5.935,
            ic_october: 5.881,
            ic_november: 5.265,
            ic_december: 5.866,
            ic_yearly: 5.449,
          },
          {
            ic_uuid: "ad040ed4-5200-479a-9230-30632ca93018",
            ic_lat: -77218,
            ic_lon: -353273,
            ic_city: "Buenos Aires",
            ic_states: "PERNAMBUCO",
            ic_january: 5.467,
            ic_february: 5.615,
            ic_march: 5.695,
            ic_april: 5.083,
            ic_may: 4.379,
            ic_june: 4.019,
            ic_july: 4.096,
            ic_august: 4.821,
            ic_september: 5.354,
            ic_october: 5.546,
            ic_november: 5.886,
            ic_december: 5.726,
            ic_yearly: 5.141,
          },
          {
            ic_uuid: "ac724970-0b29-4652-9e20-e4b476d17b68",
            ic_lat: -149561,
            ic_lon: -393027,
            ic_city: "Buerarema",
            ic_states: "BAHIA",
            ic_january: 5.845,
            ic_february: 5.872,
            ic_march: 5.339,
            ic_april: 4.511,
            ic_may: 3.802,
            ic_june: 3.465,
            ic_july: 3.722,
            ic_august: 4.269,
            ic_september: 4.874,
            ic_october: 5.065,
            ic_november: 5.122,
            ic_december: 5.983,
            ic_yearly: 4.822,
          },
          {
            ic_uuid: "09ed5bcd-5936-405f-9e67-274998797cfd",
            ic_lat: -194235,
            ic_lon: -422555,
            ic_city: "Bugre",
            ic_states: "MINAS GERAIS",
            ic_january: 5.869,
            ic_february: 6.051,
            ic_march: 5.128,
            ic_april: 4.595,
            ic_may: 3.899,
            ic_june: 3.639,
            ic_july: 3.79,
            ic_august: 4.452,
            ic_september: 4.85,
            ic_october: 5.058,
            ic_november: 4.862,
            ic_december: 5.766,
            ic_yearly: 4.83,
          },
          {
            ic_uuid: "3d6b1a60-ce37-47ab-96f6-cefcfa1cc8fc",
            ic_lat: -8623,
            ic_lon: -371558,
            ic_city: "Buíque",
            ic_states: "PERNAMBUCO",
            ic_january: 6.309,
            ic_february: 6.108,
            ic_march: 6.178,
            ic_april: 5.543,
            ic_may: 4.612,
            ic_june: 4.1,
            ic_july: 4.259,
            ic_august: 5.043,
            ic_september: 6.012,
            ic_october: 6.354,
            ic_november: 6.746,
            ic_december: 6.535,
            ic_yearly: 5.65,
          },
          {
            ic_uuid: "b26d5b56-c55b-4990-b881-91bac3b300b0",
            ic_lat: -9834,
            ic_lon: -679523,
            ic_city: "Bujari",
            ic_states: "ACRE",
            ic_january: 4.501,
            ic_february: 4.621,
            ic_march: 4.231,
            ic_april: 4.401,
            ic_may: 4.011,
            ic_june: 4.111,
            ic_july: 4.316,
            ic_august: 4.885,
            ic_september: 5.031,
            ic_october: 5.131,
            ic_november: 4.989,
            ic_december: 4.58,
            ic_yearly: 4.567,
          },
          {
            ic_uuid: "2fc53682-9f10-4d7d-ae83-5f7e794179a0",
            ic_lat: -1518,
            ic_lon: -480384,
            ic_city: "Bujaru",
            ic_states: "PARÁ",
            ic_january: 4.427,
            ic_february: 4.377,
            ic_march: 4.383,
            ic_april: 4.394,
            ic_may: 4.7,
            ic_june: 4.994,
            ic_july: 5.111,
            ic_august: 5.417,
            ic_september: 5.432,
            ic_october: 5.301,
            ic_november: 4.998,
            ic_december: 4.632,
            ic_yearly: 4.847,
          },
          {
            ic_uuid: "beb02c55-c4bd-4209-a5ca-0013b37e2822",
            ic_lat: -237982,
            ic_lon: -485962,
            ic_city: "Buri",
            ic_states: "SÃO PAULO",
            ic_january: 5.508,
            ic_february: 5.579,
            ic_march: 4.879,
            ic_april: 4.296,
            ic_may: 3.405,
            ic_june: 3.116,
            ic_july: 3.298,
            ic_august: 4.227,
            ic_september: 4.376,
            ic_october: 4.907,
            ic_november: 5.51,
            ic_december: 5.998,
            ic_yearly: 4.592,
          },
          {
            ic_uuid: "1968ec7c-e340-4212-b9c1-1e248f82e274",
            ic_lat: -210665,
            ic_lon: -501479,
            ic_city: "Buritama",
            ic_states: "SÃO PAULO",
            ic_january: 5.798,
            ic_february: 5.896,
            ic_march: 5.329,
            ic_april: 4.887,
            ic_may: 4.012,
            ic_june: 3.712,
            ic_july: 3.961,
            ic_august: 4.838,
            ic_september: 4.92,
            ic_october: 5.609,
            ic_november: 6.023,
            ic_december: 6.32,
            ic_yearly: 5.109,
          },
          {
            ic_uuid: "037c0ac0-02f5-4deb-a4c9-8f02c191e096",
            ic_lat: -39421,
            ic_lon: -429183,
            ic_city: "Buriti",
            ic_states: "MARANHÃO",
            ic_january: 5.169,
            ic_february: 5.296,
            ic_march: 5.227,
            ic_april: 5.117,
            ic_may: 5.066,
            ic_june: 5.222,
            ic_july: 5.443,
            ic_august: 5.971,
            ic_september: 6.283,
            ic_october: 6.294,
            ic_november: 6.063,
            ic_december: 5.609,
            ic_yearly: 5.563,
          },
          {
            ic_uuid: "0a5a043b-2f9d-4567-8d0c-f362672ee491",
            ic_lat: -181382,
            ic_lon: -490408,
            ic_city: "Buriti Alegre",
            ic_states: "GOIÁS",
            ic_january: 5.518,
            ic_february: 5.778,
            ic_march: 5.129,
            ic_april: 5.105,
            ic_may: 4.659,
            ic_june: 4.41,
            ic_july: 4.596,
            ic_august: 5.491,
            ic_september: 5.294,
            ic_october: 5.583,
            ic_november: 5.522,
            ic_december: 5.669,
            ic_yearly: 5.23,
          },
          {
            ic_uuid: "ac8dba6f-529c-4dec-9028-91b9c7e81d21",
            ic_lat: -58328,
            ic_lon: -438356,
            ic_city: "Buriti Bravo",
            ic_states: "MARANHÃO",
            ic_january: 4.811,
            ic_february: 4.996,
            ic_march: 4.982,
            ic_april: 4.936,
            ic_may: 5.066,
            ic_june: 5.337,
            ic_july: 5.58,
            ic_august: 6.248,
            ic_september: 6.455,
            ic_october: 5.979,
            ic_november: 5.535,
            ic_december: 5.165,
            ic_yearly: 5.424,
          },
          {
            ic_uuid: "07c5efa1-4820-4b7d-8211-1d301fc0f5fe",
            ic_lat: -161766,
            ic_lon: -504346,
            ic_city: "Buriti de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.578,
            ic_february: 5.6,
            ic_march: 5.327,
            ic_april: 5.255,
            ic_may: 4.882,
            ic_june: 4.606,
            ic_july: 4.762,
            ic_august: 5.536,
            ic_september: 5.389,
            ic_october: 5.616,
            ic_november: 5.553,
            ic_december: 5.527,
            ic_yearly: 5.303,
          },
          {
            ic_uuid: "fedccfc8-db0b-4fab-90de-a3e2d59c08ac",
            ic_lat: -53149,
            ic_lon: -482275,
            ic_city: "Buriti do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 4.564,
            ic_february: 4.725,
            ic_march: 4.823,
            ic_april: 4.877,
            ic_may: 4.816,
            ic_june: 5.153,
            ic_july: 5.248,
            ic_august: 5.725,
            ic_september: 5.518,
            ic_october: 5.012,
            ic_november: 4.881,
            ic_december: 4.655,
            ic_yearly: 5.0,
          },
          {
            ic_uuid: "f0b5662a-df5d-42ae-aea7-0d0d2d7643ed",
            ic_lat: -31789,
            ic_lon: -418703,
            ic_city: "Buriti dos Lopes",
            ic_states: "PIAUÍ",
            ic_january: 5.164,
            ic_february: 5.152,
            ic_march: 5.056,
            ic_april: 4.882,
            ic_may: 5.003,
            ic_june: 5.266,
            ic_july: 5.4,
            ic_august: 5.963,
            ic_september: 6.364,
            ic_october: 6.455,
            ic_november: 6.268,
            ic_december: 5.603,
            ic_yearly: 5.548,
          },
          {
            ic_uuid: "657f46df-e45c-4d51-8c8a-97e1c9d17925",
            ic_lat: -53062,
            ic_lon: -410937,
            ic_city: "Buriti dos Montes",
            ic_states: "PIAUÍ",
            ic_january: 5.253,
            ic_february: 5.293,
            ic_march: 5.434,
            ic_april: 5.016,
            ic_may: 5.054,
            ic_june: 5.226,
            ic_july: 5.621,
            ic_august: 6.342,
            ic_september: 6.742,
            ic_october: 6.689,
            ic_november: 6.547,
            ic_december: 5.917,
            ic_yearly: 5.761,
          },
          {
            ic_uuid: "c665d316-c2d6-445a-b009-7bc99b3631ad",
            ic_lat: -43214,
            ic_lon: -464542,
            ic_city: "Buriticupu",
            ic_states: "MARANHÃO",
            ic_january: 4.449,
            ic_february: 4.745,
            ic_march: 4.787,
            ic_april: 4.778,
            ic_may: 4.683,
            ic_june: 4.803,
            ic_july: 5.011,
            ic_august: 5.372,
            ic_september: 5.499,
            ic_october: 5.085,
            ic_november: 4.729,
            ic_december: 4.617,
            ic_yearly: 4.88,
          },
          {
            ic_uuid: "0f6eb349-2442-4aac-b441-151ce64567b5",
            ic_lat: -144561,
            ic_lon: -464313,
            ic_city: "Buritinópolis",
            ic_states: "GOIÁS",
            ic_january: 6.082,
            ic_february: 6.127,
            ic_march: 5.568,
            ic_april: 5.492,
            ic_may: 5.045,
            ic_june: 4.867,
            ic_july: 5.214,
            ic_august: 5.871,
            ic_september: 5.962,
            ic_october: 5.876,
            ic_november: 5.401,
            ic_december: 5.953,
            ic_yearly: 5.621,
          },
          {
            ic_uuid: "23e7cb16-bc4e-46a0-836b-0a700cb792ed",
            ic_lat: -107176,
            ic_lon: -43634,
            ic_city: "Buritirama",
            ic_states: "BAHIA",
            ic_january: 6.15,
            ic_february: 5.919,
            ic_march: 5.773,
            ic_april: 5.53,
            ic_may: 5.314,
            ic_june: 5.241,
            ic_july: 5.545,
            ic_august: 6.225,
            ic_september: 6.491,
            ic_october: 6.417,
            ic_november: 6.056,
            ic_december: 6.151,
            ic_yearly: 5.901,
          },
          {
            ic_uuid: "cd3b644c-6e7b-4370-86a2-8b18345b43b6",
            ic_lat: -55986,
            ic_lon: -470135,
            ic_city: "Buritirana",
            ic_states: "MARANHÃO",
            ic_january: 4.597,
            ic_february: 4.696,
            ic_march: 4.722,
            ic_april: 4.819,
            ic_may: 4.833,
            ic_june: 5.16,
            ic_july: 5.339,
            ic_august: 5.75,
            ic_september: 5.669,
            ic_october: 5.293,
            ic_november: 4.914,
            ic_december: 4.777,
            ic_yearly: 5.047,
          },
          {
            ic_uuid: "4ac712da-4353-4d46-bcd7-56bfce18338c",
            ic_lat: -156223,
            ic_lon: -464225,
            ic_city: "Buritis",
            ic_states: "MINAS GERAIS",
            ic_january: 6.074,
            ic_february: 6.284,
            ic_march: 5.525,
            ic_april: 5.529,
            ic_may: 5.024,
            ic_june: 4.817,
            ic_july: 5.147,
            ic_august: 5.85,
            ic_september: 6.049,
            ic_october: 5.947,
            ic_november: 5.36,
            ic_december: 5.85,
            ic_yearly: 5.622,
          },
          {
            ic_uuid: "b5cc5958-5d20-4b7f-a92c-55e8dc5f3872",
            ic_lat: -10206,
            ic_lon: -638312,
            ic_city: "Buritis",
            ic_states: "RONDÔNIA",
            ic_january: 4.301,
            ic_february: 4.383,
            ic_march: 4.334,
            ic_april: 4.379,
            ic_may: 3.981,
            ic_june: 4.302,
            ic_july: 4.378,
            ic_august: 4.837,
            ic_september: 4.905,
            ic_october: 4.964,
            ic_november: 4.738,
            ic_december: 4.505,
            ic_yearly: 4.501,
          },
          {
            ic_uuid: "0ab70b08-9136-45c8-af9b-d5fd30b83f8a",
            ic_lat: -201916,
            ic_lon: -4771,
            ic_city: "Buritizal",
            ic_states: "SÃO PAULO",
            ic_january: 5.663,
            ic_february: 5.988,
            ic_march: 5.195,
            ic_april: 5.013,
            ic_may: 4.309,
            ic_june: 4.098,
            ic_july: 4.338,
            ic_august: 5.241,
            ic_september: 5.167,
            ic_october: 5.584,
            ic_november: 5.723,
            ic_december: 6.019,
            ic_yearly: 5.195,
          },
          {
            ic_uuid: "149b9f86-6172-46a4-9c2e-e1901d8bc2c2",
            ic_lat: -17351,
            ic_lon: -44962,
            ic_city: "Buritizeiro",
            ic_states: "MINAS GERAIS",
            ic_january: 6.286,
            ic_february: 6.542,
            ic_march: 5.575,
            ic_april: 5.466,
            ic_may: 4.859,
            ic_june: 4.5,
            ic_july: 4.924,
            ic_august: 5.649,
            ic_september: 6.069,
            ic_october: 6.065,
            ic_november: 5.63,
            ic_december: 5.997,
            ic_yearly: 5.63,
          },
          {
            ic_uuid: "ef3ce6ea-ed7c-4163-9b14-8cd3c2778866",
            ic_lat: -301184,
            ic_lon: -519605,
            ic_city: "Butiá",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.418,
            ic_february: 5.859,
            ic_march: 4.865,
            ic_april: 3.794,
            ic_may: 2.767,
            ic_june: 2.318,
            ic_july: 2.529,
            ic_august: 3.211,
            ic_september: 3.658,
            ic_october: 4.849,
            ic_november: 6.254,
            ic_december: 6.721,
            ic_yearly: 4.437,
          },
          {
            ic_uuid: "146f5daf-de22-4430-8863-85bba5de25a1",
            ic_lat: -33158,
            ic_lon: -61221,
            ic_city: "Caapiranga",
            ic_states: "AMAZONAS",
            ic_january: 4.071,
            ic_february: 4.213,
            ic_march: 4.186,
            ic_april: 3.986,
            ic_may: 3.843,
            ic_june: 4.357,
            ic_july: 4.477,
            ic_august: 4.958,
            ic_september: 4.882,
            ic_october: 4.844,
            ic_november: 4.53,
            ic_december: 4.188,
            ic_yearly: 4.378,
          },
          {
            ic_uuid: "87f66c02-f0fb-4c32-947a-ca6af1f36895",
            ic_lat: -75139,
            ic_lon: -349058,
            ic_city: "Caaporã",
            ic_states: "PARAÍBA",
            ic_january: 5.746,
            ic_february: 6.018,
            ic_march: 6.004,
            ic_april: 5.306,
            ic_may: 4.648,
            ic_june: 4.264,
            ic_july: 4.333,
            ic_august: 5.109,
            ic_september: 5.549,
            ic_october: 5.871,
            ic_november: 6.031,
            ic_december: 6.045,
            ic_yearly: 5.41,
          },
          {
            ic_uuid: "8e895d43-58eb-4d06-9197-399f263dc538",
            ic_lat: -226372,
            ic_lon: -548214,
            ic_city: "Caarapó",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.899,
            ic_february: 5.726,
            ic_march: 5.31,
            ic_april: 4.478,
            ic_may: 3.635,
            ic_june: 3.313,
            ic_july: 3.428,
            ic_august: 4.421,
            ic_september: 4.682,
            ic_october: 5.343,
            ic_november: 5.946,
            ic_december: 6.373,
            ic_yearly: 4.88,
          },
          {
            ic_uuid: "da5ef3c8-d117-4365-a828-743a0db8a97c",
            ic_lat: -149782,
            ic_lon: -404092,
            ic_city: "Caatiba",
            ic_states: "BAHIA",
            ic_january: 5.947,
            ic_february: 5.998,
            ic_march: 5.433,
            ic_april: 4.547,
            ic_may: 3.879,
            ic_june: 3.439,
            ic_july: 3.644,
            ic_august: 4.188,
            ic_september: 5.088,
            ic_october: 5.277,
            ic_november: 5.305,
            ic_december: 6.074,
            ic_yearly: 4.902,
          },
          {
            ic_uuid: "aa6aa28d-1701-4b90-9f18-f9665c875925",
            ic_lat: -74919,
            ic_lon: -362881,
            ic_city: "Cabaceiras",
            ic_states: "PARAÍBA",
            ic_january: 5.922,
            ic_february: 6.058,
            ic_march: 6.017,
            ic_april: 5.501,
            ic_may: 4.827,
            ic_june: 4.236,
            ic_july: 4.475,
            ic_august: 5.143,
            ic_september: 5.864,
            ic_october: 6.132,
            ic_november: 6.366,
            ic_december: 6.192,
            ic_yearly: 5.561,
          },
          {
            ic_uuid: "a10506ca-23fe-4674-a340-932eaf36fbdb",
            ic_lat: -125321,
            ic_lon: -391906,
            ic_city: "Cabaceiras do Paraguaçu",
            ic_states: "BAHIA",
            ic_january: 6.139,
            ic_february: 5.998,
            ic_march: 5.762,
            ic_april: 4.708,
            ic_may: 4.032,
            ic_june: 3.638,
            ic_july: 3.898,
            ic_august: 4.501,
            ic_september: 5.271,
            ic_october: 5.524,
            ic_november: 5.79,
            ic_december: 6.118,
            ic_yearly: 5.115,
          },
          {
            ic_uuid: "56c10da1-c552-4051-bd1b-b6121b699c7b",
            ic_lat: -16027,
            ic_lon: -470879,
            ic_city: "Cabeceira Grande",
            ic_states: "MINAS GERAIS",
            ic_january: 5.696,
            ic_february: 5.931,
            ic_march: 5.216,
            ic_april: 5.13,
            ic_may: 4.82,
            ic_june: 4.714,
            ic_july: 5.076,
            ic_august: 5.834,
            ic_september: 5.842,
            ic_october: 5.766,
            ic_november: 5.181,
            ic_december: 5.63,
            ic_yearly: 5.403,
          },
          {
            ic_uuid: "5fc1eb0c-d464-4987-8421-6f7e221e2d53",
            ic_lat: -158062,
            ic_lon: -469237,
            ic_city: "Cabeceiras",
            ic_states: "GOIÁS",
            ic_january: 5.816,
            ic_february: 6.122,
            ic_march: 5.376,
            ic_april: 5.255,
            ic_may: 4.972,
            ic_june: 4.823,
            ic_july: 5.099,
            ic_august: 5.844,
            ic_september: 5.95,
            ic_october: 5.852,
            ic_november: 5.261,
            ic_december: 5.758,
            ic_yearly: 5.511,
          },
          {
            ic_uuid: "44afff59-f1b2-488a-a7ad-87bc3c48344b",
            ic_lat: -44726,
            ic_lon: -423064,
            ic_city: "Cabeceiras do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.137,
            ic_february: 5.347,
            ic_march: 5.352,
            ic_april: 5.114,
            ic_may: 5.136,
            ic_june: 5.288,
            ic_july: 5.536,
            ic_august: 6.137,
            ic_september: 6.454,
            ic_october: 6.383,
            ic_november: 6.208,
            ic_december: 5.683,
            ic_yearly: 5.648,
          },
          {
            ic_uuid: "8f48df9a-5daf-43c9-9f76-966a6c2615d7",
            ic_lat: -69877,
            ic_lon: -348287,
            ic_city: "Cabedelo",
            ic_states: "PARAÍBA",
            ic_january: 5.931,
            ic_february: 6.069,
            ic_march: 6.099,
            ic_april: 5.484,
            ic_may: 4.919,
            ic_june: 4.446,
            ic_july: 4.567,
            ic_august: 5.389,
            ic_september: 5.89,
            ic_october: 6.173,
            ic_november: 6.302,
            ic_december: 6.162,
            ic_yearly: 5.619,
          },
          {
            ic_uuid: "dab22439-ee65-44d0-a00e-6fa5cef46c59",
            ic_lat: -134951,
            ic_lon: -605446,
            ic_city: "Cabixi",
            ic_states: "RONDÔNIA",
            ic_january: 4.848,
            ic_february: 4.636,
            ic_march: 4.693,
            ic_april: 4.769,
            ic_may: 4.243,
            ic_june: 4.329,
            ic_july: 4.52,
            ic_august: 4.991,
            ic_september: 4.941,
            ic_october: 5.249,
            ic_november: 5.212,
            ic_december: 5.11,
            ic_yearly: 4.795,
          },
          {
            ic_uuid: "026528d0-d4bf-467c-80dd-90982b07c817",
            ic_lat: -82826,
            ic_lon: -350256,
            ic_city: "Cabo de Santo Agostinho",
            ic_states: "PERNAMBUCO",
            ic_january: 5.693,
            ic_february: 5.811,
            ic_march: 5.811,
            ic_april: 5.096,
            ic_may: 4.378,
            ic_june: 4.088,
            ic_july: 4.171,
            ic_august: 4.849,
            ic_september: 5.419,
            ic_october: 5.729,
            ic_november: 5.969,
            ic_december: 6.015,
            ic_yearly: 5.252,
          },
          {
            ic_uuid: "b5cab92c-c0cd-423c-9969-1b3812497c93",
            ic_lat: -228899,
            ic_lon: -42029,
            ic_city: "Cabo Frio",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.359,
            ic_february: 6.498,
            ic_march: 5.252,
            ic_april: 4.526,
            ic_may: 3.695,
            ic_june: 3.362,
            ic_july: 3.428,
            ic_august: 4.301,
            ic_september: 4.614,
            ic_october: 5.291,
            ic_november: 5.475,
            ic_december: 6.248,
            ic_yearly: 4.921,
          },
          {
            ic_uuid: "f7465ff2-7a96-47f0-9a6b-873601e7bd61",
            ic_lat: -214704,
            ic_lon: -463922,
            ic_city: "Cabo Verde",
            ic_states: "MINAS GERAIS",
            ic_january: 5.406,
            ic_february: 5.634,
            ic_march: 4.782,
            ic_april: 4.568,
            ic_may: 3.956,
            ic_june: 3.733,
            ic_july: 4.022,
            ic_august: 4.963,
            ic_september: 5.051,
            ic_october: 5.35,
            ic_november: 5.21,
            ic_december: 5.482,
            ic_yearly: 4.846,
          },
          {
            ic_uuid: "4118b30e-e34b-4889-9494-78d7d2e61cb0",
            ic_lat: -22458,
            ic_lon: -493398,
            ic_city: "Cabrália Paulista",
            ic_states: "SÃO PAULO",
            ic_january: 5.653,
            ic_february: 5.82,
            ic_march: 5.191,
            ic_april: 4.695,
            ic_may: 3.797,
            ic_june: 3.524,
            ic_july: 3.744,
            ic_august: 4.691,
            ic_september: 4.771,
            ic_october: 5.501,
            ic_november: 5.846,
            ic_december: 6.15,
            ic_yearly: 4.949,
          },
          {
            ic_uuid: "6be65444-d007-4841-9ccd-edcd1f6db2ac",
            ic_lat: -233058,
            ic_lon: -471367,
            ic_city: "Cabreúva",
            ic_states: "SÃO PAULO",
            ic_january: 5.507,
            ic_february: 5.751,
            ic_march: 5.057,
            ic_april: 4.555,
            ic_may: 3.709,
            ic_june: 3.399,
            ic_july: 3.545,
            ic_august: 4.54,
            ic_september: 4.697,
            ic_october: 5.299,
            ic_november: 5.682,
            ic_december: 6.113,
            ic_yearly: 4.821,
          },
          {
            ic_uuid: "97eaed8e-abaa-418f-91f1-42f5fccd9735",
            ic_lat: -85059,
            ic_lon: -393097,
            ic_city: "Cabrobó",
            ic_states: "PERNAMBUCO",
            ic_january: 6.268,
            ic_february: 6.081,
            ic_march: 6.139,
            ic_april: 5.512,
            ic_may: 4.837,
            ic_june: 4.456,
            ic_july: 4.722,
            ic_august: 5.457,
            ic_september: 6.268,
            ic_october: 6.459,
            ic_november: 6.685,
            ic_december: 6.463,
            ic_yearly: 5.779,
          },
          {
            ic_uuid: "05760c22-3b42-48a6-84dd-66079575d10c",
            ic_lat: -267761,
            ic_lon: -510124,
            ic_city: "Caçador",
            ic_states: "SANTA CATARINA",
            ic_january: 5.472,
            ic_february: 5.151,
            ic_march: 4.694,
            ic_april: 3.811,
            ic_may: 2.97,
            ic_june: 2.634,
            ic_july: 2.867,
            ic_august: 3.781,
            ic_september: 3.895,
            ic_october: 4.624,
            ic_november: 5.665,
            ic_december: 5.862,
            ic_yearly: 4.285,
          },
          {
            ic_uuid: "70ee6602-5110-4e54-be4b-e72a4ff4bcb8",
            ic_lat: -230997,
            ic_lon: -45708,
            ic_city: "Caçapava",
            ic_states: "SÃO PAULO",
            ic_january: 5.371,
            ic_february: 5.636,
            ic_march: 4.898,
            ic_april: 4.396,
            ic_may: 3.544,
            ic_june: 3.315,
            ic_july: 3.428,
            ic_august: 4.377,
            ic_september: 4.463,
            ic_october: 4.999,
            ic_november: 5.216,
            ic_december: 5.738,
            ic_yearly: 4.615,
          },
          {
            ic_uuid: "52260612-2563-4668-a442-fc1eade80460",
            ic_lat: -305148,
            ic_lon: -534832,
            ic_city: "Caçapava do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.454,
            ic_february: 5.9,
            ic_march: 5.006,
            ic_april: 3.861,
            ic_may: 2.794,
            ic_june: 2.326,
            ic_july: 2.569,
            ic_august: 3.289,
            ic_september: 3.81,
            ic_october: 5.042,
            ic_november: 6.324,
            ic_december: 6.883,
            ic_yearly: 4.521,
          },
          {
            ic_uuid: "62712de1-057c-4823-8b7c-e1a886b2eaeb",
            ic_lat: -103387,
            ic_lon: -629003,
            ic_city: "Cacaulândia",
            ic_states: "RONDÔNIA",
            ic_january: 4.27,
            ic_february: 4.269,
            ic_march: 4.389,
            ic_april: 4.497,
            ic_may: 4.036,
            ic_june: 4.362,
            ic_july: 4.448,
            ic_august: 4.891,
            ic_september: 4.887,
            ic_october: 5.04,
            ic_november: 4.804,
            ic_december: 4.554,
            ic_yearly: 4.537,
          },
          {
            ic_uuid: "36f511f5-e658-4218-a1b6-cf310f3c6c3a",
            ic_lat: -298839,
            ic_lon: -548249,
            ic_city: "Cacequi",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.654,
            ic_february: 6.072,
            ic_march: 5.155,
            ic_april: 3.95,
            ic_may: 2.9,
            ic_june: 2.395,
            ic_july: 2.642,
            ic_august: 3.371,
            ic_september: 3.934,
            ic_october: 5.153,
            ic_november: 6.373,
            ic_december: 6.998,
            ic_yearly: 4.633,
          },
          {
            ic_uuid: "f3e986c1-d510-4134-afde-878bd9006417",
            ic_lat: -160768,
            ic_lon: -576822,
            ic_city: "Cáceres",
            ic_states: "MATO GROSSO",
            ic_january: 5.558,
            ic_february: 5.383,
            ic_march: 5.17,
            ic_april: 4.836,
            ic_may: 4.238,
            ic_june: 4.236,
            ic_july: 4.356,
            ic_august: 5.159,
            ic_september: 5.137,
            ic_october: 5.37,
            ic_november: 5.592,
            ic_december: 5.718,
            ic_yearly: 5.063,
          },
          {
            ic_uuid: "af7ca288-c947-4e14-a96d-5e83481f7805",
            ic_lat: -125999,
            ic_lon: -38959,
            ic_city: "Cachoeira",
            ic_states: "BAHIA",
            ic_january: 6.102,
            ic_february: 5.979,
            ic_march: 5.75,
            ic_april: 4.672,
            ic_may: 4.007,
            ic_june: 3.635,
            ic_july: 3.937,
            ic_august: 4.446,
            ic_september: 5.297,
            ic_october: 5.553,
            ic_november: 5.796,
            ic_december: 6.091,
            ic_yearly: 5.106,
          },
          {
            ic_uuid: "aa66f9b2-a11b-4467-9239-e7a2dd3a3de6",
            ic_lat: -187623,
            ic_lon: -509437,
            ic_city: "Cachoeira Alta",
            ic_states: "GOIÁS",
            ic_january: 5.762,
            ic_february: 5.796,
            ic_march: 5.321,
            ic_april: 5.062,
            ic_may: 4.499,
            ic_june: 4.177,
            ic_july: 4.437,
            ic_august: 5.331,
            ic_september: 5.201,
            ic_october: 5.552,
            ic_november: 5.751,
            ic_december: 5.967,
            ic_yearly: 5.238,
          },
          {
            ic_uuid: "fbfef3be-7a0c-4bc2-ab09-d0ee7ff0f77e",
            ic_lat: -19525,
            ic_lon: -44454,
            ic_city: "Cachoeira da Prata",
            ic_states: "MINAS GERAIS",
            ic_january: 6.014,
            ic_february: 6.13,
            ic_march: 5.236,
            ic_april: 4.925,
            ic_may: 4.388,
            ic_june: 4.158,
            ic_july: 4.475,
            ic_august: 5.327,
            ic_september: 5.621,
            ic_october: 5.637,
            ic_november: 5.405,
            ic_december: 5.742,
            ic_yearly: 5.255,
          },
          {
            ic_uuid: "d26132a4-0106-47ef-80df-c1d2a0be9ff4",
            ic_lat: -166669,
            ic_lon: -506436,
            ic_city: "Cachoeira de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.497,
            ic_february: 5.575,
            ic_march: 5.217,
            ic_april: 5.163,
            ic_may: 4.79,
            ic_june: 4.526,
            ic_july: 4.68,
            ic_august: 5.447,
            ic_september: 5.365,
            ic_october: 5.528,
            ic_november: 5.478,
            ic_december: 5.561,
            ic_yearly: 5.236,
          },
          {
            ic_uuid: "59fb063b-68ef-4ba1-9045-fa687877c416",
            ic_lat: -223516,
            ic_lon: -457813,
            ic_city: "Cachoeira de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.401,
            ic_february: 5.656,
            ic_march: 4.914,
            ic_april: 4.594,
            ic_may: 3.858,
            ic_june: 3.614,
            ic_july: 3.819,
            ic_august: 4.681,
            ic_september: 4.925,
            ic_october: 5.394,
            ic_november: 5.437,
            ic_december: 5.77,
            ic_yearly: 4.839,
          },
          {
            ic_uuid: "f3357b5f-0282-4979-b6d8-35cd9d22af3e",
            ic_lat: -159693,
            ic_lon: -414951,
            ic_city: "Cachoeira de Pajeú",
            ic_states: "MINAS GERAIS",
            ic_january: 6.058,
            ic_february: 6.289,
            ic_march: 5.402,
            ic_april: 4.747,
            ic_may: 4.062,
            ic_june: 3.653,
            ic_july: 3.837,
            ic_august: 4.557,
            ic_september: 5.414,
            ic_october: 5.521,
            ic_november: 5.278,
            ic_december: 6.067,
            ic_yearly: 5.074,
          },
          {
            ic_uuid: "719f0b7a-1eb8-4e0d-9525-580bc9573b1a",
            ic_lat: -10111,
            ic_lon: -489603,
            ic_city: "Cachoeira do Arari",
            ic_states: "PARÁ",
            ic_january: 4.445,
            ic_february: 4.294,
            ic_march: 4.358,
            ic_april: 4.433,
            ic_may: 4.825,
            ic_june: 4.932,
            ic_july: 5.063,
            ic_august: 5.235,
            ic_september: 5.46,
            ic_october: 5.207,
            ic_november: 5.076,
            ic_december: 4.656,
            ic_yearly: 4.832,
          },
          {
            ic_uuid: "2d4ba1d1-7d57-4977-a918-01d128821cdd",
            ic_lat: -17601,
            ic_lon: -465463,
            ic_city: "Cachoeira do Piriá",
            ic_states: "PARÁ",
            ic_january: 4.482,
            ic_february: 4.344,
            ic_march: 4.325,
            ic_april: 4.409,
            ic_may: 4.562,
            ic_june: 4.789,
            ic_july: 4.95,
            ic_august: 5.326,
            ic_september: 5.515,
            ic_october: 5.221,
            ic_november: 5.001,
            ic_december: 4.583,
            ic_yearly: 4.792,
          },
          {
            ic_uuid: "4dec7d18-9799-42a9-814f-15c28db0e90a",
            ic_lat: -30039,
            ic_lon: -528939,
            ic_city: "Cachoeira do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.496,
            ic_february: 5.936,
            ic_march: 4.992,
            ic_april: 3.819,
            ic_may: 2.786,
            ic_june: 2.302,
            ic_july: 2.5,
            ic_august: 3.253,
            ic_september: 3.716,
            ic_october: 4.876,
            ic_november: 6.24,
            ic_december: 6.775,
            ic_yearly: 4.474,
          },
          {
            ic_uuid: "8ab47f0b-31d6-4de6-aac0-77e9cc34b7a7",
            ic_lat: -69189,
            ic_lon: -386786,
            ic_city: "Cachoeira dos Índios",
            ic_states: "PARAÍBA",
            ic_january: 6.117,
            ic_february: 6.108,
            ic_march: 6.053,
            ic_april: 5.773,
            ic_may: 5.308,
            ic_june: 5.063,
            ic_july: 5.327,
            ic_august: 6.055,
            ic_september: 6.507,
            ic_october: 6.584,
            ic_november: 6.766,
            ic_december: 6.376,
            ic_yearly: 6.003,
          },
          {
            ic_uuid: "79c7de5d-37e5-4d14-b3e9-69e97213bb62",
            ic_lat: -184863,
            ic_lon: -49477,
            ic_city: "Cachoeira Dourada",
            ic_states: "GOIÁS",
            ic_january: 5.619,
            ic_february: 5.757,
            ic_march: 5.188,
            ic_april: 5.071,
            ic_may: 4.572,
            ic_june: 4.326,
            ic_july: 4.528,
            ic_august: 5.43,
            ic_september: 5.216,
            ic_october: 5.551,
            ic_november: 5.696,
            ic_december: 5.811,
            ic_yearly: 5.23,
          },
          {
            ic_uuid: "f856a571-d700-40a3-aa37-a86bbe31fda8",
            ic_lat: -185166,
            ic_lon: -495043,
            ic_city: "Cachoeira Dourada",
            ic_states: "MINAS GERAIS",
            ic_january: 5.618,
            ic_february: 5.761,
            ic_march: 5.236,
            ic_april: 5.067,
            ic_may: 4.604,
            ic_june: 4.302,
            ic_july: 4.548,
            ic_august: 5.456,
            ic_september: 5.251,
            ic_october: 5.568,
            ic_november: 5.706,
            ic_december: 5.789,
            ic_yearly: 5.242,
          },
          {
            ic_uuid: "0dddaab9-85dc-404d-8847-1c9cf6111bf1",
            ic_lat: -29311,
            ic_lon: -440531,
            ic_city: "Cachoeira Grande",
            ic_states: "MARANHÃO",
            ic_january: 4.479,
            ic_february: 4.515,
            ic_march: 4.495,
            ic_april: 4.441,
            ic_may: 4.587,
            ic_june: 4.903,
            ic_july: 5.049,
            ic_august: 5.579,
            ic_september: 5.935,
            ic_october: 5.685,
            ic_november: 5.308,
            ic_december: 4.857,
            ic_yearly: 4.986,
          },
          {
            ic_uuid: "d0ca8492-6139-4287-bb7c-2d01245597e4",
            ic_lat: -22667,
            ic_lon: -450158,
            ic_city: "Cachoeira Paulista",
            ic_states: "SÃO PAULO",
            ic_january: 5.479,
            ic_february: 5.726,
            ic_march: 4.857,
            ic_april: 4.425,
            ic_may: 3.669,
            ic_june: 3.457,
            ic_july: 3.568,
            ic_august: 4.537,
            ic_september: 4.649,
            ic_october: 5.113,
            ic_november: 5.139,
            ic_december: 5.749,
            ic_yearly: 4.698,
          },
          {
            ic_uuid: "b5b78fa5-bb92-44b1-8f86-8ff39b7785be",
            ic_lat: -224663,
            ic_lon: -426527,
            ic_city: "Cachoeiras de Macacu",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.672,
            ic_february: 6.004,
            ic_march: 4.828,
            ic_april: 4.331,
            ic_may: 3.576,
            ic_june: 3.465,
            ic_july: 3.449,
            ic_august: 4.265,
            ic_september: 4.442,
            ic_october: 4.775,
            ic_november: 4.645,
            ic_december: 5.382,
            ic_yearly: 4.569,
          },
          {
            ic_uuid: "bce0de6d-f51e-43a4-964a-517327e53ddf",
            ic_lat: -84884,
            ic_lon: -36237,
            ic_city: "Cachoeirinha",
            ic_states: "PERNAMBUCO",
            ic_january: 5.929,
            ic_february: 5.795,
            ic_march: 5.853,
            ic_april: 5.274,
            ic_may: 4.395,
            ic_june: 3.917,
            ic_july: 4.006,
            ic_august: 4.68,
            ic_september: 5.424,
            ic_october: 5.841,
            ic_november: 6.361,
            ic_december: 6.163,
            ic_yearly: 5.303,
          },
          {
            ic_uuid: "32bb811d-58a7-438f-97a4-6b56bca03509",
            ic_lat: -299477,
            ic_lon: -511021,
            ic_city: "Cachoeirinha",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.34,
            ic_february: 5.783,
            ic_march: 4.815,
            ic_april: 3.789,
            ic_may: 2.781,
            ic_june: 2.315,
            ic_july: 2.515,
            ic_august: 3.195,
            ic_september: 3.577,
            ic_october: 4.73,
            ic_november: 6.185,
            ic_december: 6.627,
            ic_yearly: 4.388,
          },
          {
            ic_uuid: "32b7815d-d641-4470-bdf9-82b1b5f6dd73",
            ic_lat: -6118,
            ic_lon: -479206,
            ic_city: "Cachoeirinha",
            ic_states: "TOCANTINS",
            ic_january: 4.613,
            ic_february: 4.746,
            ic_march: 4.72,
            ic_april: 4.732,
            ic_may: 4.817,
            ic_june: 5.107,
            ic_july: 5.296,
            ic_august: 5.892,
            ic_september: 5.661,
            ic_october: 5.096,
            ic_november: 4.774,
            ic_december: 4.641,
            ic_yearly: 5.008,
          },
          {
            ic_uuid: "7f781e63-c176-4046-a2c6-2766b883b467",
            ic_lat: -208467,
            ic_lon: -411202,
            ic_city: "Cachoeiro de Itapemirim",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.355,
            ic_february: 6.508,
            ic_march: 5.328,
            ic_april: 4.629,
            ic_may: 3.945,
            ic_june: 3.728,
            ic_july: 3.775,
            ic_august: 4.521,
            ic_september: 5.001,
            ic_october: 5.233,
            ic_november: 5.156,
            ic_december: 5.982,
            ic_yearly: 5.013,
          },
          {
            ic_uuid: "fc38b018-6f31-461a-ad5b-4dc1561b4945",
            ic_lat: -71288,
            ic_lon: -371566,
            ic_city: "Cacimba de Areia",
            ic_states: "PARAÍBA",
            ic_january: 6.256,
            ic_february: 6.271,
            ic_march: 6.257,
            ic_april: 5.894,
            ic_may: 5.171,
            ic_june: 4.692,
            ic_july: 5.023,
            ic_august: 5.806,
            ic_september: 6.451,
            ic_october: 6.593,
            ic_november: 6.657,
            ic_december: 6.328,
            ic_yearly: 5.95,
          },
          {
            ic_uuid: "24d69124-06cd-4e10-9e01-ebb6a012dee0",
            ic_lat: -66399,
            ic_lon: -357935,
            ic_city: "Cacimba de Dentro",
            ic_states: "PARAÍBA",
            ic_january: 5.682,
            ic_february: 5.716,
            ic_march: 5.813,
            ic_april: 5.277,
            ic_may: 4.737,
            ic_june: 4.201,
            ic_july: 4.356,
            ic_august: 5.069,
            ic_september: 5.55,
            ic_october: 5.798,
            ic_november: 5.98,
            ic_december: 5.733,
            ic_yearly: 5.326,
          },
          {
            ic_uuid: "03341f93-c264-42e1-9c54-84423a8c947d",
            ic_lat: -72116,
            ic_lon: -370574,
            ic_city: "Cacimbas",
            ic_states: "PARAÍBA",
            ic_january: 6.06,
            ic_february: 6.011,
            ic_march: 6.039,
            ic_april: 5.681,
            ic_may: 5.048,
            ic_june: 4.59,
            ic_july: 4.9,
            ic_august: 5.711,
            ic_september: 6.336,
            ic_october: 6.515,
            ic_november: 6.595,
            ic_december: 6.234,
            ic_yearly: 5.81,
          },
          {
            ic_uuid: "ac0fdca2-cc63-4582-8086-f5398a0f9cdc",
            ic_lat: -94016,
            ic_lon: -369914,
            ic_city: "Cacimbinhas",
            ic_states: "ALAGOAS",
            ic_january: 6.101,
            ic_february: 5.881,
            ic_march: 5.913,
            ic_april: 5.183,
            ic_may: 4.355,
            ic_june: 3.949,
            ic_july: 4.113,
            ic_august: 4.707,
            ic_september: 5.57,
            ic_october: 5.929,
            ic_november: 6.472,
            ic_december: 6.399,
            ic_yearly: 5.381,
          },
          {
            ic_uuid: "f0ca7cd0-1e8f-47f3-a353-437b3bf0afea",
            ic_lat: -277675,
            ic_lon: -516601,
            ic_city: "Cacique Doble",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.298,
            ic_february: 5.779,
            ic_march: 4.982,
            ic_april: 4.06,
            ic_may: 3.04,
            ic_june: 2.609,
            ic_july: 2.92,
            ic_august: 3.713,
            ic_september: 3.923,
            ic_october: 5.038,
            ic_november: 6.228,
            ic_december: 6.528,
            ic_yearly: 4.593,
          },
          {
            ic_uuid: "d9f47be4-a0bc-4836-881c-e6307e589572",
            ic_lat: -114347,
            ic_lon: -614566,
            ic_city: "Cacoal",
            ic_states: "RONDÔNIA",
            ic_january: 4.339,
            ic_february: 4.362,
            ic_march: 4.476,
            ic_april: 4.518,
            ic_may: 4.143,
            ic_june: 4.385,
            ic_july: 4.538,
            ic_august: 4.962,
            ic_september: 4.923,
            ic_october: 5.109,
            ic_november: 4.973,
            ic_december: 4.739,
            ic_yearly: 4.622,
          },
          {
            ic_uuid: "46fb8230-30a0-49b6-953a-bfc7fd89d31b",
            ic_lat: -215285,
            ic_lon: -466441,
            ic_city: "Caconde",
            ic_states: "SÃO PAULO",
            ic_january: 5.509,
            ic_february: 5.66,
            ic_march: 4.887,
            ic_april: 4.739,
            ic_may: 4.026,
            ic_june: 3.754,
            ic_july: 4.05,
            ic_august: 4.902,
            ic_september: 5.045,
            ic_october: 5.444,
            ic_november: 5.491,
            ic_december: 5.649,
            ic_yearly: 4.93,
          },
          {
            ic_uuid: "07af67b9-ec7e-419c-9faf-5e9b0cf14cf1",
            ic_lat: -185598,
            ic_lon: -511332,
            ic_city: "Caçu",
            ic_states: "GOIÁS",
            ic_january: 5.685,
            ic_february: 5.748,
            ic_march: 5.362,
            ic_april: 5.087,
            ic_may: 4.52,
            ic_june: 4.197,
            ic_july: 4.485,
            ic_august: 5.398,
            ic_september: 5.211,
            ic_october: 5.515,
            ic_november: 5.777,
            ic_december: 5.895,
            ic_yearly: 5.24,
          },
          {
            ic_uuid: "cbce20ac-39f9-4b70-9be6-250e44e21ecc",
            ic_lat: -145007,
            ic_lon: -422232,
            ic_city: "Caculé",
            ic_states: "BAHIA",
            ic_january: 6.303,
            ic_february: 6.417,
            ic_march: 5.68,
            ic_april: 4.947,
            ic_may: 4.394,
            ic_june: 4.042,
            ic_july: 4.375,
            ic_august: 5.086,
            ic_september: 5.787,
            ic_october: 6.105,
            ic_november: 5.727,
            ic_december: 6.336,
            ic_yearly: 5.433,
          },
          {
            ic_uuid: "09ca9f18-640f-47e0-9eb6-0e1559cfa521",
            ic_lat: -110681,
            ic_lon: -404324,
            ic_city: "Caém",
            ic_states: "BAHIA",
            ic_january: 6.061,
            ic_february: 5.841,
            ic_march: 5.687,
            ic_april: 4.697,
            ic_may: 3.948,
            ic_june: 3.637,
            ic_july: 3.868,
            ic_august: 4.567,
            ic_september: 5.471,
            ic_october: 5.562,
            ic_november: 5.754,
            ic_december: 5.864,
            ic_yearly: 5.079,
          },
          {
            ic_uuid: "7e9b92e3-9f7c-463c-9400-7b4a58516dbb",
            ic_lat: -192975,
            ic_lon: -444192,
            ic_city: "Caetanópolis",
            ic_states: "MINAS GERAIS",
            ic_january: 6.002,
            ic_february: 6.177,
            ic_march: 5.272,
            ic_april: 5.019,
            ic_may: 4.425,
            ic_june: 4.166,
            ic_july: 4.524,
            ic_august: 5.335,
            ic_september: 5.684,
            ic_october: 5.686,
            ic_november: 5.458,
            ic_december: 5.779,
            ic_yearly: 5.294,
          },
          {
            ic_uuid: "7531e360-815d-4439-98ad-ceb813995746",
            ic_lat: -143389,
            ic_lon: -4091,
            ic_city: "Caetanos",
            ic_states: "BAHIA",
            ic_january: 6.16,
            ic_february: 6.154,
            ic_march: 5.663,
            ic_april: 4.849,
            ic_may: 4.282,
            ic_june: 3.835,
            ic_july: 4.03,
            ic_august: 4.636,
            ic_september: 5.518,
            ic_october: 5.744,
            ic_november: 5.711,
            ic_december: 6.245,
            ic_yearly: 5.236,
          },
          {
            ic_uuid: "f048f40e-a080-428f-8f81-e3f00142fdd0",
            ic_lat: -198831,
            ic_lon: -436707,
            ic_city: "Caeté",
            ic_states: "MINAS GERAIS",
            ic_january: 5.597,
            ic_february: 5.893,
            ic_march: 5.066,
            ic_april: 4.645,
            ic_may: 4.093,
            ic_june: 3.953,
            ic_july: 4.147,
            ic_august: 5.002,
            ic_september: 5.318,
            ic_october: 5.433,
            ic_november: 5.093,
            ic_december: 5.534,
            ic_yearly: 4.981,
          },
          {
            ic_uuid: "dc5c6cd9-dfb2-4b4e-8b3e-fca0bd843540",
            ic_lat: -87725,
            ic_lon: -366263,
            ic_city: "Caetés",
            ic_states: "PERNAMBUCO",
            ic_january: 6.165,
            ic_february: 5.947,
            ic_march: 5.986,
            ic_april: 5.328,
            ic_may: 4.379,
            ic_june: 3.918,
            ic_july: 4.122,
            ic_august: 4.804,
            ic_september: 5.691,
            ic_october: 6.049,
            ic_november: 6.501,
            ic_december: 6.411,
            ic_yearly: 5.442,
          },
          {
            ic_uuid: "97cd6529-dc59-4345-a3a6-15bab68b20d4",
            ic_lat: -140689,
            ic_lon: -424865,
            ic_city: "Caetité",
            ic_states: "BAHIA",
            ic_january: 6.361,
            ic_february: 6.488,
            ic_march: 5.76,
            ic_april: 5.156,
            ic_may: 4.561,
            ic_june: 4.331,
            ic_july: 4.651,
            ic_august: 5.41,
            ic_september: 5.992,
            ic_october: 6.288,
            ic_november: 5.846,
            ic_december: 6.374,
            ic_yearly: 5.601,
          },
          {
            ic_uuid: "7b4a444e-4e95-4870-bcb9-1b46b24ee904",
            ic_lat: -116918,
            ic_lon: -414692,
            ic_city: "Cafarnaum",
            ic_states: "BAHIA",
            ic_january: 6.361,
            ic_february: 6.155,
            ic_march: 5.91,
            ic_april: 5.02,
            ic_may: 4.496,
            ic_june: 4.306,
            ic_july: 4.47,
            ic_august: 5.216,
            ic_september: 6.083,
            ic_october: 6.348,
            ic_november: 6.215,
            ic_december: 6.434,
            ic_yearly: 5.585,
          },
          {
            ic_uuid: "3c5f5cdc-4831-4036-b97f-0f25d5603398",
            ic_lat: -227895,
            ic_lon: -517146,
            ic_city: "Cafeara",
            ic_states: "PARANÁ",
            ic_january: 5.985,
            ic_february: 6.028,
            ic_march: 5.495,
            ic_april: 4.739,
            ic_may: 3.758,
            ic_june: 3.456,
            ic_july: 3.67,
            ic_august: 4.606,
            ic_september: 4.809,
            ic_october: 5.526,
            ic_november: 6.19,
            ic_december: 6.479,
            ic_yearly: 5.062,
          },
          {
            ic_uuid: "ceee5afc-aadf-40d7-a9c9-17aad4e38843",
            ic_lat: -246193,
            ic_lon: -533212,
            ic_city: "Cafelândia",
            ic_states: "PARANÁ",
            ic_january: 6.309,
            ic_february: 5.606,
            ic_march: 5.239,
            ic_april: 4.377,
            ic_may: 3.442,
            ic_june: 3.059,
            ic_july: 3.258,
            ic_august: 4.213,
            ic_september: 4.492,
            ic_october: 5.281,
            ic_november: 6.061,
            ic_december: 6.467,
            ic_yearly: 4.817,
          },
          {
            ic_uuid: "0256da10-3c3e-4a5a-8aff-caf45e568b7a",
            ic_lat: -218036,
            ic_lon: -496096,
            ic_city: "Cafelândia",
            ic_states: "SÃO PAULO",
            ic_january: 5.783,
            ic_february: 5.918,
            ic_march: 5.293,
            ic_april: 4.798,
            ic_may: 3.916,
            ic_june: 3.649,
            ic_july: 3.872,
            ic_august: 4.793,
            ic_september: 4.931,
            ic_october: 5.603,
            ic_november: 5.932,
            ic_december: 6.2,
            ic_yearly: 5.057,
          },
          {
            ic_uuid: "f25e1a77-f5ef-4cf9-8dac-7827c075202b",
            ic_lat: -23901,
            ic_lon: -535129,
            ic_city: "Cafezal do Sul",
            ic_states: "PARANÁ",
            ic_january: 6.149,
            ic_february: 5.83,
            ic_march: 5.337,
            ic_april: 4.454,
            ic_may: 3.525,
            ic_june: 3.109,
            ic_july: 3.323,
            ic_august: 4.323,
            ic_september: 4.552,
            ic_october: 5.357,
            ic_november: 6.158,
            ic_december: 6.474,
            ic_yearly: 4.882,
          },
          {
            ic_uuid: "2ab68b42-1c83-48b9-8360-0575cb967264",
            ic_lat: -22012,
            ic_lon: -51236,
            ic_city: "Caiabu",
            ic_states: "SÃO PAULO",
            ic_january: 6.026,
            ic_february: 6.016,
            ic_march: 5.489,
            ic_april: 4.849,
            ic_may: 3.96,
            ic_june: 3.639,
            ic_july: 3.826,
            ic_august: 4.815,
            ic_september: 4.852,
            ic_october: 5.549,
            ic_november: 6.103,
            ic_december: 6.455,
            ic_yearly: 5.132,
          },
          {
            ic_uuid: "ab244ba7-0e31-4bd6-978d-7518b775a666",
            ic_lat: -206961,
            ic_lon: -419296,
            ic_city: "Caiana",
            ic_states: "MINAS GERAIS",
            ic_january: 5.869,
            ic_february: 6.073,
            ic_march: 4.953,
            ic_april: 4.425,
            ic_may: 3.811,
            ic_june: 3.715,
            ic_july: 3.837,
            ic_august: 4.596,
            ic_september: 4.964,
            ic_october: 5.084,
            ic_november: 4.786,
            ic_december: 5.597,
            ic_yearly: 4.809,
          },
          {
            ic_uuid: "37a600f7-f212-416b-98ee-cba1ae871857",
            ic_lat: -169543,
            ic_lon: -518095,
            ic_city: "Caiapônia",
            ic_states: "GOIÁS",
            ic_january: 5.497,
            ic_february: 5.557,
            ic_march: 5.177,
            ic_april: 5.11,
            ic_may: 4.751,
            ic_june: 4.392,
            ic_july: 4.591,
            ic_august: 5.456,
            ic_september: 5.303,
            ic_october: 5.317,
            ic_november: 5.355,
            ic_december: 5.577,
            ic_yearly: 5.174,
          },
          {
            ic_uuid: "d43d5cd3-3966-4592-8fe1-87d1b7c53318",
            ic_lat: -28288,
            ic_lon: -546379,
            ic_city: "Caibaté",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.559,
            ic_february: 6.126,
            ic_march: 5.221,
            ic_april: 4.07,
            ic_may: 3.082,
            ic_june: 2.521,
            ic_july: 2.804,
            ic_august: 3.589,
            ic_september: 4.037,
            ic_october: 5.153,
            ic_november: 6.352,
            ic_december: 6.759,
            ic_yearly: 4.689,
          },
          {
            ic_uuid: "bab36280-fb27-4926-842c-82602861a24d",
            ic_lat: -270746,
            ic_lon: -532462,
            ic_city: "Caibi",
            ic_states: "SANTA CATARINA",
            ic_january: 6.298,
            ic_february: 5.85,
            ic_march: 5.135,
            ic_april: 4.056,
            ic_may: 3.09,
            ic_june: 2.57,
            ic_july: 2.907,
            ic_august: 3.763,
            ic_september: 4.004,
            ic_october: 5.133,
            ic_november: 6.143,
            ic_december: 6.53,
            ic_yearly: 4.623,
          },
          {
            ic_uuid: "ad567589-ab40-43fc-83ef-636a8f082b09",
            ic_lat: -66216,
            ic_lon: -354584,
            ic_city: "Caiçara",
            ic_states: "PARAÍBA",
            ic_january: 5.752,
            ic_february: 5.783,
            ic_march: 5.879,
            ic_april: 5.351,
            ic_may: 4.851,
            ic_june: 4.413,
            ic_july: 4.581,
            ic_august: 5.269,
            ic_september: 5.715,
            ic_october: 5.929,
            ic_november: 6.125,
            ic_december: 5.822,
            ic_yearly: 5.456,
          },
          {
            ic_uuid: "3b22e109-61d3-48fe-b2d9-c12958868ce9",
            ic_lat: -272796,
            ic_lon: -534261,
            ic_city: "Caiçara",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.456,
            ic_february: 5.867,
            ic_march: 5.169,
            ic_april: 4.059,
            ic_may: 3.113,
            ic_june: 2.577,
            ic_july: 2.906,
            ic_august: 3.735,
            ic_september: 3.997,
            ic_october: 5.185,
            ic_november: 6.199,
            ic_december: 6.584,
            ic_yearly: 4.654,
          },
          {
            ic_uuid: "16248dc2-48ca-43c2-888e-e3c8b5d5a89a",
            ic_lat: -50639,
            ic_lon: -360534,
            ic_city: "Caiçara do Norte",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.849,
            ic_february: 5.827,
            ic_march: 5.858,
            ic_april: 5.331,
            ic_may: 5.066,
            ic_june: 4.704,
            ic_july: 4.931,
            ic_august: 5.479,
            ic_september: 5.823,
            ic_october: 6.021,
            ic_november: 6.229,
            ic_december: 5.949,
            ic_yearly: 5.589,
          },
          {
            ic_uuid: "ac234e83-dd0e-4cd5-bdc5-a593e4d2c08a",
            ic_lat: -57575,
            ic_lon: -360011,
            ic_city: "Caiçara do Rio do Vento",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.697,
            ic_february: 5.784,
            ic_march: 5.791,
            ic_april: 5.416,
            ic_may: 5.059,
            ic_june: 4.676,
            ic_july: 4.9,
            ic_august: 5.554,
            ic_september: 5.952,
            ic_october: 6.151,
            ic_november: 6.171,
            ic_december: 5.744,
            ic_yearly: 5.575,
          },
          {
            ic_uuid: "41c174bb-f487-4b49-b9b2-cb77b18e137d",
            ic_lat: -64626,
            ic_lon: -370956,
            ic_city: "Caicó",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.164,
            ic_february: 6.199,
            ic_march: 6.143,
            ic_april: 5.802,
            ic_may: 5.202,
            ic_june: 4.771,
            ic_july: 5.053,
            ic_august: 5.806,
            ic_september: 6.377,
            ic_october: 6.564,
            ic_november: 6.545,
            ic_december: 6.296,
            ic_yearly: 5.91,
          },
          {
            ic_uuid: "13cd3892-1167-43b0-9f9f-2bedfb4dbf44",
            ic_lat: -233612,
            ic_lon: -467401,
            ic_city: "Caieiras",
            ic_states: "SÃO PAULO",
            ic_january: 5.312,
            ic_february: 5.603,
            ic_march: 4.79,
            ic_april: 4.309,
            ic_may: 3.521,
            ic_june: 3.278,
            ic_july: 3.356,
            ic_august: 4.338,
            ic_september: 4.376,
            ic_october: 4.885,
            ic_november: 5.263,
            ic_december: 5.812,
            ic_yearly: 4.57,
          },
          {
            ic_uuid: "aaa7261d-04da-4d18-83c4-c7cfe65f6334",
            ic_lat: -134908,
            ic_lon: -390468,
            ic_city: "Cairu",
            ic_states: "BAHIA",
            ic_january: 6.1,
            ic_february: 6.055,
            ic_march: 5.694,
            ic_april: 4.563,
            ic_may: 3.965,
            ic_june: 3.596,
            ic_july: 3.853,
            ic_august: 4.338,
            ic_september: 5.131,
            ic_october: 5.344,
            ic_november: 5.735,
            ic_december: 6.222,
            ic_yearly: 5.05,
          },
          {
            ic_uuid: "4060e3aa-41ac-45e4-9e19-ea93750b3c1c",
            ic_lat: -218317,
            ic_lon: -519898,
            ic_city: "Caiuá",
            ic_states: "SÃO PAULO",
            ic_january: 6.072,
            ic_february: 6.056,
            ic_march: 5.485,
            ic_april: 4.804,
            ic_may: 4.01,
            ic_june: 3.61,
            ic_july: 3.811,
            ic_august: 4.709,
            ic_september: 4.835,
            ic_october: 5.564,
            ic_november: 6.139,
            ic_december: 6.515,
            ic_yearly: 5.134,
          },
          {
            ic_uuid: "851064df-5dda-4a09-a3b7-7e1c0eba7768",
            ic_lat: -233555,
            ic_lon: -468785,
            ic_city: "Cajamar",
            ic_states: "SÃO PAULO",
            ic_january: 5.271,
            ic_february: 5.554,
            ic_march: 4.781,
            ic_april: 4.287,
            ic_may: 3.51,
            ic_june: 3.269,
            ic_july: 3.361,
            ic_august: 4.337,
            ic_september: 4.339,
            ic_october: 4.852,
            ic_november: 5.222,
            ic_december: 5.765,
            ic_yearly: 4.546,
          },
          {
            ic_uuid: "9e9b4a13-94f5-41b6-81a7-4e6593e6fff4",
            ic_lat: -28737,
            ic_lon: -446745,
            ic_city: "Cajapió",
            ic_states: "MARANHÃO",
            ic_january: 4.85,
            ic_february: 4.871,
            ic_march: 4.764,
            ic_april: 4.695,
            ic_may: 4.756,
            ic_june: 4.995,
            ic_july: 5.177,
            ic_august: 5.801,
            ic_september: 6.097,
            ic_october: 6.051,
            ic_november: 5.823,
            ic_december: 5.376,
            ic_yearly: 5.271,
          },
          {
            ic_uuid: "ac4f8ed2-0150-4f50-b936-a2f6a55138c2",
            ic_lat: -33278,
            ic_lon: -450149,
            ic_city: "Cajari",
            ic_states: "MARANHÃO",
            ic_january: 4.738,
            ic_february: 5.029,
            ic_march: 5.045,
            ic_april: 4.993,
            ic_may: 4.941,
            ic_june: 5.116,
            ic_july: 5.244,
            ic_august: 5.764,
            ic_september: 6.061,
            ic_october: 5.8,
            ic_november: 5.428,
            ic_december: 5.104,
            ic_yearly: 5.272,
          },
          {
            ic_uuid: "a2793cf6-4d19-47e0-bf52-d29c75d176e1",
            ic_lat: -247278,
            ic_lon: -481109,
            ic_city: "Cajati",
            ic_states: "SÃO PAULO",
            ic_january: 5.408,
            ic_february: 5.411,
            ic_march: 4.523,
            ic_april: 3.924,
            ic_may: 3.116,
            ic_june: 2.744,
            ic_july: 2.743,
            ic_august: 3.418,
            ic_september: 3.525,
            ic_october: 3.982,
            ic_november: 4.858,
            ic_december: 5.486,
            ic_yearly: 4.095,
          },
          {
            ic_uuid: "2ea6080f-46b2-480f-ae55-9606724ed576",
            ic_lat: -68805,
            ic_lon: -38558,
            ic_city: "Cajazeiras",
            ic_states: "PARAÍBA",
            ic_january: 6.173,
            ic_february: 6.14,
            ic_march: 6.087,
            ic_april: 5.824,
            ic_may: 5.304,
            ic_june: 5.047,
            ic_july: 5.3,
            ic_august: 6.055,
            ic_september: 6.512,
            ic_october: 6.618,
            ic_november: 6.778,
            ic_december: 6.421,
            ic_yearly: 6.022,
          },
          {
            ic_uuid: "b0dc3136-5823-4daf-81a5-096123ed57bb",
            ic_lat: -67971,
            ic_lon: -423906,
            ic_city: "Cajazeiras do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.306,
            ic_february: 5.411,
            ic_march: 5.297,
            ic_april: 5.271,
            ic_may: 5.347,
            ic_june: 5.261,
            ic_july: 5.615,
            ic_august: 6.141,
            ic_september: 6.524,
            ic_october: 6.443,
            ic_november: 6.242,
            ic_december: 5.741,
            ic_yearly: 5.717,
          },
          {
            ic_uuid: "edfed6d7-7e53-43b1-8a7b-cb0d4a2e6f44",
            ic_lat: -69606,
            ic_lon: -378012,
            ic_city: "Cajazeirinhas",
            ic_states: "PARAÍBA",
            ic_january: 6.195,
            ic_february: 6.188,
            ic_march: 6.181,
            ic_april: 5.893,
            ic_may: 5.242,
            ic_june: 4.862,
            ic_july: 5.105,
            ic_august: 5.902,
            ic_september: 6.471,
            ic_october: 6.61,
            ic_november: 6.765,
            ic_december: 6.339,
            ic_yearly: 5.979,
          },
          {
            ic_uuid: "967d7611-7f7a-4de0-b696-cd50a329fbef",
            ic_lat: -208778,
            ic_lon: -488067,
            ic_city: "Cajobi",
            ic_states: "SÃO PAULO",
            ic_january: 5.817,
            ic_february: 5.936,
            ic_march: 5.212,
            ic_april: 4.836,
            ic_may: 4.129,
            ic_june: 3.915,
            ic_july: 4.15,
            ic_august: 5.025,
            ic_september: 5.078,
            ic_october: 5.579,
            ic_november: 5.933,
            ic_december: 6.142,
            ic_yearly: 5.146,
          },
          {
            ic_uuid: "0d4016cf-6977-4c71-ad1b-f9d80a2717f1",
            ic_lat: -93998,
            ic_lon: -361562,
            ic_city: "Cajueiro",
            ic_states: "ALAGOAS",
            ic_january: 5.758,
            ic_february: 5.67,
            ic_march: 5.681,
            ic_april: 5.067,
            ic_may: 4.194,
            ic_june: 3.786,
            ic_july: 3.878,
            ic_august: 4.414,
            ic_september: 5.237,
            ic_october: 5.498,
            ic_november: 6.085,
            ic_december: 6.034,
            ic_yearly: 5.109,
          },
          {
            ic_uuid: "49f0b8c8-2e2c-4158-a4fa-620be670abe8",
            ic_lat: -29315,
            ic_lon: -413411,
            ic_city: "Cajueiro da Praia",
            ic_states: "PIAUÍ",
            ic_january: 5.658,
            ic_february: 5.466,
            ic_march: 5.232,
            ic_april: 4.961,
            ic_may: 5.194,
            ic_june: 5.349,
            ic_july: 5.563,
            ic_august: 5.934,
            ic_september: 5.877,
            ic_october: 5.931,
            ic_november: 6.326,
            ic_december: 6.128,
            ic_yearly: 5.635,
          },
          {
            ic_uuid: "23194f2b-4189-40a6-8997-e7b989a977f9",
            ic_lat: -207908,
            ic_lon: -427929,
            ic_city: "Cajuri",
            ic_states: "MINAS GERAIS",
            ic_january: 5.641,
            ic_february: 5.968,
            ic_march: 4.962,
            ic_april: 4.381,
            ic_may: 3.758,
            ic_june: 3.581,
            ic_july: 3.787,
            ic_august: 4.608,
            ic_september: 4.904,
            ic_october: 5.126,
            ic_november: 4.888,
            ic_december: 5.544,
            ic_yearly: 4.762,
          },
          {
            ic_uuid: "1d2163c0-be11-4657-be16-537b9d3952ef",
            ic_lat: -212754,
            ic_lon: -473034,
            ic_city: "Cajuru",
            ic_states: "SÃO PAULO",
            ic_january: 5.59,
            ic_february: 5.785,
            ic_march: 5.07,
            ic_april: 4.774,
            ic_may: 4.088,
            ic_june: 3.855,
            ic_july: 4.088,
            ic_august: 4.98,
            ic_september: 5.005,
            ic_october: 5.532,
            ic_november: 5.661,
            ic_december: 5.94,
            ic_yearly: 5.031,
          },
          {
            ic_uuid: "0fef3177-8f59-4d91-90ad-cdff748a828c",
            ic_lat: -8741,
            ic_lon: -363359,
            ic_city: "Calçado",
            ic_states: "PERNAMBUCO",
            ic_january: 5.981,
            ic_february: 5.838,
            ic_march: 5.823,
            ic_april: 5.188,
            ic_may: 4.352,
            ic_june: 3.915,
            ic_july: 4.014,
            ic_august: 4.722,
            ic_september: 5.523,
            ic_october: 5.756,
            ic_november: 6.242,
            ic_december: 6.185,
            ic_yearly: 5.295,
          },
          {
            ic_uuid: "8feb84a0-0201-4935-8f04-44fa4c32b569",
            ic_lat: 24947,
            ic_lon: -509505,
            ic_city: "Calçoene",
            ic_states: "AMAPÁ",
            ic_january: 4.135,
            ic_february: 4.037,
            ic_march: 4.068,
            ic_april: 4.2,
            ic_may: 4.602,
            ic_june: 4.919,
            ic_july: 5.175,
            ic_august: 5.488,
            ic_september: 5.534,
            ic_october: 5.435,
            ic_november: 5.178,
            ic_december: 4.51,
            ic_yearly: 4.773,
          },
          {
            ic_uuid: "9b1a9711-f16d-49f7-abe5-b12d8cfd2677",
            ic_lat: -219188,
            ic_lon: -463847,
            ic_city: "Caldas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.206,
            ic_february: 5.508,
            ic_march: 4.691,
            ic_april: 4.494,
            ic_may: 3.898,
            ic_june: 3.662,
            ic_july: 3.923,
            ic_august: 4.841,
            ic_september: 4.967,
            ic_october: 5.257,
            ic_november: 5.134,
            ic_december: 5.44,
            ic_yearly: 4.752,
          },
          {
            ic_uuid: "6246b814-abae-4b2e-9c12-99203f575d98",
            ic_lat: -71033,
            ic_lon: -35325,
            ic_city: "Caldas Brandão",
            ic_states: "PARAÍBA",
            ic_january: 5.701,
            ic_february: 5.754,
            ic_march: 5.881,
            ic_april: 5.321,
            ic_may: 4.663,
            ic_june: 4.271,
            ic_july: 4.358,
            ic_august: 5.155,
            ic_september: 5.556,
            ic_october: 5.829,
            ic_november: 6.099,
            ic_december: 5.943,
            ic_yearly: 5.378,
          },
          {
            ic_uuid: "af64011f-de03-49cc-bf17-16c9e6790a83",
            ic_lat: -177445,
            ic_lon: -48625,
            ic_city: "Caldas Novas",
            ic_states: "GOIÁS",
            ic_january: 5.635,
            ic_february: 5.854,
            ic_march: 5.213,
            ic_april: 5.152,
            ic_may: 4.714,
            ic_june: 4.433,
            ic_july: 4.676,
            ic_august: 5.539,
            ic_september: 5.425,
            ic_october: 5.604,
            ic_november: 5.516,
            ic_december: 5.729,
            ic_yearly: 5.291,
          },
          {
            ic_uuid: "9f5eed2b-a6e5-4d03-a31f-492e9b57cc47",
            ic_lat: -167155,
            ic_lon: -489992,
            ic_city: "Caldazinha",
            ic_states: "GOIÁS",
            ic_january: 5.504,
            ic_february: 5.66,
            ic_march: 5.189,
            ic_april: 5.123,
            ic_may: 4.837,
            ic_june: 4.605,
            ic_july: 4.785,
            ic_august: 5.665,
            ic_september: 5.561,
            ic_october: 5.616,
            ic_november: 5.369,
            ic_december: 5.49,
            ic_yearly: 5.283,
          },
          {
            ic_uuid: "9e913cfc-1f26-41ee-97d4-002797273790",
            ic_lat: -110279,
            ic_lon: -402975,
            ic_city: "Caldeirão Grande",
            ic_states: "BAHIA",
            ic_january: 6.196,
            ic_february: 5.86,
            ic_march: 5.68,
            ic_april: 4.717,
            ic_may: 4.026,
            ic_june: 3.764,
            ic_july: 3.987,
            ic_august: 4.56,
            ic_september: 5.439,
            ic_october: 5.517,
            ic_november: 5.786,
            ic_december: 5.881,
            ic_yearly: 5.118,
          },
          {
            ic_uuid: "d78ee054-db89-48e5-a5f1-7c28e6cb68d6",
            ic_lat: -73318,
            ic_lon: -406369,
            ic_city: "Caldeirão Grande do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.44,
            ic_february: 5.482,
            ic_march: 5.449,
            ic_april: 5.342,
            ic_may: 5.234,
            ic_june: 5.232,
            ic_july: 5.663,
            ic_august: 6.31,
            ic_september: 6.742,
            ic_october: 6.577,
            ic_november: 6.426,
            ic_december: 5.997,
            ic_yearly: 5.825,
          },
          {
            ic_uuid: "767db720-87ae-457e-8403-a460b4dec73d",
            ic_lat: -236571,
            ic_lon: -513578,
            ic_city: "Califórnia",
            ic_states: "PARANÁ",
            ic_january: 5.87,
            ic_february: 5.637,
            ic_march: 5.254,
            ic_april: 4.565,
            ic_may: 3.573,
            ic_june: 3.249,
            ic_july: 3.468,
            ic_august: 4.45,
            ic_september: 4.615,
            ic_october: 5.244,
            ic_november: 5.956,
            ic_december: 6.218,
            ic_yearly: 4.842,
          },
          {
            ic_uuid: "f2dd0b9a-6f26-4d82-925e-d1aa4d423ec7",
            ic_lat: -265947,
            ic_lon: -510955,
            ic_city: "Calmon",
            ic_states: "SANTA CATARINA",
            ic_january: 5.366,
            ic_february: 5.109,
            ic_march: 4.583,
            ic_april: 3.681,
            ic_may: 2.96,
            ic_june: 2.592,
            ic_july: 2.83,
            ic_august: 3.779,
            ic_september: 3.872,
            ic_october: 4.514,
            ic_november: 5.493,
            ic_december: 5.72,
            ic_yearly: 4.208,
          },
          {
            ic_uuid: "0189f76a-4d25-4fc3-bed5-6d79ce3131d2",
            ic_lat: -79393,
            ic_lon: -381527,
            ic_city: "Calumbi",
            ic_states: "PERNAMBUCO",
            ic_january: 6.22,
            ic_february: 6.112,
            ic_march: 6.143,
            ic_april: 5.718,
            ic_may: 4.836,
            ic_june: 4.432,
            ic_july: 4.676,
            ic_august: 5.557,
            ic_september: 6.441,
            ic_october: 6.59,
            ic_november: 6.732,
            ic_december: 6.418,
            ic_yearly: 5.823,
          },
          {
            ic_uuid: "ac936222-1724-437a-8836-9cfd026ba4c1",
            ic_lat: -154146,
            ic_lon: -394923,
            ic_city: "Camacan",
            ic_states: "BAHIA",
            ic_january: 5.789,
            ic_february: 5.802,
            ic_march: 5.371,
            ic_april: 4.481,
            ic_may: 3.859,
            ic_june: 3.407,
            ic_july: 3.6,
            ic_august: 4.213,
            ic_september: 4.821,
            ic_october: 4.981,
            ic_november: 5.122,
            ic_december: 5.99,
            ic_yearly: 4.787,
          },
          {
            ic_uuid: "13b2c9f7-caa0-42cf-9489-e71517d6be7a",
            ic_lat: -127001,
            ic_lon: -383266,
            ic_city: "Camaçari",
            ic_states: "BAHIA",
            ic_january: 6.118,
            ic_february: 5.951,
            ic_march: 5.67,
            ic_april: 4.488,
            ic_may: 3.883,
            ic_june: 3.673,
            ic_july: 3.884,
            ic_august: 4.406,
            ic_september: 5.111,
            ic_october: 5.497,
            ic_november: 5.825,
            ic_december: 6.093,
            ic_yearly: 5.05,
          },
          {
            ic_uuid: "96e6db77-b550-4014-aa10-422e206a4461",
            ic_lat: -206298,
            ic_lon: -451597,
            ic_city: "Camacho",
            ic_states: "MINAS GERAIS",
            ic_january: 5.639,
            ic_february: 5.846,
            ic_march: 4.955,
            ic_april: 4.788,
            ic_may: 4.19,
            ic_june: 3.965,
            ic_july: 4.241,
            ic_august: 5.141,
            ic_september: 5.335,
            ic_october: 5.478,
            ic_november: 5.108,
            ic_december: 5.488,
            ic_yearly: 5.015,
          },
          {
            ic_uuid: "ec7d13e5-6d6e-4177-9b61-addd10705b40",
            ic_lat: -78855,
            ic_lon: -368245,
            ic_city: "Camalaú",
            ic_states: "PARAÍBA",
            ic_january: 6.2,
            ic_february: 6.104,
            ic_march: 6.097,
            ic_april: 5.673,
            ic_may: 4.849,
            ic_june: 4.395,
            ic_july: 4.499,
            ic_august: 5.273,
            ic_september: 6.168,
            ic_october: 6.43,
            ic_november: 6.653,
            ic_december: 6.39,
            ic_yearly: 5.728,
          },
          {
            ic_uuid: "f11f383d-78d7-493d-a410-f92facb58fb5",
            ic_lat: -139402,
            ic_lon: -391074,
            ic_city: "Camamu",
            ic_states: "BAHIA",
            ic_january: 5.92,
            ic_february: 5.95,
            ic_march: 5.525,
            ic_april: 4.481,
            ic_may: 3.87,
            ic_june: 3.48,
            ic_july: 3.726,
            ic_august: 4.265,
            ic_september: 4.914,
            ic_october: 5.153,
            ic_november: 5.378,
            ic_december: 6.024,
            ic_yearly: 4.89,
          },
          {
            ic_uuid: "7d6b6682-8e7d-405b-8529-c04e9682a750",
            ic_lat: -22752,
            ic_lon: -461498,
            ic_city: "Camanducaia",
            ic_states: "MINAS GERAIS",
            ic_january: 5.154,
            ic_february: 5.491,
            ic_march: 4.802,
            ic_april: 4.54,
            ic_may: 3.803,
            ic_june: 3.558,
            ic_july: 3.737,
            ic_august: 4.676,
            ic_september: 4.876,
            ic_october: 5.365,
            ic_november: 5.481,
            ic_december: 5.751,
            ic_yearly: 4.77,
          },
          {
            ic_uuid: "c4ea2365-13a0-456c-8a1f-5564e935b312",
            ic_lat: -195351,
            ic_lon: -540435,
            ic_city: "Camapuã",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.684,
            ic_february: 5.825,
            ic_march: 5.418,
            ic_april: 4.935,
            ic_may: 4.204,
            ic_june: 4.042,
            ic_july: 4.169,
            ic_august: 4.952,
            ic_september: 5.095,
            ic_october: 5.537,
            ic_november: 5.864,
            ic_december: 6.069,
            ic_yearly: 5.15,
          },
          {
            ic_uuid: "ff3f7986-9e24-4069-98fa-d38e03617627",
            ic_lat: -308494,
            ic_lon: -518048,
            ic_city: "Camaquã",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.241,
            ic_february: 5.533,
            ic_march: 4.692,
            ic_april: 3.687,
            ic_may: 2.715,
            ic_june: 2.254,
            ic_july: 2.468,
            ic_august: 3.095,
            ic_september: 3.519,
            ic_october: 4.64,
            ic_november: 6.012,
            ic_december: 6.468,
            ic_yearly: 4.277,
          },
          {
            ic_uuid: "2d902cdc-ebbe-4ce0-9c27-8506b73baf29",
            ic_lat: -80239,
            ic_lon: -349784,
            ic_city: "Camaragibe",
            ic_states: "PERNAMBUCO",
            ic_january: 5.731,
            ic_february: 5.88,
            ic_march: 5.805,
            ic_april: 5.148,
            ic_may: 4.438,
            ic_june: 4.138,
            ic_july: 4.263,
            ic_august: 4.976,
            ic_september: 5.375,
            ic_october: 5.722,
            ic_november: 5.956,
            ic_december: 5.975,
            ic_yearly: 5.284,
          },
          {
            ic_uuid: "2302e11f-5f82-4929-8036-9e5b6ca7ae4b",
            ic_lat: -285885,
            ic_lon: -522007,
            ic_city: "Camargo",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.232,
            ic_february: 5.84,
            ic_march: 4.943,
            ic_april: 3.952,
            ic_may: 2.965,
            ic_june: 2.464,
            ic_july: 2.765,
            ic_august: 3.538,
            ic_september: 3.82,
            ic_october: 4.978,
            ic_november: 6.197,
            ic_december: 6.615,
            ic_yearly: 4.526,
          },
          {
            ic_uuid: "f20edcbb-12da-4f3d-9d2e-5188fe39a709",
            ic_lat: -230427,
            ic_lon: -500757,
            ic_city: "Cambará",
            ic_states: "PARANÁ",
            ic_january: 5.787,
            ic_february: 5.916,
            ic_march: 5.264,
            ic_april: 4.673,
            ic_may: 3.729,
            ic_june: 3.438,
            ic_july: 3.637,
            ic_august: 4.598,
            ic_september: 4.738,
            ic_october: 5.434,
            ic_november: 5.911,
            ic_december: 6.412,
            ic_yearly: 4.962,
          },
          {
            ic_uuid: "c6c6c496-528d-49d5-9d08-0264b75dd1e4",
            ic_lat: -290479,
            ic_lon: -501469,
            ic_city: "Cambará do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 5.94,
            ic_february: 5.456,
            ic_march: 4.68,
            ic_april: 3.87,
            ic_may: 2.898,
            ic_june: 2.499,
            ic_july: 2.81,
            ic_august: 3.538,
            ic_september: 3.685,
            ic_october: 4.791,
            ic_november: 6.032,
            ic_december: 6.283,
            ic_yearly: 4.374,
          },
          {
            ic_uuid: "5dde8cc7-ad88-4c8b-a0f7-4da608330c3b",
            ic_lat: -23277,
            ic_lon: -512803,
            ic_city: "Cambé",
            ic_states: "PARANÁ",
            ic_january: 5.833,
            ic_february: 5.684,
            ic_march: 5.242,
            ic_april: 4.604,
            ic_may: 3.631,
            ic_june: 3.384,
            ic_july: 3.577,
            ic_august: 4.509,
            ic_september: 4.7,
            ic_october: 5.275,
            ic_november: 5.985,
            ic_december: 6.274,
            ic_yearly: 4.891,
          },
          {
            ic_uuid: "7485ca19-cbb0-4324-a239-41a5f2a92bf4",
            ic_lat: -235895,
            ic_lon: -515796,
            ic_city: "Cambira",
            ic_states: "PARANÁ",
            ic_january: 5.73,
            ic_february: 5.67,
            ic_march: 5.22,
            ic_april: 4.561,
            ic_may: 3.596,
            ic_june: 3.297,
            ic_july: 3.518,
            ic_august: 4.476,
            ic_september: 4.651,
            ic_october: 5.287,
            ic_november: 5.936,
            ic_december: 6.186,
            ic_yearly: 4.844,
          },
          {
            ic_uuid: "f3670a70-e275-4afa-b34a-5ef3158727c8",
            ic_lat: -270246,
            ic_lon: -486508,
            ic_city: "Camboriú",
            ic_states: "SANTA CATARINA",
            ic_january: 5.697,
            ic_february: 5.419,
            ic_march: 4.618,
            ic_april: 3.702,
            ic_may: 3.02,
            ic_june: 2.514,
            ic_july: 2.643,
            ic_august: 3.245,
            ic_september: 3.52,
            ic_october: 4.161,
            ic_november: 5.321,
            ic_december: 5.747,
            ic_yearly: 4.134,
          },
          {
            ic_uuid: "e91f7799-27d0-42ff-bcd0-d2d94387ae47",
            ic_lat: -215695,
            ic_lon: -419191,
            ic_city: "Cambuci",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.189,
            ic_february: 6.249,
            ic_march: 5.141,
            ic_april: 4.487,
            ic_may: 3.787,
            ic_june: 3.532,
            ic_july: 3.619,
            ic_august: 4.348,
            ic_september: 4.768,
            ic_october: 5.058,
            ic_november: 5.0,
            ic_december: 5.862,
            ic_yearly: 4.837,
          },
          {
            ic_uuid: "42261471-3b09-487d-9c32-af1110d0cecb",
            ic_lat: -22612,
            ic_lon: -460576,
            ic_city: "Cambuí",
            ic_states: "MINAS GERAIS",
            ic_january: 5.213,
            ic_february: 5.52,
            ic_march: 4.768,
            ic_april: 4.521,
            ic_may: 3.824,
            ic_june: 3.553,
            ic_july: 3.765,
            ic_august: 4.699,
            ic_september: 4.855,
            ic_october: 5.277,
            ic_november: 5.273,
            ic_december: 5.579,
            ic_yearly: 4.737,
          },
          {
            ic_uuid: "6919d49e-6a12-42e4-a03a-d4d87c0f83d4",
            ic_lat: -218545,
            ic_lon: -4529,
            ic_city: "Cambuquira",
            ic_states: "MINAS GERAIS",
            ic_january: 5.378,
            ic_february: 5.669,
            ic_march: 4.848,
            ic_april: 4.567,
            ic_may: 3.907,
            ic_june: 3.664,
            ic_july: 3.9,
            ic_august: 4.786,
            ic_september: 4.998,
            ic_october: 5.392,
            ic_november: 5.224,
            ic_december: 5.586,
            ic_yearly: 4.827,
          },
          {
            ic_uuid: "6c2f7233-d580-4d12-98ff-e87779e3ab65",
            ic_lat: -22433,
            ic_lon: -494983,
            ic_city: "Cametá",
            ic_states: "PARÁ",
            ic_january: 4.627,
            ic_february: 4.682,
            ic_march: 4.742,
            ic_april: 4.818,
            ic_may: 4.925,
            ic_june: 5.044,
            ic_july: 5.08,
            ic_august: 5.393,
            ic_september: 5.486,
            ic_october: 5.313,
            ic_november: 5.2,
            ic_december: 4.819,
            ic_yearly: 5.011,
          },
          {
            ic_uuid: "4d844aa4-d6f4-4e72-a1c7-642166f92092",
            ic_lat: -28987,
            ic_lon: -40848,
            ic_city: "Camocim",
            ic_states: "CEARÁ",
            ic_january: 5.387,
            ic_february: 5.216,
            ic_march: 4.917,
            ic_april: 4.68,
            ic_may: 5.014,
            ic_june: 5.201,
            ic_july: 5.436,
            ic_august: 5.932,
            ic_september: 6.207,
            ic_october: 6.198,
            ic_november: 6.255,
            ic_december: 5.914,
            ic_yearly: 5.529,
          },
          {
            ic_uuid: "96d80e05-949f-460b-9d0b-023164c5517d",
            ic_lat: -83591,
            ic_lon: -357656,
            ic_city: "Camocim de São Félix",
            ic_states: "PERNAMBUCO",
            ic_january: 5.778,
            ic_february: 5.708,
            ic_march: 5.793,
            ic_april: 5.075,
            ic_may: 4.285,
            ic_june: 3.835,
            ic_july: 3.938,
            ic_august: 4.632,
            ic_september: 5.365,
            ic_october: 5.737,
            ic_november: 6.142,
            ic_december: 6.013,
            ic_yearly: 5.192,
          },
          {
            ic_uuid: "96a9260c-ddae-41c8-950b-3caf553e4fa1",
            ic_lat: -182432,
            ic_lon: -417359,
            ic_city: "Campanário",
            ic_states: "MINAS GERAIS",
            ic_january: 6.066,
            ic_february: 6.26,
            ic_march: 5.392,
            ic_april: 4.655,
            ic_may: 3.918,
            ic_june: 3.677,
            ic_july: 3.766,
            ic_august: 4.413,
            ic_september: 4.967,
            ic_october: 5.236,
            ic_november: 4.975,
            ic_december: 5.824,
            ic_yearly: 4.929,
          },
          {
            ic_uuid: "21a3bb1d-24d8-44a5-ac4f-16811082c2e7",
            ic_lat: -218365,
            ic_lon: -454008,
            ic_city: "Campanha",
            ic_states: "MINAS GERAIS",
            ic_january: 5.411,
            ic_february: 5.724,
            ic_march: 4.905,
            ic_april: 4.579,
            ic_may: 3.927,
            ic_june: 3.678,
            ic_july: 3.9,
            ic_august: 4.78,
            ic_september: 5.02,
            ic_october: 5.401,
            ic_november: 5.255,
            ic_december: 5.651,
            ic_yearly: 4.853,
          },
          {
            ic_uuid: "c273d473-cb25-4352-8a44-a3f13854300a",
            ic_lat: -88477,
            ic_lon: -355688,
            ic_city: "Campestre",
            ic_states: "ALAGOAS",
            ic_january: 5.686,
            ic_february: 5.648,
            ic_march: 5.735,
            ic_april: 5.008,
            ic_may: 4.232,
            ic_june: 3.93,
            ic_july: 3.961,
            ic_august: 4.608,
            ic_september: 5.397,
            ic_october: 5.633,
            ic_november: 6.058,
            ic_december: 5.967,
            ic_yearly: 5.155,
          },
          {
            ic_uuid: "5fd0bfdf-49c4-4be2-a3b5-38e7b96f10d3",
            ic_lat: -217083,
            ic_lon: -462385,
            ic_city: "Campestre",
            ic_states: "MINAS GERAIS",
            ic_january: 5.337,
            ic_february: 5.623,
            ic_march: 4.81,
            ic_april: 4.535,
            ic_may: 3.939,
            ic_june: 3.698,
            ic_july: 3.97,
            ic_august: 4.904,
            ic_september: 5.061,
            ic_october: 5.359,
            ic_november: 5.218,
            ic_december: 5.558,
            ic_yearly: 4.834,
          },
          {
            ic_uuid: "2e5f2e42-4913-43b0-9c7f-bc42946d4db6",
            ic_lat: -287931,
            ic_lon: -510946,
            ic_city: "Campestre da Serra",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.025,
            ic_february: 5.651,
            ic_march: 4.841,
            ic_april: 3.83,
            ic_may: 2.891,
            ic_june: 2.437,
            ic_july: 2.754,
            ic_august: 3.485,
            ic_september: 3.735,
            ic_october: 4.836,
            ic_november: 6.123,
            ic_december: 6.409,
            ic_yearly: 4.418,
          },
          {
            ic_uuid: "3c3f96de-23ff-4661-a911-ef50774e5c04",
            ic_lat: -167634,
            ic_lon: -496942,
            ic_city: "Campestre de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.542,
            ic_february: 5.63,
            ic_march: 5.293,
            ic_april: 5.17,
            ic_may: 4.781,
            ic_june: 4.522,
            ic_july: 4.704,
            ic_august: 5.554,
            ic_september: 5.446,
            ic_october: 5.553,
            ic_november: 5.409,
            ic_december: 5.556,
            ic_yearly: 5.264,
          },
          {
            ic_uuid: "737e12f3-dc0d-408e-890b-9f8e8be34d5d",
            ic_lat: -61712,
            ic_lon: -473628,
            ic_city: "Campestre do Maranhão",
            ic_states: "MARANHÃO",
            ic_january: 4.749,
            ic_february: 4.876,
            ic_march: 4.87,
            ic_april: 4.86,
            ic_may: 4.878,
            ic_june: 5.174,
            ic_july: 5.354,
            ic_august: 5.894,
            ic_september: 5.739,
            ic_october: 5.287,
            ic_november: 4.89,
            ic_december: 4.763,
            ic_yearly: 5.111,
          },
          {
            ic_uuid: "e7806528-3aa0-41f4-b750-37994352b953",
            ic_lat: -245898,
            ic_lon: -52798,
            ic_city: "Campina da Lagoa",
            ic_states: "PARANÁ",
            ic_january: 6.262,
            ic_february: 5.498,
            ic_march: 5.164,
            ic_april: 4.409,
            ic_may: 3.474,
            ic_june: 3.038,
            ic_july: 3.266,
            ic_august: 4.236,
            ic_september: 4.467,
            ic_october: 5.209,
            ic_november: 6.032,
            ic_december: 6.45,
            ic_yearly: 4.792,
          },
          {
            ic_uuid: "cb4c4512-661e-4321-ab9e-8b691a4a1133",
            ic_lat: -279893,
            ic_lon: -548421,
            ic_city: "Campina das Missões",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.557,
            ic_february: 6.045,
            ic_march: 5.184,
            ic_april: 4.071,
            ic_may: 3.097,
            ic_june: 2.535,
            ic_july: 2.858,
            ic_august: 3.629,
            ic_september: 4.039,
            ic_october: 5.149,
            ic_november: 6.329,
            ic_december: 6.744,
            ic_yearly: 4.686,
          },
          {
            ic_uuid: "ddbe154a-5ef1-48e9-bd43-8e8691b98abb",
            ic_lat: -23592,
            ic_lon: -484769,
            ic_city: "Campina do Monte Alegre",
            ic_states: "SÃO PAULO",
            ic_january: 5.487,
            ic_february: 5.675,
            ic_march: 4.971,
            ic_april: 4.364,
            ic_may: 3.461,
            ic_june: 3.215,
            ic_july: 3.333,
            ic_august: 4.325,
            ic_september: 4.509,
            ic_october: 5.05,
            ic_november: 5.614,
            ic_december: 6.086,
            ic_yearly: 4.674,
          },
          {
            ic_uuid: "552c0f8e-af12-46f7-b0ba-0f8a7f662c35",
            ic_lat: -250806,
            ic_lon: -518241,
            ic_city: "Campina do Simão",
            ic_states: "PARANÁ",
            ic_january: 5.814,
            ic_february: 5.503,
            ic_march: 5.089,
            ic_april: 4.301,
            ic_may: 3.344,
            ic_june: 2.958,
            ic_july: 3.228,
            ic_august: 4.223,
            ic_september: 4.356,
            ic_october: 5.168,
            ic_november: 5.947,
            ic_december: 6.15,
            ic_yearly: 4.674,
          },
          {
            ic_uuid: "edde0d05-c28e-412d-9a40-1db0e3697800",
            ic_lat: -72224,
            ic_lon: -358734,
            ic_city: "Campina Grande",
            ic_states: "PARAÍBA",
            ic_january: 5.546,
            ic_february: 5.669,
            ic_march: 5.767,
            ic_april: 5.231,
            ic_may: 4.626,
            ic_june: 4.063,
            ic_july: 4.164,
            ic_august: 4.898,
            ic_september: 5.491,
            ic_october: 5.751,
            ic_november: 5.994,
            ic_december: 5.764,
            ic_yearly: 5.247,
          },
          {
            ic_uuid: "b81c2c66-fdf6-4de0-9c0d-fe9f9c6ecfc6",
            ic_lat: -253049,
            ic_lon: -490555,
            ic_city: "Campina Grande do Sul",
            ic_states: "PARANÁ",
            ic_january: 5.302,
            ic_february: 5.167,
            ic_march: 4.541,
            ic_april: 3.739,
            ic_may: 3.054,
            ic_june: 2.727,
            ic_july: 2.898,
            ic_august: 3.834,
            ic_september: 3.78,
            ic_october: 4.326,
            ic_november: 5.185,
            ic_december: 5.568,
            ic_yearly: 4.177,
          },
          {
            ic_uuid: "ceb991f3-f67c-4b8d-9a6e-b656b15ea64c",
            ic_lat: -195343,
            ic_lon: -494876,
            ic_city: "Campina Verde",
            ic_states: "MINAS GERAIS",
            ic_january: 5.727,
            ic_february: 5.779,
            ic_march: 5.194,
            ic_april: 4.99,
            ic_may: 4.38,
            ic_june: 4.098,
            ic_july: 4.389,
            ic_august: 5.294,
            ic_september: 5.228,
            ic_october: 5.57,
            ic_november: 5.803,
            ic_december: 6.006,
            ic_yearly: 5.205,
          },
          {
            ic_uuid: "73315665-6d89-40f3-996c-c837e1863f1b",
            ic_lat: -137911,
            ic_lon: -485691,
            ic_city: "Campinaçu",
            ic_states: "GOIÁS",
            ic_january: 5.273,
            ic_february: 5.424,
            ic_march: 5.082,
            ic_april: 5.093,
            ic_may: 5.012,
            ic_june: 4.795,
            ic_july: 5.21,
            ic_august: 5.91,
            ic_september: 5.725,
            ic_october: 5.512,
            ic_november: 5.03,
            ic_december: 5.223,
            ic_yearly: 5.274,
          },
          {
            ic_uuid: "017c02cf-227b-4893-94e5-d97d92314f13",
            ic_lat: -145162,
            ic_lon: -52893,
            ic_city: "Campinápolis",
            ic_states: "MATO GROSSO",
            ic_january: 5.438,
            ic_february: 5.54,
            ic_march: 5.28,
            ic_april: 5.313,
            ic_may: 4.961,
            ic_june: 4.766,
            ic_july: 5.055,
            ic_august: 5.601,
            ic_september: 5.371,
            ic_october: 5.411,
            ic_november: 5.338,
            ic_december: 5.459,
            ic_yearly: 5.295,
          },
          {
            ic_uuid: "5453d510-250d-4ced-a368-56549bf1a953",
            ic_lat: -229058,
            ic_lon: -470664,
            ic_city: "Campinas",
            ic_states: "SÃO PAULO",
            ic_january: 5.604,
            ic_february: 5.753,
            ic_march: 5.085,
            ic_april: 4.615,
            ic_may: 3.793,
            ic_june: 3.535,
            ic_july: 3.705,
            ic_august: 4.612,
            ic_september: 4.836,
            ic_october: 5.484,
            ic_november: 5.678,
            ic_december: 6.121,
            ic_yearly: 4.902,
          },
          {
            ic_uuid: "919f9230-9c8b-4bc8-ace0-b251ea2b065b",
            ic_lat: -76597,
            ic_lon: -418778,
            ic_city: "Campinas do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.765,
            ic_february: 5.609,
            ic_march: 5.655,
            ic_april: 5.355,
            ic_may: 5.243,
            ic_june: 5.249,
            ic_july: 5.506,
            ic_august: 6.182,
            ic_september: 6.553,
            ic_october: 6.541,
            ic_november: 6.366,
            ic_december: 6.011,
            ic_yearly: 5.836,
          },
          {
            ic_uuid: "ac37d738-5b18-4f11-bc39-db8d99053200",
            ic_lat: -277179,
            ic_lon: -526253,
            ic_city: "Campinas do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.368,
            ic_february: 5.939,
            ic_march: 5.039,
            ic_april: 4.087,
            ic_may: 3.088,
            ic_june: 2.596,
            ic_july: 2.912,
            ic_august: 3.73,
            ic_september: 3.961,
            ic_october: 5.119,
            ic_november: 6.17,
            ic_december: 6.61,
            ic_yearly: 4.635,
          },
          {
            ic_uuid: "6aa0e9f5-72a5-433e-8974-32e1e677196e",
            ic_lat: -143141,
            ic_lon: -491515,
            ic_city: "Campinorte",
            ic_states: "GOIÁS",
            ic_january: 5.416,
            ic_february: 5.63,
            ic_march: 5.275,
            ic_april: 5.164,
            ic_may: 5.006,
            ic_june: 4.747,
            ic_july: 5.042,
            ic_august: 5.684,
            ic_september: 5.629,
            ic_october: 5.639,
            ic_november: 5.212,
            ic_december: 5.402,
            ic_yearly: 5.32,
          },
          {
            ic_uuid: "e6585003-6959-4782-a07b-817d472f4be8",
            ic_lat: -97849,
            ic_lon: -363528,
            ic_city: "Campo Alegre",
            ic_states: "ALAGOAS",
            ic_january: 5.96,
            ic_february: 5.832,
            ic_march: 5.843,
            ic_april: 5.054,
            ic_may: 4.239,
            ic_june: 3.893,
            ic_july: 4.029,
            ic_august: 4.57,
            ic_september: 5.312,
            ic_october: 5.689,
            ic_november: 6.179,
            ic_december: 6.261,
            ic_yearly: 5.238,
          },
          {
            ic_uuid: "73c28731-314b-4311-857d-c96a52bdbe51",
            ic_lat: -261955,
            ic_lon: -49268,
            ic_city: "Campo Alegre",
            ic_states: "SANTA CATARINA",
            ic_january: 5.266,
            ic_february: 4.97,
            ic_march: 4.373,
            ic_april: 3.621,
            ic_may: 2.953,
            ic_june: 2.586,
            ic_july: 2.803,
            ic_august: 3.676,
            ic_september: 3.674,
            ic_october: 4.219,
            ic_november: 5.068,
            ic_december: 5.424,
            ic_yearly: 4.053,
          },
          {
            ic_uuid: "1dba9aad-1d5b-460b-a6a9-bff390e3bb10",
            ic_lat: -176368,
            ic_lon: -477772,
            ic_city: "Campo Alegre de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.589,
            ic_february: 5.909,
            ic_march: 5.063,
            ic_april: 5.083,
            ic_may: 4.681,
            ic_june: 4.522,
            ic_july: 4.77,
            ic_august: 5.617,
            ic_september: 5.491,
            ic_october: 5.646,
            ic_november: 5.373,
            ic_december: 5.62,
            ic_yearly: 5.28,
          },
          {
            ic_uuid: "715db9ec-f6e7-4700-9962-201f781aa80e",
            ic_lat: -9516,
            ic_lon: -430088,
            ic_city: "Campo Alegre de Lourdes",
            ic_states: "BAHIA",
            ic_january: 6.004,
            ic_february: 5.743,
            ic_march: 5.647,
            ic_april: 5.412,
            ic_may: 5.298,
            ic_june: 5.249,
            ic_july: 5.633,
            ic_august: 6.419,
            ic_september: 6.615,
            ic_october: 6.445,
            ic_november: 6.121,
            ic_december: 6.052,
            ic_yearly: 5.887,
          },
          {
            ic_uuid: "884ee5b0-a570-4c0c-bf70-4eae3762643a",
            ic_lat: -83773,
            ic_lon: -418369,
            ic_city: "Campo Alegre do Fidalgo",
            ic_states: "PIAUÍ",
            ic_january: 5.812,
            ic_february: 5.743,
            ic_march: 5.651,
            ic_april: 5.342,
            ic_may: 5.269,
            ic_june: 5.206,
            ic_july: 5.578,
            ic_august: 6.349,
            ic_september: 6.572,
            ic_october: 6.614,
            ic_november: 6.299,
            ic_december: 6.041,
            ic_yearly: 5.873,
          },
          {
            ic_uuid: "0c002a35-6c93-4bfd-a646-aa6a5eaf5b3f",
            ic_lat: -16506,
            ic_lon: -448114,
            ic_city: "Campo Azul",
            ic_states: "MINAS GERAIS",
            ic_january: 6.357,
            ic_february: 6.441,
            ic_march: 5.591,
            ic_april: 5.457,
            ic_may: 4.887,
            ic_june: 4.622,
            ic_july: 4.953,
            ic_august: 5.785,
            ic_september: 5.994,
            ic_october: 6.034,
            ic_november: 5.489,
            ic_december: 6.073,
            ic_yearly: 5.64,
          },
          {
            ic_uuid: "4daf99be-ef7b-47c7-b19e-5264428e86a5",
            ic_lat: -208937,
            ic_lon: -452703,
            ic_city: "Campo Belo",
            ic_states: "MINAS GERAIS",
            ic_january: 5.615,
            ic_february: 5.724,
            ic_march: 4.863,
            ic_april: 4.7,
            ic_may: 4.056,
            ic_june: 3.862,
            ic_july: 4.148,
            ic_august: 5.043,
            ic_september: 5.219,
            ic_october: 5.389,
            ic_november: 5.105,
            ic_december: 5.597,
            ic_yearly: 4.944,
          },
          {
            ic_uuid: "94b0b277-9d4d-4f9f-b5d8-d42bda456ec0",
            ic_lat: -27898,
            ic_lon: -5076,
            ic_city: "Campo Belo do Sul",
            ic_states: "SANTA CATARINA",
            ic_january: 5.971,
            ic_february: 5.523,
            ic_march: 4.731,
            ic_april: 3.85,
            ic_may: 2.924,
            ic_june: 2.511,
            ic_july: 2.766,
            ic_august: 3.599,
            ic_september: 3.771,
            ic_october: 4.676,
            ic_november: 5.869,
            ic_december: 6.189,
            ic_yearly: 4.365,
          },
          {
            ic_uuid: "fa0627dd-2ed9-4c35-963b-9d1a18ddb075",
            ic_lat: -296752,
            ic_lon: -510611,
            ic_city: "Campo Bom",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.241,
            ic_february: 5.707,
            ic_march: 4.754,
            ic_april: 3.779,
            ic_may: 2.822,
            ic_june: 2.325,
            ic_july: 2.519,
            ic_august: 3.188,
            ic_september: 3.556,
            ic_october: 4.622,
            ic_november: 6.07,
            ic_december: 6.528,
            ic_yearly: 4.343,
          },
          {
            ic_uuid: "c71e6f44-d974-4d4b-b469-529fd9dfa8ef",
            ic_lat: -250299,
            ic_lon: -529944,
            ic_city: "Campo Bonito",
            ic_states: "PARANÁ",
            ic_january: 6.227,
            ic_february: 5.593,
            ic_march: 5.285,
            ic_april: 4.351,
            ic_may: 3.401,
            ic_june: 3.002,
            ic_july: 3.244,
            ic_august: 4.252,
            ic_september: 4.419,
            ic_october: 5.221,
            ic_november: 6.066,
            ic_december: 6.346,
            ic_yearly: 4.784,
          },
          {
            ic_uuid: "a2c2cec7-c1ee-4a49-b4ea-c53e372fdc60",
            ic_lat: -107458,
            ic_lon: -374935,
            ic_city: "Campo do Brito",
            ic_states: "SERGIPE",
            ic_january: 6.068,
            ic_february: 5.954,
            ic_march: 5.829,
            ic_april: 5.008,
            ic_may: 4.208,
            ic_june: 3.93,
            ic_july: 4.013,
            ic_august: 4.552,
            ic_september: 5.346,
            ic_october: 5.722,
            ic_november: 6.195,
            ic_december: 6.244,
            ic_yearly: 5.256,
          },
          {
            ic_uuid: "1952eafd-776b-4d4c-ae47-8a4550bf85d7",
            ic_lat: -211132,
            ic_lon: -458277,
            ic_city: "Campo do Meio",
            ic_states: "MINAS GERAIS",
            ic_january: 5.613,
            ic_february: 5.87,
            ic_march: 4.936,
            ic_april: 4.719,
            ic_may: 4.044,
            ic_june: 3.772,
            ic_july: 4.081,
            ic_august: 4.884,
            ic_september: 5.108,
            ic_october: 5.492,
            ic_november: 5.354,
            ic_december: 5.725,
            ic_yearly: 4.967,
          },
          {
            ic_uuid: "4b13813f-1f55-46db-b6e1-bc4542c6bd3b",
            ic_lat: -259805,
            ic_lon: -496848,
            ic_city: "Campo do Tenente",
            ic_states: "PARANÁ",
            ic_january: 5.425,
            ic_february: 5.126,
            ic_march: 4.529,
            ic_april: 3.702,
            ic_may: 2.947,
            ic_june: 2.567,
            ic_july: 2.791,
            ic_august: 3.764,
            ic_september: 3.806,
            ic_october: 4.408,
            ic_november: 5.26,
            ic_december: 5.619,
            ic_yearly: 4.162,
          },
          {
            ic_uuid: "c22f239e-9e61-43af-bca7-52eae88e7b68",
            ic_lat: -263935,
            ic_lon: -530861,
            ic_city: "Campo Erê",
            ic_states: "SANTA CATARINA",
            ic_january: 6.195,
            ic_february: 5.651,
            ic_march: 5.056,
            ic_april: 4.134,
            ic_may: 3.241,
            ic_june: 2.764,
            ic_july: 3.052,
            ic_august: 3.988,
            ic_september: 4.211,
            ic_october: 5.136,
            ic_november: 6.038,
            ic_december: 6.267,
            ic_yearly: 4.645,
          },
          {
            ic_uuid: "36aa969e-73f5-4cf1-8f2b-705592c883a4",
            ic_lat: -197635,
            ic_lon: -48572,
            ic_city: "Campo Florido",
            ic_states: "MINAS GERAIS",
            ic_january: 5.649,
            ic_february: 5.914,
            ic_march: 5.111,
            ic_april: 4.994,
            ic_may: 4.373,
            ic_june: 4.097,
            ic_july: 4.374,
            ic_august: 5.289,
            ic_september: 5.186,
            ic_october: 5.515,
            ic_november: 5.653,
            ic_december: 5.907,
            ic_yearly: 5.172,
          },
          {
            ic_uuid: "86003636-9288-4c36-afa6-b31937e4600f",
            ic_lat: -10511,
            ic_lon: -403204,
            ic_city: "Campo Formoso",
            ic_states: "BAHIA",
            ic_january: 6.18,
            ic_february: 5.962,
            ic_march: 5.863,
            ic_april: 4.904,
            ic_may: 4.171,
            ic_june: 3.842,
            ic_july: 4.121,
            ic_august: 4.836,
            ic_september: 5.795,
            ic_october: 5.917,
            ic_november: 6.122,
            ic_december: 6.185,
            ic_yearly: 5.324,
          },
          {
            ic_uuid: "2f6bdcff-33fe-4238-a150-6088cd4060a8",
            ic_lat: -99559,
            ic_lon: -367929,
            ic_city: "Campo Grande",
            ic_states: "ALAGOAS",
            ic_january: 5.911,
            ic_february: 5.811,
            ic_march: 5.773,
            ic_april: 5.03,
            ic_may: 4.228,
            ic_june: 4.001,
            ic_july: 4.072,
            ic_august: 4.628,
            ic_september: 5.412,
            ic_october: 5.747,
            ic_november: 6.21,
            ic_december: 6.195,
            ic_yearly: 5.252,
          },
          {
            ic_uuid: "125c29ef-3332-41f1-b51d-d24f8e8a0a57",
            ic_lat: -204495,
            ic_lon: -546303,
            ic_city: "Campo Grande",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.652,
            ic_february: 5.674,
            ic_march: 5.344,
            ic_april: 4.818,
            ic_may: 4.027,
            ic_june: 3.765,
            ic_july: 3.92,
            ic_august: 4.811,
            ic_september: 4.917,
            ic_october: 5.423,
            ic_november: 5.918,
            ic_december: 6.102,
            ic_yearly: 5.031,
          },
          {
            ic_uuid: "ddf93abb-5cae-4672-98df-d94b999ed6ce",
            ic_lat: -71287,
            ic_lon: -410318,
            ic_city: "Campo Grande do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.58,
            ic_february: 5.47,
            ic_march: 5.61,
            ic_april: 5.291,
            ic_may: 5.115,
            ic_june: 5.067,
            ic_july: 5.506,
            ic_august: 6.171,
            ic_september: 6.61,
            ic_october: 6.442,
            ic_november: 6.371,
            ic_december: 5.985,
            ic_yearly: 5.768,
          },
          {
            ic_uuid: "831a52c9-7b8e-46f1-bbd2-bf0eb381273b",
            ic_lat: -25453,
            ic_lon: -495295,
            ic_city: "Campo Largo",
            ic_states: "PARANÁ",
            ic_january: 5.314,
            ic_february: 5.152,
            ic_march: 4.531,
            ic_april: 3.765,
            ic_may: 3.025,
            ic_june: 2.67,
            ic_july: 2.882,
            ic_august: 3.855,
            ic_september: 3.859,
            ic_october: 4.391,
            ic_november: 5.282,
            ic_december: 5.536,
            ic_yearly: 4.188,
          },
          {
            ic_uuid: "c3052881-039d-4c83-b0c7-670294db5a88",
            ic_lat: -3811,
            ic_lon: -426298,
            ic_city: "Campo Largo do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.242,
            ic_february: 5.346,
            ic_march: 5.317,
            ic_april: 5.121,
            ic_may: 5.139,
            ic_june: 5.284,
            ic_july: 5.502,
            ic_august: 6.012,
            ic_september: 6.367,
            ic_october: 6.321,
            ic_november: 6.142,
            ic_december: 5.598,
            ic_yearly: 5.616,
          },
          {
            ic_uuid: "c573ec05-a1a0-4ee7-82a9-afecace46ce3",
            ic_lat: -162924,
            ic_lon: -490923,
            ic_city: "Campo Limpo de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.365,
            ic_february: 5.513,
            ic_march: 5.055,
            ic_april: 5.058,
            ic_may: 4.855,
            ic_june: 4.652,
            ic_july: 4.897,
            ic_august: 5.796,
            ic_september: 5.526,
            ic_october: 5.499,
            ic_november: 5.155,
            ic_december: 5.408,
            ic_yearly: 5.231,
          },
          {
            ic_uuid: "ebed4089-4f78-4f57-8a4d-4cb0661fbf54",
            ic_lat: -232083,
            ic_lon: -467893,
            ic_city: "Campo Limpo Paulista",
            ic_states: "SÃO PAULO",
            ic_january: 5.44,
            ic_february: 5.739,
            ic_march: 4.963,
            ic_april: 4.438,
            ic_may: 3.666,
            ic_june: 3.412,
            ic_july: 3.544,
            ic_august: 4.542,
            ic_september: 4.624,
            ic_october: 5.214,
            ic_november: 5.5,
            ic_december: 5.948,
            ic_yearly: 4.752,
          },
          {
            ic_uuid: "b3537777-4e43-44f0-9a17-13a6a84d2edd",
            ic_lat: -253692,
            ic_lon: -494505,
            ic_city: "Campo Magro",
            ic_states: "PARANÁ",
            ic_january: 5.3,
            ic_february: 5.193,
            ic_march: 4.581,
            ic_april: 3.793,
            ic_may: 3.021,
            ic_june: 2.691,
            ic_july: 2.883,
            ic_august: 3.882,
            ic_september: 3.876,
            ic_october: 4.418,
            ic_november: 5.306,
            ic_december: 5.594,
            ic_yearly: 4.211,
          },
          {
            ic_uuid: "645c24cb-eebb-4510-8202-099b38cd4fd4",
            ic_lat: -48263,
            ic_lon: -421647,
            ic_city: "Campo Maior",
            ic_states: "PIAUÍ",
            ic_january: 5.325,
            ic_february: 5.429,
            ic_march: 5.51,
            ic_april: 5.288,
            ic_may: 5.208,
            ic_june: 5.261,
            ic_july: 5.445,
            ic_august: 6.042,
            ic_september: 6.455,
            ic_october: 6.438,
            ic_november: 6.232,
            ic_december: 5.71,
            ic_yearly: 5.695,
          },
          {
            ic_uuid: "c7465d39-5700-49f5-b660-e1c7c8525dfc",
            ic_lat: -240468,
            ic_lon: -523785,
            ic_city: "Campo Mourão",
            ic_states: "PARANÁ",
            ic_january: 6.085,
            ic_february: 5.729,
            ic_march: 5.257,
            ic_april: 4.539,
            ic_may: 3.584,
            ic_june: 3.164,
            ic_july: 3.395,
            ic_august: 4.332,
            ic_september: 4.565,
            ic_october: 5.279,
            ic_november: 6.106,
            ic_december: 6.473,
            ic_yearly: 4.876,
          },
          {
            ic_uuid: "c2a23cc2-0795-4eee-989a-bc69cd9f433f",
            ic_lat: -276797,
            ic_lon: -538057,
            ic_city: "Campo Novo",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.458,
            ic_february: 5.993,
            ic_march: 5.104,
            ic_april: 4.064,
            ic_may: 3.123,
            ic_june: 2.586,
            ic_july: 2.915,
            ic_august: 3.706,
            ic_september: 3.99,
            ic_october: 5.169,
            ic_november: 6.204,
            ic_december: 6.61,
            ic_yearly: 4.66,
          },
          {
            ic_uuid: "337cdd7d-4ae2-4218-aea4-e09b50ef4afc",
            ic_lat: -105699,
            ic_lon: -636212,
            ic_city: "Campo Novo de Rondônia",
            ic_states: "RONDÔNIA",
            ic_january: 4.244,
            ic_february: 4.346,
            ic_march: 4.326,
            ic_april: 4.462,
            ic_may: 4.093,
            ic_june: 4.365,
            ic_july: 4.384,
            ic_august: 4.862,
            ic_september: 4.895,
            ic_october: 5.014,
            ic_november: 4.761,
            ic_december: 4.553,
            ic_yearly: 4.525,
          },
          {
            ic_uuid: "220f74da-f45c-432e-92f3-e88d5acbda11",
            ic_lat: -136592,
            ic_lon: -578912,
            ic_city: "Campo Novo do Parecis",
            ic_states: "MATO GROSSO",
            ic_january: 5.02,
            ic_february: 4.982,
            ic_march: 4.745,
            ic_april: 4.728,
            ic_may: 4.435,
            ic_june: 4.446,
            ic_july: 4.578,
            ic_august: 5.24,
            ic_september: 4.999,
            ic_october: 5.121,
            ic_november: 5.196,
            ic_december: 5.354,
            ic_yearly: 4.904,
          },
          {
            ic_uuid: "a7111a50-5c70-4bac-931a-e7732e088068",
            ic_lat: -62427,
            ic_lon: -361832,
            ic_city: "Campo Redondo",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.733,
            ic_february: 5.832,
            ic_march: 5.768,
            ic_april: 5.451,
            ic_may: 4.946,
            ic_june: 4.486,
            ic_july: 4.703,
            ic_august: 5.386,
            ic_september: 5.902,
            ic_october: 6.092,
            ic_november: 6.191,
            ic_december: 5.748,
            ic_yearly: 5.52,
          },
          {
            ic_uuid: "d6bfa6ed-62f9-444d-934d-e92e6eb2ac61",
            ic_lat: -155454,
            ic_lon: -55163,
            ic_city: "Campo Verde",
            ic_states: "MATO GROSSO",
            ic_january: 5.315,
            ic_february: 5.174,
            ic_march: 4.92,
            ic_april: 4.942,
            ic_may: 4.649,
            ic_june: 4.528,
            ic_july: 4.683,
            ic_august: 5.418,
            ic_september: 5.113,
            ic_october: 5.068,
            ic_november: 5.362,
            ic_december: 5.416,
            ic_yearly: 5.049,
          },
          {
            ic_uuid: "8583ef86-dcdc-4eb6-b7d5-5dd1f2e669dd",
            ic_lat: -196946,
            ic_lon: -46169,
            ic_city: "Campos Altos",
            ic_states: "MINAS GERAIS",
            ic_january: 5.506,
            ic_february: 5.745,
            ic_march: 4.747,
            ic_april: 4.744,
            ic_may: 4.364,
            ic_june: 4.179,
            ic_july: 4.444,
            ic_august: 5.479,
            ic_september: 5.454,
            ic_october: 5.345,
            ic_november: 4.914,
            ic_december: 5.342,
            ic_yearly: 5.022,
          },
          {
            ic_uuid: "e25191fd-138f-42f5-b2d2-a6924ad7a183",
            ic_lat: -130354,
            ic_lon: -467685,
            ic_city: "Campos Belos",
            ic_states: "GOIÁS",
            ic_january: 5.855,
            ic_february: 5.659,
            ic_march: 5.283,
            ic_april: 5.33,
            ic_may: 5.13,
            ic_june: 5.012,
            ic_july: 5.396,
            ic_august: 5.977,
            ic_september: 5.929,
            ic_october: 5.778,
            ic_november: 5.454,
            ic_december: 5.728,
            ic_yearly: 5.544,
          },
          {
            ic_uuid: "91604c9e-f27a-4599-9f05-e204190b3b16",
            ic_lat: -288876,
            ic_lon: -530013,
            ic_city: "Campos Borges",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.373,
            ic_february: 5.932,
            ic_march: 5.084,
            ic_april: 3.961,
            ic_may: 2.98,
            ic_june: 2.424,
            ic_july: 2.731,
            ic_august: 3.516,
            ic_september: 3.864,
            ic_october: 5.01,
            ic_november: 6.306,
            ic_december: 6.737,
            ic_yearly: 4.576,
          },
          {
            ic_uuid: "d7218a0c-f752-4e12-8e74-ddaee29792f0",
            ic_lat: -136918,
            ic_lon: -592572,
            ic_city: "Campos de Júlio",
            ic_states: "MATO GROSSO",
            ic_january: 4.871,
            ic_february: 4.722,
            ic_march: 4.666,
            ic_april: 4.655,
            ic_may: 4.27,
            ic_june: 4.307,
            ic_july: 4.434,
            ic_august: 4.985,
            ic_september: 4.862,
            ic_october: 5.033,
            ic_november: 5.11,
            ic_december: 5.225,
            ic_yearly: 4.762,
          },
          {
            ic_uuid: "59ed64a9-5f54-472d-af72-94c7abdb6082",
            ic_lat: -227301,
            ic_lon: -455837,
            ic_city: "Campos do Jordão",
            ic_states: "SÃO PAULO",
            ic_january: 5.016,
            ic_february: 5.289,
            ic_march: 4.541,
            ic_april: 4.267,
            ic_may: 3.666,
            ic_june: 3.482,
            ic_july: 3.649,
            ic_august: 4.636,
            ic_september: 4.76,
            ic_october: 5.096,
            ic_november: 4.968,
            ic_december: 5.41,
            ic_yearly: 4.565,
          },
          {
            ic_uuid: "16097baa-1448-4712-90ac-6fb2fcf3a3ea",
            ic_lat: -217627,
            ic_lon: -413184,
            ic_city: "Campos dos Goytacazes",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.329,
            ic_february: 6.543,
            ic_march: 5.245,
            ic_april: 4.523,
            ic_may: 3.791,
            ic_june: 3.536,
            ic_july: 3.585,
            ic_august: 4.367,
            ic_september: 4.699,
            ic_october: 5.099,
            ic_november: 5.153,
            ic_december: 6.039,
            ic_yearly: 4.909,
          },
          {
            ic_uuid: "b63e6bc3-4698-4077-9803-5ef0023fa34d",
            ic_lat: -212375,
            ic_lon: -457573,
            ic_city: "Campos Gerais",
            ic_states: "MINAS GERAIS",
            ic_january: 5.616,
            ic_february: 5.799,
            ic_march: 4.921,
            ic_april: 4.638,
            ic_may: 4.032,
            ic_june: 3.82,
            ic_july: 4.096,
            ic_august: 4.974,
            ic_september: 5.133,
            ic_october: 5.457,
            ic_november: 5.271,
            ic_december: 5.633,
            ic_yearly: 4.949,
          },
          {
            ic_uuid: "54013f6e-a644-4770-971d-e66612bf40d3",
            ic_lat: -79741,
            ic_lon: -468022,
            ic_city: "Campos Lindos",
            ic_states: "TOCANTINS",
            ic_january: 4.918,
            ic_february: 4.904,
            ic_march: 4.861,
            ic_april: 4.949,
            ic_may: 5.166,
            ic_june: 5.303,
            ic_july: 5.406,
            ic_august: 5.948,
            ic_september: 6.266,
            ic_october: 5.494,
            ic_november: 4.94,
            ic_december: 4.849,
            ic_yearly: 5.25,
          },
          {
            ic_uuid: "8445c208-d718-450c-a6ec-b277d0be3c44",
            ic_lat: -274007,
            ic_lon: -51228,
            ic_city: "Campos Novos",
            ic_states: "SANTA CATARINA",
            ic_january: 5.998,
            ic_february: 5.588,
            ic_march: 4.912,
            ic_april: 4.018,
            ic_may: 3.024,
            ic_june: 2.672,
            ic_july: 2.922,
            ic_august: 3.757,
            ic_september: 4.0,
            ic_october: 4.926,
            ic_november: 6.068,
            ic_december: 6.343,
            ic_yearly: 4.519,
          },
          {
            ic_uuid: "751c1364-cc2c-4a04-8f50-1cd703d89929",
            ic_lat: -226024,
            ic_lon: -499991,
            ic_city: "Campos Novos Paulista",
            ic_states: "SÃO PAULO",
            ic_january: 5.691,
            ic_february: 5.823,
            ic_march: 5.22,
            ic_april: 4.731,
            ic_may: 3.778,
            ic_june: 3.507,
            ic_july: 3.702,
            ic_august: 4.657,
            ic_september: 4.769,
            ic_october: 5.452,
            ic_november: 5.913,
            ic_december: 6.302,
            ic_yearly: 4.962,
          },
          {
            ic_uuid: "e8f02ac9-4fde-4028-a4c3-21eb3308f90b",
            ic_lat: -70722,
            ic_lon: -403758,
            ic_city: "Campos Sales",
            ic_states: "CEARÁ",
            ic_january: 5.569,
            ic_february: 5.569,
            ic_march: 5.616,
            ic_april: 5.353,
            ic_may: 5.118,
            ic_june: 5.013,
            ic_july: 5.469,
            ic_august: 6.168,
            ic_september: 6.588,
            ic_october: 6.479,
            ic_november: 6.494,
            ic_december: 6.03,
            ic_yearly: 5.789,
          },
          {
            ic_uuid: "6b73acef-441c-497e-8d50-2bb283540ec7",
            ic_lat: -142664,
            ic_lon: -496591,
            ic_city: "Campos Verdes",
            ic_states: "GOIÁS",
            ic_january: 5.463,
            ic_february: 5.607,
            ic_march: 5.307,
            ic_april: 5.226,
            ic_may: 4.968,
            ic_june: 4.646,
            ic_july: 5.033,
            ic_august: 5.722,
            ic_september: 5.619,
            ic_october: 5.67,
            ic_november: 5.313,
            ic_december: 5.464,
            ic_yearly: 5.337,
          },
          {
            ic_uuid: "077ab69b-c861-4507-a4ea-92a9927046d1",
            ic_lat: -74099,
            ic_lon: -352731,
            ic_city: "Camutanga",
            ic_states: "PERNAMBUCO",
            ic_january: 5.697,
            ic_february: 5.882,
            ic_march: 5.872,
            ic_april: 5.327,
            ic_may: 4.635,
            ic_june: 4.244,
            ic_july: 4.374,
            ic_august: 5.091,
            ic_september: 5.602,
            ic_october: 5.818,
            ic_november: 6.102,
            ic_december: 5.973,
            ic_yearly: 5.385,
          },
          {
            ic_uuid: "2a3ff534-2bf0-40e5-a565-946ab3fb4412",
            ic_lat: -210237,
            ic_lon: -451804,
            ic_city: "Cana Verde",
            ic_states: "MINAS GERAIS",
            ic_january: 5.604,
            ic_february: 5.796,
            ic_march: 4.907,
            ic_april: 4.734,
            ic_may: 4.045,
            ic_june: 3.865,
            ic_july: 4.09,
            ic_august: 5.023,
            ic_september: 5.212,
            ic_october: 5.415,
            ic_november: 5.177,
            ic_december: 5.606,
            ic_yearly: 4.956,
          },
          {
            ic_uuid: "9a081fd4-3019-4d04-8100-5247a40ec3d1",
            ic_lat: -206874,
            ic_lon: -42617,
            ic_city: "Canaã",
            ic_states: "MINAS GERAIS",
            ic_january: 5.738,
            ic_february: 6.06,
            ic_march: 5.076,
            ic_april: 4.489,
            ic_may: 3.872,
            ic_june: 3.673,
            ic_july: 3.844,
            ic_august: 4.66,
            ic_september: 4.977,
            ic_october: 5.223,
            ic_november: 4.95,
            ic_december: 5.629,
            ic_yearly: 4.849,
          },
          {
            ic_uuid: "b7fe9da7-10b7-4f27-8223-f915bde8b12c",
            ic_lat: -6497,
            ic_lon: -49878,
            ic_city: "Canaã dos Carajás",
            ic_states: "PARÁ",
            ic_january: 4.674,
            ic_february: 4.804,
            ic_march: 4.739,
            ic_april: 4.683,
            ic_may: 4.774,
            ic_june: 4.975,
            ic_july: 5.047,
            ic_august: 5.511,
            ic_september: 5.353,
            ic_october: 4.968,
            ic_november: 4.744,
            ic_december: 4.64,
            ic_yearly: 4.909,
          },
          {
            ic_uuid: "535f1777-9243-4c7f-833c-e67b3397f9b0",
            ic_lat: -1105,
            ic_lon: -518265,
            ic_city: "Canabrava do Norte",
            ic_states: "MATO GROSSO",
            ic_january: 5.09,
            ic_february: 5.184,
            ic_march: 4.98,
            ic_april: 4.84,
            ic_may: 4.883,
            ic_june: 4.857,
            ic_july: 4.996,
            ic_august: 5.638,
            ic_september: 5.261,
            ic_october: 5.206,
            ic_november: 5.126,
            ic_december: 5.242,
            ic_yearly: 5.109,
          },
          {
            ic_uuid: "4a11356b-3f08-4f5c-b190-dd02b0f4e32d",
            ic_lat: -250149,
            ic_lon: -479345,
            ic_city: "Cananéia",
            ic_states: "SÃO PAULO",
            ic_january: 5.251,
            ic_february: 5.216,
            ic_march: 4.379,
            ic_april: 3.759,
            ic_may: 3.011,
            ic_june: 2.585,
            ic_july: 2.613,
            ic_august: 3.267,
            ic_september: 3.353,
            ic_october: 3.768,
            ic_november: 4.776,
            ic_december: 5.336,
            ic_yearly: 3.943,
          },
          {
            ic_uuid: "ef138c28-e9c1-40dd-9adf-add1be5d66d1",
            ic_lat: -91164,
            ic_lon: -376028,
            ic_city: "Canapi",
            ic_states: "ALAGOAS",
            ic_january: 6.317,
            ic_february: 6.077,
            ic_march: 6.087,
            ic_april: 5.391,
            ic_may: 4.47,
            ic_june: 3.965,
            ic_july: 4.148,
            ic_august: 4.781,
            ic_september: 5.758,
            ic_october: 6.185,
            ic_november: 6.703,
            ic_december: 6.492,
            ic_yearly: 5.531,
          },
          {
            ic_uuid: "9f1550c7-7947-4c5e-a4b8-555175c797cb",
            ic_lat: -130729,
            ic_lon: -442013,
            ic_city: "Canápolis",
            ic_states: "BAHIA",
            ic_january: 6.42,
            ic_february: 6.274,
            ic_march: 5.691,
            ic_april: 5.499,
            ic_may: 5.242,
            ic_june: 5.036,
            ic_july: 5.421,
            ic_august: 5.975,
            ic_september: 6.275,
            ic_october: 6.256,
            ic_november: 5.672,
            ic_december: 6.16,
            ic_yearly: 5.827,
          },
          {
            ic_uuid: "be87f86f-f065-47aa-9525-8b6bac7b9513",
            ic_lat: -187217,
            ic_lon: -49204,
            ic_city: "Canápolis",
            ic_states: "MINAS GERAIS",
            ic_january: 5.716,
            ic_february: 5.822,
            ic_march: 5.317,
            ic_april: 5.083,
            ic_may: 4.603,
            ic_june: 4.337,
            ic_july: 4.523,
            ic_august: 5.473,
            ic_september: 5.28,
            ic_october: 5.62,
            ic_november: 5.768,
            ic_december: 5.874,
            ic_yearly: 5.285,
          },
          {
            ic_uuid: "2cfb55ce-8fde-45e2-a546-adc224296904",
            ic_lat: -116863,
            ic_lon: -41768,
            ic_city: "Canarana",
            ic_states: "BAHIA",
            ic_january: 6.312,
            ic_february: 6.158,
            ic_march: 5.937,
            ic_april: 5.07,
            ic_may: 4.551,
            ic_june: 4.339,
            ic_july: 4.554,
            ic_august: 5.289,
            ic_september: 6.11,
            ic_october: 6.306,
            ic_november: 6.109,
            ic_december: 6.401,
            ic_yearly: 5.595,
          },
          {
            ic_uuid: "bbbb3544-24b4-4cc7-adfe-2fb118ebf5ba",
            ic_lat: -135524,
            ic_lon: -5227,
            ic_city: "Canarana",
            ic_states: "MATO GROSSO",
            ic_january: 5.332,
            ic_february: 5.419,
            ic_march: 5.09,
            ic_april: 5.151,
            ic_may: 4.921,
            ic_june: 4.763,
            ic_july: 5.167,
            ic_august: 5.692,
            ic_september: 5.368,
            ic_october: 5.328,
            ic_november: 5.177,
            ic_december: 5.393,
            ic_yearly: 5.233,
          },
          {
            ic_uuid: "8b5325ff-51cf-40cd-b85f-aade22beb607",
            ic_lat: -227008,
            ic_lon: -450525,
            ic_city: "Canas",
            ic_states: "SÃO PAULO",
            ic_january: 5.479,
            ic_february: 5.726,
            ic_march: 4.857,
            ic_april: 4.425,
            ic_may: 3.669,
            ic_june: 3.457,
            ic_july: 3.568,
            ic_august: 4.537,
            ic_september: 4.649,
            ic_october: 5.113,
            ic_november: 5.139,
            ic_december: 5.749,
            ic_yearly: 4.698,
          },
          {
            ic_uuid: "0d581057-fc09-43b4-ade9-4b2d447bf36f",
            ic_lat: -76925,
            ic_lon: -437174,
            ic_city: "Canavieira",
            ic_states: "PIAUÍ",
            ic_january: 5.338,
            ic_february: 5.268,
            ic_march: 5.232,
            ic_april: 5.2,
            ic_may: 5.33,
            ic_june: 5.359,
            ic_july: 5.66,
            ic_august: 6.177,
            ic_september: 6.52,
            ic_october: 6.284,
            ic_november: 5.851,
            ic_december: 5.553,
            ic_yearly: 5.648,
          },
          {
            ic_uuid: "e46ebe1a-2876-46cb-9c01-4860f2749e33",
            ic_lat: -156727,
            ic_lon: -38954,
            ic_city: "Canavieiras",
            ic_states: "BAHIA",
            ic_january: 6.271,
            ic_february: 6.402,
            ic_march: 5.75,
            ic_april: 4.747,
            ic_may: 4.022,
            ic_june: 3.684,
            ic_july: 3.942,
            ic_august: 4.637,
            ic_september: 5.222,
            ic_october: 5.584,
            ic_november: 5.681,
            ic_december: 6.455,
            ic_yearly: 5.199,
          },
          {
            ic_uuid: "d6c508ce-a039-4d9b-91e8-43befa917063",
            ic_lat: -118053,
            ic_lon: -391206,
            ic_city: "Candeal",
            ic_states: "BAHIA",
            ic_january: 6.174,
            ic_february: 6.011,
            ic_march: 5.77,
            ic_april: 4.819,
            ic_may: 4.037,
            ic_june: 3.718,
            ic_july: 3.99,
            ic_august: 4.442,
            ic_september: 5.272,
            ic_october: 5.498,
            ic_november: 5.835,
            ic_december: 6.035,
            ic_yearly: 5.133,
          },
          {
            ic_uuid: "8d85f536-c2c4-428d-be11-35f7e7d28dbe",
            ic_lat: -126711,
            ic_lon: -385405,
            ic_city: "Candeias",
            ic_states: "BAHIA",
            ic_january: 5.976,
            ic_february: 5.757,
            ic_march: 5.611,
            ic_april: 4.514,
            ic_may: 3.909,
            ic_june: 3.615,
            ic_july: 3.866,
            ic_august: 4.292,
            ic_september: 5.095,
            ic_october: 5.413,
            ic_november: 5.679,
            ic_december: 6.065,
            ic_yearly: 4.983,
          },
          {
            ic_uuid: "0ec39caa-06a8-41dd-99ea-f3338867fbeb",
            ic_lat: -207697,
            ic_lon: -452768,
            ic_city: "Candeias",
            ic_states: "MINAS GERAIS",
            ic_january: 5.597,
            ic_february: 5.699,
            ic_march: 4.857,
            ic_april: 4.69,
            ic_may: 4.118,
            ic_june: 3.872,
            ic_july: 4.178,
            ic_august: 5.043,
            ic_september: 5.249,
            ic_october: 5.394,
            ic_november: 5.071,
            ic_december: 5.578,
            ic_yearly: 4.946,
          },
          {
            ic_uuid: "a46863ef-af6d-4a0e-b7c5-bdd483273b36",
            ic_lat: -87911,
            ic_lon: -63701,
            ic_city: "Candeias do Jamari",
            ic_states: "RONDÔNIA",
            ic_january: 4.244,
            ic_february: 4.383,
            ic_march: 4.346,
            ic_april: 4.323,
            ic_may: 4.003,
            ic_june: 4.364,
            ic_july: 4.487,
            ic_august: 4.74,
            ic_september: 4.772,
            ic_october: 4.865,
            ic_november: 4.657,
            ic_december: 4.393,
            ic_yearly: 4.465,
          },
          {
            ic_uuid: "6fd1a645-eb42-474a-b259-77327dc7fd86",
            ic_lat: -296689,
            ic_lon: -5279,
            ic_city: "Candelária",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.243,
            ic_february: 5.821,
            ic_march: 4.901,
            ic_april: 3.788,
            ic_may: 2.801,
            ic_june: 2.32,
            ic_july: 2.551,
            ic_august: 3.241,
            ic_september: 3.611,
            ic_october: 4.739,
            ic_november: 6.087,
            ic_december: 6.577,
            ic_yearly: 4.39,
          },
          {
            ic_uuid: "45c6d005-b022-4296-add6-a64716fd79ba",
            ic_lat: -144138,
            ic_lon: -42871,
            ic_city: "Candiba",
            ic_states: "BAHIA",
            ic_january: 6.609,
            ic_february: 6.663,
            ic_march: 6.024,
            ic_april: 5.454,
            ic_may: 4.858,
            ic_june: 4.641,
            ic_july: 4.865,
            ic_august: 5.57,
            ic_september: 6.029,
            ic_october: 6.445,
            ic_november: 5.949,
            ic_december: 6.532,
            ic_yearly: 5.804,
          },
          {
            ic_uuid: "5859857a-929a-4255-a5e6-0633768eeb94",
            ic_lat: -245654,
            ic_lon: -513376,
            ic_city: "Cândido de Abreu",
            ic_states: "PARANÁ",
            ic_january: 6.098,
            ic_february: 5.759,
            ic_march: 5.181,
            ic_april: 4.429,
            ic_may: 3.456,
            ic_june: 3.051,
            ic_july: 3.291,
            ic_august: 4.307,
            ic_september: 4.503,
            ic_october: 5.26,
            ic_november: 6.075,
            ic_december: 6.333,
            ic_yearly: 4.812,
          },
          {
            ic_uuid: "315fa1db-8816-4d25-9298-1244e33eade1",
            ic_lat: -27952,
            ic_lon: -547522,
            ic_city: "Cândido Godói",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.509,
            ic_february: 6.034,
            ic_march: 5.179,
            ic_april: 4.075,
            ic_may: 3.113,
            ic_june: 2.54,
            ic_july: 2.859,
            ic_august: 3.624,
            ic_september: 4.036,
            ic_october: 5.155,
            ic_november: 6.314,
            ic_december: 6.724,
            ic_yearly: 4.68,
          },
          {
            ic_uuid: "aa972545-9527-42f0-aaeb-455af092c961",
            ic_lat: -1449,
            ic_lon: -457336,
            ic_city: "Cândido Mendes",
            ic_states: "MARANHÃO",
            ic_january: 4.74,
            ic_february: 4.476,
            ic_march: 4.314,
            ic_april: 4.222,
            ic_may: 4.471,
            ic_june: 4.767,
            ic_july: 4.863,
            ic_august: 5.29,
            ic_september: 5.648,
            ic_october: 5.446,
            ic_november: 5.311,
            ic_december: 5.028,
            ic_yearly: 4.881,
          },
          {
            ic_uuid: "848e273a-304c-4fa2-b522-9504d48715a2",
            ic_lat: -227476,
            ic_lon: -503877,
            ic_city: "Cândido Mota",
            ic_states: "SÃO PAULO",
            ic_january: 5.704,
            ic_february: 5.802,
            ic_march: 5.209,
            ic_april: 4.654,
            ic_may: 3.763,
            ic_june: 3.476,
            ic_july: 3.695,
            ic_august: 4.623,
            ic_september: 4.751,
            ic_october: 5.408,
            ic_november: 5.951,
            ic_december: 6.275,
            ic_yearly: 4.943,
          },
          {
            ic_uuid: "f525037a-b3d5-422d-b2ae-aae9c849d7db",
            ic_lat: -21325,
            ic_lon: -48631,
            ic_city: "Cândido Rodrigues",
            ic_states: "SÃO PAULO",
            ic_january: 5.738,
            ic_february: 5.95,
            ic_march: 5.233,
            ic_april: 4.831,
            ic_may: 4.111,
            ic_june: 3.85,
            ic_july: 4.069,
            ic_august: 4.958,
            ic_september: 5.054,
            ic_october: 5.62,
            ic_november: 5.896,
            ic_december: 6.149,
            ic_yearly: 5.121,
          },
          {
            ic_uuid: "33452d1a-fa68-4d4d-9753-76c70184509d",
            ic_lat: -155064,
            ic_lon: -412381,
            ic_city: "Cândido Sales",
            ic_states: "BAHIA",
            ic_january: 5.999,
            ic_february: 6.118,
            ic_march: 5.356,
            ic_april: 4.714,
            ic_may: 4.008,
            ic_june: 3.643,
            ic_july: 3.884,
            ic_august: 4.495,
            ic_september: 5.461,
            ic_october: 5.562,
            ic_november: 5.362,
            ic_december: 6.061,
            ic_yearly: 5.055,
          },
          {
            ic_uuid: "0dc267dc-4a23-4227-b476-874ca8ada4f3",
            ic_lat: -315579,
            ic_lon: -536729,
            ic_city: "Candiota",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.424,
            ic_february: 5.807,
            ic_march: 4.934,
            ic_april: 3.72,
            ic_may: 2.669,
            ic_june: 2.276,
            ic_july: 2.501,
            ic_august: 3.146,
            ic_september: 3.745,
            ic_october: 5.068,
            ic_november: 6.399,
            ic_december: 6.933,
            ic_yearly: 4.468,
          },
          {
            ic_uuid: "98ed4983-2b39-4404-a207-3c36aee1272a",
            ic_lat: -255763,
            ic_lon: -520413,
            ic_city: "Candói",
            ic_states: "PARANÁ",
            ic_january: 6.061,
            ic_february: 5.566,
            ic_march: 5.155,
            ic_april: 4.247,
            ic_may: 3.287,
            ic_june: 2.884,
            ic_july: 3.113,
            ic_august: 4.135,
            ic_september: 4.297,
            ic_october: 5.18,
            ic_november: 6.018,
            ic_december: 6.272,
            ic_yearly: 4.685,
          },
          {
            ic_uuid: "2673bb0c-3ba1-48d7-9fdb-a37cd91882f4",
            ic_lat: -293565,
            ic_lon: -508124,
            ic_city: "Canela",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 5.973,
            ic_february: 5.553,
            ic_march: 4.607,
            ic_april: 3.756,
            ic_may: 2.798,
            ic_june: 2.38,
            ic_july: 2.609,
            ic_august: 3.318,
            ic_september: 3.54,
            ic_october: 4.615,
            ic_november: 5.977,
            ic_december: 6.358,
            ic_yearly: 4.29,
          },
          {
            ic_uuid: "a0bd3cf8-261d-404c-a932-6b2332b1fcad",
            ic_lat: -272621,
            ic_lon: -487662,
            ic_city: "Canelinha",
            ic_states: "SANTA CATARINA",
            ic_january: 5.527,
            ic_february: 5.272,
            ic_march: 4.483,
            ic_april: 3.645,
            ic_may: 3.019,
            ic_june: 2.491,
            ic_july: 2.659,
            ic_august: 3.305,
            ic_september: 3.45,
            ic_october: 3.984,
            ic_november: 5.162,
            ic_december: 5.605,
            ic_yearly: 4.05,
          },
          {
            ic_uuid: "634cb50f-edc4-4aca-8aea-01755a728df4",
            ic_lat: -63814,
            ic_lon: -351293,
            ic_city: "Canguaretama",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.868,
            ic_february: 6.019,
            ic_march: 6.059,
            ic_april: 5.457,
            ic_may: 4.862,
            ic_june: 4.424,
            ic_july: 4.547,
            ic_august: 5.299,
            ic_september: 5.818,
            ic_october: 6.021,
            ic_november: 6.171,
            ic_december: 6.024,
            ic_yearly: 5.548,
          },
          {
            ic_uuid: "ccf92ee7-ed2b-4554-a482-5f1f2cf2fac9",
            ic_lat: -313965,
            ic_lon: -526787,
            ic_city: "Canguçu",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.174,
            ic_february: 5.611,
            ic_march: 4.727,
            ic_april: 3.658,
            ic_may: 2.676,
            ic_june: 2.257,
            ic_july: 2.475,
            ic_august: 3.089,
            ic_september: 3.598,
            ic_october: 4.819,
            ic_november: 6.075,
            ic_december: 6.651,
            ic_yearly: 4.317,
          },
          {
            ic_uuid: "84774237-a146-47ed-8749-8f073b72d2d0",
            ic_lat: -101382,
            ic_lon: -369734,
            ic_city: "Canhoba",
            ic_states: "SERGIPE",
            ic_january: 6.221,
            ic_february: 6.007,
            ic_march: 5.932,
            ic_april: 5.205,
            ic_may: 4.363,
            ic_june: 4.123,
            ic_july: 4.185,
            ic_august: 4.773,
            ic_september: 5.552,
            ic_october: 5.852,
            ic_november: 6.291,
            ic_december: 6.284,
            ic_yearly: 5.399,
          },
          {
            ic_uuid: "16208653-6820-48a5-8384-46200b247405",
            ic_lat: -88769,
            ic_lon: -361982,
            ic_city: "Canhotinho",
            ic_states: "PERNAMBUCO",
            ic_january: 5.808,
            ic_february: 5.679,
            ic_march: 5.688,
            ic_april: 5.088,
            ic_may: 4.237,
            ic_june: 3.778,
            ic_july: 3.851,
            ic_august: 4.508,
            ic_september: 5.341,
            ic_october: 5.645,
            ic_november: 6.148,
            ic_december: 6.088,
            ic_yearly: 5.155,
          },
          {
            ic_uuid: "36423199-7b48-4f68-8a59-205ac3346ca3",
            ic_lat: -4352,
            ic_lon: -393158,
            ic_city: "Canindé",
            ic_states: "CEARÁ",
            ic_january: 5.296,
            ic_february: 5.465,
            ic_march: 5.474,
            ic_april: 5.006,
            ic_may: 5.097,
            ic_june: 4.965,
            ic_july: 5.336,
            ic_august: 6.078,
            ic_september: 6.594,
            ic_october: 6.442,
            ic_november: 6.226,
            ic_december: 5.58,
            ic_yearly: 5.63,
          },
          {
            ic_uuid: "e2599922-9c80-48f2-bdc4-6442bbfcae2e",
            ic_lat: -96606,
            ic_lon: -377891,
            ic_city: "Canindé de São Francisco",
            ic_states: "SERGIPE",
            ic_january: 6.323,
            ic_february: 6.069,
            ic_march: 5.96,
            ic_april: 5.28,
            ic_may: 4.374,
            ic_june: 4.0,
            ic_july: 4.105,
            ic_august: 4.794,
            ic_september: 5.54,
            ic_october: 5.905,
            ic_november: 6.427,
            ic_december: 6.402,
            ic_yearly: 5.432,
          },
          {
            ic_uuid: "4cc12017-fdcc-4cbf-af12-524f64a7aef2",
            ic_lat: -23006,
            ic_lon: -497829,
            ic_city: "Canitar",
            ic_states: "SÃO PAULO",
            ic_january: 5.659,
            ic_february: 5.804,
            ic_march: 5.203,
            ic_april: 4.702,
            ic_may: 3.727,
            ic_june: 3.429,
            ic_july: 3.631,
            ic_august: 4.637,
            ic_september: 4.73,
            ic_october: 5.397,
            ic_november: 5.905,
            ic_december: 6.295,
            ic_yearly: 4.926,
          },
          {
            ic_uuid: "8a27c1d7-6b0a-4ae7-bb0c-5db93318f39b",
            ic_lat: -299132,
            ic_lon: -511861,
            ic_city: "Canoas",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.34,
            ic_february: 5.783,
            ic_march: 4.815,
            ic_april: 3.789,
            ic_may: 2.781,
            ic_june: 2.315,
            ic_july: 2.515,
            ic_august: 3.195,
            ic_september: 3.577,
            ic_october: 4.73,
            ic_november: 6.185,
            ic_december: 6.627,
            ic_yearly: 4.388,
          },
          {
            ic_uuid: "8ec47372-02ff-444c-8ed3-e33e44f97963",
            ic_lat: -261771,
            ic_lon: -503954,
            ic_city: "Canoinhas",
            ic_states: "SANTA CATARINA",
            ic_january: 5.404,
            ic_february: 5.09,
            ic_march: 4.5,
            ic_april: 3.512,
            ic_may: 2.82,
            ic_june: 2.428,
            ic_july: 2.655,
            ic_august: 3.654,
            ic_september: 3.762,
            ic_october: 4.345,
            ic_november: 5.332,
            ic_december: 5.575,
            ic_yearly: 4.09,
          },
          {
            ic_uuid: "a6a8d5d7-ab1a-490a-995e-219461bd0568",
            ic_lat: -106651,
            ic_lon: -394947,
            ic_city: "Cansanção",
            ic_states: "BAHIA",
            ic_january: 6.063,
            ic_february: 5.869,
            ic_march: 5.873,
            ic_april: 4.939,
            ic_may: 4.211,
            ic_june: 3.848,
            ic_july: 4.075,
            ic_august: 4.597,
            ic_september: 5.619,
            ic_october: 5.713,
            ic_november: 6.134,
            ic_december: 6.107,
            ic_yearly: 5.254,
          },
          {
            ic_uuid: "ed0ee6ef-4753-42a9-900c-0a5e9c8ff706",
            ic_lat: 26105,
            ic_lon: -606029,
            ic_city: "Cantá",
            ic_states: "RORAIMA",
            ic_january: 4.58,
            ic_february: 4.666,
            ic_march: 4.944,
            ic_april: 4.676,
            ic_may: 4.324,
            ic_june: 4.558,
            ic_july: 4.587,
            ic_august: 5.091,
            ic_september: 5.621,
            ic_october: 5.468,
            ic_november: 5.187,
            ic_december: 4.702,
            ic_yearly: 4.867,
          },
          {
            ic_uuid: "bde41ea8-b2f6-407b-980a-6d77aab6836c",
            ic_lat: -185228,
            ic_lon: -426253,
            ic_city: "Cantagalo",
            ic_states: "MINAS GERAIS",
            ic_january: 5.865,
            ic_february: 6.111,
            ic_march: 5.117,
            ic_april: 4.506,
            ic_may: 3.884,
            ic_june: 3.68,
            ic_july: 3.797,
            ic_august: 4.511,
            ic_september: 5.029,
            ic_october: 5.238,
            ic_november: 4.807,
            ic_december: 5.681,
            ic_yearly: 4.852,
          },
          {
            ic_uuid: "4d8a148e-ac5a-4dc7-9286-be585fb38702",
            ic_lat: -253738,
            ic_lon: -521203,
            ic_city: "Cantagalo",
            ic_states: "PARANÁ",
            ic_january: 6.02,
            ic_february: 5.561,
            ic_march: 5.139,
            ic_april: 4.292,
            ic_may: 3.335,
            ic_june: 2.919,
            ic_july: 3.165,
            ic_august: 4.164,
            ic_september: 4.347,
            ic_october: 5.153,
            ic_november: 6.036,
            ic_december: 6.187,
            ic_yearly: 4.693,
          },
          {
            ic_uuid: "2d832634-35aa-4c93-8f0d-5010d6de84fa",
            ic_lat: -219802,
            ic_lon: -423668,
            ic_city: "Cantagalo",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.878,
            ic_february: 6.137,
            ic_march: 5.01,
            ic_april: 4.33,
            ic_may: 3.62,
            ic_june: 3.361,
            ic_july: 3.537,
            ic_august: 4.247,
            ic_september: 4.556,
            ic_october: 4.853,
            ic_november: 4.825,
            ic_december: 5.665,
            ic_yearly: 4.668,
          },
          {
            ic_uuid: "f2d1ec88-28b2-41f6-8002-cef1d8d07159",
            ic_lat: -36345,
            ic_lon: -443806,
            ic_city: "Cantanhede",
            ic_states: "MARANHÃO",
            ic_january: 4.61,
            ic_february: 4.816,
            ic_march: 4.919,
            ic_april: 4.826,
            ic_may: 4.882,
            ic_june: 5.091,
            ic_july: 5.379,
            ic_august: 5.854,
            ic_september: 6.237,
            ic_october: 5.878,
            ic_november: 5.491,
            ic_december: 5.118,
            ic_yearly: 5.258,
          },
          {
            ic_uuid: "12b87250-30cc-4fc5-8332-18075fe5b764",
            ic_lat: -81115,
            ic_lon: -429521,
            ic_city: "Canto do Buriti",
            ic_states: "PIAUÍ",
            ic_january: 5.549,
            ic_february: 5.566,
            ic_march: 5.478,
            ic_april: 5.393,
            ic_may: 5.304,
            ic_june: 5.287,
            ic_july: 5.54,
            ic_august: 6.312,
            ic_september: 6.516,
            ic_october: 6.424,
            ic_november: 6.04,
            ic_december: 5.853,
            ic_yearly: 5.772,
          },
          {
            ic_uuid: "c6f56888-3feb-4ed4-ba34-d9a3332816ce",
            ic_lat: -98989,
            ic_lon: -390281,
            ic_city: "Canudos",
            ic_states: "BAHIA",
            ic_january: 6.297,
            ic_february: 5.948,
            ic_march: 5.879,
            ic_april: 5.183,
            ic_may: 4.365,
            ic_june: 3.971,
            ic_july: 4.154,
            ic_august: 4.753,
            ic_september: 5.768,
            ic_october: 5.965,
            ic_november: 6.327,
            ic_december: 6.334,
            ic_yearly: 5.412,
          },
          {
            ic_uuid: "d034a0e7-00cc-48c3-b362-5430f1a0cd71",
            ic_lat: -293277,
            ic_lon: -522396,
            ic_city: "Canudos do Vale",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.116,
            ic_february: 5.699,
            ic_march: 4.808,
            ic_april: 3.796,
            ic_may: 2.823,
            ic_june: 2.369,
            ic_july: 2.632,
            ic_august: 3.363,
            ic_september: 3.63,
            ic_october: 4.67,
            ic_november: 6.062,
            ic_december: 6.464,
            ic_yearly: 4.37,
          },
          {
            ic_uuid: "149e1516-e1a0-4b62-b6f5-375f83de1b44",
            ic_lat: -65262,
            ic_lon: -643957,
            ic_city: "Canutama",
            ic_states: "AMAZONAS",
            ic_january: 4.217,
            ic_february: 4.448,
            ic_march: 4.27,
            ic_april: 4.228,
            ic_may: 3.865,
            ic_june: 4.237,
            ic_july: 4.384,
            ic_august: 4.938,
            ic_september: 4.922,
            ic_october: 4.903,
            ic_november: 4.665,
            ic_december: 4.339,
            ic_yearly: 4.451,
          },
          {
            ic_uuid: "5c3ec34b-5a5b-43cc-bb13-562044ac7c3c",
            ic_lat: -12057,
            ic_lon: -471781,
            ic_city: "Capanema",
            ic_states: "PARÁ",
            ic_january: 4.41,
            ic_february: 4.212,
            ic_march: 4.16,
            ic_april: 4.265,
            ic_may: 4.518,
            ic_june: 4.755,
            ic_july: 4.884,
            ic_august: 5.218,
            ic_september: 5.502,
            ic_october: 5.294,
            ic_november: 5.066,
            ic_december: 4.712,
            ic_yearly: 4.75,
          },
          {
            ic_uuid: "b53f42ac-68d7-410b-b655-8f4f1a6cf305",
            ic_lat: -256696,
            ic_lon: -53806,
            ic_city: "Capanema",
            ic_states: "PARANÁ",
            ic_january: 6.365,
            ic_february: 5.855,
            ic_march: 5.32,
            ic_april: 4.252,
            ic_may: 3.272,
            ic_june: 2.807,
            ic_july: 3.063,
            ic_august: 4.043,
            ic_september: 4.257,
            ic_october: 5.281,
            ic_november: 6.146,
            ic_december: 6.507,
            ic_yearly: 4.764,
          },
          {
            ic_uuid: "1ba7dca9-aa0f-4eaa-97aa-89b62b0de7fd",
            ic_lat: -279394,
            ic_lon: -505102,
            ic_city: "Capão Alto",
            ic_states: "SANTA CATARINA",
            ic_january: 5.834,
            ic_february: 5.5,
            ic_march: 4.647,
            ic_april: 3.79,
            ic_may: 2.878,
            ic_june: 2.465,
            ic_july: 2.749,
            ic_august: 3.584,
            ic_september: 3.734,
            ic_october: 4.566,
            ic_november: 5.79,
            ic_december: 6.121,
            ic_yearly: 4.305,
          },
          {
            ic_uuid: "36346dad-4739-4363-b7c6-d971b01aae00",
            ic_lat: -24006,
            ic_lon: -483489,
            ic_city: "Capão Bonito",
            ic_states: "SÃO PAULO",
            ic_january: 5.425,
            ic_february: 5.485,
            ic_march: 4.775,
            ic_april: 4.199,
            ic_may: 3.356,
            ic_june: 3.044,
            ic_july: 3.201,
            ic_august: 4.124,
            ic_september: 4.187,
            ic_october: 4.726,
            ic_november: 5.362,
            ic_december: 5.879,
            ic_yearly: 4.48,
          },
          {
            ic_uuid: "cc10cda1-27ba-4e58-aec3-2d5159491240",
            ic_lat: -281259,
            ic_lon: -513965,
            ic_city: "Capão Bonito do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.197,
            ic_february: 5.797,
            ic_march: 4.923,
            ic_april: 3.946,
            ic_may: 2.988,
            ic_june: 2.584,
            ic_july: 2.872,
            ic_august: 3.647,
            ic_september: 3.886,
            ic_october: 4.933,
            ic_november: 6.141,
            ic_december: 6.428,
            ic_yearly: 4.528,
          },
          {
            ic_uuid: "ae1faf5f-c3d5-4f8a-aca8-f5023a623735",
            ic_lat: -297647,
            ic_lon: -500287,
            ic_city: "Capão da Canoa",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.257,
            ic_february: 5.594,
            ic_march: 4.731,
            ic_april: 3.886,
            ic_may: 2.909,
            ic_june: 2.417,
            ic_july: 2.616,
            ic_august: 3.233,
            ic_september: 3.573,
            ic_october: 4.608,
            ic_november: 6.019,
            ic_december: 6.425,
            ic_yearly: 4.356,
          },
          {
            ic_uuid: "b1ed1064-ac19-4c94-9304-5e8f5fb21abf",
            ic_lat: -289317,
            ic_lon: -545563,
            ic_city: "Capão do Cipó",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.522,
            ic_february: 6.082,
            ic_march: 5.183,
            ic_april: 4.002,
            ic_may: 3.043,
            ic_june: 2.488,
            ic_july: 2.822,
            ic_august: 3.567,
            ic_september: 4.022,
            ic_october: 5.155,
            ic_november: 6.393,
            ic_december: 6.859,
            ic_yearly: 4.678,
          },
          {
            ic_uuid: "1b89a8a9-d8ac-4a81-8d4d-b1b7ab23e351",
            ic_lat: -317569,
            ic_lon: -524893,
            ic_city: "Capão do Leão",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.221,
            ic_february: 5.573,
            ic_march: 4.746,
            ic_april: 3.603,
            ic_may: 2.64,
            ic_june: 2.235,
            ic_july: 2.405,
            ic_august: 2.991,
            ic_september: 3.533,
            ic_october: 4.77,
            ic_november: 6.11,
            ic_december: 6.626,
            ic_yearly: 4.288,
          },
          {
            ic_uuid: "1d325c73-55e7-4d85-b4b8-b4b0acede4f0",
            ic_lat: -205294,
            ic_lon: -419064,
            ic_city: "Caparaó",
            ic_states: "MINAS GERAIS",
            ic_january: 5.72,
            ic_february: 5.946,
            ic_march: 4.9,
            ic_april: 4.397,
            ic_may: 3.791,
            ic_june: 3.642,
            ic_july: 3.811,
            ic_august: 4.512,
            ic_september: 4.897,
            ic_october: 4.968,
            ic_november: 4.713,
            ic_december: 5.444,
            ic_yearly: 4.728,
          },
          {
            ic_uuid: "ee71ebf8-72e7-4251-9080-0ce3a216d3f8",
            ic_lat: -94155,
            ic_lon: -360829,
            ic_city: "Capela",
            ic_states: "ALAGOAS",
            ic_january: 5.778,
            ic_february: 5.673,
            ic_march: 5.736,
            ic_april: 5.048,
            ic_may: 4.201,
            ic_june: 3.836,
            ic_july: 3.923,
            ic_august: 4.497,
            ic_september: 5.334,
            ic_october: 5.583,
            ic_november: 6.137,
            ic_december: 6.091,
            ic_yearly: 5.153,
          },
          {
            ic_uuid: "9f335dbd-77a4-4f80-8669-d74798b112d6",
            ic_lat: -105063,
            ic_lon: -370539,
            ic_city: "Capela",
            ic_states: "SERGIPE",
            ic_january: 6.163,
            ic_february: 5.848,
            ic_march: 5.8,
            ic_april: 5.055,
            ic_may: 4.275,
            ic_june: 3.977,
            ic_july: 4.086,
            ic_august: 4.62,
            ic_september: 5.38,
            ic_october: 5.668,
            ic_november: 6.09,
            ic_december: 6.134,
            ic_yearly: 5.258,
          },
          {
            ic_uuid: "31a57438-492d-4285-805b-500b16ababbf",
            ic_lat: -29692,
            ic_lon: -513181,
            ic_city: "Capela de Santana",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.25,
            ic_february: 5.784,
            ic_march: 4.875,
            ic_april: 3.791,
            ic_may: 2.816,
            ic_june: 2.331,
            ic_july: 2.527,
            ic_august: 3.205,
            ic_september: 3.548,
            ic_october: 4.659,
            ic_november: 6.149,
            ic_december: 6.603,
            ic_yearly: 4.378,
          },
          {
            ic_uuid: "618da2e3-c6d4-428f-8f4e-5177eae45c9a",
            ic_lat: -234689,
            ic_lon: -477392,
            ic_city: "Capela do Alto",
            ic_states: "SÃO PAULO",
            ic_january: 5.51,
            ic_february: 5.725,
            ic_march: 5.053,
            ic_april: 4.451,
            ic_may: 3.582,
            ic_june: 3.356,
            ic_july: 3.466,
            ic_august: 4.444,
            ic_september: 4.615,
            ic_october: 5.221,
            ic_november: 5.687,
            ic_december: 6.157,
            ic_yearly: 4.772,
          },
          {
            ic_uuid: "adccbac9-19ef-45d2-92d6-fe78f022fa12",
            ic_lat: -116662,
            ic_lon: -398352,
            ic_city: "Capela do Alto Alegre",
            ic_states: "BAHIA",
            ic_january: 6.074,
            ic_february: 5.919,
            ic_march: 5.777,
            ic_april: 4.707,
            ic_may: 3.954,
            ic_june: 3.608,
            ic_july: 3.834,
            ic_august: 4.411,
            ic_september: 5.336,
            ic_october: 5.489,
            ic_november: 5.783,
            ic_december: 6.013,
            ic_yearly: 5.075,
          },
          {
            ic_uuid: "b5d8deda-9f32-4db1-ab0e-904ac8f1920d",
            ic_lat: -209184,
            ic_lon: -436223,
            ic_city: "Capela Nova",
            ic_states: "MINAS GERAIS",
            ic_january: 5.537,
            ic_february: 5.832,
            ic_march: 4.857,
            ic_april: 4.311,
            ic_may: 3.712,
            ic_june: 3.56,
            ic_july: 3.75,
            ic_august: 4.655,
            ic_september: 4.899,
            ic_october: 5.083,
            ic_november: 4.751,
            ic_december: 5.498,
            ic_yearly: 4.704,
          },
          {
            ic_uuid: "da50fc1d-2e42-436a-a6e5-96ba3601934f",
            ic_lat: -176893,
            ic_lon: -425151,
            ic_city: "Capelinha",
            ic_states: "MINAS GERAIS",
            ic_january: 5.858,
            ic_february: 6.098,
            ic_march: 5.146,
            ic_april: 4.489,
            ic_may: 3.989,
            ic_june: 3.815,
            ic_july: 3.986,
            ic_august: 4.775,
            ic_september: 5.317,
            ic_october: 5.401,
            ic_november: 4.899,
            ic_december: 5.749,
            ic_yearly: 4.96,
          },
          {
            ic_uuid: "ddaf78aa-4059-4504-98ae-ab8f182d9495",
            ic_lat: -206168,
            ic_lon: -470575,
            ic_city: "Capetinga",
            ic_states: "MINAS GERAIS",
            ic_january: 5.54,
            ic_february: 5.806,
            ic_march: 4.939,
            ic_april: 4.803,
            ic_may: 4.243,
            ic_june: 4.048,
            ic_july: 4.307,
            ic_august: 5.208,
            ic_september: 5.16,
            ic_october: 5.477,
            ic_november: 5.452,
            ic_december: 5.625,
            ic_yearly: 5.051,
          },
          {
            ic_uuid: "94cf1ce4-9a9f-4387-b688-3cd631aff42b",
            ic_lat: -69202,
            ic_lon: -351669,
            ic_city: "Capim",
            ic_states: "PARAÍBA",
            ic_january: 5.78,
            ic_february: 5.802,
            ic_march: 5.917,
            ic_april: 5.303,
            ic_may: 4.688,
            ic_june: 4.296,
            ic_july: 4.362,
            ic_august: 5.136,
            ic_september: 5.574,
            ic_october: 5.86,
            ic_november: 6.216,
            ic_december: 6.058,
            ic_yearly: 5.416,
          },
          {
            ic_uuid: "40894614-87a5-4857-be2b-ca8f7c633e27",
            ic_lat: -195475,
            ic_lon: -441307,
            ic_city: "Capim Branco",
            ic_states: "MINAS GERAIS",
            ic_january: 5.953,
            ic_february: 6.149,
            ic_march: 5.247,
            ic_april: 4.92,
            ic_may: 4.352,
            ic_june: 4.122,
            ic_july: 4.429,
            ic_august: 5.279,
            ic_september: 5.642,
            ic_october: 5.683,
            ic_november: 5.444,
            ic_december: 5.683,
            ic_yearly: 5.242,
          },
          {
            ic_uuid: "16028440-ce2b-4ffa-a029-306ca4bd69b3",
            ic_lat: -113801,
            ic_lon: -400093,
            ic_city: "Capim Grosso",
            ic_states: "BAHIA",
            ic_january: 6.06,
            ic_february: 5.922,
            ic_march: 5.754,
            ic_april: 4.682,
            ic_may: 4.023,
            ic_june: 3.728,
            ic_july: 3.925,
            ic_august: 4.527,
            ic_september: 5.408,
            ic_october: 5.504,
            ic_november: 5.774,
            ic_december: 5.924,
            ic_yearly: 5.102,
          },
          {
            ic_uuid: "9ede3fb3-183e-45fe-9332-68496b20c8de",
            ic_lat: -186867,
            ic_lon: -49571,
            ic_city: "Capinópolis",
            ic_states: "MINAS GERAIS",
            ic_january: 5.747,
            ic_february: 5.787,
            ic_march: 5.189,
            ic_april: 5.083,
            ic_may: 4.562,
            ic_june: 4.301,
            ic_july: 4.514,
            ic_august: 5.401,
            ic_september: 5.249,
            ic_october: 5.622,
            ic_november: 5.759,
            ic_december: 5.895,
            ic_yearly: 5.259,
          },
          {
            ic_uuid: "5bb6e6a4-8053-4b9d-ac57-8de86db1354a",
            ic_lat: -273478,
            ic_lon: -516061,
            ic_city: "Capinzal",
            ic_states: "SANTA CATARINA",
            ic_january: 6.049,
            ic_february: 5.584,
            ic_march: 5.048,
            ic_april: 4.065,
            ic_may: 3.078,
            ic_june: 2.634,
            ic_july: 2.931,
            ic_august: 3.752,
            ic_september: 4.02,
            ic_october: 4.981,
            ic_november: 6.172,
            ic_december: 6.414,
            ic_yearly: 4.561,
          },
          {
            ic_uuid: "23c936ee-1825-4a20-958b-1269cbc5a13f",
            ic_lat: -4724,
            ic_lon: -443284,
            ic_city: "Capinzal do Norte",
            ic_states: "MARANHÃO",
            ic_january: 4.828,
            ic_february: 4.881,
            ic_march: 4.914,
            ic_april: 4.874,
            ic_may: 4.879,
            ic_june: 5.054,
            ic_july: 5.385,
            ic_august: 5.968,
            ic_september: 6.271,
            ic_october: 5.913,
            ic_november: 5.495,
            ic_december: 5.111,
            ic_yearly: 5.298,
          },
          {
            ic_uuid: "f1524cad-fffb-4058-96f8-4e55ad1cb796",
            ic_lat: -44561,
            ic_lon: -389052,
            ic_city: "Capistrano",
            ic_states: "CEARÁ",
            ic_january: 5.327,
            ic_february: 5.352,
            ic_march: 5.349,
            ic_april: 4.832,
            ic_may: 4.982,
            ic_june: 4.832,
            ic_july: 5.219,
            ic_august: 5.99,
            ic_september: 6.356,
            ic_october: 6.263,
            ic_november: 6.114,
            ic_december: 5.558,
            ic_yearly: 5.514,
          },
          {
            ic_uuid: "1bf5839c-549f-4de5-91e4-4a7d9d41471d",
            ic_lat: -292679,
            ic_lon: -519857,
            ic_city: "Capitão",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.072,
            ic_february: 5.688,
            ic_march: 4.77,
            ic_april: 3.782,
            ic_may: 2.817,
            ic_june: 2.337,
            ic_july: 2.593,
            ic_august: 3.334,
            ic_september: 3.603,
            ic_october: 4.65,
            ic_november: 6.05,
            ic_december: 6.426,
            ic_yearly: 4.343,
          },
          {
            ic_uuid: "35e7be13-c485-45ad-99b7-f6341199dab8",
            ic_lat: -190753,
            ic_lon: -418618,
            ic_city: "Capitão Andrade",
            ic_states: "MINAS GERAIS",
            ic_january: 6.0,
            ic_february: 6.119,
            ic_march: 5.23,
            ic_april: 4.607,
            ic_may: 3.824,
            ic_june: 3.619,
            ic_july: 3.733,
            ic_august: 4.421,
            ic_september: 4.895,
            ic_october: 5.121,
            ic_november: 4.939,
            ic_december: 5.809,
            ic_yearly: 4.86,
          },
          {
            ic_uuid: "46308e9f-768a-4759-be00-ba3ae6fae3db",
            ic_lat: -44574,
            ic_lon: -419443,
            ic_city: "Capitão de Campos",
            ic_states: "PIAUÍ",
            ic_january: 5.198,
            ic_february: 5.337,
            ic_march: 5.324,
            ic_april: 5.038,
            ic_may: 5.157,
            ic_june: 5.3,
            ic_july: 5.585,
            ic_august: 6.177,
            ic_september: 6.573,
            ic_october: 6.448,
            ic_november: 6.287,
            ic_december: 5.723,
            ic_yearly: 5.679,
          },
          {
            ic_uuid: "6a04591c-1934-4056-ad95-cf1e61ea3fbb",
            ic_lat: -16327,
            ic_lon: -437088,
            ic_city: "Capitão Enéas",
            ic_states: "MINAS GERAIS",
            ic_january: 6.37,
            ic_february: 6.623,
            ic_march: 5.705,
            ic_april: 5.375,
            ic_may: 4.854,
            ic_june: 4.582,
            ic_july: 4.891,
            ic_august: 5.674,
            ic_september: 6.042,
            ic_october: 6.017,
            ic_november: 5.586,
            ic_december: 6.236,
            ic_yearly: 5.663,
          },
          {
            ic_uuid: "d738d238-8dbc-4939-a7af-c1e7f1f29f37",
            ic_lat: -84963,
            ic_lon: -418167,
            ic_city: "Capitão Gervásio Oliveira",
            ic_states: "PIAUÍ",
            ic_january: 5.902,
            ic_february: 5.735,
            ic_march: 5.64,
            ic_april: 5.318,
            ic_may: 5.165,
            ic_june: 5.114,
            ic_july: 5.537,
            ic_august: 6.347,
            ic_september: 6.577,
            ic_october: 6.559,
            ic_november: 6.288,
            ic_december: 6.079,
            ic_yearly: 5.855,
          },
          {
            ic_uuid: "7b9561a8-ca06-4ec4-8f7a-150d20d721a1",
            ic_lat: -254821,
            ic_lon: -536117,
            ic_city: "Capitão Leônidas Marques",
            ic_states: "PARANÁ",
            ic_january: 6.199,
            ic_february: 5.732,
            ic_march: 5.246,
            ic_april: 4.222,
            ic_may: 3.285,
            ic_june: 2.841,
            ic_july: 3.056,
            ic_august: 4.067,
            ic_september: 4.24,
            ic_october: 5.256,
            ic_november: 6.096,
            ic_december: 6.349,
            ic_yearly: 4.716,
          },
          {
            ic_uuid: "d7382bf0-13f6-4868-8d1e-441bb71ebed7",
            ic_lat: -17482,
            ic_lon: -470633,
            ic_city: "Capitão Poço",
            ic_states: "PARÁ",
            ic_january: 4.593,
            ic_february: 4.398,
            ic_march: 4.451,
            ic_april: 4.445,
            ic_may: 4.567,
            ic_june: 4.905,
            ic_july: 5.05,
            ic_august: 5.345,
            ic_september: 5.508,
            ic_october: 5.295,
            ic_november: 5.045,
            ic_december: 4.655,
            ic_yearly: 4.855,
          },
          {
            ic_uuid: "8a86b79f-d37b-4950-9f97-0f8a2b34ec2c",
            ic_lat: -206168,
            ic_lon: -460496,
            ic_city: "Capitólio",
            ic_states: "MINAS GERAIS",
            ic_january: 5.588,
            ic_february: 5.864,
            ic_march: 4.869,
            ic_april: 4.767,
            ic_may: 4.211,
            ic_june: 3.995,
            ic_july: 4.261,
            ic_august: 5.141,
            ic_september: 5.257,
            ic_october: 5.533,
            ic_november: 5.182,
            ic_december: 5.519,
            ic_yearly: 5.016,
          },
          {
            ic_uuid: "f3fca023-1d2f-4ef5-acee-746beb03f34b",
            ic_lat: -229956,
            ic_lon: -475076,
            ic_city: "Capivari",
            ic_states: "SÃO PAULO",
            ic_january: 5.63,
            ic_february: 5.868,
            ic_march: 5.116,
            ic_april: 4.593,
            ic_may: 3.771,
            ic_june: 3.483,
            ic_july: 3.623,
            ic_august: 4.586,
            ic_september: 4.755,
            ic_october: 5.419,
            ic_november: 5.716,
            ic_december: 6.135,
            ic_yearly: 4.891,
          },
          {
            ic_uuid: "3b0ac777-b1ae-4e7e-bbfc-c0ec1afc4701",
            ic_lat: -284503,
            ic_lon: -489636,
            ic_city: "Capivari de Baixo",
            ic_states: "SANTA CATARINA",
            ic_january: 5.848,
            ic_february: 5.421,
            ic_march: 4.595,
            ic_april: 3.81,
            ic_may: 2.991,
            ic_june: 2.533,
            ic_july: 2.751,
            ic_august: 3.482,
            ic_september: 3.611,
            ic_october: 4.455,
            ic_november: 5.738,
            ic_december: 6.143,
            ic_yearly: 4.281,
          },
          {
            ic_uuid: "a9436d90-2a72-4d34-8669-f033a17edec0",
            ic_lat: -30145,
            ic_lon: -505149,
            ic_city: "Capivari do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.513,
            ic_february: 5.882,
            ic_march: 4.843,
            ic_april: 3.872,
            ic_may: 2.803,
            ic_june: 2.358,
            ic_july: 2.547,
            ic_august: 3.201,
            ic_september: 3.56,
            ic_october: 4.796,
            ic_november: 6.2,
            ic_december: 6.681,
            ic_yearly: 4.438,
          },
          {
            ic_uuid: "bc9ce159-165c-487f-a450-8b41ab358f1b",
            ic_lat: -105749,
            ic_lon: -676768,
            ic_city: "Capixaba",
            ic_states: "ACRE",
            ic_january: 4.486,
            ic_february: 4.527,
            ic_march: 4.264,
            ic_april: 4.395,
            ic_may: 3.957,
            ic_june: 3.983,
            ic_july: 4.228,
            ic_august: 4.823,
            ic_september: 5.07,
            ic_october: 5.138,
            ic_november: 4.952,
            ic_december: 4.677,
            ic_yearly: 4.542,
          },
          {
            ic_uuid: "a0a10911-2e57-4ea0-9106-933f66908100",
            ic_lat: -87406,
            ic_lon: -36628,
            ic_city: "Capoeiras",
            ic_states: "PERNAMBUCO",
            ic_january: 6.211,
            ic_february: 5.931,
            ic_march: 5.942,
            ic_april: 5.365,
            ic_may: 4.394,
            ic_june: 4.003,
            ic_july: 4.113,
            ic_august: 4.9,
            ic_september: 5.752,
            ic_october: 6.135,
            ic_november: 6.575,
            ic_december: 6.418,
            ic_yearly: 5.478,
          },
          {
            ic_uuid: "cdc3c8f9-2528-4590-b0b2-d832c7392866",
            ic_lat: -201708,
            ic_lon: -422687,
            ic_city: "Caputira",
            ic_states: "MINAS GERAIS",
            ic_january: 5.677,
            ic_february: 5.873,
            ic_march: 4.97,
            ic_april: 4.519,
            ic_may: 3.847,
            ic_june: 3.677,
            ic_july: 3.857,
            ic_august: 4.588,
            ic_september: 4.922,
            ic_october: 5.04,
            ic_november: 4.833,
            ic_december: 5.57,
            ic_yearly: 4.781,
          },
          {
            ic_uuid: "55fc9051-a933-4c40-8112-c291d77ff04d",
            ic_lat: -297899,
            ic_lon: -50435,
            ic_city: "Caraá",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.369,
            ic_february: 5.769,
            ic_march: 4.783,
            ic_april: 3.827,
            ic_may: 2.807,
            ic_june: 2.34,
            ic_july: 2.561,
            ic_august: 3.197,
            ic_september: 3.551,
            ic_october: 4.706,
            ic_november: 6.171,
            ic_december: 6.604,
            ic_yearly: 4.391,
          },
          {
            ic_uuid: "40d84a57-173e-4e4d-837e-588b642bf51c",
            ic_lat: 18199,
            ic_lon: -611282,
            ic_city: "Caracaraí",
            ic_states: "RORAIMA",
            ic_january: 4.624,
            ic_february: 4.755,
            ic_march: 4.922,
            ic_april: 4.587,
            ic_may: 4.194,
            ic_june: 4.5,
            ic_july: 4.496,
            ic_august: 4.993,
            ic_september: 5.46,
            ic_october: 5.393,
            ic_november: 5.136,
            ic_december: 4.807,
            ic_yearly: 4.822,
          },
          {
            ic_uuid: "3dd13b4b-90cf-4e82-8d73-aa3787d87f6c",
            ic_lat: -220115,
            ic_lon: -570281,
            ic_city: "Caracol",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 6.07,
            ic_february: 5.674,
            ic_march: 5.293,
            ic_april: 4.565,
            ic_may: 3.564,
            ic_june: 3.189,
            ic_july: 3.309,
            ic_august: 4.322,
            ic_september: 4.659,
            ic_october: 5.398,
            ic_november: 5.998,
            ic_december: 6.435,
            ic_yearly: 4.873,
          },
          {
            ic_uuid: "ccff4805-549d-4fae-a169-b4b85ece86e5",
            ic_lat: -92798,
            ic_lon: -433293,
            ic_city: "Caracol",
            ic_states: "PIAUÍ",
            ic_january: 5.964,
            ic_february: 5.65,
            ic_march: 5.601,
            ic_april: 5.345,
            ic_may: 5.289,
            ic_june: 5.284,
            ic_july: 5.669,
            ic_august: 6.449,
            ic_september: 6.571,
            ic_october: 6.427,
            ic_november: 6.091,
            ic_december: 5.952,
            ic_yearly: 5.857,
          },
          {
            ic_uuid: "500b5ae0-6daf-4c31-83f7-2824d33c59b9",
            ic_lat: -23613,
            ic_lon: -454129,
            ic_city: "Caraguatatuba",
            ic_states: "SÃO PAULO",
            ic_january: 5.316,
            ic_february: 5.588,
            ic_march: 4.599,
            ic_april: 4.07,
            ic_may: 3.325,
            ic_june: 3.013,
            ic_july: 3.097,
            ic_august: 3.839,
            ic_september: 3.762,
            ic_october: 4.249,
            ic_november: 4.591,
            ic_december: 5.333,
            ic_yearly: 4.232,
          },
          {
            ic_uuid: "66e831d3-c5ff-4778-8312-df40b724d7b0",
            ic_lat: -171866,
            ic_lon: -417007,
            ic_city: "Caraí",
            ic_states: "MINAS GERAIS",
            ic_january: 5.948,
            ic_february: 6.256,
            ic_march: 5.257,
            ic_april: 4.534,
            ic_may: 3.916,
            ic_june: 3.639,
            ic_july: 3.831,
            ic_august: 4.647,
            ic_september: 5.188,
            ic_october: 5.455,
            ic_november: 5.076,
            ic_december: 5.823,
            ic_yearly: 4.964,
          },
          {
            ic_uuid: "cb3662f9-dfbe-4c09-8b0d-131449093ced",
            ic_lat: -147255,
            ic_lon: -412557,
            ic_city: "Caraíbas",
            ic_states: "BAHIA",
            ic_january: 6.265,
            ic_february: 6.303,
            ic_march: 5.732,
            ic_april: 5.003,
            ic_may: 4.314,
            ic_june: 3.915,
            ic_july: 4.171,
            ic_august: 4.767,
            ic_september: 5.647,
            ic_october: 5.851,
            ic_november: 5.744,
            ic_december: 6.354,
            ic_yearly: 5.339,
          },
          {
            ic_uuid: "4e573fea-248d-4335-86c0-9648ea0bb1de",
            ic_lat: -249483,
            ic_lon: -501102,
            ic_city: "Carambeí",
            ic_states: "PARANÁ",
            ic_january: 5.686,
            ic_february: 5.445,
            ic_march: 4.813,
            ic_april: 4.204,
            ic_may: 3.298,
            ic_june: 2.965,
            ic_july: 3.218,
            ic_august: 4.185,
            ic_september: 4.324,
            ic_october: 4.974,
            ic_november: 5.704,
            ic_december: 5.96,
            ic_yearly: 4.565,
          },
          {
            ic_uuid: "dfc86ef6-42c1-4fc3-ae74-a169d713dd89",
            ic_lat: -208711,
            ic_lon: -437421,
            ic_city: "Caranaíba",
            ic_states: "MINAS GERAIS",
            ic_january: 5.477,
            ic_february: 5.858,
            ic_march: 4.834,
            ic_april: 4.348,
            ic_may: 3.757,
            ic_june: 3.599,
            ic_july: 3.79,
            ic_august: 4.7,
            ic_september: 4.943,
            ic_october: 5.128,
            ic_november: 4.824,
            ic_december: 5.509,
            ic_yearly: 4.731,
          },
          {
            ic_uuid: "05b224f3-dd21-429c-a7e2-fb2e43b81a97",
            ic_lat: -209571,
            ic_lon: -438114,
            ic_city: "Carandaí",
            ic_states: "MINAS GERAIS",
            ic_january: 5.593,
            ic_february: 5.886,
            ic_march: 4.89,
            ic_april: 4.404,
            ic_may: 3.784,
            ic_june: 3.646,
            ic_july: 3.884,
            ic_august: 4.797,
            ic_september: 4.998,
            ic_october: 5.225,
            ic_november: 4.951,
            ic_december: 5.559,
            ic_yearly: 4.801,
          },
          {
            ic_uuid: "ace8031f-6387-4f36-bafa-7512b3906f01",
            ic_lat: -207348,
            ic_lon: -420317,
            ic_city: "Carangola",
            ic_states: "MINAS GERAIS",
            ic_january: 5.874,
            ic_february: 6.117,
            ic_march: 5.022,
            ic_april: 4.509,
            ic_may: 3.828,
            ic_june: 3.708,
            ic_july: 3.809,
            ic_august: 4.605,
            ic_september: 4.975,
            ic_october: 5.082,
            ic_november: 4.84,
            ic_december: 5.679,
            ic_yearly: 4.837,
          },
          {
            ic_uuid: "e00d0cf2-e4b8-40cd-947c-2b713ede2745",
            ic_lat: -221826,
            ic_lon: -416634,
            ic_city: "Carapebus",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.3,
            ic_february: 6.486,
            ic_march: 5.142,
            ic_april: 4.56,
            ic_may: 3.778,
            ic_june: 3.5,
            ic_july: 3.562,
            ic_august: 4.361,
            ic_september: 4.628,
            ic_october: 5.06,
            ic_november: 5.067,
            ic_december: 5.941,
            ic_yearly: 4.865,
          },
          {
            ic_uuid: "738f954a-f4d8-4625-8cce-7a1f3c7da726",
            ic_lat: -235239,
            ic_lon: -468411,
            ic_city: "Carapicuíba",
            ic_states: "SÃO PAULO",
            ic_january: 5.274,
            ic_february: 5.565,
            ic_march: 4.769,
            ic_april: 4.246,
            ic_may: 3.481,
            ic_june: 3.203,
            ic_july: 3.31,
            ic_august: 4.277,
            ic_september: 4.311,
            ic_october: 4.81,
            ic_november: 5.254,
            ic_december: 5.794,
            ic_yearly: 4.525,
          },
          {
            ic_uuid: "de31ab20-0bd1-459b-b5b6-44e292288c9f",
            ic_lat: -197873,
            ic_lon: -421296,
            ic_city: "Caratinga",
            ic_states: "MINAS GERAIS",
            ic_january: 5.656,
            ic_february: 5.878,
            ic_march: 4.89,
            ic_april: 4.445,
            ic_may: 3.802,
            ic_june: 3.594,
            ic_july: 3.749,
            ic_august: 4.448,
            ic_september: 4.828,
            ic_october: 4.963,
            ic_november: 4.783,
            ic_december: 5.546,
            ic_yearly: 4.715,
          },
          {
            ic_uuid: "9469d4c9-7fbb-453b-a304-0eb5cd52fd39",
            ic_lat: -48823,
            ic_lon: -669095,
            ic_city: "Carauari",
            ic_states: "AMAZONAS",
            ic_january: 4.117,
            ic_february: 4.503,
            ic_march: 4.364,
            ic_april: 4.224,
            ic_may: 3.846,
            ic_june: 4.128,
            ic_july: 4.267,
            ic_august: 4.888,
            ic_september: 4.957,
            ic_october: 4.941,
            ic_november: 4.618,
            ic_december: 4.332,
            ic_yearly: 4.432,
          },
          {
            ic_uuid: "e8b40950-9c51-400a-b0aa-1440561c0a57",
            ic_lat: -77283,
            ic_lon: -364935,
            ic_city: "Caraúbas",
            ic_states: "PARAÍBA",
            ic_january: 5.969,
            ic_february: 5.887,
            ic_march: 5.949,
            ic_april: 5.474,
            ic_may: 4.759,
            ic_june: 4.249,
            ic_july: 4.433,
            ic_august: 5.161,
            ic_september: 5.867,
            ic_october: 6.166,
            ic_november: 6.371,
            ic_december: 6.119,
            ic_yearly: 5.533,
          },
          {
            ic_uuid: "193191c3-2ecc-4f51-9ebd-6a021aedf4f7",
            ic_lat: -57843,
            ic_lon: -375589,
            ic_city: "Caraúbas",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.978,
            ic_february: 6.137,
            ic_march: 6.045,
            ic_april: 5.777,
            ic_may: 5.374,
            ic_june: 5.032,
            ic_july: 5.388,
            ic_august: 5.964,
            ic_september: 6.352,
            ic_october: 6.52,
            ic_november: 6.561,
            ic_december: 6.052,
            ic_yearly: 5.932,
          },
          {
            ic_uuid: "29238a0c-43f0-4a33-b7cf-9a6fa209f725",
            ic_lat: -34756,
            ic_lon: -418429,
            ic_city: "Caraúbas do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.127,
            ic_february: 5.247,
            ic_march: 5.171,
            ic_april: 4.905,
            ic_may: 5.16,
            ic_june: 5.343,
            ic_july: 5.502,
            ic_august: 5.927,
            ic_september: 6.402,
            ic_october: 6.436,
            ic_november: 6.199,
            ic_december: 5.502,
            ic_yearly: 5.577,
          },
          {
            ic_uuid: "7da5c769-1db3-457d-82d0-2cda8acc4e9d",
            ic_lat: -177273,
            ic_lon: -3926,
            ic_city: "Caravelas",
            ic_states: "BAHIA",
            ic_january: 6.289,
            ic_february: 6.304,
            ic_march: 5.496,
            ic_april: 4.515,
            ic_may: 3.867,
            ic_june: 3.596,
            ic_july: 3.756,
            ic_august: 4.493,
            ic_september: 4.983,
            ic_october: 5.398,
            ic_november: 5.462,
            ic_december: 6.343,
            ic_yearly: 5.042,
          },
          {
            ic_uuid: "622704d5-0a70-49ce-8c7f-9ab79ad9f86d",
            ic_lat: -282963,
            ic_lon: -527937,
            ic_city: "Carazinho",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.223,
            ic_february: 5.88,
            ic_march: 5.007,
            ic_april: 4.032,
            ic_may: 3.037,
            ic_june: 2.512,
            ic_july: 2.812,
            ic_august: 3.585,
            ic_september: 3.863,
            ic_october: 5.037,
            ic_november: 6.23,
            ic_december: 6.566,
            ic_yearly: 4.565,
          },
          {
            ic_uuid: "3dbee236-2a5c-422a-99c7-2811b82ba744",
            ic_lat: -17526,
            ic_lon: -43014,
            ic_city: "Carbonita",
            ic_states: "MINAS GERAIS",
            ic_january: 6.006,
            ic_february: 6.297,
            ic_march: 5.201,
            ic_april: 4.641,
            ic_may: 4.113,
            ic_june: 4.011,
            ic_july: 4.21,
            ic_august: 5.006,
            ic_september: 5.472,
            ic_october: 5.586,
            ic_november: 5.073,
            ic_december: 5.783,
            ic_yearly: 5.116,
          },
          {
            ic_uuid: "2d22030a-1712-4529-bd19-d1c96bc11e9a",
            ic_lat: -119418,
            ic_lon: -379487,
            ic_city: "Cardeal da Silva",
            ic_states: "BAHIA",
            ic_january: 6.092,
            ic_february: 5.872,
            ic_march: 5.746,
            ic_april: 4.759,
            ic_may: 4.046,
            ic_june: 3.846,
            ic_july: 4.018,
            ic_august: 4.453,
            ic_september: 5.234,
            ic_october: 5.575,
            ic_november: 5.911,
            ic_december: 6.177,
            ic_yearly: 5.144,
          },
          {
            ic_uuid: "41af8efe-bb29-4f8e-81e3-34eb349dad06",
            ic_lat: -20082,
            ic_lon: -499139,
            ic_city: "Cardoso",
            ic_states: "SÃO PAULO",
            ic_january: 5.849,
            ic_february: 5.977,
            ic_march: 5.365,
            ic_april: 4.93,
            ic_may: 4.245,
            ic_june: 3.923,
            ic_july: 4.207,
            ic_august: 5.045,
            ic_september: 5.13,
            ic_october: 5.709,
            ic_november: 5.919,
            ic_december: 6.257,
            ic_yearly: 5.213,
          },
          {
            ic_uuid: "b3363942-b900-4118-a5ca-f5cbcce5058c",
            ic_lat: -214898,
            ic_lon: -416155,
            ic_city: "Cardoso Moreira",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.316,
            ic_february: 6.425,
            ic_march: 5.229,
            ic_april: 4.521,
            ic_may: 3.827,
            ic_june: 3.543,
            ic_july: 3.644,
            ic_august: 4.385,
            ic_september: 4.829,
            ic_october: 5.164,
            ic_november: 5.135,
            ic_december: 5.986,
            ic_yearly: 4.917,
          },
          {
            ic_uuid: "98ee09cc-fedc-485e-9c8d-d0e6ae04214c",
            ic_lat: -220429,
            ic_lon: -456964,
            ic_city: "Careaçu",
            ic_states: "MINAS GERAIS",
            ic_january: 5.417,
            ic_february: 5.714,
            ic_march: 4.869,
            ic_april: 4.599,
            ic_may: 3.91,
            ic_june: 3.671,
            ic_july: 3.88,
            ic_august: 4.789,
            ic_september: 4.967,
            ic_october: 5.393,
            ic_november: 5.267,
            ic_december: 5.636,
            ic_yearly: 4.843,
          },
          {
            ic_uuid: "16de1413-0779-4d2e-a353-dd0a170b6ee7",
            ic_lat: -37684,
            ic_lon: -603695,
            ic_city: "Careiro",
            ic_states: "AMAZONAS",
            ic_january: 3.973,
            ic_february: 4.06,
            ic_march: 4.048,
            ic_april: 3.826,
            ic_may: 3.792,
            ic_june: 4.243,
            ic_july: 4.373,
            ic_august: 4.926,
            ic_september: 4.952,
            ic_october: 4.811,
            ic_november: 4.711,
            ic_december: 4.169,
            ic_yearly: 4.324,
          },
          {
            ic_uuid: "5fa6b18f-4c11-45bc-ae8c-690d246a68ac",
            ic_lat: -31973,
            ic_lon: -598269,
            ic_city: "Careiro da Várzea",
            ic_states: "AMAZONAS",
            ic_january: 4.114,
            ic_february: 4.194,
            ic_march: 4.197,
            ic_april: 4.03,
            ic_may: 4.034,
            ic_june: 4.568,
            ic_july: 4.607,
            ic_august: 5.163,
            ic_september: 5.216,
            ic_october: 5.001,
            ic_november: 4.878,
            ic_december: 4.338,
            ic_yearly: 4.528,
          },
          {
            ic_uuid: "a609175e-80aa-4b57-936c-589b092b2a34",
            ic_lat: -202637,
            ic_lon: -404169,
            ic_city: "Cariacica",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.037,
            ic_february: 6.346,
            ic_march: 5.239,
            ic_april: 4.452,
            ic_may: 3.803,
            ic_june: 3.563,
            ic_july: 3.631,
            ic_august: 4.258,
            ic_september: 4.713,
            ic_october: 4.792,
            ic_november: 4.843,
            ic_december: 5.722,
            ic_yearly: 4.783,
          },
          {
            ic_uuid: "fc7c5660-4645-4ed3-8032-408860481cb1",
            ic_lat: -42255,
            ic_lon: -391915,
            ic_city: "Caridade",
            ic_states: "CEARÁ",
            ic_january: 5.304,
            ic_february: 5.511,
            ic_march: 5.328,
            ic_april: 4.939,
            ic_may: 5.242,
            ic_june: 5.132,
            ic_july: 5.402,
            ic_august: 6.199,
            ic_september: 6.612,
            ic_october: 6.518,
            ic_november: 6.352,
            ic_december: 5.636,
            ic_yearly: 5.681,
          },
          {
            ic_uuid: "ae2f3e8b-8fd6-4628-b387-b73de8f917f3",
            ic_lat: -77348,
            ic_lon: -409851,
            ic_city: "Caridade do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.701,
            ic_february: 5.554,
            ic_march: 5.666,
            ic_april: 5.331,
            ic_may: 5.157,
            ic_june: 5.123,
            ic_july: 5.544,
            ic_august: 6.315,
            ic_september: 6.687,
            ic_october: 6.593,
            ic_november: 6.435,
            ic_december: 6.111,
            ic_yearly: 5.851,
          },
          {
            ic_uuid: "7d012ae6-74a1-4d57-81c7-5d4aec22cec2",
            ic_lat: -14299,
            ic_lon: -437727,
            ic_city: "Carinhanha",
            ic_states: "BAHIA",
            ic_january: 6.556,
            ic_february: 6.704,
            ic_march: 6.032,
            ic_april: 5.596,
            ic_may: 5.051,
            ic_june: 4.839,
            ic_july: 5.147,
            ic_august: 5.681,
            ic_september: 6.052,
            ic_october: 6.378,
            ic_november: 5.888,
            ic_december: 6.44,
            ic_yearly: 5.864,
          },
          {
            ic_uuid: "84124d67-9dac-4808-8467-1e23b283d5d6",
            ic_lat: -10357,
            ic_lon: -377032,
            ic_city: "Carira",
            ic_states: "SERGIPE",
            ic_january: 6.104,
            ic_february: 5.974,
            ic_march: 5.801,
            ic_april: 5.078,
            ic_may: 4.28,
            ic_june: 3.956,
            ic_july: 4.094,
            ic_august: 4.675,
            ic_september: 5.442,
            ic_october: 5.655,
            ic_november: 6.188,
            ic_december: 6.169,
            ic_yearly: 5.285,
          },
          {
            ic_uuid: "9e289e07-0fbe-44eb-b51a-e986cf7d68b0",
            ic_lat: -39509,
            ic_lon: -404759,
            ic_city: "Cariré",
            ic_states: "CEARÁ",
            ic_january: 5.163,
            ic_february: 5.46,
            ic_march: 5.312,
            ic_april: 4.951,
            ic_may: 5.001,
            ic_june: 5.004,
            ic_july: 5.253,
            ic_august: 5.985,
            ic_september: 6.508,
            ic_october: 6.364,
            ic_november: 6.112,
            ic_december: 5.497,
            ic_yearly: 5.551,
          },
          {
            ic_uuid: "824a41a5-9174-4250-8237-492691378ae4",
            ic_lat: -118885,
            ic_lon: -491613,
            ic_city: "Cariri do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 5.32,
            ic_february: 5.375,
            ic_march: 5.026,
            ic_april: 5.051,
            ic_may: 5.032,
            ic_june: 4.802,
            ic_july: 5.132,
            ic_august: 5.716,
            ic_september: 5.727,
            ic_october: 5.454,
            ic_november: 5.213,
            ic_december: 5.272,
            ic_yearly: 5.26,
          },
          {
            ic_uuid: "9c23b63f-733e-4b59-bfd7-519c59e04417",
            ic_lat: -70344,
            ic_lon: -392825,
            ic_city: "Caririaçu",
            ic_states: "CEARÁ",
            ic_january: 5.805,
            ic_february: 5.819,
            ic_march: 5.787,
            ic_april: 5.478,
            ic_may: 5.16,
            ic_june: 5.022,
            ic_july: 5.313,
            ic_august: 6.052,
            ic_september: 6.525,
            ic_october: 6.524,
            ic_november: 6.591,
            ic_december: 6.106,
            ic_yearly: 5.849,
          },
          {
            ic_uuid: "c2bbd5ec-3a2d-400b-9373-96f15f12e38a",
            ic_lat: -65333,
            ic_lon: -394966,
            ic_city: "Cariús",
            ic_states: "CEARÁ",
            ic_january: 5.687,
            ic_february: 5.753,
            ic_march: 5.784,
            ic_april: 5.426,
            ic_may: 5.196,
            ic_june: 5.111,
            ic_july: 5.407,
            ic_august: 6.164,
            ic_september: 6.485,
            ic_october: 6.461,
            ic_november: 6.479,
            ic_december: 6.01,
            ic_yearly: 5.83,
          },
          {
            ic_uuid: "62c505b3-48f8-4a44-8b98-76c9ff4e3516",
            ic_lat: -99495,
            ic_lon: -558421,
            ic_city: "Carlinda",
            ic_states: "MATO GROSSO",
            ic_january: 4.824,
            ic_february: 4.791,
            ic_march: 4.664,
            ic_april: 4.629,
            ic_may: 4.616,
            ic_june: 4.673,
            ic_july: 4.831,
            ic_august: 5.284,
            ic_september: 5.097,
            ic_october: 5.184,
            ic_november: 5.058,
            ic_december: 4.98,
            ic_yearly: 4.886,
          },
          {
            ic_uuid: "65e8a3ca-fbdc-420e-b6bf-432141eac744",
            ic_lat: -23425,
            ic_lon: -497209,
            ic_city: "Carlópolis",
            ic_states: "PARANÁ",
            ic_january: 5.746,
            ic_february: 5.81,
            ic_march: 5.18,
            ic_april: 4.553,
            ic_may: 3.583,
            ic_june: 3.23,
            ic_july: 3.491,
            ic_august: 4.462,
            ic_september: 4.617,
            ic_october: 5.291,
            ic_november: 5.953,
            ic_december: 6.374,
            ic_yearly: 4.857,
          },
          {
            ic_uuid: "8f57bc01-4aed-45a1-9195-c5a29ad6168e",
            ic_lat: -292974,
            ic_lon: -515033,
            ic_city: "Carlos Barbosa",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.168,
            ic_february: 5.688,
            ic_march: 4.743,
            ic_april: 3.824,
            ic_may: 2.82,
            ic_june: 2.386,
            ic_july: 2.648,
            ic_august: 3.376,
            ic_september: 3.617,
            ic_october: 4.73,
            ic_november: 6.124,
            ic_december: 6.506,
            ic_yearly: 4.386,
          },
          {
            ic_uuid: "7f2d5176-6de9-43eb-a039-940074b60d3e",
            ic_lat: -176978,
            ic_lon: -407726,
            ic_city: "Carlos Chagas",
            ic_states: "MINAS GERAIS",
            ic_january: 6.129,
            ic_february: 6.244,
            ic_march: 5.36,
            ic_april: 4.653,
            ic_may: 3.839,
            ic_june: 3.521,
            ic_july: 3.609,
            ic_august: 4.272,
            ic_september: 4.883,
            ic_october: 5.185,
            ic_november: 5.161,
            ic_december: 6.051,
            ic_yearly: 4.909,
          },
          {
            ic_uuid: "0d3f0783-8160-457b-b9ce-f111ea5d590c",
            ic_lat: -277172,
            ic_lon: -519126,
            ic_city: "Carlos Gomes",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.226,
            ic_february: 5.797,
            ic_march: 5.04,
            ic_april: 4.047,
            ic_may: 3.056,
            ic_june: 2.538,
            ic_july: 2.888,
            ic_august: 3.687,
            ic_september: 3.945,
            ic_october: 5.067,
            ic_november: 6.231,
            ic_december: 6.534,
            ic_yearly: 4.588,
          },
          {
            ic_uuid: "4c320112-8f15-4389-b35c-25dfc644eb34",
            ic_lat: -190882,
            ic_lon: -431386,
            ic_city: "Carmésia",
            ic_states: "MINAS GERAIS",
            ic_january: 5.604,
            ic_february: 5.893,
            ic_march: 4.922,
            ic_april: 4.471,
            ic_may: 3.856,
            ic_june: 3.658,
            ic_july: 3.85,
            ic_august: 4.653,
            ic_september: 4.998,
            ic_october: 5.118,
            ic_november: 4.758,
            ic_december: 5.466,
            ic_yearly: 4.77,
          },
          {
            ic_uuid: "83c1ca5f-a78a-4c05-8ccb-d5f77f80e1d6",
            ic_lat: -219314,
            ic_lon: -42605,
            ic_city: "Carmo",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.92,
            ic_february: 6.217,
            ic_march: 5.226,
            ic_april: 4.528,
            ic_may: 3.697,
            ic_june: 3.425,
            ic_july: 3.586,
            ic_august: 4.357,
            ic_september: 4.693,
            ic_october: 4.99,
            ic_november: 4.95,
            ic_december: 5.816,
            ic_yearly: 4.784,
          },
          {
            ic_uuid: "d40660cf-40e2-4a0c-a501-f810cd766def",
            ic_lat: -214638,
            ic_lon: -452204,
            ic_city: "Carmo da Cachoeira",
            ic_states: "MINAS GERAIS",
            ic_january: 5.457,
            ic_february: 5.659,
            ic_march: 4.823,
            ic_april: 4.539,
            ic_may: 3.903,
            ic_june: 3.741,
            ic_july: 4.005,
            ic_august: 4.89,
            ic_september: 5.071,
            ic_october: 5.357,
            ic_november: 5.177,
            ic_december: 5.594,
            ic_yearly: 4.852,
          },
          {
            ic_uuid: "b063872b-ecb1-4207-b263-07abf12d42f1",
            ic_lat: -20558,
            ic_lon: -448739,
            ic_city: "Carmo da Mata",
            ic_states: "MINAS GERAIS",
            ic_january: 5.69,
            ic_february: 5.941,
            ic_march: 5.015,
            ic_april: 4.808,
            ic_may: 4.144,
            ic_june: 4.008,
            ic_july: 4.229,
            ic_august: 5.096,
            ic_september: 5.347,
            ic_october: 5.527,
            ic_november: 5.207,
            ic_december: 5.623,
            ic_yearly: 5.053,
          },
          {
            ic_uuid: "054ed700-e7ae-4bda-9ac2-8f188e2cd2d0",
            ic_lat: -221208,
            ic_lon: -451311,
            ic_city: "Carmo de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.326,
            ic_february: 5.672,
            ic_march: 4.895,
            ic_april: 4.56,
            ic_may: 3.904,
            ic_june: 3.608,
            ic_july: 3.837,
            ic_august: 4.711,
            ic_september: 4.976,
            ic_october: 5.367,
            ic_november: 5.25,
            ic_december: 5.603,
            ic_yearly: 4.809,
          },
          {
            ic_uuid: "88cd6a27-1c03-41c0-9e36-4ad9cb30382c",
            ic_lat: -201916,
            ic_lon: -447668,
            ic_city: "Carmo do Cajuru",
            ic_states: "MINAS GERAIS",
            ic_january: 5.844,
            ic_february: 6.026,
            ic_march: 5.087,
            ic_april: 4.859,
            ic_may: 4.254,
            ic_june: 4.084,
            ic_july: 4.322,
            ic_august: 5.198,
            ic_september: 5.429,
            ic_october: 5.615,
            ic_november: 5.312,
            ic_december: 5.653,
            ic_yearly: 5.14,
          },
          {
            ic_uuid: "be9c7460-095d-41fb-957b-a07206a0de0a",
            ic_lat: -189979,
            ic_lon: -463134,
            ic_city: "Carmo do Paranaíba",
            ic_states: "MINAS GERAIS",
            ic_january: 5.738,
            ic_february: 6.012,
            ic_march: 5.052,
            ic_april: 4.998,
            ic_may: 4.569,
            ic_june: 4.294,
            ic_july: 4.657,
            ic_august: 5.6,
            ic_september: 5.611,
            ic_october: 5.57,
            ic_november: 5.287,
            ic_december: 5.637,
            ic_yearly: 5.252,
          },
          {
            ic_uuid: "62328699-d364-4156-879f-c67bc9e29bf7",
            ic_lat: -209741,
            ic_lon: -461153,
            ic_city: "Carmo do Rio Claro",
            ic_states: "MINAS GERAIS",
            ic_january: 5.618,
            ic_february: 5.831,
            ic_march: 4.878,
            ic_april: 4.675,
            ic_may: 4.064,
            ic_june: 3.815,
            ic_july: 4.105,
            ic_august: 5.009,
            ic_september: 5.151,
            ic_october: 5.462,
            ic_november: 5.331,
            ic_december: 5.605,
            ic_yearly: 4.962,
          },
          {
            ic_uuid: "d7ab52c9-4366-4260-9ec9-7b3d8e428a44",
            ic_lat: -153554,
            ic_lon: -497084,
            ic_city: "Carmo do Rio Verde",
            ic_states: "GOIÁS",
            ic_january: 5.347,
            ic_february: 5.596,
            ic_march: 5.204,
            ic_april: 5.176,
            ic_may: 4.963,
            ic_june: 4.699,
            ic_july: 4.828,
            ic_august: 5.76,
            ic_september: 5.493,
            ic_october: 5.558,
            ic_november: 5.205,
            ic_december: 5.371,
            ic_yearly: 5.267,
          },
          {
            ic_uuid: "dd292933-ffd2-457a-bda3-6f974d4b50a0",
            ic_lat: -7033,
            ic_lon: -483982,
            ic_city: "Carmolândia",
            ic_states: "TOCANTINS",
            ic_january: 4.768,
            ic_february: 4.802,
            ic_march: 4.595,
            ic_april: 4.774,
            ic_may: 4.888,
            ic_june: 5.199,
            ic_july: 5.232,
            ic_august: 5.866,
            ic_september: 5.693,
            ic_october: 4.973,
            ic_november: 4.712,
            ic_december: 4.578,
            ic_yearly: 5.007,
          },
          {
            ic_uuid: "e15de22c-2fcc-4674-990a-b0034158a61c",
            ic_lat: -10649,
            ic_lon: -369842,
            ic_city: "Carmópolis",
            ic_states: "SERGIPE",
            ic_january: 6.24,
            ic_february: 6.066,
            ic_march: 5.957,
            ic_april: 5.115,
            ic_may: 4.333,
            ic_june: 4.056,
            ic_july: 4.108,
            ic_august: 4.746,
            ic_september: 5.449,
            ic_october: 5.788,
            ic_november: 6.278,
            ic_december: 6.293,
            ic_yearly: 5.369,
          },
          {
            ic_uuid: "53438b14-3aad-4e06-9bd1-6530b7059fa8",
            ic_lat: -2054,
            ic_lon: -446339,
            ic_city: "Carmópolis de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.693,
            ic_february: 5.901,
            ic_march: 5.062,
            ic_april: 4.806,
            ic_may: 4.156,
            ic_june: 3.986,
            ic_july: 4.261,
            ic_august: 5.1,
            ic_september: 5.36,
            ic_october: 5.538,
            ic_november: 5.268,
            ic_december: 5.668,
            ic_yearly: 5.066,
          },
          {
            ic_uuid: "5c7b243f-5b62-4370-aa2c-3a272b8303b5",
            ic_lat: -78048,
            ic_lon: -377957,
            ic_city: "Carnaíba",
            ic_states: "PERNAMBUCO",
            ic_january: 6.262,
            ic_february: 6.168,
            ic_march: 6.132,
            ic_april: 5.74,
            ic_may: 4.968,
            ic_june: 4.534,
            ic_july: 4.705,
            ic_august: 5.523,
            ic_september: 6.379,
            ic_october: 6.536,
            ic_november: 6.753,
            ic_december: 6.417,
            ic_yearly: 5.843,
          },
          {
            ic_uuid: "1882a71a-2d1b-4bb7-9196-65d3dbdd6dd5",
            ic_lat: -65572,
            ic_lon: -365912,
            ic_city: "Carnaúba dos Dantas",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.935,
            ic_february: 5.868,
            ic_march: 5.941,
            ic_april: 5.517,
            ic_may: 4.956,
            ic_june: 4.533,
            ic_july: 4.839,
            ic_august: 5.608,
            ic_september: 6.147,
            ic_october: 6.413,
            ic_november: 6.476,
            ic_december: 6.067,
            ic_yearly: 5.692,
          },
          {
            ic_uuid: "f872eec6-4855-4b6c-b61f-9d36ed61496d",
            ic_lat: -53386,
            ic_lon: -368346,
            ic_city: "Carnaubais",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.935,
            ic_february: 5.924,
            ic_march: 5.921,
            ic_april: 5.514,
            ic_may: 5.297,
            ic_june: 4.873,
            ic_july: 5.1,
            ic_august: 5.815,
            ic_september: 6.235,
            ic_october: 6.44,
            ic_november: 6.476,
            ic_december: 6.122,
            ic_yearly: 5.804,
          },
          {
            ic_uuid: "ecd3b846-7fd4-4b6e-9c8d-e14a42964555",
            ic_lat: -41602,
            ic_lon: -409416,
            ic_city: "Carnaubal",
            ic_states: "CEARÁ",
            ic_january: 5.296,
            ic_february: 5.353,
            ic_march: 5.327,
            ic_april: 4.888,
            ic_may: 4.932,
            ic_june: 4.991,
            ic_july: 5.35,
            ic_august: 6.092,
            ic_september: 6.562,
            ic_october: 6.474,
            ic_november: 6.411,
            ic_december: 5.717,
            ic_yearly: 5.616,
          },
          {
            ic_uuid: "79214f93-99a1-4dee-a39f-6cf84047b1b1",
            ic_lat: -83197,
            ic_lon: -387438,
            ic_city: "Carnaubeira da Penha",
            ic_states: "PERNAMBUCO",
            ic_january: 6.192,
            ic_february: 6.081,
            ic_march: 6.055,
            ic_april: 5.548,
            ic_may: 4.795,
            ic_june: 4.317,
            ic_july: 4.581,
            ic_august: 5.383,
            ic_september: 6.374,
            ic_october: 6.499,
            ic_november: 6.692,
            ic_december: 6.418,
            ic_yearly: 5.745,
          },
          {
            ic_uuid: "850f856e-a4ac-4168-9866-45f8c3085616",
            ic_lat: -196992,
            ic_lon: -506899,
            ic_city: "Carneirinho",
            ic_states: "MINAS GERAIS",
            ic_january: 5.785,
            ic_february: 5.948,
            ic_march: 5.37,
            ic_april: 4.985,
            ic_may: 4.308,
            ic_june: 4.067,
            ic_july: 4.272,
            ic_august: 5.161,
            ic_september: 5.136,
            ic_october: 5.675,
            ic_november: 5.84,
            ic_december: 6.161,
            ic_yearly: 5.226,
          },
          {
            ic_uuid: "436e44ae-ba53-4efd-89b0-fb53c7623a35",
            ic_lat: -94837,
            ic_lon: -373759,
            ic_city: "Carneiros",
            ic_states: "ALAGOAS",
            ic_january: 6.139,
            ic_february: 5.962,
            ic_march: 5.987,
            ic_april: 5.303,
            ic_may: 4.384,
            ic_june: 4.013,
            ic_july: 4.138,
            ic_august: 4.781,
            ic_september: 5.63,
            ic_october: 5.98,
            ic_november: 6.547,
            ic_december: 6.339,
            ic_yearly: 5.434,
          },
          {
            ic_uuid: "7d9b9ec3-b80c-46b3-9e24-b618eb2c1338",
            ic_lat: 8767,
            ic_lon: -596968,
            ic_city: "Caroebe",
            ic_states: "RORAIMA",
            ic_january: 4.412,
            ic_february: 4.486,
            ic_march: 4.486,
            ic_april: 4.334,
            ic_may: 4.137,
            ic_june: 4.452,
            ic_july: 4.501,
            ic_august: 5.095,
            ic_september: 5.289,
            ic_october: 5.25,
            ic_november: 5.053,
            ic_december: 4.618,
            ic_yearly: 4.676,
          },
          {
            ic_uuid: "89b3ab2b-9ec2-4c82-ba26-8cb848932917",
            ic_lat: -73363,
            ic_lon: -474638,
            ic_city: "Carolina",
            ic_states: "MARANHÃO",
            ic_january: 4.869,
            ic_february: 4.925,
            ic_march: 4.806,
            ic_april: 4.847,
            ic_may: 5.105,
            ic_june: 5.344,
            ic_july: 5.32,
            ic_august: 5.943,
            ic_september: 6.031,
            ic_october: 5.342,
            ic_november: 4.835,
            ic_december: 4.774,
            ic_yearly: 5.178,
          },
          {
            ic_uuid: "7c562587-c2a0-4f05-a800-fa4587ddfb97",
            ic_lat: -78481,
            ic_lon: -352566,
            ic_city: "Carpina",
            ic_states: "PERNAMBUCO",
            ic_january: 5.653,
            ic_february: 5.774,
            ic_march: 5.774,
            ic_april: 5.172,
            ic_may: 4.471,
            ic_june: 4.129,
            ic_july: 4.23,
            ic_august: 4.947,
            ic_september: 5.482,
            ic_october: 5.696,
            ic_november: 5.967,
            ic_december: 5.88,
            ic_yearly: 5.265,
          },
          {
            ic_uuid: "c96bae11-6fd5-4f26-9eb1-538aebbcbed3",
            ic_lat: -214902,
            ic_lon: -446449,
            ic_city: "Carrancas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.588,
            ic_february: 5.778,
            ic_march: 4.939,
            ic_april: 4.511,
            ic_may: 3.896,
            ic_june: 3.659,
            ic_july: 3.918,
            ic_august: 4.833,
            ic_september: 5.07,
            ic_october: 5.351,
            ic_november: 5.189,
            ic_december: 5.608,
            ic_yearly: 4.862,
          },
          {
            ic_uuid: "80f04823-f79d-406f-b3f9-5791bbe33ea2",
            ic_lat: -70381,
            ic_lon: -38346,
            ic_city: "Carrapateira",
            ic_states: "PARAÍBA",
            ic_january: 6.147,
            ic_february: 6.176,
            ic_march: 6.105,
            ic_april: 5.841,
            ic_may: 5.316,
            ic_june: 5.028,
            ic_july: 5.338,
            ic_august: 6.058,
            ic_september: 6.578,
            ic_october: 6.655,
            ic_november: 6.832,
            ic_december: 6.389,
            ic_yearly: 6.039,
          },
          {
            ic_uuid: "6f7737a8-430b-4482-89ec-dd03f8bc8f08",
            ic_lat: -53236,
            ic_lon: -480357,
            ic_city: "Carrasco Bonito",
            ic_states: "TOCANTINS",
            ic_january: 4.62,
            ic_february: 4.721,
            ic_march: 4.864,
            ic_april: 4.938,
            ic_may: 4.856,
            ic_june: 5.144,
            ic_july: 5.259,
            ic_august: 5.704,
            ic_september: 5.552,
            ic_october: 5.105,
            ic_november: 4.901,
            ic_december: 4.688,
            ic_yearly: 5.029,
          },
          {
            ic_uuid: "c4eb736a-7fcf-4089-9314-32281bffbda2",
            ic_lat: -8285,
            ic_lon: -359702,
            ic_city: "Caruaru",
            ic_states: "PERNAMBUCO",
            ic_january: 5.726,
            ic_february: 5.773,
            ic_march: 5.703,
            ic_april: 5.172,
            ic_may: 4.439,
            ic_june: 4.007,
            ic_july: 4.13,
            ic_august: 4.809,
            ic_september: 5.502,
            ic_october: 5.769,
            ic_november: 6.1,
            ic_december: 5.934,
            ic_yearly: 5.255,
          },
          {
            ic_uuid: "3e859e48-5380-4863-ace1-a2f90c5a1167",
            ic_lat: -12013,
            ic_lon: -460188,
            ic_city: "Carutapera",
            ic_states: "MARANHÃO",
            ic_january: 4.78,
            ic_february: 4.535,
            ic_march: 4.343,
            ic_april: 4.298,
            ic_may: 4.501,
            ic_june: 4.786,
            ic_july: 4.899,
            ic_august: 5.255,
            ic_september: 5.668,
            ic_october: 5.536,
            ic_november: 5.416,
            ic_december: 5.124,
            ic_yearly: 4.928,
          },
          {
            ic_uuid: "f1797fbc-dd49-48dd-803d-1c7b9b71421f",
            ic_lat: -21774,
            ic_lon: -458425,
            ic_city: "Carvalhópolis",
            ic_states: "MINAS GERAIS",
            ic_january: 5.418,
            ic_february: 5.7,
            ic_march: 4.815,
            ic_april: 4.551,
            ic_may: 3.942,
            ic_june: 3.687,
            ic_july: 3.928,
            ic_august: 4.834,
            ic_september: 5.002,
            ic_october: 5.346,
            ic_november: 5.224,
            ic_december: 5.602,
            ic_yearly: 4.837,
          },
          {
            ic_uuid: "84ca0096-69da-42ca-8d08-a37d4b636cf1",
            ic_lat: -220005,
            ic_lon: -444635,
            ic_city: "Carvalhos",
            ic_states: "MINAS GERAIS",
            ic_january: 5.397,
            ic_february: 5.728,
            ic_march: 4.889,
            ic_april: 4.45,
            ic_may: 3.783,
            ic_june: 3.557,
            ic_july: 3.754,
            ic_august: 4.65,
            ic_september: 4.87,
            ic_october: 5.125,
            ic_november: 5.047,
            ic_december: 5.509,
            ic_yearly: 4.73,
          },
          {
            ic_uuid: "25dbd565-e802-47ae-98d5-16a231d9730b",
            ic_lat: -217713,
            ic_lon: -470856,
            ic_city: "Casa Branca",
            ic_states: "SÃO PAULO",
            ic_january: 5.632,
            ic_february: 5.866,
            ic_march: 5.124,
            ic_april: 4.758,
            ic_may: 4.013,
            ic_june: 3.75,
            ic_july: 3.94,
            ic_august: 4.858,
            ic_september: 4.99,
            ic_october: 5.55,
            ic_november: 5.802,
            ic_december: 6.011,
            ic_yearly: 5.025,
          },
          {
            ic_uuid: "7cbe52f8-7e30-4d20-ac51-4a537cd75522",
            ic_lat: -20793,
            ic_lon: -439347,
            ic_city: "Casa Grande",
            ic_states: "MINAS GERAIS",
            ic_january: 5.642,
            ic_february: 5.892,
            ic_march: 4.929,
            ic_april: 4.482,
            ic_may: 3.884,
            ic_june: 3.727,
            ic_july: 3.936,
            ic_august: 4.87,
            ic_september: 5.081,
            ic_october: 5.267,
            ic_november: 4.996,
            ic_december: 5.557,
            ic_yearly: 4.855,
          },
          {
            ic_uuid: "4ad31017-5e10-42a9-8a15-e5e929af6798",
            ic_lat: -91743,
            ic_lon: -409737,
            ic_city: "Casa Nova",
            ic_states: "BAHIA",
            ic_january: 6.33,
            ic_february: 5.98,
            ic_march: 5.893,
            ic_april: 5.204,
            ic_may: 4.902,
            ic_june: 4.721,
            ic_july: 4.998,
            ic_august: 5.739,
            ic_september: 6.375,
            ic_october: 6.413,
            ic_november: 6.479,
            ic_december: 6.258,
            ic_yearly: 5.774,
          },
          {
            ic_uuid: "c6a43904-db90-4567-b022-97d7d9a86ad0",
            ic_lat: -28561,
            ic_lon: -519819,
            ic_city: "Casca",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.107,
            ic_february: 5.77,
            ic_march: 4.866,
            ic_april: 3.914,
            ic_may: 2.914,
            ic_june: 2.477,
            ic_july: 2.782,
            ic_august: 3.498,
            ic_september: 3.759,
            ic_october: 4.869,
            ic_november: 6.083,
            ic_december: 6.512,
            ic_yearly: 4.462,
          },
          {
            ic_uuid: "1e04adf2-9ab3-4a0e-8fd3-9f509ea95005",
            ic_lat: -185773,
            ic_lon: -478722,
            ic_city: "Cascalho Rico",
            ic_states: "MINAS GERAIS",
            ic_january: 5.689,
            ic_february: 6.104,
            ic_march: 5.293,
            ic_april: 5.165,
            ic_may: 4.639,
            ic_june: 4.438,
            ic_july: 4.602,
            ic_august: 5.576,
            ic_september: 5.551,
            ic_october: 5.758,
            ic_november: 5.605,
            ic_december: 5.894,
            ic_yearly: 5.36,
          },
          {
            ic_uuid: "813ad131-20dc-4c7d-94de-93be0cb50549",
            ic_lat: -41301,
            ic_lon: -382415,
            ic_city: "Cascavel",
            ic_states: "CEARÁ",
            ic_january: 5.941,
            ic_february: 6.0,
            ic_march: 5.764,
            ic_april: 5.161,
            ic_may: 5.422,
            ic_june: 5.336,
            ic_july: 5.585,
            ic_august: 6.088,
            ic_september: 6.098,
            ic_october: 6.426,
            ic_november: 6.423,
            ic_december: 6.113,
            ic_yearly: 5.863,
          },
          {
            ic_uuid: "a602e61e-99c6-4aa7-ba81-c7592e41dc4f",
            ic_lat: -249578,
            ic_lon: -534595,
            ic_city: "Cascavel",
            ic_states: "PARANÁ",
            ic_january: 6.21,
            ic_february: 5.53,
            ic_march: 5.095,
            ic_april: 4.273,
            ic_may: 3.344,
            ic_june: 2.974,
            ic_july: 3.192,
            ic_august: 4.212,
            ic_september: 4.373,
            ic_october: 5.188,
            ic_november: 5.965,
            ic_december: 6.337,
            ic_yearly: 4.724,
          },
          {
            ic_uuid: "6ea229de-d9d0-4b18-8bba-e357bb8c8240",
            ic_lat: -92743,
            ic_lon: -499559,
            ic_city: "Caseara",
            ic_states: "TOCANTINS",
            ic_january: 5.104,
            ic_february: 5.011,
            ic_march: 4.844,
            ic_april: 4.742,
            ic_may: 4.898,
            ic_june: 5.016,
            ic_july: 5.276,
            ic_august: 5.779,
            ic_september: 5.42,
            ic_october: 5.194,
            ic_november: 5.083,
            ic_december: 5.049,
            ic_yearly: 5.118,
          },
          {
            ic_uuid: "15600ff2-8c52-48f2-a536-fa8a379f7725",
            ic_lat: -282586,
            ic_lon: -516866,
            ic_city: "Caseiros",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.077,
            ic_february: 5.705,
            ic_march: 4.897,
            ic_april: 4.001,
            ic_may: 2.98,
            ic_june: 2.531,
            ic_july: 2.842,
            ic_august: 3.586,
            ic_september: 3.822,
            ic_october: 4.916,
            ic_november: 6.145,
            ic_december: 6.478,
            ic_yearly: 4.499,
          },
          {
            ic_uuid: "66164b1a-6ece-4454-b610-d1ab0bddf62e",
            ic_lat: -224817,
            ic_lon: -42207,
            ic_city: "Casimiro de Abreu",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.764,
            ic_february: 6.077,
            ic_march: 4.808,
            ic_april: 4.332,
            ic_may: 3.612,
            ic_june: 3.407,
            ic_july: 3.403,
            ic_august: 4.24,
            ic_september: 4.417,
            ic_october: 4.832,
            ic_november: 4.617,
            ic_december: 5.379,
            ic_yearly: 4.574,
          },
          {
            ic_uuid: "3928021b-28d5-4833-967c-9f87bd7b844f",
            ic_lat: -77451,
            ic_lon: -357237,
            ic_city: "Casinhas",
            ic_states: "PERNAMBUCO",
            ic_january: 5.692,
            ic_february: 5.769,
            ic_march: 5.785,
            ic_april: 5.204,
            ic_may: 4.558,
            ic_june: 4.119,
            ic_july: 4.326,
            ic_august: 4.988,
            ic_september: 5.588,
            ic_october: 5.819,
            ic_november: 6.075,
            ic_december: 5.911,
            ic_yearly: 5.32,
          },
          {
            ic_uuid: "e767697d-63e4-48b5-9ae8-f4a3875cf1dd",
            ic_lat: -678,
            ic_lon: -358182,
            ic_city: "Casserengue",
            ic_states: "PARAÍBA",
            ic_january: 5.783,
            ic_february: 5.783,
            ic_march: 5.869,
            ic_april: 5.344,
            ic_may: 4.772,
            ic_june: 4.315,
            ic_july: 4.476,
            ic_august: 5.222,
            ic_september: 5.681,
            ic_october: 5.94,
            ic_november: 6.164,
            ic_december: 5.914,
            ic_yearly: 5.439,
          },
          {
            ic_uuid: "4f34f717-d9bf-42f7-a138-b18d92991258",
            ic_lat: -205835,
            ic_lon: -469205,
            ic_city: "Cássia",
            ic_states: "MINAS GERAIS",
            ic_january: 5.639,
            ic_february: 5.905,
            ic_march: 4.993,
            ic_april: 4.803,
            ic_may: 4.25,
            ic_june: 4.009,
            ic_july: 4.301,
            ic_august: 5.175,
            ic_september: 5.196,
            ic_october: 5.5,
            ic_november: 5.449,
            ic_december: 5.659,
            ic_yearly: 5.073,
          },
          {
            ic_uuid: "8292d2f3-a271-4a15-be82-668144437c61",
            ic_lat: -212806,
            ic_lon: -471647,
            ic_city: "Cássia dos Coqueiros",
            ic_states: "SÃO PAULO",
            ic_january: 5.445,
            ic_february: 5.645,
            ic_march: 4.888,
            ic_april: 4.715,
            ic_may: 4.092,
            ic_june: 3.887,
            ic_july: 4.095,
            ic_august: 5.005,
            ic_september: 5.001,
            ic_october: 5.457,
            ic_november: 5.497,
            ic_december: 5.717,
            ic_yearly: 4.954,
          },
          {
            ic_uuid: "b8cd6828-3b60-4055-b851-19afefaf50b5",
            ic_lat: -191183,
            ic_lon: -517317,
            ic_city: "Cassilândia",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.675,
            ic_february: 5.856,
            ic_march: 5.366,
            ic_april: 5.08,
            ic_may: 4.43,
            ic_june: 4.177,
            ic_july: 4.381,
            ic_august: 5.24,
            ic_september: 5.148,
            ic_october: 5.562,
            ic_november: 5.74,
            ic_december: 6.051,
            ic_yearly: 5.226,
          },
          {
            ic_uuid: "bb4791c5-9100-4df6-bbc4-ec38bdd358fd",
            ic_lat: -12983,
            ic_lon: -479171,
            ic_city: "Castanhal",
            ic_states: "PARÁ",
            ic_january: 4.372,
            ic_february: 4.197,
            ic_march: 4.268,
            ic_april: 4.316,
            ic_may: 4.564,
            ic_june: 4.835,
            ic_july: 4.955,
            ic_august: 5.201,
            ic_september: 5.281,
            ic_october: 5.109,
            ic_november: 4.907,
            ic_december: 4.515,
            ic_yearly: 4.71,
          },
          {
            ic_uuid: "d33f7ffe-8354-42cc-b3e8-1b7ef01a256b",
            ic_lat: -111404,
            ic_lon: -586081,
            ic_city: "Castanheira",
            ic_states: "MATO GROSSO",
            ic_january: 4.592,
            ic_february: 4.615,
            ic_march: 4.631,
            ic_april: 4.546,
            ic_may: 4.385,
            ic_june: 4.565,
            ic_july: 4.704,
            ic_august: 5.246,
            ic_september: 5.045,
            ic_october: 5.191,
            ic_november: 4.929,
            ic_december: 4.983,
            ic_yearly: 4.786,
          },
          {
            ic_uuid: "be893dba-296c-4f5c-a27e-70ffd4c26f4f",
            ic_lat: -114257,
            ic_lon: -619487,
            ic_city: "Castanheiras",
            ic_states: "RONDÔNIA",
            ic_january: 4.432,
            ic_february: 4.382,
            ic_march: 4.505,
            ic_april: 4.57,
            ic_may: 4.135,
            ic_june: 4.343,
            ic_july: 4.48,
            ic_august: 4.881,
            ic_september: 4.882,
            ic_october: 5.152,
            ic_november: 5.047,
            ic_december: 4.757,
            ic_yearly: 4.631,
          },
          {
            ic_uuid: "718b259b-a2ac-4bc3-bd23-3200ad6d2c33",
            ic_lat: -180887,
            ic_lon: -502137,
            ic_city: "Castelândia",
            ic_states: "GOIÁS",
            ic_january: 5.754,
            ic_february: 5.775,
            ic_march: 5.301,
            ic_april: 5.112,
            ic_may: 4.652,
            ic_june: 4.356,
            ic_july: 4.555,
            ic_august: 5.53,
            ic_september: 5.329,
            ic_october: 5.574,
            ic_november: 5.691,
            ic_december: 5.849,
            ic_yearly: 5.29,
          },
          {
            ic_uuid: "d5585a24-4b56-486a-88cc-0c4839ab9976",
            ic_lat: -206037,
            ic_lon: -412035,
            ic_city: "Castelo",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.108,
            ic_february: 6.271,
            ic_march: 5.163,
            ic_april: 4.518,
            ic_may: 3.898,
            ic_june: 3.687,
            ic_july: 3.785,
            ic_august: 4.521,
            ic_september: 4.973,
            ic_october: 5.097,
            ic_november: 4.998,
            ic_december: 5.733,
            ic_yearly: 4.896,
          },
          {
            ic_uuid: "48c104ad-d93d-4056-9f01-7d15dba8fd87",
            ic_lat: -53191,
            ic_lon: -415502,
            ic_city: "Castelo do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.136,
            ic_february: 5.265,
            ic_march: 5.33,
            ic_april: 5.1,
            ic_may: 5.128,
            ic_june: 5.143,
            ic_july: 5.458,
            ic_august: 6.205,
            ic_september: 6.615,
            ic_october: 6.48,
            ic_november: 6.318,
            ic_december: 5.747,
            ic_yearly: 5.66,
          },
          {
            ic_uuid: "3d0bb0d2-2539-40a6-8a2e-e97ef5dde2b6",
            ic_lat: -208694,
            ic_lon: -514888,
            ic_city: "Castilho",
            ic_states: "SÃO PAULO",
            ic_january: 5.968,
            ic_february: 5.951,
            ic_march: 5.399,
            ic_april: 4.887,
            ic_may: 4.098,
            ic_june: 3.797,
            ic_july: 3.975,
            ic_august: 4.939,
            ic_september: 4.972,
            ic_october: 5.637,
            ic_november: 6.007,
            ic_december: 6.399,
            ic_yearly: 5.169,
          },
          {
            ic_uuid: "e75fd176-7904-4bf1-bc68-15fee23f2902",
            ic_lat: -247896,
            ic_lon: -500112,
            ic_city: "Castro",
            ic_states: "PARANÁ",
            ic_january: 5.409,
            ic_february: 5.29,
            ic_march: 4.66,
            ic_april: 4.041,
            ic_may: 3.216,
            ic_june: 2.858,
            ic_july: 3.094,
            ic_august: 4.079,
            ic_september: 4.18,
            ic_october: 4.718,
            ic_november: 5.471,
            ic_december: 5.718,
            ic_yearly: 4.394,
          },
          {
            ic_uuid: "99aef248-04de-4943-91ec-577a7bcc415d",
            ic_lat: -127676,
            ic_lon: -394266,
            ic_city: "Castro Alves",
            ic_states: "BAHIA",
            ic_january: 5.85,
            ic_february: 5.866,
            ic_march: 5.561,
            ic_april: 4.56,
            ic_may: 3.983,
            ic_june: 3.661,
            ic_july: 3.896,
            ic_august: 4.415,
            ic_september: 5.107,
            ic_october: 5.241,
            ic_november: 5.392,
            ic_december: 5.843,
            ic_yearly: 4.948,
          },
          {
            ic_uuid: "b856da69-26a4-41e1-893a-03bfeaaa1339",
            ic_lat: -213929,
            ic_lon: -4269,
            ic_city: "Cataguases",
            ic_states: "MINAS GERAIS",
            ic_january: 5.915,
            ic_february: 6.123,
            ic_march: 5.113,
            ic_april: 4.411,
            ic_may: 3.717,
            ic_june: 3.51,
            ic_july: 3.653,
            ic_august: 4.44,
            ic_september: 4.789,
            ic_october: 5.096,
            ic_november: 4.972,
            ic_december: 5.82,
            ic_yearly: 4.797,
          },
          {
            ic_uuid: "03ab30cc-ade9-42d3-a470-905f80328499",
            ic_lat: -181661,
            ic_lon: -479444,
            ic_city: "Catalão",
            ic_states: "GOIÁS",
            ic_january: 5.651,
            ic_february: 5.883,
            ic_march: 5.145,
            ic_april: 5.094,
            ic_may: 4.67,
            ic_june: 4.432,
            ic_july: 4.705,
            ic_august: 5.628,
            ic_september: 5.522,
            ic_october: 5.654,
            ic_november: 5.516,
            ic_december: 5.717,
            ic_yearly: 5.301,
          },
          {
            ic_uuid: "5464d13e-8215-48e4-972d-34d790584a09",
            ic_lat: -211318,
            ic_lon: -489774,
            ic_city: "Catanduva",
            ic_states: "SÃO PAULO",
            ic_january: 5.778,
            ic_february: 5.859,
            ic_march: 5.168,
            ic_april: 4.783,
            ic_may: 4.084,
            ic_june: 3.866,
            ic_july: 4.074,
            ic_august: 5.018,
            ic_september: 5.075,
            ic_october: 5.588,
            ic_november: 5.878,
            ic_december: 6.155,
            ic_yearly: 5.11,
          },
          {
            ic_uuid: "68d5acd6-04b8-488d-ab76-88f2aaa3660c",
            ic_lat: -252048,
            ic_lon: -531553,
            ic_city: "Catanduvas",
            ic_states: "PARANÁ",
            ic_january: 6.203,
            ic_february: 5.571,
            ic_march: 5.1,
            ic_april: 4.248,
            ic_may: 3.317,
            ic_june: 2.938,
            ic_july: 3.17,
            ic_august: 4.191,
            ic_september: 4.342,
            ic_october: 5.156,
            ic_november: 5.921,
            ic_december: 6.274,
            ic_yearly: 4.702,
          },
          {
            ic_uuid: "b3f7fbd6-7f91-4577-80e2-74bb6149a4d4",
            ic_lat: -270694,
            ic_lon: -516606,
            ic_city: "Catanduvas",
            ic_states: "SANTA CATARINA",
            ic_january: 5.828,
            ic_february: 5.442,
            ic_march: 4.945,
            ic_april: 4.019,
            ic_may: 3.099,
            ic_june: 2.691,
            ic_july: 2.944,
            ic_august: 3.802,
            ic_september: 4.048,
            ic_october: 4.898,
            ic_november: 6.042,
            ic_december: 6.144,
            ic_yearly: 4.492,
          },
          {
            ic_uuid: "ec6eeda3-276b-4526-929c-84b38d4b804b",
            ic_lat: -61233,
            ic_lon: -39874,
            ic_city: "Catarina",
            ic_states: "CEARÁ",
            ic_january: 5.487,
            ic_february: 5.542,
            ic_march: 5.531,
            ic_april: 5.171,
            ic_may: 5.051,
            ic_june: 5.063,
            ic_july: 5.424,
            ic_august: 6.2,
            ic_september: 6.566,
            ic_october: 6.392,
            ic_november: 6.331,
            ic_december: 5.76,
            ic_yearly: 5.71,
          },
          {
            ic_uuid: "d95fd0b9-e7f6-4e05-a9df-4581e9679495",
            ic_lat: -200739,
            ic_lon: -434065,
            ic_city: "Catas Altas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.23,
            ic_february: 5.623,
            ic_march: 4.657,
            ic_april: 4.275,
            ic_may: 3.762,
            ic_june: 3.682,
            ic_july: 3.869,
            ic_august: 4.697,
            ic_september: 4.955,
            ic_october: 4.99,
            ic_november: 4.54,
            ic_december: 5.126,
            ic_yearly: 4.617,
          },
          {
            ic_uuid: "618c43e3-bdda-4805-bca0-0679311d2a77",
            ic_lat: -206906,
            ic_lon: -434942,
            ic_city: "Catas Altas da Noruega",
            ic_states: "MINAS GERAIS",
            ic_january: 5.52,
            ic_february: 5.934,
            ic_march: 4.83,
            ic_april: 4.319,
            ic_may: 3.754,
            ic_june: 3.618,
            ic_july: 3.807,
            ic_august: 4.73,
            ic_september: 4.931,
            ic_october: 5.055,
            ic_november: 4.675,
            ic_december: 5.359,
            ic_yearly: 4.711,
          },
          {
            ic_uuid: "dffe3c3b-9330-4b9d-88cd-45e6c19ec1dc",
            ic_lat: -86755,
            ic_lon: -357027,
            ic_city: "Catende",
            ic_states: "PERNAMBUCO",
            ic_january: 5.638,
            ic_february: 5.67,
            ic_march: 5.665,
            ic_april: 5.039,
            ic_may: 4.253,
            ic_june: 3.817,
            ic_july: 3.853,
            ic_august: 4.492,
            ic_september: 5.258,
            ic_october: 5.545,
            ic_november: 5.99,
            ic_december: 5.903,
            ic_yearly: 5.093,
          },
          {
            ic_uuid: "08adcf84-f800-4633-b3d9-f7dc03cbb164",
            ic_lat: -210523,
            ic_lon: -49062,
            ic_city: "Catiguá",
            ic_states: "SÃO PAULO",
            ic_january: 5.738,
            ic_february: 5.901,
            ic_march: 5.204,
            ic_april: 4.794,
            ic_may: 4.089,
            ic_june: 3.84,
            ic_july: 4.068,
            ic_august: 4.995,
            ic_september: 5.052,
            ic_october: 5.601,
            ic_november: 5.874,
            ic_december: 6.133,
            ic_yearly: 5.107,
          },
          {
            ic_uuid: "06778b38-99d3-41b9-ad47-9bd435b49feb",
            ic_lat: -71256,
            ic_lon: -376065,
            ic_city: "Catingueira",
            ic_states: "PARAÍBA",
            ic_january: 6.217,
            ic_february: 6.185,
            ic_march: 6.338,
            ic_april: 5.881,
            ic_may: 5.19,
            ic_june: 4.796,
            ic_july: 5.037,
            ic_august: 5.809,
            ic_september: 6.487,
            ic_october: 6.634,
            ic_november: 6.734,
            ic_december: 6.484,
            ic_yearly: 5.983,
          },
          {
            ic_uuid: "9aff029b-c488-40b5-be93-dbc09ddcdacb",
            ic_lat: -123158,
            ic_lon: -448632,
            ic_city: "Catolândia",
            ic_states: "BAHIA",
            ic_january: 6.173,
            ic_february: 5.99,
            ic_march: 5.585,
            ic_april: 5.484,
            ic_may: 5.301,
            ic_june: 5.146,
            ic_july: 5.522,
            ic_august: 6.074,
            ic_september: 6.212,
            ic_october: 6.093,
            ic_november: 5.675,
            ic_december: 6.143,
            ic_yearly: 5.783,
          },
          {
            ic_uuid: "4d866cb5-a9dc-4767-849e-75a7eb4d34a0",
            ic_lat: -6341,
            ic_lon: -377473,
            ic_city: "Catolé do Rocha",
            ic_states: "PARAÍBA",
            ic_january: 6.043,
            ic_february: 6.089,
            ic_march: 6.066,
            ic_april: 5.735,
            ic_may: 5.299,
            ic_june: 4.931,
            ic_july: 5.294,
            ic_august: 6.029,
            ic_september: 6.541,
            ic_october: 6.596,
            ic_november: 6.573,
            ic_december: 6.198,
            ic_yearly: 5.95,
          },
          {
            ic_uuid: "17edf7c2-b9a7-4f52-88b7-5499131ce659",
            ic_lat: -123541,
            ic_lon: -383754,
            ic_city: "Catu",
            ic_states: "BAHIA",
            ic_january: 6.074,
            ic_february: 5.908,
            ic_march: 5.698,
            ic_april: 4.635,
            ic_may: 3.943,
            ic_june: 3.734,
            ic_july: 3.909,
            ic_august: 4.443,
            ic_september: 5.197,
            ic_october: 5.53,
            ic_november: 5.853,
            ic_december: 6.157,
            ic_yearly: 5.09,
          },
          {
            ic_uuid: "2f2db49f-d4f0-40bd-8e6b-5cb588a3b974",
            ic_lat: -282558,
            ic_lon: -540137,
            ic_city: "Catuípe",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.497,
            ic_february: 6.041,
            ic_march: 5.148,
            ic_april: 4.009,
            ic_may: 3.049,
            ic_june: 2.518,
            ic_july: 2.802,
            ic_august: 3.574,
            ic_september: 3.981,
            ic_october: 5.11,
            ic_november: 6.263,
            ic_december: 6.655,
            ic_yearly: 4.637,
          },
          {
            ic_uuid: "699358ee-1dfb-43e2-a000-90721f3f090d",
            ic_lat: -173023,
            ic_lon: -41528,
            ic_city: "Catuji",
            ic_states: "MINAS GERAIS",
            ic_january: 5.969,
            ic_february: 6.195,
            ic_march: 5.217,
            ic_april: 4.447,
            ic_may: 3.809,
            ic_june: 3.511,
            ic_july: 3.661,
            ic_august: 4.44,
            ic_september: 4.959,
            ic_october: 5.275,
            ic_november: 4.96,
            ic_december: 5.761,
            ic_yearly: 4.85,
          },
          {
            ic_uuid: "3a83c9d8-b325-4ea6-92b6-55ba24a6ebc1",
            ic_lat: -46438,
            ic_lon: -402003,
            ic_city: "Catunda",
            ic_states: "CEARÁ",
            ic_january: 5.253,
            ic_february: 5.431,
            ic_march: 5.459,
            ic_april: 5.013,
            ic_may: 5.028,
            ic_june: 4.925,
            ic_july: 5.306,
            ic_august: 6.078,
            ic_september: 6.649,
            ic_october: 6.561,
            ic_november: 6.391,
            ic_december: 5.719,
            ic_yearly: 5.651,
          },
          {
            ic_uuid: "03d03425-e64c-4e3c-a7b3-83c25d838675",
            ic_lat: -164471,
            ic_lon: -494959,
            ic_city: "Caturaí",
            ic_states: "GOIÁS",
            ic_january: 5.448,
            ic_february: 5.56,
            ic_march: 5.179,
            ic_april: 5.08,
            ic_may: 4.863,
            ic_june: 4.58,
            ic_july: 4.81,
            ic_august: 5.785,
            ic_september: 5.545,
            ic_october: 5.528,
            ic_november: 5.276,
            ic_december: 5.45,
            ic_yearly: 5.258,
          },
          {
            ic_uuid: "cf9d1657-8a00-4cff-9030-6e0639fbeb63",
            ic_lat: -133303,
            ic_lon: -422918,
            ic_city: "Caturama",
            ic_states: "BAHIA",
            ic_january: 6.478,
            ic_february: 6.587,
            ic_march: 6.066,
            ic_april: 5.475,
            ic_may: 4.992,
            ic_june: 4.791,
            ic_july: 4.969,
            ic_august: 5.66,
            ic_september: 6.194,
            ic_october: 6.402,
            ic_november: 6.032,
            ic_december: 6.589,
            ic_yearly: 5.853,
          },
          {
            ic_uuid: "419d7535-e974-41df-897d-049a537de0f5",
            ic_lat: -742,
            ic_lon: -360209,
            ic_city: "Caturité",
            ic_states: "PARAÍBA",
            ic_january: 5.859,
            ic_february: 5.812,
            ic_march: 5.85,
            ic_april: 5.339,
            ic_may: 4.69,
            ic_june: 4.165,
            ic_july: 4.374,
            ic_august: 5.036,
            ic_september: 5.681,
            ic_october: 5.973,
            ic_november: 6.217,
            ic_december: 6.003,
            ic_yearly: 5.417,
          },
          {
            ic_uuid: "e2ce3f7c-a7db-4381-8527-b417705e6655",
            ic_lat: -15362,
            ic_lon: -429631,
            ic_city: "Catuti",
            ic_states: "MINAS GERAIS",
            ic_january: 6.651,
            ic_february: 6.834,
            ic_march: 5.976,
            ic_april: 5.573,
            ic_may: 4.882,
            ic_june: 4.665,
            ic_july: 4.861,
            ic_august: 5.41,
            ic_september: 5.92,
            ic_october: 6.248,
            ic_november: 5.921,
            ic_december: 6.511,
            ic_yearly: 5.787,
          },
          {
            ic_uuid: "5ea82210-5733-4457-a7e9-d9aa5c34ea8d",
            ic_lat: -37284,
            ic_lon: -386622,
            ic_city: "Caucaia",
            ic_states: "CEARÁ",
            ic_january: 5.753,
            ic_february: 5.696,
            ic_march: 5.535,
            ic_april: 4.803,
            ic_may: 5.163,
            ic_june: 5.194,
            ic_july: 5.467,
            ic_august: 6.007,
            ic_september: 6.146,
            ic_october: 6.31,
            ic_november: 6.331,
            ic_december: 6.005,
            ic_yearly: 5.701,
          },
          {
            ic_uuid: "8cab9cb5-5f6b-4ed7-8f2d-22fabcce8c06",
            ic_lat: -137921,
            ic_lon: -47457,
            ic_city: "Cavalcante",
            ic_states: "GOIÁS",
            ic_january: 5.489,
            ic_february: 5.508,
            ic_march: 5.105,
            ic_april: 5.143,
            ic_may: 5.071,
            ic_june: 5.07,
            ic_july: 5.416,
            ic_august: 6.07,
            ic_september: 6.003,
            ic_october: 5.588,
            ic_november: 5.027,
            ic_december: 5.321,
            ic_yearly: 5.401,
          },
          {
            ic_uuid: "44719029-833c-4248-9d87-0ef856d8a46e",
            ic_lat: -219757,
            ic_lon: -449322,
            ic_city: "Caxambu",
            ic_states: "MINAS GERAIS",
            ic_january: 5.464,
            ic_february: 5.771,
            ic_march: 4.988,
            ic_april: 4.625,
            ic_may: 3.902,
            ic_june: 3.673,
            ic_july: 3.903,
            ic_august: 4.757,
            ic_september: 5.044,
            ic_october: 5.436,
            ic_november: 5.346,
            ic_december: 5.711,
            ic_yearly: 4.885,
          },
          {
            ic_uuid: "837ef3b1-6355-4d91-8001-70bd1aeb9da5",
            ic_lat: -271629,
            ic_lon: -528812,
            ic_city: "Caxambu do Sul",
            ic_states: "SANTA CATARINA",
            ic_january: 6.285,
            ic_february: 5.835,
            ic_march: 5.205,
            ic_april: 4.098,
            ic_may: 3.129,
            ic_june: 2.607,
            ic_july: 2.908,
            ic_august: 3.76,
            ic_september: 4.008,
            ic_october: 5.165,
            ic_november: 6.19,
            ic_december: 6.596,
            ic_yearly: 4.649,
          },
          {
            ic_uuid: "1c2105c7-ed4e-45b8-9b1b-14114b4e8085",
            ic_lat: -48655,
            ic_lon: -433621,
            ic_city: "Caxias",
            ic_states: "MARANHÃO",
            ic_january: 4.9,
            ic_february: 5.102,
            ic_march: 5.107,
            ic_april: 5.025,
            ic_may: 4.991,
            ic_june: 5.154,
            ic_july: 5.435,
            ic_august: 6.049,
            ic_september: 6.409,
            ic_october: 6.222,
            ic_november: 5.96,
            ic_december: 5.437,
            ic_yearly: 5.483,
          },
          {
            ic_uuid: "6f4187b8-78fd-4cfe-8255-d405d9472b25",
            ic_lat: -291634,
            ic_lon: -511796,
            ic_city: "Caxias do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.101,
            ic_february: 5.643,
            ic_march: 4.661,
            ic_april: 3.803,
            ic_may: 2.809,
            ic_june: 2.384,
            ic_july: 2.687,
            ic_august: 3.389,
            ic_september: 3.635,
            ic_october: 4.727,
            ic_november: 6.075,
            ic_december: 6.415,
            ic_yearly: 4.361,
          },
          {
            ic_uuid: "9eff266e-3270-40e8-8211-24d78b93a313",
            ic_lat: -34208,
            ic_lon: -418925,
            ic_city: "Caxingó",
            ic_states: "PIAUÍ",
            ic_january: 5.135,
            ic_february: 5.233,
            ic_march: 5.107,
            ic_april: 4.831,
            ic_may: 5.089,
            ic_june: 5.334,
            ic_july: 5.539,
            ic_august: 5.998,
            ic_september: 6.427,
            ic_october: 6.457,
            ic_november: 6.213,
            ic_december: 5.48,
            ic_yearly: 5.57,
          },
          {
            ic_uuid: "8a8510d1-b6a1-4de9-92c3-5343cc2822e6",
            ic_lat: -56405,
            ic_lon: -3542,
            ic_city: "Ceará-Mirim",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.912,
            ic_february: 6.076,
            ic_march: 6.061,
            ic_april: 5.554,
            ic_may: 5.001,
            ic_june: 4.654,
            ic_july: 4.748,
            ic_august: 5.444,
            ic_september: 5.955,
            ic_october: 6.12,
            ic_november: 6.25,
            ic_december: 5.928,
            ic_yearly: 5.642,
          },
          {
            ic_uuid: "ce5ad7e1-126a-4586-8dfa-7b6c2e511b1c",
            ic_lat: -20007,
            ic_lon: -445285,
            ic_city: "Cedral",
            ic_states: "MARANHÃO",
            ic_january: 4.932,
            ic_february: 4.912,
            ic_march: 4.79,
            ic_april: 4.528,
            ic_may: 4.842,
            ic_june: 5.007,
            ic_july: 5.066,
            ic_august: 5.433,
            ic_september: 5.756,
            ic_october: 5.573,
            ic_november: 5.521,
            ic_december: 5.234,
            ic_yearly: 5.133,
          },
          {
            ic_uuid: "d80aa874-7ac8-41c0-b70b-e80bb40311c4",
            ic_lat: -20903,
            ic_lon: -492679,
            ic_city: "Cedral",
            ic_states: "SÃO PAULO",
            ic_january: 5.767,
            ic_february: 5.861,
            ic_march: 5.206,
            ic_april: 4.849,
            ic_may: 4.104,
            ic_june: 3.898,
            ic_july: 4.123,
            ic_august: 5.04,
            ic_september: 5.102,
            ic_october: 5.597,
            ic_november: 5.931,
            ic_december: 6.13,
            ic_yearly: 5.134,
          },
          {
            ic_uuid: "9617bcab-d296-48fc-a21c-62fc9029c0d5",
            ic_lat: -66008,
            ic_lon: -390612,
            ic_city: "Cedro",
            ic_states: "CEARÁ",
            ic_january: 5.92,
            ic_february: 5.989,
            ic_march: 5.882,
            ic_april: 5.597,
            ic_may: 5.261,
            ic_june: 5.13,
            ic_july: 5.377,
            ic_august: 6.15,
            ic_september: 6.495,
            ic_october: 6.565,
            ic_november: 6.687,
            ic_december: 6.18,
            ic_yearly: 5.936,
          },
          {
            ic_uuid: "f4ef6454-152e-4c7a-b899-b1cabdebb93b",
            ic_lat: -77122,
            ic_lon: -392371,
            ic_city: "Cedro",
            ic_states: "PERNAMBUCO",
            ic_january: 6.042,
            ic_february: 5.841,
            ic_march: 5.905,
            ic_april: 5.416,
            ic_may: 4.725,
            ic_june: 4.39,
            ic_july: 4.656,
            ic_august: 5.506,
            ic_september: 6.439,
            ic_october: 6.464,
            ic_november: 6.637,
            ic_december: 6.355,
            ic_yearly: 5.698,
          },
          {
            ic_uuid: "4e4fb6a7-340e-4591-9475-9e53e4ed9329",
            ic_lat: -102517,
            ic_lon: -368812,
            ic_city: "Cedro de São João",
            ic_states: "SERGIPE",
            ic_january: 5.989,
            ic_february: 5.788,
            ic_march: 5.745,
            ic_april: 4.938,
            ic_may: 4.218,
            ic_june: 4.003,
            ic_july: 4.051,
            ic_august: 4.602,
            ic_september: 5.421,
            ic_october: 5.808,
            ic_november: 6.243,
            ic_december: 6.268,
            ic_yearly: 5.256,
          },
          {
            ic_uuid: "ce6b60dc-294b-46f7-a8a0-0ea7fad18e75",
            ic_lat: -191485,
            ic_lon: -457123,
            ic_city: "Cedro do Abaeté",
            ic_states: "MINAS GERAIS",
            ic_january: 5.803,
            ic_february: 6.055,
            ic_march: 5.103,
            ic_april: 5.025,
            ic_may: 4.553,
            ic_june: 4.361,
            ic_july: 4.702,
            ic_august: 5.619,
            ic_september: 5.661,
            ic_october: 5.599,
            ic_november: 5.192,
            ic_december: 5.564,
            ic_yearly: 5.27,
          },
          {
            ic_uuid: "19e29601-4151-4541-96f2-751fc50d7a26",
            ic_lat: -276332,
            ic_lon: -513354,
            ic_city: "Celso Ramos",
            ic_states: "SANTA CATARINA",
            ic_january: 6.141,
            ic_february: 5.656,
            ic_march: 4.904,
            ic_april: 4.03,
            ic_may: 3.006,
            ic_june: 2.605,
            ic_july: 2.896,
            ic_august: 3.707,
            ic_september: 3.917,
            ic_october: 4.919,
            ic_november: 6.13,
            ic_december: 6.413,
            ic_yearly: 4.527,
          },
          {
            ic_uuid: "abbcbdb2-f0e8-4865-900e-5b2ef1fa2d1d",
            ic_lat: -27762,
            ic_lon: -519989,
            ic_city: "Centenário",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.195,
            ic_february: 5.749,
            ic_march: 4.994,
            ic_april: 4.069,
            ic_may: 3.049,
            ic_june: 2.536,
            ic_july: 2.885,
            ic_august: 3.67,
            ic_september: 3.921,
            ic_october: 5.047,
            ic_november: 6.201,
            ic_december: 6.51,
            ic_yearly: 4.569,
          },
          {
            ic_uuid: "11f4d1ea-564e-4dd1-b410-1d0ed93c8768",
            ic_lat: -89616,
            ic_lon: -47338,
            ic_city: "Centenário",
            ic_states: "TOCANTINS",
            ic_january: 5.073,
            ic_february: 4.988,
            ic_march: 4.83,
            ic_april: 4.907,
            ic_may: 5.166,
            ic_june: 5.184,
            ic_july: 5.419,
            ic_august: 6.197,
            ic_september: 6.111,
            ic_october: 5.476,
            ic_november: 5.023,
            ic_december: 4.996,
            ic_yearly: 5.281,
          },
          {
            ic_uuid: "2f42d1dd-6482-46aa-9001-50418a306247",
            ic_lat: -228192,
            ic_lon: -515977,
            ic_city: "Centenário do Sul",
            ic_states: "PARANÁ",
            ic_january: 5.998,
            ic_february: 5.967,
            ic_march: 5.456,
            ic_april: 4.775,
            ic_may: 3.76,
            ic_june: 3.446,
            ic_july: 3.676,
            ic_august: 4.602,
            ic_september: 4.786,
            ic_october: 5.511,
            ic_november: 6.131,
            ic_december: 6.464,
            ic_yearly: 5.048,
          },
          {
            ic_uuid: "6d571bbb-7d8d-4aa7-bcc0-02193c6ab89e",
            ic_lat: -11138,
            ic_lon: -42112,
            ic_city: "Central",
            ic_states: "BAHIA",
            ic_january: 6.351,
            ic_february: 6.14,
            ic_march: 6.008,
            ic_april: 5.37,
            ic_may: 4.994,
            ic_june: 4.817,
            ic_july: 5.037,
            ic_august: 5.822,
            ic_september: 6.456,
            ic_october: 6.436,
            ic_november: 6.231,
            ic_december: 6.374,
            ic_yearly: 5.836,
          },
          {
            ic_uuid: "055fe2c1-30b7-447b-80fb-ae70a1e0b500",
            ic_lat: -187617,
            ic_lon: -413147,
            ic_city: "Central de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 6.054,
            ic_february: 6.202,
            ic_march: 5.267,
            ic_april: 4.6,
            ic_may: 3.882,
            ic_june: 3.619,
            ic_july: 3.712,
            ic_august: 4.373,
            ic_september: 4.915,
            ic_october: 5.169,
            ic_november: 4.992,
            ic_december: 5.806,
            ic_yearly: 4.882,
          },
          {
            ic_uuid: "6e638f0f-01db-4631-bf81-a336b7c2e35f",
            ic_lat: -21987,
            ic_lon: -448257,
            ic_city: "Central do Maranhão",
            ic_states: "MARANHÃO",
            ic_january: 4.832,
            ic_february: 4.708,
            ic_march: 4.578,
            ic_april: 4.467,
            ic_may: 4.645,
            ic_june: 4.894,
            ic_july: 5.034,
            ic_august: 5.567,
            ic_september: 5.79,
            ic_october: 5.517,
            ic_november: 5.437,
            ic_december: 5.156,
            ic_yearly: 5.052,
          },
          {
            ic_uuid: "3b1b2178-faf0-4226-9063-9bbf30719c12",
            ic_lat: -185857,
            ic_lon: -492018,
            ic_city: "Centralina",
            ic_states: "MINAS GERAIS",
            ic_january: 5.705,
            ic_february: 5.842,
            ic_march: 5.334,
            ic_april: 5.138,
            ic_may: 4.647,
            ic_june: 4.363,
            ic_july: 4.516,
            ic_august: 5.458,
            ic_september: 5.255,
            ic_october: 5.655,
            ic_november: 5.789,
            ic_december: 5.846,
            ic_yearly: 5.296,
          },
          {
            ic_uuid: "367056d8-e3dc-45ae-8805-4809688dbb28",
            ic_lat: -24505,
            ic_lon: -460387,
            ic_city: "Centro do Guilherme",
            ic_states: "MARANHÃO",
            ic_january: 4.437,
            ic_february: 4.626,
            ic_march: 4.568,
            ic_april: 4.556,
            ic_may: 4.644,
            ic_june: 4.846,
            ic_july: 4.915,
            ic_august: 5.292,
            ic_september: 5.482,
            ic_october: 5.203,
            ic_november: 4.986,
            ic_december: 4.616,
            ic_yearly: 4.848,
          },
          {
            ic_uuid: "620ff1fd-1a9e-46a1-bbae-b2e4d26bc191",
            ic_lat: -2141,
            ic_lon: -461233,
            ic_city: "Centro Novo do Maranhão",
            ic_states: "MARANHÃO",
            ic_january: 4.423,
            ic_february: 4.457,
            ic_march: 4.41,
            ic_april: 4.461,
            ic_may: 4.53,
            ic_june: 4.785,
            ic_july: 4.883,
            ic_august: 5.328,
            ic_september: 5.505,
            ic_october: 5.207,
            ic_november: 4.965,
            ic_december: 4.584,
            ic_yearly: 4.795,
          },
          {
            ic_uuid: "2da15eaa-408f-4a32-b4de-0e391ec1460a",
            ic_lat: -131874,
            ic_lon: -608173,
            ic_city: "Cerejeiras",
            ic_states: "RONDÔNIA",
            ic_january: 4.752,
            ic_february: 4.663,
            ic_march: 4.705,
            ic_april: 4.632,
            ic_may: 4.233,
            ic_june: 4.354,
            ic_july: 4.494,
            ic_august: 4.965,
            ic_september: 4.956,
            ic_october: 5.239,
            ic_november: 5.174,
            ic_december: 5.044,
            ic_yearly: 4.767,
          },
          {
            ic_uuid: "7dea5484-ed6a-4169-99e9-74804f688676",
            ic_lat: -153066,
            ic_lon: -496004,
            ic_city: "Ceres",
            ic_states: "GOIÁS",
            ic_january: 5.295,
            ic_february: 5.526,
            ic_march: 5.113,
            ic_april: 5.132,
            ic_may: 4.947,
            ic_june: 4.656,
            ic_july: 4.857,
            ic_august: 5.775,
            ic_september: 5.516,
            ic_october: 5.522,
            ic_november: 5.138,
            ic_december: 5.305,
            ic_yearly: 5.232,
          },
          {
            ic_uuid: "07a94b8a-a00e-44f8-945b-0455097448dd",
            ic_lat: -23036,
            ic_lon: -491659,
            ic_city: "Cerqueira César",
            ic_states: "SÃO PAULO",
            ic_january: 5.537,
            ic_february: 5.724,
            ic_march: 5.093,
            ic_april: 4.61,
            ic_may: 3.685,
            ic_june: 3.444,
            ic_july: 3.625,
            ic_august: 4.608,
            ic_september: 4.737,
            ic_october: 5.36,
            ic_november: 5.829,
            ic_december: 6.173,
            ic_yearly: 4.869,
          },
          {
            ic_uuid: "6195c2e6-e949-4222-be32-657a361d0179",
            ic_lat: -23167,
            ic_lon: -477463,
            ic_city: "Cerquilho",
            ic_states: "SÃO PAULO",
            ic_january: 5.638,
            ic_february: 5.822,
            ic_march: 5.127,
            ic_april: 4.572,
            ic_may: 3.697,
            ic_june: 3.449,
            ic_july: 3.566,
            ic_august: 4.532,
            ic_september: 4.723,
            ic_october: 5.345,
            ic_november: 5.751,
            ic_december: 6.167,
            ic_yearly: 4.866,
          },
          {
            ic_uuid: "4ac11129-0485-49d4-bdce-dfc9bd3704ae",
            ic_lat: -318424,
            ic_lon: -528009,
            ic_city: "Cerrito",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.268,
            ic_february: 5.626,
            ic_march: 4.751,
            ic_april: 3.631,
            ic_may: 2.622,
            ic_june: 2.22,
            ic_july: 2.415,
            ic_august: 3.008,
            ic_september: 3.556,
            ic_october: 4.864,
            ic_november: 6.184,
            ic_december: 6.695,
            ic_yearly: 4.32,
          },
          {
            ic_uuid: "b28ca988-176a-476a-aa36-3f8ef8803509",
            ic_lat: -24824,
            ic_lon: -492609,
            ic_city: "Cerro Azul",
            ic_states: "PARANÁ",
            ic_january: 5.37,
            ic_february: 5.289,
            ic_march: 4.661,
            ic_april: 3.875,
            ic_may: 3.052,
            ic_june: 2.571,
            ic_july: 2.731,
            ic_august: 3.705,
            ic_september: 3.902,
            ic_october: 4.483,
            ic_november: 5.335,
            ic_december: 5.707,
            ic_yearly: 4.223,
          },
          {
            ic_uuid: "ba382972-dcb0-44b8-af38-9a5f7919e3dd",
            ic_lat: -296575,
            ic_lon: -529411,
            ic_city: "Cerro Branco",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.188,
            ic_february: 5.781,
            ic_march: 4.901,
            ic_april: 3.814,
            ic_may: 2.785,
            ic_june: 2.327,
            ic_july: 2.547,
            ic_august: 3.266,
            ic_september: 3.629,
            ic_october: 4.765,
            ic_november: 6.097,
            ic_december: 6.597,
            ic_yearly: 4.391,
          },
          {
            ic_uuid: "6fc23cc3-c65d-4ecf-9cc5-dac8771c462d",
            ic_lat: -60354,
            ic_lon: -363506,
            ic_city: "Cerro Corá",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.954,
            ic_february: 5.956,
            ic_march: 5.937,
            ic_april: 5.54,
            ic_may: 5.174,
            ic_june: 4.774,
            ic_july: 5.032,
            ic_august: 5.791,
            ic_september: 6.267,
            ic_october: 6.467,
            ic_november: 6.469,
            ic_december: 5.966,
            ic_yearly: 5.777,
          },
          {
            ic_uuid: "8b2c9595-e039-4c07-832e-857d5d772029",
            ic_lat: -276111,
            ic_lon: -531677,
            ic_city: "Cerro Grande",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.315,
            ic_february: 5.918,
            ic_march: 5.111,
            ic_april: 4.125,
            ic_may: 3.106,
            ic_june: 2.544,
            ic_july: 2.895,
            ic_august: 3.705,
            ic_september: 3.981,
            ic_october: 5.177,
            ic_november: 6.305,
            ic_december: 6.664,
            ic_yearly: 4.654,
          },
          {
            ic_uuid: "22032d94-d916-43c3-8b77-d0b4d8a7e4ed",
            ic_lat: -305953,
            ic_lon: -517487,
            ic_city: "Cerro Grande do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.144,
            ic_february: 5.463,
            ic_march: 4.664,
            ic_april: 3.707,
            ic_may: 2.715,
            ic_june: 2.291,
            ic_july: 2.478,
            ic_august: 3.11,
            ic_september: 3.508,
            ic_october: 4.599,
            ic_november: 6.003,
            ic_december: 6.4,
            ic_yearly: 4.257,
          },
          {
            ic_uuid: "0ce888da-09d6-4892-af2e-b32093c00548",
            ic_lat: -281467,
            ic_lon: -547433,
            ic_city: "Cerro Largo",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.535,
            ic_february: 6.07,
            ic_march: 5.191,
            ic_april: 4.085,
            ic_may: 3.102,
            ic_june: 2.535,
            ic_july: 2.848,
            ic_august: 3.611,
            ic_september: 4.033,
            ic_october: 5.139,
            ic_november: 6.351,
            ic_december: 6.727,
            ic_yearly: 4.685,
          },
          {
            ic_uuid: "80acf663-9aa0-4c55-8871-d7b8b8edd426",
            ic_lat: -277947,
            ic_lon: -508677,
            ic_city: "Cerro Negro",
            ic_states: "SANTA CATARINA",
            ic_january: 5.954,
            ic_february: 5.545,
            ic_march: 4.781,
            ic_april: 3.888,
            ic_may: 2.917,
            ic_june: 2.515,
            ic_july: 2.79,
            ic_august: 3.613,
            ic_september: 3.828,
            ic_october: 4.716,
            ic_november: 5.92,
            ic_december: 6.254,
            ic_yearly: 4.393,
          },
          {
            ic_uuid: "3451e833-1631-4ee9-a095-ff68f53b9dea",
            ic_lat: -232265,
            ic_lon: -479549,
            ic_city: "Cesário Lange",
            ic_states: "SÃO PAULO",
            ic_january: 5.585,
            ic_february: 5.797,
            ic_march: 5.132,
            ic_april: 4.573,
            ic_may: 3.68,
            ic_june: 3.441,
            ic_july: 3.54,
            ic_august: 4.564,
            ic_september: 4.736,
            ic_october: 5.337,
            ic_november: 5.753,
            ic_december: 6.133,
            ic_yearly: 4.856,
          },
          {
            ic_uuid: "d711ac4a-3dff-483d-9486-2c541d91a94e",
            ic_lat: -251493,
            ic_lon: -538419,
            ic_city: "Céu Azul",
            ic_states: "PARANÁ",
            ic_january: 6.271,
            ic_february: 5.692,
            ic_march: 5.074,
            ic_april: 4.236,
            ic_may: 3.304,
            ic_june: 2.913,
            ic_july: 3.156,
            ic_august: 4.156,
            ic_september: 4.351,
            ic_october: 5.206,
            ic_november: 5.964,
            ic_december: 6.412,
            ic_yearly: 4.728,
          },
          {
            ic_uuid: "e24b13db-10ca-48cd-9994-e6c225c5a3bb",
            ic_lat: -169723,
            ic_lon: -497762,
            ic_city: "Cezarina",
            ic_states: "GOIÁS",
            ic_january: 5.591,
            ic_february: 5.724,
            ic_march: 5.318,
            ic_april: 5.185,
            ic_may: 4.79,
            ic_june: 4.474,
            ic_july: 4.731,
            ic_august: 5.597,
            ic_september: 5.421,
            ic_october: 5.58,
            ic_november: 5.447,
            ic_december: 5.584,
            ic_yearly: 5.287,
          },
          {
            ic_uuid: "544f94af-998b-48bc-9547-e4ea40421b4d",
            ic_lat: -80072,
            ic_lon: -352043,
            ic_city: "Chã de Alegria",
            ic_states: "PERNAMBUCO",
            ic_january: 5.781,
            ic_february: 5.841,
            ic_march: 5.832,
            ic_april: 5.179,
            ic_may: 4.489,
            ic_june: 4.146,
            ic_july: 4.274,
            ic_august: 4.959,
            ic_september: 5.567,
            ic_october: 5.79,
            ic_november: 6.007,
            ic_december: 6.05,
            ic_yearly: 5.326,
          },
          {
            ic_uuid: "8ee368b8-1906-48be-a4a1-400c6598a21b",
            ic_lat: -82387,
            ic_lon: -354574,
            ic_city: "Chã Grande",
            ic_states: "PERNAMBUCO",
            ic_january: 5.769,
            ic_february: 5.726,
            ic_march: 5.721,
            ic_april: 5.125,
            ic_may: 4.422,
            ic_june: 4.119,
            ic_july: 4.157,
            ic_august: 4.916,
            ic_september: 5.49,
            ic_october: 5.68,
            ic_november: 5.939,
            ic_december: 5.897,
            ic_yearly: 5.247,
          },
          {
            ic_uuid: "682b11b5-8e12-4dd6-8537-cd8a736808bb",
            ic_lat: -9256,
            ic_lon: -362986,
            ic_city: "Chã Preta",
            ic_states: "ALAGOAS",
            ic_january: 5.876,
            ic_february: 5.766,
            ic_march: 5.746,
            ic_april: 5.087,
            ic_may: 4.197,
            ic_june: 3.735,
            ic_july: 3.828,
            ic_august: 4.469,
            ic_september: 5.3,
            ic_october: 5.631,
            ic_november: 6.254,
            ic_december: 6.203,
            ic_yearly: 5.174,
          },
          {
            ic_uuid: "5dc9a86a-d7d5-4572-b637-b5936feaaf8d",
            ic_lat: -216737,
            ic_lon: -432154,
            ic_city: "Chácara",
            ic_states: "MINAS GERAIS",
            ic_january: 5.566,
            ic_february: 5.851,
            ic_march: 4.798,
            ic_april: 4.183,
            ic_may: 3.527,
            ic_june: 3.351,
            ic_july: 3.535,
            ic_august: 4.267,
            ic_september: 4.586,
            ic_october: 4.875,
            ic_november: 4.693,
            ic_december: 5.439,
            ic_yearly: 4.556,
          },
          {
            ic_uuid: "f58f1e89-60d1-4904-a06d-a5d9e4d52017",
            ic_lat: -200458,
            ic_lon: -416901,
            ic_city: "Chalé",
            ic_states: "MINAS GERAIS",
            ic_january: 5.812,
            ic_february: 6.051,
            ic_march: 5.089,
            ic_april: 4.642,
            ic_may: 3.886,
            ic_june: 3.634,
            ic_july: 3.76,
            ic_august: 4.424,
            ic_september: 4.749,
            ic_october: 4.91,
            ic_november: 4.847,
            ic_december: 5.573,
            ic_yearly: 4.781,
          },
          {
            ic_uuid: "9b8f66d9-ccf2-48f2-8353-358558f50e59",
            ic_lat: -280563,
            ic_lon: -53067,
            ic_city: "Chapada",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.315,
            ic_february: 5.914,
            ic_march: 5.044,
            ic_april: 4.05,
            ic_may: 3.065,
            ic_june: 2.534,
            ic_july: 2.862,
            ic_august: 3.651,
            ic_september: 3.915,
            ic_october: 5.105,
            ic_november: 6.252,
            ic_december: 6.6,
            ic_yearly: 4.609,
          },
          {
            ic_uuid: "d73b43c1-7706-4692-bf61-ecd29744df31",
            ic_lat: -116179,
            ic_lon: -47749,
            ic_city: "Chapada da Natividade",
            ic_states: "TOCANTINS",
            ic_january: 5.457,
            ic_february: 5.51,
            ic_march: 5.113,
            ic_april: 5.157,
            ic_may: 5.188,
            ic_june: 5.075,
            ic_july: 5.366,
            ic_august: 6.031,
            ic_september: 6.048,
            ic_october: 5.676,
            ic_november: 5.375,
            ic_december: 5.436,
            ic_yearly: 5.453,
          },
          {
            ic_uuid: "237d5994-edb2-4158-8dcd-4d1291322616",
            ic_lat: -101379,
            ic_lon: -491481,
            ic_city: "Chapada de Areia",
            ic_states: "TOCANTINS",
            ic_january: 5.022,
            ic_february: 5.008,
            ic_march: 4.752,
            ic_april: 4.837,
            ic_may: 4.99,
            ic_june: 5.073,
            ic_july: 5.348,
            ic_august: 5.982,
            ic_september: 5.657,
            ic_october: 5.323,
            ic_november: 5.099,
            ic_december: 5.051,
            ic_yearly: 5.178,
          },
          {
            ic_uuid: "99a6ba39-6b66-4194-abd1-fae742028f25",
            ic_lat: -170886,
            ic_lon: -425395,
            ic_city: "Chapada do Norte",
            ic_states: "MINAS GERAIS",
            ic_january: 6.147,
            ic_february: 6.411,
            ic_march: 5.375,
            ic_april: 4.669,
            ic_may: 4.066,
            ic_june: 3.889,
            ic_july: 4.087,
            ic_august: 4.897,
            ic_september: 5.421,
            ic_october: 5.613,
            ic_november: 5.219,
            ic_december: 5.949,
            ic_yearly: 5.145,
          },
          {
            ic_uuid: "4c50bb9c-35bf-484b-a4a0-8846bed94f80",
            ic_lat: -154648,
            ic_lon: -557503,
            ic_city: "Chapada dos Guimarães",
            ic_states: "MATO GROSSO",
            ic_january: 5.116,
            ic_february: 5.016,
            ic_march: 4.849,
            ic_april: 4.774,
            ic_may: 4.446,
            ic_june: 4.459,
            ic_july: 4.639,
            ic_august: 5.465,
            ic_september: 5.087,
            ic_october: 5.094,
            ic_november: 5.245,
            ic_december: 5.353,
            ic_yearly: 4.962,
          },
          {
            ic_uuid: "7fe20877-a705-4ee7-9c16-8339e8488f2d",
            ic_lat: -152981,
            ic_lon: -456242,
            ic_city: "Chapada Gaúcha",
            ic_states: "MINAS GERAIS",
            ic_january: 6.04,
            ic_february: 6.267,
            ic_march: 5.41,
            ic_april: 5.472,
            ic_may: 4.954,
            ic_june: 4.8,
            ic_july: 5.144,
            ic_august: 5.843,
            ic_september: 5.976,
            ic_october: 5.857,
            ic_november: 5.302,
            ic_december: 5.891,
            ic_yearly: 5.58,
          },
          {
            ic_uuid: "30710fc7-b8c3-4be1-818e-85d19da6427b",
            ic_lat: -183946,
            ic_lon: -526648,
            ic_city: "Chapadão do Céu",
            ic_states: "GOIÁS",
            ic_january: 5.539,
            ic_february: 5.564,
            ic_march: 5.1,
            ic_april: 4.946,
            ic_may: 4.442,
            ic_june: 4.192,
            ic_july: 4.363,
            ic_august: 5.186,
            ic_september: 5.099,
            ic_october: 5.235,
            ic_november: 5.409,
            ic_december: 5.792,
            ic_yearly: 5.072,
          },
          {
            ic_uuid: "110c7fa6-171e-4723-af70-0d66db27b1eb",
            ic_lat: -27591,
            ic_lon: -495543,
            ic_city: "Chapadão do Lageado",
            ic_states: "SANTA CATARINA",
            ic_january: 5.779,
            ic_february: 5.432,
            ic_march: 4.646,
            ic_april: 3.753,
            ic_may: 2.922,
            ic_june: 2.417,
            ic_july: 2.627,
            ic_august: 3.394,
            ic_september: 3.55,
            ic_october: 4.201,
            ic_november: 5.472,
            ic_december: 5.919,
            ic_yearly: 4.176,
          },
          {
            ic_uuid: "d3914f96-5f1e-4798-859d-2a6f7e2ff72c",
            ic_lat: -187885,
            ic_lon: -526268,
            ic_city: "Chapadão do Sul",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.661,
            ic_february: 5.64,
            ic_march: 5.067,
            ic_april: 4.895,
            ic_may: 4.388,
            ic_june: 4.124,
            ic_july: 4.321,
            ic_august: 5.146,
            ic_september: 5.059,
            ic_october: 5.215,
            ic_november: 5.433,
            ic_december: 5.932,
            ic_yearly: 5.073,
          },
          {
            ic_uuid: "b51b3ea2-8143-4998-97f2-de226d3a86d6",
            ic_lat: -37391,
            ic_lon: -433542,
            ic_city: "Chapadinha",
            ic_states: "MARANHÃO",
            ic_january: 5.023,
            ic_february: 5.155,
            ic_march: 5.207,
            ic_april: 5.033,
            ic_may: 4.968,
            ic_june: 5.18,
            ic_july: 5.454,
            ic_august: 5.97,
            ic_september: 6.364,
            ic_october: 6.276,
            ic_november: 5.949,
            ic_december: 5.496,
            ic_yearly: 5.506,
          },
          {
            ic_uuid: "a4c400a3-7d20-4e47-a61d-74dce54efd88",
            ic_lat: -271009,
            ic_lon: -526156,
            ic_city: "Chapecó",
            ic_states: "SANTA CATARINA",
            ic_january: 6.179,
            ic_february: 5.71,
            ic_march: 5.098,
            ic_april: 4.075,
            ic_may: 3.149,
            ic_june: 2.662,
            ic_july: 2.947,
            ic_august: 3.794,
            ic_september: 4.036,
            ic_october: 5.147,
            ic_november: 6.181,
            ic_december: 6.476,
            ic_yearly: 4.621,
          },
          {
            ic_uuid: "1d33f348-6936-4076-9b14-83e4f1466a1e",
            ic_lat: -225101,
            ic_lon: -477759,
            ic_city: "Charqueada",
            ic_states: "SÃO PAULO",
            ic_january: 5.427,
            ic_february: 5.673,
            ic_march: 4.999,
            ic_april: 4.605,
            ic_may: 3.797,
            ic_june: 3.523,
            ic_july: 3.724,
            ic_august: 4.714,
            ic_september: 4.795,
            ic_october: 5.365,
            ic_november: 5.597,
            ic_december: 5.921,
            ic_yearly: 4.845,
          },
          {
            ic_uuid: "1cf71f66-6c4c-4939-9daf-37a48560dfe8",
            ic_lat: -29963,
            ic_lon: -516293,
            ic_city: "Charqueadas",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.436,
            ic_february: 5.867,
            ic_march: 4.854,
            ic_april: 3.793,
            ic_may: 2.77,
            ic_june: 2.295,
            ic_july: 2.516,
            ic_august: 3.219,
            ic_september: 3.611,
            ic_october: 4.825,
            ic_november: 6.209,
            ic_december: 6.679,
            ic_yearly: 4.423,
          },
          {
            ic_uuid: "70113e0b-2f46-4876-bb45-3222ed53cd35",
            ic_lat: -279497,
            ic_lon: -520155,
            ic_city: "Charrua",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.222,
            ic_february: 5.765,
            ic_march: 4.981,
            ic_april: 4.049,
            ic_may: 3.042,
            ic_june: 2.527,
            ic_july: 2.866,
            ic_august: 3.652,
            ic_september: 3.903,
            ic_october: 5.015,
            ic_november: 6.175,
            ic_december: 6.505,
            ic_yearly: 4.559,
          },
          {
            ic_uuid: "cc713344-ec61-4eac-95a3-7ce8d574dad5",
            ic_lat: -30361,
            ic_lon: -412438,
            ic_city: "Chaval",
            ic_states: "CEARÁ",
            ic_january: 5.33,
            ic_february: 5.168,
            ic_march: 4.974,
            ic_april: 4.777,
            ic_may: 5.065,
            ic_june: 5.279,
            ic_july: 5.519,
            ic_august: 6.102,
            ic_september: 6.291,
            ic_october: 6.22,
            ic_november: 6.293,
            ic_december: 5.815,
            ic_yearly: 5.57,
          },
          {
            ic_uuid: "1e286678-1449-4164-91ff-6d23dbdcb93e",
            ic_lat: -230371,
            ic_lon: -4971,
            ic_city: "Chavantes",
            ic_states: "SÃO PAULO",
            ic_january: 5.659,
            ic_february: 5.804,
            ic_march: 5.203,
            ic_april: 4.702,
            ic_may: 3.727,
            ic_june: 3.429,
            ic_july: 3.631,
            ic_august: 4.637,
            ic_september: 4.73,
            ic_october: 5.397,
            ic_november: 5.905,
            ic_december: 6.295,
            ic_yearly: 4.926,
          },
          {
            ic_uuid: "61370bba-3d2e-4c19-84a7-5d5eff34a849",
            ic_lat: -1645,
            ic_lon: -499874,
            ic_city: "Chaves",
            ic_states: "PARÁ",
            ic_january: 4.494,
            ic_february: 4.08,
            ic_march: 4.004,
            ic_april: 4.042,
            ic_may: 4.529,
            ic_june: 4.914,
            ic_july: 5.069,
            ic_august: 5.388,
            ic_september: 5.622,
            ic_october: 5.503,
            ic_november: 5.35,
            ic_december: 4.788,
            ic_yearly: 4.815,
          },
          {
            ic_uuid: "b1b4ea0d-8b74-4f27-be3e-eda18c19e4d2",
            ic_lat: -220001,
            ic_lon: -43062,
            ic_city: "Chiador",
            ic_states: "MINAS GERAIS",
            ic_january: 5.781,
            ic_february: 5.975,
            ic_march: 5.012,
            ic_april: 4.355,
            ic_may: 3.577,
            ic_june: 3.382,
            ic_july: 3.552,
            ic_august: 4.28,
            ic_september: 4.586,
            ic_october: 4.932,
            ic_november: 4.93,
            ic_december: 5.638,
            ic_yearly: 4.667,
          },
          {
            ic_uuid: "d10c52f9-2636-47f3-8254-b0787ae9fdeb",
            ic_lat: -279235,
            ic_lon: -539423,
            ic_city: "Chiapetta",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.459,
            ic_february: 6.013,
            ic_march: 5.113,
            ic_april: 4.071,
            ic_may: 3.102,
            ic_june: 2.572,
            ic_july: 2.882,
            ic_august: 3.682,
            ic_september: 3.989,
            ic_october: 5.145,
            ic_november: 6.215,
            ic_december: 6.692,
            ic_yearly: 4.661,
          },
          {
            ic_uuid: "6c14212c-ac21-4aa7-91ac-319438cc50ab",
            ic_lat: -25852,
            ic_lon: -525177,
            ic_city: "Chopinzinho",
            ic_states: "PARANÁ",
            ic_january: 6.252,
            ic_february: 5.683,
            ic_march: 5.195,
            ic_april: 4.246,
            ic_may: 3.285,
            ic_june: 2.857,
            ic_july: 3.116,
            ic_august: 4.092,
            ic_september: 4.252,
            ic_october: 5.23,
            ic_november: 6.126,
            ic_december: 6.303,
            ic_yearly: 4.72,
          },
          {
            ic_uuid: "c3f6d6d4-b30b-4954-8a63-643b611b18e7",
            ic_lat: -48395,
            ic_lon: -391347,
            ic_city: "Choró",
            ic_states: "CEARÁ",
            ic_january: 5.391,
            ic_february: 5.433,
            ic_march: 5.546,
            ic_april: 5.05,
            ic_may: 5.107,
            ic_june: 5.006,
            ic_july: 5.369,
            ic_august: 6.081,
            ic_september: 6.54,
            ic_october: 6.386,
            ic_november: 6.169,
            ic_december: 5.553,
            ic_yearly: 5.636,
          },
          {
            ic_uuid: "250eaef4-8155-4182-a48e-8e9269f690d0",
            ic_lat: -43002,
            ic_lon: -385006,
            ic_city: "Chorozinho",
            ic_states: "CEARÁ",
            ic_january: 5.47,
            ic_february: 5.516,
            ic_march: 5.483,
            ic_april: 4.953,
            ic_may: 5.16,
            ic_june: 4.998,
            ic_july: 5.35,
            ic_august: 6.009,
            ic_september: 6.276,
            ic_october: 6.285,
            ic_november: 6.188,
            ic_december: 5.677,
            ic_yearly: 5.614,
          },
          {
            ic_uuid: "0728f1b6-b3e0-47b7-a747-6f6a185945d0",
            ic_lat: -89699,
            ic_lon: -390983,
            ic_city: "Chorrochó",
            ic_states: "BAHIA",
            ic_january: 6.225,
            ic_february: 5.965,
            ic_march: 5.98,
            ic_april: 5.375,
            ic_may: 4.624,
            ic_june: 4.237,
            ic_july: 4.471,
            ic_august: 5.199,
            ic_september: 6.067,
            ic_october: 6.274,
            ic_november: 6.564,
            ic_december: 6.404,
            ic_yearly: 5.615,
          },
          {
            ic_uuid: "7614991a-d31f-4f58-83b7-c7e3d148f72a",
            ic_lat: -336871,
            ic_lon: -534599,
            ic_city: "Chuí",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.61,
            ic_february: 5.735,
            ic_march: 4.986,
            ic_april: 3.588,
            ic_may: 2.55,
            ic_june: 2.167,
            ic_july: 2.28,
            ic_august: 2.926,
            ic_september: 3.81,
            ic_october: 5.199,
            ic_november: 6.47,
            ic_december: 7.036,
            ic_yearly: 4.446,
          },
          {
            ic_uuid: "e8a5cdd0-3a40-4a9c-9c5c-f63c3b2a7557",
            ic_lat: -125533,
            ic_lon: -609026,
            ic_city: "Chupinguaia",
            ic_states: "RONDÔNIA",
            ic_january: 4.63,
            ic_february: 4.568,
            ic_march: 4.572,
            ic_april: 4.591,
            ic_may: 4.247,
            ic_june: 4.379,
            ic_july: 4.499,
            ic_august: 4.958,
            ic_september: 4.959,
            ic_october: 5.229,
            ic_november: 5.126,
            ic_december: 4.948,
            ic_yearly: 4.726,
          },
          {
            ic_uuid: "f8cdf250-38fb-47bd-87fd-358e6b4514d4",
            ic_lat: -307509,
            ic_lon: -519741,
            ic_city: "Chuvisca",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.253,
            ic_february: 5.603,
            ic_march: 4.701,
            ic_april: 3.708,
            ic_may: 2.703,
            ic_june: 2.266,
            ic_july: 2.482,
            ic_august: 3.117,
            ic_september: 3.539,
            ic_october: 4.657,
            ic_november: 6.039,
            ic_december: 6.521,
            ic_yearly: 4.299,
          },
          {
            ic_uuid: "ab688e0d-cd06-4d73-a4c1-bab535225ce3",
            ic_lat: -236603,
            ic_lon: -526059,
            ic_city: "Cianorte",
            ic_states: "PARANÁ",
            ic_january: 6.068,
            ic_february: 5.8,
            ic_march: 5.354,
            ic_april: 4.54,
            ic_may: 3.593,
            ic_june: 3.222,
            ic_july: 3.447,
            ic_august: 4.41,
            ic_september: 4.63,
            ic_october: 5.381,
            ic_november: 6.129,
            ic_december: 6.436,
            ic_yearly: 4.917,
          },
          {
            ic_uuid: "c904ffaa-d05a-4fbb-84cc-9b61cddd9884",
            ic_lat: -105952,
            ic_lon: -383829,
            ic_city: "Cícero Dantas",
            ic_states: "BAHIA",
            ic_january: 6.104,
            ic_february: 5.867,
            ic_march: 5.812,
            ic_april: 5.093,
            ic_may: 4.188,
            ic_june: 3.854,
            ic_july: 3.97,
            ic_august: 4.47,
            ic_september: 5.37,
            ic_october: 5.626,
            ic_november: 6.168,
            ic_december: 6.163,
            ic_yearly: 5.224,
          },
          {
            ic_uuid: "c5d750df-8686-486c-a902-f87092c078e6",
            ic_lat: -233776,
            ic_lon: -52944,
            ic_city: "Cidade Gaúcha",
            ic_states: "PARANÁ",
            ic_january: 6.063,
            ic_february: 5.926,
            ic_march: 5.41,
            ic_april: 4.582,
            ic_may: 3.619,
            ic_june: 3.273,
            ic_july: 3.448,
            ic_august: 4.41,
            ic_september: 4.649,
            ic_october: 5.424,
            ic_november: 6.136,
            ic_december: 6.479,
            ic_yearly: 4.952,
          },
          {
            ic_uuid: "dd3520a1-0f58-4da1-9057-313591088504",
            ic_lat: -160792,
            ic_lon: -479339,
            ic_city: "Cidade Ocidental",
            ic_states: "GOIÁS",
            ic_january: 5.463,
            ic_february: 5.725,
            ic_march: 5.083,
            ic_april: 5.08,
            ic_may: 4.823,
            ic_june: 4.718,
            ic_july: 4.984,
            ic_august: 5.821,
            ic_september: 5.664,
            ic_october: 5.511,
            ic_november: 5.091,
            ic_december: 5.415,
            ic_yearly: 5.282,
          },
          {
            ic_uuid: "d4623b39-4606-4d93-b718-d6d11650f9f4",
            ic_lat: -51772,
            ic_lon: -477838,
            ic_city: "Cidelândia",
            ic_states: "MARANHÃO",
            ic_january: 4.5,
            ic_february: 4.647,
            ic_march: 4.662,
            ic_april: 4.782,
            ic_may: 4.753,
            ic_june: 5.132,
            ic_july: 5.283,
            ic_august: 5.632,
            ic_september: 5.496,
            ic_october: 5.049,
            ic_november: 4.807,
            ic_december: 4.61,
            ic_yearly: 4.946,
          },
          {
            ic_uuid: "44ed4d93-9068-4aaa-a8de-9a0599345a87",
            ic_lat: -301702,
            ic_lon: -502151,
            ic_city: "Cidreira",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.441,
            ic_february: 5.788,
            ic_march: 4.791,
            ic_april: 3.861,
            ic_may: 2.798,
            ic_june: 2.35,
            ic_july: 2.519,
            ic_august: 3.142,
            ic_september: 3.567,
            ic_october: 4.744,
            ic_november: 6.172,
            ic_december: 6.672,
            ic_yearly: 4.404,
          },
          {
            ic_uuid: "72ab1aa6-155d-45a1-91f6-f707b4594551",
            ic_lat: -111036,
            ic_lon: -385183,
            ic_city: "Cipó",
            ic_states: "BAHIA",
            ic_january: 6.151,
            ic_february: 5.976,
            ic_march: 5.899,
            ic_april: 4.964,
            ic_may: 4.132,
            ic_june: 3.869,
            ic_july: 3.987,
            ic_august: 4.465,
            ic_september: 5.321,
            ic_october: 5.64,
            ic_november: 6.002,
            ic_december: 6.096,
            ic_yearly: 5.208,
          },
          {
            ic_uuid: "a3ad076e-72d5-445f-b509-f450306e14ba",
            ic_lat: -209031,
            ic_lon: -433633,
            ic_city: "Cipotânea",
            ic_states: "MINAS GERAIS",
            ic_january: 5.546,
            ic_february: 5.889,
            ic_march: 4.84,
            ic_april: 4.326,
            ic_may: 3.69,
            ic_june: 3.539,
            ic_july: 3.73,
            ic_august: 4.58,
            ic_september: 4.837,
            ic_october: 5.046,
            ic_november: 4.737,
            ic_december: 5.491,
            ic_yearly: 4.687,
          },
          {
            ic_uuid: "e0c74e07-b44d-4c46-993d-f9e6e78b4ae7",
            ic_lat: -283424,
            ic_lon: -518746,
            ic_city: "Ciríaco",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.177,
            ic_february: 5.675,
            ic_march: 4.899,
            ic_april: 3.991,
            ic_may: 3.004,
            ic_june: 2.52,
            ic_july: 2.836,
            ic_august: 3.593,
            ic_september: 3.815,
            ic_october: 4.95,
            ic_november: 6.181,
            ic_december: 6.48,
            ic_yearly: 4.51,
          },
          {
            ic_uuid: "09cf2e6b-088f-47c0-872a-96848a42a4a7",
            ic_lat: -203975,
            ic_lon: -472772,
            ic_city: "Claraval",
            ic_states: "MINAS GERAIS",
            ic_january: 5.592,
            ic_february: 5.953,
            ic_march: 5.124,
            ic_april: 4.942,
            ic_may: 4.306,
            ic_june: 4.128,
            ic_july: 4.321,
            ic_august: 5.263,
            ic_september: 5.206,
            ic_october: 5.576,
            ic_november: 5.639,
            ic_december: 5.8,
            ic_yearly: 5.154,
          },
          {
            ic_uuid: "98dc0ebb-f604-4781-a852-e863f493a71f",
            ic_lat: -1708,
            ic_lon: -44209,
            ic_city: "Claro dos Poções",
            ic_states: "MINAS GERAIS",
            ic_january: 6.252,
            ic_february: 6.498,
            ic_march: 5.596,
            ic_april: 5.354,
            ic_may: 4.827,
            ic_june: 4.534,
            ic_july: 4.871,
            ic_august: 5.64,
            ic_september: 6.029,
            ic_october: 5.916,
            ic_november: 5.397,
            ic_december: 5.98,
            ic_yearly: 5.575,
          },
          {
            ic_uuid: "ddeaf931-4f93-4272-a8ed-ebc1ab49c38f",
            ic_lat: -11507,
            ic_lon: -548777,
            ic_city: "Cláudia",
            ic_states: "MATO GROSSO",
            ic_january: 5.05,
            ic_february: 5.056,
            ic_march: 4.867,
            ic_april: 4.87,
            ic_may: 4.743,
            ic_june: 4.653,
            ic_july: 5.014,
            ic_august: 5.309,
            ic_september: 5.12,
            ic_october: 5.216,
            ic_november: 5.116,
            ic_december: 5.12,
            ic_yearly: 5.011,
          },
          {
            ic_uuid: "01393158-c86f-4071-9831-896ae5e6cdd5",
            ic_lat: -204441,
            ic_lon: -447677,
            ic_city: "Cláudio",
            ic_states: "MINAS GERAIS",
            ic_january: 5.819,
            ic_february: 5.941,
            ic_march: 5.117,
            ic_april: 4.838,
            ic_may: 4.254,
            ic_june: 4.027,
            ic_july: 4.306,
            ic_august: 5.192,
            ic_september: 5.413,
            ic_october: 5.563,
            ic_november: 5.321,
            ic_december: 5.634,
            ic_yearly: 5.119,
          },
          {
            ic_uuid: "4baff48b-bc12-43e9-9e7e-9d5cffced623",
            ic_lat: -2156,
            ic_lon: -504489,
            ic_city: "Clementina",
            ic_states: "SÃO PAULO",
            ic_january: 5.892,
            ic_february: 5.907,
            ic_march: 5.347,
            ic_april: 4.835,
            ic_may: 3.981,
            ic_june: 3.69,
            ic_july: 3.928,
            ic_august: 4.833,
            ic_september: 4.888,
            ic_october: 5.575,
            ic_november: 6.034,
            ic_december: 6.309,
            ic_yearly: 5.101,
          },
          {
            ic_uuid: "3422c58d-e5dc-4428-b052-6bc155e14379",
            ic_lat: -264048,
            ic_lon: -523512,
            ic_city: "Clevelândia",
            ic_states: "PARANÁ",
            ic_january: 6.114,
            ic_february: 5.598,
            ic_march: 5.087,
            ic_april: 4.16,
            ic_may: 3.254,
            ic_june: 2.784,
            ic_july: 3.07,
            ic_august: 4.027,
            ic_september: 4.176,
            ic_october: 5.092,
            ic_november: 6.097,
            ic_december: 6.265,
            ic_yearly: 4.643,
          },
          {
            ic_uuid: "53076fc9-d112-4a3b-98b0-2d669b6ac10e",
            ic_lat: -146375,
            ic_lon: -395559,
            ic_city: "Coaraci",
            ic_states: "BAHIA",
            ic_january: 5.632,
            ic_february: 5.746,
            ic_march: 5.363,
            ic_april: 4.493,
            ic_may: 3.814,
            ic_june: 3.47,
            ic_july: 3.626,
            ic_august: 4.107,
            ic_september: 4.709,
            ic_october: 4.912,
            ic_november: 5.06,
            ic_december: 5.826,
            ic_yearly: 4.73,
          },
          {
            ic_uuid: "b6932b16-e9a6-47ba-bb87-88126fcbb193",
            ic_lat: -40945,
            ic_lon: -631445,
            ic_city: "Coari",
            ic_states: "AMAZONAS",
            ic_january: 4.308,
            ic_february: 4.556,
            ic_march: 4.5,
            ic_april: 4.115,
            ic_may: 3.901,
            ic_june: 4.308,
            ic_july: 4.341,
            ic_august: 4.976,
            ic_september: 5.048,
            ic_october: 4.954,
            ic_november: 4.703,
            ic_december: 4.441,
            ic_yearly: 4.513,
          },
          {
            ic_uuid: "c19a84c5-1527-49b2-a6cd-6564f9315634",
            ic_lat: -34732,
            ic_lon: -41555,
            ic_city: "Cocal",
            ic_states: "PIAUÍ",
            ic_january: 5.146,
            ic_february: 5.228,
            ic_march: 5.107,
            ic_april: 4.811,
            ic_may: 5.036,
            ic_june: 5.216,
            ic_july: 5.443,
            ic_august: 6.035,
            ic_september: 6.453,
            ic_october: 6.493,
            ic_november: 6.29,
            ic_december: 5.582,
            ic_yearly: 5.57,
          },
          {
            ic_uuid: "ae9c6344-d5cb-4ce5-98ec-2076868d5059",
            ic_lat: -45546,
            ic_lon: -419683,
            ic_city: "Cocal de Telha",
            ic_states: "PIAUÍ",
            ic_january: 5.177,
            ic_february: 5.327,
            ic_march: 5.293,
            ic_april: 5.044,
            ic_may: 5.112,
            ic_june: 5.293,
            ic_july: 5.573,
            ic_august: 6.192,
            ic_september: 6.564,
            ic_october: 6.479,
            ic_november: 6.285,
            ic_december: 5.704,
            ic_yearly: 5.67,
          },
          {
            ic_uuid: "5dcd56d8-2c5c-4576-9a22-fd5ccb1911bb",
            ic_lat: -285991,
            ic_lon: -49334,
            ic_city: "Cocal do Sul",
            ic_states: "SANTA CATARINA",
            ic_january: 5.493,
            ic_february: 5.1,
            ic_march: 4.425,
            ic_april: 3.753,
            ic_may: 2.945,
            ic_june: 2.487,
            ic_july: 2.707,
            ic_august: 3.427,
            ic_september: 3.484,
            ic_october: 4.224,
            ic_november: 5.521,
            ic_december: 5.771,
            ic_yearly: 4.111,
          },
          {
            ic_uuid: "b31fd912-fff9-4ec7-b305-e018ec4e21aa",
            ic_lat: -36209,
            ic_lon: -414405,
            ic_city: "Cocal dos Alves",
            ic_states: "PIAUÍ",
            ic_january: 5.148,
            ic_february: 5.158,
            ic_march: 5.012,
            ic_april: 4.764,
            ic_may: 5.023,
            ic_june: 5.155,
            ic_july: 5.361,
            ic_august: 5.931,
            ic_september: 6.449,
            ic_october: 6.508,
            ic_november: 6.39,
            ic_december: 5.611,
            ic_yearly: 5.542,
          },
          {
            ic_uuid: "c4f10a2c-f429-4275-b43c-7d5d3514acaf",
            ic_lat: -14391,
            ic_lon: -510049,
            ic_city: "Cocalinho",
            ic_states: "MATO GROSSO",
            ic_january: 5.426,
            ic_february: 5.661,
            ic_march: 5.301,
            ic_april: 5.273,
            ic_may: 4.88,
            ic_june: 4.617,
            ic_july: 4.931,
            ic_august: 5.471,
            ic_september: 5.49,
            ic_october: 5.523,
            ic_november: 5.34,
            ic_december: 5.443,
            ic_yearly: 5.28,
          },
          {
            ic_uuid: "28a05922-58d2-4fbf-afe9-3724980fccae",
            ic_lat: -157764,
            ic_lon: -487691,
            ic_city: "Cocalzinho de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.39,
            ic_february: 5.487,
            ic_march: 5.013,
            ic_april: 5.017,
            ic_may: 4.87,
            ic_june: 4.659,
            ic_july: 4.93,
            ic_august: 5.902,
            ic_september: 5.594,
            ic_october: 5.492,
            ic_november: 5.062,
            ic_december: 5.311,
            ic_yearly: 5.227,
          },
          {
            ic_uuid: "8589c203-4e24-408e-93d0-195f37430f26",
            ic_lat: -141818,
            ic_lon: -445356,
            ic_city: "Cocos",
            ic_states: "BAHIA",
            ic_january: 6.453,
            ic_february: 6.56,
            ic_march: 5.889,
            ic_april: 5.66,
            ic_may: 5.168,
            ic_june: 4.995,
            ic_july: 5.318,
            ic_august: 5.887,
            ic_september: 6.252,
            ic_october: 6.278,
            ic_november: 5.711,
            ic_december: 6.279,
            ic_yearly: 5.87,
          },
          {
            ic_uuid: "5ef0b9e3-dd8a-4cc2-825e-99ee773babdc",
            ic_lat: -3837,
            ic_lon: -620592,
            ic_city: "Codajás",
            ic_states: "AMAZONAS",
            ic_january: 4.077,
            ic_february: 4.242,
            ic_march: 4.25,
            ic_april: 3.951,
            ic_may: 3.829,
            ic_june: 4.223,
            ic_july: 4.389,
            ic_august: 4.929,
            ic_september: 4.875,
            ic_october: 4.774,
            ic_november: 4.583,
            ic_december: 4.242,
            ic_yearly: 4.364,
          },
          {
            ic_uuid: "2f21bac8-a2d4-4e92-bd9b-2e335565d966",
            ic_lat: -4456,
            ic_lon: -438927,
            ic_city: "Codó",
            ic_states: "MARANHÃO",
            ic_january: 4.864,
            ic_february: 4.985,
            ic_march: 5.036,
            ic_april: 4.873,
            ic_may: 4.879,
            ic_june: 5.082,
            ic_july: 5.32,
            ic_august: 5.96,
            ic_september: 6.385,
            ic_october: 6.124,
            ic_november: 5.744,
            ic_december: 5.346,
            ic_yearly: 5.383,
          },
          {
            ic_uuid: "29f2cf59-b73a-4581-8b14-140cbe6c2d54",
            ic_lat: -42529,
            ic_lon: -430111,
            ic_city: "Coelho Neto",
            ic_states: "MARANHÃO",
            ic_january: 5.113,
            ic_february: 5.26,
            ic_march: 5.234,
            ic_april: 5.149,
            ic_may: 5.088,
            ic_june: 5.166,
            ic_july: 5.45,
            ic_august: 6.063,
            ic_september: 6.444,
            ic_october: 6.386,
            ic_november: 6.05,
            ic_december: 5.625,
            ic_yearly: 5.586,
          },
          {
            ic_uuid: "983ec621-e425-4dfd-9102-a61d0c1a55dc",
            ic_lat: -20854,
            ic_lon: -428012,
            ic_city: "Coimbra",
            ic_states: "MINAS GERAIS",
            ic_january: 5.698,
            ic_february: 5.947,
            ic_march: 4.982,
            ic_april: 4.379,
            ic_may: 3.742,
            ic_june: 3.585,
            ic_july: 3.773,
            ic_august: 4.566,
            ic_september: 4.856,
            ic_october: 5.071,
            ic_november: 4.889,
            ic_december: 5.572,
            ic_yearly: 4.755,
          },
          {
            ic_uuid: "24f4d808-98d9-49f7-8d28-2c629057b52f",
            ic_lat: -96339,
            ic_lon: -365848,
            ic_city: "Coité do Nóia",
            ic_states: "ALAGOAS",
            ic_january: 6.1,
            ic_february: 5.907,
            ic_march: 5.877,
            ic_april: 5.129,
            ic_may: 4.257,
            ic_june: 3.91,
            ic_july: 4.025,
            ic_august: 4.649,
            ic_september: 5.513,
            ic_october: 5.861,
            ic_november: 6.422,
            ic_december: 6.425,
            ic_yearly: 5.339,
          },
          {
            ic_uuid: "43c45443-81f3-49ab-b76c-3328024abf67",
            ic_lat: -50926,
            ic_lon: -422083,
            ic_city: "Coivaras",
            ic_states: "PIAUÍ",
            ic_january: 5.158,
            ic_february: 5.286,
            ic_march: 5.407,
            ic_april: 5.132,
            ic_may: 5.123,
            ic_june: 5.294,
            ic_july: 5.556,
            ic_august: 6.223,
            ic_september: 6.508,
            ic_october: 6.435,
            ic_november: 6.263,
            ic_december: 5.735,
            ic_yearly: 5.677,
          },
          {
            ic_uuid: "e27eb75e-9672-47c8-bd92-08dbc55d6c99",
            ic_lat: -9328,
            ic_lon: -482808,
            ic_city: "Colares",
            ic_states: "PARÁ",
            ic_january: 4.35,
            ic_february: 4.129,
            ic_march: 4.095,
            ic_april: 4.146,
            ic_may: 4.455,
            ic_june: 4.692,
            ic_july: 4.814,
            ic_august: 5.108,
            ic_september: 5.402,
            ic_october: 5.33,
            ic_november: 5.233,
            ic_december: 4.706,
            ic_yearly: 4.705,
          },
          {
            ic_uuid: "99773f09-48df-49d1-9909-d49662db5b85",
            ic_lat: -195389,
            ic_lon: -406278,
            ic_city: "Colatina",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.089,
            ic_february: 6.195,
            ic_march: 5.338,
            ic_april: 4.581,
            ic_may: 3.941,
            ic_june: 3.623,
            ic_july: 3.689,
            ic_august: 4.314,
            ic_september: 4.777,
            ic_october: 4.932,
            ic_november: 4.958,
            ic_december: 5.751,
            ic_yearly: 4.849,
          },
          {
            ic_uuid: "e19cb61d-2160-45f6-94e1-2ad90ff74fff",
            ic_lat: -108139,
            ic_lon: -554614,
            ic_city: "Colíder",
            ic_states: "MATO GROSSO",
            ic_january: 4.998,
            ic_february: 4.94,
            ic_march: 4.818,
            ic_april: 4.738,
            ic_may: 4.669,
            ic_june: 4.68,
            ic_july: 4.978,
            ic_august: 5.481,
            ic_september: 5.136,
            ic_october: 5.213,
            ic_november: 5.095,
            ic_december: 5.063,
            ic_yearly: 4.984,
          },
          {
            ic_uuid: "1063d30b-8230-496f-b302-f9d1e11c65a4",
            ic_lat: -207119,
            ic_lon: -485391,
            ic_city: "Colina",
            ic_states: "SÃO PAULO",
            ic_january: 5.854,
            ic_february: 6.032,
            ic_march: 5.225,
            ic_april: 4.847,
            ic_may: 4.22,
            ic_june: 3.966,
            ic_july: 4.19,
            ic_august: 5.068,
            ic_september: 5.061,
            ic_october: 5.593,
            ic_november: 5.832,
            ic_december: 6.139,
            ic_yearly: 5.169,
          },
          {
            ic_uuid: "4775acd4-1269-42da-92c8-1c129acff570",
            ic_lat: -60322,
            ic_lon: -442473,
            ic_city: "Colinas",
            ic_states: "MARANHÃO",
            ic_january: 4.71,
            ic_february: 4.963,
            ic_march: 4.991,
            ic_april: 4.93,
            ic_may: 5.02,
            ic_june: 5.336,
            ic_july: 5.602,
            ic_august: 6.208,
            ic_september: 6.489,
            ic_october: 5.895,
            ic_november: 5.503,
            ic_december: 5.123,
            ic_yearly: 5.397,
          },
          {
            ic_uuid: "a79fbb96-075f-4b82-b751-5fc96b87e24c",
            ic_lat: -293956,
            ic_lon: -518539,
            ic_city: "Colinas",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.21,
            ic_february: 5.772,
            ic_march: 4.858,
            ic_april: 3.77,
            ic_may: 2.825,
            ic_june: 2.33,
            ic_july: 2.567,
            ic_august: 3.294,
            ic_september: 3.578,
            ic_october: 4.665,
            ic_november: 6.1,
            ic_december: 6.468,
            ic_yearly: 4.37,
          },
          {
            ic_uuid: "d4b47ecb-92a0-4cce-beac-0b1b6c20c76c",
            ic_lat: -141533,
            ic_lon: -480764,
            ic_city: "Colinas do Sul",
            ic_states: "GOIÁS",
            ic_january: 5.425,
            ic_february: 5.554,
            ic_march: 5.279,
            ic_april: 5.206,
            ic_may: 5.042,
            ic_june: 4.9,
            ic_july: 5.181,
            ic_august: 5.987,
            ic_september: 5.786,
            ic_october: 5.66,
            ic_november: 5.186,
            ic_december: 5.421,
            ic_yearly: 5.386,
          },
          {
            ic_uuid: "7f9b0806-0158-4862-ae35-15ef3f8777a2",
            ic_lat: -80581,
            ic_lon: -484761,
            ic_city: "Colinas do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 4.878,
            ic_february: 4.964,
            ic_march: 4.808,
            ic_april: 4.827,
            ic_may: 4.955,
            ic_june: 5.117,
            ic_july: 5.244,
            ic_august: 5.999,
            ic_september: 5.75,
            ic_october: 5.14,
            ic_november: 4.854,
            ic_december: 4.783,
            ic_yearly: 5.11,
          },
          {
            ic_uuid: "653330e9-a540-4f5f-9f39-33c05e90fc3f",
            ic_lat: -87317,
            ic_lon: -487562,
            ic_city: "Colméia",
            ic_states: "TOCANTINS",
            ic_january: 4.802,
            ic_february: 4.804,
            ic_march: 4.646,
            ic_april: 4.676,
            ic_may: 4.998,
            ic_june: 5.111,
            ic_july: 5.271,
            ic_august: 6.031,
            ic_september: 5.698,
            ic_october: 5.1,
            ic_november: 4.839,
            ic_december: 4.773,
            ic_yearly: 5.062,
          },
          {
            ic_uuid: "2e1e3be6-1bb6-47c1-a44a-c45071b61e48",
            ic_lat: -94616,
            ic_lon: -592256,
            ic_city: "Colniza",
            ic_states: "MATO GROSSO",
            ic_january: 4.383,
            ic_february: 4.44,
            ic_march: 4.402,
            ic_april: 4.299,
            ic_may: 4.194,
            ic_june: 4.528,
            ic_july: 4.642,
            ic_august: 5.001,
            ic_september: 4.893,
            ic_october: 5.033,
            ic_november: 4.859,
            ic_december: 4.668,
            ic_yearly: 4.612,
          },
          {
            ic_uuid: "f931a0d0-b6b2-4eb4-8804-0893b558e95d",
            ic_lat: -201772,
            ic_lon: -486869,
            ic_city: "Colômbia",
            ic_states: "SÃO PAULO",
            ic_january: 5.859,
            ic_february: 6.093,
            ic_march: 5.252,
            ic_april: 5.008,
            ic_may: 4.274,
            ic_june: 3.98,
            ic_july: 4.263,
            ic_august: 5.132,
            ic_september: 5.103,
            ic_october: 5.562,
            ic_november: 5.759,
            ic_december: 6.161,
            ic_yearly: 5.204,
          },
          {
            ic_uuid: "12c958e8-b17a-4ecf-9ce6-7ed5913a5293",
            ic_lat: -25293,
            ic_lon: -492266,
            ic_city: "Colombo",
            ic_states: "PARANÁ",
            ic_january: 5.258,
            ic_february: 5.187,
            ic_march: 4.551,
            ic_april: 3.778,
            ic_may: 3.031,
            ic_june: 2.755,
            ic_july: 2.903,
            ic_august: 3.884,
            ic_september: 3.844,
            ic_october: 4.348,
            ic_november: 5.232,
            ic_december: 5.558,
            ic_yearly: 4.194,
          },
          {
            ic_uuid: "50af04c5-eb1e-4e03-80f6-3dadf721e169",
            ic_lat: -81841,
            ic_lon: -437944,
            ic_city: "Colônia do Gurguéia",
            ic_states: "PIAUÍ",
            ic_january: 5.41,
            ic_february: 5.354,
            ic_march: 5.263,
            ic_april: 5.257,
            ic_may: 5.31,
            ic_june: 5.308,
            ic_july: 5.568,
            ic_august: 6.178,
            ic_september: 6.493,
            ic_october: 6.206,
            ic_november: 5.798,
            ic_december: 5.586,
            ic_yearly: 5.644,
          },
          {
            ic_uuid: "6a8ee48e-db49-4882-a71c-b6837bbf1072",
            ic_lat: -72269,
            ic_lon: -421759,
            ic_city: "Colônia do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.521,
            ic_february: 5.405,
            ic_march: 5.422,
            ic_april: 5.296,
            ic_may: 5.356,
            ic_june: 5.264,
            ic_july: 5.609,
            ic_august: 6.204,
            ic_september: 6.712,
            ic_october: 6.475,
            ic_november: 6.297,
            ic_december: 5.861,
            ic_yearly: 5.785,
          },
          {
            ic_uuid: "b76cb696-d028-4f8d-93c6-d24cff50e4dc",
            ic_lat: -89185,
            ic_lon: -357217,
            ic_city: "Colônia Leopoldina",
            ic_states: "ALAGOAS",
            ic_january: 5.574,
            ic_february: 5.6,
            ic_march: 5.598,
            ic_april: 4.919,
            ic_may: 4.144,
            ic_june: 3.749,
            ic_july: 3.82,
            ic_august: 4.413,
            ic_september: 5.17,
            ic_october: 5.411,
            ic_november: 5.854,
            ic_december: 5.839,
            ic_yearly: 5.008,
          },
          {
            ic_uuid: "d0ca0c32-2d52-4fda-aa1b-82a53f6f7e62",
            ic_lat: -228379,
            ic_lon: -519747,
            ic_city: "Colorado",
            ic_states: "PARANÁ",
            ic_january: 5.959,
            ic_february: 5.991,
            ic_march: 5.463,
            ic_april: 4.725,
            ic_may: 3.756,
            ic_june: 3.447,
            ic_july: 3.632,
            ic_august: 4.616,
            ic_september: 4.777,
            ic_october: 5.505,
            ic_november: 6.178,
            ic_december: 6.436,
            ic_yearly: 5.041,
          },
          {
            ic_uuid: "04a9728f-deb9-4881-903a-7fe1c3d04530",
            ic_lat: -285263,
            ic_lon: -529933,
            ic_city: "Colorado",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.338,
            ic_february: 5.888,
            ic_march: 5.013,
            ic_april: 4.0,
            ic_may: 3.037,
            ic_june: 2.502,
            ic_july: 2.811,
            ic_august: 3.57,
            ic_september: 3.872,
            ic_october: 5.037,
            ic_november: 6.242,
            ic_december: 6.621,
            ic_yearly: 4.578,
          },
          {
            ic_uuid: "6a964f06-5cb2-46fe-9d19-1e9e59a64c09",
            ic_lat: -131178,
            ic_lon: -605459,
            ic_city: "Colorado do Oeste",
            ic_states: "RONDÔNIA",
            ic_january: 4.642,
            ic_february: 4.466,
            ic_march: 4.529,
            ic_april: 4.556,
            ic_may: 4.169,
            ic_june: 4.356,
            ic_july: 4.539,
            ic_august: 5.017,
            ic_september: 4.913,
            ic_october: 5.089,
            ic_november: 5.029,
            ic_december: 4.909,
            ic_yearly: 4.684,
          },
          {
            ic_uuid: "2fc58e77-a40b-4170-bf3d-18b952f00732",
            ic_lat: -18234,
            ic_lon: -4284,
            ic_city: "Coluna",
            ic_states: "MINAS GERAIS",
            ic_january: 5.86,
            ic_february: 6.123,
            ic_march: 5.101,
            ic_april: 4.518,
            ic_may: 3.952,
            ic_june: 3.786,
            ic_july: 3.919,
            ic_august: 4.729,
            ic_september: 5.208,
            ic_october: 5.348,
            ic_november: 4.804,
            ic_december: 5.599,
            ic_yearly: 4.912,
          },
          {
            ic_uuid: "4e5bb7b6-7488-409e-ab47-47a31d5157da",
            ic_lat: -128122,
            ic_lon: -465485,
            ic_city: "Combinado",
            ic_states: "TOCANTINS",
            ic_january: 5.986,
            ic_february: 5.892,
            ic_march: 5.545,
            ic_april: 5.458,
            ic_may: 5.224,
            ic_june: 5.008,
            ic_july: 5.348,
            ic_august: 5.833,
            ic_september: 5.99,
            ic_october: 5.823,
            ic_november: 5.542,
            ic_december: 5.923,
            ic_yearly: 5.631,
          },
          {
            ic_uuid: "02a71027-a8d1-495f-9dfa-89d262639f03",
            ic_lat: -19698,
            ic_lon: -490809,
            ic_city: "Comendador Gomes",
            ic_states: "MINAS GERAIS",
            ic_january: 5.686,
            ic_february: 5.855,
            ic_march: 5.198,
            ic_april: 5.031,
            ic_may: 4.363,
            ic_june: 4.104,
            ic_july: 4.38,
            ic_august: 5.273,
            ic_september: 5.169,
            ic_october: 5.566,
            ic_november: 5.762,
            ic_december: 6.008,
            ic_yearly: 5.2,
          },
          {
            ic_uuid: "35d0c29c-395e-4e2f-85f5-5a1b2610b05c",
            ic_lat: -220409,
            ic_lon: -432144,
            ic_city: "Comendador Levy Gasparian",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.654,
            ic_february: 5.872,
            ic_march: 4.897,
            ic_april: 4.297,
            ic_may: 3.536,
            ic_june: 3.375,
            ic_july: 3.527,
            ic_august: 4.227,
            ic_september: 4.553,
            ic_october: 4.88,
            ic_november: 4.8,
            ic_december: 5.541,
            ic_yearly: 4.597,
          },
          {
            ic_uuid: "e854ac73-8f35-4e5a-862e-b226611e0305",
            ic_lat: -162967,
            ic_lon: -417949,
            ic_city: "Comercinho",
            ic_states: "MINAS GERAIS",
            ic_january: 6.1,
            ic_february: 6.36,
            ic_march: 5.474,
            ic_april: 4.764,
            ic_may: 4.068,
            ic_june: 3.735,
            ic_july: 3.993,
            ic_august: 4.673,
            ic_september: 5.474,
            ic_october: 5.588,
            ic_november: 5.303,
            ic_december: 6.093,
            ic_yearly: 5.136,
          },
          {
            ic_uuid: "abf9f43b-fafc-4b1e-a4ce-d82515aa208f",
            ic_lat: -136619,
            ic_lon: -597852,
            ic_city: "Comodoro",
            ic_states: "MATO GROSSO",
            ic_january: 4.71,
            ic_february: 4.547,
            ic_march: 4.478,
            ic_april: 4.477,
            ic_may: 4.214,
            ic_june: 4.29,
            ic_july: 4.56,
            ic_august: 5.038,
            ic_september: 4.891,
            ic_october: 5.047,
            ic_november: 5.015,
            ic_december: 5.006,
            ic_yearly: 4.69,
          },
          {
            ic_uuid: "e1f42326-816b-4c01-99e7-5f95151ff0db",
            ic_lat: -75563,
            ic_lon: -385101,
            ic_city: "Conceição",
            ic_states: "PARAÍBA",
            ic_january: 6.212,
            ic_february: 6.063,
            ic_march: 6.017,
            ic_april: 5.688,
            ic_may: 5.069,
            ic_june: 4.703,
            ic_july: 4.938,
            ic_august: 5.776,
            ic_september: 6.53,
            ic_october: 6.556,
            ic_november: 6.78,
            ic_december: 6.437,
            ic_yearly: 5.897,
          },
          {
            ic_uuid: "a3957a2d-9cc9-446c-9f9b-7a518da57727",
            ic_lat: -210965,
            ic_lon: -462053,
            ic_city: "Conceição da Aparecida",
            ic_states: "MINAS GERAIS",
            ic_january: 5.44,
            ic_february: 5.731,
            ic_march: 4.767,
            ic_april: 4.562,
            ic_may: 3.986,
            ic_june: 3.78,
            ic_july: 4.089,
            ic_august: 5.049,
            ic_september: 5.124,
            ic_october: 5.363,
            ic_november: 5.166,
            ic_december: 5.447,
            ic_yearly: 4.875,
          },
          {
            ic_uuid: "c04d5bed-c213-4814-b278-54ee4086093b",
            ic_lat: -185888,
            ic_lon: -397365,
            ic_city: "Conceição da Barra",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.367,
            ic_february: 6.468,
            ic_march: 5.601,
            ic_april: 4.651,
            ic_may: 4.047,
            ic_june: 3.662,
            ic_july: 3.807,
            ic_august: 4.543,
            ic_september: 5.236,
            ic_october: 5.462,
            ic_november: 5.475,
            ic_december: 6.24,
            ic_yearly: 5.13,
          },
          {
            ic_uuid: "200926cf-156e-47b4-b33c-52757c173a2e",
            ic_lat: -21132,
            ic_lon: -444733,
            ic_city: "Conceição da Barra de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.502,
            ic_february: 5.814,
            ic_march: 4.938,
            ic_april: 4.559,
            ic_may: 3.901,
            ic_june: 3.688,
            ic_july: 3.923,
            ic_august: 4.882,
            ic_september: 5.092,
            ic_october: 5.286,
            ic_november: 5.061,
            ic_december: 5.548,
            ic_yearly: 4.849,
          },
          {
            ic_uuid: "21f1a9b9-5ec0-4672-b1b0-7ece8f783409",
            ic_lat: -125083,
            ic_lon: -389981,
            ic_city: "Conceição da Feira",
            ic_states: "BAHIA",
            ic_january: 6.059,
            ic_february: 5.927,
            ic_march: 5.712,
            ic_april: 4.644,
            ic_may: 3.992,
            ic_june: 3.625,
            ic_july: 3.895,
            ic_august: 4.424,
            ic_september: 5.279,
            ic_october: 5.551,
            ic_november: 5.755,
            ic_december: 6.07,
            ic_yearly: 5.078,
          },
          {
            ic_uuid: "4337dcee-5312-4973-b9eb-91ccbd27f9ca",
            ic_lat: -199176,
            ic_lon: -483843,
            ic_city: "Conceição das Alagoas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.719,
            ic_february: 5.927,
            ic_march: 5.086,
            ic_april: 4.96,
            ic_may: 4.375,
            ic_june: 4.13,
            ic_july: 4.388,
            ic_august: 5.295,
            ic_september: 5.18,
            ic_october: 5.527,
            ic_november: 5.653,
            ic_december: 5.947,
            ic_yearly: 5.182,
          },
          {
            ic_uuid: "3f64d9bf-99a9-45e1-980c-1d016f88e701",
            ic_lat: -221581,
            ic_lon: -454566,
            ic_city: "Conceição das Pedras",
            ic_states: "MINAS GERAIS",
            ic_january: 5.29,
            ic_february: 5.584,
            ic_march: 4.759,
            ic_april: 4.491,
            ic_may: 3.828,
            ic_june: 3.625,
            ic_july: 3.868,
            ic_august: 4.762,
            ic_september: 4.935,
            ic_october: 5.318,
            ic_november: 5.193,
            ic_december: 5.555,
            ic_yearly: 4.767,
          },
          {
            ic_uuid: "80812732-113f-48e7-bc50-5a0897e16e52",
            ic_lat: -199331,
            ic_lon: -416911,
            ic_city: "Conceição de Ipanema",
            ic_states: "MINAS GERAIS",
            ic_january: 5.883,
            ic_february: 6.117,
            ic_march: 5.126,
            ic_april: 4.673,
            ic_may: 3.917,
            ic_june: 3.648,
            ic_july: 3.787,
            ic_august: 4.438,
            ic_september: 4.761,
            ic_october: 4.927,
            ic_november: 4.89,
            ic_december: 5.623,
            ic_yearly: 4.816,
          },
          {
            ic_uuid: "c1926e5c-0308-4beb-ba87-ab5eb62f2a18",
            ic_lat: -220839,
            ic_lon: -418722,
            ic_city: "Conceição de Macabu",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.867,
            ic_february: 6.195,
            ic_march: 4.985,
            ic_april: 4.391,
            ic_may: 3.697,
            ic_june: 3.437,
            ic_july: 3.487,
            ic_august: 4.219,
            ic_september: 4.471,
            ic_october: 4.732,
            ic_november: 4.714,
            ic_december: 5.598,
            ic_yearly: 4.649,
          },
          {
            ic_uuid: "2e106687-4bcd-434f-855e-8c0f98aff6df",
            ic_lat: -12784,
            ic_lon: -391718,
            ic_city: "Conceição do Almeida",
            ic_states: "BAHIA",
            ic_january: 5.961,
            ic_february: 5.917,
            ic_march: 5.683,
            ic_april: 4.619,
            ic_may: 4.007,
            ic_june: 3.648,
            ic_july: 3.865,
            ic_august: 4.391,
            ic_september: 5.179,
            ic_october: 5.447,
            ic_november: 5.689,
            ic_december: 6.058,
            ic_yearly: 5.039,
          },
          {
            ic_uuid: "290b3128-ac3b-4c20-a579-29739de52862",
            ic_lat: -82618,
            ic_lon: -492693,
            ic_city: "Conceição do Araguaia",
            ic_states: "PARÁ",
            ic_january: 4.792,
            ic_february: 4.927,
            ic_march: 4.815,
            ic_april: 4.723,
            ic_may: 4.915,
            ic_june: 5.129,
            ic_july: 5.262,
            ic_august: 5.89,
            ic_september: 5.658,
            ic_october: 5.15,
            ic_november: 4.888,
            ic_december: 4.79,
            ic_yearly: 5.079,
          },
          {
            ic_uuid: "0a7281a9-52e3-45b6-9889-8765404d7973",
            ic_lat: -78768,
            ic_lon: -415945,
            ic_city: "Conceição do Canindé",
            ic_states: "PIAUÍ",
            ic_january: 5.778,
            ic_february: 5.626,
            ic_march: 5.648,
            ic_april: 5.399,
            ic_may: 5.227,
            ic_june: 5.239,
            ic_july: 5.52,
            ic_august: 6.251,
            ic_september: 6.653,
            ic_october: 6.534,
            ic_november: 6.33,
            ic_december: 6.0,
            ic_yearly: 5.85,
          },
          {
            ic_uuid: "cceb3b81-346c-4927-b4f0-f1bde1ed77c4",
            ic_lat: -203644,
            ic_lon: -412421,
            ic_city: "Conceição do Castelo",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.886,
            ic_february: 6.123,
            ic_march: 4.991,
            ic_april: 4.372,
            ic_may: 3.75,
            ic_june: 3.631,
            ic_july: 3.736,
            ic_august: 4.441,
            ic_september: 4.878,
            ic_october: 4.948,
            ic_november: 4.803,
            ic_december: 5.546,
            ic_yearly: 4.759,
          },
          {
            ic_uuid: "26743d31-303a-44d1-8a42-80ef5f2c33db",
            ic_lat: -115604,
            ic_lon: -392812,
            ic_city: "Conceição do Coité",
            ic_states: "BAHIA",
            ic_january: 6.145,
            ic_february: 5.934,
            ic_march: 5.794,
            ic_april: 4.82,
            ic_may: 4.083,
            ic_june: 3.761,
            ic_july: 3.968,
            ic_august: 4.565,
            ic_september: 5.397,
            ic_october: 5.618,
            ic_november: 5.885,
            ic_december: 5.984,
            ic_yearly: 5.163,
          },
          {
            ic_uuid: "d7878923-468e-4d03-9712-a8b64c3f1708",
            ic_lat: -123273,
            ic_lon: -387688,
            ic_city: "Conceição do Jacuípe",
            ic_states: "BAHIA",
            ic_january: 6.076,
            ic_february: 5.904,
            ic_march: 5.716,
            ic_april: 4.639,
            ic_may: 3.961,
            ic_june: 3.638,
            ic_july: 3.889,
            ic_august: 4.376,
            ic_september: 5.262,
            ic_october: 5.492,
            ic_november: 5.765,
            ic_december: 6.065,
            ic_yearly: 5.065,
          },
          {
            ic_uuid: "645e5545-1221-4374-b9ba-bd1b2f72073f",
            ic_lat: -38379,
            ic_lon: -44897,
            ic_city: "Conceição do Lago-Açu",
            ic_states: "MARANHÃO",
            ic_january: 4.814,
            ic_february: 4.927,
            ic_march: 5.013,
            ic_april: 4.965,
            ic_may: 4.931,
            ic_june: 5.143,
            ic_july: 5.288,
            ic_august: 5.867,
            ic_september: 6.137,
            ic_october: 5.797,
            ic_november: 5.367,
            ic_december: 5.15,
            ic_yearly: 5.283,
          },
          {
            ic_uuid: "ff877a87-ec8c-43fb-a32c-0648cb91fe4d",
            ic_lat: -190349,
            ic_lon: -434224,
            ic_city: "Conceição do Mato Dentro",
            ic_states: "MINAS GERAIS",
            ic_january: 5.58,
            ic_february: 5.876,
            ic_march: 4.96,
            ic_april: 4.533,
            ic_may: 3.945,
            ic_june: 3.736,
            ic_july: 3.935,
            ic_august: 4.7,
            ic_september: 5.046,
            ic_october: 5.174,
            ic_november: 4.782,
            ic_december: 5.433,
            ic_yearly: 4.808,
          },
          {
            ic_uuid: "4c764ede-cb46-41e7-aa69-a768d1527bc8",
            ic_lat: -197476,
            ic_lon: -448972,
            ic_city: "Conceição do Pará",
            ic_states: "MINAS GERAIS",
            ic_january: 5.812,
            ic_february: 6.006,
            ic_march: 5.154,
            ic_april: 4.895,
            ic_may: 4.347,
            ic_june: 4.179,
            ic_july: 4.459,
            ic_august: 5.318,
            ic_september: 5.552,
            ic_october: 5.59,
            ic_november: 5.317,
            ic_december: 5.667,
            ic_yearly: 5.192,
          },
          {
            ic_uuid: "edefc7a2-bcee-4892-8b27-ceff7cb45902",
            ic_lat: -218783,
            ic_lon: -450874,
            ic_city: "Conceição do Rio Verde",
            ic_states: "MINAS GERAIS",
            ic_january: 5.422,
            ic_february: 5.736,
            ic_march: 4.955,
            ic_april: 4.59,
            ic_may: 3.898,
            ic_june: 3.664,
            ic_july: 3.878,
            ic_august: 4.734,
            ic_september: 5.002,
            ic_october: 5.394,
            ic_november: 5.301,
            ic_december: 5.661,
            ic_yearly: 4.853,
          },
          {
            ic_uuid: "cb5cceea-2e62-4d0e-818b-04159f66ed0f",
            ic_lat: -122237,
            ic_lon: -472952,
            ic_city: "Conceição do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 5.787,
            ic_february: 5.765,
            ic_march: 5.432,
            ic_april: 5.359,
            ic_may: 5.3,
            ic_june: 5.134,
            ic_july: 5.435,
            ic_august: 5.988,
            ic_september: 6.126,
            ic_october: 5.854,
            ic_november: 5.574,
            ic_december: 5.727,
            ic_yearly: 5.624,
          },
          {
            ic_uuid: "03b56c5d-09b3-4174-8df2-3b7708bd6eba",
            ic_lat: -224083,
            ic_lon: -458,
            ic_city: "Conceição dos Ouros",
            ic_states: "MINAS GERAIS",
            ic_january: 5.324,
            ic_february: 5.678,
            ic_march: 4.865,
            ic_april: 4.592,
            ic_may: 3.867,
            ic_june: 3.61,
            ic_july: 3.826,
            ic_august: 4.747,
            ic_september: 4.919,
            ic_october: 5.408,
            ic_november: 5.401,
            ic_december: 5.726,
            ic_yearly: 4.83,
          },
          {
            ic_uuid: "c3836e1d-58bc-4b24-a195-2bc9978aad4b",
            ic_lat: -22338,
            ic_lon: -471733,
            ic_city: "Conchal",
            ic_states: "SÃO PAULO",
            ic_january: 5.588,
            ic_february: 5.845,
            ic_march: 5.17,
            ic_april: 4.682,
            ic_may: 3.905,
            ic_june: 3.686,
            ic_july: 3.822,
            ic_august: 4.765,
            ic_september: 4.932,
            ic_october: 5.566,
            ic_november: 5.698,
            ic_december: 6.057,
            ic_yearly: 4.977,
          },
          {
            ic_uuid: "e69c16a1-0104-4eea-b04b-cf5e0b4dd3c5",
            ic_lat: -230159,
            ic_lon: -480138,
            ic_city: "Conchas",
            ic_states: "SÃO PAULO",
            ic_january: 5.631,
            ic_february: 5.823,
            ic_march: 5.179,
            ic_april: 4.634,
            ic_may: 3.723,
            ic_june: 3.456,
            ic_july: 3.602,
            ic_august: 4.598,
            ic_september: 4.755,
            ic_october: 5.348,
            ic_november: 5.801,
            ic_december: 6.19,
            ic_yearly: 4.895,
          },
          {
            ic_uuid: "eaf76d79-b53d-4b0b-81c0-7d9028099c0f",
            ic_lat: -272339,
            ic_lon: -520264,
            ic_city: "Concórdia",
            ic_states: "SANTA CATARINA",
            ic_january: 6.106,
            ic_february: 5.607,
            ic_march: 5.057,
            ic_april: 4.061,
            ic_may: 3.109,
            ic_june: 2.647,
            ic_july: 2.921,
            ic_august: 3.79,
            ic_september: 4.016,
            ic_october: 5.013,
            ic_november: 6.134,
            ic_december: 6.379,
            ic_yearly: 4.57,
          },
          {
            ic_uuid: "c0f84f48-c801-4da5-9001-cbe7be08a989",
            ic_lat: -19928,
            ic_lon: -479426,
            ic_city: "Concórdia do Pará",
            ic_states: "PARÁ",
            ic_january: 4.58,
            ic_february: 4.561,
            ic_march: 4.591,
            ic_april: 4.587,
            ic_may: 4.7,
            ic_june: 4.905,
            ic_july: 5.031,
            ic_august: 5.343,
            ic_september: 5.373,
            ic_october: 5.204,
            ic_november: 4.95,
            ic_december: 4.666,
            ic_yearly: 4.874,
          },
          {
            ic_uuid: "f01354f3-350e-496b-a0f3-db518eae34b9",
            ic_lat: -68987,
            ic_lon: -376063,
            ic_city: "Condado",
            ic_states: "PARAÍBA",
            ic_january: 6.211,
            ic_february: 6.279,
            ic_march: 6.337,
            ic_april: 5.92,
            ic_may: 5.271,
            ic_june: 4.875,
            ic_july: 5.055,
            ic_august: 5.892,
            ic_september: 6.506,
            ic_october: 6.671,
            ic_november: 6.734,
            ic_december: 6.46,
            ic_yearly: 6.017,
          },
          {
            ic_uuid: "f44ecf98-5f53-43ad-82d1-4101d191523d",
            ic_lat: -75883,
            ic_lon: -351002,
            ic_city: "Condado",
            ic_states: "PERNAMBUCO",
            ic_january: 5.648,
            ic_february: 5.79,
            ic_march: 5.861,
            ic_april: 5.198,
            ic_may: 4.541,
            ic_june: 4.135,
            ic_july: 4.265,
            ic_august: 5.031,
            ic_september: 5.532,
            ic_october: 5.8,
            ic_november: 6.09,
            ic_december: 6.045,
            ic_yearly: 5.328,
          },
          {
            ic_uuid: "12fd5fa3-6a3d-487f-b97a-f3a821be0670",
            ic_lat: -118184,
            ic_lon: -376134,
            ic_city: "Conde",
            ic_states: "BAHIA",
            ic_january: 6.314,
            ic_february: 6.156,
            ic_march: 6.038,
            ic_april: 4.941,
            ic_may: 4.268,
            ic_june: 3.973,
            ic_july: 4.098,
            ic_august: 4.629,
            ic_september: 5.433,
            ic_october: 5.8,
            ic_november: 6.181,
            ic_december: 6.471,
            ic_yearly: 5.359,
          },
          {
            ic_uuid: "75045518-7ad4-4062-bf97-0a948f750a1a",
            ic_lat: -72579,
            ic_lon: -349002,
            ic_city: "Conde",
            ic_states: "PARAÍBA",
            ic_january: 5.812,
            ic_february: 5.992,
            ic_march: 6.019,
            ic_april: 5.313,
            ic_may: 4.734,
            ic_june: 4.32,
            ic_july: 4.385,
            ic_august: 5.19,
            ic_september: 5.594,
            ic_october: 5.92,
            ic_november: 6.059,
            ic_december: 6.048,
            ic_yearly: 5.449,
          },
          {
            ic_uuid: "9c8305d2-3c3b-475d-bcb4-eac4b105038e",
            ic_lat: -149027,
            ic_lon: -419722,
            ic_city: "Condeúba",
            ic_states: "BAHIA",
            ic_january: 6.207,
            ic_february: 6.338,
            ic_march: 5.661,
            ic_april: 4.976,
            ic_may: 4.364,
            ic_june: 3.962,
            ic_july: 4.259,
            ic_august: 4.975,
            ic_september: 5.748,
            ic_october: 5.922,
            ic_november: 5.539,
            ic_december: 6.235,
            ic_yearly: 5.349,
          },
          {
            ic_uuid: "99e0eb30-31c8-4709-8a8d-c8f9e76fd438",
            ic_lat: -28208,
            ic_lon: -53491,
            ic_city: "Condor",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.323,
            ic_february: 5.923,
            ic_march: 5.087,
            ic_april: 4.015,
            ic_may: 3.059,
            ic_june: 2.538,
            ic_july: 2.83,
            ic_august: 3.584,
            ic_september: 3.921,
            ic_october: 5.098,
            ic_november: 6.171,
            ic_december: 6.591,
            ic_yearly: 4.595,
          },
          {
            ic_uuid: "42df019b-f663-4c9d-9cb8-e7a834e07351",
            ic_lat: -152987,
            ic_lon: -444129,
            ic_city: "Cônego Marinho",
            ic_states: "MINAS GERAIS",
            ic_january: 6.375,
            ic_february: 6.597,
            ic_march: 5.805,
            ic_april: 5.641,
            ic_may: 5.106,
            ic_june: 4.837,
            ic_july: 5.234,
            ic_august: 5.851,
            ic_september: 6.142,
            ic_october: 6.091,
            ic_november: 5.493,
            ic_december: 6.1,
            ic_yearly: 5.773,
          },
          {
            ic_uuid: "9f531a10-6838-4254-8d2a-669d86f7081c",
            ic_lat: -196287,
            ic_lon: -439935,
            ic_city: "Confins",
            ic_states: "MINAS GERAIS",
            ic_january: 5.891,
            ic_february: 6.092,
            ic_march: 5.288,
            ic_april: 4.908,
            ic_may: 4.346,
            ic_june: 4.113,
            ic_july: 4.397,
            ic_august: 5.193,
            ic_september: 5.592,
            ic_october: 5.635,
            ic_november: 5.458,
            ic_december: 5.729,
            ic_yearly: 5.22,
          },
          {
            ic_uuid: "95fee31f-2cb1-4349-bbac-44b19dd430de",
            ic_lat: -106441,
            ic_lon: -515703,
            ic_city: "Confresa",
            ic_states: "MATO GROSSO",
            ic_january: 4.886,
            ic_february: 5.011,
            ic_march: 4.794,
            ic_april: 4.641,
            ic_may: 4.868,
            ic_june: 4.866,
            ic_july: 5.043,
            ic_august: 5.663,
            ic_september: 5.225,
            ic_october: 5.086,
            ic_november: 5.004,
            ic_december: 5.074,
            ic_yearly: 5.013,
          },
          {
            ic_uuid: "28b03e0a-4644-4688-b9bc-776bcc963ce0",
            ic_lat: -77912,
            ic_lon: -366584,
            ic_city: "Congo",
            ic_states: "PARAÍBA",
            ic_january: 6.137,
            ic_february: 6.012,
            ic_march: 6.053,
            ic_april: 5.631,
            ic_may: 4.838,
            ic_june: 4.359,
            ic_july: 4.479,
            ic_august: 5.212,
            ic_september: 6.028,
            ic_october: 6.325,
            ic_november: 6.535,
            ic_december: 6.274,
            ic_yearly: 5.657,
          },
          {
            ic_uuid: "2dd3d6b2-5bdf-4910-bacb-4bddcd1e8f03",
            ic_lat: -221492,
            ic_lon: -460434,
            ic_city: "Congonhal",
            ic_states: "MINAS GERAIS",
            ic_january: 5.104,
            ic_february: 5.404,
            ic_march: 4.582,
            ic_april: 4.397,
            ic_may: 3.82,
            ic_june: 3.58,
            ic_july: 3.845,
            ic_august: 4.791,
            ic_september: 4.88,
            ic_october: 5.211,
            ic_november: 5.103,
            ic_december: 5.415,
            ic_yearly: 4.678,
          },
          {
            ic_uuid: "dbe2aae4-3647-4034-a1ad-120423d91060",
            ic_lat: -204963,
            ic_lon: -438514,
            ic_city: "Congonhas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.474,
            ic_february: 5.698,
            ic_march: 4.793,
            ic_april: 4.459,
            ic_may: 4.03,
            ic_june: 3.859,
            ic_july: 4.019,
            ic_august: 4.973,
            ic_september: 5.119,
            ic_october: 5.181,
            ic_november: 4.861,
            ic_december: 5.352,
            ic_yearly: 4.818,
          },
          {
            ic_uuid: "4f715934-1133-4e9e-8953-67204c2ea8f1",
            ic_lat: -188025,
            ic_lon: -436771,
            ic_city: "Congonhas do Norte",
            ic_states: "MINAS GERAIS",
            ic_january: 5.701,
            ic_february: 5.892,
            ic_march: 4.996,
            ic_april: 4.573,
            ic_may: 4.096,
            ic_june: 3.92,
            ic_july: 4.16,
            ic_august: 4.934,
            ic_september: 5.347,
            ic_october: 5.442,
            ic_november: 4.933,
            ic_december: 5.52,
            ic_yearly: 4.959,
          },
          {
            ic_uuid: "3e1d373f-2987-41af-846a-cffa623ce69d",
            ic_lat: -23551,
            ic_lon: -505539,
            ic_city: "Congonhinhas",
            ic_states: "PARANÁ",
            ic_january: 5.78,
            ic_february: 5.713,
            ic_march: 5.141,
            ic_april: 4.541,
            ic_may: 3.62,
            ic_june: 3.286,
            ic_july: 3.504,
            ic_august: 4.518,
            ic_september: 4.637,
            ic_october: 5.25,
            ic_november: 5.949,
            ic_december: 6.222,
            ic_yearly: 4.847,
          },
          {
            ic_uuid: "55968adf-e53c-4048-b551-452b732356c5",
            ic_lat: -199367,
            ic_lon: -475449,
            ic_city: "Conquista",
            ic_states: "MINAS GERAIS",
            ic_january: 5.469,
            ic_february: 5.822,
            ic_march: 4.937,
            ic_april: 4.904,
            ic_may: 4.4,
            ic_june: 4.163,
            ic_july: 4.431,
            ic_august: 5.349,
            ic_september: 5.217,
            ic_october: 5.503,
            ic_november: 5.388,
            ic_december: 5.767,
            ic_yearly: 5.112,
          },
          {
            ic_uuid: "4d2ea009-5cba-454b-8453-14216a7a53ca",
            ic_lat: -145413,
            ic_lon: -595413,
            ic_city: "Conquista D'Oeste",
            ic_states: "MATO GROSSO",
            ic_january: 5.036,
            ic_february: 4.839,
            ic_march: 4.739,
            ic_april: 4.68,
            ic_may: 4.285,
            ic_june: 4.339,
            ic_july: 4.536,
            ic_august: 5.095,
            ic_september: 5.035,
            ic_october: 5.274,
            ic_november: 5.444,
            ic_december: 5.416,
            ic_yearly: 4.893,
          },
          {
            ic_uuid: "be4431b1-245d-4bc3-9c31-f0c0a1f22e21",
            ic_lat: -206639,
            ic_lon: -43785,
            ic_city: "Conselheiro Lafaiete",
            ic_states: "MINAS GERAIS",
            ic_january: 5.509,
            ic_february: 5.908,
            ic_march: 4.937,
            ic_april: 4.52,
            ic_may: 3.912,
            ic_june: 3.731,
            ic_july: 3.88,
            ic_august: 4.888,
            ic_september: 5.083,
            ic_october: 5.264,
            ic_november: 4.995,
            ic_december: 5.557,
            ic_yearly: 4.849,
          },
          {
            ic_uuid: "7df6a868-141e-42ef-a61b-c48faa3bee99",
            ic_lat: -236269,
            ic_lon: -501712,
            ic_city: "Conselheiro Mairinck",
            ic_states: "PARANÁ",
            ic_january: 5.824,
            ic_february: 5.742,
            ic_march: 5.191,
            ic_april: 4.552,
            ic_may: 3.6,
            ic_june: 3.226,
            ic_july: 3.478,
            ic_august: 4.483,
            ic_september: 4.639,
            ic_october: 5.257,
            ic_november: 5.932,
            ic_december: 6.307,
            ic_yearly: 4.853,
          },
          {
            ic_uuid: "6216062f-07fc-431c-b756-5109ce2489a6",
            ic_lat: -191794,
            ic_lon: -414739,
            ic_city: "Conselheiro Pena",
            ic_states: "MINAS GERAIS",
            ic_january: 6.229,
            ic_february: 6.321,
            ic_march: 5.395,
            ic_april: 4.738,
            ic_may: 3.994,
            ic_june: 3.707,
            ic_july: 3.818,
            ic_august: 4.445,
            ic_september: 4.954,
            ic_october: 5.202,
            ic_november: 5.064,
            ic_december: 5.95,
            ic_yearly: 4.985,
          },
          {
            ic_uuid: "1a6a7603-63f6-427c-ba05-dfbc34195747",
            ic_lat: -225498,
            ic_lon: -459259,
            ic_city: "Consolação",
            ic_states: "MINAS GERAIS",
            ic_january: 5.246,
            ic_february: 5.62,
            ic_march: 4.807,
            ic_april: 4.561,
            ic_may: 3.822,
            ic_june: 3.548,
            ic_july: 3.79,
            ic_august: 4.702,
            ic_september: 4.886,
            ic_october: 5.337,
            ic_november: 5.329,
            ic_december: 5.683,
            ic_yearly: 4.778,
          },
          {
            ic_uuid: "8915ed0c-76a4-409b-9066-f5af88a1f401",
            ic_lat: -277324,
            ic_lon: -529943,
            ic_city: "Constantina",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.277,
            ic_february: 5.897,
            ic_march: 5.082,
            ic_april: 4.109,
            ic_may: 3.11,
            ic_june: 2.591,
            ic_july: 2.93,
            ic_august: 3.695,
            ic_september: 3.958,
            ic_october: 5.147,
            ic_november: 6.225,
            ic_december: 6.647,
            ic_yearly: 4.639,
          },
          {
            ic_uuid: "c03ab116-8062-47b3-b029-b3104c257576",
            ic_lat: -199326,
            ic_lon: -440543,
            ic_city: "Contagem",
            ic_states: "MINAS GERAIS",
            ic_january: 5.789,
            ic_february: 5.996,
            ic_march: 5.173,
            ic_april: 4.823,
            ic_may: 4.196,
            ic_june: 4.054,
            ic_july: 4.297,
            ic_august: 5.165,
            ic_september: 5.546,
            ic_october: 5.653,
            ic_november: 5.303,
            ic_december: 5.623,
            ic_yearly: 5.135,
          },
          {
            ic_uuid: "fa7bd76d-7f56-494a-84c1-9726ede20b2b",
            ic_lat: -256793,
            ic_lon: -495355,
            ic_city: "Contenda",
            ic_states: "PARANÁ",
            ic_january: 5.396,
            ic_february: 5.147,
            ic_march: 4.513,
            ic_april: 3.743,
            ic_may: 3.019,
            ic_june: 2.706,
            ic_july: 2.87,
            ic_august: 3.838,
            ic_september: 3.872,
            ic_october: 4.434,
            ic_november: 5.31,
            ic_december: 5.617,
            ic_yearly: 4.205,
          },
          {
            ic_uuid: "0dfc2f25-e31b-40ce-930b-e99dcf388d2b",
            ic_lat: -137628,
            ic_lon: -41043,
            ic_city: "Contendas do Sincorá",
            ic_states: "BAHIA",
            ic_january: 6.102,
            ic_february: 6.115,
            ic_march: 5.68,
            ic_april: 4.771,
            ic_may: 4.172,
            ic_june: 3.805,
            ic_july: 4.029,
            ic_august: 4.643,
            ic_september: 5.583,
            ic_october: 5.681,
            ic_november: 5.608,
            ic_december: 6.223,
            ic_yearly: 5.201,
          },
          {
            ic_uuid: "17ca3b50-21c3-402b-b8f4-9af2b28babf5",
            ic_lat: -211862,
            ic_lon: -45437,
            ic_city: "Coqueiral",
            ic_states: "MINAS GERAIS",
            ic_january: 5.602,
            ic_february: 5.82,
            ic_march: 4.936,
            ic_april: 4.711,
            ic_may: 4.049,
            ic_june: 3.806,
            ic_july: 4.09,
            ic_august: 4.988,
            ic_september: 5.173,
            ic_october: 5.483,
            ic_november: 5.247,
            ic_december: 5.689,
            ic_yearly: 4.966,
          },
          {
            ic_uuid: "fe4d0f66-610e-40c3-9933-ace92ebf961a",
            ic_lat: -291811,
            ic_lon: -520951,
            ic_city: "Coqueiro Baixo",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.123,
            ic_february: 5.706,
            ic_march: 4.782,
            ic_april: 3.795,
            ic_may: 2.826,
            ic_june: 2.352,
            ic_july: 2.622,
            ic_august: 3.358,
            ic_september: 3.627,
            ic_october: 4.654,
            ic_november: 6.045,
            ic_december: 6.414,
            ic_yearly: 4.359,
          },
          {
            ic_uuid: "17585cc5-6945-4c92-879b-24fd2b8e5e1c",
            ic_lat: -96376,
            ic_lon: -357997,
            ic_city: "Coqueiro Seco",
            ic_states: "ALAGOAS",
            ic_january: 6.047,
            ic_february: 6.015,
            ic_march: 5.95,
            ic_april: 5.087,
            ic_may: 4.306,
            ic_june: 4.008,
            ic_july: 4.137,
            ic_august: 4.761,
            ic_september: 5.515,
            ic_october: 5.875,
            ic_november: 6.307,
            ic_december: 6.31,
            ic_yearly: 5.36,
          },
          {
            ic_uuid: "d8f6cbca-b1d0-4bd5-8583-cb4ef435d090",
            ic_lat: -281199,
            ic_lon: -527847,
            ic_city: "Coqueiros do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.346,
            ic_february: 5.84,
            ic_march: 5.01,
            ic_april: 4.044,
            ic_may: 3.054,
            ic_june: 2.542,
            ic_july: 2.851,
            ic_august: 3.625,
            ic_september: 3.875,
            ic_october: 5.071,
            ic_november: 6.207,
            ic_december: 6.588,
            ic_yearly: 4.588,
          },
          {
            ic_uuid: "56d324ba-d875-40b1-a907-332b6802d524",
            ic_lat: -166845,
            ic_lon: -443639,
            ic_city: "Coração de Jesus",
            ic_states: "MINAS GERAIS",
            ic_january: 6.209,
            ic_february: 6.533,
            ic_march: 5.553,
            ic_april: 5.416,
            ic_may: 4.906,
            ic_june: 4.645,
            ic_july: 4.952,
            ic_august: 5.782,
            ic_september: 5.999,
            ic_october: 5.941,
            ic_november: 5.423,
            ic_december: 6.024,
            ic_yearly: 5.615,
          },
          {
            ic_uuid: "67d70df0-e249-4c80-ad32-a2aca002ab86",
            ic_lat: -122493,
            ic_lon: -387477,
            ic_city: "Coração de Maria",
            ic_states: "BAHIA",
            ic_january: 6.058,
            ic_february: 5.841,
            ic_march: 5.728,
            ic_april: 4.7,
            ic_may: 3.958,
            ic_june: 3.681,
            ic_july: 3.902,
            ic_august: 4.445,
            ic_september: 5.282,
            ic_october: 5.522,
            ic_november: 5.811,
            ic_december: 6.081,
            ic_yearly: 5.084,
          },
          {
            ic_uuid: "8d68419d-072f-4232-99c0-401f0986591f",
            ic_lat: -247976,
            ic_lon: -53301,
            ic_city: "Corbélia",
            ic_states: "PARANÁ",
            ic_january: 6.311,
            ic_february: 5.554,
            ic_march: 5.191,
            ic_april: 4.309,
            ic_may: 3.393,
            ic_june: 3.026,
            ic_july: 3.225,
            ic_august: 4.221,
            ic_september: 4.44,
            ic_october: 5.192,
            ic_november: 6.045,
            ic_december: 6.43,
            ic_yearly: 4.778,
          },
          {
            ic_uuid: "dd0cbfa6-3d32-49ee-bd8d-6c4b4585d815",
            ic_lat: -220272,
            ic_lon: -423652,
            ic_city: "Cordeiro",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.878,
            ic_february: 6.137,
            ic_march: 5.01,
            ic_april: 4.33,
            ic_may: 3.62,
            ic_june: 3.361,
            ic_july: 3.537,
            ic_august: 4.247,
            ic_september: 4.556,
            ic_october: 4.853,
            ic_november: 4.825,
            ic_december: 5.665,
            ic_yearly: 4.668,
          },
          {
            ic_uuid: "3de2d332-3bdc-4087-b8c4-ba8a9fa177a2",
            ic_lat: -224783,
            ic_lon: -474523,
            ic_city: "Cordeirópolis",
            ic_states: "SÃO PAULO",
            ic_january: 5.567,
            ic_february: 5.736,
            ic_march: 5.069,
            ic_april: 4.621,
            ic_may: 3.82,
            ic_june: 3.583,
            ic_july: 3.75,
            ic_august: 4.74,
            ic_september: 4.832,
            ic_october: 5.433,
            ic_november: 5.631,
            ic_december: 6.001,
            ic_yearly: 4.899,
          },
          {
            ic_uuid: "69b5112f-5b8d-40ae-a70c-ad2b71a76a5b",
            ic_lat: -150392,
            ic_lon: -419318,
            ic_city: "Cordeiros",
            ic_states: "BAHIA",
            ic_january: 6.2,
            ic_february: 6.339,
            ic_march: 5.569,
            ic_april: 4.898,
            ic_may: 4.336,
            ic_june: 3.909,
            ic_july: 4.225,
            ic_august: 4.941,
            ic_september: 5.722,
            ic_october: 5.879,
            ic_november: 5.533,
            ic_december: 6.255,
            ic_yearly: 5.317,
          },
          {
            ic_uuid: "f0032978-f650-45f2-af1f-c762a2833e07",
            ic_lat: -269849,
            ic_lon: -52606,
            ic_city: "Cordilheira Alta",
            ic_states: "SANTA CATARINA",
            ic_january: 6.246,
            ic_february: 5.739,
            ic_march: 5.148,
            ic_april: 4.116,
            ic_may: 3.152,
            ic_june: 2.696,
            ic_july: 2.959,
            ic_august: 3.818,
            ic_september: 4.056,
            ic_october: 5.161,
            ic_november: 6.199,
            ic_december: 6.506,
            ic_yearly: 4.65,
          },
          {
            ic_uuid: "08712274-e4a1-477e-9446-91837cb7a8fe",
            ic_lat: -191229,
            ic_lon: -443228,
            ic_city: "Cordisburgo",
            ic_states: "MINAS GERAIS",
            ic_january: 5.982,
            ic_february: 6.236,
            ic_march: 5.34,
            ic_april: 5.052,
            ic_may: 4.469,
            ic_june: 4.226,
            ic_july: 4.566,
            ic_august: 5.284,
            ic_september: 5.729,
            ic_october: 5.764,
            ic_november: 5.472,
            ic_december: 5.831,
            ic_yearly: 5.329,
          },
          {
            ic_uuid: "64aff87c-e2da-4cdf-b441-1ba47a2097d4",
            ic_lat: -217896,
            ic_lon: -457003,
            ic_city: "Cordislândia",
            ic_states: "MINAS GERAIS",
            ic_january: 5.451,
            ic_february: 5.715,
            ic_march: 4.872,
            ic_april: 4.574,
            ic_may: 3.938,
            ic_june: 3.7,
            ic_july: 3.934,
            ic_august: 4.819,
            ic_september: 5.016,
            ic_october: 5.371,
            ic_november: 5.254,
            ic_december: 5.617,
            ic_yearly: 4.855,
          },
          {
            ic_uuid: "fd23f686-d911-4fbc-bf2e-578b8b7a4ba0",
            ic_lat: -35522,
            ic_lon: -406536,
            ic_city: "Coreaú",
            ic_states: "CEARÁ",
            ic_january: 4.963,
            ic_february: 5.167,
            ic_march: 5.116,
            ic_april: 4.817,
            ic_may: 5.034,
            ic_june: 5.028,
            ic_july: 5.308,
            ic_august: 6.006,
            ic_september: 6.451,
            ic_october: 6.376,
            ic_november: 6.086,
            ic_december: 5.289,
            ic_yearly: 5.47,
          },
          {
            ic_uuid: "02dbd1b1-5139-4de7-bcb5-e9db5c947551",
            ic_lat: -70075,
            ic_lon: -379349,
            ic_city: "Coremas",
            ic_states: "PARAÍBA",
            ic_january: 6.241,
            ic_february: 6.23,
            ic_march: 6.227,
            ic_april: 5.916,
            ic_may: 5.328,
            ic_june: 4.909,
            ic_july: 5.177,
            ic_august: 5.986,
            ic_september: 6.442,
            ic_october: 6.578,
            ic_november: 6.734,
            ic_december: 6.385,
            ic_yearly: 6.013,
          },
          {
            ic_uuid: "021e4f20-5d12-4267-874f-1cc31b4dd22d",
            ic_lat: -198301,
            ic_lon: -548316,
            ic_city: "Corguinho",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.667,
            ic_february: 5.667,
            ic_march: 5.409,
            ic_april: 4.886,
            ic_may: 4.084,
            ic_june: 3.858,
            ic_july: 4.04,
            ic_august: 4.863,
            ic_september: 5.006,
            ic_october: 5.487,
            ic_november: 5.903,
            ic_december: 6.107,
            ic_yearly: 5.081,
          },
          {
            ic_uuid: "53f7d35e-9db1-44af-a5a3-55c3bab4a2c1",
            ic_lat: -138281,
            ic_lon: -444569,
            ic_city: "Coribe",
            ic_states: "BAHIA",
            ic_january: 6.403,
            ic_february: 6.44,
            ic_march: 5.726,
            ic_april: 5.552,
            ic_may: 5.134,
            ic_june: 4.962,
            ic_july: 5.285,
            ic_august: 5.853,
            ic_september: 6.209,
            ic_october: 6.27,
            ic_november: 5.649,
            ic_december: 6.238,
            ic_yearly: 5.81,
          },
          {
            ic_uuid: "53511668-038a-414f-acaa-753be78db757",
            ic_lat: -183694,
            ic_lon: -444546,
            ic_city: "Corinto",
            ic_states: "MINAS GERAIS",
            ic_january: 6.074,
            ic_february: 6.4,
            ic_march: 5.49,
            ic_april: 5.272,
            ic_may: 4.676,
            ic_june: 4.389,
            ic_july: 4.763,
            ic_august: 5.411,
            ic_september: 5.846,
            ic_october: 5.931,
            ic_november: 5.549,
            ic_december: 5.951,
            ic_yearly: 5.48,
          },
          {
            ic_uuid: "117268b6-5d7a-4736-8b19-bee9d3b855c2",
            ic_lat: -231834,
            ic_lon: -506502,
            ic_city: "Cornélio Procópio",
            ic_states: "PARANÁ",
            ic_january: 5.944,
            ic_february: 5.881,
            ic_march: 5.286,
            ic_april: 4.627,
            ic_may: 3.68,
            ic_june: 3.399,
            ic_july: 3.595,
            ic_august: 4.575,
            ic_september: 4.751,
            ic_october: 5.397,
            ic_november: 6.063,
            ic_december: 6.352,
            ic_yearly: 4.962,
          },
          {
            ic_uuid: "0620f01e-c997-4ade-95e0-833a7de0132f",
            ic_lat: -18616,
            ic_lon: -422795,
            ic_city: "Coroaci",
            ic_states: "MINAS GERAIS",
            ic_january: 5.749,
            ic_february: 5.989,
            ic_march: 5.0,
            ic_april: 4.38,
            ic_may: 3.733,
            ic_june: 3.556,
            ic_july: 3.7,
            ic_august: 4.369,
            ic_september: 4.879,
            ic_october: 5.051,
            ic_november: 4.686,
            ic_december: 5.556,
            ic_yearly: 4.721,
          },
          {
            ic_uuid: "d0f36f4a-8f49-49c2-aafc-7ecfbc7881df",
            ic_lat: -213525,
            ic_lon: -502864,
            ic_city: "Coroados",
            ic_states: "SÃO PAULO",
            ic_january: 5.968,
            ic_february: 6.043,
            ic_march: 5.356,
            ic_april: 4.831,
            ic_may: 4.015,
            ic_june: 3.707,
            ic_july: 3.953,
            ic_august: 4.851,
            ic_september: 4.919,
            ic_october: 5.617,
            ic_november: 6.052,
            ic_december: 6.335,
            ic_yearly: 5.137,
          },
          {
            ic_uuid: "91f8045d-e580-4fcf-bf71-39192311902a",
            ic_lat: -41348,
            ic_lon: -441248,
            ic_city: "Coroatá",
            ic_states: "MARANHÃO",
            ic_january: 4.818,
            ic_february: 4.932,
            ic_march: 5.031,
            ic_april: 4.937,
            ic_may: 4.933,
            ic_june: 5.117,
            ic_july: 5.401,
            ic_august: 5.927,
            ic_september: 6.332,
            ic_october: 6.023,
            ic_november: 5.578,
            ic_december: 5.193,
            ic_yearly: 5.352,
          },
          {
            ic_uuid: "020597b0-1362-4a5f-99c9-ff7a991c79b5",
            ic_lat: -184738,
            ic_lon: -471937,
            ic_city: "Coromandel",
            ic_states: "MINAS GERAIS",
            ic_january: 5.654,
            ic_february: 5.995,
            ic_march: 5.174,
            ic_april: 5.041,
            ic_may: 4.708,
            ic_june: 4.399,
            ic_july: 4.757,
            ic_august: 5.635,
            ic_september: 5.668,
            ic_october: 5.753,
            ic_november: 5.449,
            ic_december: 5.732,
            ic_yearly: 5.33,
          },
          {
            ic_uuid: "164893c3-df08-418e-88bd-8e53aec88df9",
            ic_lat: -28383,
            ic_lon: -540659,
            ic_city: "Coronel Barros",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.508,
            ic_february: 6.042,
            ic_march: 5.192,
            ic_april: 4.015,
            ic_may: 3.053,
            ic_june: 2.509,
            ic_july: 2.798,
            ic_august: 3.575,
            ic_september: 3.978,
            ic_october: 5.112,
            ic_november: 6.285,
            ic_december: 6.702,
            ic_yearly: 4.647,
          },
          {
            ic_uuid: "500dc09b-a4be-46a9-8622-ca9fea83cfda",
            ic_lat: -277222,
            ic_lon: -537046,
            ic_city: "Coronel Bicaco",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.461,
            ic_february: 5.974,
            ic_march: 5.105,
            ic_april: 4.064,
            ic_may: 3.126,
            ic_june: 2.582,
            ic_july: 2.911,
            ic_august: 3.699,
            ic_september: 3.995,
            ic_october: 5.171,
            ic_november: 6.225,
            ic_december: 6.633,
            ic_yearly: 4.662,
          },
          {
            ic_uuid: "b0149c93-822e-49e4-9596-b49466859f88",
            ic_lat: -262282,
            ic_lon: -520361,
            ic_city: "Coronel Domingos Soares",
            ic_states: "PARANÁ",
            ic_january: 6.006,
            ic_february: 5.474,
            ic_march: 5.016,
            ic_april: 4.125,
            ic_may: 3.211,
            ic_june: 2.81,
            ic_july: 3.068,
            ic_august: 4.054,
            ic_september: 4.219,
            ic_october: 5.071,
            ic_november: 6.029,
            ic_december: 6.165,
            ic_yearly: 4.604,
          },
          {
            ic_uuid: "0fbc38bd-652d-4903-8de9-1f793fbd753c",
            ic_lat: -63859,
            ic_lon: -362175,
            ic_city: "Coronel Ezequiel",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.927,
            ic_february: 5.935,
            ic_march: 5.917,
            ic_april: 5.524,
            ic_may: 4.959,
            ic_june: 4.551,
            ic_july: 4.747,
            ic_august: 5.511,
            ic_september: 6.021,
            ic_october: 6.299,
            ic_november: 6.382,
            ic_december: 6.0,
            ic_yearly: 5.648,
          },
          {
            ic_uuid: "50c5540c-c110-46a0-b832-29876317cfb9",
            ic_lat: -195184,
            ic_lon: -426279,
            ic_city: "Coronel Fabriciano",
            ic_states: "MINAS GERAIS",
            ic_january: 5.514,
            ic_february: 5.881,
            ic_march: 4.862,
            ic_april: 4.449,
            ic_may: 3.764,
            ic_june: 3.632,
            ic_july: 3.795,
            ic_august: 4.462,
            ic_september: 4.797,
            ic_october: 4.956,
            ic_november: 4.586,
            ic_december: 5.394,
            ic_yearly: 4.675,
          },
          {
            ic_uuid: "ef642ed9-4d4f-4fc4-af8f-e9941c81ac5e",
            ic_lat: -269062,
            ic_lon: -527016,
            ic_city: "Coronel Freitas",
            ic_states: "SANTA CATARINA",
            ic_january: 6.248,
            ic_february: 5.773,
            ic_march: 5.196,
            ic_april: 4.131,
            ic_may: 3.183,
            ic_june: 2.679,
            ic_july: 2.948,
            ic_august: 3.843,
            ic_september: 4.073,
            ic_october: 5.177,
            ic_november: 6.194,
            ic_december: 6.465,
            ic_yearly: 4.659,
          },
          {
            ic_uuid: "4ffb1042-e84e-47d5-810f-b9fb3dcee733",
            ic_lat: -62501,
            ic_lon: -384445,
            ic_city: "Coronel João Pessoa",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.868,
            ic_february: 5.872,
            ic_march: 5.833,
            ic_april: 5.553,
            ic_may: 5.168,
            ic_june: 5.034,
            ic_july: 5.366,
            ic_august: 6.103,
            ic_september: 6.474,
            ic_october: 6.574,
            ic_november: 6.665,
            ic_december: 6.1,
            ic_yearly: 5.884,
          },
          {
            ic_uuid: "dc7615b5-c2d4-48b4-9b84-18601809bb09",
            ic_lat: -102843,
            ic_lon: -379254,
            ic_city: "Coronel João Sá",
            ic_states: "BAHIA",
            ic_january: 6.027,
            ic_february: 5.907,
            ic_march: 5.765,
            ic_april: 5.078,
            ic_may: 4.256,
            ic_june: 3.919,
            ic_july: 4.154,
            ic_august: 4.671,
            ic_september: 5.425,
            ic_october: 5.638,
            ic_november: 6.096,
            ic_december: 6.155,
            ic_yearly: 5.258,
          },
          {
            ic_uuid: "17867963-9539-45fd-b043-98cbb728e6bc",
            ic_lat: -88255,
            ic_lon: -42478,
            ic_city: "Coronel José Dias",
            ic_states: "PIAUÍ",
            ic_january: 5.878,
            ic_february: 5.696,
            ic_march: 5.568,
            ic_april: 5.342,
            ic_may: 5.23,
            ic_june: 5.209,
            ic_july: 5.652,
            ic_august: 6.424,
            ic_september: 6.661,
            ic_october: 6.547,
            ic_november: 6.2,
            ic_december: 5.971,
            ic_yearly: 5.865,
          },
          {
            ic_uuid: "3ad6bb4a-0ec6-496c-8236-af7515974b9a",
            ic_lat: -23631,
            ic_lon: -493139,
            ic_city: "Coronel Macedo",
            ic_states: "SÃO PAULO",
            ic_january: 5.709,
            ic_february: 5.761,
            ic_march: 5.124,
            ic_april: 4.476,
            ic_may: 3.552,
            ic_june: 3.204,
            ic_july: 3.407,
            ic_august: 4.396,
            ic_september: 4.574,
            ic_october: 5.198,
            ic_november: 5.808,
            ic_december: 6.224,
            ic_yearly: 4.786,
          },
          {
            ic_uuid: "643764b3-8dbd-4ec9-8b74-3a19159dbf08",
            ic_lat: -265115,
            ic_lon: -526698,
            ic_city: "Coronel Martins",
            ic_states: "SANTA CATARINA",
            ic_january: 6.149,
            ic_february: 5.662,
            ic_march: 5.086,
            ic_april: 4.149,
            ic_may: 3.224,
            ic_june: 2.741,
            ic_july: 3.046,
            ic_august: 3.952,
            ic_september: 4.156,
            ic_october: 5.116,
            ic_november: 6.091,
            ic_december: 6.294,
            ic_yearly: 4.639,
          },
          {
            ic_uuid: "a3fc2d5a-693d-4382-acb1-4e4cf0157ee2",
            ic_lat: -166153,
            ic_lon: -421843,
            ic_city: "Coronel Murta",
            ic_states: "MINAS GERAIS",
            ic_january: 6.14,
            ic_february: 6.327,
            ic_march: 5.396,
            ic_april: 4.773,
            ic_may: 4.12,
            ic_june: 3.817,
            ic_july: 4.056,
            ic_august: 4.775,
            ic_september: 5.459,
            ic_october: 5.634,
            ic_november: 5.256,
            ic_december: 6.036,
            ic_yearly: 5.149,
          },
          {
            ic_uuid: "26631ce9-60c2-4c2b-8684-1f039acd2aac",
            ic_lat: -215902,
            ic_lon: -432564,
            ic_city: "Coronel Pacheco",
            ic_states: "MINAS GERAIS",
            ic_january: 5.599,
            ic_february: 5.937,
            ic_march: 4.935,
            ic_april: 4.278,
            ic_may: 3.585,
            ic_june: 3.384,
            ic_july: 3.597,
            ic_august: 4.338,
            ic_september: 4.638,
            ic_october: 4.91,
            ic_november: 4.748,
            ic_december: 5.499,
            ic_yearly: 4.621,
          },
          {
            ic_uuid: "89d55d0e-9a74-4620-834f-bb97a63ed9b9",
            ic_lat: -2927,
            ic_lon: -516852,
            ic_city: "Coronel Pilar",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.175,
            ic_february: 5.715,
            ic_march: 4.809,
            ic_april: 3.812,
            ic_may: 2.813,
            ic_june: 2.358,
            ic_july: 2.654,
            ic_august: 3.369,
            ic_september: 3.618,
            ic_october: 4.731,
            ic_november: 6.143,
            ic_december: 6.509,
            ic_yearly: 4.392,
          },
          {
            ic_uuid: "08bf0860-ae60-4a99-836b-359755ed9b08",
            ic_lat: -232729,
            ic_lon: -555283,
            ic_city: "Coronel Sapucaia",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 6.041,
            ic_february: 5.638,
            ic_march: 5.277,
            ic_april: 4.361,
            ic_may: 3.451,
            ic_june: 3.133,
            ic_july: 3.268,
            ic_august: 4.233,
            ic_september: 4.6,
            ic_october: 5.316,
            ic_november: 5.887,
            ic_december: 6.494,
            ic_yearly: 4.808,
          },
          {
            ic_uuid: "5377e4a8-3a29-40bb-871c-4375c7e86d3b",
            ic_lat: -259772,
            ic_lon: -525645,
            ic_city: "Coronel Vivida",
            ic_states: "PARANÁ",
            ic_january: 6.255,
            ic_february: 5.64,
            ic_march: 5.212,
            ic_april: 4.223,
            ic_may: 3.281,
            ic_june: 2.854,
            ic_july: 3.101,
            ic_august: 4.076,
            ic_september: 4.24,
            ic_october: 5.199,
            ic_november: 6.122,
            ic_december: 6.304,
            ic_yearly: 4.709,
          },
          {
            ic_uuid: "23467f20-425f-44a0-b172-b7723cbc8239",
            ic_lat: -210282,
            ic_lon: -44221,
            ic_city: "Coronel Xavier Chaves",
            ic_states: "MINAS GERAIS",
            ic_january: 5.575,
            ic_february: 5.806,
            ic_march: 4.844,
            ic_april: 4.432,
            ic_may: 3.843,
            ic_june: 3.676,
            ic_july: 3.918,
            ic_august: 4.878,
            ic_september: 5.063,
            ic_october: 5.202,
            ic_november: 4.948,
            ic_december: 5.506,
            ic_yearly: 4.807,
          },
          {
            ic_uuid: "cba74a6e-0f49-4565-a001-2c7ee1d70645",
            ic_lat: -198246,
            ic_lon: -459045,
            ic_city: "Córrego Danta",
            ic_states: "MINAS GERAIS",
            ic_january: 5.661,
            ic_february: 5.935,
            ic_march: 4.91,
            ic_april: 4.889,
            ic_may: 4.406,
            ic_june: 4.213,
            ic_july: 4.483,
            ic_august: 5.494,
            ic_september: 5.471,
            ic_october: 5.407,
            ic_november: 5.046,
            ic_december: 5.403,
            ic_yearly: 5.11,
          },
          {
            ic_uuid: "97737740-0272-43ae-8bea-93e9a38aa8c7",
            ic_lat: -226274,
            ic_lon: -460245,
            ic_city: "Córrego do Bom Jesus",
            ic_states: "MINAS GERAIS",
            ic_january: 5.213,
            ic_february: 5.52,
            ic_march: 4.768,
            ic_april: 4.521,
            ic_may: 3.824,
            ic_june: 3.553,
            ic_july: 3.765,
            ic_august: 4.699,
            ic_september: 4.855,
            ic_october: 5.277,
            ic_november: 5.273,
            ic_december: 5.579,
            ic_yearly: 4.737,
          },
          {
            ic_uuid: "2f56eaaf-19e8-4066-abca-108215e1527d",
            ic_lat: -162954,
            ic_lon: -505525,
            ic_city: "Córrego do Ouro",
            ic_states: "GOIÁS",
            ic_january: 5.521,
            ic_february: 5.583,
            ic_march: 5.242,
            ic_april: 5.211,
            ic_may: 4.863,
            ic_june: 4.581,
            ic_july: 4.791,
            ic_august: 5.479,
            ic_september: 5.408,
            ic_october: 5.552,
            ic_november: 5.495,
            ic_december: 5.48,
            ic_yearly: 5.267,
          },
          {
            ic_uuid: "fac76970-5f70-4600-8c55-c1af5d3d156e",
            ic_lat: -204479,
            ic_lon: -455621,
            ic_city: "Córrego Fundo",
            ic_states: "MINAS GERAIS",
            ic_january: 5.715,
            ic_february: 5.898,
            ic_march: 5.021,
            ic_april: 4.828,
            ic_may: 4.269,
            ic_june: 4.02,
            ic_july: 4.312,
            ic_august: 5.187,
            ic_september: 5.361,
            ic_october: 5.49,
            ic_november: 5.201,
            ic_december: 5.665,
            ic_yearly: 5.081,
          },
          {
            ic_uuid: "b905a1de-1d3b-40dc-948b-abeba0417f2d",
            ic_lat: -198366,
            ic_lon: -423992,
            ic_city: "Córrego Novo",
            ic_states: "MINAS GERAIS",
            ic_january: 5.931,
            ic_february: 6.211,
            ic_march: 5.188,
            ic_april: 4.737,
            ic_may: 4.022,
            ic_june: 3.816,
            ic_july: 3.97,
            ic_august: 4.659,
            ic_september: 4.991,
            ic_october: 5.186,
            ic_november: 5.033,
            ic_december: 5.824,
            ic_yearly: 4.964,
          },
          {
            ic_uuid: "b610e718-2e87-49eb-8c69-54acc481af1b",
            ic_lat: -275882,
            ic_lon: -503619,
            ic_city: "Correia Pinto",
            ic_states: "SANTA CATARINA",
            ic_january: 5.673,
            ic_february: 5.394,
            ic_march: 4.634,
            ic_april: 3.718,
            ic_may: 2.868,
            ic_june: 2.457,
            ic_july: 2.673,
            ic_august: 3.477,
            ic_september: 3.664,
            ic_october: 4.392,
            ic_november: 5.619,
            ic_december: 5.969,
            ic_yearly: 4.212,
          },
          {
            ic_uuid: "d8af8620-d670-42ee-bd7e-07a8afb9ac28",
            ic_lat: -104338,
            ic_lon: -451637,
            ic_city: "Corrente",
            ic_states: "PIAUÍ",
            ic_january: 5.835,
            ic_february: 5.702,
            ic_march: 5.465,
            ic_april: 5.385,
            ic_may: 5.408,
            ic_june: 5.32,
            ic_july: 5.574,
            ic_august: 6.222,
            ic_september: 6.355,
            ic_october: 6.127,
            ic_november: 5.866,
            ic_december: 5.963,
            ic_yearly: 5.768,
          },
          {
            ic_uuid: "c41319a5-1ae8-4013-8ec5-86938a0bd291",
            ic_lat: -91216,
            ic_lon: -363247,
            ic_city: "Correntes",
            ic_states: "PERNAMBUCO",
            ic_january: 6.004,
            ic_february: 5.835,
            ic_march: 5.86,
            ic_april: 5.191,
            ic_may: 4.241,
            ic_june: 3.751,
            ic_july: 3.827,
            ic_august: 4.509,
            ic_september: 5.401,
            ic_october: 5.748,
            ic_november: 6.32,
            ic_december: 6.245,
            ic_yearly: 5.244,
          },
          {
            ic_uuid: "818e59ef-c39a-4c8a-b958-9a803f0651d1",
            ic_lat: -133432,
            ic_lon: -446349,
            ic_city: "Correntina",
            ic_states: "BAHIA",
            ic_january: 6.446,
            ic_february: 6.34,
            ic_march: 5.797,
            ic_april: 5.647,
            ic_may: 5.252,
            ic_june: 5.033,
            ic_july: 5.435,
            ic_august: 5.934,
            ic_september: 6.187,
            ic_october: 6.221,
            ic_november: 5.651,
            ic_december: 6.245,
            ic_yearly: 5.849,
          },
          {
            ic_uuid: "90db7479-c8c7-4666-912a-3c00d80414f2",
            ic_lat: -84749,
            ic_lon: -355471,
            ic_city: "Cortês",
            ic_states: "PERNAMBUCO",
            ic_january: 5.565,
            ic_february: 5.49,
            ic_march: 5.602,
            ic_april: 4.901,
            ic_may: 4.158,
            ic_june: 3.773,
            ic_july: 3.8,
            ic_august: 4.39,
            ic_september: 5.08,
            ic_october: 5.364,
            ic_november: 5.853,
            ic_december: 5.835,
            ic_yearly: 4.985,
          },
          {
            ic_uuid: "dbde9914-329b-4354-aab8-d71722afdfcc",
            ic_lat: -190082,
            ic_lon: -576515,
            ic_city: "Corumbá",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.726,
            ic_february: 5.612,
            ic_march: 5.274,
            ic_april: 4.932,
            ic_may: 3.846,
            ic_june: 3.648,
            ic_july: 3.847,
            ic_august: 4.629,
            ic_september: 4.795,
            ic_october: 5.452,
            ic_november: 5.758,
            ic_december: 6.137,
            ic_yearly: 4.971,
          },
          {
            ic_uuid: "4b119f9e-a30d-492f-8f22-d254288d7846",
            ic_lat: -159283,
            ic_lon: -48809,
            ic_city: "Corumbá de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.298,
            ic_february: 5.413,
            ic_march: 5.019,
            ic_april: 5.004,
            ic_may: 4.86,
            ic_june: 4.607,
            ic_july: 4.929,
            ic_august: 5.813,
            ic_september: 5.525,
            ic_october: 5.47,
            ic_november: 5.093,
            ic_december: 5.235,
            ic_yearly: 5.189,
          },
          {
            ic_uuid: "cdddd406-25a9-4724-9b3a-1c54817f6d0d",
            ic_lat: -18142,
            ic_lon: -48562,
            ic_city: "Corumbaíba",
            ic_states: "GOIÁS",
            ic_january: 5.578,
            ic_february: 5.805,
            ic_march: 5.262,
            ic_april: 5.156,
            ic_may: 4.715,
            ic_june: 4.423,
            ic_july: 4.641,
            ic_august: 5.515,
            ic_september: 5.487,
            ic_october: 5.637,
            ic_november: 5.62,
            ic_december: 5.75,
            ic_yearly: 5.299,
          },
          {
            ic_uuid: "4ab6c13c-6ba5-4288-b258-40f74f98f1ae",
            ic_lat: -222218,
            ic_lon: -476219,
            ic_city: "Corumbataí",
            ic_states: "SÃO PAULO",
            ic_january: 5.455,
            ic_february: 5.708,
            ic_march: 5.04,
            ic_april: 4.66,
            ic_may: 3.862,
            ic_june: 3.63,
            ic_july: 3.777,
            ic_august: 4.798,
            ic_september: 4.873,
            ic_october: 5.441,
            ic_november: 5.606,
            ic_december: 5.864,
            ic_yearly: 4.893,
          },
          {
            ic_uuid: "79d5ae43-5780-4990-b586-87bdd5ae0851",
            ic_lat: -241014,
            ic_lon: -521181,
            ic_city: "Corumbataí do Sul",
            ic_states: "PARANÁ",
            ic_january: 6.067,
            ic_february: 5.742,
            ic_march: 5.272,
            ic_april: 4.546,
            ic_may: 3.567,
            ic_june: 3.183,
            ic_july: 3.398,
            ic_august: 4.37,
            ic_september: 4.58,
            ic_october: 5.297,
            ic_november: 6.147,
            ic_december: 6.433,
            ic_yearly: 4.884,
          },
          {
            ic_uuid: "e5979450-eb06-4b57-a24e-a7bbc04263f8",
            ic_lat: -129982,
            ic_lon: -609421,
            ic_city: "Corumbiara",
            ic_states: "RONDÔNIA",
            ic_january: 4.672,
            ic_february: 4.59,
            ic_march: 4.566,
            ic_april: 4.583,
            ic_may: 4.205,
            ic_june: 4.374,
            ic_july: 4.512,
            ic_august: 5.029,
            ic_september: 4.94,
            ic_october: 5.193,
            ic_november: 5.122,
            ic_december: 4.948,
            ic_yearly: 4.728,
          },
          {
            ic_uuid: "7827eba1-a52f-4efc-81af-73f5a50aafd6",
            ic_lat: -264251,
            ic_lon: -492464,
            ic_city: "Corupá",
            ic_states: "SANTA CATARINA",
            ic_january: 5.171,
            ic_february: 4.949,
            ic_march: 4.408,
            ic_april: 3.605,
            ic_may: 2.955,
            ic_june: 2.507,
            ic_july: 2.679,
            ic_august: 3.469,
            ic_september: 3.405,
            ic_october: 3.963,
            ic_november: 4.962,
            ic_december: 5.311,
            ic_yearly: 3.948,
          },
          {
            ic_uuid: "27620c66-99a0-422c-a883-2d2606b8ee9c",
            ic_lat: -101281,
            ic_lon: -36172,
            ic_city: "Coruripe",
            ic_states: "ALAGOAS",
            ic_january: 6.126,
            ic_february: 6.183,
            ic_march: 5.996,
            ic_april: 5.152,
            ic_may: 4.44,
            ic_june: 4.127,
            ic_july: 4.222,
            ic_august: 4.905,
            ic_september: 5.577,
            ic_october: 6.021,
            ic_november: 6.344,
            ic_december: 6.454,
            ic_yearly: 5.462,
          },
          {
            ic_uuid: "7285d402-2168-4879-8789-50c7a2f8ed92",
            ic_lat: -226424,
            ic_lon: -47193,
            ic_city: "Cosmópolis",
            ic_states: "SÃO PAULO",
            ic_january: 5.575,
            ic_february: 5.823,
            ic_march: 5.148,
            ic_april: 4.652,
            ic_may: 3.859,
            ic_june: 3.58,
            ic_july: 3.757,
            ic_august: 4.72,
            ic_september: 4.888,
            ic_october: 5.504,
            ic_november: 5.715,
            ic_december: 6.159,
            ic_yearly: 4.948,
          },
          {
            ic_uuid: "5d2335ea-00e1-4ce8-95f8-84b93bcb5995",
            ic_lat: -20478,
            ic_lon: -497779,
            ic_city: "Cosmorama",
            ic_states: "SÃO PAULO",
            ic_january: 5.847,
            ic_february: 5.885,
            ic_march: 5.276,
            ic_april: 4.929,
            ic_may: 4.193,
            ic_june: 3.932,
            ic_july: 4.17,
            ic_august: 5.069,
            ic_september: 5.092,
            ic_october: 5.611,
            ic_november: 5.933,
            ic_december: 6.142,
            ic_yearly: 5.173,
          },
          {
            ic_uuid: "d7415956-2181-4e0b-8632-e4b79dd38fd7",
            ic_lat: -124371,
            ic_lon: -642284,
            ic_city: "Costa Marques",
            ic_states: "RONDÔNIA",
            ic_january: 4.665,
            ic_february: 4.537,
            ic_march: 4.485,
            ic_april: 4.556,
            ic_may: 3.92,
            ic_june: 4.164,
            ic_july: 4.372,
            ic_august: 4.886,
            ic_september: 4.934,
            ic_october: 5.128,
            ic_november: 5.096,
            ic_december: 4.936,
            ic_yearly: 4.64,
          },
          {
            ic_uuid: "182061be-a5af-4625-a288-e1d391267aa8",
            ic_lat: -185437,
            ic_lon: -531292,
            ic_city: "Costa Rica",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.558,
            ic_february: 5.609,
            ic_march: 5.188,
            ic_april: 4.915,
            ic_may: 4.405,
            ic_june: 4.177,
            ic_july: 4.356,
            ic_august: 5.203,
            ic_september: 5.139,
            ic_october: 5.349,
            ic_november: 5.551,
            ic_december: 5.738,
            ic_yearly: 5.099,
          },
          {
            ic_uuid: "9841a73f-71fc-4233-b605-eaa90d53a8cb",
            ic_lat: -120292,
            ic_lon: -442612,
            ic_city: "Cotegipe",
            ic_states: "BAHIA",
            ic_january: 6.494,
            ic_february: 6.213,
            ic_march: 5.834,
            ic_april: 5.641,
            ic_may: 5.323,
            ic_june: 5.108,
            ic_july: 5.396,
            ic_august: 5.974,
            ic_september: 6.196,
            ic_october: 6.226,
            ic_november: 5.995,
            ic_december: 6.369,
            ic_yearly: 5.897,
          },
          {
            ic_uuid: "a329f0b0-ab31-4b6f-bedf-f617cd73f5a6",
            ic_lat: -236027,
            ic_lon: -469194,
            ic_city: "Cotia",
            ic_states: "SÃO PAULO",
            ic_january: 5.338,
            ic_february: 5.547,
            ic_march: 4.799,
            ic_april: 4.228,
            ic_may: 3.441,
            ic_june: 3.193,
            ic_july: 3.276,
            ic_august: 4.248,
            ic_september: 4.255,
            ic_october: 4.769,
            ic_november: 5.235,
            ic_december: 5.764,
            ic_yearly: 4.508,
          },
          {
            ic_uuid: "5fa28098-97df-4cec-b34e-faa6c04fab80",
            ic_lat: -289896,
            ic_lon: -516975,
            ic_city: "Cotiporã",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.197,
            ic_february: 5.752,
            ic_march: 4.868,
            ic_april: 3.848,
            ic_may: 2.857,
            ic_june: 2.378,
            ic_july: 2.698,
            ic_august: 3.419,
            ic_september: 3.678,
            ic_october: 4.754,
            ic_november: 6.107,
            ic_december: 6.502,
            ic_yearly: 4.421,
          },
          {
            ic_uuid: "1e1461d0-08af-4343-9f8b-d2d647f149ce",
            ic_lat: -99006,
            ic_lon: -585719,
            ic_city: "Cotriguaçu",
            ic_states: "MATO GROSSO",
            ic_january: 4.378,
            ic_february: 4.467,
            ic_march: 4.46,
            ic_april: 4.397,
            ic_may: 4.214,
            ic_june: 4.609,
            ic_july: 4.688,
            ic_august: 5.137,
            ic_september: 4.921,
            ic_october: 5.127,
            ic_november: 4.824,
            ic_december: 4.724,
            ic_yearly: 4.662,
          },
          {
            ic_uuid: "99a5f5e4-9863-478d-a2eb-417a829799dc",
            ic_lat: -180732,
            ic_lon: -434652,
            ic_city: "Couto de Magalhães de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.931,
            ic_february: 6.199,
            ic_march: 5.143,
            ic_april: 4.738,
            ic_may: 4.199,
            ic_june: 4.067,
            ic_july: 4.337,
            ic_august: 5.091,
            ic_september: 5.544,
            ic_october: 5.497,
            ic_november: 4.935,
            ic_december: 5.659,
            ic_yearly: 5.112,
          },
          {
            ic_uuid: "f754437a-0039-485f-b5f3-b6e599a635eb",
            ic_lat: -82832,
            ic_lon: -49247,
            ic_city: "Couto Magalhães",
            ic_states: "TOCANTINS",
            ic_january: 4.792,
            ic_february: 4.927,
            ic_march: 4.815,
            ic_april: 4.723,
            ic_may: 4.915,
            ic_june: 5.129,
            ic_july: 5.262,
            ic_august: 5.89,
            ic_september: 5.658,
            ic_october: 5.15,
            ic_november: 4.888,
            ic_december: 4.79,
            ic_yearly: 5.079,
          },
          {
            ic_uuid: "ba2432b4-15cb-4384-9f55-7765ba333f45",
            ic_lat: -281284,
            ic_lon: -523028,
            ic_city: "Coxilha",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.243,
            ic_february: 5.829,
            ic_march: 4.996,
            ic_april: 4.038,
            ic_may: 3.034,
            ic_june: 2.515,
            ic_july: 2.882,
            ic_august: 3.637,
            ic_september: 3.873,
            ic_october: 5.029,
            ic_november: 6.199,
            ic_december: 6.494,
            ic_yearly: 4.564,
          },
          {
            ic_uuid: "5d7563d5-2b7a-4a07-8559-fd13b21a9d46",
            ic_lat: -185017,
            ic_lon: -547514,
            ic_city: "Coxim",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.592,
            ic_february: 5.722,
            ic_march: 5.457,
            ic_april: 4.972,
            ic_may: 4.271,
            ic_june: 4.137,
            ic_july: 4.225,
            ic_august: 5.114,
            ic_september: 5.128,
            ic_october: 5.536,
            ic_november: 5.84,
            ic_december: 6.005,
            ic_yearly: 5.166,
          },
          {
            ic_uuid: "527a059a-0533-42e2-b8ee-23385b384e11",
            ic_lat: -76275,
            ic_lon: -366058,
            ic_city: "Coxixola",
            ic_states: "PARAÍBA",
            ic_january: 5.954,
            ic_february: 5.911,
            ic_march: 5.989,
            ic_april: 5.549,
            ic_may: 4.88,
            ic_june: 4.358,
            ic_july: 4.523,
            ic_august: 5.231,
            ic_september: 6.006,
            ic_october: 6.256,
            ic_november: 6.396,
            ic_december: 6.135,
            ic_yearly: 5.599,
          },
          {
            ic_uuid: "35ed34aa-e309-4054-9c12-6d2856192dbd",
            ic_lat: -96182,
            ic_lon: -3677,
            ic_city: "Craíbas",
            ic_states: "ALAGOAS",
            ic_january: 6.118,
            ic_february: 5.849,
            ic_march: 5.973,
            ic_april: 5.235,
            ic_may: 4.316,
            ic_june: 3.982,
            ic_july: 4.14,
            ic_august: 4.728,
            ic_september: 5.589,
            ic_october: 5.913,
            ic_november: 6.423,
            ic_december: 6.396,
            ic_yearly: 5.389,
          },
          {
            ic_uuid: "a0ff553f-0244-4a35-a68b-75bbb7c499bd",
            ic_lat: -51681,
            ic_lon: -40654,
            ic_city: "Crateús",
            ic_states: "CEARÁ",
            ic_january: 5.097,
            ic_february: 5.313,
            ic_march: 5.43,
            ic_april: 5.003,
            ic_may: 4.943,
            ic_june: 4.978,
            ic_july: 5.374,
            ic_august: 6.076,
            ic_september: 6.514,
            ic_october: 6.424,
            ic_november: 6.3,
            ic_december: 5.616,
            ic_yearly: 5.589,
          },
          {
            ic_uuid: "46abc6f7-df31-4542-84e2-7755004203db",
            ic_lat: -7229,
            ic_lon: -394102,
            ic_city: "Crato",
            ic_states: "CEARÁ",
            ic_january: 5.828,
            ic_february: 5.757,
            ic_march: 5.678,
            ic_april: 5.459,
            ic_may: 5.146,
            ic_june: 4.974,
            ic_july: 5.33,
            ic_august: 6.123,
            ic_september: 6.587,
            ic_october: 6.556,
            ic_november: 6.545,
            ic_december: 6.191,
            ic_yearly: 5.848,
          },
          {
            ic_uuid: "5abbe76e-c326-4830-bb93-5153aac52b05",
            ic_lat: -213384,
            ic_lon: -477328,
            ic_city: "Cravinhos",
            ic_states: "SÃO PAULO",
            ic_january: 5.584,
            ic_february: 5.893,
            ic_march: 5.089,
            ic_april: 4.774,
            ic_may: 4.061,
            ic_june: 3.902,
            ic_july: 4.071,
            ic_august: 4.911,
            ic_september: 4.99,
            ic_october: 5.493,
            ic_november: 5.71,
            ic_december: 6.012,
            ic_yearly: 5.041,
          },
          {
            ic_uuid: "1adac5da-07d1-4385-944d-ace8028832d0",
            ic_lat: -133556,
            ic_lon: -39813,
            ic_city: "Cravolândia",
            ic_states: "BAHIA",
            ic_january: 5.73,
            ic_february: 5.787,
            ic_march: 5.472,
            ic_april: 4.453,
            ic_may: 3.862,
            ic_june: 3.496,
            ic_july: 3.728,
            ic_august: 4.269,
            ic_september: 5.102,
            ic_october: 5.213,
            ic_november: 5.305,
            ic_december: 5.871,
            ic_yearly: 4.857,
          },
          {
            ic_uuid: "f8b7748c-5931-40dc-acfb-2df665e46f55",
            ic_lat: -286728,
            ic_lon: -493733,
            ic_city: "Criciúma",
            ic_states: "SANTA CATARINA",
            ic_january: 5.659,
            ic_february: 5.234,
            ic_march: 4.498,
            ic_april: 3.788,
            ic_may: 2.98,
            ic_june: 2.491,
            ic_july: 2.704,
            ic_august: 3.425,
            ic_september: 3.538,
            ic_october: 4.32,
            ic_november: 5.647,
            ic_december: 5.914,
            ic_yearly: 4.183,
          },
          {
            ic_uuid: "9f347d75-980b-4b7b-9b8e-a86a5e2de244",
            ic_lat: -172386,
            ic_lon: -409187,
            ic_city: "Crisólita",
            ic_states: "MINAS GERAIS",
            ic_january: 6.198,
            ic_february: 6.395,
            ic_march: 5.441,
            ic_april: 4.687,
            ic_may: 3.883,
            ic_june: 3.519,
            ic_july: 3.697,
            ic_august: 4.359,
            ic_september: 5.021,
            ic_october: 5.289,
            ic_november: 5.199,
            ic_december: 6.168,
            ic_yearly: 4.988,
          },
          {
            ic_uuid: "b31ece89-e667-45dc-973c-d955e6ef1a2c",
            ic_lat: -115064,
            ic_lon: -381518,
            ic_city: "Crisópolis",
            ic_states: "BAHIA",
            ic_january: 6.091,
            ic_february: 5.918,
            ic_march: 5.724,
            ic_april: 4.867,
            ic_may: 4.129,
            ic_june: 3.94,
            ic_july: 4.06,
            ic_august: 4.561,
            ic_september: 5.279,
            ic_october: 5.573,
            ic_november: 6.02,
            ic_december: 6.11,
            ic_yearly: 5.189,
          },
          {
            ic_uuid: "b0495e0b-5bff-4985-93fb-c75e76ddd634",
            ic_lat: -275004,
            ic_lon: -540998,
            ic_city: "Crissiumal",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.446,
            ic_february: 5.965,
            ic_march: 5.13,
            ic_april: 4.039,
            ic_may: 3.114,
            ic_june: 2.546,
            ic_july: 2.887,
            ic_august: 3.691,
            ic_september: 4.001,
            ic_october: 5.241,
            ic_november: 6.234,
            ic_december: 6.652,
            ic_yearly: 4.662,
          },
          {
            ic_uuid: "fe3cc441-5090-44e1-9b32-5e5f8a56acf8",
            ic_lat: -208738,
            ic_lon: -455171,
            ic_city: "Cristais",
            ic_states: "MINAS GERAIS",
            ic_january: 5.645,
            ic_february: 5.865,
            ic_march: 4.994,
            ic_april: 4.788,
            ic_may: 4.121,
            ic_june: 3.864,
            ic_july: 4.145,
            ic_august: 5.017,
            ic_september: 5.186,
            ic_october: 5.467,
            ic_november: 5.226,
            ic_december: 5.675,
            ic_yearly: 4.999,
          },
          {
            ic_uuid: "ee1454f4-c90c-45da-9825-e51d12bbc3f6",
            ic_lat: -20404,
            ic_lon: -474213,
            ic_city: "Cristais Paulista",
            ic_states: "SÃO PAULO",
            ic_january: 5.562,
            ic_february: 5.821,
            ic_march: 5.027,
            ic_april: 4.901,
            ic_may: 4.286,
            ic_june: 4.119,
            ic_july: 4.347,
            ic_august: 5.239,
            ic_september: 5.176,
            ic_october: 5.492,
            ic_november: 5.563,
            ic_december: 5.811,
            ic_yearly: 5.112,
          },
          {
            ic_uuid: "c69bf835-3e1e-40c7-ad53-2cc7e99bb11e",
            ic_lat: -310041,
            ic_lon: -520462,
            ic_city: "Cristal",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.285,
            ic_february: 5.664,
            ic_march: 4.762,
            ic_april: 3.705,
            ic_may: 2.707,
            ic_june: 2.265,
            ic_july: 2.447,
            ic_august: 3.085,
            ic_september: 3.563,
            ic_october: 4.721,
            ic_november: 6.08,
            ic_december: 6.615,
            ic_yearly: 4.325,
          },
          {
            ic_uuid: "97bfbf22-f8c3-4a93-b30f-57e564baa72f",
            ic_lat: -274525,
            ic_lon: -532427,
            ic_city: "Cristal do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.329,
            ic_february: 5.908,
            ic_march: 5.135,
            ic_april: 4.104,
            ic_may: 3.115,
            ic_june: 2.541,
            ic_july: 2.907,
            ic_august: 3.72,
            ic_september: 3.984,
            ic_october: 5.179,
            ic_november: 6.279,
            ic_december: 6.638,
            ic_yearly: 4.653,
          },
          {
            ic_uuid: "61be1362-0a2c-4875-9ec5-b2a4187fb909",
            ic_lat: -105989,
            ic_lon: -491946,
            ic_city: "Cristalândia",
            ic_states: "TOCANTINS",
            ic_january: 5.022,
            ic_february: 5.028,
            ic_march: 4.777,
            ic_april: 4.857,
            ic_may: 4.93,
            ic_june: 5.117,
            ic_july: 5.302,
            ic_august: 5.981,
            ic_september: 5.68,
            ic_october: 5.357,
            ic_november: 5.099,
            ic_december: 5.012,
            ic_yearly: 5.18,
          },
          {
            ic_uuid: "48ba7ca0-a31d-4ed5-957b-444e741655e1",
            ic_lat: -106447,
            ic_lon: -451896,
            ic_city: "Cristalândia do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.957,
            ic_february: 5.746,
            ic_march: 5.547,
            ic_april: 5.418,
            ic_may: 5.38,
            ic_june: 5.317,
            ic_july: 5.562,
            ic_august: 6.262,
            ic_september: 6.393,
            ic_october: 6.105,
            ic_november: 5.812,
            ic_december: 6.02,
            ic_yearly: 5.793,
          },
          {
            ic_uuid: "0de2c0ab-1faf-4963-86c3-3620e85c6dd7",
            ic_lat: -167165,
            ic_lon: -428575,
            ic_city: "Cristália",
            ic_states: "MINAS GERAIS",
            ic_january: 6.076,
            ic_february: 6.348,
            ic_march: 5.327,
            ic_april: 4.718,
            ic_may: 4.156,
            ic_june: 3.903,
            ic_july: 4.149,
            ic_august: 5.018,
            ic_september: 5.537,
            ic_october: 5.682,
            ic_november: 5.162,
            ic_december: 5.919,
            ic_yearly: 5.166,
          },
          {
            ic_uuid: "829ae78c-99fd-4e7b-8583-5e3f9b5e7be6",
            ic_lat: -167681,
            ic_lon: -476135,
            ic_city: "Cristalina",
            ic_states: "GOIÁS",
            ic_january: 5.654,
            ic_february: 5.836,
            ic_march: 5.129,
            ic_april: 5.066,
            ic_may: 4.737,
            ic_june: 4.592,
            ic_july: 4.893,
            ic_august: 5.739,
            ic_september: 5.595,
            ic_october: 5.66,
            ic_november: 5.197,
            ic_december: 5.572,
            ic_yearly: 5.306,
          },
          {
            ic_uuid: "75cc8f9d-4a33-4810-957d-e0639bf85ade",
            ic_lat: -208329,
            ic_lon: -438169,
            ic_city: "Cristiano Otoni",
            ic_states: "MINAS GERAIS",
            ic_january: 5.633,
            ic_february: 5.914,
            ic_march: 4.898,
            ic_april: 4.469,
            ic_may: 3.857,
            ic_june: 3.691,
            ic_july: 3.889,
            ic_august: 4.821,
            ic_september: 5.041,
            ic_october: 5.225,
            ic_november: 4.963,
            ic_december: 5.568,
            ic_yearly: 4.831,
          },
          {
            ic_uuid: "26b5e023-3d54-49e4-98c5-ef27015d72da",
            ic_lat: -171991,
            ic_lon: -487038,
            ic_city: "Cristianópolis",
            ic_states: "GOIÁS",
            ic_january: 5.629,
            ic_february: 5.733,
            ic_march: 5.18,
            ic_april: 5.153,
            ic_may: 4.739,
            ic_june: 4.563,
            ic_july: 4.788,
            ic_august: 5.632,
            ic_september: 5.512,
            ic_october: 5.645,
            ic_november: 5.402,
            ic_december: 5.583,
            ic_yearly: 5.296,
          },
          {
            ic_uuid: "503f1b32-8400-4a3a-aae5-d700eb1b9f56",
            ic_lat: -222085,
            ic_lon: -452677,
            ic_city: "Cristina",
            ic_states: "MINAS GERAIS",
            ic_january: 5.317,
            ic_february: 5.594,
            ic_march: 4.824,
            ic_april: 4.49,
            ic_may: 3.868,
            ic_june: 3.605,
            ic_july: 3.842,
            ic_august: 4.738,
            ic_september: 4.957,
            ic_october: 5.331,
            ic_november: 5.186,
            ic_december: 5.524,
            ic_yearly: 4.773,
          },
          {
            ic_uuid: "e0022623-111b-4111-97e9-3494136ed2a6",
            ic_lat: -114603,
            ic_lon: -377634,
            ic_city: "Cristinápolis",
            ic_states: "SERGIPE",
            ic_january: 6.223,
            ic_february: 6.061,
            ic_march: 5.845,
            ic_april: 4.906,
            ic_may: 4.133,
            ic_june: 3.923,
            ic_july: 4.056,
            ic_august: 4.53,
            ic_september: 5.314,
            ic_october: 5.655,
            ic_november: 6.063,
            ic_december: 6.279,
            ic_yearly: 5.249,
          },
          {
            ic_uuid: "5a44f151-74ec-471f-8f78-75342274792a",
            ic_lat: -88232,
            ic_lon: -442233,
            ic_city: "Cristino Castro",
            ic_states: "PIAUÍ",
            ic_january: 5.622,
            ic_february: 5.414,
            ic_march: 5.367,
            ic_april: 5.396,
            ic_may: 5.386,
            ic_june: 5.283,
            ic_july: 5.663,
            ic_august: 6.37,
            ic_september: 6.668,
            ic_october: 6.144,
            ic_november: 5.757,
            ic_december: 5.654,
            ic_yearly: 5.727,
          },
          {
            ic_uuid: "a73cf4a7-db70-4b10-b851-4e69150ab4ca",
            ic_lat: -122365,
            ic_lon: -444193,
            ic_city: "Cristópolis",
            ic_states: "BAHIA",
            ic_january: 6.293,
            ic_february: 6.047,
            ic_march: 5.632,
            ic_april: 5.47,
            ic_may: 5.294,
            ic_june: 5.154,
            ic_july: 5.502,
            ic_august: 6.092,
            ic_september: 6.33,
            ic_october: 6.216,
            ic_november: 5.794,
            ic_december: 6.166,
            ic_yearly: 5.832,
          },
          {
            ic_uuid: "84ebe82b-5fe6-4f49-a83d-a395058370c7",
            ic_lat: -145405,
            ic_lon: -499756,
            ic_city: "Crixás",
            ic_states: "GOIÁS",
            ic_january: 5.4,
            ic_february: 5.603,
            ic_march: 5.231,
            ic_april: 5.228,
            ic_may: 4.96,
            ic_june: 4.689,
            ic_july: 4.966,
            ic_august: 5.727,
            ic_september: 5.564,
            ic_october: 5.621,
            ic_november: 5.281,
            ic_december: 5.419,
            ic_yearly: 5.308,
          },
          {
            ic_uuid: "2fe99ee1-27a3-47e6-a389-6824aa3dfff6",
            ic_lat: -111049,
            ic_lon: -48919,
            ic_city: "Crixás do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 5.189,
            ic_february: 5.264,
            ic_march: 4.937,
            ic_april: 4.997,
            ic_may: 5.038,
            ic_june: 5.015,
            ic_july: 5.372,
            ic_august: 5.965,
            ic_september: 5.664,
            ic_october: 5.398,
            ic_november: 5.241,
            ic_december: 5.217,
            ic_yearly: 5.275,
          },
          {
            ic_uuid: "a0d5278a-0f8e-4944-ad91-b32c34f8d3b4",
            ic_lat: -44126,
            ic_lon: -409028,
            ic_city: "Croatá",
            ic_states: "CEARÁ",
            ic_january: 5.373,
            ic_february: 5.442,
            ic_march: 5.488,
            ic_april: 5.061,
            ic_may: 5.085,
            ic_june: 5.102,
            ic_july: 5.47,
            ic_august: 6.201,
            ic_september: 6.667,
            ic_october: 6.607,
            ic_november: 6.522,
            ic_december: 5.828,
            ic_yearly: 5.737,
          },
          {
            ic_uuid: "d3e81ddd-4cb5-4b2f-8aa9-a1dc2dd2841d",
            ic_lat: -172888,
            ic_lon: -493802,
            ic_city: "Cromínia",
            ic_states: "GOIÁS",
            ic_january: 5.616,
            ic_february: 5.7,
            ic_march: 5.265,
            ic_april: 5.145,
            ic_may: 4.738,
            ic_june: 4.446,
            ic_july: 4.685,
            ic_august: 5.595,
            ic_september: 5.432,
            ic_october: 5.577,
            ic_november: 5.466,
            ic_december: 5.647,
            ic_yearly: 5.276,
          },
          {
            ic_uuid: "4c47dc44-5b91-4a11-bd15-37a9f71c2bed",
            ic_lat: -203928,
            ic_lon: -443338,
            ic_city: "Crucilândia",
            ic_states: "MINAS GERAIS",
            ic_january: 5.646,
            ic_february: 5.843,
            ic_march: 5.006,
            ic_april: 4.685,
            ic_may: 4.16,
            ic_june: 3.995,
            ic_july: 4.273,
            ic_august: 5.111,
            ic_september: 5.356,
            ic_october: 5.45,
            ic_november: 5.138,
            ic_december: 5.499,
            ic_yearly: 5.014,
          },
          {
            ic_uuid: "3cce814c-b92a-4f3f-a270-6b4adbd82d22",
            ic_lat: -29185,
            ic_lon: -401763,
            ic_city: "Cruz",
            ic_states: "CEARÁ",
            ic_january: 5.494,
            ic_february: 5.4,
            ic_march: 5.15,
            ic_april: 4.576,
            ic_may: 5.12,
            ic_june: 5.221,
            ic_july: 5.48,
            ic_august: 5.988,
            ic_september: 6.139,
            ic_october: 6.073,
            ic_november: 6.331,
            ic_december: 5.954,
            ic_yearly: 5.577,
          },
          {
            ic_uuid: "7d66f6d4-e0cb-4287-8980-1a573c752c3f",
            ic_lat: -286455,
            ic_lon: -536053,
            ic_city: "Cruz Alta",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.384,
            ic_february: 6.023,
            ic_march: 5.103,
            ic_april: 3.991,
            ic_may: 3.036,
            ic_june: 2.481,
            ic_july: 2.794,
            ic_august: 3.556,
            ic_september: 3.919,
            ic_october: 5.03,
            ic_november: 6.224,
            ic_december: 6.669,
            ic_yearly: 4.601,
          },
          {
            ic_uuid: "c698c2ad-fcf2-42ed-961a-3c9fac56d19c",
            ic_lat: -12668,
            ic_lon: -391011,
            ic_city: "Cruz das Almas",
            ic_states: "BAHIA",
            ic_january: 6.067,
            ic_february: 5.89,
            ic_march: 5.673,
            ic_april: 4.619,
            ic_may: 3.988,
            ic_june: 3.649,
            ic_july: 3.859,
            ic_august: 4.416,
            ic_september: 5.24,
            ic_october: 5.481,
            ic_november: 5.698,
            ic_december: 6.023,
            ic_yearly: 5.05,
          },
          {
            ic_uuid: "9a95e834-cba9-4281-86f9-749e5858a025",
            ic_lat: -71394,
            ic_lon: -35086,
            ic_city: "Cruz do Espírito Santo",
            ic_states: "PARAÍBA",
            ic_january: 5.749,
            ic_february: 5.903,
            ic_march: 5.968,
            ic_april: 5.302,
            ic_may: 4.686,
            ic_june: 4.208,
            ic_july: 4.305,
            ic_august: 5.146,
            ic_september: 5.564,
            ic_october: 5.818,
            ic_november: 6.093,
            ic_december: 5.997,
            ic_yearly: 5.395,
          },
          {
            ic_uuid: "8d516e16-af90-45b9-a97d-405660999592",
            ic_lat: -260171,
            ic_lon: -513434,
            ic_city: "Cruz Machado",
            ic_states: "PARANÁ",
            ic_january: 5.58,
            ic_february: 5.193,
            ic_march: 4.743,
            ic_april: 3.766,
            ic_may: 2.984,
            ic_june: 2.559,
            ic_july: 2.793,
            ic_august: 3.827,
            ic_september: 3.981,
            ic_october: 4.693,
            ic_november: 5.658,
            ic_december: 5.828,
            ic_yearly: 4.3,
          },
          {
            ic_uuid: "6ca8991e-56dd-4062-b2df-b7dedbf5de6e",
            ic_lat: -227423,
            ic_lon: -507928,
            ic_city: "Cruzália",
            ic_states: "SÃO PAULO",
            ic_january: 5.922,
            ic_february: 5.791,
            ic_march: 5.22,
            ic_april: 4.676,
            ic_may: 3.802,
            ic_june: 3.451,
            ic_july: 3.684,
            ic_august: 4.619,
            ic_september: 4.757,
            ic_october: 5.39,
            ic_november: 5.955,
            ic_december: 6.337,
            ic_yearly: 4.967,
          },
          {
            ic_uuid: "1cc1294d-a976-4876-8d8b-8051573fc665",
            ic_lat: -276676,
            ic_lon: -526526,
            ic_city: "Cruzaltense",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.368,
            ic_february: 5.939,
            ic_march: 5.039,
            ic_april: 4.087,
            ic_may: 3.088,
            ic_june: 2.596,
            ic_july: 2.912,
            ic_august: 3.73,
            ic_september: 3.961,
            ic_october: 5.119,
            ic_november: 6.17,
            ic_december: 6.61,
            ic_yearly: 4.635,
          },
          {
            ic_uuid: "39c81af9-4c35-497f-a521-3a7975881193",
            ic_lat: -225732,
            ic_lon: -449693,
            ic_city: "Cruzeiro",
            ic_states: "SÃO PAULO",
            ic_january: 5.434,
            ic_february: 5.641,
            ic_march: 4.795,
            ic_april: 4.421,
            ic_may: 3.685,
            ic_june: 3.493,
            ic_july: 3.606,
            ic_august: 4.537,
            ic_september: 4.663,
            ic_october: 5.081,
            ic_november: 5.086,
            ic_december: 5.731,
            ic_yearly: 4.681,
          },
          {
            ic_uuid: "c52d9e9b-bdd5-4886-88bf-a36b5e11fcb5",
            ic_lat: -189461,
            ic_lon: -466728,
            ic_city: "Cruzeiro da Fortaleza",
            ic_states: "MINAS GERAIS",
            ic_january: 5.682,
            ic_february: 6.082,
            ic_march: 5.042,
            ic_april: 5.012,
            ic_may: 4.634,
            ic_june: 4.368,
            ic_july: 4.667,
            ic_august: 5.625,
            ic_september: 5.618,
            ic_october: 5.611,
            ic_november: 5.265,
            ic_december: 5.618,
            ic_yearly: 5.269,
          },
          {
            ic_uuid: "8d114354-b258-4b6a-bac3-73fffcd2e9fc",
            ic_lat: -256197,
            ic_lon: -531289,
            ic_city: "Cruzeiro do Iguaçu",
            ic_states: "PARANÁ",
            ic_january: 6.316,
            ic_february: 5.786,
            ic_march: 5.286,
            ic_april: 4.28,
            ic_may: 3.278,
            ic_june: 2.833,
            ic_july: 3.086,
            ic_august: 4.071,
            ic_september: 4.25,
            ic_october: 5.261,
            ic_november: 6.162,
            ic_december: 6.473,
            ic_yearly: 4.757,
          },
          {
            ic_uuid: "42625910-6edd-48ee-92b1-a11318aeffec",
            ic_lat: -237804,
            ic_lon: -530779,
            ic_city: "Cruzeiro do Oeste",
            ic_states: "PARANÁ",
            ic_january: 6.113,
            ic_february: 5.84,
            ic_march: 5.402,
            ic_april: 4.517,
            ic_may: 3.552,
            ic_june: 3.161,
            ic_july: 3.377,
            ic_august: 4.36,
            ic_september: 4.591,
            ic_october: 5.384,
            ic_november: 6.181,
            ic_december: 6.468,
            ic_yearly: 4.912,
          },
          {
            ic_uuid: "405c16ab-5093-47d3-bdaa-0de3bcf7a363",
            ic_lat: -7628,
            ic_lon: -726761,
            ic_city: "Cruzeiro do Sul",
            ic_states: "ACRE",
            ic_january: 4.738,
            ic_february: 4.902,
            ic_march: 4.248,
            ic_april: 4.455,
            ic_may: 4.285,
            ic_june: 4.164,
            ic_july: 4.363,
            ic_august: 4.996,
            ic_september: 5.135,
            ic_october: 5.024,
            ic_november: 4.95,
            ic_december: 4.701,
            ic_yearly: 4.663,
          },
          {
            ic_uuid: "d91e4fc9-1909-40ed-b0d5-45517a875723",
            ic_lat: -229629,
            ic_lon: -521626,
            ic_city: "Cruzeiro do Sul",
            ic_states: "PARANÁ",
            ic_january: 5.943,
            ic_february: 5.871,
            ic_march: 5.375,
            ic_april: 4.675,
            ic_may: 3.708,
            ic_june: 3.413,
            ic_july: 3.59,
            ic_august: 4.532,
            ic_september: 4.741,
            ic_october: 5.452,
            ic_november: 6.174,
            ic_december: 6.386,
            ic_yearly: 4.988,
          },
          {
            ic_uuid: "89bb3313-00fa-4a11-9208-b64cdf9d8009",
            ic_lat: -29513,
            ic_lon: -519849,
            ic_city: "Cruzeiro do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.139,
            ic_february: 5.756,
            ic_march: 4.816,
            ic_april: 3.794,
            ic_may: 2.811,
            ic_june: 2.33,
            ic_july: 2.568,
            ic_august: 3.28,
            ic_september: 3.589,
            ic_october: 4.64,
            ic_november: 6.124,
            ic_december: 6.499,
            ic_yearly: 4.362,
          },
          {
            ic_uuid: "db065232-da76-4836-9a5e-70fd4e8db1eb",
            ic_lat: -64111,
            ic_lon: -36785,
            ic_city: "Cruzeta",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.994,
            ic_february: 6.017,
            ic_march: 6.039,
            ic_april: 5.638,
            ic_may: 5.084,
            ic_june: 4.681,
            ic_july: 4.92,
            ic_august: 5.657,
            ic_september: 6.275,
            ic_october: 6.483,
            ic_november: 6.495,
            ic_december: 6.077,
            ic_yearly: 5.78,
          },
          {
            ic_uuid: "872e7b7d-ecb4-4a63-8d35-c51dde7b7c09",
            ic_lat: -218405,
            ic_lon: -448071,
            ic_city: "Cruzília",
            ic_states: "MINAS GERAIS",
            ic_january: 5.399,
            ic_february: 5.698,
            ic_march: 4.92,
            ic_april: 4.532,
            ic_may: 3.855,
            ic_june: 3.627,
            ic_july: 3.899,
            ic_august: 4.796,
            ic_september: 5.036,
            ic_october: 5.338,
            ic_november: 5.165,
            ic_december: 5.543,
            ic_yearly: 4.818,
          },
          {
            ic_uuid: "c857433a-19c3-4ab4-bbbe-4dacabd5606d",
            ic_lat: -239924,
            ic_lon: -51444,
            ic_city: "Cruzmaltina",
            ic_states: "PARANÁ",
            ic_january: 5.841,
            ic_february: 5.666,
            ic_march: 5.162,
            ic_april: 4.504,
            ic_may: 3.529,
            ic_june: 3.206,
            ic_july: 3.435,
            ic_august: 4.389,
            ic_september: 4.556,
            ic_october: 5.233,
            ic_november: 5.96,
            ic_december: 6.274,
            ic_yearly: 4.813,
          },
          {
            ic_uuid: "0c2f9fee-f9a3-487c-8a75-cf5ccd40916d",
            ic_lat: -238916,
            ic_lon: -464244,
            ic_city: "Cubatão",
            ic_states: "SÃO PAULO",
            ic_january: 4.816,
            ic_february: 5.034,
            ic_march: 4.196,
            ic_april: 3.778,
            ic_may: 3.143,
            ic_june: 2.823,
            ic_july: 2.861,
            ic_august: 3.507,
            ic_september: 3.395,
            ic_october: 3.709,
            ic_november: 4.218,
            ic_december: 4.9,
            ic_yearly: 3.865,
          },
          {
            ic_uuid: "817044a9-a612-4131-a51d-5f4ae76ca689",
            ic_lat: -68649,
            ic_lon: -363522,
            ic_city: "Cubati",
            ic_states: "PARAÍBA",
            ic_january: 5.855,
            ic_february: 5.885,
            ic_march: 5.936,
            ic_april: 5.448,
            ic_may: 4.895,
            ic_june: 4.436,
            ic_july: 4.694,
            ic_august: 5.387,
            ic_september: 5.938,
            ic_october: 6.252,
            ic_november: 6.397,
            ic_december: 6.028,
            ic_yearly: 5.596,
          },
          {
            ic_uuid: "dfa62de8-b497-4121-817b-27e93a3d3d26",
            ic_lat: -156018,
            ic_lon: -560983,
            ic_city: "Cuiabá",
            ic_states: "MATO GROSSO",
            ic_january: 5.456,
            ic_february: 5.333,
            ic_march: 5.17,
            ic_april: 4.907,
            ic_may: 4.414,
            ic_june: 4.364,
            ic_july: 4.522,
            ic_august: 5.398,
            ic_september: 5.134,
            ic_october: 5.313,
            ic_november: 5.555,
            ic_december: 5.709,
            ic_yearly: 5.106,
          },
          {
            ic_uuid: "c5eb344b-977f-4da6-b12f-313b3d903933",
            ic_lat: -64857,
            ic_lon: -361549,
            ic_city: "Cuité",
            ic_states: "PARAÍBA",
            ic_january: 5.695,
            ic_february: 5.719,
            ic_march: 5.686,
            ic_april: 5.355,
            ic_may: 4.76,
            ic_june: 4.322,
            ic_july: 4.521,
            ic_august: 5.264,
            ic_september: 5.779,
            ic_october: 6.051,
            ic_november: 6.179,
            ic_december: 5.8,
            ic_yearly: 5.428,
          },
          {
            ic_uuid: "daf19531-15b8-405c-8fcc-3a988875c722",
            ic_lat: -69133,
            ic_lon: -352505,
            ic_city: "Cuité de Mamanguape",
            ic_states: "PARAÍBA",
            ic_january: 5.712,
            ic_february: 5.733,
            ic_march: 5.851,
            ic_april: 5.296,
            ic_may: 4.68,
            ic_june: 4.271,
            ic_july: 4.366,
            ic_august: 5.138,
            ic_september: 5.547,
            ic_october: 5.807,
            ic_november: 6.135,
            ic_december: 5.968,
            ic_yearly: 5.375,
          },
          {
            ic_uuid: "75104daf-84eb-4d01-ba5a-9880790936c3",
            ic_lat: -68914,
            ic_lon: -355218,
            ic_city: "Cuitegi",
            ic_states: "PARAÍBA",
            ic_january: 5.484,
            ic_february: 5.604,
            ic_march: 5.631,
            ic_april: 5.168,
            ic_may: 4.563,
            ic_june: 4.117,
            ic_july: 4.201,
            ic_august: 4.956,
            ic_september: 5.375,
            ic_october: 5.603,
            ic_november: 5.856,
            ic_december: 5.648,
            ic_yearly: 5.184,
          },
          {
            ic_uuid: "e5fc855f-37b6-4f2e-bd18-18228492d156",
            ic_lat: -93611,
            ic_lon: -62585,
            ic_city: "Cujubim",
            ic_states: "RONDÔNIA",
            ic_january: 4.16,
            ic_february: 4.29,
            ic_march: 4.344,
            ic_april: 4.295,
            ic_may: 4.005,
            ic_june: 4.422,
            ic_july: 4.495,
            ic_august: 4.87,
            ic_september: 4.799,
            ic_october: 4.968,
            ic_november: 4.729,
            ic_december: 4.545,
            ic_yearly: 4.494,
          },
          {
            ic_uuid: "b43188d3-2884-4cd0-b3fd-7b483d386dde",
            ic_lat: -182649,
            ic_lon: -481515,
            ic_city: "Cumari",
            ic_states: "GOIÁS",
            ic_january: 5.597,
            ic_february: 5.86,
            ic_march: 5.181,
            ic_april: 5.114,
            ic_may: 4.667,
            ic_june: 4.417,
            ic_july: 4.659,
            ic_august: 5.542,
            ic_september: 5.515,
            ic_october: 5.692,
            ic_november: 5.543,
            ic_december: 5.785,
            ic_yearly: 5.298,
          },
          {
            ic_uuid: "cffdf614-f4f6-4afa-967a-343eec9814ca",
            ic_lat: -80087,
            ic_lon: -35696,
            ic_city: "Cumaru",
            ic_states: "PERNAMBUCO",
            ic_january: 5.703,
            ic_february: 5.668,
            ic_march: 5.71,
            ic_april: 5.123,
            ic_may: 4.428,
            ic_june: 4.032,
            ic_july: 4.129,
            ic_august: 4.771,
            ic_september: 5.416,
            ic_october: 5.649,
            ic_november: 5.926,
            ic_december: 5.916,
            ic_yearly: 5.206,
          },
          {
            ic_uuid: "69feac28-2886-4d26-8f69-059d464823fb",
            ic_lat: -78114,
            ic_lon: -507702,
            ic_city: "Cumaru do Norte",
            ic_states: "PARÁ",
            ic_january: 4.61,
            ic_february: 4.671,
            ic_march: 4.502,
            ic_april: 4.478,
            ic_may: 4.689,
            ic_june: 5.01,
            ic_july: 5.057,
            ic_august: 5.575,
            ic_september: 5.168,
            ic_october: 4.744,
            ic_november: 4.645,
            ic_december: 4.484,
            ic_yearly: 4.803,
          },
          {
            ic_uuid: "68d25ed3-a133-46f8-85d9-5a5ebd11e347",
            ic_lat: -10353,
            ic_lon: -371878,
            ic_city: "Cumbe",
            ic_states: "SERGIPE",
            ic_january: 6.082,
            ic_february: 5.872,
            ic_march: 5.767,
            ic_april: 5.052,
            ic_may: 4.241,
            ic_june: 3.995,
            ic_july: 4.09,
            ic_august: 4.638,
            ic_september: 5.416,
            ic_october: 5.699,
            ic_november: 6.165,
            ic_december: 6.17,
            ic_yearly: 5.265,
          },
          {
            ic_uuid: "dc0635a8-6bf4-4802-8f0b-48206d1ebd02",
            ic_lat: -230736,
            ic_lon: -449579,
            ic_city: "Cunha",
            ic_states: "SÃO PAULO",
            ic_january: 5.766,
            ic_february: 5.829,
            ic_march: 4.956,
            ic_april: 4.506,
            ic_may: 3.662,
            ic_june: 3.457,
            ic_july: 3.568,
            ic_august: 4.463,
            ic_september: 4.613,
            ic_october: 5.206,
            ic_november: 5.309,
            ic_december: 5.926,
            ic_yearly: 4.772,
          },
          {
            ic_uuid: "7ace78fd-e68d-46b2-bdac-a364ae6918a3",
            ic_lat: -268955,
            ic_lon: -531666,
            ic_city: "Cunha Porã",
            ic_states: "SANTA CATARINA",
            ic_january: 6.211,
            ic_february: 5.739,
            ic_march: 5.062,
            ic_april: 4.122,
            ic_may: 3.16,
            ic_june: 2.65,
            ic_july: 2.953,
            ic_august: 3.812,
            ic_september: 4.057,
            ic_october: 5.102,
            ic_november: 6.053,
            ic_december: 6.405,
            ic_yearly: 4.61,
          },
          {
            ic_uuid: "5370ac60-b1ae-4f03-a06d-4df7f31396a6",
            ic_lat: -269714,
            ic_lon: -530899,
            ic_city: "Cunhataí",
            ic_states: "SANTA CATARINA",
            ic_january: 6.205,
            ic_february: 5.718,
            ic_march: 5.082,
            ic_april: 4.087,
            ic_may: 3.126,
            ic_june: 2.629,
            ic_july: 2.925,
            ic_august: 3.79,
            ic_september: 4.039,
            ic_october: 5.14,
            ic_november: 6.097,
            ic_december: 6.479,
            ic_yearly: 4.61,
          },
          {
            ic_uuid: "347210fa-6717-44a9-a1b3-98d266eef7e2",
            ic_lat: -189653,
            ic_lon: -41099,
            ic_city: "Cuparaque",
            ic_states: "MINAS GERAIS",
            ic_january: 5.883,
            ic_february: 6.024,
            ic_march: 5.103,
            ic_april: 4.46,
            ic_may: 3.811,
            ic_june: 3.505,
            ic_july: 3.624,
            ic_august: 4.272,
            ic_september: 4.836,
            ic_october: 5.05,
            ic_november: 4.88,
            ic_december: 5.65,
            ic_yearly: 4.758,
          },
          {
            ic_uuid: "714d40ae-45df-4a5e-a3ee-d57ac54d6f41",
            ic_lat: -86248,
            ic_lon: -359521,
            ic_city: "Cupira",
            ic_states: "PERNAMBUCO",
            ic_january: 6.007,
            ic_february: 5.825,
            ic_march: 5.932,
            ic_april: 5.18,
            ic_may: 4.352,
            ic_june: 3.943,
            ic_july: 3.987,
            ic_august: 4.641,
            ic_september: 5.429,
            ic_october: 5.763,
            ic_november: 6.22,
            ic_december: 6.12,
            ic_yearly: 5.283,
          },
          {
            ic_uuid: "f7a5de99-541a-4da7-984f-410e4467e835",
            ic_lat: -8992,
            ic_lon: -399052,
            ic_city: "Curaçá",
            ic_states: "BAHIA",
            ic_january: 6.268,
            ic_february: 5.965,
            ic_march: 5.95,
            ic_april: 5.304,
            ic_may: 4.713,
            ic_june: 4.411,
            ic_july: 4.647,
            ic_august: 5.442,
            ic_september: 6.257,
            ic_october: 6.32,
            ic_november: 6.437,
            ic_december: 6.255,
            ic_yearly: 5.664,
          },
          {
            ic_uuid: "1be9017c-fbae-4109-ae63-02c5da45c3e9",
            ic_lat: -10033,
            ic_lon: -443006,
            ic_city: "Curimatá",
            ic_states: "PIAUÍ",
            ic_january: 6.021,
            ic_february: 5.811,
            ic_march: 5.698,
            ic_april: 5.544,
            ic_may: 5.424,
            ic_june: 5.28,
            ic_july: 5.55,
            ic_august: 6.191,
            ic_september: 6.476,
            ic_october: 6.265,
            ic_november: 5.959,
            ic_december: 6.018,
            ic_yearly: 5.853,
          },
          {
            ic_uuid: "2ee08cb2-8839-4ba6-90b3-0e3c33615b6c",
            ic_lat: -6095,
            ic_lon: -496033,
            ic_city: "Curionópolis",
            ic_states: "PARÁ",
            ic_january: 4.458,
            ic_february: 4.667,
            ic_march: 4.524,
            ic_april: 4.64,
            ic_may: 4.708,
            ic_june: 4.956,
            ic_july: 5.061,
            ic_august: 5.525,
            ic_september: 5.148,
            ic_october: 4.677,
            ic_november: 4.467,
            ic_december: 4.321,
            ic_yearly: 4.763,
          },
          {
            ic_uuid: "3349e6f2-f812-4682-be4a-b40118dd944a",
            ic_lat: -254205,
            ic_lon: -492655,
            ic_city: "Curitiba",
            ic_states: "PARANÁ",
            ic_january: 5.318,
            ic_february: 5.158,
            ic_march: 4.538,
            ic_april: 3.782,
            ic_may: 3.047,
            ic_june: 2.758,
            ic_july: 2.892,
            ic_august: 3.864,
            ic_september: 3.83,
            ic_october: 4.359,
            ic_november: 5.23,
            ic_december: 5.557,
            ic_yearly: 4.194,
          },
          {
            ic_uuid: "3d7537a0-ec4a-431b-a0dc-36022934c0a3",
            ic_lat: -272829,
            ic_lon: -505821,
            ic_city: "Curitibanos",
            ic_states: "SANTA CATARINA",
            ic_january: 5.675,
            ic_february: 5.352,
            ic_march: 4.675,
            ic_april: 3.828,
            ic_may: 2.924,
            ic_june: 2.604,
            ic_july: 2.819,
            ic_august: 3.695,
            ic_september: 3.818,
            ic_october: 4.569,
            ic_november: 5.764,
            ic_december: 6.036,
            ic_yearly: 4.313,
          },
          {
            ic_uuid: "92245d4e-b79f-4b51-a72b-39c2d2f6d4d9",
            ic_lat: -240366,
            ic_lon: -504581,
            ic_city: "Curiúva",
            ic_states: "PARANÁ",
            ic_january: 5.682,
            ic_february: 5.543,
            ic_march: 5.078,
            ic_april: 4.452,
            ic_may: 3.491,
            ic_june: 3.099,
            ic_july: 3.4,
            ic_august: 4.38,
            ic_september: 4.545,
            ic_october: 5.103,
            ic_november: 5.789,
            ic_december: 6.091,
            ic_yearly: 4.721,
          },
          {
            ic_uuid: "84bdda92-f679-46e0-a193-d436c8979627",
            ic_lat: -90143,
            ic_lon: -444029,
            ic_city: "Currais",
            ic_states: "PIAUÍ",
            ic_january: 5.632,
            ic_february: 5.502,
            ic_march: 5.378,
            ic_april: 5.397,
            ic_may: 5.378,
            ic_june: 5.314,
            ic_july: 5.66,
            ic_august: 6.342,
            ic_september: 6.642,
            ic_october: 6.148,
            ic_november: 5.734,
            ic_december: 5.674,
            ic_yearly: 5.733,
          },
          {
            ic_uuid: "b8efb021-fe12-4569-9898-7379f6b34372",
            ic_lat: -62553,
            ic_lon: -365149,
            ic_city: "Currais Novos",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.918,
            ic_february: 5.935,
            ic_march: 5.866,
            ic_april: 5.56,
            ic_may: 4.956,
            ic_june: 4.605,
            ic_july: 4.837,
            ic_august: 5.573,
            ic_september: 6.18,
            ic_october: 6.369,
            ic_november: 6.433,
            ic_december: 6.076,
            ic_yearly: 5.692,
          },
          {
            ic_uuid: "34f906dd-55ec-4ad9-bc19-9e8ba0dc6b65",
            ic_lat: -67167,
            ic_lon: -352671,
            ic_city: "Curral de Cima",
            ic_states: "PARAÍBA",
            ic_january: 5.845,
            ic_february: 5.884,
            ic_march: 5.931,
            ic_april: 5.331,
            ic_may: 4.718,
            ic_june: 4.243,
            ic_july: 4.369,
            ic_august: 5.129,
            ic_september: 5.618,
            ic_october: 5.92,
            ic_november: 6.202,
            ic_december: 6.007,
            ic_yearly: 5.433,
          },
          {
            ic_uuid: "6b8a5d43-681a-4161-bb9b-c8460689b044",
            ic_lat: -159332,
            ic_lon: -41856,
            ic_city: "Curral de Dentro",
            ic_states: "MINAS GERAIS",
            ic_january: 6.18,
            ic_february: 6.252,
            ic_march: 5.459,
            ic_april: 4.755,
            ic_may: 4.116,
            ic_june: 3.78,
            ic_july: 4.026,
            ic_august: 4.735,
            ic_september: 5.636,
            ic_october: 5.699,
            ic_november: 5.348,
            ic_december: 6.077,
            ic_yearly: 5.172,
          },
          {
            ic_uuid: "c2ce9488-8a34-4b68-918d-cb24b9245f7f",
            ic_lat: -78316,
            ic_lon: -409009,
            ic_city: "Curral Novo do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.799,
            ic_february: 5.606,
            ic_march: 5.74,
            ic_april: 5.36,
            ic_may: 5.156,
            ic_june: 5.114,
            ic_july: 5.524,
            ic_august: 6.28,
            ic_september: 6.661,
            ic_october: 6.607,
            ic_november: 6.477,
            ic_december: 6.123,
            ic_yearly: 5.871,
          },
          {
            ic_uuid: "bf33d50e-54fb-40a0-8228-933a09cba1df",
            ic_lat: -75394,
            ic_lon: -38199,
            ic_city: "Curral Velho",
            ic_states: "PARAÍBA",
            ic_january: 6.223,
            ic_february: 6.133,
            ic_march: 6.223,
            ic_april: 5.864,
            ic_may: 5.199,
            ic_june: 4.766,
            ic_july: 5.005,
            ic_august: 5.87,
            ic_september: 6.554,
            ic_october: 6.639,
            ic_november: 6.798,
            ic_december: 6.423,
            ic_yearly: 5.974,
          },
          {
            ic_uuid: "3cc18f06-34fc-4d24-8223-06fb3e438db7",
            ic_lat: -18103,
            ic_lon: -497985,
            ic_city: "Curralinho",
            ic_states: "PARÁ",
            ic_january: 4.642,
            ic_february: 4.637,
            ic_march: 4.78,
            ic_april: 4.756,
            ic_may: 4.999,
            ic_june: 5.15,
            ic_july: 5.12,
            ic_august: 5.384,
            ic_september: 5.592,
            ic_october: 5.552,
            ic_november: 5.377,
            ic_december: 4.933,
            ic_yearly: 5.077,
          },
          {
            ic_uuid: "27bde64c-16c3-424a-82bd-b2c05f32df93",
            ic_lat: -56182,
            ic_lon: -428309,
            ic_city: "Curralinhos",
            ic_states: "PIAUÍ",
            ic_january: 4.93,
            ic_february: 5.104,
            ic_march: 5.142,
            ic_april: 5.046,
            ic_may: 5.12,
            ic_june: 5.394,
            ic_july: 5.583,
            ic_august: 6.238,
            ic_september: 6.541,
            ic_october: 6.293,
            ic_november: 5.966,
            ic_december: 5.519,
            ic_yearly: 5.573,
          },
          {
            ic_uuid: "c263492f-151d-4aea-a5da-65722fc92a2a",
            ic_lat: -18881,
            ic_lon: -551173,
            ic_city: "Curuá",
            ic_states: "PARÁ",
            ic_january: 4.532,
            ic_february: 4.613,
            ic_march: 4.515,
            ic_april: 4.456,
            ic_may: 4.591,
            ic_june: 4.916,
            ic_july: 4.811,
            ic_august: 5.332,
            ic_september: 5.328,
            ic_october: 5.449,
            ic_november: 5.273,
            ic_december: 4.745,
            ic_yearly: 4.88,
          },
          {
            ic_uuid: "675f0a63-c70c-4776-9e86-7b3739d5778d",
            ic_lat: -7336,
            ic_lon: -478519,
            ic_city: "Curuçá",
            ic_states: "PARÁ",
            ic_january: 4.589,
            ic_february: 4.249,
            ic_march: 4.137,
            ic_april: 4.216,
            ic_may: 4.487,
            ic_june: 4.808,
            ic_july: 4.915,
            ic_august: 5.257,
            ic_september: 5.484,
            ic_october: 5.422,
            ic_november: 5.345,
            ic_december: 4.993,
            ic_yearly: 4.825,
          },
          {
            ic_uuid: "356b7100-8e23-41eb-bfe4-005fdc1c1cfe",
            ic_lat: -18243,
            ic_lon: -448733,
            ic_city: "Cururupu",
            ic_states: "MARANHÃO",
            ic_january: 4.891,
            ic_february: 4.764,
            ic_march: 4.562,
            ic_april: 4.414,
            ic_may: 4.613,
            ic_june: 4.894,
            ic_july: 4.993,
            ic_august: 5.42,
            ic_september: 5.751,
            ic_october: 5.494,
            ic_november: 5.438,
            ic_december: 5.115,
            ic_yearly: 5.029,
          },
          {
            ic_uuid: "55eb79f6-e2f8-48d1-9198-e108b0e70558",
            ic_lat: -156088,
            ic_lon: -579137,
            ic_city: "Curvelândia",
            ic_states: "MATO GROSSO",
            ic_january: 5.396,
            ic_february: 5.188,
            ic_march: 5.021,
            ic_april: 4.777,
            ic_may: 4.187,
            ic_june: 4.232,
            ic_july: 4.339,
            ic_august: 5.091,
            ic_september: 5.056,
            ic_october: 5.187,
            ic_november: 5.411,
            ic_december: 5.527,
            ic_yearly: 4.951,
          },
          {
            ic_uuid: "fcd65a49-0722-48b4-8688-7cf72f0bffa1",
            ic_lat: -187532,
            ic_lon: -444306,
            ic_city: "Curvelo",
            ic_states: "MINAS GERAIS",
            ic_january: 6.056,
            ic_february: 6.354,
            ic_march: 5.475,
            ic_april: 5.166,
            ic_may: 4.592,
            ic_june: 4.271,
            ic_july: 4.672,
            ic_august: 5.34,
            ic_september: 5.786,
            ic_october: 5.846,
            ic_november: 5.543,
            ic_december: 5.911,
            ic_yearly: 5.418,
          },
          {
            ic_uuid: "2ad42c6c-3902-4f06-82f1-ed0f7dba077f",
            ic_lat: -80877,
            ic_lon: -376417,
            ic_city: "Custódia",
            ic_states: "PERNAMBUCO",
            ic_january: 6.317,
            ic_february: 6.249,
            ic_march: 6.125,
            ic_april: 5.728,
            ic_may: 4.862,
            ic_june: 4.28,
            ic_july: 4.542,
            ic_august: 5.331,
            ic_september: 6.261,
            ic_october: 6.526,
            ic_november: 6.797,
            ic_december: 6.49,
            ic_yearly: 5.793,
          },
          {
            ic_uuid: "0c035b1c-1517-43df-adea-f8d0e5edcad8",
            ic_lat: 969,
            ic_lon: -50798,
            ic_city: "Cutias",
            ic_states: "AMAPÁ",
            ic_january: 4.301,
            ic_february: 4.015,
            ic_march: 3.931,
            ic_april: 4.015,
            ic_may: 4.438,
            ic_june: 4.793,
            ic_july: 5.03,
            ic_august: 5.425,
            ic_september: 5.513,
            ic_october: 5.45,
            ic_november: 5.2,
            ic_december: 4.517,
            ic_yearly: 4.719,
          },
          {
            ic_uuid: "da1584eb-6d62-4c2f-b7f8-06a4a23e27a9",
            ic_lat: -145602,
            ic_lon: -461718,
            ic_city: "Damianópolis",
            ic_states: "GOIÁS",
            ic_january: 6.009,
            ic_february: 6.025,
            ic_march: 5.479,
            ic_april: 5.392,
            ic_may: 5.022,
            ic_june: 4.892,
            ic_july: 5.262,
            ic_august: 5.939,
            ic_september: 6.03,
            ic_october: 5.897,
            ic_november: 5.291,
            ic_december: 5.893,
            ic_yearly: 5.594,
          },
          {
            ic_uuid: "f4e505af-8d5d-411f-b162-f6d6d9fe44a6",
            ic_lat: -6632,
            ic_lon: -359104,
            ic_city: "Damião",
            ic_states: "PARAÍBA",
            ic_january: 5.753,
            ic_february: 5.726,
            ic_march: 5.85,
            ic_april: 5.381,
            ic_may: 4.778,
            ic_june: 4.382,
            ic_july: 4.55,
            ic_august: 5.28,
            ic_september: 5.709,
            ic_october: 5.974,
            ic_november: 6.132,
            ic_december: 5.842,
            ic_yearly: 5.446,
          },
          {
            ic_uuid: "a6cc10ac-aede-422a-9cd1-13bf7e584f62",
            ic_lat: -162546,
            ic_lon: -493668,
            ic_city: "Damolândia",
            ic_states: "GOIÁS",
            ic_january: 5.401,
            ic_february: 5.57,
            ic_march: 5.168,
            ic_april: 5.153,
            ic_may: 4.873,
            ic_june: 4.647,
            ic_july: 4.839,
            ic_august: 5.817,
            ic_september: 5.553,
            ic_october: 5.521,
            ic_november: 5.266,
            ic_december: 5.467,
            ic_yearly: 5.273,
          },
          {
            ic_uuid: "0572a663-9955-4cd8-84f1-a73b24ea1dc5",
            ic_lat: -67101,
            ic_lon: -477531,
            ic_city: "Darcinópolis",
            ic_states: "TOCANTINS",
            ic_january: 4.72,
            ic_february: 4.816,
            ic_march: 4.765,
            ic_april: 4.865,
            ic_may: 4.924,
            ic_june: 5.19,
            ic_july: 5.431,
            ic_august: 5.97,
            ic_september: 5.811,
            ic_october: 5.105,
            ic_november: 4.695,
            ic_december: 4.595,
            ic_yearly: 5.074,
          },
          {
            ic_uuid: "03131bc8-0ceb-444f-9192-50628f32b5e4",
            ic_lat: -144296,
            ic_lon: -399042,
            ic_city: "Dário Meira",
            ic_states: "BAHIA",
            ic_january: 5.803,
            ic_february: 5.927,
            ic_march: 5.505,
            ic_april: 4.55,
            ic_may: 3.957,
            ic_june: 3.553,
            ic_july: 3.714,
            ic_august: 4.148,
            ic_september: 4.816,
            ic_october: 4.961,
            ic_november: 5.157,
            ic_december: 5.933,
            ic_yearly: 4.835,
          },
          {
            ic_uuid: "11b07974-ea0a-4c13-9b3e-aa38debd5dd8",
            ic_lat: -184482,
            ic_lon: -436595,
            ic_city: "Datas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.744,
            ic_february: 6.013,
            ic_march: 5.028,
            ic_april: 4.642,
            ic_may: 4.263,
            ic_june: 4.134,
            ic_july: 4.476,
            ic_august: 5.227,
            ic_september: 5.611,
            ic_october: 5.591,
            ic_november: 5.023,
            ic_december: 5.579,
            ic_yearly: 5.111,
          },
          {
            ic_uuid: "87ddb8b7-f405-4c7b-927b-bb4dd965312c",
            ic_lat: -283854,
            ic_lon: -518487,
            ic_city: "David Canabarro",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.125,
            ic_february: 5.664,
            ic_march: 4.889,
            ic_april: 3.965,
            ic_may: 2.959,
            ic_june: 2.498,
            ic_july: 2.821,
            ic_august: 3.557,
            ic_september: 3.798,
            ic_october: 4.913,
            ic_november: 6.12,
            ic_december: 6.446,
            ic_yearly: 4.48,
          },
          {
            ic_uuid: "603058c7-2f8e-435c-b528-5ad8235798a3",
            ic_lat: -181538,
            ic_lon: -475631,
            ic_city: "Davinópolis",
            ic_states: "GOIÁS",
            ic_january: 5.681,
            ic_february: 6.026,
            ic_march: 5.191,
            ic_april: 5.175,
            ic_may: 4.73,
            ic_june: 4.484,
            ic_july: 4.798,
            ic_august: 5.675,
            ic_september: 5.629,
            ic_october: 5.706,
            ic_november: 5.503,
            ic_december: 5.766,
            ic_yearly: 5.364,
          },
          {
            ic_uuid: "06abf53b-1a26-4762-88cb-96237ac9f72c",
            ic_lat: -55312,
            ic_lon: -47394,
            ic_city: "Davinópolis",
            ic_states: "MARANHÃO",
            ic_january: 4.651,
            ic_february: 4.721,
            ic_march: 4.791,
            ic_april: 4.874,
            ic_may: 4.841,
            ic_june: 5.193,
            ic_july: 5.324,
            ic_august: 5.754,
            ic_september: 5.614,
            ic_october: 5.21,
            ic_november: 4.9,
            ic_december: 4.728,
            ic_yearly: 5.05,
          },
          {
            ic_uuid: "54709e87-cf34-4069-a07e-26abf55386de",
            ic_lat: -225041,
            ic_lon: -452796,
            ic_city: "Delfim Moreira",
            ic_states: "MINAS GERAIS",
            ic_january: 5.192,
            ic_february: 5.424,
            ic_march: 4.665,
            ic_april: 4.347,
            ic_may: 3.759,
            ic_june: 3.581,
            ic_july: 3.772,
            ic_august: 4.684,
            ic_september: 4.878,
            ic_october: 5.222,
            ic_november: 5.141,
            ic_december: 5.44,
            ic_yearly: 4.675,
          },
          {
            ic_uuid: "02dd6afb-7429-4eb0-b062-3ce862b2d60e",
            ic_lat: -203472,
            ic_lon: -468459,
            ic_city: "Delfinópolis",
            ic_states: "MINAS GERAIS",
            ic_january: 5.467,
            ic_february: 5.706,
            ic_march: 4.915,
            ic_april: 4.846,
            ic_may: 4.293,
            ic_june: 4.07,
            ic_july: 4.322,
            ic_august: 5.202,
            ic_september: 5.237,
            ic_october: 5.541,
            ic_november: 5.392,
            ic_december: 5.545,
            ic_yearly: 5.045,
          },
          {
            ic_uuid: "a5fa9915-aab1-4849-b7fc-dfef5e9b3b88",
            ic_lat: -93858,
            ic_lon: -37999,
            ic_city: "Delmiro Gouveia",
            ic_states: "ALAGOAS",
            ic_january: 6.317,
            ic_february: 6.064,
            ic_march: 5.961,
            ic_april: 5.278,
            ic_may: 4.437,
            ic_june: 4.022,
            ic_july: 4.161,
            ic_august: 4.78,
            ic_september: 5.686,
            ic_october: 5.993,
            ic_november: 6.511,
            ic_december: 6.389,
            ic_yearly: 5.467,
          },
          {
            ic_uuid: "2b16a6d2-90fc-445b-8656-125c7e969988",
            ic_lat: -199741,
            ic_lon: -477746,
            ic_city: "Delta",
            ic_states: "MINAS GERAIS",
            ic_january: 5.573,
            ic_february: 5.89,
            ic_march: 5.101,
            ic_april: 5.019,
            ic_may: 4.343,
            ic_june: 4.119,
            ic_july: 4.387,
            ic_august: 5.275,
            ic_september: 5.179,
            ic_october: 5.58,
            ic_november: 5.572,
            ic_december: 5.821,
            ic_yearly: 5.155,
          },
          {
            ic_uuid: "ecdaad1d-136c-497b-a3ab-575ef2144eaa",
            ic_lat: -53592,
            ic_lon: -426779,
            ic_city: "Demerval Lobão",
            ic_states: "PIAUÍ",
            ic_january: 5.083,
            ic_february: 5.154,
            ic_march: 5.255,
            ic_april: 5.11,
            ic_may: 5.135,
            ic_june: 5.333,
            ic_july: 5.605,
            ic_august: 6.192,
            ic_september: 6.609,
            ic_october: 6.412,
            ic_november: 6.133,
            ic_december: 5.726,
            ic_yearly: 5.645,
          },
          {
            ic_uuid: "b9b0651b-4cdf-4dd5-9dd4-c1ab487bf24d",
            ic_lat: -14729,
            ic_lon: -570507,
            ic_city: "Denise",
            ic_states: "MATO GROSSO",
            ic_january: 5.193,
            ic_february: 5.202,
            ic_march: 4.988,
            ic_april: 4.778,
            ic_may: 4.359,
            ic_june: 4.414,
            ic_july: 4.564,
            ic_august: 5.247,
            ic_september: 4.993,
            ic_october: 5.219,
            ic_november: 5.36,
            ic_december: 5.426,
            ic_yearly: 4.978,
          },
          {
            ic_uuid: "be2dffe0-bf7d-41d1-af32-7be0c984c276",
            ic_lat: -222767,
            ic_lon: -541686,
            ic_city: "Deodápolis",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.963,
            ic_february: 5.857,
            ic_march: 5.384,
            ic_april: 4.59,
            ic_may: 3.734,
            ic_june: 3.39,
            ic_july: 3.576,
            ic_august: 4.507,
            ic_september: 4.77,
            ic_october: 5.444,
            ic_november: 6.076,
            ic_december: 6.459,
            ic_yearly: 4.979,
          },
          {
            ic_uuid: "ed2399be-7085-4e6e-8667-6176a96aef16",
            ic_lat: -59153,
            ic_lon: -392573,
            ic_city: "Deputado Irapuan Pinheiro",
            ic_states: "CEARÁ",
            ic_january: 5.494,
            ic_february: 5.642,
            ic_march: 5.745,
            ic_april: 5.307,
            ic_may: 5.103,
            ic_june: 4.993,
            ic_july: 5.384,
            ic_august: 6.118,
            ic_september: 6.524,
            ic_october: 6.493,
            ic_november: 6.477,
            ic_december: 5.895,
            ic_yearly: 5.764,
          },
          {
            ic_uuid: "af61eede-cfdd-487f-9bfb-49f2aff347e4",
            ic_lat: -272646,
            ic_lon: -53865,
            ic_city: "Derrubadas",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.373,
            ic_february: 5.894,
            ic_march: 5.139,
            ic_april: 4.02,
            ic_may: 3.106,
            ic_june: 2.532,
            ic_july: 2.861,
            ic_august: 3.721,
            ic_september: 3.994,
            ic_october: 5.168,
            ic_november: 6.141,
            ic_december: 6.564,
            ic_yearly: 4.626,
          },
          {
            ic_uuid: "0ce15d7b-3de9-4a67-98d2-1f53466579c9",
            ic_lat: -219007,
            ic_lon: -476185,
            ic_city: "Descalvado",
            ic_states: "SÃO PAULO",
            ic_january: 5.632,
            ic_february: 5.888,
            ic_march: 5.127,
            ic_april: 4.758,
            ic_may: 4.003,
            ic_june: 3.731,
            ic_july: 3.921,
            ic_august: 4.824,
            ic_september: 4.946,
            ic_october: 5.544,
            ic_november: 5.701,
            ic_december: 6.005,
            ic_yearly: 5.007,
          },
          {
            ic_uuid: "f51c74ad-95b9-4871-9761-493330b13a6a",
            ic_lat: -268274,
            ic_lon: -535038,
            ic_city: "Descanso",
            ic_states: "SANTA CATARINA",
            ic_january: 6.32,
            ic_february: 5.764,
            ic_march: 5.069,
            ic_april: 4.091,
            ic_may: 3.162,
            ic_june: 2.68,
            ic_july: 2.977,
            ic_august: 3.823,
            ic_september: 4.095,
            ic_october: 5.146,
            ic_november: 6.018,
            ic_december: 6.431,
            ic_yearly: 4.631,
          },
          {
            ic_uuid: "d8f74d7d-44b3-48c1-bcca-20e7c3a152af",
            ic_lat: -214605,
            ic_lon: -429621,
            ic_city: "Descoberto",
            ic_states: "MINAS GERAIS",
            ic_january: 5.84,
            ic_february: 6.004,
            ic_march: 4.995,
            ic_april: 4.364,
            ic_may: 3.642,
            ic_june: 3.449,
            ic_july: 3.639,
            ic_august: 4.395,
            ic_september: 4.743,
            ic_october: 5.017,
            ic_november: 4.859,
            ic_december: 5.631,
            ic_yearly: 4.715,
          },
          {
            ic_uuid: "29bc9911-efb7-46ab-aa15-0f2879a255d3",
            ic_lat: -72874,
            ic_lon: -370928,
            ic_city: "Desterro",
            ic_states: "PARAÍBA",
            ic_january: 6.068,
            ic_february: 5.995,
            ic_march: 6.089,
            ic_april: 5.686,
            ic_may: 4.972,
            ic_june: 4.505,
            ic_july: 4.769,
            ic_august: 5.479,
            ic_september: 6.267,
            ic_october: 6.442,
            ic_november: 6.535,
            ic_december: 6.226,
            ic_yearly: 5.753,
          },
          {
            ic_uuid: "8331748d-99e1-4b1c-81a2-1c4dcacd4016",
            ic_lat: -206655,
            ic_lon: -443338,
            ic_city: "Desterro de Entre Rios",
            ic_states: "MINAS GERAIS",
            ic_january: 5.629,
            ic_february: 5.841,
            ic_march: 4.98,
            ic_april: 4.64,
            ic_may: 4.013,
            ic_june: 3.898,
            ic_july: 4.084,
            ic_august: 5.011,
            ic_september: 5.237,
            ic_october: 5.431,
            ic_november: 5.094,
            ic_december: 5.57,
            ic_yearly: 4.952,
          },
          {
            ic_uuid: "5e00d44b-cc4d-4002-bfe7-10e6af193675",
            ic_lat: -211435,
            ic_lon: -435182,
            ic_city: "Desterro do Melo",
            ic_states: "MINAS GERAIS",
            ic_january: 5.34,
            ic_february: 5.722,
            ic_march: 4.729,
            ic_april: 4.203,
            ic_may: 3.613,
            ic_june: 3.439,
            ic_july: 3.621,
            ic_august: 4.497,
            ic_september: 4.759,
            ic_october: 4.926,
            ic_november: 4.607,
            ic_december: 5.445,
            ic_yearly: 4.575,
          },
          {
            ic_uuid: "130240b3-82b6-42f1-8d76-940ebfce0fd7",
            ic_lat: -282252,
            ic_lon: -550482,
            ic_city: "Dezesseis de Novembro",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.547,
            ic_february: 6.073,
            ic_march: 5.274,
            ic_april: 4.058,
            ic_may: 3.095,
            ic_june: 2.531,
            ic_july: 2.843,
            ic_august: 3.6,
            ic_september: 4.069,
            ic_october: 5.172,
            ic_november: 6.405,
            ic_december: 6.796,
            ic_yearly: 4.705,
          },
          {
            ic_uuid: "21ce22af-fbb7-455e-8103-a998865a66c6",
            ic_lat: -236818,
            ic_lon: -466209,
            ic_city: "Diadema",
            ic_states: "SÃO PAULO",
            ic_january: 5.251,
            ic_february: 5.428,
            ic_march: 4.636,
            ic_april: 4.138,
            ic_may: 3.358,
            ic_june: 3.091,
            ic_july: 3.201,
            ic_august: 4.07,
            ic_september: 4.093,
            ic_october: 4.545,
            ic_november: 5.101,
            ic_december: 5.636,
            ic_yearly: 4.379,
          },
          {
            ic_uuid: "162d3023-26ee-4d3a-a312-5990cb1c17fc",
            ic_lat: -74279,
            ic_lon: -382659,
            ic_city: "Diamante",
            ic_states: "PARAÍBA",
            ic_january: 6.207,
            ic_february: 6.182,
            ic_march: 6.215,
            ic_april: 5.885,
            ic_may: 5.207,
            ic_june: 4.792,
            ic_july: 5.057,
            ic_august: 5.862,
            ic_september: 6.541,
            ic_october: 6.6,
            ic_november: 6.802,
            ic_december: 6.492,
            ic_yearly: 5.987,
          },
          {
            ic_uuid: "c6a0b5f4-239c-4a5a-b880-6ed9d0e44337",
            ic_lat: -22656,
            ic_lon: -5286,
            ic_city: "Diamante do Norte",
            ic_states: "PARANÁ",
            ic_january: 6.088,
            ic_february: 6.052,
            ic_march: 5.461,
            ic_april: 4.656,
            ic_may: 3.742,
            ic_june: 3.414,
            ic_july: 3.591,
            ic_august: 4.506,
            ic_september: 4.778,
            ic_october: 5.448,
            ic_november: 6.19,
            ic_december: 6.567,
            ic_yearly: 5.041,
          },
          {
            ic_uuid: "b78a76c8-02ea-402f-b00e-ee78236f504c",
            ic_lat: -250355,
            ic_lon: -526772,
            ic_city: "Diamante do Sul",
            ic_states: "PARANÁ",
            ic_january: 6.278,
            ic_february: 5.683,
            ic_march: 5.326,
            ic_april: 4.388,
            ic_may: 3.398,
            ic_june: 2.999,
            ic_july: 3.232,
            ic_august: 4.252,
            ic_september: 4.424,
            ic_october: 5.29,
            ic_november: 6.157,
            ic_december: 6.396,
            ic_yearly: 4.818,
          },
          {
            ic_uuid: "be76ef27-9bc8-422f-9d67-b7eaebac242f",
            ic_lat: -249423,
            ic_lon: -541056,
            ic_city: "Diamante D'Oeste",
            ic_states: "PARANÁ",
            ic_january: 6.292,
            ic_february: 5.772,
            ic_march: 5.332,
            ic_april: 4.278,
            ic_may: 3.307,
            ic_june: 2.911,
            ic_july: 3.137,
            ic_august: 4.149,
            ic_september: 4.377,
            ic_october: 5.314,
            ic_november: 6.152,
            ic_december: 6.565,
            ic_yearly: 4.799,
          },
          {
            ic_uuid: "bd76f30b-f0d2-4bf6-bbb7-19cf6c9be9d8",
            ic_lat: -182418,
            ic_lon: -436035,
            ic_city: "Diamantina",
            ic_states: "MINAS GERAIS",
            ic_january: 5.819,
            ic_february: 6.111,
            ic_march: 5.089,
            ic_april: 4.69,
            ic_may: 4.264,
            ic_june: 4.138,
            ic_july: 4.408,
            ic_august: 5.228,
            ic_september: 5.601,
            ic_october: 5.551,
            ic_november: 5.006,
            ic_december: 5.6,
            ic_yearly: 5.126,
          },
          {
            ic_uuid: "ee08fc6a-4af5-4dc7-8b0a-a0883231b074",
            ic_lat: -144008,
            ic_lon: -564375,
            ic_city: "Diamantino",
            ic_states: "MATO GROSSO",
            ic_january: 5.239,
            ic_february: 5.145,
            ic_march: 4.942,
            ic_april: 4.848,
            ic_may: 4.557,
            ic_june: 4.516,
            ic_july: 4.728,
            ic_august: 5.498,
            ic_september: 5.159,
            ic_october: 5.239,
            ic_november: 5.302,
            ic_december: 5.453,
            ic_yearly: 5.052,
          },
          {
            ic_uuid: "b28d9310-5959-43ab-85cc-7536e7b5480c",
            ic_lat: -116244,
            ic_lon: -468202,
            ic_city: "Dianópolis",
            ic_states: "TOCANTINS",
            ic_january: 5.558,
            ic_february: 5.398,
            ic_march: 5.092,
            ic_april: 5.122,
            ic_may: 5.249,
            ic_june: 5.183,
            ic_july: 5.489,
            ic_august: 6.147,
            ic_september: 6.216,
            ic_october: 5.697,
            ic_november: 5.354,
            ic_december: 5.433,
            ic_yearly: 5.495,
          },
          {
            ic_uuid: "5d859776-7a7a-4885-99e1-b40c63cc9c5b",
            ic_lat: -126192,
            ic_lon: -38293,
            ic_city: "Dias d'Ávila",
            ic_states: "BAHIA",
            ic_january: 6.214,
            ic_february: 6.122,
            ic_march: 5.852,
            ic_april: 4.673,
            ic_may: 3.969,
            ic_june: 3.722,
            ic_july: 3.948,
            ic_august: 4.46,
            ic_september: 5.323,
            ic_october: 5.726,
            ic_november: 6.021,
            ic_december: 6.329,
            ic_yearly: 5.197,
          },
          {
            ic_uuid: "da5c607d-9f44-4d29-8f05-dc854bc83256",
            ic_lat: -297059,
            ic_lon: -542127,
            ic_city: "Dilermando de Aguiar",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.575,
            ic_february: 6.068,
            ic_march: 5.071,
            ic_april: 3.919,
            ic_may: 2.858,
            ic_june: 2.374,
            ic_july: 2.614,
            ic_august: 3.373,
            ic_september: 3.865,
            ic_october: 4.998,
            ic_november: 6.32,
            ic_december: 6.936,
            ic_yearly: 4.581,
          },
          {
            ic_uuid: "89dafc30-df71-410f-aced-2582a75e012a",
            ic_lat: -204884,
            ic_lon: -431956,
            ic_city: "Diogo de Vasconcelos",
            ic_states: "MINAS GERAIS",
            ic_january: 5.575,
            ic_february: 5.867,
            ic_march: 4.89,
            ic_april: 4.391,
            ic_may: 3.754,
            ic_june: 3.564,
            ic_july: 3.771,
            ic_august: 4.599,
            ic_september: 4.836,
            ic_october: 4.947,
            ic_november: 4.688,
            ic_december: 5.336,
            ic_yearly: 4.685,
          },
          {
            ic_uuid: "d3432b64-6532-4c55-8e60-5da296f13a51",
            ic_lat: -19843,
            ic_lon: -42777,
            ic_city: "Dionísio",
            ic_states: "MINAS GERAIS",
            ic_january: 5.555,
            ic_february: 5.896,
            ic_march: 4.869,
            ic_april: 4.502,
            ic_may: 3.801,
            ic_june: 3.617,
            ic_july: 3.838,
            ic_august: 4.507,
            ic_september: 4.8,
            ic_october: 4.966,
            ic_november: 4.677,
            ic_december: 5.41,
            ic_yearly: 4.703,
          },
          {
            ic_uuid: "a3cc61b7-c164-45db-bea1-e6bbb18a0d34",
            ic_lat: -262652,
            ic_lon: -536356,
            ic_city: "Dionísio Cerqueira",
            ic_states: "SANTA CATARINA",
            ic_january: 6.337,
            ic_february: 5.812,
            ic_march: 5.172,
            ic_april: 4.136,
            ic_may: 3.214,
            ic_june: 2.763,
            ic_july: 3.052,
            ic_august: 3.964,
            ic_september: 4.225,
            ic_october: 5.215,
            ic_november: 6.044,
            ic_december: 6.363,
            ic_yearly: 4.692,
          },
          {
            ic_uuid: "272debae-5eb4-4a30-a0ec-5087620cf0dc",
            ic_lat: -162339,
            ic_lon: -512586,
            ic_city: "Diorama",
            ic_states: "GOIÁS",
            ic_january: 5.567,
            ic_february: 5.59,
            ic_march: 5.263,
            ic_april: 5.3,
            ic_may: 4.864,
            ic_june: 4.574,
            ic_july: 4.634,
            ic_august: 5.411,
            ic_september: 5.42,
            ic_october: 5.532,
            ic_november: 5.378,
            ic_december: 5.537,
            ic_yearly: 5.256,
          },
          {
            ic_uuid: "47f01a76-5113-4374-b6dc-4b8ea3e3bcd2",
            ic_lat: -204628,
            ic_lon: -506102,
            ic_city: "Dirce Reis",
            ic_states: "SÃO PAULO",
            ic_january: 5.82,
            ic_february: 5.999,
            ic_march: 5.337,
            ic_april: 4.85,
            ic_may: 4.156,
            ic_june: 3.894,
            ic_july: 4.128,
            ic_august: 5.053,
            ic_september: 5.078,
            ic_october: 5.663,
            ic_november: 5.968,
            ic_december: 6.326,
            ic_yearly: 5.189,
          },
          {
            ic_uuid: "8171c7c2-b401-4261-ab67-6e8add866b4f",
            ic_lat: -93398,
            ic_lon: -424351,
            ic_city: "Dirceu Arcoverde",
            ic_states: "PIAUÍ",
            ic_january: 6.067,
            ic_february: 5.801,
            ic_march: 5.65,
            ic_april: 5.439,
            ic_may: 5.255,
            ic_june: 5.205,
            ic_july: 5.576,
            ic_august: 6.338,
            ic_september: 6.573,
            ic_october: 6.511,
            ic_november: 6.308,
            ic_december: 6.103,
            ic_yearly: 5.902,
          },
          {
            ic_uuid: "566500cf-6846-422c-800e-9e4388d92695",
            ic_lat: -106786,
            ic_lon: -371509,
            ic_city: "Divina Pastora",
            ic_states: "SERGIPE",
            ic_january: 6.16,
            ic_february: 6.086,
            ic_march: 5.954,
            ic_april: 5.046,
            ic_may: 4.319,
            ic_june: 4.007,
            ic_july: 4.127,
            ic_august: 4.645,
            ic_september: 5.406,
            ic_october: 5.772,
            ic_november: 6.251,
            ic_december: 6.247,
            ic_yearly: 5.335,
          },
          {
            ic_uuid: "55bc2daa-caf0-4705-9b3c-40e35dd121e6",
            ic_lat: -209922,
            ic_lon: -430006,
            ic_city: "Divinésia",
            ic_states: "MINAS GERAIS",
            ic_january: 5.601,
            ic_february: 5.86,
            ic_march: 4.846,
            ic_april: 4.245,
            ic_may: 3.641,
            ic_june: 3.532,
            ic_july: 3.702,
            ic_august: 4.47,
            ic_september: 4.777,
            ic_october: 4.988,
            ic_november: 4.76,
            ic_december: 5.446,
            ic_yearly: 4.656,
          },
          {
            ic_uuid: "59b80490-91a9-41bf-8a2c-a8f84a5b45cd",
            ic_lat: -206139,
            ic_lon: -421442,
            ic_city: "Divino",
            ic_states: "MINAS GERAIS",
            ic_january: 5.784,
            ic_february: 6.034,
            ic_march: 4.966,
            ic_april: 4.479,
            ic_may: 3.833,
            ic_june: 3.707,
            ic_july: 3.862,
            ic_august: 4.61,
            ic_september: 5.029,
            ic_october: 5.112,
            ic_november: 4.782,
            ic_december: 5.579,
            ic_yearly: 4.815,
          },
          {
            ic_uuid: "26f56046-cad8-4564-97ad-1e20a200994d",
            ic_lat: -18776,
            ic_lon: -414784,
            ic_city: "Divino das Laranjeiras",
            ic_states: "MINAS GERAIS",
            ic_january: 6.042,
            ic_february: 6.172,
            ic_march: 5.261,
            ic_april: 4.596,
            ic_may: 3.885,
            ic_june: 3.642,
            ic_july: 3.736,
            ic_august: 4.384,
            ic_september: 4.889,
            ic_october: 5.141,
            ic_november: 4.961,
            ic_december: 5.759,
            ic_yearly: 4.872,
          },
          {
            ic_uuid: "190f1d89-3a74-4c16-8d00-8ca47b7488d1",
            ic_lat: -206207,
            ic_lon: -416835,
            ic_city: "Divino de São Lourenço",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.913,
            ic_february: 6.157,
            ic_march: 4.955,
            ic_april: 4.354,
            ic_may: 3.755,
            ic_june: 3.654,
            ic_july: 3.793,
            ic_august: 4.547,
            ic_september: 4.96,
            ic_october: 5.012,
            ic_november: 4.811,
            ic_december: 5.522,
            ic_yearly: 4.786,
          },
          {
            ic_uuid: "50ca01f8-6f25-4bce-b3c2-01270bdf2157",
            ic_lat: -216642,
            ic_lon: -467365,
            ic_city: "Divinolândia",
            ic_states: "SÃO PAULO",
            ic_january: 5.585,
            ic_february: 5.755,
            ic_march: 5.026,
            ic_april: 4.779,
            ic_may: 4.038,
            ic_june: 3.84,
            ic_july: 4.037,
            ic_august: 4.918,
            ic_september: 5.05,
            ic_october: 5.5,
            ic_november: 5.646,
            ic_december: 5.85,
            ic_yearly: 5.002,
          },
          {
            ic_uuid: "a7070858-4846-4433-9d09-9bc90e309a7d",
            ic_lat: -188053,
            ic_lon: -426218,
            ic_city: "Divinolândia de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.629,
            ic_february: 5.872,
            ic_march: 4.861,
            ic_april: 4.341,
            ic_may: 3.73,
            ic_june: 3.606,
            ic_july: 3.748,
            ic_august: 4.411,
            ic_september: 4.912,
            ic_october: 5.048,
            ic_november: 4.653,
            ic_december: 5.417,
            ic_yearly: 4.685,
          },
          {
            ic_uuid: "cce0b503-63d0-4fdc-a676-04c2ec568894",
            ic_lat: -201451,
            ic_lon: -448916,
            ic_city: "Divinópolis",
            ic_states: "MINAS GERAIS",
            ic_january: 5.878,
            ic_february: 6.03,
            ic_march: 5.144,
            ic_april: 4.917,
            ic_may: 4.282,
            ic_june: 4.109,
            ic_july: 4.338,
            ic_august: 5.246,
            ic_september: 5.476,
            ic_october: 5.583,
            ic_november: 5.331,
            ic_december: 5.646,
            ic_yearly: 5.165,
          },
          {
            ic_uuid: "2ffb21d1-6832-4fb9-bc46-9a0985d69235",
            ic_lat: -132917,
            ic_lon: -463958,
            ic_city: "Divinópolis de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.902,
            ic_february: 5.768,
            ic_march: 5.237,
            ic_april: 5.186,
            ic_may: 5.059,
            ic_june: 5.065,
            ic_july: 5.446,
            ic_august: 5.984,
            ic_september: 6.058,
            ic_october: 5.731,
            ic_november: 5.123,
            ic_december: 5.667,
            ic_yearly: 5.519,
          },
          {
            ic_uuid: "19b9104c-8935-45ed-b735-f9234b87c9e3",
            ic_lat: -98006,
            ic_lon: -492172,
            ic_city: "Divinópolis do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 4.984,
            ic_february: 4.993,
            ic_march: 4.74,
            ic_april: 4.738,
            ic_may: 4.92,
            ic_june: 5.072,
            ic_july: 5.373,
            ic_august: 5.942,
            ic_september: 5.602,
            ic_october: 5.236,
            ic_november: 5.089,
            ic_december: 4.98,
            ic_yearly: 5.139,
          },
          {
            ic_uuid: "08cef23a-8358-4886-82ae-3d65ed02ec3c",
            ic_lat: -157225,
            ic_lon: -413466,
            ic_city: "Divisa Alegre",
            ic_states: "MINAS GERAIS",
            ic_january: 5.968,
            ic_february: 6.154,
            ic_march: 5.377,
            ic_april: 4.688,
            ic_may: 4.071,
            ic_june: 3.659,
            ic_july: 3.885,
            ic_august: 4.544,
            ic_september: 5.467,
            ic_october: 5.537,
            ic_november: 5.277,
            ic_december: 6.015,
            ic_yearly: 5.053,
          },
          {
            ic_uuid: "e79c1753-9499-4929-83b7-a1bba25b1d4d",
            ic_lat: -215096,
            ic_lon: -461908,
            ic_city: "Divisa Nova",
            ic_states: "MINAS GERAIS",
            ic_january: 5.592,
            ic_february: 5.776,
            ic_march: 4.912,
            ic_april: 4.614,
            ic_may: 3.995,
            ic_june: 3.715,
            ic_july: 3.986,
            ic_august: 4.935,
            ic_september: 5.076,
            ic_october: 5.404,
            ic_november: 5.296,
            ic_december: 5.63,
            ic_yearly: 4.911,
          },
          {
            ic_uuid: "237c8f3d-b625-4a11-8095-f0b57063c0c9",
            ic_lat: -157258,
            ic_lon: -41,
            ic_city: "Divisópolis",
            ic_states: "MINAS GERAIS",
            ic_january: 5.953,
            ic_february: 6.051,
            ic_march: 5.374,
            ic_april: 4.659,
            ic_may: 4.014,
            ic_june: 3.599,
            ic_july: 3.855,
            ic_august: 4.468,
            ic_september: 5.322,
            ic_october: 5.418,
            ic_november: 5.217,
            ic_december: 6.008,
            ic_yearly: 4.995,
          },
          {
            ic_uuid: "cf23d0a5-26e8-4cad-a290-28f56031fbec",
            ic_lat: -21516,
            ic_lon: -483939,
            ic_city: "Dobrada",
            ic_states: "SÃO PAULO",
            ic_january: 5.735,
            ic_february: 5.899,
            ic_march: 5.142,
            ic_april: 4.772,
            ic_may: 4.054,
            ic_june: 3.814,
            ic_july: 4.007,
            ic_august: 4.871,
            ic_september: 4.984,
            ic_october: 5.576,
            ic_november: 5.817,
            ic_december: 6.094,
            ic_yearly: 5.064,
          },
          {
            ic_uuid: "988d0fdf-29a9-4b3f-ba81-fced6f54b47d",
            ic_lat: -223678,
            ic_lon: -483823,
            ic_city: "Dois Córregos",
            ic_states: "SÃO PAULO",
            ic_january: 5.561,
            ic_february: 5.809,
            ic_march: 5.13,
            ic_april: 4.682,
            ic_may: 3.844,
            ic_june: 3.582,
            ic_july: 3.719,
            ic_august: 4.718,
            ic_september: 4.874,
            ic_october: 5.513,
            ic_november: 5.855,
            ic_december: 6.114,
            ic_yearly: 4.95,
          },
          {
            ic_uuid: "49e6e25c-c5d5-4809-bc00-08baaac3988e",
            ic_lat: -29584,
            ic_lon: -510902,
            ic_city: "Dois Irmãos",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.2,
            ic_february: 5.688,
            ic_march: 4.72,
            ic_april: 3.753,
            ic_may: 2.808,
            ic_june: 2.336,
            ic_july: 2.535,
            ic_august: 3.214,
            ic_september: 3.508,
            ic_october: 4.601,
            ic_november: 6.035,
            ic_december: 6.486,
            ic_yearly: 4.323,
          },
          {
            ic_uuid: "10dcde60-e8a4-434a-9875-bc068ca38b68",
            ic_lat: -276626,
            ic_lon: -535309,
            ic_city: "Dois Irmãos das Missões",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.448,
            ic_february: 5.922,
            ic_march: 5.068,
            ic_april: 4.08,
            ic_may: 3.117,
            ic_june: 2.579,
            ic_july: 2.927,
            ic_august: 3.706,
            ic_september: 3.989,
            ic_october: 5.17,
            ic_november: 6.193,
            ic_december: 6.642,
            ic_yearly: 4.653,
          },
          {
            ic_uuid: "3c91d923-0b37-4068-9a95-1fb1e1d0304e",
            ic_lat: -206902,
            ic_lon: -552824,
            ic_city: "Dois Irmãos do Buriti",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.759,
            ic_february: 5.792,
            ic_march: 5.413,
            ic_april: 4.79,
            ic_may: 3.921,
            ic_june: 3.677,
            ic_july: 3.813,
            ic_august: 4.656,
            ic_september: 4.903,
            ic_october: 5.502,
            ic_november: 6.012,
            ic_december: 6.247,
            ic_yearly: 5.04,
          },
          {
            ic_uuid: "51c72c03-e977-43f0-b228-82d333e698f8",
            ic_lat: -92558,
            ic_lon: -490641,
            ic_city: "Dois Irmãos do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 4.936,
            ic_february: 4.944,
            ic_march: 4.755,
            ic_april: 4.743,
            ic_may: 5.025,
            ic_june: 5.079,
            ic_july: 5.315,
            ic_august: 5.961,
            ic_september: 5.656,
            ic_october: 5.228,
            ic_november: 5.048,
            ic_december: 4.925,
            ic_yearly: 5.135,
          },
          {
            ic_uuid: "06f71095-b321-44ea-a968-26426ec934f5",
            ic_lat: -289835,
            ic_lon: -5184,
            ic_city: "Dois Lajeados",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.178,
            ic_february: 5.773,
            ic_march: 4.857,
            ic_april: 3.844,
            ic_may: 2.854,
            ic_june: 2.369,
            ic_july: 2.686,
            ic_august: 3.398,
            ic_september: 3.687,
            ic_october: 4.739,
            ic_november: 6.104,
            ic_december: 6.47,
            ic_yearly: 4.413,
          },
          {
            ic_uuid: "e55c8cf4-a9be-4c9c-b9e1-61018118726d",
            ic_lat: -93874,
            ic_lon: -370896,
            ic_city: "Dois Riachos",
            ic_states: "ALAGOAS",
            ic_january: 6.185,
            ic_february: 5.924,
            ic_march: 5.955,
            ic_april: 5.239,
            ic_may: 4.381,
            ic_june: 3.964,
            ic_july: 4.119,
            ic_august: 4.744,
            ic_september: 5.595,
            ic_october: 5.979,
            ic_november: 6.487,
            ic_december: 6.412,
            ic_yearly: 5.415,
          },
          {
            ic_uuid: "f97cb2ee-d83a-4722-8258-59a17ea9a577",
            ic_lat: -257412,
            ic_lon: -530575,
            ic_city: "Dois Vizinhos",
            ic_states: "PARANÁ",
            ic_january: 6.371,
            ic_february: 5.79,
            ic_march: 5.269,
            ic_april: 4.276,
            ic_may: 3.28,
            ic_june: 2.835,
            ic_july: 3.104,
            ic_august: 4.091,
            ic_september: 4.286,
            ic_october: 5.259,
            ic_november: 6.194,
            ic_december: 6.461,
            ic_yearly: 4.768,
          },
          {
            ic_uuid: "27df2d1d-2fb6-42b2-bfe8-920729b105fa",
            ic_lat: -20123,
            ic_lon: -505129,
            ic_city: "Dolcinópolis",
            ic_states: "SÃO PAULO",
            ic_january: 5.79,
            ic_february: 5.886,
            ic_march: 5.338,
            ic_april: 4.893,
            ic_may: 4.242,
            ic_june: 3.983,
            ic_july: 4.183,
            ic_august: 5.128,
            ic_september: 5.097,
            ic_october: 5.664,
            ic_november: 5.88,
            ic_december: 6.228,
            ic_yearly: 5.193,
          },
          {
            ic_uuid: "74e75492-c451-44fb-847f-4ea4e2cd2632",
            ic_lat: -158099,
            ic_lon: -549222,
            ic_city: "Dom Aquino",
            ic_states: "MATO GROSSO",
            ic_january: 5.328,
            ic_february: 5.263,
            ic_march: 5.073,
            ic_april: 5.098,
            ic_may: 4.659,
            ic_june: 4.575,
            ic_july: 4.667,
            ic_august: 5.589,
            ic_september: 5.124,
            ic_october: 5.09,
            ic_november: 5.326,
            ic_december: 5.477,
            ic_yearly: 5.106,
          },
          {
            ic_uuid: "bfb07984-10b2-4b2e-b094-791bab69dbae",
            ic_lat: -137616,
            ic_lon: -417707,
            ic_city: "Dom Basílio",
            ic_states: "BAHIA",
            ic_january: 6.482,
            ic_february: 6.507,
            ic_march: 5.902,
            ic_april: 5.178,
            ic_may: 4.523,
            ic_june: 4.213,
            ic_july: 4.503,
            ic_august: 5.166,
            ic_september: 5.998,
            ic_october: 6.302,
            ic_november: 6.002,
            ic_december: 6.543,
            ic_yearly: 5.61,
          },
          {
            ic_uuid: "88f09270-7025-445f-b457-7bf0cbd4ee48",
            ic_lat: -166521,
            ic_lon: -462652,
            ic_city: "Dom Bosco",
            ic_states: "MINAS GERAIS",
            ic_january: 6.073,
            ic_february: 6.291,
            ic_march: 5.43,
            ic_april: 5.325,
            ic_may: 4.921,
            ic_june: 4.624,
            ic_july: 5.026,
            ic_august: 5.845,
            ic_september: 5.821,
            ic_october: 5.884,
            ic_november: 5.315,
            ic_december: 5.872,
            ic_yearly: 5.536,
          },
          {
            ic_uuid: "b31d0e4b-8027-4a5a-b24d-9b6342bdc8ee",
            ic_lat: -193695,
            ic_lon: -421142,
            ic_city: "Dom Cavati",
            ic_states: "MINAS GERAIS",
            ic_january: 5.825,
            ic_february: 6.013,
            ic_march: 5.12,
            ic_april: 4.561,
            ic_may: 3.88,
            ic_june: 3.636,
            ic_july: 3.777,
            ic_august: 4.431,
            ic_september: 4.831,
            ic_october: 5.024,
            ic_november: 4.823,
            ic_december: 5.735,
            ic_yearly: 4.805,
          },
          {
            ic_uuid: "dbbdef91-5d15-48d2-bad5-59f230a432b6",
            ic_lat: -42962,
            ic_lon: -475514,
            ic_city: "Dom Eliseu",
            ic_states: "PARÁ",
            ic_january: 4.452,
            ic_february: 4.665,
            ic_march: 4.652,
            ic_april: 4.771,
            ic_may: 4.677,
            ic_june: 4.866,
            ic_july: 4.908,
            ic_august: 5.187,
            ic_september: 5.222,
            ic_october: 4.873,
            ic_november: 4.595,
            ic_december: 4.49,
            ic_yearly: 4.78,
          },
          {
            ic_uuid: "21e90c96-7ee8-49de-b785-ca4b93d3aeb3",
            ic_lat: -69591,
            ic_lon: -416481,
            ic_city: "Dom Expedito Lopes",
            ic_states: "PIAUÍ",
            ic_january: 5.468,
            ic_february: 5.349,
            ic_march: 5.452,
            ic_april: 5.354,
            ic_may: 5.25,
            ic_june: 5.198,
            ic_july: 5.652,
            ic_august: 6.347,
            ic_september: 6.759,
            ic_october: 6.546,
            ic_november: 6.327,
            ic_december: 5.899,
            ic_yearly: 5.8,
          },
          {
            ic_uuid: "77b49516-c862-4813-99bc-4dc5af5acb1e",
            ic_lat: -307008,
            ic_lon: -521031,
            ic_city: "Dom Feliciano",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.213,
            ic_february: 5.633,
            ic_march: 4.747,
            ic_april: 3.729,
            ic_may: 2.719,
            ic_june: 2.277,
            ic_july: 2.508,
            ic_august: 3.129,
            ic_september: 3.559,
            ic_october: 4.701,
            ic_november: 6.066,
            ic_december: 6.584,
            ic_yearly: 4.322,
          },
          {
            ic_uuid: "1268d911-d2c3-4b61-a3ef-b8bb3d4375d5",
            ic_lat: -90054,
            ic_lon: -419737,
            ic_city: "Dom Inocêncio",
            ic_states: "PIAUÍ",
            ic_january: 6.145,
            ic_february: 5.945,
            ic_march: 5.792,
            ic_april: 5.388,
            ic_may: 5.246,
            ic_june: 5.21,
            ic_july: 5.604,
            ic_august: 6.352,
            ic_september: 6.538,
            ic_october: 6.617,
            ic_november: 6.343,
            ic_december: 6.17,
            ic_yearly: 5.946,
          },
          {
            ic_uuid: "fba5ebd9-5109-4294-9b20-7f6ba18fdce1",
            ic_lat: -189615,
            ic_lon: -432547,
            ic_city: "Dom Joaquim",
            ic_states: "MINAS GERAIS",
            ic_january: 5.708,
            ic_february: 6.001,
            ic_march: 5.03,
            ic_april: 4.534,
            ic_may: 3.887,
            ic_june: 3.7,
            ic_july: 3.888,
            ic_august: 4.677,
            ic_september: 5.015,
            ic_october: 5.179,
            ic_november: 4.868,
            ic_december: 5.531,
            ic_yearly: 4.835,
          },
          {
            ic_uuid: "9abef1c9-e610-4c14-b99e-b987afcaea9d",
            ic_lat: -12906,
            ic_lon: -391921,
            ic_city: "Dom Macedo Costa",
            ic_states: "BAHIA",
            ic_january: 6.051,
            ic_february: 5.939,
            ic_march: 5.714,
            ic_april: 4.635,
            ic_may: 4.043,
            ic_june: 3.628,
            ic_july: 3.83,
            ic_august: 4.386,
            ic_september: 5.194,
            ic_october: 5.385,
            ic_november: 5.641,
            ic_december: 6.108,
            ic_yearly: 5.046,
          },
          {
            ic_uuid: "255e39ee-2570-4b63-bc37-e2654ff4199c",
            ic_lat: -309793,
            ic_lon: -546702,
            ic_city: "Dom Pedrito",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.698,
            ic_february: 6.003,
            ic_march: 5.108,
            ic_april: 3.882,
            ic_may: 2.791,
            ic_june: 2.352,
            ic_july: 2.583,
            ic_august: 3.291,
            ic_september: 3.871,
            ic_october: 5.253,
            ic_november: 6.458,
            ic_december: 7.014,
            ic_yearly: 4.608,
          },
          {
            ic_uuid: "0c64fd81-1793-4b74-b7f4-0b05807cdaf5",
            ic_lat: -50356,
            ic_lon: -444412,
            ic_city: "Dom Pedro",
            ic_states: "MARANHÃO",
            ic_january: 4.779,
            ic_february: 4.879,
            ic_march: 4.874,
            ic_april: 4.807,
            ic_may: 4.918,
            ic_june: 5.108,
            ic_july: 5.427,
            ic_august: 6.03,
            ic_september: 6.301,
            ic_october: 5.812,
            ic_november: 5.379,
            ic_december: 4.982,
            ic_yearly: 5.275,
          },
          {
            ic_uuid: "7ca4ce5c-6851-47f0-b760-adbda60906e7",
            ic_lat: -293695,
            ic_lon: -498497,
            ic_city: "Dom Pedro de Alcântara",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 5.656,
            ic_february: 5.284,
            ic_march: 4.456,
            ic_april: 3.799,
            ic_may: 2.925,
            ic_june: 2.43,
            ic_july: 2.646,
            ic_august: 3.311,
            ic_september: 3.475,
            ic_october: 4.21,
            ic_november: 5.604,
            ic_december: 5.918,
            ic_yearly: 4.143,
          },
          {
            ic_uuid: "b30e5dee-d0fa-46b5-9939-0b2a21ac7d5d",
            ic_lat: -201632,
            ic_lon: -42963,
            ic_city: "Dom Silvério",
            ic_states: "MINAS GERAIS",
            ic_january: 5.548,
            ic_february: 5.875,
            ic_march: 4.899,
            ic_april: 4.443,
            ic_may: 3.799,
            ic_june: 3.632,
            ic_july: 3.82,
            ic_august: 4.628,
            ic_september: 4.901,
            ic_october: 5.012,
            ic_november: 4.731,
            ic_december: 5.404,
            ic_yearly: 4.725,
          },
          {
            ic_uuid: "c9457b4d-f4a3-4ce1-a5ef-72c092914487",
            ic_lat: -222516,
            ic_lon: -451647,
            ic_city: "Dom Viçoso",
            ic_states: "MINAS GERAIS",
            ic_january: 5.077,
            ic_february: 5.483,
            ic_march: 4.663,
            ic_april: 4.381,
            ic_may: 3.815,
            ic_june: 3.493,
            ic_july: 3.771,
            ic_august: 4.687,
            ic_september: 4.886,
            ic_october: 5.203,
            ic_november: 5.04,
            ic_december: 5.442,
            ic_yearly: 4.662,
          },
          {
            ic_uuid: "c16362fc-541a-49a6-bbeb-87c16907b920",
            ic_lat: -203608,
            ic_lon: -406598,
            ic_city: "Domingos Martins",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.853,
            ic_february: 6.143,
            ic_march: 5.056,
            ic_april: 4.335,
            ic_may: 3.734,
            ic_june: 3.509,
            ic_july: 3.569,
            ic_august: 4.214,
            ic_september: 4.695,
            ic_october: 4.755,
            ic_november: 4.689,
            ic_december: 5.527,
            ic_yearly: 4.673,
          },
          {
            ic_uuid: "cef39f6f-4cd9-4454-a56d-55b185aee176",
            ic_lat: -42535,
            ic_lon: -412714,
            ic_city: "Domingos Mourão",
            ic_states: "PIAUÍ",
            ic_january: 5.122,
            ic_february: 5.27,
            ic_march: 5.318,
            ic_april: 5.034,
            ic_may: 5.077,
            ic_june: 5.117,
            ic_july: 5.392,
            ic_august: 6.049,
            ic_september: 6.547,
            ic_october: 6.538,
            ic_november: 6.406,
            ic_december: 5.666,
            ic_yearly: 5.628,
          },
          {
            ic_uuid: "d17cbe85-a34f-40a9-8385-32c47f67bb07",
            ic_lat: -269815,
            ic_lon: -497266,
            ic_city: "Dona Emma",
            ic_states: "SANTA CATARINA",
            ic_january: 5.448,
            ic_february: 5.187,
            ic_march: 4.56,
            ic_april: 3.61,
            ic_may: 2.859,
            ic_june: 2.333,
            ic_july: 2.543,
            ic_august: 3.363,
            ic_september: 3.497,
            ic_october: 4.107,
            ic_november: 5.315,
            ic_december: 5.6,
            ic_yearly: 4.035,
          },
          {
            ic_uuid: "110f4cce-74b8-4ac1-87f1-4529e76ad734",
            ic_lat: -213195,
            ic_lon: -428074,
            ic_city: "Dona Eusébia",
            ic_states: "MINAS GERAIS",
            ic_january: 5.855,
            ic_february: 6.096,
            ic_march: 5.111,
            ic_april: 4.43,
            ic_may: 3.71,
            ic_june: 3.524,
            ic_july: 3.7,
            ic_august: 4.459,
            ic_september: 4.852,
            ic_october: 5.171,
            ic_november: 5.009,
            ic_december: 5.733,
            ic_yearly: 4.804,
          },
          {
            ic_uuid: "f1d004a2-1a75-4e72-8794-0e99c33915ed",
            ic_lat: -29622,
            ic_lon: -533569,
            ic_city: "Dona Francisca",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.35,
            ic_february: 5.891,
            ic_march: 4.99,
            ic_april: 3.85,
            ic_may: 2.825,
            ic_june: 2.346,
            ic_july: 2.585,
            ic_august: 3.338,
            ic_september: 3.701,
            ic_october: 4.886,
            ic_november: 6.23,
            ic_december: 6.733,
            ic_yearly: 4.477,
          },
          {
            ic_uuid: "48a2a4f4-1aa5-4a62-a05b-0764aa59dd32",
            ic_lat: -66032,
            ic_lon: -356274,
            ic_city: "Dona Inês",
            ic_states: "PARAÍBA",
            ic_january: 5.618,
            ic_february: 5.757,
            ic_march: 5.828,
            ic_april: 5.352,
            ic_may: 4.821,
            ic_june: 4.269,
            ic_july: 4.454,
            ic_august: 5.197,
            ic_september: 5.579,
            ic_october: 5.784,
            ic_november: 5.984,
            ic_december: 5.731,
            ic_yearly: 5.364,
          },
          {
            ic_uuid: "9b909bcd-167e-4c9a-a071-8fd4bdd2525c",
            ic_lat: -211144,
            ic_lon: -440211,
            ic_city: "Dores de Campos",
            ic_states: "MINAS GERAIS",
            ic_january: 5.551,
            ic_february: 5.818,
            ic_march: 4.842,
            ic_april: 4.404,
            ic_may: 3.808,
            ic_june: 3.631,
            ic_july: 3.864,
            ic_august: 4.789,
            ic_september: 4.962,
            ic_october: 5.164,
            ic_november: 4.956,
            ic_december: 5.497,
            ic_yearly: 4.774,
          },
          {
            ic_uuid: "7b02a480-d36a-47ac-8b8a-0ea5b9e9eb0a",
            ic_lat: -190521,
            ic_lon: -429257,
            ic_city: "Dores de Guanhães",
            ic_states: "MINAS GERAIS",
            ic_january: 5.758,
            ic_february: 5.982,
            ic_march: 4.962,
            ic_april: 4.43,
            ic_may: 3.843,
            ic_june: 3.68,
            ic_july: 3.879,
            ic_august: 4.572,
            ic_september: 4.982,
            ic_october: 5.102,
            ic_november: 4.781,
            ic_december: 5.489,
            ic_yearly: 4.788,
          },
          {
            ic_uuid: "3d65500c-47fe-4886-81d0-08760a0978bb",
            ic_lat: -194646,
            ic_lon: -456001,
            ic_city: "Dores do Indaiá",
            ic_states: "MINAS GERAIS",
            ic_january: 5.849,
            ic_february: 6.145,
            ic_march: 5.129,
            ic_april: 4.971,
            ic_may: 4.557,
            ic_june: 4.309,
            ic_july: 4.549,
            ic_august: 5.528,
            ic_september: 5.612,
            ic_october: 5.649,
            ic_november: 5.283,
            ic_december: 5.673,
            ic_yearly: 5.271,
          },
          {
            ic_uuid: "2780ab39-4983-43b6-a6d9-3547b7409fef",
            ic_lat: -206936,
            ic_lon: -418409,
            ic_city: "Dores do Rio Preto",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.893,
            ic_february: 6.041,
            ic_march: 4.897,
            ic_april: 4.404,
            ic_may: 3.806,
            ic_june: 3.707,
            ic_july: 3.824,
            ic_august: 4.612,
            ic_september: 4.963,
            ic_october: 5.071,
            ic_november: 4.781,
            ic_december: 5.603,
            ic_yearly: 4.8,
          },
          {
            ic_uuid: "f46151b6-531d-47af-9fb5-6e59e3b6bea7",
            ic_lat: -20979,
            ic_lon: -431838,
            ic_city: "Dores do Turvo",
            ic_states: "MINAS GERAIS",
            ic_january: 5.579,
            ic_february: 5.844,
            ic_march: 4.816,
            ic_april: 4.254,
            ic_may: 3.641,
            ic_june: 3.515,
            ic_july: 3.706,
            ic_august: 4.467,
            ic_september: 4.778,
            ic_october: 4.985,
            ic_november: 4.744,
            ic_december: 5.457,
            ic_yearly: 4.649,
          },
          {
            ic_uuid: "d8234abe-c434-4838-b449-3389ae9ac956",
            ic_lat: -202873,
            ic_lon: -459011,
            ic_city: "Doresópolis",
            ic_states: "MINAS GERAIS",
            ic_january: 5.673,
            ic_february: 5.984,
            ic_march: 4.977,
            ic_april: 4.872,
            ic_may: 4.352,
            ic_june: 4.096,
            ic_july: 4.361,
            ic_august: 5.316,
            ic_september: 5.377,
            ic_october: 5.512,
            ic_november: 5.162,
            ic_december: 5.599,
            ic_yearly: 5.107,
          },
          {
            ic_uuid: "2edde42f-3a6b-40ef-a668-4939b4825adf",
            ic_lat: -84416,
            ic_lon: -407665,
            ic_city: "Dormentes",
            ic_states: "PERNAMBUCO",
            ic_january: 6.003,
            ic_february: 5.744,
            ic_march: 5.722,
            ic_april: 5.184,
            ic_may: 4.8,
            ic_june: 4.646,
            ic_july: 4.999,
            ic_august: 5.934,
            ic_september: 6.549,
            ic_october: 6.507,
            ic_november: 6.41,
            ic_december: 6.126,
            ic_yearly: 5.719,
          },
          {
            ic_uuid: "14fe1c65-2dcf-41d1-942b-e99f8d8081f9",
            ic_lat: -2204,
            ic_lon: -546129,
            ic_city: "Douradina",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.962,
            ic_february: 5.807,
            ic_march: 5.356,
            ic_april: 4.588,
            ic_may: 3.751,
            ic_june: 3.414,
            ic_july: 3.545,
            ic_august: 4.472,
            ic_september: 4.772,
            ic_october: 5.399,
            ic_november: 5.994,
            ic_december: 6.414,
            ic_yearly: 4.956,
          },
          {
            ic_uuid: "abcd2b7c-9a2d-4ffb-b678-8dde2ea0a620",
            ic_lat: -233812,
            ic_lon: -532922,
            ic_city: "Douradina",
            ic_states: "PARANÁ",
            ic_january: 6.027,
            ic_february: 5.883,
            ic_march: 5.374,
            ic_april: 4.543,
            ic_may: 3.594,
            ic_june: 3.221,
            ic_july: 3.441,
            ic_august: 4.404,
            ic_september: 4.618,
            ic_october: 5.444,
            ic_november: 6.147,
            ic_december: 6.477,
            ic_yearly: 4.931,
          },
          {
            ic_uuid: "4c42ff0e-8d31-4f22-bfb2-a5d23ebdb954",
            ic_lat: -221129,
            ic_lon: -483138,
            ic_city: "Dourado",
            ic_states: "SÃO PAULO",
            ic_january: 5.666,
            ic_february: 5.882,
            ic_march: 5.197,
            ic_april: 4.696,
            ic_may: 3.918,
            ic_june: 3.69,
            ic_july: 3.862,
            ic_august: 4.79,
            ic_september: 4.876,
            ic_october: 5.567,
            ic_november: 5.818,
            ic_december: 6.156,
            ic_yearly: 5.01,
          },
          {
            ic_uuid: "a8b4f30a-b44c-460f-91d8-c655d872c29e",
            ic_lat: -184341,
            ic_lon: -476085,
            ic_city: "Douradoquara",
            ic_states: "MINAS GERAIS",
            ic_january: 5.724,
            ic_february: 6.051,
            ic_march: 5.293,
            ic_april: 5.213,
            ic_may: 4.72,
            ic_june: 4.395,
            ic_july: 4.674,
            ic_august: 5.598,
            ic_september: 5.565,
            ic_october: 5.749,
            ic_november: 5.604,
            ic_december: 5.833,
            ic_yearly: 5.368,
          },
          {
            ic_uuid: "1f1c188d-6c53-467a-b965-92e8d1fe2b41",
            ic_lat: -222236,
            ic_lon: -548125,
            ic_city: "Dourados",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.925,
            ic_february: 5.722,
            ic_march: 5.265,
            ic_april: 4.507,
            ic_may: 3.691,
            ic_june: 3.353,
            ic_july: 3.489,
            ic_august: 4.464,
            ic_september: 4.743,
            ic_october: 5.317,
            ic_november: 5.91,
            ic_december: 6.39,
            ic_yearly: 4.898,
          },
          {
            ic_uuid: "864d525c-497e-4268-9cc9-372999a4d960",
            ic_lat: -235586,
            ic_lon: -522182,
            ic_city: "Doutor Camargo",
            ic_states: "PARANÁ",
            ic_january: 6.084,
            ic_february: 5.68,
            ic_march: 5.217,
            ic_april: 4.593,
            ic_may: 3.639,
            ic_june: 3.244,
            ic_july: 3.45,
            ic_august: 4.341,
            ic_september: 4.63,
            ic_october: 5.281,
            ic_november: 6.047,
            ic_december: 6.484,
            ic_yearly: 4.891,
          },
          {
            ic_uuid: "f2626ef8-30c9-4b59-b12c-dad9a7fa0154",
            ic_lat: -275108,
            ic_lon: -543582,
            ic_city: "Doutor Maurício Cardoso",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.448,
            ic_february: 5.994,
            ic_march: 5.176,
            ic_april: 4.013,
            ic_may: 3.088,
            ic_june: 2.525,
            ic_july: 2.834,
            ic_august: 3.685,
            ic_september: 4.001,
            ic_october: 5.197,
            ic_november: 6.21,
            ic_december: 6.609,
            ic_yearly: 4.648,
          },
          {
            ic_uuid: "37e6b3fc-535f-4a57-82a7-bbf925d29f32",
            ic_lat: -267178,
            ic_lon: -494799,
            ic_city: "Doutor Pedrinho",
            ic_states: "SANTA CATARINA",
            ic_january: 5.148,
            ic_february: 4.831,
            ic_march: 4.355,
            ic_april: 3.544,
            ic_may: 2.912,
            ic_june: 2.503,
            ic_july: 2.662,
            ic_august: 3.465,
            ic_september: 3.418,
            ic_october: 3.945,
            ic_november: 4.982,
            ic_december: 5.248,
            ic_yearly: 3.918,
          },
          {
            ic_uuid: "44f0d4d1-a134-41ec-b824-e7cbd5850521",
            ic_lat: -29086,
            ic_lon: -519919,
            ic_city: "Doutor Ricardo",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.104,
            ic_february: 5.706,
            ic_march: 4.772,
            ic_april: 3.795,
            ic_may: 2.819,
            ic_june: 2.339,
            ic_july: 2.632,
            ic_august: 3.377,
            ic_september: 3.653,
            ic_october: 4.685,
            ic_november: 6.061,
            ic_december: 6.404,
            ic_yearly: 4.362,
          },
          {
            ic_uuid: "956ff6e6-4463-451e-b196-15ab48362957",
            ic_lat: -60812,
            ic_lon: -383797,
            ic_city: "Doutor Severiano",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.815,
            ic_february: 5.855,
            ic_march: 5.866,
            ic_april: 5.595,
            ic_may: 5.215,
            ic_june: 5.03,
            ic_july: 5.353,
            ic_august: 6.039,
            ic_september: 6.486,
            ic_october: 6.559,
            ic_november: 6.613,
            ic_december: 6.049,
            ic_yearly: 5.873,
          },
          {
            ic_uuid: "aef5405f-760c-419b-a91f-0a8907976fcf",
            ic_lat: -24568,
            ic_lon: -494199,
            ic_city: "Doutor Ulysses",
            ic_states: "PARANÁ",
            ic_january: 5.046,
            ic_february: 5.119,
            ic_march: 4.485,
            ic_april: 3.839,
            ic_may: 3.102,
            ic_june: 2.809,
            ic_july: 2.946,
            ic_august: 3.934,
            ic_september: 3.92,
            ic_october: 4.367,
            ic_november: 5.124,
            ic_december: 5.502,
            ic_yearly: 4.183,
          },
          {
            ic_uuid: "84f80205-ecd6-44d1-b724-4eacbe75ca7a",
            ic_lat: -167217,
            ic_lon: -523141,
            ic_city: "Doverlândia",
            ic_states: "GOIÁS",
            ic_january: 5.494,
            ic_february: 5.637,
            ic_march: 5.295,
            ic_april: 5.219,
            ic_may: 4.788,
            ic_june: 4.476,
            ic_july: 4.632,
            ic_august: 5.398,
            ic_september: 5.356,
            ic_october: 5.332,
            ic_november: 5.559,
            ic_december: 5.691,
            ic_yearly: 5.24,
          },
          {
            ic_uuid: "13bb18f9-a768-4912-8a1d-252550e9aba5",
            ic_lat: -214848,
            ic_lon: -515355,
            ic_city: "Dracena",
            ic_states: "SÃO PAULO",
            ic_january: 5.908,
            ic_february: 5.986,
            ic_march: 5.443,
            ic_april: 4.807,
            ic_may: 4.029,
            ic_june: 3.706,
            ic_july: 3.884,
            ic_august: 4.78,
            ic_september: 4.902,
            ic_october: 5.577,
            ic_november: 6.046,
            ic_december: 6.364,
            ic_yearly: 5.12,
          },
          {
            ic_uuid: "24d94876-887f-482a-b4eb-1900256de06b",
            ic_lat: -22415,
            ic_lon: -494088,
            ic_city: "Duartina",
            ic_states: "SÃO PAULO",
            ic_january: 5.645,
            ic_february: 5.82,
            ic_march: 5.17,
            ic_april: 4.684,
            ic_may: 3.828,
            ic_june: 3.558,
            ic_july: 3.765,
            ic_august: 4.717,
            ic_september: 4.803,
            ic_october: 5.524,
            ic_november: 5.858,
            ic_december: 6.182,
            ic_yearly: 4.963,
          },
          {
            ic_uuid: "48226609-c5a2-4fcb-860c-046ea5718564",
            ic_lat: -220541,
            ic_lon: -425236,
            ic_city: "Duas Barras",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.707,
            ic_february: 6.034,
            ic_march: 4.974,
            ic_april: 4.306,
            ic_may: 3.563,
            ic_june: 3.325,
            ic_july: 3.473,
            ic_august: 4.19,
            ic_september: 4.52,
            ic_october: 4.828,
            ic_november: 4.661,
            ic_december: 5.569,
            ic_yearly: 4.596,
          },
          {
            ic_uuid: "59ee3ebc-ccb3-4b04-b1f9-9aeea2c41a86",
            ic_lat: -66888,
            ic_lon: -354184,
            ic_city: "Duas Estradas",
            ic_states: "PARAÍBA",
            ic_january: 5.678,
            ic_february: 5.685,
            ic_march: 5.739,
            ic_april: 5.265,
            ic_may: 4.74,
            ic_june: 4.283,
            ic_july: 4.358,
            ic_august: 5.139,
            ic_september: 5.599,
            ic_october: 5.867,
            ic_november: 6.023,
            ic_december: 5.784,
            ic_yearly: 5.346,
          },
          {
            ic_uuid: "f2d2de81-2855-49cb-842a-a229d5e76466",
            ic_lat: -113421,
            ic_lon: -49272,
            ic_city: "Dueré",
            ic_states: "TOCANTINS",
            ic_january: 5.207,
            ic_february: 5.202,
            ic_march: 4.948,
            ic_april: 4.994,
            ic_may: 5.005,
            ic_june: 5.023,
            ic_july: 5.349,
            ic_august: 5.965,
            ic_september: 5.705,
            ic_october: 5.418,
            ic_november: 5.186,
            ic_december: 5.291,
            ic_yearly: 5.274,
          },
          {
            ic_uuid: "9e26afb8-7def-4832-8cd1-ec0930ab94b1",
            ic_lat: -212329,
            ic_lon: -47976,
            ic_city: "Dumont",
            ic_states: "SÃO PAULO",
            ic_january: 5.719,
            ic_february: 5.966,
            ic_march: 5.17,
            ic_april: 4.822,
            ic_may: 4.113,
            ic_june: 3.895,
            ic_july: 4.113,
            ic_august: 4.944,
            ic_september: 4.992,
            ic_october: 5.532,
            ic_november: 5.784,
            ic_december: 6.13,
            ic_yearly: 5.098,
          },
          {
            ic_uuid: "d8339b52-cd8a-4c2b-82dc-2c6cfd5a3560",
            ic_lat: -41508,
            ic_lon: -429448,
            ic_city: "Duque Bacelar",
            ic_states: "MARANHÃO",
            ic_january: 5.122,
            ic_february: 5.292,
            ic_march: 5.281,
            ic_april: 5.184,
            ic_may: 5.074,
            ic_june: 5.163,
            ic_july: 5.464,
            ic_august: 5.982,
            ic_september: 6.402,
            ic_october: 6.343,
            ic_november: 6.075,
            ic_december: 5.61,
            ic_yearly: 5.583,
          },
          {
            ic_uuid: "40fdd16a-d2ce-4fd8-a57e-df6d85392418",
            ic_lat: -227863,
            ic_lon: -433053,
            ic_city: "Duque de Caxias",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.954,
            ic_february: 6.136,
            ic_march: 5.017,
            ic_april: 4.363,
            ic_may: 3.618,
            ic_june: 3.384,
            ic_july: 3.372,
            ic_august: 4.226,
            ic_september: 4.431,
            ic_october: 5.02,
            ic_november: 5.057,
            ic_december: 5.743,
            ic_yearly: 4.693,
          },
          {
            ic_uuid: "ec0e0493-2a8f-4b4f-924f-f3cbe6298b77",
            ic_lat: -202063,
            ic_lon: -417981,
            ic_city: "Durandé",
            ic_states: "MINAS GERAIS",
            ic_january: 5.766,
            ic_february: 5.948,
            ic_march: 5.001,
            ic_april: 4.536,
            ic_may: 3.809,
            ic_june: 3.617,
            ic_july: 3.777,
            ic_august: 4.435,
            ic_september: 4.762,
            ic_october: 4.863,
            ic_november: 4.785,
            ic_december: 5.441,
            ic_yearly: 4.728,
          },
          {
            ic_uuid: "e37f20d9-e14c-4d6d-ad41-3ce0eb67fea6",
            ic_lat: -22429,
            ic_lon: -502009,
            ic_city: "Echaporã",
            ic_states: "SÃO PAULO",
            ic_january: 5.743,
            ic_february: 5.838,
            ic_march: 5.325,
            ic_april: 4.753,
            ic_may: 3.839,
            ic_june: 3.576,
            ic_july: 3.752,
            ic_august: 4.735,
            ic_september: 4.825,
            ic_october: 5.507,
            ic_november: 5.959,
            ic_december: 6.32,
            ic_yearly: 5.014,
          },
          {
            ic_uuid: "787c5d72-a792-4093-aee5-a8ed47c757b9",
            ic_lat: -183722,
            ic_lon: -40831,
            ic_city: "Ecoporanga",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.945,
            ic_february: 6.098,
            ic_march: 5.256,
            ic_april: 4.618,
            ic_may: 3.808,
            ic_june: 3.546,
            ic_july: 3.614,
            ic_august: 4.168,
            ic_september: 4.755,
            ic_october: 4.924,
            ic_november: 4.913,
            ic_december: 5.762,
            ic_yearly: 4.784,
          },
          {
            ic_uuid: "1bef4776-46a0-49cf-b9b4-09fd4cc9e5dd",
            ic_lat: -174244,
            ic_lon: -496648,
            ic_city: "Edealina",
            ic_states: "GOIÁS",
            ic_january: 5.637,
            ic_february: 5.792,
            ic_march: 5.361,
            ic_april: 5.17,
            ic_may: 4.723,
            ic_june: 4.428,
            ic_july: 4.642,
            ic_august: 5.533,
            ic_september: 5.421,
            ic_october: 5.634,
            ic_november: 5.586,
            ic_december: 5.688,
            ic_yearly: 5.301,
          },
          {
            ic_uuid: "23ad5bf8-5802-45de-8972-286bc00b6274",
            ic_lat: -173411,
            ic_lon: -499299,
            ic_city: "Edéia",
            ic_states: "GOIÁS",
            ic_january: 5.685,
            ic_february: 5.736,
            ic_march: 5.377,
            ic_april: 5.137,
            ic_may: 4.715,
            ic_june: 4.422,
            ic_july: 4.642,
            ic_august: 5.502,
            ic_september: 5.394,
            ic_october: 5.579,
            ic_november: 5.585,
            ic_december: 5.679,
            ic_yearly: 5.288,
          },
          {
            ic_uuid: "3e3b35e0-df9f-48f4-a7bc-f1c111e9aa0b",
            ic_lat: -66572,
            ic_lon: -698666,
            ic_city: "Eirunepé",
            ic_states: "AMAZONAS",
            ic_january: 4.319,
            ic_february: 4.743,
            ic_march: 4.354,
            ic_april: 4.343,
            ic_may: 4.047,
            ic_june: 4.101,
            ic_july: 4.285,
            ic_august: 4.968,
            ic_september: 5.11,
            ic_october: 4.923,
            ic_november: 4.722,
            ic_december: 4.441,
            ic_yearly: 4.53,
          },
          {
            ic_uuid: "429cada1-80ea-48b4-867c-08331d0863c1",
            ic_lat: -237873,
            ic_lon: -542842,
            ic_city: "Eldorado",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 6.109,
            ic_february: 5.791,
            ic_march: 5.34,
            ic_april: 4.396,
            ic_may: 3.471,
            ic_june: 3.056,
            ic_july: 3.264,
            ic_august: 4.264,
            ic_september: 4.549,
            ic_october: 5.361,
            ic_november: 6.07,
            ic_december: 6.502,
            ic_yearly: 4.848,
          },
          {
            ic_uuid: "13e04876-ae4c-4a61-9e85-e981d5364903",
            ic_lat: -2452,
            ic_lon: -481079,
            ic_city: "Eldorado",
            ic_states: "SÃO PAULO",
            ic_january: 5.201,
            ic_february: 5.158,
            ic_march: 4.355,
            ic_april: 3.806,
            ic_may: 3.037,
            ic_june: 2.662,
            ic_july: 2.668,
            ic_august: 3.362,
            ic_september: 3.392,
            ic_october: 3.751,
            ic_november: 4.601,
            ic_december: 5.191,
            ic_yearly: 3.932,
          },
          {
            ic_uuid: "b6b5717e-8b81-4ad3-bb36-3345d5b44d2f",
            ic_lat: -61043,
            ic_lon: -493556,
            ic_city: "Eldorado do Carajás",
            ic_states: "PARÁ",
            ic_january: 4.611,
            ic_february: 4.773,
            ic_march: 4.7,
            ic_april: 4.725,
            ic_may: 4.791,
            ic_june: 5.055,
            ic_july: 5.162,
            ic_august: 5.604,
            ic_september: 5.229,
            ic_october: 4.89,
            ic_november: 4.72,
            ic_december: 4.574,
            ic_yearly: 4.903,
          },
          {
            ic_uuid: "6e3b1d78-a25a-40b7-945f-d6f0ca1e509b",
            ic_lat: -299979,
            ic_lon: -513109,
            ic_city: "Eldorado do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.518,
            ic_february: 5.902,
            ic_march: 4.877,
            ic_april: 3.813,
            ic_may: 2.773,
            ic_june: 2.312,
            ic_july: 2.524,
            ic_august: 3.212,
            ic_september: 3.609,
            ic_october: 4.844,
            ic_november: 6.261,
            ic_december: 6.754,
            ic_yearly: 4.45,
          },
          {
            ic_uuid: "a247c5ea-2b98-47e1-ae86-ec1adb277502",
            ic_lat: -61999,
            ic_lon: -421358,
            ic_city: "Elesbão Veloso",
            ic_states: "PIAUÍ",
            ic_january: 5.29,
            ic_february: 5.369,
            ic_march: 5.309,
            ic_april: 5.314,
            ic_may: 5.207,
            ic_june: 5.196,
            ic_july: 5.52,
            ic_august: 6.162,
            ic_september: 6.576,
            ic_october: 6.359,
            ic_november: 6.239,
            ic_december: 5.809,
            ic_yearly: 5.696,
          },
          {
            ic_uuid: "6e9ac68c-2e4f-4a4b-8429-62517ad85315",
            ic_lat: -230433,
            ic_lon: -473686,
            ic_city: "Elias Fausto",
            ic_states: "SÃO PAULO",
            ic_january: 5.722,
            ic_february: 5.82,
            ic_march: 5.112,
            ic_april: 4.654,
            ic_may: 3.776,
            ic_june: 3.505,
            ic_july: 3.651,
            ic_august: 4.613,
            ic_september: 4.783,
            ic_october: 5.426,
            ic_november: 5.736,
            ic_december: 6.172,
            ic_yearly: 4.914,
          },
          {
            ic_uuid: "7f746c4a-7790-4cdb-9348-6cc58a5bfc79",
            ic_lat: -81007,
            ic_lon: -436587,
            ic_city: "Eliseu Martins",
            ic_states: "PIAUÍ",
            ic_january: 5.436,
            ic_february: 5.373,
            ic_march: 5.249,
            ic_april: 5.289,
            ic_may: 5.271,
            ic_june: 5.26,
            ic_july: 5.634,
            ic_august: 6.14,
            ic_september: 6.514,
            ic_october: 6.286,
            ic_november: 5.785,
            ic_december: 5.597,
            ic_yearly: 5.653,
          },
          {
            ic_uuid: "4cc6292d-4fea-4cbd-bf11-0006cb6e0a60",
            ic_lat: -21166,
            ic_lon: -491119,
            ic_city: "Elisiário",
            ic_states: "SÃO PAULO",
            ic_january: 5.804,
            ic_february: 5.936,
            ic_march: 5.236,
            ic_april: 4.825,
            ic_may: 4.067,
            ic_june: 3.815,
            ic_july: 4.043,
            ic_august: 4.951,
            ic_september: 5.066,
            ic_october: 5.611,
            ic_november: 5.883,
            ic_december: 6.159,
            ic_yearly: 5.116,
          },
          {
            ic_uuid: "d7bfd2af-2389-4608-be4a-c34f9f09eca1",
            ic_lat: -129464,
            ic_lon: -39521,
            ic_city: "Elísio Medrado",
            ic_states: "BAHIA",
            ic_january: 5.905,
            ic_february: 5.908,
            ic_march: 5.637,
            ic_april: 4.579,
            ic_may: 3.987,
            ic_june: 3.703,
            ic_july: 3.872,
            ic_august: 4.381,
            ic_september: 5.207,
            ic_october: 5.327,
            ic_november: 5.452,
            ic_december: 5.92,
            ic_yearly: 4.99,
          },
          {
            ic_uuid: "470daa50-70e4-46d0-9b64-0535dbb689d3",
            ic_lat: -216093,
            ic_lon: -455695,
            ic_city: "Elói Mendes",
            ic_states: "MINAS GERAIS",
            ic_january: 5.53,
            ic_february: 5.8,
            ic_march: 4.962,
            ic_april: 4.615,
            ic_may: 3.966,
            ic_june: 3.722,
            ic_july: 3.999,
            ic_august: 4.851,
            ic_september: 5.097,
            ic_october: 5.401,
            ic_november: 5.288,
            ic_december: 5.673,
            ic_yearly: 4.908,
          },
          {
            ic_uuid: "f754fa12-dff1-4af8-bf2f-4b83440da4ee",
            ic_lat: -71001,
            ic_lon: -377166,
            ic_city: "Emas",
            ic_states: "PARAÍBA",
            ic_january: 6.241,
            ic_february: 6.118,
            ic_march: 6.284,
            ic_april: 5.844,
            ic_may: 5.196,
            ic_june: 4.773,
            ic_july: 5.02,
            ic_august: 5.844,
            ic_september: 6.48,
            ic_october: 6.662,
            ic_november: 6.792,
            ic_december: 6.42,
            ic_yearly: 5.973,
          },
          {
            ic_uuid: "d3b2086d-77eb-4467-96fe-99d725c309e4",
            ic_lat: -209801,
            ic_lon: -488329,
            ic_city: "Embaúba",
            ic_states: "SÃO PAULO",
            ic_january: 5.808,
            ic_february: 5.915,
            ic_march: 5.195,
            ic_april: 4.785,
            ic_may: 4.101,
            ic_june: 3.902,
            ic_july: 4.148,
            ic_august: 5.017,
            ic_september: 5.094,
            ic_october: 5.596,
            ic_november: 5.901,
            ic_december: 6.124,
            ic_yearly: 5.132,
          },
          {
            ic_uuid: "a0bf6584-e8ee-4877-80b3-5c7e6d5fb7e2",
            ic_lat: -236442,
            ic_lon: -468583,
            ic_city: "Embu das Artes",
            ic_states: "SÃO PAULO",
            ic_january: 5.33,
            ic_february: 5.558,
            ic_march: 4.767,
            ic_april: 4.219,
            ic_may: 3.432,
            ic_june: 3.165,
            ic_july: 3.259,
            ic_august: 4.22,
            ic_september: 4.248,
            ic_october: 4.766,
            ic_november: 5.242,
            ic_december: 5.775,
            ic_yearly: 4.499,
          },
          {
            ic_uuid: "5e605d6d-6910-413d-979d-e2e1f591b148",
            ic_lat: -238302,
            ic_lon: -46814,
            ic_city: "Embu-Guaçu",
            ic_states: "SÃO PAULO",
            ic_january: 5.144,
            ic_february: 5.351,
            ic_march: 4.538,
            ic_april: 4.057,
            ic_may: 3.29,
            ic_june: 3.06,
            ic_july: 3.145,
            ic_august: 4.034,
            ic_september: 3.978,
            ic_october: 4.33,
            ic_november: 4.878,
            ic_december: 5.445,
            ic_yearly: 4.271,
          },
          {
            ic_uuid: "111f5cf4-c9a0-418c-9d2e-95144880b542",
            ic_lat: -218319,
            ic_lon: -514836,
            ic_city: "Emilianópolis",
            ic_states: "SÃO PAULO",
            ic_january: 5.935,
            ic_february: 5.965,
            ic_march: 5.442,
            ic_april: 4.818,
            ic_may: 3.971,
            ic_june: 3.664,
            ic_july: 3.842,
            ic_august: 4.781,
            ic_september: 4.806,
            ic_october: 5.545,
            ic_november: 6.064,
            ic_december: 6.356,
            ic_yearly: 5.099,
          },
          {
            ic_uuid: "b1428ad0-589a-485f-9b9d-6f77b123a2d7",
            ic_lat: -292356,
            ic_lon: -518707,
            ic_city: "Encantado",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.211,
            ic_february: 5.753,
            ic_march: 4.828,
            ic_april: 3.805,
            ic_may: 2.824,
            ic_june: 2.353,
            ic_july: 2.606,
            ic_august: 3.342,
            ic_september: 3.636,
            ic_october: 4.722,
            ic_november: 6.089,
            ic_december: 6.511,
            ic_yearly: 4.39,
          },
          {
            ic_uuid: "ce247732-69f8-47b5-b1bd-86429ea4ff7b",
            ic_lat: -61073,
            ic_lon: -383036,
            ic_city: "Encanto",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.815,
            ic_february: 5.855,
            ic_march: 5.866,
            ic_april: 5.595,
            ic_may: 5.215,
            ic_june: 5.03,
            ic_july: 5.353,
            ic_august: 6.039,
            ic_september: 6.486,
            ic_october: 6.559,
            ic_november: 6.613,
            ic_december: 6.049,
            ic_yearly: 5.873,
          },
          {
            ic_uuid: "9db867dc-5a08-40f0-9474-1903fad8287d",
            ic_lat: -155325,
            ic_lon: -409076,
            ic_city: "Encruzilhada",
            ic_states: "BAHIA",
            ic_january: 6.057,
            ic_february: 5.996,
            ic_march: 5.313,
            ic_april: 4.636,
            ic_may: 3.936,
            ic_june: 3.586,
            ic_july: 3.815,
            ic_august: 4.387,
            ic_september: 5.295,
            ic_october: 5.4,
            ic_november: 5.312,
            ic_december: 6.095,
            ic_yearly: 4.986,
          },
          {
            ic_uuid: "a73344a6-5d03-4a88-ac3b-2c3c2dd01d54",
            ic_lat: -305435,
            ic_lon: -525208,
            ic_city: "Encruzilhada do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.431,
            ic_february: 5.811,
            ic_march: 4.888,
            ic_april: 3.817,
            ic_may: 2.78,
            ic_june: 2.335,
            ic_july: 2.562,
            ic_august: 3.232,
            ic_september: 3.723,
            ic_october: 4.938,
            ic_november: 6.221,
            ic_december: 6.748,
            ic_yearly: 4.457,
          },
          {
            ic_uuid: "f864371b-12f4-4e1e-9cb4-1f5c9177667f",
            ic_lat: -25945,
            ic_lon: -531663,
            ic_city: "Enéas Marques",
            ic_states: "PARANÁ",
            ic_january: 6.289,
            ic_february: 5.749,
            ic_march: 5.233,
            ic_april: 4.251,
            ic_may: 3.285,
            ic_june: 2.837,
            ic_july: 3.136,
            ic_august: 4.096,
            ic_september: 4.281,
            ic_october: 5.239,
            ic_november: 6.156,
            ic_december: 6.446,
            ic_yearly: 4.75,
          },
          {
            ic_uuid: "7461ec87-e70f-47f7-b7e1-673f17755b2b",
            ic_lat: -237975,
            ic_lon: -522663,
            ic_city: "Engenheiro Beltrão",
            ic_states: "PARANÁ",
            ic_january: 6.053,
            ic_february: 5.77,
            ic_march: 5.316,
            ic_april: 4.581,
            ic_may: 3.616,
            ic_june: 3.228,
            ic_july: 3.419,
            ic_august: 4.366,
            ic_september: 4.601,
            ic_october: 5.29,
            ic_november: 6.138,
            ic_december: 6.421,
            ic_yearly: 4.9,
          },
          {
            ic_uuid: "ed2b0723-9dbc-4f56-8201-cb672ed5441f",
            ic_lat: -19207,
            ic_lon: -420507,
            ic_city: "Engenheiro Caldas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.946,
            ic_february: 6.109,
            ic_march: 5.129,
            ic_april: 4.561,
            ic_may: 3.858,
            ic_june: 3.632,
            ic_july: 3.715,
            ic_august: 4.39,
            ic_september: 4.865,
            ic_october: 5.1,
            ic_november: 4.884,
            ic_december: 5.775,
            ic_yearly: 4.83,
          },
          {
            ic_uuid: "4490650e-a669-4be6-ae82-fef489cf0ba6",
            ic_lat: -22484,
            ic_lon: -472114,
            ic_city: "Engenheiro Coelho",
            ic_states: "SÃO PAULO",
            ic_january: 5.611,
            ic_february: 5.865,
            ic_march: 5.157,
            ic_april: 4.628,
            ic_may: 3.889,
            ic_june: 3.626,
            ic_july: 3.765,
            ic_august: 4.733,
            ic_september: 4.893,
            ic_october: 5.506,
            ic_november: 5.682,
            ic_december: 6.117,
            ic_yearly: 4.956,
          },
          {
            ic_uuid: "47212a59-7fc3-474e-9de8-3db608cb9922",
            ic_lat: -172836,
            ic_lon: -439473,
            ic_city: "Engenheiro Navarro",
            ic_states: "MINAS GERAIS",
            ic_january: 6.241,
            ic_february: 6.566,
            ic_march: 5.555,
            ic_april: 5.262,
            ic_may: 4.739,
            ic_june: 4.464,
            ic_july: 4.793,
            ic_august: 5.58,
            ic_september: 6.026,
            ic_october: 5.98,
            ic_november: 5.476,
            ic_december: 6.056,
            ic_yearly: 5.561,
          },
          {
            ic_uuid: "8f90f652-08d7-4985-a834-6f9276cf98d4",
            ic_lat: -225503,
            ic_lon: -436831,
            ic_city: "Engenheiro Paulo de Frontin",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.831,
            ic_february: 6.119,
            ic_march: 4.962,
            ic_april: 4.375,
            ic_may: 3.59,
            ic_june: 3.397,
            ic_july: 3.467,
            ic_august: 4.26,
            ic_september: 4.504,
            ic_october: 4.932,
            ic_november: 5.021,
            ic_december: 5.708,
            ic_yearly: 4.681,
          },
          {
            ic_uuid: "a9cc5a2d-c4c6-4617-85a1-a47857c6e8aa",
            ic_lat: -277065,
            ic_lon: -52915,
            ic_city: "Engenho Velho",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.277,
            ic_february: 5.897,
            ic_march: 5.082,
            ic_april: 4.109,
            ic_may: 3.11,
            ic_june: 2.591,
            ic_july: 2.93,
            ic_august: 3.695,
            ic_september: 3.958,
            ic_october: 5.147,
            ic_november: 6.225,
            ic_december: 6.647,
            ic_yearly: 4.639,
          },
          {
            ic_uuid: "23b0ec3b-d442-4ab4-bf94-2b0310582916",
            ic_lat: -196223,
            ic_lon: -422309,
            ic_city: "Entre Folhas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.786,
            ic_february: 5.914,
            ic_march: 5.014,
            ic_april: 4.48,
            ic_may: 3.811,
            ic_june: 3.597,
            ic_july: 3.76,
            ic_august: 4.437,
            ic_september: 4.822,
            ic_october: 4.961,
            ic_november: 4.784,
            ic_december: 5.588,
            ic_yearly: 4.746,
          },
          {
            ic_uuid: "d805f56f-0b2f-452d-92ce-2301776f5a19",
            ic_lat: -119396,
            ic_lon: -380874,
            ic_city: "Entre Rios",
            ic_states: "BAHIA",
            ic_january: 6.115,
            ic_february: 5.892,
            ic_march: 5.774,
            ic_april: 4.802,
            ic_may: 4.069,
            ic_june: 3.885,
            ic_july: 4.039,
            ic_august: 4.445,
            ic_september: 5.295,
            ic_october: 5.596,
            ic_november: 5.917,
            ic_december: 6.184,
            ic_yearly: 5.168,
          },
          {
            ic_uuid: "a2784138-9cdc-432a-8a49-cf5db08ff3ab",
            ic_lat: -267229,
            ic_lon: -52559,
            ic_city: "Entre Rios",
            ic_states: "SANTA CATARINA",
            ic_january: 6.259,
            ic_february: 5.674,
            ic_march: 5.145,
            ic_april: 4.15,
            ic_may: 3.202,
            ic_june: 2.711,
            ic_july: 2.985,
            ic_august: 3.895,
            ic_september: 4.118,
            ic_october: 5.127,
            ic_november: 6.174,
            ic_december: 6.358,
            ic_yearly: 4.65,
          },
          {
            ic_uuid: "414261d6-673d-4843-9797-c227609cad85",
            ic_lat: -206711,
            ic_lon: -440658,
            ic_city: "Entre Rios de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.642,
            ic_february: 5.852,
            ic_march: 4.906,
            ic_april: 4.523,
            ic_may: 3.913,
            ic_june: 3.751,
            ic_july: 3.967,
            ic_august: 4.927,
            ic_september: 5.136,
            ic_october: 5.296,
            ic_november: 5.023,
            ic_december: 5.527,
            ic_yearly: 4.872,
          },
          {
            ic_uuid: "98996617-4d6f-41d1-9f47-cd7346196306",
            ic_lat: -247047,
            ic_lon: -54239,
            ic_city: "Entre Rios do Oeste",
            ic_states: "PARANÁ",
            ic_january: 6.374,
            ic_february: 5.839,
            ic_march: 5.33,
            ic_april: 4.307,
            ic_may: 3.326,
            ic_june: 2.918,
            ic_july: 3.131,
            ic_august: 4.098,
            ic_september: 4.373,
            ic_october: 5.305,
            ic_november: 6.142,
            ic_december: 6.586,
            ic_yearly: 4.811,
          },
          {
            ic_uuid: "88c58314-5a4a-4f19-99c5-d3434c34bdeb",
            ic_lat: -275303,
            ic_lon: -527352,
            ic_city: "Entre Rios do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.247,
            ic_february: 5.819,
            ic_march: 5.042,
            ic_april: 4.075,
            ic_may: 3.103,
            ic_june: 2.611,
            ic_july: 2.921,
            ic_august: 3.736,
            ic_september: 3.987,
            ic_october: 5.103,
            ic_november: 6.13,
            ic_december: 6.52,
            ic_yearly: 4.608,
          },
          {
            ic_uuid: "3082b143-f490-479f-8a42-2918c9f381b7",
            ic_lat: -2836,
            ic_lon: -542679,
            ic_city: "Entre-Ijuís",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.504,
            ic_february: 6.055,
            ic_march: 5.182,
            ic_april: 4.036,
            ic_may: 3.066,
            ic_june: 2.516,
            ic_july: 2.803,
            ic_august: 3.559,
            ic_september: 3.991,
            ic_october: 5.138,
            ic_november: 6.301,
            ic_december: 6.735,
            ic_yearly: 4.657,
          },
          {
            ic_uuid: "fc04b89f-8634-4e2a-af7e-f38117b3321d",
            ic_lat: -74322,
            ic_lon: -700234,
            ic_city: "Envira",
            ic_states: "AMAZONAS",
            ic_january: 4.405,
            ic_february: 4.705,
            ic_march: 4.333,
            ic_april: 4.326,
            ic_may: 4.012,
            ic_june: 4.071,
            ic_july: 4.313,
            ic_august: 4.945,
            ic_september: 5.156,
            ic_october: 5.001,
            ic_november: 4.703,
            ic_december: 4.528,
            ic_yearly: 4.541,
          },
          {
            ic_uuid: "126f996f-345f-42c0-9dc3-1e6d63e90d55",
            ic_lat: -110293,
            ic_lon: -687442,
            ic_city: "Epitaciolândia",
            ic_states: "ACRE",
            ic_january: 4.655,
            ic_february: 4.653,
            ic_march: 4.268,
            ic_april: 4.566,
            ic_may: 3.945,
            ic_june: 4.079,
            ic_july: 4.285,
            ic_august: 4.858,
            ic_september: 5.102,
            ic_october: 5.105,
            ic_november: 5.157,
            ic_december: 4.771,
            ic_yearly: 4.62,
          },
          {
            ic_uuid: "f2857fe9-706b-4168-9b3d-1d6b5960a03a",
            ic_lat: -69452,
            ic_lon: -367172,
            ic_city: "Equador",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.015,
            ic_february: 5.977,
            ic_march: 6.025,
            ic_april: 5.61,
            ic_may: 5.025,
            ic_june: 4.64,
            ic_july: 4.945,
            ic_august: 5.724,
            ic_september: 6.275,
            ic_october: 6.475,
            ic_november: 6.516,
            ic_december: 6.172,
            ic_yearly: 5.783,
          },
          {
            ic_uuid: "c95939eb-0dc5-4fb7-ad7b-68cd4a0e06c3",
            ic_lat: -278549,
            ic_lon: -523009,
            ic_city: "Erebango",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.285,
            ic_february: 5.816,
            ic_march: 4.947,
            ic_april: 4.038,
            ic_may: 3.058,
            ic_june: 2.582,
            ic_july: 2.907,
            ic_august: 3.678,
            ic_september: 3.922,
            ic_october: 5.039,
            ic_november: 6.153,
            ic_december: 6.528,
            ic_yearly: 4.579,
          },
          {
            ic_uuid: "3dcfc260-bedd-4fe4-9ebb-837d382918c0",
            ic_lat: -276369,
            ic_lon: -522701,
            ic_city: "Erechim",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.226,
            ic_february: 5.794,
            ic_march: 4.996,
            ic_april: 4.053,
            ic_may: 3.07,
            ic_june: 2.59,
            ic_july: 2.938,
            ic_august: 3.714,
            ic_september: 3.959,
            ic_october: 5.048,
            ic_november: 6.198,
            ic_december: 6.501,
            ic_yearly: 4.59,
          },
          {
            ic_uuid: "0798bcad-141b-42bc-acda-00fea6775976",
            ic_lat: -60279,
            ic_lon: -383464,
            ic_city: "Ererê",
            ic_states: "CEARÁ",
            ic_january: 5.869,
            ic_february: 5.909,
            ic_march: 5.972,
            ic_april: 5.642,
            ic_may: 5.289,
            ic_june: 5.07,
            ic_july: 5.381,
            ic_august: 6.074,
            ic_september: 6.458,
            ic_october: 6.581,
            ic_november: 6.581,
            ic_december: 6.067,
            ic_yearly: 5.908,
          },
          {
            ic_uuid: "fd8bba56-6af3-4971-bd08-e7bf218e31fe",
            ic_lat: -134176,
            ic_lon: -421407,
            ic_city: "Érico Cardoso",
            ic_states: "BAHIA",
            ic_january: 6.415,
            ic_february: 6.468,
            ic_march: 5.956,
            ic_april: 5.444,
            ic_may: 4.933,
            ic_june: 4.73,
            ic_july: 4.939,
            ic_august: 5.614,
            ic_september: 6.211,
            ic_october: 6.352,
            ic_november: 5.872,
            ic_december: 6.423,
            ic_yearly: 5.78,
          },
          {
            ic_uuid: "21407265-8a96-4f2a-b683-d4d5f5f7dfab",
            ic_lat: -289874,
            ic_lon: -496435,
            ic_city: "Ermo",
            ic_states: "SANTA CATARINA",
            ic_january: 5.639,
            ic_february: 5.308,
            ic_march: 4.542,
            ic_april: 3.833,
            ic_may: 2.971,
            ic_june: 2.444,
            ic_july: 2.688,
            ic_august: 3.396,
            ic_september: 3.504,
            ic_october: 4.295,
            ic_november: 5.641,
            ic_december: 5.955,
            ic_yearly: 4.185,
          },
          {
            ic_uuid: "570b3490-5458-4068-a173-3f011e444da7",
            ic_lat: -284983,
            ic_lon: -525841,
            ic_city: "Ernestina",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.287,
            ic_february: 5.856,
            ic_march: 4.985,
            ic_april: 3.98,
            ic_may: 3.014,
            ic_june: 2.469,
            ic_july: 2.79,
            ic_august: 3.553,
            ic_september: 3.831,
            ic_october: 5.001,
            ic_november: 6.224,
            ic_december: 6.61,
            ic_yearly: 4.55,
          },
          {
            ic_uuid: "c05f9cbf-caf2-481c-82de-877ecca1a6cb",
            ic_lat: -273931,
            ic_lon: -525745,
            ic_city: "Erval Grande",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.217,
            ic_february: 5.759,
            ic_march: 5.107,
            ic_april: 4.082,
            ic_may: 3.091,
            ic_june: 2.594,
            ic_july: 2.897,
            ic_august: 3.754,
            ic_september: 4.004,
            ic_october: 5.131,
            ic_november: 6.177,
            ic_december: 6.555,
            ic_yearly: 4.614,
          },
          {
            ic_uuid: "ac6e7a38-9d74-464c-b25a-bb6f0d1cef2d",
            ic_lat: -275448,
            ic_lon: -53501,
            ic_city: "Erval Seco",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.398,
            ic_february: 5.858,
            ic_march: 5.134,
            ic_april: 4.087,
            ic_may: 3.106,
            ic_june: 2.56,
            ic_july: 2.901,
            ic_august: 3.702,
            ic_september: 3.996,
            ic_october: 5.19,
            ic_november: 6.256,
            ic_december: 6.621,
            ic_yearly: 4.651,
          },
          {
            ic_uuid: "7df61eba-2101-47b5-9a0b-762ef3e11db8",
            ic_lat: -272748,
            ic_lon: -514434,
            ic_city: "Erval Velho",
            ic_states: "SANTA CATARINA",
            ic_january: 6.09,
            ic_february: 5.585,
            ic_march: 5.048,
            ic_april: 4.124,
            ic_may: 3.071,
            ic_june: 2.674,
            ic_july: 2.915,
            ic_august: 3.766,
            ic_september: 4.021,
            ic_october: 4.993,
            ic_november: 6.174,
            ic_december: 6.418,
            ic_yearly: 4.573,
          },
          {
            ic_uuid: "1d0722a7-1208-49d1-afee-65c644fb3980",
            ic_lat: -208407,
            ic_lon: -426548,
            ic_city: "Ervália",
            ic_states: "MINAS GERAIS",
            ic_january: 5.722,
            ic_february: 6.046,
            ic_march: 5.018,
            ic_april: 4.423,
            ic_may: 3.825,
            ic_june: 3.655,
            ic_july: 3.841,
            ic_august: 4.635,
            ic_september: 4.957,
            ic_october: 5.196,
            ic_november: 4.959,
            ic_december: 5.622,
            ic_yearly: 4.825,
          },
          {
            ic_uuid: "0aa669a4-811b-411e-8532-b1a02d0270e3",
            ic_lat: -83571,
            ic_lon: -352244,
            ic_city: "Escada",
            ic_states: "PERNAMBUCO",
            ic_january: 5.651,
            ic_february: 5.772,
            ic_march: 5.793,
            ic_april: 5.014,
            ic_may: 4.301,
            ic_june: 3.988,
            ic_july: 4.041,
            ic_august: 4.736,
            ic_september: 5.4,
            ic_october: 5.705,
            ic_november: 5.987,
            ic_december: 5.994,
            ic_yearly: 5.198,
          },
          {
            ic_uuid: "0b855e60-5bfd-46a5-88aa-075724989386",
            ic_lat: -280522,
            ic_lon: -511937,
            ic_city: "Esmeralda",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.178,
            ic_february: 5.703,
            ic_march: 4.886,
            ic_april: 3.941,
            ic_may: 2.98,
            ic_june: 2.563,
            ic_july: 2.879,
            ic_august: 3.646,
            ic_september: 3.873,
            ic_october: 4.914,
            ic_november: 6.128,
            ic_december: 6.375,
            ic_yearly: 4.505,
          },
          {
            ic_uuid: "303dce16-cb41-4ad2-8d6b-09c5b7de511c",
            ic_lat: -19763,
            ic_lon: -44314,
            ic_city: "Esmeraldas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.905,
            ic_february: 6.087,
            ic_march: 5.18,
            ic_april: 4.91,
            ic_may: 4.307,
            ic_june: 4.14,
            ic_july: 4.378,
            ic_august: 5.22,
            ic_september: 5.547,
            ic_october: 5.625,
            ic_november: 5.366,
            ic_december: 5.704,
            ic_yearly: 5.198,
          },
          {
            ic_uuid: "f0f1760b-14c4-4062-bd74-e3aba5a786b4",
            ic_lat: -206512,
            ic_lon: -419123,
            ic_city: "Espera Feliz",
            ic_states: "MINAS GERAIS",
            ic_january: 5.869,
            ic_february: 6.073,
            ic_march: 4.953,
            ic_april: 4.425,
            ic_may: 3.811,
            ic_june: 3.715,
            ic_july: 3.837,
            ic_august: 4.596,
            ic_september: 4.964,
            ic_october: 5.084,
            ic_november: 4.786,
            ic_december: 5.597,
            ic_yearly: 4.809,
          },
          {
            ic_uuid: "dccc28fa-e4ea-4628-b1d3-1281400a7aa3",
            ic_lat: -70187,
            ic_lon: -358595,
            ic_city: "Esperança",
            ic_states: "PARAÍBA",
            ic_january: 5.783,
            ic_february: 5.885,
            ic_march: 5.872,
            ic_april: 5.402,
            ic_may: 4.776,
            ic_june: 4.238,
            ic_july: 4.413,
            ic_august: 5.139,
            ic_september: 5.663,
            ic_october: 5.944,
            ic_november: 6.199,
            ic_december: 5.989,
            ic_yearly: 5.442,
          },
          {
            ic_uuid: "f9007efb-8cb4-4e96-a204-692ed896d27c",
            ic_lat: -273607,
            ic_lon: -539895,
            ic_city: "Esperança do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.404,
            ic_february: 5.902,
            ic_march: 5.125,
            ic_april: 4.048,
            ic_may: 3.124,
            ic_june: 2.566,
            ic_july: 2.885,
            ic_august: 3.696,
            ic_september: 4.013,
            ic_october: 5.218,
            ic_november: 6.21,
            ic_december: 6.612,
            ic_yearly: 4.65,
          },
          {
            ic_uuid: "9881f8a0-8423-4e26-b086-98c2b0cea07a",
            ic_lat: -237242,
            ic_lon: -538114,
            ic_city: "Esperança Nova",
            ic_states: "PARANÁ",
            ic_january: 6.186,
            ic_february: 5.897,
            ic_march: 5.355,
            ic_april: 4.457,
            ic_may: 3.512,
            ic_june: 3.131,
            ic_july: 3.323,
            ic_august: 4.317,
            ic_september: 4.574,
            ic_october: 5.381,
            ic_november: 6.138,
            ic_december: 6.556,
            ic_yearly: 4.902,
          },
          {
            ic_uuid: "337be617-1bf5-46c6-ae73-9e00ab78850d",
            ic_lat: -3889,
            ic_lon: -422328,
            ic_city: "Esperantina",
            ic_states: "PIAUÍ",
            ic_january: 5.304,
            ic_february: 5.501,
            ic_march: 5.396,
            ic_april: 5.144,
            ic_may: 5.188,
            ic_june: 5.323,
            ic_july: 5.527,
            ic_august: 6.149,
            ic_september: 6.497,
            ic_october: 6.482,
            ic_november: 6.325,
            ic_december: 5.715,
            ic_yearly: 5.713,
          },
          {
            ic_uuid: "03452f66-962a-4a97-bdea-cdf63a3e28ba",
            ic_lat: -53663,
            ic_lon: -485382,
            ic_city: "Esperantina",
            ic_states: "TOCANTINS",
            ic_january: 4.55,
            ic_february: 4.749,
            ic_march: 4.795,
            ic_april: 4.866,
            ic_may: 4.821,
            ic_june: 5.136,
            ic_july: 5.213,
            ic_august: 5.711,
            ic_september: 5.477,
            ic_october: 4.955,
            ic_november: 4.862,
            ic_december: 4.579,
            ic_yearly: 4.977,
          },
          {
            ic_uuid: "424773bf-d432-441d-b8e0-de787b6e6198",
            ic_lat: -48798,
            ic_lon: -44693,
            ic_city: "Esperantinópolis",
            ic_states: "MARANHÃO",
            ic_january: 4.727,
            ic_february: 4.94,
            ic_march: 4.952,
            ic_april: 4.897,
            ic_may: 4.909,
            ic_june: 5.075,
            ic_july: 5.346,
            ic_august: 5.884,
            ic_september: 6.175,
            ic_october: 5.698,
            ic_november: 5.321,
            ic_december: 4.91,
            ic_yearly: 5.236,
          },
          {
            ic_uuid: "8ba68408-e9e7-45b7-ab1d-422f175be1e2",
            ic_lat: -254221,
            ic_lon: -528352,
            ic_city: "Espigão Alto do Iguaçu",
            ic_states: "PARANÁ",
            ic_january: 6.229,
            ic_february: 5.639,
            ic_march: 5.173,
            ic_april: 4.283,
            ic_may: 3.309,
            ic_june: 2.91,
            ic_july: 3.139,
            ic_august: 4.126,
            ic_september: 4.314,
            ic_october: 5.195,
            ic_november: 6.071,
            ic_december: 6.306,
            ic_yearly: 4.725,
          },
          {
            ic_uuid: "c486de38-4cf6-4b15-9c07-fe3d118fcd3e",
            ic_lat: -11527,
            ic_lon: -610256,
            ic_city: "Espigão D'Oeste",
            ic_states: "RONDÔNIA",
            ic_january: 4.491,
            ic_february: 4.448,
            ic_march: 4.5,
            ic_april: 4.485,
            ic_may: 4.159,
            ic_june: 4.423,
            ic_july: 4.571,
            ic_august: 4.961,
            ic_september: 4.926,
            ic_october: 5.155,
            ic_november: 5.025,
            ic_december: 4.772,
            ic_yearly: 4.659,
          },
          {
            ic_uuid: "40c84a18-3791-4996-afbd-0e36f7878c37",
            ic_lat: -149254,
            ic_lon: -428094,
            ic_city: "Espinosa",
            ic_states: "MINAS GERAIS",
            ic_january: 6.548,
            ic_february: 6.638,
            ic_march: 5.917,
            ic_april: 5.395,
            ic_may: 4.813,
            ic_june: 4.614,
            ic_july: 4.837,
            ic_august: 5.542,
            ic_september: 6.017,
            ic_october: 6.276,
            ic_november: 5.828,
            ic_december: 6.488,
            ic_yearly: 5.743,
          },
          {
            ic_uuid: "7402274a-195c-4db2-8930-fbe5bfda05ce",
            ic_lat: -63329,
            ic_lon: -353117,
            ic_city: "Espírito Santo",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.859,
            ic_february: 6.002,
            ic_march: 6.085,
            ic_april: 5.432,
            ic_may: 4.939,
            ic_june: 4.446,
            ic_july: 4.55,
            ic_august: 5.355,
            ic_september: 5.748,
            ic_october: 6.028,
            ic_november: 6.274,
            ic_december: 5.914,
            ic_yearly: 5.553,
          },
          {
            ic_uuid: "96fe0765-2d33-4a08-9571-65e93d348952",
            ic_lat: -220458,
            ic_lon: -459552,
            ic_city: "Espírito Santo do Dourado",
            ic_states: "MINAS GERAIS",
            ic_january: 5.149,
            ic_february: 5.494,
            ic_march: 4.655,
            ic_april: 4.403,
            ic_may: 3.837,
            ic_june: 3.595,
            ic_july: 3.864,
            ic_august: 4.767,
            ic_september: 4.914,
            ic_october: 5.247,
            ic_november: 5.079,
            ic_december: 5.449,
            ic_yearly: 4.705,
          },
          {
            ic_uuid: "b81aaf35-56c4-491f-b49a-732763abb13f",
            ic_lat: -221913,
            ic_lon: -467481,
            ic_city: "Espírito Santo do Pinhal",
            ic_states: "SÃO PAULO",
            ic_january: 5.476,
            ic_february: 5.645,
            ic_march: 4.995,
            ic_april: 4.66,
            ic_may: 3.932,
            ic_june: 3.667,
            ic_july: 3.88,
            ic_august: 4.763,
            ic_september: 4.946,
            ic_october: 5.465,
            ic_november: 5.626,
            ic_december: 5.848,
            ic_yearly: 4.909,
          },
          {
            ic_uuid: "390fe77a-c2b0-4b5d-9336-4e9e141e8e80",
            ic_lat: -22692,
            ic_lon: -494299,
            ic_city: "Espírito Santo do Turvo",
            ic_states: "SÃO PAULO",
            ic_january: 5.598,
            ic_february: 5.779,
            ic_march: 5.203,
            ic_april: 4.681,
            ic_may: 3.759,
            ic_june: 3.489,
            ic_july: 3.711,
            ic_august: 4.694,
            ic_september: 4.744,
            ic_october: 5.431,
            ic_november: 5.893,
            ic_december: 6.214,
            ic_yearly: 4.933,
          },
          {
            ic_uuid: "95773229-56b7-4f64-a5eb-70a4d65ec730",
            ic_lat: -11793,
            ic_lon: -379506,
            ic_city: "Esplanada",
            ic_states: "BAHIA",
            ic_january: 6.101,
            ic_february: 5.91,
            ic_march: 5.685,
            ic_april: 4.771,
            ic_may: 4.008,
            ic_june: 3.842,
            ic_july: 3.962,
            ic_august: 4.397,
            ic_september: 5.237,
            ic_october: 5.613,
            ic_november: 5.99,
            ic_december: 6.203,
            ic_yearly: 5.143,
          },
          {
            ic_uuid: "23715c81-be84-41de-a1ac-ac8a5e1e55f8",
            ic_lat: -287291,
            ic_lon: -528465,
            ic_city: "Espumoso",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.336,
            ic_february: 5.942,
            ic_march: 5.031,
            ic_april: 3.981,
            ic_may: 3.007,
            ic_june: 2.464,
            ic_july: 2.758,
            ic_august: 3.549,
            ic_september: 3.852,
            ic_october: 5.039,
            ic_november: 6.273,
            ic_december: 6.679,
            ic_yearly: 4.576,
          },
          {
            ic_uuid: "3d8a6723-1bef-418c-b4d7-330d625b394f",
            ic_lat: -279139,
            ic_lon: -52264,
            ic_city: "Estação",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.259,
            ic_february: 5.797,
            ic_march: 4.941,
            ic_april: 4.022,
            ic_may: 3.043,
            ic_june: 2.56,
            ic_july: 2.901,
            ic_august: 3.673,
            ic_september: 3.918,
            ic_october: 5.041,
            ic_november: 6.158,
            ic_december: 6.501,
            ic_yearly: 4.568,
          },
          {
            ic_uuid: "7549de34-5435-4985-b5b3-9c597f659faa",
            ic_lat: -112686,
            ic_lon: -374376,
            ic_city: "Estância",
            ic_states: "SERGIPE",
            ic_january: 6.258,
            ic_february: 6.03,
            ic_march: 5.939,
            ic_april: 4.973,
            ic_may: 4.22,
            ic_june: 3.947,
            ic_july: 4.091,
            ic_august: 4.578,
            ic_september: 5.333,
            ic_october: 5.728,
            ic_november: 6.188,
            ic_december: 6.389,
            ic_yearly: 5.306,
          },
          {
            ic_uuid: "670fc774-186a-4d72-961c-d075957ed9fc",
            ic_lat: -29654,
            ic_lon: -511848,
            ic_city: "Estância Velha",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.243,
            ic_february: 5.746,
            ic_march: 4.782,
            ic_april: 3.779,
            ic_may: 2.827,
            ic_june: 2.32,
            ic_july: 2.526,
            ic_august: 3.201,
            ic_september: 3.557,
            ic_october: 4.629,
            ic_november: 6.079,
            ic_december: 6.567,
            ic_yearly: 4.354,
          },
          {
            ic_uuid: "2ca48d31-b59f-49a9-b92e-9180f5e9ee60",
            ic_lat: -298524,
            ic_lon: -511845,
            ic_city: "Esteio",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.34,
            ic_february: 5.783,
            ic_march: 4.815,
            ic_april: 3.789,
            ic_may: 2.781,
            ic_june: 2.315,
            ic_july: 2.515,
            ic_august: 3.195,
            ic_september: 3.577,
            ic_october: 4.73,
            ic_november: 6.185,
            ic_december: 6.627,
            ic_yearly: 4.388,
          },
          {
            ic_uuid: "586ca54f-6a68-4cee-9078-78800e6cf82d",
            ic_lat: -224582,
            ic_lon: -460194,
            ic_city: "Estiva",
            ic_states: "MINAS GERAIS",
            ic_january: 5.128,
            ic_february: 5.489,
            ic_march: 4.7,
            ic_april: 4.444,
            ic_may: 3.769,
            ic_june: 3.515,
            ic_july: 3.767,
            ic_august: 4.669,
            ic_september: 4.82,
            ic_october: 5.232,
            ic_november: 5.216,
            ic_december: 5.571,
            ic_yearly: 4.694,
          },
          {
            ic_uuid: "730716ef-deb9-4a9a-bfc1-53542ce4f582",
            ic_lat: -222718,
            ic_lon: -469485,
            ic_city: "Estiva Gerbi",
            ic_states: "SÃO PAULO",
            ic_january: 5.59,
            ic_february: 5.809,
            ic_march: 5.156,
            ic_april: 4.687,
            ic_may: 3.922,
            ic_june: 3.647,
            ic_july: 3.847,
            ic_august: 4.748,
            ic_september: 4.949,
            ic_october: 5.548,
            ic_november: 5.772,
            ic_december: 6.108,
            ic_yearly: 4.982,
          },
          {
            ic_uuid: "1f0beeb2-b820-4841-ba1e-556e0626eb7f",
            ic_lat: -65612,
            ic_lon: -474434,
            ic_city: "Estreito",
            ic_states: "MARANHÃO",
            ic_january: 4.818,
            ic_february: 4.901,
            ic_march: 4.894,
            ic_april: 4.916,
            ic_may: 4.935,
            ic_june: 5.221,
            ic_july: 5.42,
            ic_august: 5.884,
            ic_september: 5.903,
            ic_october: 5.347,
            ic_november: 4.861,
            ic_december: 4.773,
            ic_yearly: 5.156,
          },
          {
            ic_uuid: "603c48a9-6e4c-42b0-a91f-1a9c2aeededf",
            ic_lat: -294888,
            ic_lon: -519531,
            ic_city: "Estrela",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.139,
            ic_february: 5.756,
            ic_march: 4.816,
            ic_april: 3.794,
            ic_may: 2.811,
            ic_june: 2.33,
            ic_july: 2.568,
            ic_august: 3.28,
            ic_september: 3.589,
            ic_october: 4.64,
            ic_november: 6.124,
            ic_december: 6.499,
            ic_yearly: 4.362,
          },
          {
            ic_uuid: "f789f225-785b-44c0-89aa-de5f5a9eed39",
            ic_lat: -217417,
            ic_lon: -424578,
            ic_city: "Estrela Dalva",
            ic_states: "MINAS GERAIS",
            ic_january: 5.889,
            ic_february: 6.039,
            ic_march: 5.081,
            ic_april: 4.409,
            ic_may: 3.647,
            ic_june: 3.416,
            ic_july: 3.549,
            ic_august: 4.272,
            ic_september: 4.684,
            ic_october: 4.998,
            ic_november: 4.944,
            ic_december: 5.742,
            ic_yearly: 4.722,
          },
          {
            ic_uuid: "694df4b6-9550-440f-aa32-a6b055e1ba65",
            ic_lat: -9389,
            ic_lon: -3676,
            ic_city: "Estrela de Alagoas",
            ic_states: "ALAGOAS",
            ic_january: 6.122,
            ic_february: 5.947,
            ic_march: 5.972,
            ic_april: 5.259,
            ic_may: 4.355,
            ic_june: 3.971,
            ic_july: 4.116,
            ic_august: 4.807,
            ic_september: 5.721,
            ic_october: 6.041,
            ic_november: 6.588,
            ic_december: 6.468,
            ic_yearly: 5.447,
          },
          {
            ic_uuid: "b843597e-4757-4fe6-b252-dd095e927467",
            ic_lat: -195188,
            ic_lon: -4579,
            ic_city: "Estrela do Indaiá",
            ic_states: "MINAS GERAIS",
            ic_january: 5.817,
            ic_february: 6.107,
            ic_march: 5.098,
            ic_april: 4.946,
            ic_may: 4.544,
            ic_june: 4.298,
            ic_july: 4.542,
            ic_august: 5.497,
            ic_september: 5.573,
            ic_october: 5.619,
            ic_november: 5.219,
            ic_december: 5.634,
            ic_yearly: 5.241,
          },
          {
            ic_uuid: "2b6c138e-814f-428c-ab0d-2d697c5ffaf9",
            ic_lat: -138676,
            ic_lon: -490688,
            ic_city: "Estrela do Norte",
            ic_states: "GOIÁS",
            ic_january: 5.357,
            ic_february: 5.494,
            ic_march: 5.126,
            ic_april: 5.119,
            ic_may: 5.006,
            ic_june: 4.864,
            ic_july: 5.202,
            ic_august: 5.909,
            ic_september: 5.688,
            ic_october: 5.56,
            ic_november: 5.132,
            ic_december: 5.338,
            ic_yearly: 5.316,
          },
          {
            ic_uuid: "d08fb061-525c-471a-b44f-dc859bca052d",
            ic_lat: -224879,
            ic_lon: -5166,
            ic_city: "Estrela do Norte",
            ic_states: "SÃO PAULO",
            ic_january: 5.9,
            ic_february: 5.919,
            ic_march: 5.427,
            ic_april: 4.76,
            ic_may: 3.808,
            ic_june: 3.508,
            ic_july: 3.718,
            ic_august: 4.677,
            ic_september: 4.819,
            ic_october: 5.505,
            ic_november: 6.078,
            ic_december: 6.43,
            ic_yearly: 5.046,
          },
          {
            ic_uuid: "6c053881-6657-4828-b95b-26ae5a54e04c",
            ic_lat: -187404,
            ic_lon: -47696,
            ic_city: "Estrela do Sul",
            ic_states: "MINAS GERAIS",
            ic_january: 5.672,
            ic_february: 5.997,
            ic_march: 5.187,
            ic_april: 5.039,
            ic_may: 4.622,
            ic_june: 4.362,
            ic_july: 4.635,
            ic_august: 5.548,
            ic_september: 5.552,
            ic_october: 5.638,
            ic_november: 5.488,
            ic_december: 5.762,
            ic_yearly: 5.292,
          },
          {
            ic_uuid: "ef65b918-1629-42fd-b8fb-dd6ca996b86b",
            ic_lat: -20288,
            ic_lon: -504009,
            ic_city: "Estrela d'Oeste",
            ic_states: "SÃO PAULO",
            ic_january: 5.815,
            ic_february: 5.911,
            ic_march: 5.295,
            ic_april: 4.911,
            ic_may: 4.211,
            ic_june: 3.948,
            ic_july: 4.162,
            ic_august: 5.09,
            ic_september: 5.117,
            ic_october: 5.642,
            ic_november: 5.949,
            ic_december: 6.216,
            ic_yearly: 5.189,
          },
          {
            ic_uuid: "173604e8-f105-4fc9-a59b-3ca1800160d9",
            ic_lat: -29177,
            ic_lon: -531589,
            ic_city: "Estrela Velha",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.369,
            ic_february: 5.954,
            ic_march: 5.047,
            ic_april: 3.904,
            ic_may: 2.933,
            ic_june: 2.42,
            ic_july: 2.693,
            ic_august: 3.485,
            ic_september: 3.808,
            ic_october: 4.967,
            ic_november: 6.27,
            ic_december: 6.728,
            ic_yearly: 4.548,
          },
          {
            ic_uuid: "8e81034f-0849-44a8-925e-b714419678d9",
            ic_lat: -105082,
            ic_lon: -390156,
            ic_city: "Euclides da Cunha",
            ic_states: "BAHIA",
            ic_january: 6.292,
            ic_february: 6.032,
            ic_march: 5.921,
            ic_april: 5.094,
            ic_may: 4.284,
            ic_june: 3.868,
            ic_july: 4.1,
            ic_august: 4.662,
            ic_september: 5.707,
            ic_october: 5.839,
            ic_november: 6.324,
            ic_december: 6.2,
            ic_yearly: 5.36,
          },
          {
            ic_uuid: "693586b4-88eb-40b3-acf3-cae73961103e",
            ic_lat: -22555,
            ic_lon: -525932,
            ic_city: "Euclides da Cunha Paulista",
            ic_states: "SÃO PAULO",
            ic_january: 6.07,
            ic_february: 5.995,
            ic_march: 5.453,
            ic_april: 4.69,
            ic_may: 3.724,
            ic_june: 3.377,
            ic_july: 3.585,
            ic_august: 4.494,
            ic_september: 4.721,
            ic_october: 5.417,
            ic_november: 6.143,
            ic_december: 6.48,
            ic_yearly: 5.012,
          },
          {
            ic_uuid: "8916e039-bc17-4322-a042-80615d8e09b0",
            ic_lat: -285319,
            ic_lon: -541511,
            ic_city: "Eugênio de Castro",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.499,
            ic_february: 6.073,
            ic_march: 5.19,
            ic_april: 4.033,
            ic_may: 3.06,
            ic_june: 2.518,
            ic_july: 2.806,
            ic_august: 3.571,
            ic_september: 3.999,
            ic_october: 5.117,
            ic_november: 6.299,
            ic_december: 6.738,
            ic_yearly: 4.658,
          },
          {
            ic_uuid: "ab5080c2-d56e-4a78-a380-34c9bbd83f62",
            ic_lat: -211006,
            ic_lon: -421882,
            ic_city: "Eugenópolis",
            ic_states: "MINAS GERAIS",
            ic_january: 5.994,
            ic_february: 6.204,
            ic_march: 5.092,
            ic_april: 4.508,
            ic_may: 3.816,
            ic_june: 3.6,
            ic_july: 3.731,
            ic_august: 4.52,
            ic_september: 4.896,
            ic_october: 5.138,
            ic_november: 4.941,
            ic_december: 5.805,
            ic_yearly: 4.853,
          },
          {
            ic_uuid: "18ab1fe3-7691-4043-8d2e-451ebc3542a9",
            ic_lat: -16372,
            ic_lon: -395825,
            ic_city: "Eunápolis",
            ic_states: "BAHIA",
            ic_january: 5.889,
            ic_february: 5.951,
            ic_march: 5.333,
            ic_april: 4.587,
            ic_may: 3.869,
            ic_june: 3.504,
            ic_july: 3.71,
            ic_august: 4.241,
            ic_september: 4.865,
            ic_october: 5.097,
            ic_november: 5.128,
            ic_december: 5.973,
            ic_yearly: 4.846,
          },
          {
            ic_uuid: "5b065787-0980-4699-8c2c-80c93c3c6e2e",
            ic_lat: -38929,
            ic_lon: -384562,
            ic_city: "Eusébio",
            ic_states: "CEARÁ",
            ic_january: 5.718,
            ic_february: 5.808,
            ic_march: 5.606,
            ic_april: 4.903,
            ic_may: 5.211,
            ic_june: 5.237,
            ic_july: 5.441,
            ic_august: 5.947,
            ic_september: 6.179,
            ic_october: 6.368,
            ic_november: 6.373,
            ic_december: 5.974,
            ic_yearly: 5.73,
          },
          {
            ic_uuid: "36c431c4-d0ec-47bb-98af-024445d902ac",
            ic_lat: -215503,
            ic_lon: -435072,
            ic_city: "Ewbank da Câmara",
            ic_states: "MINAS GERAIS",
            ic_january: 5.326,
            ic_february: 5.77,
            ic_march: 4.697,
            ic_april: 4.159,
            ic_may: 3.557,
            ic_june: 3.422,
            ic_july: 3.603,
            ic_august: 4.38,
            ic_september: 4.639,
            ic_october: 4.823,
            ic_november: 4.614,
            ic_december: 5.38,
            ic_yearly: 4.531,
          },
          {
            ic_uuid: "2749e4d1-64cf-4fa1-844e-ba72370367ef",
            ic_lat: -228545,
            ic_lon: -463182,
            ic_city: "Extrema",
            ic_states: "MINAS GERAIS",
            ic_january: 5.271,
            ic_february: 5.502,
            ic_march: 4.811,
            ic_april: 4.525,
            ic_may: 3.761,
            ic_june: 3.499,
            ic_july: 3.634,
            ic_august: 4.627,
            ic_september: 4.767,
            ic_october: 5.294,
            ic_november: 5.432,
            ic_december: 5.813,
            ic_yearly: 4.745,
          },
          {
            ic_uuid: "7c19a9dd-ffd3-4700-946d-da20cc0923e1",
            ic_lat: -57043,
            ic_lon: -352895,
            ic_city: "Extremoz",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.995,
            ic_february: 6.058,
            ic_march: 6.025,
            ic_april: 5.583,
            ic_may: 5.112,
            ic_june: 4.704,
            ic_july: 4.873,
            ic_august: 5.624,
            ic_september: 6.014,
            ic_october: 6.086,
            ic_november: 6.252,
            ic_december: 5.971,
            ic_yearly: 5.691,
          },
          {
            ic_uuid: "c0b73f4c-f96c-4e99-bf1f-3077f19f936b",
            ic_lat: -7515,
            ic_lon: -397232,
            ic_city: "Exu",
            ic_states: "PERNAMBUCO",
            ic_january: 5.851,
            ic_february: 5.692,
            ic_march: 5.719,
            ic_april: 5.27,
            ic_may: 4.756,
            ic_june: 4.483,
            ic_july: 4.787,
            ic_august: 5.709,
            ic_september: 6.459,
            ic_october: 6.507,
            ic_november: 6.466,
            ic_december: 6.095,
            ic_yearly: 5.65,
          },
          {
            ic_uuid: "6e905af3-a845-40d4-b640-184597b81a33",
            ic_lat: -73582,
            ic_lon: -357852,
            ic_city: "Fagundes",
            ic_states: "PARAÍBA",
            ic_january: 5.703,
            ic_february: 5.746,
            ic_march: 5.807,
            ic_april: 5.249,
            ic_may: 4.549,
            ic_june: 4.068,
            ic_july: 4.184,
            ic_august: 4.888,
            ic_september: 5.422,
            ic_october: 5.731,
            ic_november: 5.994,
            ic_december: 5.901,
            ic_yearly: 5.27,
          },
          {
            ic_uuid: "44e7456a-204e-4d71-b187-09ea8d11dd8a",
            ic_lat: -288799,
            ic_lon: -517019,
            ic_city: "Fagundes Varela",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.225,
            ic_february: 5.746,
            ic_march: 4.85,
            ic_april: 3.881,
            ic_may: 2.873,
            ic_june: 2.38,
            ic_july: 2.711,
            ic_august: 3.432,
            ic_september: 3.706,
            ic_october: 4.77,
            ic_november: 6.114,
            ic_december: 6.498,
            ic_yearly: 4.432,
          },
          {
            ic_uuid: "e8cec638-fa20-414c-bb48-6fca7849280c",
            ic_lat: -154477,
            ic_lon: -503626,
            ic_city: "Faina",
            ic_states: "GOIÁS",
            ic_january: 5.39,
            ic_february: 5.566,
            ic_march: 5.325,
            ic_april: 5.251,
            ic_may: 4.969,
            ic_june: 4.619,
            ic_july: 4.826,
            ic_august: 5.66,
            ic_september: 5.493,
            ic_october: 5.622,
            ic_november: 5.402,
            ic_december: 5.434,
            ic_yearly: 5.296,
          },
          {
            ic_uuid: "0424de89-fff6-4595-9fa3-5a718eefab4f",
            ic_lat: -214094,
            ic_lon: -45829,
            ic_city: "Fama",
            ic_states: "MINAS GERAIS",
            ic_january: 5.651,
            ic_february: 5.865,
            ic_march: 4.991,
            ic_april: 4.645,
            ic_may: 3.986,
            ic_june: 3.709,
            ic_july: 4.006,
            ic_august: 4.84,
            ic_september: 5.063,
            ic_october: 5.438,
            ic_november: 5.34,
            ic_december: 5.757,
            ic_yearly: 4.941,
          },
          {
            ic_uuid: "c5e1cd81-bef6-4c9f-b20b-6c363c9f37e6",
            ic_lat: -208102,
            ic_lon: -420217,
            ic_city: "Faria Lemos",
            ic_states: "MINAS GERAIS",
            ic_january: 5.942,
            ic_february: 6.185,
            ic_march: 5.038,
            ic_april: 4.508,
            ic_may: 3.8,
            ic_june: 3.679,
            ic_july: 3.792,
            ic_august: 4.559,
            ic_september: 4.946,
            ic_october: 5.087,
            ic_november: 4.863,
            ic_december: 5.698,
            ic_yearly: 4.841,
          },
          {
            ic_uuid: "003d5787-42bb-49cd-a8be-e01e68e86d8e",
            ic_lat: -69219,
            ic_lon: -395654,
            ic_city: "Farias Brito",
            ic_states: "CEARÁ",
            ic_january: 5.732,
            ic_february: 5.752,
            ic_march: 5.804,
            ic_april: 5.468,
            ic_may: 5.194,
            ic_june: 5.074,
            ic_july: 5.371,
            ic_august: 6.114,
            ic_september: 6.526,
            ic_october: 6.475,
            ic_november: 6.455,
            ic_december: 6.027,
            ic_yearly: 5.833,
          },
          {
            ic_uuid: "9bde8c7c-3677-4198-aa23-6f952a2d1cf7",
            ic_lat: -21684,
            ic_lon: -567409,
            ic_city: "Faro",
            ic_states: "PARÁ",
            ic_january: 4.158,
            ic_february: 4.198,
            ic_march: 4.214,
            ic_april: 4.09,
            ic_may: 4.178,
            ic_june: 4.74,
            ic_july: 4.75,
            ic_august: 5.326,
            ic_september: 5.219,
            ic_october: 5.304,
            ic_november: 5.084,
            ic_december: 4.537,
            ic_yearly: 4.65,
          },
          {
            ic_uuid: "93f772af-b1b8-4546-8406-eb79d796534a",
            ic_lat: -240963,
            ic_lon: -526221,
            ic_city: "Farol",
            ic_states: "PARANÁ",
            ic_january: 6.198,
            ic_february: 5.691,
            ic_march: 5.235,
            ic_april: 4.473,
            ic_may: 3.539,
            ic_june: 3.137,
            ic_july: 3.38,
            ic_august: 4.301,
            ic_september: 4.548,
            ic_october: 5.318,
            ic_november: 6.084,
            ic_december: 6.504,
            ic_yearly: 4.867,
          },
          {
            ic_uuid: "cf1d98af-d8b9-4df9-9eed-bc8fd581a99a",
            ic_lat: -292232,
            ic_lon: -513423,
            ic_city: "Farroupilha",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.197,
            ic_february: 5.698,
            ic_march: 4.744,
            ic_april: 3.833,
            ic_may: 2.822,
            ic_june: 2.393,
            ic_july: 2.668,
            ic_august: 3.402,
            ic_september: 3.66,
            ic_october: 4.753,
            ic_november: 6.16,
            ic_december: 6.487,
            ic_yearly: 4.401,
          },
          {
            ic_uuid: "fb54b553-1237-40fc-a242-69224a0c3424",
            ic_lat: -233921,
            ic_lon: -495128,
            ic_city: "Fartura",
            ic_states: "SÃO PAULO",
            ic_january: 5.831,
            ic_february: 5.907,
            ic_march: 5.253,
            ic_april: 4.555,
            ic_may: 3.61,
            ic_june: 3.249,
            ic_july: 3.479,
            ic_august: 4.449,
            ic_september: 4.616,
            ic_october: 5.326,
            ic_november: 5.95,
            ic_december: 6.442,
            ic_yearly: 4.889,
          },
          {
            ic_uuid: "46d10aa9-7d6c-4959-acba-ac7ced88c81a",
            ic_lat: -94791,
            ic_lon: -427906,
            ic_city: "Fartura do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 6.055,
            ic_february: 5.815,
            ic_march: 5.696,
            ic_april: 5.405,
            ic_may: 5.251,
            ic_june: 5.223,
            ic_july: 5.632,
            ic_august: 6.361,
            ic_september: 6.622,
            ic_october: 6.463,
            ic_november: 6.184,
            ic_december: 6.089,
            ic_yearly: 5.899,
          },
          {
            ic_uuid: "026837d5-8e5a-406a-904d-9eb6a947a332",
            ic_lat: -10594,
            ic_lon: -382116,
            ic_city: "Fátima",
            ic_states: "BAHIA",
            ic_january: 6.081,
            ic_february: 5.893,
            ic_march: 5.813,
            ic_april: 5.035,
            ic_may: 4.203,
            ic_june: 3.848,
            ic_july: 3.964,
            ic_august: 4.462,
            ic_september: 5.33,
            ic_october: 5.55,
            ic_november: 6.14,
            ic_december: 6.146,
            ic_yearly: 5.206,
          },
          {
            ic_uuid: "81d8fb5c-b71c-42a8-89c8-ecf4ec8160dc",
            ic_lat: -107579,
            ic_lon: -48903,
            ic_city: "Fátima",
            ic_states: "TOCANTINS",
            ic_january: 5.035,
            ic_february: 5.092,
            ic_march: 4.841,
            ic_april: 4.929,
            ic_may: 4.976,
            ic_june: 5.087,
            ic_july: 5.444,
            ic_august: 5.96,
            ic_september: 5.703,
            ic_october: 5.302,
            ic_november: 5.141,
            ic_december: 5.064,
            ic_yearly: 5.214,
          },
          {
            ic_uuid: "2b39ee99-24b0-461c-8183-88f058d51483",
            ic_lat: -223794,
            ic_lon: -545135,
            ic_city: "Fátima do Sul",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.926,
            ic_february: 5.77,
            ic_march: 5.306,
            ic_april: 4.545,
            ic_may: 3.669,
            ic_june: 3.344,
            ic_july: 3.499,
            ic_august: 4.45,
            ic_september: 4.736,
            ic_october: 5.347,
            ic_november: 5.962,
            ic_december: 6.44,
            ic_yearly: 4.916,
          },
          {
            ic_uuid: "467df4c9-b973-489c-9fd2-0fc195858168",
            ic_lat: -240081,
            ic_lon: -513231,
            ic_city: "Faxinal",
            ic_states: "PARANÁ",
            ic_january: 5.766,
            ic_february: 5.622,
            ic_march: 5.095,
            ic_april: 4.465,
            ic_may: 3.509,
            ic_june: 3.205,
            ic_july: 3.427,
            ic_august: 4.375,
            ic_september: 4.529,
            ic_october: 5.173,
            ic_november: 5.893,
            ic_december: 6.177,
            ic_yearly: 4.77,
          },
          {
            ic_uuid: "69743597-f4a1-49a3-95bd-91a6cd0d9b54",
            ic_lat: -29575,
            ic_lon: -534449,
            ic_city: "Faxinal do Soturno",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.326,
            ic_february: 5.909,
            ic_march: 4.987,
            ic_april: 3.856,
            ic_may: 2.831,
            ic_june: 2.351,
            ic_july: 2.593,
            ic_august: 3.344,
            ic_september: 3.723,
            ic_october: 4.895,
            ic_november: 6.247,
            ic_december: 6.739,
            ic_yearly: 4.483,
          },
          {
            ic_uuid: "a2cc9efc-3979-494a-a67e-116e8d975463",
            ic_lat: -268456,
            ic_lon: -522601,
            ic_city: "Faxinal dos Guedes",
            ic_states: "SANTA CATARINA",
            ic_january: 6.211,
            ic_february: 5.537,
            ic_march: 5.025,
            ic_april: 4.067,
            ic_may: 3.179,
            ic_june: 2.74,
            ic_july: 2.996,
            ic_august: 3.867,
            ic_september: 4.091,
            ic_october: 5.094,
            ic_november: 6.04,
            ic_december: 6.304,
            ic_yearly: 4.596,
          },
          {
            ic_uuid: "4dcd60d1-f443-41b4-8dbe-4b7b3dafd05d",
            ic_lat: -274242,
            ic_lon: -526794,
            ic_city: "Faxinalzinho",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.273,
            ic_february: 5.8,
            ic_march: 5.091,
            ic_april: 4.089,
            ic_may: 3.113,
            ic_june: 2.633,
            ic_july: 2.915,
            ic_august: 3.77,
            ic_september: 4.012,
            ic_october: 5.145,
            ic_november: 6.16,
            ic_december: 6.549,
            ic_yearly: 4.629,
          },
          {
            ic_uuid: "537a99a6-a641-4330-8133-c827aea373de",
            ic_lat: -1618,
            ic_lon: -507822,
            ic_city: "Fazenda Nova",
            ic_states: "GOIÁS",
            ic_january: 5.561,
            ic_february: 5.639,
            ic_march: 5.334,
            ic_april: 5.286,
            ic_may: 4.909,
            ic_june: 4.571,
            ic_july: 4.705,
            ic_august: 5.444,
            ic_september: 5.415,
            ic_october: 5.647,
            ic_november: 5.492,
            ic_december: 5.52,
            ic_yearly: 5.294,
          },
          {
            ic_uuid: "496dd4dc-d4c2-499e-b7d8-db3a0883bd73",
            ic_lat: -256628,
            ic_lon: -493077,
            ic_city: "Fazenda Rio Grande",
            ic_states: "PARANÁ",
            ic_january: 5.379,
            ic_february: 5.164,
            ic_march: 4.506,
            ic_april: 3.731,
            ic_may: 2.99,
            ic_june: 2.681,
            ic_july: 2.838,
            ic_august: 3.802,
            ic_september: 3.806,
            ic_october: 4.367,
            ic_november: 5.241,
            ic_december: 5.599,
            ic_yearly: 4.175,
          },
          {
            ic_uuid: "ccc2dd1e-eee1-4967-9583-292b639a22b3",
            ic_lat: -29589,
            ic_lon: -518222,
            ic_city: "Fazenda Vilanova",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.308,
            ic_february: 5.793,
            ic_march: 4.882,
            ic_april: 3.788,
            ic_may: 2.803,
            ic_june: 2.328,
            ic_july: 2.538,
            ic_august: 3.271,
            ic_september: 3.607,
            ic_october: 4.668,
            ic_november: 6.183,
            ic_december: 6.596,
            ic_yearly: 4.397,
          },
          {
            ic_uuid: "7d305a4b-d30f-4ca6-b318-cdc6f2403457",
            ic_lat: -81635,
            ic_lon: -703537,
            ic_city: "Feijó",
            ic_states: "ACRE",
            ic_january: 4.505,
            ic_february: 4.806,
            ic_march: 4.211,
            ic_april: 4.384,
            ic_may: 3.993,
            ic_june: 4.088,
            ic_july: 4.275,
            ic_august: 4.951,
            ic_september: 5.09,
            ic_october: 5.09,
            ic_november: 4.785,
            ic_december: 4.503,
            ic_yearly: 4.557,
          },
          {
            ic_uuid: "54d7931c-ceed-4aa8-9551-8c3cfeb4e21b",
            ic_lat: -142102,
            ic_lon: -442795,
            ic_city: "Feira da Mata",
            ic_states: "BAHIA",
            ic_january: 6.448,
            ic_february: 6.564,
            ic_march: 5.875,
            ic_april: 5.631,
            ic_may: 5.138,
            ic_june: 4.941,
            ic_july: 5.285,
            ic_august: 5.795,
            ic_september: 6.155,
            ic_october: 6.236,
            ic_november: 5.718,
            ic_december: 6.327,
            ic_yearly: 5.843,
          },
          {
            ic_uuid: "8cbdf80d-aaaf-4a7c-9489-a99ddb251e5a",
            ic_lat: -122669,
            ic_lon: -389666,
            ic_city: "Feira de Santana",
            ic_states: "BAHIA",
            ic_january: 6.062,
            ic_february: 5.882,
            ic_march: 5.734,
            ic_april: 4.627,
            ic_may: 3.948,
            ic_june: 3.563,
            ic_july: 3.866,
            ic_august: 4.346,
            ic_september: 5.246,
            ic_october: 5.527,
            ic_november: 5.727,
            ic_december: 6.083,
            ic_yearly: 5.051,
          },
          {
            ic_uuid: "c229dafd-f686-4b7e-a359-64b111d8b0f5",
            ic_lat: -98986,
            ic_lon: -36679,
            ic_city: "Feira Grande",
            ic_states: "ALAGOAS",
            ic_january: 5.966,
            ic_february: 5.788,
            ic_march: 5.762,
            ic_april: 5.062,
            ic_may: 4.264,
            ic_june: 3.959,
            ic_july: 4.025,
            ic_august: 4.6,
            ic_september: 5.402,
            ic_october: 5.696,
            ic_november: 6.217,
            ic_december: 6.209,
            ic_yearly: 5.246,
          },
          {
            ic_uuid: "856ae446-c95e-4b2c-b908-4bf704ec3dd6",
            ic_lat: -7948,
            ic_lon: -353888,
            ic_city: "Feira Nova",
            ic_states: "PERNAMBUCO",
            ic_january: 5.753,
            ic_february: 5.798,
            ic_march: 5.747,
            ic_april: 5.202,
            ic_may: 4.456,
            ic_june: 4.127,
            ic_july: 4.253,
            ic_august: 4.891,
            ic_september: 5.541,
            ic_october: 5.747,
            ic_november: 5.977,
            ic_december: 5.959,
            ic_yearly: 5.288,
          },
          {
            ic_uuid: "717fb9f6-69c9-4a2a-813f-6d3393c82e91",
            ic_lat: -102637,
            ic_lon: -373095,
            ic_city: "Feira Nova",
            ic_states: "SERGIPE",
            ic_january: 6.006,
            ic_february: 5.828,
            ic_march: 5.783,
            ic_april: 5.032,
            ic_may: 4.24,
            ic_june: 3.999,
            ic_july: 4.074,
            ic_august: 4.685,
            ic_september: 5.418,
            ic_october: 5.646,
            ic_november: 6.218,
            ic_december: 6.147,
            ic_yearly: 5.256,
          },
          {
            ic_uuid: "27e3cd53-2bac-4cff-a18f-4266f8a0205e",
            ic_lat: -69624,
            ic_lon: -46682,
            ic_city: "Feira Nova do Maranhão",
            ic_states: "MARANHÃO",
            ic_january: 4.635,
            ic_february: 4.721,
            ic_march: 4.732,
            ic_april: 4.859,
            ic_may: 5.014,
            ic_june: 5.343,
            ic_july: 5.468,
            ic_august: 6.043,
            ic_september: 6.186,
            ic_october: 5.435,
            ic_november: 4.845,
            ic_december: 4.738,
            ic_yearly: 5.168,
          },
          {
            ic_uuid: "47f3bd36-d753-4292-a3e2-7f19b7f1eaf0",
            ic_lat: -18076,
            ic_lon: -432426,
            ic_city: "Felício dos Santos",
            ic_states: "MINAS GERAIS",
            ic_january: 5.8,
            ic_february: 6.152,
            ic_march: 5.119,
            ic_april: 4.638,
            ic_may: 4.128,
            ic_june: 3.989,
            ic_july: 4.198,
            ic_august: 4.965,
            ic_september: 5.427,
            ic_october: 5.422,
            ic_november: 4.934,
            ic_december: 5.643,
            ic_yearly: 5.035,
          },
          {
            ic_uuid: "9f47636e-5b7a-4e96-992b-a6e5d23dc4b7",
            ic_lat: -5597,
            ic_lon: -376967,
            ic_city: "Felipe Guerra",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.978,
            ic_february: 6.049,
            ic_march: 5.958,
            ic_april: 5.707,
            ic_may: 5.341,
            ic_june: 4.99,
            ic_july: 5.382,
            ic_august: 6.02,
            ic_september: 6.347,
            ic_october: 6.561,
            ic_november: 6.521,
            ic_december: 6.04,
            ic_yearly: 5.908,
          },
          {
            ic_uuid: "29ed03cd-8c08-4c24-ad40-cfd4b6071365",
            ic_lat: -166353,
            ic_lon: -407608,
            ic_city: "Felisburgo",
            ic_states: "MINAS GERAIS",
            ic_january: 5.947,
            ic_february: 6.122,
            ic_march: 5.265,
            ic_april: 4.594,
            ic_may: 3.857,
            ic_june: 3.487,
            ic_july: 3.622,
            ic_august: 4.248,
            ic_september: 5.051,
            ic_october: 5.202,
            ic_november: 5.083,
            ic_december: 5.968,
            ic_yearly: 4.87,
          },
          {
            ic_uuid: "b831b661-9c29-4259-bb31-2da87e92733b",
            ic_lat: -187546,
            ic_lon: -449006,
            ic_city: "Felixlândia",
            ic_states: "MINAS GERAIS",
            ic_january: 6.057,
            ic_february: 6.329,
            ic_march: 5.444,
            ic_april: 5.26,
            ic_may: 4.627,
            ic_june: 4.316,
            ic_july: 4.719,
            ic_august: 5.409,
            ic_september: 5.796,
            ic_october: 5.757,
            ic_november: 5.518,
            ic_december: 5.889,
            ic_yearly: 5.427,
          },
          {
            ic_uuid: "1ba2210d-99fc-417b-8e39-9be47e533ccd",
            ic_lat: -294532,
            ic_lon: -513036,
            ic_city: "Feliz",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.094,
            ic_february: 5.642,
            ic_march: 4.743,
            ic_april: 3.774,
            ic_may: 2.812,
            ic_june: 2.337,
            ic_july: 2.554,
            ic_august: 3.238,
            ic_september: 3.524,
            ic_october: 4.564,
            ic_november: 6.044,
            ic_december: 6.417,
            ic_yearly: 4.312,
          },
          {
            ic_uuid: "bf2766e1-8162-4da6-88e1-a411f08beee4",
            ic_lat: -102939,
            ic_lon: -363031,
            ic_city: "Feliz Deserto",
            ic_states: "ALAGOAS",
            ic_january: 6.257,
            ic_february: 6.212,
            ic_march: 6.051,
            ic_april: 5.04,
            ic_may: 4.364,
            ic_june: 4.031,
            ic_july: 4.142,
            ic_august: 4.785,
            ic_september: 5.523,
            ic_october: 6.027,
            ic_november: 6.346,
            ic_december: 6.488,
            ic_yearly: 5.439,
          },
          {
            ic_uuid: "0a82bb64-c0c3-4a1b-bfa4-6ade2e55190c",
            ic_lat: -123822,
            ic_lon: -549389,
            ic_city: "Feliz Natal",
            ic_states: "MATO GROSSO",
            ic_january: 5.141,
            ic_february: 5.153,
            ic_march: 4.979,
            ic_april: 4.983,
            ic_may: 4.911,
            ic_june: 4.704,
            ic_july: 4.981,
            ic_august: 5.306,
            ic_september: 5.152,
            ic_october: 5.287,
            ic_november: 5.138,
            ic_december: 5.258,
            ic_yearly: 5.083,
          },
          {
            ic_uuid: "bb914dc5-fc96-450a-a524-c040d454d7d2",
            ic_lat: -23914,
            ic_lon: -51981,
            ic_city: "Fênix",
            ic_states: "PARANÁ",
            ic_january: 6.101,
            ic_february: 5.818,
            ic_march: 5.294,
            ic_april: 4.597,
            ic_may: 3.592,
            ic_june: 3.195,
            ic_july: 3.423,
            ic_august: 4.366,
            ic_september: 4.581,
            ic_october: 5.258,
            ic_november: 6.113,
            ic_december: 6.412,
            ic_yearly: 4.896,
          },
          {
            ic_uuid: "899507f4-ea9f-45f5-8a24-e797bada2306",
            ic_lat: -254112,
            ic_lon: -50546,
            ic_city: "Fernandes Pinheiro",
            ic_states: "PARANÁ",
            ic_january: 5.518,
            ic_february: 5.253,
            ic_march: 4.634,
            ic_april: 3.876,
            ic_may: 3.093,
            ic_june: 2.7,
            ic_july: 2.969,
            ic_august: 3.965,
            ic_september: 4.069,
            ic_october: 4.655,
            ic_november: 5.561,
            ic_december: 5.703,
            ic_yearly: 4.333,
          },
          {
            ic_uuid: "f0ef4222-c3e4-42c5-9652-61aec3fa1099",
            ic_lat: -191525,
            ic_lon: -420802,
            ic_city: "Fernandes Tourinho",
            ic_states: "MINAS GERAIS",
            ic_january: 5.946,
            ic_february: 6.109,
            ic_march: 5.129,
            ic_april: 4.561,
            ic_may: 3.858,
            ic_june: 3.632,
            ic_july: 3.715,
            ic_august: 4.39,
            ic_september: 4.865,
            ic_october: 5.1,
            ic_november: 4.884,
            ic_december: 5.775,
            ic_yearly: 4.83,
          },
          {
            ic_uuid: "1e7e021e-d670-4ce4-81dc-fbeb69a749c2",
            ic_lat: -61625,
            ic_lon: -448982,
            ic_city: "Fernando Falcão",
            ic_states: "MARANHÃO",
            ic_january: 4.618,
            ic_february: 4.878,
            ic_march: 4.849,
            ic_april: 4.915,
            ic_may: 4.994,
            ic_june: 5.307,
            ic_july: 5.568,
            ic_august: 6.235,
            ic_september: 6.473,
            ic_october: 5.745,
            ic_november: 5.215,
            ic_december: 4.96,
            ic_yearly: 5.313,
          },
          {
            ic_uuid: "96825edb-a84e-41f2-9e01-965e36dc22aa",
            ic_lat: -56914,
            ic_lon: -365285,
            ic_city: "Fernando Pedroza",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.917,
            ic_february: 5.921,
            ic_march: 5.991,
            ic_april: 5.553,
            ic_may: 5.215,
            ic_june: 4.803,
            ic_july: 5.078,
            ic_august: 5.786,
            ic_september: 6.234,
            ic_october: 6.428,
            ic_november: 6.419,
            ic_december: 6.001,
            ic_yearly: 5.779,
          },
          {
            ic_uuid: "d84dfcc2-9690-49a0-b499-05c18cd2c709",
            ic_lat: -212665,
            ic_lon: -486878,
            ic_city: "Fernando Prestes",
            ic_states: "SÃO PAULO",
            ic_january: 5.738,
            ic_february: 5.95,
            ic_march: 5.233,
            ic_april: 4.831,
            ic_may: 4.111,
            ic_june: 3.85,
            ic_july: 4.069,
            ic_august: 4.958,
            ic_september: 5.054,
            ic_october: 5.62,
            ic_november: 5.896,
            ic_december: 6.149,
            ic_yearly: 5.121,
          },
          {
            ic_uuid: "6b2c203c-f454-4f4e-aa6d-8c83d3900dce",
            ic_lat: -20281,
            ic_lon: -502475,
            ic_city: "Fernandópolis",
            ic_states: "SÃO PAULO",
            ic_january: 5.843,
            ic_february: 5.943,
            ic_march: 5.259,
            ic_april: 4.89,
            ic_may: 4.202,
            ic_june: 3.93,
            ic_july: 4.169,
            ic_august: 5.054,
            ic_september: 5.138,
            ic_october: 5.63,
            ic_november: 5.931,
            ic_december: 6.242,
            ic_yearly: 5.186,
          },
          {
            ic_uuid: "08c16ec7-380c-4afe-b9af-ecaef1804835",
            ic_lat: -223594,
            ic_lon: -495224,
            ic_city: "Fernão",
            ic_states: "SÃO PAULO",
            ic_january: 5.647,
            ic_february: 5.778,
            ic_march: 5.124,
            ic_april: 4.68,
            ic_may: 3.821,
            ic_june: 3.554,
            ic_july: 3.766,
            ic_august: 4.749,
            ic_september: 4.82,
            ic_october: 5.515,
            ic_november: 5.851,
            ic_december: 6.185,
            ic_yearly: 4.957,
          },
          {
            ic_uuid: "93a4498a-d8b7-4054-ae56-3a25b5ae5503",
            ic_lat: -235415,
            ic_lon: -463714,
            ic_city: "Ferraz de Vasconcelos",
            ic_states: "SÃO PAULO",
            ic_january: 5.29,
            ic_february: 5.504,
            ic_march: 4.643,
            ic_april: 4.116,
            ic_may: 3.346,
            ic_june: 3.109,
            ic_july: 3.229,
            ic_august: 4.105,
            ic_september: 4.203,
            ic_october: 4.722,
            ic_november: 5.113,
            ic_december: 5.661,
            ic_yearly: 4.42,
          },
          {
            ic_uuid: "1971c93d-bc42-4dae-83b1-de813b7997ab",
            ic_lat: 8569,
            ic_lon: -511799,
            ic_city: "Ferreira Gomes",
            ic_states: "AMAPÁ",
            ic_january: 4.23,
            ic_february: 4.004,
            ic_march: 4.047,
            ic_april: 4.089,
            ic_may: 4.428,
            ic_june: 4.684,
            ic_july: 4.888,
            ic_august: 5.357,
            ic_september: 5.477,
            ic_october: 5.436,
            ic_november: 5.149,
            ic_december: 4.465,
            ic_yearly: 4.688,
          },
          {
            ic_uuid: "29096f97-e7f7-4ffa-a616-7b517aad4254",
            ic_lat: -74501,
            ic_lon: -352434,
            ic_city: "Ferreiros",
            ic_states: "PERNAMBUCO",
            ic_january: 5.697,
            ic_february: 5.882,
            ic_march: 5.872,
            ic_april: 5.327,
            ic_may: 4.635,
            ic_june: 4.244,
            ic_july: 4.374,
            ic_august: 5.091,
            ic_september: 5.602,
            ic_october: 5.818,
            ic_november: 6.102,
            ic_december: 5.973,
            ic_yearly: 5.385,
          },
          {
            ic_uuid: "5236a96e-4501-499c-b8f3-f68522a030dc",
            ic_lat: -192348,
            ic_lon: -430196,
            ic_city: "Ferros",
            ic_states: "MINAS GERAIS",
            ic_january: 5.74,
            ic_february: 6.024,
            ic_march: 5.038,
            ic_april: 4.53,
            ic_may: 3.87,
            ic_june: 3.647,
            ic_july: 3.89,
            ic_august: 4.633,
            ic_september: 4.975,
            ic_october: 5.16,
            ic_november: 4.803,
            ic_december: 5.499,
            ic_yearly: 4.818,
          },
          {
            ic_uuid: "7d748ed6-b902-4767-bd6d-9047bba0e361",
            ic_lat: -207265,
            ic_lon: -422794,
            ic_city: "Fervedouro",
            ic_states: "MINAS GERAIS",
            ic_january: 5.792,
            ic_february: 6.053,
            ic_march: 4.909,
            ic_april: 4.383,
            ic_may: 3.792,
            ic_june: 3.703,
            ic_july: 3.842,
            ic_august: 4.617,
            ic_september: 4.987,
            ic_october: 5.033,
            ic_november: 4.711,
            ic_december: 5.545,
            ic_yearly: 4.78,
          },
          {
            ic_uuid: "6d241738-08bb-4a2a-8c12-4e283153f199",
            ic_lat: -23849,
            ic_lon: -504029,
            ic_city: "Figueira",
            ic_states: "PARANÁ",
            ic_january: 5.622,
            ic_february: 5.561,
            ic_march: 4.956,
            ic_april: 4.418,
            ic_may: 3.527,
            ic_june: 3.151,
            ic_july: 3.411,
            ic_august: 4.417,
            ic_september: 4.56,
            ic_october: 5.075,
            ic_november: 5.747,
            ic_december: 6.07,
            ic_yearly: 4.71,
          },
          {
            ic_uuid: "eb56ae73-f031-4419-a5b9-b7031d9ef314",
            ic_lat: -186833,
            ic_lon: -536417,
            ic_city: "Figueirão",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.675,
            ic_february: 5.854,
            ic_march: 5.441,
            ic_april: 5.08,
            ic_may: 4.442,
            ic_june: 4.157,
            ic_july: 4.305,
            ic_august: 5.136,
            ic_september: 5.215,
            ic_october: 5.545,
            ic_november: 5.921,
            ic_december: 6.029,
            ic_yearly: 5.233,
          },
          {
            ic_uuid: "166d3e7e-7521-4ac8-bd3d-488d1a6a98aa",
            ic_lat: -121382,
            ic_lon: -491712,
            ic_city: "Figueirópolis",
            ic_states: "TOCANTINS",
            ic_january: 5.368,
            ic_february: 5.404,
            ic_march: 5.069,
            ic_april: 5.104,
            ic_may: 5.039,
            ic_june: 4.776,
            ic_july: 5.125,
            ic_august: 5.744,
            ic_september: 5.756,
            ic_october: 5.492,
            ic_november: 5.227,
            ic_december: 5.301,
            ic_yearly: 5.284,
          },
          {
            ic_uuid: "b1728110-86b8-47dd-b52d-b88a2258b2c4",
            ic_lat: -15449,
            ic_lon: -587382,
            ic_city: "Figueirópolis D'Oeste",
            ic_states: "MATO GROSSO",
            ic_january: 5.2,
            ic_february: 5.039,
            ic_march: 4.85,
            ic_april: 4.656,
            ic_may: 4.059,
            ic_june: 4.194,
            ic_july: 4.311,
            ic_august: 5.062,
            ic_september: 5.018,
            ic_october: 5.215,
            ic_november: 5.446,
            ic_december: 5.403,
            ic_yearly: 4.871,
          },
          {
            ic_uuid: "ddf06946-5cc6-4c88-9be7-2184a82bcb24",
            ic_lat: -107404,
            ic_lon: -401325,
            ic_city: "Filadélfia",
            ic_states: "BAHIA",
            ic_january: 6.076,
            ic_february: 5.905,
            ic_march: 5.847,
            ic_april: 4.815,
            ic_may: 4.088,
            ic_june: 3.786,
            ic_july: 4.008,
            ic_august: 4.569,
            ic_september: 5.568,
            ic_october: 5.645,
            ic_november: 5.961,
            ic_december: 5.983,
            ic_yearly: 5.188,
          },
          {
            ic_uuid: "6ae01d90-ce4b-4c99-91b4-be0cf254e49a",
            ic_lat: -73354,
            ic_lon: -474958,
            ic_city: "Filadélfia",
            ic_states: "TOCANTINS",
            ic_january: 4.869,
            ic_february: 4.925,
            ic_march: 4.806,
            ic_april: 4.847,
            ic_may: 5.105,
            ic_june: 5.344,
            ic_july: 5.32,
            ic_august: 5.943,
            ic_september: 6.031,
            ic_october: 5.342,
            ic_november: 4.835,
            ic_december: 4.774,
            ic_yearly: 5.178,
          },
          {
            ic_uuid: "d4621769-51a3-45c1-b9c4-53797698cf9f",
            ic_lat: -149875,
            ic_lon: -399238,
            ic_city: "Firmino Alves",
            ic_states: "BAHIA",
            ic_january: 5.8,
            ic_february: 5.842,
            ic_march: 5.344,
            ic_april: 4.556,
            ic_may: 3.853,
            ic_june: 3.499,
            ic_july: 3.696,
            ic_august: 4.203,
            ic_september: 4.878,
            ic_october: 5.032,
            ic_november: 5.154,
            ic_december: 5.953,
            ic_yearly: 4.818,
          },
          {
            ic_uuid: "dd6d87bd-baa5-4b37-aa73-28ee7f4175a1",
            ic_lat: -165782,
            ic_lon: -503044,
            ic_city: "Firminópolis",
            ic_states: "GOIÁS",
            ic_january: 5.647,
            ic_february: 5.622,
            ic_march: 5.275,
            ic_april: 5.218,
            ic_may: 4.847,
            ic_june: 4.54,
            ic_july: 4.743,
            ic_august: 5.535,
            ic_september: 5.446,
            ic_october: 5.567,
            ic_november: 5.603,
            ic_december: 5.579,
            ic_yearly: 5.302,
          },
          {
            ic_uuid: "81f74e5f-1432-4d89-935a-f6567913e83d",
            ic_lat: -92732,
            ic_lon: -357142,
            ic_city: "Flexeiras",
            ic_states: "ALAGOAS",
            ic_january: 5.716,
            ic_february: 5.707,
            ic_march: 5.729,
            ic_april: 4.997,
            ic_may: 4.138,
            ic_june: 3.83,
            ic_july: 3.929,
            ic_august: 4.473,
            ic_september: 5.263,
            ic_october: 5.504,
            ic_november: 6.062,
            ic_december: 6.008,
            ic_yearly: 5.113,
          },
          {
            ic_uuid: "ce233292-f18d-48e4-acb2-e10b1032f9f4",
            ic_lat: -262528,
            ic_lon: -533097,
            ic_city: "Flor da Serra do Sul",
            ic_states: "PARANÁ",
            ic_january: 6.317,
            ic_february: 5.707,
            ic_march: 5.126,
            ic_april: 4.173,
            ic_may: 3.23,
            ic_june: 2.75,
            ic_july: 3.061,
            ic_august: 3.982,
            ic_september: 4.24,
            ic_october: 5.177,
            ic_november: 6.06,
            ic_december: 6.299,
            ic_yearly: 4.677,
          },
          {
            ic_uuid: "1e4c668d-f1d1-49ef-baa6-1953d08551cf",
            ic_lat: -267779,
            ic_lon: -533481,
            ic_city: "Flor do Sertão",
            ic_states: "SANTA CATARINA",
            ic_january: 6.287,
            ic_february: 5.805,
            ic_march: 5.141,
            ic_april: 4.083,
            ic_may: 3.161,
            ic_june: 2.621,
            ic_july: 2.93,
            ic_august: 3.794,
            ic_september: 4.065,
            ic_october: 5.143,
            ic_november: 6.088,
            ic_december: 6.414,
            ic_yearly: 4.628,
          },
          {
            ic_uuid: "95f14430-2e9e-4656-8798-e15af98e29b8",
            ic_lat: -21676,
            ic_lon: -513806,
            ic_city: "Flora Rica",
            ic_states: "SÃO PAULO",
            ic_january: 5.89,
            ic_february: 5.948,
            ic_march: 5.417,
            ic_april: 4.839,
            ic_may: 4.0,
            ic_june: 3.694,
            ic_july: 3.837,
            ic_august: 4.789,
            ic_september: 4.859,
            ic_october: 5.562,
            ic_november: 6.053,
            ic_december: 6.345,
            ic_yearly: 5.103,
          },
          {
            ic_uuid: "54fa3f9b-aada-44c1-b349-a611ccb1868c",
            ic_lat: -233183,
            ic_lon: -523033,
            ic_city: "Floraí",
            ic_states: "PARANÁ",
            ic_january: 5.946,
            ic_february: 5.887,
            ic_march: 5.383,
            ic_april: 4.598,
            ic_may: 3.631,
            ic_june: 3.289,
            ic_july: 3.509,
            ic_august: 4.412,
            ic_september: 4.674,
            ic_october: 5.414,
            ic_november: 6.108,
            ic_december: 6.401,
            ic_yearly: 4.938,
          },
          {
            ic_uuid: "77aa0082-27a5-40a2-8662-7dec89ae36aa",
            ic_lat: -61236,
            ic_lon: -368106,
            ic_city: "Florânia",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.863,
            ic_february: 5.916,
            ic_march: 5.913,
            ic_april: 5.635,
            ic_may: 5.078,
            ic_june: 4.766,
            ic_july: 5.083,
            ic_august: 5.897,
            ic_september: 6.381,
            ic_october: 6.439,
            ic_november: 6.383,
            ic_december: 5.85,
            ic_yearly: 5.767,
          },
          {
            ic_uuid: "d842921f-7d42-4c85-82d1-232ef44e39f3",
            ic_lat: -20677,
            ic_lon: -501449,
            ic_city: "Floreal",
            ic_states: "SÃO PAULO",
            ic_january: 5.862,
            ic_february: 5.911,
            ic_march: 5.298,
            ic_april: 4.865,
            ic_may: 4.129,
            ic_june: 3.887,
            ic_july: 4.113,
            ic_august: 5.002,
            ic_september: 5.013,
            ic_october: 5.63,
            ic_november: 5.958,
            ic_december: 6.228,
            ic_yearly: 5.158,
          },
          {
            ic_uuid: "60295de5-0d69-43e7-be9c-ba49d7a5a6d2",
            ic_lat: -78588,
            ic_lon: -379718,
            ic_city: "Flores",
            ic_states: "PERNAMBUCO",
            ic_january: 6.214,
            ic_february: 6.189,
            ic_march: 6.139,
            ic_april: 5.74,
            ic_may: 4.829,
            ic_june: 4.347,
            ic_july: 4.577,
            ic_august: 5.409,
            ic_september: 6.332,
            ic_october: 6.51,
            ic_november: 6.71,
            ic_december: 6.402,
            ic_yearly: 5.783,
          },
          {
            ic_uuid: "991404b4-a257-42f3-b0a0-3266bae04f07",
            ic_lat: -290266,
            ic_lon: -511879,
            ic_city: "Flores da Cunha",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.164,
            ic_february: 5.72,
            ic_march: 4.792,
            ic_april: 3.856,
            ic_may: 2.864,
            ic_june: 2.404,
            ic_july: 2.72,
            ic_august: 3.44,
            ic_september: 3.704,
            ic_october: 4.829,
            ic_november: 6.185,
            ic_december: 6.472,
            ic_yearly: 4.429,
          },
          {
            ic_uuid: "9d9662c9-6ab8-4d4d-b0e1-60ff50546fdf",
            ic_lat: -144447,
            ic_lon: -470369,
            ic_city: "Flores de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.951,
            ic_february: 6.097,
            ic_march: 5.527,
            ic_april: 5.417,
            ic_may: 5.046,
            ic_june: 4.929,
            ic_july: 5.264,
            ic_august: 5.856,
            ic_september: 6.026,
            ic_october: 5.864,
            ic_november: 5.345,
            ic_december: 5.862,
            ic_yearly: 5.599,
          },
          {
            ic_uuid: "e39e5412-1127-4f31-9f08-705c3748acbc",
            ic_lat: -77938,
            ic_lon: -429247,
            ic_city: "Flores do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.431,
            ic_february: 5.358,
            ic_march: 5.298,
            ic_april: 5.306,
            ic_may: 5.284,
            ic_june: 5.322,
            ic_july: 5.598,
            ic_august: 6.276,
            ic_september: 6.713,
            ic_october: 6.409,
            ic_november: 5.965,
            ic_december: 5.763,
            ic_yearly: 5.727,
          },
          {
            ic_uuid: "458359ce-74bd-499f-b755-552d31a419c9",
            ic_lat: -236035,
            ic_lon: -520811,
            ic_city: "Floresta",
            ic_states: "PARANÁ",
            ic_january: 5.987,
            ic_february: 5.735,
            ic_march: 5.194,
            ic_april: 4.576,
            ic_may: 3.62,
            ic_june: 3.262,
            ic_july: 3.459,
            ic_august: 4.365,
            ic_september: 4.636,
            ic_october: 5.266,
            ic_november: 6.036,
            ic_december: 6.392,
            ic_yearly: 4.878,
          },
          {
            ic_uuid: "1a50fab5-67c9-498c-80fc-d8ddb1325b73",
            ic_lat: -85993,
            ic_lon: -38572,
            ic_city: "Floresta",
            ic_states: "PERNAMBUCO",
            ic_january: 6.367,
            ic_february: 6.161,
            ic_march: 6.139,
            ic_april: 5.61,
            ic_may: 4.742,
            ic_june: 4.189,
            ic_july: 4.464,
            ic_august: 5.213,
            ic_september: 6.208,
            ic_october: 6.476,
            ic_november: 6.735,
            ic_december: 6.51,
            ic_yearly: 5.734,
          },
          {
            ic_uuid: "cd413429-45b1-4e48-8c9c-9a218c1c1a62",
            ic_lat: -148586,
            ic_lon: -396564,
            ic_city: "Floresta Azul",
            ic_states: "BAHIA",
            ic_january: 5.943,
            ic_february: 5.857,
            ic_march: 5.422,
            ic_april: 4.559,
            ic_may: 3.868,
            ic_june: 3.531,
            ic_july: 3.762,
            ic_august: 4.28,
            ic_september: 4.939,
            ic_october: 5.132,
            ic_november: 5.198,
            ic_december: 5.952,
            ic_yearly: 4.87,
          },
          {
            ic_uuid: "b207f4b6-d1a8-4097-9c2f-aa9ec4ccb181",
            ic_lat: -75573,
            ic_lon: -497022,
            ic_city: "Floresta do Araguaia",
            ic_states: "PARÁ",
            ic_january: 4.741,
            ic_february: 4.787,
            ic_march: 4.686,
            ic_april: 4.638,
            ic_may: 4.795,
            ic_june: 5.038,
            ic_july: 5.132,
            ic_august: 5.722,
            ic_september: 5.441,
            ic_october: 4.996,
            ic_november: 4.77,
            ic_december: 4.704,
            ic_yearly: 4.954,
          },
          {
            ic_uuid: "0af0d8d2-409a-43ff-8b99-10ceffba9346",
            ic_lat: -74669,
            ic_lon: -417997,
            ic_city: "Floresta do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.667,
            ic_february: 5.52,
            ic_march: 5.573,
            ic_april: 5.313,
            ic_may: 5.257,
            ic_june: 5.251,
            ic_july: 5.552,
            ic_august: 6.158,
            ic_september: 6.607,
            ic_october: 6.493,
            ic_november: 6.283,
            ic_december: 5.907,
            ic_yearly: 5.798,
          },
          {
            ic_uuid: "55f5c674-d46b-4fba-9e5e-45720d449c95",
            ic_lat: -198885,
            ic_lon: -444321,
            ic_city: "Florestal",
            ic_states: "MINAS GERAIS",
            ic_january: 5.874,
            ic_february: 6.055,
            ic_march: 5.13,
            ic_april: 4.862,
            ic_may: 4.291,
            ic_june: 4.133,
            ic_july: 4.375,
            ic_august: 5.239,
            ic_september: 5.5,
            ic_october: 5.586,
            ic_november: 5.308,
            ic_december: 5.619,
            ic_yearly: 5.164,
          },
          {
            ic_uuid: "1b5e491d-c419-4813-9b67-2bc66ac623cd",
            ic_lat: -228627,
            ic_lon: -513886,
            ic_city: "Florestópolis",
            ic_states: "PARANÁ",
            ic_january: 5.963,
            ic_february: 5.93,
            ic_march: 5.407,
            ic_april: 4.727,
            ic_may: 3.734,
            ic_june: 3.422,
            ic_july: 3.655,
            ic_august: 4.563,
            ic_september: 4.752,
            ic_october: 5.451,
            ic_november: 6.14,
            ic_december: 6.427,
            ic_yearly: 5.014,
          },
          {
            ic_uuid: "6ba1b206-1ca1-4089-8aa0-ed9e14ff8845",
            ic_lat: -67722,
            ic_lon: -430245,
            ic_city: "Floriano",
            ic_states: "PIAUÍ",
            ic_january: 5.24,
            ic_february: 5.306,
            ic_march: 5.265,
            ic_april: 5.183,
            ic_may: 5.21,
            ic_june: 5.34,
            ic_july: 5.568,
            ic_august: 6.089,
            ic_september: 6.583,
            ic_october: 6.356,
            ic_november: 5.995,
            ic_december: 5.565,
            ic_yearly: 5.641,
          },
          {
            ic_uuid: "e1c89784-1b3e-4cc5-ae7a-a4d373224d39",
            ic_lat: -278619,
            ic_lon: -520842,
            ic_city: "Floriano Peixoto",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.222,
            ic_february: 5.765,
            ic_march: 4.981,
            ic_april: 4.049,
            ic_may: 3.042,
            ic_june: 2.527,
            ic_july: 2.866,
            ic_august: 3.652,
            ic_september: 3.903,
            ic_october: 5.015,
            ic_november: 6.175,
            ic_december: 6.505,
            ic_yearly: 4.559,
          },
          {
            ic_uuid: "de50d500-25f5-4dff-9b37-9364ed9ce2b3",
            ic_lat: -275955,
            ic_lon: -485485,
            ic_city: "Florianópolis",
            ic_states: "SANTA CATARINA",
            ic_january: 5.868,
            ic_february: 5.453,
            ic_march: 4.714,
            ic_april: 3.773,
            ic_may: 3.051,
            ic_june: 2.573,
            ic_july: 2.699,
            ic_august: 3.407,
            ic_september: 3.559,
            ic_october: 4.364,
            ic_november: 5.537,
            ic_december: 6.011,
            ic_yearly: 4.251,
          },
          {
            ic_uuid: "9b7c6e34-6b73-4f63-8990-3baf7d14f56f",
            ic_lat: -230852,
            ic_lon: -51955,
            ic_city: "Flórida",
            ic_states: "PARANÁ",
            ic_january: 6.056,
            ic_february: 5.911,
            ic_march: 5.37,
            ic_april: 4.676,
            ic_may: 3.698,
            ic_june: 3.373,
            ic_july: 3.566,
            ic_august: 4.536,
            ic_september: 4.73,
            ic_october: 5.432,
            ic_november: 6.126,
            ic_december: 6.42,
            ic_yearly: 4.991,
          },
          {
            ic_uuid: "7504ccf9-7f32-4d67-aefe-becf4ed05f9a",
            ic_lat: -216132,
            ic_lon: -511729,
            ic_city: "Flórida Paulista",
            ic_states: "SÃO PAULO",
            ic_january: 5.907,
            ic_february: 5.996,
            ic_march: 5.456,
            ic_april: 4.886,
            ic_may: 4.024,
            ic_june: 3.716,
            ic_july: 3.899,
            ic_august: 4.805,
            ic_september: 4.864,
            ic_october: 5.563,
            ic_november: 6.064,
            ic_december: 6.322,
            ic_yearly: 5.125,
          },
          {
            ic_uuid: "03b70ebf-515f-4bec-adb4-dafc91dc1579",
            ic_lat: -229039,
            ic_lon: -507312,
            ic_city: "Florínia",
            ic_states: "SÃO PAULO",
            ic_january: 6.012,
            ic_february: 5.908,
            ic_march: 5.287,
            ic_april: 4.708,
            ic_may: 3.765,
            ic_june: 3.429,
            ic_july: 3.615,
            ic_august: 4.562,
            ic_september: 4.687,
            ic_october: 5.391,
            ic_november: 6.035,
            ic_december: 6.461,
            ic_yearly: 4.988,
          },
          {
            ic_uuid: "3a722f54-7d8b-4d15-be4c-0736a447cab9",
            ic_lat: -25238,
            ic_lon: -660947,
            ic_city: "Fonte Boa",
            ic_states: "AMAZONAS",
            ic_january: 4.469,
            ic_february: 4.722,
            ic_march: 4.591,
            ic_april: 4.338,
            ic_may: 4.04,
            ic_june: 4.219,
            ic_july: 4.243,
            ic_august: 4.886,
            ic_september: 5.101,
            ic_october: 4.971,
            ic_november: 4.818,
            ic_december: 4.566,
            ic_yearly: 4.58,
          },
          {
            ic_uuid: "9c64e568-4e1b-41c8-ba2f-97e4f5de430e",
            ic_lat: -289821,
            ic_lon: -523449,
            ic_city: "Fontoura Xavier",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.109,
            ic_february: 5.732,
            ic_march: 4.854,
            ic_april: 3.865,
            ic_may: 2.891,
            ic_june: 2.425,
            ic_july: 2.72,
            ic_august: 3.46,
            ic_september: 3.724,
            ic_october: 4.822,
            ic_november: 6.136,
            ic_december: 6.481,
            ic_yearly: 4.435,
          },
          {
            ic_uuid: "d6a33a01-9270-4996-bc17-9dfef636bd1d",
            ic_lat: -204623,
            ic_lon: -454272,
            ic_city: "Formiga",
            ic_states: "MINAS GERAIS",
            ic_january: 5.628,
            ic_february: 5.856,
            ic_march: 4.923,
            ic_april: 4.801,
            ic_may: 4.219,
            ic_june: 4.012,
            ic_july: 4.262,
            ic_august: 5.184,
            ic_september: 5.334,
            ic_october: 5.487,
            ic_november: 5.171,
            ic_december: 5.628,
            ic_yearly: 5.042,
          },
          {
            ic_uuid: "aee460ba-ff87-4c47-b6ca-981aa91c0212",
            ic_lat: -300014,
            ic_lon: -535029,
            ic_city: "Formigueiro",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.543,
            ic_february: 6.037,
            ic_march: 5.068,
            ic_april: 3.887,
            ic_may: 2.818,
            ic_june: 2.338,
            ic_july: 2.539,
            ic_august: 3.312,
            ic_september: 3.811,
            ic_october: 4.983,
            ic_november: 6.321,
            ic_december: 6.919,
            ic_yearly: 4.548,
          },
          {
            ic_uuid: "6d9322de-ae29-437a-a2aa-a834bd1d2a88",
            ic_lat: -155404,
            ic_lon: -473374,
            ic_city: "Formosa",
            ic_states: "GOIÁS",
            ic_january: 5.682,
            ic_february: 5.878,
            ic_march: 5.276,
            ic_april: 5.165,
            ic_may: 4.923,
            ic_june: 4.8,
            ic_july: 5.179,
            ic_august: 5.934,
            ic_september: 5.941,
            ic_october: 5.767,
            ic_november: 5.185,
            ic_december: 5.63,
            ic_yearly: 5.447,
          },
          {
            ic_uuid: "7ac0458e-d36d-46d7-95bd-270c0e47056e",
            ic_lat: -64406,
            ic_lon: -461919,
            ic_city: "Formosa da Serra Negra",
            ic_states: "MARANHÃO",
            ic_january: 4.689,
            ic_february: 4.806,
            ic_march: 4.783,
            ic_april: 4.747,
            ic_may: 4.993,
            ic_june: 5.275,
            ic_july: 5.478,
            ic_august: 6.064,
            ic_september: 6.099,
            ic_october: 5.47,
            ic_november: 4.924,
            ic_december: 4.799,
            ic_yearly: 5.177,
          },
          {
            ic_uuid: "69d246d4-0e17-4a49-a2cc-c66fac4b0e25",
            ic_lat: -242956,
            ic_lon: -533118,
            ic_city: "Formosa do Oeste",
            ic_states: "PARANÁ",
            ic_january: 6.245,
            ic_february: 5.795,
            ic_march: 5.297,
            ic_april: 4.43,
            ic_may: 3.491,
            ic_june: 3.052,
            ic_july: 3.25,
            ic_august: 4.267,
            ic_september: 4.525,
            ic_october: 5.332,
            ic_november: 6.101,
            ic_december: 6.506,
            ic_yearly: 4.857,
          },
          {
            ic_uuid: "7454fc8f-a74f-474f-aa9b-a7f22df2c8a6",
            ic_lat: -110427,
            ic_lon: -451904,
            ic_city: "Formosa do Rio Preto",
            ic_states: "BAHIA",
            ic_january: 5.992,
            ic_february: 5.786,
            ic_march: 5.552,
            ic_april: 5.403,
            ic_may: 5.411,
            ic_june: 5.331,
            ic_july: 5.61,
            ic_august: 6.221,
            ic_september: 6.406,
            ic_october: 6.038,
            ic_november: 5.735,
            ic_december: 5.978,
            ic_yearly: 5.789,
          },
          {
            ic_uuid: "1cd8c5ef-3862-4457-a854-6ca81db47b71",
            ic_lat: -266457,
            ic_lon: -527951,
            ic_city: "Formosa do Sul",
            ic_states: "SANTA CATARINA",
            ic_january: 6.163,
            ic_february: 5.627,
            ic_march: 5.1,
            ic_april: 4.124,
            ic_may: 3.183,
            ic_june: 2.722,
            ic_july: 3.015,
            ic_august: 3.912,
            ic_september: 4.147,
            ic_october: 5.115,
            ic_november: 6.077,
            ic_december: 6.289,
            ic_yearly: 4.623,
          },
          {
            ic_uuid: "8d5ff664-3db8-4bca-9fd0-fd6b7cbe6d1b",
            ic_lat: -136503,
            ic_lon: -488779,
            ic_city: "Formoso",
            ic_states: "GOIÁS",
            ic_january: 5.362,
            ic_february: 5.55,
            ic_march: 5.17,
            ic_april: 5.191,
            ic_may: 5.092,
            ic_june: 4.818,
            ic_july: 5.208,
            ic_august: 5.896,
            ic_september: 5.738,
            ic_october: 5.595,
            ic_november: 5.155,
            ic_december: 5.376,
            ic_yearly: 5.346,
          },
          {
            ic_uuid: "7d709946-6440-4e55-bcad-d83fff2c6097",
            ic_lat: -149532,
            ic_lon: -462323,
            ic_city: "Formoso",
            ic_states: "MINAS GERAIS",
            ic_january: 5.913,
            ic_february: 6.089,
            ic_march: 5.346,
            ic_april: 5.318,
            ic_may: 4.979,
            ic_june: 4.83,
            ic_july: 5.232,
            ic_august: 5.894,
            ic_september: 6.031,
            ic_october: 5.83,
            ic_november: 5.28,
            ic_december: 5.76,
            ic_yearly: 5.542,
          },
          {
            ic_uuid: "c3fe23d4-8836-46f5-a99d-201a9048a848",
            ic_lat: -118064,
            ic_lon: -495252,
            ic_city: "Formoso do Araguaia",
            ic_states: "TOCANTINS",
            ic_january: 5.324,
            ic_february: 5.304,
            ic_march: 5.076,
            ic_april: 4.993,
            ic_may: 4.991,
            ic_june: 4.821,
            ic_july: 5.03,
            ic_august: 5.741,
            ic_september: 5.62,
            ic_october: 5.465,
            ic_november: 5.213,
            ic_december: 5.322,
            ic_yearly: 5.242,
          },
          {
            ic_uuid: "88e4fcf6-9929-4b5b-a91f-20523d6c8c82",
            ic_lat: -293832,
            ic_lon: -520985,
            ic_city: "Forquetinha",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.063,
            ic_february: 5.706,
            ic_march: 4.801,
            ic_april: 3.782,
            ic_may: 2.819,
            ic_june: 2.35,
            ic_july: 2.576,
            ic_august: 3.304,
            ic_september: 3.588,
            ic_october: 4.653,
            ic_november: 6.053,
            ic_december: 6.455,
            ic_yearly: 4.346,
          },
          {
            ic_uuid: "c405f48a-288b-4e10-b1e7-207772948ff7",
            ic_lat: -37998,
            ic_lon: -402637,
            ic_city: "Forquilha",
            ic_states: "CEARÁ",
            ic_january: 5.2,
            ic_february: 5.31,
            ic_march: 5.25,
            ic_april: 4.869,
            ic_may: 5.023,
            ic_june: 4.927,
            ic_july: 5.237,
            ic_august: 6.006,
            ic_september: 6.488,
            ic_october: 6.384,
            ic_november: 6.173,
            ic_december: 5.526,
            ic_yearly: 5.533,
          },
          {
            ic_uuid: "7713c986-55fb-48d2-a943-4e9eed1a6725",
            ic_lat: -287458,
            ic_lon: -494789,
            ic_city: "Forquilhinha",
            ic_states: "SANTA CATARINA",
            ic_january: 5.482,
            ic_february: 5.104,
            ic_march: 4.431,
            ic_april: 3.746,
            ic_may: 2.955,
            ic_june: 2.477,
            ic_july: 2.704,
            ic_august: 3.411,
            ic_september: 3.486,
            ic_october: 4.211,
            ic_november: 5.523,
            ic_december: 5.763,
            ic_yearly: 4.108,
          },
          {
            ic_uuid: "411f1fb2-d990-4c41-902b-4c24ddad8e0c",
            ic_lat: -37174,
            ic_lon: -385429,
            ic_city: "Fortaleza",
            ic_states: "CEARÁ",
            ic_january: 5.871,
            ic_february: 5.807,
            ic_march: 5.652,
            ic_april: 4.91,
            ic_may: 5.291,
            ic_june: 5.3,
            ic_july: 5.525,
            ic_august: 5.933,
            ic_september: 6.089,
            ic_october: 6.344,
            ic_november: 6.434,
            ic_december: 6.155,
            ic_yearly: 5.776,
          },
          {
            ic_uuid: "9fc4e0b0-28a6-4b37-9dfc-0cd92c5d3854",
            ic_lat: -208512,
            ic_lon: -467124,
            ic_city: "Fortaleza de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.617,
            ic_february: 5.854,
            ic_march: 5.026,
            ic_april: 4.797,
            ic_may: 4.196,
            ic_june: 3.955,
            ic_july: 4.191,
            ic_august: 5.089,
            ic_september: 5.149,
            ic_october: 5.549,
            ic_november: 5.482,
            ic_december: 5.677,
            ic_yearly: 5.048,
          },
          {
            ic_uuid: "1ab0ab88-e8b8-4cc1-9064-1dd1fb0f768f",
            ic_lat: -90565,
            ic_lon: -485209,
            ic_city: "Fortaleza do Tabocão",
            ic_states: "TOCANTINS",
            ic_january: 4.918,
            ic_february: 5.028,
            ic_march: 4.817,
            ic_april: 4.807,
            ic_may: 5.002,
            ic_june: 5.099,
            ic_july: 5.304,
            ic_august: 5.969,
            ic_september: 5.771,
            ic_october: 5.128,
            ic_november: 4.941,
            ic_december: 4.92,
            ic_yearly: 5.142,
          },
          {
            ic_uuid: "db9bd490-11fd-421f-928c-9cc9e69806ca",
            ic_lat: -69623,
            ic_lon: -461781,
            ic_city: "Fortaleza dos Nogueiras",
            ic_states: "MARANHÃO",
            ic_january: 4.626,
            ic_february: 4.816,
            ic_march: 4.736,
            ic_april: 4.879,
            ic_may: 5.088,
            ic_june: 5.353,
            ic_july: 5.493,
            ic_august: 6.102,
            ic_september: 6.289,
            ic_october: 5.59,
            ic_november: 4.953,
            ic_december: 4.765,
            ic_yearly: 5.224,
          },
          {
            ic_uuid: "f7800cba-2fab-470e-b4d8-8b5e3148602f",
            ic_lat: -287991,
            ic_lon: -532254,
            ic_city: "Fortaleza dos Valos",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.4,
            ic_february: 5.921,
            ic_march: 5.059,
            ic_april: 3.971,
            ic_may: 2.996,
            ic_june: 2.412,
            ic_july: 2.744,
            ic_august: 3.528,
            ic_september: 3.893,
            ic_october: 4.993,
            ic_november: 6.247,
            ic_december: 6.664,
            ic_yearly: 4.569,
          },
          {
            ic_uuid: "46074d8d-71de-412c-ad87-31fe3df1572d",
            ic_lat: -44517,
            ic_lon: -377984,
            ic_city: "Fortim",
            ic_states: "CEARÁ",
            ic_january: 5.866,
            ic_february: 5.956,
            ic_march: 5.833,
            ic_april: 5.323,
            ic_may: 5.311,
            ic_june: 5.061,
            ic_july: 5.187,
            ic_august: 5.683,
            ic_september: 5.805,
            ic_october: 5.977,
            ic_november: 6.068,
            ic_december: 5.92,
            ic_yearly: 5.666,
          },
          {
            ic_uuid: "04816f49-c083-4ca7-951f-1fddf45f5e2d",
            ic_lat: -57283,
            ic_lon: -441568,
            ic_city: "Fortuna",
            ic_states: "MARANHÃO",
            ic_january: 4.714,
            ic_february: 4.945,
            ic_march: 4.987,
            ic_april: 4.964,
            ic_may: 4.982,
            ic_june: 5.269,
            ic_july: 5.532,
            ic_august: 6.169,
            ic_september: 6.366,
            ic_october: 5.804,
            ic_november: 5.373,
            ic_december: 5.095,
            ic_yearly: 5.35,
          },
          {
            ic_uuid: "dba4fc13-4710-4220-8d40-01eec905a338",
            ic_lat: -19561,
            ic_lon: -44447,
            ic_city: "Fortuna de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.926,
            ic_february: 6.039,
            ic_march: 5.207,
            ic_april: 4.915,
            ic_may: 4.347,
            ic_june: 4.133,
            ic_july: 4.434,
            ic_august: 5.308,
            ic_september: 5.599,
            ic_october: 5.643,
            ic_november: 5.425,
            ic_december: 5.724,
            ic_yearly: 5.225,
          },
          {
            ic_uuid: "1306ca21-40be-4be8-a3f0-452491bad6df",
            ic_lat: -255432,
            ic_lon: -545831,
            ic_city: "Foz do Iguaçu",
            ic_states: "PARANÁ",
            ic_january: 6.434,
            ic_february: 5.909,
            ic_march: 5.353,
            ic_april: 4.193,
            ic_may: 3.267,
            ic_june: 2.794,
            ic_july: 3.022,
            ic_august: 3.976,
            ic_september: 4.277,
            ic_october: 5.294,
            ic_november: 6.171,
            ic_december: 6.627,
            ic_yearly: 4.776,
          },
          {
            ic_uuid: "efba447e-e471-4d2d-a6e7-4b29a2070f22",
            ic_lat: -257376,
            ic_lon: -521193,
            ic_city: "Foz do Jordão",
            ic_states: "PARANÁ",
            ic_january: 6.153,
            ic_february: 5.594,
            ic_march: 5.204,
            ic_april: 4.268,
            ic_may: 3.293,
            ic_june: 2.861,
            ic_july: 3.105,
            ic_august: 4.118,
            ic_september: 4.28,
            ic_october: 5.209,
            ic_november: 6.092,
            ic_december: 6.346,
            ic_yearly: 4.71,
          },
          {
            ic_uuid: "b79c7321-69cf-446c-b959-cacfb059e1e2",
            ic_lat: -270238,
            ic_lon: -509204,
            ic_city: "Fraiburgo",
            ic_states: "SANTA CATARINA",
            ic_january: 5.682,
            ic_february: 5.282,
            ic_march: 4.781,
            ic_april: 3.863,
            ic_may: 2.992,
            ic_june: 2.647,
            ic_july: 2.894,
            ic_august: 3.767,
            ic_september: 3.905,
            ic_october: 4.677,
            ic_november: 5.785,
            ic_december: 5.996,
            ic_yearly: 4.356,
          },
          {
            ic_uuid: "b66f9921-fd34-4f65-9ee9-b29a4f2ba34e",
            ic_lat: -205357,
            ic_lon: -474043,
            ic_city: "Franca",
            ic_states: "SÃO PAULO",
            ic_january: 5.566,
            ic_february: 5.793,
            ic_march: 5.019,
            ic_april: 4.847,
            ic_may: 4.268,
            ic_june: 4.091,
            ic_july: 4.283,
            ic_august: 5.229,
            ic_september: 5.159,
            ic_october: 5.492,
            ic_november: 5.581,
            ic_december: 5.804,
            ic_yearly: 5.094,
          },
          {
            ic_uuid: "778840cd-742a-4c1f-bd37-2722fbf7b92a",
            ic_lat: -63969,
            ic_lon: -422669,
            ic_city: "Francinópolis",
            ic_states: "PIAUÍ",
            ic_january: 5.186,
            ic_february: 5.295,
            ic_march: 5.217,
            ic_april: 5.148,
            ic_may: 5.238,
            ic_june: 5.267,
            ic_july: 5.583,
            ic_august: 6.22,
            ic_september: 6.567,
            ic_october: 6.284,
            ic_november: 6.119,
            ic_december: 5.699,
            ic_yearly: 5.652,
          },
          {
            ic_uuid: "c0f12ac3-b049-4d8e-ac3b-c0382d6468ed",
            ic_lat: -240672,
            ic_lon: -538466,
            ic_city: "Francisco Alves",
            ic_states: "PARANÁ",
            ic_january: 6.155,
            ic_february: 5.829,
            ic_march: 5.302,
            ic_april: 4.395,
            ic_may: 3.493,
            ic_june: 3.058,
            ic_july: 3.257,
            ic_august: 4.28,
            ic_september: 4.537,
            ic_october: 5.347,
            ic_november: 6.164,
            ic_december: 6.501,
            ic_yearly: 4.86,
          },
          {
            ic_uuid: "4aac91fb-7fc6-4422-8c9e-24afb3633bcd",
            ic_lat: -66252,
            ic_lon: -426936,
            ic_city: "Francisco Ayres",
            ic_states: "PIAUÍ",
            ic_january: 5.199,
            ic_february: 5.285,
            ic_march: 5.24,
            ic_april: 5.203,
            ic_may: 5.292,
            ic_june: 5.367,
            ic_july: 5.629,
            ic_august: 6.159,
            ic_september: 6.517,
            ic_october: 6.392,
            ic_november: 6.077,
            ic_december: 5.67,
            ic_yearly: 5.67,
          },
          {
            ic_uuid: "2e189792-abb6-434e-8ce8-0f49b91483cd",
            ic_lat: -169887,
            ic_lon: -423572,
            ic_city: "Francisco Badaró",
            ic_states: "MINAS GERAIS",
            ic_january: 6.288,
            ic_february: 6.45,
            ic_march: 5.528,
            ic_april: 4.798,
            ic_may: 4.118,
            ic_june: 3.918,
            ic_july: 4.131,
            ic_august: 4.899,
            ic_september: 5.454,
            ic_october: 5.703,
            ic_november: 5.305,
            ic_december: 6.028,
            ic_yearly: 5.218,
          },
          {
            ic_uuid: "f2782d0a-bdec-42eb-a8fd-188dfc2820ef",
            ic_lat: -260821,
            ic_lon: -530539,
            ic_city: "Francisco Beltrão",
            ic_states: "PARANÁ",
            ic_january: 6.21,
            ic_february: 5.657,
            ic_march: 5.181,
            ic_april: 4.198,
            ic_may: 3.267,
            ic_june: 2.813,
            ic_july: 3.109,
            ic_august: 4.068,
            ic_september: 4.265,
            ic_october: 5.207,
            ic_november: 6.09,
            ic_december: 6.366,
            ic_yearly: 4.703,
          },
          {
            ic_uuid: "eef03dc0-c693-4cf5-bef6-8f8904773ae5",
            ic_lat: -60728,
            ic_lon: -381215,
            ic_city: "Francisco Dantas",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.934,
            ic_february: 5.963,
            ic_march: 6.006,
            ic_april: 5.663,
            ic_may: 5.314,
            ic_june: 5.022,
            ic_july: 5.372,
            ic_august: 6.043,
            ic_september: 6.415,
            ic_october: 6.599,
            ic_november: 6.614,
            ic_december: 6.099,
            ic_yearly: 5.92,
          },
          {
            ic_uuid: "728cb58d-214e-4cca-b228-f21e06a6e91e",
            ic_lat: -173111,
            ic_lon: -442321,
            ic_city: "Francisco Dumont",
            ic_states: "MINAS GERAIS",
            ic_january: 6.313,
            ic_february: 6.619,
            ic_march: 5.691,
            ic_april: 5.385,
            ic_may: 4.847,
            ic_june: 4.525,
            ic_july: 4.879,
            ic_august: 5.575,
            ic_september: 6.01,
            ic_october: 6.03,
            ic_november: 5.578,
            ic_december: 6.068,
            ic_yearly: 5.627,
          },
          {
            ic_uuid: "4bab261a-6858-4f21-a646-8a58d0404983",
            ic_lat: -73274,
            ic_lon: -407931,
            ic_city: "Francisco Macedo",
            ic_states: "PIAUÍ",
            ic_january: 5.504,
            ic_february: 5.488,
            ic_march: 5.499,
            ic_april: 5.336,
            ic_may: 5.215,
            ic_june: 5.186,
            ic_july: 5.615,
            ic_august: 6.319,
            ic_september: 6.719,
            ic_october: 6.525,
            ic_november: 6.422,
            ic_december: 5.986,
            ic_yearly: 5.818,
          },
          {
            ic_uuid: "ef1e06b9-ce7e-4c37-9f43-6af1abadd1ee",
            ic_lat: -232797,
            ic_lon: -467452,
            ic_city: "Francisco Morato",
            ic_states: "SÃO PAULO",
            ic_january: 5.377,
            ic_february: 5.672,
            ic_march: 4.892,
            ic_april: 4.367,
            ic_may: 3.595,
            ic_june: 3.353,
            ic_july: 3.457,
            ic_august: 4.447,
            ic_september: 4.502,
            ic_october: 5.051,
            ic_november: 5.388,
            ic_december: 5.882,
            ic_yearly: 4.665,
          },
          {
            ic_uuid: "c0d46d95-9d10-4131-80be-704e27772de3",
            ic_lat: -164832,
            ic_lon: -4349,
            ic_city: "Francisco Sá",
            ic_states: "MINAS GERAIS",
            ic_january: 6.322,
            ic_february: 6.615,
            ic_march: 5.661,
            ic_april: 5.351,
            ic_may: 4.774,
            ic_june: 4.505,
            ic_july: 4.798,
            ic_august: 5.503,
            ic_september: 6.025,
            ic_october: 6.109,
            ic_november: 5.587,
            ic_december: 6.197,
            ic_yearly: 5.621,
          },
          {
            ic_uuid: "eba26651-89df-4baf-9aee-0e94bde2c9e0",
            ic_lat: -69937,
            ic_lon: -411344,
            ic_city: "Francisco Santos",
            ic_states: "PIAUÍ",
            ic_january: 5.683,
            ic_february: 5.486,
            ic_march: 5.635,
            ic_april: 5.313,
            ic_may: 5.18,
            ic_june: 5.076,
            ic_july: 5.552,
            ic_august: 6.22,
            ic_september: 6.676,
            ic_october: 6.519,
            ic_november: 6.477,
            ic_december: 6.056,
            ic_yearly: 5.823,
          },
          {
            ic_uuid: "029c6c69-6c19-4ef6-99fb-0440478ffe21",
            ic_lat: -1796,
            ic_lon: -42009,
            ic_city: "Franciscópolis",
            ic_states: "MINAS GERAIS",
            ic_january: 6.036,
            ic_february: 6.173,
            ic_march: 5.305,
            ic_april: 4.59,
            ic_may: 3.934,
            ic_june: 3.695,
            ic_july: 3.829,
            ic_august: 4.519,
            ic_september: 5.077,
            ic_october: 5.396,
            ic_november: 5.004,
            ic_december: 5.843,
            ic_yearly: 4.95,
          },
          {
            ic_uuid: "e3755ee5-a2c7-4bda-b3c3-cd5cea560624",
            ic_lat: -233234,
            ic_lon: -467294,
            ic_city: "Franco da Rocha",
            ic_states: "SÃO PAULO",
            ic_january: 5.377,
            ic_february: 5.672,
            ic_march: 4.892,
            ic_april: 4.367,
            ic_may: 3.595,
            ic_june: 3.353,
            ic_july: 3.457,
            ic_august: 4.447,
            ic_september: 4.502,
            ic_october: 5.051,
            ic_november: 5.388,
            ic_december: 5.882,
            ic_yearly: 4.665,
          },
          {
            ic_uuid: "d4e337e2-b849-4338-a46e-8f883598395c",
            ic_lat: -37682,
            ic_lon: -408333,
            ic_city: "Frecheirinha",
            ic_states: "CEARÁ",
            ic_january: 4.859,
            ic_february: 5.097,
            ic_march: 5.041,
            ic_april: 4.713,
            ic_may: 4.815,
            ic_june: 4.924,
            ic_july: 5.255,
            ic_august: 5.943,
            ic_september: 6.36,
            ic_october: 6.265,
            ic_november: 6.03,
            ic_december: 5.291,
            ic_yearly: 5.383,
          },
          {
            ic_uuid: "882d32e9-462b-4050-b4d1-646613cd88d8",
            ic_lat: -273591,
            ic_lon: -533963,
            ic_city: "Frederico Westphalen",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.354,
            ic_february: 5.856,
            ic_march: 5.115,
            ic_april: 4.068,
            ic_may: 3.104,
            ic_june: 2.547,
            ic_july: 2.891,
            ic_august: 3.74,
            ic_september: 3.987,
            ic_october: 5.176,
            ic_november: 6.231,
            ic_december: 6.602,
            ic_yearly: 4.639,
          },
          {
            ic_uuid: "507553e3-c8ae-4bff-a860-200c8b10d8ef",
            ic_lat: -180714,
            ic_lon: -414329,
            ic_city: "Frei Gaspar",
            ic_states: "MINAS GERAIS",
            ic_january: 5.993,
            ic_february: 6.098,
            ic_march: 5.245,
            ic_april: 4.575,
            ic_may: 3.795,
            ic_june: 3.576,
            ic_july: 3.681,
            ic_august: 4.331,
            ic_september: 4.851,
            ic_october: 5.11,
            ic_november: 4.899,
            ic_december: 5.752,
            ic_yearly: 4.825,
          },
          {
            ic_uuid: "cee0351c-ef19-4070-81e9-94dde6864039",
            ic_lat: -185561,
            ic_lon: -419124,
            ic_city: "Frei Inocêncio",
            ic_states: "MINAS GERAIS",
            ic_january: 6.087,
            ic_february: 6.204,
            ic_march: 5.349,
            ic_april: 4.649,
            ic_may: 3.926,
            ic_june: 3.663,
            ic_july: 3.765,
            ic_august: 4.414,
            ic_september: 4.993,
            ic_october: 5.22,
            ic_november: 4.965,
            ic_december: 5.902,
            ic_yearly: 4.928,
          },
          {
            ic_uuid: "ebcb438d-4371-4511-b379-20651d8885dc",
            ic_lat: -18168,
            ic_lon: -42766,
            ic_city: "Frei Lagonegro",
            ic_states: "MINAS GERAIS",
            ic_january: 5.826,
            ic_february: 6.111,
            ic_march: 5.113,
            ic_april: 4.492,
            ic_may: 3.907,
            ic_june: 3.733,
            ic_july: 3.836,
            ic_august: 4.651,
            ic_september: 5.131,
            ic_october: 5.322,
            ic_november: 4.792,
            ic_december: 5.572,
            ic_yearly: 4.874,
          },
          {
            ic_uuid: "c2b87c00-b31c-4d81-b19e-82122a9030fb",
            ic_lat: -6398,
            ic_lon: -364529,
            ic_city: "Frei Martinho",
            ic_states: "PARAÍBA",
            ic_january: 5.95,
            ic_february: 5.889,
            ic_march: 5.897,
            ic_april: 5.57,
            ic_may: 4.984,
            ic_june: 4.556,
            ic_july: 4.805,
            ic_august: 5.62,
            ic_september: 6.142,
            ic_october: 6.317,
            ic_november: 6.423,
            ic_december: 5.979,
            ic_yearly: 5.678,
          },
          {
            ic_uuid: "2aef64ed-e65f-4b9a-a091-b929d45ee644",
            ic_lat: -79417,
            ic_lon: -359208,
            ic_city: "Frei Miguelinho",
            ic_states: "PERNAMBUCO",
            ic_january: 5.722,
            ic_february: 5.773,
            ic_march: 5.842,
            ic_april: 5.192,
            ic_may: 4.562,
            ic_june: 4.044,
            ic_july: 4.261,
            ic_august: 4.874,
            ic_september: 5.473,
            ic_october: 5.795,
            ic_november: 6.106,
            ic_december: 5.983,
            ic_yearly: 5.302,
          },
          {
            ic_uuid: "cc3b15f9-61ac-43c9-8cb7-18fa1d9f6025",
            ic_lat: -105506,
            ic_lon: -37534,
            ic_city: "Frei Paulo",
            ic_states: "SERGIPE",
            ic_january: 6.097,
            ic_february: 5.938,
            ic_march: 5.808,
            ic_april: 4.999,
            ic_may: 4.199,
            ic_june: 3.924,
            ic_july: 4.051,
            ic_august: 4.605,
            ic_september: 5.361,
            ic_october: 5.726,
            ic_november: 6.203,
            ic_december: 6.145,
            ic_yearly: 5.254,
          },
          {
            ic_uuid: "208e3876-2874-45ce-b6a3-fb924c72257f",
            ic_lat: -271755,
            ic_lon: -508081,
            ic_city: "Frei Rogério",
            ic_states: "SANTA CATARINA",
            ic_january: 5.676,
            ic_february: 5.357,
            ic_march: 4.74,
            ic_april: 3.853,
            ic_may: 2.973,
            ic_june: 2.619,
            ic_july: 2.879,
            ic_august: 3.741,
            ic_september: 3.877,
            ic_october: 4.657,
            ic_november: 5.799,
            ic_december: 6.065,
            ic_yearly: 4.353,
          },
          {
            ic_uuid: "9fe93c2c-3127-43b8-b7e8-c80e20ea5513",
            ic_lat: -202832,
            ic_lon: -492011,
            ic_city: "Fronteira",
            ic_states: "MINAS GERAIS",
            ic_january: 5.768,
            ic_february: 5.985,
            ic_march: 5.203,
            ic_april: 5.018,
            ic_may: 4.228,
            ic_june: 3.965,
            ic_july: 4.221,
            ic_august: 5.075,
            ic_september: 5.062,
            ic_october: 5.575,
            ic_november: 5.82,
            ic_december: 6.181,
            ic_yearly: 5.175,
          },
          {
            ic_uuid: "7dd0a861-1b24-40f0-9ec7-221360bb0ed7",
            ic_lat: -168903,
            ic_lon: -409233,
            ic_city: "Fronteira dos Vales",
            ic_states: "MINAS GERAIS",
            ic_january: 6.081,
            ic_february: 6.32,
            ic_march: 5.395,
            ic_april: 4.678,
            ic_may: 3.901,
            ic_june: 3.561,
            ic_july: 3.706,
            ic_august: 4.432,
            ic_september: 5.134,
            ic_october: 5.315,
            ic_november: 5.105,
            ic_december: 6.084,
            ic_yearly: 4.976,
          },
          {
            ic_uuid: "ed559d47-4b6b-4d07-b126-b643afdd6e46",
            ic_lat: -70877,
            ic_lon: -406149,
            ic_city: "Fronteiras",
            ic_states: "PIAUÍ",
            ic_january: 5.555,
            ic_february: 5.539,
            ic_march: 5.586,
            ic_april: 5.288,
            ic_may: 5.153,
            ic_june: 5.116,
            ic_july: 5.476,
            ic_august: 6.261,
            ic_september: 6.682,
            ic_october: 6.519,
            ic_november: 6.465,
            ic_december: 6.017,
            ic_yearly: 5.805,
          },
          {
            ic_uuid: "0eef5d02-5b9e-4617-8fc1-0bb544330831",
            ic_lat: -161172,
            ic_lon: -425235,
            ic_city: "Fruta de Leite",
            ic_states: "MINAS GERAIS",
            ic_january: 6.165,
            ic_february: 6.415,
            ic_march: 5.455,
            ic_april: 4.806,
            ic_may: 4.204,
            ic_june: 3.9,
            ic_july: 4.243,
            ic_august: 5.04,
            ic_september: 5.682,
            ic_october: 5.776,
            ic_november: 5.311,
            ic_december: 6.1,
            ic_yearly: 5.258,
          },
          {
            ic_uuid: "2808735a-b8e3-45fb-97cc-d99fcd179783",
            ic_lat: -200263,
            ic_lon: -489359,
            ic_city: "Frutal",
            ic_states: "MINAS GERAIS",
            ic_january: 5.713,
            ic_february: 5.934,
            ic_march: 5.171,
            ic_april: 4.99,
            ic_may: 4.305,
            ic_june: 4.032,
            ic_july: 4.294,
            ic_august: 5.202,
            ic_september: 5.124,
            ic_october: 5.554,
            ic_november: 5.703,
            ic_december: 6.015,
            ic_yearly: 5.17,
          },
          {
            ic_uuid: "e1b70aa0-d2f3-4342-9d44-53201c1900c5",
            ic_lat: -61571,
            ic_lon: -378378,
            ic_city: "Frutuoso Gomes",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.057,
            ic_february: 6.043,
            ic_march: 6.041,
            ic_april: 5.795,
            ic_may: 5.33,
            ic_june: 4.986,
            ic_july: 5.328,
            ic_august: 6.037,
            ic_september: 6.498,
            ic_october: 6.636,
            ic_november: 6.64,
            ic_december: 6.195,
            ic_yearly: 5.965,
          },
          {
            ic_uuid: "b7ffd56a-c1dc-44a7-a8f3-be4601fba07a",
            ic_lat: -199375,
            ic_lon: -404081,
            ic_city: "Fundão",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.773,
            ic_february: 6.014,
            ic_march: 5.084,
            ic_april: 4.278,
            ic_may: 3.713,
            ic_june: 3.471,
            ic_july: 3.568,
            ic_august: 4.084,
            ic_september: 4.521,
            ic_october: 4.611,
            ic_november: 4.627,
            ic_december: 5.502,
            ic_yearly: 4.604,
          },
          {
            ic_uuid: "14d39a06-1367-4b4d-9c0c-9940b9fa89c2",
            ic_lat: -193676,
            ic_lon: -440626,
            ic_city: "Funilândia",
            ic_states: "MINAS GERAIS",
            ic_january: 5.991,
            ic_february: 6.141,
            ic_march: 5.359,
            ic_april: 4.949,
            ic_may: 4.391,
            ic_june: 4.144,
            ic_july: 4.474,
            ic_august: 5.283,
            ic_september: 5.687,
            ic_october: 5.726,
            ic_november: 5.494,
            ic_december: 5.858,
            ic_yearly: 5.291,
          },
          {
            ic_uuid: "7e286691-2677-4479-918e-ae411793b709",
            ic_lat: -21531,
            ic_lon: -505549,
            ic_city: "Gabriel Monteiro",
            ic_states: "SÃO PAULO",
            ic_january: 5.948,
            ic_february: 5.935,
            ic_march: 5.379,
            ic_april: 4.881,
            ic_may: 3.985,
            ic_june: 3.716,
            ic_july: 3.929,
            ic_august: 4.845,
            ic_september: 4.89,
            ic_october: 5.591,
            ic_november: 6.057,
            ic_december: 6.295,
            ic_yearly: 5.121,
          },
          {
            ic_uuid: "177024cc-e286-497c-9bfb-f0a20ed7d69e",
            ic_lat: -75832,
            ic_lon: -357902,
            ic_city: "Gado Bravo",
            ic_states: "PARAÍBA",
            ic_january: 5.665,
            ic_february: 5.735,
            ic_march: 5.753,
            ic_april: 5.206,
            ic_may: 4.56,
            ic_june: 4.068,
            ic_july: 4.297,
            ic_august: 4.916,
            ic_september: 5.524,
            ic_october: 5.788,
            ic_november: 6.033,
            ic_december: 5.788,
            ic_yearly: 5.278,
          },
          {
            ic_uuid: "c8b42351-a6f7-4ff5-ab80-36edf2357796",
            ic_lat: -222923,
            ic_lon: -495508,
            ic_city: "Gália",
            ic_states: "SÃO PAULO",
            ic_january: 5.635,
            ic_february: 5.827,
            ic_march: 5.184,
            ic_april: 4.677,
            ic_may: 3.855,
            ic_june: 3.574,
            ic_july: 3.791,
            ic_august: 4.769,
            ic_september: 4.828,
            ic_october: 5.535,
            ic_november: 5.88,
            ic_december: 6.194,
            ic_yearly: 4.979,
          },
          {
            ic_uuid: "4c61cea5-5b62-45a9-ac81-ae42407c2635",
            ic_lat: -190009,
            ic_lon: -41539,
            ic_city: "Galiléia",
            ic_states: "MINAS GERAIS",
            ic_january: 6.131,
            ic_february: 6.238,
            ic_march: 5.343,
            ic_april: 4.687,
            ic_may: 3.917,
            ic_june: 3.691,
            ic_july: 3.749,
            ic_august: 4.414,
            ic_september: 4.905,
            ic_october: 5.185,
            ic_november: 5.015,
            ic_december: 5.879,
            ic_yearly: 4.929,
          },
          {
            ic_uuid: "2a4aec3c-4b6f-416a-a604-c96e1a967448",
            ic_lat: -50913,
            ic_lon: -362757,
            ic_city: "Galinhos",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.047,
            ic_february: 5.925,
            ic_march: 5.888,
            ic_april: 5.525,
            ic_may: 5.277,
            ic_june: 4.824,
            ic_july: 4.988,
            ic_august: 5.669,
            ic_september: 6.07,
            ic_october: 6.158,
            ic_november: 6.397,
            ic_december: 6.214,
            ic_yearly: 5.749,
          },
          {
            ic_uuid: "b926c9d1-c359-4247-b18f-14dbbc145f48",
            ic_lat: -264554,
            ic_lon: -52688,
            ic_city: "Galvão",
            ic_states: "SANTA CATARINA",
            ic_january: 6.149,
            ic_february: 5.662,
            ic_march: 5.086,
            ic_april: 4.149,
            ic_may: 3.224,
            ic_june: 2.741,
            ic_july: 3.046,
            ic_august: 3.952,
            ic_september: 4.156,
            ic_october: 5.116,
            ic_november: 6.091,
            ic_december: 6.294,
            ic_yearly: 4.639,
          },
          {
            ic_uuid: "c457413a-5eb3-4aba-96a9-1f271c5649e6",
            ic_lat: -85802,
            ic_lon: -353849,
            ic_city: "Gameleira",
            ic_states: "PERNAMBUCO",
            ic_january: 5.689,
            ic_february: 5.759,
            ic_march: 5.732,
            ic_april: 5.029,
            ic_may: 4.342,
            ic_june: 3.954,
            ic_july: 4.038,
            ic_august: 4.667,
            ic_september: 5.375,
            ic_october: 5.682,
            ic_november: 6.05,
            ic_december: 6.091,
            ic_yearly: 5.201,
          },
          {
            ic_uuid: "cc036768-5ed1-4d9d-9220-475905ae2509",
            ic_lat: -164835,
            ic_lon: -486448,
            ic_city: "Gameleira de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.483,
            ic_february: 5.729,
            ic_march: 5.081,
            ic_april: 5.064,
            ic_may: 4.769,
            ic_june: 4.654,
            ic_july: 4.914,
            ic_august: 5.78,
            ic_september: 5.55,
            ic_october: 5.56,
            ic_november: 5.1,
            ic_december: 5.517,
            ic_yearly: 5.267,
          },
          {
            ic_uuid: "56ef5fa3-a51a-4f6e-8372-9667a93f7be0",
            ic_lat: -150838,
            ic_lon: -431203,
            ic_city: "Gameleiras",
            ic_states: "MINAS GERAIS",
            ic_january: 6.421,
            ic_february: 6.593,
            ic_march: 5.861,
            ic_april: 5.411,
            ic_may: 4.828,
            ic_june: 4.615,
            ic_july: 4.909,
            ic_august: 5.63,
            ic_september: 6.146,
            ic_october: 6.146,
            ic_november: 5.644,
            ic_december: 6.349,
            ic_yearly: 5.713,
          },
          {
            ic_uuid: "a524b864-071a-48bb-9a2a-133829197209",
            ic_lat: -137471,
            ic_lon: -394899,
            ic_city: "Gandu",
            ic_states: "BAHIA",
            ic_january: 5.898,
            ic_february: 5.846,
            ic_march: 5.53,
            ic_april: 4.462,
            ic_may: 3.829,
            ic_june: 3.436,
            ic_july: 3.705,
            ic_august: 4.184,
            ic_september: 4.887,
            ic_october: 5.089,
            ic_november: 5.337,
            ic_december: 5.924,
            ic_yearly: 4.844,
          },
          {
            ic_uuid: "8ccfd035-30ad-41c7-a35a-45e9fbc19101",
            ic_lat: -88829,
            ic_lon: -364969,
            ic_city: "Garanhuns",
            ic_states: "PERNAMBUCO",
            ic_january: 6.0,
            ic_february: 5.706,
            ic_march: 5.817,
            ic_april: 5.13,
            ic_may: 4.272,
            ic_june: 3.812,
            ic_july: 3.942,
            ic_august: 4.587,
            ic_september: 5.448,
            ic_october: 5.763,
            ic_november: 6.319,
            ic_december: 6.226,
            ic_yearly: 5.252,
          },
          {
            ic_uuid: "b367d59b-2163-4a1d-a46a-d6ff426f0555",
            ic_lat: -99701,
            ic_lon: -370869,
            ic_city: "Gararu",
            ic_states: "SERGIPE",
            ic_january: 6.289,
            ic_february: 6.051,
            ic_march: 5.999,
            ic_april: 5.276,
            ic_may: 4.383,
            ic_june: 4.141,
            ic_july: 4.214,
            ic_august: 4.867,
            ic_september: 5.694,
            ic_october: 5.93,
            ic_november: 6.379,
            ic_december: 6.328,
            ic_yearly: 5.463,
          },
          {
            ic_uuid: "c8400493-e712-4e6a-a965-020dd90f7080",
            ic_lat: -22213,
            ic_lon: -49655,
            ic_city: "Garça",
            ic_states: "SÃO PAULO",
            ic_january: 5.663,
            ic_february: 5.828,
            ic_march: 5.255,
            ic_april: 4.717,
            ic_may: 3.885,
            ic_june: 3.589,
            ic_july: 3.825,
            ic_august: 4.781,
            ic_september: 4.845,
            ic_october: 5.57,
            ic_november: 5.914,
            ic_december: 6.236,
            ic_yearly: 5.009,
          },
          {
            ic_uuid: "721fbda1-d8a0-4530-bcd4-33444de17c4c",
            ic_lat: -292595,
            ic_lon: -515356,
            ic_city: "Garibaldi",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.168,
            ic_february: 5.688,
            ic_march: 4.743,
            ic_april: 3.824,
            ic_may: 2.82,
            ic_june: 2.386,
            ic_july: 2.648,
            ic_august: 3.376,
            ic_september: 3.617,
            ic_october: 4.73,
            ic_november: 6.124,
            ic_december: 6.506,
            ic_yearly: 4.386,
          },
          {
            ic_uuid: "a8a7b078-b745-42af-b504-f91d286c067c",
            ic_lat: -280285,
            ic_lon: -486236,
            ic_city: "Garopaba",
            ic_states: "SANTA CATARINA",
            ic_january: 5.636,
            ic_february: 5.165,
            ic_march: 4.471,
            ic_april: 3.67,
            ic_may: 3.009,
            ic_june: 2.539,
            ic_july: 2.715,
            ic_august: 3.443,
            ic_september: 3.55,
            ic_october: 4.199,
            ic_november: 5.445,
            ic_december: 5.826,
            ic_yearly: 4.139,
          },
          {
            ic_uuid: "c31e7772-70d7-4c0f-9270-3b7a75b44d44",
            ic_lat: -19302,
            ic_lon: -470509,
            ic_city: "Garrafão do Norte",
            ic_states: "PARÁ",
            ic_january: 4.644,
            ic_february: 4.456,
            ic_march: 4.54,
            ic_april: 4.547,
            ic_may: 4.672,
            ic_june: 4.943,
            ic_july: 5.082,
            ic_august: 5.443,
            ic_september: 5.57,
            ic_october: 5.359,
            ic_november: 5.165,
            ic_december: 4.773,
            ic_yearly: 4.933,
          },
          {
            ic_uuid: "0ce3cbea-0dc6-4342-9761-064f1f671201",
            ic_lat: -281949,
            ic_lon: -556388,
            ic_city: "Garruchos",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.7,
            ic_february: 6.135,
            ic_march: 5.271,
            ic_april: 4.032,
            ic_may: 3.096,
            ic_june: 2.532,
            ic_july: 2.841,
            ic_august: 3.608,
            ic_september: 4.133,
            ic_october: 5.223,
            ic_november: 6.418,
            ic_december: 6.865,
            ic_yearly: 4.738,
          },
          {
            ic_uuid: "b1ae9f91-8e0e-4e40-9759-5144db7ce7a4",
            ic_lat: -260297,
            ic_lon: -488524,
            ic_city: "Garuva",
            ic_states: "SANTA CATARINA",
            ic_january: 4.714,
            ic_february: 4.534,
            ic_march: 3.955,
            ic_april: 3.327,
            ic_may: 2.844,
            ic_june: 2.436,
            ic_july: 2.528,
            ic_august: 3.238,
            ic_september: 3.066,
            ic_october: 3.457,
            ic_november: 4.351,
            ic_december: 4.683,
            ic_yearly: 3.594,
          },
          {
            ic_uuid: "21098e42-8633-43ac-afba-61fda26f6f8f",
            ic_lat: -269341,
            ic_lon: -489539,
            ic_city: "Gaspar",
            ic_states: "SANTA CATARINA",
            ic_january: 5.425,
            ic_february: 5.178,
            ic_march: 4.492,
            ic_april: 3.627,
            ic_may: 2.993,
            ic_june: 2.464,
            ic_july: 2.61,
            ic_august: 3.224,
            ic_september: 3.356,
            ic_october: 3.782,
            ic_november: 4.993,
            ic_december: 5.455,
            ic_yearly: 3.967,
          },
          {
            ic_uuid: "1b14ab4b-ac71-4666-8214-98c29ed5f9e6",
            ic_lat: -20799,
            ic_lon: -501869,
            ic_city: "Gastão Vidigal",
            ic_states: "SÃO PAULO",
            ic_january: 5.84,
            ic_february: 5.941,
            ic_march: 5.299,
            ic_april: 4.922,
            ic_may: 4.125,
            ic_june: 3.865,
            ic_july: 4.069,
            ic_august: 5.009,
            ic_september: 4.999,
            ic_october: 5.641,
            ic_november: 5.986,
            ic_december: 6.233,
            ic_yearly: 5.161,
          },
          {
            ic_uuid: "a905feef-079b-4147-b52f-99a1d72ca03a",
            ic_lat: -131773,
            ic_lon: -532624,
            ic_city: "Gaúcha do Norte",
            ic_states: "MATO GROSSO",
            ic_january: 5.248,
            ic_february: 5.372,
            ic_march: 5.11,
            ic_april: 5.168,
            ic_may: 4.895,
            ic_june: 4.716,
            ic_july: 5.08,
            ic_august: 5.555,
            ic_september: 5.256,
            ic_october: 5.281,
            ic_november: 5.186,
            ic_december: 5.236,
            ic_yearly: 5.175,
          },
          {
            ic_uuid: "045615cf-1658-4c02-ab80-17a73f5227ed",
            ic_lat: -275861,
            ic_lon: -52092,
            ic_city: "Gaurama",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.263,
            ic_february: 5.77,
            ic_march: 5.006,
            ic_april: 4.045,
            ic_may: 3.066,
            ic_june: 2.574,
            ic_july: 2.911,
            ic_august: 3.715,
            ic_september: 3.969,
            ic_october: 5.05,
            ic_november: 6.217,
            ic_december: 6.506,
            ic_yearly: 4.591,
          },
          {
            ic_uuid: "e995ca0e-36d5-4e6c-b602-e8740bb9d6f4",
            ic_lat: -114712,
            ic_lon: -397832,
            ic_city: "Gavião",
            ic_states: "BAHIA",
            ic_january: 6.183,
            ic_february: 5.954,
            ic_march: 5.805,
            ic_april: 4.784,
            ic_may: 4.038,
            ic_june: 3.709,
            ic_july: 3.945,
            ic_august: 4.537,
            ic_september: 5.4,
            ic_october: 5.577,
            ic_november: 5.91,
            ic_december: 5.97,
            ic_yearly: 5.151,
          },
          {
            ic_uuid: "a5657e05-c7c1-43ab-99f2-2f205ebfb2cf",
            ic_lat: -218371,
            ic_lon: -484961,
            ic_city: "Gavião Peixoto",
            ic_states: "SÃO PAULO",
            ic_january: 5.691,
            ic_february: 5.873,
            ic_march: 5.136,
            ic_april: 4.724,
            ic_may: 3.982,
            ic_june: 3.7,
            ic_july: 3.92,
            ic_august: 4.83,
            ic_september: 4.954,
            ic_october: 5.559,
            ic_november: 5.798,
            ic_december: 6.077,
            ic_yearly: 5.02,
          },
          {
            ic_uuid: "fbe750b6-134c-4727-9aaa-7ad9b6b09330",
            ic_lat: -71585,
            ic_lon: -413634,
            ic_city: "Geminiano",
            ic_states: "PIAUÍ",
            ic_january: 5.502,
            ic_february: 5.403,
            ic_march: 5.489,
            ic_april: 5.294,
            ic_may: 5.235,
            ic_june: 5.147,
            ic_july: 5.528,
            ic_august: 6.248,
            ic_september: 6.667,
            ic_october: 6.43,
            ic_november: 6.287,
            ic_december: 5.899,
            ic_yearly: 5.761,
          },
          {
            ic_uuid: "eed72ea0-284c-4ccf-9d8c-89a6a263522b",
            ic_lat: -299037,
            ic_lon: -517616,
            ic_city: "General Câmara",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.393,
            ic_february: 5.842,
            ic_march: 4.866,
            ic_april: 3.792,
            ic_may: 2.765,
            ic_june: 2.293,
            ic_july: 2.502,
            ic_august: 3.234,
            ic_september: 3.583,
            ic_october: 4.774,
            ic_november: 6.204,
            ic_december: 6.666,
            ic_yearly: 4.41,
          },
          {
            ic_uuid: "883994e8-1070-4696-81c8-64a57d71ea31",
            ic_lat: -157099,
            ic_lon: -527578,
            ic_city: "General Carneiro",
            ic_states: "MATO GROSSO",
            ic_january: 5.442,
            ic_february: 5.565,
            ic_march: 5.257,
            ic_april: 5.342,
            ic_may: 4.969,
            ic_june: 4.609,
            ic_july: 4.807,
            ic_august: 5.519,
            ic_september: 5.387,
            ic_october: 5.431,
            ic_november: 5.384,
            ic_december: 5.52,
            ic_yearly: 5.269,
          },
          {
            ic_uuid: "5af1259c-be9b-4923-a5f7-5592bc5567f7",
            ic_lat: -264254,
            ic_lon: -513177,
            ic_city: "General Carneiro",
            ic_states: "PARANÁ",
            ic_january: 5.41,
            ic_february: 5.09,
            ic_march: 4.544,
            ic_april: 3.654,
            ic_may: 2.93,
            ic_june: 2.551,
            ic_july: 2.804,
            ic_august: 3.771,
            ic_september: 3.844,
            ic_october: 4.488,
            ic_november: 5.459,
            ic_december: 5.656,
            ic_yearly: 4.184,
          },
          {
            ic_uuid: "70361138-d910-4e4a-ad42-1fa8fc5df4c0",
            ic_lat: -106839,
            ic_lon: -369841,
            ic_city: "General Maynard",
            ic_states: "SERGIPE",
            ic_january: 6.436,
            ic_february: 6.307,
            ic_march: 6.139,
            ic_april: 5.164,
            ic_may: 4.37,
            ic_june: 4.092,
            ic_july: 4.168,
            ic_august: 4.812,
            ic_september: 5.578,
            ic_october: 5.996,
            ic_november: 6.468,
            ic_december: 6.525,
            ic_yearly: 5.504,
          },
          {
            ic_uuid: "d291afda-041f-4495-9b45-f617ff0926af",
            ic_lat: -20648,
            ic_lon: -503609,
            ic_city: "General Salgado",
            ic_states: "SÃO PAULO",
            ic_january: 5.858,
            ic_february: 5.947,
            ic_march: 5.322,
            ic_april: 4.902,
            ic_may: 4.15,
            ic_june: 3.891,
            ic_july: 4.111,
            ic_august: 5.028,
            ic_september: 5.093,
            ic_october: 5.614,
            ic_november: 5.963,
            ic_december: 6.242,
            ic_yearly: 5.177,
          },
          {
            ic_uuid: "1a1e9d4d-7e45-4a0b-88ad-aeb67e828e4d",
            ic_lat: -40439,
            ic_lon: -394543,
            ic_city: "General Sampaio",
            ic_states: "CEARÁ",
            ic_january: 5.098,
            ic_february: 5.32,
            ic_march: 5.243,
            ic_april: 4.923,
            ic_may: 5.134,
            ic_june: 4.976,
            ic_july: 5.293,
            ic_august: 6.046,
            ic_september: 6.5,
            ic_october: 6.351,
            ic_november: 6.113,
            ic_december: 5.504,
            ic_yearly: 5.542,
          },
          {
            ic_uuid: "9e509f4f-fda8-4fa8-b3f9-871b5b609148",
            ic_lat: -284321,
            ic_lon: -520341,
            ic_city: "Gentil",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.136,
            ic_february: 5.748,
            ic_march: 4.926,
            ic_april: 3.976,
            ic_may: 2.971,
            ic_june: 2.514,
            ic_july: 2.809,
            ic_august: 3.555,
            ic_september: 3.806,
            ic_october: 4.954,
            ic_november: 6.152,
            ic_december: 6.499,
            ic_yearly: 4.504,
          },
          {
            ic_uuid: "60f61ea0-76ed-4965-8638-1db976b9ff24",
            ic_lat: -114347,
            ic_lon: -42508,
            ic_city: "Gentio do Ouro",
            ic_states: "BAHIA",
            ic_january: 6.436,
            ic_february: 6.288,
            ic_march: 6.067,
            ic_april: 5.588,
            ic_may: 5.172,
            ic_june: 5.055,
            ic_july: 5.36,
            ic_august: 6.128,
            ic_september: 6.616,
            ic_october: 6.557,
            ic_november: 6.209,
            ic_december: 6.424,
            ic_yearly: 5.992,
          },
          {
            ic_uuid: "cfbbce3f-88b0-4630-aa6d-a3a42e2cb0df",
            ic_lat: -21799,
            ic_lon: -499289,
            ic_city: "Getulina",
            ic_states: "SÃO PAULO",
            ic_january: 5.851,
            ic_february: 5.894,
            ic_march: 5.299,
            ic_april: 4.821,
            ic_may: 3.926,
            ic_june: 3.62,
            ic_july: 3.87,
            ic_august: 4.752,
            ic_september: 4.872,
            ic_october: 5.582,
            ic_november: 5.936,
            ic_december: 6.268,
            ic_yearly: 5.058,
          },
          {
            ic_uuid: "f55a3fd2-f646-4215-8b91-a51b9f98d28a",
            ic_lat: -278916,
            ic_lon: -522298,
            ic_city: "Getúlio Vargas",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.259,
            ic_february: 5.797,
            ic_march: 4.941,
            ic_april: 4.022,
            ic_may: 3.043,
            ic_june: 2.56,
            ic_july: 2.901,
            ic_august: 3.673,
            ic_september: 3.918,
            ic_october: 5.041,
            ic_november: 6.158,
            ic_december: 6.501,
            ic_yearly: 4.568,
          },
          {
            ic_uuid: "b75b7ed4-d8fb-4626-a252-0d5c88651c08",
            ic_lat: -98309,
            ic_lon: -45337,
            ic_city: "Gilbués",
            ic_states: "PIAUÍ",
            ic_january: 5.786,
            ic_february: 5.667,
            ic_march: 5.453,
            ic_april: 5.386,
            ic_may: 5.481,
            ic_june: 5.221,
            ic_july: 5.356,
            ic_august: 6.066,
            ic_september: 6.376,
            ic_october: 6.132,
            ic_november: 5.704,
            ic_december: 5.808,
            ic_yearly: 5.703,
          },
          {
            ic_uuid: "ca4e3e0a-dfa3-4fdd-9ed2-cc2c6db018dd",
            ic_lat: -98845,
            ic_lon: -368319,
            ic_city: "Girau do Ponciano",
            ic_states: "ALAGOAS",
            ic_january: 6.01,
            ic_february: 5.815,
            ic_march: 5.843,
            ic_april: 5.091,
            ic_may: 4.266,
            ic_june: 3.992,
            ic_july: 4.098,
            ic_august: 4.682,
            ic_september: 5.461,
            ic_october: 5.774,
            ic_november: 6.247,
            ic_december: 6.205,
            ic_yearly: 5.29,
          },
          {
            ic_uuid: "331d73b7-726b-43a5-ab07-2c899481ef5a",
            ic_lat: -280301,
            ic_lon: -543521,
            ic_city: "Giruá",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.525,
            ic_february: 6.076,
            ic_march: 5.155,
            ic_april: 4.076,
            ic_may: 3.111,
            ic_june: 2.564,
            ic_july: 2.866,
            ic_august: 3.659,
            ic_september: 4.03,
            ic_october: 5.171,
            ic_november: 6.297,
            ic_december: 6.705,
            ic_yearly: 4.686,
          },
          {
            ic_uuid: "af4577bb-d49a-451a-8cb9-b00fa670b7c5",
            ic_lat: -168485,
            ic_lon: -436923,
            ic_city: "Glaucilândia",
            ic_states: "MINAS GERAIS",
            ic_january: 6.289,
            ic_february: 6.505,
            ic_march: 5.54,
            ic_april: 5.251,
            ic_may: 4.752,
            ic_june: 4.467,
            ic_july: 4.753,
            ic_august: 5.544,
            ic_september: 6.017,
            ic_october: 5.965,
            ic_november: 5.437,
            ic_december: 6.136,
            ic_yearly: 5.555,
          },
          {
            ic_uuid: "eb612790-1b2a-4a25-b9b6-3d5b25ad11cb",
            ic_lat: -21384,
            ic_lon: -502109,
            ic_city: "Glicério",
            ic_states: "SÃO PAULO",
            ic_january: 5.968,
            ic_february: 6.043,
            ic_march: 5.356,
            ic_april: 4.831,
            ic_may: 4.015,
            ic_june: 3.707,
            ic_july: 3.953,
            ic_august: 4.851,
            ic_september: 4.919,
            ic_october: 5.617,
            ic_november: 6.052,
            ic_december: 6.335,
            ic_yearly: 5.137,
          },
          {
            ic_uuid: "fe709f14-7216-4ce4-be60-7da749f50c00",
            ic_lat: -93433,
            ic_lon: -382595,
            ic_city: "Glória",
            ic_states: "BAHIA",
            ic_january: 6.298,
            ic_february: 6.308,
            ic_march: 6.111,
            ic_april: 5.481,
            ic_may: 4.638,
            ic_june: 4.154,
            ic_july: 4.359,
            ic_august: 4.987,
            ic_september: 5.824,
            ic_october: 6.055,
            ic_november: 6.496,
            ic_december: 6.441,
            ic_yearly: 5.596,
          },
          {
            ic_uuid: "e0cb0182-83f6-46c2-a839-fb55ace0b831",
            ic_lat: -22418,
            ic_lon: -542329,
            ic_city: "Glória de Dourados",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.972,
            ic_february: 5.869,
            ic_march: 5.395,
            ic_april: 4.579,
            ic_may: 3.698,
            ic_june: 3.363,
            ic_july: 3.545,
            ic_august: 4.508,
            ic_september: 4.762,
            ic_october: 5.423,
            ic_november: 6.069,
            ic_december: 6.444,
            ic_yearly: 4.969,
          },
          {
            ic_uuid: "36adb0c7-bbc5-4340-ba52-8fae6d804fb8",
            ic_lat: -80061,
            ic_lon: -352907,
            ic_city: "Glória do Goitá",
            ic_states: "PERNAMBUCO",
            ic_january: 5.781,
            ic_february: 5.841,
            ic_march: 5.832,
            ic_april: 5.179,
            ic_may: 4.489,
            ic_june: 4.146,
            ic_july: 4.274,
            ic_august: 4.959,
            ic_september: 5.567,
            ic_october: 5.79,
            ic_november: 6.007,
            ic_december: 6.05,
            ic_yearly: 5.326,
          },
          {
            ic_uuid: "ea017d98-7287-4f1f-89e8-25a912f920c2",
            ic_lat: -157684,
            ic_lon: -583085,
            ic_city: "Glória D'Oeste",
            ic_states: "MATO GROSSO",
            ic_january: 5.449,
            ic_february: 5.297,
            ic_march: 5.045,
            ic_april: 4.777,
            ic_may: 4.076,
            ic_june: 4.119,
            ic_july: 4.281,
            ic_august: 5.005,
            ic_september: 5.075,
            ic_october: 5.359,
            ic_november: 5.553,
            ic_december: 5.67,
            ic_yearly: 4.975,
          },
          {
            ic_uuid: "195f4465-c1c4-4aa4-9bbc-3805bfeb47e0",
            ic_lat: -298803,
            ic_lon: -507738,
            ic_city: "Glorinha",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.395,
            ic_february: 5.828,
            ic_march: 4.823,
            ic_april: 3.837,
            ic_may: 2.822,
            ic_june: 2.35,
            ic_july: 2.554,
            ic_august: 3.196,
            ic_september: 3.603,
            ic_october: 4.754,
            ic_november: 6.244,
            ic_december: 6.658,
            ic_yearly: 4.422,
          },
          {
            ic_uuid: "88dbe250-6d0e-4088-afe6-816e34bbc215",
            ic_lat: -1403,
            ic_lon: -457798,
            ic_city: "Godofredo Viana",
            ic_states: "MARANHÃO",
            ic_january: 4.74,
            ic_february: 4.476,
            ic_march: 4.314,
            ic_april: 4.222,
            ic_may: 4.471,
            ic_june: 4.767,
            ic_july: 4.863,
            ic_august: 5.29,
            ic_september: 5.648,
            ic_october: 5.446,
            ic_november: 5.311,
            ic_december: 5.028,
            ic_yearly: 4.881,
          },
          {
            ic_uuid: "98181e28-fef3-443e-8ce3-3fb30f154843",
            ic_lat: -241734,
            ic_lon: -519251,
            ic_city: "Godoy Moreira",
            ic_states: "PARANÁ",
            ic_january: 6.096,
            ic_february: 5.836,
            ic_march: 5.301,
            ic_april: 4.606,
            ic_may: 3.553,
            ic_june: 3.14,
            ic_july: 3.389,
            ic_august: 4.355,
            ic_september: 4.581,
            ic_october: 5.311,
            ic_november: 6.174,
            ic_december: 6.392,
            ic_yearly: 4.895,
          },
          {
            ic_uuid: "e19ab8d8-62a3-4f3a-8c5d-7c2f22331582",
            ic_lat: -189811,
            ic_lon: -412239,
            ic_city: "Goiabeira",
            ic_states: "MINAS GERAIS",
            ic_january: 6.088,
            ic_february: 6.188,
            ic_march: 5.306,
            ic_april: 4.611,
            ic_may: 3.894,
            ic_june: 3.609,
            ic_july: 3.733,
            ic_august: 4.351,
            ic_september: 4.85,
            ic_october: 5.091,
            ic_november: 5.016,
            ic_december: 5.812,
            ic_yearly: 4.879,
          },
          {
            ic_uuid: "710fb885-339a-4b15-b55d-e8cd1fbfb8ed",
            ic_lat: -7561,
            ic_lon: -349962,
            ic_city: "Goiana",
            ic_states: "PERNAMBUCO",
            ic_january: 5.806,
            ic_february: 6.119,
            ic_march: 6.042,
            ic_april: 5.3,
            ic_may: 4.657,
            ic_june: 4.28,
            ic_july: 4.374,
            ic_august: 5.139,
            ic_september: 5.546,
            ic_october: 5.93,
            ic_november: 6.096,
            ic_december: 6.124,
            ic_yearly: 5.451,
          },
          {
            ic_uuid: "7b8bc2ae-4250-4688-ab2f-b47e22d8481a",
            ic_lat: -215365,
            ic_lon: -43196,
            ic_city: "Goianá",
            ic_states: "MINAS GERAIS",
            ic_january: 5.717,
            ic_february: 5.94,
            ic_march: 4.92,
            ic_april: 4.285,
            ic_may: 3.62,
            ic_june: 3.436,
            ic_july: 3.615,
            ic_august: 4.391,
            ic_september: 4.703,
            ic_october: 4.97,
            ic_november: 4.805,
            ic_december: 5.586,
            ic_yearly: 4.665,
          },
          {
            ic_uuid: "9345b6d4-c904-4901-b78b-d744847ff2c9",
            ic_lat: -165023,
            ic_lon: -490207,
            ic_city: "Goianápolis",
            ic_states: "GOIÁS",
            ic_january: 5.46,
            ic_february: 5.55,
            ic_march: 5.137,
            ic_april: 5.082,
            ic_may: 4.822,
            ic_june: 4.608,
            ic_july: 4.847,
            ic_august: 5.704,
            ic_september: 5.512,
            ic_october: 5.548,
            ic_november: 5.222,
            ic_december: 5.391,
            ic_yearly: 5.24,
          },
          {
            ic_uuid: "9f410a69-282e-4365-91ba-409d6f65cf11",
            ic_lat: -181356,
            ic_lon: -480878,
            ic_city: "Goiandira",
            ic_states: "GOIÁS",
            ic_january: 5.682,
            ic_february: 5.91,
            ic_march: 5.196,
            ic_april: 5.134,
            ic_may: 4.693,
            ic_june: 4.446,
            ic_july: 4.705,
            ic_august: 5.617,
            ic_september: 5.561,
            ic_october: 5.697,
            ic_november: 5.555,
            ic_december: 5.757,
            ic_yearly: 5.33,
          },
          {
            ic_uuid: "449a88b3-2eba-4ce4-98a3-8badf4bb5ee9",
            ic_lat: -153123,
            ic_lon: -491166,
            ic_city: "Goianésia",
            ic_states: "GOIÁS",
            ic_january: 5.354,
            ic_february: 5.594,
            ic_march: 5.192,
            ic_april: 5.158,
            ic_may: 4.935,
            ic_june: 4.704,
            ic_july: 4.863,
            ic_august: 5.699,
            ic_september: 5.581,
            ic_october: 5.514,
            ic_november: 5.187,
            ic_december: 5.356,
            ic_yearly: 5.261,
          },
          {
            ic_uuid: "6978fe67-48c8-4e41-b910-c246ed524719",
            ic_lat: -38438,
            ic_lon: -490978,
            ic_city: "Goianésia do Pará",
            ic_states: "PARÁ",
            ic_january: 4.385,
            ic_february: 4.562,
            ic_march: 4.665,
            ic_april: 4.647,
            ic_may: 4.643,
            ic_june: 4.941,
            ic_july: 4.971,
            ic_august: 5.231,
            ic_september: 5.083,
            ic_october: 4.816,
            ic_november: 4.633,
            ic_december: 4.329,
            ic_yearly: 4.742,
          },
          {
            ic_uuid: "1f3246b2-29c6-4d19-8ca3-5cae6ca50036",
            ic_lat: -166873,
            ic_lon: -492651,
            ic_city: "Goiânia",
            ic_states: "GOIÁS",
            ic_january: 5.452,
            ic_february: 5.518,
            ic_march: 5.12,
            ic_april: 5.024,
            ic_may: 4.753,
            ic_june: 4.56,
            ic_july: 4.78,
            ic_august: 5.678,
            ic_september: 5.567,
            ic_october: 5.611,
            ic_november: 5.391,
            ic_december: 5.505,
            ic_yearly: 5.247,
          },
          {
            ic_uuid: "839c8a69-f4db-4bd9-b01c-e0a082b15653",
            ic_lat: -62678,
            ic_lon: -352092,
            ic_city: "Goianinha",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.855,
            ic_february: 5.993,
            ic_march: 6.065,
            ic_april: 5.443,
            ic_may: 4.88,
            ic_june: 4.426,
            ic_july: 4.526,
            ic_august: 5.317,
            ic_september: 5.789,
            ic_october: 6.013,
            ic_november: 6.228,
            ic_december: 5.989,
            ic_yearly: 5.544,
          },
          {
            ic_uuid: "fa0fd6b2-7f55-4b78-8cc2-370d1907563b",
            ic_lat: -165118,
            ic_lon: -494206,
            ic_city: "Goianira",
            ic_states: "GOIÁS",
            ic_january: 5.493,
            ic_february: 5.572,
            ic_march: 5.191,
            ic_april: 5.116,
            ic_may: 4.827,
            ic_june: 4.601,
            ic_july: 4.812,
            ic_august: 5.749,
            ic_september: 5.545,
            ic_october: 5.546,
            ic_november: 5.288,
            ic_december: 5.444,
            ic_yearly: 5.265,
          },
          {
            ic_uuid: "2e4759dd-818e-43e3-8a5b-d4d9be55053d",
            ic_lat: -87709,
            ic_lon: -48936,
            ic_city: "Goianorte",
            ic_states: "TOCANTINS",
            ic_january: 4.858,
            ic_february: 4.911,
            ic_march: 4.702,
            ic_april: 4.743,
            ic_may: 4.989,
            ic_june: 5.108,
            ic_july: 5.344,
            ic_august: 6.06,
            ic_september: 5.686,
            ic_october: 5.117,
            ic_november: 4.968,
            ic_december: 4.846,
            ic_yearly: 5.111,
          },
          {
            ic_uuid: "00d8cb85-4ec1-4fa6-90d3-75b36a89e972",
            ic_lat: -159408,
            ic_lon: -501434,
            ic_city: "Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.387,
            ic_february: 5.569,
            ic_march: 5.247,
            ic_april: 5.187,
            ic_may: 4.911,
            ic_june: 4.66,
            ic_july: 4.731,
            ic_august: 5.679,
            ic_september: 5.446,
            ic_october: 5.586,
            ic_november: 5.334,
            ic_december: 5.367,
            ic_yearly: 5.259,
          },
          {
            ic_uuid: "8b15959a-f59a-48e3-b405-a2eabbe12f09",
            ic_lat: -77146,
            ic_lon: -473164,
            ic_city: "Goiatins",
            ic_states: "TOCANTINS",
            ic_january: 4.975,
            ic_february: 4.968,
            ic_march: 4.956,
            ic_april: 4.933,
            ic_may: 5.155,
            ic_june: 5.282,
            ic_july: 5.341,
            ic_august: 6.02,
            ic_september: 6.071,
            ic_october: 5.313,
            ic_november: 4.906,
            ic_december: 4.778,
            ic_yearly: 5.225,
          },
          {
            ic_uuid: "8befa52e-cbf1-41c0-b85a-cd0bef82ffd9",
            ic_lat: -18011,
            ic_lon: -493662,
            ic_city: "Goiatuba",
            ic_states: "GOIÁS",
            ic_january: 5.569,
            ic_february: 5.684,
            ic_march: 5.143,
            ic_april: 5.03,
            ic_may: 4.616,
            ic_june: 4.365,
            ic_july: 4.602,
            ic_august: 5.473,
            ic_september: 5.345,
            ic_october: 5.558,
            ic_november: 5.556,
            ic_december: 5.706,
            ic_yearly: 5.221,
          },
          {
            ic_uuid: "3c01b097-ba94-4744-ba06-40c50fcc70b2",
            ic_lat: -241839,
            ic_lon: -530253,
            ic_city: "Goioerê",
            ic_states: "PARANÁ",
            ic_january: 6.22,
            ic_february: 5.776,
            ic_march: 5.306,
            ic_april: 4.47,
            ic_may: 3.503,
            ic_june: 3.089,
            ic_july: 3.303,
            ic_august: 4.267,
            ic_september: 4.522,
            ic_october: 5.361,
            ic_november: 6.12,
            ic_december: 6.504,
            ic_yearly: 4.87,
          },
          {
            ic_uuid: "20e6ba94-16f1-4ed5-a941-a9fe58cdb0f0",
            ic_lat: -251931,
            ic_lon: -519915,
            ic_city: "Goioxim",
            ic_states: "PARANÁ",
            ic_january: 5.965,
            ic_february: 5.557,
            ic_march: 5.157,
            ic_april: 4.3,
            ic_may: 3.343,
            ic_june: 2.966,
            ic_july: 3.238,
            ic_august: 4.204,
            ic_september: 4.376,
            ic_october: 5.207,
            ic_november: 5.969,
            ic_december: 6.164,
            ic_yearly: 4.704,
          },
          {
            ic_uuid: "9fb1ce0f-7344-4727-8ee5-072948a9c29d",
            ic_lat: -22655,
            ic_lon: -45856,
            ic_city: "Gonçalves",
            ic_states: "MINAS GERAIS",
            ic_january: 5.041,
            ic_february: 5.383,
            ic_march: 4.594,
            ic_april: 4.319,
            ic_may: 3.694,
            ic_june: 3.446,
            ic_july: 3.69,
            ic_august: 4.656,
            ic_september: 4.771,
            ic_october: 5.166,
            ic_november: 5.091,
            ic_december: 5.495,
            ic_yearly: 4.612,
          },
          {
            ic_uuid: "bd5892f4-9e59-4e26-923e-f494f0a4e722",
            ic_lat: -51479,
            ic_lon: -443017,
            ic_city: "Gonçalves Dias",
            ic_states: "MARANHÃO",
            ic_january: 4.78,
            ic_february: 4.918,
            ic_march: 4.912,
            ic_april: 4.869,
            ic_may: 4.955,
            ic_june: 5.149,
            ic_july: 5.475,
            ic_august: 6.109,
            ic_september: 6.337,
            ic_october: 5.81,
            ic_november: 5.437,
            ic_december: 5.043,
            ic_yearly: 5.316,
          },
          {
            ic_uuid: "abe54ed8-b399-459a-b70a-248c44ca0b8f",
            ic_lat: -143245,
            ic_lon: -394654,
            ic_city: "Gongogi",
            ic_states: "BAHIA",
            ic_january: 5.905,
            ic_february: 5.868,
            ic_march: 5.482,
            ic_april: 4.538,
            ic_may: 3.862,
            ic_june: 3.514,
            ic_july: 3.772,
            ic_august: 4.25,
            ic_september: 4.957,
            ic_october: 5.146,
            ic_november: 5.275,
            ic_december: 6.001,
            ic_yearly: 4.881,
          },
          {
            ic_uuid: "e88ba1ea-f5d1-46a0-b41f-3d38e1c0d186",
            ic_lat: -188201,
            ic_lon: -424773,
            ic_city: "Gonzaga",
            ic_states: "MINAS GERAIS",
            ic_january: 5.582,
            ic_february: 5.803,
            ic_march: 4.863,
            ic_april: 4.308,
            ic_may: 3.692,
            ic_june: 3.547,
            ic_july: 3.665,
            ic_august: 4.368,
            ic_september: 4.849,
            ic_october: 4.996,
            ic_november: 4.568,
            ic_december: 5.405,
            ic_yearly: 4.637,
          },
          {
            ic_uuid: "5324dc4e-2a7c-44b6-ae8e-a57fdccafdb6",
            ic_lat: -184524,
            ic_lon: -437427,
            ic_city: "Gouveia",
            ic_states: "MINAS GERAIS",
            ic_january: 5.813,
            ic_february: 6.1,
            ic_march: 5.105,
            ic_april: 4.746,
            ic_may: 4.355,
            ic_june: 4.169,
            ic_july: 4.475,
            ic_august: 5.248,
            ic_september: 5.651,
            ic_october: 5.634,
            ic_november: 5.084,
            ic_december: 5.626,
            ic_yearly: 5.167,
          },
          {
            ic_uuid: "79757c35-b8c9-4e66-bd57-957839b2b54e",
            ic_lat: -18441,
            ic_lon: -50136,
            ic_city: "Gouvelândia",
            ic_states: "GOIÁS",
            ic_january: 5.745,
            ic_february: 5.686,
            ic_march: 5.295,
            ic_april: 5.084,
            ic_may: 4.606,
            ic_june: 4.339,
            ic_july: 4.547,
            ic_august: 5.526,
            ic_september: 5.315,
            ic_october: 5.631,
            ic_november: 5.773,
            ic_december: 5.938,
            ic_yearly: 5.29,
          },
          {
            ic_uuid: "72cdd3e6-781f-450c-8eba-b06cee640aef",
            ic_lat: -50212,
            ic_lon: -442758,
            ic_city: "Governador Archer",
            ic_states: "MARANHÃO",
            ic_january: 4.797,
            ic_february: 4.929,
            ic_march: 4.999,
            ic_april: 4.884,
            ic_may: 4.956,
            ic_june: 5.114,
            ic_july: 5.4,
            ic_august: 6.025,
            ic_september: 6.285,
            ic_october: 5.831,
            ic_november: 5.48,
            ic_december: 5.067,
            ic_yearly: 5.314,
          },
          {
            ic_uuid: "ac9195c4-abdf-42d6-b605-380a01b569e5",
            ic_lat: -273176,
            ic_lon: -48558,
            ic_city: "Governador Celso Ramos",
            ic_states: "SANTA CATARINA",
            ic_january: 5.872,
            ic_february: 5.531,
            ic_march: 4.744,
            ic_april: 3.785,
            ic_may: 3.071,
            ic_june: 2.558,
            ic_july: 2.707,
            ic_august: 3.372,
            ic_september: 3.593,
            ic_october: 4.303,
            ic_november: 5.534,
            ic_december: 5.902,
            ic_yearly: 4.248,
          },
          {
            ic_uuid: "1c869d7f-f168-43ae-8257-6e6e24b2147a",
            ic_lat: -54493,
            ic_lon: -375186,
            ic_city: "Governador Dix-Sept Rosado",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.789,
            ic_february: 5.828,
            ic_march: 5.903,
            ic_april: 5.651,
            ic_may: 5.339,
            ic_june: 5.039,
            ic_july: 5.332,
            ic_august: 5.997,
            ic_september: 6.364,
            ic_october: 6.519,
            ic_november: 6.501,
            ic_december: 5.982,
            ic_yearly: 5.854,
          },
          {
            ic_uuid: "f00ff043-0c33-4dee-9ea3-ffa52d316b4c",
            ic_lat: -57501,
            ic_lon: -47365,
            ic_city: "Governador Edison Lobão",
            ic_states: "MARANHÃO",
            ic_january: 4.627,
            ic_february: 4.676,
            ic_march: 4.76,
            ic_april: 4.809,
            ic_may: 4.846,
            ic_june: 5.141,
            ic_july: 5.321,
            ic_august: 5.748,
            ic_september: 5.613,
            ic_october: 5.198,
            ic_november: 4.908,
            ic_december: 4.776,
            ic_yearly: 5.035,
          },
          {
            ic_uuid: "63f33cb0-17d8-47c7-86e5-b6af5ba7ad2f",
            ic_lat: -53194,
            ic_lon: -442473,
            ic_city: "Governador Eugênio Barros",
            ic_states: "MARANHÃO",
            ic_january: 4.712,
            ic_february: 4.875,
            ic_march: 4.948,
            ic_april: 4.904,
            ic_may: 4.928,
            ic_june: 5.186,
            ic_july: 5.505,
            ic_august: 6.074,
            ic_september: 6.347,
            ic_october: 5.826,
            ic_november: 5.408,
            ic_december: 5.045,
            ic_yearly: 5.313,
          },
          {
            ic_uuid: "2fa16d80-2af3-4e56-8609-89803f89ec4e",
            ic_lat: -106104,
            ic_lon: -627375,
            ic_city: "Governador Jorge Teixeira",
            ic_states: "RONDÔNIA",
            ic_january: 4.374,
            ic_february: 4.297,
            ic_march: 4.399,
            ic_april: 4.516,
            ic_may: 4.007,
            ic_june: 4.343,
            ic_july: 4.464,
            ic_august: 4.886,
            ic_september: 4.935,
            ic_october: 5.079,
            ic_november: 4.847,
            ic_december: 4.676,
            ic_yearly: 4.569,
          },
          {
            ic_uuid: "994c7df2-6e27-418a-86a7-0573c0c9a404",
            ic_lat: -192523,
            ic_lon: -404613,
            ic_city: "Governador Lindenberg",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.898,
            ic_february: 6.004,
            ic_march: 5.175,
            ic_april: 4.386,
            ic_may: 3.776,
            ic_june: 3.502,
            ic_july: 3.583,
            ic_august: 4.125,
            ic_september: 4.612,
            ic_october: 4.752,
            ic_november: 4.764,
            ic_december: 5.633,
            ic_yearly: 4.684,
          },
          {
            ic_uuid: "7b6284b5-4dbb-4176-a7f3-38bd8201228e",
            ic_lat: -54723,
            ic_lon: -440702,
            ic_city: "Governador Luiz Rocha",
            ic_states: "MARANHÃO",
            ic_january: 4.752,
            ic_february: 4.949,
            ic_march: 5.023,
            ic_april: 4.983,
            ic_may: 5.033,
            ic_june: 5.219,
            ic_july: 5.569,
            ic_august: 6.191,
            ic_september: 6.433,
            ic_october: 5.911,
            ic_november: 5.484,
            ic_december: 5.139,
            ic_yearly: 5.391,
          },
          {
            ic_uuid: "e516bf2a-58da-4782-a6aa-79b49dd61d3f",
            ic_lat: -125999,
            ic_lon: -390415,
            ic_city: "Governador Mangabeira",
            ic_states: "BAHIA",
            ic_january: 6.077,
            ic_february: 5.97,
            ic_march: 5.792,
            ic_april: 4.651,
            ic_may: 4.033,
            ic_june: 3.663,
            ic_july: 3.928,
            ic_august: 4.501,
            ic_september: 5.343,
            ic_october: 5.598,
            ic_november: 5.79,
            ic_december: 6.112,
            ic_yearly: 5.122,
          },
          {
            ic_uuid: "75bfacbc-d544-4f95-8c97-2e611c2f10b5",
            ic_lat: -34328,
            ic_lon: -456623,
            ic_city: "Governador Newton Bello",
            ic_states: "MARANHÃO",
            ic_january: 4.544,
            ic_february: 4.782,
            ic_march: 4.813,
            ic_april: 4.813,
            ic_may: 4.717,
            ic_june: 4.849,
            ic_july: 4.981,
            ic_august: 5.373,
            ic_september: 5.634,
            ic_october: 5.351,
            ic_november: 5.036,
            ic_december: 4.815,
            ic_yearly: 4.975,
          },
          {
            ic_uuid: "c36b0d77-4fad-4e1c-85a3-982c9e507996",
            ic_lat: -2129,
            ic_lon: -458913,
            ic_city: "Governador Nunes Freire",
            ic_states: "MARANHÃO",
            ic_january: 4.517,
            ic_february: 4.44,
            ic_march: 4.379,
            ic_april: 4.474,
            ic_may: 4.505,
            ic_june: 4.826,
            ic_july: 4.95,
            ic_august: 5.432,
            ic_september: 5.644,
            ic_october: 5.353,
            ic_november: 5.144,
            ic_december: 4.685,
            ic_yearly: 4.862,
          },
          {
            ic_uuid: "ffbc5b0b-ee18-46ba-b592-90866a46db7a",
            ic_lat: -188549,
            ic_lon: -419559,
            ic_city: "Governador Valadares",
            ic_states: "MINAS GERAIS",
            ic_january: 5.984,
            ic_february: 6.122,
            ic_march: 5.26,
            ic_april: 4.591,
            ic_may: 3.875,
            ic_june: 3.637,
            ic_july: 3.751,
            ic_august: 4.506,
            ic_september: 4.966,
            ic_october: 5.192,
            ic_november: 4.933,
            ic_december: 5.799,
            ic_yearly: 4.885,
          },
          {
            ic_uuid: "552b1304-97c4-4186-927b-7a72958e971a",
            ic_lat: -40446,
            ic_lon: -407494,
            ic_city: "Graça",
            ic_states: "CEARÁ",
            ic_january: 4.971,
            ic_february: 5.157,
            ic_march: 5.126,
            ic_april: 4.786,
            ic_may: 4.874,
            ic_june: 4.915,
            ic_july: 5.217,
            ic_august: 5.933,
            ic_september: 6.393,
            ic_october: 6.272,
            ic_november: 5.971,
            ic_december: 5.287,
            ic_yearly: 5.408,
          },
          {
            ic_uuid: "3be9b26d-ebd5-4903-ac0b-f276eb492e23",
            ic_lat: -54059,
            ic_lon: -443362,
            ic_city: "Graça Aranha",
            ic_states: "MARANHÃO",
            ic_january: 4.696,
            ic_february: 4.836,
            ic_march: 4.9,
            ic_april: 4.909,
            ic_may: 4.986,
            ic_june: 5.196,
            ic_july: 5.489,
            ic_august: 6.136,
            ic_september: 6.342,
            ic_october: 5.786,
            ic_november: 5.392,
            ic_december: 5.058,
            ic_yearly: 5.311,
          },
          {
            ic_uuid: "e688b2b5-20e9-416e-8391-0f5044559b02",
            ic_lat: -1023,
            ic_lon: -37197,
            ic_city: "Graccho Cardoso",
            ic_states: "SERGIPE",
            ic_january: 6.017,
            ic_february: 5.822,
            ic_march: 5.789,
            ic_april: 5.052,
            ic_may: 4.226,
            ic_june: 3.994,
            ic_july: 4.123,
            ic_august: 4.649,
            ic_september: 5.451,
            ic_october: 5.724,
            ic_november: 6.152,
            ic_december: 6.177,
            ic_yearly: 5.265,
          },
          {
            ic_uuid: "9de2f445-662a-49ae-940a-9a0a511feb61",
            ic_lat: -58202,
            ic_lon: -461559,
            ic_city: "Grajaú",
            ic_states: "MARANHÃO",
            ic_january: 4.655,
            ic_february: 4.81,
            ic_march: 4.778,
            ic_april: 4.816,
            ic_may: 4.898,
            ic_june: 5.116,
            ic_july: 5.37,
            ic_august: 5.874,
            ic_september: 5.932,
            ic_october: 5.429,
            ic_november: 4.931,
            ic_december: 4.771,
            ic_yearly: 5.115,
          },
          {
            ic_uuid: "fc1dca01-e972-4e2f-b533-87ff0b552726",
            ic_lat: -293738,
            ic_lon: -508766,
            ic_city: "Gramado",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 5.973,
            ic_february: 5.553,
            ic_march: 4.607,
            ic_april: 3.756,
            ic_may: 2.798,
            ic_june: 2.38,
            ic_july: 2.609,
            ic_august: 3.318,
            ic_september: 3.54,
            ic_october: 4.615,
            ic_november: 5.977,
            ic_december: 6.358,
            ic_yearly: 4.29,
          },
          {
            ic_uuid: "786e6f5e-ee55-41ee-9137-da05ff69e129",
            ic_lat: -274434,
            ic_lon: -529153,
            ic_city: "Gramado dos Loureiros",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.249,
            ic_february: 5.823,
            ic_march: 5.12,
            ic_april: 4.134,
            ic_may: 3.11,
            ic_june: 2.626,
            ic_july: 2.917,
            ic_august: 3.739,
            ic_september: 4.003,
            ic_october: 5.147,
            ic_november: 6.181,
            ic_december: 6.572,
            ic_yearly: 4.635,
          },
          {
            ic_uuid: "0e134967-ddad-435f-934c-3388ece45e7b",
            ic_lat: -29271,
            ic_lon: -5258,
            ic_city: "Gramado Xavier",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.165,
            ic_february: 5.704,
            ic_march: 4.872,
            ic_april: 3.859,
            ic_may: 2.847,
            ic_june: 2.397,
            ic_july: 2.668,
            ic_august: 3.411,
            ic_september: 3.64,
            ic_october: 4.794,
            ic_november: 6.126,
            ic_december: 6.523,
            ic_yearly: 4.417,
          },
          {
            ic_uuid: "93cb3176-e09f-4223-aca3-586743b09aa6",
            ic_lat: -241471,
            ic_lon: -515098,
            ic_city: "Grandes Rios",
            ic_states: "PARANÁ",
            ic_january: 6.031,
            ic_february: 5.801,
            ic_march: 5.302,
            ic_april: 4.6,
            ic_may: 3.542,
            ic_june: 3.167,
            ic_july: 3.397,
            ic_august: 4.39,
            ic_september: 4.57,
            ic_october: 5.292,
            ic_november: 6.136,
            ic_december: 6.43,
            ic_yearly: 4.888,
          },
          {
            ic_uuid: "ad8c1c7f-1cfe-417a-92c3-c029fbec3189",
            ic_lat: -77148,
            ic_lon: -396152,
            ic_city: "Granito",
            ic_states: "PERNAMBUCO",
            ic_january: 5.974,
            ic_february: 5.797,
            ic_march: 5.809,
            ic_april: 5.343,
            ic_may: 4.772,
            ic_june: 4.395,
            ic_july: 4.701,
            ic_august: 5.596,
            ic_september: 6.385,
            ic_october: 6.488,
            ic_november: 6.536,
            ic_december: 6.29,
            ic_yearly: 5.674,
          },
          {
            ic_uuid: "c80327b9-f04a-4d72-b4f0-2fd60a7e23f8",
            ic_lat: -31231,
            ic_lon: -40827,
            ic_city: "Granja",
            ic_states: "CEARÁ",
            ic_january: 5.044,
            ic_february: 4.986,
            ic_march: 4.921,
            ic_april: 4.757,
            ic_may: 5.08,
            ic_june: 5.167,
            ic_july: 5.3,
            ic_august: 5.948,
            ic_september: 6.434,
            ic_october: 6.416,
            ic_november: 6.054,
            ic_december: 5.354,
            ic_yearly: 5.455,
          },
          {
            ic_uuid: "d0415341-151f-49d7-90ca-8b5266a42a38",
            ic_lat: -68886,
            ic_lon: -392182,
            ic_city: "Granjeiro",
            ic_states: "CEARÁ",
            ic_january: 5.869,
            ic_february: 5.86,
            ic_march: 5.82,
            ic_april: 5.501,
            ic_may: 5.184,
            ic_june: 5.113,
            ic_july: 5.377,
            ic_august: 6.099,
            ic_september: 6.534,
            ic_october: 6.554,
            ic_november: 6.568,
            ic_december: 6.144,
            ic_yearly: 5.885,
          },
          {
            ic_uuid: "256412e5-67a0-4fbb-84a1-bbf2f0621ceb",
            ic_lat: -165667,
            ic_lon: -428927,
            ic_city: "Grão Mogol",
            ic_states: "MINAS GERAIS",
            ic_january: 6.051,
            ic_february: 6.34,
            ic_march: 5.329,
            ic_april: 4.729,
            ic_may: 4.174,
            ic_june: 3.93,
            ic_july: 4.199,
            ic_august: 5.056,
            ic_september: 5.554,
            ic_october: 5.691,
            ic_november: 5.179,
            ic_december: 5.924,
            ic_yearly: 5.18,
          },
          {
            ic_uuid: "c15e63f9-da95-46ef-9781-8607658d59a7",
            ic_lat: -281814,
            ic_lon: -492256,
            ic_city: "Grão Pará",
            ic_states: "SANTA CATARINA",
            ic_january: 5.467,
            ic_february: 5.049,
            ic_march: 4.424,
            ic_april: 3.739,
            ic_may: 2.935,
            ic_june: 2.543,
            ic_july: 2.734,
            ic_august: 3.468,
            ic_september: 3.552,
            ic_october: 4.2,
            ic_november: 5.453,
            ic_december: 5.674,
            ic_yearly: 4.103,
          },
          {
            ic_uuid: "5e5dc40a-f729-4d23-996c-9e44de57ac49",
            ic_lat: -82116,
            ic_lon: -355678,
            ic_city: "Gravatá",
            ic_states: "PERNAMBUCO",
            ic_january: 5.752,
            ic_february: 5.707,
            ic_march: 5.708,
            ic_april: 5.089,
            ic_may: 4.435,
            ic_june: 4.126,
            ic_july: 4.178,
            ic_august: 4.934,
            ic_september: 5.505,
            ic_october: 5.711,
            ic_november: 5.922,
            ic_december: 5.838,
            ic_yearly: 5.242,
          },
          {
            ic_uuid: "e34b3b0f-6a65-49c1-8b34-3a7437998d43",
            ic_lat: -299362,
            ic_lon: -510167,
            ic_city: "Gravataí",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.341,
            ic_february: 5.764,
            ic_march: 4.799,
            ic_april: 3.791,
            ic_may: 2.788,
            ic_june: 2.319,
            ic_july: 2.509,
            ic_august: 3.197,
            ic_september: 3.572,
            ic_october: 4.713,
            ic_november: 6.193,
            ic_december: 6.624,
            ic_yearly: 4.384,
          },
          {
            ic_uuid: "f976b96d-4063-4815-9b97-9caf9e694671",
            ic_lat: -283212,
            ic_lon: -490431,
            ic_city: "Gravatal",
            ic_states: "SANTA CATARINA",
            ic_january: 5.764,
            ic_february: 5.301,
            ic_march: 4.534,
            ic_april: 3.808,
            ic_may: 2.991,
            ic_june: 2.541,
            ic_july: 2.75,
            ic_august: 3.502,
            ic_september: 3.618,
            ic_october: 4.425,
            ic_november: 5.709,
            ic_december: 6.041,
            ic_yearly: 4.249,
          },
          {
            ic_uuid: "823ea0c5-b59c-4fe5-b406-7b2dfab0d1f0",
            ic_lat: -39183,
            ic_lon: -403856,
            ic_city: "Groaíras",
            ic_states: "CEARÁ",
            ic_january: 5.151,
            ic_february: 5.331,
            ic_march: 5.261,
            ic_april: 4.892,
            ic_may: 4.971,
            ic_june: 5.008,
            ic_july: 5.255,
            ic_august: 5.991,
            ic_september: 6.481,
            ic_october: 6.376,
            ic_november: 6.147,
            ic_december: 5.502,
            ic_yearly: 5.53,
          },
          {
            ic_uuid: "243e55b4-341e-4215-91a9-a452415a2337",
            ic_lat: -49811,
            ic_lon: -371624,
            ic_city: "Grossos",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.991,
            ic_february: 5.874,
            ic_march: 5.831,
            ic_april: 5.482,
            ic_may: 5.268,
            ic_june: 4.912,
            ic_july: 5.16,
            ic_august: 5.717,
            ic_september: 6.188,
            ic_october: 6.088,
            ic_november: 6.254,
            ic_december: 6.187,
            ic_yearly: 5.746,
          },
          {
            ic_uuid: "c67b2dd0-4fcd-4ef5-85d1-590d907be8fd",
            ic_lat: -184968,
            ic_lon: -477226,
            ic_city: "Grupiara",
            ic_states: "MINAS GERAIS",
            ic_january: 5.76,
            ic_february: 6.104,
            ic_march: 5.351,
            ic_april: 5.195,
            ic_may: 4.682,
            ic_june: 4.378,
            ic_july: 4.602,
            ic_august: 5.52,
            ic_september: 5.562,
            ic_october: 5.73,
            ic_november: 5.66,
            ic_december: 5.932,
            ic_yearly: 5.373,
          },
          {
            ic_uuid: "1266f5c9-8f12-41ac-8604-c5e223088358",
            ic_lat: -285426,
            ic_lon: -516953,
            ic_city: "Guabiju",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.068,
            ic_february: 5.672,
            ic_march: 4.841,
            ic_april: 3.947,
            ic_may: 2.94,
            ic_june: 2.514,
            ic_july: 2.806,
            ic_august: 3.516,
            ic_september: 3.767,
            ic_october: 4.888,
            ic_november: 6.127,
            ic_december: 6.427,
            ic_yearly: 4.46,
          },
          {
            ic_uuid: "78f8766b-d73d-4bef-bd41-f2f67786f473",
            ic_lat: -270813,
            ic_lon: -489809,
            ic_city: "Guabiruba",
            ic_states: "SANTA CATARINA",
            ic_january: 5.446,
            ic_february: 5.23,
            ic_march: 4.537,
            ic_april: 3.611,
            ic_may: 2.996,
            ic_june: 2.469,
            ic_july: 2.628,
            ic_august: 3.241,
            ic_september: 3.36,
            ic_october: 3.839,
            ic_november: 4.988,
            ic_december: 5.498,
            ic_yearly: 3.987,
          },
          {
            ic_uuid: "d7e3ccea-7b72-4376-b24e-e721000b6379",
            ic_lat: -207673,
            ic_lon: -416738,
            ic_city: "Guaçuí",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.996,
            ic_february: 6.185,
            ic_march: 4.969,
            ic_april: 4.383,
            ic_may: 3.8,
            ic_june: 3.62,
            ic_july: 3.748,
            ic_august: 4.508,
            ic_september: 4.952,
            ic_october: 5.054,
            ic_november: 4.832,
            ic_december: 5.549,
            ic_yearly: 4.8,
          },
          {
            ic_uuid: "8ed32a17-46e1-4440-9c41-bf513c03858d",
            ic_lat: -67833,
            ic_lon: -435598,
            ic_city: "Guadalupe",
            ic_states: "PIAUÍ",
            ic_january: 5.08,
            ic_february: 5.186,
            ic_march: 5.152,
            ic_april: 5.189,
            ic_may: 5.169,
            ic_june: 5.376,
            ic_july: 5.649,
            ic_august: 6.131,
            ic_september: 6.563,
            ic_october: 6.269,
            ic_november: 5.879,
            ic_december: 5.436,
            ic_yearly: 5.59,
          },
          {
            ic_uuid: "d05f80ba-ed7c-48c1-af02-3972cce97c62",
            ic_lat: -301091,
            ic_lon: -513238,
            ic_city: "Guaíba",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.522,
            ic_february: 5.869,
            ic_march: 4.882,
            ic_april: 3.832,
            ic_may: 2.789,
            ic_june: 2.32,
            ic_july: 2.538,
            ic_august: 3.216,
            ic_september: 3.629,
            ic_october: 4.866,
            ic_november: 6.266,
            ic_december: 6.755,
            ic_yearly: 4.457,
          },
          {
            ic_uuid: "f06fc97c-048a-4ce0-96e1-79023ac44485",
            ic_lat: -21622,
            ic_lon: -497989,
            ic_city: "Guaiçara",
            ic_states: "SÃO PAULO",
            ic_january: 5.886,
            ic_february: 6.01,
            ic_march: 5.386,
            ic_april: 4.858,
            ic_may: 3.966,
            ic_june: 3.687,
            ic_july: 3.925,
            ic_august: 4.788,
            ic_september: 4.959,
            ic_october: 5.647,
            ic_november: 6.004,
            ic_december: 6.26,
            ic_yearly: 5.115,
          },
          {
            ic_uuid: "c9ac9acb-c2af-41ce-ad33-47dd5010a6d3",
            ic_lat: -219095,
            ic_lon: -49899,
            ic_city: "Guaimbê",
            ic_states: "SÃO PAULO",
            ic_january: 5.777,
            ic_february: 5.89,
            ic_march: 5.284,
            ic_april: 4.788,
            ic_may: 3.912,
            ic_june: 3.623,
            ic_july: 3.863,
            ic_august: 4.779,
            ic_september: 4.881,
            ic_october: 5.605,
            ic_november: 5.943,
            ic_december: 6.244,
            ic_yearly: 5.049,
          },
          {
            ic_uuid: "bec67201-c0aa-4184-9efa-0a4a04d32366",
            ic_lat: -240855,
            ic_lon: -542578,
            ic_city: "Guaíra",
            ic_states: "PARANÁ",
            ic_january: 6.291,
            ic_february: 5.875,
            ic_march: 5.353,
            ic_april: 4.38,
            ic_may: 3.427,
            ic_june: 2.985,
            ic_july: 3.214,
            ic_august: 4.2,
            ic_september: 4.463,
            ic_october: 5.325,
            ic_november: 6.123,
            ic_december: 6.525,
            ic_yearly: 4.847,
          },
          {
            ic_uuid: "5e48d7c4-cff1-4907-999a-c5d2076ffe99",
            ic_lat: -203201,
            ic_lon: -483124,
            ic_city: "Guaíra",
            ic_states: "SÃO PAULO",
            ic_january: 5.882,
            ic_february: 6.07,
            ic_march: 5.22,
            ic_april: 4.972,
            ic_may: 4.322,
            ic_june: 4.027,
            ic_july: 4.277,
            ic_august: 5.11,
            ic_september: 5.154,
            ic_october: 5.598,
            ic_november: 5.793,
            ic_december: 6.205,
            ic_yearly: 5.219,
          },
          {
            ic_uuid: "8f6c976e-cf23-42a1-a684-8ab4681dae92",
            ic_lat: -22934,
            ic_lon: -52686,
            ic_city: "Guairaçá",
            ic_states: "PARANÁ",
            ic_january: 6.03,
            ic_february: 5.966,
            ic_march: 5.391,
            ic_april: 4.645,
            ic_may: 3.685,
            ic_june: 3.359,
            ic_july: 3.584,
            ic_august: 4.531,
            ic_september: 4.756,
            ic_october: 5.452,
            ic_november: 6.178,
            ic_december: 6.457,
            ic_yearly: 5.003,
          },
          {
            ic_uuid: "f8e3994d-79bf-459d-8402-1dd0c4f44ebf",
            ic_lat: -4041,
            ic_lon: -386407,
            ic_city: "Guaiúba",
            ic_states: "CEARÁ",
            ic_january: 5.314,
            ic_february: 5.326,
            ic_march: 5.165,
            ic_april: 4.605,
            ic_may: 4.925,
            ic_june: 4.915,
            ic_july: 5.302,
            ic_august: 5.972,
            ic_september: 6.152,
            ic_october: 6.034,
            ic_november: 6.015,
            ic_december: 5.474,
            ic_yearly: 5.433,
          },
          {
            ic_uuid: "31ce5210-54b3-42f5-a7a7-1f4642c3bfe3",
            ic_lat: -75384,
            ic_lon: -725912,
            ic_city: "Guajará",
            ic_states: "AMAZONAS",
            ic_january: 4.676,
            ic_february: 4.908,
            ic_march: 4.233,
            ic_april: 4.446,
            ic_may: 4.283,
            ic_june: 4.202,
            ic_july: 4.354,
            ic_august: 5.068,
            ic_september: 5.138,
            ic_october: 5.03,
            ic_november: 4.949,
            ic_december: 4.666,
            ic_yearly: 4.663,
          },
          {
            ic_uuid: "9f1e5ed9-d16f-4c76-a37a-c93ec9196e8b",
            ic_lat: -107893,
            ic_lon: -6533,
            ic_city: "Guajará-Mirim",
            ic_states: "RONDÔNIA",
            ic_january: 4.513,
            ic_february: 4.47,
            ic_march: 4.356,
            ic_april: 4.433,
            ic_may: 3.96,
            ic_june: 4.141,
            ic_july: 4.378,
            ic_august: 4.908,
            ic_september: 4.972,
            ic_october: 5.134,
            ic_november: 4.912,
            ic_december: 4.834,
            ic_yearly: 4.585,
          },
          {
            ic_uuid: "29df874f-8551-4d6f-9009-4130d02e8ece",
            ic_lat: -145471,
            ic_lon: -419384,
            ic_city: "Guajeru",
            ic_states: "BAHIA",
            ic_january: 6.329,
            ic_february: 6.326,
            ic_march: 5.722,
            ic_april: 4.941,
            ic_may: 4.358,
            ic_june: 3.988,
            ic_july: 4.306,
            ic_august: 5.017,
            ic_september: 5.773,
            ic_october: 6.083,
            ic_november: 5.798,
            ic_december: 6.445,
            ic_yearly: 5.424,
          },
          {
            ic_uuid: "ec96859e-f688-4ee2-9ff4-9ea3b0635f2f",
            ic_lat: -51066,
            ic_lon: -363225,
            ic_city: "Guamaré",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.934,
            ic_february: 5.84,
            ic_march: 5.806,
            ic_april: 5.421,
            ic_may: 5.133,
            ic_june: 4.674,
            ic_july: 4.844,
            ic_august: 5.479,
            ic_september: 6.065,
            ic_october: 6.052,
            ic_november: 6.188,
            ic_december: 6.005,
            ic_yearly: 5.62,
          },
          {
            ic_uuid: "abfd0100-4c12-43a0-93f5-e02b4aa0cfb6",
            ic_lat: -251916,
            ic_lon: -508025,
            ic_city: "Guamiranga",
            ic_states: "PARANÁ",
            ic_january: 5.702,
            ic_february: 5.457,
            ic_march: 4.84,
            ic_april: 4.034,
            ic_may: 3.204,
            ic_june: 2.804,
            ic_july: 3.061,
            ic_august: 4.053,
            ic_september: 4.237,
            ic_october: 4.857,
            ic_november: 5.745,
            ic_december: 5.952,
            ic_yearly: 4.495,
          },
          {
            ic_uuid: "25a82627-391b-4f0e-8a65-9266561679a3",
            ic_lat: -142235,
            ic_lon: -427803,
            ic_city: "Guanambi",
            ic_states: "BAHIA",
            ic_january: 6.671,
            ic_february: 6.71,
            ic_march: 6.099,
            ic_april: 5.506,
            ic_may: 4.915,
            ic_june: 4.667,
            ic_july: 4.926,
            ic_august: 5.561,
            ic_september: 5.957,
            ic_october: 6.535,
            ic_november: 6.03,
            ic_december: 6.616,
            ic_yearly: 5.849,
          },
          {
            ic_uuid: "6e545087-0622-4289-a846-784de0ffea99",
            ic_lat: -187718,
            ic_lon: -429316,
            ic_city: "Guanhães",
            ic_states: "MINAS GERAIS",
            ic_january: 5.691,
            ic_february: 5.939,
            ic_march: 4.897,
            ic_april: 4.364,
            ic_may: 3.837,
            ic_june: 3.678,
            ic_july: 3.833,
            ic_august: 4.525,
            ic_september: 4.984,
            ic_october: 5.155,
            ic_november: 4.806,
            ic_december: 5.508,
            ic_yearly: 4.768,
          },
          {
            ic_uuid: "1ee04aef-230d-46b0-b066-0cf43f5603c9",
            ic_lat: -207636,
            ic_lon: -459156,
            ic_city: "Guapé",
            ic_states: "MINAS GERAIS",
            ic_january: 5.607,
            ic_february: 5.936,
            ic_march: 4.996,
            ic_april: 4.866,
            ic_may: 4.216,
            ic_june: 3.984,
            ic_july: 4.224,
            ic_august: 5.087,
            ic_september: 5.182,
            ic_october: 5.547,
            ic_november: 5.375,
            ic_december: 5.704,
            ic_yearly: 5.06,
          },
          {
            ic_uuid: "e1d45610-12b8-4f3e-939e-35c4f9a99a22",
            ic_lat: -20795,
            ic_lon: -492199,
            ic_city: "Guapiaçu",
            ic_states: "SÃO PAULO",
            ic_january: 5.783,
            ic_february: 5.858,
            ic_march: 5.218,
            ic_april: 4.851,
            ic_may: 4.117,
            ic_june: 3.921,
            ic_july: 4.134,
            ic_august: 5.07,
            ic_september: 5.113,
            ic_october: 5.592,
            ic_november: 5.916,
            ic_december: 6.158,
            ic_yearly: 5.144,
          },
          {
            ic_uuid: "013484b6-24b5-43d3-8e8c-60d6e7233de7",
            ic_lat: -241897,
            ic_lon: -4853,
            ic_city: "Guapiara",
            ic_states: "SÃO PAULO",
            ic_january: 5.337,
            ic_february: 5.388,
            ic_march: 4.655,
            ic_april: 4.066,
            ic_may: 3.286,
            ic_june: 2.983,
            ic_july: 3.1,
            ic_august: 4.055,
            ic_september: 4.078,
            ic_october: 4.548,
            ic_november: 5.214,
            ic_december: 5.771,
            ic_yearly: 4.374,
          },
          {
            ic_uuid: "6d543714-0969-4143-8086-1c4d5a00d130",
            ic_lat: -225352,
            ic_lon: -429899,
            ic_city: "Guapimirim",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.247,
            ic_february: 5.696,
            ic_march: 4.526,
            ic_april: 4.038,
            ic_may: 3.448,
            ic_june: 3.279,
            ic_july: 3.318,
            ic_august: 4.081,
            ic_september: 4.302,
            ic_october: 4.509,
            ic_november: 4.355,
            ic_december: 5.023,
            ic_yearly: 4.319,
          },
          {
            ic_uuid: "93f6b309-1545-4cee-86c5-d2bec7fca70c",
            ic_lat: -235159,
            ic_lon: -500403,
            ic_city: "Guapirama",
            ic_states: "PARANÁ",
            ic_january: 5.801,
            ic_february: 5.788,
            ic_march: 5.184,
            ic_april: 4.592,
            ic_may: 3.629,
            ic_june: 3.263,
            ic_july: 3.503,
            ic_august: 4.51,
            ic_september: 4.647,
            ic_october: 5.313,
            ic_november: 5.984,
            ic_december: 6.348,
            ic_yearly: 4.88,
          },
          {
            ic_uuid: "7634aaf0-adbc-4c75-a8e4-4cc142a3bb9c",
            ic_lat: -168301,
            ic_lon: -495349,
            ic_city: "Guapó",
            ic_states: "GOIÁS",
            ic_january: 5.582,
            ic_february: 5.642,
            ic_march: 5.298,
            ic_april: 5.171,
            ic_may: 4.794,
            ic_june: 4.544,
            ic_july: 4.738,
            ic_august: 5.554,
            ic_september: 5.479,
            ic_october: 5.589,
            ic_november: 5.462,
            ic_december: 5.587,
            ic_yearly: 5.287,
          },
          {
            ic_uuid: "7a852bed-e760-4b52-8955-afc167c0db3d",
            ic_lat: -288404,
            ic_lon: -5189,
            ic_city: "Guaporé",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.088,
            ic_february: 5.751,
            ic_march: 4.824,
            ic_april: 3.846,
            ic_may: 2.876,
            ic_june: 2.423,
            ic_july: 2.715,
            ic_august: 3.443,
            ic_september: 3.731,
            ic_october: 4.784,
            ic_november: 6.116,
            ic_december: 6.502,
            ic_yearly: 4.425,
          },
          {
            ic_uuid: "fc31d135-cd07-4969-bddb-4a3d263a8d1a",
            ic_lat: -233407,
            ic_lon: -52779,
            ic_city: "Guaporema",
            ic_states: "PARANÁ",
            ic_january: 6.02,
            ic_february: 5.873,
            ic_march: 5.4,
            ic_april: 4.569,
            ic_may: 3.629,
            ic_june: 3.271,
            ic_july: 3.477,
            ic_august: 4.453,
            ic_september: 4.664,
            ic_october: 5.424,
            ic_november: 6.151,
            ic_december: 6.438,
            ic_yearly: 4.948,
          },
          {
            ic_uuid: "cfdc93a6-6bfe-485b-8e36-f19c2a95cacc",
            ic_lat: -204306,
            ic_lon: -47824,
            ic_city: "Guará",
            ic_states: "SÃO PAULO",
            ic_january: 5.778,
            ic_february: 6.039,
            ic_march: 5.174,
            ic_april: 4.942,
            ic_may: 4.296,
            ic_june: 4.033,
            ic_july: 4.256,
            ic_august: 5.188,
            ic_september: 5.146,
            ic_october: 5.564,
            ic_november: 5.751,
            ic_december: 6.06,
            ic_yearly: 5.186,
          },
          {
            ic_uuid: "094b5842-1c33-4478-8cb3-8ac44cd3e069",
            ic_lat: -68511,
            ic_lon: -354853,
            ic_city: "Guarabira",
            ic_states: "PARAÍBA",
            ic_january: 5.509,
            ic_february: 5.641,
            ic_march: 5.698,
            ic_april: 5.221,
            ic_may: 4.592,
            ic_june: 4.185,
            ic_july: 4.258,
            ic_august: 5.015,
            ic_september: 5.424,
            ic_october: 5.701,
            ic_november: 5.906,
            ic_december: 5.745,
            ic_yearly: 5.241,
          },
          {
            ic_uuid: "c2e31899-a347-4940-9b27-eef9c05509f6",
            ic_lat: -210333,
            ic_lon: -512092,
            ic_city: "Guaraçaí",
            ic_states: "SÃO PAULO",
            ic_january: 5.883,
            ic_february: 5.927,
            ic_march: 5.428,
            ic_april: 4.923,
            ic_may: 4.099,
            ic_june: 3.8,
            ic_july: 3.966,
            ic_august: 4.921,
            ic_september: 4.977,
            ic_october: 5.64,
            ic_november: 6.064,
            ic_december: 6.288,
            ic_yearly: 5.16,
          },
          {
            ic_uuid: "19c5a827-336e-49b3-9187-c6333c051f65",
            ic_lat: -229729,
            ic_lon: -516499,
            ic_city: "Guaraci",
            ic_states: "PARANÁ",
            ic_january: 5.978,
            ic_february: 5.925,
            ic_march: 5.446,
            ic_april: 4.725,
            ic_may: 3.722,
            ic_june: 3.434,
            ic_july: 3.623,
            ic_august: 4.562,
            ic_september: 4.774,
            ic_october: 5.499,
            ic_november: 6.192,
            ic_december: 6.436,
            ic_yearly: 5.026,
          },
          {
            ic_uuid: "efd0f1f5-23d1-4042-b2ec-5159e9127cf9",
            ic_lat: -20499,
            ic_lon: -489449,
            ic_city: "Guaraci",
            ic_states: "SÃO PAULO",
            ic_january: 5.821,
            ic_february: 6.119,
            ic_march: 5.269,
            ic_april: 4.93,
            ic_may: 4.225,
            ic_june: 3.944,
            ic_july: 4.18,
            ic_august: 5.107,
            ic_september: 5.109,
            ic_october: 5.626,
            ic_november: 5.876,
            ic_december: 6.2,
            ic_yearly: 5.2,
          },
          {
            ic_uuid: "84f9ab8c-2176-4736-a7e9-5c643f258fb8",
            ic_lat: -20572,
            ic_lon: -430098,
            ic_city: "Guaraciaba",
            ic_states: "MINAS GERAIS",
            ic_january: 5.589,
            ic_february: 5.9,
            ic_march: 4.91,
            ic_april: 4.402,
            ic_may: 3.733,
            ic_june: 3.552,
            ic_july: 3.76,
            ic_august: 4.591,
            ic_september: 4.843,
            ic_october: 5.0,
            ic_november: 4.741,
            ic_december: 5.398,
            ic_yearly: 4.702,
          },
          {
            ic_uuid: "35005ff6-f471-45d9-8db1-46c25eec7368",
            ic_lat: -266047,
            ic_lon: -535248,
            ic_city: "Guaraciaba",
            ic_states: "SANTA CATARINA",
            ic_january: 6.315,
            ic_february: 5.733,
            ic_march: 5.112,
            ic_april: 4.116,
            ic_may: 3.19,
            ic_june: 2.712,
            ic_july: 2.982,
            ic_august: 3.871,
            ic_september: 4.166,
            ic_october: 5.174,
            ic_november: 6.043,
            ic_december: 6.399,
            ic_yearly: 4.651,
          },
          {
            ic_uuid: "04606d4f-f713-4175-a9ee-de899391dbe1",
            ic_lat: -41585,
            ic_lon: -407479,
            ic_city: "Guaraciaba do Norte",
            ic_states: "CEARÁ",
            ic_january: 5.115,
            ic_february: 5.201,
            ic_march: 5.189,
            ic_april: 4.775,
            ic_may: 4.798,
            ic_june: 4.922,
            ic_july: 5.292,
            ic_august: 6.083,
            ic_september: 6.516,
            ic_october: 6.409,
            ic_november: 6.142,
            ic_december: 5.448,
            ic_yearly: 5.491,
          },
          {
            ic_uuid: "f41db690-0935-4703-812f-e303db31df9a",
            ic_lat: -17014,
            ic_lon: -43673,
            ic_city: "Guaraciama",
            ic_states: "MINAS GERAIS",
            ic_january: 6.165,
            ic_february: 6.436,
            ic_march: 5.459,
            ic_april: 5.131,
            ic_may: 4.704,
            ic_june: 4.431,
            ic_july: 4.743,
            ic_august: 5.508,
            ic_september: 5.996,
            ic_october: 5.958,
            ic_november: 5.443,
            ic_december: 6.071,
            ic_yearly: 5.504,
          },
          {
            ic_uuid: "5232c276-b93f-4d05-a38e-238f7e17923e",
            ic_lat: -88358,
            ic_lon: -485118,
            ic_city: "Guaraí",
            ic_states: "TOCANTINS",
            ic_january: 4.859,
            ic_february: 4.917,
            ic_march: 4.775,
            ic_april: 4.742,
            ic_may: 4.993,
            ic_june: 5.051,
            ic_july: 5.263,
            ic_august: 5.986,
            ic_september: 5.695,
            ic_october: 5.116,
            ic_november: 4.816,
            ic_december: 4.84,
            ic_yearly: 5.088,
          },
          {
            ic_uuid: "6a3d1df3-79b4-4678-b90d-4c5b76d669a9",
            ic_lat: -156326,
            ic_lon: -500296,
            ic_city: "Guaraíta",
            ic_states: "GOIÁS",
            ic_january: 5.268,
            ic_february: 5.509,
            ic_march: 5.159,
            ic_april: 5.155,
            ic_may: 4.934,
            ic_june: 4.656,
            ic_july: 4.762,
            ic_august: 5.671,
            ic_september: 5.457,
            ic_october: 5.526,
            ic_november: 5.147,
            ic_december: 5.333,
            ic_yearly: 5.215,
          },
          {
            ic_uuid: "8298e5a2-d13f-4bde-bd76-311141b3adc0",
            ic_lat: -42629,
            ic_lon: -389324,
            ic_city: "Guaramiranga",
            ic_states: "CEARÁ",
            ic_january: 5.232,
            ic_february: 5.154,
            ic_march: 5.112,
            ic_april: 4.594,
            ic_may: 4.845,
            ic_june: 4.717,
            ic_july: 5.254,
            ic_august: 6.09,
            ic_september: 6.408,
            ic_october: 6.261,
            ic_november: 6.117,
            ic_december: 5.479,
            ic_yearly: 5.438,
          },
          {
            ic_uuid: "b06f4bf4-792e-4652-8740-f6d75541b003",
            ic_lat: -264692,
            ic_lon: -490031,
            ic_city: "Guaramirim",
            ic_states: "SANTA CATARINA",
            ic_january: 5.26,
            ic_february: 5.048,
            ic_march: 4.429,
            ic_april: 3.627,
            ic_may: 2.985,
            ic_june: 2.482,
            ic_july: 2.586,
            ic_august: 3.279,
            ic_september: 3.32,
            ic_october: 3.844,
            ic_november: 4.921,
            ic_december: 5.3,
            ic_yearly: 3.924,
          },
          {
            ic_uuid: "6260ee3c-19f2-474a-a141-285191b7827a",
            ic_lat: -213014,
            ic_lon: -467967,
            ic_city: "Guaranésia",
            ic_states: "MINAS GERAIS",
            ic_january: 5.477,
            ic_february: 5.634,
            ic_march: 4.857,
            ic_april: 4.724,
            ic_may: 4.069,
            ic_june: 3.876,
            ic_july: 4.103,
            ic_august: 5.021,
            ic_september: 5.088,
            ic_october: 5.424,
            ic_november: 5.395,
            ic_december: 5.591,
            ic_yearly: 4.938,
          },
          {
            ic_uuid: "49ff7098-3f02-41fb-9fa8-18f76beeb75f",
            ic_lat: -213568,
            ic_lon: -430331,
            ic_city: "Guarani",
            ic_states: "MINAS GERAIS",
            ic_january: 5.773,
            ic_february: 5.996,
            ic_march: 4.964,
            ic_april: 4.347,
            ic_may: 3.658,
            ic_june: 3.473,
            ic_july: 3.631,
            ic_august: 4.414,
            ic_september: 4.753,
            ic_october: 5.038,
            ic_november: 4.851,
            ic_december: 5.639,
            ic_yearly: 4.712,
          },
          {
            ic_uuid: "92a14ed3-fcbf-4052-9e08-5678cbf5c7bf",
            ic_lat: -28141,
            ic_lon: -545579,
            ic_city: "Guarani das Missões",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.534,
            ic_february: 6.096,
            ic_march: 5.159,
            ic_april: 4.06,
            ic_may: 3.096,
            ic_june: 2.539,
            ic_july: 2.844,
            ic_august: 3.632,
            ic_september: 4.029,
            ic_october: 5.141,
            ic_november: 6.306,
            ic_december: 6.718,
            ic_yearly: 4.68,
          },
          {
            ic_uuid: "484b9c12-a760-487c-a759-453dc41373ae",
            ic_lat: -139462,
            ic_lon: -464862,
            ic_city: "Guarani de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.933,
            ic_february: 5.937,
            ic_march: 5.479,
            ic_april: 5.348,
            ic_may: 5.047,
            ic_june: 4.943,
            ic_july: 5.308,
            ic_august: 5.991,
            ic_september: 5.981,
            ic_october: 5.699,
            ic_november: 5.309,
            ic_december: 5.808,
            ic_yearly: 5.565,
          },
          {
            ic_uuid: "5f29892b-15dd-4169-a82f-7c705bc1eab8",
            ic_lat: -200751,
            ic_lon: -503415,
            ic_city: "Guarani d'Oeste",
            ic_states: "SÃO PAULO",
            ic_january: 5.785,
            ic_february: 5.987,
            ic_march: 5.318,
            ic_april: 4.941,
            ic_may: 4.247,
            ic_june: 3.973,
            ic_july: 4.188,
            ic_august: 5.093,
            ic_september: 5.13,
            ic_october: 5.663,
            ic_november: 5.876,
            ic_december: 6.194,
            ic_yearly: 5.2,
          },
          {
            ic_uuid: "ea6cbe65-40a5-4ae7-a46d-761e76005018",
            ic_lat: -250973,
            ic_lon: -52876,
            ic_city: "Guaraniaçu",
            ic_states: "PARANÁ",
            ic_january: 6.211,
            ic_february: 5.572,
            ic_march: 5.249,
            ic_april: 4.332,
            ic_may: 3.358,
            ic_june: 2.987,
            ic_july: 3.232,
            ic_august: 4.231,
            ic_september: 4.411,
            ic_october: 5.233,
            ic_november: 6.06,
            ic_december: 6.265,
            ic_yearly: 4.762,
          },
          {
            ic_uuid: "a6389b0e-c23f-412b-ad25-54eeca439b09",
            ic_lat: -21895,
            ic_lon: -495899,
            ic_city: "Guarantã",
            ic_states: "SÃO PAULO",
            ic_january: 5.759,
            ic_february: 5.864,
            ic_march: 5.255,
            ic_april: 4.748,
            ic_may: 3.902,
            ic_june: 3.665,
            ic_july: 3.875,
            ic_august: 4.77,
            ic_september: 4.899,
            ic_october: 5.601,
            ic_november: 5.907,
            ic_december: 6.215,
            ic_yearly: 5.038,
          },
          {
            ic_uuid: "1e34fdd5-82ab-4992-8245-c600ce0c5384",
            ic_lat: -99626,
            ic_lon: -549125,
            ic_city: "Guarantã do Norte",
            ic_states: "MATO GROSSO",
            ic_january: 4.801,
            ic_february: 4.759,
            ic_march: 4.726,
            ic_april: 4.612,
            ic_may: 4.662,
            ic_june: 4.7,
            ic_july: 4.893,
            ic_august: 5.431,
            ic_september: 5.016,
            ic_october: 5.137,
            ic_november: 4.945,
            ic_december: 4.927,
            ic_yearly: 4.884,
          },
          {
            ic_uuid: "5693b1ab-925d-402b-b108-b4c28582ba28",
            ic_lat: -206777,
            ic_lon: -405096,
            ic_city: "Guarapari",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.423,
            ic_february: 6.566,
            ic_march: 5.305,
            ic_april: 4.583,
            ic_may: 3.901,
            ic_june: 3.639,
            ic_july: 3.692,
            ic_august: 4.453,
            ic_september: 4.896,
            ic_october: 5.066,
            ic_november: 5.033,
            ic_december: 5.897,
            ic_yearly: 4.954,
          },
          {
            ic_uuid: "8eaa1493-f311-4af0-88d7-1e2a3b648425",
            ic_lat: -253907,
            ic_lon: -514628,
            ic_city: "Guarapuava",
            ic_states: "PARANÁ",
            ic_january: 5.753,
            ic_february: 5.344,
            ic_march: 4.865,
            ic_april: 4.117,
            ic_may: 3.229,
            ic_june: 2.863,
            ic_july: 3.135,
            ic_august: 4.112,
            ic_september: 4.284,
            ic_october: 4.983,
            ic_november: 5.829,
            ic_december: 6.009,
            ic_yearly: 4.543,
          },
          {
            ic_uuid: "5a4d67f5-da90-4733-adf0-6e8c259c0c20",
            ic_lat: -253075,
            ic_lon: -483208,
            ic_city: "Guaraqueçaba",
            ic_states: "PARANÁ",
            ic_january: 5.307,
            ic_february: 5.211,
            ic_march: 4.372,
            ic_april: 3.657,
            ic_may: 2.984,
            ic_june: 2.558,
            ic_july: 2.527,
            ic_august: 3.238,
            ic_september: 3.284,
            ic_october: 3.679,
            ic_november: 4.758,
            ic_december: 5.34,
            ic_yearly: 3.91,
          },
          {
            ic_uuid: "6dbe451e-8d28-4baa-a77d-bbfd407642d6",
            ic_lat: -217308,
            ic_lon: -430338,
            ic_city: "Guarará",
            ic_states: "MINAS GERAIS",
            ic_january: 5.734,
            ic_february: 5.992,
            ic_march: 4.983,
            ic_april: 4.268,
            ic_may: 3.582,
            ic_june: 3.341,
            ic_july: 3.556,
            ic_august: 4.3,
            ic_september: 4.646,
            ic_october: 4.944,
            ic_november: 4.771,
            ic_december: 5.567,
            ic_yearly: 4.64,
          },
          {
            ic_uuid: "3ff44b4a-11b3-4252-8da4-935dc19bcf0f",
            ic_lat: -212549,
            ic_lon: -506458,
            ic_city: "Guararapes",
            ic_states: "SÃO PAULO",
            ic_january: 5.935,
            ic_february: 5.97,
            ic_march: 5.389,
            ic_april: 4.889,
            ic_may: 4.003,
            ic_june: 3.717,
            ic_july: 3.969,
            ic_august: 4.869,
            ic_september: 4.917,
            ic_october: 5.607,
            ic_november: 6.087,
            ic_december: 6.311,
            ic_yearly: 5.139,
          },
          {
            ic_uuid: "1e361e3e-a523-49b1-91be-54a515fcd5f6",
            ic_lat: -234117,
            ic_lon: -460373,
            ic_city: "Guararema",
            ic_states: "SÃO PAULO",
            ic_january: 5.345,
            ic_february: 5.667,
            ic_march: 4.8,
            ic_april: 4.227,
            ic_may: 3.389,
            ic_june: 3.087,
            ic_july: 3.243,
            ic_august: 4.193,
            ic_september: 4.3,
            ic_october: 4.908,
            ic_november: 5.208,
            ic_december: 5.808,
            ic_yearly: 4.515,
          },
          {
            ic_uuid: "f4b3857e-b7dd-4c00-89b4-b79cca550c41",
            ic_lat: -165837,
            ic_lon: -39785,
            ic_city: "Guaratinga",
            ic_states: "BAHIA",
            ic_january: 5.886,
            ic_february: 5.993,
            ic_march: 5.403,
            ic_april: 4.618,
            ic_may: 3.929,
            ic_june: 3.524,
            ic_july: 3.738,
            ic_august: 4.242,
            ic_september: 4.865,
            ic_october: 5.059,
            ic_november: 5.116,
            ic_december: 5.967,
            ic_yearly: 4.862,
          },
          {
            ic_uuid: "104e7bac-a7ff-47ee-a34f-785a30a51265",
            ic_lat: -22808,
            ic_lon: -451942,
            ic_city: "Guaratinguetá",
            ic_states: "SÃO PAULO",
            ic_january: 5.564,
            ic_february: 5.794,
            ic_march: 4.912,
            ic_april: 4.481,
            ic_may: 3.676,
            ic_june: 3.413,
            ic_july: 3.548,
            ic_august: 4.511,
            ic_september: 4.671,
            ic_october: 5.146,
            ic_november: 5.236,
            ic_december: 5.867,
            ic_yearly: 4.735,
          },
          {
            ic_uuid: "4b87a6c6-9cf8-4792-8774-b68d357f4fe5",
            ic_lat: -258822,
            ic_lon: -485756,
            ic_city: "Guaratuba",
            ic_states: "PARANÁ",
            ic_january: 5.42,
            ic_february: 5.238,
            ic_march: 4.478,
            ic_april: 3.67,
            ic_may: 2.985,
            ic_june: 2.489,
            ic_july: 2.52,
            ic_august: 3.214,
            ic_september: 3.329,
            ic_october: 3.937,
            ic_november: 4.956,
            ic_december: 5.382,
            ic_yearly: 3.968,
          },
          {
            ic_uuid: "cbdbb1c0-5cde-4beb-aa4c-789ea276be97",
            ic_lat: -177678,
            ic_lon: -471001,
            ic_city: "Guarda-Mor",
            ic_states: "MINAS GERAIS",
            ic_january: 5.752,
            ic_february: 6.127,
            ic_march: 5.175,
            ic_april: 5.225,
            ic_may: 4.78,
            ic_june: 4.525,
            ic_july: 4.819,
            ic_august: 5.722,
            ic_september: 5.698,
            ic_october: 5.809,
            ic_november: 5.419,
            ic_december: 5.646,
            ic_yearly: 5.391,
          },
          {
            ic_uuid: "4a363a59-af75-41d7-9de2-809b8d90c226",
            ic_lat: -233718,
            ic_lon: -481841,
            ic_city: "Guareí",
            ic_states: "SÃO PAULO",
            ic_january: 5.423,
            ic_february: 5.675,
            ic_march: 5.008,
            ic_april: 4.456,
            ic_may: 3.596,
            ic_june: 3.35,
            ic_july: 3.437,
            ic_august: 4.446,
            ic_september: 4.601,
            ic_october: 5.157,
            ic_november: 5.613,
            ic_december: 6.004,
            ic_yearly: 4.73,
          },
          {
            ic_uuid: "0f22a20e-bc19-4de4-9026-eb3e6579081b",
            ic_lat: -213598,
            ic_lon: -48232,
            ic_city: "Guariba",
            ic_states: "SÃO PAULO",
            ic_january: 5.742,
            ic_february: 5.97,
            ic_march: 5.17,
            ic_april: 4.795,
            ic_may: 4.088,
            ic_june: 3.822,
            ic_july: 4.036,
            ic_august: 4.868,
            ic_september: 4.984,
            ic_october: 5.536,
            ic_november: 5.823,
            ic_december: 6.098,
            ic_yearly: 5.078,
          },
          {
            ic_uuid: "5fec2ed6-c264-4529-b0ab-0dde79a086bd",
            ic_lat: -93944,
            ic_lon: -436918,
            ic_city: "Guaribas",
            ic_states: "PIAUÍ",
            ic_january: 5.852,
            ic_february: 5.569,
            ic_march: 5.447,
            ic_april: 5.342,
            ic_may: 5.412,
            ic_june: 5.378,
            ic_july: 5.712,
            ic_august: 6.451,
            ic_september: 6.668,
            ic_october: 6.313,
            ic_november: 5.907,
            ic_december: 5.817,
            ic_yearly: 5.823,
          },
          {
            ic_uuid: "6da567d2-a118-44e0-a0f9-a4bbd91414e1",
            ic_lat: -147336,
            ic_lon: -497021,
            ic_city: "Guarinos",
            ic_states: "GOIÁS",
            ic_january: 5.39,
            ic_february: 5.592,
            ic_march: 5.265,
            ic_april: 5.181,
            ic_may: 4.936,
            ic_june: 4.762,
            ic_july: 4.989,
            ic_august: 5.862,
            ic_september: 5.585,
            ic_october: 5.618,
            ic_november: 5.294,
            ic_december: 5.41,
            ic_yearly: 5.324,
          },
          {
            ic_uuid: "6b88e878-1548-46a6-a4c2-4d0b6028ad3c",
            ic_lat: -239893,
            ic_lon: -462584,
            ic_city: "Guarujá",
            ic_states: "SÃO PAULO",
            ic_january: 5.449,
            ic_february: 5.493,
            ic_march: 4.619,
            ic_april: 4.034,
            ic_may: 3.272,
            ic_june: 2.879,
            ic_july: 2.914,
            ic_august: 3.595,
            ic_september: 3.591,
            ic_october: 4.032,
            ic_november: 4.873,
            ic_december: 5.449,
            ic_yearly: 4.183,
          },
          {
            ic_uuid: "33b7f47d-61a9-4af9-a331-fe4ccafcd454",
            ic_lat: -263863,
            ic_lon: -5353,
            ic_city: "Guarujá do Sul",
            ic_states: "SANTA CATARINA",
            ic_january: 6.274,
            ic_february: 5.773,
            ic_march: 5.115,
            ic_april: 4.132,
            ic_may: 3.228,
            ic_june: 2.762,
            ic_july: 3.053,
            ic_august: 3.946,
            ic_september: 4.212,
            ic_october: 5.177,
            ic_november: 6.015,
            ic_december: 6.375,
            ic_yearly: 4.672,
          },
          {
            ic_uuid: "f6227b98-97d7-4692-b4d2-7d9dbbaa144a",
            ic_lat: -234542,
            ic_lon: -465337,
            ic_city: "Guarulhos",
            ic_states: "SÃO PAULO",
            ic_january: 5.196,
            ic_february: 5.509,
            ic_march: 4.689,
            ic_april: 4.144,
            ic_may: 3.402,
            ic_june: 3.174,
            ic_july: 3.263,
            ic_august: 4.197,
            ic_september: 4.202,
            ic_october: 4.723,
            ic_november: 5.084,
            ic_december: 5.646,
            ic_yearly: 4.436,
          },
          {
            ic_uuid: "0fb5e7e7-be69-4a57-986f-6bbe014067a0",
            ic_lat: -271346,
            ic_lon: -527891,
            ic_city: "Guatambú",
            ic_states: "SANTA CATARINA",
            ic_january: 6.216,
            ic_february: 5.748,
            ic_march: 5.153,
            ic_april: 4.1,
            ic_may: 3.145,
            ic_june: 2.66,
            ic_july: 2.947,
            ic_august: 3.786,
            ic_september: 4.022,
            ic_october: 5.136,
            ic_november: 6.152,
            ic_december: 6.511,
            ic_yearly: 4.631,
          },
          {
            ic_uuid: "a19093f8-600f-485f-ae76-4c4180c7fd24",
            ic_lat: -214949,
            ic_lon: -48036,
            ic_city: "Guatapará",
            ic_states: "SÃO PAULO",
            ic_january: 5.738,
            ic_february: 5.975,
            ic_march: 5.19,
            ic_april: 4.764,
            ic_may: 4.052,
            ic_june: 3.819,
            ic_july: 4.006,
            ic_august: 4.861,
            ic_september: 4.935,
            ic_october: 5.515,
            ic_november: 5.767,
            ic_december: 6.107,
            ic_yearly: 5.061,
          },
          {
            ic_uuid: "9ba99731-295b-4ac5-bff6-047c2bd7cb59",
            ic_lat: -213055,
            ic_lon: -467084,
            ic_city: "Guaxupé",
            ic_states: "MINAS GERAIS",
            ic_january: 5.477,
            ic_february: 5.634,
            ic_march: 4.857,
            ic_april: 4.724,
            ic_may: 4.069,
            ic_june: 3.876,
            ic_july: 4.103,
            ic_august: 5.021,
            ic_september: 5.088,
            ic_october: 5.424,
            ic_november: 5.395,
            ic_december: 5.591,
            ic_yearly: 4.938,
          },
          {
            ic_uuid: "4b8714bc-46f4-4f49-91c9-e1af28e308a1",
            ic_lat: -214588,
            ic_lon: -561122,
            ic_city: "Guia Lopes da Laguna",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.918,
            ic_february: 5.743,
            ic_march: 5.433,
            ic_april: 4.7,
            ic_may: 3.712,
            ic_june: 3.42,
            ic_july: 3.589,
            ic_august: 4.523,
            ic_september: 4.744,
            ic_october: 5.445,
            ic_november: 5.972,
            ic_december: 6.28,
            ic_yearly: 4.956,
          },
          {
            ic_uuid: "1f30f62f-2f58-401c-bd29-92e52eb2d0fa",
            ic_lat: -211555,
            ic_lon: -427891,
            ic_city: "Guidoval",
            ic_states: "MINAS GERAIS",
            ic_january: 5.787,
            ic_february: 6.041,
            ic_march: 5.023,
            ic_april: 4.424,
            ic_may: 3.724,
            ic_june: 3.545,
            ic_july: 3.699,
            ic_august: 4.501,
            ic_september: 4.896,
            ic_october: 5.168,
            ic_november: 4.977,
            ic_december: 5.713,
            ic_yearly: 4.791,
          },
          {
            ic_uuid: "0c471f19-b93a-4958-8329-5353264a9e59",
            ic_lat: -21344,
            ic_lon: -446056,
            ic_city: "Guimarães",
            ic_states: "MARANHÃO",
            ic_january: 4.784,
            ic_february: 4.76,
            ic_march: 4.647,
            ic_april: 4.484,
            ic_may: 4.717,
            ic_june: 5.004,
            ic_july: 5.128,
            ic_august: 5.504,
            ic_september: 5.726,
            ic_october: 5.464,
            ic_november: 5.359,
            ic_december: 5.052,
            ic_yearly: 5.052,
          },
          {
            ic_uuid: "11e2031e-62da-45ac-a47d-b5c3f41eabba",
            ic_lat: -188447,
            ic_lon: -46797,
            ic_city: "Guimarânia",
            ic_states: "MINAS GERAIS",
            ic_january: 5.564,
            ic_february: 5.946,
            ic_march: 4.978,
            ic_april: 4.978,
            ic_may: 4.665,
            ic_june: 4.384,
            ic_july: 4.692,
            ic_august: 5.639,
            ic_september: 5.616,
            ic_october: 5.596,
            ic_november: 5.267,
            ic_december: 5.54,
            ic_yearly: 5.239,
          },
          {
            ic_uuid: "0025aaf5-66b5-4d22-8206-a8c79d41e967",
            ic_lat: -163466,
            ic_lon: -537642,
            ic_city: "Guiratinga",
            ic_states: "MATO GROSSO",
            ic_january: 5.437,
            ic_february: 5.441,
            ic_march: 5.23,
            ic_april: 5.122,
            ic_may: 4.754,
            ic_june: 4.569,
            ic_july: 4.742,
            ic_august: 5.595,
            ic_september: 5.376,
            ic_october: 5.369,
            ic_november: 5.531,
            ic_december: 5.594,
            ic_yearly: 5.23,
          },
          {
            ic_uuid: "0684ae8b-c619-4952-b9c4-43f3361053e7",
            ic_lat: -210103,
            ic_lon: -42721,
            ic_city: "Guiricema",
            ic_states: "MINAS GERAIS",
            ic_january: 5.864,
            ic_february: 6.102,
            ic_march: 5.079,
            ic_april: 4.471,
            ic_may: 3.816,
            ic_june: 3.63,
            ic_july: 3.829,
            ic_august: 4.621,
            ic_september: 4.947,
            ic_october: 5.21,
            ic_november: 5.027,
            ic_december: 5.701,
            ic_yearly: 4.858,
          },
          {
            ic_uuid: "b35b1f13-392a-44c3-8d52-df6acf5ad410",
            ic_lat: -192147,
            ic_lon: -49788,
            ic_city: "Gurinhatã",
            ic_states: "MINAS GERAIS",
            ic_january: 5.76,
            ic_february: 5.779,
            ic_march: 5.243,
            ic_april: 5.015,
            ic_may: 4.46,
            ic_june: 4.181,
            ic_july: 4.438,
            ic_august: 5.325,
            ic_september: 5.244,
            ic_october: 5.602,
            ic_november: 5.876,
            ic_december: 5.99,
            ic_yearly: 5.243,
          },
          {
            ic_uuid: "f117b95b-3d74-4048-b15e-003111265aa4",
            ic_lat: -7122,
            ic_lon: -354251,
            ic_city: "Gurinhém",
            ic_states: "PARAÍBA",
            ic_january: 5.572,
            ic_february: 5.766,
            ic_march: 5.808,
            ic_april: 5.269,
            ic_may: 4.672,
            ic_june: 4.192,
            ic_july: 4.374,
            ic_august: 5.089,
            ic_september: 5.503,
            ic_october: 5.739,
            ic_november: 6.005,
            ic_december: 5.846,
            ic_yearly: 5.32,
          },
          {
            ic_uuid: "068e7292-f12e-42a3-b186-6ace13772066",
            ic_lat: -72464,
            ic_lon: -36488,
            ic_city: "Gurjão",
            ic_states: "PARAÍBA",
            ic_january: 5.877,
            ic_february: 5.918,
            ic_march: 5.905,
            ic_april: 5.467,
            ic_may: 4.83,
            ic_june: 4.303,
            ic_july: 4.5,
            ic_august: 5.218,
            ic_september: 5.892,
            ic_october: 6.17,
            ic_november: 6.348,
            ic_december: 6.091,
            ic_yearly: 5.543,
          },
          {
            ic_uuid: "7ae1ce80-5b8d-43d7-87af-68b0216672b1",
            ic_lat: -14127,
            ic_lon: -516309,
            ic_city: "Gurupá",
            ic_states: "PARÁ",
            ic_january: 4.513,
            ic_february: 4.389,
            ic_march: 4.387,
            ic_april: 4.427,
            ic_may: 4.626,
            ic_june: 4.831,
            ic_july: 4.843,
            ic_august: 5.163,
            ic_september: 5.301,
            ic_october: 5.193,
            ic_november: 5.172,
            ic_december: 4.664,
            ic_yearly: 4.792,
          },
          {
            ic_uuid: "463e8f76-471a-4697-a8da-80efe1b31c75",
            ic_lat: -117284,
            ic_lon: -490684,
            ic_city: "Gurupi",
            ic_states: "TOCANTINS",
            ic_january: 5.243,
            ic_february: 5.384,
            ic_march: 5.011,
            ic_april: 5.021,
            ic_may: 5.016,
            ic_june: 4.868,
            ic_july: 5.227,
            ic_august: 5.806,
            ic_september: 5.779,
            ic_october: 5.464,
            ic_november: 5.245,
            ic_december: 5.316,
            ic_yearly: 5.282,
          },
          {
            ic_uuid: "91202377-6eaf-4161-ad10-9dca05e3948c",
            ic_lat: -2065,
            ic_lon: -506619,
            ic_city: "Guzolândia",
            ic_states: "SÃO PAULO",
            ic_january: 5.853,
            ic_february: 6.014,
            ic_march: 5.347,
            ic_april: 4.899,
            ic_may: 4.154,
            ic_june: 3.862,
            ic_july: 4.092,
            ic_august: 5.034,
            ic_september: 5.063,
            ic_october: 5.645,
            ic_november: 5.982,
            ic_december: 6.292,
            ic_yearly: 5.186,
          },
          {
            ic_uuid: "2c70e7c1-ab9a-4bef-b142-7981e7c2fead",
            ic_lat: -295512,
            ic_lon: -514202,
            ic_city: "Harmonia",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.152,
            ic_february: 5.718,
            ic_march: 4.809,
            ic_april: 3.77,
            ic_may: 2.8,
            ic_june: 2.331,
            ic_july: 2.527,
            ic_august: 3.215,
            ic_september: 3.539,
            ic_october: 4.551,
            ic_november: 6.086,
            ic_december: 6.459,
            ic_yearly: 4.33,
          },
          {
            ic_uuid: "3c59ab8c-17d3-4215-b4ce-8e17281c87fd",
            ic_lat: -157194,
            ic_lon: -498272,
            ic_city: "Heitoraí",
            ic_states: "GOIÁS",
            ic_january: 5.371,
            ic_february: 5.608,
            ic_march: 5.145,
            ic_april: 5.189,
            ic_may: 4.883,
            ic_june: 4.678,
            ic_july: 4.697,
            ic_august: 5.708,
            ic_september: 5.434,
            ic_october: 5.548,
            ic_november: 5.205,
            ic_december: 5.308,
            ic_yearly: 5.231,
          },
          {
            ic_uuid: "6577a95d-d7a5-4b6f-977b-56a583dc4a07",
            ic_lat: -220649,
            ic_lon: -455457,
            ic_city: "Heliodora",
            ic_states: "MINAS GERAIS",
            ic_january: 5.386,
            ic_february: 5.694,
            ic_march: 4.835,
            ic_april: 4.558,
            ic_may: 3.911,
            ic_june: 3.662,
            ic_july: 3.878,
            ic_august: 4.778,
            ic_september: 4.958,
            ic_october: 5.406,
            ic_november: 5.272,
            ic_december: 5.655,
            ic_yearly: 4.833,
          },
          {
            ic_uuid: "e55b7718-a25b-4b17-8f23-1b115d48af52",
            ic_lat: -106814,
            ic_lon: -38294,
            ic_city: "Heliópolis",
            ic_states: "BAHIA",
            ic_january: 6.136,
            ic_february: 5.902,
            ic_march: 5.828,
            ic_april: 5.038,
            ic_may: 4.221,
            ic_june: 3.877,
            ic_july: 4.025,
            ic_august: 4.493,
            ic_september: 5.314,
            ic_october: 5.594,
            ic_november: 6.143,
            ic_december: 6.096,
            ic_yearly: 5.222,
          },
          {
            ic_uuid: "914fcc9f-d65d-4c86-ad27-949d1e4cae9e",
            ic_lat: -220043,
            ic_lon: -503912,
            ic_city: "Herculândia",
            ic_states: "SÃO PAULO",
            ic_january: 5.841,
            ic_february: 5.92,
            ic_march: 5.303,
            ic_april: 4.742,
            ic_may: 3.887,
            ic_june: 3.622,
            ic_july: 3.82,
            ic_august: 4.803,
            ic_september: 4.819,
            ic_october: 5.536,
            ic_november: 6.006,
            ic_december: 6.313,
            ic_yearly: 5.051,
          },
          {
            ic_uuid: "afa35b86-2f55-45bb-b60c-153622d39e4d",
            ic_lat: -320245,
            ic_lon: -533948,
            ic_city: "Herval",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.301,
            ic_february: 5.648,
            ic_march: 4.826,
            ic_april: 3.683,
            ic_may: 2.631,
            ic_june: 2.243,
            ic_july: 2.449,
            ic_august: 3.032,
            ic_september: 3.641,
            ic_october: 4.978,
            ic_november: 6.289,
            ic_december: 6.807,
            ic_yearly: 4.377,
          },
          {
            ic_uuid: "095b6826-b91f-44a1-ba4e-b29e6bd1de43",
            ic_lat: -271908,
            ic_lon: -514922,
            ic_city: "Herval d'Oeste",
            ic_states: "SANTA CATARINA",
            ic_january: 5.922,
            ic_february: 5.532,
            ic_march: 5.012,
            ic_april: 4.087,
            ic_may: 3.061,
            ic_june: 2.667,
            ic_july: 2.903,
            ic_august: 3.783,
            ic_september: 4.02,
            ic_october: 4.958,
            ic_november: 6.153,
            ic_december: 6.299,
            ic_yearly: 4.533,
          },
          {
            ic_uuid: "25120175-5668-439b-95d9-3452293d9722",
            ic_lat: -294543,
            ic_lon: -526505,
            ic_city: "Herveiras",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.107,
            ic_february: 5.69,
            ic_march: 4.801,
            ic_april: 3.778,
            ic_may: 2.809,
            ic_june: 2.359,
            ic_july: 2.607,
            ic_august: 3.334,
            ic_september: 3.605,
            ic_october: 4.732,
            ic_november: 6.071,
            ic_december: 6.495,
            ic_yearly: 4.366,
          },
          {
            ic_uuid: "2ee0bb13-4135-445d-904f-2aa062d512a8",
            ic_lat: -441,
            ic_lon: -404059,
            ic_city: "Hidrolândia",
            ic_states: "CEARÁ",
            ic_january: 5.27,
            ic_february: 5.385,
            ic_march: 5.495,
            ic_april: 5.065,
            ic_may: 5.121,
            ic_june: 4.985,
            ic_july: 5.223,
            ic_august: 6.004,
            ic_september: 6.546,
            ic_october: 6.456,
            ic_november: 6.25,
            ic_december: 5.593,
            ic_yearly: 5.616,
          },
          {
            ic_uuid: "5200192f-6df2-486e-8085-70ac41f618f3",
            ic_lat: -169695,
            ic_lon: -492283,
            ic_city: "Hidrolândia",
            ic_states: "GOIÁS",
            ic_january: 5.523,
            ic_february: 5.641,
            ic_march: 5.189,
            ic_april: 5.074,
            ic_may: 4.738,
            ic_june: 4.52,
            ic_july: 4.725,
            ic_august: 5.603,
            ic_september: 5.46,
            ic_october: 5.574,
            ic_november: 5.387,
            ic_december: 5.593,
            ic_yearly: 5.252,
          },
          {
            ic_uuid: "8852685a-1080-407e-ab52-56adcfa9895a",
            ic_lat: -147206,
            ic_lon: -494683,
            ic_city: "Hidrolina",
            ic_states: "GOIÁS",
            ic_january: 5.313,
            ic_february: 5.469,
            ic_march: 5.148,
            ic_april: 5.075,
            ic_may: 4.907,
            ic_june: 4.728,
            ic_july: 4.976,
            ic_august: 5.881,
            ic_september: 5.611,
            ic_october: 5.473,
            ic_november: 5.162,
            ic_december: 5.335,
            ic_yearly: 5.256,
          },
          {
            ic_uuid: "b7207ebc-17e4-4e0c-8eb3-f4cf7cf86728",
            ic_lat: -22641,
            ic_lon: -470491,
            ic_city: "Holambra",
            ic_states: "SÃO PAULO",
            ic_january: 5.573,
            ic_february: 5.831,
            ic_march: 5.137,
            ic_april: 4.669,
            ic_may: 3.875,
            ic_june: 3.576,
            ic_july: 3.775,
            ic_august: 4.715,
            ic_september: 4.884,
            ic_october: 5.524,
            ic_november: 5.736,
            ic_december: 6.164,
            ic_yearly: 4.955,
          },
          {
            ic_uuid: "b83a3576-8642-4b03-add5-d40f052ccb19",
            ic_lat: -261395,
            ic_lon: -523853,
            ic_city: "Honório Serpa",
            ic_states: "PARANÁ",
            ic_january: 6.192,
            ic_february: 5.615,
            ic_march: 5.116,
            ic_april: 4.146,
            ic_may: 3.257,
            ic_june: 2.834,
            ic_july: 3.118,
            ic_august: 4.068,
            ic_september: 4.232,
            ic_october: 5.131,
            ic_november: 6.065,
            ic_december: 6.229,
            ic_yearly: 4.667,
          },
          {
            ic_uuid: "dc88d020-7919-474e-8efc-510a3eb233ac",
            ic_lat: -41109,
            ic_lon: -384886,
            ic_city: "Horizonte",
            ic_states: "CEARÁ",
            ic_january: 5.737,
            ic_february: 5.719,
            ic_march: 5.526,
            ic_april: 4.907,
            ic_may: 5.195,
            ic_june: 5.152,
            ic_july: 5.451,
            ic_august: 6.062,
            ic_september: 6.207,
            ic_october: 6.378,
            ic_november: 6.371,
            ic_december: 5.92,
            ic_yearly: 5.719,
          },
          {
            ic_uuid: "0598e44f-fcb8-4152-9a71-3667d6e2748a",
            ic_lat: -276287,
            ic_lon: -543058,
            ic_city: "Horizontina",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.461,
            ic_february: 6.026,
            ic_march: 5.166,
            ic_april: 4.046,
            ic_may: 3.096,
            ic_june: 2.546,
            ic_july: 2.853,
            ic_august: 3.682,
            ic_september: 4.023,
            ic_october: 5.201,
            ic_november: 6.225,
            ic_december: 6.639,
            ic_yearly: 4.664,
          },
          {
            ic_uuid: "471e712e-9a20-451a-aae3-b31299fb9571",
            ic_lat: -228533,
            ic_lon: -472147,
            ic_city: "Hortolândia",
            ic_states: "SÃO PAULO",
            ic_january: 5.658,
            ic_february: 5.768,
            ic_march: 5.123,
            ic_april: 4.666,
            ic_may: 3.791,
            ic_june: 3.551,
            ic_july: 3.697,
            ic_august: 4.66,
            ic_september: 4.835,
            ic_october: 5.488,
            ic_november: 5.765,
            ic_december: 6.192,
            ic_yearly: 4.933,
          },
          {
            ic_uuid: "c1742db5-4fd7-4b04-9841-97eb7168ef4d",
            ic_lat: -5989,
            ic_lon: -425601,
            ic_city: "Hugo Napoleão",
            ic_states: "PIAUÍ",
            ic_january: 5.068,
            ic_february: 5.227,
            ic_march: 5.298,
            ic_april: 5.16,
            ic_may: 5.217,
            ic_june: 5.39,
            ic_july: 5.616,
            ic_august: 6.203,
            ic_september: 6.579,
            ic_october: 6.294,
            ic_november: 5.984,
            ic_december: 5.597,
            ic_yearly: 5.636,
          },
          {
            ic_uuid: "b6ddb3e9-b240-4d22-957b-7f6cb8834a6b",
            ic_lat: -314072,
            ic_lon: -538672,
            ic_city: "Hulha Negra",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.462,
            ic_february: 5.887,
            ic_march: 5.023,
            ic_april: 3.772,
            ic_may: 2.704,
            ic_june: 2.293,
            ic_july: 2.537,
            ic_august: 3.178,
            ic_september: 3.785,
            ic_october: 5.098,
            ic_november: 6.413,
            ic_december: 6.922,
            ic_yearly: 4.506,
          },
          {
            ic_uuid: "59e2f9ea-a883-4eae-8741-1f08fcfc5cbd",
            ic_lat: -75121,
            ic_lon: -630332,
            ic_city: "Humaitá",
            ic_states: "AMAZONAS",
            ic_january: 4.189,
            ic_february: 4.378,
            ic_march: 4.377,
            ic_april: 4.279,
            ic_may: 3.926,
            ic_june: 4.422,
            ic_july: 4.523,
            ic_august: 4.939,
            ic_september: 4.971,
            ic_october: 4.899,
            ic_november: 4.66,
            ic_december: 4.412,
            ic_yearly: 4.498,
          },
          {
            ic_uuid: "1b38a16a-2e20-4c00-9412-23dba1d7261d",
            ic_lat: -275695,
            ic_lon: -5397,
            ic_city: "Humaitá",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.422,
            ic_february: 5.947,
            ic_march: 5.156,
            ic_april: 4.067,
            ic_may: 3.122,
            ic_june: 2.58,
            ic_july: 2.923,
            ic_august: 3.694,
            ic_september: 3.993,
            ic_october: 5.208,
            ic_november: 6.219,
            ic_december: 6.65,
            ic_yearly: 4.665,
          },
          {
            ic_uuid: "59afc0f0-f2aa-4b31-ba75-1011232c2386",
            ic_lat: -25987,
            ic_lon: -434652,
            ic_city: "Humberto de Campos",
            ic_states: "MARANHÃO",
            ic_january: 4.899,
            ic_february: 4.89,
            ic_march: 4.737,
            ic_april: 4.633,
            ic_may: 4.726,
            ic_june: 4.907,
            ic_july: 5.109,
            ic_august: 5.824,
            ic_september: 6.214,
            ic_october: 5.958,
            ic_november: 5.731,
            ic_december: 5.412,
            ic_yearly: 5.253,
          },
          {
            ic_uuid: "d826a38f-6914-45ad-8105-cea9295658be",
            ic_lat: -2189,
            ic_lon: -490249,
            ic_city: "Iacanga",
            ic_states: "SÃO PAULO",
            ic_january: 5.847,
            ic_february: 6.02,
            ic_march: 5.282,
            ic_april: 4.786,
            ic_may: 3.92,
            ic_june: 3.697,
            ic_july: 3.885,
            ic_august: 4.796,
            ic_september: 4.907,
            ic_october: 5.668,
            ic_november: 6.011,
            ic_december: 6.317,
            ic_yearly: 5.095,
          },
          {
            ic_uuid: "0b3a88e3-b8df-476f-a6e0-e5df53aaccdc",
            ic_lat: -141015,
            ic_lon: -466338,
            ic_city: "Iaciara",
            ic_states: "GOIÁS",
            ic_january: 6.006,
            ic_february: 6.016,
            ic_march: 5.515,
            ic_april: 5.389,
            ic_may: 5.07,
            ic_june: 4.975,
            ic_july: 5.338,
            ic_august: 5.891,
            ic_september: 5.986,
            ic_october: 5.851,
            ic_november: 5.424,
            ic_december: 5.952,
            ic_yearly: 5.618,
          },
          {
            ic_uuid: "04d5e5ac-b440-4fd4-bc81-12cb9a687cf3",
            ic_lat: -21858,
            ic_lon: -506889,
            ic_city: "Iacri",
            ic_states: "SÃO PAULO",
            ic_january: 5.855,
            ic_february: 5.908,
            ic_march: 5.331,
            ic_april: 4.762,
            ic_may: 3.948,
            ic_june: 3.63,
            ic_july: 3.859,
            ic_august: 4.849,
            ic_september: 4.822,
            ic_october: 5.543,
            ic_november: 6.019,
            ic_december: 6.345,
            ic_yearly: 5.073,
          },
          {
            ic_uuid: "a64c31ac-69b0-4f63-b831-2582b360b6b4",
            ic_lat: -127671,
            ic_lon: -402059,
            ic_city: "Iaçu",
            ic_states: "BAHIA",
            ic_january: 5.969,
            ic_february: 5.916,
            ic_march: 5.737,
            ic_april: 4.677,
            ic_may: 4.0,
            ic_june: 3.672,
            ic_july: 3.839,
            ic_august: 4.417,
            ic_september: 5.305,
            ic_october: 5.353,
            ic_november: 5.514,
            ic_december: 5.926,
            ic_yearly: 5.027,
          },
          {
            ic_uuid: "6345ee8d-1aff-4afe-8e3b-d528489c0578",
            ic_lat: -194392,
            ic_lon: -422151,
            ic_city: "Iapu",
            ic_states: "MINAS GERAIS",
            ic_january: 5.869,
            ic_february: 6.051,
            ic_march: 5.128,
            ic_april: 4.595,
            ic_may: 3.899,
            ic_june: 3.639,
            ic_july: 3.79,
            ic_august: 4.452,
            ic_september: 4.85,
            ic_october: 5.058,
            ic_november: 4.862,
            ic_december: 5.766,
            ic_yearly: 4.83,
          },
          {
            ic_uuid: "342eedcc-ef93-44ae-ba72-40e60bef50d8",
            ic_lat: -22871,
            ic_lon: -491629,
            ic_city: "Iaras",
            ic_states: "SÃO PAULO",
            ic_january: 5.512,
            ic_february: 5.726,
            ic_march: 5.101,
            ic_april: 4.642,
            ic_may: 3.703,
            ic_june: 3.461,
            ic_july: 3.658,
            ic_august: 4.654,
            ic_september: 4.742,
            ic_october: 5.366,
            ic_november: 5.83,
            ic_december: 6.155,
            ic_yearly: 4.879,
          },
          {
            ic_uuid: "6a530a62-6e8f-47e8-8578-ac90ebc217a8",
            ic_lat: -90437,
            ic_lon: -368465,
            ic_city: "Iati",
            ic_states: "PERNAMBUCO",
            ic_january: 6.157,
            ic_february: 5.904,
            ic_march: 5.882,
            ic_april: 5.313,
            ic_may: 4.403,
            ic_june: 3.95,
            ic_july: 4.113,
            ic_august: 4.785,
            ic_september: 5.711,
            ic_october: 6.104,
            ic_november: 6.552,
            ic_december: 6.396,
            ic_yearly: 5.439,
          },
          {
            ic_uuid: "c39bb121-2966-4742-a0ca-6cd94524a7c8",
            ic_lat: -238483,
            ic_lon: -501936,
            ic_city: "Ibaiti",
            ic_states: "PARANÁ",
            ic_january: 5.716,
            ic_february: 5.703,
            ic_march: 5.115,
            ic_april: 4.489,
            ic_may: 3.578,
            ic_june: 3.197,
            ic_july: 3.454,
            ic_august: 4.441,
            ic_september: 4.614,
            ic_october: 5.208,
            ic_november: 5.917,
            ic_december: 6.241,
            ic_yearly: 4.806,
          },
          {
            ic_uuid: "5b601e38-9a10-4dd1-9be4-bc1598fe4ebf",
            ic_lat: -294208,
            ic_lon: -5313,
            ic_city: "Ibarama",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.252,
            ic_february: 5.913,
            ic_march: 5.041,
            ic_april: 3.9,
            ic_may: 2.877,
            ic_june: 2.387,
            ic_july: 2.658,
            ic_august: 3.402,
            ic_september: 3.746,
            ic_october: 4.958,
            ic_november: 6.273,
            ic_december: 6.733,
            ic_yearly: 4.512,
          },
          {
            ic_uuid: "3d061639-9cb4-4537-89ad-25c2e1cc3791",
            ic_lat: -48209,
            ic_lon: -388243,
            ic_city: "Ibaretama",
            ic_states: "CEARÁ",
            ic_january: 5.523,
            ic_february: 5.573,
            ic_march: 5.661,
            ic_april: 5.123,
            ic_may: 5.241,
            ic_june: 4.966,
            ic_july: 5.34,
            ic_august: 6.132,
            ic_september: 6.499,
            ic_october: 6.49,
            ic_november: 6.224,
            ic_december: 5.678,
            ic_yearly: 5.704,
          },
          {
            ic_uuid: "421370f0-687f-4c9c-9347-e397d58f41e1",
            ic_lat: -219566,
            ic_lon: -48004,
            ic_city: "Ibaté",
            ic_states: "SÃO PAULO",
            ic_january: 5.537,
            ic_february: 5.853,
            ic_march: 5.154,
            ic_april: 4.675,
            ic_may: 3.948,
            ic_june: 3.719,
            ic_july: 3.845,
            ic_august: 4.781,
            ic_september: 4.907,
            ic_october: 5.497,
            ic_november: 5.691,
            ic_december: 6.023,
            ic_yearly: 4.969,
          },
          {
            ic_uuid: "ad7e27b8-8e8a-4709-911f-8f69841a8d7a",
            ic_lat: -89787,
            ic_lon: -359376,
            ic_city: "Ibateguara",
            ic_states: "ALAGOAS",
            ic_january: 5.854,
            ic_february: 5.809,
            ic_march: 5.758,
            ic_april: 5.048,
            ic_may: 4.127,
            ic_june: 3.683,
            ic_july: 3.795,
            ic_august: 4.451,
            ic_september: 5.355,
            ic_october: 5.651,
            ic_november: 6.187,
            ic_december: 6.121,
            ic_yearly: 5.153,
          },
          {
            ic_uuid: "49738773-9562-4ecd-b6a1-1d2d831e9619",
            ic_lat: -202352,
            ic_lon: -41509,
            ic_city: "Ibatiba",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.778,
            ic_february: 5.961,
            ic_march: 4.958,
            ic_april: 4.512,
            ic_may: 3.804,
            ic_june: 3.589,
            ic_july: 3.714,
            ic_august: 4.391,
            ic_september: 4.759,
            ic_october: 4.833,
            ic_november: 4.746,
            ic_december: 5.44,
            ic_yearly: 4.707,
          },
          {
            ic_uuid: "37626053-9d42-4d6f-a097-46c80b77342d",
            ic_lat: -251118,
            ic_lon: -530151,
            ic_city: "Ibema",
            ic_states: "PARANÁ",
            ic_january: 6.2,
            ic_february: 5.543,
            ic_march: 5.124,
            ic_april: 4.299,
            ic_may: 3.333,
            ic_june: 2.986,
            ic_july: 3.213,
            ic_august: 4.232,
            ic_september: 4.378,
            ic_october: 5.167,
            ic_november: 5.933,
            ic_december: 6.252,
            ic_yearly: 4.722,
          },
          {
            ic_uuid: "70ee7147-e4e4-4c12-befb-b7e67aa5db94",
            ic_lat: -214335,
            ic_lon: -439643,
            ic_city: "Ibertioga",
            ic_states: "MINAS GERAIS",
            ic_january: 5.499,
            ic_february: 5.793,
            ic_march: 4.836,
            ic_april: 4.35,
            ic_may: 3.77,
            ic_june: 3.595,
            ic_july: 3.766,
            ic_august: 4.666,
            ic_september: 4.898,
            ic_october: 5.096,
            ic_november: 4.934,
            ic_december: 5.553,
            ic_yearly: 4.73,
          },
          {
            ic_uuid: "4de2a582-ae9a-455c-b120-210378e1548f",
            ic_lat: -19489,
            ic_lon: -465474,
            ic_city: "Ibiá",
            ic_states: "MINAS GERAIS",
            ic_january: 5.527,
            ic_february: 5.869,
            ic_march: 4.92,
            ic_april: 4.846,
            ic_may: 4.435,
            ic_june: 4.225,
            ic_july: 4.456,
            ic_august: 5.526,
            ic_september: 5.444,
            ic_october: 5.529,
            ic_november: 5.224,
            ic_december: 5.61,
            ic_yearly: 5.134,
          },
          {
            ic_uuid: "9dbf6284-695d-43d7-82b4-543263ce994b",
            ic_lat: -28057,
            ic_lon: -518603,
            ic_city: "Ibiaçá",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.229,
            ic_february: 5.738,
            ic_march: 4.975,
            ic_april: 4.022,
            ic_may: 3.003,
            ic_june: 2.518,
            ic_july: 2.864,
            ic_august: 3.643,
            ic_september: 3.863,
            ic_october: 4.996,
            ic_november: 6.211,
            ic_december: 6.543,
            ic_yearly: 4.55,
          },
          {
            ic_uuid: "c0f74a21-0005-4782-8347-32dedcf32929",
            ic_lat: -168579,
            ic_lon: -449092,
            ic_city: "Ibiaí",
            ic_states: "MINAS GERAIS",
            ic_january: 6.354,
            ic_february: 6.53,
            ic_march: 5.623,
            ic_april: 5.537,
            ic_may: 4.926,
            ic_june: 4.649,
            ic_july: 4.933,
            ic_august: 5.746,
            ic_september: 6.035,
            ic_october: 6.109,
            ic_november: 5.599,
            ic_december: 6.036,
            ic_yearly: 5.673,
          },
          {
            ic_uuid: "f850f3e9-1b4b-40e2-b129-e774aadaf4c5",
            ic_lat: -271851,
            ic_lon: -512356,
            ic_city: "Ibiam",
            ic_states: "SANTA CATARINA",
            ic_january: 5.936,
            ic_february: 5.523,
            ic_march: 4.956,
            ic_april: 4.02,
            ic_may: 3.035,
            ic_june: 2.663,
            ic_july: 2.878,
            ic_august: 3.776,
            ic_september: 3.965,
            ic_october: 4.868,
            ic_november: 6.03,
            ic_december: 6.259,
            ic_yearly: 4.493,
          },
          {
            ic_uuid: "59db28d3-b8ba-4973-b987-e187066cb179",
            ic_lat: -39244,
            ic_lon: -408915,
            ic_city: "Ibiapina",
            ic_states: "CEARÁ",
            ic_january: 4.842,
            ic_february: 4.967,
            ic_march: 4.928,
            ic_april: 4.539,
            ic_may: 4.7,
            ic_june: 4.806,
            ic_july: 5.198,
            ic_august: 5.952,
            ic_september: 6.383,
            ic_october: 6.256,
            ic_november: 6.028,
            ic_december: 5.286,
            ic_yearly: 5.324,
          },
          {
            ic_uuid: "6bd2e691-2800-4377-b764-ddf16d9688e2",
            ic_lat: -748,
            ic_lon: -384062,
            ic_city: "Ibiara",
            ic_states: "PARAÍBA",
            ic_january: 6.247,
            ic_february: 6.143,
            ic_march: 6.043,
            ic_april: 5.756,
            ic_may: 5.134,
            ic_june: 4.738,
            ic_july: 5.001,
            ic_august: 5.824,
            ic_september: 6.548,
            ic_october: 6.588,
            ic_november: 6.798,
            ic_december: 6.476,
            ic_yearly: 5.941,
          },
          {
            ic_uuid: "01cbad57-7d6e-47a3-b1ab-979526fa9339",
            ic_lat: -142716,
            ic_lon: -422574,
            ic_city: "Ibiassucê",
            ic_states: "BAHIA",
            ic_january: 6.309,
            ic_february: 6.404,
            ic_march: 5.708,
            ic_april: 5.017,
            ic_may: 4.461,
            ic_june: 4.121,
            ic_july: 4.526,
            ic_august: 5.214,
            ic_september: 5.861,
            ic_october: 6.184,
            ic_november: 5.799,
            ic_december: 6.382,
            ic_yearly: 5.499,
          },
          {
            ic_uuid: "e43f7881-9340-4771-936d-94d59e411ebd",
            ic_lat: -148583,
            ic_lon: -395918,
            ic_city: "Ibicaraí",
            ic_states: "BAHIA",
            ic_january: 5.892,
            ic_february: 5.841,
            ic_march: 5.414,
            ic_april: 4.565,
            ic_may: 3.886,
            ic_june: 3.507,
            ic_july: 3.73,
            ic_august: 4.268,
            ic_september: 4.914,
            ic_october: 5.094,
            ic_november: 5.2,
            ic_december: 5.973,
            ic_yearly: 4.857,
          },
          {
            ic_uuid: "e8aed249-c56d-404a-b48c-e4f1081fd85f",
            ic_lat: -270886,
            ic_lon: -513685,
            ic_city: "Ibicaré",
            ic_states: "SANTA CATARINA",
            ic_january: 5.889,
            ic_february: 5.47,
            ic_march: 4.972,
            ic_april: 4.023,
            ic_may: 3.062,
            ic_june: 2.676,
            ic_july: 2.878,
            ic_august: 3.776,
            ic_september: 4.007,
            ic_october: 4.899,
            ic_november: 6.066,
            ic_december: 6.237,
            ic_yearly: 4.496,
          },
          {
            ic_uuid: "38274222-55f0-4ce7-adfa-d8febd77456a",
            ic_lat: -134064,
            ic_lon: -412843,
            ic_city: "Ibicoara",
            ic_states: "BAHIA",
            ic_january: 5.954,
            ic_february: 6.042,
            ic_march: 5.574,
            ic_april: 4.607,
            ic_may: 4.089,
            ic_june: 3.716,
            ic_july: 3.902,
            ic_august: 4.566,
            ic_september: 5.531,
            ic_october: 5.651,
            ic_november: 5.478,
            ic_december: 6.033,
            ic_yearly: 5.095,
          },
          {
            ic_uuid: "20594fb0-6348-4b9d-bf6f-b157cc912ea0",
            ic_lat: -148454,
            ic_lon: -399882,
            ic_city: "Ibicuí",
            ic_states: "BAHIA",
            ic_january: 5.869,
            ic_february: 5.854,
            ic_march: 5.445,
            ic_april: 4.566,
            ic_may: 3.887,
            ic_june: 3.557,
            ic_july: 3.683,
            ic_august: 4.212,
            ic_september: 4.815,
            ic_october: 4.973,
            ic_november: 5.136,
            ic_december: 6.008,
            ic_yearly: 4.834,
          },
          {
            ic_uuid: "25f8a0e6-a292-42a3-b33f-ad864323f353",
            ic_lat: -49704,
            ic_lon: -386366,
            ic_city: "Ibicuitinga",
            ic_states: "CEARÁ",
            ic_january: 5.635,
            ic_february: 5.711,
            ic_march: 5.768,
            ic_april: 5.284,
            ic_may: 5.203,
            ic_june: 5.045,
            ic_july: 5.416,
            ic_august: 6.097,
            ic_september: 6.473,
            ic_october: 6.538,
            ic_november: 6.31,
            ic_december: 5.825,
            ic_yearly: 5.775,
          },
          {
            ic_uuid: "49552616-c5a1-4b57-94b4-365f6827fe38",
            ic_lat: -8538,
            ic_lon: -37696,
            ic_city: "Ibimirim",
            ic_states: "PERNAMBUCO",
            ic_january: 6.378,
            ic_february: 6.216,
            ic_march: 6.251,
            ic_april: 5.696,
            ic_may: 4.785,
            ic_june: 4.216,
            ic_july: 4.421,
            ic_august: 5.202,
            ic_september: 6.125,
            ic_october: 6.338,
            ic_november: 6.663,
            ic_december: 6.533,
            ic_yearly: 5.735,
          },
          {
            ic_uuid: "97759d87-b575-4db1-8242-2269aa11bed8",
            ic_lat: -116442,
            ic_lon: -420199,
            ic_city: "Ibipeba",
            ic_states: "BAHIA",
            ic_january: 6.28,
            ic_february: 6.037,
            ic_march: 5.883,
            ic_april: 5.132,
            ic_may: 4.645,
            ic_june: 4.422,
            ic_july: 4.683,
            ic_august: 5.437,
            ic_september: 6.223,
            ic_october: 6.272,
            ic_november: 5.991,
            ic_december: 6.237,
            ic_yearly: 5.603,
          },
          {
            ic_uuid: "10dabe8a-6f65-4775-aa3c-54a991e8f131",
            ic_lat: -128809,
            ic_lon: -424859,
            ic_city: "Ibipitanga",
            ic_states: "BAHIA",
            ic_january: 6.668,
            ic_february: 6.596,
            ic_march: 6.164,
            ic_april: 5.565,
            ic_may: 5.032,
            ic_june: 4.817,
            ic_july: 5.02,
            ic_august: 5.738,
            ic_september: 6.218,
            ic_october: 6.524,
            ic_november: 6.126,
            ic_december: 6.671,
            ic_yearly: 5.928,
          },
          {
            ic_uuid: "520f5852-45df-4586-80b1-726be03d8272",
            ic_lat: -232664,
            ic_lon: -510527,
            ic_city: "Ibiporã",
            ic_states: "PARANÁ",
            ic_january: 5.865,
            ic_february: 5.735,
            ic_march: 5.257,
            ic_april: 4.652,
            ic_may: 3.665,
            ic_june: 3.374,
            ic_july: 3.586,
            ic_august: 4.535,
            ic_september: 4.714,
            ic_october: 5.284,
            ic_november: 5.989,
            ic_december: 6.261,
            ic_yearly: 4.91,
          },
          {
            ic_uuid: "c55e6341-edb5-4356-873c-a052df20cb4d",
            ic_lat: -126484,
            ic_lon: -409304,
            ic_city: "Ibiquera",
            ic_states: "BAHIA",
            ic_january: 5.855,
            ic_february: 5.881,
            ic_march: 5.501,
            ic_april: 4.55,
            ic_may: 4.025,
            ic_june: 3.758,
            ic_july: 3.929,
            ic_august: 4.562,
            ic_september: 5.505,
            ic_october: 5.466,
            ic_november: 5.395,
            ic_december: 5.749,
            ic_yearly: 5.015,
          },
          {
            ic_uuid: "9631c804-ebb0-4272-a5c8-f20d5a57584b",
            ic_lat: -210835,
            ic_lon: -492452,
            ic_city: "Ibirá",
            ic_states: "SÃO PAULO",
            ic_january: 5.833,
            ic_february: 5.941,
            ic_march: 5.235,
            ic_april: 4.835,
            ic_may: 4.095,
            ic_june: 3.81,
            ic_july: 4.059,
            ic_august: 4.964,
            ic_september: 5.079,
            ic_october: 5.622,
            ic_november: 5.93,
            ic_december: 6.15,
            ic_yearly: 5.13,
          },
          {
            ic_uuid: "1d8be417-4e61-49ec-92b3-c375db370b55",
            ic_lat: -15667,
            ic_lon: -441641,
            ic_city: "Ibiracatu",
            ic_states: "MINAS GERAIS",
            ic_january: 6.347,
            ic_february: 6.628,
            ic_march: 5.737,
            ic_april: 5.632,
            ic_may: 5.005,
            ic_june: 4.728,
            ic_july: 5.077,
            ic_august: 5.783,
            ic_september: 6.121,
            ic_october: 6.093,
            ic_november: 5.499,
            ic_december: 6.152,
            ic_yearly: 5.733,
          },
          {
            ic_uuid: "caac5c66-9576-43a0-b886-5475fe9b20fe",
            ic_lat: -204615,
            ic_lon: -471225,
            ic_city: "Ibiraci",
            ic_states: "MINAS GERAIS",
            ic_january: 5.538,
            ic_february: 5.785,
            ic_march: 4.973,
            ic_april: 4.833,
            ic_may: 4.263,
            ic_june: 4.062,
            ic_july: 4.327,
            ic_august: 5.216,
            ic_september: 5.203,
            ic_october: 5.522,
            ic_november: 5.538,
            ic_december: 5.653,
            ic_yearly: 5.076,
          },
          {
            ic_uuid: "724e6873-184c-4271-8fc1-36b704654ec8",
            ic_lat: -198371,
            ic_lon: -403735,
            ic_city: "Ibiraçu",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.955,
            ic_february: 6.143,
            ic_march: 5.273,
            ic_april: 4.47,
            ic_may: 3.858,
            ic_june: 3.567,
            ic_july: 3.671,
            ic_august: 4.206,
            ic_september: 4.649,
            ic_october: 4.733,
            ic_november: 4.79,
            ic_december: 5.631,
            ic_yearly: 4.746,
          },
          {
            ic_uuid: "7cfe5218-abbe-40f9-8be9-a6c8b702b465",
            ic_lat: -283746,
            ic_lon: -516381,
            ic_city: "Ibiraiaras",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.086,
            ic_february: 5.702,
            ic_march: 4.859,
            ic_april: 3.966,
            ic_may: 2.966,
            ic_june: 2.534,
            ic_july: 2.828,
            ic_august: 3.555,
            ic_september: 3.793,
            ic_october: 4.894,
            ic_november: 6.137,
            ic_december: 6.447,
            ic_yearly: 4.481,
          },
          {
            ic_uuid: "f12517f4-22d8-45b6-8a3c-3cfe013665a4",
            ic_lat: -85795,
            ic_lon: -361769,
            ic_city: "Ibirajuba",
            ic_states: "PERNAMBUCO",
            ic_january: 5.887,
            ic_february: 5.699,
            ic_march: 5.752,
            ic_april: 5.146,
            ic_may: 4.321,
            ic_june: 3.867,
            ic_july: 4.014,
            ic_august: 4.627,
            ic_september: 5.395,
            ic_october: 5.741,
            ic_november: 6.275,
            ic_december: 6.101,
            ic_yearly: 5.235,
          },
          {
            ic_uuid: "83c04a9f-7ca3-41c4-b3e2-e4c679464ebe",
            ic_lat: -270551,
            ic_lon: -495197,
            ic_city: "Ibirama",
            ic_states: "SANTA CATARINA",
            ic_january: 5.576,
            ic_february: 5.292,
            ic_march: 4.645,
            ic_april: 3.68,
            ic_may: 2.903,
            ic_june: 2.355,
            ic_july: 2.561,
            ic_august: 3.333,
            ic_september: 3.495,
            ic_october: 4.104,
            ic_november: 5.37,
            ic_december: 5.677,
            ic_yearly: 4.083,
          },
          {
            ic_uuid: "a1b50c37-7bb4-4264-ab3e-78dca5b4ed5e",
            ic_lat: -141621,
            ic_lon: -393727,
            ic_city: "Ibirapitanga",
            ic_states: "BAHIA",
            ic_january: 5.826,
            ic_february: 5.763,
            ic_march: 5.443,
            ic_april: 4.458,
            ic_may: 3.82,
            ic_june: 3.489,
            ic_july: 3.708,
            ic_august: 4.194,
            ic_september: 4.873,
            ic_october: 5.104,
            ic_november: 5.248,
            ic_december: 5.953,
            ic_yearly: 4.823,
          },
          {
            ic_uuid: "695c28be-7894-4a2b-a675-9680a88e638b",
            ic_lat: -176836,
            ic_lon: -401132,
            ic_city: "Ibirapuã",
            ic_states: "BAHIA",
            ic_january: 6.156,
            ic_february: 6.169,
            ic_march: 5.367,
            ic_april: 4.574,
            ic_may: 3.873,
            ic_june: 3.482,
            ic_july: 3.654,
            ic_august: 4.314,
            ic_september: 4.89,
            ic_october: 5.212,
            ic_november: 5.201,
            ic_december: 6.171,
            ic_yearly: 4.922,
          },
          {
            ic_uuid: "c27c264f-d61b-4456-9995-921d0ff91acb",
            ic_lat: -28624,
            ic_lon: -525109,
            ic_city: "Ibirapuitã",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.285,
            ic_february: 5.871,
            ic_march: 5.011,
            ic_april: 3.99,
            ic_may: 3.007,
            ic_june: 2.467,
            ic_july: 2.772,
            ic_august: 3.555,
            ic_september: 3.828,
            ic_october: 5.024,
            ic_november: 6.261,
            ic_december: 6.637,
            ic_yearly: 4.559,
          },
          {
            ic_uuid: "f7b94a3a-6ebb-45b8-ae79-c0699278bc13",
            ic_lat: -22818,
            ic_lon: -500729,
            ic_city: "Ibirarema",
            ic_states: "SÃO PAULO",
            ic_january: 5.658,
            ic_february: 5.785,
            ic_march: 5.143,
            ic_april: 4.687,
            ic_may: 3.758,
            ic_june: 3.46,
            ic_july: 3.674,
            ic_august: 4.612,
            ic_september: 4.751,
            ic_october: 5.41,
            ic_november: 5.882,
            ic_december: 6.304,
            ic_yearly: 4.927,
          },
          {
            ic_uuid: "07123e18-17c4-4910-aa0f-fed5423ace39",
            ic_lat: -140648,
            ic_lon: -396407,
            ic_city: "Ibirataia",
            ic_states: "BAHIA",
            ic_january: 5.857,
            ic_february: 5.801,
            ic_march: 5.497,
            ic_april: 4.487,
            ic_may: 3.847,
            ic_june: 3.489,
            ic_july: 3.711,
            ic_august: 4.21,
            ic_september: 4.956,
            ic_october: 5.141,
            ic_november: 5.274,
            ic_december: 5.903,
            ic_yearly: 4.848,
          },
          {
            ic_uuid: "4fead426-d30a-4710-a4ac-870e144a3df7",
            ic_lat: -200257,
            ic_lon: -440573,
            ic_city: "Ibirité",
            ic_states: "MINAS GERAIS",
            ic_january: 5.659,
            ic_february: 5.908,
            ic_march: 5.093,
            ic_april: 4.741,
            ic_may: 4.201,
            ic_june: 4.032,
            ic_july: 4.292,
            ic_august: 5.175,
            ic_september: 5.538,
            ic_october: 5.622,
            ic_november: 5.251,
            ic_december: 5.581,
            ic_yearly: 5.091,
          },
          {
            ic_uuid: "6fbb08c6-6848-47c2-ab14-1f16036bfbf5",
            ic_lat: -286306,
            ic_lon: -530966,
            ic_city: "Ibirubá",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.367,
            ic_february: 5.945,
            ic_march: 5.06,
            ic_april: 3.963,
            ic_may: 3.021,
            ic_june: 2.479,
            ic_july: 2.758,
            ic_august: 3.554,
            ic_september: 3.877,
            ic_october: 5.025,
            ic_november: 6.259,
            ic_december: 6.624,
            ic_yearly: 4.578,
          },
          {
            ic_uuid: "d44ca211-6bdc-414a-b54d-2973b576a176",
            ic_lat: -126506,
            ic_lon: -422183,
            ic_city: "Ibitiara",
            ic_states: "BAHIA",
            ic_january: 6.365,
            ic_february: 6.301,
            ic_march: 5.915,
            ic_april: 5.293,
            ic_may: 4.887,
            ic_june: 4.706,
            ic_july: 4.933,
            ic_august: 5.689,
            ic_september: 6.315,
            ic_october: 6.416,
            ic_november: 5.983,
            ic_december: 6.496,
            ic_yearly: 5.775,
          },
          {
            ic_uuid: "ef4f9cab-6226-434e-9fbc-a4c850dece5f",
            ic_lat: -217567,
            ic_lon: -488323,
            ic_city: "Ibitinga",
            ic_states: "SÃO PAULO",
            ic_january: 5.815,
            ic_february: 5.966,
            ic_march: 5.221,
            ic_april: 4.742,
            ic_may: 3.93,
            ic_june: 3.664,
            ic_july: 3.908,
            ic_august: 4.773,
            ic_september: 4.936,
            ic_october: 5.605,
            ic_november: 5.884,
            ic_december: 6.221,
            ic_yearly: 5.056,
          },
          {
            ic_uuid: "6bf77546-a747-4002-9461-f27a9aca579f",
            ic_lat: -205471,
            ic_lon: -416671,
            ic_city: "Ibitirama",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.789,
            ic_february: 6.091,
            ic_march: 4.908,
            ic_april: 4.326,
            ic_may: 3.759,
            ic_june: 3.639,
            ic_july: 3.771,
            ic_august: 4.509,
            ic_september: 4.929,
            ic_october: 4.945,
            ic_november: 4.696,
            ic_december: 5.539,
            ic_yearly: 4.742,
          },
          {
            ic_uuid: "f958e0fb-967b-4db2-89b3-c647ec379b73",
            ic_lat: -115419,
            ic_lon: -419752,
            ic_city: "Ibititá",
            ic_states: "BAHIA",
            ic_january: 6.415,
            ic_february: 6.213,
            ic_march: 5.999,
            ic_april: 5.228,
            ic_may: 4.727,
            ic_june: 4.517,
            ic_july: 4.759,
            ic_august: 5.543,
            ic_september: 6.242,
            ic_october: 6.363,
            ic_november: 6.119,
            ic_december: 6.378,
            ic_yearly: 5.709,
          },
          {
            ic_uuid: "a5b0f7eb-bf57-44fa-8170-e9f1f56959ff",
            ic_lat: -220609,
            ic_lon: -464372,
            ic_city: "Ibitiúra de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.202,
            ic_february: 5.42,
            ic_march: 4.748,
            ic_april: 4.52,
            ic_may: 3.883,
            ic_june: 3.696,
            ic_july: 3.891,
            ic_august: 4.802,
            ic_september: 4.944,
            ic_october: 5.282,
            ic_november: 5.318,
            ic_december: 5.476,
            ic_yearly: 4.765,
          },
          {
            ic_uuid: "5a7a80e7-d803-49e5-844a-ecb04e151873",
            ic_lat: -211546,
            ic_lon: -447483,
            ic_city: "Ibituruna",
            ic_states: "MINAS GERAIS",
            ic_january: 5.63,
            ic_february: 5.799,
            ic_march: 5.001,
            ic_april: 4.644,
            ic_may: 3.951,
            ic_june: 3.739,
            ic_july: 3.974,
            ic_august: 4.933,
            ic_september: 5.129,
            ic_october: 5.379,
            ic_november: 5.192,
            ic_december: 5.624,
            ic_yearly: 4.916,
          },
          {
            ic_uuid: "2292470e-6074-44d7-b060-47cf9f74ef58",
            ic_lat: -236601,
            ic_lon: -472234,
            ic_city: "Ibiúna",
            ic_states: "SÃO PAULO",
            ic_january: 5.263,
            ic_february: 5.548,
            ic_march: 4.809,
            ic_april: 4.277,
            ic_may: 3.48,
            ic_june: 3.225,
            ic_july: 3.308,
            ic_august: 4.281,
            ic_september: 4.353,
            ic_october: 4.874,
            ic_november: 5.354,
            ic_december: 5.829,
            ic_yearly: 4.55,
          },
          {
            ic_uuid: "e61ba69f-06d2-4a75-afa3-f257abe31f41",
            ic_lat: -121783,
            ic_lon: -43217,
            ic_city: "Ibotirama",
            ic_states: "BAHIA",
            ic_january: 6.59,
            ic_february: 6.525,
            ic_march: 6.214,
            ic_april: 5.78,
            ic_may: 5.267,
            ic_june: 5.046,
            ic_july: 5.231,
            ic_august: 5.89,
            ic_september: 6.242,
            ic_october: 6.469,
            ic_november: 6.181,
            ic_december: 6.536,
            ic_yearly: 5.998,
          },
          {
            ic_uuid: "d0fd57e9-d146-4066-9b85-c0d4fca89142",
            ic_lat: -47125,
            ic_lon: -373534,
            ic_city: "Icapuí",
            ic_states: "CEARÁ",
            ic_january: 6.298,
            ic_february: 6.322,
            ic_march: 6.192,
            ic_april: 5.723,
            ic_may: 5.546,
            ic_june: 5.335,
            ic_july: 5.518,
            ic_august: 6.094,
            ic_september: 5.979,
            ic_october: 6.074,
            ic_november: 6.28,
            ic_december: 6.412,
            ic_yearly: 5.981,
          },
          {
            ic_uuid: "f2d25870-45e5-400c-9243-fe077015cfb5",
            ic_lat: -287137,
            ic_lon: -493091,
            ic_city: "Içara",
            ic_states: "SANTA CATARINA",
            ic_january: 5.659,
            ic_february: 5.234,
            ic_march: 4.498,
            ic_april: 3.788,
            ic_may: 2.98,
            ic_june: 2.491,
            ic_july: 2.704,
            ic_august: 3.425,
            ic_september: 3.538,
            ic_october: 4.32,
            ic_november: 5.647,
            ic_december: 5.914,
            ic_yearly: 4.183,
          },
          {
            ic_uuid: "cfb7039d-d3cc-4dde-8d01-57e13a0ae107",
            ic_lat: -162194,
            ic_lon: -449045,
            ic_city: "Icaraí de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 6.328,
            ic_february: 6.516,
            ic_march: 5.695,
            ic_april: 5.519,
            ic_may: 4.97,
            ic_june: 4.71,
            ic_july: 5.005,
            ic_august: 5.886,
            ic_september: 5.993,
            ic_october: 6.06,
            ic_november: 5.61,
            ic_december: 6.145,
            ic_yearly: 5.703,
          },
          {
            ic_uuid: "0bdbb71b-523c-4396-9a8f-48dcb95cd998",
            ic_lat: -233948,
            ic_lon: -536154,
            ic_city: "Icaraíma",
            ic_states: "PARANÁ",
            ic_january: 6.166,
            ic_february: 5.97,
            ic_march: 5.44,
            ic_april: 4.526,
            ic_may: 3.587,
            ic_june: 3.211,
            ic_july: 3.413,
            ic_august: 4.336,
            ic_september: 4.606,
            ic_october: 5.425,
            ic_november: 6.159,
            ic_december: 6.564,
            ic_yearly: 4.95,
          },
          {
            ic_uuid: "21c74b9a-8825-426c-a862-c268e7b882f7",
            ic_lat: -27749,
            ic_lon: -440617,
            ic_city: "Icatu",
            ic_states: "MARANHÃO",
            ic_january: 4.485,
            ic_february: 4.462,
            ic_march: 4.451,
            ic_april: 4.394,
            ic_may: 4.514,
            ic_june: 4.781,
            ic_july: 5.022,
            ic_august: 5.569,
            ic_september: 5.939,
            ic_october: 5.706,
            ic_november: 5.367,
            ic_december: 4.89,
            ic_yearly: 4.965,
          },
          {
            ic_uuid: "53ad46b9-e788-4577-aa5e-2332413efd0f",
            ic_lat: -20342,
            ic_lon: -491949,
            ic_city: "Icém",
            ic_states: "SÃO PAULO",
            ic_january: 5.785,
            ic_february: 6.008,
            ic_march: 5.238,
            ic_april: 4.97,
            ic_may: 4.203,
            ic_june: 3.891,
            ic_july: 4.189,
            ic_august: 5.02,
            ic_september: 5.026,
            ic_october: 5.57,
            ic_november: 5.827,
            ic_december: 6.187,
            ic_yearly: 5.16,
          },
          {
            ic_uuid: "6fd6698c-48e4-4b20-98f6-f8cfebc5910e",
            ic_lat: -117487,
            ic_lon: -391906,
            ic_city: "Ichu",
            ic_states: "BAHIA",
            ic_january: 6.107,
            ic_february: 5.864,
            ic_march: 5.751,
            ic_april: 4.804,
            ic_may: 4.011,
            ic_june: 3.732,
            ic_july: 3.956,
            ic_august: 4.429,
            ic_september: 5.315,
            ic_october: 5.571,
            ic_november: 5.874,
            ic_december: 6.036,
            ic_yearly: 5.121,
          },
          {
            ic_uuid: "8ae17874-767a-4ef1-ab8a-adada52cd849",
            ic_lat: -63967,
            ic_lon: -388557,
            ic_city: "Icó",
            ic_states: "CEARÁ",
            ic_january: 5.878,
            ic_february: 5.909,
            ic_march: 5.965,
            ic_april: 5.685,
            ic_may: 5.232,
            ic_june: 5.075,
            ic_july: 5.377,
            ic_august: 6.133,
            ic_september: 6.43,
            ic_october: 6.607,
            ic_november: 6.62,
            ic_december: 6.113,
            ic_yearly: 5.918,
          },
          {
            ic_uuid: "cc8f86fd-980f-4702-8f03-781686d30e46",
            ic_lat: -207918,
            ic_lon: -408136,
            ic_city: "Iconha",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.991,
            ic_february: 6.219,
            ic_march: 4.956,
            ic_april: 4.283,
            ic_may: 3.679,
            ic_june: 3.494,
            ic_july: 3.548,
            ic_august: 4.27,
            ic_september: 4.673,
            ic_october: 4.822,
            ic_november: 4.694,
            ic_december: 5.487,
            ic_yearly: 4.676,
          },
          {
            ic_uuid: "22658b3c-efc5-4204-8c79-4ca8bc1452a9",
            ic_lat: -58221,
            ic_lon: -355519,
            ic_city: "Ielmo Marinho",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.89,
            ic_february: 5.926,
            ic_march: 5.951,
            ic_april: 5.513,
            ic_may: 4.998,
            ic_june: 4.571,
            ic_july: 4.745,
            ic_august: 5.395,
            ic_september: 5.835,
            ic_october: 6.047,
            ic_november: 6.242,
            ic_december: 5.867,
            ic_yearly: 5.582,
          },
          {
            ic_uuid: "980b2a8e-4d27-40bc-a454-2da2815584dc",
            ic_lat: -226606,
            ic_lon: -510783,
            ic_city: "Iepê",
            ic_states: "SÃO PAULO",
            ic_january: 5.894,
            ic_february: 5.847,
            ic_march: 5.364,
            ic_april: 4.727,
            ic_may: 3.776,
            ic_june: 3.426,
            ic_july: 3.66,
            ic_august: 4.63,
            ic_september: 4.728,
            ic_october: 5.441,
            ic_november: 6.053,
            ic_december: 6.402,
            ic_yearly: 4.995,
          },
          {
            ic_uuid: "06b131c9-3d2a-4fd8-8796-e0315bdad62f",
            ic_lat: -95381,
            ic_lon: -366375,
            ic_city: "Igaci",
            ic_states: "ALAGOAS",
            ic_january: 6.078,
            ic_february: 5.861,
            ic_march: 5.919,
            ic_april: 5.194,
            ic_may: 4.31,
            ic_june: 3.95,
            ic_july: 4.073,
            ic_august: 4.703,
            ic_september: 5.539,
            ic_october: 5.883,
            ic_november: 6.47,
            ic_december: 6.386,
            ic_yearly: 5.364,
          },
          {
            ic_uuid: "cb489313-9ff3-458d-ab57-2233f4b0cf26",
            ic_lat: -1377,
            ic_lon: -427182,
            ic_city: "Igaporã",
            ic_states: "BAHIA",
            ic_january: 6.523,
            ic_february: 6.554,
            ic_march: 5.924,
            ic_april: 5.546,
            ic_may: 4.928,
            ic_june: 4.716,
            ic_july: 5.005,
            ic_august: 5.714,
            ic_september: 6.194,
            ic_october: 6.417,
            ic_november: 5.849,
            ic_december: 6.422,
            ic_yearly: 5.816,
          },
          {
            ic_uuid: "0c51961c-84e6-45e6-9dd3-e75365254d34",
            ic_lat: -225095,
            ic_lon: -485601,
            ic_city: "Igaraçu do Tietê",
            ic_states: "SÃO PAULO",
            ic_january: 5.667,
            ic_february: 5.965,
            ic_march: 5.298,
            ic_april: 4.704,
            ic_may: 3.814,
            ic_june: 3.538,
            ic_july: 3.73,
            ic_august: 4.677,
            ic_september: 4.852,
            ic_october: 5.56,
            ic_november: 5.994,
            ic_december: 6.314,
            ic_yearly: 5.009,
          },
          {
            ic_uuid: "89bae338-9962-434e-b2ca-addd94176c0c",
            ic_lat: -71813,
            ic_lon: -381508,
            ic_city: "Igaracy",
            ic_states: "PARAÍBA",
            ic_january: 6.147,
            ic_february: 6.129,
            ic_march: 6.225,
            ic_april: 5.873,
            ic_may: 5.231,
            ic_june: 4.862,
            ic_july: 5.145,
            ic_august: 5.946,
            ic_september: 6.548,
            ic_october: 6.632,
            ic_november: 6.789,
            ic_december: 6.458,
            ic_yearly: 5.999,
          },
          {
            ic_uuid: "bf9a14cb-763e-4802-9d44-330fc6724f68",
            ic_lat: -200412,
            ic_lon: -47747,
            ic_city: "Igarapava",
            ic_states: "SÃO PAULO",
            ic_january: 5.573,
            ic_february: 5.89,
            ic_march: 5.101,
            ic_april: 5.019,
            ic_may: 4.343,
            ic_june: 4.119,
            ic_july: 4.387,
            ic_august: 5.275,
            ic_september: 5.179,
            ic_october: 5.58,
            ic_november: 5.572,
            ic_december: 5.821,
            ic_yearly: 5.155,
          },
          {
            ic_uuid: "c0244384-b535-4c0e-836c-15ae52646bc6",
            ic_lat: -200711,
            ic_lon: -442998,
            ic_city: "Igarapé",
            ic_states: "MINAS GERAIS",
            ic_january: 5.858,
            ic_february: 5.995,
            ic_march: 5.101,
            ic_april: 4.766,
            ic_may: 4.214,
            ic_june: 4.069,
            ic_july: 4.333,
            ic_august: 5.209,
            ic_september: 5.485,
            ic_october: 5.592,
            ic_november: 5.291,
            ic_december: 5.56,
            ic_yearly: 5.123,
          },
          {
            ic_uuid: "299c4857-881b-4bfc-8be9-7ba244f3b5b7",
            ic_lat: -36404,
            ic_lon: -451847,
            ic_city: "Igarapé do Meio",
            ic_states: "MARANHÃO",
            ic_january: 4.703,
            ic_february: 4.769,
            ic_march: 4.821,
            ic_april: 4.845,
            ic_may: 4.759,
            ic_june: 4.957,
            ic_july: 5.093,
            ic_august: 5.735,
            ic_september: 5.916,
            ic_october: 5.513,
            ic_november: 5.153,
            ic_december: 4.972,
            ic_yearly: 5.103,
          },
          {
            ic_uuid: "328b2b05-8bfb-4522-9df3-9c3370ce3add",
            ic_lat: -46629,
            ic_lon: -448562,
            ic_city: "Igarapé Grande",
            ic_states: "MARANHÃO",
            ic_january: 4.649,
            ic_february: 4.89,
            ic_march: 4.89,
            ic_april: 4.851,
            ic_may: 4.853,
            ic_june: 5.041,
            ic_july: 5.28,
            ic_august: 5.821,
            ic_september: 6.085,
            ic_october: 5.643,
            ic_november: 5.25,
            ic_december: 4.933,
            ic_yearly: 5.182,
          },
          {
            ic_uuid: "2e7f9ec5-c98f-4193-9eb7-0dad992d9ae5",
            ic_lat: -11266,
            ic_lon: -476231,
            ic_city: "Igarapé-Açu",
            ic_states: "PARÁ",
            ic_january: 4.356,
            ic_february: 4.195,
            ic_march: 4.136,
            ic_april: 4.266,
            ic_may: 4.543,
            ic_june: 4.794,
            ic_july: 4.938,
            ic_august: 5.202,
            ic_september: 5.412,
            ic_october: 5.234,
            ic_november: 5.051,
            ic_december: 4.621,
            ic_yearly: 4.729,
          },
          {
            ic_uuid: "0ad93f8f-5eca-4262-9143-d058cf0d4f70",
            ic_lat: -19757,
            ic_lon: -489578,
            ic_city: "Igarapé-Miri",
            ic_states: "PARÁ",
            ic_january: 4.488,
            ic_february: 4.522,
            ic_march: 4.567,
            ic_april: 4.627,
            ic_may: 4.714,
            ic_june: 4.925,
            ic_july: 5.073,
            ic_august: 5.288,
            ic_september: 5.246,
            ic_october: 5.055,
            ic_november: 4.912,
            ic_december: 4.645,
            ic_yearly: 4.838,
          },
          {
            ic_uuid: "32c0d0f1-f3c4-496f-90c5-b6aae18ecec9",
            ic_lat: -78378,
            ic_lon: -349025,
            ic_city: "Igarassu",
            ic_states: "PERNAMBUCO",
            ic_january: 5.77,
            ic_february: 5.931,
            ic_march: 5.969,
            ic_april: 5.162,
            ic_may: 4.483,
            ic_june: 4.17,
            ic_july: 4.29,
            ic_august: 5.019,
            ic_september: 5.405,
            ic_october: 5.729,
            ic_november: 6.037,
            ic_december: 6.034,
            ic_yearly: 5.333,
          },
          {
            ic_uuid: "ec7473bd-f57b-4a14-9a52-7f50e939d263",
            ic_lat: -232041,
            ic_lon: -461574,
            ic_city: "Igaratá",
            ic_states: "SÃO PAULO",
            ic_january: 5.238,
            ic_february: 5.516,
            ic_march: 4.738,
            ic_april: 4.242,
            ic_may: 3.387,
            ic_june: 3.167,
            ic_july: 3.243,
            ic_august: 4.248,
            ic_september: 4.279,
            ic_october: 4.828,
            ic_november: 5.086,
            ic_december: 5.659,
            ic_yearly: 4.469,
          },
          {
            ic_uuid: "e2f6e73a-5d70-4d7c-bdfe-46766b73ec74",
            ic_lat: -199481,
            ic_lon: -447067,
            ic_city: "Igaratinga",
            ic_states: "MINAS GERAIS",
            ic_january: 5.875,
            ic_february: 6.068,
            ic_march: 5.177,
            ic_april: 4.901,
            ic_may: 4.305,
            ic_june: 4.135,
            ic_july: 4.373,
            ic_august: 5.251,
            ic_september: 5.493,
            ic_october: 5.59,
            ic_november: 5.376,
            ic_december: 5.642,
            ic_yearly: 5.182,
          },
          {
            ic_uuid: "841537b6-5819-4912-bd7f-df770dd356c6",
            ic_lat: -138214,
            ic_lon: -391416,
            ic_city: "Igrapiúna",
            ic_states: "BAHIA",
            ic_january: 6.002,
            ic_february: 5.873,
            ic_march: 5.625,
            ic_april: 4.556,
            ic_may: 3.9,
            ic_june: 3.542,
            ic_july: 3.769,
            ic_august: 4.239,
            ic_september: 4.952,
            ic_october: 5.179,
            ic_november: 5.435,
            ic_december: 6.02,
            ic_yearly: 4.925,
          },
          {
            ic_uuid: "c77698c5-427a-411d-b685-3e4e7db85e02",
            ic_lat: -101284,
            ic_lon: -366571,
            ic_city: "Igreja Nova",
            ic_states: "ALAGOAS",
            ic_january: 5.979,
            ic_february: 5.848,
            ic_march: 5.791,
            ic_april: 5.008,
            ic_may: 4.252,
            ic_june: 4.037,
            ic_july: 4.085,
            ic_august: 4.635,
            ic_september: 5.388,
            ic_october: 5.749,
            ic_november: 6.213,
            ic_december: 6.189,
            ic_yearly: 5.265,
          },
          {
            ic_uuid: "85bd38f0-f72a-4056-aae8-71c1639ce9be",
            ic_lat: -295698,
            ic_lon: -507923,
            ic_city: "Igrejinha",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.144,
            ic_february: 5.618,
            ic_march: 4.7,
            ic_april: 3.785,
            ic_may: 2.813,
            ic_june: 2.357,
            ic_july: 2.557,
            ic_august: 3.252,
            ic_september: 3.553,
            ic_october: 4.603,
            ic_november: 6.07,
            ic_december: 6.428,
            ic_yearly: 4.323,
          },
          {
            ic_uuid: "fb077ee0-1ba8-482c-a7ba-19c83c6dd318",
            ic_lat: -228499,
            ic_lon: -422303,
            ic_city: "Iguaba Grande",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.27,
            ic_february: 6.49,
            ic_march: 5.2,
            ic_april: 4.511,
            ic_may: 3.665,
            ic_june: 3.442,
            ic_july: 3.459,
            ic_august: 4.291,
            ic_september: 4.551,
            ic_october: 5.174,
            ic_november: 5.212,
            ic_december: 6.062,
            ic_yearly: 4.86,
          },
          {
            ic_uuid: "22882a10-4e40-4919-be3d-117eb55daea4",
            ic_lat: -147532,
            ic_lon: -400898,
            ic_city: "Iguaí",
            ic_states: "BAHIA",
            ic_january: 5.799,
            ic_february: 5.927,
            ic_march: 5.444,
            ic_april: 4.594,
            ic_may: 3.889,
            ic_june: 3.502,
            ic_july: 3.691,
            ic_august: 4.172,
            ic_september: 4.903,
            ic_october: 5.075,
            ic_november: 5.237,
            ic_december: 6.028,
            ic_yearly: 4.855,
          },
          {
            ic_uuid: "d7af6bd4-ce57-4d9b-b098-47b808b2f237",
            ic_lat: -24708,
            ic_lon: -47555,
            ic_city: "Iguape",
            ic_states: "SÃO PAULO",
            ic_january: 5.558,
            ic_february: 5.549,
            ic_march: 4.592,
            ic_april: 3.933,
            ic_may: 3.067,
            ic_june: 2.616,
            ic_july: 2.595,
            ic_august: 3.259,
            ic_september: 3.499,
            ic_october: 3.971,
            ic_november: 5.021,
            ic_december: 5.658,
            ic_yearly: 4.11,
          },
          {
            ic_uuid: "18e5b9cb-4c36-4130-964c-bb9924fe6478",
            ic_lat: -231954,
            ic_lon: -51826,
            ic_city: "Iguaraçu",
            ic_states: "PARANÁ",
            ic_january: 5.98,
            ic_february: 5.915,
            ic_march: 5.354,
            ic_april: 4.621,
            ic_may: 3.68,
            ic_june: 3.362,
            ic_july: 3.557,
            ic_august: 4.506,
            ic_september: 4.698,
            ic_october: 5.426,
            ic_november: 6.094,
            ic_december: 6.395,
            ic_yearly: 4.966,
          },
          {
            ic_uuid: "c0f19318-9a23-4feb-b890-4264ed286003",
            ic_lat: -78359,
            ic_lon: -375134,
            ic_city: "Iguaracy",
            ic_states: "PERNAMBUCO",
            ic_january: 6.215,
            ic_february: 6.156,
            ic_march: 6.085,
            ic_april: 5.711,
            ic_may: 4.986,
            ic_june: 4.562,
            ic_july: 4.731,
            ic_august: 5.558,
            ic_september: 6.369,
            ic_october: 6.518,
            ic_november: 6.742,
            ic_december: 6.412,
            ic_yearly: 5.837,
          },
          {
            ic_uuid: "1eb1b2cd-5e0f-4d2f-8433-5a65d9cdb8d8",
            ic_lat: -201781,
            ic_lon: -457115,
            ic_city: "Iguatama",
            ic_states: "MINAS GERAIS",
            ic_january: 5.794,
            ic_february: 6.006,
            ic_march: 5.049,
            ic_april: 4.898,
            ic_may: 4.372,
            ic_june: 4.123,
            ic_july: 4.403,
            ic_august: 5.353,
            ic_september: 5.474,
            ic_october: 5.56,
            ic_november: 5.199,
            ic_december: 5.678,
            ic_yearly: 5.159,
          },
          {
            ic_uuid: "f512d0b7-523f-43b9-9cc3-e499c9aae4ce",
            ic_lat: -23674,
            ic_lon: -545642,
            ic_city: "Iguatemi",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 6.042,
            ic_february: 5.788,
            ic_march: 5.301,
            ic_april: 4.346,
            ic_may: 3.465,
            ic_june: 3.036,
            ic_july: 3.265,
            ic_august: 4.246,
            ic_september: 4.555,
            ic_october: 5.339,
            ic_november: 6.009,
            ic_december: 6.444,
            ic_yearly: 4.82,
          },
          {
            ic_uuid: "13856ba2-dcca-4fa7-86fd-d2fbdee6f1a7",
            ic_lat: -63632,
            ic_lon: -392895,
            ic_city: "Iguatu",
            ic_states: "CEARÁ",
            ic_january: 5.731,
            ic_february: 5.803,
            ic_march: 5.85,
            ic_april: 5.513,
            ic_may: 5.241,
            ic_june: 5.116,
            ic_july: 5.404,
            ic_august: 6.121,
            ic_september: 6.425,
            ic_october: 6.434,
            ic_november: 6.522,
            ic_december: 6.059,
            ic_yearly: 5.851,
          },
          {
            ic_uuid: "7b44fbee-eaf2-4d2d-9a84-7e98add28e7c",
            ic_lat: -247157,
            ic_lon: -530832,
            ic_city: "Iguatu",
            ic_states: "PARANÁ",
            ic_january: 6.258,
            ic_february: 5.605,
            ic_march: 5.296,
            ic_april: 4.413,
            ic_may: 3.478,
            ic_june: 3.034,
            ic_july: 3.24,
            ic_august: 4.241,
            ic_september: 4.477,
            ic_october: 5.274,
            ic_november: 6.106,
            ic_december: 6.447,
            ic_yearly: 4.822,
          },
          {
            ic_uuid: "2dcd7d45-d234-4a5b-a96d-3fec1f4d89f7",
            ic_lat: -211743,
            ic_lon: -449236,
            ic_city: "Ijaci",
            ic_states: "MINAS GERAIS",
            ic_january: 5.615,
            ic_february: 5.796,
            ic_march: 5.018,
            ic_april: 4.677,
            ic_may: 3.973,
            ic_june: 3.767,
            ic_july: 4.018,
            ic_august: 4.924,
            ic_september: 5.164,
            ic_october: 5.408,
            ic_november: 5.254,
            ic_december: 5.667,
            ic_yearly: 4.94,
          },
          {
            ic_uuid: "489f8734-4be3-485f-87e8-8ae35794559a",
            ic_lat: -283885,
            ic_lon: -539204,
            ic_city: "Ijuí",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.48,
            ic_february: 6.022,
            ic_march: 5.178,
            ic_april: 4.012,
            ic_may: 3.046,
            ic_june: 2.5,
            ic_july: 2.796,
            ic_august: 3.573,
            ic_september: 3.958,
            ic_october: 5.1,
            ic_november: 6.293,
            ic_december: 6.691,
            ic_yearly: 4.637,
          },
          {
            ic_uuid: "e69f4adf-1d7b-41bd-846e-048055d9e5de",
            ic_lat: -247364,
            ic_lon: -475401,
            ic_city: "Ilha Comprida",
            ic_states: "SÃO PAULO",
            ic_january: 5.558,
            ic_february: 5.549,
            ic_march: 4.592,
            ic_april: 3.933,
            ic_may: 3.067,
            ic_june: 2.616,
            ic_july: 2.595,
            ic_august: 3.259,
            ic_september: 3.499,
            ic_october: 3.971,
            ic_november: 5.021,
            ic_december: 5.658,
            ic_yearly: 4.11,
          },
          {
            ic_uuid: "bbe3f285-81d5-45fa-b16c-f1e8d097c1f8",
            ic_lat: -104429,
            ic_lon: -365482,
            ic_city: "Ilha das Flores",
            ic_states: "SERGIPE",
            ic_january: 6.234,
            ic_february: 6.106,
            ic_march: 6.017,
            ic_april: 5.075,
            ic_may: 4.31,
            ic_june: 4.037,
            ic_july: 4.135,
            ic_august: 4.758,
            ic_september: 5.534,
            ic_october: 6.008,
            ic_november: 6.388,
            ic_december: 6.412,
            ic_yearly: 5.418,
          },
          {
            ic_uuid: "7d6a0f8f-c790-4611-90a3-f1c0247b5826",
            ic_lat: -77481,
            ic_lon: -348306,
            ic_city: "Ilha de Itamaracá",
            ic_states: "PERNAMBUCO",
            ic_january: 5.881,
            ic_february: 6.127,
            ic_march: 6.088,
            ic_april: 5.337,
            ic_may: 4.694,
            ic_june: 4.347,
            ic_july: 4.489,
            ic_august: 5.245,
            ic_september: 5.698,
            ic_october: 6.071,
            ic_november: 6.207,
            ic_december: 6.2,
            ic_yearly: 5.532,
          },
          {
            ic_uuid: "c2590df8-b643-4748-85eb-afcd690efa25",
            ic_lat: -28581,
            ic_lon: -418189,
            ic_city: "Ilha Grande",
            ic_states: "PIAUÍ",
            ic_january: 5.613,
            ic_february: 5.462,
            ic_march: 5.333,
            ic_april: 5.002,
            ic_may: 5.196,
            ic_june: 5.336,
            ic_july: 5.509,
            ic_august: 6.089,
            ic_september: 6.093,
            ic_october: 6.05,
            ic_november: 6.366,
            ic_december: 6.08,
            ic_yearly: 5.677,
          },
          {
            ic_uuid: "4c77b47e-f64f-4a54-b7bd-bcf875dd6e00",
            ic_lat: -204331,
            ic_lon: -51343,
            ic_city: "Ilha Solteira",
            ic_states: "SÃO PAULO",
            ic_january: 5.859,
            ic_february: 6.029,
            ic_march: 5.494,
            ic_april: 4.949,
            ic_may: 4.169,
            ic_june: 3.83,
            ic_july: 4.024,
            ic_august: 4.975,
            ic_september: 4.905,
            ic_october: 5.599,
            ic_november: 5.98,
            ic_december: 6.348,
            ic_yearly: 5.18,
          },
          {
            ic_uuid: "be3293fb-ca83-4e75-80ba-ffebc1590db0",
            ic_lat: -23779,
            ic_lon: -453556,
            ic_city: "Ilhabela",
            ic_states: "SÃO PAULO",
            ic_january: 5.68,
            ic_february: 5.832,
            ic_march: 4.73,
            ic_april: 4.23,
            ic_may: 3.373,
            ic_june: 3.021,
            ic_july: 3.112,
            ic_august: 3.893,
            ic_september: 3.855,
            ic_october: 4.434,
            ic_november: 4.934,
            ic_december: 5.553,
            ic_yearly: 4.387,
          },
          {
            ic_uuid: "73ac1762-d8a2-413c-97d8-e8f314d2c60b",
            ic_lat: -147935,
            ic_lon: -390463,
            ic_city: "Ilhéus",
            ic_states: "BAHIA",
            ic_january: 6.203,
            ic_february: 6.202,
            ic_march: 5.758,
            ic_april: 4.745,
            ic_may: 4.058,
            ic_june: 3.722,
            ic_july: 3.969,
            ic_august: 4.557,
            ic_september: 5.254,
            ic_october: 5.548,
            ic_november: 5.73,
            ic_december: 6.423,
            ic_yearly: 5.181,
          },
          {
            ic_uuid: "82cf3f9d-2f2e-4797-b3ce-82ad1fadbc4b",
            ic_lat: -269028,
            ic_lon: -488255,
            ic_city: "Ilhota",
            ic_states: "SANTA CATARINA",
            ic_january: 5.457,
            ic_february: 5.19,
            ic_march: 4.498,
            ic_april: 3.619,
            ic_may: 2.991,
            ic_june: 2.479,
            ic_july: 2.619,
            ic_august: 3.228,
            ic_september: 3.354,
            ic_october: 3.846,
            ic_november: 5.018,
            ic_december: 5.451,
            ic_yearly: 3.979,
          },
          {
            ic_uuid: "65d63b27-77f7-464f-bd7b-4eb4932f1702",
            ic_lat: -209407,
            ic_lon: -458312,
            ic_city: "Ilicínea",
            ic_states: "MINAS GERAIS",
            ic_january: 5.509,
            ic_february: 5.856,
            ic_march: 4.941,
            ic_april: 4.716,
            ic_may: 4.138,
            ic_june: 3.925,
            ic_july: 4.18,
            ic_august: 5.094,
            ic_september: 5.202,
            ic_october: 5.481,
            ic_november: 5.255,
            ic_december: 5.635,
            ic_yearly: 4.994,
          },
          {
            ic_uuid: "b975804e-5d51-4626-a037-fb67ebb54352",
            ic_lat: -289287,
            ic_lon: -521263,
            ic_city: "Ilópolis",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.087,
            ic_february: 5.689,
            ic_march: 4.786,
            ic_april: 3.855,
            ic_may: 2.874,
            ic_june: 2.412,
            ic_july: 2.719,
            ic_august: 3.474,
            ic_september: 3.72,
            ic_october: 4.776,
            ic_november: 6.098,
            ic_december: 6.46,
            ic_yearly: 4.413,
          },
          {
            ic_uuid: "05201fbf-8a29-4e34-9a7e-1a0e7b804303",
            ic_lat: -73893,
            ic_lon: -375082,
            ic_city: "Imaculada",
            ic_states: "PARAÍBA",
            ic_january: 6.097,
            ic_february: 5.992,
            ic_march: 6.033,
            ic_april: 5.764,
            ic_may: 5.097,
            ic_june: 4.727,
            ic_july: 5.054,
            ic_august: 5.814,
            ic_september: 6.509,
            ic_october: 6.57,
            ic_november: 6.67,
            ic_december: 6.296,
            ic_yearly: 5.885,
          },
          {
            ic_uuid: "55103744-05d0-4581-af2f-2b422118edc2",
            ic_lat: -283344,
            ic_lon: -488174,
            ic_city: "Imaruí",
            ic_states: "SANTA CATARINA",
            ic_january: 5.77,
            ic_february: 5.332,
            ic_march: 4.544,
            ic_april: 3.752,
            ic_may: 2.98,
            ic_june: 2.51,
            ic_july: 2.732,
            ic_august: 3.464,
            ic_september: 3.587,
            ic_october: 4.345,
            ic_november: 5.68,
            ic_december: 6.082,
            ic_yearly: 4.231,
          },
          {
            ic_uuid: "928fa722-386a-440b-b8e6-271d76905f49",
            ic_lat: -244485,
            ic_lon: -507537,
            ic_city: "Imbaú",
            ic_states: "PARANÁ",
            ic_january: 5.703,
            ic_february: 5.517,
            ic_march: 5.043,
            ic_april: 4.375,
            ic_may: 3.419,
            ic_june: 3.008,
            ic_july: 3.317,
            ic_august: 4.316,
            ic_september: 4.453,
            ic_october: 5.055,
            ic_november: 5.856,
            ic_december: 6.096,
            ic_yearly: 4.68,
          },
          {
            ic_uuid: "e1286a46-5cf4-4b40-977a-63728b63f971",
            ic_lat: -299758,
            ic_lon: -501285,
            ic_city: "Imbé",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.36,
            ic_february: 5.728,
            ic_march: 4.757,
            ic_april: 3.851,
            ic_may: 2.861,
            ic_june: 2.371,
            ic_july: 2.54,
            ic_august: 3.202,
            ic_september: 3.592,
            ic_october: 4.694,
            ic_november: 6.129,
            ic_december: 6.578,
            ic_yearly: 4.389,
          },
          {
            ic_uuid: "23721119-86af-4dbf-a88f-36b63f3198ae",
            ic_lat: -196022,
            ic_lon: -419698,
            ic_city: "Imbé de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.693,
            ic_february: 5.861,
            ic_march: 4.909,
            ic_april: 4.498,
            ic_may: 3.736,
            ic_june: 3.533,
            ic_july: 3.721,
            ic_august: 4.371,
            ic_september: 4.728,
            ic_october: 4.864,
            ic_november: 4.722,
            ic_december: 5.478,
            ic_yearly: 4.676,
          },
          {
            ic_uuid: "28def0e7-7857-4ecd-a8ff-d0bf6fc36717",
            ic_lat: -282289,
            ic_lon: -486663,
            ic_city: "Imbituba",
            ic_states: "SANTA CATARINA",
            ic_january: 5.949,
            ic_february: 5.455,
            ic_march: 4.703,
            ic_april: 3.785,
            ic_may: 3.034,
            ic_june: 2.532,
            ic_july: 2.748,
            ic_august: 3.44,
            ic_september: 3.652,
            ic_october: 4.451,
            ic_november: 5.784,
            ic_december: 6.184,
            ic_yearly: 4.31,
          },
          {
            ic_uuid: "1b02bd87-1d40-4314-8136-386b23adec85",
            ic_lat: -25229,
            ic_lon: -505993,
            ic_city: "Imbituva",
            ic_states: "PARANÁ",
            ic_january: 5.61,
            ic_february: 5.403,
            ic_march: 4.787,
            ic_april: 4.001,
            ic_may: 3.165,
            ic_june: 2.795,
            ic_july: 3.056,
            ic_august: 4.055,
            ic_september: 4.186,
            ic_october: 4.785,
            ic_november: 5.634,
            ic_december: 5.873,
            ic_yearly: 4.446,
          },
          {
            ic_uuid: "d1bfdeb9-d417-4b42-8989-f995afb5b339",
            ic_lat: -274913,
            ic_lon: -494222,
            ic_city: "Imbuia",
            ic_states: "SANTA CATARINA",
            ic_january: 5.671,
            ic_february: 5.28,
            ic_march: 4.562,
            ic_april: 3.687,
            ic_may: 2.902,
            ic_june: 2.396,
            ic_july: 2.616,
            ic_august: 3.381,
            ic_september: 3.519,
            ic_october: 4.083,
            ic_november: 5.391,
            ic_december: 5.732,
            ic_yearly: 4.102,
          },
          {
            ic_uuid: "6379229e-9ba2-4508-860e-46fa30bda7fd",
            ic_lat: -293513,
            ic_lon: -517752,
            ic_city: "Imigrante",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.212,
            ic_february: 5.714,
            ic_march: 4.808,
            ic_april: 3.775,
            ic_may: 2.807,
            ic_june: 2.334,
            ic_july: 2.589,
            ic_august: 3.298,
            ic_september: 3.567,
            ic_october: 4.643,
            ic_november: 6.103,
            ic_december: 6.45,
            ic_yearly: 4.358,
          },
          {
            ic_uuid: "d2d7a166-6ac7-4a2b-a3d6-ae1fbae7b06e",
            ic_lat: -55189,
            ic_lon: -474781,
            ic_city: "Imperatriz",
            ic_states: "MARANHÃO",
            ic_january: 4.64,
            ic_february: 4.714,
            ic_march: 4.798,
            ic_april: 4.838,
            ic_may: 4.785,
            ic_june: 5.136,
            ic_july: 5.255,
            ic_august: 5.711,
            ic_september: 5.587,
            ic_october: 5.186,
            ic_november: 4.913,
            ic_december: 4.746,
            ic_yearly: 5.025,
          },
          {
            ic_uuid: "a2d07f9e-72ea-4952-ae88-c2a45bbae6f9",
            ic_lat: -255709,
            ic_lon: -510774,
            ic_city: "Inácio Martins",
            ic_states: "PARANÁ",
            ic_january: 5.348,
            ic_february: 5.065,
            ic_march: 4.528,
            ic_april: 3.763,
            ic_may: 3.012,
            ic_june: 2.687,
            ic_july: 2.923,
            ic_august: 3.956,
            ic_september: 4.032,
            ic_october: 4.567,
            ic_november: 5.461,
            ic_december: 5.581,
            ic_yearly: 4.244,
          },
          {
            ic_uuid: "a3a10b46-59d6-483b-b40d-a9781eeb1825",
            ic_lat: -184874,
            ic_lon: -499892,
            ic_city: "Inaciolândia",
            ic_states: "GOIÁS",
            ic_january: 5.801,
            ic_february: 5.746,
            ic_march: 5.254,
            ic_april: 5.032,
            ic_may: 4.562,
            ic_june: 4.294,
            ic_july: 4.508,
            ic_august: 5.437,
            ic_september: 5.309,
            ic_october: 5.579,
            ic_november: 5.767,
            ic_december: 5.954,
            ic_yearly: 5.27,
          },
          {
            ic_uuid: "47046764-b898-416a-9164-d9146b871d9c",
            ic_lat: -227514,
            ic_lon: -521999,
            ic_city: "Inajá",
            ic_states: "PARANÁ",
            ic_january: 5.973,
            ic_february: 5.966,
            ic_march: 5.449,
            ic_april: 4.687,
            ic_may: 3.745,
            ic_june: 3.422,
            ic_july: 3.609,
            ic_august: 4.555,
            ic_september: 4.76,
            ic_october: 5.48,
            ic_november: 6.196,
            ic_december: 6.454,
            ic_yearly: 5.025,
          },
          {
            ic_uuid: "bdf6c290-7ba4-472f-a793-626de11c573d",
            ic_lat: -89,
            ic_lon: -378303,
            ic_city: "Inajá",
            ic_states: "PERNAMBUCO",
            ic_january: 6.423,
            ic_february: 6.097,
            ic_march: 6.125,
            ic_april: 5.488,
            ic_may: 4.549,
            ic_june: 4.042,
            ic_july: 4.245,
            ic_august: 4.968,
            ic_september: 5.946,
            ic_october: 6.323,
            ic_november: 6.689,
            ic_december: 6.565,
            ic_yearly: 5.622,
          },
          {
            ic_uuid: "c64855aa-2373-494e-a6c2-a6ae92425029",
            ic_lat: -22314,
            ic_lon: -463268,
            ic_city: "Inconfidentes",
            ic_states: "MINAS GERAIS",
            ic_january: 5.3,
            ic_february: 5.567,
            ic_march: 4.909,
            ic_april: 4.616,
            ic_may: 3.906,
            ic_june: 3.648,
            ic_july: 3.879,
            ic_august: 4.733,
            ic_september: 4.951,
            ic_october: 5.393,
            ic_november: 5.475,
            ic_december: 5.686,
            ic_yearly: 4.838,
          },
          {
            ic_uuid: "7b4af704-53e2-41bb-a590-8b310bc34dc9",
            ic_lat: -154923,
            ic_lon: -421977,
            ic_city: "Indaiabira",
            ic_states: "MINAS GERAIS",
            ic_january: 6.045,
            ic_february: 6.304,
            ic_march: 5.443,
            ic_april: 4.755,
            ic_may: 4.165,
            ic_june: 3.822,
            ic_july: 4.122,
            ic_august: 4.856,
            ic_september: 5.669,
            ic_october: 5.723,
            ic_november: 5.351,
            ic_december: 6.076,
            ic_yearly: 5.194,
          },
          {
            ic_uuid: "5c0d327b-94c1-4e6a-ad08-847ef4af1456",
            ic_lat: -268997,
            ic_lon: -492358,
            ic_city: "Indaial",
            ic_states: "SANTA CATARINA",
            ic_january: 5.516,
            ic_february: 5.207,
            ic_march: 4.577,
            ic_april: 3.719,
            ic_may: 3.014,
            ic_june: 2.478,
            ic_july: 2.617,
            ic_august: 3.312,
            ic_september: 3.394,
            ic_october: 3.883,
            ic_november: 5.1,
            ic_december: 5.49,
            ic_yearly: 4.026,
          },
          {
            ic_uuid: "3c240fdf-8a55-46d7-ab4e-8517fad13d4f",
            ic_lat: -230821,
            ic_lon: -472105,
            ic_city: "Indaiatuba",
            ic_states: "SÃO PAULO",
            ic_january: 5.681,
            ic_february: 5.828,
            ic_march: 5.09,
            ic_april: 4.601,
            ic_may: 3.713,
            ic_june: 3.456,
            ic_july: 3.625,
            ic_august: 4.56,
            ic_september: 4.755,
            ic_october: 5.394,
            ic_november: 5.703,
            ic_december: 6.209,
            ic_yearly: 4.884,
          },
          {
            ic_uuid: "64ac15b1-8913-4b7f-b52d-80b3e7d86999",
            ic_lat: -53883,
            ic_lon: -403089,
            ic_city: "Independência",
            ic_states: "CEARÁ",
            ic_january: 5.338,
            ic_february: 5.386,
            ic_march: 5.48,
            ic_april: 5.107,
            ic_may: 5.042,
            ic_june: 5.058,
            ic_july: 5.39,
            ic_august: 6.128,
            ic_september: 6.567,
            ic_october: 6.497,
            ic_november: 6.302,
            ic_december: 5.764,
            ic_yearly: 5.672,
          },
          {
            ic_uuid: "77c4f092-912c-45f1-92be-8d549f610ae1",
            ic_lat: -278359,
            ic_lon: -541891,
            ic_city: "Independência",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.522,
            ic_february: 6.042,
            ic_march: 5.147,
            ic_april: 4.087,
            ic_may: 3.11,
            ic_june: 2.567,
            ic_july: 2.871,
            ic_august: 3.668,
            ic_september: 4.003,
            ic_october: 5.21,
            ic_november: 6.281,
            ic_december: 6.697,
            ic_yearly: 4.684,
          },
          {
            ic_uuid: "10897e0e-a238-488c-b260-5d7ae3e8cb11",
            ic_lat: -221742,
            ic_lon: -512559,
            ic_city: "Indiana",
            ic_states: "SÃO PAULO",
            ic_january: 5.986,
            ic_february: 5.957,
            ic_march: 5.433,
            ic_april: 4.786,
            ic_may: 3.899,
            ic_june: 3.607,
            ic_july: 3.776,
            ic_august: 4.772,
            ic_september: 4.853,
            ic_october: 5.524,
            ic_november: 6.098,
            ic_december: 6.447,
            ic_yearly: 5.095,
          },
          {
            ic_uuid: "0dd5540b-ce89-40f5-a6af-68b1e330512f",
            ic_lat: -190373,
            ic_lon: -479185,
            ic_city: "Indianópolis",
            ic_states: "MINAS GERAIS",
            ic_january: 5.487,
            ic_february: 5.873,
            ic_march: 5.011,
            ic_april: 4.911,
            ic_may: 4.495,
            ic_june: 4.292,
            ic_july: 4.514,
            ic_august: 5.501,
            ic_september: 5.435,
            ic_october: 5.597,
            ic_november: 5.528,
            ic_december: 5.722,
            ic_yearly: 5.197,
          },
          {
            ic_uuid: "32f5f91a-fd44-47dc-aec0-f0bf13b71973",
            ic_lat: -234767,
            ic_lon: -526993,
            ic_city: "Indianópolis",
            ic_states: "PARANÁ",
            ic_january: 6.013,
            ic_february: 5.879,
            ic_march: 5.434,
            ic_april: 4.541,
            ic_may: 3.607,
            ic_june: 3.247,
            ic_july: 3.438,
            ic_august: 4.418,
            ic_september: 4.624,
            ic_october: 5.398,
            ic_november: 6.14,
            ic_december: 6.464,
            ic_yearly: 4.934,
          },
          {
            ic_uuid: "e4d887ae-3d87-46b0-961a-0c9b1eec5fb9",
            ic_lat: -199794,
            ic_lon: -502913,
            ic_city: "Indiaporã",
            ic_states: "SÃO PAULO",
            ic_january: 5.797,
            ic_february: 6.024,
            ic_march: 5.355,
            ic_april: 4.962,
            ic_may: 4.266,
            ic_june: 3.95,
            ic_july: 4.203,
            ic_august: 5.054,
            ic_september: 5.099,
            ic_october: 5.684,
            ic_november: 5.927,
            ic_december: 6.192,
            ic_yearly: 5.21,
          },
          {
            ic_uuid: "61e0d1b1-10d6-4504-a92f-0d4d03c8a878",
            ic_lat: -171392,
            ic_lon: -499866,
            ic_city: "Indiara",
            ic_states: "GOIÁS",
            ic_january: 5.637,
            ic_february: 5.797,
            ic_march: 5.367,
            ic_april: 5.141,
            ic_may: 4.762,
            ic_june: 4.435,
            ic_july: 4.661,
            ic_august: 5.526,
            ic_september: 5.438,
            ic_october: 5.58,
            ic_november: 5.503,
            ic_december: 5.585,
            ic_yearly: 5.286,
          },
          {
            ic_uuid: "ec6b15a4-a01e-4bb1-b4b3-192346e30ac1",
            ic_lat: -115185,
            ic_lon: -375104,
            ic_city: "Indiaroba",
            ic_states: "SERGIPE",
            ic_january: 6.307,
            ic_february: 6.131,
            ic_march: 5.993,
            ic_april: 4.971,
            ic_may: 4.216,
            ic_june: 3.947,
            ic_july: 4.081,
            ic_august: 4.549,
            ic_september: 5.332,
            ic_october: 5.723,
            ic_november: 6.168,
            ic_december: 6.407,
            ic_yearly: 5.319,
          },
          {
            ic_uuid: "f037a57e-653f-4953-b7ad-baa6315c9307",
            ic_lat: -154962,
            ic_lon: -585787,
            ic_city: "Indiavaí",
            ic_states: "MATO GROSSO",
            ic_january: 5.254,
            ic_february: 5.091,
            ic_march: 4.917,
            ic_april: 4.697,
            ic_may: 4.069,
            ic_june: 4.175,
            ic_july: 4.258,
            ic_august: 5.058,
            ic_september: 4.99,
            ic_october: 5.247,
            ic_november: 5.465,
            ic_december: 5.416,
            ic_yearly: 4.886,
          },
          {
            ic_uuid: "977f10a4-8e43-49fe-a120-ecdda5993198",
            ic_lat: -72912,
            ic_lon: -356108,
            ic_city: "Ingá",
            ic_states: "PARAÍBA",
            ic_january: 5.599,
            ic_february: 5.68,
            ic_march: 5.757,
            ic_april: 5.244,
            ic_may: 4.643,
            ic_june: 4.136,
            ic_july: 4.228,
            ic_august: 4.888,
            ic_september: 5.437,
            ic_october: 5.72,
            ic_november: 6.053,
            ic_december: 5.824,
            ic_yearly: 5.267,
          },
          {
            ic_uuid: "63fd2e10-e5f8-4215-b04c-d237a3712db1",
            ic_lat: -214029,
            ic_lon: -449155,
            ic_city: "Ingaí",
            ic_states: "MINAS GERAIS",
            ic_january: 5.56,
            ic_february: 5.751,
            ic_march: 4.918,
            ic_april: 4.618,
            ic_may: 3.931,
            ic_june: 3.749,
            ic_july: 3.996,
            ic_august: 4.885,
            ic_september: 5.094,
            ic_october: 5.393,
            ic_november: 5.246,
            ic_december: 5.642,
            ic_yearly: 4.899,
          },
          {
            ic_uuid: "1d1186cd-16b7-41d0-86d5-1a6d46400756",
            ic_lat: -76777,
            ic_lon: -374598,
            ic_city: "Ingazeira",
            ic_states: "PERNAMBUCO",
            ic_january: 6.182,
            ic_february: 6.136,
            ic_march: 6.118,
            ic_april: 5.728,
            ic_may: 4.952,
            ic_june: 4.507,
            ic_july: 4.679,
            ic_august: 5.51,
            ic_september: 6.343,
            ic_october: 6.506,
            ic_november: 6.734,
            ic_december: 6.366,
            ic_yearly: 5.813,
          },
          {
            ic_uuid: "b56a50a9-0f89-4522-a533-af04e387f7a0",
            ic_lat: -278757,
            ic_lon: -540154,
            ic_city: "Inhacorá",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.488,
            ic_february: 6.02,
            ic_march: 5.166,
            ic_april: 4.087,
            ic_may: 3.1,
            ic_june: 2.563,
            ic_july: 2.866,
            ic_august: 3.677,
            ic_september: 4.004,
            ic_october: 5.174,
            ic_november: 6.26,
            ic_december: 6.715,
            ic_yearly: 4.677,
          },
          {
            ic_uuid: "7a64b31b-b309-43c3-bd06-c6bcd12213ef",
            ic_lat: -117911,
            ic_lon: -383558,
            ic_city: "Inhambupe",
            ic_states: "BAHIA",
            ic_january: 6.05,
            ic_february: 5.815,
            ic_march: 5.682,
            ic_april: 4.788,
            ic_may: 4.111,
            ic_june: 3.805,
            ic_july: 4.004,
            ic_august: 4.485,
            ic_september: 5.277,
            ic_october: 5.512,
            ic_november: 5.869,
            ic_december: 6.089,
            ic_yearly: 5.124,
          },
          {
            ic_uuid: "72aad495-4060-4aba-ab16-352f73e78c91",
            ic_lat: -14293,
            ic_lon: -479086,
            ic_city: "Inhangapi",
            ic_states: "PARÁ",
            ic_january: 4.373,
            ic_february: 4.277,
            ic_march: 4.312,
            ic_april: 4.313,
            ic_may: 4.598,
            ic_june: 4.881,
            ic_july: 4.996,
            ic_august: 5.293,
            ic_september: 5.392,
            ic_october: 5.206,
            ic_november: 4.96,
            ic_december: 4.529,
            ic_yearly: 4.761,
          },
          {
            ic_uuid: "f3ee8c44-e239-40fc-85f7-2ce03c77da08",
            ic_lat: -92264,
            ic_lon: -377512,
            ic_city: "Inhapi",
            ic_states: "ALAGOAS",
            ic_january: 6.36,
            ic_february: 6.124,
            ic_march: 6.057,
            ic_april: 5.343,
            ic_may: 4.446,
            ic_june: 3.947,
            ic_july: 4.116,
            ic_august: 4.842,
            ic_september: 5.757,
            ic_october: 6.175,
            ic_november: 6.67,
            ic_december: 6.47,
            ic_yearly: 5.525,
          },
          {
            ic_uuid: "d604c2a1-923a-44a7-96aa-ecf28e349f2c",
            ic_lat: -195473,
            ic_lon: -421176,
            ic_city: "Inhapim",
            ic_states: "MINAS GERAIS",
            ic_january: 5.749,
            ic_february: 5.999,
            ic_march: 5.046,
            ic_april: 4.468,
            ic_may: 3.811,
            ic_june: 3.572,
            ic_july: 3.712,
            ic_august: 4.382,
            ic_september: 4.779,
            ic_october: 4.958,
            ic_november: 4.775,
            ic_december: 5.676,
            ic_yearly: 4.744,
          },
          {
            ic_uuid: "50b49828-8602-4e50-8784-3444cb504ed8",
            ic_lat: -194903,
            ic_lon: -443938,
            ic_city: "Inhaúma",
            ic_states: "MINAS GERAIS",
            ic_january: 5.976,
            ic_february: 6.136,
            ic_march: 5.202,
            ic_april: 4.933,
            ic_may: 4.37,
            ic_june: 4.162,
            ic_july: 4.481,
            ic_august: 5.314,
            ic_september: 5.644,
            ic_october: 5.656,
            ic_november: 5.412,
            ic_december: 5.715,
            ic_yearly: 5.25,
          },
          {
            ic_uuid: "71ce0652-7938-4636-b8e9-27f2fba5af33",
            ic_lat: -6668,
            ic_lon: -417096,
            ic_city: "Inhuma",
            ic_states: "PIAUÍ",
            ic_january: 5.225,
            ic_february: 5.277,
            ic_march: 5.186,
            ic_april: 5.149,
            ic_may: 5.262,
            ic_june: 5.165,
            ic_july: 5.608,
            ic_august: 6.221,
            ic_september: 6.661,
            ic_october: 6.435,
            ic_november: 6.164,
            ic_december: 5.758,
            ic_yearly: 5.676,
          },
          {
            ic_uuid: "33b9ee39-52cc-4781-82d6-5bacf4161f51",
            ic_lat: -163615,
            ic_lon: -495005,
            ic_city: "Inhumas",
            ic_states: "GOIÁS",
            ic_january: 5.46,
            ic_february: 5.528,
            ic_march: 5.15,
            ic_april: 5.112,
            ic_may: 4.871,
            ic_june: 4.559,
            ic_july: 4.829,
            ic_august: 5.782,
            ic_september: 5.497,
            ic_october: 5.521,
            ic_november: 5.25,
            ic_december: 5.421,
            ic_yearly: 5.248,
          },
          {
            ic_uuid: "cedb7995-4b93-4f08-ba4c-70ca3b228385",
            ic_lat: -18729,
            ic_lon: -44361,
            ic_city: "Inimutaba",
            ic_states: "MINAS GERAIS",
            ic_january: 6.128,
            ic_february: 6.375,
            ic_march: 5.504,
            ic_april: 5.262,
            ic_may: 4.62,
            ic_june: 4.233,
            ic_july: 4.666,
            ic_august: 5.309,
            ic_september: 5.798,
            ic_october: 5.87,
            ic_november: 5.547,
            ic_december: 5.938,
            ic_yearly: 5.437,
          },
          {
            ic_uuid: "552bb949-526c-405c-b8b5-c5925593b884",
            ic_lat: -197282,
            ic_lon: -519285,
            ic_city: "Inocência",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.667,
            ic_february: 5.844,
            ic_march: 5.345,
            ic_april: 4.958,
            ic_may: 4.307,
            ic_june: 4.028,
            ic_july: 4.202,
            ic_august: 5.096,
            ic_september: 5.099,
            ic_october: 5.488,
            ic_november: 5.818,
            ic_december: 6.176,
            ic_yearly: 5.169,
          },
          {
            ic_uuid: "98179414-2e9c-4366-b921-964acfb235c1",
            ic_lat: -2177,
            ic_lon: -509619,
            ic_city: "Inúbia Paulista",
            ic_states: "SÃO PAULO",
            ic_january: 5.939,
            ic_february: 5.943,
            ic_march: 5.413,
            ic_april: 4.819,
            ic_may: 3.982,
            ic_june: 3.674,
            ic_july: 3.849,
            ic_august: 4.833,
            ic_september: 4.872,
            ic_october: 5.559,
            ic_november: 6.057,
            ic_december: 6.321,
            ic_yearly: 5.105,
          },
          {
            ic_uuid: "f7c17c76-8a0d-4e84-a397-207056e8f4f3",
            ic_lat: -270024,
            ic_lon: -512447,
            ic_city: "Iomerê",
            ic_states: "SANTA CATARINA",
            ic_january: 5.757,
            ic_february: 5.353,
            ic_march: 4.845,
            ic_april: 3.944,
            ic_may: 3.015,
            ic_june: 2.648,
            ic_july: 2.893,
            ic_august: 3.782,
            ic_september: 3.959,
            ic_october: 4.766,
            ic_november: 5.898,
            ic_december: 6.087,
            ic_yearly: 4.412,
          },
          {
            ic_uuid: "e3e63525-bbef-4a61-ad8f-d251668ff948",
            ic_lat: -19414,
            ic_lon: -42419,
            ic_city: "Ipaba",
            ic_states: "MINAS GERAIS",
            ic_january: 5.959,
            ic_february: 6.22,
            ic_march: 5.177,
            ic_april: 4.712,
            ic_may: 3.951,
            ic_june: 3.731,
            ic_july: 3.929,
            ic_august: 4.61,
            ic_september: 4.966,
            ic_october: 5.137,
            ic_november: 4.913,
            ic_december: 5.692,
            ic_yearly: 4.916,
          },
          {
            ic_uuid: "641e6b85-15bd-4af2-9f21-fe4bd6d96ee2",
            ic_lat: -17722,
            ic_lon: -481585,
            ic_city: "Ipameri",
            ic_states: "GOIÁS",
            ic_january: 5.704,
            ic_february: 5.794,
            ic_march: 5.142,
            ic_april: 5.112,
            ic_may: 4.709,
            ic_june: 4.458,
            ic_july: 4.719,
            ic_august: 5.629,
            ic_september: 5.409,
            ic_october: 5.612,
            ic_november: 5.427,
            ic_december: 5.68,
            ic_yearly: 5.283,
          },
          {
            ic_uuid: "5a105825-920e-4a2c-afe2-c8883649921f",
            ic_lat: -197996,
            ic_lon: -417168,
            ic_city: "Ipanema",
            ic_states: "MINAS GERAIS",
            ic_january: 5.779,
            ic_february: 6.016,
            ic_march: 5.023,
            ic_april: 4.644,
            ic_may: 3.85,
            ic_june: 3.588,
            ic_july: 3.758,
            ic_august: 4.405,
            ic_september: 4.694,
            ic_october: 4.842,
            ic_november: 4.749,
            ic_december: 5.513,
            ic_yearly: 4.739,
          },
          {
            ic_uuid: "38ee46e9-afea-4602-b521-14545e225ed2",
            ic_lat: -55092,
            ic_lon: -368586,
            ic_city: "Ipanguaçu",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.908,
            ic_february: 5.968,
            ic_march: 5.951,
            ic_april: 5.611,
            ic_may: 5.316,
            ic_june: 4.843,
            ic_july: 5.109,
            ic_august: 5.853,
            ic_september: 6.275,
            ic_october: 6.413,
            ic_november: 6.41,
            ic_december: 6.038,
            ic_yearly: 5.808,
          },
          {
            ic_uuid: "3490941e-7afb-4f7a-b1f3-36d19e6855a5",
            ic_lat: -4898,
            ic_lon: -40754,
            ic_city: "Ipaporanga",
            ic_states: "CEARÁ",
            ic_january: 5.04,
            ic_february: 5.258,
            ic_march: 5.381,
            ic_april: 4.901,
            ic_may: 4.924,
            ic_june: 4.835,
            ic_july: 5.29,
            ic_august: 5.988,
            ic_september: 6.52,
            ic_october: 6.45,
            ic_november: 6.266,
            ic_december: 5.546,
            ic_yearly: 5.533,
          },
          {
            ic_uuid: "15f5843f-2070-4e67-8cae-37187483ec69",
            ic_lat: -194707,
            ic_lon: -42548,
            ic_city: "Ipatinga",
            ic_states: "MINAS GERAIS",
            ic_january: 5.784,
            ic_february: 6.144,
            ic_march: 5.085,
            ic_april: 4.612,
            ic_may: 3.875,
            ic_june: 3.695,
            ic_july: 3.898,
            ic_august: 4.548,
            ic_september: 4.949,
            ic_october: 5.118,
            ic_november: 4.818,
            ic_december: 5.602,
            ic_yearly: 4.844,
          },
          {
            ic_uuid: "5a936ca9-3e95-4c3c-bc7e-30eb84c32432",
            ic_lat: -67831,
            ic_lon: -387182,
            ic_city: "Ipaumirim",
            ic_states: "CEARÁ",
            ic_january: 6.042,
            ic_february: 6.059,
            ic_march: 5.995,
            ic_april: 5.715,
            ic_may: 5.253,
            ic_june: 5.03,
            ic_july: 5.325,
            ic_august: 6.057,
            ic_september: 6.506,
            ic_october: 6.573,
            ic_november: 6.709,
            ic_december: 6.321,
            ic_yearly: 5.965,
          },
          {
            ic_uuid: "51c2dd43-2e2a-46d6-a88c-fc414210a28c",
            ic_lat: -23057,
            ic_lon: -496259,
            ic_city: "Ipaussu",
            ic_states: "SÃO PAULO",
            ic_january: 5.64,
            ic_february: 5.802,
            ic_march: 5.233,
            ic_april: 4.663,
            ic_may: 3.68,
            ic_june: 3.381,
            ic_july: 3.577,
            ic_august: 4.567,
            ic_september: 4.687,
            ic_october: 5.381,
            ic_november: 5.892,
            ic_december: 6.286,
            ic_yearly: 4.899,
          },
          {
            ic_uuid: "a35a8e5a-2bbb-45b7-bd5a-9f4abf15496c",
            ic_lat: -288176,
            ic_lon: -512864,
            ic_city: "Ipê",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.063,
            ic_february: 5.697,
            ic_march: 4.842,
            ic_april: 3.872,
            ic_may: 2.888,
            ic_june: 2.457,
            ic_july: 2.76,
            ic_august: 3.497,
            ic_september: 3.736,
            ic_october: 4.84,
            ic_november: 6.178,
            ic_december: 6.468,
            ic_yearly: 4.442,
          },
          {
            ic_uuid: "d0b87573-4f67-4e3c-b728-6382686ea3f0",
            ic_lat: -123157,
            ic_lon: -393084,
            ic_city: "Ipecaetá",
            ic_states: "BAHIA",
            ic_january: 5.945,
            ic_february: 5.93,
            ic_march: 5.745,
            ic_april: 4.699,
            ic_may: 4.002,
            ic_june: 3.657,
            ic_july: 3.872,
            ic_august: 4.452,
            ic_september: 5.218,
            ic_october: 5.432,
            ic_november: 5.664,
            ic_december: 5.987,
            ic_yearly: 5.05,
          },
          {
            ic_uuid: "7f51cdc4-a101-4223-a5c8-1fa86404f4ee",
            ic_lat: -233518,
            ic_lon: -476931,
            ic_city: "Iperó",
            ic_states: "SÃO PAULO",
            ic_january: 5.553,
            ic_february: 5.787,
            ic_march: 5.087,
            ic_april: 4.515,
            ic_may: 3.652,
            ic_june: 3.393,
            ic_july: 3.49,
            ic_august: 4.499,
            ic_september: 4.668,
            ic_october: 5.246,
            ic_november: 5.655,
            ic_december: 6.123,
            ic_yearly: 4.806,
          },
          {
            ic_uuid: "d629cbc8-6c50-40a8-a90c-aa2e0485a3ed",
            ic_lat: -22436,
            ic_lon: -477155,
            ic_city: "Ipeúna",
            ic_states: "SÃO PAULO",
            ic_january: 5.47,
            ic_february: 5.685,
            ic_march: 5.044,
            ic_april: 4.644,
            ic_may: 3.835,
            ic_june: 3.589,
            ic_july: 3.761,
            ic_august: 4.747,
            ic_september: 4.827,
            ic_october: 5.42,
            ic_november: 5.676,
            ic_december: 5.934,
            ic_yearly: 4.886,
          },
          {
            ic_uuid: "44d8c7a7-1ad3-48c8-8569-6846ffaa24b7",
            ic_lat: -186932,
            ic_lon: -49944,
            ic_city: "Ipiaçu",
            ic_states: "MINAS GERAIS",
            ic_january: 5.94,
            ic_february: 5.846,
            ic_march: 5.343,
            ic_april: 5.125,
            ic_may: 4.547,
            ic_june: 4.22,
            ic_july: 4.39,
            ic_august: 5.286,
            ic_september: 5.267,
            ic_october: 5.621,
            ic_november: 5.908,
            ic_december: 6.072,
            ic_yearly: 5.297,
          },
          {
            ic_uuid: "b53f02ec-f87f-4efb-a121-31bf3c6865a2",
            ic_lat: -141333,
            ic_lon: -397373,
            ic_city: "Ipiaú",
            ic_states: "BAHIA",
            ic_january: 5.868,
            ic_february: 5.934,
            ic_march: 5.519,
            ic_april: 4.552,
            ic_may: 3.893,
            ic_june: 3.514,
            ic_july: 3.731,
            ic_august: 4.25,
            ic_september: 4.979,
            ic_october: 5.166,
            ic_november: 5.281,
            ic_december: 5.933,
            ic_yearly: 4.885,
          },
          {
            ic_uuid: "002274ba-c425-4544-a091-69dfad00c053",
            ic_lat: -206562,
            ic_lon: -493846,
            ic_city: "Ipiguá",
            ic_states: "SÃO PAULO",
            ic_january: 5.773,
            ic_february: 5.872,
            ic_march: 5.201,
            ic_april: 4.881,
            ic_may: 4.136,
            ic_june: 3.934,
            ic_july: 4.15,
            ic_august: 5.079,
            ic_september: 5.086,
            ic_october: 5.605,
            ic_november: 5.931,
            ic_december: 6.139,
            ic_yearly: 5.149,
          },
          {
            ic_uuid: "67b4c6e6-f48d-4875-af2d-3bb828b01ff3",
            ic_lat: -274043,
            ic_lon: -517762,
            ic_city: "Ipira",
            ic_states: "SANTA CATARINA",
            ic_january: 6.161,
            ic_february: 5.677,
            ic_march: 5.087,
            ic_april: 4.082,
            ic_may: 3.078,
            ic_june: 2.618,
            ic_july: 2.919,
            ic_august: 3.742,
            ic_september: 3.984,
            ic_october: 5.041,
            ic_november: 6.236,
            ic_december: 6.506,
            ic_yearly: 4.594,
          },
          {
            ic_uuid: "7ad480df-dee9-466f-8435-3d52f40436cf",
            ic_lat: -121565,
            ic_lon: -397363,
            ic_city: "Ipirá",
            ic_states: "BAHIA",
            ic_january: 6.021,
            ic_february: 5.902,
            ic_march: 5.735,
            ic_april: 4.647,
            ic_may: 3.979,
            ic_june: 3.722,
            ic_july: 3.868,
            ic_august: 4.465,
            ic_september: 5.286,
            ic_october: 5.443,
            ic_november: 5.69,
            ic_december: 5.923,
            ic_yearly: 5.057,
          },
          {
            ic_uuid: "02481f7a-47d9-4644-8e09-9278092fefde",
            ic_lat: -250242,
            ic_lon: -505798,
            ic_city: "Ipiranga",
            ic_states: "PARANÁ",
            ic_january: 5.67,
            ic_february: 5.424,
            ic_march: 4.873,
            ic_april: 4.116,
            ic_may: 3.278,
            ic_june: 2.88,
            ic_july: 3.131,
            ic_august: 4.115,
            ic_september: 4.28,
            ic_october: 4.9,
            ic_november: 5.711,
            ic_december: 5.901,
            ic_yearly: 4.523,
          },
          {
            ic_uuid: "65cf1177-814d-40a2-8014-8992521ca1ae",
            ic_lat: -151732,
            ic_lon: -496721,
            ic_city: "Ipiranga de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.303,
            ic_february: 5.538,
            ic_march: 5.176,
            ic_april: 5.132,
            ic_may: 4.947,
            ic_june: 4.626,
            ic_july: 4.831,
            ic_august: 5.697,
            ic_september: 5.563,
            ic_october: 5.53,
            ic_november: 5.196,
            ic_december: 5.325,
            ic_yearly: 5.239,
          },
          {
            ic_uuid: "cdebc17c-803a-4071-8e54-d03f5a9969f9",
            ic_lat: -122406,
            ic_lon: -561464,
            ic_city: "Ipiranga do Norte",
            ic_states: "MATO GROSSO",
            ic_january: 5.131,
            ic_february: 4.988,
            ic_march: 4.851,
            ic_april: 4.797,
            ic_may: 4.664,
            ic_june: 4.554,
            ic_july: 4.733,
            ic_august: 5.222,
            ic_september: 5.064,
            ic_october: 5.238,
            ic_november: 5.072,
            ic_december: 5.237,
            ic_yearly: 4.963,
          },
          {
            ic_uuid: "abf096e8-699b-4d7a-9759-1651d546e196",
            ic_lat: -68246,
            ic_lon: -417384,
            ic_city: "Ipiranga do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.186,
            ic_february: 5.226,
            ic_march: 5.214,
            ic_april: 5.19,
            ic_may: 5.237,
            ic_june: 5.147,
            ic_july: 5.621,
            ic_august: 6.233,
            ic_september: 6.687,
            ic_october: 6.403,
            ic_november: 6.155,
            ic_december: 5.712,
            ic_yearly: 5.668,
          },
          {
            ic_uuid: "6ad3eaf6-895d-408a-81d2-248bb2640593",
            ic_lat: -279408,
            ic_lon: -524276,
            ic_city: "Ipiranga do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.276,
            ic_february: 5.84,
            ic_march: 4.967,
            ic_april: 4.06,
            ic_may: 3.076,
            ic_june: 2.58,
            ic_july: 2.917,
            ic_august: 3.687,
            ic_september: 3.91,
            ic_october: 5.052,
            ic_november: 6.135,
            ic_december: 6.541,
            ic_yearly: 4.587,
          },
          {
            ic_uuid: "e94cadb6-ff51-4078-aa24-778839f7363f",
            ic_lat: -7051,
            ic_lon: -716895,
            ic_city: "Ipixuna",
            ic_states: "AMAZONAS",
            ic_january: 4.526,
            ic_february: 4.821,
            ic_march: 4.283,
            ic_april: 4.41,
            ic_may: 4.168,
            ic_june: 4.1,
            ic_july: 4.356,
            ic_august: 5.052,
            ic_september: 5.164,
            ic_october: 4.98,
            ic_november: 4.824,
            ic_december: 4.577,
            ic_yearly: 4.605,
          },
          {
            ic_uuid: "dd68fea0-32be-430a-bf45-8113b8fe6a21",
            ic_lat: -25562,
            ic_lon: -475006,
            ic_city: "Ipixuna do Pará",
            ic_states: "PARÁ",
            ic_january: 4.576,
            ic_february: 4.619,
            ic_march: 4.584,
            ic_april: 4.654,
            ic_may: 4.722,
            ic_june: 4.938,
            ic_july: 4.997,
            ic_august: 5.344,
            ic_september: 5.373,
            ic_october: 5.237,
            ic_november: 4.904,
            ic_december: 4.656,
            ic_yearly: 4.884,
          },
          {
            ic_uuid: "9cf870b7-3897-4100-9a92-ca508a7db4eb",
            ic_lat: -83935,
            ic_lon: -350612,
            ic_city: "Ipojuca",
            ic_states: "PERNAMBUCO",
            ic_january: 5.72,
            ic_february: 5.839,
            ic_march: 5.904,
            ic_april: 5.14,
            ic_may: 4.396,
            ic_june: 4.11,
            ic_july: 4.195,
            ic_august: 4.87,
            ic_september: 5.403,
            ic_october: 5.732,
            ic_november: 5.972,
            ic_december: 6.014,
            ic_yearly: 5.275,
          },
          {
            ic_uuid: "49432232-bb4f-4ede-84b6-177a784d2428",
            ic_lat: -164402,
            ic_lon: -511185,
            ic_city: "Iporá",
            ic_states: "GOIÁS",
            ic_january: 5.502,
            ic_february: 5.542,
            ic_march: 5.216,
            ic_april: 5.248,
            ic_may: 4.834,
            ic_june: 4.588,
            ic_july: 4.721,
            ic_august: 5.511,
            ic_september: 5.37,
            ic_october: 5.499,
            ic_november: 5.391,
            ic_december: 5.529,
            ic_yearly: 5.246,
          },
          {
            ic_uuid: "eefc284a-e3d0-409f-9112-3c5ec3009964",
            ic_lat: -240088,
            ic_lon: -537065,
            ic_city: "Iporã",
            ic_states: "PARANÁ",
            ic_january: 6.212,
            ic_february: 5.825,
            ic_march: 5.33,
            ic_april: 4.411,
            ic_may: 3.477,
            ic_june: 3.095,
            ic_july: 3.269,
            ic_august: 4.29,
            ic_september: 4.536,
            ic_october: 5.352,
            ic_november: 6.16,
            ic_december: 6.513,
            ic_yearly: 4.872,
          },
          {
            ic_uuid: "32db279c-b689-470b-b6cb-cc2a27920dec",
            ic_lat: -269859,
            ic_lon: -535359,
            ic_city: "Iporã do Oeste",
            ic_states: "SANTA CATARINA",
            ic_january: 6.333,
            ic_february: 5.791,
            ic_march: 5.083,
            ic_april: 4.079,
            ic_may: 3.116,
            ic_june: 2.629,
            ic_july: 2.922,
            ic_august: 3.776,
            ic_september: 4.049,
            ic_october: 5.136,
            ic_november: 6.103,
            ic_december: 6.443,
            ic_yearly: 4.621,
          },
          {
            ic_uuid: "83ad7311-9e0e-4194-9bd9-bc35e484baa0",
            ic_lat: -24586,
            ic_lon: -485929,
            ic_city: "Iporanga",
            ic_states: "SÃO PAULO",
            ic_january: 5.271,
            ic_february: 5.229,
            ic_march: 4.495,
            ic_april: 3.861,
            ic_may: 3.085,
            ic_june: 2.66,
            ic_july: 2.711,
            ic_august: 3.541,
            ic_september: 3.598,
            ic_october: 4.092,
            ic_november: 4.845,
            ic_december: 5.427,
            ic_yearly: 4.068,
          },
          {
            ic_uuid: "4fc79a8b-269a-4d8b-ab70-1b13e3e47fe4",
            ic_lat: -43179,
            ic_lon: -407062,
            ic_city: "Ipu",
            ic_states: "CEARÁ",
            ic_january: 5.086,
            ic_february: 5.168,
            ic_march: 5.217,
            ic_april: 4.812,
            ic_may: 4.794,
            ic_june: 4.9,
            ic_july: 5.292,
            ic_august: 6.085,
            ic_september: 6.522,
            ic_october: 6.394,
            ic_november: 6.175,
            ic_december: 5.473,
            ic_yearly: 5.493,
          },
          {
            ic_uuid: "c1570f37-2532-4337-8d10-ea7843f51eaf",
            ic_lat: -204443,
            ic_lon: -480133,
            ic_city: "Ipuã",
            ic_states: "SÃO PAULO",
            ic_january: 5.837,
            ic_february: 6.075,
            ic_march: 5.156,
            ic_april: 4.965,
            ic_may: 4.292,
            ic_june: 4.038,
            ic_july: 4.288,
            ic_august: 5.191,
            ic_september: 5.101,
            ic_october: 5.596,
            ic_november: 5.762,
            ic_december: 6.108,
            ic_yearly: 5.201,
          },
          {
            ic_uuid: "c6263542-221e-4260-ad6a-7ce909ed6e9f",
            ic_lat: -266325,
            ic_lon: -524571,
            ic_city: "Ipuaçu",
            ic_states: "SANTA CATARINA",
            ic_january: 6.173,
            ic_february: 5.608,
            ic_march: 5.04,
            ic_april: 4.096,
            ic_may: 3.203,
            ic_june: 2.753,
            ic_july: 3.012,
            ic_august: 3.915,
            ic_september: 4.133,
            ic_october: 5.085,
            ic_november: 6.103,
            ic_december: 6.347,
            ic_yearly: 4.622,
          },
          {
            ic_uuid: "49676945-794f-4153-958f-cf475d65de94",
            ic_lat: -76455,
            ic_lon: -401479,
            ic_city: "Ipubi",
            ic_states: "PERNAMBUCO",
            ic_january: 5.745,
            ic_february: 5.58,
            ic_march: 5.617,
            ic_april: 5.149,
            ic_may: 4.725,
            ic_june: 4.446,
            ic_july: 4.774,
            ic_august: 5.699,
            ic_september: 6.38,
            ic_october: 6.385,
            ic_november: 6.439,
            ic_december: 6.127,
            ic_yearly: 5.589,
          },
          {
            ic_uuid: "45d4767e-f40f-4b65-acbf-87b597649fd1",
            ic_lat: -6816,
            ic_lon: -371988,
            ic_city: "Ipueira",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.174,
            ic_february: 6.182,
            ic_march: 6.2,
            ic_april: 5.849,
            ic_may: 5.208,
            ic_june: 4.762,
            ic_july: 5.032,
            ic_august: 5.798,
            ic_september: 6.373,
            ic_october: 6.582,
            ic_november: 6.606,
            ic_december: 6.291,
            ic_yearly: 5.922,
          },
          {
            ic_uuid: "ef93e503-cb7a-46a6-bc53-2088d3b111cd",
            ic_lat: -45388,
            ic_lon: -407124,
            ic_city: "Ipueiras",
            ic_states: "CEARÁ",
            ic_january: 4.95,
            ic_february: 5.129,
            ic_march: 5.225,
            ic_april: 4.829,
            ic_may: 4.866,
            ic_june: 4.882,
            ic_july: 5.266,
            ic_august: 5.989,
            ic_september: 6.475,
            ic_october: 6.344,
            ic_november: 6.164,
            ic_december: 5.411,
            ic_yearly: 5.461,
          },
          {
            ic_uuid: "fafabc70-fdab-46d2-a12e-2bc1ad44f9c6",
            ic_lat: -112379,
            ic_lon: -484552,
            ic_city: "Ipueiras",
            ic_states: "TOCANTINS",
            ic_january: 5.199,
            ic_february: 5.41,
            ic_march: 5.059,
            ic_april: 5.132,
            ic_may: 5.1,
            ic_june: 5.031,
            ic_july: 5.184,
            ic_august: 5.908,
            ic_september: 5.797,
            ic_october: 5.476,
            ic_november: 5.275,
            ic_december: 5.301,
            ic_yearly: 5.323,
          },
          {
            ic_uuid: "214613c1-154e-4ed7-a6e4-b5adf614923a",
            ic_lat: -221017,
            ic_lon: -461919,
            ic_city: "Ipuiúna",
            ic_states: "MINAS GERAIS",
            ic_january: 5.097,
            ic_february: 5.388,
            ic_march: 4.627,
            ic_april: 4.392,
            ic_may: 3.839,
            ic_june: 3.588,
            ic_july: 3.857,
            ic_august: 4.78,
            ic_september: 4.904,
            ic_october: 5.192,
            ic_november: 5.11,
            ic_december: 5.354,
            ic_yearly: 4.677,
          },
          {
            ic_uuid: "11e06a93-d0f5-4290-b1e7-e50c818d2844",
            ic_lat: -270762,
            ic_lon: -521343,
            ic_city: "Ipumirim",
            ic_states: "SANTA CATARINA",
            ic_january: 6.049,
            ic_february: 5.485,
            ic_march: 5.024,
            ic_april: 4.045,
            ic_may: 3.107,
            ic_june: 2.66,
            ic_july: 2.928,
            ic_august: 3.817,
            ic_september: 4.015,
            ic_october: 4.996,
            ic_november: 6.079,
            ic_december: 6.316,
            ic_yearly: 4.543,
          },
          {
            ic_uuid: "a128af07-3b89-4105-9a02-a3aab8a39736",
            ic_lat: -11822,
            ic_lon: -426114,
            ic_city: "Ipupiara",
            ic_states: "BAHIA",
            ic_january: 6.437,
            ic_february: 6.307,
            ic_march: 6.048,
            ic_april: 5.621,
            ic_may: 5.133,
            ic_june: 4.962,
            ic_july: 5.163,
            ic_august: 5.867,
            ic_september: 6.358,
            ic_october: 6.466,
            ic_november: 6.153,
            ic_december: 6.477,
            ic_yearly: 5.916,
          },
          {
            ic_uuid: "13e16b7a-9db8-4346-8fe4-ae55b8ee3eb4",
            ic_lat: -58128,
            ic_lon: -382923,
            ic_city: "Iracema",
            ic_states: "CEARÁ",
            ic_january: 5.896,
            ic_february: 5.944,
            ic_march: 5.977,
            ic_april: 5.615,
            ic_may: 5.286,
            ic_june: 5.057,
            ic_july: 5.372,
            ic_august: 6.032,
            ic_september: 6.439,
            ic_october: 6.616,
            ic_november: 6.627,
            ic_december: 6.088,
            ic_yearly: 5.912,
          },
          {
            ic_uuid: "c5cadc15-8639-4936-9d4b-a4e69ea413ae",
            ic_lat: 21668,
            ic_lon: -610529,
            ic_city: "Iracema",
            ic_states: "RORAIMA",
            ic_january: 4.649,
            ic_february: 4.748,
            ic_march: 5.064,
            ic_april: 4.68,
            ic_may: 4.327,
            ic_june: 4.509,
            ic_july: 4.552,
            ic_august: 4.985,
            ic_september: 5.477,
            ic_october: 5.406,
            ic_november: 5.152,
            ic_december: 4.8,
            ic_yearly: 4.862,
          },
          {
            ic_uuid: "363549e3-f1fe-4ba8-802b-f1b314704f7a",
            ic_lat: -244266,
            ic_lon: -533532,
            ic_city: "Iracema do Oeste",
            ic_states: "PARANÁ",
            ic_january: 6.256,
            ic_february: 5.717,
            ic_march: 5.284,
            ic_april: 4.392,
            ic_may: 3.473,
            ic_june: 3.055,
            ic_july: 3.256,
            ic_august: 4.274,
            ic_september: 4.534,
            ic_october: 5.317,
            ic_november: 6.095,
            ic_december: 6.469,
            ic_yearly: 4.844,
          },
          {
            ic_uuid: "cf0eb6e7-9fdf-41e0-8c02-d5faaeeb1047",
            ic_lat: -225837,
            ic_lon: -475234,
            ic_city: "Iracemápolis",
            ic_states: "SÃO PAULO",
            ic_january: 5.63,
            ic_february: 5.821,
            ic_march: 5.124,
            ic_april: 4.625,
            ic_may: 3.808,
            ic_june: 3.525,
            ic_july: 3.727,
            ic_august: 4.708,
            ic_september: 4.829,
            ic_october: 5.449,
            ic_november: 5.596,
            ic_december: 6.001,
            ic_yearly: 4.904,
          },
          {
            ic_uuid: "a2bd9c5d-3f91-4166-935e-58d0469fbe2f",
            ic_lat: -26822,
            ic_lon: -532771,
            ic_city: "Iraceminha",
            ic_states: "SANTA CATARINA",
            ic_january: 6.25,
            ic_february: 5.813,
            ic_march: 5.12,
            ic_april: 4.169,
            ic_may: 3.168,
            ic_june: 2.668,
            ic_july: 2.958,
            ic_august: 3.807,
            ic_september: 4.085,
            ic_october: 5.125,
            ic_november: 6.069,
            ic_december: 6.397,
            ic_yearly: 4.636,
          },
          {
            ic_uuid: "569ce5f6-14a8-4cc4-a3fc-b45d84a37bd1",
            ic_lat: -271955,
            ic_lon: -532548,
            ic_city: "Iraí",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.368,
            ic_february: 5.881,
            ic_march: 5.182,
            ic_april: 4.062,
            ic_may: 3.092,
            ic_june: 2.569,
            ic_july: 2.87,
            ic_august: 3.727,
            ic_september: 4.0,
            ic_october: 5.169,
            ic_november: 6.199,
            ic_december: 6.618,
            ic_yearly: 4.645,
          },
          {
            ic_uuid: "bd5d5868-560d-4a83-845f-d808ee8e0861",
            ic_lat: -189872,
            ic_lon: -474629,
            ic_city: "Iraí de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.526,
            ic_february: 5.81,
            ic_march: 4.876,
            ic_april: 4.821,
            ic_may: 4.537,
            ic_june: 4.239,
            ic_july: 4.547,
            ic_august: 5.517,
            ic_september: 5.46,
            ic_october: 5.524,
            ic_november: 5.374,
            ic_december: 5.632,
            ic_yearly: 5.155,
          },
          {
            ic_uuid: "733f6bab-c30b-4fd8-a3f2-a04a120385b9",
            ic_lat: -132568,
            ic_lon: -400852,
            ic_city: "Irajuba",
            ic_states: "BAHIA",
            ic_january: 5.771,
            ic_february: 5.729,
            ic_march: 5.452,
            ic_april: 4.431,
            ic_may: 3.839,
            ic_june: 3.483,
            ic_july: 3.689,
            ic_august: 4.249,
            ic_september: 5.169,
            ic_october: 5.233,
            ic_november: 5.33,
            ic_december: 5.791,
            ic_yearly: 4.847,
          },
          {
            ic_uuid: "8c24ed44-5f2d-4c82-a992-515141fea928",
            ic_lat: -132929,
            ic_lon: -409593,
            ic_city: "Iramaia",
            ic_states: "BAHIA",
            ic_january: 5.841,
            ic_february: 6.02,
            ic_march: 5.619,
            ic_april: 4.702,
            ic_may: 4.146,
            ic_june: 3.769,
            ic_july: 3.926,
            ic_august: 4.544,
            ic_september: 5.486,
            ic_october: 5.497,
            ic_november: 5.439,
            ic_december: 5.907,
            ic_yearly: 5.075,
          },
          {
            ic_uuid: "76a1ef80-a421-4e7b-9e4a-a538ec39052b",
            ic_lat: -32752,
            ic_lon: -601904,
            ic_city: "Iranduba",
            ic_states: "AMAZONAS",
            ic_january: 4.143,
            ic_february: 4.156,
            ic_march: 4.17,
            ic_april: 4.026,
            ic_may: 4.06,
            ic_june: 4.544,
            ic_july: 4.634,
            ic_august: 5.149,
            ic_september: 5.138,
            ic_october: 5.015,
            ic_november: 4.901,
            ic_december: 4.269,
            ic_yearly: 4.517,
          },
          {
            ic_uuid: "d598dcb4-cbb4-4e60-8bdd-1340798973ef",
            ic_lat: -270292,
            ic_lon: -519016,
            ic_city: "Irani",
            ic_states: "SANTA CATARINA",
            ic_january: 5.89,
            ic_february: 5.443,
            ic_march: 4.946,
            ic_april: 4.07,
            ic_may: 3.107,
            ic_june: 2.707,
            ic_july: 2.963,
            ic_august: 3.833,
            ic_september: 4.05,
            ic_october: 4.97,
            ic_november: 6.005,
            ic_december: 6.203,
            ic_yearly: 4.516,
          },
          {
            ic_uuid: "c38cb5af-58f5-4f9a-9260-1a1848d72580",
            ic_lat: -212782,
            ic_lon: -494116,
            ic_city: "Irapuã",
            ic_states: "SÃO PAULO",
            ic_january: 5.92,
            ic_february: 5.959,
            ic_march: 5.304,
            ic_april: 4.841,
            ic_may: 4.022,
            ic_june: 3.768,
            ic_july: 3.987,
            ic_august: 4.854,
            ic_september: 4.975,
            ic_october: 5.628,
            ic_november: 5.947,
            ic_december: 6.255,
            ic_yearly: 5.122,
          },
          {
            ic_uuid: "399fded8-4909-46f9-9e49-ec08b957ca98",
            ic_lat: -215689,
            ic_lon: -513476,
            ic_city: "Irapuru",
            ic_states: "SÃO PAULO",
            ic_january: 5.904,
            ic_february: 6.001,
            ic_march: 5.372,
            ic_april: 4.848,
            ic_may: 3.991,
            ic_june: 3.671,
            ic_july: 3.886,
            ic_august: 4.805,
            ic_september: 4.901,
            ic_october: 5.561,
            ic_november: 6.04,
            ic_december: 6.34,
            ic_yearly: 5.11,
          },
          {
            ic_uuid: "434c5899-32f7-4ad1-aa6f-d85baea004af",
            ic_lat: -122433,
            ic_lon: -416158,
            ic_city: "Iraquara",
            ic_states: "BAHIA",
            ic_january: 5.998,
            ic_february: 5.913,
            ic_march: 5.648,
            ic_april: 4.84,
            ic_may: 4.234,
            ic_june: 3.963,
            ic_july: 4.125,
            ic_august: 4.808,
            ic_september: 5.715,
            ic_october: 5.834,
            ic_november: 5.699,
            ic_december: 6.075,
            ic_yearly: 5.238,
          },
          {
            ic_uuid: "bd7dd475-95da-4147-94b8-a8903f3b940c",
            ic_lat: -120509,
            ic_lon: -387635,
            ic_city: "Irará",
            ic_states: "BAHIA",
            ic_january: 6.08,
            ic_february: 5.803,
            ic_march: 5.718,
            ic_april: 4.717,
            ic_may: 3.963,
            ic_june: 3.688,
            ic_july: 3.93,
            ic_august: 4.452,
            ic_september: 5.294,
            ic_october: 5.544,
            ic_november: 5.858,
            ic_december: 6.117,
            ic_yearly: 5.097,
          },
          {
            ic_uuid: "be091a68-5b54-43d2-9451-8d69b8173051",
            ic_lat: -254701,
            ic_lon: -506497,
            ic_city: "Irati",
            ic_states: "PARANÁ",
            ic_january: 5.541,
            ic_february: 5.25,
            ic_march: 4.582,
            ic_april: 3.798,
            ic_may: 3.061,
            ic_june: 2.673,
            ic_july: 2.935,
            ic_august: 3.946,
            ic_september: 4.051,
            ic_october: 4.623,
            ic_november: 5.571,
            ic_december: 5.701,
            ic_yearly: 4.311,
          },
          {
            ic_uuid: "9fdf976d-36b1-4eec-90d2-bb46e9ce048c",
            ic_lat: -266543,
            ic_lon: -52896,
            ic_city: "Irati",
            ic_states: "SANTA CATARINA",
            ic_january: 6.176,
            ic_february: 5.647,
            ic_march: 5.113,
            ic_april: 4.117,
            ic_may: 3.16,
            ic_june: 2.673,
            ic_july: 3.016,
            ic_august: 3.876,
            ic_september: 4.11,
            ic_october: 5.109,
            ic_november: 6.095,
            ic_december: 6.358,
            ic_yearly: 4.621,
          },
          {
            ic_uuid: "2ded939f-89cb-461b-8de5-83651b44826a",
            ic_lat: -37478,
            ic_lon: -397847,
            ic_city: "Irauçuba",
            ic_states: "CEARÁ",
            ic_january: 5.197,
            ic_february: 5.32,
            ic_march: 5.186,
            ic_april: 4.775,
            ic_may: 4.972,
            ic_june: 5.005,
            ic_july: 5.317,
            ic_august: 6.082,
            ic_september: 6.519,
            ic_october: 6.428,
            ic_november: 6.22,
            ic_december: 5.623,
            ic_yearly: 5.554,
          },
          {
            ic_uuid: "3d5ed133-cb20-4470-b9a3-4a7326a0f06a",
            ic_lat: -113038,
            ic_lon: -418538,
            ic_city: "Irecê",
            ic_states: "BAHIA",
            ic_january: 6.378,
            ic_february: 6.236,
            ic_march: 5.967,
            ic_april: 5.263,
            ic_may: 4.803,
            ic_june: 4.639,
            ic_july: 4.881,
            ic_august: 5.634,
            ic_september: 6.284,
            ic_october: 6.468,
            ic_november: 6.251,
            ic_december: 6.458,
            ic_yearly: 5.772,
          },
          {
            ic_uuid: "c9ae992c-3b8e-4065-8152-32a0e35368e0",
            ic_lat: -244258,
            ic_lon: -521016,
            ic_city: "Iretama",
            ic_states: "PARANÁ",
            ic_january: 6.113,
            ic_february: 5.725,
            ic_march: 5.242,
            ic_april: 4.502,
            ic_may: 3.518,
            ic_june: 3.113,
            ic_july: 3.338,
            ic_august: 4.328,
            ic_september: 4.543,
            ic_october: 5.252,
            ic_november: 6.135,
            ic_december: 6.339,
            ic_yearly: 4.845,
          },
          {
            ic_uuid: "68eaf986-6fa8-4d4c-af73-59d1d241304e",
            ic_lat: -262425,
            ic_lon: -507961,
            ic_city: "Irineópolis",
            ic_states: "SANTA CATARINA",
            ic_january: 5.3,
            ic_february: 4.999,
            ic_march: 4.491,
            ic_april: 3.517,
            ic_may: 2.798,
            ic_june: 2.382,
            ic_july: 2.617,
            ic_august: 3.604,
            ic_september: 3.771,
            ic_october: 4.329,
            ic_november: 5.342,
            ic_december: 5.514,
            ic_yearly: 4.055,
          },
          {
            ic_uuid: "43db98c6-07df-4423-bcc1-03d5b3d6b87a",
            ic_lat: -17702,
            ic_lon: -474464,
            ic_city: "Irituia",
            ic_states: "PARÁ",
            ic_january: 4.507,
            ic_february: 4.502,
            ic_march: 4.449,
            ic_april: 4.539,
            ic_may: 4.66,
            ic_june: 4.957,
            ic_july: 5.059,
            ic_august: 5.357,
            ic_september: 5.448,
            ic_october: 5.199,
            ic_november: 5.066,
            ic_december: 4.613,
            ic_yearly: 4.863,
          },
          {
            ic_uuid: "8802d108-57b4-4674-9279-4740aaeb17ad",
            ic_lat: -203506,
            ic_lon: -416447,
            ic_city: "Irupi",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.751,
            ic_february: 6.047,
            ic_march: 4.955,
            ic_april: 4.381,
            ic_may: 3.775,
            ic_june: 3.636,
            ic_july: 3.751,
            ic_august: 4.454,
            ic_september: 4.835,
            ic_october: 4.897,
            ic_november: 4.707,
            ic_december: 5.539,
            ic_yearly: 4.727,
          },
          {
            ic_uuid: "61653ecb-a32b-47a7-bc36-de7c9c46a2c1",
            ic_lat: -77374,
            ic_lon: -416777,
            ic_city: "Isaías Coelho",
            ic_states: "PIAUÍ",
            ic_january: 5.718,
            ic_february: 5.601,
            ic_march: 5.588,
            ic_april: 5.329,
            ic_may: 5.247,
            ic_june: 5.215,
            ic_july: 5.545,
            ic_august: 6.283,
            ic_september: 6.699,
            ic_october: 6.571,
            ic_november: 6.362,
            ic_december: 6.014,
            ic_yearly: 5.848,
          },
          {
            ic_uuid: "da810d43-fbd0-4106-9e90-214bc142ba3f",
            ic_lat: -163141,
            ic_lon: -509057,
            ic_city: "Israelândia",
            ic_states: "GOIÁS",
            ic_january: 5.541,
            ic_february: 5.667,
            ic_march: 5.371,
            ic_april: 5.317,
            ic_may: 4.831,
            ic_june: 4.553,
            ic_july: 4.668,
            ic_august: 5.358,
            ic_september: 5.364,
            ic_october: 5.571,
            ic_november: 5.479,
            ic_december: 5.558,
            ic_yearly: 5.273,
          },
          {
            ic_uuid: "6e6b97e4-9ecc-4616-b926-fc5ccc15879a",
            ic_lat: -272769,
            ic_lon: -523336,
            ic_city: "Itá",
            ic_states: "SANTA CATARINA",
            ic_january: 6.3,
            ic_february: 5.841,
            ic_march: 5.134,
            ic_april: 4.003,
            ic_may: 3.041,
            ic_june: 2.482,
            ic_july: 2.813,
            ic_august: 3.694,
            ic_september: 3.977,
            ic_october: 5.085,
            ic_november: 6.236,
            ic_december: 6.582,
            ic_yearly: 4.599,
          },
          {
            ic_uuid: "f10550b0-f51c-4a26-9b08-7d12578f1776",
            ic_lat: -2961,
            ic_lon: -537649,
            ic_city: "Itaara",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.384,
            ic_february: 5.923,
            ic_march: 4.96,
            ic_april: 3.89,
            ic_may: 2.857,
            ic_june: 2.376,
            ic_july: 2.621,
            ic_august: 3.362,
            ic_september: 3.788,
            ic_october: 4.92,
            ic_november: 6.22,
            ic_december: 6.733,
            ic_yearly: 4.503,
          },
          {
            ic_uuid: "c295a6c3-cc8e-498f-b346-a6c1b7ca4662",
            ic_lat: -73361,
            ic_lon: -353371,
            ic_city: "Itabaiana",
            ic_states: "PARAÍBA",
            ic_january: 5.655,
            ic_february: 5.874,
            ic_march: 5.848,
            ic_april: 5.351,
            ic_may: 4.677,
            ic_june: 4.223,
            ic_july: 4.357,
            ic_august: 5.112,
            ic_september: 5.519,
            ic_october: 5.761,
            ic_november: 5.991,
            ic_december: 5.935,
            ic_yearly: 5.359,
          },
          {
            ic_uuid: "c7525ef9-666e-4978-add6-bf5261104b7b",
            ic_lat: -10683,
            ic_lon: -374276,
            ic_city: "Itabaiana",
            ic_states: "SERGIPE",
            ic_january: 6.068,
            ic_february: 5.954,
            ic_march: 5.829,
            ic_april: 5.008,
            ic_may: 4.208,
            ic_june: 3.93,
            ic_july: 4.013,
            ic_august: 4.552,
            ic_september: 5.346,
            ic_october: 5.722,
            ic_november: 6.195,
            ic_december: 6.244,
            ic_yearly: 5.256,
          },
          {
            ic_uuid: "51f6622f-cb97-4274-a225-0372d7946203",
            ic_lat: -112697,
            ic_lon: -377878,
            ic_city: "Itabaianinha",
            ic_states: "SERGIPE",
            ic_january: 6.178,
            ic_february: 5.993,
            ic_march: 5.867,
            ic_april: 4.895,
            ic_may: 4.193,
            ic_june: 3.88,
            ic_july: 4.046,
            ic_august: 4.54,
            ic_september: 5.313,
            ic_october: 5.661,
            ic_november: 6.168,
            ic_december: 6.25,
            ic_yearly: 5.249,
          },
          {
            ic_uuid: "9102e7b8-9943-4d11-9e45-954fd92547d8",
            ic_lat: -165737,
            ic_lon: -395597,
            ic_city: "Itabela",
            ic_states: "BAHIA",
            ic_january: 5.882,
            ic_february: 6.043,
            ic_march: 5.361,
            ic_april: 4.537,
            ic_may: 3.867,
            ic_june: 3.48,
            ic_july: 3.7,
            ic_august: 4.243,
            ic_september: 4.866,
            ic_october: 5.1,
            ic_november: 5.14,
            ic_december: 5.99,
            ic_yearly: 4.85,
          },
          {
            ic_uuid: "15bc530f-d5a0-4283-8cbe-4f89c6bd80cc",
            ic_lat: -238643,
            ic_lon: -491404,
            ic_city: "Itaberá",
            ic_states: "SÃO PAULO",
            ic_january: 5.692,
            ic_february: 5.712,
            ic_march: 5.022,
            ic_april: 4.393,
            ic_may: 3.478,
            ic_june: 3.158,
            ic_july: 3.361,
            ic_august: 4.345,
            ic_september: 4.518,
            ic_october: 5.103,
            ic_november: 5.744,
            ic_december: 6.211,
            ic_yearly: 4.728,
          },
          {
            ic_uuid: "63603360-5cd3-4750-9755-40b914ca4383",
            ic_lat: -125246,
            ic_lon: -403062,
            ic_city: "Itaberaba",
            ic_states: "BAHIA",
            ic_january: 5.853,
            ic_february: 5.874,
            ic_march: 5.701,
            ic_april: 4.611,
            ic_may: 3.965,
            ic_june: 3.665,
            ic_july: 3.817,
            ic_august: 4.378,
            ic_september: 5.195,
            ic_october: 5.218,
            ic_november: 5.386,
            ic_december: 5.788,
            ic_yearly: 4.954,
          },
          {
            ic_uuid: "5c460a8b-64ed-42ab-9289-eae2e635d29f",
            ic_lat: -16021,
            ic_lon: -498064,
            ic_city: "Itaberaí",
            ic_states: "GOIÁS",
            ic_january: 5.335,
            ic_february: 5.638,
            ic_march: 5.17,
            ic_april: 5.128,
            ic_may: 4.873,
            ic_june: 4.633,
            ic_july: 4.789,
            ic_august: 5.76,
            ic_september: 5.508,
            ic_october: 5.543,
            ic_november: 5.296,
            ic_december: 5.404,
            ic_yearly: 5.256,
          },
          {
            ic_uuid: "51439aca-d9ba-4361-964c-3e668664caf8",
            ic_lat: -101252,
            ic_lon: -371059,
            ic_city: "Itabi",
            ic_states: "SERGIPE",
            ic_january: 6.059,
            ic_february: 5.871,
            ic_march: 5.836,
            ic_april: 5.115,
            ic_may: 4.267,
            ic_june: 3.984,
            ic_july: 4.139,
            ic_august: 4.711,
            ic_september: 5.509,
            ic_october: 5.795,
            ic_november: 6.211,
            ic_december: 6.177,
            ic_yearly: 5.306,
          },
          {
            ic_uuid: "1551a861-8a97-4e6c-a39e-91de0a29805d",
            ic_lat: -196244,
            ic_lon: -432316,
            ic_city: "Itabira",
            ic_states: "MINAS GERAIS",
            ic_january: 5.511,
            ic_february: 5.818,
            ic_march: 4.819,
            ic_april: 4.432,
            ic_may: 3.919,
            ic_june: 3.756,
            ic_july: 3.947,
            ic_august: 4.721,
            ic_september: 5.009,
            ic_october: 5.074,
            ic_november: 4.753,
            ic_december: 5.453,
            ic_yearly: 4.768,
          },
          {
            ic_uuid: "b9222556-ea77-466a-aefb-469bc388cf89",
            ic_lat: -185696,
            ic_lon: -412329,
            ic_city: "Itabirinha",
            ic_states: "MINAS GERAIS",
            ic_january: 5.93,
            ic_february: 6.059,
            ic_march: 5.186,
            ic_april: 4.594,
            ic_may: 3.85,
            ic_june: 3.605,
            ic_july: 3.733,
            ic_august: 4.342,
            ic_september: 4.869,
            ic_october: 5.079,
            ic_november: 4.917,
            ic_december: 5.691,
            ic_yearly: 4.822,
          },
          {
            ic_uuid: "5db9d641-1230-40aa-bb89-1fa07f51a1f3",
            ic_lat: -202506,
            ic_lon: -438042,
            ic_city: "Itabirito",
            ic_states: "MINAS GERAIS",
            ic_january: 5.426,
            ic_february: 5.658,
            ic_march: 4.825,
            ic_april: 4.49,
            ic_may: 4.005,
            ic_june: 3.894,
            ic_july: 4.13,
            ic_august: 5.011,
            ic_september: 5.182,
            ic_october: 5.193,
            ic_november: 4.882,
            ic_december: 5.276,
            ic_yearly: 4.831,
          },
          {
            ic_uuid: "984f14fe-2a65-4d36-86e3-a890c56ac312",
            ic_lat: -22757,
            ic_lon: -428643,
            ic_city: "Itaboraí",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.178,
            ic_february: 6.338,
            ic_march: 5.094,
            ic_april: 4.475,
            ic_may: 3.624,
            ic_june: 3.398,
            ic_july: 3.419,
            ic_august: 4.242,
            ic_september: 4.465,
            ic_october: 5.079,
            ic_november: 5.154,
            ic_december: 5.956,
            ic_yearly: 4.785,
          },
          {
            ic_uuid: "424f5787-bcac-492d-8bc0-076a27c128a5",
            ic_lat: -14788,
            ic_lon: -392784,
            ic_city: "Itabuna",
            ic_states: "BAHIA",
            ic_january: 5.884,
            ic_february: 5.958,
            ic_march: 5.475,
            ic_april: 4.557,
            ic_may: 3.893,
            ic_june: 3.537,
            ic_july: 3.779,
            ic_august: 4.32,
            ic_september: 4.922,
            ic_october: 5.126,
            ic_november: 5.297,
            ic_december: 6.055,
            ic_yearly: 4.9,
          },
          {
            ic_uuid: "9ef8ba60-8d18-4f6f-a88b-da46a48b1b22",
            ic_lat: -83934,
            ic_lon: -47773,
            ic_city: "Itacajá",
            ic_states: "TOCANTINS",
            ic_january: 4.99,
            ic_february: 4.959,
            ic_march: 4.848,
            ic_april: 4.861,
            ic_may: 5.08,
            ic_june: 5.177,
            ic_july: 5.329,
            ic_august: 6.075,
            ic_september: 5.983,
            ic_october: 5.34,
            ic_november: 4.883,
            ic_december: 4.85,
            ic_yearly: 5.198,
          },
          {
            ic_uuid: "53c6117c-b6dd-4a6b-b749-bbabec694443",
            ic_lat: -170629,
            ic_lon: -433073,
            ic_city: "Itacambira",
            ic_states: "MINAS GERAIS",
            ic_january: 6.163,
            ic_february: 6.465,
            ic_march: 5.33,
            ic_april: 4.875,
            ic_may: 4.502,
            ic_june: 4.354,
            ic_july: 4.582,
            ic_august: 5.469,
            ic_september: 5.938,
            ic_october: 5.987,
            ic_november: 5.262,
            ic_december: 5.952,
            ic_yearly: 5.407,
          },
          {
            ic_uuid: "fc881120-b12b-4b9f-9361-e41574a0b4c1",
            ic_lat: -150963,
            ic_lon: -440983,
            ic_city: "Itacarambi",
            ic_states: "MINAS GERAIS",
            ic_january: 6.417,
            ic_february: 6.715,
            ic_march: 5.941,
            ic_april: 5.704,
            ic_may: 5.03,
            ic_june: 4.801,
            ic_july: 5.076,
            ic_august: 5.659,
            ic_september: 6.062,
            ic_october: 6.264,
            ic_november: 5.729,
            ic_december: 6.314,
            ic_yearly: 5.809,
          },
          {
            ic_uuid: "b786d5f7-02c7-433d-afaf-d96fef5b466c",
            ic_lat: -142789,
            ic_lon: -389962,
            ic_city: "Itacaré",
            ic_states: "BAHIA",
            ic_january: 6.355,
            ic_february: 6.322,
            ic_march: 5.891,
            ic_april: 4.794,
            ic_may: 4.136,
            ic_june: 3.758,
            ic_july: 3.99,
            ic_august: 4.645,
            ic_september: 5.45,
            ic_october: 5.723,
            ic_november: 5.884,
            ic_december: 6.529,
            ic_yearly: 5.29,
          },
          {
            ic_uuid: "7ae3120c-7865-42f9-bf9b-9f447da651b8",
            ic_lat: -3139,
            ic_lon: -584453,
            ic_city: "Itacoatiara",
            ic_states: "AMAZONAS",
            ic_january: 4.109,
            ic_february: 4.182,
            ic_march: 4.149,
            ic_april: 3.983,
            ic_may: 3.982,
            ic_june: 4.574,
            ic_july: 4.585,
            ic_august: 5.201,
            ic_september: 5.259,
            ic_october: 5.095,
            ic_november: 4.895,
            ic_december: 4.442,
            ic_yearly: 4.538,
          },
          {
            ic_uuid: "32f81019-1af0-49d4-9640-ceddc937a85e",
            ic_lat: -88227,
            ic_lon: -386978,
            ic_city: "Itacuruba",
            ic_states: "PERNAMBUCO",
            ic_january: 6.528,
            ic_february: 6.33,
            ic_march: 6.318,
            ic_april: 5.665,
            ic_may: 4.879,
            ic_june: 4.52,
            ic_july: 4.765,
            ic_august: 5.366,
            ic_september: 6.098,
            ic_october: 6.238,
            ic_november: 6.51,
            ic_december: 6.576,
            ic_yearly: 5.816,
          },
          {
            ic_uuid: "3b267395-bbd1-44af-8855-2b592e3715ea",
            ic_lat: -287917,
            ic_lon: -552451,
            ic_city: "Itacurubi",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.639,
            ic_february: 6.127,
            ic_march: 5.255,
            ic_april: 4.021,
            ic_may: 3.071,
            ic_june: 2.516,
            ic_july: 2.81,
            ic_august: 3.601,
            ic_september: 4.079,
            ic_october: 5.256,
            ic_november: 6.464,
            ic_december: 6.906,
            ic_yearly: 4.728,
          },
          {
            ic_uuid: "332e3819-231f-46c6-998c-0136579b964f",
            ic_lat: -129822,
            ic_lon: -409638,
            ic_city: "Itaeté",
            ic_states: "BAHIA",
            ic_january: 5.923,
            ic_february: 6.118,
            ic_march: 5.637,
            ic_april: 4.747,
            ic_may: 4.2,
            ic_june: 3.83,
            ic_july: 4.025,
            ic_august: 4.672,
            ic_september: 5.486,
            ic_october: 5.479,
            ic_november: 5.392,
            ic_december: 5.837,
            ic_yearly: 5.112,
          },
          {
            ic_uuid: "c3528b46-276c-4f18-97f8-dd291efe7feb",
            ic_lat: -141639,
            ic_lon: -400038,
            ic_city: "Itagi",
            ic_states: "BAHIA",
            ic_january: 5.866,
            ic_february: 5.961,
            ic_march: 5.484,
            ic_april: 4.528,
            ic_may: 3.96,
            ic_june: 3.558,
            ic_july: 3.713,
            ic_august: 4.214,
            ic_september: 4.929,
            ic_october: 5.159,
            ic_november: 5.312,
            ic_december: 5.972,
            ic_yearly: 4.888,
          },
          {
            ic_uuid: "59ec30c9-a663-47d3-a4d5-7ef802eb7f97",
            ic_lat: -142838,
            ic_lon: -398439,
            ic_city: "Itagibá",
            ic_states: "BAHIA",
            ic_january: 5.833,
            ic_february: 5.897,
            ic_march: 5.491,
            ic_april: 4.592,
            ic_may: 3.91,
            ic_june: 3.543,
            ic_july: 3.756,
            ic_august: 4.231,
            ic_september: 4.842,
            ic_october: 5.057,
            ic_november: 5.2,
            ic_december: 5.968,
            ic_yearly: 4.86,
          },
          {
            ic_uuid: "578f45e6-9e91-4a77-8858-2331de70123d",
            ic_lat: -160824,
            ic_lon: -396136,
            ic_city: "Itagimirim",
            ic_states: "BAHIA",
            ic_january: 5.923,
            ic_february: 6.013,
            ic_march: 5.363,
            ic_april: 4.622,
            ic_may: 3.843,
            ic_june: 3.509,
            ic_july: 3.677,
            ic_august: 4.263,
            ic_september: 4.917,
            ic_october: 5.135,
            ic_november: 5.249,
            ic_december: 6.022,
            ic_yearly: 4.878,
          },
          {
            ic_uuid: "7826cee3-e48f-48d2-bc35-57c74b78cba8",
            ic_lat: -198023,
            ic_lon: -408605,
            ic_city: "Itaguaçu",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.996,
            ic_february: 6.307,
            ic_march: 5.259,
            ic_april: 4.616,
            ic_may: 3.915,
            ic_june: 3.6,
            ic_july: 3.72,
            ic_august: 4.325,
            ic_september: 4.742,
            ic_october: 4.879,
            ic_november: 4.851,
            ic_december: 5.63,
            ic_yearly: 4.82,
          },
          {
            ic_uuid: "4871b0e5-b8df-4adf-be23-a89d208b9685",
            ic_lat: -110101,
            ic_lon: -423998,
            ic_city: "Itaguaçu da Bahia",
            ic_states: "BAHIA",
            ic_january: 6.537,
            ic_february: 6.355,
            ic_march: 6.207,
            ic_april: 5.555,
            ic_may: 5.183,
            ic_june: 5.067,
            ic_july: 5.28,
            ic_august: 6.018,
            ic_september: 6.444,
            ic_october: 6.512,
            ic_november: 6.415,
            ic_december: 6.5,
            ic_yearly: 6.006,
          },
          {
            ic_uuid: "e88a7b30-4fc3-43a4-a554-39ba71d77d6e",
            ic_lat: -228641,
            ic_lon: -437802,
            ic_city: "Itaguaí",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.904,
            ic_february: 6.193,
            ic_march: 5.01,
            ic_april: 4.479,
            ic_may: 3.611,
            ic_june: 3.314,
            ic_july: 3.364,
            ic_august: 4.217,
            ic_september: 4.339,
            ic_october: 4.93,
            ic_november: 5.077,
            ic_december: 5.823,
            ic_yearly: 4.688,
          },
          {
            ic_uuid: "8b1e3a4c-4101-41dc-86ce-990b48692e20",
            ic_lat: -226187,
            ic_lon: -519678,
            ic_city: "Itaguajé",
            ic_states: "PARANÁ",
            ic_january: 6.011,
            ic_february: 5.997,
            ic_march: 5.492,
            ic_april: 4.743,
            ic_may: 3.771,
            ic_june: 3.461,
            ic_july: 3.647,
            ic_august: 4.597,
            ic_september: 4.753,
            ic_october: 5.481,
            ic_november: 6.137,
            ic_december: 6.462,
            ic_yearly: 5.046,
          },
          {
            ic_uuid: "9fe2d906-0945-4a10-a011-20b78a679445",
            ic_lat: -203952,
            ic_lon: -444879,
            ic_city: "Itaguara",
            ic_states: "MINAS GERAIS",
            ic_january: 5.596,
            ic_february: 5.794,
            ic_march: 4.974,
            ic_april: 4.694,
            ic_may: 4.174,
            ic_june: 4.017,
            ic_july: 4.258,
            ic_august: 5.148,
            ic_september: 5.366,
            ic_october: 5.423,
            ic_november: 5.127,
            ic_december: 5.498,
            ic_yearly: 5.006,
          },
          {
            ic_uuid: "baedf5ee-0726-45e3-a163-01ccace494a0",
            ic_lat: -159192,
            ic_lon: -496033,
            ic_city: "Itaguari",
            ic_states: "GOIÁS",
            ic_january: 5.458,
            ic_february: 5.688,
            ic_march: 5.224,
            ic_april: 5.153,
            ic_may: 4.883,
            ic_june: 4.673,
            ic_july: 4.766,
            ic_august: 5.689,
            ic_september: 5.495,
            ic_october: 5.564,
            ic_november: 5.28,
            ic_december: 5.412,
            ic_yearly: 5.274,
          },
          {
            ic_uuid: "0eaea9ed-5f44-42ec-88d4-193d8c0b505f",
            ic_lat: -157611,
            ic_lon: -49634,
            ic_city: "Itaguaru",
            ic_states: "GOIÁS",
            ic_january: 5.42,
            ic_february: 5.696,
            ic_march: 5.248,
            ic_april: 5.178,
            ic_may: 4.903,
            ic_june: 4.633,
            ic_july: 4.67,
            ic_august: 5.648,
            ic_september: 5.476,
            ic_october: 5.598,
            ic_november: 5.265,
            ic_december: 5.455,
            ic_yearly: 5.266,
          },
          {
            ic_uuid: "64002722-6d1b-44ea-b33b-2a0daae01dd1",
            ic_lat: -57766,
            ic_lon: -474798,
            ic_city: "Itaguatins",
            ic_states: "TOCANTINS",
            ic_january: 4.674,
            ic_february: 4.738,
            ic_march: 4.775,
            ic_april: 4.817,
            ic_may: 4.876,
            ic_june: 5.143,
            ic_july: 5.34,
            ic_august: 5.827,
            ic_september: 5.605,
            ic_october: 5.267,
            ic_november: 4.938,
            ic_december: 4.789,
            ic_yearly: 5.066,
          },
          {
            ic_uuid: "f482f199-5967-425e-a347-97edc98cbd49",
            ic_lat: -234218,
            ic_lon: -490924,
            ic_city: "Itaí",
            ic_states: "SÃO PAULO",
            ic_january: 5.614,
            ic_february: 5.785,
            ic_march: 5.125,
            ic_april: 4.514,
            ic_may: 3.583,
            ic_june: 3.268,
            ic_july: 3.447,
            ic_august: 4.432,
            ic_september: 4.589,
            ic_october: 5.266,
            ic_november: 5.837,
            ic_december: 6.177,
            ic_yearly: 4.803,
          },
          {
            ic_uuid: "32371d3d-2640-44a6-b217-fccff74658e1",
            ic_lat: -8947,
            ic_lon: -374214,
            ic_city: "Itaíba",
            ic_states: "PERNAMBUCO",
            ic_january: 6.387,
            ic_february: 6.147,
            ic_march: 6.179,
            ic_april: 5.506,
            ic_may: 4.526,
            ic_june: 4.049,
            ic_july: 4.231,
            ic_august: 4.984,
            ic_september: 5.961,
            ic_october: 6.336,
            ic_november: 6.73,
            ic_december: 6.501,
            ic_yearly: 5.628,
          },
          {
            ic_uuid: "7efda991-fa67-4df0-9f88-254b562af43d",
            ic_lat: -46719,
            ic_lon: -378333,
            ic_city: "Itaiçaba",
            ic_states: "CEARÁ",
            ic_january: 5.803,
            ic_february: 5.899,
            ic_march: 5.86,
            ic_april: 5.317,
            ic_may: 5.322,
            ic_june: 5.068,
            ic_july: 5.356,
            ic_august: 6.005,
            ic_september: 6.332,
            ic_october: 6.374,
            ic_november: 6.396,
            ic_december: 6.067,
            ic_yearly: 5.817,
          },
          {
            ic_uuid: "9ba38f67-0f51-4770-a3ec-7e90de6a2ea8",
            ic_lat: -74491,
            ic_lon: -414803,
            ic_city: "Itainópolis",
            ic_states: "PIAUÍ",
            ic_january: 5.556,
            ic_february: 5.511,
            ic_march: 5.594,
            ic_april: 5.329,
            ic_may: 5.258,
            ic_june: 5.215,
            ic_july: 5.583,
            ic_august: 6.209,
            ic_september: 6.693,
            ic_october: 6.517,
            ic_november: 6.319,
            ic_december: 5.911,
            ic_yearly: 5.808,
          },
          {
            ic_uuid: "09907887-db5e-468b-8385-103b6410093a",
            ic_lat: -263395,
            ic_lon: -499096,
            ic_city: "Itaiópolis",
            ic_states: "SANTA CATARINA",
            ic_january: 5.429,
            ic_february: 5.067,
            ic_march: 4.565,
            ic_april: 3.584,
            ic_may: 2.916,
            ic_june: 2.495,
            ic_july: 2.769,
            ic_august: 3.697,
            ic_september: 3.76,
            ic_october: 4.382,
            ic_november: 5.324,
            ic_december: 5.63,
            ic_yearly: 4.135,
          },
          {
            ic_uuid: "0cd08ef2-0b56-452d-a592-cc32f0b97478",
            ic_lat: -51429,
            ic_lon: -457881,
            ic_city: "Itaipava do Grajaú",
            ic_states: "MARANHÃO",
            ic_january: 4.653,
            ic_february: 4.857,
            ic_march: 4.884,
            ic_april: 4.867,
            ic_may: 4.809,
            ic_june: 5.071,
            ic_july: 5.331,
            ic_august: 5.761,
            ic_september: 5.906,
            ic_october: 5.424,
            ic_november: 5.059,
            ic_december: 4.876,
            ic_yearly: 5.125,
          },
          {
            ic_uuid: "9e828cc9-94f2-455c-a433-d2be1a3005c8",
            ic_lat: -174019,
            ic_lon: -4167,
            ic_city: "Itaipé",
            ic_states: "MINAS GERAIS",
            ic_january: 5.975,
            ic_february: 6.262,
            ic_march: 5.224,
            ic_april: 4.452,
            ic_may: 3.83,
            ic_june: 3.559,
            ic_july: 3.706,
            ic_august: 4.468,
            ic_september: 4.989,
            ic_october: 5.319,
            ic_november: 4.973,
            ic_december: 5.753,
            ic_yearly: 4.876,
          },
          {
            ic_uuid: "3822b324-559f-4e1a-b737-bfc82827f0da",
            ic_lat: -251371,
            ic_lon: -543006,
            ic_city: "Itaipulândia",
            ic_states: "PARANÁ",
            ic_january: 6.419,
            ic_february: 5.933,
            ic_march: 5.385,
            ic_april: 4.256,
            ic_may: 3.298,
            ic_june: 2.878,
            ic_july: 3.082,
            ic_august: 4.055,
            ic_september: 4.317,
            ic_october: 5.296,
            ic_november: 6.188,
            ic_december: 6.717,
            ic_yearly: 4.819,
          },
          {
            ic_uuid: "4fab78d2-18fe-4547-a022-f8ad76b8891b",
            ic_lat: -39662,
            ic_lon: -385301,
            ic_city: "Itaitinga",
            ic_states: "CEARÁ",
            ic_january: 5.522,
            ic_february: 5.523,
            ic_march: 5.404,
            ic_april: 4.789,
            ic_may: 5.103,
            ic_june: 5.1,
            ic_july: 5.377,
            ic_august: 6.026,
            ic_september: 6.177,
            ic_october: 6.207,
            ic_november: 6.152,
            ic_december: 5.655,
            ic_yearly: 5.586,
          },
          {
            ic_uuid: "6a2d7824-4ebc-4988-bc03-0c4f3ef2590b",
            ic_lat: -42671,
            ic_lon: -559931,
            ic_city: "Itaituba",
            ic_states: "PARÁ",
            ic_january: 4.32,
            ic_february: 4.406,
            ic_march: 4.398,
            ic_april: 4.228,
            ic_may: 4.235,
            ic_june: 4.529,
            ic_july: 4.522,
            ic_august: 5.196,
            ic_september: 5.186,
            ic_october: 5.283,
            ic_november: 5.078,
            ic_december: 4.75,
            ic_yearly: 4.678,
          },
          {
            ic_uuid: "5816d0a1-6958-4534-a0ec-b9170db10619",
            ic_lat: -190678,
            ic_lon: -515499,
            ic_city: "Itajá",
            ic_states: "GOIÁS",
            ic_january: 5.764,
            ic_february: 5.868,
            ic_march: 5.399,
            ic_april: 5.096,
            ic_may: 4.46,
            ic_june: 4.167,
            ic_july: 4.388,
            ic_august: 5.224,
            ic_september: 5.172,
            ic_october: 5.57,
            ic_november: 5.766,
            ic_december: 6.079,
            ic_yearly: 5.246,
          },
          {
            ic_uuid: "70b76c0c-b143-4a50-a07b-0d6d78da908f",
            ic_lat: -56409,
            ic_lon: -368676,
            ic_city: "Itajá",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.847,
            ic_february: 6.01,
            ic_march: 5.936,
            ic_april: 5.645,
            ic_may: 5.275,
            ic_june: 4.847,
            ic_july: 5.121,
            ic_august: 5.842,
            ic_september: 6.273,
            ic_october: 6.41,
            ic_november: 6.427,
            ic_december: 6.005,
            ic_yearly: 5.803,
          },
          {
            ic_uuid: "5984d356-46cb-46eb-b4fd-5d237a02571d",
            ic_lat: -269106,
            ic_lon: -486709,
            ic_city: "Itajaí",
            ic_states: "SANTA CATARINA",
            ic_january: 5.738,
            ic_february: 5.435,
            ic_march: 4.648,
            ic_april: 3.693,
            ic_may: 3.016,
            ic_june: 2.51,
            ic_july: 2.626,
            ic_august: 3.256,
            ic_september: 3.505,
            ic_october: 4.213,
            ic_november: 5.368,
            ic_december: 5.774,
            ic_yearly: 4.148,
          },
          {
            ic_uuid: "490af517-ff7e-46d2-a2b5-be79e80955d0",
            ic_lat: -21318,
            ic_lon: -490539,
            ic_city: "Itajobi",
            ic_states: "SÃO PAULO",
            ic_january: 5.85,
            ic_february: 5.884,
            ic_march: 5.216,
            ic_april: 4.786,
            ic_may: 4.061,
            ic_june: 3.805,
            ic_july: 4.02,
            ic_august: 4.953,
            ic_september: 5.04,
            ic_october: 5.604,
            ic_november: 5.88,
            ic_december: 6.135,
            ic_yearly: 5.103,
          },
          {
            ic_uuid: "b6c1a4a5-4075-4cf7-bb15-d42afe678e69",
            ic_lat: -219859,
            ic_lon: -488107,
            ic_city: "Itaju",
            ic_states: "SÃO PAULO",
            ic_january: 5.835,
            ic_february: 5.954,
            ic_march: 5.305,
            ic_april: 4.774,
            ic_may: 3.89,
            ic_june: 3.673,
            ic_july: 3.853,
            ic_august: 4.73,
            ic_september: 4.876,
            ic_october: 5.622,
            ic_november: 5.987,
            ic_december: 6.31,
            ic_yearly: 5.068,
          },
          {
            ic_uuid: "6e663cc1-9ac5-4b81-8ce5-610635697e04",
            ic_lat: -151381,
            ic_lon: -397238,
            ic_city: "Itaju do Colônia",
            ic_states: "BAHIA",
            ic_january: 5.935,
            ic_february: 5.812,
            ic_march: 5.421,
            ic_april: 4.555,
            ic_may: 3.875,
            ic_june: 3.502,
            ic_july: 3.755,
            ic_august: 4.254,
            ic_september: 4.895,
            ic_october: 5.148,
            ic_november: 5.252,
            ic_december: 5.983,
            ic_yearly: 4.865,
          },
          {
            ic_uuid: "ff6a4f67-9bc1-447f-932c-b299b2263f1f",
            ic_lat: -22423,
            ic_lon: -454602,
            ic_city: "Itajubá",
            ic_states: "MINAS GERAIS",
            ic_january: 5.383,
            ic_february: 5.662,
            ic_march: 4.922,
            ic_april: 4.557,
            ic_may: 3.85,
            ic_june: 3.611,
            ic_july: 3.828,
            ic_august: 4.703,
            ic_september: 4.965,
            ic_october: 5.423,
            ic_november: 5.403,
            ic_december: 5.767,
            ic_yearly: 4.839,
          },
          {
            ic_uuid: "073cd6c5-4b09-4a67-81f8-dcf556e37cd3",
            ic_lat: -146765,
            ic_lon: -393739,
            ic_city: "Itajuípe",
            ic_states: "BAHIA",
            ic_january: 5.79,
            ic_february: 5.835,
            ic_march: 5.432,
            ic_april: 4.522,
            ic_may: 3.883,
            ic_june: 3.547,
            ic_july: 3.705,
            ic_august: 4.19,
            ic_september: 4.812,
            ic_october: 4.994,
            ic_november: 5.133,
            ic_december: 5.939,
            ic_yearly: 4.815,
          },
          {
            ic_uuid: "c7323b8e-17e9-4e81-a4f0-9369de582e5c",
            ic_lat: -214301,
            ic_lon: -417018,
            ic_city: "Italva",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.266,
            ic_february: 6.424,
            ic_march: 5.248,
            ic_april: 4.507,
            ic_may: 3.874,
            ic_june: 3.536,
            ic_july: 3.645,
            ic_august: 4.426,
            ic_september: 4.861,
            ic_october: 5.191,
            ic_november: 5.159,
            ic_december: 6.033,
            ic_yearly: 4.931,
          },
          {
            ic_uuid: "15aa1e33-2796-43ba-a44d-1e8eb4d0688a",
            ic_lat: -170383,
            ic_lon: -395389,
            ic_city: "Itamaraju",
            ic_states: "BAHIA",
            ic_january: 5.981,
            ic_february: 6.075,
            ic_march: 5.374,
            ic_april: 4.466,
            ic_may: 3.832,
            ic_june: 3.467,
            ic_july: 3.651,
            ic_august: 4.293,
            ic_september: 4.849,
            ic_october: 5.114,
            ic_november: 5.211,
            ic_december: 6.041,
            ic_yearly: 4.863,
          },
          {
            ic_uuid: "d9ff9209-2c19-471b-9ec7-c538b655457e",
            ic_lat: -178556,
            ic_lon: -428565,
            ic_city: "Itamarandiba",
            ic_states: "MINAS GERAIS",
            ic_january: 5.849,
            ic_february: 6.109,
            ic_march: 5.067,
            ic_april: 4.532,
            ic_may: 3.989,
            ic_june: 3.862,
            ic_july: 4.013,
            ic_august: 4.86,
            ic_september: 5.327,
            ic_october: 5.384,
            ic_november: 4.915,
            ic_december: 5.629,
            ic_yearly: 4.962,
          },
          {
            ic_uuid: "f66faee4-96f7-4d5e-8841-43769b969f90",
            ic_lat: -6425,
            ic_lon: -682605,
            ic_city: "Itamarati",
            ic_states: "AMAZONAS",
            ic_january: 4.23,
            ic_february: 4.534,
            ic_march: 4.283,
            ic_april: 4.224,
            ic_may: 3.95,
            ic_june: 4.166,
            ic_july: 4.272,
            ic_august: 4.961,
            ic_september: 5.108,
            ic_october: 4.83,
            ic_november: 4.631,
            ic_december: 4.363,
            ic_yearly: 4.463,
          },
          {
            ic_uuid: "a4143678-675f-4cf9-b936-cf5659e624fd",
            ic_lat: -214184,
            ic_lon: -428133,
            ic_city: "Itamarati de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.812,
            ic_february: 6.041,
            ic_march: 5.041,
            ic_april: 4.316,
            ic_may: 3.647,
            ic_june: 3.455,
            ic_july: 3.633,
            ic_august: 4.398,
            ic_september: 4.736,
            ic_october: 5.049,
            ic_november: 4.874,
            ic_december: 5.652,
            ic_yearly: 4.721,
          },
          {
            ic_uuid: "eafcf24d-2786-4715-8a42-7aae944f72cb",
            ic_lat: -137786,
            ic_lon: -396833,
            ic_city: "Itamari",
            ic_states: "BAHIA",
            ic_january: 5.866,
            ic_february: 5.802,
            ic_march: 5.45,
            ic_april: 4.435,
            ic_may: 3.846,
            ic_june: 3.461,
            ic_july: 3.6,
            ic_august: 4.118,
            ic_september: 4.827,
            ic_october: 5.09,
            ic_november: 5.371,
            ic_december: 5.917,
            ic_yearly: 4.815,
          },
          {
            ic_uuid: "5f1dfb0e-84fd-45f9-9e1f-624056bc59a9",
            ic_lat: -180354,
            ic_lon: -416833,
            ic_city: "Itambacuri",
            ic_states: "MINAS GERAIS",
            ic_january: 5.928,
            ic_february: 6.181,
            ic_march: 5.243,
            ic_april: 4.546,
            ic_may: 3.79,
            ic_june: 3.567,
            ic_july: 3.697,
            ic_august: 4.419,
            ic_september: 4.907,
            ic_october: 5.217,
            ic_november: 4.922,
            ic_december: 5.75,
            ic_yearly: 4.847,
          },
          {
            ic_uuid: "6f40d06c-ad1a-491b-b027-09584cd04f9a",
            ic_lat: -23018,
            ic_lon: -504059,
            ic_city: "Itambaracá",
            ic_states: "PARANÁ",
            ic_january: 5.921,
            ic_february: 5.885,
            ic_march: 5.277,
            ic_april: 4.657,
            ic_may: 3.731,
            ic_june: 3.398,
            ic_july: 3.607,
            ic_august: 4.57,
            ic_september: 4.708,
            ic_october: 5.402,
            ic_november: 5.998,
            ic_december: 6.41,
            ic_yearly: 4.964,
          },
          {
            ic_uuid: "7ffabc11-9cf6-46a2-aef8-36f2e70fdbe9",
            ic_lat: -152449,
            ic_lon: -406328,
            ic_city: "Itambé",
            ic_states: "BAHIA",
            ic_january: 6.053,
            ic_february: 6.056,
            ic_march: 5.502,
            ic_april: 4.68,
            ic_may: 3.92,
            ic_june: 3.461,
            ic_july: 3.689,
            ic_august: 4.179,
            ic_september: 5.079,
            ic_october: 5.273,
            ic_november: 5.334,
            ic_december: 6.124,
            ic_yearly: 4.946,
          },
          {
            ic_uuid: "bb2a1a88-49ca-4e77-bdd3-bd6a29a8bd4b",
            ic_lat: -236605,
            ic_lon: -519917,
            ic_city: "Itambé",
            ic_states: "PARANÁ",
            ic_january: 6.039,
            ic_february: 5.706,
            ic_march: 5.215,
            ic_april: 4.56,
            ic_may: 3.608,
            ic_june: 3.261,
            ic_july: 3.454,
            ic_august: 4.354,
            ic_september: 4.605,
            ic_october: 5.27,
            ic_november: 6.057,
            ic_december: 6.398,
            ic_yearly: 4.877,
          },
          {
            ic_uuid: "b4a21ddb-d115-4e25-9f34-5de65fd0e59c",
            ic_lat: -74095,
            ic_lon: -351113,
            ic_city: "Itambé",
            ic_states: "PERNAMBUCO",
            ic_january: 5.74,
            ic_february: 5.94,
            ic_march: 5.925,
            ic_april: 5.335,
            ic_may: 4.628,
            ic_june: 4.243,
            ic_july: 4.356,
            ic_august: 5.058,
            ic_september: 5.592,
            ic_october: 5.849,
            ic_november: 6.132,
            ic_december: 6.035,
            ic_yearly: 5.403,
          },
          {
            ic_uuid: "b171f4ba-8405-4e2d-9c85-d26e009bdfb3",
            ic_lat: -194169,
            ic_lon: -433224,
            ic_city: "Itambé do Mato Dentro",
            ic_states: "MINAS GERAIS",
            ic_january: 5.473,
            ic_february: 5.756,
            ic_march: 4.838,
            ic_april: 4.372,
            ic_may: 3.826,
            ic_june: 3.697,
            ic_july: 3.89,
            ic_august: 4.69,
            ic_september: 4.97,
            ic_october: 5.004,
            ic_november: 4.644,
            ic_december: 5.335,
            ic_yearly: 4.708,
          },
          {
            ic_uuid: "4926261c-0b98-49e5-8ea3-f52bd5b8dcec",
            ic_lat: -210763,
            ic_lon: -470464,
            ic_city: "Itamogi",
            ic_states: "MINAS GERAIS",
            ic_january: 5.508,
            ic_february: 5.682,
            ic_march: 4.916,
            ic_april: 4.696,
            ic_may: 4.146,
            ic_june: 3.925,
            ic_july: 4.169,
            ic_august: 5.039,
            ic_september: 5.083,
            ic_october: 5.499,
            ic_november: 5.538,
            ic_december: 5.719,
            ic_yearly: 4.993,
          },
          {
            ic_uuid: "5b0019fd-fb97-479f-ab2d-06a26d043526",
            ic_lat: -222864,
            ic_lon: -448684,
            ic_city: "Itamonte",
            ic_states: "MINAS GERAIS",
            ic_january: 5.381,
            ic_february: 5.653,
            ic_march: 4.939,
            ic_april: 4.549,
            ic_may: 3.889,
            ic_june: 3.626,
            ic_july: 3.838,
            ic_august: 4.713,
            ic_september: 5.002,
            ic_october: 5.463,
            ic_november: 5.293,
            ic_december: 5.707,
            ic_yearly: 4.838,
          },
          {
            ic_uuid: "ae00476f-182d-4716-b2c7-90eff935ea1b",
            ic_lat: -122549,
            ic_lon: -380405,
            ic_city: "Itanagra",
            ic_states: "BAHIA",
            ic_january: 6.16,
            ic_february: 6.003,
            ic_march: 5.853,
            ic_april: 4.73,
            ic_may: 4.026,
            ic_june: 3.857,
            ic_july: 4.004,
            ic_august: 4.452,
            ic_september: 5.306,
            ic_october: 5.633,
            ic_november: 5.975,
            ic_december: 6.317,
            ic_yearly: 5.193,
          },
          {
            ic_uuid: "5811f439-a259-4193-be60-3434fc606781",
            ic_lat: -241777,
            ic_lon: -46786,
            ic_city: "Itanhaém",
            ic_states: "SÃO PAULO",
            ic_january: 5.383,
            ic_february: 5.453,
            ic_march: 4.57,
            ic_april: 4.056,
            ic_may: 3.211,
            ic_june: 2.826,
            ic_july: 2.854,
            ic_august: 3.51,
            ic_september: 3.566,
            ic_october: 3.968,
            ic_november: 4.888,
            ic_december: 5.417,
            ic_yearly: 4.142,
          },
          {
            ic_uuid: "72d0f9dd-cfbd-43a4-94c9-dd6c8e34817c",
            ic_lat: -222947,
            ic_lon: -449386,
            ic_city: "Itanhandu",
            ic_states: "MINAS GERAIS",
            ic_january: 5.486,
            ic_february: 5.799,
            ic_march: 5.007,
            ic_april: 4.645,
            ic_may: 3.95,
            ic_june: 3.626,
            ic_july: 3.838,
            ic_august: 4.7,
            ic_september: 5.025,
            ic_october: 5.493,
            ic_november: 5.402,
            ic_december: 5.789,
            ic_yearly: 4.897,
          },
          {
            ic_uuid: "dc18b1c7-41e8-4f48-a7c8-90fe2c2a103c",
            ic_lat: -122363,
            ic_lon: -566425,
            ic_city: "Itanhangá",
            ic_states: "MATO GROSSO",
            ic_january: 5.067,
            ic_february: 4.877,
            ic_march: 4.814,
            ic_april: 4.748,
            ic_may: 4.642,
            ic_june: 4.692,
            ic_july: 4.909,
            ic_august: 5.298,
            ic_september: 5.105,
            ic_october: 5.293,
            ic_november: 5.075,
            ic_december: 5.14,
            ic_yearly: 4.972,
          },
          {
            ic_uuid: "99a2e01f-2b8b-414d-a62a-cb18ed409e5a",
            ic_lat: -171647,
            ic_lon: -403325,
            ic_city: "Itanhém",
            ic_states: "BAHIA",
            ic_january: 6.084,
            ic_february: 6.223,
            ic_march: 5.47,
            ic_april: 4.66,
            ic_may: 3.866,
            ic_june: 3.512,
            ic_july: 3.68,
            ic_august: 4.398,
            ic_september: 5.074,
            ic_october: 5.295,
            ic_november: 5.298,
            ic_december: 6.229,
            ic_yearly: 4.982,
          },
          {
            ic_uuid: "31422418-e08d-49cd-b70c-175a2ad115e4",
            ic_lat: -191741,
            ic_lon: -418634,
            ic_city: "Itanhomi",
            ic_states: "MINAS GERAIS",
            ic_january: 5.964,
            ic_february: 6.079,
            ic_march: 5.157,
            ic_april: 4.536,
            ic_may: 3.781,
            ic_june: 3.564,
            ic_july: 3.701,
            ic_august: 4.403,
            ic_september: 4.886,
            ic_october: 5.094,
            ic_november: 4.913,
            ic_december: 5.786,
            ic_yearly: 4.822,
          },
          {
            ic_uuid: "d42956db-a12e-4aa4-9780-b772cc6fe2e2",
            ic_lat: -165575,
            ic_lon: -41502,
            ic_city: "Itaobim",
            ic_states: "MINAS GERAIS",
            ic_january: 6.293,
            ic_february: 6.6,
            ic_march: 5.575,
            ic_april: 4.84,
            ic_may: 4.124,
            ic_june: 3.727,
            ic_july: 3.958,
            ic_august: 4.678,
            ic_september: 5.459,
            ic_october: 5.699,
            ic_november: 5.477,
            ic_december: 6.272,
            ic_yearly: 5.225,
          },
          {
            ic_uuid: "2a9c823f-4b73-4bb3-aeda-433cf1036ad1",
            ic_lat: -246397,
            ic_lon: -488417,
            ic_city: "Itaóca",
            ic_states: "SÃO PAULO",
            ic_january: 5.219,
            ic_february: 5.219,
            ic_march: 4.494,
            ic_april: 3.84,
            ic_may: 3.06,
            ic_june: 2.686,
            ic_july: 2.785,
            ic_august: 3.702,
            ic_september: 3.764,
            ic_october: 4.277,
            ic_november: 5.009,
            ic_december: 5.496,
            ic_yearly: 4.129,
          },
          {
            ic_uuid: "d1ba91c5-c815-4816-b256-da57d0c4ea23",
            ic_lat: -216753,
            ic_lon: -420762,
            ic_city: "Itaocara",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.224,
            ic_february: 6.396,
            ic_march: 5.301,
            ic_april: 4.553,
            ic_may: 3.825,
            ic_june: 3.537,
            ic_july: 3.638,
            ic_august: 4.43,
            ic_september: 4.834,
            ic_october: 5.217,
            ic_november: 5.222,
            ic_december: 6.029,
            ic_yearly: 4.934,
          },
          {
            ic_uuid: "22ae3638-c293-4b2b-a9ee-e46a3ba9ffef",
            ic_lat: -149527,
            ic_lon: -495515,
            ic_city: "Itapaci",
            ic_states: "GOIÁS",
            ic_january: 5.285,
            ic_february: 5.556,
            ic_march: 5.196,
            ic_april: 5.148,
            ic_may: 4.89,
            ic_june: 4.625,
            ic_july: 4.845,
            ic_august: 5.706,
            ic_september: 5.603,
            ic_october: 5.517,
            ic_november: 5.232,
            ic_december: 5.346,
            ic_yearly: 5.246,
          },
          {
            ic_uuid: "dac7bb76-3bde-42e3-b657-d463e774fc8d",
            ic_lat: -199016,
            ic_lon: -493708,
            ic_city: "Itapagipe",
            ic_states: "MINAS GERAIS",
            ic_january: 5.694,
            ic_february: 5.899,
            ic_march: 5.193,
            ic_april: 4.995,
            ic_may: 4.298,
            ic_june: 4.016,
            ic_july: 4.296,
            ic_august: 5.187,
            ic_september: 5.112,
            ic_october: 5.561,
            ic_november: 5.813,
            ic_december: 6.106,
            ic_yearly: 5.181,
          },
          {
            ic_uuid: "652c8545-7c2c-4682-bfda-24d1c2c9c8ab",
            ic_lat: -36835,
            ic_lon: -395859,
            ic_city: "Itapajé",
            ic_states: "CEARÁ",
            ic_january: 5.072,
            ic_february: 5.193,
            ic_march: 5.119,
            ic_april: 4.667,
            ic_may: 4.934,
            ic_june: 4.87,
            ic_july: 5.181,
            ic_august: 5.994,
            ic_september: 6.358,
            ic_october: 6.187,
            ic_november: 5.978,
            ic_december: 5.438,
            ic_yearly: 5.416,
          },
          {
            ic_uuid: "389e4dc8-0faf-4b1d-bb97-b16994f81e31",
            ic_lat: -128937,
            ic_lon: -386803,
            ic_city: "Itaparica",
            ic_states: "BAHIA",
            ic_january: 6.385,
            ic_february: 6.254,
            ic_march: 6.024,
            ic_april: 4.805,
            ic_may: 4.064,
            ic_june: 3.755,
            ic_july: 4.019,
            ic_august: 4.638,
            ic_september: 5.568,
            ic_october: 5.957,
            ic_november: 6.171,
            ic_december: 6.5,
            ic_yearly: 5.345,
          },
          {
            ic_uuid: "1efc0b55-6c43-4448-ac26-eff0762588c4",
            ic_lat: -148919,
            ic_lon: -394244,
            ic_city: "Itapé",
            ic_states: "BAHIA",
            ic_january: 5.863,
            ic_february: 5.888,
            ic_march: 5.436,
            ic_april: 4.562,
            ic_may: 3.88,
            ic_june: 3.511,
            ic_july: 3.724,
            ic_august: 4.283,
            ic_september: 4.905,
            ic_october: 5.073,
            ic_november: 5.238,
            ic_december: 6.01,
            ic_yearly: 4.864,
          },
          {
            ic_uuid: "17ab9f23-164c-43e8-856d-28a33b6958fb",
            ic_lat: -159706,
            ic_lon: -395319,
            ic_city: "Itapebi",
            ic_states: "BAHIA",
            ic_january: 5.928,
            ic_february: 5.991,
            ic_march: 5.379,
            ic_april: 4.613,
            ic_may: 3.826,
            ic_june: 3.517,
            ic_july: 3.694,
            ic_august: 4.308,
            ic_september: 4.853,
            ic_october: 5.081,
            ic_november: 5.177,
            ic_december: 5.984,
            ic_yearly: 4.863,
          },
          {
            ic_uuid: "ebdabd84-db26-450f-8aec-5f16de652970",
            ic_lat: -204708,
            ic_lon: -451274,
            ic_city: "Itapecerica",
            ic_states: "MINAS GERAIS",
            ic_january: 5.656,
            ic_february: 5.878,
            ic_march: 4.989,
            ic_april: 4.819,
            ic_may: 4.203,
            ic_june: 3.99,
            ic_july: 4.271,
            ic_august: 5.15,
            ic_september: 5.374,
            ic_october: 5.495,
            ic_november: 5.162,
            ic_december: 5.55,
            ic_yearly: 5.045,
          },
          {
            ic_uuid: "f2a17ee9-f87c-456a-ae1e-e6c293cf4f0f",
            ic_lat: -237166,
            ic_lon: -468576,
            ic_city: "Itapecerica da Serra",
            ic_states: "SÃO PAULO",
            ic_january: 5.238,
            ic_february: 5.49,
            ic_march: 4.676,
            ic_april: 4.162,
            ic_may: 3.348,
            ic_june: 3.108,
            ic_july: 3.191,
            ic_august: 4.134,
            ic_september: 4.119,
            ic_october: 4.574,
            ic_november: 5.082,
            ic_december: 5.648,
            ic_yearly: 4.397,
          },
          {
            ic_uuid: "fe162658-3587-43ef-9c69-da88720f0fde",
            ic_lat: -34024,
            ic_lon: -443512,
            ic_city: "Itapecuru Mirim",
            ic_states: "MARANHÃO",
            ic_january: 4.727,
            ic_february: 4.767,
            ic_march: 4.815,
            ic_april: 4.789,
            ic_may: 4.86,
            ic_june: 5.031,
            ic_july: 5.206,
            ic_august: 5.778,
            ic_september: 6.103,
            ic_october: 5.832,
            ic_november: 5.441,
            ic_december: 5.105,
            ic_yearly: 5.204,
          },
          {
            ic_uuid: "3d888053-8b6a-4096-bc31-80465e399a8e",
            ic_lat: -259625,
            ic_lon: -528143,
            ic_city: "Itapejara d'Oeste",
            ic_states: "PARANÁ",
            ic_january: 6.255,
            ic_february: 5.709,
            ic_march: 5.256,
            ic_april: 4.269,
            ic_may: 3.298,
            ic_june: 2.817,
            ic_july: 3.104,
            ic_august: 4.1,
            ic_september: 4.278,
            ic_october: 5.234,
            ic_november: 6.212,
            ic_december: 6.433,
            ic_yearly: 4.747,
          },
          {
            ic_uuid: "b0c39acb-e751-4a57-926c-bae2ce912464",
            ic_lat: -270866,
            ic_lon: -486165,
            ic_city: "Itapema",
            ic_states: "SANTA CATARINA",
            ic_january: 5.527,
            ic_february: 5.274,
            ic_march: 4.518,
            ic_april: 3.64,
            ic_may: 3.001,
            ic_june: 2.496,
            ic_july: 2.646,
            ic_august: 3.259,
            ic_september: 3.444,
            ic_october: 3.982,
            ic_november: 5.161,
            ic_december: 5.583,
            ic_yearly: 4.044,
          },
          {
            ic_uuid: "e1034c95-e45d-4dfb-ae83-e7f0af6d4a88",
            ic_lat: -2101,
            ic_lon: -40831,
            ic_city: "Itapemirim",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.537,
            ic_february: 6.627,
            ic_march: 5.341,
            ic_april: 4.566,
            ic_may: 3.831,
            ic_june: 3.646,
            ic_july: 3.703,
            ic_august: 4.537,
            ic_september: 4.965,
            ic_october: 5.29,
            ic_november: 5.174,
            ic_december: 6.036,
            ic_yearly: 5.021,
          },
          {
            ic_uuid: "21d4e9d7-1f42-4e92-8425-00c578de5523",
            ic_lat: -252197,
            ic_lon: -493458,
            ic_city: "Itaperuçu",
            ic_states: "PARANÁ",
            ic_january: 5.381,
            ic_february: 5.246,
            ic_march: 4.649,
            ic_april: 3.855,
            ic_may: 3.07,
            ic_june: 2.723,
            ic_july: 2.937,
            ic_august: 3.921,
            ic_september: 3.934,
            ic_october: 4.464,
            ic_november: 5.328,
            ic_december: 5.607,
            ic_yearly: 4.26,
          },
          {
            ic_uuid: "3cad3400-9caf-41bc-bc87-9ebfbc8b8a76",
            ic_lat: -211977,
            ic_lon: -41902,
            ic_city: "Itaperuna",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.177,
            ic_february: 6.368,
            ic_march: 5.227,
            ic_april: 4.579,
            ic_may: 3.85,
            ic_june: 3.599,
            ic_july: 3.709,
            ic_august: 4.524,
            ic_september: 4.922,
            ic_october: 5.227,
            ic_november: 5.124,
            ic_december: 6.033,
            ic_yearly: 4.945,
          },
          {
            ic_uuid: "5f3fe623-9d91-48f2-9f1f-f779087576af",
            ic_lat: -73759,
            ic_lon: -371858,
            ic_city: "Itapetim",
            ic_states: "PERNAMBUCO",
            ic_january: 6.078,
            ic_february: 5.987,
            ic_march: 6.022,
            ic_april: 5.652,
            ic_may: 4.93,
            ic_june: 4.522,
            ic_july: 4.766,
            ic_august: 5.522,
            ic_september: 6.279,
            ic_october: 6.461,
            ic_november: 6.589,
            ic_december: 6.236,
            ic_yearly: 5.754,
          },
          {
            ic_uuid: "425e1f98-f7a8-4bf4-a967-4d22c350abc7",
            ic_lat: -15248,
            ic_lon: -402486,
            ic_city: "Itapetinga",
            ic_states: "BAHIA",
            ic_january: 5.875,
            ic_february: 6.038,
            ic_march: 5.416,
            ic_april: 4.57,
            ic_may: 3.875,
            ic_june: 3.459,
            ic_july: 3.676,
            ic_august: 4.252,
            ic_september: 5.013,
            ic_october: 5.195,
            ic_november: 5.298,
            ic_december: 6.119,
            ic_yearly: 4.899,
          },
          {
            ic_uuid: "d19dbc85-72da-45ae-8b46-e5df456c2274",
            ic_lat: -235891,
            ic_lon: -480487,
            ic_city: "Itapetininga",
            ic_states: "SÃO PAULO",
            ic_january: 5.405,
            ic_february: 5.635,
            ic_march: 4.914,
            ic_april: 4.381,
            ic_may: 3.516,
            ic_june: 3.271,
            ic_july: 3.401,
            ic_august: 4.371,
            ic_september: 4.533,
            ic_october: 5.048,
            ic_november: 5.54,
            ic_december: 6.032,
            ic_yearly: 4.671,
          },
          {
            ic_uuid: "d2b13a22-ef9c-4db2-a606-a5b7866508b1",
            ic_lat: -22767,
            ic_lon: -462244,
            ic_city: "Itapeva",
            ic_states: "MINAS GERAIS",
            ic_january: 5.123,
            ic_february: 5.444,
            ic_march: 4.752,
            ic_april: 4.502,
            ic_may: 3.781,
            ic_june: 3.554,
            ic_july: 3.718,
            ic_august: 4.666,
            ic_september: 4.848,
            ic_october: 5.299,
            ic_november: 5.392,
            ic_december: 5.688,
            ic_yearly: 4.731,
          },
          {
            ic_uuid: "251fdb1d-f182-4086-824d-ef25fd2fb01b",
            ic_lat: -239793,
            ic_lon: -488768,
            ic_city: "Itapeva",
            ic_states: "SÃO PAULO",
            ic_january: 5.588,
            ic_february: 5.602,
            ic_march: 4.921,
            ic_april: 4.293,
            ic_may: 3.421,
            ic_june: 3.112,
            ic_july: 3.295,
            ic_august: 4.265,
            ic_september: 4.399,
            ic_october: 4.961,
            ic_november: 5.605,
            ic_december: 6.096,
            ic_yearly: 4.63,
          },
          {
            ic_uuid: "fa2b67a1-600f-4c4f-8f56-879015ef014e",
            ic_lat: -235493,
            ic_lon: -469331,
            ic_city: "Itapevi",
            ic_states: "SÃO PAULO",
            ic_january: 5.314,
            ic_february: 5.556,
            ic_march: 4.803,
            ic_april: 4.294,
            ic_may: 3.518,
            ic_june: 3.244,
            ic_july: 3.331,
            ic_august: 4.31,
            ic_september: 4.345,
            ic_october: 4.885,
            ic_november: 5.303,
            ic_december: 5.799,
            ic_yearly: 4.558,
          },
          {
            ic_uuid: "3957eeb7-6cc8-4368-ad63-abc2e4dcb057",
            ic_lat: -113102,
            ic_lon: -382175,
            ic_city: "Itapicuru",
            ic_states: "BAHIA",
            ic_january: 6.195,
            ic_february: 5.988,
            ic_march: 5.805,
            ic_april: 4.923,
            ic_may: 4.141,
            ic_june: 3.902,
            ic_july: 4.06,
            ic_august: 4.532,
            ic_september: 5.297,
            ic_october: 5.615,
            ic_november: 6.037,
            ic_december: 6.146,
            ic_yearly: 5.22,
          },
          {
            ic_uuid: "06f5d16b-d65f-4a1b-ad93-342f36295d0d",
            ic_lat: -34997,
            ic_lon: -395839,
            ic_city: "Itapipoca",
            ic_states: "CEARÁ",
            ic_january: 5.165,
            ic_february: 5.234,
            ic_march: 5.127,
            ic_april: 4.704,
            ic_may: 5.193,
            ic_june: 5.284,
            ic_july: 5.506,
            ic_august: 6.146,
            ic_september: 6.456,
            ic_october: 6.321,
            ic_november: 6.159,
            ic_december: 5.438,
            ic_yearly: 5.561,
          },
          {
            ic_uuid: "ed50b385-0c61-4176-864b-fe7be4844d01",
            ic_lat: -224362,
            ic_lon: -468228,
            ic_city: "Itapira",
            ic_states: "SÃO PAULO",
            ic_january: 5.559,
            ic_february: 5.842,
            ic_march: 5.131,
            ic_april: 4.713,
            ic_may: 3.906,
            ic_june: 3.628,
            ic_july: 3.836,
            ic_august: 4.71,
            ic_september: 4.947,
            ic_october: 5.564,
            ic_november: 5.737,
            ic_december: 6.065,
            ic_yearly: 4.97,
          },
          {
            ic_uuid: "5bd54e7d-78e8-4e22-ae44-465f7b80383a",
            ic_lat: -27412,
            ic_lon: -580297,
            ic_city: "Itapiranga",
            ic_states: "AMAZONAS",
            ic_january: 3.987,
            ic_february: 4.133,
            ic_march: 4.124,
            ic_april: 3.984,
            ic_may: 4.079,
            ic_june: 4.587,
            ic_july: 4.54,
            ic_august: 5.194,
            ic_september: 5.292,
            ic_october: 5.122,
            ic_november: 4.941,
            ic_december: 4.449,
            ic_yearly: 4.536,
          },
          {
            ic_uuid: "80327511-11bc-4c52-bb4b-b9cfbacfc786",
            ic_lat: -271663,
            ic_lon: -537171,
            ic_city: "Itapiranga",
            ic_states: "SANTA CATARINA",
            ic_january: 6.407,
            ic_february: 5.867,
            ic_march: 5.152,
            ic_april: 4.025,
            ic_may: 3.093,
            ic_june: 2.515,
            ic_july: 2.857,
            ic_august: 3.711,
            ic_september: 3.998,
            ic_october: 5.166,
            ic_november: 6.158,
            ic_december: 6.552,
            ic_yearly: 4.625,
          },
          {
            ic_uuid: "cb31152b-2928-4974-b182-e60f49220138",
            ic_lat: -158209,
            ic_lon: -506098,
            ic_city: "Itapirapuã",
            ic_states: "GOIÁS",
            ic_january: 5.486,
            ic_february: 5.653,
            ic_march: 5.347,
            ic_april: 5.278,
            ic_may: 4.899,
            ic_june: 4.649,
            ic_july: 4.771,
            ic_august: 5.571,
            ic_september: 5.454,
            ic_october: 5.596,
            ic_november: 5.396,
            ic_december: 5.512,
            ic_yearly: 5.301,
          },
          {
            ic_uuid: "cf94a414-c6e0-428c-8bbe-aa2dfa9dcae4",
            ic_lat: -245739,
            ic_lon: -491688,
            ic_city: "Itapirapuã Paulista",
            ic_states: "SÃO PAULO",
            ic_january: 5.227,
            ic_february: 5.177,
            ic_march: 4.569,
            ic_april: 3.877,
            ic_may: 3.093,
            ic_june: 2.718,
            ic_july: 2.788,
            ic_august: 3.78,
            ic_september: 3.829,
            ic_october: 4.389,
            ic_november: 5.175,
            ic_december: 5.558,
            ic_yearly: 4.182,
          },
          {
            ic_uuid: "fedc674f-fbc3-4d37-8bf5-9d14d7db2715",
            ic_lat: -83848,
            ic_lon: -481066,
            ic_city: "Itapiratins",
            ic_states: "TOCANTINS",
            ic_january: 4.966,
            ic_february: 5.044,
            ic_march: 4.915,
            ic_april: 4.923,
            ic_may: 5.11,
            ic_june: 5.186,
            ic_july: 5.18,
            ic_august: 6.009,
            ic_september: 5.828,
            ic_october: 5.316,
            ic_november: 4.903,
            ic_december: 4.909,
            ic_yearly: 5.191,
          },
          {
            ic_uuid: "fa1cd323-6c79-4a20-96e9-639f911bddf2",
            ic_lat: -77684,
            ic_lon: -348974,
            ic_city: "Itapissuma",
            ic_states: "PERNAMBUCO",
            ic_january: 5.94,
            ic_february: 6.082,
            ic_march: 6.077,
            ic_april: 5.329,
            ic_may: 4.629,
            ic_june: 4.306,
            ic_july: 4.399,
            ic_august: 5.217,
            ic_september: 5.68,
            ic_october: 6.058,
            ic_november: 6.243,
            ic_december: 6.256,
            ic_yearly: 5.518,
          },
          {
            ic_uuid: "932f8e79-f307-4667-9007-bc44e815b4c7",
            ic_lat: -14423,
            ic_lon: -395638,
            ic_city: "Itapitanga",
            ic_states: "BAHIA",
            ic_january: 5.822,
            ic_february: 5.852,
            ic_march: 5.436,
            ic_april: 4.516,
            ic_may: 3.837,
            ic_june: 3.524,
            ic_july: 3.73,
            ic_august: 4.188,
            ic_september: 4.843,
            ic_october: 5.075,
            ic_november: 5.287,
            ic_december: 5.959,
            ic_yearly: 4.839,
          },
          {
            ic_uuid: "63027995-0e83-4bd0-98ed-5b2e69121628",
            ic_lat: -45556,
            ic_lon: -389284,
            ic_city: "Itapiúna",
            ic_states: "CEARÁ",
            ic_january: 5.366,
            ic_february: 5.477,
            ic_march: 5.453,
            ic_april: 4.991,
            ic_may: 5.09,
            ic_june: 4.847,
            ic_july: 5.263,
            ic_august: 6.037,
            ic_september: 6.407,
            ic_october: 6.324,
            ic_november: 6.16,
            ic_december: 5.609,
            ic_yearly: 5.585,
          },
          {
            ic_uuid: "efe0c780-d6eb-4708-a209-efde6b680dc6",
            ic_lat: -261162,
            ic_lon: -486186,
            ic_city: "Itapoá",
            ic_states: "SANTA CATARINA",
            ic_january: 5.303,
            ic_february: 5.103,
            ic_march: 4.397,
            ic_april: 3.68,
            ic_may: 2.978,
            ic_june: 2.471,
            ic_july: 2.503,
            ic_august: 3.172,
            ic_september: 3.235,
            ic_october: 3.82,
            ic_november: 4.813,
            ic_december: 5.272,
            ic_yearly: 3.896,
          },
          {
            ic_uuid: "c3b9f2a0-1de4-4cc2-be44-b22c4117b613",
            ic_lat: -215947,
            ic_lon: -488153,
            ic_city: "Itápolis",
            ic_states: "SÃO PAULO",
            ic_january: 5.778,
            ic_february: 5.956,
            ic_march: 5.206,
            ic_april: 4.749,
            ic_may: 3.993,
            ic_june: 3.745,
            ic_july: 3.97,
            ic_august: 4.824,
            ic_september: 4.984,
            ic_october: 5.594,
            ic_november: 5.838,
            ic_december: 6.134,
            ic_yearly: 5.064,
          },
          {
            ic_uuid: "4d07cd3b-ead7-46b3-a381-c3309b0190eb",
            ic_lat: -220805,
            ic_lon: -547938,
            ic_city: "Itaporã",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.963,
            ic_february: 5.738,
            ic_march: 5.288,
            ic_april: 4.569,
            ic_may: 3.74,
            ic_june: 3.4,
            ic_july: 3.515,
            ic_august: 4.438,
            ic_september: 4.767,
            ic_october: 5.344,
            ic_november: 5.959,
            ic_december: 6.402,
            ic_yearly: 4.927,
          },
          {
            ic_uuid: "f9990ef7-68fe-4197-b01f-066e3f93b5e6",
            ic_lat: -85721,
            ic_lon: -486899,
            ic_city: "Itaporã do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 4.742,
            ic_february: 4.831,
            ic_march: 4.653,
            ic_april: 4.687,
            ic_may: 4.984,
            ic_june: 5.168,
            ic_july: 5.251,
            ic_august: 5.948,
            ic_september: 5.654,
            ic_october: 5.016,
            ic_november: 4.766,
            ic_december: 4.718,
            ic_yearly: 5.035,
          },
          {
            ic_uuid: "e74cc122-3b66-4b0c-bd87-9fbfc655b53e",
            ic_lat: -73024,
            ic_lon: -381508,
            ic_city: "Itaporanga",
            ic_states: "PARAÍBA",
            ic_january: 6.219,
            ic_february: 6.179,
            ic_march: 6.255,
            ic_april: 5.873,
            ic_may: 5.205,
            ic_june: 4.838,
            ic_july: 5.081,
            ic_august: 5.913,
            ic_september: 6.544,
            ic_october: 6.609,
            ic_november: 6.786,
            ic_december: 6.46,
            ic_yearly: 5.997,
          },
          {
            ic_uuid: "58da4864-4f28-4cdc-a895-12792727349e",
            ic_lat: -237047,
            ic_lon: -494823,
            ic_city: "Itaporanga",
            ic_states: "SÃO PAULO",
            ic_january: 5.724,
            ic_february: 5.813,
            ic_march: 5.128,
            ic_april: 4.48,
            ic_may: 3.536,
            ic_june: 3.171,
            ic_july: 3.379,
            ic_august: 4.396,
            ic_september: 4.589,
            ic_october: 5.207,
            ic_november: 5.841,
            ic_december: 6.307,
            ic_yearly: 4.797,
          },
          {
            ic_uuid: "f1af8499-bdc6-4ee9-b248-d046ce9e46b3",
            ic_lat: -109981,
            ic_lon: -373121,
            ic_city: "Itaporanga d'Ajuda",
            ic_states: "SERGIPE",
            ic_january: 6.206,
            ic_february: 6.097,
            ic_march: 5.979,
            ic_april: 5.003,
            ic_may: 4.205,
            ic_june: 3.954,
            ic_july: 4.059,
            ic_august: 4.609,
            ic_september: 5.361,
            ic_october: 5.739,
            ic_november: 6.216,
            ic_december: 6.305,
            ic_yearly: 5.311,
          },
          {
            ic_uuid: "4e3bfe17-b61c-40a1-89ea-a4301377bb75",
            ic_lat: -68289,
            ic_lon: -352463,
            ic_city: "Itapororoca",
            ic_states: "PARAÍBA",
            ic_january: 5.778,
            ic_february: 5.816,
            ic_march: 5.949,
            ic_april: 5.332,
            ic_may: 4.687,
            ic_june: 4.25,
            ic_july: 4.336,
            ic_august: 5.107,
            ic_september: 5.571,
            ic_october: 5.902,
            ic_november: 6.219,
            ic_december: 6.07,
            ic_yearly: 5.418,
          },
          {
            ic_uuid: "f479f295-afb9-4b0b-8d25-de4fc5cc7745",
            ic_lat: -91954,
            ic_lon: -631787,
            ic_city: "Itapuã do Oeste",
            ic_states: "RONDÔNIA",
            ic_january: 4.196,
            ic_february: 4.388,
            ic_march: 4.293,
            ic_april: 4.336,
            ic_may: 3.995,
            ic_june: 4.409,
            ic_july: 4.551,
            ic_august: 4.846,
            ic_september: 4.957,
            ic_october: 5.03,
            ic_november: 4.787,
            ic_december: 4.497,
            ic_yearly: 4.524,
          },
          {
            ic_uuid: "2cc38663-efa1-41c4-901d-127d6679d939",
            ic_lat: -287773,
            ic_lon: -521697,
            ic_city: "Itapuca",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.073,
            ic_february: 5.771,
            ic_march: 4.864,
            ic_april: 3.899,
            ic_may: 2.893,
            ic_june: 2.431,
            ic_july: 2.74,
            ic_august: 3.495,
            ic_september: 3.764,
            ic_october: 4.843,
            ic_november: 6.148,
            ic_december: 6.499,
            ic_yearly: 4.452,
          },
          {
            ic_uuid: "0c9511b4-dacb-436f-8cab-181275008c44",
            ic_lat: -222329,
            ic_lon: -487201,
            ic_city: "Itapuí",
            ic_states: "SÃO PAULO",
            ic_january: 5.772,
            ic_february: 5.967,
            ic_march: 5.278,
            ic_april: 4.715,
            ic_may: 3.877,
            ic_june: 3.597,
            ic_july: 3.773,
            ic_august: 4.635,
            ic_september: 4.816,
            ic_october: 5.57,
            ic_november: 5.939,
            ic_december: 6.314,
            ic_yearly: 5.021,
          },
          {
            ic_uuid: "634a23fd-ea8c-462d-ab7c-5ca75f4ea55e",
            ic_lat: -206424,
            ic_lon: -515067,
            ic_city: "Itapura",
            ic_states: "SÃO PAULO",
            ic_january: 5.885,
            ic_february: 5.966,
            ic_march: 5.47,
            ic_april: 4.873,
            ic_may: 4.106,
            ic_june: 3.785,
            ic_july: 3.989,
            ic_august: 4.904,
            ic_september: 4.896,
            ic_october: 5.55,
            ic_november: 5.967,
            ic_december: 6.305,
            ic_yearly: 5.141,
          },
          {
            ic_uuid: "1710cf01-79bc-4a8e-b6d6-3e32847485cc",
            ic_lat: -155604,
            ic_lon: -499405,
            ic_city: "Itapuranga",
            ic_states: "GOIÁS",
            ic_january: 5.331,
            ic_february: 5.61,
            ic_march: 5.201,
            ic_april: 5.226,
            ic_may: 4.94,
            ic_june: 4.652,
            ic_july: 4.708,
            ic_august: 5.637,
            ic_september: 5.481,
            ic_october: 5.568,
            ic_november: 5.219,
            ic_december: 5.381,
            ic_yearly: 5.246,
          },
          {
            ic_uuid: "4003cb51-9195-4935-8a09-a19d3acfa413",
            ic_lat: -23484,
            ic_lon: -463461,
            ic_city: "Itaquaquecetuba",
            ic_states: "SÃO PAULO",
            ic_january: 5.29,
            ic_february: 5.504,
            ic_march: 4.643,
            ic_april: 4.116,
            ic_may: 3.346,
            ic_june: 3.109,
            ic_july: 3.229,
            ic_august: 4.105,
            ic_september: 4.203,
            ic_october: 4.722,
            ic_november: 5.113,
            ic_december: 5.661,
            ic_yearly: 4.42,
          },
          {
            ic_uuid: "a85c4899-ef09-4a83-87e3-5520c51b4a8d",
            ic_lat: -134463,
            ic_lon: -399381,
            ic_city: "Itaquara",
            ic_states: "BAHIA",
            ic_january: 5.757,
            ic_february: 5.799,
            ic_march: 5.471,
            ic_april: 4.457,
            ic_may: 3.843,
            ic_june: 3.459,
            ic_july: 3.669,
            ic_august: 4.245,
            ic_september: 5.127,
            ic_october: 5.201,
            ic_november: 5.327,
            ic_december: 5.892,
            ic_yearly: 4.854,
          },
          {
            ic_uuid: "8eb581f5-d132-498c-94de-2da737a2962f",
            ic_lat: -291315,
            ic_lon: -56552,
            ic_city: "Itaqui",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.922,
            ic_february: 6.265,
            ic_march: 5.32,
            ic_april: 4.126,
            ic_may: 3.031,
            ic_june: 2.506,
            ic_july: 2.82,
            ic_august: 3.607,
            ic_september: 4.188,
            ic_october: 5.427,
            ic_november: 6.519,
            ic_december: 7.107,
            ic_yearly: 4.82,
          },
          {
            ic_uuid: "eb40d52c-ff9b-407a-bd2f-eeda4ef1518b",
            ic_lat: -234783,
            ic_lon: -541874,
            ic_city: "Itaquiraí",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 6.024,
            ic_february: 5.77,
            ic_march: 5.299,
            ic_april: 4.409,
            ic_may: 3.499,
            ic_june: 3.127,
            ic_july: 3.352,
            ic_august: 4.307,
            ic_september: 4.592,
            ic_october: 5.361,
            ic_november: 6.069,
            ic_december: 6.548,
            ic_yearly: 4.863,
          },
          {
            ic_uuid: "364e1548-fb45-43d7-a935-d7a7528028c3",
            ic_lat: -76642,
            ic_lon: -351005,
            ic_city: "Itaquitinga",
            ic_states: "PERNAMBUCO",
            ic_january: 5.644,
            ic_february: 5.806,
            ic_march: 5.854,
            ic_april: 5.192,
            ic_may: 4.5,
            ic_june: 4.119,
            ic_july: 4.235,
            ic_august: 4.982,
            ic_september: 5.521,
            ic_october: 5.762,
            ic_november: 6.098,
            ic_december: 6.018,
            ic_yearly: 5.311,
          },
          {
            ic_uuid: "5f660169-8ff5-46dc-974f-af617f098f2e",
            ic_lat: -198755,
            ic_lon: -408756,
            ic_city: "Itarana",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.935,
            ic_february: 6.264,
            ic_march: 5.219,
            ic_april: 4.615,
            ic_may: 3.922,
            ic_june: 3.56,
            ic_july: 3.714,
            ic_august: 4.327,
            ic_september: 4.723,
            ic_october: 4.865,
            ic_november: 4.873,
            ic_december: 5.559,
            ic_yearly: 4.798,
          },
          {
            ic_uuid: "152cbae2-2c7f-468b-8134-803e53772071",
            ic_lat: -156533,
            ic_lon: -400653,
            ic_city: "Itarantim",
            ic_states: "BAHIA",
            ic_january: 6.006,
            ic_february: 6.065,
            ic_march: 5.505,
            ic_april: 4.677,
            ic_may: 3.903,
            ic_june: 3.494,
            ic_july: 3.672,
            ic_august: 4.27,
            ic_september: 5.02,
            ic_october: 5.203,
            ic_november: 5.249,
            ic_december: 6.139,
            ic_yearly: 4.934,
          },
          {
            ic_uuid: "ccf9d1cb-190a-4b72-aabe-b2fceb1c0949",
            ic_lat: -241089,
            ic_lon: -493356,
            ic_city: "Itararé",
            ic_states: "SÃO PAULO",
            ic_january: 5.566,
            ic_february: 5.673,
            ic_march: 4.988,
            ic_april: 4.33,
            ic_may: 3.465,
            ic_june: 3.117,
            ic_july: 3.362,
            ic_august: 4.339,
            ic_september: 4.525,
            ic_october: 5.068,
            ic_november: 5.758,
            ic_december: 6.187,
            ic_yearly: 4.698,
          },
          {
            ic_uuid: "6a7ecfad-3f5f-440c-8c89-3dfa9bbcfa34",
            ic_lat: -29252,
            ic_lon: -39917,
            ic_city: "Itarema",
            ic_states: "CEARÁ",
            ic_january: 5.644,
            ic_february: 5.567,
            ic_march: 5.373,
            ic_april: 4.726,
            ic_may: 5.225,
            ic_june: 5.294,
            ic_july: 5.538,
            ic_august: 5.887,
            ic_september: 5.923,
            ic_october: 6.001,
            ic_november: 6.321,
            ic_december: 6.064,
            ic_yearly: 5.63,
          },
          {
            ic_uuid: "9087e853-89e9-4081-b19e-f125f943857a",
            ic_lat: -24289,
            ic_lon: -47174,
            ic_city: "Itariri",
            ic_states: "SÃO PAULO",
            ic_january: 5.244,
            ic_february: 5.425,
            ic_march: 4.57,
            ic_april: 3.952,
            ic_may: 3.191,
            ic_june: 2.819,
            ic_july: 2.855,
            ic_august: 3.578,
            ic_september: 3.611,
            ic_october: 4.047,
            ic_november: 4.797,
            ic_december: 5.483,
            ic_yearly: 4.131,
          },
          {
            ic_uuid: "2ab6b0c3-f365-4259-bc8f-4dbb7207ae8b",
            ic_lat: -18765,
            ic_lon: -513489,
            ic_city: "Itarumã",
            ic_states: "GOIÁS",
            ic_january: 5.777,
            ic_february: 5.841,
            ic_march: 5.396,
            ic_april: 5.085,
            ic_may: 4.483,
            ic_june: 4.196,
            ic_july: 4.407,
            ic_august: 5.307,
            ic_september: 5.195,
            ic_october: 5.613,
            ic_november: 5.78,
            ic_december: 6.026,
            ic_yearly: 5.259,
          },
          {
            ic_uuid: "57e24235-d70b-452a-92e6-2f318b16b979",
            ic_lat: -294933,
            ic_lon: -50103,
            ic_city: "Itati",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 5.478,
            ic_february: 5.001,
            ic_march: 4.289,
            ic_april: 3.708,
            ic_may: 2.794,
            ic_june: 2.408,
            ic_july: 2.649,
            ic_august: 3.327,
            ic_september: 3.429,
            ic_october: 4.257,
            ic_november: 5.513,
            ic_december: 5.821,
            ic_yearly: 4.056,
          },
          {
            ic_uuid: "957521b6-aa69-4066-b7eb-527ed6bd12ab",
            ic_lat: -224902,
            ic_lon: -445679,
            ic_city: "Itatiaia",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.194,
            ic_february: 5.384,
            ic_march: 4.594,
            ic_april: 4.244,
            ic_may: 3.542,
            ic_june: 3.334,
            ic_july: 3.424,
            ic_august: 4.336,
            ic_september: 4.434,
            ic_october: 4.705,
            ic_november: 4.698,
            ic_december: 5.343,
            ic_yearly: 4.436,
          },
          {
            ic_uuid: "51c316a4-f4ae-4647-a7a1-c430d33829b6",
            ic_lat: -201987,
            ic_lon: -444215,
            ic_city: "Itatiaiuçu",
            ic_states: "MINAS GERAIS",
            ic_january: 5.721,
            ic_february: 5.885,
            ic_march: 4.999,
            ic_april: 4.723,
            ic_may: 4.202,
            ic_june: 4.056,
            ic_july: 4.303,
            ic_august: 5.185,
            ic_september: 5.447,
            ic_october: 5.506,
            ic_november: 5.207,
            ic_december: 5.511,
            ic_yearly: 5.062,
          },
          {
            ic_uuid: "ed0590dc-0573-4f9e-8f17-825fc9745c28",
            ic_lat: -23004,
            ic_lon: -468468,
            ic_city: "Itatiba",
            ic_states: "SÃO PAULO",
            ic_january: 5.521,
            ic_february: 5.808,
            ic_march: 5.067,
            ic_april: 4.61,
            ic_may: 3.774,
            ic_june: 3.514,
            ic_july: 3.67,
            ic_august: 4.623,
            ic_september: 4.779,
            ic_october: 5.389,
            ic_november: 5.676,
            ic_december: 6.091,
            ic_yearly: 4.877,
          },
          {
            ic_uuid: "4abdd2cb-8655-4e92-8ab1-6518269cd007",
            ic_lat: -273851,
            ic_lon: -524542,
            ic_city: "Itatiba do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.226,
            ic_february: 5.791,
            ic_march: 5.105,
            ic_april: 4.096,
            ic_may: 3.07,
            ic_june: 2.537,
            ic_july: 2.891,
            ic_august: 3.741,
            ic_september: 4.003,
            ic_october: 5.115,
            ic_november: 6.243,
            ic_december: 6.566,
            ic_yearly: 4.615,
          },
          {
            ic_uuid: "75af9e64-a3b5-406d-b8f6-a42c151a2330",
            ic_lat: -127136,
            ic_lon: -396971,
            ic_city: "Itatim",
            ic_states: "BAHIA",
            ic_january: 5.943,
            ic_february: 5.905,
            ic_march: 5.671,
            ic_april: 4.61,
            ic_may: 3.97,
            ic_june: 3.65,
            ic_july: 3.859,
            ic_august: 4.386,
            ic_september: 5.185,
            ic_october: 5.313,
            ic_november: 5.547,
            ic_december: 5.938,
            ic_yearly: 4.998,
          },
          {
            ic_uuid: "cf4bb1a9-f364-4c91-88ad-d154139c6b49",
            ic_lat: -231052,
            ic_lon: -486161,
            ic_city: "Itatinga",
            ic_states: "SÃO PAULO",
            ic_january: 5.436,
            ic_february: 5.613,
            ic_march: 5.016,
            ic_april: 4.546,
            ic_may: 3.641,
            ic_june: 3.397,
            ic_july: 3.536,
            ic_august: 4.566,
            ic_september: 4.691,
            ic_october: 5.238,
            ic_november: 5.696,
            ic_december: 5.985,
            ic_yearly: 4.78,
          },
          {
            ic_uuid: "e041eb36-7f43-4ca4-9cdf-7ecf912418a5",
            ic_lat: -45265,
            ic_lon: -396205,
            ic_city: "Itatira",
            ic_states: "CEARÁ",
            ic_january: 5.231,
            ic_february: 5.343,
            ic_march: 5.334,
            ic_april: 4.851,
            ic_may: 4.889,
            ic_june: 4.85,
            ic_july: 5.304,
            ic_august: 6.121,
            ic_september: 6.625,
            ic_october: 6.518,
            ic_november: 6.255,
            ic_december: 5.567,
            ic_yearly: 5.574,
          },
          {
            ic_uuid: "1805f54a-0134-40f8-8969-f25095582662",
            ic_lat: -73784,
            ic_lon: -356277,
            ic_city: "Itatuba",
            ic_states: "PARAÍBA",
            ic_january: 5.614,
            ic_february: 5.75,
            ic_march: 5.81,
            ic_april: 5.248,
            ic_may: 4.576,
            ic_june: 4.099,
            ic_july: 4.219,
            ic_august: 4.908,
            ic_september: 5.437,
            ic_october: 5.732,
            ic_november: 6.061,
            ic_december: 5.866,
            ic_yearly: 5.277,
          },
          {
            ic_uuid: "fea150a6-134f-4e3e-98a9-3f1af84bc117",
            ic_lat: -58367,
            ic_lon: -379916,
            ic_city: "Itaú",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.007,
            ic_february: 6.083,
            ic_march: 6.08,
            ic_april: 5.728,
            ic_may: 5.366,
            ic_june: 5.047,
            ic_july: 5.407,
            ic_august: 6.075,
            ic_september: 6.433,
            ic_october: 6.665,
            ic_november: 6.655,
            ic_december: 6.149,
            ic_yearly: 5.974,
          },
          {
            ic_uuid: "436ac04d-4306-4f04-a80d-89ea6af62f20",
            ic_lat: -20738,
            ic_lon: -467529,
            ic_city: "Itaú de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.769,
            ic_february: 5.978,
            ic_march: 5.104,
            ic_april: 4.846,
            ic_may: 4.239,
            ic_june: 4.037,
            ic_july: 4.255,
            ic_august: 5.143,
            ic_september: 5.168,
            ic_october: 5.621,
            ic_november: 5.608,
            ic_december: 5.814,
            ic_yearly: 5.132,
          },
          {
            ic_uuid: "342f7882-8688-421c-953e-e34ab2f61043",
            ic_lat: -110075,
            ic_lon: -552435,
            ic_city: "Itaúba",
            ic_states: "MATO GROSSO",
            ic_january: 4.932,
            ic_february: 4.815,
            ic_march: 4.71,
            ic_april: 4.631,
            ic_may: 4.665,
            ic_june: 4.697,
            ic_july: 5.049,
            ic_august: 5.441,
            ic_september: 5.038,
            ic_october: 5.07,
            ic_november: 4.954,
            ic_december: 4.947,
            ic_yearly: 4.912,
          },
          {
            ic_uuid: "4989ae0c-9fca-4e11-8ae8-f9c278a14a7e",
            ic_lat: 6056,
            ic_lon: -506991,
            ic_city: "Itaubal",
            ic_states: "AMAPÁ",
            ic_january: 4.304,
            ic_february: 4.042,
            ic_march: 3.977,
            ic_april: 3.99,
            ic_may: 4.45,
            ic_june: 4.823,
            ic_july: 5.047,
            ic_august: 5.461,
            ic_september: 5.518,
            ic_october: 5.448,
            ic_november: 5.133,
            ic_december: 4.623,
            ic_yearly: 4.735,
          },
          {
            ic_uuid: "87092b26-ebc9-4ecb-a645-1638e0a1f774",
            ic_lat: -162036,
            ic_lon: -496048,
            ic_city: "Itauçu",
            ic_states: "GOIÁS",
            ic_january: 5.447,
            ic_february: 5.588,
            ic_march: 5.126,
            ic_april: 5.119,
            ic_may: 4.851,
            ic_june: 4.619,
            ic_july: 4.801,
            ic_august: 5.699,
            ic_september: 5.49,
            ic_october: 5.54,
            ic_november: 5.294,
            ic_december: 5.396,
            ic_yearly: 5.248,
          },
          {
            ic_uuid: "f4ab6cf0-2500-461c-a113-edcd60f757b2",
            ic_lat: -76003,
            ic_lon: -430252,
            ic_city: "Itaueira",
            ic_states: "PIAUÍ",
            ic_january: 5.41,
            ic_february: 5.357,
            ic_march: 5.331,
            ic_april: 5.283,
            ic_may: 5.28,
            ic_june: 5.282,
            ic_july: 5.613,
            ic_august: 6.163,
            ic_september: 6.575,
            ic_october: 6.378,
            ic_november: 5.993,
            ic_december: 5.791,
            ic_yearly: 5.705,
          },
          {
            ic_uuid: "45eb9b8d-d159-4708-9fb7-0832b99ed688",
            ic_lat: -200823,
            ic_lon: -445805,
            ic_city: "Itaúna",
            ic_states: "MINAS GERAIS",
            ic_january: 5.751,
            ic_february: 5.962,
            ic_march: 5.04,
            ic_april: 4.828,
            ic_may: 4.2,
            ic_june: 4.08,
            ic_july: 4.332,
            ic_august: 5.189,
            ic_september: 5.448,
            ic_october: 5.544,
            ic_november: 5.26,
            ic_december: 5.586,
            ic_yearly: 5.102,
          },
          {
            ic_uuid: "d62812b8-fc18-4d96-9f17-e863ea31786f",
            ic_lat: -227309,
            ic_lon: -528869,
            ic_city: "Itaúna do Sul",
            ic_states: "PARANÁ",
            ic_january: 6.088,
            ic_february: 6.052,
            ic_march: 5.461,
            ic_april: 4.656,
            ic_may: 3.742,
            ic_june: 3.414,
            ic_july: 3.591,
            ic_august: 4.506,
            ic_september: 4.778,
            ic_october: 5.448,
            ic_november: 6.19,
            ic_december: 6.567,
            ic_yearly: 5.041,
          },
          {
            ic_uuid: "1b77355a-4617-4cb8-9c22-915a5420fd36",
            ic_lat: -206773,
            ic_lon: -436145,
            ic_city: "Itaverava",
            ic_states: "MINAS GERAIS",
            ic_january: 5.55,
            ic_february: 5.947,
            ic_march: 4.861,
            ic_april: 4.413,
            ic_may: 3.856,
            ic_june: 3.641,
            ic_july: 3.808,
            ic_august: 4.812,
            ic_september: 5.02,
            ic_october: 5.165,
            ic_november: 4.805,
            ic_december: 5.485,
            ic_yearly: 4.78,
          },
          {
            ic_uuid: "ff725be3-848a-4dc0-b288-7f0757563b19",
            ic_lat: -166139,
            ic_lon: -417681,
            ic_city: "Itinga",
            ic_states: "MINAS GERAIS",
            ic_january: 6.389,
            ic_february: 6.625,
            ic_march: 5.616,
            ic_april: 4.909,
            ic_may: 4.181,
            ic_june: 3.849,
            ic_july: 4.053,
            ic_august: 4.809,
            ic_september: 5.516,
            ic_october: 5.746,
            ic_november: 5.53,
            ic_december: 6.279,
            ic_yearly: 5.292,
          },
          {
            ic_uuid: "650c16ba-b3f0-402e-a1b3-8f7155c9e1b8",
            ic_lat: -44556,
            ic_lon: -475303,
            ic_city: "Itinga do Maranhão",
            ic_states: "MARANHÃO",
            ic_january: 4.433,
            ic_february: 4.658,
            ic_march: 4.687,
            ic_april: 4.793,
            ic_may: 4.678,
            ic_june: 4.853,
            ic_july: 4.962,
            ic_august: 5.247,
            ic_september: 5.223,
            ic_october: 4.887,
            ic_november: 4.641,
            ic_december: 4.549,
            ic_yearly: 4.801,
          },
          {
            ic_uuid: "85c3e74a-f749-4288-b1e6-8d2a92467414",
            ic_lat: -172049,
            ic_lon: -54146,
            ic_city: "Itiquira",
            ic_states: "MATO GROSSO",
            ic_january: 5.581,
            ic_february: 5.513,
            ic_march: 5.217,
            ic_april: 4.991,
            ic_may: 4.542,
            ic_june: 4.363,
            ic_july: 4.564,
            ic_august: 5.406,
            ic_september: 5.219,
            ic_october: 5.275,
            ic_november: 5.6,
            ic_december: 5.738,
            ic_yearly: 5.168,
          },
          {
            ic_uuid: "d9bfd973-b44d-4e9f-a9a5-4aa661eca45a",
            ic_lat: -222567,
            ic_lon: -47817,
            ic_city: "Itirapina",
            ic_states: "SÃO PAULO",
            ic_january: 5.425,
            ic_february: 5.647,
            ic_march: 5.028,
            ic_april: 4.633,
            ic_may: 3.863,
            ic_june: 3.635,
            ic_july: 3.818,
            ic_august: 4.771,
            ic_september: 4.852,
            ic_october: 5.448,
            ic_november: 5.649,
            ic_december: 5.892,
            ic_yearly: 4.888,
          },
          {
            ic_uuid: "40245ef8-ca55-4adb-85ea-1b54f129740b",
            ic_lat: -206421,
            ic_lon: -472198,
            ic_city: "Itirapuã",
            ic_states: "SÃO PAULO",
            ic_january: 5.538,
            ic_february: 5.788,
            ic_march: 4.98,
            ic_april: 4.825,
            ic_may: 4.244,
            ic_june: 4.042,
            ic_july: 4.282,
            ic_august: 5.237,
            ic_september: 5.143,
            ic_october: 5.476,
            ic_november: 5.546,
            ic_december: 5.688,
            ic_yearly: 5.066,
          },
          {
            ic_uuid: "fb2cc090-547e-4317-af3c-0d252cca3e11",
            ic_lat: -135343,
            ic_lon: -401513,
            ic_city: "Itiruçu",
            ic_states: "BAHIA",
            ic_january: 5.743,
            ic_february: 5.803,
            ic_march: 5.423,
            ic_april: 4.433,
            ic_may: 3.904,
            ic_june: 3.457,
            ic_july: 3.675,
            ic_august: 4.246,
            ic_september: 5.166,
            ic_october: 5.244,
            ic_november: 5.384,
            ic_december: 5.869,
            ic_yearly: 4.862,
          },
          {
            ic_uuid: "a1a8b27f-2398-4795-b091-b509586bebdf",
            ic_lat: -106914,
            ic_lon: -398543,
            ic_city: "Itiúba",
            ic_states: "BAHIA",
            ic_january: 6.165,
            ic_february: 5.93,
            ic_march: 5.799,
            ic_april: 4.82,
            ic_may: 4.157,
            ic_june: 3.791,
            ic_july: 4.038,
            ic_august: 4.641,
            ic_september: 5.597,
            ic_october: 5.723,
            ic_november: 6.025,
            ic_december: 6.017,
            ic_yearly: 5.225,
          },
          {
            ic_uuid: "06699032-8af4-4aa0-8004-2f1d8df324cb",
            ic_lat: -217313,
            ic_lon: -469747,
            ic_city: "Itobi",
            ic_states: "SÃO PAULO",
            ic_january: 5.696,
            ic_february: 5.878,
            ic_march: 5.215,
            ic_april: 4.858,
            ic_may: 4.052,
            ic_june: 3.805,
            ic_july: 3.984,
            ic_august: 4.866,
            ic_september: 5.028,
            ic_october: 5.594,
            ic_november: 5.836,
            ic_december: 6.0,
            ic_yearly: 5.068,
          },
          {
            ic_uuid: "946c5ad9-02bd-4a08-8623-a9ad74b9023d",
            ic_lat: -151146,
            ic_lon: -400679,
            ic_city: "Itororó",
            ic_states: "BAHIA",
            ic_january: 5.866,
            ic_february: 5.959,
            ic_march: 5.381,
            ic_april: 4.601,
            ic_may: 3.908,
            ic_june: 3.469,
            ic_july: 3.687,
            ic_august: 4.213,
            ic_september: 4.905,
            ic_october: 5.135,
            ic_november: 5.247,
            ic_december: 5.994,
            ic_yearly: 4.863,
          },
          {
            ic_uuid: "d278ceae-4119-4ef3-aabf-a7d279277dce",
            ic_lat: -232549,
            ic_lon: -472931,
            ic_city: "Itu",
            ic_states: "SÃO PAULO",
            ic_january: 5.562,
            ic_february: 5.802,
            ic_march: 5.087,
            ic_april: 4.563,
            ic_may: 3.708,
            ic_june: 3.406,
            ic_july: 3.558,
            ic_august: 4.524,
            ic_september: 4.705,
            ic_october: 5.307,
            ic_november: 5.731,
            ic_december: 6.143,
            ic_yearly: 4.841,
          },
          {
            ic_uuid: "04e0ed29-62fa-48d3-9a8d-c056dc6804b8",
            ic_lat: -138111,
            ic_lon: -413006,
            ic_city: "Ituaçu",
            ic_states: "BAHIA",
            ic_january: 6.175,
            ic_february: 6.147,
            ic_march: 5.633,
            ic_april: 4.763,
            ic_may: 4.233,
            ic_june: 3.878,
            ic_july: 4.108,
            ic_august: 4.792,
            ic_september: 5.708,
            ic_october: 5.93,
            ic_november: 5.718,
            ic_december: 6.321,
            ic_yearly: 5.284,
          },
          {
            ic_uuid: "e8446a1c-7dcc-49c9-bfdc-7de56880a16c",
            ic_lat: -13725,
            ic_lon: -391487,
            ic_city: "Ituberá",
            ic_states: "BAHIA",
            ic_january: 6.0,
            ic_february: 5.845,
            ic_march: 5.58,
            ic_april: 4.562,
            ic_may: 3.905,
            ic_june: 3.551,
            ic_july: 3.804,
            ic_august: 4.259,
            ic_september: 4.967,
            ic_october: 5.125,
            ic_november: 5.404,
            ic_december: 5.962,
            ic_yearly: 4.914,
          },
          {
            ic_uuid: "2655b488-0736-4e56-b4f3-368ae1a47a52",
            ic_lat: -194004,
            ic_lon: -41175,
            ic_city: "Itueta",
            ic_states: "MINAS GERAIS",
            ic_january: 6.135,
            ic_february: 6.304,
            ic_march: 5.342,
            ic_april: 4.67,
            ic_may: 3.98,
            ic_june: 3.655,
            ic_july: 3.769,
            ic_august: 4.338,
            ic_september: 4.861,
            ic_october: 5.018,
            ic_november: 5.022,
            ic_december: 5.835,
            ic_yearly: 4.911,
          },
          {
            ic_uuid: "a1c9f988-5261-4086-9249-9b8e0d2b743c",
            ic_lat: -189776,
            ic_lon: -494644,
            ic_city: "Ituiutaba",
            ic_states: "MINAS GERAIS",
            ic_january: 5.722,
            ic_february: 5.724,
            ic_march: 5.229,
            ic_april: 4.986,
            ic_may: 4.45,
            ic_june: 4.255,
            ic_july: 4.482,
            ic_august: 5.421,
            ic_september: 5.29,
            ic_october: 5.608,
            ic_november: 5.725,
            ic_december: 5.92,
            ic_yearly: 5.234,
          },
          {
            ic_uuid: "8d4feb68-9e0f-43f9-b9d9-ce4f7e5f3938",
            ic_lat: -184097,
            ic_lon: -492162,
            ic_city: "Itumbiara",
            ic_states: "GOIÁS",
            ic_january: 5.588,
            ic_february: 5.695,
            ic_march: 5.177,
            ic_april: 5.062,
            ic_may: 4.623,
            ic_june: 4.388,
            ic_july: 4.569,
            ic_august: 5.548,
            ic_september: 5.308,
            ic_october: 5.58,
            ic_november: 5.597,
            ic_december: 5.775,
            ic_yearly: 5.242,
          },
          {
            ic_uuid: "08e41154-5d42-4570-b95c-dfebfda1f20a",
            ic_lat: -213175,
            ic_lon: -448727,
            ic_city: "Itumirim",
            ic_states: "MINAS GERAIS",
            ic_january: 5.684,
            ic_february: 5.867,
            ic_march: 5.019,
            ic_april: 4.637,
            ic_may: 3.93,
            ic_june: 3.72,
            ic_july: 3.992,
            ic_august: 4.92,
            ic_september: 5.115,
            ic_october: 5.413,
            ic_november: 5.258,
            ic_december: 5.717,
            ic_yearly: 4.939,
          },
          {
            ic_uuid: "b57b7c26-86ea-4e0e-9a38-b022740441f2",
            ic_lat: -231531,
            ic_lon: -470597,
            ic_city: "Itupeva",
            ic_states: "SÃO PAULO",
            ic_january: 5.481,
            ic_february: 5.766,
            ic_march: 5.061,
            ic_april: 4.551,
            ic_may: 3.724,
            ic_june: 3.456,
            ic_july: 3.602,
            ic_august: 4.581,
            ic_september: 4.731,
            ic_october: 5.363,
            ic_november: 5.683,
            ic_december: 6.121,
            ic_yearly: 4.843,
          },
          {
            ic_uuid: "6d3279ea-69d9-4e66-8f43-1fb4bd707dd5",
            ic_lat: -51324,
            ic_lon: -493311,
            ic_city: "Itupiranga",
            ic_states: "PARÁ",
            ic_january: 4.404,
            ic_february: 4.623,
            ic_march: 4.566,
            ic_april: 4.659,
            ic_may: 4.665,
            ic_june: 5.02,
            ic_july: 5.116,
            ic_august: 5.454,
            ic_september: 5.185,
            ic_october: 4.795,
            ic_november: 4.644,
            ic_december: 4.377,
            ic_yearly: 4.793,
          },
          {
            ic_uuid: "79195d83-cee0-490d-ae5e-b96871d159de",
            ic_lat: -274173,
            ic_lon: -495996,
            ic_city: "Ituporanga",
            ic_states: "SANTA CATARINA",
            ic_january: 5.662,
            ic_february: 5.348,
            ic_march: 4.633,
            ic_april: 3.65,
            ic_may: 2.865,
            ic_june: 2.343,
            ic_july: 2.54,
            ic_august: 3.333,
            ic_september: 3.517,
            ic_october: 4.129,
            ic_november: 5.419,
            ic_december: 5.785,
            ic_yearly: 4.102,
          },
          {
            ic_uuid: "6b3b05bd-b597-43f3-883d-d0e1daa9de85",
            ic_lat: -19728,
            ic_lon: -50197,
            ic_city: "Iturama",
            ic_states: "MINAS GERAIS",
            ic_january: 5.739,
            ic_february: 5.873,
            ic_march: 5.31,
            ic_april: 4.991,
            ic_may: 4.317,
            ic_june: 4.026,
            ic_july: 4.329,
            ic_august: 5.153,
            ic_september: 5.168,
            ic_october: 5.644,
            ic_november: 5.826,
            ic_december: 6.093,
            ic_yearly: 5.206,
          },
          {
            ic_uuid: "1ce8f11f-4b52-4c1d-b5aa-26785f9ad10d",
            ic_lat: -213005,
            ic_lon: -446571,
            ic_city: "Itutinga",
            ic_states: "MINAS GERAIS",
            ic_january: 5.661,
            ic_february: 5.805,
            ic_march: 5.004,
            ic_april: 4.602,
            ic_may: 3.92,
            ic_june: 3.682,
            ic_july: 3.899,
            ic_august: 4.859,
            ic_september: 5.092,
            ic_october: 5.366,
            ic_november: 5.176,
            ic_december: 5.636,
            ic_yearly: 4.892,
          },
          {
            ic_uuid: "2f0e59b1-ed6c-4ef5-8dc5-e73ad6d70db9",
            ic_lat: -203359,
            ic_lon: -477906,
            ic_city: "Ituverava",
            ic_states: "SÃO PAULO",
            ic_january: 5.687,
            ic_february: 5.969,
            ic_march: 5.189,
            ic_april: 4.949,
            ic_may: 4.296,
            ic_june: 4.065,
            ic_july: 4.314,
            ic_august: 5.235,
            ic_september: 5.159,
            ic_october: 5.565,
            ic_november: 5.722,
            ic_december: 6.052,
            ic_yearly: 5.184,
          },
          {
            ic_uuid: "371b6d89-abec-4cc8-af73-b698e9a45fb3",
            ic_lat: -144107,
            ic_lon: -435511,
            ic_city: "Iuiú",
            ic_states: "BAHIA",
            ic_january: 6.523,
            ic_february: 6.632,
            ic_march: 5.917,
            ic_april: 5.543,
            ic_may: 4.869,
            ic_june: 4.715,
            ic_july: 5.034,
            ic_august: 5.563,
            ic_september: 5.899,
            ic_october: 6.287,
            ic_november: 5.874,
            ic_december: 6.497,
            ic_yearly: 5.779,
          },
          {
            ic_uuid: "120b1fa7-9f94-4aac-a728-87ec0dad293d",
            ic_lat: -203536,
            ic_lon: -415338,
            ic_city: "Iúna",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.859,
            ic_february: 6.129,
            ic_march: 5.018,
            ic_april: 4.46,
            ic_may: 3.814,
            ic_june: 3.66,
            ic_july: 3.728,
            ic_august: 4.482,
            ic_september: 4.84,
            ic_october: 4.963,
            ic_november: 4.798,
            ic_december: 5.639,
            ic_yearly: 4.782,
          },
          {
            ic_uuid: "6bf13da7-919b-4809-a498-eb5ee6760121",
            ic_lat: -250072,
            ic_lon: -508575,
            ic_city: "Ivaí",
            ic_states: "PARANÁ",
            ic_january: 5.771,
            ic_february: 5.462,
            ic_march: 4.945,
            ic_april: 4.133,
            ic_may: 3.261,
            ic_june: 2.865,
            ic_july: 3.135,
            ic_august: 4.137,
            ic_september: 4.301,
            ic_october: 4.949,
            ic_november: 5.808,
            ic_december: 6.001,
            ic_yearly: 4.564,
          },
          {
            ic_uuid: "16851032-ef41-4d72-a482-d346fb1940d9",
            ic_lat: -24249,
            ic_lon: -516759,
            ic_city: "Ivaiporã",
            ic_states: "PARANÁ",
            ic_january: 6.036,
            ic_february: 5.778,
            ic_march: 5.277,
            ic_april: 4.582,
            ic_may: 3.531,
            ic_june: 3.16,
            ic_july: 3.386,
            ic_august: 4.391,
            ic_september: 4.562,
            ic_october: 5.263,
            ic_november: 6.101,
            ic_december: 6.384,
            ic_yearly: 4.871,
          },
          {
            ic_uuid: "e45ebbcc-d6e9-40db-8686-a874d39fb663",
            ic_lat: -234077,
            ic_lon: -533691,
            ic_city: "Ivaté",
            ic_states: "PARANÁ",
            ic_january: 6.036,
            ic_february: 5.868,
            ic_march: 5.373,
            ic_april: 4.521,
            ic_may: 3.59,
            ic_june: 3.225,
            ic_july: 3.429,
            ic_august: 4.382,
            ic_september: 4.619,
            ic_october: 5.429,
            ic_november: 6.167,
            ic_december: 6.501,
            ic_yearly: 4.928,
          },
          {
            ic_uuid: "9b264ea8-6134-4e3b-99bc-8a15bfe1ad91",
            ic_lat: -236192,
            ic_lon: -522207,
            ic_city: "Ivatuba",
            ic_states: "PARANÁ",
            ic_january: 6.084,
            ic_february: 5.68,
            ic_march: 5.217,
            ic_april: 4.593,
            ic_may: 3.639,
            ic_june: 3.244,
            ic_july: 3.45,
            ic_august: 4.341,
            ic_september: 4.63,
            ic_october: 5.281,
            ic_november: 6.047,
            ic_december: 6.484,
            ic_yearly: 4.891,
          },
          {
            ic_uuid: "427d282b-8166-4977-8ed2-df1b85408a94",
            ic_lat: -22305,
            ic_lon: -538189,
            ic_city: "Ivinhema",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.865,
            ic_february: 5.801,
            ic_march: 5.386,
            ic_april: 4.612,
            ic_may: 3.731,
            ic_june: 3.378,
            ic_july: 3.562,
            ic_august: 4.519,
            ic_september: 4.762,
            ic_october: 5.45,
            ic_november: 5.994,
            ic_december: 6.426,
            ic_yearly: 4.957,
          },
          {
            ic_uuid: "41302c73-732a-4659-a0b6-07034951c9b1",
            ic_lat: -166061,
            ic_lon: -507924,
            ic_city: "Ivolândia",
            ic_states: "GOIÁS",
            ic_january: 5.579,
            ic_february: 5.607,
            ic_march: 5.283,
            ic_april: 5.196,
            ic_may: 4.851,
            ic_june: 4.503,
            ic_july: 4.763,
            ic_august: 5.504,
            ic_september: 5.394,
            ic_october: 5.556,
            ic_november: 5.474,
            ic_december: 5.573,
            ic_yearly: 5.273,
          },
          {
            ic_uuid: "dd34b13c-1bd2-4f91-8f58-c5d54c0352e6",
            ic_lat: -2952,
            ic_lon: -535809,
            ic_city: "Ivorá",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.281,
            ic_february: 5.92,
            ic_march: 5.01,
            ic_april: 3.871,
            ic_may: 2.877,
            ic_june: 2.369,
            ic_july: 2.624,
            ic_august: 3.381,
            ic_september: 3.782,
            ic_october: 4.906,
            ic_november: 6.254,
            ic_december: 6.722,
            ic_yearly: 4.5,
          },
          {
            ic_uuid: "ac99152b-a522-402c-8e56-905aaa9d0831",
            ic_lat: -295999,
            ic_lon: -511538,
            ic_city: "Ivoti",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.22,
            ic_february: 5.755,
            ic_march: 4.791,
            ic_april: 3.776,
            ic_may: 2.816,
            ic_june: 2.34,
            ic_july: 2.534,
            ic_august: 3.222,
            ic_september: 3.538,
            ic_october: 4.631,
            ic_november: 6.081,
            ic_december: 6.55,
            ic_yearly: 4.354,
          },
          {
            ic_uuid: "d1b35876-e0cc-41ff-a2ef-d2937fbda477",
            ic_lat: -81653,
            ic_lon: -349212,
            ic_city: "Jaboatão dos Guararapes",
            ic_states: "PERNAMBUCO",
            ic_january: 5.922,
            ic_february: 6.009,
            ic_march: 5.975,
            ic_april: 5.226,
            ic_may: 4.552,
            ic_june: 4.252,
            ic_july: 4.327,
            ic_august: 5.064,
            ic_september: 5.614,
            ic_october: 5.956,
            ic_november: 6.196,
            ic_december: 6.253,
            ic_yearly: 5.445,
          },
          {
            ic_uuid: "69aedf80-2da0-46a7-b94e-87682742c777",
            ic_lat: -271741,
            ic_lon: -517365,
            ic_city: "Jaborá",
            ic_states: "SANTA CATARINA",
            ic_january: 5.951,
            ic_february: 5.509,
            ic_march: 4.998,
            ic_april: 4.033,
            ic_may: 3.099,
            ic_june: 2.667,
            ic_july: 2.945,
            ic_august: 3.77,
            ic_september: 4.034,
            ic_october: 4.96,
            ic_november: 6.111,
            ic_december: 6.288,
            ic_yearly: 4.53,
          },
          {
            ic_uuid: "57b4bf0d-b20d-454a-a029-b540e0fe0e8c",
            ic_lat: -136116,
            ic_lon: -44476,
            ic_city: "Jaborandi",
            ic_states: "BAHIA",
            ic_january: 6.443,
            ic_february: 6.341,
            ic_march: 5.716,
            ic_april: 5.53,
            ic_may: 5.131,
            ic_june: 4.94,
            ic_july: 5.301,
            ic_august: 5.837,
            ic_september: 6.149,
            ic_october: 6.25,
            ic_november: 5.75,
            ic_december: 6.265,
            ic_yearly: 5.804,
          },
          {
            ic_uuid: "511979d2-e6d2-4114-8525-67cd899d15ad",
            ic_lat: -206889,
            ic_lon: -484116,
            ic_city: "Jaborandi",
            ic_states: "SÃO PAULO",
            ic_january: 5.824,
            ic_february: 6.061,
            ic_march: 5.216,
            ic_april: 4.865,
            ic_may: 4.234,
            ic_june: 3.971,
            ic_july: 4.203,
            ic_august: 5.062,
            ic_september: 5.042,
            ic_october: 5.599,
            ic_november: 5.818,
            ic_december: 6.17,
            ic_yearly: 5.172,
          },
          {
            ic_uuid: "3fbae260-3fa3-43a5-be24-70a19d392c43",
            ic_lat: -23744,
            ic_lon: -500733,
            ic_city: "Jaboti",
            ic_states: "PARANÁ",
            ic_january: 5.763,
            ic_february: 5.803,
            ic_march: 5.162,
            ic_april: 4.516,
            ic_may: 3.611,
            ic_june: 3.219,
            ic_july: 3.485,
            ic_august: 4.459,
            ic_september: 4.643,
            ic_october: 5.263,
            ic_november: 5.968,
            ic_december: 6.322,
            ic_yearly: 4.851,
          },
          {
            ic_uuid: "96458f41-e8c9-4b0a-80e8-bfdc5d5ddec4",
            ic_lat: -276352,
            ic_lon: -532766,
            ic_city: "Jaboticaba",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.305,
            ic_february: 5.91,
            ic_march: 5.082,
            ic_april: 4.114,
            ic_may: 3.117,
            ic_june: 2.548,
            ic_july: 2.913,
            ic_august: 3.71,
            ic_september: 3.989,
            ic_october: 5.181,
            ic_november: 6.281,
            ic_december: 6.662,
            ic_yearly: 4.651,
          },
          {
            ic_uuid: "e6e98755-c1ff-4a33-a22f-99093da07765",
            ic_lat: -212525,
            ic_lon: -483256,
            ic_city: "Jaboticabal",
            ic_states: "SÃO PAULO",
            ic_january: 5.782,
            ic_february: 5.948,
            ic_march: 5.17,
            ic_april: 4.799,
            ic_may: 4.113,
            ic_june: 3.859,
            ic_july: 4.076,
            ic_august: 4.882,
            ic_september: 5.005,
            ic_october: 5.585,
            ic_november: 5.842,
            ic_december: 6.119,
            ic_yearly: 5.099,
          },
          {
            ic_uuid: "d5368de0-0f6d-47ff-bb35-c8b24bb834df",
            ic_lat: -19514,
            ic_lon: -43745,
            ic_city: "Jaboticatubas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.933,
            ic_february: 6.092,
            ic_march: 5.303,
            ic_april: 4.887,
            ic_may: 4.387,
            ic_june: 4.134,
            ic_july: 4.438,
            ic_august: 5.267,
            ic_september: 5.677,
            ic_october: 5.749,
            ic_november: 5.482,
            ic_december: 5.774,
            ic_yearly: 5.26,
          },
          {
            ic_uuid: "583e2e42-28d6-41f9-8220-673a9f917ad1",
            ic_lat: -64188,
            ic_lon: -362033,
            ic_city: "Jaçanã",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.927,
            ic_february: 5.935,
            ic_march: 5.917,
            ic_april: 5.524,
            ic_may: 4.959,
            ic_june: 4.551,
            ic_july: 4.747,
            ic_august: 5.511,
            ic_september: 6.021,
            ic_october: 6.299,
            ic_november: 6.382,
            ic_december: 6.0,
            ic_yearly: 5.648,
          },
          {
            ic_uuid: "ddc171d6-960a-43d5-b648-1709575f2f2e",
            ic_lat: -148431,
            ic_lon: -42432,
            ic_city: "Jacaraci",
            ic_states: "BAHIA",
            ic_january: 6.097,
            ic_february: 6.288,
            ic_march: 5.557,
            ic_april: 4.976,
            ic_may: 4.399,
            ic_june: 4.084,
            ic_july: 4.451,
            ic_august: 5.186,
            ic_september: 5.765,
            ic_october: 5.964,
            ic_november: 5.486,
            ic_december: 6.127,
            ic_yearly: 5.365,
          },
          {
            ic_uuid: "311fe362-16cd-403d-99cd-8e8142c3593e",
            ic_lat: -66149,
            ic_lon: -352893,
            ic_city: "Jacaraú",
            ic_states: "PARAÍBA",
            ic_january: 5.921,
            ic_february: 5.976,
            ic_march: 5.983,
            ic_april: 5.401,
            ic_may: 4.835,
            ic_june: 4.381,
            ic_july: 4.486,
            ic_august: 5.238,
            ic_september: 5.712,
            ic_october: 5.954,
            ic_november: 6.258,
            ic_december: 6.048,
            ic_yearly: 5.516,
          },
          {
            ic_uuid: "f9e480cd-5bf1-474b-91d3-e0bf445f0b9d",
            ic_lat: -96359,
            ic_lon: -372079,
            ic_city: "Jacaré dos Homens",
            ic_states: "ALAGOAS",
            ic_january: 6.104,
            ic_february: 5.882,
            ic_march: 5.92,
            ic_april: 5.223,
            ic_may: 4.355,
            ic_june: 3.979,
            ic_july: 4.124,
            ic_august: 4.68,
            ic_september: 5.469,
            ic_october: 5.85,
            ic_november: 6.462,
            ic_december: 6.268,
            ic_yearly: 5.36,
          },
          {
            ic_uuid: "3d40fd26-f7ae-4ab4-bd97-6e2e71d500f4",
            ic_lat: -62151,
            ic_lon: -577548,
            ic_city: "Jacareacanga",
            ic_states: "PARÁ",
            ic_january: 4.198,
            ic_february: 4.386,
            ic_march: 4.314,
            ic_april: 4.08,
            ic_may: 4.147,
            ic_june: 4.591,
            ic_july: 4.562,
            ic_august: 5.279,
            ic_september: 5.053,
            ic_october: 4.897,
            ic_november: 4.752,
            ic_december: 4.488,
            ic_yearly: 4.562,
          },
          {
            ic_uuid: "885799fc-379b-45b6-b47c-18255acfc7e2",
            ic_lat: -232988,
            ic_lon: -459662,
            ic_city: "Jacareí",
            ic_states: "SÃO PAULO",
            ic_january: 5.378,
            ic_february: 5.657,
            ic_march: 4.809,
            ic_april: 4.264,
            ic_may: 3.403,
            ic_june: 3.144,
            ic_july: 3.273,
            ic_august: 4.228,
            ic_september: 4.33,
            ic_october: 4.983,
            ic_november: 5.236,
            ic_december: 5.809,
            ic_yearly: 4.543,
          },
          {
            ic_uuid: "e5abaafd-5bed-48ef-a8cc-8914bf39fda3",
            ic_lat: -231596,
            ic_lon: -499743,
            ic_city: "Jacarezinho",
            ic_states: "PARANÁ",
            ic_january: 5.771,
            ic_february: 5.817,
            ic_march: 5.262,
            ic_april: 4.672,
            ic_may: 3.686,
            ic_june: 3.382,
            ic_july: 3.617,
            ic_august: 4.589,
            ic_september: 4.732,
            ic_october: 5.416,
            ic_november: 5.969,
            ic_december: 6.388,
            ic_yearly: 4.942,
          },
          {
            ic_uuid: "fde90e8b-23d7-4519-bf83-22c32e975915",
            ic_lat: -208809,
            ic_lon: -495801,
            ic_city: "Jaci",
            ic_states: "SÃO PAULO",
            ic_january: 5.817,
            ic_february: 5.871,
            ic_march: 5.217,
            ic_april: 4.862,
            ic_may: 4.085,
            ic_june: 3.863,
            ic_july: 4.124,
            ic_august: 5.026,
            ic_september: 5.069,
            ic_october: 5.62,
            ic_november: 5.962,
            ic_december: 6.177,
            ic_yearly: 5.141,
          },
          {
            ic_uuid: "57895e28-3c23-4187-aa27-a4489b46f9fd",
            ic_lat: -159552,
            ic_lon: -549738,
            ic_city: "Jaciara",
            ic_states: "MATO GROSSO",
            ic_january: 5.373,
            ic_february: 5.361,
            ic_march: 5.119,
            ic_april: 5.094,
            ic_may: 4.646,
            ic_june: 4.536,
            ic_july: 4.627,
            ic_august: 5.561,
            ic_september: 5.165,
            ic_october: 5.14,
            ic_november: 5.41,
            ic_december: 5.498,
            ic_yearly: 5.128,
          },
          {
            ic_uuid: "388cb8e8-2c0e-4827-b0f5-83adeca39376",
            ic_lat: -161432,
            ic_lon: -402953,
            ic_city: "Jacinto",
            ic_states: "MINAS GERAIS",
            ic_january: 6.014,
            ic_february: 6.178,
            ic_march: 5.501,
            ic_april: 4.789,
            ic_may: 3.951,
            ic_june: 3.594,
            ic_july: 3.732,
            ic_august: 4.257,
            ic_september: 4.92,
            ic_october: 5.093,
            ic_november: 5.191,
            ic_december: 6.038,
            ic_yearly: 4.938,
          },
          {
            ic_uuid: "3e3f0b58-2a58-4b98-8628-c5327201bc39",
            ic_lat: -289966,
            ic_lon: -497628,
            ic_city: "Jacinto Machado",
            ic_states: "SANTA CATARINA",
            ic_january: 5.468,
            ic_february: 5.148,
            ic_march: 4.42,
            ic_april: 3.772,
            ic_may: 2.926,
            ic_june: 2.437,
            ic_july: 2.68,
            ic_august: 3.384,
            ic_september: 3.476,
            ic_october: 4.17,
            ic_november: 5.482,
            ic_december: 5.736,
            ic_yearly: 4.092,
          },
          {
            ic_uuid: "e20da73e-8a98-4e34-bec7-b45b2f079a5b",
            ic_lat: -111817,
            ic_lon: -40512,
            ic_city: "Jacobina",
            ic_states: "BAHIA",
            ic_january: 6.197,
            ic_february: 5.993,
            ic_march: 5.892,
            ic_april: 4.86,
            ic_may: 4.068,
            ic_june: 3.821,
            ic_july: 4.003,
            ic_august: 4.713,
            ic_september: 5.688,
            ic_october: 5.713,
            ic_november: 5.965,
            ic_december: 6.103,
            ic_yearly: 5.251,
          },
          {
            ic_uuid: "001c6bb3-05fb-4930-932c-68e7ea500637",
            ic_lat: -79316,
            ic_lon: -412081,
            ic_city: "Jacobina do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.874,
            ic_february: 5.664,
            ic_march: 5.675,
            ic_april: 5.346,
            ic_may: 5.143,
            ic_june: 5.143,
            ic_july: 5.491,
            ic_august: 6.31,
            ic_september: 6.656,
            ic_october: 6.586,
            ic_november: 6.407,
            ic_december: 6.095,
            ic_yearly: 5.866,
          },
          {
            ic_uuid: "1eb70460-1cec-4f95-bce5-1358c50d5bbd",
            ic_lat: -210141,
            ic_lon: -467363,
            ic_city: "Jacuí",
            ic_states: "MINAS GERAIS",
            ic_january: 5.517,
            ic_february: 5.711,
            ic_march: 4.958,
            ic_april: 4.699,
            ic_may: 4.107,
            ic_june: 3.896,
            ic_july: 4.158,
            ic_august: 5.046,
            ic_september: 5.128,
            ic_october: 5.48,
            ic_november: 5.402,
            ic_december: 5.588,
            ic_yearly: 4.974,
          },
          {
            ic_uuid: "4462aea9-6fb2-49f3-848c-a83a6feea4cd",
            ic_lat: -88399,
            ic_lon: -354594,
            ic_city: "Jacuípe",
            ic_states: "ALAGOAS",
            ic_january: 5.706,
            ic_february: 5.691,
            ic_march: 5.754,
            ic_april: 5.012,
            ic_may: 4.236,
            ic_june: 3.971,
            ic_july: 3.996,
            ic_august: 4.64,
            ic_september: 5.436,
            ic_october: 5.663,
            ic_november: 6.113,
            ic_december: 5.998,
            ic_yearly: 5.185,
          },
          {
            ic_uuid: "c96a4f32-3144-4741-b4d4-3d98f9a01574",
            ic_lat: -290339,
            ic_lon: -530594,
            ic_city: "Jacuizinho",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.413,
            ic_february: 5.947,
            ic_march: 5.075,
            ic_april: 3.94,
            ic_may: 2.965,
            ic_june: 2.436,
            ic_july: 2.719,
            ic_august: 3.509,
            ic_september: 3.844,
            ic_october: 5.006,
            ic_november: 6.307,
            ic_december: 6.753,
            ic_yearly: 4.576,
          },
          {
            ic_uuid: "156c01ed-b685-4d84-aaf3-8993b76d22ee",
            ic_lat: -44466,
            ic_lon: -491157,
            ic_city: "Jacundá",
            ic_states: "PARÁ",
            ic_january: 4.404,
            ic_february: 4.553,
            ic_march: 4.584,
            ic_april: 4.64,
            ic_may: 4.558,
            ic_june: 4.827,
            ic_july: 4.966,
            ic_august: 5.251,
            ic_september: 5.169,
            ic_october: 4.717,
            ic_november: 4.625,
            ic_december: 4.41,
            ic_yearly: 4.725,
          },
          {
            ic_uuid: "8c428c02-82dc-47ac-9eee-838a7bb055ad",
            ic_lat: -246968,
            ic_lon: -480068,
            ic_city: "Jacupiranga",
            ic_states: "SÃO PAULO",
            ic_january: 5.414,
            ic_february: 5.388,
            ic_march: 4.534,
            ic_april: 3.875,
            ic_may: 3.13,
            ic_june: 2.699,
            ic_july: 2.696,
            ic_august: 3.365,
            ic_september: 3.494,
            ic_october: 4.003,
            ic_november: 4.855,
            ic_december: 5.447,
            ic_yearly: 4.075,
          },
          {
            ic_uuid: "d464e696-4769-4c54-a20d-14703e0f2070",
            ic_lat: -222864,
            ic_lon: -46617,
            ic_city: "Jacutinga",
            ic_states: "MINAS GERAIS",
            ic_january: 5.409,
            ic_february: 5.555,
            ic_march: 4.959,
            ic_april: 4.629,
            ic_may: 3.9,
            ic_june: 3.661,
            ic_july: 3.875,
            ic_august: 4.765,
            ic_september: 4.933,
            ic_october: 5.403,
            ic_november: 5.512,
            ic_december: 5.74,
            ic_yearly: 4.861,
          },
          {
            ic_uuid: "a2700834-3605-4425-bd06-f2f2d1e2728d",
            ic_lat: -277296,
            ic_lon: -525377,
            ic_city: "Jacutinga",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.28,
            ic_february: 5.854,
            ic_march: 5.034,
            ic_april: 4.079,
            ic_may: 3.081,
            ic_june: 2.596,
            ic_july: 2.92,
            ic_august: 3.728,
            ic_september: 3.945,
            ic_october: 5.098,
            ic_november: 6.166,
            ic_december: 6.573,
            ic_yearly: 4.613,
          },
          {
            ic_uuid: "9e9208bb-1bfe-4dfe-b922-772c3c4daa57",
            ic_lat: -231109,
            ic_lon: -515346,
            ic_city: "Jaguapitã",
            ic_states: "PARANÁ",
            ic_january: 5.912,
            ic_february: 5.917,
            ic_march: 5.418,
            ic_april: 4.742,
            ic_may: 3.68,
            ic_june: 3.433,
            ic_july: 3.605,
            ic_august: 4.537,
            ic_september: 4.764,
            ic_october: 5.464,
            ic_november: 6.155,
            ic_december: 6.376,
            ic_yearly: 5.0,
          },
          {
            ic_uuid: "815a7149-2c89-4b9a-8716-3d845ef01295",
            ic_lat: -135253,
            ic_lon: -399643,
            ic_city: "Jaguaquara",
            ic_states: "BAHIA",
            ic_january: 5.735,
            ic_february: 5.824,
            ic_march: 5.502,
            ic_april: 4.452,
            ic_may: 3.892,
            ic_june: 3.54,
            ic_july: 3.682,
            ic_august: 4.298,
            ic_september: 5.149,
            ic_october: 5.252,
            ic_november: 5.417,
            ic_december: 5.914,
            ic_yearly: 4.888,
          },
          {
            ic_uuid: "7813fd09-41a8-4594-8ea3-b1c2274cd22a",
            ic_lat: -196481,
            ic_lon: -427511,
            ic_city: "Jaguaraçu",
            ic_states: "MINAS GERAIS",
            ic_january: 5.804,
            ic_february: 6.086,
            ic_march: 5.067,
            ic_april: 4.628,
            ic_may: 3.89,
            ic_june: 3.709,
            ic_july: 3.925,
            ic_august: 4.643,
            ic_september: 4.947,
            ic_october: 5.123,
            ic_november: 4.773,
            ic_december: 5.557,
            ic_yearly: 4.846,
          },
          {
            ic_uuid: "6aed339d-fba1-4948-be58-b3f3de6a458a",
            ic_lat: -325609,
            ic_lon: -533775,
            ic_city: "Jaguarão",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.553,
            ic_february: 5.804,
            ic_march: 4.907,
            ic_april: 3.662,
            ic_may: 2.633,
            ic_june: 2.198,
            ic_july: 2.341,
            ic_august: 2.98,
            ic_september: 3.719,
            ic_october: 5.043,
            ic_november: 6.399,
            ic_december: 6.933,
            ic_yearly: 4.431,
          },
          {
            ic_uuid: "34562c32-a3ce-4893-95f9-9ac832f9cf5b",
            ic_lat: -102587,
            ic_lon: -401953,
            ic_city: "Jaguarari",
            ic_states: "BAHIA",
            ic_january: 6.085,
            ic_february: 5.873,
            ic_march: 5.815,
            ic_april: 4.84,
            ic_may: 4.204,
            ic_june: 3.893,
            ic_july: 4.074,
            ic_august: 4.749,
            ic_september: 5.725,
            ic_october: 5.813,
            ic_november: 6.081,
            ic_december: 6.02,
            ic_yearly: 5.264,
          },
          {
            ic_uuid: "6fd829a2-2e43-49f4-83b1-4f19d6c3942e",
            ic_lat: -189075,
            ic_lon: -400762,
            ic_city: "Jaguaré",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.054,
            ic_february: 6.133,
            ic_march: 5.311,
            ic_april: 4.464,
            ic_may: 3.806,
            ic_june: 3.543,
            ic_july: 3.592,
            ic_august: 4.251,
            ic_september: 4.803,
            ic_october: 5.021,
            ic_november: 5.011,
            ic_december: 5.866,
            ic_yearly: 4.821,
          },
          {
            ic_uuid: "27042be6-c593-49fe-8bb0-2a4e7fc198b5",
            ic_lat: -56055,
            ic_lon: -387642,
            ic_city: "Jaguaretama",
            ic_states: "CEARÁ",
            ic_january: 5.882,
            ic_february: 5.919,
            ic_march: 6.064,
            ic_april: 5.491,
            ic_may: 5.298,
            ic_june: 5.067,
            ic_july: 5.417,
            ic_august: 6.115,
            ic_september: 6.428,
            ic_october: 6.588,
            ic_november: 6.529,
            ic_december: 6.138,
            ic_yearly: 5.911,
          },
          {
            ic_uuid: "ddb8de44-0ae5-4cdd-bec1-c167e4355676",
            ic_lat: -29497,
            ic_lon: -546899,
            ic_city: "Jaguari",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.571,
            ic_february: 5.986,
            ic_march: 5.089,
            ic_april: 3.944,
            ic_may: 2.928,
            ic_june: 2.434,
            ic_july: 2.686,
            ic_august: 3.428,
            ic_september: 3.91,
            ic_october: 5.036,
            ic_november: 6.352,
            ic_december: 6.893,
            ic_yearly: 4.605,
          },
          {
            ic_uuid: "9c2a93df-e8c0-42e1-822f-158769f8cf6a",
            ic_lat: -24251,
            ic_lon: -497059,
            ic_city: "Jaguariaíva",
            ic_states: "PARANÁ",
            ic_january: 5.49,
            ic_february: 5.498,
            ic_march: 4.829,
            ic_april: 4.256,
            ic_may: 3.429,
            ic_june: 3.082,
            ic_july: 3.328,
            ic_august: 4.305,
            ic_september: 4.456,
            ic_october: 4.993,
            ic_november: 5.682,
            ic_december: 5.985,
            ic_yearly: 4.611,
          },
          {
            ic_uuid: "0109fdaf-c0a5-40ee-a1d5-e0986b8e2d96",
            ic_lat: -5459,
            ic_lon: -384619,
            ic_city: "Jaguaribara",
            ic_states: "CEARÁ",
            ic_january: 5.792,
            ic_february: 5.822,
            ic_march: 5.93,
            ic_april: 5.545,
            ic_may: 5.36,
            ic_june: 5.083,
            ic_july: 5.376,
            ic_august: 6.053,
            ic_september: 6.319,
            ic_october: 6.456,
            ic_november: 6.419,
            ic_december: 5.987,
            ic_yearly: 5.845,
          },
          {
            ic_uuid: "8e8b250f-0ce1-4a7a-8723-80d2da45f4b8",
            ic_lat: -59025,
            ic_lon: -38623,
            ic_city: "Jaguaribe",
            ic_states: "CEARÁ",
            ic_january: 5.887,
            ic_february: 5.896,
            ic_march: 6.009,
            ic_april: 5.567,
            ic_may: 5.276,
            ic_june: 5.081,
            ic_july: 5.36,
            ic_august: 6.06,
            ic_september: 6.439,
            ic_october: 6.609,
            ic_november: 6.586,
            ic_december: 6.125,
            ic_yearly: 5.908,
          },
          {
            ic_uuid: "d8e62e24-67ad-4bb3-9ca8-7d6010ef34c4",
            ic_lat: -131114,
            ic_lon: -388942,
            ic_city: "Jaguaripe",
            ic_states: "BAHIA",
            ic_january: 6.279,
            ic_february: 6.167,
            ic_march: 5.867,
            ic_april: 4.715,
            ic_may: 4.092,
            ic_june: 3.714,
            ic_july: 3.949,
            ic_august: 4.587,
            ic_september: 5.368,
            ic_october: 5.653,
            ic_november: 5.921,
            ic_december: 6.341,
            ic_yearly: 5.221,
          },
          {
            ic_uuid: "15d4d5ab-0215-4294-906a-f60baa2cdb16",
            ic_lat: -227042,
            ic_lon: -469855,
            ic_city: "Jaguariúna",
            ic_states: "SÃO PAULO",
            ic_january: 5.585,
            ic_february: 5.812,
            ic_march: 5.172,
            ic_april: 4.675,
            ic_may: 3.873,
            ic_june: 3.573,
            ic_july: 3.783,
            ic_august: 4.692,
            ic_september: 4.88,
            ic_october: 5.524,
            ic_november: 5.732,
            ic_december: 6.167,
            ic_yearly: 4.955,
          },
          {
            ic_uuid: "6d303768-b93b-4195-9ad0-26f69f81f065",
            ic_lat: -48319,
            ic_lon: -377813,
            ic_city: "Jaguaruana",
            ic_states: "CEARÁ",
            ic_january: 5.748,
            ic_february: 5.795,
            ic_march: 5.818,
            ic_april: 5.368,
            ic_may: 5.291,
            ic_june: 5.023,
            ic_july: 5.328,
            ic_august: 5.957,
            ic_september: 6.25,
            ic_october: 6.409,
            ic_november: 6.396,
            ic_december: 6.001,
            ic_yearly: 5.782,
          },
          {
            ic_uuid: "fbefdb92-6894-4284-93a5-100e2a800f52",
            ic_lat: -286151,
            ic_lon: -4903,
            ic_city: "Jaguaruna",
            ic_states: "SANTA CATARINA",
            ic_january: 5.912,
            ic_february: 5.392,
            ic_march: 4.573,
            ic_april: 3.793,
            ic_may: 3.007,
            ic_june: 2.497,
            ic_july: 2.721,
            ic_august: 3.445,
            ic_september: 3.575,
            ic_october: 4.413,
            ic_november: 5.766,
            ic_december: 6.146,
            ic_yearly: 4.27,
          },
          {
            ic_uuid: "30c7ac6b-0c5a-4355-8868-31caf07a67c9",
            ic_lat: -153437,
            ic_lon: -436692,
            ic_city: "Jaíba",
            ic_states: "MINAS GERAIS",
            ic_january: 6.473,
            ic_february: 6.703,
            ic_march: 5.876,
            ic_april: 5.558,
            ic_may: 4.932,
            ic_june: 4.69,
            ic_july: 4.949,
            ic_august: 5.578,
            ic_september: 5.934,
            ic_october: 6.18,
            ic_november: 5.702,
            ic_december: 6.361,
            ic_yearly: 5.744,
          },
          {
            ic_uuid: "c189d47a-72fb-4300-8784-0c8ea8bfb75f",
            ic_lat: -73605,
            ic_lon: -411409,
            ic_city: "Jaicós",
            ic_states: "PIAUÍ",
            ic_january: 5.6,
            ic_february: 5.539,
            ic_march: 5.6,
            ic_april: 5.328,
            ic_may: 5.202,
            ic_june: 5.19,
            ic_july: 5.495,
            ic_august: 6.21,
            ic_september: 6.685,
            ic_october: 6.512,
            ic_november: 6.308,
            ic_december: 5.961,
            ic_yearly: 5.802,
          },
          {
            ic_uuid: "23c0ad0e-4c53-495a-9268-4ef7502894e6",
            ic_lat: -202677,
            ic_lon: -505499,
            ic_city: "Jales",
            ic_states: "SÃO PAULO",
            ic_january: 5.799,
            ic_february: 5.904,
            ic_march: 5.329,
            ic_april: 4.889,
            ic_may: 4.202,
            ic_june: 3.933,
            ic_july: 4.153,
            ic_august: 5.074,
            ic_september: 5.121,
            ic_october: 5.677,
            ic_november: 5.953,
            ic_december: 6.263,
            ic_yearly: 5.191,
          },
          {
            ic_uuid: "faa37edb-13a9-4455-98fe-275876b3c6a3",
            ic_lat: -232527,
            ic_lon: -456946,
            ic_city: "Jambeiro",
            ic_states: "SÃO PAULO",
            ic_january: 5.6,
            ic_february: 5.819,
            ic_march: 4.888,
            ic_april: 4.371,
            ic_may: 3.519,
            ic_june: 3.285,
            ic_july: 3.383,
            ic_august: 4.311,
            ic_september: 4.396,
            ic_october: 5.054,
            ic_november: 5.303,
            ic_december: 5.883,
            ic_yearly: 4.651,
          },
          {
            ic_uuid: "e6f7a7bf-3b09-4d7e-a04c-0ef17786a48b",
            ic_lat: -184615,
            ic_lon: -418094,
            ic_city: "Jampruca",
            ic_states: "MINAS GERAIS",
            ic_january: 6.02,
            ic_february: 6.189,
            ic_march: 5.345,
            ic_april: 4.68,
            ic_may: 3.935,
            ic_june: 3.659,
            ic_july: 3.766,
            ic_august: 4.43,
            ic_september: 4.958,
            ic_october: 5.214,
            ic_november: 4.969,
            ic_december: 5.868,
            ic_yearly: 4.919,
          },
          {
            ic_uuid: "4b89318a-5ba2-4600-941f-d278513c5864",
            ic_lat: -158027,
            ic_lon: -433135,
            ic_city: "Janaúba",
            ic_states: "MINAS GERAIS",
            ic_january: 6.576,
            ic_february: 6.737,
            ic_march: 5.84,
            ic_april: 5.484,
            ic_may: 4.866,
            ic_june: 4.592,
            ic_july: 4.832,
            ic_august: 5.573,
            ic_september: 5.987,
            ic_october: 6.193,
            ic_november: 5.799,
            ic_december: 6.372,
            ic_yearly: 5.737,
          },
          {
            ic_uuid: "81210750-c085-4fed-8409-494ac8f40bd1",
            ic_lat: -170486,
            ic_lon: -501457,
            ic_city: "Jandaia",
            ic_states: "GOIÁS",
            ic_january: 5.59,
            ic_february: 5.81,
            ic_march: 5.293,
            ic_april: 5.109,
            ic_may: 4.779,
            ic_june: 4.452,
            ic_july: 4.661,
            ic_august: 5.453,
            ic_september: 5.423,
            ic_october: 5.576,
            ic_november: 5.515,
            ic_december: 5.595,
            ic_yearly: 5.271,
          },
          {
            ic_uuid: "85cc78c2-4b3b-4158-ad46-345f6de54625",
            ic_lat: -236016,
            ic_lon: -516453,
            ic_city: "Jandaia do Sul",
            ic_states: "PARANÁ",
            ic_january: 5.77,
            ic_february: 5.728,
            ic_march: 5.241,
            ic_april: 4.554,
            ic_may: 3.586,
            ic_june: 3.292,
            ic_july: 3.503,
            ic_august: 4.478,
            ic_september: 4.644,
            ic_october: 5.303,
            ic_november: 5.979,
            ic_december: 6.222,
            ic_yearly: 4.858,
          },
          {
            ic_uuid: "2ce7219e-0936-4c88-9d42-9bdac384ef30",
            ic_lat: -115621,
            ic_lon: -377856,
            ic_city: "Jandaíra",
            ic_states: "BAHIA",
            ic_january: 6.143,
            ic_february: 6.0,
            ic_march: 5.81,
            ic_april: 4.857,
            ic_may: 4.119,
            ic_june: 3.947,
            ic_july: 4.036,
            ic_august: 4.517,
            ic_september: 5.296,
            ic_october: 5.644,
            ic_november: 6.025,
            ic_december: 6.277,
            ic_yearly: 5.223,
          },
          {
            ic_uuid: "e5eba4c2-900e-427f-a578-ae13c4206999",
            ic_lat: -53525,
            ic_lon: -361281,
            ic_city: "Jandaíra",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.785,
            ic_february: 5.798,
            ic_march: 5.734,
            ic_april: 5.468,
            ic_may: 5.202,
            ic_june: 4.832,
            ic_july: 5.082,
            ic_august: 5.759,
            ic_september: 6.045,
            ic_october: 6.264,
            ic_november: 6.261,
            ic_december: 5.855,
            ic_yearly: 5.674,
          },
          {
            ic_uuid: "37eab7bc-8666-48fd-94d3-40c46369e426",
            ic_lat: -23528,
            ic_lon: -469027,
            ic_city: "Jandira",
            ic_states: "SÃO PAULO",
            ic_january: 5.314,
            ic_february: 5.556,
            ic_march: 4.803,
            ic_april: 4.294,
            ic_may: 3.518,
            ic_june: 3.244,
            ic_july: 3.331,
            ic_august: 4.31,
            ic_september: 4.345,
            ic_october: 4.885,
            ic_november: 5.303,
            ic_december: 5.799,
            ic_yearly: 4.558,
          },
          {
            ic_uuid: "3a79f35a-ee45-4e78-907b-fe95f7eb7426",
            ic_lat: -60152,
            ic_lon: -374051,
            ic_city: "Janduís",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.011,
            ic_february: 6.082,
            ic_march: 6.082,
            ic_april: 5.846,
            ic_may: 5.377,
            ic_june: 4.969,
            ic_july: 5.317,
            ic_august: 6.026,
            ic_september: 6.493,
            ic_october: 6.598,
            ic_november: 6.608,
            ic_december: 6.118,
            ic_yearly: 5.961,
          },
          {
            ic_uuid: "9c0bd61e-6291-4594-9d4c-3579a413e249",
            ic_lat: -152355,
            ic_lon: -564921,
            ic_city: "Jangada",
            ic_states: "MATO GROSSO",
            ic_january: 5.402,
            ic_february: 5.375,
            ic_march: 5.173,
            ic_april: 4.951,
            ic_may: 4.479,
            ic_june: 4.477,
            ic_july: 4.604,
            ic_august: 5.408,
            ic_september: 5.124,
            ic_october: 5.281,
            ic_november: 5.58,
            ic_december: 5.69,
            ic_yearly: 5.129,
          },
          {
            ic_uuid: "13130da7-1ba3-42ae-bd64-8c54790c7df2",
            ic_lat: -241406,
            ic_lon: -527788,
            ic_city: "Janiópolis",
            ic_states: "PARANÁ",
            ic_january: 6.185,
            ic_february: 5.707,
            ic_march: 5.276,
            ic_april: 4.469,
            ic_may: 3.541,
            ic_june: 3.128,
            ic_july: 3.369,
            ic_august: 4.314,
            ic_september: 4.563,
            ic_october: 5.352,
            ic_november: 6.101,
            ic_december: 6.496,
            ic_yearly: 4.875,
          },
          {
            ic_uuid: "3d429821-1816-46d8-8e61-fb75aafa8bb1",
            ic_lat: -154806,
            ic_lon: -443643,
            ic_city: "Januária",
            ic_states: "MINAS GERAIS",
            ic_january: 6.487,
            ic_february: 6.718,
            ic_march: 5.935,
            ic_april: 5.677,
            ic_may: 5.098,
            ic_june: 4.786,
            ic_july: 5.115,
            ic_august: 5.782,
            ic_september: 6.105,
            ic_october: 6.212,
            ic_november: 5.636,
            ic_december: 6.233,
            ic_yearly: 5.815,
          },
          {
            ic_uuid: "ccd0cf01-aed7-4865-9cb3-d9e80f98cab4",
            ic_lat: -61602,
            ic_lon: -355996,
            ic_city: "Januário Cicco",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.689,
            ic_february: 5.82,
            ic_march: 5.868,
            ic_april: 5.48,
            ic_may: 4.947,
            ic_june: 4.522,
            ic_july: 4.647,
            ic_august: 5.346,
            ic_september: 5.738,
            ic_october: 5.921,
            ic_november: 6.118,
            ic_december: 5.769,
            ic_yearly: 5.489,
          },
          {
            ic_uuid: "0b31e331-1b27-428c-a4fc-03cb32a7e851",
            ic_lat: -201447,
            ic_lon: -455019,
            ic_city: "Japaraíba",
            ic_states: "MINAS GERAIS",
            ic_january: 5.907,
            ic_february: 5.965,
            ic_march: 5.111,
            ic_april: 4.969,
            ic_may: 4.355,
            ic_june: 4.132,
            ic_july: 4.406,
            ic_august: 5.283,
            ic_september: 5.497,
            ic_october: 5.605,
            ic_november: 5.299,
            ic_december: 5.703,
            ic_yearly: 5.186,
          },
          {
            ic_uuid: "292c497a-4a64-4c3e-86e8-cfc9a0a20082",
            ic_lat: -90879,
            ic_lon: -352637,
            ic_city: "Japaratinga",
            ic_states: "ALAGOAS",
            ic_january: 6.124,
            ic_february: 6.043,
            ic_march: 6.202,
            ic_april: 5.228,
            ic_may: 4.469,
            ic_june: 4.201,
            ic_july: 4.277,
            ic_august: 5.043,
            ic_september: 5.72,
            ic_october: 6.13,
            ic_november: 6.378,
            ic_december: 6.406,
            ic_yearly: 5.518,
          },
          {
            ic_uuid: "803b4103-d106-4331-bd38-78ea86473b08",
            ic_lat: -105854,
            ic_lon: -369421,
            ic_city: "Japaratuba",
            ic_states: "SERGIPE",
            ic_january: 6.24,
            ic_february: 6.066,
            ic_march: 5.957,
            ic_april: 5.115,
            ic_may: 4.333,
            ic_june: 4.056,
            ic_july: 4.108,
            ic_august: 4.746,
            ic_september: 5.449,
            ic_october: 5.788,
            ic_november: 6.278,
            ic_december: 6.293,
            ic_yearly: 5.369,
          },
          {
            ic_uuid: "64571d5f-6353-4c6e-8e52-164d0b692d03",
            ic_lat: -22644,
            ic_lon: -436606,
            ic_city: "Japeri",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.722,
            ic_february: 6.101,
            ic_march: 4.931,
            ic_april: 4.383,
            ic_may: 3.59,
            ic_june: 3.38,
            ic_july: 3.393,
            ic_august: 4.215,
            ic_september: 4.382,
            ic_october: 4.824,
            ic_november: 4.881,
            ic_december: 5.57,
            ic_yearly: 4.614,
          },
          {
            ic_uuid: "912dbec2-2525-4eff-b851-bbc5a84475c6",
            ic_lat: -64664,
            ic_lon: -359464,
            ic_city: "Japi",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.724,
            ic_february: 5.759,
            ic_march: 5.784,
            ic_april: 5.335,
            ic_may: 4.803,
            ic_june: 4.386,
            ic_july: 4.543,
            ic_august: 5.304,
            ic_september: 5.732,
            ic_october: 5.956,
            ic_november: 6.139,
            ic_december: 5.795,
            ic_yearly: 5.438,
          },
          {
            ic_uuid: "57691012-8eb8-4b69-831f-453754f335c7",
            ic_lat: -238147,
            ic_lon: -501426,
            ic_city: "Japira",
            ic_states: "PARANÁ",
            ic_january: 5.716,
            ic_february: 5.703,
            ic_march: 5.115,
            ic_april: 4.489,
            ic_may: 3.578,
            ic_june: 3.197,
            ic_july: 3.454,
            ic_august: 4.441,
            ic_september: 4.614,
            ic_october: 5.208,
            ic_november: 5.917,
            ic_december: 6.241,
            ic_yearly: 4.806,
          },
          {
            ic_uuid: "3d8a0935-787f-4234-9beb-c9f6322ae656",
            ic_lat: -103445,
            ic_lon: -367983,
            ic_city: "Japoatã",
            ic_states: "SERGIPE",
            ic_january: 6.141,
            ic_february: 6.085,
            ic_march: 5.943,
            ic_april: 5.049,
            ic_may: 4.291,
            ic_june: 4.044,
            ic_july: 4.11,
            ic_august: 4.653,
            ic_september: 5.539,
            ic_october: 5.918,
            ic_november: 6.364,
            ic_december: 6.363,
            ic_yearly: 5.375,
          },
          {
            ic_uuid: "07e458d9-608b-417b-823f-8e5cb1dd39b8",
            ic_lat: -159984,
            ic_lon: -442755,
            ic_city: "Japonvar",
            ic_states: "MINAS GERAIS",
            ic_january: 6.318,
            ic_february: 6.561,
            ic_march: 5.713,
            ic_april: 5.551,
            ic_may: 4.983,
            ic_june: 4.73,
            ic_july: 5.068,
            ic_august: 5.813,
            ic_september: 6.124,
            ic_october: 6.109,
            ic_november: 5.471,
            ic_december: 6.126,
            ic_yearly: 5.714,
          },
          {
            ic_uuid: "f5b16c06-2009-4709-8b2a-4089c8bd993c",
            ic_lat: -238908,
            ic_lon: -544064,
            ic_city: "Japorã",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 6.102,
            ic_february: 5.785,
            ic_march: 5.318,
            ic_april: 4.377,
            ic_may: 3.453,
            ic_june: 3.019,
            ic_july: 3.25,
            ic_august: 4.251,
            ic_september: 4.54,
            ic_october: 5.324,
            ic_november: 6.054,
            ic_december: 6.459,
            ic_yearly: 4.827,
          },
          {
            ic_uuid: "2a97b90f-7a00-4444-8356-d087bb3852a4",
            ic_lat: -18803,
            ic_lon: -669957,
            ic_city: "Japurá",
            ic_states: "AMAZONAS",
            ic_january: 4.508,
            ic_february: 4.748,
            ic_march: 4.561,
            ic_april: 4.319,
            ic_may: 3.966,
            ic_june: 4.146,
            ic_july: 4.182,
            ic_august: 4.856,
            ic_september: 5.054,
            ic_october: 4.97,
            ic_november: 4.811,
            ic_december: 4.569,
            ic_yearly: 4.557,
          },
          {
            ic_uuid: "11ccf2d2-ec4e-484c-bdbd-e41f56390fdb",
            ic_lat: -234697,
            ic_lon: -525562,
            ic_city: "Japurá",
            ic_states: "PARANÁ",
            ic_january: 6.055,
            ic_february: 5.841,
            ic_march: 5.319,
            ic_april: 4.557,
            ic_may: 3.614,
            ic_june: 3.255,
            ic_july: 3.477,
            ic_august: 4.4,
            ic_september: 4.647,
            ic_october: 5.377,
            ic_november: 6.119,
            ic_december: 6.486,
            ic_yearly: 4.929,
          },
          {
            ic_uuid: "25717e8a-214e-4721-8b2c-53dbabe78477",
            ic_lat: -87266,
            ic_lon: -357945,
            ic_city: "Jaqueira",
            ic_states: "PERNAMBUCO",
            ic_january: 5.638,
            ic_february: 5.67,
            ic_march: 5.665,
            ic_april: 5.039,
            ic_may: 4.253,
            ic_june: 3.817,
            ic_july: 3.853,
            ic_august: 4.492,
            ic_september: 5.258,
            ic_october: 5.545,
            ic_november: 5.99,
            ic_december: 5.903,
            ic_yearly: 5.093,
          },
          {
            ic_uuid: "5e5358e6-354a-4ba0-9989-660fe1202e04",
            ic_lat: -288816,
            ic_lon: -503641,
            ic_city: "Jaquirana",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.049,
            ic_february: 5.6,
            ic_march: 4.775,
            ic_april: 3.896,
            ic_may: 2.908,
            ic_june: 2.483,
            ic_july: 2.789,
            ic_august: 3.537,
            ic_september: 3.716,
            ic_october: 4.856,
            ic_november: 6.108,
            ic_december: 6.381,
            ic_yearly: 4.425,
          },
          {
            ic_uuid: "84083367-cccd-486a-802e-83f81b34beeb",
            ic_lat: -157494,
            ic_lon: -493335,
            ic_city: "Jaraguá",
            ic_states: "GOIÁS",
            ic_january: 5.461,
            ic_february: 5.717,
            ic_march: 5.247,
            ic_april: 5.175,
            ic_may: 4.902,
            ic_june: 4.677,
            ic_july: 4.796,
            ic_august: 5.723,
            ic_september: 5.592,
            ic_october: 5.661,
            ic_november: 5.329,
            ic_december: 5.429,
            ic_yearly: 5.309,
          },
          {
            ic_uuid: "6e842b80-53ab-47bb-b7d0-763cc3b051c6",
            ic_lat: -264856,
            ic_lon: -490717,
            ic_city: "Jaraguá do Sul",
            ic_states: "SANTA CATARINA",
            ic_january: 5.26,
            ic_february: 5.048,
            ic_march: 4.429,
            ic_april: 3.627,
            ic_may: 2.985,
            ic_june: 2.482,
            ic_july: 2.586,
            ic_august: 3.279,
            ic_september: 3.32,
            ic_october: 3.844,
            ic_november: 4.921,
            ic_december: 5.3,
            ic_yearly: 3.924,
          },
          {
            ic_uuid: "b646e852-fdac-4cc0-9423-a4f820dbe702",
            ic_lat: -201391,
            ic_lon: -544001,
            ic_city: "Jaraguari",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.616,
            ic_february: 5.658,
            ic_march: 5.335,
            ic_april: 4.858,
            ic_may: 4.078,
            ic_june: 3.83,
            ic_july: 4.024,
            ic_august: 4.827,
            ic_september: 4.951,
            ic_october: 5.466,
            ic_november: 5.876,
            ic_december: 6.081,
            ic_yearly: 5.05,
          },
          {
            ic_uuid: "d50c9cb6-2c29-40f5-97e1-bf40b42e7b39",
            ic_lat: -96593,
            ic_lon: -370017,
            ic_city: "Jaramataia",
            ic_states: "ALAGOAS",
            ic_january: 6.029,
            ic_february: 5.731,
            ic_march: 5.783,
            ic_april: 5.08,
            ic_may: 4.237,
            ic_june: 3.972,
            ic_july: 4.05,
            ic_august: 4.646,
            ic_september: 5.427,
            ic_october: 5.711,
            ic_november: 6.356,
            ic_december: 6.206,
            ic_yearly: 5.269,
          },
          {
            ic_uuid: "729b5bcc-729a-4ecd-9005-24dacb42d944",
            ic_lat: -75855,
            ic_lon: -392767,
            ic_city: "Jardim",
            ic_states: "CEARÁ",
            ic_january: 5.968,
            ic_february: 5.79,
            ic_march: 5.792,
            ic_april: 5.362,
            ic_may: 4.732,
            ic_june: 4.443,
            ic_july: 4.726,
            ic_august: 5.628,
            ic_september: 6.498,
            ic_october: 6.517,
            ic_november: 6.685,
            ic_december: 6.364,
            ic_yearly: 5.709,
          },
          {
            ic_uuid: "f4aab3dd-7df3-45e7-91d0-f6d46d0b6282",
            ic_lat: -214804,
            ic_lon: -561494,
            ic_city: "Jardim",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.918,
            ic_february: 5.743,
            ic_march: 5.433,
            ic_april: 4.7,
            ic_may: 3.712,
            ic_june: 3.42,
            ic_july: 3.589,
            ic_august: 4.523,
            ic_september: 4.744,
            ic_october: 5.445,
            ic_november: 5.972,
            ic_december: 6.28,
            ic_yearly: 4.956,
          },
          {
            ic_uuid: "f3c5e805-2655-42da-bb24-8f9452e3ada2",
            ic_lat: -241814,
            ic_lon: -516906,
            ic_city: "Jardim Alegre",
            ic_states: "PARANÁ",
            ic_january: 6.036,
            ic_february: 5.778,
            ic_march: 5.277,
            ic_april: 4.582,
            ic_may: 3.531,
            ic_june: 3.16,
            ic_july: 3.386,
            ic_august: 4.391,
            ic_september: 4.562,
            ic_october: 5.263,
            ic_november: 6.101,
            ic_december: 6.384,
            ic_yearly: 4.871,
          },
          {
            ic_uuid: "27f20687-850b-446c-99f6-c25d8b69746e",
            ic_lat: -56504,
            ic_lon: -359716,
            ic_city: "Jardim de Angicos",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.749,
            ic_february: 5.777,
            ic_march: 5.764,
            ic_april: 5.499,
            ic_may: 5.058,
            ic_june: 4.691,
            ic_july: 4.897,
            ic_august: 5.53,
            ic_september: 5.904,
            ic_october: 6.118,
            ic_november: 6.204,
            ic_december: 5.781,
            ic_yearly: 5.581,
          },
          {
            ic_uuid: "80f9ab01-e0d1-4e19-938c-9f303c1908ae",
            ic_lat: -63771,
            ic_lon: -373499,
            ic_city: "Jardim de Piranhas",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.199,
            ic_february: 6.248,
            ic_march: 6.22,
            ic_april: 5.86,
            ic_may: 5.297,
            ic_june: 4.846,
            ic_july: 5.158,
            ic_august: 5.924,
            ic_september: 6.416,
            ic_october: 6.604,
            ic_november: 6.603,
            ic_december: 6.214,
            ic_yearly: 5.966,
          },
          {
            ic_uuid: "26d393df-c3b6-441b-acb5-7629cbb5152a",
            ic_lat: -60994,
            ic_lon: -426303,
            ic_city: "Jardim do Mulato",
            ic_states: "PIAUÍ",
            ic_january: 5.017,
            ic_february: 5.241,
            ic_march: 5.232,
            ic_april: 5.124,
            ic_may: 5.221,
            ic_june: 5.332,
            ic_july: 5.674,
            ic_august: 6.294,
            ic_september: 6.57,
            ic_october: 6.293,
            ic_november: 5.996,
            ic_december: 5.483,
            ic_yearly: 5.623,
          },
          {
            ic_uuid: "dab6bba8-58aa-41f6-8e53-6889c11322cd",
            ic_lat: -65857,
            ic_lon: -367764,
            ic_city: "Jardim do Seridó",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.037,
            ic_february: 6.049,
            ic_march: 6.107,
            ic_april: 5.717,
            ic_may: 5.16,
            ic_june: 4.621,
            ic_july: 4.992,
            ic_august: 5.756,
            ic_september: 6.314,
            ic_october: 6.53,
            ic_november: 6.504,
            ic_december: 6.145,
            ic_yearly: 5.828,
          },
          {
            ic_uuid: "422839a2-417b-4c1c-bc3d-c95361c12bdd",
            ic_lat: -225521,
            ic_lon: -520374,
            ic_city: "Jardim Olinda",
            ic_states: "PARANÁ",
            ic_january: 5.992,
            ic_february: 5.97,
            ic_march: 5.485,
            ic_april: 4.717,
            ic_may: 3.771,
            ic_june: 3.465,
            ic_july: 3.649,
            ic_august: 4.596,
            ic_september: 4.764,
            ic_october: 5.486,
            ic_november: 6.147,
            ic_december: 6.465,
            ic_yearly: 5.042,
          },
          {
            ic_uuid: "7b02e17f-b480-460e-931a-ee19b1ae49e3",
            ic_lat: -267196,
            ic_lon: -52863,
            ic_city: "Jardinópolis",
            ic_states: "SANTA CATARINA",
            ic_january: 6.176,
            ic_february: 5.647,
            ic_march: 5.113,
            ic_april: 4.117,
            ic_may: 3.16,
            ic_june: 2.673,
            ic_july: 3.016,
            ic_august: 3.876,
            ic_september: 4.11,
            ic_october: 5.109,
            ic_november: 6.095,
            ic_december: 6.358,
            ic_yearly: 4.621,
          },
          {
            ic_uuid: "d1768ca6-3d37-4865-b2ad-6e2634729409",
            ic_lat: -210181,
            ic_lon: -47761,
            ic_city: "Jardinópolis",
            ic_states: "SÃO PAULO",
            ic_january: 5.667,
            ic_february: 5.889,
            ic_march: 5.15,
            ic_april: 4.873,
            ic_may: 4.163,
            ic_june: 3.947,
            ic_july: 4.158,
            ic_august: 5.018,
            ic_september: 5.05,
            ic_october: 5.526,
            ic_november: 5.699,
            ic_december: 5.99,
            ic_yearly: 5.094,
          },
          {
            ic_uuid: "545f4b29-eddb-4d21-98a1-f8da1b87ec6a",
            ic_lat: -292927,
            ic_lon: -542242,
            ic_city: "Jari",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.487,
            ic_february: 6.028,
            ic_march: 5.117,
            ic_april: 3.972,
            ic_may: 2.974,
            ic_june: 2.458,
            ic_july: 2.761,
            ic_august: 3.502,
            ic_september: 3.947,
            ic_october: 5.052,
            ic_november: 6.335,
            ic_december: 6.807,
            ic_yearly: 4.62,
          },
          {
            ic_uuid: "bc730fcb-f87a-4439-99c5-c3ced363fbca",
            ic_lat: -231043,
            ic_lon: -467284,
            ic_city: "Jarinu",
            ic_states: "SÃO PAULO",
            ic_january: 5.524,
            ic_february: 5.77,
            ic_march: 5.057,
            ic_april: 4.517,
            ic_may: 3.731,
            ic_june: 3.456,
            ic_july: 3.607,
            ic_august: 4.572,
            ic_september: 4.698,
            ic_october: 5.321,
            ic_november: 5.58,
            ic_december: 6.045,
            ic_yearly: 4.823,
          },
          {
            ic_uuid: "64384ac7-55f0-41cc-b170-23f9bebe635d",
            ic_lat: -104322,
            ic_lon: -624792,
            ic_city: "Jaru",
            ic_states: "RONDÔNIA",
            ic_january: 4.348,
            ic_february: 4.357,
            ic_march: 4.377,
            ic_april: 4.493,
            ic_may: 4.029,
            ic_june: 4.428,
            ic_july: 4.457,
            ic_august: 4.832,
            ic_september: 4.903,
            ic_october: 5.056,
            ic_november: 4.854,
            ic_december: 4.597,
            ic_yearly: 4.561,
          },
          {
            ic_uuid: "56bc6f53-076c-4fec-9298-7e501a1dce49",
            ic_lat: -178788,
            ic_lon: -517208,
            ic_city: "Jataí",
            ic_states: "GOIÁS",
            ic_january: 5.565,
            ic_february: 5.493,
            ic_march: 5.171,
            ic_april: 5.048,
            ic_may: 4.605,
            ic_june: 4.334,
            ic_july: 4.468,
            ic_august: 5.379,
            ic_september: 5.208,
            ic_october: 5.34,
            ic_november: 5.56,
            ic_december: 5.787,
            ic_yearly: 5.163,
          },
          {
            ic_uuid: "d1337169-0b1a-4b08-9a18-07c2e78f6aa4",
            ic_lat: -232583,
            ic_lon: -509782,
            ic_city: "Jataizinho",
            ic_states: "PARANÁ",
            ic_january: 5.928,
            ic_february: 5.78,
            ic_march: 5.283,
            ic_april: 4.646,
            ic_may: 3.696,
            ic_june: 3.372,
            ic_july: 3.577,
            ic_august: 4.547,
            ic_september: 4.694,
            ic_october: 5.31,
            ic_november: 5.981,
            ic_december: 6.321,
            ic_yearly: 4.928,
          },
          {
            ic_uuid: "68bce20d-2b84-48d2-a6b3-077fe932f63c",
            ic_lat: -79824,
            ic_lon: -364964,
            ic_city: "Jataúba",
            ic_states: "PERNAMBUCO",
            ic_january: 6.018,
            ic_february: 5.898,
            ic_march: 5.905,
            ic_april: 5.489,
            ic_may: 4.668,
            ic_june: 4.254,
            ic_july: 4.378,
            ic_august: 5.082,
            ic_september: 5.842,
            ic_october: 6.125,
            ic_november: 6.362,
            ic_december: 6.159,
            ic_yearly: 5.515,
          },
          {
            ic_uuid: "24cdfc66-f086-4a75-bc51-3018b976294a",
            ic_lat: -224811,
            ic_lon: -543084,
            ic_city: "Jateí",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.949,
            ic_february: 5.87,
            ic_march: 5.369,
            ic_april: 4.544,
            ic_may: 3.672,
            ic_june: 3.36,
            ic_july: 3.512,
            ic_august: 4.492,
            ic_september: 4.761,
            ic_october: 5.382,
            ic_november: 6.033,
            ic_december: 6.458,
            ic_yearly: 4.95,
          },
          {
            ic_uuid: "13d27534-9f90-4744-8cf1-374ea36c70ce",
            ic_lat: -76864,
            ic_lon: -390057,
            ic_city: "Jati",
            ic_states: "CEARÁ",
            ic_january: 5.953,
            ic_february: 5.958,
            ic_march: 5.886,
            ic_april: 5.457,
            ic_may: 4.826,
            ic_june: 4.482,
            ic_july: 4.757,
            ic_august: 5.544,
            ic_september: 6.424,
            ic_october: 6.41,
            ic_november: 6.561,
            ic_december: 6.29,
            ic_yearly: 5.712,
          },
          {
            ic_uuid: "aabf4c27-f2f8-4221-a7a1-8be5dabd4942",
            ic_lat: -58194,
            ic_lon: -442248,
            ic_city: "Jatobá",
            ic_states: "MARANHÃO",
            ic_january: 4.7,
            ic_february: 4.867,
            ic_march: 4.952,
            ic_april: 4.908,
            ic_may: 4.957,
            ic_june: 5.261,
            ic_july: 5.548,
            ic_august: 6.175,
            ic_september: 6.404,
            ic_october: 5.798,
            ic_november: 5.36,
            ic_december: 5.063,
            ic_yearly: 5.333,
          },
          {
            ic_uuid: "69485fe9-33ad-48f5-943f-88ca6287931c",
            ic_lat: -91869,
            ic_lon: -382693,
            ic_city: "Jatobá",
            ic_states: "PERNAMBUCO",
            ic_january: 6.344,
            ic_february: 6.151,
            ic_march: 6.081,
            ic_april: 5.426,
            ic_may: 4.624,
            ic_june: 4.092,
            ic_july: 4.293,
            ic_august: 4.939,
            ic_september: 5.888,
            ic_october: 6.11,
            ic_november: 6.58,
            ic_december: 6.431,
            ic_yearly: 5.58,
          },
          {
            ic_uuid: "3a1ce3a7-9f92-438c-8387-08c6f87702a7",
            ic_lat: -47707,
            ic_lon: -418173,
            ic_city: "Jatobá do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.239,
            ic_february: 5.345,
            ic_march: 5.42,
            ic_april: 5.113,
            ic_may: 5.153,
            ic_june: 5.176,
            ic_july: 5.45,
            ic_august: 6.083,
            ic_september: 6.53,
            ic_october: 6.499,
            ic_november: 6.299,
            ic_december: 5.702,
            ic_yearly: 5.667,
          },
          {
            ic_uuid: "43a241b0-f170-44c1-9fbe-d0383fbb27db",
            ic_lat: -222941,
            ic_lon: -485596,
            ic_city: "Jaú",
            ic_states: "SÃO PAULO",
            ic_january: 5.687,
            ic_february: 5.923,
            ic_march: 5.238,
            ic_april: 4.714,
            ic_may: 3.878,
            ic_june: 3.63,
            ic_july: 3.832,
            ic_august: 4.745,
            ic_september: 4.892,
            ic_october: 5.572,
            ic_november: 5.87,
            ic_december: 6.184,
            ic_yearly: 5.014,
          },
          {
            ic_uuid: "adb22f78-ba19-4423-9170-c237d6d9abce",
            ic_lat: -126526,
            ic_lon: -48591,
            ic_city: "Jaú do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 5.455,
            ic_february: 5.462,
            ic_march: 5.155,
            ic_april: 5.177,
            ic_may: 5.132,
            ic_june: 4.952,
            ic_july: 5.219,
            ic_august: 5.906,
            ic_september: 5.857,
            ic_october: 5.563,
            ic_november: 5.177,
            ic_december: 5.371,
            ic_yearly: 5.369,
          },
          {
            ic_uuid: "382c3310-d98e-4955-b0ce-c061063adb0f",
            ic_lat: -161815,
            ic_lon: -509544,
            ic_city: "Jaupaci",
            ic_states: "GOIÁS",
            ic_january: 5.533,
            ic_february: 5.669,
            ic_march: 5.361,
            ic_april: 5.306,
            ic_may: 4.899,
            ic_june: 4.56,
            ic_july: 4.731,
            ic_august: 5.388,
            ic_september: 5.396,
            ic_october: 5.595,
            ic_november: 5.481,
            ic_december: 5.586,
            ic_yearly: 5.292,
          },
          {
            ic_uuid: "5fa15b9c-0e07-464e-855f-46cd169c2975",
            ic_lat: -153384,
            ic_lon: -588728,
            ic_city: "Jauru",
            ic_states: "MATO GROSSO",
            ic_january: 5.197,
            ic_february: 4.981,
            ic_march: 4.802,
            ic_april: 4.644,
            ic_may: 4.079,
            ic_june: 4.219,
            ic_july: 4.319,
            ic_august: 5.114,
            ic_september: 5.026,
            ic_october: 5.228,
            ic_november: 5.488,
            ic_december: 5.432,
            ic_yearly: 4.877,
          },
          {
            ic_uuid: "19a480f8-ae3d-4e0e-8fb7-026bcd8cd555",
            ic_lat: -205343,
            ic_lon: -439898,
            ic_city: "Jeceaba",
            ic_states: "MINAS GERAIS",
            ic_january: 5.651,
            ic_february: 5.845,
            ic_march: 4.965,
            ic_april: 4.628,
            ic_may: 4.103,
            ic_june: 3.898,
            ic_july: 4.083,
            ic_august: 5.029,
            ic_september: 5.234,
            ic_october: 5.331,
            ic_november: 5.064,
            ic_december: 5.516,
            ic_yearly: 4.945,
          },
          {
            ic_uuid: "8a06aa6a-34e1-4022-9258-d1300c50d02c",
            ic_lat: -170836,
            ic_lon: -422593,
            ic_city: "Jenipapo de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 6.258,
            ic_february: 6.443,
            ic_march: 5.437,
            ic_april: 4.719,
            ic_may: 4.081,
            ic_june: 3.859,
            ic_july: 4.082,
            ic_august: 4.853,
            ic_september: 5.416,
            ic_october: 5.623,
            ic_november: 5.302,
            ic_december: 6.059,
            ic_yearly: 5.178,
          },
          {
            ic_uuid: "89a4bc5b-5292-4d65-9a6f-bf1c65bfbf38",
            ic_lat: -53696,
            ic_lon: -45636,
            ic_city: "Jenipapo dos Vieiras",
            ic_states: "MARANHÃO",
            ic_january: 4.482,
            ic_february: 4.698,
            ic_march: 4.781,
            ic_april: 4.829,
            ic_may: 4.837,
            ic_june: 5.12,
            ic_july: 5.356,
            ic_august: 5.919,
            ic_september: 5.96,
            ic_october: 5.4,
            ic_november: 4.968,
            ic_december: 4.765,
            ic_yearly: 5.093,
          },
          {
            ic_uuid: "3ddda35f-040a-4755-9f54-cacb4e689432",
            ic_lat: -204547,
            ic_lon: -426654,
            ic_city: "Jequeri",
            ic_states: "MINAS GERAIS",
            ic_january: 5.831,
            ic_february: 6.1,
            ic_march: 5.138,
            ic_april: 4.556,
            ic_may: 3.907,
            ic_june: 3.672,
            ic_july: 3.888,
            ic_august: 4.67,
            ic_september: 4.946,
            ic_october: 5.199,
            ic_november: 4.986,
            ic_december: 5.716,
            ic_yearly: 4.884,
          },
          {
            ic_uuid: "7344f35f-8cc7-4c63-ad40-6086c47a04f1",
            ic_lat: -100137,
            ic_lon: -360145,
            ic_city: "Jequiá da Praia",
            ic_states: "ALAGOAS",
            ic_january: 6.133,
            ic_february: 6.178,
            ic_march: 6.052,
            ic_april: 5.177,
            ic_may: 4.395,
            ic_june: 4.075,
            ic_july: 4.168,
            ic_august: 4.839,
            ic_september: 5.582,
            ic_october: 6.045,
            ic_november: 6.334,
            ic_december: 6.488,
            ic_yearly: 5.455,
          },
          {
            ic_uuid: "b1c432bb-de74-4295-9805-74a50a30db47",
            ic_lat: -138513,
            ic_lon: -40088,
            ic_city: "Jequié",
            ic_states: "BAHIA",
            ic_january: 5.931,
            ic_february: 6.013,
            ic_march: 5.574,
            ic_april: 4.591,
            ic_may: 3.978,
            ic_june: 3.597,
            ic_july: 3.803,
            ic_august: 4.3,
            ic_september: 5.126,
            ic_october: 5.33,
            ic_november: 5.447,
            ic_december: 6.062,
            ic_yearly: 4.979,
          },
          {
            ic_uuid: "42764f9c-8669-419c-b486-5f682c4cd33b",
            ic_lat: -172331,
            ic_lon: -444375,
            ic_city: "Jequitaí",
            ic_states: "MINAS GERAIS",
            ic_january: 6.309,
            ic_february: 6.595,
            ic_march: 5.665,
            ic_april: 5.489,
            ic_may: 4.92,
            ic_june: 4.543,
            ic_july: 4.883,
            ic_august: 5.541,
            ic_september: 5.972,
            ic_october: 6.06,
            ic_november: 5.515,
            ic_december: 6.066,
            ic_yearly: 5.63,
          },
          {
            ic_uuid: "68dffb31-8ff3-4a3a-8995-aee757f48961",
            ic_lat: -19236,
            ic_lon: -44028,
            ic_city: "Jequitibá",
            ic_states: "MINAS GERAIS",
            ic_january: 6.057,
            ic_february: 6.28,
            ic_march: 5.444,
            ic_april: 5.045,
            ic_may: 4.507,
            ic_june: 4.21,
            ic_july: 4.569,
            ic_august: 5.353,
            ic_september: 5.719,
            ic_october: 5.808,
            ic_november: 5.523,
            ic_december: 5.851,
            ic_yearly: 5.364,
          },
          {
            ic_uuid: "6655cf9b-081c-41dd-ae15-fd9ede0de669",
            ic_lat: -164379,
            ic_lon: -410121,
            ic_city: "Jequitinhonha",
            ic_states: "MINAS GERAIS",
            ic_january: 6.095,
            ic_february: 6.262,
            ic_march: 5.439,
            ic_april: 4.766,
            ic_may: 3.997,
            ic_june: 3.649,
            ic_july: 3.827,
            ic_august: 4.465,
            ic_september: 5.234,
            ic_october: 5.388,
            ic_november: 5.181,
            ic_december: 6.042,
            ic_yearly: 5.029,
          },
          {
            ic_uuid: "e8b1f0c9-b06a-4a48-8408-ff2c6b6eba35",
            ic_lat: -100763,
            ic_lon: -38348,
            ic_city: "Jeremoabo",
            ic_states: "BAHIA",
            ic_january: 6.12,
            ic_february: 5.928,
            ic_march: 5.852,
            ic_april: 5.138,
            ic_may: 4.272,
            ic_june: 3.885,
            ic_july: 4.031,
            ic_august: 4.614,
            ic_september: 5.464,
            ic_october: 5.706,
            ic_november: 6.203,
            ic_december: 6.227,
            ic_yearly: 5.287,
          },
          {
            ic_uuid: "5b328a9c-11c9-4031-88bd-a691626dfb8a",
            ic_lat: -65535,
            ic_lon: -37806,
            ic_city: "Jericó",
            ic_states: "PARAÍBA",
            ic_january: 6.087,
            ic_february: 6.094,
            ic_march: 6.133,
            ic_april: 5.824,
            ic_may: 5.309,
            ic_june: 4.947,
            ic_july: 5.236,
            ic_august: 5.988,
            ic_september: 6.528,
            ic_october: 6.653,
            ic_november: 6.689,
            ic_december: 6.266,
            ic_yearly: 5.979,
          },
          {
            ic_uuid: "a52ccbce-82aa-4113-b63f-9cefee214d65",
            ic_lat: -20312,
            ic_lon: -475922,
            ic_city: "Jeriquara",
            ic_states: "SÃO PAULO",
            ic_january: 5.591,
            ic_february: 5.916,
            ic_march: 5.119,
            ic_april: 4.931,
            ic_may: 4.331,
            ic_june: 4.112,
            ic_july: 4.359,
            ic_august: 5.239,
            ic_september: 5.191,
            ic_october: 5.494,
            ic_november: 5.629,
            ic_december: 5.924,
            ic_yearly: 5.153,
          },
          {
            ic_uuid: "a9aef3f9-2fe7-43af-b029-6476f4c03296",
            ic_lat: -207998,
            ic_lon: -413952,
            ic_city: "Jerônimo Monteiro",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.169,
            ic_february: 6.374,
            ic_march: 5.234,
            ic_april: 4.584,
            ic_may: 3.946,
            ic_june: 3.71,
            ic_july: 3.782,
            ic_august: 4.5,
            ic_september: 5.003,
            ic_october: 5.173,
            ic_november: 5.071,
            ic_december: 5.856,
            ic_yearly: 4.95,
          },
          {
            ic_uuid: "51835a54-8120-45c7-aa5b-23b12f0ef883",
            ic_lat: -70917,
            ic_lon: -435037,
            ic_city: "Jerumenha",
            ic_states: "PIAUÍ",
            ic_january: 5.184,
            ic_february: 5.18,
            ic_march: 5.157,
            ic_april: 5.156,
            ic_may: 5.253,
            ic_june: 5.399,
            ic_july: 5.612,
            ic_august: 6.194,
            ic_september: 6.601,
            ic_october: 6.343,
            ic_november: 5.911,
            ic_december: 5.58,
            ic_yearly: 5.631,
          },
          {
            ic_uuid: "d3327777-b28b-4995-a0f6-9b798f4854a8",
            ic_lat: -219891,
            ic_lon: -452915,
            ic_city: "Jesuânia",
            ic_states: "MINAS GERAIS",
            ic_january: 5.424,
            ic_february: 5.661,
            ic_march: 4.872,
            ic_april: 4.56,
            ic_may: 3.934,
            ic_june: 3.627,
            ic_july: 3.888,
            ic_august: 4.786,
            ic_september: 5.002,
            ic_october: 5.382,
            ic_november: 5.273,
            ic_december: 5.603,
            ic_yearly: 4.834,
          },
          {
            ic_uuid: "70ebe17b-4912-4601-a0d6-d0979b584d39",
            ic_lat: -243844,
            ic_lon: -533854,
            ic_city: "Jesuítas",
            ic_states: "PARANÁ",
            ic_january: 6.256,
            ic_february: 5.717,
            ic_march: 5.284,
            ic_april: 4.392,
            ic_may: 3.473,
            ic_june: 3.055,
            ic_july: 3.256,
            ic_august: 4.274,
            ic_september: 4.534,
            ic_october: 5.317,
            ic_november: 6.095,
            ic_december: 6.469,
            ic_yearly: 4.844,
          },
          {
            ic_uuid: "3ac40952-6e25-44cc-9f30-600bb0a42f65",
            ic_lat: -159562,
            ic_lon: -493751,
            ic_city: "Jesúpolis",
            ic_states: "GOIÁS",
            ic_january: 5.453,
            ic_february: 5.676,
            ic_march: 5.286,
            ic_april: 5.23,
            ic_may: 4.903,
            ic_june: 4.66,
            ic_july: 4.841,
            ic_august: 5.726,
            ic_september: 5.548,
            ic_october: 5.595,
            ic_november: 5.348,
            ic_december: 5.501,
            ic_yearly: 5.314,
          },
          {
            ic_uuid: "547c3aef-359c-4067-9079-1e33ca8a06ab",
            ic_lat: -2901,
            ic_lon: -40448,
            ic_city: "Jijoca de Jericoacoara",
            ic_states: "CEARÁ",
            ic_january: 5.27,
            ic_february: 5.209,
            ic_march: 4.947,
            ic_april: 4.529,
            ic_may: 5.01,
            ic_june: 5.132,
            ic_july: 5.38,
            ic_august: 5.922,
            ic_september: 6.13,
            ic_october: 6.052,
            ic_november: 6.175,
            ic_december: 5.721,
            ic_yearly: 5.456,
          },
          {
            ic_uuid: "6d3a8116-7b5e-4710-a194-49fc7fea5a3d",
            ic_lat: -108782,
            ic_lon: -619326,
            ic_city: "Ji-Paraná",
            ic_states: "RONDÔNIA",
            ic_january: 4.411,
            ic_february: 4.425,
            ic_march: 4.454,
            ic_april: 4.505,
            ic_may: 4.074,
            ic_june: 4.363,
            ic_july: 4.43,
            ic_august: 4.867,
            ic_september: 4.877,
            ic_october: 5.149,
            ic_november: 4.968,
            ic_december: 4.747,
            ic_yearly: 4.606,
          },
          {
            ic_uuid: "e9773654-a232-493c-be14-652572ef110b",
            ic_lat: -13254,
            ic_lon: -395682,
            ic_city: "Jiquiriçá",
            ic_states: "BAHIA",
            ic_january: 5.773,
            ic_february: 5.818,
            ic_march: 5.54,
            ic_april: 4.48,
            ic_may: 3.891,
            ic_june: 3.503,
            ic_july: 3.664,
            ic_august: 4.121,
            ic_september: 4.878,
            ic_october: 4.982,
            ic_november: 5.269,
            ic_december: 5.853,
            ic_yearly: 4.814,
          },
          {
            ic_uuid: "97fd29bf-280f-41bd-980c-4db237024c7d",
            ic_lat: -140167,
            ic_lon: -398897,
            ic_city: "Jitaúna",
            ic_states: "BAHIA",
            ic_january: 5.841,
            ic_february: 5.916,
            ic_march: 5.542,
            ic_april: 4.538,
            ic_may: 3.924,
            ic_june: 3.478,
            ic_july: 3.674,
            ic_august: 4.212,
            ic_september: 4.957,
            ic_october: 5.124,
            ic_november: 5.336,
            ic_december: 5.97,
            ic_yearly: 4.876,
          },
          {
            ic_uuid: "ec97b97d-713e-41ed-8b1b-e64591656b3a",
            ic_lat: -271726,
            ic_lon: -515112,
            ic_city: "Joaçaba",
            ic_states: "SANTA CATARINA",
            ic_january: 5.914,
            ic_february: 5.459,
            ic_march: 4.996,
            ic_april: 4.052,
            ic_may: 3.069,
            ic_june: 2.653,
            ic_july: 2.915,
            ic_august: 3.782,
            ic_september: 4.024,
            ic_october: 4.935,
            ic_november: 6.115,
            ic_december: 6.25,
            ic_yearly: 4.514,
          },
          {
            ic_uuid: "165c20b4-3a45-4120-8f86-b9e28791418a",
            ic_lat: -166527,
            ic_lon: -410232,
            ic_city: "Joaíma",
            ic_states: "MINAS GERAIS",
            ic_january: 6.164,
            ic_february: 6.389,
            ic_march: 5.455,
            ic_april: 4.747,
            ic_may: 3.94,
            ic_june: 3.593,
            ic_july: 3.719,
            ic_august: 4.34,
            ic_september: 5.117,
            ic_october: 5.34,
            ic_november: 5.24,
            ic_december: 6.091,
            ic_yearly: 5.011,
          },
          {
            ic_uuid: "45c7b74d-af1d-4c6b-8718-1ad96316e738",
            ic_lat: -191734,
            ic_lon: -426778,
            ic_city: "Joanésia",
            ic_states: "MINAS GERAIS",
            ic_january: 5.929,
            ic_february: 6.187,
            ic_march: 5.187,
            ic_april: 4.666,
            ic_may: 3.949,
            ic_june: 3.724,
            ic_july: 3.895,
            ic_august: 4.586,
            ic_september: 4.948,
            ic_october: 5.172,
            ic_november: 4.955,
            ic_december: 5.755,
            ic_yearly: 4.913,
          },
          {
            ic_uuid: "eb67cc40-0775-4e82-b3f9-668beaae0c2a",
            ic_lat: -229275,
            ic_lon: -462745,
            ic_city: "Joanópolis",
            ic_states: "SÃO PAULO",
            ic_january: 5.211,
            ic_february: 5.485,
            ic_march: 4.777,
            ic_april: 4.482,
            ic_may: 3.741,
            ic_june: 3.497,
            ic_july: 3.644,
            ic_august: 4.628,
            ic_september: 4.761,
            ic_october: 5.287,
            ic_november: 5.368,
            ic_december: 5.74,
            ic_yearly: 4.718,
          },
          {
            ic_uuid: "66a1b8fc-d41c-4d44-8b44-d93100f61732",
            ic_lat: -78607,
            ic_lon: -355911,
            ic_city: "João Alfredo",
            ic_states: "PERNAMBUCO",
            ic_january: 5.61,
            ic_february: 5.54,
            ic_march: 5.661,
            ic_april: 5.1,
            ic_may: 4.392,
            ic_june: 4.056,
            ic_july: 4.166,
            ic_august: 4.798,
            ic_september: 5.395,
            ic_october: 5.562,
            ic_november: 5.88,
            ic_december: 5.794,
            ic_yearly: 5.163,
          },
          {
            ic_uuid: "05417973-7631-4fdd-bb9a-457c07186fcd",
            ic_lat: -55391,
            ic_lon: -358186,
            ic_city: "João Câmara",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.804,
            ic_february: 5.788,
            ic_march: 5.794,
            ic_april: 5.47,
            ic_may: 5.041,
            ic_june: 4.681,
            ic_july: 4.865,
            ic_august: 5.526,
            ic_september: 5.95,
            ic_october: 6.109,
            ic_november: 6.225,
            ic_december: 5.834,
            ic_yearly: 5.591,
          },
          {
            ic_uuid: "6986ec1c-061b-4ade-9b04-eceed7933d17",
            ic_lat: -85124,
            ic_lon: -424152,
            ic_city: "João Costa",
            ic_states: "PIAUÍ",
            ic_january: 5.798,
            ic_february: 5.739,
            ic_march: 5.561,
            ic_april: 5.386,
            ic_may: 5.29,
            ic_june: 5.272,
            ic_july: 5.593,
            ic_august: 6.428,
            ic_september: 6.63,
            ic_october: 6.54,
            ic_november: 6.201,
            ic_december: 5.971,
            ic_yearly: 5.867,
          },
          {
            ic_uuid: "f3e8c0d9-5561-4d85-9120-be03b69da4d0",
            ic_lat: -62726,
            ic_lon: -377889,
            ic_city: "João Dias",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.043,
            ic_february: 6.089,
            ic_march: 6.066,
            ic_april: 5.735,
            ic_may: 5.299,
            ic_june: 4.931,
            ic_july: 5.294,
            ic_august: 6.029,
            ic_september: 6.541,
            ic_october: 6.596,
            ic_november: 6.573,
            ic_december: 6.198,
            ic_yearly: 5.95,
          },
          {
            ic_uuid: "4f369cf4-fbfc-4772-af99-ae46e30eb5af",
            ic_lat: -113491,
            ic_lon: -416551,
            ic_city: "João Dourado",
            ic_states: "BAHIA",
            ic_january: 6.447,
            ic_february: 6.199,
            ic_march: 5.985,
            ic_april: 5.21,
            ic_may: 4.76,
            ic_june: 4.619,
            ic_july: 4.817,
            ic_august: 5.573,
            ic_september: 6.278,
            ic_october: 6.457,
            ic_november: 6.318,
            ic_december: 6.449,
            ic_yearly: 5.759,
          },
          {
            ic_uuid: "e9a3ab4c-5209-4f7f-bed1-f35c25c3fda4",
            ic_lat: -5444,
            ic_lon: -474068,
            ic_city: "João Lisboa",
            ic_states: "MARANHÃO",
            ic_january: 4.616,
            ic_february: 4.697,
            ic_march: 4.775,
            ic_april: 4.835,
            ic_may: 4.805,
            ic_june: 5.17,
            ic_july: 5.309,
            ic_august: 5.712,
            ic_september: 5.549,
            ic_october: 5.156,
            ic_november: 4.87,
            ic_december: 4.72,
            ic_yearly: 5.018,
          },
          {
            ic_uuid: "e04d7e65-abdd-4548-b950-d7dbd9069d97",
            ic_lat: -198131,
            ic_lon: -431738,
            ic_city: "João Monlevade",
            ic_states: "MINAS GERAIS",
            ic_january: 5.499,
            ic_february: 5.809,
            ic_march: 4.879,
            ic_april: 4.448,
            ic_may: 3.826,
            ic_june: 3.688,
            ic_july: 3.902,
            ic_august: 4.681,
            ic_september: 4.956,
            ic_october: 5.022,
            ic_november: 4.718,
            ic_december: 5.379,
            ic_yearly: 4.734,
          },
          {
            ic_uuid: "d371e10d-0935-4bf1-a07e-4fb60738b05d",
            ic_lat: -197582,
            ic_lon: -403863,
            ic_city: "João Neiva",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.955,
            ic_february: 6.143,
            ic_march: 5.273,
            ic_april: 4.47,
            ic_may: 3.858,
            ic_june: 3.567,
            ic_july: 3.671,
            ic_august: 4.206,
            ic_september: 4.649,
            ic_october: 4.733,
            ic_november: 4.79,
            ic_december: 5.631,
            ic_yearly: 4.746,
          },
          {
            ic_uuid: "907c9ab8-31b7-4799-8e91-c38295500597",
            ic_lat: -71159,
            ic_lon: -348647,
            ic_city: "João Pessoa",
            ic_states: "PARAÍBA",
            ic_january: 5.862,
            ic_february: 6.002,
            ic_march: 6.009,
            ic_april: 5.365,
            ic_may: 4.823,
            ic_june: 4.36,
            ic_july: 4.475,
            ic_august: 5.261,
            ic_september: 5.758,
            ic_october: 6.054,
            ic_november: 6.24,
            ic_december: 6.151,
            ic_yearly: 5.53,
          },
          {
            ic_uuid: "83eda52d-fa04-4e23-b748-d743d1da2d33",
            ic_lat: -177418,
            ic_lon: -46176,
            ic_city: "João Pinheiro",
            ic_states: "MINAS GERAIS",
            ic_january: 6.094,
            ic_february: 6.303,
            ic_march: 5.422,
            ic_april: 5.234,
            ic_may: 4.815,
            ic_june: 4.566,
            ic_july: 4.891,
            ic_august: 5.712,
            ic_september: 5.829,
            ic_october: 5.834,
            ic_november: 5.542,
            ic_december: 5.923,
            ic_yearly: 5.514,
          },
          {
            ic_uuid: "be747e67-21c6-4547-bffb-313e4e2415a1",
            ic_lat: -2225,
            ic_lon: -507679,
            ic_city: "João Ramalho",
            ic_states: "SÃO PAULO",
            ic_january: 5.889,
            ic_february: 5.878,
            ic_march: 5.339,
            ic_april: 4.726,
            ic_may: 3.88,
            ic_june: 3.614,
            ic_july: 3.783,
            ic_august: 4.738,
            ic_september: 4.831,
            ic_october: 5.488,
            ic_november: 5.975,
            ic_december: 6.352,
            ic_yearly: 5.041,
          },
          {
            ic_uuid: "1c3a7258-5ce5-4ab4-af2d-7d4bc59319c9",
            ic_lat: -17758,
            ic_lon: -44172,
            ic_city: "Joaquim Felício",
            ic_states: "MINAS GERAIS",
            ic_january: 6.025,
            ic_february: 6.403,
            ic_march: 5.327,
            ic_april: 5.104,
            ic_may: 4.661,
            ic_june: 4.469,
            ic_july: 4.82,
            ic_august: 5.557,
            ic_september: 5.96,
            ic_october: 5.872,
            ic_november: 5.256,
            ic_december: 5.851,
            ic_yearly: 5.442,
          },
          {
            ic_uuid: "3f1f33df-5d7b-4a48-93b5-143a070b7e78",
            ic_lat: -91332,
            ic_lon: -357477,
            ic_city: "Joaquim Gomes",
            ic_states: "ALAGOAS",
            ic_january: 5.529,
            ic_february: 5.545,
            ic_march: 5.634,
            ic_april: 4.915,
            ic_may: 4.089,
            ic_june: 3.773,
            ic_july: 3.793,
            ic_august: 4.388,
            ic_september: 5.141,
            ic_october: 5.329,
            ic_november: 5.874,
            ic_december: 5.782,
            ic_yearly: 4.983,
          },
          {
            ic_uuid: "c8495c38-3636-4aa6-a9e6-6ea74ddaa773",
            ic_lat: -86232,
            ic_lon: -355291,
            ic_city: "Joaquim Nabuco",
            ic_states: "PERNAMBUCO",
            ic_january: 5.652,
            ic_february: 5.57,
            ic_march: 5.62,
            ic_april: 4.937,
            ic_may: 4.179,
            ic_june: 3.821,
            ic_july: 3.826,
            ic_august: 4.432,
            ic_september: 5.202,
            ic_october: 5.473,
            ic_november: 5.928,
            ic_december: 5.898,
            ic_yearly: 5.045,
          },
          {
            ic_uuid: "99646abe-99fe-4c15-af79-ac3b0a1bfdc8",
            ic_lat: -35084,
            ic_lon: -421954,
            ic_city: "Joaquim Pires",
            ic_states: "PIAUÍ",
            ic_january: 5.195,
            ic_february: 5.319,
            ic_march: 5.184,
            ic_april: 4.994,
            ic_may: 5.174,
            ic_june: 5.348,
            ic_july: 5.495,
            ic_august: 5.946,
            ic_september: 6.343,
            ic_october: 6.385,
            ic_november: 6.09,
            ic_december: 5.508,
            ic_yearly: 5.582,
          },
          {
            ic_uuid: "b7060d85-2cf3-40a8-bfa6-235789217cc8",
            ic_lat: -23503,
            ic_lon: -499239,
            ic_city: "Joaquim Távora",
            ic_states: "PARANÁ",
            ic_january: 5.772,
            ic_february: 5.79,
            ic_march: 5.222,
            ic_april: 4.589,
            ic_may: 3.625,
            ic_june: 3.279,
            ic_july: 3.513,
            ic_august: 4.523,
            ic_september: 4.655,
            ic_october: 5.323,
            ic_november: 5.985,
            ic_december: 6.385,
            ic_yearly: 4.888,
          },
          {
            ic_uuid: "1d12d7d9-8fb7-438a-a922-ba4dc5b4d0bb",
            ic_lat: -6484,
            ic_lon: -384767,
            ic_city: "Joca Claudino",
            ic_states: "PARAÍBA",
            ic_january: 5.992,
            ic_february: 5.939,
            ic_march: 5.926,
            ic_april: 5.566,
            ic_may: 5.131,
            ic_june: 4.932,
            ic_july: 5.258,
            ic_august: 6.02,
            ic_september: 6.464,
            ic_october: 6.561,
            ic_november: 6.674,
            ic_december: 6.16,
            ic_yearly: 5.885,
          },
          {
            ic_uuid: "fd2c9278-3489-4168-8278-c01e4c98f179",
            ic_lat: -34989,
            ic_lon: -42433,
            ic_city: "Joca Marques",
            ic_states: "PIAUÍ",
            ic_january: 5.288,
            ic_february: 5.346,
            ic_march: 5.383,
            ic_april: 5.108,
            ic_may: 5.176,
            ic_june: 5.294,
            ic_july: 5.522,
            ic_august: 6.019,
            ic_september: 6.368,
            ic_october: 6.35,
            ic_november: 6.102,
            ic_december: 5.547,
            ic_yearly: 5.625,
          },
          {
            ic_uuid: "dacb542c-e99f-41fb-b388-264069fb7c73",
            ic_lat: -28644,
            ic_lon: -541146,
            ic_city: "Jóia",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.512,
            ic_february: 6.038,
            ic_march: 5.215,
            ic_april: 4.037,
            ic_may: 3.046,
            ic_june: 2.507,
            ic_july: 2.804,
            ic_august: 3.562,
            ic_september: 3.977,
            ic_october: 5.097,
            ic_november: 6.334,
            ic_december: 6.748,
            ic_yearly: 4.656,
          },
          {
            ic_uuid: "bbe9e092-b9b2-4dfd-abd7-4c8d881c9eda",
            ic_lat: -26305,
            ic_lon: -488491,
            ic_city: "Joinville",
            ic_states: "SANTA CATARINA",
            ic_january: 5.046,
            ic_february: 4.844,
            ic_march: 4.241,
            ic_april: 3.519,
            ic_may: 2.926,
            ic_june: 2.449,
            ic_july: 2.517,
            ic_august: 3.184,
            ic_september: 3.198,
            ic_october: 3.712,
            ic_november: 4.737,
            ic_december: 5.071,
            ic_yearly: 3.787,
          },
          {
            ic_uuid: "023133f2-6e27-4398-899a-0ffb14496e1a",
            ic_lat: -158987,
            ic_lon: -40179,
            ic_city: "Jordânia",
            ic_states: "MINAS GERAIS",
            ic_january: 5.97,
            ic_february: 6.143,
            ic_march: 5.514,
            ic_april: 4.797,
            ic_may: 3.949,
            ic_june: 3.549,
            ic_july: 3.747,
            ic_august: 4.255,
            ic_september: 4.97,
            ic_october: 5.12,
            ic_november: 5.212,
            ic_december: 6.099,
            ic_yearly: 4.944,
          },
          {
            ic_uuid: "8ba11e52-df74-4bf7-9ed4-6c1b27d36095",
            ic_lat: -91969,
            ic_lon: -719482,
            ic_city: "Jordão",
            ic_states: "ACRE",
            ic_january: 4.685,
            ic_february: 4.804,
            ic_march: 4.118,
            ic_april: 4.494,
            ic_may: 4.132,
            ic_june: 4.151,
            ic_july: 4.367,
            ic_august: 5.047,
            ic_september: 5.174,
            ic_october: 5.038,
            ic_november: 5.006,
            ic_december: 4.669,
            ic_yearly: 4.64,
          },
          {
            ic_uuid: "b9bd3ae5-f14f-4e7f-9085-b9049c3ff8e2",
            ic_lat: -26957,
            ic_lon: -496291,
            ic_city: "José Boiteux",
            ic_states: "SANTA CATARINA",
            ic_january: 5.498,
            ic_february: 5.222,
            ic_march: 4.589,
            ic_april: 3.628,
            ic_may: 2.863,
            ic_june: 2.339,
            ic_july: 2.543,
            ic_august: 3.355,
            ic_september: 3.501,
            ic_october: 4.126,
            ic_november: 5.331,
            ic_december: 5.646,
            ic_yearly: 4.053,
          },
          {
            ic_uuid: "eedb20bf-3e9a-45f7-8c65-de0c91714b91",
            ic_lat: -210555,
            ic_lon: -496896,
            ic_city: "José Bonifácio",
            ic_states: "SÃO PAULO",
            ic_january: 5.889,
            ic_february: 5.878,
            ic_march: 5.263,
            ic_april: 4.842,
            ic_may: 4.055,
            ic_june: 3.805,
            ic_july: 4.053,
            ic_august: 4.932,
            ic_september: 5.031,
            ic_october: 5.611,
            ic_november: 5.945,
            ic_december: 6.195,
            ic_yearly: 5.125,
          },
          {
            ic_uuid: "b76df6b5-37e8-4dfe-8638-eb7da89aeadb",
            ic_lat: -63114,
            ic_lon: -382826,
            ic_city: "José da Penha",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.006,
            ic_february: 6.039,
            ic_march: 6.016,
            ic_april: 5.724,
            ic_may: 5.279,
            ic_june: 4.992,
            ic_july: 5.317,
            ic_august: 6.001,
            ic_september: 6.441,
            ic_october: 6.554,
            ic_november: 6.621,
            ic_december: 6.188,
            ic_yearly: 5.932,
          },
          {
            ic_uuid: "f6b340ee-280c-445a-be10-6ec44d4adb60",
            ic_lat: -47519,
            ic_lon: -425749,
            ic_city: "José de Freitas",
            ic_states: "PIAUÍ",
            ic_january: 5.181,
            ic_february: 5.285,
            ic_march: 5.308,
            ic_april: 5.129,
            ic_may: 5.073,
            ic_june: 5.263,
            ic_july: 5.543,
            ic_august: 6.139,
            ic_september: 6.494,
            ic_october: 6.394,
            ic_november: 6.175,
            ic_december: 5.684,
            ic_yearly: 5.639,
          },
          {
            ic_uuid: "a408d3eb-3418-4e21-a378-aa544c7f7e11",
            ic_lat: -169086,
            ic_lon: -426046,
            ic_city: "José Gonçalves de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 6.068,
            ic_february: 6.352,
            ic_march: 5.284,
            ic_april: 4.69,
            ic_may: 4.069,
            ic_june: 3.902,
            ic_july: 4.148,
            ic_august: 4.973,
            ic_september: 5.484,
            ic_october: 5.617,
            ic_november: 5.172,
            ic_december: 5.863,
            ic_yearly: 5.135,
          },
          {
            ic_uuid: "d7b7a604-94d9-457d-aba3-d4035f3bd6d9",
            ic_lat: -1822,
            ic_lon: -42495,
            ic_city: "José Raydan",
            ic_states: "MINAS GERAIS",
            ic_january: 5.963,
            ic_february: 6.245,
            ic_march: 5.251,
            ic_april: 4.576,
            ic_may: 3.958,
            ic_june: 3.735,
            ic_july: 3.852,
            ic_august: 4.591,
            ic_september: 5.101,
            ic_october: 5.315,
            ic_november: 4.931,
            ic_december: 5.734,
            ic_yearly: 4.938,
          },
          {
            ic_uuid: "6a86da35-b523-406a-9da9-4d76916d578b",
            ic_lat: -49865,
            ic_lon: -446962,
            ic_city: "Joselândia",
            ic_states: "MARANHÃO",
            ic_january: 4.783,
            ic_february: 4.911,
            ic_march: 4.959,
            ic_april: 4.901,
            ic_may: 4.914,
            ic_june: 5.122,
            ic_july: 5.374,
            ic_august: 5.916,
            ic_september: 6.151,
            ic_october: 5.657,
            ic_november: 5.315,
            ic_december: 4.954,
            ic_yearly: 5.247,
          },
          {
            ic_uuid: "6296931a-1c4b-42cd-997e-b9537802e40d",
            ic_lat: -165422,
            ic_lon: -425154,
            ic_city: "Josenópolis",
            ic_states: "MINAS GERAIS",
            ic_january: 6.058,
            ic_february: 6.318,
            ic_march: 5.332,
            ic_april: 4.677,
            ic_may: 4.032,
            ic_june: 3.835,
            ic_july: 4.124,
            ic_august: 4.908,
            ic_september: 5.496,
            ic_october: 5.664,
            ic_november: 5.204,
            ic_december: 5.908,
            ic_yearly: 5.13,
          },
          {
            ic_uuid: "d306781b-3cd3-4cc9-84c7-c4d04f0a304f",
            ic_lat: -178072,
            ic_lon: -496291,
            ic_city: "Joviânia",
            ic_states: "GOIÁS",
            ic_january: 5.607,
            ic_february: 5.688,
            ic_march: 5.188,
            ic_april: 5.094,
            ic_may: 4.672,
            ic_june: 4.363,
            ic_july: 4.606,
            ic_august: 5.54,
            ic_september: 5.33,
            ic_october: 5.587,
            ic_november: 5.575,
            ic_december: 5.725,
            ic_yearly: 5.248,
          },
          {
            ic_uuid: "ec6a9e88-3ec7-4663-bd35-714576b4166c",
            ic_lat: -112535,
            ic_lon: -575083,
            ic_city: "Juara",
            ic_states: "MATO GROSSO",
            ic_january: 4.796,
            ic_february: 4.765,
            ic_march: 4.691,
            ic_april: 4.609,
            ic_may: 4.517,
            ic_june: 4.597,
            ic_july: 4.801,
            ic_august: 5.227,
            ic_september: 5.051,
            ic_october: 5.237,
            ic_november: 5.015,
            ic_december: 5.017,
            ic_yearly: 4.86,
          },
          {
            ic_uuid: "2e824931-01bc-43dd-8e0f-9ab708740f6b",
            ic_lat: -71746,
            ic_lon: -355741,
            ic_city: "Juarez Távora",
            ic_states: "PARAÍBA",
            ic_january: 5.623,
            ic_february: 5.718,
            ic_march: 5.712,
            ic_april: 5.195,
            ic_may: 4.615,
            ic_june: 4.086,
            ic_july: 4.276,
            ic_august: 4.982,
            ic_september: 5.476,
            ic_october: 5.639,
            ic_november: 5.896,
            ic_december: 5.824,
            ic_yearly: 5.253,
          },
          {
            ic_uuid: "b5d71b97-4636-440f-a077-82d3056c0383",
            ic_lat: -81195,
            ic_lon: -490969,
            ic_city: "Juarina",
            ic_states: "TOCANTINS",
            ic_january: 4.681,
            ic_february: 4.782,
            ic_march: 4.699,
            ic_april: 4.674,
            ic_may: 4.897,
            ic_june: 5.107,
            ic_july: 5.216,
            ic_august: 5.925,
            ic_september: 5.564,
            ic_october: 5.049,
            ic_november: 4.781,
            ic_december: 4.634,
            ic_yearly: 5.001,
          },
          {
            ic_uuid: "2cd46065-74b0-44e4-8f88-861847f8a363",
            ic_lat: -199558,
            ic_lon: -443413,
            ic_city: "Juatuba",
            ic_states: "MINAS GERAIS",
            ic_january: 5.851,
            ic_february: 6.019,
            ic_march: 5.155,
            ic_april: 4.856,
            ic_may: 4.223,
            ic_june: 4.08,
            ic_july: 4.339,
            ic_august: 5.185,
            ic_september: 5.444,
            ic_october: 5.568,
            ic_november: 5.293,
            ic_december: 5.562,
            ic_yearly: 5.131,
          },
          {
            ic_uuid: "20a204e6-efce-4190-88c6-f04686af91aa",
            ic_lat: -70684,
            ic_lon: -365792,
            ic_city: "Juazeirinho",
            ic_states: "PARAÍBA",
            ic_january: 5.805,
            ic_february: 5.844,
            ic_march: 5.908,
            ic_april: 5.505,
            ic_may: 4.826,
            ic_june: 4.354,
            ic_july: 4.597,
            ic_august: 5.316,
            ic_september: 5.966,
            ic_october: 6.183,
            ic_november: 6.37,
            ic_december: 6.057,
            ic_yearly: 5.561,
          },
          {
            ic_uuid: "319744c2-431e-4924-8f6e-113e03d47b30",
            ic_lat: -94166,
            ic_lon: -405036,
            ic_city: "Juazeiro",
            ic_states: "BAHIA",
            ic_january: 6.381,
            ic_february: 6.165,
            ic_march: 6.027,
            ic_april: 5.242,
            ic_may: 4.828,
            ic_june: 4.576,
            ic_july: 4.82,
            ic_august: 5.553,
            ic_september: 6.323,
            ic_october: 6.401,
            ic_november: 6.503,
            ic_december: 6.384,
            ic_yearly: 5.767,
          },
          {
            ic_uuid: "b8ddf963-a20f-449a-a5f6-5753fd706ff7",
            ic_lat: -72193,
            ic_lon: -393162,
            ic_city: "Juazeiro do Norte",
            ic_states: "CEARÁ",
            ic_january: 5.927,
            ic_february: 5.884,
            ic_march: 5.848,
            ic_april: 5.551,
            ic_may: 5.162,
            ic_june: 4.938,
            ic_july: 5.278,
            ic_august: 5.991,
            ic_september: 6.519,
            ic_october: 6.568,
            ic_november: 6.601,
            ic_december: 6.231,
            ic_yearly: 5.875,
          },
          {
            ic_uuid: "9753ea86-8768-4986-b2b1-5040758365c5",
            ic_lat: -5175,
            ic_lon: -416979,
            ic_city: "Juazeiro do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.188,
            ic_february: 5.341,
            ic_march: 5.401,
            ic_april: 5.148,
            ic_may: 5.163,
            ic_june: 5.157,
            ic_july: 5.424,
            ic_august: 6.149,
            ic_september: 6.559,
            ic_october: 6.436,
            ic_november: 6.3,
            ic_december: 5.816,
            ic_yearly: 5.674,
          },
          {
            ic_uuid: "5cba1a57-ae9a-42c6-bd30-f14da550194d",
            ic_lat: -65211,
            ic_lon: -39525,
            ic_city: "Jucás",
            ic_states: "CEARÁ",
            ic_january: 5.626,
            ic_february: 5.727,
            ic_march: 5.735,
            ic_april: 5.351,
            ic_may: 5.164,
            ic_june: 5.094,
            ic_july: 5.398,
            ic_august: 6.167,
            ic_september: 6.526,
            ic_october: 6.444,
            ic_november: 6.446,
            ic_december: 5.944,
            ic_yearly: 5.802,
          },
          {
            ic_uuid: "74a58326-aac3-4565-b726-0e59e4293da4",
            ic_lat: -87067,
            ic_lon: -364889,
            ic_city: "Jucati",
            ic_states: "PERNAMBUCO",
            ic_january: 6.026,
            ic_february: 5.872,
            ic_march: 5.843,
            ic_april: 5.21,
            ic_may: 4.339,
            ic_june: 3.888,
            ic_july: 3.99,
            ic_august: 4.717,
            ic_september: 5.515,
            ic_october: 5.799,
            ic_november: 6.284,
            ic_december: 6.208,
            ic_yearly: 5.307,
          },
          {
            ic_uuid: "1d4bd927-0d99-4bbc-9e3c-df4a13319455",
            ic_lat: -168433,
            ic_lon: -401593,
            ic_city: "Jucuruçu",
            ic_states: "BAHIA",
            ic_january: 5.96,
            ic_february: 6.102,
            ic_march: 5.428,
            ic_april: 4.625,
            ic_may: 3.865,
            ic_june: 3.516,
            ic_july: 3.661,
            ic_august: 4.29,
            ic_september: 5.009,
            ic_october: 5.138,
            ic_november: 5.138,
            ic_december: 6.05,
            ic_yearly: 4.899,
          },
          {
            ic_uuid: "910fdb97-d434-4a61-b5c6-c52ecb3d8c68",
            ic_lat: -60355,
            ic_lon: -370203,
            ic_city: "Jucurutu",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.008,
            ic_february: 6.104,
            ic_march: 6.165,
            ic_april: 5.795,
            ic_may: 5.298,
            ic_june: 4.884,
            ic_july: 5.138,
            ic_august: 5.846,
            ic_september: 6.371,
            ic_october: 6.478,
            ic_november: 6.515,
            ic_december: 6.069,
            ic_yearly: 5.889,
          },
          {
            ic_uuid: "6df5d3ad-6bb6-424e-8341-636813e0aade",
            ic_lat: -11425,
            ic_lon: -587705,
            ic_city: "Juína",
            ic_states: "MATO GROSSO",
            ic_january: 4.646,
            ic_february: 4.592,
            ic_march: 4.639,
            ic_april: 4.517,
            ic_may: 4.355,
            ic_june: 4.543,
            ic_july: 4.693,
            ic_august: 5.234,
            ic_september: 5.007,
            ic_october: 5.188,
            ic_november: 4.915,
            ic_december: 4.977,
            ic_yearly: 4.776,
          },
          {
            ic_uuid: "fcba538d-11e5-4529-920a-6343e0e65dab",
            ic_lat: -2176,
            ic_lon: -433401,
            ic_city: "Juiz de Fora",
            ic_states: "MINAS GERAIS",
            ic_january: 5.511,
            ic_february: 5.807,
            ic_march: 4.764,
            ic_april: 4.148,
            ic_may: 3.519,
            ic_june: 3.349,
            ic_july: 3.519,
            ic_august: 4.259,
            ic_september: 4.544,
            ic_october: 4.829,
            ic_november: 4.702,
            ic_december: 5.41,
            ic_yearly: 4.53,
          },
          {
            ic_uuid: "70d630d1-9d89-4b4a-bf7c-efdbe03590fd",
            ic_lat: -103274,
            ic_lon: -442391,
            ic_city: "Júlio Borges",
            ic_states: "PIAUÍ",
            ic_january: 5.975,
            ic_february: 5.839,
            ic_march: 5.687,
            ic_april: 5.455,
            ic_may: 5.362,
            ic_june: 5.286,
            ic_july: 5.579,
            ic_august: 6.27,
            ic_september: 6.606,
            ic_october: 6.21,
            ic_november: 5.865,
            ic_december: 5.999,
            ic_yearly: 5.845,
          },
          {
            ic_uuid: "1f6233d3-cc10-4f04-b0e3-bbf0d1db97ee",
            ic_lat: -292303,
            ic_lon: -536776,
            ic_city: "Júlio de Castilhos",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.374,
            ic_february: 5.937,
            ic_march: 5.062,
            ic_april: 3.943,
            ic_may: 2.948,
            ic_june: 2.429,
            ic_july: 2.739,
            ic_august: 3.521,
            ic_september: 3.909,
            ic_october: 5.012,
            ic_november: 6.3,
            ic_december: 6.761,
            ic_yearly: 4.578,
          },
          {
            ic_uuid: "8a06a136-f57b-4d4a-a12c-8567d18c03c5",
            ic_lat: -22009,
            ic_lon: -497869,
            ic_city: "Júlio Mesquita",
            ic_states: "SÃO PAULO",
            ic_january: 5.718,
            ic_february: 5.879,
            ic_march: 5.227,
            ic_april: 4.773,
            ic_may: 3.905,
            ic_june: 3.605,
            ic_july: 3.841,
            ic_august: 4.753,
            ic_september: 4.845,
            ic_october: 5.597,
            ic_november: 5.949,
            ic_december: 6.232,
            ic_yearly: 5.027,
          },
          {
            ic_uuid: "c466713d-fa90-4cb9-bde1-3f8625ac1526",
            ic_lat: -230888,
            ic_lon: -477872,
            ic_city: "Jumirim",
            ic_states: "SÃO PAULO",
            ic_january: 5.62,
            ic_february: 5.886,
            ic_march: 5.169,
            ic_april: 4.62,
            ic_may: 3.74,
            ic_june: 3.476,
            ic_july: 3.585,
            ic_august: 4.568,
            ic_september: 4.753,
            ic_october: 5.39,
            ic_november: 5.8,
            ic_december: 6.179,
            ic_yearly: 4.899,
          },
          {
            ic_uuid: "b88965ab-ba6b-4c64-a983-657a4d1290a5",
            ic_lat: -18783,
            ic_lon: -460713,
            ic_city: "Junco do Maranhão",
            ic_states: "MARANHÃO",
            ic_january: 4.556,
            ic_february: 4.412,
            ic_march: 4.357,
            ic_april: 4.374,
            ic_may: 4.497,
            ic_june: 4.815,
            ic_july: 4.913,
            ic_august: 5.411,
            ic_september: 5.67,
            ic_october: 5.397,
            ic_november: 5.227,
            ic_december: 4.753,
            ic_yearly: 4.865,
          },
          {
            ic_uuid: "c22fd738-9a17-4d27-bee2-9dd3c6df1013",
            ic_lat: -69931,
            ic_lon: -367169,
            ic_city: "Junco do Seridó",
            ic_states: "PARAÍBA",
            ic_january: 6.022,
            ic_february: 5.976,
            ic_march: 5.954,
            ic_april: 5.568,
            ic_may: 4.993,
            ic_june: 4.56,
            ic_july: 4.847,
            ic_august: 5.625,
            ic_september: 6.225,
            ic_october: 6.413,
            ic_november: 6.495,
            ic_december: 6.147,
            ic_yearly: 5.735,
          },
          {
            ic_uuid: "a0ae45d6-b841-40b1-bc48-d76e0d0d130e",
            ic_lat: -89415,
            ic_lon: -355538,
            ic_city: "Jundiá",
            ic_states: "ALAGOAS",
            ic_january: 5.721,
            ic_february: 5.663,
            ic_march: 5.731,
            ic_april: 5.006,
            ic_may: 4.245,
            ic_june: 3.94,
            ic_july: 3.976,
            ic_august: 4.594,
            ic_september: 5.352,
            ic_october: 5.605,
            ic_november: 5.992,
            ic_december: 5.946,
            ic_yearly: 5.147,
          },
          {
            ic_uuid: "93e8a247-bbbe-4c32-8f74-a29c8cf17b7c",
            ic_lat: -62707,
            ic_lon: -353273,
            ic_city: "Jundiá",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.859,
            ic_february: 6.002,
            ic_march: 6.085,
            ic_april: 5.432,
            ic_may: 4.939,
            ic_june: 4.446,
            ic_july: 4.55,
            ic_august: 5.355,
            ic_september: 5.748,
            ic_october: 6.028,
            ic_november: 6.274,
            ic_december: 5.914,
            ic_yearly: 5.553,
          },
          {
            ic_uuid: "54398c0d-9c64-4802-9faf-9d4f8ac877c5",
            ic_lat: -231857,
            ic_lon: -468978,
            ic_city: "Jundiaí",
            ic_states: "SÃO PAULO",
            ic_january: 5.481,
            ic_february: 5.713,
            ic_march: 5.023,
            ic_april: 4.457,
            ic_may: 3.672,
            ic_june: 3.421,
            ic_july: 3.553,
            ic_august: 4.526,
            ic_september: 4.675,
            ic_october: 5.292,
            ic_november: 5.599,
            ic_december: 6.01,
            ic_yearly: 4.785,
          },
          {
            ic_uuid: "d8581827-ba0a-4aba-9960-b005f28c5f5f",
            ic_lat: -234361,
            ic_lon: -5025,
            ic_city: "Jundiaí do Sul",
            ic_states: "PARANÁ",
            ic_january: 5.757,
            ic_february: 5.794,
            ic_march: 5.141,
            ic_april: 4.591,
            ic_may: 3.672,
            ic_june: 3.305,
            ic_july: 3.546,
            ic_august: 4.531,
            ic_september: 4.684,
            ic_october: 5.326,
            ic_november: 5.949,
            ic_december: 6.316,
            ic_yearly: 4.884,
          },
          {
            ic_uuid: "b15b1f29-7e75-4543-b3ee-843e7b65e7d9",
            ic_lat: -99074,
            ic_lon: -364806,
            ic_city: "Junqueiro",
            ic_states: "ALAGOAS",
            ic_january: 5.999,
            ic_february: 5.806,
            ic_march: 5.804,
            ic_april: 5.069,
            ic_may: 4.271,
            ic_june: 3.934,
            ic_july: 3.975,
            ic_august: 4.556,
            ic_september: 5.354,
            ic_october: 5.681,
            ic_november: 6.193,
            ic_december: 6.2,
            ic_yearly: 5.237,
          },
          {
            ic_uuid: "8c5ee1ae-06ff-41c8-903e-3220efd7bb6c",
            ic_lat: -215108,
            ic_lon: -514346,
            ic_city: "Junqueirópolis",
            ic_states: "SÃO PAULO",
            ic_january: 5.916,
            ic_february: 5.979,
            ic_march: 5.423,
            ic_april: 4.807,
            ic_may: 4.035,
            ic_june: 3.708,
            ic_july: 3.894,
            ic_august: 4.804,
            ic_september: 4.88,
            ic_october: 5.582,
            ic_november: 6.047,
            ic_december: 6.342,
            ic_yearly: 5.118,
          },
          {
            ic_uuid: "2622e93c-c4cd-4f20-8587-623c112a632b",
            ic_lat: -8712,
            ic_lon: -364182,
            ic_city: "Jupi",
            ic_states: "PERNAMBUCO",
            ic_january: 6.026,
            ic_february: 5.872,
            ic_march: 5.843,
            ic_april: 5.21,
            ic_may: 4.339,
            ic_june: 3.888,
            ic_july: 3.99,
            ic_august: 4.717,
            ic_september: 5.515,
            ic_october: 5.799,
            ic_november: 6.284,
            ic_december: 6.208,
            ic_yearly: 5.307,
          },
          {
            ic_uuid: "dadf6057-a0bd-4cd2-ac30-bef1ea2a26ca",
            ic_lat: -263955,
            ic_lon: -527302,
            ic_city: "Jupiá",
            ic_states: "SANTA CATARINA",
            ic_january: 6.159,
            ic_february: 5.639,
            ic_march: 5.074,
            ic_april: 4.152,
            ic_may: 3.229,
            ic_june: 2.79,
            ic_july: 3.069,
            ic_august: 4.0,
            ic_september: 4.184,
            ic_october: 5.119,
            ic_november: 6.089,
            ic_december: 6.287,
            ic_yearly: 4.65,
          },
          {
            ic_uuid: "4218c8c9-f09a-49bb-899c-a8357a00fde5",
            ic_lat: -24321,
            ic_lon: -47635,
            ic_city: "Juquiá",
            ic_states: "SÃO PAULO",
            ic_january: 5.16,
            ic_february: 5.291,
            ic_march: 4.4,
            ic_april: 3.832,
            ic_may: 3.035,
            ic_june: 2.684,
            ic_july: 2.683,
            ic_august: 3.347,
            ic_september: 3.443,
            ic_october: 3.82,
            ic_november: 4.675,
            ic_december: 5.328,
            ic_yearly: 3.975,
          },
          {
            ic_uuid: "bc5f9987-1a7c-4d06-865c-0cf870d8795d",
            ic_lat: -239249,
            ic_lon: -470657,
            ic_city: "Juquitiba",
            ic_states: "SÃO PAULO",
            ic_january: 4.972,
            ic_february: 5.275,
            ic_march: 4.477,
            ic_april: 3.981,
            ic_may: 3.29,
            ic_june: 3.044,
            ic_july: 3.094,
            ic_august: 3.957,
            ic_september: 3.919,
            ic_october: 4.257,
            ic_november: 4.833,
            ic_december: 5.387,
            ic_yearly: 4.207,
          },
          {
            ic_uuid: "95d8620e-a3a2-4663-9cf5-88d07ed4944f",
            ic_lat: -168478,
            ic_lon: -435868,
            ic_city: "Juramento",
            ic_states: "MINAS GERAIS",
            ic_january: 6.29,
            ic_february: 6.49,
            ic_march: 5.521,
            ic_april: 5.23,
            ic_may: 4.737,
            ic_june: 4.476,
            ic_july: 4.736,
            ic_august: 5.488,
            ic_september: 6.016,
            ic_october: 6.005,
            ic_november: 5.448,
            ic_december: 6.134,
            ic_yearly: 5.548,
          },
          {
            ic_uuid: "b3d56c96-b444-47ae-9489-2b8bf2da8eaa",
            ic_lat: -244213,
            ic_lon: -528418,
            ic_city: "Juranda",
            ic_states: "PARANÁ",
            ic_january: 6.222,
            ic_february: 5.628,
            ic_march: 5.151,
            ic_april: 4.444,
            ic_may: 3.492,
            ic_june: 3.077,
            ic_july: 3.286,
            ic_august: 4.217,
            ic_september: 4.48,
            ic_october: 5.247,
            ic_november: 6.025,
            ic_december: 6.481,
            ic_yearly: 4.813,
          },
          {
            ic_uuid: "104fcc61-6eb2-42b2-8dd3-ec188c07e3a8",
            ic_lat: -87076,
            ic_lon: -36135,
            ic_city: "Jurema",
            ic_states: "PERNAMBUCO",
            ic_january: 5.915,
            ic_february: 5.768,
            ic_march: 5.755,
            ic_april: 5.126,
            ic_may: 4.379,
            ic_june: 3.955,
            ic_july: 4.045,
            ic_august: 4.725,
            ic_september: 5.492,
            ic_october: 5.8,
            ic_november: 6.232,
            ic_december: 6.137,
            ic_yearly: 5.278,
          },
          {
            ic_uuid: "0cf5d961-7ede-4950-8ca2-f02fc297e0fe",
            ic_lat: -92254,
            ic_lon: -431303,
            ic_city: "Jurema",
            ic_states: "PIAUÍ",
            ic_january: 6.029,
            ic_february: 5.696,
            ic_march: 5.576,
            ic_april: 5.368,
            ic_may: 5.274,
            ic_june: 5.265,
            ic_july: 5.665,
            ic_august: 6.383,
            ic_september: 6.68,
            ic_october: 6.483,
            ic_november: 6.125,
            ic_december: 5.973,
            ic_yearly: 5.876,
          },
          {
            ic_uuid: "e91c06d6-cfa2-42c1-99cf-2ffaa87f98f8",
            ic_lat: -73587,
            ic_lon: -352387,
            ic_city: "Juripiranga",
            ic_states: "PARAÍBA",
            ic_january: 5.697,
            ic_february: 5.882,
            ic_march: 5.872,
            ic_april: 5.327,
            ic_may: 4.635,
            ic_june: 4.244,
            ic_july: 4.374,
            ic_august: 5.091,
            ic_september: 5.602,
            ic_october: 5.818,
            ic_november: 6.102,
            ic_december: 5.973,
            ic_yearly: 5.385,
          },
          {
            ic_uuid: "3bd10673-7934-4141-b9fe-038881e5c00f",
            ic_lat: -75383,
            ic_lon: -378202,
            ic_city: "Juru",
            ic_states: "PARAÍBA",
            ic_january: 6.068,
            ic_february: 6.073,
            ic_march: 6.036,
            ic_april: 5.78,
            ic_may: 5.124,
            ic_june: 4.781,
            ic_july: 5.022,
            ic_august: 5.824,
            ic_september: 6.535,
            ic_october: 6.577,
            ic_november: 6.694,
            ic_december: 6.27,
            ic_yearly: 5.899,
          },
          {
            ic_uuid: "1ae84d6b-a0d8-432a-ad02-7e92e598a907",
            ic_lat: -34848,
            ic_lon: -660723,
            ic_city: "Juruá",
            ic_states: "AMAZONAS",
            ic_january: 4.147,
            ic_february: 4.393,
            ic_march: 4.378,
            ic_april: 4.149,
            ic_may: 3.802,
            ic_june: 4.026,
            ic_july: 4.161,
            ic_august: 4.756,
            ic_september: 4.827,
            ic_october: 4.794,
            ic_november: 4.626,
            ic_december: 4.334,
            ic_yearly: 4.366,
          },
          {
            ic_uuid: "657eabe1-2d40-4aa8-a62f-6027dd26ff4d",
            ic_lat: -212498,
            ic_lon: -465739,
            ic_city: "Juruaia",
            ic_states: "MINAS GERAIS",
            ic_january: 5.419,
            ic_february: 5.591,
            ic_march: 4.755,
            ic_april: 4.587,
            ic_may: 3.991,
            ic_june: 3.775,
            ic_july: 4.076,
            ic_august: 5.035,
            ic_september: 5.079,
            ic_october: 5.349,
            ic_november: 5.23,
            ic_december: 5.465,
            ic_yearly: 4.863,
          },
          {
            ic_uuid: "4f7cdfa8-0a75-461a-9aa9-754fa7ec6c29",
            ic_lat: -103377,
            ic_lon: -584985,
            ic_city: "Juruena",
            ic_states: "MATO GROSSO",
            ic_january: 4.471,
            ic_february: 4.506,
            ic_march: 4.577,
            ic_april: 4.419,
            ic_may: 4.378,
            ic_june: 4.645,
            ic_july: 4.788,
            ic_august: 5.115,
            ic_september: 4.931,
            ic_october: 5.154,
            ic_november: 4.772,
            ic_december: 4.767,
            ic_yearly: 4.71,
          },
          {
            ic_uuid: "83b53f23-f0bf-4997-9bed-56cdc153c270",
            ic_lat: -21639,
            ic_lon: -560893,
            ic_city: "Juruti",
            ic_states: "PARÁ",
            ic_january: 4.346,
            ic_february: 4.409,
            ic_march: 4.386,
            ic_april: 4.124,
            ic_may: 4.194,
            ic_june: 4.667,
            ic_july: 4.637,
            ic_august: 5.271,
            ic_september: 5.293,
            ic_october: 5.451,
            ic_november: 5.27,
            ic_december: 4.731,
            ic_yearly: 4.732,
          },
          {
            ic_uuid: "ca5fc6d5-e34b-4d09-b911-7ef51d68fffb",
            ic_lat: -160538,
            ic_lon: -548874,
            ic_city: "Juscimeira",
            ic_states: "MATO GROSSO",
            ic_january: 5.465,
            ic_february: 5.471,
            ic_march: 5.176,
            ic_april: 5.071,
            ic_may: 4.632,
            ic_june: 4.501,
            ic_july: 4.61,
            ic_august: 5.546,
            ic_september: 5.178,
            ic_october: 5.199,
            ic_november: 5.454,
            ic_december: 5.577,
            ic_yearly: 5.157,
          },
          {
            ic_uuid: "3f44d610-9583-40ca-bf09-4aece3291591",
            ic_lat: -110435,
            ic_lon: -419706,
            ic_city: "Jussara",
            ic_states: "BAHIA",
            ic_january: 6.375,
            ic_february: 6.216,
            ic_march: 6.009,
            ic_april: 5.324,
            ic_may: 4.924,
            ic_june: 4.792,
            ic_july: 5.011,
            ic_august: 5.798,
            ic_september: 6.459,
            ic_october: 6.436,
            ic_november: 6.366,
            ic_december: 6.444,
            ic_yearly: 5.846,
          },
          {
            ic_uuid: "df52c02f-2421-40e0-bd4c-48787d53d979",
            ic_lat: -158663,
            ic_lon: -508672,
            ic_city: "Jussara",
            ic_states: "GOIÁS",
            ic_january: 5.508,
            ic_february: 5.649,
            ic_march: 5.376,
            ic_april: 5.356,
            ic_may: 4.891,
            ic_june: 4.634,
            ic_july: 4.685,
            ic_august: 5.443,
            ic_september: 5.427,
            ic_october: 5.605,
            ic_november: 5.419,
            ic_december: 5.521,
            ic_yearly: 5.293,
          },
          {
            ic_uuid: "8569b10f-defe-47f3-bbaa-635e463355d6",
            ic_lat: -236224,
            ic_lon: -524697,
            ic_city: "Jussara",
            ic_states: "PARANÁ",
            ic_january: 6.047,
            ic_february: 5.83,
            ic_march: 5.336,
            ic_april: 4.553,
            ic_may: 3.628,
            ic_june: 3.243,
            ic_july: 3.466,
            ic_august: 4.363,
            ic_september: 4.612,
            ic_october: 5.343,
            ic_november: 6.102,
            ic_december: 6.43,
            ic_yearly: 4.913,
          },
          {
            ic_uuid: "014d343d-7af5-4b72-b3a5-ac60fabec39f",
            ic_lat: -15188,
            ic_lon: -394941,
            ic_city: "Jussari",
            ic_states: "BAHIA",
            ic_january: 5.603,
            ic_february: 5.693,
            ic_march: 5.282,
            ic_april: 4.492,
            ic_may: 3.833,
            ic_june: 3.483,
            ic_july: 3.7,
            ic_august: 4.204,
            ic_september: 4.727,
            ic_october: 4.903,
            ic_november: 4.972,
            ic_december: 5.785,
            ic_yearly: 4.723,
          },
          {
            ic_uuid: "0a67ad06-80c7-468f-bcb2-5120619d1c49",
            ic_lat: -13516,
            ic_lon: -415916,
            ic_city: "Jussiape",
            ic_states: "BAHIA",
            ic_january: 6.222,
            ic_february: 6.196,
            ic_march: 5.736,
            ic_april: 4.89,
            ic_may: 4.371,
            ic_june: 4.044,
            ic_july: 4.257,
            ic_august: 5.03,
            ic_september: 5.924,
            ic_october: 6.099,
            ic_november: 5.811,
            ic_december: 6.453,
            ic_yearly: 5.419,
          },
          {
            ic_uuid: "135b9419-3b7a-4173-a5bd-55b6acc86ae8",
            ic_lat: -2749,
            ic_lon: -667696,
            ic_city: "Jutaí",
            ic_states: "AMAZONAS",
            ic_january: 4.38,
            ic_february: 4.703,
            ic_march: 4.51,
            ic_april: 4.283,
            ic_may: 3.903,
            ic_june: 4.145,
            ic_july: 4.117,
            ic_august: 4.79,
            ic_september: 5.001,
            ic_october: 4.91,
            ic_november: 4.727,
            ic_december: 4.47,
            ic_yearly: 4.495,
          },
          {
            ic_uuid: "4005b0fe-72a0-4140-ad46-00cd68ebdcef",
            ic_lat: -228601,
            ic_lon: -546066,
            ic_city: "Juti",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.94,
            ic_february: 5.752,
            ic_march: 5.335,
            ic_april: 4.426,
            ic_may: 3.553,
            ic_june: 3.231,
            ic_july: 3.402,
            ic_august: 4.386,
            ic_september: 4.649,
            ic_october: 5.344,
            ic_november: 5.988,
            ic_december: 6.412,
            ic_yearly: 4.868,
          },
          {
            ic_uuid: "e4202a90-8e1f-4d1f-8291-3428414beac4",
            ic_lat: -142667,
            ic_lon: -4416,
            ic_city: "Juvenília",
            ic_states: "MINAS GERAIS",
            ic_january: 6.561,
            ic_february: 6.564,
            ic_march: 5.932,
            ic_april: 5.666,
            ic_may: 5.111,
            ic_june: 4.865,
            ic_july: 5.262,
            ic_august: 5.774,
            ic_september: 6.192,
            ic_october: 6.265,
            ic_november: 5.748,
            ic_december: 6.348,
            ic_yearly: 5.857,
          },
          {
            ic_uuid: "e37ab6ae-6980-46db-a02a-5b811104e64a",
            ic_lat: -238193,
            ic_lon: -516691,
            ic_city: "Kaloré",
            ic_states: "PARANÁ",
            ic_january: 5.85,
            ic_february: 5.713,
            ic_march: 5.227,
            ic_april: 4.526,
            ic_may: 3.561,
            ic_june: 3.222,
            ic_july: 3.45,
            ic_august: 4.442,
            ic_september: 4.605,
            ic_october: 5.257,
            ic_november: 5.989,
            ic_december: 6.279,
            ic_yearly: 4.843,
          },
          {
            ic_uuid: "d25a3976-74ed-4c19-ac7c-b16b0a7128e0",
            ic_lat: -72645,
            ic_lon: -647952,
            ic_city: "Lábrea",
            ic_states: "AMAZONAS",
            ic_january: 4.213,
            ic_february: 4.39,
            ic_march: 4.28,
            ic_april: 4.175,
            ic_may: 3.82,
            ic_june: 4.239,
            ic_july: 4.376,
            ic_august: 4.878,
            ic_september: 4.969,
            ic_october: 4.863,
            ic_november: 4.636,
            ic_december: 4.365,
            ic_yearly: 4.434,
          },
          {
            ic_uuid: "e13a0e2b-53d7-4f9c-9e8e-1c888cf48520",
            ic_lat: -272584,
            ic_lon: -515581,
            ic_city: "Lacerdópolis",
            ic_states: "SANTA CATARINA",
            ic_january: 6.072,
            ic_february: 5.618,
            ic_march: 5.074,
            ic_april: 4.098,
            ic_may: 3.074,
            ic_june: 2.659,
            ic_july: 2.935,
            ic_august: 3.746,
            ic_september: 4.042,
            ic_october: 5.01,
            ic_november: 6.201,
            ic_december: 6.42,
            ic_yearly: 4.579,
          },
          {
            ic_uuid: "c14157fb-7b9d-4f29-8457-eec0f5f26a20",
            ic_lat: -176284,
            ic_lon: -417492,
            ic_city: "Ladainha",
            ic_states: "MINAS GERAIS",
            ic_january: 5.961,
            ic_february: 6.221,
            ic_march: 5.241,
            ic_april: 4.58,
            ic_may: 3.885,
            ic_june: 3.589,
            ic_july: 3.765,
            ic_august: 4.468,
            ic_september: 5.006,
            ic_october: 5.348,
            ic_november: 4.954,
            ic_december: 5.752,
            ic_yearly: 4.898,
          },
          {
            ic_uuid: "1b6572ba-89e5-4b52-b3b8-af1a2c85b40d",
            ic_lat: -190093,
            ic_lon: -575977,
            ic_city: "Ladário",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.752,
            ic_february: 5.571,
            ic_march: 5.237,
            ic_april: 4.946,
            ic_may: 3.853,
            ic_june: 3.687,
            ic_july: 3.921,
            ic_august: 4.689,
            ic_september: 4.798,
            ic_october: 5.479,
            ic_november: 5.773,
            ic_december: 6.147,
            ic_yearly: 4.988,
          },
          {
            ic_uuid: "4f887c56-1a75-4c5c-81a7-43919d5ae92e",
            ic_lat: -13656,
            ic_lon: -402124,
            ic_city: "Lafaiete Coutinho",
            ic_states: "BAHIA",
            ic_january: 5.841,
            ic_february: 5.885,
            ic_march: 5.45,
            ic_april: 4.465,
            ic_may: 3.923,
            ic_june: 3.55,
            ic_july: 3.731,
            ic_august: 4.289,
            ic_september: 5.216,
            ic_october: 5.3,
            ic_november: 5.384,
            ic_december: 5.926,
            ic_yearly: 4.914,
          },
          {
            ic_uuid: "f73a0199-9fe5-41c2-b57e-adcad3609e72",
            ic_lat: -181764,
            ic_lon: -468067,
            ic_city: "Lagamar",
            ic_states: "MINAS GERAIS",
            ic_january: 5.803,
            ic_february: 6.103,
            ic_march: 5.197,
            ic_april: 5.167,
            ic_may: 4.749,
            ic_june: 4.51,
            ic_july: 4.809,
            ic_august: 5.701,
            ic_september: 5.759,
            ic_october: 5.798,
            ic_november: 5.469,
            ic_december: 5.737,
            ic_yearly: 5.4,
          },
          {
            ic_uuid: "790b9d05-152e-4d01-a32d-58844e4b7e3a",
            ic_lat: -109141,
            ic_lon: -376693,
            ic_city: "Lagarto",
            ic_states: "SERGIPE",
            ic_january: 6.076,
            ic_february: 5.952,
            ic_march: 5.818,
            ic_april: 4.978,
            ic_may: 4.156,
            ic_june: 3.865,
            ic_july: 4.015,
            ic_august: 4.472,
            ic_september: 5.293,
            ic_october: 5.651,
            ic_november: 6.087,
            ic_december: 6.195,
            ic_yearly: 5.213,
          },
          {
            ic_uuid: "9b8f6190-5b8c-4dcf-ae43-5398e7c0cb20",
            ic_lat: -278154,
            ic_lon: -503263,
            ic_city: "Lages",
            ic_states: "SANTA CATARINA",
            ic_january: 5.751,
            ic_february: 5.448,
            ic_march: 4.603,
            ic_april: 3.727,
            ic_may: 2.854,
            ic_june: 2.447,
            ic_july: 2.691,
            ic_august: 3.497,
            ic_september: 3.676,
            ic_october: 4.414,
            ic_november: 5.637,
            ic_december: 5.977,
            ic_yearly: 4.227,
          },
          {
            ic_uuid: "6ab6d934-17ae-48b8-a79f-9740ca6cc317",
            ic_lat: -45586,
            ic_lon: -451347,
            ic_city: "Lago da Pedra",
            ic_states: "MARANHÃO",
            ic_january: 4.632,
            ic_february: 4.745,
            ic_march: 4.878,
            ic_april: 4.852,
            ic_may: 4.736,
            ic_june: 5.003,
            ic_july: 5.194,
            ic_august: 5.687,
            ic_september: 5.931,
            ic_october: 5.524,
            ic_november: 5.083,
            ic_december: 4.824,
            ic_yearly: 5.091,
          },
          {
            ic_uuid: "23668bef-8692-4c32-a895-f60318d1942e",
            ic_lat: -46077,
            ic_lon: -450566,
            ic_city: "Lago do Junco",
            ic_states: "MARANHÃO",
            ic_january: 4.638,
            ic_february: 4.789,
            ic_march: 4.875,
            ic_april: 4.856,
            ic_may: 4.771,
            ic_june: 5.013,
            ic_july: 5.205,
            ic_august: 5.757,
            ic_september: 5.987,
            ic_october: 5.531,
            ic_november: 5.133,
            ic_december: 4.862,
            ic_yearly: 5.118,
          },
          {
            ic_uuid: "1127bb13-7267-4fdb-86c3-9daea3ee5934",
            ic_lat: -46121,
            ic_lon: -449801,
            ic_city: "Lago dos Rodrigues",
            ic_states: "MARANHÃO",
            ic_january: 4.692,
            ic_february: 4.831,
            ic_march: 4.903,
            ic_april: 4.842,
            ic_may: 4.814,
            ic_june: 4.984,
            ic_july: 5.177,
            ic_august: 5.744,
            ic_september: 6.01,
            ic_october: 5.556,
            ic_november: 5.154,
            ic_december: 4.949,
            ic_yearly: 5.138,
          },
          {
            ic_uuid: "bbf91e94-4b40-4460-944b-f76a59149842",
            ic_lat: -40446,
            ic_lon: -449338,
            ic_city: "Lago Verde",
            ic_states: "MARANHÃO",
            ic_january: 4.763,
            ic_february: 4.846,
            ic_march: 4.974,
            ic_april: 4.878,
            ic_may: 4.839,
            ic_june: 4.984,
            ic_july: 5.158,
            ic_august: 5.689,
            ic_september: 6.033,
            ic_october: 5.587,
            ic_november: 5.196,
            ic_december: 5.025,
            ic_yearly: 5.164,
          },
          {
            ic_uuid: "adf4e9cc-0723-4811-9d2e-34cddb4a4d72",
            ic_lat: -65921,
            ic_lon: -379156,
            ic_city: "Lagoa",
            ic_states: "PARAÍBA",
            ic_january: 6.096,
            ic_february: 6.082,
            ic_march: 6.128,
            ic_april: 5.812,
            ic_may: 5.308,
            ic_june: 4.99,
            ic_july: 5.265,
            ic_august: 6.027,
            ic_september: 6.573,
            ic_october: 6.682,
            ic_november: 6.748,
            ic_december: 6.282,
            ic_yearly: 5.999,
          },
          {
            ic_uuid: "57c46156-9612-42fa-93ec-d0f489dd264c",
            ic_lat: -45119,
            ic_lon: -426179,
            ic_city: "Lagoa Alegre",
            ic_states: "PIAUÍ",
            ic_january: 5.145,
            ic_february: 5.204,
            ic_march: 5.291,
            ic_april: 5.114,
            ic_may: 5.079,
            ic_june: 5.275,
            ic_july: 5.556,
            ic_august: 6.076,
            ic_september: 6.518,
            ic_october: 6.439,
            ic_november: 6.148,
            ic_december: 5.656,
            ic_yearly: 5.625,
          },
          {
            ic_uuid: "31f6ee40-01ed-480e-89bc-6ec909f1c9eb",
            ic_lat: -294893,
            ic_lon: -530139,
            ic_city: "Lagoa Bonita do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.228,
            ic_february: 5.881,
            ic_march: 4.959,
            ic_april: 3.877,
            ic_may: 2.863,
            ic_june: 2.399,
            ic_july: 2.647,
            ic_august: 3.386,
            ic_september: 3.71,
            ic_october: 4.942,
            ic_november: 6.228,
            ic_december: 6.697,
            ic_yearly: 4.485,
          },
          {
            ic_uuid: "17c3b7db-8b07-4bfb-8139-96b8fe1c2404",
            ic_lat: -98294,
            ic_lon: -367401,
            ic_city: "Lagoa da Canoa",
            ic_states: "ALAGOAS",
            ic_january: 6.065,
            ic_february: 5.84,
            ic_march: 5.855,
            ic_april: 5.113,
            ic_may: 4.249,
            ic_june: 3.977,
            ic_july: 4.12,
            ic_august: 4.683,
            ic_september: 5.45,
            ic_october: 5.821,
            ic_november: 6.324,
            ic_december: 6.29,
            ic_yearly: 5.316,
          },
          {
            ic_uuid: "bcee8bca-e1cc-4ecb-9bec-1f322ec0438a",
            ic_lat: -107876,
            ic_lon: -496213,
            ic_city: "Lagoa da Confusão",
            ic_states: "TOCANTINS",
            ic_january: 5.172,
            ic_february: 5.187,
            ic_march: 4.887,
            ic_april: 4.909,
            ic_may: 4.956,
            ic_june: 4.974,
            ic_july: 5.183,
            ic_august: 5.771,
            ic_september: 5.551,
            ic_october: 5.345,
            ic_november: 5.157,
            ic_december: 5.163,
            ic_yearly: 5.188,
          },
          {
            ic_uuid: "dd85fab2-6762-4b76-bd9d-02d334b8d5c2",
            ic_lat: -200242,
            ic_lon: -455405,
            ic_city: "Lagoa da Prata",
            ic_states: "MINAS GERAIS",
            ic_january: 5.891,
            ic_february: 6.058,
            ic_march: 5.168,
            ic_april: 4.994,
            ic_may: 4.384,
            ic_june: 4.152,
            ic_july: 4.412,
            ic_august: 5.317,
            ic_september: 5.509,
            ic_october: 5.606,
            ic_november: 5.311,
            ic_december: 5.7,
            ic_yearly: 5.208,
          },
          {
            ic_uuid: "9e4dc9f0-ee75-4b4e-aa18-01b965809a1b",
            ic_lat: -63916,
            ic_lon: -355973,
            ic_city: "Lagoa d'Anta",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.745,
            ic_february: 5.829,
            ic_march: 5.876,
            ic_april: 5.425,
            ic_may: 4.883,
            ic_june: 4.479,
            ic_july: 4.642,
            ic_august: 5.315,
            ic_september: 5.722,
            ic_october: 5.903,
            ic_november: 6.095,
            ic_december: 5.805,
            ic_yearly: 5.476,
          },
          {
            ic_uuid: "ee191b2d-357c-41f5-992f-2945cc153ef9",
            ic_lat: -66725,
            ic_lon: -353709,
            ic_city: "Lagoa de Dentro",
            ic_states: "PARAÍBA",
            ic_january: 5.762,
            ic_february: 5.779,
            ic_march: 5.818,
            ic_april: 5.316,
            ic_may: 4.724,
            ic_june: 4.256,
            ic_july: 4.42,
            ic_august: 5.139,
            ic_september: 5.587,
            ic_october: 5.863,
            ic_november: 6.142,
            ic_december: 5.917,
            ic_yearly: 5.394,
          },
          {
            ic_uuid: "7c6d6c3f-ebb5-4fac-a29d-b220b82038b1",
            ic_lat: -61557,
            ic_lon: -354378,
            ic_city: "Lagoa de Pedras",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.859,
            ic_february: 5.933,
            ic_march: 5.982,
            ic_april: 5.477,
            ic_may: 4.909,
            ic_june: 4.527,
            ic_july: 4.665,
            ic_august: 5.372,
            ic_september: 5.766,
            ic_october: 5.981,
            ic_november: 6.175,
            ic_december: 5.848,
            ic_yearly: 5.541,
          },
          {
            ic_uuid: "f3ca6e61-8cf5-485a-92d3-6460dd99e14c",
            ic_lat: -43934,
            ic_lon: -415993,
            ic_city: "Lagoa de São Francisco",
            ic_states: "PIAUÍ",
            ic_january: 5.126,
            ic_february: 5.282,
            ic_march: 5.248,
            ic_april: 4.941,
            ic_may: 5.106,
            ic_june: 5.223,
            ic_july: 5.459,
            ic_august: 6.134,
            ic_september: 6.564,
            ic_october: 6.466,
            ic_november: 6.257,
            ic_december: 5.64,
            ic_yearly: 5.621,
          },
          {
            ic_uuid: "07063ef5-18e9-4225-888e-983e60a82d46",
            ic_lat: -60039,
            ic_lon: -358712,
            ic_city: "Lagoa de Velhos",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.752,
            ic_february: 5.772,
            ic_march: 5.768,
            ic_april: 5.457,
            ic_may: 4.949,
            ic_june: 4.506,
            ic_july: 4.725,
            ic_august: 5.382,
            ic_september: 5.752,
            ic_october: 5.962,
            ic_november: 6.108,
            ic_december: 5.76,
            ic_yearly: 5.491,
          },
          {
            ic_uuid: "7057a197-8a2d-4311-819b-37f5488421e2",
            ic_lat: -84769,
            ic_lon: -415376,
            ic_city: "Lagoa do Barro do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 6.036,
            ic_february: 5.88,
            ic_march: 5.767,
            ic_april: 5.412,
            ic_may: 5.24,
            ic_june: 5.158,
            ic_july: 5.53,
            ic_august: 6.317,
            ic_september: 6.508,
            ic_october: 6.641,
            ic_november: 6.459,
            ic_december: 6.181,
            ic_yearly: 5.927,
          },
          {
            ic_uuid: "0b531e7e-85ce-4b07-b721-56af558aa22e",
            ic_lat: -78443,
            ic_lon: -353111,
            ic_city: "Lagoa do Carro",
            ic_states: "PERNAMBUCO",
            ic_january: 5.622,
            ic_february: 5.673,
            ic_march: 5.688,
            ic_april: 5.157,
            ic_may: 4.405,
            ic_june: 4.071,
            ic_july: 4.186,
            ic_august: 4.835,
            ic_september: 5.436,
            ic_october: 5.644,
            ic_november: 5.891,
            ic_december: 5.776,
            ic_yearly: 5.199,
          },
          {
            ic_uuid: "6e9e48a7-8443-41c0-9ca1-d8a42a8d2eb2",
            ic_lat: -79292,
            ic_lon: -352885,
            ic_city: "Lagoa do Itaenga",
            ic_states: "PERNAMBUCO",
            ic_january: 5.751,
            ic_february: 5.844,
            ic_march: 5.804,
            ic_april: 5.229,
            ic_may: 4.486,
            ic_june: 4.152,
            ic_july: 4.277,
            ic_august: 4.999,
            ic_september: 5.539,
            ic_october: 5.777,
            ic_november: 6.008,
            ic_december: 6.02,
            ic_yearly: 5.324,
          },
          {
            ic_uuid: "55aa50a9-3578-46f2-a15e-7582e0021434",
            ic_lat: -60477,
            ic_lon: -435278,
            ic_city: "Lagoa do Mato",
            ic_states: "MARANHÃO",
            ic_january: 4.901,
            ic_february: 5.078,
            ic_march: 4.974,
            ic_april: 5.05,
            ic_may: 5.114,
            ic_june: 5.38,
            ic_july: 5.596,
            ic_august: 6.203,
            ic_september: 6.486,
            ic_october: 6.134,
            ic_november: 5.713,
            ic_december: 5.262,
            ic_yearly: 5.491,
          },
          {
            ic_uuid: "b37ce889-3c4a-4b1d-855a-8f5c5b257434",
            ic_lat: -91261,
            ic_lon: -364587,
            ic_city: "Lagoa do Ouro",
            ic_states: "PERNAMBUCO",
            ic_january: 6.014,
            ic_february: 5.843,
            ic_march: 5.836,
            ic_april: 5.193,
            ic_may: 4.246,
            ic_june: 3.777,
            ic_july: 3.873,
            ic_august: 4.524,
            ic_september: 5.471,
            ic_october: 5.841,
            ic_november: 6.384,
            ic_december: 6.315,
            ic_yearly: 5.277,
          },
          {
            ic_uuid: "4f23491a-dc11-4235-a4b8-03c11ab41abe",
            ic_lat: -54135,
            ic_lon: -42647,
            ic_city: "Lagoa do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.083,
            ic_february: 5.154,
            ic_march: 5.255,
            ic_april: 5.11,
            ic_may: 5.135,
            ic_june: 5.333,
            ic_july: 5.605,
            ic_august: 6.192,
            ic_september: 6.609,
            ic_october: 6.412,
            ic_november: 6.133,
            ic_december: 5.726,
            ic_yearly: 5.645,
          },
          {
            ic_uuid: "ac817ca6-7da9-42f6-b110-88f4c0fb239e",
            ic_lat: -6521,
            ic_lon: -415727,
            ic_city: "Lagoa do Sítio",
            ic_states: "PIAUÍ",
            ic_january: 5.21,
            ic_february: 5.28,
            ic_march: 5.226,
            ic_april: 5.171,
            ic_may: 5.179,
            ic_june: 5.177,
            ic_july: 5.606,
            ic_august: 6.24,
            ic_september: 6.587,
            ic_october: 6.36,
            ic_november: 6.145,
            ic_december: 5.663,
            ic_yearly: 5.653,
          },
          {
            ic_uuid: "c645f790-9408-421c-ad68-c00864b82459",
            ic_lat: -102963,
            ic_lon: -475653,
            ic_city: "Lagoa do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 5.409,
            ic_february: 5.326,
            ic_march: 5.062,
            ic_april: 5.137,
            ic_may: 5.209,
            ic_june: 5.117,
            ic_july: 5.344,
            ic_august: 5.966,
            ic_september: 6.067,
            ic_october: 5.574,
            ic_november: 5.375,
            ic_december: 5.402,
            ic_yearly: 5.416,
          },
          {
            ic_uuid: "db2d3695-42d2-4146-8eb2-2e64c13fa24a",
            ic_lat: -86606,
            ic_lon: -359043,
            ic_city: "Lagoa dos Gatos",
            ic_states: "PERNAMBUCO",
            ic_january: 5.836,
            ic_february: 5.685,
            ic_march: 5.702,
            ic_april: 5.051,
            ic_may: 4.237,
            ic_june: 3.874,
            ic_july: 3.921,
            ic_august: 4.559,
            ic_september: 5.304,
            ic_october: 5.64,
            ic_november: 6.104,
            ic_december: 6.03,
            ic_yearly: 5.162,
          },
          {
            ic_uuid: "d453e82e-84e2-49e6-b260-237c5832ed15",
            ic_lat: -169825,
            ic_lon: -445791,
            ic_city: "Lagoa dos Patos",
            ic_states: "MINAS GERAIS",
            ic_january: 6.19,
            ic_february: 6.469,
            ic_march: 5.505,
            ic_april: 5.397,
            ic_may: 4.865,
            ic_june: 4.54,
            ic_july: 4.897,
            ic_august: 5.652,
            ic_september: 5.996,
            ic_october: 5.915,
            ic_november: 5.44,
            ic_december: 5.937,
            ic_yearly: 5.567,
          },
          {
            ic_uuid: "bcee06c0-fe66-4694-a966-de2a58885191",
            ic_lat: -285688,
            ic_lon: -528629,
            ic_city: "Lagoa dos Três Cantos",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.334,
            ic_february: 5.902,
            ic_march: 5.021,
            ic_april: 3.984,
            ic_may: 3.032,
            ic_june: 2.479,
            ic_july: 2.787,
            ic_august: 3.568,
            ic_september: 3.864,
            ic_october: 5.049,
            ic_november: 6.273,
            ic_december: 6.665,
            ic_yearly: 4.58,
          },
          {
            ic_uuid: "3b778519-803d-41d6-bfe6-fa5ef5fba3e8",
            ic_lat: -209143,
            ic_lon: -440801,
            ic_city: "Lagoa Dourada",
            ic_states: "MINAS GERAIS",
            ic_january: 5.575,
            ic_february: 5.842,
            ic_march: 4.854,
            ic_april: 4.423,
            ic_may: 3.83,
            ic_june: 3.696,
            ic_july: 3.917,
            ic_august: 4.851,
            ic_september: 5.053,
            ic_october: 5.25,
            ic_november: 4.958,
            ic_december: 5.541,
            ic_yearly: 4.816,
          },
          {
            ic_uuid: "b922294d-3689-4e51-ac82-cfee5810ddda",
            ic_lat: -18772,
            ic_lon: -464016,
            ic_city: "Lagoa Formosa",
            ic_states: "MINAS GERAIS",
            ic_january: 5.759,
            ic_february: 6.014,
            ic_march: 5.116,
            ic_april: 5.028,
            ic_may: 4.673,
            ic_june: 4.401,
            ic_july: 4.703,
            ic_august: 5.643,
            ic_september: 5.677,
            ic_october: 5.664,
            ic_november: 5.356,
            ic_december: 5.65,
            ic_yearly: 5.307,
          },
          {
            ic_uuid: "9a948b3e-b8b8-44d3-b0b0-c8d647eb21e2",
            ic_lat: -178436,
            ic_lon: -465187,
            ic_city: "Lagoa Grande",
            ic_states: "MINAS GERAIS",
            ic_january: 6.021,
            ic_february: 6.297,
            ic_march: 5.407,
            ic_april: 5.267,
            ic_may: 4.802,
            ic_june: 4.572,
            ic_july: 4.891,
            ic_august: 5.668,
            ic_september: 5.833,
            ic_october: 5.925,
            ic_november: 5.534,
            ic_december: 5.896,
            ic_yearly: 5.51,
          },
          {
            ic_uuid: "e67f9177-a00f-4f42-95ff-6452670ec0d2",
            ic_lat: -89946,
            ic_lon: -402702,
            ic_city: "Lagoa Grande",
            ic_states: "PERNAMBUCO",
            ic_january: 6.217,
            ic_february: 5.919,
            ic_march: 5.923,
            ic_april: 5.273,
            ic_may: 4.758,
            ic_june: 4.513,
            ic_july: 4.755,
            ic_august: 5.574,
            ic_september: 6.352,
            ic_october: 6.365,
            ic_november: 6.462,
            ic_december: 6.223,
            ic_yearly: 5.695,
          },
          {
            ic_uuid: "0f69c72f-89f7-4f29-8a2a-96eff03c0357",
            ic_lat: -49893,
            ic_lon: -45382,
            ic_city: "Lagoa Grande do Maranhão",
            ic_states: "MARANHÃO",
            ic_january: 4.571,
            ic_february: 4.756,
            ic_march: 4.799,
            ic_april: 4.799,
            ic_may: 4.783,
            ic_june: 4.988,
            ic_july: 5.248,
            ic_august: 5.734,
            ic_september: 5.913,
            ic_october: 5.41,
            ic_november: 5.036,
            ic_december: 4.813,
            ic_yearly: 5.071,
          },
          {
            ic_uuid: "bfd0c498-47a4-4baf-8205-eef5867f0df8",
            ic_lat: -60938,
            ic_lon: -364706,
            ic_city: "Lagoa Nova",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.745,
            ic_february: 5.815,
            ic_march: 5.816,
            ic_april: 5.41,
            ic_may: 4.949,
            ic_june: 4.584,
            ic_july: 4.797,
            ic_august: 5.582,
            ic_september: 6.105,
            ic_october: 6.23,
            ic_november: 6.315,
            ic_december: 5.861,
            ic_yearly: 5.601,
          },
          {
            ic_uuid: "f5640b52-eb27-43fa-8b08-afdd85a85092",
            ic_lat: -14034,
            ic_lon: -421414,
            ic_city: "Lagoa Real",
            ic_states: "BAHIA",
            ic_january: 6.4,
            ic_february: 6.49,
            ic_march: 5.801,
            ic_april: 5.034,
            ic_may: 4.454,
            ic_june: 4.208,
            ic_july: 4.513,
            ic_august: 5.269,
            ic_september: 5.938,
            ic_october: 6.277,
            ic_november: 5.85,
            ic_december: 6.501,
            ic_yearly: 5.561,
          },
          {
            ic_uuid: "05cbb4bb-33ac-43c3-936a-9cd8607f2292",
            ic_lat: -61212,
            ic_lon: -354759,
            ic_city: "Lagoa Salgada",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.785,
            ic_february: 5.849,
            ic_march: 5.98,
            ic_april: 5.457,
            ic_may: 4.924,
            ic_june: 4.488,
            ic_july: 4.626,
            ic_august: 5.326,
            ic_september: 5.741,
            ic_october: 5.948,
            ic_november: 6.18,
            ic_december: 5.922,
            ic_yearly: 5.519,
          },
          {
            ic_uuid: "230df850-f802-429a-89d2-eb1d057e18f9",
            ic_lat: -191856,
            ic_lon: -513998,
            ic_city: "Lagoa Santa",
            ic_states: "GOIÁS",
            ic_january: 5.752,
            ic_february: 5.879,
            ic_march: 5.385,
            ic_april: 5.079,
            ic_may: 4.448,
            ic_june: 4.132,
            ic_july: 4.387,
            ic_august: 5.234,
            ic_september: 5.194,
            ic_october: 5.605,
            ic_november: 5.807,
            ic_december: 6.135,
            ic_yearly: 5.253,
          },
          {
            ic_uuid: "6fd062a5-fcc9-4c58-b5c8-728f92e6a3c4",
            ic_lat: -19627,
            ic_lon: -4389,
            ic_city: "Lagoa Santa",
            ic_states: "MINAS GERAIS",
            ic_january: 5.903,
            ic_february: 6.127,
            ic_march: 5.326,
            ic_april: 4.943,
            ic_may: 4.355,
            ic_june: 4.137,
            ic_july: 4.417,
            ic_august: 5.201,
            ic_september: 5.591,
            ic_october: 5.68,
            ic_november: 5.497,
            ic_december: 5.799,
            ic_yearly: 5.248,
          },
          {
            ic_uuid: "b1b8728a-8acb-4453-a4dc-ad82e11385da",
            ic_lat: -71558,
            ic_lon: -358545,
            ic_city: "Lagoa Seca",
            ic_states: "PARAÍBA",
            ic_january: 5.546,
            ic_february: 5.669,
            ic_march: 5.767,
            ic_april: 5.231,
            ic_may: 4.626,
            ic_june: 4.063,
            ic_july: 4.164,
            ic_august: 4.898,
            ic_september: 5.491,
            ic_october: 5.751,
            ic_november: 5.994,
            ic_december: 5.764,
            ic_yearly: 5.247,
          },
          {
            ic_uuid: "be382036-e1a6-4293-beb2-9723f036d8ae",
            ic_lat: -282098,
            ic_lon: -515253,
            ic_city: "Lagoa Vermelha",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.107,
            ic_february: 5.726,
            ic_march: 4.907,
            ic_april: 3.974,
            ic_may: 2.997,
            ic_june: 2.518,
            ic_july: 2.873,
            ic_august: 3.627,
            ic_september: 3.832,
            ic_october: 4.925,
            ic_november: 6.168,
            ic_december: 6.459,
            ic_yearly: 4.509,
          },
          {
            ic_uuid: "1b08c58e-215c-47c9-a416-f9da70b075f1",
            ic_lat: -292231,
            ic_lon: -52772,
            ic_city: "Lagoão",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.227,
            ic_february: 5.873,
            ic_march: 4.952,
            ic_april: 3.919,
            ic_may: 2.895,
            ic_june: 2.421,
            ic_july: 2.696,
            ic_august: 3.45,
            ic_september: 3.733,
            ic_october: 4.912,
            ic_november: 6.256,
            ic_december: 6.639,
            ic_yearly: 4.498,
          },
          {
            ic_uuid: "021b5230-9f6b-4e16-9060-c7575e02cb35",
            ic_lat: -230851,
            ic_lon: -451948,
            ic_city: "Lagoinha",
            ic_states: "SÃO PAULO",
            ic_january: 5.518,
            ic_february: 5.734,
            ic_march: 4.829,
            ic_april: 4.41,
            ic_may: 3.638,
            ic_june: 3.428,
            ic_july: 3.537,
            ic_august: 4.455,
            ic_september: 4.528,
            ic_october: 5.088,
            ic_november: 5.202,
            ic_december: 5.768,
            ic_yearly: 4.678,
          },
          {
            ic_uuid: "75b2d2c6-5e54-4c11-80a7-da8df05ba781",
            ic_lat: -58316,
            ic_lon: -426338,
            ic_city: "Lagoinha do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 4.961,
            ic_february: 5.238,
            ic_march: 5.213,
            ic_april: 5.113,
            ic_may: 5.214,
            ic_june: 5.431,
            ic_july: 5.684,
            ic_august: 6.244,
            ic_september: 6.596,
            ic_october: 6.362,
            ic_november: 5.993,
            ic_december: 5.593,
            ic_yearly: 5.637,
          },
          {
            ic_uuid: "9cefc817-d8a2-4aba-a6a2-5759f3089c47",
            ic_lat: -284848,
            ic_lon: -487777,
            ic_city: "Laguna",
            ic_states: "SANTA CATARINA",
            ic_january: 6.21,
            ic_february: 5.642,
            ic_march: 4.818,
            ic_april: 3.888,
            ic_may: 3.051,
            ic_june: 2.531,
            ic_july: 2.749,
            ic_august: 3.447,
            ic_september: 3.703,
            ic_october: 4.671,
            ic_november: 6.059,
            ic_december: 6.481,
            ic_yearly: 4.438,
          },
          {
            ic_uuid: "0e3a038f-13ef-4abb-acc8-f97ee418a059",
            ic_lat: -225486,
            ic_lon: -551506,
            ic_city: "Laguna Carapã",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.882,
            ic_february: 5.705,
            ic_march: 5.241,
            ic_april: 4.448,
            ic_may: 3.588,
            ic_june: 3.297,
            ic_july: 3.408,
            ic_august: 4.42,
            ic_september: 4.703,
            ic_october: 5.334,
            ic_november: 5.886,
            ic_december: 6.289,
            ic_yearly: 4.85,
          },
          {
            ic_uuid: "3ccd68d2-698b-4af7-8d83-fb6eb6303dba",
            ic_lat: -131678,
            ic_lon: -394216,
            ic_city: "Laje",
            ic_states: "BAHIA",
            ic_january: 5.86,
            ic_february: 5.758,
            ic_march: 5.597,
            ic_april: 4.546,
            ic_may: 3.921,
            ic_june: 3.557,
            ic_july: 3.733,
            ic_august: 4.222,
            ic_september: 4.983,
            ic_october: 5.124,
            ic_november: 5.396,
            ic_december: 5.905,
            ic_yearly: 4.884,
          },
          {
            ic_uuid: "40059ede-4d08-4a46-8880-2233db8b5c5e",
            ic_lat: -212102,
            ic_lon: -421191,
            ic_city: "Laje do Muriaé",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.115,
            ic_february: 6.309,
            ic_march: 5.186,
            ic_april: 4.529,
            ic_may: 3.819,
            ic_june: 3.603,
            ic_july: 3.701,
            ic_august: 4.496,
            ic_september: 4.897,
            ic_october: 5.216,
            ic_november: 5.06,
            ic_december: 5.946,
            ic_yearly: 4.906,
          },
          {
            ic_uuid: "510ff3ab-d102-4261-b282-afa5bf02ffaa",
            ic_lat: -294596,
            ic_lon: -519649,
            ic_city: "Lajeado",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.139,
            ic_february: 5.756,
            ic_march: 4.816,
            ic_april: 3.794,
            ic_may: 2.811,
            ic_june: 2.33,
            ic_july: 2.568,
            ic_august: 3.28,
            ic_september: 3.589,
            ic_october: 4.64,
            ic_november: 6.124,
            ic_december: 6.499,
            ic_yearly: 4.362,
          },
          {
            ic_uuid: "35dc0f8a-0121-41eb-b150-5d7de9fe73e2",
            ic_lat: -97504,
            ic_lon: -483569,
            ic_city: "Lajeado",
            ic_states: "TOCANTINS",
            ic_january: 4.984,
            ic_february: 5.006,
            ic_march: 4.803,
            ic_april: 4.885,
            ic_may: 5.033,
            ic_june: 5.126,
            ic_july: 5.343,
            ic_august: 6.086,
            ic_september: 5.77,
            ic_october: 5.349,
            ic_november: 5.085,
            ic_december: 5.049,
            ic_yearly: 5.21,
          },
          {
            ic_uuid: "5ece99b9-6a80-4455-bc29-2578ee133cb7",
            ic_lat: -276918,
            ic_lon: -531823,
            ic_city: "Lajeado do Bugre",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.377,
            ic_february: 5.883,
            ic_march: 5.09,
            ic_april: 4.112,
            ic_may: 3.11,
            ic_june: 2.563,
            ic_july: 2.914,
            ic_august: 3.688,
            ic_september: 3.971,
            ic_october: 5.147,
            ic_november: 6.271,
            ic_december: 6.71,
            ic_yearly: 4.653,
          },
          {
            ic_uuid: "dafba11f-261e-447c-b445-d508d1b2b65a",
            ic_lat: -268581,
            ic_lon: -525652,
            ic_city: "Lajeado Grande",
            ic_states: "SANTA CATARINA",
            ic_january: 6.267,
            ic_february: 5.734,
            ic_march: 5.174,
            ic_april: 4.148,
            ic_may: 3.168,
            ic_june: 2.699,
            ic_july: 2.979,
            ic_august: 3.846,
            ic_september: 4.073,
            ic_october: 5.142,
            ic_november: 6.184,
            ic_december: 6.44,
            ic_yearly: 4.655,
          },
          {
            ic_uuid: "b4fa130b-f841-40d5-ba13-04a7b3f67542",
            ic_lat: -61899,
            ic_lon: -470373,
            ic_city: "Lajeado Novo",
            ic_states: "MARANHÃO",
            ic_january: 4.678,
            ic_february: 4.792,
            ic_march: 4.776,
            ic_april: 4.862,
            ic_may: 4.932,
            ic_june: 5.267,
            ic_july: 5.413,
            ic_august: 5.958,
            ic_september: 5.858,
            ic_october: 5.322,
            ic_november: 4.879,
            ic_december: 4.799,
            ic_yearly: 5.128,
          },
          {
            ic_uuid: "c7397fef-315b-4406-b13e-e491bb287ae6",
            ic_lat: -176061,
            ic_lon: -403386,
            ic_city: "Lajedão",
            ic_states: "BAHIA",
            ic_january: 6.131,
            ic_february: 6.247,
            ic_march: 5.406,
            ic_april: 4.622,
            ic_may: 3.866,
            ic_june: 3.504,
            ic_july: 3.615,
            ic_august: 4.28,
            ic_september: 4.936,
            ic_october: 5.25,
            ic_november: 5.24,
            ic_december: 6.094,
            ic_yearly: 4.933,
          },
          {
            ic_uuid: "d40902bf-ad07-4895-82a9-705dcdb54ae9",
            ic_lat: -123556,
            ic_lon: -409034,
            ic_city: "Lajedinho",
            ic_states: "BAHIA",
            ic_january: 5.871,
            ic_february: 5.842,
            ic_march: 5.515,
            ic_april: 4.557,
            ic_may: 4.021,
            ic_june: 3.762,
            ic_july: 3.901,
            ic_august: 4.558,
            ic_september: 5.476,
            ic_october: 5.489,
            ic_november: 5.475,
            ic_december: 5.848,
            ic_yearly: 5.026,
          },
          {
            ic_uuid: "a53a201d-922e-4341-86c6-695f464a0f5d",
            ic_lat: -86604,
            ic_lon: -36323,
            ic_city: "Lajedo",
            ic_states: "PERNAMBUCO",
            ic_january: 5.981,
            ic_february: 5.838,
            ic_march: 5.823,
            ic_april: 5.188,
            ic_may: 4.352,
            ic_june: 3.915,
            ic_july: 4.014,
            ic_august: 4.722,
            ic_september: 5.523,
            ic_october: 5.756,
            ic_november: 6.242,
            ic_december: 6.185,
            ic_yearly: 5.295,
          },
          {
            ic_uuid: "619a03b1-61fb-4045-a29a-7b07fc19356f",
            ic_lat: -134667,
            ic_lon: -402208,
            ic_city: "Lajedo do Tabocal",
            ic_states: "BAHIA",
            ic_january: 5.696,
            ic_february: 5.831,
            ic_march: 5.476,
            ic_april: 4.469,
            ic_may: 4.003,
            ic_june: 3.541,
            ic_july: 3.758,
            ic_august: 4.345,
            ic_september: 5.258,
            ic_october: 5.367,
            ic_november: 5.432,
            ic_december: 5.86,
            ic_yearly: 4.92,
          },
          {
            ic_uuid: "342dcd24-00ae-486f-8735-b793c34bf05e",
            ic_lat: -57016,
            ic_lon: -362475,
            ic_city: "Lajes",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.946,
            ic_february: 5.966,
            ic_march: 5.89,
            ic_april: 5.612,
            ic_may: 5.242,
            ic_june: 4.768,
            ic_july: 5.02,
            ic_august: 5.762,
            ic_september: 6.175,
            ic_october: 6.435,
            ic_november: 6.41,
            ic_december: 6.002,
            ic_yearly: 5.769,
          },
          {
            ic_uuid: "c770c17f-5ba4-42d5-9840-436d7469a02e",
            ic_lat: -61498,
            ic_lon: -361174,
            ic_city: "Lajes Pintadas",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.673,
            ic_february: 5.8,
            ic_march: 5.718,
            ic_april: 5.455,
            ic_may: 4.94,
            ic_june: 4.527,
            ic_july: 4.772,
            ic_august: 5.467,
            ic_september: 5.914,
            ic_october: 6.11,
            ic_november: 6.18,
            ic_december: 5.733,
            ic_yearly: 5.524,
          },
          {
            ic_uuid: "ff2dd798-35a4-4c96-8d7e-b1b0b5ec4c61",
            ic_lat: -201544,
            ic_lon: -416232,
            ic_city: "Lajinha",
            ic_states: "MINAS GERAIS",
            ic_january: 5.81,
            ic_february: 5.967,
            ic_march: 5.012,
            ic_april: 4.549,
            ic_may: 3.843,
            ic_june: 3.62,
            ic_july: 3.756,
            ic_august: 4.43,
            ic_september: 4.754,
            ic_october: 4.857,
            ic_november: 4.786,
            ic_december: 5.502,
            ic_yearly: 4.741,
          },
          {
            ic_uuid: "ae39065d-a50d-46e1-a88a-2abd684a3fea",
            ic_lat: -117735,
            ic_lon: -388873,
            ic_city: "Lamarão",
            ic_states: "BAHIA",
            ic_january: 6.08,
            ic_february: 5.846,
            ic_march: 5.663,
            ic_april: 4.729,
            ic_may: 3.989,
            ic_june: 3.687,
            ic_july: 3.932,
            ic_august: 4.367,
            ic_september: 5.157,
            ic_october: 5.426,
            ic_november: 5.816,
            ic_december: 6.087,
            ic_yearly: 5.065,
          },
          {
            ic_uuid: "5fdd8594-202f-4dc5-87fc-3f824ca1f064",
            ic_lat: -219676,
            ic_lon: -453502,
            ic_city: "Lambari",
            ic_states: "MINAS GERAIS",
            ic_january: 5.381,
            ic_february: 5.577,
            ic_march: 4.782,
            ic_april: 4.501,
            ic_may: 3.883,
            ic_june: 3.619,
            ic_july: 3.882,
            ic_august: 4.756,
            ic_september: 4.977,
            ic_october: 5.351,
            ic_november: 5.124,
            ic_december: 5.511,
            ic_yearly: 4.779,
          },
          {
            ic_uuid: "f8ed3dd0-198b-4593-8928-e49fed1607ad",
            ic_lat: -153186,
            ic_lon: -580049,
            ic_city: "Lambari D'Oeste",
            ic_states: "MATO GROSSO",
            ic_january: 5.116,
            ic_february: 5.049,
            ic_march: 4.898,
            ic_april: 4.724,
            ic_may: 4.209,
            ic_june: 4.284,
            ic_july: 4.386,
            ic_august: 5.177,
            ic_september: 5.003,
            ic_october: 5.097,
            ic_november: 5.306,
            ic_december: 5.263,
            ic_yearly: 4.876,
          },
          {
            ic_uuid: "315b58cb-591e-4707-87dc-5e5fb360d668",
            ic_lat: -207905,
            ic_lon: -43471,
            ic_city: "Lamim",
            ic_states: "MINAS GERAIS",
            ic_january: 5.599,
            ic_february: 6.014,
            ic_march: 4.928,
            ic_april: 4.358,
            ic_may: 3.752,
            ic_june: 3.581,
            ic_july: 3.773,
            ic_august: 4.698,
            ic_september: 4.93,
            ic_october: 5.133,
            ic_november: 4.791,
            ic_december: 5.533,
            ic_yearly: 4.758,
          },
          {
            ic_uuid: "7b830f3b-03e4-4fb3-82f0-9d309e4cf125",
            ic_lat: -72628,
            ic_lon: -439316,
            ic_city: "Landri Sales",
            ic_states: "PIAUÍ",
            ic_january: 5.146,
            ic_february: 5.138,
            ic_march: 5.038,
            ic_april: 5.13,
            ic_may: 5.3,
            ic_june: 5.397,
            ic_july: 5.642,
            ic_august: 6.157,
            ic_september: 6.614,
            ic_october: 6.243,
            ic_november: 5.755,
            ic_december: 5.417,
            ic_yearly: 5.582,
          },
          {
            ic_uuid: "e9f9a9a4-8263-431d-8f70-7b1ddb2ea89f",
            ic_lat: -257676,
            ic_lon: -497172,
            ic_city: "Lapa",
            ic_states: "PARANÁ",
            ic_january: 5.357,
            ic_february: 5.128,
            ic_march: 4.537,
            ic_april: 3.742,
            ic_may: 3.0,
            ic_june: 2.666,
            ic_july: 2.856,
            ic_august: 3.864,
            ic_september: 3.89,
            ic_october: 4.466,
            ic_november: 5.345,
            ic_december: 5.637,
            ic_yearly: 4.208,
          },
          {
            ic_uuid: "2db7da75-99f5-4062-b7ba-7892e99cf13c",
            ic_lat: -113855,
            ic_lon: -41829,
            ic_city: "Lapão",
            ic_states: "BAHIA",
            ic_january: 6.448,
            ic_february: 6.217,
            ic_march: 6.001,
            ic_april: 5.243,
            ic_may: 4.765,
            ic_june: 4.582,
            ic_july: 4.806,
            ic_august: 5.577,
            ic_september: 6.258,
            ic_october: 6.452,
            ic_november: 6.221,
            ic_december: 6.442,
            ic_yearly: 5.751,
          },
          {
            ic_uuid: "e55533bc-b7f3-4e20-ab87-bc98bcbe9cf5",
            ic_lat: -198999,
            ic_lon: -410625,
            ic_city: "Laranja da Terra",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.03,
            ic_february: 6.184,
            ic_march: 5.248,
            ic_april: 4.706,
            ic_may: 3.948,
            ic_june: 3.638,
            ic_july: 3.727,
            ic_august: 4.392,
            ic_september: 4.724,
            ic_october: 4.905,
            ic_november: 4.873,
            ic_december: 5.663,
            ic_yearly: 4.836,
          },
          {
            ic_uuid: "6bbd818a-720f-472c-a856-2de3950f768a",
            ic_lat: -21372,
            ic_lon: -424735,
            ic_city: "Laranjal",
            ic_states: "MINAS GERAIS",
            ic_january: 5.96,
            ic_february: 6.16,
            ic_march: 5.131,
            ic_april: 4.431,
            ic_may: 3.723,
            ic_june: 3.492,
            ic_july: 3.637,
            ic_august: 4.408,
            ic_september: 4.788,
            ic_october: 5.108,
            ic_november: 4.939,
            ic_december: 5.843,
            ic_yearly: 4.802,
          },
          {
            ic_uuid: "34ea2f66-4de0-4a7e-a8c1-67c6200dcd93",
            ic_lat: -248866,
            ic_lon: -524705,
            ic_city: "Laranjal",
            ic_states: "PARANÁ",
            ic_january: 6.203,
            ic_february: 5.633,
            ic_march: 5.272,
            ic_april: 4.414,
            ic_may: 3.42,
            ic_june: 3.027,
            ic_july: 3.251,
            ic_august: 4.257,
            ic_september: 4.443,
            ic_october: 5.249,
            ic_november: 6.096,
            ic_december: 6.326,
            ic_yearly: 4.799,
          },
          {
            ic_uuid: "2b7cfbb0-9278-4b30-8d2b-045c4978446f",
            ic_lat: -8379,
            ic_lon: -525227,
            ic_city: "Laranjal do Jari",
            ic_states: "AMAPÁ",
            ic_january: 4.352,
            ic_february: 4.349,
            ic_march: 4.363,
            ic_april: 4.356,
            ic_may: 4.524,
            ic_june: 4.695,
            ic_july: 4.722,
            ic_august: 5.03,
            ic_september: 5.118,
            ic_october: 5.063,
            ic_november: 4.893,
            ic_december: 4.431,
            ic_yearly: 4.658,
          },
          {
            ic_uuid: "502324cb-0612-4e5e-ba50-31c817cc0332",
            ic_lat: -23051,
            ic_lon: -47838,
            ic_city: "Laranjal Paulista",
            ic_states: "SÃO PAULO",
            ic_january: 5.629,
            ic_february: 5.836,
            ic_march: 5.108,
            ic_april: 4.621,
            ic_may: 3.698,
            ic_june: 3.455,
            ic_july: 3.587,
            ic_august: 4.576,
            ic_september: 4.74,
            ic_october: 5.386,
            ic_november: 5.756,
            ic_december: 6.175,
            ic_yearly: 4.88,
          },
          {
            ic_uuid: "d1787e81-2e2d-4512-a52c-cece8cb1f610",
            ic_lat: -108048,
            ic_lon: -371671,
            ic_city: "Laranjeiras",
            ic_states: "SERGIPE",
            ic_january: 6.342,
            ic_february: 6.168,
            ic_march: 6.057,
            ic_april: 5.084,
            ic_may: 4.329,
            ic_june: 3.938,
            ic_july: 4.09,
            ic_august: 4.613,
            ic_september: 5.431,
            ic_october: 5.853,
            ic_november: 6.344,
            ic_december: 6.416,
            ic_yearly: 5.389,
          },
          {
            ic_uuid: "1a6ef98d-193a-44e6-ba9f-efa9332f034f",
            ic_lat: -254081,
            ic_lon: -524113,
            ic_city: "Laranjeiras do Sul",
            ic_states: "PARANÁ",
            ic_january: 6.118,
            ic_february: 5.604,
            ic_march: 5.183,
            ic_april: 4.272,
            ic_may: 3.32,
            ic_june: 2.954,
            ic_july: 3.19,
            ic_august: 4.174,
            ic_september: 4.35,
            ic_october: 5.195,
            ic_november: 6.059,
            ic_december: 6.229,
            ic_yearly: 4.721,
          },
          {
            ic_uuid: "c9ab9661-800d-46c2-b560-e9e9bd3efce0",
            ic_lat: -17887,
            ic_lon: -44578,
            ic_city: "Lassance",
            ic_states: "MINAS GERAIS",
            ic_january: 6.078,
            ic_february: 6.437,
            ic_march: 5.538,
            ic_april: 5.35,
            ic_may: 4.831,
            ic_june: 4.501,
            ic_july: 4.878,
            ic_august: 5.486,
            ic_september: 5.977,
            ic_october: 5.908,
            ic_november: 5.47,
            ic_december: 5.946,
            ic_yearly: 5.533,
          },
          {
            ic_uuid: "ed8de226-fcda-461f-bc19-0ea50ef7ad98",
            ic_lat: -65064,
            ic_lon: -381745,
            ic_city: "Lastro",
            ic_states: "PARAÍBA",
            ic_january: 6.022,
            ic_february: 5.97,
            ic_march: 6.052,
            ic_april: 5.715,
            ic_may: 5.26,
            ic_june: 4.982,
            ic_july: 5.269,
            ic_august: 6.031,
            ic_september: 6.464,
            ic_october: 6.597,
            ic_november: 6.75,
            ic_december: 6.203,
            ic_yearly: 5.943,
          },
          {
            ic_uuid: "fca93d55-fe4f-4bd3-8b4f-d0f41443431b",
            ic_lat: -272178,
            ic_lon: -497336,
            ic_city: "Laurentino",
            ic_states: "SANTA CATARINA",
            ic_january: 5.55,
            ic_february: 5.251,
            ic_march: 4.608,
            ic_april: 3.639,
            ic_may: 2.848,
            ic_june: 2.301,
            ic_july: 2.479,
            ic_august: 3.317,
            ic_september: 3.506,
            ic_october: 4.144,
            ic_november: 5.414,
            ic_december: 5.733,
            ic_yearly: 4.066,
          },
          {
            ic_uuid: "4a684ee9-9bb6-4ea3-8cec-9deff866f310",
            ic_lat: -128983,
            ic_lon: -383213,
            ic_city: "Lauro de Freitas",
            ic_states: "BAHIA",
            ic_january: 6.264,
            ic_february: 6.128,
            ic_march: 5.865,
            ic_april: 4.647,
            ic_may: 4.064,
            ic_june: 3.75,
            ic_july: 4.006,
            ic_august: 4.589,
            ic_september: 5.424,
            ic_october: 5.788,
            ic_november: 6.071,
            ic_december: 6.387,
            ic_yearly: 5.249,
          },
          {
            ic_uuid: "9322d3ac-f6f2-45c4-a8d8-5ca0058abef1",
            ic_lat: -283864,
            ic_lon: -494039,
            ic_city: "Lauro Muller",
            ic_states: "SANTA CATARINA",
            ic_january: 5.156,
            ic_february: 4.804,
            ic_march: 4.213,
            ic_april: 3.638,
            ic_may: 2.868,
            ic_june: 2.508,
            ic_july: 2.695,
            ic_august: 3.424,
            ic_september: 3.453,
            ic_october: 4.037,
            ic_november: 5.24,
            ic_december: 5.483,
            ic_yearly: 3.96,
          },
          {
            ic_uuid: "9694bf25-6aaf-4c9e-a04c-95dcbb64509e",
            ic_lat: -127875,
            ic_lon: -46508,
            ic_city: "Lavandeira",
            ic_states: "TOCANTINS",
            ic_january: 5.986,
            ic_february: 5.892,
            ic_march: 5.545,
            ic_april: 5.458,
            ic_may: 5.224,
            ic_june: 5.008,
            ic_july: 5.348,
            ic_august: 5.833,
            ic_september: 5.99,
            ic_october: 5.823,
            ic_november: 5.542,
            ic_december: 5.923,
            ic_yearly: 5.631,
          },
          {
            ic_uuid: "a82f4821-e796-44c0-8db8-2b302895bbe2",
            ic_lat: -211679,
            ic_lon: -510399,
            ic_city: "Lavínia",
            ic_states: "SÃO PAULO",
            ic_january: 5.901,
            ic_february: 5.967,
            ic_march: 5.431,
            ic_april: 4.891,
            ic_may: 4.048,
            ic_june: 3.766,
            ic_july: 3.943,
            ic_august: 4.881,
            ic_september: 4.91,
            ic_october: 5.565,
            ic_november: 6.032,
            ic_december: 6.279,
            ic_yearly: 5.134,
          },
          {
            ic_uuid: "bf95569b-a3b6-47ba-936d-8d5949479c91",
            ic_lat: -212485,
            ic_lon: -450013,
            ic_city: "Lavras",
            ic_states: "MINAS GERAIS",
            ic_january: 5.698,
            ic_february: 5.853,
            ic_march: 5.028,
            ic_april: 4.716,
            ic_may: 4.018,
            ic_june: 3.799,
            ic_july: 4.062,
            ic_august: 4.967,
            ic_september: 5.185,
            ic_october: 5.441,
            ic_november: 5.249,
            ic_december: 5.708,
            ic_yearly: 4.977,
          },
          {
            ic_uuid: "bdf4f5f2-603a-4a2b-9d55-df6ba4d96510",
            ic_lat: -67452,
            ic_lon: -389709,
            ic_city: "Lavras da Mangabeira",
            ic_states: "CEARÁ",
            ic_january: 6.003,
            ic_february: 5.98,
            ic_march: 5.898,
            ic_april: 5.601,
            ic_may: 5.202,
            ic_june: 5.073,
            ic_july: 5.323,
            ic_august: 6.085,
            ic_september: 6.528,
            ic_october: 6.617,
            ic_november: 6.669,
            ic_december: 6.248,
            ic_yearly: 5.936,
          },
          {
            ic_uuid: "d71bc3e5-0a68-4a2a-aec6-fb69d284a726",
            ic_lat: -30806,
            ic_lon: -538963,
            ic_city: "Lavras do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.452,
            ic_february: 5.885,
            ic_march: 5.021,
            ic_april: 3.832,
            ic_may: 2.763,
            ic_june: 2.312,
            ic_july: 2.599,
            ic_august: 3.251,
            ic_september: 3.836,
            ic_october: 5.069,
            ic_november: 6.325,
            ic_december: 6.901,
            ic_yearly: 4.52,
          },
          {
            ic_uuid: "1a0ab352-1be6-4938-acb6-02111b580e78",
            ic_lat: -225705,
            ic_lon: -449027,
            ic_city: "Lavrinhas",
            ic_states: "SÃO PAULO",
            ic_january: 5.434,
            ic_february: 5.641,
            ic_march: 4.795,
            ic_april: 4.421,
            ic_may: 3.685,
            ic_june: 3.493,
            ic_july: 3.606,
            ic_august: 4.537,
            ic_september: 4.663,
            ic_october: 5.081,
            ic_november: 5.086,
            ic_december: 5.731,
            ic_yearly: 4.681,
          },
          {
            ic_uuid: "408e8ba4-3e30-46a2-bdd8-f91e0af4f49d",
            ic_lat: -197163,
            ic_lon: -450245,
            ic_city: "Leandro Ferreira",
            ic_states: "MINAS GERAIS",
            ic_january: 5.866,
            ic_february: 6.088,
            ic_march: 5.204,
            ic_april: 4.955,
            ic_may: 4.387,
            ic_june: 4.206,
            ic_july: 4.49,
            ic_august: 5.344,
            ic_september: 5.555,
            ic_october: 5.608,
            ic_november: 5.326,
            ic_december: 5.7,
            ic_yearly: 5.228,
          },
          {
            ic_uuid: "52e3d50e-9fa0-4632-9fa2-4af2e62ae4ae",
            ic_lat: -269285,
            ic_lon: -506925,
            ic_city: "Lebon Régis",
            ic_states: "SANTA CATARINA",
            ic_january: 5.501,
            ic_february: 5.214,
            ic_march: 4.629,
            ic_april: 3.809,
            ic_may: 2.956,
            ic_june: 2.649,
            ic_july: 2.892,
            ic_august: 3.773,
            ic_september: 3.896,
            ic_october: 4.575,
            ic_november: 5.646,
            ic_december: 5.86,
            ic_yearly: 4.284,
          },
          {
            ic_uuid: "953b992e-19d1-4e0e-b4ea-ea278981dbea",
            ic_lat: -221813,
            ic_lon: -473845,
            ic_city: "Leme",
            ic_states: "SÃO PAULO",
            ic_january: 5.6,
            ic_february: 5.855,
            ic_march: 5.135,
            ic_april: 4.679,
            ic_may: 3.919,
            ic_june: 3.687,
            ic_july: 3.824,
            ic_august: 4.789,
            ic_september: 4.949,
            ic_october: 5.511,
            ic_november: 5.714,
            ic_december: 6.026,
            ic_yearly: 4.974,
          },
          {
            ic_uuid: "6a83b85a-996d-4655-88af-5d7add45a023",
            ic_lat: -170814,
            ic_lon: -426919,
            ic_city: "Leme do Prado",
            ic_states: "MINAS GERAIS",
            ic_january: 6.077,
            ic_february: 6.433,
            ic_march: 5.375,
            ic_april: 4.701,
            ic_may: 4.103,
            ic_june: 3.93,
            ic_july: 4.143,
            ic_august: 4.959,
            ic_september: 5.436,
            ic_october: 5.61,
            ic_november: 5.201,
            ic_december: 5.873,
            ic_yearly: 5.154,
          },
          {
            ic_uuid: "c450fb54-892b-4aac-9262-da5228b9c4cb",
            ic_lat: -125626,
            ic_lon: -413901,
            ic_city: "Lençóis",
            ic_states: "BAHIA",
            ic_january: 5.89,
            ic_february: 5.934,
            ic_march: 5.591,
            ic_april: 4.751,
            ic_may: 4.144,
            ic_june: 3.82,
            ic_july: 3.973,
            ic_august: 4.627,
            ic_september: 5.351,
            ic_october: 5.413,
            ic_november: 5.347,
            ic_december: 5.726,
            ic_yearly: 5.048,
          },
          {
            ic_uuid: "775c9d3b-c31d-41b3-875c-c319b92a6030",
            ic_lat: -226032,
            ic_lon: -488041,
            ic_city: "Lençóis Paulista",
            ic_states: "SÃO PAULO",
            ic_january: 5.552,
            ic_february: 5.796,
            ic_march: 5.171,
            ic_april: 4.666,
            ic_may: 3.81,
            ic_june: 3.561,
            ic_july: 3.709,
            ic_august: 4.696,
            ic_september: 4.802,
            ic_october: 5.476,
            ic_november: 5.869,
            ic_december: 6.19,
            ic_yearly: 4.941,
          },
          {
            ic_uuid: "64dabf2a-774c-4cae-9933-001956f1bc43",
            ic_lat: -275086,
            ic_lon: -492793,
            ic_city: "Leoberto Leal",
            ic_states: "SANTA CATARINA",
            ic_january: 5.514,
            ic_february: 5.205,
            ic_march: 4.484,
            ic_april: 3.678,
            ic_may: 2.937,
            ic_june: 2.46,
            ic_july: 2.643,
            ic_august: 3.388,
            ic_september: 3.48,
            ic_october: 4.012,
            ic_november: 5.27,
            ic_december: 5.63,
            ic_yearly: 4.058,
          },
          {
            ic_uuid: "c1633ce9-0428-4bb8-be32-e6cb33ce485a",
            ic_lat: -215301,
            ic_lon: -426425,
            ic_city: "Leopoldina",
            ic_states: "MINAS GERAIS",
            ic_january: 5.929,
            ic_february: 6.186,
            ic_march: 5.192,
            ic_april: 4.43,
            ic_may: 3.735,
            ic_june: 3.504,
            ic_july: 3.653,
            ic_august: 4.423,
            ic_september: 4.756,
            ic_october: 5.108,
            ic_november: 4.99,
            ic_december: 5.854,
            ic_yearly: 4.813,
          },
          {
            ic_uuid: "d1624fa1-9d8b-41d9-a86d-755ec2d06ab8",
            ic_lat: -166195,
            ic_lon: -487432,
            ic_city: "Leopoldo de Bulhões",
            ic_states: "GOIÁS",
            ic_january: 5.495,
            ic_february: 5.702,
            ic_march: 5.134,
            ic_april: 5.081,
            ic_may: 4.787,
            ic_june: 4.609,
            ic_july: 4.827,
            ic_august: 5.726,
            ic_september: 5.537,
            ic_october: 5.572,
            ic_november: 5.171,
            ic_december: 5.427,
            ic_yearly: 5.256,
          },
          {
            ic_uuid: "7d003c95-e0f8-47f8-897a-36ed3ba3e9f8",
            ic_lat: -2308,
            ic_lon: -507509,
            ic_city: "Leópolis",
            ic_states: "PARANÁ",
            ic_january: 6.006,
            ic_february: 5.961,
            ic_march: 5.31,
            ic_april: 4.63,
            ic_may: 3.742,
            ic_june: 3.425,
            ic_july: 3.627,
            ic_august: 4.586,
            ic_september: 4.731,
            ic_october: 5.428,
            ic_november: 6.049,
            ic_december: 6.403,
            ic_yearly: 4.991,
          },
          {
            ic_uuid: "62d01bc2-c925-4530-98d8-58a1307ba45c",
            ic_lat: -276014,
            ic_lon: -530758,
            ic_city: "Liberato Salzano",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.338,
            ic_february: 5.901,
            ic_march: 5.124,
            ic_april: 4.146,
            ic_may: 3.114,
            ic_june: 2.562,
            ic_july: 2.893,
            ic_august: 3.697,
            ic_september: 3.974,
            ic_october: 5.17,
            ic_november: 6.273,
            ic_december: 6.667,
            ic_yearly: 4.655,
          },
          {
            ic_uuid: "55c1f066-3d68-47df-8e2f-05edcd8901a6",
            ic_lat: -22028,
            ic_lon: -443211,
            ic_city: "Liberdade",
            ic_states: "MINAS GERAIS",
            ic_january: 5.383,
            ic_february: 5.745,
            ic_march: 4.861,
            ic_april: 4.437,
            ic_may: 3.732,
            ic_june: 3.551,
            ic_july: 3.691,
            ic_august: 4.625,
            ic_september: 4.806,
            ic_october: 5.033,
            ic_november: 5.004,
            ic_december: 5.464,
            ic_yearly: 4.694,
          },
          {
            ic_uuid: "6566e5af-1dcc-41d8-9b3f-da54d64a8b16",
            ic_lat: -14682,
            ic_lon: -425053,
            ic_city: "Licínio de Almeida",
            ic_states: "BAHIA",
            ic_january: 6.324,
            ic_february: 6.447,
            ic_march: 5.777,
            ic_april: 5.232,
            ic_may: 4.631,
            ic_june: 4.394,
            ic_july: 4.646,
            ic_august: 5.362,
            ic_september: 5.936,
            ic_october: 6.219,
            ic_november: 5.745,
            ic_december: 6.357,
            ic_yearly: 5.589,
          },
          {
            ic_uuid: "14254e1a-8e53-4bca-901d-e86b15cab16e",
            ic_lat: -241104,
            ic_lon: -516511,
            ic_city: "Lidianópolis",
            ic_states: "PARANÁ",
            ic_january: 6.056,
            ic_february: 5.782,
            ic_march: 5.28,
            ic_april: 4.577,
            ic_may: 3.552,
            ic_june: 3.208,
            ic_july: 3.412,
            ic_august: 4.389,
            ic_september: 4.573,
            ic_october: 5.3,
            ic_november: 6.125,
            ic_december: 6.433,
            ic_yearly: 4.891,
          },
          {
            ic_uuid: "966b6082-d71f-4b63-84f9-859f1d5c57bd",
            ic_lat: -45188,
            ic_lon: -44465,
            ic_city: "Lima Campos",
            ic_states: "MARANHÃO",
            ic_january: 4.71,
            ic_february: 4.819,
            ic_march: 4.895,
            ic_april: 4.871,
            ic_may: 4.846,
            ic_june: 5.077,
            ic_july: 5.316,
            ic_august: 5.898,
            ic_september: 6.252,
            ic_october: 5.849,
            ic_november: 5.435,
            ic_december: 5.047,
            ic_yearly: 5.251,
          },
          {
            ic_uuid: "3f9cb1d9-32dd-425b-bbee-82fe59e8fa87",
            ic_lat: -218391,
            ic_lon: -437938,
            ic_city: "Lima Duarte",
            ic_states: "MINAS GERAIS",
            ic_january: 5.353,
            ic_february: 5.691,
            ic_march: 4.76,
            ic_april: 4.178,
            ic_may: 3.584,
            ic_june: 3.372,
            ic_july: 3.551,
            ic_august: 4.392,
            ic_september: 4.572,
            ic_october: 4.794,
            ic_november: 4.674,
            ic_december: 5.399,
            ic_yearly: 4.527,
          },
          {
            ic_uuid: "f6ad2180-3e8a-4c4e-b46e-c4b5cb36a00e",
            ic_lat: -225665,
            ic_lon: -473974,
            ic_city: "Limeira",
            ic_states: "SÃO PAULO",
            ic_january: 5.567,
            ic_february: 5.744,
            ic_march: 5.111,
            ic_april: 4.598,
            ic_may: 3.824,
            ic_june: 3.577,
            ic_july: 3.725,
            ic_august: 4.696,
            ic_september: 4.845,
            ic_october: 5.457,
            ic_november: 5.657,
            ic_december: 6.04,
            ic_yearly: 4.903,
          },
          {
            ic_uuid: "7a9fc40f-2d4d-4d3c-a9fe-ad9b9edc45db",
            ic_lat: -195517,
            ic_lon: -505819,
            ic_city: "Limeira do Oeste",
            ic_states: "MINAS GERAIS",
            ic_january: 5.805,
            ic_february: 5.931,
            ic_march: 5.338,
            ic_april: 4.948,
            ic_may: 4.35,
            ic_june: 4.088,
            ic_july: 4.328,
            ic_august: 5.173,
            ic_september: 5.159,
            ic_october: 5.669,
            ic_november: 5.876,
            ic_december: 6.163,
            ic_yearly: 5.236,
          },
          {
            ic_uuid: "2bbd62ec-fad0-431e-bcc9-6e095625556f",
            ic_lat: -78798,
            ic_lon: -354461,
            ic_city: "Limoeiro",
            ic_states: "PERNAMBUCO",
            ic_january: 5.669,
            ic_february: 5.59,
            ic_march: 5.647,
            ic_april: 5.111,
            ic_may: 4.376,
            ic_june: 4.068,
            ic_july: 4.168,
            ic_august: 4.809,
            ic_september: 5.43,
            ic_october: 5.654,
            ic_november: 5.937,
            ic_december: 5.839,
            ic_yearly: 5.191,
          },
          {
            ic_uuid: "00b332cb-dc7a-4a54-bde7-9e2a24c34b56",
            ic_lat: -97407,
            ic_lon: -365034,
            ic_city: "Limoeiro de Anadia",
            ic_states: "ALAGOAS",
            ic_january: 6.002,
            ic_february: 5.809,
            ic_march: 5.825,
            ic_april: 5.09,
            ic_may: 4.243,
            ic_june: 3.941,
            ic_july: 4.04,
            ic_august: 4.661,
            ic_september: 5.451,
            ic_october: 5.762,
            ic_november: 6.358,
            ic_december: 6.302,
            ic_yearly: 5.29,
          },
          {
            ic_uuid: "6e46dea8-e026-444d-bea4-6cb4357c55ec",
            ic_lat: -18958,
            ic_lon: -493821,
            ic_city: "Limoeiro do Ajuru",
            ic_states: "PARÁ",
            ic_january: 4.788,
            ic_february: 4.781,
            ic_march: 4.872,
            ic_april: 4.96,
            ic_may: 5.013,
            ic_june: 5.152,
            ic_july: 5.208,
            ic_august: 5.449,
            ic_september: 5.588,
            ic_october: 5.526,
            ic_november: 5.375,
            ic_december: 5.03,
            ic_yearly: 5.146,
          },
          {
            ic_uuid: "04cada1e-a47a-4601-b924-16ecd131575b",
            ic_lat: -51443,
            ic_lon: -38085,
            ic_city: "Limoeiro do Norte",
            ic_states: "CEARÁ",
            ic_january: 5.771,
            ic_february: 5.812,
            ic_march: 5.867,
            ic_april: 5.417,
            ic_may: 5.257,
            ic_june: 4.98,
            ic_july: 5.306,
            ic_august: 6.022,
            ic_september: 6.316,
            ic_october: 6.482,
            ic_november: 6.417,
            ic_december: 5.91,
            ic_yearly: 5.796,
          },
          {
            ic_uuid: "ecbe8371-6366-4e8c-947e-56bc9d455dc4",
            ic_lat: -252601,
            ic_lon: -535737,
            ic_city: "Lindoeste",
            ic_states: "PARANÁ",
            ic_january: 6.131,
            ic_february: 5.628,
            ic_march: 5.129,
            ic_april: 4.228,
            ic_may: 3.292,
            ic_june: 2.88,
            ic_july: 3.115,
            ic_august: 4.143,
            ic_september: 4.305,
            ic_october: 5.202,
            ic_november: 6.0,
            ic_december: 6.297,
            ic_yearly: 4.696,
          },
          {
            ic_uuid: "000505aa-3424-4197-9094-8df030f3ca52",
            ic_lat: -22523,
            ic_lon: -466504,
            ic_city: "Lindóia",
            ic_states: "SÃO PAULO",
            ic_january: 5.53,
            ic_february: 5.716,
            ic_march: 5.105,
            ic_april: 4.697,
            ic_may: 3.926,
            ic_june: 3.634,
            ic_july: 3.838,
            ic_august: 4.691,
            ic_september: 4.933,
            ic_october: 5.486,
            ic_november: 5.662,
            ic_december: 5.952,
            ic_yearly: 4.931,
          },
          {
            ic_uuid: "6ebc9029-9c87-4d64-8e2b-b33c89ac40f6",
            ic_lat: -270512,
            ic_lon: -520833,
            ic_city: "Lindóia do Sul",
            ic_states: "SANTA CATARINA",
            ic_january: 5.95,
            ic_february: 5.519,
            ic_march: 4.985,
            ic_april: 4.055,
            ic_may: 3.096,
            ic_june: 2.668,
            ic_july: 2.941,
            ic_august: 3.801,
            ic_september: 4.015,
            ic_october: 4.985,
            ic_november: 6.064,
            ic_december: 6.267,
            ic_yearly: 4.529,
          },
          {
            ic_uuid: "7081b22d-5a31-4eae-b369-eb07d882466d",
            ic_lat: -29595,
            ic_lon: -512088,
            ic_city: "Lindolfo Collor",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.21,
            ic_february: 5.762,
            ic_march: 4.841,
            ic_april: 3.788,
            ic_may: 2.824,
            ic_june: 2.346,
            ic_july: 2.538,
            ic_august: 3.227,
            ic_september: 3.548,
            ic_october: 4.621,
            ic_november: 6.105,
            ic_december: 6.568,
            ic_yearly: 4.365,
          },
          {
            ic_uuid: "67d5756e-6d5b-49e8-9cd8-1e015b8fa84d",
            ic_lat: -294684,
            ic_lon: -512007,
            ic_city: "Linha Nova",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.19,
            ic_february: 5.709,
            ic_march: 4.782,
            ic_april: 3.79,
            ic_may: 2.806,
            ic_june: 2.346,
            ic_july: 2.564,
            ic_august: 3.241,
            ic_september: 3.543,
            ic_october: 4.591,
            ic_november: 6.088,
            ic_december: 6.488,
            ic_yearly: 4.345,
          },
          {
            ic_uuid: "bd88d78b-8a79-44b4-9579-6ec3d4a91fe7",
            ic_lat: -193951,
            ic_lon: -400646,
            ic_city: "Linhares",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.06,
            ic_february: 6.138,
            ic_march: 5.277,
            ic_april: 4.488,
            ic_may: 3.927,
            ic_june: 3.603,
            ic_july: 3.683,
            ic_august: 4.297,
            ic_september: 4.824,
            ic_october: 4.983,
            ic_november: 5.036,
            ic_december: 5.906,
            ic_yearly: 4.852,
          },
          {
            ic_uuid: "8fdd4a04-822b-400c-b3e6-ac96fe344998",
            ic_lat: -216723,
            ic_lon: -49753,
            ic_city: "Lins",
            ic_states: "SÃO PAULO",
            ic_january: 5.849,
            ic_february: 5.954,
            ic_march: 5.306,
            ic_april: 4.809,
            ic_may: 3.938,
            ic_june: 3.643,
            ic_july: 3.903,
            ic_august: 4.8,
            ic_september: 4.934,
            ic_october: 5.625,
            ic_november: 5.948,
            ic_december: 6.225,
            ic_yearly: 5.078,
          },
          {
            ic_uuid: "a6196d04-6b9a-428e-868a-20d5ab9a96d5",
            ic_lat: -73758,
            ic_lon: -369463,
            ic_city: "Livramento",
            ic_states: "PARAÍBA",
            ic_january: 6.048,
            ic_february: 6.019,
            ic_march: 6.041,
            ic_april: 5.625,
            ic_may: 4.912,
            ic_june: 4.478,
            ic_july: 4.699,
            ic_august: 5.418,
            ic_september: 6.181,
            ic_october: 6.393,
            ic_november: 6.507,
            ic_december: 6.212,
            ic_yearly: 5.711,
          },
          {
            ic_uuid: "74051709-904e-46a1-9689-a6d87fecadec",
            ic_lat: -136393,
            ic_lon: -418395,
            ic_city: "Livramento de Nossa Senhora",
            ic_states: "BAHIA",
            ic_january: 6.29,
            ic_february: 6.272,
            ic_march: 5.755,
            ic_april: 4.972,
            ic_may: 4.425,
            ic_june: 4.148,
            ic_july: 4.443,
            ic_august: 5.19,
            ic_september: 6.018,
            ic_october: 6.221,
            ic_november: 5.779,
            ic_december: 6.391,
            ic_yearly: 5.492,
          },
          {
            ic_uuid: "09fc1d25-4bbc-411a-9e36-e9c6b254a682",
            ic_lat: -95954,
            ic_lon: -466731,
            ic_city: "Lizarda",
            ic_states: "TOCANTINS",
            ic_january: 5.362,
            ic_february: 5.135,
            ic_march: 4.961,
            ic_april: 5.094,
            ic_may: 5.283,
            ic_june: 5.106,
            ic_july: 5.293,
            ic_august: 5.981,
            ic_september: 6.263,
            ic_october: 5.706,
            ic_november: 5.295,
            ic_december: 5.28,
            ic_yearly: 5.397,
          },
          {
            ic_uuid: "a5de693d-df6b-480e-ae0e-b90f94d5ce0e",
            ic_lat: -229237,
            ic_lon: -531367,
            ic_city: "Loanda",
            ic_states: "PARANÁ",
            ic_january: 5.965,
            ic_february: 5.948,
            ic_march: 5.418,
            ic_april: 4.647,
            ic_may: 3.696,
            ic_june: 3.361,
            ic_july: 3.546,
            ic_august: 4.478,
            ic_september: 4.745,
            ic_october: 5.449,
            ic_november: 6.171,
            ic_december: 6.505,
            ic_yearly: 4.994,
          },
          {
            ic_uuid: "d84ff3d0-9ba0-41e0-a7b4-5325d92f2b0d",
            ic_lat: -230062,
            ic_lon: -519529,
            ic_city: "Lobato",
            ic_states: "PARANÁ",
            ic_january: 5.972,
            ic_february: 5.936,
            ic_march: 5.402,
            ic_april: 4.668,
            ic_may: 3.722,
            ic_june: 3.401,
            ic_july: 3.589,
            ic_august: 4.538,
            ic_september: 4.743,
            ic_october: 5.466,
            ic_november: 6.164,
            ic_december: 6.405,
            ic_yearly: 5.001,
          },
          {
            ic_uuid: "39760c54-5608-4063-b629-ebc57d875436",
            ic_lat: -66165,
            ic_lon: -354407,
            ic_city: "Logradouro",
            ic_states: "PARAÍBA",
            ic_january: 5.752,
            ic_february: 5.783,
            ic_march: 5.879,
            ic_april: 5.351,
            ic_may: 4.851,
            ic_june: 4.413,
            ic_july: 4.581,
            ic_august: 5.269,
            ic_september: 5.715,
            ic_october: 5.929,
            ic_november: 6.125,
            ic_december: 5.822,
            ic_yearly: 5.456,
          },
          {
            ic_uuid: "d9816c9e-5899-4aad-a985-4535c825a483",
            ic_lat: -233044,
            ic_lon: -511695,
            ic_city: "Londrina",
            ic_states: "PARANÁ",
            ic_january: 5.84,
            ic_february: 5.714,
            ic_march: 5.257,
            ic_april: 4.625,
            ic_may: 3.655,
            ic_june: 3.371,
            ic_july: 3.584,
            ic_august: 4.535,
            ic_september: 4.698,
            ic_october: 5.263,
            ic_november: 6.003,
            ic_december: 6.261,
            ic_yearly: 4.9,
          },
          {
            ic_uuid: "9bfa969a-45ff-4362-85af-74b9fec7709f",
            ic_lat: -159018,
            ic_lon: -443064,
            ic_city: "Lontra",
            ic_states: "MINAS GERAIS",
            ic_january: 6.296,
            ic_february: 6.541,
            ic_march: 5.676,
            ic_april: 5.529,
            ic_may: 4.976,
            ic_june: 4.699,
            ic_july: 5.059,
            ic_august: 5.81,
            ic_september: 6.055,
            ic_october: 6.085,
            ic_november: 5.505,
            ic_december: 6.17,
            ic_yearly: 5.7,
          },
          {
            ic_uuid: "5ecd1bf5-3dd4-4ead-bce6-0359385eead3",
            ic_lat: -271688,
            ic_lon: -495355,
            ic_city: "Lontras",
            ic_states: "SANTA CATARINA",
            ic_january: 5.602,
            ic_february: 5.268,
            ic_march: 4.622,
            ic_april: 3.674,
            ic_may: 2.894,
            ic_june: 2.368,
            ic_july: 2.558,
            ic_august: 3.338,
            ic_september: 3.497,
            ic_october: 4.095,
            ic_november: 5.36,
            ic_december: 5.677,
            ic_yearly: 4.079,
          },
          {
            ic_uuid: "fe3159f9-3ae5-4390-bba4-3152ffa0593f",
            ic_lat: -227339,
            ic_lon: -451201,
            ic_city: "Lorena",
            ic_states: "SÃO PAULO",
            ic_january: 5.338,
            ic_february: 5.611,
            ic_march: 4.768,
            ic_april: 4.338,
            ic_may: 3.628,
            ic_june: 3.409,
            ic_july: 3.544,
            ic_august: 4.494,
            ic_september: 4.573,
            ic_october: 4.981,
            ic_november: 4.986,
            ic_december: 5.587,
            ic_yearly: 4.605,
          },
          {
            ic_uuid: "f1e1dd3d-b715-4842-9353-128b235b9e48",
            ic_lat: -70864,
            ic_lon: -451389,
            ic_city: "Loreto",
            ic_states: "MARANHÃO",
            ic_january: 4.873,
            ic_february: 5.154,
            ic_march: 5.095,
            ic_april: 5.156,
            ic_may: 5.227,
            ic_june: 5.315,
            ic_july: 5.597,
            ic_august: 6.06,
            ic_september: 6.539,
            ic_october: 5.895,
            ic_november: 5.339,
            ic_december: 5.142,
            ic_yearly: 5.449,
          },
          {
            ic_uuid: "39993efc-f6ff-4e75-83cc-07a9b8442cff",
            ic_lat: -209665,
            ic_lon: -502267,
            ic_city: "Lourdes",
            ic_states: "SÃO PAULO",
            ic_january: 5.85,
            ic_february: 5.901,
            ic_march: 5.309,
            ic_april: 4.906,
            ic_may: 4.082,
            ic_june: 3.754,
            ic_july: 4.019,
            ic_august: 4.912,
            ic_september: 4.94,
            ic_october: 5.63,
            ic_november: 6.018,
            ic_december: 6.299,
            ic_yearly: 5.135,
          },
          {
            ic_uuid: "864fd71c-7aa5-437d-8e66-588abd8b61ae",
            ic_lat: -23086,
            ic_lon: -469488,
            ic_city: "Louveira",
            ic_states: "SÃO PAULO",
            ic_january: 5.541,
            ic_february: 5.781,
            ic_march: 5.055,
            ic_april: 4.563,
            ic_may: 3.755,
            ic_june: 3.499,
            ic_july: 3.652,
            ic_august: 4.602,
            ic_september: 4.757,
            ic_october: 5.417,
            ic_november: 5.699,
            ic_december: 6.1,
            ic_yearly: 4.868,
          },
          {
            ic_uuid: "54466d07-380f-4e5a-8edc-a54e86eca852",
            ic_lat: -130717,
            ic_lon: -559135,
            ic_city: "Lucas do Rio Verde",
            ic_states: "MATO GROSSO",
            ic_january: 5.182,
            ic_february: 5.002,
            ic_march: 4.949,
            ic_april: 4.961,
            ic_may: 4.67,
            ic_june: 4.497,
            ic_july: 4.677,
            ic_august: 5.176,
            ic_september: 5.13,
            ic_october: 5.275,
            ic_november: 5.259,
            ic_december: 5.323,
            ic_yearly: 5.009,
          },
          {
            ic_uuid: "6636adc0-208e-42e6-896e-ff32cd0ec223",
            ic_lat: -217187,
            ic_lon: -510219,
            ic_city: "Lucélia",
            ic_states: "SÃO PAULO",
            ic_january: 5.954,
            ic_february: 5.944,
            ic_march: 5.48,
            ic_april: 4.872,
            ic_may: 4.004,
            ic_june: 3.673,
            ic_july: 3.88,
            ic_august: 4.829,
            ic_september: 4.891,
            ic_october: 5.567,
            ic_november: 6.06,
            ic_december: 6.352,
            ic_yearly: 5.125,
          },
          {
            ic_uuid: "f302f64f-7107-47f0-b11a-672a83b25250",
            ic_lat: -69002,
            ic_lon: -348764,
            ic_city: "Lucena",
            ic_states: "PARAÍBA",
            ic_january: 6.034,
            ic_february: 6.181,
            ic_march: 6.19,
            ic_april: 5.562,
            ic_may: 5.007,
            ic_june: 4.528,
            ic_july: 4.65,
            ic_august: 5.534,
            ic_september: 5.989,
            ic_october: 6.291,
            ic_november: 6.467,
            ic_december: 6.331,
            ic_yearly: 5.73,
          },
          {
            ic_uuid: "2d6a52d5-c8bf-4492-8de6-6bf46b78c3d0",
            ic_lat: -224299,
            ic_lon: -495224,
            ic_city: "Lucianópolis",
            ic_states: "SÃO PAULO",
            ic_january: 5.647,
            ic_february: 5.778,
            ic_march: 5.124,
            ic_april: 4.68,
            ic_may: 3.821,
            ic_june: 3.554,
            ic_july: 3.766,
            ic_august: 4.749,
            ic_september: 4.82,
            ic_october: 5.515,
            ic_november: 5.851,
            ic_december: 6.185,
            ic_yearly: 4.957,
          },
          {
            ic_uuid: "c9b14509-fe48-4d89-8b35-00b1afbc758d",
            ic_lat: -112224,
            ic_lon: -50668,
            ic_city: "Luciara",
            ic_states: "MATO GROSSO",
            ic_january: 5.088,
            ic_february: 5.275,
            ic_march: 4.981,
            ic_april: 4.842,
            ic_may: 4.94,
            ic_june: 5.011,
            ic_july: 5.213,
            ic_august: 5.694,
            ic_september: 5.458,
            ic_october: 5.279,
            ic_november: 5.129,
            ic_december: 5.247,
            ic_yearly: 5.18,
          },
          {
            ic_uuid: "ba7676ea-ee32-477a-858e-72118cf6ca0e",
            ic_lat: -61057,
            ic_lon: -378137,
            ic_city: "Lucrécia",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.114,
            ic_february: 6.014,
            ic_march: 6.047,
            ic_april: 5.809,
            ic_may: 5.332,
            ic_june: 5.024,
            ic_july: 5.383,
            ic_august: 6.08,
            ic_september: 6.509,
            ic_october: 6.656,
            ic_november: 6.669,
            ic_december: 6.153,
            ic_yearly: 5.982,
          },
          {
            ic_uuid: "b24fc19c-e0b0-4f29-8c41-59e7100e6732",
            ic_lat: -215511,
            ic_lon: -477012,
            ic_city: "Luís Antônio",
            ic_states: "SÃO PAULO",
            ic_january: 5.618,
            ic_february: 5.903,
            ic_march: 5.101,
            ic_april: 4.787,
            ic_may: 4.036,
            ic_june: 3.799,
            ic_july: 3.975,
            ic_august: 4.881,
            ic_september: 4.921,
            ic_october: 5.48,
            ic_november: 5.652,
            ic_december: 6.002,
            ic_yearly: 5.013,
          },
          {
            ic_uuid: "a7b6e0c0-01a1-4aa7-939f-9c09463325b4",
            ic_lat: -28848,
            ic_lon: -416645,
            ic_city: "Luís Correia",
            ic_states: "PIAUÍ",
            ic_january: 5.601,
            ic_february: 5.505,
            ic_march: 5.325,
            ic_april: 4.998,
            ic_may: 5.159,
            ic_june: 5.283,
            ic_july: 5.381,
            ic_august: 5.65,
            ic_september: 5.431,
            ic_october: 5.593,
            ic_november: 5.976,
            ic_december: 5.905,
            ic_yearly: 5.484,
          },
          {
            ic_uuid: "7cd8962a-8c9e-456d-9397-20d015ec95cf",
            ic_lat: -13304,
            ic_lon: -458984,
            ic_city: "Luís Domingues",
            ic_states: "MARANHÃO",
            ic_january: 4.786,
            ic_february: 4.516,
            ic_march: 4.361,
            ic_april: 4.295,
            ic_may: 4.538,
            ic_june: 4.771,
            ic_july: 4.939,
            ic_august: 5.3,
            ic_september: 5.693,
            ic_october: 5.464,
            ic_november: 5.343,
            ic_december: 5.047,
            ic_yearly: 4.921,
          },
          {
            ic_uuid: "af017a4d-5152-40b4-987d-efb26a7aa52e",
            ic_lat: -12087,
            ic_lon: -457966,
            ic_city: "Luís Eduardo Magalhães",
            ic_states: "BAHIA",
            ic_january: 6.054,
            ic_february: 5.767,
            ic_march: 5.346,
            ic_april: 5.292,
            ic_may: 5.199,
            ic_june: 5.073,
            ic_july: 5.381,
            ic_august: 5.938,
            ic_september: 6.216,
            ic_october: 6.005,
            ic_november: 5.576,
            ic_december: 5.823,
            ic_yearly: 5.639,
          },
          {
            ic_uuid: "6730123e-9db9-433d-adc4-059ee1595b45",
            ic_lat: -64063,
            ic_lon: -383902,
            ic_city: "Luís Gomes",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.01,
            ic_february: 5.982,
            ic_march: 6.013,
            ic_april: 5.641,
            ic_may: 5.21,
            ic_june: 4.95,
            ic_july: 5.324,
            ic_august: 6.069,
            ic_september: 6.476,
            ic_october: 6.582,
            ic_november: 6.7,
            ic_december: 6.201,
            ic_yearly: 5.93,
          },
          {
            ic_uuid: "540edbef-0334-4703-a217-d7ca4ac1729b",
            ic_lat: -204472,
            ic_lon: -42098,
            ic_city: "Luisburgo",
            ic_states: "MINAS GERAIS",
            ic_january: 5.474,
            ic_february: 5.783,
            ic_march: 4.818,
            ic_april: 4.388,
            ic_may: 3.76,
            ic_june: 3.576,
            ic_july: 3.724,
            ic_august: 4.449,
            ic_september: 4.761,
            ic_october: 4.863,
            ic_november: 4.552,
            ic_december: 5.249,
            ic_yearly: 4.616,
          },
          {
            ic_uuid: "e8104ca8-5d60-4b24-8062-3e8e778bee39",
            ic_lat: -161171,
            ic_lon: -445857,
            ic_city: "Luislândia",
            ic_states: "MINAS GERAIS",
            ic_january: 6.328,
            ic_february: 6.553,
            ic_march: 5.635,
            ic_april: 5.522,
            ic_may: 4.984,
            ic_june: 4.7,
            ic_july: 5.022,
            ic_august: 5.907,
            ic_september: 5.988,
            ic_october: 5.993,
            ic_november: 5.491,
            ic_december: 6.116,
            ic_yearly: 5.687,
          },
          {
            ic_uuid: "7074def2-9257-4591-a294-26b0bb77b33f",
            ic_lat: -267156,
            ic_lon: -489327,
            ic_city: "Luiz Alves",
            ic_states: "SANTA CATARINA",
            ic_january: 5.334,
            ic_february: 5.131,
            ic_march: 4.47,
            ic_april: 3.618,
            ic_may: 2.996,
            ic_june: 2.48,
            ic_july: 2.593,
            ic_august: 3.213,
            ic_september: 3.298,
            ic_october: 3.759,
            ic_november: 4.898,
            ic_december: 5.337,
            ic_yearly: 3.927,
          },
          {
            ic_uuid: "05ef7354-5913-4214-8567-cfd0f723a958",
            ic_lat: -242857,
            ic_lon: -522695,
            ic_city: "Luiziana",
            ic_states: "PARANÁ",
            ic_january: 6.061,
            ic_february: 5.677,
            ic_march: 5.157,
            ic_april: 4.457,
            ic_may: 3.499,
            ic_june: 3.097,
            ic_july: 3.356,
            ic_august: 4.309,
            ic_september: 4.511,
            ic_october: 5.208,
            ic_november: 6.083,
            ic_december: 6.403,
            ic_yearly: 4.818,
          },
          {
            ic_uuid: "80fc8079-d895-4e0b-8377-b5f3ce2e9ca1",
            ic_lat: -21676,
            ic_lon: -503269,
            ic_city: "Luiziânia",
            ic_states: "SÃO PAULO",
            ic_january: 5.935,
            ic_february: 5.946,
            ic_march: 5.338,
            ic_april: 4.817,
            ic_may: 3.962,
            ic_june: 3.655,
            ic_july: 3.883,
            ic_august: 4.788,
            ic_september: 4.859,
            ic_october: 5.571,
            ic_november: 6.018,
            ic_december: 6.344,
            ic_yearly: 5.093,
          },
          {
            ic_uuid: "ad45f55d-8f8c-4997-9855-099dfa234bcd",
            ic_lat: -21515,
            ic_lon: -449038,
            ic_city: "Luminárias",
            ic_states: "MINAS GERAIS",
            ic_january: 5.559,
            ic_february: 5.752,
            ic_march: 4.899,
            ic_april: 4.564,
            ic_may: 3.92,
            ic_june: 3.714,
            ic_july: 3.979,
            ic_august: 4.861,
            ic_september: 5.067,
            ic_october: 5.373,
            ic_november: 5.253,
            ic_december: 5.648,
            ic_yearly: 4.882,
          },
          {
            ic_uuid: "fcdc4a1c-e7e2-47df-a4c0-2c66db19dd70",
            ic_lat: -240825,
            ic_lon: -517372,
            ic_city: "Lunardelli",
            ic_states: "PARANÁ",
            ic_january: 6.091,
            ic_february: 5.799,
            ic_march: 5.306,
            ic_april: 4.585,
            ic_may: 3.563,
            ic_june: 3.19,
            ic_july: 3.414,
            ic_august: 4.39,
            ic_september: 4.578,
            ic_october: 5.269,
            ic_november: 6.096,
            ic_december: 6.413,
            ic_yearly: 4.891,
          },
          {
            ic_uuid: "5711287a-489d-4d01-9d72-80bd8ae04918",
            ic_lat: -224151,
            ic_lon: -498184,
            ic_city: "Lupércio",
            ic_states: "SÃO PAULO",
            ic_january: 5.65,
            ic_february: 5.813,
            ic_march: 5.236,
            ic_april: 4.753,
            ic_may: 3.844,
            ic_june: 3.538,
            ic_july: 3.775,
            ic_august: 4.767,
            ic_september: 4.824,
            ic_october: 5.493,
            ic_november: 5.902,
            ic_december: 6.25,
            ic_yearly: 4.987,
          },
          {
            ic_uuid: "b8ab3011-d7da-4d2f-8984-e832ccee842a",
            ic_lat: -227555,
            ic_lon: -516605,
            ic_city: "Lupionópolis",
            ic_states: "PARANÁ",
            ic_january: 5.981,
            ic_february: 6.013,
            ic_march: 5.497,
            ic_april: 4.745,
            ic_may: 3.758,
            ic_june: 3.448,
            ic_july: 3.674,
            ic_august: 4.596,
            ic_september: 4.789,
            ic_october: 5.52,
            ic_november: 6.148,
            ic_december: 6.475,
            ic_yearly: 5.053,
          },
          {
            ic_uuid: "15df8aed-3aab-4ddd-adf3-bdb27656311e",
            ic_lat: -223388,
            ic_lon: -503944,
            ic_city: "Lutécia",
            ic_states: "SÃO PAULO",
            ic_january: 5.812,
            ic_february: 5.87,
            ic_march: 5.307,
            ic_april: 4.763,
            ic_may: 3.862,
            ic_june: 3.566,
            ic_july: 3.79,
            ic_august: 4.747,
            ic_september: 4.797,
            ic_october: 5.49,
            ic_november: 5.997,
            ic_december: 6.301,
            ic_yearly: 5.025,
          },
          {
            ic_uuid: "b66df32a-06b2-4d28-a5bd-88f7d448997f",
            ic_lat: -197916,
            ic_lon: -456798,
            ic_city: "Luz",
            ic_states: "MINAS GERAIS",
            ic_january: 5.851,
            ic_february: 6.101,
            ic_march: 5.109,
            ic_april: 4.982,
            ic_may: 4.447,
            ic_june: 4.206,
            ic_july: 4.493,
            ic_august: 5.404,
            ic_september: 5.527,
            ic_october: 5.61,
            ic_november: 5.302,
            ic_december: 5.648,
            ic_yearly: 5.223,
          },
          {
            ic_uuid: "1eec0001-0136-4f61-9806-9a318e9f9a7c",
            ic_lat: -271308,
            ic_lon: -514687,
            ic_city: "Luzerna",
            ic_states: "SANTA CATARINA",
            ic_january: 5.826,
            ic_february: 5.453,
            ic_march: 4.963,
            ic_april: 4.032,
            ic_may: 3.065,
            ic_june: 2.68,
            ic_july: 2.899,
            ic_august: 3.777,
            ic_september: 4.026,
            ic_october: 4.899,
            ic_november: 6.042,
            ic_december: 6.2,
            ic_yearly: 4.488,
          },
          {
            ic_uuid: "116a632e-1615-46ef-9573-75fa0c1b2587",
            ic_lat: -162535,
            ic_lon: -479504,
            ic_city: "Luziânia",
            ic_states: "GOIÁS",
            ic_january: 5.635,
            ic_february: 5.817,
            ic_march: 5.195,
            ic_april: 5.129,
            ic_may: 4.813,
            ic_june: 4.682,
            ic_july: 4.945,
            ic_august: 5.771,
            ic_september: 5.635,
            ic_october: 5.634,
            ic_november: 5.221,
            ic_december: 5.579,
            ic_yearly: 5.338,
          },
          {
            ic_uuid: "30e6a28f-cde7-4ffe-995b-6af4e5f0c597",
            ic_lat: -34617,
            ic_lon: -423721,
            ic_city: "Luzilândia",
            ic_states: "PIAUÍ",
            ic_january: 5.251,
            ic_february: 5.406,
            ic_march: 5.313,
            ic_april: 5.1,
            ic_may: 5.203,
            ic_june: 5.278,
            ic_july: 5.514,
            ic_august: 5.984,
            ic_september: 6.308,
            ic_october: 6.335,
            ic_november: 6.096,
            ic_december: 5.549,
            ic_yearly: 5.612,
          },
          {
            ic_uuid: "fd1e030a-b213-461e-b03a-054188c88baa",
            ic_lat: -61921,
            ic_lon: -478562,
            ic_city: "Luzinópolis",
            ic_states: "TOCANTINS",
            ic_january: 4.603,
            ic_february: 4.783,
            ic_march: 4.739,
            ic_april: 4.753,
            ic_may: 4.816,
            ic_june: 5.12,
            ic_july: 5.358,
            ic_august: 5.892,
            ic_september: 5.688,
            ic_october: 5.093,
            ic_november: 4.696,
            ic_december: 4.56,
            ic_yearly: 5.008,
          },
          {
            ic_uuid: "76327443-1809-4c80-8960-c24f819c53d6",
            ic_lat: -223773,
            ic_lon: -417852,
            ic_city: "Macaé",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.335,
            ic_february: 6.576,
            ic_march: 5.244,
            ic_april: 4.641,
            ic_may: 3.799,
            ic_june: 3.492,
            ic_july: 3.547,
            ic_august: 4.387,
            ic_september: 4.604,
            ic_october: 5.134,
            ic_november: 5.169,
            ic_december: 6.064,
            ic_yearly: 4.916,
          },
          {
            ic_uuid: "e603e1c4-57a2-47a1-9717-17fe819e7958",
            ic_lat: -58572,
            ic_lon: -353535,
            ic_city: "Macaíba",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.927,
            ic_february: 6.041,
            ic_march: 6.082,
            ic_april: 5.526,
            ic_may: 5.064,
            ic_june: 4.602,
            ic_july: 4.692,
            ic_august: 5.488,
            ic_september: 5.929,
            ic_october: 6.109,
            ic_november: 6.256,
            ic_december: 5.956,
            ic_yearly: 5.639,
          },
          {
            ic_uuid: "fbe0f0ff-9c0b-4de2-8991-f0e849d8a96a",
            ic_lat: -121376,
            ic_lon: -403603,
            ic_city: "Macajuba",
            ic_states: "BAHIA",
            ic_january: 5.83,
            ic_february: 5.856,
            ic_march: 5.655,
            ic_april: 4.572,
            ic_may: 3.949,
            ic_june: 3.613,
            ic_july: 3.751,
            ic_august: 4.32,
            ic_september: 5.191,
            ic_october: 5.223,
            ic_november: 5.455,
            ic_december: 5.792,
            ic_yearly: 4.934,
          },
          {
            ic_uuid: "9541894c-f4f6-4b36-a97b-fefe8ae196a7",
            ic_lat: -291449,
            ic_lon: -560679,
            ic_city: "Maçambará",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.872,
            ic_february: 6.258,
            ic_march: 5.301,
            ic_april: 4.061,
            ic_may: 3.047,
            ic_june: 2.499,
            ic_july: 2.802,
            ic_august: 3.584,
            ic_september: 4.147,
            ic_october: 5.389,
            ic_november: 6.496,
            ic_december: 7.044,
            ic_yearly: 4.792,
          },
          {
            ic_uuid: "d77cb1aa-ebf6-444a-b2e6-d4939e8751de",
            ic_lat: -106665,
            ic_lon: -375414,
            ic_city: "Macambira",
            ic_states: "SERGIPE",
            ic_january: 6.086,
            ic_february: 5.902,
            ic_march: 5.778,
            ic_april: 4.97,
            ic_may: 4.153,
            ic_june: 3.919,
            ic_july: 4.025,
            ic_august: 4.541,
            ic_september: 5.354,
            ic_october: 5.656,
            ic_november: 6.157,
            ic_december: 6.174,
            ic_yearly: 5.226,
          },
          {
            ic_uuid: "13b6bcd7-579a-48e6-9e1d-a415d921094a",
            ic_lat: 342,
            ic_lon: -510702,
            ic_city: "Macapá",
            ic_states: "AMAPÁ",
            ic_january: 4.714,
            ic_february: 4.317,
            ic_march: 4.232,
            ic_april: 4.286,
            ic_may: 4.71,
            ic_june: 4.973,
            ic_july: 5.09,
            ic_august: 5.477,
            ic_september: 5.618,
            ic_october: 5.483,
            ic_november: 5.456,
            ic_december: 4.98,
            ic_yearly: 4.944,
          },
          {
            ic_uuid: "302b2634-19d0-421c-9620-dc5041324876",
            ic_lat: -75544,
            ic_lon: -3545,
            ic_city: "Macaparana",
            ic_states: "PERNAMBUCO",
            ic_january: 5.507,
            ic_february: 5.686,
            ic_march: 5.686,
            ic_april: 5.154,
            ic_may: 4.409,
            ic_june: 3.95,
            ic_july: 4.064,
            ic_august: 4.792,
            ic_september: 5.338,
            ic_october: 5.58,
            ic_november: 5.997,
            ic_december: 5.827,
            ic_yearly: 5.166,
          },
          {
            ic_uuid: "fd9ddc93-b0b5-4272-a63b-7829c548ea9b",
            ic_lat: -15565,
            ic_lon: -404212,
            ic_city: "Macarani",
            ic_states: "BAHIA",
            ic_january: 5.991,
            ic_february: 6.134,
            ic_march: 5.453,
            ic_april: 4.684,
            ic_may: 3.905,
            ic_june: 3.457,
            ic_july: 3.646,
            ic_august: 4.171,
            ic_september: 4.951,
            ic_october: 5.094,
            ic_november: 5.174,
            ic_december: 6.098,
            ic_yearly: 4.897,
          },
          {
            ic_uuid: "253b0357-52c0-47b1-9393-0955e78e2b08",
            ic_lat: -225007,
            ic_lon: -487106,
            ic_city: "Macatuba",
            ic_states: "SÃO PAULO",
            ic_january: 5.668,
            ic_february: 5.916,
            ic_march: 5.271,
            ic_april: 4.684,
            ic_may: 3.818,
            ic_june: 3.576,
            ic_july: 3.75,
            ic_august: 4.723,
            ic_september: 4.869,
            ic_october: 5.555,
            ic_november: 5.944,
            ic_december: 6.291,
            ic_yearly: 5.005,
          },
          {
            ic_uuid: "36dff00e-3777-4eb0-be2f-d61680ee23b1",
            ic_lat: -5113,
            ic_lon: -366345,
            ic_city: "Macau",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.237,
            ic_february: 6.082,
            ic_march: 5.98,
            ic_april: 5.635,
            ic_may: 5.316,
            ic_june: 4.894,
            ic_july: 5.104,
            ic_august: 5.77,
            ic_september: 6.263,
            ic_october: 6.259,
            ic_november: 6.366,
            ic_december: 6.329,
            ic_yearly: 5.853,
          },
          {
            ic_uuid: "898dcf26-dc94-4a04-9093-bd6384e2b06b",
            ic_lat: -20806,
            ic_lon: -499639,
            ic_city: "Macaubal",
            ic_states: "SÃO PAULO",
            ic_january: 5.833,
            ic_february: 5.886,
            ic_march: 5.266,
            ic_april: 4.905,
            ic_may: 4.131,
            ic_june: 3.858,
            ic_july: 4.121,
            ic_august: 5.031,
            ic_september: 5.029,
            ic_october: 5.63,
            ic_november: 5.952,
            ic_december: 6.177,
            ic_yearly: 5.151,
          },
          {
            ic_uuid: "6a6d827e-1f36-4701-9548-ba26a32b863e",
            ic_lat: -13019,
            ic_lon: -426949,
            ic_city: "Macaúbas",
            ic_states: "BAHIA",
            ic_january: 6.651,
            ic_february: 6.637,
            ic_march: 6.044,
            ic_april: 5.557,
            ic_may: 5.006,
            ic_june: 4.806,
            ic_july: 5.019,
            ic_august: 5.665,
            ic_september: 6.114,
            ic_october: 6.382,
            ic_november: 6.088,
            ic_december: 6.594,
            ic_yearly: 5.88,
          },
          {
            ic_uuid: "17b640a6-d478-4804-be49-0af05804c353",
            ic_lat: -20146,
            ic_lon: -501939,
            ic_city: "Macedônia",
            ic_states: "SÃO PAULO",
            ic_january: 5.843,
            ic_february: 6.011,
            ic_march: 5.333,
            ic_april: 4.936,
            ic_may: 4.267,
            ic_june: 3.988,
            ic_july: 4.245,
            ic_august: 5.088,
            ic_september: 5.136,
            ic_october: 5.697,
            ic_november: 5.915,
            ic_december: 6.221,
            ic_yearly: 5.223,
          },
          {
            ic_uuid: "f125a2ca-0116-4ec0-8b62-f75a9ab9cd03",
            ic_lat: -96607,
            ic_lon: -357018,
            ic_city: "Maceió",
            ic_states: "ALAGOAS",
            ic_january: 6.173,
            ic_february: 6.161,
            ic_march: 6.101,
            ic_april: 5.255,
            ic_may: 4.446,
            ic_june: 4.14,
            ic_july: 4.301,
            ic_august: 5.012,
            ic_september: 5.735,
            ic_october: 6.036,
            ic_november: 6.406,
            ic_december: 6.51,
            ic_yearly: 5.523,
          },
          {
            ic_uuid: "e7c2833d-927e-484a-9ec3-8b4c93f95c7b",
            ic_lat: -170727,
            ic_lon: -407248,
            ic_city: "Machacalis",
            ic_states: "MINAS GERAIS",
            ic_january: 6.124,
            ic_february: 6.347,
            ic_march: 5.454,
            ic_april: 4.679,
            ic_may: 3.866,
            ic_june: 3.496,
            ic_july: 3.695,
            ic_august: 4.383,
            ic_september: 5.092,
            ic_october: 5.339,
            ic_november: 5.269,
            ic_december: 6.21,
            ic_yearly: 4.996,
          },
          {
            ic_uuid: "6191974b-8e32-44c4-98ed-e1ae089c6b21",
            ic_lat: -275671,
            ic_lon: -516673,
            ic_city: "Machadinho",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.33,
            ic_february: 5.791,
            ic_march: 5.043,
            ic_april: 4.11,
            ic_may: 3.078,
            ic_june: 2.644,
            ic_july: 2.908,
            ic_august: 3.727,
            ic_september: 3.961,
            ic_october: 5.06,
            ic_november: 6.246,
            ic_december: 6.574,
            ic_yearly: 4.623,
          },
          {
            ic_uuid: "345e0a66-32f9-4329-b554-70a0f50ec076",
            ic_lat: -94275,
            ic_lon: -619976,
            ic_city: "Machadinho D'Oeste",
            ic_states: "RONDÔNIA",
            ic_january: 4.194,
            ic_february: 4.31,
            ic_march: 4.331,
            ic_april: 4.344,
            ic_may: 4.045,
            ic_june: 4.484,
            ic_july: 4.591,
            ic_august: 4.853,
            ic_september: 4.844,
            ic_october: 4.985,
            ic_november: 4.679,
            ic_december: 4.517,
            ic_yearly: 4.515,
          },
          {
            ic_uuid: "45ba9632-58c9-41b6-8a72-0773d86f9f67",
            ic_lat: -216782,
            ic_lon: -459222,
            ic_city: "Machado",
            ic_states: "MINAS GERAIS",
            ic_january: 5.396,
            ic_february: 5.675,
            ic_march: 4.77,
            ic_april: 4.53,
            ic_may: 3.942,
            ic_june: 3.708,
            ic_july: 3.946,
            ic_august: 4.873,
            ic_september: 5.019,
            ic_october: 5.307,
            ic_november: 5.182,
            ic_december: 5.535,
            ic_yearly: 4.824,
          },
          {
            ic_uuid: "785127be-bbeb-4e57-9fff-62fe6e7c989a",
            ic_lat: -76851,
            ic_lon: -355154,
            ic_city: "Machados",
            ic_states: "PERNAMBUCO",
            ic_january: 5.633,
            ic_february: 5.733,
            ic_march: 5.777,
            ic_april: 5.185,
            ic_may: 4.457,
            ic_june: 3.984,
            ic_july: 4.115,
            ic_august: 4.857,
            ic_september: 5.442,
            ic_october: 5.721,
            ic_november: 6.027,
            ic_december: 5.914,
            ic_yearly: 5.237,
          },
          {
            ic_uuid: "d5dc54a1-1dbf-4412-b3fd-5ec56fcf8e09",
            ic_lat: -268557,
            ic_lon: -51371,
            ic_city: "Macieira",
            ic_states: "SANTA CATARINA",
            ic_january: 5.69,
            ic_february: 5.25,
            ic_march: 4.802,
            ic_april: 3.933,
            ic_may: 3.016,
            ic_june: 2.7,
            ic_july: 2.925,
            ic_august: 3.833,
            ic_september: 3.995,
            ic_october: 4.792,
            ic_november: 5.845,
            ic_december: 6.02,
            ic_yearly: 4.4,
          },
          {
            ic_uuid: "0844dd47-d9ec-4266-904b-b314ac7ce679",
            ic_lat: -219818,
            ic_lon: -422537,
            ic_city: "Macuco",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.99,
            ic_february: 6.2,
            ic_march: 5.112,
            ic_april: 4.405,
            ic_may: 3.654,
            ic_june: 3.398,
            ic_july: 3.555,
            ic_august: 4.288,
            ic_september: 4.599,
            ic_october: 4.928,
            ic_november: 4.929,
            ic_december: 5.776,
            ic_yearly: 4.736,
          },
          {
            ic_uuid: "fd02c3b4-f436-403e-8cf8-37fecac03a90",
            ic_lat: -91627,
            ic_lon: -390528,
            ic_city: "Macururé",
            ic_states: "BAHIA",
            ic_january: 6.216,
            ic_february: 5.992,
            ic_march: 5.906,
            ic_april: 5.305,
            ic_may: 4.581,
            ic_june: 4.212,
            ic_july: 4.406,
            ic_august: 5.129,
            ic_september: 6.042,
            ic_october: 6.199,
            ic_november: 6.452,
            ic_december: 6.335,
            ic_yearly: 5.565,
          },
          {
            ic_uuid: "661998dc-c0c2-4a9f-89e0-0790ecaf8563",
            ic_lat: -48464,
            ic_lon: -395729,
            ic_city: "Madalena",
            ic_states: "CEARÁ",
            ic_january: 5.181,
            ic_february: 5.316,
            ic_march: 5.312,
            ic_april: 4.94,
            ic_may: 4.893,
            ic_june: 4.812,
            ic_july: 5.179,
            ic_august: 5.95,
            ic_september: 6.471,
            ic_october: 6.392,
            ic_november: 6.2,
            ic_december: 5.554,
            ic_yearly: 5.517,
          },
          {
            ic_uuid: "a37005c5-7e1f-46e5-a8da-8f2d38f47f1e",
            ic_lat: -34848,
            ic_lon: -425031,
            ic_city: "Madeiro",
            ic_states: "PIAUÍ",
            ic_january: 5.277,
            ic_february: 5.303,
            ic_march: 5.403,
            ic_april: 5.146,
            ic_may: 5.15,
            ic_june: 5.299,
            ic_july: 5.522,
            ic_august: 5.996,
            ic_september: 6.318,
            ic_october: 6.334,
            ic_november: 6.09,
            ic_december: 5.577,
            ic_yearly: 5.618,
          },
          {
            ic_uuid: "e244a272-a6cd-4342-bb57-5c1b8b719e83",
            ic_lat: -127451,
            ic_lon: -386156,
            ic_city: "Madre de Deus",
            ic_states: "BAHIA",
            ic_january: 6.22,
            ic_february: 6.083,
            ic_march: 5.936,
            ic_april: 4.801,
            ic_may: 4.093,
            ic_june: 3.769,
            ic_july: 4.025,
            ic_august: 4.55,
            ic_september: 5.371,
            ic_october: 5.627,
            ic_november: 5.856,
            ic_december: 6.284,
            ic_yearly: 5.218,
          },
          {
            ic_uuid: "2e9c4a8a-9874-406d-b3a0-35395d730006",
            ic_lat: -214835,
            ic_lon: -443291,
            ic_city: "Madre de Deus de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.534,
            ic_february: 5.808,
            ic_march: 4.953,
            ic_april: 4.435,
            ic_may: 3.775,
            ic_june: 3.594,
            ic_july: 3.796,
            ic_august: 4.725,
            ic_september: 4.975,
            ic_october: 5.222,
            ic_november: 5.083,
            ic_december: 5.64,
            ic_yearly: 4.795,
          },
          {
            ic_uuid: "3fd70b08-db0a-425e-a324-d12e77e372d3",
            ic_lat: -72579,
            ic_lon: -374277,
            ic_city: "Mãe d'Água",
            ic_states: "PARAÍBA",
            ic_january: 6.035,
            ic_february: 6.044,
            ic_march: 6.053,
            ic_april: 5.792,
            ic_may: 5.106,
            ic_june: 4.707,
            ic_july: 5.071,
            ic_august: 5.853,
            ic_september: 6.473,
            ic_october: 6.586,
            ic_november: 6.681,
            ic_december: 6.362,
            ic_yearly: 5.897,
          },
          {
            ic_uuid: "5857c242-0662-4d6b-a99a-caf4323b4bb1",
            ic_lat: -20456,
            ic_lon: -475571,
            ic_city: "Mãe do Rio",
            ic_states: "PARÁ",
            ic_january: 4.609,
            ic_february: 4.508,
            ic_march: 4.601,
            ic_april: 4.586,
            ic_may: 4.766,
            ic_june: 4.971,
            ic_july: 5.091,
            ic_august: 5.366,
            ic_september: 5.469,
            ic_october: 5.279,
            ic_november: 5.036,
            ic_december: 4.731,
            ic_yearly: 4.918,
          },
          {
            ic_uuid: "82e06acc-1e04-44a5-990d-82b1b7316120",
            ic_lat: -146628,
            ic_lon: -414918,
            ic_city: "Maetinga",
            ic_states: "BAHIA",
            ic_january: 6.263,
            ic_february: 6.227,
            ic_march: 5.643,
            ic_april: 4.905,
            ic_may: 4.296,
            ic_june: 3.896,
            ic_july: 4.185,
            ic_august: 4.808,
            ic_september: 5.707,
            ic_october: 5.84,
            ic_november: 5.6,
            ic_december: 6.218,
            ic_yearly: 5.299,
          },
          {
            ic_uuid: "74d5e9c8-83df-4469-9292-2a135d423fd8",
            ic_lat: -261164,
            ic_lon: -498091,
            ic_city: "Mafra",
            ic_states: "SANTA CATARINA",
            ic_january: 5.481,
            ic_february: 5.15,
            ic_march: 4.554,
            ic_april: 3.633,
            ic_may: 2.891,
            ic_june: 2.497,
            ic_july: 2.746,
            ic_august: 3.733,
            ic_september: 3.787,
            ic_october: 4.418,
            ic_november: 5.305,
            ic_december: 5.629,
            ic_yearly: 4.152,
          },
          {
            ic_uuid: "c3d82866-304e-40f8-a950-c247f6e29772",
            ic_lat: -7975,
            ic_lon: -47601,
            ic_city: "Magalhães Barata",
            ic_states: "PARÁ",
            ic_january: 4.52,
            ic_february: 4.232,
            ic_march: 4.156,
            ic_april: 4.168,
            ic_may: 4.481,
            ic_june: 4.736,
            ic_july: 4.835,
            ic_august: 5.196,
            ic_september: 5.46,
            ic_october: 5.331,
            ic_november: 5.189,
            ic_december: 4.839,
            ic_yearly: 4.762,
          },
          {
            ic_uuid: "1cecab59-1100-4cb9-b460-851f8c08a51e",
            ic_lat: -33964,
            ic_lon: -422046,
            ic_city: "Magalhães de Almeida",
            ic_states: "MARANHÃO",
            ic_january: 5.21,
            ic_february: 5.307,
            ic_march: 5.247,
            ic_april: 5.057,
            ic_may: 5.142,
            ic_june: 5.322,
            ic_july: 5.517,
            ic_august: 5.953,
            ic_september: 6.263,
            ic_october: 6.347,
            ic_november: 6.059,
            ic_december: 5.514,
            ic_yearly: 5.578,
          },
          {
            ic_uuid: "1e78d103-bdcd-4ce3-bd82-d059094c3086",
            ic_lat: -20644,
            ic_lon: -502259,
            ic_city: "Magda",
            ic_states: "SÃO PAULO",
            ic_january: 5.861,
            ic_february: 5.912,
            ic_march: 5.315,
            ic_april: 4.878,
            ic_may: 4.139,
            ic_june: 3.885,
            ic_july: 4.11,
            ic_august: 5.003,
            ic_september: 5.067,
            ic_october: 5.601,
            ic_november: 5.943,
            ic_december: 6.21,
            ic_yearly: 5.16,
          },
          {
            ic_uuid: "6fec15b8-1a45-464f-bbd2-6257d34109e9",
            ic_lat: -226636,
            ic_lon: -430319,
            ic_city: "Magé",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.933,
            ic_february: 6.154,
            ic_march: 4.99,
            ic_april: 4.424,
            ic_may: 3.586,
            ic_june: 3.385,
            ic_july: 3.39,
            ic_august: 4.202,
            ic_september: 4.353,
            ic_october: 4.888,
            ic_november: 4.896,
            ic_december: 5.663,
            ic_yearly: 4.655,
          },
          {
            ic_uuid: "e1989a3e-3748-419b-b502-f4c77e2f861e",
            ic_lat: -156244,
            ic_lon: -402591,
            ic_city: "Maiquinique",
            ic_states: "BAHIA",
            ic_january: 5.878,
            ic_february: 6.024,
            ic_march: 5.355,
            ic_april: 4.67,
            ic_may: 3.909,
            ic_june: 3.483,
            ic_july: 3.649,
            ic_august: 4.146,
            ic_september: 4.918,
            ic_october: 5.068,
            ic_november: 5.128,
            ic_december: 5.99,
            ic_yearly: 4.851,
          },
          {
            ic_uuid: "7d026ca2-6f67-44a9-9fad-0a4af79db3b8",
            ic_lat: -11706,
            ic_lon: -401498,
            ic_city: "Mairi",
            ic_states: "BAHIA",
            ic_january: 5.969,
            ic_february: 5.827,
            ic_march: 5.719,
            ic_april: 4.666,
            ic_may: 3.983,
            ic_june: 3.677,
            ic_july: 3.874,
            ic_august: 4.395,
            ic_september: 5.265,
            ic_october: 5.379,
            ic_november: 5.609,
            ic_december: 5.886,
            ic_yearly: 5.021,
          },
          {
            ic_uuid: "d4f8a57d-19ee-4f00-a3fb-1e432a1a8ba3",
            ic_lat: -235403,
            ic_lon: -471854,
            ic_city: "Mairinque",
            ic_states: "SÃO PAULO",
            ic_january: 5.439,
            ic_february: 5.686,
            ic_march: 4.991,
            ic_april: 4.41,
            ic_may: 3.591,
            ic_june: 3.346,
            ic_july: 3.434,
            ic_august: 4.443,
            ic_september: 4.54,
            ic_october: 5.069,
            ic_november: 5.516,
            ic_december: 6.002,
            ic_yearly: 4.706,
          },
          {
            ic_uuid: "b9545b6c-e341-4618-a0ab-406e53f32262",
            ic_lat: -233176,
            ic_lon: -465901,
            ic_city: "Mairiporã",
            ic_states: "SÃO PAULO",
            ic_january: 5.301,
            ic_february: 5.627,
            ic_march: 4.875,
            ic_april: 4.35,
            ic_may: 3.564,
            ic_june: 3.356,
            ic_july: 3.446,
            ic_august: 4.443,
            ic_september: 4.48,
            ic_october: 5.003,
            ic_november: 5.26,
            ic_december: 5.83,
            ic_yearly: 4.628,
          },
          {
            ic_uuid: "793dd8a6-aa0e-4aa2-ae92-cc21dc35cc26",
            ic_lat: -17297,
            ic_lon: -494944,
            ic_city: "Mairipotaba",
            ic_states: "GOIÁS",
            ic_january: 5.617,
            ic_february: 5.711,
            ic_march: 5.293,
            ic_april: 5.153,
            ic_may: 4.724,
            ic_june: 4.457,
            ic_july: 4.694,
            ic_august: 5.585,
            ic_september: 5.416,
            ic_october: 5.578,
            ic_november: 5.482,
            ic_december: 5.625,
            ic_yearly: 5.278,
          },
          {
            ic_uuid: "34b41bd7-0c74-413b-bb9d-26e9b7a0acce",
            ic_lat: -274197,
            ic_lon: -489492,
            ic_city: "Major Gercino",
            ic_states: "SANTA CATARINA",
            ic_january: 5.447,
            ic_february: 5.271,
            ic_march: 4.538,
            ic_april: 3.646,
            ic_may: 2.996,
            ic_june: 2.502,
            ic_july: 2.643,
            ic_august: 3.33,
            ic_september: 3.398,
            ic_october: 3.877,
            ic_november: 5.104,
            ic_december: 5.56,
            ic_yearly: 4.026,
          },
          {
            ic_uuid: "91d1f6dc-560f-4678-b563-1832d9a6bf5a",
            ic_lat: -95339,
            ic_lon: -369869,
            ic_city: "Major Isidoro",
            ic_states: "ALAGOAS",
            ic_january: 6.127,
            ic_february: 5.828,
            ic_march: 5.917,
            ic_april: 5.193,
            ic_may: 4.316,
            ic_june: 3.956,
            ic_july: 4.094,
            ic_august: 4.669,
            ic_september: 5.503,
            ic_october: 5.893,
            ic_november: 6.468,
            ic_december: 6.37,
            ic_yearly: 5.361,
          },
          {
            ic_uuid: "23828d4a-efbb-4691-80dc-fd998eba8a2e",
            ic_lat: -63999,
            ic_lon: -383243,
            ic_city: "Major Sales",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.01,
            ic_february: 5.982,
            ic_march: 6.013,
            ic_april: 5.641,
            ic_may: 5.21,
            ic_june: 4.95,
            ic_july: 5.324,
            ic_august: 6.069,
            ic_september: 6.476,
            ic_october: 6.582,
            ic_november: 6.7,
            ic_december: 6.201,
            ic_yearly: 5.93,
          },
          {
            ic_uuid: "fe3563b2-f504-4f88-9870-e7aae97c2800",
            ic_lat: -263714,
            ic_lon: -50327,
            ic_city: "Major Vieira",
            ic_states: "SANTA CATARINA",
            ic_january: 5.415,
            ic_february: 5.044,
            ic_march: 4.536,
            ic_april: 3.537,
            ic_may: 2.85,
            ic_june: 2.433,
            ic_july: 2.671,
            ic_august: 3.62,
            ic_september: 3.72,
            ic_october: 4.284,
            ic_november: 5.342,
            ic_december: 5.568,
            ic_yearly: 4.085,
          },
          {
            ic_uuid: "3c6a7bf2-6f0f-4695-8ccf-1371b1a97f7f",
            ic_lat: -178461,
            ic_lon: -420773,
            ic_city: "Malacacheta",
            ic_states: "MINAS GERAIS",
            ic_january: 5.79,
            ic_february: 6.04,
            ic_march: 5.127,
            ic_april: 4.4,
            ic_may: 3.804,
            ic_june: 3.662,
            ic_july: 3.847,
            ic_august: 4.531,
            ic_september: 5.085,
            ic_october: 5.304,
            ic_november: 4.812,
            ic_december: 5.606,
            ic_yearly: 4.834,
          },
          {
            ic_uuid: "76d086a8-820a-4461-a671-aa7cf55f75e3",
            ic_lat: -143389,
            ic_lon: -43773,
            ic_city: "Malhada",
            ic_states: "BAHIA",
            ic_january: 6.556,
            ic_february: 6.704,
            ic_march: 6.032,
            ic_april: 5.596,
            ic_may: 5.051,
            ic_june: 4.839,
            ic_july: 5.147,
            ic_august: 5.681,
            ic_september: 6.052,
            ic_october: 6.378,
            ic_november: 5.888,
            ic_december: 6.44,
            ic_yearly: 5.864,
          },
          {
            ic_uuid: "e18d39e6-3166-44bf-b7b6-4acc57f88893",
            ic_lat: -143851,
            ic_lon: -418846,
            ic_city: "Malhada de Pedras",
            ic_states: "BAHIA",
            ic_january: 6.426,
            ic_february: 6.402,
            ic_march: 5.794,
            ic_april: 4.96,
            ic_may: 4.372,
            ic_june: 4.003,
            ic_july: 4.289,
            ic_august: 5.005,
            ic_september: 5.781,
            ic_october: 6.12,
            ic_november: 5.85,
            ic_december: 6.459,
            ic_yearly: 5.455,
          },
          {
            ic_uuid: "04ea5aa9-ba09-4081-9bf9-465fb69f2b78",
            ic_lat: -103446,
            ic_lon: -369209,
            ic_city: "Malhada dos Bois",
            ic_states: "SERGIPE",
            ic_january: 5.911,
            ic_february: 5.725,
            ic_march: 5.704,
            ic_april: 4.977,
            ic_may: 4.206,
            ic_june: 3.98,
            ic_july: 4.044,
            ic_august: 4.615,
            ic_september: 5.31,
            ic_october: 5.66,
            ic_november: 6.187,
            ic_december: 6.162,
            ic_yearly: 5.207,
          },
          {
            ic_uuid: "f58c8620-a0be-4d91-b011-6bc2b6592a1d",
            ic_lat: -106586,
            ic_lon: -373062,
            ic_city: "Malhador",
            ic_states: "SERGIPE",
            ic_january: 6.219,
            ic_february: 5.953,
            ic_march: 5.9,
            ic_april: 5.035,
            ic_may: 4.141,
            ic_june: 3.91,
            ic_july: 3.998,
            ic_august: 4.54,
            ic_september: 5.345,
            ic_october: 5.74,
            ic_november: 6.233,
            ic_december: 6.302,
            ic_yearly: 5.276,
          },
          {
            ic_uuid: "6a92dab8-1fce-4b03-a463-5ac86b28db1f",
            ic_lat: -258811,
            ic_lon: -508178,
            ic_city: "Mallet",
            ic_states: "PARANÁ",
            ic_january: 5.25,
            ic_february: 4.967,
            ic_march: 4.45,
            ic_april: 3.57,
            ic_may: 2.871,
            ic_june: 2.512,
            ic_july: 2.735,
            ic_august: 3.745,
            ic_september: 3.827,
            ic_october: 4.326,
            ic_november: 5.322,
            ic_december: 5.492,
            ic_yearly: 4.089,
          },
          {
            ic_uuid: "e6de22f7-6e12-4653-a787-31e151c994ac",
            ic_lat: -69047,
            ic_lon: -375215,
            ic_city: "Malta",
            ic_states: "PARAÍBA",
            ic_january: 6.232,
            ic_february: 6.268,
            ic_march: 6.339,
            ic_april: 5.924,
            ic_may: 5.27,
            ic_june: 4.845,
            ic_july: 5.092,
            ic_august: 5.912,
            ic_september: 6.51,
            ic_october: 6.648,
            ic_november: 6.668,
            ic_december: 6.448,
            ic_yearly: 6.013,
          },
          {
            ic_uuid: "4c5e2d18-d390-4088-a5ee-3d12ccb244e5",
            ic_lat: -68341,
            ic_lon: -351216,
            ic_city: "Mamanguape",
            ic_states: "PARAÍBA",
            ic_january: 5.822,
            ic_february: 5.854,
            ic_march: 5.991,
            ic_april: 5.341,
            ic_may: 4.688,
            ic_june: 4.244,
            ic_july: 4.321,
            ic_august: 5.115,
            ic_september: 5.586,
            ic_october: 5.906,
            ic_november: 6.223,
            ic_december: 6.089,
            ic_yearly: 5.432,
          },
          {
            ic_uuid: "cc6d1fe1-14da-49d7-8c51-b0613599ae18",
            ic_lat: -144966,
            ic_lon: -46109,
            ic_city: "Mambaí",
            ic_states: "GOIÁS",
            ic_january: 6.045,
            ic_february: 6.042,
            ic_march: 5.509,
            ic_april: 5.448,
            ic_may: 5.055,
            ic_june: 4.927,
            ic_july: 5.288,
            ic_august: 5.977,
            ic_september: 6.022,
            ic_october: 5.883,
            ic_november: 5.281,
            ic_december: 5.897,
            ic_yearly: 5.614,
          },
          {
            ic_uuid: "cfe4cf0c-3d3e-45af-8ed4-ebce6e45445e",
            ic_lat: -243174,
            ic_lon: -525276,
            ic_city: "Mamborê",
            ic_states: "PARANÁ",
            ic_january: 6.212,
            ic_february: 5.667,
            ic_march: 5.166,
            ic_april: 4.403,
            ic_may: 3.515,
            ic_june: 3.148,
            ic_july: 3.366,
            ic_august: 4.258,
            ic_september: 4.503,
            ic_october: 5.274,
            ic_november: 6.013,
            ic_december: 6.475,
            ic_yearly: 4.833,
          },
          {
            ic_uuid: "f4c8bc28-ed70-418d-9ed5-04ad82e23726",
            ic_lat: -150509,
            ic_lon: -429511,
            ic_city: "Mamonas",
            ic_states: "MINAS GERAIS",
            ic_january: 6.524,
            ic_february: 6.592,
            ic_march: 5.806,
            ic_april: 5.331,
            ic_may: 4.784,
            ic_june: 4.606,
            ic_july: 4.869,
            ic_august: 5.523,
            ic_september: 6.004,
            ic_october: 6.15,
            ic_november: 5.706,
            ic_december: 6.329,
            ic_yearly: 5.685,
          },
          {
            ic_uuid: "956ede7f-c2bb-442e-a2c7-2c20593c6f26",
            ic_lat: -292109,
            ic_lon: -49935,
            ic_city: "Mampituba",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 5.175,
            ic_february: 4.867,
            ic_march: 4.198,
            ic_april: 3.671,
            ic_may: 2.854,
            ic_june: 2.422,
            ic_july: 2.658,
            ic_august: 3.347,
            ic_september: 3.379,
            ic_october: 3.995,
            ic_november: 5.238,
            ic_december: 5.463,
            ic_yearly: 3.939,
          },
          {
            ic_uuid: "557628df-dba3-440d-bff6-863aaa433a25",
            ic_lat: -3291,
            ic_lon: -60622,
            ic_city: "Manacapuru",
            ic_states: "AMAZONAS",
            ic_january: 4.133,
            ic_february: 4.208,
            ic_march: 4.222,
            ic_april: 4.01,
            ic_may: 3.949,
            ic_june: 4.467,
            ic_july: 4.542,
            ic_august: 5.021,
            ic_september: 4.971,
            ic_october: 4.951,
            ic_november: 4.79,
            ic_december: 4.305,
            ic_yearly: 4.464,
          },
          {
            ic_uuid: "fcc144aa-8cd4-469d-a9aa-7a52ad708933",
            ic_lat: -77037,
            ic_lon: -381526,
            ic_city: "Manaíra",
            ic_states: "PARAÍBA",
            ic_january: 6.157,
            ic_february: 6.109,
            ic_march: 6.139,
            ic_april: 5.72,
            ic_may: 5.048,
            ic_june: 4.69,
            ic_july: 4.94,
            ic_august: 5.779,
            ic_september: 6.472,
            ic_october: 6.564,
            ic_november: 6.738,
            ic_december: 6.323,
            ic_yearly: 5.89,
          },
          {
            ic_uuid: "5fa25cc6-da3a-4032-9c55-ba91d4d8bf71",
            ic_lat: -34412,
            ic_lon: -604617,
            ic_city: "Manaquiri",
            ic_states: "AMAZONAS",
            ic_january: 4.152,
            ic_february: 4.247,
            ic_march: 4.213,
            ic_april: 3.977,
            ic_may: 3.984,
            ic_june: 4.44,
            ic_july: 4.548,
            ic_august: 5.088,
            ic_september: 5.084,
            ic_october: 5.038,
            ic_november: 4.859,
            ic_december: 4.291,
            ic_yearly: 4.494,
          },
          {
            ic_uuid: "ec055adc-7083-4ac5-8766-240ee3ed0a49",
            ic_lat: -8961,
            ic_lon: -376245,
            ic_city: "Manari",
            ic_states: "PERNAMBUCO",
            ic_january: 6.38,
            ic_february: 6.116,
            ic_march: 6.066,
            ic_april: 5.457,
            ic_may: 4.504,
            ic_june: 3.976,
            ic_july: 4.173,
            ic_august: 4.889,
            ic_september: 5.879,
            ic_october: 6.265,
            ic_november: 6.721,
            ic_december: 6.504,
            ic_yearly: 5.578,
          },
          {
            ic_uuid: "fd324609-379f-411d-b12a-9d23bf8f6040",
            ic_lat: -31194,
            ic_lon: -600221,
            ic_city: "Manaus",
            ic_states: "AMAZONAS",
            ic_january: 3.947,
            ic_february: 4.017,
            ic_march: 4.04,
            ic_april: 3.831,
            ic_may: 3.879,
            ic_june: 4.385,
            ic_july: 4.431,
            ic_august: 4.934,
            ic_september: 4.887,
            ic_october: 4.778,
            ic_november: 4.629,
            ic_december: 4.118,
            ic_yearly: 4.323,
          },
          {
            ic_uuid: "ba4d3244-b31c-4744-9489-99865a473e52",
            ic_lat: -7617,
            ic_lon: -729002,
            ic_city: "Mâncio Lima",
            ic_states: "ACRE",
            ic_january: 4.752,
            ic_february: 4.903,
            ic_march: 4.335,
            ic_april: 4.496,
            ic_may: 4.279,
            ic_june: 4.173,
            ic_july: 4.323,
            ic_august: 5.095,
            ic_september: 5.2,
            ic_october: 5.115,
            ic_november: 5.005,
            ic_december: 4.759,
            ic_yearly: 4.703,
          },
          {
            ic_uuid: "03148cef-f893-47ff-931d-19597776bede",
            ic_lat: -233462,
            ic_lon: -520948,
            ic_city: "Mandaguaçu",
            ic_states: "PARANÁ",
            ic_january: 5.894,
            ic_february: 5.826,
            ic_march: 5.296,
            ic_april: 4.596,
            ic_may: 3.642,
            ic_june: 3.344,
            ic_july: 3.538,
            ic_august: 4.483,
            ic_september: 4.68,
            ic_october: 5.372,
            ic_november: 6.046,
            ic_december: 6.347,
            ic_yearly: 4.922,
          },
          {
            ic_uuid: "f7ae7441-9580-406d-b298-5977372c7b3e",
            ic_lat: -235451,
            ic_lon: -516715,
            ic_city: "Mandaguari",
            ic_states: "PARANÁ",
            ic_january: 5.822,
            ic_february: 5.756,
            ic_march: 5.3,
            ic_april: 4.601,
            ic_may: 3.626,
            ic_june: 3.298,
            ic_july: 3.522,
            ic_august: 4.493,
            ic_september: 4.667,
            ic_october: 5.352,
            ic_november: 5.998,
            ic_december: 6.271,
            ic_yearly: 4.892,
          },
          {
            ic_uuid: "2ba3e308-5eb3-449c-9176-8495aed99102",
            ic_lat: -257775,
            ic_lon: -493286,
            ic_city: "Mandirituba",
            ic_states: "PARANÁ",
            ic_january: 5.392,
            ic_february: 5.095,
            ic_march: 4.493,
            ic_april: 3.726,
            ic_may: 3.001,
            ic_june: 2.66,
            ic_july: 2.838,
            ic_august: 3.787,
            ic_september: 3.797,
            ic_october: 4.362,
            ic_november: 5.23,
            ic_december: 5.588,
            ic_yearly: 4.164,
          },
          {
            ic_uuid: "7d7a625e-161a-453a-8044-276888e42de6",
            ic_lat: -23006,
            ic_lon: -493206,
            ic_city: "Manduri",
            ic_states: "SÃO PAULO",
            ic_january: 5.555,
            ic_february: 5.75,
            ic_march: 5.145,
            ic_april: 4.638,
            ic_may: 3.707,
            ic_june: 3.417,
            ic_july: 3.623,
            ic_august: 4.611,
            ic_september: 4.698,
            ic_october: 5.368,
            ic_november: 5.853,
            ic_december: 6.222,
            ic_yearly: 4.882,
          },
          {
            ic_uuid: "d969b37f-4828-43cb-85b2-9b13bc6cb3d2",
            ic_lat: -261484,
            ic_lon: -533157,
            ic_city: "Manfrinópolis",
            ic_states: "PARANÁ",
            ic_january: 6.26,
            ic_february: 5.772,
            ic_march: 5.217,
            ic_april: 4.21,
            ic_may: 3.234,
            ic_june: 2.813,
            ic_july: 3.098,
            ic_august: 4.064,
            ic_september: 4.27,
            ic_october: 5.209,
            ic_november: 6.129,
            ic_december: 6.385,
            ic_yearly: 4.722,
          },
          {
            ic_uuid: "7e25297d-014b-436a-ba6a-060a5c507f48",
            ic_lat: -147533,
            ic_lon: -439394,
            ic_city: "Manga",
            ic_states: "MINAS GERAIS",
            ic_january: 6.527,
            ic_february: 6.683,
            ic_march: 5.961,
            ic_april: 5.684,
            ic_may: 5.009,
            ic_june: 4.828,
            ic_july: 5.156,
            ic_august: 5.732,
            ic_september: 6.221,
            ic_october: 6.279,
            ic_november: 5.779,
            ic_december: 6.302,
            ic_yearly: 5.847,
          },
          {
            ic_uuid: "9cbe8628-ec4a-418c-9c01-08d85526fd02",
            ic_lat: -229599,
            ic_lon: -440413,
            ic_city: "Mangaratiba",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.466,
            ic_february: 5.832,
            ic_march: 4.696,
            ic_april: 4.256,
            ic_may: 3.473,
            ic_june: 3.215,
            ic_july: 3.239,
            ic_august: 4.06,
            ic_september: 4.1,
            ic_october: 4.54,
            ic_november: 4.615,
            ic_december: 5.302,
            ic_yearly: 4.399,
          },
          {
            ic_uuid: "73cf4a98-9987-4852-8db1-a586eb0a027f",
            ic_lat: -259426,
            ic_lon: -521747,
            ic_city: "Mangueirinha",
            ic_states: "PARANÁ",
            ic_january: 6.195,
            ic_february: 5.69,
            ic_march: 5.223,
            ic_april: 4.233,
            ic_may: 3.259,
            ic_june: 2.844,
            ic_july: 3.086,
            ic_august: 4.098,
            ic_september: 4.244,
            ic_october: 5.204,
            ic_november: 6.137,
            ic_december: 6.351,
            ic_yearly: 4.714,
          },
          {
            ic_uuid: "b829ad5a-6d33-4f83-b93d-53ebdf0b0163",
            ic_lat: -202577,
            ic_lon: -420283,
            ic_city: "Manhuaçu",
            ic_states: "MINAS GERAIS",
            ic_january: 5.525,
            ic_february: 5.779,
            ic_march: 4.801,
            ic_april: 4.4,
            ic_may: 3.76,
            ic_june: 3.574,
            ic_july: 3.75,
            ic_august: 4.457,
            ic_september: 4.738,
            ic_october: 4.855,
            ic_november: 4.572,
            ic_december: 5.277,
            ic_yearly: 4.624,
          },
          {
            ic_uuid: "d9866c58-796c-4a2b-b694-12d97085d2ba",
            ic_lat: -203596,
            ic_lon: -419593,
            ic_city: "Manhumirim",
            ic_states: "MINAS GERAIS",
            ic_january: 5.609,
            ic_february: 5.862,
            ic_march: 4.894,
            ic_april: 4.41,
            ic_may: 3.788,
            ic_june: 3.592,
            ic_july: 3.761,
            ic_august: 4.434,
            ic_september: 4.751,
            ic_october: 4.882,
            ic_november: 4.656,
            ic_december: 5.377,
            ic_yearly: 4.668,
          },
          {
            ic_uuid: "0ada7bff-769d-413d-8f3c-265ad4e97423",
            ic_lat: -5805,
            ic_lon: -612899,
            ic_city: "Manicoré",
            ic_states: "AMAZONAS",
            ic_january: 4.196,
            ic_february: 4.292,
            ic_march: 4.272,
            ic_april: 4.097,
            ic_may: 3.804,
            ic_june: 4.287,
            ic_july: 4.418,
            ic_august: 4.966,
            ic_september: 4.918,
            ic_october: 4.766,
            ic_november: 4.671,
            ic_december: 4.3,
            ic_yearly: 4.416,
          },
          {
            ic_uuid: "5a477469-2f20-4b4e-8553-013f067debbd",
            ic_lat: -80043,
            ic_lon: -438723,
            ic_city: "Manoel Emídio",
            ic_states: "PIAUÍ",
            ic_january: 5.397,
            ic_february: 5.311,
            ic_march: 5.225,
            ic_april: 5.335,
            ic_may: 5.337,
            ic_june: 5.369,
            ic_july: 5.639,
            ic_august: 6.26,
            ic_september: 6.557,
            ic_october: 6.255,
            ic_november: 5.832,
            ic_december: 5.559,
            ic_yearly: 5.673,
          },
          {
            ic_uuid: "53c1ca25-1761-47a9-be2c-cd3d11c5e4a4",
            ic_lat: -245148,
            ic_lon: -516663,
            ic_city: "Manoel Ribas",
            ic_states: "PARANÁ",
            ic_january: 6.01,
            ic_february: 5.707,
            ic_march: 5.131,
            ic_april: 4.45,
            ic_may: 3.483,
            ic_june: 3.088,
            ic_july: 3.334,
            ic_august: 4.33,
            ic_september: 4.521,
            ic_october: 5.189,
            ic_november: 6.018,
            ic_december: 6.239,
            ic_yearly: 4.791,
          },
          {
            ic_uuid: "2ddf32ba-88b6-4a51-8ef6-9a660b8bc2ca",
            ic_lat: -88333,
            ic_lon: -692684,
            ic_city: "Manoel Urbano",
            ic_states: "ACRE",
            ic_january: 4.458,
            ic_february: 4.702,
            ic_march: 4.22,
            ic_april: 4.375,
            ic_may: 3.967,
            ic_june: 4.104,
            ic_july: 4.381,
            ic_august: 5.014,
            ic_september: 5.12,
            ic_october: 5.003,
            ic_november: 4.802,
            ic_december: 4.515,
            ic_yearly: 4.555,
          },
          {
            ic_uuid: "d5cac1d5-dbd8-444e-a812-3f22d3ceaf1a",
            ic_lat: -295864,
            ic_lon: -554846,
            ic_city: "Manoel Viana",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.814,
            ic_february: 6.128,
            ic_march: 5.234,
            ic_april: 4.031,
            ic_may: 2.976,
            ic_june: 2.474,
            ic_july: 2.709,
            ic_august: 3.508,
            ic_september: 4.07,
            ic_october: 5.267,
            ic_november: 6.429,
            ic_december: 7.036,
            ic_yearly: 4.723,
          },
          {
            ic_uuid: "29a68115-6056-4a60-92bf-838d9e4b0a11",
            ic_lat: -141481,
            ic_lon: -402402,
            ic_city: "Manoel Vitorino",
            ic_states: "BAHIA",
            ic_january: 5.859,
            ic_february: 5.896,
            ic_march: 5.471,
            ic_april: 4.575,
            ic_may: 3.997,
            ic_june: 3.603,
            ic_july: 3.766,
            ic_august: 4.304,
            ic_september: 5.127,
            ic_october: 5.289,
            ic_november: 5.425,
            ic_december: 6.088,
            ic_yearly: 4.95,
          },
          {
            ic_uuid: "c44a3dc9-59d7-4f9f-8d72-67c8d26af672",
            ic_lat: -107231,
            ic_lon: -440432,
            ic_city: "Mansidão",
            ic_states: "BAHIA",
            ic_january: 6.019,
            ic_february: 5.87,
            ic_march: 5.611,
            ic_april: 5.441,
            ic_may: 5.353,
            ic_june: 5.243,
            ic_july: 5.459,
            ic_august: 6.134,
            ic_september: 6.475,
            ic_october: 6.21,
            ic_november: 5.831,
            ic_december: 6.058,
            ic_yearly: 5.809,
          },
          {
            ic_uuid: "43c97edb-7677-4983-b516-6502e6bf497c",
            ic_lat: -187765,
            ic_lon: -409877,
            ic_city: "Mantena",
            ic_states: "MINAS GERAIS",
            ic_january: 6.039,
            ic_february: 6.182,
            ic_march: 5.333,
            ic_april: 4.589,
            ic_may: 3.857,
            ic_june: 3.596,
            ic_july: 3.674,
            ic_august: 4.228,
            ic_september: 4.803,
            ic_october: 4.982,
            ic_november: 4.975,
            ic_december: 5.816,
            ic_yearly: 4.84,
          },
          {
            ic_uuid: "bb5b90f4-e456-4b59-9af8-cbce9b52b8a5",
            ic_lat: -188626,
            ic_lon: -411221,
            ic_city: "Mantenópolis",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.957,
            ic_february: 5.999,
            ic_march: 5.165,
            ic_april: 4.456,
            ic_may: 3.825,
            ic_june: 3.548,
            ic_july: 3.647,
            ic_august: 4.282,
            ic_september: 4.83,
            ic_october: 5.06,
            ic_november: 4.938,
            ic_december: 5.734,
            ic_yearly: 4.787,
          },
          {
            ic_uuid: "828ff9c0-2d51-4982-a0db-06567b4f6df4",
            ic_lat: -29675,
            ic_lon: -502069,
            ic_city: "Maquiné",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 5.74,
            ic_february: 5.168,
            ic_march: 4.387,
            ic_april: 3.702,
            ic_may: 2.732,
            ic_june: 2.36,
            ic_july: 2.585,
            ic_august: 3.234,
            ic_september: 3.417,
            ic_october: 4.284,
            ic_november: 5.608,
            ic_december: 5.954,
            ic_yearly: 4.098,
          },
          {
            ic_uuid: "71582691-0417-4e1d-b9ab-496ffb6dba24",
            ic_lat: -218712,
            ic_lon: -430066,
            ic_city: "Mar de Espanha",
            ic_states: "MINAS GERAIS",
            ic_january: 5.718,
            ic_february: 5.916,
            ic_march: 4.94,
            ic_april: 4.27,
            ic_may: 3.555,
            ic_june: 3.348,
            ic_july: 3.547,
            ic_august: 4.24,
            ic_september: 4.586,
            ic_october: 4.928,
            ic_november: 4.812,
            ic_december: 5.549,
            ic_yearly: 4.617,
          },
          {
            ic_uuid: "6db486cf-4025-48b3-acf2-75a31becc50a",
            ic_lat: -94478,
            ic_lon: -363884,
            ic_city: "Mar Vermelho",
            ic_states: "ALAGOAS",
            ic_january: 5.922,
            ic_february: 5.837,
            ic_march: 5.813,
            ic_april: 5.075,
            ic_may: 4.22,
            ic_june: 3.77,
            ic_july: 3.908,
            ic_august: 4.517,
            ic_september: 5.398,
            ic_october: 5.737,
            ic_november: 6.348,
            ic_december: 6.263,
            ic_yearly: 5.234,
          },
          {
            ic_uuid: "a5a195cf-5bb2-43bf-8a1d-0103eb80f6b8",
            ic_lat: -140153,
            ic_lon: -49178,
            ic_city: "Mara Rosa",
            ic_states: "GOIÁS",
            ic_january: 5.423,
            ic_february: 5.51,
            ic_march: 5.147,
            ic_april: 5.148,
            ic_may: 4.996,
            ic_june: 4.777,
            ic_july: 5.154,
            ic_august: 5.842,
            ic_september: 5.662,
            ic_october: 5.57,
            ic_november: 5.164,
            ic_december: 5.349,
            ic_yearly: 5.312,
          },
          {
            ic_uuid: "016865f8-ab3a-41ef-a032-365afb703589",
            ic_lat: -18535,
            ic_lon: -655735,
            ic_city: "Maraã",
            ic_states: "AMAZONAS",
            ic_january: 4.54,
            ic_february: 4.681,
            ic_march: 4.555,
            ic_april: 4.354,
            ic_may: 4.017,
            ic_june: 4.247,
            ic_july: 4.327,
            ic_august: 4.962,
            ic_september: 5.114,
            ic_october: 4.993,
            ic_november: 4.754,
            ic_december: 4.536,
            ic_yearly: 4.59,
          },
          {
            ic_uuid: "72b29b90-1114-4a35-9dc0-08ad15ece901",
            ic_lat: -53812,
            ic_lon: -491331,
            ic_city: "Marabá",
            ic_states: "PARÁ",
            ic_january: 4.571,
            ic_february: 4.71,
            ic_march: 4.708,
            ic_april: 4.785,
            ic_may: 4.693,
            ic_june: 5.007,
            ic_july: 5.137,
            ic_august: 5.506,
            ic_september: 5.329,
            ic_october: 4.895,
            ic_november: 4.744,
            ic_december: 4.489,
            ic_yearly: 4.881,
          },
          {
            ic_uuid: "2abeb15e-9350-4312-ae7e-ae4d667846ec",
            ic_lat: -221072,
            ic_lon: -519621,
            ic_city: "Marabá Paulista",
            ic_states: "SÃO PAULO",
            ic_january: 6.002,
            ic_february: 5.966,
            ic_march: 5.425,
            ic_april: 4.765,
            ic_may: 3.909,
            ic_june: 3.602,
            ic_july: 3.774,
            ic_august: 4.716,
            ic_september: 4.823,
            ic_october: 5.56,
            ic_november: 6.118,
            ic_december: 6.469,
            ic_yearly: 5.094,
          },
          {
            ic_uuid: "ec1a3e1d-0b6d-4a66-9449-cc699460a1b4",
            ic_lat: -20496,
            ic_lon: -459591,
            ic_city: "Maracaçumé",
            ic_states: "MARANHÃO",
            ic_january: 4.486,
            ic_february: 4.427,
            ic_march: 4.364,
            ic_april: 4.383,
            ic_may: 4.504,
            ic_june: 4.829,
            ic_july: 4.921,
            ic_august: 5.384,
            ic_september: 5.601,
            ic_october: 5.344,
            ic_november: 5.115,
            ic_december: 4.723,
            ic_yearly: 4.84,
          },
          {
            ic_uuid: "4f6c8c99-5456-4278-90ab-2d9872a962df",
            ic_lat: -226154,
            ic_lon: -506717,
            ic_city: "Maracaí",
            ic_states: "SÃO PAULO",
            ic_january: 5.797,
            ic_february: 5.776,
            ic_march: 5.282,
            ic_april: 4.732,
            ic_may: 3.807,
            ic_june: 3.505,
            ic_july: 3.708,
            ic_august: 4.679,
            ic_september: 4.81,
            ic_october: 5.438,
            ic_november: 5.99,
            ic_december: 6.252,
            ic_yearly: 4.981,
          },
          {
            ic_uuid: "4b76013e-31fe-466f-a754-c51914b776f0",
            ic_lat: -288468,
            ic_lon: -49461,
            ic_city: "Maracajá",
            ic_states: "SANTA CATARINA",
            ic_january: 5.788,
            ic_february: 5.322,
            ic_march: 4.554,
            ic_april: 3.825,
            ic_may: 2.999,
            ic_june: 2.461,
            ic_july: 2.698,
            ic_august: 3.416,
            ic_september: 3.541,
            ic_october: 4.341,
            ic_november: 5.706,
            ic_december: 5.977,
            ic_yearly: 4.219,
          },
          {
            ic_uuid: "1072bf8b-85a9-456e-bdfd-8a5bcce65b63",
            ic_lat: -21611,
            ic_lon: -551682,
            ic_city: "Maracaju",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.91,
            ic_february: 5.69,
            ic_march: 5.248,
            ic_april: 4.605,
            ic_may: 3.765,
            ic_june: 3.433,
            ic_july: 3.606,
            ic_august: 4.5,
            ic_september: 4.774,
            ic_october: 5.365,
            ic_november: 5.872,
            ic_december: 6.355,
            ic_yearly: 4.927,
          },
          {
            ic_uuid: "ef140bd8-b569-4612-89de-f5b7181d3fb5",
            ic_lat: -7715,
            ic_lon: -474516,
            ic_city: "Maracanã",
            ic_states: "PARÁ",
            ic_january: 4.62,
            ic_february: 4.316,
            ic_march: 4.2,
            ic_april: 4.222,
            ic_may: 4.53,
            ic_june: 4.777,
            ic_july: 4.926,
            ic_august: 5.251,
            ic_september: 5.496,
            ic_october: 5.409,
            ic_november: 5.302,
            ic_december: 5.0,
            ic_yearly: 4.837,
          },
          {
            ic_uuid: "9332bc7c-65aa-4402-a454-225fe4c5560d",
            ic_lat: -38674,
            ic_lon: -386262,
            ic_city: "Maracanaú",
            ic_states: "CEARÁ",
            ic_january: 5.323,
            ic_february: 5.411,
            ic_march: 5.21,
            ic_april: 4.612,
            ic_may: 4.971,
            ic_june: 4.936,
            ic_july: 5.266,
            ic_august: 5.918,
            ic_september: 6.079,
            ic_october: 6.096,
            ic_november: 6.027,
            ic_december: 5.522,
            ic_yearly: 5.448,
          },
          {
            ic_uuid: "58dd1718-872a-468e-a9b4-4e88046c893d",
            ic_lat: -134359,
            ic_lon: -404327,
            ic_city: "Maracás",
            ic_states: "BAHIA",
            ic_january: 5.72,
            ic_february: 5.809,
            ic_march: 5.401,
            ic_april: 4.456,
            ic_may: 4.007,
            ic_june: 3.705,
            ic_july: 3.884,
            ic_august: 4.508,
            ic_september: 5.439,
            ic_october: 5.478,
            ic_november: 5.404,
            ic_december: 5.855,
            ic_yearly: 4.972,
          },
          {
            ic_uuid: "a8c65e43-811c-4c23-8fc3-3a818fa60266",
            ic_lat: -90079,
            ic_lon: -35227,
            ic_city: "Maragogi",
            ic_states: "ALAGOAS",
            ic_january: 5.958,
            ic_february: 5.937,
            ic_march: 6.044,
            ic_april: 5.106,
            ic_may: 4.398,
            ic_june: 4.107,
            ic_july: 4.164,
            ic_august: 4.884,
            ic_september: 5.466,
            ic_october: 5.832,
            ic_november: 6.143,
            ic_december: 6.189,
            ic_yearly: 5.352,
          },
          {
            ic_uuid: "ef15e28c-5a26-41f1-988d-65f607177ce9",
            ic_lat: -127765,
            ic_lon: -389178,
            ic_city: "Maragogipe",
            ic_states: "BAHIA",
            ic_january: 6.228,
            ic_february: 6.021,
            ic_march: 5.793,
            ic_april: 4.651,
            ic_may: 3.971,
            ic_june: 3.612,
            ic_july: 3.864,
            ic_august: 4.389,
            ic_september: 5.254,
            ic_october: 5.566,
            ic_november: 5.897,
            ic_december: 6.239,
            ic_yearly: 5.124,
          },
          {
            ic_uuid: "c74fa76b-5925-40ab-a75a-56cb33b4b426",
            ic_lat: -87911,
            ic_lon: -358269,
            ic_city: "Maraial",
            ic_states: "PERNAMBUCO",
            ic_january: 5.648,
            ic_february: 5.616,
            ic_march: 5.656,
            ic_april: 5.031,
            ic_may: 4.181,
            ic_june: 3.739,
            ic_july: 3.839,
            ic_august: 4.425,
            ic_september: 5.234,
            ic_october: 5.481,
            ic_november: 5.966,
            ic_december: 5.94,
            ic_yearly: 5.063,
          },
          {
            ic_uuid: "6f934240-0352-4f31-a163-cae35682b86f",
            ic_lat: -46289,
            ic_lon: -454568,
            ic_city: "Marajá do Sena",
            ic_states: "MARANHÃO",
            ic_january: 4.631,
            ic_february: 4.837,
            ic_march: 4.921,
            ic_april: 4.892,
            ic_may: 4.807,
            ic_june: 5.014,
            ic_july: 5.175,
            ic_august: 5.566,
            ic_september: 5.846,
            ic_october: 5.444,
            ic_november: 5.042,
            ic_december: 4.808,
            ic_yearly: 5.082,
          },
          {
            ic_uuid: "cc1fc89c-7abc-45de-8a9c-549c61f2cb31",
            ic_lat: -38918,
            ic_lon: -386832,
            ic_city: "Maranguape",
            ic_states: "CEARÁ",
            ic_january: 5.323,
            ic_february: 5.411,
            ic_march: 5.21,
            ic_april: 4.612,
            ic_may: 4.971,
            ic_june: 4.936,
            ic_july: 5.266,
            ic_august: 5.918,
            ic_september: 6.079,
            ic_october: 6.096,
            ic_november: 6.027,
            ic_december: 5.522,
            ic_yearly: 5.448,
          },
          {
            ic_uuid: "bb9d5496-ec5d-4a3b-8083-3bfc090c5e8b",
            ic_lat: -22411,
            ic_lon: -458575,
            ic_city: "Maranhãozinho",
            ic_states: "MARANHÃO",
            ic_january: 4.562,
            ic_february: 4.462,
            ic_march: 4.395,
            ic_april: 4.508,
            ic_may: 4.56,
            ic_june: 4.868,
            ic_july: 4.987,
            ic_august: 5.511,
            ic_september: 5.727,
            ic_october: 5.463,
            ic_november: 5.173,
            ic_december: 4.738,
            ic_yearly: 4.913,
          },
          {
            ic_uuid: "b950ba26-575a-4522-a0e4-f5282105f452",
            ic_lat: -7114,
            ic_lon: -476964,
            ic_city: "Marapanim",
            ic_states: "PARÁ",
            ic_january: 4.742,
            ic_february: 4.349,
            ic_march: 4.265,
            ic_april: 4.29,
            ic_may: 4.601,
            ic_june: 4.867,
            ic_july: 5.014,
            ic_august: 5.349,
            ic_september: 5.603,
            ic_october: 5.549,
            ic_november: 5.48,
            ic_december: 5.099,
            ic_yearly: 4.934,
          },
          {
            ic_uuid: "927e0d89-6cf8-4b76-9bee-40dba6f40a5b",
            ic_lat: -212592,
            ic_lon: -491304,
            ic_city: "Marapoama",
            ic_states: "SÃO PAULO",
            ic_january: 5.852,
            ic_february: 5.91,
            ic_march: 5.252,
            ic_april: 4.795,
            ic_may: 4.048,
            ic_june: 3.811,
            ic_july: 4.029,
            ic_august: 4.941,
            ic_september: 5.039,
            ic_october: 5.624,
            ic_november: 5.874,
            ic_december: 6.161,
            ic_yearly: 5.111,
          },
          {
            ic_uuid: "5e04a0e7-730a-4b3c-831a-3a8a8aeb305e",
            ic_lat: -295496,
            ic_lon: -515557,
            ic_city: "Maratá",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.149,
            ic_february: 5.656,
            ic_march: 4.749,
            ic_april: 3.776,
            ic_may: 2.784,
            ic_june: 2.341,
            ic_july: 2.566,
            ic_august: 3.231,
            ic_september: 3.523,
            ic_october: 4.555,
            ic_november: 6.035,
            ic_december: 6.403,
            ic_yearly: 4.314,
          },
          {
            ic_uuid: "c9afb7f8-6f19-4420-bd81-cfbfcd0b285c",
            ic_lat: -210403,
            ic_lon: -408388,
            ic_city: "Marataízes",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.537,
            ic_february: 6.627,
            ic_march: 5.341,
            ic_april: 4.566,
            ic_may: 3.831,
            ic_june: 3.646,
            ic_july: 3.703,
            ic_august: 4.537,
            ic_september: 4.965,
            ic_october: 5.29,
            ic_november: 5.174,
            ic_december: 6.036,
            ic_yearly: 5.021,
          },
          {
            ic_uuid: "77bfbba5-426e-43be-ae65-e1ef763c4e99",
            ic_lat: -284502,
            ic_lon: -521991,
            ic_city: "Marau",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.191,
            ic_february: 5.759,
            ic_march: 4.923,
            ic_april: 3.99,
            ic_may: 2.995,
            ic_june: 2.487,
            ic_july: 2.817,
            ic_august: 3.555,
            ic_september: 3.822,
            ic_october: 4.989,
            ic_november: 6.209,
            ic_december: 6.556,
            ic_yearly: 4.524,
          },
          {
            ic_uuid: "63cf7f24-f879-4390-b7ec-30875f0bfcd7",
            ic_lat: -14104,
            ic_lon: -39014,
            ic_city: "Maraú",
            ic_states: "BAHIA",
            ic_january: 5.984,
            ic_february: 6.027,
            ic_march: 5.647,
            ic_april: 4.567,
            ic_may: 3.971,
            ic_june: 3.629,
            ic_july: 3.854,
            ic_august: 4.436,
            ic_september: 5.033,
            ic_october: 5.281,
            ic_november: 5.526,
            ic_december: 6.182,
            ic_yearly: 5.011,
          },
          {
            ic_uuid: "4f31e154-22f1-472d-9c9d-cdd838e8f67b",
            ic_lat: -92309,
            ic_lon: -373527,
            ic_city: "Maravilha",
            ic_states: "ALAGOAS",
            ic_january: 6.221,
            ic_february: 6.029,
            ic_march: 6.022,
            ic_april: 5.305,
            ic_may: 4.404,
            ic_june: 4.01,
            ic_july: 4.216,
            ic_august: 4.824,
            ic_september: 5.745,
            ic_october: 6.141,
            ic_november: 6.511,
            ic_december: 6.417,
            ic_yearly: 5.487,
          },
          {
            ic_uuid: "f0976459-f781-498c-a2b4-c936010347f8",
            ic_lat: -26767,
            ic_lon: -531742,
            ic_city: "Maravilha",
            ic_states: "SANTA CATARINA",
            ic_january: 6.215,
            ic_february: 5.748,
            ic_march: 5.067,
            ic_april: 4.128,
            ic_may: 3.173,
            ic_june: 2.664,
            ic_july: 2.97,
            ic_august: 3.826,
            ic_september: 4.084,
            ic_october: 5.105,
            ic_november: 6.049,
            ic_december: 6.38,
            ic_yearly: 4.617,
          },
          {
            ic_uuid: "6ba2b98c-6181-449e-9726-93fc57d45734",
            ic_lat: -19516,
            ic_lon: -44676,
            ic_city: "Maravilhas",
            ic_states: "MINAS GERAIS",
            ic_january: 6.001,
            ic_february: 6.079,
            ic_march: 5.242,
            ic_april: 4.967,
            ic_may: 4.417,
            ic_june: 4.188,
            ic_july: 4.489,
            ic_august: 5.35,
            ic_september: 5.613,
            ic_october: 5.651,
            ic_november: 5.424,
            ic_december: 5.749,
            ic_yearly: 5.264,
          },
          {
            ic_uuid: "1b4bc20d-0a1b-4978-bfec-6a122c6f2c52",
            ic_lat: -67658,
            ic_lon: -350089,
            ic_city: "Marcação",
            ic_states: "PARAÍBA",
            ic_january: 5.86,
            ic_february: 5.965,
            ic_march: 6.069,
            ic_april: 5.368,
            ic_may: 4.758,
            ic_june: 4.291,
            ic_july: 4.341,
            ic_august: 5.185,
            ic_september: 5.612,
            ic_october: 5.937,
            ic_november: 6.205,
            ic_december: 6.078,
            ic_yearly: 5.472,
          },
          {
            ic_uuid: "2b6bae04-feb5-438e-a108-580ec0b0e399",
            ic_lat: -110835,
            ic_lon: -54521,
            ic_city: "Marcelândia",
            ic_states: "MATO GROSSO",
            ic_january: 5.038,
            ic_february: 4.975,
            ic_march: 4.811,
            ic_april: 4.83,
            ic_may: 4.757,
            ic_june: 4.662,
            ic_july: 5.121,
            ic_august: 5.53,
            ic_september: 5.115,
            ic_october: 5.147,
            ic_november: 5.039,
            ic_december: 5.053,
            ic_yearly: 5.006,
          },
          {
            ic_uuid: "7336054d-b8d5-4865-af6f-659d5bba3566",
            ic_lat: -274681,
            ic_lon: -5191,
            ic_city: "Marcelino Ramos",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.315,
            ic_february: 5.811,
            ic_march: 5.087,
            ic_april: 4.061,
            ic_may: 3.063,
            ic_june: 2.554,
            ic_july: 2.886,
            ic_august: 3.726,
            ic_september: 3.979,
            ic_october: 5.089,
            ic_november: 6.3,
            ic_december: 6.587,
            ic_yearly: 4.621,
          },
          {
            ic_uuid: "f6ac3729-8176-4906-9e6a-eb3161ca0e83",
            ic_lat: -6285,
            ic_lon: -381645,
            ic_city: "Marcelino Vieira",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.045,
            ic_february: 6.02,
            ic_march: 6.064,
            ic_april: 5.776,
            ic_may: 5.25,
            ic_june: 4.931,
            ic_july: 5.295,
            ic_august: 6.037,
            ic_september: 6.461,
            ic_october: 6.573,
            ic_november: 6.624,
            ic_december: 6.165,
            ic_yearly: 5.937,
          },
          {
            ic_uuid: "8a64d5d7-1ca0-427f-9ed7-3a0b3919c72a",
            ic_lat: -130069,
            ic_lon: -405298,
            ic_city: "Marcionílio Souza",
            ic_states: "BAHIA",
            ic_january: 6.046,
            ic_february: 6.098,
            ic_march: 5.743,
            ic_april: 4.783,
            ic_may: 4.196,
            ic_june: 3.769,
            ic_july: 4.008,
            ic_august: 4.585,
            ic_september: 5.483,
            ic_october: 5.588,
            ic_november: 5.648,
            ic_december: 6.048,
            ic_yearly: 5.166,
          },
          {
            ic_uuid: "5213351f-b4d0-4e4e-a999-3d3810e7491b",
            ic_lat: -31208,
            ic_lon: -401542,
            ic_city: "Marco",
            ic_states: "CEARÁ",
            ic_january: 5.222,
            ic_february: 5.174,
            ic_march: 4.987,
            ic_april: 4.584,
            ic_may: 5.127,
            ic_june: 5.274,
            ic_july: 5.39,
            ic_august: 6.044,
            ic_september: 6.37,
            ic_october: 6.242,
            ic_november: 6.193,
            ic_december: 5.694,
            ic_yearly: 5.525,
          },
          {
            ic_uuid: "afb5a232-2b9a-4265-8322-0301b65504fd",
            ic_lat: -74421,
            ic_lon: -406605,
            ic_city: "Marcolândia",
            ic_states: "PIAUÍ",
            ic_january: 5.54,
            ic_february: 5.492,
            ic_march: 5.452,
            ic_april: 5.345,
            ic_may: 5.223,
            ic_june: 5.184,
            ic_july: 5.515,
            ic_august: 6.066,
            ic_september: 6.53,
            ic_october: 6.513,
            ic_november: 6.37,
            ic_december: 5.98,
            ic_yearly: 5.768,
          },
          {
            ic_uuid: "f951d820-a5fc-4455-b5d5-d311c5364d09",
            ic_lat: -71161,
            ic_lon: -43893,
            ic_city: "Marcos Parente",
            ic_states: "PIAUÍ",
            ic_january: 5.118,
            ic_february: 5.093,
            ic_march: 5.068,
            ic_april: 5.06,
            ic_may: 5.249,
            ic_june: 5.404,
            ic_july: 5.689,
            ic_august: 6.152,
            ic_september: 6.639,
            ic_october: 6.255,
            ic_november: 5.771,
            ic_december: 5.429,
            ic_yearly: 5.577,
          },
          {
            ic_uuid: "1122a0b1-4e05-46fa-8357-dd60ef88b059",
            ic_lat: -245584,
            ic_lon: -540566,
            ic_city: "Marechal Cândido Rondon",
            ic_states: "PARANÁ",
            ic_january: 6.231,
            ic_february: 5.721,
            ic_march: 5.277,
            ic_april: 4.312,
            ic_may: 3.375,
            ic_june: 2.987,
            ic_july: 3.192,
            ic_august: 4.178,
            ic_september: 4.444,
            ic_october: 5.286,
            ic_november: 6.131,
            ic_december: 6.541,
            ic_yearly: 4.806,
          },
          {
            ic_uuid: "be5f0a15-0cbb-4151-b0de-62901c643f96",
            ic_lat: -97101,
            ic_lon: -35897,
            ic_city: "Marechal Deodoro",
            ic_states: "ALAGOAS",
            ic_january: 6.147,
            ic_february: 6.103,
            ic_march: 6.033,
            ic_april: 5.183,
            ic_may: 4.401,
            ic_june: 4.066,
            ic_july: 4.216,
            ic_august: 4.893,
            ic_september: 5.597,
            ic_october: 6.004,
            ic_november: 6.355,
            ic_december: 6.415,
            ic_yearly: 5.451,
          },
          {
            ic_uuid: "be0e01f6-038d-4c87-aab6-ca8db6921ada",
            ic_lat: -204123,
            ic_lon: -406754,
            ic_city: "Marechal Floriano",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.853,
            ic_february: 6.143,
            ic_march: 5.056,
            ic_april: 4.335,
            ic_may: 3.734,
            ic_june: 3.509,
            ic_july: 3.569,
            ic_august: 4.214,
            ic_september: 4.695,
            ic_october: 4.755,
            ic_november: 4.689,
            ic_december: 5.527,
            ic_yearly: 4.673,
          },
          {
            ic_uuid: "9ae58075-6467-491d-bac2-42989092a16e",
            ic_lat: -89394,
            ic_lon: -728002,
            ic_city: "Marechal Thaumaturgo",
            ic_states: "ACRE",
            ic_january: 4.717,
            ic_february: 4.931,
            ic_march: 4.178,
            ic_april: 4.514,
            ic_may: 4.261,
            ic_june: 4.149,
            ic_july: 4.357,
            ic_august: 5.063,
            ic_september: 5.333,
            ic_october: 5.149,
            ic_november: 5.067,
            ic_december: 4.741,
            ic_yearly: 4.705,
          },
          {
            ic_uuid: "3781f4ed-ddd2-44b3-baf5-1188f1d432a3",
            ic_lat: -268028,
            ic_lon: -526269,
            ic_city: "Marema",
            ic_states: "SANTA CATARINA",
            ic_january: 6.317,
            ic_february: 5.75,
            ic_march: 5.211,
            ic_april: 4.18,
            ic_may: 3.169,
            ic_june: 2.69,
            ic_july: 2.961,
            ic_august: 3.853,
            ic_september: 4.092,
            ic_october: 5.138,
            ic_november: 6.207,
            ic_december: 6.426,
            ic_yearly: 4.666,
          },
          {
            ic_uuid: "43aa7599-1b12-43b4-b817-9319b43007ca",
            ic_lat: -70598,
            ic_lon: -353183,
            ic_city: "Mari",
            ic_states: "PARAÍBA",
            ic_january: 5.701,
            ic_february: 5.754,
            ic_march: 5.881,
            ic_april: 5.321,
            ic_may: 4.663,
            ic_june: 4.271,
            ic_july: 4.358,
            ic_august: 5.155,
            ic_september: 5.556,
            ic_october: 5.829,
            ic_november: 6.099,
            ic_december: 5.943,
            ic_yearly: 5.378,
          },
          {
            ic_uuid: "3028ee5a-80cb-4a79-a91e-dfa797048ae2",
            ic_lat: -223049,
            ic_lon: -453777,
            ic_city: "Maria da Fé",
            ic_states: "MINAS GERAIS",
            ic_january: 5.25,
            ic_february: 5.499,
            ic_march: 4.749,
            ic_april: 4.437,
            ic_may: 3.814,
            ic_june: 3.615,
            ic_july: 3.839,
            ic_august: 4.739,
            ic_september: 4.932,
            ic_october: 5.285,
            ic_november: 5.203,
            ic_december: 5.515,
            ic_yearly: 4.74,
          },
          {
            ic_uuid: "294a4608-a505-4c63-af57-e7f0d923dbb7",
            ic_lat: -236162,
            ic_lon: -532058,
            ic_city: "Maria Helena",
            ic_states: "PARANÁ",
            ic_january: 6.077,
            ic_february: 5.87,
            ic_march: 5.367,
            ic_april: 4.532,
            ic_may: 3.566,
            ic_june: 3.186,
            ic_july: 3.4,
            ic_august: 4.373,
            ic_september: 4.595,
            ic_october: 5.414,
            ic_november: 6.15,
            ic_december: 6.479,
            ic_yearly: 4.917,
          },
          {
            ic_uuid: "3bb9f596-c6f5-4419-b10a-10c0ab36cee8",
            ic_lat: -234848,
            ic_lon: -517932,
            ic_city: "Marialva",
            ic_states: "PARANÁ",
            ic_january: 5.826,
            ic_february: 5.811,
            ic_march: 5.268,
            ic_april: 4.579,
            ic_may: 3.622,
            ic_june: 3.303,
            ic_july: 3.52,
            ic_august: 4.465,
            ic_september: 4.662,
            ic_october: 5.327,
            ic_november: 5.986,
            ic_december: 6.293,
            ic_yearly: 4.888,
          },
          {
            ic_uuid: "532c344a-fde6-4743-bf7e-5f8606fb9549",
            ic_lat: -20377,
            ic_lon: -434144,
            ic_city: "Mariana",
            ic_states: "MINAS GERAIS",
            ic_january: 5.226,
            ic_february: 5.533,
            ic_march: 4.567,
            ic_april: 4.215,
            ic_may: 3.75,
            ic_june: 3.607,
            ic_july: 3.792,
            ic_august: 4.659,
            ic_september: 4.856,
            ic_october: 4.855,
            ic_november: 4.523,
            ic_december: 5.092,
            ic_yearly: 4.556,
          },
          {
            ic_uuid: "c7161b30-9d2e-49c4-9b4a-daca78c6cff5",
            ic_lat: -30353,
            ic_lon: -515829,
            ic_city: "Mariana Pimentel",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.245,
            ic_february: 5.591,
            ic_march: 4.718,
            ic_april: 3.718,
            ic_may: 2.752,
            ic_june: 2.318,
            ic_july: 2.513,
            ic_august: 3.131,
            ic_september: 3.54,
            ic_october: 4.684,
            ic_november: 6.077,
            ic_december: 6.486,
            ic_yearly: 4.314,
          },
          {
            ic_uuid: "7eb1eb93-a8b6-4834-90d3-ce6ddb7a72ef",
            ic_lat: -273572,
            ic_lon: -521471,
            ic_city: "Mariano Moro",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.377,
            ic_february: 5.884,
            ic_march: 5.146,
            ic_april: 4.061,
            ic_may: 3.061,
            ic_june: 2.562,
            ic_july: 2.882,
            ic_august: 3.745,
            ic_september: 3.99,
            ic_october: 5.11,
            ic_november: 6.279,
            ic_december: 6.627,
            ic_yearly: 4.644,
          },
          {
            ic_uuid: "6726e49d-a9fb-4162-8d4f-d93b6b8c3016",
            ic_lat: -97942,
            ic_lon: -496557,
            ic_city: "Marianópolis do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 4.999,
            ic_february: 5.017,
            ic_march: 4.771,
            ic_april: 4.716,
            ic_may: 4.87,
            ic_june: 5.035,
            ic_july: 5.314,
            ic_august: 5.899,
            ic_september: 5.517,
            ic_october: 5.191,
            ic_november: 5.036,
            ic_december: 5.053,
            ic_yearly: 5.118,
          },
          {
            ic_uuid: "25c320ee-01a1-4f54-8669-13a1225f8d9f",
            ic_lat: -217964,
            ic_lon: -511829,
            ic_city: "Mariápolis",
            ic_states: "SÃO PAULO",
            ic_january: 5.944,
            ic_february: 5.976,
            ic_march: 5.475,
            ic_april: 4.832,
            ic_may: 3.994,
            ic_june: 3.673,
            ic_july: 3.853,
            ic_august: 4.83,
            ic_september: 4.854,
            ic_october: 5.557,
            ic_november: 6.057,
            ic_december: 6.373,
            ic_yearly: 5.118,
          },
          {
            ic_uuid: "98df2e1f-283c-480d-9c47-36f46ead2e4e",
            ic_lat: -9584,
            ic_lon: -363048,
            ic_city: "Maribondo",
            ic_states: "ALAGOAS",
            ic_january: 5.958,
            ic_february: 5.799,
            ic_march: 5.772,
            ic_april: 5.081,
            ic_may: 4.198,
            ic_june: 3.832,
            ic_july: 3.924,
            ic_august: 4.532,
            ic_september: 5.393,
            ic_october: 5.729,
            ic_november: 6.295,
            ic_december: 6.283,
            ic_yearly: 5.233,
          },
          {
            ic_uuid: "d4c834a1-df0d-417e-8952-6a3fe39691d8",
            ic_lat: -229359,
            ic_lon: -42825,
            ic_city: "Maricá",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.189,
            ic_february: 6.373,
            ic_march: 5.123,
            ic_april: 4.418,
            ic_may: 3.594,
            ic_june: 3.344,
            ic_july: 3.371,
            ic_august: 4.213,
            ic_september: 4.447,
            ic_october: 5.071,
            ic_november: 5.22,
            ic_december: 6.053,
            ic_yearly: 4.785,
          },
          {
            ic_uuid: "49b110e6-e207-477d-9864-1910833638e8",
            ic_lat: -185084,
            ic_lon: -420826,
            ic_city: "Marilac",
            ic_states: "MINAS GERAIS",
            ic_january: 6.01,
            ic_february: 6.227,
            ic_march: 5.368,
            ic_april: 4.642,
            ic_may: 3.946,
            ic_june: 3.737,
            ic_july: 3.806,
            ic_august: 4.501,
            ic_september: 4.97,
            ic_october: 5.258,
            ic_november: 4.935,
            ic_december: 5.863,
            ic_yearly: 4.939,
          },
          {
            ic_uuid: "d0e67d41-adb1-4b0d-ba78-14966868f1ac",
            ic_lat: -194118,
            ic_lon: -40546,
            ic_city: "Marilândia",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.989,
            ic_february: 6.093,
            ic_march: 5.215,
            ic_april: 4.448,
            ic_may: 3.82,
            ic_june: 3.554,
            ic_july: 3.606,
            ic_august: 4.204,
            ic_september: 4.67,
            ic_october: 4.857,
            ic_november: 4.86,
            ic_december: 5.717,
            ic_yearly: 4.753,
          },
          {
            ic_uuid: "1f38d13a-3f79-464e-bf81-cb162ff75ec2",
            ic_lat: -23743,
            ic_lon: -513142,
            ic_city: "Marilândia do Sul",
            ic_states: "PARANÁ",
            ic_january: 5.87,
            ic_february: 5.637,
            ic_march: 5.254,
            ic_april: 4.565,
            ic_may: 3.573,
            ic_june: 3.249,
            ic_july: 3.468,
            ic_august: 4.45,
            ic_september: 4.615,
            ic_october: 5.244,
            ic_november: 5.956,
            ic_december: 6.218,
            ic_yearly: 4.842,
          },
          {
            ic_uuid: "220a72ac-638d-4725-ba56-7ecaa579aa87",
            ic_lat: -22734,
            ic_lon: -530407,
            ic_city: "Marilena",
            ic_states: "PARANÁ",
            ic_january: 6.099,
            ic_february: 6.046,
            ic_march: 5.508,
            ic_april: 4.69,
            ic_may: 3.722,
            ic_june: 3.378,
            ic_july: 3.568,
            ic_august: 4.457,
            ic_september: 4.755,
            ic_october: 5.457,
            ic_november: 6.191,
            ic_december: 6.561,
            ic_yearly: 5.036,
          },
          {
            ic_uuid: "d197e701-8540-4a2e-a0f9-1b6cb832353b",
            ic_lat: -222176,
            ic_lon: -499505,
            ic_city: "Marília",
            ic_states: "SÃO PAULO",
            ic_january: 5.715,
            ic_february: 5.872,
            ic_march: 5.27,
            ic_april: 4.761,
            ic_may: 3.841,
            ic_june: 3.554,
            ic_july: 3.813,
            ic_august: 4.776,
            ic_september: 4.833,
            ic_october: 5.525,
            ic_november: 5.948,
            ic_december: 6.169,
            ic_yearly: 5.007,
          },
          {
            ic_uuid: "713bd3c1-32a9-48a7-a05b-988c15012602",
            ic_lat: -240094,
            ic_lon: -531437,
            ic_city: "Mariluz",
            ic_states: "PARANÁ",
            ic_january: 6.106,
            ic_february: 5.819,
            ic_march: 5.37,
            ic_april: 4.487,
            ic_may: 3.537,
            ic_june: 3.124,
            ic_july: 3.322,
            ic_august: 4.305,
            ic_september: 4.56,
            ic_october: 5.386,
            ic_november: 6.157,
            ic_december: 6.499,
            ic_yearly: 4.889,
          },
          {
            ic_uuid: "e1dde9e2-04e1-4d8f-b0c7-62866c154cee",
            ic_lat: -23421,
            ic_lon: -519337,
            ic_city: "Maringá",
            ic_states: "PARANÁ",
            ic_january: 5.888,
            ic_february: 5.792,
            ic_march: 5.219,
            ic_april: 4.544,
            ic_may: 3.636,
            ic_june: 3.333,
            ic_july: 3.529,
            ic_august: 4.451,
            ic_september: 4.683,
            ic_october: 5.311,
            ic_november: 5.969,
            ic_december: 6.327,
            ic_yearly: 4.89,
          },
          {
            ic_uuid: "0f6589d7-b6e0-4a4a-898a-a368d399a05e",
            ic_lat: -20441,
            ic_lon: -508229,
            ic_city: "Marinópolis",
            ic_states: "SÃO PAULO",
            ic_january: 5.851,
            ic_february: 5.982,
            ic_march: 5.367,
            ic_april: 4.873,
            ic_may: 4.195,
            ic_june: 3.887,
            ic_july: 4.109,
            ic_august: 5.052,
            ic_september: 5.061,
            ic_october: 5.698,
            ic_november: 6.008,
            ic_december: 6.346,
            ic_yearly: 5.202,
          },
          {
            ic_uuid: "07521141-de9e-44df-8ba7-1a9fafcf1d41",
            ic_lat: -200586,
            ic_lon: -441887,
            ic_city: "Mário Campos",
            ic_states: "MINAS GERAIS",
            ic_january: 5.858,
            ic_february: 5.92,
            ic_march: 5.131,
            ic_april: 4.78,
            ic_may: 4.202,
            ic_june: 4.04,
            ic_july: 4.269,
            ic_august: 5.184,
            ic_september: 5.49,
            ic_october: 5.585,
            ic_november: 5.282,
            ic_december: 5.681,
            ic_yearly: 5.118,
          },
          {
            ic_uuid: "b3ce0cb5-73e7-4e4a-87eb-65cec37e6972",
            ic_lat: -263515,
            ic_lon: -525502,
            ic_city: "Mariópolis",
            ic_states: "PARANÁ",
            ic_january: 6.178,
            ic_february: 5.638,
            ic_march: 5.11,
            ic_april: 4.177,
            ic_may: 3.251,
            ic_june: 2.788,
            ic_july: 3.078,
            ic_august: 4.023,
            ic_september: 4.181,
            ic_october: 5.136,
            ic_november: 6.099,
            ic_december: 6.306,
            ic_yearly: 4.664,
          },
          {
            ic_uuid: "a8f61796-52fe-410b-9c75-b075b19a317d",
            ic_lat: -244204,
            ic_lon: -538291,
            ic_city: "Maripá",
            ic_states: "PARANÁ",
            ic_january: 6.172,
            ic_february: 5.658,
            ic_march: 5.274,
            ic_april: 4.402,
            ic_may: 3.436,
            ic_june: 3.045,
            ic_july: 3.234,
            ic_august: 4.224,
            ic_september: 4.522,
            ic_october: 5.322,
            ic_november: 6.152,
            ic_december: 6.564,
            ic_yearly: 4.834,
          },
          {
            ic_uuid: "04b5433c-feee-4625-bab2-3de3436ea69e",
            ic_lat: -216984,
            ic_lon: -42955,
            ic_city: "Maripá de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.739,
            ic_february: 5.978,
            ic_march: 4.959,
            ic_april: 4.275,
            ic_may: 3.606,
            ic_june: 3.344,
            ic_july: 3.535,
            ic_august: 4.296,
            ic_september: 4.648,
            ic_october: 4.954,
            ic_november: 4.765,
            ic_december: 5.608,
            ic_yearly: 4.642,
          },
          {
            ic_uuid: "f18c7e79-2ee0-436c-9b30-31314a0f2865",
            ic_lat: -13647,
            ic_lon: -48331,
            ic_city: "Marituba",
            ic_states: "PARÁ",
            ic_january: 4.293,
            ic_february: 4.279,
            ic_march: 4.274,
            ic_april: 4.311,
            ic_may: 4.598,
            ic_june: 4.936,
            ic_july: 5.038,
            ic_august: 5.221,
            ic_september: 5.29,
            ic_october: 5.064,
            ic_november: 4.926,
            ic_december: 4.539,
            ic_yearly: 4.731,
          },
          {
            ic_uuid: "7e17736c-eaeb-48a0-84fc-85a293690380",
            ic_lat: -68458,
            ic_lon: -383486,
            ic_city: "Marizópolis",
            ic_states: "PARAÍBA",
            ic_january: 6.19,
            ic_february: 6.149,
            ic_march: 6.19,
            ic_april: 5.828,
            ic_may: 5.302,
            ic_june: 5.047,
            ic_july: 5.308,
            ic_august: 6.051,
            ic_september: 6.512,
            ic_october: 6.676,
            ic_november: 6.819,
            ic_december: 6.472,
            ic_yearly: 6.046,
          },
          {
            ic_uuid: "14cbbe1c-5457-47fa-aa41-2348d4c811c0",
            ic_lat: -197101,
            ic_lon: -427331,
            ic_city: "Marliéria",
            ic_states: "MINAS GERAIS",
            ic_january: 5.662,
            ic_february: 5.963,
            ic_march: 4.947,
            ic_april: 4.537,
            ic_may: 3.82,
            ic_june: 3.643,
            ic_july: 3.862,
            ic_august: 4.54,
            ic_september: 4.869,
            ic_october: 5.053,
            ic_november: 4.716,
            ic_december: 5.518,
            ic_yearly: 4.761,
          },
          {
            ic_uuid: "d9184e87-3008-4dc2-8227-1ef7a4dd9e56",
            ic_lat: -261477,
            ic_lon: -530272,
            ic_city: "Marmeleiro",
            ic_states: "PARANÁ",
            ic_january: 6.21,
            ic_february: 5.657,
            ic_march: 5.181,
            ic_april: 4.198,
            ic_may: 3.267,
            ic_june: 2.813,
            ic_july: 3.109,
            ic_august: 4.068,
            ic_september: 4.265,
            ic_october: 5.207,
            ic_november: 6.09,
            ic_december: 6.366,
            ic_yearly: 4.703,
          },
          {
            ic_uuid: "958e3b6a-8ea7-484c-8dc2-1c8bbd4a74e5",
            ic_lat: -224475,
            ic_lon: -451649,
            ic_city: "Marmelópolis",
            ic_states: "MINAS GERAIS",
            ic_january: 5.006,
            ic_february: 5.37,
            ic_march: 4.589,
            ic_april: 4.319,
            ic_may: 3.751,
            ic_june: 3.526,
            ic_july: 3.752,
            ic_august: 4.698,
            ic_september: 4.907,
            ic_october: 5.184,
            ic_november: 4.997,
            ic_december: 5.388,
            ic_yearly: 4.624,
          },
          {
            ic_uuid: "2ded8a64-10e6-4c96-8289-a5dab28c90bf",
            ic_lat: -293298,
            ic_lon: -520976,
            ic_city: "Marques de Souza",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.087,
            ic_february: 5.708,
            ic_march: 4.785,
            ic_april: 3.789,
            ic_may: 2.82,
            ic_june: 2.354,
            ic_july: 2.607,
            ic_august: 3.329,
            ic_september: 3.592,
            ic_october: 4.658,
            ic_november: 6.046,
            ic_december: 6.438,
            ic_yearly: 4.351,
          },
          {
            ic_uuid: "c0b16d66-3b3a-4ab4-a126-417c945d214e",
            ic_lat: -251125,
            ic_lon: -522501,
            ic_city: "Marquinho",
            ic_states: "PARANÁ",
            ic_january: 6.099,
            ic_february: 5.627,
            ic_march: 5.234,
            ic_april: 4.388,
            ic_may: 3.383,
            ic_june: 2.986,
            ic_july: 3.24,
            ic_august: 4.242,
            ic_september: 4.416,
            ic_october: 5.241,
            ic_november: 6.073,
            ic_december: 6.275,
            ic_yearly: 4.767,
          },
          {
            ic_uuid: "4a7bcce2-b127-453f-ad39-da1f28cc45bb",
            ic_lat: -193211,
            ic_lon: -452384,
            ic_city: "Martinho Campos",
            ic_states: "MINAS GERAIS",
            ic_january: 5.894,
            ic_february: 6.134,
            ic_march: 5.225,
            ic_april: 5.033,
            ic_may: 4.517,
            ic_june: 4.303,
            ic_july: 4.571,
            ic_august: 5.453,
            ic_september: 5.66,
            ic_october: 5.683,
            ic_november: 5.448,
            ic_december: 5.754,
            ic_yearly: 5.306,
          },
          {
            ic_uuid: "26cae1f8-1d90-4ea6-b999-920ab87a0ed7",
            ic_lat: -32234,
            ic_lon: -40695,
            ic_city: "Martinópole",
            ic_states: "CEARÁ",
            ic_january: 5.09,
            ic_february: 5.027,
            ic_march: 5.017,
            ic_april: 4.781,
            ic_may: 5.168,
            ic_june: 5.271,
            ic_july: 5.387,
            ic_august: 6.055,
            ic_september: 6.533,
            ic_october: 6.456,
            ic_november: 6.153,
            ic_december: 5.438,
            ic_yearly: 5.531,
          },
          {
            ic_uuid: "0970830e-9d4d-47f7-8b39-649fa4cfb5dd",
            ic_lat: -221466,
            ic_lon: -511714,
            ic_city: "Martinópolis",
            ic_states: "SÃO PAULO",
            ic_january: 5.985,
            ic_february: 5.939,
            ic_march: 5.461,
            ic_april: 4.819,
            ic_may: 3.937,
            ic_june: 3.627,
            ic_july: 3.817,
            ic_august: 4.797,
            ic_september: 4.835,
            ic_october: 5.554,
            ic_november: 6.086,
            ic_december: 6.422,
            ic_yearly: 5.107,
          },
          {
            ic_uuid: "eaa77842-bf57-492c-a69f-33e3e6c23927",
            ic_lat: -60832,
            ic_lon: -379083,
            ic_city: "Martins",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.102,
            ic_february: 6.008,
            ic_march: 6.03,
            ic_april: 5.763,
            ic_may: 5.318,
            ic_june: 5.057,
            ic_july: 5.417,
            ic_august: 6.103,
            ic_september: 6.521,
            ic_october: 6.679,
            ic_november: 6.712,
            ic_december: 6.19,
            ic_yearly: 5.992,
          },
          {
            ic_uuid: "8eabdd5d-7eff-4b25-bfca-a773f895db49",
            ic_lat: -20255,
            ic_lon: -418789,
            ic_city: "Martins Soares",
            ic_states: "MINAS GERAIS",
            ic_january: 5.598,
            ic_february: 5.84,
            ic_march: 4.854,
            ic_april: 4.42,
            ic_may: 3.776,
            ic_june: 3.571,
            ic_july: 3.73,
            ic_august: 4.443,
            ic_september: 4.729,
            ic_october: 4.814,
            ic_november: 4.653,
            ic_december: 5.347,
            ic_yearly: 4.648,
          },
          {
            ic_uuid: "f8e9320a-3954-4800-b028-1211efbfd5a1",
            ic_lat: -107425,
            ic_lon: -370865,
            ic_city: "Maruim",
            ic_states: "SERGIPE",
            ic_january: 6.338,
            ic_february: 6.191,
            ic_march: 6.048,
            ic_april: 5.099,
            ic_may: 4.332,
            ic_june: 4.065,
            ic_july: 4.119,
            ic_august: 4.715,
            ic_september: 5.485,
            ic_october: 5.878,
            ic_november: 6.393,
            ic_december: 6.419,
            ic_yearly: 5.424,
          },
          {
            ic_uuid: "92ebc1ed-02e6-4fcd-adb9-f0dc66154846",
            ic_lat: -237062,
            ic_lon: -516408,
            ic_city: "Marumbi",
            ic_states: "PARANÁ",
            ic_january: 5.808,
            ic_february: 5.707,
            ic_march: 5.206,
            ic_april: 4.533,
            ic_may: 3.561,
            ic_june: 3.245,
            ic_july: 3.473,
            ic_august: 4.462,
            ic_september: 4.629,
            ic_october: 5.281,
            ic_november: 5.941,
            ic_december: 6.244,
            ic_yearly: 4.841,
          },
          {
            ic_uuid: "747e43b5-b9fb-45a0-bf76-2aafe0bfe16d",
            ic_lat: -179788,
            ic_lon: -486435,
            ic_city: "Marzagão",
            ic_states: "GOIÁS",
            ic_january: 5.624,
            ic_february: 5.827,
            ic_march: 5.22,
            ic_april: 5.115,
            ic_may: 4.717,
            ic_june: 4.399,
            ic_july: 4.63,
            ic_august: 5.48,
            ic_september: 5.477,
            ic_october: 5.617,
            ic_november: 5.6,
            ic_december: 5.7,
            ic_yearly: 5.284,
          },
          {
            ic_uuid: "57e7f58e-d5c2-4de3-b224-7ade4b5bb594",
            ic_lat: -155648,
            ic_lon: -393021,
            ic_city: "Mascote",
            ic_states: "BAHIA",
            ic_january: 5.883,
            ic_february: 5.934,
            ic_march: 5.443,
            ic_april: 4.542,
            ic_may: 3.841,
            ic_june: 3.466,
            ic_july: 3.72,
            ic_august: 4.308,
            ic_september: 4.884,
            ic_october: 5.069,
            ic_november: 5.182,
            ic_december: 5.98,
            ic_yearly: 4.854,
          },
          {
            ic_uuid: "8717710d-f34d-428c-956f-f48e8bd5c766",
            ic_lat: -3524,
            ic_lon: -403426,
            ic_city: "Massapê",
            ic_states: "CEARÁ",
            ic_january: 4.937,
            ic_february: 5.207,
            ic_march: 5.2,
            ic_april: 4.86,
            ic_may: 5.178,
            ic_june: 5.072,
            ic_july: 5.333,
            ic_august: 6.046,
            ic_september: 6.469,
            ic_october: 6.398,
            ic_november: 6.069,
            ic_december: 5.394,
            ic_yearly: 5.514,
          },
          {
            ic_uuid: "585b671a-4343-49de-8d9f-d2c7f4f40a7e",
            ic_lat: -74751,
            ic_lon: -411107,
            ic_city: "Massapê do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.67,
            ic_february: 5.621,
            ic_march: 5.672,
            ic_april: 5.321,
            ic_may: 5.182,
            ic_june: 5.146,
            ic_july: 5.481,
            ic_august: 6.211,
            ic_september: 6.647,
            ic_october: 6.551,
            ic_november: 6.366,
            ic_december: 6.022,
            ic_yearly: 5.824,
          },
          {
            ic_uuid: "e5ff71dc-d351-4e7b-a267-f15ae3e6e249",
            ic_lat: -71904,
            ic_lon: -357851,
            ic_city: "Massaranduba",
            ic_states: "PARAÍBA",
            ic_january: 5.424,
            ic_february: 5.601,
            ic_march: 5.712,
            ic_april: 5.213,
            ic_may: 4.644,
            ic_june: 4.085,
            ic_july: 4.193,
            ic_august: 4.91,
            ic_september: 5.443,
            ic_october: 5.676,
            ic_november: 5.923,
            ic_december: 5.751,
            ic_yearly: 5.215,
          },
          {
            ic_uuid: "73290b63-2199-4d19-b225-73a23a5a27c3",
            ic_lat: -266113,
            ic_lon: -490058,
            ic_city: "Massaranduba",
            ic_states: "SANTA CATARINA",
            ic_january: 5.256,
            ic_february: 5.093,
            ic_march: 4.448,
            ic_april: 3.603,
            ic_may: 2.97,
            ic_june: 2.484,
            ic_july: 2.594,
            ic_august: 3.262,
            ic_september: 3.31,
            ic_october: 3.832,
            ic_november: 4.919,
            ic_december: 5.334,
            ic_yearly: 3.925,
          },
          {
            ic_uuid: "75b8f190-9943-4515-ad76-644545872a13",
            ic_lat: -295654,
            ic_lon: -544646,
            ic_city: "Mata",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.527,
            ic_february: 6.025,
            ic_march: 5.079,
            ic_april: 3.939,
            ic_may: 2.876,
            ic_june: 2.399,
            ic_july: 2.66,
            ic_august: 3.396,
            ic_september: 3.873,
            ic_october: 5.013,
            ic_november: 6.297,
            ic_december: 6.9,
            ic_yearly: 4.582,
          },
          {
            ic_uuid: "b53f74a7-c988-4088-be44-2e32bbb21e7f",
            ic_lat: -125311,
            ic_lon: -383012,
            ic_city: "Mata de São João",
            ic_states: "BAHIA",
            ic_january: 6.103,
            ic_february: 5.932,
            ic_march: 5.731,
            ic_april: 4.597,
            ic_may: 3.931,
            ic_june: 3.706,
            ic_july: 3.893,
            ic_august: 4.434,
            ic_september: 5.201,
            ic_october: 5.553,
            ic_november: 5.883,
            ic_december: 6.191,
            ic_yearly: 5.096,
          },
          {
            ic_uuid: "76f1e123-ecdc-4f66-92b9-e67a8cad0568",
            ic_lat: -91192,
            ic_lon: -377312,
            ic_city: "Mata Grande",
            ic_states: "ALAGOAS",
            ic_january: 6.423,
            ic_february: 6.143,
            ic_march: 6.096,
            ic_april: 5.432,
            ic_may: 4.563,
            ic_june: 4.058,
            ic_july: 4.229,
            ic_august: 5.011,
            ic_september: 5.932,
            ic_october: 6.276,
            ic_november: 6.695,
            ic_december: 6.547,
            ic_yearly: 5.617,
          },
          {
            ic_uuid: "21a1f955-dc3c-4077-b129-b1f2279858e1",
            ic_lat: -362,
            ic_lon: -431163,
            ic_city: "Mata Roma",
            ic_states: "MARANHÃO",
            ic_january: 5.047,
            ic_february: 5.222,
            ic_march: 5.231,
            ic_april: 5.139,
            ic_may: 5.063,
            ic_june: 5.197,
            ic_july: 5.476,
            ic_august: 6.065,
            ic_september: 6.419,
            ic_october: 6.291,
            ic_november: 5.97,
            ic_december: 5.536,
            ic_yearly: 5.555,
          },
          {
            ic_uuid: "b97b7290-5618-43f7-892f-6f664b68e19d",
            ic_lat: -156871,
            ic_lon: -407381,
            ic_city: "Mata Verde",
            ic_states: "MINAS GERAIS",
            ic_january: 5.96,
            ic_february: 5.988,
            ic_march: 5.348,
            ic_april: 4.6,
            ic_may: 3.883,
            ic_june: 3.491,
            ic_july: 3.691,
            ic_august: 4.261,
            ic_september: 5.12,
            ic_october: 5.27,
            ic_november: 5.183,
            ic_december: 5.974,
            ic_yearly: 4.897,
          },
          {
            ic_uuid: "5c36b5ad-7549-46d1-bc92-c8084c460275",
            ic_lat: -21603,
            ic_lon: -483644,
            ic_city: "Matão",
            ic_states: "SÃO PAULO",
            ic_january: 5.751,
            ic_february: 5.92,
            ic_march: 5.174,
            ic_april: 4.711,
            ic_may: 4.025,
            ic_june: 3.782,
            ic_july: 3.958,
            ic_august: 4.865,
            ic_september: 4.966,
            ic_october: 5.583,
            ic_november: 5.813,
            ic_december: 6.089,
            ic_yearly: 5.053,
          },
          {
            ic_uuid: "083c642d-a7da-4792-bf40-66f55f7f695f",
            ic_lat: -65971,
            ic_lon: -350534,
            ic_city: "Mataraca",
            ic_states: "PARAÍBA",
            ic_january: 6.011,
            ic_february: 6.027,
            ic_march: 6.105,
            ic_april: 5.5,
            ic_may: 4.92,
            ic_june: 4.457,
            ic_july: 4.588,
            ic_august: 5.386,
            ic_september: 5.815,
            ic_october: 6.047,
            ic_november: 6.256,
            ic_december: 6.043,
            ic_yearly: 5.596,
          },
          {
            ic_uuid: "e2853df9-19df-4e67-aa34-291d244ebea4",
            ic_lat: -105468,
            ic_lon: -464171,
            ic_city: "Mateiros",
            ic_states: "TOCANTINS",
            ic_january: 5.457,
            ic_february: 5.23,
            ic_march: 4.964,
            ic_april: 5.06,
            ic_may: 5.136,
            ic_june: 5.106,
            ic_july: 5.373,
            ic_august: 6.12,
            ic_september: 6.236,
            ic_october: 5.729,
            ic_november: 5.339,
            ic_december: 5.369,
            ic_yearly: 5.427,
          },
          {
            ic_uuid: "9561b3a1-7dcd-40c0-9ace-c2b47d66eb2b",
            ic_lat: -2525,
            ic_lon: -539939,
            ic_city: "Matelândia",
            ic_states: "PARANÁ",
            ic_january: 6.218,
            ic_february: 5.766,
            ic_march: 5.147,
            ic_april: 4.196,
            ic_may: 3.303,
            ic_june: 2.888,
            ic_july: 3.111,
            ic_august: 4.134,
            ic_september: 4.338,
            ic_october: 5.246,
            ic_november: 6.015,
            ic_december: 6.442,
            ic_yearly: 4.734,
          },
          {
            ic_uuid: "cac512b0-2015-4818-8279-54796b777019",
            ic_lat: -184704,
            ic_lon: -430583,
            ic_city: "Materlândia",
            ic_states: "MINAS GERAIS",
            ic_january: 5.914,
            ic_february: 6.093,
            ic_march: 5.059,
            ic_april: 4.478,
            ic_may: 3.959,
            ic_june: 3.753,
            ic_july: 3.894,
            ic_august: 4.686,
            ic_september: 5.119,
            ic_october: 5.32,
            ic_november: 4.839,
            ic_december: 5.61,
            ic_yearly: 4.894,
          },
          {
            ic_uuid: "534ac118-2ffd-4d7f-a3f7-1c8ef3117479",
            ic_lat: -19986,
            ic_lon: -44428,
            ic_city: "Mateus Leme",
            ic_states: "MINAS GERAIS",
            ic_january: 5.817,
            ic_february: 6.012,
            ic_march: 5.075,
            ic_april: 4.822,
            ic_may: 4.217,
            ic_june: 4.085,
            ic_july: 4.359,
            ic_august: 5.215,
            ic_september: 5.447,
            ic_october: 5.547,
            ic_november: 5.251,
            ic_december: 5.539,
            ic_yearly: 5.115,
          },
          {
            ic_uuid: "730f16b7-bd2b-4092-aed7-79e6d7a16da5",
            ic_lat: -185905,
            ic_lon: -419169,
            ic_city: "Mathias Lobato",
            ic_states: "MINAS GERAIS",
            ic_january: 6.087,
            ic_february: 6.204,
            ic_march: 5.349,
            ic_april: 4.649,
            ic_may: 3.926,
            ic_june: 3.663,
            ic_july: 3.765,
            ic_august: 4.414,
            ic_september: 4.993,
            ic_october: 5.22,
            ic_november: 4.965,
            ic_december: 5.902,
            ic_yearly: 4.928,
          },
          {
            ic_uuid: "5b38a170-a481-4e05-90f1-25f3b7cafb4b",
            ic_lat: -218694,
            ic_lon: -433138,
            ic_city: "Matias Barbosa",
            ic_states: "MINAS GERAIS",
            ic_january: 5.552,
            ic_february: 5.843,
            ic_march: 4.827,
            ic_april: 4.215,
            ic_may: 3.535,
            ic_june: 3.373,
            ic_july: 3.532,
            ic_august: 4.267,
            ic_september: 4.549,
            ic_october: 4.869,
            ic_november: 4.751,
            ic_december: 5.447,
            ic_yearly: 4.563,
          },
          {
            ic_uuid: "53c57b16-012b-4587-b3b3-cf7f0adc7d26",
            ic_lat: -148529,
            ic_lon: -439203,
            ic_city: "Matias Cardoso",
            ic_states: "MINAS GERAIS",
            ic_january: 6.493,
            ic_february: 6.639,
            ic_march: 5.942,
            ic_april: 5.678,
            ic_may: 5.003,
            ic_june: 4.809,
            ic_july: 5.16,
            ic_august: 5.767,
            ic_september: 6.19,
            ic_october: 6.236,
            ic_november: 5.703,
            ic_december: 6.297,
            ic_yearly: 5.826,
          },
          {
            ic_uuid: "7016b8ed-d16d-4da6-880b-636fe1da3b8c",
            ic_lat: -37149,
            ic_lon: -42555,
            ic_city: "Matias Olímpio",
            ic_states: "PIAUÍ",
            ic_january: 5.2,
            ic_february: 5.372,
            ic_march: 5.375,
            ic_april: 5.144,
            ic_may: 5.112,
            ic_june: 5.317,
            ic_july: 5.494,
            ic_august: 6.111,
            ic_september: 6.446,
            ic_october: 6.36,
            ic_november: 6.09,
            ic_december: 5.535,
            ic_yearly: 5.629,
          },
          {
            ic_uuid: "871996b5-695c-4766-9de2-fb84a0f047ba",
            ic_lat: -139114,
            ic_lon: -428443,
            ic_city: "Matina",
            ic_states: "BAHIA",
            ic_january: 6.684,
            ic_february: 6.617,
            ic_march: 6.03,
            ic_april: 5.502,
            ic_may: 4.952,
            ic_june: 4.731,
            ic_july: 4.972,
            ic_august: 5.595,
            ic_september: 6.063,
            ic_october: 6.47,
            ic_november: 5.904,
            ic_december: 6.509,
            ic_yearly: 5.836,
          },
          {
            ic_uuid: "05d85886-0327-4491-9240-59a4b3b7062c",
            ic_lat: -30989,
            ic_lon: -450354,
            ic_city: "Matinha",
            ic_states: "MARANHÃO",
            ic_january: 4.645,
            ic_february: 4.889,
            ic_march: 4.887,
            ic_april: 4.774,
            ic_may: 4.873,
            ic_june: 5.012,
            ic_july: 5.118,
            ic_august: 5.647,
            ic_september: 5.916,
            ic_october: 5.677,
            ic_november: 5.31,
            ic_december: 4.972,
            ic_yearly: 5.144,
          },
          {
            ic_uuid: "edd84797-94b1-4eb0-89fd-22474f8c06f4",
            ic_lat: -71221,
            ic_lon: -357701,
            ic_city: "Matinhas",
            ic_states: "PARAÍBA",
            ic_january: 5.599,
            ic_february: 5.726,
            ic_march: 5.716,
            ic_april: 5.282,
            ic_may: 4.617,
            ic_june: 4.098,
            ic_july: 4.171,
            ic_august: 4.947,
            ic_september: 5.436,
            ic_october: 5.731,
            ic_november: 6.0,
            ic_december: 5.81,
            ic_yearly: 5.261,
          },
          {
            ic_uuid: "91662ecf-f0d9-4646-998c-42e0ab7d2a2e",
            ic_lat: -258241,
            ic_lon: -485494,
            ic_city: "Matinhos",
            ic_states: "PARANÁ",
            ic_january: 5.271,
            ic_february: 5.06,
            ic_march: 4.337,
            ic_april: 3.584,
            ic_may: 2.931,
            ic_june: 2.459,
            ic_july: 2.503,
            ic_august: 3.175,
            ic_september: 3.193,
            ic_october: 3.722,
            ic_november: 4.745,
            ic_december: 5.155,
            ic_yearly: 3.845,
          },
          {
            ic_uuid: "b0523649-b35e-4908-863f-ff1b42741b12",
            ic_lat: -202877,
            ic_lon: -423405,
            ic_city: "Matipó",
            ic_states: "MINAS GERAIS",
            ic_january: 5.711,
            ic_february: 5.981,
            ic_march: 5.003,
            ic_april: 4.487,
            ic_may: 3.829,
            ic_june: 3.663,
            ic_july: 3.846,
            ic_august: 4.563,
            ic_september: 4.924,
            ic_october: 5.032,
            ic_november: 4.791,
            ic_december: 5.605,
            ic_yearly: 4.786,
          },
          {
            ic_uuid: "13f7b038-5ab1-4ae5-aa03-c014ed1e08c0",
            ic_lat: -282805,
            ic_lon: -521937,
            ic_city: "Mato Castelhano",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.161,
            ic_february: 5.748,
            ic_march: 4.904,
            ic_april: 3.982,
            ic_may: 2.995,
            ic_june: 2.502,
            ic_july: 2.831,
            ic_august: 3.585,
            ic_september: 3.835,
            ic_october: 4.967,
            ic_november: 6.146,
            ic_december: 6.509,
            ic_yearly: 4.514,
          },
          {
            ic_uuid: "072a259f-ff9c-48a4-a016-24f25c01fd0c",
            ic_lat: -65414,
            ic_lon: -377149,
            ic_city: "Mato Grosso",
            ic_states: "PARAÍBA",
            ic_january: 6.168,
            ic_february: 6.186,
            ic_march: 6.21,
            ic_april: 5.872,
            ic_may: 5.341,
            ic_june: 4.936,
            ic_july: 5.251,
            ic_august: 5.994,
            ic_september: 6.523,
            ic_october: 6.651,
            ic_november: 6.703,
            ic_december: 6.323,
            ic_yearly: 6.013,
          },
          {
            ic_uuid: "bba052c8-d576-440c-a9e4-c2eda4ff0277",
            ic_lat: -29529,
            ic_lon: -521282,
            ic_city: "Mato Leitão",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 5.972,
            ic_february: 5.628,
            ic_march: 4.742,
            ic_april: 3.782,
            ic_may: 2.814,
            ic_june: 2.342,
            ic_july: 2.552,
            ic_august: 3.286,
            ic_september: 3.567,
            ic_october: 4.581,
            ic_november: 6.003,
            ic_december: 6.391,
            ic_yearly: 4.305,
          },
          {
            ic_uuid: "d9f9798d-8228-4642-a836-35d7374976ac",
            ic_lat: -282562,
            ic_lon: -546163,
            ic_city: "Mato Queimado",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.559,
            ic_february: 6.126,
            ic_march: 5.221,
            ic_april: 4.07,
            ic_may: 3.082,
            ic_june: 2.521,
            ic_july: 2.804,
            ic_august: 3.589,
            ic_september: 4.037,
            ic_october: 5.153,
            ic_november: 6.352,
            ic_december: 6.759,
            ic_yearly: 4.689,
          },
          {
            ic_uuid: "be6068f6-6a6b-4096-8be4-4845e3540023",
            ic_lat: -247,
            ic_lon: -521459,
            ic_city: "Mato Rico",
            ic_states: "PARANÁ",
            ic_january: 6.129,
            ic_february: 5.665,
            ic_march: 5.166,
            ic_april: 4.447,
            ic_may: 3.453,
            ic_june: 3.063,
            ic_july: 3.297,
            ic_august: 4.283,
            ic_september: 4.453,
            ic_october: 5.235,
            ic_november: 6.052,
            ic_december: 6.284,
            ic_yearly: 4.794,
          },
          {
            ic_uuid: "99f059b8-811c-4529-a683-c0d76d5fb369",
            ic_lat: -153949,
            ic_lon: -428603,
            ic_city: "Mato Verde",
            ic_states: "MINAS GERAIS",
            ic_january: 6.654,
            ic_february: 6.808,
            ic_march: 6.022,
            ic_april: 5.586,
            ic_may: 4.884,
            ic_june: 4.622,
            ic_july: 4.835,
            ic_august: 5.474,
            ic_september: 6.028,
            ic_october: 6.264,
            ic_november: 5.93,
            ic_december: 6.526,
            ic_yearly: 5.803,
          },
          {
            ic_uuid: "598d7259-14a5-4434-a29d-a771116afb72",
            ic_lat: -5524,
            ic_lon: -432043,
            ic_city: "Matões",
            ic_states: "MARANHÃO",
            ic_january: 4.952,
            ic_february: 5.072,
            ic_march: 5.026,
            ic_april: 4.975,
            ic_may: 5.054,
            ic_june: 5.328,
            ic_july: 5.592,
            ic_august: 6.22,
            ic_september: 6.531,
            ic_october: 6.184,
            ic_november: 5.783,
            ic_december: 5.435,
            ic_yearly: 5.513,
          },
          {
            ic_uuid: "aea11d63-3a40-4001-b2fd-c27cd48f71ed",
            ic_lat: -36248,
            ic_lon: -445471,
            ic_city: "Matões do Norte",
            ic_states: "MARANHÃO",
            ic_january: 4.716,
            ic_february: 4.86,
            ic_march: 4.885,
            ic_april: 4.802,
            ic_may: 4.852,
            ic_june: 5.052,
            ic_july: 5.227,
            ic_august: 5.81,
            ic_september: 6.079,
            ic_october: 5.727,
            ic_november: 5.322,
            ic_december: 5.065,
            ic_yearly: 5.2,
          },
          {
            ic_uuid: "a5b00691-0174-4279-ab38-c6334eba2367",
            ic_lat: -264713,
            ic_lon: -511505,
            ic_city: "Matos Costa",
            ic_states: "SANTA CATARINA",
            ic_january: 5.414,
            ic_february: 5.128,
            ic_march: 4.591,
            ic_april: 3.667,
            ic_may: 2.944,
            ic_june: 2.563,
            ic_july: 2.816,
            ic_august: 3.779,
            ic_september: 3.863,
            ic_october: 4.509,
            ic_november: 5.472,
            ic_december: 5.699,
            ic_yearly: 4.204,
          },
          {
            ic_uuid: "de27d4c9-453b-4e63-973c-e059627af47f",
            ic_lat: -195548,
            ic_lon: -440872,
            ic_city: "Matozinhos",
            ic_states: "MINAS GERAIS",
            ic_january: 5.894,
            ic_february: 6.031,
            ic_march: 5.224,
            ic_april: 4.891,
            ic_may: 4.331,
            ic_june: 4.094,
            ic_july: 4.412,
            ic_august: 5.209,
            ic_september: 5.608,
            ic_october: 5.645,
            ic_november: 5.418,
            ic_december: 5.669,
            ic_yearly: 5.202,
          },
          {
            ic_uuid: "5a84a330-582d-4183-bb57-c13286ef5b6f",
            ic_lat: -154346,
            ic_lon: -50746,
            ic_city: "Matrinchã",
            ic_states: "GOIÁS",
            ic_january: 5.46,
            ic_february: 5.604,
            ic_march: 5.358,
            ic_april: 5.263,
            ic_may: 4.92,
            ic_june: 4.534,
            ic_july: 4.751,
            ic_august: 5.545,
            ic_september: 5.447,
            ic_october: 5.586,
            ic_november: 5.406,
            ic_december: 5.476,
            ic_yearly: 5.279,
          },
          {
            ic_uuid: "c977b152-88f5-4905-932d-94bd93521f76",
            ic_lat: -91548,
            ic_lon: -355246,
            ic_city: "Matriz de Camaragibe",
            ic_states: "ALAGOAS",
            ic_january: 5.9,
            ic_february: 5.774,
            ic_march: 5.846,
            ic_april: 4.99,
            ic_may: 4.3,
            ic_june: 3.962,
            ic_july: 4.038,
            ic_august: 4.656,
            ic_september: 5.368,
            ic_october: 5.617,
            ic_november: 6.092,
            ic_december: 6.104,
            ic_yearly: 5.221,
          },
          {
            ic_uuid: "069f39d9-482a-4369-a330-38232f567d6e",
            ic_lat: -101825,
            ic_lon: -549471,
            ic_city: "Matupá",
            ic_states: "MATO GROSSO",
            ic_january: 4.94,
            ic_february: 4.893,
            ic_march: 4.759,
            ic_april: 4.674,
            ic_may: 4.732,
            ic_june: 4.546,
            ic_july: 4.848,
            ic_august: 5.36,
            ic_september: 5.096,
            ic_october: 5.251,
            ic_november: 5.025,
            ic_december: 5.018,
            ic_yearly: 4.929,
          },
          {
            ic_uuid: "f1d2cc9a-cc18-4f42-a9ea-9554cfdc396c",
            ic_lat: -72623,
            ic_lon: -373513,
            ic_city: "Maturéia",
            ic_states: "PARAÍBA",
            ic_january: 6.074,
            ic_february: 6.011,
            ic_march: 6.04,
            ic_april: 5.71,
            ic_may: 5.057,
            ic_june: 4.693,
            ic_july: 5.057,
            ic_august: 5.8,
            ic_september: 6.459,
            ic_october: 6.556,
            ic_november: 6.696,
            ic_december: 6.243,
            ic_yearly: 5.866,
          },
          {
            ic_uuid: "3b6c8ff5-ef5b-44c1-849a-c8c0704de5ee",
            ic_lat: -192218,
            ic_lon: -459684,
            ic_city: "Matutina",
            ic_states: "MINAS GERAIS",
            ic_january: 5.709,
            ic_february: 6.022,
            ic_march: 4.971,
            ic_april: 4.909,
            ic_may: 4.512,
            ic_june: 4.292,
            ic_july: 4.587,
            ic_august: 5.575,
            ic_september: 5.604,
            ic_october: 5.517,
            ic_november: 5.053,
            ic_december: 5.484,
            ic_yearly: 5.186,
          },
          {
            ic_uuid: "71999674-8f5d-40ba-9807-779a0f1ce733",
            ic_lat: -236682,
            ic_lon: -464617,
            ic_city: "Mauá",
            ic_states: "SÃO PAULO",
            ic_january: 5.03,
            ic_february: 5.397,
            ic_march: 4.549,
            ic_april: 4.026,
            ic_may: 3.303,
            ic_june: 3.03,
            ic_july: 3.144,
            ic_august: 3.969,
            ic_september: 4.013,
            ic_october: 4.453,
            ic_november: 4.928,
            ic_december: 5.506,
            ic_yearly: 4.279,
          },
          {
            ic_uuid: "bfd5c8c7-46cb-47bf-be42-2e93f9b53840",
            ic_lat: -238992,
            ic_lon: -512282,
            ic_city: "Mauá da Serra",
            ic_states: "PARANÁ",
            ic_january: 5.759,
            ic_february: 5.575,
            ic_march: 5.112,
            ic_april: 4.457,
            ic_may: 3.528,
            ic_june: 3.24,
            ic_july: 3.47,
            ic_august: 4.43,
            ic_september: 4.541,
            ic_october: 5.196,
            ic_november: 5.843,
            ic_december: 6.163,
            ic_yearly: 4.776,
          },
          {
            ic_uuid: "fa7a2783-6d32-4eeb-9e38-468f60942966",
            ic_lat: -33933,
            ic_lon: -577072,
            ic_city: "Maués",
            ic_states: "AMAZONAS",
            ic_january: 3.95,
            ic_february: 4.133,
            ic_march: 4.048,
            ic_april: 3.842,
            ic_may: 3.992,
            ic_june: 4.508,
            ic_july: 4.545,
            ic_august: 5.158,
            ic_september: 5.087,
            ic_october: 5.113,
            ic_november: 4.83,
            ic_december: 4.391,
            ic_yearly: 4.466,
          },
          {
            ic_uuid: "38273ffb-9921-4c8e-87d0-3ebc7fd93dad",
            ic_lat: -179723,
            ic_lon: -503392,
            ic_city: "Maurilândia",
            ic_states: "GOIÁS",
            ic_january: 5.725,
            ic_february: 5.788,
            ic_march: 5.308,
            ic_april: 5.086,
            ic_may: 4.674,
            ic_june: 4.368,
            ic_july: 4.599,
            ic_august: 5.582,
            ic_september: 5.337,
            ic_october: 5.566,
            ic_november: 5.658,
            ic_december: 5.821,
            ic_yearly: 5.293,
          },
          {
            ic_uuid: "4cfa45f0-039a-4266-a678-5aae7975b5ca",
            ic_lat: -59549,
            ic_lon: -4751,
            ic_city: "Maurilândia do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 4.695,
            ic_february: 4.847,
            ic_march: 4.811,
            ic_april: 4.846,
            ic_may: 4.927,
            ic_june: 5.193,
            ic_july: 5.386,
            ic_august: 5.917,
            ic_september: 5.756,
            ic_october: 5.283,
            ic_november: 4.916,
            ic_december: 4.743,
            ic_yearly: 5.11,
          },
          {
            ic_uuid: "864338cd-308f-4ebe-81ce-1998c29334a2",
            ic_lat: -73864,
            ic_lon: -387711,
            ic_city: "Mauriti",
            ic_states: "CEARÁ",
            ic_january: 6.053,
            ic_february: 5.966,
            ic_march: 5.997,
            ic_april: 5.705,
            ic_may: 5.085,
            ic_june: 4.8,
            ic_july: 5.089,
            ic_august: 5.865,
            ic_september: 6.562,
            ic_october: 6.568,
            ic_november: 6.666,
            ic_december: 6.274,
            ic_yearly: 5.886,
          },
          {
            ic_uuid: "1d6e36b9-04df-49c4-b595-84db46c84d56",
            ic_lat: -55183,
            ic_lon: -35258,
            ic_city: "Maxaranguape",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.156,
            ic_february: 6.225,
            ic_march: 6.12,
            ic_april: 5.695,
            ic_may: 5.23,
            ic_june: 4.819,
            ic_july: 4.984,
            ic_august: 5.775,
            ic_september: 6.23,
            ic_october: 6.324,
            ic_november: 6.472,
            ic_december: 6.203,
            ic_yearly: 5.853,
          },
          {
            ic_uuid: "e9f4a6d4-595a-4064-af49-e37b91186abd",
            ic_lat: -27633,
            ic_lon: -518025,
            ic_city: "Maximiliano de Almeida",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.317,
            ic_february: 5.836,
            ic_march: 5.084,
            ic_april: 4.093,
            ic_may: 3.072,
            ic_june: 2.572,
            ic_july: 2.893,
            ic_august: 3.704,
            ic_september: 3.957,
            ic_october: 5.081,
            ic_november: 6.291,
            ic_december: 6.597,
            ic_yearly: 4.625,
          },
          {
            ic_uuid: "77213ef3-e46d-4aa7-815b-b6ad03a67e60",
            ic_lat: -1137,
            ic_lon: -512895,
            ic_city: "Mazagão",
            ic_states: "AMAPÁ",
            ic_january: 4.566,
            ic_february: 4.245,
            ic_march: 4.208,
            ic_april: 4.229,
            ic_may: 4.635,
            ic_june: 4.905,
            ic_july: 5.074,
            ic_august: 5.307,
            ic_september: 5.491,
            ic_october: 5.401,
            ic_november: 5.288,
            ic_december: 4.792,
            ic_yearly: 4.845,
          },
          {
            ic_uuid: "7ee2c2f2-f019-4775-868e-363ac05f0d74",
            ic_lat: -199945,
            ic_lon: -462251,
            ic_city: "Medeiros",
            ic_states: "MINAS GERAIS",
            ic_january: 5.442,
            ic_february: 5.767,
            ic_march: 4.739,
            ic_april: 4.763,
            ic_may: 4.299,
            ic_june: 4.113,
            ic_july: 4.382,
            ic_august: 5.398,
            ic_september: 5.35,
            ic_october: 5.35,
            ic_november: 4.94,
            ic_december: 5.353,
            ic_yearly: 4.991,
          },
          {
            ic_uuid: "7b74aced-a541-4252-9ade-966f64c12396",
            ic_lat: -173711,
            ic_lon: -402241,
            ic_city: "Medeiros Neto",
            ic_states: "BAHIA",
            ic_january: 6.156,
            ic_february: 6.204,
            ic_march: 5.451,
            ic_april: 4.625,
            ic_may: 3.855,
            ic_june: 3.474,
            ic_july: 3.642,
            ic_august: 4.329,
            ic_september: 4.989,
            ic_october: 5.285,
            ic_november: 5.32,
            ic_december: 6.184,
            ic_yearly: 4.959,
          },
          {
            ic_uuid: "3fe33edb-d46b-4516-8e51-b975d49be2ed",
            ic_lat: -252981,
            ic_lon: -540948,
            ic_city: "Medianeira",
            ic_states: "PARANÁ",
            ic_january: 6.248,
            ic_february: 5.754,
            ic_march: 5.188,
            ic_april: 4.21,
            ic_may: 3.258,
            ic_june: 2.862,
            ic_july: 3.086,
            ic_august: 4.069,
            ic_september: 4.318,
            ic_october: 5.255,
            ic_november: 6.044,
            ic_december: 6.457,
            ic_yearly: 4.729,
          },
          {
            ic_uuid: "8ad3ad51-80d2-4408-ad69-0c1d80399a7c",
            ic_lat: -34468,
            ic_lon: -528879,
            ic_city: "Medicilândia",
            ic_states: "PARÁ",
            ic_january: 4.133,
            ic_february: 4.26,
            ic_march: 4.249,
            ic_april: 4.173,
            ic_may: 4.18,
            ic_june: 4.433,
            ic_july: 4.441,
            ic_august: 4.712,
            ic_september: 4.549,
            ic_october: 4.585,
            ic_november: 4.485,
            ic_december: 4.102,
            ic_yearly: 4.359,
          },
          {
            ic_uuid: "50a0e5a4-0509-40e8-836b-e0aa854072ff",
            ic_lat: -16225,
            ic_lon: -414731,
            ic_city: "Medina",
            ic_states: "MINAS GERAIS",
            ic_january: 6.022,
            ic_february: 6.251,
            ic_march: 5.39,
            ic_april: 4.736,
            ic_may: 4.016,
            ic_june: 3.613,
            ic_july: 3.83,
            ic_august: 4.541,
            ic_september: 5.345,
            ic_october: 5.459,
            ic_november: 5.278,
            ic_december: 5.995,
            ic_yearly: 5.039,
          },
          {
            ic_uuid: "6b7d93b2-105a-4bfe-8a8d-a34d7005eaad",
            ic_lat: -288249,
            ic_lon: -496383,
            ic_city: "Meleiro",
            ic_states: "SANTA CATARINA",
            ic_january: 5.422,
            ic_february: 5.068,
            ic_march: 4.341,
            ic_april: 3.726,
            ic_may: 2.917,
            ic_june: 2.438,
            ic_july: 2.687,
            ic_august: 3.373,
            ic_september: 3.435,
            ic_october: 4.106,
            ic_november: 5.412,
            ic_december: 5.637,
            ic_yearly: 4.047,
          },
          {
            ic_uuid: "9262b650-b0c6-454e-870f-52a5a2adaa80",
            ic_lat: -18036,
            ic_lon: -507153,
            ic_city: "Melgaço",
            ic_states: "PARÁ",
            ic_january: 4.56,
            ic_february: 4.432,
            ic_march: 4.514,
            ic_april: 4.512,
            ic_may: 4.695,
            ic_june: 4.916,
            ic_july: 5.036,
            ic_august: 5.28,
            ic_september: 5.297,
            ic_october: 5.156,
            ic_november: 5.044,
            ic_december: 4.729,
            ic_yearly: 4.848,
          },
          {
            ic_uuid: "cca5e7c3-e2c4-4684-bb1f-691ac2d29603",
            ic_lat: -22525,
            ic_lon: -437316,
            ic_city: "Mendes",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.842,
            ic_february: 6.103,
            ic_march: 4.977,
            ic_april: 4.39,
            ic_may: 3.61,
            ic_june: 3.405,
            ic_july: 3.468,
            ic_august: 4.297,
            ic_september: 4.537,
            ic_october: 4.949,
            ic_november: 5.076,
            ic_december: 5.727,
            ic_yearly: 4.698,
          },
          {
            ic_uuid: "9e288921-1f68-44d1-82c7-0d26899de356",
            ic_lat: -186636,
            ic_lon: -414056,
            ic_city: "Mendes Pimentel",
            ic_states: "MINAS GERAIS",
            ic_january: 5.916,
            ic_february: 6.057,
            ic_march: 5.163,
            ic_april: 4.556,
            ic_may: 3.867,
            ic_june: 3.602,
            ic_july: 3.734,
            ic_august: 4.341,
            ic_september: 4.906,
            ic_october: 5.148,
            ic_november: 4.908,
            ic_december: 5.666,
            ic_yearly: 4.822,
          },
          {
            ic_uuid: "fc311fee-8f58-4127-a5bf-ead2e3474ebd",
            ic_lat: -211841,
            ic_lon: -495791,
            ic_city: "Mendonça",
            ic_states: "SÃO PAULO",
            ic_january: 5.91,
            ic_february: 5.922,
            ic_march: 5.292,
            ic_april: 4.832,
            ic_may: 4.024,
            ic_june: 3.764,
            ic_july: 3.993,
            ic_august: 4.846,
            ic_september: 4.996,
            ic_october: 5.628,
            ic_november: 5.948,
            ic_december: 6.201,
            ic_yearly: 5.113,
          },
          {
            ic_uuid: "f296da7d-7918-4009-9c14-4bfaf148b203",
            ic_lat: -244543,
            ic_lon: -541623,
            ic_city: "Mercedes",
            ic_states: "PARANÁ",
            ic_january: 6.25,
            ic_february: 5.765,
            ic_march: 5.324,
            ic_april: 4.326,
            ic_may: 3.378,
            ic_june: 2.987,
            ic_july: 3.175,
            ic_august: 4.169,
            ic_september: 4.471,
            ic_october: 5.297,
            ic_november: 6.124,
            ic_december: 6.532,
            ic_yearly: 4.816,
          },
          {
            ic_uuid: "5c9be70c-568b-4b87-9e5f-a1dc2d9acaff",
            ic_lat: -211981,
            ic_lon: -43334,
            ic_city: "Mercês",
            ic_states: "MINAS GERAIS",
            ic_january: 5.477,
            ic_february: 5.814,
            ic_march: 4.798,
            ic_april: 4.24,
            ic_may: 3.628,
            ic_june: 3.487,
            ic_july: 3.623,
            ic_august: 4.428,
            ic_september: 4.704,
            ic_october: 4.908,
            ic_november: 4.694,
            ic_december: 5.468,
            ic_yearly: 4.606,
          },
          {
            ic_uuid: "325ec6aa-c870-4130-9cc0-06b3b14f4695",
            ic_lat: -203499,
            ic_lon: -501821,
            ic_city: "Meridiano",
            ic_states: "SÃO PAULO",
            ic_january: 5.799,
            ic_february: 5.917,
            ic_march: 5.263,
            ic_april: 4.928,
            ic_may: 4.214,
            ic_june: 3.918,
            ic_july: 4.198,
            ic_august: 5.064,
            ic_september: 5.137,
            ic_october: 5.624,
            ic_november: 5.903,
            ic_december: 6.219,
            ic_yearly: 5.182,
          },
          {
            ic_uuid: "59e29af6-db27-4393-9c9f-b8c401b8c2fe",
            ic_lat: -35401,
            ic_lon: -404534,
            ic_city: "Meruoca",
            ic_states: "CEARÁ",
            ic_january: 5.106,
            ic_february: 5.283,
            ic_march: 5.194,
            ic_april: 4.817,
            ic_may: 5.132,
            ic_june: 5.121,
            ic_july: 5.449,
            ic_august: 6.157,
            ic_september: 6.611,
            ic_october: 6.534,
            ic_november: 6.293,
            ic_december: 5.586,
            ic_yearly: 5.607,
          },
          {
            ic_uuid: "876caccc-fc3e-4fce-8117-4b624031f7a5",
            ic_lat: -199698,
            ic_lon: -50617,
            ic_city: "Mesópolis",
            ic_states: "SÃO PAULO",
            ic_january: 5.816,
            ic_february: 5.925,
            ic_march: 5.39,
            ic_april: 4.904,
            ic_may: 4.238,
            ic_june: 3.966,
            ic_july: 4.156,
            ic_august: 5.072,
            ic_september: 5.043,
            ic_october: 5.685,
            ic_november: 5.875,
            ic_december: 6.224,
            ic_yearly: 5.191,
          },
          {
            ic_uuid: "b8ae6617-bdef-4ed4-ae28-f312c4935e76",
            ic_lat: -192245,
            ic_lon: -426082,
            ic_city: "Mesquita",
            ic_states: "MINAS GERAIS",
            ic_january: 5.929,
            ic_february: 6.187,
            ic_march: 5.187,
            ic_april: 4.666,
            ic_may: 3.949,
            ic_june: 3.724,
            ic_july: 3.895,
            ic_august: 4.586,
            ic_september: 4.948,
            ic_october: 5.172,
            ic_november: 4.955,
            ic_december: 5.755,
            ic_yearly: 4.913,
          },
          {
            ic_uuid: "d7db8fc7-4f28-4ca9-b454-8bc9adcd7f6e",
            ic_lat: -228033,
            ic_lon: -434604,
            ic_city: "Mesquita",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.848,
            ic_february: 6.049,
            ic_march: 4.899,
            ic_april: 4.321,
            ic_may: 3.585,
            ic_june: 3.336,
            ic_july: 3.325,
            ic_august: 4.174,
            ic_september: 4.353,
            ic_october: 4.909,
            ic_november: 4.925,
            ic_december: 5.658,
            ic_yearly: 4.615,
          },
          {
            ic_uuid: "d294e913-8912-4d78-be39-c609c809161d",
            ic_lat: -93943,
            ic_lon: -358395,
            ic_city: "Messias",
            ic_states: "ALAGOAS",
            ic_january: 5.871,
            ic_february: 5.833,
            ic_march: 5.841,
            ic_april: 5.057,
            ic_may: 4.232,
            ic_june: 3.895,
            ic_july: 4.002,
            ic_august: 4.573,
            ic_september: 5.388,
            ic_october: 5.671,
            ic_november: 6.161,
            ic_december: 6.107,
            ic_yearly: 5.219,
          },
          {
            ic_uuid: "ae2f6cfc-df84-48eb-b9ed-e688feca5b1e",
            ic_lat: -60723,
            ic_lon: -375161,
            ic_city: "Messias Targino",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.113,
            ic_february: 6.12,
            ic_march: 6.109,
            ic_april: 5.859,
            ic_may: 5.379,
            ic_june: 5.015,
            ic_july: 5.286,
            ic_august: 5.973,
            ic_september: 6.521,
            ic_october: 6.599,
            ic_november: 6.633,
            ic_december: 6.169,
            ic_yearly: 5.981,
          },
          {
            ic_uuid: "37424bac-3ecb-4110-ad7f-58e45e5c920e",
            ic_lat: -4169,
            ic_lon: -428966,
            ic_city: "Miguel Alves",
            ic_states: "PIAUÍ",
            ic_january: 5.081,
            ic_february: 5.282,
            ic_march: 5.24,
            ic_april: 5.074,
            ic_may: 5.071,
            ic_june: 5.252,
            ic_july: 5.459,
            ic_august: 5.99,
            ic_september: 6.398,
            ic_october: 6.36,
            ic_november: 6.054,
            ic_december: 5.587,
            ic_yearly: 5.571,
          },
          {
            ic_uuid: "6a8d8662-5348-43c4-953e-e35e5d585cc3",
            ic_lat: -114312,
            ic_lon: -405969,
            ic_city: "Miguel Calmon",
            ic_states: "BAHIA",
            ic_january: 6.071,
            ic_february: 5.934,
            ic_march: 5.773,
            ic_april: 4.765,
            ic_may: 3.995,
            ic_june: 3.724,
            ic_july: 3.909,
            ic_august: 4.631,
            ic_september: 5.67,
            ic_october: 5.726,
            ic_november: 5.858,
            ic_december: 5.999,
            ic_yearly: 5.171,
          },
          {
            ic_uuid: "9f571340-bed6-4aeb-a311-834946b8afe1",
            ic_lat: -56827,
            ic_lon: -427364,
            ic_city: "Miguel Leão",
            ic_states: "PIAUÍ",
            ic_january: 4.89,
            ic_february: 5.125,
            ic_march: 5.142,
            ic_april: 5.009,
            ic_may: 5.155,
            ic_june: 5.371,
            ic_july: 5.657,
            ic_august: 6.194,
            ic_september: 6.532,
            ic_october: 6.301,
            ic_november: 5.916,
            ic_december: 5.55,
            ic_yearly: 5.57,
          },
          {
            ic_uuid: "e6cb5994-4d1d-4b09-a8b2-d4e6861c1579",
            ic_lat: -224577,
            ic_lon: -434806,
            ic_city: "Miguel Pereira",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.772,
            ic_february: 6.136,
            ic_march: 4.955,
            ic_april: 4.341,
            ic_may: 3.623,
            ic_june: 3.407,
            ic_july: 3.506,
            ic_august: 4.292,
            ic_september: 4.546,
            ic_october: 4.988,
            ic_november: 5.007,
            ic_december: 5.723,
            ic_yearly: 4.691,
          },
          {
            ic_uuid: "f3caedd1-596a-4709-90e6-c19af4e13659",
            ic_lat: -201801,
            ic_lon: -480314,
            ic_city: "Miguelópolis",
            ic_states: "SÃO PAULO",
            ic_january: 5.775,
            ic_february: 6.105,
            ic_march: 5.222,
            ic_april: 5.021,
            ic_may: 4.336,
            ic_june: 4.022,
            ic_july: 4.304,
            ic_august: 5.148,
            ic_september: 5.099,
            ic_october: 5.623,
            ic_november: 5.782,
            ic_december: 6.101,
            ic_yearly: 5.211,
          },
          {
            ic_uuid: "041a380a-0d4c-47b1-9b5f-5eac5a45f013",
            ic_lat: -128671,
            ic_lon: -398615,
            ic_city: "Milagres",
            ic_states: "BAHIA",
            ic_january: 5.865,
            ic_february: 5.887,
            ic_march: 5.597,
            ic_april: 4.528,
            ic_may: 3.952,
            ic_june: 3.631,
            ic_july: 3.843,
            ic_august: 4.402,
            ic_september: 5.211,
            ic_october: 5.27,
            ic_november: 5.378,
            ic_december: 5.883,
            ic_yearly: 4.954,
          },
          {
            ic_uuid: "94e102d2-ca8e-41a9-9759-b7ec548ac53d",
            ic_lat: -73008,
            ic_lon: -389421,
            ic_city: "Milagres",
            ic_states: "CEARÁ",
            ic_january: 6.025,
            ic_february: 5.944,
            ic_march: 5.927,
            ic_april: 5.671,
            ic_may: 5.174,
            ic_june: 4.905,
            ic_july: 5.227,
            ic_august: 5.908,
            ic_september: 6.557,
            ic_october: 6.547,
            ic_november: 6.615,
            ic_december: 6.197,
            ic_yearly: 5.891,
          },
          {
            ic_uuid: "7ea920b3-143a-46d4-b5b8-2fb9b24e40a5",
            ic_lat: -35822,
            ic_lon: -426072,
            ic_city: "Milagres do Maranhão",
            ic_states: "MARANHÃO",
            ic_january: 5.278,
            ic_february: 5.299,
            ic_march: 5.377,
            ic_april: 5.17,
            ic_may: 5.203,
            ic_june: 5.319,
            ic_july: 5.52,
            ic_august: 6.085,
            ic_september: 6.417,
            ic_october: 6.359,
            ic_november: 6.101,
            ic_december: 5.631,
            ic_yearly: 5.646,
          },
          {
            ic_uuid: "1532b8c5-1818-4fc0-8418-b5ae40d9c230",
            ic_lat: -56729,
            ic_lon: -391878,
            ic_city: "Milhã",
            ic_states: "CEARÁ",
            ic_january: 5.589,
            ic_february: 5.587,
            ic_march: 5.73,
            ic_april: 5.278,
            ic_may: 5.022,
            ic_june: 5.003,
            ic_july: 5.346,
            ic_august: 6.073,
            ic_september: 6.495,
            ic_october: 6.52,
            ic_november: 6.513,
            ic_december: 5.962,
            ic_yearly: 5.76,
          },
          {
            ic_uuid: "f01024c7-f734-4fe4-a51b-e1af8f46ff79",
            ic_lat: -46833,
            ic_lon: -414177,
            ic_city: "Milton Brandão",
            ic_states: "PIAUÍ",
            ic_january: 5.199,
            ic_february: 5.345,
            ic_march: 5.501,
            ic_april: 5.128,
            ic_may: 5.129,
            ic_june: 5.157,
            ic_july: 5.458,
            ic_august: 6.188,
            ic_september: 6.695,
            ic_october: 6.581,
            ic_november: 6.391,
            ic_december: 5.734,
            ic_yearly: 5.709,
          },
          {
            ic_uuid: "d7287429-e6a0-4624-b18b-56c27d69504b",
            ic_lat: -150551,
            ic_lon: -481632,
            ic_city: "Mimoso de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.526,
            ic_february: 5.773,
            ic_march: 5.314,
            ic_april: 5.229,
            ic_may: 5.042,
            ic_june: 4.803,
            ic_july: 5.028,
            ic_august: 5.947,
            ic_september: 5.731,
            ic_october: 5.686,
            ic_november: 5.255,
            ic_december: 5.529,
            ic_yearly: 5.405,
          },
          {
            ic_uuid: "24272b1e-3f3e-43b8-8051-983e8ad1c22b",
            ic_lat: -210633,
            ic_lon: -413619,
            ic_city: "Mimoso do Sul",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.169,
            ic_february: 6.294,
            ic_march: 5.083,
            ic_april: 4.511,
            ic_may: 3.801,
            ic_june: 3.607,
            ic_july: 3.636,
            ic_august: 4.414,
            ic_september: 4.846,
            ic_october: 5.05,
            ic_november: 4.937,
            ic_december: 5.665,
            ic_yearly: 4.834,
          },
          {
            ic_uuid: "2843c496-ad53-4363-b251-40e27a642a6c",
            ic_lat: -135309,
            ic_lon: -48221,
            ic_city: "Minaçu",
            ic_states: "GOIÁS",
            ic_january: 5.414,
            ic_february: 5.497,
            ic_march: 5.196,
            ic_april: 5.15,
            ic_may: 5.044,
            ic_june: 4.732,
            ic_july: 5.135,
            ic_august: 5.786,
            ic_september: 5.699,
            ic_october: 5.534,
            ic_november: 5.125,
            ic_december: 5.336,
            ic_yearly: 5.304,
          },
          {
            ic_uuid: "a9374802-1981-4db3-8adc-40a5d86ba905",
            ic_lat: -93078,
            ic_lon: -36866,
            ic_city: "Minador do Negrão",
            ic_states: "ALAGOAS",
            ic_january: 6.084,
            ic_february: 5.924,
            ic_march: 5.917,
            ic_april: 5.264,
            ic_may: 4.374,
            ic_june: 3.951,
            ic_july: 4.085,
            ic_august: 4.728,
            ic_september: 5.631,
            ic_october: 6.0,
            ic_november: 6.48,
            ic_december: 6.416,
            ic_yearly: 5.405,
          },
          {
            ic_uuid: "5505a8b7-58c5-489b-af4f-a0742677017a",
            ic_lat: -30127,
            ic_lon: -520479,
            ic_city: "Minas do Leão",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.453,
            ic_february: 5.861,
            ic_march: 4.898,
            ic_april: 3.804,
            ic_may: 2.783,
            ic_june: 2.32,
            ic_july: 2.527,
            ic_august: 3.211,
            ic_september: 3.668,
            ic_october: 4.873,
            ic_november: 6.252,
            ic_december: 6.734,
            ic_yearly: 4.449,
          },
          {
            ic_uuid: "9e01e08d-70c5-4aa6-b244-adedf937f824",
            ic_lat: -172161,
            ic_lon: -425888,
            ic_city: "Minas Novas",
            ic_states: "MINAS GERAIS",
            ic_january: 6.073,
            ic_february: 6.334,
            ic_march: 5.338,
            ic_april: 4.646,
            ic_may: 4.038,
            ic_june: 3.882,
            ic_july: 4.084,
            ic_august: 4.886,
            ic_september: 5.385,
            ic_october: 5.545,
            ic_november: 5.157,
            ic_december: 5.884,
            ic_yearly: 5.104,
          },
          {
            ic_uuid: "202be9b5-0161-46fd-8a6e-f01966ae30f1",
            ic_lat: -216802,
            ic_lon: -446055,
            ic_city: "Minduri",
            ic_states: "MINAS GERAIS",
            ic_january: 5.487,
            ic_february: 5.73,
            ic_march: 4.882,
            ic_april: 4.494,
            ic_may: 3.816,
            ic_june: 3.62,
            ic_july: 3.844,
            ic_august: 4.753,
            ic_september: 4.992,
            ic_october: 5.271,
            ic_november: 5.157,
            ic_december: 5.536,
            ic_yearly: 4.798,
          },
          {
            ic_uuid: "1803073b-f3ea-41ba-a097-907635f00d11",
            ic_lat: -175658,
            ic_lon: -525541,
            ic_city: "Mineiros",
            ic_states: "GOIÁS",
            ic_january: 5.593,
            ic_february: 5.627,
            ic_march: 5.211,
            ic_april: 5.098,
            ic_may: 4.641,
            ic_june: 4.326,
            ic_july: 4.527,
            ic_august: 5.352,
            ic_september: 5.252,
            ic_october: 5.233,
            ic_november: 5.579,
            ic_december: 5.743,
            ic_yearly: 5.182,
          },
          {
            ic_uuid: "04453f3e-058a-43c2-ac34-d30b2df4a730",
            ic_lat: -224125,
            ic_lon: -484514,
            ic_city: "Mineiros do Tietê",
            ic_states: "SÃO PAULO",
            ic_january: 5.574,
            ic_february: 5.848,
            ic_march: 5.195,
            ic_april: 4.686,
            ic_may: 3.864,
            ic_june: 3.594,
            ic_july: 3.779,
            ic_august: 4.713,
            ic_september: 4.884,
            ic_october: 5.552,
            ic_november: 5.887,
            ic_december: 6.184,
            ic_yearly: 4.98,
          },
          {
            ic_uuid: "b9da8f8b-c4be-4072-ba0f-6cb69063ca9e",
            ic_lat: -111964,
            ic_lon: -615179,
            ic_city: "Ministro Andreazza",
            ic_states: "RONDÔNIA",
            ic_january: 4.341,
            ic_february: 4.38,
            ic_march: 4.425,
            ic_april: 4.435,
            ic_may: 4.065,
            ic_june: 4.374,
            ic_july: 4.553,
            ic_august: 4.927,
            ic_september: 4.878,
            ic_october: 5.018,
            ic_november: 4.944,
            ic_december: 4.767,
            ic_yearly: 4.592,
          },
          {
            ic_uuid: "b3dacedd-1bae-44a4-8fd3-0d4569f1d000",
            ic_lat: -19979,
            ic_lon: -501369,
            ic_city: "Mira Estrela",
            ic_states: "SÃO PAULO",
            ic_january: 5.843,
            ic_february: 6.057,
            ic_march: 5.367,
            ic_april: 4.95,
            ic_may: 4.271,
            ic_june: 3.927,
            ic_july: 4.206,
            ic_august: 5.043,
            ic_september: 5.096,
            ic_october: 5.715,
            ic_november: 5.975,
            ic_december: 6.216,
            ic_yearly: 5.222,
          },
          {
            ic_uuid: "16f0745b-4cf8-4efa-a540-cbb0b4314189",
            ic_lat: -16252,
            ic_lon: -441626,
            ic_city: "Mirabela",
            ic_states: "MINAS GERAIS",
            ic_january: 6.338,
            ic_february: 6.57,
            ic_march: 5.632,
            ic_april: 5.515,
            ic_may: 4.928,
            ic_june: 4.652,
            ic_july: 5.017,
            ic_august: 5.851,
            ic_september: 6.103,
            ic_october: 6.007,
            ic_november: 5.408,
            ic_december: 6.122,
            ic_yearly: 5.678,
          },
          {
            ic_uuid: "77e79020-060c-4512-b993-35328d014828",
            ic_lat: -242771,
            ic_lon: -474629,
            ic_city: "Miracatu",
            ic_states: "SÃO PAULO",
            ic_january: 5.195,
            ic_february: 5.354,
            ic_march: 4.502,
            ic_april: 3.887,
            ic_may: 3.104,
            ic_june: 2.735,
            ic_july: 2.759,
            ic_august: 3.45,
            ic_september: 3.549,
            ic_october: 3.883,
            ic_november: 4.744,
            ic_december: 5.438,
            ic_yearly: 4.05,
          },
          {
            ic_uuid: "f9a640b9-92e2-46b2-a2cf-1a7efb55b142",
            ic_lat: -214153,
            ic_lon: -421942,
            ic_city: "Miracema",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.938,
            ic_february: 6.181,
            ic_march: 5.117,
            ic_april: 4.423,
            ic_may: 3.754,
            ic_june: 3.483,
            ic_july: 3.605,
            ic_august: 4.384,
            ic_september: 4.778,
            ic_october: 5.118,
            ic_november: 4.953,
            ic_december: 5.832,
            ic_yearly: 4.797,
          },
          {
            ic_uuid: "22dbb792-0aa9-4b3a-a678-f72a690bba2f",
            ic_lat: -9566,
            ic_lon: -483934,
            ic_city: "Miracema do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 5.029,
            ic_february: 5.063,
            ic_march: 4.895,
            ic_april: 4.954,
            ic_may: 5.087,
            ic_june: 5.107,
            ic_july: 5.325,
            ic_august: 6.077,
            ic_september: 5.824,
            ic_october: 5.359,
            ic_november: 5.107,
            ic_december: 5.062,
            ic_yearly: 5.241,
          },
          {
            ic_uuid: "d190778e-bdb4-42f1-a45e-2622433f3ccc",
            ic_lat: -6375,
            ic_lon: -443686,
            ic_city: "Mirador",
            ic_states: "MARANHÃO",
            ic_january: 4.761,
            ic_february: 4.944,
            ic_march: 4.941,
            ic_april: 4.92,
            ic_may: 5.093,
            ic_june: 5.374,
            ic_july: 5.624,
            ic_august: 6.263,
            ic_september: 6.541,
            ic_october: 5.935,
            ic_november: 5.447,
            ic_december: 5.093,
            ic_yearly: 5.411,
          },
          {
            ic_uuid: "45959f5d-a221-4134-a8e5-9029b5ca5d00",
            ic_lat: -232554,
            ic_lon: -527765,
            ic_city: "Mirador",
            ic_states: "PARANÁ",
            ic_january: 6.02,
            ic_february: 5.873,
            ic_march: 5.4,
            ic_april: 4.569,
            ic_may: 3.629,
            ic_june: 3.271,
            ic_july: 3.477,
            ic_august: 4.453,
            ic_september: 4.664,
            ic_october: 5.424,
            ic_november: 6.151,
            ic_december: 6.438,
            ic_yearly: 4.948,
          },
          {
            ic_uuid: "1f14cbe1-c927-467f-9704-2788a8a1fe62",
            ic_lat: -208904,
            ic_lon: -423462,
            ic_city: "Miradouro",
            ic_states: "MINAS GERAIS",
            ic_january: 5.684,
            ic_february: 5.988,
            ic_march: 4.903,
            ic_april: 4.367,
            ic_may: 3.762,
            ic_june: 3.637,
            ic_july: 3.768,
            ic_august: 4.57,
            ic_september: 4.892,
            ic_october: 4.984,
            ic_november: 4.689,
            ic_december: 5.509,
            ic_yearly: 4.73,
          },
          {
            ic_uuid: "16582189-1f02-4dc9-a543-927f7dcb83e9",
            ic_lat: -274974,
            ic_lon: -536895,
            ic_city: "Miraguaí",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.445,
            ic_february: 5.901,
            ic_march: 5.142,
            ic_april: 4.071,
            ic_may: 3.119,
            ic_june: 2.552,
            ic_july: 2.922,
            ic_august: 3.704,
            ic_september: 4.005,
            ic_october: 5.174,
            ic_november: 6.224,
            ic_december: 6.599,
            ic_yearly: 4.655,
          },
          {
            ic_uuid: "3002b212-ef9c-42d2-8d3b-45fd36d7618a",
            ic_lat: -212026,
            ic_lon: -426126,
            ic_city: "Miraí",
            ic_states: "MINAS GERAIS",
            ic_january: 5.693,
            ic_february: 5.943,
            ic_march: 4.91,
            ic_april: 4.341,
            ic_may: 3.685,
            ic_june: 3.53,
            ic_july: 3.66,
            ic_august: 4.45,
            ic_september: 4.807,
            ic_october: 5.067,
            ic_november: 4.849,
            ic_december: 5.607,
            ic_yearly: 4.712,
          },
          {
            ic_uuid: "50cb4540-64a8-4a20-836b-dfb0f9d4785d",
            ic_lat: -35692,
            ic_lon: -399684,
            ic_city: "Miraíma",
            ic_states: "CEARÁ",
            ic_january: 5.167,
            ic_february: 5.268,
            ic_march: 5.105,
            ic_april: 4.819,
            ic_may: 5.141,
            ic_june: 5.095,
            ic_july: 5.345,
            ic_august: 6.161,
            ic_september: 6.529,
            ic_october: 6.409,
            ic_november: 6.177,
            ic_december: 5.475,
            ic_yearly: 5.558,
          },
          {
            ic_uuid: "a815db26-a584-472a-b0a7-531e3b97948d",
            ic_lat: -20236,
            ic_lon: -56375,
            ic_city: "Miranda",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.833,
            ic_february: 5.717,
            ic_march: 5.316,
            ic_april: 4.829,
            ic_may: 3.88,
            ic_june: 3.643,
            ic_july: 3.8,
            ic_august: 4.593,
            ic_september: 4.861,
            ic_october: 5.477,
            ic_november: 5.915,
            ic_december: 6.225,
            ic_yearly: 5.007,
          },
          {
            ic_uuid: "84bfd2c7-0733-4019-9a35-d5ed0b0056c0",
            ic_lat: -35635,
            ic_lon: -445818,
            ic_city: "Miranda do Norte",
            ic_states: "MARANHÃO",
            ic_january: 4.716,
            ic_february: 4.86,
            ic_march: 4.885,
            ic_april: 4.802,
            ic_may: 4.852,
            ic_june: 5.052,
            ic_july: 5.227,
            ic_august: 5.81,
            ic_september: 6.079,
            ic_october: 5.727,
            ic_november: 5.322,
            ic_december: 5.065,
            ic_yearly: 5.2,
          },
          {
            ic_uuid: "d7bc3915-5d7f-42c0-8b30-965fc927350f",
            ic_lat: -81216,
            ic_lon: -387392,
            ic_city: "Mirandiba",
            ic_states: "PERNAMBUCO",
            ic_january: 6.228,
            ic_february: 6.006,
            ic_march: 6.015,
            ic_april: 5.544,
            ic_may: 4.826,
            ic_june: 4.404,
            ic_july: 4.67,
            ic_august: 5.482,
            ic_september: 6.424,
            ic_october: 6.526,
            ic_november: 6.75,
            ic_december: 6.425,
            ic_yearly: 5.775,
          },
          {
            ic_uuid: "37c55260-7f3e-40f7-884f-906e63bd561e",
            ic_lat: -211318,
            ic_lon: -511039,
            ic_city: "Mirandópolis",
            ic_states: "SÃO PAULO",
            ic_january: 5.881,
            ic_february: 5.951,
            ic_march: 5.431,
            ic_april: 4.88,
            ic_may: 4.088,
            ic_june: 3.783,
            ic_july: 3.957,
            ic_august: 4.935,
            ic_september: 4.966,
            ic_october: 5.598,
            ic_november: 6.075,
            ic_december: 6.297,
            ic_yearly: 5.153,
          },
          {
            ic_uuid: "bfac28f1-3262-4d0b-afac-b1ed6128b7c7",
            ic_lat: -109543,
            ic_lon: -405748,
            ic_city: "Mirangaba",
            ic_states: "BAHIA",
            ic_january: 6.187,
            ic_february: 5.968,
            ic_march: 5.831,
            ic_april: 4.855,
            ic_may: 4.126,
            ic_june: 3.845,
            ic_july: 4.077,
            ic_august: 4.797,
            ic_september: 5.713,
            ic_october: 5.818,
            ic_november: 6.003,
            ic_december: 6.074,
            ic_yearly: 5.274,
          },
          {
            ic_uuid: "abe74dd6-b06b-47ca-82c1-cb42bdb41ae9",
            ic_lat: -95295,
            ic_lon: -485926,
            ic_city: "Miranorte",
            ic_states: "TOCANTINS",
            ic_january: 5.043,
            ic_february: 5.079,
            ic_march: 4.833,
            ic_april: 4.91,
            ic_may: 5.042,
            ic_june: 5.078,
            ic_july: 5.287,
            ic_august: 6.007,
            ic_september: 5.762,
            ic_october: 5.201,
            ic_november: 5.03,
            ic_december: 4.964,
            ic_yearly: 5.186,
          },
          {
            ic_uuid: "10e01e51-8279-45ef-9793-0e334124baa1",
            ic_lat: -142405,
            ic_lon: -407731,
            ic_city: "Mirante",
            ic_states: "BAHIA",
            ic_january: 6.029,
            ic_february: 6.015,
            ic_march: 5.584,
            ic_april: 4.73,
            ic_may: 4.188,
            ic_june: 3.778,
            ic_july: 3.966,
            ic_august: 4.595,
            ic_september: 5.475,
            ic_october: 5.636,
            ic_november: 5.668,
            ic_december: 6.121,
            ic_yearly: 5.149,
          },
          {
            ic_uuid: "10007486-e4a3-4ab4-84ad-dd3f80493928",
            ic_lat: -110294,
            ic_lon: -626701,
            ic_city: "Mirante da Serra",
            ic_states: "RONDÔNIA",
            ic_january: 4.404,
            ic_february: 4.318,
            ic_march: 4.379,
            ic_april: 4.454,
            ic_may: 3.999,
            ic_june: 4.322,
            ic_july: 4.459,
            ic_august: 4.903,
            ic_september: 4.924,
            ic_october: 5.113,
            ic_november: 4.958,
            ic_december: 4.667,
            ic_yearly: 4.575,
          },
          {
            ic_uuid: "e4b593af-66bb-4a44-b9b4-aa7e614b3072",
            ic_lat: -222909,
            ic_lon: -519088,
            ic_city: "Mirante do Paranapanema",
            ic_states: "SÃO PAULO",
            ic_january: 6.033,
            ic_february: 5.977,
            ic_march: 5.43,
            ic_april: 4.767,
            ic_may: 3.851,
            ic_june: 3.529,
            ic_july: 3.733,
            ic_august: 4.687,
            ic_september: 4.806,
            ic_october: 5.551,
            ic_november: 6.132,
            ic_december: 6.481,
            ic_yearly: 5.081,
          },
          {
            ic_uuid: "5ee0aade-91c2-44b0-bf85-df0b537adb09",
            ic_lat: -229661,
            ic_lon: -51485,
            ic_city: "Miraselva",
            ic_states: "PARANÁ",
            ic_january: 5.906,
            ic_february: 5.905,
            ic_march: 5.403,
            ic_april: 4.727,
            ic_may: 3.712,
            ic_june: 3.425,
            ic_july: 3.655,
            ic_august: 4.585,
            ic_september: 4.767,
            ic_october: 5.454,
            ic_november: 6.172,
            ic_december: 6.392,
            ic_yearly: 5.009,
          },
          {
            ic_uuid: "728b028c-6ec5-402f-bc6b-e19dbf5ebc94",
            ic_lat: -208173,
            ic_lon: -49521,
            ic_city: "Mirassol",
            ic_states: "SÃO PAULO",
            ic_january: 5.853,
            ic_february: 5.859,
            ic_march: 5.203,
            ic_april: 4.867,
            ic_may: 4.116,
            ic_june: 3.88,
            ic_july: 4.137,
            ic_august: 5.042,
            ic_september: 5.064,
            ic_october: 5.626,
            ic_november: 5.944,
            ic_december: 6.151,
            ic_yearly: 5.145,
          },
          {
            ic_uuid: "0a9df365-d5c4-4796-91ef-9e9905f68213",
            ic_lat: -156746,
            ic_lon: -580896,
            ic_city: "Mirassol d'Oeste",
            ic_states: "MATO GROSSO",
            ic_january: 5.423,
            ic_february: 5.199,
            ic_march: 5.01,
            ic_april: 4.748,
            ic_may: 4.135,
            ic_june: 4.191,
            ic_july: 4.298,
            ic_august: 5.052,
            ic_september: 5.038,
            ic_october: 5.244,
            ic_november: 5.408,
            ic_december: 5.532,
            ic_yearly: 4.94,
          },
          {
            ic_uuid: "7468478b-df3e-4339-a5bd-fb8c6639dcd9",
            ic_lat: -20617,
            ic_lon: -494639,
            ic_city: "Mirassolândia",
            ic_states: "SÃO PAULO",
            ic_january: 5.763,
            ic_february: 5.886,
            ic_march: 5.194,
            ic_april: 4.93,
            ic_may: 4.169,
            ic_june: 3.943,
            ic_july: 4.164,
            ic_august: 5.082,
            ic_september: 5.091,
            ic_october: 5.611,
            ic_november: 5.924,
            ic_december: 6.117,
            ic_yearly: 5.156,
          },
          {
            ic_uuid: "6be44402-2593-46f0-86e3-6e6e4cdc9d33",
            ic_lat: -147431,
            ic_lon: -444177,
            ic_city: "Miravânia",
            ic_states: "MINAS GERAIS",
            ic_january: 6.327,
            ic_february: 6.597,
            ic_march: 5.748,
            ic_april: 5.633,
            ic_may: 5.08,
            ic_june: 4.898,
            ic_july: 5.323,
            ic_august: 5.976,
            ic_september: 6.299,
            ic_october: 6.182,
            ic_november: 5.569,
            ic_december: 6.202,
            ic_yearly: 5.819,
          },
          {
            ic_uuid: "bfe7b057-4fe1-40df-bf2b-3447fcd8ff19",
            ic_lat: -272011,
            ic_lon: -500688,
            ic_city: "Mirim Doce",
            ic_states: "SANTA CATARINA",
            ic_january: 5.436,
            ic_february: 5.175,
            ic_march: 4.53,
            ic_april: 3.613,
            ic_may: 2.851,
            ic_june: 2.323,
            ic_july: 2.493,
            ic_august: 3.314,
            ic_september: 3.445,
            ic_october: 4.01,
            ic_november: 5.331,
            ic_december: 5.604,
            ic_yearly: 4.01,
          },
          {
            ic_uuid: "193ed7c8-5d48-4728-8d3a-199c7092701b",
            ic_lat: -20672,
            ic_lon: -447847,
            ic_city: "Mirinzal",
            ic_states: "MARANHÃO",
            ic_january: 4.724,
            ic_february: 4.7,
            ic_march: 4.575,
            ic_april: 4.454,
            ic_may: 4.668,
            ic_june: 4.947,
            ic_july: 5.08,
            ic_august: 5.581,
            ic_september: 5.736,
            ic_october: 5.471,
            ic_november: 5.37,
            ic_december: 5.054,
            ic_yearly: 5.03,
          },
          {
            ic_uuid: "a86252b2-1c62-4c13-96e5-aebd20931314",
            ic_lat: -250923,
            ic_lon: -542481,
            ic_city: "Missal",
            ic_states: "PARANÁ",
            ic_january: 6.311,
            ic_february: 5.806,
            ic_march: 5.324,
            ic_april: 4.264,
            ic_may: 3.3,
            ic_june: 2.898,
            ic_july: 3.094,
            ic_august: 4.079,
            ic_september: 4.361,
            ic_october: 5.277,
            ic_november: 6.162,
            ic_december: 6.632,
            ic_yearly: 4.792,
          },
          {
            ic_uuid: "4fd5cc1d-4a79-4924-b403-074f1b6a5a45",
            ic_lat: -72489,
            ic_lon: -391405,
            ic_city: "Missão Velha",
            ic_states: "CEARÁ",
            ic_january: 6.018,
            ic_february: 5.887,
            ic_march: 5.935,
            ic_april: 5.628,
            ic_may: 5.134,
            ic_june: 4.844,
            ic_july: 5.171,
            ic_august: 5.929,
            ic_september: 6.528,
            ic_october: 6.53,
            ic_november: 6.61,
            ic_december: 6.195,
            ic_yearly: 5.867,
          },
          {
            ic_uuid: "9e4b6292-b5cc-44cd-98f6-f07e6806eb59",
            ic_lat: -25835,
            ic_lon: -495046,
            ic_city: "Mocajuba",
            ic_states: "PARÁ",
            ic_january: 4.549,
            ic_february: 4.657,
            ic_march: 4.691,
            ic_april: 4.718,
            ic_may: 4.801,
            ic_june: 4.933,
            ic_july: 5.033,
            ic_august: 5.334,
            ic_september: 5.284,
            ic_october: 5.153,
            ic_november: 4.955,
            ic_december: 4.585,
            ic_yearly: 4.891,
          },
          {
            ic_uuid: "88ff0b41-7872-4da4-ac00-6b0d180f0e73",
            ic_lat: -214652,
            ic_lon: -470028,
            ic_city: "Mococa",
            ic_states: "SÃO PAULO",
            ic_january: 5.646,
            ic_february: 5.825,
            ic_march: 5.124,
            ic_april: 4.8,
            ic_may: 4.067,
            ic_june: 3.822,
            ic_july: 4.036,
            ic_august: 4.895,
            ic_september: 4.979,
            ic_october: 5.537,
            ic_november: 5.738,
            ic_december: 5.914,
            ic_yearly: 5.032,
          },
          {
            ic_uuid: "5d57a7cd-53cb-4f1c-a5f9-328c38839529",
            ic_lat: -267734,
            ic_lon: -530404,
            ic_city: "Modelo",
            ic_states: "SANTA CATARINA",
            ic_january: 6.196,
            ic_february: 5.678,
            ic_march: 5.049,
            ic_april: 4.071,
            ic_may: 3.151,
            ic_june: 2.637,
            ic_july: 2.977,
            ic_august: 3.831,
            ic_september: 4.071,
            ic_october: 5.09,
            ic_november: 6.01,
            ic_december: 6.374,
            ic_yearly: 4.595,
          },
          {
            ic_uuid: "a3d5fbad-a367-469d-8bd1-8a0756086f5c",
            ic_lat: -203403,
            ic_lon: -440513,
            ic_city: "Moeda",
            ic_states: "MINAS GERAIS",
            ic_january: 5.796,
            ic_february: 5.921,
            ic_march: 5.114,
            ic_april: 4.778,
            ic_may: 4.201,
            ic_june: 4.011,
            ic_july: 4.226,
            ic_august: 5.11,
            ic_september: 5.373,
            ic_october: 5.493,
            ic_november: 5.239,
            ic_december: 5.609,
            ic_yearly: 5.073,
          },
          {
            ic_uuid: "44efae98-3061-45d9-a8d1-26df3b3e3c7a",
            ic_lat: -198416,
            ic_lon: -454145,
            ic_city: "Moema",
            ic_states: "MINAS GERAIS",
            ic_january: 5.876,
            ic_february: 6.146,
            ic_march: 5.133,
            ic_april: 5.014,
            ic_may: 4.443,
            ic_june: 4.199,
            ic_july: 4.451,
            ic_august: 5.365,
            ic_september: 5.503,
            ic_october: 5.629,
            ic_november: 5.384,
            ic_december: 5.7,
            ic_yearly: 5.237,
          },
          {
            ic_uuid: "89cac900-0348-45d2-854b-d3027db32fad",
            ic_lat: -72955,
            ic_lon: -354802,
            ic_city: "Mogeiro",
            ic_states: "PARAÍBA",
            ic_january: 5.548,
            ic_february: 5.786,
            ic_march: 5.806,
            ic_april: 5.279,
            ic_may: 4.584,
            ic_june: 4.151,
            ic_july: 4.251,
            ic_august: 4.997,
            ic_september: 5.426,
            ic_october: 5.633,
            ic_november: 5.976,
            ic_december: 5.837,
            ic_yearly: 5.273,
          },
          {
            ic_uuid: "b54deb1d-f377-46f7-9920-19db6e54228a",
            ic_lat: -235213,
            ic_lon: -461858,
            ic_city: "Mogi das Cruzes",
            ic_states: "SÃO PAULO",
            ic_january: 5.297,
            ic_february: 5.579,
            ic_march: 4.724,
            ic_april: 4.113,
            ic_may: 3.334,
            ic_june: 3.07,
            ic_july: 3.197,
            ic_august: 4.119,
            ic_september: 4.167,
            ic_october: 4.744,
            ic_november: 5.096,
            ic_december: 5.661,
            ic_yearly: 4.425,
          },
          {
            ic_uuid: "3cacd9e8-0122-49db-a171-32e890d88bed",
            ic_lat: -223679,
            ic_lon: -469432,
            ic_city: "Mogi Guaçu",
            ic_states: "SÃO PAULO",
            ic_january: 5.577,
            ic_february: 5.845,
            ic_march: 5.143,
            ic_april: 4.686,
            ic_may: 3.904,
            ic_june: 3.63,
            ic_july: 3.829,
            ic_august: 4.707,
            ic_september: 4.929,
            ic_october: 5.54,
            ic_november: 5.736,
            ic_december: 6.098,
            ic_yearly: 4.968,
          },
          {
            ic_uuid: "d01e8232-0b48-49e3-bc4b-1728c01364c0",
            ic_lat: -224324,
            ic_lon: -469509,
            ic_city: "Mogi Mirim",
            ic_states: "SÃO PAULO",
            ic_january: 5.577,
            ic_february: 5.845,
            ic_march: 5.143,
            ic_april: 4.686,
            ic_may: 3.904,
            ic_june: 3.63,
            ic_july: 3.829,
            ic_august: 4.707,
            ic_september: 4.929,
            ic_october: 5.54,
            ic_november: 5.736,
            ic_december: 6.098,
            ic_yearly: 4.968,
          },
          {
            ic_uuid: "d4b50452-449d-475d-a946-621199a3add1",
            ic_lat: -165449,
            ic_lon: -507387,
            ic_city: "Moiporá",
            ic_states: "GOIÁS",
            ic_january: 5.58,
            ic_february: 5.595,
            ic_march: 5.259,
            ic_april: 5.221,
            ic_may: 4.852,
            ic_june: 4.512,
            ic_july: 4.741,
            ic_august: 5.471,
            ic_september: 5.383,
            ic_october: 5.561,
            ic_november: 5.494,
            ic_december: 5.547,
            ic_yearly: 5.268,
          },
          {
            ic_uuid: "829e8697-fc49-4b96-aff0-f3a750c11a69",
            ic_lat: -105791,
            ic_lon: -373431,
            ic_city: "Moita Bonita",
            ic_states: "SERGIPE",
            ic_january: 6.178,
            ic_february: 6.019,
            ic_march: 5.905,
            ic_april: 5.07,
            ic_may: 4.215,
            ic_june: 3.952,
            ic_july: 4.052,
            ic_august: 4.626,
            ic_september: 5.39,
            ic_october: 5.778,
            ic_november: 6.218,
            ic_december: 6.291,
            ic_yearly: 5.308,
          },
          {
            ic_uuid: "888e7e07-3bd5-4630-8850-6cd55e5f6006",
            ic_lat: -18903,
            ic_lon: -487671,
            ic_city: "Moju",
            ic_states: "PARÁ",
            ic_january: 4.501,
            ic_february: 4.51,
            ic_march: 4.569,
            ic_april: 4.598,
            ic_may: 4.735,
            ic_june: 4.934,
            ic_july: 5.067,
            ic_august: 5.312,
            ic_september: 5.307,
            ic_october: 5.207,
            ic_november: 5.011,
            ic_december: 4.728,
            ic_yearly: 4.873,
          },
          {
            ic_uuid: "25e2fd48-3ced-46c0-9f73-bc1b5eac015c",
            ic_lat: -26819,
            ic_lon: -546515,
            ic_city: "Mojuí dos Campos",
            ic_states: "PARÁ",
            ic_january: 4.412,
            ic_february: 4.379,
            ic_march: 4.238,
            ic_april: 4.116,
            ic_may: 4.191,
            ic_june: 4.445,
            ic_july: 4.384,
            ic_august: 4.914,
            ic_september: 4.86,
            ic_october: 5.152,
            ic_november: 4.993,
            ic_december: 4.479,
            ic_yearly: 4.547,
          },
          {
            ic_uuid: "af43f2dc-c72c-481b-bd94-4dc48bef44b0",
            ic_lat: -57389,
            ic_lon: -396303,
            ic_city: "Mombaça",
            ic_states: "CEARÁ",
            ic_january: 5.359,
            ic_february: 5.475,
            ic_march: 5.602,
            ic_april: 5.199,
            ic_may: 4.998,
            ic_june: 4.978,
            ic_july: 5.351,
            ic_august: 6.057,
            ic_september: 6.468,
            ic_october: 6.396,
            ic_november: 6.284,
            ic_december: 5.709,
            ic_yearly: 5.657,
          },
          {
            ic_uuid: "16345294-472a-4f1d-91b2-447f8e2c5547",
            ic_lat: -22929,
            ic_lon: -475594,
            ic_city: "Mombuca",
            ic_states: "SÃO PAULO",
            ic_january: 5.68,
            ic_february: 5.845,
            ic_march: 5.126,
            ic_april: 4.584,
            ic_may: 3.785,
            ic_june: 3.483,
            ic_july: 3.671,
            ic_august: 4.619,
            ic_september: 4.776,
            ic_october: 5.425,
            ic_november: 5.684,
            ic_december: 6.118,
            ic_yearly: 4.9,
          },
          {
            ic_uuid: "cbbcd113-be0b-4168-b112-8bc5e1441142",
            ic_lat: -34816,
            ic_lon: -4525,
            ic_city: "Monção",
            ic_states: "MARANHÃO",
            ic_january: 4.667,
            ic_february: 4.922,
            ic_march: 4.959,
            ic_april: 4.964,
            ic_may: 4.793,
            ic_june: 5.037,
            ic_july: 5.152,
            ic_august: 5.605,
            ic_september: 5.892,
            ic_october: 5.573,
            ic_november: 5.277,
            ic_december: 4.989,
            ic_yearly: 5.153,
          },
          {
            ic_uuid: "23b2a120-c7e5-4b21-b3d0-718bb6b844e7",
            ic_lat: -2085,
            ic_lon: -500919,
            ic_city: "Monções",
            ic_states: "SÃO PAULO",
            ic_january: 5.841,
            ic_february: 5.895,
            ic_march: 5.249,
            ic_april: 4.904,
            ic_may: 4.142,
            ic_june: 3.848,
            ic_july: 4.107,
            ic_august: 5.015,
            ic_september: 5.007,
            ic_october: 5.627,
            ic_november: 5.982,
            ic_december: 6.178,
            ic_yearly: 5.149,
          },
          {
            ic_uuid: "6b3f316e-2fb5-4f12-97a6-3516e0cb89e5",
            ic_lat: -271013,
            ic_lon: -534036,
            ic_city: "Mondaí",
            ic_states: "SANTA CATARINA",
            ic_january: 6.321,
            ic_february: 5.783,
            ic_march: 5.102,
            ic_april: 4.015,
            ic_may: 3.073,
            ic_june: 2.565,
            ic_july: 2.868,
            ic_august: 3.737,
            ic_september: 4.019,
            ic_october: 5.139,
            ic_november: 6.164,
            ic_december: 6.487,
            ic_yearly: 4.606,
          },
          {
            ic_uuid: "227c3808-5d89-41a5-bdc1-8bc568bf854b",
            ic_lat: -24087,
            ic_lon: -46629,
            ic_city: "Mongaguá",
            ic_states: "SÃO PAULO",
            ic_january: 5.09,
            ic_february: 5.162,
            ic_march: 4.296,
            ic_april: 3.884,
            ic_may: 3.136,
            ic_june: 2.753,
            ic_july: 2.791,
            ic_august: 3.378,
            ic_september: 3.365,
            ic_october: 3.703,
            ic_november: 4.54,
            ic_december: 5.087,
            ic_yearly: 3.932,
          },
          {
            ic_uuid: "5b3ff599-25b6-45ec-b345-ec7bd179414f",
            ic_lat: -183249,
            ic_lon: -441183,
            ic_city: "Monjolos",
            ic_states: "MINAS GERAIS",
            ic_january: 6.134,
            ic_february: 6.42,
            ic_march: 5.48,
            ic_april: 5.213,
            ic_may: 4.674,
            ic_june: 4.368,
            ic_july: 4.679,
            ic_august: 5.343,
            ic_september: 5.821,
            ic_october: 5.924,
            ic_november: 5.476,
            ic_december: 5.973,
            ic_yearly: 5.459,
          },
          {
            ic_uuid: "2dfbccb8-2d4a-4885-a673-4c161181befe",
            ic_lat: -55624,
            ic_lon: -426078,
            ic_city: "Monsenhor Gil",
            ic_states: "PIAUÍ",
            ic_january: 5.031,
            ic_february: 5.185,
            ic_march: 5.203,
            ic_april: 5.058,
            ic_may: 5.168,
            ic_june: 5.391,
            ic_july: 5.54,
            ic_august: 6.256,
            ic_september: 6.58,
            ic_october: 6.41,
            ic_november: 6.126,
            ic_december: 5.72,
            ic_yearly: 5.639,
          },
          {
            ic_uuid: "4c4d7e06-1b86-4c49-9f32-ef69adb6678e",
            ic_lat: -69958,
            ic_lon: -4103,
            ic_city: "Monsenhor Hipólito",
            ic_states: "PIAUÍ",
            ic_january: 5.6,
            ic_february: 5.472,
            ic_march: 5.67,
            ic_april: 5.314,
            ic_may: 5.124,
            ic_june: 5.065,
            ic_july: 5.483,
            ic_august: 6.231,
            ic_september: 6.684,
            ic_october: 6.479,
            ic_november: 6.425,
            ic_december: 6.056,
            ic_yearly: 5.8,
          },
          {
            ic_uuid: "13610f91-1443-48c7-af5b-394ad7b92a3a",
            ic_lat: -217584,
            ic_lon: -455394,
            ic_city: "Monsenhor Paulo",
            ic_states: "MINAS GERAIS",
            ic_january: 5.416,
            ic_february: 5.709,
            ic_march: 4.85,
            ic_april: 4.584,
            ic_may: 3.918,
            ic_june: 3.685,
            ic_july: 3.906,
            ic_august: 4.763,
            ic_september: 5.001,
            ic_october: 5.387,
            ic_november: 5.239,
            ic_december: 5.62,
            ic_yearly: 4.84,
          },
          {
            ic_uuid: "308510e7-36c3-4950-90f3-e3866d726724",
            ic_lat: -47914,
            ic_lon: -40065,
            ic_city: "Monsenhor Tabosa",
            ic_states: "CEARÁ",
            ic_january: 5.131,
            ic_february: 5.175,
            ic_march: 5.197,
            ic_april: 4.734,
            ic_may: 4.815,
            ic_june: 4.73,
            ic_july: 5.23,
            ic_august: 5.993,
            ic_september: 6.498,
            ic_october: 6.366,
            ic_november: 6.241,
            ic_december: 5.541,
            ic_yearly: 5.471,
          },
          {
            ic_uuid: "14d828db-7689-42fb-8309-09370ebd4796",
            ic_lat: -70889,
            ic_lon: -359595,
            ic_city: "Montadas",
            ic_states: "PARAÍBA",
            ic_january: 5.813,
            ic_february: 5.928,
            ic_march: 5.877,
            ic_april: 5.374,
            ic_may: 4.781,
            ic_june: 4.224,
            ic_july: 4.383,
            ic_august: 5.136,
            ic_september: 5.683,
            ic_october: 5.952,
            ic_november: 6.133,
            ic_december: 5.958,
            ic_yearly: 5.437,
          },
          {
            ic_uuid: "785ac0bf-36fa-4cf0-9df3-0fb015ff0686",
            ic_lat: -144238,
            ic_lon: -443699,
            ic_city: "Montalvânia",
            ic_states: "MINAS GERAIS",
            ic_january: 6.467,
            ic_february: 6.516,
            ic_march: 5.833,
            ic_april: 5.631,
            ic_may: 5.062,
            ic_june: 4.861,
            ic_july: 5.241,
            ic_august: 5.759,
            ic_september: 6.161,
            ic_october: 6.197,
            ic_november: 5.74,
            ic_december: 6.316,
            ic_yearly: 5.815,
          },
          {
            ic_uuid: "d64d47ab-21e4-4100-b697-f7b5373f90ad",
            ic_lat: -181272,
            ic_lon: -403645,
            ic_city: "Montanha",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.192,
            ic_february: 6.162,
            ic_march: 5.366,
            ic_april: 4.611,
            ic_may: 3.824,
            ic_june: 3.565,
            ic_july: 3.671,
            ic_august: 4.278,
            ic_september: 4.849,
            ic_october: 5.187,
            ic_november: 5.112,
            ic_december: 6.015,
            ic_yearly: 4.903,
          },
          {
            ic_uuid: "b592ed49-4bd7-4a12-bf26-4e640e1e40a6",
            ic_lat: -64856,
            ic_lon: -352844,
            ic_city: "Montanhas",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.922,
            ic_february: 6.019,
            ic_march: 6.029,
            ic_april: 5.421,
            ic_may: 4.845,
            ic_june: 4.4,
            ic_july: 4.52,
            ic_august: 5.266,
            ic_september: 5.76,
            ic_october: 5.988,
            ic_november: 6.202,
            ic_december: 6.017,
            ic_yearly: 5.533,
          },
          {
            ic_uuid: "ebf64501-fab8-42d6-8878-df3ebf52fd14",
            ic_lat: -286466,
            ic_lon: -520772,
            ic_city: "Montauri",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.146,
            ic_february: 5.777,
            ic_march: 4.894,
            ic_april: 3.939,
            ic_may: 2.922,
            ic_june: 2.471,
            ic_july: 2.769,
            ic_august: 3.51,
            ic_september: 3.774,
            ic_october: 4.904,
            ic_november: 6.114,
            ic_december: 6.566,
            ic_yearly: 4.482,
          },
          {
            ic_uuid: "82356a66-a918-491f-8d2f-a1ec03fa3ca9",
            ic_lat: -19981,
            ic_lon: -540728,
            ic_city: "Monte Alegre",
            ic_states: "PARÁ",
            ic_january: 4.892,
            ic_february: 4.774,
            ic_march: 4.788,
            ic_april: 4.628,
            ic_may: 4.696,
            ic_june: 4.939,
            ic_july: 4.9,
            ic_august: 5.208,
            ic_september: 5.129,
            ic_october: 5.409,
            ic_november: 5.386,
            ic_december: 4.959,
            ic_yearly: 4.975,
          },
          {
            ic_uuid: "5145b4b9-b051-4c1c-914f-8b8377ddfb3e",
            ic_lat: -60692,
            ic_lon: -353326,
            ic_city: "Monte Alegre",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.976,
            ic_february: 6.031,
            ic_march: 6.074,
            ic_april: 5.504,
            ic_may: 4.946,
            ic_june: 4.493,
            ic_july: 4.577,
            ic_august: 5.329,
            ic_september: 5.843,
            ic_october: 6.074,
            ic_november: 6.334,
            ic_december: 6.009,
            ic_yearly: 5.599,
          },
          {
            ic_uuid: "5934fea0-04e7-4d68-9c90-7c6cbed02d4d",
            ic_lat: -132583,
            ic_lon: -468915,
            ic_city: "Monte Alegre de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.909,
            ic_february: 5.756,
            ic_march: 5.408,
            ic_april: 5.405,
            ic_may: 5.139,
            ic_june: 4.997,
            ic_july: 5.354,
            ic_august: 5.903,
            ic_september: 5.95,
            ic_october: 5.73,
            ic_november: 5.344,
            ic_december: 5.822,
            ic_yearly: 5.56,
          },
          {
            ic_uuid: "8a2735b0-2225-4888-9c57-8e68ae8643a0",
            ic_lat: -18871,
            ic_lon: -48881,
            ic_city: "Monte Alegre de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.591,
            ic_february: 5.818,
            ic_march: 5.074,
            ic_april: 4.996,
            ic_may: 4.472,
            ic_june: 4.242,
            ic_july: 4.527,
            ic_august: 5.506,
            ic_september: 5.309,
            ic_october: 5.622,
            ic_november: 5.657,
            ic_december: 5.794,
            ic_yearly: 5.217,
          },
          {
            ic_uuid: "2afea0c7-f612-4717-8f76-f7efdd5da12d",
            ic_lat: -10026,
            ic_lon: -375619,
            ic_city: "Monte Alegre de Sergipe",
            ic_states: "SERGIPE",
            ic_january: 6.026,
            ic_february: 5.899,
            ic_march: 5.862,
            ic_april: 5.126,
            ic_may: 4.283,
            ic_june: 3.971,
            ic_july: 4.131,
            ic_august: 4.701,
            ic_september: 5.468,
            ic_october: 5.697,
            ic_november: 6.223,
            ic_december: 6.2,
            ic_yearly: 5.299,
          },
          {
            ic_uuid: "a0b3aea7-8844-485d-a155-8b141e9fcf3f",
            ic_lat: -97541,
            ic_lon: -453041,
            ic_city: "Monte Alegre do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.786,
            ic_february: 5.667,
            ic_march: 5.453,
            ic_april: 5.386,
            ic_may: 5.481,
            ic_june: 5.221,
            ic_july: 5.356,
            ic_august: 6.066,
            ic_september: 6.376,
            ic_october: 6.132,
            ic_november: 5.704,
            ic_december: 5.808,
            ic_yearly: 5.703,
          },
          {
            ic_uuid: "6e7f267e-0d37-44a0-86d1-676252474888",
            ic_lat: -226822,
            ic_lon: -466814,
            ic_city: "Monte Alegre do Sul",
            ic_states: "SÃO PAULO",
            ic_january: 5.382,
            ic_february: 5.716,
            ic_march: 5.018,
            ic_april: 4.594,
            ic_may: 3.861,
            ic_june: 3.581,
            ic_july: 3.769,
            ic_august: 4.704,
            ic_september: 4.889,
            ic_october: 5.457,
            ic_november: 5.606,
            ic_december: 5.962,
            ic_yearly: 4.878,
          },
          {
            ic_uuid: "d3c3a400-5e0e-4779-a1c5-109b00575347",
            ic_lat: -28681,
            ic_lon: -507838,
            ic_city: "Monte Alegre dos Campos",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 5.996,
            ic_february: 5.627,
            ic_march: 4.787,
            ic_april: 3.913,
            ic_may: 2.921,
            ic_june: 2.469,
            ic_july: 2.795,
            ic_august: 3.56,
            ic_september: 3.763,
            ic_october: 4.862,
            ic_november: 6.1,
            ic_december: 6.339,
            ic_yearly: 4.428,
          },
          {
            ic_uuid: "4d1739c7-1323-418a-a478-b0103753cbad",
            ic_lat: -21266,
            ic_lon: -484975,
            ic_city: "Monte Alto",
            ic_states: "SÃO PAULO",
            ic_january: 5.709,
            ic_february: 5.92,
            ic_march: 5.136,
            ic_april: 4.784,
            ic_may: 4.114,
            ic_june: 3.85,
            ic_july: 4.079,
            ic_august: 4.926,
            ic_september: 5.028,
            ic_october: 5.617,
            ic_november: 5.899,
            ic_december: 6.133,
            ic_yearly: 5.1,
          },
          {
            ic_uuid: "2a1a7cab-4b7e-4b0e-9b4c-5705e8dcb36e",
            ic_lat: -20773,
            ic_lon: -497139,
            ic_city: "Monte Aprazível",
            ic_states: "SÃO PAULO",
            ic_january: 5.804,
            ic_february: 5.871,
            ic_march: 5.26,
            ic_april: 4.889,
            ic_may: 4.145,
            ic_june: 3.884,
            ic_july: 4.127,
            ic_august: 5.063,
            ic_september: 5.059,
            ic_october: 5.629,
            ic_november: 5.96,
            ic_december: 6.192,
            ic_yearly: 5.157,
          },
          {
            ic_uuid: "8ebe1074-947e-4211-9532-b98bb385a44b",
            ic_lat: -151518,
            ic_lon: -428721,
            ic_city: "Monte Azul",
            ic_states: "MINAS GERAIS",
            ic_january: 6.461,
            ic_february: 6.638,
            ic_march: 5.859,
            ic_april: 5.414,
            ic_may: 4.827,
            ic_june: 4.629,
            ic_july: 4.853,
            ic_august: 5.571,
            ic_september: 6.065,
            ic_october: 6.189,
            ic_november: 5.72,
            ic_december: 6.358,
            ic_yearly: 5.715,
          },
          {
            ic_uuid: "22bc4f51-1d07-4711-a30d-1bd894dca520",
            ic_lat: -20907,
            ic_lon: -486391,
            ic_city: "Monte Azul Paulista",
            ic_states: "SÃO PAULO",
            ic_january: 5.893,
            ic_february: 6.012,
            ic_march: 5.209,
            ic_april: 4.863,
            ic_may: 4.164,
            ic_june: 3.917,
            ic_july: 4.15,
            ic_august: 5.014,
            ic_september: 5.058,
            ic_october: 5.564,
            ic_november: 5.917,
            ic_december: 6.109,
            ic_yearly: 5.156,
          },
          {
            ic_uuid: "e2aa2f6f-1742-4e9d-bd5b-e8f91cbbab61",
            ic_lat: -213276,
            ic_lon: -463639,
            ic_city: "Monte Belo",
            ic_states: "MINAS GERAIS",
            ic_january: 5.419,
            ic_february: 5.685,
            ic_march: 4.792,
            ic_april: 4.574,
            ic_may: 3.952,
            ic_june: 3.719,
            ic_july: 4.016,
            ic_august: 4.997,
            ic_september: 5.089,
            ic_october: 5.386,
            ic_november: 5.214,
            ic_december: 5.488,
            ic_yearly: 4.861,
          },
          {
            ic_uuid: "85f73848-3e43-4d10-90e8-305e5c4ba87b",
            ic_lat: -291612,
            ic_lon: -516337,
            ic_city: "Monte Belo do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.208,
            ic_february: 5.744,
            ic_march: 4.827,
            ic_april: 3.837,
            ic_may: 2.829,
            ic_june: 2.361,
            ic_july: 2.657,
            ic_august: 3.381,
            ic_september: 3.644,
            ic_october: 4.765,
            ic_november: 6.141,
            ic_december: 6.529,
            ic_yearly: 4.41,
          },
          {
            ic_uuid: "6dcae1b1-f2e3-4c89-bdd6-84a845e8ebab",
            ic_lat: -272244,
            ic_lon: -509812,
            ic_city: "Monte Carlo",
            ic_states: "SANTA CATARINA",
            ic_january: 5.734,
            ic_february: 5.378,
            ic_march: 4.77,
            ic_april: 3.88,
            ic_may: 2.984,
            ic_june: 2.624,
            ic_july: 2.886,
            ic_august: 3.736,
            ic_september: 3.875,
            ic_october: 4.699,
            ic_november: 5.837,
            ic_december: 6.085,
            ic_yearly: 4.374,
          },
          {
            ic_uuid: "32df9c1c-7b13-4403-9e30-4c1d4b1411a5",
            ic_lat: -187307,
            ic_lon: -474916,
            ic_city: "Monte Carmelo",
            ic_states: "MINAS GERAIS",
            ic_january: 5.614,
            ic_february: 5.915,
            ic_march: 5.128,
            ic_april: 5.017,
            ic_may: 4.624,
            ic_june: 4.4,
            ic_july: 4.677,
            ic_august: 5.626,
            ic_september: 5.608,
            ic_october: 5.707,
            ic_november: 5.514,
            ic_december: 5.779,
            ic_yearly: 5.301,
          },
          {
            ic_uuid: "a88f644e-7eff-42f5-8514-a773a11a6d61",
            ic_lat: -264615,
            ic_lon: -502332,
            ic_city: "Monte Castelo",
            ic_states: "SANTA CATARINA",
            ic_january: 5.42,
            ic_february: 5.061,
            ic_march: 4.544,
            ic_april: 3.568,
            ic_may: 2.858,
            ic_june: 2.444,
            ic_july: 2.685,
            ic_august: 3.592,
            ic_september: 3.707,
            ic_october: 4.266,
            ic_november: 5.348,
            ic_december: 5.532,
            ic_yearly: 4.085,
          },
          {
            ic_uuid: "c3f0a749-26f2-417c-a2a0-f11cbb39775b",
            ic_lat: -212986,
            ic_lon: -515683,
            ic_city: "Monte Castelo",
            ic_states: "SÃO PAULO",
            ic_january: 5.867,
            ic_february: 5.963,
            ic_march: 5.419,
            ic_april: 4.858,
            ic_may: 4.059,
            ic_june: 3.721,
            ic_july: 3.911,
            ic_august: 4.822,
            ic_september: 4.912,
            ic_october: 5.579,
            ic_november: 6.081,
            ic_december: 6.388,
            ic_yearly: 5.132,
          },
          {
            ic_uuid: "22fe5371-5210-4ebb-8d4d-b1b3a68e1816",
            ic_lat: -64374,
            ic_lon: -357834,
            ic_city: "Monte das Gameleiras",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.813,
            ic_february: 5.936,
            ic_march: 5.984,
            ic_april: 5.484,
            ic_may: 4.911,
            ic_june: 4.504,
            ic_july: 4.629,
            ic_august: 5.377,
            ic_september: 5.785,
            ic_october: 5.943,
            ic_november: 6.159,
            ic_december: 5.902,
            ic_yearly: 5.536,
          },
          {
            ic_uuid: "034e4da4-cb6e-48f6-a8f8-70b0d121622c",
            ic_lat: -107615,
            ic_lon: -481118,
            ic_city: "Monte do Carmo",
            ic_states: "TOCANTINS",
            ic_january: 5.221,
            ic_february: 5.128,
            ic_march: 4.797,
            ic_april: 5.024,
            ic_may: 5.079,
            ic_june: 5.012,
            ic_july: 5.353,
            ic_august: 6.061,
            ic_september: 5.941,
            ic_october: 5.413,
            ic_november: 5.141,
            ic_december: 5.219,
            ic_yearly: 5.282,
          },
          {
            ic_uuid: "0c28bc08-e362-4f0f-b2f3-d92319581f02",
            ic_lat: -168681,
            ic_lon: -412547,
            ic_city: "Monte Formoso",
            ic_states: "MINAS GERAIS",
            ic_january: 5.946,
            ic_february: 6.278,
            ic_march: 5.259,
            ic_april: 4.534,
            ic_may: 3.836,
            ic_june: 3.525,
            ic_july: 3.735,
            ic_august: 4.422,
            ic_september: 5.102,
            ic_october: 5.336,
            ic_november: 5.089,
            ic_december: 5.942,
            ic_yearly: 4.917,
          },
          {
            ic_uuid: "18c8572f-d778-43d6-ba36-f75da8340f46",
            ic_lat: -72156,
            ic_lon: -38586,
            ic_city: "Monte Horebe",
            ic_states: "PARAÍBA",
            ic_january: 6.01,
            ic_february: 6.005,
            ic_march: 5.968,
            ic_april: 5.631,
            ic_may: 5.159,
            ic_june: 4.963,
            ic_july: 5.222,
            ic_august: 5.99,
            ic_september: 6.602,
            ic_october: 6.639,
            ic_november: 6.766,
            ic_december: 6.415,
            ic_yearly: 5.948,
          },
          {
            ic_uuid: "9b43bcc5-6570-479c-9ba0-4cf1b42c180f",
            ic_lat: -229455,
            ic_lon: -473126,
            ic_city: "Monte Mor",
            ic_states: "SÃO PAULO",
            ic_january: 5.711,
            ic_february: 5.819,
            ic_march: 5.142,
            ic_april: 4.667,
            ic_may: 3.804,
            ic_june: 3.524,
            ic_july: 3.679,
            ic_august: 4.634,
            ic_september: 4.817,
            ic_october: 5.462,
            ic_november: 5.749,
            ic_december: 6.152,
            ic_yearly: 4.93,
          },
          {
            ic_uuid: "8cd252ef-e04d-4551-a1e9-7e66b2c86533",
            ic_lat: -102594,
            ic_lon: -632983,
            ic_city: "Monte Negro",
            ic_states: "RONDÔNIA",
            ic_january: 4.21,
            ic_february: 4.407,
            ic_march: 4.386,
            ic_april: 4.453,
            ic_may: 4.005,
            ic_june: 4.38,
            ic_july: 4.432,
            ic_august: 4.905,
            ic_september: 4.918,
            ic_october: 5.013,
            ic_november: 4.759,
            ic_december: 4.51,
            ic_yearly: 4.531,
          },
          {
            ic_uuid: "e403f7f7-1310-4607-aadd-54ee8b8056a0",
            ic_lat: -104378,
            ic_lon: -393324,
            ic_city: "Monte Santo",
            ic_states: "BAHIA",
            ic_january: 6.121,
            ic_february: 5.96,
            ic_march: 5.85,
            ic_april: 4.987,
            ic_may: 4.238,
            ic_june: 3.858,
            ic_july: 4.069,
            ic_august: 4.624,
            ic_september: 5.693,
            ic_october: 5.753,
            ic_november: 6.213,
            ic_december: 6.158,
            ic_yearly: 5.294,
          },
          {
            ic_uuid: "f7a49afe-4e5c-47af-ad3a-3183179427d5",
            ic_lat: -211878,
            ic_lon: -469757,
            ic_city: "Monte Santo de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.4,
            ic_february: 5.658,
            ic_march: 4.856,
            ic_april: 4.703,
            ic_may: 4.085,
            ic_june: 3.872,
            ic_july: 4.115,
            ic_august: 5.03,
            ic_september: 5.082,
            ic_october: 5.4,
            ic_november: 5.455,
            ic_december: 5.632,
            ic_yearly: 4.941,
          },
          {
            ic_uuid: "ca020a5b-8a09-4f55-afbf-e655c2b5d90e",
            ic_lat: -100063,
            ic_lon: -489923,
            ic_city: "Monte Santo do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 4.943,
            ic_february: 4.952,
            ic_march: 4.647,
            ic_april: 4.801,
            ic_may: 4.958,
            ic_june: 5.16,
            ic_july: 5.402,
            ic_august: 5.995,
            ic_september: 5.636,
            ic_october: 5.166,
            ic_november: 5.062,
            ic_december: 4.95,
            ic_yearly: 5.139,
          },
          {
            ic_uuid: "efe3a3f0-63c1-4be1-81ec-480d6cff2766",
            ic_lat: -22434,
            ic_lon: -465734,
            ic_city: "Monte Sião",
            ic_states: "MINAS GERAIS",
            ic_january: 5.459,
            ic_february: 5.665,
            ic_march: 5.043,
            ic_april: 4.685,
            ic_may: 3.91,
            ic_june: 3.64,
            ic_july: 3.866,
            ic_august: 4.725,
            ic_september: 4.931,
            ic_october: 5.43,
            ic_november: 5.604,
            ic_december: 5.795,
            ic_yearly: 4.896,
          },
          {
            ic_uuid: "f02b8567-751f-4869-bcf9-a03caee65149",
            ic_lat: -78947,
            ic_lon: -371221,
            ic_city: "Monteiro",
            ic_states: "PARAÍBA",
            ic_january: 6.3,
            ic_february: 6.131,
            ic_march: 6.152,
            ic_april: 5.702,
            ic_may: 4.902,
            ic_june: 4.399,
            ic_july: 4.556,
            ic_august: 5.314,
            ic_september: 6.284,
            ic_october: 6.508,
            ic_november: 6.775,
            ic_december: 6.426,
            ic_yearly: 5.788,
          },
          {
            ic_uuid: "12db39af-bfcb-455d-90be-23869c6ce414",
            ic_lat: -229549,
            ic_lon: -458411,
            ic_city: "Monteiro Lobato",
            ic_states: "SÃO PAULO",
            ic_january: 4.995,
            ic_february: 5.319,
            ic_march: 4.573,
            ic_april: 4.221,
            ic_may: 3.542,
            ic_june: 3.35,
            ic_july: 3.432,
            ic_august: 4.408,
            ic_september: 4.385,
            ic_october: 4.775,
            ic_november: 4.891,
            ic_december: 5.359,
            ic_yearly: 4.438,
          },
          {
            ic_uuid: "18cb5362-6b5c-4b05-9619-e130295a08f7",
            ic_lat: -9604,
            ic_lon: -372508,
            ic_city: "Monteirópolis",
            ic_states: "ALAGOAS",
            ic_january: 6.104,
            ic_february: 5.882,
            ic_march: 5.92,
            ic_april: 5.223,
            ic_may: 4.355,
            ic_june: 3.979,
            ic_july: 4.124,
            ic_august: 4.68,
            ic_september: 5.469,
            ic_october: 5.85,
            ic_november: 6.462,
            ic_december: 6.268,
            ic_yearly: 5.36,
          },
          {
            ic_uuid: "fce1e5af-8009-4331-9785-adf496315b83",
            ic_lat: -296872,
            ic_lon: -514603,
            ic_city: "Montenegro",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.225,
            ic_february: 5.773,
            ic_march: 4.857,
            ic_april: 3.788,
            ic_may: 2.804,
            ic_june: 2.315,
            ic_july: 2.526,
            ic_august: 3.205,
            ic_september: 3.54,
            ic_october: 4.624,
            ic_november: 6.14,
            ic_december: 6.592,
            ic_yearly: 4.366,
          },
          {
            ic_uuid: "2e5639bb-f425-496b-93d1-2a884ac0b768",
            ic_lat: -58311,
            ic_lon: -470677,
            ic_city: "Montes Altos",
            ic_states: "MARANHÃO",
            ic_january: 4.716,
            ic_february: 4.8,
            ic_march: 4.81,
            ic_april: 4.88,
            ic_may: 4.943,
            ic_june: 5.223,
            ic_july: 5.378,
            ic_august: 5.854,
            ic_september: 5.76,
            ic_october: 5.263,
            ic_november: 4.956,
            ic_december: 4.837,
            ic_yearly: 5.118,
          },
          {
            ic_uuid: "c7104ca9-54af-4b61-9098-f2369cf12881",
            ic_lat: -167286,
            ic_lon: -438582,
            ic_city: "Montes Claros",
            ic_states: "MINAS GERAIS",
            ic_january: 6.198,
            ic_february: 6.503,
            ic_march: 5.498,
            ic_april: 5.293,
            ic_may: 4.74,
            ic_june: 4.503,
            ic_july: 4.825,
            ic_august: 5.634,
            ic_september: 6.03,
            ic_october: 5.969,
            ic_november: 5.436,
            ic_december: 6.101,
            ic_yearly: 5.561,
          },
          {
            ic_uuid: "c6966faf-b9d9-4def-b476-1cad2b72b7fd",
            ic_lat: -160064,
            ic_lon: -513983,
            ic_city: "Montes Claros de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.514,
            ic_february: 5.68,
            ic_march: 5.301,
            ic_april: 5.278,
            ic_may: 4.884,
            ic_june: 4.574,
            ic_july: 4.658,
            ic_august: 5.385,
            ic_september: 5.422,
            ic_october: 5.517,
            ic_november: 5.383,
            ic_december: 5.561,
            ic_yearly: 5.263,
          },
          {
            ic_uuid: "be027ef9-7571-42e3-94e5-a0c8e3a136a1",
            ic_lat: -151753,
            ic_lon: -425009,
            ic_city: "Montezuma",
            ic_states: "MINAS GERAIS",
            ic_january: 6.08,
            ic_february: 6.322,
            ic_march: 5.526,
            ic_april: 4.873,
            ic_may: 4.336,
            ic_june: 4.03,
            ic_july: 4.4,
            ic_august: 5.136,
            ic_september: 5.827,
            ic_october: 5.841,
            ic_november: 5.406,
            ic_december: 6.122,
            ic_yearly: 5.325,
          },
          {
            ic_uuid: "6b9762c0-c466-4fd8-b76f-e95b3d086dab",
            ic_lat: -174444,
            ic_lon: -511732,
            ic_city: "Montividiu",
            ic_states: "GOIÁS",
            ic_january: 5.576,
            ic_february: 5.503,
            ic_march: 5.075,
            ic_april: 5.049,
            ic_may: 4.662,
            ic_june: 4.417,
            ic_july: 4.536,
            ic_august: 5.444,
            ic_september: 5.192,
            ic_october: 5.352,
            ic_november: 5.387,
            ic_december: 5.657,
            ic_yearly: 5.154,
          },
          {
            ic_uuid: "8fe18116-27a6-440c-be14-b37437d48ddc",
            ic_lat: -133494,
            ic_lon: -486872,
            ic_city: "Montividiu do Norte",
            ic_states: "GOIÁS",
            ic_january: 5.352,
            ic_february: 5.448,
            ic_march: 5.132,
            ic_april: 5.176,
            ic_may: 4.994,
            ic_june: 4.913,
            ic_july: 5.279,
            ic_august: 5.954,
            ic_september: 5.798,
            ic_october: 5.53,
            ic_november: 5.072,
            ic_december: 5.27,
            ic_yearly: 5.326,
          },
          {
            ic_uuid: "a9108b0c-60ab-4e42-8da7-a9bf04be644e",
            ic_lat: -50978,
            ic_lon: -383705,
            ic_city: "Morada Nova",
            ic_states: "CEARÁ",
            ic_january: 5.763,
            ic_february: 5.809,
            ic_march: 5.937,
            ic_april: 5.446,
            ic_may: 5.294,
            ic_june: 5.045,
            ic_july: 5.438,
            ic_august: 6.07,
            ic_september: 6.424,
            ic_october: 6.588,
            ic_november: 6.437,
            ic_december: 5.93,
            ic_yearly: 5.848,
          },
          {
            ic_uuid: "e51a2420-2541-45e0-8851-228f053b2691",
            ic_lat: -186002,
            ic_lon: -453588,
            ic_city: "Morada Nova de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 6.138,
            ic_february: 6.343,
            ic_march: 5.479,
            ic_april: 5.229,
            ic_may: 4.671,
            ic_june: 4.36,
            ic_july: 4.753,
            ic_august: 5.439,
            ic_september: 5.816,
            ic_october: 5.934,
            ic_november: 5.61,
            ic_december: 5.924,
            ic_yearly: 5.475,
          },
          {
            ic_uuid: "25cc28b5-d1b7-4226-9df1-45e541d46ac8",
            ic_lat: -34669,
            ic_lon: -406799,
            ic_city: "Moraújo",
            ic_states: "CEARÁ",
            ic_january: 5.176,
            ic_february: 5.27,
            ic_march: 5.119,
            ic_april: 4.818,
            ic_may: 5.067,
            ic_june: 5.13,
            ic_july: 5.352,
            ic_august: 6.036,
            ic_september: 6.468,
            ic_october: 6.412,
            ic_november: 6.177,
            ic_december: 5.593,
            ic_yearly: 5.552,
          },
          {
            ic_uuid: "5845055a-3587-4ed6-b95b-c6497af78e9b",
            ic_lat: -7621,
            ic_lon: -395469,
            ic_city: "Moreilândia",
            ic_states: "PERNAMBUCO",
            ic_january: 5.826,
            ic_february: 5.735,
            ic_march: 5.774,
            ic_april: 5.375,
            ic_may: 4.789,
            ic_june: 4.473,
            ic_july: 4.759,
            ic_august: 5.639,
            ic_september: 6.445,
            ic_october: 6.531,
            ic_november: 6.535,
            ic_december: 6.229,
            ic_yearly: 5.676,
          },
          {
            ic_uuid: "7ce83d62-15b0-44dd-87e2-ecbaf852a682",
            ic_lat: -240531,
            ic_lon: -530061,
            ic_city: "Moreira Sales",
            ic_states: "PARANÁ",
            ic_january: 6.185,
            ic_february: 5.827,
            ic_march: 5.327,
            ic_april: 4.475,
            ic_may: 3.512,
            ic_june: 3.111,
            ic_july: 3.327,
            ic_august: 4.296,
            ic_september: 4.548,
            ic_october: 5.382,
            ic_november: 6.147,
            ic_december: 6.484,
            ic_yearly: 4.885,
          },
          {
            ic_uuid: "95ce35d3-bec9-4e29-892d-25ba04270102",
            ic_lat: -81091,
            ic_lon: -350838,
            ic_city: "Moreno",
            ic_states: "PERNAMBUCO",
            ic_january: 5.812,
            ic_february: 5.889,
            ic_march: 5.891,
            ic_april: 5.131,
            ic_may: 4.389,
            ic_june: 4.124,
            ic_july: 4.23,
            ic_august: 4.9,
            ic_september: 5.471,
            ic_october: 5.723,
            ic_november: 5.998,
            ic_december: 6.002,
            ic_yearly: 5.297,
          },
          {
            ic_uuid: "8e15ae23-2464-4860-a0c5-1249654f1aa1",
            ic_lat: -28692,
            ic_lon: -526919,
            ic_city: "Mormaço",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.282,
            ic_february: 5.862,
            ic_march: 5.025,
            ic_april: 3.97,
            ic_may: 3.014,
            ic_june: 2.462,
            ic_july: 2.766,
            ic_august: 3.536,
            ic_september: 3.847,
            ic_october: 5.033,
            ic_november: 6.27,
            ic_december: 6.653,
            ic_yearly: 4.56,
          },
          {
            ic_uuid: "ae2af168-66cf-4cae-8023-80a0d8d90511",
            ic_lat: -115573,
            ic_lon: -432769,
            ic_city: "Morpará",
            ic_states: "BAHIA",
            ic_january: 6.409,
            ic_february: 6.3,
            ic_march: 6.094,
            ic_april: 5.717,
            ic_may: 5.317,
            ic_june: 5.061,
            ic_july: 5.325,
            ic_august: 5.973,
            ic_september: 6.427,
            ic_october: 6.44,
            ic_november: 6.162,
            ic_december: 6.402,
            ic_yearly: 5.969,
          },
          {
            ic_uuid: "1886082a-71c2-4932-9f34-8681a9fd6c8a",
            ic_lat: -254748,
            ic_lon: -48835,
            ic_city: "Morretes",
            ic_states: "PARANÁ",
            ic_january: 4.998,
            ic_february: 4.849,
            ic_march: 4.18,
            ic_april: 3.535,
            ic_may: 2.929,
            ic_june: 2.557,
            ic_july: 2.623,
            ic_august: 3.348,
            ic_september: 3.182,
            ic_october: 3.66,
            ic_november: 4.557,
            ic_december: 4.997,
            ic_yearly: 3.785,
          },
          {
            ic_uuid: "bce62a24-6604-400f-9716-1aca856caeb0",
            ic_lat: -32289,
            ic_lon: -401247,
            ic_city: "Morrinhos",
            ic_states: "CEARÁ",
            ic_january: 5.159,
            ic_february: 5.14,
            ic_march: 5.016,
            ic_april: 4.64,
            ic_may: 5.142,
            ic_june: 5.16,
            ic_july: 5.333,
            ic_august: 6.031,
            ic_september: 6.402,
            ic_october: 6.261,
            ic_november: 6.154,
            ic_december: 5.546,
            ic_yearly: 5.499,
          },
          {
            ic_uuid: "b12afc44-a2e1-4081-bf6c-2a3f8e069f18",
            ic_lat: -177338,
            ic_lon: -491063,
            ic_city: "Morrinhos",
            ic_states: "GOIÁS",
            ic_january: 5.623,
            ic_february: 5.783,
            ic_march: 5.301,
            ic_april: 5.159,
            ic_may: 4.734,
            ic_june: 4.414,
            ic_july: 4.644,
            ic_august: 5.541,
            ic_september: 5.423,
            ic_october: 5.586,
            ic_november: 5.552,
            ic_december: 5.71,
            ic_yearly: 5.289,
          },
          {
            ic_uuid: "cfd342f4-5e8f-4280-b7aa-45d3a695081c",
            ic_lat: -29365,
            ic_lon: -499349,
            ic_city: "Morrinhos do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 5.303,
            ic_february: 4.967,
            ic_march: 4.219,
            ic_april: 3.666,
            ic_may: 2.845,
            ic_june: 2.395,
            ic_july: 2.617,
            ic_august: 3.308,
            ic_september: 3.367,
            ic_october: 3.948,
            ic_november: 5.224,
            ic_december: 5.505,
            ic_yearly: 3.947,
          },
          {
            ic_uuid: "fda923e7-3df9-48c7-9bc0-eff64853dc37",
            ic_lat: -207293,
            ic_lon: -480585,
            ic_city: "Morro Agudo",
            ic_states: "SÃO PAULO",
            ic_january: 5.793,
            ic_february: 5.999,
            ic_march: 5.165,
            ic_april: 4.835,
            ic_may: 4.214,
            ic_june: 3.966,
            ic_july: 4.17,
            ic_august: 5.078,
            ic_september: 5.06,
            ic_october: 5.547,
            ic_november: 5.781,
            ic_december: 6.13,
            ic_yearly: 5.145,
          },
          {
            ic_uuid: "f60542ad-588a-46c3-8955-ce76d75c6d10",
            ic_lat: -153189,
            ic_lon: -500557,
            ic_city: "Morro Agudo de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.247,
            ic_february: 5.401,
            ic_march: 5.081,
            ic_april: 5.044,
            ic_may: 4.951,
            ic_june: 4.672,
            ic_july: 4.868,
            ic_august: 5.711,
            ic_september: 5.447,
            ic_october: 5.501,
            ic_november: 5.187,
            ic_december: 5.269,
            ic_yearly: 5.198,
          },
          {
            ic_uuid: "67e46e8e-4b3e-47ff-947f-b24483ccbe2a",
            ic_lat: -97277,
            ic_lon: -439082,
            ic_city: "Morro Cabeça no Tempo",
            ic_states: "PIAUÍ",
            ic_january: 5.927,
            ic_february: 5.656,
            ic_march: 5.593,
            ic_april: 5.425,
            ic_may: 5.47,
            ic_june: 5.433,
            ic_july: 5.77,
            ic_august: 6.437,
            ic_september: 6.698,
            ic_october: 6.301,
            ic_november: 5.894,
            ic_december: 5.867,
            ic_yearly: 5.873,
          },
          {
            ic_uuid: "d3e353dc-6bcb-4c54-943f-3e6ef9359b2f",
            ic_lat: -286516,
            ic_lon: -492174,
            ic_city: "Morro da Fumaça",
            ic_states: "SANTA CATARINA",
            ic_january: 5.857,
            ic_february: 5.376,
            ic_march: 4.56,
            ic_april: 3.822,
            ic_may: 2.996,
            ic_june: 2.499,
            ic_july: 2.708,
            ic_august: 3.432,
            ic_september: 3.569,
            ic_october: 4.412,
            ic_november: 5.768,
            ic_december: 6.086,
            ic_yearly: 4.257,
          },
          {
            ic_uuid: "75f53a7c-172e-4e1b-8eed-bf490d6ea411",
            ic_lat: -18547,
            ic_lon: -44603,
            ic_city: "Morro da Garça",
            ic_states: "MINAS GERAIS",
            ic_january: 6.099,
            ic_february: 6.341,
            ic_march: 5.425,
            ic_april: 5.254,
            ic_may: 4.659,
            ic_june: 4.343,
            ic_july: 4.751,
            ic_august: 5.398,
            ic_september: 5.825,
            ic_october: 5.843,
            ic_november: 5.494,
            ic_december: 5.907,
            ic_yearly: 5.445,
          },
          {
            ic_uuid: "77640bb8-939f-45e8-b2e1-db5ab63e73a7",
            ic_lat: -115492,
            ic_lon: -411568,
            ic_city: "Morro do Chapéu",
            ic_states: "BAHIA",
            ic_january: 6.064,
            ic_february: 5.912,
            ic_march: 5.725,
            ic_april: 4.729,
            ic_may: 4.15,
            ic_june: 3.903,
            ic_july: 4.085,
            ic_august: 4.798,
            ic_september: 5.78,
            ic_october: 5.97,
            ic_november: 5.822,
            ic_december: 6.025,
            ic_yearly: 5.247,
          },
          {
            ic_uuid: "ddc1bf6d-81c6-47c8-aa67-eb8e772790b2",
            ic_lat: -37434,
            ic_lon: -423103,
            ic_city: "Morro do Chapéu do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.27,
            ic_february: 5.42,
            ic_march: 5.365,
            ic_april: 5.147,
            ic_may: 5.141,
            ic_june: 5.359,
            ic_july: 5.517,
            ic_august: 6.098,
            ic_september: 6.452,
            ic_october: 6.418,
            ic_november: 6.127,
            ic_december: 5.568,
            ic_yearly: 5.657,
          },
          {
            ic_uuid: "0b8eda1b-dcbf-4ba3-a3ea-dd815daf9144",
            ic_lat: -192188,
            ic_lon: -433812,
            ic_city: "Morro do Pilar",
            ic_states: "MINAS GERAIS",
            ic_january: 5.608,
            ic_february: 5.899,
            ic_march: 4.938,
            ic_april: 4.51,
            ic_may: 3.874,
            ic_june: 3.718,
            ic_july: 3.914,
            ic_august: 4.696,
            ic_september: 4.995,
            ic_october: 5.089,
            ic_november: 4.741,
            ic_december: 5.458,
            ic_yearly: 4.787,
          },
          {
            ic_uuid: "4301782d-23d9-4089-be14-0cbbccc82ab5",
            ic_lat: -288011,
            ic_lon: -497218,
            ic_city: "Morro Grande",
            ic_states: "SANTA CATARINA",
            ic_january: 5.2,
            ic_february: 4.915,
            ic_march: 4.252,
            ic_april: 3.666,
            ic_may: 2.873,
            ic_june: 2.432,
            ic_july: 2.672,
            ic_august: 3.362,
            ic_september: 3.367,
            ic_october: 3.974,
            ic_november: 5.238,
            ic_december: 5.446,
            ic_yearly: 3.95,
          },
          {
            ic_uuid: "4d193688-ced7-4be5-9fe5-d9e23a8ad7a5",
            ic_lat: -315892,
            ic_lon: -526265,
            ic_city: "Morro Redondo",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.209,
            ic_february: 5.544,
            ic_march: 4.733,
            ic_april: 3.618,
            ic_may: 2.643,
            ic_june: 2.221,
            ic_july: 2.43,
            ic_august: 3.037,
            ic_september: 3.524,
            ic_october: 4.775,
            ic_november: 6.085,
            ic_december: 6.622,
            ic_yearly: 4.287,
          },
          {
            ic_uuid: "c4e9379b-1915-4225-b54c-f692c5ccd9dd",
            ic_lat: -295384,
            ic_lon: -510816,
            ic_city: "Morro Reuter",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.216,
            ic_february: 5.693,
            ic_march: 4.719,
            ic_april: 3.767,
            ic_may: 2.804,
            ic_june: 2.36,
            ic_july: 2.587,
            ic_august: 3.268,
            ic_september: 3.559,
            ic_october: 4.635,
            ic_november: 6.062,
            ic_december: 6.495,
            ic_yearly: 4.347,
          },
          {
            ic_uuid: "9f1dc361-6180-4387-ab38-e47344df381f",
            ic_lat: -28659,
            ic_lon: -440364,
            ic_city: "Morros",
            ic_states: "MARANHÃO",
            ic_january: 4.479,
            ic_february: 4.515,
            ic_march: 4.495,
            ic_april: 4.441,
            ic_may: 4.587,
            ic_june: 4.903,
            ic_july: 5.049,
            ic_august: 5.579,
            ic_september: 5.935,
            ic_october: 5.685,
            ic_november: 5.308,
            ic_december: 4.857,
            ic_yearly: 4.986,
          },
          {
            ic_uuid: "08087573-cfe1-4731-93db-e4d8703ec36c",
            ic_lat: -150226,
            ic_lon: -423697,
            ic_city: "Mortugaba",
            ic_states: "BAHIA",
            ic_january: 6.134,
            ic_february: 6.341,
            ic_march: 5.595,
            ic_april: 4.895,
            ic_may: 4.365,
            ic_june: 4.023,
            ic_july: 4.353,
            ic_august: 5.082,
            ic_september: 5.743,
            ic_october: 5.905,
            ic_november: 5.445,
            ic_december: 6.145,
            ic_yearly: 5.336,
          },
          {
            ic_uuid: "c441db26-cd98-482e-bdb6-3a85dcf5a4fd",
            ic_lat: -228815,
            ic_lon: -4679,
            ic_city: "Morungaba",
            ic_states: "SÃO PAULO",
            ic_january: 5.451,
            ic_february: 5.783,
            ic_march: 5.036,
            ic_april: 4.604,
            ic_may: 3.795,
            ic_june: 3.5,
            ic_july: 3.67,
            ic_august: 4.654,
            ic_september: 4.799,
            ic_october: 5.393,
            ic_november: 5.627,
            ic_december: 6.058,
            ic_yearly: 4.864,
          },
          {
            ic_uuid: "6ba17cfc-cc0e-4d28-8a14-830573a43ad1",
            ic_lat: -161244,
            ic_lon: -502071,
            ic_city: "Mossâmedes",
            ic_states: "GOIÁS",
            ic_january: 5.435,
            ic_february: 5.541,
            ic_march: 5.228,
            ic_april: 5.212,
            ic_may: 4.908,
            ic_june: 4.598,
            ic_july: 4.773,
            ic_august: 5.681,
            ic_september: 5.454,
            ic_october: 5.622,
            ic_november: 5.389,
            ic_december: 5.431,
            ic_yearly: 5.273,
          },
          {
            ic_uuid: "572fdecf-a19b-4840-bd66-8a9bed367473",
            ic_lat: -51841,
            ic_lon: -373478,
            ic_city: "Mossoró",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.845,
            ic_february: 5.86,
            ic_march: 5.928,
            ic_april: 5.557,
            ic_may: 5.262,
            ic_june: 4.926,
            ic_july: 5.211,
            ic_august: 5.862,
            ic_september: 6.244,
            ic_october: 6.391,
            ic_november: 6.45,
            ic_december: 6.084,
            ic_yearly: 5.801,
          },
          {
            ic_uuid: "c65403a4-ec44-43be-a9f8-e6d782ca1325",
            ic_lat: -311059,
            ic_lon: -509171,
            ic_city: "Mostardas",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.485,
            ic_february: 5.849,
            ic_march: 4.856,
            ic_april: 3.78,
            ic_may: 2.732,
            ic_june: 2.257,
            ic_july: 2.449,
            ic_august: 3.102,
            ic_september: 3.599,
            ic_october: 4.834,
            ic_november: 6.242,
            ic_december: 6.794,
            ic_yearly: 4.415,
          },
          {
            ic_uuid: "cc0aace9-718f-45f0-9c32-18ccb1de6e11",
            ic_lat: -21508,
            ic_lon: -48151,
            ic_city: "Motuca",
            ic_states: "SÃO PAULO",
            ic_january: 5.691,
            ic_february: 5.911,
            ic_march: 5.149,
            ic_april: 4.803,
            ic_may: 4.069,
            ic_june: 3.829,
            ic_july: 4.01,
            ic_august: 4.833,
            ic_september: 4.964,
            ic_october: 5.497,
            ic_november: 5.764,
            ic_december: 6.07,
            ic_yearly: 5.049,
          },
          {
            ic_uuid: "4cf4f794-e00b-4a71-b49d-b04e19d6065a",
            ic_lat: -147461,
            ic_lon: -505717,
            ic_city: "Mozarlândia",
            ic_states: "GOIÁS",
            ic_january: 5.41,
            ic_february: 5.654,
            ic_march: 5.316,
            ic_april: 5.256,
            ic_may: 4.914,
            ic_june: 4.641,
            ic_july: 4.879,
            ic_august: 5.62,
            ic_september: 5.482,
            ic_october: 5.512,
            ic_november: 5.335,
            ic_december: 5.384,
            ic_yearly: 5.284,
          },
          {
            ic_uuid: "982f5637-2fce-4f47-abba-c009701501c9",
            ic_lat: -15397,
            ic_lon: -492228,
            ic_city: "Muaná",
            ic_states: "PARÁ",
            ic_january: 4.478,
            ic_february: 4.381,
            ic_march: 4.478,
            ic_april: 4.504,
            ic_may: 4.74,
            ic_june: 4.865,
            ic_july: 5.032,
            ic_august: 5.301,
            ic_september: 5.376,
            ic_october: 5.376,
            ic_november: 5.247,
            ic_december: 4.803,
            ic_yearly: 4.882,
          },
          {
            ic_uuid: "f240e511-f402-4132-9b4a-75b18c148e5f",
            ic_lat: 24448,
            ic_lon: -609209,
            ic_city: "Mucajaí",
            ic_states: "RORAIMA",
            ic_january: 4.61,
            ic_february: 4.678,
            ic_march: 4.939,
            ic_april: 4.661,
            ic_may: 4.277,
            ic_june: 4.539,
            ic_july: 4.585,
            ic_august: 5.063,
            ic_september: 5.549,
            ic_october: 5.423,
            ic_november: 5.207,
            ic_december: 4.76,
            ic_yearly: 4.858,
          },
          {
            ic_uuid: "bfb1e2e5-d1fe-4822-bf17-b5809f91823e",
            ic_lat: -3906,
            ic_lon: -40746,
            ic_city: "Mucambo",
            ic_states: "CEARÁ",
            ic_january: 4.946,
            ic_february: 5.181,
            ic_march: 5.175,
            ic_april: 4.843,
            ic_may: 4.944,
            ic_june: 4.941,
            ic_july: 5.245,
            ic_august: 5.932,
            ic_september: 6.408,
            ic_october: 6.311,
            ic_november: 5.973,
            ic_december: 5.31,
            ic_yearly: 5.434,
          },
          {
            ic_uuid: "d581acab-335f-49aa-b1a5-233af1008d1f",
            ic_lat: -130057,
            ic_lon: -413706,
            ic_city: "Mucugê",
            ic_states: "BAHIA",
            ic_january: 5.818,
            ic_february: 5.967,
            ic_march: 5.534,
            ic_april: 4.679,
            ic_may: 4.14,
            ic_june: 3.835,
            ic_july: 3.979,
            ic_august: 4.671,
            ic_september: 5.528,
            ic_october: 5.473,
            ic_november: 5.283,
            ic_december: 5.872,
            ic_yearly: 5.065,
          },
          {
            ic_uuid: "e7267f7f-e6bd-45ed-8256-add157a2ed45",
            ic_lat: -291668,
            ic_lon: -518747,
            ic_city: "Muçum",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.211,
            ic_february: 5.753,
            ic_march: 4.828,
            ic_april: 3.805,
            ic_may: 2.824,
            ic_june: 2.353,
            ic_july: 2.606,
            ic_august: 3.342,
            ic_september: 3.636,
            ic_october: 4.722,
            ic_november: 6.089,
            ic_december: 6.511,
            ic_yearly: 4.39,
          },
          {
            ic_uuid: "e431545b-66f8-47e0-9f11-3cbadc759e01",
            ic_lat: -180759,
            ic_lon: -395568,
            ic_city: "Mucuri",
            ic_states: "BAHIA",
            ic_january: 6.378,
            ic_february: 6.471,
            ic_march: 5.6,
            ic_april: 4.743,
            ic_may: 4.003,
            ic_june: 3.717,
            ic_july: 3.842,
            ic_august: 4.634,
            ic_september: 5.175,
            ic_october: 5.423,
            ic_november: 5.404,
            ic_december: 6.222,
            ic_yearly: 5.135,
          },
          {
            ic_uuid: "79ec30e1-8505-41c8-b135-0680c9e8c818",
            ic_lat: -180942,
            ic_lon: -405191,
            ic_city: "Mucurici",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.124,
            ic_february: 6.191,
            ic_march: 5.338,
            ic_april: 4.61,
            ic_may: 3.803,
            ic_june: 3.474,
            ic_july: 3.642,
            ic_august: 4.243,
            ic_september: 4.814,
            ic_october: 5.129,
            ic_november: 5.033,
            ic_december: 5.977,
            ic_yearly: 4.865,
          },
          {
            ic_uuid: "267f787d-eded-4c75-88f6-939fb00efaff",
            ic_lat: -283137,
            ic_lon: -51184,
            ic_city: "Muitos Capões",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.063,
            ic_february: 5.698,
            ic_march: 4.81,
            ic_april: 3.926,
            ic_may: 2.951,
            ic_june: 2.53,
            ic_july: 2.852,
            ic_august: 3.623,
            ic_september: 3.872,
            ic_october: 4.915,
            ic_november: 6.106,
            ic_december: 6.408,
            ic_yearly: 4.479,
          },
          {
            ic_uuid: "5019a077-6611-42da-9432-6d4ade133eae",
            ic_lat: -283258,
            ic_lon: -517701,
            ic_city: "Muliterno",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.13,
            ic_february: 5.697,
            ic_march: 4.908,
            ic_april: 4.003,
            ic_may: 2.993,
            ic_june: 2.538,
            ic_july: 2.845,
            ic_august: 3.597,
            ic_september: 3.807,
            ic_october: 4.927,
            ic_november: 6.163,
            ic_december: 6.491,
            ic_yearly: 4.508,
          },
          {
            ic_uuid: "ca2b6999-9ad8-4a19-86a0-d84b50618a32",
            ic_lat: -43033,
            ic_lon: -389954,
            ic_city: "Mulungu",
            ic_states: "CEARÁ",
            ic_january: 5.232,
            ic_february: 5.154,
            ic_march: 5.112,
            ic_april: 4.594,
            ic_may: 4.845,
            ic_june: 4.717,
            ic_july: 5.254,
            ic_august: 6.09,
            ic_september: 6.408,
            ic_october: 6.261,
            ic_november: 6.117,
            ic_december: 5.479,
            ic_yearly: 5.438,
          },
          {
            ic_uuid: "22ad9780-0ebb-4756-afb5-3d19557b5fd5",
            ic_lat: -70257,
            ic_lon: -354603,
            ic_city: "Mulungu",
            ic_states: "PARAÍBA",
            ic_january: 5.587,
            ic_february: 5.724,
            ic_march: 5.742,
            ic_april: 5.237,
            ic_may: 4.621,
            ic_june: 4.211,
            ic_july: 4.318,
            ic_august: 5.077,
            ic_september: 5.527,
            ic_october: 5.733,
            ic_november: 5.976,
            ic_december: 5.786,
            ic_yearly: 5.295,
          },
          {
            ic_uuid: "41e1f05f-5292-412d-9dba-43557a275c04",
            ic_lat: -119652,
            ic_lon: -416377,
            ic_city: "Mulungu do Morro",
            ic_states: "BAHIA",
            ic_january: 6.15,
            ic_february: 5.988,
            ic_march: 5.784,
            ic_april: 4.914,
            ic_may: 4.358,
            ic_june: 4.087,
            ic_july: 4.282,
            ic_august: 5.029,
            ic_september: 5.934,
            ic_october: 6.13,
            ic_november: 5.907,
            ic_december: 6.231,
            ic_yearly: 5.4,
          },
          {
            ic_uuid: "b478077b-d368-43bf-a160-03503b7f845f",
            ic_lat: -11866,
            ic_lon: -404707,
            ic_city: "Mundo Novo",
            ic_states: "BAHIA",
            ic_january: 5.889,
            ic_february: 5.786,
            ic_march: 5.663,
            ic_april: 4.592,
            ic_may: 3.911,
            ic_june: 3.607,
            ic_july: 3.724,
            ic_august: 4.397,
            ic_september: 5.22,
            ic_october: 5.274,
            ic_november: 5.489,
            ic_december: 5.756,
            ic_yearly: 4.943,
          },
          {
            ic_uuid: "ad54dc79-1173-4e14-81ee-83cea97eeee8",
            ic_lat: -137734,
            ic_lon: -502818,
            ic_city: "Mundo Novo",
            ic_states: "GOIÁS",
            ic_january: 5.376,
            ic_february: 5.646,
            ic_march: 5.239,
            ic_april: 5.165,
            ic_may: 4.934,
            ic_june: 4.726,
            ic_july: 5.124,
            ic_august: 5.662,
            ic_september: 5.645,
            ic_october: 5.534,
            ic_november: 5.217,
            ic_december: 5.419,
            ic_yearly: 5.307,
          },
          {
            ic_uuid: "15a2f128-a674-4b6c-97a0-fd0bff1b745f",
            ic_lat: -23936,
            ic_lon: -542815,
            ic_city: "Mundo Novo",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 6.153,
            ic_february: 5.788,
            ic_march: 5.328,
            ic_april: 4.4,
            ic_may: 3.451,
            ic_june: 3.031,
            ic_july: 3.252,
            ic_august: 4.234,
            ic_september: 4.525,
            ic_october: 5.332,
            ic_november: 6.057,
            ic_december: 6.489,
            ic_yearly: 4.836,
          },
          {
            ic_uuid: "2647197f-c13f-4d7a-8c7c-484300517720",
            ic_lat: -226096,
            ic_lon: -463624,
            ic_city: "Munhoz",
            ic_states: "MINAS GERAIS",
            ic_january: 5.281,
            ic_february: 5.522,
            ic_march: 4.852,
            ic_april: 4.525,
            ic_may: 3.845,
            ic_june: 3.603,
            ic_july: 3.804,
            ic_august: 4.732,
            ic_september: 4.934,
            ic_october: 5.398,
            ic_november: 5.522,
            ic_december: 5.77,
            ic_yearly: 4.816,
          },
          {
            ic_uuid: "d55c415f-3199-4227-b4e7-19ae33a34790",
            ic_lat: -231465,
            ic_lon: -517736,
            ic_city: "Munhoz de Melo",
            ic_states: "PARANÁ",
            ic_january: 5.97,
            ic_february: 5.908,
            ic_march: 5.389,
            ic_april: 4.657,
            ic_may: 3.683,
            ic_june: 3.408,
            ic_july: 3.586,
            ic_august: 4.521,
            ic_september: 4.731,
            ic_october: 5.435,
            ic_november: 6.119,
            ic_december: 6.416,
            ic_yearly: 4.985,
          },
          {
            ic_uuid: "3750d728-a86d-4f1a-94e0-611387f172ec",
            ic_lat: -13002,
            ic_lon: -391086,
            ic_city: "Muniz Ferreira",
            ic_states: "BAHIA",
            ic_january: 6.026,
            ic_february: 5.924,
            ic_march: 5.696,
            ic_april: 4.616,
            ic_may: 4.019,
            ic_june: 3.589,
            ic_july: 3.802,
            ic_august: 4.4,
            ic_september: 5.181,
            ic_october: 5.363,
            ic_november: 5.712,
            ic_december: 6.099,
            ic_yearly: 5.036,
          },
          {
            ic_uuid: "42103a99-7a09-4bb4-9e21-088a2525831a",
            ic_lat: -204657,
            ic_lon: -41416,
            ic_city: "Muniz Freire",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.034,
            ic_february: 6.207,
            ic_march: 5.123,
            ic_april: 4.547,
            ic_may: 3.886,
            ic_june: 3.731,
            ic_july: 3.783,
            ic_august: 4.547,
            ic_september: 4.927,
            ic_october: 5.034,
            ic_november: 4.885,
            ic_december: 5.681,
            ic_yearly: 4.866,
          },
          {
            ic_uuid: "3a4dfd97-7a25-4388-a7e7-f60042ff989f",
            ic_lat: -120654,
            ic_lon: -435492,
            ic_city: "Muquém de São Francisco",
            ic_states: "BAHIA",
            ic_january: 6.545,
            ic_february: 6.326,
            ic_march: 6.047,
            ic_april: 5.685,
            ic_may: 5.283,
            ic_june: 5.017,
            ic_july: 5.258,
            ic_august: 5.853,
            ic_september: 6.179,
            ic_october: 6.294,
            ic_november: 6.037,
            ic_december: 6.426,
            ic_yearly: 5.912,
          },
          {
            ic_uuid: "071e786a-383b-4896-b631-de34b484b2e6",
            ic_lat: -209513,
            ic_lon: -413463,
            ic_city: "Muqui",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.05,
            ic_february: 6.272,
            ic_march: 5.058,
            ic_april: 4.417,
            ic_may: 3.782,
            ic_june: 3.556,
            ic_july: 3.643,
            ic_august: 4.397,
            ic_september: 4.866,
            ic_october: 4.98,
            ic_november: 4.841,
            ic_december: 5.639,
            ic_yearly: 4.792,
          },
          {
            ic_uuid: "41a46bc8-275b-442d-b8fe-699bf7fc3a02",
            ic_lat: -211304,
            ic_lon: -423697,
            ic_city: "Muriaé",
            ic_states: "MINAS GERAIS",
            ic_january: 5.971,
            ic_february: 6.232,
            ic_march: 5.145,
            ic_april: 4.465,
            ic_may: 3.813,
            ic_june: 3.621,
            ic_july: 3.747,
            ic_august: 4.564,
            ic_september: 4.9,
            ic_october: 5.155,
            ic_november: 4.85,
            ic_december: 5.79,
            ic_yearly: 4.854,
          },
          {
            ic_uuid: "8a9d1835-cb07-49ab-a6d6-349956bea7c4",
            ic_lat: -104243,
            ic_lon: -369565,
            ic_city: "Muribeca",
            ic_states: "SERGIPE",
            ic_january: 6.004,
            ic_february: 5.761,
            ic_march: 5.716,
            ic_april: 4.972,
            ic_may: 4.222,
            ic_june: 3.965,
            ic_july: 4.067,
            ic_august: 4.56,
            ic_september: 5.319,
            ic_october: 5.61,
            ic_november: 6.126,
            ic_december: 6.133,
            ic_yearly: 5.205,
          },
          {
            ic_uuid: "9df35b59-623c-4f61-8059-f6efecc7e451",
            ic_lat: -93073,
            ic_lon: -359431,
            ic_city: "Murici",
            ic_states: "ALAGOAS",
            ic_january: 5.814,
            ic_february: 5.823,
            ic_march: 5.826,
            ic_april: 5.076,
            ic_may: 4.182,
            ic_june: 3.847,
            ic_july: 3.905,
            ic_august: 4.566,
            ic_september: 5.354,
            ic_october: 5.643,
            ic_november: 6.124,
            ic_december: 6.09,
            ic_yearly: 5.188,
          },
          {
            ic_uuid: "af3b75ae-a05e-4e21-ad9f-93ab831e0449",
            ic_lat: -33194,
            ic_lon: -420943,
            ic_city: "Murici dos Portelas",
            ic_states: "PIAUÍ",
            ic_january: 5.204,
            ic_february: 5.217,
            ic_march: 5.212,
            ic_april: 5.009,
            ic_may: 5.106,
            ic_june: 5.372,
            ic_july: 5.563,
            ic_august: 6.03,
            ic_september: 6.336,
            ic_october: 6.39,
            ic_november: 6.222,
            ic_december: 5.638,
            ic_yearly: 5.608,
          },
          {
            ic_uuid: "2b4e563d-f525-499e-a759-03b6b4b585cf",
            ic_lat: -71451,
            ic_lon: -486076,
            ic_city: "Muricilândia",
            ic_states: "TOCANTINS",
            ic_january: 4.7,
            ic_february: 4.8,
            ic_march: 4.623,
            ic_april: 4.722,
            ic_may: 4.886,
            ic_june: 5.101,
            ic_july: 5.223,
            ic_august: 5.852,
            ic_september: 5.61,
            ic_october: 4.991,
            ic_november: 4.68,
            ic_december: 4.627,
            ic_yearly: 4.985,
          },
          {
            ic_uuid: "736d5c95-c86a-4032-bb2e-e5c0cb737bdf",
            ic_lat: -1262,
            ic_lon: -389903,
            ic_city: "Muritiba",
            ic_states: "BAHIA",
            ic_january: 6.102,
            ic_february: 5.979,
            ic_march: 5.75,
            ic_april: 4.672,
            ic_may: 4.007,
            ic_june: 3.635,
            ic_july: 3.937,
            ic_august: 4.446,
            ic_september: 5.297,
            ic_october: 5.553,
            ic_november: 5.796,
            ic_december: 6.091,
            ic_yearly: 5.106,
          },
          {
            ic_uuid: "b8f99011-d88f-4869-b866-3ad234829789",
            ic_lat: -209913,
            ic_lon: -512778,
            ic_city: "Murutinga do Sul",
            ic_states: "SÃO PAULO",
            ic_january: 5.883,
            ic_february: 5.927,
            ic_march: 5.428,
            ic_april: 4.923,
            ic_may: 4.099,
            ic_june: 3.8,
            ic_july: 3.966,
            ic_august: 4.921,
            ic_september: 4.977,
            ic_october: 5.64,
            ic_november: 6.064,
            ic_december: 6.288,
            ic_yearly: 5.16,
          },
          {
            ic_uuid: "a31007e5-0a24-4a45-bbe7-8e03951ccdad",
            ic_lat: -132288,
            ic_lon: -395047,
            ic_city: "Mutuípe",
            ic_states: "BAHIA",
            ic_january: 5.781,
            ic_february: 5.747,
            ic_march: 5.547,
            ic_april: 4.482,
            ic_may: 3.884,
            ic_june: 3.464,
            ic_july: 3.646,
            ic_august: 4.127,
            ic_september: 4.874,
            ic_october: 4.984,
            ic_november: 5.23,
            ic_december: 5.852,
            ic_yearly: 4.801,
          },
          {
            ic_uuid: "1f061178-5997-4ba0-8c9e-93c14dd2d211",
            ic_lat: -198126,
            ic_lon: -41441,
            ic_city: "Mutum",
            ic_states: "MINAS GERAIS",
            ic_january: 6.001,
            ic_february: 6.213,
            ic_march: 5.186,
            ic_april: 4.729,
            ic_may: 3.928,
            ic_june: 3.67,
            ic_july: 3.767,
            ic_august: 4.414,
            ic_september: 4.765,
            ic_october: 4.948,
            ic_november: 4.946,
            ic_december: 5.669,
            ic_yearly: 4.853,
          },
          {
            ic_uuid: "681421d3-22ba-4aea-a7dd-b7e41234b600",
            ic_lat: -137308,
            ic_lon: -492749,
            ic_city: "Mutunópolis",
            ic_states: "GOIÁS",
            ic_january: 5.376,
            ic_february: 5.542,
            ic_march: 5.146,
            ic_april: 5.2,
            ic_may: 5.018,
            ic_june: 4.786,
            ic_july: 5.143,
            ic_august: 5.797,
            ic_september: 5.703,
            ic_october: 5.642,
            ic_november: 5.176,
            ic_december: 5.324,
            ic_yearly: 5.321,
          },
          {
            ic_uuid: "cb65c2f6-535c-4302-be28-d0bf79d9d057",
            ic_lat: -213696,
            ic_lon: -465217,
            ic_city: "Muzambinho",
            ic_states: "MINAS GERAIS",
            ic_january: 5.422,
            ic_february: 5.603,
            ic_march: 4.804,
            ic_april: 4.613,
            ic_may: 3.986,
            ic_june: 3.774,
            ic_july: 4.063,
            ic_august: 4.955,
            ic_september: 5.078,
            ic_october: 5.379,
            ic_november: 5.32,
            ic_december: 5.521,
            ic_yearly: 4.876,
          },
          {
            ic_uuid: "24cc116e-f322-40e7-942c-fd23acac82e3",
            ic_lat: -184549,
            ic_lon: -422485,
            ic_city: "Nacip Raydan",
            ic_states: "MINAS GERAIS",
            ic_january: 6.01,
            ic_february: 6.114,
            ic_march: 5.221,
            ic_april: 4.515,
            ic_may: 3.839,
            ic_june: 3.658,
            ic_july: 3.744,
            ic_august: 4.48,
            ic_september: 4.979,
            ic_october: 5.218,
            ic_november: 4.863,
            ic_december: 5.755,
            ic_yearly: 4.866,
          },
          {
            ic_uuid: "b15fd16c-8575-4d84-a239-70fca76efe17",
            ic_lat: -226195,
            ic_lon: -512375,
            ic_city: "Nantes",
            ic_states: "SÃO PAULO",
            ic_january: 5.86,
            ic_february: 5.832,
            ic_march: 5.356,
            ic_april: 4.724,
            ic_may: 3.791,
            ic_june: 3.423,
            ic_july: 3.695,
            ic_august: 4.631,
            ic_september: 4.734,
            ic_october: 5.446,
            ic_november: 6.053,
            ic_december: 6.351,
            ic_yearly: 4.991,
          },
          {
            ic_uuid: "1387a6c4-4c47-4968-bb17-2d692a7d1b4d",
            ic_lat: -178486,
            ic_lon: -403536,
            ic_city: "Nanuque",
            ic_states: "MINAS GERAIS",
            ic_january: 6.126,
            ic_february: 6.167,
            ic_march: 5.402,
            ic_april: 4.631,
            ic_may: 3.839,
            ic_june: 3.525,
            ic_july: 3.634,
            ic_august: 4.291,
            ic_september: 4.85,
            ic_october: 5.187,
            ic_november: 5.179,
            ic_december: 6.092,
            ic_yearly: 4.91,
          },
          {
            ic_uuid: "50f5b2bc-663f-4ad1-9eb2-6f0fbed0ce39",
            ic_lat: -284552,
            ic_lon: -528186,
            ic_city: "Não-Me-Toque",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.341,
            ic_february: 5.886,
            ic_march: 5.005,
            ic_april: 4.005,
            ic_may: 3.038,
            ic_june: 2.497,
            ic_july: 2.818,
            ic_august: 3.56,
            ic_september: 3.867,
            ic_october: 5.033,
            ic_november: 6.25,
            ic_december: 6.621,
            ic_yearly: 4.577,
          },
          {
            ic_uuid: "e6ba3f2b-af1b-441f-a689-637b8043abe4",
            ic_lat: -192258,
            ic_lon: -423302,
            ic_city: "Naque",
            ic_states: "MINAS GERAIS",
            ic_january: 5.914,
            ic_february: 6.193,
            ic_march: 5.204,
            ic_april: 4.666,
            ic_may: 3.926,
            ic_june: 3.744,
            ic_july: 3.883,
            ic_august: 4.537,
            ic_september: 4.958,
            ic_october: 5.114,
            ic_november: 4.915,
            ic_december: 5.688,
            ic_yearly: 4.895,
          },
          {
            ic_uuid: "9ae90594-ee2a-4d18-89ab-698a9adaa9bf",
            ic_lat: -224069,
            ic_lon: -515239,
            ic_city: "Narandiba",
            ic_states: "SÃO PAULO",
            ic_january: 5.958,
            ic_february: 5.939,
            ic_march: 5.428,
            ic_april: 4.765,
            ic_may: 3.832,
            ic_june: 3.488,
            ic_july: 3.741,
            ic_august: 4.673,
            ic_september: 4.817,
            ic_october: 5.523,
            ic_november: 6.097,
            ic_december: 6.49,
            ic_yearly: 5.063,
          },
          {
            ic_uuid: "f87f122e-77eb-486b-9534-605cdd473190",
            ic_lat: -57944,
            ic_lon: -351992,
            ic_city: "Natal",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.017,
            ic_february: 6.032,
            ic_march: 6.082,
            ic_april: 5.561,
            ic_may: 5.075,
            ic_june: 4.658,
            ic_july: 4.773,
            ic_august: 5.571,
            ic_september: 5.971,
            ic_october: 6.112,
            ic_november: 6.269,
            ic_december: 5.968,
            ic_yearly: 5.674,
          },
          {
            ic_uuid: "28d53b21-739f-469a-b308-3174400f46d4",
            ic_lat: -165059,
            ic_lon: -464941,
            ic_city: "Natalândia",
            ic_states: "MINAS GERAIS",
            ic_january: 5.971,
            ic_february: 6.245,
            ic_march: 5.361,
            ic_april: 5.256,
            ic_may: 4.877,
            ic_june: 4.659,
            ic_july: 5.02,
            ic_august: 5.849,
            ic_september: 5.799,
            ic_october: 5.833,
            ic_november: 5.276,
            ic_december: 5.738,
            ic_yearly: 5.49,
          },
          {
            ic_uuid: "260bd4e6-729b-4ba6-89db-11cc3cfa0d66",
            ic_lat: -221162,
            ic_lon: -455127,
            ic_city: "Natércia",
            ic_states: "MINAS GERAIS",
            ic_january: 5.386,
            ic_february: 5.694,
            ic_march: 4.835,
            ic_april: 4.558,
            ic_may: 3.911,
            ic_june: 3.662,
            ic_july: 3.878,
            ic_august: 4.778,
            ic_september: 4.958,
            ic_october: 5.406,
            ic_november: 5.272,
            ic_december: 5.655,
            ic_yearly: 4.833,
          },
          {
            ic_uuid: "2a08ed4b-5c8c-48cb-8461-30ba515e24f4",
            ic_lat: -210439,
            ic_lon: -419732,
            ic_city: "Natividade",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.08,
            ic_february: 6.279,
            ic_march: 5.158,
            ic_april: 4.578,
            ic_may: 3.901,
            ic_june: 3.689,
            ic_july: 3.789,
            ic_august: 4.554,
            ic_september: 4.933,
            ic_october: 5.171,
            ic_november: 5.016,
            ic_december: 5.85,
            ic_yearly: 4.917,
          },
          {
            ic_uuid: "af0b30dd-7a0d-46a2-92ce-ceefb4013c40",
            ic_lat: -117072,
            ic_lon: -477281,
            ic_city: "Natividade",
            ic_states: "TOCANTINS",
            ic_january: 5.518,
            ic_february: 5.535,
            ic_march: 5.146,
            ic_april: 5.177,
            ic_may: 5.191,
            ic_june: 5.054,
            ic_july: 5.37,
            ic_august: 6.04,
            ic_september: 6.064,
            ic_october: 5.663,
            ic_november: 5.394,
            ic_december: 5.475,
            ic_yearly: 5.469,
          },
          {
            ic_uuid: "ec6de67b-8d22-4956-8514-b323c9f34b9d",
            ic_lat: -233711,
            ic_lon: -454472,
            ic_city: "Natividade da Serra",
            ic_states: "SÃO PAULO",
            ic_january: 5.664,
            ic_february: 5.812,
            ic_march: 4.852,
            ic_april: 4.343,
            ic_may: 3.524,
            ic_june: 3.26,
            ic_july: 3.361,
            ic_august: 4.261,
            ic_september: 4.312,
            ic_october: 4.915,
            ic_november: 5.138,
            ic_december: 5.819,
            ic_yearly: 4.605,
          },
          {
            ic_uuid: "ae617aa1-672b-44ab-999f-59acf8100ac6",
            ic_lat: -76435,
            ic_lon: -355531,
            ic_city: "Natuba",
            ic_states: "PARAÍBA",
            ic_january: 5.709,
            ic_february: 5.925,
            ic_march: 5.9,
            ic_april: 5.3,
            ic_may: 4.543,
            ic_june: 4.079,
            ic_july: 4.234,
            ic_august: 5.009,
            ic_september: 5.618,
            ic_october: 5.917,
            ic_november: 6.219,
            ic_december: 6.062,
            ic_yearly: 5.376,
          },
          {
            ic_uuid: "bd0f6a48-b02e-45a1-b393-31298545ba75",
            ic_lat: -268948,
            ic_lon: -48655,
            ic_city: "Navegantes",
            ic_states: "SANTA CATARINA",
            ic_january: 5.738,
            ic_february: 5.435,
            ic_march: 4.648,
            ic_april: 3.693,
            ic_may: 3.016,
            ic_june: 2.51,
            ic_july: 2.626,
            ic_august: 3.256,
            ic_september: 3.505,
            ic_october: 4.213,
            ic_november: 5.368,
            ic_december: 5.774,
            ic_yearly: 4.148,
          },
          {
            ic_uuid: "ffc66caa-4750-46c0-aded-0d879b587fed",
            ic_lat: -230623,
            ic_lon: -541999,
            ic_city: "Naviraí",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.964,
            ic_february: 5.776,
            ic_march: 5.31,
            ic_april: 4.442,
            ic_may: 3.53,
            ic_june: 3.182,
            ic_july: 3.392,
            ic_august: 4.333,
            ic_september: 4.615,
            ic_october: 5.369,
            ic_november: 6.056,
            ic_december: 6.427,
            ic_yearly: 4.867,
          },
          {
            ic_uuid: "2cddf2e6-7f2e-4535-9b80-ca0274a2cc33",
            ic_lat: -130336,
            ic_lon: -390104,
            ic_city: "Nazaré",
            ic_states: "BAHIA",
            ic_january: 6.054,
            ic_february: 5.958,
            ic_march: 5.742,
            ic_april: 4.63,
            ic_may: 4.001,
            ic_june: 3.615,
            ic_july: 3.848,
            ic_august: 4.421,
            ic_september: 5.151,
            ic_october: 5.353,
            ic_november: 5.69,
            ic_december: 6.115,
            ic_yearly: 5.048,
          },
          {
            ic_uuid: "f74957d3-9ff4-4c04-aea6-4b947fa96435",
            ic_lat: -63754,
            ic_lon: -476647,
            ic_city: "Nazaré",
            ic_states: "TOCANTINS",
            ic_january: 4.652,
            ic_february: 4.857,
            ic_march: 4.791,
            ic_april: 4.856,
            ic_may: 4.933,
            ic_june: 5.243,
            ic_july: 5.417,
            ic_august: 5.939,
            ic_september: 5.753,
            ic_october: 5.086,
            ic_november: 4.732,
            ic_december: 4.655,
            ic_yearly: 5.076,
          },
          {
            ic_uuid: "5531f821-1c0c-4d01-b9f4-c16c7bcf3522",
            ic_lat: -77419,
            ic_lon: -352196,
            ic_city: "Nazaré da Mata",
            ic_states: "PERNAMBUCO",
            ic_january: 5.528,
            ic_february: 5.682,
            ic_march: 5.76,
            ic_april: 5.146,
            ic_may: 4.432,
            ic_june: 4.066,
            ic_july: 4.179,
            ic_august: 4.896,
            ic_september: 5.431,
            ic_october: 5.643,
            ic_november: 5.98,
            ic_december: 5.86,
            ic_yearly: 5.217,
          },
          {
            ic_uuid: "fc520def-4b0d-4759-9253-e18e20ab989d",
            ic_lat: -69747,
            ic_lon: -426734,
            ic_city: "Nazaré do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.304,
            ic_february: 5.359,
            ic_march: 5.317,
            ic_april: 5.308,
            ic_may: 5.328,
            ic_june: 5.315,
            ic_july: 5.594,
            ic_august: 6.158,
            ic_september: 6.514,
            ic_october: 6.379,
            ic_november: 6.078,
            ic_december: 5.681,
            ic_yearly: 5.694,
          },
          {
            ic_uuid: "9232c0e2-4807-4ed6-9d5a-5f9698394970",
            ic_lat: -231752,
            ic_lon: -463987,
            ic_city: "Nazaré Paulista",
            ic_states: "SÃO PAULO",
            ic_january: 5.387,
            ic_february: 5.703,
            ic_march: 4.847,
            ic_april: 4.328,
            ic_may: 3.553,
            ic_june: 3.305,
            ic_july: 3.396,
            ic_august: 4.406,
            ic_september: 4.441,
            ic_october: 5.005,
            ic_november: 5.265,
            ic_december: 5.876,
            ic_yearly: 4.626,
          },
          {
            ic_uuid: "0ba0103d-b910-4ade-af12-6ef71d6febc5",
            ic_lat: -212173,
            ic_lon: -446142,
            ic_city: "Nazareno",
            ic_states: "MINAS GERAIS",
            ic_january: 5.597,
            ic_february: 5.783,
            ic_march: 4.955,
            ic_april: 4.602,
            ic_may: 3.908,
            ic_june: 3.696,
            ic_july: 3.914,
            ic_august: 4.894,
            ic_september: 5.118,
            ic_october: 5.317,
            ic_november: 5.131,
            ic_december: 5.583,
            ic_yearly: 4.875,
          },
          {
            ic_uuid: "89a06cd3-3fa9-4b86-95e3-16ed4530fd0b",
            ic_lat: -69118,
            ic_lon: -383223,
            ic_city: "Nazarezinho",
            ic_states: "PARAÍBA",
            ic_january: 6.192,
            ic_february: 6.189,
            ic_march: 6.208,
            ic_april: 5.86,
            ic_may: 5.316,
            ic_june: 5.058,
            ic_july: 5.321,
            ic_august: 6.036,
            ic_september: 6.545,
            ic_october: 6.687,
            ic_november: 6.815,
            ic_december: 6.482,
            ic_yearly: 6.059,
          },
          {
            ic_uuid: "840d6854-cb63-4c0d-b82d-1721225c5593",
            ic_lat: -53473,
            ic_lon: -428144,
            ic_city: "Nazária",
            ic_states: "PIAUÍ",
            ic_january: 5.064,
            ic_february: 5.258,
            ic_march: 5.222,
            ic_april: 5.086,
            ic_may: 5.079,
            ic_june: 5.293,
            ic_july: 5.607,
            ic_august: 6.184,
            ic_september: 6.561,
            ic_october: 6.362,
            ic_november: 6.104,
            ic_december: 5.709,
            ic_yearly: 5.628,
          },
          {
            ic_uuid: "c4c3f15a-20ca-4ff4-b3fc-f6a4351214b0",
            ic_lat: -165812,
            ic_lon: -498821,
            ic_city: "Nazário",
            ic_states: "GOIÁS",
            ic_january: 5.513,
            ic_february: 5.602,
            ic_march: 5.216,
            ic_april: 5.197,
            ic_may: 4.793,
            ic_june: 4.585,
            ic_july: 4.773,
            ic_august: 5.693,
            ic_september: 5.482,
            ic_october: 5.566,
            ic_november: 5.349,
            ic_december: 5.547,
            ic_yearly: 5.276,
          },
          {
            ic_uuid: "ac78cdd6-f72b-48f2-8d12-ccf338ad06db",
            ic_lat: -103219,
            ic_lon: -365853,
            ic_city: "Neópolis",
            ic_states: "SERGIPE",
            ic_january: 6.097,
            ic_february: 5.947,
            ic_march: 5.891,
            ic_april: 5.045,
            ic_may: 4.298,
            ic_june: 4.042,
            ic_july: 4.11,
            ic_august: 4.722,
            ic_september: 5.442,
            ic_october: 5.861,
            ic_november: 6.297,
            ic_december: 6.312,
            ic_yearly: 5.338,
          },
          {
            ic_uuid: "7ae7571d-4062-48ae-91b7-4aba1d07324b",
            ic_lat: -212329,
            ic_lon: -452353,
            ic_city: "Nepomuceno",
            ic_states: "MINAS GERAIS",
            ic_january: 5.669,
            ic_february: 5.835,
            ic_march: 4.988,
            ic_april: 4.735,
            ic_may: 4.037,
            ic_june: 3.803,
            ic_july: 4.069,
            ic_august: 4.994,
            ic_september: 5.164,
            ic_october: 5.472,
            ic_november: 5.311,
            ic_december: 5.696,
            ic_yearly: 4.981,
          },
          {
            ic_uuid: "0e3b6389-d0e4-4426-b3b9-1a7a8b5f40ca",
            ic_lat: -164052,
            ic_lon: -492231,
            ic_city: "Nerópolis",
            ic_states: "GOIÁS",
            ic_january: 5.517,
            ic_february: 5.523,
            ic_march: 5.144,
            ic_april: 5.115,
            ic_may: 4.839,
            ic_june: 4.598,
            ic_july: 4.843,
            ic_august: 5.748,
            ic_september: 5.535,
            ic_october: 5.541,
            ic_november: 5.262,
            ic_december: 5.429,
            ic_yearly: 5.258,
          },
          {
            ic_uuid: "4810754a-32f3-496a-a780-2a7d4be4db9e",
            ic_lat: -20846,
            ic_lon: -496299,
            ic_city: "Neves Paulista",
            ic_states: "SÃO PAULO",
            ic_january: 5.823,
            ic_february: 5.874,
            ic_march: 5.237,
            ic_april: 4.875,
            ic_may: 4.153,
            ic_june: 3.875,
            ic_july: 4.123,
            ic_august: 5.037,
            ic_september: 5.058,
            ic_october: 5.627,
            ic_november: 5.972,
            ic_december: 6.178,
            ic_yearly: 5.153,
          },
          {
            ic_uuid: "bb5419ee-4f5a-42af-9d1c-fd6fdaf28011",
            ic_lat: -22083,
            ic_lon: -567116,
            ic_city: "Nhamundá",
            ic_states: "AMAZONAS",
            ic_january: 4.158,
            ic_february: 4.198,
            ic_march: 4.214,
            ic_april: 4.09,
            ic_may: 4.178,
            ic_june: 4.74,
            ic_july: 4.75,
            ic_august: 5.326,
            ic_september: 5.219,
            ic_october: 5.304,
            ic_november: 5.084,
            ic_december: 4.537,
            ic_yearly: 4.65,
          },
          {
            ic_uuid: "9c836b37-f1a9-4ad1-b487-e596bd46710e",
            ic_lat: -20695,
            ic_lon: -50044,
            ic_city: "Nhandeara",
            ic_states: "SÃO PAULO",
            ic_january: 5.841,
            ic_february: 5.886,
            ic_march: 5.248,
            ic_april: 4.883,
            ic_may: 4.128,
            ic_june: 3.879,
            ic_july: 4.122,
            ic_august: 4.98,
            ic_september: 5.0,
            ic_october: 5.622,
            ic_november: 5.935,
            ic_december: 6.176,
            ic_yearly: 5.142,
          },
          {
            ic_uuid: "4d6ff426-e637-4817-973e-05a3c8664eb9",
            ic_lat: -285303,
            ic_lon: -524681,
            ic_city: "Nicolau Vergueiro",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.285,
            ic_february: 5.87,
            ic_march: 4.994,
            ic_april: 3.999,
            ic_may: 2.988,
            ic_june: 2.469,
            ic_july: 2.782,
            ic_august: 3.561,
            ic_september: 3.832,
            ic_october: 5.006,
            ic_november: 6.219,
            ic_december: 6.627,
            ic_yearly: 4.553,
          },
          {
            ic_uuid: "8f1d7a5c-10b5-41c8-850b-4a5446c8a742",
            ic_lat: -136008,
            ic_lon: -391059,
            ic_city: "Nilo Peçanha",
            ic_states: "BAHIA",
            ic_january: 5.947,
            ic_february: 5.795,
            ic_march: 5.533,
            ic_april: 4.535,
            ic_may: 3.928,
            ic_june: 3.555,
            ic_july: 3.795,
            ic_august: 4.289,
            ic_september: 4.977,
            ic_october: 5.141,
            ic_november: 5.454,
            ic_december: 5.968,
            ic_yearly: 4.91,
          },
          {
            ic_uuid: "588f6b09-45ca-4994-a0d7-496d274b6d80",
            ic_lat: -228061,
            ic_lon: -434237,
            ic_city: "Nilópolis",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.848,
            ic_february: 6.049,
            ic_march: 4.899,
            ic_april: 4.321,
            ic_may: 3.585,
            ic_june: 3.336,
            ic_july: 3.325,
            ic_august: 4.174,
            ic_september: 4.353,
            ic_october: 4.909,
            ic_november: 4.925,
            ic_december: 5.658,
            ic_yearly: 4.615,
          },
          {
            ic_uuid: "66194e44-7277-4b1b-96df-c2ee91411e01",
            ic_lat: -34676,
            ic_lon: -439115,
            ic_city: "Nina Rodrigues",
            ic_states: "MARANHÃO",
            ic_january: 4.78,
            ic_february: 4.902,
            ic_march: 5.004,
            ic_april: 4.84,
            ic_may: 4.959,
            ic_june: 5.11,
            ic_july: 5.301,
            ic_august: 5.887,
            ic_september: 6.262,
            ic_october: 6.056,
            ic_november: 5.603,
            ic_december: 5.189,
            ic_yearly: 5.324,
          },
          {
            ic_uuid: "e7fd05e3-f95b-425a-a988-f9440b4a7a2c",
            ic_lat: -1532,
            ic_lon: -417556,
            ic_city: "Ninheira",
            ic_states: "MINAS GERAIS",
            ic_january: 6.047,
            ic_february: 6.116,
            ic_march: 5.434,
            ic_april: 4.693,
            ic_may: 4.072,
            ic_june: 3.734,
            ic_july: 4.02,
            ic_august: 4.717,
            ic_september: 5.631,
            ic_october: 5.707,
            ic_november: 5.393,
            ic_december: 6.096,
            ic_yearly: 5.138,
          },
          {
            ic_uuid: "7bc8c309-a3dd-4397-8532-542004b214ec",
            ic_lat: -211581,
            ic_lon: -558283,
            ic_city: "Nioaque",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.881,
            ic_february: 5.746,
            ic_march: 5.408,
            ic_april: 4.723,
            ic_may: 3.794,
            ic_june: 3.499,
            ic_july: 3.659,
            ic_august: 4.568,
            ic_september: 4.797,
            ic_october: 5.487,
            ic_november: 6.024,
            ic_december: 6.331,
            ic_yearly: 4.993,
          },
          {
            ic_uuid: "8c39456b-0044-4b70-9a16-2fc31585caf5",
            ic_lat: -20913,
            ic_lon: -497779,
            ic_city: "Nipoã",
            ic_states: "SÃO PAULO",
            ic_january: 5.84,
            ic_february: 5.901,
            ic_march: 5.275,
            ic_april: 4.87,
            ic_may: 4.103,
            ic_june: 3.855,
            ic_july: 4.101,
            ic_august: 5.013,
            ic_september: 5.065,
            ic_october: 5.607,
            ic_november: 5.954,
            ic_december: 6.179,
            ic_yearly: 5.147,
          },
          {
            ic_uuid: "30f8eb22-0dd0-435a-9f29-ab52caf6905c",
            ic_lat: -144666,
            ic_lon: -484603,
            ic_city: "Niquelândia",
            ic_states: "GOIÁS",
            ic_january: 5.422,
            ic_february: 5.595,
            ic_march: 5.152,
            ic_april: 5.254,
            ic_may: 5.018,
            ic_june: 4.718,
            ic_july: 5.11,
            ic_august: 5.943,
            ic_september: 5.696,
            ic_october: 5.654,
            ic_november: 5.101,
            ic_december: 5.386,
            ic_yearly: 5.337,
          },
          {
            ic_uuid: "f7565a68-2aac-43da-a0b5-b2c2879a7f39",
            ic_lat: -60886,
            ic_lon: -35209,
            ic_city: "Nísia Floresta",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.017,
            ic_february: 6.175,
            ic_march: 6.131,
            ic_april: 5.558,
            ic_may: 5.043,
            ic_june: 4.532,
            ic_july: 4.655,
            ic_august: 5.441,
            ic_september: 5.933,
            ic_october: 6.196,
            ic_november: 6.36,
            ic_december: 6.097,
            ic_yearly: 5.678,
          },
          {
            ic_uuid: "525aaf92-bbd9-4119-864b-db722baa100b",
            ic_lat: -228837,
            ic_lon: -431037,
            ic_city: "Niterói",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.184,
            ic_february: 6.345,
            ic_march: 5.158,
            ic_april: 4.456,
            ic_may: 3.598,
            ic_june: 3.341,
            ic_july: 3.34,
            ic_august: 4.22,
            ic_september: 4.413,
            ic_october: 5.101,
            ic_november: 5.213,
            ic_december: 6.053,
            ic_yearly: 4.785,
          },
          {
            ic_uuid: "a7907045-7e7a-4811-91cd-5206493852e5",
            ic_lat: -147196,
            ic_lon: -563288,
            ic_city: "Nobres",
            ic_states: "MATO GROSSO",
            ic_january: 5.2,
            ic_february: 5.184,
            ic_march: 5.032,
            ic_april: 4.823,
            ic_may: 4.499,
            ic_june: 4.489,
            ic_july: 4.708,
            ic_august: 5.469,
            ic_september: 5.18,
            ic_october: 5.23,
            ic_november: 5.436,
            ic_december: 5.524,
            ic_yearly: 5.064,
          },
          {
            ic_uuid: "455e2790-9b62-4f00-bd3f-ac64996d0599",
            ic_lat: -273694,
            ic_lon: -52776,
            ic_city: "Nonoai",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.289,
            ic_february: 5.823,
            ic_march: 5.108,
            ic_april: 4.091,
            ic_may: 3.114,
            ic_june: 2.636,
            ic_july: 2.908,
            ic_august: 3.766,
            ic_september: 4.012,
            ic_october: 5.125,
            ic_november: 6.13,
            ic_december: 6.535,
            ic_yearly: 4.628,
          },
          {
            ic_uuid: "250e5251-6282-466f-bc76-e9b136427b5c",
            ic_lat: -108262,
            ic_lon: -394301,
            ic_city: "Nordestina",
            ic_states: "BAHIA",
            ic_january: 6.141,
            ic_february: 5.981,
            ic_march: 5.84,
            ic_april: 4.93,
            ic_may: 4.15,
            ic_june: 3.851,
            ic_july: 4.02,
            ic_august: 4.613,
            ic_september: 5.565,
            ic_october: 5.694,
            ic_november: 6.041,
            ic_december: 6.137,
            ic_yearly: 5.247,
          },
          {
            ic_uuid: "3f52f5ba-45d5-4c21-ad51-c92e61c7710e",
            ic_lat: 38821,
            ic_lon: -596317,
            ic_city: "Normandia",
            ic_states: "RORAIMA",
            ic_january: 4.717,
            ic_february: 4.877,
            ic_march: 5.146,
            ic_april: 5.078,
            ic_may: 4.798,
            ic_june: 4.964,
            ic_july: 5.05,
            ic_august: 5.478,
            ic_september: 5.777,
            ic_october: 5.403,
            ic_november: 5.138,
            ic_december: 4.728,
            ic_yearly: 5.096,
          },
          {
            ic_uuid: "7a716cc0-4426-4564-a29f-e504ee0f84cc",
            ic_lat: -144537,
            ic_lon: -568023,
            ic_city: "Nortelândia",
            ic_states: "MATO GROSSO",
            ic_january: 5.272,
            ic_february: 5.211,
            ic_march: 4.963,
            ic_april: 4.826,
            ic_may: 4.495,
            ic_june: 4.47,
            ic_july: 4.645,
            ic_august: 5.368,
            ic_september: 5.104,
            ic_october: 5.247,
            ic_november: 5.36,
            ic_december: 5.414,
            ic_yearly: 5.031,
          },
          {
            ic_uuid: "d8bae9eb-b044-48d0-9421-29798d87dc3b",
            ic_lat: -103975,
            ic_lon: -374518,
            ic_city: "Nossa Senhora Aparecida",
            ic_states: "SERGIPE",
            ic_january: 6.055,
            ic_february: 5.904,
            ic_march: 5.761,
            ic_april: 5.078,
            ic_may: 4.274,
            ic_june: 3.987,
            ic_july: 4.104,
            ic_august: 4.703,
            ic_september: 5.451,
            ic_october: 5.675,
            ic_november: 6.119,
            ic_december: 6.173,
            ic_yearly: 5.274,
          },
          {
            ic_uuid: "6cae6056-c88c-410b-9daa-c18a5d9aeb1e",
            ic_lat: -102162,
            ic_lon: -374214,
            ic_city: "Nossa Senhora da Glória",
            ic_states: "SERGIPE",
            ic_january: 6.12,
            ic_february: 5.91,
            ic_march: 5.753,
            ic_april: 5.101,
            ic_may: 4.309,
            ic_june: 4.014,
            ic_july: 4.2,
            ic_august: 4.791,
            ic_september: 5.536,
            ic_october: 5.703,
            ic_november: 6.244,
            ic_december: 6.171,
            ic_yearly: 5.321,
          },
          {
            ic_uuid: "65e640c7-8205-4dd5-b0fa-a8b5c70da037",
            ic_lat: -104911,
            ic_lon: -371966,
            ic_city: "Nossa Senhora das Dores",
            ic_states: "SERGIPE",
            ic_january: 6.197,
            ic_february: 5.931,
            ic_march: 5.79,
            ic_april: 5.067,
            ic_may: 4.231,
            ic_june: 3.975,
            ic_july: 4.056,
            ic_august: 4.602,
            ic_september: 5.391,
            ic_october: 5.692,
            ic_november: 6.139,
            ic_december: 6.201,
            ic_yearly: 5.273,
          },
          {
            ic_uuid: "c0b85c40-19f7-4575-8ae7-6ba0fe4cfe4c",
            ic_lat: -229134,
            ic_lon: -517982,
            ic_city: "Nossa Senhora das Graças",
            ic_states: "PARANÁ",
            ic_january: 5.979,
            ic_february: 5.966,
            ic_march: 5.451,
            ic_april: 4.71,
            ic_may: 3.736,
            ic_june: 3.445,
            ic_july: 3.64,
            ic_august: 4.587,
            ic_september: 4.774,
            ic_october: 5.508,
            ic_november: 6.184,
            ic_december: 6.445,
            ic_yearly: 5.035,
          },
          {
            ic_uuid: "683858d3-1cba-4f11-b301-b6f55c71ac55",
            ic_lat: -100788,
            ic_lon: -370575,
            ic_city: "Nossa Senhora de Lourdes",
            ic_states: "SERGIPE",
            ic_january: 6.199,
            ic_february: 5.907,
            ic_march: 5.868,
            ic_april: 5.154,
            ic_may: 4.336,
            ic_june: 4.091,
            ic_july: 4.16,
            ic_august: 4.77,
            ic_september: 5.57,
            ic_october: 5.835,
            ic_november: 6.293,
            ic_december: 6.252,
            ic_yearly: 5.37,
          },
          {
            ic_uuid: "d86d3069-8d82-431f-8392-7665ab823e7c",
            ic_lat: -46306,
            ic_lon: -421733,
            ic_city: "Nossa Senhora de Nazaré",
            ic_states: "PIAUÍ",
            ic_january: 5.237,
            ic_february: 5.271,
            ic_march: 5.302,
            ic_april: 5.098,
            ic_may: 5.119,
            ic_june: 5.326,
            ic_july: 5.527,
            ic_august: 6.046,
            ic_september: 6.423,
            ic_october: 6.375,
            ic_november: 6.208,
            ic_december: 5.714,
            ic_yearly: 5.637,
          },
          {
            ic_uuid: "6225300f-0ebc-41c8-8675-20714a17d832",
            ic_lat: -157725,
            ic_lon: -563437,
            ic_city: "Nossa Senhora do Livramento",
            ic_states: "MATO GROSSO",
            ic_january: 5.484,
            ic_february: 5.411,
            ic_march: 5.164,
            ic_april: 4.923,
            ic_may: 4.374,
            ic_june: 4.352,
            ic_july: 4.503,
            ic_august: 5.37,
            ic_september: 5.098,
            ic_october: 5.23,
            ic_november: 5.568,
            ic_december: 5.713,
            ic_yearly: 5.099,
          },
          {
            ic_uuid: "7d0f3961-e72c-4f57-bb66-81a3be6aaec1",
            ic_lat: -108537,
            ic_lon: -371284,
            ic_city: "Nossa Senhora do Socorro",
            ic_states: "SERGIPE",
            ic_january: 6.324,
            ic_february: 6.266,
            ic_march: 6.062,
            ic_april: 5.061,
            ic_may: 4.311,
            ic_june: 3.96,
            ic_july: 4.045,
            ic_august: 4.662,
            ic_september: 5.436,
            ic_october: 5.94,
            ic_november: 6.365,
            ic_december: 6.472,
            ic_yearly: 5.409,
          },
          {
            ic_uuid: "fbb687d2-0353-4362-96f4-40a72208db7a",
            ic_lat: -39818,
            ic_lon: -426208,
            ic_city: "Nossa Senhora dos Remédios",
            ic_states: "PIAUÍ",
            ic_january: 5.286,
            ic_february: 5.326,
            ic_march: 5.316,
            ic_april: 5.03,
            ic_may: 5.059,
            ic_june: 5.272,
            ic_july: 5.593,
            ic_august: 6.075,
            ic_september: 6.412,
            ic_october: 6.456,
            ic_november: 6.17,
            ic_december: 5.699,
            ic_yearly: 5.641,
          },
          {
            ic_uuid: "c6ed312a-10f5-44c3-9f06-c54a01cce8a9",
            ic_lat: -210161,
            ic_lon: -494991,
            ic_city: "Nova Aliança",
            ic_states: "SÃO PAULO",
            ic_january: 5.762,
            ic_february: 5.909,
            ic_march: 5.227,
            ic_april: 4.845,
            ic_may: 4.083,
            ic_june: 3.846,
            ic_july: 4.104,
            ic_august: 4.981,
            ic_september: 5.066,
            ic_october: 5.598,
            ic_november: 5.928,
            ic_december: 6.179,
            ic_yearly: 5.127,
          },
          {
            ic_uuid: "1d5be896-5f8a-4f47-bf6e-844b44f8a8bc",
            ic_lat: -231767,
            ic_lon: -526037,
            ic_city: "Nova Aliança do Ivaí",
            ic_states: "PARANÁ",
            ic_january: 5.984,
            ic_february: 5.846,
            ic_march: 5.381,
            ic_april: 4.626,
            ic_may: 3.625,
            ic_june: 3.299,
            ic_july: 3.509,
            ic_august: 4.454,
            ic_september: 4.685,
            ic_october: 5.434,
            ic_november: 6.133,
            ic_december: 6.411,
            ic_yearly: 4.949,
          },
          {
            ic_uuid: "671abf09-7a01-41f8-84d4-7704a06fd5a1",
            ic_lat: -286827,
            ic_lon: -521635,
            ic_city: "Nova Alvorada",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.171,
            ic_february: 5.838,
            ic_march: 4.907,
            ic_april: 3.937,
            ic_may: 2.921,
            ic_june: 2.443,
            ic_july: 2.75,
            ic_august: 3.53,
            ic_september: 3.783,
            ic_october: 4.916,
            ic_november: 6.185,
            ic_december: 6.62,
            ic_yearly: 4.5,
          },
          {
            ic_uuid: "c985855b-07c0-44a9-ad51-08db4fb3c72d",
            ic_lat: -214661,
            ic_lon: -543829,
            ic_city: "Nova Alvorada do Sul",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.791,
            ic_february: 5.739,
            ic_march: 5.331,
            ic_april: 4.672,
            ic_may: 3.838,
            ic_june: 3.512,
            ic_july: 3.64,
            ic_august: 4.573,
            ic_september: 4.8,
            ic_october: 5.399,
            ic_november: 5.904,
            ic_december: 6.228,
            ic_yearly: 4.952,
          },
          {
            ic_uuid: "c3bfda5d-4067-46fe-b0f7-8603d253cd93",
            ic_lat: -15021,
            ic_lon: -498957,
            ic_city: "Nova América",
            ic_states: "GOIÁS",
            ic_january: 5.154,
            ic_february: 5.394,
            ic_march: 5.01,
            ic_april: 5.033,
            ic_may: 4.919,
            ic_june: 4.697,
            ic_july: 4.907,
            ic_august: 5.772,
            ic_september: 5.501,
            ic_october: 5.513,
            ic_november: 5.184,
            ic_december: 5.251,
            ic_yearly: 5.195,
          },
          {
            ic_uuid: "71f7d4be-7015-426e-a24f-3f020f756b6e",
            ic_lat: -233312,
            ic_lon: -507172,
            ic_city: "Nova América da Colina",
            ic_states: "PARANÁ",
            ic_january: 5.897,
            ic_february: 5.822,
            ic_march: 5.269,
            ic_april: 4.611,
            ic_may: 3.693,
            ic_june: 3.382,
            ic_july: 3.581,
            ic_august: 4.528,
            ic_september: 4.716,
            ic_october: 5.345,
            ic_november: 6.008,
            ic_december: 6.332,
            ic_yearly: 4.932,
          },
          {
            ic_uuid: "89935dad-7a69-4970-b9b2-6128a9e83138",
            ic_lat: -222384,
            ic_lon: -533442,
            ic_city: "Nova Andradina",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.82,
            ic_february: 5.783,
            ic_march: 5.33,
            ic_april: 4.675,
            ic_may: 3.773,
            ic_june: 3.426,
            ic_july: 3.606,
            ic_august: 4.562,
            ic_september: 4.794,
            ic_october: 5.442,
            ic_november: 6.012,
            ic_december: 6.392,
            ic_yearly: 4.968,
          },
          {
            ic_uuid: "c1ea499c-336e-4ad8-882b-0e752369778e",
            ic_lat: -286542,
            ic_lon: -517463,
            ic_city: "Nova Araçá",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.123,
            ic_february: 5.738,
            ic_march: 4.86,
            ic_april: 3.92,
            ic_may: 2.907,
            ic_june: 2.471,
            ic_july: 2.765,
            ic_august: 3.49,
            ic_september: 3.744,
            ic_october: 4.818,
            ic_november: 6.126,
            ic_december: 6.462,
            ic_yearly: 4.452,
          },
          {
            ic_uuid: "ea1d1005-9266-4da4-92a5-f599e5e33696",
            ic_lat: -180601,
            ic_lon: -482556,
            ic_city: "Nova Aurora",
            ic_states: "GOIÁS",
            ic_january: 5.635,
            ic_february: 5.879,
            ic_march: 5.22,
            ic_april: 5.175,
            ic_may: 4.728,
            ic_june: 4.456,
            ic_july: 4.691,
            ic_august: 5.613,
            ic_september: 5.503,
            ic_october: 5.647,
            ic_november: 5.551,
            ic_december: 5.742,
            ic_yearly: 5.32,
          },
          {
            ic_uuid: "8dff5e0b-c74c-400b-a37a-c54b40e7ec8f",
            ic_lat: -245293,
            ic_lon: -532579,
            ic_city: "Nova Aurora",
            ic_states: "PARANÁ",
            ic_january: 6.334,
            ic_february: 5.644,
            ic_march: 5.258,
            ic_april: 4.389,
            ic_may: 3.477,
            ic_june: 3.054,
            ic_july: 3.256,
            ic_august: 4.243,
            ic_september: 4.499,
            ic_october: 5.288,
            ic_november: 6.06,
            ic_december: 6.445,
            ic_yearly: 4.829,
          },
          {
            ic_uuid: "3e8d4581-8507-4697-a1fb-0bb857889e71",
            ic_lat: -98457,
            ic_lon: -578252,
            ic_city: "Nova Bandeirantes",
            ic_states: "MATO GROSSO",
            ic_january: 4.564,
            ic_february: 4.578,
            ic_march: 4.477,
            ic_april: 4.419,
            ic_may: 4.384,
            ic_june: 4.563,
            ic_july: 4.751,
            ic_august: 5.227,
            ic_september: 4.924,
            ic_october: 5.112,
            ic_november: 4.836,
            ic_december: 4.82,
            ic_yearly: 4.721,
          },
          {
            ic_uuid: "3e6f61e4-c7be-46c8-b249-ef75a2bd1499",
            ic_lat: -287296,
            ic_lon: -517076,
            ic_city: "Nova Bassano",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.123,
            ic_february: 5.738,
            ic_march: 4.86,
            ic_april: 3.92,
            ic_may: 2.907,
            ic_june: 2.471,
            ic_july: 2.765,
            ic_august: 3.49,
            ic_september: 3.744,
            ic_october: 4.818,
            ic_november: 6.126,
            ic_december: 6.462,
            ic_yearly: 4.452,
          },
          {
            ic_uuid: "d156e543-7291-442a-a28b-5d437a83f719",
            ic_lat: -18493,
            ic_lon: -41111,
            ic_city: "Nova Belém",
            ic_states: "MINAS GERAIS",
            ic_january: 5.833,
            ic_february: 5.919,
            ic_march: 5.039,
            ic_april: 4.39,
            ic_may: 3.724,
            ic_june: 3.533,
            ic_july: 3.655,
            ic_august: 4.214,
            ic_september: 4.787,
            ic_october: 4.987,
            ic_november: 4.847,
            ic_december: 5.664,
            ic_yearly: 4.716,
          },
          {
            ic_uuid: "f0e7173d-a0f2-47c4-91a4-2757fad72d72",
            ic_lat: -279931,
            ic_lon: -529788,
            ic_city: "Nova Boa Vista",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.306,
            ic_february: 5.924,
            ic_march: 5.046,
            ic_april: 4.102,
            ic_may: 3.073,
            ic_june: 2.571,
            ic_july: 2.877,
            ic_august: 3.644,
            ic_september: 3.901,
            ic_october: 5.127,
            ic_november: 6.258,
            ic_december: 6.651,
            ic_yearly: 4.623,
          },
          {
            ic_uuid: "957904e4-637b-44aa-a300-14c6d2b640fa",
            ic_lat: -149292,
            ic_lon: -549736,
            ic_city: "Nova Brasilândia",
            ic_states: "MATO GROSSO",
            ic_january: 5.403,
            ic_february: 5.285,
            ic_march: 5.158,
            ic_april: 5.157,
            ic_may: 4.796,
            ic_june: 4.665,
            ic_july: 4.751,
            ic_august: 5.51,
            ic_september: 5.271,
            ic_october: 5.279,
            ic_november: 5.371,
            ic_december: 5.46,
            ic_yearly: 5.175,
          },
          {
            ic_uuid: "49115290-5860-4ff3-adb7-52ce13d8fd7c",
            ic_lat: -117251,
            ic_lon: -623131,
            ic_city: "Nova Brasilândia D'Oeste",
            ic_states: "RONDÔNIA",
            ic_january: 4.452,
            ic_february: 4.404,
            ic_march: 4.432,
            ic_april: 4.461,
            ic_may: 3.98,
            ic_june: 4.349,
            ic_july: 4.444,
            ic_august: 4.945,
            ic_september: 4.937,
            ic_october: 5.116,
            ic_november: 4.912,
            ic_december: 4.732,
            ic_yearly: 4.597,
          },
          {
            ic_uuid: "22ce217b-147c-46bf-85da-cbde25ec8e5a",
            ic_lat: -29214,
            ic_lon: -520279,
            ic_city: "Nova Bréscia",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.123,
            ic_february: 5.706,
            ic_march: 4.782,
            ic_april: 3.795,
            ic_may: 2.826,
            ic_june: 2.352,
            ic_july: 2.622,
            ic_august: 3.358,
            ic_september: 3.627,
            ic_october: 4.654,
            ic_november: 6.045,
            ic_december: 6.414,
            ic_yearly: 4.359,
          },
          {
            ic_uuid: "6d73169c-36b3-4abc-8c68-8f3886dd2f40",
            ic_lat: -241228,
            ic_lon: -489026,
            ic_city: "Nova Campina",
            ic_states: "SÃO PAULO",
            ic_january: 5.548,
            ic_february: 5.603,
            ic_march: 4.928,
            ic_april: 4.268,
            ic_may: 3.427,
            ic_june: 3.089,
            ic_july: 3.275,
            ic_august: 4.265,
            ic_september: 4.414,
            ic_october: 4.921,
            ic_november: 5.594,
            ic_december: 6.045,
            ic_yearly: 4.615,
          },
          {
            ic_uuid: "4e66eac1-2dde-479f-968f-7cc64937d09b",
            ic_lat: -14795,
            ic_lon: -401443,
            ic_city: "Nova Canaã",
            ic_states: "BAHIA",
            ic_january: 5.88,
            ic_february: 5.998,
            ic_march: 5.46,
            ic_april: 4.56,
            ic_may: 3.895,
            ic_june: 3.498,
            ic_july: 3.649,
            ic_august: 4.147,
            ic_september: 4.956,
            ic_october: 5.174,
            ic_november: 5.342,
            ic_december: 6.1,
            ic_yearly: 4.888,
          },
          {
            ic_uuid: "631077f4-d652-4ecd-b340-54bfb8a107a1",
            ic_lat: -105584,
            ic_lon: -559534,
            ic_city: "Nova Canaã do Norte",
            ic_states: "MATO GROSSO",
            ic_january: 4.882,
            ic_february: 4.858,
            ic_march: 4.759,
            ic_april: 4.669,
            ic_may: 4.644,
            ic_june: 4.641,
            ic_july: 4.842,
            ic_august: 5.336,
            ic_september: 5.057,
            ic_october: 5.162,
            ic_november: 5.0,
            ic_december: 5.047,
            ic_yearly: 4.908,
          },
          {
            ic_uuid: "e82f369e-6527-4b75-87c1-e0af46613c4e",
            ic_lat: -20386,
            ic_lon: -509489,
            ic_city: "Nova Canaã Paulista",
            ic_states: "SÃO PAULO",
            ic_january: 5.875,
            ic_february: 6.043,
            ic_march: 5.424,
            ic_april: 4.958,
            ic_may: 4.207,
            ic_june: 3.839,
            ic_july: 4.1,
            ic_august: 5.041,
            ic_september: 5.014,
            ic_october: 5.686,
            ic_november: 6.04,
            ic_december: 6.383,
            ic_yearly: 5.218,
          },
          {
            ic_uuid: "5573d93b-ecac-4a8c-bc0f-56a7e79055f6",
            ic_lat: -276141,
            ic_lon: -541079,
            ic_city: "Nova Candelária",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.49,
            ic_february: 5.985,
            ic_march: 5.148,
            ic_april: 4.06,
            ic_may: 3.119,
            ic_june: 2.548,
            ic_july: 2.881,
            ic_august: 3.693,
            ic_september: 3.989,
            ic_october: 5.232,
            ic_november: 6.229,
            ic_december: 6.671,
            ic_yearly: 4.67,
          },
          {
            ic_uuid: "49f1c6a1-c4cb-4b89-bc49-7bcdc7023f71",
            ic_lat: -246728,
            ic_lon: -525665,
            ic_city: "Nova Cantu",
            ic_states: "PARANÁ",
            ic_january: 6.273,
            ic_february: 5.589,
            ic_march: 5.188,
            ic_april: 4.418,
            ic_may: 3.457,
            ic_june: 3.04,
            ic_july: 3.26,
            ic_august: 4.253,
            ic_september: 4.438,
            ic_october: 5.185,
            ic_november: 6.004,
            ic_december: 6.362,
            ic_yearly: 4.789,
          },
          {
            ic_uuid: "036314a4-0195-4aea-a577-2dfbd97dc827",
            ic_lat: -20763,
            ic_lon: -503429,
            ic_city: "Nova Castilho",
            ic_states: "SÃO PAULO",
            ic_january: 5.885,
            ic_february: 5.946,
            ic_march: 5.334,
            ic_april: 4.897,
            ic_may: 4.118,
            ic_june: 3.843,
            ic_july: 4.072,
            ic_august: 4.991,
            ic_september: 4.997,
            ic_october: 5.633,
            ic_november: 5.995,
            ic_december: 6.269,
            ic_yearly: 5.165,
          },
          {
            ic_uuid: "7a465813-4c5f-4f9c-b046-ba662a3ca7fb",
            ic_lat: -7123,
            ic_lon: -46261,
            ic_city: "Nova Colinas",
            ic_states: "MARANHÃO",
            ic_january: 4.609,
            ic_february: 4.81,
            ic_march: 4.683,
            ic_april: 4.917,
            ic_may: 5.085,
            ic_june: 5.373,
            ic_july: 5.475,
            ic_august: 6.097,
            ic_september: 6.253,
            ic_october: 5.488,
            ic_november: 4.978,
            ic_december: 4.769,
            ic_yearly: 5.212,
          },
          {
            ic_uuid: "32f67292-5528-4523-978f-595bf45b3199",
            ic_lat: -140991,
            ic_lon: -50335,
            ic_city: "Nova Crixás",
            ic_states: "GOIÁS",
            ic_january: 5.415,
            ic_february: 5.622,
            ic_march: 5.198,
            ic_april: 5.163,
            ic_may: 4.9,
            ic_june: 4.626,
            ic_july: 4.985,
            ic_august: 5.651,
            ic_september: 5.519,
            ic_october: 5.48,
            ic_november: 5.175,
            ic_december: 5.388,
            ic_yearly: 5.26,
          },
          {
            ic_uuid: "7122d07d-4b43-4092-824b-0b2d8b1f516b",
            ic_lat: -64791,
            ic_lon: -354343,
            ic_city: "Nova Cruz",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.771,
            ic_february: 5.81,
            ic_march: 5.875,
            ic_april: 5.381,
            ic_may: 4.845,
            ic_june: 4.406,
            ic_july: 4.583,
            ic_august: 5.268,
            ic_september: 5.693,
            ic_october: 5.905,
            ic_november: 6.135,
            ic_december: 5.859,
            ic_yearly: 5.461,
          },
          {
            ic_uuid: "bfef55f6-417f-4e45-a68d-049c3d2a3f0b",
            ic_lat: -19763,
            ic_lon: -430313,
            ic_city: "Nova Era",
            ic_states: "MINAS GERAIS",
            ic_january: 5.628,
            ic_february: 5.939,
            ic_march: 4.974,
            ic_april: 4.501,
            ic_may: 3.852,
            ic_june: 3.634,
            ic_july: 3.89,
            ic_august: 4.654,
            ic_september: 4.961,
            ic_october: 5.05,
            ic_november: 4.739,
            ic_december: 5.465,
            ic_yearly: 4.774,
          },
          {
            ic_uuid: "9f08a98c-e9f9-4e5b-8a00-14fb436635e9",
            ic_lat: -268986,
            ic_lon: -52907,
            ic_city: "Nova Erechim",
            ic_states: "SANTA CATARINA",
            ic_january: 6.173,
            ic_february: 5.693,
            ic_march: 5.121,
            ic_april: 4.112,
            ic_may: 3.141,
            ic_june: 2.637,
            ic_july: 2.925,
            ic_august: 3.808,
            ic_september: 4.041,
            ic_october: 5.136,
            ic_november: 6.116,
            ic_december: 6.422,
            ic_yearly: 4.61,
          },
          {
            ic_uuid: "a8794c6f-64fb-46b8-9037-86d0aecce7f8",
            ic_lat: -231825,
            ic_lon: -522036,
            ic_city: "Nova Esperança",
            ic_states: "PARANÁ",
            ic_january: 5.936,
            ic_february: 5.883,
            ic_march: 5.412,
            ic_april: 4.626,
            ic_may: 3.639,
            ic_june: 3.324,
            ic_july: 3.538,
            ic_august: 4.466,
            ic_september: 4.7,
            ic_october: 5.436,
            ic_november: 6.138,
            ic_december: 6.365,
            ic_yearly: 4.955,
          },
          {
            ic_uuid: "2e6fae47-8f82-448d-ad72-e0918baf73fa",
            ic_lat: -2268,
            ic_lon: -469703,
            ic_city: "Nova Esperança do Piriá",
            ic_states: "PARÁ",
            ic_january: 4.571,
            ic_february: 4.596,
            ic_march: 4.588,
            ic_april: 4.601,
            ic_may: 4.719,
            ic_june: 4.927,
            ic_july: 5.013,
            ic_august: 5.407,
            ic_september: 5.513,
            ic_october: 5.298,
            ic_november: 5.091,
            ic_december: 4.851,
            ic_yearly: 4.931,
          },
          {
            ic_uuid: "8eac847a-b3f7-4e88-8d15-2163b4e60a7e",
            ic_lat: -259065,
            ic_lon: -532657,
            ic_city: "Nova Esperança do Sudoeste",
            ic_states: "PARANÁ",
            ic_january: 6.3,
            ic_february: 5.755,
            ic_march: 5.24,
            ic_april: 4.249,
            ic_may: 3.285,
            ic_june: 2.844,
            ic_july: 3.135,
            ic_august: 4.104,
            ic_september: 4.286,
            ic_october: 5.266,
            ic_november: 6.163,
            ic_december: 6.475,
            ic_yearly: 4.759,
          },
          {
            ic_uuid: "9a450cab-1a5e-4a68-8e22-45ad8cc2a07e",
            ic_lat: -294071,
            ic_lon: -548298,
            ic_city: "Nova Esperança do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.542,
            ic_february: 6.018,
            ic_march: 5.118,
            ic_april: 3.979,
            ic_may: 2.93,
            ic_june: 2.459,
            ic_july: 2.733,
            ic_august: 3.463,
            ic_september: 3.982,
            ic_october: 5.082,
            ic_november: 6.374,
            ic_december: 6.855,
            ic_yearly: 4.628,
          },
          {
            ic_uuid: "dfe83f51-bae2-4644-b6d4-46cd64987ff3",
            ic_lat: -21778,
            ic_lon: -48561,
            ic_city: "Nova Europa",
            ic_states: "SÃO PAULO",
            ic_january: 5.728,
            ic_february: 5.887,
            ic_march: 5.147,
            ic_april: 4.737,
            ic_may: 3.956,
            ic_june: 3.716,
            ic_july: 3.912,
            ic_august: 4.815,
            ic_september: 4.928,
            ic_october: 5.565,
            ic_november: 5.834,
            ic_december: 6.157,
            ic_yearly: 5.032,
          },
          {
            ic_uuid: "1c738c1d-fb85-42bb-acd3-6e1da51d1b38",
            ic_lat: -116082,
            ic_lon: -396301,
            ic_city: "Nova Fátima",
            ic_states: "BAHIA",
            ic_january: 6.179,
            ic_february: 5.994,
            ic_march: 5.836,
            ic_april: 4.823,
            ic_may: 4.013,
            ic_june: 3.706,
            ic_july: 3.953,
            ic_august: 4.486,
            ic_september: 5.389,
            ic_october: 5.583,
            ic_november: 5.86,
            ic_december: 6.045,
            ic_yearly: 5.156,
          },
          {
            ic_uuid: "d8f9c950-a065-4648-8784-b615638b577e",
            ic_lat: -23432,
            ic_lon: -505639,
            ic_city: "Nova Fátima",
            ic_states: "PARANÁ",
            ic_january: 5.879,
            ic_february: 5.816,
            ic_march: 5.209,
            ic_april: 4.578,
            ic_may: 3.657,
            ic_june: 3.343,
            ic_july: 3.558,
            ic_august: 4.536,
            ic_september: 4.687,
            ic_october: 5.303,
            ic_november: 5.998,
            ic_december: 6.365,
            ic_yearly: 4.911,
          },
          {
            ic_uuid: "d2a637e0-05b1-4b23-9b8a-4a9508c5d1ec",
            ic_lat: -64567,
            ic_lon: -362049,
            ic_city: "Nova Floresta",
            ic_states: "PARAÍBA",
            ic_january: 5.875,
            ic_february: 5.844,
            ic_march: 5.871,
            ic_april: 5.497,
            ic_may: 4.916,
            ic_june: 4.505,
            ic_july: 4.722,
            ic_august: 5.471,
            ic_september: 6.004,
            ic_october: 6.248,
            ic_november: 6.304,
            ic_december: 5.931,
            ic_yearly: 5.599,
          },
          {
            ic_uuid: "b9f63adb-294e-4493-a59a-585761360a50",
            ic_lat: -222937,
            ic_lon: -425381,
            ic_city: "Nova Friburgo",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.506,
            ic_february: 5.826,
            ic_march: 4.701,
            ic_april: 4.141,
            ic_may: 3.486,
            ic_june: 3.283,
            ic_july: 3.453,
            ic_august: 4.176,
            ic_september: 4.461,
            ic_october: 4.752,
            ic_november: 4.587,
            ic_december: 5.32,
            ic_yearly: 4.474,
          },
          {
            ic_uuid: "207d9671-7490-4399-9371-0a5a7f253c80",
            ic_lat: -151458,
            ic_lon: -495745,
            ic_city: "Nova Glória",
            ic_states: "GOIÁS",
            ic_january: 5.307,
            ic_february: 5.582,
            ic_march: 5.223,
            ic_april: 5.156,
            ic_may: 4.896,
            ic_june: 4.615,
            ic_july: 4.822,
            ic_august: 5.688,
            ic_september: 5.591,
            ic_october: 5.583,
            ic_november: 5.213,
            ic_december: 5.353,
            ic_yearly: 5.252,
          },
          {
            ic_uuid: "ae9ed442-bc34-4e0d-a57e-354d7b053cae",
            ic_lat: -205325,
            ic_lon: -493127,
            ic_city: "Nova Granada",
            ic_states: "SÃO PAULO",
            ic_january: 5.761,
            ic_february: 5.882,
            ic_march: 5.19,
            ic_april: 4.94,
            ic_may: 4.202,
            ic_june: 3.932,
            ic_july: 4.191,
            ic_august: 5.089,
            ic_september: 5.092,
            ic_october: 5.605,
            ic_november: 5.918,
            ic_december: 6.109,
            ic_yearly: 5.159,
          },
          {
            ic_uuid: "88392b9c-95b6-448b-8f92-aa99b0fe841e",
            ic_lat: -103124,
            ic_lon: -554065,
            ic_city: "Nova Guarita",
            ic_states: "MATO GROSSO",
            ic_january: 4.922,
            ic_february: 4.89,
            ic_march: 4.816,
            ic_april: 4.716,
            ic_may: 4.685,
            ic_june: 4.627,
            ic_july: 4.914,
            ic_august: 5.319,
            ic_september: 5.152,
            ic_october: 5.263,
            ic_november: 5.036,
            ic_december: 5.06,
            ic_yearly: 4.95,
          },
          {
            ic_uuid: "86a47d8c-e030-4159-9cfc-51900ef3eac9",
            ic_lat: -21334,
            ic_lon: -516439,
            ic_city: "Nova Guataporanga",
            ic_states: "SÃO PAULO",
            ic_january: 5.968,
            ic_february: 5.973,
            ic_march: 5.486,
            ic_april: 4.843,
            ic_may: 4.047,
            ic_june: 3.715,
            ic_july: 3.902,
            ic_august: 4.846,
            ic_september: 4.904,
            ic_october: 5.586,
            ic_november: 6.088,
            ic_december: 6.371,
            ic_yearly: 5.144,
          },
          {
            ic_uuid: "7f225492-9465-4502-a039-05b0fd175214",
            ic_lat: -295812,
            ic_lon: -509056,
            ic_city: "Nova Hartz",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.081,
            ic_february: 5.596,
            ic_march: 4.652,
            ic_april: 3.732,
            ic_may: 2.789,
            ic_june: 2.338,
            ic_july: 2.549,
            ic_august: 3.224,
            ic_september: 3.497,
            ic_october: 4.557,
            ic_november: 5.979,
            ic_december: 6.375,
            ic_yearly: 4.281,
          },
          {
            ic_uuid: "b41e5d94-4a52-4f1d-8182-fc5ba1dff5bc",
            ic_lat: -13811,
            ic_lon: -396256,
            ic_city: "Nova Ibiá",
            ic_states: "BAHIA",
            ic_january: 5.866,
            ic_february: 5.802,
            ic_march: 5.45,
            ic_april: 4.435,
            ic_may: 3.846,
            ic_june: 3.461,
            ic_july: 3.6,
            ic_august: 4.118,
            ic_september: 4.827,
            ic_october: 5.09,
            ic_november: 5.371,
            ic_december: 5.917,
            ic_yearly: 4.815,
          },
          {
            ic_uuid: "aa69bc04-b0af-4fc3-be1b-83687656d0b9",
            ic_lat: -227561,
            ic_lon: -434607,
            ic_city: "Nova Iguaçu",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.848,
            ic_february: 6.049,
            ic_march: 4.899,
            ic_april: 4.321,
            ic_may: 3.585,
            ic_june: 3.336,
            ic_july: 3.325,
            ic_august: 4.174,
            ic_september: 4.353,
            ic_october: 4.909,
            ic_november: 4.925,
            ic_december: 5.658,
            ic_yearly: 4.615,
          },
          {
            ic_uuid: "b0b2f27f-7ee1-4aa6-a1b4-39fc3a7288b3",
            ic_lat: -142873,
            ic_lon: -493876,
            ic_city: "Nova Iguaçu de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.493,
            ic_february: 5.655,
            ic_march: 5.335,
            ic_april: 5.2,
            ic_may: 4.988,
            ic_june: 4.702,
            ic_july: 5.087,
            ic_august: 5.766,
            ic_september: 5.647,
            ic_october: 5.703,
            ic_november: 5.289,
            ic_december: 5.449,
            ic_yearly: 5.36,
          },
          {
            ic_uuid: "1e1f1519-1b3d-4883-bde8-cdf688ec5f1a",
            ic_lat: -211031,
            ic_lon: -51491,
            ic_city: "Nova Independência",
            ic_states: "SÃO PAULO",
            ic_january: 5.872,
            ic_february: 5.938,
            ic_march: 5.423,
            ic_april: 4.876,
            ic_may: 4.087,
            ic_june: 3.76,
            ic_july: 3.961,
            ic_august: 4.89,
            ic_september: 4.92,
            ic_october: 5.607,
            ic_november: 6.02,
            ic_december: 6.358,
            ic_yearly: 5.143,
          },
          {
            ic_uuid: "10ac9779-9e45-4107-ac11-81db1e817d59",
            ic_lat: -67309,
            ic_lon: -440475,
            ic_city: "Nova Iorque",
            ic_states: "MARANHÃO",
            ic_january: 4.894,
            ic_february: 5.046,
            ic_march: 5.137,
            ic_april: 5.113,
            ic_may: 5.2,
            ic_june: 5.441,
            ic_july: 5.582,
            ic_august: 5.996,
            ic_september: 6.537,
            ic_october: 6.187,
            ic_november: 5.663,
            ic_december: 5.272,
            ic_yearly: 5.506,
          },
          {
            ic_uuid: "eb098e77-bcfa-4c82-990b-d95c90d90a88",
            ic_lat: -49166,
            ic_lon: -490826,
            ic_city: "Nova Ipixuna",
            ic_states: "PARÁ",
            ic_january: 4.387,
            ic_february: 4.459,
            ic_march: 4.494,
            ic_april: 4.501,
            ic_may: 4.552,
            ic_june: 4.949,
            ic_july: 5.07,
            ic_august: 5.438,
            ic_september: 5.228,
            ic_october: 4.746,
            ic_november: 4.621,
            ic_december: 4.364,
            ic_yearly: 4.734,
          },
          {
            ic_uuid: "08f643f3-1cc5-4a96-9ec1-553dda700d4c",
            ic_lat: -269433,
            ic_lon: -528145,
            ic_city: "Nova Itaberaba",
            ic_states: "SANTA CATARINA",
            ic_january: 6.223,
            ic_february: 5.747,
            ic_march: 5.168,
            ic_april: 4.138,
            ic_may: 3.158,
            ic_june: 2.656,
            ic_july: 2.941,
            ic_august: 3.821,
            ic_september: 4.061,
            ic_october: 5.153,
            ic_november: 6.158,
            ic_december: 6.466,
            ic_yearly: 4.641,
          },
          {
            ic_uuid: "523345b3-9a48-4b36-97d8-129a27821000",
            ic_lat: -130269,
            ic_lon: -400683,
            ic_city: "Nova Itarana",
            ic_states: "BAHIA",
            ic_january: 5.753,
            ic_february: 5.738,
            ic_march: 5.502,
            ic_april: 4.53,
            ic_may: 3.938,
            ic_june: 3.557,
            ic_july: 3.81,
            ic_august: 4.342,
            ic_september: 5.187,
            ic_october: 5.262,
            ic_november: 5.364,
            ic_december: 5.848,
            ic_yearly: 4.902,
          },
          {
            ic_uuid: "8984607b-f403-4057-aef8-6409a10c8deb",
            ic_lat: -144771,
            ic_lon: -595885,
            ic_city: "Nova Lacerda",
            ic_states: "MATO GROSSO",
            ic_january: 5.036,
            ic_february: 4.839,
            ic_march: 4.739,
            ic_april: 4.68,
            ic_may: 4.285,
            ic_june: 4.339,
            ic_july: 4.536,
            ic_august: 5.095,
            ic_september: 5.035,
            ic_october: 5.274,
            ic_november: 5.444,
            ic_december: 5.416,
            ic_yearly: 4.893,
          },
          {
            ic_uuid: "1da61c83-a3e6-49de-b296-c6ed41763740",
            ic_lat: -253058,
            ic_lon: -525451,
            ic_city: "Nova Laranjeiras",
            ic_states: "PARANÁ",
            ic_january: 6.129,
            ic_february: 5.547,
            ic_march: 5.188,
            ic_april: 4.304,
            ic_may: 3.311,
            ic_june: 2.941,
            ic_july: 3.176,
            ic_august: 4.19,
            ic_september: 4.345,
            ic_october: 5.217,
            ic_november: 6.037,
            ic_december: 6.237,
            ic_yearly: 4.718,
          },
          {
            ic_uuid: "799bc593-a231-4283-83ec-290c7b1376b3",
            ic_lat: -199762,
            ic_lon: -438512,
            ic_city: "Nova Lima",
            ic_states: "MINAS GERAIS",
            ic_january: 5.644,
            ic_february: 5.905,
            ic_march: 5.056,
            ic_april: 4.691,
            ic_may: 4.088,
            ic_june: 4.011,
            ic_july: 4.234,
            ic_august: 5.081,
            ic_september: 5.357,
            ic_october: 5.465,
            ic_november: 5.122,
            ic_december: 5.478,
            ic_yearly: 5.011,
          },
          {
            ic_uuid: "8b988314-139e-4ba3-9f1d-2bf335d9e832",
            ic_lat: -227644,
            ic_lon: -529872,
            ic_city: "Nova Londrina",
            ic_states: "PARANÁ",
            ic_january: 6.049,
            ic_february: 5.978,
            ic_march: 5.43,
            ic_april: 4.638,
            ic_may: 3.702,
            ic_june: 3.384,
            ic_july: 3.563,
            ic_august: 4.492,
            ic_september: 4.766,
            ic_october: 5.44,
            ic_november: 6.195,
            ic_december: 6.522,
            ic_yearly: 5.013,
          },
          {
            ic_uuid: "84d2f825-52b6-4b8c-ba48-5ec38a17bcc0",
            ic_lat: -208564,
            ic_lon: -502621,
            ic_city: "Nova Luzitânia",
            ic_states: "SÃO PAULO",
            ic_january: 5.852,
            ic_february: 5.917,
            ic_march: 5.314,
            ic_april: 4.929,
            ic_may: 4.09,
            ic_june: 3.792,
            ic_july: 4.041,
            ic_august: 4.946,
            ic_september: 4.953,
            ic_october: 5.641,
            ic_november: 6.013,
            ic_december: 6.274,
            ic_yearly: 5.147,
          },
          {
            ic_uuid: "29aa83e6-7e11-49d8-9dd8-e5929a2832f6",
            ic_lat: -104081,
            ic_lon: -653351,
            ic_city: "Nova Mamoré",
            ic_states: "RONDÔNIA",
            ic_january: 4.498,
            ic_february: 4.513,
            ic_march: 4.359,
            ic_april: 4.416,
            ic_may: 3.939,
            ic_june: 4.181,
            ic_july: 4.391,
            ic_august: 4.86,
            ic_september: 5.001,
            ic_october: 5.115,
            ic_november: 4.798,
            ic_december: 4.748,
            ic_yearly: 4.568,
          },
          {
            ic_uuid: "05c060d0-fe11-40d5-8587-baf5e98c9f88",
            ic_lat: -143684,
            ic_lon: -569753,
            ic_city: "Nova Marilândia",
            ic_states: "MATO GROSSO",
            ic_january: 5.176,
            ic_february: 5.154,
            ic_march: 4.967,
            ic_april: 4.843,
            ic_may: 4.483,
            ic_june: 4.49,
            ic_july: 4.666,
            ic_august: 5.449,
            ic_september: 5.122,
            ic_october: 5.23,
            ic_november: 5.323,
            ic_december: 5.406,
            ic_yearly: 5.026,
          },
          {
            ic_uuid: "ea3c74dc-b643-49d5-9688-1c162dd68b53",
            ic_lat: -130307,
            ic_lon: -570922,
            ic_city: "Nova Maringá",
            ic_states: "MATO GROSSO",
            ic_january: 5.119,
            ic_february: 4.987,
            ic_march: 4.895,
            ic_april: 4.84,
            ic_may: 4.628,
            ic_june: 4.619,
            ic_july: 4.762,
            ic_august: 5.197,
            ic_september: 5.175,
            ic_october: 5.326,
            ic_november: 5.191,
            ic_december: 5.281,
            ic_yearly: 5.002,
          },
          {
            ic_uuid: "acf911d1-1a39-467a-b957-e1f74637a430",
            ic_lat: -184422,
            ic_lon: -414987,
            ic_city: "Nova Módica",
            ic_states: "MINAS GERAIS",
            ic_january: 6.192,
            ic_february: 6.268,
            ic_march: 5.34,
            ic_april: 4.61,
            ic_may: 3.872,
            ic_june: 3.631,
            ic_july: 3.709,
            ic_august: 4.425,
            ic_september: 4.959,
            ic_october: 5.245,
            ic_november: 5.067,
            ic_december: 5.877,
            ic_yearly: 4.933,
          },
          {
            ic_uuid: "52dc1242-484b-4538-8b94-e30ed8dffa61",
            ic_lat: -100004,
            ic_lon: -575265,
            ic_city: "Nova Monte Verde",
            ic_states: "MATO GROSSO",
            ic_january: 4.626,
            ic_february: 4.61,
            ic_march: 4.52,
            ic_april: 4.485,
            ic_may: 4.44,
            ic_june: 4.593,
            ic_july: 4.729,
            ic_august: 5.259,
            ic_september: 4.955,
            ic_october: 5.135,
            ic_november: 4.939,
            ic_december: 4.887,
            ic_yearly: 4.765,
          },
          {
            ic_uuid: "a45f8d99-fc1b-4125-8f21-0f09594de557",
            ic_lat: -138196,
            ic_lon: -560802,
            ic_city: "Nova Mutum",
            ic_states: "MATO GROSSO",
            ic_january: 5.207,
            ic_february: 5.073,
            ic_march: 4.921,
            ic_april: 4.853,
            ic_may: 4.582,
            ic_june: 4.541,
            ic_july: 4.809,
            ic_august: 5.373,
            ic_september: 5.18,
            ic_october: 5.273,
            ic_november: 5.29,
            ic_december: 5.405,
            ic_yearly: 5.042,
          },
          {
            ic_uuid: "e2230496-a8df-4005-97f9-7a516d7e44ed",
            ic_lat: -13955,
            ic_lon: -517949,
            ic_city: "Nova Nazaré",
            ic_states: "MATO GROSSO",
            ic_january: 5.403,
            ic_february: 5.595,
            ic_march: 5.285,
            ic_april: 5.295,
            ic_may: 5.003,
            ic_june: 4.754,
            ic_july: 5.084,
            ic_august: 5.655,
            ic_september: 5.507,
            ic_october: 5.494,
            ic_november: 5.328,
            ic_december: 5.399,
            ic_yearly: 5.317,
          },
          {
            ic_uuid: "c0421584-bbbf-4088-8af9-a00ca195b154",
            ic_lat: -227837,
            ic_lon: -472945,
            ic_city: "Nova Odessa",
            ic_states: "SÃO PAULO",
            ic_january: 5.641,
            ic_february: 5.781,
            ic_march: 5.157,
            ic_april: 4.638,
            ic_may: 3.793,
            ic_june: 3.557,
            ic_july: 3.703,
            ic_august: 4.661,
            ic_september: 4.844,
            ic_october: 5.475,
            ic_november: 5.734,
            ic_december: 6.165,
            ic_yearly: 4.929,
          },
          {
            ic_uuid: "cbada4c4-d8e5-4cd8-9367-341235e738f4",
            ic_lat: -147803,
            ic_lon: -572849,
            ic_city: "Nova Olímpia",
            ic_states: "MATO GROSSO",
            ic_january: 5.232,
            ic_february: 5.155,
            ic_march: 4.963,
            ic_april: 4.774,
            ic_may: 4.336,
            ic_june: 4.351,
            ic_july: 4.51,
            ic_august: 5.193,
            ic_september: 4.994,
            ic_october: 5.228,
            ic_november: 5.423,
            ic_december: 5.454,
            ic_yearly: 4.968,
          },
          {
            ic_uuid: "36ffda75-eacd-4d98-b29a-3b28b9c609a7",
            ic_lat: -234707,
            ic_lon: -530902,
            ic_city: "Nova Olímpia",
            ic_states: "PARANÁ",
            ic_january: 6.049,
            ic_february: 5.849,
            ic_march: 5.371,
            ic_april: 4.572,
            ic_may: 3.613,
            ic_june: 3.223,
            ic_july: 3.442,
            ic_august: 4.411,
            ic_september: 4.623,
            ic_october: 5.418,
            ic_november: 6.126,
            ic_december: 6.507,
            ic_yearly: 4.934,
          },
          {
            ic_uuid: "38025a70-3deb-4ae3-80ec-4ada70a23fbd",
            ic_lat: -70887,
            ic_lon: -396752,
            ic_city: "Nova Olinda",
            ic_states: "CEARÁ",
            ic_january: 5.841,
            ic_february: 5.78,
            ic_march: 5.78,
            ic_april: 5.483,
            ic_may: 5.182,
            ic_june: 5.034,
            ic_july: 5.335,
            ic_august: 6.062,
            ic_september: 6.554,
            ic_october: 6.551,
            ic_november: 6.53,
            ic_december: 6.194,
            ic_yearly: 5.861,
          },
          {
            ic_uuid: "0ecf1c39-145a-4112-a4ba-fea8791e80ba",
            ic_lat: -74784,
            ic_lon: -380399,
            ic_city: "Nova Olinda",
            ic_states: "PARAÍBA",
            ic_january: 6.252,
            ic_february: 6.179,
            ic_march: 6.251,
            ic_april: 5.888,
            ic_may: 5.179,
            ic_june: 4.757,
            ic_july: 5.001,
            ic_august: 5.862,
            ic_september: 6.534,
            ic_october: 6.608,
            ic_november: 6.782,
            ic_december: 6.428,
            ic_yearly: 5.977,
          },
          {
            ic_uuid: "f447837e-5f52-4a2f-a73d-6fb06576a436",
            ic_lat: -76321,
            ic_lon: -484256,
            ic_city: "Nova Olinda",
            ic_states: "TOCANTINS",
            ic_january: 4.663,
            ic_february: 4.818,
            ic_march: 4.713,
            ic_april: 4.753,
            ic_may: 4.924,
            ic_june: 5.175,
            ic_july: 5.227,
            ic_august: 5.932,
            ic_september: 5.709,
            ic_october: 5.02,
            ic_november: 4.687,
            ic_december: 4.612,
            ic_yearly: 5.019,
          },
          {
            ic_uuid: "f5a4e499-98d7-4e15-8f10-dbb02eb6fe25",
            ic_lat: -28063,
            ic_lon: -457024,
            ic_city: "Nova Olinda do Maranhão",
            ic_states: "MARANHÃO",
            ic_january: 4.504,
            ic_february: 4.712,
            ic_march: 4.55,
            ic_april: 4.674,
            ic_may: 4.708,
            ic_june: 4.906,
            ic_july: 4.963,
            ic_august: 5.356,
            ic_september: 5.539,
            ic_october: 5.316,
            ic_november: 5.084,
            ic_december: 4.675,
            ic_yearly: 4.916,
          },
          {
            ic_uuid: "8d194256-d519-45dc-8bda-e5c71a6fe915",
            ic_lat: -39008,
            ic_lon: -590945,
            ic_city: "Nova Olinda do Norte",
            ic_states: "AMAZONAS",
            ic_january: 3.918,
            ic_february: 4.115,
            ic_march: 4.009,
            ic_april: 3.884,
            ic_may: 3.941,
            ic_june: 4.352,
            ic_july: 4.469,
            ic_august: 5.023,
            ic_september: 5.078,
            ic_october: 4.901,
            ic_november: 4.718,
            ic_december: 4.206,
            ic_yearly: 4.385,
          },
          {
            ic_uuid: "f1686ff5-9567-426f-a5bd-683c475eaf79",
            ic_lat: -29029,
            ic_lon: -513069,
            ic_city: "Nova Pádua",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.211,
            ic_february: 5.734,
            ic_march: 4.852,
            ic_april: 3.846,
            ic_may: 2.85,
            ic_june: 2.401,
            ic_july: 2.681,
            ic_august: 3.44,
            ic_september: 3.689,
            ic_october: 4.811,
            ic_november: 6.156,
            ic_december: 6.476,
            ic_yearly: 4.429,
          },
          {
            ic_uuid: "a16b7808-7f38-4f3f-a8b7-3191538f4d01",
            ic_lat: -294715,
            ic_lon: -534694,
            ic_city: "Nova Palma",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.278,
            ic_february: 5.909,
            ic_march: 5.001,
            ic_april: 3.871,
            ic_may: 2.865,
            ic_june: 2.368,
            ic_july: 2.627,
            ic_august: 3.376,
            ic_september: 3.753,
            ic_october: 4.917,
            ic_november: 6.245,
            ic_december: 6.728,
            ic_yearly: 4.495,
          },
          {
            ic_uuid: "97b75875-eca8-4e5e-b5b0-2e99319117a6",
            ic_lat: -66789,
            ic_lon: -364198,
            ic_city: "Nova Palmeira",
            ic_states: "PARAÍBA",
            ic_january: 5.986,
            ic_february: 5.911,
            ic_march: 5.896,
            ic_april: 5.533,
            ic_may: 4.941,
            ic_june: 4.492,
            ic_july: 4.775,
            ic_august: 5.511,
            ic_september: 6.067,
            ic_october: 6.428,
            ic_november: 6.496,
            ic_december: 6.128,
            ic_yearly: 5.68,
          },
          {
            ic_uuid: "aa340837-7997-4a5b-ab80-d852d010a305",
            ic_lat: -293746,
            ic_lon: -51114,
            ic_city: "Nova Petrópolis",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.183,
            ic_february: 5.678,
            ic_march: 4.729,
            ic_april: 3.776,
            ic_may: 2.803,
            ic_june: 2.352,
            ic_july: 2.603,
            ic_august: 3.276,
            ic_september: 3.569,
            ic_october: 4.629,
            ic_november: 6.041,
            ic_december: 6.43,
            ic_yearly: 4.339,
          },
          {
            ic_uuid: "49e7006e-4af0-4a19-9924-eab4a39434ad",
            ic_lat: -191673,
            ic_lon: -47674,
            ic_city: "Nova Ponte",
            ic_states: "MINAS GERAIS",
            ic_january: 5.501,
            ic_february: 5.881,
            ic_march: 4.984,
            ic_april: 4.988,
            ic_may: 4.489,
            ic_june: 4.27,
            ic_july: 4.475,
            ic_august: 5.432,
            ic_september: 5.346,
            ic_october: 5.613,
            ic_november: 5.454,
            ic_december: 5.727,
            ic_yearly: 5.18,
          },
          {
            ic_uuid: "856d0292-38c4-4fb0-ad6b-7c4972c60371",
            ic_lat: -158003,
            ic_lon: -432981,
            ic_city: "Nova Porteirinha",
            ic_states: "MINAS GERAIS",
            ic_january: 6.551,
            ic_february: 6.702,
            ic_march: 5.841,
            ic_april: 5.493,
            ic_may: 4.868,
            ic_june: 4.569,
            ic_july: 4.81,
            ic_august: 5.533,
            ic_september: 5.994,
            ic_october: 6.171,
            ic_november: 5.805,
            ic_december: 6.352,
            ic_yearly: 5.724,
          },
          {
            ic_uuid: "05412b2d-0efa-4dda-adc4-701dc127bf75",
            ic_lat: -287804,
            ic_lon: -516117,
            ic_city: "Nova Prata",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.17,
            ic_february: 5.768,
            ic_march: 4.819,
            ic_april: 3.882,
            ic_may: 2.901,
            ic_june: 2.424,
            ic_july: 2.737,
            ic_august: 3.467,
            ic_september: 3.742,
            ic_october: 4.774,
            ic_november: 6.113,
            ic_december: 6.47,
            ic_yearly: 4.439,
          },
          {
            ic_uuid: "eaad4622-64c9-45d3-8de3-5af7258e71db",
            ic_lat: -256314,
            ic_lon: -533473,
            ic_city: "Nova Prata do Iguaçu",
            ic_states: "PARANÁ",
            ic_january: 6.355,
            ic_february: 5.808,
            ic_march: 5.275,
            ic_april: 4.254,
            ic_may: 3.277,
            ic_june: 2.843,
            ic_july: 3.081,
            ic_august: 4.074,
            ic_september: 4.232,
            ic_october: 5.275,
            ic_november: 6.153,
            ic_december: 6.488,
            ic_yearly: 4.76,
          },
          {
            ic_uuid: "79f071b0-4ec4-4d67-ad4a-113d461c788c",
            ic_lat: -280672,
            ic_lon: -536996,
            ic_city: "Nova Ramada",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.375,
            ic_february: 6.017,
            ic_march: 5.09,
            ic_april: 4.035,
            ic_may: 3.088,
            ic_june: 2.585,
            ic_july: 2.846,
            ic_august: 3.63,
            ic_september: 3.958,
            ic_october: 5.108,
            ic_november: 6.17,
            ic_december: 6.614,
            ic_yearly: 4.626,
          },
          {
            ic_uuid: "d60c453a-71d7-4180-9cd5-6c7d28933bf0",
            ic_lat: -128167,
            ic_lon: -410686,
            ic_city: "Nova Redenção",
            ic_states: "BAHIA",
            ic_january: 5.738,
            ic_february: 5.864,
            ic_march: 5.583,
            ic_april: 4.682,
            ic_may: 4.103,
            ic_june: 3.772,
            ic_july: 3.903,
            ic_august: 4.563,
            ic_september: 5.425,
            ic_october: 5.397,
            ic_november: 5.297,
            ic_december: 5.735,
            ic_yearly: 5.005,
          },
          {
            ic_uuid: "75811535-2fb1-469d-a296-aa4967e7e62b",
            ic_lat: -211291,
            ic_lon: -464161,
            ic_city: "Nova Resende",
            ic_states: "MINAS GERAIS",
            ic_january: 5.426,
            ic_february: 5.635,
            ic_march: 4.763,
            ic_april: 4.566,
            ic_may: 4.032,
            ic_june: 3.838,
            ic_july: 4.107,
            ic_august: 5.072,
            ic_september: 5.137,
            ic_october: 5.406,
            ic_november: 5.239,
            ic_december: 5.49,
            ic_yearly: 4.893,
          },
          {
            ic_uuid: "261458f2-6459-4d22-9c85-8975b4ddc898",
            ic_lat: -137409,
            ic_lon: -468859,
            ic_city: "Nova Roma",
            ic_states: "GOIÁS",
            ic_january: 5.965,
            ic_february: 5.917,
            ic_march: 5.477,
            ic_april: 5.418,
            ic_may: 5.133,
            ic_june: 5.065,
            ic_july: 5.398,
            ic_august: 5.959,
            ic_september: 6.006,
            ic_october: 5.824,
            ic_november: 5.344,
            ic_december: 5.858,
            ic_yearly: 5.614,
          },
          {
            ic_uuid: "a827c25f-bc3a-4cdb-b7fc-e4a32b1acb4d",
            ic_lat: -289887,
            ic_lon: -5141,
            ic_city: "Nova Roma do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.192,
            ic_february: 5.719,
            ic_march: 4.864,
            ic_april: 3.85,
            ic_may: 2.854,
            ic_june: 2.397,
            ic_july: 2.682,
            ic_august: 3.433,
            ic_september: 3.687,
            ic_october: 4.787,
            ic_november: 6.138,
            ic_december: 6.482,
            ic_yearly: 4.423,
          },
          {
            ic_uuid: "bb4562d8-def9-44d0-a998-6448849ae875",
            ic_lat: -105655,
            ic_lon: -489129,
            ic_city: "Nova Rosalândia",
            ic_states: "TOCANTINS",
            ic_january: 5.015,
            ic_february: 5.04,
            ic_march: 4.8,
            ic_april: 4.933,
            ic_may: 4.959,
            ic_june: 5.105,
            ic_july: 5.296,
            ic_august: 5.971,
            ic_september: 5.722,
            ic_october: 5.37,
            ic_november: 5.115,
            ic_december: 5.066,
            ic_yearly: 5.199,
          },
          {
            ic_uuid: "eb5f8d70-d4b2-4bc0-9b33-50573765b56a",
            ic_lat: -47054,
            ic_lon: -405639,
            ic_city: "Nova Russas",
            ic_states: "CEARÁ",
            ic_january: 5.111,
            ic_february: 5.355,
            ic_march: 5.452,
            ic_april: 5.051,
            ic_may: 5.107,
            ic_june: 4.968,
            ic_july: 5.306,
            ic_august: 6.033,
            ic_september: 6.528,
            ic_october: 6.429,
            ic_november: 6.242,
            ic_december: 5.553,
            ic_yearly: 5.595,
          },
          {
            ic_uuid: "55da8fdc-fa1f-444a-8a56-da1c505f24d8",
            ic_lat: -235935,
            ic_lon: -507593,
            ic_city: "Nova Santa Bárbara",
            ic_states: "PARANÁ",
            ic_january: 5.849,
            ic_february: 5.741,
            ic_march: 5.186,
            ic_april: 4.569,
            ic_may: 3.639,
            ic_june: 3.328,
            ic_july: 3.527,
            ic_august: 4.497,
            ic_september: 4.658,
            ic_october: 5.257,
            ic_november: 5.934,
            ic_december: 6.254,
            ic_yearly: 4.87,
          },
          {
            ic_uuid: "c24f88a8-9175-4ffc-bd8c-4b4f1136dc63",
            ic_lat: -10848,
            ic_lon: -551826,
            ic_city: "Nova Santa Helena",
            ic_states: "MATO GROSSO",
            ic_january: 4.985,
            ic_february: 4.908,
            ic_march: 4.744,
            ic_april: 4.694,
            ic_may: 4.73,
            ic_june: 4.69,
            ic_july: 5.009,
            ic_august: 5.508,
            ic_september: 5.116,
            ic_october: 5.127,
            ic_november: 5.054,
            ic_december: 5.047,
            ic_yearly: 4.968,
          },
          {
            ic_uuid: "b008cb34-93f3-4d5a-afde-7e5cf1c6602e",
            ic_lat: -80975,
            ic_lon: -420475,
            ic_city: "Nova Santa Rita",
            ic_states: "PIAUÍ",
            ic_january: 5.662,
            ic_february: 5.574,
            ic_march: 5.563,
            ic_april: 5.354,
            ic_may: 5.264,
            ic_june: 5.253,
            ic_july: 5.544,
            ic_august: 6.307,
            ic_september: 6.593,
            ic_october: 6.503,
            ic_november: 6.241,
            ic_december: 5.932,
            ic_yearly: 5.816,
          },
          {
            ic_uuid: "e18885bf-ff05-44a9-b416-3382e9bbba5f",
            ic_lat: -298484,
            ic_lon: -5127,
            ic_city: "Nova Santa Rita",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.316,
            ic_february: 5.841,
            ic_march: 4.881,
            ic_april: 3.809,
            ic_may: 2.795,
            ic_june: 2.337,
            ic_july: 2.543,
            ic_august: 3.198,
            ic_september: 3.559,
            ic_october: 4.69,
            ic_november: 6.173,
            ic_december: 6.634,
            ic_yearly: 4.398,
          },
          {
            ic_uuid: "997d64ee-d4d0-4851-b63a-92871ab3d305",
            ic_lat: -244698,
            ic_lon: -539557,
            ic_city: "Nova Santa Rosa",
            ic_states: "PARANÁ",
            ic_january: 6.202,
            ic_february: 5.669,
            ic_march: 5.286,
            ic_april: 4.353,
            ic_may: 3.403,
            ic_june: 3.0,
            ic_july: 3.208,
            ic_august: 4.203,
            ic_september: 4.475,
            ic_october: 5.29,
            ic_november: 6.14,
            ic_december: 6.552,
            ic_yearly: 4.815,
          },
          {
            ic_uuid: "93582a5e-cbea-46a0-9c91-830bf56f737e",
            ic_lat: -198718,
            ic_lon: -44985,
            ic_city: "Nova Serrana",
            ic_states: "MINAS GERAIS",
            ic_january: 5.799,
            ic_february: 6.032,
            ic_march: 5.161,
            ic_april: 4.888,
            ic_may: 4.309,
            ic_june: 4.104,
            ic_july: 4.346,
            ic_august: 5.241,
            ic_september: 5.476,
            ic_october: 5.6,
            ic_november: 5.331,
            ic_december: 5.642,
            ic_yearly: 5.161,
          },
          {
            ic_uuid: "061d38b6-538a-4722-9b6f-c32b08d5494a",
            ic_lat: -112379,
            ic_lon: -38478,
            ic_city: "Nova Soure",
            ic_states: "BAHIA",
            ic_january: 6.152,
            ic_february: 6.021,
            ic_march: 5.855,
            ic_april: 4.984,
            ic_may: 4.141,
            ic_june: 3.885,
            ic_july: 4.072,
            ic_august: 4.547,
            ic_september: 5.324,
            ic_october: 5.599,
            ic_november: 6.009,
            ic_december: 6.091,
            ic_yearly: 5.223,
          },
          {
            ic_uuid: "35ff70d3-14e2-4bcc-bfd9-ef503e6e14ea",
            ic_lat: -244384,
            ic_lon: -519458,
            ic_city: "Nova Tebas",
            ic_states: "PARANÁ",
            ic_january: 6.116,
            ic_february: 5.75,
            ic_march: 5.293,
            ic_april: 4.546,
            ic_may: 3.518,
            ic_june: 3.132,
            ic_july: 3.344,
            ic_august: 4.327,
            ic_september: 4.564,
            ic_october: 5.284,
            ic_november: 6.167,
            ic_december: 6.387,
            ic_yearly: 4.869,
          },
          {
            ic_uuid: "b5e4ef5b-4633-49ef-a65d-36e198fd942c",
            ic_lat: -12044,
            ic_lon: -473915,
            ic_city: "Nova Timboteua",
            ic_states: "PARÁ",
            ic_january: 4.389,
            ic_february: 4.223,
            ic_march: 4.212,
            ic_april: 4.276,
            ic_may: 4.542,
            ic_june: 4.816,
            ic_july: 4.915,
            ic_august: 5.242,
            ic_september: 5.511,
            ic_october: 5.334,
            ic_november: 5.128,
            ic_december: 4.718,
            ic_yearly: 4.775,
          },
          {
            ic_uuid: "6bac95b9-7787-4bc8-900c-b8ee317d8e89",
            ic_lat: -272785,
            ic_lon: -489303,
            ic_city: "Nova Trento",
            ic_states: "SANTA CATARINA",
            ic_january: 5.451,
            ic_february: 5.254,
            ic_march: 4.559,
            ic_april: 3.644,
            ic_may: 3.008,
            ic_june: 2.494,
            ic_july: 2.63,
            ic_august: 3.305,
            ic_september: 3.369,
            ic_october: 3.844,
            ic_november: 5.059,
            ic_december: 5.537,
            ic_yearly: 4.013,
          },
          {
            ic_uuid: "00789699-556c-41a9-9fae-f0d637f6e1b1",
            ic_lat: -130356,
            ic_lon: -552504,
            ic_city: "Nova Ubiratã",
            ic_states: "MATO GROSSO",
            ic_january: 5.158,
            ic_february: 5.134,
            ic_march: 4.991,
            ic_april: 5.012,
            ic_may: 4.771,
            ic_june: 4.56,
            ic_july: 4.842,
            ic_august: 5.302,
            ic_september: 5.154,
            ic_october: 5.279,
            ic_november: 5.222,
            ic_december: 5.294,
            ic_yearly: 5.06,
          },
          {
            ic_uuid: "d91e154c-b35f-4554-8f27-81209adf84cc",
            ic_lat: -196881,
            ic_lon: -435834,
            ic_city: "Nova União",
            ic_states: "MINAS GERAIS",
            ic_january: 5.696,
            ic_february: 5.984,
            ic_march: 5.092,
            ic_april: 4.646,
            ic_may: 4.127,
            ic_june: 3.981,
            ic_july: 4.217,
            ic_august: 5.06,
            ic_september: 5.337,
            ic_october: 5.489,
            ic_november: 5.119,
            ic_december: 5.527,
            ic_yearly: 5.023,
          },
          {
            ic_uuid: "8fcbd67e-df5a-4f1c-a801-0dd348513e71",
            ic_lat: -109122,
            ic_lon: -625562,
            ic_city: "Nova União",
            ic_states: "RONDÔNIA",
            ic_january: 4.378,
            ic_february: 4.355,
            ic_march: 4.447,
            ic_april: 4.462,
            ic_may: 4.01,
            ic_june: 4.326,
            ic_july: 4.446,
            ic_august: 4.887,
            ic_september: 4.934,
            ic_october: 5.129,
            ic_november: 4.96,
            ic_december: 4.638,
            ic_yearly: 4.581,
          },
          {
            ic_uuid: "4e93dd5a-5126-4e7a-8600-c61d86db30ec",
            ic_lat: -187155,
            ic_lon: -404056,
            ic_city: "Nova Venécia",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.07,
            ic_february: 6.107,
            ic_march: 5.286,
            ic_april: 4.571,
            ic_may: 3.854,
            ic_june: 3.525,
            ic_july: 3.623,
            ic_august: 4.226,
            ic_september: 4.768,
            ic_october: 4.93,
            ic_november: 4.935,
            ic_december: 5.767,
            ic_yearly: 4.805,
          },
          {
            ic_uuid: "aef0543f-5ac3-4f38-9559-298fb770dd45",
            ic_lat: -163718,
            ic_lon: -493196,
            ic_city: "Nova Veneza",
            ic_states: "GOIÁS",
            ic_january: 5.47,
            ic_february: 5.56,
            ic_march: 5.185,
            ic_april: 5.118,
            ic_may: 4.872,
            ic_june: 4.584,
            ic_july: 4.823,
            ic_august: 5.767,
            ic_september: 5.532,
            ic_october: 5.55,
            ic_november: 5.297,
            ic_december: 5.471,
            ic_yearly: 5.269,
          },
          {
            ic_uuid: "7660853a-ba78-4b7c-9409-8f1e9a12c9c2",
            ic_lat: -286343,
            ic_lon: -49506,
            ic_city: "Nova Veneza",
            ic_states: "SANTA CATARINA",
            ic_january: 5.073,
            ic_february: 4.836,
            ic_march: 4.205,
            ic_april: 3.599,
            ic_may: 2.885,
            ic_june: 2.471,
            ic_july: 2.681,
            ic_august: 3.386,
            ic_september: 3.381,
            ic_october: 4.033,
            ic_november: 5.262,
            ic_december: 5.525,
            ic_yearly: 3.945,
          },
          {
            ic_uuid: "5aeae517-8555-4c04-ba91-8f051a87f3ce",
            ic_lat: -178931,
            ic_lon: -393747,
            ic_city: "Nova Viçosa",
            ic_states: "BAHIA",
            ic_january: 6.215,
            ic_february: 6.298,
            ic_march: 5.517,
            ic_april: 4.635,
            ic_may: 3.964,
            ic_june: 3.68,
            ic_july: 3.815,
            ic_august: 4.561,
            ic_september: 5.075,
            ic_october: 5.397,
            ic_november: 5.404,
            ic_december: 6.228,
            ic_yearly: 5.066,
          },
          {
            ic_uuid: "7259beb0-61ac-409e-b24e-da7c1f230444",
            ic_lat: -146746,
            ic_lon: -523525,
            ic_city: "Nova Xavantina",
            ic_states: "MATO GROSSO",
            ic_january: 5.485,
            ic_february: 5.634,
            ic_march: 5.326,
            ic_april: 5.308,
            ic_may: 4.955,
            ic_june: 4.642,
            ic_july: 4.903,
            ic_august: 5.488,
            ic_september: 5.478,
            ic_october: 5.526,
            ic_november: 5.422,
            ic_december: 5.509,
            ic_yearly: 5.306,
          },
          {
            ic_uuid: "d4e037d7-b27c-4b26-a570-b2f8c86336dc",
            ic_lat: -20992,
            ic_lon: -489189,
            ic_city: "Novais",
            ic_states: "SÃO PAULO",
            ic_january: 5.834,
            ic_february: 5.879,
            ic_march: 5.197,
            ic_april: 4.801,
            ic_may: 4.103,
            ic_june: 3.896,
            ic_july: 4.124,
            ic_august: 5.014,
            ic_september: 5.098,
            ic_october: 5.587,
            ic_november: 5.912,
            ic_december: 6.121,
            ic_yearly: 5.131,
          },
          {
            ic_uuid: "58cbab55-d6ec-44ae-af7d-eae68b6770bf",
            ic_lat: -99711,
            ic_lon: -476789,
            ic_city: "Novo Acordo",
            ic_states: "TOCANTINS",
            ic_january: 5.312,
            ic_february: 5.23,
            ic_march: 5.007,
            ic_april: 5.087,
            ic_may: 5.159,
            ic_june: 5.127,
            ic_july: 5.403,
            ic_august: 6.17,
            ic_september: 5.968,
            ic_october: 5.525,
            ic_november: 5.269,
            ic_december: 5.334,
            ic_yearly: 5.383,
          },
          {
            ic_uuid: "34c5f7db-ab2a-411f-abfe-339bf3d05dfb",
            ic_lat: -26368,
            ic_lon: -609438,
            ic_city: "Novo Airão",
            ic_states: "AMAZONAS",
            ic_january: 4.179,
            ic_february: 4.304,
            ic_march: 4.249,
            ic_april: 4.041,
            ic_may: 4.055,
            ic_june: 4.508,
            ic_july: 4.591,
            ic_august: 5.117,
            ic_september: 5.14,
            ic_october: 5.047,
            ic_november: 4.821,
            ic_december: 4.386,
            ic_yearly: 4.536,
          },
          {
            ic_uuid: "7e2c9c48-ca68-468d-aae1-96714d1af3b1",
            ic_lat: -129151,
            ic_lon: -465772,
            ic_city: "Novo Alegre",
            ic_states: "TOCANTINS",
            ic_january: 5.921,
            ic_february: 5.761,
            ic_march: 5.429,
            ic_april: 5.366,
            ic_may: 5.2,
            ic_june: 5.002,
            ic_july: 5.326,
            ic_august: 5.854,
            ic_september: 5.875,
            ic_october: 5.749,
            ic_november: 5.474,
            ic_december: 5.878,
            ic_yearly: 5.569,
          },
          {
            ic_uuid: "7ebe0281-fdf3-44e8-ac3a-1ce04aa1b236",
            ic_lat: -51263,
            ic_lon: -603736,
            ic_city: "Novo Aripuanã",
            ic_states: "AMAZONAS",
            ic_january: 4.126,
            ic_february: 4.294,
            ic_march: 4.268,
            ic_april: 4.027,
            ic_may: 3.946,
            ic_june: 4.37,
            ic_july: 4.473,
            ic_august: 5.086,
            ic_september: 5.046,
            ic_october: 4.891,
            ic_november: 4.7,
            ic_december: 4.32,
            ic_yearly: 4.462,
          },
          {
            ic_uuid: "07d15796-e410-47f9-95a0-a2dc5778ce66",
            ic_lat: -279082,
            ic_lon: -531107,
            ic_city: "Novo Barreiro",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.367,
            ic_february: 5.928,
            ic_march: 5.048,
            ic_april: 4.085,
            ic_may: 3.099,
            ic_june: 2.567,
            ic_july: 2.899,
            ic_august: 3.653,
            ic_september: 3.967,
            ic_october: 5.126,
            ic_november: 6.241,
            ic_december: 6.633,
            ic_yearly: 4.634,
          },
          {
            ic_uuid: "fc731f0e-7ab0-4b1b-816b-1974ca1007b5",
            ic_lat: -160318,
            ic_lon: -507117,
            ic_city: "Novo Brasil",
            ic_states: "GOIÁS",
            ic_january: 5.534,
            ic_february: 5.714,
            ic_march: 5.375,
            ic_april: 5.357,
            ic_may: 4.94,
            ic_june: 4.592,
            ic_july: 4.721,
            ic_august: 5.392,
            ic_september: 5.435,
            ic_october: 5.656,
            ic_november: 5.498,
            ic_december: 5.513,
            ic_yearly: 5.311,
          },
          {
            ic_uuid: "7cf587a0-032f-4b7c-911b-f63a59c12df0",
            ic_lat: -297342,
            ic_lon: -529494,
            ic_city: "Novo Cabrais",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.188,
            ic_february: 5.781,
            ic_march: 4.901,
            ic_april: 3.814,
            ic_may: 2.785,
            ic_june: 2.327,
            ic_july: 2.547,
            ic_august: 3.266,
            ic_september: 3.629,
            ic_october: 4.765,
            ic_november: 6.097,
            ic_december: 6.597,
            ic_yearly: 4.391,
          },
          {
            ic_uuid: "b000d540-5492-468a-b00a-86efc378ea5a",
            ic_lat: -174659,
            ic_lon: -41883,
            ic_city: "Novo Cruzeiro",
            ic_states: "MINAS GERAIS",
            ic_january: 5.803,
            ic_february: 6.094,
            ic_march: 5.098,
            ic_april: 4.519,
            ic_may: 3.851,
            ic_june: 3.612,
            ic_july: 3.812,
            ic_august: 4.523,
            ic_september: 5.07,
            ic_october: 5.333,
            ic_november: 4.918,
            ic_december: 5.714,
            ic_yearly: 4.862,
          },
          {
            ic_uuid: "a5b2e93f-e4b0-4a31-809e-1e7d688489d6",
            ic_lat: -160533,
            ic_lon: -480319,
            ic_city: "Novo Gama",
            ic_states: "GOIÁS",
            ic_january: 5.499,
            ic_february: 5.697,
            ic_march: 5.116,
            ic_april: 5.082,
            ic_may: 4.834,
            ic_june: 4.742,
            ic_july: 4.966,
            ic_august: 5.83,
            ic_september: 5.619,
            ic_october: 5.543,
            ic_november: 5.156,
            ic_december: 5.424,
            ic_yearly: 5.292,
          },
          {
            ic_uuid: "ad4367b3-d026-40fa-beee-877acb19f96b",
            ic_lat: -29688,
            ic_lon: -511333,
            ic_city: "Novo Hamburgo",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.243,
            ic_february: 5.746,
            ic_march: 4.782,
            ic_april: 3.779,
            ic_may: 2.827,
            ic_june: 2.32,
            ic_july: 2.526,
            ic_august: 3.201,
            ic_september: 3.557,
            ic_october: 4.629,
            ic_november: 6.079,
            ic_december: 6.567,
            ic_yearly: 4.354,
          },
          {
            ic_uuid: "c078a0b6-84c6-425f-81d8-0784782b50b8",
            ic_lat: -128088,
            ic_lon: -421685,
            ic_city: "Novo Horizonte",
            ic_states: "BAHIA",
            ic_january: 6.339,
            ic_february: 6.297,
            ic_march: 5.872,
            ic_april: 5.274,
            ic_may: 4.796,
            ic_june: 4.645,
            ic_july: 4.891,
            ic_august: 5.657,
            ic_september: 6.294,
            ic_october: 6.382,
            ic_november: 5.929,
            ic_december: 6.486,
            ic_yearly: 5.739,
          },
          {
            ic_uuid: "da0ee123-6a7b-47f7-9eda-ca47381b29a7",
            ic_lat: -264447,
            ic_lon: -528286,
            ic_city: "Novo Horizonte",
            ic_states: "SANTA CATARINA",
            ic_january: 6.132,
            ic_february: 5.662,
            ic_march: 5.072,
            ic_april: 4.137,
            ic_may: 3.228,
            ic_june: 2.769,
            ic_july: 3.071,
            ic_august: 3.987,
            ic_september: 4.196,
            ic_october: 5.11,
            ic_november: 6.045,
            ic_december: 6.268,
            ic_yearly: 4.64,
          },
          {
            ic_uuid: "d0466917-3964-403c-9c52-4c5efddd6c13",
            ic_lat: -214656,
            ic_lon: -492238,
            ic_city: "Novo Horizonte",
            ic_states: "SÃO PAULO",
            ic_january: 5.889,
            ic_february: 5.961,
            ic_march: 5.298,
            ic_april: 4.802,
            ic_may: 4.008,
            ic_june: 3.735,
            ic_july: 3.989,
            ic_august: 4.874,
            ic_september: 4.965,
            ic_october: 5.622,
            ic_november: 5.934,
            ic_december: 6.201,
            ic_yearly: 5.107,
          },
          {
            ic_uuid: "6fb1db92-290f-40c6-8457-c17b964fe2c9",
            ic_lat: -113902,
            ic_lon: -573177,
            ic_city: "Novo Horizonte do Norte",
            ic_states: "MATO GROSSO",
            ic_january: 4.917,
            ic_february: 4.793,
            ic_march: 4.703,
            ic_april: 4.622,
            ic_may: 4.522,
            ic_june: 4.611,
            ic_july: 4.881,
            ic_august: 5.207,
            ic_september: 5.057,
            ic_october: 5.218,
            ic_november: 5.01,
            ic_december: 5.036,
            ic_yearly: 4.881,
          },
          {
            ic_uuid: "1c57b3d7-42bd-49fe-bbb6-2106e5f2ea04",
            ic_lat: -117091,
            ic_lon: -619993,
            ic_city: "Novo Horizonte do Oeste",
            ic_states: "RONDÔNIA",
            ic_january: 4.502,
            ic_february: 4.408,
            ic_march: 4.552,
            ic_april: 4.558,
            ic_may: 4.109,
            ic_june: 4.386,
            ic_july: 4.455,
            ic_august: 4.97,
            ic_september: 4.972,
            ic_october: 5.14,
            ic_november: 5.021,
            ic_december: 4.734,
            ic_yearly: 4.651,
          },
          {
            ic_uuid: "f6cf2627-61df-4c1a-a05a-03b9a2155d73",
            ic_lat: -226557,
            ic_lon: -538577,
            ic_city: "Novo Horizonte do Sul",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.902,
            ic_february: 5.84,
            ic_march: 5.361,
            ic_april: 4.568,
            ic_may: 3.683,
            ic_june: 3.325,
            ic_july: 3.516,
            ic_august: 4.465,
            ic_september: 4.717,
            ic_october: 5.416,
            ic_november: 6.061,
            ic_december: 6.453,
            ic_yearly: 4.942,
          },
          {
            ic_uuid: "56be4b59-a822-4a9e-9b4a-b9cee8fd761e",
            ic_lat: -237635,
            ic_lon: -515083,
            ic_city: "Novo Itacolomi",
            ic_states: "PARANÁ",
            ic_january: 5.827,
            ic_february: 5.73,
            ic_march: 5.264,
            ic_april: 4.566,
            ic_may: 3.565,
            ic_june: 3.248,
            ic_july: 3.46,
            ic_august: 4.467,
            ic_september: 4.606,
            ic_october: 5.26,
            ic_november: 5.987,
            ic_december: 6.24,
            ic_yearly: 4.852,
          },
          {
            ic_uuid: "1681a9d0-47e3-4e50-8960-cc02ac801751",
            ic_lat: -118205,
            ic_lon: -46625,
            ic_city: "Novo Jardim",
            ic_states: "TOCANTINS",
            ic_january: 5.6,
            ic_february: 5.429,
            ic_march: 5.107,
            ic_april: 5.15,
            ic_may: 5.221,
            ic_june: 5.182,
            ic_july: 5.425,
            ic_august: 6.12,
            ic_september: 6.199,
            ic_october: 5.712,
            ic_november: 5.356,
            ic_december: 5.483,
            ic_yearly: 5.499,
          },
          {
            ic_uuid: "01534e35-b5a0-46cb-8e4c-5bfb4e8ae89b",
            ic_lat: -89423,
            ic_lon: -356643,
            ic_city: "Novo Lino",
            ic_states: "ALAGOAS",
            ic_january: 5.661,
            ic_february: 5.635,
            ic_march: 5.639,
            ic_april: 4.984,
            ic_may: 4.197,
            ic_june: 3.88,
            ic_july: 3.912,
            ic_august: 4.533,
            ic_september: 5.267,
            ic_october: 5.513,
            ic_november: 5.928,
            ic_december: 5.837,
            ic_yearly: 5.082,
          },
          {
            ic_uuid: "78fcc34f-65f9-431c-bd49-d02d58c03fc8",
            ic_lat: -275769,
            ic_lon: -545041,
            ic_city: "Novo Machado",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.504,
            ic_february: 5.988,
            ic_march: 5.161,
            ic_april: 4.04,
            ic_may: 3.076,
            ic_june: 2.52,
            ic_july: 2.818,
            ic_august: 3.64,
            ic_september: 4.016,
            ic_october: 5.176,
            ic_november: 6.219,
            ic_december: 6.672,
            ic_yearly: 4.653,
          },
          {
            ic_uuid: "ea65b568-3187-4509-b97e-d3811ec234e6",
            ic_lat: -99746,
            ic_lon: -551791,
            ic_city: "Novo Mundo",
            ic_states: "MATO GROSSO",
            ic_january: 4.841,
            ic_february: 4.782,
            ic_march: 4.747,
            ic_april: 4.631,
            ic_may: 4.676,
            ic_june: 4.618,
            ic_july: 4.872,
            ic_august: 5.442,
            ic_september: 5.077,
            ic_october: 5.164,
            ic_november: 4.979,
            ic_december: 4.951,
            ic_yearly: 4.898,
          },
          {
            ic_uuid: "9cccdebf-53bd-4f8d-a22d-994c4ab49924",
            ic_lat: -55259,
            ic_lon: -407716,
            ic_city: "Novo Oriente",
            ic_states: "CEARÁ",
            ic_january: 5.082,
            ic_february: 5.235,
            ic_march: 5.372,
            ic_april: 5.035,
            ic_may: 4.927,
            ic_june: 5.018,
            ic_july: 5.378,
            ic_august: 6.127,
            ic_september: 6.476,
            ic_october: 6.34,
            ic_november: 6.194,
            ic_december: 5.58,
            ic_yearly: 5.564,
          },
          {
            ic_uuid: "6570d56e-4634-4de6-bb52-77be925c6e42",
            ic_lat: -174094,
            ic_lon: -412197,
            ic_city: "Novo Oriente de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 6.236,
            ic_february: 6.467,
            ic_march: 5.46,
            ic_april: 4.693,
            ic_may: 3.933,
            ic_june: 3.51,
            ic_july: 3.705,
            ic_august: 4.451,
            ic_september: 4.99,
            ic_october: 5.292,
            ic_november: 5.139,
            ic_december: 6.039,
            ic_yearly: 4.993,
          },
          {
            ic_uuid: "0452590e-faf4-4715-915d-d70058768c32",
            ic_lat: -64494,
            ic_lon: -419265,
            ic_city: "Novo Oriente do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.268,
            ic_february: 5.306,
            ic_march: 5.305,
            ic_april: 5.23,
            ic_may: 5.255,
            ic_june: 5.277,
            ic_july: 5.594,
            ic_august: 6.179,
            ic_september: 6.562,
            ic_october: 6.35,
            ic_november: 6.204,
            ic_december: 5.764,
            ic_yearly: 5.691,
          },
          {
            ic_uuid: "b7064a05-cd25-4688-97d4-de2f7f01b2b0",
            ic_lat: -132428,
            ic_lon: -495064,
            ic_city: "Novo Planalto",
            ic_states: "GOIÁS",
            ic_january: 5.342,
            ic_february: 5.441,
            ic_march: 5.117,
            ic_april: 5.171,
            ic_may: 4.993,
            ic_june: 4.836,
            ic_july: 5.172,
            ic_august: 5.865,
            ic_september: 5.693,
            ic_october: 5.571,
            ic_november: 5.195,
            ic_december: 5.421,
            ic_yearly: 5.318,
          },
          {
            ic_uuid: "85ab3df8-b86d-46ae-ab60-f4c22c5cd016",
            ic_lat: -71439,
            ic_lon: -55379,
            ic_city: "Novo Progresso",
            ic_states: "PARÁ",
            ic_january: 4.34,
            ic_february: 4.33,
            ic_march: 4.302,
            ic_april: 4.183,
            ic_may: 4.343,
            ic_june: 4.665,
            ic_july: 4.664,
            ic_august: 5.224,
            ic_september: 4.927,
            ic_october: 4.918,
            ic_november: 4.728,
            ic_december: 4.567,
            ic_yearly: 4.6,
          },
          {
            ic_uuid: "dd3584af-3c2c-4c1f-aaca-0fadc87b131d",
            ic_lat: -42479,
            ic_lon: -499503,
            ic_city: "Novo Repartimento",
            ic_states: "PARÁ",
            ic_january: 4.253,
            ic_february: 4.535,
            ic_march: 4.634,
            ic_april: 4.616,
            ic_may: 4.686,
            ic_june: 4.91,
            ic_july: 4.999,
            ic_august: 5.198,
            ic_september: 4.951,
            ic_october: 4.572,
            ic_november: 4.512,
            ic_december: 4.273,
            ic_yearly: 4.678,
          },
          {
            ic_uuid: "30809f2d-0ba1-4195-96c5-6d68eec1b6d5",
            ic_lat: -122915,
            ic_lon: -509683,
            ic_city: "Novo Santo Antônio",
            ic_states: "MATO GROSSO",
            ic_january: 5.163,
            ic_february: 5.398,
            ic_march: 5.187,
            ic_april: 5.09,
            ic_may: 5.043,
            ic_june: 4.778,
            ic_july: 5.234,
            ic_august: 5.851,
            ic_september: 5.58,
            ic_october: 5.399,
            ic_november: 5.245,
            ic_december: 5.288,
            ic_yearly: 5.272,
          },
          {
            ic_uuid: "a86edb06-1b2d-400c-b831-5ec631551df8",
            ic_lat: -52879,
            ic_lon: -419329,
            ic_city: "Novo Santo Antônio",
            ic_states: "PIAUÍ",
            ic_january: 5.174,
            ic_february: 5.355,
            ic_march: 5.443,
            ic_april: 5.213,
            ic_may: 5.16,
            ic_june: 5.219,
            ic_july: 5.532,
            ic_august: 6.149,
            ic_september: 6.549,
            ic_october: 6.416,
            ic_november: 6.275,
            ic_december: 5.787,
            ic_yearly: 5.689,
          },
          {
            ic_uuid: "e9b8e31a-8721-4c02-8333-82556808a815",
            ic_lat: -149112,
            ic_lon: -530274,
            ic_city: "Novo São Joaquim",
            ic_states: "MATO GROSSO",
            ic_january: 5.455,
            ic_february: 5.531,
            ic_march: 5.29,
            ic_april: 5.397,
            ic_may: 4.978,
            ic_june: 4.689,
            ic_july: 4.943,
            ic_august: 5.481,
            ic_september: 5.36,
            ic_october: 5.444,
            ic_november: 5.338,
            ic_december: 5.511,
            ic_yearly: 5.285,
          },
          {
            ic_uuid: "8d6039aa-acbc-4e3e-ba4c-acd5d03a8da1",
            ic_lat: -275654,
            ic_lon: -531842,
            ic_city: "Novo Tiradentes",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.315,
            ic_february: 5.918,
            ic_march: 5.111,
            ic_april: 4.125,
            ic_may: 3.106,
            ic_june: 2.544,
            ic_july: 2.895,
            ic_august: 3.705,
            ic_september: 3.981,
            ic_october: 5.177,
            ic_november: 6.305,
            ic_december: 6.664,
            ic_yearly: 4.654,
          },
          {
            ic_uuid: "6aefe6ec-3d58-46bc-abb5-4ee14e03b7c5",
            ic_lat: -103346,
            ic_lon: -38421,
            ic_city: "Novo Triunfo",
            ic_states: "BAHIA",
            ic_january: 6.075,
            ic_february: 5.897,
            ic_march: 5.814,
            ic_april: 5.024,
            ic_may: 4.221,
            ic_june: 3.825,
            ic_july: 3.952,
            ic_august: 4.528,
            ic_september: 5.4,
            ic_october: 5.632,
            ic_november: 6.133,
            ic_december: 6.22,
            ic_yearly: 5.227,
          },
          {
            ic_uuid: "b78becda-e503-4df0-aec6-994108e88526",
            ic_lat: -277495,
            ic_lon: -530644,
            ic_city: "Novo Xingu",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.335,
            ic_february: 5.924,
            ic_march: 5.133,
            ic_april: 4.115,
            ic_may: 3.113,
            ic_june: 2.57,
            ic_july: 2.895,
            ic_august: 3.669,
            ic_september: 3.957,
            ic_october: 5.16,
            ic_november: 6.267,
            ic_december: 6.701,
            ic_yearly: 4.653,
          },
          {
            ic_uuid: "a135f1eb-e6d2-4151-90ca-e2ae2eb0d1fc",
            ic_lat: -160167,
            ic_lon: -424048,
            ic_city: "Novorizonte",
            ic_states: "MINAS GERAIS",
            ic_january: 6.152,
            ic_february: 6.308,
            ic_march: 5.426,
            ic_april: 4.797,
            ic_may: 4.166,
            ic_june: 3.863,
            ic_july: 4.195,
            ic_august: 4.969,
            ic_september: 5.653,
            ic_october: 5.751,
            ic_november: 5.344,
            ic_december: 6.098,
            ic_yearly: 5.227,
          },
          {
            ic_uuid: "9daa0439-4d40-43af-8146-aba5a49c576a",
            ic_lat: -207301,
            ic_lon: -477433,
            ic_city: "Nuporanga",
            ic_states: "SÃO PAULO",
            ic_january: 5.678,
            ic_february: 5.931,
            ic_march: 5.056,
            ic_april: 4.909,
            ic_may: 4.228,
            ic_june: 4.013,
            ic_july: 4.186,
            ic_august: 5.112,
            ic_september: 5.073,
            ic_october: 5.545,
            ic_november: 5.717,
            ic_december: 5.967,
            ic_yearly: 5.118,
          },
          {
            ic_uuid: "86ac1640-6cc5-421d-aed3-01c39069c0e9",
            ic_lat: -19015,
            ic_lon: -555212,
            ic_city: "Óbidos",
            ic_states: "PARÁ",
            ic_january: 4.331,
            ic_february: 4.449,
            ic_march: 4.398,
            ic_april: 4.295,
            ic_may: 4.365,
            ic_june: 4.806,
            ic_july: 4.715,
            ic_august: 5.311,
            ic_september: 5.253,
            ic_october: 5.366,
            ic_november: 5.151,
            ic_december: 4.652,
            ic_yearly: 4.758,
          },
          {
            ic_uuid: "1e3fd058-076f-4f5e-a658-def21552f24a",
            ic_lat: -44856,
            ic_lon: -385936,
            ic_city: "Ocara",
            ic_states: "CEARÁ",
            ic_january: 5.486,
            ic_february: 5.557,
            ic_march: 5.589,
            ic_april: 5.137,
            ic_may: 5.241,
            ic_june: 5.074,
            ic_july: 5.43,
            ic_august: 6.035,
            ic_september: 6.357,
            ic_october: 6.331,
            ic_november: 6.145,
            ic_december: 5.687,
            ic_yearly: 5.672,
          },
          {
            ic_uuid: "8aad2b73-d58e-489e-b930-ece4fa164f9f",
            ic_lat: -224384,
            ic_lon: -499224,
            ic_city: "Ocauçu",
            ic_states: "SÃO PAULO",
            ic_january: 5.664,
            ic_february: 5.845,
            ic_march: 5.297,
            ic_april: 4.754,
            ic_may: 3.846,
            ic_june: 3.541,
            ic_july: 3.765,
            ic_august: 4.752,
            ic_september: 4.827,
            ic_october: 5.492,
            ic_november: 5.92,
            ic_december: 6.251,
            ic_yearly: 4.996,
          },
          {
            ic_uuid: "45e6229d-4b9c-42fc-9136-8f9ab592ff2a",
            ic_lat: -70196,
            ic_lon: -421286,
            ic_city: "Oeiras",
            ic_states: "PIAUÍ",
            ic_january: 5.449,
            ic_february: 5.394,
            ic_march: 5.401,
            ic_april: 5.369,
            ic_may: 5.348,
            ic_june: 5.278,
            ic_july: 5.641,
            ic_august: 6.142,
            ic_september: 6.64,
            ic_october: 6.5,
            ic_november: 6.285,
            ic_december: 5.88,
            ic_yearly: 5.777,
          },
          {
            ic_uuid: "e528cf9b-7885-4932-b1ee-5c4d359280de",
            ic_lat: -2004,
            ic_lon: -498632,
            ic_city: "Oeiras do Pará",
            ic_states: "PARÁ",
            ic_january: 4.622,
            ic_february: 4.568,
            ic_march: 4.61,
            ic_april: 4.646,
            ic_may: 4.681,
            ic_june: 4.792,
            ic_july: 4.887,
            ic_august: 5.222,
            ic_september: 5.321,
            ic_october: 5.273,
            ic_november: 5.098,
            ic_december: 4.774,
            ic_yearly: 4.875,
          },
          {
            ic_uuid: "8e10e189-23ed-431a-b58f-e72a540987fe",
            ic_lat: 38404,
            ic_lon: -518335,
            ic_city: "Oiapoque",
            ic_states: "AMAPÁ",
            ic_january: 4.014,
            ic_february: 4.039,
            ic_march: 3.957,
            ic_april: 4.447,
            ic_may: 4.39,
            ic_june: 4.657,
            ic_july: 5.002,
            ic_august: 5.423,
            ic_september: 5.727,
            ic_october: 5.432,
            ic_november: 5.112,
            ic_december: 4.254,
            ic_yearly: 4.705,
          },
          {
            ic_uuid: "e235377c-41c2-4c48-a119-9119744aabd7",
            ic_lat: -218603,
            ic_lon: -439359,
            ic_city: "Olaria",
            ic_states: "MINAS GERAIS",
            ic_january: 5.346,
            ic_february: 5.683,
            ic_march: 4.747,
            ic_april: 4.248,
            ic_may: 3.634,
            ic_june: 3.468,
            ic_july: 3.594,
            ic_august: 4.486,
            ic_september: 4.676,
            ic_october: 4.819,
            ic_november: 4.743,
            ic_december: 5.341,
            ic_yearly: 4.565,
          },
          {
            ic_uuid: "e2753965-0eec-4a37-9743-414047c28b7c",
            ic_lat: -229439,
            ic_lon: -493423,
            ic_city: "Óleo",
            ic_states: "SÃO PAULO",
            ic_january: 5.565,
            ic_february: 5.753,
            ic_march: 5.146,
            ic_april: 4.645,
            ic_may: 3.728,
            ic_june: 3.446,
            ic_july: 3.641,
            ic_august: 4.631,
            ic_september: 4.695,
            ic_october: 5.384,
            ic_november: 5.85,
            ic_december: 6.208,
            ic_yearly: 4.891,
          },
          {
            ic_uuid: "f2d21c33-4735-49c6-9013-b3018b8de641",
            ic_lat: -72286,
            ic_lon: -377466,
            ic_city: "Olho d'Água",
            ic_states: "PARAÍBA",
            ic_january: 6.257,
            ic_february: 6.168,
            ic_march: 6.272,
            ic_april: 5.926,
            ic_may: 5.184,
            ic_june: 4.814,
            ic_july: 5.047,
            ic_august: 5.846,
            ic_september: 6.495,
            ic_october: 6.638,
            ic_november: 6.777,
            ic_december: 6.456,
            ic_yearly: 5.99,
          },
          {
            ic_uuid: "45feb469-1fc8-4393-8a6d-442567ad3cc0",
            ic_lat: -41376,
            ic_lon: -451191,
            ic_city: "Olho d'Água das Cunhãs",
            ic_states: "MARANHÃO",
            ic_january: 4.692,
            ic_february: 4.893,
            ic_march: 4.962,
            ic_april: 4.883,
            ic_may: 4.79,
            ic_june: 4.94,
            ic_july: 5.14,
            ic_august: 5.606,
            ic_september: 5.902,
            ic_october: 5.498,
            ic_november: 5.188,
            ic_december: 4.923,
            ic_yearly: 5.118,
          },
          {
            ic_uuid: "6bc263d0-3ef7-43f3-951b-88840c5ca7db",
            ic_lat: -95335,
            ic_lon: -372958,
            ic_city: "Olho d'Água das Flores",
            ic_states: "ALAGOAS",
            ic_january: 6.141,
            ic_february: 5.938,
            ic_march: 6.01,
            ic_april: 5.27,
            ic_may: 4.375,
            ic_june: 3.992,
            ic_july: 4.108,
            ic_august: 4.728,
            ic_september: 5.572,
            ic_october: 5.955,
            ic_november: 6.542,
            ic_december: 6.347,
            ic_yearly: 5.415,
          },
          {
            ic_uuid: "4dd77c9e-f12d-4d27-8cd3-e087c13b070a",
            ic_lat: -95037,
            ic_lon: -37827,
            ic_city: "Olho d'Água do Casado",
            ic_states: "ALAGOAS",
            ic_january: 6.316,
            ic_february: 6.084,
            ic_march: 5.978,
            ic_april: 5.329,
            ic_may: 4.468,
            ic_june: 4.079,
            ic_july: 4.194,
            ic_august: 4.844,
            ic_september: 5.684,
            ic_october: 5.958,
            ic_november: 6.486,
            ic_december: 6.385,
            ic_yearly: 5.484,
          },
          {
            ic_uuid: "75e7522b-b48d-4265-8afc-094eae649da3",
            ic_lat: -5848,
            ic_lon: -425749,
            ic_city: "Olho d'Água do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 4.975,
            ic_february: 5.206,
            ic_march: 5.193,
            ic_april: 5.107,
            ic_may: 5.219,
            ic_june: 5.403,
            ic_july: 5.673,
            ic_august: 6.237,
            ic_september: 6.627,
            ic_october: 6.396,
            ic_november: 6.07,
            ic_december: 5.595,
            ic_yearly: 5.642,
          },
          {
            ic_uuid: "906e7339-d70a-4f54-bcda-8812864b7e7e",
            ic_lat: -100571,
            ic_lon: -368133,
            ic_city: "Olho d'Água Grande",
            ic_states: "ALAGOAS",
            ic_january: 6.069,
            ic_february: 5.904,
            ic_march: 5.799,
            ic_april: 5.144,
            ic_may: 4.314,
            ic_june: 4.098,
            ic_july: 4.159,
            ic_august: 4.736,
            ic_september: 5.465,
            ic_october: 5.763,
            ic_november: 6.206,
            ic_december: 6.201,
            ic_yearly: 5.321,
          },
          {
            ic_uuid: "39396076-c310-4566-8fde-443d2d1d5c72",
            ic_lat: -5949,
            ic_lon: -37705,
            ic_city: "Olho-d'Água do Borges",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.984,
            ic_february: 6.04,
            ic_march: 6.076,
            ic_april: 5.758,
            ic_may: 5.371,
            ic_june: 5.032,
            ic_july: 5.377,
            ic_august: 6.05,
            ic_september: 6.437,
            ic_october: 6.62,
            ic_november: 6.613,
            ic_december: 6.14,
            ic_yearly: 5.958,
          },
          {
            ic_uuid: "d895907a-6847-49a2-a6d3-aeab5561ae0d",
            ic_lat: -173964,
            ic_lon: -435708,
            ic_city: "Olhos-d'Água",
            ic_states: "MINAS GERAIS",
            ic_january: 6.067,
            ic_february: 6.377,
            ic_march: 5.37,
            ic_april: 4.883,
            ic_may: 4.433,
            ic_june: 4.253,
            ic_july: 4.446,
            ic_august: 5.341,
            ic_september: 5.796,
            ic_october: 5.764,
            ic_november: 5.246,
            ic_december: 5.938,
            ic_yearly: 5.326,
          },
          {
            ic_uuid: "06952a91-09c4-4466-9b01-897475239712",
            ic_lat: -207371,
            ic_lon: -48911,
            ic_city: "Olímpia",
            ic_states: "SÃO PAULO",
            ic_january: 5.786,
            ic_february: 5.989,
            ic_march: 5.227,
            ic_april: 4.854,
            ic_may: 4.214,
            ic_june: 3.947,
            ic_july: 4.163,
            ic_august: 5.087,
            ic_september: 5.065,
            ic_october: 5.588,
            ic_november: 5.887,
            ic_december: 6.124,
            ic_yearly: 5.161,
          },
          {
            ic_uuid: "6b04a956-945c-4f5b-be3d-b04c9fa5855c",
            ic_lat: -22069,
            ic_lon: -452661,
            ic_city: "Olímpio Noronha",
            ic_states: "MINAS GERAIS",
            ic_january: 5.338,
            ic_february: 5.664,
            ic_march: 4.833,
            ic_april: 4.536,
            ic_may: 3.875,
            ic_june: 3.605,
            ic_july: 3.853,
            ic_august: 4.732,
            ic_september: 4.973,
            ic_october: 5.34,
            ic_november: 5.216,
            ic_december: 5.575,
            ic_yearly: 4.795,
          },
          {
            ic_uuid: "369ce129-6d0c-463d-8ebb-fd7fd2ce5724",
            ic_lat: -80106,
            ic_lon: -348548,
            ic_city: "Olinda",
            ic_states: "PERNAMBUCO",
            ic_january: 5.886,
            ic_february: 6.014,
            ic_march: 5.93,
            ic_april: 5.251,
            ic_may: 4.538,
            ic_june: 4.246,
            ic_july: 4.397,
            ic_august: 5.169,
            ic_september: 5.659,
            ic_october: 6.044,
            ic_november: 6.199,
            ic_december: 6.204,
            ic_yearly: 5.462,
          },
          {
            ic_uuid: "158fdcc3-f204-4992-a92d-3724b9afb184",
            ic_lat: -30018,
            ic_lon: -449926,
            ic_city: "Olinda Nova do Maranhão",
            ic_states: "MARANHÃO",
            ic_january: 4.599,
            ic_february: 4.833,
            ic_march: 4.778,
            ic_april: 4.794,
            ic_may: 4.846,
            ic_june: 5.04,
            ic_july: 5.177,
            ic_august: 5.666,
            ic_september: 5.923,
            ic_october: 5.655,
            ic_november: 5.437,
            ic_december: 5.041,
            ic_yearly: 5.149,
          },
          {
            ic_uuid: "709057f7-09e3-4b30-b639-507dd3218ac2",
            ic_lat: -113592,
            ic_lon: -383265,
            ic_city: "Olindina",
            ic_states: "BAHIA",
            ic_january: 6.138,
            ic_february: 5.928,
            ic_march: 5.795,
            ic_april: 4.892,
            ic_may: 4.095,
            ic_june: 3.867,
            ic_july: 4.035,
            ic_august: 4.53,
            ic_september: 5.308,
            ic_october: 5.585,
            ic_november: 6.037,
            ic_december: 6.074,
            ic_yearly: 5.19,
          },
          {
            ic_uuid: "0ba38834-a48e-46b7-8fd3-8edcfc9363d9",
            ic_lat: -69917,
            ic_lon: -36244,
            ic_city: "Olivedos",
            ic_states: "PARAÍBA",
            ic_january: 5.799,
            ic_february: 5.788,
            ic_march: 5.803,
            ic_april: 5.317,
            ic_may: 4.778,
            ic_june: 4.196,
            ic_july: 4.458,
            ic_august: 5.157,
            ic_september: 5.782,
            ic_october: 6.092,
            ic_november: 6.276,
            ic_december: 5.968,
            ic_yearly: 5.451,
          },
          {
            ic_uuid: "2829de81-0835-4c27-9b2b-1e5355fa905d",
            ic_lat: -206987,
            ic_lon: -448294,
            ic_city: "Oliveira",
            ic_states: "MINAS GERAIS",
            ic_january: 5.646,
            ic_february: 5.846,
            ic_march: 4.98,
            ic_april: 4.722,
            ic_may: 4.107,
            ic_june: 3.949,
            ic_july: 4.203,
            ic_august: 5.065,
            ic_september: 5.296,
            ic_october: 5.457,
            ic_november: 5.187,
            ic_december: 5.615,
            ic_yearly: 5.006,
          },
          {
            ic_uuid: "db77951b-f965-4c40-b97b-e3934f40491d",
            ic_lat: -107074,
            ic_lon: -48909,
            ic_city: "Oliveira de Fátima",
            ic_states: "TOCANTINS",
            ic_january: 4.97,
            ic_february: 5.013,
            ic_march: 4.799,
            ic_april: 4.928,
            ic_may: 4.924,
            ic_june: 5.086,
            ic_july: 5.367,
            ic_august: 5.963,
            ic_september: 5.72,
            ic_october: 5.346,
            ic_november: 5.119,
            ic_december: 5.054,
            ic_yearly: 5.191,
          },
          {
            ic_uuid: "b403d738-49c4-4cd5-b3a7-4645689444b9",
            ic_lat: -123177,
            ic_lon: -428898,
            ic_city: "Oliveira dos Brejinhos",
            ic_states: "BAHIA",
            ic_january: 6.581,
            ic_february: 6.446,
            ic_march: 6.114,
            ic_april: 5.657,
            ic_may: 5.181,
            ic_june: 4.999,
            ic_july: 5.197,
            ic_august: 5.883,
            ic_september: 6.289,
            ic_october: 6.539,
            ic_november: 6.163,
            ic_december: 6.488,
            ic_yearly: 5.961,
          },
          {
            ic_uuid: "8c0d1bb2-2535-49e8-976c-5d9eee5aa7ac",
            ic_lat: -213405,
            ic_lon: -434503,
            ic_city: "Oliveira Fortes",
            ic_states: "MINAS GERAIS",
            ic_january: 5.425,
            ic_february: 5.776,
            ic_march: 4.82,
            ic_april: 4.208,
            ic_may: 3.616,
            ic_june: 3.463,
            ic_july: 3.629,
            ic_august: 4.444,
            ic_september: 4.689,
            ic_october: 4.887,
            ic_november: 4.633,
            ic_december: 5.42,
            ic_yearly: 4.584,
          },
          {
            ic_uuid: "892f66f9-0639-4eab-ac63-acecc292b6aa",
            ic_lat: -95181,
            ic_lon: -371912,
            ic_city: "Olivença",
            ic_states: "ALAGOAS",
            ic_january: 6.18,
            ic_february: 5.922,
            ic_march: 5.972,
            ic_april: 5.198,
            ic_may: 4.341,
            ic_june: 3.965,
            ic_july: 4.086,
            ic_august: 4.661,
            ic_september: 5.507,
            ic_october: 5.936,
            ic_november: 6.502,
            ic_december: 6.339,
            ic_yearly: 5.384,
          },
          {
            ic_uuid: "5d47e29b-6f7e-4e98-8873-36a8b77cc09f",
            ic_lat: -19728,
            ic_lon: -448061,
            ic_city: "Onça de Pitangui",
            ic_states: "MINAS GERAIS",
            ic_january: 5.812,
            ic_february: 6.006,
            ic_march: 5.154,
            ic_april: 4.895,
            ic_may: 4.347,
            ic_june: 4.179,
            ic_july: 4.459,
            ic_august: 5.318,
            ic_september: 5.552,
            ic_october: 5.59,
            ic_november: 5.317,
            ic_december: 5.667,
            ic_yearly: 5.192,
          },
          {
            ic_uuid: "5f8bc07c-44dd-435e-b26a-e471bdf95284",
            ic_lat: -206072,
            ic_lon: -492953,
            ic_city: "Onda Verde",
            ic_states: "SÃO PAULO",
            ic_january: 5.77,
            ic_february: 5.914,
            ic_march: 5.216,
            ic_april: 4.921,
            ic_may: 4.161,
            ic_june: 3.94,
            ic_july: 4.172,
            ic_august: 5.043,
            ic_september: 5.082,
            ic_october: 5.586,
            ic_november: 5.908,
            ic_december: 6.095,
            ic_yearly: 5.15,
          },
          {
            ic_uuid: "9142e08a-63d1-4f01-9f8f-d3e3aa14d55c",
            ic_lat: -204302,
            ic_lon: -427981,
            ic_city: "Oratórios",
            ic_states: "MINAS GERAIS",
            ic_january: 5.719,
            ic_february: 6.058,
            ic_march: 5.029,
            ic_april: 4.484,
            ic_may: 3.851,
            ic_june: 3.62,
            ic_july: 3.849,
            ic_august: 4.622,
            ic_september: 4.909,
            ic_october: 5.126,
            ic_november: 4.867,
            ic_december: 5.595,
            ic_yearly: 4.811,
          },
          {
            ic_uuid: "5da0fab4-1ce1-409a-82e1-2d99eb8bd0f2",
            ic_lat: -22153,
            ic_lon: -500909,
            ic_city: "Oriente",
            ic_states: "SÃO PAULO",
            ic_january: 5.742,
            ic_february: 5.893,
            ic_march: 5.291,
            ic_april: 4.784,
            ic_may: 3.862,
            ic_june: 3.566,
            ic_july: 3.8,
            ic_august: 4.791,
            ic_september: 4.818,
            ic_october: 5.515,
            ic_november: 5.961,
            ic_december: 6.189,
            ic_yearly: 5.018,
          },
          {
            ic_uuid: "1c9e605f-51a2-4a5d-b865-dcbedac8d5a5",
            ic_lat: -20182,
            ic_lon: -493509,
            ic_city: "Orindiúva",
            ic_states: "SÃO PAULO",
            ic_january: 5.743,
            ic_february: 5.921,
            ic_march: 5.208,
            ic_april: 4.99,
            ic_may: 4.227,
            ic_june: 3.971,
            ic_july: 4.247,
            ic_august: 5.127,
            ic_september: 5.085,
            ic_october: 5.563,
            ic_november: 5.837,
            ic_december: 6.195,
            ic_yearly: 5.176,
          },
          {
            ic_uuid: "03026f53-1edd-44d3-b2f6-20cb634856ed",
            ic_lat: -17603,
            ic_lon: -558584,
            ic_city: "Oriximiná",
            ic_states: "PARÁ",
            ic_january: 4.256,
            ic_february: 4.289,
            ic_march: 4.266,
            ic_april: 4.095,
            ic_may: 4.256,
            ic_june: 4.695,
            ic_july: 4.688,
            ic_august: 5.238,
            ic_september: 5.207,
            ic_october: 5.268,
            ic_november: 5.109,
            ic_december: 4.565,
            ic_yearly: 4.661,
          },
          {
            ic_uuid: "1c05f782-eac9-4d21-986d-0fa98f0d7232",
            ic_lat: -205146,
            ic_lon: -421995,
            ic_city: "Orizânia",
            ic_states: "MINAS GERAIS",
            ic_january: 5.576,
            ic_february: 5.875,
            ic_march: 4.877,
            ic_april: 4.412,
            ic_may: 3.744,
            ic_june: 3.613,
            ic_july: 3.816,
            ic_august: 4.552,
            ic_september: 4.941,
            ic_october: 5.013,
            ic_november: 4.726,
            ic_december: 5.497,
            ic_yearly: 4.72,
          },
          {
            ic_uuid: "fdf1b7eb-95e8-4811-bcb2-fd3320b4106e",
            ic_lat: -170341,
            ic_lon: -48302,
            ic_city: "Orizona",
            ic_states: "GOIÁS",
            ic_january: 5.65,
            ic_february: 5.815,
            ic_march: 5.121,
            ic_april: 5.065,
            ic_may: 4.764,
            ic_june: 4.578,
            ic_july: 4.817,
            ic_august: 5.669,
            ic_september: 5.607,
            ic_october: 5.653,
            ic_november: 5.293,
            ic_december: 5.671,
            ic_yearly: 5.309,
          },
          {
            ic_uuid: "6937020b-e79c-437d-8a7d-cc232fdb9884",
            ic_lat: -207174,
            ic_lon: -478856,
            ic_city: "Orlândia",
            ic_states: "SÃO PAULO",
            ic_january: 5.664,
            ic_february: 5.913,
            ic_march: 5.106,
            ic_april: 4.818,
            ic_may: 4.228,
            ic_june: 3.963,
            ic_july: 4.202,
            ic_august: 5.134,
            ic_september: 5.044,
            ic_october: 5.542,
            ic_november: 5.736,
            ic_december: 5.964,
            ic_yearly: 5.11,
          },
          {
            ic_uuid: "530d3036-9b32-4031-9142-f04dfad80f8f",
            ic_lat: -283492,
            ic_lon: -492991,
            ic_city: "Orleans",
            ic_states: "SANTA CATARINA",
            ic_january: 5.28,
            ic_february: 4.931,
            ic_march: 4.332,
            ic_april: 3.706,
            ic_may: 2.915,
            ic_june: 2.512,
            ic_july: 2.708,
            ic_august: 3.458,
            ic_september: 3.536,
            ic_october: 4.16,
            ic_november: 5.395,
            ic_december: 5.601,
            ic_yearly: 4.044,
          },
          {
            ic_uuid: "42abf8ae-6119-48f7-9dda-2ebf909fca25",
            ic_lat: -77473,
            ic_lon: -356045,
            ic_city: "Orobó",
            ic_states: "PERNAMBUCO",
            ic_january: 5.664,
            ic_february: 5.769,
            ic_march: 5.832,
            ic_april: 5.203,
            ic_may: 4.51,
            ic_june: 4.037,
            ic_july: 4.246,
            ic_august: 4.96,
            ic_september: 5.571,
            ic_october: 5.822,
            ic_november: 6.131,
            ic_december: 5.979,
            ic_yearly: 5.31,
          },
          {
            ic_uuid: "ea1c60b3-3ec6-4f50-94be-7fee5f871c99",
            ic_lat: -86107,
            ic_lon: -39603,
            ic_city: "Orocó",
            ic_states: "PERNAMBUCO",
            ic_january: 6.251,
            ic_february: 6.005,
            ic_march: 6.039,
            ic_april: 5.456,
            ic_may: 4.805,
            ic_june: 4.423,
            ic_july: 4.671,
            ic_august: 5.437,
            ic_september: 6.32,
            ic_october: 6.494,
            ic_november: 6.675,
            ic_december: 6.482,
            ic_yearly: 5.755,
          },
          {
            ic_uuid: "dd8580e0-de46-4425-83d9-1c6463a4662e",
            ic_lat: -62522,
            ic_lon: -389056,
            ic_city: "Orós",
            ic_states: "CEARÁ",
            ic_january: 5.844,
            ic_february: 5.848,
            ic_march: 5.947,
            ic_april: 5.617,
            ic_may: 5.255,
            ic_june: 5.087,
            ic_july: 5.41,
            ic_august: 6.145,
            ic_september: 6.407,
            ic_october: 6.467,
            ic_november: 6.556,
            ic_december: 6.086,
            ic_yearly: 5.889,
          },
          {
            ic_uuid: "99d721e4-67e3-4615-b27e-5c3048356e4d",
            ic_lat: -24212,
            ic_lon: -509274,
            ic_city: "Ortigueira",
            ic_states: "PARANÁ",
            ic_january: 5.645,
            ic_february: 5.465,
            ic_march: 5.019,
            ic_april: 4.416,
            ic_may: 3.464,
            ic_june: 3.065,
            ic_july: 3.33,
            ic_august: 4.336,
            ic_september: 4.469,
            ic_october: 5.074,
            ic_november: 5.817,
            ic_december: 6.013,
            ic_yearly: 4.676,
          },
          {
            ic_uuid: "bf09f67c-a3d0-4430-9e72-0dd3446abf50",
            ic_lat: -235329,
            ic_lon: -46792,
            ic_city: "Osasco",
            ic_states: "SÃO PAULO",
            ic_january: 5.247,
            ic_february: 5.529,
            ic_march: 4.727,
            ic_april: 4.205,
            ic_may: 3.458,
            ic_june: 3.194,
            ic_july: 3.288,
            ic_august: 4.255,
            ic_september: 4.261,
            ic_october: 4.773,
            ic_november: 5.194,
            ic_december: 5.767,
            ic_yearly: 4.492,
          },
          {
            ic_uuid: "c24f4e47-1862-4944-b6c9-7154255fffc9",
            ic_lat: -223153,
            ic_lon: -502815,
            ic_city: "Oscar Bressane",
            ic_states: "SÃO PAULO",
            ic_january: 5.779,
            ic_february: 5.863,
            ic_march: 5.327,
            ic_april: 4.782,
            ic_may: 3.856,
            ic_june: 3.566,
            ic_july: 3.784,
            ic_august: 4.749,
            ic_september: 4.808,
            ic_october: 5.511,
            ic_november: 5.99,
            ic_december: 6.308,
            ic_yearly: 5.027,
          },
          {
            ic_uuid: "49206be0-e08d-4a9a-acd1-e43690c98790",
            ic_lat: -298886,
            ic_lon: -502672,
            ic_city: "Osório",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.217,
            ic_february: 5.554,
            ic_march: 4.662,
            ic_april: 3.808,
            ic_may: 2.819,
            ic_june: 2.372,
            ic_july: 2.566,
            ic_august: 3.226,
            ic_september: 3.497,
            ic_october: 4.539,
            ic_november: 5.96,
            ic_december: 6.358,
            ic_yearly: 4.298,
          },
          {
            ic_uuid: "b4d7680a-a1c3-49db-a5c2-302dd73541cb",
            ic_lat: -217972,
            ic_lon: -508797,
            ic_city: "Osvaldo Cruz",
            ic_states: "SÃO PAULO",
            ic_january: 5.913,
            ic_february: 5.911,
            ic_march: 5.387,
            ic_april: 4.789,
            ic_may: 3.991,
            ic_june: 3.672,
            ic_july: 3.868,
            ic_august: 4.843,
            ic_september: 4.851,
            ic_october: 5.541,
            ic_november: 6.042,
            ic_december: 6.319,
            ic_yearly: 5.094,
          },
          {
            ic_uuid: "303d705c-5e7d-4a55-bf61-28f5afb23506",
            ic_lat: -274794,
            ic_lon: -501235,
            ic_city: "Otacílio Costa",
            ic_states: "SANTA CATARINA",
            ic_january: 5.543,
            ic_february: 5.248,
            ic_march: 4.527,
            ic_april: 3.663,
            ic_may: 2.85,
            ic_june: 2.405,
            ic_july: 2.642,
            ic_august: 3.405,
            ic_september: 3.556,
            ic_october: 4.135,
            ic_november: 5.463,
            ic_december: 5.767,
            ic_yearly: 4.1,
          },
          {
            ic_uuid: "7065e7fe-6556-4c65-80e8-7f331659365b",
            ic_lat: -15462,
            ic_lon: -471155,
            ic_city: "Ourém",
            ic_states: "PARÁ",
            ic_january: 4.435,
            ic_february: 4.31,
            ic_march: 4.316,
            ic_april: 4.378,
            ic_may: 4.574,
            ic_june: 4.86,
            ic_july: 5.022,
            ic_august: 5.297,
            ic_september: 5.514,
            ic_october: 5.232,
            ic_november: 5.039,
            ic_december: 4.686,
            ic_yearly: 4.805,
          },
          {
            ic_uuid: "4182832d-122e-4c1c-9e9b-97870997b477",
            ic_lat: -120117,
            ic_lon: -386295,
            ic_city: "Ouriçangas",
            ic_states: "BAHIA",
            ic_january: 6.101,
            ic_february: 5.871,
            ic_march: 5.714,
            ic_april: 4.727,
            ic_may: 3.958,
            ic_june: 3.704,
            ic_july: 3.971,
            ic_august: 4.434,
            ic_september: 5.311,
            ic_october: 5.558,
            ic_november: 5.873,
            ic_december: 6.087,
            ic_yearly: 5.109,
          },
          {
            ic_uuid: "dff26b68-7c90-49d1-850d-b03c1e2de8bf",
            ic_lat: -78796,
            ic_lon: -400803,
            ic_city: "Ouricuri",
            ic_states: "PERNAMBUCO",
            ic_january: 5.924,
            ic_february: 5.621,
            ic_march: 5.76,
            ic_april: 5.205,
            ic_may: 4.68,
            ic_june: 4.358,
            ic_july: 4.616,
            ic_august: 5.562,
            ic_september: 6.267,
            ic_october: 6.417,
            ic_november: 6.456,
            ic_december: 6.233,
            ic_yearly: 5.592,
          },
          {
            ic_uuid: "0e6b2505-b584-4ff1-8b82-e1342edb9919",
            ic_lat: -67533,
            ic_lon: -510862,
            ic_city: "Ourilândia do Norte",
            ic_states: "PARÁ",
            ic_january: 4.459,
            ic_february: 4.567,
            ic_march: 4.48,
            ic_april: 4.445,
            ic_may: 4.625,
            ic_june: 4.866,
            ic_july: 4.888,
            ic_august: 5.363,
            ic_september: 5.232,
            ic_october: 4.823,
            ic_november: 4.658,
            ic_december: 4.47,
            ic_yearly: 4.74,
          },
          {
            ic_uuid: "cf05938a-75ca-41f7-86be-2cbbf4de97bb",
            ic_lat: -229802,
            ic_lon: -498701,
            ic_city: "Ourinhos",
            ic_states: "SÃO PAULO",
            ic_january: 5.674,
            ic_february: 5.8,
            ic_march: 5.203,
            ic_april: 4.663,
            ic_may: 3.712,
            ic_june: 3.423,
            ic_july: 3.619,
            ic_august: 4.617,
            ic_september: 4.716,
            ic_october: 5.398,
            ic_november: 5.913,
            ic_december: 6.349,
            ic_yearly: 4.924,
          },
          {
            ic_uuid: "98c0f5eb-288c-474b-8962-ca2d9cdf6ab3",
            ic_lat: -234058,
            ic_lon: -521968,
            ic_city: "Ourizona",
            ic_states: "PARANÁ",
            ic_january: 5.882,
            ic_february: 5.877,
            ic_march: 5.305,
            ic_april: 4.578,
            ic_may: 3.625,
            ic_june: 3.294,
            ic_july: 3.494,
            ic_august: 4.457,
            ic_september: 4.662,
            ic_october: 5.374,
            ic_november: 6.059,
            ic_december: 6.349,
            ic_yearly: 4.913,
          },
          {
            ic_uuid: "3a45e95d-b4c9-4942-8ac4-70fadcf0f6ad",
            ic_lat: -273384,
            ic_lon: -516198,
            ic_city: "Ouro",
            ic_states: "SANTA CATARINA",
            ic_january: 6.049,
            ic_february: 5.584,
            ic_march: 5.048,
            ic_april: 4.065,
            ic_may: 3.078,
            ic_june: 2.634,
            ic_july: 2.931,
            ic_august: 3.752,
            ic_september: 4.02,
            ic_october: 4.981,
            ic_november: 6.172,
            ic_december: 6.414,
            ic_yearly: 4.561,
          },
          {
            ic_uuid: "e7626e05-66fa-494e-bf6e-6d658f9e89d1",
            ic_lat: -91593,
            ic_lon: -373559,
            ic_city: "Ouro Branco",
            ic_states: "ALAGOAS",
            ic_january: 6.221,
            ic_february: 6.029,
            ic_march: 6.022,
            ic_april: 5.305,
            ic_may: 4.404,
            ic_june: 4.01,
            ic_july: 4.216,
            ic_august: 4.824,
            ic_september: 5.745,
            ic_october: 6.141,
            ic_november: 6.511,
            ic_december: 6.417,
            ic_yearly: 5.487,
          },
          {
            ic_uuid: "b70d2b9f-9d64-40a5-aa06-d5dfe68277d9",
            ic_lat: -205268,
            ic_lon: -436966,
            ic_city: "Ouro Branco",
            ic_states: "MINAS GERAIS",
            ic_january: 5.482,
            ic_february: 5.742,
            ic_march: 4.789,
            ic_april: 4.399,
            ic_may: 3.931,
            ic_june: 3.817,
            ic_july: 3.998,
            ic_august: 4.948,
            ic_september: 5.096,
            ic_october: 5.178,
            ic_november: 4.79,
            ic_december: 5.323,
            ic_yearly: 4.791,
          },
          {
            ic_uuid: "4e3e8534-b9d2-4f8c-8751-048ac86a86af",
            ic_lat: -67024,
            ic_lon: -369452,
            ic_city: "Ouro Branco",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.12,
            ic_february: 6.187,
            ic_march: 6.227,
            ic_april: 5.848,
            ic_may: 5.179,
            ic_june: 4.73,
            ic_july: 5.025,
            ic_august: 5.799,
            ic_september: 6.326,
            ic_october: 6.558,
            ic_november: 6.607,
            ic_december: 6.309,
            ic_yearly: 5.91,
          },
          {
            ic_uuid: "586de0cd-8fe3-4a9c-a7bf-33ea548200be",
            ic_lat: -222783,
            ic_lon: -46372,
            ic_city: "Ouro Fino",
            ic_states: "MINAS GERAIS",
            ic_january: 5.3,
            ic_february: 5.567,
            ic_march: 4.909,
            ic_april: 4.616,
            ic_may: 3.906,
            ic_june: 3.648,
            ic_july: 3.879,
            ic_august: 4.733,
            ic_september: 4.951,
            ic_october: 5.393,
            ic_november: 5.475,
            ic_december: 5.686,
            ic_yearly: 4.838,
          },
          {
            ic_uuid: "ea6e137f-1b4e-4e07-bdc1-52459c547768",
            ic_lat: -2038,
            ic_lon: -435124,
            ic_city: "Ouro Preto",
            ic_states: "MINAS GERAIS",
            ic_january: 5.594,
            ic_february: 5.825,
            ic_march: 4.909,
            ic_april: 4.429,
            ic_may: 3.937,
            ic_june: 3.829,
            ic_july: 4.017,
            ic_august: 4.946,
            ic_september: 5.108,
            ic_october: 5.161,
            ic_november: 4.792,
            ic_december: 5.352,
            ic_yearly: 4.825,
          },
          {
            ic_uuid: "5b1b7d98-683a-496d-8bc6-810cca4cd7b4",
            ic_lat: -107171,
            ic_lon: -62257,
            ic_city: "Ouro Preto do Oeste",
            ic_states: "RONDÔNIA",
            ic_january: 4.417,
            ic_february: 4.27,
            ic_march: 4.401,
            ic_april: 4.491,
            ic_may: 4.075,
            ic_june: 4.38,
            ic_july: 4.456,
            ic_august: 4.889,
            ic_september: 4.847,
            ic_october: 5.094,
            ic_november: 4.918,
            ic_december: 4.637,
            ic_yearly: 4.573,
          },
          {
            ic_uuid: "69df136e-6292-42e9-ab9d-4cb5dacfde77",
            ic_lat: -76165,
            ic_lon: -371522,
            ic_city: "Ouro Velho",
            ic_states: "PARAÍBA",
            ic_january: 6.136,
            ic_february: 6.122,
            ic_march: 6.1,
            ic_april: 5.747,
            ic_may: 4.982,
            ic_june: 4.527,
            ic_july: 4.72,
            ic_august: 5.465,
            ic_september: 6.31,
            ic_october: 6.525,
            ic_november: 6.668,
            ic_december: 6.335,
            ic_yearly: 5.803,
          },
          {
            ic_uuid: "e7abb4bb-af40-461f-9032-d010ce2f74e4",
            ic_lat: -266925,
            ic_lon: -523113,
            ic_city: "Ouro Verde",
            ic_states: "SANTA CATARINA",
            ic_january: 6.195,
            ic_february: 5.549,
            ic_march: 5.016,
            ic_april: 4.119,
            ic_may: 3.205,
            ic_june: 2.754,
            ic_july: 3.019,
            ic_august: 3.904,
            ic_september: 4.121,
            ic_october: 5.096,
            ic_november: 6.088,
            ic_december: 6.283,
            ic_yearly: 4.612,
          },
          {
            ic_uuid: "3a726d62-f1da-42b4-a21f-29cae5acfcdc",
            ic_lat: -214876,
            ic_lon: -517028,
            ic_city: "Ouro Verde",
            ic_states: "SÃO PAULO",
            ic_january: 5.915,
            ic_february: 5.992,
            ic_march: 5.425,
            ic_april: 4.847,
            ic_may: 4.025,
            ic_june: 3.682,
            ic_july: 3.877,
            ic_august: 4.78,
            ic_september: 4.896,
            ic_october: 5.592,
            ic_november: 6.027,
            ic_december: 6.364,
            ic_yearly: 5.118,
          },
          {
            ic_uuid: "f24d6f2e-7145-4236-9a78-5d6da57717b1",
            ic_lat: -162183,
            ic_lon: -491966,
            ic_city: "Ouro Verde de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.379,
            ic_february: 5.524,
            ic_march: 5.113,
            ic_april: 5.087,
            ic_may: 4.834,
            ic_june: 4.635,
            ic_july: 4.839,
            ic_august: 5.785,
            ic_september: 5.522,
            ic_october: 5.52,
            ic_november: 5.214,
            ic_december: 5.419,
            ic_yearly: 5.239,
          },
          {
            ic_uuid: "04089a43-201d-412e-b038-81d2aeb4ac3b",
            ic_lat: -180724,
            ic_lon: -412738,
            ic_city: "Ouro Verde de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.981,
            ic_february: 6.156,
            ic_march: 5.266,
            ic_april: 4.568,
            ic_may: 3.81,
            ic_june: 3.589,
            ic_july: 3.665,
            ic_august: 4.295,
            ic_september: 4.874,
            ic_october: 5.144,
            ic_november: 4.957,
            ic_december: 5.775,
            ic_yearly: 4.84,
          },
          {
            ic_uuid: "9216134c-aab0-4e16-98f2-b734bc2a416a",
            ic_lat: -247937,
            ic_lon: -539048,
            ic_city: "Ouro Verde do Oeste",
            ic_states: "PARANÁ",
            ic_january: 6.275,
            ic_february: 5.701,
            ic_march: 5.287,
            ic_april: 4.264,
            ic_may: 3.336,
            ic_june: 2.958,
            ic_july: 3.165,
            ic_august: 4.176,
            ic_september: 4.39,
            ic_october: 5.27,
            ic_november: 6.12,
            ic_december: 6.504,
            ic_yearly: 4.787,
          },
          {
            ic_uuid: "0576d89f-09e6-44fd-9d3a-5dd860090a62",
            ic_lat: -199975,
            ic_lon: -503745,
            ic_city: "Ouroeste",
            ic_states: "SÃO PAULO",
            ic_january: 5.772,
            ic_february: 5.972,
            ic_march: 5.318,
            ic_april: 4.944,
            ic_may: 4.264,
            ic_june: 3.959,
            ic_july: 4.199,
            ic_august: 5.075,
            ic_september: 5.116,
            ic_october: 5.684,
            ic_november: 5.904,
            ic_december: 6.165,
            ic_yearly: 5.198,
          },
          {
            ic_uuid: "86595f10-0d6f-43dc-adfd-5c63144c9c34",
            ic_lat: -109703,
            ic_lon: -410812,
            ic_city: "Ourolândia",
            ic_states: "BAHIA",
            ic_january: 6.245,
            ic_february: 5.926,
            ic_march: 5.828,
            ic_april: 4.908,
            ic_may: 4.373,
            ic_june: 4.126,
            ic_july: 4.305,
            ic_august: 5.056,
            ic_september: 5.984,
            ic_october: 6.044,
            ic_november: 6.097,
            ic_december: 6.134,
            ic_yearly: 5.419,
          },
          {
            ic_uuid: "8a758b03-c51f-44a2-b825-dfe00b2399b8",
            ic_lat: -182336,
            ic_lon: -478342,
            ic_city: "Ouvidor",
            ic_states: "GOIÁS",
            ic_january: 5.644,
            ic_february: 5.898,
            ic_march: 5.158,
            ic_april: 5.118,
            ic_may: 4.683,
            ic_june: 4.45,
            ic_july: 4.723,
            ic_august: 5.64,
            ic_september: 5.531,
            ic_october: 5.645,
            ic_november: 5.51,
            ic_december: 5.69,
            ic_yearly: 5.308,
          },
          {
            ic_uuid: "c73d536c-eec4-4e6f-8592-05e46265c683",
            ic_lat: -215631,
            ic_lon: -512658,
            ic_city: "Pacaembu",
            ic_states: "SÃO PAULO",
            ic_january: 5.922,
            ic_february: 5.956,
            ic_march: 5.444,
            ic_april: 4.876,
            ic_may: 4.016,
            ic_june: 3.713,
            ic_july: 3.88,
            ic_august: 4.808,
            ic_september: 4.878,
            ic_october: 5.557,
            ic_november: 6.079,
            ic_december: 6.326,
            ic_yearly: 5.121,
          },
          {
            ic_uuid: "525bdf8e-e481-4719-b5c4-10969f5ac3f0",
            ic_lat: -38377,
            ic_lon: -50639,
            ic_city: "Pacajá",
            ic_states: "PARÁ",
            ic_january: 4.228,
            ic_february: 4.368,
            ic_march: 4.436,
            ic_april: 4.419,
            ic_may: 4.48,
            ic_june: 4.74,
            ic_july: 4.807,
            ic_august: 5.056,
            ic_september: 4.813,
            ic_october: 4.56,
            ic_november: 4.488,
            ic_december: 4.222,
            ic_yearly: 4.552,
          },
          {
            ic_uuid: "99c05a56-534f-4a56-bc37-5fa5747183c9",
            ic_lat: -41715,
            ic_lon: -384653,
            ic_city: "Pacajus",
            ic_states: "CEARÁ",
            ic_january: 5.638,
            ic_february: 5.674,
            ic_march: 5.521,
            ic_april: 4.969,
            ic_may: 5.238,
            ic_june: 5.102,
            ic_july: 5.408,
            ic_august: 6.037,
            ic_september: 6.243,
            ic_october: 6.329,
            ic_november: 6.293,
            ic_december: 5.869,
            ic_yearly: 5.694,
          },
          {
            ic_uuid: "a5c50f95-f5ad-487e-a9d8-bc3c8ff50322",
            ic_lat: 44795,
            ic_lon: -611481,
            ic_city: "Pacaraima",
            ic_states: "RORAIMA",
            ic_january: 4.815,
            ic_february: 5.032,
            ic_march: 5.2,
            ic_april: 5.28,
            ic_may: 4.861,
            ic_june: 4.728,
            ic_july: 4.86,
            ic_august: 5.219,
            ic_september: 5.618,
            ic_october: 5.462,
            ic_november: 5.159,
            ic_december: 4.785,
            ic_yearly: 5.085,
          },
          {
            ic_uuid: "cf1e5779-8197-4e5d-afbf-c7017a676021",
            ic_lat: -39788,
            ic_lon: -386186,
            ic_city: "Pacatuba",
            ic_states: "CEARÁ",
            ic_january: 5.314,
            ic_february: 5.326,
            ic_march: 5.165,
            ic_april: 4.605,
            ic_may: 4.925,
            ic_june: 4.915,
            ic_july: 5.302,
            ic_august: 5.972,
            ic_september: 6.152,
            ic_october: 6.034,
            ic_november: 6.015,
            ic_december: 5.474,
            ic_yearly: 5.433,
          },
          {
            ic_uuid: "93eb8337-304a-43ec-93a9-339c79b3758f",
            ic_lat: -104555,
            ic_lon: -366485,
            ic_city: "Pacatuba",
            ic_states: "SERGIPE",
            ic_january: 6.14,
            ic_february: 6.02,
            ic_march: 5.859,
            ic_april: 4.986,
            ic_may: 4.235,
            ic_june: 4.005,
            ic_july: 4.07,
            ic_august: 4.671,
            ic_september: 5.361,
            ic_october: 5.753,
            ic_november: 6.247,
            ic_december: 6.315,
            ic_yearly: 5.305,
          },
          {
            ic_uuid: "6045aeea-24a2-4bbc-b704-2475a8b7e137",
            ic_lat: -25176,
            ic_lon: -441091,
            ic_city: "Paço do Lumiar",
            ic_states: "MARANHÃO",
            ic_january: 4.906,
            ic_february: 4.914,
            ic_march: 4.766,
            ic_april: 4.589,
            ic_may: 4.667,
            ic_june: 4.833,
            ic_july: 5.058,
            ic_august: 5.656,
            ic_september: 5.959,
            ic_october: 5.73,
            ic_november: 5.561,
            ic_december: 5.402,
            ic_yearly: 5.17,
          },
          {
            ic_uuid: "a449c67c-cab0-400e-a19e-e339683b9f78",
            ic_lat: -42253,
            ic_lon: -389223,
            ic_city: "Pacoti",
            ic_states: "CEARÁ",
            ic_january: 5.356,
            ic_february: 5.329,
            ic_march: 5.21,
            ic_april: 4.72,
            ic_may: 5.091,
            ic_june: 4.995,
            ic_july: 5.416,
            ic_august: 6.222,
            ic_september: 6.609,
            ic_october: 6.473,
            ic_november: 6.307,
            ic_december: 5.653,
            ic_yearly: 5.615,
          },
          {
            ic_uuid: "d8708531-b170-456e-a843-9322df4916f3",
            ic_lat: -39837,
            ic_lon: -406992,
            ic_city: "Pacujá",
            ic_states: "CEARÁ",
            ic_january: 4.971,
            ic_february: 5.157,
            ic_march: 5.126,
            ic_april: 4.786,
            ic_may: 4.874,
            ic_june: 4.915,
            ic_july: 5.217,
            ic_august: 5.933,
            ic_september: 6.393,
            ic_october: 6.272,
            ic_november: 5.971,
            ic_december: 5.287,
            ic_yearly: 5.408,
          },
          {
            ic_uuid: "616faaba-8ae3-4d51-b6b6-f818deedc51d",
            ic_lat: -151642,
            ic_lon: -482865,
            ic_city: "Padre Bernardo",
            ic_states: "GOIÁS",
            ic_january: 5.677,
            ic_february: 5.865,
            ic_march: 5.409,
            ic_april: 5.26,
            ic_may: 5.005,
            ic_june: 4.766,
            ic_july: 4.921,
            ic_august: 5.847,
            ic_september: 5.735,
            ic_october: 5.768,
            ic_november: 5.364,
            ic_december: 5.627,
            ic_yearly: 5.437,
          },
          {
            ic_uuid: "1664a127-7aaf-47b1-8520-83726f35c14c",
            ic_lat: -163644,
            ic_lon: -425158,
            ic_city: "Padre Carvalho",
            ic_states: "MINAS GERAIS",
            ic_january: 6.046,
            ic_february: 6.335,
            ic_march: 5.336,
            ic_april: 4.707,
            ic_may: 4.059,
            ic_june: 3.842,
            ic_july: 4.111,
            ic_august: 4.922,
            ic_september: 5.556,
            ic_october: 5.693,
            ic_november: 5.182,
            ic_december: 5.942,
            ic_yearly: 5.144,
          },
          {
            ic_uuid: "bb33c6ea-fe7f-4c28-8b97-78ecedee4184",
            ic_lat: -7347,
            ic_lon: -409086,
            ic_city: "Padre Marcos",
            ic_states: "PIAUÍ",
            ic_january: 5.544,
            ic_february: 5.539,
            ic_march: 5.57,
            ic_april: 5.271,
            ic_may: 5.187,
            ic_june: 5.105,
            ic_july: 5.464,
            ic_august: 6.141,
            ic_september: 6.633,
            ic_october: 6.453,
            ic_november: 6.357,
            ic_december: 6.025,
            ic_yearly: 5.774,
          },
          {
            ic_uuid: "0d25343e-5d0c-4542-baca-1f9fe8cebb31",
            ic_lat: -170763,
            ic_lon: -414824,
            ic_city: "Padre Paraíso",
            ic_states: "MINAS GERAIS",
            ic_january: 5.992,
            ic_february: 6.234,
            ic_march: 5.244,
            ic_april: 4.511,
            ic_may: 3.829,
            ic_june: 3.529,
            ic_july: 3.7,
            ic_august: 4.469,
            ic_september: 5.045,
            ic_october: 5.321,
            ic_november: 4.967,
            ic_december: 5.75,
            ic_yearly: 4.883,
          },
          {
            ic_uuid: "a2e729e6-3a74-4936-bf23-9c82ae38db82",
            ic_lat: -77767,
            ic_lon: -422548,
            ic_city: "Paes Landim",
            ic_states: "PIAUÍ",
            ic_january: 5.633,
            ic_february: 5.537,
            ic_march: 5.595,
            ic_april: 5.351,
            ic_may: 5.306,
            ic_june: 5.26,
            ic_july: 5.573,
            ic_august: 6.292,
            ic_september: 6.579,
            ic_october: 6.487,
            ic_november: 6.183,
            ic_december: 5.881,
            ic_yearly: 5.806,
          },
          {
            ic_uuid: "fa3b9d01-bbf1-4ce4-bdb4-6b4a3527d6f4",
            ic_lat: -155314,
            ic_lon: -430679,
            ic_city: "Pai Pedro",
            ic_states: "MINAS GERAIS",
            ic_january: 6.647,
            ic_february: 6.778,
            ic_march: 5.947,
            ic_april: 5.515,
            ic_may: 4.89,
            ic_june: 4.592,
            ic_july: 4.842,
            ic_august: 5.469,
            ic_september: 6.004,
            ic_october: 6.215,
            ic_november: 5.832,
            ic_december: 6.468,
            ic_yearly: 5.767,
          },
          {
            ic_uuid: "05aa0e05-76cb-4212-bf68-a69a8cfe67f0",
            ic_lat: -272546,
            ic_lon: -52498,
            ic_city: "Paial",
            ic_states: "SANTA CATARINA",
            ic_january: 6.23,
            ic_february: 5.778,
            ic_march: 5.137,
            ic_april: 4.073,
            ic_may: 3.036,
            ic_june: 2.476,
            ic_july: 2.816,
            ic_august: 3.718,
            ic_september: 3.988,
            ic_october: 5.115,
            ic_november: 6.236,
            ic_december: 6.572,
            ic_yearly: 4.598,
          },
          {
            ic_uuid: "1a377136-7785-4058-be78-4185666593bf",
            ic_lat: -23456,
            ic_lon: -520464,
            ic_city: "Paiçandu",
            ic_states: "PARANÁ",
            ic_january: 5.916,
            ic_february: 5.771,
            ic_march: 5.231,
            ic_april: 4.538,
            ic_may: 3.622,
            ic_june: 3.268,
            ic_july: 3.498,
            ic_august: 4.397,
            ic_september: 4.649,
            ic_october: 5.314,
            ic_november: 6.015,
            ic_december: 6.343,
            ic_yearly: 4.88,
          },
          {
            ic_uuid: "0b471ec2-dff0-480b-833c-28fd1e59e2d4",
            ic_lat: -27708,
            ic_lon: -517634,
            ic_city: "Paim Filho",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.274,
            ic_february: 5.778,
            ic_march: 5.022,
            ic_april: 4.086,
            ic_may: 3.062,
            ic_june: 2.613,
            ic_july: 2.903,
            ic_august: 3.706,
            ic_september: 3.929,
            ic_october: 5.044,
            ic_november: 6.251,
            ic_december: 6.541,
            ic_yearly: 4.601,
          },
          {
            ic_uuid: "151bced3-bd05-4579-a3cb-c5d38674ad4e",
            ic_lat: -189047,
            ic_lon: -45536,
            ic_city: "Paineiras",
            ic_states: "MINAS GERAIS",
            ic_january: 5.963,
            ic_february: 6.207,
            ic_march: 5.258,
            ic_april: 5.123,
            ic_may: 4.664,
            ic_june: 4.344,
            ic_july: 4.734,
            ic_august: 5.583,
            ic_september: 5.757,
            ic_october: 5.734,
            ic_november: 5.356,
            ic_december: 5.742,
            ic_yearly: 5.372,
          },
          {
            ic_uuid: "5dbede25-c04e-4a9d-81fe-64cee9862948",
            ic_lat: -279217,
            ic_lon: -501014,
            ic_city: "Painel",
            ic_states: "SANTA CATARINA",
            ic_january: 5.77,
            ic_february: 5.398,
            ic_march: 4.682,
            ic_april: 3.784,
            ic_may: 2.895,
            ic_june: 2.503,
            ic_july: 2.761,
            ic_august: 3.543,
            ic_september: 3.762,
            ic_october: 4.529,
            ic_november: 5.779,
            ic_december: 6.048,
            ic_yearly: 4.288,
          },
          {
            ic_uuid: "01e06125-50fe-45be-b46e-8bae9f5e3337",
            ic_lat: -203709,
            ic_lon: -456631,
            ic_city: "Pains",
            ic_states: "MINAS GERAIS",
            ic_january: 5.75,
            ic_february: 5.973,
            ic_march: 5.011,
            ic_april: 4.882,
            ic_may: 4.291,
            ic_june: 4.049,
            ic_july: 4.313,
            ic_august: 5.236,
            ic_september: 5.358,
            ic_october: 5.504,
            ic_november: 5.202,
            ic_december: 5.7,
            ic_yearly: 5.106,
          },
          {
            ic_uuid: "fbe6c6ea-3160-4bb1-b34c-fe1fb61439c1",
            ic_lat: -212918,
            ic_lon: -434091,
            ic_city: "Paiva",
            ic_states: "MINAS GERAIS",
            ic_january: 5.425,
            ic_february: 5.776,
            ic_march: 4.82,
            ic_april: 4.208,
            ic_may: 3.616,
            ic_june: 3.463,
            ic_july: 3.629,
            ic_august: 4.444,
            ic_september: 4.689,
            ic_october: 4.887,
            ic_november: 4.633,
            ic_december: 5.42,
            ic_yearly: 4.584,
          },
          {
            ic_uuid: "370c122b-5135-4efa-82e3-c3fa62ac88df",
            ic_lat: -78557,
            ic_lon: -428219,
            ic_city: "Pajeú do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.488,
            ic_february: 5.395,
            ic_march: 5.415,
            ic_april: 5.32,
            ic_may: 5.271,
            ic_june: 5.353,
            ic_july: 5.61,
            ic_august: 6.357,
            ic_september: 6.687,
            ic_october: 6.472,
            ic_november: 6.041,
            ic_december: 5.766,
            ic_yearly: 5.765,
          },
          {
            ic_uuid: "4e76bf1d-dd81-450d-be14-1ee859e8f73c",
            ic_lat: -96725,
            ic_lon: -373386,
            ic_city: "Palestina",
            ic_states: "ALAGOAS",
            ic_january: 6.07,
            ic_february: 5.901,
            ic_march: 5.898,
            ic_april: 5.19,
            ic_may: 4.389,
            ic_june: 4.012,
            ic_july: 4.128,
            ic_august: 4.669,
            ic_september: 5.467,
            ic_october: 5.758,
            ic_november: 6.367,
            ic_december: 6.241,
            ic_yearly: 5.341,
          },
          {
            ic_uuid: "9e5caef1-a5cb-40ce-9173-7c7d1d6edc18",
            ic_lat: -203905,
            ic_lon: -494313,
            ic_city: "Palestina",
            ic_states: "SÃO PAULO",
            ic_january: 5.725,
            ic_february: 5.845,
            ic_march: 5.214,
            ic_april: 4.999,
            ic_may: 4.209,
            ic_june: 3.934,
            ic_july: 4.204,
            ic_august: 5.116,
            ic_september: 5.084,
            ic_october: 5.59,
            ic_november: 5.876,
            ic_december: 6.123,
            ic_yearly: 5.16,
          },
          {
            ic_uuid: "54c4c168-22fc-4c12-9561-d76edd6f585b",
            ic_lat: -167446,
            ic_lon: -515278,
            ic_city: "Palestina de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.515,
            ic_february: 5.621,
            ic_march: 5.242,
            ic_april: 5.208,
            ic_may: 4.801,
            ic_june: 4.487,
            ic_july: 4.622,
            ic_august: 5.423,
            ic_september: 5.356,
            ic_october: 5.517,
            ic_november: 5.494,
            ic_december: 5.557,
            ic_yearly: 5.237,
          },
          {
            ic_uuid: "a6115a40-cb8b-44ab-b04a-6ed8605609c5",
            ic_lat: -57407,
            ic_lon: -483185,
            ic_city: "Palestina do Pará",
            ic_states: "PARÁ",
            ic_january: 4.615,
            ic_february: 4.731,
            ic_march: 4.764,
            ic_april: 4.772,
            ic_may: 4.77,
            ic_june: 5.1,
            ic_july: 5.227,
            ic_august: 5.707,
            ic_september: 5.439,
            ic_october: 4.952,
            ic_november: 4.77,
            ic_december: 4.593,
            ic_yearly: 4.953,
          },
          {
            ic_uuid: "18cae5e9-9c40-47b8-8167-d2728f6c946e",
            ic_lat: -47371,
            ic_lon: -379659,
            ic_city: "Palhano",
            ic_states: "CEARÁ",
            ic_january: 5.735,
            ic_february: 5.848,
            ic_march: 5.834,
            ic_april: 5.314,
            ic_may: 5.304,
            ic_june: 5.046,
            ic_july: 5.326,
            ic_august: 5.962,
            ic_september: 6.287,
            ic_october: 6.362,
            ic_november: 6.327,
            ic_december: 5.978,
            ic_yearly: 5.777,
          },
          {
            ic_uuid: "a50f3502-57a2-4275-96c1-da3ad3e0d2bd",
            ic_lat: -27646,
            ic_lon: -486701,
            ic_city: "Palhoça",
            ic_states: "SANTA CATARINA",
            ic_january: 5.733,
            ic_february: 5.352,
            ic_march: 4.573,
            ic_april: 3.733,
            ic_may: 3.03,
            ic_june: 2.544,
            ic_july: 2.679,
            ic_august: 3.389,
            ic_september: 3.464,
            ic_october: 4.149,
            ic_november: 5.38,
            ic_december: 5.844,
            ic_yearly: 4.156,
          },
          {
            ic_uuid: "849f4eea-dad2-4008-a072-9585a5f2f5d5",
            ic_lat: -213753,
            ic_lon: -423126,
            ic_city: "Palma",
            ic_states: "MINAS GERAIS",
            ic_january: 5.962,
            ic_february: 6.12,
            ic_march: 5.078,
            ic_april: 4.403,
            ic_may: 3.709,
            ic_june: 3.477,
            ic_july: 3.614,
            ic_august: 4.41,
            ic_september: 4.79,
            ic_october: 5.109,
            ic_november: 4.911,
            ic_december: 5.84,
            ic_yearly: 4.785,
          },
          {
            ic_uuid: "4e0b474f-c758-413c-8588-e13212893b57",
            ic_lat: -263475,
            ic_lon: -532775,
            ic_city: "Palma Sola",
            ic_states: "SANTA CATARINA",
            ic_january: 6.216,
            ic_february: 5.668,
            ic_march: 5.109,
            ic_april: 4.141,
            ic_may: 3.237,
            ic_june: 2.759,
            ic_july: 3.072,
            ic_august: 4.005,
            ic_september: 4.245,
            ic_october: 5.165,
            ic_november: 6.023,
            ic_december: 6.283,
            ic_yearly: 4.66,
          },
          {
            ic_uuid: "a68a44ad-d767-4a36-8e3d-2580b4e29bb7",
            ic_lat: -41387,
            ic_lon: -388449,
            ic_city: "Palmácia",
            ic_states: "CEARÁ",
            ic_january: 5.317,
            ic_february: 5.394,
            ic_march: 5.32,
            ic_april: 4.843,
            ic_may: 5.176,
            ic_june: 5.11,
            ic_july: 5.419,
            ic_august: 6.146,
            ic_september: 6.406,
            ic_october: 6.274,
            ic_november: 6.152,
            ic_december: 5.525,
            ic_yearly: 5.59,
          },
          {
            ic_uuid: "af3e7948-bc26-40ca-9c22-eb33740a739c",
            ic_lat: -86847,
            ic_lon: -355893,
            ic_city: "Palmares",
            ic_states: "PERNAMBUCO",
            ic_january: 5.711,
            ic_february: 5.636,
            ic_march: 5.733,
            ic_april: 5.001,
            ic_may: 4.289,
            ic_june: 3.929,
            ic_july: 3.968,
            ic_august: 4.56,
            ic_september: 5.357,
            ic_october: 5.612,
            ic_november: 5.981,
            ic_december: 5.961,
            ic_yearly: 5.145,
          },
          {
            ic_uuid: "a094028b-8cf8-46ef-9463-a73414107841",
            ic_lat: -30258,
            ic_lon: -505099,
            ic_city: "Palmares do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.567,
            ic_february: 5.961,
            ic_march: 4.889,
            ic_april: 3.874,
            ic_may: 2.811,
            ic_june: 2.327,
            ic_july: 2.528,
            ic_august: 3.178,
            ic_september: 3.608,
            ic_october: 4.867,
            ic_november: 6.234,
            ic_december: 6.785,
            ic_yearly: 4.469,
          },
          {
            ic_uuid: "16f682a1-7519-4539-9bfb-37a78f78de4d",
            ic_lat: -210858,
            ic_lon: -488041,
            ic_city: "Palmares Paulista",
            ic_states: "SÃO PAULO",
            ic_january: 5.832,
            ic_february: 5.909,
            ic_march: 5.191,
            ic_april: 4.81,
            ic_may: 4.102,
            ic_june: 3.878,
            ic_july: 4.092,
            ic_august: 5.006,
            ic_september: 5.093,
            ic_october: 5.616,
            ic_november: 5.872,
            ic_december: 6.137,
            ic_yearly: 5.128,
          },
          {
            ic_uuid: "80fe2aaf-9fad-4077-8b56-a8e9f3042353",
            ic_lat: -264843,
            ic_lon: -519893,
            ic_city: "Palmas",
            ic_states: "PARANÁ",
            ic_january: 5.928,
            ic_february: 5.484,
            ic_march: 5.004,
            ic_april: 4.111,
            ic_may: 3.168,
            ic_june: 2.761,
            ic_july: 3.032,
            ic_august: 3.975,
            ic_september: 4.145,
            ic_october: 5.024,
            ic_november: 6.038,
            ic_december: 6.183,
            ic_yearly: 4.571,
          },
          {
            ic_uuid: "83ccd11f-f1c3-4a98-a4d9-a6e968414a4b",
            ic_lat: -102408,
            ic_lon: -483565,
            ic_city: "Palmas",
            ic_states: "TOCANTINS",
            ic_january: 5.143,
            ic_february: 5.133,
            ic_march: 4.857,
            ic_april: 4.954,
            ic_may: 4.967,
            ic_june: 5.035,
            ic_july: 5.244,
            ic_august: 5.942,
            ic_september: 5.715,
            ic_october: 5.401,
            ic_november: 5.137,
            ic_december: 5.089,
            ic_yearly: 5.218,
          },
          {
            ic_uuid: "7bcf6bfb-193f-42fd-ae98-8c3a28231649",
            ic_lat: -14268,
            ic_lon: -431612,
            ic_city: "Palmas de Monte Alto",
            ic_states: "BAHIA",
            ic_january: 6.568,
            ic_february: 6.615,
            ic_march: 5.956,
            ic_april: 5.518,
            ic_may: 4.85,
            ic_june: 4.76,
            ic_july: 5.022,
            ic_august: 5.685,
            ic_september: 6.107,
            ic_october: 6.396,
            ic_november: 5.92,
            ic_december: 6.476,
            ic_yearly: 5.823,
          },
          {
            ic_uuid: "78c5d8b6-78d4-455f-94c3-ae657d4d002b",
            ic_lat: -254262,
            ic_lon: -500075,
            ic_city: "Palmeira",
            ic_states: "PARANÁ",
            ic_january: 5.573,
            ic_february: 5.33,
            ic_march: 4.688,
            ic_april: 3.922,
            ic_may: 3.119,
            ic_june: 2.793,
            ic_july: 2.998,
            ic_august: 4.002,
            ic_september: 4.075,
            ic_october: 4.67,
            ic_november: 5.523,
            ic_december: 5.774,
            ic_yearly: 4.372,
          },
          {
            ic_uuid: "d769c8bd-8a96-4cd7-bf1e-3817746450e8",
            ic_lat: -275835,
            ic_lon: -501582,
            ic_city: "Palmeira",
            ic_states: "SANTA CATARINA",
            ic_january: 5.621,
            ic_february: 5.304,
            ic_march: 4.493,
            ic_april: 3.646,
            ic_may: 2.845,
            ic_june: 2.391,
            ic_july: 2.649,
            ic_august: 3.405,
            ic_september: 3.571,
            ic_october: 4.189,
            ic_november: 5.48,
            ic_december: 5.826,
            ic_yearly: 4.118,
          },
          {
            ic_uuid: "0998bb3c-7d83-487b-9314-b5c273b15271",
            ic_lat: -279011,
            ic_lon: -533138,
            ic_city: "Palmeira das Missões",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.399,
            ic_february: 5.958,
            ic_march: 5.008,
            ic_april: 4.04,
            ic_may: 3.111,
            ic_june: 2.592,
            ic_july: 2.91,
            ic_august: 3.677,
            ic_september: 3.973,
            ic_october: 5.128,
            ic_november: 6.204,
            ic_december: 6.639,
            ic_yearly: 4.637,
          },
          {
            ic_uuid: "0126b531-1662-4836-8af3-e84989d0b003",
            ic_lat: -8732,
            ic_lon: -442471,
            ic_city: "Palmeira do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.628,
            ic_february: 5.387,
            ic_march: 5.37,
            ic_april: 5.467,
            ic_may: 5.388,
            ic_june: 5.371,
            ic_july: 5.655,
            ic_august: 6.387,
            ic_september: 6.673,
            ic_october: 6.158,
            ic_november: 5.722,
            ic_december: 5.62,
            ic_yearly: 5.735,
          },
          {
            ic_uuid: "7a60319e-db59-46fe-a7ea-03e8bf32ebf5",
            ic_lat: -204152,
            ic_lon: -507636,
            ic_city: "Palmeira d'Oeste",
            ic_states: "SÃO PAULO",
            ic_january: 5.85,
            ic_february: 5.971,
            ic_march: 5.355,
            ic_april: 4.847,
            ic_may: 4.193,
            ic_june: 3.917,
            ic_july: 4.128,
            ic_august: 5.07,
            ic_september: 5.099,
            ic_october: 5.701,
            ic_november: 5.993,
            ic_december: 6.327,
            ic_yearly: 5.204,
          },
          {
            ic_uuid: "456619f9-9844-47a9-8b57-09bebe278fd0",
            ic_lat: -94113,
            ic_lon: -366324,
            ic_city: "Palmeira dos Índios",
            ic_states: "ALAGOAS",
            ic_january: 6.048,
            ic_february: 5.875,
            ic_march: 5.923,
            ic_april: 5.216,
            ic_may: 4.32,
            ic_june: 3.928,
            ic_july: 4.064,
            ic_august: 4.744,
            ic_september: 5.61,
            ic_october: 5.961,
            ic_november: 6.52,
            ic_december: 6.397,
            ic_yearly: 5.384,
          },
          {
            ic_uuid: "0bfdf897-56d3-4293-8865-8fc231d17aab",
            ic_lat: -59713,
            ic_lon: -430564,
            ic_city: "Palmeirais",
            ic_states: "PIAUÍ",
            ic_january: 4.975,
            ic_february: 5.114,
            ic_march: 5.056,
            ic_april: 5.026,
            ic_may: 5.112,
            ic_june: 5.375,
            ic_july: 5.678,
            ic_august: 6.222,
            ic_september: 6.576,
            ic_october: 6.242,
            ic_november: 5.902,
            ic_december: 5.493,
            ic_yearly: 5.564,
          },
          {
            ic_uuid: "83d02fd3-fef2-4c56-b56a-505437d24604",
            ic_lat: -26466,
            ic_lon: -448974,
            ic_city: "Palmeirândia",
            ic_states: "MARANHÃO",
            ic_january: 4.68,
            ic_february: 4.717,
            ic_march: 4.661,
            ic_april: 4.703,
            ic_may: 4.752,
            ic_june: 5.034,
            ic_july: 5.194,
            ic_august: 5.664,
            ic_september: 5.839,
            ic_october: 5.586,
            ic_november: 5.34,
            ic_december: 5.01,
            ic_yearly: 5.098,
          },
          {
            ic_uuid: "faa3ca30-e723-409b-93cb-ca01f08cd156",
            ic_lat: -78479,
            ic_lon: -479261,
            ic_city: "Palmeirante",
            ic_states: "TOCANTINS",
            ic_january: 4.848,
            ic_february: 4.933,
            ic_march: 4.925,
            ic_april: 4.878,
            ic_may: 5.059,
            ic_june: 5.197,
            ic_july: 5.309,
            ic_august: 5.873,
            ic_september: 5.873,
            ic_october: 5.262,
            ic_november: 4.877,
            ic_december: 4.809,
            ic_yearly: 5.154,
          },
          {
            ic_uuid: "437c030d-d43a-4514-b4d2-552a94b924a2",
            ic_lat: -125063,
            ic_lon: -415812,
            ic_city: "Palmeiras",
            ic_states: "BAHIA",
            ic_january: 6.034,
            ic_february: 6.077,
            ic_march: 5.695,
            ic_april: 4.881,
            ic_may: 4.369,
            ic_june: 4.042,
            ic_july: 4.218,
            ic_august: 4.859,
            ic_september: 5.705,
            ic_october: 5.858,
            ic_november: 5.653,
            ic_december: 6.091,
            ic_yearly: 5.29,
          },
          {
            ic_uuid: "862bed46-e525-41a1-b4a9-4005352fe990",
            ic_lat: -168049,
            ic_lon: -499244,
            ic_city: "Palmeiras de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.584,
            ic_february: 5.736,
            ic_march: 5.276,
            ic_april: 5.196,
            ic_may: 4.787,
            ic_june: 4.503,
            ic_july: 4.714,
            ic_august: 5.589,
            ic_september: 5.46,
            ic_october: 5.558,
            ic_november: 5.393,
            ic_december: 5.563,
            ic_yearly: 5.28,
          },
          {
            ic_uuid: "261dad9b-28e4-4fc1-87c9-ff0ec7c473d6",
            ic_lat: -66113,
            ic_lon: -475484,
            ic_city: "Palmeiras do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 4.742,
            ic_february: 4.87,
            ic_march: 4.872,
            ic_april: 4.899,
            ic_may: 4.976,
            ic_june: 5.276,
            ic_july: 5.425,
            ic_august: 5.924,
            ic_september: 5.856,
            ic_october: 5.195,
            ic_november: 4.777,
            ic_december: 4.74,
            ic_yearly: 5.129,
          },
          {
            ic_uuid: "fea3e89d-3c04-47d5-b328-1ed1d5d8bc42",
            ic_lat: -90113,
            ic_lon: -363245,
            ic_city: "Palmeirina",
            ic_states: "PERNAMBUCO",
            ic_january: 5.974,
            ic_february: 5.727,
            ic_march: 5.8,
            ic_april: 5.143,
            ic_may: 4.209,
            ic_june: 3.696,
            ic_july: 3.795,
            ic_august: 4.452,
            ic_september: 5.363,
            ic_october: 5.702,
            ic_november: 6.348,
            ic_december: 6.268,
            ic_yearly: 5.206,
          },
          {
            ic_uuid: "133a5c34-335b-4f0a-8b71-4b39dd73aced",
            ic_lat: -1304,
            ic_lon: -484134,
            ic_city: "Palmeirópolis",
            ic_states: "TOCANTINS",
            ic_january: 5.288,
            ic_february: 5.398,
            ic_march: 5.039,
            ic_april: 5.165,
            ic_may: 5.061,
            ic_june: 4.931,
            ic_july: 5.301,
            ic_august: 5.844,
            ic_september: 5.795,
            ic_october: 5.516,
            ic_november: 4.99,
            ic_december: 5.185,
            ic_yearly: 5.293,
          },
          {
            ic_uuid: "b00259f7-4d8b-436d-ab2a-12da08997da7",
            ic_lat: -173263,
            ic_lon: -484264,
            ic_city: "Palmelo",
            ic_states: "GOIÁS",
            ic_january: 5.667,
            ic_february: 5.775,
            ic_march: 5.253,
            ic_april: 5.204,
            ic_may: 4.76,
            ic_june: 4.546,
            ic_july: 4.792,
            ic_august: 5.661,
            ic_september: 5.411,
            ic_october: 5.697,
            ic_november: 5.397,
            ic_december: 5.667,
            ic_yearly: 5.319,
          },
          {
            ic_uuid: "833affa8-d5c3-41cb-a2fa-018660591cbb",
            ic_lat: -167929,
            ic_lon: -501656,
            ic_city: "Palminópolis",
            ic_states: "GOIÁS",
            ic_january: 5.614,
            ic_february: 5.735,
            ic_march: 5.264,
            ic_april: 5.136,
            ic_may: 4.78,
            ic_june: 4.498,
            ic_july: 4.712,
            ic_august: 5.516,
            ic_september: 5.472,
            ic_october: 5.558,
            ic_november: 5.534,
            ic_december: 5.569,
            ic_yearly: 5.282,
          },
          {
            ic_uuid: "b5a7960a-f885-4656-b12b-1b99d3f84b7a",
            ic_lat: -248858,
            ic_lon: -522033,
            ic_city: "Palmital",
            ic_states: "PARANÁ",
            ic_january: 6.093,
            ic_february: 5.619,
            ic_march: 5.215,
            ic_april: 4.4,
            ic_may: 3.402,
            ic_june: 3.028,
            ic_july: 3.257,
            ic_august: 4.232,
            ic_september: 4.436,
            ic_october: 5.234,
            ic_november: 6.089,
            ic_december: 6.289,
            ic_yearly: 4.775,
          },
          {
            ic_uuid: "86ca1157-3186-4c53-912e-34cec725a742",
            ic_lat: -227863,
            ic_lon: -502184,
            ic_city: "Palmital",
            ic_states: "SÃO PAULO",
            ic_january: 5.744,
            ic_february: 5.8,
            ic_march: 5.158,
            ic_april: 4.665,
            ic_may: 3.759,
            ic_june: 3.469,
            ic_july: 3.675,
            ic_august: 4.594,
            ic_september: 4.763,
            ic_october: 5.371,
            ic_november: 5.905,
            ic_december: 6.313,
            ic_yearly: 4.934,
          },
          {
            ic_uuid: "fdaffbda-f989-48b7-bb35-7fc21c5b6f81",
            ic_lat: -2736,
            ic_lon: -535585,
            ic_city: "Palmitinho",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.462,
            ic_february: 5.853,
            ic_march: 5.169,
            ic_april: 4.08,
            ic_may: 3.109,
            ic_june: 2.57,
            ic_july: 2.903,
            ic_august: 3.719,
            ic_september: 3.986,
            ic_october: 5.193,
            ic_november: 6.202,
            ic_december: 6.596,
            ic_yearly: 4.654,
          },
          {
            ic_uuid: "b8444756-3436-4d82-8750-298a82acfcb9",
            ic_lat: -270707,
            ic_lon: -531591,
            ic_city: "Palmitos",
            ic_states: "SANTA CATARINA",
            ic_january: 6.244,
            ic_february: 5.809,
            ic_march: 5.112,
            ic_april: 4.065,
            ic_may: 3.099,
            ic_june: 2.589,
            ic_july: 2.91,
            ic_august: 3.768,
            ic_september: 4.006,
            ic_october: 5.127,
            ic_november: 6.13,
            ic_december: 6.516,
            ic_yearly: 4.615,
          },
          {
            ic_uuid: "cb20eb4b-7edf-41e1-9ef2-df166faaaa26",
            ic_lat: -167368,
            ic_lon: -404299,
            ic_city: "Palmópolis",
            ic_states: "MINAS GERAIS",
            ic_january: 5.881,
            ic_february: 6.004,
            ic_march: 5.279,
            ic_april: 4.561,
            ic_may: 3.821,
            ic_june: 3.46,
            ic_july: 3.588,
            ic_august: 4.261,
            ic_september: 4.995,
            ic_october: 5.139,
            ic_november: 5.054,
            ic_december: 5.965,
            ic_yearly: 4.834,
          },
          {
            ic_uuid: "515ac55f-4709-4925-b891-f57da3d3bf78",
            ic_lat: -242872,
            ic_lon: -538409,
            ic_city: "Palotina",
            ic_states: "PARANÁ",
            ic_january: 6.18,
            ic_february: 5.734,
            ic_march: 5.311,
            ic_april: 4.4,
            ic_may: 3.445,
            ic_june: 3.079,
            ic_july: 3.236,
            ic_august: 4.235,
            ic_september: 4.541,
            ic_october: 5.312,
            ic_november: 6.148,
            ic_december: 6.552,
            ic_yearly: 4.848,
          },
          {
            ic_uuid: "509e4fd5-af00-4a24-a900-a466d5cf86fa",
            ic_lat: -18177,
            ic_lon: -493539,
            ic_city: "Panamá",
            ic_states: "GOIÁS",
            ic_january: 5.547,
            ic_february: 5.687,
            ic_march: 5.097,
            ic_april: 5.062,
            ic_may: 4.626,
            ic_june: 4.355,
            ic_july: 4.577,
            ic_august: 5.437,
            ic_september: 5.277,
            ic_october: 5.542,
            ic_november: 5.536,
            ic_december: 5.721,
            ic_yearly: 5.205,
          },
          {
            ic_uuid: "cff7c064-b133-40e7-bf37-e4ad70b86cb4",
            ic_lat: -282838,
            ic_lon: -535027,
            ic_city: "Panambi",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.378,
            ic_february: 5.935,
            ic_march: 5.108,
            ic_april: 4.031,
            ic_may: 3.061,
            ic_june: 2.51,
            ic_july: 2.805,
            ic_august: 3.595,
            ic_september: 3.931,
            ic_october: 5.114,
            ic_november: 6.223,
            ic_december: 6.629,
            ic_yearly: 4.61,
          },
          {
            ic_uuid: "bacdcf5b-8999-4ff3-9bc1-1b90677a0bd5",
            ic_lat: -192234,
            ic_lon: -408538,
            ic_city: "Pancas",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.877,
            ic_february: 6.031,
            ic_march: 5.165,
            ic_april: 4.473,
            ic_may: 3.816,
            ic_june: 3.554,
            ic_july: 3.663,
            ic_august: 4.231,
            ic_september: 4.75,
            ic_october: 4.942,
            ic_november: 4.855,
            ic_december: 5.585,
            ic_yearly: 4.745,
          },
          {
            ic_uuid: "a4b75596-ebd4-409d-9def-bf42bd0fb5e0",
            ic_lat: -86646,
            ic_lon: -360071,
            ic_city: "Panelas",
            ic_states: "PERNAMBUCO",
            ic_january: 5.889,
            ic_february: 5.72,
            ic_march: 5.712,
            ic_april: 5.078,
            ic_may: 4.332,
            ic_june: 3.928,
            ic_july: 3.986,
            ic_august: 4.656,
            ic_september: 5.401,
            ic_october: 5.729,
            ic_november: 6.186,
            ic_december: 6.101,
            ic_yearly: 5.227,
          },
          {
            ic_uuid: "ce9d214a-1541-4b16-bea4-8f836042ddfb",
            ic_lat: -213544,
            ic_lon: -518566,
            ic_city: "Panorama",
            ic_states: "SÃO PAULO",
            ic_january: 5.947,
            ic_february: 6.097,
            ic_march: 5.49,
            ic_april: 4.837,
            ic_may: 4.003,
            ic_june: 3.632,
            ic_july: 3.842,
            ic_august: 4.698,
            ic_september: 4.833,
            ic_october: 5.58,
            ic_november: 6.073,
            ic_december: 6.371,
            ic_yearly: 5.117,
          },
          {
            ic_uuid: "c8ea43cb-4fa0-4793-bc6d-7a90c655da4a",
            ic_lat: -301906,
            ic_lon: -523734,
            ic_city: "Pantano Grande",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.485,
            ic_february: 5.952,
            ic_march: 4.946,
            ic_april: 3.802,
            ic_may: 2.78,
            ic_june: 2.313,
            ic_july: 2.527,
            ic_august: 3.225,
            ic_september: 3.724,
            ic_october: 4.908,
            ic_november: 6.231,
            ic_december: 6.756,
            ic_yearly: 4.471,
          },
          {
            ic_uuid: "848cb100-5eb3-4207-b6ab-24bf1245e648",
            ic_lat: -97415,
            ic_lon: -374407,
            ic_city: "Pão de Açúcar",
            ic_states: "ALAGOAS",
            ic_january: 6.201,
            ic_february: 6.071,
            ic_march: 5.984,
            ic_april: 5.326,
            ic_may: 4.435,
            ic_june: 4.13,
            ic_july: 4.222,
            ic_august: 4.848,
            ic_september: 5.568,
            ic_october: 5.863,
            ic_november: 6.425,
            ic_december: 6.287,
            ic_yearly: 5.447,
          },
          {
            ic_uuid: "81497198-15d3-47ef-87dc-6fa838691795",
            ic_lat: -194424,
            ic_lon: -447472,
            ic_city: "Papagaios",
            ic_states: "MINAS GERAIS",
            ic_january: 5.957,
            ic_february: 6.168,
            ic_march: 5.242,
            ic_april: 5.027,
            ic_may: 4.472,
            ic_june: 4.202,
            ic_july: 4.545,
            ic_august: 5.368,
            ic_september: 5.649,
            ic_october: 5.659,
            ic_november: 5.397,
            ic_december: 5.806,
            ic_yearly: 5.291,
          },
          {
            ic_uuid: "33cb3c8c-6e52-42ae-83cb-3ece3d23d200",
            ic_lat: -264085,
            ic_lon: -501395,
            ic_city: "Papanduva",
            ic_states: "SANTA CATARINA",
            ic_january: 5.332,
            ic_february: 5.035,
            ic_march: 4.498,
            ic_april: 3.533,
            ic_may: 2.859,
            ic_june: 2.457,
            ic_july: 2.675,
            ic_august: 3.633,
            ic_september: 3.733,
            ic_october: 4.304,
            ic_november: 5.303,
            ic_december: 5.537,
            ic_yearly: 4.075,
          },
          {
            ic_uuid: "b8e3f99c-b975-473a-953d-6ab127cd0caa",
            ic_lat: -71034,
            ic_lon: -417004,
            ic_city: "Paquetá",
            ic_states: "PIAUÍ",
            ic_january: 5.434,
            ic_february: 5.44,
            ic_march: 5.48,
            ic_april: 5.374,
            ic_may: 5.249,
            ic_june: 5.224,
            ic_july: 5.642,
            ic_august: 6.287,
            ic_september: 6.726,
            ic_october: 6.547,
            ic_november: 6.345,
            ic_december: 5.862,
            ic_yearly: 5.801,
          },
          {
            ic_uuid: "cccffed9-e4dd-4827-b0d5-02b55c02af2e",
            ic_lat: -198539,
            ic_lon: -446117,
            ic_city: "Pará de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.788,
            ic_february: 6.051,
            ic_march: 5.092,
            ic_april: 4.91,
            ic_may: 4.281,
            ic_june: 4.127,
            ic_july: 4.376,
            ic_august: 5.199,
            ic_september: 5.503,
            ic_october: 5.553,
            ic_november: 5.336,
            ic_december: 5.607,
            ic_yearly: 5.152,
          },
          {
            ic_uuid: "657197aa-7f7d-4834-b5ca-d50de5ed5b48",
            ic_lat: -226083,
            ic_lon: -437112,
            ic_city: "Paracambi",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.774,
            ic_february: 6.114,
            ic_march: 4.969,
            ic_april: 4.37,
            ic_may: 3.591,
            ic_june: 3.355,
            ic_july: 3.39,
            ic_august: 4.245,
            ic_september: 4.421,
            ic_october: 4.89,
            ic_november: 4.928,
            ic_december: 5.643,
            ic_yearly: 4.641,
          },
          {
            ic_uuid: "4e686e3d-4157-42de-be75-393c3af8b23e",
            ic_lat: -172256,
            ic_lon: -468714,
            ic_city: "Paracatu",
            ic_states: "MINAS GERAIS",
            ic_january: 5.894,
            ic_february: 6.192,
            ic_march: 5.3,
            ic_april: 5.286,
            ic_may: 4.817,
            ic_june: 4.574,
            ic_july: 4.956,
            ic_august: 5.718,
            ic_september: 5.698,
            ic_october: 5.842,
            ic_november: 5.443,
            ic_december: 5.764,
            ic_yearly: 5.457,
          },
          {
            ic_uuid: "aa043d7b-73f5-47de-b5c4-e27895ac5077",
            ic_lat: -34148,
            ic_lon: -390303,
            ic_city: "Paracuru",
            ic_states: "CEARÁ",
            ic_january: 5.784,
            ic_february: 5.828,
            ic_march: 5.544,
            ic_april: 4.807,
            ic_may: 5.251,
            ic_june: 5.26,
            ic_july: 5.465,
            ic_august: 5.883,
            ic_september: 5.964,
            ic_october: 6.102,
            ic_november: 6.352,
            ic_december: 6.072,
            ic_yearly: 5.693,
          },
          {
            ic_uuid: "df7ef300-7832-42d0-911c-0ae0ff8d14c3",
            ic_lat: -30025,
            ic_lon: -473531,
            ic_city: "Paragominas",
            ic_states: "PARÁ",
            ic_january: 4.603,
            ic_february: 4.681,
            ic_march: 4.711,
            ic_april: 4.721,
            ic_may: 4.706,
            ic_june: 4.833,
            ic_july: 4.926,
            ic_august: 5.214,
            ic_september: 5.405,
            ic_october: 5.226,
            ic_november: 4.912,
            ic_december: 4.716,
            ic_yearly: 4.888,
          },
          {
            ic_uuid: "b46f23e9-dcc5-4834-a5c1-5241bcc098a6",
            ic_lat: -21547,
            ic_lon: -457378,
            ic_city: "Paraguaçu",
            ic_states: "MINAS GERAIS",
            ic_january: 5.632,
            ic_february: 5.828,
            ic_march: 4.957,
            ic_april: 4.675,
            ic_may: 3.994,
            ic_june: 3.719,
            ic_july: 3.98,
            ic_august: 4.869,
            ic_september: 5.082,
            ic_october: 5.424,
            ic_november: 5.362,
            ic_december: 5.73,
            ic_yearly: 4.938,
          },
          {
            ic_uuid: "eeb78367-4159-4e1e-a163-8dea71f3a245",
            ic_lat: -22413,
            ic_lon: -505759,
            ic_city: "Paraguaçu Paulista",
            ic_states: "SÃO PAULO",
            ic_january: 5.77,
            ic_february: 5.814,
            ic_march: 5.299,
            ic_april: 4.72,
            ic_may: 3.853,
            ic_june: 3.574,
            ic_july: 3.762,
            ic_august: 4.729,
            ic_september: 4.827,
            ic_october: 5.469,
            ic_november: 5.966,
            ic_december: 6.285,
            ic_yearly: 5.005,
          },
          {
            ic_uuid: "51581b2a-afb4-40e1-bcbe-4c6832e4fa26",
            ic_lat: -285969,
            ic_lon: -517901,
            ic_city: "Paraí",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.107,
            ic_february: 5.709,
            ic_march: 4.878,
            ic_april: 3.945,
            ic_may: 2.901,
            ic_june: 2.467,
            ic_july: 2.774,
            ic_august: 3.499,
            ic_september: 3.755,
            ic_october: 4.865,
            ic_november: 6.122,
            ic_december: 6.469,
            ic_yearly: 4.458,
          },
          {
            ic_uuid: "fd3aea23-be79-4b4d-851d-551dd5858bfe",
            ic_lat: -221632,
            ic_lon: -432956,
            ic_city: "Paraíba do Sul",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.863,
            ic_february: 6.107,
            ic_march: 5.099,
            ic_april: 4.443,
            ic_may: 3.638,
            ic_june: 3.388,
            ic_july: 3.548,
            ic_august: 4.361,
            ic_september: 4.669,
            ic_october: 5.053,
            ic_november: 5.051,
            ic_december: 5.792,
            ic_yearly: 4.751,
          },
          {
            ic_uuid: "c824b128-b1b3-4a8b-8564-a2d92879b079",
            ic_lat: -6432,
            ic_lon: -439895,
            ic_city: "Paraibano",
            ic_states: "MARANHÃO",
            ic_january: 4.801,
            ic_february: 4.925,
            ic_march: 4.947,
            ic_april: 4.909,
            ic_may: 5.059,
            ic_june: 5.353,
            ic_july: 5.66,
            ic_august: 6.292,
            ic_september: 6.6,
            ic_october: 6.017,
            ic_november: 5.576,
            ic_december: 5.141,
            ic_yearly: 5.44,
          },
          {
            ic_uuid: "e0fa3fcf-c4ab-4e0f-a19d-9bc13e3f0951",
            ic_lat: -233877,
            ic_lon: -456643,
            ic_city: "Paraibuna",
            ic_states: "SÃO PAULO",
            ic_january: 5.675,
            ic_february: 5.882,
            ic_march: 4.903,
            ic_april: 4.388,
            ic_may: 3.499,
            ic_june: 3.253,
            ic_july: 3.341,
            ic_august: 4.279,
            ic_september: 4.36,
            ic_october: 5.043,
            ic_november: 5.348,
            ic_december: 5.946,
            ic_yearly: 4.66,
          },
          {
            ic_uuid: "6ad8c425-3664-4bca-b81f-9c5ee8c6399b",
            ic_lat: -34384,
            ic_lon: -391482,
            ic_city: "Paraipaba",
            ic_states: "CEARÁ",
            ic_january: 5.732,
            ic_february: 5.755,
            ic_march: 5.467,
            ic_april: 4.754,
            ic_may: 5.162,
            ic_june: 5.207,
            ic_july: 5.426,
            ic_august: 5.9,
            ic_september: 6.145,
            ic_october: 6.208,
            ic_november: 6.375,
            ic_december: 6.08,
            ic_yearly: 5.684,
          },
          {
            ic_uuid: "bf34d864-b55b-421e-bffa-42e343cee168",
            ic_lat: -266205,
            ic_lon: -536721,
            ic_city: "Paraíso",
            ic_states: "SANTA CATARINA",
            ic_january: 6.308,
            ic_february: 5.789,
            ic_march: 5.116,
            ic_april: 4.099,
            ic_may: 3.173,
            ic_june: 2.716,
            ic_july: 2.98,
            ic_august: 3.858,
            ic_september: 4.138,
            ic_october: 5.146,
            ic_november: 6.034,
            ic_december: 6.377,
            ic_yearly: 4.644,
          },
          {
            ic_uuid: "03ad9f15-59fa-4bae-a389-327aa7fc5740",
            ic_lat: -210164,
            ic_lon: -487765,
            ic_city: "Paraíso",
            ic_states: "SÃO PAULO",
            ic_january: 5.791,
            ic_february: 5.937,
            ic_march: 5.203,
            ic_april: 4.781,
            ic_may: 4.116,
            ic_june: 3.905,
            ic_july: 4.155,
            ic_august: 5.02,
            ic_september: 5.077,
            ic_october: 5.592,
            ic_november: 5.869,
            ic_december: 6.136,
            ic_yearly: 5.132,
          },
          {
            ic_uuid: "ce0a4556-a608-44a9-a960-1db233580256",
            ic_lat: -190235,
            ic_lon: -530129,
            ic_city: "Paraíso das Águas",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.785,
            ic_february: 5.698,
            ic_march: 5.3,
            ic_april: 4.964,
            ic_may: 4.36,
            ic_june: 4.11,
            ic_july: 4.268,
            ic_august: 5.171,
            ic_september: 5.095,
            ic_october: 5.427,
            ic_november: 5.668,
            ic_december: 6.015,
            ic_yearly: 5.155,
          },
          {
            ic_uuid: "1036c8de-181a-4f88-9eae-68238a091e77",
            ic_lat: -232829,
            ic_lon: -526059,
            ic_city: "Paraíso do Norte",
            ic_states: "PARANÁ",
            ic_january: 6.016,
            ic_february: 5.852,
            ic_march: 5.371,
            ic_april: 4.605,
            ic_may: 3.614,
            ic_june: 3.266,
            ic_july: 3.491,
            ic_august: 4.445,
            ic_september: 4.663,
            ic_october: 5.435,
            ic_november: 6.136,
            ic_december: 6.432,
            ic_yearly: 4.944,
          },
          {
            ic_uuid: "42ae77fb-64c6-433a-8359-cded01ce7a43",
            ic_lat: -29666,
            ic_lon: -531417,
            ic_city: "Paraíso do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.357,
            ic_february: 5.867,
            ic_march: 4.967,
            ic_april: 3.808,
            ic_may: 2.773,
            ic_june: 2.315,
            ic_july: 2.555,
            ic_august: 3.298,
            ic_september: 3.676,
            ic_october: 4.88,
            ic_november: 6.228,
            ic_december: 6.747,
            ic_yearly: 4.456,
          },
          {
            ic_uuid: "bd78693b-4ac8-4571-9f47-cd14a2937b67",
            ic_lat: -101754,
            ic_lon: -488827,
            ic_city: "Paraíso do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 4.946,
            ic_february: 4.988,
            ic_march: 4.622,
            ic_april: 4.84,
            ic_may: 4.974,
            ic_june: 5.082,
            ic_july: 5.303,
            ic_august: 6.032,
            ic_september: 5.695,
            ic_october: 5.26,
            ic_november: 5.035,
            ic_december: 4.934,
            ic_yearly: 5.143,
          },
          {
            ic_uuid: "2ae04cac-3191-4933-802a-2516a712a1f5",
            ic_lat: -225544,
            ic_lon: -457807,
            ic_city: "Paraisópolis",
            ic_states: "MINAS GERAIS",
            ic_january: 5.315,
            ic_february: 5.635,
            ic_march: 4.9,
            ic_april: 4.55,
            ic_may: 3.813,
            ic_june: 3.559,
            ic_july: 3.767,
            ic_august: 4.681,
            ic_september: 4.9,
            ic_october: 5.365,
            ic_november: 5.379,
            ic_december: 5.75,
            ic_yearly: 4.801,
          },
          {
            ic_uuid: "896711e0-325f-46dd-99ca-b1ff1f5d39db",
            ic_lat: -62081,
            ic_lon: -406909,
            ic_city: "Parambu",
            ic_states: "CEARÁ",
            ic_january: 5.288,
            ic_february: 5.29,
            ic_march: 5.369,
            ic_april: 5.047,
            ic_may: 4.973,
            ic_june: 5.027,
            ic_july: 5.435,
            ic_august: 6.248,
            ic_september: 6.552,
            ic_october: 6.383,
            ic_november: 6.222,
            ic_december: 5.623,
            ic_yearly: 5.622,
          },
          {
            ic_uuid: "edbc4846-c457-41bf-bc19-033b24f0340a",
            ic_lat: -134445,
            ic_lon: -422424,
            ic_city: "Paramirim",
            ic_states: "BAHIA",
            ic_january: 6.572,
            ic_february: 6.604,
            ic_march: 6.089,
            ic_april: 5.538,
            ic_may: 5.014,
            ic_june: 4.775,
            ic_july: 4.934,
            ic_august: 5.537,
            ic_september: 6.113,
            ic_october: 6.475,
            ic_november: 6.075,
            ic_december: 6.577,
            ic_yearly: 5.859,
          },
          {
            ic_uuid: "ee4c4722-b305-4db4-b87d-746d0f8acf5e",
            ic_lat: -40885,
            ic_lon: -39242,
            ic_city: "Paramoti",
            ic_states: "CEARÁ",
            ic_january: 5.231,
            ic_february: 5.387,
            ic_march: 5.28,
            ic_april: 4.924,
            ic_may: 5.184,
            ic_june: 5.051,
            ic_july: 5.325,
            ic_august: 6.074,
            ic_september: 6.546,
            ic_october: 6.448,
            ic_november: 6.237,
            ic_december: 5.635,
            ic_yearly: 5.61,
          },
          {
            ic_uuid: "75026e81-6352-4b9b-aeb0-91b4cfa85f1a",
            ic_lat: -64815,
            ic_lon: -383111,
            ic_city: "Paraná",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.037,
            ic_february: 6.02,
            ic_march: 6.014,
            ic_april: 5.656,
            ic_may: 5.206,
            ic_june: 4.965,
            ic_july: 5.281,
            ic_august: 6.051,
            ic_september: 6.462,
            ic_october: 6.602,
            ic_november: 6.708,
            ic_december: 6.203,
            ic_yearly: 5.934,
          },
          {
            ic_uuid: "1f59a0b3-8a9a-46a9-b797-b143bf51b8ca",
            ic_lat: -126172,
            ic_lon: -478738,
            ic_city: "Paranã",
            ic_states: "TOCANTINS",
            ic_january: 5.743,
            ic_february: 5.704,
            ic_march: 5.369,
            ic_april: 5.32,
            ic_may: 5.117,
            ic_june: 4.977,
            ic_july: 5.366,
            ic_august: 5.922,
            ic_september: 5.946,
            ic_october: 5.688,
            ic_november: 5.38,
            ic_december: 5.626,
            ic_yearly: 5.513,
          },
          {
            ic_uuid: "b6cd17b3-59a7-4ba6-bbac-065abe390228",
            ic_lat: -229302,
            ic_lon: -521554,
            ic_city: "Paranacity",
            ic_states: "PARANÁ",
            ic_january: 5.937,
            ic_february: 5.874,
            ic_march: 5.396,
            ic_april: 4.682,
            ic_may: 3.718,
            ic_june: 3.424,
            ic_july: 3.603,
            ic_august: 4.553,
            ic_september: 4.758,
            ic_october: 5.456,
            ic_november: 6.169,
            ic_december: 6.416,
            ic_yearly: 4.999,
          },
          {
            ic_uuid: "e9c35799-2eec-4261-a240-fa49cc47764e",
            ic_lat: -255166,
            ic_lon: -485229,
            ic_city: "Paranaguá",
            ic_states: "PARANÁ",
            ic_january: 5.293,
            ic_february: 5.19,
            ic_march: 4.372,
            ic_april: 3.628,
            ic_may: 2.98,
            ic_june: 2.535,
            ic_july: 2.54,
            ic_august: 3.245,
            ic_september: 3.224,
            ic_october: 3.75,
            ic_november: 4.791,
            ic_december: 5.301,
            ic_yearly: 3.904,
          },
          {
            ic_uuid: "614cf27c-0bf6-4f54-88b5-5dad5ed1ae1f",
            ic_lat: -196751,
            ic_lon: -511913,
            ic_city: "Paranaíba",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.698,
            ic_february: 5.962,
            ic_march: 5.438,
            ic_april: 5.038,
            ic_may: 4.315,
            ic_june: 4.029,
            ic_july: 4.261,
            ic_august: 5.186,
            ic_september: 5.12,
            ic_october: 5.619,
            ic_november: 5.908,
            ic_december: 6.187,
            ic_yearly: 5.23,
          },
          {
            ic_uuid: "852ee1bc-02cb-41cd-a7a2-ac5bd96453a7",
            ic_lat: -189145,
            ic_lon: -506543,
            ic_city: "Paranaiguara",
            ic_states: "GOIÁS",
            ic_january: 5.675,
            ic_february: 5.747,
            ic_march: 5.321,
            ic_april: 5.061,
            ic_may: 4.5,
            ic_june: 4.185,
            ic_july: 4.455,
            ic_august: 5.313,
            ic_september: 5.206,
            ic_october: 5.55,
            ic_november: 5.727,
            ic_december: 5.958,
            ic_yearly: 5.225,
          },
          {
            ic_uuid: "c7a96f81-bd19-40a2-a97b-375084adfeaf",
            ic_lat: -96588,
            ic_lon: -564791,
            ic_city: "Paranaíta",
            ic_states: "MATO GROSSO",
            ic_january: 4.729,
            ic_february: 4.704,
            ic_march: 4.577,
            ic_april: 4.446,
            ic_may: 4.48,
            ic_june: 4.663,
            ic_july: 4.789,
            ic_august: 5.289,
            ic_september: 5.056,
            ic_october: 5.133,
            ic_november: 4.925,
            ic_december: 4.889,
            ic_yearly: 4.807,
          },
          {
            ic_uuid: "bb12a4b4-3408-45ec-accf-71a667b33b46",
            ic_lat: -233867,
            ic_lon: -487218,
            ic_city: "Paranapanema",
            ic_states: "SÃO PAULO",
            ic_january: 5.597,
            ic_february: 5.786,
            ic_march: 5.112,
            ic_april: 4.462,
            ic_may: 3.546,
            ic_june: 3.246,
            ic_july: 3.426,
            ic_august: 4.398,
            ic_september: 4.545,
            ic_october: 5.227,
            ic_november: 5.821,
            ic_december: 6.205,
            ic_yearly: 4.781,
          },
          {
            ic_uuid: "1ee9c787-2cff-4798-8047-ffa73212d5ca",
            ic_lat: -226563,
            ic_lon: -520839,
            ic_city: "Paranapoema",
            ic_states: "PARANÁ",
            ic_january: 5.977,
            ic_february: 5.956,
            ic_march: 5.461,
            ic_april: 4.707,
            ic_may: 3.778,
            ic_june: 3.446,
            ic_july: 3.643,
            ic_august: 4.61,
            ic_september: 4.781,
            ic_october: 5.471,
            ic_november: 6.169,
            ic_december: 6.448,
            ic_yearly: 5.037,
          },
          {
            ic_uuid: "f29fc2fb-cee2-45fd-b101-6d039bf26a46",
            ic_lat: -201052,
            ic_lon: -50589,
            ic_city: "Paranapuã",
            ic_states: "SÃO PAULO",
            ic_january: 5.79,
            ic_february: 5.886,
            ic_march: 5.338,
            ic_april: 4.893,
            ic_may: 4.242,
            ic_june: 3.983,
            ic_july: 4.183,
            ic_august: 5.128,
            ic_september: 5.097,
            ic_october: 5.664,
            ic_november: 5.88,
            ic_december: 6.228,
            ic_yearly: 5.193,
          },
          {
            ic_uuid: "f53c7b23-70cc-494f-8f68-9242c9409bae",
            ic_lat: -89192,
            ic_lon: -366552,
            ic_city: "Paranatama",
            ic_states: "PERNAMBUCO",
            ic_january: 6.149,
            ic_february: 5.887,
            ic_march: 5.938,
            ic_april: 5.259,
            ic_may: 4.344,
            ic_june: 3.904,
            ic_july: 4.068,
            ic_august: 4.762,
            ic_september: 5.699,
            ic_october: 6.042,
            ic_november: 6.496,
            ic_december: 6.39,
            ic_yearly: 5.412,
          },
          {
            ic_uuid: "a45b13b3-571a-40ea-9a94-07df21ee8534",
            ic_lat: -144269,
            ic_lon: -540528,
            ic_city: "Paranatinga",
            ic_states: "MATO GROSSO",
            ic_january: 5.304,
            ic_february: 5.34,
            ic_march: 5.077,
            ic_april: 5.183,
            ic_may: 4.809,
            ic_june: 4.694,
            ic_july: 4.907,
            ic_august: 5.527,
            ic_september: 5.242,
            ic_october: 5.364,
            ic_november: 5.285,
            ic_december: 5.38,
            ic_yearly: 5.176,
          },
          {
            ic_uuid: "242206e6-3c87-4789-973a-de0d66564b70",
            ic_lat: -230821,
            ic_lon: -524622,
            ic_city: "Paranavaí",
            ic_states: "PARANÁ",
            ic_january: 5.996,
            ic_february: 5.868,
            ic_march: 5.387,
            ic_april: 4.616,
            ic_may: 3.651,
            ic_june: 3.345,
            ic_july: 3.552,
            ic_august: 4.483,
            ic_september: 4.724,
            ic_october: 5.442,
            ic_november: 6.165,
            ic_december: 6.421,
            ic_yearly: 4.971,
          },
          {
            ic_uuid: "367f3c2f-cc16-4bb5-98f5-53adadbe3a2a",
            ic_lat: -238915,
            ic_lon: -554295,
            ic_city: "Paranhos",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 6.137,
            ic_february: 5.716,
            ic_march: 5.327,
            ic_april: 4.302,
            ic_may: 3.384,
            ic_june: 2.974,
            ic_july: 3.141,
            ic_august: 4.152,
            ic_september: 4.513,
            ic_october: 5.309,
            ic_november: 6.014,
            ic_december: 6.546,
            ic_yearly: 4.793,
          },
          {
            ic_uuid: "44838abe-6d7c-4f49-8357-992475eb7642",
            ic_lat: -192737,
            ic_lon: -444047,
            ic_city: "Paraopeba",
            ic_states: "MINAS GERAIS",
            ic_january: 6.002,
            ic_february: 6.177,
            ic_march: 5.272,
            ic_april: 5.019,
            ic_may: 4.425,
            ic_june: 4.166,
            ic_july: 4.524,
            ic_august: 5.335,
            ic_september: 5.684,
            ic_october: 5.686,
            ic_november: 5.458,
            ic_december: 5.779,
            ic_yearly: 5.294,
          },
          {
            ic_uuid: "7fcf00b8-730f-42b9-93de-e98aea3f9ecb",
            ic_lat: -217797,
            ic_lon: -507953,
            ic_city: "Parapuã",
            ic_states: "SÃO PAULO",
            ic_january: 5.892,
            ic_february: 5.885,
            ic_march: 5.365,
            ic_april: 4.779,
            ic_may: 3.987,
            ic_june: 3.675,
            ic_july: 3.874,
            ic_august: 4.845,
            ic_september: 4.846,
            ic_october: 5.548,
            ic_november: 6.044,
            ic_december: 6.32,
            ic_yearly: 5.088,
          },
          {
            ic_uuid: "b572be08-ece3-4576-9e29-cc7fb2121378",
            ic_lat: -73182,
            ic_lon: -366562,
            ic_city: "Parari",
            ic_states: "PARAÍBA",
            ic_january: 5.921,
            ic_february: 5.939,
            ic_march: 6.023,
            ic_april: 5.553,
            ic_may: 4.916,
            ic_june: 4.389,
            ic_july: 4.604,
            ic_august: 5.353,
            ic_september: 6.039,
            ic_october: 6.237,
            ic_november: 6.416,
            ic_december: 6.13,
            ic_yearly: 5.627,
          },
          {
            ic_uuid: "fee288d0-0440-4579-9325-6d930376e57c",
            ic_lat: -126874,
            ic_lon: -431801,
            ic_city: "Paratinga",
            ic_states: "BAHIA",
            ic_january: 6.619,
            ic_february: 6.53,
            ic_march: 6.074,
            ic_april: 5.663,
            ic_may: 5.109,
            ic_june: 4.891,
            ic_july: 5.172,
            ic_august: 5.867,
            ic_september: 6.141,
            ic_october: 6.376,
            ic_november: 6.007,
            ic_december: 6.513,
            ic_yearly: 5.913,
          },
          {
            ic_uuid: "f35308a4-e757-484e-a655-a3a437ecb428",
            ic_lat: -232229,
            ic_lon: -44717,
            ic_city: "Paraty",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.021,
            ic_february: 5.319,
            ic_march: 4.329,
            ic_april: 3.926,
            ic_may: 3.331,
            ic_june: 3.128,
            ic_july: 3.129,
            ic_august: 3.892,
            ic_september: 3.911,
            ic_october: 4.189,
            ic_november: 4.321,
            ic_december: 4.975,
            ic_yearly: 4.122,
          },
          {
            ic_uuid: "8bb35f11-6478-40f1-bd40-aa542f2c1db2",
            ic_lat: -57773,
            ic_lon: -37102,
            ic_city: "Paraú",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.077,
            ic_february: 6.169,
            ic_march: 6.209,
            ic_april: 5.819,
            ic_may: 5.398,
            ic_june: 4.963,
            ic_july: 5.245,
            ic_august: 5.918,
            ic_september: 6.408,
            ic_october: 6.595,
            ic_november: 6.56,
            ic_december: 6.183,
            ic_yearly: 5.962,
          },
          {
            ic_uuid: "06127e25-b865-4200-a4f2-eb4f0184fc44",
            ic_lat: -60682,
            ic_lon: -499041,
            ic_city: "Parauapebas",
            ic_states: "PARÁ",
            ic_january: 4.497,
            ic_february: 4.564,
            ic_march: 4.49,
            ic_april: 4.497,
            ic_may: 4.706,
            ic_june: 4.949,
            ic_july: 5.138,
            ic_august: 5.6,
            ic_september: 5.225,
            ic_october: 4.8,
            ic_november: 4.495,
            ic_december: 4.327,
            ic_yearly: 4.774,
          },
          {
            ic_uuid: "ecbfb496-6006-4817-93e0-0baf5a05ccd1",
            ic_lat: -169467,
            ic_lon: -504488,
            ic_city: "Paraúna",
            ic_states: "GOIÁS",
            ic_january: 5.514,
            ic_february: 5.604,
            ic_march: 5.239,
            ic_april: 5.174,
            ic_may: 4.774,
            ic_june: 4.507,
            ic_july: 4.735,
            ic_august: 5.47,
            ic_september: 5.396,
            ic_october: 5.543,
            ic_november: 5.493,
            ic_december: 5.598,
            ic_yearly: 5.254,
          },
          {
            ic_uuid: "906f2433-3731-4131-b34d-59598f8eaa83",
            ic_lat: -52232,
            ic_lon: -358401,
            ic_city: "Parazinho",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.848,
            ic_february: 5.852,
            ic_march: 5.863,
            ic_april: 5.434,
            ic_may: 5.154,
            ic_june: 4.825,
            ic_july: 5.011,
            ic_august: 5.681,
            ic_september: 5.936,
            ic_october: 6.123,
            ic_november: 6.215,
            ic_december: 5.819,
            ic_yearly: 5.647,
          },
          {
            ic_uuid: "9c698a6c-4842-4874-85c8-7c22eb74d1db",
            ic_lat: -23081,
            ic_lon: -483739,
            ic_city: "Pardinho",
            ic_states: "SÃO PAULO",
            ic_january: 5.398,
            ic_february: 5.6,
            ic_march: 4.931,
            ic_april: 4.505,
            ic_may: 3.648,
            ic_june: 3.427,
            ic_july: 3.547,
            ic_august: 4.527,
            ic_september: 4.686,
            ic_october: 5.247,
            ic_november: 5.619,
            ic_december: 5.97,
            ic_yearly: 4.759,
          },
          {
            ic_uuid: "77513559-fe27-42f0-af2c-7a00a5bf4ec6",
            ic_lat: -29637,
            ic_lon: -513979,
            ic_city: "Pareci Novo",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.174,
            ic_february: 5.764,
            ic_march: 4.822,
            ic_april: 3.778,
            ic_may: 2.815,
            ic_june: 2.341,
            ic_july: 2.533,
            ic_august: 3.225,
            ic_september: 3.542,
            ic_october: 4.603,
            ic_november: 6.108,
            ic_december: 6.522,
            ic_yearly: 4.352,
          },
          {
            ic_uuid: "fe350ff6-efd1-4f53-bc0b-6d3748325577",
            ic_lat: -121758,
            ic_lon: -616037,
            ic_city: "Parecis",
            ic_states: "RONDÔNIA",
            ic_january: 4.426,
            ic_february: 4.401,
            ic_march: 4.492,
            ic_april: 4.571,
            ic_may: 4.155,
            ic_june: 4.335,
            ic_july: 4.482,
            ic_august: 4.984,
            ic_september: 4.949,
            ic_october: 5.134,
            ic_november: 5.008,
            ic_december: 4.815,
            ic_yearly: 4.646,
          },
          {
            ic_uuid: "b21f6e63-b3fb-415d-9439-4b60f4b7cffb",
            ic_lat: -66853,
            ic_lon: -366569,
            ic_city: "Parelhas",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.045,
            ic_february: 6.062,
            ic_march: 6.032,
            ic_april: 5.7,
            ic_may: 5.081,
            ic_june: 4.641,
            ic_july: 4.929,
            ic_august: 5.679,
            ic_september: 6.222,
            ic_october: 6.408,
            ic_november: 6.471,
            ic_december: 6.192,
            ic_yearly: 5.789,
          },
          {
            ic_uuid: "e2b93f0b-ebbf-4839-bc31-3a49e819a822",
            ic_lat: -92568,
            ic_lon: -379991,
            ic_city: "Pariconha",
            ic_states: "ALAGOAS",
            ic_january: 6.315,
            ic_february: 6.115,
            ic_march: 5.983,
            ic_april: 5.364,
            ic_may: 4.531,
            ic_june: 4.025,
            ic_july: 4.226,
            ic_august: 4.85,
            ic_september: 5.777,
            ic_october: 6.086,
            ic_november: 6.596,
            ic_december: 6.418,
            ic_yearly: 5.524,
          },
          {
            ic_uuid: "f550c08b-fdd7-4d3e-8bef-7eac5a935024",
            ic_lat: -26378,
            ic_lon: -567295,
            ic_city: "Parintins",
            ic_states: "AMAZONAS",
            ic_january: 4.244,
            ic_february: 4.268,
            ic_march: 4.163,
            ic_april: 3.916,
            ic_may: 4.053,
            ic_june: 4.597,
            ic_july: 4.544,
            ic_august: 5.267,
            ic_september: 5.193,
            ic_october: 5.367,
            ic_november: 5.185,
            ic_december: 4.62,
            ic_yearly: 4.618,
          },
          {
            ic_uuid: "d92e7595-9cbc-41ca-92ee-eb7e278e43d7",
            ic_lat: -106863,
            ic_lon: -378629,
            ic_city: "Paripiranga",
            ic_states: "BAHIA",
            ic_january: 6.112,
            ic_february: 5.827,
            ic_march: 5.762,
            ic_april: 5.084,
            ic_may: 4.253,
            ic_june: 3.866,
            ic_july: 4.033,
            ic_august: 4.554,
            ic_september: 5.279,
            ic_october: 5.59,
            ic_november: 6.148,
            ic_december: 6.118,
            ic_yearly: 5.219,
          },
          {
            ic_uuid: "1a3eca80-f613-41ec-a172-788474cd1be9",
            ic_lat: -94636,
            ic_lon: -355523,
            ic_city: "Paripueira",
            ic_states: "ALAGOAS",
            ic_january: 6.166,
            ic_february: 6.148,
            ic_march: 6.157,
            ic_april: 5.252,
            ic_may: 4.478,
            ic_june: 4.165,
            ic_july: 4.304,
            ic_august: 5.007,
            ic_september: 5.798,
            ic_october: 6.096,
            ic_november: 6.49,
            ic_december: 6.494,
            ic_yearly: 5.546,
          },
          {
            ic_uuid: "69ca8822-85aa-4891-824f-864c25463487",
            ic_lat: -24715,
            ic_lon: -478809,
            ic_city: "Pariquera-Açu",
            ic_states: "SÃO PAULO",
            ic_january: 5.389,
            ic_february: 5.41,
            ic_march: 4.51,
            ic_april: 3.873,
            ic_may: 3.083,
            ic_june: 2.667,
            ic_july: 2.66,
            ic_august: 3.32,
            ic_september: 3.475,
            ic_october: 3.959,
            ic_november: 4.879,
            ic_december: 5.534,
            ic_yearly: 4.063,
          },
          {
            ic_uuid: "2004134a-d3bd-4f72-b59a-3305a9caf041",
            ic_lat: -203024,
            ic_lon: -500213,
            ic_city: "Parisi",
            ic_states: "SÃO PAULO",
            ic_january: 5.824,
            ic_february: 5.931,
            ic_march: 5.295,
            ic_april: 4.918,
            ic_may: 4.227,
            ic_june: 3.925,
            ic_july: 4.201,
            ic_august: 5.069,
            ic_september: 5.118,
            ic_october: 5.63,
            ic_november: 5.905,
            ic_december: 6.183,
            ic_yearly: 5.186,
          },
          {
            ic_uuid: "6db36b95-530d-4590-85a2-47af88b16463",
            ic_lat: -10224,
            ic_lon: -446376,
            ic_city: "Parnaguá",
            ic_states: "PIAUÍ",
            ic_january: 5.996,
            ic_february: 5.807,
            ic_march: 5.626,
            ic_april: 5.495,
            ic_may: 5.478,
            ic_june: 5.3,
            ic_july: 5.571,
            ic_august: 6.2,
            ic_september: 6.452,
            ic_october: 6.228,
            ic_november: 5.888,
            ic_december: 5.995,
            ic_yearly: 5.836,
          },
          {
            ic_uuid: "83a3e858-5f58-4353-8037-31a15c72cc51",
            ic_lat: -29062,
            ic_lon: -417757,
            ic_city: "Parnaíba",
            ic_states: "PIAUÍ",
            ic_january: 5.594,
            ic_february: 5.531,
            ic_march: 5.365,
            ic_april: 5.045,
            ic_may: 5.154,
            ic_june: 5.287,
            ic_july: 5.453,
            ic_august: 5.841,
            ic_september: 5.719,
            ic_october: 5.768,
            ic_november: 6.18,
            ic_december: 5.992,
            ic_yearly: 5.577,
          },
          {
            ic_uuid: "82f13010-841d-4143-8bbf-317240a38c79",
            ic_lat: -80916,
            ic_lon: -395782,
            ic_city: "Parnamirim",
            ic_states: "PERNAMBUCO",
            ic_january: 6.061,
            ic_february: 5.866,
            ic_march: 5.893,
            ic_april: 5.396,
            ic_may: 4.753,
            ic_june: 4.45,
            ic_july: 4.693,
            ic_august: 5.535,
            ic_september: 6.388,
            ic_october: 6.497,
            ic_november: 6.585,
            ic_december: 6.331,
            ic_yearly: 5.704,
          },
          {
            ic_uuid: "f4bc614b-843f-4afa-bd8d-670495ce52d2",
            ic_lat: -59116,
            ic_lon: -352713,
            ic_city: "Parnamirim",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.945,
            ic_february: 6.047,
            ic_march: 6.058,
            ic_april: 5.535,
            ic_may: 5.073,
            ic_june: 4.665,
            ic_july: 4.752,
            ic_august: 5.542,
            ic_september: 5.903,
            ic_october: 6.082,
            ic_november: 6.219,
            ic_december: 5.963,
            ic_yearly: 5.649,
          },
          {
            ic_uuid: "007ef5cd-db21-42ba-ae77-744e9c3155c9",
            ic_lat: -56839,
            ic_lon: -431069,
            ic_city: "Parnarama",
            ic_states: "MARANHÃO",
            ic_january: 4.948,
            ic_february: 5.149,
            ic_march: 5.092,
            ic_april: 5.052,
            ic_may: 5.027,
            ic_june: 5.343,
            ic_july: 5.603,
            ic_august: 6.215,
            ic_september: 6.518,
            ic_october: 6.225,
            ic_november: 5.809,
            ic_december: 5.529,
            ic_yearly: 5.542,
          },
          {
            ic_uuid: "2a73559d-b8cf-44ee-8e47-cd2d696268aa",
            ic_lat: -29629,
            ic_lon: -508349,
            ic_city: "Parobé",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.063,
            ic_february: 5.553,
            ic_march: 4.648,
            ic_april: 3.756,
            ic_may: 2.8,
            ic_june: 2.351,
            ic_july: 2.553,
            ic_august: 3.245,
            ic_september: 3.524,
            ic_october: 4.541,
            ic_november: 5.997,
            ic_december: 6.364,
            ic_yearly: 4.283,
          },
          {
            ic_uuid: "ff2f9154-ef56-42a3-a3ff-f4d03cb753db",
            ic_lat: -64306,
            ic_lon: -356445,
            ic_city: "Passa e Fica",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.755,
            ic_february: 5.809,
            ic_march: 5.866,
            ic_april: 5.44,
            ic_may: 4.919,
            ic_june: 4.522,
            ic_july: 4.692,
            ic_august: 5.344,
            ic_september: 5.726,
            ic_october: 5.891,
            ic_november: 6.061,
            ic_december: 5.733,
            ic_yearly: 5.48,
          },
          {
            ic_uuid: "af3a5b70-5365-40d6-91cf-10dc838b7b43",
            ic_lat: -223876,
            ic_lon: -449713,
            ic_city: "Passa Quatro",
            ic_states: "MINAS GERAIS",
            ic_january: 5.324,
            ic_february: 5.682,
            ic_march: 4.894,
            ic_april: 4.511,
            ic_may: 3.877,
            ic_june: 3.584,
            ic_july: 3.776,
            ic_august: 4.686,
            ic_september: 4.973,
            ic_october: 5.381,
            ic_november: 5.234,
            ic_december: 5.705,
            ic_yearly: 4.802,
          },
          {
            ic_uuid: "fe87ebf6-35ea-4cf1-afae-0cf13e8146f8",
            ic_lat: -294474,
            ic_lon: -529653,
            ic_city: "Passa Sete",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.219,
            ic_february: 5.914,
            ic_march: 5.025,
            ic_april: 3.893,
            ic_may: 2.878,
            ic_june: 2.413,
            ic_july: 2.666,
            ic_august: 3.417,
            ic_september: 3.726,
            ic_october: 4.955,
            ic_november: 6.276,
            ic_december: 6.714,
            ic_yearly: 4.508,
          },
          {
            ic_uuid: "f2db53ab-0142-41cd-970f-e6c2ee99e08e",
            ic_lat: -206544,
            ic_lon: -44493,
            ic_city: "Passa Tempo",
            ic_states: "MINAS GERAIS",
            ic_january: 5.654,
            ic_february: 5.869,
            ic_march: 4.989,
            ic_april: 4.668,
            ic_may: 4.062,
            ic_june: 3.935,
            ic_july: 4.143,
            ic_august: 5.054,
            ic_september: 5.271,
            ic_october: 5.467,
            ic_november: 5.115,
            ic_december: 5.646,
            ic_yearly: 4.989,
          },
          {
            ic_uuid: "a6e3b835-b1a6-4cd5-b212-99aeb145ce4e",
            ic_lat: -193514,
            ic_lon: -431387,
            ic_city: "Passabém",
            ic_states: "MINAS GERAIS",
            ic_january: 5.723,
            ic_february: 5.986,
            ic_march: 5.032,
            ic_april: 4.469,
            ic_may: 3.882,
            ic_june: 3.687,
            ic_july: 3.879,
            ic_august: 4.686,
            ic_september: 5.013,
            ic_october: 5.155,
            ic_november: 4.832,
            ic_december: 5.613,
            ic_yearly: 4.83,
          },
          {
            ic_uuid: "f5f82230-a3ef-4001-b173-7124e605cf11",
            ic_lat: -71369,
            ic_lon: -370494,
            ic_city: "Passagem",
            ic_states: "PARAÍBA",
            ic_january: 6.107,
            ic_february: 6.183,
            ic_march: 6.165,
            ic_april: 5.808,
            ic_may: 5.147,
            ic_june: 4.626,
            ic_july: 4.995,
            ic_august: 5.785,
            ic_september: 6.408,
            ic_october: 6.502,
            ic_november: 6.592,
            ic_december: 6.27,
            ic_yearly: 5.882,
          },
          {
            ic_uuid: "dc0920a2-1cd9-4be6-81dc-a8d14af82342",
            ic_lat: -62731,
            ic_lon: -353703,
            ic_city: "Passagem",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.859,
            ic_february: 6.002,
            ic_march: 6.085,
            ic_april: 5.432,
            ic_may: 4.939,
            ic_june: 4.446,
            ic_july: 4.55,
            ic_august: 5.355,
            ic_september: 5.748,
            ic_october: 6.028,
            ic_november: 6.274,
            ic_december: 5.914,
            ic_yearly: 5.553,
          },
          {
            ic_uuid: "c4e87305-aa62-483f-bca8-19322e7cbb77",
            ic_lat: -61779,
            ic_lon: -437759,
            ic_city: "Passagem Franca",
            ic_states: "MARANHÃO",
            ic_january: 4.782,
            ic_february: 4.965,
            ic_march: 4.896,
            ic_april: 4.941,
            ic_may: 5.057,
            ic_june: 5.363,
            ic_july: 5.666,
            ic_august: 6.316,
            ic_september: 6.544,
            ic_october: 6.061,
            ic_november: 5.582,
            ic_december: 5.134,
            ic_yearly: 5.442,
          },
          {
            ic_uuid: "270a9b5e-da88-422f-9135-ad38bf42fecf",
            ic_lat: -58571,
            ic_lon: -424412,
            ic_city: "Passagem Franca do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.083,
            ic_february: 5.26,
            ic_march: 5.302,
            ic_april: 5.187,
            ic_may: 5.246,
            ic_june: 5.387,
            ic_july: 5.638,
            ic_august: 6.247,
            ic_september: 6.635,
            ic_october: 6.405,
            ic_november: 6.133,
            ic_december: 5.702,
            ic_yearly: 5.685,
          },
          {
            ic_uuid: "237e0516-f8ad-483f-9a92-d97ef3056ca9",
            ic_lat: -222071,
            ic_lon: -442369,
            ic_city: "Passa-Vinte",
            ic_states: "MINAS GERAIS",
            ic_january: 5.223,
            ic_february: 5.602,
            ic_march: 4.65,
            ic_april: 4.167,
            ic_may: 3.597,
            ic_june: 3.465,
            ic_july: 3.513,
            ic_august: 4.446,
            ic_september: 4.509,
            ic_october: 4.689,
            ic_november: 4.6,
            ic_december: 5.274,
            ic_yearly: 4.478,
          },
          {
            ic_uuid: "2c744f4f-a9ea-4192-a7ae-a66e41cf1197",
            ic_lat: -79776,
            ic_lon: -355839,
            ic_city: "Passira",
            ic_states: "PERNAMBUCO",
            ic_january: 5.678,
            ic_february: 5.634,
            ic_march: 5.667,
            ic_april: 5.092,
            ic_may: 4.396,
            ic_june: 4.075,
            ic_july: 4.19,
            ic_august: 4.796,
            ic_september: 5.408,
            ic_october: 5.616,
            ic_november: 5.886,
            ic_december: 5.852,
            ic_yearly: 5.191,
          },
          {
            ic_uuid: "b562c34e-7d79-4c24-8a86-34afa0e822a2",
            ic_lat: -92396,
            ic_lon: -354882,
            ic_city: "Passo de Camaragibe",
            ic_states: "ALAGOAS",
            ic_january: 5.968,
            ic_february: 5.822,
            ic_march: 5.933,
            ic_april: 5.004,
            ic_may: 4.318,
            ic_june: 3.982,
            ic_july: 4.082,
            ic_august: 4.718,
            ic_september: 5.423,
            ic_october: 5.748,
            ic_november: 6.201,
            ic_december: 6.213,
            ic_yearly: 5.284,
          },
          {
            ic_uuid: "6c7ce856-02ad-456b-9831-82a4a4bbd340",
            ic_lat: -293104,
            ic_lon: -497226,
            ic_city: "Passo de Torres",
            ic_states: "SANTA CATARINA",
            ic_january: 5.85,
            ic_february: 5.422,
            ic_march: 4.581,
            ic_april: 3.839,
            ic_may: 2.964,
            ic_june: 2.444,
            ic_july: 2.658,
            ic_august: 3.336,
            ic_september: 3.525,
            ic_october: 4.342,
            ic_november: 5.735,
            ic_december: 6.082,
            ic_yearly: 4.232,
          },
          {
            ic_uuid: "9b20b3f1-0481-4a4b-89ed-4bcbae3e5554",
            ic_lat: -297485,
            ic_lon: -522752,
            ic_city: "Passo do Sobrado",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.149,
            ic_february: 5.783,
            ic_march: 4.825,
            ic_april: 3.788,
            ic_may: 2.781,
            ic_june: 2.305,
            ic_july: 2.527,
            ic_august: 3.219,
            ic_september: 3.586,
            ic_october: 4.683,
            ic_november: 6.107,
            ic_december: 6.549,
            ic_yearly: 4.359,
          },
          {
            ic_uuid: "487d0bf0-e4ea-4c9e-bf58-4ab743b85d76",
            ic_lat: -28258,
            ic_lon: -524096,
            ic_city: "Passo Fundo",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.266,
            ic_february: 5.807,
            ic_march: 4.945,
            ic_april: 4.004,
            ic_may: 3.008,
            ic_june: 2.512,
            ic_july: 2.825,
            ic_august: 3.589,
            ic_september: 3.837,
            ic_october: 5.042,
            ic_november: 6.221,
            ic_december: 6.582,
            ic_yearly: 4.553,
          },
          {
            ic_uuid: "4c8ea499-2cd1-442a-a845-ec61e9513f27",
            ic_lat: -207197,
            ic_lon: -466094,
            ic_city: "Passos",
            ic_states: "MINAS GERAIS",
            ic_january: 5.787,
            ic_february: 5.97,
            ic_march: 5.126,
            ic_april: 4.845,
            ic_may: 4.23,
            ic_june: 4.023,
            ic_july: 4.242,
            ic_august: 5.065,
            ic_september: 5.2,
            ic_october: 5.626,
            ic_november: 5.618,
            ic_december: 5.826,
            ic_yearly: 5.129,
          },
          {
            ic_uuid: "43b2fc5d-3e82-4234-a00e-bec8acb6f49a",
            ic_lat: -267834,
            ic_lon: -520572,
            ic_city: "Passos Maia",
            ic_states: "SANTA CATARINA",
            ic_january: 5.979,
            ic_february: 5.502,
            ic_march: 5.042,
            ic_april: 4.094,
            ic_may: 3.149,
            ic_june: 2.742,
            ic_july: 3.011,
            ic_august: 3.883,
            ic_september: 4.103,
            ic_october: 5.059,
            ic_november: 6.044,
            ic_december: 6.27,
            ic_yearly: 4.573,
          },
          {
            ic_uuid: "312451bc-4256-4002-add4-81f5e862ed68",
            ic_lat: -65989,
            ic_lon: -440845,
            ic_city: "Pastos Bons",
            ic_states: "MARANHÃO",
            ic_january: 4.886,
            ic_february: 4.986,
            ic_march: 4.973,
            ic_april: 5.013,
            ic_may: 5.11,
            ic_june: 5.387,
            ic_july: 5.628,
            ic_august: 6.209,
            ic_september: 6.594,
            ic_october: 6.069,
            ic_november: 5.545,
            ic_december: 5.204,
            ic_yearly: 5.467,
          },
          {
            ic_uuid: "6bdc5951-59c4-414b-becd-53a25cf7dbab",
            ic_lat: -160796,
            ic_lon: -440812,
            ic_city: "Patis",
            ic_states: "MINAS GERAIS",
            ic_january: 6.309,
            ic_february: 6.583,
            ic_march: 5.69,
            ic_april: 5.476,
            ic_may: 4.887,
            ic_june: 4.642,
            ic_july: 4.959,
            ic_august: 5.75,
            ic_september: 6.012,
            ic_october: 6.02,
            ic_november: 5.467,
            ic_december: 6.197,
            ic_yearly: 5.666,
          },
          {
            ic_uuid: "75cb6e90-41c1-4b38-bf91-0336657e6c40",
            ic_lat: -246251,
            ic_lon: -542286,
            ic_city: "Pato Bragado",
            ic_states: "PARANÁ",
            ic_january: 6.299,
            ic_february: 5.864,
            ic_march: 5.343,
            ic_april: 4.331,
            ic_may: 3.33,
            ic_june: 2.933,
            ic_july: 3.132,
            ic_august: 4.117,
            ic_september: 4.39,
            ic_october: 5.304,
            ic_november: 6.135,
            ic_december: 6.57,
            ic_yearly: 4.812,
          },
          {
            ic_uuid: "17882083-40fb-42de-a397-78885de2e6d7",
            ic_lat: -262297,
            ic_lon: -526711,
            ic_city: "Pato Branco",
            ic_states: "PARANÁ",
            ic_january: 6.272,
            ic_february: 5.647,
            ic_march: 5.173,
            ic_april: 4.188,
            ic_may: 3.251,
            ic_june: 2.824,
            ic_july: 3.082,
            ic_august: 4.085,
            ic_september: 4.206,
            ic_october: 5.158,
            ic_november: 6.063,
            ic_december: 6.335,
            ic_yearly: 4.69,
          },
          {
            ic_uuid: "a324be56-0e29-4a88-a342-17f425d7325e",
            ic_lat: -70178,
            ic_lon: -37275,
            ic_city: "Patos",
            ic_states: "PARAÍBA",
            ic_january: 6.235,
            ic_february: 6.199,
            ic_march: 6.285,
            ic_april: 5.891,
            ic_may: 5.162,
            ic_june: 4.735,
            ic_july: 5.054,
            ic_august: 5.836,
            ic_september: 6.389,
            ic_october: 6.548,
            ic_november: 6.586,
            ic_december: 6.351,
            ic_yearly: 5.939,
          },
          {
            ic_uuid: "2e5a729e-d46c-4dc9-bfc9-72087f122a88",
            ic_lat: -185782,
            ic_lon: -465134,
            ic_city: "Patos de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.688,
            ic_february: 5.969,
            ic_march: 5.126,
            ic_april: 5.034,
            ic_may: 4.658,
            ic_june: 4.41,
            ic_july: 4.722,
            ic_august: 5.686,
            ic_september: 5.716,
            ic_october: 5.669,
            ic_november: 5.306,
            ic_december: 5.607,
            ic_yearly: 5.299,
          },
          {
            ic_uuid: "5910a206-6711-41db-8235-2719a75bb682",
            ic_lat: -76785,
            ic_lon: -412445,
            ic_city: "Patos do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.785,
            ic_february: 5.65,
            ic_march: 5.705,
            ic_april: 5.343,
            ic_may: 5.149,
            ic_june: 5.14,
            ic_july: 5.508,
            ic_august: 6.208,
            ic_september: 6.629,
            ic_october: 6.516,
            ic_november: 6.434,
            ic_december: 6.114,
            ic_yearly: 5.848,
          },
          {
            ic_uuid: "a1529a2f-4bab-4226-93a5-d626c4d50a48",
            ic_lat: -189383,
            ic_lon: -469938,
            ic_city: "Patrocínio",
            ic_states: "MINAS GERAIS",
            ic_january: 5.603,
            ic_february: 5.894,
            ic_march: 5.04,
            ic_april: 4.974,
            ic_may: 4.666,
            ic_june: 4.374,
            ic_july: 4.708,
            ic_august: 5.628,
            ic_september: 5.6,
            ic_october: 5.628,
            ic_november: 5.313,
            ic_december: 5.533,
            ic_yearly: 5.247,
          },
          {
            ic_uuid: "8023671f-89ed-46f1-886d-2171cdab6b9b",
            ic_lat: -211549,
            ic_lon: -422129,
            ic_city: "Patrocínio do Muriaé",
            ic_states: "MINAS GERAIS",
            ic_january: 6.07,
            ic_february: 6.299,
            ic_march: 5.197,
            ic_april: 4.532,
            ic_may: 3.836,
            ic_june: 3.605,
            ic_july: 3.714,
            ic_august: 4.51,
            ic_september: 4.92,
            ic_october: 5.208,
            ic_november: 5.039,
            ic_december: 5.923,
            ic_yearly: 4.904,
          },
          {
            ic_uuid: "a8116d1b-26c6-4242-8f74-16991e68694b",
            ic_lat: -206388,
            ic_lon: -472805,
            ic_city: "Patrocínio Paulista",
            ic_states: "SÃO PAULO",
            ic_january: 5.538,
            ic_february: 5.788,
            ic_march: 4.98,
            ic_april: 4.825,
            ic_may: 4.244,
            ic_june: 4.042,
            ic_july: 4.282,
            ic_august: 5.237,
            ic_september: 5.143,
            ic_october: 5.476,
            ic_november: 5.546,
            ic_december: 5.688,
            ic_yearly: 5.066,
          },
          {
            ic_uuid: "d8afaf3a-aea8-4137-933c-0a7051d04a58",
            ic_lat: -6107,
            ic_lon: -376359,
            ic_city: "Patu",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.161,
            ic_february: 6.165,
            ic_march: 6.155,
            ic_april: 5.868,
            ic_may: 5.431,
            ic_june: 5.092,
            ic_july: 5.334,
            ic_august: 5.981,
            ic_september: 6.547,
            ic_october: 6.608,
            ic_november: 6.63,
            ic_december: 6.198,
            ic_yearly: 6.014,
          },
          {
            ic_uuid: "383b204d-ecb5-47cc-9357-aecdeede9243",
            ic_lat: -224314,
            ic_lon: -434289,
            ic_city: "Paty do Alferes",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.836,
            ic_february: 6.18,
            ic_march: 5.072,
            ic_april: 4.408,
            ic_may: 3.635,
            ic_june: 3.393,
            ic_july: 3.522,
            ic_august: 4.311,
            ic_september: 4.633,
            ic_october: 5.106,
            ic_november: 5.14,
            ic_december: 5.847,
            ic_yearly: 4.757,
          },
          {
            ic_uuid: "fba9e824-199e-474a-a3d2-0695fbdf4445",
            ic_lat: -154599,
            ic_lon: -396434,
            ic_city: "Pau Brasil",
            ic_states: "BAHIA",
            ic_january: 5.848,
            ic_february: 5.959,
            ic_march: 5.337,
            ic_april: 4.569,
            ic_may: 3.836,
            ic_june: 3.49,
            ic_july: 3.712,
            ic_august: 4.353,
            ic_september: 5.062,
            ic_october: 5.224,
            ic_november: 5.211,
            ic_december: 5.958,
            ic_yearly: 4.88,
          },
          {
            ic_uuid: "3f6914ce-47cd-4ad3-895f-284446c27b32",
            ic_lat: -78343,
            ic_lon: -500444,
            ic_city: "Pau D'Arco",
            ic_states: "PARÁ",
            ic_january: 4.861,
            ic_february: 4.826,
            ic_march: 4.705,
            ic_april: 4.689,
            ic_may: 4.82,
            ic_june: 5.019,
            ic_july: 5.106,
            ic_august: 5.78,
            ic_september: 5.391,
            ic_october: 4.939,
            ic_november: 4.838,
            ic_december: 4.725,
            ic_yearly: 4.975,
          },
          {
            ic_uuid: "62dc1d31-d9af-4835-825c-279a4f4838d8",
            ic_lat: -75396,
            ic_lon: -493674,
            ic_city: "Pau D'Arco",
            ic_states: "TOCANTINS",
            ic_january: 4.786,
            ic_february: 4.91,
            ic_march: 4.686,
            ic_april: 4.707,
            ic_may: 4.894,
            ic_june: 5.093,
            ic_july: 5.14,
            ic_august: 5.764,
            ic_september: 5.618,
            ic_october: 5.122,
            ic_november: 4.753,
            ic_december: 4.774,
            ic_yearly: 5.021,
          },
          {
            ic_uuid: "96a96328-c2a0-4ba9-adbf-76c993855d6d",
            ic_lat: -52544,
            ic_lon: -42388,
            ic_city: "Pau D'Arco do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.221,
            ic_february: 5.29,
            ic_march: 5.337,
            ic_april: 5.123,
            ic_may: 5.143,
            ic_june: 5.302,
            ic_july: 5.582,
            ic_august: 6.258,
            ic_september: 6.575,
            ic_october: 6.462,
            ic_november: 6.272,
            ic_december: 5.828,
            ic_yearly: 5.699,
          },
          {
            ic_uuid: "23bd9240-c43b-4958-af51-eea7323908f8",
            ic_lat: -61054,
            ic_lon: -38208,
            ic_city: "Pau dos Ferros",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.863,
            ic_february: 5.961,
            ic_march: 6.016,
            ic_april: 5.695,
            ic_may: 5.311,
            ic_june: 5.014,
            ic_july: 5.361,
            ic_august: 6.052,
            ic_september: 6.436,
            ic_october: 6.579,
            ic_november: 6.626,
            ic_december: 6.103,
            ic_yearly: 5.918,
          },
          {
            ic_uuid: "5037c97c-228a-4ca8-97a0-a071f9636e18",
            ic_lat: -79033,
            ic_lon: -351719,
            ic_city: "Paudalho",
            ic_states: "PERNAMBUCO",
            ic_january: 5.753,
            ic_february: 5.885,
            ic_march: 5.84,
            ic_april: 5.217,
            ic_may: 4.504,
            ic_june: 4.175,
            ic_july: 4.29,
            ic_august: 5.031,
            ic_september: 5.55,
            ic_october: 5.778,
            ic_november: 6.031,
            ic_december: 6.041,
            ic_yearly: 5.341,
          },
          {
            ic_uuid: "fb288a65-c38d-40c4-a96b-63384c4023f4",
            ic_lat: -77135,
            ic_lon: -669925,
            ic_city: "Pauini",
            ic_states: "AMAZONAS",
            ic_january: 4.226,
            ic_february: 4.478,
            ic_march: 4.256,
            ic_april: 4.249,
            ic_may: 3.893,
            ic_june: 4.208,
            ic_july: 4.38,
            ic_august: 4.969,
            ic_september: 5.045,
            ic_october: 4.857,
            ic_november: 4.644,
            ic_december: 4.383,
            ic_yearly: 4.465,
          },
          {
            ic_uuid: "d6762a01-68a3-4b3b-91b0-3af0902aa6ef",
            ic_lat: -208759,
            ic_lon: -429756,
            ic_city: "Paula Cândido",
            ic_states: "MINAS GERAIS",
            ic_january: 5.621,
            ic_february: 5.885,
            ic_march: 4.856,
            ic_april: 4.254,
            ic_may: 3.683,
            ic_june: 3.52,
            ic_july: 3.741,
            ic_august: 4.487,
            ic_september: 4.811,
            ic_october: 5.008,
            ic_november: 4.771,
            ic_december: 5.48,
            ic_yearly: 4.676,
          },
          {
            ic_uuid: "74103622-c595-4301-abdd-925ce5848ecd",
            ic_lat: -26211,
            ic_lon: -509314,
            ic_city: "Paula Freitas",
            ic_states: "PARANÁ",
            ic_january: 5.32,
            ic_february: 5.058,
            ic_march: 4.559,
            ic_april: 3.529,
            ic_may: 2.85,
            ic_june: 2.401,
            ic_july: 2.619,
            ic_august: 3.643,
            ic_september: 3.775,
            ic_october: 4.406,
            ic_november: 5.361,
            ic_december: 5.564,
            ic_yearly: 4.09,
          },
          {
            ic_uuid: "3dc9f41c-10dc-4a24-b306-f710c787c76e",
            ic_lat: -213157,
            ic_lon: -518325,
            ic_city: "Paulicéia",
            ic_states: "SÃO PAULO",
            ic_january: 5.896,
            ic_february: 6.063,
            ic_march: 5.481,
            ic_april: 4.846,
            ic_may: 4.023,
            ic_june: 3.668,
            ic_july: 3.837,
            ic_august: 4.706,
            ic_september: 4.837,
            ic_october: 5.557,
            ic_november: 6.03,
            ic_december: 6.341,
            ic_yearly: 5.107,
          },
          {
            ic_uuid: "4aba695b-1584-4764-8ff3-833af756fd0f",
            ic_lat: -227547,
            ic_lon: -471492,
            ic_city: "Paulínia",
            ic_states: "SÃO PAULO",
            ic_january: 5.623,
            ic_february: 5.814,
            ic_march: 5.152,
            ic_april: 4.658,
            ic_may: 3.804,
            ic_june: 3.571,
            ic_july: 3.728,
            ic_august: 4.644,
            ic_september: 4.855,
            ic_october: 5.477,
            ic_november: 5.728,
            ic_december: 6.185,
            ic_yearly: 4.936,
          },
          {
            ic_uuid: "54868853-3fc8-4eb1-88af-ec4cf1108b9b",
            ic_lat: -27222,
            ic_lon: -425369,
            ic_city: "Paulino Neves",
            ic_states: "MARANHÃO",
            ic_january: 5.298,
            ic_february: 5.294,
            ic_march: 5.133,
            ic_april: 4.965,
            ic_may: 5.031,
            ic_june: 5.036,
            ic_july: 5.16,
            ic_august: 5.401,
            ic_september: 5.435,
            ic_october: 5.387,
            ic_november: 5.671,
            ic_december: 5.658,
            ic_yearly: 5.289,
          },
          {
            ic_uuid: "93c027c5-f16a-4f4b-a443-cb5e23d8684d",
            ic_lat: -65918,
            ic_lon: -376189,
            ic_city: "Paulista",
            ic_states: "PARAÍBA",
            ic_january: 6.252,
            ic_february: 6.224,
            ic_march: 6.242,
            ic_april: 5.857,
            ic_may: 5.324,
            ic_june: 4.902,
            ic_july: 5.167,
            ic_august: 5.953,
            ic_september: 6.494,
            ic_october: 6.651,
            ic_november: 6.67,
            ic_december: 6.348,
            ic_yearly: 6.007,
          },
          {
            ic_uuid: "4ad0f714-65f0-4f7d-83ff-18cc73c3d299",
            ic_lat: -79406,
            ic_lon: -348674,
            ic_city: "Paulista",
            ic_states: "PERNAMBUCO",
            ic_january: 5.816,
            ic_february: 5.966,
            ic_march: 5.913,
            ic_april: 5.22,
            ic_may: 4.532,
            ic_june: 4.234,
            ic_july: 4.345,
            ic_august: 5.117,
            ic_september: 5.544,
            ic_october: 5.918,
            ic_november: 6.15,
            ic_december: 6.093,
            ic_yearly: 5.404,
          },
          {
            ic_uuid: "b3a3d20e-c034-435c-9f54-347acfd514ab",
            ic_lat: -81348,
            ic_lon: -411434,
            ic_city: "Paulistana",
            ic_states: "PIAUÍ",
            ic_january: 5.897,
            ic_february: 5.67,
            ic_march: 5.741,
            ic_april: 5.395,
            ic_may: 5.142,
            ic_june: 5.034,
            ic_july: 5.417,
            ic_august: 6.225,
            ic_september: 6.561,
            ic_october: 6.622,
            ic_november: 6.442,
            ic_december: 6.12,
            ic_yearly: 5.856,
          },
          {
            ic_uuid: "cb712676-74d9-4146-801d-cc411d01d7fe",
            ic_lat: -225773,
            ic_lon: -494013,
            ic_city: "Paulistânia",
            ic_states: "SÃO PAULO",
            ic_january: 5.585,
            ic_february: 5.783,
            ic_march: 5.211,
            ic_april: 4.691,
            ic_may: 3.796,
            ic_june: 3.506,
            ic_july: 3.728,
            ic_august: 4.723,
            ic_september: 4.775,
            ic_october: 5.45,
            ic_november: 5.852,
            ic_december: 6.176,
            ic_yearly: 4.94,
          },
          {
            ic_uuid: "7aee74db-585a-4d48-8431-01684cef6237",
            ic_lat: -184317,
            ic_lon: -428726,
            ic_city: "Paulistas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.94,
            ic_february: 6.175,
            ic_march: 5.127,
            ic_april: 4.552,
            ic_may: 3.946,
            ic_june: 3.759,
            ic_july: 3.866,
            ic_august: 4.62,
            ic_september: 5.12,
            ic_october: 5.319,
            ic_november: 4.863,
            ic_december: 5.676,
            ic_yearly: 4.914,
          },
          {
            ic_uuid: "74f3fded-4ed4-4d44-b2e8-132537a7abbd",
            ic_lat: -93987,
            ic_lon: -382219,
            ic_city: "Paulo Afonso",
            ic_states: "BAHIA",
            ic_january: 6.327,
            ic_february: 6.239,
            ic_march: 6.062,
            ic_april: 5.395,
            ic_may: 4.545,
            ic_june: 4.067,
            ic_july: 4.208,
            ic_august: 4.839,
            ic_september: 5.796,
            ic_october: 6.093,
            ic_november: 6.421,
            ic_december: 6.472,
            ic_yearly: 5.539,
          },
          {
            ic_uuid: "81ed04ee-0ff7-4af6-821a-a6eed0c4f9ef",
            ic_lat: -277056,
            ic_lon: -524174,
            ic_city: "Paulo Bento",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.222,
            ic_february: 5.787,
            ic_march: 5.022,
            ic_april: 4.063,
            ic_may: 3.053,
            ic_june: 2.584,
            ic_july: 2.929,
            ic_august: 3.734,
            ic_september: 3.932,
            ic_october: 5.088,
            ic_november: 6.152,
            ic_december: 6.537,
            ic_yearly: 4.592,
          },
          {
            ic_uuid: "eb20eaa2-d712-49c4-ab11-4d32b12d1139",
            ic_lat: -200301,
            ic_lon: -494004,
            ic_city: "Paulo de Faria",
            ic_states: "SÃO PAULO",
            ic_january: 5.732,
            ic_february: 5.954,
            ic_march: 5.25,
            ic_april: 4.993,
            ic_may: 4.246,
            ic_june: 3.943,
            ic_july: 4.219,
            ic_august: 5.107,
            ic_september: 5.051,
            ic_october: 5.571,
            ic_november: 5.818,
            ic_december: 6.157,
            ic_yearly: 5.17,
          },
          {
            ic_uuid: "5fce6723-6429-4ed5-b6fb-75e1b6d42cc9",
            ic_lat: -26047,
            ic_lon: -508309,
            ic_city: "Paulo Frontin",
            ic_states: "PARANÁ",
            ic_january: 5.204,
            ic_february: 4.927,
            ic_march: 4.467,
            ic_april: 3.521,
            ic_may: 2.843,
            ic_june: 2.463,
            ic_july: 2.681,
            ic_august: 3.686,
            ic_september: 3.798,
            ic_october: 4.308,
            ic_november: 5.314,
            ic_december: 5.48,
            ic_yearly: 4.058,
          },
          {
            ic_uuid: "c9aa4a17-ec2c-4ad5-8e95-935753a66b9e",
            ic_lat: -93705,
            ic_lon: -363759,
            ic_city: "Paulo Jacinto",
            ic_states: "ALAGOAS",
            ic_january: 5.922,
            ic_february: 5.837,
            ic_march: 5.813,
            ic_april: 5.075,
            ic_may: 4.22,
            ic_june: 3.77,
            ic_july: 3.908,
            ic_august: 4.517,
            ic_september: 5.398,
            ic_october: 5.737,
            ic_november: 6.348,
            ic_december: 6.263,
            ic_yearly: 5.234,
          },
          {
            ic_uuid: "6555e484-6f91-4452-87c4-8f65e6713bb1",
            ic_lat: -279606,
            ic_lon: -486727,
            ic_city: "Paulo Lopes",
            ic_states: "SANTA CATARINA",
            ic_january: 5.636,
            ic_february: 5.165,
            ic_march: 4.471,
            ic_april: 3.67,
            ic_may: 3.009,
            ic_june: 2.539,
            ic_july: 2.715,
            ic_august: 3.443,
            ic_september: 3.55,
            ic_october: 4.199,
            ic_november: 5.445,
            ic_december: 5.826,
            ic_yearly: 4.139,
          },
          {
            ic_uuid: "f4a17cc5-1489-43be-a7ca-2839ab5dd4ca",
            ic_lat: -44452,
            ic_lon: -452401,
            ic_city: "Paulo Ramos",
            ic_states: "MARANHÃO",
            ic_january: 4.574,
            ic_february: 4.817,
            ic_march: 4.901,
            ic_april: 4.85,
            ic_may: 4.775,
            ic_june: 4.935,
            ic_july: 5.146,
            ic_august: 5.6,
            ic_september: 5.853,
            ic_october: 5.371,
            ic_november: 5.004,
            ic_december: 4.803,
            ic_yearly: 5.052,
          },
          {
            ic_uuid: "a82322e9-d2a8-4ad2-ad21-6341c19b10fe",
            ic_lat: -174272,
            ic_lon: -410038,
            ic_city: "Pavão",
            ic_states: "MINAS GERAIS",
            ic_january: 6.149,
            ic_february: 6.332,
            ic_march: 5.397,
            ic_april: 4.626,
            ic_may: 3.857,
            ic_june: 3.471,
            ic_july: 3.647,
            ic_august: 4.362,
            ic_september: 4.933,
            ic_october: 5.214,
            ic_november: 5.152,
            ic_december: 6.067,
            ic_yearly: 4.934,
          },
          {
            ic_uuid: "ac73e26f-a903-43e6-92dc-7f281e655cbf",
            ic_lat: -29549,
            ic_lon: -517344,
            ic_city: "Paverama",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.231,
            ic_february: 5.727,
            ic_march: 4.836,
            ic_april: 3.783,
            ic_may: 2.802,
            ic_june: 2.325,
            ic_july: 2.568,
            ic_august: 3.267,
            ic_september: 3.574,
            ic_october: 4.627,
            ic_november: 6.109,
            ic_december: 6.508,
            ic_yearly: 4.363,
          },
          {
            ic_uuid: "e1b223bb-92b8-49ea-9b45-550be7b4b40b",
            ic_lat: -7965,
            ic_lon: -432251,
            ic_city: "Pavussu",
            ic_states: "PIAUÍ",
            ic_january: 5.482,
            ic_february: 5.451,
            ic_march: 5.283,
            ic_april: 5.24,
            ic_may: 5.376,
            ic_june: 5.288,
            ic_july: 5.65,
            ic_august: 6.294,
            ic_september: 6.601,
            ic_october: 6.371,
            ic_november: 5.9,
            ic_december: 5.717,
            ic_yearly: 5.721,
          },
          {
            ic_uuid: "3e2aae60-6556-4c58-9bc9-1fb03ef7ea8a",
            ic_lat: -118317,
            ic_lon: -396114,
            ic_city: "Pé de Serra",
            ic_states: "BAHIA",
            ic_january: 6.055,
            ic_february: 5.953,
            ic_march: 5.81,
            ic_april: 4.782,
            ic_may: 3.983,
            ic_june: 3.693,
            ic_july: 3.91,
            ic_august: 4.452,
            ic_september: 5.333,
            ic_october: 5.548,
            ic_november: 5.797,
            ic_december: 6.017,
            ic_yearly: 5.111,
          },
          {
            ic_uuid: "c8bab61d-6923-42c4-afd5-68530fb49531",
            ic_lat: -239145,
            ic_lon: -523435,
            ic_city: "Peabiru",
            ic_states: "PARANÁ",
            ic_january: 6.083,
            ic_february: 5.755,
            ic_march: 5.261,
            ic_april: 4.567,
            ic_may: 3.604,
            ic_june: 3.184,
            ic_july: 3.399,
            ic_august: 4.332,
            ic_september: 4.59,
            ic_october: 5.271,
            ic_november: 6.109,
            ic_december: 6.477,
            ic_yearly: 4.886,
          },
          {
            ic_uuid: "54256c1e-d710-456c-8246-ba628c297a09",
            ic_lat: -185445,
            ic_lon: -425586,
            ic_city: "Peçanha",
            ic_states: "MINAS GERAIS",
            ic_january: 5.906,
            ic_february: 6.176,
            ic_march: 5.173,
            ic_april: 4.502,
            ic_may: 3.866,
            ic_june: 3.654,
            ic_july: 3.78,
            ic_august: 4.488,
            ic_september: 5.02,
            ic_october: 5.232,
            ic_november: 4.818,
            ic_december: 5.719,
            ic_yearly: 4.861,
          },
          {
            ic_uuid: "32cf57db-4e01-4db0-9e60-3c7be9438d3a",
            ic_lat: -223516,
            ic_lon: -487785,
            ic_city: "Pederneiras",
            ic_states: "SÃO PAULO",
            ic_january: 5.696,
            ic_february: 5.938,
            ic_march: 5.275,
            ic_april: 4.676,
            ic_may: 3.82,
            ic_june: 3.58,
            ic_july: 3.761,
            ic_august: 4.673,
            ic_september: 4.847,
            ic_october: 5.576,
            ic_november: 5.941,
            ic_december: 6.316,
            ic_yearly: 5.008,
          },
          {
            ic_uuid: "70f44763-e81b-48eb-a21f-85dd81dc2ae5",
            ic_lat: -85,
            ic_lon: -369459,
            ic_city: "Pedra",
            ic_states: "PERNAMBUCO",
            ic_january: 6.192,
            ic_february: 6.085,
            ic_march: 6.103,
            ic_april: 5.501,
            ic_may: 4.534,
            ic_june: 3.982,
            ic_july: 4.139,
            ic_august: 4.927,
            ic_september: 5.934,
            ic_october: 6.298,
            ic_november: 6.67,
            ic_december: 6.468,
            ic_yearly: 5.57,
          },
          {
            ic_uuid: "a7ff31cb-3b52-4fe4-b330-3ae73bd15f90",
            ic_lat: -160091,
            ic_lon: -412913,
            ic_city: "Pedra Azul",
            ic_states: "MINAS GERAIS",
            ic_january: 6.07,
            ic_february: 6.283,
            ic_march: 5.417,
            ic_april: 4.718,
            ic_may: 4.042,
            ic_june: 3.633,
            ic_july: 3.799,
            ic_august: 4.508,
            ic_september: 5.357,
            ic_october: 5.537,
            ic_november: 5.291,
            ic_december: 6.095,
            ic_yearly: 5.062,
          },
          {
            ic_uuid: "7c1f17a4-4634-4a0b-b331-289a48b5094e",
            ic_lat: -227907,
            ic_lon: -464459,
            ic_city: "Pedra Bela",
            ic_states: "SÃO PAULO",
            ic_january: 5.322,
            ic_february: 5.609,
            ic_march: 4.912,
            ic_april: 4.554,
            ic_may: 3.798,
            ic_june: 3.54,
            ic_july: 3.715,
            ic_august: 4.675,
            ic_september: 4.853,
            ic_october: 5.394,
            ic_november: 5.536,
            ic_december: 5.882,
            ic_yearly: 4.816,
          },
          {
            ic_uuid: "19ecfd35-719f-435f-8536-5aece4567fce",
            ic_lat: -205224,
            ic_lon: -423308,
            ic_city: "Pedra Bonita",
            ic_states: "MINAS GERAIS",
            ic_january: 5.437,
            ic_february: 5.799,
            ic_march: 4.822,
            ic_april: 4.347,
            ic_may: 3.717,
            ic_june: 3.545,
            ic_july: 3.81,
            ic_august: 4.487,
            ic_september: 4.89,
            ic_october: 4.977,
            ic_november: 4.65,
            ic_december: 5.413,
            ic_yearly: 4.658,
          },
          {
            ic_uuid: "da871c53-1988-41e0-a038-987a06702c39",
            ic_lat: -54538,
            ic_lon: -397081,
            ic_city: "Pedra Branca",
            ic_states: "CEARÁ",
            ic_january: 5.359,
            ic_february: 5.404,
            ic_march: 5.491,
            ic_april: 5.043,
            ic_may: 4.863,
            ic_june: 4.901,
            ic_july: 5.317,
            ic_august: 6.007,
            ic_september: 6.422,
            ic_october: 6.324,
            ic_november: 6.289,
            ic_december: 5.707,
            ic_yearly: 5.594,
          },
          {
            ic_uuid: "3efc5853-b34d-41c7-9fe2-5fb2363b9eed",
            ic_lat: -74288,
            ic_lon: -380711,
            ic_city: "Pedra Branca",
            ic_states: "PARAÍBA",
            ic_january: 6.249,
            ic_february: 6.219,
            ic_march: 6.277,
            ic_april: 5.918,
            ic_may: 5.213,
            ic_june: 4.808,
            ic_july: 5.028,
            ic_august: 5.89,
            ic_september: 6.488,
            ic_october: 6.581,
            ic_november: 6.775,
            ic_december: 6.448,
            ic_yearly: 5.991,
          },
          {
            ic_uuid: "7007c6b7-3afa-4098-9159-b2d9a0a15e21",
            ic_lat: 7771,
            ic_lon: -519507,
            ic_city: "Pedra Branca do Amapari",
            ic_states: "AMAPÁ",
            ic_january: 4.155,
            ic_february: 4.027,
            ic_march: 4.083,
            ic_april: 4.147,
            ic_may: 4.381,
            ic_june: 4.509,
            ic_july: 4.68,
            ic_august: 5.038,
            ic_september: 5.289,
            ic_october: 5.128,
            ic_november: 4.935,
            ic_december: 4.321,
            ic_yearly: 4.558,
          },
          {
            ic_uuid: "98ea8984-0827-454b-a76f-411123a5cc0a",
            ic_lat: -205973,
            ic_lon: -427127,
            ic_city: "Pedra do Anta",
            ic_states: "MINAS GERAIS",
            ic_january: 5.628,
            ic_february: 5.975,
            ic_march: 4.998,
            ic_april: 4.402,
            ic_may: 3.782,
            ic_june: 3.571,
            ic_july: 3.78,
            ic_august: 4.584,
            ic_september: 4.875,
            ic_october: 5.091,
            ic_november: 4.834,
            ic_december: 5.544,
            ic_yearly: 4.756,
          },
          {
            ic_uuid: "fa1d5bd5-5d27-4bc6-acdd-1db3c5dd65e9",
            ic_lat: -202568,
            ic_lon: -45211,
            ic_city: "Pedra do Indaiá",
            ic_states: "MINAS GERAIS",
            ic_january: 5.694,
            ic_february: 5.865,
            ic_march: 4.961,
            ic_april: 4.835,
            ic_may: 4.264,
            ic_june: 4.048,
            ic_july: 4.3,
            ic_august: 5.187,
            ic_september: 5.419,
            ic_october: 5.488,
            ic_november: 5.094,
            ic_december: 5.531,
            ic_yearly: 5.057,
          },
          {
            ic_uuid: "6d0c40a7-9df6-457a-a32d-aaa7e7cb1e4c",
            ic_lat: -208271,
            ic_lon: -421519,
            ic_city: "Pedra Dourada",
            ic_states: "MINAS GERAIS",
            ic_january: 5.829,
            ic_february: 6.006,
            ic_march: 4.893,
            ic_april: 4.345,
            ic_may: 3.737,
            ic_june: 3.64,
            ic_july: 3.758,
            ic_august: 4.566,
            ic_september: 4.901,
            ic_october: 4.986,
            ic_november: 4.729,
            ic_december: 5.551,
            ic_yearly: 4.745,
          },
          {
            ic_uuid: "2c1e4cad-3f28-4ca9-abe8-3796120b2dc6",
            ic_lat: -51503,
            ic_lon: -358763,
            ic_city: "Pedra Grande",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.873,
            ic_february: 5.941,
            ic_march: 5.926,
            ic_april: 5.404,
            ic_may: 5.029,
            ic_june: 4.7,
            ic_july: 4.822,
            ic_august: 5.492,
            ic_september: 5.809,
            ic_october: 5.929,
            ic_november: 6.14,
            ic_december: 5.846,
            ic_yearly: 5.576,
          },
          {
            ic_uuid: "582bc659-1996-4aaf-b81c-bc307107e79c",
            ic_lat: -6756,
            ic_lon: -364683,
            ic_city: "Pedra Lavrada",
            ic_states: "PARAÍBA",
            ic_january: 5.91,
            ic_february: 5.884,
            ic_march: 5.931,
            ic_april: 5.522,
            ic_may: 4.92,
            ic_june: 4.491,
            ic_july: 4.762,
            ic_august: 5.472,
            ic_september: 6.045,
            ic_october: 6.359,
            ic_november: 6.477,
            ic_december: 6.091,
            ic_yearly: 5.655,
          },
          {
            ic_uuid: "847fcfdb-e3c5-4a24-b209-73c8263be042",
            ic_lat: -10619,
            ic_lon: -376872,
            ic_city: "Pedra Mole",
            ic_states: "SERGIPE",
            ic_january: 6.026,
            ic_february: 5.853,
            ic_march: 5.824,
            ic_april: 5.015,
            ic_may: 4.215,
            ic_june: 3.94,
            ic_july: 4.068,
            ic_august: 4.573,
            ic_september: 5.358,
            ic_october: 5.661,
            ic_november: 6.139,
            ic_december: 6.105,
            ic_yearly: 5.231,
          },
          {
            ic_uuid: "b7d8e963-02fa-4037-a0c6-d21e8d785bb4",
            ic_lat: -16622,
            ic_lon: -544764,
            ic_city: "Pedra Preta",
            ic_states: "MATO GROSSO",
            ic_january: 5.651,
            ic_february: 5.646,
            ic_march: 5.396,
            ic_april: 5.164,
            ic_may: 4.684,
            ic_june: 4.479,
            ic_july: 4.614,
            ic_august: 5.467,
            ic_september: 5.237,
            ic_october: 5.377,
            ic_november: 5.67,
            ic_december: 5.766,
            ic_yearly: 5.262,
          },
          {
            ic_uuid: "51d67e2b-91cd-4103-9a07-02cb493e98d8",
            ic_lat: -55797,
            ic_lon: -361034,
            ic_city: "Pedra Preta",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.846,
            ic_february: 5.839,
            ic_march: 5.78,
            ic_april: 5.506,
            ic_may: 5.166,
            ic_june: 4.801,
            ic_july: 5.021,
            ic_august: 5.722,
            ic_september: 6.077,
            ic_october: 6.296,
            ic_november: 6.302,
            ic_december: 5.898,
            ic_yearly: 5.688,
          },
          {
            ic_uuid: "c046e613-edc2-4d63-9e47-1937deb0d759",
            ic_lat: -222391,
            ic_lon: -454658,
            ic_city: "Pedralva",
            ic_states: "MINAS GERAIS",
            ic_january: 5.29,
            ic_february: 5.584,
            ic_march: 4.759,
            ic_april: 4.491,
            ic_may: 3.828,
            ic_june: 3.625,
            ic_july: 3.868,
            ic_august: 4.762,
            ic_september: 4.935,
            ic_october: 5.318,
            ic_november: 5.193,
            ic_december: 5.555,
            ic_yearly: 4.767,
          },
          {
            ic_uuid: "a752008d-6500-4502-94b9-78e14180e4ef",
            ic_lat: -20248,
            ic_lon: -501099,
            ic_city: "Pedranópolis",
            ic_states: "SÃO PAULO",
            ic_january: 5.814,
            ic_february: 5.97,
            ic_march: 5.301,
            ic_april: 4.937,
            ic_may: 4.247,
            ic_june: 3.96,
            ic_july: 4.21,
            ic_august: 5.089,
            ic_september: 5.131,
            ic_october: 5.672,
            ic_november: 5.904,
            ic_december: 6.212,
            ic_yearly: 5.204,
          },
          {
            ic_uuid: "ad3dbbda-366f-4fb5-9520-f27f39451d3a",
            ic_lat: -121456,
            ic_lon: -386428,
            ic_city: "Pedrão",
            ic_states: "BAHIA",
            ic_january: 6.081,
            ic_february: 5.835,
            ic_march: 5.716,
            ic_april: 4.715,
            ic_may: 3.961,
            ic_june: 3.69,
            ic_july: 3.933,
            ic_august: 4.434,
            ic_september: 5.289,
            ic_october: 5.524,
            ic_november: 5.869,
            ic_december: 6.109,
            ic_yearly: 5.096,
          },
          {
            ic_uuid: "321a073d-93e5-43ce-8190-79441853b9e4",
            ic_lat: -317374,
            ic_lon: -535823,
            ic_city: "Pedras Altas",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.448,
            ic_february: 5.798,
            ic_march: 4.911,
            ic_april: 3.715,
            ic_may: 2.661,
            ic_june: 2.277,
            ic_july: 2.508,
            ic_august: 3.117,
            ic_september: 3.747,
            ic_october: 5.075,
            ic_november: 6.383,
            ic_december: 6.914,
            ic_yearly: 4.463,
          },
          {
            ic_uuid: "297c5893-737c-4e37-bba8-3137dd23bf32",
            ic_lat: -74025,
            ic_lon: -351125,
            ic_city: "Pedras de Fogo",
            ic_states: "PARAÍBA",
            ic_january: 5.74,
            ic_february: 5.94,
            ic_march: 5.925,
            ic_april: 5.335,
            ic_may: 4.628,
            ic_june: 4.243,
            ic_july: 4.356,
            ic_august: 5.058,
            ic_september: 5.592,
            ic_october: 5.849,
            ic_november: 6.132,
            ic_december: 6.035,
            ic_yearly: 5.403,
          },
          {
            ic_uuid: "ba7dd61c-4c0d-4204-af01-f5a1a4297b05",
            ic_lat: -156037,
            ic_lon: -443914,
            ic_city: "Pedras de Maria da Cruz",
            ic_states: "MINAS GERAIS",
            ic_january: 6.454,
            ic_february: 6.691,
            ic_march: 5.901,
            ic_april: 5.636,
            ic_may: 5.062,
            ic_june: 4.754,
            ic_july: 5.082,
            ic_august: 5.781,
            ic_september: 6.071,
            ic_october: 6.167,
            ic_november: 5.634,
            ic_december: 6.209,
            ic_yearly: 5.787,
          },
          {
            ic_uuid: "8ec41dec-938b-4c0b-a366-097f8451f6c9",
            ic_lat: -284343,
            ic_lon: -491953,
            ic_city: "Pedras Grandes",
            ic_states: "SANTA CATARINA",
            ic_january: 5.689,
            ic_february: 5.277,
            ic_march: 4.546,
            ic_april: 3.785,
            ic_may: 2.969,
            ic_june: 2.527,
            ic_july: 2.729,
            ic_august: 3.484,
            ic_september: 3.596,
            ic_october: 4.389,
            ic_november: 5.666,
            ic_december: 5.992,
            ic_yearly: 4.221,
          },
          {
            ic_uuid: "8db0d0f8-b9c7-4f52-8e81-4990670a2440",
            ic_lat: -202539,
            ic_lon: -474778,
            ic_city: "Pedregulho",
            ic_states: "SÃO PAULO",
            ic_january: 5.603,
            ic_february: 5.864,
            ic_march: 5.075,
            ic_april: 4.917,
            ic_may: 4.304,
            ic_june: 4.113,
            ic_july: 4.37,
            ic_august: 5.261,
            ic_september: 5.187,
            ic_october: 5.475,
            ic_november: 5.588,
            ic_december: 5.88,
            ic_yearly: 5.136,
          },
          {
            ic_uuid: "f20521ec-7051-4706-83c9-31666cec1c8a",
            ic_lat: -227418,
            ic_lon: -468952,
            ic_city: "Pedreira",
            ic_states: "SÃO PAULO",
            ic_january: 5.565,
            ic_february: 5.814,
            ic_march: 5.135,
            ic_april: 4.693,
            ic_may: 3.891,
            ic_june: 3.567,
            ic_july: 3.788,
            ic_august: 4.679,
            ic_september: 4.894,
            ic_october: 5.526,
            ic_november: 5.731,
            ic_december: 6.159,
            ic_yearly: 4.954,
          },
          {
            ic_uuid: "4cc4aa0d-e7c8-4ab3-b7a7-2b4fdf7e764d",
            ic_lat: -45652,
            ic_lon: -44601,
            ic_city: "Pedreiras",
            ic_states: "MARANHÃO",
            ic_january: 4.752,
            ic_february: 4.851,
            ic_march: 4.943,
            ic_april: 4.865,
            ic_may: 4.823,
            ic_june: 5.064,
            ic_july: 5.298,
            ic_august: 5.822,
            ic_september: 6.181,
            ic_october: 5.73,
            ic_november: 5.315,
            ic_december: 5.013,
            ic_yearly: 5.222,
          },
          {
            ic_uuid: "80f9d688-60e9-4c10-b155-8ddb8b854d73",
            ic_lat: -111906,
            ic_lon: -376778,
            ic_city: "Pedrinhas",
            ic_states: "SERGIPE",
            ic_january: 6.167,
            ic_february: 6.034,
            ic_march: 5.905,
            ic_april: 4.919,
            ic_may: 4.21,
            ic_june: 3.941,
            ic_july: 4.066,
            ic_august: 4.487,
            ic_september: 5.284,
            ic_october: 5.607,
            ic_november: 6.119,
            ic_december: 6.231,
            ic_yearly: 5.247,
          },
          {
            ic_uuid: "a1c2c42b-c0b4-478e-84ab-f8391ac7f2f2",
            ic_lat: -22815,
            ic_lon: -507939,
            ic_city: "Pedrinhas Paulista",
            ic_states: "SÃO PAULO",
            ic_january: 5.95,
            ic_february: 5.809,
            ic_march: 5.225,
            ic_april: 4.687,
            ic_may: 3.781,
            ic_june: 3.437,
            ic_july: 3.646,
            ic_august: 4.572,
            ic_september: 4.71,
            ic_october: 5.389,
            ic_november: 5.977,
            ic_december: 6.422,
            ic_yearly: 4.967,
          },
          {
            ic_uuid: "2236ba03-2ef9-4b8c-a73f-8906eff531ec",
            ic_lat: -192282,
            ic_lon: -474643,
            ic_city: "Pedrinópolis",
            ic_states: "MINAS GERAIS",
            ic_january: 5.611,
            ic_february: 6.026,
            ic_march: 5.074,
            ic_april: 5.04,
            ic_may: 4.54,
            ic_june: 4.305,
            ic_july: 4.515,
            ic_august: 5.46,
            ic_september: 5.377,
            ic_october: 5.655,
            ic_november: 5.526,
            ic_december: 5.749,
            ic_yearly: 5.24,
          },
          {
            ic_uuid: "99d284e1-593c-42dd-9c26-75d729e004bb",
            ic_lat: -89708,
            ic_lon: -481733,
            ic_city: "Pedro Afonso",
            ic_states: "TOCANTINS",
            ic_january: 5.088,
            ic_february: 5.15,
            ic_march: 4.954,
            ic_april: 4.887,
            ic_may: 5.1,
            ic_june: 5.151,
            ic_july: 5.269,
            ic_august: 5.978,
            ic_september: 5.8,
            ic_october: 5.316,
            ic_november: 5.099,
            ic_december: 5.028,
            ic_yearly: 5.235,
          },
          {
            ic_uuid: "7e371443-a42c-45e7-9461-3b51a418194d",
            ic_lat: -100124,
            ic_lon: -378935,
            ic_city: "Pedro Alexandre",
            ic_states: "BAHIA",
            ic_january: 6.012,
            ic_february: 5.834,
            ic_march: 5.786,
            ic_april: 5.052,
            ic_may: 4.259,
            ic_june: 3.941,
            ic_july: 4.065,
            ic_august: 4.652,
            ic_september: 5.414,
            ic_october: 5.636,
            ic_november: 6.173,
            ic_december: 6.154,
            ic_yearly: 5.248,
          },
          {
            ic_uuid: "0e63ded7-a2b8-485c-af8e-2e4262d42e92",
            ic_lat: -55165,
            ic_lon: -36387,
            ic_city: "Pedro Avelino",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.764,
            ic_february: 5.868,
            ic_march: 5.742,
            ic_april: 5.456,
            ic_may: 5.183,
            ic_june: 4.791,
            ic_july: 5.01,
            ic_august: 5.749,
            ic_september: 6.09,
            ic_october: 6.308,
            ic_november: 6.329,
            ic_december: 5.913,
            ic_yearly: 5.684,
          },
          {
            ic_uuid: "7f18783c-8262-4e3a-963e-30032c13de68",
            ic_lat: -183002,
            ic_lon: -399542,
            ic_city: "Pedro Canário",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.119,
            ic_february: 6.184,
            ic_march: 5.319,
            ic_april: 4.524,
            ic_may: 3.862,
            ic_june: 3.511,
            ic_july: 3.673,
            ic_august: 4.362,
            ic_september: 4.895,
            ic_october: 5.133,
            ic_november: 5.154,
            ic_december: 6.033,
            ic_yearly: 4.897,
          },
          {
            ic_uuid: "ddbfae3e-347b-431a-9e35-b3ab5bc6ad2e",
            ic_lat: -24275,
            ic_lon: -47233,
            ic_city: "Pedro de Toledo",
            ic_states: "SÃO PAULO",
            ic_january: 5.331,
            ic_february: 5.459,
            ic_march: 4.608,
            ic_april: 4.008,
            ic_may: 3.207,
            ic_june: 2.838,
            ic_july: 2.883,
            ic_august: 3.612,
            ic_september: 3.654,
            ic_october: 4.074,
            ic_november: 4.87,
            ic_december: 5.558,
            ic_yearly: 4.175,
          },
          {
            ic_uuid: "a0fdf0a4-0983-4953-a001-86a54af9e15b",
            ic_lat: -29692,
            ic_lon: -453486,
            ic_city: "Pedro do Rosário",
            ic_states: "MARANHÃO",
            ic_january: 4.639,
            ic_february: 4.744,
            ic_march: 4.697,
            ic_april: 4.673,
            ic_may: 4.725,
            ic_june: 4.93,
            ic_july: 5.042,
            ic_august: 5.603,
            ic_september: 5.737,
            ic_october: 5.523,
            ic_november: 5.238,
            ic_december: 4.84,
            ic_yearly: 5.033,
          },
          {
            ic_uuid: "85ae6a24-a5ca-4328-b02a-a274995530b5",
            ic_lat: -181006,
            ic_lon: -545535,
            ic_city: "Pedro Gomes",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.546,
            ic_february: 5.666,
            ic_march: 5.433,
            ic_april: 5.041,
            ic_may: 4.389,
            ic_june: 4.226,
            ic_july: 4.307,
            ic_august: 5.202,
            ic_september: 5.1,
            ic_october: 5.452,
            ic_november: 5.732,
            ic_december: 5.907,
            ic_yearly: 5.167,
          },
          {
            ic_uuid: "4d8c8200-7026-4e89-a9fc-5f346bcc3ae6",
            ic_lat: -44285,
            ic_lon: -41453,
            ic_city: "Pedro II",
            ic_states: "PIAUÍ",
            ic_january: 5.228,
            ic_february: 5.304,
            ic_march: 5.325,
            ic_april: 4.992,
            ic_may: 5.125,
            ic_june: 5.198,
            ic_july: 5.511,
            ic_august: 6.176,
            ic_september: 6.652,
            ic_october: 6.54,
            ic_november: 6.416,
            ic_december: 5.768,
            ic_yearly: 5.686,
          },
          {
            ic_uuid: "526a2089-e3cd-4618-860d-d50725797cba",
            ic_lat: -80685,
            ic_lon: -422851,
            ic_city: "Pedro Laurentino",
            ic_states: "PIAUÍ",
            ic_january: 5.638,
            ic_february: 5.598,
            ic_march: 5.563,
            ic_april: 5.331,
            ic_may: 5.249,
            ic_june: 5.177,
            ic_july: 5.529,
            ic_august: 6.334,
            ic_september: 6.565,
            ic_october: 6.482,
            ic_november: 6.208,
            ic_december: 5.859,
            ic_yearly: 5.795,
          },
          {
            ic_uuid: "03123d3c-e703-4eb3-a59e-340c8f2c78cb",
            ic_lat: -19618,
            ic_lon: -44043,
            ic_city: "Pedro Leopoldo",
            ic_states: "MINAS GERAIS",
            ic_january: 5.894,
            ic_february: 6.031,
            ic_march: 5.224,
            ic_april: 4.891,
            ic_may: 4.331,
            ic_june: 4.094,
            ic_july: 4.412,
            ic_august: 5.209,
            ic_september: 5.608,
            ic_october: 5.645,
            ic_november: 5.418,
            ic_december: 5.669,
            ic_yearly: 5.202,
          },
          {
            ic_uuid: "ccb02c3d-30db-41e7-bb24-732094d9b89e",
            ic_lat: -318647,
            ic_lon: -528189,
            ic_city: "Pedro Osório",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.329,
            ic_february: 5.65,
            ic_march: 4.792,
            ic_april: 3.653,
            ic_may: 2.638,
            ic_june: 2.245,
            ic_july: 2.41,
            ic_august: 3.015,
            ic_september: 3.583,
            ic_october: 4.887,
            ic_november: 6.201,
            ic_december: 6.74,
            ic_yearly: 4.345,
          },
          {
            ic_uuid: "69961ea6-6f7d-45b1-b7f5-7e3c15dde6f6",
            ic_lat: -66404,
            ic_lon: -352734,
            ic_city: "Pedro Régis",
            ic_states: "PARAÍBA",
            ic_january: 5.921,
            ic_february: 5.976,
            ic_march: 5.983,
            ic_april: 5.401,
            ic_may: 4.835,
            ic_june: 4.381,
            ic_july: 4.486,
            ic_august: 5.238,
            ic_september: 5.712,
            ic_october: 5.954,
            ic_november: 6.258,
            ic_december: 6.048,
            ic_yearly: 5.516,
          },
          {
            ic_uuid: "d706f56e-16b7-49a4-a346-dd67168cfff3",
            ic_lat: -217081,
            ic_lon: -437433,
            ic_city: "Pedro Teixeira",
            ic_states: "MINAS GERAIS",
            ic_january: 5.383,
            ic_february: 5.702,
            ic_march: 4.758,
            ic_april: 4.173,
            ic_may: 3.588,
            ic_june: 3.415,
            ic_july: 3.555,
            ic_august: 4.404,
            ic_september: 4.597,
            ic_october: 4.815,
            ic_november: 4.64,
            ic_december: 5.412,
            ic_yearly: 4.537,
          },
          {
            ic_uuid: "f0cea5fd-fbfb-492c-af2f-897e44e7ab02",
            ic_lat: -6439,
            ic_lon: -352217,
            ic_city: "Pedro Velho",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.848,
            ic_february: 5.97,
            ic_march: 6.02,
            ic_april: 5.412,
            ic_may: 4.84,
            ic_june: 4.409,
            ic_july: 4.516,
            ic_august: 5.273,
            ic_september: 5.718,
            ic_october: 5.942,
            ic_november: 6.158,
            ic_december: 5.955,
            ic_yearly: 5.505,
          },
          {
            ic_uuid: "9dbce959-0584-43d1-90c3-f5bb37663074",
            ic_lat: -120374,
            ic_lon: -485418,
            ic_city: "Peixe",
            ic_states: "TOCANTINS",
            ic_january: 5.452,
            ic_february: 5.5,
            ic_march: 5.166,
            ic_april: 5.126,
            ic_may: 5.032,
            ic_june: 4.931,
            ic_july: 5.225,
            ic_august: 5.788,
            ic_september: 5.831,
            ic_october: 5.618,
            ic_november: 5.306,
            ic_december: 5.392,
            ic_yearly: 5.364,
          },
          {
            ic_uuid: "cb1cfcae-b76a-455a-a6b8-d2aebe815452",
            ic_lat: -11923,
            ic_lon: -473204,
            ic_city: "Peixe-Boi",
            ic_states: "PARÁ",
            ic_january: 4.389,
            ic_february: 4.223,
            ic_march: 4.212,
            ic_april: 4.276,
            ic_may: 4.542,
            ic_june: 4.816,
            ic_july: 4.915,
            ic_august: 5.242,
            ic_september: 5.511,
            ic_october: 5.334,
            ic_november: 5.128,
            ic_december: 4.718,
            ic_yearly: 4.775,
          },
          {
            ic_uuid: "ca945b34-d4ef-48dc-8830-55f470e48723",
            ic_lat: -102266,
            ic_lon: -549798,
            ic_city: "Peixoto de Azevedo",
            ic_states: "MATO GROSSO",
            ic_january: 4.94,
            ic_february: 4.893,
            ic_march: 4.759,
            ic_april: 4.674,
            ic_may: 4.732,
            ic_june: 4.546,
            ic_july: 4.848,
            ic_august: 5.36,
            ic_september: 5.096,
            ic_october: 5.251,
            ic_november: 5.025,
            ic_december: 5.018,
            ic_yearly: 4.929,
          },
          {
            ic_uuid: "764c53a7-649c-495c-b99b-dba99df2584a",
            ic_lat: -28423,
            ic_lon: -536559,
            ic_city: "Pejuçara",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.428,
            ic_february: 5.964,
            ic_march: 5.12,
            ic_april: 3.991,
            ic_may: 3.054,
            ic_june: 2.505,
            ic_july: 2.812,
            ic_august: 3.559,
            ic_september: 3.926,
            ic_october: 5.074,
            ic_november: 6.216,
            ic_december: 6.65,
            ic_yearly: 4.608,
          },
          {
            ic_uuid: "486d5b05-5183-49f7-82b4-33309bf4064c",
            ic_lat: -317654,
            ic_lon: -523375,
            ic_city: "Pelotas",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.277,
            ic_february: 5.633,
            ic_march: 4.79,
            ic_april: 3.635,
            ic_may: 2.649,
            ic_june: 2.227,
            ic_july: 2.373,
            ic_august: 2.984,
            ic_september: 3.538,
            ic_october: 4.799,
            ic_november: 6.151,
            ic_december: 6.673,
            ic_yearly: 4.311,
          },
          {
            ic_uuid: "8cf85641-bd6e-4564-ae62-93f976e44582",
            ic_lat: -78291,
            ic_lon: -390722,
            ic_city: "Penaforte",
            ic_states: "CEARÁ",
            ic_january: 5.999,
            ic_february: 6.003,
            ic_march: 5.982,
            ic_april: 5.477,
            ic_may: 4.816,
            ic_june: 4.496,
            ic_july: 4.777,
            ic_august: 5.535,
            ic_september: 6.445,
            ic_october: 6.439,
            ic_november: 6.617,
            ic_december: 6.324,
            ic_yearly: 5.743,
          },
          {
            ic_uuid: "9bf28a95-efd7-4edf-a7c8-1b067b0e16f6",
            ic_lat: -32771,
            ic_lon: -451772,
            ic_city: "Penalva",
            ic_states: "MARANHÃO",
            ic_january: 4.748,
            ic_february: 5.054,
            ic_march: 5.06,
            ic_april: 5.0,
            ic_may: 4.94,
            ic_june: 5.129,
            ic_july: 5.257,
            ic_august: 5.749,
            ic_september: 6.047,
            ic_october: 5.777,
            ic_november: 5.413,
            ic_december: 5.095,
            ic_yearly: 5.272,
          },
          {
            ic_uuid: "d57b1d6c-7b57-46dc-b4be-d532f28d06b3",
            ic_lat: -214152,
            ic_lon: -500773,
            ic_city: "Penápolis",
            ic_states: "SÃO PAULO",
            ic_january: 5.918,
            ic_february: 6.007,
            ic_march: 5.402,
            ic_april: 4.858,
            ic_may: 4.003,
            ic_june: 3.719,
            ic_july: 3.982,
            ic_august: 4.862,
            ic_september: 4.934,
            ic_october: 5.655,
            ic_november: 6.098,
            ic_december: 6.346,
            ic_yearly: 5.149,
          },
          {
            ic_uuid: "f9a02275-d82e-4cfb-85be-2df4e756d3f6",
            ic_lat: -52608,
            ic_lon: -367185,
            ic_city: "Pendências",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.844,
            ic_february: 5.905,
            ic_march: 5.866,
            ic_april: 5.52,
            ic_may: 5.215,
            ic_june: 4.804,
            ic_july: 5.058,
            ic_august: 5.692,
            ic_september: 6.151,
            ic_october: 6.371,
            ic_november: 6.395,
            ic_december: 6.049,
            ic_yearly: 5.739,
          },
          {
            ic_uuid: "a6ba2887-3ec2-40f7-8b09-90d04f2806e1",
            ic_lat: -102879,
            ic_lon: -365822,
            ic_city: "Penedo",
            ic_states: "ALAGOAS",
            ic_january: 6.097,
            ic_february: 5.947,
            ic_march: 5.891,
            ic_april: 5.045,
            ic_may: 4.298,
            ic_june: 4.042,
            ic_july: 4.11,
            ic_august: 4.722,
            ic_september: 5.442,
            ic_october: 5.861,
            ic_november: 6.297,
            ic_december: 6.312,
            ic_yearly: 5.338,
          },
          {
            ic_uuid: "c0e23165-f799-43f5-88d5-6b8098bc9464",
            ic_lat: -267759,
            ic_lon: -486469,
            ic_city: "Penha",
            ic_states: "SANTA CATARINA",
            ic_january: 5.735,
            ic_february: 5.436,
            ic_march: 4.669,
            ic_april: 3.682,
            ic_may: 3.027,
            ic_june: 2.52,
            ic_july: 2.617,
            ic_august: 3.263,
            ic_september: 3.501,
            ic_october: 4.249,
            ic_november: 5.405,
            ic_december: 5.786,
            ic_yearly: 4.157,
          },
          {
            ic_uuid: "4e20d886-bedc-4596-baed-2ab99e5a0013",
            ic_lat: -37931,
            ic_lon: -392695,
            ic_city: "Pentecoste",
            ic_states: "CEARÁ",
            ic_january: 5.305,
            ic_february: 5.458,
            ic_march: 5.3,
            ic_april: 4.854,
            ic_may: 5.22,
            ic_june: 5.089,
            ic_july: 5.347,
            ic_august: 6.098,
            ic_september: 6.456,
            ic_october: 6.349,
            ic_november: 6.207,
            ic_december: 5.613,
            ic_yearly: 5.608,
          },
          {
            ic_uuid: "e7908f3f-9fe3-4d71-ba01-6cd7a7ea0509",
            ic_lat: -218346,
            ic_lon: -431149,
            ic_city: "Pequeri",
            ic_states: "MINAS GERAIS",
            ic_january: 5.68,
            ic_february: 5.866,
            ic_march: 4.872,
            ic_april: 4.204,
            ic_may: 3.522,
            ic_june: 3.34,
            ic_july: 3.547,
            ic_august: 4.241,
            ic_september: 4.578,
            ic_october: 4.865,
            ic_november: 4.699,
            ic_december: 5.425,
            ic_yearly: 4.57,
          },
          {
            ic_uuid: "f0385f3f-3403-4ce3-8e49-27c7cb1b6b5a",
            ic_lat: -196288,
            ic_lon: -446608,
            ic_city: "Pequi",
            ic_states: "MINAS GERAIS",
            ic_january: 5.948,
            ic_february: 6.149,
            ic_march: 5.199,
            ic_april: 4.927,
            ic_may: 4.341,
            ic_june: 4.128,
            ic_july: 4.454,
            ic_august: 5.316,
            ic_september: 5.597,
            ic_october: 5.621,
            ic_november: 5.382,
            ic_december: 5.694,
            ic_yearly: 5.229,
          },
          {
            ic_uuid: "a7dadec4-342f-4c3a-991b-46fab2ebd582",
            ic_lat: -85936,
            ic_lon: -489331,
            ic_city: "Pequizeiro",
            ic_states: "TOCANTINS",
            ic_january: 4.846,
            ic_february: 4.877,
            ic_march: 4.719,
            ic_april: 4.696,
            ic_may: 4.965,
            ic_june: 5.185,
            ic_july: 5.356,
            ic_august: 6.011,
            ic_september: 5.669,
            ic_october: 5.086,
            ic_november: 4.912,
            ic_december: 4.758,
            ic_yearly: 5.09,
          },
          {
            ic_uuid: "bde441c9-7349-4deb-b469-8aaff5a39327",
            ic_lat: -199521,
            ic_lon: -450858,
            ic_city: "Perdigão",
            ic_states: "MINAS GERAIS",
            ic_january: 5.844,
            ic_february: 6.007,
            ic_march: 5.105,
            ic_april: 4.925,
            ic_may: 4.33,
            ic_june: 4.158,
            ic_july: 4.379,
            ic_august: 5.291,
            ic_september: 5.489,
            ic_october: 5.557,
            ic_november: 5.273,
            ic_december: 5.633,
            ic_yearly: 5.166,
          },
          {
            ic_uuid: "220d9e1c-b39c-476b-9110-b379bf00491a",
            ic_lat: -193492,
            ic_lon: -472934,
            ic_city: "Perdizes",
            ic_states: "MINAS GERAIS",
            ic_january: 5.578,
            ic_february: 5.972,
            ic_march: 5.022,
            ic_april: 4.912,
            ic_may: 4.522,
            ic_june: 4.314,
            ic_july: 4.549,
            ic_august: 5.536,
            ic_september: 5.463,
            ic_october: 5.596,
            ic_november: 5.438,
            ic_december: 5.699,
            ic_yearly: 5.217,
          },
          {
            ic_uuid: "ad611395-0d71-4fb2-ae30-766c4226f6f7",
            ic_lat: -210937,
            ic_lon: -4509,
            ic_city: "Perdões",
            ic_states: "MINAS GERAIS",
            ic_january: 5.631,
            ic_february: 5.796,
            ic_march: 4.973,
            ic_april: 4.732,
            ic_may: 4.014,
            ic_june: 3.798,
            ic_july: 4.078,
            ic_august: 4.966,
            ic_september: 5.169,
            ic_october: 5.413,
            ic_november: 5.169,
            ic_december: 5.632,
            ic_yearly: 4.948,
          },
          {
            ic_uuid: "42f3755f-ccb2-45f9-a418-6291e4835fec",
            ic_lat: -206372,
            ic_lon: -511127,
            ic_city: "Pereira Barreto",
            ic_states: "SÃO PAULO",
            ic_january: 5.951,
            ic_february: 6.011,
            ic_march: 5.404,
            ic_april: 4.9,
            ic_may: 4.119,
            ic_june: 3.738,
            ic_july: 3.994,
            ic_august: 4.936,
            ic_september: 4.904,
            ic_october: 5.618,
            ic_november: 6.036,
            ic_december: 6.354,
            ic_yearly: 5.164,
          },
          {
            ic_uuid: "9ea461a3-29d7-44ee-bfb7-b980a60f9cfa",
            ic_lat: -230808,
            ic_lon: -479724,
            ic_city: "Pereiras",
            ic_states: "SÃO PAULO",
            ic_january: 5.628,
            ic_february: 5.842,
            ic_march: 5.16,
            ic_april: 4.62,
            ic_may: 3.704,
            ic_june: 3.468,
            ic_july: 3.584,
            ic_august: 4.58,
            ic_september: 4.753,
            ic_october: 5.349,
            ic_november: 5.786,
            ic_december: 6.179,
            ic_yearly: 4.888,
          },
          {
            ic_uuid: "03025850-6892-4753-9ad2-633cc9395b25",
            ic_lat: -60362,
            ic_lon: -384627,
            ic_city: "Pereiro",
            ic_states: "CEARÁ",
            ic_january: 5.905,
            ic_february: 5.912,
            ic_march: 5.94,
            ic_april: 5.592,
            ic_may: 5.268,
            ic_june: 5.094,
            ic_july: 5.42,
            ic_august: 6.1,
            ic_september: 6.48,
            ic_october: 6.662,
            ic_november: 6.653,
            ic_december: 6.146,
            ic_yearly: 5.931,
          },
          {
            ic_uuid: "52261021-4052-4d73-9481-d193bfb6ffcc",
            ic_lat: -25778,
            ic_lon: -448549,
            ic_city: "Peri Mirim",
            ic_states: "MARANHÃO",
            ic_january: 4.68,
            ic_february: 4.717,
            ic_march: 4.661,
            ic_april: 4.703,
            ic_may: 4.752,
            ic_june: 5.034,
            ic_july: 5.194,
            ic_august: 5.664,
            ic_september: 5.839,
            ic_october: 5.586,
            ic_november: 5.34,
            ic_december: 5.01,
            ic_yearly: 5.098,
          },
          {
            ic_uuid: "03ad7b02-44bb-4e80-bd7d-3bf0802e88fb",
            ic_lat: -191563,
            ic_lon: -42241,
            ic_city: "Periquito",
            ic_states: "MINAS GERAIS",
            ic_january: 5.97,
            ic_february: 6.205,
            ic_march: 5.246,
            ic_april: 4.692,
            ic_may: 3.941,
            ic_june: 3.726,
            ic_july: 3.851,
            ic_august: 4.523,
            ic_september: 4.931,
            ic_october: 5.146,
            ic_november: 4.949,
            ic_december: 5.813,
            ic_yearly: 4.916,
          },
          {
            ic_uuid: "9ad8df92-c0ca-4209-a1f0-c57bbeea4388",
            ic_lat: -273759,
            ic_lon: -519022,
            ic_city: "Peritiba",
            ic_states: "SANTA CATARINA",
            ic_january: 6.309,
            ic_february: 5.736,
            ic_march: 5.108,
            ic_april: 4.043,
            ic_may: 3.069,
            ic_june: 2.529,
            ic_july: 2.858,
            ic_august: 3.719,
            ic_september: 3.973,
            ic_october: 5.066,
            ic_november: 6.262,
            ic_december: 6.58,
            ic_yearly: 4.604,
          },
          {
            ic_uuid: "a73873b7-e587-477f-99c0-d69f5bddb69f",
            ic_lat: -4373,
            ic_lon: -443313,
            ic_city: "Peritoró",
            ic_states: "MARANHÃO",
            ic_january: 4.696,
            ic_february: 4.889,
            ic_march: 4.954,
            ic_april: 4.872,
            ic_may: 4.887,
            ic_june: 5.042,
            ic_july: 5.383,
            ic_august: 5.942,
            ic_september: 6.293,
            ic_october: 5.954,
            ic_november: 5.539,
            ic_december: 5.119,
            ic_yearly: 5.297,
          },
          {
            ic_uuid: "6af033da-21bd-4e0a-8912-1d5d3b556302",
            ic_lat: -238953,
            ic_lon: -534103,
            ic_city: "Perobal",
            ic_states: "PARANÁ",
            ic_january: 6.135,
            ic_february: 5.851,
            ic_march: 5.339,
            ic_april: 4.469,
            ic_may: 3.527,
            ic_june: 3.117,
            ic_july: 3.335,
            ic_august: 4.319,
            ic_september: 4.558,
            ic_october: 5.356,
            ic_november: 6.178,
            ic_december: 6.49,
            ic_yearly: 4.89,
          },
          {
            ic_uuid: "312b6853-71e6-4799-96f1-92392cbdd7a3",
            ic_lat: -238044,
            ic_lon: -536838,
            ic_city: "Pérola",
            ic_states: "PARANÁ",
            ic_january: 6.09,
            ic_february: 5.879,
            ic_march: 5.342,
            ic_april: 4.477,
            ic_may: 3.499,
            ic_june: 3.138,
            ic_july: 3.339,
            ic_august: 4.325,
            ic_september: 4.576,
            ic_october: 5.363,
            ic_november: 6.148,
            ic_december: 6.48,
            ic_yearly: 4.888,
          },
          {
            ic_uuid: "acc1ad70-1e7b-48dd-aa08-10e8abe5578a",
            ic_lat: -258214,
            ic_lon: -537452,
            ic_city: "Pérola d'Oeste",
            ic_states: "PARANÁ",
            ic_january: 6.39,
            ic_february: 5.91,
            ic_march: 5.288,
            ic_april: 4.265,
            ic_may: 3.296,
            ic_june: 2.834,
            ic_july: 3.108,
            ic_august: 4.063,
            ic_september: 4.258,
            ic_october: 5.278,
            ic_november: 6.185,
            ic_december: 6.505,
            ic_yearly: 4.782,
          },
          {
            ic_uuid: "79339dda-e517-403a-aaef-d17ccfea537b",
            ic_lat: -175263,
            ic_lon: -520654,
            ic_city: "Perolândia",
            ic_states: "GOIÁS",
            ic_january: 5.507,
            ic_february: 5.542,
            ic_march: 5.138,
            ic_april: 5.053,
            ic_may: 4.667,
            ic_june: 4.391,
            ic_july: 4.494,
            ic_august: 5.435,
            ic_september: 5.265,
            ic_october: 5.292,
            ic_november: 5.534,
            ic_december: 5.628,
            ic_yearly: 5.162,
          },
          {
            ic_uuid: "03cc6611-88c1-4217-a710-bea00bc02a24",
            ic_lat: -243125,
            ic_lon: -470016,
            ic_city: "Peruíbe",
            ic_states: "SÃO PAULO",
            ic_january: 5.178,
            ic_february: 5.311,
            ic_march: 4.461,
            ic_april: 3.934,
            ic_may: 3.179,
            ic_june: 2.8,
            ic_july: 2.824,
            ic_august: 3.494,
            ic_september: 3.526,
            ic_october: 3.871,
            ic_november: 4.697,
            ic_december: 5.324,
            ic_yearly: 4.05,
          },
          {
            ic_uuid: "69f1bb09-5e5f-45cd-9026-528ec583545e",
            ic_lat: -183574,
            ic_lon: -416009,
            ic_city: "Pescador",
            ic_states: "MINAS GERAIS",
            ic_january: 5.988,
            ic_february: 6.171,
            ic_march: 5.325,
            ic_april: 4.635,
            ic_may: 3.882,
            ic_june: 3.594,
            ic_july: 3.752,
            ic_august: 4.422,
            ic_september: 4.987,
            ic_october: 5.225,
            ic_november: 4.981,
            ic_december: 5.823,
            ic_yearly: 4.899,
          },
          {
            ic_uuid: "0e4a21e2-2820-4277-a808-2181670f7441",
            ic_lat: -28382,
            ic_lon: -488764,
            ic_city: "Pescaria Brava",
            ic_states: "SANTA CATARINA",
            ic_january: 5.914,
            ic_february: 5.423,
            ic_march: 4.632,
            ic_april: 3.805,
            ic_may: 3.002,
            ic_june: 2.513,
            ic_july: 2.736,
            ic_august: 3.451,
            ic_september: 3.615,
            ic_october: 4.446,
            ic_november: 5.793,
            ic_december: 6.182,
            ic_yearly: 4.293,
          },
          {
            ic_uuid: "896d7dcc-e834-4317-86c5-b5bde8f6baa9",
            ic_lat: -83584,
            ic_lon: -366981,
            ic_city: "Pesqueira",
            ic_states: "PERNAMBUCO",
            ic_january: 6.104,
            ic_february: 5.922,
            ic_march: 5.931,
            ic_april: 5.37,
            ic_may: 4.415,
            ic_june: 3.872,
            ic_july: 3.983,
            ic_august: 4.761,
            ic_september: 5.66,
            ic_october: 6.036,
            ic_november: 6.455,
            ic_december: 6.321,
            ic_yearly: 5.402,
          },
          {
            ic_uuid: "8dbe507d-6258-4263-8526-12c4a17759a8",
            ic_lat: -90771,
            ic_lon: -3829,
            ic_city: "Petrolândia",
            ic_states: "PERNAMBUCO",
            ic_january: 6.335,
            ic_february: 6.123,
            ic_march: 6.094,
            ic_april: 5.461,
            ic_may: 4.679,
            ic_june: 4.165,
            ic_july: 4.389,
            ic_august: 5.132,
            ic_september: 6.013,
            ic_october: 6.181,
            ic_november: 6.639,
            ic_december: 6.47,
            ic_yearly: 5.64,
          },
          {
            ic_uuid: "8170464e-c593-412c-b046-2fdf2303d609",
            ic_lat: -275351,
            ic_lon: -496942,
            ic_city: "Petrolândia",
            ic_states: "SANTA CATARINA",
            ic_january: 5.691,
            ic_february: 5.37,
            ic_march: 4.666,
            ic_april: 3.699,
            ic_may: 2.897,
            ic_june: 2.394,
            ic_july: 2.576,
            ic_august: 3.365,
            ic_september: 3.528,
            ic_october: 4.153,
            ic_november: 5.455,
            ic_december: 5.796,
            ic_yearly: 4.132,
          },
          {
            ic_uuid: "5b030d29-4857-4562-a615-862b533840c3",
            ic_lat: -93891,
            ic_lon: -405031,
            ic_city: "Petrolina",
            ic_states: "PERNAMBUCO",
            ic_january: 6.381,
            ic_february: 6.165,
            ic_march: 6.027,
            ic_april: 5.242,
            ic_may: 4.828,
            ic_june: 4.576,
            ic_july: 4.82,
            ic_august: 5.553,
            ic_september: 6.323,
            ic_october: 6.401,
            ic_november: 6.503,
            ic_december: 6.384,
            ic_yearly: 5.767,
          },
          {
            ic_uuid: "88b4943d-9b1a-4c18-9bf0-7b27349183e3",
            ic_lat: -160973,
            ic_lon: -493368,
            ic_city: "Petrolina de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.479,
            ic_february: 5.663,
            ic_march: 5.242,
            ic_april: 5.229,
            ic_may: 4.898,
            ic_june: 4.671,
            ic_july: 4.844,
            ic_august: 5.76,
            ic_september: 5.569,
            ic_october: 5.591,
            ic_november: 5.308,
            ic_december: 5.483,
            ic_yearly: 5.312,
          },
          {
            ic_uuid: "8f1c8501-f50f-439b-9772-f295aa619688",
            ic_lat: -225204,
            ic_lon: -43193,
            ic_city: "Petrópolis",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.514,
            ic_february: 5.796,
            ic_march: 4.69,
            ic_april: 4.161,
            ic_may: 3.48,
            ic_june: 3.351,
            ic_july: 3.449,
            ic_august: 4.252,
            ic_september: 4.482,
            ic_october: 4.821,
            ic_november: 4.733,
            ic_december: 5.361,
            ic_yearly: 4.508,
          },
          {
            ic_uuid: "d2ce8098-6666-4316-9d63-98a4656be5a2",
            ic_lat: -104064,
            ic_lon: -364343,
            ic_city: "Piaçabuçu",
            ic_states: "ALAGOAS",
            ic_january: 6.151,
            ic_february: 6.169,
            ic_march: 6.059,
            ic_april: 5.039,
            ic_may: 4.366,
            ic_june: 4.106,
            ic_july: 4.158,
            ic_august: 4.855,
            ic_september: 5.535,
            ic_october: 6.017,
            ic_november: 6.358,
            ic_december: 6.473,
            ic_yearly: 5.441,
          },
          {
            ic_uuid: "c3c7bec2-c277-43ea-923a-33095d7c4f42",
            ic_lat: -21592,
            ic_lon: -505989,
            ic_city: "Piacatu",
            ic_states: "SÃO PAULO",
            ic_january: 5.905,
            ic_february: 5.896,
            ic_march: 5.346,
            ic_april: 4.821,
            ic_may: 3.979,
            ic_june: 3.692,
            ic_july: 3.924,
            ic_august: 4.832,
            ic_september: 4.864,
            ic_october: 5.58,
            ic_november: 6.054,
            ic_december: 6.32,
            ic_yearly: 5.101,
          },
          {
            ic_uuid: "b89cef91-760b-48ca-baf5-87fd0ab4b848",
            ic_lat: -71932,
            ic_lon: -379292,
            ic_city: "Piancó",
            ic_states: "PARAÍBA",
            ic_january: 6.205,
            ic_february: 6.207,
            ic_march: 6.319,
            ic_april: 5.905,
            ic_may: 5.222,
            ic_june: 4.83,
            ic_july: 5.063,
            ic_august: 5.857,
            ic_september: 6.497,
            ic_october: 6.657,
            ic_november: 6.757,
            ic_december: 6.492,
            ic_yearly: 6.001,
          },
          {
            ic_uuid: "58a8a009-261e-4ce5-966b-8d744ff5621f",
            ic_lat: -131526,
            ic_lon: -417704,
            ic_city: "Piatã",
            ic_states: "BAHIA",
            ic_january: 5.923,
            ic_february: 6.066,
            ic_march: 5.519,
            ic_april: 4.759,
            ic_may: 4.243,
            ic_june: 4.003,
            ic_july: 4.227,
            ic_august: 4.985,
            ic_september: 5.838,
            ic_october: 5.908,
            ic_november: 5.592,
            ic_december: 6.114,
            ic_yearly: 5.265,
          },
          {
            ic_uuid: "104e33f1-4da5-48b8-b0fd-b72c215ad9d4",
            ic_lat: -215101,
            ic_lon: -433134,
            ic_city: "Piau",
            ic_states: "MINAS GERAIS",
            ic_january: 5.425,
            ic_february: 5.863,
            ic_march: 4.839,
            ic_april: 4.216,
            ic_may: 3.575,
            ic_june: 3.385,
            ic_july: 3.557,
            ic_august: 4.336,
            ic_september: 4.627,
            ic_october: 4.85,
            ic_november: 4.649,
            ic_december: 5.459,
            ic_yearly: 4.565,
          },
          {
            ic_uuid: "56c2d41c-a8b1-4682-98a5-799e28f6c65a",
            ic_lat: -294509,
            ic_lon: -511344,
            ic_city: "Picada Café",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.226,
            ic_february: 5.735,
            ic_march: 4.777,
            ic_april: 3.795,
            ic_may: 2.809,
            ic_june: 2.356,
            ic_july: 2.58,
            ic_august: 3.26,
            ic_september: 3.562,
            ic_october: 4.651,
            ic_november: 6.102,
            ic_december: 6.516,
            ic_yearly: 4.364,
          },
          {
            ic_uuid: "092cf346-0813-4b30-8b76-92463963aa7b",
            ic_lat: -64427,
            ic_lon: -488622,
            ic_city: "Piçarra",
            ic_states: "PARÁ",
            ic_january: 4.567,
            ic_february: 4.777,
            ic_march: 4.616,
            ic_april: 4.72,
            ic_may: 4.816,
            ic_june: 5.117,
            ic_july: 5.255,
            ic_august: 5.752,
            ic_september: 5.453,
            ic_october: 4.973,
            ic_november: 4.693,
            ic_december: 4.567,
            ic_yearly: 4.942,
          },
          {
            ic_uuid: "ddc1d92f-cf1a-4203-8521-73c949fec88e",
            ic_lat: -70776,
            ic_lon: -414673,
            ic_city: "Picos",
            ic_states: "PIAUÍ",
            ic_january: 5.61,
            ic_february: 5.507,
            ic_march: 5.62,
            ic_april: 5.35,
            ic_may: 5.171,
            ic_june: 5.109,
            ic_july: 5.5,
            ic_august: 6.269,
            ic_september: 6.675,
            ic_october: 6.444,
            ic_november: 6.372,
            ic_december: 6.017,
            ic_yearly: 5.804,
          },
          {
            ic_uuid: "4d94272a-4b71-4846-a412-e9919c376b7e",
            ic_lat: -65089,
            ic_lon: -3635,
            ic_city: "Picuí",
            ic_states: "PARAÍBA",
            ic_january: 5.964,
            ic_february: 5.905,
            ic_march: 5.902,
            ic_april: 5.533,
            ic_may: 4.962,
            ic_june: 4.588,
            ic_july: 4.837,
            ic_august: 5.56,
            ic_september: 6.093,
            ic_october: 6.33,
            ic_november: 6.388,
            ic_december: 5.987,
            ic_yearly: 5.671,
          },
          {
            ic_uuid: "a6770c14-0e8f-456b-99ce-fb88679f93aa",
            ic_lat: -237144,
            ic_lon: -47426,
            ic_city: "Piedade",
            ic_states: "SÃO PAULO",
            ic_january: 5.434,
            ic_february: 5.646,
            ic_march: 4.99,
            ic_april: 4.39,
            ic_may: 3.57,
            ic_june: 3.336,
            ic_july: 3.413,
            ic_august: 4.398,
            ic_september: 4.593,
            ic_october: 5.114,
            ic_november: 5.624,
            ic_december: 6.075,
            ic_yearly: 4.715,
          },
          {
            ic_uuid: "f626c05e-7466-45dd-933e-3f8b42ad4da4",
            ic_lat: -197598,
            ic_lon: -420759,
            ic_city: "Piedade de Caratinga",
            ic_states: "MINAS GERAIS",
            ic_january: 5.492,
            ic_february: 5.764,
            ic_march: 4.841,
            ic_april: 4.332,
            ic_may: 3.727,
            ic_june: 3.549,
            ic_july: 3.706,
            ic_august: 4.389,
            ic_september: 4.762,
            ic_october: 4.885,
            ic_november: 4.652,
            ic_december: 5.432,
            ic_yearly: 4.627,
          },
          {
            ic_uuid: "4482508c-f476-4478-9800-39941733de54",
            ic_lat: -202443,
            ic_lon: -427382,
            ic_city: "Piedade de Ponte Nova",
            ic_states: "MINAS GERAIS",
            ic_january: 5.778,
            ic_february: 6.099,
            ic_march: 5.099,
            ic_april: 4.596,
            ic_may: 3.9,
            ic_june: 3.693,
            ic_july: 3.925,
            ic_august: 4.684,
            ic_september: 4.992,
            ic_october: 5.139,
            ic_november: 4.919,
            ic_december: 5.596,
            ic_yearly: 4.868,
          },
          {
            ic_uuid: "0008f74a-5670-43af-aad8-5bd228c1a134",
            ic_lat: -214695,
            ic_lon: -441942,
            ic_city: "Piedade do Rio Grande",
            ic_states: "MINAS GERAIS",
            ic_january: 5.531,
            ic_february: 5.745,
            ic_march: 4.874,
            ic_april: 4.391,
            ic_may: 3.789,
            ic_june: 3.611,
            ic_july: 3.783,
            ic_august: 4.721,
            ic_september: 4.924,
            ic_october: 5.139,
            ic_november: 4.987,
            ic_december: 5.585,
            ic_yearly: 4.757,
          },
          {
            ic_uuid: "2b0b0ab1-b3d7-4e13-b4db-c3077fc00e0c",
            ic_lat: -20472,
            ic_lon: -442247,
            ic_city: "Piedade dos Gerais",
            ic_states: "MINAS GERAIS",
            ic_january: 5.703,
            ic_february: 5.898,
            ic_march: 4.964,
            ic_april: 4.691,
            ic_may: 4.125,
            ic_june: 3.938,
            ic_july: 4.19,
            ic_august: 5.048,
            ic_september: 5.315,
            ic_october: 5.435,
            ic_november: 5.152,
            ic_december: 5.591,
            ic_yearly: 5.004,
          },
          {
            ic_uuid: "144eabd5-e43d-4368-b120-606ef1528d9d",
            ic_lat: -260969,
            ic_lon: -49434,
            ic_city: "Piên",
            ic_states: "PARANÁ",
            ic_january: 5.361,
            ic_february: 5.078,
            ic_march: 4.498,
            ic_april: 3.687,
            ic_may: 2.953,
            ic_june: 2.607,
            ic_july: 2.813,
            ic_august: 3.708,
            ic_september: 3.744,
            ic_october: 4.298,
            ic_november: 5.193,
            ic_december: 5.527,
            ic_yearly: 4.122,
          },
          {
            ic_uuid: "f309ac2c-9555-42f4-99bf-d6d384e60dae",
            ic_lat: -9997,
            ic_lon: -42482,
            ic_city: "Pilão Arcado",
            ic_states: "BAHIA",
            ic_january: 6.504,
            ic_february: 6.226,
            ic_march: 6.037,
            ic_april: 5.607,
            ic_may: 5.41,
            ic_june: 5.178,
            ic_july: 5.463,
            ic_august: 6.015,
            ic_september: 6.357,
            ic_october: 6.546,
            ic_november: 6.476,
            ic_december: 6.395,
            ic_yearly: 6.018,
          },
          {
            ic_uuid: "5c0293c9-a0e7-4b02-be9f-d9491d84f3eb",
            ic_lat: -96018,
            ic_lon: -359546,
            ic_city: "Pilar",
            ic_states: "ALAGOAS",
            ic_january: 5.977,
            ic_february: 5.918,
            ic_march: 5.877,
            ic_april: 5.097,
            ic_may: 4.313,
            ic_june: 3.984,
            ic_july: 4.102,
            ic_august: 4.705,
            ic_september: 5.494,
            ic_october: 5.76,
            ic_november: 6.238,
            ic_december: 6.22,
            ic_yearly: 5.307,
          },
          {
            ic_uuid: "9f07f918-96a4-4ca8-90c1-37fbcf0e7a75",
            ic_lat: -72665,
            ic_lon: -35257,
            ic_city: "Pilar",
            ic_states: "PARAÍBA",
            ic_january: 5.701,
            ic_february: 5.877,
            ic_march: 5.847,
            ic_april: 5.343,
            ic_may: 4.665,
            ic_june: 4.267,
            ic_july: 4.393,
            ic_august: 5.151,
            ic_september: 5.586,
            ic_october: 5.802,
            ic_november: 6.063,
            ic_december: 5.951,
            ic_yearly: 5.387,
          },
          {
            ic_uuid: "7bd554bb-2acc-466e-a969-d1082906090c",
            ic_lat: -147633,
            ic_lon: -495805,
            ic_city: "Pilar de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.314,
            ic_february: 5.452,
            ic_march: 5.053,
            ic_april: 5.035,
            ic_may: 4.926,
            ic_june: 4.693,
            ic_july: 4.936,
            ic_august: 5.939,
            ic_september: 5.563,
            ic_october: 5.47,
            ic_november: 5.143,
            ic_december: 5.337,
            ic_yearly: 5.238,
          },
          {
            ic_uuid: "15b19836-43ed-4808-9433-021c84629ceb",
            ic_lat: -238082,
            ic_lon: -477226,
            ic_city: "Pilar do Sul",
            ic_states: "SÃO PAULO",
            ic_january: 5.415,
            ic_february: 5.611,
            ic_march: 4.912,
            ic_april: 4.388,
            ic_may: 3.497,
            ic_june: 3.288,
            ic_july: 3.37,
            ic_august: 4.302,
            ic_september: 4.472,
            ic_october: 5.029,
            ic_november: 5.614,
            ic_december: 6.102,
            ic_yearly: 4.667,
          },
          {
            ic_uuid: "9a220fa2-5868-44ba-bfdd-9489e6f172d0",
            ic_lat: -68687,
            ic_lon: -356133,
            ic_city: "Pilões",
            ic_states: "PARAÍBA",
            ic_january: 5.626,
            ic_february: 5.747,
            ic_march: 5.727,
            ic_april: 5.234,
            ic_may: 4.639,
            ic_june: 4.138,
            ic_july: 4.26,
            ic_august: 5.047,
            ic_september: 5.515,
            ic_october: 5.796,
            ic_november: 5.973,
            ic_december: 5.798,
            ic_yearly: 5.292,
          },
          {
            ic_uuid: "4adbbcd3-487e-4d52-8928-f727c033c1ce",
            ic_lat: -6264,
            ic_lon: -380464,
            ic_city: "Pilões",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.091,
            ic_february: 6.01,
            ic_march: 6.125,
            ic_april: 5.828,
            ic_may: 5.268,
            ic_june: 4.957,
            ic_july: 5.313,
            ic_august: 6.091,
            ic_september: 6.495,
            ic_october: 6.638,
            ic_november: 6.67,
            ic_december: 6.187,
            ic_yearly: 5.973,
          },
          {
            ic_uuid: "28ea80a5-c8e7-43f0-8e71-d5602b4afee1",
            ic_lat: -68425,
            ic_lon: -355292,
            ic_city: "Pilõezinhos",
            ic_states: "PARAÍBA",
            ic_january: 5.505,
            ic_february: 5.593,
            ic_march: 5.65,
            ic_april: 5.171,
            ic_may: 4.609,
            ic_june: 4.15,
            ic_july: 4.231,
            ic_august: 4.994,
            ic_september: 5.44,
            ic_october: 5.669,
            ic_november: 5.872,
            ic_december: 5.632,
            ic_yearly: 5.21,
          },
          {
            ic_uuid: "7f71214e-1568-481d-bea2-efeb11bcd9e0",
            ic_lat: -204832,
            ic_lon: -458053,
            ic_city: "Pimenta",
            ic_states: "MINAS GERAIS",
            ic_january: 5.597,
            ic_february: 5.823,
            ic_march: 4.88,
            ic_april: 4.791,
            ic_may: 4.224,
            ic_june: 3.993,
            ic_july: 4.28,
            ic_august: 5.191,
            ic_september: 5.294,
            ic_october: 5.518,
            ic_november: 5.114,
            ic_december: 5.597,
            ic_yearly: 5.025,
          },
          {
            ic_uuid: "3187ab68-4d66-4975-bb96-d976975d5c05",
            ic_lat: -116724,
            ic_lon: -611984,
            ic_city: "Pimenta Bueno",
            ic_states: "RONDÔNIA",
            ic_january: 4.43,
            ic_february: 4.407,
            ic_march: 4.551,
            ic_april: 4.512,
            ic_may: 4.193,
            ic_june: 4.402,
            ic_july: 4.547,
            ic_august: 4.996,
            ic_september: 4.888,
            ic_october: 5.088,
            ic_november: 4.981,
            ic_december: 4.821,
            ic_yearly: 4.651,
          },
          {
            ic_uuid: "ac747986-7aaf-41c1-b7a7-e90622df7a19",
            ic_lat: -62388,
            ic_lon: -414116,
            ic_city: "Pimenteiras",
            ic_states: "PIAUÍ",
            ic_january: 5.179,
            ic_february: 5.231,
            ic_march: 5.283,
            ic_april: 5.26,
            ic_may: 5.16,
            ic_june: 5.182,
            ic_july: 5.497,
            ic_august: 6.185,
            ic_september: 6.627,
            ic_october: 6.435,
            ic_november: 6.232,
            ic_december: 5.689,
            ic_yearly: 5.663,
          },
          {
            ic_uuid: "2d81b4f9-6f06-4535-aae3-ad47e88f5c8b",
            ic_lat: -134827,
            ic_lon: -610475,
            ic_city: "Pimenteiras do Oeste",
            ic_states: "RONDÔNIA",
            ic_january: 4.846,
            ic_february: 4.696,
            ic_march: 4.697,
            ic_april: 4.737,
            ic_may: 4.259,
            ic_june: 4.369,
            ic_july: 4.594,
            ic_august: 5.105,
            ic_september: 5.058,
            ic_october: 5.265,
            ic_november: 5.251,
            ic_december: 5.108,
            ic_yearly: 4.832,
          },
          {
            ic_uuid: "02659814-3f03-49c9-b05c-7ec44e68af68",
            ic_lat: -144926,
            ic_lon: -426863,
            ic_city: "Pindaí",
            ic_states: "BAHIA",
            ic_january: 6.719,
            ic_february: 6.681,
            ic_march: 6.043,
            ic_april: 5.492,
            ic_may: 4.882,
            ic_june: 4.67,
            ic_july: 4.886,
            ic_august: 5.572,
            ic_september: 6.017,
            ic_october: 6.483,
            ic_november: 6.038,
            ic_december: 6.563,
            ic_yearly: 5.837,
          },
          {
            ic_uuid: "e756065f-3816-4706-8ebc-a919d5f644f0",
            ic_lat: -229251,
            ic_lon: -454617,
            ic_city: "Pindamonhangaba",
            ic_states: "SÃO PAULO",
            ic_january: 5.397,
            ic_february: 5.525,
            ic_march: 4.74,
            ic_april: 4.332,
            ic_may: 3.581,
            ic_june: 3.383,
            ic_july: 3.477,
            ic_august: 4.436,
            ic_september: 4.515,
            ic_october: 4.988,
            ic_november: 5.102,
            ic_december: 5.624,
            ic_yearly: 4.592,
          },
          {
            ic_uuid: "6d652434-0a36-47ea-81f9-773f0085da50",
            ic_lat: -36102,
            ic_lon: -453423,
            ic_city: "Pindaré-Mirim",
            ic_states: "MARANHÃO",
            ic_january: 4.726,
            ic_february: 4.96,
            ic_march: 4.972,
            ic_april: 4.992,
            ic_may: 4.849,
            ic_june: 4.985,
            ic_july: 5.157,
            ic_august: 5.622,
            ic_september: 5.848,
            ic_october: 5.53,
            ic_november: 5.149,
            ic_december: 4.98,
            ic_yearly: 5.148,
          },
          {
            ic_uuid: "0fd64aee-d41b-4134-8740-b42a29721319",
            ic_lat: -94743,
            ic_lon: -362921,
            ic_city: "Pindoba",
            ic_states: "ALAGOAS",
            ic_january: 5.814,
            ic_february: 5.734,
            ic_march: 5.748,
            ic_april: 5.014,
            ic_may: 4.166,
            ic_june: 3.751,
            ic_july: 3.885,
            ic_august: 4.431,
            ic_september: 5.283,
            ic_october: 5.623,
            ic_november: 6.158,
            ic_december: 6.113,
            ic_yearly: 5.143,
          },
          {
            ic_uuid: "54034a78-0f65-45fa-b0c5-9fa2e777fe29",
            ic_lat: -107438,
            ic_lon: -403678,
            ic_city: "Pindobaçu",
            ic_states: "BAHIA",
            ic_january: 6.016,
            ic_february: 5.817,
            ic_march: 5.679,
            ic_april: 4.673,
            ic_may: 3.911,
            ic_june: 3.607,
            ic_july: 3.86,
            ic_august: 4.507,
            ic_september: 5.569,
            ic_october: 5.653,
            ic_november: 5.867,
            ic_december: 5.947,
            ic_yearly: 5.092,
          },
          {
            ic_uuid: "dd557a23-f7bb-49bd-a6a8-4fe979a5372f",
            ic_lat: -211858,
            ic_lon: -48909,
            ic_city: "Pindorama",
            ic_states: "SÃO PAULO",
            ic_january: 5.75,
            ic_february: 5.803,
            ic_march: 5.164,
            ic_april: 4.8,
            ic_may: 4.076,
            ic_june: 3.837,
            ic_july: 4.05,
            ic_august: 4.988,
            ic_september: 5.061,
            ic_october: 5.576,
            ic_november: 5.859,
            ic_december: 6.109,
            ic_yearly: 5.089,
          },
          {
            ic_uuid: "ae5a5c89-f05c-43bb-b867-7b0c53bde308",
            ic_lat: -111358,
            ic_lon: -475782,
            ic_city: "Pindorama do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 5.441,
            ic_february: 5.279,
            ic_march: 5.026,
            ic_april: 5.164,
            ic_may: 5.215,
            ic_june: 5.232,
            ic_july: 5.511,
            ic_august: 6.129,
            ic_september: 6.118,
            ic_october: 5.635,
            ic_november: 5.32,
            ic_december: 5.365,
            ic_yearly: 5.453,
          },
          {
            ic_uuid: "cfadd2c3-cf82-40a5-9730-a17a4cd4f9d6",
            ic_lat: -40162,
            ic_lon: -383064,
            ic_city: "Pindoretama",
            ic_states: "CEARÁ",
            ic_january: 5.812,
            ic_february: 5.914,
            ic_march: 5.642,
            ic_april: 5.02,
            ic_may: 5.324,
            ic_june: 5.326,
            ic_july: 5.536,
            ic_august: 6.067,
            ic_september: 6.192,
            ic_october: 6.387,
            ic_november: 6.419,
            ic_december: 5.986,
            ic_yearly: 5.802,
          },
          {
            ic_uuid: "781f4485-a0ad-45c7-b0ae-89dc0d4ba9f5",
            ic_lat: -197278,
            ic_lon: -424081,
            ic_city: "Pingo-d'Água",
            ic_states: "MINAS GERAIS",
            ic_january: 5.948,
            ic_february: 6.217,
            ic_march: 5.233,
            ic_april: 4.719,
            ic_may: 4.007,
            ic_june: 3.796,
            ic_july: 3.979,
            ic_august: 4.641,
            ic_september: 4.979,
            ic_october: 5.168,
            ic_november: 4.983,
            ic_december: 5.792,
            ic_yearly: 4.955,
          },
          {
            ic_uuid: "038ab1ad-ca73-43e8-9143-1226882d0e88",
            ic_lat: -254434,
            ic_lon: -491931,
            ic_city: "Pinhais",
            ic_states: "PARANÁ",
            ic_january: 5.309,
            ic_february: 5.149,
            ic_march: 4.499,
            ic_april: 3.771,
            ic_may: 3.039,
            ic_june: 2.728,
            ic_july: 2.89,
            ic_august: 3.83,
            ic_september: 3.794,
            ic_october: 4.341,
            ic_november: 5.208,
            ic_december: 5.559,
            ic_yearly: 4.176,
          },
          {
            ic_uuid: "4c3b96a2-9cb0-4ebf-afae-53f6a9101613",
            ic_lat: -275085,
            ic_lon: -532086,
            ic_city: "Pinhal",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.329,
            ic_february: 5.908,
            ic_march: 5.135,
            ic_april: 4.104,
            ic_may: 3.115,
            ic_june: 2.541,
            ic_july: 2.907,
            ic_august: 3.72,
            ic_september: 3.984,
            ic_october: 5.179,
            ic_november: 6.279,
            ic_december: 6.638,
            ic_yearly: 4.653,
          },
          {
            ic_uuid: "a525f543-6f53-462c-b698-c9da10c5b1be",
            ic_lat: -278756,
            ic_lon: -511678,
            ic_city: "Pinhal da Serra",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.118,
            ic_february: 5.713,
            ic_march: 4.911,
            ic_april: 3.98,
            ic_may: 2.976,
            ic_june: 2.58,
            ic_july: 2.869,
            ic_august: 3.648,
            ic_september: 3.876,
            ic_october: 4.896,
            ic_november: 6.132,
            ic_december: 6.396,
            ic_yearly: 4.508,
          },
          {
            ic_uuid: "7d2b2afb-2dd7-4cf6-ac23-eba0f2a2680a",
            ic_lat: -260329,
            ic_lon: -534824,
            ic_city: "Pinhal de São Bento",
            ic_states: "PARANÁ",
            ic_january: 6.382,
            ic_february: 5.833,
            ic_march: 5.243,
            ic_april: 4.22,
            ic_may: 3.272,
            ic_june: 2.844,
            ic_july: 3.115,
            ic_august: 4.063,
            ic_september: 4.27,
            ic_october: 5.244,
            ic_november: 6.137,
            ic_december: 6.466,
            ic_yearly: 4.757,
          },
          {
            ic_uuid: "5d5ee1b9-78ae-4a2b-90e4-7516dc8d6793",
            ic_lat: -293353,
            ic_lon: -533386,
            ic_city: "Pinhal Grande",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.343,
            ic_february: 5.971,
            ic_march: 5.052,
            ic_april: 3.898,
            ic_may: 2.937,
            ic_june: 2.406,
            ic_july: 2.701,
            ic_august: 3.467,
            ic_september: 3.838,
            ic_october: 4.979,
            ic_november: 6.298,
            ic_december: 6.708,
            ic_yearly: 4.55,
          },
          {
            ic_uuid: "9179f3ec-0e72-4a81-a264-5cfc71262800",
            ic_lat: -23793,
            ic_lon: -500559,
            ic_city: "Pinhalão",
            ic_states: "PARANÁ",
            ic_january: 5.72,
            ic_february: 5.742,
            ic_march: 5.126,
            ic_april: 4.504,
            ic_may: 3.582,
            ic_june: 3.193,
            ic_july: 3.454,
            ic_august: 4.428,
            ic_september: 4.625,
            ic_october: 5.245,
            ic_november: 5.961,
            ic_december: 6.294,
            ic_yearly: 4.823,
          },
          {
            ic_uuid: "c346c7f0-344c-4240-ab50-b1b657118c7c",
            ic_lat: -2685,
            ic_lon: -529917,
            ic_city: "Pinhalzinho",
            ic_states: "SANTA CATARINA",
            ic_january: 6.142,
            ic_february: 5.646,
            ic_march: 5.11,
            ic_april: 4.099,
            ic_may: 3.151,
            ic_june: 2.633,
            ic_july: 2.954,
            ic_august: 3.834,
            ic_september: 4.072,
            ic_october: 5.105,
            ic_november: 6.086,
            ic_december: 6.375,
            ic_yearly: 4.601,
          },
          {
            ic_uuid: "22e92d16-b9fc-41a3-b650-a69ad989b911",
            ic_lat: -227816,
            ic_lon: -465901,
            ic_city: "Pinhalzinho",
            ic_states: "SÃO PAULO",
            ic_january: 5.385,
            ic_february: 5.679,
            ic_march: 4.983,
            ic_april: 4.582,
            ic_may: 3.815,
            ic_june: 3.541,
            ic_july: 3.719,
            ic_august: 4.668,
            ic_september: 4.863,
            ic_october: 5.426,
            ic_november: 5.617,
            ic_december: 5.99,
            ic_yearly: 4.856,
          },
          {
            ic_uuid: "ae68efef-5e1f-4b44-8a14-99bb429ee7af",
            ic_lat: -256949,
            ic_lon: -51654,
            ic_city: "Pinhão",
            ic_states: "PARANÁ",
            ic_january: 5.905,
            ic_february: 5.418,
            ic_march: 5.036,
            ic_april: 4.087,
            ic_may: 3.219,
            ic_june: 2.869,
            ic_july: 3.102,
            ic_august: 4.111,
            ic_september: 4.287,
            ic_october: 5.08,
            ic_november: 5.863,
            ic_december: 6.137,
            ic_yearly: 4.593,
          },
          {
            ic_uuid: "9285bb3c-620a-486a-954e-2188eb4d793b",
            ic_lat: -105681,
            ic_lon: -377246,
            ic_city: "Pinhão",
            ic_states: "SERGIPE",
            ic_january: 5.994,
            ic_february: 5.843,
            ic_march: 5.758,
            ic_april: 5.061,
            ic_may: 4.28,
            ic_june: 3.947,
            ic_july: 4.096,
            ic_august: 4.633,
            ic_september: 5.379,
            ic_october: 5.587,
            ic_november: 6.144,
            ic_december: 6.071,
            ic_yearly: 5.233,
          },
          {
            ic_uuid: "a8d583dd-1948-47f5-9231-d85f4d43ea7b",
            ic_lat: -225177,
            ic_lon: -440025,
            ic_city: "Pinheiral",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.715,
            ic_february: 5.996,
            ic_march: 4.936,
            ic_april: 4.37,
            ic_may: 3.594,
            ic_june: 3.396,
            ic_july: 3.436,
            ic_august: 4.351,
            ic_september: 4.533,
            ic_october: 4.952,
            ic_november: 5.038,
            ic_december: 5.727,
            ic_yearly: 4.671,
          },
          {
            ic_uuid: "01971f90-269e-436c-8ac1-15435d8e1489",
            ic_lat: -272114,
            ic_lon: -536085,
            ic_city: "Pinheirinho do Vale",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.456,
            ic_february: 5.859,
            ic_march: 5.155,
            ic_april: 4.036,
            ic_may: 3.101,
            ic_june: 2.53,
            ic_july: 2.866,
            ic_august: 3.725,
            ic_september: 4.003,
            ic_october: 5.186,
            ic_november: 6.2,
            ic_december: 6.589,
            ic_yearly: 4.642,
          },
          {
            ic_uuid: "cd8cf88c-d48f-4519-9bd5-5a250aee2884",
            ic_lat: -25226,
            ic_lon: -450792,
            ic_city: "Pinheiro",
            ic_states: "MARANHÃO",
            ic_january: 4.67,
            ic_february: 4.67,
            ic_march: 4.558,
            ic_april: 4.494,
            ic_may: 4.709,
            ic_june: 4.968,
            ic_july: 5.11,
            ic_august: 5.665,
            ic_september: 5.821,
            ic_october: 5.544,
            ic_november: 5.31,
            ic_december: 4.912,
            ic_yearly: 5.036,
          },
          {
            ic_uuid: "27e28b82-80c7-431d-9f69-7c67dee2ac26",
            ic_lat: -315799,
            ic_lon: -533803,
            ic_city: "Pinheiro Machado",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.292,
            ic_february: 5.76,
            ic_march: 4.86,
            ic_april: 3.695,
            ic_may: 2.653,
            ic_june: 2.273,
            ic_july: 2.477,
            ic_august: 3.114,
            ic_september: 3.682,
            ic_october: 4.985,
            ic_november: 6.306,
            ic_december: 6.869,
            ic_yearly: 4.414,
          },
          {
            ic_uuid: "29501782-d881-4fa9-af43-f17e6ebc4f79",
            ic_lat: -270449,
            ic_lon: -512298,
            ic_city: "Pinheiro Preto",
            ic_states: "SANTA CATARINA",
            ic_january: 5.757,
            ic_february: 5.353,
            ic_march: 4.845,
            ic_april: 3.944,
            ic_may: 3.015,
            ic_june: 2.648,
            ic_july: 2.893,
            ic_august: 3.782,
            ic_september: 3.959,
            ic_october: 4.766,
            ic_november: 5.898,
            ic_december: 6.087,
            ic_yearly: 4.412,
          },
          {
            ic_uuid: "582703ca-8cfc-4bb6-8188-c5a9e2da37bd",
            ic_lat: -184145,
            ic_lon: -402175,
            ic_city: "Pinheiros",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.116,
            ic_february: 6.165,
            ic_march: 5.295,
            ic_april: 4.542,
            ic_may: 3.826,
            ic_june: 3.566,
            ic_july: 3.657,
            ic_august: 4.251,
            ic_september: 4.841,
            ic_october: 5.1,
            ic_november: 5.026,
            ic_december: 5.94,
            ic_yearly: 4.861,
          },
          {
            ic_uuid: "26d63343-6d09-445f-9754-8b50c2eafb0a",
            ic_lat: -118122,
            ic_lon: -399087,
            ic_city: "Pintadas",
            ic_states: "BAHIA",
            ic_january: 6.049,
            ic_february: 5.858,
            ic_march: 5.722,
            ic_april: 4.691,
            ic_may: 3.912,
            ic_june: 3.661,
            ic_july: 3.769,
            ic_august: 4.328,
            ic_september: 5.25,
            ic_october: 5.396,
            ic_november: 5.69,
            ic_december: 5.969,
            ic_yearly: 5.025,
          },
          {
            ic_uuid: "a2fb5f91-7926-4e1d-98b8-201f4d1aebc6",
            ic_lat: -290974,
            ic_lon: -51453,
            ic_city: "Pinto Bandeira",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.226,
            ic_february: 5.749,
            ic_march: 4.823,
            ic_april: 3.843,
            ic_may: 2.859,
            ic_june: 2.387,
            ic_july: 2.661,
            ic_august: 3.431,
            ic_september: 3.683,
            ic_october: 4.788,
            ic_november: 6.167,
            ic_december: 6.515,
            ic_yearly: 4.427,
          },
          {
            ic_uuid: "ca92bff3-d240-413a-b987-700f858ff53e",
            ic_lat: -160576,
            ic_lon: -451455,
            ic_city: "Pintópolis",
            ic_states: "MINAS GERAIS",
            ic_january: 6.41,
            ic_february: 6.636,
            ic_march: 5.755,
            ic_april: 5.636,
            ic_may: 5.075,
            ic_june: 4.773,
            ic_july: 5.068,
            ic_august: 5.898,
            ic_september: 6.021,
            ic_october: 6.18,
            ic_november: 5.627,
            ic_december: 6.142,
            ic_yearly: 5.768,
          },
          {
            ic_uuid: "e9dcaf58-1727-4fa6-9f12-a8552cce7601",
            ic_lat: -68338,
            ic_lon: -40614,
            ic_city: "Pio IX",
            ic_states: "PIAUÍ",
            ic_january: 5.412,
            ic_february: 5.348,
            ic_march: 5.388,
            ic_april: 5.13,
            ic_may: 4.981,
            ic_june: 4.966,
            ic_july: 5.417,
            ic_august: 6.277,
            ic_september: 6.684,
            ic_october: 6.542,
            ic_november: 6.344,
            ic_december: 5.943,
            ic_yearly: 5.703,
          },
          {
            ic_uuid: "e6357b39-80cd-4838-97ec-05e997efe06a",
            ic_lat: -38935,
            ic_lon: -451763,
            ic_city: "Pio XII",
            ic_states: "MARANHÃO",
            ic_january: 4.69,
            ic_february: 4.924,
            ic_march: 4.945,
            ic_april: 4.889,
            ic_may: 4.797,
            ic_june: 4.987,
            ic_july: 5.154,
            ic_august: 5.61,
            ic_september: 5.914,
            ic_october: 5.568,
            ic_november: 5.187,
            ic_december: 4.972,
            ic_yearly: 5.137,
          },
          {
            ic_uuid: "2d8e4ec7-c605-4c30-8f39-118663f3f4b0",
            ic_lat: -218872,
            ic_lon: -517309,
            ic_city: "Piquerobi",
            ic_states: "SÃO PAULO",
            ic_january: 5.961,
            ic_february: 6.0,
            ic_march: 5.457,
            ic_april: 4.796,
            ic_may: 3.975,
            ic_june: 3.618,
            ic_july: 3.828,
            ic_august: 4.73,
            ic_september: 4.866,
            ic_october: 5.559,
            ic_november: 6.1,
            ic_december: 6.464,
            ic_yearly: 5.113,
          },
          {
            ic_uuid: "10d4ab52-d13f-4ebf-b979-d880186285ec",
            ic_lat: -58007,
            ic_lon: -394173,
            ic_city: "Piquet Carneiro",
            ic_states: "CEARÁ",
            ic_january: 5.407,
            ic_february: 5.487,
            ic_march: 5.59,
            ic_april: 5.177,
            ic_may: 5.027,
            ic_june: 5.011,
            ic_july: 5.36,
            ic_august: 6.085,
            ic_september: 6.483,
            ic_october: 6.464,
            ic_november: 6.394,
            ic_december: 5.831,
            ic_yearly: 5.693,
          },
          {
            ic_uuid: "06f0d711-2854-41c9-9ef9-56fe1bfa2311",
            ic_lat: -226074,
            ic_lon: -451873,
            ic_city: "Piquete",
            ic_states: "SÃO PAULO",
            ic_january: 5.05,
            ic_february: 5.352,
            ic_march: 4.593,
            ic_april: 4.171,
            ic_may: 3.536,
            ic_june: 3.43,
            ic_july: 3.546,
            ic_august: 4.491,
            ic_september: 4.519,
            ic_october: 4.848,
            ic_november: 4.722,
            ic_december: 5.324,
            ic_yearly: 4.465,
          },
          {
            ic_uuid: "88c42add-9ebf-425e-889f-7ee6480b755a",
            ic_lat: -23053,
            ic_lon: -463598,
            ic_city: "Piracaia",
            ic_states: "SÃO PAULO",
            ic_january: 5.34,
            ic_february: 5.719,
            ic_march: 4.89,
            ic_april: 4.423,
            ic_may: 3.668,
            ic_june: 3.385,
            ic_july: 3.487,
            ic_august: 4.522,
            ic_september: 4.568,
            ic_october: 5.154,
            ic_november: 5.416,
            ic_december: 5.97,
            ic_yearly: 4.712,
          },
          {
            ic_uuid: "3a5c334b-333a-4e9e-9315-4504537dbd75",
            ic_lat: -173019,
            ic_lon: -49026,
            ic_city: "Piracanjuba",
            ic_states: "GOIÁS",
            ic_january: 5.623,
            ic_february: 5.731,
            ic_march: 5.26,
            ic_april: 5.147,
            ic_may: 4.771,
            ic_june: 4.483,
            ic_july: 4.663,
            ic_august: 5.549,
            ic_september: 5.441,
            ic_october: 5.632,
            ic_november: 5.474,
            ic_december: 5.68,
            ic_yearly: 5.288,
          },
          {
            ic_uuid: "a616bce5-b539-48f2-b8c2-15972074f673",
            ic_lat: -205093,
            ic_lon: -444787,
            ic_city: "Piracema",
            ic_states: "MINAS GERAIS",
            ic_january: 5.654,
            ic_february: 5.896,
            ic_march: 5.079,
            ic_april: 4.779,
            ic_may: 4.184,
            ic_june: 4.013,
            ic_july: 4.25,
            ic_august: 5.108,
            ic_september: 5.339,
            ic_october: 5.494,
            ic_november: 5.198,
            ic_december: 5.595,
            ic_yearly: 5.049,
          },
          {
            ic_uuid: "ddfffec0-ba2d-42a8-af31-6ab50b41a5f3",
            ic_lat: -227343,
            ic_lon: -47648,
            ic_city: "Piracicaba",
            ic_states: "SÃO PAULO",
            ic_january: 5.613,
            ic_february: 5.777,
            ic_march: 5.108,
            ic_april: 4.612,
            ic_may: 3.798,
            ic_june: 3.525,
            ic_july: 3.703,
            ic_august: 4.656,
            ic_september: 4.822,
            ic_october: 5.423,
            ic_november: 5.684,
            ic_december: 6.073,
            ic_yearly: 4.9,
          },
          {
            ic_uuid: "cc0831b4-4f60-474f-a48c-eb2bf2cfa483",
            ic_lat: -39337,
            ic_lon: -417091,
            ic_city: "Piracuruca",
            ic_states: "PIAUÍ",
            ic_january: 5.297,
            ic_february: 5.398,
            ic_march: 5.306,
            ic_april: 5.038,
            ic_may: 5.237,
            ic_june: 5.413,
            ic_july: 5.62,
            ic_august: 6.063,
            ic_september: 6.442,
            ic_october: 6.563,
            ic_november: 6.384,
            ic_december: 5.719,
            ic_yearly: 5.707,
          },
          {
            ic_uuid: "7ba32d61-224a-40ff-b119-cc7ce21c353c",
            ic_lat: -22622,
            ic_lon: -439084,
            ic_city: "Piraí",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.734,
            ic_february: 6.076,
            ic_march: 4.934,
            ic_april: 4.338,
            ic_may: 3.562,
            ic_june: 3.339,
            ic_july: 3.401,
            ic_august: 4.266,
            ic_september: 4.467,
            ic_october: 4.913,
            ic_november: 5.023,
            ic_december: 5.743,
            ic_yearly: 4.65,
          },
          {
            ic_uuid: "02f99525-6fe1-4a32-9663-0acdf1bbbe03",
            ic_lat: -137604,
            ic_lon: -393772,
            ic_city: "Piraí do Norte",
            ic_states: "BAHIA",
            ic_january: 5.841,
            ic_february: 5.782,
            ic_march: 5.479,
            ic_april: 4.411,
            ic_may: 3.823,
            ic_june: 3.475,
            ic_july: 3.682,
            ic_august: 4.142,
            ic_september: 4.833,
            ic_october: 5.06,
            ic_november: 5.301,
            ic_december: 5.895,
            ic_yearly: 4.811,
          },
          {
            ic_uuid: "ab82444b-d7d2-447b-b2d6-cce0adade2ec",
            ic_lat: -245311,
            ic_lon: -499437,
            ic_city: "Piraí do Sul",
            ic_states: "PARANÁ",
            ic_january: 5.416,
            ic_february: 5.382,
            ic_march: 4.753,
            ic_april: 4.182,
            ic_may: 3.354,
            ic_june: 2.996,
            ic_july: 3.232,
            ic_august: 4.243,
            ic_september: 4.368,
            ic_october: 4.908,
            ic_november: 5.634,
            ic_december: 5.87,
            ic_yearly: 4.528,
          },
          {
            ic_uuid: "3189242e-275e-4d39-885c-4a724b8252bd",
            ic_lat: -231985,
            ic_lon: -493808,
            ic_city: "Piraju",
            ic_states: "SÃO PAULO",
            ic_january: 5.648,
            ic_february: 5.743,
            ic_march: 5.144,
            ic_april: 4.535,
            ic_may: 3.61,
            ic_june: 3.326,
            ic_july: 3.516,
            ic_august: 4.499,
            ic_september: 4.653,
            ic_october: 5.312,
            ic_november: 5.904,
            ic_december: 6.283,
            ic_yearly: 4.848,
          },
          {
            ic_uuid: "28911c50-099b-48ca-977b-8e04aba553aa",
            ic_lat: -199097,
            ic_lon: -487031,
            ic_city: "Pirajuba",
            ic_states: "MINAS GERAIS",
            ic_january: 5.691,
            ic_february: 5.989,
            ic_march: 5.143,
            ic_april: 4.985,
            ic_may: 4.311,
            ic_june: 4.08,
            ic_july: 4.332,
            ic_august: 5.274,
            ic_september: 5.16,
            ic_october: 5.499,
            ic_november: 5.656,
            ic_december: 6.009,
            ic_yearly: 5.177,
          },
          {
            ic_uuid: "08d7650d-3ac8-4ae4-b4f4-af5bbea75b42",
            ic_lat: -21999,
            ic_lon: -494569,
            ic_city: "Pirajuí",
            ic_states: "SÃO PAULO",
            ic_january: 5.708,
            ic_february: 5.868,
            ic_march: 5.205,
            ic_april: 4.778,
            ic_may: 3.902,
            ic_june: 3.595,
            ic_july: 3.864,
            ic_august: 4.765,
            ic_september: 4.85,
            ic_october: 5.589,
            ic_november: 5.906,
            ic_december: 6.235,
            ic_yearly: 5.022,
          },
          {
            ic_uuid: "ed43b7f8-78a7-4c68-9fe9-2a008f4a389d",
            ic_lat: -107389,
            ic_lon: -368577,
            ic_city: "Pirambu",
            ic_states: "SERGIPE",
            ic_january: 6.435,
            ic_february: 6.345,
            ic_march: 6.138,
            ic_april: 5.188,
            ic_may: 4.398,
            ic_june: 4.123,
            ic_july: 4.212,
            ic_august: 4.904,
            ic_september: 5.63,
            ic_october: 6.047,
            ic_november: 6.403,
            ic_december: 6.551,
            ic_yearly: 5.531,
          },
          {
            ic_uuid: "1f54d037-74f1-4da4-afdb-5ce8c74e8497",
            ic_lat: -206839,
            ic_lon: -432971,
            ic_city: "Piranga",
            ic_states: "MINAS GERAIS",
            ic_january: 5.47,
            ic_february: 5.848,
            ic_march: 4.836,
            ic_april: 4.332,
            ic_may: 3.734,
            ic_june: 3.558,
            ic_july: 3.776,
            ic_august: 4.623,
            ic_september: 4.829,
            ic_october: 4.991,
            ic_november: 4.681,
            ic_december: 5.332,
            ic_yearly: 4.667,
          },
          {
            ic_uuid: "7cc60d11-6977-4cf7-93b6-23fa423f5900",
            ic_lat: -210891,
            ic_lon: -486611,
            ic_city: "Pirangi",
            ic_states: "SÃO PAULO",
            ic_january: 5.861,
            ic_february: 5.974,
            ic_march: 5.177,
            ic_april: 4.777,
            ic_may: 4.118,
            ic_june: 3.908,
            ic_july: 4.116,
            ic_august: 4.963,
            ic_september: 5.078,
            ic_october: 5.609,
            ic_november: 5.881,
            ic_december: 6.124,
            ic_yearly: 5.132,
          },
          {
            ic_uuid: "102bb8bd-5c86-42dd-8775-4da9f3f65845",
            ic_lat: -225254,
            ic_lon: -454949,
            ic_city: "Piranguçu",
            ic_states: "MINAS GERAIS",
            ic_january: 5.373,
            ic_february: 5.676,
            ic_march: 4.932,
            ic_april: 4.571,
            ic_may: 3.828,
            ic_june: 3.573,
            ic_july: 3.79,
            ic_august: 4.686,
            ic_september: 4.957,
            ic_october: 5.418,
            ic_november: 5.375,
            ic_december: 5.81,
            ic_yearly: 4.832,
          },
          {
            ic_uuid: "59f8b78b-82c3-406f-8e68-01f58ff988e5",
            ic_lat: -223954,
            ic_lon: -455328,
            ic_city: "Piranguinho",
            ic_states: "MINAS GERAIS",
            ic_january: 5.402,
            ic_february: 5.701,
            ic_march: 4.94,
            ic_april: 4.618,
            ic_may: 3.839,
            ic_june: 3.598,
            ic_july: 3.824,
            ic_august: 4.71,
            ic_september: 4.966,
            ic_october: 5.425,
            ic_november: 5.405,
            ic_december: 5.799,
            ic_yearly: 4.852,
          },
          {
            ic_uuid: "53d6da4d-f43f-40fc-8ecc-277930bb8201",
            ic_lat: -96089,
            ic_lon: -377598,
            ic_city: "Piranhas",
            ic_states: "ALAGOAS",
            ic_january: 6.312,
            ic_february: 6.059,
            ic_march: 5.965,
            ic_april: 5.324,
            ic_may: 4.47,
            ic_june: 4.066,
            ic_july: 4.198,
            ic_august: 4.834,
            ic_september: 5.658,
            ic_october: 5.958,
            ic_november: 6.426,
            ic_december: 6.357,
            ic_yearly: 5.469,
          },
          {
            ic_uuid: "666f23e1-8f64-4e79-9ed2-25904cd50f9d",
            ic_lat: -164272,
            ic_lon: -518197,
            ic_city: "Piranhas",
            ic_states: "GOIÁS",
            ic_january: 5.533,
            ic_february: 5.734,
            ic_march: 5.273,
            ic_april: 5.33,
            ic_may: 4.879,
            ic_june: 4.533,
            ic_july: 4.604,
            ic_august: 5.389,
            ic_september: 5.361,
            ic_october: 5.555,
            ic_november: 5.524,
            ic_december: 5.596,
            ic_yearly: 5.276,
          },
          {
            ic_uuid: "d522da32-014e-4fa0-8ee2-e9cb6a7747b3",
            ic_lat: -3726,
            ic_lon: -442292,
            ic_city: "Pirapemas",
            ic_states: "MARANHÃO",
            ic_january: 4.785,
            ic_february: 5.005,
            ic_march: 5.015,
            ic_april: 4.877,
            ic_may: 4.951,
            ic_june: 5.119,
            ic_july: 5.325,
            ic_august: 5.899,
            ic_september: 6.16,
            ic_october: 5.944,
            ic_november: 5.535,
            ic_december: 5.183,
            ic_yearly: 5.316,
          },
          {
            ic_uuid: "51711a9d-747f-4d6c-99c6-16ecc8f7612b",
            ic_lat: -216559,
            ic_lon: -423438,
            ic_city: "Pirapetinga",
            ic_states: "MINAS GERAIS",
            ic_january: 5.997,
            ic_february: 6.081,
            ic_march: 5.131,
            ic_april: 4.421,
            ic_may: 3.699,
            ic_june: 3.45,
            ic_july: 3.582,
            ic_august: 4.302,
            ic_september: 4.751,
            ic_october: 5.052,
            ic_november: 4.973,
            ic_december: 5.82,
            ic_yearly: 4.772,
          },
          {
            ic_uuid: "e2868a3d-cad7-4c58-824a-8df3e65fa3e5",
            ic_lat: -280444,
            ic_lon: -552006,
            ic_city: "Pirapó",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.612,
            ic_february: 6.092,
            ic_march: 5.242,
            ic_april: 4.049,
            ic_may: 3.098,
            ic_june: 2.512,
            ic_july: 2.821,
            ic_august: 3.604,
            ic_september: 4.076,
            ic_october: 5.182,
            ic_november: 6.373,
            ic_december: 6.763,
            ic_yearly: 4.702,
          },
          {
            ic_uuid: "06e485fc-94bc-4537-92fe-7d8378e6db8c",
            ic_lat: -173396,
            ic_lon: -449344,
            ic_city: "Pirapora",
            ic_states: "MINAS GERAIS",
            ic_january: 6.307,
            ic_february: 6.557,
            ic_march: 5.563,
            ic_april: 5.477,
            ic_may: 4.893,
            ic_june: 4.546,
            ic_july: 4.961,
            ic_august: 5.716,
            ic_september: 6.05,
            ic_october: 6.06,
            ic_november: 5.583,
            ic_december: 5.993,
            ic_yearly: 5.642,
          },
          {
            ic_uuid: "302503ca-604d-437a-a790-03ab3f88784b",
            ic_lat: -23397,
            ic_lon: -469995,
            ic_city: "Pirapora do Bom Jesus",
            ic_states: "SÃO PAULO",
            ic_january: 5.41,
            ic_february: 5.69,
            ic_march: 4.987,
            ic_april: 4.434,
            ic_may: 3.62,
            ic_june: 3.334,
            ic_july: 3.451,
            ic_august: 4.454,
            ic_september: 4.529,
            ic_october: 5.069,
            ic_november: 5.505,
            ic_december: 6.005,
            ic_yearly: 4.707,
          },
          {
            ic_uuid: "1e158a49-b2f5-4478-ba70-5a1ec5e2a11a",
            ic_lat: -222716,
            ic_lon: -514981,
            ic_city: "Pirapozinho",
            ic_states: "SÃO PAULO",
            ic_january: 5.969,
            ic_february: 6.001,
            ic_march: 5.456,
            ic_april: 4.772,
            ic_may: 3.862,
            ic_june: 3.55,
            ic_july: 3.772,
            ic_august: 4.738,
            ic_september: 4.862,
            ic_october: 5.544,
            ic_november: 6.151,
            ic_december: 6.501,
            ic_yearly: 5.098,
          },
          {
            ic_uuid: "29c2a058-69f6-448b-b578-1f064757ed59",
            ic_lat: -254426,
            ic_lon: -490628,
            ic_city: "Piraquara",
            ic_states: "PARANÁ",
            ic_january: 5.319,
            ic_february: 5.141,
            ic_march: 4.487,
            ic_april: 3.734,
            ic_may: 3.038,
            ic_june: 2.711,
            ic_july: 2.877,
            ic_august: 3.798,
            ic_september: 3.785,
            ic_october: 4.358,
            ic_november: 5.188,
            ic_december: 5.547,
            ic_yearly: 4.165,
          },
          {
            ic_uuid: "e7568f67-ac88-4ef8-be5a-c97f511e3ba7",
            ic_lat: -67684,
            ic_lon: -482949,
            ic_city: "Piraquê",
            ic_states: "TOCANTINS",
            ic_january: 4.721,
            ic_february: 4.767,
            ic_march: 4.654,
            ic_april: 4.724,
            ic_may: 4.844,
            ic_june: 5.137,
            ic_july: 5.321,
            ic_august: 5.868,
            ic_september: 5.672,
            ic_october: 5.055,
            ic_november: 4.707,
            ic_december: 4.572,
            ic_yearly: 5.003,
          },
          {
            ic_uuid: "9e3fbbc9-5a88-43f0-9eea-87077b5452f6",
            ic_lat: -219965,
            ic_lon: -474261,
            ic_city: "Pirassununga",
            ic_states: "SÃO PAULO",
            ic_january: 5.657,
            ic_february: 5.924,
            ic_march: 5.156,
            ic_april: 4.729,
            ic_may: 3.959,
            ic_june: 3.703,
            ic_july: 3.887,
            ic_august: 4.805,
            ic_september: 4.958,
            ic_october: 5.561,
            ic_november: 5.737,
            ic_december: 6.045,
            ic_yearly: 5.01,
          },
          {
            ic_uuid: "c52b30fe-6966-4512-9401-85ea7a199923",
            ic_lat: -314483,
            ic_lon: -531003,
            ic_city: "Piratini",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.273,
            ic_february: 5.712,
            ic_march: 4.826,
            ic_april: 3.692,
            ic_may: 2.688,
            ic_june: 2.26,
            ic_july: 2.496,
            ic_august: 3.133,
            ic_september: 3.681,
            ic_october: 4.936,
            ic_november: 6.257,
            ic_december: 6.795,
            ic_yearly: 4.396,
          },
          {
            ic_uuid: "888f1a6f-5388-4bed-b01d-c711c8d2a1f5",
            ic_lat: -224146,
            ic_lon: -491343,
            ic_city: "Piratininga",
            ic_states: "SÃO PAULO",
            ic_january: 5.614,
            ic_february: 5.826,
            ic_march: 5.237,
            ic_april: 4.722,
            ic_may: 3.844,
            ic_june: 3.551,
            ic_july: 3.765,
            ic_august: 4.712,
            ic_september: 4.802,
            ic_october: 5.538,
            ic_november: 5.911,
            ic_december: 6.228,
            ic_yearly: 4.979,
          },
          {
            ic_uuid: "2032f1ea-fcf0-4a12-8a85-8d201f693a67",
            ic_lat: -274247,
            ic_lon: -517673,
            ic_city: "Piratuba",
            ic_states: "SANTA CATARINA",
            ic_january: 6.161,
            ic_february: 5.677,
            ic_march: 5.087,
            ic_april: 4.082,
            ic_may: 3.078,
            ic_june: 2.618,
            ic_july: 2.919,
            ic_august: 3.742,
            ic_september: 3.984,
            ic_october: 5.041,
            ic_november: 6.236,
            ic_december: 6.506,
            ic_yearly: 4.594,
          },
          {
            ic_uuid: "fcbab408-a2a6-413c-bc98-cae65ba1c14d",
            ic_lat: -21283,
            ic_lon: -430176,
            ic_city: "Piraúba",
            ic_states: "MINAS GERAIS",
            ic_january: 5.732,
            ic_february: 5.99,
            ic_march: 4.97,
            ic_april: 4.353,
            ic_may: 3.677,
            ic_june: 3.5,
            ic_july: 3.675,
            ic_august: 4.46,
            ic_september: 4.799,
            ic_october: 5.078,
            ic_november: 4.881,
            ic_december: 5.605,
            ic_yearly: 4.727,
          },
          {
            ic_uuid: "1a1f5991-6477-4405-a758-1dd557ed5d60",
            ic_lat: -158549,
            ic_lon: -489487,
            ic_city: "Pirenópolis",
            ic_states: "GOIÁS",
            ic_january: 5.37,
            ic_february: 5.448,
            ic_march: 5.106,
            ic_april: 5.094,
            ic_may: 4.889,
            ic_june: 4.612,
            ic_july: 4.889,
            ic_august: 5.719,
            ic_september: 5.519,
            ic_october: 5.5,
            ic_november: 5.135,
            ic_december: 5.266,
            ic_yearly: 5.212,
          },
          {
            ic_uuid: "73457e63-b2d3-4f8d-8159-572d5604b8b3",
            ic_lat: -173024,
            ic_lon: -482771,
            ic_city: "Pires do Rio",
            ic_states: "GOIÁS",
            ic_january: 5.697,
            ic_february: 5.89,
            ic_march: 5.279,
            ic_april: 5.274,
            ic_may: 4.771,
            ic_june: 4.543,
            ic_july: 4.757,
            ic_august: 5.674,
            ic_september: 5.427,
            ic_october: 5.697,
            ic_november: 5.452,
            ic_december: 5.709,
            ic_yearly: 5.347,
          },
          {
            ic_uuid: "452ff75f-918c-4fb5-8fb3-c4388734831a",
            ic_lat: -42396,
            ic_lon: -406445,
            ic_city: "Pires Ferreira",
            ic_states: "CEARÁ",
            ic_january: 5.012,
            ic_february: 5.213,
            ic_march: 5.18,
            ic_april: 4.824,
            ic_may: 4.833,
            ic_june: 4.843,
            ic_july: 5.146,
            ic_august: 5.881,
            ic_september: 6.371,
            ic_october: 6.284,
            ic_november: 6.016,
            ic_december: 5.258,
            ic_yearly: 5.405,
          },
          {
            ic_uuid: "4c4612c3-6a50-42a8-9442-27c17f176ee5",
            ic_lat: -149448,
            ic_lon: -417172,
            ic_city: "Piripá",
            ic_states: "BAHIA",
            ic_january: 6.212,
            ic_february: 6.369,
            ic_march: 5.645,
            ic_april: 5.011,
            ic_may: 4.307,
            ic_june: 3.879,
            ic_july: 4.233,
            ic_august: 4.92,
            ic_september: 5.686,
            ic_october: 5.878,
            ic_november: 5.564,
            ic_december: 6.184,
            ic_yearly: 5.324,
          },
          {
            ic_uuid: "d35b50bc-5f77-4c41-987f-f5cd222eb7a6",
            ic_lat: -42747,
            ic_lon: -417762,
            ic_city: "Piripiri",
            ic_states: "PIAUÍ",
            ic_january: 5.212,
            ic_february: 5.35,
            ic_march: 5.371,
            ic_april: 5.06,
            ic_may: 5.224,
            ic_june: 5.245,
            ic_july: 5.491,
            ic_august: 6.125,
            ic_september: 6.519,
            ic_october: 6.429,
            ic_november: 6.266,
            ic_december: 5.706,
            ic_yearly: 5.666,
          },
          {
            ic_uuid: "f6d9b978-1f3d-4e92-b5ec-96da1ac0718d",
            ic_lat: -117308,
            ic_lon: -405548,
            ic_city: "Piritiba",
            ic_states: "BAHIA",
            ic_january: 6.003,
            ic_february: 5.803,
            ic_march: 5.639,
            ic_april: 4.673,
            ic_may: 3.94,
            ic_june: 3.668,
            ic_july: 3.78,
            ic_august: 4.425,
            ic_september: 5.371,
            ic_october: 5.381,
            ic_november: 5.596,
            ic_december: 5.815,
            ic_yearly: 5.008,
          },
          {
            ic_uuid: "da591b72-ddba-40ca-990c-2539cb70642a",
            ic_lat: -678,
            ic_lon: -354911,
            ic_city: "Pirpirituba",
            ic_states: "PARAÍBA",
            ic_january: 5.543,
            ic_february: 5.596,
            ic_march: 5.688,
            ic_april: 5.216,
            ic_may: 4.636,
            ic_june: 4.192,
            ic_july: 4.279,
            ic_august: 5.027,
            ic_september: 5.484,
            ic_october: 5.758,
            ic_november: 6.0,
            ic_december: 5.743,
            ic_yearly: 5.263,
          },
          {
            ic_uuid: "855a9e32-fe5d-4417-83f5-238d16aa293d",
            ic_lat: -247592,
            ic_lon: -517601,
            ic_city: "Pitanga",
            ic_states: "PARANÁ",
            ic_january: 5.755,
            ic_february: 5.52,
            ic_march: 5.037,
            ic_april: 4.294,
            ic_may: 3.378,
            ic_june: 2.964,
            ic_july: 3.291,
            ic_august: 4.243,
            ic_september: 4.441,
            ic_october: 5.108,
            ic_november: 5.928,
            ic_december: 6.077,
            ic_yearly: 4.67,
          },
          {
            ic_uuid: "4c08fe34-5728-4362-99bd-fcbdbf105873",
            ic_lat: -232286,
            ic_lon: -515877,
            ic_city: "Pitangueiras",
            ic_states: "PARANÁ",
            ic_january: 5.921,
            ic_february: 5.842,
            ic_march: 5.328,
            ic_april: 4.675,
            ic_may: 3.677,
            ic_june: 3.398,
            ic_july: 3.583,
            ic_august: 4.511,
            ic_september: 4.703,
            ic_october: 5.4,
            ic_november: 6.067,
            ic_december: 6.365,
            ic_yearly: 4.956,
          },
          {
            ic_uuid: "088f92e1-8bf7-4582-a036-0e04c245bd1a",
            ic_lat: -210137,
            ic_lon: -482214,
            ic_city: "Pitangueiras",
            ic_states: "SÃO PAULO",
            ic_january: 5.787,
            ic_february: 6.055,
            ic_march: 5.214,
            ic_april: 4.818,
            ic_may: 4.167,
            ic_june: 3.919,
            ic_july: 4.154,
            ic_august: 4.977,
            ic_september: 5.006,
            ic_october: 5.577,
            ic_november: 5.859,
            ic_december: 6.174,
            ic_yearly: 5.142,
          },
          {
            ic_uuid: "7bf3d502-e391-46f8-bd7b-6bb52ec38ef0",
            ic_lat: -19683,
            ic_lon: -4489,
            ic_city: "Pitangui",
            ic_states: "MINAS GERAIS",
            ic_january: 5.812,
            ic_february: 6.006,
            ic_march: 5.154,
            ic_april: 4.895,
            ic_may: 4.347,
            ic_june: 4.179,
            ic_july: 4.459,
            ic_august: 5.318,
            ic_september: 5.552,
            ic_october: 5.59,
            ic_november: 5.317,
            ic_december: 5.667,
            ic_yearly: 5.192,
          },
          {
            ic_uuid: "d2ee5c3d-e2d0-4c92-af4a-79e8fd1537bb",
            ic_lat: -74668,
            ic_lon: -348154,
            ic_city: "Pitimbu",
            ic_states: "PARAÍBA",
            ic_january: 5.81,
            ic_february: 6.081,
            ic_march: 6.054,
            ic_april: 5.352,
            ic_may: 4.782,
            ic_june: 4.366,
            ic_july: 4.423,
            ic_august: 5.242,
            ic_september: 5.668,
            ic_october: 6.037,
            ic_november: 6.073,
            ic_december: 6.164,
            ic_yearly: 5.504,
          },
          {
            ic_uuid: "ebafdb30-ff6f-43ce-8b1a-bdeeb984904d",
            ic_lat: -104409,
            ic_lon: -491797,
            ic_city: "Pium",
            ic_states: "TOCANTINS",
            ic_january: 5.024,
            ic_february: 4.991,
            ic_march: 4.733,
            ic_april: 4.835,
            ic_may: 4.928,
            ic_june: 5.089,
            ic_july: 5.384,
            ic_august: 5.995,
            ic_september: 5.685,
            ic_october: 5.344,
            ic_november: 5.097,
            ic_december: 5.007,
            ic_yearly: 5.176,
          },
          {
            ic_uuid: "31276da6-5067-4f2f-b254-a29d39947858",
            ic_lat: -208339,
            ic_lon: -407272,
            ic_city: "Piúma",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.232,
            ic_february: 6.5,
            ic_march: 5.229,
            ic_april: 4.493,
            ic_may: 3.8,
            ic_june: 3.6,
            ic_july: 3.643,
            ic_august: 4.38,
            ic_september: 4.813,
            ic_october: 5.043,
            ic_november: 4.932,
            ic_december: 5.753,
            ic_yearly: 4.868,
          },
          {
            ic_uuid: "cb0f6b8f-7e5e-44d7-b62f-5b91fccde8a9",
            ic_lat: -204767,
            ic_lon: -459593,
            ic_city: "Piumhi",
            ic_states: "MINAS GERAIS",
            ic_january: 5.624,
            ic_february: 5.898,
            ic_march: 4.96,
            ic_april: 4.816,
            ic_may: 4.238,
            ic_june: 4.04,
            ic_july: 4.29,
            ic_august: 5.214,
            ic_september: 5.321,
            ic_october: 5.552,
            ic_november: 5.192,
            ic_december: 5.57,
            ic_yearly: 5.06,
          },
          {
            ic_uuid: "3394a9d3-b899-426c-9861-5a48f90522a4",
            ic_lat: -38685,
            ic_lon: -542128,
            ic_city: "Placas",
            ic_states: "PARÁ",
            ic_january: 4.127,
            ic_february: 4.234,
            ic_march: 4.226,
            ic_april: 4.139,
            ic_may: 4.267,
            ic_june: 4.616,
            ic_july: 4.51,
            ic_august: 4.991,
            ic_september: 4.798,
            ic_october: 4.977,
            ic_november: 4.764,
            ic_december: 4.354,
            ic_yearly: 4.501,
          },
          {
            ic_uuid: "51caee3a-4b9d-4403-b10d-b322bad22a5b",
            ic_lat: -103257,
            ic_lon: -67186,
            ic_city: "Plácido de Castro",
            ic_states: "ACRE",
            ic_january: 4.476,
            ic_february: 4.49,
            ic_march: 4.266,
            ic_april: 4.385,
            ic_may: 3.968,
            ic_june: 4.093,
            ic_july: 4.3,
            ic_august: 4.829,
            ic_september: 4.853,
            ic_october: 5.073,
            ic_november: 4.917,
            ic_december: 4.626,
            ic_yearly: 4.523,
          },
          {
            ic_uuid: "ac73eba5-54fc-419f-887d-2ac0b0ab164a",
            ic_lat: -154525,
            ic_lon: -476093,
            ic_city: "Planaltina",
            ic_states: "GOIÁS",
            ic_january: 5.518,
            ic_february: 5.856,
            ic_march: 5.223,
            ic_april: 5.155,
            ic_may: 4.919,
            ic_june: 4.828,
            ic_july: 5.118,
            ic_august: 6.026,
            ic_september: 5.922,
            ic_october: 5.664,
            ic_november: 5.126,
            ic_december: 5.524,
            ic_yearly: 5.407,
          },
          {
            ic_uuid: "bb1fdf3e-b6ca-4134-b1b9-d68641526b4e",
            ic_lat: -230106,
            ic_lon: -529167,
            ic_city: "Planaltina do Paraná",
            ic_states: "PARANÁ",
            ic_january: 5.945,
            ic_february: 5.912,
            ic_march: 5.407,
            ic_april: 4.623,
            ic_may: 3.659,
            ic_june: 3.326,
            ic_july: 3.521,
            ic_august: 4.48,
            ic_september: 4.718,
            ic_october: 5.415,
            ic_november: 6.165,
            ic_december: 6.447,
            ic_yearly: 4.968,
          },
          {
            ic_uuid: "17633477-fc17-4141-b0d5-f54d217d8bb1",
            ic_lat: -132591,
            ic_lon: -403695,
            ic_city: "Planaltino",
            ic_states: "BAHIA",
            ic_january: 5.81,
            ic_february: 5.94,
            ic_march: 5.522,
            ic_april: 4.507,
            ic_may: 4.053,
            ic_june: 3.673,
            ic_july: 3.874,
            ic_august: 4.473,
            ic_september: 5.368,
            ic_october: 5.427,
            ic_november: 5.367,
            ic_december: 5.814,
            ic_yearly: 4.986,
          },
          {
            ic_uuid: "65f20ea1-2247-4ca4-93d1-1683df396b78",
            ic_lat: -14668,
            ic_lon: -404746,
            ic_city: "Planalto",
            ic_states: "BAHIA",
            ic_january: 5.834,
            ic_february: 5.865,
            ic_march: 5.357,
            ic_april: 4.551,
            ic_may: 4.013,
            ic_june: 3.612,
            ic_july: 3.806,
            ic_august: 4.325,
            ic_september: 5.194,
            ic_october: 5.41,
            ic_november: 5.295,
            ic_december: 5.93,
            ic_yearly: 4.933,
          },
          {
            ic_uuid: "0d72fc3b-5578-49b9-846d-5c15073f0c53",
            ic_lat: -257216,
            ic_lon: -537647,
            ic_city: "Planalto",
            ic_states: "PARANÁ",
            ic_january: 6.359,
            ic_february: 5.875,
            ic_march: 5.305,
            ic_april: 4.25,
            ic_may: 3.279,
            ic_june: 2.823,
            ic_july: 3.082,
            ic_august: 4.059,
            ic_september: 4.253,
            ic_october: 5.278,
            ic_november: 6.149,
            ic_december: 6.499,
            ic_yearly: 4.768,
          },
          {
            ic_uuid: "0b1d4ebb-627e-4274-b4ac-627fdc387c38",
            ic_lat: -273302,
            ic_lon: -53058,
            ic_city: "Planalto",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.296,
            ic_february: 5.844,
            ic_march: 5.169,
            ic_april: 4.084,
            ic_may: 3.111,
            ic_june: 2.612,
            ic_july: 2.889,
            ic_august: 3.743,
            ic_september: 4.018,
            ic_october: 5.172,
            ic_november: 6.204,
            ic_december: 6.611,
            ic_yearly: 4.646,
          },
          {
            ic_uuid: "39a430b9-f588-42cd-9193-f339ecd76922",
            ic_lat: -21034,
            ic_lon: -499289,
            ic_city: "Planalto",
            ic_states: "SÃO PAULO",
            ic_january: 5.856,
            ic_february: 5.922,
            ic_march: 5.286,
            ic_april: 4.912,
            ic_may: 4.089,
            ic_june: 3.822,
            ic_july: 4.028,
            ic_august: 4.933,
            ic_september: 5.002,
            ic_october: 5.603,
            ic_november: 6.005,
            ic_december: 6.239,
            ic_yearly: 5.141,
          },
          {
            ic_uuid: "e23596b7-05b5-4d35-a59b-2d8538a9b5eb",
            ic_lat: -270709,
            ic_lon: -528674,
            ic_city: "Planalto Alegre",
            ic_states: "SANTA CATARINA",
            ic_january: 6.245,
            ic_february: 5.814,
            ic_march: 5.207,
            ic_april: 4.113,
            ic_may: 3.124,
            ic_june: 2.629,
            ic_july: 2.912,
            ic_august: 3.776,
            ic_september: 4.007,
            ic_october: 5.151,
            ic_november: 6.195,
            ic_december: 6.566,
            ic_yearly: 4.645,
          },
          {
            ic_uuid: "4f5325fe-0f36-4694-aade-9517950a71d1",
            ic_lat: -146616,
            ic_lon: -547762,
            ic_city: "Planalto da Serra",
            ic_states: "MATO GROSSO",
            ic_january: 5.314,
            ic_february: 5.311,
            ic_march: 5.143,
            ic_april: 5.212,
            ic_may: 4.811,
            ic_june: 4.693,
            ic_july: 4.824,
            ic_august: 5.537,
            ic_september: 5.213,
            ic_october: 5.315,
            ic_november: 5.379,
            ic_december: 5.389,
            ic_yearly: 5.178,
          },
          {
            ic_uuid: "e6d35fdd-6073-4e68-81ae-482b288bd7eb",
            ic_lat: -20138,
            ic_lon: -48702,
            ic_city: "Planura",
            ic_states: "MINAS GERAIS",
            ic_january: 5.792,
            ic_february: 6.03,
            ic_march: 5.165,
            ic_april: 4.998,
            ic_may: 4.258,
            ic_june: 3.964,
            ic_july: 4.27,
            ic_august: 5.181,
            ic_september: 5.111,
            ic_october: 5.528,
            ic_november: 5.692,
            ic_december: 5.994,
            ic_yearly: 5.165,
          },
          {
            ic_uuid: "7a3a2c71-00de-41ed-941d-1d3fbc8c2634",
            ic_lat: -226363,
            ic_lon: -502044,
            ic_city: "Platina",
            ic_states: "SÃO PAULO",
            ic_january: 5.738,
            ic_february: 5.785,
            ic_march: 5.26,
            ic_april: 4.705,
            ic_may: 3.794,
            ic_june: 3.507,
            ic_july: 3.704,
            ic_august: 4.666,
            ic_september: 4.762,
            ic_october: 5.454,
            ic_november: 5.941,
            ic_december: 6.305,
            ic_yearly: 4.968,
          },
          {
            ic_uuid: "2c9c81b7-3b40-407a-8cc5-11b5abea8341",
            ic_lat: -235338,
            ic_lon: -463477,
            ic_city: "Poá",
            ic_states: "SÃO PAULO",
            ic_january: 5.29,
            ic_february: 5.504,
            ic_march: 4.643,
            ic_april: 4.116,
            ic_may: 3.346,
            ic_june: 3.109,
            ic_july: 3.229,
            ic_august: 4.105,
            ic_september: 4.203,
            ic_october: 4.722,
            ic_november: 5.113,
            ic_december: 5.661,
            ic_yearly: 4.42,
          },
          {
            ic_uuid: "309808be-9ca2-4df6-ac7f-99864c0db523",
            ic_lat: -81862,
            ic_lon: -36704,
            ic_city: "Poção",
            ic_states: "PERNAMBUCO",
            ic_january: 6.25,
            ic_february: 6.067,
            ic_march: 6.047,
            ic_april: 5.53,
            ic_may: 4.708,
            ic_june: 4.223,
            ic_july: 4.404,
            ic_august: 5.243,
            ic_september: 6.155,
            ic_october: 6.392,
            ic_november: 6.645,
            ic_december: 6.393,
            ic_yearly: 5.671,
          },
          {
            ic_uuid: "06554501-575f-4c1d-955e-d11e8739c428",
            ic_lat: -47511,
            ic_lon: -449426,
            ic_city: "Poção de Pedras",
            ic_states: "MARANHÃO",
            ic_january: 4.685,
            ic_february: 4.873,
            ic_march: 4.857,
            ic_april: 4.859,
            ic_may: 4.841,
            ic_june: 5.025,
            ic_july: 5.259,
            ic_august: 5.832,
            ic_september: 6.088,
            ic_october: 5.628,
            ic_november: 5.223,
            ic_december: 4.948,
            ic_yearly: 5.177,
          },
          {
            ic_uuid: "0a6724b9-153e-40e0-8506-538c269f25b6",
            ic_lat: -70738,
            ic_lon: -360608,
            ic_city: "Pocinhos",
            ic_states: "PARAÍBA",
            ic_january: 5.762,
            ic_february: 5.812,
            ic_march: 5.874,
            ic_april: 5.315,
            ic_may: 4.712,
            ic_june: 4.18,
            ic_july: 4.411,
            ic_august: 5.075,
            ic_september: 5.7,
            ic_october: 6.013,
            ic_november: 6.165,
            ic_december: 5.985,
            ic_yearly: 5.417,
          },
          {
            ic_uuid: "78ee741d-578f-436e-9bbf-d3460d0fbd54",
            ic_lat: -56227,
            ic_lon: -356638,
            ic_city: "Poço Branco",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.853,
            ic_february: 5.944,
            ic_march: 5.875,
            ic_april: 5.538,
            ic_may: 5.02,
            ic_june: 4.629,
            ic_july: 4.753,
            ic_august: 5.448,
            ic_september: 5.903,
            ic_october: 6.043,
            ic_november: 6.197,
            ic_december: 5.803,
            ic_yearly: 5.584,
          },
          {
            ic_uuid: "f71ad902-754f-46d7-858d-ae1c848238c2",
            ic_lat: -63992,
            ic_lon: -384912,
            ic_city: "Poço Dantas",
            ic_states: "PARAÍBA",
            ic_january: 5.92,
            ic_february: 5.838,
            ic_march: 5.864,
            ic_april: 5.507,
            ic_may: 5.13,
            ic_june: 4.937,
            ic_july: 5.303,
            ic_august: 6.072,
            ic_september: 6.48,
            ic_october: 6.57,
            ic_november: 6.66,
            ic_december: 6.118,
            ic_yearly: 5.867,
          },
          {
            ic_uuid: "73ae4049-2c07-4b31-ad4a-12551f62e8ca",
            ic_lat: -294486,
            ic_lon: -516724,
            ic_city: "Poço das Antas",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.205,
            ic_february: 5.667,
            ic_march: 4.763,
            ic_april: 3.781,
            ic_may: 2.812,
            ic_june: 2.346,
            ic_july: 2.6,
            ic_august: 3.304,
            ic_september: 3.56,
            ic_october: 4.641,
            ic_november: 6.086,
            ic_december: 6.423,
            ic_yearly: 4.349,
          },
          {
            ic_uuid: "565b734a-ad0a-46b1-91c0-fc14ca44388b",
            ic_lat: -93049,
            ic_lon: -372848,
            ic_city: "Poço das Trincheiras",
            ic_states: "ALAGOAS",
            ic_january: 6.174,
            ic_february: 5.955,
            ic_march: 6.005,
            ic_april: 5.302,
            ic_may: 4.397,
            ic_june: 3.956,
            ic_july: 4.176,
            ic_august: 4.735,
            ic_september: 5.754,
            ic_october: 6.084,
            ic_november: 6.553,
            ic_december: 6.367,
            ic_yearly: 5.455,
          },
          {
            ic_uuid: "dae0e262-da9a-461a-839f-429b655b060a",
            ic_lat: -65644,
            ic_lon: -385114,
            ic_city: "Poço de José de Moura",
            ic_states: "PARAÍBA",
            ic_january: 6.079,
            ic_february: 6.043,
            ic_march: 5.918,
            ic_april: 5.675,
            ic_may: 5.179,
            ic_june: 4.966,
            ic_july: 5.271,
            ic_august: 6.036,
            ic_september: 6.463,
            ic_october: 6.58,
            ic_november: 6.74,
            ic_december: 6.256,
            ic_yearly: 5.934,
          },
          {
            ic_uuid: "fb812670-34f9-4e49-9e1c-17c4d3679b15",
            ic_lat: -217805,
            ic_lon: -459661,
            ic_city: "Poço Fundo",
            ic_states: "MINAS GERAIS",
            ic_january: 5.343,
            ic_february: 5.644,
            ic_march: 4.784,
            ic_april: 4.513,
            ic_may: 3.928,
            ic_june: 3.676,
            ic_july: 3.908,
            ic_august: 4.852,
            ic_september: 4.995,
            ic_october: 5.301,
            ic_november: 5.163,
            ic_december: 5.573,
            ic_yearly: 4.807,
          },
          {
            ic_uuid: "d7d23475-137c-4524-8c6f-056ea922a776",
            ic_lat: -98066,
            ic_lon: -376836,
            ic_city: "Poço Redondo",
            ic_states: "SERGIPE",
            ic_january: 6.148,
            ic_february: 5.947,
            ic_march: 5.916,
            ic_april: 5.167,
            ic_may: 4.339,
            ic_june: 3.969,
            ic_july: 4.082,
            ic_august: 4.629,
            ic_september: 5.465,
            ic_october: 5.801,
            ic_november: 6.345,
            ic_december: 6.311,
            ic_yearly: 5.343,
          },
          {
            ic_uuid: "03e7f73b-1fbd-43b9-ab89-ddfd63742090",
            ic_lat: -107155,
            ic_lon: -38178,
            ic_city: "Poço Verde",
            ic_states: "SERGIPE",
            ic_january: 6.122,
            ic_february: 5.866,
            ic_march: 5.811,
            ic_april: 5.014,
            ic_may: 4.176,
            ic_june: 3.852,
            ic_july: 4.038,
            ic_august: 4.475,
            ic_september: 5.352,
            ic_october: 5.657,
            ic_november: 6.184,
            ic_december: 6.15,
            ic_yearly: 5.225,
          },
          {
            ic_uuid: "27b4a8cb-3351-4399-8fe9-8d05593a3b9a",
            ic_lat: -145238,
            ic_lon: -403637,
            ic_city: "Poções",
            ic_states: "BAHIA",
            ic_january: 5.854,
            ic_february: 5.836,
            ic_march: 5.395,
            ic_april: 4.617,
            ic_may: 4.076,
            ic_june: 3.644,
            ic_july: 3.869,
            ic_august: 4.344,
            ic_september: 5.168,
            ic_october: 5.385,
            ic_november: 5.357,
            ic_december: 5.968,
            ic_yearly: 4.959,
          },
          {
            ic_uuid: "18759491-bdda-4864-8504-da0eba2ea3c6",
            ic_lat: -162665,
            ic_lon: -566265,
            ic_city: "Poconé",
            ic_states: "MATO GROSSO",
            ic_january: 5.559,
            ic_february: 5.429,
            ic_march: 5.159,
            ic_april: 4.837,
            ic_may: 4.241,
            ic_june: 4.184,
            ic_july: 4.368,
            ic_august: 5.225,
            ic_september: 5.037,
            ic_october: 5.342,
            ic_november: 5.54,
            ic_december: 5.808,
            ic_yearly: 5.061,
          },
          {
            ic_uuid: "5a8d5adf-4a40-47fe-aea9-4620dc5fae0b",
            ic_lat: -217848,
            ic_lon: -465687,
            ic_city: "Poços de Caldas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.259,
            ic_february: 5.513,
            ic_march: 4.723,
            ic_april: 4.523,
            ic_may: 3.923,
            ic_june: 3.704,
            ic_july: 3.968,
            ic_august: 4.891,
            ic_september: 5.0,
            ic_october: 5.314,
            ic_november: 5.262,
            ic_december: 5.527,
            ic_yearly: 4.801,
          },
          {
            ic_uuid: "5b0fd0e9-5972-483d-bece-6686cd544f10",
            ic_lat: -196213,
            ic_lon: -416338,
            ic_city: "Pocrane",
            ic_states: "MINAS GERAIS",
            ic_january: 5.891,
            ic_february: 6.153,
            ic_march: 5.194,
            ic_april: 4.64,
            ic_may: 3.878,
            ic_june: 3.627,
            ic_july: 3.784,
            ic_august: 4.437,
            ic_september: 4.835,
            ic_october: 4.997,
            ic_november: 4.929,
            ic_december: 5.655,
            ic_yearly: 4.835,
          },
          {
            ic_uuid: "2b4a91e2-bd73-43d0-b40a-2f5ca2e6cf7b",
            ic_lat: -124307,
            ic_lon: -383375,
            ic_city: "Pojuca",
            ic_states: "BAHIA",
            ic_january: 6.074,
            ic_february: 5.908,
            ic_march: 5.698,
            ic_april: 4.635,
            ic_may: 3.943,
            ic_june: 3.734,
            ic_july: 3.909,
            ic_august: 4.443,
            ic_september: 5.197,
            ic_october: 5.53,
            ic_november: 5.853,
            ic_december: 6.157,
            ic_yearly: 5.09,
          },
          {
            ic_uuid: "7ddca20e-8e98-409f-b5c1-459844c52503",
            ic_lat: -207854,
            ic_lon: -498144,
            ic_city: "Poloni",
            ic_states: "SÃO PAULO",
            ic_january: 5.789,
            ic_february: 5.88,
            ic_march: 5.259,
            ic_april: 4.892,
            ic_may: 4.128,
            ic_june: 3.884,
            ic_july: 4.118,
            ic_august: 5.059,
            ic_september: 5.029,
            ic_october: 5.644,
            ic_november: 5.937,
            ic_december: 6.173,
            ic_yearly: 5.149,
          },
          {
            ic_uuid: "c2239c4c-0f9a-4de9-9c75-9d1402fdbfdf",
            ic_lat: -67665,
            ic_lon: -378006,
            ic_city: "Pombal",
            ic_states: "PARAÍBA",
            ic_january: 6.168,
            ic_february: 6.161,
            ic_march: 6.247,
            ic_april: 5.856,
            ic_may: 5.296,
            ic_june: 4.908,
            ic_july: 5.152,
            ic_august: 5.924,
            ic_september: 6.529,
            ic_october: 6.699,
            ic_november: 6.796,
            ic_december: 6.368,
            ic_yearly: 6.009,
          },
          {
            ic_uuid: "41deb6df-839f-4e8d-88e4-c434bb1ad4dd",
            ic_lat: -81402,
            ic_lon: -35397,
            ic_city: "Pombos",
            ic_states: "PERNAMBUCO",
            ic_january: 5.675,
            ic_february: 5.729,
            ic_march: 5.746,
            ic_april: 5.076,
            ic_may: 4.434,
            ic_june: 4.092,
            ic_july: 4.212,
            ic_august: 4.862,
            ic_september: 5.511,
            ic_october: 5.746,
            ic_november: 5.94,
            ic_december: 6.006,
            ic_yearly: 5.252,
          },
          {
            ic_uuid: "6d61a661-002d-4c77-998f-636664825a52",
            ic_lat: -267388,
            ic_lon: -491789,
            ic_city: "Pomerode",
            ic_states: "SANTA CATARINA",
            ic_january: 5.261,
            ic_february: 5.01,
            ic_march: 4.399,
            ic_april: 3.576,
            ic_may: 2.939,
            ic_june: 2.437,
            ic_july: 2.575,
            ic_august: 3.241,
            ic_september: 3.267,
            ic_october: 3.714,
            ic_november: 4.862,
            ic_december: 5.275,
            ic_yearly: 3.88,
          },
          {
            ic_uuid: "1ff0975d-4957-4b1e-bf67-7ad596c5934c",
            ic_lat: -221075,
            ic_lon: -501764,
            ic_city: "Pompéia",
            ic_states: "SÃO PAULO",
            ic_january: 5.779,
            ic_february: 5.888,
            ic_march: 5.264,
            ic_april: 4.768,
            ic_may: 3.884,
            ic_june: 3.606,
            ic_july: 3.823,
            ic_august: 4.789,
            ic_september: 4.823,
            ic_october: 5.534,
            ic_november: 5.97,
            ic_december: 6.255,
            ic_yearly: 5.032,
          },
          {
            ic_uuid: "af0b0567-f341-4f84-86cd-d92e63e5ed53",
            ic_lat: -192261,
            ic_lon: -450145,
            ic_city: "Pompéu",
            ic_states: "MINAS GERAIS",
            ic_january: 6.011,
            ic_february: 6.19,
            ic_march: 5.281,
            ic_april: 5.085,
            ic_may: 4.54,
            ic_june: 4.293,
            ic_july: 4.626,
            ic_august: 5.42,
            ic_september: 5.698,
            ic_october: 5.717,
            ic_november: 5.457,
            ic_december: 5.821,
            ic_yearly: 5.345,
          },
          {
            ic_uuid: "d598a155-d78b-42d7-9cd4-170093ccad8b",
            ic_lat: -217363,
            ic_lon: -493584,
            ic_city: "Pongaí",
            ic_states: "SÃO PAULO",
            ic_january: 5.967,
            ic_february: 6.102,
            ic_march: 5.416,
            ic_april: 4.824,
            ic_may: 3.939,
            ic_june: 3.685,
            ic_july: 3.933,
            ic_august: 4.796,
            ic_september: 4.957,
            ic_october: 5.685,
            ic_november: 6.021,
            ic_december: 6.334,
            ic_yearly: 5.138,
          },
          {
            ic_uuid: "4a03c239-a191-4877-b035-76086dbf5f91",
            ic_lat: -13963,
            ic_lon: -488665,
            ic_city: "Ponta de Pedras",
            ic_states: "PARÁ",
            ic_january: 4.807,
            ic_february: 4.654,
            ic_march: 4.731,
            ic_april: 4.804,
            ic_may: 4.99,
            ic_june: 5.165,
            ic_july: 5.229,
            ic_august: 5.507,
            ic_september: 5.673,
            ic_october: 5.581,
            ic_november: 5.496,
            ic_december: 5.153,
            ic_yearly: 5.149,
          },
          {
            ic_uuid: "c8f67393-62c7-41bd-bd8c-9a50a1f70b09",
            ic_lat: -250921,
            ic_lon: -501672,
            ic_city: "Ponta Grossa",
            ic_states: "PARANÁ",
            ic_january: 5.603,
            ic_february: 5.379,
            ic_march: 4.768,
            ic_april: 4.1,
            ic_may: 3.269,
            ic_june: 2.904,
            ic_july: 3.101,
            ic_august: 4.095,
            ic_september: 4.254,
            ic_october: 4.86,
            ic_november: 5.652,
            ic_december: 5.893,
            ic_yearly: 4.49,
          },
          {
            ic_uuid: "2a3bf83e-dde2-4644-8345-312efdc6bb85",
            ic_lat: -225301,
            ic_lon: -557208,
            ic_city: "Ponta Porã",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.826,
            ic_february: 5.613,
            ic_march: 5.282,
            ic_april: 4.455,
            ic_may: 3.539,
            ic_june: 3.261,
            ic_july: 3.365,
            ic_august: 4.423,
            ic_september: 4.701,
            ic_october: 5.345,
            ic_november: 5.852,
            ic_december: 6.312,
            ic_yearly: 4.831,
          },
          {
            ic_uuid: "ae9a4ec0-045a-48c0-9cd7-7ee5268db70f",
            ic_lat: -21022,
            ic_lon: -480427,
            ic_city: "Pontal",
            ic_states: "SÃO PAULO",
            ic_january: 5.812,
            ic_february: 6.029,
            ic_march: 5.249,
            ic_april: 4.906,
            ic_may: 4.163,
            ic_june: 3.89,
            ic_july: 4.177,
            ic_august: 5.019,
            ic_september: 4.996,
            ic_october: 5.594,
            ic_november: 5.866,
            ic_december: 6.193,
            ic_yearly: 5.158,
          },
          {
            ic_uuid: "2ee20e24-34ab-4bbd-a2e8-3e1ebd143ae1",
            ic_lat: -15906,
            ic_lon: -522584,
            ic_city: "Pontal do Araguaia",
            ic_states: "MATO GROSSO",
            ic_january: 5.473,
            ic_february: 5.603,
            ic_march: 5.201,
            ic_april: 5.294,
            ic_may: 4.941,
            ic_june: 4.585,
            ic_july: 4.721,
            ic_august: 5.489,
            ic_september: 5.292,
            ic_october: 5.489,
            ic_november: 5.43,
            ic_december: 5.545,
            ic_yearly: 5.256,
          },
          {
            ic_uuid: "7122f01d-b090-42bf-8c82-780dc964f648",
            ic_lat: -25674,
            ic_lon: -485115,
            ic_city: "Pontal do Paraná",
            ic_states: "PARANÁ",
            ic_january: 5.12,
            ic_february: 5.032,
            ic_march: 4.266,
            ic_april: 3.527,
            ic_may: 2.931,
            ic_june: 2.469,
            ic_july: 2.512,
            ic_august: 3.2,
            ic_september: 3.157,
            ic_october: 3.666,
            ic_november: 4.631,
            ic_december: 5.117,
            ic_yearly: 3.802,
          },
          {
            ic_uuid: "b56bf0af-bc29-48a9-8444-3ed434bd6d1e",
            ic_lat: -175166,
            ic_lon: -494491,
            ic_city: "Pontalina",
            ic_states: "GOIÁS",
            ic_january: 5.596,
            ic_february: 5.703,
            ic_march: 5.353,
            ic_april: 5.152,
            ic_may: 4.732,
            ic_june: 4.349,
            ic_july: 4.595,
            ic_august: 5.499,
            ic_september: 5.373,
            ic_october: 5.611,
            ic_november: 5.525,
            ic_december: 5.679,
            ic_yearly: 5.264,
          },
          {
            ic_uuid: "ce57eead-0a74-461d-869d-5406a2e8d338",
            ic_lat: -20441,
            ic_lon: -505229,
            ic_city: "Pontalinda",
            ic_states: "SÃO PAULO",
            ic_january: 5.8,
            ic_february: 5.916,
            ic_march: 5.312,
            ic_april: 4.885,
            ic_may: 4.173,
            ic_june: 3.914,
            ic_july: 4.119,
            ic_august: 5.037,
            ic_september: 5.103,
            ic_october: 5.666,
            ic_november: 5.982,
            ic_december: 6.281,
            ic_yearly: 5.182,
          },
          {
            ic_uuid: "6ce5bcf8-9f58-473b-ad61-a3611fac69df",
            ic_lat: -28059,
            ic_lon: -526795,
            ic_city: "Pontão",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.331,
            ic_february: 5.841,
            ic_march: 4.956,
            ic_april: 4.066,
            ic_may: 3.051,
            ic_june: 2.539,
            ic_july: 2.852,
            ic_august: 3.624,
            ic_september: 3.876,
            ic_october: 5.059,
            ic_november: 6.188,
            ic_december: 6.559,
            ic_yearly: 4.578,
          },
          {
            ic_uuid: "fa956d8e-61bf-4232-a2eb-828ef3c74ca7",
            ic_lat: -27484,
            ic_lon: -503768,
            ic_city: "Ponte Alta",
            ic_states: "SANTA CATARINA",
            ic_january: 5.647,
            ic_february: 5.294,
            ic_march: 4.642,
            ic_april: 3.739,
            ic_may: 2.894,
            ic_june: 2.506,
            ic_july: 2.703,
            ic_august: 3.55,
            ic_september: 3.707,
            ic_october: 4.393,
            ic_november: 5.643,
            ic_december: 5.95,
            ic_yearly: 4.223,
          },
          {
            ic_uuid: "50ce3e8b-a8e6-461f-b615-8c3a25ba43c2",
            ic_lat: -120878,
            ic_lon: -464749,
            ic_city: "Ponte Alta do Bom Jesus",
            ic_states: "TOCANTINS",
            ic_january: 5.791,
            ic_february: 5.625,
            ic_march: 5.146,
            ic_april: 5.048,
            ic_may: 5.204,
            ic_june: 5.112,
            ic_july: 5.482,
            ic_august: 6.033,
            ic_september: 6.161,
            ic_october: 5.785,
            ic_november: 5.319,
            ic_december: 5.602,
            ic_yearly: 5.526,
          },
          {
            ic_uuid: "40a5b937-80d2-437c-9906-93ad64c3f5dc",
            ic_lat: -271596,
            ic_lon: -504664,
            ic_city: "Ponte Alta do Norte",
            ic_states: "SANTA CATARINA",
            ic_january: 5.553,
            ic_february: 5.299,
            ic_march: 4.611,
            ic_april: 3.775,
            ic_may: 2.93,
            ic_june: 2.593,
            ic_july: 2.807,
            ic_august: 3.68,
            ic_september: 3.782,
            ic_october: 4.49,
            ic_november: 5.68,
            ic_december: 5.918,
            ic_yearly: 4.26,
          },
          {
            ic_uuid: "2ac965fa-2705-4402-9a73-34a4c1e89d5d",
            ic_lat: -107419,
            ic_lon: -475399,
            ic_city: "Ponte Alta do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 5.322,
            ic_february: 5.317,
            ic_march: 5.016,
            ic_april: 5.121,
            ic_may: 5.146,
            ic_june: 5.128,
            ic_july: 5.381,
            ic_august: 6.06,
            ic_september: 6.027,
            ic_october: 5.609,
            ic_november: 5.272,
            ic_december: 5.359,
            ic_yearly: 5.397,
          },
          {
            ic_uuid: "3f849711-2d86-4361-b8c3-a4ecd585f4a5",
            ic_lat: -167665,
            ic_lon: -528353,
            ic_city: "Ponte Branca",
            ic_states: "MATO GROSSO",
            ic_january: 5.506,
            ic_february: 5.696,
            ic_march: 5.221,
            ic_april: 5.212,
            ic_may: 4.818,
            ic_june: 4.466,
            ic_july: 4.543,
            ic_august: 5.481,
            ic_september: 5.376,
            ic_october: 5.363,
            ic_november: 5.524,
            ic_december: 5.713,
            ic_yearly: 5.243,
          },
          {
            ic_uuid: "31268cdc-866e-472a-be2c-3e9d76fadda7",
            ic_lat: -204116,
            ic_lon: -428982,
            ic_city: "Ponte Nova",
            ic_states: "MINAS GERAIS",
            ic_january: 5.706,
            ic_february: 6.076,
            ic_march: 5.068,
            ic_april: 4.517,
            ic_may: 3.848,
            ic_june: 3.61,
            ic_july: 3.856,
            ic_august: 4.634,
            ic_september: 4.929,
            ic_october: 5.14,
            ic_november: 4.877,
            ic_december: 5.575,
            ic_yearly: 4.82,
          },
          {
            ic_uuid: "19608a17-469b-470d-a58a-20639b195b3d",
            ic_lat: -276592,
            ic_lon: -524853,
            ic_city: "Ponte Preta",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.222,
            ic_february: 5.787,
            ic_march: 5.022,
            ic_april: 4.063,
            ic_may: 3.053,
            ic_june: 2.584,
            ic_july: 2.929,
            ic_august: 3.734,
            ic_september: 3.932,
            ic_october: 5.088,
            ic_november: 6.152,
            ic_december: 6.537,
            ic_yearly: 4.592,
          },
          {
            ic_uuid: "ae3645dd-c5e1-4523-be56-6f5942807e43",
            ic_lat: -268738,
            ic_lon: -520117,
            ic_city: "Ponte Serrada",
            ic_states: "SANTA CATARINA",
            ic_january: 5.959,
            ic_february: 5.47,
            ic_march: 5.006,
            ic_april: 4.088,
            ic_may: 3.133,
            ic_june: 2.721,
            ic_july: 2.989,
            ic_august: 3.859,
            ic_september: 4.069,
            ic_october: 5.014,
            ic_november: 6.015,
            ic_december: 6.228,
            ic_yearly: 4.546,
          },
          {
            ic_uuid: "bb72608c-80f9-4e23-bc14-0c1757dc9272",
            ic_lat: -152309,
            ic_lon: -593368,
            ic_city: "Pontes e Lacerda",
            ic_states: "MATO GROSSO",
            ic_january: 5.206,
            ic_february: 5.015,
            ic_march: 4.866,
            ic_april: 4.788,
            ic_may: 4.213,
            ic_june: 4.29,
            ic_july: 4.422,
            ic_august: 5.034,
            ic_september: 5.082,
            ic_october: 5.323,
            ic_november: 5.535,
            ic_december: 5.483,
            ic_yearly: 4.938,
          },
          {
            ic_uuid: "0b4547d1-c7df-4629-8f13-7ea16e55ca51",
            ic_lat: -201828,
            ic_lon: -49702,
            ic_city: "Pontes Gestal",
            ic_states: "SÃO PAULO",
            ic_january: 5.807,
            ic_february: 5.91,
            ic_march: 5.301,
            ic_april: 4.929,
            ic_may: 4.231,
            ic_june: 3.937,
            ic_july: 4.205,
            ic_august: 5.106,
            ic_september: 5.119,
            ic_october: 5.646,
            ic_november: 5.869,
            ic_december: 6.191,
            ic_yearly: 5.188,
          },
          {
            ic_uuid: "e92d2731-7ee3-45b1-986d-13ad838833bb",
            ic_lat: -181229,
            ic_lon: -405408,
            ic_city: "Ponto Belo",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.124,
            ic_february: 6.191,
            ic_march: 5.338,
            ic_april: 4.61,
            ic_may: 3.803,
            ic_june: 3.474,
            ic_july: 3.642,
            ic_august: 4.243,
            ic_september: 4.814,
            ic_october: 5.129,
            ic_november: 5.033,
            ic_december: 5.977,
            ic_yearly: 4.865,
          },
          {
            ic_uuid: "15187924-db59-4b8c-a571-54657e19c1ce",
            ic_lat: -166318,
            ic_lon: -450641,
            ic_city: "Ponto Chique",
            ic_states: "MINAS GERAIS",
            ic_january: 6.303,
            ic_february: 6.575,
            ic_march: 5.735,
            ic_april: 5.551,
            ic_may: 5.0,
            ic_june: 4.739,
            ic_july: 5.001,
            ic_august: 5.758,
            ic_september: 6.0,
            ic_october: 6.106,
            ic_november: 5.617,
            ic_december: 6.128,
            ic_yearly: 5.709,
          },
          {
            ic_uuid: "6cafcd4d-5739-4cde-b7bf-6a4780b934ff",
            ic_lat: -167502,
            ic_lon: -415059,
            ic_city: "Ponto dos Volantes",
            ic_states: "MINAS GERAIS",
            ic_january: 6.113,
            ic_february: 6.4,
            ic_march: 5.393,
            ic_april: 4.666,
            ic_may: 4.012,
            ic_june: 3.612,
            ic_july: 3.841,
            ic_august: 4.566,
            ic_september: 5.293,
            ic_october: 5.512,
            ic_november: 5.313,
            ic_december: 6.08,
            ic_yearly: 5.067,
          },
          {
            ic_uuid: "530a5c40-fc39-424c-9b88-199709920a87",
            ic_lat: -108618,
            ic_lon: -401334,
            ic_city: "Ponto Novo",
            ic_states: "BAHIA",
            ic_january: 6.181,
            ic_february: 5.948,
            ic_march: 5.773,
            ic_april: 4.733,
            ic_may: 4.042,
            ic_june: 3.785,
            ic_july: 3.98,
            ic_august: 4.53,
            ic_september: 5.518,
            ic_october: 5.616,
            ic_november: 5.962,
            ic_december: 5.955,
            ic_yearly: 5.168,
          },
          {
            ic_uuid: "20bb4f2e-1156-42d0-804a-153f74243a4d",
            ic_lat: -199458,
            ic_lon: -505384,
            ic_city: "Populina",
            ic_states: "SÃO PAULO",
            ic_january: 5.825,
            ic_february: 5.944,
            ic_march: 5.366,
            ic_april: 4.915,
            ic_may: 4.25,
            ic_june: 3.969,
            ic_july: 4.168,
            ic_august: 5.068,
            ic_september: 5.036,
            ic_october: 5.675,
            ic_november: 5.836,
            ic_december: 6.165,
            ic_yearly: 5.185,
          },
          {
            ic_uuid: "2c38a5f9-09f8-449b-94a6-7e718b2a1c0b",
            ic_lat: -47456,
            ic_lon: -409239,
            ic_city: "Poranga",
            ic_states: "CEARÁ",
            ic_january: 5.303,
            ic_february: 5.399,
            ic_march: 5.516,
            ic_april: 5.079,
            ic_may: 5.165,
            ic_june: 5.208,
            ic_july: 5.612,
            ic_august: 6.314,
            ic_september: 6.792,
            ic_october: 6.728,
            ic_november: 6.612,
            ic_december: 5.833,
            ic_yearly: 5.797,
          },
          {
            ic_uuid: "351f0207-3dd7-46f7-9ed5-62b67f634ab4",
            ic_lat: -231766,
            ic_lon: -481199,
            ic_city: "Porangaba",
            ic_states: "SÃO PAULO",
            ic_january: 5.609,
            ic_february: 5.775,
            ic_march: 5.055,
            ic_april: 4.525,
            ic_may: 3.649,
            ic_june: 3.391,
            ic_july: 3.537,
            ic_august: 4.526,
            ic_september: 4.691,
            ic_october: 5.26,
            ic_november: 5.733,
            ic_december: 6.101,
            ic_yearly: 4.821,
          },
          {
            ic_uuid: "81b30b96-4b81-41ec-9880-24e092891cd9",
            ic_lat: -134387,
            ic_lon: -491499,
            ic_city: "Porangatu",
            ic_states: "GOIÁS",
            ic_january: 5.399,
            ic_february: 5.555,
            ic_march: 5.155,
            ic_april: 5.244,
            ic_may: 5.012,
            ic_june: 4.83,
            ic_july: 5.156,
            ic_august: 5.859,
            ic_september: 5.754,
            ic_october: 5.615,
            ic_november: 5.178,
            ic_december: 5.384,
            ic_yearly: 5.345,
          },
          {
            ic_uuid: "0d1bc2df-32a2-4c6f-8ec4-574daf432743",
            ic_lat: -209653,
            ic_lon: -42041,
            ic_city: "Porciúncula",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.08,
            ic_february: 6.275,
            ic_march: 5.133,
            ic_april: 4.509,
            ic_may: 3.841,
            ic_june: 3.672,
            ic_july: 3.788,
            ic_august: 4.569,
            ic_september: 4.957,
            ic_october: 5.169,
            ic_november: 4.967,
            ic_december: 5.801,
            ic_yearly: 4.897,
          },
          {
            ic_uuid: "0ca9a7e5-3b2a-4142-b447-43e8be8faa3e",
            ic_lat: -227542,
            ic_lon: -513799,
            ic_city: "Porecatu",
            ic_states: "PARANÁ",
            ic_january: 5.965,
            ic_february: 5.951,
            ic_march: 5.466,
            ic_april: 4.774,
            ic_may: 3.752,
            ic_june: 3.423,
            ic_july: 3.65,
            ic_august: 4.588,
            ic_september: 4.716,
            ic_october: 5.475,
            ic_november: 6.157,
            ic_december: 6.473,
            ic_yearly: 5.033,
          },
          {
            ic_uuid: "1229d73d-8a44-4e07-9829-8577908ec76c",
            ic_lat: -60211,
            ic_lon: -379868,
            ic_city: "Portalegre",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.053,
            ic_february: 6.118,
            ic_march: 6.139,
            ic_april: 5.847,
            ic_may: 5.398,
            ic_june: 5.108,
            ic_july: 5.432,
            ic_august: 6.102,
            ic_september: 6.5,
            ic_october: 6.671,
            ic_november: 6.701,
            ic_december: 6.149,
            ic_yearly: 6.018,
          },
          {
            ic_uuid: "9d43ef14-80d5-4450-88a8-5fc27be2bc85",
            ic_lat: -29702,
            ic_lon: -512433,
            ic_city: "Portão",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.26,
            ic_february: 5.797,
            ic_march: 4.857,
            ic_april: 3.783,
            ic_may: 2.818,
            ic_june: 2.335,
            ic_july: 2.54,
            ic_august: 3.225,
            ic_september: 3.571,
            ic_october: 4.66,
            ic_november: 6.108,
            ic_december: 6.62,
            ic_yearly: 4.381,
          },
          {
            ic_uuid: "9d4b355f-5610-4b07-b938-d6e6861ea0de",
            ic_lat: -178105,
            ic_lon: -501658,
            ic_city: "Porteirão",
            ic_states: "GOIÁS",
            ic_january: 5.734,
            ic_february: 5.795,
            ic_march: 5.321,
            ic_april: 5.121,
            ic_may: 4.731,
            ic_june: 4.412,
            ic_july: 4.624,
            ic_august: 5.567,
            ic_september: 5.327,
            ic_october: 5.588,
            ic_november: 5.619,
            ic_december: 5.747,
            ic_yearly: 5.299,
          },
          {
            ic_uuid: "f6977218-7c94-4023-a050-9d2098780718",
            ic_lat: -75338,
            ic_lon: -391162,
            ic_city: "Porteiras",
            ic_states: "CEARÁ",
            ic_january: 5.954,
            ic_february: 5.811,
            ic_march: 5.781,
            ic_april: 5.389,
            ic_may: 4.893,
            ic_june: 4.623,
            ic_july: 4.926,
            ic_august: 5.775,
            ic_september: 6.54,
            ic_october: 6.492,
            ic_november: 6.642,
            ic_december: 6.339,
            ic_yearly: 5.764,
          },
          {
            ic_uuid: "ccd17cd3-fed3-4d4c-af58-f88c51c83147",
            ic_lat: -157409,
            ic_lon: -430284,
            ic_city: "Porteirinha",
            ic_states: "MINAS GERAIS",
            ic_january: 6.602,
            ic_february: 6.755,
            ic_march: 5.825,
            ic_april: 5.44,
            ic_may: 4.796,
            ic_june: 4.528,
            ic_july: 4.782,
            ic_august: 5.406,
            ic_september: 5.887,
            ic_october: 6.13,
            ic_november: 5.763,
            ic_december: 6.415,
            ic_yearly: 5.694,
          },
          {
            ic_uuid: "001bf367-46a1-457e-b698-5cb2f83bbc36",
            ic_lat: -19368,
            ic_lon: -508198,
            ic_city: "Portel",
            ic_states: "PARÁ",
            ic_january: 4.589,
            ic_february: 4.489,
            ic_march: 4.592,
            ic_april: 4.608,
            ic_may: 4.708,
            ic_june: 4.916,
            ic_july: 4.986,
            ic_august: 5.259,
            ic_september: 5.31,
            ic_october: 5.138,
            ic_november: 5.056,
            ic_december: 4.718,
            ic_yearly: 4.864,
          },
          {
            ic_uuid: "314cf71c-6e3b-4553-987d-5ccf7d07f052",
            ic_lat: -173558,
            ic_lon: -526804,
            ic_city: "Portelândia",
            ic_states: "GOIÁS",
            ic_january: 5.535,
            ic_february: 5.557,
            ic_march: 5.166,
            ic_april: 5.059,
            ic_may: 4.67,
            ic_june: 4.361,
            ic_july: 4.493,
            ic_august: 5.289,
            ic_september: 5.302,
            ic_october: 5.287,
            ic_november: 5.56,
            ic_december: 5.712,
            ic_yearly: 5.166,
          },
          {
            ic_uuid: "8677d6fb-eb2c-418b-9df3-641b86fe38b2",
            ic_lat: -38917,
            ic_lon: -427115,
            ic_city: "Porto",
            ic_states: "PIAUÍ",
            ic_january: 5.275,
            ic_february: 5.405,
            ic_march: 5.371,
            ic_april: 5.187,
            ic_may: 5.157,
            ic_june: 5.304,
            ic_july: 5.544,
            ic_august: 6.07,
            ic_september: 6.385,
            ic_october: 6.334,
            ic_november: 6.143,
            ic_december: 5.672,
            ic_yearly: 5.654,
          },
          {
            ic_uuid: "ddcf93b0-9744-4751-b881-9fe2ebd7b95e",
            ic_lat: -95942,
            ic_lon: -675408,
            ic_city: "Porto Acre",
            ic_states: "ACRE",
            ic_january: 4.411,
            ic_february: 4.549,
            ic_march: 4.278,
            ic_april: 4.376,
            ic_may: 3.975,
            ic_june: 4.122,
            ic_july: 4.333,
            ic_august: 4.946,
            ic_september: 5.01,
            ic_october: 5.064,
            ic_november: 4.914,
            ic_december: 4.589,
            ic_yearly: 4.547,
          },
          {
            ic_uuid: "edd6274a-0939-4bc3-b5bf-acbd413983c4",
            ic_lat: -300327,
            ic_lon: -512074,
            ic_city: "Porto Alegre",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.449,
            ic_february: 5.863,
            ic_march: 4.848,
            ic_april: 3.819,
            ic_may: 2.777,
            ic_june: 2.309,
            ic_july: 2.524,
            ic_august: 3.194,
            ic_september: 3.596,
            ic_october: 4.822,
            ic_november: 6.248,
            ic_december: 6.716,
            ic_yearly: 4.43,
          },
          {
            ic_uuid: "722cc980-3813-4717-9717-fd378e8ff5df",
            ic_lat: -108765,
            ic_lon: -516361,
            ic_city: "Porto Alegre do Norte",
            ic_states: "MATO GROSSO",
            ic_january: 5.001,
            ic_february: 5.145,
            ic_march: 4.931,
            ic_april: 4.738,
            ic_may: 4.889,
            ic_june: 4.681,
            ic_july: 4.996,
            ic_august: 5.509,
            ic_september: 5.268,
            ic_october: 5.218,
            ic_november: 5.128,
            ic_december: 5.199,
            ic_yearly: 5.058,
          },
          {
            ic_uuid: "cf1bcba8-59c0-4107-b264-d6b02a449dba",
            ic_lat: -6982,
            ic_lon: -441968,
            ic_city: "Porto Alegre do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.006,
            ic_february: 5.062,
            ic_march: 5.019,
            ic_april: 5.121,
            ic_may: 5.235,
            ic_june: 5.391,
            ic_july: 5.604,
            ic_august: 6.054,
            ic_september: 6.549,
            ic_october: 6.138,
            ic_november: 5.704,
            ic_december: 5.323,
            ic_yearly: 5.517,
          },
          {
            ic_uuid: "e323b5d8-b981-46bc-88d3-25aaef7323f8",
            ic_lat: -116083,
            ic_lon: -470488,
            ic_city: "Porto Alegre do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 5.604,
            ic_february: 5.489,
            ic_march: 5.164,
            ic_april: 5.24,
            ic_may: 5.297,
            ic_june: 5.194,
            ic_july: 5.468,
            ic_august: 6.141,
            ic_september: 6.158,
            ic_october: 5.757,
            ic_november: 5.404,
            ic_december: 5.562,
            ic_yearly: 5.54,
          },
          {
            ic_uuid: "98c13831-aa86-4846-bb02-ceddc2a33bd1",
            ic_lat: -255405,
            ic_lon: -49895,
            ic_city: "Porto Amazonas",
            ic_states: "PARANÁ",
            ic_january: 5.46,
            ic_february: 5.254,
            ic_march: 4.672,
            ic_april: 3.864,
            ic_may: 3.052,
            ic_june: 2.788,
            ic_july: 2.962,
            ic_august: 3.968,
            ic_september: 4.011,
            ic_october: 4.593,
            ic_november: 5.388,
            ic_december: 5.703,
            ic_yearly: 4.31,
          },
          {
            ic_uuid: "9ed65ba3-b7c7-4ea5-9169-774508fdfe37",
            ic_lat: -255482,
            ic_lon: -524072,
            ic_city: "Porto Barreiro",
            ic_states: "PARANÁ",
            ic_january: 6.104,
            ic_february: 5.607,
            ic_march: 5.165,
            ic_april: 4.255,
            ic_may: 3.298,
            ic_june: 2.912,
            ic_july: 3.158,
            ic_august: 4.13,
            ic_september: 4.324,
            ic_october: 5.17,
            ic_november: 6.063,
            ic_december: 6.225,
            ic_yearly: 4.701,
          },
          {
            ic_uuid: "3b0129dd-1981-4299-b90c-94ab331e6eb9",
            ic_lat: -271591,
            ic_lon: -485473,
            ic_city: "Porto Belo",
            ic_states: "SANTA CATARINA",
            ic_january: 5.828,
            ic_february: 5.471,
            ic_march: 4.707,
            ic_april: 3.748,
            ic_may: 3.051,
            ic_june: 2.543,
            ic_july: 2.694,
            ic_august: 3.345,
            ic_september: 3.55,
            ic_october: 4.267,
            ic_november: 5.469,
            ic_december: 5.86,
            ic_yearly: 4.211,
          },
          {
            ic_uuid: "b02a1fea-956e-4c3f-ace4-061b370a5297",
            ic_lat: -90524,
            ic_lon: -353989,
            ic_city: "Porto Calvo",
            ic_states: "ALAGOAS",
            ic_january: 5.957,
            ic_february: 5.917,
            ic_march: 6.044,
            ic_april: 5.055,
            ic_may: 4.356,
            ic_june: 4.085,
            ic_july: 4.128,
            ic_august: 4.857,
            ic_september: 5.478,
            ic_october: 5.837,
            ic_november: 6.24,
            ic_december: 6.24,
            ic_yearly: 5.35,
          },
          {
            ic_uuid: "c9423319-32a5-4a7d-a3cd-22383af6be8f",
            ic_lat: -99167,
            ic_lon: -372845,
            ic_city: "Porto da Folha",
            ic_states: "SERGIPE",
            ic_january: 6.271,
            ic_february: 6.043,
            ic_march: 5.901,
            ic_april: 5.202,
            ic_may: 4.328,
            ic_june: 4.078,
            ic_july: 4.2,
            ic_august: 4.783,
            ic_september: 5.631,
            ic_october: 5.887,
            ic_november: 6.439,
            ic_december: 6.322,
            ic_yearly: 5.424,
          },
          {
            ic_uuid: "9981e1c1-8d32-44df-9d15-b139f9bd93aa",
            ic_lat: -17473,
            ic_lon: -522365,
            ic_city: "Porto de Moz",
            ic_states: "PARÁ",
            ic_january: 4.477,
            ic_february: 4.507,
            ic_march: 4.484,
            ic_april: 4.465,
            ic_may: 4.5,
            ic_june: 4.664,
            ic_july: 4.69,
            ic_august: 5.055,
            ic_september: 5.131,
            ic_october: 5.107,
            ic_november: 4.966,
            ic_december: 4.569,
            ic_yearly: 4.718,
          },
          {
            ic_uuid: "78931235-5cc3-4ba6-84da-74a0cd5c4b8d",
            ic_lat: -91605,
            ic_lon: -353052,
            ic_city: "Porto de Pedras",
            ic_states: "ALAGOAS",
            ic_january: 6.024,
            ic_february: 5.985,
            ic_march: 6.118,
            ic_april: 5.133,
            ic_may: 4.399,
            ic_june: 4.138,
            ic_july: 4.201,
            ic_august: 4.948,
            ic_september: 5.587,
            ic_october: 5.928,
            ic_november: 6.292,
            ic_december: 6.267,
            ic_yearly: 5.418,
          },
          {
            ic_uuid: "00d54fdf-4d95-4d10-b9a4-0b7383a8e0ca",
            ic_lat: -50673,
            ic_lon: -3678,
            ic_city: "Porto do Mangue",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.246,
            ic_february: 6.062,
            ic_march: 5.979,
            ic_april: 5.663,
            ic_may: 5.238,
            ic_june: 4.919,
            ic_july: 5.097,
            ic_august: 5.744,
            ic_september: 6.179,
            ic_october: 6.11,
            ic_november: 6.289,
            ic_december: 6.287,
            ic_yearly: 5.818,
          },
          {
            ic_uuid: "acd41298-f6c9-4adb-b7fb-0567f70c40fc",
            ic_lat: -115334,
            ic_lon: -574137,
            ic_city: "Porto dos Gaúchos",
            ic_states: "MATO GROSSO",
            ic_january: 4.892,
            ic_february: 4.766,
            ic_march: 4.678,
            ic_april: 4.659,
            ic_may: 4.537,
            ic_june: 4.65,
            ic_july: 4.833,
            ic_august: 5.225,
            ic_september: 5.039,
            ic_october: 5.184,
            ic_november: 4.984,
            ic_december: 5.036,
            ic_yearly: 4.873,
          },
          {
            ic_uuid: "436b20ed-9102-434a-a362-2856b4226173",
            ic_lat: -158575,
            ic_lon: -584624,
            ic_city: "Porto Esperidião",
            ic_states: "MATO GROSSO",
            ic_january: 5.469,
            ic_february: 5.322,
            ic_march: 5.06,
            ic_april: 4.772,
            ic_may: 4.064,
            ic_june: 4.117,
            ic_july: 4.269,
            ic_august: 4.963,
            ic_september: 5.088,
            ic_october: 5.37,
            ic_november: 5.581,
            ic_december: 5.649,
            ic_yearly: 4.977,
          },
          {
            ic_uuid: "9668acc6-da26-46fd-87b9-8958fc6aa812",
            ic_lat: -153333,
            ic_lon: -572045,
            ic_city: "Porto Estrela",
            ic_states: "MATO GROSSO",
            ic_january: 5.394,
            ic_february: 5.271,
            ic_march: 5.134,
            ic_april: 4.864,
            ic_may: 4.399,
            ic_june: 4.389,
            ic_july: 4.521,
            ic_august: 5.125,
            ic_september: 5.129,
            ic_october: 5.334,
            ic_november: 5.538,
            ic_december: 5.633,
            ic_yearly: 5.061,
          },
          {
            ic_uuid: "785430fe-68f6-428d-a89e-13616d6470a7",
            ic_lat: -232098,
            ic_lon: -475255,
            ic_city: "Porto Feliz",
            ic_states: "SÃO PAULO",
            ic_january: 5.598,
            ic_february: 5.865,
            ic_march: 5.134,
            ic_april: 4.524,
            ic_may: 3.737,
            ic_june: 3.435,
            ic_july: 3.554,
            ic_august: 4.549,
            ic_september: 4.71,
            ic_october: 5.355,
            ic_november: 5.739,
            ic_december: 6.165,
            ic_yearly: 4.864,
          },
          {
            ic_uuid: "6ebdbe49-2c4d-4e03-872e-d7dd37009244",
            ic_lat: -218503,
            ic_lon: -474874,
            ic_city: "Porto Ferreira",
            ic_states: "SÃO PAULO",
            ic_january: 5.671,
            ic_february: 5.848,
            ic_march: 5.134,
            ic_april: 4.776,
            ic_may: 4.015,
            ic_june: 3.76,
            ic_july: 3.941,
            ic_august: 4.876,
            ic_september: 4.953,
            ic_october: 5.54,
            ic_november: 5.748,
            ic_december: 6.049,
            ic_yearly: 5.026,
          },
          {
            ic_uuid: "2b38b71a-a861-4363-b809-985d50e153ed",
            ic_lat: -206647,
            ic_lon: -430838,
            ic_city: "Porto Firme",
            ic_states: "MINAS GERAIS",
            ic_january: 5.612,
            ic_february: 5.886,
            ic_march: 4.921,
            ic_april: 4.364,
            ic_may: 3.743,
            ic_june: 3.513,
            ic_july: 3.733,
            ic_august: 4.598,
            ic_september: 4.843,
            ic_october: 5.024,
            ic_november: 4.739,
            ic_december: 5.443,
            ic_yearly: 4.702,
          },
          {
            ic_uuid: "53aac203-35f2-4711-a257-76a87133e595",
            ic_lat: -63419,
            ic_lon: -473966,
            ic_city: "Porto Franco",
            ic_states: "MARANHÃO",
            ic_january: 4.772,
            ic_february: 4.87,
            ic_march: 4.877,
            ic_april: 4.913,
            ic_may: 4.917,
            ic_june: 5.168,
            ic_july: 5.364,
            ic_august: 5.907,
            ic_september: 5.795,
            ic_october: 5.282,
            ic_november: 4.882,
            ic_december: 4.803,
            ic_yearly: 5.129,
          },
          {
            ic_uuid: "b05b3cf4-173f-4a98-a4ca-d48dc119d686",
            ic_lat: 708,
            ic_lon: -514041,
            ic_city: "Porto Grande",
            ic_states: "AMAPÁ",
            ic_january: 4.169,
            ic_february: 4.044,
            ic_march: 4.042,
            ic_april: 4.068,
            ic_may: 4.326,
            ic_june: 4.531,
            ic_july: 4.68,
            ic_august: 5.059,
            ic_september: 5.297,
            ic_october: 5.138,
            ic_november: 4.898,
            ic_december: 4.323,
            ic_yearly: 4.548,
          },
          {
            ic_uuid: "8800e9a5-5b4e-49f7-8410-ba7182aaea31",
            ic_lat: -278573,
            ic_lon: -550105,
            ic_city: "Porto Lucena",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.546,
            ic_february: 6.05,
            ic_march: 5.197,
            ic_april: 4.042,
            ic_may: 3.1,
            ic_june: 2.524,
            ic_july: 2.831,
            ic_august: 3.596,
            ic_september: 4.055,
            ic_october: 5.131,
            ic_november: 6.331,
            ic_december: 6.726,
            ic_yearly: 4.677,
          },
          {
            ic_uuid: "4b63259a-b253-4c30-a299-8e0b79757458",
            ic_lat: -2758,
            ic_lon: -546662,
            ic_city: "Porto Mauá",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.496,
            ic_february: 5.967,
            ic_march: 5.147,
            ic_april: 4.047,
            ic_may: 3.056,
            ic_june: 2.495,
            ic_july: 2.798,
            ic_august: 3.622,
            ic_september: 4.018,
            ic_october: 5.188,
            ic_november: 6.231,
            ic_december: 6.669,
            ic_yearly: 4.645,
          },
          {
            ic_uuid: "d46ac378-59d6-4e9f-b66b-1d27398b937b",
            ic_lat: -216985,
            ic_lon: -57884,
            ic_city: "Porto Murtinho",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 6.294,
            ic_february: 5.822,
            ic_march: 5.39,
            ic_april: 4.64,
            ic_may: 3.571,
            ic_june: 3.161,
            ic_july: 3.293,
            ic_august: 4.293,
            ic_september: 4.629,
            ic_october: 5.473,
            ic_november: 6.073,
            ic_december: 6.567,
            ic_yearly: 4.934,
          },
          {
            ic_uuid: "edafb040-7459-401b-b868-86812aa35a36",
            ic_lat: -107031,
            ic_lon: -484084,
            ic_city: "Porto Nacional",
            ic_states: "TOCANTINS",
            ic_january: 5.29,
            ic_february: 5.256,
            ic_march: 4.93,
            ic_april: 5.124,
            ic_may: 5.112,
            ic_june: 4.97,
            ic_july: 5.177,
            ic_august: 5.68,
            ic_september: 5.791,
            ic_october: 5.468,
            ic_november: 5.132,
            ic_december: 5.177,
            ic_yearly: 5.259,
          },
          {
            ic_uuid: "d293daec-12d0-49ea-93fe-7f540c081aef",
            ic_lat: -22418,
            ic_lon: -442955,
            ic_city: "Porto Real",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.5,
            ic_february: 5.799,
            ic_march: 4.826,
            ic_april: 4.326,
            ic_may: 3.614,
            ic_june: 3.424,
            ic_july: 3.453,
            ic_august: 4.396,
            ic_september: 4.551,
            ic_october: 4.869,
            ic_november: 4.892,
            ic_december: 5.544,
            ic_yearly: 4.6,
          },
          {
            ic_uuid: "3eb5d056-d2bb-42be-b9e2-d09bc4ab7f38",
            ic_lat: -101853,
            ic_lon: -368379,
            ic_city: "Porto Real do Colégio",
            ic_states: "ALAGOAS",
            ic_january: 6.333,
            ic_february: 6.118,
            ic_march: 5.996,
            ic_april: 5.239,
            ic_may: 4.349,
            ic_june: 4.101,
            ic_july: 4.151,
            ic_august: 4.716,
            ic_september: 5.538,
            ic_october: 5.903,
            ic_november: 6.349,
            ic_december: 6.408,
            ic_yearly: 5.434,
          },
          {
            ic_uuid: "551c7fcc-7891-46be-91b9-96bc7743ab3e",
            ic_lat: -227752,
            ic_lon: -532682,
            ic_city: "Porto Rico",
            ic_states: "PARANÁ",
            ic_january: 6.029,
            ic_february: 5.988,
            ic_march: 5.469,
            ic_april: 4.664,
            ic_may: 3.69,
            ic_june: 3.353,
            ic_july: 3.52,
            ic_august: 4.425,
            ic_september: 4.694,
            ic_october: 5.436,
            ic_november: 6.148,
            ic_december: 6.528,
            ic_yearly: 4.995,
          },
          {
            ic_uuid: "f0eb5bd2-de68-41d5-8f7e-922a0b111493",
            ic_lat: -18624,
            ic_lon: -445955,
            ic_city: "Porto Rico do Maranhão",
            ic_states: "MARANHÃO",
            ic_january: 4.989,
            ic_february: 4.995,
            ic_march: 4.794,
            ic_april: 4.561,
            ic_may: 4.844,
            ic_june: 5.043,
            ic_july: 5.092,
            ic_august: 5.47,
            ic_september: 5.809,
            ic_october: 5.678,
            ic_november: 5.603,
            ic_december: 5.309,
            ic_yearly: 5.182,
          },
          {
            ic_uuid: "1d661817-909d-4b33-8a32-0db0270f45f3",
            ic_lat: -164439,
            ic_lon: -390646,
            ic_city: "Porto Seguro",
            ic_states: "BAHIA",
            ic_january: 6.231,
            ic_february: 6.351,
            ic_march: 5.688,
            ic_april: 4.7,
            ic_may: 4.021,
            ic_june: 3.686,
            ic_july: 3.903,
            ic_august: 4.577,
            ic_september: 5.129,
            ic_october: 5.458,
            ic_november: 5.491,
            ic_december: 6.295,
            ic_yearly: 5.127,
          },
          {
            ic_uuid: "7345cbf0-a572-4d53-b422-cfe86f47fb4a",
            ic_lat: -262455,
            ic_lon: -510763,
            ic_city: "Porto União",
            ic_states: "SANTA CATARINA",
            ic_january: 5.465,
            ic_february: 5.087,
            ic_march: 4.575,
            ic_april: 3.578,
            ic_may: 2.877,
            ic_june: 2.429,
            ic_july: 2.647,
            ic_august: 3.683,
            ic_september: 3.821,
            ic_october: 4.475,
            ic_november: 5.448,
            ic_december: 5.642,
            ic_yearly: 4.144,
          },
          {
            ic_uuid: "4b71aaca-e814-4c28-88fd-01578dfeb6fb",
            ic_lat: -87616,
            ic_lon: -639008,
            ic_city: "Porto Velho",
            ic_states: "RONDÔNIA",
            ic_january: 4.213,
            ic_february: 4.439,
            ic_march: 4.321,
            ic_april: 4.345,
            ic_may: 3.97,
            ic_june: 4.277,
            ic_july: 4.468,
            ic_august: 4.842,
            ic_september: 4.856,
            ic_october: 4.953,
            ic_november: 4.648,
            ic_december: 4.426,
            ic_yearly: 4.48,
          },
          {
            ic_uuid: "ee4e2d95-f2ba-46d6-aaf5-c7c00e8e24d3",
            ic_lat: -27741,
            ic_lon: -548998,
            ic_city: "Porto Vera Cruz",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.471,
            ic_february: 5.956,
            ic_march: 5.176,
            ic_april: 4.07,
            ic_may: 3.097,
            ic_june: 2.518,
            ic_july: 2.816,
            ic_august: 3.636,
            ic_september: 4.025,
            ic_october: 5.146,
            ic_november: 6.244,
            ic_december: 6.65,
            ic_yearly: 4.651,
          },
          {
            ic_uuid: "5a3d0aa4-c6a1-4883-bfe1-24785a580ebd",
            ic_lat: -261679,
            ic_lon: -512314,
            ic_city: "Porto Vitória",
            ic_states: "PARANÁ",
            ic_january: 5.549,
            ic_february: 5.222,
            ic_march: 4.658,
            ic_april: 3.675,
            ic_may: 2.904,
            ic_june: 2.459,
            ic_july: 2.685,
            ic_august: 3.751,
            ic_september: 3.888,
            ic_october: 4.574,
            ic_november: 5.589,
            ic_december: 5.814,
            ic_yearly: 4.231,
          },
          {
            ic_uuid: "691adbdb-a095-4420-a877-31f630c3c39b",
            ic_lat: -82636,
            ic_lon: -727542,
            ic_city: "Porto Walter",
            ic_states: "ACRE",
            ic_january: 4.779,
            ic_february: 4.887,
            ic_march: 4.257,
            ic_april: 4.537,
            ic_may: 4.301,
            ic_june: 4.184,
            ic_july: 4.419,
            ic_august: 5.124,
            ic_september: 5.306,
            ic_october: 5.14,
            ic_november: 5.056,
            ic_december: 4.779,
            ic_yearly: 4.731,
          },
          {
            ic_uuid: "1626f000-db43-411a-8dd5-61e17aaf7480",
            ic_lat: -279086,
            ic_lon: -551384,
            ic_city: "Porto Xavier",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.595,
            ic_february: 6.054,
            ic_march: 5.215,
            ic_april: 4.039,
            ic_may: 3.092,
            ic_june: 2.512,
            ic_july: 2.821,
            ic_august: 3.6,
            ic_september: 4.048,
            ic_october: 5.137,
            ic_november: 6.32,
            ic_december: 6.732,
            ic_yearly: 4.68,
          },
          {
            ic_uuid: "d6e8ed4e-927a-4690-b804-6a288748996d",
            ic_lat: -140893,
            ic_lon: -463632,
            ic_city: "Posse",
            ic_states: "GOIÁS",
            ic_january: 5.797,
            ic_february: 5.781,
            ic_march: 5.294,
            ic_april: 5.166,
            ic_may: 4.97,
            ic_june: 4.913,
            ic_july: 5.32,
            ic_august: 6.01,
            ic_september: 5.943,
            ic_october: 5.722,
            ic_november: 5.128,
            ic_december: 5.704,
            ic_yearly: 5.479,
          },
          {
            ic_uuid: "00442c0a-3f34-41b0-a920-d1a1689e8831",
            ic_lat: -178082,
            ic_lon: -417864,
            ic_city: "Poté",
            ic_states: "MINAS GERAIS",
            ic_january: 6.026,
            ic_february: 6.234,
            ic_march: 5.339,
            ic_april: 4.552,
            ic_may: 3.85,
            ic_june: 3.585,
            ic_july: 3.713,
            ic_august: 4.438,
            ic_september: 4.943,
            ic_october: 5.302,
            ic_november: 4.991,
            ic_december: 5.852,
            ic_yearly: 4.902,
          },
          {
            ic_uuid: "ac71909b-640c-490e-aaca-94138b23e51c",
            ic_lat: -7093,
            ic_lon: -400283,
            ic_city: "Potengi",
            ic_states: "CEARÁ",
            ic_january: 5.558,
            ic_february: 5.523,
            ic_march: 5.61,
            ic_april: 5.327,
            ic_may: 5.112,
            ic_june: 5.041,
            ic_july: 5.418,
            ic_august: 6.186,
            ic_september: 6.58,
            ic_october: 6.483,
            ic_november: 6.435,
            ic_december: 6.026,
            ic_yearly: 5.775,
          },
          {
            ic_uuid: "d3e190d9-9f7a-43b9-a799-295b1fd9d6af",
            ic_lat: -228348,
            ic_lon: -452556,
            ic_city: "Potim",
            ic_states: "SÃO PAULO",
            ic_january: 5.364,
            ic_february: 5.624,
            ic_march: 4.809,
            ic_april: 4.381,
            ic_may: 3.611,
            ic_june: 3.386,
            ic_july: 3.513,
            ic_august: 4.478,
            ic_september: 4.594,
            ic_october: 5.033,
            ic_november: 5.082,
            ic_december: 5.661,
            ic_yearly: 4.628,
          },
          {
            ic_uuid: "0a6461c4-8118-40fb-a4ce-25e73dc38ef7",
            ic_lat: -155932,
            ic_lon: -398743,
            ic_city: "Potiraguá",
            ic_states: "BAHIA",
            ic_january: 5.907,
            ic_february: 5.999,
            ic_march: 5.385,
            ic_april: 4.592,
            ic_may: 3.852,
            ic_june: 3.477,
            ic_july: 3.668,
            ic_august: 4.262,
            ic_september: 4.893,
            ic_october: 5.088,
            ic_november: 5.165,
            ic_december: 6.021,
            ic_yearly: 4.859,
          },
          {
            ic_uuid: "c2d2c8f1-78e5-4953-a835-0c43a294fb82",
            ic_lat: -21043,
            ic_lon: -493769,
            ic_city: "Potirendaba",
            ic_states: "SÃO PAULO",
            ic_january: 5.797,
            ic_february: 5.867,
            ic_march: 5.195,
            ic_april: 4.837,
            ic_may: 4.105,
            ic_june: 3.865,
            ic_july: 4.084,
            ic_august: 4.99,
            ic_september: 5.078,
            ic_october: 5.614,
            ic_november: 5.905,
            ic_december: 6.117,
            ic_yearly: 5.122,
          },
          {
            ic_uuid: "8f4f0d65-a63f-4ea3-a90a-7e74538dd1c1",
            ic_lat: -57133,
            ic_lon: -381581,
            ic_city: "Potiretama",
            ic_states: "CEARÁ",
            ic_january: 5.924,
            ic_february: 5.974,
            ic_march: 5.954,
            ic_april: 5.608,
            ic_may: 5.295,
            ic_june: 5.065,
            ic_july: 5.419,
            ic_august: 6.059,
            ic_september: 6.418,
            ic_october: 6.617,
            ic_november: 6.639,
            ic_december: 6.07,
            ic_yearly: 5.921,
          },
          {
            ic_uuid: "27d1eae0-9d19-4bce-bdc4-08701a1c34fe",
            ic_lat: -222271,
            ic_lon: -459393,
            ic_city: "Pouso Alegre",
            ic_states: "MINAS GERAIS",
            ic_january: 5.26,
            ic_february: 5.549,
            ic_march: 4.811,
            ic_april: 4.569,
            ic_may: 3.885,
            ic_june: 3.616,
            ic_july: 3.875,
            ic_august: 4.773,
            ic_september: 4.967,
            ic_october: 5.316,
            ic_november: 5.344,
            ic_december: 5.568,
            ic_yearly: 4.794,
          },
          {
            ic_uuid: "0d5b53f0-ce8d-4ca8-a9e1-678fb5bf8453",
            ic_lat: -221968,
            ic_lon: -449752,
            ic_city: "Pouso Alto",
            ic_states: "MINAS GERAIS",
            ic_january: 5.466,
            ic_february: 5.772,
            ic_march: 4.969,
            ic_april: 4.637,
            ic_may: 3.953,
            ic_june: 3.621,
            ic_july: 3.846,
            ic_august: 4.72,
            ic_september: 5.012,
            ic_october: 5.481,
            ic_november: 5.34,
            ic_december: 5.704,
            ic_yearly: 4.877,
          },
          {
            ic_uuid: "054cea10-0439-472a-8d95-0d929808648f",
            ic_lat: -29171,
            ic_lon: -522079,
            ic_city: "Pouso Novo",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.132,
            ic_february: 5.695,
            ic_march: 4.813,
            ic_april: 3.803,
            ic_may: 2.821,
            ic_june: 2.358,
            ic_july: 2.62,
            ic_august: 3.375,
            ic_september: 3.629,
            ic_october: 4.679,
            ic_november: 6.048,
            ic_december: 6.469,
            ic_yearly: 4.37,
          },
          {
            ic_uuid: "19929498-77d1-49d8-a18e-25c353db5965",
            ic_lat: -272572,
            ic_lon: -499305,
            ic_city: "Pouso Redondo",
            ic_states: "SANTA CATARINA",
            ic_january: 5.517,
            ic_february: 5.28,
            ic_march: 4.553,
            ic_april: 3.608,
            ic_may: 2.854,
            ic_june: 2.318,
            ic_july: 2.522,
            ic_august: 3.278,
            ic_september: 3.478,
            ic_october: 4.084,
            ic_november: 5.352,
            ic_december: 5.673,
            ic_yearly: 4.043,
          },
          {
            ic_uuid: "99768b7b-50d0-499f-8a40-7f6659017f6e",
            ic_lat: -158282,
            ic_lon: -543953,
            ic_city: "Poxoréu",
            ic_states: "MATO GROSSO",
            ic_january: 5.33,
            ic_february: 5.313,
            ic_march: 5.124,
            ic_april: 5.07,
            ic_may: 4.715,
            ic_june: 4.578,
            ic_july: 4.741,
            ic_august: 5.653,
            ic_september: 5.185,
            ic_october: 5.176,
            ic_november: 5.408,
            ic_december: 5.361,
            ic_yearly: 5.138,
          },
          {
            ic_uuid: "b2f09ecb-114c-41b3-b118-4210e008c453",
            ic_lat: -218532,
            ic_lon: -510862,
            ic_city: "Pracinha",
            ic_states: "SÃO PAULO",
            ic_january: 5.947,
            ic_february: 5.972,
            ic_march: 5.459,
            ic_april: 4.822,
            ic_may: 3.97,
            ic_june: 3.659,
            ic_july: 3.827,
            ic_august: 4.836,
            ic_september: 4.804,
            ic_october: 5.563,
            ic_november: 6.08,
            ic_december: 6.398,
            ic_yearly: 5.111,
          },
          {
            ic_uuid: "9610a35e-cfc5-48df-841d-b867eed22cca",
            ic_lat: 17448,
            ic_lon: -507839,
            ic_city: "Pracuúba",
            ic_states: "AMAPÁ",
            ic_january: 4.206,
            ic_february: 3.954,
            ic_march: 3.922,
            ic_april: 3.933,
            ic_may: 4.345,
            ic_june: 4.553,
            ic_july: 4.863,
            ic_august: 5.247,
            ic_september: 5.537,
            ic_october: 5.446,
            ic_november: 5.235,
            ic_december: 4.528,
            ic_yearly: 4.647,
          },
          {
            ic_uuid: "0b4edc0b-b1c8-4b9f-9b1c-a68b75522528",
            ic_lat: -173368,
            ic_lon: -392231,
            ic_city: "Prado",
            ic_states: "BAHIA",
            ic_january: 6.352,
            ic_february: 6.444,
            ic_march: 5.58,
            ic_april: 4.576,
            ic_may: 3.953,
            ic_june: 3.684,
            ic_july: 3.841,
            ic_august: 4.568,
            ic_september: 5.137,
            ic_october: 5.473,
            ic_november: 5.537,
            ic_december: 6.378,
            ic_yearly: 5.127,
          },
          {
            ic_uuid: "14072351-cd5e-4ea3-8c36-c97291652200",
            ic_lat: -230362,
            ic_lon: -514433,
            ic_city: "Prado Ferreira",
            ic_states: "PARANÁ",
            ic_january: 5.906,
            ic_february: 5.905,
            ic_march: 5.403,
            ic_april: 4.727,
            ic_may: 3.712,
            ic_june: 3.425,
            ic_july: 3.655,
            ic_august: 4.585,
            ic_september: 4.767,
            ic_october: 5.454,
            ic_november: 6.172,
            ic_december: 6.392,
            ic_yearly: 5.009,
          },
          {
            ic_uuid: "4082b610-c9bf-4ae6-b9e4-6be3298175be",
            ic_lat: -21363,
            ic_lon: -480683,
            ic_city: "Pradópolis",
            ic_states: "SÃO PAULO",
            ic_january: 5.705,
            ic_february: 5.946,
            ic_march: 5.215,
            ic_april: 4.747,
            ic_may: 4.082,
            ic_june: 3.832,
            ic_july: 4.02,
            ic_august: 4.853,
            ic_september: 4.924,
            ic_october: 5.511,
            ic_november: 5.762,
            ic_december: 6.109,
            ic_yearly: 5.059,
          },
          {
            ic_uuid: "0f5bfe26-ce84-442b-b027-473848edf163",
            ic_lat: -210602,
            ic_lon: -440782,
            ic_city: "Prados",
            ic_states: "MINAS GERAIS",
            ic_january: 5.551,
            ic_february: 5.818,
            ic_march: 4.842,
            ic_april: 4.404,
            ic_may: 3.808,
            ic_june: 3.631,
            ic_july: 3.864,
            ic_august: 4.789,
            ic_september: 4.962,
            ic_october: 5.164,
            ic_november: 4.956,
            ic_december: 5.497,
            ic_yearly: 4.774,
          },
          {
            ic_uuid: "d50191aa-475b-4d6a-a383-206a66ae7bbf",
            ic_lat: -291969,
            ic_lon: -499499,
            ic_city: "Praia Grande",
            ic_states: "SANTA CATARINA",
            ic_january: 5.175,
            ic_february: 4.867,
            ic_march: 4.198,
            ic_april: 3.671,
            ic_may: 2.854,
            ic_june: 2.422,
            ic_july: 2.658,
            ic_august: 3.347,
            ic_september: 3.379,
            ic_october: 3.995,
            ic_november: 5.238,
            ic_december: 5.463,
            ic_yearly: 3.939,
          },
          {
            ic_uuid: "d50f1f03-9c2a-40bf-b0cd-dab155d1ceb9",
            ic_lat: -240089,
            ic_lon: -464125,
            ic_city: "Praia Grande",
            ic_states: "SÃO PAULO",
            ic_january: 4.936,
            ic_february: 5.099,
            ic_march: 4.289,
            ic_april: 3.827,
            ic_may: 3.153,
            ic_june: 2.791,
            ic_july: 2.799,
            ic_august: 3.436,
            ic_september: 3.392,
            ic_october: 3.766,
            ic_november: 4.45,
            ic_december: 4.987,
            ic_yearly: 3.91,
          },
          {
            ic_uuid: "d0e52197-1fc8-43f8-a1f7-080573751b71",
            ic_lat: -53932,
            ic_lon: -478115,
            ic_city: "Praia Norte",
            ic_states: "TOCANTINS",
            ic_january: 4.623,
            ic_february: 4.796,
            ic_march: 4.916,
            ic_april: 4.949,
            ic_may: 4.822,
            ic_june: 5.141,
            ic_july: 5.277,
            ic_august: 5.72,
            ic_september: 5.594,
            ic_october: 5.235,
            ic_november: 4.936,
            ic_december: 4.762,
            ic_yearly: 5.064,
          },
          {
            ic_uuid: "6ecdab02-fb4e-4cd9-89bb-d4836669c914",
            ic_lat: -17929,
            ic_lon: -534817,
            ic_city: "Prainha",
            ic_states: "PARÁ",
            ic_january: 4.656,
            ic_february: 4.654,
            ic_march: 4.698,
            ic_april: 4.629,
            ic_may: 4.715,
            ic_june: 4.962,
            ic_july: 4.933,
            ic_august: 5.218,
            ic_september: 5.109,
            ic_october: 5.352,
            ic_november: 5.194,
            ic_december: 4.764,
            ic_yearly: 4.907,
          },
          {
            ic_uuid: "fda1f527-a05b-48f5-b3f6-23e89dbee0b2",
            ic_lat: -260224,
            ic_lon: -537416,
            ic_city: "Pranchita",
            ic_states: "PARANÁ",
            ic_january: 6.422,
            ic_february: 5.872,
            ic_march: 5.218,
            ic_april: 4.197,
            ic_may: 3.265,
            ic_june: 2.805,
            ic_july: 3.097,
            ic_august: 4.029,
            ic_september: 4.237,
            ic_october: 5.236,
            ic_november: 6.123,
            ic_december: 6.513,
            ic_yearly: 4.751,
          },
          {
            ic_uuid: "eae72093-3bc8-46cb-a191-de8f064860c2",
            ic_lat: -193091,
            ic_lon: -48928,
            ic_city: "Prata",
            ic_states: "MINAS GERAIS",
            ic_january: 5.631,
            ic_february: 5.823,
            ic_march: 5.129,
            ic_april: 5.036,
            ic_may: 4.442,
            ic_june: 4.181,
            ic_july: 4.444,
            ic_august: 5.378,
            ic_september: 5.212,
            ic_october: 5.595,
            ic_november: 5.704,
            ic_december: 5.906,
            ic_yearly: 5.207,
          },
          {
            ic_uuid: "15c8fa5f-588b-4edc-a0d5-bb19bea8ec96",
            ic_lat: -7695,
            ic_lon: -370836,
            ic_city: "Prata",
            ic_states: "PARAÍBA",
            ic_january: 6.183,
            ic_february: 6.1,
            ic_march: 6.063,
            ic_april: 5.734,
            ic_may: 4.948,
            ic_june: 4.454,
            ic_july: 4.611,
            ic_august: 5.383,
            ic_september: 6.232,
            ic_october: 6.475,
            ic_november: 6.657,
            ic_december: 6.353,
            ic_yearly: 5.766,
          },
          {
            ic_uuid: "e30f8a96-926b-43e7-af13-a0e11f840ea6",
            ic_lat: -56731,
            ic_lon: -42205,
            ic_city: "Prata do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.295,
            ic_february: 5.335,
            ic_march: 5.336,
            ic_april: 5.265,
            ic_may: 5.259,
            ic_june: 5.311,
            ic_july: 5.596,
            ic_august: 6.138,
            ic_september: 6.539,
            ic_october: 6.432,
            ic_november: 6.269,
            ic_december: 5.805,
            ic_yearly: 5.715,
          },
          {
            ic_uuid: "9f79336e-294d-4333-aa7a-f59227a5e3c3",
            ic_lat: -228117,
            ic_lon: -48664,
            ic_city: "Pratânia",
            ic_states: "SÃO PAULO",
            ic_january: 5.59,
            ic_february: 5.757,
            ic_march: 5.131,
            ic_april: 4.612,
            ic_may: 3.779,
            ic_june: 3.525,
            ic_july: 3.701,
            ic_august: 4.692,
            ic_september: 4.791,
            ic_october: 5.452,
            ic_november: 5.83,
            ic_december: 6.163,
            ic_yearly: 4.919,
          },
          {
            ic_uuid: "49e55bc0-d462-4905-ad29-d33559d4baef",
            ic_lat: -207415,
            ic_lon: -468628,
            ic_city: "Pratápolis",
            ic_states: "MINAS GERAIS",
            ic_january: 5.701,
            ic_february: 5.941,
            ic_march: 5.057,
            ic_april: 4.82,
            ic_may: 4.244,
            ic_june: 4.047,
            ic_july: 4.274,
            ic_august: 5.191,
            ic_september: 5.176,
            ic_october: 5.589,
            ic_november: 5.576,
            ic_december: 5.748,
            ic_yearly: 5.114,
          },
          {
            ic_uuid: "385e9cfa-ea25-400b-a03a-755cf3ea563f",
            ic_lat: -197526,
            ic_lon: -463833,
            ic_city: "Pratinha",
            ic_states: "MINAS GERAIS",
            ic_january: 5.52,
            ic_february: 5.77,
            ic_march: 4.798,
            ic_april: 4.716,
            ic_may: 4.335,
            ic_june: 4.161,
            ic_july: 4.414,
            ic_august: 5.485,
            ic_september: 5.46,
            ic_october: 5.449,
            ic_november: 5.03,
            ic_december: 5.486,
            ic_yearly: 5.052,
          },
          {
            ic_uuid: "4d5eb48c-3790-4322-aef3-4c48d36fafd0",
            ic_lat: -221004,
            ic_lon: -494385,
            ic_city: "Presidente Alves",
            ic_states: "SÃO PAULO",
            ic_january: 5.647,
            ic_february: 5.878,
            ic_march: 5.232,
            ic_april: 4.751,
            ic_may: 3.904,
            ic_june: 3.603,
            ic_july: 3.843,
            ic_august: 4.766,
            ic_september: 4.86,
            ic_october: 5.582,
            ic_november: 5.914,
            ic_december: 6.228,
            ic_yearly: 5.017,
          },
          {
            ic_uuid: "e37983d9-a4b3-4a70-bcbc-455eac93b3e6",
            ic_lat: -20766,
            ic_lon: -431899,
            ic_city: "Presidente Bernardes",
            ic_states: "MINAS GERAIS",
            ic_january: 5.575,
            ic_february: 5.92,
            ic_march: 4.848,
            ic_april: 4.346,
            ic_may: 3.687,
            ic_june: 3.533,
            ic_july: 3.718,
            ic_august: 4.561,
            ic_september: 4.804,
            ic_october: 4.994,
            ic_november: 4.689,
            ic_december: 5.417,
            ic_yearly: 4.674,
          },
          {
            ic_uuid: "fb7406ee-131b-47d8-8aaa-8865c250f410",
            ic_lat: -219991,
            ic_lon: -515627,
            ic_city: "Presidente Bernardes",
            ic_states: "SÃO PAULO",
            ic_january: 5.981,
            ic_february: 6.037,
            ic_march: 5.512,
            ic_april: 4.852,
            ic_may: 3.947,
            ic_june: 3.601,
            ic_july: 3.836,
            ic_august: 4.77,
            ic_september: 4.848,
            ic_october: 5.57,
            ic_november: 6.113,
            ic_december: 6.432,
            ic_yearly: 5.125,
          },
          {
            ic_uuid: "d7292e18-53f7-44a3-99b2-c9e0bce9b7ac",
            ic_lat: -272253,
            ic_lon: -518038,
            ic_city: "Presidente Castello Branco",
            ic_states: "SANTA CATARINA",
            ic_january: 5.934,
            ic_february: 5.533,
            ic_march: 4.983,
            ic_april: 4.04,
            ic_may: 3.092,
            ic_june: 2.671,
            ic_july: 2.944,
            ic_august: 3.782,
            ic_september: 4.024,
            ic_october: 4.967,
            ic_november: 6.11,
            ic_december: 6.306,
            ic_yearly: 4.532,
          },
          {
            ic_uuid: "bb6e1dbe-2018-4faa-b794-489d82e04873",
            ic_lat: -232787,
            ic_lon: -52154,
            ic_city: "Presidente Castelo Branco",
            ic_states: "PARANÁ",
            ic_january: 5.9,
            ic_february: 5.862,
            ic_march: 5.363,
            ic_april: 4.612,
            ic_may: 3.639,
            ic_june: 3.32,
            ic_july: 3.535,
            ic_august: 4.491,
            ic_september: 4.684,
            ic_october: 5.407,
            ic_november: 6.096,
            ic_december: 6.355,
            ic_yearly: 4.939,
          },
          {
            ic_uuid: "997c5336-4562-4d23-86e4-4e4757898b72",
            ic_lat: -112928,
            ic_lon: -419847,
            ic_city: "Presidente Dutra",
            ic_states: "BAHIA",
            ic_january: 6.401,
            ic_february: 6.24,
            ic_march: 5.985,
            ic_april: 5.298,
            ic_may: 4.836,
            ic_june: 4.672,
            ic_july: 4.926,
            ic_august: 5.672,
            ic_september: 6.348,
            ic_october: 6.464,
            ic_november: 6.199,
            ic_december: 6.42,
            ic_yearly: 5.789,
          },
          {
            ic_uuid: "a0196626-2c3d-49c1-a5c5-708eee780c43",
            ic_lat: -52902,
            ic_lon: -444953,
            ic_city: "Presidente Dutra",
            ic_states: "MARANHÃO",
            ic_january: 4.826,
            ic_february: 4.883,
            ic_march: 4.957,
            ic_april: 4.916,
            ic_may: 4.952,
            ic_june: 5.154,
            ic_july: 5.421,
            ic_august: 6.051,
            ic_september: 6.262,
            ic_october: 5.713,
            ic_november: 5.363,
            ic_december: 5.079,
            ic_yearly: 5.298,
          },
          {
            ic_uuid: "8512261c-33bd-4a1a-b841-26c90a3dff92",
            ic_lat: -217655,
            ic_lon: -521116,
            ic_city: "Presidente Epitácio",
            ic_states: "SÃO PAULO",
            ic_january: 6.064,
            ic_february: 6.165,
            ic_march: 5.637,
            ic_april: 4.839,
            ic_may: 3.969,
            ic_june: 3.606,
            ic_july: 3.775,
            ic_august: 4.722,
            ic_september: 4.757,
            ic_october: 5.52,
            ic_november: 6.152,
            ic_december: 6.59,
            ic_yearly: 5.15,
          },
          {
            ic_uuid: "47b54f87-acbf-45ec-8d11-fa42357275e6",
            ic_lat: -20302,
            ic_lon: -600238,
            ic_city: "Presidente Figueiredo",
            ic_states: "AMAZONAS",
            ic_january: 4.147,
            ic_february: 4.159,
            ic_march: 4.148,
            ic_april: 3.88,
            ic_may: 3.845,
            ic_june: 4.308,
            ic_july: 4.283,
            ic_august: 4.907,
            ic_september: 4.961,
            ic_october: 4.881,
            ic_november: 4.614,
            ic_december: 4.328,
            ic_yearly: 4.372,
          },
          {
            ic_uuid: "2990b174-12cd-4de6-a3c8-7b53cd6db726",
            ic_lat: -270443,
            ic_lon: -49619,
            ic_city: "Presidente Getúlio",
            ic_states: "SANTA CATARINA",
            ic_january: 5.498,
            ic_february: 5.222,
            ic_march: 4.589,
            ic_april: 3.628,
            ic_may: 2.863,
            ic_june: 2.339,
            ic_july: 2.543,
            ic_august: 3.355,
            ic_september: 3.501,
            ic_october: 4.126,
            ic_november: 5.331,
            ic_december: 5.646,
            ic_yearly: 4.053,
          },
          {
            ic_uuid: "89f815eb-7e13-4d4e-a2ab-280d52734421",
            ic_lat: -146889,
            ic_lon: -416801,
            ic_city: "Presidente Jânio Quadros",
            ic_states: "BAHIA",
            ic_january: 6.133,
            ic_february: 6.289,
            ic_march: 5.603,
            ic_april: 4.929,
            ic_may: 4.341,
            ic_june: 3.911,
            ic_july: 4.232,
            ic_august: 4.843,
            ic_september: 5.679,
            ic_october: 5.886,
            ic_november: 5.626,
            ic_december: 6.238,
            ic_yearly: 5.309,
          },
          {
            ic_uuid: "b7ba0566-9b84-4da5-b7be-f9d4dd6ee14d",
            ic_lat: -29277,
            ic_lon: -440714,
            ic_city: "Presidente Juscelino",
            ic_states: "MARANHÃO",
            ic_january: 4.479,
            ic_february: 4.515,
            ic_march: 4.495,
            ic_april: 4.441,
            ic_may: 4.587,
            ic_june: 4.903,
            ic_july: 5.049,
            ic_august: 5.579,
            ic_september: 5.935,
            ic_october: 5.685,
            ic_november: 5.308,
            ic_december: 4.857,
            ic_yearly: 4.986,
          },
          {
            ic_uuid: "df012cef-7453-4c7c-a5f4-f3b0387dc4bf",
            ic_lat: -18637,
            ic_lon: -44058,
            ic_city: "Presidente Juscelino",
            ic_states: "MINAS GERAIS",
            ic_january: 6.095,
            ic_february: 6.339,
            ic_march: 5.438,
            ic_april: 5.22,
            ic_may: 4.616,
            ic_june: 4.302,
            ic_july: 4.68,
            ic_august: 5.344,
            ic_september: 5.805,
            ic_october: 5.891,
            ic_november: 5.546,
            ic_december: 5.919,
            ic_yearly: 5.433,
          },
          {
            ic_uuid: "0d8704bc-878c-4150-8277-3188ff04e9fc",
            ic_lat: -210968,
            ic_lon: -410472,
            ic_city: "Presidente Kennedy",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.347,
            ic_february: 6.614,
            ic_march: 5.258,
            ic_april: 4.55,
            ic_may: 3.846,
            ic_june: 3.63,
            ic_july: 3.671,
            ic_august: 4.473,
            ic_september: 4.867,
            ic_october: 5.164,
            ic_november: 5.11,
            ic_december: 5.896,
            ic_yearly: 4.952,
          },
          {
            ic_uuid: "25a5bd7b-eec5-4797-8aec-a697357596d0",
            ic_lat: -85414,
            ic_lon: -485071,
            ic_city: "Presidente Kennedy",
            ic_states: "TOCANTINS",
            ic_january: 4.812,
            ic_february: 4.932,
            ic_march: 4.736,
            ic_april: 4.733,
            ic_may: 4.975,
            ic_june: 5.173,
            ic_july: 5.26,
            ic_august: 5.869,
            ic_september: 5.653,
            ic_october: 5.052,
            ic_november: 4.796,
            ic_december: 4.759,
            ic_yearly: 5.062,
          },
          {
            ic_uuid: "d0b3091d-c437-4ed1-a6a7-3d810ba56349",
            ic_lat: -18614,
            ic_lon: -43562,
            ic_city: "Presidente Kubitschek",
            ic_states: "MINAS GERAIS",
            ic_january: 5.847,
            ic_february: 6.102,
            ic_march: 5.101,
            ic_april: 4.654,
            ic_may: 4.202,
            ic_june: 4.018,
            ic_july: 4.271,
            ic_august: 5.051,
            ic_september: 5.485,
            ic_october: 5.533,
            ic_november: 5.008,
            ic_december: 5.604,
            ic_yearly: 5.073,
          },
          {
            ic_uuid: "363c04c3-c9f9-4593-9ecf-bf871719a951",
            ic_lat: -295171,
            ic_lon: -511773,
            ic_city: "Presidente Lucena",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.226,
            ic_february: 5.735,
            ic_march: 4.777,
            ic_april: 3.795,
            ic_may: 2.809,
            ic_june: 2.356,
            ic_july: 2.58,
            ic_august: 3.26,
            ic_september: 3.562,
            ic_october: 4.651,
            ic_november: 6.102,
            ic_december: 6.516,
            ic_yearly: 4.364,
          },
          {
            ic_uuid: "e0f2c6f0-66f7-4aa7-94ac-c0c93a9fda9d",
            ic_lat: -2376,
            ic_lon: -458237,
            ic_city: "Presidente Médici",
            ic_states: "MARANHÃO",
            ic_january: 4.51,
            ic_february: 4.549,
            ic_march: 4.466,
            ic_april: 4.625,
            ic_may: 4.621,
            ic_june: 4.898,
            ic_july: 5.012,
            ic_august: 5.511,
            ic_september: 5.685,
            ic_october: 5.397,
            ic_november: 5.084,
            ic_december: 4.688,
            ic_yearly: 4.92,
          },
          {
            ic_uuid: "355d1cc6-965f-4256-99af-6edae0b7c4b9",
            ic_lat: -111694,
            ic_lon: -618991,
            ic_city: "Presidente Médici",
            ic_states: "RONDÔNIA",
            ic_january: 4.488,
            ic_february: 4.412,
            ic_march: 4.49,
            ic_april: 4.524,
            ic_may: 4.124,
            ic_june: 4.398,
            ic_july: 4.473,
            ic_august: 4.892,
            ic_september: 4.929,
            ic_october: 5.171,
            ic_november: 4.968,
            ic_december: 4.763,
            ic_yearly: 4.636,
          },
          {
            ic_uuid: "ca2828e0-e7b4-493c-9b6f-6a720494d809",
            ic_lat: -272773,
            ic_lon: -493894,
            ic_city: "Presidente Nereu",
            ic_states: "SANTA CATARINA",
            ic_january: 5.479,
            ic_february: 5.151,
            ic_march: 4.497,
            ic_april: 3.653,
            ic_may: 2.908,
            ic_june: 2.418,
            ic_july: 2.602,
            ic_august: 3.356,
            ic_september: 3.433,
            ic_october: 3.977,
            ic_november: 5.215,
            ic_december: 5.544,
            ic_yearly: 4.02,
          },
          {
            ic_uuid: "282b8a28-5a8b-48e8-9a78-ba64a9cbc170",
            ic_lat: -184109,
            ic_lon: -464169,
            ic_city: "Presidente Olegário",
            ic_states: "MINAS GERAIS",
            ic_january: 5.762,
            ic_february: 6.043,
            ic_march: 5.101,
            ic_april: 5.036,
            ic_may: 4.716,
            ic_june: 4.468,
            ic_july: 4.842,
            ic_august: 5.7,
            ic_september: 5.766,
            ic_october: 5.7,
            ic_november: 5.368,
            ic_december: 5.636,
            ic_yearly: 5.345,
          },
          {
            ic_uuid: "d499c5f7-72f9-435a-98d3-76bae4528313",
            ic_lat: -221211,
            ic_lon: -51393,
            ic_city: "Presidente Prudente",
            ic_states: "SÃO PAULO",
            ic_january: 5.991,
            ic_february: 5.983,
            ic_march: 5.463,
            ic_april: 4.813,
            ic_may: 3.929,
            ic_june: 3.583,
            ic_july: 3.829,
            ic_august: 4.776,
            ic_september: 4.853,
            ic_october: 5.544,
            ic_november: 6.103,
            ic_december: 6.459,
            ic_yearly: 5.11,
          },
          {
            ic_uuid: "a22d0495-9ad5-4272-87fd-1c19a1193d33",
            ic_lat: -25918,
            ic_lon: -453631,
            ic_city: "Presidente Sarney",
            ic_states: "MARANHÃO",
            ic_january: 4.576,
            ic_february: 4.628,
            ic_march: 4.5,
            ic_april: 4.566,
            ic_may: 4.682,
            ic_june: 4.944,
            ic_july: 5.054,
            ic_august: 5.611,
            ic_september: 5.815,
            ic_october: 5.579,
            ic_november: 5.293,
            ic_december: 4.875,
            ic_yearly: 5.01,
          },
          {
            ic_uuid: "ef5bf053-c697-494c-9490-e6efcf8a0d34",
            ic_lat: -134536,
            ic_lon: -394229,
            ic_city: "Presidente Tancredo Neves",
            ic_states: "BAHIA",
            ic_january: 5.865,
            ic_february: 5.687,
            ic_march: 5.522,
            ic_april: 4.419,
            ic_may: 3.891,
            ic_june: 3.443,
            ic_july: 3.66,
            ic_august: 4.144,
            ic_september: 4.826,
            ic_october: 4.974,
            ic_november: 5.29,
            ic_december: 5.81,
            ic_yearly: 4.794,
          },
          {
            ic_uuid: "068c0c21-5fd6-47a1-8c1a-3131dcd9fd53",
            ic_lat: -34083,
            ic_lon: -440238,
            ic_city: "Presidente Vargas",
            ic_states: "MARANHÃO",
            ic_january: 4.738,
            ic_february: 4.901,
            ic_march: 4.898,
            ic_april: 4.808,
            ic_may: 4.942,
            ic_june: 5.141,
            ic_july: 5.353,
            ic_august: 5.954,
            ic_september: 6.288,
            ic_october: 6.024,
            ic_november: 5.546,
            ic_december: 5.136,
            ic_yearly: 5.311,
          },
          {
            ic_uuid: "2cfeb2bd-a6eb-4d3f-bccb-21406f8b3641",
            ic_lat: -218737,
            ic_lon: -518452,
            ic_city: "Presidente Venceslau",
            ic_states: "SÃO PAULO",
            ic_january: 6.023,
            ic_february: 6.05,
            ic_march: 5.473,
            ic_april: 4.762,
            ic_may: 3.959,
            ic_june: 3.616,
            ic_july: 3.821,
            ic_august: 4.753,
            ic_september: 4.864,
            ic_october: 5.548,
            ic_november: 6.09,
            ic_december: 6.474,
            ic_yearly: 5.12,
          },
          {
            ic_uuid: "03c6c0e5-39e1-495a-abda-472e77ec7cfe",
            ic_lat: -9458,
            ic_lon: -471256,
            ic_city: "Primavera",
            ic_states: "PARÁ",
            ic_january: 4.601,
            ic_february: 4.358,
            ic_march: 4.221,
            ic_april: 4.233,
            ic_may: 4.482,
            ic_june: 4.8,
            ic_july: 4.881,
            ic_august: 5.194,
            ic_september: 5.517,
            ic_october: 5.412,
            ic_november: 5.267,
            ic_december: 4.975,
            ic_yearly: 4.828,
          },
          {
            ic_uuid: "0aadf683-b16a-46a3-b887-e53b219f69c8",
            ic_lat: -83304,
            ic_lon: -353547,
            ic_city: "Primavera",
            ic_states: "PERNAMBUCO",
            ic_january: 5.485,
            ic_february: 5.556,
            ic_march: 5.565,
            ic_april: 4.882,
            ic_may: 4.191,
            ic_june: 3.83,
            ic_july: 3.889,
            ic_august: 4.472,
            ic_september: 5.182,
            ic_october: 5.432,
            ic_november: 5.746,
            ic_december: 5.794,
            ic_yearly: 5.002,
          },
          {
            ic_uuid: "adb22213-7e82-4ee6-ba3b-54a8ded9c143",
            ic_lat: -118299,
            ic_lon: -613158,
            ic_city: "Primavera de Rondônia",
            ic_states: "RONDÔNIA",
            ic_january: 4.45,
            ic_february: 4.426,
            ic_march: 4.531,
            ic_april: 4.519,
            ic_may: 4.216,
            ic_june: 4.359,
            ic_july: 4.543,
            ic_august: 4.938,
            ic_september: 4.91,
            ic_october: 5.083,
            ic_november: 4.972,
            ic_december: 4.794,
            ic_yearly: 4.645,
          },
          {
            ic_uuid: "08d60d9b-cf7f-4f71-b5f9-58bd46580ece",
            ic_lat: -155526,
            ic_lon: -543031,
            ic_city: "Primavera do Leste",
            ic_states: "MATO GROSSO",
            ic_january: 5.403,
            ic_february: 5.262,
            ic_march: 4.994,
            ic_april: 5.032,
            ic_may: 4.725,
            ic_june: 4.512,
            ic_july: 4.706,
            ic_august: 5.478,
            ic_september: 5.133,
            ic_october: 5.123,
            ic_november: 5.293,
            ic_december: 5.389,
            ic_yearly: 5.088,
          },
          {
            ic_uuid: "7afc98f6-2364-4637-a455-765477a8c9c3",
            ic_lat: -25065,
            ic_lon: -434239,
            ic_city: "Primeira Cruz",
            ic_states: "MARANHÃO",
            ic_january: 4.937,
            ic_february: 4.904,
            ic_march: 4.743,
            ic_april: 4.69,
            ic_may: 4.866,
            ic_june: 5.014,
            ic_july: 5.201,
            ic_august: 5.92,
            ic_september: 6.167,
            ic_october: 5.991,
            ic_november: 5.785,
            ic_december: 5.432,
            ic_yearly: 5.304,
          },
          {
            ic_uuid: "a68b538d-d472-4448-832a-b13e533fd211",
            ic_lat: -22851,
            ic_lon: -510279,
            ic_city: "Primeiro de Maio",
            ic_states: "PARANÁ",
            ic_january: 6.058,
            ic_february: 5.976,
            ic_march: 5.388,
            ic_april: 4.76,
            ic_may: 3.759,
            ic_june: 3.445,
            ic_july: 3.66,
            ic_august: 4.556,
            ic_september: 4.706,
            ic_october: 5.47,
            ic_november: 6.144,
            ic_december: 6.537,
            ic_yearly: 5.038,
          },
          {
            ic_uuid: "2e35f73f-70db-4398-87d4-4f462843bdc2",
            ic_lat: -264446,
            ic_lon: -535999,
            ic_city: "Princesa",
            ic_states: "SANTA CATARINA",
            ic_january: 6.318,
            ic_february: 5.83,
            ic_march: 5.146,
            ic_april: 4.126,
            ic_may: 3.212,
            ic_june: 2.755,
            ic_july: 3.029,
            ic_august: 3.915,
            ic_september: 4.209,
            ic_october: 5.197,
            ic_november: 6.051,
            ic_december: 6.379,
            ic_yearly: 4.68,
          },
          {
            ic_uuid: "500cc99d-33c5-4e20-9b49-6c7085da66ef",
            ic_lat: -77366,
            ic_lon: -37994,
            ic_city: "Princesa Isabel",
            ic_states: "PARAÍBA",
            ic_january: 6.182,
            ic_february: 6.137,
            ic_march: 6.126,
            ic_april: 5.768,
            ic_may: 5.054,
            ic_june: 4.626,
            ic_july: 4.89,
            ic_august: 5.727,
            ic_september: 6.445,
            ic_october: 6.554,
            ic_november: 6.687,
            ic_december: 6.35,
            ic_yearly: 5.879,
          },
          {
            ic_uuid: "a7918f40-e978-4ac3-8139-cab7a7aeb3b5",
            ic_lat: -172549,
            ic_lon: -492452,
            ic_city: "Professor Jamil",
            ic_states: "GOIÁS",
            ic_january: 5.605,
            ic_february: 5.713,
            ic_march: 5.281,
            ic_april: 5.151,
            ic_may: 4.775,
            ic_june: 4.428,
            ic_july: 4.681,
            ic_august: 5.546,
            ic_september: 5.425,
            ic_october: 5.587,
            ic_november: 5.465,
            ic_december: 5.646,
            ic_yearly: 5.275,
          },
          {
            ic_uuid: "509ae687-d880-47ec-be4e-98b83dafcce7",
            ic_lat: -29243,
            ic_lon: -523112,
            ic_city: "Progresso",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.135,
            ic_february: 5.726,
            ic_march: 4.855,
            ic_april: 3.836,
            ic_may: 2.845,
            ic_june: 2.364,
            ic_july: 2.635,
            ic_august: 3.409,
            ic_september: 3.651,
            ic_october: 4.727,
            ic_november: 6.089,
            ic_december: 6.506,
            ic_yearly: 4.398,
          },
          {
            ic_uuid: "36e73b52-dc91-4ba5-995d-fbcf7b033b47",
            ic_lat: -21536,
            ic_lon: -498603,
            ic_city: "Promissão",
            ic_states: "SÃO PAULO",
            ic_january: 5.901,
            ic_february: 6.025,
            ic_march: 5.367,
            ic_april: 4.872,
            ic_may: 3.954,
            ic_june: 3.715,
            ic_july: 3.944,
            ic_august: 4.809,
            ic_september: 4.952,
            ic_october: 5.625,
            ic_november: 6.02,
            ic_december: 6.331,
            ic_yearly: 5.126,
          },
          {
            ic_uuid: "ec477682-3c63-4013-995f-0986453812c1",
            ic_lat: -102168,
            ic_lon: -368396,
            ic_city: "Propriá",
            ic_states: "SERGIPE",
            ic_january: 6.333,
            ic_february: 6.118,
            ic_march: 5.996,
            ic_april: 5.239,
            ic_may: 4.349,
            ic_june: 4.101,
            ic_july: 4.151,
            ic_august: 4.716,
            ic_september: 5.538,
            ic_october: 5.903,
            ic_november: 6.349,
            ic_december: 6.408,
            ic_yearly: 5.434,
          },
          {
            ic_uuid: "5032c5ef-6eba-4741-ae5f-d8f08fdc210e",
            ic_lat: -287577,
            ic_lon: -514762,
            ic_city: "Protásio Alves",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.118,
            ic_february: 5.666,
            ic_march: 4.871,
            ic_april: 3.883,
            ic_may: 2.899,
            ic_june: 2.427,
            ic_july: 2.73,
            ic_august: 3.463,
            ic_september: 3.709,
            ic_october: 4.808,
            ic_november: 6.148,
            ic_december: 6.443,
            ic_yearly: 4.43,
          },
          {
            ic_uuid: "f7f69958-7459-4390-bdcd-99d5f23808a1",
            ic_lat: -194747,
            ic_lon: -441594,
            ic_city: "Prudente de Morais",
            ic_states: "MINAS GERAIS",
            ic_january: 5.953,
            ic_february: 6.149,
            ic_march: 5.247,
            ic_april: 4.92,
            ic_may: 4.352,
            ic_june: 4.122,
            ic_july: 4.429,
            ic_august: 5.279,
            ic_september: 5.642,
            ic_october: 5.683,
            ic_november: 5.444,
            ic_december: 5.683,
            ic_yearly: 5.242,
          },
          {
            ic_uuid: "972ce998-b83c-4659-9394-08b4f2c86dc8",
            ic_lat: -252116,
            ic_lon: -509758,
            ic_city: "Prudentópolis",
            ic_states: "PARANÁ",
            ic_january: 5.717,
            ic_february: 5.4,
            ic_march: 4.78,
            ic_april: 4.012,
            ic_may: 3.183,
            ic_june: 2.763,
            ic_july: 3.039,
            ic_august: 4.018,
            ic_september: 4.198,
            ic_october: 4.807,
            ic_november: 5.674,
            ic_december: 5.912,
            ic_yearly: 4.458,
          },
          {
            ic_uuid: "7a98a589-46ae-4730-9719-f9e1aa13d795",
            ic_lat: -104245,
            ic_lon: -488961,
            ic_city: "Pugmil",
            ic_states: "TOCANTINS",
            ic_january: 5.042,
            ic_february: 5.051,
            ic_march: 4.775,
            ic_april: 4.978,
            ic_may: 4.995,
            ic_june: 5.112,
            ic_july: 5.281,
            ic_august: 5.957,
            ic_september: 5.734,
            ic_october: 5.345,
            ic_november: 5.126,
            ic_december: 5.028,
            ic_yearly: 5.202,
          },
          {
            ic_uuid: "41812c64-2d50-41f5-a800-c3d6376ba0cf",
            ic_lat: -54643,
            ic_lon: -355557,
            ic_city: "Pureza",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.821,
            ic_february: 5.996,
            ic_march: 5.984,
            ic_april: 5.493,
            ic_may: 5.039,
            ic_june: 4.653,
            ic_july: 4.735,
            ic_august: 5.456,
            ic_september: 5.933,
            ic_october: 6.054,
            ic_november: 6.217,
            ic_december: 5.888,
            ic_yearly: 5.606,
          },
          {
            ic_uuid: "822e00b1-f58e-45db-990a-260e656a0375",
            ic_lat: -29005,
            ic_lon: -521574,
            ic_city: "Putinga",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.087,
            ic_february: 5.652,
            ic_march: 4.789,
            ic_april: 3.842,
            ic_may: 2.857,
            ic_june: 2.406,
            ic_july: 2.709,
            ic_august: 3.437,
            ic_september: 3.683,
            ic_october: 4.718,
            ic_november: 6.063,
            ic_december: 6.443,
            ic_yearly: 4.39,
          },
          {
            ic_uuid: "36cfcaed-3930-48d9-85a7-15af22ccacaf",
            ic_lat: -71457,
            ic_lon: -359686,
            ic_city: "Puxinanã",
            ic_states: "PARAÍBA",
            ic_january: 5.813,
            ic_february: 5.928,
            ic_march: 5.877,
            ic_april: 5.374,
            ic_may: 4.781,
            ic_june: 4.224,
            ic_july: 4.383,
            ic_august: 5.136,
            ic_september: 5.683,
            ic_october: 5.952,
            ic_november: 6.133,
            ic_december: 5.958,
            ic_yearly: 5.437,
          },
          {
            ic_uuid: "2ea27756-9384-4834-b787-361862f593cf",
            ic_lat: -232998,
            ic_lon: -480551,
            ic_city: "Quadra",
            ic_states: "SÃO PAULO",
            ic_january: 5.529,
            ic_february: 5.78,
            ic_march: 5.054,
            ic_april: 4.53,
            ic_may: 3.631,
            ic_june: 3.399,
            ic_july: 3.502,
            ic_august: 4.511,
            ic_september: 4.678,
            ic_october: 5.262,
            ic_november: 5.721,
            ic_december: 6.095,
            ic_yearly: 4.808,
          },
          {
            ic_uuid: "c5c2153b-f5b4-4b64-9e63-5286875da057",
            ic_lat: -303845,
            ic_lon: -564487,
            ic_city: "Quaraí",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.843,
            ic_february: 6.178,
            ic_march: 5.294,
            ic_april: 4.101,
            ic_may: 2.943,
            ic_june: 2.425,
            ic_july: 2.751,
            ic_august: 3.508,
            ic_september: 4.223,
            ic_october: 5.433,
            ic_november: 6.582,
            ic_december: 7.179,
            ic_yearly: 4.788,
          },
          {
            ic_uuid: "e598c0d6-b89d-4a2a-908c-71dd2a755346",
            ic_lat: -192708,
            ic_lon: -455573,
            ic_city: "Quartel Geral",
            ic_states: "MINAS GERAIS",
            ic_january: 5.885,
            ic_february: 6.178,
            ic_march: 5.198,
            ic_april: 5.02,
            ic_may: 4.569,
            ic_june: 4.307,
            ic_july: 4.58,
            ic_august: 5.556,
            ic_september: 5.657,
            ic_october: 5.705,
            ic_november: 5.322,
            ic_december: 5.685,
            ic_yearly: 5.305,
          },
          {
            ic_uuid: "386d39f7-7e59-46e4-a681-02499c9884d9",
            ic_lat: -24278,
            ic_lon: -530763,
            ic_city: "Quarto Centenário",
            ic_states: "PARANÁ",
            ic_january: 6.239,
            ic_february: 5.73,
            ic_march: 5.233,
            ic_april: 4.449,
            ic_may: 3.495,
            ic_june: 3.07,
            ic_july: 3.274,
            ic_august: 4.238,
            ic_september: 4.505,
            ic_october: 5.317,
            ic_november: 6.082,
            ic_december: 6.507,
            ic_yearly: 4.845,
          },
          {
            ic_uuid: "0e6e03f9-9dcd-49d3-bc27-a135a42a5ba0",
            ic_lat: -22248,
            ic_lon: -506979,
            ic_city: "Quatá",
            ic_states: "SÃO PAULO",
            ic_january: 5.827,
            ic_february: 5.878,
            ic_march: 5.317,
            ic_april: 4.711,
            ic_may: 3.893,
            ic_june: 3.543,
            ic_july: 3.814,
            ic_august: 4.764,
            ic_september: 4.815,
            ic_october: 5.493,
            ic_november: 5.975,
            ic_december: 6.318,
            ic_yearly: 5.029,
          },
          {
            ic_uuid: "cb5dee04-7d8d-4080-b04d-db0a76c3c285",
            ic_lat: -235675,
            ic_lon: -499164,
            ic_city: "Quatiguá",
            ic_states: "PARANÁ",
            ic_january: 5.764,
            ic_february: 5.8,
            ic_march: 5.201,
            ic_april: 4.534,
            ic_may: 3.609,
            ic_june: 3.252,
            ic_july: 3.493,
            ic_august: 4.508,
            ic_september: 4.668,
            ic_october: 5.305,
            ic_november: 5.957,
            ic_december: 6.337,
            ic_yearly: 4.869,
          },
          {
            ic_uuid: "3330b566-f286-4d6c-b26e-de8b505138c8",
            ic_lat: -8948,
            ic_lon: -47005,
            ic_city: "Quatipuru",
            ic_states: "PARÁ",
            ic_january: 4.64,
            ic_february: 4.404,
            ic_march: 4.25,
            ic_april: 4.266,
            ic_may: 4.485,
            ic_june: 4.778,
            ic_july: 4.894,
            ic_august: 5.244,
            ic_september: 5.535,
            ic_october: 5.464,
            ic_november: 5.322,
            ic_december: 5.046,
            ic_yearly: 4.861,
          },
          {
            ic_uuid: "ec12c459-233c-4878-bcd4-239634579b8a",
            ic_lat: -22405,
            ic_lon: -442601,
            ic_city: "Quatis",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.5,
            ic_february: 5.799,
            ic_march: 4.826,
            ic_april: 4.326,
            ic_may: 3.614,
            ic_june: 3.424,
            ic_july: 3.453,
            ic_august: 4.396,
            ic_september: 4.551,
            ic_october: 4.869,
            ic_november: 4.892,
            ic_december: 5.544,
            ic_yearly: 4.6,
          },
          {
            ic_uuid: "e56b012f-fd86-45a1-b789-1de2b6742dd7",
            ic_lat: -253678,
            ic_lon: -490767,
            ic_city: "Quatro Barras",
            ic_states: "PARANÁ",
            ic_january: 5.319,
            ic_february: 5.141,
            ic_march: 4.487,
            ic_april: 3.734,
            ic_may: 3.038,
            ic_june: 2.711,
            ic_july: 2.877,
            ic_august: 3.798,
            ic_september: 3.785,
            ic_october: 4.358,
            ic_november: 5.188,
            ic_december: 5.547,
            ic_yearly: 4.165,
          },
          {
            ic_uuid: "09d2beb6-d2f4-4788-bf4a-6eda1acb8af0",
            ic_lat: -278262,
            ic_lon: -524429,
            ic_city: "Quatro Irmãos",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.252,
            ic_february: 5.819,
            ic_march: 4.991,
            ic_april: 4.066,
            ic_may: 3.072,
            ic_june: 2.587,
            ic_july: 2.913,
            ic_august: 3.711,
            ic_september: 3.925,
            ic_october: 5.082,
            ic_november: 6.146,
            ic_december: 6.541,
            ic_yearly: 4.592,
          },
          {
            ic_uuid: "813e384d-80f1-48fb-8a8c-8beee7e1c60a",
            ic_lat: -245756,
            ic_lon: -539763,
            ic_city: "Quatro Pontes",
            ic_states: "PARANÁ",
            ic_january: 6.19,
            ic_february: 5.64,
            ic_march: 5.28,
            ic_april: 4.32,
            ic_may: 3.4,
            ic_june: 2.988,
            ic_july: 3.214,
            ic_august: 4.187,
            ic_september: 4.446,
            ic_october: 5.297,
            ic_november: 6.143,
            ic_december: 6.529,
            ic_yearly: 4.803,
          },
          {
            ic_uuid: "3af245d6-138b-4a07-a06d-61f13aef7907",
            ic_lat: -93204,
            ic_lon: -364695,
            ic_city: "Quebrangulo",
            ic_states: "ALAGOAS",
            ic_january: 5.955,
            ic_february: 5.861,
            ic_march: 5.834,
            ic_april: 5.181,
            ic_may: 4.246,
            ic_june: 3.754,
            ic_july: 3.908,
            ic_august: 4.536,
            ic_september: 5.438,
            ic_october: 5.828,
            ic_november: 6.433,
            ic_december: 6.301,
            ic_yearly: 5.273,
          },
          {
            ic_uuid: "6fcd5926-d086-4c87-825f-620c0dc0e772",
            ic_lat: -254496,
            ic_lon: -529107,
            ic_city: "Quedas do Iguaçu",
            ic_states: "PARANÁ",
            ic_january: 6.266,
            ic_february: 5.665,
            ic_march: 5.211,
            ic_april: 4.3,
            ic_may: 3.307,
            ic_june: 2.897,
            ic_july: 3.126,
            ic_august: 4.137,
            ic_september: 4.312,
            ic_october: 5.221,
            ic_november: 6.095,
            ic_december: 6.329,
            ic_yearly: 4.739,
          },
          {
            ic_uuid: "1692abf1-95da-46e5-8349-b1103f4a4446",
            ic_lat: -85711,
            ic_lon: -414109,
            ic_city: "Queimada Nova",
            ic_states: "PIAUÍ",
            ic_january: 6.067,
            ic_february: 5.834,
            ic_march: 5.73,
            ic_april: 5.332,
            ic_may: 5.127,
            ic_june: 5.071,
            ic_july: 5.409,
            ic_august: 6.214,
            ic_september: 6.475,
            ic_october: 6.596,
            ic_november: 6.472,
            ic_december: 6.193,
            ic_yearly: 5.876,
          },
          {
            ic_uuid: "a5d2a3ef-fa53-4e6c-9e31-1a588c7b84ad",
            ic_lat: -10974,
            ic_lon: -396297,
            ic_city: "Queimadas",
            ic_states: "BAHIA",
            ic_january: 6.205,
            ic_february: 5.986,
            ic_march: 5.815,
            ic_april: 4.864,
            ic_may: 4.067,
            ic_june: 3.768,
            ic_july: 4.0,
            ic_august: 4.535,
            ic_september: 5.45,
            ic_october: 5.666,
            ic_november: 5.954,
            ic_december: 6.089,
            ic_yearly: 5.2,
          },
          {
            ic_uuid: "f5a7a57d-4dd7-4fdd-8126-442c50c7d6ec",
            ic_lat: -7367,
            ic_lon: -359036,
            ic_city: "Queimadas",
            ic_states: "PARAÍBA",
            ic_january: 5.827,
            ic_february: 5.815,
            ic_march: 5.846,
            ic_april: 5.332,
            ic_may: 4.665,
            ic_june: 4.095,
            ic_july: 4.308,
            ic_august: 4.994,
            ic_september: 5.603,
            ic_october: 5.917,
            ic_november: 6.203,
            ic_december: 5.978,
            ic_yearly: 5.382,
          },
          {
            ic_uuid: "67e17f7d-bef4-4459-a5d9-53c88d0e2840",
            ic_lat: -227107,
            ic_lon: -435522,
            ic_city: "Queimados",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.849,
            ic_february: 6.116,
            ic_march: 4.999,
            ic_april: 4.423,
            ic_may: 3.618,
            ic_june: 3.377,
            ic_july: 3.385,
            ic_august: 4.231,
            ic_september: 4.387,
            ic_october: 4.904,
            ic_november: 4.949,
            ic_december: 5.669,
            ic_yearly: 4.659,
          },
          {
            ic_uuid: "70949a4e-6009-41c4-a287-f51bf4184f20",
            ic_lat: -21799,
            ic_lon: -502399,
            ic_city: "Queiroz",
            ic_states: "SÃO PAULO",
            ic_january: 5.89,
            ic_february: 5.917,
            ic_march: 5.329,
            ic_april: 4.802,
            ic_may: 3.915,
            ic_june: 3.646,
            ic_july: 3.876,
            ic_august: 4.786,
            ic_september: 4.839,
            ic_october: 5.595,
            ic_november: 5.995,
            ic_december: 6.368,
            ic_yearly: 5.08,
          },
          {
            ic_uuid: "83693464-649a-46cd-beef-efe5442b2ef9",
            ic_lat: -225317,
            ic_lon: -447785,
            ic_city: "Queluz",
            ic_states: "SÃO PAULO",
            ic_january: 5.025,
            ic_february: 5.205,
            ic_march: 4.486,
            ic_april: 4.146,
            ic_may: 3.585,
            ic_june: 3.403,
            ic_july: 3.483,
            ic_august: 4.364,
            ic_september: 4.467,
            ic_october: 4.704,
            ic_november: 4.633,
            ic_december: 5.229,
            ic_yearly: 4.394,
          },
          {
            ic_uuid: "4d6839a2-a313-4cac-818c-35537486ddf5",
            ic_lat: -20742,
            ic_lon: -438855,
            ic_city: "Queluzito",
            ic_states: "MINAS GERAIS",
            ic_january: 5.662,
            ic_february: 5.939,
            ic_march: 4.959,
            ic_april: 4.523,
            ic_may: 3.923,
            ic_june: 3.746,
            ic_july: 3.945,
            ic_august: 4.903,
            ic_september: 5.094,
            ic_october: 5.282,
            ic_november: 5.032,
            ic_december: 5.574,
            ic_yearly: 4.882,
          },
          {
            ic_uuid: "2a3cbe94-c914-4a1e-8265-a9fc72bb9a6f",
            ic_lat: -125942,
            ic_lon: -522079,
            ic_city: "Querência",
            ic_states: "MATO GROSSO",
            ic_january: 5.179,
            ic_february: 5.384,
            ic_march: 4.945,
            ic_april: 4.954,
            ic_may: 4.908,
            ic_june: 4.719,
            ic_july: 5.169,
            ic_august: 5.454,
            ic_september: 5.273,
            ic_october: 5.295,
            ic_november: 5.143,
            ic_december: 5.255,
            ic_yearly: 5.14,
          },
          {
            ic_uuid: "4189a9f1-f94f-40a6-b0be-04cf34d4512d",
            ic_lat: -230842,
            ic_lon: -534835,
            ic_city: "Querência do Norte",
            ic_states: "PARANÁ",
            ic_january: 6.012,
            ic_february: 5.87,
            ic_march: 5.453,
            ic_april: 4.584,
            ic_may: 3.662,
            ic_june: 3.267,
            ic_july: 3.472,
            ic_august: 4.384,
            ic_september: 4.644,
            ic_october: 5.445,
            ic_november: 6.124,
            ic_december: 6.484,
            ic_yearly: 4.95,
          },
          {
            ic_uuid: "1f86eeb4-99d6-4f87-91c6-6d47c14b083d",
            ic_lat: -29353,
            ic_lon: -540719,
            ic_city: "Quevedos",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.409,
            ic_february: 5.943,
            ic_march: 5.043,
            ic_april: 3.935,
            ic_may: 2.917,
            ic_june: 2.426,
            ic_july: 2.71,
            ic_august: 3.452,
            ic_september: 3.898,
            ic_october: 4.985,
            ic_november: 6.271,
            ic_december: 6.752,
            ic_yearly: 4.562,
          },
          {
            ic_uuid: "a4183d28-458b-4882-8f83-291cdaff5be7",
            ic_lat: -107533,
            ic_lon: -392095,
            ic_city: "Quijingue",
            ic_states: "BAHIA",
            ic_january: 6.131,
            ic_february: 5.975,
            ic_march: 5.892,
            ic_april: 4.942,
            ic_may: 4.195,
            ic_june: 3.816,
            ic_july: 4.039,
            ic_august: 4.58,
            ic_september: 5.571,
            ic_october: 5.705,
            ic_november: 6.134,
            ic_december: 6.075,
            ic_yearly: 5.255,
          },
          {
            ic_uuid: "960001a6-2b09-46c7-b35a-3f427ddcac5c",
            ic_lat: -267269,
            ic_lon: -527244,
            ic_city: "Quilombo",
            ic_states: "SANTA CATARINA",
            ic_january: 6.126,
            ic_february: 5.611,
            ic_march: 5.098,
            ic_april: 4.101,
            ic_may: 3.166,
            ic_june: 2.691,
            ic_july: 2.987,
            ic_august: 3.878,
            ic_september: 4.107,
            ic_october: 5.094,
            ic_november: 6.1,
            ic_december: 6.34,
            ic_yearly: 4.608,
          },
          {
            ic_uuid: "22fd6446-c890-4842-907b-99c534c4bc30",
            ic_lat: -238537,
            ic_lon: -521313,
            ic_city: "Quinta do Sol",
            ic_states: "PARANÁ",
            ic_january: 6.091,
            ic_february: 5.782,
            ic_march: 5.287,
            ic_april: 4.578,
            ic_may: 3.606,
            ic_june: 3.221,
            ic_july: 3.427,
            ic_august: 4.344,
            ic_september: 4.596,
            ic_october: 5.265,
            ic_november: 6.146,
            ic_december: 6.47,
            ic_yearly: 4.901,
          },
          {
            ic_uuid: "13c98671-1a7c-431f-8f1e-a2f08b9851b0",
            ic_lat: -220696,
            ic_lon: -503074,
            ic_city: "Quintana",
            ic_states: "SÃO PAULO",
            ic_january: 5.84,
            ic_february: 5.883,
            ic_march: 5.323,
            ic_april: 4.754,
            ic_may: 3.883,
            ic_june: 3.601,
            ic_july: 3.815,
            ic_august: 4.791,
            ic_september: 4.788,
            ic_october: 5.506,
            ic_november: 5.978,
            ic_december: 6.264,
            ic_yearly: 5.035,
          },
          {
            ic_uuid: "c4f30575-6c1d-4df9-aa40-77ce929a4ee7",
            ic_lat: -28742,
            ic_lon: -530949,
            ic_city: "Quinze de Novembro",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.406,
            ic_february: 5.959,
            ic_march: 5.06,
            ic_april: 3.991,
            ic_may: 3.025,
            ic_june: 2.466,
            ic_july: 2.761,
            ic_august: 3.535,
            ic_september: 3.881,
            ic_october: 5.036,
            ic_november: 6.269,
            ic_december: 6.656,
            ic_yearly: 4.587,
          },
          {
            ic_uuid: "7af15424-217e-4726-b7ab-603c661914f6",
            ic_lat: -88122,
            ic_lon: -36014,
            ic_city: "Quipapá",
            ic_states: "PERNAMBUCO",
            ic_january: 5.717,
            ic_february: 5.618,
            ic_march: 5.657,
            ic_april: 4.981,
            ic_may: 4.133,
            ic_june: 3.716,
            ic_july: 3.736,
            ic_august: 4.344,
            ic_september: 5.223,
            ic_october: 5.58,
            ic_november: 6.042,
            ic_december: 6.063,
            ic_yearly: 5.067,
          },
          {
            ic_uuid: "8c520a2b-b0e0-41a2-8ada-3016adb94e2a",
            ic_lat: -184476,
            ic_lon: -504551,
            ic_city: "Quirinópolis",
            ic_states: "GOIÁS",
            ic_january: 5.614,
            ic_february: 5.667,
            ic_march: 5.248,
            ic_april: 5.072,
            ic_may: 4.562,
            ic_june: 4.313,
            ic_july: 4.478,
            ic_august: 5.442,
            ic_september: 5.289,
            ic_october: 5.56,
            ic_november: 5.689,
            ic_december: 5.798,
            ic_yearly: 5.228,
          },
          {
            ic_uuid: "9c33f34c-3d0d-4ea2-be40-8df84e902176",
            ic_lat: -221036,
            ic_lon: -414696,
            ic_city: "Quissamã",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.392,
            ic_february: 6.604,
            ic_march: 5.245,
            ic_april: 4.592,
            ic_may: 3.791,
            ic_june: 3.494,
            ic_july: 3.59,
            ic_august: 4.425,
            ic_september: 4.672,
            ic_october: 5.167,
            ic_november: 5.187,
            ic_december: 6.104,
            ic_yearly: 4.939,
          },
          {
            ic_uuid: "01b59024-a6df-426b-84d2-d62b8248fb8d",
            ic_lat: -258739,
            ic_lon: -494978,
            ic_city: "Quitandinha",
            ic_states: "PARANÁ",
            ic_january: 5.423,
            ic_february: 5.116,
            ic_march: 4.5,
            ic_april: 3.707,
            ic_may: 2.98,
            ic_june: 2.628,
            ic_july: 2.837,
            ic_august: 3.774,
            ic_september: 3.796,
            ic_october: 4.376,
            ic_november: 5.267,
            ic_december: 5.579,
            ic_yearly: 4.165,
          },
          {
            ic_uuid: "b5a74e52-e453-431f-b094-2721584d1fd8",
            ic_lat: -58429,
            ic_lon: -407005,
            ic_city: "Quiterianópolis",
            ic_states: "CEARÁ",
            ic_january: 5.086,
            ic_february: 5.157,
            ic_march: 5.239,
            ic_april: 4.918,
            ic_may: 4.885,
            ic_june: 4.99,
            ic_july: 5.371,
            ic_august: 6.204,
            ic_september: 6.52,
            ic_october: 6.376,
            ic_november: 6.173,
            ic_december: 5.561,
            ic_yearly: 5.54,
          },
          {
            ic_uuid: "ce78954b-db71-4517-9125-db0f33c718c9",
            ic_lat: -70309,
            ic_lon: -371484,
            ic_city: "Quixaba",
            ic_states: "PARAÍBA",
            ic_january: 6.223,
            ic_february: 6.205,
            ic_march: 6.233,
            ic_april: 5.857,
            ic_may: 5.148,
            ic_june: 4.698,
            ic_july: 5.027,
            ic_august: 5.772,
            ic_september: 6.389,
            ic_october: 6.545,
            ic_november: 6.571,
            ic_december: 6.29,
            ic_yearly: 5.913,
          },
          {
            ic_uuid: "60cfd328-aefa-4dff-878e-a42f68a802ae",
            ic_lat: -77189,
            ic_lon: -378464,
            ic_city: "Quixaba",
            ic_states: "PERNAMBUCO",
            ic_january: 6.19,
            ic_february: 6.146,
            ic_march: 6.16,
            ic_april: 5.78,
            ic_may: 5.035,
            ic_june: 4.593,
            ic_july: 4.852,
            ic_august: 5.692,
            ic_september: 6.428,
            ic_october: 6.543,
            ic_november: 6.713,
            ic_december: 6.37,
            ic_yearly: 5.875,
          },
          {
            ic_uuid: "0da9154e-5982-4b90-a5f9-7e3d1fe05c9e",
            ic_lat: -114108,
            ic_lon: -401262,
            ic_city: "Quixabeira",
            ic_states: "BAHIA",
            ic_january: 6.062,
            ic_february: 5.956,
            ic_march: 5.755,
            ic_april: 4.699,
            ic_may: 4.028,
            ic_june: 3.737,
            ic_july: 3.901,
            ic_august: 4.492,
            ic_september: 5.415,
            ic_october: 5.461,
            ic_november: 5.705,
            ic_december: 5.887,
            ic_yearly: 5.091,
          },
          {
            ic_uuid: "1a845409-2ec6-4f50-b74f-fc09eaf590a2",
            ic_lat: -49667,
            ic_lon: -390158,
            ic_city: "Quixadá",
            ic_states: "CEARÁ",
            ic_january: 5.494,
            ic_february: 5.551,
            ic_march: 5.61,
            ic_april: 5.152,
            ic_may: 5.122,
            ic_june: 4.95,
            ic_july: 5.316,
            ic_august: 6.056,
            ic_september: 6.517,
            ic_october: 6.436,
            ic_november: 6.277,
            ic_december: 5.752,
            ic_yearly: 5.686,
          },
          {
            ic_uuid: "1499bd25-e764-405c-8c04-3cfcab63ea1b",
            ic_lat: -62468,
            ic_lon: -392014,
            ic_city: "Quixelô",
            ic_states: "CEARÁ",
            ic_january: 5.746,
            ic_february: 5.89,
            ic_march: 5.867,
            ic_april: 5.472,
            ic_may: 5.248,
            ic_june: 5.12,
            ic_july: 5.433,
            ic_august: 6.177,
            ic_september: 6.417,
            ic_october: 6.462,
            ic_november: 6.494,
            ic_december: 6.01,
            ic_yearly: 5.861,
          },
          {
            ic_uuid: "61d1cea0-1a4c-47f3-85c5-56e24630207d",
            ic_lat: -51911,
            ic_lon: -392893,
            ic_city: "Quixeramobim",
            ic_states: "CEARÁ",
            ic_january: 5.468,
            ic_february: 5.496,
            ic_march: 5.64,
            ic_april: 5.167,
            ic_may: 5.103,
            ic_june: 4.911,
            ic_july: 5.28,
            ic_august: 6.03,
            ic_september: 6.451,
            ic_october: 6.456,
            ic_november: 6.368,
            ic_december: 5.82,
            ic_yearly: 5.683,
          },
          {
            ic_uuid: "bd581447-d5ac-4b62-8c36-d0acf8162bce",
            ic_lat: -50719,
            ic_lon: -379805,
            ic_city: "Quixeré",
            ic_states: "CEARÁ",
            ic_january: 5.683,
            ic_february: 5.831,
            ic_march: 5.799,
            ic_april: 5.464,
            ic_may: 5.276,
            ic_june: 5.069,
            ic_july: 5.36,
            ic_august: 5.989,
            ic_september: 6.341,
            ic_october: 6.502,
            ic_november: 6.404,
            ic_december: 5.919,
            ic_yearly: 5.803,
          },
          {
            ic_uuid: "c9c88b17-5da2-4757-85a9-06c325864f55",
            ic_lat: -61903,
            ic_lon: -382214,
            ic_city: "Rafael Fernandes",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.936,
            ic_february: 6.026,
            ic_march: 6.02,
            ic_april: 5.699,
            ic_may: 5.285,
            ic_june: 4.992,
            ic_july: 5.324,
            ic_august: 6.047,
            ic_september: 6.445,
            ic_october: 6.572,
            ic_november: 6.616,
            ic_december: 6.156,
            ic_yearly: 5.926,
          },
          {
            ic_uuid: "ec4a39e3-c79e-4280-b8df-1f72685da3f0",
            ic_lat: -60729,
            ic_lon: -377163,
            ic_city: "Rafael Godeiro",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.147,
            ic_february: 6.065,
            ic_march: 6.124,
            ic_april: 5.882,
            ic_may: 5.406,
            ic_june: 5.07,
            ic_july: 5.368,
            ic_august: 6.057,
            ic_september: 6.503,
            ic_october: 6.627,
            ic_november: 6.651,
            ic_december: 6.163,
            ic_yearly: 6.005,
          },
          {
            ic_uuid: "03b09617-0403-48c8-b647-d95f545e50b3",
            ic_lat: -124057,
            ic_lon: -39501,
            ic_city: "Rafael Jambeiro",
            ic_states: "BAHIA",
            ic_january: 5.997,
            ic_february: 5.832,
            ic_march: 5.689,
            ic_april: 4.646,
            ic_may: 3.981,
            ic_june: 3.626,
            ic_july: 3.864,
            ic_august: 4.448,
            ic_september: 5.192,
            ic_october: 5.338,
            ic_november: 5.606,
            ic_december: 5.923,
            ic_yearly: 5.012,
          },
          {
            ic_uuid: "3ec885d4-e601-4537-bdca-0cfd961e1b11",
            ic_lat: -23011,
            ic_lon: -475322,
            ic_city: "Rafard",
            ic_states: "SÃO PAULO",
            ic_january: 5.63,
            ic_february: 5.868,
            ic_march: 5.116,
            ic_april: 4.593,
            ic_may: 3.771,
            ic_june: 3.483,
            ic_july: 3.623,
            ic_august: 4.586,
            ic_september: 4.755,
            ic_october: 5.419,
            ic_november: 5.716,
            ic_december: 6.135,
            ic_yearly: 4.891,
          },
          {
            ic_uuid: "c3d17312-216e-4d2f-89fd-5872f579ea3a",
            ic_lat: -251199,
            ic_lon: -540235,
            ic_city: "Ramilândia",
            ic_states: "PARANÁ",
            ic_january: 6.237,
            ic_february: 5.805,
            ic_march: 5.261,
            ic_april: 4.259,
            ic_may: 3.297,
            ic_june: 2.918,
            ic_july: 3.143,
            ic_august: 4.15,
            ic_september: 4.383,
            ic_october: 5.289,
            ic_november: 6.113,
            ic_december: 6.488,
            ic_yearly: 4.778,
          },
          {
            ic_uuid: "4c3918a1-b769-4a81-af92-9d29472e7e99",
            ic_lat: -222274,
            ic_lon: -508935,
            ic_city: "Rancharia",
            ic_states: "SÃO PAULO",
            ic_january: 5.909,
            ic_february: 5.904,
            ic_march: 5.36,
            ic_april: 4.744,
            ic_may: 3.885,
            ic_june: 3.592,
            ic_july: 3.789,
            ic_august: 4.728,
            ic_september: 4.831,
            ic_october: 5.507,
            ic_november: 5.999,
            ic_december: 6.352,
            ic_yearly: 5.05,
          },
          {
            ic_uuid: "d60fdf3f-f144-425a-8c94-706ae36dc6ac",
            ic_lat: -230681,
            ic_lon: -509149,
            ic_city: "Rancho Alegre",
            ic_states: "PARANÁ",
            ic_january: 5.989,
            ic_february: 5.898,
            ic_march: 5.315,
            ic_april: 4.644,
            ic_may: 3.734,
            ic_june: 3.398,
            ic_july: 3.596,
            ic_august: 4.532,
            ic_september: 4.676,
            ic_october: 5.393,
            ic_november: 6.016,
            ic_december: 6.403,
            ic_yearly: 4.966,
          },
          {
            ic_uuid: "bde47db5-f250-4ef0-bd7b-c8e33da0a6c5",
            ic_lat: -24304,
            ic_lon: -529503,
            ic_city: "Rancho Alegre D'Oeste",
            ic_states: "PARANÁ",
            ic_january: 6.202,
            ic_february: 5.742,
            ic_march: 5.233,
            ic_april: 4.47,
            ic_may: 3.499,
            ic_june: 3.096,
            ic_july: 3.303,
            ic_august: 4.248,
            ic_september: 4.512,
            ic_october: 5.319,
            ic_november: 6.101,
            ic_december: 6.475,
            ic_yearly: 4.85,
          },
          {
            ic_uuid: "850557ea-b017-4ed1-b82c-75ae60e2c31d",
            ic_lat: -276732,
            ic_lon: -490196,
            ic_city: "Rancho Queimado",
            ic_states: "SANTA CATARINA",
            ic_january: 5.33,
            ic_february: 5.046,
            ic_march: 4.378,
            ic_april: 3.644,
            ic_may: 2.929,
            ic_june: 2.54,
            ic_july: 2.665,
            ic_august: 3.396,
            ic_september: 3.438,
            ic_october: 3.996,
            ic_november: 5.188,
            ic_december: 5.535,
            ic_yearly: 4.007,
          },
          {
            ic_uuid: "ab6fadf2-0731-4501-a77e-deb51ade8402",
            ic_lat: -24427,
            ic_lon: -441032,
            ic_city: "Raposa",
            ic_states: "MARANHÃO",
            ic_january: 5.24,
            ic_february: 5.144,
            ic_march: 4.957,
            ic_april: 4.759,
            ic_may: 4.885,
            ic_june: 5.114,
            ic_july: 5.31,
            ic_august: 5.708,
            ic_september: 5.953,
            ic_october: 5.937,
            ic_november: 5.898,
            ic_december: 5.716,
            ic_yearly: 5.385,
          },
          {
            ic_uuid: "131149a6-683a-4966-b673-70a805fc08ea",
            ic_lat: -19967,
            ic_lon: -43804,
            ic_city: "Raposos",
            ic_states: "MINAS GERAIS",
            ic_january: 5.644,
            ic_february: 5.905,
            ic_march: 5.056,
            ic_april: 4.691,
            ic_may: 4.088,
            ic_june: 4.011,
            ic_july: 4.234,
            ic_august: 5.081,
            ic_september: 5.357,
            ic_october: 5.465,
            ic_november: 5.122,
            ic_december: 5.478,
            ic_yearly: 5.011,
          },
          {
            ic_uuid: "1f856bc8-dff5-4ba2-acf5-c80b4143dfc6",
            ic_lat: -201066,
            ic_lon: -424506,
            ic_city: "Raul Soares",
            ic_states: "MINAS GERAIS",
            ic_january: 5.945,
            ic_february: 6.07,
            ic_march: 5.134,
            ic_april: 4.688,
            ic_may: 3.981,
            ic_june: 3.751,
            ic_july: 3.888,
            ic_august: 4.645,
            ic_september: 4.962,
            ic_october: 5.152,
            ic_november: 5.031,
            ic_december: 5.773,
            ic_yearly: 4.918,
          },
          {
            ic_uuid: "f445caff-c698-45b7-8831-2f15c7c6e1e6",
            ic_lat: -257716,
            ic_lon: -535265,
            ic_city: "Realeza",
            ic_states: "PARANÁ",
            ic_january: 6.418,
            ic_february: 5.882,
            ic_march: 5.307,
            ic_april: 4.246,
            ic_may: 3.316,
            ic_june: 2.85,
            ic_july: 3.103,
            ic_august: 4.071,
            ic_september: 4.263,
            ic_october: 5.292,
            ic_november: 6.176,
            ic_december: 6.51,
            ic_yearly: 4.786,
          },
          {
            ic_uuid: "c80f31e0-473a-45b1-a560-f84f5d7609f5",
            ic_lat: -256237,
            ic_lon: -506882,
            ic_city: "Rebouças",
            ic_states: "PARANÁ",
            ic_january: 5.502,
            ic_february: 5.165,
            ic_march: 4.546,
            ic_april: 3.743,
            ic_may: 3.021,
            ic_june: 2.603,
            ic_july: 2.895,
            ic_august: 3.893,
            ic_september: 3.97,
            ic_october: 4.566,
            ic_november: 5.506,
            ic_december: 5.629,
            ic_yearly: 4.253,
          },
          {
            ic_uuid: "ea3d4925-9bdb-41bf-89d7-7663bc397344",
            ic_lat: -80475,
            ic_lon: -348776,
            ic_city: "Recife",
            ic_states: "PERNAMBUCO",
            ic_january: 5.886,
            ic_february: 6.014,
            ic_march: 5.93,
            ic_april: 5.251,
            ic_may: 4.538,
            ic_june: 4.246,
            ic_july: 4.397,
            ic_august: 5.169,
            ic_september: 5.659,
            ic_october: 6.044,
            ic_november: 6.199,
            ic_december: 6.204,
            ic_yearly: 5.462,
          },
          {
            ic_uuid: "919c578a-a149-4216-9113-3967f13de613",
            ic_lat: -215294,
            ic_lon: -42468,
            ic_city: "Recreio",
            ic_states: "MINAS GERAIS",
            ic_january: 5.983,
            ic_february: 6.214,
            ic_march: 5.196,
            ic_april: 4.443,
            ic_may: 3.723,
            ic_june: 3.504,
            ic_july: 3.641,
            ic_august: 4.403,
            ic_september: 4.793,
            ic_october: 5.137,
            ic_november: 5.035,
            ic_december: 5.926,
            ic_yearly: 4.833,
          },
          {
            ic_uuid: "cdb14ff8-0515-4d2b-8a0c-bd44fe9cdaf6",
            ic_lat: -87367,
            ic_lon: -472426,
            ic_city: "Recursolândia",
            ic_states: "TOCANTINS",
            ic_january: 5.004,
            ic_february: 4.978,
            ic_march: 4.804,
            ic_april: 4.917,
            ic_may: 5.238,
            ic_june: 5.241,
            ic_july: 5.374,
            ic_august: 6.118,
            ic_september: 6.051,
            ic_october: 5.402,
            ic_november: 4.997,
            ic_december: 4.962,
            ic_yearly: 5.257,
          },
          {
            ic_uuid: "e5fc2bb5-5a1f-45ca-bd70-d2c810134491",
            ic_lat: -42163,
            ic_lon: -38728,
            ic_city: "Redenção",
            ic_states: "CEARÁ",
            ic_january: 5.127,
            ic_february: 5.185,
            ic_march: 5.124,
            ic_april: 4.695,
            ic_may: 4.962,
            ic_june: 4.871,
            ic_july: 5.321,
            ic_august: 6.002,
            ic_september: 6.264,
            ic_october: 6.066,
            ic_november: 5.955,
            ic_december: 5.346,
            ic_yearly: 5.41,
          },
          {
            ic_uuid: "82d09ff2-33b6-4413-8ab9-06b970894758",
            ic_lat: -80257,
            ic_lon: -500321,
            ic_city: "Redenção",
            ic_states: "PARÁ",
            ic_january: 4.851,
            ic_february: 4.9,
            ic_march: 4.722,
            ic_april: 4.689,
            ic_may: 4.789,
            ic_june: 4.99,
            ic_july: 4.975,
            ic_august: 5.698,
            ic_september: 5.434,
            ic_october: 5.054,
            ic_november: 4.905,
            ic_december: 4.764,
            ic_yearly: 4.981,
          },
          {
            ic_uuid: "382cbe4f-b23e-4850-8634-ee1724b7eebb",
            ic_lat: -232642,
            ic_lon: -455426,
            ic_city: "Redenção da Serra",
            ic_states: "SÃO PAULO",
            ic_january: 5.609,
            ic_february: 5.838,
            ic_march: 4.859,
            ic_april: 4.362,
            ic_may: 3.534,
            ic_june: 3.278,
            ic_july: 3.395,
            ic_august: 4.32,
            ic_september: 4.387,
            ic_october: 4.999,
            ic_november: 5.226,
            ic_december: 5.842,
            ic_yearly: 4.637,
          },
          {
            ic_uuid: "3238d805-3351-4348-924b-d9d964168eda",
            ic_lat: -94851,
            ic_lon: -445798,
            ic_city: "Redenção do Gurguéia",
            ic_states: "PIAUÍ",
            ic_january: 5.793,
            ic_february: 5.563,
            ic_march: 5.47,
            ic_april: 5.4,
            ic_may: 5.412,
            ic_june: 5.315,
            ic_july: 5.601,
            ic_august: 6.213,
            ic_september: 6.479,
            ic_october: 6.157,
            ic_november: 5.753,
            ic_december: 5.755,
            ic_yearly: 5.743,
          },
          {
            ic_uuid: "7998700d-cd3e-4428-90fd-bc4f2c9f2a24",
            ic_lat: -276644,
            ic_lon: -536411,
            ic_city: "Redentora",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.434,
            ic_february: 5.947,
            ic_march: 5.068,
            ic_april: 4.055,
            ic_may: 3.114,
            ic_june: 2.587,
            ic_july: 2.917,
            ic_august: 3.688,
            ic_september: 3.988,
            ic_october: 5.149,
            ic_november: 6.2,
            ic_december: 6.646,
            ic_yearly: 4.649,
          },
          {
            ic_uuid: "77465eb6-b925-4394-9c4c-08a0b2a49309",
            ic_lat: -202406,
            ic_lon: -419852,
            ic_city: "Reduto",
            ic_states: "MINAS GERAIS",
            ic_january: 5.652,
            ic_february: 5.872,
            ic_march: 4.901,
            ic_april: 4.462,
            ic_may: 3.809,
            ic_june: 3.595,
            ic_july: 3.78,
            ic_august: 4.451,
            ic_september: 4.754,
            ic_october: 4.875,
            ic_november: 4.696,
            ic_december: 5.349,
            ic_yearly: 4.683,
          },
          {
            ic_uuid: "801874f2-e5b1-48a6-8628-48eccdec3fd7",
            ic_lat: -62316,
            ic_lon: -426845,
            ic_city: "Regeneração",
            ic_states: "PIAUÍ",
            ic_january: 5.015,
            ic_february: 5.18,
            ic_march: 5.184,
            ic_april: 5.075,
            ic_may: 5.191,
            ic_june: 5.288,
            ic_july: 5.63,
            ic_august: 6.269,
            ic_september: 6.549,
            ic_october: 6.273,
            ic_november: 5.922,
            ic_december: 5.47,
            ic_yearly: 5.587,
          },
          {
            ic_uuid: "d53fee36-44b5-411d-9051-793f99eec1e6",
            ic_lat: -222186,
            ic_lon: -513059,
            ic_city: "Regente Feijó",
            ic_states: "SÃO PAULO",
            ic_january: 5.966,
            ic_february: 5.976,
            ic_march: 5.416,
            ic_april: 4.779,
            ic_may: 3.888,
            ic_june: 3.581,
            ic_july: 3.793,
            ic_august: 4.763,
            ic_september: 4.876,
            ic_october: 5.513,
            ic_november: 6.118,
            ic_december: 6.436,
            ic_yearly: 5.092,
          },
          {
            ic_uuid: "d611be7c-1ece-472e-ae1a-dff160fde6c5",
            ic_lat: -21888,
            ic_lon: -492249,
            ic_city: "Reginópolis",
            ic_states: "SÃO PAULO",
            ic_january: 5.825,
            ic_february: 5.953,
            ic_march: 5.325,
            ic_april: 4.752,
            ic_may: 3.935,
            ic_june: 3.67,
            ic_july: 3.912,
            ic_august: 4.803,
            ic_september: 4.89,
            ic_october: 5.642,
            ic_november: 5.949,
            ic_december: 6.278,
            ic_yearly: 5.078,
          },
          {
            ic_uuid: "cd900757-db71-40cd-b256-667badbe5d4f",
            ic_lat: -244984,
            ic_lon: -478453,
            ic_city: "Registro",
            ic_states: "SÃO PAULO",
            ic_january: 5.365,
            ic_february: 5.361,
            ic_march: 4.452,
            ic_april: 3.854,
            ic_may: 3.056,
            ic_june: 2.622,
            ic_july: 2.624,
            ic_august: 3.28,
            ic_september: 3.44,
            ic_october: 3.882,
            ic_november: 4.79,
            ic_december: 5.457,
            ic_yearly: 4.015,
          },
          {
            ic_uuid: "a01731fa-a66a-4e4a-bb20-de8b7ba694bb",
            ic_lat: -291164,
            ic_lon: -520796,
            ic_city: "Relvado",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.076,
            ic_february: 5.674,
            ic_march: 4.76,
            ic_april: 3.787,
            ic_may: 2.827,
            ic_june: 2.359,
            ic_july: 2.652,
            ic_august: 3.389,
            ic_september: 3.648,
            ic_october: 4.659,
            ic_november: 6.028,
            ic_december: 6.394,
            ic_yearly: 4.354,
          },
          {
            ic_uuid: "0a0d56eb-cb12-4075-a81a-77fbea242923",
            ic_lat: -96199,
            ic_lon: -420852,
            ic_city: "Remanso",
            ic_states: "BAHIA",
            ic_january: 6.471,
            ic_february: 6.186,
            ic_march: 6.108,
            ic_april: 5.639,
            ic_may: 5.379,
            ic_june: 5.202,
            ic_july: 5.383,
            ic_august: 5.656,
            ic_september: 5.941,
            ic_october: 6.512,
            ic_november: 6.554,
            ic_december: 6.37,
            ic_yearly: 5.95,
          },
          {
            ic_uuid: "9136658e-29f7-47cf-bee8-5c58859851aa",
            ic_lat: -69554,
            ic_lon: -357989,
            ic_city: "Remígio",
            ic_states: "PARAÍBA",
            ic_january: 5.721,
            ic_february: 5.854,
            ic_march: 5.797,
            ic_april: 5.339,
            ic_may: 4.702,
            ic_june: 4.152,
            ic_july: 4.297,
            ic_august: 5.027,
            ic_september: 5.591,
            ic_october: 5.865,
            ic_november: 6.153,
            ic_december: 5.956,
            ic_yearly: 5.371,
          },
          {
            ic_uuid: "3c1dd630-47a3-40a8-9d4c-3cf743af44e1",
            ic_lat: -261593,
            ic_lon: -529708,
            ic_city: "Renascença",
            ic_states: "PARANÁ",
            ic_january: 6.193,
            ic_february: 5.681,
            ic_march: 5.172,
            ic_april: 4.207,
            ic_may: 3.27,
            ic_june: 2.824,
            ic_july: 3.089,
            ic_august: 4.049,
            ic_september: 4.242,
            ic_october: 5.193,
            ic_november: 6.095,
            ic_december: 6.326,
            ic_yearly: 4.695,
          },
          {
            ic_uuid: "c105eb7a-4e83-43cf-994e-5c56244e743c",
            ic_lat: -41423,
            ic_lon: -405762,
            ic_city: "Reriutaba",
            ic_states: "CEARÁ",
            ic_january: 5.147,
            ic_february: 5.384,
            ic_march: 5.33,
            ic_april: 4.989,
            ic_may: 5.034,
            ic_june: 5.043,
            ic_july: 5.299,
            ic_august: 6.054,
            ic_september: 6.503,
            ic_october: 6.379,
            ic_november: 6.054,
            ic_december: 5.431,
            ic_yearly: 5.554,
          },
          {
            ic_uuid: "4c69c409-a874-4a19-b512-f18c3a83c509",
            ic_lat: -22471,
            ic_lon: -444513,
            ic_city: "Resende",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.386,
            ic_february: 5.66,
            ic_march: 4.784,
            ic_april: 4.32,
            ic_may: 3.592,
            ic_june: 3.371,
            ic_july: 3.43,
            ic_august: 4.367,
            ic_september: 4.489,
            ic_october: 4.816,
            ic_november: 4.868,
            ic_december: 5.505,
            ic_yearly: 4.549,
          },
          {
            ic_uuid: "30bd6f28-c94b-4ec6-9fc9-1b8f4835fd2d",
            ic_lat: -209175,
            ic_lon: -442411,
            ic_city: "Resende Costa",
            ic_states: "MINAS GERAIS",
            ic_january: 5.576,
            ic_february: 5.816,
            ic_march: 4.85,
            ic_april: 4.469,
            ic_may: 3.855,
            ic_june: 3.728,
            ic_july: 3.963,
            ic_august: 4.923,
            ic_september: 5.091,
            ic_october: 5.256,
            ic_november: 4.971,
            ic_december: 5.511,
            ic_yearly: 4.834,
          },
          {
            ic_uuid: "f48ea70c-4e7e-4d2b-9647-ea9ec1e3fb26",
            ic_lat: -246496,
            ic_lon: -50847,
            ic_city: "Reserva",
            ic_states: "PARANÁ",
            ic_january: 5.726,
            ic_february: 5.542,
            ic_march: 5.05,
            ic_april: 4.312,
            ic_may: 3.378,
            ic_june: 3.01,
            ic_july: 3.275,
            ic_august: 4.271,
            ic_september: 4.444,
            ic_october: 5.077,
            ic_november: 5.871,
            ic_december: 6.071,
            ic_yearly: 4.669,
          },
          {
            ic_uuid: "39ea8019-d00c-41d2-9a90-d376caea3276",
            ic_lat: -151209,
            ic_lon: -583821,
            ic_city: "Reserva do Cabaçal",
            ic_states: "MATO GROSSO",
            ic_january: 4.882,
            ic_february: 4.768,
            ic_march: 4.608,
            ic_april: 4.532,
            ic_may: 4.066,
            ic_june: 4.241,
            ic_july: 4.36,
            ic_august: 5.179,
            ic_september: 4.882,
            ic_october: 4.957,
            ic_november: 5.143,
            ic_december: 5.099,
            ic_yearly: 4.726,
          },
          {
            ic_uuid: "81993ef7-873f-48d9-a60c-98b187961817",
            ic_lat: -258324,
            ic_lon: -520277,
            ic_city: "Reserva do Iguaçu",
            ic_states: "PARANÁ",
            ic_january: 6.163,
            ic_february: 5.595,
            ic_march: 5.2,
            ic_april: 4.234,
            ic_may: 3.279,
            ic_june: 2.869,
            ic_july: 3.099,
            ic_august: 4.108,
            ic_september: 4.257,
            ic_october: 5.195,
            ic_november: 6.025,
            ic_december: 6.277,
            ic_yearly: 4.692,
          },
          {
            ic_uuid: "af48a207-e886-4c8b-b2a7-df305afc3497",
            ic_lat: -193199,
            ic_lon: -412466,
            ic_city: "Resplendor",
            ic_states: "MINAS GERAIS",
            ic_january: 6.124,
            ic_february: 6.285,
            ic_march: 5.368,
            ic_april: 4.669,
            ic_may: 3.936,
            ic_june: 3.658,
            ic_july: 3.751,
            ic_august: 4.369,
            ic_september: 4.842,
            ic_october: 5.011,
            ic_november: 4.974,
            ic_december: 5.822,
            ic_yearly: 4.901,
          },
          {
            ic_uuid: "55e136be-a63a-4673-b8ad-e7b6b4ff8e48",
            ic_lat: -210647,
            ic_lon: -437602,
            ic_city: "Ressaquinha",
            ic_states: "MINAS GERAIS",
            ic_january: 5.538,
            ic_february: 5.824,
            ic_march: 4.82,
            ic_april: 4.337,
            ic_may: 3.76,
            ic_june: 3.592,
            ic_july: 3.817,
            ic_august: 4.719,
            ic_september: 4.893,
            ic_october: 5.145,
            ic_november: 4.865,
            ic_december: 5.523,
            ic_yearly: 4.736,
          },
          {
            ic_uuid: "6d5880dc-2815-43f7-a31d-2e25c202a416",
            ic_lat: -206061,
            ic_lon: -474837,
            ic_city: "Restinga",
            ic_states: "SÃO PAULO",
            ic_january: 5.537,
            ic_february: 5.759,
            ic_march: 4.995,
            ic_april: 4.833,
            ic_may: 4.241,
            ic_june: 4.046,
            ic_july: 4.263,
            ic_august: 5.222,
            ic_september: 5.14,
            ic_october: 5.474,
            ic_november: 5.552,
            ic_december: 5.76,
            ic_yearly: 5.068,
          },
          {
            ic_uuid: "34d34038-4417-452e-bda0-85178b7df9e8",
            ic_lat: -29813,
            ic_lon: -533749,
            ic_city: "Restinga Sêca",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.499,
            ic_february: 5.954,
            ic_march: 5.023,
            ic_april: 3.856,
            ic_may: 2.822,
            ic_june: 2.328,
            ic_july: 2.563,
            ic_august: 3.279,
            ic_september: 3.756,
            ic_october: 4.921,
            ic_november: 6.3,
            ic_december: 6.823,
            ic_yearly: 4.51,
          },
          {
            ic_uuid: "e437e362-be4d-4269-8b79-6812ded95613",
            ic_lat: -114935,
            ic_lon: -394283,
            ic_city: "Retirolândia",
            ic_states: "BAHIA",
            ic_january: 6.096,
            ic_february: 5.892,
            ic_march: 5.833,
            ic_april: 4.814,
            ic_may: 4.046,
            ic_june: 3.757,
            ic_july: 3.993,
            ic_august: 4.499,
            ic_september: 5.401,
            ic_october: 5.58,
            ic_november: 5.856,
            ic_december: 5.973,
            ic_yearly: 5.145,
          },
          {
            ic_uuid: "73759e7d-4c3e-4916-a44f-735f9a72f0da",
            ic_lat: -73586,
            ic_lon: -466229,
            ic_city: "Riachão",
            ic_states: "MARANHÃO",
            ic_january: 4.775,
            ic_february: 4.882,
            ic_march: 4.818,
            ic_april: 4.997,
            ic_may: 5.191,
            ic_june: 5.305,
            ic_july: 5.263,
            ic_august: 5.887,
            ic_september: 6.2,
            ic_october: 5.465,
            ic_november: 4.912,
            ic_december: 4.802,
            ic_yearly: 5.208,
          },
          {
            ic_uuid: "21a09c5a-73fd-4053-96ea-e9b0c62efb2c",
            ic_lat: -65431,
            ic_lon: -356613,
            ic_city: "Riachão",
            ic_states: "PARAÍBA",
            ic_january: 5.607,
            ic_february: 5.729,
            ic_march: 5.789,
            ic_april: 5.368,
            ic_may: 4.838,
            ic_june: 4.378,
            ic_july: 4.537,
            ic_august: 5.224,
            ic_september: 5.592,
            ic_october: 5.75,
            ic_november: 5.978,
            ic_december: 5.681,
            ic_yearly: 5.373,
          },
          {
            ic_uuid: "7fd25ef5-3822-45a1-aa02-01e0bc90fa77",
            ic_lat: -11749,
            ic_lon: -449122,
            ic_city: "Riachão das Neves",
            ic_states: "BAHIA",
            ic_january: 6.25,
            ic_february: 6.02,
            ic_march: 5.678,
            ic_april: 5.506,
            ic_may: 5.355,
            ic_june: 5.274,
            ic_july: 5.524,
            ic_august: 6.068,
            ic_september: 6.312,
            ic_october: 6.188,
            ic_november: 5.854,
            ic_december: 6.143,
            ic_yearly: 5.848,
          },
          {
            ic_uuid: "f229763d-d433-40c8-8e86-282b4da0164f",
            ic_lat: -72508,
            ic_lon: -356652,
            ic_city: "Riachão do Bacamarte",
            ic_states: "PARAÍBA",
            ic_january: 5.599,
            ic_february: 5.68,
            ic_march: 5.757,
            ic_april: 5.244,
            ic_may: 4.643,
            ic_june: 4.136,
            ic_july: 4.228,
            ic_august: 4.888,
            ic_september: 5.437,
            ic_october: 5.72,
            ic_november: 6.053,
            ic_december: 5.824,
            ic_yearly: 5.267,
          },
          {
            ic_uuid: "211c67e0-b90e-4b82-9ea9-699816b3a08a",
            ic_lat: -110696,
            ic_lon: -377256,
            ic_city: "Riachão do Dantas",
            ic_states: "SERGIPE",
            ic_january: 6.047,
            ic_february: 5.972,
            ic_march: 5.826,
            ic_april: 4.925,
            ic_may: 4.156,
            ic_june: 3.882,
            ic_july: 4.022,
            ic_august: 4.493,
            ic_september: 5.262,
            ic_october: 5.579,
            ic_november: 6.047,
            ic_december: 6.147,
            ic_yearly: 5.196,
          },
          {
            ic_uuid: "c91f92b5-5d18-4d2b-9f43-d7c57781e0b9",
            ic_lat: -118071,
            ic_lon: -393821,
            ic_city: "Riachão do Jacuípe",
            ic_states: "BAHIA",
            ic_january: 6.183,
            ic_february: 5.946,
            ic_march: 5.778,
            ic_april: 4.772,
            ic_may: 3.987,
            ic_june: 3.699,
            ic_july: 3.931,
            ic_august: 4.446,
            ic_september: 5.33,
            ic_october: 5.541,
            ic_november: 5.82,
            ic_december: 5.967,
            ic_yearly: 5.117,
          },
          {
            ic_uuid: "ce1ddab9-c308-4b31-84b6-59c782eca99f",
            ic_lat: -71787,
            ic_lon: -352792,
            ic_city: "Riachão do Poço",
            ic_states: "PARAÍBA",
            ic_january: 5.652,
            ic_february: 5.782,
            ic_march: 5.891,
            ic_april: 5.305,
            ic_may: 4.632,
            ic_june: 4.245,
            ic_july: 4.339,
            ic_august: 5.116,
            ic_september: 5.542,
            ic_october: 5.763,
            ic_november: 5.985,
            ic_december: 5.881,
            ic_yearly: 5.344,
          },
          {
            ic_uuid: "a5bcc905-1fe9-410b-ba3e-70afc9e58157",
            ic_lat: -162203,
            ic_lon: -459993,
            ic_city: "Riachinho",
            ic_states: "MINAS GERAIS",
            ic_january: 6.237,
            ic_february: 6.446,
            ic_march: 5.569,
            ic_april: 5.558,
            ic_may: 5.041,
            ic_june: 4.757,
            ic_july: 5.098,
            ic_august: 5.906,
            ic_september: 5.975,
            ic_october: 6.043,
            ic_november: 5.413,
            ic_december: 6.003,
            ic_yearly: 5.671,
          },
          {
            ic_uuid: "1f40f0d6-dec4-4063-8682-377459b94b3f",
            ic_lat: -64405,
            ic_lon: -481374,
            ic_city: "Riachinho",
            ic_states: "TOCANTINS",
            ic_january: 4.659,
            ic_february: 4.789,
            ic_march: 4.717,
            ic_april: 4.732,
            ic_may: 4.88,
            ic_june: 5.209,
            ic_july: 5.318,
            ic_august: 5.807,
            ic_september: 5.631,
            ic_october: 5.027,
            ic_november: 4.676,
            ic_december: 4.567,
            ic_yearly: 5.001,
          },
          {
            ic_uuid: "5174dda4-4e1e-4bda-b08d-1c2dd41c8cf6",
            ic_lat: -5927,
            ic_lon: -379494,
            ic_city: "Riacho da Cruz",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.021,
            ic_february: 6.104,
            ic_march: 6.106,
            ic_april: 5.807,
            ic_may: 5.407,
            ic_june: 5.077,
            ic_july: 5.41,
            ic_august: 6.085,
            ic_september: 6.458,
            ic_october: 6.658,
            ic_november: 6.676,
            ic_december: 6.148,
            ic_yearly: 5.996,
          },
          {
            ic_uuid: "73ec73cc-0d49-4a60-8377-a372d05297ac",
            ic_lat: -81378,
            ic_lon: -358651,
            ic_city: "Riacho das Almas",
            ic_states: "PERNAMBUCO",
            ic_january: 5.755,
            ic_february: 5.755,
            ic_march: 5.739,
            ic_april: 5.125,
            ic_may: 4.467,
            ic_june: 4.028,
            ic_july: 4.182,
            ic_august: 4.766,
            ic_september: 5.455,
            ic_october: 5.755,
            ic_november: 6.074,
            ic_december: 5.943,
            ic_yearly: 5.254,
          },
          {
            ic_uuid: "a70f14c4-d818-4faa-bb04-0b41487e419a",
            ic_lat: -13613,
            ic_lon: -429364,
            ic_city: "Riacho de Santana",
            ic_states: "BAHIA",
            ic_january: 6.531,
            ic_february: 6.553,
            ic_march: 5.981,
            ic_april: 5.514,
            ic_may: 5.002,
            ic_june: 4.785,
            ic_july: 5.074,
            ic_august: 5.701,
            ic_september: 6.186,
            ic_october: 6.261,
            ic_november: 5.775,
            ic_december: 6.435,
            ic_yearly: 5.816,
          },
          {
            ic_uuid: "be3c2711-992b-443c-ae56-909c9e4d2775",
            ic_lat: -62518,
            ic_lon: -383119,
            ic_city: "Riacho de Santana",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.96,
            ic_february: 5.934,
            ic_march: 5.955,
            ic_april: 5.615,
            ic_may: 5.208,
            ic_june: 4.953,
            ic_july: 5.347,
            ic_august: 6.048,
            ic_september: 6.471,
            ic_october: 6.552,
            ic_november: 6.64,
            ic_december: 6.16,
            ic_yearly: 5.904,
          },
          {
            ic_uuid: "b26e42be-4305-4cfa-812e-bf87037bb174",
            ic_lat: -7681,
            ic_lon: -361577,
            ic_city: "Riacho de Santo Antônio",
            ic_states: "PARAÍBA",
            ic_january: 5.886,
            ic_february: 5.858,
            ic_march: 5.856,
            ic_april: 5.351,
            ic_may: 4.703,
            ic_june: 4.176,
            ic_july: 4.381,
            ic_august: 5.048,
            ic_september: 5.71,
            ic_october: 6.004,
            ic_november: 6.26,
            ic_december: 5.986,
            ic_yearly: 5.435,
          },
          {
            ic_uuid: "71ca3773-91f8-415c-a060-e921a4b25a82",
            ic_lat: -64411,
            ic_lon: -376486,
            ic_city: "Riacho dos Cavalos",
            ic_states: "PARAÍBA",
            ic_january: 6.13,
            ic_february: 6.185,
            ic_march: 6.173,
            ic_april: 5.878,
            ic_may: 5.326,
            ic_june: 4.904,
            ic_july: 5.26,
            ic_august: 5.961,
            ic_september: 6.504,
            ic_october: 6.613,
            ic_november: 6.622,
            ic_december: 6.266,
            ic_yearly: 5.985,
          },
          {
            ic_uuid: "64cccfa6-97cc-4718-84b9-86dd1b8decb9",
            ic_lat: -160096,
            ic_lon: -430492,
            ic_city: "Riacho dos Machados",
            ic_states: "MINAS GERAIS",
            ic_january: 6.482,
            ic_february: 6.589,
            ic_march: 5.689,
            ic_april: 5.281,
            ic_may: 4.728,
            ic_june: 4.44,
            ic_july: 4.754,
            ic_august: 5.517,
            ic_september: 6.039,
            ic_october: 6.095,
            ic_november: 5.588,
            ic_december: 6.234,
            ic_yearly: 5.62,
          },
          {
            ic_uuid: "5f8b5203-aca1-41b4-b529-210a945acfc4",
            ic_lat: -101293,
            ic_lon: -449542,
            ic_city: "Riacho Frio",
            ic_states: "PIAUÍ",
            ic_january: 5.894,
            ic_february: 5.77,
            ic_march: 5.532,
            ic_april: 5.446,
            ic_may: 5.449,
            ic_june: 5.32,
            ic_july: 5.506,
            ic_august: 6.16,
            ic_september: 6.413,
            ic_october: 6.197,
            ic_november: 5.845,
            ic_december: 5.905,
            ic_yearly: 5.787,
          },
          {
            ic_uuid: "af948558-2f30-489d-9fba-744aba971d98",
            ic_lat: -58141,
            ic_lon: -35824,
            ic_city: "Riachuelo",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.713,
            ic_february: 5.714,
            ic_march: 5.761,
            ic_april: 5.398,
            ic_may: 4.969,
            ic_june: 4.533,
            ic_july: 4.791,
            ic_august: 5.357,
            ic_september: 5.762,
            ic_october: 5.959,
            ic_november: 6.071,
            ic_december: 5.739,
            ic_yearly: 5.481,
          },
          {
            ic_uuid: "98011bcf-1b42-4108-a5aa-eed6231ea75c",
            ic_lat: -107314,
            ic_lon: -371871,
            ic_city: "Riachuelo",
            ic_states: "SERGIPE",
            ic_january: 6.16,
            ic_february: 6.086,
            ic_march: 5.954,
            ic_april: 5.046,
            ic_may: 4.319,
            ic_june: 4.007,
            ic_july: 4.127,
            ic_august: 4.645,
            ic_september: 5.406,
            ic_october: 5.772,
            ic_november: 6.251,
            ic_december: 6.247,
            ic_yearly: 5.335,
          },
          {
            ic_uuid: "8fff0383-ac07-4a12-bb20-01a3e033e855",
            ic_lat: -153149,
            ic_lon: -495818,
            ic_city: "Rialma",
            ic_states: "GOIÁS",
            ic_january: 5.359,
            ic_february: 5.636,
            ic_march: 5.21,
            ic_april: 5.196,
            ic_may: 4.929,
            ic_june: 4.637,
            ic_july: 4.82,
            ic_august: 5.769,
            ic_september: 5.547,
            ic_october: 5.565,
            ic_november: 5.219,
            ic_december: 5.373,
            ic_yearly: 5.272,
          },
          {
            ic_uuid: "d0f7c795-3e4b-4dcc-a050-5384f159c57a",
            ic_lat: -154491,
            ic_lon: -495087,
            ic_city: "Rianápolis",
            ic_states: "GOIÁS",
            ic_january: 5.386,
            ic_february: 5.639,
            ic_march: 5.23,
            ic_april: 5.125,
            ic_may: 4.922,
            ic_june: 4.664,
            ic_july: 4.787,
            ic_august: 5.765,
            ic_september: 5.515,
            ic_october: 5.558,
            ic_november: 5.25,
            ic_december: 5.381,
            ic_yearly: 5.268,
          },
          {
            ic_uuid: "5627f38e-0b9a-4bcb-80ed-f49a75f8c602",
            ic_lat: -59311,
            ic_lon: -473891,
            ic_city: "Ribamar Fiquene",
            ic_states: "MARANHÃO",
            ic_january: 4.661,
            ic_february: 4.734,
            ic_march: 4.767,
            ic_april: 4.793,
            ic_may: 4.853,
            ic_june: 5.148,
            ic_july: 5.363,
            ic_august: 5.849,
            ic_september: 5.668,
            ic_october: 5.266,
            ic_november: 4.931,
            ic_december: 4.792,
            ic_yearly: 5.069,
          },
          {
            ic_uuid: "bc30da2c-3708-46fe-a36a-0b6d516f17a0",
            ic_lat: -20445,
            ic_lon: -537593,
            ic_city: "Ribas do Rio Pardo",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.709,
            ic_february: 5.806,
            ic_march: 5.377,
            ic_april: 4.816,
            ic_may: 4.016,
            ic_june: 3.735,
            ic_july: 3.93,
            ic_august: 4.819,
            ic_september: 4.943,
            ic_october: 5.425,
            ic_november: 5.815,
            ic_december: 6.138,
            ic_yearly: 5.044,
          },
          {
            ic_uuid: "ec4df72b-c596-4c5c-adeb-a71ee2ff1061",
            ic_lat: -246523,
            ic_lon: -49005,
            ic_city: "Ribeira",
            ic_states: "SÃO PAULO",
            ic_january: 5.423,
            ic_february: 5.358,
            ic_march: 4.668,
            ic_april: 3.897,
            ic_may: 3.096,
            ic_june: 2.68,
            ic_july: 2.764,
            ic_august: 3.728,
            ic_september: 3.866,
            ic_october: 4.44,
            ic_november: 5.268,
            ic_december: 5.707,
            ic_yearly: 4.241,
          },
          {
            ic_uuid: "e9171b08-075f-40bf-b118-705032d24614",
            ic_lat: -110425,
            ic_lon: -384245,
            ic_city: "Ribeira do Amparo",
            ic_states: "BAHIA",
            ic_january: 6.105,
            ic_february: 5.831,
            ic_march: 5.803,
            ic_april: 4.911,
            ic_may: 4.135,
            ic_june: 3.81,
            ic_july: 3.943,
            ic_august: 4.441,
            ic_september: 5.218,
            ic_october: 5.517,
            ic_november: 5.984,
            ic_december: 6.052,
            ic_yearly: 5.146,
          },
          {
            ic_uuid: "43e8b55f-f0ed-4dc2-b7d3-5409a0a8b226",
            ic_lat: -76907,
            ic_lon: -427131,
            ic_city: "Ribeira do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.497,
            ic_february: 5.428,
            ic_march: 5.415,
            ic_april: 5.267,
            ic_may: 5.329,
            ic_june: 5.325,
            ic_july: 5.58,
            ic_august: 6.326,
            ic_september: 6.685,
            ic_october: 6.47,
            ic_november: 6.036,
            ic_december: 5.794,
            ic_yearly: 5.763,
          },
          {
            ic_uuid: "e4d9d14a-bf83-4153-88bf-6c63995ac1fd",
            ic_lat: -108377,
            ic_lon: -385385,
            ic_city: "Ribeira do Pombal",
            ic_states: "BAHIA",
            ic_january: 6.11,
            ic_february: 5.909,
            ic_march: 5.868,
            ic_april: 5.034,
            ic_may: 4.208,
            ic_june: 3.848,
            ic_july: 4.02,
            ic_august: 4.495,
            ic_september: 5.352,
            ic_october: 5.598,
            ic_november: 6.076,
            ic_december: 6.11,
            ic_yearly: 5.219,
          },
          {
            ic_uuid: "1b3d3fea-d33b-4b28-84e2-e3d6216e70f1",
            ic_lat: -851,
            ic_lon: -353701,
            ic_city: "Ribeirão",
            ic_states: "PERNAMBUCO",
            ic_january: 5.666,
            ic_february: 5.675,
            ic_march: 5.698,
            ic_april: 5.0,
            ic_may: 4.301,
            ic_june: 3.932,
            ic_july: 4.018,
            ic_august: 4.623,
            ic_september: 5.341,
            ic_october: 5.659,
            ic_november: 5.984,
            ic_december: 6.001,
            ic_yearly: 5.158,
          },
          {
            ic_uuid: "9662cc09-0c6c-41af-bda4-73ad4504cbc0",
            ic_lat: -220677,
            ic_lon: -481793,
            ic_city: "Ribeirão Bonito",
            ic_states: "SÃO PAULO",
            ic_january: 5.552,
            ic_february: 5.867,
            ic_march: 5.169,
            ic_april: 4.712,
            ic_may: 3.928,
            ic_june: 3.674,
            ic_july: 3.866,
            ic_august: 4.79,
            ic_september: 4.902,
            ic_october: 5.531,
            ic_november: 5.728,
            ic_december: 6.066,
            ic_yearly: 4.982,
          },
          {
            ic_uuid: "2e74ad60-92b8-42e3-9362-3fb140983f14",
            ic_lat: -242211,
            ic_lon: -48764,
            ic_city: "Ribeirão Branco",
            ic_states: "SÃO PAULO",
            ic_january: 5.467,
            ic_february: 5.461,
            ic_march: 4.728,
            ic_april: 4.126,
            ic_may: 3.319,
            ic_june: 3.017,
            ic_july: 3.192,
            ic_august: 4.147,
            ic_september: 4.201,
            ic_october: 4.691,
            ic_november: 5.378,
            ic_december: 5.88,
            ic_yearly: 4.467,
          },
          {
            ic_uuid: "0995cdd3-cd7b-4cc9-8e57-541d0446a896",
            ic_lat: -129372,
            ic_lon: -518248,
            ic_city: "Ribeirão Cascalheira",
            ic_states: "MATO GROSSO",
            ic_january: 5.117,
            ic_february: 5.309,
            ic_march: 5.065,
            ic_april: 5.051,
            ic_may: 4.945,
            ic_june: 4.839,
            ic_july: 5.221,
            ic_august: 5.685,
            ic_september: 5.412,
            ic_october: 5.289,
            ic_november: 5.138,
            ic_december: 5.255,
            ic_yearly: 5.194,
          },
          {
            ic_uuid: "97acc0bd-82d4-429c-83d6-0123e210cdd7",
            ic_lat: -23194,
            ic_lon: -497579,
            ic_city: "Ribeirão Claro",
            ic_states: "PARANÁ",
            ic_january: 5.715,
            ic_february: 5.837,
            ic_march: 5.284,
            ic_april: 4.665,
            ic_may: 3.681,
            ic_june: 3.355,
            ic_july: 3.573,
            ic_august: 4.565,
            ic_september: 4.693,
            ic_october: 5.405,
            ic_november: 5.998,
            ic_december: 6.351,
            ic_yearly: 4.927,
          },
          {
            ic_uuid: "4f78604c-94d2-4a3e-9fb3-942310e78d2c",
            ic_lat: -204583,
            ic_lon: -475908,
            ic_city: "Ribeirão Corrente",
            ic_states: "SÃO PAULO",
            ic_january: 5.576,
            ic_february: 5.803,
            ic_march: 5.005,
            ic_april: 4.865,
            ic_may: 4.267,
            ic_june: 4.079,
            ic_july: 4.3,
            ic_august: 5.252,
            ic_september: 5.148,
            ic_october: 5.477,
            ic_november: 5.637,
            ic_december: 5.865,
            ic_yearly: 5.106,
          },
          {
            ic_uuid: "f20e3dda-67bd-49a8-ad1a-eaee6ef1c8e6",
            ic_lat: -197625,
            ic_lon: -440848,
            ic_city: "Ribeirão das Neves",
            ic_states: "MINAS GERAIS",
            ic_january: 5.825,
            ic_february: 6.044,
            ic_march: 5.185,
            ic_april: 4.835,
            ic_may: 4.245,
            ic_june: 4.051,
            ic_july: 4.319,
            ic_august: 5.169,
            ic_september: 5.537,
            ic_october: 5.63,
            ic_november: 5.353,
            ic_december: 5.608,
            ic_yearly: 5.15,
          },
          {
            ic_uuid: "db12e7d0-5ac7-4ce9-afee-19f1280470d5",
            ic_lat: -154512,
            ic_lon: -407445,
            ic_city: "Ribeirão do Largo",
            ic_states: "BAHIA",
            ic_january: 5.999,
            ic_february: 6.013,
            ic_march: 5.363,
            ic_april: 4.623,
            ic_may: 3.913,
            ic_june: 3.529,
            ic_july: 3.742,
            ic_august: 4.282,
            ic_september: 5.13,
            ic_october: 5.317,
            ic_november: 5.217,
            ic_december: 6.015,
            ic_yearly: 4.929,
          },
          {
            ic_uuid: "fe2ed7a4-6ecf-49a8-856a-7daf234f78dc",
            ic_lat: -23408,
            ic_lon: -503569,
            ic_city: "Ribeirão do Pinhal",
            ic_states: "PARANÁ",
            ic_january: 5.822,
            ic_february: 5.79,
            ic_march: 5.177,
            ic_april: 4.606,
            ic_may: 3.684,
            ic_june: 3.333,
            ic_july: 3.55,
            ic_august: 4.547,
            ic_september: 4.696,
            ic_october: 5.346,
            ic_november: 5.961,
            ic_december: 6.339,
            ic_yearly: 4.904,
          },
          {
            ic_uuid: "51fdae13-b83f-4a9f-a398-58a67795d70f",
            ic_lat: -22784,
            ic_lon: -499339,
            ic_city: "Ribeirão do Sul",
            ic_states: "SÃO PAULO",
            ic_january: 5.667,
            ic_february: 5.787,
            ic_march: 5.12,
            ic_april: 4.672,
            ic_may: 3.763,
            ic_june: 3.457,
            ic_july: 3.678,
            ic_august: 4.609,
            ic_september: 4.719,
            ic_october: 5.406,
            ic_november: 5.881,
            ic_december: 6.32,
            ic_yearly: 4.923,
          },
          {
            ic_uuid: "ba541047-be73-4e42-9005-2258397a3697",
            ic_lat: -218369,
            ic_lon: -51604,
            ic_city: "Ribeirão dos Índios",
            ic_states: "SÃO PAULO",
            ic_january: 5.966,
            ic_february: 6.005,
            ic_march: 5.433,
            ic_april: 4.816,
            ic_may: 3.982,
            ic_june: 3.633,
            ic_july: 3.834,
            ic_august: 4.747,
            ic_september: 4.843,
            ic_october: 5.58,
            ic_november: 6.104,
            ic_december: 6.456,
            ic_yearly: 5.117,
          },
          {
            ic_uuid: "86ba4418-0da6-40fe-93bc-92a9b6892733",
            ic_lat: -24099,
            ic_lon: -483649,
            ic_city: "Ribeirão Grande",
            ic_states: "SÃO PAULO",
            ic_january: 5.479,
            ic_february: 5.458,
            ic_march: 4.731,
            ic_april: 4.133,
            ic_may: 3.315,
            ic_june: 3.024,
            ic_july: 3.16,
            ic_august: 4.088,
            ic_september: 4.15,
            ic_october: 4.663,
            ic_november: 5.338,
            ic_december: 5.852,
            ic_yearly: 4.449,
          },
          {
            ic_uuid: "7673e64e-abc9-4da9-a536-a736a6afc83e",
            ic_lat: -237071,
            ic_lon: -464062,
            ic_city: "Ribeirão Pires",
            ic_states: "SÃO PAULO",
            ic_january: 5.03,
            ic_february: 5.397,
            ic_march: 4.549,
            ic_april: 4.026,
            ic_may: 3.303,
            ic_june: 3.03,
            ic_july: 3.144,
            ic_august: 3.969,
            ic_september: 4.013,
            ic_october: 4.453,
            ic_november: 4.928,
            ic_december: 5.506,
            ic_yearly: 4.279,
          },
          {
            ic_uuid: "70778e39-2191-44d4-a626-12a9677cfbc9",
            ic_lat: -211704,
            ic_lon: -478103,
            ic_city: "Ribeirão Preto",
            ic_states: "SÃO PAULO",
            ic_january: 5.639,
            ic_february: 5.928,
            ic_march: 5.166,
            ic_april: 4.8,
            ic_may: 4.105,
            ic_june: 3.916,
            ic_july: 4.12,
            ic_august: 4.929,
            ic_september: 4.999,
            ic_october: 5.516,
            ic_november: 5.74,
            ic_december: 6.078,
            ic_yearly: 5.078,
          },
          {
            ic_uuid: "1255dd26-9cf3-4111-aba1-0e64ce8311b4",
            ic_lat: -211883,
            ic_lon: -450641,
            ic_city: "Ribeirão Vermelho",
            ic_states: "MINAS GERAIS",
            ic_january: 5.698,
            ic_february: 5.853,
            ic_march: 5.028,
            ic_april: 4.716,
            ic_may: 4.018,
            ic_june: 3.799,
            ic_july: 4.062,
            ic_august: 4.967,
            ic_september: 5.185,
            ic_october: 5.441,
            ic_november: 5.249,
            ic_december: 5.708,
            ic_yearly: 4.977,
          },
          {
            ic_uuid: "06dcfe07-6afc-48b2-a0ea-c95c03e72dcb",
            ic_lat: -164861,
            ic_lon: -526929,
            ic_city: "Ribeirãozinho",
            ic_states: "MATO GROSSO",
            ic_january: 5.618,
            ic_february: 5.669,
            ic_march: 5.263,
            ic_april: 5.264,
            ic_may: 4.82,
            ic_june: 4.505,
            ic_july: 4.549,
            ic_august: 5.367,
            ic_september: 5.377,
            ic_october: 5.39,
            ic_november: 5.609,
            ic_december: 5.712,
            ic_yearly: 5.262,
          },
          {
            ic_uuid: "804f9103-1ed6-4148-8e6e-1e09bf46d912",
            ic_lat: -75569,
            ic_lon: -452451,
            ic_city: "Ribeiro Gonçalves",
            ic_states: "PIAUÍ",
            ic_january: 4.969,
            ic_february: 5.188,
            ic_march: 4.995,
            ic_april: 5.19,
            ic_may: 5.284,
            ic_june: 5.372,
            ic_july: 5.528,
            ic_august: 6.075,
            ic_september: 6.535,
            ic_october: 5.927,
            ic_november: 5.327,
            ic_december: 5.218,
            ic_yearly: 5.467,
          },
          {
            ic_uuid: "cb34033a-a284-4775-89cb-7f3e6f376312",
            ic_lat: -105361,
            ic_lon: -374383,
            ic_city: "Ribeirópolis",
            ic_states: "SERGIPE",
            ic_january: 6.073,
            ic_february: 5.935,
            ic_march: 5.752,
            ic_april: 5.036,
            ic_may: 4.24,
            ic_june: 3.958,
            ic_july: 4.064,
            ic_august: 4.668,
            ic_september: 5.4,
            ic_october: 5.687,
            ic_november: 6.156,
            ic_december: 6.192,
            ic_yearly: 5.263,
          },
          {
            ic_uuid: "e386fb09-5ce5-4708-99bc-afd1e6eb7e3d",
            ic_lat: -200807,
            ic_lon: -474295,
            ic_city: "Rifaina",
            ic_states: "SÃO PAULO",
            ic_january: 5.671,
            ic_february: 5.988,
            ic_march: 5.143,
            ic_april: 4.995,
            ic_may: 4.363,
            ic_june: 4.147,
            ic_july: 4.401,
            ic_august: 5.281,
            ic_september: 5.185,
            ic_october: 5.634,
            ic_november: 5.613,
            ic_december: 5.873,
            ic_yearly: 5.191,
          },
          {
            ic_uuid: "d016792c-e25a-4b33-9e99-aef0dbd7ecf1",
            ic_lat: -215899,
            ic_lon: -480732,
            ic_city: "Rincão",
            ic_states: "SÃO PAULO",
            ic_january: 5.637,
            ic_february: 5.958,
            ic_march: 5.175,
            ic_april: 4.784,
            ic_may: 4.062,
            ic_june: 3.791,
            ic_july: 3.994,
            ic_august: 4.873,
            ic_september: 4.947,
            ic_october: 5.526,
            ic_november: 5.762,
            ic_december: 6.109,
            ic_yearly: 5.051,
          },
          {
            ic_uuid: "34e716d8-5339-461c-aedc-6ea46958b578",
            ic_lat: -217289,
            ic_lon: -507243,
            ic_city: "Rinópolis",
            ic_states: "SÃO PAULO",
            ic_january: 5.873,
            ic_february: 5.887,
            ic_march: 5.38,
            ic_april: 4.81,
            ic_may: 3.978,
            ic_june: 3.677,
            ic_july: 3.888,
            ic_august: 4.818,
            ic_september: 4.811,
            ic_october: 5.538,
            ic_november: 6.052,
            ic_december: 6.301,
            ic_yearly: 5.084,
          },
          {
            ic_uuid: "6cee8830-f24f-4dba-8817-ac3c740677ea",
            ic_lat: -20088,
            ic_lon: -437882,
            ic_city: "Rio Acima",
            ic_states: "MINAS GERAIS",
            ic_january: 5.572,
            ic_february: 5.917,
            ic_march: 5.084,
            ic_april: 4.656,
            ic_may: 4.124,
            ic_june: 4.011,
            ic_july: 4.175,
            ic_august: 5.07,
            ic_september: 5.347,
            ic_october: 5.435,
            ic_november: 5.101,
            ic_december: 5.477,
            ic_yearly: 4.997,
          },
          {
            ic_uuid: "9b2aa00f-0d8d-4b5d-8f37-e91d4e7de99b",
            ic_lat: -257311,
            ic_lon: -50799,
            ic_city: "Rio Azul",
            ic_states: "PARANÁ",
            ic_january: 5.447,
            ic_february: 5.163,
            ic_march: 4.517,
            ic_april: 3.677,
            ic_may: 2.956,
            ic_june: 2.569,
            ic_july: 2.851,
            ic_august: 3.836,
            ic_september: 3.92,
            ic_october: 4.481,
            ic_november: 5.428,
            ic_december: 5.586,
            ic_yearly: 4.203,
          },
          {
            ic_uuid: "9eb64c77-9a28-49a4-a110-eafe94f7a8ce",
            ic_lat: -192724,
            ic_lon: -403369,
            ic_city: "Rio Bananal",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.907,
            ic_february: 6.023,
            ic_march: 5.218,
            ic_april: 4.411,
            ic_may: 3.834,
            ic_june: 3.552,
            ic_july: 3.622,
            ic_august: 4.134,
            ic_september: 4.661,
            ic_october: 4.763,
            ic_november: 4.778,
            ic_december: 5.693,
            ic_yearly: 4.716,
          },
          {
            ic_uuid: "20287358-3e29-4e2d-821b-7b34b55545a0",
            ic_lat: -237611,
            ic_lon: -514127,
            ic_city: "Rio Bom",
            ic_states: "PARANÁ",
            ic_january: 5.835,
            ic_february: 5.71,
            ic_march: 5.277,
            ic_april: 4.567,
            ic_may: 3.563,
            ic_june: 3.248,
            ic_july: 3.454,
            ic_august: 4.445,
            ic_september: 4.609,
            ic_october: 5.264,
            ic_november: 5.99,
            ic_december: 6.275,
            ic_yearly: 4.853,
          },
          {
            ic_uuid: "43e867d9-b161-44c1-b259-5fa7c985d112",
            ic_lat: -227186,
            ic_lon: -426279,
            ic_city: "Rio Bonito",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.013,
            ic_february: 6.286,
            ic_march: 4.996,
            ic_april: 4.417,
            ic_may: 3.635,
            ic_june: 3.441,
            ic_july: 3.443,
            ic_august: 4.253,
            ic_september: 4.458,
            ic_october: 4.964,
            ic_november: 4.988,
            ic_december: 5.75,
            ic_yearly: 4.721,
          },
          {
            ic_uuid: "66a6df7a-16eb-43dc-9537-7eef0fb16f09",
            ic_lat: -254879,
            ic_lon: -525296,
            ic_city: "Rio Bonito do Iguaçu",
            ic_states: "PARANÁ",
            ic_january: 6.1,
            ic_february: 5.603,
            ic_march: 5.183,
            ic_april: 4.272,
            ic_may: 3.296,
            ic_june: 2.903,
            ic_july: 3.141,
            ic_august: 4.108,
            ic_september: 4.302,
            ic_october: 5.176,
            ic_november: 6.072,
            ic_december: 6.226,
            ic_yearly: 4.699,
          },
          {
            ic_uuid: "bbefac21-b950-41aa-ba11-8418c7d790ed",
            ic_lat: -99758,
            ic_lon: -678253,
            ic_city: "Rio Branco",
            ic_states: "ACRE",
            ic_january: 4.47,
            ic_february: 4.608,
            ic_march: 4.216,
            ic_april: 4.414,
            ic_may: 3.946,
            ic_june: 4.088,
            ic_july: 4.263,
            ic_august: 4.866,
            ic_september: 5.104,
            ic_october: 5.105,
            ic_november: 5.035,
            ic_december: 4.606,
            ic_yearly: 4.56,
          },
          {
            ic_uuid: "616d07ec-dbc4-4141-953e-1fc649b20267",
            ic_lat: -152471,
            ic_lon: -581267,
            ic_city: "Rio Branco",
            ic_states: "MATO GROSSO",
            ic_january: 5.01,
            ic_february: 4.894,
            ic_march: 4.724,
            ic_april: 4.64,
            ic_may: 4.131,
            ic_june: 4.261,
            ic_july: 4.374,
            ic_august: 5.164,
            ic_september: 4.925,
            ic_october: 5.001,
            ic_november: 5.221,
            ic_december: 5.118,
            ic_yearly: 4.789,
          },
          {
            ic_uuid: "9d3853ff-4a48-4a6a-a72a-02eeb4cce5c5",
            ic_lat: -243249,
            ic_lon: -513191,
            ic_city: "Rio Branco do Ivaí",
            ic_states: "PARANÁ",
            ic_january: 5.956,
            ic_february: 5.71,
            ic_march: 5.224,
            ic_april: 4.513,
            ic_may: 3.513,
            ic_june: 3.097,
            ic_july: 3.359,
            ic_august: 4.361,
            ic_september: 4.542,
            ic_october: 5.255,
            ic_november: 6.079,
            ic_december: 6.329,
            ic_yearly: 4.828,
          },
          {
            ic_uuid: "3943f49d-0247-408b-9aa2-c9cb6a1dd434",
            ic_lat: -251897,
            ic_lon: -49312,
            ic_city: "Rio Branco do Sul",
            ic_states: "PARANÁ",
            ic_january: 5.381,
            ic_february: 5.246,
            ic_march: 4.649,
            ic_april: 3.855,
            ic_may: 3.07,
            ic_june: 2.723,
            ic_july: 2.937,
            ic_august: 3.921,
            ic_september: 3.934,
            ic_october: 4.464,
            ic_november: 5.328,
            ic_december: 5.607,
            ic_yearly: 4.26,
          },
          {
            ic_uuid: "78ef426f-fe40-43ab-b275-38cacba62d40",
            ic_lat: -218037,
            ic_lon: -545432,
            ic_city: "Rio Brilhante",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.931,
            ic_february: 5.744,
            ic_march: 5.331,
            ic_april: 4.606,
            ic_may: 3.77,
            ic_june: 3.445,
            ic_july: 3.581,
            ic_august: 4.528,
            ic_september: 4.754,
            ic_october: 5.372,
            ic_november: 5.957,
            ic_december: 6.39,
            ic_yearly: 4.951,
          },
          {
            ic_uuid: "02305030-2e94-418c-b1f3-bb96c4381b8e",
            ic_lat: -20229,
            ic_lon: -426466,
            ic_city: "Rio Casca",
            ic_states: "MINAS GERAIS",
            ic_january: 5.814,
            ic_february: 6.091,
            ic_march: 5.097,
            ic_april: 4.598,
            ic_may: 3.927,
            ic_june: 3.713,
            ic_july: 3.923,
            ic_august: 4.66,
            ic_september: 4.973,
            ic_october: 5.162,
            ic_november: 4.945,
            ic_december: 5.682,
            ic_yearly: 4.882,
          },
          {
            ic_uuid: "4500cea5-2b7e-4be0-809b-c76aef57db7e",
            ic_lat: -227205,
            ic_lon: -441423,
            ic_city: "Rio Claro",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.589,
            ic_february: 6.0,
            ic_march: 4.845,
            ic_april: 4.275,
            ic_may: 3.552,
            ic_june: 3.335,
            ic_july: 3.403,
            ic_august: 4.259,
            ic_september: 4.463,
            ic_october: 4.913,
            ic_november: 4.955,
            ic_december: 5.704,
            ic_yearly: 4.608,
          },
          {
            ic_uuid: "ba1c6b93-6110-4454-a6ae-1a82ed8fdc86",
            ic_lat: -223989,
            ic_lon: -47555,
            ic_city: "Rio Claro",
            ic_states: "SÃO PAULO",
            ic_january: 5.56,
            ic_february: 5.71,
            ic_march: 5.09,
            ic_april: 4.653,
            ic_may: 3.836,
            ic_june: 3.596,
            ic_july: 3.755,
            ic_august: 4.708,
            ic_september: 4.838,
            ic_october: 5.466,
            ic_november: 5.632,
            ic_december: 5.982,
            ic_yearly: 4.902,
          },
          {
            ic_uuid: "4d604290-7cab-40c5-840a-e4525c7227a3",
            ic_lat: -97049,
            ic_lon: -628985,
            ic_city: "Rio Crespo",
            ic_states: "RONDÔNIA",
            ic_january: 4.211,
            ic_february: 4.351,
            ic_march: 4.277,
            ic_april: 4.372,
            ic_may: 4.045,
            ic_june: 4.368,
            ic_july: 4.459,
            ic_august: 4.835,
            ic_september: 4.844,
            ic_october: 4.987,
            ic_november: 4.802,
            ic_december: 4.596,
            ic_yearly: 4.512,
          },
          {
            ic_uuid: "4e5a8394-f3ff-4f78-bc21-87258b582e29",
            ic_lat: -113969,
            ic_lon: -468843,
            ic_city: "Rio da Conceição",
            ic_states: "TOCANTINS",
            ic_january: 5.451,
            ic_february: 5.26,
            ic_march: 4.98,
            ic_april: 4.981,
            ic_may: 5.087,
            ic_june: 5.111,
            ic_july: 5.363,
            ic_august: 6.033,
            ic_september: 6.103,
            ic_october: 5.564,
            ic_november: 5.242,
            ic_december: 5.306,
            ic_yearly: 5.373,
          },
          {
            ic_uuid: "f8ac5612-1f3b-4411-bd2d-f731dacd625e",
            ic_lat: -268951,
            ic_lon: -510679,
            ic_city: "Rio das Antas",
            ic_states: "SANTA CATARINA",
            ic_january: 5.647,
            ic_february: 5.244,
            ic_march: 4.774,
            ic_april: 3.865,
            ic_may: 2.993,
            ic_june: 2.631,
            ic_july: 2.887,
            ic_august: 3.783,
            ic_september: 3.906,
            ic_october: 4.678,
            ic_november: 5.76,
            ic_december: 5.961,
            ic_yearly: 4.344,
          },
          {
            ic_uuid: "f2c65bc2-407b-4c11-baef-00375f66a111",
            ic_lat: -221697,
            ic_lon: -43586,
            ic_city: "Rio das Flores",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.726,
            ic_february: 5.995,
            ic_march: 4.928,
            ic_april: 4.311,
            ic_may: 3.594,
            ic_june: 3.372,
            ic_july: 3.482,
            ic_august: 4.266,
            ic_september: 4.553,
            ic_october: 4.917,
            ic_november: 4.896,
            ic_december: 5.559,
            ic_yearly: 4.633,
          },
          {
            ic_uuid: "6b5f62bf-21f3-427c-a3dd-37befe899647",
            ic_lat: -225179,
            ic_lon: -419479,
            ic_city: "Rio das Ostras",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.196,
            ic_february: 6.409,
            ic_march: 5.102,
            ic_april: 4.468,
            ic_may: 3.689,
            ic_june: 3.424,
            ic_july: 3.482,
            ic_august: 4.291,
            ic_september: 4.509,
            ic_october: 5.031,
            ic_november: 4.974,
            ic_december: 5.837,
            ic_yearly: 4.784,
          },
          {
            ic_uuid: "e97437e3-5057-4e4b-9647-d19b1c62969d",
            ic_lat: -228422,
            ic_lon: -476051,
            ic_city: "Rio das Pedras",
            ic_states: "SÃO PAULO",
            ic_january: 5.644,
            ic_february: 5.815,
            ic_march: 5.125,
            ic_april: 4.614,
            ic_may: 3.804,
            ic_june: 3.527,
            ic_july: 3.697,
            ic_august: 4.632,
            ic_september: 4.808,
            ic_october: 5.433,
            ic_november: 5.731,
            ic_december: 6.11,
            ic_yearly: 4.912,
          },
          {
            ic_uuid: "8f604077-acfb-420d-b542-0d0c02b03d77",
            ic_lat: -135796,
            ic_lon: -418107,
            ic_city: "Rio de Contas",
            ic_states: "BAHIA",
            ic_january: 6.29,
            ic_february: 6.272,
            ic_march: 5.755,
            ic_april: 4.972,
            ic_may: 4.425,
            ic_june: 4.148,
            ic_july: 4.443,
            ic_august: 5.19,
            ic_september: 6.018,
            ic_october: 6.221,
            ic_november: 5.779,
            ic_december: 6.391,
            ic_yearly: 5.492,
          },
          {
            ic_uuid: "5104308d-8739-429b-921d-f5b1f2a1e583",
            ic_lat: -229139,
            ic_lon: -43201,
            ic_city: "Rio de Janeiro",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.041,
            ic_february: 6.221,
            ic_march: 5.062,
            ic_april: 4.358,
            ic_may: 3.59,
            ic_june: 3.35,
            ic_july: 3.341,
            ic_august: 4.203,
            ic_september: 4.435,
            ic_october: 5.106,
            ic_november: 5.143,
            ic_december: 5.928,
            ic_yearly: 4.732,
          },
          {
            ic_uuid: "68442296-0e99-479c-9189-4de2cbce89a6",
            ic_lat: -144095,
            ic_lon: -420748,
            ic_city: "Rio do Antônio",
            ic_states: "BAHIA",
            ic_january: 6.381,
            ic_february: 6.416,
            ic_march: 5.7,
            ic_april: 4.973,
            ic_may: 4.455,
            ic_june: 4.062,
            ic_july: 4.364,
            ic_august: 5.08,
            ic_september: 5.781,
            ic_october: 6.179,
            ic_november: 5.84,
            ic_december: 6.416,
            ic_yearly: 5.471,
          },
          {
            ic_uuid: "80a78143-773f-4973-bac5-b55e96bd40d3",
            ic_lat: -269457,
            ic_lon: -501365,
            ic_city: "Rio do Campo",
            ic_states: "SANTA CATARINA",
            ic_january: 5.22,
            ic_february: 4.933,
            ic_march: 4.413,
            ic_april: 3.557,
            ic_may: 2.861,
            ic_june: 2.41,
            ic_july: 2.639,
            ic_august: 3.48,
            ic_september: 3.556,
            ic_october: 4.023,
            ic_november: 5.214,
            ic_december: 5.398,
            ic_yearly: 3.975,
          },
          {
            ic_uuid: "b4f9bcc2-0ef7-4325-998d-e7d097559640",
            ic_lat: -52728,
            ic_lon: -353826,
            ic_city: "Rio do Fogo",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.161,
            ic_february: 6.196,
            ic_march: 6.121,
            ic_april: 5.704,
            ic_may: 5.236,
            ic_june: 4.848,
            ic_july: 5.016,
            ic_august: 5.818,
            ic_september: 6.234,
            ic_october: 6.267,
            ic_november: 6.506,
            ic_december: 6.233,
            ic_yearly: 5.862,
          },
          {
            ic_uuid: "fa80b26f-3b4b-4c3d-aef6-e0f13ca1d393",
            ic_lat: -271956,
            ic_lon: -497993,
            ic_city: "Rio do Oeste",
            ic_states: "SANTA CATARINA",
            ic_january: 5.562,
            ic_february: 5.239,
            ic_march: 4.639,
            ic_april: 3.658,
            ic_may: 2.856,
            ic_june: 2.312,
            ic_july: 2.456,
            ic_august: 3.312,
            ic_september: 3.519,
            ic_october: 4.137,
            ic_november: 5.412,
            ic_december: 5.737,
            ic_yearly: 4.07,
          },
          {
            ic_uuid: "2af5ccea-c4f6-4d03-85ab-5980520368f1",
            ic_lat: -13126,
            ic_lon: -42292,
            ic_city: "Rio do Pires",
            ic_states: "BAHIA",
            ic_january: 6.516,
            ic_february: 6.603,
            ic_march: 6.059,
            ic_april: 5.465,
            ic_may: 4.996,
            ic_june: 4.736,
            ic_july: 4.938,
            ic_august: 5.657,
            ic_september: 6.219,
            ic_october: 6.408,
            ic_november: 6.058,
            ic_december: 6.579,
            ic_yearly: 5.853,
          },
          {
            ic_uuid: "ee48c36d-9d39-4abe-9f6f-58d316beb06f",
            ic_lat: -166061,
            ic_lon: -405717,
            ic_city: "Rio do Prado",
            ic_states: "MINAS GERAIS",
            ic_january: 6.032,
            ic_february: 6.23,
            ic_march: 5.412,
            ic_april: 4.731,
            ic_may: 3.948,
            ic_june: 3.537,
            ic_july: 3.646,
            ic_august: 4.279,
            ic_september: 5.051,
            ic_october: 5.185,
            ic_november: 5.186,
            ic_december: 6.074,
            ic_yearly: 4.943,
          },
          {
            ic_uuid: "4bc361e8-59e7-4293-853f-4df75871e5f6",
            ic_lat: -272161,
            ic_lon: -496434,
            ic_city: "Rio do Sul",
            ic_states: "SANTA CATARINA",
            ic_january: 5.565,
            ic_february: 5.25,
            ic_march: 4.589,
            ic_april: 3.647,
            ic_may: 2.859,
            ic_june: 2.331,
            ic_july: 2.518,
            ic_august: 3.336,
            ic_september: 3.505,
            ic_october: 4.111,
            ic_november: 5.381,
            ic_december: 5.719,
            ic_yearly: 4.068,
          },
          {
            ic_uuid: "30399152-e6ab-4516-b9a8-fdf725243a7c",
            ic_lat: -202416,
            ic_lon: -428999,
            ic_city: "Rio Doce",
            ic_states: "MINAS GERAIS",
            ic_january: 5.548,
            ic_february: 5.875,
            ic_march: 4.899,
            ic_april: 4.443,
            ic_may: 3.799,
            ic_june: 3.632,
            ic_july: 3.82,
            ic_august: 4.628,
            ic_september: 4.901,
            ic_october: 5.012,
            ic_november: 4.731,
            ic_december: 5.404,
            ic_yearly: 4.725,
          },
          {
            ic_uuid: "22df20c1-f7dc-4048-8d32-dbe7374c4f72",
            ic_lat: -93419,
            ic_lon: -485337,
            ic_city: "Rio dos Bois",
            ic_states: "TOCANTINS",
            ic_january: 4.968,
            ic_february: 5.051,
            ic_march: 4.838,
            ic_april: 4.837,
            ic_may: 5.0,
            ic_june: 5.072,
            ic_july: 5.265,
            ic_august: 5.992,
            ic_september: 5.739,
            ic_october: 5.193,
            ic_november: 5.004,
            ic_december: 4.913,
            ic_yearly: 5.156,
          },
          {
            ic_uuid: "002692f0-1029-4b4d-89c1-3cb50db9d496",
            ic_lat: -267403,
            ic_lon: -492723,
            ic_city: "Rio dos Cedros",
            ic_states: "SANTA CATARINA",
            ic_january: 5.205,
            ic_february: 4.937,
            ic_march: 4.348,
            ic_april: 3.568,
            ic_may: 2.93,
            ic_june: 2.446,
            ic_july: 2.593,
            ic_august: 3.314,
            ic_september: 3.281,
            ic_october: 3.755,
            ic_november: 4.863,
            ic_december: 5.234,
            ic_yearly: 3.873,
          },
          {
            ic_uuid: "d36245fb-7592-49de-8e38-54ff3ca73a09",
            ic_lat: -272978,
            ic_lon: -528422,
            ic_city: "Rio dos Índios",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.296,
            ic_february: 5.872,
            ic_march: 5.169,
            ic_april: 4.123,
            ic_may: 3.131,
            ic_june: 2.636,
            ic_july: 2.925,
            ic_august: 3.779,
            ic_september: 4.026,
            ic_october: 5.169,
            ic_november: 6.186,
            ic_december: 6.588,
            ic_yearly: 4.658,
          },
          {
            ic_uuid: "c2c06f37-d28a-4c4e-a90d-a0c52ba482ec",
            ic_lat: -208555,
            ic_lon: -434724,
            ic_city: "Rio Espera",
            ic_states: "MINAS GERAIS",
            ic_january: 5.552,
            ic_february: 5.92,
            ic_march: 4.888,
            ic_april: 4.33,
            ic_may: 3.709,
            ic_june: 3.521,
            ic_july: 3.718,
            ic_august: 4.619,
            ic_september: 4.867,
            ic_october: 5.073,
            ic_november: 4.796,
            ic_december: 5.54,
            ic_yearly: 4.711,
          },
          {
            ic_uuid: "a2733c46-4a1f-4823-b016-af564c2d3dc9",
            ic_lat: -86596,
            ic_lon: -351535,
            ic_city: "Rio Formoso",
            ic_states: "PERNAMBUCO",
            ic_january: 5.836,
            ic_february: 5.895,
            ic_march: 5.952,
            ic_april: 5.091,
            ic_may: 4.384,
            ic_june: 4.054,
            ic_july: 4.149,
            ic_august: 4.824,
            ic_september: 5.444,
            ic_october: 5.756,
            ic_november: 6.094,
            ic_december: 6.14,
            ic_yearly: 5.302,
          },
          {
            ic_uuid: "d4c72edf-db53-4870-ba59-84c930d6b2f7",
            ic_lat: -281249,
            ic_lon: -491072,
            ic_city: "Rio Fortuna",
            ic_states: "SANTA CATARINA",
            ic_january: 5.546,
            ic_february: 5.206,
            ic_march: 4.519,
            ic_april: 3.79,
            ic_may: 2.945,
            ic_june: 2.548,
            ic_july: 2.757,
            ic_august: 3.5,
            ic_september: 3.578,
            ic_october: 4.304,
            ic_november: 5.555,
            ic_december: 5.818,
            ic_yearly: 4.172,
          },
          {
            ic_uuid: "7aa44fb8-8f3b-4f6e-b076-afdb9ee05033",
            ic_lat: -320354,
            ic_lon: -521075,
            ic_city: "Rio Grande",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.376,
            ic_february: 5.735,
            ic_march: 4.847,
            ic_april: 3.634,
            ic_may: 2.647,
            ic_june: 2.213,
            ic_july: 2.377,
            ic_august: 3.0,
            ic_september: 3.624,
            ic_october: 4.929,
            ic_november: 6.229,
            ic_december: 6.79,
            ic_yearly: 4.367,
          },
          {
            ic_uuid: "048e4059-3923-42b8-ac37-570f8d07e916",
            ic_lat: -237442,
            ic_lon: -463975,
            ic_city: "Rio Grande da Serra",
            ic_states: "SÃO PAULO",
            ic_january: 5.061,
            ic_february: 5.388,
            ic_march: 4.539,
            ic_april: 3.983,
            ic_may: 3.269,
            ic_june: 2.994,
            ic_july: 3.11,
            ic_august: 3.958,
            ic_september: 3.971,
            ic_october: 4.422,
            ic_november: 4.843,
            ic_december: 5.475,
            ic_yearly: 4.251,
          },
          {
            ic_uuid: "6ad257d4-a825-43bf-a7a7-56620920b546",
            ic_lat: -77746,
            ic_lon: -431368,
            ic_city: "Rio Grande do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.365,
            ic_february: 5.429,
            ic_march: 5.339,
            ic_april: 5.25,
            ic_may: 5.269,
            ic_june: 5.248,
            ic_july: 5.608,
            ic_august: 6.187,
            ic_september: 6.567,
            ic_october: 6.38,
            ic_november: 5.928,
            ic_december: 5.711,
            ic_yearly: 5.69,
          },
          {
            ic_uuid: "d638b3c8-7481-4787-b5ef-f979e0d84e07",
            ic_lat: -94783,
            ic_lon: -358397,
            ic_city: "Rio Largo",
            ic_states: "ALAGOAS",
            ic_january: 5.897,
            ic_february: 5.869,
            ic_march: 5.826,
            ic_april: 5.028,
            ic_may: 4.216,
            ic_june: 3.955,
            ic_july: 4.055,
            ic_august: 4.639,
            ic_september: 5.399,
            ic_october: 5.694,
            ic_november: 6.186,
            ic_december: 6.151,
            ic_yearly: 5.243,
          },
          {
            ic_uuid: "f4bcf191-0613-44d2-a500-5082cd3cd905",
            ic_lat: -20267,
            ic_lon: -443072,
            ic_city: "Rio Manso",
            ic_states: "MINAS GERAIS",
            ic_january: 5.673,
            ic_february: 5.874,
            ic_march: 4.998,
            ic_april: 4.702,
            ic_may: 4.185,
            ic_june: 4.013,
            ic_july: 4.259,
            ic_august: 5.159,
            ic_september: 5.388,
            ic_october: 5.465,
            ic_november: 5.162,
            ic_december: 5.511,
            ic_yearly: 5.032,
          },
          {
            ic_uuid: "817cee9e-a606-4b35-b23a-1434543236b8",
            ic_lat: -73174,
            ic_lon: -500432,
            ic_city: "Rio Maria",
            ic_states: "PARÁ",
            ic_january: 4.754,
            ic_february: 4.803,
            ic_march: 4.681,
            ic_april: 4.682,
            ic_may: 4.842,
            ic_june: 5.015,
            ic_july: 5.046,
            ic_august: 5.666,
            ic_september: 5.341,
            ic_october: 4.968,
            ic_november: 4.773,
            ic_december: 4.681,
            ic_yearly: 4.938,
          },
          {
            ic_uuid: "2a6b3d22-371d-4e92-bd0b-f1f74f8d580d",
            ic_lat: -262596,
            ic_lon: -495182,
            ic_city: "Rio Negrinho",
            ic_states: "SANTA CATARINA",
            ic_january: 5.327,
            ic_february: 5.068,
            ic_march: 4.478,
            ic_april: 3.6,
            ic_may: 2.902,
            ic_june: 2.506,
            ic_july: 2.76,
            ic_august: 3.664,
            ic_september: 3.702,
            ic_october: 4.297,
            ic_november: 5.177,
            ic_december: 5.543,
            ic_yearly: 4.085,
          },
          {
            ic_uuid: "2e7d0634-235d-4e64-b32c-2fd1074f2fbe",
            ic_lat: -194475,
            ic_lon: -549863,
            ic_city: "Rio Negro",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.528,
            ic_february: 5.735,
            ic_march: 5.432,
            ic_april: 4.941,
            ic_may: 4.131,
            ic_june: 3.95,
            ic_july: 4.098,
            ic_august: 4.895,
            ic_september: 5.057,
            ic_october: 5.487,
            ic_november: 5.848,
            ic_december: 6.056,
            ic_yearly: 5.096,
          },
          {
            ic_uuid: "644434f9-3cff-478e-85df-f413f0d15f89",
            ic_lat: -260955,
            ic_lon: -497987,
            ic_city: "Rio Negro",
            ic_states: "PARANÁ",
            ic_january: 5.444,
            ic_february: 5.117,
            ic_march: 4.534,
            ic_april: 3.645,
            ic_may: 2.9,
            ic_june: 2.509,
            ic_july: 2.768,
            ic_august: 3.721,
            ic_september: 3.76,
            ic_october: 4.389,
            ic_november: 5.274,
            ic_december: 5.603,
            ic_yearly: 4.139,
          },
          {
            ic_uuid: "0acd14af-c984-43f2-989e-b933c542f558",
            ic_lat: -214654,
            ic_lon: -431172,
            ic_city: "Rio Novo",
            ic_states: "MINAS GERAIS",
            ic_january: 5.717,
            ic_february: 5.94,
            ic_march: 4.92,
            ic_april: 4.285,
            ic_may: 3.62,
            ic_june: 3.436,
            ic_july: 3.615,
            ic_august: 4.391,
            ic_september: 4.703,
            ic_october: 4.97,
            ic_november: 4.805,
            ic_december: 5.586,
            ic_yearly: 4.665,
          },
          {
            ic_uuid: "5739db4c-b0f1-4421-9696-122ae1043003",
            ic_lat: -208561,
            ic_lon: -409391,
            ic_city: "Rio Novo do Sul",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.219,
            ic_february: 6.42,
            ic_march: 5.176,
            ic_april: 4.469,
            ic_may: 3.809,
            ic_june: 3.627,
            ic_july: 3.647,
            ic_august: 4.407,
            ic_september: 4.834,
            ic_october: 5.069,
            ic_november: 4.928,
            ic_december: 5.806,
            ic_yearly: 4.868,
          },
          {
            ic_uuid: "309ffab7-a8c0-48ff-ac92-e0bcf4dd5918",
            ic_lat: -191909,
            ic_lon: -462459,
            ic_city: "Rio Paranaíba",
            ic_states: "MINAS GERAIS",
            ic_january: 5.653,
            ic_february: 5.932,
            ic_march: 4.974,
            ic_april: 4.961,
            ic_may: 4.541,
            ic_june: 4.272,
            ic_july: 4.546,
            ic_august: 5.538,
            ic_september: 5.531,
            ic_october: 5.558,
            ic_november: 5.219,
            ic_december: 5.554,
            ic_yearly: 5.19,
          },
          {
            ic_uuid: "de96fdfb-25b0-4f41-8c37-267c10e9a4cd",
            ic_lat: -299897,
            ic_lon: -52378,
            ic_city: "Rio Pardo",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.426,
            ic_february: 5.878,
            ic_march: 4.896,
            ic_april: 3.788,
            ic_may: 2.795,
            ic_june: 2.319,
            ic_july: 2.495,
            ic_august: 3.219,
            ic_september: 3.64,
            ic_october: 4.829,
            ic_november: 6.221,
            ic_december: 6.718,
            ic_yearly: 4.435,
          },
          {
            ic_uuid: "100300ef-82d7-4b43-9335-d6dd7db3db0a",
            ic_lat: -156165,
            ic_lon: -425409,
            ic_city: "Rio Pardo de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 6.085,
            ic_february: 6.308,
            ic_march: 5.475,
            ic_april: 4.846,
            ic_may: 4.237,
            ic_june: 3.949,
            ic_july: 4.269,
            ic_august: 5.034,
            ic_september: 5.718,
            ic_october: 5.755,
            ic_november: 5.354,
            ic_december: 6.077,
            ic_yearly: 5.259,
          },
          {
            ic_uuid: "6859d796-4777-4995-b2e2-619843888bab",
            ic_lat: -19929,
            ic_lon: -43174,
            ic_city: "Rio Piracicaba",
            ic_states: "MINAS GERAIS",
            ic_january: 5.454,
            ic_february: 5.782,
            ic_march: 4.802,
            ic_april: 4.383,
            ic_may: 3.789,
            ic_june: 3.64,
            ic_july: 3.838,
            ic_august: 4.597,
            ic_september: 4.897,
            ic_october: 4.95,
            ic_november: 4.705,
            ic_december: 5.348,
            ic_yearly: 4.682,
          },
          {
            ic_uuid: "cefaaa25-05a3-4c15-b7b3-ff5947a66f37",
            ic_lat: -212716,
            ic_lon: -4317,
            ic_city: "Rio Pomba",
            ic_states: "MINAS GERAIS",
            ic_january: 5.663,
            ic_february: 5.915,
            ic_march: 4.914,
            ic_april: 4.299,
            ic_may: 3.662,
            ic_june: 3.464,
            ic_july: 3.627,
            ic_august: 4.434,
            ic_september: 4.77,
            ic_october: 5.018,
            ic_november: 4.813,
            ic_december: 5.533,
            ic_yearly: 4.676,
          },
          {
            ic_uuid: "9abdff16-3dbe-4a9b-afdc-c9427dc51d04",
            ic_lat: -220866,
            ic_lon: -438297,
            ic_city: "Rio Preto",
            ic_states: "MINAS GERAIS",
            ic_january: 5.366,
            ic_february: 5.727,
            ic_march: 4.761,
            ic_april: 4.232,
            ic_may: 3.599,
            ic_june: 3.444,
            ic_july: 3.525,
            ic_august: 4.386,
            ic_september: 4.558,
            ic_october: 4.742,
            ic_november: 4.701,
            ic_december: 5.34,
            ic_yearly: 4.532,
          },
          {
            ic_uuid: "29fe13d2-c2d7-4101-9056-eef4e4336f10",
            ic_lat: -27049,
            ic_lon: -596862,
            ic_city: "Rio Preto da Eva",
            ic_states: "AMAZONAS",
            ic_january: 3.998,
            ic_february: 4.078,
            ic_march: 4.019,
            ic_april: 3.785,
            ic_may: 3.796,
            ic_june: 4.281,
            ic_july: 4.296,
            ic_august: 4.945,
            ic_september: 5.02,
            ic_october: 4.859,
            ic_november: 4.67,
            ic_december: 4.212,
            ic_yearly: 4.33,
          },
          {
            ic_uuid: "68e6da4c-5ee7-4d8e-baba-6ddad5ad4f45",
            ic_lat: -177702,
            ic_lon: -487567,
            ic_city: "Rio Quente",
            ic_states: "GOIÁS",
            ic_january: 5.598,
            ic_february: 5.812,
            ic_march: 5.269,
            ic_april: 5.148,
            ic_may: 4.756,
            ic_june: 4.478,
            ic_july: 4.711,
            ic_august: 5.59,
            ic_september: 5.468,
            ic_october: 5.636,
            ic_november: 5.595,
            ic_december: 5.691,
            ic_yearly: 5.313,
          },
          {
            ic_uuid: "db19bebf-3e27-418a-8fce-936641a032fe",
            ic_lat: -114818,
            ic_lon: -379336,
            ic_city: "Rio Real",
            ic_states: "BAHIA",
            ic_january: 6.191,
            ic_february: 5.993,
            ic_march: 5.769,
            ic_april: 4.864,
            ic_may: 4.119,
            ic_june: 3.917,
            ic_july: 4.048,
            ic_august: 4.518,
            ic_september: 5.309,
            ic_october: 5.653,
            ic_november: 6.021,
            ic_december: 6.215,
            ic_yearly: 5.218,
          },
          {
            ic_uuid: "48858be5-4e88-4c52-a7bf-d19cae2b2812",
            ic_lat: -278597,
            ic_lon: -497758,
            ic_city: "Rio Rufino",
            ic_states: "SANTA CATARINA",
            ic_january: 5.646,
            ic_february: 5.341,
            ic_march: 4.553,
            ic_april: 3.759,
            ic_may: 2.917,
            ic_june: 2.494,
            ic_july: 2.7,
            ic_august: 3.478,
            ic_september: 3.638,
            ic_october: 4.272,
            ic_november: 5.53,
            ic_december: 5.854,
            ic_yearly: 4.182,
          },
          {
            ic_uuid: "3f7ddfc2-37a0-4d24-8c06-4cef3c950b03",
            ic_lat: -93485,
            ic_lon: -478942,
            ic_city: "Rio Sono",
            ic_states: "TOCANTINS",
            ic_january: 5.048,
            ic_february: 5.11,
            ic_march: 4.943,
            ic_april: 5.035,
            ic_may: 5.173,
            ic_june: 5.2,
            ic_july: 5.376,
            ic_august: 6.18,
            ic_september: 5.961,
            ic_october: 5.427,
            ic_november: 5.147,
            ic_december: 5.091,
            ic_yearly: 5.308,
          },
          {
            ic_uuid: "755c1b05-e2c2-432a-a654-9a17262c845e",
            ic_lat: -68042,
            ic_lon: -350779,
            ic_city: "Rio Tinto",
            ic_states: "PARAÍBA",
            ic_january: 5.86,
            ic_february: 5.965,
            ic_march: 6.069,
            ic_april: 5.368,
            ic_may: 4.758,
            ic_june: 4.291,
            ic_july: 4.341,
            ic_august: 5.185,
            ic_september: 5.612,
            ic_october: 5.937,
            ic_november: 6.205,
            ic_december: 6.078,
            ic_yearly: 5.472,
          },
          {
            ic_uuid: "3e6060d0-b9c4-491a-9f99-eec406503af4",
            ic_lat: -177927,
            ic_lon: -509196,
            ic_city: "Rio Verde",
            ic_states: "GOIÁS",
            ic_january: 5.605,
            ic_february: 5.58,
            ic_march: 5.145,
            ic_april: 5.041,
            ic_may: 4.647,
            ic_june: 4.385,
            ic_july: 4.538,
            ic_august: 5.528,
            ic_september: 5.308,
            ic_october: 5.42,
            ic_november: 5.368,
            ic_december: 5.701,
            ic_yearly: 5.189,
          },
          {
            ic_uuid: "6790ba39-555f-4d08-9f48-02ea6b7bf40e",
            ic_lat: -189179,
            ic_lon: -548446,
            ic_city: "Rio Verde de Mato Grosso",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.567,
            ic_february: 5.659,
            ic_march: 5.322,
            ic_april: 4.937,
            ic_may: 4.212,
            ic_june: 4.044,
            ic_july: 4.212,
            ic_august: 4.969,
            ic_september: 5.129,
            ic_october: 5.505,
            ic_november: 5.778,
            ic_december: 5.976,
            ic_yearly: 5.109,
          },
          {
            ic_uuid: "96c725d7-7f67-42d9-b5e8-dbb9baa95b10",
            ic_lat: -18294,
            ic_lon: -43009,
            ic_city: "Rio Vermelho",
            ic_states: "MINAS GERAIS",
            ic_january: 5.916,
            ic_february: 6.137,
            ic_march: 5.07,
            ic_april: 4.505,
            ic_may: 3.977,
            ic_june: 3.803,
            ic_july: 3.956,
            ic_august: 4.779,
            ic_september: 5.218,
            ic_october: 5.338,
            ic_november: 4.824,
            ic_december: 5.574,
            ic_yearly: 4.925,
          },
          {
            ic_uuid: "05c78423-7497-45e4-a584-d5e9964854fb",
            ic_lat: -199777,
            ic_lon: -496811,
            ic_city: "Riolândia",
            ic_states: "SÃO PAULO",
            ic_january: 5.752,
            ic_february: 5.948,
            ic_march: 5.278,
            ic_april: 4.972,
            ic_may: 4.216,
            ic_june: 3.953,
            ic_july: 4.222,
            ic_august: 5.094,
            ic_september: 5.065,
            ic_october: 5.617,
            ic_november: 5.845,
            ic_december: 6.169,
            ic_yearly: 5.178,
          },
          {
            ic_uuid: "4195c58d-edc0-4ba2-9bf8-d43df6dee5b9",
            ic_lat: -296395,
            ic_lon: -504492,
            ic_city: "Riozinho",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.041,
            ic_february: 5.466,
            ic_march: 4.594,
            ic_april: 3.763,
            ic_may: 2.759,
            ic_june: 2.339,
            ic_july: 2.588,
            ic_august: 3.257,
            ic_september: 3.508,
            ic_october: 4.57,
            ic_november: 5.981,
            ic_december: 6.326,
            ic_yearly: 4.266,
          },
          {
            ic_uuid: "e753566b-8420-43b3-b2da-6fa6bed9a935",
            ic_lat: -270658,
            ic_lon: -533269,
            ic_city: "Riqueza",
            ic_states: "SANTA CATARINA",
            ic_january: 6.377,
            ic_february: 5.841,
            ic_march: 5.159,
            ic_april: 4.016,
            ic_may: 3.059,
            ic_june: 2.555,
            ic_july: 2.863,
            ic_august: 3.725,
            ic_september: 4.0,
            ic_october: 5.144,
            ic_november: 6.167,
            ic_december: 6.526,
            ic_yearly: 4.619,
          },
          {
            ic_uuid: "7ca0bdf0-21db-4108-acd4-b78d3626f699",
            ic_lat: -21028,
            ic_lon: -443208,
            ic_city: "Ritápolis",
            ic_states: "MINAS GERAIS",
            ic_january: 5.539,
            ic_february: 5.759,
            ic_march: 4.833,
            ic_april: 4.479,
            ic_may: 3.878,
            ic_june: 3.738,
            ic_july: 3.965,
            ic_august: 4.908,
            ic_september: 5.102,
            ic_october: 5.234,
            ic_november: 4.975,
            ic_december: 5.492,
            ic_yearly: 4.825,
          },
          {
            ic_uuid: "d5019a5c-8e4f-4936-9401-09eef55da1dd",
            ic_lat: -238295,
            ic_lon: -494294,
            ic_city: "Riversul",
            ic_states: "SÃO PAULO",
            ic_january: 5.726,
            ic_february: 5.782,
            ic_march: 5.132,
            ic_april: 4.461,
            ic_may: 3.541,
            ic_june: 3.145,
            ic_july: 3.366,
            ic_august: 4.378,
            ic_september: 4.584,
            ic_october: 5.169,
            ic_november: 5.876,
            ic_december: 6.284,
            ic_yearly: 4.787,
          },
          {
            ic_uuid: "fd321b07-9441-4a05-a3e9-6af1f5be2df0",
            ic_lat: -292894,
            ic_lon: -518708,
            ic_city: "Roca Sales",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.209,
            ic_february: 5.741,
            ic_march: 4.823,
            ic_april: 3.775,
            ic_may: 2.82,
            ic_june: 2.323,
            ic_july: 2.604,
            ic_august: 3.327,
            ic_september: 3.609,
            ic_october: 4.7,
            ic_november: 6.086,
            ic_december: 6.502,
            ic_yearly: 4.376,
          },
          {
            ic_uuid: "0ec680f3-204c-4b77-b75b-9cac3e706ed8",
            ic_lat: -199535,
            ic_lon: -548904,
            ic_city: "Rochedo",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.65,
            ic_february: 5.687,
            ic_march: 5.389,
            ic_april: 4.883,
            ic_may: 4.04,
            ic_june: 3.811,
            ic_july: 3.969,
            ic_august: 4.837,
            ic_september: 4.974,
            ic_october: 5.487,
            ic_november: 5.886,
            ic_december: 6.125,
            ic_yearly: 5.061,
          },
          {
            ic_uuid: "aaffa68c-abd0-40a3-a310-d1dab79e6336",
            ic_lat: -216289,
            ic_lon: -430169,
            ic_city: "Rochedo de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.763,
            ic_february: 6.005,
            ic_march: 5.009,
            ic_april: 4.329,
            ic_may: 3.614,
            ic_june: 3.374,
            ic_july: 3.567,
            ic_august: 4.315,
            ic_september: 4.693,
            ic_october: 4.986,
            ic_november: 4.825,
            ic_december: 5.652,
            ic_yearly: 4.678,
          },
          {
            ic_uuid: "1d72dc09-87cc-4dea-8955-c9b40bdeac3e",
            ic_lat: -269248,
            ic_lon: -493653,
            ic_city: "Rodeio",
            ic_states: "SANTA CATARINA",
            ic_january: 5.34,
            ic_february: 5.081,
            ic_march: 4.482,
            ic_april: 3.63,
            ic_may: 2.965,
            ic_june: 2.451,
            ic_july: 2.587,
            ic_august: 3.308,
            ic_september: 3.345,
            ic_october: 3.821,
            ic_november: 5.03,
            ic_december: 5.38,
            ic_yearly: 3.952,
          },
          {
            ic_uuid: "3c837850-b435-409f-8599-6e81b51cd7e9",
            ic_lat: -274747,
            ic_lon: -53171,
            ic_city: "Rodeio Bonito",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.329,
            ic_february: 5.909,
            ic_march: 5.127,
            ic_april: 4.11,
            ic_may: 3.106,
            ic_june: 2.548,
            ic_july: 2.898,
            ic_august: 3.717,
            ic_september: 3.978,
            ic_october: 5.177,
            ic_november: 6.269,
            ic_december: 6.649,
            ic_yearly: 4.651,
          },
          {
            ic_uuid: "a06d5977-3f34-465e-b175-332105821d15",
            ic_lat: -212039,
            ic_lon: -42859,
            ic_city: "Rodeiro",
            ic_states: "MINAS GERAIS",
            ic_january: 5.87,
            ic_february: 6.097,
            ic_march: 5.077,
            ic_april: 4.431,
            ic_may: 3.735,
            ic_june: 3.546,
            ic_july: 3.722,
            ic_august: 4.5,
            ic_september: 4.872,
            ic_october: 5.173,
            ic_november: 4.997,
            ic_december: 5.715,
            ic_yearly: 4.811,
          },
          {
            ic_uuid: "f8c4b3dc-a8b5-4806-98e1-879a674f05c6",
            ic_lat: -88506,
            ic_lon: -387803,
            ic_city: "Rodelas",
            ic_states: "BAHIA",
            ic_january: 6.491,
            ic_february: 6.305,
            ic_march: 6.291,
            ic_april: 5.626,
            ic_may: 4.827,
            ic_june: 4.368,
            ic_july: 4.597,
            ic_august: 5.295,
            ic_september: 6.142,
            ic_october: 6.373,
            ic_november: 6.583,
            ic_december: 6.649,
            ic_yearly: 5.796,
          },
          {
            ic_uuid: "45c26d5d-0596-4c31-ae74-11852982edd2",
            ic_lat: -57843,
            ic_lon: -380582,
            ic_city: "Rodolfo Fernandes",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.984,
            ic_february: 6.095,
            ic_march: 6.051,
            ic_april: 5.721,
            ic_may: 5.364,
            ic_june: 5.049,
            ic_july: 5.393,
            ic_august: 6.077,
            ic_september: 6.423,
            ic_october: 6.664,
            ic_november: 6.677,
            ic_december: 6.147,
            ic_yearly: 5.971,
          },
          {
            ic_uuid: "935d3f4e-0e8b-4b85-a415-98fd70ff1bdd",
            ic_lat: -77342,
            ic_lon: -726505,
            ic_city: "Rodrigues Alves",
            ic_states: "ACRE",
            ic_january: 4.755,
            ic_february: 4.923,
            ic_march: 4.243,
            ic_april: 4.49,
            ic_may: 4.278,
            ic_june: 4.184,
            ic_july: 4.339,
            ic_august: 5.049,
            ic_september: 5.208,
            ic_october: 5.028,
            ic_november: 4.957,
            ic_december: 4.708,
            ic_yearly: 4.68,
          },
          {
            ic_uuid: "96a50013-8647-498c-aea0-da68c3fdbede",
            ic_lat: -28257,
            ic_lon: -548191,
            ic_city: "Rolador",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.605,
            ic_february: 6.137,
            ic_march: 5.22,
            ic_april: 4.061,
            ic_may: 3.086,
            ic_june: 2.531,
            ic_july: 2.833,
            ic_august: 3.59,
            ic_september: 4.05,
            ic_october: 5.157,
            ic_november: 6.373,
            ic_december: 6.779,
            ic_yearly: 4.702,
          },
          {
            ic_uuid: "5719de34-08ab-452c-b843-18b7b0c3d932",
            ic_lat: -233106,
            ic_lon: -513664,
            ic_city: "Rolândia",
            ic_states: "PARANÁ",
            ic_january: 5.853,
            ic_february: 5.759,
            ic_march: 5.201,
            ic_april: 4.607,
            ic_may: 3.646,
            ic_june: 3.378,
            ic_july: 3.601,
            ic_august: 4.52,
            ic_september: 4.695,
            ic_october: 5.3,
            ic_november: 5.968,
            ic_december: 6.257,
            ic_yearly: 4.899,
          },
          {
            ic_uuid: "fd3dfe18-34c1-43df-ba8d-fb99e65d6c03",
            ic_lat: -29651,
            ic_lon: -505761,
            ic_city: "Rolante",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.315,
            ic_february: 5.718,
            ic_march: 4.752,
            ic_april: 3.814,
            ic_may: 2.806,
            ic_june: 2.334,
            ic_july: 2.573,
            ic_august: 3.226,
            ic_september: 3.561,
            ic_october: 4.69,
            ic_november: 6.172,
            ic_december: 6.536,
            ic_yearly: 4.375,
          },
          {
            ic_uuid: "b58cc572-693b-489d-ae86-f6a101d2bd97",
            ic_lat: -117275,
            ic_lon: -617719,
            ic_city: "Rolim de Moura",
            ic_states: "RONDÔNIA",
            ic_january: 4.452,
            ic_february: 4.414,
            ic_march: 4.572,
            ic_april: 4.531,
            ic_may: 4.116,
            ic_june: 4.359,
            ic_july: 4.5,
            ic_august: 4.878,
            ic_september: 4.951,
            ic_october: 5.129,
            ic_november: 4.94,
            ic_december: 4.768,
            ic_yearly: 4.634,
          },
          {
            ic_uuid: "fc8224eb-5e4d-450a-880d-69fe807fb463",
            ic_lat: -188845,
            ic_lon: -47584,
            ic_city: "Romaria",
            ic_states: "MINAS GERAIS",
            ic_january: 5.58,
            ic_february: 5.859,
            ic_march: 4.976,
            ic_april: 4.878,
            ic_may: 4.542,
            ic_june: 4.314,
            ic_july: 4.607,
            ic_august: 5.588,
            ic_september: 5.517,
            ic_october: 5.57,
            ic_november: 5.401,
            ic_december: 5.676,
            ic_yearly: 5.209,
          },
          {
            ic_uuid: "6e97e8bc-8aa9-4fa9-b3ca-5a5c963992ab",
            ic_lat: -266814,
            ic_lon: -533176,
            ic_city: "Romelândia",
            ic_states: "SANTA CATARINA",
            ic_january: 6.316,
            ic_february: 5.804,
            ic_march: 5.136,
            ic_april: 4.105,
            ic_may: 3.183,
            ic_june: 2.648,
            ic_july: 2.933,
            ic_august: 3.813,
            ic_september: 4.088,
            ic_october: 5.131,
            ic_november: 6.088,
            ic_december: 6.407,
            ic_yearly: 4.637,
          },
          {
            ic_uuid: "f0ef9952-a135-4a9d-868e-370d33b006f7",
            ic_lat: -245963,
            ic_lon: -52272,
            ic_city: "Roncador",
            ic_states: "PARANÁ",
            ic_january: 6.173,
            ic_february: 5.646,
            ic_march: 5.188,
            ic_april: 4.407,
            ic_may: 3.471,
            ic_june: 3.07,
            ic_july: 3.308,
            ic_august: 4.296,
            ic_september: 4.45,
            ic_october: 5.203,
            ic_november: 5.991,
            ic_december: 6.269,
            ic_yearly: 4.789,
          },
          {
            ic_uuid: "0bf30c69-6e59-4361-b968-a8497a77d305",
            ic_lat: -277763,
            ic_lon: -52806,
            ic_city: "Ronda Alta",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.332,
            ic_february: 5.919,
            ic_march: 5.055,
            ic_april: 4.09,
            ic_may: 3.092,
            ic_june: 2.579,
            ic_july: 2.92,
            ic_august: 3.676,
            ic_september: 3.93,
            ic_october: 5.135,
            ic_november: 6.194,
            ic_december: 6.629,
            ic_yearly: 4.629,
          },
          {
            ic_uuid: "36be0f2e-4dd8-4acf-a53d-d8fbb9246ea0",
            ic_lat: -278319,
            ic_lon: -529086,
            ic_city: "Rondinha",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.35,
            ic_february: 5.94,
            ic_march: 5.073,
            ic_april: 4.104,
            ic_may: 3.102,
            ic_june: 2.577,
            ic_july: 2.925,
            ic_august: 3.679,
            ic_september: 3.937,
            ic_october: 5.134,
            ic_november: 6.228,
            ic_december: 6.657,
            ic_yearly: 4.642,
          },
          {
            ic_uuid: "ed684780-83f0-433c-b11f-fb1acd110c94",
            ic_lat: -10838,
            ic_lon: -614702,
            ic_city: "Rondolândia",
            ic_states: "MATO GROSSO",
            ic_january: 4.4,
            ic_february: 4.259,
            ic_march: 4.382,
            ic_april: 4.42,
            ic_may: 4.064,
            ic_june: 4.488,
            ic_july: 4.62,
            ic_august: 5.022,
            ic_september: 4.95,
            ic_october: 5.099,
            ic_november: 4.813,
            ic_december: 4.616,
            ic_yearly: 4.594,
          },
          {
            ic_uuid: "b0a84b1a-fb66-4c3d-abb7-4012cd55d260",
            ic_lat: -234124,
            ic_lon: -527663,
            ic_city: "Rondon",
            ic_states: "PARANÁ",
            ic_january: 6.03,
            ic_february: 5.885,
            ic_march: 5.434,
            ic_april: 4.57,
            ic_may: 3.625,
            ic_june: 3.254,
            ic_july: 3.457,
            ic_august: 4.434,
            ic_september: 4.652,
            ic_october: 5.403,
            ic_november: 6.145,
            ic_december: 6.462,
            ic_yearly: 4.946,
          },
          {
            ic_uuid: "4b034be4-59d3-4ebb-91b2-b7d7cdbc6621",
            ic_lat: -47783,
            ic_lon: -480673,
            ic_city: "Rondon do Pará",
            ic_states: "PARÁ",
            ic_january: 4.439,
            ic_february: 4.594,
            ic_march: 4.622,
            ic_april: 4.722,
            ic_may: 4.711,
            ic_june: 4.938,
            ic_july: 5.05,
            ic_august: 5.452,
            ic_september: 5.26,
            ic_october: 4.874,
            ic_november: 4.692,
            ic_december: 4.551,
            ic_yearly: 4.825,
          },
          {
            ic_uuid: "7f28052c-8193-499f-9315-6b5b0156c9cf",
            ic_lat: -164677,
            ic_lon: -546376,
            ic_city: "Rondonópolis",
            ic_states: "MATO GROSSO",
            ic_january: 5.541,
            ic_february: 5.515,
            ic_march: 5.26,
            ic_april: 5.061,
            ic_may: 4.605,
            ic_june: 4.408,
            ic_july: 4.555,
            ic_august: 5.45,
            ic_september: 5.208,
            ic_october: 5.276,
            ic_november: 5.491,
            ic_december: 5.639,
            ic_yearly: 5.168,
          },
          {
            ic_uuid: "7f7d4b87-f623-4caf-a1e8-26cb14f96793",
            ic_lat: -281301,
            ic_lon: -550271,
            ic_city: "Roque Gonzales",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.608,
            ic_february: 6.119,
            ic_march: 5.218,
            ic_april: 4.066,
            ic_may: 3.072,
            ic_june: 2.542,
            ic_july: 2.832,
            ic_august: 3.631,
            ic_september: 4.057,
            ic_october: 5.142,
            ic_november: 6.371,
            ic_december: 6.78,
            ic_yearly: 4.703,
          },
          {
            ic_uuid: "791459f0-f929-4491-9b32-a0283fa6848d",
            ic_lat: 9396,
            ic_lon: -604394,
            ic_city: "Rorainópolis",
            ic_states: "RORAIMA",
            ic_january: 4.506,
            ic_february: 4.598,
            ic_march: 4.659,
            ic_april: 4.426,
            ic_may: 4.106,
            ic_june: 4.374,
            ic_july: 4.459,
            ic_august: 5.008,
            ic_september: 5.348,
            ic_october: 5.332,
            ic_november: 5.0,
            ic_december: 4.707,
            ic_yearly: 4.71,
          },
          {
            ic_uuid: "13d656fb-0683-4679-8d1a-c33582e2522a",
            ic_lat: -2258,
            ic_lon: -530591,
            ic_city: "Rosana",
            ic_states: "SÃO PAULO",
            ic_january: 6.053,
            ic_february: 6.051,
            ic_march: 5.539,
            ic_april: 4.691,
            ic_may: 3.719,
            ic_june: 3.374,
            ic_july: 3.563,
            ic_august: 4.459,
            ic_september: 4.717,
            ic_october: 5.445,
            ic_november: 6.139,
            ic_december: 6.548,
            ic_yearly: 5.025,
          },
          {
            ic_uuid: "ea827347-b609-4113-984d-e965821a2fd1",
            ic_lat: -29385,
            ic_lon: -442475,
            ic_city: "Rosário",
            ic_states: "MARANHÃO",
            ic_january: 4.705,
            ic_february: 4.644,
            ic_march: 4.568,
            ic_april: 4.473,
            ic_may: 4.614,
            ic_june: 4.901,
            ic_july: 5.078,
            ic_august: 5.748,
            ic_september: 6.177,
            ic_october: 6.047,
            ic_november: 5.743,
            ic_december: 5.325,
            ic_yearly: 5.169,
          },
          {
            ic_uuid: "675fcf6c-1a4a-464f-b9eb-e25a12051fe7",
            ic_lat: -209817,
            ic_lon: -425116,
            ic_city: "Rosário da Limeira",
            ic_states: "MINAS GERAIS",
            ic_january: 5.479,
            ic_february: 5.748,
            ic_march: 4.766,
            ic_april: 4.248,
            ic_may: 3.692,
            ic_june: 3.549,
            ic_july: 3.676,
            ic_august: 4.464,
            ic_september: 4.814,
            ic_october: 4.922,
            ic_november: 4.676,
            ic_december: 5.453,
            ic_yearly: 4.624,
          },
          {
            ic_uuid: "5efb305a-0683-4719-ae5e-fcaa7795a134",
            ic_lat: -106962,
            ic_lon: -370325,
            ic_city: "Rosário do Catete",
            ic_states: "SERGIPE",
            ic_january: 6.338,
            ic_february: 6.191,
            ic_march: 6.048,
            ic_april: 5.099,
            ic_may: 4.332,
            ic_june: 4.065,
            ic_july: 4.119,
            ic_august: 4.715,
            ic_september: 5.485,
            ic_october: 5.878,
            ic_november: 6.393,
            ic_december: 6.419,
            ic_yearly: 5.424,
          },
          {
            ic_uuid: "92042cd6-bfc0-43ac-81f9-90303341b744",
            ic_lat: -242519,
            ic_lon: -512419,
            ic_city: "Rosário do Ivaí",
            ic_states: "PARANÁ",
            ic_january: 5.982,
            ic_february: 5.694,
            ic_march: 5.279,
            ic_april: 4.537,
            ic_may: 3.508,
            ic_june: 3.126,
            ic_july: 3.385,
            ic_august: 4.376,
            ic_september: 4.575,
            ic_october: 5.278,
            ic_november: 6.134,
            ic_december: 6.361,
            ic_yearly: 4.853,
          },
          {
            ic_uuid: "d500b579-9fab-44dc-9e5e-d75b0e193e96",
            ic_lat: -30258,
            ic_lon: -549139,
            ic_city: "Rosário do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.726,
            ic_february: 6.041,
            ic_march: 5.148,
            ic_april: 3.95,
            ic_may: 2.872,
            ic_june: 2.383,
            ic_july: 2.64,
            ic_august: 3.38,
            ic_september: 3.956,
            ic_october: 5.227,
            ic_november: 6.399,
            ic_december: 7.046,
            ic_yearly: 4.647,
          },
          {
            ic_uuid: "ec8586a6-9af8-4e81-aadd-9cd1a06247f8",
            ic_lat: -148264,
            ic_lon: -56424,
            ic_city: "Rosário Oeste",
            ic_states: "MATO GROSSO",
            ic_january: 5.146,
            ic_february: 5.196,
            ic_march: 5.011,
            ic_april: 4.842,
            ic_may: 4.478,
            ic_june: 4.457,
            ic_july: 4.65,
            ic_august: 5.428,
            ic_september: 5.15,
            ic_october: 5.219,
            ic_november: 5.475,
            ic_december: 5.549,
            ic_yearly: 5.05,
          },
          {
            ic_uuid: "3a32301f-1cee-4309-87f5-34f0399cfd8b",
            ic_lat: -228943,
            ic_lon: -453074,
            ic_city: "Roseira",
            ic_states: "SÃO PAULO",
            ic_january: 5.509,
            ic_february: 5.699,
            ic_march: 4.88,
            ic_april: 4.422,
            ic_may: 3.621,
            ic_june: 3.397,
            ic_july: 3.49,
            ic_august: 4.463,
            ic_september: 4.601,
            ic_october: 5.113,
            ic_november: 5.212,
            ic_december: 5.784,
            ic_yearly: 4.682,
          },
          {
            ic_uuid: "27b20f47-51aa-4ca5-ade6-cc436af46f3d",
            ic_lat: -98355,
            ic_lon: -359785,
            ic_city: "Roteiro",
            ic_states: "ALAGOAS",
            ic_january: 6.125,
            ic_february: 6.105,
            ic_march: 6.021,
            ic_april: 5.137,
            ic_may: 4.364,
            ic_june: 3.993,
            ic_july: 4.159,
            ic_august: 4.768,
            ic_september: 5.496,
            ic_october: 5.906,
            ic_november: 6.322,
            ic_december: 6.382,
            ic_yearly: 5.398,
          },
          {
            ic_uuid: "c8bb71f8-c533-47c2-99b1-5991fed49b80",
            ic_lat: -164057,
            ic_lon: -422614,
            ic_city: "Rubelita",
            ic_states: "MINAS GERAIS",
            ic_january: 6.252,
            ic_february: 6.379,
            ic_march: 5.435,
            ic_april: 4.78,
            ic_may: 4.116,
            ic_june: 3.823,
            ic_july: 4.093,
            ic_august: 4.863,
            ic_september: 5.548,
            ic_october: 5.68,
            ic_november: 5.332,
            ic_december: 6.139,
            ic_yearly: 5.203,
          },
          {
            ic_uuid: "d6a50617-1fdb-4de6-9a9c-6a85c441f4eb",
            ic_lat: -21301,
            ic_lon: -5073,
            ic_city: "Rubiácea",
            ic_states: "SÃO PAULO",
            ic_january: 5.907,
            ic_february: 5.936,
            ic_march: 5.407,
            ic_april: 4.86,
            ic_may: 4.004,
            ic_june: 3.724,
            ic_july: 3.946,
            ic_august: 4.846,
            ic_september: 4.926,
            ic_october: 5.598,
            ic_november: 6.028,
            ic_december: 6.293,
            ic_yearly: 5.123,
          },
          {
            ic_uuid: "e8f437da-2976-4b7a-b755-24070e1fd2ad",
            ic_lat: -151637,
            ic_lon: -498014,
            ic_city: "Rubiataba",
            ic_states: "GOIÁS",
            ic_january: 5.258,
            ic_february: 5.45,
            ic_march: 5.121,
            ic_april: 5.097,
            ic_may: 4.958,
            ic_june: 4.682,
            ic_july: 4.85,
            ic_august: 5.758,
            ic_september: 5.542,
            ic_october: 5.514,
            ic_november: 5.195,
            ic_december: 5.299,
            ic_yearly: 5.227,
          },
          {
            ic_uuid: "c3ea48ab-9c79-4fa9-8504-13f6c5ae9e22",
            ic_lat: -16378,
            ic_lon: -405401,
            ic_city: "Rubim",
            ic_states: "MINAS GERAIS",
            ic_january: 6.053,
            ic_february: 6.259,
            ic_march: 5.462,
            ic_april: 4.808,
            ic_may: 3.996,
            ic_june: 3.565,
            ic_july: 3.708,
            ic_august: 4.275,
            ic_september: 5.009,
            ic_october: 5.181,
            ic_november: 5.223,
            ic_december: 6.087,
            ic_yearly: 4.969,
          },
          {
            ic_uuid: "35ddeb83-91ff-4b37-a559-d147a2f66ae4",
            ic_lat: -201764,
            ic_lon: -510075,
            ic_city: "Rubinéia",
            ic_states: "SÃO PAULO",
            ic_january: 5.785,
            ic_february: 6.0,
            ic_march: 5.492,
            ic_april: 4.974,
            ic_may: 4.211,
            ic_june: 3.879,
            ic_july: 4.084,
            ic_august: 4.992,
            ic_september: 4.929,
            ic_october: 5.617,
            ic_november: 6.014,
            ic_december: 6.328,
            ic_yearly: 5.192,
          },
          {
            ic_uuid: "c91537b3-d651-4919-b540-9cfacad3e231",
            ic_lat: -41007,
            ic_lon: -549096,
            ic_city: "Rurópolis",
            ic_states: "PARÁ",
            ic_january: 4.106,
            ic_february: 4.259,
            ic_march: 4.248,
            ic_april: 4.168,
            ic_may: 4.181,
            ic_june: 4.507,
            ic_july: 4.466,
            ic_august: 5.076,
            ic_september: 4.979,
            ic_october: 5.049,
            ic_november: 4.86,
            ic_december: 4.469,
            ic_yearly: 4.531,
          },
          {
            ic_uuid: "e56aef05-c60c-4b50-bfab-3c5c66079209",
            ic_lat: -49271,
            ic_lon: -379724,
            ic_city: "Russas",
            ic_states: "CEARÁ",
            ic_january: 5.764,
            ic_february: 5.801,
            ic_march: 5.848,
            ic_april: 5.409,
            ic_may: 5.251,
            ic_june: 5.059,
            ic_july: 5.315,
            ic_august: 5.969,
            ic_september: 6.326,
            ic_october: 6.46,
            ic_november: 6.342,
            ic_december: 5.913,
            ic_yearly: 5.788,
          },
          {
            ic_uuid: "730ea243-9ee4-4b9c-b073-659c607c452f",
            ic_lat: -122821,
            ic_lon: -404934,
            ic_city: "Ruy Barbosa",
            ic_states: "BAHIA",
            ic_january: 5.858,
            ic_february: 5.848,
            ic_march: 5.711,
            ic_april: 4.656,
            ic_may: 3.989,
            ic_june: 3.678,
            ic_july: 3.795,
            ic_august: 4.369,
            ic_september: 5.241,
            ic_october: 5.277,
            ic_november: 5.461,
            ic_december: 5.84,
            ic_yearly: 4.977,
          },
          {
            ic_uuid: "d14efead-dc75-4239-929d-6d9f70c5456c",
            ic_lat: -58813,
            ic_lon: -359353,
            ic_city: "Ruy Barbosa",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.72,
            ic_february: 5.707,
            ic_march: 5.746,
            ic_april: 5.453,
            ic_may: 4.945,
            ic_june: 4.524,
            ic_july: 4.745,
            ic_august: 5.375,
            ic_september: 5.786,
            ic_october: 5.983,
            ic_november: 6.101,
            ic_december: 5.725,
            ic_yearly: 5.484,
          },
          {
            ic_uuid: "5b2b2c06-1a82-46e3-b38b-dbb101509646",
            ic_lat: -198845,
            ic_lon: -438267,
            ic_city: "Sabará",
            ic_states: "MINAS GERAIS",
            ic_january: 5.801,
            ic_february: 5.969,
            ic_march: 5.126,
            ic_april: 4.762,
            ic_may: 4.133,
            ic_june: 4.043,
            ic_july: 4.265,
            ic_august: 5.091,
            ic_september: 5.469,
            ic_october: 5.586,
            ic_november: 5.286,
            ic_december: 5.634,
            ic_yearly: 5.097,
          },
          {
            ic_uuid: "896b6e56-4449-47f8-a8dc-d1c4cfbcee82",
            ic_lat: -23316,
            ic_lon: -515554,
            ic_city: "Sabáudia",
            ic_states: "PARANÁ",
            ic_january: 5.925,
            ic_february: 5.789,
            ic_march: 5.301,
            ic_april: 4.635,
            ic_may: 3.654,
            ic_june: 3.387,
            ic_july: 3.57,
            ic_august: 4.491,
            ic_september: 4.685,
            ic_october: 5.355,
            ic_november: 6.034,
            ic_december: 6.313,
            ic_yearly: 4.928,
          },
          {
            ic_uuid: "f5317ccd-de53-4d89-883e-3f228aed0d49",
            ic_lat: -214598,
            ic_lon: -49576,
            ic_city: "Sabino",
            ic_states: "SÃO PAULO",
            ic_january: 6.005,
            ic_february: 6.125,
            ic_march: 5.44,
            ic_april: 4.812,
            ic_may: 3.97,
            ic_june: 3.682,
            ic_july: 3.951,
            ic_august: 4.788,
            ic_september: 4.949,
            ic_october: 5.658,
            ic_november: 6.044,
            ic_december: 6.381,
            ic_yearly: 5.15,
          },
          {
            ic_uuid: "bff7c5c7-315b-40c5-95e9-d0ee50922c24",
            ic_lat: -186658,
            ic_lon: -430756,
            ic_city: "Sabinópolis",
            ic_states: "MINAS GERAIS",
            ic_january: 5.716,
            ic_february: 5.957,
            ic_march: 4.944,
            ic_april: 4.442,
            ic_may: 3.872,
            ic_june: 3.702,
            ic_july: 3.888,
            ic_august: 4.609,
            ic_september: 5.026,
            ic_october: 5.179,
            ic_november: 4.823,
            ic_december: 5.52,
            ic_yearly: 4.807,
          },
          {
            ic_uuid: "a019ff53-c714-45a4-99ef-5f739493ad75",
            ic_lat: -6535,
            ic_lon: -399021,
            ic_city: "Saboeiro",
            ic_states: "CEARÁ",
            ic_january: 5.565,
            ic_february: 5.602,
            ic_march: 5.594,
            ic_april: 5.278,
            ic_may: 5.113,
            ic_june: 5.079,
            ic_july: 5.445,
            ic_august: 6.201,
            ic_september: 6.558,
            ic_october: 6.459,
            ic_november: 6.36,
            ic_december: 5.824,
            ic_yearly: 5.756,
          },
          {
            ic_uuid: "f5686f50-732a-4ea4-a9af-2fb74a0698eb",
            ic_lat: -1987,
            ic_lon: -474436,
            ic_city: "Sacramento",
            ic_states: "MINAS GERAIS",
            ic_january: 5.434,
            ic_february: 5.793,
            ic_march: 4.957,
            ic_april: 4.882,
            ic_may: 4.41,
            ic_june: 4.174,
            ic_july: 4.474,
            ic_august: 5.323,
            ic_september: 5.244,
            ic_october: 5.526,
            ic_november: 5.434,
            ic_december: 5.746,
            ic_yearly: 5.116,
          },
          {
            ic_uuid: "2d53a5db-0dd4-4342-9093-9c156725582a",
            ic_lat: -27709,
            ic_lon: -531355,
            ic_city: "Sagrada Família",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.377,
            ic_february: 5.883,
            ic_march: 5.09,
            ic_april: 4.112,
            ic_may: 3.11,
            ic_june: 2.563,
            ic_july: 2.914,
            ic_august: 3.688,
            ic_september: 3.971,
            ic_october: 5.147,
            ic_november: 6.271,
            ic_december: 6.71,
            ic_yearly: 4.653,
          },
          {
            ic_uuid: "9b162f6f-c450-4da8-bb01-aadd44bcb494",
            ic_lat: -218828,
            ic_lon: -509598,
            ic_city: "Sagres",
            ic_states: "SÃO PAULO",
            ic_january: 5.915,
            ic_february: 5.956,
            ic_march: 5.427,
            ic_april: 4.799,
            ic_may: 3.966,
            ic_june: 3.66,
            ic_july: 3.842,
            ic_august: 4.81,
            ic_september: 4.838,
            ic_october: 5.576,
            ic_november: 6.072,
            ic_december: 6.353,
            ic_yearly: 5.101,
          },
          {
            ic_uuid: "33a4d53b-ac31-4ea3-a4bb-1e6fda46ab75",
            ic_lat: -83276,
            ic_lon: -357103,
            ic_city: "Sairé",
            ic_states: "PERNAMBUCO",
            ic_january: 5.748,
            ic_february: 5.725,
            ic_march: 5.767,
            ic_april: 5.075,
            ic_may: 4.34,
            ic_june: 3.922,
            ic_july: 4.027,
            ic_august: 4.709,
            ic_september: 5.433,
            ic_october: 5.761,
            ic_november: 6.099,
            ic_december: 5.935,
            ic_yearly: 5.212,
          },
          {
            ic_uuid: "b1017195-3d3a-41ac-819b-e693f47125fd",
            ic_lat: -283946,
            ic_lon: -530975,
            ic_city: "Saldanha Marinho",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.343,
            ic_february: 5.922,
            ic_march: 5.04,
            ic_april: 3.994,
            ic_may: 3.044,
            ic_june: 2.501,
            ic_july: 2.816,
            ic_august: 3.573,
            ic_september: 3.864,
            ic_october: 5.036,
            ic_november: 6.218,
            ic_december: 6.621,
            ic_yearly: 4.581,
          },
          {
            ic_uuid: "821fd62a-b59f-4bb7-a9a9-b81101537672",
            ic_lat: -213413,
            ic_lon: -494973,
            ic_city: "Sales",
            ic_states: "SÃO PAULO",
            ic_january: 5.92,
            ic_february: 5.959,
            ic_march: 5.304,
            ic_april: 4.841,
            ic_may: 4.022,
            ic_june: 3.768,
            ic_july: 3.987,
            ic_august: 4.854,
            ic_september: 4.975,
            ic_october: 5.628,
            ic_november: 5.947,
            ic_december: 6.255,
            ic_yearly: 5.122,
          },
          {
            ic_uuid: "a1bac16d-0107-410c-b670-732d29f132ff",
            ic_lat: -207701,
            ic_lon: -478373,
            ic_city: "Sales Oliveira",
            ic_states: "SÃO PAULO",
            ic_january: 5.691,
            ic_february: 5.871,
            ic_march: 5.081,
            ic_april: 4.814,
            ic_may: 4.213,
            ic_june: 3.954,
            ic_july: 4.182,
            ic_august: 5.087,
            ic_september: 5.061,
            ic_october: 5.539,
            ic_november: 5.731,
            ic_december: 5.97,
            ic_yearly: 5.099,
          },
          {
            ic_uuid: "472ef314-5249-4848-af7e-ab09a0042dd0",
            ic_lat: -235293,
            ic_lon: -458469,
            ic_city: "Salesópolis",
            ic_states: "SÃO PAULO",
            ic_january: 5.483,
            ic_february: 5.739,
            ic_march: 4.83,
            ic_april: 4.285,
            ic_may: 3.43,
            ic_june: 3.212,
            ic_july: 3.302,
            ic_august: 4.229,
            ic_september: 4.302,
            ic_october: 4.957,
            ic_november: 5.291,
            ic_december: 5.841,
            ic_yearly: 4.575,
          },
          {
            ic_uuid: "5fb81b19-9055-4a2b-afe5-7566a45d22c8",
            ic_lat: -269803,
            ic_lon: -499992,
            ic_city: "Salete",
            ic_states: "SANTA CATARINA",
            ic_january: 5.265,
            ic_february: 5.036,
            ic_march: 4.468,
            ic_april: 3.565,
            ic_may: 2.831,
            ic_june: 2.373,
            ic_july: 2.572,
            ic_august: 3.394,
            ic_september: 3.502,
            ic_october: 4.003,
            ic_november: 5.247,
            ic_december: 5.469,
            ic_yearly: 3.977,
          },
          {
            ic_uuid: "03ad4faf-2692-4e61-a0d4-8b2508565f3a",
            ic_lat: -71027,
            ic_lon: -36845,
            ic_city: "Salgadinho",
            ic_states: "PARAÍBA",
            ic_january: 6.041,
            ic_february: 6.035,
            ic_march: 5.987,
            ic_april: 5.652,
            ic_may: 4.992,
            ic_june: 4.5,
            ic_july: 4.786,
            ic_august: 5.572,
            ic_september: 6.219,
            ic_october: 6.428,
            ic_november: 6.542,
            ic_december: 6.208,
            ic_yearly: 5.747,
          },
          {
            ic_uuid: "95022a31-ea48-41cd-b971-9a230d5e12a4",
            ic_lat: -79378,
            ic_lon: -356321,
            ic_city: "Salgadinho",
            ic_states: "PERNAMBUCO",
            ic_january: 5.703,
            ic_february: 5.713,
            ic_march: 5.81,
            ic_april: 5.181,
            ic_may: 4.464,
            ic_june: 4.094,
            ic_july: 4.25,
            ic_august: 4.862,
            ic_september: 5.518,
            ic_october: 5.705,
            ic_november: 6.002,
            ic_december: 5.927,
            ic_yearly: 5.269,
          },
          {
            ic_uuid: "2a42c639-f68d-47b5-82f9-85affc76aa41",
            ic_lat: -110268,
            ic_lon: -374763,
            ic_city: "Salgado",
            ic_states: "SERGIPE",
            ic_january: 6.187,
            ic_february: 5.99,
            ic_march: 5.905,
            ic_april: 4.984,
            ic_may: 4.175,
            ic_june: 3.952,
            ic_july: 4.006,
            ic_august: 4.545,
            ic_september: 5.339,
            ic_october: 5.681,
            ic_november: 6.191,
            ic_december: 6.257,
            ic_yearly: 5.268,
          },
          {
            ic_uuid: "b244bbc7-66d9-4323-8228-3e8628f1ddfc",
            ic_lat: -73576,
            ic_lon: -354363,
            ic_city: "Salgado de São Félix",
            ic_states: "PARAÍBA",
            ic_january: 5.695,
            ic_february: 5.874,
            ic_march: 5.896,
            ic_april: 5.282,
            ic_may: 4.598,
            ic_june: 4.164,
            ic_july: 4.278,
            ic_august: 5.101,
            ic_september: 5.588,
            ic_october: 5.811,
            ic_november: 6.117,
            ic_december: 5.941,
            ic_yearly: 5.362,
          },
          {
            ic_uuid: "c39aafe0-3c71-4511-97fb-724d6f03f50c",
            ic_lat: -261781,
            ic_lon: -533636,
            ic_city: "Salgado Filho",
            ic_states: "PARANÁ",
            ic_january: 6.334,
            ic_february: 5.716,
            ic_march: 5.163,
            ic_april: 4.198,
            ic_may: 3.253,
            ic_june: 2.78,
            ic_july: 3.1,
            ic_august: 4.029,
            ic_september: 4.269,
            ic_october: 5.222,
            ic_november: 6.124,
            ic_december: 6.338,
            ic_yearly: 4.711,
          },
          {
            ic_uuid: "cd4274aa-c2c5-4bfd-b4cf-902367753ec0",
            ic_lat: -80742,
            ic_lon: -39125,
            ic_city: "Salgueiro",
            ic_states: "PERNAMBUCO",
            ic_january: 6.066,
            ic_february: 5.903,
            ic_march: 5.885,
            ic_april: 5.46,
            ic_may: 4.765,
            ic_june: 4.437,
            ic_july: 4.738,
            ic_august: 5.53,
            ic_september: 6.363,
            ic_october: 6.436,
            ic_november: 6.599,
            ic_december: 6.264,
            ic_yearly: 5.704,
          },
          {
            ic_uuid: "3b64b3f2-d495-4f68-9a4b-417b8eb59467",
            ic_lat: -161691,
            ic_lon: -42296,
            ic_city: "Salinas",
            ic_states: "MINAS GERAIS",
            ic_january: 6.172,
            ic_february: 6.349,
            ic_march: 5.465,
            ic_april: 4.786,
            ic_may: 4.135,
            ic_june: 3.826,
            ic_july: 4.082,
            ic_august: 4.861,
            ic_september: 5.589,
            ic_october: 5.651,
            ic_november: 5.395,
            ic_december: 6.156,
            ic_yearly: 5.206,
          },
          {
            ic_uuid: "d5391348-8362-4d8a-bec5-768b3868f757",
            ic_lat: -128735,
            ic_lon: -387565,
            ic_city: "Salinas da Margarida",
            ic_states: "BAHIA",
            ic_january: 6.42,
            ic_february: 6.214,
            ic_march: 5.996,
            ic_april: 4.77,
            ic_may: 4.069,
            ic_june: 3.736,
            ic_july: 3.999,
            ic_august: 4.588,
            ic_september: 5.496,
            ic_october: 5.883,
            ic_november: 6.128,
            ic_december: 6.446,
            ic_yearly: 5.312,
          },
          {
            ic_uuid: "207eb722-eb6a-4f87-b655-79b7d7ff9857",
            ic_lat: -6314,
            ic_lon: -473476,
            ic_city: "Salinópolis",
            ic_states: "PARÁ",
            ic_january: 4.944,
            ic_february: 4.534,
            ic_march: 4.45,
            ic_april: 4.508,
            ic_may: 4.805,
            ic_june: 5.099,
            ic_july: 5.217,
            ic_august: 5.238,
            ic_september: 5.657,
            ic_october: 5.538,
            ic_november: 5.503,
            ic_december: 5.23,
            ic_yearly: 5.06,
          },
          {
            ic_uuid: "dbde77ad-4926-454a-afec-b6923b0488e1",
            ic_lat: -72842,
            ic_lon: -40459,
            ic_city: "Salitre",
            ic_states: "CEARÁ",
            ic_january: 5.456,
            ic_february: 5.402,
            ic_march: 5.392,
            ic_april: 5.281,
            ic_may: 5.141,
            ic_june: 5.132,
            ic_july: 5.455,
            ic_august: 6.148,
            ic_september: 6.509,
            ic_october: 6.419,
            ic_november: 6.324,
            ic_december: 5.897,
            ic_yearly: 5.713,
          },
          {
            ic_uuid: "cb39e3e5-aee8-4eca-a1b1-c28639a861b5",
            ic_lat: -21624,
            ic_lon: -508609,
            ic_city: "Salmourão",
            ic_states: "SÃO PAULO",
            ic_january: 5.901,
            ic_february: 5.927,
            ic_march: 5.38,
            ic_april: 4.799,
            ic_may: 3.977,
            ic_june: 3.677,
            ic_july: 3.889,
            ic_august: 4.809,
            ic_september: 4.845,
            ic_october: 5.504,
            ic_november: 5.996,
            ic_december: 6.259,
            ic_yearly: 5.08,
          },
          {
            ic_uuid: "f7867620-71d4-4a4b-b39f-05a8be9d86e9",
            ic_lat: -89727,
            ic_lon: -366913,
            ic_city: "Saloá",
            ic_states: "PERNAMBUCO",
            ic_january: 6.079,
            ic_february: 5.85,
            ic_march: 5.897,
            ic_april: 5.233,
            ic_may: 4.252,
            ic_june: 3.803,
            ic_july: 3.953,
            ic_august: 4.606,
            ic_september: 5.569,
            ic_october: 5.987,
            ic_november: 6.562,
            ic_december: 6.403,
            ic_yearly: 5.349,
          },
          {
            ic_uuid: "5b11e7a1-7bea-47c3-b0eb-9a46c978de11",
            ic_lat: -266054,
            ic_lon: -530582,
            ic_city: "Saltinho",
            ic_states: "SANTA CATARINA",
            ic_january: 6.146,
            ic_february: 5.663,
            ic_march: 5.046,
            ic_april: 4.096,
            ic_may: 3.173,
            ic_june: 2.702,
            ic_july: 3.008,
            ic_august: 3.891,
            ic_september: 4.124,
            ic_october: 5.076,
            ic_november: 5.967,
            ic_december: 6.285,
            ic_yearly: 4.598,
          },
          {
            ic_uuid: "3b395dee-8b9c-4d06-9bf7-6c4cd6507733",
            ic_lat: -228446,
            ic_lon: -476758,
            ic_city: "Saltinho",
            ic_states: "SÃO PAULO",
            ic_january: 5.644,
            ic_february: 5.815,
            ic_march: 5.125,
            ic_april: 4.614,
            ic_may: 3.804,
            ic_june: 3.527,
            ic_july: 3.697,
            ic_august: 4.632,
            ic_september: 4.808,
            ic_october: 5.433,
            ic_november: 5.731,
            ic_december: 6.11,
            ic_yearly: 4.912,
          },
          {
            ic_uuid: "c504b807-03ea-4e8a-96f3-b617055189e3",
            ic_lat: -232001,
            ic_lon: -472935,
            ic_city: "Salto",
            ic_states: "SÃO PAULO",
            ic_january: 5.584,
            ic_february: 5.806,
            ic_march: 5.091,
            ic_april: 4.562,
            ic_may: 3.724,
            ic_june: 3.424,
            ic_july: 3.586,
            ic_august: 4.545,
            ic_september: 4.715,
            ic_october: 5.326,
            ic_november: 5.725,
            ic_december: 6.162,
            ic_yearly: 4.854,
          },
          {
            ic_uuid: "699c73ff-14cf-42cb-8446-8616257bf148",
            ic_lat: -160068,
            ic_lon: -399394,
            ic_city: "Salto da Divisa",
            ic_states: "MINAS GERAIS",
            ic_january: 6.028,
            ic_february: 6.115,
            ic_march: 5.378,
            ic_april: 4.69,
            ic_may: 3.871,
            ic_june: 3.535,
            ic_july: 3.715,
            ic_august: 4.225,
            ic_september: 4.944,
            ic_october: 5.114,
            ic_november: 5.173,
            ic_december: 6.068,
            ic_yearly: 4.905,
          },
          {
            ic_uuid: "c84d3a4f-36b2-4d88-ae0c-4cc700d78cce",
            ic_lat: -236479,
            ic_lon: -475747,
            ic_city: "Salto de Pirapora",
            ic_states: "SÃO PAULO",
            ic_january: 5.485,
            ic_february: 5.695,
            ic_march: 4.997,
            ic_april: 4.429,
            ic_may: 3.567,
            ic_june: 3.329,
            ic_july: 3.44,
            ic_august: 4.438,
            ic_september: 4.586,
            ic_october: 5.136,
            ic_november: 5.614,
            ic_december: 6.058,
            ic_yearly: 4.731,
          },
          {
            ic_uuid: "06afe72b-fa7d-474f-986d-20530c0fbdc3",
            ic_lat: -151308,
            ic_lon: -581322,
            ic_city: "Salto do Céu",
            ic_states: "MATO GROSSO",
            ic_january: 4.922,
            ic_february: 4.855,
            ic_march: 4.728,
            ic_april: 4.662,
            ic_may: 4.181,
            ic_june: 4.291,
            ic_july: 4.408,
            ic_august: 5.182,
            ic_september: 4.933,
            ic_october: 5.013,
            ic_november: 5.215,
            ic_december: 5.1,
            ic_yearly: 4.791,
          },
          {
            ic_uuid: "c897383d-7194-46ec-ad26-e733e20c8275",
            ic_lat: -236078,
            ic_lon: -496358,
            ic_city: "Salto do Itararé",
            ic_states: "PARANÁ",
            ic_january: 5.745,
            ic_february: 5.847,
            ic_march: 5.197,
            ic_april: 4.517,
            ic_may: 3.539,
            ic_june: 3.203,
            ic_july: 3.423,
            ic_august: 4.431,
            ic_september: 4.613,
            ic_october: 5.265,
            ic_november: 5.906,
            ic_december: 6.358,
            ic_yearly: 4.837,
          },
          {
            ic_uuid: "4fffcb64-6b78-47c7-8dfd-81d561b58d75",
            ic_lat: -290955,
            ic_lon: -532138,
            ic_city: "Salto do Jacuí",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.378,
            ic_february: 5.91,
            ic_march: 5.045,
            ic_april: 3.909,
            ic_may: 2.954,
            ic_june: 2.42,
            ic_july: 2.704,
            ic_august: 3.483,
            ic_september: 3.816,
            ic_october: 4.977,
            ic_november: 6.25,
            ic_december: 6.696,
            ic_yearly: 4.545,
          },
          {
            ic_uuid: "84ad3144-9426-4a37-a8d0-90270bc66a1a",
            ic_lat: -257817,
            ic_lon: -53314,
            ic_city: "Salto do Lontra",
            ic_states: "PARANÁ",
            ic_january: 6.389,
            ic_february: 5.853,
            ic_march: 5.25,
            ic_april: 4.274,
            ic_may: 3.302,
            ic_june: 2.857,
            ic_july: 3.112,
            ic_august: 4.106,
            ic_september: 4.29,
            ic_october: 5.3,
            ic_november: 6.173,
            ic_december: 6.518,
            ic_yearly: 4.785,
          },
          {
            ic_uuid: "4a2e80de-768e-4e37-829c-75078e4569cc",
            ic_lat: -228899,
            ic_lon: -499836,
            ic_city: "Salto Grande",
            ic_states: "SÃO PAULO",
            ic_january: 5.705,
            ic_february: 5.811,
            ic_march: 5.151,
            ic_april: 4.674,
            ic_may: 3.733,
            ic_june: 3.44,
            ic_july: 3.647,
            ic_august: 4.591,
            ic_september: 4.709,
            ic_october: 5.392,
            ic_november: 5.864,
            ic_december: 6.371,
            ic_yearly: 4.924,
          },
          {
            ic_uuid: "8d25f3d7-85d4-43ea-97c0-cb23954ea6b9",
            ic_lat: -269035,
            ic_lon: -514048,
            ic_city: "Salto Veloso",
            ic_states: "SANTA CATARINA",
            ic_january: 5.701,
            ic_february: 5.259,
            ic_march: 4.816,
            ic_april: 3.962,
            ic_may: 3.039,
            ic_june: 2.719,
            ic_july: 2.942,
            ic_august: 3.848,
            ic_september: 4.036,
            ic_october: 4.829,
            ic_november: 5.892,
            ic_december: 6.042,
            ic_yearly: 4.424,
          },
          {
            ic_uuid: "10b79d25-f3af-4477-b6bb-054075ec9e1a",
            ic_lat: -129727,
            ic_lon: -385017,
            ic_city: "Salvador",
            ic_states: "BAHIA",
            ic_january: 6.443,
            ic_february: 6.292,
            ic_march: 6.038,
            ic_april: 4.839,
            ic_may: 4.107,
            ic_june: 3.862,
            ic_july: 4.063,
            ic_august: 4.76,
            ic_september: 5.523,
            ic_october: 5.87,
            ic_november: 6.098,
            ic_december: 6.482,
            ic_yearly: 5.365,
          },
          {
            ic_uuid: "5e1bd498-e5cd-464c-9e4d-1549faeb3a3a",
            ic_lat: -281238,
            ic_lon: -548378,
            ic_city: "Salvador das Missões",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.604,
            ic_february: 6.094,
            ic_march: 5.206,
            ic_april: 4.076,
            ic_may: 3.096,
            ic_june: 2.531,
            ic_july: 2.848,
            ic_august: 3.612,
            ic_september: 4.038,
            ic_october: 5.139,
            ic_november: 6.355,
            ic_december: 6.758,
            ic_yearly: 4.696,
          },
          {
            ic_uuid: "b70fdb04-e4d1-4f77-b98a-ea1c0d978a1f",
            ic_lat: -294419,
            ic_lon: -515115,
            ic_city: "Salvador do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.143,
            ic_february: 5.64,
            ic_march: 4.72,
            ic_april: 3.782,
            ic_may: 2.8,
            ic_june: 2.36,
            ic_july: 2.61,
            ic_august: 3.29,
            ic_september: 3.556,
            ic_october: 4.636,
            ic_november: 6.042,
            ic_december: 6.429,
            ic_yearly: 4.334,
          },
          {
            ic_uuid: "b5539912-6db3-494e-b5b7-69d34944ef7f",
            ic_lat: -7588,
            ic_lon: -485143,
            ic_city: "Salvaterra",
            ic_states: "PARÁ",
            ic_january: 4.697,
            ic_february: 4.385,
            ic_march: 4.408,
            ic_april: 4.481,
            ic_may: 4.841,
            ic_june: 5.046,
            ic_july: 5.103,
            ic_august: 5.455,
            ic_september: 5.708,
            ic_october: 5.67,
            ic_november: 5.614,
            ic_december: 5.155,
            ic_yearly: 5.047,
          },
          {
            ic_uuid: "f55e1988-c9a6-4fad-a50b-6f90912d22ce",
            ic_lat: -71409,
            ic_lon: -453465,
            ic_city: "Sambaíba",
            ic_states: "MARANHÃO",
            ic_january: 4.867,
            ic_february: 5.028,
            ic_march: 5.018,
            ic_april: 5.089,
            ic_may: 5.256,
            ic_june: 5.389,
            ic_july: 5.531,
            ic_august: 6.012,
            ic_september: 6.528,
            ic_october: 5.848,
            ic_november: 5.286,
            ic_december: 5.094,
            ic_yearly: 5.412,
          },
          {
            ic_uuid: "38a3d759-af98-4e8d-b14b-86ea849293bb",
            ic_lat: -53546,
            ic_lon: -478786,
            ic_city: "Sampaio",
            ic_states: "TOCANTINS",
            ic_january: 4.623,
            ic_february: 4.796,
            ic_march: 4.916,
            ic_april: 4.949,
            ic_may: 4.822,
            ic_june: 5.141,
            ic_july: 5.277,
            ic_august: 5.72,
            ic_september: 5.594,
            ic_october: 5.235,
            ic_november: 4.936,
            ic_december: 4.762,
            ic_yearly: 5.064,
          },
          {
            ic_uuid: "40a3e8ce-8389-4608-8c21-a40c895fd417",
            ic_lat: -279475,
            ic_lon: -518084,
            ic_city: "Sananduva",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.26,
            ic_february: 5.78,
            ic_march: 5.01,
            ic_april: 4.078,
            ic_may: 3.026,
            ic_june: 2.564,
            ic_july: 2.887,
            ic_august: 3.661,
            ic_september: 3.896,
            ic_october: 5.014,
            ic_november: 6.243,
            ic_december: 6.535,
            ic_yearly: 4.579,
          },
          {
            ic_uuid: "4cd9eabf-fccc-4669-b860-52136904bc1c",
            ic_lat: -162115,
            ic_lon: -503037,
            ic_city: "Sanclerlândia",
            ic_states: "GOIÁS",
            ic_january: 5.537,
            ic_february: 5.611,
            ic_march: 5.315,
            ic_april: 5.247,
            ic_may: 4.866,
            ic_june: 4.653,
            ic_july: 4.757,
            ic_august: 5.632,
            ic_september: 5.421,
            ic_october: 5.598,
            ic_november: 5.514,
            ic_december: 5.547,
            ic_yearly: 5.308,
          },
          {
            ic_uuid: "c57fc853-4919-4471-a035-c529dea0bdc5",
            ic_lat: -125399,
            ic_lon: -49926,
            ic_city: "Sandolândia",
            ic_states: "TOCANTINS",
            ic_january: 5.336,
            ic_february: 5.376,
            ic_march: 5.055,
            ic_april: 5.079,
            ic_may: 5.001,
            ic_june: 4.879,
            ic_july: 5.265,
            ic_august: 5.869,
            ic_september: 5.666,
            ic_october: 5.414,
            ic_november: 5.163,
            ic_december: 5.295,
            ic_yearly: 5.283,
          },
          {
            ic_uuid: "0f1fe2bc-c1a8-4d69-8d13-f7a9aeaa4400",
            ic_lat: -22456,
            ic_lon: -51763,
            ic_city: "Sandovalina",
            ic_states: "SÃO PAULO",
            ic_january: 5.917,
            ic_february: 5.94,
            ic_march: 5.447,
            ic_april: 4.764,
            ic_may: 3.823,
            ic_june: 3.501,
            ic_july: 3.706,
            ic_august: 4.656,
            ic_september: 4.783,
            ic_october: 5.505,
            ic_november: 6.082,
            ic_december: 6.473,
            ic_yearly: 5.05,
          },
          {
            ic_uuid: "df6188d6-59d6-4cc6-a1e3-c2f8d5445529",
            ic_lat: -286331,
            ic_lon: -491326,
            ic_city: "Sangão",
            ic_states: "SANTA CATARINA",
            ic_january: 5.775,
            ic_february: 5.318,
            ic_march: 4.534,
            ic_april: 3.789,
            ic_may: 2.987,
            ic_june: 2.496,
            ic_july: 2.71,
            ic_august: 3.44,
            ic_september: 3.538,
            ic_october: 4.378,
            ic_november: 5.721,
            ic_december: 6.053,
            ic_yearly: 4.228,
          },
          {
            ic_uuid: "d0c4107b-2a22-4465-8674-67f6ecd674fb",
            ic_lat: -83618,
            ic_lon: -365632,
            ic_city: "Sanharó",
            ic_states: "PERNAMBUCO",
            ic_january: 6.012,
            ic_february: 5.867,
            ic_march: 5.92,
            ic_april: 5.334,
            ic_may: 4.399,
            ic_june: 3.906,
            ic_july: 4.01,
            ic_august: 4.754,
            ic_september: 5.633,
            ic_october: 6.014,
            ic_november: 6.401,
            ic_december: 6.27,
            ic_yearly: 5.377,
          },
          {
            ic_uuid: "8589aee6-97db-4467-9491-a64d5f804b33",
            ic_lat: -212431,
            ic_lon: -488067,
            ic_city: "Santa Adélia",
            ic_states: "SÃO PAULO",
            ic_january: 5.791,
            ic_february: 5.869,
            ic_march: 5.187,
            ic_april: 4.82,
            ic_may: 4.094,
            ic_june: 3.847,
            ic_july: 4.062,
            ic_august: 4.973,
            ic_september: 5.063,
            ic_october: 5.607,
            ic_november: 5.862,
            ic_december: 6.127,
            ic_yearly: 5.109,
          },
          {
            ic_uuid: "1efd314e-9957-48d6-bd6a-2c9b6efc123f",
            ic_lat: -200316,
            ic_lon: -507301,
            ic_city: "Santa Albertina",
            ic_states: "SÃO PAULO",
            ic_january: 5.782,
            ic_february: 5.991,
            ic_march: 5.422,
            ic_april: 4.921,
            ic_may: 4.233,
            ic_june: 3.972,
            ic_july: 4.187,
            ic_august: 5.062,
            ic_september: 5.013,
            ic_october: 5.669,
            ic_november: 5.946,
            ic_december: 6.24,
            ic_yearly: 5.203,
          },
          {
            ic_uuid: "ed10f003-f8b2-4ce4-a0aa-80762e679636",
            ic_lat: -232659,
            ic_lon: -504292,
            ic_city: "Santa Amélia",
            ic_states: "PARANÁ",
            ic_january: 5.918,
            ic_february: 5.829,
            ic_march: 5.189,
            ic_april: 4.604,
            ic_may: 3.675,
            ic_june: 3.359,
            ic_july: 3.576,
            ic_august: 4.556,
            ic_september: 4.719,
            ic_october: 5.348,
            ic_november: 5.961,
            ic_december: 6.341,
            ic_yearly: 4.923,
          },
          {
            ic_uuid: "49bff3e2-8943-4c8d-a3c4-c00c5e161b1a",
            ic_lat: -119527,
            ic_lon: -389748,
            ic_city: "Santa Bárbara",
            ic_states: "BAHIA",
            ic_january: 6.105,
            ic_february: 5.906,
            ic_march: 5.686,
            ic_april: 4.696,
            ic_may: 3.972,
            ic_june: 3.669,
            ic_july: 3.942,
            ic_august: 4.417,
            ic_september: 5.2,
            ic_october: 5.433,
            ic_november: 5.749,
            ic_december: 6.068,
            ic_yearly: 5.07,
          },
          {
            ic_uuid: "5f864212-a890-4ffc-b96d-96b21da8eed8",
            ic_lat: -199608,
            ic_lon: -434105,
            ic_city: "Santa Bárbara",
            ic_states: "MINAS GERAIS",
            ic_january: 5.407,
            ic_february: 5.791,
            ic_march: 4.843,
            ic_april: 4.441,
            ic_may: 3.861,
            ic_june: 3.693,
            ic_july: 3.9,
            ic_august: 4.699,
            ic_september: 4.955,
            ic_october: 5.03,
            ic_november: 4.746,
            ic_december: 5.25,
            ic_yearly: 4.718,
          },
          {
            ic_uuid: "db36020d-b860-4cd4-a04a-3fa89e2ecdc8",
            ic_lat: -165789,
            ic_lon: -497015,
            ic_city: "Santa Bárbara de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.489,
            ic_february: 5.577,
            ic_march: 5.228,
            ic_april: 5.206,
            ic_may: 4.808,
            ic_june: 4.565,
            ic_july: 4.769,
            ic_august: 5.672,
            ic_september: 5.455,
            ic_october: 5.535,
            ic_november: 5.343,
            ic_december: 5.522,
            ic_yearly: 5.264,
          },
          {
            ic_uuid: "edf0c543-ddd4-4147-92f0-b6fa922f7078",
            ic_lat: -19978,
            ic_lon: -4214,
            ic_city: "Santa Bárbara do Leste",
            ic_states: "MINAS GERAIS",
            ic_january: 5.651,
            ic_february: 5.795,
            ic_march: 4.795,
            ic_april: 4.372,
            ic_may: 3.741,
            ic_june: 3.616,
            ic_july: 3.742,
            ic_august: 4.495,
            ic_september: 4.889,
            ic_october: 4.925,
            ic_november: 4.711,
            ic_december: 5.465,
            ic_yearly: 4.683,
          },
          {
            ic_uuid: "f32a66f4-9eda-4abe-8a58-87bcd4ad9a14",
            ic_lat: -219597,
            ic_lon: -437031,
            ic_city: "Santa Bárbara do Monte Verde",
            ic_states: "MINAS GERAIS",
            ic_january: 5.334,
            ic_february: 5.719,
            ic_march: 4.719,
            ic_april: 4.2,
            ic_may: 3.568,
            ic_june: 3.441,
            ic_july: 3.528,
            ic_august: 4.368,
            ic_september: 4.521,
            ic_october: 4.709,
            ic_november: 4.606,
            ic_december: 5.296,
            ic_yearly: 4.501,
          },
          {
            ic_uuid: "390ab437-69ad-45f7-8026-92de9511acb5",
            ic_lat: -12265,
            ic_lon: -482955,
            ic_city: "Santa Bárbara do Pará",
            ic_states: "PARÁ",
            ic_january: 4.295,
            ic_february: 4.171,
            ic_march: 4.121,
            ic_april: 4.221,
            ic_may: 4.483,
            ic_june: 4.817,
            ic_july: 4.947,
            ic_august: 5.216,
            ic_september: 5.306,
            ic_october: 5.142,
            ic_november: 4.943,
            ic_december: 4.521,
            ic_yearly: 4.682,
          },
          {
            ic_uuid: "1fbc55a5-331d-4be4-ae7e-f2dbc94adac8",
            ic_lat: -283658,
            ic_lon: -532515,
            ic_city: "Santa Bárbara do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.379,
            ic_february: 5.923,
            ic_march: 5.031,
            ic_april: 4.013,
            ic_may: 3.037,
            ic_june: 2.509,
            ic_july: 2.823,
            ic_august: 3.571,
            ic_september: 3.881,
            ic_october: 5.051,
            ic_november: 6.203,
            ic_december: 6.622,
            ic_yearly: 4.587,
          },
          {
            ic_uuid: "42ad4e3d-0152-463c-bf37-5fb93199e490",
            ic_lat: -212436,
            ic_lon: -435611,
            ic_city: "Santa Bárbara do Tugúrio",
            ic_states: "MINAS GERAIS",
            ic_january: 5.301,
            ic_february: 5.634,
            ic_march: 4.679,
            ic_april: 4.193,
            ic_may: 3.563,
            ic_june: 3.439,
            ic_july: 3.626,
            ic_august: 4.467,
            ic_september: 4.697,
            ic_october: 4.85,
            ic_november: 4.547,
            ic_december: 5.37,
            ic_yearly: 4.53,
          },
          {
            ic_uuid: "72979ac1-fdc1-481b-8c86-7352ec0f5b58",
            ic_lat: -227557,
            ic_lon: -474147,
            ic_city: "Santa Bárbara d'Oeste",
            ic_states: "SÃO PAULO",
            ic_january: 5.668,
            ic_february: 5.831,
            ic_march: 5.146,
            ic_april: 4.623,
            ic_may: 3.802,
            ic_june: 3.537,
            ic_july: 3.688,
            ic_august: 4.623,
            ic_september: 4.826,
            ic_october: 5.461,
            ic_november: 5.703,
            ic_december: 6.168,
            ic_yearly: 4.923,
          },
          {
            ic_uuid: "dd76005f-ef14-49bb-b1ea-f438f1522824",
            ic_lat: -233938,
            ic_lon: -458879,
            ic_city: "Santa Branca",
            ic_states: "SÃO PAULO",
            ic_january: 5.473,
            ic_february: 5.746,
            ic_march: 4.864,
            ic_april: 4.336,
            ic_may: 3.463,
            ic_june: 3.22,
            ic_july: 3.322,
            ic_august: 4.254,
            ic_september: 4.36,
            ic_october: 5.057,
            ic_november: 5.342,
            ic_december: 5.875,
            ic_yearly: 4.609,
          },
          {
            ic_uuid: "678d1015-0cf5-4d16-8f64-5bf4bf46d94c",
            ic_lat: -9735,
            ic_lon: -381288,
            ic_city: "Santa Brígida",
            ic_states: "BAHIA",
            ic_january: 6.172,
            ic_february: 6.029,
            ic_march: 5.865,
            ic_april: 5.201,
            ic_may: 4.372,
            ic_june: 3.925,
            ic_july: 4.135,
            ic_august: 4.656,
            ic_september: 5.499,
            ic_october: 5.84,
            ic_november: 6.339,
            ic_december: 6.29,
            ic_yearly: 5.36,
          },
          {
            ic_uuid: "17dafab6-e4a6-4a2e-8ee1-f0ea1de7a147",
            ic_lat: -119777,
            ic_lon: -552774,
            ic_city: "Santa Carmem",
            ic_states: "MATO GROSSO",
            ic_january: 5.153,
            ic_february: 5.036,
            ic_march: 4.942,
            ic_april: 4.884,
            ic_may: 4.759,
            ic_june: 4.624,
            ic_july: 4.951,
            ic_august: 5.258,
            ic_september: 5.132,
            ic_october: 5.297,
            ic_november: 5.145,
            ic_december: 5.251,
            ic_yearly: 5.036,
          },
          {
            ic_uuid: "db85c4a6-b0c8-48d8-80b4-2e9a0f8ce828",
            ic_lat: -7743,
            ic_lon: -358794,
            ic_city: "Santa Cecília",
            ic_states: "PARAÍBA",
            ic_january: 5.725,
            ic_february: 5.763,
            ic_march: 5.777,
            ic_april: 5.234,
            ic_may: 4.597,
            ic_june: 4.149,
            ic_july: 4.332,
            ic_august: 5.001,
            ic_september: 5.616,
            ic_october: 5.871,
            ic_november: 6.1,
            ic_december: 5.906,
            ic_yearly: 5.339,
          },
          {
            ic_uuid: "0d5ef9da-c064-474d-9396-59ac09da1850",
            ic_lat: -269597,
            ic_lon: -504256,
            ic_city: "Santa Cecília",
            ic_states: "SANTA CATARINA",
            ic_january: 5.506,
            ic_february: 5.201,
            ic_march: 4.591,
            ic_april: 3.797,
            ic_may: 2.944,
            ic_june: 2.629,
            ic_july: 2.851,
            ic_august: 3.753,
            ic_september: 3.822,
            ic_october: 4.499,
            ic_november: 5.617,
            ic_december: 5.836,
            ic_yearly: 4.254,
          },
          {
            ic_uuid: "220e7fd2-21c3-4972-bc0b-02170f814d02",
            ic_lat: -23517,
            ic_lon: -507839,
            ic_city: "Santa Cecília do Pavão",
            ic_states: "PARANÁ",
            ic_january: 5.906,
            ic_february: 5.74,
            ic_march: 5.163,
            ic_april: 4.584,
            ic_may: 3.644,
            ic_june: 3.346,
            ic_july: 3.542,
            ic_august: 4.496,
            ic_september: 4.651,
            ic_october: 5.271,
            ic_november: 5.989,
            ic_december: 6.298,
            ic_yearly: 4.886,
          },
          {
            ic_uuid: "cc29250f-7184-4bfe-ba9c-6f64b0f9e1ab",
            ic_lat: -281613,
            ic_lon: -519284,
            ic_city: "Santa Cecília do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.205,
            ic_february: 5.726,
            ic_march: 4.931,
            ic_april: 4.005,
            ic_may: 2.994,
            ic_june: 2.515,
            ic_july: 2.862,
            ic_august: 3.648,
            ic_september: 3.854,
            ic_october: 4.978,
            ic_november: 6.178,
            ic_december: 6.505,
            ic_yearly: 4.533,
          },
          {
            ic_uuid: "345a9884-c4c2-469d-8023-4fcf76746570",
            ic_lat: -29469,
            ic_lon: -520879,
            ic_city: "Santa Clara do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.078,
            ic_february: 5.709,
            ic_march: 4.785,
            ic_april: 3.794,
            ic_may: 2.816,
            ic_june: 2.336,
            ic_july: 2.556,
            ic_august: 3.287,
            ic_september: 3.59,
            ic_october: 4.62,
            ic_november: 6.062,
            ic_december: 6.456,
            ic_yearly: 4.341,
          },
          {
            ic_uuid: "448d6d7f-7979-481c-a97f-0d16511d469d",
            ic_lat: -20092,
            ic_lon: -509279,
            ic_city: "Santa Clara d'Oeste",
            ic_states: "SÃO PAULO",
            ic_january: 5.808,
            ic_february: 6.009,
            ic_march: 5.485,
            ic_april: 4.965,
            ic_may: 4.188,
            ic_june: 3.859,
            ic_july: 4.107,
            ic_august: 4.979,
            ic_september: 4.958,
            ic_october: 5.635,
            ic_november: 6.001,
            ic_december: 6.338,
            ic_yearly: 5.194,
          },
          {
            ic_uuid: "ad80e4eb-5255-42b6-96c4-694469abbcbe",
            ic_lat: -65329,
            ic_lon: -380582,
            ic_city: "Santa Cruz",
            ic_states: "PARAÍBA",
            ic_january: 6.08,
            ic_february: 6.049,
            ic_march: 6.133,
            ic_april: 5.769,
            ic_may: 5.294,
            ic_june: 4.992,
            ic_july: 5.27,
            ic_august: 6.0,
            ic_september: 6.507,
            ic_october: 6.666,
            ic_november: 6.763,
            ic_december: 6.286,
            ic_yearly: 5.984,
          },
          {
            ic_uuid: "a1907d95-ef18-4f25-9452-1ec64d63eb3e",
            ic_lat: -8241,
            ic_lon: -403376,
            ic_city: "Santa Cruz",
            ic_states: "PERNAMBUCO",
            ic_january: 5.87,
            ic_february: 5.596,
            ic_march: 5.666,
            ic_april: 5.101,
            ic_may: 4.664,
            ic_june: 4.457,
            ic_july: 4.827,
            ic_august: 5.694,
            ic_september: 6.378,
            ic_october: 6.382,
            ic_november: 6.361,
            ic_december: 6.103,
            ic_yearly: 5.591,
          },
          {
            ic_uuid: "230550db-f40e-425a-845e-322333b19808",
            ic_lat: -62252,
            ic_lon: -360196,
            ic_city: "Santa Cruz",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.683,
            ic_february: 5.831,
            ic_march: 5.768,
            ic_april: 5.4,
            ic_may: 4.921,
            ic_june: 4.444,
            ic_july: 4.63,
            ic_august: 5.281,
            ic_september: 5.798,
            ic_october: 5.997,
            ic_november: 6.114,
            ic_december: 5.748,
            ic_yearly: 5.468,
          },
          {
            ic_uuid: "2cbdff49-b1ca-4104-8bc5-903d6127f64c",
            ic_lat: -162821,
            ic_lon: -390228,
            ic_city: "Santa Cruz Cabrália",
            ic_states: "BAHIA",
            ic_january: 6.19,
            ic_february: 6.28,
            ic_march: 5.613,
            ic_april: 4.687,
            ic_may: 3.971,
            ic_june: 3.682,
            ic_july: 3.866,
            ic_august: 4.565,
            ic_september: 5.064,
            ic_october: 5.396,
            ic_november: 5.402,
            ic_december: 6.224,
            ic_yearly: 5.078,
          },
          {
            ic_uuid: "d37992c3-df12-4bca-bc51-5afe89679fc8",
            ic_lat: -78211,
            ic_lon: -381522,
            ic_city: "Santa Cruz da Baixa Verde",
            ic_states: "PERNAMBUCO",
            ic_january: 6.137,
            ic_february: 6.07,
            ic_march: 6.101,
            ic_april: 5.707,
            ic_may: 4.953,
            ic_june: 4.617,
            ic_july: 4.876,
            ic_august: 5.751,
            ic_september: 6.529,
            ic_october: 6.587,
            ic_november: 6.713,
            ic_december: 6.332,
            ic_yearly: 5.864,
          },
          {
            ic_uuid: "12e6df53-1dc0-4a06-a9b3-963e186c8aad",
            ic_lat: -22141,
            ic_lon: -474516,
            ic_city: "Santa Cruz da Conceição",
            ic_states: "SÃO PAULO",
            ic_january: 5.585,
            ic_february: 5.865,
            ic_march: 5.138,
            ic_april: 4.715,
            ic_may: 3.939,
            ic_june: 3.703,
            ic_july: 3.869,
            ic_august: 4.807,
            ic_september: 4.955,
            ic_october: 5.531,
            ic_november: 5.724,
            ic_december: 6.008,
            ic_yearly: 4.987,
          },
          {
            ic_uuid: "eab252b8-c410-4c66-afd0-7d22ba0bbc3b",
            ic_lat: -212955,
            ic_lon: -474308,
            ic_city: "Santa Cruz da Esperança",
            ic_states: "SÃO PAULO",
            ic_january: 5.649,
            ic_february: 5.856,
            ic_march: 5.135,
            ic_april: 4.81,
            ic_may: 4.085,
            ic_june: 3.856,
            ic_july: 4.086,
            ic_august: 4.952,
            ic_september: 4.976,
            ic_october: 5.55,
            ic_november: 5.71,
            ic_december: 6.031,
            ic_yearly: 5.058,
          },
          {
            ic_uuid: "da9c2c6f-73e4-4042-a8a6-4b709ae1f148",
            ic_lat: -149644,
            ic_lon: -398118,
            ic_city: "Santa Cruz da Vitória",
            ic_states: "BAHIA",
            ic_january: 5.848,
            ic_february: 5.823,
            ic_march: 5.365,
            ic_april: 4.57,
            ic_may: 3.86,
            ic_june: 3.502,
            ic_july: 3.714,
            ic_august: 4.223,
            ic_september: 4.879,
            ic_october: 5.042,
            ic_november: 5.206,
            ic_december: 5.977,
            ic_yearly: 4.834,
          },
          {
            ic_uuid: "3bf76736-cdaa-4e4b-8be4-f695f436632f",
            ic_lat: -218239,
            ic_lon: -472484,
            ic_city: "Santa Cruz das Palmeiras",
            ic_states: "SÃO PAULO",
            ic_january: 5.607,
            ic_february: 5.8,
            ic_march: 5.104,
            ic_april: 4.766,
            ic_may: 4.008,
            ic_june: 3.746,
            ic_july: 3.933,
            ic_august: 4.877,
            ic_september: 4.965,
            ic_october: 5.524,
            ic_november: 5.718,
            ic_december: 5.963,
            ic_yearly: 5.001,
          },
          {
            ic_uuid: "a35dfd0d-c010-4010-9980-ac711a2ac25e",
            ic_lat: -17316,
            ic_lon: -484813,
            ic_city: "Santa Cruz de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.667,
            ic_february: 5.775,
            ic_march: 5.253,
            ic_april: 5.204,
            ic_may: 4.76,
            ic_june: 4.546,
            ic_july: 4.792,
            ic_august: 5.661,
            ic_september: 5.411,
            ic_october: 5.697,
            ic_november: 5.397,
            ic_december: 5.667,
            ic_yearly: 5.319,
          },
          {
            ic_uuid: "8389a257-17c7-4cde-a3f2-ebbd0ed5f5c8",
            ic_lat: -211246,
            ic_lon: -442206,
            ic_city: "Santa Cruz de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.55,
            ic_february: 5.768,
            ic_march: 4.899,
            ic_april: 4.444,
            ic_may: 3.833,
            ic_june: 3.65,
            ic_july: 3.894,
            ic_august: 4.824,
            ic_september: 5.037,
            ic_october: 5.24,
            ic_november: 4.993,
            ic_december: 5.547,
            ic_yearly: 4.806,
          },
          {
            ic_uuid: "413e9a8b-1ffe-4e43-8980-9cc4b9522218",
            ic_lat: -229579,
            ic_lon: -532946,
            ic_city: "Santa Cruz de Monte Castelo",
            ic_states: "PARANÁ",
            ic_january: 5.937,
            ic_february: 5.923,
            ic_march: 5.424,
            ic_april: 4.627,
            ic_may: 3.678,
            ic_june: 3.321,
            ic_july: 3.513,
            ic_august: 4.456,
            ic_september: 4.718,
            ic_october: 5.439,
            ic_november: 6.155,
            ic_december: 6.477,
            ic_yearly: 4.972,
          },
          {
            ic_uuid: "5c394aea-2157-494e-acff-15dbf97cbc3e",
            ic_lat: -160976,
            ic_lon: -417486,
            ic_city: "Santa Cruz de Salinas",
            ic_states: "MINAS GERAIS",
            ic_january: 6.006,
            ic_february: 6.282,
            ic_march: 5.402,
            ic_april: 4.721,
            ic_may: 4.069,
            ic_june: 3.736,
            ic_july: 4.006,
            ic_august: 4.692,
            ic_september: 5.572,
            ic_october: 5.654,
            ic_november: 5.301,
            ic_december: 6.087,
            ic_yearly: 5.127,
          },
          {
            ic_uuid: "a2c3e829-7876-4ff9-8611-b2ae9cc74cd0",
            ic_lat: -6614,
            ic_lon: -491775,
            ic_city: "Santa Cruz do Arari",
            ic_states: "PARÁ",
            ic_january: 4.322,
            ic_february: 4.128,
            ic_march: 4.24,
            ic_april: 4.311,
            ic_may: 4.688,
            ic_june: 4.914,
            ic_july: 5.05,
            ic_august: 5.197,
            ic_september: 5.3,
            ic_october: 5.096,
            ic_november: 4.989,
            ic_december: 4.531,
            ic_yearly: 4.73,
          },
          {
            ic_uuid: "fd166f78-d7ba-4c30-948a-c69129111e6e",
            ic_lat: -79484,
            ic_lon: -362064,
            ic_city: "Santa Cruz do Capibaribe",
            ic_states: "PERNAMBUCO",
            ic_january: 5.889,
            ic_february: 5.886,
            ic_march: 5.862,
            ic_april: 5.366,
            ic_may: 4.63,
            ic_june: 4.199,
            ic_july: 4.337,
            ic_august: 5.027,
            ic_september: 5.714,
            ic_october: 6.015,
            ic_november: 6.248,
            ic_december: 5.982,
            ic_yearly: 5.429,
          },
          {
            ic_uuid: "6f2b0861-9a84-46c9-9b6f-7d0c06894108",
            ic_lat: -202377,
            ic_lon: -428172,
            ic_city: "Santa Cruz do Escalvado",
            ic_states: "MINAS GERAIS",
            ic_january: 5.697,
            ic_february: 6.039,
            ic_march: 5.054,
            ic_april: 4.573,
            ic_may: 3.846,
            ic_june: 3.644,
            ic_july: 3.902,
            ic_august: 4.674,
            ic_september: 4.987,
            ic_october: 5.122,
            ic_november: 4.833,
            ic_december: 5.476,
            ic_yearly: 4.82,
          },
          {
            ic_uuid: "4aa3e78e-63e4-49a8-bd80-4c507e37a1f5",
            ic_lat: -71789,
            ic_lon: -417613,
            ic_city: "Santa Cruz do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.568,
            ic_february: 5.457,
            ic_march: 5.614,
            ic_april: 5.345,
            ic_may: 5.296,
            ic_june: 5.242,
            ic_july: 5.6,
            ic_august: 6.193,
            ic_september: 6.643,
            ic_october: 6.523,
            ic_november: 6.454,
            ic_december: 5.984,
            ic_yearly: 5.826,
          },
          {
            ic_uuid: "05060d44-0738-4425-9c57-6e1f3b07d508",
            ic_lat: -228992,
            ic_lon: -496359,
            ic_city: "Santa Cruz do Rio Pardo",
            ic_states: "SÃO PAULO",
            ic_january: 5.671,
            ic_february: 5.799,
            ic_march: 5.163,
            ic_april: 4.676,
            ic_may: 3.723,
            ic_june: 3.459,
            ic_july: 3.651,
            ic_august: 4.641,
            ic_september: 4.73,
            ic_october: 5.429,
            ic_november: 5.867,
            ic_december: 6.295,
            ic_yearly: 4.925,
          },
          {
            ic_uuid: "5e7d0e9c-4fea-4f3b-9a36-825926cb1466",
            ic_lat: -297225,
            ic_lon: -524348,
            ic_city: "Santa Cruz do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.131,
            ic_february: 5.722,
            ic_march: 4.833,
            ic_april: 3.783,
            ic_may: 2.796,
            ic_june: 2.322,
            ic_july: 2.54,
            ic_august: 3.237,
            ic_september: 3.588,
            ic_october: 4.677,
            ic_november: 6.092,
            ic_december: 6.547,
            ic_yearly: 4.356,
          },
          {
            ic_uuid: "917ac2b4-0b45-42b7-8e59-314059aa30a2",
            ic_lat: -101536,
            ic_lon: -523958,
            ic_city: "Santa Cruz do Xingu",
            ic_states: "MATO GROSSO",
            ic_january: 4.846,
            ic_february: 4.999,
            ic_march: 4.789,
            ic_april: 4.599,
            ic_may: 4.741,
            ic_june: 4.748,
            ic_july: 4.928,
            ic_august: 5.56,
            ic_september: 5.207,
            ic_october: 5.141,
            ic_november: 5.034,
            ic_december: 5.062,
            ic_yearly: 4.971,
          },
          {
            ic_uuid: "f8f9fcdc-7764-42e5-b2ef-77353feb3498",
            ic_lat: -58062,
            ic_lon: -41951,
            ic_city: "Santa Cruz dos Milagres",
            ic_states: "PIAUÍ",
            ic_january: 5.199,
            ic_february: 5.393,
            ic_march: 5.362,
            ic_april: 5.29,
            ic_may: 5.279,
            ic_june: 5.281,
            ic_july: 5.552,
            ic_august: 6.242,
            ic_september: 6.579,
            ic_october: 6.407,
            ic_november: 6.235,
            ic_december: 5.73,
            ic_yearly: 5.712,
          },
          {
            ic_uuid: "cc9d9865-13d6-49d2-a2a1-ac0a40cbc43b",
            ic_lat: -188239,
            ic_lon: -424392,
            ic_city: "Santa Efigênia de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.582,
            ic_february: 5.803,
            ic_march: 4.863,
            ic_april: 4.308,
            ic_may: 3.692,
            ic_june: 3.547,
            ic_july: 3.665,
            ic_august: 4.368,
            ic_september: 4.849,
            ic_october: 4.996,
            ic_november: 4.568,
            ic_december: 5.405,
            ic_yearly: 4.637,
          },
          {
            ic_uuid: "31a64b31-10ff-4c15-928c-be32844d7393",
            ic_lat: -21463,
            ic_lon: -48391,
            ic_city: "Santa Ernestina",
            ic_states: "SÃO PAULO",
            ic_january: 5.735,
            ic_february: 5.899,
            ic_march: 5.142,
            ic_april: 4.772,
            ic_may: 4.054,
            ic_june: 3.814,
            ic_july: 4.007,
            ic_august: 4.871,
            ic_september: 4.984,
            ic_october: 5.576,
            ic_november: 5.817,
            ic_december: 6.094,
            ic_yearly: 5.064,
          },
          {
            ic_uuid: "13cc6bdd-599a-4c24-a736-d603e3b108b6",
            ic_lat: -230405,
            ic_lon: -518084,
            ic_city: "Santa Fé",
            ic_states: "PARANÁ",
            ic_january: 5.914,
            ic_february: 5.939,
            ic_march: 5.42,
            ic_april: 4.669,
            ic_may: 3.693,
            ic_june: 3.423,
            ic_july: 3.595,
            ic_august: 4.569,
            ic_september: 4.75,
            ic_october: 5.472,
            ic_november: 6.149,
            ic_december: 6.42,
            ic_yearly: 5.001,
          },
          {
            ic_uuid: "0e03c941-7517-481c-85c7-811dd0b962d8",
            ic_lat: -157669,
            ic_lon: -511041,
            ic_city: "Santa Fé de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.515,
            ic_february: 5.637,
            ic_march: 5.28,
            ic_april: 5.276,
            ic_may: 4.895,
            ic_june: 4.581,
            ic_july: 4.735,
            ic_august: 5.429,
            ic_september: 5.406,
            ic_october: 5.501,
            ic_november: 5.348,
            ic_december: 5.527,
            ic_yearly: 5.261,
          },
          {
            ic_uuid: "a72174b1-a39b-4563-a94e-abf504d15166",
            ic_lat: -166937,
            ic_lon: -454148,
            ic_city: "Santa Fé de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 6.266,
            ic_february: 6.546,
            ic_march: 5.568,
            ic_april: 5.512,
            ic_may: 4.987,
            ic_june: 4.679,
            ic_july: 5.01,
            ic_august: 5.84,
            ic_september: 5.949,
            ic_october: 6.103,
            ic_november: 5.495,
            ic_december: 5.976,
            ic_yearly: 5.661,
          },
          {
            ic_uuid: "b42a7eaa-30e6-4de4-8e38-96377c9e05a6",
            ic_lat: -71543,
            ic_lon: -486921,
            ic_city: "Santa Fé do Araguaia",
            ic_states: "TOCANTINS",
            ic_january: 4.668,
            ic_february: 4.816,
            ic_march: 4.634,
            ic_april: 4.692,
            ic_may: 4.905,
            ic_june: 5.137,
            ic_july: 5.24,
            ic_august: 5.854,
            ic_september: 5.615,
            ic_october: 4.998,
            ic_november: 4.712,
            ic_december: 4.618,
            ic_yearly: 4.991,
          },
          {
            ic_uuid: "778409df-79b7-433a-ac6c-2a267027bc83",
            ic_lat: -202087,
            ic_lon: -509324,
            ic_city: "Santa Fé do Sul",
            ic_states: "SÃO PAULO",
            ic_january: 5.823,
            ic_february: 6.001,
            ic_march: 5.478,
            ic_april: 4.979,
            ic_may: 4.195,
            ic_june: 3.867,
            ic_july: 4.1,
            ic_august: 4.968,
            ic_september: 4.979,
            ic_october: 5.657,
            ic_november: 6.038,
            ic_december: 6.367,
            ic_yearly: 5.204,
          },
          {
            ic_uuid: "200587ea-6ca4-4499-bfde-7fc5b05216da",
            ic_lat: -81605,
            ic_lon: -406119,
            ic_city: "Santa Filomena",
            ic_states: "PERNAMBUCO",
            ic_january: 5.929,
            ic_february: 5.709,
            ic_march: 5.733,
            ic_april: 5.26,
            ic_may: 4.899,
            ic_june: 4.749,
            ic_july: 5.083,
            ic_august: 6.043,
            ic_september: 6.635,
            ic_october: 6.571,
            ic_november: 6.467,
            ic_december: 6.178,
            ic_yearly: 5.771,
          },
          {
            ic_uuid: "87e8b46c-75e7-40cc-ba63-924c1d582aa4",
            ic_lat: -91175,
            ic_lon: -459182,
            ic_city: "Santa Filomena",
            ic_states: "PIAUÍ",
            ic_january: 5.344,
            ic_february: 5.246,
            ic_march: 5.111,
            ic_april: 5.172,
            ic_may: 5.286,
            ic_june: 5.306,
            ic_july: 5.459,
            ic_august: 6.174,
            ic_september: 6.401,
            ic_october: 5.812,
            ic_november: 5.376,
            ic_december: 5.408,
            ic_yearly: 5.508,
          },
          {
            ic_uuid: "872a74e3-7358-4aac-b7a5-aaf53732f750",
            ic_lat: -54981,
            ic_lon: -445591,
            ic_city: "Santa Filomena do Maranhão",
            ic_states: "MARANHÃO",
            ic_january: 4.761,
            ic_february: 4.81,
            ic_march: 4.925,
            ic_april: 4.833,
            ic_may: 4.923,
            ic_june: 5.203,
            ic_july: 5.467,
            ic_august: 6.083,
            ic_september: 6.286,
            ic_october: 5.668,
            ic_november: 5.346,
            ic_december: 5.04,
            ic_yearly: 5.279,
          },
          {
            ic_uuid: "58dc2d1a-e977-4f11-84b4-ae8b9e232606",
            ic_lat: -224577,
            ic_lon: -475276,
            ic_city: "Santa Gertrudes",
            ic_states: "SÃO PAULO",
            ic_january: 5.602,
            ic_february: 5.707,
            ic_march: 5.074,
            ic_april: 4.64,
            ic_may: 3.816,
            ic_june: 3.551,
            ic_july: 3.749,
            ic_august: 4.725,
            ic_september: 4.834,
            ic_october: 5.45,
            ic_november: 5.617,
            ic_december: 6.029,
            ic_yearly: 4.899,
          },
          {
            ic_uuid: "06bc5112-e498-4a18-a80e-c65b77c41987",
            ic_lat: -2234,
            ic_lon: -453011,
            ic_city: "Santa Helena",
            ic_states: "MARANHÃO",
            ic_january: 4.599,
            ic_february: 4.486,
            ic_march: 4.373,
            ic_april: 4.372,
            ic_may: 4.569,
            ic_june: 4.872,
            ic_july: 4.938,
            ic_august: 5.5,
            ic_september: 5.746,
            ic_october: 5.469,
            ic_november: 5.217,
            ic_december: 4.823,
            ic_yearly: 4.913,
          },
          {
            ic_uuid: "a049c24e-4782-40e9-9280-31f8c9e7df16",
            ic_lat: -6718,
            ic_lon: -38643,
            ic_city: "Santa Helena",
            ic_states: "PARAÍBA",
            ic_january: 6.111,
            ic_february: 6.077,
            ic_march: 6.08,
            ic_april: 5.744,
            ic_may: 5.248,
            ic_june: 5.052,
            ic_july: 5.38,
            ic_august: 6.05,
            ic_september: 6.451,
            ic_october: 6.602,
            ic_november: 6.747,
            ic_december: 6.337,
            ic_yearly: 5.99,
          },
          {
            ic_uuid: "fe7a69c5-0c38-4d47-a838-1cff5c1a1e6c",
            ic_lat: -24859,
            ic_lon: -543365,
            ic_city: "Santa Helena",
            ic_states: "PARANÁ",
            ic_january: 6.423,
            ic_february: 5.955,
            ic_march: 5.404,
            ic_april: 4.287,
            ic_may: 3.302,
            ic_june: 2.905,
            ic_july: 3.113,
            ic_august: 4.076,
            ic_september: 4.35,
            ic_october: 5.348,
            ic_november: 6.193,
            ic_december: 6.671,
            ic_yearly: 4.836,
          },
          {
            ic_uuid: "1c31c982-9774-4e96-92ca-7ff91299ad37",
            ic_lat: -269374,
            ic_lon: -536219,
            ic_city: "Santa Helena",
            ic_states: "SANTA CATARINA",
            ic_january: 6.271,
            ic_february: 5.779,
            ic_march: 5.088,
            ic_april: 4.059,
            ic_may: 3.131,
            ic_june: 2.629,
            ic_july: 2.919,
            ic_august: 3.772,
            ic_september: 4.031,
            ic_october: 5.117,
            ic_november: 6.052,
            ic_december: 6.391,
            ic_yearly: 4.603,
          },
          {
            ic_uuid: "fec39937-dd0c-4ec5-9f4d-0635513cf70d",
            ic_lat: -17812,
            ic_lon: -505981,
            ic_city: "Santa Helena de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.649,
            ic_february: 5.751,
            ic_march: 5.278,
            ic_april: 5.077,
            ic_may: 4.697,
            ic_june: 4.371,
            ic_july: 4.585,
            ic_august: 5.55,
            ic_september: 5.307,
            ic_october: 5.519,
            ic_november: 5.552,
            ic_december: 5.783,
            ic_yearly: 5.26,
          },
          {
            ic_uuid: "a2a74e3d-ca83-4687-9c82-337d8536299c",
            ic_lat: -169373,
            ic_lon: -406842,
            ic_city: "Santa Helena de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.943,
            ic_february: 6.167,
            ic_march: 5.285,
            ic_april: 4.566,
            ic_may: 3.773,
            ic_june: 3.45,
            ic_july: 3.678,
            ic_august: 4.34,
            ic_september: 5.064,
            ic_october: 5.284,
            ic_november: 5.093,
            ic_december: 6.032,
            ic_yearly: 4.89,
          },
          {
            ic_uuid: "609f6aff-79c3-48da-8fe7-4a8637a5532e",
            ic_lat: -132954,
            ic_lon: -398215,
            ic_city: "Santa Inês",
            ic_states: "BAHIA",
            ic_january: 5.754,
            ic_february: 5.774,
            ic_march: 5.509,
            ic_april: 4.453,
            ic_may: 3.814,
            ic_june: 3.439,
            ic_july: 3.686,
            ic_august: 4.223,
            ic_september: 5.092,
            ic_october: 5.24,
            ic_november: 5.38,
            ic_december: 5.916,
            ic_yearly: 4.856,
          },
          {
            ic_uuid: "a6249b70-c186-4e9b-b042-3fc14c10b402",
            ic_lat: -36515,
            ic_lon: -453778,
            ic_city: "Santa Inês",
            ic_states: "MARANHÃO",
            ic_january: 4.642,
            ic_february: 4.871,
            ic_march: 4.921,
            ic_april: 4.877,
            ic_may: 4.744,
            ic_june: 4.895,
            ic_july: 4.981,
            ic_august: 5.512,
            ic_september: 5.784,
            ic_october: 5.49,
            ic_november: 5.138,
            ic_december: 4.951,
            ic_yearly: 5.067,
          },
          {
            ic_uuid: "b2f83755-e544-4424-b61c-d8c173d6c934",
            ic_lat: -76268,
            ic_lon: -385593,
            ic_city: "Santa Inês",
            ic_states: "PARAÍBA",
            ic_january: 6.212,
            ic_february: 6.063,
            ic_march: 6.017,
            ic_april: 5.688,
            ic_may: 5.069,
            ic_june: 4.703,
            ic_july: 4.938,
            ic_august: 5.776,
            ic_september: 6.53,
            ic_october: 6.556,
            ic_november: 6.78,
            ic_december: 6.437,
            ic_yearly: 5.897,
          },
          {
            ic_uuid: "47f8d349-5b4d-411a-a7a5-3f1da79d6992",
            ic_lat: -226407,
            ic_lon: -519029,
            ic_city: "Santa Inês",
            ic_states: "PARANÁ",
            ic_january: 6.011,
            ic_february: 5.997,
            ic_march: 5.492,
            ic_april: 4.743,
            ic_may: 3.771,
            ic_june: 3.461,
            ic_july: 3.647,
            ic_august: 4.597,
            ic_september: 4.753,
            ic_october: 5.481,
            ic_november: 6.137,
            ic_december: 6.462,
            ic_yearly: 5.046,
          },
          {
            ic_uuid: "5248de19-b029-41d5-999e-d270ea7d284c",
            ic_lat: -152978,
            ic_lon: -49425,
            ic_city: "Santa Isabel",
            ic_states: "GOIÁS",
            ic_january: 5.361,
            ic_february: 5.653,
            ic_march: 5.212,
            ic_april: 5.148,
            ic_may: 4.921,
            ic_june: 4.63,
            ic_july: 4.822,
            ic_august: 5.78,
            ic_september: 5.557,
            ic_october: 5.522,
            ic_november: 5.208,
            ic_december: 5.393,
            ic_yearly: 5.267,
          },
          {
            ic_uuid: "6ab06d9e-fb7c-4cae-b7d2-69c941441b3b",
            ic_lat: -233177,
            ic_lon: -462241,
            ic_city: "Santa Isabel",
            ic_states: "SÃO PAULO",
            ic_january: 5.248,
            ic_february: 5.578,
            ic_march: 4.739,
            ic_april: 4.19,
            ic_may: 3.383,
            ic_june: 3.167,
            ic_july: 3.23,
            ic_august: 4.223,
            ic_september: 4.245,
            ic_october: 4.792,
            ic_november: 5.059,
            ic_december: 5.689,
            ic_yearly: 4.462,
          },
          {
            ic_uuid: "19ecfefd-5b16-46b5-b9e5-3d73b5c8c08f",
            ic_lat: -229992,
            ic_lon: -531976,
            ic_city: "Santa Isabel do Ivaí",
            ic_states: "PARANÁ",
            ic_january: 5.898,
            ic_february: 5.887,
            ic_march: 5.397,
            ic_april: 4.617,
            ic_may: 3.683,
            ic_june: 3.319,
            ic_july: 3.522,
            ic_august: 4.471,
            ic_september: 4.716,
            ic_october: 5.429,
            ic_november: 6.147,
            ic_december: 6.452,
            ic_yearly: 4.961,
          },
          {
            ic_uuid: "169edb7b-cef8-41b7-8ee5-1f62ace20e6c",
            ic_lat: -4112,
            ic_lon: -650097,
            ic_city: "Santa Isabel do Rio Negro",
            ic_states: "AMAZONAS",
            ic_january: 4.576,
            ic_february: 4.723,
            ic_march: 4.571,
            ic_april: 4.387,
            ic_may: 3.963,
            ic_june: 4.297,
            ic_july: 4.339,
            ic_august: 4.884,
            ic_september: 5.132,
            ic_october: 5.015,
            ic_november: 4.775,
            ic_december: 4.582,
            ic_yearly: 4.604,
          },
          {
            ic_uuid: "4623abc9-d164-47dd-ada6-c7bb7ef83743",
            ic_lat: -258221,
            ic_lon: -534806,
            ic_city: "Santa Izabel do Oeste",
            ic_states: "PARANÁ",
            ic_january: 6.404,
            ic_february: 5.863,
            ic_march: 5.257,
            ic_april: 4.245,
            ic_may: 3.303,
            ic_june: 2.859,
            ic_july: 3.117,
            ic_august: 4.089,
            ic_september: 4.278,
            ic_october: 5.282,
            ic_november: 6.162,
            ic_december: 6.512,
            ic_yearly: 4.781,
          },
          {
            ic_uuid: "c6bbf095-9a68-4a2a-8324-71503ffee8a8",
            ic_lat: -12982,
            ic_lon: -48157,
            ic_city: "Santa Izabel do Pará",
            ic_states: "PARÁ",
            ic_january: 4.33,
            ic_february: 4.191,
            ic_march: 4.167,
            ic_april: 4.289,
            ic_may: 4.543,
            ic_june: 4.861,
            ic_july: 4.981,
            ic_august: 5.237,
            ic_september: 5.363,
            ic_october: 5.123,
            ic_november: 4.899,
            ic_december: 4.555,
            ic_yearly: 4.711,
          },
          {
            ic_uuid: "79477589-fa1d-4abb-8dbe-3b1441140b72",
            ic_lat: -19311,
            ic_lon: -475294,
            ic_city: "Santa Juliana",
            ic_states: "MINAS GERAIS",
            ic_january: 5.57,
            ic_february: 5.951,
            ic_march: 4.995,
            ic_april: 4.935,
            ic_may: 4.48,
            ic_june: 4.296,
            ic_july: 4.527,
            ic_august: 5.514,
            ic_september: 5.442,
            ic_october: 5.623,
            ic_november: 5.454,
            ic_december: 5.74,
            ic_yearly: 5.21,
          },
          {
            ic_uuid: "d07df595-8b78-4835-9ceb-e8bd4471f364",
            ic_lat: -201004,
            ic_lon: -405273,
            ic_city: "Santa Leopoldina",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.716,
            ic_february: 5.963,
            ic_march: 5.017,
            ic_april: 4.308,
            ic_may: 3.721,
            ic_june: 3.467,
            ic_july: 3.529,
            ic_august: 4.125,
            ic_september: 4.557,
            ic_october: 4.624,
            ic_november: 4.587,
            ic_december: 5.473,
            ic_yearly: 4.591,
          },
          {
            ic_uuid: "a2ddfcad-88d1-4590-8ce5-b2ec3fe685df",
            ic_lat: -254108,
            ic_lon: -535642,
            ic_city: "Santa Lúcia",
            ic_states: "PARANÁ",
            ic_january: 6.147,
            ic_february: 5.663,
            ic_march: 5.175,
            ic_april: 4.242,
            ic_may: 3.291,
            ic_june: 2.856,
            ic_july: 3.083,
            ic_august: 4.13,
            ic_september: 4.268,
            ic_october: 5.241,
            ic_november: 6.057,
            ic_december: 6.333,
            ic_yearly: 4.707,
          },
          {
            ic_uuid: "b3c2f46d-2111-4269-9784-7e04414038f0",
            ic_lat: -21685,
            ic_lon: -48084,
            ic_city: "Santa Lúcia",
            ic_states: "SÃO PAULO",
            ic_january: 5.656,
            ic_february: 5.903,
            ic_march: 5.123,
            ic_april: 4.722,
            ic_may: 4.015,
            ic_june: 3.762,
            ic_july: 3.969,
            ic_august: 4.819,
            ic_september: 4.954,
            ic_october: 5.522,
            ic_november: 5.779,
            ic_december: 5.964,
            ic_yearly: 5.016,
          },
          {
            ic_uuid: "1c35fa31-66e9-4b20-95af-fbfea959f53b",
            ic_lat: -89492,
            ic_lon: -441299,
            ic_city: "Santa Luz",
            ic_states: "PIAUÍ",
            ic_january: 5.586,
            ic_february: 5.436,
            ic_march: 5.332,
            ic_april: 5.393,
            ic_may: 5.354,
            ic_june: 5.271,
            ic_july: 5.583,
            ic_august: 6.248,
            ic_september: 6.566,
            ic_october: 6.097,
            ic_november: 5.7,
            ic_december: 5.625,
            ic_yearly: 5.683,
          },
          {
            ic_uuid: "a355c96a-ba2b-46b6-aae3-7112c8700916",
            ic_lat: -154346,
            ic_lon: -39329,
            ic_city: "Santa Luzia",
            ic_states: "BAHIA",
            ic_january: 5.794,
            ic_february: 5.827,
            ic_march: 5.31,
            ic_april: 4.428,
            ic_may: 3.778,
            ic_june: 3.408,
            ic_july: 3.602,
            ic_august: 4.231,
            ic_september: 4.786,
            ic_october: 4.936,
            ic_november: 5.091,
            ic_december: 5.948,
            ic_yearly: 4.762,
          },
          {
            ic_uuid: "b9a04fee-d622-4def-9938-405a994d3598",
            ic_lat: -39613,
            ic_lon: -456644,
            ic_city: "Santa Luzia",
            ic_states: "MARANHÃO",
            ic_january: 4.569,
            ic_february: 4.782,
            ic_march: 4.863,
            ic_april: 4.849,
            ic_may: 4.676,
            ic_june: 4.86,
            ic_july: 5.02,
            ic_august: 5.427,
            ic_september: 5.661,
            ic_october: 5.266,
            ic_november: 4.929,
            ic_december: 4.779,
            ic_yearly: 4.973,
          },
          {
            ic_uuid: "1c8ed1f3-a811-45d6-82bb-74014ce9ff1f",
            ic_lat: -197553,
            ic_lon: -4385,
            ic_city: "Santa Luzia",
            ic_states: "MINAS GERAIS",
            ic_january: 5.876,
            ic_february: 6.108,
            ic_march: 5.31,
            ic_april: 4.87,
            ic_may: 4.219,
            ic_june: 4.083,
            ic_july: 4.341,
            ic_august: 5.17,
            ic_september: 5.544,
            ic_october: 5.657,
            ic_november: 5.399,
            ic_december: 5.717,
            ic_yearly: 5.191,
          },
          {
            ic_uuid: "2840e559-841b-420a-ba07-4af53113eed4",
            ic_lat: -68697,
            ic_lon: -369188,
            ic_city: "Santa Luzia",
            ic_states: "PARAÍBA",
            ic_january: 6.083,
            ic_february: 6.139,
            ic_march: 6.147,
            ic_april: 5.757,
            ic_may: 5.108,
            ic_june: 4.642,
            ic_july: 4.988,
            ic_august: 5.761,
            ic_september: 6.315,
            ic_october: 6.537,
            ic_november: 6.61,
            ic_december: 6.23,
            ic_yearly: 5.86,
          },
          {
            ic_uuid: "b010bbe4-038a-4df0-af03-de1971f30de9",
            ic_lat: -113512,
            ic_lon: -374612,
            ic_city: "Santa Luzia do Itanhy",
            ic_states: "SERGIPE",
            ic_january: 6.382,
            ic_february: 6.121,
            ic_march: 5.998,
            ic_april: 5.021,
            ic_may: 4.226,
            ic_june: 3.956,
            ic_july: 4.076,
            ic_august: 4.583,
            ic_september: 5.345,
            ic_october: 5.73,
            ic_november: 6.253,
            ic_december: 6.472,
            ic_yearly: 5.347,
          },
          {
            ic_uuid: "80feafe4-aafb-4e51-a739-8f1bb0044fbf",
            ic_lat: -96041,
            ic_lon: -358235,
            ic_city: "Santa Luzia do Norte",
            ic_states: "ALAGOAS",
            ic_january: 6.047,
            ic_february: 6.015,
            ic_march: 5.95,
            ic_april: 5.087,
            ic_may: 4.306,
            ic_june: 4.008,
            ic_july: 4.137,
            ic_august: 4.761,
            ic_september: 5.515,
            ic_october: 5.875,
            ic_november: 6.307,
            ic_december: 6.31,
            ic_yearly: 5.36,
          },
          {
            ic_uuid: "c2f1d2e0-0b61-43c9-a012-3d2b9f9c3493",
            ic_lat: -15218,
            ic_lon: -469012,
            ic_city: "Santa Luzia do Pará",
            ic_states: "PARÁ",
            ic_january: 4.482,
            ic_february: 4.291,
            ic_march: 4.311,
            ic_april: 4.346,
            ic_may: 4.528,
            ic_june: 4.808,
            ic_july: 5.013,
            ic_august: 5.282,
            ic_september: 5.484,
            ic_october: 5.265,
            ic_november: 5.044,
            ic_december: 4.675,
            ic_yearly: 4.794,
          },
          {
            ic_uuid: "b758e522-ff1d-4579-a069-698920b47edf",
            ic_lat: -25442,
            ic_lon: -457751,
            ic_city: "Santa Luzia do Paruá",
            ic_states: "MARANHÃO",
            ic_january: 4.543,
            ic_february: 4.63,
            ic_march: 4.538,
            ic_april: 4.595,
            ic_may: 4.658,
            ic_june: 4.884,
            ic_july: 4.997,
            ic_august: 5.555,
            ic_september: 5.735,
            ic_october: 5.412,
            ic_november: 5.085,
            ic_december: 4.77,
            ic_yearly: 4.95,
          },
          {
            ic_uuid: "97d754c0-9fca-421a-a4bf-17a5ceeff08a",
            ic_lat: -119078,
            ic_lon: -617781,
            ic_city: "Santa Luzia D'Oeste",
            ic_states: "RONDÔNIA",
            ic_january: 4.504,
            ic_february: 4.415,
            ic_march: 4.561,
            ic_april: 4.562,
            ic_may: 4.172,
            ic_june: 4.377,
            ic_july: 4.496,
            ic_august: 4.96,
            ic_september: 4.99,
            ic_october: 5.146,
            ic_november: 4.998,
            ic_december: 4.811,
            ic_yearly: 4.666,
          },
          {
            ic_uuid: "c95e76eb-9f82-4092-a758-8903fe826ed3",
            ic_lat: -203843,
            ic_lon: -422523,
            ic_city: "Santa Margarida",
            ic_states: "MINAS GERAIS",
            ic_january: 5.65,
            ic_february: 5.897,
            ic_march: 4.984,
            ic_april: 4.479,
            ic_may: 3.802,
            ic_june: 3.688,
            ic_july: 3.852,
            ic_august: 4.576,
            ic_september: 4.956,
            ic_october: 5.031,
            ic_november: 4.759,
            ic_december: 5.54,
            ic_yearly: 4.768,
          },
          {
            ic_uuid: "97f4dd16-e104-4510-9d02-d1a6418d80be",
            ic_lat: -303398,
            ic_lon: -540822,
            ic_city: "Santa Margarida do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.588,
            ic_february: 6.057,
            ic_march: 5.111,
            ic_april: 3.93,
            ic_may: 2.861,
            ic_june: 2.386,
            ic_july: 2.619,
            ic_august: 3.34,
            ic_september: 3.886,
            ic_october: 5.126,
            ic_november: 6.396,
            ic_december: 7.008,
            ic_yearly: 4.609,
          },
          {
            ic_uuid: "1c8f6bd7-4c0b-49f0-9347-3f605a9e9421",
            ic_lat: -58384,
            ic_lon: -356917,
            ic_city: "Santa Maria",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.801,
            ic_february: 5.859,
            ic_march: 5.859,
            ic_april: 5.489,
            ic_may: 4.985,
            ic_june: 4.545,
            ic_july: 4.71,
            ic_august: 5.371,
            ic_september: 5.814,
            ic_october: 6.003,
            ic_november: 6.184,
            ic_december: 5.809,
            ic_yearly: 5.536,
          },
          {
            ic_uuid: "ad12fe59-a0ef-4043-a757-b8b2293bbeb7",
            ic_lat: -296873,
            ic_lon: -538154,
            ic_city: "Santa Maria",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.461,
            ic_february: 5.963,
            ic_march: 4.967,
            ic_april: 3.891,
            ic_may: 2.809,
            ic_june: 2.333,
            ic_july: 2.563,
            ic_august: 3.323,
            ic_september: 3.774,
            ic_october: 4.927,
            ic_november: 6.275,
            ic_december: 6.809,
            ic_yearly: 4.508,
          },
          {
            ic_uuid: "71a3fbb1-e396-4563-b75b-e4afaec4baaa",
            ic_lat: -87981,
            ic_lon: -398244,
            ic_city: "Santa Maria da Boa Vista",
            ic_states: "PERNAMBUCO",
            ic_january: 6.193,
            ic_february: 5.934,
            ic_march: 5.962,
            ic_april: 5.325,
            ic_may: 4.708,
            ic_june: 4.408,
            ic_july: 4.603,
            ic_august: 5.391,
            ic_september: 6.271,
            ic_october: 6.396,
            ic_november: 6.539,
            ic_december: 6.317,
            ic_yearly: 5.67,
          },
          {
            ic_uuid: "f2130fdb-de07-463e-8ff2-232063ddc61d",
            ic_lat: -225666,
            ic_lon: -481597,
            ic_city: "Santa Maria da Serra",
            ic_states: "SÃO PAULO",
            ic_january: 5.527,
            ic_february: 5.761,
            ic_march: 5.123,
            ic_april: 4.615,
            ic_may: 3.745,
            ic_june: 3.468,
            ic_july: 3.611,
            ic_august: 4.608,
            ic_september: 4.76,
            ic_october: 5.389,
            ic_november: 5.794,
            ic_december: 6.116,
            ic_yearly: 4.876,
          },
          {
            ic_uuid: "efea4ccf-9b75-46e7-8c3b-fc2d87f7e545",
            ic_lat: -133863,
            ic_lon: -442014,
            ic_city: "Santa Maria da Vitória",
            ic_states: "BAHIA",
            ic_january: 6.576,
            ic_february: 6.373,
            ic_march: 5.842,
            ic_april: 5.591,
            ic_may: 5.194,
            ic_june: 5.001,
            ic_july: 5.322,
            ic_august: 5.829,
            ic_september: 6.18,
            ic_october: 6.295,
            ic_november: 5.798,
            ic_december: 6.33,
            ic_yearly: 5.861,
          },
          {
            ic_uuid: "c9b1494d-fc1b-446d-b916-72f523dcf4b7",
            ic_lat: -8859,
            ic_lon: -497208,
            ic_city: "Santa Maria das Barreiras",
            ic_states: "PARÁ",
            ic_january: 4.912,
            ic_february: 5.001,
            ic_march: 4.801,
            ic_april: 4.762,
            ic_may: 4.925,
            ic_june: 5.1,
            ic_july: 5.232,
            ic_august: 5.89,
            ic_september: 5.535,
            ic_october: 5.121,
            ic_november: 5.052,
            ic_december: 4.967,
            ic_yearly: 5.108,
          },
          {
            ic_uuid: "b5f7bd3a-beec-4a00-b9de-6e7bdd7a5ab1",
            ic_lat: -19449,
            ic_lon: -43113,
            ic_city: "Santa Maria de Itabira",
            ic_states: "MINAS GERAIS",
            ic_january: 5.723,
            ic_february: 5.986,
            ic_march: 5.032,
            ic_april: 4.469,
            ic_may: 3.882,
            ic_june: 3.687,
            ic_july: 3.879,
            ic_august: 4.686,
            ic_september: 5.013,
            ic_october: 5.155,
            ic_november: 4.832,
            ic_december: 5.613,
            ic_yearly: 4.83,
          },
          {
            ic_uuid: "9cad6ced-589c-4189-9bfe-caff40c1722b",
            ic_lat: -200258,
            ic_lon: -407443,
            ic_city: "Santa Maria de Jetibá",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.789,
            ic_february: 6.036,
            ic_march: 5.016,
            ic_april: 4.389,
            ic_may: 3.754,
            ic_june: 3.492,
            ic_july: 3.561,
            ic_august: 4.19,
            ic_september: 4.569,
            ic_october: 4.61,
            ic_november: 4.646,
            ic_december: 5.376,
            ic_yearly: 4.619,
          },
          {
            ic_uuid: "a488dd33-ca24-428d-8913-35d57694b058",
            ic_lat: -7833,
            ic_lon: -358813,
            ic_city: "Santa Maria do Cambucá",
            ic_states: "PERNAMBUCO",
            ic_january: 5.791,
            ic_february: 5.811,
            ic_march: 5.87,
            ic_april: 5.26,
            ic_may: 4.573,
            ic_june: 4.091,
            ic_july: 4.288,
            ic_august: 4.967,
            ic_september: 5.544,
            ic_october: 5.821,
            ic_november: 6.123,
            ic_december: 5.953,
            ic_yearly: 5.341,
          },
          {
            ic_uuid: "49ea8406-eb4b-4d70-93d6-17b6ab349def",
            ic_lat: -294912,
            ic_lon: -509886,
            ic_city: "Santa Maria do Herval",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.036,
            ic_february: 5.559,
            ic_march: 4.602,
            ic_april: 3.738,
            ic_may: 2.786,
            ic_june: 2.365,
            ic_july: 2.591,
            ic_august: 3.275,
            ic_september: 3.523,
            ic_october: 4.607,
            ic_november: 6.005,
            ic_december: 6.4,
            ic_yearly: 4.291,
          },
          {
            ic_uuid: "530cab5c-0d7d-47a8-bed7-fae1612af57e",
            ic_lat: -249382,
            ic_lon: -5187,
            ic_city: "Santa Maria do Oeste",
            ic_states: "PARANÁ",
            ic_january: 5.795,
            ic_february: 5.509,
            ic_march: 5.069,
            ic_april: 4.289,
            ic_may: 3.372,
            ic_june: 2.992,
            ic_july: 3.264,
            ic_august: 4.252,
            ic_september: 4.413,
            ic_october: 5.153,
            ic_november: 5.962,
            ic_december: 6.143,
            ic_yearly: 4.684,
          },
          {
            ic_uuid: "7223b254-19ca-4e22-a2de-5e7328df2ef0",
            ic_lat: -13486,
            ic_lon: -475739,
            ic_city: "Santa Maria do Pará",
            ic_states: "PARÁ",
            ic_january: 4.348,
            ic_february: 4.206,
            ic_march: 4.242,
            ic_april: 4.337,
            ic_may: 4.599,
            ic_june: 4.846,
            ic_july: 4.967,
            ic_august: 5.261,
            ic_september: 5.429,
            ic_october: 5.237,
            ic_november: 4.988,
            ic_december: 4.603,
            ic_yearly: 4.755,
          },
          {
            ic_uuid: "643eb511-2afd-4cd8-b5b2-6513163e0056",
            ic_lat: -162484,
            ic_lon: -401515,
            ic_city: "Santa Maria do Salto",
            ic_states: "MINAS GERAIS",
            ic_january: 6.018,
            ic_february: 6.179,
            ic_march: 5.465,
            ic_april: 4.737,
            ic_may: 3.901,
            ic_june: 3.539,
            ic_july: 3.696,
            ic_august: 4.179,
            ic_september: 4.896,
            ic_october: 5.052,
            ic_november: 5.126,
            ic_december: 6.005,
            ic_yearly: 4.899,
          },
          {
            ic_uuid: "c588c0c9-b849-46d2-95a6-739626278c28",
            ic_lat: -181889,
            ic_lon: -424194,
            ic_city: "Santa Maria do Suaçuí",
            ic_states: "MINAS GERAIS",
            ic_january: 5.963,
            ic_february: 6.245,
            ic_march: 5.251,
            ic_april: 4.576,
            ic_may: 3.958,
            ic_june: 3.735,
            ic_july: 3.852,
            ic_august: 4.591,
            ic_september: 5.101,
            ic_october: 5.315,
            ic_november: 4.931,
            ic_december: 5.734,
            ic_yearly: 4.938,
          },
          {
            ic_uuid: "545050b2-0c61-4bb4-b821-9d76226eefd5",
            ic_lat: -8776,
            ic_lon: -477481,
            ic_city: "Santa Maria do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 4.977,
            ic_february: 5.043,
            ic_march: 4.859,
            ic_april: 4.904,
            ic_may: 5.119,
            ic_june: 5.228,
            ic_july: 5.308,
            ic_august: 6.086,
            ic_september: 5.974,
            ic_october: 5.362,
            ic_november: 4.946,
            ic_december: 5.001,
            ic_yearly: 5.234,
          },
          {
            ic_uuid: "590ea1a5-c05d-49cb-b77e-32edfcb8a0ff",
            ic_lat: -219551,
            ic_lon: -420102,
            ic_city: "Santa Maria Madalena",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.967,
            ic_february: 6.286,
            ic_march: 5.107,
            ic_april: 4.36,
            ic_may: 3.669,
            ic_june: 3.397,
            ic_july: 3.512,
            ic_august: 4.268,
            ic_september: 4.623,
            ic_october: 4.936,
            ic_november: 4.938,
            ic_december: 5.774,
            ic_yearly: 4.736,
          },
          {
            ic_uuid: "94c3fb69-25e1-48d3-9f6c-c0166f85a307",
            ic_lat: -23147,
            ic_lon: -505172,
            ic_city: "Santa Mariana",
            ic_states: "PARANÁ",
            ic_january: 5.924,
            ic_february: 5.906,
            ic_march: 5.237,
            ic_april: 4.648,
            ic_may: 3.72,
            ic_june: 3.407,
            ic_july: 3.611,
            ic_august: 4.568,
            ic_september: 4.71,
            ic_october: 5.383,
            ic_november: 6.002,
            ic_december: 6.412,
            ic_yearly: 4.961,
          },
          {
            ic_uuid: "76ed8de4-c294-45db-96a2-a03c542f2e24",
            ic_lat: -213509,
            ic_lon: -517549,
            ic_city: "Santa Mercedes",
            ic_states: "SÃO PAULO",
            ic_january: 5.956,
            ic_february: 6.003,
            ic_march: 5.464,
            ic_april: 4.843,
            ic_may: 4.033,
            ic_june: 3.696,
            ic_july: 3.889,
            ic_august: 4.797,
            ic_september: 4.913,
            ic_october: 5.61,
            ic_november: 6.059,
            ic_december: 6.365,
            ic_yearly: 5.136,
          },
          {
            ic_uuid: "5812ab07-dedc-4d4d-bb6c-e41f21186a2a",
            ic_lat: -231085,
            ic_lon: -531107,
            ic_city: "Santa Mônica",
            ic_states: "PARANÁ",
            ic_january: 5.895,
            ic_february: 5.854,
            ic_march: 5.413,
            ic_april: 4.611,
            ic_may: 3.656,
            ic_june: 3.267,
            ic_july: 3.495,
            ic_august: 4.451,
            ic_september: 4.676,
            ic_october: 5.417,
            ic_november: 6.125,
            ic_december: 6.423,
            ic_yearly: 4.94,
          },
          {
            ic_uuid: "48d24b52-2b4f-465b-86e7-7e44dc6b4608",
            ic_lat: -43359,
            ic_lon: -401546,
            ic_city: "Santa Quitéria",
            ic_states: "CEARÁ",
            ic_january: 5.296,
            ic_february: 5.421,
            ic_march: 5.416,
            ic_april: 5.024,
            ic_may: 5.049,
            ic_june: 4.993,
            ic_july: 5.274,
            ic_august: 6.06,
            ic_september: 6.565,
            ic_october: 6.506,
            ic_november: 6.351,
            ic_december: 5.658,
            ic_yearly: 5.635,
          },
          {
            ic_uuid: "771b95f8-64c1-461f-a68e-df88880159ac",
            ic_lat: -34985,
            ic_lon: -425605,
            ic_city: "Santa Quitéria do Maranhão",
            ic_states: "MARANHÃO",
            ic_january: 5.277,
            ic_february: 5.303,
            ic_march: 5.403,
            ic_april: 5.146,
            ic_may: 5.15,
            ic_june: 5.299,
            ic_july: 5.522,
            ic_august: 5.996,
            ic_september: 6.318,
            ic_october: 6.334,
            ic_november: 6.09,
            ic_december: 5.577,
            ic_yearly: 5.618,
          },
          {
            ic_uuid: "0f65dae3-824c-47fd-93f5-43cafc90803f",
            ic_lat: -31375,
            ic_lon: -44326,
            ic_city: "Santa Rita",
            ic_states: "MARANHÃO",
            ic_january: 4.643,
            ic_february: 4.788,
            ic_march: 4.691,
            ic_april: 4.594,
            ic_may: 4.762,
            ic_june: 5.035,
            ic_july: 5.245,
            ic_august: 5.862,
            ic_september: 6.114,
            ic_october: 5.828,
            ic_november: 5.479,
            ic_december: 5.031,
            ic_yearly: 5.173,
          },
          {
            ic_uuid: "d615505f-5aaf-42a7-b375-cd45d07c98a0",
            ic_lat: -71512,
            ic_lon: -349707,
            ic_city: "Santa Rita",
            ic_states: "PARAÍBA",
            ic_january: 5.843,
            ic_february: 5.959,
            ic_march: 5.993,
            ic_april: 5.331,
            ic_may: 4.775,
            ic_june: 4.333,
            ic_july: 4.443,
            ic_august: 5.216,
            ic_september: 5.609,
            ic_october: 5.901,
            ic_november: 6.123,
            ic_december: 6.023,
            ic_yearly: 5.462,
          },
          {
            ic_uuid: "c23b77e2-f8be-49f5-a492-0daafb524492",
            ic_lat: -220297,
            ic_lon: -463389,
            ic_city: "Santa Rita de Caldas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.14,
            ic_february: 5.454,
            ic_march: 4.632,
            ic_april: 4.414,
            ic_may: 3.866,
            ic_june: 3.641,
            ic_july: 3.881,
            ic_august: 4.824,
            ic_september: 4.933,
            ic_october: 5.228,
            ic_november: 5.114,
            ic_december: 5.418,
            ic_yearly: 4.712,
          },
          {
            ic_uuid: "2ff51c6b-feda-4753-8a4d-5a5b119db30c",
            ic_lat: -109962,
            ic_lon: -445214,
            ic_city: "Santa Rita de Cássia",
            ic_states: "BAHIA",
            ic_january: 6.239,
            ic_february: 5.942,
            ic_march: 5.658,
            ic_april: 5.486,
            ic_may: 5.415,
            ic_june: 5.245,
            ic_july: 5.516,
            ic_august: 6.107,
            ic_september: 6.448,
            ic_october: 6.239,
            ic_november: 5.94,
            ic_december: 6.143,
            ic_yearly: 5.865,
          },
          {
            ic_uuid: "43aad41d-b146-4b8f-97c7-258d25a95420",
            ic_lat: -215663,
            ic_lon: -439167,
            ic_city: "Santa Rita de Ibitipoca",
            ic_states: "MINAS GERAIS",
            ic_january: 5.386,
            ic_february: 5.732,
            ic_march: 4.843,
            ic_april: 4.301,
            ic_may: 3.736,
            ic_june: 3.567,
            ic_july: 3.723,
            ic_august: 4.62,
            ic_september: 4.824,
            ic_october: 5.056,
            ic_november: 4.869,
            ic_december: 5.498,
            ic_yearly: 4.679,
          },
          {
            ic_uuid: "81f6f5c9-1042-41da-98a5-38bdc71e5621",
            ic_lat: -221479,
            ic_lon: -440981,
            ic_city: "Santa Rita de Jacutinga",
            ic_states: "MINAS GERAIS",
            ic_january: 5.302,
            ic_february: 5.662,
            ic_march: 4.729,
            ic_april: 4.234,
            ic_may: 3.604,
            ic_june: 3.468,
            ic_july: 3.542,
            ic_august: 4.417,
            ic_september: 4.525,
            ic_october: 4.738,
            ic_november: 4.68,
            ic_december: 5.349,
            ic_yearly: 4.521,
          },
          {
            ic_uuid: "9fcae519-cf21-436d-ae8c-2c54b15b7672",
            ic_lat: -198732,
            ic_lon: -421326,
            ic_city: "Santa Rita de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.677,
            ic_february: 5.857,
            ic_march: 4.89,
            ic_april: 4.437,
            ic_may: 3.799,
            ic_june: 3.607,
            ic_july: 3.761,
            ic_august: 4.472,
            ic_september: 4.885,
            ic_october: 4.943,
            ic_november: 4.757,
            ic_december: 5.553,
            ic_yearly: 4.72,
          },
          {
            ic_uuid: "3005f6ab-3cd3-4caf-805a-9d7d6d20875e",
            ic_lat: -173274,
            ic_lon: -532017,
            ic_city: "Santa Rita do Araguaia",
            ic_states: "GOIÁS",
            ic_january: 5.472,
            ic_february: 5.571,
            ic_march: 5.141,
            ic_april: 5.095,
            ic_may: 4.633,
            ic_june: 4.403,
            ic_july: 4.547,
            ic_august: 5.414,
            ic_september: 5.314,
            ic_october: 5.249,
            ic_november: 5.417,
            ic_december: 5.598,
            ic_yearly: 5.154,
          },
          {
            ic_uuid: "8df63f62-9075-4998-976b-4de017a6c960",
            ic_lat: -193581,
            ic_lon: -413824,
            ic_city: "Santa Rita do Itueto",
            ic_states: "MINAS GERAIS",
            ic_january: 5.827,
            ic_february: 5.976,
            ic_march: 5.052,
            ic_april: 4.471,
            ic_may: 3.792,
            ic_june: 3.578,
            ic_july: 3.673,
            ic_august: 4.321,
            ic_september: 4.769,
            ic_october: 4.907,
            ic_november: 4.778,
            ic_december: 5.58,
            ic_yearly: 4.727,
          },
          {
            ic_uuid: "ffd83c13-d5c8-4d5e-b27e-9c5016f714cc",
            ic_lat: -151352,
            ic_lon: -491189,
            ic_city: "Santa Rita do Novo Destino",
            ic_states: "GOIÁS",
            ic_january: 5.398,
            ic_february: 5.655,
            ic_march: 5.264,
            ic_april: 5.178,
            ic_may: 4.958,
            ic_june: 4.712,
            ic_july: 4.88,
            ic_august: 5.698,
            ic_september: 5.684,
            ic_october: 5.61,
            ic_november: 5.255,
            ic_december: 5.378,
            ic_yearly: 5.306,
          },
          {
            ic_uuid: "672d37fd-f8ff-4849-9086-7d5edc304821",
            ic_lat: -213021,
            ic_lon: -528338,
            ic_city: "Santa Rita do Pardo",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.792,
            ic_february: 5.897,
            ic_march: 5.398,
            ic_april: 4.785,
            ic_may: 4.022,
            ic_june: 3.671,
            ic_july: 3.831,
            ic_august: 4.783,
            ic_september: 4.847,
            ic_october: 5.444,
            ic_november: 6.005,
            ic_december: 6.405,
            ic_yearly: 5.073,
          },
          {
            ic_uuid: "08932da8-d70a-4dd3-a68b-7c545963cf7e",
            ic_lat: -217088,
            ic_lon: -474784,
            ic_city: "Santa Rita do Passa Quatro",
            ic_states: "SÃO PAULO",
            ic_january: 5.633,
            ic_february: 5.846,
            ic_march: 5.143,
            ic_april: 4.779,
            ic_may: 4.016,
            ic_june: 3.799,
            ic_july: 3.951,
            ic_august: 4.883,
            ic_september: 4.911,
            ic_october: 5.485,
            ic_november: 5.738,
            ic_december: 6.01,
            ic_yearly: 5.016,
          },
          {
            ic_uuid: "3d4a2f45-27ee-48cb-bd78-7ff45c304cdb",
            ic_lat: -222466,
            ic_lon: -457038,
            ic_city: "Santa Rita do Sapucaí",
            ic_states: "MINAS GERAIS",
            ic_january: 5.368,
            ic_february: 5.69,
            ic_march: 4.896,
            ic_april: 4.598,
            ic_may: 3.889,
            ic_june: 3.634,
            ic_july: 3.855,
            ic_august: 4.788,
            ic_september: 4.93,
            ic_october: 5.371,
            ic_november: 5.328,
            ic_december: 5.68,
            ic_yearly: 4.835,
          },
          {
            ic_uuid: "67c03fd7-e0e2-4dc7-87b4-cbcda84a6b45",
            ic_lat: -108641,
            ic_lon: -489084,
            ic_city: "Santa Rita do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 5.13,
            ic_february: 5.154,
            ic_march: 4.881,
            ic_april: 4.951,
            ic_may: 5.012,
            ic_june: 5.083,
            ic_july: 5.364,
            ic_august: 5.887,
            ic_september: 5.678,
            ic_october: 5.336,
            ic_november: 5.198,
            ic_december: 5.122,
            ic_yearly: 5.233,
          },
          {
            ic_uuid: "051ab1d2-6bff-4392-be61-a87c1e4db665",
            ic_lat: -138162,
            ic_lon: -552741,
            ic_city: "Santa Rita do Trivelato",
            ic_states: "MATO GROSSO",
            ic_january: 5.201,
            ic_february: 5.186,
            ic_march: 4.971,
            ic_april: 5.04,
            ic_may: 4.782,
            ic_june: 4.676,
            ic_july: 4.837,
            ic_august: 5.408,
            ic_september: 5.147,
            ic_october: 5.289,
            ic_november: 5.243,
            ic_december: 5.329,
            ic_yearly: 5.092,
          },
          {
            ic_uuid: "26e6dbf5-6de3-4498-9137-bcbf960126ec",
            ic_lat: -20143,
            ic_lon: -508329,
            ic_city: "Santa Rita d'Oeste",
            ic_states: "SÃO PAULO",
            ic_january: 5.807,
            ic_february: 6.006,
            ic_march: 5.432,
            ic_april: 4.932,
            ic_may: 4.23,
            ic_june: 3.952,
            ic_july: 4.167,
            ic_august: 5.056,
            ic_september: 5.044,
            ic_october: 5.686,
            ic_november: 5.993,
            ic_december: 6.301,
            ic_yearly: 5.217,
          },
          {
            ic_uuid: "c3d07edb-2190-46fd-9003-187f19c69149",
            ic_lat: -278707,
            ic_lon: -544801,
            ic_city: "Santa Rosa",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.486,
            ic_february: 6.048,
            ic_march: 5.165,
            ic_april: 4.092,
            ic_may: 3.108,
            ic_june: 2.547,
            ic_july: 2.845,
            ic_august: 3.642,
            ic_september: 4.036,
            ic_october: 5.168,
            ic_november: 6.296,
            ic_december: 6.709,
            ic_yearly: 4.679,
          },
          {
            ic_uuid: "85be11f5-dd8f-4be5-b64f-952965d885de",
            ic_lat: -195263,
            ic_lon: -459662,
            ic_city: "Santa Rosa da Serra",
            ic_states: "MINAS GERAIS",
            ic_january: 5.64,
            ic_february: 5.969,
            ic_march: 4.907,
            ic_april: 4.866,
            ic_may: 4.449,
            ic_june: 4.253,
            ic_july: 4.536,
            ic_august: 5.547,
            ic_september: 5.527,
            ic_october: 5.451,
            ic_november: 5.024,
            ic_december: 5.405,
            ic_yearly: 5.131,
          },
          {
            ic_uuid: "0f926d81-6e56-4527-b001-ea3b5895f1a7",
            ic_lat: -160843,
            ic_lon: -494932,
            ic_city: "Santa Rosa de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.432,
            ic_february: 5.633,
            ic_march: 5.207,
            ic_april: 5.18,
            ic_may: 4.888,
            ic_june: 4.658,
            ic_july: 4.829,
            ic_august: 5.728,
            ic_september: 5.513,
            ic_october: 5.552,
            ic_november: 5.251,
            ic_december: 5.436,
            ic_yearly: 5.276,
          },
          {
            ic_uuid: "c580c8be-c49b-40af-971d-d8760a83db23",
            ic_lat: -280336,
            ic_lon: -491335,
            ic_city: "Santa Rosa de Lima",
            ic_states: "SANTA CATARINA",
            ic_january: 5.417,
            ic_february: 5.108,
            ic_march: 4.449,
            ic_april: 3.741,
            ic_may: 2.931,
            ic_june: 2.554,
            ic_july: 2.751,
            ic_august: 3.501,
            ic_september: 3.58,
            ic_october: 4.248,
            ic_november: 5.501,
            ic_december: 5.741,
            ic_yearly: 4.126,
          },
          {
            ic_uuid: "c02bf239-ad5c-4ec4-aad2-b81ecc5529c6",
            ic_lat: -106438,
            ic_lon: -371934,
            ic_city: "Santa Rosa de Lima",
            ic_states: "SERGIPE",
            ic_january: 6.192,
            ic_february: 5.997,
            ic_march: 5.865,
            ic_april: 5.048,
            ic_may: 4.254,
            ic_june: 3.971,
            ic_july: 4.055,
            ic_august: 4.608,
            ic_september: 5.358,
            ic_october: 5.703,
            ic_november: 6.197,
            ic_december: 6.209,
            ic_yearly: 5.288,
          },
          {
            ic_uuid: "322c5ae4-df8f-47b2-828c-9f430e500563",
            ic_lat: -21478,
            ic_lon: -473626,
            ic_city: "Santa Rosa de Viterbo",
            ic_states: "SÃO PAULO",
            ic_january: 5.671,
            ic_february: 5.895,
            ic_march: 5.13,
            ic_april: 4.823,
            ic_may: 4.058,
            ic_june: 3.815,
            ic_july: 4.02,
            ic_august: 4.905,
            ic_september: 4.974,
            ic_october: 5.563,
            ic_november: 5.759,
            ic_december: 5.986,
            ic_yearly: 5.05,
          },
          {
            ic_uuid: "f60dd771-b9e0-4b54-a9c3-d79ac56b7b10",
            ic_lat: -67962,
            ic_lon: -422817,
            ic_city: "Santa Rosa do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.302,
            ic_february: 5.369,
            ic_march: 5.266,
            ic_april: 5.262,
            ic_may: 5.327,
            ic_june: 5.296,
            ic_july: 5.636,
            ic_august: 6.106,
            ic_september: 6.521,
            ic_october: 6.398,
            ic_november: 6.222,
            ic_december: 5.702,
            ic_yearly: 5.701,
          },
          {
            ic_uuid: "77af81ae-abd8-415c-a96e-40f606c853e5",
            ic_lat: -94469,
            ic_lon: -704907,
            ic_city: "Santa Rosa do Purus",
            ic_states: "ACRE",
            ic_january: 4.585,
            ic_february: 4.698,
            ic_march: 4.184,
            ic_april: 4.434,
            ic_may: 4.087,
            ic_june: 4.106,
            ic_july: 4.372,
            ic_august: 5.028,
            ic_september: 5.071,
            ic_october: 5.006,
            ic_november: 4.945,
            ic_december: 4.58,
            ic_yearly: 4.591,
          },
          {
            ic_uuid: "4106c231-1d4e-410e-b096-e6646cb16397",
            ic_lat: -291365,
            ic_lon: -497148,
            ic_city: "Santa Rosa do Sul",
            ic_states: "SANTA CATARINA",
            ic_january: 5.52,
            ic_february: 5.158,
            ic_march: 4.43,
            ic_april: 3.785,
            ic_may: 2.929,
            ic_june: 2.435,
            ic_july: 2.673,
            ic_august: 3.36,
            ic_september: 3.465,
            ic_october: 4.164,
            ic_november: 5.492,
            ic_december: 5.768,
            ic_yearly: 4.098,
          },
          {
            ic_uuid: "c9a7cf6f-73df-4da2-b13d-16abceeb5555",
            ic_lat: -114476,
            ic_lon: -481191,
            ic_city: "Santa Rosa do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 5.434,
            ic_february: 5.436,
            ic_march: 5.078,
            ic_april: 5.089,
            ic_may: 5.128,
            ic_june: 5.07,
            ic_july: 5.395,
            ic_august: 6.037,
            ic_september: 5.929,
            ic_october: 5.632,
            ic_november: 5.345,
            ic_december: 5.388,
            ic_yearly: 5.413,
          },
          {
            ic_uuid: "9f83ff68-e0d6-4780-aceb-f3849e2868a7",
            ic_lat: -20245,
            ic_lon: -506879,
            ic_city: "Santa Salete",
            ic_states: "SÃO PAULO",
            ic_january: 5.796,
            ic_february: 5.929,
            ic_march: 5.335,
            ic_april: 4.882,
            ic_may: 4.234,
            ic_june: 3.962,
            ic_july: 4.192,
            ic_august: 5.111,
            ic_september: 5.079,
            ic_october: 5.68,
            ic_november: 5.928,
            ic_december: 6.262,
            ic_yearly: 5.199,
          },
          {
            ic_uuid: "eb9c9fcd-a4d4-49d8-a8df-964e95104855",
            ic_lat: -199368,
            ic_lon: -405983,
            ic_city: "Santa Teresa",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.65,
            ic_february: 5.901,
            ic_march: 5.008,
            ic_april: 4.257,
            ic_may: 3.689,
            ic_june: 3.43,
            ic_july: 3.48,
            ic_august: 4.067,
            ic_september: 4.497,
            ic_october: 4.537,
            ic_november: 4.583,
            ic_december: 5.4,
            ic_yearly: 4.542,
          },
          {
            ic_uuid: "d00c9999-be89-49b2-8ee0-55383c7231ef",
            ic_lat: -127696,
            ic_lon: -395227,
            ic_city: "Santa Teresinha",
            ic_states: "BAHIA",
            ic_january: 5.835,
            ic_february: 5.849,
            ic_march: 5.587,
            ic_april: 4.543,
            ic_may: 3.951,
            ic_june: 3.663,
            ic_july: 3.856,
            ic_august: 4.357,
            ic_september: 5.118,
            ic_october: 5.269,
            ic_november: 5.433,
            ic_december: 5.877,
            ic_yearly: 4.945,
          },
          {
            ic_uuid: "425fb21e-d81f-4d4e-8734-19ff84d30275",
            ic_lat: -70842,
            ic_lon: -37444,
            ic_city: "Santa Teresinha",
            ic_states: "PARAÍBA",
            ic_january: 6.26,
            ic_february: 6.337,
            ic_march: 6.424,
            ic_april: 5.958,
            ic_may: 5.234,
            ic_june: 4.784,
            ic_july: 5.013,
            ic_august: 5.859,
            ic_september: 6.498,
            ic_october: 6.676,
            ic_november: 6.798,
            ic_december: 6.536,
            ic_yearly: 6.032,
          },
          {
            ic_uuid: "2ffbcce0-b3d8-4df5-bbac-16e579ea55a0",
            ic_lat: -291659,
            ic_lon: -517355,
            ic_city: "Santa Tereza",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.193,
            ic_february: 5.744,
            ic_march: 4.824,
            ic_april: 3.829,
            ic_may: 2.822,
            ic_june: 2.349,
            ic_july: 2.637,
            ic_august: 3.362,
            ic_september: 3.64,
            ic_october: 4.734,
            ic_november: 6.115,
            ic_december: 6.505,
            ic_yearly: 4.396,
          },
          {
            ic_uuid: "a6d3e8a5-9f12-4d32-94d1-61a8ad8c0852",
            ic_lat: -137143,
            ic_lon: -490148,
            ic_city: "Santa Tereza de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.382,
            ic_february: 5.574,
            ic_march: 5.19,
            ic_april: 5.207,
            ic_may: 5.018,
            ic_june: 4.818,
            ic_july: 5.151,
            ic_august: 5.857,
            ic_september: 5.688,
            ic_october: 5.603,
            ic_november: 5.173,
            ic_december: 5.327,
            ic_yearly: 5.332,
          },
          {
            ic_uuid: "75982a75-f157-4eb7-8b99-b4468e56e438",
            ic_lat: -250548,
            ic_lon: -536279,
            ic_city: "Santa Tereza do Oeste",
            ic_states: "PARANÁ",
            ic_january: 6.114,
            ic_february: 5.58,
            ic_march: 5.08,
            ic_april: 4.223,
            ic_may: 3.291,
            ic_june: 2.945,
            ic_july: 3.162,
            ic_august: 4.18,
            ic_september: 4.343,
            ic_october: 5.178,
            ic_november: 5.935,
            ic_december: 6.336,
            ic_yearly: 4.697,
          },
          {
            ic_uuid: "74ede3ae-cdbf-41f3-85bc-77e24b9361ca",
            ic_lat: -102821,
            ic_lon: -478102,
            ic_city: "Santa Tereza do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 5.32,
            ic_february: 5.193,
            ic_march: 5.037,
            ic_april: 5.083,
            ic_may: 5.172,
            ic_june: 5.095,
            ic_july: 5.42,
            ic_august: 5.996,
            ic_september: 5.876,
            ic_october: 5.429,
            ic_november: 5.215,
            ic_december: 5.281,
            ic_yearly: 5.343,
          },
          {
            ic_uuid: "8403a1e7-e225-4116-9d0b-f4e322469fcd",
            ic_lat: -10469,
            ic_lon: -505108,
            ic_city: "Santa Terezinha",
            ic_states: "MATO GROSSO",
            ic_january: 5.032,
            ic_february: 5.141,
            ic_march: 4.882,
            ic_april: 4.777,
            ic_may: 4.903,
            ic_june: 4.943,
            ic_july: 5.228,
            ic_august: 5.716,
            ic_september: 5.412,
            ic_october: 5.175,
            ic_november: 5.063,
            ic_december: 5.118,
            ic_yearly: 5.116,
          },
          {
            ic_uuid: "b1285e0b-3d29-495e-9b80-ad4cc289fa49",
            ic_lat: -73774,
            ic_lon: -374791,
            ic_city: "Santa Terezinha",
            ic_states: "PERNAMBUCO",
            ic_january: 6.101,
            ic_february: 6.012,
            ic_march: 6.009,
            ic_april: 5.739,
            ic_may: 5.052,
            ic_june: 4.658,
            ic_july: 4.996,
            ic_august: 5.794,
            ic_september: 6.477,
            ic_october: 6.563,
            ic_november: 6.68,
            ic_december: 6.301,
            ic_yearly: 5.865,
          },
          {
            ic_uuid: "35901538-9ed7-4627-a499-edf3d8473b04",
            ic_lat: -267818,
            ic_lon: -500094,
            ic_city: "Santa Terezinha",
            ic_states: "SANTA CATARINA",
            ic_january: 5.306,
            ic_february: 5.113,
            ic_march: 4.564,
            ic_april: 3.572,
            ic_may: 2.856,
            ic_june: 2.397,
            ic_july: 2.647,
            ic_august: 3.499,
            ic_september: 3.582,
            ic_october: 4.158,
            ic_november: 5.329,
            ic_december: 5.527,
            ic_yearly: 4.046,
          },
          {
            ic_uuid: "018c85a7-269a-4052-86b5-a248e24872f5",
            ic_lat: -144331,
            ic_lon: -497095,
            ic_city: "Santa Terezinha de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.411,
            ic_february: 5.601,
            ic_march: 5.288,
            ic_april: 5.282,
            ic_may: 4.975,
            ic_june: 4.716,
            ic_july: 5.014,
            ic_august: 5.737,
            ic_september: 5.602,
            ic_october: 5.635,
            ic_november: 5.294,
            ic_december: 5.457,
            ic_yearly: 5.334,
          },
          {
            ic_uuid: "e3380cff-78ad-4d94-97d5-e5e10aaa5203",
            ic_lat: -254349,
            ic_lon: -544012,
            ic_city: "Santa Terezinha de Itaipu",
            ic_states: "PARANÁ",
            ic_january: 6.525,
            ic_february: 5.95,
            ic_march: 5.364,
            ic_april: 4.223,
            ic_may: 3.285,
            ic_june: 2.82,
            ic_july: 3.046,
            ic_august: 3.996,
            ic_september: 4.262,
            ic_october: 5.278,
            ic_november: 6.168,
            ic_december: 6.709,
            ic_yearly: 4.802,
          },
          {
            ic_uuid: "c614342e-1ba6-42b5-bfae-66cb5addd0bc",
            ic_lat: -266245,
            ic_lon: -532002,
            ic_city: "Santa Terezinha do Progresso",
            ic_states: "SANTA CATARINA",
            ic_january: 6.181,
            ic_february: 5.759,
            ic_march: 5.092,
            ic_april: 4.119,
            ic_may: 3.196,
            ic_june: 2.711,
            ic_july: 2.988,
            ic_august: 3.873,
            ic_september: 4.135,
            ic_october: 5.104,
            ic_november: 6.061,
            ic_december: 6.327,
            ic_yearly: 4.629,
          },
          {
            ic_uuid: "6908a5f5-3a14-4d06-af1d-0dc27619f809",
            ic_lat: -64356,
            ic_lon: -476728,
            ic_city: "Santa Terezinha do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 4.652,
            ic_february: 4.857,
            ic_march: 4.791,
            ic_april: 4.856,
            ic_may: 4.933,
            ic_june: 5.243,
            ic_july: 5.417,
            ic_august: 5.939,
            ic_september: 5.753,
            ic_october: 5.086,
            ic_november: 4.732,
            ic_december: 4.655,
            ic_yearly: 5.076,
          },
          {
            ic_uuid: "2e3a4105-9291-4abb-a0cb-82e73bab30f6",
            ic_lat: -188419,
            ic_lon: -501212,
            ic_city: "Santa Vitória",
            ic_states: "MINAS GERAIS",
            ic_january: 5.869,
            ic_february: 5.867,
            ic_march: 5.385,
            ic_april: 5.093,
            ic_may: 4.491,
            ic_june: 4.237,
            ic_july: 4.436,
            ic_august: 5.355,
            ic_september: 5.278,
            ic_october: 5.659,
            ic_november: 5.887,
            ic_december: 6.066,
            ic_yearly: 5.302,
          },
          {
            ic_uuid: "1cd4a681-ec42-448c-8fe8-0e6056c1b4a5",
            ic_lat: -335255,
            ic_lon: -533722,
            ic_city: "Santa Vitória do Palmar",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.594,
            ic_february: 5.726,
            ic_march: 4.971,
            ic_april: 3.61,
            ic_may: 2.582,
            ic_june: 2.175,
            ic_july: 2.28,
            ic_august: 2.933,
            ic_september: 3.803,
            ic_october: 5.181,
            ic_november: 6.44,
            ic_december: 6.973,
            ic_yearly: 4.439,
          },
          {
            ic_uuid: "84271e12-2112-42ac-8e96-272fe95d4dc1",
            ic_lat: -112513,
            ic_lon: -393753,
            ic_city: "Santaluz",
            ic_states: "BAHIA",
            ic_january: 6.137,
            ic_february: 5.898,
            ic_march: 5.791,
            ic_april: 4.809,
            ic_may: 4.029,
            ic_june: 3.758,
            ic_july: 3.971,
            ic_august: 4.457,
            ic_september: 5.384,
            ic_october: 5.577,
            ic_november: 6.025,
            ic_december: 6.046,
            ic_yearly: 5.157,
          },
          {
            ic_uuid: "690271f1-cdba-480e-a402-876b295207f5",
            ic_lat: -458,
            ic_lon: -511733,
            ic_city: "Santana",
            ic_states: "AMAPÁ",
            ic_january: 4.531,
            ic_february: 4.191,
            ic_march: 4.117,
            ic_april: 4.17,
            ic_may: 4.627,
            ic_june: 4.902,
            ic_july: 4.991,
            ic_august: 5.37,
            ic_september: 5.563,
            ic_october: 5.407,
            ic_november: 5.334,
            ic_december: 4.785,
            ic_yearly: 4.832,
          },
          {
            ic_uuid: "8ec26964-f7c8-4c88-a390-2d052ee687e7",
            ic_lat: -129797,
            ic_lon: -44051,
            ic_city: "Santana",
            ic_states: "BAHIA",
            ic_january: 6.572,
            ic_february: 6.421,
            ic_march: 5.871,
            ic_april: 5.619,
            ic_may: 5.219,
            ic_june: 5.026,
            ic_july: 5.308,
            ic_august: 5.843,
            ic_september: 6.168,
            ic_october: 6.309,
            ic_november: 5.876,
            ic_december: 6.38,
            ic_yearly: 5.884,
          },
          {
            ic_uuid: "1daa2222-adcd-45fb-a380-ef5ad2911c2a",
            ic_lat: -30872,
            ic_lon: -531149,
            ic_city: "Santana da Boa Vista",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.353,
            ic_february: 5.856,
            ic_march: 4.923,
            ic_april: 3.752,
            ic_may: 2.709,
            ic_june: 2.277,
            ic_july: 2.502,
            ic_august: 3.17,
            ic_september: 3.711,
            ic_october: 4.987,
            ic_november: 6.275,
            ic_december: 6.877,
            ic_yearly: 4.45,
          },
          {
            ic_uuid: "ee9336d4-063d-4053-a6fd-e7d751ad7e6f",
            ic_lat: -20253,
            ic_lon: -507969,
            ic_city: "Santana da Ponte Pensa",
            ic_states: "SÃO PAULO",
            ic_january: 5.803,
            ic_february: 5.976,
            ic_march: 5.368,
            ic_april: 4.898,
            ic_may: 4.211,
            ic_june: 3.931,
            ic_july: 4.15,
            ic_august: 5.071,
            ic_september: 5.074,
            ic_october: 5.674,
            ic_november: 5.964,
            ic_december: 6.285,
            ic_yearly: 5.2,
          },
          {
            ic_uuid: "faf4d952-3dc0-4e0a-b61d-5a79c28d70d2",
            ic_lat: -212454,
            ic_lon: -455009,
            ic_city: "Santana da Vargem",
            ic_states: "MINAS GERAIS",
            ic_january: 5.593,
            ic_february: 5.82,
            ic_march: 4.921,
            ic_april: 4.676,
            ic_may: 4.006,
            ic_june: 3.807,
            ic_july: 4.088,
            ic_august: 5.0,
            ic_september: 5.158,
            ic_october: 5.458,
            ic_november: 5.247,
            ic_december: 5.612,
            ic_yearly: 4.949,
          },
          {
            ic_uuid: "f9c23f4f-f54b-4d97-ab10-7ff8c0a6664d",
            ic_lat: -212898,
            ic_lon: -425527,
            ic_city: "Santana de Cataguases",
            ic_states: "MINAS GERAIS",
            ic_january: 5.887,
            ic_february: 6.082,
            ic_march: 5.017,
            ic_april: 4.366,
            ic_may: 3.698,
            ic_june: 3.502,
            ic_july: 3.653,
            ic_august: 4.446,
            ic_september: 4.788,
            ic_october: 5.07,
            ic_november: 4.883,
            ic_december: 5.709,
            ic_yearly: 4.758,
          },
          {
            ic_uuid: "7b2c6159-ecd2-41ac-aedf-aec7edee29eb",
            ic_lat: -7552,
            ic_lon: -383365,
            ic_city: "Santana de Mangueira",
            ic_states: "PARAÍBA",
            ic_january: 6.167,
            ic_february: 6.107,
            ic_march: 6.083,
            ic_april: 5.783,
            ic_may: 5.108,
            ic_june: 4.744,
            ic_july: 5.018,
            ic_august: 5.784,
            ic_september: 6.495,
            ic_october: 6.584,
            ic_november: 6.777,
            ic_december: 6.388,
            ic_yearly: 5.92,
          },
          {
            ic_uuid: "5340eca9-052b-4b4e-b47d-86fec882ecd1",
            ic_lat: -23443,
            ic_lon: -469226,
            ic_city: "Santana de Parnaíba",
            ic_states: "SÃO PAULO",
            ic_january: 5.284,
            ic_february: 5.562,
            ic_march: 4.811,
            ic_april: 4.313,
            ic_may: 3.541,
            ic_june: 3.285,
            ic_july: 3.375,
            ic_august: 4.376,
            ic_september: 4.386,
            ic_october: 4.909,
            ic_november: 5.302,
            ic_december: 5.827,
            ic_yearly: 4.581,
          },
          {
            ic_uuid: "f4b65005-8775-4a03-93f2-06af4b8f26e0",
            ic_lat: -189967,
            ic_lon: -440413,
            ic_city: "Santana de Pirapama",
            ic_states: "MINAS GERAIS",
            ic_january: 6.072,
            ic_february: 6.419,
            ic_march: 5.492,
            ic_april: 5.121,
            ic_may: 4.543,
            ic_june: 4.287,
            ic_july: 4.616,
            ic_august: 5.34,
            ic_september: 5.796,
            ic_october: 5.895,
            ic_november: 5.595,
            ic_december: 5.985,
            ic_yearly: 5.43,
          },
          {
            ic_uuid: "441cfc70-dd8f-4a89-8102-c3f2bab76cde",
            ic_lat: -34605,
            ic_lon: -402085,
            ic_city: "Santana do Acaraú",
            ic_states: "CEARÁ",
            ic_january: 5.04,
            ic_february: 5.159,
            ic_march: 5.159,
            ic_april: 4.864,
            ic_may: 5.126,
            ic_june: 5.009,
            ic_july: 5.278,
            ic_august: 6.046,
            ic_september: 6.451,
            ic_october: 6.37,
            ic_november: 6.12,
            ic_december: 5.454,
            ic_yearly: 5.506,
          },
          {
            ic_uuid: "06ca6abc-6832-4daf-a903-bf1587d25f3c",
            ic_lat: -93285,
            ic_lon: -503504,
            ic_city: "Santana do Araguaia",
            ic_states: "PARÁ",
            ic_january: 4.931,
            ic_february: 4.961,
            ic_march: 4.813,
            ic_april: 4.688,
            ic_may: 4.822,
            ic_june: 4.972,
            ic_july: 5.139,
            ic_august: 5.778,
            ic_september: 5.368,
            ic_october: 5.091,
            ic_november: 5.04,
            ic_december: 4.95,
            ic_yearly: 5.046,
          },
          {
            ic_uuid: "c8601632-9b37-43db-822f-e23e82377357",
            ic_lat: -71839,
            ic_lon: -397381,
            ic_city: "Santana do Cariri",
            ic_states: "CEARÁ",
            ic_january: 5.599,
            ic_february: 5.483,
            ic_march: 5.541,
            ic_april: 5.255,
            ic_may: 5.024,
            ic_june: 4.918,
            ic_july: 5.319,
            ic_august: 6.086,
            ic_september: 6.475,
            ic_october: 6.372,
            ic_november: 6.402,
            ic_december: 6.028,
            ic_yearly: 5.708,
          },
          {
            ic_uuid: "4bb2b9d7-d182-40be-8379-1b027d283211",
            ic_lat: -219517,
            ic_lon: -431587,
            ic_city: "Santana do Deserto",
            ic_states: "MINAS GERAIS",
            ic_january: 5.745,
            ic_february: 5.955,
            ic_march: 4.956,
            ic_april: 4.338,
            ic_may: 3.558,
            ic_june: 3.394,
            ic_july: 3.536,
            ic_august: 4.266,
            ic_september: 4.57,
            ic_october: 4.907,
            ic_november: 4.852,
            ic_december: 5.595,
            ic_yearly: 4.64,
          },
          {
            ic_uuid: "658337e7-e67a-4678-a5a9-68d5387a1a6a",
            ic_lat: -215988,
            ic_lon: -441054,
            ic_city: "Santana do Garambéu",
            ic_states: "MINAS GERAIS",
            ic_january: 5.54,
            ic_february: 5.819,
            ic_march: 4.883,
            ic_april: 4.407,
            ic_may: 3.739,
            ic_june: 3.59,
            ic_july: 3.781,
            ic_august: 4.697,
            ic_september: 4.915,
            ic_october: 5.13,
            ic_november: 5.044,
            ic_december: 5.579,
            ic_yearly: 4.761,
          },
          {
            ic_uuid: "2fd78ad8-4d4a-4692-a441-772e56d5fd16",
            ic_lat: -93704,
            ic_lon: -372483,
            ic_city: "Santana do Ipanema",
            ic_states: "ALAGOAS",
            ic_january: 6.206,
            ic_february: 5.996,
            ic_march: 6.061,
            ic_april: 5.305,
            ic_may: 4.413,
            ic_june: 4.011,
            ic_july: 4.134,
            ic_august: 4.76,
            ic_september: 5.616,
            ic_october: 6.052,
            ic_november: 6.567,
            ic_december: 6.422,
            ic_yearly: 5.462,
          },
          {
            ic_uuid: "b74c9b52-8e85-4ae9-94d8-7d20a10d999a",
            ic_lat: -237592,
            ic_lon: -496297,
            ic_city: "Santana do Itararé",
            ic_states: "PARANÁ",
            ic_january: 5.69,
            ic_february: 5.713,
            ic_march: 5.107,
            ic_april: 4.463,
            ic_may: 3.523,
            ic_june: 3.145,
            ic_july: 3.375,
            ic_august: 4.389,
            ic_september: 4.572,
            ic_october: 5.176,
            ic_november: 5.839,
            ic_december: 6.229,
            ic_yearly: 4.768,
          },
          {
            ic_uuid: "0722cbda-6113-4f74-ab2f-fe59bf146376",
            ic_lat: -209012,
            ic_lon: -451289,
            ic_city: "Santana do Jacaré",
            ic_states: "MINAS GERAIS",
            ic_january: 5.599,
            ic_february: 5.755,
            ic_march: 4.851,
            ic_april: 4.729,
            ic_may: 4.047,
            ic_june: 3.851,
            ic_july: 4.12,
            ic_august: 5.069,
            ic_september: 5.215,
            ic_october: 5.361,
            ic_november: 5.105,
            ic_december: 5.545,
            ic_yearly: 4.938,
          },
          {
            ic_uuid: "158a501d-c246-43c9-9ade-f3b2497bd531",
            ic_lat: -308882,
            ic_lon: -55532,
            ic_city: "Sant'Ana do Livramento",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.677,
            ic_february: 5.973,
            ic_march: 5.1,
            ic_april: 3.952,
            ic_may: 2.789,
            ic_june: 2.323,
            ic_july: 2.633,
            ic_august: 3.339,
            ic_september: 3.939,
            ic_october: 5.281,
            ic_november: 6.435,
            ic_december: 7.032,
            ic_yearly: 4.623,
          },
          {
            ic_uuid: "03019c4b-5153-4dd1-b7d3-d6a8369cc4a6",
            ic_lat: -201036,
            ic_lon: -419282,
            ic_city: "Santana do Manhuaçu",
            ic_states: "MINAS GERAIS",
            ic_january: 5.645,
            ic_february: 5.85,
            ic_march: 4.865,
            ic_april: 4.443,
            ic_may: 3.805,
            ic_june: 3.596,
            ic_july: 3.77,
            ic_august: 4.459,
            ic_september: 4.72,
            ic_october: 4.794,
            ic_november: 4.643,
            ic_december: 5.32,
            ic_yearly: 4.659,
          },
          {
            ic_uuid: "af07113f-8c68-4b04-b871-c9fe041663ad",
            ic_lat: -31121,
            ic_lon: -424139,
            ic_city: "Santana do Maranhão",
            ic_states: "MARANHÃO",
            ic_january: 5.015,
            ic_february: 4.935,
            ic_march: 4.929,
            ic_april: 4.845,
            ic_may: 5.003,
            ic_june: 5.162,
            ic_july: 5.415,
            ic_august: 6.048,
            ic_september: 6.364,
            ic_october: 6.322,
            ic_november: 6.032,
            ic_december: 5.423,
            ic_yearly: 5.458,
          },
          {
            ic_uuid: "7282af07-c316-4cb0-b8d6-7969822e10a3",
            ic_lat: -59503,
            ic_lon: -366534,
            ic_city: "Santana do Matos",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.985,
            ic_february: 5.969,
            ic_march: 6.014,
            ic_april: 5.649,
            ic_may: 5.21,
            ic_june: 4.744,
            ic_july: 5.074,
            ic_august: 5.768,
            ic_september: 6.273,
            ic_october: 6.491,
            ic_november: 6.482,
            ic_december: 6.067,
            ic_yearly: 5.811,
          },
          {
            ic_uuid: "b401320f-9e7b-4fc1-b805-ed26144225ff",
            ic_lat: -91705,
            ic_lon: -362209,
            ic_city: "Santana do Mundaú",
            ic_states: "ALAGOAS",
            ic_january: 5.843,
            ic_february: 5.724,
            ic_march: 5.696,
            ic_april: 5.092,
            ic_may: 4.165,
            ic_june: 3.687,
            ic_july: 3.79,
            ic_august: 4.361,
            ic_september: 5.27,
            ic_october: 5.6,
            ic_november: 6.164,
            ic_december: 6.165,
            ic_yearly: 5.13,
          },
          {
            ic_uuid: "01239d33-6088-4331-9f47-c0bdcb85d97b",
            ic_lat: -193665,
            ic_lon: -425449,
            ic_city: "Santana do Paraíso",
            ic_states: "MINAS GERAIS",
            ic_january: 5.734,
            ic_february: 6.023,
            ic_march: 4.993,
            ic_april: 4.52,
            ic_may: 3.805,
            ic_june: 3.626,
            ic_july: 3.829,
            ic_august: 4.492,
            ic_september: 4.846,
            ic_october: 5.009,
            ic_november: 4.729,
            ic_december: 5.522,
            ic_yearly: 4.761,
          },
          {
            ic_uuid: "2c35789b-dc88-417c-b254-6d83067500b3",
            ic_lat: -69474,
            ic_lon: -415181,
            ic_city: "Santana do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.461,
            ic_february: 5.374,
            ic_march: 5.424,
            ic_april: 5.354,
            ic_may: 5.219,
            ic_june: 5.156,
            ic_july: 5.604,
            ic_august: 6.323,
            ic_september: 6.724,
            ic_october: 6.501,
            ic_november: 6.314,
            ic_december: 5.897,
            ic_yearly: 5.779,
          },
          {
            ic_uuid: "f7cd2781-eb49-435d-b068-2f9d62243d50",
            ic_lat: -19169,
            ic_lon: -43714,
            ic_city: "Santana do Riacho",
            ic_states: "MINAS GERAIS",
            ic_january: 6.019,
            ic_february: 6.242,
            ic_march: 5.345,
            ic_april: 4.931,
            ic_may: 4.443,
            ic_june: 4.222,
            ic_july: 4.547,
            ic_august: 5.299,
            ic_september: 5.739,
            ic_october: 5.816,
            ic_november: 5.455,
            ic_december: 5.836,
            ic_yearly: 5.324,
          },
          {
            ic_uuid: "d0744e57-e926-468d-bd3d-842d19273d19",
            ic_lat: -102913,
            ic_lon: -366122,
            ic_city: "Santana do São Francisco",
            ic_states: "SERGIPE",
            ic_january: 6.132,
            ic_february: 6.061,
            ic_march: 5.931,
            ic_april: 5.07,
            ic_may: 4.31,
            ic_june: 4.059,
            ic_july: 4.14,
            ic_august: 4.715,
            ic_september: 5.522,
            ic_october: 5.944,
            ic_november: 6.363,
            ic_december: 6.354,
            ic_yearly: 5.384,
          },
          {
            ic_uuid: "a60ef9c4-f31f-4e7c-9d32-9664792b72a2",
            ic_lat: -677,
            ic_lon: -367344,
            ic_city: "Santana do Seridó",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.037,
            ic_february: 6.052,
            ic_march: 6.109,
            ic_april: 5.683,
            ic_may: 5.068,
            ic_june: 4.643,
            ic_july: 4.939,
            ic_august: 5.728,
            ic_september: 6.267,
            ic_october: 6.449,
            ic_november: 6.482,
            ic_december: 6.206,
            ic_yearly: 5.805,
          },
          {
            ic_uuid: "b168cff5-d5b4-42ee-9690-4938fc9e7b30",
            ic_lat: -73856,
            ic_lon: -379895,
            ic_city: "Santana dos Garrotes",
            ic_states: "PARAÍBA",
            ic_january: 6.119,
            ic_february: 6.173,
            ic_march: 6.164,
            ic_april: 5.853,
            ic_may: 5.17,
            ic_june: 4.767,
            ic_july: 5.063,
            ic_august: 5.858,
            ic_september: 6.483,
            ic_october: 6.557,
            ic_november: 6.736,
            ic_december: 6.392,
            ic_yearly: 5.944,
          },
          {
            ic_uuid: "dd10ce6c-bc48-48be-8d00-b7c65110386c",
            ic_lat: -207873,
            ic_lon: -436953,
            ic_city: "Santana dos Montes",
            ic_states: "MINAS GERAIS",
            ic_january: 5.522,
            ic_february: 5.901,
            ic_march: 4.864,
            ic_april: 4.379,
            ic_may: 3.77,
            ic_june: 3.577,
            ic_july: 3.766,
            ic_august: 4.738,
            ic_september: 4.947,
            ic_october: 5.101,
            ic_november: 4.803,
            ic_december: 5.511,
            ic_yearly: 4.74,
          },
          {
            ic_uuid: "f0ec171e-1c82-4e37-87e0-a698e93558d4",
            ic_lat: -120251,
            ic_lon: -388658,
            ic_city: "Santanópolis",
            ic_states: "BAHIA",
            ic_january: 6.064,
            ic_february: 5.899,
            ic_march: 5.708,
            ic_april: 4.745,
            ic_may: 3.949,
            ic_june: 3.69,
            ic_july: 3.951,
            ic_august: 4.473,
            ic_september: 5.334,
            ic_october: 5.515,
            ic_november: 5.837,
            ic_december: 6.092,
            ic_yearly: 5.105,
          },
          {
            ic_uuid: "3cf7d3aa-8b27-4c15-8109-ab1aa7bdd9e4",
            ic_lat: -24389,
            ic_lon: -547,
            ic_city: "Santarém",
            ic_states: "PARÁ",
            ic_january: 4.656,
            ic_february: 4.741,
            ic_march: 4.614,
            ic_april: 4.401,
            ic_may: 4.543,
            ic_june: 4.867,
            ic_july: 4.707,
            ic_august: 5.122,
            ic_september: 5.071,
            ic_october: 5.473,
            ic_november: 5.444,
            ic_december: 4.858,
            ic_yearly: 4.875,
          },
          {
            ic_uuid: "241fc793-d1f2-4f8f-abcd-b31c98005401",
            ic_lat: -929,
            ic_lon: -473955,
            ic_city: "Santarém Novo",
            ic_states: "PARÁ",
            ic_january: 4.585,
            ic_february: 4.265,
            ic_march: 4.2,
            ic_april: 4.199,
            ic_may: 4.474,
            ic_june: 4.756,
            ic_july: 4.91,
            ic_august: 5.204,
            ic_september: 5.499,
            ic_october: 5.378,
            ic_november: 5.223,
            ic_december: 4.95,
            ic_yearly: 4.804,
          },
          {
            ic_uuid: "2f8e84b6-02d2-4c70-bd99-92f04cf43854",
            ic_lat: -291901,
            ic_lon: -548671,
            ic_city: "Santiago",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.541,
            ic_february: 6.064,
            ic_march: 5.19,
            ic_april: 4.036,
            ic_may: 2.997,
            ic_june: 2.466,
            ic_july: 2.8,
            ic_august: 3.513,
            ic_september: 4.047,
            ic_october: 5.17,
            ic_november: 6.463,
            ic_december: 6.898,
            ic_yearly: 4.682,
          },
          {
            ic_uuid: "b65289b0-c331-4d49-9a17-d74938a0cc4f",
            ic_lat: -266357,
            ic_lon: -526763,
            ic_city: "Santiago do Sul",
            ic_states: "SANTA CATARINA",
            ic_january: 6.117,
            ic_february: 5.673,
            ic_march: 5.141,
            ic_april: 4.138,
            ic_may: 3.186,
            ic_june: 2.722,
            ic_july: 3.019,
            ic_august: 3.933,
            ic_september: 4.143,
            ic_october: 5.124,
            ic_november: 6.107,
            ic_december: 6.359,
            ic_yearly: 4.638,
          },
          {
            ic_uuid: "8c7cac36-b3b0-4b0a-8ce6-4f66a2cbb867",
            ic_lat: -144949,
            ic_lon: -570095,
            ic_city: "Santo Afonso",
            ic_states: "MATO GROSSO",
            ic_january: 5.13,
            ic_february: 5.147,
            ic_march: 4.977,
            ic_april: 4.827,
            ic_may: 4.414,
            ic_june: 4.44,
            ic_july: 4.607,
            ic_august: 5.314,
            ic_september: 5.109,
            ic_october: 5.237,
            ic_november: 5.346,
            ic_december: 5.443,
            ic_yearly: 4.999,
          },
          {
            ic_uuid: "b64325aa-1e2d-4e84-a61e-706688f315cc",
            ic_lat: -125485,
            ic_lon: -38706,
            ic_city: "Santo Amaro",
            ic_states: "BAHIA",
            ic_january: 6.114,
            ic_february: 5.894,
            ic_march: 5.727,
            ic_april: 4.642,
            ic_may: 3.988,
            ic_june: 3.635,
            ic_july: 3.867,
            ic_august: 4.337,
            ic_september: 5.161,
            ic_october: 5.489,
            ic_november: 5.748,
            ic_december: 6.162,
            ic_yearly: 5.064,
          },
          {
            ic_uuid: "2a59c344-0c17-44ed-a718-897bf4abb75b",
            ic_lat: -276857,
            ic_lon: -487817,
            ic_city: "Santo Amaro da Imperatriz",
            ic_states: "SANTA CATARINA",
            ic_january: 5.506,
            ic_february: 5.244,
            ic_march: 4.484,
            ic_april: 3.661,
            ic_may: 3.014,
            ic_june: 2.573,
            ic_july: 2.696,
            ic_august: 3.407,
            ic_september: 3.472,
            ic_october: 4.086,
            ic_november: 5.276,
            ic_december: 5.699,
            ic_yearly: 4.093,
          },
          {
            ic_uuid: "253d3a0c-f26c-4ea3-8457-8ce87ab7715c",
            ic_lat: -107885,
            ic_lon: -370534,
            ic_city: "Santo Amaro das Brotas",
            ic_states: "SERGIPE",
            ic_january: 6.282,
            ic_february: 6.277,
            ic_march: 6.096,
            ic_april: 5.073,
            ic_may: 4.334,
            ic_june: 4.001,
            ic_july: 4.108,
            ic_august: 4.688,
            ic_september: 5.492,
            ic_october: 5.965,
            ic_november: 6.406,
            ic_december: 6.516,
            ic_yearly: 5.437,
          },
          {
            ic_uuid: "07f7074c-6e25-47d3-bbd1-1dc2ba95d4d1",
            ic_lat: -25009,
            ic_lon: -432534,
            ic_city: "Santo Amaro do Maranhão",
            ic_states: "MARANHÃO",
            ic_january: 5.09,
            ic_february: 5.077,
            ic_march: 4.9,
            ic_april: 4.829,
            ic_may: 4.965,
            ic_june: 5.174,
            ic_july: 5.359,
            ic_august: 5.811,
            ic_september: 6.006,
            ic_october: 5.908,
            ic_november: 5.741,
            ic_december: 5.456,
            ic_yearly: 5.36,
          },
          {
            ic_uuid: "2b0d198f-08bd-459c-8507-df1183e5d1bb",
            ic_lat: -219751,
            ic_lon: -516531,
            ic_city: "Santo Anastácio",
            ic_states: "SÃO PAULO",
            ic_january: 5.974,
            ic_february: 5.984,
            ic_march: 5.438,
            ic_april: 4.789,
            ic_may: 3.942,
            ic_june: 3.628,
            ic_july: 3.822,
            ic_august: 4.718,
            ic_september: 4.87,
            ic_october: 5.575,
            ic_november: 6.133,
            ic_december: 6.428,
            ic_yearly: 5.109,
          },
          {
            ic_uuid: "b401e0dc-a75f-48d1-8230-273fc54b7c9e",
            ic_lat: -72202,
            ic_lon: -366316,
            ic_city: "Santo André",
            ic_states: "PARAÍBA",
            ic_january: 5.958,
            ic_february: 5.951,
            ic_march: 5.957,
            ic_april: 5.545,
            ic_may: 4.905,
            ic_june: 4.356,
            ic_july: 4.65,
            ic_august: 5.345,
            ic_september: 5.983,
            ic_october: 6.279,
            ic_november: 6.407,
            ic_december: 6.133,
            ic_yearly: 5.622,
          },
          {
            ic_uuid: "d0213a82-d9fe-4a3f-bfe5-3e86f58a43f8",
            ic_lat: -236742,
            ic_lon: -465436,
            ic_city: "Santo André",
            ic_states: "SÃO PAULO",
            ic_january: 5.105,
            ic_february: 5.404,
            ic_march: 4.598,
            ic_april: 4.075,
            ic_may: 3.338,
            ic_june: 3.077,
            ic_july: 3.18,
            ic_august: 4.035,
            ic_september: 4.084,
            ic_october: 4.515,
            ic_november: 5.012,
            ic_december: 5.571,
            ic_yearly: 4.333,
          },
          {
            ic_uuid: "5648133b-7068-4667-8399-214faffd9158",
            ic_lat: -283006,
            ic_lon: -542672,
            ic_city: "Santo Ângelo",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.463,
            ic_february: 5.987,
            ic_march: 5.176,
            ic_april: 4.011,
            ic_may: 3.074,
            ic_june: 2.518,
            ic_july: 2.815,
            ic_august: 3.562,
            ic_september: 3.992,
            ic_october: 5.148,
            ic_november: 6.279,
            ic_december: 6.708,
            ic_yearly: 4.645,
          },
          {
            ic_uuid: "3bf2c93c-8702-43b2-b16c-95015845b37c",
            ic_lat: -63112,
            ic_lon: -354805,
            ic_city: "Santo Antônio",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.744,
            ic_february: 5.9,
            ic_march: 6.026,
            ic_april: 5.422,
            ic_may: 4.898,
            ic_june: 4.481,
            ic_july: 4.655,
            ic_august: 5.298,
            ic_september: 5.747,
            ic_october: 5.995,
            ic_november: 6.226,
            ic_december: 5.857,
            ic_yearly: 5.521,
          },
          {
            ic_uuid: "b5d7e224-d843-4cdf-ad5a-2ac7b53b1db8",
            ic_lat: -210869,
            ic_lon: -471468,
            ic_city: "Santo Antônio da Alegria",
            ic_states: "SÃO PAULO",
            ic_january: 5.52,
            ic_february: 5.708,
            ic_march: 4.984,
            ic_april: 4.734,
            ic_may: 4.15,
            ic_june: 3.929,
            ic_july: 4.151,
            ic_august: 5.024,
            ic_september: 5.049,
            ic_october: 5.518,
            ic_november: 5.596,
            ic_december: 5.778,
            ic_yearly: 5.012,
          },
          {
            ic_uuid: "3867bc2b-ea3e-4e8f-bb4d-bc21b2d774e8",
            ic_lat: -175589,
            ic_lon: -50635,
            ic_city: "Santo Antônio da Barra",
            ic_states: "GOIÁS",
            ic_january: 5.598,
            ic_february: 5.713,
            ic_march: 5.241,
            ic_april: 5.111,
            ic_may: 4.713,
            ic_june: 4.418,
            ic_july: 4.62,
            ic_august: 5.546,
            ic_september: 5.313,
            ic_october: 5.484,
            ic_november: 5.542,
            ic_december: 5.715,
            ic_yearly: 5.251,
          },
          {
            ic_uuid: "e6c8252f-6665-4a88-bbf4-f7e6192ae2ad",
            ic_lat: -298273,
            ic_lon: -505179,
            ic_city: "Santo Antônio da Patrulha",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.407,
            ic_february: 5.813,
            ic_march: 4.805,
            ic_april: 3.833,
            ic_may: 2.82,
            ic_june: 2.342,
            ic_july: 2.57,
            ic_august: 3.216,
            ic_september: 3.591,
            ic_october: 4.741,
            ic_november: 6.209,
            ic_december: 6.659,
            ic_yearly: 4.417,
          },
          {
            ic_uuid: "5ae8cde3-46b5-40bd-ad1c-f2550ec688b8",
            ic_lat: -232964,
            ic_lon: -500819,
            ic_city: "Santo Antônio da Platina",
            ic_states: "PARANÁ",
            ic_january: 5.807,
            ic_february: 5.849,
            ic_march: 5.233,
            ic_april: 4.639,
            ic_may: 3.672,
            ic_june: 3.353,
            ic_july: 3.59,
            ic_august: 4.562,
            ic_september: 4.712,
            ic_october: 5.38,
            ic_november: 5.992,
            ic_december: 6.377,
            ic_yearly: 4.931,
          },
          {
            ic_uuid: "d56ad2ae-efc2-4637-870d-a644c977bff4",
            ic_lat: -28511,
            ic_lon: -552279,
            ic_city: "Santo Antônio das Missões",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.65,
            ic_february: 6.179,
            ic_march: 5.271,
            ic_april: 4.041,
            ic_may: 3.097,
            ic_june: 2.521,
            ic_july: 2.841,
            ic_august: 3.6,
            ic_september: 4.106,
            ic_october: 5.224,
            ic_november: 6.437,
            ic_december: 6.855,
            ic_yearly: 4.735,
          },
          {
            ic_uuid: "2b837a46-3713-4ce2-88ea-d6c929e5563b",
            ic_lat: -164865,
            ic_lon: -493094,
            ic_city: "Santo Antônio de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.503,
            ic_february: 5.581,
            ic_march: 5.178,
            ic_april: 5.103,
            ic_may: 4.853,
            ic_june: 4.604,
            ic_july: 4.825,
            ic_august: 5.722,
            ic_september: 5.522,
            ic_october: 5.566,
            ic_november: 5.297,
            ic_december: 5.444,
            ic_yearly: 5.267,
          },
          {
            ic_uuid: "5936422c-7d5a-4043-b2a0-d54c648878f1",
            ic_lat: -129618,
            ic_lon: -392587,
            ic_city: "Santo Antônio de Jesus",
            ic_states: "BAHIA",
            ic_january: 5.984,
            ic_february: 5.92,
            ic_march: 5.644,
            ic_april: 4.587,
            ic_may: 3.984,
            ic_june: 3.604,
            ic_july: 3.775,
            ic_august: 4.328,
            ic_september: 5.123,
            ic_october: 5.329,
            ic_november: 5.612,
            ic_december: 6.008,
            ic_yearly: 4.992,
          },
          {
            ic_uuid: "ae7a6618-dbff-40ab-b5e8-97d936f46e0d",
            ic_lat: -69801,
            ic_lon: -412315,
            ic_city: "Santo Antônio de Lisboa",
            ic_states: "PIAUÍ",
            ic_january: 5.656,
            ic_february: 5.465,
            ic_march: 5.555,
            ic_april: 5.286,
            ic_may: 5.133,
            ic_june: 5.089,
            ic_july: 5.512,
            ic_august: 6.23,
            ic_september: 6.65,
            ic_october: 6.534,
            ic_november: 6.459,
            ic_december: 6.02,
            ic_yearly: 5.799,
          },
          {
            ic_uuid: "421e480e-77a8-440c-815c-a8c26e981d69",
            ic_lat: -215415,
            ic_lon: -421835,
            ic_city: "Santo Antônio de Pádua",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.048,
            ic_february: 6.269,
            ic_march: 5.212,
            ic_april: 4.484,
            ic_may: 3.753,
            ic_june: 3.477,
            ic_july: 3.604,
            ic_august: 4.397,
            ic_september: 4.805,
            ic_october: 5.148,
            ic_november: 5.045,
            ic_december: 5.934,
            ic_yearly: 4.848,
          },
          {
            ic_uuid: "cf9ecbe3-f41a-4aae-a06b-680bdf327a38",
            ic_lat: -226033,
            ic_lon: -469196,
            ic_city: "Santo Antônio de Posse",
            ic_states: "SÃO PAULO",
            ic_january: 5.551,
            ic_february: 5.823,
            ic_march: 5.161,
            ic_april: 4.669,
            ic_may: 3.874,
            ic_june: 3.59,
            ic_july: 3.793,
            ic_august: 4.719,
            ic_september: 4.904,
            ic_october: 5.536,
            ic_november: 5.755,
            ic_december: 6.148,
            ic_yearly: 4.96,
          },
          {
            ic_uuid: "3bf8e1f4-c4a0-40d0-ad8c-9e8f12c68af9",
            ic_lat: -209435,
            ic_lon: -44918,
            ic_city: "Santo Antônio do Amparo",
            ic_states: "MINAS GERAIS",
            ic_january: 5.536,
            ic_february: 5.732,
            ic_march: 4.837,
            ic_april: 4.669,
            ic_may: 4.041,
            ic_june: 3.845,
            ic_july: 4.127,
            ic_august: 5.015,
            ic_september: 5.19,
            ic_october: 5.337,
            ic_november: 5.077,
            ic_december: 5.518,
            ic_yearly: 4.91,
          },
          {
            ic_uuid: "35fc817c-8c94-4bcf-a98a-7c062df6def9",
            ic_lat: -20937,
            ic_lon: -504959,
            ic_city: "Santo Antônio do Aracanguá",
            ic_states: "SÃO PAULO",
            ic_january: 5.887,
            ic_february: 5.895,
            ic_march: 5.365,
            ic_april: 4.905,
            ic_may: 4.106,
            ic_june: 3.793,
            ic_july: 4.026,
            ic_august: 4.945,
            ic_september: 4.945,
            ic_october: 5.637,
            ic_november: 6.003,
            ic_december: 6.278,
            ic_yearly: 5.149,
          },
          {
            ic_uuid: "5c3c0fa9-e89f-4889-9e1c-9bdbf9fd6ca2",
            ic_lat: -217611,
            ic_lon: -428118,
            ic_city: "Santo Antônio do Aventureiro",
            ic_states: "MINAS GERAIS",
            ic_january: 5.614,
            ic_february: 5.888,
            ic_march: 4.863,
            ic_april: 4.241,
            ic_may: 3.527,
            ic_june: 3.295,
            ic_july: 3.498,
            ic_august: 4.201,
            ic_september: 4.574,
            ic_october: 4.864,
            ic_november: 4.659,
            ic_december: 5.504,
            ic_yearly: 4.561,
          },
          {
            ic_uuid: "f2f99c8f-0a6e-4810-a0ed-537339f503a1",
            ic_lat: -227355,
            ic_lon: -523445,
            ic_city: "Santo Antônio do Caiuá",
            ic_states: "PARANÁ",
            ic_january: 6.045,
            ic_february: 5.997,
            ic_march: 5.486,
            ic_april: 4.692,
            ic_may: 3.753,
            ic_june: 3.422,
            ic_july: 3.616,
            ic_august: 4.559,
            ic_september: 4.751,
            ic_october: 5.463,
            ic_november: 6.202,
            ic_december: 6.474,
            ic_yearly: 5.038,
          },
          {
            ic_uuid: "fbe67d40-b0ef-4ab7-8635-4c10450ea55e",
            ic_lat: -159478,
            ic_lon: -48265,
            ic_city: "Santo Antônio do Descoberto",
            ic_states: "GOIÁS",
            ic_january: 5.391,
            ic_february: 5.625,
            ic_march: 5.067,
            ic_april: 4.988,
            ic_may: 4.904,
            ic_june: 4.82,
            ic_july: 5.03,
            ic_august: 5.926,
            ic_september: 5.621,
            ic_october: 5.521,
            ic_november: 5.124,
            ic_december: 5.437,
            ic_yearly: 5.288,
          },
          {
            ic_uuid: "076f5ed8-3c67-4f85-a7bd-26343e05dabb",
            ic_lat: -203189,
            ic_lon: -426051,
            ic_city: "Santo Antônio do Grama",
            ic_states: "MINAS GERAIS",
            ic_january: 5.808,
            ic_february: 6.121,
            ic_march: 5.14,
            ic_april: 4.548,
            ic_may: 3.906,
            ic_june: 3.704,
            ic_july: 3.902,
            ic_august: 4.656,
            ic_september: 4.966,
            ic_october: 5.143,
            ic_november: 4.922,
            ic_december: 5.652,
            ic_yearly: 4.872,
          },
          {
            ic_uuid: "ac67901d-4e73-4d71-9b50-d5e4bcfdd1ba",
            ic_lat: -31032,
            ic_lon: -679461,
            ic_city: "Santo Antônio do Içá",
            ic_states: "AMAZONAS",
            ic_january: 4.333,
            ic_february: 4.702,
            ic_march: 4.5,
            ic_april: 4.396,
            ic_may: 4.01,
            ic_june: 4.135,
            ic_july: 4.161,
            ic_august: 4.849,
            ic_september: 5.029,
            ic_october: 4.995,
            ic_november: 4.763,
            ic_december: 4.421,
            ic_yearly: 4.524,
          },
          {
            ic_uuid: "d2445dce-5bd3-42fb-ba6c-8052f63c3544",
            ic_lat: -18469,
            ic_lon: -433037,
            ic_city: "Santo Antônio do Itambé",
            ic_states: "MINAS GERAIS",
            ic_january: 5.686,
            ic_february: 5.969,
            ic_march: 4.96,
            ic_april: 4.482,
            ic_may: 4.023,
            ic_june: 3.897,
            ic_july: 4.098,
            ic_august: 4.864,
            ic_september: 5.288,
            ic_october: 5.341,
            ic_november: 4.794,
            ic_december: 5.431,
            ic_yearly: 4.903,
          },
          {
            ic_uuid: "11ad16b0-2b93-455f-803f-b5c5aa697514",
            ic_lat: -165337,
            ic_lon: -401821,
            ic_city: "Santo Antônio do Jacinto",
            ic_states: "MINAS GERAIS",
            ic_january: 5.816,
            ic_february: 5.994,
            ic_march: 5.263,
            ic_april: 4.665,
            ic_may: 3.855,
            ic_june: 3.53,
            ic_july: 3.649,
            ic_august: 4.24,
            ic_september: 4.93,
            ic_october: 5.097,
            ic_november: 5.042,
            ic_december: 5.903,
            ic_yearly: 4.832,
          },
          {
            ic_uuid: "7fb62bb7-cd0e-4d5a-90cb-3660eb2a8157",
            ic_lat: -221126,
            ic_lon: -466849,
            ic_city: "Santo Antônio do Jardim",
            ic_states: "SÃO PAULO",
            ic_january: 5.38,
            ic_february: 5.581,
            ic_march: 4.869,
            ic_april: 4.598,
            ic_may: 3.947,
            ic_june: 3.717,
            ic_july: 3.891,
            ic_august: 4.797,
            ic_september: 4.963,
            ic_october: 5.367,
            ic_november: 5.485,
            ic_december: 5.713,
            ic_yearly: 4.859,
          },
          {
            ic_uuid: "e74c6cf7-72d8-4f04-aa4d-4de9b34baadd",
            ic_lat: -148087,
            ic_lon: -536074,
            ic_city: "Santo Antônio do Leste",
            ic_states: "MATO GROSSO",
            ic_january: 5.379,
            ic_february: 5.449,
            ic_march: 5.145,
            ic_april: 5.162,
            ic_may: 4.83,
            ic_june: 4.658,
            ic_july: 4.892,
            ic_august: 5.5,
            ic_september: 5.27,
            ic_october: 5.298,
            ic_november: 5.336,
            ic_december: 5.432,
            ic_yearly: 5.196,
          },
          {
            ic_uuid: "b9a8e23e-4dfb-46e9-89fd-bca2fec7ad63",
            ic_lat: -158628,
            ic_lon: -560792,
            ic_city: "Santo Antônio do Leverger",
            ic_states: "MATO GROSSO",
            ic_january: 5.549,
            ic_february: 5.471,
            ic_march: 5.272,
            ic_april: 5.039,
            ic_may: 4.459,
            ic_june: 4.353,
            ic_july: 4.487,
            ic_august: 5.437,
            ic_september: 5.085,
            ic_october: 5.291,
            ic_november: 5.565,
            ic_december: 5.77,
            ic_yearly: 5.148,
          },
          {
            ic_uuid: "8ca4323b-0d53-4557-87e0-380eb78df53c",
            ic_lat: -200855,
            ic_lon: -452951,
            ic_city: "Santo Antônio do Monte",
            ic_states: "MINAS GERAIS",
            ic_january: 5.756,
            ic_february: 5.956,
            ic_march: 4.985,
            ic_april: 4.87,
            ic_may: 4.288,
            ic_june: 4.074,
            ic_july: 4.324,
            ic_august: 5.213,
            ic_september: 5.462,
            ic_october: 5.545,
            ic_november: 5.267,
            ic_december: 5.625,
            ic_yearly: 5.114,
          },
          {
            ic_uuid: "96db947f-1e6c-48ca-a9ed-5f525daf393b",
            ic_lat: -28496,
            ic_lon: -520272,
            ic_city: "Santo Antônio do Palma",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.137,
            ic_february: 5.741,
            ic_march: 4.9,
            ic_april: 3.954,
            ic_may: 2.958,
            ic_june: 2.488,
            ic_july: 2.791,
            ic_august: 3.516,
            ic_september: 3.782,
            ic_october: 4.926,
            ic_november: 6.124,
            ic_december: 6.5,
            ic_yearly: 4.485,
          },
          {
            ic_uuid: "95d5174e-a9d7-486c-8028-b1350e3c2e42",
            ic_lat: -23494,
            ic_lon: -506459,
            ic_city: "Santo Antônio do Paraíso",
            ic_states: "PARANÁ",
            ic_january: 5.876,
            ic_february: 5.751,
            ic_march: 5.19,
            ic_april: 4.559,
            ic_may: 3.647,
            ic_june: 3.34,
            ic_july: 3.532,
            ic_august: 4.509,
            ic_september: 4.645,
            ic_october: 5.292,
            ic_november: 6.006,
            ic_december: 6.305,
            ic_yearly: 4.888,
          },
          {
            ic_uuid: "0bbf5df4-ed1b-48db-9734-bba4369772aa",
            ic_lat: -228275,
            ic_lon: -456634,
            ic_city: "Santo Antônio do Pinhal",
            ic_states: "SÃO PAULO",
            ic_january: 5.231,
            ic_february: 5.576,
            ic_march: 4.772,
            ic_april: 4.422,
            ic_may: 3.719,
            ic_june: 3.502,
            ic_july: 3.664,
            ic_august: 4.62,
            ic_september: 4.703,
            ic_october: 5.192,
            ic_november: 5.196,
            ic_december: 5.723,
            ic_yearly: 4.693,
          },
          {
            ic_uuid: "38fc49cb-f6f4-4e42-9afe-d2622e6e60e0",
            ic_lat: -28396,
            ic_lon: -526909,
            ic_city: "Santo Antônio do Planalto",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.273,
            ic_february: 5.867,
            ic_march: 4.989,
            ic_april: 3.993,
            ic_may: 3.023,
            ic_june: 2.495,
            ic_july: 2.823,
            ic_august: 3.572,
            ic_september: 3.85,
            ic_october: 5.005,
            ic_november: 6.205,
            ic_december: 6.613,
            ic_yearly: 4.559,
          },
          {
            ic_uuid: "706bf7fa-8675-4261-8e12-b0557f4fa9b1",
            ic_lat: -153421,
            ic_lon: -426219,
            ic_city: "Santo Antônio do Retiro",
            ic_states: "MINAS GERAIS",
            ic_january: 6.115,
            ic_february: 6.328,
            ic_march: 5.46,
            ic_april: 4.871,
            ic_may: 4.3,
            ic_june: 4.028,
            ic_july: 4.392,
            ic_august: 5.158,
            ic_september: 5.813,
            ic_october: 5.807,
            ic_november: 5.333,
            ic_december: 6.064,
            ic_yearly: 5.306,
          },
          {
            ic_uuid: "df474762-a1d2-47b3-a59d-6a7a8dc39ed6",
            ic_lat: -19228,
            ic_lon: -43254,
            ic_city: "Santo Antônio do Rio Abaixo",
            ic_states: "MINAS GERAIS",
            ic_january: 5.598,
            ic_february: 5.861,
            ic_march: 4.92,
            ic_april: 4.506,
            ic_may: 3.852,
            ic_june: 3.671,
            ic_july: 3.878,
            ic_august: 4.687,
            ic_september: 4.98,
            ic_october: 5.1,
            ic_november: 4.777,
            ic_december: 5.482,
            ic_yearly: 4.776,
          },
          {
            ic_uuid: "b4467de7-14df-4979-a5c9-5459cb870e23",
            ic_lat: -260742,
            ic_lon: -537256,
            ic_city: "Santo Antônio do Sudoeste",
            ic_states: "PARANÁ",
            ic_january: 6.403,
            ic_february: 5.84,
            ic_march: 5.192,
            ic_april: 4.215,
            ic_may: 3.25,
            ic_june: 2.799,
            ic_july: 3.087,
            ic_august: 4.01,
            ic_september: 4.229,
            ic_october: 5.233,
            ic_november: 6.096,
            ic_december: 6.477,
            ic_yearly: 4.736,
          },
          {
            ic_uuid: "601a6c20-56e6-4849-977c-c201726ff83e",
            ic_lat: -11526,
            ic_lon: -481318,
            ic_city: "Santo Antônio do Tauá",
            ic_states: "PARÁ",
            ic_january: 4.31,
            ic_february: 4.159,
            ic_march: 4.087,
            ic_april: 4.227,
            ic_may: 4.501,
            ic_june: 4.841,
            ic_july: 4.975,
            ic_august: 5.223,
            ic_september: 5.32,
            ic_october: 5.15,
            ic_november: 4.896,
            ic_december: 4.522,
            ic_yearly: 4.684,
          },
          {
            ic_uuid: "4e451abf-3a93-4db7-af39-067e34ee98c9",
            ic_lat: -48705,
            ic_lon: -443591,
            ic_city: "Santo Antônio dos Lopes",
            ic_states: "MARANHÃO",
            ic_january: 4.825,
            ic_february: 4.881,
            ic_march: 4.947,
            ic_april: 4.846,
            ic_may: 4.938,
            ic_june: 5.091,
            ic_july: 5.395,
            ic_august: 6.019,
            ic_september: 6.286,
            ic_october: 5.84,
            ic_november: 5.47,
            ic_december: 5.027,
            ic_yearly: 5.297,
          },
          {
            ic_uuid: "ec9e035e-497e-4013-9f29-42b6b8bf3fa8",
            ic_lat: -60469,
            ic_lon: -427127,
            ic_city: "Santo Antônio dos Milagres",
            ic_states: "PIAUÍ",
            ic_january: 5.014,
            ic_february: 5.172,
            ic_march: 5.164,
            ic_april: 5.065,
            ic_may: 5.189,
            ic_june: 5.367,
            ic_july: 5.671,
            ic_august: 6.265,
            ic_september: 6.582,
            ic_october: 6.29,
            ic_november: 5.933,
            ic_december: 5.537,
            ic_yearly: 5.604,
          },
          {
            ic_uuid: "5b797558-1632-49c2-b26f-23ef4cc1f1e3",
            ic_lat: -27853,
            ic_lon: -537781,
            ic_city: "Santo Augusto",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.395,
            ic_february: 5.989,
            ic_march: 5.078,
            ic_april: 4.057,
            ic_may: 3.116,
            ic_june: 2.577,
            ic_july: 2.903,
            ic_august: 3.685,
            ic_september: 3.989,
            ic_october: 5.146,
            ic_november: 6.199,
            ic_december: 6.671,
            ic_yearly: 4.65,
          },
          {
            ic_uuid: "18757f74-2d05-485b-a228-93cbfe59286f",
            ic_lat: -278267,
            ic_lon: -546624,
            ic_city: "Santo Cristo",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.486,
            ic_february: 6.001,
            ic_march: 5.166,
            ic_april: 4.086,
            ic_may: 3.095,
            ic_june: 2.519,
            ic_july: 2.827,
            ic_august: 3.653,
            ic_september: 4.039,
            ic_october: 5.168,
            ic_november: 6.269,
            ic_december: 6.713,
            ic_yearly: 4.669,
          },
          {
            ic_uuid: "70e3bb71-15ce-4fbb-950b-415dcc59864a",
            ic_lat: -124284,
            ic_lon: -392509,
            ic_city: "Santo Estêvão",
            ic_states: "BAHIA",
            ic_january: 6.03,
            ic_february: 5.931,
            ic_march: 5.777,
            ic_april: 4.71,
            ic_may: 3.998,
            ic_june: 3.693,
            ic_july: 3.876,
            ic_august: 4.485,
            ic_september: 5.222,
            ic_october: 5.453,
            ic_november: 5.698,
            ic_december: 5.999,
            ic_yearly: 5.073,
          },
          {
            ic_uuid: "9f85555d-270b-4299-8f8c-58a3f18008fa",
            ic_lat: -218503,
            ic_lon: -513935,
            ic_city: "Santo Expedito",
            ic_states: "SÃO PAULO",
            ic_january: 5.919,
            ic_february: 5.953,
            ic_march: 5.439,
            ic_april: 4.829,
            ic_may: 3.981,
            ic_june: 3.682,
            ic_july: 3.84,
            ic_august: 4.799,
            ic_september: 4.823,
            ic_october: 5.532,
            ic_november: 6.051,
            ic_december: 6.364,
            ic_yearly: 5.101,
          },
          {
            ic_uuid: "36ce2ca4-558e-4469-87e0-82930e64995d",
            ic_lat: -279079,
            ic_lon: -516438,
            ic_city: "Santo Expedito do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.293,
            ic_february: 5.782,
            ic_march: 4.977,
            ic_april: 4.042,
            ic_may: 3.021,
            ic_june: 2.594,
            ic_july: 2.907,
            ic_august: 3.692,
            ic_september: 3.91,
            ic_october: 5.001,
            ic_november: 6.223,
            ic_december: 6.523,
            ic_yearly: 4.581,
          },
          {
            ic_uuid: "a1a62342-fe94-470d-b98a-c8b575255321",
            ic_lat: -182972,
            ic_lon: -442232,
            ic_city: "Santo Hipólito",
            ic_states: "MINAS GERAIS",
            ic_january: 6.15,
            ic_february: 6.458,
            ic_march: 5.552,
            ic_april: 5.3,
            ic_may: 4.708,
            ic_june: 4.39,
            ic_july: 4.712,
            ic_august: 5.328,
            ic_september: 5.855,
            ic_october: 5.977,
            ic_november: 5.531,
            ic_december: 5.994,
            ic_yearly: 5.496,
          },
          {
            ic_uuid: "228ce4ac-d03c-4767-97c9-edd0c37c002d",
            ic_lat: -22698,
            ic_lon: -51794,
            ic_city: "Santo Inácio",
            ic_states: "PARANÁ",
            ic_january: 5.972,
            ic_february: 5.999,
            ic_march: 5.503,
            ic_april: 4.748,
            ic_may: 3.77,
            ic_june: 3.452,
            ic_july: 3.668,
            ic_august: 4.586,
            ic_september: 4.78,
            ic_october: 5.499,
            ic_november: 6.151,
            ic_december: 6.475,
            ic_yearly: 5.05,
          },
          {
            ic_uuid: "e89571cf-e35f-410d-a7d4-c367b415a1fc",
            ic_lat: -7424,
            ic_lon: -419131,
            ic_city: "Santo Inácio do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.601,
            ic_february: 5.478,
            ic_march: 5.504,
            ic_april: 5.29,
            ic_may: 5.274,
            ic_june: 5.281,
            ic_july: 5.593,
            ic_august: 6.287,
            ic_september: 6.674,
            ic_october: 6.543,
            ic_november: 6.277,
            ic_december: 5.881,
            ic_yearly: 5.807,
          },
          {
            ic_uuid: "ffe75335-6d97-4f27-8a7f-c787e0e09077",
            ic_lat: -21638,
            ic_lon: -504999,
            ic_city: "Santópolis do Aguapeí",
            ic_states: "SÃO PAULO",
            ic_january: 5.905,
            ic_february: 5.896,
            ic_march: 5.346,
            ic_april: 4.821,
            ic_may: 3.979,
            ic_june: 3.692,
            ic_july: 3.924,
            ic_august: 4.832,
            ic_september: 4.864,
            ic_october: 5.58,
            ic_november: 6.054,
            ic_december: 6.32,
            ic_yearly: 5.101,
          },
          {
            ic_uuid: "0aff60b8-a938-4a11-bbc3-7eb575fb0ba8",
            ic_lat: -23954,
            ic_lon: -463354,
            ic_city: "Santos",
            ic_states: "SÃO PAULO",
            ic_january: 5.311,
            ic_february: 5.443,
            ic_march: 4.579,
            ic_april: 4.028,
            ic_may: 3.26,
            ic_june: 2.885,
            ic_july: 2.905,
            ic_august: 3.583,
            ic_september: 3.56,
            ic_october: 3.983,
            ic_november: 4.838,
            ic_december: 5.328,
            ic_yearly: 4.142,
          },
          {
            ic_uuid: "6bd02b71-0c9d-4d7f-8076-f6284f014cb1",
            ic_lat: -214639,
            ic_lon: -435502,
            ic_city: "Santos Dumont",
            ic_states: "MINAS GERAIS",
            ic_january: 5.326,
            ic_february: 5.77,
            ic_march: 4.697,
            ic_april: 4.159,
            ic_may: 3.557,
            ic_june: 3.422,
            ic_july: 3.603,
            ic_august: 4.38,
            ic_september: 4.639,
            ic_october: 4.823,
            ic_november: 4.614,
            ic_december: 5.38,
            ic_yearly: 4.531,
          },
          {
            ic_uuid: "6222994e-622b-4c6b-ae76-7a9fee571689",
            ic_lat: -40475,
            ic_lon: -408599,
            ic_city: "São Benedito",
            ic_states: "CEARÁ",
            ic_january: 4.952,
            ic_february: 4.972,
            ic_march: 4.983,
            ic_april: 4.552,
            ic_may: 4.737,
            ic_june: 4.856,
            ic_july: 5.286,
            ic_august: 6.072,
            ic_september: 6.54,
            ic_october: 6.371,
            ic_november: 6.207,
            ic_december: 5.484,
            ic_yearly: 5.418,
          },
          {
            ic_uuid: "d2c0d647-4a82-4546-8cbc-a50e3136acc7",
            ic_lat: -33355,
            ic_lon: -435291,
            ic_city: "São Benedito do Rio Preto",
            ic_states: "MARANHÃO",
            ic_january: 4.811,
            ic_february: 4.866,
            ic_march: 4.879,
            ic_april: 4.814,
            ic_may: 4.922,
            ic_june: 5.198,
            ic_july: 5.351,
            ic_august: 5.971,
            ic_september: 6.308,
            ic_october: 6.132,
            ic_november: 5.801,
            ic_december: 5.281,
            ic_yearly: 5.361,
          },
          {
            ic_uuid: "865f20d1-d4d2-4aa7-8b59-183ab87215f7",
            ic_lat: -8817,
            ic_lon: -359456,
            ic_city: "São Benedito do Sul",
            ic_states: "PERNAMBUCO",
            ic_january: 5.765,
            ic_february: 5.656,
            ic_march: 5.705,
            ic_april: 5.046,
            ic_may: 4.131,
            ic_june: 3.725,
            ic_july: 3.822,
            ic_august: 4.421,
            ic_september: 5.24,
            ic_october: 5.543,
            ic_november: 6.022,
            ic_december: 6.06,
            ic_yearly: 5.095,
          },
          {
            ic_uuid: "cf030a64-8248-406f-8093-c0c728f01afe",
            ic_lat: -68904,
            ic_lon: -377271,
            ic_city: "São Bentinho",
            ic_states: "PARAÍBA",
            ic_january: 6.193,
            ic_february: 6.214,
            ic_march: 6.288,
            ic_april: 5.877,
            ic_may: 5.277,
            ic_june: 4.879,
            ic_july: 5.068,
            ic_august: 5.9,
            ic_september: 6.514,
            ic_october: 6.689,
            ic_november: 6.784,
            ic_december: 6.394,
            ic_yearly: 6.006,
          },
          {
            ic_uuid: "e4fcbedf-70fd-4b46-886e-9dab2dcd862f",
            ic_lat: -26982,
            ic_lon: -448293,
            ic_city: "São Bento",
            ic_states: "MARANHÃO",
            ic_january: 4.66,
            ic_february: 4.838,
            ic_march: 4.799,
            ic_april: 4.85,
            ic_may: 4.84,
            ic_june: 5.117,
            ic_july: 5.175,
            ic_august: 5.646,
            ic_september: 5.798,
            ic_october: 5.565,
            ic_november: 5.387,
            ic_december: 5.0,
            ic_yearly: 5.14,
          },
          {
            ic_uuid: "ab8628df-7c15-4d54-aee4-8a902cc2eeef",
            ic_lat: -64857,
            ic_lon: -374491,
            ic_city: "São Bento",
            ic_states: "PARAÍBA",
            ic_january: 6.219,
            ic_february: 6.233,
            ic_march: 6.237,
            ic_april: 5.905,
            ic_may: 5.301,
            ic_june: 4.854,
            ic_july: 5.131,
            ic_august: 5.877,
            ic_september: 6.439,
            ic_october: 6.624,
            ic_november: 6.612,
            ic_december: 6.313,
            ic_yearly: 5.979,
          },
          {
            ic_uuid: "64281115-5579-40c6-899c-ec227ba4ba4b",
            ic_lat: -215844,
            ic_lon: -450703,
            ic_city: "São Bento Abade",
            ic_states: "MINAS GERAIS",
            ic_january: 5.463,
            ic_february: 5.639,
            ic_march: 4.785,
            ic_april: 4.528,
            ic_may: 3.882,
            ic_june: 3.702,
            ic_july: 3.935,
            ic_august: 4.787,
            ic_september: 5.035,
            ic_october: 5.337,
            ic_november: 5.222,
            ic_december: 5.558,
            ic_yearly: 4.823,
          },
          {
            ic_uuid: "0068e89b-0fb1-4117-9160-e7449ce98aaf",
            ic_lat: -51094,
            ic_lon: -359779,
            ic_city: "São Bento do Norte",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.883,
            ic_february: 5.912,
            ic_march: 5.935,
            ic_april: 5.413,
            ic_may: 5.083,
            ic_june: 4.756,
            ic_july: 4.893,
            ic_august: 5.566,
            ic_september: 5.826,
            ic_october: 6.039,
            ic_november: 6.275,
            ic_december: 5.959,
            ic_yearly: 5.629,
          },
          {
            ic_uuid: "64b2366f-dbed-46e2-9463-8dd1100997b4",
            ic_lat: -226842,
            ic_lon: -457291,
            ic_city: "São Bento do Sapucaí",
            ic_states: "SÃO PAULO",
            ic_january: 5.228,
            ic_february: 5.599,
            ic_march: 4.818,
            ic_april: 4.445,
            ic_may: 3.742,
            ic_june: 3.492,
            ic_july: 3.702,
            ic_august: 4.651,
            ic_september: 4.788,
            ic_october: 5.255,
            ic_november: 5.278,
            ic_december: 5.699,
            ic_yearly: 4.725,
          },
          {
            ic_uuid: "70834077-44e5-4ace-8bed-87e2b2aa454b",
            ic_lat: -2625,
            ic_lon: -493835,
            ic_city: "São Bento do Sul",
            ic_states: "SANTA CATARINA",
            ic_january: 5.28,
            ic_february: 4.989,
            ic_march: 4.402,
            ic_april: 3.617,
            ic_may: 2.942,
            ic_june: 2.576,
            ic_july: 2.787,
            ic_august: 3.679,
            ic_september: 3.707,
            ic_october: 4.244,
            ic_november: 5.098,
            ic_december: 5.45,
            ic_yearly: 4.064,
          },
          {
            ic_uuid: "710058a5-115c-4d0c-82fc-1d6bd207f5aa",
            ic_lat: -60295,
            ic_lon: -479238,
            ic_city: "São Bento do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 4.57,
            ic_february: 4.732,
            ic_march: 4.736,
            ic_april: 4.76,
            ic_may: 4.844,
            ic_june: 5.151,
            ic_july: 5.329,
            ic_august: 5.901,
            ic_september: 5.625,
            ic_october: 5.055,
            ic_november: 4.748,
            ic_december: 4.656,
            ic_yearly: 5.009,
          },
          {
            ic_uuid: "d6f836bb-abb4-4499-a2a2-f2845a0f45a6",
            ic_lat: -63422,
            ic_lon: -360856,
            ic_city: "São Bento do Trairí",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.705,
            ic_february: 5.818,
            ic_march: 5.85,
            ic_april: 5.435,
            ic_may: 4.884,
            ic_june: 4.349,
            ic_july: 4.534,
            ic_august: 5.307,
            ic_september: 5.775,
            ic_october: 6.007,
            ic_november: 6.166,
            ic_december: 5.778,
            ic_yearly: 5.467,
          },
          {
            ic_uuid: "8b7026c8-9ac9-4743-aaa3-26975bea496f",
            ic_lat: -85268,
            ic_lon: -364468,
            ic_city: "São Bento do Una",
            ic_states: "PERNAMBUCO",
            ic_january: 5.97,
            ic_february: 5.798,
            ic_march: 5.853,
            ic_april: 5.285,
            ic_may: 4.395,
            ic_june: 3.886,
            ic_july: 4.033,
            ic_august: 4.707,
            ic_september: 5.49,
            ic_october: 5.838,
            ic_november: 6.307,
            ic_december: 6.182,
            ic_yearly: 5.312,
          },
          {
            ic_uuid: "06220178-94a0-450a-983b-2e360f8bbc7f",
            ic_lat: -264744,
            ic_lon: -529691,
            ic_city: "São Bernardino",
            ic_states: "SANTA CATARINA",
            ic_january: 6.16,
            ic_february: 5.669,
            ic_march: 5.075,
            ic_april: 4.12,
            ic_may: 3.191,
            ic_june: 2.733,
            ic_july: 3.02,
            ic_august: 3.943,
            ic_september: 4.152,
            ic_october: 5.1,
            ic_november: 6.038,
            ic_december: 6.288,
            ic_yearly: 4.624,
          },
          {
            ic_uuid: "f669b24a-e5ea-46ec-bf00-56d371a677aa",
            ic_lat: -33726,
            ic_lon: -424195,
            ic_city: "São Bernardo",
            ic_states: "MARANHÃO",
            ic_january: 5.212,
            ic_february: 5.247,
            ic_march: 5.285,
            ic_april: 5.064,
            ic_may: 5.117,
            ic_june: 5.304,
            ic_july: 5.505,
            ic_august: 6.017,
            ic_september: 6.342,
            ic_october: 6.407,
            ic_november: 6.054,
            ic_december: 5.506,
            ic_yearly: 5.588,
          },
          {
            ic_uuid: "e3b5a968-9872-4f3f-8250-95662f559472",
            ic_lat: -236919,
            ic_lon: -46565,
            ic_city: "São Bernardo do Campo",
            ic_states: "SÃO PAULO",
            ic_january: 5.105,
            ic_february: 5.404,
            ic_march: 4.598,
            ic_april: 4.075,
            ic_may: 3.338,
            ic_june: 3.077,
            ic_july: 3.18,
            ic_august: 4.035,
            ic_september: 4.084,
            ic_october: 4.515,
            ic_november: 5.012,
            ic_december: 5.571,
            ic_yearly: 4.333,
          },
          {
            ic_uuid: "a63accda-7797-4f30-8bd0-f1d1d8450e8e",
            ic_lat: -279014,
            ic_lon: -48933,
            ic_city: "São Bonifácio",
            ic_states: "SANTA CATARINA",
            ic_january: 5.366,
            ic_february: 5.017,
            ic_march: 4.35,
            ic_april: 3.63,
            ic_may: 2.927,
            ic_june: 2.55,
            ic_july: 2.722,
            ic_august: 3.4,
            ic_september: 3.447,
            ic_october: 4.06,
            ic_november: 5.243,
            ic_december: 5.58,
            ic_yearly: 4.024,
          },
          {
            ic_uuid: "fd62b741-1022-4bb8-8de1-0017a7fa49a9",
            ic_lat: -286583,
            ic_lon: -560041,
            ic_city: "São Borja",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.834,
            ic_february: 6.191,
            ic_march: 5.273,
            ic_april: 4.038,
            ic_may: 3.055,
            ic_june: 2.505,
            ic_july: 2.827,
            ic_august: 3.586,
            ic_september: 4.13,
            ic_october: 5.34,
            ic_november: 6.466,
            ic_december: 7.014,
            ic_yearly: 4.771,
          },
          {
            ic_uuid: "8568ee64-fb9d-4ea1-a92c-00657613a5f5",
            ic_lat: -101215,
            ic_lon: -369019,
            ic_city: "São Brás",
            ic_states: "ALAGOAS",
            ic_january: 6.221,
            ic_february: 6.007,
            ic_march: 5.932,
            ic_april: 5.205,
            ic_may: 4.363,
            ic_june: 4.123,
            ic_july: 4.185,
            ic_august: 4.773,
            ic_september: 5.552,
            ic_october: 5.852,
            ic_november: 6.291,
            ic_december: 6.284,
            ic_yearly: 5.399,
          },
          {
            ic_uuid: "f9c0c9ed-3c44-49c2-937b-8b416c945502",
            ic_lat: -206246,
            ic_lon: -439518,
            ic_city: "São Brás do Suaçuí",
            ic_states: "MINAS GERAIS",
            ic_january: 5.736,
            ic_february: 5.893,
            ic_march: 4.989,
            ic_april: 4.599,
            ic_may: 4.02,
            ic_june: 3.812,
            ic_july: 4.001,
            ic_august: 4.969,
            ic_september: 5.193,
            ic_october: 5.344,
            ic_november: 5.096,
            ic_december: 5.56,
            ic_yearly: 4.934,
          },
          {
            ic_uuid: "7b961472-dfb9-4be7-ab3d-0a3afa765009",
            ic_lat: -90648,
            ic_lon: -430016,
            ic_city: "São Braz do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.953,
            ic_february: 5.687,
            ic_march: 5.575,
            ic_april: 5.432,
            ic_may: 5.29,
            ic_june: 5.299,
            ic_july: 5.677,
            ic_august: 6.402,
            ic_september: 6.728,
            ic_october: 6.492,
            ic_november: 6.132,
            ic_december: 5.972,
            ic_yearly: 5.886,
          },
          {
            ic_uuid: "2cada078-17bf-4bd2-9c66-cd8742d3caf7",
            ic_lat: -7481,
            ic_lon: -480202,
            ic_city: "São Caetano de Odivelas",
            ic_states: "PARÁ",
            ic_january: 4.673,
            ic_february: 4.247,
            ic_march: 4.209,
            ic_april: 4.277,
            ic_may: 4.499,
            ic_june: 4.811,
            ic_july: 4.941,
            ic_august: 5.372,
            ic_september: 5.646,
            ic_october: 5.608,
            ic_november: 5.542,
            ic_december: 5.137,
            ic_yearly: 4.913,
          },
          {
            ic_uuid: "e1154f70-ef06-48d6-8a24-0666dc4f3976",
            ic_lat: -236233,
            ic_lon: -465552,
            ic_city: "São Caetano do Sul",
            ic_states: "SÃO PAULO",
            ic_january: 5.185,
            ic_february: 5.438,
            ic_march: 4.672,
            ic_april: 4.135,
            ic_may: 3.395,
            ic_june: 3.157,
            ic_july: 3.255,
            ic_august: 4.161,
            ic_september: 4.178,
            ic_october: 4.688,
            ic_november: 5.107,
            ic_december: 5.652,
            ic_yearly: 4.419,
          },
          {
            ic_uuid: "2c5622fa-97dd-4f63-805d-b5b7216e78e7",
            ic_lat: -83284,
            ic_lon: -361395,
            ic_city: "São Caitano",
            ic_states: "PERNAMBUCO",
            ic_january: 5.83,
            ic_february: 5.689,
            ic_march: 5.735,
            ic_april: 5.196,
            ic_may: 4.408,
            ic_june: 3.997,
            ic_july: 4.072,
            ic_august: 4.815,
            ic_september: 5.503,
            ic_october: 5.793,
            ic_november: 6.164,
            ic_december: 5.954,
            ic_yearly: 5.263,
          },
          {
            ic_uuid: "3be98b71-7622-4e03-98a3-53172e3ab3a8",
            ic_lat: -270802,
            ic_lon: -530042,
            ic_city: "São Carlos",
            ic_states: "SANTA CATARINA",
            ic_january: 6.246,
            ic_february: 5.795,
            ic_march: 5.109,
            ic_april: 4.077,
            ic_may: 3.097,
            ic_june: 2.594,
            ic_july: 2.895,
            ic_august: 3.746,
            ic_september: 4.016,
            ic_october: 5.143,
            ic_november: 6.16,
            ic_december: 6.52,
            ic_yearly: 4.617,
          },
          {
            ic_uuid: "94a738d0-4866-4f7e-a2fb-270608888ca7",
            ic_lat: -220179,
            ic_lon: -478864,
            ic_city: "São Carlos",
            ic_states: "SÃO PAULO",
            ic_january: 5.541,
            ic_february: 5.806,
            ic_march: 5.063,
            ic_april: 4.672,
            ic_may: 3.949,
            ic_june: 3.714,
            ic_july: 3.91,
            ic_august: 4.836,
            ic_september: 4.945,
            ic_october: 5.532,
            ic_november: 5.692,
            ic_december: 5.994,
            ic_yearly: 4.971,
          },
          {
            ic_uuid: "3f5b7746-000b-46bb-b219-b5fea3f3b129",
            ic_lat: -233163,
            ic_lon: -524766,
            ic_city: "São Carlos do Ivaí",
            ic_states: "PARANÁ",
            ic_january: 5.957,
            ic_february: 5.871,
            ic_march: 5.372,
            ic_april: 4.597,
            ic_may: 3.624,
            ic_june: 3.275,
            ic_july: 3.498,
            ic_august: 4.425,
            ic_september: 4.676,
            ic_october: 5.429,
            ic_november: 6.136,
            ic_december: 6.422,
            ic_yearly: 4.94,
          },
          {
            ic_uuid: "a1b354d7-c2a6-4826-bd9c-cdecd6454a68",
            ic_lat: -110097,
            ic_lon: -372082,
            ic_city: "São Cristóvão",
            ic_states: "SERGIPE",
            ic_january: 6.263,
            ic_february: 6.162,
            ic_march: 6.012,
            ic_april: 5.017,
            ic_may: 4.261,
            ic_june: 3.973,
            ic_july: 4.078,
            ic_august: 4.621,
            ic_september: 5.385,
            ic_october: 5.797,
            ic_november: 6.278,
            ic_december: 6.381,
            ic_yearly: 5.353,
          },
          {
            ic_uuid: "d4c2a31a-f67f-4e3f-9c9c-2dc8360da552",
            ic_lat: -272671,
            ic_lon: -504392,
            ic_city: "São Cristovão do Sul",
            ic_states: "SANTA CATARINA",
            ic_january: 5.562,
            ic_february: 5.305,
            ic_march: 4.582,
            ic_april: 3.777,
            ic_may: 2.908,
            ic_june: 2.594,
            ic_july: 2.767,
            ic_august: 3.681,
            ic_september: 3.769,
            ic_october: 4.476,
            ic_november: 5.646,
            ic_december: 5.913,
            ic_yearly: 4.248,
          },
          {
            ic_uuid: "066056d2-b2d6-4dcc-a836-8c68e28b2ea5",
            ic_lat: -123615,
            ic_lon: -449746,
            ic_city: "São Desidério",
            ic_states: "BAHIA",
            ic_january: 6.286,
            ic_february: 6.086,
            ic_march: 5.708,
            ic_april: 5.536,
            ic_may: 5.307,
            ic_june: 5.09,
            ic_july: 5.518,
            ic_august: 6.107,
            ic_september: 6.16,
            ic_october: 6.131,
            ic_november: 5.727,
            ic_december: 6.179,
            ic_yearly: 5.82,
          },
          {
            ic_uuid: "c647d6e3-ef9c-466e-b592-1e9464b1fb98",
            ic_lat: -114654,
            ic_lon: -395271,
            ic_city: "São Domingos",
            ic_states: "BAHIA",
            ic_january: 6.077,
            ic_february: 5.915,
            ic_march: 5.836,
            ic_april: 4.799,
            ic_may: 4.034,
            ic_june: 3.729,
            ic_july: 3.963,
            ic_august: 4.486,
            ic_september: 5.402,
            ic_october: 5.566,
            ic_november: 5.845,
            ic_december: 5.954,
            ic_yearly: 5.134,
          },
          {
            ic_uuid: "8d6056b4-01e3-4d56-ab9b-6440b747666a",
            ic_lat: -134032,
            ic_lon: -463114,
            ic_city: "São Domingos",
            ic_states: "GOIÁS",
            ic_january: 5.919,
            ic_february: 5.763,
            ic_march: 5.328,
            ic_april: 5.287,
            ic_may: 5.15,
            ic_june: 5.079,
            ic_july: 5.398,
            ic_august: 5.999,
            ic_september: 6.022,
            ic_october: 5.753,
            ic_november: 5.184,
            ic_december: 5.716,
            ic_yearly: 5.55,
          },
          {
            ic_uuid: "254a238f-03ca-4ab2-ab65-95279dcfb300",
            ic_lat: -68161,
            ic_lon: -379426,
            ic_city: "São Domingos",
            ic_states: "PARAÍBA",
            ic_january: 6.131,
            ic_february: 6.104,
            ic_march: 6.186,
            ic_april: 5.833,
            ic_may: 5.304,
            ic_june: 4.92,
            ic_july: 5.192,
            ic_august: 5.931,
            ic_september: 6.508,
            ic_october: 6.689,
            ic_november: 6.843,
            ic_december: 6.372,
            ic_yearly: 6.001,
          },
          {
            ic_uuid: "31c4afff-3913-4923-9b08-e81a8c3cff24",
            ic_lat: -265553,
            ic_lon: -525318,
            ic_city: "São Domingos",
            ic_states: "SANTA CATARINA",
            ic_january: 6.115,
            ic_february: 5.637,
            ic_march: 5.1,
            ic_april: 4.12,
            ic_may: 3.195,
            ic_june: 2.734,
            ic_july: 3.018,
            ic_august: 3.93,
            ic_september: 4.146,
            ic_october: 5.119,
            ic_november: 6.095,
            ic_december: 6.338,
            ic_yearly: 4.629,
          },
          {
            ic_uuid: "eb8beba3-6431-45ce-994f-1bb3b1c50378",
            ic_lat: -107921,
            ic_lon: -375688,
            ic_city: "São Domingos",
            ic_states: "SERGIPE",
            ic_january: 6.117,
            ic_february: 5.931,
            ic_march: 5.777,
            ic_april: 4.958,
            ic_may: 4.194,
            ic_june: 3.917,
            ic_july: 4.038,
            ic_august: 4.534,
            ic_september: 5.379,
            ic_october: 5.672,
            ic_november: 6.151,
            ic_december: 6.231,
            ic_yearly: 5.242,
          },
          {
            ic_uuid: "07d5df04-0704-4dd4-8150-5ba8a0274bf9",
            ic_lat: -195267,
            ic_lon: -420121,
            ic_city: "São Domingos das Dores",
            ic_states: "MINAS GERAIS",
            ic_january: 5.747,
            ic_february: 5.99,
            ic_march: 4.98,
            ic_april: 4.379,
            ic_may: 3.741,
            ic_june: 3.535,
            ic_july: 3.695,
            ic_august: 4.357,
            ic_september: 4.786,
            ic_october: 4.941,
            ic_november: 4.784,
            ic_december: 5.637,
            ic_yearly: 4.714,
          },
          {
            ic_uuid: "05a23a7f-b138-4c65-8357-6f75e5a7642f",
            ic_lat: -5549,
            ic_lon: -487301,
            ic_city: "São Domingos do Araguaia",
            ic_states: "PARÁ",
            ic_january: 4.594,
            ic_february: 4.751,
            ic_march: 4.748,
            ic_april: 4.787,
            ic_may: 4.726,
            ic_june: 5.034,
            ic_july: 5.127,
            ic_august: 5.605,
            ic_september: 5.374,
            ic_october: 4.852,
            ic_november: 4.761,
            ic_december: 4.547,
            ic_yearly: 4.909,
          },
          {
            ic_uuid: "a5c3246c-127e-4f23-82fc-82007bde998d",
            ic_lat: -68124,
            ic_lon: -446445,
            ic_city: "São Domingos do Azeitão",
            ic_states: "MARANHÃO",
            ic_january: 4.759,
            ic_february: 4.96,
            ic_march: 4.94,
            ic_april: 4.953,
            ic_may: 5.103,
            ic_june: 5.271,
            ic_july: 5.566,
            ic_august: 6.035,
            ic_september: 6.511,
            ic_october: 5.892,
            ic_november: 5.337,
            ic_december: 5.114,
            ic_yearly: 5.37,
          },
          {
            ic_uuid: "7b4e4a5d-f88d-47a6-8bd5-31094643f44a",
            ic_lat: -16881,
            ic_lon: -477668,
            ic_city: "São Domingos do Capim",
            ic_states: "PARÁ",
            ic_january: 4.585,
            ic_february: 4.519,
            ic_march: 4.472,
            ic_april: 4.547,
            ic_may: 4.727,
            ic_june: 4.988,
            ic_july: 5.087,
            ic_august: 5.348,
            ic_september: 5.434,
            ic_october: 5.319,
            ic_november: 5.081,
            ic_december: 4.677,
            ic_yearly: 4.899,
          },
          {
            ic_uuid: "8782f5f7-0720-4943-b91b-d41191f8c4d5",
            ic_lat: -76332,
            ic_lon: -364377,
            ic_city: "São Domingos do Cariri",
            ic_states: "PARAÍBA",
            ic_january: 5.884,
            ic_february: 5.777,
            ic_march: 5.916,
            ic_april: 5.394,
            ic_may: 4.773,
            ic_june: 4.207,
            ic_july: 4.437,
            ic_august: 5.09,
            ic_september: 5.861,
            ic_october: 6.155,
            ic_november: 6.338,
            ic_december: 6.127,
            ic_yearly: 5.497,
          },
          {
            ic_uuid: "531b04fa-e2ba-4ac8-af00-51cf41d8812c",
            ic_lat: -55814,
            ic_lon: -443825,
            ic_city: "São Domingos do Maranhão",
            ic_states: "MARANHÃO",
            ic_january: 4.73,
            ic_february: 4.78,
            ic_march: 4.946,
            ic_april: 4.953,
            ic_may: 4.955,
            ic_june: 5.217,
            ic_july: 5.49,
            ic_august: 6.104,
            ic_september: 6.337,
            ic_october: 5.763,
            ic_november: 5.362,
            ic_december: 5.072,
            ic_yearly: 5.309,
          },
          {
            ic_uuid: "758c2227-5fc3-4a3c-a8ce-9ccee79b4497",
            ic_lat: -191442,
            ic_lon: -406238,
            ic_city: "São Domingos do Norte",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.04,
            ic_february: 6.144,
            ic_march: 5.315,
            ic_april: 4.548,
            ic_may: 3.874,
            ic_june: 3.594,
            ic_july: 3.633,
            ic_august: 4.245,
            ic_september: 4.721,
            ic_october: 4.896,
            ic_november: 4.919,
            ic_december: 5.745,
            ic_yearly: 4.806,
          },
          {
            ic_uuid: "a1ea0f65-5bd0-4f5b-a5be-ff723a539b62",
            ic_lat: -198683,
            ic_lon: -429714,
            ic_city: "São Domingos do Prata",
            ic_states: "MINAS GERAIS",
            ic_january: 5.559,
            ic_february: 5.915,
            ic_march: 4.906,
            ic_april: 4.44,
            ic_may: 3.808,
            ic_june: 3.591,
            ic_july: 3.847,
            ic_august: 4.606,
            ic_september: 4.92,
            ic_october: 5.016,
            ic_november: 4.71,
            ic_december: 5.462,
            ic_yearly: 4.732,
          },
          {
            ic_uuid: "4bf66e9e-626a-4413-b843-1fed600b8abe",
            ic_lat: -285316,
            ic_lon: -518864,
            ic_city: "São Domingos do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.128,
            ic_february: 5.699,
            ic_march: 4.891,
            ic_april: 3.955,
            ic_may: 2.928,
            ic_june: 2.48,
            ic_july: 2.796,
            ic_august: 3.51,
            ic_september: 3.773,
            ic_october: 4.881,
            ic_november: 6.098,
            ic_december: 6.465,
            ic_yearly: 4.467,
          },
          {
            ic_uuid: "83859301-a4ee-4adf-846b-f6c958283ffd",
            ic_lat: -128455,
            ic_lon: -390871,
            ic_city: "São Felipe",
            ic_states: "BAHIA",
            ic_january: 6.068,
            ic_february: 5.912,
            ic_march: 5.698,
            ic_april: 4.597,
            ic_may: 3.968,
            ic_june: 3.603,
            ic_july: 3.827,
            ic_august: 4.371,
            ic_september: 5.192,
            ic_october: 5.466,
            ic_november: 5.757,
            ic_december: 6.091,
            ic_yearly: 5.046,
          },
          {
            ic_uuid: "8d2b060a-2550-4e79-8007-17b31fdc9272",
            ic_lat: -119027,
            ic_lon: -615031,
            ic_city: "São Felipe D'Oeste",
            ic_states: "RONDÔNIA",
            ic_january: 4.522,
            ic_february: 4.453,
            ic_march: 4.58,
            ic_april: 4.596,
            ic_may: 4.179,
            ic_june: 4.371,
            ic_july: 4.496,
            ic_august: 4.948,
            ic_september: 4.969,
            ic_october: 5.18,
            ic_november: 5.007,
            ic_december: 4.836,
            ic_yearly: 4.678,
          },
          {
            ic_uuid: "2843e68d-59b4-4ebc-994b-41bf24196261",
            ic_lat: -126129,
            ic_lon: -389742,
            ic_city: "São Félix",
            ic_states: "BAHIA",
            ic_january: 6.102,
            ic_february: 5.979,
            ic_march: 5.75,
            ic_april: 4.672,
            ic_may: 4.007,
            ic_june: 3.635,
            ic_july: 3.937,
            ic_august: 4.446,
            ic_september: 5.297,
            ic_october: 5.553,
            ic_november: 5.796,
            ic_december: 6.091,
            ic_yearly: 5.106,
          },
          {
            ic_uuid: "edaff47c-d95e-4c7b-adf4-c36af1e265fa",
            ic_lat: -70758,
            ic_lon: -448096,
            ic_city: "São Félix de Balsas",
            ic_states: "MARANHÃO",
            ic_january: 4.979,
            ic_february: 5.122,
            ic_march: 5.042,
            ic_april: 5.116,
            ic_may: 5.26,
            ic_june: 5.396,
            ic_july: 5.514,
            ic_august: 6.047,
            ic_september: 6.565,
            ic_october: 5.997,
            ic_november: 5.48,
            ic_december: 5.267,
            ic_yearly: 5.482,
          },
          {
            ic_uuid: "a7e9586f-90c0-4002-83b9-44893e63b6e8",
            ic_lat: -185964,
            ic_lon: -414892,
            ic_city: "São Félix de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.895,
            ic_february: 6.005,
            ic_march: 5.105,
            ic_april: 4.501,
            ic_may: 3.803,
            ic_june: 3.591,
            ic_july: 3.706,
            ic_august: 4.332,
            ic_september: 4.888,
            ic_october: 5.164,
            ic_november: 4.919,
            ic_december: 5.693,
            ic_yearly: 4.8,
          },
          {
            ic_uuid: "dc35f4c7-5dc1-414e-91a6-4051d05ec93f",
            ic_lat: -116209,
            ic_lon: -506782,
            ic_city: "São Félix do Araguaia",
            ic_states: "MATO GROSSO",
            ic_january: 5.143,
            ic_february: 5.253,
            ic_march: 5.068,
            ic_april: 4.91,
            ic_may: 4.958,
            ic_june: 4.899,
            ic_july: 5.279,
            ic_august: 5.76,
            ic_september: 5.481,
            ic_october: 5.296,
            ic_november: 5.188,
            ic_december: 5.288,
            ic_yearly: 5.21,
          },
          {
            ic_uuid: "6c1a238c-30aa-43d1-86ed-3f74381dd599",
            ic_lat: -134055,
            ic_lon: -441942,
            ic_city: "São Félix do Coribe",
            ic_states: "BAHIA",
            ic_january: 6.581,
            ic_february: 6.368,
            ic_march: 5.836,
            ic_april: 5.591,
            ic_may: 5.162,
            ic_june: 4.982,
            ic_july: 5.287,
            ic_august: 5.788,
            ic_september: 6.217,
            ic_october: 6.338,
            ic_november: 5.828,
            ic_december: 6.353,
            ic_yearly: 5.861,
          },
          {
            ic_uuid: "19f88b7b-287f-4703-aa22-7ec208d8ae13",
            ic_lat: -59359,
            ic_lon: -421131,
            ic_city: "São Félix do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.241,
            ic_february: 5.339,
            ic_march: 5.36,
            ic_april: 5.253,
            ic_may: 5.269,
            ic_june: 5.284,
            ic_july: 5.562,
            ic_august: 6.177,
            ic_september: 6.549,
            ic_october: 6.377,
            ic_november: 6.221,
            ic_december: 5.736,
            ic_yearly: 5.697,
          },
          {
            ic_uuid: "4ffced91-29db-4248-b5ef-b5fe4e5b2a62",
            ic_lat: -101619,
            ic_lon: -466622,
            ic_city: "São Félix do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 5.453,
            ic_february: 5.292,
            ic_march: 5.006,
            ic_april: 5.118,
            ic_may: 5.24,
            ic_june: 5.2,
            ic_july: 5.472,
            ic_august: 6.095,
            ic_september: 6.214,
            ic_october: 5.744,
            ic_november: 5.375,
            ic_december: 5.412,
            ic_yearly: 5.469,
          },
          {
            ic_uuid: "01c43af6-1ea9-4b36-8fd6-c65ad7d5dd7d",
            ic_lat: -66429,
            ic_lon: -519908,
            ic_city: "São Félix do Xingu",
            ic_states: "PARÁ",
            ic_january: 4.342,
            ic_february: 4.583,
            ic_march: 4.523,
            ic_april: 4.436,
            ic_may: 4.619,
            ic_june: 4.903,
            ic_july: 4.836,
            ic_august: 5.285,
            ic_september: 5.094,
            ic_october: 4.803,
            ic_november: 4.635,
            ic_december: 4.39,
            ic_yearly: 4.704,
          },
          {
            ic_uuid: "f4c8dcc5-4d9e-4acf-bc3f-2512bc1c6fce",
            ic_lat: -63802,
            ic_lon: -371867,
            ic_city: "São Fernando",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.141,
            ic_february: 6.132,
            ic_march: 6.108,
            ic_april: 5.832,
            ic_may: 5.24,
            ic_june: 4.824,
            ic_july: 5.132,
            ic_august: 5.88,
            ic_september: 6.431,
            ic_october: 6.59,
            ic_november: 6.596,
            ic_december: 6.217,
            ic_yearly: 5.927,
          },
          {
            ic_uuid: "321eeefb-0881-4231-a5a6-8154bb822d4a",
            ic_lat: -216485,
            ic_lon: -417485,
            ic_city: "São Fidélis",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.254,
            ic_february: 6.376,
            ic_march: 5.209,
            ic_april: 4.49,
            ic_may: 3.859,
            ic_june: 3.58,
            ic_july: 3.644,
            ic_august: 4.38,
            ic_september: 4.774,
            ic_october: 5.101,
            ic_november: 5.081,
            ic_december: 5.968,
            ic_yearly: 4.893,
          },
          {
            ic_uuid: "d7fe3283-bedd-4ea7-94c6-66d3a607b55d",
            ic_lat: -159519,
            ic_lon: -448597,
            ic_city: "São Francisco",
            ic_states: "MINAS GERAIS",
            ic_january: 6.372,
            ic_february: 6.554,
            ic_march: 5.76,
            ic_april: 5.568,
            ic_may: 4.99,
            ic_june: 4.7,
            ic_july: 5.044,
            ic_august: 5.834,
            ic_september: 5.964,
            ic_october: 6.109,
            ic_november: 5.684,
            ic_december: 6.186,
            ic_yearly: 5.731,
          },
          {
            ic_uuid: "391638fb-dce6-4371-a133-cc67ab3afa1c",
            ic_lat: -66188,
            ic_lon: -380958,
            ic_city: "São Francisco",
            ic_states: "PARAÍBA",
            ic_january: 6.027,
            ic_february: 6.003,
            ic_march: 6.106,
            ic_april: 5.792,
            ic_may: 5.289,
            ic_june: 4.981,
            ic_july: 5.251,
            ic_august: 5.997,
            ic_september: 6.553,
            ic_october: 6.65,
            ic_november: 6.791,
            ic_december: 6.267,
            ic_yearly: 5.976,
          },
          {
            ic_uuid: "b746c630-d12f-4ba6-952b-4f0efd81178e",
            ic_lat: -203542,
            ic_lon: -507011,
            ic_city: "São Francisco",
            ic_states: "SÃO PAULO",
            ic_january: 5.85,
            ic_february: 5.971,
            ic_march: 5.355,
            ic_april: 4.847,
            ic_may: 4.193,
            ic_june: 3.917,
            ic_july: 4.128,
            ic_august: 5.07,
            ic_september: 5.099,
            ic_october: 5.701,
            ic_november: 5.993,
            ic_december: 6.327,
            ic_yearly: 5.204,
          },
          {
            ic_uuid: "6d91cac4-a733-484f-bbb8-23bbc32e15a2",
            ic_lat: -103453,
            ic_lon: -368837,
            ic_city: "São Francisco",
            ic_states: "SERGIPE",
            ic_january: 5.989,
            ic_february: 5.788,
            ic_march: 5.745,
            ic_april: 4.938,
            ic_may: 4.218,
            ic_june: 4.003,
            ic_july: 4.051,
            ic_august: 4.602,
            ic_september: 5.421,
            ic_october: 5.808,
            ic_november: 6.243,
            ic_december: 6.268,
            ic_yearly: 5.256,
          },
          {
            ic_uuid: "c3fc7d53-bef3-43aa-8d06-ec504bde886c",
            ic_lat: -295552,
            ic_lon: -551258,
            ic_city: "São Francisco de Assis",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.687,
            ic_february: 6.085,
            ic_march: 5.174,
            ic_april: 3.999,
            ic_may: 2.948,
            ic_june: 2.458,
            ic_july: 2.712,
            ic_august: 3.479,
            ic_september: 4.006,
            ic_october: 5.195,
            ic_november: 6.372,
            ic_december: 6.987,
            ic_yearly: 4.675,
          },
          {
            ic_uuid: "853b0507-d543-4b0c-8b44-524fdb7eec9d",
            ic_lat: -82376,
            ic_lon: -416871,
            ic_city: "São Francisco de Assis do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.766,
            ic_february: 5.679,
            ic_march: 5.665,
            ic_april: 5.362,
            ic_may: 5.232,
            ic_june: 5.22,
            ic_july: 5.564,
            ic_august: 6.329,
            ic_september: 6.666,
            ic_october: 6.653,
            ic_november: 6.39,
            ic_december: 6.063,
            ic_yearly: 5.883,
          },
          {
            ic_uuid: "2d255b60-2693-4896-9e4a-d6211d9d1819",
            ic_lat: -15932,
            ic_lon: -492603,
            ic_city: "São Francisco de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.542,
            ic_february: 5.663,
            ic_march: 5.235,
            ic_april: 5.17,
            ic_may: 4.928,
            ic_june: 4.648,
            ic_july: 4.849,
            ic_august: 5.717,
            ic_september: 5.53,
            ic_october: 5.614,
            ic_november: 5.35,
            ic_december: 5.443,
            ic_yearly: 5.308,
          },
          {
            ic_uuid: "a7286c11-8b81-40f9-bfce-378e171802c9",
            ic_lat: -214752,
            ic_lon: -411076,
            ic_city: "São Francisco de Itabapoana",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.48,
            ic_february: 6.676,
            ic_march: 5.301,
            ic_april: 4.517,
            ic_may: 3.836,
            ic_june: 3.563,
            ic_july: 3.635,
            ic_august: 4.5,
            ic_september: 4.831,
            ic_october: 5.261,
            ic_november: 5.227,
            ic_december: 6.088,
            ic_yearly: 4.993,
          },
          {
            ic_uuid: "ede6e199-3178-47f1-84a6-83a0bc1fc76f",
            ic_lat: -207041,
            ic_lon: -449841,
            ic_city: "São Francisco de Paula",
            ic_states: "MINAS GERAIS",
            ic_january: 5.647,
            ic_february: 5.843,
            ic_march: 4.884,
            ic_april: 4.727,
            ic_may: 4.101,
            ic_june: 3.925,
            ic_july: 4.206,
            ic_august: 5.035,
            ic_september: 5.267,
            ic_october: 5.434,
            ic_november: 5.148,
            ic_december: 5.57,
            ic_yearly: 4.982,
          },
          {
            ic_uuid: "b734983a-af38-4b64-91ea-9d8aa3f66b64",
            ic_lat: -294479,
            ic_lon: -505839,
            ic_city: "São Francisco de Paula",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.05,
            ic_february: 5.544,
            ic_march: 4.594,
            ic_april: 3.82,
            ic_may: 2.813,
            ic_june: 2.424,
            ic_july: 2.665,
            ic_august: 3.357,
            ic_september: 3.594,
            ic_october: 4.701,
            ic_november: 6.014,
            ic_december: 6.35,
            ic_yearly: 4.327,
          },
          {
            ic_uuid: "6edad617-62b7-4d9c-9ab1-b52b1b9d8b24",
            ic_lat: -198616,
            ic_lon: -497731,
            ic_city: "São Francisco de Sales",
            ic_states: "MINAS GERAIS",
            ic_january: 5.75,
            ic_february: 5.862,
            ic_march: 5.234,
            ic_april: 4.985,
            ic_may: 4.255,
            ic_june: 3.991,
            ic_july: 4.256,
            ic_august: 5.124,
            ic_september: 5.098,
            ic_october: 5.59,
            ic_november: 5.842,
            ic_december: 6.104,
            ic_yearly: 5.174,
          },
          {
            ic_uuid: "c8be9bbe-a4fe-4c9b-8855-75ade7754ccb",
            ic_lat: -51262,
            ic_lon: -473894,
            ic_city: "São Francisco do Brejão",
            ic_states: "MARANHÃO",
            ic_january: 4.491,
            ic_february: 4.688,
            ic_march: 4.667,
            ic_april: 4.715,
            ic_may: 4.738,
            ic_june: 5.002,
            ic_july: 5.165,
            ic_august: 5.566,
            ic_september: 5.434,
            ic_october: 4.992,
            ic_november: 4.737,
            ic_december: 4.652,
            ic_yearly: 4.904,
          },
          {
            ic_uuid: "a0003549-310d-4448-a039-269a26f85252",
            ic_lat: -126221,
            ic_lon: -386762,
            ic_city: "São Francisco do Conde",
            ic_states: "BAHIA",
            ic_january: 6.075,
            ic_february: 5.808,
            ic_march: 5.729,
            ic_april: 4.635,
            ic_may: 3.968,
            ic_june: 3.643,
            ic_july: 3.884,
            ic_august: 4.337,
            ic_september: 5.14,
            ic_october: 5.472,
            ic_november: 5.755,
            ic_december: 6.152,
            ic_yearly: 5.05,
          },
          {
            ic_uuid: "2b81fe32-c641-49cd-8848-51aa44b47d0d",
            ic_lat: -207928,
            ic_lon: -422676,
            ic_city: "São Francisco do Glória",
            ic_states: "MINAS GERAIS",
            ic_january: 5.818,
            ic_february: 6.038,
            ic_march: 4.953,
            ic_april: 4.378,
            ic_may: 3.809,
            ic_june: 3.687,
            ic_july: 3.809,
            ic_august: 4.619,
            ic_september: 4.968,
            ic_october: 5.056,
            ic_november: 4.773,
            ic_december: 5.561,
            ic_yearly: 4.789,
          },
          {
            ic_uuid: "6854aa70-9ed9-4d19-b231-359ec914cca5",
            ic_lat: -120524,
            ic_lon: -635684,
            ic_city: "São Francisco do Guaporé",
            ic_states: "RONDÔNIA",
            ic_january: 4.54,
            ic_february: 4.414,
            ic_march: 4.406,
            ic_april: 4.471,
            ic_may: 3.892,
            ic_june: 4.187,
            ic_july: 4.398,
            ic_august: 4.854,
            ic_september: 4.908,
            ic_october: 5.129,
            ic_november: 4.98,
            ic_december: 4.805,
            ic_yearly: 4.582,
          },
          {
            ic_uuid: "f1b6722a-71c7-4059-b271-4ad5db46a616",
            ic_lat: -62477,
            ic_lon: -428579,
            ic_city: "São Francisco do Maranhão",
            ic_states: "MARANHÃO",
            ic_january: 5.062,
            ic_february: 5.214,
            ic_march: 5.204,
            ic_april: 5.137,
            ic_may: 5.263,
            ic_june: 5.406,
            ic_july: 5.66,
            ic_august: 6.155,
            ic_september: 6.56,
            ic_october: 6.272,
            ic_november: 6.001,
            ic_december: 5.51,
            ic_yearly: 5.62,
          },
          {
            ic_uuid: "2d047d5f-752b-43f1-b143-48b989082189",
            ic_lat: -59751,
            ic_lon: -381523,
            ic_city: "São Francisco do Oeste",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.845,
            ic_february: 5.957,
            ic_march: 5.996,
            ic_april: 5.639,
            ic_may: 5.296,
            ic_june: 5.055,
            ic_july: 5.393,
            ic_august: 6.087,
            ic_september: 6.445,
            ic_october: 6.596,
            ic_november: 6.612,
            ic_december: 6.088,
            ic_yearly: 5.917,
          },
          {
            ic_uuid: "1a87803a-424b-4601-a8d9-e9aba1226ef1",
            ic_lat: -11702,
            ic_lon: -477923,
            ic_city: "São Francisco do Pará",
            ic_states: "PARÁ",
            ic_january: 4.341,
            ic_february: 4.218,
            ic_march: 4.206,
            ic_april: 4.282,
            ic_may: 4.574,
            ic_june: 4.813,
            ic_july: 5.003,
            ic_august: 5.222,
            ic_september: 5.368,
            ic_october: 5.264,
            ic_november: 5.035,
            ic_december: 4.571,
            ic_yearly: 4.741,
          },
          {
            ic_uuid: "c0ff9b40-a1f1-4b0e-8c8b-4f74934935da",
            ic_lat: -72467,
            ic_lon: -425414,
            ic_city: "São Francisco do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.391,
            ic_february: 5.339,
            ic_march: 5.322,
            ic_april: 5.244,
            ic_may: 5.278,
            ic_june: 5.236,
            ic_july: 5.576,
            ic_august: 6.181,
            ic_september: 6.666,
            ic_october: 6.4,
            ic_november: 6.091,
            ic_december: 5.759,
            ic_yearly: 5.707,
          },
          {
            ic_uuid: "81a91e01-eacb-4050-90ce-bee3ce7f88b3",
            ic_lat: -262584,
            ic_lon: -486348,
            ic_city: "São Francisco do Sul",
            ic_states: "SANTA CATARINA",
            ic_january: 5.281,
            ic_february: 5.143,
            ic_march: 4.433,
            ic_april: 3.644,
            ic_may: 2.99,
            ic_june: 2.454,
            ic_july: 2.507,
            ic_august: 3.16,
            ic_september: 3.268,
            ic_october: 3.903,
            ic_november: 4.9,
            ic_december: 5.354,
            ic_yearly: 3.92,
          },
          {
            ic_uuid: "7788d792-7c84-4365-8b5c-1f1ed49a9838",
            ic_lat: -112274,
            ic_lon: -418797,
            ic_city: "São Gabriel",
            ic_states: "BAHIA",
            ic_january: 6.367,
            ic_february: 6.193,
            ic_march: 5.925,
            ic_april: 5.256,
            ic_may: 4.825,
            ic_june: 4.672,
            ic_july: 4.926,
            ic_august: 5.66,
            ic_september: 6.366,
            ic_october: 6.44,
            ic_november: 6.24,
            ic_december: 6.419,
            ic_yearly: 5.774,
          },
          {
            ic_uuid: "23a6a103-1b95-40c4-92d6-8c01cebbe630",
            ic_lat: -30336,
            ic_lon: -543199,
            ic_city: "São Gabriel",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.62,
            ic_february: 6.085,
            ic_march: 5.17,
            ic_april: 3.965,
            ic_may: 2.864,
            ic_june: 2.371,
            ic_july: 2.602,
            ic_august: 3.34,
            ic_september: 3.903,
            ic_october: 5.162,
            ic_november: 6.384,
            ic_december: 6.998,
            ic_yearly: 4.622,
          },
          {
            ic_uuid: "05701fa4-9d54-48d9-ac4a-934e4a248961",
            ic_lat: -1195,
            ic_lon: -670845,
            ic_city: "São Gabriel da Cachoeira",
            ic_states: "AMAZONAS",
            ic_january: 4.677,
            ic_february: 4.866,
            ic_march: 4.923,
            ic_april: 4.545,
            ic_may: 4.209,
            ic_june: 4.318,
            ic_july: 4.293,
            ic_august: 4.738,
            ic_september: 5.024,
            ic_october: 5.015,
            ic_november: 4.787,
            ic_december: 4.581,
            ic_yearly: 4.664,
          },
          {
            ic_uuid: "3c217bf2-3b56-47a3-a177-587f710e90d4",
            ic_lat: -190187,
            ic_lon: -405368,
            ic_city: "São Gabriel da Palha",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.082,
            ic_february: 6.189,
            ic_march: 5.283,
            ic_april: 4.572,
            ic_may: 3.834,
            ic_june: 3.577,
            ic_july: 3.634,
            ic_august: 4.176,
            ic_september: 4.699,
            ic_october: 4.897,
            ic_november: 4.925,
            ic_december: 5.77,
            ic_yearly: 4.803,
          },
          {
            ic_uuid: "f1265e43-870d-45ba-8bf3-cc82b8c68ffe",
            ic_lat: -193921,
            ic_lon: -545782,
            ic_city: "São Gabriel do Oeste",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.586,
            ic_february: 5.529,
            ic_march: 5.159,
            ic_april: 4.789,
            ic_may: 4.137,
            ic_june: 3.992,
            ic_july: 4.113,
            ic_august: 4.884,
            ic_september: 5.025,
            ic_october: 5.347,
            ic_november: 5.659,
            ic_december: 5.944,
            ic_yearly: 5.014,
          },
          {
            ic_uuid: "5b278c3d-f379-4b89-898e-b85b7f1032ab",
            ic_lat: -209257,
            ic_lon: -428367,
            ic_city: "São Geraldo",
            ic_states: "MINAS GERAIS",
            ic_january: 5.698,
            ic_february: 5.947,
            ic_march: 4.982,
            ic_april: 4.379,
            ic_may: 3.742,
            ic_june: 3.585,
            ic_july: 3.773,
            ic_august: 4.566,
            ic_september: 4.856,
            ic_october: 5.071,
            ic_november: 4.889,
            ic_december: 5.572,
            ic_yearly: 4.755,
          },
          {
            ic_uuid: "e00f1fc8-166a-400c-9af1-62298c4a6a04",
            ic_lat: -188443,
            ic_lon: -422886,
            ic_city: "São Geraldo da Piedade",
            ic_states: "MINAS GERAIS",
            ic_january: 5.775,
            ic_february: 5.978,
            ic_march: 5.049,
            ic_april: 4.498,
            ic_may: 3.777,
            ic_june: 3.606,
            ic_july: 3.715,
            ic_august: 4.389,
            ic_september: 4.87,
            ic_october: 5.032,
            ic_november: 4.711,
            ic_december: 5.535,
            ic_yearly: 4.745,
          },
          {
            ic_uuid: "533aa0e7-c511-40d2-89ec-1e33b37af1d4",
            ic_lat: -63951,
            ic_lon: -485595,
            ic_city: "São Geraldo do Araguaia",
            ic_states: "PARÁ",
            ic_january: 4.671,
            ic_february: 4.841,
            ic_march: 4.72,
            ic_april: 4.779,
            ic_may: 4.882,
            ic_june: 5.16,
            ic_july: 5.244,
            ic_august: 5.737,
            ic_september: 5.619,
            ic_october: 5.057,
            ic_november: 4.722,
            ic_december: 4.587,
            ic_yearly: 5.002,
          },
          {
            ic_uuid: "77b87e39-bfeb-4d4b-96cc-e7a4ed060055",
            ic_lat: -189101,
            ic_lon: -413633,
            ic_city: "São Geraldo do Baixio",
            ic_states: "MINAS GERAIS",
            ic_january: 5.978,
            ic_february: 6.111,
            ic_march: 5.219,
            ic_april: 4.568,
            ic_may: 3.889,
            ic_june: 3.589,
            ic_july: 3.686,
            ic_august: 4.329,
            ic_september: 4.852,
            ic_october: 5.104,
            ic_november: 4.954,
            ic_december: 5.767,
            ic_yearly: 4.837,
          },
          {
            ic_uuid: "1a608dbc-4d58-46c6-b741-aa2e737b0364",
            ic_lat: -228273,
            ic_lon: -430637,
            ic_city: "São Gonçalo",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.119,
            ic_february: 6.32,
            ic_march: 5.106,
            ic_april: 4.448,
            ic_may: 3.601,
            ic_june: 3.37,
            ic_july: 3.381,
            ic_august: 4.216,
            ic_september: 4.397,
            ic_october: 5.035,
            ic_november: 5.092,
            ic_december: 5.916,
            ic_yearly: 4.75,
          },
          {
            ic_uuid: "66132ad7-7b33-4c19-b6d7-464fac43c445",
            ic_lat: -183403,
            ic_lon: -458335,
            ic_city: "São Gonçalo do Abaeté",
            ic_states: "MINAS GERAIS",
            ic_january: 5.953,
            ic_february: 6.165,
            ic_march: 5.171,
            ic_april: 5.076,
            ic_may: 4.701,
            ic_june: 4.429,
            ic_july: 4.834,
            ic_august: 5.672,
            ic_september: 5.854,
            ic_october: 5.687,
            ic_november: 5.306,
            ic_december: 5.704,
            ic_yearly: 5.379,
          },
          {
            ic_uuid: "3cac60a7-8dbe-4d4b-ad0d-675107712a88",
            ic_lat: -36055,
            ic_lon: -38973,
            ic_city: "São Gonçalo do Amarante",
            ic_states: "CEARÁ",
            ic_january: 5.737,
            ic_february: 5.7,
            ic_march: 5.477,
            ic_april: 4.743,
            ic_may: 5.227,
            ic_june: 5.254,
            ic_july: 5.559,
            ic_august: 6.176,
            ic_september: 6.354,
            ic_october: 6.401,
            ic_november: 6.513,
            ic_december: 6.054,
            ic_yearly: 5.766,
          },
          {
            ic_uuid: "c0f16448-eba2-4375-b7a4-e63cbca87548",
            ic_lat: -57852,
            ic_lon: -353305,
            ic_city: "São Gonçalo do Amarante",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.985,
            ic_february: 6.056,
            ic_march: 6.105,
            ic_april: 5.557,
            ic_may: 5.091,
            ic_june: 4.613,
            ic_july: 4.721,
            ic_august: 5.491,
            ic_september: 5.939,
            ic_october: 6.137,
            ic_november: 6.287,
            ic_december: 5.964,
            ic_yearly: 5.662,
          },
          {
            ic_uuid: "18710040-ec61-4bdb-b766-57aab0264c59",
            ic_lat: -100307,
            ic_lon: -453022,
            ic_city: "São Gonçalo do Gurguéia",
            ic_states: "PIAUÍ",
            ic_january: 5.843,
            ic_february: 5.682,
            ic_march: 5.422,
            ic_april: 5.299,
            ic_may: 5.432,
            ic_june: 5.323,
            ic_july: 5.491,
            ic_august: 6.113,
            ic_september: 6.424,
            ic_october: 6.153,
            ic_november: 5.725,
            ic_december: 5.845,
            ic_yearly: 5.729,
          },
          {
            ic_uuid: "3906489d-0c4a-41d0-9412-2ff49d54471c",
            ic_lat: -199826,
            ic_lon: -448597,
            ic_city: "São Gonçalo do Pará",
            ic_states: "MINAS GERAIS",
            ic_january: 5.911,
            ic_february: 6.051,
            ic_march: 5.153,
            ic_april: 4.885,
            ic_may: 4.275,
            ic_june: 4.153,
            ic_july: 4.375,
            ic_august: 5.27,
            ic_september: 5.473,
            ic_october: 5.59,
            ic_november: 5.341,
            ic_december: 5.66,
            ic_yearly: 5.178,
          },
          {
            ic_uuid: "85fe0a42-0e63-46ef-a25b-7c356dd72a34",
            ic_lat: -5997,
            ic_lon: -427029,
            ic_city: "São Gonçalo do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.014,
            ic_february: 5.172,
            ic_march: 5.164,
            ic_april: 5.065,
            ic_may: 5.189,
            ic_june: 5.367,
            ic_july: 5.671,
            ic_august: 6.265,
            ic_september: 6.582,
            ic_october: 6.29,
            ic_november: 5.933,
            ic_december: 5.537,
            ic_yearly: 5.604,
          },
          {
            ic_uuid: "a8251003-e7cb-4dc4-a5a2-81145309a1f5",
            ic_lat: -198226,
            ic_lon: -433664,
            ic_city: "São Gonçalo do Rio Abaixo",
            ic_states: "MINAS GERAIS",
            ic_january: 5.534,
            ic_february: 5.81,
            ic_march: 4.892,
            ic_april: 4.478,
            ic_may: 3.858,
            ic_june: 3.711,
            ic_july: 3.949,
            ic_august: 4.733,
            ic_september: 5.009,
            ic_october: 5.112,
            ic_november: 4.815,
            ic_december: 5.417,
            ic_yearly: 4.777,
          },
          {
            ic_uuid: "d3d88f60-f769-47ea-9518-5b943454878b",
            ic_lat: -179993,
            ic_lon: -433817,
            ic_city: "São Gonçalo do Rio Preto",
            ic_states: "MINAS GERAIS",
            ic_january: 5.927,
            ic_february: 6.213,
            ic_march: 5.139,
            ic_april: 4.654,
            ic_may: 4.122,
            ic_june: 4.007,
            ic_july: 4.17,
            ic_august: 5.009,
            ic_september: 5.487,
            ic_october: 5.466,
            ic_november: 4.956,
            ic_december: 5.695,
            ic_yearly: 5.07,
          },
          {
            ic_uuid: "6998193d-39a0-4268-adae-16c707f1dd50",
            ic_lat: -218937,
            ic_lon: -455897,
            ic_city: "São Gonçalo do Sapucaí",
            ic_states: "MINAS GERAIS",
            ic_january: 5.379,
            ic_february: 5.68,
            ic_march: 4.818,
            ic_april: 4.572,
            ic_may: 3.897,
            ic_june: 3.671,
            ic_july: 3.884,
            ic_august: 4.776,
            ic_september: 4.985,
            ic_october: 5.415,
            ic_november: 5.213,
            ic_december: 5.576,
            ic_yearly: 4.822,
          },
          {
            ic_uuid: "50174d21-5160-47df-a438-601d2839a921",
            ic_lat: -124352,
            ic_lon: -389495,
            ic_city: "São Gonçalo dos Campos",
            ic_states: "BAHIA",
            ic_january: 6.03,
            ic_february: 5.853,
            ic_march: 5.671,
            ic_april: 4.641,
            ic_may: 3.958,
            ic_june: 3.584,
            ic_july: 3.84,
            ic_august: 4.36,
            ic_september: 5.254,
            ic_october: 5.518,
            ic_november: 5.733,
            ic_december: 6.042,
            ic_yearly: 5.041,
          },
          {
            ic_uuid: "8cdfbae2-64a8-49a6-8a18-813ccc25ae20",
            ic_lat: -193092,
            ic_lon: -460469,
            ic_city: "São Gotardo",
            ic_states: "MINAS GERAIS",
            ic_january: 5.632,
            ic_february: 5.971,
            ic_march: 4.876,
            ic_april: 4.807,
            ic_may: 4.447,
            ic_june: 4.237,
            ic_july: 4.52,
            ic_august: 5.511,
            ic_september: 5.521,
            ic_october: 5.478,
            ic_november: 5.024,
            ic_december: 5.418,
            ic_yearly: 5.12,
          },
          {
            ic_uuid: "12164826-671a-45c9-b004-00b2a585d004",
            ic_lat: -299646,
            ic_lon: -517211,
            ic_city: "São Jerônimo",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.42,
            ic_february: 5.862,
            ic_march: 4.867,
            ic_april: 3.787,
            ic_may: 2.768,
            ic_june: 2.292,
            ic_july: 2.509,
            ic_august: 3.216,
            ic_september: 3.612,
            ic_october: 4.83,
            ic_november: 6.206,
            ic_december: 6.697,
            ic_yearly: 4.422,
          },
          {
            ic_uuid: "1fdb2806-ff52-4e74-8604-073f41571412",
            ic_lat: -237233,
            ic_lon: -507416,
            ic_city: "São Jerônimo da Serra",
            ic_states: "PARANÁ",
            ic_january: 5.77,
            ic_february: 5.648,
            ic_march: 5.19,
            ic_april: 4.525,
            ic_may: 3.598,
            ic_june: 3.271,
            ic_july: 3.514,
            ic_august: 4.481,
            ic_september: 4.647,
            ic_october: 5.227,
            ic_november: 5.868,
            ic_december: 6.177,
            ic_yearly: 4.826,
          },
          {
            ic_uuid: "82b630e9-c9a5-4dbb-8d77-c58211246540",
            ic_lat: -258218,
            ic_lon: -527257,
            ic_city: "São João",
            ic_states: "PARANÁ",
            ic_january: 6.339,
            ic_february: 5.777,
            ic_march: 5.249,
            ic_april: 4.255,
            ic_may: 3.295,
            ic_june: 2.867,
            ic_july: 3.136,
            ic_august: 4.096,
            ic_september: 4.298,
            ic_october: 5.251,
            ic_november: 6.176,
            ic_december: 6.418,
            ic_yearly: 4.763,
          },
          {
            ic_uuid: "fa55fb11-e59b-4d38-8f88-76edaaa8af7b",
            ic_lat: -88762,
            ic_lon: -363656,
            ic_city: "São João",
            ic_states: "PERNAMBUCO",
            ic_january: 5.941,
            ic_february: 5.679,
            ic_march: 5.788,
            ic_april: 5.109,
            ic_may: 4.281,
            ic_june: 3.809,
            ic_july: 3.922,
            ic_august: 4.555,
            ic_september: 5.4,
            ic_october: 5.704,
            ic_november: 6.262,
            ic_december: 6.177,
            ic_yearly: 5.219,
          },
          {
            ic_uuid: "6742b0de-5433-4e6c-a0be-740c544da0a8",
            ic_lat: -29584,
            ic_lon: -447976,
            ic_city: "São João Batista",
            ic_states: "MARANHÃO",
            ic_january: 4.778,
            ic_february: 4.881,
            ic_march: 4.793,
            ic_april: 4.793,
            ic_may: 4.828,
            ic_june: 5.035,
            ic_july: 5.109,
            ic_august: 5.629,
            ic_september: 5.996,
            ic_october: 5.878,
            ic_november: 5.674,
            ic_december: 5.253,
            ic_yearly: 5.22,
          },
          {
            ic_uuid: "af5dab47-ff89-4696-b95d-8fb9fc5c2fd4",
            ic_lat: -272777,
            ic_lon: -488478,
            ic_city: "São João Batista",
            ic_states: "SANTA CATARINA",
            ic_january: 5.523,
            ic_february: 5.309,
            ic_march: 4.559,
            ic_april: 3.679,
            ic_may: 3.041,
            ic_june: 2.507,
            ic_july: 2.659,
            ic_august: 3.327,
            ic_september: 3.448,
            ic_october: 3.937,
            ic_november: 5.147,
            ic_december: 5.622,
            ic_yearly: 4.063,
          },
          {
            ic_uuid: "7b1103b7-5a75-4f83-bfdc-bd3fc3cf95f0",
            ic_lat: -206355,
            ic_lon: -465084,
            ic_city: "São João Batista do Glória",
            ic_states: "MINAS GERAIS",
            ic_january: 5.661,
            ic_february: 5.894,
            ic_march: 5.025,
            ic_april: 4.837,
            ic_may: 4.24,
            ic_june: 4.0,
            ic_july: 4.234,
            ic_august: 5.076,
            ic_september: 5.195,
            ic_october: 5.572,
            ic_november: 5.456,
            ic_december: 5.705,
            ic_yearly: 5.075,
          },
          {
            ic_uuid: "f2a7642a-eaf1-4b5e-8a21-747b6915cdb3",
            ic_lat: 9513,
            ic_lon: -599137,
            ic_city: "São João da Baliza",
            ic_states: "RORAIMA",
            ic_january: 4.459,
            ic_february: 4.525,
            ic_march: 4.593,
            ic_april: 4.38,
            ic_may: 4.125,
            ic_june: 4.449,
            ic_july: 4.438,
            ic_august: 5.001,
            ic_september: 5.31,
            ic_october: 5.31,
            ic_november: 5.098,
            ic_december: 4.689,
            ic_yearly: 4.698,
          },
          {
            ic_uuid: "30e064bb-a257-4ffa-ab87-35f296cccf52",
            ic_lat: -216385,
            ic_lon: -410449,
            ic_city: "São João da Barra",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.476,
            ic_february: 6.691,
            ic_march: 5.377,
            ic_april: 4.626,
            ic_may: 3.852,
            ic_june: 3.584,
            ic_july: 3.668,
            ic_august: 4.547,
            ic_september: 4.908,
            ic_october: 5.379,
            ic_november: 5.369,
            ic_december: 6.238,
            ic_yearly: 5.06,
          },
          {
            ic_uuid: "8c9ec5c3-e86f-488e-92e2-1da717095194",
            ic_lat: -219712,
            ic_lon: -467948,
            ic_city: "São João da Boa Vista",
            ic_states: "SÃO PAULO",
            ic_january: 5.517,
            ic_february: 5.684,
            ic_march: 4.997,
            ic_april: 4.687,
            ic_may: 3.976,
            ic_june: 3.714,
            ic_july: 3.898,
            ic_august: 4.798,
            ic_september: 4.962,
            ic_october: 5.47,
            ic_november: 5.64,
            ic_december: 5.857,
            ic_yearly: 4.933,
          },
          {
            ic_uuid: "3099787d-a85f-4066-8851-79c83d1a24fa",
            ic_lat: -68173,
            ic_lon: -413446,
            ic_city: "São João da Canabrava",
            ic_states: "PIAUÍ",
            ic_january: 5.425,
            ic_february: 5.323,
            ic_march: 5.376,
            ic_april: 5.25,
            ic_may: 5.18,
            ic_june: 5.158,
            ic_july: 5.56,
            ic_august: 6.291,
            ic_september: 6.735,
            ic_october: 6.472,
            ic_november: 6.228,
            ic_december: 5.788,
            ic_yearly: 5.732,
          },
          {
            ic_uuid: "0af6f405-af9f-492c-aa8d-8d2183fd8a22",
            ic_lat: -39386,
            ic_lon: -412587,
            ic_city: "São João da Fronteira",
            ic_states: "PIAUÍ",
            ic_january: 5.264,
            ic_february: 5.269,
            ic_march: 5.155,
            ic_april: 4.83,
            ic_may: 5.024,
            ic_june: 5.097,
            ic_july: 5.342,
            ic_august: 6.003,
            ic_september: 6.598,
            ic_october: 6.586,
            ic_november: 6.446,
            ic_december: 5.74,
            ic_yearly: 5.613,
          },
          {
            ic_uuid: "9e64c876-a161-48c9-84de-ef2fb9c44d75",
            ic_lat: -168508,
            ic_lon: -443461,
            ic_city: "São João da Lagoa",
            ic_states: "MINAS GERAIS",
            ic_january: 6.133,
            ic_february: 6.419,
            ic_march: 5.478,
            ic_april: 5.354,
            ic_may: 4.867,
            ic_june: 4.57,
            ic_july: 4.919,
            ic_august: 5.679,
            ic_september: 5.99,
            ic_october: 5.932,
            ic_november: 5.401,
            ic_december: 5.974,
            ic_yearly: 5.56,
          },
          {
            ic_uuid: "97bd6449-c235-43f7-9e13-ccfd0b8e6320",
            ic_lat: -219285,
            ic_lon: -459301,
            ic_city: "São João da Mata",
            ic_states: "MINAS GERAIS",
            ic_january: 5.255,
            ic_february: 5.569,
            ic_march: 4.719,
            ic_april: 4.474,
            ic_may: 3.885,
            ic_june: 3.621,
            ic_july: 3.882,
            ic_august: 4.824,
            ic_september: 4.949,
            ic_october: 5.282,
            ic_november: 5.108,
            ic_december: 5.54,
            ic_yearly: 4.759,
          },
          {
            ic_uuid: "84355c7b-0cbc-455f-b0fd-ddc97c38fe37",
            ic_lat: -16816,
            ic_lon: -504074,
            ic_city: "São João da Paraúna",
            ic_states: "GOIÁS",
            ic_january: 5.584,
            ic_february: 5.691,
            ic_march: 5.319,
            ic_april: 5.21,
            ic_may: 4.816,
            ic_june: 4.541,
            ic_july: 4.714,
            ic_august: 5.566,
            ic_september: 5.437,
            ic_october: 5.597,
            ic_november: 5.564,
            ic_december: 5.613,
            ic_yearly: 5.304,
          },
          {
            ic_uuid: "154c654d-49c7-442d-aa9e-2b977222b93e",
            ic_lat: -8513,
            ic_lon: -47921,
            ic_city: "São João da Ponta",
            ic_states: "PARÁ",
            ic_january: 4.315,
            ic_february: 4.051,
            ic_march: 3.988,
            ic_april: 4.13,
            ic_may: 4.411,
            ic_june: 4.754,
            ic_july: 4.858,
            ic_august: 5.174,
            ic_september: 5.421,
            ic_october: 5.298,
            ic_november: 5.08,
            ic_december: 4.66,
            ic_yearly: 4.678,
          },
          {
            ic_uuid: "8706c375-6884-4e4f-a1ab-32950d21b4b6",
            ic_lat: -159287,
            ic_lon: -440058,
            ic_city: "São João da Ponte",
            ic_states: "MINAS GERAIS",
            ic_january: 6.286,
            ic_february: 6.666,
            ic_march: 5.71,
            ic_april: 5.531,
            ic_may: 4.922,
            ic_june: 4.676,
            ic_july: 4.999,
            ic_august: 5.782,
            ic_september: 6.052,
            ic_october: 6.063,
            ic_november: 5.51,
            ic_december: 6.191,
            ic_yearly: 5.699,
          },
          {
            ic_uuid: "39cc2454-e8a6-472b-8287-d2ea3841f65b",
            ic_lat: -55112,
            ic_lon: -418926,
            ic_city: "São João da Serra",
            ic_states: "PIAUÍ",
            ic_january: 5.172,
            ic_february: 5.339,
            ic_march: 5.332,
            ic_april: 5.196,
            ic_may: 5.205,
            ic_june: 5.229,
            ic_july: 5.52,
            ic_august: 6.191,
            ic_september: 6.566,
            ic_october: 6.377,
            ic_november: 6.272,
            ic_december: 5.732,
            ic_yearly: 5.678,
          },
          {
            ic_uuid: "a28b0daa-73a6-4cfc-a967-d6ae43ee146f",
            ic_lat: -2782,
            ic_lon: -518261,
            ic_city: "São João da Urtiga",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.262,
            ic_february: 5.772,
            ic_march: 5.021,
            ic_april: 4.08,
            ic_may: 3.03,
            ic_june: 2.568,
            ic_july: 2.887,
            ic_august: 3.682,
            ic_september: 3.911,
            ic_october: 5.04,
            ic_november: 6.25,
            ic_december: 6.532,
            ic_yearly: 4.586,
          },
          {
            ic_uuid: "39d5c22d-55d8-48b5-9c32-2fa570a98d39",
            ic_lat: -69153,
            ic_lon: -418639,
            ic_city: "São João da Varjota",
            ic_states: "PIAUÍ",
            ic_january: 5.242,
            ic_february: 5.205,
            ic_march: 5.213,
            ic_april: 5.241,
            ic_may: 5.288,
            ic_june: 5.249,
            ic_july: 5.644,
            ic_august: 6.256,
            ic_september: 6.733,
            ic_october: 6.466,
            ic_november: 6.201,
            ic_december: 5.747,
            ic_yearly: 5.707,
          },
          {
            ic_uuid: "fe1d5e8c-82cc-4f77-bcda-6e47a894bc21",
            ic_lat: -147053,
            ic_lon: -475232,
            ic_city: "São João d'Aliança",
            ic_states: "GOIÁS",
            ic_january: 5.598,
            ic_february: 5.796,
            ic_march: 5.271,
            ic_april: 5.152,
            ic_may: 4.993,
            ic_june: 4.926,
            ic_july: 5.161,
            ic_august: 6.047,
            ic_september: 5.93,
            ic_october: 5.643,
            ic_november: 5.006,
            ic_december: 5.502,
            ic_yearly: 5.419,
          },
          {
            ic_uuid: "bc208f97-77aa-4795-906a-a046ead36890",
            ic_lat: -203883,
            ic_lon: -503796,
            ic_city: "São João das Duas Pontes",
            ic_states: "SÃO PAULO",
            ic_january: 5.856,
            ic_february: 5.949,
            ic_march: 5.266,
            ic_april: 4.883,
            ic_may: 4.185,
            ic_june: 3.926,
            ic_july: 4.138,
            ic_august: 5.06,
            ic_september: 5.117,
            ic_october: 5.632,
            ic_november: 5.97,
            ic_december: 6.248,
            ic_yearly: 5.186,
          },
          {
            ic_uuid: "33645d1f-9d2a-4557-9802-09fb5b290eec",
            ic_lat: -148815,
            ic_lon: -440802,
            ic_city: "São João das Missões",
            ic_states: "MINAS GERAIS",
            ic_january: 6.447,
            ic_february: 6.658,
            ic_march: 5.915,
            ic_april: 5.684,
            ic_may: 4.999,
            ic_june: 4.811,
            ic_july: 5.171,
            ic_august: 5.728,
            ic_september: 6.216,
            ic_october: 6.218,
            ic_november: 5.689,
            ic_december: 6.283,
            ic_yearly: 5.818,
          },
          {
            ic_uuid: "e6421699-a680-465a-814b-eb33b542ed1b",
            ic_lat: -205115,
            ic_lon: -503564,
            ic_city: "São João de Iracema",
            ic_states: "SÃO PAULO",
            ic_january: 5.836,
            ic_february: 5.925,
            ic_march: 5.3,
            ic_april: 4.894,
            ic_may: 4.161,
            ic_june: 3.909,
            ic_july: 4.133,
            ic_august: 5.027,
            ic_september: 5.1,
            ic_october: 5.622,
            ic_november: 5.931,
            ic_december: 6.219,
            ic_yearly: 5.171,
          },
          {
            ic_uuid: "2f7599db-ef4a-4619-95da-2b6610936560",
            ic_lat: -228063,
            ic_lon: -433733,
            ic_city: "São João de Meriti",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.954,
            ic_february: 6.136,
            ic_march: 5.017,
            ic_april: 4.363,
            ic_may: 3.618,
            ic_june: 3.384,
            ic_july: 3.372,
            ic_august: 4.226,
            ic_september: 4.431,
            ic_october: 5.02,
            ic_november: 5.057,
            ic_december: 5.743,
            ic_yearly: 4.693,
          },
          {
            ic_uuid: "dd3d0bb3-b8a7-4388-b1f1-4c0bbe031ef6",
            ic_lat: -7806,
            ic_lon: -471814,
            ic_city: "São João de Pirabas",
            ic_states: "PARÁ",
            ic_january: 4.755,
            ic_february: 4.486,
            ic_march: 4.314,
            ic_april: 4.347,
            ic_may: 4.665,
            ic_june: 4.89,
            ic_july: 4.999,
            ic_august: 5.305,
            ic_september: 5.586,
            ic_october: 5.496,
            ic_november: 5.391,
            ic_december: 5.115,
            ic_yearly: 4.946,
          },
          {
            ic_uuid: "1e213a32-8101-4373-8201-5b90d5aff688",
            ic_lat: -211315,
            ic_lon: -44253,
            ic_city: "São João del Rei",
            ic_states: "MINAS GERAIS",
            ic_january: 5.55,
            ic_february: 5.768,
            ic_march: 4.899,
            ic_april: 4.444,
            ic_may: 3.833,
            ic_june: 3.65,
            ic_july: 3.894,
            ic_august: 4.824,
            ic_september: 5.037,
            ic_october: 5.24,
            ic_november: 4.993,
            ic_december: 5.547,
            ic_yearly: 4.806,
          },
          {
            ic_uuid: "b063108f-674c-4c73-bf48-98c35824649a",
            ic_lat: -53606,
            ic_lon: -487925,
            ic_city: "São João do Araguaia",
            ic_states: "PARÁ",
            ic_january: 4.743,
            ic_february: 4.808,
            ic_march: 4.857,
            ic_april: 4.976,
            ic_may: 4.842,
            ic_june: 5.107,
            ic_july: 5.147,
            ic_august: 5.649,
            ic_september: 5.482,
            ic_october: 4.983,
            ic_november: 4.875,
            ic_december: 4.633,
            ic_yearly: 5.008,
          },
          {
            ic_uuid: "b629d3d7-fa7e-46ba-a935-45e544adf501",
            ic_lat: -38157,
            ic_lon: -424519,
            ic_city: "São João do Arraial",
            ic_states: "PIAUÍ",
            ic_january: 5.298,
            ic_february: 5.466,
            ic_march: 5.362,
            ic_april: 5.118,
            ic_may: 5.172,
            ic_june: 5.351,
            ic_july: 5.568,
            ic_august: 6.17,
            ic_september: 6.527,
            ic_october: 6.469,
            ic_november: 6.219,
            ic_december: 5.641,
            ic_yearly: 5.697,
          },
          {
            ic_uuid: "5bd044d8-de30-4ad7-bca2-db6550c4ae30",
            ic_lat: -22852,
            ic_lon: -52337,
            ic_city: "São João do Caiuá",
            ic_states: "PARANÁ",
            ic_january: 5.999,
            ic_february: 5.938,
            ic_march: 5.421,
            ic_april: 4.664,
            ic_may: 3.719,
            ic_june: 3.398,
            ic_july: 3.586,
            ic_august: 4.537,
            ic_september: 4.763,
            ic_october: 5.482,
            ic_november: 6.201,
            ic_december: 6.471,
            ic_yearly: 5.015,
          },
          {
            ic_uuid: "2a7885f4-af15-4f67-a90e-805b45a614ba",
            ic_lat: -73861,
            ic_lon: -365321,
            ic_city: "São João do Cariri",
            ic_states: "PARAÍBA",
            ic_january: 5.973,
            ic_february: 5.926,
            ic_march: 6.017,
            ic_april: 5.489,
            ic_may: 4.868,
            ic_june: 4.325,
            ic_july: 4.575,
            ic_august: 5.25,
            ic_september: 5.985,
            ic_october: 6.233,
            ic_november: 6.424,
            ic_december: 6.183,
            ic_yearly: 5.604,
          },
          {
            ic_uuid: "20c181e6-acc0-4669-ac26-e1f36ec02b88",
            ic_lat: -35507,
            ic_lon: -462511,
            ic_city: "São João do Carú",
            ic_states: "MARANHÃO",
            ic_january: 4.488,
            ic_february: 4.648,
            ic_march: 4.72,
            ic_april: 4.781,
            ic_may: 4.661,
            ic_june: 4.851,
            ic_july: 4.988,
            ic_august: 5.329,
            ic_september: 5.466,
            ic_october: 5.134,
            ic_november: 4.832,
            ic_december: 4.742,
            ic_yearly: 4.886,
          },
          {
            ic_uuid: "75e6049a-fdc3-45d5-adb4-fd8c88f26539",
            ic_lat: -266218,
            ic_lon: -487687,
            ic_city: "São João do Itaperiú",
            ic_states: "SANTA CATARINA",
            ic_january: 5.464,
            ic_february: 5.266,
            ic_march: 4.552,
            ic_april: 3.695,
            ic_may: 2.995,
            ic_june: 2.459,
            ic_july: 2.57,
            ic_august: 3.178,
            ic_september: 3.369,
            ic_october: 3.985,
            ic_november: 5.075,
            ic_december: 5.497,
            ic_yearly: 4.009,
          },
          {
            ic_uuid: "d112e193-b8fd-4e92-bf29-906013da9e3d",
            ic_lat: -239837,
            ic_lon: -51822,
            ic_city: "São João do Ivaí",
            ic_states: "PARANÁ",
            ic_january: 6.173,
            ic_february: 5.804,
            ic_march: 5.334,
            ic_april: 4.605,
            ic_may: 3.563,
            ic_june: 3.205,
            ic_july: 3.429,
            ic_august: 4.396,
            ic_september: 4.597,
            ic_october: 5.3,
            ic_november: 6.118,
            ic_december: 6.449,
            ic_yearly: 4.914,
          },
          {
            ic_uuid: "16a3a109-edfa-4ebc-8cf7-c431ec31bef9",
            ic_lat: -52756,
            ic_lon: -382697,
            ic_city: "São João do Jaguaribe",
            ic_states: "CEARÁ",
            ic_january: 5.68,
            ic_february: 5.805,
            ic_march: 5.927,
            ic_april: 5.484,
            ic_may: 5.271,
            ic_june: 5.013,
            ic_july: 5.374,
            ic_august: 6.035,
            ic_september: 6.393,
            ic_october: 6.583,
            ic_november: 6.442,
            ic_december: 5.9,
            ic_yearly: 5.825,
          },
          {
            ic_uuid: "d1781a51-3263-4619-b719-bbc16830e38b",
            ic_lat: -203938,
            ic_lon: -421536,
            ic_city: "São João do Manhuaçu",
            ic_states: "MINAS GERAIS",
            ic_january: 5.538,
            ic_february: 5.775,
            ic_march: 4.835,
            ic_april: 4.395,
            ic_may: 3.744,
            ic_june: 3.613,
            ic_july: 3.762,
            ic_august: 4.498,
            ic_september: 4.84,
            ic_october: 4.911,
            ic_november: 4.622,
            ic_december: 5.377,
            ic_yearly: 4.659,
          },
          {
            ic_uuid: "0a23e61a-b114-4231-98d6-35fa1fdb51bf",
            ic_lat: -187235,
            ic_lon: -411632,
            ic_city: "São João do Manteninha",
            ic_states: "MINAS GERAIS",
            ic_january: 6.162,
            ic_february: 6.244,
            ic_march: 5.358,
            ic_april: 4.697,
            ic_may: 3.936,
            ic_june: 3.648,
            ic_july: 3.767,
            ic_august: 4.389,
            ic_september: 4.96,
            ic_october: 5.209,
            ic_november: 5.083,
            ic_december: 5.857,
            ic_yearly: 4.942,
          },
          {
            ic_uuid: "999bf755-f4a6-47f9-97c0-22fe8c6b62b8",
            ic_lat: -270988,
            ic_lon: -535981,
            ic_city: "São João do Oeste",
            ic_states: "SANTA CATARINA",
            ic_january: 6.315,
            ic_february: 5.773,
            ic_march: 5.101,
            ic_april: 4.028,
            ic_may: 3.1,
            ic_june: 2.587,
            ic_july: 2.891,
            ic_august: 3.752,
            ic_september: 4.015,
            ic_october: 5.14,
            ic_november: 6.145,
            ic_december: 6.481,
            ic_yearly: 4.611,
          },
          {
            ic_uuid: "78acb257-ad97-4907-9048-3e1d6430218d",
            ic_lat: -19339,
            ic_lon: -42158,
            ic_city: "São João do Oriente",
            ic_states: "MINAS GERAIS",
            ic_january: 5.883,
            ic_february: 6.061,
            ic_march: 5.148,
            ic_april: 4.6,
            ic_may: 3.909,
            ic_june: 3.653,
            ic_july: 3.759,
            ic_august: 4.431,
            ic_september: 4.852,
            ic_october: 5.058,
            ic_november: 4.891,
            ic_december: 5.804,
            ic_yearly: 4.837,
          },
          {
            ic_uuid: "79759946-2ce7-476e-bf8a-dde29eac3043",
            ic_lat: -165326,
            ic_lon: -445302,
            ic_city: "São João do Pacuí",
            ic_states: "MINAS GERAIS",
            ic_january: 6.315,
            ic_february: 6.544,
            ic_march: 5.638,
            ic_april: 5.484,
            ic_may: 4.94,
            ic_june: 4.655,
            ic_july: 5.01,
            ic_august: 5.808,
            ic_september: 5.992,
            ic_october: 6.008,
            ic_november: 5.415,
            ic_december: 6.1,
            ic_yearly: 5.659,
          },
          {
            ic_uuid: "a7136bf5-720a-4a88-ba60-f186b8cb69a9",
            ic_lat: -64651,
            ic_lon: -470565,
            ic_city: "São João do Paraíso",
            ic_states: "MARANHÃO",
            ic_january: 4.656,
            ic_february: 4.785,
            ic_march: 4.706,
            ic_april: 4.735,
            ic_may: 4.924,
            ic_june: 5.276,
            ic_july: 5.439,
            ic_august: 6.022,
            ic_september: 5.947,
            ic_october: 5.341,
            ic_november: 4.805,
            ic_december: 4.771,
            ic_yearly: 5.118,
          },
          {
            ic_uuid: "58274c9a-6c8f-4734-8200-043bda44ad43",
            ic_lat: -153173,
            ic_lon: -420217,
            ic_city: "São João do Paraíso",
            ic_states: "MINAS GERAIS",
            ic_january: 6.022,
            ic_february: 6.248,
            ic_march: 5.354,
            ic_april: 4.761,
            ic_may: 4.159,
            ic_june: 3.826,
            ic_july: 4.076,
            ic_august: 4.817,
            ic_september: 5.71,
            ic_october: 5.764,
            ic_november: 5.4,
            ic_december: 6.056,
            ic_yearly: 5.183,
          },
          {
            ic_uuid: "94188136-aefd-49a6-a6c6-5717feafb227",
            ic_lat: -21268,
            ic_lon: -51666,
            ic_city: "São João do Pau d'Alho",
            ic_states: "SÃO PAULO",
            ic_january: 5.968,
            ic_february: 5.973,
            ic_march: 5.486,
            ic_april: 4.843,
            ic_may: 4.047,
            ic_june: 3.715,
            ic_july: 3.902,
            ic_august: 4.846,
            ic_september: 4.904,
            ic_october: 5.586,
            ic_november: 6.088,
            ic_december: 6.371,
            ic_yearly: 5.144,
          },
          {
            ic_uuid: "5a2d606d-d76f-449f-ade1-a355780060a5",
            ic_lat: -83551,
            ic_lon: -422562,
            ic_city: "São João do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.727,
            ic_february: 5.679,
            ic_march: 5.647,
            ic_april: 5.442,
            ic_may: 5.258,
            ic_june: 5.248,
            ic_july: 5.563,
            ic_august: 6.344,
            ic_september: 6.564,
            ic_october: 6.5,
            ic_november: 6.242,
            ic_december: 5.992,
            ic_yearly: 5.85,
          },
          {
            ic_uuid: "7d0c0fa4-d326-4102-b3c4-6aafaed0462c",
            ic_lat: -29614,
            ic_lon: -534459,
            ic_city: "São João do Polêsine",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.326,
            ic_february: 5.909,
            ic_march: 4.987,
            ic_april: 3.856,
            ic_may: 2.831,
            ic_june: 2.351,
            ic_july: 2.593,
            ic_august: 3.344,
            ic_september: 3.723,
            ic_october: 4.895,
            ic_november: 6.247,
            ic_december: 6.739,
            ic_yearly: 4.483,
          },
          {
            ic_uuid: "c5ed8fab-1b8f-44c5-b476-a260d8251cc5",
            ic_lat: -67216,
            ic_lon: -384555,
            ic_city: "São João do Rio do Peixe",
            ic_states: "PARAÍBA",
            ic_january: 6.182,
            ic_february: 6.087,
            ic_march: 6.069,
            ic_april: 5.795,
            ic_may: 5.233,
            ic_june: 5.009,
            ic_july: 5.315,
            ic_august: 6.045,
            ic_september: 6.43,
            ic_october: 6.631,
            ic_november: 6.756,
            ic_december: 6.362,
            ic_yearly: 5.993,
          },
          {
            ic_uuid: "db7b9f82-6b23-421b-b17f-66e561e534cc",
            ic_lat: -67143,
            ic_lon: -37203,
            ic_city: "São João do Sabugi",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.162,
            ic_february: 6.24,
            ic_march: 6.208,
            ic_april: 5.843,
            ic_may: 5.226,
            ic_june: 4.776,
            ic_july: 5.085,
            ic_august: 5.834,
            ic_september: 6.394,
            ic_october: 6.621,
            ic_november: 6.668,
            ic_december: 6.344,
            ic_yearly: 5.95,
          },
          {
            ic_uuid: "01a84c39-1ad6-4377-af4a-8ab867cbf149",
            ic_lat: -51086,
            ic_lon: -438166,
            ic_city: "São João do Soter",
            ic_states: "MARANHÃO",
            ic_january: 4.805,
            ic_february: 4.996,
            ic_march: 5.0,
            ic_april: 4.945,
            ic_may: 5.019,
            ic_june: 5.119,
            ic_july: 5.484,
            ic_august: 6.19,
            ic_september: 6.438,
            ic_october: 6.001,
            ic_november: 5.579,
            ic_december: 5.133,
            ic_yearly: 5.392,
          },
          {
            ic_uuid: "7f87a4ea-4ed6-4b43-a1d6-43bea59ef459",
            ic_lat: -292191,
            ic_lon: -498036,
            ic_city: "São João do Sul",
            ic_states: "SANTA CATARINA",
            ic_january: 5.329,
            ic_february: 5.026,
            ic_march: 4.319,
            ic_april: 3.742,
            ic_may: 2.888,
            ic_june: 2.441,
            ic_july: 2.687,
            ic_august: 3.357,
            ic_september: 3.461,
            ic_october: 4.106,
            ic_november: 5.381,
            ic_december: 5.637,
            ic_yearly: 4.031,
          },
          {
            ic_uuid: "bab0f471-efa8-45ff-bdea-28ff69551b54",
            ic_lat: -80778,
            ic_lon: -368554,
            ic_city: "São João do Tigre",
            ic_states: "PARAÍBA",
            ic_january: 6.134,
            ic_february: 5.973,
            ic_march: 5.991,
            ic_april: 5.585,
            ic_may: 4.713,
            ic_june: 4.254,
            ic_july: 4.437,
            ic_august: 5.285,
            ic_september: 6.129,
            ic_october: 6.338,
            ic_november: 6.675,
            ic_december: 6.426,
            ic_yearly: 5.662,
          },
          {
            ic_uuid: "f190919a-f12e-4880-9368-454c365bd4da",
            ic_lat: -256834,
            ic_lon: -502954,
            ic_city: "São João do Triunfo",
            ic_states: "PARANÁ",
            ic_january: 5.415,
            ic_february: 5.105,
            ic_march: 4.556,
            ic_april: 3.712,
            ic_may: 2.98,
            ic_june: 2.661,
            ic_july: 2.846,
            ic_august: 3.86,
            ic_september: 3.931,
            ic_october: 4.515,
            ic_november: 5.428,
            ic_december: 5.613,
            ic_yearly: 4.219,
          },
          {
            ic_uuid: "62834338-a1ef-4aeb-802d-551d23c1cfab",
            ic_lat: -64938,
            ic_lon: -43704,
            ic_city: "São João dos Patos",
            ic_states: "MARANHÃO",
            ic_january: 4.76,
            ic_february: 4.962,
            ic_march: 4.93,
            ic_april: 4.926,
            ic_may: 5.071,
            ic_june: 5.369,
            ic_july: 5.655,
            ic_august: 6.239,
            ic_september: 6.534,
            ic_october: 6.007,
            ic_november: 5.526,
            ic_december: 5.1,
            ic_yearly: 5.423,
          },
          {
            ic_uuid: "df71e94d-1df3-4d50-aa14-71090f1cc56a",
            ic_lat: -185485,
            ic_lon: -427659,
            ic_city: "São João Evangelista",
            ic_states: "MINAS GERAIS",
            ic_january: 5.859,
            ic_february: 6.085,
            ic_march: 5.089,
            ic_april: 4.493,
            ic_may: 3.887,
            ic_june: 3.696,
            ic_july: 3.822,
            ic_august: 4.516,
            ic_september: 5.024,
            ic_october: 5.239,
            ic_november: 4.815,
            ic_december: 5.662,
            ic_yearly: 4.849,
          },
          {
            ic_uuid: "72893115-eb68-46ca-897b-46644eac064e",
            ic_lat: -215386,
            ic_lon: -430072,
            ic_city: "São João Nepomuceno",
            ic_states: "MINAS GERAIS",
            ic_january: 5.813,
            ic_february: 5.995,
            ic_march: 4.971,
            ic_april: 4.341,
            ic_may: 3.631,
            ic_june: 3.437,
            ic_july: 3.624,
            ic_august: 4.386,
            ic_september: 4.724,
            ic_october: 5.004,
            ic_november: 4.858,
            ic_december: 5.622,
            ic_yearly: 4.7,
          },
          {
            ic_uuid: "cab12b93-f419-4419-b427-8ce788c7e028",
            ic_lat: -282892,
            ic_lon: -499462,
            ic_city: "São Joaquim",
            ic_states: "SANTA CATARINA",
            ic_january: 5.847,
            ic_february: 5.488,
            ic_march: 4.791,
            ic_april: 3.926,
            ic_may: 3.011,
            ic_june: 2.613,
            ic_july: 2.88,
            ic_august: 3.668,
            ic_september: 3.906,
            ic_october: 4.881,
            ic_november: 6.094,
            ic_december: 6.401,
            ic_yearly: 4.459,
          },
          {
            ic_uuid: "6e5abbac-0272-4d8c-8bb6-041ee5d929b5",
            ic_lat: -205816,
            ic_lon: -478597,
            ic_city: "São Joaquim da Barra",
            ic_states: "SÃO PAULO",
            ic_january: 5.733,
            ic_february: 5.972,
            ic_march: 5.138,
            ic_april: 4.859,
            ic_may: 4.234,
            ic_june: 3.989,
            ic_july: 4.256,
            ic_august: 5.15,
            ic_september: 5.117,
            ic_october: 5.564,
            ic_november: 5.727,
            ic_december: 6.026,
            ic_yearly: 5.147,
          },
          {
            ic_uuid: "006731f1-49c1-4bc7-9ecc-75b430fb7de4",
            ic_lat: -200484,
            ic_lon: -442753,
            ic_city: "São Joaquim de Bicas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.869,
            ic_february: 6.035,
            ic_march: 5.189,
            ic_april: 4.859,
            ic_may: 4.238,
            ic_june: 4.097,
            ic_july: 4.351,
            ic_august: 5.198,
            ic_september: 5.506,
            ic_october: 5.604,
            ic_november: 5.323,
            ic_december: 5.625,
            ic_yearly: 5.158,
          },
          {
            ic_uuid: "f962978e-24f9-4a22-a1c5-de5976af1940",
            ic_lat: -84324,
            ic_lon: -358038,
            ic_city: "São Joaquim do Monte",
            ic_states: "PERNAMBUCO",
            ic_january: 5.732,
            ic_february: 5.694,
            ic_march: 5.732,
            ic_april: 5.078,
            ic_may: 4.304,
            ic_june: 3.856,
            ic_july: 3.981,
            ic_august: 4.662,
            ic_september: 5.392,
            ic_october: 5.7,
            ic_november: 6.131,
            ic_december: 5.944,
            ic_yearly: 5.184,
          },
          {
            ic_uuid: "bc7716e9-2a75-45ba-a8dc-baae1e152f88",
            ic_lat: -284989,
            ic_lon: -517069,
            ic_city: "São Jorge",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.099,
            ic_february: 5.682,
            ic_march: 4.86,
            ic_april: 3.948,
            ic_may: 2.929,
            ic_june: 2.496,
            ic_july: 2.799,
            ic_august: 3.523,
            ic_september: 3.771,
            ic_october: 4.889,
            ic_november: 6.114,
            ic_december: 6.448,
            ic_yearly: 4.463,
          },
          {
            ic_uuid: "bad335dc-f84e-422b-bdf3-6f565132077b",
            ic_lat: -234341,
            ic_lon: -522933,
            ic_city: "São Jorge do Ivaí",
            ic_states: "PARANÁ",
            ic_january: 5.965,
            ic_february: 5.844,
            ic_march: 5.284,
            ic_april: 4.575,
            ic_may: 3.633,
            ic_june: 3.282,
            ic_july: 3.492,
            ic_august: 4.38,
            ic_september: 4.657,
            ic_october: 5.368,
            ic_november: 6.041,
            ic_december: 6.371,
            ic_yearly: 4.908,
          },
          {
            ic_uuid: "04eb46bb-d00a-4d40-b0de-272e170d8b9b",
            ic_lat: -237652,
            ic_lon: -538827,
            ic_city: "São Jorge do Patrocínio",
            ic_states: "PARANÁ",
            ic_january: 6.183,
            ic_february: 5.88,
            ic_march: 5.347,
            ic_april: 4.45,
            ic_may: 3.501,
            ic_june: 3.122,
            ic_july: 3.312,
            ic_august: 4.312,
            ic_september: 4.575,
            ic_october: 5.369,
            ic_november: 6.134,
            ic_december: 6.515,
            ic_yearly: 4.891,
          },
          {
            ic_uuid: "8dd52d1c-ffc2-4582-a07d-5c569b1993e0",
            ic_lat: -256995,
            ic_lon: -529135,
            ic_city: "São Jorge d'Oeste",
            ic_states: "PARANÁ",
            ic_january: 6.304,
            ic_february: 5.735,
            ic_march: 5.244,
            ic_april: 4.287,
            ic_may: 3.28,
            ic_june: 2.835,
            ic_july: 3.079,
            ic_august: 4.079,
            ic_september: 4.293,
            ic_october: 5.252,
            ic_november: 6.188,
            ic_december: 6.419,
            ic_yearly: 4.75,
          },
          {
            ic_uuid: "219700ec-ab5e-4ba3-a872-94f143afcb0d",
            ic_lat: -275904,
            ic_lon: -48616,
            ic_city: "São José",
            ic_states: "SANTA CATARINA",
            ic_january: 5.733,
            ic_february: 5.352,
            ic_march: 4.573,
            ic_april: 3.733,
            ic_may: 3.03,
            ic_june: 2.544,
            ic_july: 2.679,
            ic_august: 3.389,
            ic_september: 3.464,
            ic_october: 4.149,
            ic_november: 5.38,
            ic_december: 5.844,
            ic_yearly: 4.156,
          },
          {
            ic_uuid: "5670aecb-7a44-406a-ac6b-846d3e486592",
            ic_lat: -207183,
            ic_lon: -463134,
            ic_city: "São José da Barra",
            ic_states: "MINAS GERAIS",
            ic_january: 5.622,
            ic_february: 5.808,
            ic_march: 5.021,
            ic_april: 4.825,
            ic_may: 4.25,
            ic_june: 4.02,
            ic_july: 4.258,
            ic_august: 5.114,
            ic_september: 5.216,
            ic_october: 5.573,
            ic_november: 5.408,
            ic_december: 5.636,
            ic_yearly: 5.063,
          },
          {
            ic_uuid: "e9ab1698-0bbe-4d46-aa27-f2299144fdf7",
            ic_lat: -20594,
            ic_lon: -476428,
            ic_city: "São José da Bela Vista",
            ic_states: "SÃO PAULO",
            ic_january: 5.641,
            ic_february: 5.875,
            ic_march: 5.04,
            ic_april: 4.88,
            ic_may: 4.239,
            ic_june: 4.006,
            ic_july: 4.229,
            ic_august: 5.212,
            ic_september: 5.104,
            ic_october: 5.509,
            ic_november: 5.654,
            ic_december: 5.92,
            ic_yearly: 5.109,
          },
          {
            ic_uuid: "0de6edd3-087a-4a3f-a945-1a6bbb3bd5c3",
            ic_lat: -23916,
            ic_lon: -496519,
            ic_city: "São José da Boa Vista",
            ic_states: "PARANÁ",
            ic_january: 5.699,
            ic_february: 5.709,
            ic_march: 5.055,
            ic_april: 4.421,
            ic_may: 3.516,
            ic_june: 3.141,
            ic_july: 3.374,
            ic_august: 4.368,
            ic_september: 4.552,
            ic_october: 5.146,
            ic_november: 5.852,
            ic_december: 6.198,
            ic_yearly: 4.753,
          },
          {
            ic_uuid: "1f959d8e-6a3c-4b5e-b44a-cf78f101f0ea",
            ic_lat: -88898,
            ic_lon: -351518,
            ic_city: "São José da Coroa Grande",
            ic_states: "PERNAMBUCO",
            ic_january: 5.967,
            ic_february: 5.998,
            ic_march: 6.061,
            ic_april: 5.128,
            ic_may: 4.428,
            ic_june: 4.162,
            ic_july: 4.212,
            ic_august: 4.928,
            ic_september: 5.636,
            ic_october: 5.994,
            ic_november: 6.213,
            ic_december: 6.273,
            ic_yearly: 5.417,
          },
          {
            ic_uuid: "60824446-8d60-4d72-a0ea-0c337652000f",
            ic_lat: -69369,
            ic_lon: -381625,
            ic_city: "São José da Lagoa Tapada",
            ic_states: "PARAÍBA",
            ic_january: 6.121,
            ic_february: 6.145,
            ic_march: 6.189,
            ic_april: 5.888,
            ic_may: 5.347,
            ic_june: 5.05,
            ic_july: 5.279,
            ic_august: 6.061,
            ic_september: 6.549,
            ic_october: 6.675,
            ic_november: 6.791,
            ic_december: 6.41,
            ic_yearly: 6.042,
          },
          {
            ic_uuid: "12483435-cb91-48c2-83dd-406f0965ecf4",
            ic_lat: -90132,
            ic_lon: -360518,
            ic_city: "São José da Laje",
            ic_states: "ALAGOAS",
            ic_january: 5.718,
            ic_february: 5.63,
            ic_march: 5.634,
            ic_april: 5.02,
            ic_may: 4.168,
            ic_june: 3.723,
            ic_july: 3.798,
            ic_august: 4.434,
            ic_september: 5.267,
            ic_october: 5.498,
            ic_november: 6.073,
            ic_december: 5.993,
            ic_yearly: 5.08,
          },
          {
            ic_uuid: "dbaf9b9e-5f52-4ec2-9f5b-b65ecafe2ae6",
            ic_lat: -196976,
            ic_lon: -43959,
            ic_city: "São José da Lapa",
            ic_states: "MINAS GERAIS",
            ic_january: 5.845,
            ic_february: 6.076,
            ic_march: 5.293,
            ic_april: 4.886,
            ic_may: 4.297,
            ic_june: 4.087,
            ic_july: 4.365,
            ic_august: 5.194,
            ic_september: 5.561,
            ic_october: 5.629,
            ic_november: 5.444,
            ic_december: 5.71,
            ic_yearly: 5.199,
          },
          {
            ic_uuid: "54129e62-6aae-40aa-81cd-1b67199cbf3a",
            ic_lat: -183249,
            ic_lon: -421461,
            ic_city: "São José da Safira",
            ic_states: "MINAS GERAIS",
            ic_january: 5.835,
            ic_february: 6.04,
            ic_march: 5.094,
            ic_april: 4.482,
            ic_may: 3.86,
            ic_june: 3.65,
            ic_july: 3.773,
            ic_august: 4.485,
            ic_september: 4.962,
            ic_october: 5.202,
            ic_november: 4.814,
            ic_december: 5.63,
            ic_yearly: 4.819,
          },
          {
            ic_uuid: "1ad276c0-9353-4a1c-b6cd-34c0970b1fbc",
            ic_lat: -95581,
            ic_lon: -373834,
            ic_city: "São José da Tapera",
            ic_states: "ALAGOAS",
            ic_january: 6.159,
            ic_february: 5.971,
            ic_march: 5.949,
            ic_april: 5.272,
            ic_may: 4.358,
            ic_june: 4.012,
            ic_july: 4.163,
            ic_august: 4.737,
            ic_september: 5.538,
            ic_october: 5.919,
            ic_november: 6.483,
            ic_december: 6.298,
            ic_yearly: 5.405,
          },
          {
            ic_uuid: "21e8eb38-07b8-406d-96a7-3170f22b5b87",
            ic_lat: -19701,
            ic_lon: -445563,
            ic_city: "São José da Varginha",
            ic_states: "MINAS GERAIS",
            ic_january: 5.982,
            ic_february: 6.067,
            ic_march: 5.178,
            ic_april: 4.914,
            ic_may: 4.325,
            ic_june: 4.144,
            ic_july: 4.434,
            ic_august: 5.282,
            ic_september: 5.564,
            ic_october: 5.635,
            ic_november: 5.406,
            ic_december: 5.698,
            ic_yearly: 5.219,
          },
          {
            ic_uuid: "b39d7ecf-7b7d-4e6a-a2cb-d277d4a7be45",
            ic_lat: -150784,
            ic_lon: -393411,
            ic_city: "São José da Vitória",
            ic_states: "BAHIA",
            ic_january: 5.706,
            ic_february: 5.756,
            ic_march: 5.211,
            ic_april: 4.397,
            ic_may: 3.751,
            ic_june: 3.405,
            ic_july: 3.648,
            ic_august: 4.17,
            ic_september: 4.77,
            ic_october: 4.909,
            ic_november: 4.979,
            ic_december: 5.844,
            ic_yearly: 4.712,
          },
          {
            ic_uuid: "2c59cab5-b5ed-4b88-b17d-88f59c5ef81a",
            ic_lat: -277794,
            ic_lon: -531231,
            ic_city: "São José das Missões",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.373,
            ic_february: 5.917,
            ic_march: 5.059,
            ic_april: 4.115,
            ic_may: 3.1,
            ic_june: 2.569,
            ic_july: 2.908,
            ic_august: 3.664,
            ic_september: 3.965,
            ic_october: 5.136,
            ic_november: 6.251,
            ic_december: 6.674,
            ic_yearly: 4.644,
          },
          {
            ic_uuid: "7152e608-66cb-42a8-bbc5-994ca513caa5",
            ic_lat: -248373,
            ic_lon: -540577,
            ic_city: "São José das Palmeiras",
            ic_states: "PARANÁ",
            ic_january: 6.26,
            ic_february: 5.706,
            ic_march: 5.333,
            ic_april: 4.263,
            ic_may: 3.319,
            ic_june: 2.941,
            ic_july: 3.158,
            ic_august: 4.159,
            ic_september: 4.406,
            ic_october: 5.295,
            ic_november: 6.145,
            ic_december: 6.526,
            ic_yearly: 4.793,
          },
          {
            ic_uuid: "3276b437-37a7-4a6f-877c-ee029025e931",
            ic_lat: -72519,
            ic_lon: -383002,
            ic_city: "São José de Caiana",
            ic_states: "PARAÍBA",
            ic_january: 6.144,
            ic_february: 6.071,
            ic_march: 6.095,
            ic_april: 5.811,
            ic_may: 5.246,
            ic_june: 4.932,
            ic_july: 5.167,
            ic_august: 5.976,
            ic_september: 6.592,
            ic_october: 6.643,
            ic_november: 6.838,
            ic_december: 6.461,
            ic_yearly: 5.998,
          },
          {
            ic_uuid: "e1ce4454-88df-4e07-ae0a-c1cf1e7441e4",
            ic_lat: -68402,
            ic_lon: -373217,
            ic_city: "São José de Espinharas",
            ic_states: "PARAÍBA",
            ic_january: 6.102,
            ic_february: 6.203,
            ic_march: 6.23,
            ic_april: 5.885,
            ic_may: 5.232,
            ic_june: 4.779,
            ic_july: 5.085,
            ic_august: 5.86,
            ic_september: 6.396,
            ic_october: 6.582,
            ic_november: 6.636,
            ic_december: 6.344,
            ic_yearly: 5.944,
          },
          {
            ic_uuid: "3c6eef83-d22a-4951-9577-d0a1a3919f13",
            ic_lat: -6074,
            ic_lon: -352353,
            ic_city: "São José de Mipibu",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.017,
            ic_february: 6.175,
            ic_march: 6.131,
            ic_april: 5.558,
            ic_may: 5.043,
            ic_june: 4.532,
            ic_july: 4.655,
            ic_august: 5.441,
            ic_september: 5.933,
            ic_october: 6.196,
            ic_november: 6.36,
            ic_december: 6.097,
            ic_yearly: 5.678,
          },
          {
            ic_uuid: "16266277-5f0c-404a-9bac-def71322afe4",
            ic_lat: -71191,
            ic_lon: -385023,
            ic_city: "São José de Piranhas",
            ic_states: "PARAÍBA",
            ic_january: 6.194,
            ic_february: 6.156,
            ic_march: 6.116,
            ic_april: 5.824,
            ic_may: 5.256,
            ic_june: 4.973,
            ic_july: 5.227,
            ic_august: 6.017,
            ic_september: 6.601,
            ic_october: 6.671,
            ic_november: 6.766,
            ic_december: 6.479,
            ic_yearly: 6.023,
          },
          {
            ic_uuid: "c5ec9b87-2b33-4775-ba08-12b99089945b",
            ic_lat: -77431,
            ic_lon: -381003,
            ic_city: "São José de Princesa",
            ic_states: "PARAÍBA",
            ic_january: 6.157,
            ic_february: 6.109,
            ic_march: 6.139,
            ic_april: 5.72,
            ic_may: 5.048,
            ic_june: 4.69,
            ic_july: 4.94,
            ic_august: 5.779,
            ic_september: 6.472,
            ic_october: 6.564,
            ic_november: 6.738,
            ic_december: 6.323,
            ic_yearly: 5.89,
          },
          {
            ic_uuid: "0076ca4d-e9ea-441c-a59c-eefd647ae475",
            ic_lat: -25539,
            ic_lon: -440581,
            ic_city: "São José de Ribamar",
            ic_states: "MARANHÃO",
            ic_january: 5.026,
            ic_february: 4.883,
            ic_march: 4.736,
            ic_april: 4.575,
            ic_may: 4.707,
            ic_june: 4.964,
            ic_july: 5.191,
            ic_august: 5.85,
            ic_september: 6.18,
            ic_october: 6.059,
            ic_november: 5.963,
            ic_december: 5.667,
            ic_yearly: 5.317,
          },
          {
            ic_uuid: "944832c5-374b-452e-b8f1-2284b4e20fc9",
            ic_lat: -213589,
            ic_lon: -419385,
            ic_city: "São José de Ubá",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.138,
            ic_february: 6.355,
            ic_march: 5.176,
            ic_april: 4.469,
            ic_may: 3.793,
            ic_june: 3.526,
            ic_july: 3.641,
            ic_august: 4.42,
            ic_september: 4.841,
            ic_october: 5.192,
            ic_november: 5.088,
            ic_december: 5.982,
            ic_yearly: 4.885,
          },
          {
            ic_uuid: "17c185b7-22be-4646-bd93-cd4c0381ef05",
            ic_lat: -223247,
            ic_lon: -455262,
            ic_city: "São José do Alegre",
            ic_states: "MINAS GERAIS",
            ic_january: 5.317,
            ic_february: 5.606,
            ic_march: 4.862,
            ic_april: 4.542,
            ic_may: 3.83,
            ic_june: 3.623,
            ic_july: 3.838,
            ic_august: 4.725,
            ic_september: 4.93,
            ic_october: 5.355,
            ic_november: 5.283,
            ic_december: 5.645,
            ic_yearly: 4.796,
          },
          {
            ic_uuid: "a1820f48-4684-4aed-8b08-7c725bfaa920",
            ic_lat: -226418,
            ic_lon: -445778,
            ic_city: "São José do Barreiro",
            ic_states: "SÃO PAULO",
            ic_january: 5.583,
            ic_february: 5.772,
            ic_march: 4.929,
            ic_april: 4.386,
            ic_may: 3.67,
            ic_june: 3.4,
            ic_july: 3.48,
            ic_august: 4.403,
            ic_september: 4.566,
            ic_october: 4.999,
            ic_november: 5.05,
            ic_december: 5.761,
            ic_yearly: 4.667,
          },
          {
            ic_uuid: "9263f1a2-abbd-4658-b8e1-16ea73ce6f2d",
            ic_lat: -78654,
            ic_lon: -387613,
            ic_city: "São José do Belmonte",
            ic_states: "PERNAMBUCO",
            ic_january: 6.163,
            ic_february: 6.045,
            ic_march: 5.946,
            ic_april: 5.566,
            ic_may: 4.866,
            ic_june: 4.518,
            ic_july: 4.794,
            ic_august: 5.595,
            ic_september: 6.501,
            ic_october: 6.503,
            ic_november: 6.622,
            ic_december: 6.359,
            ic_yearly: 5.79,
          },
          {
            ic_uuid: "af6df7b0-1336-4897-a881-b72b0cb860b2",
            ic_lat: -71604,
            ic_lon: -373072,
            ic_city: "São José do Bonfim",
            ic_states: "PARAÍBA",
            ic_january: 6.232,
            ic_february: 6.204,
            ic_march: 6.252,
            ic_april: 5.84,
            ic_may: 5.145,
            ic_june: 4.675,
            ic_july: 5.01,
            ic_august: 5.88,
            ic_september: 6.479,
            ic_october: 6.618,
            ic_november: 6.743,
            ic_december: 6.338,
            ic_yearly: 5.951,
          },
          {
            ic_uuid: "0b5207ec-8281-4bc0-a237-379655152d86",
            ic_lat: -62122,
            ic_lon: -373549,
            ic_city: "São José do Brejo do Cruz",
            ic_states: "PARAÍBA",
            ic_january: 6.1,
            ic_february: 6.126,
            ic_march: 6.028,
            ic_april: 5.852,
            ic_may: 5.307,
            ic_june: 4.887,
            ic_july: 5.218,
            ic_august: 5.951,
            ic_september: 6.484,
            ic_october: 6.593,
            ic_november: 6.601,
            ic_december: 6.193,
            ic_yearly: 5.945,
          },
          {
            ic_uuid: "126458d3-56af-4088-80d5-537d3b2ef184",
            ic_lat: -210303,
            ic_lon: -416568,
            ic_city: "São José do Calçado",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.95,
            ic_february: 6.132,
            ic_march: 5.02,
            ic_april: 4.446,
            ic_may: 3.784,
            ic_june: 3.61,
            ic_july: 3.705,
            ic_august: 4.45,
            ic_september: 4.899,
            ic_october: 5.017,
            ic_november: 4.87,
            ic_december: 5.648,
            ic_yearly: 4.794,
          },
          {
            ic_uuid: "a7d3ae62-20ce-46b2-9b92-4f5ad1074046",
            ic_lat: -63176,
            ic_lon: -357126,
            ic_city: "São José do Campestre",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.798,
            ic_february: 5.909,
            ic_march: 5.941,
            ic_april: 5.494,
            ic_may: 4.955,
            ic_june: 4.499,
            ic_july: 4.619,
            ic_august: 5.348,
            ic_september: 5.744,
            ic_october: 5.96,
            ic_november: 6.149,
            ic_december: 5.863,
            ic_yearly: 5.523,
          },
          {
            ic_uuid: "2c469288-35b6-4f05-b27a-589b1876381d",
            ic_lat: -264566,
            ic_lon: -534959,
            ic_city: "São José do Cedro",
            ic_states: "SANTA CATARINA",
            ic_january: 6.278,
            ic_february: 5.774,
            ic_march: 5.086,
            ic_april: 4.118,
            ic_may: 3.21,
            ic_june: 2.731,
            ic_july: 3.009,
            ic_august: 3.903,
            ic_september: 4.174,
            ic_october: 5.149,
            ic_november: 6.035,
            ic_december: 6.356,
            ic_yearly: 4.652,
          },
          {
            ic_uuid: "ae3fb168-7a2d-40a8-8eae-e30bef880629",
            ic_lat: -276607,
            ic_lon: -505737,
            ic_city: "São José do Cerrito",
            ic_states: "SANTA CATARINA",
            ic_january: 5.761,
            ic_february: 5.428,
            ic_march: 4.65,
            ic_april: 3.81,
            ic_may: 2.886,
            ic_june: 2.517,
            ic_july: 2.761,
            ic_august: 3.589,
            ic_september: 3.779,
            ic_october: 4.554,
            ic_november: 5.758,
            ic_december: 6.097,
            ic_yearly: 4.299,
          },
          {
            ic_uuid: "bcc03e7a-f894-4b6b-83d7-572d785773cf",
            ic_lat: -184798,
            ic_lon: -41391,
            ic_city: "São José do Divino",
            ic_states: "MINAS GERAIS",
            ic_january: 6.047,
            ic_february: 6.129,
            ic_march: 5.218,
            ic_april: 4.549,
            ic_may: 3.819,
            ic_june: 3.601,
            ic_july: 3.705,
            ic_august: 4.313,
            ic_september: 4.868,
            ic_october: 5.149,
            ic_november: 4.987,
            ic_december: 5.825,
            ic_yearly: 4.851,
          },
          {
            ic_uuid: "79b70bb2-2ea8-411f-9229-d9412523a207",
            ic_lat: -3811,
            ic_lon: -418317,
            ic_city: "São José do Divino",
            ic_states: "PIAUÍ",
            ic_january: 5.219,
            ic_february: 5.369,
            ic_march: 5.256,
            ic_april: 5.056,
            ic_may: 5.235,
            ic_june: 5.377,
            ic_july: 5.563,
            ic_august: 6.054,
            ic_september: 6.437,
            ic_october: 6.513,
            ic_november: 6.318,
            ic_december: 5.634,
            ic_yearly: 5.669,
          },
          {
            ic_uuid: "2dca6795-d7d9-436e-be6b-2a9ed283403a",
            ic_lat: -74699,
            ic_lon: -372743,
            ic_city: "São José do Egito",
            ic_states: "PERNAMBUCO",
            ic_january: 6.148,
            ic_february: 6.04,
            ic_march: 6.025,
            ic_april: 5.661,
            ic_may: 4.954,
            ic_june: 4.478,
            ic_july: 4.699,
            ic_august: 5.507,
            ic_september: 6.281,
            ic_october: 6.493,
            ic_november: 6.657,
            ic_december: 6.289,
            ic_yearly: 5.769,
          },
          {
            ic_uuid: "c46cd892-3208-4a91-85b8-2b6b83cf167d",
            ic_lat: -199219,
            ic_lon: -427039,
            ic_city: "São José do Goiabal",
            ic_states: "MINAS GERAIS",
            ic_january: 5.673,
            ic_february: 6.021,
            ic_march: 5.028,
            ic_april: 4.593,
            ic_may: 3.873,
            ic_june: 3.68,
            ic_july: 3.909,
            ic_august: 4.588,
            ic_september: 4.908,
            ic_october: 5.069,
            ic_november: 4.782,
            ic_december: 5.49,
            ic_yearly: 4.801,
          },
          {
            ic_uuid: "c26fcf4c-9344-4e16-b466-3da2ebf59429",
            ic_lat: -29044,
            ic_lon: -522959,
            ic_city: "São José do Herval",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.082,
            ic_february: 5.694,
            ic_march: 4.818,
            ic_april: 3.842,
            ic_may: 2.867,
            ic_june: 2.406,
            ic_july: 2.707,
            ic_august: 3.432,
            ic_september: 3.682,
            ic_october: 4.749,
            ic_november: 6.094,
            ic_december: 6.457,
            ic_yearly: 4.403,
          },
          {
            ic_uuid: "fc498b12-25d4-44b6-bc11-d6a47483f754",
            ic_lat: -295247,
            ic_lon: -512485,
            ic_city: "São José do Hortêncio",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.19,
            ic_february: 5.709,
            ic_march: 4.782,
            ic_april: 3.79,
            ic_may: 2.806,
            ic_june: 2.346,
            ic_july: 2.564,
            ic_august: 3.241,
            ic_september: 3.543,
            ic_october: 4.591,
            ic_november: 6.088,
            ic_december: 6.488,
            ic_yearly: 4.345,
          },
          {
            ic_uuid: "449d9fe6-f1d1-42d3-817c-5da81dddf7fa",
            ic_lat: -277255,
            ic_lon: -541279,
            ic_city: "São José do Inhacorá",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.532,
            ic_february: 5.998,
            ic_march: 5.163,
            ic_april: 4.096,
            ic_may: 3.125,
            ic_june: 2.557,
            ic_july: 2.864,
            ic_august: 3.7,
            ic_september: 4.0,
            ic_october: 5.227,
            ic_november: 6.268,
            ic_december: 6.692,
            ic_yearly: 4.685,
          },
          {
            ic_uuid: "6a2db4c5-b32f-4652-b667-e4d52dc5edaf",
            ic_lat: -115051,
            ic_lon: -400239,
            ic_city: "São José do Jacuípe",
            ic_states: "BAHIA",
            ic_january: 6.067,
            ic_february: 5.893,
            ic_march: 5.744,
            ic_april: 4.66,
            ic_may: 3.992,
            ic_june: 3.7,
            ic_july: 3.868,
            ic_august: 4.439,
            ic_september: 5.36,
            ic_october: 5.49,
            ic_november: 5.761,
            ic_december: 5.918,
            ic_yearly: 5.074,
          },
          {
            ic_uuid: "496dca32-c519-4a5f-89a0-a2d3dcce42cb",
            ic_lat: -182744,
            ic_lon: -426711,
            ic_city: "São José do Jacuri",
            ic_states: "MINAS GERAIS",
            ic_january: 5.825,
            ic_february: 6.101,
            ic_march: 5.129,
            ic_april: 4.533,
            ic_may: 3.879,
            ic_june: 3.736,
            ic_july: 3.799,
            ic_august: 4.559,
            ic_september: 5.027,
            ic_october: 5.268,
            ic_november: 4.82,
            ic_december: 5.599,
            ic_yearly: 4.856,
          },
          {
            ic_uuid: "90277db5-b181-4aba-8de0-b4c432dfb865",
            ic_lat: -200062,
            ic_lon: -417489,
            ic_city: "São José do Mantimento",
            ic_states: "MINAS GERAIS",
            ic_january: 5.784,
            ic_february: 5.995,
            ic_march: 5.02,
            ic_april: 4.598,
            ic_may: 3.839,
            ic_june: 3.636,
            ic_july: 3.742,
            ic_august: 4.4,
            ic_september: 4.74,
            ic_october: 4.876,
            ic_november: 4.787,
            ic_december: 5.534,
            ic_yearly: 4.746,
          },
          {
            ic_uuid: "4c30d121-bd7e-45be-b222-6408d7c9312a",
            ic_lat: -320125,
            ic_lon: -520338,
            ic_city: "São José do Norte",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.391,
            ic_february: 5.749,
            ic_march: 4.852,
            ic_april: 3.65,
            ic_may: 2.645,
            ic_june: 2.219,
            ic_july: 2.37,
            ic_august: 2.992,
            ic_september: 3.626,
            ic_october: 4.913,
            ic_november: 6.215,
            ic_december: 6.774,
            ic_yearly: 4.366,
          },
          {
            ic_uuid: "696d26fb-1c22-4b78-a9ab-3085c0078439",
            ic_lat: -277712,
            ic_lon: -515971,
            ic_city: "São José do Ouro",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.291,
            ic_february: 5.778,
            ic_march: 4.957,
            ic_april: 4.027,
            ic_may: 3.02,
            ic_june: 2.592,
            ic_july: 2.914,
            ic_august: 3.713,
            ic_september: 3.921,
            ic_october: 4.997,
            ic_november: 6.174,
            ic_december: 6.484,
            ic_yearly: 4.572,
          },
          {
            ic_uuid: "c312b81e-636f-4c4c-a35c-fa4337956b6b",
            ic_lat: -7494,
            ic_lon: -425678,
            ic_city: "São José do Peixe",
            ic_states: "PIAUÍ",
            ic_january: 5.489,
            ic_february: 5.454,
            ic_march: 5.439,
            ic_april: 5.318,
            ic_may: 5.286,
            ic_june: 5.287,
            ic_july: 5.627,
            ic_august: 6.173,
            ic_september: 6.607,
            ic_october: 6.457,
            ic_november: 6.065,
            ic_december: 5.786,
            ic_yearly: 5.749,
          },
          {
            ic_uuid: "4ff6b4ff-8d88-46bd-9f88-1b20a99f87ca",
            ic_lat: -68725,
            ic_lon: -414785,
            ic_city: "São José do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.508,
            ic_february: 5.36,
            ic_march: 5.458,
            ic_april: 5.345,
            ic_may: 5.153,
            ic_june: 5.138,
            ic_july: 5.523,
            ic_august: 6.325,
            ic_september: 6.74,
            ic_october: 6.496,
            ic_november: 6.331,
            ic_december: 5.894,
            ic_yearly: 5.773,
          },
          {
            ic_uuid: "17328be9-9099-4fde-bea9-d99ffe179f5d",
            ic_lat: -164657,
            ic_lon: -542542,
            ic_city: "São José do Povo",
            ic_states: "MATO GROSSO",
            ic_january: 5.648,
            ic_february: 5.635,
            ic_march: 5.396,
            ic_april: 5.185,
            ic_may: 4.725,
            ic_june: 4.516,
            ic_july: 4.671,
            ic_august: 5.506,
            ic_september: 5.273,
            ic_october: 5.42,
            ic_november: 5.671,
            ic_december: 5.782,
            ic_yearly: 5.286,
          },
          {
            ic_uuid: "45e647c5-191e-4b3c-bd44-7c0c7198b140",
            ic_lat: -134502,
            ic_lon: -56721,
            ic_city: "São José do Rio Claro",
            ic_states: "MATO GROSSO",
            ic_january: 5.156,
            ic_february: 5.011,
            ic_march: 4.985,
            ic_april: 4.897,
            ic_may: 4.709,
            ic_june: 4.616,
            ic_july: 4.777,
            ic_august: 5.341,
            ic_september: 5.219,
            ic_october: 5.433,
            ic_november: 5.297,
            ic_december: 5.301,
            ic_yearly: 5.062,
          },
          {
            ic_uuid: "6f0d794b-3e75-4cd0-982a-960b40894c2d",
            ic_lat: -215958,
            ic_lon: -468877,
            ic_city: "São José do Rio Pardo",
            ic_states: "SÃO PAULO",
            ic_january: 5.695,
            ic_february: 5.887,
            ic_march: 5.123,
            ic_april: 4.83,
            ic_may: 4.069,
            ic_june: 3.83,
            ic_july: 4.039,
            ic_august: 4.908,
            ic_september: 5.06,
            ic_october: 5.555,
            ic_november: 5.787,
            ic_december: 5.943,
            ic_yearly: 5.06,
          },
          {
            ic_uuid: "a7714f4e-2df9-46b7-9e77-75fecd22327e",
            ic_lat: -208118,
            ic_lon: -493762,
            ic_city: "São José do Rio Preto",
            ic_states: "SÃO PAULO",
            ic_january: 5.754,
            ic_february: 5.855,
            ic_march: 5.185,
            ic_april: 4.823,
            ic_may: 4.104,
            ic_june: 3.899,
            ic_july: 4.14,
            ic_august: 5.061,
            ic_september: 5.104,
            ic_october: 5.622,
            ic_november: 5.905,
            ic_december: 6.136,
            ic_yearly: 5.132,
          },
          {
            ic_uuid: "2ea45d94-54a2-4b07-a68d-d77b951410bc",
            ic_lat: -67741,
            ic_lon: -367983,
            ic_city: "São José do Sabugi",
            ic_states: "PARAÍBA",
            ic_january: 6.037,
            ic_february: 6.052,
            ic_march: 6.109,
            ic_april: 5.683,
            ic_may: 5.068,
            ic_june: 4.643,
            ic_july: 4.939,
            ic_august: 5.728,
            ic_september: 6.267,
            ic_october: 6.449,
            ic_november: 6.482,
            ic_december: 6.206,
            ic_yearly: 5.805,
          },
          {
            ic_uuid: "bd37fdbc-c065-4063-923b-ebd525c5dfba",
            ic_lat: -6448,
            ic_lon: -368813,
            ic_city: "São José do Seridó",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.03,
            ic_february: 6.033,
            ic_march: 6.079,
            ic_april: 5.698,
            ic_may: 5.097,
            ic_june: 4.693,
            ic_july: 4.944,
            ic_august: 5.717,
            ic_september: 6.29,
            ic_october: 6.469,
            ic_november: 6.47,
            ic_december: 6.158,
            ic_yearly: 5.806,
          },
          {
            ic_uuid: "a9ec0e68-e460-4fe2-9919-f83c7b663d3c",
            ic_lat: -295423,
            ic_lon: -514878,
            ic_city: "São José do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.061,
            ic_february: 5.617,
            ic_march: 4.726,
            ic_april: 3.756,
            ic_may: 2.793,
            ic_june: 2.341,
            ic_july: 2.551,
            ic_august: 3.237,
            ic_september: 3.519,
            ic_october: 4.51,
            ic_november: 6.009,
            ic_december: 6.372,
            ic_yearly: 4.291,
          },
          {
            ic_uuid: "e0a47ae0-0e7f-4c2f-8093-5e953b68a67c",
            ic_lat: -22153,
            ic_lon: -429331,
            ic_city: "São José do Vale do Rio Preto",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.733,
            ic_february: 6.01,
            ic_march: 4.984,
            ic_april: 4.292,
            ic_may: 3.576,
            ic_june: 3.342,
            ic_july: 3.504,
            ic_august: 4.272,
            ic_september: 4.569,
            ic_october: 4.965,
            ic_november: 4.862,
            ic_december: 5.64,
            ic_yearly: 4.645,
          },
          {
            ic_uuid: "d49e1c46-55d2-44dd-b02c-df89b7c1e08b",
            ic_lat: -108019,
            ic_lon: -527351,
            ic_city: "São José do Xingu",
            ic_states: "MATO GROSSO",
            ic_january: 4.977,
            ic_february: 5.075,
            ic_march: 4.837,
            ic_april: 4.77,
            ic_may: 4.847,
            ic_june: 4.873,
            ic_july: 5.148,
            ic_august: 5.62,
            ic_september: 5.229,
            ic_october: 5.074,
            ic_november: 5.023,
            ic_december: 5.18,
            ic_yearly: 5.055,
          },
          {
            ic_uuid: "f2729fa1-0199-4855-ad0a-9a15ff38e46b",
            ic_lat: -287481,
            ic_lon: -500682,
            ic_city: "São José dos Ausentes",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 5.977,
            ic_february: 5.529,
            ic_march: 4.79,
            ic_april: 3.956,
            ic_may: 2.96,
            ic_june: 2.55,
            ic_july: 2.861,
            ic_august: 3.63,
            ic_september: 3.804,
            ic_october: 4.885,
            ic_november: 6.093,
            ic_december: 6.381,
            ic_yearly: 4.451,
          },
          {
            ic_uuid: "72895ea8-1af4-4c8e-8684-ea13b49499fc",
            ic_lat: -50596,
            ic_lon: -445824,
            ic_city: "São José dos Basílios",
            ic_states: "MARANHÃO",
            ic_january: 4.78,
            ic_february: 4.853,
            ic_march: 4.894,
            ic_april: 4.842,
            ic_may: 4.932,
            ic_june: 5.141,
            ic_july: 5.384,
            ic_august: 5.98,
            ic_september: 6.204,
            ic_october: 5.643,
            ic_november: 5.316,
            ic_december: 4.92,
            ic_yearly: 5.241,
          },
          {
            ic_uuid: "80397885-8867-4d0c-86bb-4f383d804a94",
            ic_lat: -2319,
            ic_lon: -458845,
            ic_city: "São José dos Campos",
            ic_states: "SÃO PAULO",
            ic_january: 5.391,
            ic_february: 5.655,
            ic_march: 4.821,
            ic_april: 4.271,
            ic_may: 3.431,
            ic_june: 3.215,
            ic_july: 3.344,
            ic_august: 4.299,
            ic_september: 4.388,
            ic_october: 4.993,
            ic_november: 5.23,
            ic_december: 5.797,
            ic_yearly: 4.57,
          },
          {
            ic_uuid: "b0a8355c-d2bf-4cc2-8fed-6a2e89cc2dbe",
            ic_lat: -73914,
            ic_lon: -368078,
            ic_city: "São José dos Cordeiros",
            ic_states: "PARAÍBA",
            ic_january: 6.019,
            ic_february: 6.044,
            ic_march: 6.01,
            ic_april: 5.598,
            ic_may: 4.895,
            ic_june: 4.46,
            ic_july: 4.673,
            ic_august: 5.388,
            ic_september: 6.109,
            ic_october: 6.331,
            ic_november: 6.461,
            ic_december: 6.175,
            ic_yearly: 5.68,
          },
          {
            ic_uuid: "a140bea7-972f-42dd-b82a-4655a69be447",
            ic_lat: -255318,
            ic_lon: -492035,
            ic_city: "São José dos Pinhais",
            ic_states: "PARANÁ",
            ic_january: 5.342,
            ic_february: 5.151,
            ic_march: 4.497,
            ic_april: 3.793,
            ic_may: 3.062,
            ic_june: 2.748,
            ic_july: 2.895,
            ic_august: 3.852,
            ic_september: 3.84,
            ic_october: 4.408,
            ic_november: 5.264,
            ic_december: 5.557,
            ic_yearly: 4.201,
          },
          {
            ic_uuid: "368f5897-510c-4b8c-a5fe-66a31ad3eed3",
            ic_lat: -15628,
            ic_lon: -581777,
            ic_city: "São José dos Quatro Marcos",
            ic_states: "MATO GROSSO",
            ic_january: 5.37,
            ic_february: 5.214,
            ic_march: 4.987,
            ic_april: 4.741,
            ic_may: 4.119,
            ic_june: 4.206,
            ic_july: 4.289,
            ic_august: 5.046,
            ic_september: 5.052,
            ic_october: 5.227,
            ic_november: 5.416,
            ic_december: 5.536,
            ic_yearly: 4.934,
          },
          {
            ic_uuid: "40b77e0d-db29-47d3-ab89-0a1b1e33a2d8",
            ic_lat: -72478,
            ic_lon: -353782,
            ic_city: "São José dos Ramos",
            ic_states: "PARAÍBA",
            ic_january: 5.644,
            ic_february: 5.799,
            ic_march: 5.85,
            ic_april: 5.332,
            ic_may: 4.639,
            ic_june: 4.219,
            ic_july: 4.348,
            ic_august: 5.087,
            ic_september: 5.515,
            ic_october: 5.713,
            ic_november: 5.963,
            ic_december: 5.823,
            ic_yearly: 5.327,
          },
          {
            ic_uuid: "35bc43b5-b4d9-43a7-bc7c-19d59cf159f4",
            ic_lat: -70822,
            ic_lon: -408365,
            ic_city: "São Julião",
            ic_states: "PIAUÍ",
            ic_january: 5.579,
            ic_february: 5.519,
            ic_march: 5.584,
            ic_april: 5.297,
            ic_may: 5.098,
            ic_june: 5.04,
            ic_july: 5.5,
            ic_august: 6.252,
            ic_september: 6.692,
            ic_october: 6.491,
            ic_november: 6.521,
            ic_december: 6.066,
            ic_yearly: 5.803,
          },
          {
            ic_uuid: "5eb6ba91-1193-4690-8bf4-b1c8295f70fd",
            ic_lat: -29755,
            ic_lon: -511502,
            ic_city: "São Leopoldo",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.314,
            ic_february: 5.783,
            ic_march: 4.8,
            ic_april: 3.804,
            ic_may: 2.792,
            ic_june: 2.335,
            ic_july: 2.517,
            ic_august: 3.207,
            ic_september: 3.56,
            ic_october: 4.682,
            ic_november: 6.142,
            ic_december: 6.616,
            ic_yearly: 4.379,
          },
          {
            ic_uuid: "0433b719-2419-427f-bf09-2722694c188f",
            ic_lat: -221171,
            ic_lon: -45051,
            ic_city: "São Lourenço",
            ic_states: "MINAS GERAIS",
            ic_january: 5.434,
            ic_february: 5.759,
            ic_march: 4.957,
            ic_april: 4.609,
            ic_may: 3.914,
            ic_june: 3.634,
            ic_july: 3.844,
            ic_august: 4.725,
            ic_september: 5.025,
            ic_october: 5.437,
            ic_november: 5.37,
            ic_december: 5.684,
            ic_yearly: 4.866,
          },
          {
            ic_uuid: "b59c90da-64b5-4f5e-8496-597922d7da12",
            ic_lat: -80073,
            ic_lon: -350126,
            ic_city: "São Lourenço da Mata",
            ic_states: "PERNAMBUCO",
            ic_january: 5.802,
            ic_february: 5.911,
            ic_march: 5.876,
            ic_april: 5.159,
            ic_may: 4.429,
            ic_june: 4.137,
            ic_july: 4.25,
            ic_august: 4.945,
            ic_september: 5.48,
            ic_october: 5.754,
            ic_november: 6.024,
            ic_december: 6.05,
            ic_yearly: 5.318,
          },
          {
            ic_uuid: "9d924b19-f7fc-4189-b9aa-11faec4dbf17",
            ic_lat: -238496,
            ic_lon: -469436,
            ic_city: "São Lourenço da Serra",
            ic_states: "SÃO PAULO",
            ic_january: 5.028,
            ic_february: 5.3,
            ic_march: 4.493,
            ic_april: 4.051,
            ic_may: 3.292,
            ic_june: 3.089,
            ic_july: 3.14,
            ic_august: 4.067,
            ic_september: 4.001,
            ic_october: 4.36,
            ic_november: 4.88,
            ic_december: 5.408,
            ic_yearly: 4.259,
          },
          {
            ic_uuid: "41ae6177-91c1-406e-837f-c77028cf778f",
            ic_lat: -263561,
            ic_lon: -528503,
            ic_city: "São Lourenço do Oeste",
            ic_states: "SANTA CATARINA",
            ic_january: 6.132,
            ic_february: 5.662,
            ic_march: 5.072,
            ic_april: 4.137,
            ic_may: 3.228,
            ic_june: 2.769,
            ic_july: 3.071,
            ic_august: 3.987,
            ic_september: 4.196,
            ic_october: 5.11,
            ic_november: 6.045,
            ic_december: 6.268,
            ic_yearly: 4.64,
          },
          {
            ic_uuid: "1a22f043-9ae2-41aa-be86-5aea5ef5aed0",
            ic_lat: -91688,
            ic_lon: -425474,
            ic_city: "São Lourenço do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 6.001,
            ic_february: 5.772,
            ic_march: 5.699,
            ic_april: 5.429,
            ic_may: 5.232,
            ic_june: 5.207,
            ic_july: 5.561,
            ic_august: 6.357,
            ic_september: 6.635,
            ic_october: 6.523,
            ic_november: 6.292,
            ic_december: 6.107,
            ic_yearly: 5.901,
          },
          {
            ic_uuid: "0547cdb2-044a-4430-8ae5-e039ce4b15de",
            ic_lat: -31361,
            ic_lon: -519736,
            ic_city: "São Lourenço do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.471,
            ic_february: 5.801,
            ic_march: 4.877,
            ic_april: 3.696,
            ic_may: 2.696,
            ic_june: 2.263,
            ic_july: 2.416,
            ic_august: 3.038,
            ic_september: 3.625,
            ic_october: 4.855,
            ic_november: 6.257,
            ic_december: 6.778,
            ic_yearly: 4.398,
          },
          {
            ic_uuid: "3da994b3-7627-43e9-a8a1-7a5d15829e85",
            ic_lat: -283149,
            ic_lon: -491811,
            ic_city: "São Ludgero",
            ic_states: "SANTA CATARINA",
            ic_january: 5.701,
            ic_february: 5.239,
            ic_march: 4.522,
            ic_april: 3.797,
            ic_may: 2.973,
            ic_june: 2.528,
            ic_july: 2.735,
            ic_august: 3.501,
            ic_september: 3.607,
            ic_october: 4.38,
            ic_november: 5.649,
            ic_december: 5.976,
            ic_yearly: 4.217,
          },
          {
            ic_uuid: "dcc72b8b-f00b-4790-b20e-750fc836014e",
            ic_lat: -25395,
            ic_lon: -442832,
            ic_city: "São Luís",
            ic_states: "MARANHÃO",
            ic_january: 4.958,
            ic_february: 4.989,
            ic_march: 4.787,
            ic_april: 4.648,
            ic_may: 4.687,
            ic_june: 4.844,
            ic_july: 5.078,
            ic_august: 5.659,
            ic_september: 5.999,
            ic_october: 5.741,
            ic_november: 5.635,
            ic_december: 5.48,
            ic_yearly: 5.208,
          },
          {
            ic_uuid: "1000dc1b-d5b7-4baa-a80b-1e6b096afe82",
            ic_lat: -165215,
            ic_lon: -50373,
            ic_city: "São Luís de Montes Belos",
            ic_states: "GOIÁS",
            ic_january: 5.619,
            ic_february: 5.637,
            ic_march: 5.276,
            ic_april: 5.206,
            ic_may: 4.856,
            ic_june: 4.538,
            ic_july: 4.76,
            ic_august: 5.556,
            ic_september: 5.446,
            ic_october: 5.576,
            ic_november: 5.596,
            ic_december: 5.562,
            ic_yearly: 5.302,
          },
          {
            ic_uuid: "f969e4d4-a6ea-4524-b12c-63b9d51b04fc",
            ic_lat: -36702,
            ic_lon: -392394,
            ic_city: "São Luís do Curu",
            ic_states: "CEARÁ",
            ic_january: 5.28,
            ic_february: 5.346,
            ic_march: 5.214,
            ic_april: 4.828,
            ic_may: 5.197,
            ic_june: 5.06,
            ic_july: 5.357,
            ic_august: 6.076,
            ic_september: 6.358,
            ic_october: 6.266,
            ic_november: 6.138,
            ic_december: 5.526,
            ic_yearly: 5.554,
          },
          {
            ic_uuid: "3eb3c6f2-ea7d-480e-821d-2eb32924ce68",
            ic_lat: -68272,
            ic_lon: -413237,
            ic_city: "São Luis do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.425,
            ic_february: 5.323,
            ic_march: 5.376,
            ic_april: 5.25,
            ic_may: 5.18,
            ic_june: 5.158,
            ic_july: 5.56,
            ic_august: 6.291,
            ic_september: 6.735,
            ic_october: 6.472,
            ic_november: 6.228,
            ic_december: 5.788,
            ic_yearly: 5.732,
          },
          {
            ic_uuid: "ef76fedc-eac4-4a6c-a426-ee7d430fe072",
            ic_lat: -93186,
            ic_lon: -355608,
            ic_city: "São Luís do Quitunde",
            ic_states: "ALAGOAS",
            ic_january: 5.987,
            ic_february: 5.922,
            ic_march: 5.988,
            ic_april: 5.115,
            ic_may: 4.336,
            ic_june: 4.08,
            ic_july: 4.107,
            ic_august: 4.796,
            ic_september: 5.469,
            ic_october: 5.737,
            ic_november: 6.19,
            ic_december: 6.232,
            ic_yearly: 5.33,
          },
          {
            ic_uuid: "129ffc75-3ad4-41b2-82f5-f51d68ee4948",
            ic_lat: -43772,
            ic_lon: -44671,
            ic_city: "São Luís Gonzaga do Maranhão",
            ic_states: "MARANHÃO",
            ic_january: 4.673,
            ic_february: 4.805,
            ic_march: 4.893,
            ic_april: 4.863,
            ic_may: 4.839,
            ic_june: 5.044,
            ic_july: 5.325,
            ic_august: 5.843,
            ic_september: 6.189,
            ic_october: 5.757,
            ic_november: 5.348,
            ic_december: 5.013,
            ic_yearly: 5.216,
          },
          {
            ic_uuid: "fe6dce33-03e8-4d73-ad20-097bfeb86038",
            ic_lat: 10169,
            ic_lon: -600387,
            ic_city: "São Luiz",
            ic_states: "RORAIMA",
            ic_january: 4.455,
            ic_february: 4.523,
            ic_march: 4.619,
            ic_april: 4.409,
            ic_may: 4.155,
            ic_june: 4.416,
            ic_july: 4.47,
            ic_august: 5.048,
            ic_september: 5.351,
            ic_october: 5.336,
            ic_november: 5.096,
            ic_december: 4.674,
            ic_yearly: 4.713,
          },
          {
            ic_uuid: "e3d29908-c60b-4b30-9a34-70f511860b46",
            ic_lat: -14863,
            ic_lon: -493305,
            ic_city: "São Luiz do Norte",
            ic_states: "GOIÁS",
            ic_january: 5.375,
            ic_february: 5.664,
            ic_march: 5.253,
            ic_april: 5.158,
            ic_may: 4.935,
            ic_june: 4.607,
            ic_july: 4.832,
            ic_august: 5.688,
            ic_september: 5.577,
            ic_october: 5.567,
            ic_november: 5.242,
            ic_december: 5.393,
            ic_yearly: 5.274,
          },
          {
            ic_uuid: "9cde5d1e-98a0-47c6-afe9-ae4008565945",
            ic_lat: -23221,
            ic_lon: -453094,
            ic_city: "São Luíz do Paraitinga",
            ic_states: "SÃO PAULO",
            ic_january: 5.717,
            ic_february: 5.84,
            ic_march: 4.92,
            ic_april: 4.423,
            ic_may: 3.585,
            ic_june: 3.383,
            ic_july: 3.47,
            ic_august: 4.397,
            ic_september: 4.495,
            ic_october: 5.108,
            ic_november: 5.306,
            ic_december: 5.923,
            ic_yearly: 4.714,
          },
          {
            ic_uuid: "df157359-d0b7-4f39-875e-310e3fcabe7d",
            ic_lat: -28408,
            ic_lon: -549609,
            ic_city: "São Luiz Gonzaga",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.572,
            ic_february: 6.119,
            ic_march: 5.238,
            ic_april: 4.043,
            ic_may: 3.088,
            ic_june: 2.517,
            ic_july: 2.836,
            ic_august: 3.593,
            ic_september: 4.063,
            ic_october: 5.158,
            ic_november: 6.381,
            ic_december: 6.806,
            ic_yearly: 4.701,
          },
          {
            ic_uuid: "013883dd-3207-48d2-8320-1bce77ede518",
            ic_lat: -69243,
            ic_lon: -370957,
            ic_city: "São Mamede",
            ic_states: "PARAÍBA",
            ic_january: 6.124,
            ic_february: 6.164,
            ic_march: 6.189,
            ic_april: 5.8,
            ic_may: 5.17,
            ic_june: 4.651,
            ic_july: 5.015,
            ic_august: 5.796,
            ic_september: 6.363,
            ic_october: 6.563,
            ic_november: 6.652,
            ic_december: 6.247,
            ic_yearly: 5.895,
          },
          {
            ic_uuid: "542a2a84-d9c5-4801-8348-5b2fb6b0c339",
            ic_lat: -233946,
            ic_lon: -526458,
            ic_city: "São Manoel do Paraná",
            ic_states: "PARANÁ",
            ic_january: 6.038,
            ic_february: 5.866,
            ic_march: 5.37,
            ic_april: 4.568,
            ic_may: 3.602,
            ic_june: 3.254,
            ic_july: 3.469,
            ic_august: 4.408,
            ic_september: 4.646,
            ic_october: 5.399,
            ic_november: 6.117,
            ic_december: 6.472,
            ic_yearly: 4.934,
          },
          {
            ic_uuid: "8ecbce21-523b-4179-a057-9ae2d72c0c81",
            ic_lat: -227326,
            ic_lon: -485727,
            ic_city: "São Manuel",
            ic_states: "SÃO PAULO",
            ic_january: 5.618,
            ic_february: 5.916,
            ic_march: 5.253,
            ic_april: 4.684,
            ic_may: 3.82,
            ic_june: 3.55,
            ic_july: 3.715,
            ic_august: 4.699,
            ic_september: 4.826,
            ic_october: 5.497,
            ic_november: 5.929,
            ic_december: 6.259,
            ic_yearly: 4.981,
          },
          {
            ic_uuid: "b85a6a77-16a6-4ec2-a0cc-9b0728c35590",
            ic_lat: -289681,
            ic_lon: -5107,
            ic_city: "São Marcos",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.181,
            ic_february: 5.71,
            ic_march: 4.793,
            ic_april: 3.857,
            ic_may: 2.868,
            ic_june: 2.411,
            ic_july: 2.742,
            ic_august: 3.445,
            ic_september: 3.708,
            ic_october: 4.839,
            ic_november: 6.185,
            ic_december: 6.467,
            ic_yearly: 4.434,
          },
          {
            ic_uuid: "f879c96a-0e2a-4641-be90-f3e70f0a668b",
            ic_lat: -277116,
            ic_lon: -539704,
            ic_city: "São Martinho",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.465,
            ic_february: 5.982,
            ic_march: 5.116,
            ic_april: 4.079,
            ic_may: 3.114,
            ic_june: 2.589,
            ic_july: 2.898,
            ic_august: 3.685,
            ic_september: 3.978,
            ic_october: 5.182,
            ic_november: 6.201,
            ic_december: 6.667,
            ic_yearly: 4.663,
          },
          {
            ic_uuid: "8f9a0364-250a-4dbd-a086-70b6d28a4247",
            ic_lat: -281614,
            ic_lon: -489872,
            ic_city: "São Martinho",
            ic_states: "SANTA CATARINA",
            ic_january: 5.815,
            ic_february: 5.346,
            ic_march: 4.579,
            ic_april: 3.82,
            ic_may: 3.001,
            ic_june: 2.568,
            ic_july: 2.77,
            ic_august: 3.506,
            ic_september: 3.632,
            ic_october: 4.431,
            ic_november: 5.712,
            ic_december: 6.051,
            ic_yearly: 4.269,
          },
          {
            ic_uuid: "9c8773a8-695f-48cc-a6e6-219a8dd4bb5c",
            ic_lat: -29538,
            ic_lon: -538549,
            ic_city: "São Martinho da Serra",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.436,
            ic_february: 5.957,
            ic_march: 5.018,
            ic_april: 3.921,
            ic_may: 2.921,
            ic_june: 2.419,
            ic_july: 2.687,
            ic_august: 3.414,
            ic_september: 3.871,
            ic_october: 5.004,
            ic_november: 6.283,
            ic_december: 6.766,
            ic_yearly: 4.558,
          },
          {
            ic_uuid: "f1d9ce31-b8f8-4d70-bc36-f355d5fdf0da",
            ic_lat: -187219,
            ic_lon: -398583,
            ic_city: "São Mateus",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.273,
            ic_february: 6.392,
            ic_march: 5.507,
            ic_april: 4.55,
            ic_may: 3.925,
            ic_june: 3.579,
            ic_july: 3.719,
            ic_august: 4.419,
            ic_september: 5.009,
            ic_october: 5.231,
            ic_november: 5.257,
            ic_december: 6.098,
            ic_yearly: 4.997,
          },
          {
            ic_uuid: "dc20f09d-ebd8-4a6b-9047-8b5b59ddda78",
            ic_lat: -40378,
            ic_lon: -44471,
            ic_city: "São Mateus do Maranhão",
            ic_states: "MARANHÃO",
            ic_january: 4.904,
            ic_february: 4.959,
            ic_march: 5.067,
            ic_april: 4.971,
            ic_may: 4.934,
            ic_june: 5.095,
            ic_july: 5.364,
            ic_august: 5.798,
            ic_september: 6.156,
            ic_october: 5.842,
            ic_november: 5.454,
            ic_december: 5.173,
            ic_yearly: 5.31,
          },
          {
            ic_uuid: "da3bc115-0993-4b79-bec7-935903c167f3",
            ic_lat: -258681,
            ic_lon: -503844,
            ic_city: "São Mateus do Sul",
            ic_states: "PARANÁ",
            ic_january: 5.398,
            ic_february: 5.074,
            ic_march: 4.517,
            ic_april: 3.579,
            ic_may: 2.901,
            ic_june: 2.517,
            ic_july: 2.757,
            ic_august: 3.748,
            ic_september: 3.863,
            ic_october: 4.474,
            ic_november: 5.387,
            ic_december: 5.558,
            ic_yearly: 4.148,
          },
          {
            ic_uuid: "12ba4724-6203-4c45-afa1-d612b2ca55b8",
            ic_lat: -62032,
            ic_lon: -38495,
            ic_city: "São Miguel",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.868,
            ic_february: 5.872,
            ic_march: 5.833,
            ic_april: 5.553,
            ic_may: 5.168,
            ic_june: 5.034,
            ic_july: 5.366,
            ic_august: 6.103,
            ic_september: 6.474,
            ic_october: 6.574,
            ic_november: 6.665,
            ic_december: 6.1,
            ic_yearly: 5.884,
          },
          {
            ic_uuid: "3bc33cd8-aed9-4723-af07-ac516471e9f3",
            ic_lat: -238787,
            ic_lon: -47994,
            ic_city: "São Miguel Arcanjo",
            ic_states: "SÃO PAULO",
            ic_january: 5.407,
            ic_february: 5.529,
            ic_march: 4.765,
            ic_april: 4.241,
            ic_may: 3.393,
            ic_june: 3.148,
            ic_july: 3.226,
            ic_august: 4.145,
            ic_september: 4.223,
            ic_october: 4.751,
            ic_november: 5.424,
            ic_december: 5.907,
            ic_yearly: 4.513,
          },
          {
            ic_uuid: "e275cd0e-f67f-4100-b88c-83125d134d45",
            ic_lat: -58643,
            ic_lon: -421856,
            ic_city: "São Miguel da Baixa Grande",
            ic_states: "PIAUÍ",
            ic_january: 5.241,
            ic_february: 5.339,
            ic_march: 5.36,
            ic_april: 5.253,
            ic_may: 5.269,
            ic_june: 5.284,
            ic_july: 5.562,
            ic_august: 6.177,
            ic_september: 6.549,
            ic_october: 6.377,
            ic_november: 6.221,
            ic_december: 5.736,
            ic_yearly: 5.697,
          },
          {
            ic_uuid: "8243bb18-e7ff-44f4-8dce-cebb9ace5b97",
            ic_lat: -266874,
            ic_lon: -532516,
            ic_city: "São Miguel da Boa Vista",
            ic_states: "SANTA CATARINA",
            ic_january: 6.287,
            ic_february: 5.786,
            ic_march: 5.14,
            ic_april: 4.14,
            ic_may: 3.178,
            ic_june: 2.706,
            ic_july: 2.966,
            ic_august: 3.853,
            ic_september: 4.112,
            ic_october: 5.12,
            ic_november: 6.09,
            ic_december: 6.413,
            ic_yearly: 4.649,
          },
          {
            ic_uuid: "48511190-baca-4fd2-a069-e103e2352258",
            ic_lat: -130438,
            ic_lon: -394582,
            ic_city: "São Miguel das Matas",
            ic_states: "BAHIA",
            ic_january: 5.822,
            ic_february: 5.825,
            ic_march: 5.587,
            ic_april: 4.524,
            ic_may: 3.931,
            ic_june: 3.531,
            ic_july: 3.735,
            ic_august: 4.262,
            ic_september: 5.026,
            ic_october: 5.181,
            ic_november: 5.44,
            ic_december: 5.914,
            ic_yearly: 4.898,
          },
          {
            ic_uuid: "873cd2d8-b7ce-43c2-bb3d-4e8035c1336b",
            ic_lat: -285565,
            ic_lon: -545563,
            ic_city: "São Miguel das Missões",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.53,
            ic_february: 6.042,
            ic_march: 5.211,
            ic_april: 4.029,
            ic_may: 3.049,
            ic_june: 2.501,
            ic_july: 2.795,
            ic_august: 3.58,
            ic_september: 4.013,
            ic_october: 5.142,
            ic_november: 6.373,
            ic_december: 6.757,
            ic_yearly: 4.669,
          },
          {
            ic_uuid: "11be482f-a3d4-4fc3-a458-2e91394dc51a",
            ic_lat: -72481,
            ic_lon: -352019,
            ic_city: "São Miguel de Taipu",
            ic_states: "PARAÍBA",
            ic_january: 5.652,
            ic_february: 5.782,
            ic_march: 5.891,
            ic_april: 5.305,
            ic_may: 4.632,
            ic_june: 4.245,
            ic_july: 4.339,
            ic_august: 5.116,
            ic_september: 5.542,
            ic_october: 5.763,
            ic_november: 5.985,
            ic_december: 5.881,
            ic_yearly: 5.344,
          },
          {
            ic_uuid: "8ca204eb-642a-4ea5-b7f4-3292590916f6",
            ic_lat: -103887,
            ic_lon: -373825,
            ic_city: "São Miguel do Aleixo",
            ic_states: "SERGIPE",
            ic_january: 6.017,
            ic_february: 5.918,
            ic_march: 5.75,
            ic_april: 5.046,
            ic_may: 4.244,
            ic_june: 4.03,
            ic_july: 4.093,
            ic_august: 4.691,
            ic_september: 5.397,
            ic_october: 5.642,
            ic_november: 6.157,
            ic_december: 6.103,
            ic_yearly: 5.257,
          },
          {
            ic_uuid: "de800380-ff23-4aae-b688-d11c8fe2e653",
            ic_lat: -207071,
            ic_lon: -427178,
            ic_city: "São Miguel do Anta",
            ic_states: "MINAS GERAIS",
            ic_january: 5.639,
            ic_february: 5.944,
            ic_march: 4.954,
            ic_april: 4.402,
            ic_may: 3.777,
            ic_june: 3.573,
            ic_july: 3.773,
            ic_august: 4.605,
            ic_september: 4.884,
            ic_october: 5.119,
            ic_november: 4.835,
            ic_december: 5.523,
            ic_yearly: 4.752,
          },
          {
            ic_uuid: "c2b16a88-ab71-4fbf-880d-54542d0d2bc2",
            ic_lat: -132735,
            ic_lon: -501638,
            ic_city: "São Miguel do Araguaia",
            ic_states: "GOIÁS",
            ic_january: 5.33,
            ic_february: 5.403,
            ic_march: 5.12,
            ic_april: 5.059,
            ic_may: 4.947,
            ic_june: 4.815,
            ic_july: 5.134,
            ic_august: 5.669,
            ic_september: 5.642,
            ic_october: 5.44,
            ic_november: 5.163,
            ic_december: 5.364,
            ic_yearly: 5.257,
          },
          {
            ic_uuid: "96b475d7-153d-48f9-b8bd-6bb41e12655a",
            ic_lat: -75976,
            ic_lon: -423679,
            ic_city: "São Miguel do Fidalgo",
            ic_states: "PIAUÍ",
            ic_january: 5.655,
            ic_february: 5.516,
            ic_march: 5.509,
            ic_april: 5.435,
            ic_may: 5.297,
            ic_june: 5.26,
            ic_july: 5.535,
            ic_august: 6.264,
            ic_september: 6.58,
            ic_october: 6.5,
            ic_november: 6.13,
            ic_december: 5.852,
            ic_yearly: 5.794,
          },
          {
            ic_uuid: "db97a0d3-d66e-4565-ad21-ef8aa13cab30",
            ic_lat: -51263,
            ic_lon: -356417,
            ic_city: "São Miguel do Gostoso",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.244,
            ic_february: 6.165,
            ic_march: 6.175,
            ic_april: 5.614,
            ic_may: 5.295,
            ic_june: 4.832,
            ic_july: 5.0,
            ic_august: 5.757,
            ic_september: 6.186,
            ic_october: 6.315,
            ic_november: 6.516,
            ic_december: 6.23,
            ic_yearly: 5.861,
          },
          {
            ic_uuid: "33d5648c-4470-4f74-8c83-8e54470c912d",
            ic_lat: -16135,
            ic_lon: -474788,
            ic_city: "São Miguel do Guamá",
            ic_states: "PARÁ",
            ic_january: 4.56,
            ic_february: 4.404,
            ic_march: 4.384,
            ic_april: 4.492,
            ic_may: 4.66,
            ic_june: 5.0,
            ic_july: 5.092,
            ic_august: 5.41,
            ic_september: 5.526,
            ic_october: 5.249,
            ic_november: 5.069,
            ic_december: 4.659,
            ic_yearly: 4.875,
          },
          {
            ic_uuid: "b021248a-bf4d-436f-87d9-50c2aa13fea2",
            ic_lat: -116957,
            ic_lon: -627197,
            ic_city: "São Miguel do Guaporé",
            ic_states: "RONDÔNIA",
            ic_january: 4.455,
            ic_february: 4.454,
            ic_march: 4.445,
            ic_april: 4.474,
            ic_may: 3.962,
            ic_june: 4.277,
            ic_july: 4.474,
            ic_august: 4.924,
            ic_september: 5.025,
            ic_october: 5.115,
            ic_november: 4.983,
            ic_december: 4.746,
            ic_yearly: 4.611,
          },
          {
            ic_uuid: "05640319-331f-4021-872d-a07c6b12d0d4",
            ic_lat: -253496,
            ic_lon: -54241,
            ic_city: "São Miguel do Iguaçu",
            ic_states: "PARANÁ",
            ic_january: 6.369,
            ic_february: 5.804,
            ic_march: 5.311,
            ic_april: 4.247,
            ic_may: 3.276,
            ic_june: 2.839,
            ic_july: 3.054,
            ic_august: 4.024,
            ic_september: 4.305,
            ic_october: 5.251,
            ic_november: 6.136,
            ic_december: 6.635,
            ic_yearly: 4.771,
          },
          {
            ic_uuid: "6dbea9e6-6913-4920-b402-7a46190f1db0",
            ic_lat: -267247,
            ic_lon: -535167,
            ic_city: "São Miguel do Oeste",
            ic_states: "SANTA CATARINA",
            ic_january: 6.349,
            ic_february: 5.759,
            ic_march: 5.085,
            ic_april: 4.107,
            ic_may: 3.174,
            ic_june: 2.719,
            ic_july: 2.998,
            ic_august: 3.85,
            ic_september: 4.126,
            ic_october: 5.152,
            ic_november: 6.04,
            ic_december: 6.447,
            ic_yearly: 4.65,
          },
          {
            ic_uuid: "cb1a4286-6612-454d-b96d-7208e5df2c35",
            ic_lat: -170564,
            ic_lon: -486634,
            ic_city: "São Miguel do Passa Quatro",
            ic_states: "GOIÁS",
            ic_january: 5.619,
            ic_february: 5.771,
            ic_march: 5.177,
            ic_april: 5.139,
            ic_may: 4.79,
            ic_june: 4.563,
            ic_july: 4.792,
            ic_august: 5.682,
            ic_september: 5.531,
            ic_october: 5.641,
            ic_november: 5.29,
            ic_december: 5.606,
            ic_yearly: 5.3,
          },
          {
            ic_uuid: "9189cd12-f81f-476e-98f5-a1c8ab54d4ab",
            ic_lat: -54977,
            ic_lon: -413168,
            ic_city: "São Miguel do Tapuio",
            ic_states: "PIAUÍ",
            ic_january: 5.182,
            ic_february: 5.321,
            ic_march: 5.384,
            ic_april: 5.12,
            ic_may: 5.081,
            ic_june: 5.107,
            ic_july: 5.426,
            ic_august: 6.209,
            ic_september: 6.664,
            ic_october: 6.55,
            ic_november: 6.377,
            ic_december: 5.783,
            ic_yearly: 5.684,
          },
          {
            ic_uuid: "12a7faf9-954f-4bb4-92b9-9b896d1aa742",
            ic_lat: -55521,
            ic_lon: -475774,
            ic_city: "São Miguel do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 4.624,
            ic_february: 4.754,
            ic_march: 4.837,
            ic_april: 4.837,
            ic_may: 4.835,
            ic_june: 5.127,
            ic_july: 5.253,
            ic_august: 5.713,
            ic_september: 5.58,
            ic_october: 5.168,
            ic_november: 4.91,
            ic_december: 4.748,
            ic_yearly: 5.032,
          },
          {
            ic_uuid: "f63cd8c9-5bca-4c29-b87a-ed27622689c5",
            ic_lat: -97834,
            ic_lon: -360974,
            ic_city: "São Miguel dos Campos",
            ic_states: "ALAGOAS",
            ic_january: 6.011,
            ic_february: 5.976,
            ic_march: 5.945,
            ic_april: 5.026,
            ic_may: 4.323,
            ic_june: 3.963,
            ic_july: 4.05,
            ic_august: 4.611,
            ic_september: 5.438,
            ic_october: 5.844,
            ic_november: 6.326,
            ic_december: 6.311,
            ic_yearly: 5.319,
          },
          {
            ic_uuid: "9d7d0f56-c88a-4852-838c-644eb70b7823",
            ic_lat: -92654,
            ic_lon: -353765,
            ic_city: "São Miguel dos Milagres",
            ic_states: "ALAGOAS",
            ic_january: 6.273,
            ic_february: 6.118,
            ic_march: 6.238,
            ic_april: 5.333,
            ic_may: 4.511,
            ic_june: 4.196,
            ic_july: 4.346,
            ic_august: 5.092,
            ic_september: 5.819,
            ic_october: 6.133,
            ic_november: 6.525,
            ic_december: 6.557,
            ic_yearly: 5.595,
          },
          {
            ic_uuid: "fd304e43-6633-4e11-85dc-4bb82bb30aef",
            ic_lat: -281838,
            ic_lon: -552659,
            ic_city: "São Nicolau",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.613,
            ic_february: 6.123,
            ic_march: 5.269,
            ic_april: 4.055,
            ic_may: 3.097,
            ic_june: 2.542,
            ic_july: 2.856,
            ic_august: 3.614,
            ic_september: 4.098,
            ic_october: 5.197,
            ic_november: 6.403,
            ic_december: 6.813,
            ic_yearly: 4.723,
          },
          {
            ic_uuid: "5dd1e1aa-7e1e-408c-ba31-5affd5986dc0",
            ic_lat: -153504,
            ic_lon: -498184,
            ic_city: "São Patrício",
            ic_states: "GOIÁS",
            ic_january: 5.246,
            ic_february: 5.448,
            ic_march: 5.083,
            ic_april: 5.068,
            ic_may: 4.938,
            ic_june: 4.633,
            ic_july: 4.788,
            ic_august: 5.734,
            ic_september: 5.462,
            ic_october: 5.475,
            ic_november: 5.161,
            ic_december: 5.256,
            ic_yearly: 5.191,
          },
          {
            ic_uuid: "3f1d1c18-cc58-436a-8718-aeacd7bd5645",
            ic_lat: -235339,
            ic_lon: -466403,
            ic_city: "São Paulo",
            ic_states: "SÃO PAULO",
            ic_january: 5.222,
            ic_february: 5.479,
            ic_march: 4.704,
            ic_april: 4.142,
            ic_may: 3.422,
            ic_june: 3.172,
            ic_july: 3.244,
            ic_august: 4.196,
            ic_september: 4.237,
            ic_october: 4.762,
            ic_november: 5.135,
            ic_december: 5.691,
            ic_yearly: 4.45,
          },
          {
            ic_uuid: "4a00f860-8171-4861-9e35-45b6731e506f",
            ic_lat: -28021,
            ic_lon: -549359,
            ic_city: "São Paulo das Missões",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.569,
            ic_february: 6.071,
            ic_march: 5.208,
            ic_april: 4.069,
            ic_may: 3.093,
            ic_june: 2.535,
            ic_july: 2.851,
            ic_august: 3.63,
            ic_september: 4.046,
            ic_october: 5.151,
            ic_november: 6.354,
            ic_december: 6.756,
            ic_yearly: 4.695,
          },
          {
            ic_uuid: "29cf8b5f-eb6b-4021-8571-ad88c9f381a4",
            ic_lat: -34619,
            ic_lon: -68951,
            ic_city: "São Paulo de Olivença",
            ic_states: "AMAZONAS",
            ic_january: 4.249,
            ic_february: 4.659,
            ic_march: 4.546,
            ic_april: 4.383,
            ic_may: 4.035,
            ic_june: 4.145,
            ic_july: 4.061,
            ic_august: 4.821,
            ic_september: 5.049,
            ic_october: 4.995,
            ic_november: 4.826,
            ic_december: 4.455,
            ic_yearly: 4.519,
          },
          {
            ic_uuid: "19fbe982-7ffa-4787-8dc3-a83206f582da",
            ic_lat: -58998,
            ic_lon: -357645,
            ic_city: "São Paulo do Potengi",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.799,
            ic_february: 5.817,
            ic_march: 5.827,
            ic_april: 5.455,
            ic_may: 4.978,
            ic_june: 4.523,
            ic_july: 4.762,
            ic_august: 5.381,
            ic_september: 5.78,
            ic_october: 5.964,
            ic_november: 6.139,
            ic_december: 5.816,
            ic_yearly: 5.52,
          },
          {
            ic_uuid: "0a4c8b94-de14-440e-ac29-d84bd65f6842",
            ic_lat: -58962,
            ic_lon: -356349,
            ic_city: "São Pedro",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.835,
            ic_february: 5.863,
            ic_march: 5.867,
            ic_april: 5.485,
            ic_may: 4.968,
            ic_june: 4.535,
            ic_july: 4.699,
            ic_august: 5.39,
            ic_september: 5.796,
            ic_october: 5.989,
            ic_november: 6.175,
            ic_december: 5.842,
            ic_yearly: 5.537,
          },
          {
            ic_uuid: "977b6d46-4305-45a1-961f-fadb9f434e13",
            ic_lat: -225487,
            ic_lon: -4791,
            ic_city: "São Pedro",
            ic_states: "SÃO PAULO",
            ic_january: 5.351,
            ic_february: 5.599,
            ic_march: 4.963,
            ic_april: 4.61,
            ic_may: 3.807,
            ic_june: 3.535,
            ic_july: 3.738,
            ic_august: 4.735,
            ic_september: 4.834,
            ic_october: 5.364,
            ic_november: 5.627,
            ic_december: 5.893,
            ic_yearly: 4.838,
          },
          {
            ic_uuid: "2875e9f5-cfb2-4434-88c0-c55818d672b9",
            ic_lat: -50851,
            ic_lon: -484295,
            ic_city: "São Pedro da Água Branca",
            ic_states: "MARANHÃO",
            ic_january: 4.403,
            ic_february: 4.564,
            ic_march: 4.645,
            ic_april: 4.726,
            ic_may: 4.7,
            ic_june: 5.07,
            ic_july: 5.188,
            ic_august: 5.626,
            ic_september: 5.371,
            ic_october: 4.871,
            ic_november: 4.788,
            ic_december: 4.513,
            ic_yearly: 4.872,
          },
          {
            ic_uuid: "8c6a4dc0-201f-450a-8165-dd498fb42dee",
            ic_lat: -228433,
            ic_lon: -42103,
            ic_city: "São Pedro da Aldeia",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.308,
            ic_february: 6.55,
            ic_march: 5.219,
            ic_april: 4.526,
            ic_may: 3.687,
            ic_june: 3.435,
            ic_july: 3.468,
            ic_august: 4.313,
            ic_september: 4.588,
            ic_october: 5.203,
            ic_november: 5.238,
            ic_december: 6.115,
            ic_yearly: 4.887,
          },
          {
            ic_uuid: "14723737-a66c-4ceb-80bb-746da29791e0",
            ic_lat: -160014,
            ic_lon: -549191,
            ic_city: "São Pedro da Cipa",
            ic_states: "MATO GROSSO",
            ic_january: 5.373,
            ic_february: 5.361,
            ic_march: 5.119,
            ic_april: 5.094,
            ic_may: 4.646,
            ic_june: 4.536,
            ic_july: 4.627,
            ic_august: 5.561,
            ic_september: 5.165,
            ic_october: 5.14,
            ic_november: 5.41,
            ic_december: 5.498,
            ic_yearly: 5.128,
          },
          {
            ic_uuid: "fa030741-8be1-427d-b831-ed4c9b2f13f4",
            ic_lat: -294205,
            ic_lon: -515107,
            ic_city: "São Pedro da Serra",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.143,
            ic_february: 5.64,
            ic_march: 4.72,
            ic_april: 3.782,
            ic_may: 2.8,
            ic_june: 2.36,
            ic_july: 2.61,
            ic_august: 3.29,
            ic_september: 3.556,
            ic_october: 4.636,
            ic_november: 6.042,
            ic_december: 6.429,
            ic_yearly: 4.334,
          },
          {
            ic_uuid: "696933f5-0533-4cd2-8629-d4c8f195808f",
            ic_lat: -211314,
            ic_lon: -466127,
            ic_city: "São Pedro da União",
            ic_states: "MINAS GERAIS",
            ic_january: 5.396,
            ic_february: 5.683,
            ic_march: 4.835,
            ic_april: 4.606,
            ic_may: 4.05,
            ic_june: 3.864,
            ic_july: 4.118,
            ic_august: 5.049,
            ic_september: 5.109,
            ic_october: 5.43,
            ic_november: 5.314,
            ic_december: 5.507,
            ic_yearly: 4.914,
          },
          {
            ic_uuid: "a77ad87a-9e99-496f-aaee-0d790ca34d0a",
            ic_lat: -27771,
            ic_lon: -532518,
            ic_city: "São Pedro das Missões",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.387,
            ic_february: 5.888,
            ic_march: 5.026,
            ic_april: 4.098,
            ic_may: 3.105,
            ic_june: 2.581,
            ic_july: 2.913,
            ic_august: 3.675,
            ic_september: 3.981,
            ic_october: 5.131,
            ic_november: 6.214,
            ic_december: 6.64,
            ic_yearly: 4.636,
          },
          {
            ic_uuid: "02b5ba61-beed-42ee-b660-9612a3e6c1c2",
            ic_lat: -27567,
            ic_lon: -488052,
            ic_city: "São Pedro de Alcântara",
            ic_states: "SANTA CATARINA",
            ic_january: 5.354,
            ic_february: 5.044,
            ic_march: 4.424,
            ic_april: 3.617,
            ic_may: 2.964,
            ic_june: 2.536,
            ic_july: 2.65,
            ic_august: 3.357,
            ic_september: 3.371,
            ic_october: 3.887,
            ic_november: 5.048,
            ic_december: 5.518,
            ic_yearly: 3.981,
          },
          {
            ic_uuid: "f11c5b86-498b-4e0b-9375-d47e84001c2c",
            ic_lat: -28124,
            ic_lon: -548869,
            ic_city: "São Pedro do Butiá",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.604,
            ic_february: 6.094,
            ic_march: 5.206,
            ic_april: 4.076,
            ic_may: 3.096,
            ic_june: 2.531,
            ic_july: 2.848,
            ic_august: 3.612,
            ic_september: 4.038,
            ic_october: 5.139,
            ic_november: 6.355,
            ic_december: 6.758,
            ic_yearly: 4.696,
          },
          {
            ic_uuid: "994fef1c-3e67-46e9-8939-e6cc7dc01cf0",
            ic_lat: -249378,
            ic_lon: -538526,
            ic_city: "São Pedro do Iguaçu",
            ic_states: "PARANÁ",
            ic_january: 6.292,
            ic_february: 5.678,
            ic_march: 5.193,
            ic_april: 4.254,
            ic_may: 3.329,
            ic_june: 2.942,
            ic_july: 3.182,
            ic_august: 4.185,
            ic_september: 4.384,
            ic_october: 5.22,
            ic_november: 6.064,
            ic_december: 6.494,
            ic_yearly: 4.768,
          },
          {
            ic_uuid: "7feb99f7-94d8-4e31-8d1f-afef562058aa",
            ic_lat: -238639,
            ic_lon: -518572,
            ic_city: "São Pedro do Ivaí",
            ic_states: "PARANÁ",
            ic_january: 6.069,
            ic_february: 5.792,
            ic_march: 5.319,
            ic_april: 4.581,
            ic_may: 3.574,
            ic_june: 3.207,
            ic_july: 3.425,
            ic_august: 4.4,
            ic_september: 4.579,
            ic_october: 5.261,
            ic_november: 6.097,
            ic_december: 6.397,
            ic_yearly: 4.892,
          },
          {
            ic_uuid: "fd5de030-bd75-4976-9d17-ab9ef91ddbf7",
            ic_lat: -228243,
            ic_lon: -532225,
            ic_city: "São Pedro do Paraná",
            ic_states: "PARANÁ",
            ic_january: 6.029,
            ic_february: 5.988,
            ic_march: 5.469,
            ic_april: 4.664,
            ic_may: 3.69,
            ic_june: 3.353,
            ic_july: 3.52,
            ic_august: 4.425,
            ic_september: 4.694,
            ic_october: 5.436,
            ic_november: 6.148,
            ic_december: 6.528,
            ic_yearly: 4.995,
          },
          {
            ic_uuid: "1c2964ee-8787-46d7-98ce-7c59548de44b",
            ic_lat: -59212,
            ic_lon: -427196,
            ic_city: "São Pedro do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 4.946,
            ic_february: 5.184,
            ic_march: 5.178,
            ic_april: 5.079,
            ic_may: 5.215,
            ic_june: 5.402,
            ic_july: 5.676,
            ic_august: 6.257,
            ic_september: 6.594,
            ic_october: 6.333,
            ic_november: 5.933,
            ic_december: 5.543,
            ic_yearly: 5.612,
          },
          {
            ic_uuid: "46e66440-0ec3-4810-8656-62a6ead60f98",
            ic_lat: -18366,
            ic_lon: -42603,
            ic_city: "São Pedro do Suaçuí",
            ic_states: "MINAS GERAIS",
            ic_january: 5.882,
            ic_february: 6.152,
            ic_march: 5.191,
            ic_april: 4.599,
            ic_may: 3.909,
            ic_june: 3.743,
            ic_july: 3.841,
            ic_august: 4.557,
            ic_september: 5.049,
            ic_october: 5.271,
            ic_november: 4.891,
            ic_december: 5.717,
            ic_yearly: 4.9,
          },
          {
            ic_uuid: "ce56679d-850a-4b0a-81fd-8ad77a6ac8dc",
            ic_lat: -296209,
            ic_lon: -54179,
            ic_city: "São Pedro do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.495,
            ic_february: 5.985,
            ic_march: 5.045,
            ic_april: 3.916,
            ic_may: 2.864,
            ic_june: 2.374,
            ic_july: 2.637,
            ic_august: 3.376,
            ic_september: 3.841,
            ic_october: 4.993,
            ic_november: 6.31,
            ic_december: 6.855,
            ic_yearly: 4.558,
          },
          {
            ic_uuid: "9fd66299-7fcb-4bf0-bec8-ac342e68c64c",
            ic_lat: -227458,
            ic_lon: -497432,
            ic_city: "São Pedro do Turvo",
            ic_states: "SÃO PAULO",
            ic_january: 5.651,
            ic_february: 5.786,
            ic_march: 5.18,
            ic_april: 4.702,
            ic_may: 3.757,
            ic_june: 3.483,
            ic_july: 3.696,
            ic_august: 4.683,
            ic_september: 4.756,
            ic_october: 5.441,
            ic_november: 5.911,
            ic_december: 6.233,
            ic_yearly: 4.94,
          },
          {
            ic_uuid: "8391a6d3-fd76-4eec-9907-b996237c4f47",
            ic_lat: -68243,
            ic_lon: -465322,
            ic_city: "São Pedro dos Crentes",
            ic_states: "MARANHÃO",
            ic_january: 4.631,
            ic_february: 4.773,
            ic_march: 4.745,
            ic_april: 4.812,
            ic_may: 4.996,
            ic_june: 5.294,
            ic_july: 5.445,
            ic_august: 6.008,
            ic_september: 6.154,
            ic_october: 5.512,
            ic_november: 4.885,
            ic_december: 4.829,
            ic_yearly: 5.174,
          },
          {
            ic_uuid: "c089e775-0655-4622-8d5e-5e5cb71b01f9",
            ic_lat: -201737,
            ic_lon: -425254,
            ic_city: "São Pedro dos Ferros",
            ic_states: "MINAS GERAIS",
            ic_january: 5.864,
            ic_february: 6.088,
            ic_march: 5.129,
            ic_april: 4.63,
            ic_may: 3.952,
            ic_june: 3.712,
            ic_july: 3.899,
            ic_august: 4.644,
            ic_september: 4.95,
            ic_october: 5.158,
            ic_november: 4.994,
            ic_december: 5.702,
            ic_yearly: 4.893,
          },
          {
            ic_uuid: "00713314-ce5c-4127-922b-6a4a2be36167",
            ic_lat: -58024,
            ic_lon: -368855,
            ic_city: "São Rafael",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.983,
            ic_february: 5.968,
            ic_march: 6.118,
            ic_april: 5.821,
            ic_may: 5.269,
            ic_june: 4.819,
            ic_july: 5.074,
            ic_august: 5.754,
            ic_september: 6.243,
            ic_october: 6.344,
            ic_november: 6.404,
            ic_december: 6.099,
            ic_yearly: 5.824,
          },
          {
            ic_uuid: "50466feb-778a-419c-b1e1-185138e0a97e",
            ic_lat: -70222,
            ic_lon: -454812,
            ic_city: "São Raimundo das Mangabeiras",
            ic_states: "MARANHÃO",
            ic_january: 4.752,
            ic_february: 4.9,
            ic_march: 4.874,
            ic_april: 4.996,
            ic_may: 5.194,
            ic_june: 5.363,
            ic_july: 5.521,
            ic_august: 6.106,
            ic_september: 6.482,
            ic_october: 5.802,
            ic_november: 5.236,
            ic_december: 4.996,
            ic_yearly: 5.352,
          },
          {
            ic_uuid: "2fad3cef-8074-407d-bb51-86222dd83e6d",
            ic_lat: -51061,
            ic_lon: -450771,
            ic_city: "São Raimundo do Doca Bezerra",
            ic_states: "MARANHÃO",
            ic_january: 4.654,
            ic_february: 4.915,
            ic_march: 4.978,
            ic_april: 4.926,
            ic_may: 4.907,
            ic_june: 5.055,
            ic_july: 5.308,
            ic_august: 5.896,
            ic_september: 6.117,
            ic_october: 5.544,
            ic_november: 5.193,
            ic_december: 4.911,
            ic_yearly: 5.2,
          },
          {
            ic_uuid: "bb1d4180-b600-4f63-ab62-d14be7bddf1f",
            ic_lat: -90128,
            ic_lon: -42699,
            ic_city: "São Raimundo Nonato",
            ic_states: "PIAUÍ",
            ic_january: 5.964,
            ic_february: 5.717,
            ic_march: 5.649,
            ic_april: 5.404,
            ic_may: 5.254,
            ic_june: 5.232,
            ic_july: 5.594,
            ic_august: 6.365,
            ic_september: 6.683,
            ic_october: 6.538,
            ic_november: 6.24,
            ic_december: 6.091,
            ic_yearly: 5.894,
          },
          {
            ic_uuid: "c55f25e7-a174-4168-821b-63dba67b1950",
            ic_lat: -50227,
            ic_lon: -449927,
            ic_city: "São Roberto",
            ic_states: "MARANHÃO",
            ic_january: 4.682,
            ic_february: 4.902,
            ic_march: 4.932,
            ic_april: 4.931,
            ic_may: 4.954,
            ic_june: 5.062,
            ic_july: 5.298,
            ic_august: 5.892,
            ic_september: 6.105,
            ic_october: 5.582,
            ic_november: 5.22,
            ic_december: 4.899,
            ic_yearly: 5.205,
          },
          {
            ic_uuid: "829dac00-0b7a-4d51-8999-7e34efe0d83d",
            ic_lat: -163671,
            ic_lon: -450686,
            ic_city: "São Romão",
            ic_states: "MINAS GERAIS",
            ic_january: 6.33,
            ic_february: 6.566,
            ic_march: 5.681,
            ic_april: 5.551,
            ic_may: 5.005,
            ic_june: 4.73,
            ic_july: 5.032,
            ic_august: 5.85,
            ic_september: 6.004,
            ic_october: 6.135,
            ic_november: 5.578,
            ic_december: 6.097,
            ic_yearly: 5.713,
          },
          {
            ic_uuid: "a0cc6668-2118-4406-9c4e-0a64fbff8cea",
            ic_lat: -235231,
            ic_lon: -471361,
            ic_city: "São Roque",
            ic_states: "SÃO PAULO",
            ic_january: 5.439,
            ic_february: 5.686,
            ic_march: 4.991,
            ic_april: 4.41,
            ic_may: 3.591,
            ic_june: 3.346,
            ic_july: 3.434,
            ic_august: 4.443,
            ic_september: 4.54,
            ic_october: 5.069,
            ic_november: 5.516,
            ic_december: 6.002,
            ic_yearly: 4.706,
          },
          {
            ic_uuid: "40a7d2ff-4782-4032-85fd-1f2217af1050",
            ic_lat: -202495,
            ic_lon: -463643,
            ic_city: "São Roque de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.427,
            ic_february: 5.792,
            ic_march: 4.768,
            ic_april: 4.757,
            ic_may: 4.255,
            ic_june: 4.058,
            ic_july: 4.346,
            ic_august: 5.352,
            ic_september: 5.311,
            ic_october: 5.369,
            ic_november: 4.977,
            ic_december: 5.32,
            ic_yearly: 4.978,
          },
          {
            ic_uuid: "aacaa2f4-4609-43da-8f6d-2a1da2eb9a23",
            ic_lat: -197416,
            ic_lon: -406529,
            ic_city: "São Roque do Canaã",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.03,
            ic_february: 6.194,
            ic_march: 5.274,
            ic_april: 4.566,
            ic_may: 3.926,
            ic_june: 3.618,
            ic_july: 3.697,
            ic_august: 4.255,
            ic_september: 4.734,
            ic_october: 4.84,
            ic_november: 4.877,
            ic_december: 5.715,
            ic_yearly: 4.81,
          },
          {
            ic_uuid: "5d8f60ab-13c8-4fc6-a06f-5c6635e59ec8",
            ic_lat: -127495,
            ic_lon: -482358,
            ic_city: "São Salvador do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 5.625,
            ic_february: 5.625,
            ic_march: 5.288,
            ic_april: 5.292,
            ic_may: 5.082,
            ic_june: 4.85,
            ic_july: 5.144,
            ic_august: 5.793,
            ic_september: 5.826,
            ic_october: 5.675,
            ic_november: 5.244,
            ic_december: 5.46,
            ic_yearly: 5.409,
          },
          {
            ic_uuid: "dc8b722a-e4ea-4170-85a9-e0f2473df2a7",
            ic_lat: -99319,
            ic_lon: -365501,
            ic_city: "São Sebastião",
            ic_states: "ALAGOAS",
            ic_january: 6.023,
            ic_february: 5.811,
            ic_march: 5.804,
            ic_april: 5.061,
            ic_may: 4.293,
            ic_june: 3.991,
            ic_july: 3.969,
            ic_august: 4.642,
            ic_september: 5.368,
            ic_october: 5.711,
            ic_november: 6.221,
            ic_december: 6.205,
            ic_yearly: 5.258,
          },
          {
            ic_uuid: "f29911e2-45d1-4289-a7c0-6933a49d4f4c",
            ic_lat: -237955,
            ic_lon: -454147,
            ic_city: "São Sebastião",
            ic_states: "SÃO PAULO",
            ic_january: 5.499,
            ic_february: 5.627,
            ic_march: 4.562,
            ic_april: 4.098,
            ic_may: 3.307,
            ic_june: 2.95,
            ic_july: 3.052,
            ic_august: 3.756,
            ic_september: 3.63,
            ic_october: 4.132,
            ic_november: 4.644,
            ic_december: 5.305,
            ic_yearly: 4.214,
          },
          {
            ic_uuid: "0c82dca1-f474-4ac7-9c60-28c0aab7fa94",
            ic_lat: -234661,
            ic_lon: -50763,
            ic_city: "São Sebastião da Amoreira",
            ic_states: "PARANÁ",
            ic_january: 5.906,
            ic_february: 5.74,
            ic_march: 5.163,
            ic_april: 4.584,
            ic_may: 3.644,
            ic_june: 3.346,
            ic_july: 3.542,
            ic_august: 4.496,
            ic_september: 4.651,
            ic_october: 5.271,
            ic_november: 5.989,
            ic_december: 6.298,
            ic_yearly: 4.886,
          },
          {
            ic_uuid: "e45a817f-cd90-4f0d-bfcb-8412064001c7",
            ic_lat: -221588,
            ic_lon: -45755,
            ic_city: "São Sebastião da Bela Vista",
            ic_states: "MINAS GERAIS",
            ic_january: 5.368,
            ic_february: 5.69,
            ic_march: 4.896,
            ic_april: 4.598,
            ic_may: 3.889,
            ic_june: 3.634,
            ic_july: 3.855,
            ic_august: 4.788,
            ic_september: 4.93,
            ic_october: 5.371,
            ic_november: 5.328,
            ic_december: 5.68,
            ic_yearly: 4.835,
          },
          {
            ic_uuid: "f1f6927c-05b0-4ae6-a085-e3202af9de07",
            ic_lat: -17159,
            ic_lon: -495305,
            ic_city: "São Sebastião da Boa Vista",
            ic_states: "PARÁ",
            ic_january: 4.633,
            ic_february: 4.577,
            ic_march: 4.692,
            ic_april: 4.689,
            ic_may: 4.992,
            ic_june: 5.056,
            ic_july: 5.166,
            ic_august: 5.322,
            ic_september: 5.565,
            ic_october: 5.49,
            ic_november: 5.397,
            ic_december: 4.953,
            ic_yearly: 5.045,
          },
          {
            ic_uuid: "b45b79bc-3bb5-4e2e-b615-72fa3af16fa6",
            ic_lat: -217046,
            ic_lon: -468211,
            ic_city: "São Sebastião da Grama",
            ic_states: "SÃO PAULO",
            ic_january: 5.653,
            ic_february: 5.833,
            ic_march: 5.133,
            ic_april: 4.838,
            ic_may: 4.046,
            ic_june: 3.824,
            ic_july: 4.026,
            ic_august: 4.894,
            ic_september: 5.06,
            ic_october: 5.569,
            ic_november: 5.761,
            ic_december: 5.951,
            ic_yearly: 5.049,
          },
          {
            ic_uuid: "f21273e0-1d7a-41c8-b484-a1b0e33d8fed",
            ic_lat: -210741,
            ic_lon: -42635,
            ic_city: "São Sebastião da Vargem Alegre",
            ic_states: "MINAS GERAIS",
            ic_january: 5.723,
            ic_february: 6.015,
            ic_march: 4.995,
            ic_april: 4.371,
            ic_may: 3.737,
            ic_june: 3.559,
            ic_july: 3.721,
            ic_august: 4.517,
            ic_september: 4.925,
            ic_october: 5.136,
            ic_november: 4.937,
            ic_december: 5.685,
            ic_yearly: 4.777,
          },
          {
            ic_uuid: "589a88c7-4db0-4ed7-9f3a-e6179a4285c0",
            ic_lat: -71057,
            ic_lon: -358686,
            ic_city: "São Sebastião de Lagoa de Roça",
            ic_states: "PARAÍBA",
            ic_january: 5.701,
            ic_february: 5.812,
            ic_march: 5.793,
            ic_april: 5.332,
            ic_may: 4.684,
            ic_june: 4.148,
            ic_july: 4.272,
            ic_august: 5.041,
            ic_september: 5.561,
            ic_october: 5.87,
            ic_november: 6.072,
            ic_december: 5.902,
            ic_yearly: 5.349,
          },
          {
            ic_uuid: "e10f8a3c-5787-4220-bf14-21894b65c9e4",
            ic_lat: -219583,
            ic_lon: -421332,
            ic_city: "São Sebastião do Alto",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.031,
            ic_february: 6.283,
            ic_march: 5.162,
            ic_april: 4.452,
            ic_may: 3.691,
            ic_june: 3.418,
            ic_july: 3.556,
            ic_august: 4.308,
            ic_september: 4.645,
            ic_october: 4.961,
            ic_november: 5.008,
            ic_december: 5.834,
            ic_yearly: 4.779,
          },
          {
            ic_uuid: "63332513-6ed3-4e0d-8065-3623492f7ccd",
            ic_lat: -195001,
            ic_lon: -419796,
            ic_city: "São Sebastião do Anta",
            ic_states: "MINAS GERAIS",
            ic_january: 5.696,
            ic_february: 5.937,
            ic_march: 4.971,
            ic_april: 4.428,
            ic_may: 3.699,
            ic_june: 3.51,
            ic_july: 3.677,
            ic_august: 4.312,
            ic_september: 4.748,
            ic_october: 4.895,
            ic_november: 4.747,
            ic_december: 5.579,
            ic_yearly: 4.683,
          },
          {
            ic_uuid: "5859b626-7811-4d38-8580-45fc387ce6b0",
            ic_lat: -29589,
            ic_lon: -513753,
            ic_city: "São Sebastião do Caí",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.174,
            ic_february: 5.764,
            ic_march: 4.822,
            ic_april: 3.778,
            ic_may: 2.815,
            ic_june: 2.341,
            ic_july: 2.533,
            ic_august: 3.225,
            ic_september: 3.542,
            ic_october: 4.603,
            ic_november: 6.108,
            ic_december: 6.522,
            ic_yearly: 4.352,
          },
          {
            ic_uuid: "5fc646db-7311-42cd-b8f3-80ac540dce36",
            ic_lat: -180892,
            ic_lon: -425698,
            ic_city: "São Sebastião do Maranhão",
            ic_states: "MINAS GERAIS",
            ic_january: 5.932,
            ic_february: 6.201,
            ic_march: 5.193,
            ic_april: 4.582,
            ic_may: 3.952,
            ic_june: 3.74,
            ic_july: 3.864,
            ic_august: 4.647,
            ic_september: 5.143,
            ic_october: 5.296,
            ic_november: 4.846,
            ic_december: 5.655,
            ic_yearly: 4.921,
          },
          {
            ic_uuid: "ce9f3be8-144b-4030-bb91-eef40d7ce2bb",
            ic_lat: -202763,
            ic_lon: -450067,
            ic_city: "São Sebastião do Oeste",
            ic_states: "MINAS GERAIS",
            ic_january: 5.708,
            ic_february: 5.968,
            ic_march: 5.002,
            ic_april: 4.86,
            ic_may: 4.256,
            ic_june: 4.084,
            ic_july: 4.327,
            ic_august: 5.215,
            ic_september: 5.431,
            ic_october: 5.539,
            ic_november: 5.205,
            ic_december: 5.578,
            ic_yearly: 5.098,
          },
          {
            ic_uuid: "c4ce18d5-05de-48c8-97ef-e70a4d420281",
            ic_lat: -209172,
            ic_lon: -469841,
            ic_city: "São Sebastião do Paraíso",
            ic_states: "MINAS GERAIS",
            ic_january: 5.596,
            ic_february: 5.875,
            ic_march: 5.013,
            ic_april: 4.782,
            ic_may: 4.208,
            ic_june: 3.98,
            ic_july: 4.211,
            ic_august: 5.095,
            ic_september: 5.133,
            ic_october: 5.528,
            ic_november: 5.518,
            ic_december: 5.693,
            ic_yearly: 5.053,
          },
          {
            ic_uuid: "8937071a-7ea3-401f-9516-d1bd7d204a20",
            ic_lat: -125127,
            ic_lon: -384908,
            ic_city: "São Sebastião do Passé",
            ic_states: "BAHIA",
            ic_january: 6.051,
            ic_february: 5.87,
            ic_march: 5.691,
            ic_april: 4.543,
            ic_may: 3.887,
            ic_june: 3.627,
            ic_july: 3.833,
            ic_august: 4.366,
            ic_september: 5.165,
            ic_october: 5.555,
            ic_november: 5.851,
            ic_december: 6.139,
            ic_yearly: 5.048,
          },
          {
            ic_uuid: "ab7d4f38-7369-4fcd-b8d5-1a520ba3a84d",
            ic_lat: -19293,
            ic_lon: -431749,
            ic_city: "São Sebastião do Rio Preto",
            ic_states: "MINAS GERAIS",
            ic_january: 5.819,
            ic_february: 6.076,
            ic_march: 5.137,
            ic_april: 4.598,
            ic_may: 3.896,
            ic_june: 3.697,
            ic_july: 3.927,
            ic_august: 4.724,
            ic_september: 5.052,
            ic_october: 5.21,
            ic_november: 4.9,
            ic_december: 5.662,
            ic_yearly: 4.891,
          },
          {
            ic_uuid: "59e8747c-c4da-4f0d-a8b1-21e7c8089d5b",
            ic_lat: -222188,
            ic_lon: -449765,
            ic_city: "São Sebastião do Rio Verde",
            ic_states: "MINAS GERAIS",
            ic_january: 5.466,
            ic_february: 5.772,
            ic_march: 4.969,
            ic_april: 4.637,
            ic_may: 3.953,
            ic_june: 3.621,
            ic_july: 3.846,
            ic_august: 4.72,
            ic_september: 5.012,
            ic_october: 5.481,
            ic_november: 5.34,
            ic_december: 5.704,
            ic_yearly: 4.877,
          },
          {
            ic_uuid: "693b322a-f744-4c88-b29b-888af7921408",
            ic_lat: -52577,
            ic_lon: -482023,
            ic_city: "São Sebastião do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 4.564,
            ic_february: 4.725,
            ic_march: 4.823,
            ic_april: 4.877,
            ic_may: 4.816,
            ic_june: 5.153,
            ic_july: 5.248,
            ic_august: 5.725,
            ic_september: 5.518,
            ic_october: 5.012,
            ic_november: 4.881,
            ic_december: 4.655,
            ic_yearly: 5.0,
          },
          {
            ic_uuid: "56c431ae-c0c7-43a3-a8d6-901f9942a2d8",
            ic_lat: -25595,
            ic_lon: -578735,
            ic_city: "São Sebastião do Uatumã",
            ic_states: "AMAZONAS",
            ic_january: 4.024,
            ic_february: 4.122,
            ic_march: 4.114,
            ic_april: 3.966,
            ic_may: 4.073,
            ic_june: 4.699,
            ic_july: 4.639,
            ic_august: 5.258,
            ic_september: 5.251,
            ic_october: 5.186,
            ic_november: 4.979,
            ic_december: 4.478,
            ic_yearly: 4.566,
          },
          {
            ic_uuid: "8e5dc04c-0263-4c85-b83d-db89ceb9cfb7",
            ic_lat: -81528,
            ic_lon: -370093,
            ic_city: "São Sebastião do Umbuzeiro",
            ic_states: "PARAÍBA",
            ic_january: 6.195,
            ic_february: 5.983,
            ic_march: 6.043,
            ic_april: 5.514,
            ic_may: 4.741,
            ic_june: 4.274,
            ic_july: 4.384,
            ic_august: 5.217,
            ic_september: 6.142,
            ic_october: 6.392,
            ic_november: 6.683,
            ic_december: 6.397,
            ic_yearly: 5.664,
          },
          {
            ic_uuid: "db5f90c6-a00a-491c-94a1-a433072bfb28",
            ic_lat: -30161,
            ic_lon: -535649,
            ic_city: "São Sepé",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.491,
            ic_february: 6.028,
            ic_march: 5.068,
            ic_april: 3.875,
            ic_may: 2.825,
            ic_june: 2.33,
            ic_july: 2.541,
            ic_august: 3.304,
            ic_september: 3.803,
            ic_october: 5.027,
            ic_november: 6.346,
            ic_december: 6.903,
            ic_yearly: 4.545,
          },
          {
            ic_uuid: "0d354acb-f934-49ef-932f-d3ca08fdd98c",
            ic_lat: -189965,
            ic_lon: -505474,
            ic_city: "São Simão",
            ic_states: "GOIÁS",
            ic_january: 5.742,
            ic_february: 5.917,
            ic_march: 5.386,
            ic_april: 5.051,
            ic_may: 4.465,
            ic_june: 4.168,
            ic_july: 4.367,
            ic_august: 5.206,
            ic_september: 5.147,
            ic_october: 5.585,
            ic_november: 5.842,
            ic_december: 6.058,
            ic_yearly: 5.245,
          },
          {
            ic_uuid: "14399b2f-634d-42f0-917d-3083d0b1a95f",
            ic_lat: -214736,
            ic_lon: -475522,
            ic_city: "São Simão",
            ic_states: "SÃO PAULO",
            ic_january: 5.644,
            ic_february: 5.869,
            ic_march: 5.092,
            ic_april: 4.779,
            ic_may: 4.049,
            ic_june: 3.816,
            ic_july: 4.01,
            ic_august: 4.901,
            ic_september: 4.96,
            ic_october: 5.513,
            ic_november: 5.637,
            ic_december: 5.987,
            ic_yearly: 5.021,
          },
          {
            ic_uuid: "af69bbd0-a085-473c-bd42-aff409b02f9e",
            ic_lat: -217263,
            ic_lon: -449852,
            ic_city: "São Thomé das Letras",
            ic_states: "MINAS GERAIS",
            ic_january: 5.423,
            ic_february: 5.641,
            ic_march: 4.882,
            ic_april: 4.532,
            ic_may: 3.889,
            ic_june: 3.662,
            ic_july: 3.906,
            ic_august: 4.776,
            ic_september: 5.037,
            ic_october: 5.361,
            ic_november: 5.211,
            ic_december: 5.547,
            ic_yearly: 4.822,
          },
          {
            ic_uuid: "cf710346-6168-45d3-bf30-0aabb7299765",
            ic_lat: -209079,
            ic_lon: -445102,
            ic_city: "São Tiago",
            ic_states: "MINAS GERAIS",
            ic_january: 5.583,
            ic_february: 5.749,
            ic_march: 4.871,
            ic_april: 4.569,
            ic_may: 3.964,
            ic_june: 3.819,
            ic_july: 4.054,
            ic_august: 4.987,
            ic_september: 5.176,
            ic_october: 5.339,
            ic_november: 5.063,
            ic_december: 5.536,
            ic_yearly: 4.892,
          },
          {
            ic_uuid: "4717748a-71f9-4f02-9bd4-58913e78889d",
            ic_lat: -207795,
            ic_lon: -470966,
            ic_city: "São Tomás de Aquino",
            ic_states: "MINAS GERAIS",
            ic_january: 5.549,
            ic_february: 5.822,
            ic_march: 5.004,
            ic_april: 4.841,
            ic_may: 4.208,
            ic_june: 4.021,
            ic_july: 4.261,
            ic_august: 5.168,
            ic_september: 5.144,
            ic_october: 5.5,
            ic_november: 5.539,
            ic_december: 5.706,
            ic_yearly: 5.064,
          },
          {
            ic_uuid: "7d29c8b3-0944-4ff0-900e-520cc249fced",
            ic_lat: -235354,
            ic_lon: -525906,
            ic_city: "São Tomé",
            ic_states: "PARANÁ",
            ic_january: 6.055,
            ic_february: 5.841,
            ic_march: 5.319,
            ic_april: 4.557,
            ic_may: 3.614,
            ic_june: 3.255,
            ic_july: 3.477,
            ic_august: 4.4,
            ic_september: 4.647,
            ic_october: 5.377,
            ic_november: 6.119,
            ic_december: 6.486,
            ic_yearly: 4.929,
          },
          {
            ic_uuid: "cc82f4bb-0cd0-42cd-b4cf-4ba66d3a5458",
            ic_lat: -59749,
            ic_lon: -36077,
            ic_city: "São Tomé",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.781,
            ic_february: 5.827,
            ic_march: 5.805,
            ic_april: 5.479,
            ic_may: 4.993,
            ic_june: 4.506,
            ic_july: 4.744,
            ic_august: 5.384,
            ic_september: 5.888,
            ic_october: 6.051,
            ic_november: 6.156,
            ic_december: 5.819,
            ic_yearly: 5.536,
          },
          {
            ic_uuid: "8caecb7d-3b7b-4c81-9e0b-225941a7ca9c",
            ic_lat: -275588,
            ic_lon: -525242,
            ic_city: "São Valentim",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.2,
            ic_february: 5.752,
            ic_march: 5.008,
            ic_april: 4.104,
            ic_may: 3.088,
            ic_june: 2.605,
            ic_july: 2.927,
            ic_august: 3.752,
            ic_september: 3.965,
            ic_october: 5.099,
            ic_november: 6.165,
            ic_december: 6.514,
            ic_yearly: 4.598,
          },
          {
            ic_uuid: "d6175925-9960-4919-9122-8d5956a417af",
            ic_lat: -29047,
            ic_lon: -517649,
            ic_city: "São Valentim do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.199,
            ic_february: 5.755,
            ic_march: 4.866,
            ic_april: 3.844,
            ic_may: 2.851,
            ic_june: 2.37,
            ic_july: 2.691,
            ic_august: 3.402,
            ic_september: 3.676,
            ic_october: 4.745,
            ic_november: 6.107,
            ic_december: 6.498,
            ic_yearly: 4.417,
          },
          {
            ic_uuid: "b2de3024-d8e7-4c57-9925-db085b3cba0b",
            ic_lat: -119747,
            ic_lon: -482357,
            ic_city: "São Valério da Natividade",
            ic_states: "TOCANTINS",
            ic_january: 5.447,
            ic_february: 5.451,
            ic_march: 5.11,
            ic_april: 5.142,
            ic_may: 5.122,
            ic_june: 4.97,
            ic_july: 5.284,
            ic_august: 5.857,
            ic_september: 5.916,
            ic_october: 5.602,
            ic_november: 5.357,
            ic_december: 5.397,
            ic_yearly: 5.388,
          },
          {
            ic_uuid: "90e77936-9315-4e0c-b578-27635fe51124",
            ic_lat: -277911,
            ic_lon: -539372,
            ic_city: "São Valério do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.449,
            ic_february: 6.002,
            ic_march: 5.095,
            ic_april: 4.07,
            ic_may: 3.1,
            ic_june: 2.583,
            ic_july: 2.888,
            ic_august: 3.696,
            ic_september: 3.982,
            ic_october: 5.16,
            ic_november: 6.215,
            ic_december: 6.683,
            ic_yearly: 4.66,
          },
          {
            ic_uuid: "819d79a0-17ef-46e3-83e0-b1f316c8bc33",
            ic_lat: -293727,
            ic_lon: -513707,
            ic_city: "São Vendelino",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.085,
            ic_february: 5.59,
            ic_march: 4.715,
            ic_april: 3.779,
            ic_may: 2.808,
            ic_june: 2.356,
            ic_july: 2.59,
            ic_august: 3.283,
            ic_september: 3.544,
            ic_october: 4.577,
            ic_november: 6.002,
            ic_december: 6.379,
            ic_yearly: 4.309,
          },
          {
            ic_uuid: "230729c4-0f74-4728-9e09-f82ae85dbdb2",
            ic_lat: -62193,
            ic_lon: -36683,
            ic_city: "São Vicente",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.945,
            ic_february: 5.914,
            ic_march: 5.905,
            ic_april: 5.544,
            ic_may: 5.066,
            ic_june: 4.65,
            ic_july: 4.927,
            ic_august: 5.717,
            ic_september: 6.244,
            ic_october: 6.452,
            ic_november: 6.488,
            ic_december: 6.064,
            ic_yearly: 5.743,
          },
          {
            ic_uuid: "3f462228-d94d-43ec-b542-3666013c886b",
            ic_lat: -239578,
            ic_lon: -463887,
            ic_city: "São Vicente",
            ic_states: "SÃO PAULO",
            ic_january: 5.311,
            ic_february: 5.443,
            ic_march: 4.579,
            ic_april: 4.028,
            ic_may: 3.26,
            ic_june: 2.885,
            ic_july: 2.905,
            ic_august: 3.583,
            ic_september: 3.56,
            ic_october: 3.983,
            ic_november: 4.838,
            ic_december: 5.328,
            ic_yearly: 4.142,
          },
          {
            ic_uuid: "7760e300-1065-4f93-9a56-a8130c43707c",
            ic_lat: -217047,
            ic_lon: -444434,
            ic_city: "São Vicente de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.381,
            ic_february: 5.758,
            ic_march: 4.845,
            ic_april: 4.415,
            ic_may: 3.738,
            ic_june: 3.583,
            ic_july: 3.763,
            ic_august: 4.696,
            ic_september: 4.887,
            ic_october: 5.159,
            ic_november: 5.106,
            ic_december: 5.5,
            ic_yearly: 4.736,
          },
          {
            ic_uuid: "81b49cc0-3421-4d39-9e61-c1af92dc478b",
            ic_lat: -69338,
            ic_lon: -364046,
            ic_city: "São Vicente do Seridó",
            ic_states: "PARAÍBA",
            ic_january: 5.897,
            ic_february: 5.93,
            ic_march: 5.97,
            ic_april: 5.531,
            ic_may: 4.933,
            ic_june: 4.503,
            ic_july: 4.774,
            ic_august: 5.475,
            ic_september: 6.052,
            ic_october: 6.328,
            ic_november: 6.449,
            ic_december: 6.06,
            ic_yearly: 5.658,
          },
          {
            ic_uuid: "2e586a9c-0027-42c0-b25f-79945c7606c8",
            ic_lat: -296919,
            ic_lon: -54679,
            ic_city: "São Vicente do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.616,
            ic_february: 6.027,
            ic_march: 5.129,
            ic_april: 3.953,
            ic_may: 2.91,
            ic_june: 2.413,
            ic_july: 2.677,
            ic_august: 3.417,
            ic_september: 3.903,
            ic_october: 5.077,
            ic_november: 6.371,
            ic_december: 6.981,
            ic_yearly: 4.623,
          },
          {
            ic_uuid: "51e8cc6d-041c-4088-957b-cb7c6c14ec13",
            ic_lat: -28924,
            ic_lon: -448801,
            ic_city: "São Vicente Ferrer",
            ic_states: "MARANHÃO",
            ic_january: 4.731,
            ic_february: 4.845,
            ic_march: 4.805,
            ic_april: 4.797,
            ic_may: 4.806,
            ic_june: 5.009,
            ic_july: 5.096,
            ic_august: 5.615,
            ic_september: 5.812,
            ic_october: 5.595,
            ic_november: 5.443,
            ic_december: 5.089,
            ic_yearly: 5.137,
          },
          {
            ic_uuid: "30f7a70b-0f43-4cf7-beb7-37f8b462a39c",
            ic_lat: -75921,
            ic_lon: -354866,
            ic_city: "São Vicente Ferrer",
            ic_states: "PERNAMBUCO",
            ic_january: 5.507,
            ic_february: 5.686,
            ic_march: 5.686,
            ic_april: 5.154,
            ic_may: 4.409,
            ic_june: 3.95,
            ic_july: 4.064,
            ic_august: 4.792,
            ic_september: 5.338,
            ic_october: 5.58,
            ic_november: 5.997,
            ic_december: 5.827,
            ic_yearly: 5.166,
          },
          {
            ic_uuid: "1365951a-606e-47d7-a387-b5ff110240ac",
            ic_lat: -70908,
            ic_lon: -352305,
            ic_city: "Sapé",
            ic_states: "PARAÍBA",
            ic_january: 5.7,
            ic_february: 5.85,
            ic_march: 5.929,
            ic_april: 5.305,
            ic_may: 4.674,
            ic_june: 4.234,
            ic_july: 4.326,
            ic_august: 5.102,
            ic_september: 5.548,
            ic_october: 5.832,
            ic_november: 6.102,
            ic_december: 5.955,
            ic_yearly: 5.38,
          },
          {
            ic_uuid: "0a0eb1b0-9e76-491f-87b6-a182d066c5d1",
            ic_lat: -127298,
            ic_lon: -39187,
            ic_city: "Sapeaçu",
            ic_states: "BAHIA",
            ic_january: 6.067,
            ic_february: 5.89,
            ic_march: 5.673,
            ic_april: 4.619,
            ic_may: 3.988,
            ic_june: 3.649,
            ic_july: 3.859,
            ic_august: 4.416,
            ic_september: 5.24,
            ic_october: 5.481,
            ic_november: 5.698,
            ic_december: 6.023,
            ic_yearly: 5.05,
          },
          {
            ic_uuid: "dc19e165-1e16-4cef-9b5c-2f34489826c1",
            ic_lat: -135454,
            ic_lon: -588193,
            ic_city: "Sapezal",
            ic_states: "MATO GROSSO",
            ic_january: 4.934,
            ic_february: 4.79,
            ic_march: 4.803,
            ic_april: 4.681,
            ic_may: 4.289,
            ic_june: 4.348,
            ic_july: 4.486,
            ic_august: 5.031,
            ic_september: 4.878,
            ic_october: 5.056,
            ic_november: 5.191,
            ic_december: 5.252,
            ic_yearly: 4.812,
          },
          {
            ic_uuid: "96cf6574-f4dc-4b9b-a0b4-7505181f5245",
            ic_lat: -296354,
            ic_lon: -510069,
            ic_city: "Sapiranga",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.2,
            ic_february: 5.688,
            ic_march: 4.72,
            ic_april: 3.753,
            ic_may: 2.808,
            ic_june: 2.336,
            ic_july: 2.535,
            ic_august: 3.214,
            ic_september: 3.508,
            ic_october: 4.601,
            ic_november: 6.035,
            ic_december: 6.486,
            ic_yearly: 4.323,
          },
          {
            ic_uuid: "66cd2874-123d-4351-8233-626b93771d03",
            ic_lat: -23911,
            ic_lon: -505799,
            ic_city: "Sapopema",
            ic_states: "PARANÁ",
            ic_january: 5.599,
            ic_february: 5.47,
            ic_march: 5.007,
            ic_april: 4.419,
            ic_may: 3.511,
            ic_june: 3.124,
            ic_july: 3.392,
            ic_august: 4.4,
            ic_september: 4.537,
            ic_october: 5.076,
            ic_november: 5.745,
            ic_december: 6.013,
            ic_yearly: 4.691,
          },
          {
            ic_uuid: "c80a51ba-5ba1-4b57-9a8b-c579caf9242d",
            ic_lat: -69411,
            ic_lon: -497008,
            ic_city: "Sapucaia",
            ic_states: "PARÁ",
            ic_january: 4.68,
            ic_february: 4.76,
            ic_march: 4.683,
            ic_april: 4.647,
            ic_may: 4.841,
            ic_june: 5.043,
            ic_july: 5.083,
            ic_august: 5.606,
            ic_september: 5.394,
            ic_october: 4.952,
            ic_november: 4.692,
            ic_december: 4.623,
            ic_yearly: 4.917,
          },
          {
            ic_uuid: "147bb938-4140-4c91-9537-b13e1b32ea1c",
            ic_lat: -219954,
            ic_lon: -429146,
            ic_city: "Sapucaia",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.789,
            ic_february: 6.016,
            ic_march: 5.084,
            ic_april: 4.389,
            ic_may: 3.6,
            ic_june: 3.396,
            ic_july: 3.559,
            ic_august: 4.317,
            ic_september: 4.636,
            ic_october: 5.008,
            ic_november: 4.989,
            ic_december: 5.712,
            ic_yearly: 4.708,
          },
          {
            ic_uuid: "a816aa6d-69e1-4eda-9118-f299704f0289",
            ic_lat: -298281,
            ic_lon: -511454,
            ic_city: "Sapucaia do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.314,
            ic_february: 5.783,
            ic_march: 4.8,
            ic_april: 3.804,
            ic_may: 2.792,
            ic_june: 2.335,
            ic_july: 2.517,
            ic_august: 3.207,
            ic_september: 3.56,
            ic_october: 4.682,
            ic_november: 6.142,
            ic_december: 6.616,
            ic_yearly: 4.379,
          },
          {
            ic_uuid: "cdd5e558-2f6b-4ed5-a191-0cf5e4feba1f",
            ic_lat: -227414,
            ic_lon: -457383,
            ic_city: "Sapucaí-Mirim",
            ic_states: "MINAS GERAIS",
            ic_january: 5.228,
            ic_february: 5.599,
            ic_march: 4.818,
            ic_april: 4.445,
            ic_may: 3.742,
            ic_june: 3.492,
            ic_july: 3.702,
            ic_august: 4.651,
            ic_september: 4.788,
            ic_october: 5.255,
            ic_november: 5.278,
            ic_december: 5.699,
            ic_yearly: 4.725,
          },
          {
            ic_uuid: "bfff8a39-2ba8-45ee-8c2a-0b99380ecb29",
            ic_lat: -229297,
            ic_lon: -425103,
            ic_city: "Saquarema",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.183,
            ic_february: 6.443,
            ic_march: 5.11,
            ic_april: 4.436,
            ic_may: 3.591,
            ic_june: 3.367,
            ic_july: 3.377,
            ic_august: 4.173,
            ic_september: 4.394,
            ic_october: 5.049,
            ic_november: 5.121,
            ic_december: 5.932,
            ic_yearly: 4.764,
          },
          {
            ic_uuid: "35d162ca-df3c-426c-9703-948ed832ac5c",
            ic_lat: -234446,
            ic_lon: -518764,
            ic_city: "Sarandi",
            ic_states: "PARANÁ",
            ic_january: 5.899,
            ic_february: 5.795,
            ic_march: 5.271,
            ic_april: 4.587,
            ic_may: 3.644,
            ic_june: 3.34,
            ic_july: 3.532,
            ic_august: 4.459,
            ic_september: 4.686,
            ic_october: 5.323,
            ic_november: 5.984,
            ic_december: 6.298,
            ic_yearly: 4.902,
          },
          {
            ic_uuid: "6cd3e2ed-d236-4723-b7d0-845bf7ed91f6",
            ic_lat: -279425,
            ic_lon: -529235,
            ic_city: "Sarandi",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.354,
            ic_february: 5.958,
            ic_march: 5.053,
            ic_april: 4.106,
            ic_may: 3.085,
            ic_june: 2.571,
            ic_july: 2.905,
            ic_august: 3.668,
            ic_september: 3.92,
            ic_october: 5.124,
            ic_november: 6.24,
            ic_december: 6.647,
            ic_yearly: 4.636,
          },
          {
            ic_uuid: "d5ef768a-495b-4e7d-8456-62b04628ffcb",
            ic_lat: -236402,
            ic_lon: -478253,
            ic_city: "Sarapuí",
            ic_states: "SÃO PAULO",
            ic_january: 5.474,
            ic_february: 5.688,
            ic_march: 4.997,
            ic_april: 4.454,
            ic_may: 3.573,
            ic_june: 3.324,
            ic_july: 3.423,
            ic_august: 4.421,
            ic_september: 4.564,
            ic_october: 5.127,
            ic_november: 5.643,
            ic_december: 6.051,
            ic_yearly: 4.728,
          },
          {
            ic_uuid: "9d3dd397-a859-4a2a-863c-030a8b3fa5cd",
            ic_lat: -187833,
            ic_lon: -423633,
            ic_city: "Sardoá",
            ic_states: "MINAS GERAIS",
            ic_january: 5.648,
            ic_february: 5.852,
            ic_march: 4.94,
            ic_april: 4.363,
            ic_may: 3.718,
            ic_june: 3.56,
            ic_july: 3.665,
            ic_august: 4.375,
            ic_september: 4.853,
            ic_october: 5.017,
            ic_november: 4.623,
            ic_december: 5.457,
            ic_yearly: 4.673,
          },
          {
            ic_uuid: "413b4030-4213-4a6b-97ae-3c15811e82a0",
            ic_lat: -23273,
            ic_lon: -494799,
            ic_city: "Sarutaiá",
            ic_states: "SÃO PAULO",
            ic_january: 5.638,
            ic_february: 5.735,
            ic_march: 5.132,
            ic_april: 4.537,
            ic_may: 3.626,
            ic_june: 3.332,
            ic_july: 3.551,
            ic_august: 4.523,
            ic_september: 4.652,
            ic_october: 5.303,
            ic_november: 5.858,
            ic_december: 6.215,
            ic_yearly: 4.842,
          },
          {
            ic_uuid: "1ff9a299-991e-444f-823b-08534a1f35e7",
            ic_lat: -200371,
            ic_lon: -44145,
            ic_city: "Sarzedo",
            ic_states: "MINAS GERAIS",
            ic_january: 5.801,
            ic_february: 5.999,
            ic_march: 5.162,
            ic_april: 4.822,
            ic_may: 4.237,
            ic_june: 4.075,
            ic_july: 4.336,
            ic_august: 5.193,
            ic_september: 5.523,
            ic_october: 5.616,
            ic_november: 5.293,
            ic_december: 5.619,
            ic_yearly: 5.14,
          },
          {
            ic_uuid: "6a3ff7e3-5dc3-46cc-a382-48aac406c415",
            ic_lat: -11604,
            ic_lon: -385814,
            ic_city: "Sátiro Dias",
            ic_states: "BAHIA",
            ic_january: 6.026,
            ic_february: 5.879,
            ic_march: 5.753,
            ic_april: 4.808,
            ic_may: 4.072,
            ic_june: 3.819,
            ic_july: 3.998,
            ic_august: 4.505,
            ic_september: 5.298,
            ic_october: 5.523,
            ic_november: 5.928,
            ic_december: 6.057,
            ic_yearly: 5.139,
          },
          {
            ic_uuid: "3a119a2a-0774-41f9-ab17-4e7caeca2151",
            ic_lat: -95695,
            ic_lon: -35823,
            ic_city: "Satuba",
            ic_states: "ALAGOAS",
            ic_january: 6.047,
            ic_february: 6.015,
            ic_march: 5.95,
            ic_april: 5.087,
            ic_may: 4.306,
            ic_june: 4.008,
            ic_july: 4.137,
            ic_august: 4.761,
            ic_september: 5.515,
            ic_october: 5.875,
            ic_november: 6.307,
            ic_december: 6.31,
            ic_yearly: 5.36,
          },
          {
            ic_uuid: "9104fbe7-d165-4a8f-9c44-f78edb9e2bf8",
            ic_lat: -40372,
            ic_lon: -452387,
            ic_city: "Satubinha",
            ic_states: "MARANHÃO",
            ic_january: 4.655,
            ic_february: 4.844,
            ic_march: 4.93,
            ic_april: 4.913,
            ic_may: 4.765,
            ic_june: 4.958,
            ic_july: 5.061,
            ic_august: 5.568,
            ic_september: 5.841,
            ic_october: 5.49,
            ic_november: 5.151,
            ic_december: 4.94,
            ic_yearly: 5.093,
          },
          {
            ic_uuid: "f816aad6-ad6a-420c-8d76-fc85fc323dc6",
            ic_lat: -127398,
            ic_lon: -38767,
            ic_city: "Saubara",
            ic_states: "BAHIA",
            ic_january: 6.366,
            ic_february: 6.217,
            ic_march: 5.999,
            ic_april: 4.849,
            ic_may: 4.13,
            ic_june: 3.776,
            ic_july: 4.05,
            ic_august: 4.626,
            ic_september: 5.49,
            ic_october: 5.809,
            ic_november: 6.031,
            ic_december: 6.386,
            ic_yearly: 5.311,
          },
          {
            ic_uuid: "cebc00c7-a64c-4a12-a4cd-4e3d7cac6765",
            ic_lat: -256922,
            ic_lon: -526188,
            ic_city: "Saudade do Iguaçu",
            ic_states: "PARANÁ",
            ic_january: 6.197,
            ic_february: 5.715,
            ic_march: 5.272,
            ic_april: 4.283,
            ic_may: 3.301,
            ic_june: 2.883,
            ic_july: 3.096,
            ic_august: 4.102,
            ic_september: 4.293,
            ic_october: 5.263,
            ic_november: 6.164,
            ic_december: 6.332,
            ic_yearly: 4.742,
          },
          {
            ic_uuid: "6d5f08c5-eef0-494e-be4a-4f933170ab9d",
            ic_lat: -269321,
            ic_lon: -530026,
            ic_city: "Saudades",
            ic_states: "SANTA CATARINA",
            ic_january: 6.215,
            ic_february: 5.701,
            ic_march: 5.062,
            ic_april: 4.084,
            ic_may: 3.144,
            ic_june: 2.632,
            ic_july: 2.95,
            ic_august: 3.805,
            ic_september: 4.047,
            ic_october: 5.105,
            ic_november: 6.052,
            ic_december: 6.429,
            ic_yearly: 4.602,
          },
          {
            ic_uuid: "e23ae70f-f15a-48d6-887d-08909091915a",
            ic_lat: -109412,
            ic_lon: -404204,
            ic_city: "Saúde",
            ic_states: "BAHIA",
            ic_january: 6.192,
            ic_february: 5.929,
            ic_march: 5.736,
            ic_april: 4.773,
            ic_may: 4.039,
            ic_june: 3.738,
            ic_july: 4.0,
            ic_august: 4.679,
            ic_september: 5.637,
            ic_october: 5.763,
            ic_november: 5.941,
            ic_december: 5.997,
            ic_yearly: 5.202,
          },
          {
            ic_uuid: "e3c2b79c-65cb-4a9f-a847-3b261147075f",
            ic_lat: -264121,
            ic_lon: -490745,
            ic_city: "Schroeder",
            ic_states: "SANTA CATARINA",
            ic_january: 5.017,
            ic_february: 4.791,
            ic_march: 4.237,
            ic_april: 3.523,
            ic_may: 2.928,
            ic_june: 2.456,
            ic_july: 2.563,
            ic_august: 3.275,
            ic_september: 3.198,
            ic_october: 3.704,
            ic_november: 4.726,
            ic_december: 5.022,
            ic_yearly: 3.787,
          },
          {
            ic_uuid: "6165940f-e0a2-408d-ac3a-42a6d82a1a28",
            ic_lat: -124173,
            ic_lon: -417725,
            ic_city: "Seabra",
            ic_states: "BAHIA",
            ic_january: 5.953,
            ic_february: 5.954,
            ic_march: 5.637,
            ic_april: 4.823,
            ic_may: 4.336,
            ic_june: 3.997,
            ic_july: 4.205,
            ic_august: 4.866,
            ic_september: 5.698,
            ic_october: 5.805,
            ic_november: 5.573,
            ic_december: 5.947,
            ic_yearly: 5.233,
          },
          {
            ic_uuid: "0664bc71-5b96-4c90-a07a-1f34c2add2e9",
            ic_lat: -271569,
            ic_lon: -522995,
            ic_city: "Seara",
            ic_states: "SANTA CATARINA",
            ic_january: 6.189,
            ic_february: 5.741,
            ic_march: 5.105,
            ic_april: 4.033,
            ic_may: 3.083,
            ic_june: 2.548,
            ic_july: 2.866,
            ic_august: 3.755,
            ic_september: 4.0,
            ic_october: 5.064,
            ic_november: 6.171,
            ic_december: 6.457,
            ic_yearly: 4.584,
          },
          {
            ic_uuid: "b03a4fe8-3a28-44a8-8760-40dc34008a8f",
            ic_lat: -206528,
            ic_lon: -499254,
            ic_city: "Sebastianópolis do Sul",
            ic_states: "SÃO PAULO",
            ic_january: 5.823,
            ic_february: 5.879,
            ic_march: 5.253,
            ic_april: 4.892,
            ic_may: 4.145,
            ic_june: 3.886,
            ic_july: 4.148,
            ic_august: 5.024,
            ic_september: 5.026,
            ic_october: 5.643,
            ic_november: 5.941,
            ic_december: 6.163,
            ic_yearly: 5.152,
          },
          {
            ic_uuid: "715a5158-1c46-4439-b091-c9d2c48fd78f",
            ic_lat: -108174,
            ic_lon: -448341,
            ic_city: "Sebastião Barros",
            ic_states: "PIAUÍ",
            ic_january: 5.911,
            ic_february: 5.754,
            ic_march: 5.546,
            ic_april: 5.419,
            ic_may: 5.459,
            ic_june: 5.31,
            ic_july: 5.551,
            ic_august: 6.162,
            ic_september: 6.458,
            ic_october: 6.138,
            ic_november: 5.752,
            ic_december: 5.9,
            ic_yearly: 5.78,
          },
          {
            ic_uuid: "840e06a5-cea5-49c7-93b8-a8615aa23733",
            ic_lat: -145715,
            ic_lon: -429438,
            ic_city: "Sebastião Laranjeiras",
            ic_states: "BAHIA",
            ic_january: 6.655,
            ic_february: 6.642,
            ic_march: 5.955,
            ic_april: 5.511,
            ic_may: 4.878,
            ic_june: 4.764,
            ic_july: 4.947,
            ic_august: 5.582,
            ic_september: 6.072,
            ic_october: 6.369,
            ic_november: 5.85,
            ic_december: 6.48,
            ic_yearly: 5.809,
          },
          {
            ic_uuid: "79c9d8dc-82d0-4e9a-8040-c0392184c7e4",
            ic_lat: -75682,
            ic_lon: -440636,
            ic_city: "Sebastião Leal",
            ic_states: "PIAUÍ",
            ic_january: 5.24,
            ic_february: 5.204,
            ic_march: 5.111,
            ic_april: 5.23,
            ic_may: 5.341,
            ic_june: 5.4,
            ic_july: 5.562,
            ic_august: 6.136,
            ic_september: 6.566,
            ic_october: 6.257,
            ic_november: 5.703,
            ic_december: 5.489,
            ic_yearly: 5.603,
          },
          {
            ic_uuid: "bbde3a3d-c417-479c-b8d8-141fde3c75df",
            ic_lat: -274833,
            ic_lon: -534031,
            ic_city: "Seberi",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.398,
            ic_february: 5.855,
            ic_march: 5.116,
            ic_april: 4.081,
            ic_may: 3.11,
            ic_june: 2.567,
            ic_july: 2.91,
            ic_august: 3.713,
            ic_september: 3.998,
            ic_october: 5.185,
            ic_november: 6.241,
            ic_december: 6.638,
            ic_yearly: 4.651,
          },
          {
            ic_uuid: "b2ee4cf8-4ec8-4850-bfe3-064213aaf8ff",
            ic_lat: -276372,
            ic_lon: -539498,
            ic_city: "Sede Nova",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.422,
            ic_february: 5.947,
            ic_march: 5.156,
            ic_april: 4.067,
            ic_may: 3.122,
            ic_june: 2.58,
            ic_july: 2.923,
            ic_august: 3.694,
            ic_september: 3.993,
            ic_october: 5.208,
            ic_november: 6.219,
            ic_december: 6.65,
            ic_yearly: 4.665,
          },
          {
            ic_uuid: "99becb42-4064-4136-999b-7c4d2d543304",
            ic_lat: -293528,
            ic_lon: -529771,
            ic_city: "Segredo",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.219,
            ic_february: 5.914,
            ic_march: 5.025,
            ic_april: 3.893,
            ic_may: 2.878,
            ic_june: 2.413,
            ic_july: 2.666,
            ic_august: 3.417,
            ic_september: 3.726,
            ic_october: 4.955,
            ic_november: 6.276,
            ic_december: 6.714,
            ic_yearly: 4.508,
          },
          {
            ic_uuid: "b59d4abd-8c41-4233-877b-e6edd9e85d9d",
            ic_lat: -286299,
            ic_lon: -529502,
            ic_city: "Selbach",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.332,
            ic_february: 5.903,
            ic_march: 5.028,
            ic_april: 3.995,
            ic_may: 3.039,
            ic_june: 2.474,
            ic_july: 2.796,
            ic_august: 3.568,
            ic_september: 3.879,
            ic_october: 5.033,
            ic_november: 6.251,
            ic_december: 6.664,
            ic_yearly: 4.58,
          },
          {
            ic_uuid: "6b933998-ee0b-436a-a80c-481bed33040e",
            ic_lat: -203642,
            ic_lon: -514197,
            ic_city: "Selvíria",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.846,
            ic_february: 5.946,
            ic_march: 5.451,
            ic_april: 4.907,
            ic_may: 4.163,
            ic_june: 3.861,
            ic_july: 4.053,
            ic_august: 4.95,
            ic_september: 4.977,
            ic_october: 5.618,
            ic_november: 5.956,
            ic_december: 6.301,
            ic_yearly: 5.169,
          },
          {
            ic_uuid: "ecea40ef-b4e8-4602-a579-6dd17ed99b2b",
            ic_lat: -201013,
            ic_lon: -428487,
            ic_city: "Sem-Peixe",
            ic_states: "MINAS GERAIS",
            ic_january: 5.59,
            ic_february: 5.93,
            ic_march: 4.917,
            ic_april: 4.471,
            ic_may: 3.78,
            ic_june: 3.588,
            ic_july: 3.833,
            ic_august: 4.575,
            ic_september: 4.886,
            ic_october: 4.995,
            ic_november: 4.703,
            ic_december: 5.344,
            ic_yearly: 4.718,
          },
          {
            ic_uuid: "4be1b184-2d25-4cd6-9aed-3b0ac4f4aff2",
            ic_lat: -90639,
            ic_lon: -686718,
            ic_city: "Sena Madureira",
            ic_states: "ACRE",
            ic_january: 4.479,
            ic_february: 4.619,
            ic_march: 4.286,
            ic_april: 4.302,
            ic_may: 3.959,
            ic_june: 4.081,
            ic_july: 4.303,
            ic_august: 4.94,
            ic_september: 5.035,
            ic_october: 5.008,
            ic_november: 4.882,
            ic_december: 4.573,
            ic_yearly: 4.539,
          },
          {
            ic_uuid: "744417f9-d2c4-4ed3-924a-309d1d95acad",
            ic_lat: -526,
            ic_lon: -440553,
            ic_city: "Senador Alexandre Costa",
            ic_states: "MARANHÃO",
            ic_january: 4.696,
            ic_february: 4.907,
            ic_march: 4.962,
            ic_april: 4.903,
            ic_may: 4.955,
            ic_june: 5.178,
            ic_july: 5.471,
            ic_august: 6.096,
            ic_september: 6.379,
            ic_october: 5.905,
            ic_november: 5.502,
            ic_december: 5.105,
            ic_yearly: 5.338,
          },
          {
            ic_uuid: "423482ca-c094-4edf-9444-a895659ed92f",
            ic_lat: -225874,
            ic_lon: -461767,
            ic_city: "Senador Amaral",
            ic_states: "MINAS GERAIS",
            ic_january: 5.073,
            ic_february: 5.37,
            ic_march: 4.65,
            ic_april: 4.385,
            ic_may: 3.776,
            ic_june: 3.537,
            ic_july: 3.753,
            ic_august: 4.688,
            ic_september: 4.818,
            ic_october: 5.19,
            ic_november: 5.162,
            ic_december: 5.479,
            ic_yearly: 4.657,
          },
          {
            ic_uuid: "e8cf36ab-5c56-46e8-a7f3-aa85c5895ca1",
            ic_lat: -167089,
            ic_lon: -490918,
            ic_city: "Senador Canedo",
            ic_states: "GOIÁS",
            ic_january: 5.504,
            ic_february: 5.66,
            ic_march: 5.189,
            ic_april: 5.123,
            ic_may: 4.837,
            ic_june: 4.605,
            ic_july: 4.785,
            ic_august: 5.665,
            ic_september: 5.561,
            ic_october: 5.616,
            ic_november: 5.369,
            ic_december: 5.49,
            ic_yearly: 5.283,
          },
          {
            ic_uuid: "0d6bf5f8-ce5f-486a-934f-c3b068a41584",
            ic_lat: -21799,
            ic_lon: -429427,
            ic_city: "Senador Cortes",
            ic_states: "MINAS GERAIS",
            ic_january: 5.713,
            ic_february: 5.923,
            ic_march: 4.938,
            ic_april: 4.266,
            ic_may: 3.574,
            ic_june: 3.336,
            ic_july: 3.546,
            ic_august: 4.256,
            ic_september: 4.623,
            ic_october: 4.945,
            ic_november: 4.76,
            ic_december: 5.549,
            ic_yearly: 4.619,
          },
          {
            ic_uuid: "938002e7-d597-4f27-871d-7a8040259d88",
            ic_lat: -6036,
            ic_lon: -356928,
            ic_city: "Senador Elói de Souza",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.888,
            ic_february: 5.87,
            ic_march: 5.906,
            ic_april: 5.516,
            ic_may: 4.973,
            ic_june: 4.539,
            ic_july: 4.705,
            ic_august: 5.424,
            ic_september: 5.806,
            ic_october: 6.005,
            ic_november: 6.192,
            ic_december: 5.882,
            ic_yearly: 5.559,
          },
          {
            ic_uuid: "3d2c5dfe-4ced-44b2-9217-a57d99b235df",
            ic_lat: -209162,
            ic_lon: -430908,
            ic_city: "Senador Firmino",
            ic_states: "MINAS GERAIS",
            ic_january: 5.601,
            ic_february: 5.892,
            ic_march: 4.853,
            ic_april: 4.268,
            ic_may: 3.667,
            ic_june: 3.52,
            ic_july: 3.728,
            ic_august: 4.492,
            ic_september: 4.8,
            ic_october: 4.997,
            ic_november: 4.756,
            ic_december: 5.464,
            ic_yearly: 4.67,
          },
          {
            ic_uuid: "b0f5ee0e-67d7-4b4d-a8a9-130e3b6bcab1",
            ic_lat: -61638,
            ic_lon: -351255,
            ic_city: "Senador Georgino Avelino",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.93,
            ic_february: 6.135,
            ic_march: 6.15,
            ic_april: 5.536,
            ic_may: 5.041,
            ic_june: 4.503,
            ic_july: 4.664,
            ic_august: 5.467,
            ic_september: 5.918,
            ic_october: 6.181,
            ic_november: 6.293,
            ic_december: 6.102,
            ic_yearly: 5.66,
          },
          {
            ic_uuid: "f4784630-71fe-4cbf-b12b-f9223799d6e3",
            ic_lat: -101501,
            ic_lon: -677367,
            ic_city: "Senador Guiomard",
            ic_states: "ACRE",
            ic_january: 4.483,
            ic_february: 4.594,
            ic_march: 4.218,
            ic_april: 4.417,
            ic_may: 3.943,
            ic_june: 4.088,
            ic_july: 4.271,
            ic_august: 4.874,
            ic_september: 5.097,
            ic_october: 5.137,
            ic_november: 4.967,
            ic_december: 4.611,
            ic_yearly: 4.558,
          },
          {
            ic_uuid: "3b11e106-a0c1-477a-a684-2f2c59647976",
            ic_lat: -221638,
            ic_lon: -461796,
            ic_city: "Senador José Bento",
            ic_states: "MINAS GERAIS",
            ic_january: 5.113,
            ic_february: 5.443,
            ic_march: 4.752,
            ic_april: 4.48,
            ic_may: 3.863,
            ic_june: 3.573,
            ic_july: 3.865,
            ic_august: 4.762,
            ic_september: 4.917,
            ic_october: 5.275,
            ic_november: 5.241,
            ic_december: 5.488,
            ic_yearly: 4.731,
          },
          {
            ic_uuid: "4f421d26-6855-4b6b-a45f-a64d8504ab90",
            ic_lat: -25864,
            ic_lon: -519491,
            ic_city: "Senador José Porfírio",
            ic_states: "PARÁ",
            ic_january: 4.265,
            ic_february: 4.326,
            ic_march: 4.34,
            ic_april: 4.377,
            ic_may: 4.401,
            ic_june: 4.581,
            ic_july: 4.7,
            ic_august: 5.028,
            ic_september: 4.916,
            ic_october: 4.819,
            ic_november: 4.649,
            ic_december: 4.266,
            ic_yearly: 4.556,
          },
          {
            ic_uuid: "533e9539-e28a-4b12-a11b-e4161ea9555d",
            ic_lat: -54465,
            ic_lon: -472963,
            ic_city: "Senador La Rocque",
            ic_states: "MARANHÃO",
            ic_january: 4.6,
            ic_february: 4.659,
            ic_march: 4.723,
            ic_april: 4.819,
            ic_may: 4.83,
            ic_june: 5.201,
            ic_july: 5.323,
            ic_august: 5.7,
            ic_september: 5.588,
            ic_october: 5.196,
            ic_november: 4.879,
            ic_december: 4.705,
            ic_yearly: 5.018,
          },
          {
            ic_uuid: "2bf79e07-7df2-4b6b-b40a-be5a867b6e5c",
            ic_lat: -17947,
            ic_lon: -43225,
            ic_city: "Senador Modestino Gonçalves",
            ic_states: "MINAS GERAIS",
            ic_january: 5.858,
            ic_february: 6.115,
            ic_march: 5.18,
            ic_april: 4.614,
            ic_may: 4.102,
            ic_june: 4.011,
            ic_july: 4.179,
            ic_august: 5.012,
            ic_september: 5.479,
            ic_october: 5.455,
            ic_november: 4.989,
            ic_december: 5.735,
            ic_yearly: 5.061,
          },
          {
            ic_uuid: "f7035987-2843-4557-a098-497e1abf26ce",
            ic_lat: -55829,
            ic_lon: -393707,
            ic_city: "Senador Pompeu",
            ic_states: "CEARÁ",
            ic_january: 5.447,
            ic_february: 5.519,
            ic_march: 5.624,
            ic_april: 5.125,
            ic_may: 5.012,
            ic_june: 4.944,
            ic_july: 5.342,
            ic_august: 6.03,
            ic_september: 6.48,
            ic_october: 6.456,
            ic_november: 6.406,
            ic_december: 5.781,
            ic_yearly: 5.68,
          },
          {
            ic_uuid: "1d5ed284-d78d-4a8b-b689-308ce7670411",
            ic_lat: -94703,
            ic_lon: -374579,
            ic_city: "Senador Rui Palmeira",
            ic_states: "ALAGOAS",
            ic_january: 6.168,
            ic_february: 6.003,
            ic_march: 5.943,
            ic_april: 5.255,
            ic_may: 4.38,
            ic_june: 4.013,
            ic_july: 4.145,
            ic_august: 4.775,
            ic_september: 5.613,
            ic_october: 5.954,
            ic_november: 6.485,
            ic_december: 6.343,
            ic_yearly: 5.423,
          },
          {
            ic_uuid: "2f4a59c6-9fac-44de-acdf-9cba49b7b9e0",
            ic_lat: -33534,
            ic_lon: -404665,
            ic_city: "Senador Sá",
            ic_states: "CEARÁ",
            ic_january: 5.078,
            ic_february: 5.197,
            ic_march: 5.16,
            ic_april: 4.898,
            ic_may: 5.242,
            ic_june: 5.191,
            ic_july: 5.443,
            ic_august: 6.075,
            ic_september: 6.497,
            ic_october: 6.455,
            ic_november: 6.161,
            ic_december: 5.456,
            ic_yearly: 5.571,
          },
          {
            ic_uuid: "df6d4ab6-6ebe-4e85-a08a-23f49850f966",
            ic_lat: -280255,
            ic_lon: -545512,
            ic_city: "Senador Salgado Filho",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.512,
            ic_february: 6.075,
            ic_march: 5.162,
            ic_april: 4.086,
            ic_may: 3.114,
            ic_june: 2.541,
            ic_july: 2.858,
            ic_august: 3.646,
            ic_september: 4.046,
            ic_october: 5.157,
            ic_november: 6.313,
            ic_december: 6.71,
            ic_yearly: 4.685,
          },
          {
            ic_uuid: "829ba120-5974-4c66-954e-11866c437cc1",
            ic_lat: -241134,
            ic_lon: -49462,
            ic_city: "Sengés",
            ic_states: "PARANÁ",
            ic_january: 5.549,
            ic_february: 5.687,
            ic_march: 5.015,
            ic_april: 4.353,
            ic_may: 3.476,
            ic_june: 3.11,
            ic_july: 3.354,
            ic_august: 4.337,
            ic_september: 4.507,
            ic_october: 5.087,
            ic_november: 5.775,
            ic_december: 6.194,
            ic_yearly: 4.704,
          },
          {
            ic_uuid: "cf9a1b20-4ebc-4f05-a82e-3540efbf2a14",
            ic_lat: -104598,
            ic_lon: -401869,
            ic_city: "Senhor do Bonfim",
            ic_states: "BAHIA",
            ic_january: 6.014,
            ic_february: 5.827,
            ic_march: 5.717,
            ic_april: 4.761,
            ic_may: 4.063,
            ic_june: 3.722,
            ic_july: 3.963,
            ic_august: 4.546,
            ic_september: 5.565,
            ic_october: 5.642,
            ic_november: 5.936,
            ic_december: 5.979,
            ic_yearly: 5.145,
          },
          {
            ic_uuid: "d7f4286f-7230-45c2-b565-a519e0b555c4",
            ic_lat: -207977,
            ic_lon: -433398,
            ic_city: "Senhora de Oliveira",
            ic_states: "MINAS GERAIS",
            ic_january: 5.521,
            ic_february: 5.913,
            ic_march: 4.82,
            ic_april: 4.305,
            ic_may: 3.721,
            ic_june: 3.57,
            ic_july: 3.777,
            ic_august: 4.608,
            ic_september: 4.846,
            ic_october: 5.048,
            ic_november: 4.679,
            ic_december: 5.434,
            ic_yearly: 4.687,
          },
          {
            ic_uuid: "50f42307-54fc-4c5a-baf8-2ec5b81c6343",
            ic_lat: -188914,
            ic_lon: -430802,
            ic_city: "Senhora do Porto",
            ic_states: "MINAS GERAIS",
            ic_january: 5.699,
            ic_february: 5.909,
            ic_march: 4.946,
            ic_april: 4.433,
            ic_may: 3.873,
            ic_june: 3.703,
            ic_july: 3.835,
            ic_august: 4.563,
            ic_september: 4.98,
            ic_october: 5.081,
            ic_november: 4.766,
            ic_december: 5.482,
            ic_yearly: 4.772,
          },
          {
            ic_uuid: "da0b5da2-51b5-4952-b751-a54fae517d87",
            ic_lat: -210356,
            ic_lon: -435816,
            ic_city: "Senhora dos Remédios",
            ic_states: "MINAS GERAIS",
            ic_january: 5.591,
            ic_february: 5.847,
            ic_march: 4.835,
            ic_april: 4.278,
            ic_may: 3.674,
            ic_june: 3.512,
            ic_july: 3.703,
            ic_august: 4.56,
            ic_september: 4.836,
            ic_october: 5.033,
            ic_november: 4.723,
            ic_december: 5.519,
            ic_yearly: 4.676,
          },
          {
            ic_uuid: "cb34206b-9e67-4987-be01-81ac3065520e",
            ic_lat: -306112,
            ic_lon: -515866,
            ic_city: "Sentinela do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.26,
            ic_february: 5.563,
            ic_march: 4.728,
            ic_april: 3.736,
            ic_may: 2.759,
            ic_june: 2.286,
            ic_july: 2.502,
            ic_august: 3.109,
            ic_september: 3.557,
            ic_october: 4.653,
            ic_november: 6.091,
            ic_december: 6.434,
            ic_yearly: 4.306,
          },
          {
            ic_uuid: "0bbbc7cf-e19a-419d-8ed7-7bfa06d34a78",
            ic_lat: -97418,
            ic_lon: -418789,
            ic_city: "Sento Sé",
            ic_states: "BAHIA",
            ic_january: 6.439,
            ic_february: 6.286,
            ic_march: 6.281,
            ic_april: 5.655,
            ic_may: 5.423,
            ic_june: 5.337,
            ic_july: 5.529,
            ic_august: 5.934,
            ic_september: 5.965,
            ic_october: 6.313,
            ic_november: 6.577,
            ic_december: 6.377,
            ic_yearly: 6.01,
          },
          {
            ic_uuid: "9927842c-4120-414d-8e2f-c0d9107f4b78",
            ic_lat: -287131,
            ic_lon: -519357,
            ic_city: "Serafina Corrêa",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.117,
            ic_february: 5.822,
            ic_march: 4.851,
            ic_april: 3.903,
            ic_may: 2.898,
            ic_june: 2.454,
            ic_july: 2.744,
            ic_august: 3.495,
            ic_september: 3.763,
            ic_october: 4.85,
            ic_november: 6.135,
            ic_december: 6.544,
            ic_yearly: 4.465,
          },
          {
            ic_uuid: "87fd764c-f36a-423a-aa59-4c2ebe7984cf",
            ic_lat: -204753,
            ic_lon: -424831,
            ic_city: "Sericita",
            ic_states: "MINAS GERAIS",
            ic_january: 5.507,
            ic_february: 5.808,
            ic_march: 4.903,
            ic_april: 4.347,
            ic_may: 3.775,
            ic_june: 3.595,
            ic_july: 3.819,
            ic_august: 4.551,
            ic_september: 4.919,
            ic_october: 5.02,
            ic_november: 4.713,
            ic_december: 5.435,
            ic_yearly: 4.699,
          },
          {
            ic_uuid: "b31795bc-c61c-41ec-ab50-500636a37b39",
            ic_lat: -117699,
            ic_lon: -630337,
            ic_city: "Seringueiras",
            ic_states: "RONDÔNIA",
            ic_january: 4.477,
            ic_february: 4.418,
            ic_march: 4.41,
            ic_april: 4.432,
            ic_may: 3.937,
            ic_june: 4.242,
            ic_july: 4.441,
            ic_august: 4.911,
            ic_september: 4.951,
            ic_october: 5.042,
            ic_november: 4.958,
            ic_december: 4.688,
            ic_yearly: 4.576,
          },
          {
            ic_uuid: "4b32c515-d4b4-4f5b-be5d-e808a6b4b440",
            ic_lat: -29383,
            ic_lon: -522689,
            ic_city: "Sério",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 5.974,
            ic_february: 5.6,
            ic_march: 4.724,
            ic_april: 3.748,
            ic_may: 2.803,
            ic_june: 2.356,
            ic_july: 2.593,
            ic_august: 3.316,
            ic_september: 3.583,
            ic_october: 4.597,
            ic_november: 6.017,
            ic_december: 6.371,
            ic_yearly: 4.307,
          },
          {
            ic_uuid: "c7078058-6e5f-4dc6-927f-20a6662c8f9c",
            ic_lat: -219138,
            ic_lon: -445183,
            ic_city: "Seritinga",
            ic_states: "MINAS GERAIS",
            ic_january: 5.463,
            ic_february: 5.69,
            ic_march: 4.903,
            ic_april: 4.473,
            ic_may: 3.783,
            ic_june: 3.565,
            ic_july: 3.778,
            ic_august: 4.666,
            ic_september: 4.888,
            ic_october: 5.216,
            ic_november: 5.111,
            ic_december: 5.532,
            ic_yearly: 4.756,
          },
          {
            ic_uuid: "adc849b5-3ed2-4327-9a38-b478fbd91874",
            ic_lat: -227531,
            ic_lon: -437159,
            ic_city: "Seropédica",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.906,
            ic_february: 6.216,
            ic_march: 4.991,
            ic_april: 4.434,
            ic_may: 3.589,
            ic_june: 3.314,
            ic_july: 3.375,
            ic_august: 4.224,
            ic_september: 4.35,
            ic_october: 4.909,
            ic_november: 4.992,
            ic_december: 5.783,
            ic_yearly: 4.674,
          },
          {
            ic_uuid: "77f3b5f3-13a9-4dda-bfa0-10a5d0b1d301",
            ic_lat: -201215,
            ic_lon: -403078,
            ic_city: "Serra",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.116,
            ic_february: 6.44,
            ic_march: 5.395,
            ic_april: 4.557,
            ic_may: 3.917,
            ic_june: 3.63,
            ic_july: 3.745,
            ic_august: 4.348,
            ic_september: 4.855,
            ic_october: 4.957,
            ic_november: 4.992,
            ic_december: 5.891,
            ic_yearly: 4.903,
          },
          {
            ic_uuid: "f16c3d65-3d5b-4b31-aa9d-f88e2077756b",
            ic_lat: -267234,
            ic_lon: -530413,
            ic_city: "Serra Alta",
            ic_states: "SANTA CATARINA",
            ic_january: 6.138,
            ic_february: 5.65,
            ic_march: 5.073,
            ic_april: 4.094,
            ic_may: 3.174,
            ic_june: 2.684,
            ic_july: 2.998,
            ic_august: 3.853,
            ic_september: 4.115,
            ic_october: 5.096,
            ic_november: 6.039,
            ic_december: 6.347,
            ic_yearly: 4.605,
          },
          {
            ic_uuid: "0b0a3455-35a4-41d5-b120-c63954e790f9",
            ic_lat: -213079,
            ic_lon: -475606,
            ic_city: "Serra Azul",
            ic_states: "SÃO PAULO",
            ic_january: 5.609,
            ic_february: 5.858,
            ic_march: 5.141,
            ic_april: 4.805,
            ic_may: 4.093,
            ic_june: 3.889,
            ic_july: 4.081,
            ic_august: 4.958,
            ic_september: 4.968,
            ic_october: 5.522,
            ic_november: 5.698,
            ic_december: 6.05,
            ic_yearly: 5.056,
          },
          {
            ic_uuid: "2742936b-9e6d-4cd9-a429-0765f49c6449",
            ic_lat: -183606,
            ic_lon: -431678,
            ic_city: "Serra Azul de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.833,
            ic_february: 6.084,
            ic_march: 5.065,
            ic_april: 4.487,
            ic_may: 3.974,
            ic_june: 3.819,
            ic_july: 3.946,
            ic_august: 4.792,
            ic_september: 5.188,
            ic_october: 5.328,
            ic_november: 4.796,
            ic_december: 5.55,
            ic_yearly: 4.906,
          },
          {
            ic_uuid: "ec564359-6f10-4def-9774-e0190deca66c",
            ic_lat: -74808,
            ic_lon: -366663,
            ic_city: "Serra Branca",
            ic_states: "PARAÍBA",
            ic_january: 5.958,
            ic_february: 5.938,
            ic_march: 5.982,
            ic_april: 5.532,
            ic_may: 4.872,
            ic_june: 4.344,
            ic_july: 4.6,
            ic_august: 5.268,
            ic_september: 6.009,
            ic_october: 6.239,
            ic_november: 6.398,
            ic_december: 6.134,
            ic_yearly: 5.606,
          },
          {
            ic_uuid: "dcaf5894-ce8a-4fe8-84ea-8cdef5ef79ca",
            ic_lat: -61059,
            ic_lon: -357124,
            ic_city: "Serra Caiada",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.7,
            ic_february: 5.803,
            ic_march: 5.787,
            ic_april: 5.474,
            ic_may: 4.981,
            ic_june: 4.501,
            ic_july: 4.679,
            ic_august: 5.407,
            ic_september: 5.755,
            ic_october: 5.928,
            ic_november: 6.127,
            ic_december: 5.805,
            ic_yearly: 5.496,
          },
          {
            ic_uuid: "a7d69c08-3933-4e0f-89c3-fc9698965cad",
            ic_lat: -66857,
            ic_lon: -354382,
            ic_city: "Serra da Raiz",
            ic_states: "PARAÍBA",
            ic_january: 5.678,
            ic_february: 5.685,
            ic_march: 5.739,
            ic_april: 5.265,
            ic_may: 4.74,
            ic_june: 4.283,
            ic_july: 4.358,
            ic_august: 5.139,
            ic_september: 5.599,
            ic_october: 5.867,
            ic_november: 6.023,
            ic_december: 5.784,
            ic_yearly: 5.346,
          },
          {
            ic_uuid: "2160511f-80ed-4ff3-b2f7-39884211af90",
            ic_lat: -194381,
            ic_lon: -458,
            ic_city: "Serra da Saudade",
            ic_states: "MINAS GERAIS",
            ic_january: 5.662,
            ic_february: 6.048,
            ic_march: 4.954,
            ic_april: 4.925,
            ic_may: 4.52,
            ic_june: 4.24,
            ic_july: 4.546,
            ic_august: 5.555,
            ic_september: 5.572,
            ic_october: 5.522,
            ic_november: 5.112,
            ic_december: 5.476,
            ic_yearly: 5.178,
          },
          {
            ic_uuid: "39f97dc5-3fbb-4906-bb35-9c7df2db54d1",
            ic_lat: -64178,
            ic_lon: -357049,
            ic_city: "Serra de São Bento",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.813,
            ic_february: 5.936,
            ic_march: 5.984,
            ic_april: 5.484,
            ic_may: 4.911,
            ic_june: 4.504,
            ic_july: 4.629,
            ic_august: 5.377,
            ic_september: 5.785,
            ic_october: 5.943,
            ic_november: 6.159,
            ic_december: 5.902,
            ic_yearly: 5.536,
          },
          {
            ic_uuid: "c5700be8-c714-4dc7-85f4-8ed94df613fd",
            ic_lat: -51716,
            ic_lon: -3704,
            ic_city: "Serra do Mel",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.922,
            ic_february: 5.881,
            ic_march: 5.807,
            ic_april: 5.462,
            ic_may: 5.305,
            ic_june: 5.003,
            ic_july: 5.224,
            ic_august: 5.918,
            ic_september: 6.396,
            ic_october: 6.488,
            ic_november: 6.521,
            ic_december: 6.121,
            ic_yearly: 5.837,
          },
          {
            ic_uuid: "c8f6e5c1-db6c-41dd-a782-6ef28e3eea25",
            ic_lat: 901,
            ic_lon: -52004,
            ic_city: "Serra do Navio",
            ic_states: "AMAPÁ",
            ic_january: 4.178,
            ic_february: 4.009,
            ic_march: 4.103,
            ic_april: 4.175,
            ic_may: 4.383,
            ic_june: 4.481,
            ic_july: 4.676,
            ic_august: 5.028,
            ic_september: 5.33,
            ic_october: 5.211,
            ic_november: 4.945,
            ic_december: 4.382,
            ic_yearly: 4.575,
          },
          {
            ic_uuid: "8e32f023-01f0-4a9e-8673-c07ecca6d356",
            ic_lat: -135421,
            ic_lon: -435773,
            ic_city: "Serra do Ramalho",
            ic_states: "BAHIA",
            ic_january: 6.681,
            ic_february: 6.602,
            ic_march: 5.967,
            ic_april: 5.645,
            ic_may: 5.111,
            ic_june: 4.883,
            ic_july: 5.139,
            ic_august: 5.639,
            ic_september: 6.057,
            ic_october: 6.461,
            ic_november: 6.007,
            ic_december: 6.496,
            ic_yearly: 5.891,
          },
          {
            ic_uuid: "07d29f4b-6dd4-4e5d-bcba-59a13fde0981",
            ic_lat: -191099,
            ic_lon: -466822,
            ic_city: "Serra do Salitre",
            ic_states: "MINAS GERAIS",
            ic_january: 5.606,
            ic_february: 5.937,
            ic_march: 4.948,
            ic_april: 4.958,
            ic_may: 4.582,
            ic_june: 4.321,
            ic_july: 4.621,
            ic_august: 5.592,
            ic_september: 5.539,
            ic_october: 5.575,
            ic_november: 5.267,
            ic_december: 5.563,
            ic_yearly: 5.209,
          },
          {
            ic_uuid: "32dfd913-1a09-4373-b790-bcaaca9f1aa6",
            ic_lat: -177876,
            ic_lon: -402456,
            ic_city: "Serra dos Aimorés",
            ic_states: "MINAS GERAIS",
            ic_january: 6.154,
            ic_february: 6.156,
            ic_march: 5.371,
            ic_april: 4.624,
            ic_may: 3.829,
            ic_june: 3.479,
            ic_july: 3.613,
            ic_august: 4.305,
            ic_september: 4.845,
            ic_october: 5.193,
            ic_november: 5.144,
            ic_december: 6.101,
            ic_yearly: 4.901,
          },
          {
            ic_uuid: "c412c6b5-f8bc-4627-899a-8a1cd7055d3e",
            ic_lat: -127594,
            ic_lon: -439508,
            ic_city: "Serra Dourada",
            ic_states: "BAHIA",
            ic_january: 6.575,
            ic_february: 6.391,
            ic_march: 5.878,
            ic_april: 5.627,
            ic_may: 5.212,
            ic_june: 5.016,
            ic_july: 5.276,
            ic_august: 5.821,
            ic_september: 6.101,
            ic_october: 6.364,
            ic_november: 5.98,
            ic_december: 6.484,
            ic_yearly: 5.894,
          },
          {
            ic_uuid: "f0098a49-d901-4965-9036-3c0b902e10fd",
            ic_lat: -72147,
            ic_lon: -383721,
            ic_city: "Serra Grande",
            ic_states: "PARAÍBA",
            ic_january: 6.158,
            ic_february: 6.106,
            ic_march: 6.061,
            ic_april: 5.788,
            ic_may: 5.232,
            ic_june: 4.967,
            ic_july: 5.201,
            ic_august: 6.029,
            ic_september: 6.59,
            ic_october: 6.64,
            ic_november: 6.814,
            ic_december: 6.441,
            ic_yearly: 6.002,
          },
          {
            ic_uuid: "b1586db5-04cf-4684-850d-25b41fe2586a",
            ic_lat: -226144,
            ic_lon: -467037,
            ic_city: "Serra Negra",
            ic_states: "SÃO PAULO",
            ic_january: 5.536,
            ic_february: 5.805,
            ic_march: 5.148,
            ic_april: 4.681,
            ic_may: 3.903,
            ic_june: 3.589,
            ic_july: 3.819,
            ic_august: 4.712,
            ic_september: 4.932,
            ic_october: 5.536,
            ic_november: 5.742,
            ic_december: 6.075,
            ic_yearly: 4.956,
          },
          {
            ic_uuid: "2b48df18-8274-465d-a975-18b55f0c4d31",
            ic_lat: -66658,
            ic_lon: -373987,
            ic_city: "Serra Negra do Norte",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.106,
            ic_february: 6.236,
            ic_march: 6.179,
            ic_april: 5.835,
            ic_may: 5.216,
            ic_june: 4.786,
            ic_july: 5.091,
            ic_august: 5.837,
            ic_september: 6.379,
            ic_october: 6.598,
            ic_november: 6.68,
            ic_december: 6.282,
            ic_yearly: 5.935,
          },
          {
            ic_uuid: "9d3f0394-59e3-4e89-8385-5b8e6928b7ca",
            ic_lat: -120901,
            ic_lon: -514029,
            ic_city: "Serra Nova Dourada",
            ic_states: "MATO GROSSO",
            ic_january: 5.08,
            ic_february: 5.193,
            ic_march: 4.981,
            ic_april: 4.922,
            ic_may: 4.978,
            ic_june: 4.85,
            ic_july: 5.185,
            ic_august: 5.751,
            ic_september: 5.367,
            ic_october: 5.285,
            ic_november: 5.168,
            ic_december: 5.215,
            ic_yearly: 5.164,
          },
          {
            ic_uuid: "3d087d8d-fb7b-4a20-9743-dd8d8a35e4f6",
            ic_lat: -121598,
            ic_lon: -39332,
            ic_city: "Serra Preta",
            ic_states: "BAHIA",
            ic_january: 6.005,
            ic_february: 5.886,
            ic_march: 5.684,
            ic_april: 4.662,
            ic_may: 3.987,
            ic_june: 3.617,
            ic_july: 3.847,
            ic_august: 4.432,
            ic_september: 5.245,
            ic_october: 5.412,
            ic_november: 5.658,
            ic_december: 5.938,
            ic_yearly: 5.031,
          },
          {
            ic_uuid: "cddfc321-b1e9-4f5d-b306-24b15041eb95",
            ic_lat: -71867,
            ic_lon: -356799,
            ic_city: "Serra Redonda",
            ic_states: "PARAÍBA",
            ic_january: 5.51,
            ic_february: 5.626,
            ic_march: 5.632,
            ic_april: 5.149,
            ic_may: 4.585,
            ic_june: 4.11,
            ic_july: 4.239,
            ic_august: 4.881,
            ic_september: 5.402,
            ic_october: 5.575,
            ic_november: 5.819,
            ic_december: 5.693,
            ic_yearly: 5.185,
          },
          {
            ic_uuid: "af9396fe-f4a9-459f-b9fa-cfe70bbda0f1",
            ic_lat: -79822,
            ic_lon: -382893,
            ic_city: "Serra Talhada",
            ic_states: "PERNAMBUCO",
            ic_january: 6.281,
            ic_february: 6.205,
            ic_march: 6.121,
            ic_april: 5.706,
            ic_may: 4.85,
            ic_june: 4.408,
            ic_july: 4.658,
            ic_august: 5.513,
            ic_september: 6.419,
            ic_october: 6.592,
            ic_november: 6.77,
            ic_december: 6.453,
            ic_yearly: 5.831,
          },
          {
            ic_uuid: "5cfbfb28-f4e8-4b0c-be7a-fe23612bed0f",
            ic_lat: -212048,
            ic_lon: -475956,
            ic_city: "Serrana",
            ic_states: "SÃO PAULO",
            ic_january: 5.602,
            ic_february: 5.81,
            ic_march: 5.114,
            ic_april: 4.809,
            ic_may: 4.109,
            ic_june: 3.899,
            ic_july: 4.095,
            ic_august: 4.995,
            ic_september: 4.997,
            ic_october: 5.535,
            ic_november: 5.668,
            ic_december: 5.981,
            ic_yearly: 5.051,
          },
          {
            ic_uuid: "c643bf4f-19c3-4c8f-8895-6cf8fc950f37",
            ic_lat: -215446,
            ic_lon: -460421,
            ic_city: "Serrania",
            ic_states: "MINAS GERAIS",
            ic_january: 5.685,
            ic_february: 5.837,
            ic_march: 4.946,
            ic_april: 4.639,
            ic_may: 3.991,
            ic_june: 3.689,
            ic_july: 3.983,
            ic_august: 4.904,
            ic_september: 5.084,
            ic_october: 5.445,
            ic_november: 5.353,
            ic_december: 5.681,
            ic_yearly: 4.936,
          },
          {
            ic_uuid: "4b0003a8-0a82-4012-9f85-d59e6010d2cd",
            ic_lat: -18527,
            ic_lon: -451211,
            ic_city: "Serrano do Maranhão",
            ic_states: "MARANHÃO",
            ic_january: 4.64,
            ic_february: 4.509,
            ic_march: 4.321,
            ic_april: 4.237,
            ic_may: 4.485,
            ic_june: 4.817,
            ic_july: 4.969,
            ic_august: 5.434,
            ic_september: 5.702,
            ic_october: 5.433,
            ic_november: 5.259,
            ic_december: 4.985,
            ic_yearly: 4.899,
          },
          {
            ic_uuid: "05951d42-f11d-4a72-8406-2f63e40f53fa",
            ic_lat: -183072,
            ic_lon: -519591,
            ic_city: "Serranópolis",
            ic_states: "GOIÁS",
            ic_january: 5.56,
            ic_february: 5.565,
            ic_march: 5.232,
            ic_april: 5.035,
            ic_may: 4.511,
            ic_june: 4.237,
            ic_july: 4.439,
            ic_august: 5.28,
            ic_september: 5.2,
            ic_october: 5.347,
            ic_november: 5.579,
            ic_december: 5.849,
            ic_yearly: 5.153,
          },
          {
            ic_uuid: "a58a3709-bd0d-4c20-b5c8-1895f6548bbc",
            ic_lat: -158115,
            ic_lon: -428717,
            ic_city: "Serranópolis de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 6.565,
            ic_february: 6.778,
            ic_march: 5.781,
            ic_april: 5.421,
            ic_may: 4.794,
            ic_june: 4.533,
            ic_july: 4.753,
            ic_august: 5.524,
            ic_september: 6.069,
            ic_october: 6.18,
            ic_november: 5.686,
            ic_december: 6.301,
            ic_yearly: 5.699,
          },
          {
            ic_uuid: "5974828c-97b4-4d7d-ba5c-900c075455e2",
            ic_lat: -253804,
            ic_lon: -540523,
            ic_city: "Serranópolis do Iguaçu",
            ic_states: "PARANÁ",
            ic_january: 6.258,
            ic_february: 5.724,
            ic_march: 5.198,
            ic_april: 4.231,
            ic_may: 3.26,
            ic_june: 2.831,
            ic_july: 3.071,
            ic_august: 4.053,
            ic_september: 4.296,
            ic_october: 5.234,
            ic_november: 6.051,
            ic_december: 6.486,
            ic_yearly: 4.724,
          },
          {
            ic_uuid: "74d57272-ac1b-45d3-88d5-811e8fcf4b58",
            ic_lat: -218862,
            ic_lon: -445129,
            ic_city: "Serranos",
            ic_states: "MINAS GERAIS",
            ic_january: 5.463,
            ic_february: 5.69,
            ic_march: 4.903,
            ic_april: 4.473,
            ic_may: 3.783,
            ic_june: 3.565,
            ic_july: 3.778,
            ic_august: 4.666,
            ic_september: 4.888,
            ic_october: 5.216,
            ic_november: 5.111,
            ic_december: 5.532,
            ic_yearly: 4.756,
          },
          {
            ic_uuid: "724ba7aa-7f66-4bb4-aa68-fb7d38cc3377",
            ic_lat: -68156,
            ic_lon: -356281,
            ic_city: "Serraria",
            ic_states: "PARAÍBA",
            ic_january: 5.669,
            ic_february: 5.79,
            ic_march: 5.802,
            ic_april: 5.29,
            ic_may: 4.698,
            ic_june: 4.194,
            ic_july: 4.325,
            ic_august: 5.118,
            ic_september: 5.598,
            ic_october: 5.838,
            ic_november: 6.043,
            ic_december: 5.855,
            ic_yearly: 5.352,
          },
          {
            ic_uuid: "dc88f7b7-4791-4ad8-a3a6-b48a9da22c25",
            ic_lat: -116588,
            ic_lon: -390103,
            ic_city: "Serrinha",
            ic_states: "BAHIA",
            ic_january: 6.193,
            ic_february: 5.925,
            ic_march: 5.766,
            ic_april: 4.771,
            ic_may: 4.025,
            ic_june: 3.689,
            ic_july: 3.939,
            ic_august: 4.451,
            ic_september: 5.322,
            ic_october: 5.576,
            ic_november: 5.926,
            ic_december: 6.11,
            ic_yearly: 5.141,
          },
          {
            ic_uuid: "f7a4954f-6584-442f-89a3-1eb001f5fbec",
            ic_lat: -62781,
            ic_lon: -354972,
            ic_city: "Serrinha",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.744,
            ic_february: 5.9,
            ic_march: 6.026,
            ic_april: 5.422,
            ic_may: 4.898,
            ic_june: 4.481,
            ic_july: 4.655,
            ic_august: 5.298,
            ic_september: 5.747,
            ic_october: 5.995,
            ic_november: 6.226,
            ic_december: 5.857,
            ic_yearly: 5.521,
          },
          {
            ic_uuid: "7bbad775-eea1-453f-bf69-e4a96da7485f",
            ic_lat: -61113,
            ic_lon: -379551,
            ic_city: "Serrinha dos Pintos",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.102,
            ic_february: 6.008,
            ic_march: 6.03,
            ic_april: 5.763,
            ic_may: 5.318,
            ic_june: 5.057,
            ic_july: 5.417,
            ic_august: 6.103,
            ic_september: 6.521,
            ic_october: 6.679,
            ic_november: 6.712,
            ic_december: 6.19,
            ic_yearly: 5.992,
          },
          {
            ic_uuid: "fd1dcfda-a3bf-400d-b177-cf4d05a4728e",
            ic_lat: -79408,
            ic_lon: -392954,
            ic_city: "Serrita",
            ic_states: "PERNAMBUCO",
            ic_january: 6.029,
            ic_february: 5.894,
            ic_march: 5.781,
            ic_april: 5.426,
            ic_may: 4.718,
            ic_june: 4.443,
            ic_july: 4.701,
            ic_august: 5.522,
            ic_september: 6.437,
            ic_october: 6.468,
            ic_november: 6.623,
            ic_december: 6.282,
            ic_yearly: 5.694,
          },
          {
            ic_uuid: "158d94ae-70b8-4806-aaa5-e3a359a25f3e",
            ic_lat: -185996,
            ic_lon: -433748,
            ic_city: "Serro",
            ic_states: "MINAS GERAIS",
            ic_january: 5.729,
            ic_february: 6.015,
            ic_march: 4.988,
            ic_april: 4.536,
            ic_may: 3.994,
            ic_june: 3.829,
            ic_july: 3.991,
            ic_august: 4.741,
            ic_september: 5.172,
            ic_october: 5.286,
            ic_november: 4.8,
            ic_december: 5.448,
            ic_yearly: 4.877,
          },
          {
            ic_uuid: "416aeb97-d4ac-498f-9ade-9a98299e1694",
            ic_lat: -114166,
            ic_lon: -40301,
            ic_city: "Serrolândia",
            ic_states: "BAHIA",
            ic_january: 6.039,
            ic_february: 5.817,
            ic_march: 5.61,
            ic_april: 4.627,
            ic_may: 3.896,
            ic_june: 3.632,
            ic_july: 3.798,
            ic_august: 4.432,
            ic_september: 5.328,
            ic_october: 5.343,
            ic_november: 5.605,
            ic_december: 5.775,
            ic_yearly: 4.992,
          },
          {
            ic_uuid: "7aca9933-f254-4f29-952f-a40e7f6a4f97",
            ic_lat: -230386,
            ic_lon: -508173,
            ic_city: "Sertaneja",
            ic_states: "PARANÁ",
            ic_january: 6.021,
            ic_february: 5.971,
            ic_march: 5.283,
            ic_april: 4.705,
            ic_may: 3.755,
            ic_june: 3.423,
            ic_july: 3.633,
            ic_august: 4.558,
            ic_september: 4.659,
            ic_october: 5.429,
            ic_november: 6.039,
            ic_december: 6.476,
            ic_yearly: 4.996,
          },
          {
            ic_uuid: "5b6779d4-e7c1-40fe-a01c-1b8be84e6f1e",
            ic_lat: -80689,
            ic_lon: -372687,
            ic_city: "Sertânia",
            ic_states: "PERNAMBUCO",
            ic_january: 6.268,
            ic_february: 6.127,
            ic_march: 6.039,
            ic_april: 5.581,
            ic_may: 4.802,
            ic_june: 4.3,
            ic_july: 4.475,
            ic_august: 5.262,
            ic_september: 6.184,
            ic_october: 6.434,
            ic_november: 6.757,
            ic_december: 6.522,
            ic_yearly: 5.729,
          },
          {
            ic_uuid: "093fe6bb-9cf2-4da0-b08f-391548b976d8",
            ic_lat: -230575,
            ic_lon: -510404,
            ic_city: "Sertanópolis",
            ic_states: "PARANÁ",
            ic_january: 5.916,
            ic_february: 5.837,
            ic_march: 5.267,
            ic_april: 4.68,
            ic_may: 3.694,
            ic_june: 3.405,
            ic_july: 3.601,
            ic_august: 4.558,
            ic_september: 4.711,
            ic_october: 5.371,
            ic_november: 6.022,
            ic_december: 6.373,
            ic_yearly: 4.953,
          },
          {
            ic_uuid: "5c0d747f-d91a-4b8d-9544-bc580ae19f67",
            ic_lat: -279803,
            ic_lon: -522592,
            ic_city: "Sertão",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.242,
            ic_february: 5.82,
            ic_march: 4.961,
            ic_april: 4.026,
            ic_may: 3.046,
            ic_june: 2.527,
            ic_july: 2.891,
            ic_august: 3.661,
            ic_september: 3.91,
            ic_october: 5.039,
            ic_november: 6.16,
            ic_december: 6.501,
            ic_yearly: 4.565,
          },
          {
            ic_uuid: "7dac3f53-08a1-4954-a8fe-47b61dd0e8da",
            ic_lat: -304567,
            ic_lon: -516022,
            ic_city: "Sertão Santana",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.148,
            ic_february: 5.531,
            ic_march: 4.677,
            ic_april: 3.728,
            ic_may: 2.742,
            ic_june: 2.316,
            ic_july: 2.506,
            ic_august: 3.136,
            ic_september: 3.511,
            ic_october: 4.625,
            ic_november: 6.039,
            ic_december: 6.423,
            ic_yearly: 4.282,
          },
          {
            ic_uuid: "28bacf4f-57e7-4fba-9df0-7ef7be71c7e2",
            ic_lat: -67517,
            ic_lon: -354375,
            ic_city: "Sertãozinho",
            ic_states: "PARAÍBA",
            ic_january: 5.543,
            ic_february: 5.596,
            ic_march: 5.688,
            ic_april: 5.216,
            ic_may: 4.636,
            ic_june: 4.192,
            ic_july: 4.279,
            ic_august: 5.027,
            ic_september: 5.484,
            ic_october: 5.758,
            ic_november: 6.0,
            ic_december: 5.743,
            ic_yearly: 5.263,
          },
          {
            ic_uuid: "6f505f3a-51c1-4b5d-b597-295e2485cedb",
            ic_lat: -211321,
            ic_lon: -479879,
            ic_city: "Sertãozinho",
            ic_states: "SÃO PAULO",
            ic_january: 5.748,
            ic_february: 5.974,
            ic_march: 5.204,
            ic_april: 4.852,
            ic_may: 4.154,
            ic_june: 3.912,
            ic_july: 4.123,
            ic_august: 5.002,
            ic_september: 4.997,
            ic_october: 5.552,
            ic_november: 5.829,
            ic_december: 6.125,
            ic_yearly: 5.123,
          },
          {
            ic_uuid: "e40d5b48-ca13-4b61-9014-3aa455b7ffda",
            ic_lat: -243825,
            ic_lon: -479284,
            ic_city: "Sete Barras",
            ic_states: "SÃO PAULO",
            ic_january: 5.225,
            ic_february: 5.237,
            ic_march: 4.361,
            ic_april: 3.828,
            ic_may: 3.016,
            ic_june: 2.618,
            ic_july: 2.624,
            ic_august: 3.269,
            ic_september: 3.406,
            ic_october: 3.755,
            ic_november: 4.665,
            ic_december: 5.32,
            ic_yearly: 3.944,
          },
          {
            ic_uuid: "b87811e6-9ea5-441a-8393-94b2861cf3fa",
            ic_lat: -28131,
            ic_lon: -544629,
            ic_city: "Sete de Setembro",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.532,
            ic_february: 6.066,
            ic_march: 5.153,
            ic_april: 4.061,
            ic_may: 3.104,
            ic_june: 2.552,
            ic_july: 2.864,
            ic_august: 3.638,
            ic_september: 4.03,
            ic_october: 5.146,
            ic_november: 6.308,
            ic_december: 6.708,
            ic_yearly: 4.68,
          },
          {
            ic_uuid: "cecd956e-572e-4b7f-95c2-702c5f983c4d",
            ic_lat: -194574,
            ic_lon: -442416,
            ic_city: "Sete Lagoas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.928,
            ic_february: 6.1,
            ic_march: 5.193,
            ic_april: 4.903,
            ic_may: 4.35,
            ic_june: 4.121,
            ic_july: 4.454,
            ic_august: 5.267,
            ic_september: 5.632,
            ic_october: 5.66,
            ic_november: 5.412,
            ic_december: 5.66,
            ic_yearly: 5.224,
          },
          {
            ic_uuid: "332f45c9-0aad-48c5-b70d-33d06b50115d",
            ic_lat: -23971,
            ic_lon: -550403,
            ic_city: "Sete Quedas",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 6.105,
            ic_february: 5.737,
            ic_march: 5.331,
            ic_april: 4.317,
            ic_may: 3.397,
            ic_june: 2.961,
            ic_july: 3.213,
            ic_august: 4.202,
            ic_september: 4.502,
            ic_october: 5.341,
            ic_november: 6.035,
            ic_december: 6.49,
            ic_yearly: 4.802,
          },
          {
            ic_uuid: "146da4a4-8e48-42ad-a4ad-b4fe2f2828dd",
            ic_lat: -176007,
            ic_lon: -42159,
            ic_city: "Setubinha",
            ic_states: "MINAS GERAIS",
            ic_january: 5.98,
            ic_february: 6.137,
            ic_march: 5.165,
            ic_april: 4.479,
            ic_may: 3.872,
            ic_june: 3.679,
            ic_july: 3.887,
            ic_august: 4.616,
            ic_september: 5.138,
            ic_october: 5.365,
            ic_november: 4.905,
            ic_december: 5.747,
            ic_yearly: 4.914,
          },
          {
            ic_uuid: "e72e53e6-da88-4c4c-b715-e637caa11beb",
            ic_lat: -274367,
            ic_lon: -521222,
            ic_city: "Severiano de Almeida",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.377,
            ic_february: 5.884,
            ic_march: 5.146,
            ic_april: 4.061,
            ic_may: 3.061,
            ic_june: 2.562,
            ic_july: 2.882,
            ic_august: 3.745,
            ic_september: 3.99,
            ic_october: 5.11,
            ic_november: 6.279,
            ic_december: 6.627,
            ic_yearly: 4.644,
          },
          {
            ic_uuid: "a52f6d69-ce5b-4c10-9b19-c4b1deb217db",
            ic_lat: -57771,
            ic_lon: -379573,
            ic_city: "Severiano Melo",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.007,
            ic_february: 6.083,
            ic_march: 6.08,
            ic_april: 5.728,
            ic_may: 5.366,
            ic_june: 5.047,
            ic_july: 5.407,
            ic_august: 6.075,
            ic_september: 6.433,
            ic_october: 6.665,
            ic_november: 6.655,
            ic_december: 6.149,
            ic_yearly: 5.974,
          },
          {
            ic_uuid: "7fe8938f-a7cf-4612-9a06-e3ce3d7f7370",
            ic_lat: -208112,
            ic_lon: -488058,
            ic_city: "Severínia",
            ic_states: "SÃO PAULO",
            ic_january: 5.802,
            ic_february: 5.96,
            ic_march: 5.214,
            ic_april: 4.862,
            ic_may: 4.179,
            ic_june: 3.939,
            ic_july: 4.171,
            ic_august: 5.073,
            ic_september: 5.094,
            ic_october: 5.581,
            ic_november: 5.93,
            ic_december: 6.126,
            ic_yearly: 5.161,
          },
          {
            ic_uuid: "31a72b03-19e8-4440-b4d6-3c699eba5808",
            ic_lat: -28596,
            ic_lon: -494319,
            ic_city: "Siderópolis",
            ic_states: "SANTA CATARINA",
            ic_january: 5.244,
            ic_february: 4.921,
            ic_march: 4.305,
            ic_april: 3.699,
            ic_may: 2.902,
            ic_june: 2.465,
            ic_july: 2.697,
            ic_august: 3.399,
            ic_september: 3.444,
            ic_october: 4.098,
            ic_november: 5.365,
            ic_december: 5.568,
            ic_yearly: 4.009,
          },
          {
            ic_uuid: "ab8a1014-3549-4fef-b708-ff3b6da7a282",
            ic_lat: -209307,
            ic_lon: -549697,
            ic_city: "Sidrolândia",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.739,
            ic_february: 5.778,
            ic_march: 5.372,
            ic_april: 4.763,
            ic_may: 3.904,
            ic_june: 3.636,
            ic_july: 3.78,
            ic_august: 4.658,
            ic_september: 4.914,
            ic_october: 5.46,
            ic_november: 5.979,
            ic_december: 6.241,
            ic_yearly: 5.019,
          },
          {
            ic_uuid: "4e678d51-afc1-473b-b512-cd1dd2ab423e",
            ic_lat: -4917,
            ic_lon: -417315,
            ic_city: "Sigefredo Pacheco",
            ic_states: "PIAUÍ",
            ic_january: 5.274,
            ic_february: 5.401,
            ic_march: 5.535,
            ic_april: 5.23,
            ic_may: 5.265,
            ic_june: 5.242,
            ic_july: 5.524,
            ic_august: 6.166,
            ic_september: 6.611,
            ic_october: 6.519,
            ic_november: 6.376,
            ic_december: 5.776,
            ic_yearly: 5.743,
          },
          {
            ic_uuid: "c78cc44a-1162-415d-bce2-1a2385735792",
            ic_lat: -226579,
            ic_lon: -423965,
            ic_city: "Silva Jardim",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.116,
            ic_february: 6.372,
            ic_march: 5.078,
            ic_april: 4.458,
            ic_may: 3.657,
            ic_june: 3.428,
            ic_july: 3.436,
            ic_august: 4.284,
            ic_september: 4.479,
            ic_october: 5.032,
            ic_november: 4.998,
            ic_december: 5.781,
            ic_yearly: 4.76,
          },
          {
            ic_uuid: "02ee6006-a60c-45dd-8171-0a4d1f326c10",
            ic_lat: -166604,
            ic_lon: -486087,
            ic_city: "Silvânia",
            ic_states: "GOIÁS",
            ic_january: 5.505,
            ic_february: 5.747,
            ic_march: 5.104,
            ic_april: 5.074,
            ic_may: 4.777,
            ic_june: 4.566,
            ic_july: 4.796,
            ic_august: 5.721,
            ic_september: 5.516,
            ic_october: 5.602,
            ic_november: 5.205,
            ic_december: 5.509,
            ic_yearly: 5.26,
          },
          {
            ic_uuid: "9f83147b-b855-47db-9401-00adf55f7a69",
            ic_lat: -111475,
            ic_lon: -481698,
            ic_city: "Silvanópolis",
            ic_states: "TOCANTINS",
            ic_january: 5.357,
            ic_february: 5.351,
            ic_march: 4.969,
            ic_april: 5.124,
            ic_may: 5.119,
            ic_june: 5.052,
            ic_july: 5.289,
            ic_august: 5.966,
            ic_september: 5.878,
            ic_october: 5.484,
            ic_november: 5.237,
            ic_december: 5.302,
            ic_yearly: 5.344,
          },
          {
            ic_uuid: "c852cc81-bb52-488e-bd0f-c0bf60dab3b8",
            ic_lat: -29642,
            ic_lon: -535859,
            ic_city: "Silveira Martins",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.278,
            ic_february: 5.903,
            ic_march: 4.96,
            ic_april: 3.857,
            ic_may: 2.844,
            ic_june: 2.357,
            ic_july: 2.596,
            ic_august: 3.357,
            ic_september: 3.757,
            ic_october: 4.879,
            ic_november: 6.247,
            ic_december: 6.703,
            ic_yearly: 4.478,
          },
          {
            ic_uuid: "98233d3e-5555-4d84-8559-81d94f9f096a",
            ic_lat: -21162,
            ic_lon: -432132,
            ic_city: "Silveirânia",
            ic_states: "MINAS GERAIS",
            ic_january: 5.497,
            ic_february: 5.811,
            ic_march: 4.802,
            ic_april: 4.229,
            ic_may: 3.614,
            ic_june: 3.474,
            ic_july: 3.609,
            ic_august: 4.41,
            ic_september: 4.716,
            ic_october: 4.915,
            ic_november: 4.683,
            ic_december: 5.449,
            ic_yearly: 4.601,
          },
          {
            ic_uuid: "710010cc-cb9e-44cc-9396-6307054f3f47",
            ic_lat: -226643,
            ic_lon: -448525,
            ic_city: "Silveiras",
            ic_states: "SÃO PAULO",
            ic_january: 5.724,
            ic_february: 5.919,
            ic_march: 5.013,
            ic_april: 4.515,
            ic_may: 3.757,
            ic_june: 3.539,
            ic_july: 3.636,
            ic_august: 4.563,
            ic_september: 4.746,
            ic_october: 5.251,
            ic_november: 5.365,
            ic_december: 5.991,
            ic_yearly: 4.835,
          },
          {
            ic_uuid: "68101232-504d-4ccb-a7fc-77eaf2e6d0f3",
            ic_lat: -28366,
            ic_lon: -582146,
            ic_city: "Silves",
            ic_states: "AMAZONAS",
            ic_january: 3.971,
            ic_february: 4.092,
            ic_march: 4.056,
            ic_april: 3.884,
            ic_may: 3.974,
            ic_june: 4.562,
            ic_july: 4.507,
            ic_august: 5.158,
            ic_september: 5.172,
            ic_october: 4.928,
            ic_november: 4.755,
            ic_december: 4.358,
            ic_yearly: 4.452,
          },
          {
            ic_uuid: "bc29c512-15d4-4163-922c-dd1507831558",
            ic_lat: -220279,
            ic_lon: -458389,
            ic_city: "Silvianópolis",
            ic_states: "MINAS GERAIS",
            ic_january: 5.247,
            ic_february: 5.615,
            ic_march: 4.756,
            ic_april: 4.508,
            ic_may: 3.886,
            ic_june: 3.647,
            ic_july: 3.878,
            ic_august: 4.808,
            ic_september: 4.936,
            ic_october: 5.299,
            ic_november: 5.164,
            ic_december: 5.533,
            ic_yearly: 4.773,
          },
          {
            ic_uuid: "fc862363-9126-4504-891f-012aaad4571d",
            ic_lat: -107391,
            ic_lon: -378101,
            ic_city: "Simão Dias",
            ic_states: "SERGIPE",
            ic_january: 6.112,
            ic_february: 5.827,
            ic_march: 5.762,
            ic_april: 5.084,
            ic_may: 4.253,
            ic_june: 3.866,
            ic_july: 4.033,
            ic_august: 4.554,
            ic_september: 5.279,
            ic_october: 5.59,
            ic_november: 6.148,
            ic_december: 6.118,
            ic_yearly: 5.219,
          },
          {
            ic_uuid: "33f4a4d9-18e9-4a05-aa27-7e3ed5bddaf6",
            ic_lat: -219644,
            ic_lon: -433092,
            ic_city: "Simão Pereira",
            ic_states: "MINAS GERAIS",
            ic_january: 5.588,
            ic_february: 5.865,
            ic_march: 4.882,
            ic_april: 4.286,
            ic_may: 3.561,
            ic_june: 3.377,
            ic_july: 3.523,
            ic_august: 4.245,
            ic_september: 4.568,
            ic_october: 4.89,
            ic_november: 4.822,
            ic_december: 5.568,
            ic_yearly: 4.598,
          },
          {
            ic_uuid: "dd11c124-81f4-4eee-b3c8-268b5c27ab05",
            ic_lat: -7595,
            ic_lon: -408182,
            ic_city: "Simões",
            ic_states: "PIAUÍ",
            ic_january: 5.735,
            ic_february: 5.519,
            ic_march: 5.601,
            ic_april: 5.351,
            ic_may: 5.227,
            ic_june: 5.192,
            ic_july: 5.585,
            ic_august: 6.269,
            ic_september: 6.705,
            ic_october: 6.607,
            ic_november: 6.378,
            ic_december: 6.047,
            ic_yearly: 5.851,
          },
          {
            ic_uuid: "046f1a52-085f-4129-aeb2-375f2e3d8f6c",
            ic_lat: -12787,
            ic_lon: -384033,
            ic_city: "Simões Filho",
            ic_states: "BAHIA",
            ic_january: 6.172,
            ic_february: 5.974,
            ic_march: 5.699,
            ic_april: 4.529,
            ic_may: 3.94,
            ic_june: 3.656,
            ic_july: 3.888,
            ic_august: 4.416,
            ic_september: 5.185,
            ic_october: 5.583,
            ic_november: 5.846,
            ic_december: 6.207,
            ic_yearly: 5.091,
          },
          {
            ic_uuid: "c81a7ef6-7ce4-4de8-96b2-d0b06753674e",
            ic_lat: -144614,
            ic_lon: -464862,
            ic_city: "Simolândia",
            ic_states: "GOIÁS",
            ic_january: 6.082,
            ic_february: 6.127,
            ic_march: 5.568,
            ic_april: 5.492,
            ic_may: 5.045,
            ic_june: 4.867,
            ic_july: 5.214,
            ic_august: 5.871,
            ic_september: 5.962,
            ic_october: 5.876,
            ic_november: 5.401,
            ic_december: 5.953,
            ic_yearly: 5.621,
          },
          {
            ic_uuid: "6f6d7231-adc9-47a0-bd1e-498ed339e707",
            ic_lat: -201346,
            ic_lon: -420094,
            ic_city: "Simonésia",
            ic_states: "MINAS GERAIS",
            ic_january: 5.351,
            ic_february: 5.612,
            ic_march: 4.593,
            ic_april: 4.195,
            ic_may: 3.623,
            ic_june: 3.483,
            ic_july: 3.678,
            ic_august: 4.402,
            ic_september: 4.666,
            ic_october: 4.659,
            ic_november: 4.443,
            ic_december: 5.131,
            ic_yearly: 4.486,
          },
          {
            ic_uuid: "01b8d688-e9b7-48d3-ad92-fe1d3d4e8a51",
            ic_lat: -78595,
            ic_lon: -419086,
            ic_city: "Simplício Mendes",
            ic_states: "PIAUÍ",
            ic_january: 5.775,
            ic_february: 5.578,
            ic_march: 5.599,
            ic_april: 5.356,
            ic_may: 5.256,
            ic_june: 5.258,
            ic_july: 5.57,
            ic_august: 6.325,
            ic_september: 6.658,
            ic_october: 6.525,
            ic_november: 6.315,
            ic_december: 5.993,
            ic_yearly: 5.851,
          },
          {
            ic_uuid: "fe9a9f83-9d9d-4d9f-9881-31d8ff34982e",
            ic_lat: -29539,
            ic_lon: -525219,
            ic_city: "Sinimbu",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.096,
            ic_february: 5.683,
            ic_march: 4.779,
            ic_april: 3.776,
            ic_may: 2.806,
            ic_june: 2.364,
            ic_july: 2.6,
            ic_august: 3.323,
            ic_september: 3.589,
            ic_october: 4.694,
            ic_november: 6.029,
            ic_december: 6.463,
            ic_yearly: 4.35,
          },
          {
            ic_uuid: "723a8875-a8db-4444-a4b1-49ff0578b82f",
            ic_lat: -118609,
            ic_lon: -555095,
            ic_city: "Sinop",
            ic_states: "MATO GROSSO",
            ic_january: 5.049,
            ic_february: 4.981,
            ic_march: 4.942,
            ic_april: 4.821,
            ic_may: 4.613,
            ic_june: 4.589,
            ic_july: 4.873,
            ic_august: 5.197,
            ic_september: 5.068,
            ic_october: 5.291,
            ic_november: 5.056,
            ic_december: 5.212,
            ic_yearly: 4.974,
          },
          {
            ic_uuid: "204a33cc-fc27-4186-b549-266f3daf717d",
            ic_lat: -23688,
            ic_lon: -498308,
            ic_city: "Siqueira Campos",
            ic_states: "PARANÁ",
            ic_january: 5.691,
            ic_february: 5.75,
            ic_march: 5.16,
            ic_april: 4.518,
            ic_may: 3.583,
            ic_june: 3.179,
            ic_july: 3.446,
            ic_august: 4.46,
            ic_september: 4.625,
            ic_october: 5.263,
            ic_november: 5.946,
            ic_december: 6.265,
            ic_yearly: 4.824,
          },
          {
            ic_uuid: "181f8879-67a4-4054-b3c9-add081607cf1",
            ic_lat: -85882,
            ic_lon: -351129,
            ic_city: "Sirinhaém",
            ic_states: "PERNAMBUCO",
            ic_january: 5.736,
            ic_february: 5.915,
            ic_march: 5.952,
            ic_april: 5.055,
            ic_may: 4.398,
            ic_june: 4.031,
            ic_july: 4.146,
            ic_august: 4.794,
            ic_september: 5.449,
            ic_october: 5.775,
            ic_november: 6.039,
            ic_december: 6.037,
            ic_yearly: 5.277,
          },
          {
            ic_uuid: "bfdc6795-f241-48f8-b35d-dd2cb55c36a1",
            ic_lat: -106045,
            ic_lon: -371135,
            ic_city: "Siriri",
            ic_states: "SERGIPE",
            ic_january: 6.192,
            ic_february: 5.997,
            ic_march: 5.865,
            ic_april: 5.048,
            ic_may: 4.254,
            ic_june: 3.971,
            ic_july: 4.055,
            ic_august: 4.608,
            ic_september: 5.358,
            ic_october: 5.703,
            ic_november: 6.197,
            ic_december: 6.209,
            ic_yearly: 5.288,
          },
          {
            ic_uuid: "a5596a62-f364-40cb-8033-f01c3a1e5f0e",
            ic_lat: -148049,
            ic_lon: -462538,
            ic_city: "Sítio d'Abadia",
            ic_states: "GOIÁS",
            ic_january: 5.935,
            ic_february: 5.991,
            ic_march: 5.417,
            ic_april: 5.34,
            ic_may: 4.928,
            ic_june: 4.812,
            ic_july: 5.251,
            ic_august: 5.899,
            ic_september: 6.022,
            ic_october: 5.831,
            ic_november: 5.293,
            ic_december: 5.837,
            ic_yearly: 5.546,
          },
          {
            ic_uuid: "0e6a19b8-2ede-49f6-81ee-f421598c33cf",
            ic_lat: -130831,
            ic_lon: -43469,
            ic_city: "Sítio do Mato",
            ic_states: "BAHIA",
            ic_january: 6.649,
            ic_february: 6.572,
            ic_march: 6.047,
            ic_april: 5.691,
            ic_may: 5.15,
            ic_june: 4.941,
            ic_july: 5.197,
            ic_august: 5.708,
            ic_september: 6.087,
            ic_october: 6.4,
            ic_november: 5.945,
            ic_december: 6.487,
            ic_yearly: 5.906,
          },
          {
            ic_uuid: "919dd1d7-27ff-437c-8fd6-ba11e269743f",
            ic_lat: -103685,
            ic_lon: -382046,
            ic_city: "Sítio do Quinto",
            ic_states: "BAHIA",
            ic_january: 6.126,
            ic_february: 5.849,
            ic_march: 5.782,
            ic_april: 5.085,
            ic_may: 4.262,
            ic_june: 3.887,
            ic_july: 4.057,
            ic_august: 4.541,
            ic_september: 5.376,
            ic_october: 5.617,
            ic_november: 6.163,
            ic_december: 6.148,
            ic_yearly: 5.241,
          },
          {
            ic_uuid: "9cdf9eda-7764-46dd-abed-b032a242f442",
            ic_lat: -58764,
            ic_lon: -467037,
            ic_city: "Sítio Novo",
            ic_states: "MARANHÃO",
            ic_january: 4.742,
            ic_february: 4.819,
            ic_march: 4.858,
            ic_april: 4.827,
            ic_may: 4.874,
            ic_june: 5.165,
            ic_july: 5.394,
            ic_august: 5.885,
            ic_september: 5.824,
            ic_october: 5.348,
            ic_november: 4.907,
            ic_december: 4.871,
            ic_yearly: 5.126,
          },
          {
            ic_uuid: "8ee386e9-1ac1-4bf4-a4b7-d34b9d4a318d",
            ic_lat: -6104,
            ic_lon: -35911,
            ic_city: "Sítio Novo",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.728,
            ic_february: 5.873,
            ic_march: 5.796,
            ic_april: 5.492,
            ic_may: 4.97,
            ic_june: 4.498,
            ic_july: 4.705,
            ic_august: 5.351,
            ic_september: 5.808,
            ic_october: 5.958,
            ic_november: 6.104,
            ic_december: 5.688,
            ic_yearly: 5.498,
          },
          {
            ic_uuid: "fc544665-2ecb-42c9-a8cc-65a0e7280b66",
            ic_lat: -56016,
            ic_lon: -476385,
            ic_city: "Sítio Novo do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 4.593,
            ic_february: 4.734,
            ic_march: 4.805,
            ic_april: 4.824,
            ic_may: 4.83,
            ic_june: 5.125,
            ic_july: 5.27,
            ic_august: 5.752,
            ic_september: 5.554,
            ic_october: 5.136,
            ic_november: 4.888,
            ic_december: 4.711,
            ic_yearly: 5.019,
          },
          {
            ic_uuid: "5e2faef6-7ca8-4711-99bf-c80a4acdf37d",
            ic_lat: -94687,
            ic_lon: -407945,
            ic_city: "Sobradinho",
            ic_states: "BAHIA",
            ic_january: 6.408,
            ic_february: 6.089,
            ic_march: 6.01,
            ic_april: 5.246,
            ic_may: 4.944,
            ic_june: 4.709,
            ic_july: 4.979,
            ic_august: 5.681,
            ic_september: 6.334,
            ic_october: 6.425,
            ic_november: 6.581,
            ic_december: 6.333,
            ic_yearly: 5.811,
          },
          {
            ic_uuid: "df33b4d5-e71e-482c-8536-6ba7373a7750",
            ic_lat: -294199,
            ic_lon: -530331,
            ic_city: "Sobradinho",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.291,
            ic_february: 5.937,
            ic_march: 5.019,
            ic_april: 3.911,
            ic_may: 2.891,
            ic_june: 2.406,
            ic_july: 2.66,
            ic_august: 3.417,
            ic_september: 3.746,
            ic_october: 4.972,
            ic_november: 6.289,
            ic_december: 6.726,
            ic_yearly: 4.522,
          },
          {
            ic_uuid: "a0ad1b99-ba06-452c-93fe-27b79b15b6b6",
            ic_lat: -71447,
            ic_lon: -35236,
            ic_city: "Sobrado",
            ic_states: "PARAÍBA",
            ic_january: 5.7,
            ic_february: 5.85,
            ic_march: 5.929,
            ic_april: 5.305,
            ic_may: 4.674,
            ic_june: 4.234,
            ic_july: 4.326,
            ic_august: 5.102,
            ic_september: 5.548,
            ic_october: 5.832,
            ic_november: 6.102,
            ic_december: 5.955,
            ic_yearly: 5.38,
          },
          {
            ic_uuid: "51538fbe-ba17-4105-a274-50c7fe4cfe3b",
            ic_lat: -36895,
            ic_lon: -403485,
            ic_city: "Sobral",
            ic_states: "CEARÁ",
            ic_january: 5.186,
            ic_february: 5.308,
            ic_march: 5.261,
            ic_april: 4.899,
            ic_may: 5.001,
            ic_june: 4.909,
            ic_july: 5.195,
            ic_august: 5.877,
            ic_september: 6.397,
            ic_october: 6.307,
            ic_november: 6.113,
            ic_december: 5.443,
            ic_yearly: 5.491,
          },
          {
            ic_uuid: "2a707aaa-5af6-4e25-a28a-b6a7a6697b6a",
            ic_lat: -19235,
            ic_lon: -42098,
            ic_city: "Sobrália",
            ic_states: "MINAS GERAIS",
            ic_january: 5.946,
            ic_february: 6.109,
            ic_march: 5.129,
            ic_april: 4.561,
            ic_may: 3.858,
            ic_june: 3.632,
            ic_july: 3.715,
            ic_august: 4.39,
            ic_september: 4.865,
            ic_october: 5.1,
            ic_november: 4.884,
            ic_december: 5.775,
            ic_yearly: 4.83,
          },
          {
            ic_uuid: "58959906-e098-4f97-83ce-a05a8d5219c5",
            ic_lat: -225907,
            ic_lon: -465255,
            ic_city: "Socorro",
            ic_states: "SÃO PAULO",
            ic_january: 5.491,
            ic_february: 5.744,
            ic_march: 5.093,
            ic_april: 4.637,
            ic_may: 3.881,
            ic_june: 3.577,
            ic_july: 3.799,
            ic_august: 4.727,
            ic_september: 4.936,
            ic_october: 5.523,
            ic_november: 5.677,
            ic_december: 5.942,
            ic_yearly: 4.919,
          },
          {
            ic_uuid: "5d2d340d-381f-46fb-b76c-cc26a1b18f98",
            ic_lat: -78646,
            ic_lon: -425027,
            ic_city: "Socorro do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.606,
            ic_february: 5.518,
            ic_march: 5.486,
            ic_april: 5.309,
            ic_may: 5.283,
            ic_june: 5.285,
            ic_july: 5.608,
            ic_august: 6.335,
            ic_september: 6.628,
            ic_october: 6.503,
            ic_november: 6.094,
            ic_december: 5.819,
            ic_yearly: 5.79,
          },
          {
            ic_uuid: "ecb7fab8-34c1-465d-adaf-6cb240c10043",
            ic_lat: -67605,
            ic_lon: -356587,
            ic_city: "Solânea",
            ic_states: "PARAÍBA",
            ic_january: 5.669,
            ic_february: 5.79,
            ic_march: 5.802,
            ic_april: 5.29,
            ic_may: 4.698,
            ic_june: 4.194,
            ic_july: 4.325,
            ic_august: 5.118,
            ic_september: 5.598,
            ic_october: 5.838,
            ic_november: 6.043,
            ic_december: 5.855,
            ic_yearly: 5.352,
          },
          {
            ic_uuid: "687dfec7-eafe-4c36-8f07-847cf27966ae",
            ic_lat: -70591,
            ic_lon: -363629,
            ic_city: "Soledade",
            ic_states: "PARAÍBA",
            ic_january: 5.771,
            ic_february: 5.825,
            ic_march: 5.866,
            ic_april: 5.393,
            ic_may: 4.76,
            ic_june: 4.258,
            ic_july: 4.439,
            ic_august: 5.15,
            ic_september: 5.825,
            ic_october: 6.106,
            ic_november: 6.31,
            ic_december: 6.036,
            ic_yearly: 5.478,
          },
          {
            ic_uuid: "d5fc4bd6-5f67-4176-bbd4-4aae42e74efa",
            ic_lat: -288311,
            ic_lon: -525135,
            ic_city: "Soledade",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.268,
            ic_february: 5.852,
            ic_march: 5.016,
            ic_april: 3.958,
            ic_may: 2.983,
            ic_june: 2.463,
            ic_july: 2.766,
            ic_august: 3.526,
            ic_september: 3.829,
            ic_october: 5.033,
            ic_november: 6.287,
            ic_december: 6.69,
            ic_yearly: 4.556,
          },
          {
            ic_uuid: "2775abf6-baff-43f0-a0a6-7f91e9261899",
            ic_lat: -220559,
            ic_lon: -450468,
            ic_city: "Soledade de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.434,
            ic_february: 5.759,
            ic_march: 4.957,
            ic_april: 4.609,
            ic_may: 3.914,
            ic_june: 3.634,
            ic_july: 3.844,
            ic_august: 4.725,
            ic_september: 5.025,
            ic_october: 5.437,
            ic_november: 5.37,
            ic_december: 5.684,
            ic_yearly: 4.866,
          },
          {
            ic_uuid: "5ed74667-b836-4eec-833f-fa18e710a6f4",
            ic_lat: -7601,
            ic_lon: -376488,
            ic_city: "Solidão",
            ic_states: "PERNAMBUCO",
            ic_january: 6.152,
            ic_february: 6.174,
            ic_march: 6.142,
            ic_april: 5.796,
            ic_may: 5.016,
            ic_june: 4.653,
            ic_july: 4.827,
            ic_august: 5.707,
            ic_september: 6.449,
            ic_october: 6.583,
            ic_november: 6.723,
            ic_december: 6.329,
            ic_yearly: 5.879,
          },
          {
            ic_uuid: "3c193ba1-6489-4fa5-8426-e7a97b497a55",
            ic_lat: -57193,
            ic_lon: -39011,
            ic_city: "Solonópole",
            ic_states: "CEARÁ",
            ic_january: 5.636,
            ic_february: 5.682,
            ic_march: 5.789,
            ic_april: 5.335,
            ic_may: 5.09,
            ic_june: 5.025,
            ic_july: 5.361,
            ic_august: 6.038,
            ic_september: 6.508,
            ic_october: 6.617,
            ic_november: 6.563,
            ic_december: 6.043,
            ic_yearly: 5.807,
          },
          {
            ic_uuid: "d3c87fb2-b036-4273-ba7a-275daed39a0f",
            ic_lat: -291085,
            ic_lon: -496332,
            ic_city: "Sombrio",
            ic_states: "SANTA CATARINA",
            ic_january: 5.748,
            ic_february: 5.362,
            ic_march: 4.581,
            ic_april: 3.841,
            ic_may: 2.98,
            ic_june: 2.441,
            ic_july: 2.686,
            ic_august: 3.368,
            ic_september: 3.52,
            ic_october: 4.325,
            ic_november: 5.71,
            ic_december: 6.034,
            ic_yearly: 4.216,
          },
          {
            ic_uuid: "ba71bc1a-1c72-44ea-9deb-16ebbbf20357",
            ic_lat: -175787,
            ic_lon: -547538,
            ic_city: "Sonora",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.43,
            ic_february: 5.503,
            ic_march: 5.098,
            ic_april: 4.749,
            ic_may: 4.372,
            ic_june: 4.196,
            ic_july: 4.388,
            ic_august: 5.209,
            ic_september: 5.023,
            ic_october: 5.261,
            ic_november: 5.577,
            ic_december: 5.802,
            ic_yearly: 5.051,
          },
          {
            ic_uuid: "dfed79ba-603d-41b9-9a86-df0c81dcf39d",
            ic_lat: -191902,
            ic_lon: -400977,
            ic_city: "Sooretama",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.115,
            ic_february: 6.095,
            ic_march: 5.28,
            ic_april: 4.456,
            ic_may: 3.865,
            ic_june: 3.535,
            ic_july: 3.629,
            ic_august: 4.273,
            ic_september: 4.782,
            ic_october: 4.929,
            ic_november: 4.986,
            ic_december: 5.913,
            ic_yearly: 4.821,
          },
          {
            ic_uuid: "58e5d699-d753-4de6-ab47-6680a7ee4e67",
            ic_lat: -234974,
            ic_lon: -474455,
            ic_city: "Sorocaba",
            ic_states: "SÃO PAULO",
            ic_january: 5.532,
            ic_february: 5.731,
            ic_march: 4.969,
            ic_april: 4.429,
            ic_may: 3.616,
            ic_june: 3.362,
            ic_july: 3.463,
            ic_august: 4.442,
            ic_september: 4.628,
            ic_october: 5.213,
            ic_november: 5.61,
            ic_december: 6.108,
            ic_yearly: 4.758,
          },
          {
            ic_uuid: "37339d76-808f-4b7f-b9e4-f6300cfe02c6",
            ic_lat: -12543,
            ic_lon: -557215,
            ic_city: "Sorriso",
            ic_states: "MATO GROSSO",
            ic_january: 5.184,
            ic_february: 5.05,
            ic_march: 4.989,
            ic_april: 4.92,
            ic_may: 4.753,
            ic_june: 4.627,
            ic_july: 4.882,
            ic_august: 5.231,
            ic_september: 5.108,
            ic_october: 5.309,
            ic_november: 5.156,
            ic_december: 5.265,
            ic_yearly: 5.04,
          },
          {
            ic_uuid: "1c88e760-433d-4424-8922-dc02a8376953",
            ic_lat: -67663,
            ic_lon: -362474,
            ic_city: "Sossêgo",
            ic_states: "PARAÍBA",
            ic_january: 5.838,
            ic_february: 5.751,
            ic_march: 5.795,
            ic_april: 5.369,
            ic_may: 4.8,
            ic_june: 4.335,
            ic_july: 4.547,
            ic_august: 5.268,
            ic_september: 5.82,
            ic_october: 6.146,
            ic_november: 6.295,
            ic_december: 5.982,
            ic_yearly: 5.496,
          },
          {
            ic_uuid: "818bc87f-91f3-4266-90d1-9cfc084379fd",
            ic_lat: -7237,
            ic_lon: -485156,
            ic_city: "Soure",
            ic_states: "PARÁ",
            ic_january: 4.726,
            ic_february: 4.351,
            ic_march: 4.38,
            ic_april: 4.47,
            ic_may: 4.86,
            ic_june: 5.078,
            ic_july: 5.168,
            ic_august: 5.381,
            ic_september: 5.665,
            ic_october: 5.596,
            ic_november: 5.532,
            ic_december: 5.154,
            ic_yearly: 5.03,
          },
          {
            ic_uuid: "3059442e-6d16-4f86-aa48-d1aeae81207b",
            ic_lat: -67593,
            ic_lon: -38228,
            ic_city: "Sousa",
            ic_states: "PARAÍBA",
            ic_january: 6.195,
            ic_february: 6.188,
            ic_march: 6.201,
            ic_april: 5.874,
            ic_may: 5.331,
            ic_june: 5.045,
            ic_july: 5.292,
            ic_august: 6.036,
            ic_september: 6.496,
            ic_october: 6.691,
            ic_november: 6.834,
            ic_december: 6.434,
            ic_yearly: 6.052,
          },
          {
            ic_uuid: "6474563e-6082-4cc7-9c85-e058fac0675f",
            ic_lat: -120885,
            ic_lon: -416431,
            ic_city: "Souto Soares",
            ic_states: "BAHIA",
            ic_january: 6.067,
            ic_february: 5.928,
            ic_march: 5.694,
            ic_april: 4.849,
            ic_may: 4.272,
            ic_june: 3.985,
            ic_july: 4.166,
            ic_august: 4.904,
            ic_september: 5.822,
            ic_october: 5.989,
            ic_november: 5.808,
            ic_december: 6.179,
            ic_yearly: 5.305,
          },
          {
            ic_uuid: "e2b40d58-bf0b-432a-b388-750f652b1716",
            ic_lat: -119872,
            ic_lon: -489132,
            ic_city: "Sucupira",
            ic_states: "TOCANTINS",
            ic_january: 5.394,
            ic_february: 5.504,
            ic_march: 5.1,
            ic_april: 5.146,
            ic_may: 5.069,
            ic_june: 4.845,
            ic_july: 5.141,
            ic_august: 5.698,
            ic_september: 5.821,
            ic_october: 5.552,
            ic_november: 5.244,
            ic_december: 5.387,
            ic_yearly: 5.325,
          },
          {
            ic_uuid: "506e98d4-81fc-40f0-ab79-ed1fd8e5472d",
            ic_lat: -64788,
            ic_lon: -441922,
            ic_city: "Sucupira do Norte",
            ic_states: "MARANHÃO",
            ic_january: 4.691,
            ic_february: 4.911,
            ic_march: 4.853,
            ic_april: 4.963,
            ic_may: 5.129,
            ic_june: 5.41,
            ic_july: 5.675,
            ic_august: 6.277,
            ic_september: 6.555,
            ic_october: 5.966,
            ic_november: 5.424,
            ic_december: 5.064,
            ic_yearly: 5.41,
          },
          {
            ic_uuid: "92073483-b278-424b-b90b-9f18cf77abdf",
            ic_lat: -64162,
            ic_lon: -435451,
            ic_city: "Sucupira do Riachão",
            ic_states: "MARANHÃO",
            ic_january: 4.824,
            ic_february: 5.065,
            ic_march: 4.99,
            ic_april: 5.004,
            ic_may: 5.096,
            ic_june: 5.36,
            ic_july: 5.686,
            ic_august: 6.307,
            ic_september: 6.509,
            ic_october: 6.089,
            ic_november: 5.678,
            ic_december: 5.175,
            ic_yearly: 5.482,
          },
          {
            ic_uuid: "436a0ec4-d409-4f70-9c39-685529f69575",
            ic_lat: -206877,
            ic_lon: -509242,
            ic_city: "Sud Mennucci",
            ic_states: "SÃO PAULO",
            ic_january: 5.888,
            ic_february: 5.983,
            ic_march: 5.388,
            ic_april: 4.862,
            ic_may: 4.098,
            ic_june: 3.757,
            ic_july: 3.992,
            ic_august: 4.928,
            ic_september: 4.94,
            ic_october: 5.652,
            ic_november: 6.028,
            ic_december: 6.339,
            ic_yearly: 5.155,
          },
          {
            ic_uuid: "2b1d3edd-7062-4101-8fe6-3c0c585fcfab",
            ic_lat: -267356,
            ic_lon: -529644,
            ic_city: "Sul Brasil",
            ic_states: "SANTA CATARINA",
            ic_january: 6.146,
            ic_february: 5.666,
            ic_march: 5.094,
            ic_april: 4.103,
            ic_may: 3.148,
            ic_june: 2.656,
            ic_july: 3.014,
            ic_august: 3.86,
            ic_september: 4.116,
            ic_october: 5.112,
            ic_november: 6.085,
            ic_december: 6.337,
            ic_yearly: 4.611,
          },
          {
            ic_uuid: "f5ae6e85-5647-4fe9-ae05-6608fb134931",
            ic_lat: -25707,
            ic_lon: -527303,
            ic_city: "Sulina",
            ic_states: "PARANÁ",
            ic_january: 6.228,
            ic_february: 5.756,
            ic_march: 5.24,
            ic_april: 4.287,
            ic_may: 3.308,
            ic_june: 2.879,
            ic_july: 3.105,
            ic_august: 4.092,
            ic_september: 4.3,
            ic_october: 5.266,
            ic_november: 6.195,
            ic_december: 6.388,
            ic_yearly: 4.754,
          },
          {
            ic_uuid: "740c6fb4-3a8e-4003-924a-23a7ec89741b",
            ic_lat: -228209,
            ic_lon: -472732,
            ic_city: "Sumaré",
            ic_states: "SÃO PAULO",
            ic_january: 5.641,
            ic_february: 5.781,
            ic_march: 5.157,
            ic_april: 4.638,
            ic_may: 3.793,
            ic_june: 3.557,
            ic_july: 3.703,
            ic_august: 4.661,
            ic_september: 4.844,
            ic_october: 5.475,
            ic_november: 5.734,
            ic_december: 6.165,
            ic_yearly: 4.929,
          },
          {
            ic_uuid: "dc374d49-5f2b-40d6-927a-7ce7ed366f53",
            ic_lat: -76725,
            ic_lon: -368828,
            ic_city: "Sumé",
            ic_states: "PARAÍBA",
            ic_january: 6.118,
            ic_february: 6.053,
            ic_march: 6.032,
            ic_april: 5.636,
            ic_may: 4.885,
            ic_june: 4.401,
            ic_july: 4.573,
            ic_august: 5.282,
            ic_september: 6.138,
            ic_october: 6.378,
            ic_november: 6.574,
            ic_december: 6.277,
            ic_yearly: 5.695,
          },
          {
            ic_uuid: "c60855b4-e41a-4342-9da7-230287aaeb4e",
            ic_lat: -220489,
            ic_lon: -426765,
            ic_city: "Sumidouro",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.921,
            ic_february: 6.199,
            ic_march: 5.191,
            ic_april: 4.528,
            ic_may: 3.671,
            ic_june: 3.38,
            ic_july: 3.555,
            ic_august: 4.324,
            ic_september: 4.633,
            ic_october: 4.965,
            ic_november: 4.916,
            ic_december: 5.789,
            ic_yearly: 4.756,
          },
          {
            ic_uuid: "5de1c237-2a9f-4318-8a3a-ee3226cadcfc",
            ic_lat: -78471,
            ic_lon: -357605,
            ic_city: "Surubim",
            ic_states: "PERNAMBUCO",
            ic_january: 5.793,
            ic_february: 5.727,
            ic_march: 5.807,
            ic_april: 5.241,
            ic_may: 4.52,
            ic_june: 4.082,
            ic_july: 4.244,
            ic_august: 4.932,
            ic_september: 5.539,
            ic_october: 5.75,
            ic_november: 6.061,
            ic_december: 5.904,
            ic_yearly: 5.3,
          },
          {
            ic_uuid: "76799839-3955-4c92-b7ba-96e643897612",
            ic_lat: -70283,
            ic_lon: -414058,
            ic_city: "Sussuapara",
            ic_states: "PIAUÍ",
            ic_january: 5.556,
            ic_february: 5.455,
            ic_march: 5.552,
            ic_april: 5.331,
            ic_may: 5.137,
            ic_june: 5.103,
            ic_july: 5.515,
            ic_august: 6.347,
            ic_september: 6.733,
            ic_october: 6.491,
            ic_november: 6.347,
            ic_december: 5.974,
            ic_yearly: 5.795,
          },
          {
            ic_uuid: "325ccb37-61ab-41a7-a540-8ec7511c4261",
            ic_lat: -204985,
            ic_lon: -510272,
            ic_city: "Suzanápolis",
            ic_states: "SÃO PAULO",
            ic_january: 5.932,
            ic_february: 6.051,
            ic_march: 5.416,
            ic_april: 4.917,
            ic_may: 4.168,
            ic_june: 3.829,
            ic_july: 4.049,
            ic_august: 5.01,
            ic_september: 4.956,
            ic_october: 5.647,
            ic_november: 6.026,
            ic_december: 6.328,
            ic_yearly: 5.194,
          },
          {
            ic_uuid: "57e7eac6-44ef-4de3-ae36-255bd310c65f",
            ic_lat: -235453,
            ic_lon: -463116,
            ic_city: "Suzano",
            ic_states: "SÃO PAULO",
            ic_january: 5.29,
            ic_february: 5.504,
            ic_march: 4.643,
            ic_april: 4.116,
            ic_may: 3.346,
            ic_june: 3.109,
            ic_july: 3.229,
            ic_august: 4.105,
            ic_september: 4.203,
            ic_october: 4.722,
            ic_november: 5.113,
            ic_december: 5.661,
            ic_yearly: 4.42,
          },
          {
            ic_uuid: "e915dc82-aff4-4af7-b01e-56fc465ff43c",
            ic_lat: -296435,
            ic_lon: -516827,
            ic_city: "Tabaí",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.173,
            ic_february: 5.741,
            ic_march: 4.822,
            ic_april: 3.781,
            ic_may: 2.785,
            ic_june: 2.312,
            ic_july: 2.534,
            ic_august: 3.244,
            ic_september: 3.555,
            ic_october: 4.612,
            ic_november: 6.113,
            ic_december: 6.531,
            ic_yearly: 4.35,
          },
          {
            ic_uuid: "823698fc-3f60-4203-9fc6-ea2572abfa73",
            ic_lat: -113089,
            ic_lon: -568233,
            ic_city: "Tabaporã",
            ic_states: "MATO GROSSO",
            ic_january: 4.841,
            ic_february: 4.822,
            ic_march: 4.687,
            ic_april: 4.576,
            ic_may: 4.558,
            ic_june: 4.632,
            ic_july: 4.876,
            ic_august: 5.359,
            ic_september: 5.038,
            ic_october: 5.119,
            ic_november: 4.882,
            ic_december: 5.019,
            ic_yearly: 4.867,
          },
          {
            ic_uuid: "913ca266-6526-49bf-b949-1e0bbb7a17da",
            ic_lat: -209606,
            ic_lon: -490311,
            ic_city: "Tabapuã",
            ic_states: "SÃO PAULO",
            ic_january: 5.808,
            ic_february: 5.9,
            ic_march: 5.214,
            ic_april: 4.822,
            ic_may: 4.109,
            ic_june: 3.888,
            ic_july: 4.108,
            ic_august: 5.006,
            ic_september: 5.088,
            ic_october: 5.598,
            ic_november: 5.883,
            ic_december: 6.133,
            ic_yearly: 5.13,
          },
          {
            ic_uuid: "7f5eab48-5432-4103-b1fd-7b1591d807d9",
            ic_lat: -4242,
            ic_lon: -699388,
            ic_city: "Tabatinga",
            ic_states: "AMAZONAS",
            ic_january: 4.275,
            ic_february: 4.679,
            ic_march: 4.508,
            ic_april: 4.362,
            ic_may: 4.017,
            ic_june: 4.121,
            ic_july: 4.097,
            ic_august: 4.863,
            ic_september: 5.041,
            ic_october: 4.886,
            ic_november: 4.787,
            ic_december: 4.375,
            ic_yearly: 4.501,
          },
          {
            ic_uuid: "c1034e8f-55eb-49e3-8de0-5109c471a348",
            ic_lat: -217295,
            ic_lon: -486903,
            ic_city: "Tabatinga",
            ic_states: "SÃO PAULO",
            ic_january: 5.777,
            ic_february: 5.907,
            ic_march: 5.134,
            ic_april: 4.733,
            ic_may: 3.988,
            ic_june: 3.769,
            ic_july: 3.949,
            ic_august: 4.865,
            ic_september: 4.966,
            ic_october: 5.6,
            ic_november: 5.859,
            ic_december: 6.184,
            ic_yearly: 5.061,
          },
          {
            ic_uuid: "eaee2ac2-e042-4d72-bc1d-7ffdd2b51a06",
            ic_lat: -75841,
            ic_lon: -37538,
            ic_city: "Tabira",
            ic_states: "PERNAMBUCO",
            ic_january: 6.174,
            ic_february: 6.224,
            ic_march: 6.141,
            ic_april: 5.793,
            ic_may: 5.002,
            ic_june: 4.6,
            ic_july: 4.758,
            ic_august: 5.616,
            ic_september: 6.396,
            ic_october: 6.521,
            ic_november: 6.732,
            ic_december: 6.359,
            ic_yearly: 5.86,
          },
          {
            ic_uuid: "9d31f0b5-21f0-4f49-98a3-fdb488ff25cf",
            ic_lat: -236028,
            ic_lon: -467534,
            ic_city: "Taboão da Serra",
            ic_states: "SÃO PAULO",
            ic_january: 5.28,
            ic_february: 5.522,
            ic_march: 4.73,
            ic_april: 4.186,
            ic_may: 3.429,
            ic_june: 3.167,
            ic_july: 3.256,
            ic_august: 4.201,
            ic_september: 4.226,
            ic_october: 4.744,
            ic_november: 5.215,
            ic_december: 5.758,
            ic_yearly: 4.476,
          },
          {
            ic_uuid: "a86c2c98-72a5-4837-b942-76cc49d8f686",
            ic_lat: -127031,
            ic_lon: -440078,
            ic_city: "Tabocas do Brejo Velho",
            ic_states: "BAHIA",
            ic_january: 6.458,
            ic_february: 6.35,
            ic_march: 5.836,
            ic_april: 5.613,
            ic_may: 5.238,
            ic_june: 5.08,
            ic_july: 5.352,
            ic_august: 5.886,
            ic_september: 6.157,
            ic_october: 6.351,
            ic_november: 5.857,
            ic_december: 6.367,
            ic_yearly: 5.879,
          },
          {
            ic_uuid: "7de88cd3-1544-4602-b192-c40f928f83f2",
            ic_lat: -59199,
            ic_lon: -38037,
            ic_city: "Taboleiro Grande",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.955,
            ic_february: 6.078,
            ic_march: 6.068,
            ic_april: 5.733,
            ic_may: 5.349,
            ic_june: 5.018,
            ic_july: 5.361,
            ic_august: 6.021,
            ic_september: 6.453,
            ic_october: 6.649,
            ic_november: 6.66,
            ic_december: 6.164,
            ic_yearly: 5.959,
          },
          {
            ic_uuid: "b5d9b8e7-6727-4cc8-bed1-aca94ed0d83d",
            ic_lat: -213637,
            ic_lon: -432384,
            ic_city: "Tabuleiro",
            ic_states: "MINAS GERAIS",
            ic_january: 5.519,
            ic_february: 5.904,
            ic_march: 4.862,
            ic_april: 4.216,
            ic_may: 3.609,
            ic_june: 3.431,
            ic_july: 3.592,
            ic_august: 4.382,
            ic_september: 4.684,
            ic_october: 4.958,
            ic_november: 4.719,
            ic_december: 5.579,
            ic_yearly: 4.621,
          },
          {
            ic_uuid: "a2594cdd-4c5a-4e69-a597-bfb3971f41b8",
            ic_lat: -52439,
            ic_lon: -381285,
            ic_city: "Tabuleiro do Norte",
            ic_states: "CEARÁ",
            ic_january: 5.77,
            ic_february: 5.821,
            ic_march: 5.943,
            ic_april: 5.466,
            ic_may: 5.245,
            ic_june: 5.057,
            ic_july: 5.347,
            ic_august: 6.03,
            ic_september: 6.391,
            ic_october: 6.583,
            ic_november: 6.433,
            ic_december: 5.986,
            ic_yearly: 5.84,
          },
          {
            ic_uuid: "0b93176b-3af0-4d0b-998d-c31041e4b221",
            ic_lat: -83169,
            ic_lon: -362919,
            ic_city: "Tacaimbó",
            ic_states: "PERNAMBUCO",
            ic_january: 5.829,
            ic_february: 5.671,
            ic_march: 5.73,
            ic_april: 5.223,
            ic_may: 4.443,
            ic_june: 3.988,
            ic_july: 4.062,
            ic_august: 4.794,
            ic_september: 5.517,
            ic_october: 5.8,
            ic_november: 6.169,
            ic_december: 5.985,
            ic_yearly: 5.267,
          },
          {
            ic_uuid: "bf0e0378-131f-4eeb-b3d2-ceb78d62555b",
            ic_lat: -91034,
            ic_lon: -381498,
            ic_city: "Tacaratu",
            ic_states: "PERNAMBUCO",
            ic_january: 6.382,
            ic_february: 6.117,
            ic_march: 6.056,
            ic_april: 5.397,
            ic_may: 4.62,
            ic_june: 4.135,
            ic_july: 4.321,
            ic_august: 5.085,
            ic_september: 6.071,
            ic_october: 6.292,
            ic_november: 6.704,
            ic_december: 6.471,
            ic_yearly: 5.637,
          },
          {
            ic_uuid: "437a8ea3-fe01-44b8-9288-2d56a52e7e2b",
            ic_lat: -2239,
            ic_lon: -51285,
            ic_city: "Taciba",
            ic_states: "SÃO PAULO",
            ic_january: 5.897,
            ic_february: 5.891,
            ic_march: 5.381,
            ic_april: 4.743,
            ic_may: 3.834,
            ic_june: 3.534,
            ic_july: 3.734,
            ic_august: 4.707,
            ic_september: 4.809,
            ic_october: 5.504,
            ic_november: 6.086,
            ic_december: 6.405,
            ic_yearly: 5.044,
          },
          {
            ic_uuid: "532c4afd-9a16-4c37-a140-111a54ee5c8d",
            ic_lat: -6488,
            ic_lon: -35637,
            ic_city: "Tacima",
            ic_states: "PARAÍBA",
            ic_january: 5.607,
            ic_february: 5.729,
            ic_march: 5.789,
            ic_april: 5.368,
            ic_may: 4.838,
            ic_june: 4.378,
            ic_july: 4.537,
            ic_august: 5.224,
            ic_september: 5.592,
            ic_october: 5.75,
            ic_november: 5.978,
            ic_december: 5.681,
            ic_yearly: 5.373,
          },
          {
            ic_uuid: "fde2756f-2cc8-4081-bfec-92c12e1c2d45",
            ic_lat: -236364,
            ic_lon: -550146,
            ic_city: "Tacuru",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.997,
            ic_february: 5.698,
            ic_march: 5.275,
            ic_april: 4.358,
            ic_may: 3.456,
            ic_june: 3.052,
            ic_july: 3.237,
            ic_august: 4.218,
            ic_september: 4.569,
            ic_october: 5.313,
            ic_november: 5.996,
            ic_december: 6.417,
            ic_yearly: 4.799,
          },
          {
            ic_uuid: "d807aa61-6619-4137-aac9-1af1dbe53814",
            ic_lat: -23452,
            ic_lon: -494089,
            ic_city: "Taguaí",
            ic_states: "SÃO PAULO",
            ic_january: 5.756,
            ic_february: 5.84,
            ic_march: 5.192,
            ic_april: 4.515,
            ic_may: 3.585,
            ic_june: 3.252,
            ic_july: 3.45,
            ic_august: 4.43,
            ic_september: 4.606,
            ic_october: 5.264,
            ic_november: 5.895,
            ic_december: 6.306,
            ic_yearly: 4.841,
          },
          {
            ic_uuid: "e3cdd95f-698d-4ca3-b489-2397a93b2d14",
            ic_lat: -123981,
            ic_lon: -464346,
            ic_city: "Taguatinga",
            ic_states: "TOCANTINS",
            ic_january: 5.747,
            ic_february: 5.568,
            ic_march: 5.277,
            ic_april: 5.212,
            ic_may: 5.233,
            ic_june: 5.167,
            ic_july: 5.48,
            ic_august: 6.089,
            ic_september: 6.126,
            ic_october: 5.793,
            ic_november: 5.344,
            ic_december: 5.761,
            ic_yearly: 5.566,
          },
          {
            ic_uuid: "e213fc0c-8067-42b3-97e9-4fa78a6442d0",
            ic_lat: -211436,
            ic_lon: -485116,
            ic_city: "Taiaçu",
            ic_states: "SÃO PAULO",
            ic_january: 5.82,
            ic_february: 6.004,
            ic_march: 5.197,
            ic_april: 4.799,
            ic_may: 4.123,
            ic_june: 3.894,
            ic_july: 4.12,
            ic_august: 4.958,
            ic_september: 5.072,
            ic_october: 5.594,
            ic_november: 5.891,
            ic_december: 6.131,
            ic_yearly: 5.134,
          },
          {
            ic_uuid: "9933d44a-6a33-45c7-8589-5f68feb8375e",
            ic_lat: -29462,
            ic_lon: -489493,
            ic_city: "Tailândia",
            ic_states: "PARÁ",
            ic_january: 4.445,
            ic_february: 4.517,
            ic_march: 4.67,
            ic_april: 4.692,
            ic_may: 4.707,
            ic_june: 4.875,
            ic_july: 4.983,
            ic_august: 5.207,
            ic_september: 5.117,
            ic_october: 4.908,
            ic_november: 4.676,
            ic_december: 4.491,
            ic_yearly: 4.774,
          },
          {
            ic_uuid: "27f7a241-d834-404c-813d-a9f5ffeec7b2",
            ic_lat: -271187,
            ic_lon: -500041,
            ic_city: "Taió",
            ic_states: "SANTA CATARINA",
            ic_january: 5.373,
            ic_february: 5.101,
            ic_march: 4.549,
            ic_april: 3.608,
            ic_may: 2.835,
            ic_june: 2.346,
            ic_july: 2.52,
            ic_august: 3.334,
            ic_september: 3.478,
            ic_october: 4.02,
            ic_november: 5.318,
            ic_december: 5.553,
            ic_yearly: 4.003,
          },
          {
            ic_uuid: "7dd501d4-1db8-4b57-a033-7aa8957a412a",
            ic_lat: -158111,
            ic_lon: -422263,
            ic_city: "Taiobeiras",
            ic_states: "MINAS GERAIS",
            ic_january: 6.093,
            ic_february: 6.282,
            ic_march: 5.443,
            ic_april: 4.723,
            ic_may: 4.151,
            ic_june: 3.804,
            ic_july: 4.107,
            ic_august: 4.868,
            ic_september: 5.641,
            ic_october: 5.697,
            ic_november: 5.388,
            ic_december: 6.083,
            ic_yearly: 5.19,
          },
          {
            ic_uuid: "bab57341-95ba-41e4-970b-093f3332afde",
            ic_lat: -121922,
            ic_lon: -469914,
            ic_city: "Taipas do Tocantins",
            ic_states: "TOCANTINS",
            ic_january: 5.81,
            ic_february: 5.791,
            ic_march: 5.419,
            ic_april: 5.399,
            ic_may: 5.283,
            ic_june: 5.156,
            ic_july: 5.389,
            ic_august: 5.945,
            ic_september: 6.136,
            ic_october: 5.873,
            ic_november: 5.555,
            ic_december: 5.79,
            ic_yearly: 5.629,
          },
          {
            ic_uuid: "9081859f-a76b-4ba7-8116-59978ea3d490",
            ic_lat: -56254,
            ic_lon: -355988,
            ic_city: "Taipu",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.906,
            ic_february: 6.034,
            ic_march: 5.99,
            ic_april: 5.537,
            ic_may: 5.013,
            ic_june: 4.641,
            ic_july: 4.745,
            ic_august: 5.437,
            ic_september: 5.937,
            ic_october: 6.085,
            ic_november: 6.236,
            ic_december: 5.867,
            ic_yearly: 5.619,
          },
          {
            ic_uuid: "38a2d1cd-d4a1-491b-9628-58e161e475dc",
            ic_lat: -21124,
            ic_lon: -48452,
            ic_city: "Taiúva",
            ic_states: "SÃO PAULO",
            ic_january: 5.763,
            ic_february: 5.988,
            ic_march: 5.213,
            ic_april: 4.818,
            ic_may: 4.125,
            ic_june: 3.889,
            ic_july: 4.122,
            ic_august: 4.979,
            ic_september: 5.06,
            ic_october: 5.592,
            ic_november: 5.902,
            ic_december: 6.145,
            ic_yearly: 5.133,
          },
          {
            ic_uuid: "313dedb4-8b2a-4daa-8561-6ff4e4a84023",
            ic_lat: -128045,
            ic_lon: -49093,
            ic_city: "Talismã",
            ic_states: "TOCANTINS",
            ic_january: 5.345,
            ic_february: 5.464,
            ic_march: 5.099,
            ic_april: 5.118,
            ic_may: 5.016,
            ic_june: 4.896,
            ic_july: 5.163,
            ic_august: 5.784,
            ic_september: 5.753,
            ic_october: 5.594,
            ic_november: 5.182,
            ic_december: 5.337,
            ic_yearly: 5.312,
          },
          {
            ic_uuid: "dbdb326e-6669-44f7-b7ec-dfbd50b87402",
            ic_lat: -87571,
            ic_lon: -351036,
            ic_city: "Tamandaré",
            ic_states: "PERNAMBUCO",
            ic_january: 5.832,
            ic_february: 5.917,
            ic_march: 6.001,
            ic_april: 5.075,
            ic_may: 4.332,
            ic_june: 4.095,
            ic_july: 4.173,
            ic_august: 4.855,
            ic_september: 5.566,
            ic_october: 5.894,
            ic_november: 6.129,
            ic_december: 6.176,
            ic_yearly: 5.337,
          },
          {
            ic_uuid: "c425e356-ff84-48ca-bb60-0f6be9237671",
            ic_lat: -237208,
            ic_lon: -510995,
            ic_city: "Tamarana",
            ic_states: "PARANÁ",
            ic_january: 5.743,
            ic_february: 5.634,
            ic_march: 5.175,
            ic_april: 4.549,
            ic_may: 3.605,
            ic_june: 3.267,
            ic_july: 3.482,
            ic_august: 4.458,
            ic_september: 4.619,
            ic_october: 5.228,
            ic_november: 5.928,
            ic_december: 6.206,
            ic_yearly: 4.825,
          },
          {
            ic_uuid: "d7aac1ad-a13b-4dd4-b2c5-44d292da9ac1",
            ic_lat: -217034,
            ic_lon: -472707,
            ic_city: "Tambaú",
            ic_states: "SÃO PAULO",
            ic_january: 5.631,
            ic_february: 5.803,
            ic_march: 5.121,
            ic_april: 4.766,
            ic_may: 4.023,
            ic_june: 3.752,
            ic_july: 3.965,
            ic_august: 4.877,
            ic_september: 4.964,
            ic_october: 5.517,
            ic_november: 5.732,
            ic_december: 5.948,
            ic_yearly: 5.008,
          },
          {
            ic_uuid: "96e7a62a-99a4-49e4-acf7-ca830de69723",
            ic_lat: -23204,
            ic_lon: -524747,
            ic_city: "Tamboara",
            ic_states: "PARANÁ",
            ic_january: 5.947,
            ic_february: 5.892,
            ic_march: 5.376,
            ic_april: 4.627,
            ic_may: 3.625,
            ic_june: 3.302,
            ic_july: 3.509,
            ic_august: 4.449,
            ic_september: 4.708,
            ic_october: 5.434,
            ic_november: 6.142,
            ic_december: 6.399,
            ic_yearly: 4.951,
          },
          {
            ic_uuid: "304d1793-7073-48c0-a812-99407d81c9e2",
            ic_lat: -48318,
            ic_lon: -403199,
            ic_city: "Tamboril",
            ic_states: "CEARÁ",
            ic_january: 5.114,
            ic_february: 5.3,
            ic_march: 5.302,
            ic_april: 4.975,
            ic_may: 5.087,
            ic_june: 5.03,
            ic_july: 5.36,
            ic_august: 6.146,
            ic_september: 6.616,
            ic_october: 6.506,
            ic_november: 6.336,
            ic_december: 5.535,
            ic_yearly: 5.609,
          },
          {
            ic_uuid: "87864d94-83a6-4f94-ab7c-577447146c83",
            ic_lat: -83996,
            ic_lon: -429123,
            ic_city: "Tamboril do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.597,
            ic_february: 5.587,
            ic_march: 5.436,
            ic_april: 5.337,
            ic_may: 5.25,
            ic_june: 5.2,
            ic_july: 5.587,
            ic_august: 6.445,
            ic_september: 6.605,
            ic_october: 6.408,
            ic_november: 6.002,
            ic_december: 5.815,
            ic_yearly: 5.772,
          },
          {
            ic_uuid: "256a44c9-7ca1-4aaf-9e8d-c2120cf67c2f",
            ic_lat: -206233,
            ic_lon: -496567,
            ic_city: "Tanabi",
            ic_states: "SÃO PAULO",
            ic_january: 5.783,
            ic_february: 5.862,
            ic_march: 5.216,
            ic_april: 4.927,
            ic_may: 4.204,
            ic_june: 3.904,
            ic_july: 4.172,
            ic_august: 5.067,
            ic_september: 5.101,
            ic_october: 5.607,
            ic_november: 5.883,
            ic_december: 6.137,
            ic_yearly: 5.155,
          },
          {
            ic_uuid: "19f77b06-d9fc-4553-9d6e-58b7572fbf5a",
            ic_lat: -61976,
            ic_lon: -358003,
            ic_city: "Tangará",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.756,
            ic_february: 5.845,
            ic_march: 5.838,
            ic_april: 5.486,
            ic_may: 4.956,
            ic_june: 4.467,
            ic_july: 4.61,
            ic_august: 5.304,
            ic_september: 5.729,
            ic_october: 5.897,
            ic_november: 6.057,
            ic_december: 5.741,
            ic_yearly: 5.474,
          },
          {
            ic_uuid: "ba6aed05-d762-4a06-b202-01842533b985",
            ic_lat: -271001,
            ic_lon: -512477,
            ic_city: "Tangará",
            ic_states: "SANTA CATARINA",
            ic_january: 5.83,
            ic_february: 5.479,
            ic_march: 4.922,
            ic_april: 4.019,
            ic_may: 3.014,
            ic_june: 2.644,
            ic_july: 2.87,
            ic_august: 3.778,
            ic_september: 3.963,
            ic_october: 4.84,
            ic_november: 5.985,
            ic_december: 6.237,
            ic_yearly: 4.465,
          },
          {
            ic_uuid: "7fddcfae-9fc5-4a34-adeb-27b5dd71dcd0",
            ic_lat: -146233,
            ic_lon: -574937,
            ic_city: "Tangará da Serra",
            ic_states: "MATO GROSSO",
            ic_january: 5.115,
            ic_february: 5.099,
            ic_march: 4.964,
            ic_april: 4.765,
            ic_may: 4.348,
            ic_june: 4.388,
            ic_july: 4.561,
            ic_august: 5.284,
            ic_september: 5.063,
            ic_october: 5.225,
            ic_november: 5.302,
            ic_december: 5.409,
            ic_yearly: 4.96,
          },
          {
            ic_uuid: "f64bd662-e357-4a9e-b032-7934634dd06c",
            ic_lat: -227428,
            ic_lon: -427206,
            ic_city: "Tanguá",
            ic_states: "RIO DE JANEIRO",
            ic_january: 6.057,
            ic_february: 6.311,
            ic_march: 5.044,
            ic_april: 4.449,
            ic_may: 3.635,
            ic_june: 3.448,
            ic_july: 3.444,
            ic_august: 4.251,
            ic_september: 4.457,
            ic_october: 4.985,
            ic_november: 5.025,
            ic_december: 5.787,
            ic_yearly: 4.741,
          },
          {
            ic_uuid: "7f67c5d6-12a3-46a4-b041-46194d35ebe7",
            ic_lat: -140201,
            ic_lon: -412476,
            ic_city: "Tanhaçu",
            ic_states: "BAHIA",
            ic_january: 6.285,
            ic_february: 6.263,
            ic_march: 5.749,
            ic_april: 4.894,
            ic_may: 4.325,
            ic_june: 3.949,
            ic_july: 4.111,
            ic_august: 4.802,
            ic_september: 5.678,
            ic_october: 5.91,
            ic_november: 5.851,
            ic_december: 6.449,
            ic_yearly: 5.355,
          },
          {
            ic_uuid: "01dc944b-7506-4d81-9acf-b9da07bf6e4f",
            ic_lat: -95348,
            ic_lon: -364334,
            ic_city: "Tanque d'Arca",
            ic_states: "ALAGOAS",
            ic_january: 5.992,
            ic_february: 5.84,
            ic_march: 5.816,
            ic_april: 5.108,
            ic_may: 4.247,
            ic_june: 3.84,
            ic_july: 3.941,
            ic_august: 4.591,
            ic_september: 5.464,
            ic_october: 5.826,
            ic_november: 6.426,
            ic_december: 6.329,
            ic_yearly: 5.285,
          },
          {
            ic_uuid: "c9dd712f-4eab-49e1-b2db-0f477440c6c4",
            ic_lat: -65983,
            ic_lon: -422799,
            ic_city: "Tanque do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.138,
            ic_february: 5.165,
            ic_march: 5.115,
            ic_april: 5.115,
            ic_may: 5.256,
            ic_june: 5.321,
            ic_july: 5.7,
            ic_august: 6.189,
            ic_september: 6.64,
            ic_october: 6.337,
            ic_november: 6.085,
            ic_december: 5.659,
            ic_yearly: 5.643,
          },
          {
            ic_uuid: "b50f66f5-f82e-4565-98d5-4d9d1902ef74",
            ic_lat: -135476,
            ic_lon: -424908,
            ic_city: "Tanque Novo",
            ic_states: "BAHIA",
            ic_january: 6.565,
            ic_february: 6.599,
            ic_march: 6.008,
            ic_april: 5.476,
            ic_may: 4.986,
            ic_june: 4.708,
            ic_july: 4.961,
            ic_august: 5.608,
            ic_september: 6.14,
            ic_october: 6.44,
            ic_november: 5.94,
            ic_december: 6.547,
            ic_yearly: 5.832,
          },
          {
            ic_uuid: "2e050bc6-3e7c-455b-a4a5-b3c5879c74bf",
            ic_lat: -119751,
            ic_lon: -391048,
            ic_city: "Tanquinho",
            ic_states: "BAHIA",
            ic_january: 6.05,
            ic_february: 5.913,
            ic_march: 5.702,
            ic_april: 4.718,
            ic_may: 4.024,
            ic_june: 3.683,
            ic_july: 3.915,
            ic_august: 4.411,
            ic_september: 5.217,
            ic_october: 5.449,
            ic_november: 5.721,
            ic_december: 5.992,
            ic_yearly: 5.066,
          },
          {
            ic_uuid: "5d32c9be-6689-4744-b14b-bcdcb1a4f9a5",
            ic_lat: -197626,
            ic_lon: -416083,
            ic_city: "Taparuba",
            ic_states: "MINAS GERAIS",
            ic_january: 5.916,
            ic_february: 6.181,
            ic_march: 5.157,
            ic_april: 4.714,
            ic_may: 3.886,
            ic_june: 3.639,
            ic_july: 3.767,
            ic_august: 4.456,
            ic_september: 4.794,
            ic_october: 4.962,
            ic_november: 4.903,
            ic_december: 5.684,
            ic_yearly: 4.838,
          },
          {
            ic_uuid: "48c9c4f1-f795-4b18-b506-d318905ec866",
            ic_lat: -56212,
            ic_lon: -631813,
            ic_city: "Tapauá",
            ic_states: "AMAZONAS",
            ic_january: 4.164,
            ic_february: 4.394,
            ic_march: 4.259,
            ic_april: 4.122,
            ic_may: 3.794,
            ic_june: 4.18,
            ic_july: 4.344,
            ic_august: 4.927,
            ic_september: 4.977,
            ic_october: 4.865,
            ic_november: 4.614,
            ic_december: 4.284,
            ic_yearly: 4.41,
          },
          {
            ic_uuid: "168baa52-752b-448a-92e8-f406b7aac6e6",
            ic_lat: -237319,
            ic_lon: -528739,
            ic_city: "Tapejara",
            ic_states: "PARANÁ",
            ic_january: 6.129,
            ic_february: 5.867,
            ic_march: 5.38,
            ic_april: 4.54,
            ic_may: 3.559,
            ic_june: 3.211,
            ic_july: 3.423,
            ic_august: 4.372,
            ic_september: 4.603,
            ic_october: 5.398,
            ic_november: 6.129,
            ic_december: 6.437,
            ic_yearly: 4.921,
          },
          {
            ic_uuid: "6560f598-6c55-46ca-b04e-1b31679d654b",
            ic_lat: -280657,
            ic_lon: -520101,
            ic_city: "Tapejara",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.228,
            ic_february: 5.796,
            ic_march: 4.965,
            ic_april: 4.024,
            ic_may: 3.018,
            ic_june: 2.532,
            ic_july: 2.858,
            ic_august: 3.646,
            ic_september: 3.88,
            ic_october: 5.004,
            ic_november: 6.184,
            ic_december: 6.521,
            ic_yearly: 4.554,
          },
          {
            ic_uuid: "64dc1471-7dca-448b-8314-bf68f1c4770a",
            ic_lat: -28626,
            ic_lon: -528699,
            ic_city: "Tapera",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.334,
            ic_february: 5.902,
            ic_march: 5.021,
            ic_april: 3.984,
            ic_may: 3.032,
            ic_june: 2.479,
            ic_july: 2.787,
            ic_august: 3.568,
            ic_september: 3.864,
            ic_october: 5.049,
            ic_november: 6.273,
            ic_december: 6.665,
            ic_yearly: 4.58,
          },
          {
            ic_uuid: "c04b7344-231f-41b4-a24b-1a1e9fdc6352",
            ic_lat: -135392,
            ic_lon: -391005,
            ic_city: "Taperoá",
            ic_states: "BAHIA",
            ic_january: 5.97,
            ic_february: 5.89,
            ic_march: 5.555,
            ic_april: 4.509,
            ic_may: 3.91,
            ic_june: 3.548,
            ic_july: 3.785,
            ic_august: 4.296,
            ic_september: 5.004,
            ic_october: 5.158,
            ic_november: 5.52,
            ic_december: 6.029,
            ic_yearly: 4.931,
          },
          {
            ic_uuid: "9fc6c671-b487-478b-92e8-85d9e54c0579",
            ic_lat: -72067,
            ic_lon: -368248,
            ic_city: "Taperoá",
            ic_states: "PARAÍBA",
            ic_january: 5.966,
            ic_february: 5.976,
            ic_march: 5.945,
            ic_april: 5.65,
            ic_may: 4.951,
            ic_june: 4.439,
            ic_july: 4.769,
            ic_august: 5.516,
            ic_september: 6.158,
            ic_october: 6.381,
            ic_november: 6.501,
            ic_december: 6.187,
            ic_yearly: 5.703,
          },
          {
            ic_uuid: "aa98117c-004e-4720-85ed-ef9fa46df309",
            ic_lat: -306688,
            ic_lon: -513996,
            ic_city: "Tapes",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.495,
            ic_february: 5.711,
            ic_march: 4.848,
            ic_april: 3.768,
            ic_may: 2.78,
            ic_june: 2.291,
            ic_july: 2.5,
            ic_august: 3.092,
            ic_september: 3.623,
            ic_october: 4.754,
            ic_november: 6.198,
            ic_december: 6.598,
            ic_yearly: 4.388,
          },
          {
            ic_uuid: "feb38fd9-9c4f-4484-a63f-e585889ccc78",
            ic_lat: -199231,
            ic_lon: -468222,
            ic_city: "Tapira",
            ic_states: "MINAS GERAIS",
            ic_january: 5.407,
            ic_february: 5.699,
            ic_march: 4.81,
            ic_april: 4.787,
            ic_may: 4.382,
            ic_june: 4.144,
            ic_july: 4.399,
            ic_august: 5.42,
            ic_september: 5.331,
            ic_october: 5.497,
            ic_november: 5.183,
            ic_december: 5.47,
            ic_yearly: 5.044,
          },
          {
            ic_uuid: "a6fa72c5-6bca-44ab-b0fd-85867e90172a",
            ic_lat: -233198,
            ic_lon: -530689,
            ic_city: "Tapira",
            ic_states: "PARANÁ",
            ic_january: 6.009,
            ic_february: 5.946,
            ic_march: 5.421,
            ic_april: 4.585,
            ic_may: 3.638,
            ic_june: 3.248,
            ic_july: 3.46,
            ic_august: 4.416,
            ic_september: 4.654,
            ic_october: 5.431,
            ic_november: 6.155,
            ic_december: 6.523,
            ic_yearly: 4.957,
          },
          {
            ic_uuid: "dd682433-d198-4dd7-9874-43cb82785811",
            ic_lat: -19888,
            ic_lon: -460204,
            ic_city: "Tapiraí",
            ic_states: "MINAS GERAIS",
            ic_january: 5.709,
            ic_february: 5.984,
            ic_march: 4.933,
            ic_april: 4.931,
            ic_may: 4.402,
            ic_june: 4.207,
            ic_july: 4.47,
            ic_august: 5.469,
            ic_september: 5.457,
            ic_october: 5.478,
            ic_november: 5.085,
            ic_december: 5.493,
            ic_yearly: 5.135,
          },
          {
            ic_uuid: "85efc48a-9629-4d1e-b1f1-80a8e5ebfe0a",
            ic_lat: -239616,
            ic_lon: -475067,
            ic_city: "Tapiraí",
            ic_states: "SÃO PAULO",
            ic_january: 4.867,
            ic_february: 5.226,
            ic_march: 4.425,
            ic_april: 3.88,
            ic_may: 3.141,
            ic_june: 2.942,
            ic_july: 2.997,
            ic_august: 3.796,
            ic_september: 3.744,
            ic_october: 4.149,
            ic_november: 4.802,
            ic_december: 5.407,
            ic_yearly: 4.115,
          },
          {
            ic_uuid: "88b81147-e2e5-43ca-874b-55b47f7f8119",
            ic_lat: -118479,
            ic_lon: -40793,
            ic_city: "Tapiramutá",
            ic_states: "BAHIA",
            ic_january: 5.89,
            ic_february: 5.76,
            ic_march: 5.557,
            ic_april: 4.593,
            ic_may: 3.923,
            ic_june: 3.651,
            ic_july: 3.793,
            ic_august: 4.473,
            ic_september: 5.409,
            ic_october: 5.384,
            ic_november: 5.504,
            ic_december: 5.765,
            ic_yearly: 4.975,
          },
          {
            ic_uuid: "b858c3ec-71c8-4eb8-ae71-7448982630d8",
            ic_lat: -214718,
            ic_lon: -467452,
            ic_city: "Tapiratiba",
            ic_states: "SÃO PAULO",
            ic_january: 5.593,
            ic_february: 5.77,
            ic_march: 4.967,
            ic_april: 4.754,
            ic_may: 4.06,
            ic_june: 3.835,
            ic_july: 4.045,
            ic_august: 4.933,
            ic_september: 5.05,
            ic_october: 5.486,
            ic_november: 5.577,
            ic_december: 5.796,
            ic_yearly: 4.989,
          },
          {
            ic_uuid: "9daa1678-912e-44fe-b715-740b802814df",
            ic_lat: -127377,
            ic_lon: -565143,
            ic_city: "Tapurah",
            ic_states: "MATO GROSSO",
            ic_january: 5.139,
            ic_february: 5.01,
            ic_march: 4.919,
            ic_april: 4.807,
            ic_may: 4.656,
            ic_june: 4.654,
            ic_july: 4.84,
            ic_august: 5.239,
            ic_september: 5.158,
            ic_october: 5.365,
            ic_november: 5.166,
            ic_december: 5.269,
            ic_yearly: 5.019,
          },
          {
            ic_uuid: "4f11d5cb-e7f7-4c19-a4d7-2713f15a6f60",
            ic_lat: -29651,
            ic_lon: -507757,
            ic_city: "Taquara",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.265,
            ic_february: 5.715,
            ic_march: 4.787,
            ic_april: 3.813,
            ic_may: 2.837,
            ic_june: 2.341,
            ic_july: 2.546,
            ic_august: 3.227,
            ic_september: 3.591,
            ic_october: 4.69,
            ic_november: 6.164,
            ic_december: 6.557,
            ic_yearly: 4.378,
          },
          {
            ic_uuid: "63607c88-2fad-48ea-a6d4-7bc546a154ae",
            ic_lat: -196657,
            ic_lon: -436926,
            ic_city: "Taquaraçu de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.839,
            ic_february: 6.139,
            ic_march: 5.259,
            ic_april: 4.817,
            ic_may: 4.262,
            ic_june: 4.104,
            ic_july: 4.336,
            ic_august: 5.152,
            ic_september: 5.529,
            ic_october: 5.683,
            ic_november: 5.374,
            ic_december: 5.696,
            ic_yearly: 5.183,
          },
          {
            ic_uuid: "5938abba-2d5e-4711-bdbf-5f8ab31fbb63",
            ic_lat: -210742,
            ic_lon: -48413,
            ic_city: "Taquaral",
            ic_states: "SÃO PAULO",
            ic_january: 5.763,
            ic_february: 5.988,
            ic_march: 5.213,
            ic_april: 4.818,
            ic_may: 4.125,
            ic_june: 3.889,
            ic_july: 4.122,
            ic_august: 4.979,
            ic_september: 5.06,
            ic_october: 5.592,
            ic_november: 5.902,
            ic_december: 6.145,
            ic_yearly: 5.133,
          },
          {
            ic_uuid: "72f1acc4-2ae2-4ca1-b227-029a6b3e98d3",
            ic_lat: -16055,
            ic_lon: -496025,
            ic_city: "Taquaral de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.451,
            ic_february: 5.65,
            ic_march: 5.184,
            ic_april: 5.154,
            ic_may: 4.882,
            ic_june: 4.629,
            ic_july: 4.753,
            ic_august: 5.646,
            ic_september: 5.494,
            ic_october: 5.566,
            ic_november: 5.282,
            ic_december: 5.428,
            ic_yearly: 5.26,
          },
          {
            ic_uuid: "a199dabb-b6e9-4a41-8626-75dedf86ad80",
            ic_lat: -96457,
            ic_lon: -364931,
            ic_city: "Taquarana",
            ic_states: "ALAGOAS",
            ic_january: 5.989,
            ic_february: 5.835,
            ic_march: 5.777,
            ic_april: 5.115,
            ic_may: 4.198,
            ic_june: 3.834,
            ic_july: 3.925,
            ic_august: 4.551,
            ic_september: 5.377,
            ic_october: 5.718,
            ic_november: 6.331,
            ic_december: 6.314,
            ic_yearly: 5.247,
          },
          {
            ic_uuid: "e9ea83fc-7076-414f-9405-6c9613d99136",
            ic_lat: -297948,
            ic_lon: -518658,
            ic_city: "Taquari",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.387,
            ic_february: 5.845,
            ic_march: 4.871,
            ic_april: 3.811,
            ic_may: 2.773,
            ic_june: 2.3,
            ic_july: 2.516,
            ic_august: 3.262,
            ic_september: 3.593,
            ic_october: 4.739,
            ic_november: 6.212,
            ic_december: 6.681,
            ic_yearly: 4.416,
          },
          {
            ic_uuid: "89001d1c-7cd6-4d98-9782-948d6d6920f5",
            ic_lat: -214053,
            ic_lon: -485108,
            ic_city: "Taquaritinga",
            ic_states: "SÃO PAULO",
            ic_january: 5.745,
            ic_february: 5.937,
            ic_march: 5.163,
            ic_april: 4.789,
            ic_may: 4.092,
            ic_june: 3.819,
            ic_july: 4.025,
            ic_august: 4.934,
            ic_september: 5.009,
            ic_october: 5.614,
            ic_november: 5.903,
            ic_december: 6.141,
            ic_yearly: 5.098,
          },
          {
            ic_uuid: "b6c91666-abf3-4b16-b70d-79e590087135",
            ic_lat: -7898,
            ic_lon: -360497,
            ic_city: "Taquaritinga do Norte",
            ic_states: "PERNAMBUCO",
            ic_january: 5.898,
            ic_february: 5.894,
            ic_march: 5.913,
            ic_april: 5.296,
            ic_may: 4.613,
            ic_june: 4.103,
            ic_july: 4.226,
            ic_august: 4.916,
            ic_september: 5.637,
            ic_october: 5.988,
            ic_november: 6.268,
            ic_december: 6.07,
            ic_yearly: 5.402,
          },
          {
            ic_uuid: "acf26993-7e60-4d6d-a3cd-a778efc65df0",
            ic_lat: -23533,
            ic_lon: -492439,
            ic_city: "Taquarituba",
            ic_states: "SÃO PAULO",
            ic_january: 5.619,
            ic_february: 5.68,
            ic_march: 5.026,
            ic_april: 4.428,
            ic_may: 3.539,
            ic_june: 3.209,
            ic_july: 3.406,
            ic_august: 4.389,
            ic_september: 4.555,
            ic_october: 5.189,
            ic_november: 5.762,
            ic_december: 6.094,
            ic_yearly: 4.741,
          },
          {
            ic_uuid: "e8b1a6a4-9793-4705-8d79-a780b577f427",
            ic_lat: -239216,
            ic_lon: -486952,
            ic_city: "Taquarivaí",
            ic_states: "SÃO PAULO",
            ic_january: 5.493,
            ic_february: 5.517,
            ic_march: 4.847,
            ic_april: 4.267,
            ic_may: 3.407,
            ic_june: 3.087,
            ic_july: 3.259,
            ic_august: 4.223,
            ic_september: 4.341,
            ic_october: 4.857,
            ic_november: 5.462,
            ic_december: 5.987,
            ic_yearly: 4.562,
          },
          {
            ic_uuid: "7caf1e71-9d8b-4bf1-9945-1bd316c87888",
            ic_lat: -274009,
            ic_lon: -534707,
            ic_city: "Taquaruçu do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.43,
            ic_february: 5.845,
            ic_march: 5.14,
            ic_april: 4.073,
            ic_may: 3.116,
            ic_june: 2.581,
            ic_july: 2.906,
            ic_august: 3.732,
            ic_september: 3.987,
            ic_october: 5.185,
            ic_november: 6.203,
            ic_december: 6.597,
            ic_yearly: 4.65,
          },
          {
            ic_uuid: "50c45a28-8923-43bb-a961-7d18107b042a",
            ic_lat: -22488,
            ic_lon: -533513,
            ic_city: "Taquarussu",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 6.001,
            ic_february: 5.882,
            ic_march: 5.417,
            ic_april: 4.659,
            ic_may: 3.729,
            ic_june: 3.375,
            ic_july: 3.557,
            ic_august: 4.504,
            ic_september: 4.755,
            ic_october: 5.435,
            ic_november: 6.078,
            ic_december: 6.494,
            ic_yearly: 4.991,
          },
          {
            ic_uuid: "c8fd0a6e-b443-41ba-b45c-dc64e5aca73a",
            ic_lat: -223021,
            ic_lon: -515626,
            ic_city: "Tarabai",
            ic_states: "SÃO PAULO",
            ic_january: 5.973,
            ic_february: 5.995,
            ic_march: 5.477,
            ic_april: 4.789,
            ic_may: 3.842,
            ic_june: 3.533,
            ic_july: 3.764,
            ic_august: 4.716,
            ic_september: 4.839,
            ic_october: 5.558,
            ic_november: 6.123,
            ic_december: 6.516,
            ic_yearly: 5.093,
          },
          {
            ic_uuid: "5f258fff-3ba9-45ab-b3ac-6044bc73ac42",
            ic_lat: -81574,
            ic_lon: -707726,
            ic_city: "Tarauacá",
            ic_states: "ACRE",
            ic_january: 4.555,
            ic_february: 4.804,
            ic_march: 4.236,
            ic_april: 4.397,
            ic_may: 4.088,
            ic_june: 4.115,
            ic_july: 4.341,
            ic_august: 5.005,
            ic_september: 5.193,
            ic_october: 5.118,
            ic_november: 4.843,
            ic_december: 4.533,
            ic_yearly: 4.602,
          },
          {
            ic_uuid: "4cdfde21-d0fb-44f8-b165-8adbda2b6482",
            ic_lat: -66832,
            ic_lon: -397575,
            ic_city: "Tarrafas",
            ic_states: "CEARÁ",
            ic_january: 5.63,
            ic_february: 5.683,
            ic_march: 5.671,
            ic_april: 5.37,
            ic_may: 5.183,
            ic_june: 5.077,
            ic_july: 5.401,
            ic_august: 6.153,
            ic_september: 6.543,
            ic_october: 6.448,
            ic_november: 6.351,
            ic_december: 5.9,
            ic_yearly: 5.784,
          },
          {
            ic_uuid: "1ed837f9-2d27-4213-b06d-c71ec2b7b125",
            ic_lat: 15062,
            ic_lon: -509091,
            ic_city: "Tartarugalzinho",
            ic_states: "AMAPÁ",
            ic_january: 4.138,
            ic_february: 3.916,
            ic_march: 3.941,
            ic_april: 3.952,
            ic_may: 4.347,
            ic_june: 4.624,
            ic_july: 4.869,
            ic_august: 5.254,
            ic_september: 5.402,
            ic_october: 5.267,
            ic_november: 5.114,
            ic_december: 4.318,
            ic_yearly: 4.595,
          },
          {
            ic_uuid: "02cb1da6-ad4b-4de6-9a02-ed724cdce3e6",
            ic_lat: -227433,
            ic_lon: -50579,
            ic_city: "Tarumã",
            ic_states: "SÃO PAULO",
            ic_january: 5.762,
            ic_february: 5.813,
            ic_march: 5.264,
            ic_april: 4.707,
            ic_may: 3.801,
            ic_june: 3.461,
            ic_july: 3.705,
            ic_august: 4.674,
            ic_september: 4.783,
            ic_october: 5.429,
            ic_november: 5.973,
            ic_december: 6.277,
            ic_yearly: 4.971,
          },
          {
            ic_uuid: "2ea4f98e-693c-43af-9c83-b8d172f6dab9",
            ic_lat: -192788,
            ic_lon: -420119,
            ic_city: "Tarumirim",
            ic_states: "MINAS GERAIS",
            ic_january: 5.816,
            ic_february: 6.04,
            ic_march: 5.068,
            ic_april: 4.515,
            ic_may: 3.818,
            ic_june: 3.592,
            ic_july: 3.698,
            ic_august: 4.37,
            ic_september: 4.824,
            ic_october: 5.01,
            ic_november: 4.815,
            ic_december: 5.735,
            ic_yearly: 4.775,
          },
          {
            ic_uuid: "480f9fe9-d6c0-4904-92d9-7ce4b7df80b4",
            ic_lat: -84737,
            ic_lon: -457479,
            ic_city: "Tasso Fragoso",
            ic_states: "MARANHÃO",
            ic_january: 5.172,
            ic_february: 5.203,
            ic_march: 5.107,
            ic_april: 5.242,
            ic_may: 5.325,
            ic_june: 5.359,
            ic_july: 5.512,
            ic_august: 5.961,
            ic_september: 6.439,
            ic_october: 5.816,
            ic_november: 5.359,
            ic_december: 5.214,
            ic_yearly: 5.476,
          },
          {
            ic_uuid: "d7c4862d-a80e-430a-a108-db6a3cddee0e",
            ic_lat: -233492,
            ic_lon: -478465,
            ic_city: "Tatuí",
            ic_states: "SÃO PAULO",
            ic_january: 5.549,
            ic_february: 5.769,
            ic_march: 5.085,
            ic_april: 4.526,
            ic_may: 3.657,
            ic_june: 3.42,
            ic_july: 3.51,
            ic_august: 4.523,
            ic_september: 4.69,
            ic_october: 5.269,
            ic_november: 5.704,
            ic_december: 6.102,
            ic_yearly: 4.817,
          },
          {
            ic_uuid: "b37ad97f-0419-4f43-acda-551bb4e2b215",
            ic_lat: -59863,
            ic_lon: -402971,
            ic_city: "Tauá",
            ic_states: "CEARÁ",
            ic_january: 5.263,
            ic_february: 5.361,
            ic_march: 5.408,
            ic_april: 5.073,
            ic_may: 5.009,
            ic_june: 5.007,
            ic_july: 5.402,
            ic_august: 6.201,
            ic_september: 6.572,
            ic_october: 6.398,
            ic_november: 6.277,
            ic_december: 5.678,
            ic_yearly: 5.638,
          },
          {
            ic_uuid: "7d9c9906-5ceb-4563-a1ee-4804ea476786",
            ic_lat: -230109,
            ic_lon: -455597,
            ic_city: "Taubaté",
            ic_states: "SÃO PAULO",
            ic_january: 5.383,
            ic_february: 5.676,
            ic_march: 4.833,
            ic_april: 4.406,
            ic_may: 3.583,
            ic_june: 3.326,
            ic_july: 3.446,
            ic_august: 4.406,
            ic_september: 4.525,
            ic_october: 5.066,
            ic_november: 5.247,
            ic_december: 5.781,
            ic_yearly: 4.64,
          },
          {
            ic_uuid: "a313e48a-f0b4-4b56-8bbe-df802c4c0f43",
            ic_lat: -76274,
            ic_lon: -378715,
            ic_city: "Tavares",
            ic_states: "PARAÍBA",
            ic_january: 6.129,
            ic_february: 6.142,
            ic_march: 6.12,
            ic_april: 5.778,
            ic_may: 5.063,
            ic_june: 4.731,
            ic_july: 4.992,
            ic_august: 5.765,
            ic_september: 6.427,
            ic_october: 6.506,
            ic_november: 6.646,
            ic_december: 6.278,
            ic_yearly: 5.882,
          },
          {
            ic_uuid: "49008c87-e091-4cba-9ad9-19df7f141fdf",
            ic_lat: -312869,
            ic_lon: -510939,
            ic_city: "Tavares",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.617,
            ic_february: 5.858,
            ic_march: 4.896,
            ic_april: 3.795,
            ic_may: 2.734,
            ic_june: 2.279,
            ic_july: 2.461,
            ic_august: 3.101,
            ic_september: 3.635,
            ic_october: 4.877,
            ic_november: 6.317,
            ic_december: 6.841,
            ic_yearly: 4.451,
          },
          {
            ic_uuid: "c526f7da-485e-4df0-b1e7-5627862ad7bd",
            ic_lat: -33686,
            ic_lon: -647198,
            ic_city: "Tefé",
            ic_states: "AMAZONAS",
            ic_january: 4.338,
            ic_february: 4.55,
            ic_march: 4.544,
            ic_april: 4.257,
            ic_may: 3.937,
            ic_june: 4.236,
            ic_july: 4.319,
            ic_august: 4.954,
            ic_september: 5.037,
            ic_october: 5.008,
            ic_november: 4.749,
            ic_december: 4.542,
            ic_yearly: 4.539,
          },
          {
            ic_uuid: "c4917984-d419-421f-9ee4-1ba878747bd3",
            ic_lat: -72215,
            ic_lon: -372528,
            ic_city: "Teixeira",
            ic_states: "PARAÍBA",
            ic_january: 6.168,
            ic_february: 6.165,
            ic_march: 6.224,
            ic_april: 5.822,
            ic_may: 5.097,
            ic_june: 4.666,
            ic_july: 4.998,
            ic_august: 5.849,
            ic_september: 6.444,
            ic_october: 6.568,
            ic_november: 6.672,
            ic_december: 6.291,
            ic_yearly: 5.914,
          },
          {
            ic_uuid: "7b9dbd8f-74a0-4506-841c-4998ce1ec7e7",
            ic_lat: -175404,
            ic_lon: -397403,
            ic_city: "Teixeira de Freitas",
            ic_states: "BAHIA",
            ic_january: 6.158,
            ic_february: 6.069,
            ic_march: 5.229,
            ic_april: 4.508,
            ic_may: 3.839,
            ic_june: 3.463,
            ic_july: 3.655,
            ic_august: 4.34,
            ic_september: 4.883,
            ic_october: 5.185,
            ic_november: 5.243,
            ic_december: 6.127,
            ic_yearly: 4.892,
          },
          {
            ic_uuid: "bd3461ee-9478-4509-92b6-1f494f9242a4",
            ic_lat: -253706,
            ic_lon: -504575,
            ic_city: "Teixeira Soares",
            ic_states: "PARANÁ",
            ic_january: 5.569,
            ic_february: 5.255,
            ic_march: 4.674,
            ic_april: 3.929,
            ic_may: 3.121,
            ic_june: 2.716,
            ic_july: 2.977,
            ic_august: 3.976,
            ic_september: 4.064,
            ic_october: 4.674,
            ic_november: 5.574,
            ic_december: 5.71,
            ic_yearly: 4.353,
          },
          {
            ic_uuid: "165d4313-6d9f-4dce-8112-f5625540f173",
            ic_lat: -206566,
            ic_lon: -428568,
            ic_city: "Teixeiras",
            ic_states: "MINAS GERAIS",
            ic_january: 5.592,
            ic_february: 5.88,
            ic_march: 4.899,
            ic_april: 4.376,
            ic_may: 3.736,
            ic_june: 3.525,
            ic_july: 3.749,
            ic_august: 4.55,
            ic_september: 4.829,
            ic_october: 5.037,
            ic_november: 4.786,
            ic_december: 5.479,
            ic_yearly: 4.703,
          },
          {
            ic_uuid: "bfbb57c3-43d2-4121-b417-ea5f0e04b32f",
            ic_lat: -109337,
            ic_lon: -622562,
            ic_city: "Teixeirópolis",
            ic_states: "RONDÔNIA",
            ic_january: 4.527,
            ic_february: 4.388,
            ic_march: 4.431,
            ic_april: 4.49,
            ic_may: 4.054,
            ic_june: 4.385,
            ic_july: 4.52,
            ic_august: 4.946,
            ic_september: 4.872,
            ic_october: 5.181,
            ic_november: 4.991,
            ic_december: 4.712,
            ic_yearly: 4.625,
          },
          {
            ic_uuid: "cdacf8b1-570c-492b-86c8-2a21573af355",
            ic_lat: -39887,
            ic_lon: -395802,
            ic_city: "Tejuçuoca",
            ic_states: "CEARÁ",
            ic_january: 5.153,
            ic_february: 5.381,
            ic_march: 5.309,
            ic_april: 4.955,
            ic_may: 5.175,
            ic_june: 5.004,
            ic_july: 5.332,
            ic_august: 6.127,
            ic_september: 6.546,
            ic_october: 6.431,
            ic_november: 6.163,
            ic_december: 5.539,
            ic_yearly: 5.593,
          },
          {
            ic_uuid: "0c63cdaf-db7a-4a61-9498-f1d00d26e441",
            ic_lat: -23343,
            ic_lon: -493759,
            ic_city: "Tejupá",
            ic_states: "SÃO PAULO",
            ic_january: 5.596,
            ic_february: 5.749,
            ic_march: 5.105,
            ic_april: 4.509,
            ic_may: 3.589,
            ic_june: 3.302,
            ic_july: 3.503,
            ic_august: 4.493,
            ic_september: 4.626,
            ic_october: 5.283,
            ic_november: 5.848,
            ic_december: 6.213,
            ic_yearly: 4.818,
          },
          {
            ic_uuid: "a78ef9fb-c931-46d9-a55e-05bcd7d326df",
            ic_lat: -24325,
            ic_lon: -50618,
            ic_city: "Telêmaco Borba",
            ic_states: "PARANÁ",
            ic_january: 5.722,
            ic_february: 5.551,
            ic_march: 5.065,
            ic_april: 4.407,
            ic_may: 3.434,
            ic_june: 3.021,
            ic_july: 3.311,
            ic_august: 4.313,
            ic_september: 4.468,
            ic_october: 5.037,
            ic_november: 5.846,
            ic_december: 6.101,
            ic_yearly: 4.69,
          },
          {
            ic_uuid: "8bed74e6-47ac-4025-a173-4903050e318e",
            ic_lat: -102069,
            ic_lon: -368821,
            ic_city: "Telha",
            ic_states: "SERGIPE",
            ic_january: 6.333,
            ic_february: 6.118,
            ic_march: 5.996,
            ic_april: 5.239,
            ic_may: 4.349,
            ic_june: 4.101,
            ic_july: 4.151,
            ic_august: 4.716,
            ic_september: 5.538,
            ic_october: 5.903,
            ic_november: 6.349,
            ic_december: 6.408,
            ic_yearly: 5.434,
          },
          {
            ic_uuid: "b39a995a-d602-4059-a9aa-99cb1e238dac",
            ic_lat: -64586,
            ic_lon: -381823,
            ic_city: "Tenente Ananias",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.022,
            ic_february: 5.97,
            ic_march: 6.052,
            ic_april: 5.715,
            ic_may: 5.26,
            ic_june: 4.982,
            ic_july: 5.269,
            ic_august: 6.031,
            ic_september: 6.464,
            ic_october: 6.597,
            ic_november: 6.75,
            ic_december: 6.203,
            ic_yearly: 5.943,
          },
          {
            ic_uuid: "f686893c-52c0-4588-b78f-6be25b8d318a",
            ic_lat: -61481,
            ic_lon: -367174,
            ic_city: "Tenente Laurentino Cruz",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.832,
            ic_february: 5.727,
            ic_march: 5.804,
            ic_april: 5.489,
            ic_may: 5.046,
            ic_june: 4.715,
            ic_july: 5.072,
            ic_august: 5.866,
            ic_september: 6.348,
            ic_october: 6.445,
            ic_november: 6.431,
            ic_december: 5.849,
            ic_yearly: 5.718,
          },
          {
            ic_uuid: "49113587-b7a0-45cf-89ba-4e4d6af9ec12",
            ic_lat: -273715,
            ic_lon: -537589,
            ic_city: "Tenente Portela",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.419,
            ic_february: 5.901,
            ic_march: 5.128,
            ic_april: 4.043,
            ic_may: 3.112,
            ic_june: 2.565,
            ic_july: 2.904,
            ic_august: 3.71,
            ic_september: 4.006,
            ic_october: 5.179,
            ic_november: 6.183,
            ic_december: 6.591,
            ic_yearly: 4.645,
          },
          {
            ic_uuid: "91ccb06f-8082-42cb-8d83-2621e08b792e",
            ic_lat: -6939,
            ic_lon: -366276,
            ic_city: "Tenório",
            ic_states: "PARAÍBA",
            ic_january: 5.941,
            ic_february: 5.915,
            ic_march: 5.95,
            ic_april: 5.533,
            ic_may: 4.937,
            ic_june: 4.538,
            ic_july: 4.821,
            ic_august: 5.561,
            ic_september: 6.123,
            ic_october: 6.392,
            ic_november: 6.423,
            ic_december: 6.121,
            ic_yearly: 5.688,
          },
          {
            ic_uuid: "36f4f848-45e1-4c3a-974c-e4a355ac3e47",
            ic_lat: -123026,
            ic_lon: -386402,
            ic_city: "Teodoro Sampaio",
            ic_states: "BAHIA",
            ic_january: 6.035,
            ic_february: 5.865,
            ic_march: 5.695,
            ic_april: 4.661,
            ic_may: 3.939,
            ic_june: 3.644,
            ic_july: 3.891,
            ic_august: 4.371,
            ic_september: 5.232,
            ic_october: 5.485,
            ic_november: 5.8,
            ic_december: 6.061,
            ic_yearly: 5.057,
          },
          {
            ic_uuid: "808ecd3c-a2ff-42a9-84ef-ab20ef288f5c",
            ic_lat: -225304,
            ic_lon: -521686,
            ic_city: "Teodoro Sampaio",
            ic_states: "SÃO PAULO",
            ic_january: 6.024,
            ic_february: 5.945,
            ic_march: 5.395,
            ic_april: 4.717,
            ic_may: 3.779,
            ic_june: 3.464,
            ic_july: 3.673,
            ic_august: 4.584,
            ic_september: 4.775,
            ic_october: 5.46,
            ic_november: 6.1,
            ic_december: 6.386,
            ic_yearly: 5.025,
          },
          {
            ic_uuid: "9bf3dd5a-5fd8-4507-bd83-89fa3074d739",
            ic_lat: -11479,
            ic_lon: -389971,
            ic_city: "Teofilândia",
            ic_states: "BAHIA",
            ic_january: 6.164,
            ic_february: 5.942,
            ic_march: 5.788,
            ic_april: 4.879,
            ic_may: 4.104,
            ic_june: 3.834,
            ic_july: 4.06,
            ic_august: 4.529,
            ic_september: 5.344,
            ic_october: 5.577,
            ic_november: 5.911,
            ic_december: 6.068,
            ic_yearly: 5.183,
          },
          {
            ic_uuid: "cd244796-b1f4-4270-9e92-b42647b082e9",
            ic_lat: -1786,
            ic_lon: -415091,
            ic_city: "Teófilo Otoni",
            ic_states: "MINAS GERAIS",
            ic_january: 6.085,
            ic_february: 6.232,
            ic_march: 5.327,
            ic_april: 4.582,
            ic_may: 3.812,
            ic_june: 3.547,
            ic_july: 3.672,
            ic_august: 4.36,
            ic_september: 4.856,
            ic_october: 5.179,
            ic_november: 4.958,
            ic_december: 5.823,
            ic_yearly: 4.869,
          },
          {
            ic_uuid: "d979f6e3-643a-49a2-9802-53291c80ff52",
            ic_lat: -135924,
            ic_lon: -394888,
            ic_city: "Teolândia",
            ic_states: "BAHIA",
            ic_january: 5.931,
            ic_february: 5.779,
            ic_march: 5.549,
            ic_april: 4.476,
            ic_may: 3.896,
            ic_june: 3.461,
            ic_july: 3.691,
            ic_august: 4.184,
            ic_september: 4.922,
            ic_october: 5.058,
            ic_november: 5.327,
            ic_december: 5.907,
            ic_yearly: 4.849,
          },
          {
            ic_uuid: "35569596-5904-4b08-bb82-cd0b4ae6fae1",
            ic_lat: -99094,
            ic_lon: -36358,
            ic_city: "Teotônio Vilela",
            ic_states: "ALAGOAS",
            ic_january: 5.961,
            ic_february: 5.808,
            ic_march: 5.811,
            ic_april: 5.038,
            ic_may: 4.282,
            ic_june: 3.93,
            ic_july: 3.989,
            ic_august: 4.581,
            ic_september: 5.314,
            ic_october: 5.664,
            ic_november: 6.196,
            ic_december: 6.185,
            ic_yearly: 5.23,
          },
          {
            ic_uuid: "e0bf4cf9-c222-4554-9477-dc5cfb67eea4",
            ic_lat: -204383,
            ic_lon: -548652,
            ic_city: "Terenos",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.726,
            ic_february: 5.78,
            ic_march: 5.404,
            ic_april: 4.85,
            ic_may: 4.01,
            ic_june: 3.761,
            ic_july: 3.94,
            ic_august: 4.787,
            ic_september: 4.919,
            ic_october: 5.448,
            ic_november: 5.942,
            ic_december: 6.201,
            ic_yearly: 5.064,
          },
          {
            ic_uuid: "eff3b2ca-b6b3-4b4c-9ddd-506d058d94d8",
            ic_lat: -50927,
            ic_lon: -42804,
            ic_city: "Teresina",
            ic_states: "PIAUÍ",
            ic_january: 5.108,
            ic_february: 5.217,
            ic_march: 5.151,
            ic_april: 5.035,
            ic_may: 5.053,
            ic_june: 5.171,
            ic_july: 5.465,
            ic_august: 6.03,
            ic_september: 6.46,
            ic_october: 6.353,
            ic_november: 6.163,
            ic_december: 5.652,
            ic_yearly: 5.572,
          },
          {
            ic_uuid: "481d98bf-a391-4536-aeb6-7f89cad1ba01",
            ic_lat: -137805,
            ic_lon: -472663,
            ic_city: "Teresina de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.58,
            ic_february: 5.633,
            ic_march: 5.199,
            ic_april: 5.125,
            ic_may: 5.05,
            ic_june: 4.987,
            ic_july: 5.414,
            ic_august: 6.016,
            ic_september: 6.011,
            ic_october: 5.693,
            ic_november: 5.083,
            ic_december: 5.478,
            ic_yearly: 5.439,
          },
          {
            ic_uuid: "53161111-3626-4769-96f6-effeb069361e",
            ic_lat: -224169,
            ic_lon: -429756,
            ic_city: "Teresópolis",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.43,
            ic_february: 5.788,
            ic_march: 4.678,
            ic_april: 4.115,
            ic_may: 3.476,
            ic_june: 3.306,
            ic_july: 3.407,
            ic_august: 4.165,
            ic_september: 4.459,
            ic_october: 4.747,
            ic_november: 4.588,
            ic_december: 5.297,
            ic_yearly: 4.455,
          },
          {
            ic_uuid: "1ca8346e-c471-417f-8aaa-b9fd8900d7df",
            ic_lat: -9056,
            ic_lon: -366245,
            ic_city: "Terezinha",
            ic_states: "PERNAMBUCO",
            ic_january: 6.062,
            ic_february: 5.916,
            ic_march: 5.936,
            ic_april: 5.258,
            ic_may: 4.282,
            ic_june: 3.925,
            ic_july: 4.053,
            ic_august: 4.699,
            ic_september: 5.623,
            ic_october: 6.005,
            ic_november: 6.577,
            ic_december: 6.435,
            ic_yearly: 5.398,
          },
          {
            ic_uuid: "13dc0b0a-b7ca-4697-a4d6-5adc5aefd755",
            ic_lat: -16395,
            ic_lon: -490801,
            ic_city: "Terezópolis de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.398,
            ic_february: 5.555,
            ic_march: 5.096,
            ic_april: 5.092,
            ic_may: 4.832,
            ic_june: 4.622,
            ic_july: 4.822,
            ic_august: 5.729,
            ic_september: 5.535,
            ic_october: 5.552,
            ic_november: 5.213,
            ic_december: 5.358,
            ic_yearly: 5.233,
          },
          {
            ic_uuid: "a05f18a7-c4d2-4aa9-99bd-d2d2f0de40c8",
            ic_lat: -1037,
            ic_lon: -479094,
            ic_city: "Terra Alta",
            ic_states: "PARÁ",
            ic_january: 4.343,
            ic_february: 4.059,
            ic_march: 4.012,
            ic_april: 4.164,
            ic_may: 4.48,
            ic_june: 4.815,
            ic_july: 4.914,
            ic_august: 5.192,
            ic_september: 5.374,
            ic_october: 5.21,
            ic_november: 5.058,
            ic_december: 4.618,
            ic_yearly: 4.687,
          },
          {
            ic_uuid: "dc15221c-0ea6-4dd5-a90e-15a4196ad941",
            ic_lat: -237688,
            ic_lon: -524474,
            ic_city: "Terra Boa",
            ic_states: "PARANÁ",
            ic_january: 6.102,
            ic_february: 5.769,
            ic_march: 5.281,
            ic_april: 4.569,
            ic_may: 3.609,
            ic_june: 3.204,
            ic_july: 3.433,
            ic_august: 4.371,
            ic_september: 4.626,
            ic_october: 5.331,
            ic_november: 6.118,
            ic_december: 6.454,
            ic_yearly: 4.906,
          },
          {
            ic_uuid: "3221465a-8414-47dd-b875-448ffe144e13",
            ic_lat: -295787,
            ic_lon: -500648,
            ic_city: "Terra de Areia",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 5.769,
            ic_february: 5.246,
            ic_march: 4.487,
            ic_april: 3.792,
            ic_may: 2.863,
            ic_june: 2.429,
            ic_july: 2.639,
            ic_august: 3.288,
            ic_september: 3.476,
            ic_october: 4.311,
            ic_november: 5.688,
            ic_december: 6.013,
            ic_yearly: 4.167,
          },
          {
            ic_uuid: "e0fd4287-e918-475c-832a-06827e41e2b3",
            ic_lat: -124039,
            ic_lon: -386203,
            ic_city: "Terra Nova",
            ic_states: "BAHIA",
            ic_january: 5.962,
            ic_february: 5.763,
            ic_march: 5.636,
            ic_april: 4.608,
            ic_may: 3.934,
            ic_june: 3.595,
            ic_july: 3.814,
            ic_august: 4.306,
            ic_september: 5.181,
            ic_october: 5.465,
            ic_november: 5.744,
            ic_december: 6.077,
            ic_yearly: 5.007,
          },
          {
            ic_uuid: "6b5ec9a7-0cb5-426b-b3d8-4b0e02b1435e",
            ic_lat: -82286,
            ic_lon: -393789,
            ic_city: "Terra Nova",
            ic_states: "PERNAMBUCO",
            ic_january: 6.179,
            ic_february: 5.919,
            ic_march: 5.87,
            ic_april: 5.442,
            ic_may: 4.807,
            ic_june: 4.409,
            ic_july: 4.718,
            ic_august: 5.469,
            ic_september: 6.404,
            ic_october: 6.501,
            ic_november: 6.623,
            ic_december: 6.34,
            ic_yearly: 5.723,
          },
          {
            ic_uuid: "122797a5-2bdb-4494-86c9-833fb09c181e",
            ic_lat: -105952,
            ic_lon: -551161,
            ic_city: "Terra Nova do Norte",
            ic_states: "MATO GROSSO",
            ic_january: 5.023,
            ic_february: 4.923,
            ic_march: 4.788,
            ic_april: 4.719,
            ic_may: 4.688,
            ic_june: 4.682,
            ic_july: 5.02,
            ic_august: 5.483,
            ic_september: 5.116,
            ic_october: 5.194,
            ic_november: 5.087,
            ic_december: 5.076,
            ic_yearly: 4.983,
          },
          {
            ic_uuid: "f9a9f209-b3c5-4321-a163-8ae36ebd2e57",
            ic_lat: -227264,
            ic_lon: -526203,
            ic_city: "Terra Rica",
            ic_states: "PARANÁ",
            ic_january: 6.075,
            ic_february: 6.056,
            ic_march: 5.46,
            ic_april: 4.697,
            ic_may: 3.731,
            ic_june: 3.392,
            ic_july: 3.604,
            ic_august: 4.53,
            ic_september: 4.761,
            ic_october: 5.46,
            ic_november: 6.205,
            ic_december: 6.552,
            ic_yearly: 5.044,
          },
          {
            ic_uuid: "d79c6304-ed49-4231-bfe1-0efc23284bc8",
            ic_lat: -241579,
            ic_lon: -540993,
            ic_city: "Terra Roxa",
            ic_states: "PARANÁ",
            ic_january: 6.279,
            ic_february: 5.893,
            ic_march: 5.353,
            ic_april: 4.386,
            ic_may: 3.408,
            ic_june: 3.008,
            ic_july: 3.248,
            ic_august: 4.212,
            ic_september: 4.504,
            ic_october: 5.348,
            ic_november: 6.116,
            ic_december: 6.568,
            ic_yearly: 4.86,
          },
          {
            ic_uuid: "7c827305-7e9b-40c5-8386-d0a109985b3e",
            ic_lat: -207875,
            ic_lon: -483318,
            ic_city: "Terra Roxa",
            ic_states: "SÃO PAULO",
            ic_january: 5.846,
            ic_february: 6.091,
            ic_march: 5.245,
            ic_april: 4.831,
            ic_may: 4.215,
            ic_june: 3.962,
            ic_july: 4.194,
            ic_august: 5.053,
            ic_september: 5.034,
            ic_october: 5.581,
            ic_november: 5.851,
            ic_december: 6.161,
            ic_yearly: 5.172,
          },
          {
            ic_uuid: "24b648a7-6f67-4e6b-841e-fa0387a9df64",
            ic_lat: -21048,
            ic_lon: -564881,
            ic_city: "Terra Santa",
            ic_states: "PARÁ",
            ic_january: 4.333,
            ic_february: 4.395,
            ic_march: 4.359,
            ic_april: 4.203,
            ic_may: 4.297,
            ic_june: 4.819,
            ic_july: 4.762,
            ic_august: 5.248,
            ic_september: 5.341,
            ic_october: 5.42,
            ic_november: 5.238,
            ic_december: 4.694,
            ic_yearly: 4.759,
          },
          {
            ic_uuid: "11e2d832-c97f-431e-9800-6c7dde7a1961",
            ic_lat: -160816,
            ic_lon: -535544,
            ic_city: "Tesouro",
            ic_states: "MATO GROSSO",
            ic_january: 5.53,
            ic_february: 5.525,
            ic_march: 5.194,
            ic_april: 5.235,
            ic_may: 4.831,
            ic_june: 4.586,
            ic_july: 4.745,
            ic_august: 5.539,
            ic_september: 5.398,
            ic_october: 5.356,
            ic_november: 5.485,
            ic_december: 5.551,
            ic_yearly: 5.248,
          },
          {
            ic_uuid: "be58e775-be03-4d3c-b12e-5767b6395d0f",
            ic_lat: -294487,
            ic_lon: -518048,
            ic_city: "Teutônia",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.21,
            ic_february: 5.772,
            ic_march: 4.858,
            ic_april: 3.77,
            ic_may: 2.825,
            ic_june: 2.33,
            ic_july: 2.567,
            ic_august: 3.294,
            ic_september: 3.578,
            ic_october: 4.665,
            ic_november: 6.1,
            ic_december: 6.468,
            ic_yearly: 4.37,
          },
          {
            ic_uuid: "13f5fc02-9d63-491b-a109-7b8e5a068fbe",
            ic_lat: -102487,
            ic_lon: -623543,
            ic_city: "Theobroma",
            ic_states: "RONDÔNIA",
            ic_january: 4.38,
            ic_february: 4.289,
            ic_march: 4.294,
            ic_april: 4.477,
            ic_may: 4.038,
            ic_june: 4.43,
            ic_july: 4.494,
            ic_august: 4.835,
            ic_september: 4.844,
            ic_october: 4.988,
            ic_november: 4.785,
            ic_december: 4.618,
            ic_yearly: 4.54,
          },
          {
            ic_uuid: "9209e1b5-08ee-4655-a4a1-b8362937be5c",
            ic_lat: -373,
            ic_lon: -409926,
            ic_city: "Tianguá",
            ic_states: "CEARÁ",
            ic_january: 5.037,
            ic_february: 5.019,
            ic_march: 4.996,
            ic_april: 4.647,
            ic_may: 4.843,
            ic_june: 4.994,
            ic_july: 5.354,
            ic_august: 6.11,
            ic_september: 6.527,
            ic_october: 6.435,
            ic_november: 6.253,
            ic_december: 5.534,
            ic_yearly: 5.479,
          },
          {
            ic_uuid: "6b2e06c1-9ef8-4242-af86-e5bc98a1631e",
            ic_lat: -245096,
            ic_lon: -504157,
            ic_city: "Tibagi",
            ic_states: "PARANÁ",
            ic_january: 5.732,
            ic_february: 5.482,
            ic_march: 4.987,
            ic_april: 4.355,
            ic_may: 3.442,
            ic_june: 3.009,
            ic_july: 3.285,
            ic_august: 4.271,
            ic_september: 4.456,
            ic_october: 5.06,
            ic_november: 5.857,
            ic_december: 6.046,
            ic_yearly: 4.665,
          },
          {
            ic_uuid: "3b8f7be2-3f85-4e9f-b5a9-d31535c1cae7",
            ic_lat: -48377,
            ic_lon: -372557,
            ic_city: "Tibau",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.329,
            ic_february: 6.268,
            ic_march: 6.132,
            ic_april: 5.763,
            ic_may: 5.519,
            ic_june: 5.247,
            ic_july: 5.46,
            ic_august: 6.036,
            ic_september: 6.081,
            ic_october: 6.053,
            ic_november: 6.241,
            ic_december: 6.407,
            ic_yearly: 5.961,
          },
          {
            ic_uuid: "83c8b4d3-5005-4996-bd99-57ddba7df8c4",
            ic_lat: -61874,
            ic_lon: -350921,
            ic_city: "Tibau do Sul",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.16,
            ic_february: 6.28,
            ic_march: 6.249,
            ic_april: 5.661,
            ic_may: 5.191,
            ic_june: 4.654,
            ic_july: 4.844,
            ic_august: 5.725,
            ic_september: 6.131,
            ic_october: 6.336,
            ic_november: 6.544,
            ic_december: 6.325,
            ic_yearly: 5.842,
          },
          {
            ic_uuid: "7bb6d767-bf77-4925-a07d-22291626ae89",
            ic_lat: -231106,
            ic_lon: -477168,
            ic_city: "Tietê",
            ic_states: "SÃO PAULO",
            ic_january: 5.62,
            ic_february: 5.886,
            ic_march: 5.169,
            ic_april: 4.62,
            ic_may: 3.74,
            ic_june: 3.476,
            ic_july: 3.585,
            ic_august: 4.568,
            ic_september: 4.753,
            ic_october: 5.39,
            ic_november: 5.8,
            ic_december: 6.179,
            ic_yearly: 4.899,
          },
          {
            ic_uuid: "0456f0d9-9da1-428a-b983-168ee0fbd4f3",
            ic_lat: -266881,
            ic_lon: -53155,
            ic_city: "Tigrinhos",
            ic_states: "SANTA CATARINA",
            ic_january: 6.229,
            ic_february: 5.744,
            ic_march: 5.119,
            ic_april: 4.135,
            ic_may: 3.173,
            ic_june: 2.709,
            ic_july: 2.976,
            ic_august: 3.853,
            ic_september: 4.106,
            ic_october: 5.101,
            ic_november: 6.072,
            ic_december: 6.384,
            ic_yearly: 4.633,
          },
          {
            ic_uuid: "df83bcd9-33a3-498c-be98-883eccd96718",
            ic_lat: -272359,
            ic_lon: -486326,
            ic_city: "Tijucas",
            ic_states: "SANTA CATARINA",
            ic_january: 5.607,
            ic_february: 5.284,
            ic_march: 4.508,
            ic_april: 3.624,
            ic_may: 3.008,
            ic_june: 2.504,
            ic_july: 2.685,
            ic_august: 3.297,
            ic_september: 3.434,
            ic_october: 3.985,
            ic_november: 5.174,
            ic_december: 5.627,
            ic_yearly: 4.061,
          },
          {
            ic_uuid: "baa35e89-45b1-4c2f-96a1-35c0be28b6df",
            ic_lat: -259315,
            ic_lon: -491954,
            ic_city: "Tijucas do Sul",
            ic_states: "PARANÁ",
            ic_january: 5.311,
            ic_february: 5.063,
            ic_march: 4.431,
            ic_april: 3.675,
            ic_may: 3.006,
            ic_june: 2.652,
            ic_july: 2.8,
            ic_august: 3.73,
            ic_september: 3.729,
            ic_october: 4.267,
            ic_november: 5.107,
            ic_december: 5.445,
            ic_yearly: 4.101,
          },
          {
            ic_uuid: "48ada672-5ebd-48e0-8b96-b859018305a6",
            ic_lat: -75104,
            ic_lon: -353172,
            ic_city: "Timbaúba",
            ic_states: "PERNAMBUCO",
            ic_january: 5.528,
            ic_february: 5.706,
            ic_march: 5.718,
            ic_april: 5.157,
            ic_may: 4.485,
            ic_june: 4.138,
            ic_july: 4.243,
            ic_august: 4.985,
            ic_september: 5.427,
            ic_october: 5.615,
            ic_november: 5.885,
            ic_december: 5.773,
            ic_yearly: 5.222,
          },
          {
            ic_uuid: "b8039866-6476-4f2e-a8df-f50564ce0534",
            ic_lat: -64625,
            ic_lon: -372733,
            ic_city: "Timbaúba dos Batistas",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.142,
            ic_february: 6.271,
            ic_march: 6.18,
            ic_april: 5.862,
            ic_may: 5.295,
            ic_june: 4.835,
            ic_july: 5.12,
            ic_august: 5.856,
            ic_september: 6.389,
            ic_october: 6.606,
            ic_november: 6.579,
            ic_december: 6.257,
            ic_yearly: 5.949,
          },
          {
            ic_uuid: "0304b09a-3ba0-4c66-978b-5caa444aed51",
            ic_lat: -288292,
            ic_lon: -498425,
            ic_city: "Timbé do Sul",
            ic_states: "SANTA CATARINA",
            ic_january: 5.061,
            ic_february: 4.836,
            ic_march: 4.229,
            ic_april: 3.656,
            ic_may: 2.829,
            ic_june: 2.42,
            ic_july: 2.703,
            ic_august: 3.362,
            ic_september: 3.329,
            ic_october: 3.994,
            ic_november: 5.192,
            ic_december: 5.462,
            ic_yearly: 3.923,
          },
          {
            ic_uuid: "56715738-8043-4d0e-a3f5-4165108ee76f",
            ic_lat: -42564,
            ic_lon: -439323,
            ic_city: "Timbiras",
            ic_states: "MARANHÃO",
            ic_january: 4.85,
            ic_february: 4.939,
            ic_march: 5.021,
            ic_april: 4.894,
            ic_may: 4.905,
            ic_june: 5.11,
            ic_july: 5.381,
            ic_august: 6.019,
            ic_september: 6.314,
            ic_october: 6.038,
            ic_november: 5.674,
            ic_december: 5.256,
            ic_yearly: 5.367,
          },
          {
            ic_uuid: "85355d36-192d-4df4-b2d1-657a8be25e38",
            ic_lat: -268251,
            ic_lon: -492695,
            ic_city: "Timbó",
            ic_states: "SANTA CATARINA",
            ic_january: 5.409,
            ic_february: 5.083,
            ic_march: 4.473,
            ic_april: 3.662,
            ic_may: 3.004,
            ic_june: 2.456,
            ic_july: 2.616,
            ic_august: 3.295,
            ic_september: 3.335,
            ic_october: 3.838,
            ic_november: 5.02,
            ic_december: 5.373,
            ic_yearly: 3.964,
          },
          {
            ic_uuid: "58bdbf5f-3efd-44ab-9694-1d43b586ce87",
            ic_lat: -266131,
            ic_lon: -506612,
            ic_city: "Timbó Grande",
            ic_states: "SANTA CATARINA",
            ic_january: 5.353,
            ic_february: 5.083,
            ic_march: 4.513,
            ic_april: 3.6,
            ic_may: 2.872,
            ic_june: 2.476,
            ic_july: 2.747,
            ic_august: 3.673,
            ic_september: 3.762,
            ic_october: 4.344,
            ic_november: 5.402,
            ic_december: 5.513,
            ic_yearly: 4.112,
          },
          {
            ic_uuid: "4ec6a912-1fd4-4630-b33f-375d2702b29b",
            ic_lat: -23205,
            ic_lon: -496069,
            ic_city: "Timburi",
            ic_states: "SÃO PAULO",
            ic_january: 5.698,
            ic_february: 5.82,
            ic_march: 5.208,
            ic_april: 4.609,
            ic_may: 3.65,
            ic_june: 3.335,
            ic_july: 3.546,
            ic_august: 4.534,
            ic_september: 4.664,
            ic_october: 5.351,
            ic_november: 5.921,
            ic_december: 6.332,
            ic_yearly: 4.889,
          },
          {
            ic_uuid: "f7c378ac-66d4-4f85-bca3-dc8721816a2e",
            ic_lat: -50981,
            ic_lon: -428333,
            ic_city: "Timon",
            ic_states: "MARANHÃO",
            ic_january: 5.108,
            ic_february: 5.217,
            ic_march: 5.151,
            ic_april: 5.035,
            ic_may: 5.053,
            ic_june: 5.171,
            ic_july: 5.465,
            ic_august: 6.03,
            ic_september: 6.46,
            ic_october: 6.353,
            ic_november: 6.163,
            ic_december: 5.652,
            ic_yearly: 5.572,
          },
          {
            ic_uuid: "dfd9442c-b03a-469c-96da-52630f2e9fcd",
            ic_lat: -195764,
            ic_lon: -42648,
            ic_city: "Timóteo",
            ic_states: "MINAS GERAIS",
            ic_january: 5.768,
            ic_february: 6.039,
            ic_march: 5.019,
            ic_april: 4.579,
            ic_may: 3.812,
            ic_june: 3.684,
            ic_july: 3.879,
            ic_august: 4.563,
            ic_september: 4.905,
            ic_october: 5.078,
            ic_november: 4.823,
            ic_december: 5.549,
            ic_yearly: 4.808,
          },
          {
            ic_uuid: "d86e1299-7580-4f05-bbbe-cf21d8a27656",
            ic_lat: -285717,
            ic_lon: -52596,
            ic_city: "Tio Hugo",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.285,
            ic_february: 5.871,
            ic_march: 5.011,
            ic_april: 3.99,
            ic_may: 3.007,
            ic_june: 2.467,
            ic_july: 2.772,
            ic_august: 3.555,
            ic_september: 3.828,
            ic_october: 5.024,
            ic_november: 6.261,
            ic_december: 6.637,
            ic_yearly: 4.559,
          },
          {
            ic_uuid: "5e7250d2-e066-4eea-8ee3-c8ff011acff7",
            ic_lat: -211107,
            ic_lon: -441748,
            ic_city: "Tiradentes",
            ic_states: "MINAS GERAIS",
            ic_january: 5.534,
            ic_february: 5.793,
            ic_march: 4.854,
            ic_april: 4.418,
            ic_may: 3.817,
            ic_june: 3.653,
            ic_july: 3.877,
            ic_august: 4.805,
            ic_september: 5.001,
            ic_october: 5.216,
            ic_november: 4.979,
            ic_december: 5.55,
            ic_yearly: 4.791,
          },
          {
            ic_uuid: "8860a7ad-754b-4a9d-94f1-e74866d504b3",
            ic_lat: -274027,
            ic_lon: -540818,
            ic_city: "Tiradentes do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.426,
            ic_february: 5.927,
            ic_march: 5.133,
            ic_april: 4.05,
            ic_may: 3.116,
            ic_june: 2.56,
            ic_july: 2.874,
            ic_august: 3.683,
            ic_september: 4.01,
            ic_october: 5.219,
            ic_november: 6.212,
            ic_december: 6.635,
            ic_yearly: 4.653,
          },
          {
            ic_uuid: "29283d81-46db-4241-bb66-cd32c3035d02",
            ic_lat: -190042,
            ic_lon: -45963,
            ic_city: "Tiros",
            ic_states: "MINAS GERAIS",
            ic_january: 5.714,
            ic_february: 6.029,
            ic_march: 5.04,
            ic_april: 4.986,
            ic_may: 4.592,
            ic_june: 4.351,
            ic_july: 4.666,
            ic_august: 5.627,
            ic_september: 5.678,
            ic_october: 5.575,
            ic_november: 5.154,
            ic_december: 5.519,
            ic_yearly: 5.244,
          },
          {
            ic_uuid: "3a516bf9-35de-45c4-9e5b-f438e13896f6",
            ic_lat: -111802,
            ic_lon: -379998,
            ic_city: "Tobias Barreto",
            ic_states: "SERGIPE",
            ic_january: 6.141,
            ic_february: 5.888,
            ic_march: 5.818,
            ic_april: 4.939,
            ic_may: 4.136,
            ic_june: 3.839,
            ic_july: 4.043,
            ic_august: 4.524,
            ic_september: 5.203,
            ic_october: 5.584,
            ic_november: 5.995,
            ic_december: 6.088,
            ic_yearly: 5.183,
          },
          {
            ic_uuid: "f7d322a3-7aaf-4d50-93ae-799eb968a931",
            ic_lat: -95636,
            ic_lon: -483745,
            ic_city: "Tocantínia",
            ic_states: "TOCANTINS",
            ic_january: 5.029,
            ic_february: 5.063,
            ic_march: 4.895,
            ic_april: 4.954,
            ic_may: 5.087,
            ic_june: 5.107,
            ic_july: 5.325,
            ic_august: 6.077,
            ic_september: 5.824,
            ic_october: 5.359,
            ic_november: 5.107,
            ic_december: 5.062,
            ic_yearly: 5.241,
          },
          {
            ic_uuid: "997093c3-74c9-40f3-8e51-ca1ee10e2988",
            ic_lat: -63249,
            ic_lon: -474228,
            ic_city: "Tocantinópolis",
            ic_states: "TOCANTINS",
            ic_january: 4.765,
            ic_february: 4.887,
            ic_march: 4.897,
            ic_april: 4.902,
            ic_may: 4.904,
            ic_june: 5.214,
            ic_july: 5.4,
            ic_august: 5.915,
            ic_september: 5.856,
            ic_october: 5.309,
            ic_november: 4.895,
            ic_december: 4.796,
            ic_yearly: 5.145,
          },
          {
            ic_uuid: "3a0bccda-3489-4b60-98db-fc09f3552000",
            ic_lat: -211779,
            ic_lon: -430131,
            ic_city: "Tocantins",
            ic_states: "MINAS GERAIS",
            ic_january: 5.651,
            ic_february: 5.945,
            ic_march: 4.908,
            ic_april: 4.321,
            ic_may: 3.656,
            ic_june: 3.492,
            ic_july: 3.68,
            ic_august: 4.469,
            ic_september: 4.788,
            ic_october: 5.026,
            ic_november: 4.815,
            ic_december: 5.54,
            ic_yearly: 4.691,
          },
          {
            ic_uuid: "58b0f49b-8caa-4153-9502-25de4056422c",
            ic_lat: -223703,
            ic_lon: -460975,
            ic_city: "Tocos do Moji",
            ic_states: "MINAS GERAIS",
            ic_january: 5.217,
            ic_february: 5.595,
            ic_march: 4.82,
            ic_april: 4.516,
            ic_may: 3.815,
            ic_june: 3.543,
            ic_july: 3.829,
            ic_august: 4.733,
            ic_september: 4.895,
            ic_october: 5.321,
            ic_november: 5.32,
            ic_december: 5.651,
            ic_yearly: 4.771,
          },
          {
            ic_uuid: "4cc7f1ce-a1f3-441b-bcaa-2ac7a0e85f96",
            ic_lat: -227426,
            ic_lon: -463732,
            ic_city: "Toledo",
            ic_states: "MINAS GERAIS",
            ic_january: 5.171,
            ic_february: 5.459,
            ic_march: 4.747,
            ic_april: 4.482,
            ic_may: 3.804,
            ic_june: 3.579,
            ic_july: 3.754,
            ic_august: 4.691,
            ic_september: 4.876,
            ic_october: 5.356,
            ic_november: 5.411,
            ic_december: 5.688,
            ic_yearly: 4.752,
          },
          {
            ic_uuid: "964cb46e-7143-49cc-8036-b20a921c2be4",
            ic_lat: -247251,
            ic_lon: -537416,
            ic_city: "Toledo",
            ic_states: "PARANÁ",
            ic_january: 6.285,
            ic_february: 5.631,
            ic_march: 5.207,
            ic_april: 4.325,
            ic_may: 3.384,
            ic_june: 3.008,
            ic_july: 3.198,
            ic_august: 4.206,
            ic_september: 4.443,
            ic_october: 5.25,
            ic_november: 6.108,
            ic_december: 6.515,
            ic_yearly: 4.797,
          },
          {
            ic_uuid: "5caaf8ad-c9f0-4e9b-861c-d8dcdc2f76a9",
            ic_lat: -113698,
            ic_lon: -378437,
            ic_city: "Tomar do Geru",
            ic_states: "SERGIPE",
            ic_january: 6.178,
            ic_february: 5.987,
            ic_march: 5.773,
            ic_april: 4.862,
            ic_may: 4.133,
            ic_june: 3.917,
            ic_july: 4.052,
            ic_august: 4.528,
            ic_september: 5.284,
            ic_october: 5.638,
            ic_november: 6.009,
            ic_december: 6.217,
            ic_yearly: 5.215,
          },
          {
            ic_uuid: "4b31b403-d617-47c4-a38a-56e96f249310",
            ic_lat: -237801,
            ic_lon: -499504,
            ic_city: "Tomazina",
            ic_states: "PARANÁ",
            ic_january: 5.706,
            ic_february: 5.76,
            ic_march: 5.133,
            ic_april: 4.502,
            ic_may: 3.574,
            ic_june: 3.184,
            ic_july: 3.445,
            ic_august: 4.431,
            ic_september: 4.637,
            ic_october: 5.231,
            ic_november: 5.972,
            ic_december: 6.304,
            ic_yearly: 4.823,
          },
          {
            ic_uuid: "5d6b09d8-200c-4559-8e55-7a80915a36d8",
            ic_lat: -209091,
            ic_lon: -420231,
            ic_city: "Tombos",
            ic_states: "MINAS GERAIS",
            ic_january: 6.017,
            ic_february: 6.202,
            ic_march: 5.084,
            ic_april: 4.526,
            ic_may: 3.813,
            ic_june: 3.671,
            ic_july: 3.8,
            ic_august: 4.574,
            ic_september: 4.937,
            ic_october: 5.111,
            ic_november: 4.898,
            ic_december: 5.701,
            ic_yearly: 4.861,
          },
          {
            ic_uuid: "489b5868-4cf4-4209-bb15-dd12d091a088",
            ic_lat: -24184,
            ic_lon: -481545,
            ic_city: "Tomé-Açu",
            ic_states: "PARÁ",
            ic_january: 4.596,
            ic_february: 4.575,
            ic_march: 4.678,
            ic_april: 4.671,
            ic_may: 4.684,
            ic_june: 4.909,
            ic_july: 5.011,
            ic_august: 5.278,
            ic_september: 5.273,
            ic_october: 5.079,
            ic_november: 4.868,
            ic_december: 4.663,
            ic_yearly: 4.857,
          },
          {
            ic_uuid: "00d5b548-b0f9-4109-ac6e-e999714e0fb6",
            ic_lat: -28662,
            ic_lon: -677924,
            ic_city: "Tonantins",
            ic_states: "AMAZONAS",
            ic_january: 4.397,
            ic_february: 4.75,
            ic_march: 4.514,
            ic_april: 4.374,
            ic_may: 4.004,
            ic_june: 4.11,
            ic_july: 4.125,
            ic_august: 4.813,
            ic_september: 5.062,
            ic_october: 4.966,
            ic_november: 4.786,
            ic_december: 4.494,
            ic_yearly: 4.533,
          },
          {
            ic_uuid: "0731ae7c-9f3f-4abc-9e5e-037d181a9a5c",
            ic_lat: -80042,
            ic_lon: -360569,
            ic_city: "Toritama",
            ic_states: "PERNAMBUCO",
            ic_january: 5.851,
            ic_february: 5.807,
            ic_march: 5.869,
            ic_april: 5.328,
            ic_may: 4.543,
            ic_june: 4.028,
            ic_july: 4.127,
            ic_august: 4.798,
            ic_september: 5.509,
            ic_october: 5.872,
            ic_november: 6.216,
            ic_december: 6.07,
            ic_yearly: 5.335,
          },
          {
            ic_uuid: "e19c73c6-c080-42fb-bdc2-d2845bbb4740",
            ic_lat: -16201,
            ic_lon: -525575,
            ic_city: "Torixoréu",
            ic_states: "MATO GROSSO",
            ic_january: 5.522,
            ic_february: 5.633,
            ic_march: 5.249,
            ic_april: 5.319,
            ic_may: 4.881,
            ic_june: 4.549,
            ic_july: 4.67,
            ic_august: 5.438,
            ic_september: 5.424,
            ic_october: 5.462,
            ic_november: 5.534,
            ic_december: 5.629,
            ic_yearly: 5.276,
          },
          {
            ic_uuid: "d7d43fbb-cd9e-4a0b-bc3e-817f65945227",
            ic_lat: -294787,
            ic_lon: -542249,
            ic_city: "Toropi",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.484,
            ic_february: 5.952,
            ic_march: 5.054,
            ic_april: 3.932,
            ic_may: 2.901,
            ic_june: 2.412,
            ic_july: 2.677,
            ic_august: 3.418,
            ic_september: 3.864,
            ic_october: 4.997,
            ic_november: 6.3,
            ic_december: 6.819,
            ic_yearly: 4.567,
          },
          {
            ic_uuid: "78260397-4bb9-4394-b301-38dc95b961bb",
            ic_lat: -232466,
            ic_lon: -481959,
            ic_city: "Torre de Pedra",
            ic_states: "SÃO PAULO",
            ic_january: 5.609,
            ic_february: 5.775,
            ic_march: 5.055,
            ic_april: 4.525,
            ic_may: 3.649,
            ic_june: 3.391,
            ic_july: 3.537,
            ic_august: 4.526,
            ic_september: 4.691,
            ic_october: 5.26,
            ic_november: 5.733,
            ic_december: 6.101,
            ic_yearly: 4.821,
          },
          {
            ic_uuid: "83e219ef-8e6d-47eb-bcad-7af7442548d8",
            ic_lat: -293339,
            ic_lon: -497337,
            ic_city: "Torres",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 5.85,
            ic_february: 5.422,
            ic_march: 4.581,
            ic_april: 3.839,
            ic_may: 2.964,
            ic_june: 2.444,
            ic_july: 2.658,
            ic_august: 3.336,
            ic_september: 3.525,
            ic_october: 4.342,
            ic_november: 5.735,
            ic_december: 6.082,
            ic_yearly: 4.232,
          },
          {
            ic_uuid: "0e54858b-8da4-42b5-af3d-754ee4cfeb28",
            ic_lat: -22426,
            ic_lon: -48169,
            ic_city: "Torrinha",
            ic_states: "SÃO PAULO",
            ic_january: 5.503,
            ic_february: 5.752,
            ic_march: 5.09,
            ic_april: 4.625,
            ic_may: 3.858,
            ic_june: 3.596,
            ic_july: 3.774,
            ic_august: 4.743,
            ic_september: 4.895,
            ic_october: 5.463,
            ic_november: 5.767,
            ic_december: 6.029,
            ic_yearly: 4.925,
          },
          {
            ic_uuid: "8d5f3c10-1a47-44ad-b5ac-5742fc9fb80e",
            ic_lat: -51993,
            ic_lon: -354597,
            ic_city: "Touros",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.174,
            ic_february: 6.215,
            ic_march: 6.102,
            ic_april: 5.655,
            ic_may: 5.219,
            ic_june: 4.881,
            ic_july: 5.044,
            ic_august: 5.729,
            ic_september: 6.208,
            ic_october: 6.176,
            ic_november: 6.37,
            ic_december: 6.119,
            ic_yearly: 5.824,
          },
          {
            ic_uuid: "c1606b2b-4019-4606-8084-f335cde7e7df",
            ic_lat: -220393,
            ic_lon: -483346,
            ic_city: "Trabiju",
            ic_states: "SÃO PAULO",
            ic_january: 5.662,
            ic_february: 5.824,
            ic_march: 5.139,
            ic_april: 4.709,
            ic_may: 3.939,
            ic_june: 3.682,
            ic_july: 3.892,
            ic_august: 4.831,
            ic_september: 4.896,
            ic_october: 5.575,
            ic_november: 5.787,
            ic_december: 6.118,
            ic_yearly: 5.005,
          },
          {
            ic_uuid: "dc12295f-3083-4f8e-8017-20ab6a928242",
            ic_lat: -10784,
            ic_lon: -469026,
            ic_city: "Tracuateua",
            ic_states: "PARÁ",
            ic_january: 4.537,
            ic_february: 4.317,
            ic_march: 4.147,
            ic_april: 4.216,
            ic_may: 4.476,
            ic_june: 4.784,
            ic_july: 4.879,
            ic_august: 5.231,
            ic_september: 5.537,
            ic_october: 5.363,
            ic_november: 5.211,
            ic_december: 4.865,
            ic_yearly: 4.797,
          },
          {
            ic_uuid: "0da09725-e30f-43dc-81c8-eb588f806e99",
            ic_lat: -78033,
            ic_lon: -352363,
            ic_city: "Tracunhaém",
            ic_states: "PERNAMBUCO",
            ic_january: 5.653,
            ic_february: 5.774,
            ic_march: 5.774,
            ic_april: 5.172,
            ic_may: 4.471,
            ic_june: 4.129,
            ic_july: 4.23,
            ic_august: 4.947,
            ic_september: 5.482,
            ic_october: 5.696,
            ic_november: 5.967,
            ic_december: 5.88,
            ic_yearly: 5.265,
          },
          {
            ic_uuid: "31b3ab41-daad-4eb4-b02a-c9532002cad1",
            ic_lat: -99628,
            ic_lon: -370025,
            ic_city: "Traipu",
            ic_states: "ALAGOAS",
            ic_january: 6.289,
            ic_february: 6.051,
            ic_march: 5.999,
            ic_april: 5.276,
            ic_may: 4.383,
            ic_june: 4.141,
            ic_july: 4.214,
            ic_august: 4.867,
            ic_september: 5.694,
            ic_october: 5.93,
            ic_november: 6.379,
            ic_december: 6.328,
            ic_yearly: 5.463,
          },
          {
            ic_uuid: "8e2937f4-1176-4c9b-b715-065cf9778fad",
            ic_lat: -45739,
            ic_lon: -559433,
            ic_city: "Trairão",
            ic_states: "PARÁ",
            ic_january: 4.049,
            ic_february: 4.167,
            ic_march: 4.124,
            ic_april: 3.974,
            ic_may: 4.066,
            ic_june: 4.476,
            ic_july: 4.464,
            ic_august: 5.088,
            ic_september: 5.068,
            ic_october: 5.065,
            ic_november: 4.818,
            ic_december: 4.49,
            ic_yearly: 4.487,
          },
          {
            ic_uuid: "da51c381-9b15-4e83-9a41-f9a6a3a75313",
            ic_lat: -32697,
            ic_lon: -392684,
            ic_city: "Trairi",
            ic_states: "CEARÁ",
            ic_january: 5.63,
            ic_february: 5.675,
            ic_march: 5.449,
            ic_april: 4.731,
            ic_may: 5.134,
            ic_june: 5.152,
            ic_july: 5.378,
            ic_august: 5.784,
            ic_september: 5.969,
            ic_october: 6.023,
            ic_november: 6.265,
            ic_december: 5.935,
            ic_yearly: 5.594,
          },
          {
            ic_uuid: "c0c0668f-d834-4214-a6ad-3819002cda2f",
            ic_lat: -220631,
            ic_lon: -42071,
            ic_city: "Trajano de Moraes",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.857,
            ic_february: 6.142,
            ic_march: 4.888,
            ic_april: 4.231,
            ic_may: 3.554,
            ic_june: 3.323,
            ic_july: 3.428,
            ic_august: 4.161,
            ic_september: 4.504,
            ic_october: 4.753,
            ic_november: 4.706,
            ic_december: 5.581,
            ic_yearly: 4.594,
          },
          {
            ic_uuid: "5a7c7b8a-3d44-4105-913e-1749694bacf4",
            ic_lat: -299846,
            ic_lon: -501326,
            ic_city: "Tramandaí",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.36,
            ic_february: 5.728,
            ic_march: 4.757,
            ic_april: 3.851,
            ic_may: 2.861,
            ic_june: 2.371,
            ic_july: 2.54,
            ic_august: 3.202,
            ic_september: 3.592,
            ic_october: 4.694,
            ic_november: 6.129,
            ic_december: 6.578,
            ic_yearly: 4.389,
          },
          {
            ic_uuid: "b5837d12-8d5a-4e59-9a52-cc45038e918a",
            ic_lat: -292981,
            ic_lon: -520537,
            ic_city: "Travesseiro",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.087,
            ic_february: 5.708,
            ic_march: 4.785,
            ic_april: 3.789,
            ic_may: 2.82,
            ic_june: 2.354,
            ic_july: 2.607,
            ic_august: 3.329,
            ic_september: 3.592,
            ic_october: 4.658,
            ic_november: 6.046,
            ic_december: 6.438,
            ic_yearly: 4.351,
          },
          {
            ic_uuid: "fd4440b1-dd0d-42e7-98a8-1b45cdc56509",
            ic_lat: -149769,
            ic_lon: -41409,
            ic_city: "Tremedal",
            ic_states: "BAHIA",
            ic_january: 6.161,
            ic_february: 6.293,
            ic_march: 5.614,
            ic_april: 4.875,
            ic_may: 4.263,
            ic_june: 3.864,
            ic_july: 4.125,
            ic_august: 4.709,
            ic_september: 5.616,
            ic_october: 5.756,
            ic_november: 5.572,
            ic_december: 6.149,
            ic_yearly: 5.25,
          },
          {
            ic_uuid: "4b236919-aee1-461b-a428-2fd7515d2ffb",
            ic_lat: -229576,
            ic_lon: -455479,
            ic_city: "Tremembé",
            ic_states: "SÃO PAULO",
            ic_january: 5.383,
            ic_february: 5.676,
            ic_march: 4.833,
            ic_april: 4.406,
            ic_may: 3.583,
            ic_june: 3.326,
            ic_july: 3.446,
            ic_august: 4.406,
            ic_september: 4.525,
            ic_october: 5.066,
            ic_november: 5.247,
            ic_december: 5.781,
            ic_yearly: 4.64,
          },
          {
            ic_uuid: "9a5ac032-a8a4-4292-828c-18e180091954",
            ic_lat: -275007,
            ic_lon: -521452,
            ic_city: "Três Arroios",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.314,
            ic_february: 5.852,
            ic_march: 5.077,
            ic_april: 4.073,
            ic_may: 3.083,
            ic_june: 2.606,
            ic_july: 2.938,
            ic_august: 3.735,
            ic_september: 3.98,
            ic_october: 5.101,
            ic_november: 6.241,
            ic_december: 6.564,
            ic_yearly: 4.63,
          },
          {
            ic_uuid: "54438d67-126c-4915-914a-ef1430490cc5",
            ic_lat: -261061,
            ic_lon: -503201,
            ic_city: "Três Barras",
            ic_states: "SANTA CATARINA",
            ic_january: 5.422,
            ic_february: 5.122,
            ic_march: 4.521,
            ic_april: 3.545,
            ic_may: 2.843,
            ic_june: 2.444,
            ic_july: 2.688,
            ic_august: 3.696,
            ic_september: 3.816,
            ic_october: 4.384,
            ic_november: 5.369,
            ic_december: 5.6,
            ic_yearly: 4.121,
          },
          {
            ic_uuid: "a2611f9b-3c80-45a6-be30-a5808f02061c",
            ic_lat: -25419,
            ic_lon: -531838,
            ic_city: "Três Barras do Paraná",
            ic_states: "PARANÁ",
            ic_january: 6.236,
            ic_february: 5.638,
            ic_march: 5.201,
            ic_april: 4.261,
            ic_may: 3.306,
            ic_june: 2.882,
            ic_july: 3.1,
            ic_august: 4.129,
            ic_september: 4.281,
            ic_october: 5.19,
            ic_november: 6.054,
            ic_december: 6.322,
            ic_yearly: 4.717,
          },
          {
            ic_uuid: "8510de93-f11b-4efe-859b-2eea98d10a59",
            ic_lat: -29456,
            ic_lon: -499239,
            ic_city: "Três Cachoeiras",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 5.532,
            ic_february: 5.071,
            ic_march: 4.335,
            ic_april: 3.747,
            ic_may: 2.886,
            ic_june: 2.427,
            ic_july: 2.623,
            ic_august: 3.309,
            ic_september: 3.414,
            ic_october: 4.123,
            ic_november: 5.469,
            ic_december: 5.696,
            ic_yearly: 4.053,
          },
          {
            ic_uuid: "07528b75-1466-4536-8735-33b0344d03fc",
            ic_lat: -216926,
            ic_lon: -452515,
            ic_city: "Três Corações",
            ic_states: "MINAS GERAIS",
            ic_january: 5.463,
            ic_february: 5.653,
            ic_march: 4.847,
            ic_april: 4.603,
            ic_may: 3.898,
            ic_june: 3.657,
            ic_july: 3.903,
            ic_august: 4.773,
            ic_september: 5.034,
            ic_october: 5.394,
            ic_november: 5.216,
            ic_december: 5.624,
            ic_yearly: 4.839,
          },
          {
            ic_uuid: "7a245885-0996-4b2a-b872-5973d9f85fd6",
            ic_lat: -295142,
            ic_lon: -507743,
            ic_city: "Três Coroas",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.031,
            ic_february: 5.587,
            ic_march: 4.644,
            ic_april: 3.767,
            ic_may: 2.802,
            ic_june: 2.376,
            ic_july: 2.591,
            ic_august: 3.289,
            ic_september: 3.531,
            ic_october: 4.579,
            ic_november: 6.008,
            ic_december: 6.345,
            ic_yearly: 4.296,
          },
          {
            ic_uuid: "5dd6968b-c3a4-43a8-9c25-faee4828935c",
            ic_lat: -277805,
            ic_lon: -542362,
            ic_city: "Três de Maio",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.503,
            ic_february: 6.051,
            ic_march: 5.125,
            ic_april: 4.085,
            ic_may: 3.112,
            ic_june: 2.563,
            ic_july: 2.869,
            ic_august: 3.672,
            ic_september: 4.023,
            ic_october: 5.183,
            ic_november: 6.225,
            ic_december: 6.689,
            ic_yearly: 4.675,
          },
          {
            ic_uuid: "9a75a625-23b2-408f-9038-bfcb4be43946",
            ic_lat: -29537,
            ic_lon: -500644,
            ic_city: "Três Forquilhas",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 5.529,
            ic_february: 5.067,
            ic_march: 4.316,
            ic_april: 3.725,
            ic_may: 2.825,
            ic_june: 2.405,
            ic_july: 2.631,
            ic_august: 3.31,
            ic_september: 3.409,
            ic_october: 4.195,
            ic_november: 5.499,
            ic_december: 5.808,
            ic_yearly: 4.06,
          },
          {
            ic_uuid: "e6df21a8-50de-4f43-9843-d2325ae73c2d",
            ic_lat: -20235,
            ic_lon: -508899,
            ic_city: "Três Fronteiras",
            ic_states: "SÃO PAULO",
            ic_january: 5.796,
            ic_february: 5.992,
            ic_march: 5.407,
            ic_april: 4.944,
            ic_may: 4.23,
            ic_june: 3.959,
            ic_july: 4.168,
            ic_august: 5.101,
            ic_september: 5.078,
            ic_october: 5.709,
            ic_november: 5.993,
            ic_december: 6.293,
            ic_yearly: 5.223,
          },
          {
            ic_uuid: "a951019b-15f3-44a4-899c-09addea2c18f",
            ic_lat: -207853,
            ic_lon: -517012,
            ic_city: "Três Lagoas",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 5.901,
            ic_february: 5.973,
            ic_march: 5.46,
            ic_april: 4.841,
            ic_may: 4.089,
            ic_june: 3.789,
            ic_july: 3.994,
            ic_august: 4.923,
            ic_september: 4.948,
            ic_october: 5.6,
            ic_november: 6.012,
            ic_december: 6.346,
            ic_yearly: 5.156,
          },
          {
            ic_uuid: "4dfbb9b9-56fd-4c55-8add-3eecc7b664df",
            ic_lat: -182022,
            ic_lon: -452285,
            ic_city: "Três Marias",
            ic_states: "MINAS GERAIS",
            ic_january: 6.011,
            ic_february: 6.302,
            ic_march: 5.468,
            ic_april: 5.294,
            ic_may: 4.775,
            ic_june: 4.48,
            ic_july: 4.847,
            ic_august: 5.546,
            ic_september: 5.857,
            ic_october: 5.896,
            ic_november: 5.542,
            ic_december: 5.889,
            ic_yearly: 5.492,
          },
          {
            ic_uuid: "0fa0a31d-8b3c-493c-a691-75e145bd2430",
            ic_lat: -276144,
            ic_lon: -528442,
            ic_city: "Três Palmeiras",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.225,
            ic_february: 5.809,
            ic_march: 5.034,
            ic_april: 4.088,
            ic_may: 3.091,
            ic_june: 2.6,
            ic_july: 2.931,
            ic_august: 3.717,
            ic_september: 3.976,
            ic_october: 5.128,
            ic_november: 6.169,
            ic_december: 6.577,
            ic_yearly: 4.612,
          },
          {
            ic_uuid: "bd4b9df4-0263-4678-b563-7cb16d95be22",
            ic_lat: -274559,
            ic_lon: -539301,
            ic_city: "Três Passos",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.399,
            ic_february: 5.932,
            ic_march: 5.137,
            ic_april: 4.061,
            ic_may: 3.122,
            ic_june: 2.582,
            ic_july: 2.909,
            ic_august: 3.7,
            ic_september: 4.009,
            ic_october: 5.22,
            ic_november: 6.219,
            ic_december: 6.649,
            ic_yearly: 4.662,
          },
          {
            ic_uuid: "43a135d7-5dd1-4700-b9fd-371a6e6c8890",
            ic_lat: -213699,
            ic_lon: -455113,
            ic_city: "Três Pontas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.567,
            ic_february: 5.735,
            ic_march: 4.851,
            ic_april: 4.572,
            ic_may: 3.987,
            ic_june: 3.764,
            ic_july: 4.029,
            ic_august: 4.925,
            ic_september: 5.1,
            ic_october: 5.396,
            ic_november: 5.194,
            ic_december: 5.579,
            ic_yearly: 4.891,
          },
          {
            ic_uuid: "76574d33-bdce-439e-bb94-0add04589b45",
            ic_lat: -18358,
            ic_lon: -477779,
            ic_city: "Três Ranchos",
            ic_states: "GOIÁS",
            ic_january: 5.687,
            ic_february: 6.026,
            ic_march: 5.304,
            ic_april: 5.219,
            ic_may: 4.726,
            ic_june: 4.441,
            ic_july: 4.621,
            ic_august: 5.551,
            ic_september: 5.52,
            ic_october: 5.682,
            ic_november: 5.585,
            ic_december: 5.814,
            ic_yearly: 5.348,
          },
          {
            ic_uuid: "6576dfc4-5dd2-4d9e-a85a-f0d092186084",
            ic_lat: -22117,
            ic_lon: -432189,
            ic_city: "Três Rios",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.709,
            ic_february: 5.979,
            ic_march: 4.991,
            ic_april: 4.355,
            ic_may: 3.583,
            ic_june: 3.381,
            ic_july: 3.534,
            ic_august: 4.29,
            ic_september: 4.582,
            ic_october: 4.947,
            ic_november: 4.915,
            ic_december: 5.658,
            ic_yearly: 4.66,
          },
          {
            ic_uuid: "92585895-b20a-424a-9e8f-a0782dd9d6d4",
            ic_lat: -285101,
            ic_lon: -494638,
            ic_city: "Treviso",
            ic_states: "SANTA CATARINA",
            ic_january: 5.164,
            ic_february: 4.818,
            ic_march: 4.239,
            ic_april: 3.652,
            ic_may: 2.878,
            ic_june: 2.488,
            ic_july: 2.685,
            ic_august: 3.412,
            ic_september: 3.408,
            ic_october: 4.02,
            ic_november: 5.25,
            ic_december: 5.496,
            ic_yearly: 3.959,
          },
          {
            ic_uuid: "6579d81f-f403-44b4-a34c-aebc4e7f32f1",
            ic_lat: -285541,
            ic_lon: -49157,
            ic_city: "Treze de Maio",
            ic_states: "SANTA CATARINA",
            ic_january: 5.775,
            ic_february: 5.318,
            ic_march: 4.534,
            ic_april: 3.789,
            ic_may: 2.987,
            ic_june: 2.496,
            ic_july: 2.71,
            ic_august: 3.44,
            ic_september: 3.538,
            ic_october: 4.378,
            ic_november: 5.721,
            ic_december: 6.053,
            ic_yearly: 4.228,
          },
          {
            ic_uuid: "b2840b90-f11c-430b-977b-d4eed870a7a1",
            ic_lat: -270031,
            ic_lon: -514089,
            ic_city: "Treze Tílias",
            ic_states: "SANTA CATARINA",
            ic_january: 5.734,
            ic_february: 5.328,
            ic_march: 4.897,
            ic_april: 4.002,
            ic_may: 3.071,
            ic_june: 2.718,
            ic_july: 2.92,
            ic_august: 3.821,
            ic_september: 4.028,
            ic_october: 4.866,
            ic_november: 5.956,
            ic_december: 6.112,
            ic_yearly: 4.455,
          },
          {
            ic_uuid: "27915e66-ff91-483d-b31c-190d50a47866",
            ic_lat: -166522,
            ic_lon: -494931,
            ic_city: "Trindade",
            ic_states: "GOIÁS",
            ic_january: 5.508,
            ic_february: 5.58,
            ic_march: 5.215,
            ic_april: 5.102,
            ic_may: 4.813,
            ic_june: 4.579,
            ic_july: 4.741,
            ic_august: 5.631,
            ic_september: 5.523,
            ic_october: 5.556,
            ic_november: 5.397,
            ic_december: 5.518,
            ic_yearly: 5.264,
          },
          {
            ic_uuid: "2efbd0ac-222c-4eed-9b6a-66c808b5d08b",
            ic_lat: -77594,
            ic_lon: -40265,
            ic_city: "Trindade",
            ic_states: "PERNAMBUCO",
            ic_january: 5.87,
            ic_february: 5.58,
            ic_march: 5.761,
            ic_april: 5.199,
            ic_may: 4.74,
            ic_june: 4.484,
            ic_july: 4.745,
            ic_august: 5.73,
            ic_september: 6.344,
            ic_october: 6.421,
            ic_november: 6.489,
            ic_december: 6.268,
            ic_yearly: 5.636,
          },
          {
            ic_uuid: "a6b57ebf-f1b8-468c-85c9-ef8aa24eb59d",
            ic_lat: -275244,
            ic_lon: -52896,
            ic_city: "Trindade do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.252,
            ic_february: 5.808,
            ic_march: 5.054,
            ic_april: 4.099,
            ic_may: 3.099,
            ic_june: 2.62,
            ic_july: 2.924,
            ic_august: 3.74,
            ic_september: 3.995,
            ic_october: 5.119,
            ic_november: 6.148,
            ic_december: 6.56,
            ic_yearly: 4.618,
          },
          {
            ic_uuid: "a967be09-910b-41d3-bd30-a72f8d7525bc",
            ic_lat: -65717,
            ic_lon: -385989,
            ic_city: "Triunfo",
            ic_states: "PARAÍBA",
            ic_january: 6.079,
            ic_february: 6.043,
            ic_march: 5.918,
            ic_april: 5.675,
            ic_may: 5.179,
            ic_june: 4.966,
            ic_july: 5.271,
            ic_august: 6.036,
            ic_september: 6.463,
            ic_october: 6.58,
            ic_november: 6.74,
            ic_december: 6.256,
            ic_yearly: 5.934,
          },
          {
            ic_uuid: "3c3a76ad-3464-4a74-9be3-f5e32e764427",
            ic_lat: -78344,
            ic_lon: -381055,
            ic_city: "Triunfo",
            ic_states: "PERNAMBUCO",
            ic_january: 6.137,
            ic_february: 6.07,
            ic_march: 6.101,
            ic_april: 5.707,
            ic_may: 4.953,
            ic_june: 4.617,
            ic_july: 4.876,
            ic_august: 5.751,
            ic_september: 6.529,
            ic_october: 6.587,
            ic_november: 6.713,
            ic_december: 6.332,
            ic_yearly: 5.864,
          },
          {
            ic_uuid: "47503b92-75a8-4f86-9e0c-d99e94d7e348",
            ic_lat: -299296,
            ic_lon: -517079,
            ic_city: "Triunfo",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.393,
            ic_february: 5.842,
            ic_march: 4.866,
            ic_april: 3.792,
            ic_may: 2.765,
            ic_june: 2.293,
            ic_july: 2.502,
            ic_august: 3.234,
            ic_september: 3.583,
            ic_october: 4.774,
            ic_november: 6.204,
            ic_december: 6.666,
            ic_yearly: 4.41,
          },
          {
            ic_uuid: "d581c8ca-8eca-47b7-9063-36e7eab11054",
            ic_lat: -58672,
            ic_lon: -371864,
            ic_city: "Triunfo Potiguar",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.064,
            ic_february: 6.145,
            ic_march: 6.172,
            ic_april: 5.803,
            ic_may: 5.384,
            ic_june: 4.948,
            ic_july: 5.261,
            ic_august: 5.928,
            ic_september: 6.41,
            ic_october: 6.569,
            ic_november: 6.569,
            ic_december: 6.166,
            ic_yearly: 5.952,
          },
          {
            ic_uuid: "9cea0114-f288-47de-9f10-91ed081c4aab",
            ic_lat: -45683,
            ic_lon: -446164,
            ic_city: "Trizidela do Vale",
            ic_states: "MARANHÃO",
            ic_january: 4.752,
            ic_february: 4.851,
            ic_march: 4.943,
            ic_april: 4.865,
            ic_may: 4.823,
            ic_june: 5.064,
            ic_july: 5.298,
            ic_august: 5.822,
            ic_september: 6.181,
            ic_october: 5.73,
            ic_november: 5.315,
            ic_december: 5.013,
            ic_yearly: 5.222,
          },
          {
            ic_uuid: "1f2f8b2e-0502-4ccb-8beb-162ebb9ba615",
            ic_lat: -135083,
            ic_lon: -487421,
            ic_city: "Trombas",
            ic_states: "GOIÁS",
            ic_january: 5.374,
            ic_february: 5.555,
            ic_march: 5.154,
            ic_april: 5.189,
            ic_may: 5.087,
            ic_june: 4.883,
            ic_july: 5.256,
            ic_august: 5.964,
            ic_september: 5.784,
            ic_october: 5.616,
            ic_november: 5.157,
            ic_december: 5.358,
            ic_yearly: 5.365,
          },
          {
            ic_uuid: "503a6680-6f01-41e7-b9c6-21fbad9a643f",
            ic_lat: -273038,
            ic_lon: -497934,
            ic_city: "Trombudo Central",
            ic_states: "SANTA CATARINA",
            ic_january: 5.663,
            ic_february: 5.358,
            ic_march: 4.645,
            ic_april: 3.649,
            ic_may: 2.863,
            ic_june: 2.326,
            ic_july: 2.491,
            ic_august: 3.318,
            ic_september: 3.53,
            ic_october: 4.156,
            ic_november: 5.424,
            ic_december: 5.809,
            ic_yearly: 4.102,
          },
          {
            ic_uuid: "8e2583fc-20a3-4e30-83d0-771b16986e78",
            ic_lat: -284718,
            ic_lon: -490148,
            ic_city: "Tubarão",
            ic_states: "SANTA CATARINA",
            ic_january: 5.768,
            ic_february: 5.342,
            ic_march: 4.542,
            ic_april: 3.78,
            ic_may: 2.997,
            ic_june: 2.519,
            ic_july: 2.723,
            ic_august: 3.47,
            ic_september: 3.584,
            ic_october: 4.398,
            ic_november: 5.699,
            ic_december: 6.074,
            ic_yearly: 4.241,
          },
          {
            ic_uuid: "f5c1ad8d-c767-41d6-81f3-82070700b50c",
            ic_lat: -109588,
            ic_lon: -387897,
            ic_city: "Tucano",
            ic_states: "BAHIA",
            ic_january: 6.234,
            ic_february: 6.068,
            ic_march: 5.976,
            ic_april: 5.086,
            ic_may: 4.251,
            ic_june: 3.938,
            ic_july: 4.127,
            ic_august: 4.643,
            ic_september: 5.502,
            ic_october: 5.754,
            ic_november: 6.149,
            ic_december: 6.174,
            ic_yearly: 5.325,
          },
          {
            ic_uuid: "df28fb6f-7133-4173-b93d-07b18616766a",
            ic_lat: -67473,
            ic_lon: -51163,
            ic_city: "Tucumã",
            ic_states: "PARÁ",
            ic_january: 4.493,
            ic_february: 4.601,
            ic_march: 4.474,
            ic_april: 4.478,
            ic_may: 4.673,
            ic_june: 4.938,
            ic_july: 4.881,
            ic_august: 5.401,
            ic_september: 5.191,
            ic_october: 4.814,
            ic_november: 4.687,
            ic_december: 4.464,
            ic_yearly: 4.758,
          },
          {
            ic_uuid: "c6c1341c-ce27-4923-9db6-cb8308c6b90d",
            ic_lat: -276578,
            ic_lon: -544443,
            ic_city: "Tucunduva",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.508,
            ic_february: 5.988,
            ic_march: 5.139,
            ic_april: 4.067,
            ic_may: 3.089,
            ic_june: 2.542,
            ic_july: 2.852,
            ic_august: 3.656,
            ic_september: 4.035,
            ic_october: 5.195,
            ic_november: 6.239,
            ic_december: 6.648,
            ic_yearly: 4.663,
          },
          {
            ic_uuid: "9bd9581c-2c1e-404b-9c86-2aea64cd61e0",
            ic_lat: -37661,
            ic_lon: -496777,
            ic_city: "Tucuruí",
            ic_states: "PARÁ",
            ic_january: 4.352,
            ic_february: 4.541,
            ic_march: 4.579,
            ic_april: 4.659,
            ic_may: 4.606,
            ic_june: 4.859,
            ic_july: 4.958,
            ic_august: 5.252,
            ic_september: 5.008,
            ic_october: 4.662,
            ic_november: 4.52,
            ic_december: 4.252,
            ic_yearly: 4.687,
          },
          {
            ic_uuid: "03290a4e-299a-4f11-a396-fb44e6d1607f",
            ic_lat: -36739,
            ic_lon: -456241,
            ic_city: "Tufilândia",
            ic_states: "MARANHÃO",
            ic_january: 4.631,
            ic_february: 4.888,
            ic_march: 4.882,
            ic_april: 4.898,
            ic_may: 4.74,
            ic_june: 4.88,
            ic_july: 5.049,
            ic_august: 5.404,
            ic_september: 5.687,
            ic_october: 5.323,
            ic_november: 4.964,
            ic_december: 4.74,
            ic_yearly: 5.007,
          },
          {
            ic_uuid: "026cc183-6c2b-4605-9890-6280491d5b17",
            ic_lat: -228198,
            ic_lon: -466941,
            ic_city: "Tuiuti",
            ic_states: "SÃO PAULO",
            ic_january: 5.418,
            ic_february: 5.753,
            ic_march: 5.016,
            ic_april: 4.572,
            ic_may: 3.833,
            ic_june: 3.554,
            ic_july: 3.734,
            ic_august: 4.69,
            ic_september: 4.886,
            ic_october: 5.435,
            ic_november: 5.618,
            ic_december: 6.027,
            ic_yearly: 4.878,
          },
          {
            ic_uuid: "ddd99569-4faf-48bd-b6fe-df0fd838efd3",
            ic_lat: -189849,
            ic_lon: -41653,
            ic_city: "Tumiritinga",
            ic_states: "MINAS GERAIS",
            ic_january: 6.183,
            ic_february: 6.286,
            ic_march: 5.383,
            ic_april: 4.692,
            ic_may: 3.902,
            ic_june: 3.722,
            ic_july: 3.789,
            ic_august: 4.439,
            ic_september: 4.948,
            ic_october: 5.222,
            ic_november: 5.053,
            ic_december: 5.905,
            ic_yearly: 4.96,
          },
          {
            ic_uuid: "c395aac6-994c-4a6a-bb0a-fef721c88506",
            ic_lat: -269686,
            ic_lon: -536422,
            ic_city: "Tunápolis",
            ic_states: "SANTA CATARINA",
            ic_january: 6.324,
            ic_february: 5.802,
            ic_march: 5.099,
            ic_april: 4.063,
            ic_may: 3.119,
            ic_june: 2.618,
            ic_july: 2.905,
            ic_august: 3.765,
            ic_september: 4.016,
            ic_october: 5.132,
            ic_november: 6.09,
            ic_december: 6.435,
            ic_yearly: 4.614,
          },
          {
            ic_uuid: "ece5e10c-bd70-4373-bf2d-de14329f3c11",
            ic_lat: -291044,
            ic_lon: -529543,
            ic_city: "Tunas",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.351,
            ic_february: 5.921,
            ic_march: 5.073,
            ic_april: 3.912,
            ic_may: 2.954,
            ic_june: 2.429,
            ic_july: 2.694,
            ic_august: 3.491,
            ic_september: 3.788,
            ic_october: 4.984,
            ic_november: 6.291,
            ic_december: 6.703,
            ic_yearly: 4.549,
          },
          {
            ic_uuid: "4be3726d-82ea-4ba0-9da2-7fe210f59d9d",
            ic_lat: -249736,
            ic_lon: -490883,
            ic_city: "Tunas do Paraná",
            ic_states: "PARANÁ",
            ic_january: 5.396,
            ic_february: 5.268,
            ic_march: 4.575,
            ic_april: 3.823,
            ic_may: 3.111,
            ic_june: 2.788,
            ic_july: 2.957,
            ic_august: 3.903,
            ic_september: 3.896,
            ic_october: 4.407,
            ic_november: 5.235,
            ic_december: 5.615,
            ic_yearly: 4.248,
          },
          {
            ic_uuid: "db9809e5-41b1-4b01-bb66-0b7c797d387d",
            ic_lat: -238653,
            ic_lon: -528773,
            ic_city: "Tuneiras do Oeste",
            ic_states: "PARANÁ",
            ic_january: 6.104,
            ic_february: 5.785,
            ic_march: 5.364,
            ic_april: 4.521,
            ic_may: 3.56,
            ic_june: 3.173,
            ic_july: 3.385,
            ic_august: 4.343,
            ic_september: 4.567,
            ic_october: 5.363,
            ic_november: 6.137,
            ic_december: 6.456,
            ic_yearly: 4.897,
          },
          {
            ic_uuid: "c5441cf6-0a5f-4d16-8f67-39a7ba3bebe7",
            ic_lat: -52552,
            ic_lon: -446447,
            ic_city: "Tuntum",
            ic_states: "MARANHÃO",
            ic_january: 4.78,
            ic_february: 4.928,
            ic_march: 4.926,
            ic_april: 4.908,
            ic_may: 4.877,
            ic_june: 5.166,
            ic_july: 5.381,
            ic_august: 6.007,
            ic_september: 6.202,
            ic_october: 5.654,
            ic_november: 5.399,
            ic_december: 5.06,
            ic_yearly: 5.274,
          },
          {
            ic_uuid: "15862726-1180-4d06-95c0-2da3e1f7160d",
            ic_lat: -21934,
            ic_lon: -505195,
            ic_city: "Tupã",
            ic_states: "SÃO PAULO",
            ic_january: 5.875,
            ic_february: 5.915,
            ic_march: 5.331,
            ic_april: 4.748,
            ic_may: 3.946,
            ic_june: 3.645,
            ic_july: 3.865,
            ic_august: 4.847,
            ic_september: 4.824,
            ic_october: 5.55,
            ic_november: 6.022,
            ic_december: 6.388,
            ic_yearly: 5.08,
          },
          {
            ic_uuid: "f2461daa-3a73-4286-ad22-de9b88453820",
            ic_lat: -18593,
            ic_lon: -48705,
            ic_city: "Tupaciguara",
            ic_states: "MINAS GERAIS",
            ic_january: 5.65,
            ic_february: 5.904,
            ic_march: 5.161,
            ic_april: 5.039,
            ic_may: 4.572,
            ic_june: 4.339,
            ic_july: 4.54,
            ic_august: 5.448,
            ic_september: 5.358,
            ic_october: 5.653,
            ic_november: 5.678,
            ic_december: 5.882,
            ic_yearly: 5.269,
          },
          {
            ic_uuid: "2225ffe8-4203-46ba-924f-42f777a90fe5",
            ic_lat: -87536,
            ic_lon: -373432,
            ic_city: "Tupanatinga",
            ic_states: "PERNAMBUCO",
            ic_january: 6.343,
            ic_february: 6.147,
            ic_march: 6.199,
            ic_april: 5.55,
            ic_may: 4.515,
            ic_june: 4.015,
            ic_july: 4.18,
            ic_august: 4.956,
            ic_september: 5.986,
            ic_october: 6.361,
            ic_november: 6.779,
            ic_december: 6.579,
            ic_yearly: 5.634,
          },
          {
            ic_uuid: "1bd84ed7-3eef-497e-8f91-de5441c92688",
            ic_lat: -279246,
            ic_lon: -515387,
            ic_city: "Tupanci do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.272,
            ic_february: 5.759,
            ic_march: 4.964,
            ic_april: 4.029,
            ic_may: 3.022,
            ic_june: 2.594,
            ic_july: 2.899,
            ic_august: 3.693,
            ic_september: 3.912,
            ic_october: 4.981,
            ic_november: 6.2,
            ic_december: 6.48,
            ic_yearly: 4.567,
          },
          {
            ic_uuid: "cee6bfdd-d31f-4646-994a-e03399abdf40",
            ic_lat: -29081,
            ic_lon: -538359,
            ic_city: "Tupanciretã",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.456,
            ic_february: 5.99,
            ic_march: 5.102,
            ic_april: 3.982,
            ic_may: 2.986,
            ic_june: 2.466,
            ic_july: 2.755,
            ic_august: 3.521,
            ic_september: 3.94,
            ic_october: 5.031,
            ic_november: 6.327,
            ic_december: 6.749,
            ic_yearly: 4.608,
          },
          {
            ic_uuid: "346d8aa4-8510-4634-afcb-a734557c2ad4",
            ic_lat: -294777,
            ic_lon: -514178,
            ic_city: "Tupandi",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.061,
            ic_february: 5.617,
            ic_march: 4.726,
            ic_april: 3.756,
            ic_may: 2.793,
            ic_june: 2.341,
            ic_july: 2.551,
            ic_august: 3.237,
            ic_september: 3.519,
            ic_october: 4.51,
            ic_november: 6.009,
            ic_december: 6.372,
            ic_yearly: 4.291,
          },
          {
            ic_uuid: "9c865657-f9fe-43a4-b09a-5bee571f2eca",
            ic_lat: -277602,
            ic_lon: -544819,
            ic_city: "Tuparendi",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.497,
            ic_february: 6.026,
            ic_march: 5.161,
            ic_april: 4.089,
            ic_may: 3.099,
            ic_june: 2.554,
            ic_july: 2.843,
            ic_august: 3.661,
            ic_september: 4.039,
            ic_october: 5.186,
            ic_november: 6.276,
            ic_december: 6.704,
            ic_yearly: 4.678,
          },
          {
            ic_uuid: "7be6d68a-05c9-40b6-bf4d-032a64bf57e9",
            ic_lat: -76015,
            ic_lon: -3731,
            ic_city: "Tuparetama",
            ic_states: "PERNAMBUCO",
            ic_january: 6.239,
            ic_february: 6.06,
            ic_march: 6.079,
            ic_april: 5.668,
            ic_may: 4.981,
            ic_june: 4.504,
            ic_july: 4.694,
            ic_august: 5.51,
            ic_september: 6.343,
            ic_october: 6.525,
            ic_november: 6.713,
            ic_december: 6.341,
            ic_yearly: 5.805,
          },
          {
            ic_uuid: "4225dc0b-fef1-4ddd-b476-6a774bfa61f5",
            ic_lat: -245883,
            ic_lon: -53511,
            ic_city: "Tupãssi",
            ic_states: "PARANÁ",
            ic_january: 6.382,
            ic_february: 5.578,
            ic_march: 5.257,
            ic_april: 4.385,
            ic_may: 3.446,
            ic_june: 3.046,
            ic_july: 3.238,
            ic_august: 4.187,
            ic_september: 4.503,
            ic_october: 5.263,
            ic_november: 6.11,
            ic_december: 6.5,
            ic_yearly: 4.824,
          },
          {
            ic_uuid: "f3086b6c-3e0b-4e59-a4bc-1e3b71791b03",
            ic_lat: -213865,
            ic_lon: -515746,
            ic_city: "Tupi Paulista",
            ic_states: "SÃO PAULO",
            ic_january: 5.909,
            ic_february: 5.973,
            ic_march: 5.441,
            ic_april: 4.868,
            ic_may: 4.048,
            ic_june: 3.724,
            ic_july: 3.896,
            ic_august: 4.79,
            ic_september: 4.927,
            ic_october: 5.575,
            ic_november: 6.059,
            ic_december: 6.352,
            ic_yearly: 5.13,
          },
          {
            ic_uuid: "c6bcccef-4277-4b2e-bdaf-8dd69b46c022",
            ic_lat: -89759,
            ic_lon: -481903,
            ic_city: "Tupirama",
            ic_states: "TOCANTINS",
            ic_january: 5.088,
            ic_february: 5.15,
            ic_march: 4.954,
            ic_april: 4.887,
            ic_may: 5.1,
            ic_june: 5.151,
            ic_july: 5.269,
            ic_august: 5.978,
            ic_september: 5.8,
            ic_october: 5.316,
            ic_november: 5.099,
            ic_december: 5.028,
            ic_yearly: 5.235,
          },
          {
            ic_uuid: "bfb3478a-e1d1-4660-9888-392084f5d3ce",
            ic_lat: -84015,
            ic_lon: -481289,
            ic_city: "Tupiratins",
            ic_states: "TOCANTINS",
            ic_january: 4.966,
            ic_february: 5.044,
            ic_march: 4.915,
            ic_april: 4.923,
            ic_may: 5.11,
            ic_june: 5.186,
            ic_july: 5.18,
            ic_august: 6.009,
            ic_september: 5.828,
            ic_october: 5.316,
            ic_november: 4.903,
            ic_december: 4.909,
            ic_yearly: 5.191,
          },
          {
            ic_uuid: "737013a9-6b51-4f50-98b1-97e796b45d97",
            ic_lat: -16656,
            ic_lon: -453704,
            ic_city: "Turiaçu",
            ic_states: "MARANHÃO",
            ic_january: 4.616,
            ic_february: 4.458,
            ic_march: 4.209,
            ic_april: 4.175,
            ic_may: 4.375,
            ic_june: 4.696,
            ic_july: 4.871,
            ic_august: 5.329,
            ic_september: 5.63,
            ic_october: 5.404,
            ic_november: 5.228,
            ic_december: 4.942,
            ic_yearly: 4.828,
          },
          {
            ic_uuid: "1ff6327d-3fc8-425b-9e1d-e23bbbf6fd27",
            ic_lat: -22204,
            ic_lon: -453067,
            ic_city: "Turilândia",
            ic_states: "MARANHÃO",
            ic_january: 4.599,
            ic_february: 4.486,
            ic_march: 4.373,
            ic_april: 4.372,
            ic_may: 4.569,
            ic_june: 4.872,
            ic_july: 4.938,
            ic_august: 5.5,
            ic_september: 5.746,
            ic_october: 5.469,
            ic_november: 5.217,
            ic_december: 4.823,
            ic_yearly: 4.913,
          },
          {
            ic_uuid: "6aaab88e-d903-4098-a5f3-0c27cccfac42",
            ic_lat: -209498,
            ic_lon: -501075,
            ic_city: "Turiúba",
            ic_states: "SÃO PAULO",
            ic_january: 5.823,
            ic_february: 5.898,
            ic_march: 5.31,
            ic_april: 4.957,
            ic_may: 4.098,
            ic_june: 3.829,
            ic_july: 4.045,
            ic_august: 4.969,
            ic_september: 4.978,
            ic_october: 5.661,
            ic_november: 6.029,
            ic_december: 6.243,
            ic_yearly: 5.153,
          },
          {
            ic_uuid: "ebbc83df-cb55-409a-aced-5d14e26e53c4",
            ic_lat: -172833,
            ic_lon: -427288,
            ic_city: "Turmalina",
            ic_states: "MINAS GERAIS",
            ic_january: 6.08,
            ic_february: 6.343,
            ic_march: 5.334,
            ic_april: 4.672,
            ic_may: 4.07,
            ic_june: 3.94,
            ic_july: 4.126,
            ic_august: 4.914,
            ic_september: 5.415,
            ic_october: 5.582,
            ic_november: 5.174,
            ic_december: 5.847,
            ic_yearly: 5.125,
          },
          {
            ic_uuid: "a9a1301d-d88d-404c-9dc5-0075ce4740f5",
            ic_lat: -200516,
            ic_lon: -504813,
            ic_city: "Turmalina",
            ic_states: "SÃO PAULO",
            ic_january: 5.795,
            ic_february: 5.886,
            ic_march: 5.316,
            ic_april: 4.905,
            ic_may: 4.237,
            ic_june: 3.966,
            ic_july: 4.171,
            ic_august: 5.107,
            ic_september: 5.111,
            ic_october: 5.646,
            ic_november: 5.867,
            ic_december: 6.21,
            ic_yearly: 5.185,
          },
          {
            ic_uuid: "48f3b7cf-01be-4e0e-992a-aeb2923bcec3",
            ic_lat: -31422,
            ic_lon: -521779,
            ic_city: "Turuçu",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.218,
            ic_february: 5.577,
            ic_march: 4.714,
            ic_april: 3.65,
            ic_may: 2.684,
            ic_june: 2.265,
            ic_july: 2.442,
            ic_august: 3.056,
            ic_september: 3.526,
            ic_october: 4.715,
            ic_november: 6.069,
            ic_december: 6.576,
            ic_yearly: 4.291,
          },
          {
            ic_uuid: "eb94fa13-52f8-40c1-be54-2e4fa3adf483",
            ic_lat: -35845,
            ic_lon: -3943,
            ic_city: "Tururu",
            ic_states: "CEARÁ",
            ic_january: 4.836,
            ic_february: 5.056,
            ic_march: 4.977,
            ic_april: 4.613,
            ic_may: 4.951,
            ic_june: 4.972,
            ic_july: 5.255,
            ic_august: 5.987,
            ic_september: 6.283,
            ic_october: 6.038,
            ic_november: 5.848,
            ic_december: 5.131,
            ic_yearly: 5.329,
          },
          {
            ic_uuid: "dc59ff91-305d-4684-89e5-a5f467705f5d",
            ic_lat: -166095,
            ic_lon: -501364,
            ic_city: "Turvânia",
            ic_states: "GOIÁS",
            ic_january: 5.557,
            ic_february: 5.655,
            ic_march: 5.276,
            ic_april: 5.169,
            ic_may: 4.793,
            ic_june: 4.546,
            ic_july: 4.783,
            ic_august: 5.65,
            ic_september: 5.46,
            ic_october: 5.579,
            ic_november: 5.485,
            ic_december: 5.577,
            ic_yearly: 5.294,
          },
          {
            ic_uuid: "c63742f3-4592-4572-861d-c30fdff13126",
            ic_lat: -178507,
            ic_lon: -503028,
            ic_city: "Turvelândia",
            ic_states: "GOIÁS",
            ic_january: 5.743,
            ic_february: 5.795,
            ic_march: 5.28,
            ic_april: 5.152,
            ic_may: 4.712,
            ic_june: 4.4,
            ic_july: 4.635,
            ic_august: 5.553,
            ic_september: 5.349,
            ic_october: 5.552,
            ic_november: 5.601,
            ic_december: 5.791,
            ic_yearly: 5.297,
          },
          {
            ic_uuid: "bf987df7-45ab-4f3f-bcdd-446e2b8e9282",
            ic_lat: -250441,
            ic_lon: -515287,
            ic_city: "Turvo",
            ic_states: "PARANÁ",
            ic_january: 5.671,
            ic_february: 5.474,
            ic_march: 4.914,
            ic_april: 4.244,
            ic_may: 3.322,
            ic_june: 2.943,
            ic_july: 3.235,
            ic_august: 4.225,
            ic_september: 4.377,
            ic_october: 5.077,
            ic_november: 5.826,
            ic_december: 6.045,
            ic_yearly: 4.613,
          },
          {
            ic_uuid: "da63c2b5-561e-44b3-b317-797f167586f8",
            ic_lat: -289277,
            ic_lon: -496835,
            ic_city: "Turvo",
            ic_states: "SANTA CATARINA",
            ic_january: 5.58,
            ic_february: 5.24,
            ic_march: 4.48,
            ic_april: 3.825,
            ic_may: 2.96,
            ic_june: 2.448,
            ic_july: 2.694,
            ic_august: 3.396,
            ic_september: 3.495,
            ic_october: 4.231,
            ic_november: 5.574,
            ic_december: 5.849,
            ic_yearly: 4.148,
          },
          {
            ic_uuid: "3951db1f-ea6c-4a27-91da-884cc8f4a55e",
            ic_lat: -218738,
            ic_lon: -457863,
            ic_city: "Turvolândia",
            ic_states: "MINAS GERAIS",
            ic_january: 5.407,
            ic_february: 5.699,
            ic_march: 4.879,
            ic_april: 4.552,
            ic_may: 3.922,
            ic_june: 3.703,
            ic_july: 3.91,
            ic_august: 4.802,
            ic_september: 4.979,
            ic_october: 5.384,
            ic_november: 5.245,
            ic_december: 5.626,
            ic_yearly: 4.842,
          },
          {
            ic_uuid: "d1f37c2e-383e-4d41-a407-cc962d31d4f6",
            ic_lat: -27618,
            ic_lon: -422759,
            ic_city: "Tutóia",
            ic_states: "MARANHÃO",
            ic_january: 5.479,
            ic_february: 5.317,
            ic_march: 5.179,
            ic_april: 4.97,
            ic_may: 5.041,
            ic_june: 5.184,
            ic_july: 5.397,
            ic_august: 5.921,
            ic_september: 6.043,
            ic_october: 5.887,
            ic_november: 6.21,
            ic_december: 5.957,
            ic_yearly: 5.549,
          },
          {
            ic_uuid: "8e7854dc-00df-44fe-aa8b-d16e98397305",
            ic_lat: -29965,
            ic_lon: -651138,
            ic_city: "Uarini",
            ic_states: "AMAZONAS",
            ic_january: 4.428,
            ic_february: 4.7,
            ic_march: 4.589,
            ic_april: 4.322,
            ic_may: 3.958,
            ic_june: 4.281,
            ic_july: 4.316,
            ic_august: 4.936,
            ic_september: 5.0,
            ic_october: 5.037,
            ic_november: 4.733,
            ic_december: 4.566,
            ic_yearly: 4.572,
          },
          {
            ic_uuid: "1ad9d2a3-9e58-4535-a690-81988ec4c79b",
            ic_lat: -98421,
            ic_lon: -394795,
            ic_city: "Uauá",
            ic_states: "BAHIA",
            ic_january: 6.247,
            ic_february: 6.069,
            ic_march: 5.897,
            ic_april: 5.142,
            ic_may: 4.493,
            ic_june: 4.148,
            ic_july: 4.323,
            ic_august: 4.957,
            ic_september: 5.946,
            ic_october: 6.041,
            ic_november: 6.291,
            ic_december: 6.287,
            ic_yearly: 5.487,
          },
          {
            ic_uuid: "0a343475-9808-4470-993a-313643e6d8ea",
            ic_lat: -211209,
            ic_lon: -429363,
            ic_city: "Ubá",
            ic_states: "MINAS GERAIS",
            ic_january: 5.632,
            ic_february: 5.992,
            ic_march: 4.931,
            ic_april: 4.326,
            ic_may: 3.698,
            ic_june: 3.536,
            ic_july: 3.693,
            ic_august: 4.513,
            ic_september: 4.81,
            ic_october: 5.069,
            ic_november: 4.818,
            ic_december: 5.573,
            ic_yearly: 4.716,
          },
          {
            ic_uuid: "2e52f422-b1b6-463c-b417-7b3b430a0459",
            ic_lat: -16289,
            ic_lon: -447786,
            ic_city: "Ubaí",
            ic_states: "MINAS GERAIS",
            ic_january: 6.256,
            ic_february: 6.476,
            ic_march: 5.605,
            ic_april: 5.503,
            ic_may: 4.943,
            ic_june: 4.667,
            ic_july: 5.003,
            ic_august: 5.85,
            ic_september: 5.976,
            ic_october: 6.027,
            ic_november: 5.492,
            ic_december: 6.126,
            ic_yearly: 5.661,
          },
          {
            ic_uuid: "9dedd431-f6aa-4e97-9bd1-6b0842ac2653",
            ic_lat: -132685,
            ic_lon: -396627,
            ic_city: "Ubaíra",
            ic_states: "BAHIA",
            ic_january: 5.808,
            ic_february: 5.846,
            ic_march: 5.552,
            ic_april: 4.463,
            ic_may: 3.843,
            ic_june: 3.451,
            ic_july: 3.648,
            ic_august: 4.169,
            ic_september: 4.967,
            ic_october: 5.089,
            ic_november: 5.306,
            ic_december: 5.888,
            ic_yearly: 4.836,
          },
          {
            ic_uuid: "979a83e7-8140-4db5-baba-f467c674111e",
            ic_lat: -142998,
            ic_lon: -393227,
            ic_city: "Ubaitaba",
            ic_states: "BAHIA",
            ic_january: 5.887,
            ic_february: 5.83,
            ic_march: 5.503,
            ic_april: 4.516,
            ic_may: 3.84,
            ic_june: 3.508,
            ic_july: 3.743,
            ic_august: 4.246,
            ic_september: 4.918,
            ic_october: 5.143,
            ic_november: 5.262,
            ic_december: 6.02,
            ic_yearly: 4.868,
          },
          {
            ic_uuid: "0dd51d07-ea34-403a-bd9d-a22930e56efc",
            ic_lat: -38549,
            ic_lon: -409208,
            ic_city: "Ubajara",
            ic_states: "CEARÁ",
            ic_january: 5.21,
            ic_february: 5.29,
            ic_march: 5.295,
            ic_april: 4.851,
            ic_may: 5.032,
            ic_june: 5.138,
            ic_july: 5.459,
            ic_august: 6.233,
            ic_september: 6.662,
            ic_october: 6.555,
            ic_november: 6.47,
            ic_december: 5.741,
            ic_yearly: 5.661,
          },
          {
            ic_uuid: "a552189f-ad8a-41e9-88cb-eb87ffd78606",
            ic_lat: -196355,
            ic_lon: -421063,
            ic_city: "Ubaporanga",
            ic_states: "MINAS GERAIS",
            ic_january: 5.787,
            ic_february: 5.925,
            ic_march: 5.02,
            ic_april: 4.482,
            ic_may: 3.77,
            ic_june: 3.581,
            ic_july: 3.754,
            ic_august: 4.429,
            ic_september: 4.814,
            ic_october: 4.957,
            ic_november: 4.781,
            ic_december: 5.559,
            ic_yearly: 4.738,
          },
          {
            ic_uuid: "c2341d0d-7f9b-4062-8b0c-d158c461c827",
            ic_lat: -211654,
            ic_lon: -497202,
            ic_city: "Ubarana",
            ic_states: "SÃO PAULO",
            ic_january: 5.88,
            ic_february: 5.926,
            ic_march: 5.28,
            ic_april: 4.847,
            ic_may: 4.007,
            ic_june: 3.771,
            ic_july: 3.997,
            ic_august: 4.859,
            ic_september: 4.981,
            ic_october: 5.632,
            ic_november: 6.0,
            ic_december: 6.251,
            ic_yearly: 5.119,
          },
          {
            ic_uuid: "f49ff74b-f2b6-4b3a-b43d-ed2df0e0f12b",
            ic_lat: -142157,
            ic_lon: -395181,
            ic_city: "Ubatã",
            ic_states: "BAHIA",
            ic_january: 5.832,
            ic_february: 5.835,
            ic_march: 5.522,
            ic_april: 4.522,
            ic_may: 3.849,
            ic_june: 3.531,
            ic_july: 3.761,
            ic_august: 4.244,
            ic_september: 4.952,
            ic_october: 5.179,
            ic_november: 5.282,
            ic_december: 5.926,
            ic_yearly: 4.87,
          },
          {
            ic_uuid: "18f0abb0-54d3-4ff3-8f39-9407318c908d",
            ic_lat: -234336,
            ic_lon: -450838,
            ic_city: "Ubatuba",
            ic_states: "SÃO PAULO",
            ic_january: 4.904,
            ic_february: 5.256,
            ic_march: 4.275,
            ic_april: 3.938,
            ic_may: 3.269,
            ic_june: 3.035,
            ic_july: 3.076,
            ic_august: 3.791,
            ic_september: 3.676,
            ic_october: 4.0,
            ic_november: 4.203,
            ic_december: 4.914,
            ic_yearly: 4.028,
          },
          {
            ic_uuid: "5d4813dc-46c8-4c88-9f7d-06ce6abe2dc8",
            ic_lat: -197477,
            ic_lon: -479385,
            ic_city: "Uberaba",
            ic_states: "MINAS GERAIS",
            ic_january: 5.559,
            ic_february: 5.807,
            ic_march: 5.029,
            ic_april: 4.941,
            ic_may: 4.402,
            ic_june: 4.153,
            ic_july: 4.446,
            ic_august: 5.378,
            ic_september: 5.212,
            ic_october: 5.545,
            ic_november: 5.513,
            ic_december: 5.786,
            ic_yearly: 5.148,
          },
          {
            ic_uuid: "3c23987c-b16a-424e-872c-933f5f411333",
            ic_lat: -189146,
            ic_lon: -482753,
            ic_city: "Uberlândia",
            ic_states: "MINAS GERAIS",
            ic_january: 5.549,
            ic_february: 5.854,
            ic_march: 5.112,
            ic_april: 5.024,
            ic_may: 4.514,
            ic_june: 4.32,
            ic_july: 4.547,
            ic_august: 5.472,
            ic_september: 5.444,
            ic_october: 5.665,
            ic_november: 5.591,
            ic_december: 5.768,
            ic_yearly: 5.238,
          },
          {
            ic_uuid: "b4d7555e-90ef-4169-9ae3-52beadbd9e36",
            ic_lat: -225277,
            ic_lon: -496617,
            ic_city: "Ubirajara",
            ic_states: "SÃO PAULO",
            ic_january: 5.656,
            ic_february: 5.773,
            ic_march: 5.178,
            ic_april: 4.716,
            ic_may: 3.795,
            ic_june: 3.51,
            ic_july: 3.744,
            ic_august: 4.728,
            ic_september: 4.81,
            ic_october: 5.466,
            ic_november: 5.863,
            ic_december: 6.191,
            ic_yearly: 4.952,
          },
          {
            ic_uuid: "edd10a84-db8b-45b3-9c3b-457de1eb8762",
            ic_lat: -245398,
            ic_lon: -529869,
            ic_city: "Ubiratã",
            ic_states: "PARANÁ",
            ic_january: 6.271,
            ic_february: 5.557,
            ic_march: 5.21,
            ic_april: 4.43,
            ic_may: 3.475,
            ic_june: 3.061,
            ic_july: 3.266,
            ic_august: 4.213,
            ic_september: 4.47,
            ic_october: 5.23,
            ic_november: 6.053,
            ic_december: 6.476,
            ic_yearly: 4.809,
          },
          {
            ic_uuid: "c4510a0e-d647-45cf-a87a-c0c35b1e298d",
            ic_lat: -28046,
            ic_lon: -546809,
            ic_city: "Ubiretama",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.502,
            ic_february: 6.045,
            ic_march: 5.167,
            ic_april: 4.085,
            ic_may: 3.108,
            ic_june: 2.538,
            ic_july: 2.853,
            ic_august: 3.631,
            ic_september: 4.032,
            ic_october: 5.145,
            ic_november: 6.302,
            ic_december: 6.715,
            ic_yearly: 4.677,
          },
          {
            ic_uuid: "aba7f440-1897-46a0-9534-ce3f906e4c5e",
            ic_lat: -20953,
            ic_lon: -491749,
            ic_city: "Uchoa",
            ic_states: "SÃO PAULO",
            ic_january: 5.787,
            ic_february: 5.887,
            ic_march: 5.207,
            ic_april: 4.831,
            ic_may: 4.104,
            ic_june: 3.88,
            ic_july: 4.098,
            ic_august: 4.986,
            ic_september: 5.086,
            ic_october: 5.59,
            ic_november: 5.883,
            ic_december: 6.134,
            ic_yearly: 5.123,
          },
          {
            ic_uuid: "d8eed139-6310-4766-b834-570f400b0c3c",
            ic_lat: -113399,
            ic_lon: -421357,
            ic_city: "Uibaí",
            ic_states: "BAHIA",
            ic_january: 6.434,
            ic_february: 6.218,
            ic_march: 6.012,
            ic_april: 5.372,
            ic_may: 4.92,
            ic_june: 4.773,
            ic_july: 5.024,
            ic_august: 5.763,
            ic_september: 6.365,
            ic_october: 6.456,
            ic_november: 6.199,
            ic_december: 6.411,
            ic_yearly: 5.829,
          },
          {
            ic_uuid: "ff0a221c-7258-4399-b761-041286d3e284",
            ic_lat: 45949,
            ic_lon: -601634,
            ic_city: "Uiramutã",
            ic_states: "RORAIMA",
            ic_january: 4.833,
            ic_february: 5.071,
            ic_march: 5.309,
            ic_april: 5.245,
            ic_may: 4.867,
            ic_june: 4.855,
            ic_july: 5.082,
            ic_august: 5.407,
            ic_september: 5.709,
            ic_october: 5.52,
            ic_november: 5.162,
            ic_december: 4.65,
            ic_yearly: 5.142,
          },
          {
            ic_uuid: "33bf062b-9a5d-4c3b-8da6-7e9366c26261",
            ic_lat: -14284,
            ic_lon: -499205,
            ic_city: "Uirapuru",
            ic_states: "GOIÁS",
            ic_january: 5.4,
            ic_february: 5.573,
            ic_march: 5.225,
            ic_april: 5.26,
            ic_may: 4.911,
            ic_june: 4.718,
            ic_july: 5.004,
            ic_august: 5.749,
            ic_september: 5.602,
            ic_october: 5.596,
            ic_november: 5.284,
            ic_december: 5.422,
            ic_yearly: 5.312,
          },
          {
            ic_uuid: "fec9b62f-6ada-4049-9647-5cfb5fe9de20",
            ic_lat: -65207,
            ic_lon: -384116,
            ic_city: "Uiraúna",
            ic_states: "PARAÍBA",
            ic_january: 5.992,
            ic_february: 5.939,
            ic_march: 5.926,
            ic_april: 5.566,
            ic_may: 5.131,
            ic_june: 4.932,
            ic_july: 5.258,
            ic_august: 6.02,
            ic_september: 6.464,
            ic_october: 6.561,
            ic_november: 6.674,
            ic_december: 6.16,
            ic_yearly: 5.885,
          },
          {
            ic_uuid: "03c618db-83b4-4643-abe1-deb8165a8775",
            ic_lat: -37395,
            ic_lon: -474985,
            ic_city: "Ulianópolis",
            ic_states: "PARÁ",
            ic_january: 4.447,
            ic_february: 4.623,
            ic_march: 4.742,
            ic_april: 4.749,
            ic_may: 4.694,
            ic_june: 4.836,
            ic_july: 4.879,
            ic_august: 5.109,
            ic_september: 5.152,
            ic_october: 4.887,
            ic_november: 4.683,
            ic_december: 4.503,
            ic_yearly: 4.775,
          },
          {
            ic_uuid: "98415def-d265-4239-aa74-0a2aa5621981",
            ic_lat: -66393,
            ic_lon: -387011,
            ic_city: "Umari",
            ic_states: "CEARÁ",
            ic_january: 5.919,
            ic_february: 5.92,
            ic_march: 5.88,
            ic_april: 5.568,
            ic_may: 5.215,
            ic_june: 5.08,
            ic_july: 5.372,
            ic_august: 6.147,
            ic_september: 6.52,
            ic_october: 6.672,
            ic_november: 6.732,
            ic_december: 6.18,
            ic_yearly: 5.934,
          },
          {
            ic_uuid: "44370768-defb-4584-8c8a-bf69d4b38fe3",
            ic_lat: -59828,
            ic_lon: -378183,
            ic_city: "Umarizal",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.094,
            ic_february: 6.127,
            ic_march: 6.153,
            ic_april: 5.875,
            ic_may: 5.451,
            ic_june: 5.098,
            ic_july: 5.436,
            ic_august: 6.106,
            ic_september: 6.481,
            ic_october: 6.664,
            ic_november: 6.675,
            ic_december: 6.142,
            ic_yearly: 6.025,
          },
          {
            ic_uuid: "932203fb-57e3-44b4-8b37-1f977d9502e7",
            ic_lat: -113834,
            ic_lon: -376615,
            ic_city: "Umbaúba",
            ic_states: "SERGIPE",
            ic_january: 6.253,
            ic_february: 6.068,
            ic_march: 5.954,
            ic_april: 4.93,
            ic_may: 4.196,
            ic_june: 3.927,
            ic_july: 4.095,
            ic_august: 4.528,
            ic_september: 5.256,
            ic_october: 5.665,
            ic_november: 6.099,
            ic_december: 6.298,
            ic_yearly: 5.272,
          },
          {
            ic_uuid: "63f13a84-2928-4084-842d-96ef82668cc2",
            ic_lat: -107309,
            ic_lon: -413263,
            ic_city: "Umburanas",
            ic_states: "BAHIA",
            ic_january: 6.357,
            ic_february: 5.952,
            ic_march: 5.914,
            ic_april: 5.002,
            ic_may: 4.561,
            ic_june: 4.328,
            ic_july: 4.606,
            ic_august: 5.385,
            ic_september: 6.188,
            ic_october: 6.256,
            ic_november: 6.306,
            ic_december: 6.249,
            ic_yearly: 5.592,
          },
          {
            ic_uuid: "06a2c2b2-ded6-4602-9ae2-39a7ffcb91c8",
            ic_lat: -172553,
            ic_lon: -405783,
            ic_city: "Umburatiba",
            ic_states: "MINAS GERAIS",
            ic_january: 6.121,
            ic_february: 6.29,
            ic_march: 5.439,
            ic_april: 4.663,
            ic_may: 3.832,
            ic_june: 3.465,
            ic_july: 3.652,
            ic_august: 4.331,
            ic_september: 5.009,
            ic_october: 5.316,
            ic_november: 5.26,
            ic_december: 6.212,
            ic_yearly: 4.966,
          },
          {
            ic_uuid: "8620175e-838d-4b29-bb7c-38219dcf2c88",
            ic_lat: -76955,
            ic_lon: -356639,
            ic_city: "Umbuzeiro",
            ic_states: "PARAÍBA",
            ic_january: 5.664,
            ic_february: 5.769,
            ic_march: 5.832,
            ic_april: 5.203,
            ic_may: 4.51,
            ic_june: 4.037,
            ic_july: 4.246,
            ic_august: 4.96,
            ic_september: 5.571,
            ic_october: 5.822,
            ic_november: 6.131,
            ic_december: 5.979,
            ic_yearly: 5.31,
          },
          {
            ic_uuid: "69e4e27f-741e-46ea-9600-d2a196cfdb65",
            ic_lat: -36769,
            ic_lon: -393468,
            ic_city: "Umirim",
            ic_states: "CEARÁ",
            ic_january: 5.168,
            ic_february: 5.302,
            ic_march: 5.175,
            ic_april: 4.822,
            ic_may: 5.15,
            ic_june: 5.006,
            ic_july: 5.309,
            ic_august: 6.061,
            ic_september: 6.385,
            ic_october: 6.255,
            ic_november: 6.058,
            ic_december: 5.461,
            ic_yearly: 5.513,
          },
          {
            ic_uuid: "1cfd9fd8-385b-4c5d-a99d-253a8c8814d8",
            ic_lat: -237661,
            ic_lon: -533206,
            ic_city: "Umuarama",
            ic_states: "PARANÁ",
            ic_january: 6.088,
            ic_february: 5.832,
            ic_march: 5.332,
            ic_april: 4.498,
            ic_may: 3.523,
            ic_june: 3.136,
            ic_july: 3.38,
            ic_august: 4.351,
            ic_september: 4.579,
            ic_october: 5.381,
            ic_november: 6.142,
            ic_december: 6.512,
            ic_yearly: 4.896,
          },
          {
            ic_uuid: "d766ce5f-4b83-4165-b7d6-b1bee006d23a",
            ic_lat: -152937,
            ic_lon: -39075,
            ic_city: "Una",
            ic_states: "BAHIA",
            ic_january: 6.118,
            ic_february: 6.208,
            ic_march: 5.58,
            ic_april: 4.638,
            ic_may: 3.915,
            ic_june: 3.624,
            ic_july: 3.849,
            ic_august: 4.484,
            ic_september: 5.045,
            ic_october: 5.315,
            ic_november: 5.475,
            ic_december: 6.276,
            ic_yearly: 5.044,
          },
          {
            ic_uuid: "937fc354-d13f-4000-9ed7-77f95d1e626f",
            ic_lat: -163597,
            ic_lon: -469025,
            ic_city: "Unaí",
            ic_states: "MINAS GERAIS",
            ic_january: 5.917,
            ic_february: 6.169,
            ic_march: 5.452,
            ic_april: 5.296,
            ic_may: 4.876,
            ic_june: 4.689,
            ic_july: 5.002,
            ic_august: 5.868,
            ic_september: 5.819,
            ic_october: 5.856,
            ic_november: 5.392,
            ic_december: 5.809,
            ic_yearly: 5.512,
          },
          {
            ic_uuid: "8f90084d-58c8-49eb-8275-8720fe156228",
            ic_lat: -45861,
            ic_lon: -428586,
            ic_city: "União",
            ic_states: "PIAUÍ",
            ic_january: 5.185,
            ic_february: 5.24,
            ic_march: 5.246,
            ic_april: 5.133,
            ic_may: 5.049,
            ic_june: 5.184,
            ic_july: 5.462,
            ic_august: 6.1,
            ic_september: 6.434,
            ic_october: 6.332,
            ic_november: 6.088,
            ic_december: 5.665,
            ic_yearly: 5.593,
          },
          {
            ic_uuid: "14f35d36-d0f0-4fb5-8912-0b87e71638c7",
            ic_lat: -287838,
            ic_lon: -520242,
            ic_city: "União da Serra",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.1,
            ic_february: 5.802,
            ic_march: 4.867,
            ic_april: 3.876,
            ic_may: 2.892,
            ic_june: 2.416,
            ic_july: 2.72,
            ic_august: 3.472,
            ic_september: 3.744,
            ic_october: 4.828,
            ic_november: 6.142,
            ic_december: 6.551,
            ic_yearly: 4.451,
          },
          {
            ic_uuid: "e1107f17-722f-410f-9327-4c946bf8b551",
            ic_lat: -262278,
            ic_lon: -510878,
            ic_city: "União da Vitória",
            ic_states: "PARANÁ",
            ic_january: 5.465,
            ic_february: 5.087,
            ic_march: 4.575,
            ic_april: 3.578,
            ic_may: 2.877,
            ic_june: 2.429,
            ic_july: 2.647,
            ic_august: 3.683,
            ic_september: 3.821,
            ic_october: 4.475,
            ic_november: 5.448,
            ic_december: 5.642,
            ic_yearly: 4.144,
          },
          {
            ic_uuid: "e07573f9-ebd6-4343-9ce6-c37db53fe0d3",
            ic_lat: -1953,
            ic_lon: -503359,
            ic_city: "União de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.814,
            ic_february: 5.899,
            ic_march: 5.319,
            ic_april: 4.978,
            ic_may: 4.374,
            ic_june: 4.095,
            ic_july: 4.347,
            ic_august: 5.204,
            ic_september: 5.175,
            ic_october: 5.65,
            ic_november: 5.869,
            ic_december: 6.111,
            ic_yearly: 5.236,
          },
          {
            ic_uuid: "79df8388-8c45-41a3-bd52-6d375a9f229c",
            ic_lat: -267625,
            ic_lon: -528546,
            ic_city: "União do Oeste",
            ic_states: "SANTA CATARINA",
            ic_january: 6.153,
            ic_february: 5.691,
            ic_march: 5.136,
            ic_april: 4.112,
            ic_may: 3.167,
            ic_june: 2.65,
            ic_july: 2.967,
            ic_august: 3.839,
            ic_september: 4.068,
            ic_october: 5.111,
            ic_november: 6.126,
            ic_december: 6.401,
            ic_yearly: 4.618,
          },
          {
            ic_uuid: "8e70e031-3008-4f05-9321-1539ec11d38a",
            ic_lat: -115302,
            ic_lon: -543686,
            ic_city: "União do Sul",
            ic_states: "MATO GROSSO",
            ic_january: 5.043,
            ic_february: 5.068,
            ic_march: 4.852,
            ic_april: 4.902,
            ic_may: 4.839,
            ic_june: 4.741,
            ic_july: 5.1,
            ic_august: 5.469,
            ic_september: 5.163,
            ic_october: 5.171,
            ic_november: 5.07,
            ic_december: 5.129,
            ic_yearly: 5.046,
          },
          {
            ic_uuid: "33f07a9f-eb68-40f3-9959-be6f62926872",
            ic_lat: -91596,
            ic_lon: -360226,
            ic_city: "União dos Palmares",
            ic_states: "ALAGOAS",
            ic_january: 5.781,
            ic_february: 5.755,
            ic_march: 5.725,
            ic_april: 5.079,
            ic_may: 4.217,
            ic_june: 3.814,
            ic_july: 3.893,
            ic_august: 4.505,
            ic_september: 5.392,
            ic_october: 5.643,
            ic_november: 6.139,
            ic_december: 6.108,
            ic_yearly: 5.171,
          },
          {
            ic_uuid: "421b438e-99af-4f86-8720-00f284706aac",
            ic_lat: -20887,
            ic_lon: -498969,
            ic_city: "União Paulista",
            ic_states: "SÃO PAULO",
            ic_january: 5.839,
            ic_february: 5.89,
            ic_march: 5.276,
            ic_april: 4.871,
            ic_may: 4.116,
            ic_june: 3.848,
            ic_july: 4.084,
            ic_august: 5.014,
            ic_september: 5.032,
            ic_october: 5.611,
            ic_november: 5.945,
            ic_december: 6.204,
            ic_yearly: 5.144,
          },
          {
            ic_uuid: "6ef106ff-421a-45b6-bac8-6946f8b7cc7f",
            ic_lat: -230873,
            ic_lon: -521577,
            ic_city: "Uniflor",
            ic_states: "PARANÁ",
            ic_january: 5.956,
            ic_february: 5.874,
            ic_march: 5.411,
            ic_april: 4.668,
            ic_may: 3.685,
            ic_june: 3.375,
            ic_july: 3.567,
            ic_august: 4.507,
            ic_september: 4.723,
            ic_october: 5.441,
            ic_november: 6.175,
            ic_december: 6.412,
            ic_yearly: 4.983,
          },
          {
            ic_uuid: "3f27d437-a968-48fa-a92d-4dec42b1c650",
            ic_lat: -290469,
            ic_lon: -551499,
            ic_city: "Unistalda",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.595,
            ic_february: 6.131,
            ic_march: 5.231,
            ic_april: 4.056,
            ic_may: 3.045,
            ic_june: 2.52,
            ic_july: 2.844,
            ic_august: 3.568,
            ic_september: 4.084,
            ic_october: 5.273,
            ic_november: 6.501,
            ic_december: 6.952,
            ic_yearly: 4.733,
          },
          {
            ic_uuid: "39b94daf-5032-410b-b786-b07306185f15",
            ic_lat: -56423,
            ic_lon: -372598,
            ic_city: "Upanema",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.977,
            ic_february: 5.987,
            ic_march: 6.031,
            ic_april: 5.783,
            ic_may: 5.376,
            ic_june: 5.053,
            ic_july: 5.287,
            ic_august: 5.978,
            ic_september: 6.377,
            ic_october: 6.572,
            ic_november: 6.534,
            ic_december: 6.042,
            ic_yearly: 5.916,
          },
          {
            ic_uuid: "f48f4829-8e2a-4feb-abbe-3d360a8d702e",
            ic_lat: -232005,
            ic_lon: -507943,
            ic_city: "Uraí",
            ic_states: "PARANÁ",
            ic_january: 5.923,
            ic_february: 5.887,
            ic_march: 5.308,
            ic_april: 4.642,
            ic_may: 3.709,
            ic_june: 3.398,
            ic_july: 3.598,
            ic_august: 4.562,
            ic_september: 4.738,
            ic_october: 5.4,
            ic_november: 6.026,
            ic_december: 6.326,
            ic_yearly: 4.96,
          },
          {
            ic_uuid: "27278c48-c8ab-4c5d-a770-4833b8cd9809",
            ic_lat: -147691,
            ic_lon: -426576,
            ic_city: "Urandi",
            ic_states: "BAHIA",
            ic_january: 6.637,
            ic_february: 6.68,
            ic_march: 5.978,
            ic_april: 5.448,
            ic_may: 4.842,
            ic_june: 4.647,
            ic_july: 4.822,
            ic_august: 5.508,
            ic_september: 6.089,
            ic_october: 6.32,
            ic_november: 5.948,
            ic_december: 6.603,
            ic_yearly: 5.793,
          },
          {
            ic_uuid: "d9693b3f-b541-485f-926e-7591ad474b4f",
            ic_lat: -202459,
            ic_lon: -506459,
            ic_city: "Urânia",
            ic_states: "SÃO PAULO",
            ic_january: 5.796,
            ic_february: 5.929,
            ic_march: 5.335,
            ic_april: 4.882,
            ic_may: 4.234,
            ic_june: 3.962,
            ic_july: 4.192,
            ic_august: 5.111,
            ic_september: 5.079,
            ic_october: 5.68,
            ic_november: 5.928,
            ic_december: 6.262,
            ic_yearly: 5.199,
          },
          {
            ic_uuid: "f9276b37-5a3b-457b-9db5-bc5338e440d6",
            ic_lat: -32068,
            ic_lon: -433882,
            ic_city: "Urbano Santos",
            ic_states: "MARANHÃO",
            ic_january: 4.845,
            ic_february: 4.844,
            ic_march: 4.858,
            ic_april: 4.814,
            ic_may: 4.961,
            ic_june: 5.165,
            ic_july: 5.342,
            ic_august: 5.946,
            ic_september: 6.307,
            ic_october: 6.135,
            ic_november: 5.852,
            ic_december: 5.227,
            ic_yearly: 5.358,
          },
          {
            ic_uuid: "cc727d72-436d-459d-822f-b4c0bc9a0dc0",
            ic_lat: -21784,
            ic_lon: -492809,
            ic_city: "Uru",
            ic_states: "SÃO PAULO",
            ic_january: 5.931,
            ic_february: 6.034,
            ic_march: 5.375,
            ic_april: 4.794,
            ic_may: 3.94,
            ic_june: 3.693,
            ic_july: 3.924,
            ic_august: 4.806,
            ic_september: 4.923,
            ic_october: 5.662,
            ic_november: 5.989,
            ic_december: 6.322,
            ic_yearly: 5.116,
          },
          {
            ic_uuid: "1d55ca25-62cc-498c-8f38-9b567b7ab605",
            ic_lat: -145243,
            ic_lon: -4914,
            ic_city: "Uruaçu",
            ic_states: "GOIÁS",
            ic_january: 5.382,
            ic_february: 5.683,
            ic_march: 5.276,
            ic_april: 5.169,
            ic_may: 4.987,
            ic_june: 4.667,
            ic_july: 4.93,
            ic_august: 5.735,
            ic_september: 5.63,
            ic_october: 5.6,
            ic_november: 5.177,
            ic_december: 5.447,
            ic_yearly: 5.307,
          },
          {
            ic_uuid: "bbfa9a33-bdf6-488d-b140-ed800c793769",
            ic_lat: -15505,
            ic_lon: -496793,
            ic_city: "Uruana",
            ic_states: "GOIÁS",
            ic_january: 5.414,
            ic_february: 5.692,
            ic_march: 5.278,
            ic_april: 5.219,
            ic_may: 4.969,
            ic_june: 4.65,
            ic_july: 4.713,
            ic_august: 5.669,
            ic_september: 5.529,
            ic_october: 5.632,
            ic_november: 5.283,
            ic_december: 5.392,
            ic_yearly: 5.287,
          },
          {
            ic_uuid: "3759710d-c07d-4a54-a14a-04522ecee2e0",
            ic_lat: -160603,
            ic_lon: -462565,
            ic_city: "Uruana de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 6.061,
            ic_february: 6.367,
            ic_march: 5.486,
            ic_april: 5.466,
            ic_may: 4.999,
            ic_june: 4.715,
            ic_july: 5.098,
            ic_august: 5.866,
            ic_september: 5.918,
            ic_october: 5.928,
            ic_november: 5.304,
            ic_december: 5.885,
            ic_yearly: 5.591,
          },
          {
            ic_uuid: "a77a11cf-74ec-42c5-a2eb-bd608f2f790b",
            ic_lat: -37156,
            ic_lon: -537401,
            ic_city: "Uruará",
            ic_states: "PARÁ",
            ic_january: 4.207,
            ic_february: 4.22,
            ic_march: 4.249,
            ic_april: 4.047,
            ic_may: 4.275,
            ic_june: 4.524,
            ic_july: 4.463,
            ic_august: 4.933,
            ic_september: 4.779,
            ic_october: 4.83,
            ic_november: 4.664,
            ic_december: 4.224,
            ic_yearly: 4.451,
          },
          {
            ic_uuid: "027e9bb9-acdb-4c93-8517-b43eca624cd2",
            ic_lat: -280161,
            ic_lon: -49593,
            ic_city: "Urubici",
            ic_states: "SANTA CATARINA",
            ic_january: 5.648,
            ic_february: 5.331,
            ic_march: 4.597,
            ic_april: 3.802,
            ic_may: 2.948,
            ic_june: 2.531,
            ic_july: 2.737,
            ic_august: 3.525,
            ic_september: 3.738,
            ic_october: 4.365,
            ic_november: 5.631,
            ic_december: 5.892,
            ic_yearly: 4.229,
          },
          {
            ic_uuid: "297800ea-4adf-46d6-a962-408ea7d907dc",
            ic_lat: -36236,
            ic_lon: -39511,
            ic_city: "Uruburetama",
            ic_states: "CEARÁ",
            ic_january: 4.88,
            ic_february: 4.988,
            ic_march: 4.887,
            ic_april: 4.515,
            ic_may: 4.869,
            ic_june: 4.964,
            ic_july: 5.292,
            ic_august: 6.118,
            ic_september: 6.405,
            ic_october: 6.194,
            ic_november: 5.916,
            ic_december: 5.206,
            ic_yearly: 5.353,
          },
          {
            ic_uuid: "f532afa1-811b-424e-a6d1-13b4df9935e4",
            ic_lat: -203526,
            ic_lon: -427374,
            ic_city: "Urucânia",
            ic_states: "MINAS GERAIS",
            ic_january: 5.719,
            ic_february: 6.058,
            ic_march: 5.029,
            ic_april: 4.484,
            ic_may: 3.851,
            ic_june: 3.62,
            ic_july: 3.849,
            ic_august: 4.622,
            ic_september: 4.909,
            ic_october: 5.126,
            ic_november: 4.867,
            ic_december: 5.595,
            ic_yearly: 4.811,
          },
          {
            ic_uuid: "d40bee39-aee9-482d-825f-9e5bfd301893",
            ic_lat: -25297,
            ic_lon: -577542,
            ic_city: "Urucará",
            ic_states: "AMAZONAS",
            ic_january: 3.955,
            ic_february: 4.059,
            ic_march: 4.019,
            ic_april: 3.891,
            ic_may: 4.005,
            ic_june: 4.661,
            ic_july: 4.644,
            ic_august: 5.274,
            ic_september: 5.247,
            ic_october: 5.15,
            ic_november: 4.878,
            ic_december: 4.419,
            ic_yearly: 4.517,
          },
          {
            ic_uuid: "3c523961-ec51-4be0-ab13-6e1e37579d79",
            ic_lat: -145911,
            ic_lon: -392851,
            ic_city: "Uruçuca",
            ic_states: "BAHIA",
            ic_january: 5.893,
            ic_february: 5.905,
            ic_march: 5.5,
            ic_april: 4.531,
            ic_may: 3.843,
            ic_june: 3.577,
            ic_july: 3.775,
            ic_august: 4.3,
            ic_september: 4.897,
            ic_october: 5.06,
            ic_november: 5.209,
            ic_december: 5.957,
            ic_yearly: 4.87,
          },
          {
            ic_uuid: "0fe8c3c6-d504-4cdc-be7c-993a11c5a181",
            ic_lat: -72399,
            ic_lon: -44558,
            ic_city: "Uruçuí",
            ic_states: "PIAUÍ",
            ic_january: 5.027,
            ic_february: 5.089,
            ic_march: 5.019,
            ic_april: 5.118,
            ic_may: 5.278,
            ic_june: 5.385,
            ic_july: 5.607,
            ic_august: 6.032,
            ic_september: 6.558,
            ic_october: 6.04,
            ic_november: 5.573,
            ic_december: 5.312,
            ic_yearly: 5.503,
          },
          {
            ic_uuid: "2d747082-81ac-440c-91b6-10aff1f17b78",
            ic_lat: -161245,
            ic_lon: -45741,
            ic_city: "Urucuia",
            ic_states: "MINAS GERAIS",
            ic_january: 6.253,
            ic_february: 6.421,
            ic_march: 5.54,
            ic_april: 5.505,
            ic_may: 5.034,
            ic_june: 4.757,
            ic_july: 5.132,
            ic_august: 5.98,
            ic_september: 5.979,
            ic_october: 6.015,
            ic_november: 5.451,
            ic_december: 5.993,
            ic_yearly: 5.672,
          },
          {
            ic_uuid: "ff4b2e8a-22ab-4e13-a6a3-32eaee16a500",
            ic_lat: -31288,
            ic_lon: -581501,
            ic_city: "Urucurituba",
            ic_states: "AMAZONAS",
            ic_january: 3.97,
            ic_february: 4.146,
            ic_march: 4.051,
            ic_april: 3.839,
            ic_may: 3.952,
            ic_june: 4.5,
            ic_july: 4.487,
            ic_august: 5.094,
            ic_september: 5.112,
            ic_october: 4.98,
            ic_november: 4.756,
            ic_december: 4.329,
            ic_yearly: 4.435,
          },
          {
            ic_uuid: "7e5ede96-3280-489c-b1f4-75d7a248a5a0",
            ic_lat: -297619,
            ic_lon: -570858,
            ic_city: "Uruguaiana",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.984,
            ic_february: 6.217,
            ic_march: 5.286,
            ic_april: 4.128,
            ic_may: 3.025,
            ic_june: 2.456,
            ic_july: 2.808,
            ic_august: 3.566,
            ic_september: 4.259,
            ic_october: 5.504,
            ic_november: 6.55,
            ic_december: 7.153,
            ic_yearly: 4.828,
          },
          {
            ic_uuid: "d8791358-f93b-4b17-a392-f506bc8b42e4",
            ic_lat: -33137,
            ic_lon: -405589,
            ic_city: "Uruoca",
            ic_states: "CEARÁ",
            ic_january: 5.045,
            ic_february: 5.106,
            ic_march: 5.096,
            ic_april: 4.892,
            ic_may: 5.191,
            ic_june: 5.248,
            ic_july: 5.456,
            ic_august: 6.041,
            ic_september: 6.501,
            ic_october: 6.416,
            ic_november: 6.136,
            ic_december: 5.495,
            ic_yearly: 5.552,
          },
          {
            ic_uuid: "193ca2c7-91ec-4e80-a83e-589d9860d8bf",
            ic_lat: -111265,
            ic_lon: -623644,
            ic_city: "Urupá",
            ic_states: "RONDÔNIA",
            ic_january: 4.425,
            ic_february: 4.389,
            ic_march: 4.416,
            ic_april: 4.526,
            ic_may: 4.062,
            ic_june: 4.36,
            ic_july: 4.494,
            ic_august: 4.891,
            ic_september: 4.908,
            ic_october: 5.186,
            ic_november: 4.958,
            ic_december: 4.71,
            ic_yearly: 4.61,
          },
          {
            ic_uuid: "db95d7cb-0965-49e7-b474-3aa4f4e7468d",
            ic_lat: -279562,
            ic_lon: -498733,
            ic_city: "Urupema",
            ic_states: "SANTA CATARINA",
            ic_january: 5.779,
            ic_february: 5.361,
            ic_march: 4.651,
            ic_april: 3.813,
            ic_may: 2.978,
            ic_june: 2.597,
            ic_july: 2.836,
            ic_august: 3.656,
            ic_september: 3.858,
            ic_october: 4.564,
            ic_november: 5.824,
            ic_december: 6.071,
            ic_yearly: 4.332,
          },
          {
            ic_uuid: "3697ce73-37af-4c14-96ba-542b9c7f1b55",
            ic_lat: -212037,
            ic_lon: -492935,
            ic_city: "Urupês",
            ic_states: "SÃO PAULO",
            ic_january: 5.839,
            ic_february: 5.94,
            ic_march: 5.254,
            ic_april: 4.826,
            ic_may: 4.061,
            ic_june: 3.821,
            ic_july: 4.038,
            ic_august: 4.952,
            ic_september: 5.058,
            ic_october: 5.625,
            ic_november: 5.927,
            ic_december: 6.174,
            ic_yearly: 5.126,
          },
          {
            ic_uuid: "4a1de734-8514-4ed9-aa71-b595096cf8c6",
            ic_lat: -285185,
            ic_lon: -493242,
            ic_city: "Urussanga",
            ic_states: "SANTA CATARINA",
            ic_january: 5.45,
            ic_february: 5.087,
            ic_march: 4.419,
            ic_april: 3.761,
            ic_may: 2.94,
            ic_june: 2.494,
            ic_july: 2.693,
            ic_august: 3.436,
            ic_september: 3.494,
            ic_october: 4.207,
            ic_november: 5.48,
            ic_december: 5.777,
            ic_yearly: 4.103,
          },
          {
            ic_uuid: "d9aff3f3-d634-4c05-92df-20ac694274d1",
            ic_lat: -174656,
            ic_lon: -482019,
            ic_city: "Urutaí",
            ic_states: "GOIÁS",
            ic_january: 5.723,
            ic_february: 5.867,
            ic_march: 5.296,
            ic_april: 5.247,
            ic_may: 4.793,
            ic_june: 4.508,
            ic_july: 4.753,
            ic_august: 5.622,
            ic_september: 5.429,
            ic_october: 5.691,
            ic_november: 5.509,
            ic_december: 5.762,
            ic_yearly: 5.35,
          },
          {
            ic_uuid: "c7cbeb75-c886-45ac-ba66-56b3a21a000e",
            ic_lat: -120787,
            ic_lon: -410958,
            ic_city: "Utinga",
            ic_states: "BAHIA",
            ic_january: 5.799,
            ic_february: 5.716,
            ic_march: 5.489,
            ic_april: 4.537,
            ic_may: 3.941,
            ic_june: 3.671,
            ic_july: 3.852,
            ic_august: 4.523,
            ic_september: 5.451,
            ic_october: 5.48,
            ic_november: 5.482,
            ic_december: 5.771,
            ic_yearly: 4.976,
          },
          {
            ic_uuid: "0d847207-cff7-4fdd-87d1-1affc2192386",
            ic_lat: -285083,
            ic_lon: -509422,
            ic_city: "Vacaria",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.043,
            ic_february: 5.671,
            ic_march: 4.783,
            ic_april: 3.877,
            ic_may: 2.941,
            ic_june: 2.5,
            ic_july: 2.802,
            ic_august: 3.592,
            ic_september: 3.812,
            ic_october: 4.87,
            ic_november: 6.093,
            ic_december: 6.328,
            ic_yearly: 4.442,
          },
          {
            ic_uuid: "29707eb7-b39a-4c9e-a226-4171604c0eb7",
            ic_lat: -152954,
            ic_lon: -590624,
            ic_city: "Vale de São Domingos",
            ic_states: "MATO GROSSO",
            ic_january: 5.273,
            ic_february: 5.034,
            ic_march: 4.806,
            ic_april: 4.729,
            ic_may: 4.142,
            ic_june: 4.272,
            ic_july: 4.376,
            ic_august: 5.042,
            ic_september: 5.084,
            ic_october: 5.34,
            ic_november: 5.536,
            ic_december: 5.521,
            ic_yearly: 4.93,
          },
          {
            ic_uuid: "72f0a690-877b-4069-9514-c9528f13c750",
            ic_lat: -98577,
            ic_lon: -621745,
            ic_city: "Vale do Anari",
            ic_states: "RONDÔNIA",
            ic_january: 4.335,
            ic_february: 4.363,
            ic_march: 4.337,
            ic_april: 4.456,
            ic_may: 4.057,
            ic_june: 4.41,
            ic_july: 4.536,
            ic_august: 4.849,
            ic_september: 4.809,
            ic_october: 4.97,
            ic_november: 4.716,
            ic_december: 4.59,
            ic_yearly: 4.535,
          },
          {
            ic_uuid: "5f80efa2-9767-42b2-a0bd-24a9d80ffc86",
            ic_lat: -104268,
            ic_lon: -621285,
            ic_city: "Vale do Paraíso",
            ic_states: "RONDÔNIA",
            ic_january: 4.368,
            ic_february: 4.326,
            ic_march: 4.38,
            ic_april: 4.513,
            ic_may: 4.06,
            ic_june: 4.422,
            ic_july: 4.514,
            ic_august: 4.879,
            ic_september: 4.863,
            ic_october: 5.062,
            ic_november: 4.864,
            ic_december: 4.663,
            ic_yearly: 4.576,
          },
          {
            ic_uuid: "861eb86b-3184-41d6-abd0-e822dc2be12a",
            ic_lat: -296038,
            ic_lon: -526863,
            ic_city: "Vale do Sol",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.093,
            ic_february: 5.694,
            ic_march: 4.847,
            ic_april: 3.776,
            ic_may: 2.787,
            ic_june: 2.329,
            ic_july: 2.566,
            ic_august: 3.27,
            ic_september: 3.585,
            ic_october: 4.686,
            ic_november: 6.04,
            ic_december: 6.491,
            ic_yearly: 4.347,
          },
          {
            ic_uuid: "f631fc41-046d-4a12-9261-1aa4460f1bae",
            ic_lat: -293904,
            ic_lon: -512569,
            ic_city: "Vale Real",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.143,
            ic_february: 5.665,
            ic_march: 4.74,
            ic_april: 3.786,
            ic_may: 2.799,
            ic_june: 2.354,
            ic_july: 2.6,
            ic_august: 3.274,
            ic_september: 3.551,
            ic_october: 4.595,
            ic_november: 6.041,
            ic_december: 6.416,
            ic_yearly: 4.33,
          },
          {
            ic_uuid: "d234d29a-9535-4e67-a973-693d68c14c0c",
            ic_lat: -29787,
            ic_lon: -521839,
            ic_city: "Vale Verde",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.236,
            ic_february: 5.828,
            ic_march: 4.854,
            ic_april: 3.803,
            ic_may: 2.815,
            ic_june: 2.333,
            ic_july: 2.551,
            ic_august: 3.228,
            ic_september: 3.628,
            ic_october: 4.746,
            ic_november: 6.158,
            ic_december: 6.634,
            ic_yearly: 4.401,
          },
          {
            ic_uuid: "1dafea38-3904-4dfa-8e63-ccd5d9fee39d",
            ic_lat: -133674,
            ic_lon: -390733,
            ic_city: "Valença",
            ic_states: "BAHIA",
            ic_january: 6.18,
            ic_february: 6.026,
            ic_march: 5.781,
            ic_april: 4.547,
            ic_may: 3.983,
            ic_june: 3.591,
            ic_july: 3.822,
            ic_august: 4.342,
            ic_september: 5.176,
            ic_october: 5.438,
            ic_november: 5.798,
            ic_december: 6.258,
            ic_yearly: 5.078,
          },
          {
            ic_uuid: "4c4ea036-719c-40a5-a681-33d5912bfc10",
            ic_lat: -22245,
            ic_lon: -437133,
            ic_city: "Valença",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.619,
            ic_february: 5.984,
            ic_march: 4.951,
            ic_april: 4.369,
            ic_may: 3.645,
            ic_june: 3.451,
            ic_july: 3.524,
            ic_august: 4.374,
            ic_september: 4.606,
            ic_october: 4.918,
            ic_november: 4.955,
            ic_december: 5.654,
            ic_yearly: 4.671,
          },
          {
            ic_uuid: "bb7c12f0-5505-4b6d-b4e3-fe0558237d91",
            ic_lat: -64034,
            ic_lon: -417378,
            ic_city: "Valença do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.231,
            ic_february: 5.236,
            ic_march: 5.259,
            ic_april: 5.227,
            ic_may: 5.208,
            ic_june: 5.233,
            ic_july: 5.64,
            ic_august: 6.21,
            ic_september: 6.593,
            ic_october: 6.413,
            ic_november: 6.218,
            ic_december: 5.733,
            ic_yearly: 5.683,
          },
          {
            ic_uuid: "498b087f-aecb-446f-b334-adcfa7ebce10",
            ic_lat: -114133,
            ic_lon: -394653,
            ic_city: "Valente",
            ic_states: "BAHIA",
            ic_january: 6.08,
            ic_february: 5.91,
            ic_march: 5.819,
            ic_april: 4.838,
            ic_may: 4.068,
            ic_june: 3.782,
            ic_july: 3.99,
            ic_august: 4.509,
            ic_september: 5.383,
            ic_october: 5.552,
            ic_november: 5.879,
            ic_december: 5.988,
            ic_yearly: 5.15,
          },
          {
            ic_uuid: "4b84c596-f34e-4562-988d-bc6e50d1f79b",
            ic_lat: -204221,
            ic_lon: -500893,
            ic_city: "Valentim Gentil",
            ic_states: "SÃO PAULO",
            ic_january: 5.856,
            ic_february: 5.912,
            ic_march: 5.273,
            ic_april: 4.909,
            ic_may: 4.178,
            ic_june: 3.903,
            ic_july: 4.185,
            ic_august: 5.042,
            ic_september: 5.079,
            ic_october: 5.601,
            ic_november: 5.92,
            ic_december: 6.187,
            ic_yearly: 5.17,
          },
          {
            ic_uuid: "2fcd53bb-ac93-4a1b-882a-2f5f37d2d881",
            ic_lat: -229703,
            ic_lon: -469978,
            ic_city: "Valinhos",
            ic_states: "SÃO PAULO",
            ic_january: 5.519,
            ic_february: 5.749,
            ic_march: 5.071,
            ic_april: 4.592,
            ic_may: 3.762,
            ic_june: 3.528,
            ic_july: 3.684,
            ic_august: 4.615,
            ic_september: 4.804,
            ic_october: 5.447,
            ic_november: 5.709,
            ic_december: 6.091,
            ic_yearly: 4.881,
          },
          {
            ic_uuid: "a060ce37-7ed4-4a2b-a757-cb07ed34d12e",
            ic_lat: -212234,
            ic_lon: -508703,
            ic_city: "Valparaíso",
            ic_states: "SÃO PAULO",
            ic_january: 5.935,
            ic_february: 5.957,
            ic_march: 5.422,
            ic_april: 4.867,
            ic_may: 4.032,
            ic_june: 3.757,
            ic_july: 3.962,
            ic_august: 4.887,
            ic_september: 4.95,
            ic_october: 5.586,
            ic_november: 6.033,
            ic_december: 6.309,
            ic_yearly: 5.141,
          },
          {
            ic_uuid: "32e5eeb1-ffa9-447a-ac69-c932f6317169",
            ic_lat: -160655,
            ic_lon: -479761,
            ic_city: "Valparaíso de Goiás",
            ic_states: "GOIÁS",
            ic_january: 5.463,
            ic_february: 5.725,
            ic_march: 5.083,
            ic_april: 5.08,
            ic_may: 4.823,
            ic_june: 4.718,
            ic_july: 4.984,
            ic_august: 5.821,
            ic_september: 5.664,
            ic_october: 5.511,
            ic_november: 5.091,
            ic_december: 5.415,
            ic_yearly: 5.282,
          },
          {
            ic_uuid: "3389fd60-d9c3-4fa6-b150-659b6a4fcb77",
            ic_lat: -284763,
            ic_lon: -518452,
            ic_city: "Vanini",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.128,
            ic_february: 5.699,
            ic_march: 4.891,
            ic_april: 3.955,
            ic_may: 2.928,
            ic_june: 2.48,
            ic_july: 2.796,
            ic_august: 3.51,
            ic_september: 3.773,
            ic_october: 4.881,
            ic_november: 6.098,
            ic_december: 6.465,
            ic_yearly: 4.467,
          },
          {
            ic_uuid: "3dc491e9-da0f-4680-8d8b-bf188a8e007d",
            ic_lat: -268626,
            ic_lon: -521554,
            ic_city: "Vargeão",
            ic_states: "SANTA CATARINA",
            ic_january: 6.082,
            ic_february: 5.545,
            ic_march: 5.04,
            ic_april: 4.083,
            ic_may: 3.125,
            ic_june: 2.721,
            ic_july: 2.988,
            ic_august: 3.85,
            ic_september: 4.073,
            ic_october: 5.041,
            ic_november: 6.04,
            ic_december: 6.272,
            ic_yearly: 4.572,
          },
          {
            ic_uuid: "37a810f8-f767-4d72-bef2-f6bc4f1d0c55",
            ic_lat: -274872,
            ic_lon: -509728,
            ic_city: "Vargem",
            ic_states: "SANTA CATARINA",
            ic_january: 5.801,
            ic_february: 5.463,
            ic_march: 4.781,
            ic_april: 3.888,
            ic_may: 2.926,
            ic_june: 2.545,
            ic_july: 2.801,
            ic_august: 3.645,
            ic_september: 3.863,
            ic_october: 4.701,
            ic_november: 5.88,
            ic_december: 6.157,
            ic_yearly: 4.371,
          },
          {
            ic_uuid: "2bad5410-71f9-4113-a320-73d3172c9af3",
            ic_lat: -228875,
            ic_lon: -464128,
            ic_city: "Vargem",
            ic_states: "SÃO PAULO",
            ic_january: 5.358,
            ic_february: 5.667,
            ic_march: 4.948,
            ic_april: 4.606,
            ic_may: 3.764,
            ic_june: 3.507,
            ic_july: 3.666,
            ic_august: 4.639,
            ic_september: 4.789,
            ic_october: 5.354,
            ic_november: 5.589,
            ic_december: 5.974,
            ic_yearly: 4.822,
          },
          {
            ic_uuid: "bc2892c8-ad47-4a3b-98fb-ef48f22a80f0",
            ic_lat: -195992,
            ic_lon: -422953,
            ic_city: "Vargem Alegre",
            ic_states: "MINAS GERAIS",
            ic_january: 5.786,
            ic_february: 5.914,
            ic_march: 5.014,
            ic_april: 4.48,
            ic_may: 3.811,
            ic_june: 3.597,
            ic_july: 3.76,
            ic_august: 4.437,
            ic_september: 4.822,
            ic_october: 4.961,
            ic_november: 4.784,
            ic_december: 5.588,
            ic_yearly: 4.746,
          },
          {
            ic_uuid: "7bba6720-b93b-43c0-a395-6ef092bf8bc5",
            ic_lat: -206714,
            ic_lon: -410074,
            ic_city: "Vargem Alta",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.113,
            ic_february: 6.155,
            ic_march: 5.057,
            ic_april: 4.483,
            ic_may: 3.84,
            ic_june: 3.645,
            ic_july: 3.672,
            ic_august: 4.434,
            ic_september: 4.885,
            ic_october: 5.005,
            ic_november: 4.867,
            ic_december: 5.703,
            ic_yearly: 4.822,
          },
          {
            ic_uuid: "d1a10457-0cd9-4d50-beb3-2ef567592160",
            ic_lat: -203337,
            ic_lon: -463692,
            ic_city: "Vargem Bonita",
            ic_states: "MINAS GERAIS",
            ic_january: 5.407,
            ic_february: 5.778,
            ic_march: 4.688,
            ic_april: 4.692,
            ic_may: 4.225,
            ic_june: 4.035,
            ic_july: 4.312,
            ic_august: 5.293,
            ic_september: 5.261,
            ic_october: 5.344,
            ic_november: 4.92,
            ic_december: 5.279,
            ic_yearly: 4.936,
          },
          {
            ic_uuid: "cf359100-647d-437f-b3bc-7ae3efce3e19",
            ic_lat: -270059,
            ic_lon: -517407,
            ic_city: "Vargem Bonita",
            ic_states: "SANTA CATARINA",
            ic_january: 5.801,
            ic_february: 5.418,
            ic_march: 4.952,
            ic_april: 4.035,
            ic_may: 3.094,
            ic_june: 2.735,
            ic_july: 2.977,
            ic_august: 3.841,
            ic_september: 4.059,
            ic_october: 4.942,
            ic_november: 5.998,
            ic_december: 6.144,
            ic_yearly: 4.5,
          },
          {
            ic_uuid: "7ceaf71f-4c6a-403c-92eb-f8344218d844",
            ic_lat: -35454,
            ic_lon: -439174,
            ic_city: "Vargem Grande",
            ic_states: "MARANHÃO",
            ic_january: 4.78,
            ic_february: 4.902,
            ic_march: 5.004,
            ic_april: 4.84,
            ic_may: 4.959,
            ic_june: 5.11,
            ic_july: 5.301,
            ic_august: 5.887,
            ic_september: 6.262,
            ic_october: 6.056,
            ic_november: 5.603,
            ic_december: 5.189,
            ic_yearly: 5.324,
          },
          {
            ic_uuid: "ec3075cd-218c-41c3-9766-65b04ba31e9b",
            ic_lat: -154016,
            ic_lon: -423096,
            ic_city: "Vargem Grande do Rio Pardo",
            ic_states: "MINAS GERAIS",
            ic_january: 6.003,
            ic_february: 6.292,
            ic_march: 5.481,
            ic_april: 4.802,
            ic_may: 4.24,
            ic_june: 3.928,
            ic_july: 4.252,
            ic_august: 4.981,
            ic_september: 5.777,
            ic_october: 5.773,
            ic_november: 5.344,
            ic_december: 6.106,
            ic_yearly: 5.248,
          },
          {
            ic_uuid: "cfd83072-6d96-41c3-b62f-3db547300802",
            ic_lat: -218327,
            ic_lon: -468917,
            ic_city: "Vargem Grande do Sul",
            ic_states: "SÃO PAULO",
            ic_january: 5.601,
            ic_february: 5.794,
            ic_march: 5.061,
            ic_april: 4.788,
            ic_may: 4.011,
            ic_june: 3.778,
            ic_july: 3.974,
            ic_august: 4.872,
            ic_september: 5.029,
            ic_october: 5.529,
            ic_november: 5.709,
            ic_december: 5.924,
            ic_yearly: 5.006,
          },
          {
            ic_uuid: "44a5987f-7aa2-486c-9a0b-219a6d779dc4",
            ic_lat: -235998,
            ic_lon: -470224,
            ic_city: "Vargem Grande Paulista",
            ic_states: "SÃO PAULO",
            ic_january: 5.286,
            ic_february: 5.552,
            ic_march: 4.841,
            ic_april: 4.272,
            ic_may: 3.47,
            ic_june: 3.254,
            ic_july: 3.325,
            ic_august: 4.314,
            ic_september: 4.358,
            ic_october: 4.822,
            ic_november: 5.291,
            ic_december: 5.787,
            ic_yearly: 4.548,
          },
          {
            ic_uuid: "094b80af-8352-40a0-95c9-7b6718009c3f",
            ic_lat: -215561,
            ic_lon: -454368,
            ic_city: "Varginha",
            ic_states: "MINAS GERAIS",
            ic_january: 5.484,
            ic_february: 5.707,
            ic_march: 4.886,
            ic_april: 4.607,
            ic_may: 3.935,
            ic_june: 3.698,
            ic_july: 3.971,
            ic_august: 4.831,
            ic_september: 5.054,
            ic_october: 5.387,
            ic_november: 5.206,
            ic_december: 5.665,
            ic_yearly: 4.869,
          },
          {
            ic_uuid: "ab3e4dca-543e-47e6-8869-5a9aac681272",
            ic_lat: -17044,
            ic_lon: -496324,
            ic_city: "Varjão",
            ic_states: "GOIÁS",
            ic_january: 5.55,
            ic_february: 5.654,
            ic_march: 5.325,
            ic_april: 5.181,
            ic_may: 4.781,
            ic_june: 4.506,
            ic_july: 4.723,
            ic_august: 5.605,
            ic_september: 5.432,
            ic_october: 5.55,
            ic_november: 5.46,
            ic_december: 5.592,
            ic_yearly: 5.28,
          },
          {
            ic_uuid: "6eab2984-ea2d-4ab5-8df3-92b4c37147a2",
            ic_lat: -183786,
            ic_lon: -46031,
            ic_city: "Varjão de Minas",
            ic_states: "MINAS GERAIS",
            ic_january: 5.933,
            ic_february: 6.101,
            ic_march: 5.19,
            ic_april: 5.077,
            ic_may: 4.707,
            ic_june: 4.508,
            ic_july: 4.822,
            ic_august: 5.693,
            ic_september: 5.817,
            ic_october: 5.675,
            ic_november: 5.339,
            ic_december: 5.736,
            ic_yearly: 5.383,
          },
          {
            ic_uuid: "a32ae49f-44ff-4ec9-bb8b-f8c23babc123",
            ic_lat: -41943,
            ic_lon: -404744,
            ic_city: "Varjota",
            ic_states: "CEARÁ",
            ic_january: 5.269,
            ic_february: 5.396,
            ic_march: 5.441,
            ic_april: 5.042,
            ic_may: 5.092,
            ic_june: 5.071,
            ic_july: 5.307,
            ic_august: 6.03,
            ic_september: 6.509,
            ic_october: 6.331,
            ic_november: 6.2,
            ic_december: 5.559,
            ic_yearly: 5.604,
          },
          {
            ic_uuid: "31a98d5f-83a9-4a97-989f-9c12a71fc41e",
            ic_lat: -20928,
            ic_lon: -418704,
            ic_city: "Varre-Sai",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.88,
            ic_february: 6.04,
            ic_march: 4.937,
            ic_april: 4.392,
            ic_may: 3.784,
            ic_june: 3.632,
            ic_july: 3.742,
            ic_august: 4.496,
            ic_september: 4.894,
            ic_october: 5.01,
            ic_november: 4.806,
            ic_december: 5.628,
            ic_yearly: 4.77,
          },
          {
            ic_uuid: "5401c9bf-8eeb-4da4-8a05-7e66343c0a27",
            ic_lat: -67709,
            ic_lon: -369925,
            ic_city: "Várzea",
            ic_states: "PARAÍBA",
            ic_january: 6.09,
            ic_february: 6.147,
            ic_march: 6.238,
            ic_april: 5.793,
            ic_may: 5.156,
            ic_june: 4.664,
            ic_july: 5.007,
            ic_august: 5.784,
            ic_september: 6.318,
            ic_october: 6.526,
            ic_november: 6.601,
            ic_december: 6.248,
            ic_yearly: 5.881,
          },
          {
            ic_uuid: "f4b04b97-37b3-497a-ba39-beb5a6b7a12a",
            ic_lat: -63468,
            ic_lon: -353735,
            ic_city: "Várzea",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.859,
            ic_february: 6.002,
            ic_march: 6.085,
            ic_april: 5.432,
            ic_may: 4.939,
            ic_june: 4.446,
            ic_july: 4.55,
            ic_august: 5.355,
            ic_september: 5.748,
            ic_october: 6.028,
            ic_november: 6.274,
            ic_december: 5.914,
            ic_yearly: 5.553,
          },
          {
            ic_uuid: "9d9d6b08-600d-4170-8c64-57676464cca5",
            ic_lat: -67898,
            ic_lon: -392952,
            ic_city: "Várzea Alegre",
            ic_states: "CEARÁ",
            ic_january: 5.89,
            ic_february: 5.895,
            ic_march: 5.819,
            ic_april: 5.567,
            ic_may: 5.232,
            ic_june: 5.133,
            ic_july: 5.401,
            ic_august: 6.106,
            ic_september: 6.47,
            ic_october: 6.536,
            ic_november: 6.533,
            ic_december: 6.13,
            ic_yearly: 5.893,
          },
          {
            ic_uuid: "d356f420-1178-43dd-bef9-2b6ac6da91cb",
            ic_lat: -92402,
            ic_lon: -429646,
            ic_city: "Várzea Branca",
            ic_states: "PIAUÍ",
            ic_january: 6.02,
            ic_february: 5.728,
            ic_march: 5.644,
            ic_april: 5.477,
            ic_may: 5.264,
            ic_june: 5.258,
            ic_july: 5.643,
            ic_august: 6.377,
            ic_september: 6.657,
            ic_october: 6.497,
            ic_november: 6.245,
            ic_december: 6.098,
            ic_yearly: 5.909,
          },
          {
            ic_uuid: "14e78320-2342-4148-ae46-449b2edc048b",
            ic_lat: -17598,
            ic_lon: -44731,
            ic_city: "Várzea da Palma",
            ic_states: "MINAS GERAIS",
            ic_january: 6.198,
            ic_february: 6.473,
            ic_march: 5.548,
            ic_april: 5.38,
            ic_may: 4.802,
            ic_june: 4.518,
            ic_july: 4.866,
            ic_august: 5.595,
            ic_september: 6.043,
            ic_october: 6.03,
            ic_november: 5.635,
            ic_december: 6.006,
            ic_yearly: 5.591,
          },
          {
            ic_uuid: "771aebe3-cdb4-41d0-b72d-882548d90603",
            ic_lat: -11607,
            ic_lon: -401386,
            ic_city: "Várzea da Roça",
            ic_states: "BAHIA",
            ic_january: 6.025,
            ic_february: 5.873,
            ic_march: 5.723,
            ic_april: 4.676,
            ic_may: 3.998,
            ic_june: 3.724,
            ic_july: 3.895,
            ic_august: 4.438,
            ic_september: 5.393,
            ic_october: 5.44,
            ic_november: 5.701,
            ic_december: 5.932,
            ic_yearly: 5.068,
          },
          {
            ic_uuid: "a4a91562-feb5-401d-bc1f-077b2b2a9c99",
            ic_lat: -115274,
            ic_lon: -403187,
            ic_city: "Várzea do Poço",
            ic_states: "BAHIA",
            ic_january: 5.984,
            ic_february: 5.815,
            ic_march: 5.603,
            ic_april: 4.61,
            ic_may: 3.879,
            ic_june: 3.632,
            ic_july: 3.784,
            ic_august: 4.402,
            ic_september: 5.301,
            ic_october: 5.36,
            ic_november: 5.597,
            ic_december: 5.795,
            ic_yearly: 4.98,
          },
          {
            ic_uuid: "8a7c90bd-f8ea-48d8-9605-dd160bf46f0e",
            ic_lat: -156462,
            ic_lon: -561327,
            ic_city: "Várzea Grande",
            ic_states: "MATO GROSSO",
            ic_january: 5.497,
            ic_february: 5.377,
            ic_march: 5.202,
            ic_april: 4.943,
            ic_may: 4.441,
            ic_june: 4.37,
            ic_july: 4.528,
            ic_august: 5.412,
            ic_september: 5.133,
            ic_october: 5.317,
            ic_november: 5.584,
            ic_december: 5.777,
            ic_yearly: 5.132,
          },
          {
            ic_uuid: "6d418436-43f7-4fa4-89c6-b91c6e7c8a9e",
            ic_lat: -65494,
            ic_lon: -422483,
            ic_city: "Várzea Grande",
            ic_states: "PIAUÍ",
            ic_january: 5.161,
            ic_february: 5.208,
            ic_march: 5.159,
            ic_april: 5.144,
            ic_may: 5.239,
            ic_june: 5.284,
            ic_july: 5.637,
            ic_august: 6.192,
            ic_september: 6.587,
            ic_october: 6.306,
            ic_november: 6.097,
            ic_december: 5.675,
            ic_yearly: 5.641,
          },
          {
            ic_uuid: "c3075fe8-f8ce-42c7-a2ee-a5e92feef8eb",
            ic_lat: -112561,
            ic_lon: -409435,
            ic_city: "Várzea Nova",
            ic_states: "BAHIA",
            ic_january: 6.201,
            ic_february: 5.934,
            ic_march: 5.799,
            ic_april: 4.859,
            ic_may: 4.277,
            ic_june: 4.031,
            ic_july: 4.259,
            ic_august: 4.849,
            ic_september: 5.83,
            ic_october: 5.982,
            ic_november: 5.983,
            ic_december: 6.108,
            ic_yearly: 5.343,
          },
          {
            ic_uuid: "44483f90-dcd9-4926-87ce-5bdbba445ebe",
            ic_lat: -232141,
            ic_lon: -468238,
            ic_city: "Várzea Paulista",
            ic_states: "SÃO PAULO",
            ic_january: 5.481,
            ic_february: 5.713,
            ic_march: 5.023,
            ic_april: 4.457,
            ic_may: 3.672,
            ic_june: 3.421,
            ic_july: 3.553,
            ic_august: 4.526,
            ic_september: 4.675,
            ic_october: 5.292,
            ic_november: 5.599,
            ic_december: 6.01,
            ic_yearly: 4.785,
          },
          {
            ic_uuid: "2707a9f6-e41c-4207-a570-43ae74148da5",
            ic_lat: -129676,
            ic_lon: -393922,
            ic_city: "Varzedo",
            ic_states: "BAHIA",
            ic_january: 5.913,
            ic_february: 5.875,
            ic_march: 5.646,
            ic_april: 4.572,
            ic_may: 3.959,
            ic_june: 3.566,
            ic_july: 3.746,
            ic_august: 4.283,
            ic_september: 5.044,
            ic_october: 5.222,
            ic_november: 5.496,
            ic_december: 5.947,
            ic_yearly: 4.939,
          },
          {
            ic_uuid: "ce3e0958-23ba-4b7f-a49a-235a9823dbe2",
            ic_lat: -157039,
            ic_lon: -440297,
            ic_city: "Varzelândia",
            ic_states: "MINAS GERAIS",
            ic_january: 6.379,
            ic_february: 6.693,
            ic_march: 5.746,
            ic_april: 5.585,
            ic_may: 4.983,
            ic_june: 4.7,
            ic_july: 5.044,
            ic_august: 5.781,
            ic_september: 6.108,
            ic_october: 6.095,
            ic_november: 5.567,
            ic_december: 6.209,
            ic_yearly: 5.741,
          },
          {
            ic_uuid: "6e6e2c07-19d4-470f-a381-aaf6d856a5a3",
            ic_lat: -224064,
            ic_lon: -436689,
            ic_city: "Vassouras",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.792,
            ic_february: 6.082,
            ic_march: 4.983,
            ic_april: 4.383,
            ic_may: 3.596,
            ic_june: 3.388,
            ic_july: 3.484,
            ic_august: 4.29,
            ic_september: 4.544,
            ic_october: 4.963,
            ic_november: 5.071,
            ic_december: 5.745,
            ic_yearly: 4.694,
          },
          {
            ic_uuid: "bb5108c4-bce6-462d-8967-5145207cb9a3",
            ic_lat: -179902,
            ic_lon: -469008,
            ic_city: "Vazante",
            ic_states: "MINAS GERAIS",
            ic_january: 5.838,
            ic_february: 6.056,
            ic_march: 5.175,
            ic_april: 5.16,
            ic_may: 4.754,
            ic_june: 4.488,
            ic_july: 4.857,
            ic_august: 5.68,
            ic_september: 5.738,
            ic_october: 5.81,
            ic_november: 5.408,
            ic_december: 5.697,
            ic_yearly: 5.388,
          },
          {
            ic_uuid: "dc8e56b1-298f-4b01-8867-a9361815b766",
            ic_lat: -296148,
            ic_lon: -521936,
            ic_city: "Venâncio Aires",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.041,
            ic_february: 5.71,
            ic_march: 4.784,
            ic_april: 3.797,
            ic_may: 2.786,
            ic_june: 2.317,
            ic_july: 2.538,
            ic_august: 3.256,
            ic_september: 3.588,
            ic_october: 4.621,
            ic_november: 6.07,
            ic_december: 6.462,
            ic_yearly: 4.331,
          },
          {
            ic_uuid: "5afc494f-751e-4167-b11f-e5c0a805fed1",
            ic_lat: -203275,
            ic_lon: -411359,
            ic_city: "Venda Nova do Imigrante",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.804,
            ic_february: 6.06,
            ic_march: 4.966,
            ic_april: 4.388,
            ic_may: 3.735,
            ic_june: 3.566,
            ic_july: 3.667,
            ic_august: 4.363,
            ic_september: 4.714,
            ic_october: 4.839,
            ic_november: 4.707,
            ic_december: 5.402,
            ic_yearly: 4.684,
          },
          {
            ic_uuid: "fed35250-396d-47f0-978d-6ccffe773665",
            ic_lat: -63152,
            ic_lon: -384877,
            ic_city: "Venha-Ver",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.961,
            ic_february: 5.896,
            ic_march: 5.905,
            ic_april: 5.571,
            ic_may: 5.205,
            ic_june: 4.975,
            ic_july: 5.364,
            ic_august: 6.097,
            ic_september: 6.489,
            ic_october: 6.585,
            ic_november: 6.712,
            ic_december: 6.16,
            ic_yearly: 5.91,
          },
          {
            ic_uuid: "76d99144-4f63-4063-a641-d6a065702b48",
            ic_lat: -24246,
            ic_lon: -502429,
            ic_city: "Ventania",
            ic_states: "PARANÁ",
            ic_january: 5.637,
            ic_february: 5.503,
            ic_march: 4.978,
            ic_april: 4.376,
            ic_may: 3.473,
            ic_june: 3.096,
            ic_july: 3.345,
            ic_august: 4.365,
            ic_september: 4.533,
            ic_october: 5.057,
            ic_november: 5.776,
            ic_december: 6.023,
            ic_yearly: 4.68,
          },
          {
            ic_uuid: "5e731e30-5d80-4c71-8159-7a13e7bbf9d3",
            ic_lat: -85765,
            ic_lon: -368762,
            ic_city: "Venturosa",
            ic_states: "PERNAMBUCO",
            ic_january: 6.247,
            ic_february: 6.008,
            ic_march: 6.034,
            ic_april: 5.433,
            ic_may: 4.473,
            ic_june: 3.994,
            ic_july: 4.021,
            ic_august: 4.843,
            ic_september: 5.806,
            ic_october: 6.182,
            ic_november: 6.629,
            ic_december: 6.433,
            ic_yearly: 5.508,
          },
          {
            ic_uuid: "2777a99d-9572-4d4d-84f0-eb17610fa939",
            ic_lat: -122891,
            ic_lon: -552969,
            ic_city: "Vera",
            ic_states: "MATO GROSSO",
            ic_january: 5.137,
            ic_february: 5.076,
            ic_march: 4.941,
            ic_april: 4.912,
            ic_may: 4.813,
            ic_june: 4.689,
            ic_july: 4.968,
            ic_august: 5.297,
            ic_september: 5.099,
            ic_october: 5.264,
            ic_november: 5.144,
            ic_december: 5.298,
            ic_yearly: 5.053,
          },
          {
            ic_uuid: "2d373f58-fbe4-4495-bed9-a666f742a5a4",
            ic_lat: -129637,
            ic_lon: -386177,
            ic_city: "Vera Cruz",
            ic_states: "BAHIA",
            ic_january: 6.448,
            ic_february: 6.29,
            ic_march: 5.946,
            ic_april: 4.792,
            ic_may: 4.05,
            ic_june: 3.811,
            ic_july: 4.039,
            ic_august: 4.692,
            ic_september: 5.498,
            ic_october: 5.842,
            ic_november: 6.063,
            ic_december: 6.45,
            ic_yearly: 5.327,
          },
          {
            ic_uuid: "74b108e7-f754-4b10-bed6-e9f350cfbc60",
            ic_lat: -60444,
            ic_lon: -354283,
            ic_city: "Vera Cruz",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 5.953,
            ic_february: 5.952,
            ic_march: 6.017,
            ic_april: 5.519,
            ic_may: 4.966,
            ic_june: 4.5,
            ic_july: 4.667,
            ic_august: 5.37,
            ic_september: 5.822,
            ic_october: 6.07,
            ic_november: 6.265,
            ic_december: 5.964,
            ic_yearly: 5.589,
          },
          {
            ic_uuid: "edf06362-e624-4057-99cc-05aef54199e3",
            ic_lat: -297189,
            ic_lon: -525156,
            ic_city: "Vera Cruz",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.147,
            ic_february: 5.754,
            ic_march: 4.862,
            ic_april: 3.784,
            ic_may: 2.79,
            ic_june: 2.32,
            ic_july: 2.534,
            ic_august: 3.234,
            ic_september: 3.59,
            ic_october: 4.705,
            ic_november: 6.089,
            ic_december: 6.553,
            ic_yearly: 4.363,
          },
          {
            ic_uuid: "92a9e169-efa3-47a8-98f4-36a78d3e2ec7",
            ic_lat: -222188,
            ic_lon: -498212,
            ic_city: "Vera Cruz",
            ic_states: "SÃO PAULO",
            ic_january: 5.694,
            ic_february: 5.887,
            ic_march: 5.301,
            ic_april: 4.763,
            ic_may: 3.849,
            ic_june: 3.594,
            ic_july: 3.846,
            ic_august: 4.777,
            ic_september: 4.856,
            ic_october: 5.556,
            ic_november: 5.948,
            ic_december: 6.183,
            ic_yearly: 5.021,
          },
          {
            ic_uuid: "08e95c4e-6e6e-443c-acbc-873ab7fc825a",
            ic_lat: -250582,
            ic_lon: -538775,
            ic_city: "Vera Cruz do Oeste",
            ic_states: "PARANÁ",
            ic_january: 6.271,
            ic_february: 5.692,
            ic_march: 5.074,
            ic_april: 4.236,
            ic_may: 3.304,
            ic_june: 2.913,
            ic_july: 3.156,
            ic_august: 4.156,
            ic_september: 4.351,
            ic_october: 5.206,
            ic_november: 5.964,
            ic_december: 6.412,
            ic_yearly: 4.728,
          },
          {
            ic_uuid: "dfaf3fbb-b46b-44da-b35e-3a141eb61d27",
            ic_lat: -75979,
            ic_lon: -414677,
            ic_city: "Vera Mendes",
            ic_states: "PIAUÍ",
            ic_january: 5.712,
            ic_february: 5.533,
            ic_march: 5.56,
            ic_april: 5.318,
            ic_may: 5.221,
            ic_june: 5.259,
            ic_july: 5.592,
            ic_august: 6.293,
            ic_september: 6.728,
            ic_october: 6.565,
            ic_november: 6.403,
            ic_december: 6.006,
            ic_yearly: 5.849,
          },
          {
            ic_uuid: "a762e08b-e345-4165-877f-9a9825d2d335",
            ic_lat: -289317,
            ic_lon: -515521,
            ic_city: "Veranópolis",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.121,
            ic_february: 5.693,
            ic_march: 4.824,
            ic_april: 3.854,
            ic_may: 2.886,
            ic_june: 2.429,
            ic_july: 2.723,
            ic_august: 3.455,
            ic_september: 3.722,
            ic_october: 4.782,
            ic_november: 6.119,
            ic_december: 6.444,
            ic_yearly: 4.421,
          },
          {
            ic_uuid: "2d071352-587b-4dee-a022-b0e779feaf77",
            ic_lat: -79305,
            ic_lon: -389699,
            ic_city: "Verdejante",
            ic_states: "PERNAMBUCO",
            ic_january: 5.996,
            ic_february: 5.93,
            ic_march: 5.922,
            ic_april: 5.463,
            ic_may: 4.858,
            ic_june: 4.509,
            ic_july: 4.796,
            ic_august: 5.566,
            ic_september: 6.458,
            ic_october: 6.444,
            ic_november: 6.598,
            ic_december: 6.253,
            ic_yearly: 5.733,
          },
          {
            ic_uuid: "cfb43343-fb38-4252-a52b-039067db6af9",
            ic_lat: -155927,
            ic_lon: -43607,
            ic_city: "Verdelândia",
            ic_states: "MINAS GERAIS",
            ic_january: 6.58,
            ic_february: 6.761,
            ic_march: 5.827,
            ic_april: 5.513,
            ic_may: 4.896,
            ic_june: 4.611,
            ic_july: 4.957,
            ic_august: 5.644,
            ic_september: 5.93,
            ic_october: 6.103,
            ic_november: 5.738,
            ic_december: 6.348,
            ic_yearly: 5.742,
          },
          {
            ic_uuid: "7c4ed2f2-8625-4622-86e5-c1a25351233f",
            ic_lat: -258777,
            ic_lon: -529056,
            ic_city: "Verê",
            ic_states: "PARANÁ",
            ic_january: 6.294,
            ic_february: 5.773,
            ic_march: 5.271,
            ic_april: 4.284,
            ic_may: 3.292,
            ic_june: 2.827,
            ic_july: 3.12,
            ic_august: 4.105,
            ic_september: 4.287,
            ic_october: 5.245,
            ic_november: 6.225,
            ic_december: 6.472,
            ic_yearly: 4.766,
          },
          {
            ic_uuid: "54b3d476-0505-44a2-bd8d-6b698193877b",
            ic_lat: -172187,
            ic_lon: -400977,
            ic_city: "Vereda",
            ic_states: "BAHIA",
            ic_january: 6.139,
            ic_february: 6.27,
            ic_march: 5.502,
            ic_april: 4.63,
            ic_may: 3.871,
            ic_june: 3.5,
            ic_july: 3.674,
            ic_august: 4.344,
            ic_september: 5.018,
            ic_october: 5.296,
            ic_november: 5.316,
            ic_december: 6.209,
            ic_yearly: 4.981,
          },
          {
            ic_uuid: "e4b6ae70-ca1a-4c55-91a5-05705a5f659f",
            ic_lat: -173978,
            ic_lon: -427311,
            ic_city: "Veredinha",
            ic_states: "MINAS GERAIS",
            ic_january: 6.035,
            ic_february: 6.274,
            ic_march: 5.249,
            ic_april: 4.63,
            ic_may: 4.05,
            ic_june: 3.929,
            ic_july: 4.113,
            ic_august: 4.893,
            ic_september: 5.38,
            ic_october: 5.523,
            ic_november: 5.112,
            ic_december: 5.808,
            ic_yearly: 5.083,
          },
          {
            ic_uuid: "52eda454-cc4b-4ffd-9518-a79a34bb28e3",
            ic_lat: -19663,
            ic_lon: -48308,
            ic_city: "Veríssimo",
            ic_states: "MINAS GERAIS",
            ic_january: 5.613,
            ic_february: 5.858,
            ic_march: 5.168,
            ic_april: 5.001,
            ic_may: 4.406,
            ic_june: 4.166,
            ic_july: 4.417,
            ic_august: 5.351,
            ic_september: 5.188,
            ic_october: 5.546,
            ic_november: 5.644,
            ic_december: 5.867,
            ic_yearly: 5.186,
          },
          {
            ic_uuid: "5ec56d73-ddd6-4a79-8ff7-3a59f762119d",
            ic_lat: -200411,
            ic_lon: -422692,
            ic_city: "Vermelho Novo",
            ic_states: "MINAS GERAIS",
            ic_january: 5.753,
            ic_february: 5.947,
            ic_march: 4.936,
            ic_april: 4.49,
            ic_may: 3.828,
            ic_june: 3.682,
            ic_july: 3.799,
            ic_august: 4.53,
            ic_september: 4.91,
            ic_october: 5.02,
            ic_november: 4.831,
            ic_december: 5.61,
            ic_yearly: 4.778,
          },
          {
            ic_uuid: "168d8ecc-778d-4077-95f2-caa4eba78506",
            ic_lat: -77713,
            ic_lon: -358493,
            ic_city: "Vertente do Lério",
            ic_states: "PERNAMBUCO",
            ic_january: 5.791,
            ic_february: 5.811,
            ic_march: 5.87,
            ic_april: 5.26,
            ic_may: 4.573,
            ic_june: 4.091,
            ic_july: 4.288,
            ic_august: 4.967,
            ic_september: 5.544,
            ic_october: 5.821,
            ic_november: 6.123,
            ic_december: 5.953,
            ic_yearly: 5.341,
          },
          {
            ic_uuid: "77b6076e-a16a-4bb9-98d1-5e02254139d0",
            ic_lat: -79036,
            ic_lon: -359867,
            ic_city: "Vertentes",
            ic_states: "PERNAMBUCO",
            ic_january: 5.722,
            ic_february: 5.773,
            ic_march: 5.842,
            ic_april: 5.192,
            ic_may: 4.562,
            ic_june: 4.044,
            ic_july: 4.261,
            ic_august: 4.874,
            ic_september: 5.473,
            ic_october: 5.795,
            ic_november: 6.106,
            ic_december: 5.983,
            ic_yearly: 5.302,
          },
          {
            ic_uuid: "e47b0c4d-30a7-4cd3-a79d-65b63d5449f4",
            ic_lat: -196888,
            ic_lon: -439243,
            ic_city: "Vespasiano",
            ic_states: "MINAS GERAIS",
            ic_january: 5.845,
            ic_february: 6.076,
            ic_march: 5.293,
            ic_april: 4.886,
            ic_may: 4.297,
            ic_june: 4.087,
            ic_july: 4.365,
            ic_august: 5.194,
            ic_september: 5.561,
            ic_october: 5.629,
            ic_november: 5.444,
            ic_december: 5.71,
            ic_yearly: 5.199,
          },
          {
            ic_uuid: "3f45f113-7bd9-4da9-ba7a-53a152028205",
            ic_lat: -29068,
            ic_lon: -518589,
            ic_city: "Vespasiano Corrêa",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.184,
            ic_february: 5.748,
            ic_march: 4.84,
            ic_april: 3.806,
            ic_may: 2.835,
            ic_june: 2.364,
            ic_july: 2.64,
            ic_august: 3.379,
            ic_september: 3.663,
            ic_october: 4.709,
            ic_november: 6.087,
            ic_december: 6.476,
            ic_yearly: 4.394,
          },
          {
            ic_uuid: "b33d8ec5-14b4-41b7-b157-f48ee7176058",
            ic_lat: -27572,
            ic_lon: -520216,
            ic_city: "Viadutos",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.263,
            ic_february: 5.77,
            ic_march: 5.006,
            ic_april: 4.045,
            ic_may: 3.066,
            ic_june: 2.574,
            ic_july: 2.911,
            ic_august: 3.715,
            ic_september: 3.969,
            ic_october: 5.05,
            ic_november: 6.217,
            ic_december: 6.506,
            ic_yearly: 4.591,
          },
          {
            ic_uuid: "1849a95d-0113-4556-8c19-a715c6cfe9c3",
            ic_lat: -300824,
            ic_lon: -510199,
            ic_city: "Viamão",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.352,
            ic_february: 5.744,
            ic_march: 4.774,
            ic_april: 3.78,
            ic_may: 2.76,
            ic_june: 2.313,
            ic_july: 2.516,
            ic_august: 3.175,
            ic_september: 3.563,
            ic_october: 4.75,
            ic_november: 6.181,
            ic_december: 6.593,
            ic_yearly: 4.375,
          },
          {
            ic_uuid: "26c112b3-5040-4b0a-ab6e-5455c62b8bda",
            ic_lat: -20383,
            ic_lon: -404936,
            ic_city: "Viana",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.127,
            ic_february: 6.453,
            ic_march: 5.303,
            ic_april: 4.527,
            ic_may: 3.854,
            ic_june: 3.612,
            ic_july: 3.651,
            ic_august: 4.354,
            ic_september: 4.812,
            ic_october: 4.917,
            ic_november: 4.952,
            ic_december: 5.834,
            ic_yearly: 4.866,
          },
          {
            ic_uuid: "5288cebd-6952-4e38-bfd0-b2c50dc6af02",
            ic_lat: -32119,
            ic_lon: -449995,
            ic_city: "Viana",
            ic_states: "MARANHÃO",
            ic_january: 4.672,
            ic_february: 4.974,
            ic_march: 4.985,
            ic_april: 4.913,
            ic_may: 4.947,
            ic_june: 5.105,
            ic_july: 5.225,
            ic_august: 5.739,
            ic_september: 5.962,
            ic_october: 5.684,
            ic_november: 5.353,
            ic_december: 5.016,
            ic_yearly: 5.214,
          },
          {
            ic_uuid: "86aa5b1f-4b16-4b23-8d0d-d5ca0c97bb2f",
            ic_lat: -167457,
            ic_lon: -485066,
            ic_city: "Vianópolis",
            ic_states: "GOIÁS",
            ic_january: 5.537,
            ic_february: 5.782,
            ic_march: 5.166,
            ic_april: 5.12,
            ic_may: 4.788,
            ic_june: 4.6,
            ic_july: 4.843,
            ic_august: 5.733,
            ic_september: 5.583,
            ic_october: 5.677,
            ic_november: 5.29,
            ic_december: 5.556,
            ic_yearly: 5.306,
          },
          {
            ic_uuid: "3d2c6dbf-7936-48d7-bd9a-16b3d113a14c",
            ic_lat: -76565,
            ic_lon: -353218,
            ic_city: "Vicência",
            ic_states: "PERNAMBUCO",
            ic_january: 5.467,
            ic_february: 5.615,
            ic_march: 5.695,
            ic_april: 5.083,
            ic_may: 4.379,
            ic_june: 4.019,
            ic_july: 4.096,
            ic_august: 4.821,
            ic_september: 5.354,
            ic_october: 5.546,
            ic_november: 5.886,
            ic_december: 5.726,
            ic_yearly: 5.141,
          },
          {
            ic_uuid: "946a34b9-3a98-4ac9-b0e1-18959f0e7ede",
            ic_lat: -271612,
            ic_lon: -534027,
            ic_city: "Vicente Dutra",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.429,
            ic_february: 5.857,
            ic_march: 5.155,
            ic_april: 4.041,
            ic_may: 3.096,
            ic_june: 2.541,
            ic_july: 2.857,
            ic_august: 3.731,
            ic_september: 4.01,
            ic_october: 5.172,
            ic_november: 6.201,
            ic_december: 6.588,
            ic_yearly: 4.64,
          },
          {
            ic_uuid: "e8e6efaa-da40-40f5-a4a8-9f1725ea7292",
            ic_lat: -224098,
            ic_lon: -544376,
            ic_city: "Vicentina",
            ic_states: "MATO GROSSO DO SUL",
            ic_january: 6.02,
            ic_february: 5.838,
            ic_march: 5.345,
            ic_april: 4.563,
            ic_may: 3.67,
            ic_june: 3.364,
            ic_july: 3.511,
            ic_august: 4.469,
            ic_september: 4.752,
            ic_october: 5.373,
            ic_november: 5.993,
            ic_december: 6.442,
            ic_yearly: 4.945,
          },
          {
            ic_uuid: "0538a05c-cddb-4eb2-a050-d96b752e09fd",
            ic_lat: -177327,
            ic_lon: -498052,
            ic_city: "Vicentinópolis",
            ic_states: "GOIÁS",
            ic_january: 5.71,
            ic_february: 5.868,
            ic_march: 5.302,
            ic_april: 5.121,
            ic_may: 4.733,
            ic_june: 4.36,
            ic_july: 4.631,
            ic_august: 5.562,
            ic_september: 5.347,
            ic_october: 5.59,
            ic_november: 5.611,
            ic_december: 5.754,
            ic_yearly: 5.299,
          },
          {
            ic_uuid: "a44ae8e6-1418-4170-b5d7-b469032f6d6f",
            ic_lat: -93719,
            ic_lon: -362423,
            ic_city: "Viçosa",
            ic_states: "ALAGOAS",
            ic_january: 5.856,
            ic_february: 5.82,
            ic_march: 5.789,
            ic_april: 5.092,
            ic_may: 4.19,
            ic_june: 3.771,
            ic_july: 3.916,
            ic_august: 4.479,
            ic_september: 5.325,
            ic_october: 5.668,
            ic_november: 6.227,
            ic_december: 6.183,
            ic_yearly: 5.193,
          },
          {
            ic_uuid: "b39f41b4-9c05-4858-863d-22dcfe6de6bb",
            ic_lat: -207564,
            ic_lon: -428745,
            ic_city: "Viçosa",
            ic_states: "MINAS GERAIS",
            ic_january: 5.598,
            ic_february: 5.9,
            ic_march: 4.931,
            ic_april: 4.37,
            ic_may: 3.727,
            ic_june: 3.538,
            ic_july: 3.757,
            ic_august: 4.572,
            ic_september: 4.852,
            ic_october: 5.063,
            ic_november: 4.816,
            ic_december: 5.517,
            ic_yearly: 4.72,
          },
          {
            ic_uuid: "1dbb024f-9226-4f6c-9b53-9d8cbeb8e86a",
            ic_lat: -59829,
            ic_lon: -379465,
            ic_city: "Viçosa",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.053,
            ic_february: 6.118,
            ic_march: 6.139,
            ic_april: 5.847,
            ic_may: 5.398,
            ic_june: 5.108,
            ic_july: 5.432,
            ic_august: 6.102,
            ic_september: 6.5,
            ic_october: 6.671,
            ic_november: 6.701,
            ic_december: 6.149,
            ic_yearly: 6.018,
          },
          {
            ic_uuid: "0c570710-9c4c-4612-81b9-530e461ae67d",
            ic_lat: -35671,
            ic_lon: -410919,
            ic_city: "Viçosa do Ceará",
            ic_states: "CEARÁ",
            ic_january: 4.964,
            ic_february: 5.084,
            ic_march: 5.015,
            ic_april: 4.725,
            ic_may: 4.904,
            ic_june: 5.029,
            ic_july: 5.318,
            ic_august: 6.103,
            ic_september: 6.516,
            ic_october: 6.438,
            ic_november: 6.278,
            ic_december: 5.474,
            ic_yearly: 5.487,
          },
          {
            ic_uuid: "60f76f67-bef5-48d1-a787-d24141697acb",
            ic_lat: -285636,
            ic_lon: -5275,
            ic_city: "Victor Graeff",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.354,
            ic_february: 5.916,
            ic_march: 5.011,
            ic_april: 3.969,
            ic_may: 3.015,
            ic_june: 2.483,
            ic_july: 2.776,
            ic_august: 3.556,
            ic_september: 3.843,
            ic_october: 5.03,
            ic_november: 6.27,
            ic_december: 6.643,
            ic_yearly: 4.572,
          },
          {
            ic_uuid: "b41a88b9-0555-4146-a851-568b21eaa135",
            ic_lat: -273891,
            ic_lon: -493598,
            ic_city: "Vidal Ramos",
            ic_states: "SANTA CATARINA",
            ic_january: 5.532,
            ic_february: 5.184,
            ic_march: 4.486,
            ic_april: 3.664,
            ic_may: 2.912,
            ic_june: 2.428,
            ic_july: 2.612,
            ic_august: 3.369,
            ic_september: 3.463,
            ic_october: 4.01,
            ic_november: 5.267,
            ic_december: 5.568,
            ic_yearly: 4.041,
          },
          {
            ic_uuid: "19d71947-4e86-4cf3-a7be-330035d60679",
            ic_lat: -270065,
            ic_lon: -511513,
            ic_city: "Videira",
            ic_states: "SANTA CATARINA",
            ic_january: 5.755,
            ic_february: 5.338,
            ic_march: 4.857,
            ic_april: 3.929,
            ic_may: 3.017,
            ic_june: 2.623,
            ic_july: 2.89,
            ic_august: 3.782,
            ic_september: 3.946,
            ic_october: 4.76,
            ic_november: 5.861,
            ic_december: 6.058,
            ic_yearly: 4.401,
          },
          {
            ic_uuid: "65863f10-0a99-4ea2-8295-2bb29724c766",
            ic_lat: -208675,
            ic_lon: -422404,
            ic_city: "Vieiras",
            ic_states: "MINAS GERAIS",
            ic_january: 5.831,
            ic_february: 5.98,
            ic_march: 4.967,
            ic_april: 4.382,
            ic_may: 3.799,
            ic_june: 3.636,
            ic_july: 3.748,
            ic_august: 4.589,
            ic_september: 4.933,
            ic_october: 5.024,
            ic_november: 4.797,
            ic_december: 5.559,
            ic_yearly: 4.77,
          },
          {
            ic_uuid: "36378b3c-d52b-4b6c-b4fa-e489fa36dd1f",
            ic_lat: -65453,
            ic_lon: -382773,
            ic_city: "Vieirópolis",
            ic_states: "PARAÍBA",
            ic_january: 6.03,
            ic_february: 6.015,
            ic_march: 6.005,
            ic_april: 5.673,
            ic_may: 5.239,
            ic_june: 5.003,
            ic_july: 5.305,
            ic_august: 6.064,
            ic_september: 6.451,
            ic_october: 6.601,
            ic_november: 6.729,
            ic_december: 6.191,
            ic_yearly: 5.942,
          },
          {
            ic_uuid: "b4f63e72-3d17-471c-bca4-38fb0849ff45",
            ic_lat: -8616,
            ic_lon: -48139,
            ic_city: "Vigia",
            ic_states: "PARÁ",
            ic_january: 4.272,
            ic_february: 4.033,
            ic_march: 3.986,
            ic_april: 4.061,
            ic_may: 4.409,
            ic_june: 4.664,
            ic_july: 4.813,
            ic_august: 5.137,
            ic_september: 5.343,
            ic_october: 5.186,
            ic_november: 5.069,
            ic_december: 4.526,
            ic_yearly: 4.625,
          },
          {
            ic_uuid: "1ff7cfc2-edf0-45cb-9eea-15c4252e7f32",
            ic_lat: -150044,
            ic_lon: -599477,
            ic_city: "Vila Bela da Santíssima Trindade",
            ic_states: "MATO GROSSO",
            ic_january: 5.121,
            ic_february: 4.912,
            ic_march: 4.903,
            ic_april: 4.779,
            ic_may: 4.189,
            ic_june: 4.288,
            ic_july: 4.437,
            ic_august: 5.1,
            ic_september: 5.031,
            ic_october: 5.277,
            ic_november: 5.39,
            ic_december: 5.283,
            ic_yearly: 4.893,
          },
          {
            ic_uuid: "f79c7c24-a88c-43a3-aacb-82de7bc5a7ca",
            ic_lat: -150341,
            ic_lon: -470529,
            ic_city: "Vila Boa",
            ic_states: "GOIÁS",
            ic_january: 5.934,
            ic_february: 6.103,
            ic_march: 5.495,
            ic_april: 5.358,
            ic_may: 4.991,
            ic_june: 4.876,
            ic_july: 5.255,
            ic_august: 5.89,
            ic_september: 5.959,
            ic_october: 5.874,
            ic_november: 5.42,
            ic_december: 5.822,
            ic_yearly: 5.582,
          },
          {
            ic_uuid: "fe8bcc27-3e0c-4702-a6cc-74a925666e81",
            ic_lat: -63138,
            ic_lon: -350763,
            ic_city: "Vila Flor",
            ic_states: "RIO GRANDE DO NORTE",
            ic_january: 6.001,
            ic_february: 6.15,
            ic_march: 6.193,
            ic_april: 5.586,
            ic_may: 5.038,
            ic_june: 4.576,
            ic_july: 4.742,
            ic_august: 5.548,
            ic_september: 6.021,
            ic_october: 6.241,
            ic_november: 6.352,
            ic_december: 6.173,
            ic_yearly: 5.718,
          },
          {
            ic_uuid: "08da916d-29f8-47ee-9257-54f5addb94ae",
            ic_lat: -288603,
            ic_lon: -515508,
            ic_city: "Vila Flores",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.121,
            ic_february: 5.693,
            ic_march: 4.824,
            ic_april: 3.854,
            ic_may: 2.886,
            ic_june: 2.429,
            ic_july: 2.723,
            ic_august: 3.455,
            ic_september: 3.722,
            ic_october: 4.782,
            ic_november: 6.119,
            ic_december: 6.444,
            ic_yearly: 4.421,
          },
          {
            ic_uuid: "9e862e97-2260-4e9d-83be-f1fa6744f052",
            ic_lat: -281067,
            ic_lon: -521442,
            ic_city: "Vila Lângaro",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.226,
            ic_february: 5.817,
            ic_march: 4.933,
            ic_april: 4.027,
            ic_may: 3.033,
            ic_june: 2.513,
            ic_july: 2.852,
            ic_august: 3.642,
            ic_september: 3.889,
            ic_october: 5.009,
            ic_november: 6.16,
            ic_december: 6.5,
            ic_yearly: 4.55,
          },
          {
            ic_uuid: "8b0c58ed-cac8-4858-9e19-bdef80f0eaa6",
            ic_lat: -285363,
            ic_lon: -521491,
            ic_city: "Vila Maria",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.171,
            ic_february: 5.765,
            ic_march: 4.931,
            ic_april: 3.968,
            ic_may: 2.973,
            ic_june: 2.483,
            ic_july: 2.787,
            ic_august: 3.52,
            ic_september: 3.795,
            ic_october: 4.947,
            ic_november: 6.162,
            ic_december: 6.558,
            ic_yearly: 4.505,
          },
          {
            ic_uuid: "0957387d-d126-4220-b627-e57683790bd9",
            ic_lat: -71423,
            ic_lon: -409395,
            ic_city: "Vila Nova do Piauí",
            ic_states: "PIAUÍ",
            ic_january: 5.557,
            ic_february: 5.441,
            ic_march: 5.609,
            ic_april: 5.291,
            ic_may: 5.072,
            ic_june: 5.05,
            ic_july: 5.494,
            ic_august: 6.184,
            ic_september: 6.656,
            ic_october: 6.434,
            ic_november: 6.372,
            ic_december: 5.995,
            ic_yearly: 5.763,
          },
          {
            ic_uuid: "5c8b899e-7024-48e2-a371-91aa8e431154",
            ic_lat: -303486,
            ic_lon: -538772,
            ic_city: "Vila Nova do Sul",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.547,
            ic_february: 6.075,
            ic_march: 5.108,
            ic_april: 3.939,
            ic_may: 2.843,
            ic_june: 2.381,
            ic_july: 2.602,
            ic_august: 3.345,
            ic_september: 3.865,
            ic_october: 5.118,
            ic_november: 6.412,
            ic_december: 6.959,
            ic_yearly: 4.6,
          },
          {
            ic_uuid: "0baec532-a114-49b1-8fe7-026a97624f5c",
            ic_lat: -5182,
            ic_lon: -48138,
            ic_city: "Vila Nova dos Martírios",
            ic_states: "MARANHÃO",
            ic_january: 4.446,
            ic_february: 4.614,
            ic_march: 4.685,
            ic_april: 4.825,
            ic_may: 4.783,
            ic_june: 5.206,
            ic_july: 5.221,
            ic_august: 5.653,
            ic_september: 5.433,
            ic_october: 4.958,
            ic_november: 4.734,
            ic_december: 4.523,
            ic_yearly: 4.923,
          },
          {
            ic_uuid: "e0a5a948-0763-43fe-978f-7471e6e86b6b",
            ic_lat: -186096,
            ic_lon: -406093,
            ic_city: "Vila Pavão",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 5.976,
            ic_february: 6.09,
            ic_march: 5.264,
            ic_april: 4.613,
            ic_may: 3.909,
            ic_june: 3.565,
            ic_july: 3.616,
            ic_august: 4.175,
            ic_september: 4.73,
            ic_october: 4.914,
            ic_november: 4.866,
            ic_december: 5.829,
            ic_yearly: 4.795,
          },
          {
            ic_uuid: "d6e44a46-261e-437e-84b2-b2006a79ab04",
            ic_lat: -15456,
            ic_lon: -488827,
            ic_city: "Vila Propício",
            ic_states: "GOIÁS",
            ic_january: 5.537,
            ic_february: 5.726,
            ic_march: 5.238,
            ic_april: 5.207,
            ic_may: 4.932,
            ic_june: 4.692,
            ic_july: 4.829,
            ic_august: 5.847,
            ic_september: 5.635,
            ic_october: 5.647,
            ic_november: 5.261,
            ic_december: 5.439,
            ic_yearly: 5.332,
          },
          {
            ic_uuid: "4ede00b8-eebe-488c-9ab3-375ec5b150b2",
            ic_lat: -100141,
            ic_lon: -51119,
            ic_city: "Vila Rica",
            ic_states: "MATO GROSSO",
            ic_january: 4.951,
            ic_february: 4.986,
            ic_march: 4.821,
            ic_april: 4.744,
            ic_may: 4.757,
            ic_june: 4.912,
            ic_july: 5.138,
            ic_august: 5.682,
            ic_september: 5.274,
            ic_october: 5.09,
            ic_november: 4.998,
            ic_december: 5.124,
            ic_yearly: 5.04,
          },
          {
            ic_uuid: "5e029ae1-6611-4a8d-b6e1-a2cee2741617",
            ic_lat: -189962,
            ic_lon: -403853,
            ic_city: "Vila Valério",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.072,
            ic_february: 6.106,
            ic_march: 5.291,
            ic_april: 4.516,
            ic_may: 3.85,
            ic_june: 3.563,
            ic_july: 3.605,
            ic_august: 4.204,
            ic_september: 4.721,
            ic_october: 4.895,
            ic_november: 4.877,
            ic_december: 5.744,
            ic_yearly: 4.787,
          },
          {
            ic_uuid: "53305547-f5e0-4ccb-8e9b-2ff306e06bc4",
            ic_lat: -203422,
            ic_lon: -402878,
            ic_city: "Vila Velha",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.309,
            ic_february: 6.603,
            ic_march: 5.452,
            ic_april: 4.675,
            ic_may: 3.961,
            ic_june: 3.638,
            ic_july: 3.754,
            ic_august: 4.492,
            ic_september: 4.972,
            ic_october: 5.155,
            ic_november: 5.246,
            ic_december: 6.052,
            ic_yearly: 5.026,
          },
          {
            ic_uuid: "fdb96f53-bedb-4cb4-b04d-f67a906f1b91",
            ic_lat: -127506,
            ic_lon: -601493,
            ic_city: "Vilhena",
            ic_states: "RONDÔNIA",
            ic_january: 4.582,
            ic_february: 4.449,
            ic_march: 4.425,
            ic_april: 4.519,
            ic_may: 4.241,
            ic_june: 4.364,
            ic_july: 4.539,
            ic_august: 5.048,
            ic_september: 4.797,
            ic_october: 4.948,
            ic_november: 4.872,
            ic_december: 4.824,
            ic_yearly: 4.634,
          },
          {
            ic_uuid: "2e436410-5dc0-44f8-8aff-045169760ed3",
            ic_lat: -230307,
            ic_lon: -469837,
            ic_city: "Vinhedo",
            ic_states: "SÃO PAULO",
            ic_january: 5.519,
            ic_february: 5.749,
            ic_march: 5.071,
            ic_april: 4.592,
            ic_may: 3.762,
            ic_june: 3.528,
            ic_july: 3.684,
            ic_august: 4.615,
            ic_september: 4.804,
            ic_october: 5.447,
            ic_november: 5.709,
            ic_december: 6.091,
            ic_yearly: 4.881,
          },
          {
            ic_uuid: "72ebbcf1-97c6-436c-a34d-754f8d89c43d",
            ic_lat: -208738,
            ic_lon: -482934,
            ic_city: "Viradouro",
            ic_states: "SÃO PAULO",
            ic_january: 5.827,
            ic_february: 6.078,
            ic_march: 5.229,
            ic_april: 4.841,
            ic_may: 4.188,
            ic_june: 3.945,
            ic_july: 4.177,
            ic_august: 5.023,
            ic_september: 5.024,
            ic_october: 5.581,
            ic_november: 5.858,
            ic_december: 6.183,
            ic_yearly: 5.163,
          },
          {
            ic_uuid: "5b51f7cc-fbee-4997-bd02-f3731bba25f0",
            ic_lat: -168075,
            ic_lon: -423434,
            ic_city: "Virgem da Lapa",
            ic_states: "MINAS GERAIS",
            ic_january: 6.101,
            ic_february: 6.235,
            ic_march: 5.336,
            ic_april: 4.762,
            ic_may: 4.056,
            ic_june: 3.877,
            ic_july: 4.098,
            ic_august: 4.838,
            ic_september: 5.412,
            ic_october: 5.596,
            ic_november: 5.187,
            ic_december: 5.925,
            ic_yearly: 5.119,
          },
          {
            ic_uuid: "af141b14-7c20-4dc1-be2a-c78fc9e2d43b",
            ic_lat: -223269,
            ic_lon: -450968,
            ic_city: "Virgínia",
            ic_states: "MINAS GERAIS",
            ic_january: 5.298,
            ic_february: 5.699,
            ic_march: 4.896,
            ic_april: 4.558,
            ic_may: 3.909,
            ic_june: 3.569,
            ic_july: 3.821,
            ic_august: 4.722,
            ic_september: 4.996,
            ic_october: 5.396,
            ic_november: 5.291,
            ic_december: 5.688,
            ic_yearly: 4.82,
          },
          {
            ic_uuid: "0dc69aff-252d-4c19-a14c-d41f2a94c7d8",
            ic_lat: -188159,
            ic_lon: -427019,
            ic_city: "Virginópolis",
            ic_states: "MINAS GERAIS",
            ic_january: 5.615,
            ic_february: 5.857,
            ic_march: 4.868,
            ic_april: 4.346,
            ic_may: 3.751,
            ic_june: 3.628,
            ic_july: 3.769,
            ic_august: 4.442,
            ic_september: 4.909,
            ic_october: 5.053,
            ic_november: 4.677,
            ic_december: 5.424,
            ic_yearly: 4.695,
          },
          {
            ic_uuid: "e2207380-dfb3-4511-9b09-a43d3ec7d5de",
            ic_lat: -184749,
            ic_lon: -42308,
            ic_city: "Virgolândia",
            ic_states: "MINAS GERAIS",
            ic_january: 6.043,
            ic_february: 6.163,
            ic_march: 5.167,
            ic_april: 4.463,
            ic_may: 3.836,
            ic_june: 3.648,
            ic_july: 3.711,
            ic_august: 4.465,
            ic_september: 4.997,
            ic_october: 5.216,
            ic_november: 4.817,
            ic_december: 5.757,
            ic_yearly: 4.857,
          },
          {
            ic_uuid: "9844c66d-fde7-4705-9f31-93adbeb73286",
            ic_lat: -253834,
            ic_lon: -521992,
            ic_city: "Virmond",
            ic_states: "PARANÁ",
            ic_january: 6.026,
            ic_february: 5.542,
            ic_march: 5.136,
            ic_april: 4.274,
            ic_may: 3.317,
            ic_june: 2.924,
            ic_july: 3.176,
            ic_august: 4.151,
            ic_september: 4.348,
            ic_october: 5.155,
            ic_november: 6.018,
            ic_december: 6.202,
            ic_yearly: 4.689,
          },
          {
            ic_uuid: "13467d0a-dbb2-478d-935e-a931dd73f8d9",
            ic_lat: -210132,
            ic_lon: -428365,
            ic_city: "Visconde do Rio Branco",
            ic_states: "MINAS GERAIS",
            ic_january: 5.792,
            ic_february: 6.03,
            ic_march: 5.034,
            ic_april: 4.4,
            ic_may: 3.756,
            ic_june: 3.587,
            ic_july: 3.776,
            ic_august: 4.581,
            ic_september: 4.871,
            ic_october: 5.138,
            ic_november: 4.944,
            ic_december: 5.629,
            ic_yearly: 4.795,
          },
          {
            ic_uuid: "0e42b488-df79-4850-aac5-4d7c7a4202a5",
            ic_lat: -12024,
            ic_lon: -461389,
            ic_city: "Viseu",
            ic_states: "PARÁ",
            ic_january: 4.778,
            ic_february: 4.495,
            ic_march: 4.323,
            ic_april: 4.271,
            ic_may: 4.532,
            ic_june: 4.781,
            ic_july: 4.92,
            ic_august: 5.296,
            ic_september: 5.679,
            ic_october: 5.525,
            ic_november: 5.439,
            ic_december: 5.113,
            ic_yearly: 4.929,
          },
          {
            ic_uuid: "9df4e49b-b43d-42e5-9868-a7ed791b886b",
            ic_lat: -273691,
            ic_lon: -534923,
            ic_city: "Vista Alegre",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.43,
            ic_february: 5.845,
            ic_march: 5.14,
            ic_april: 4.073,
            ic_may: 3.116,
            ic_june: 2.581,
            ic_july: 2.906,
            ic_august: 3.732,
            ic_september: 3.987,
            ic_october: 5.185,
            ic_november: 6.203,
            ic_december: 6.597,
            ic_yearly: 4.65,
          },
          {
            ic_uuid: "5ad1a7c6-3a91-4036-aa8f-52ce8617f3bf",
            ic_lat: -21171,
            ic_lon: -48629,
            ic_city: "Vista Alegre do Alto",
            ic_states: "SÃO PAULO",
            ic_january: 5.789,
            ic_february: 5.962,
            ic_march: 5.209,
            ic_april: 4.806,
            ic_may: 4.121,
            ic_june: 3.884,
            ic_july: 4.099,
            ic_august: 4.961,
            ic_september: 5.082,
            ic_october: 5.615,
            ic_november: 5.903,
            ic_december: 6.139,
            ic_yearly: 5.131,
          },
          {
            ic_uuid: "6e3905c8-87ec-455a-85c8-286d76b1a228",
            ic_lat: -288057,
            ic_lon: -517951,
            ic_city: "Vista Alegre do Prata",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.155,
            ic_february: 5.771,
            ic_march: 4.827,
            ic_april: 3.878,
            ic_may: 2.894,
            ic_june: 2.426,
            ic_july: 2.737,
            ic_august: 3.464,
            ic_september: 3.729,
            ic_october: 4.79,
            ic_november: 6.127,
            ic_december: 6.499,
            ic_yearly: 4.441,
          },
          {
            ic_uuid: "2d4247e5-1f09-4826-a83d-78a6a631b0a3",
            ic_lat: -272907,
            ic_lon: -536978,
            ic_city: "Vista Gaúcha",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.475,
            ic_february: 5.859,
            ic_march: 5.165,
            ic_april: 4.045,
            ic_may: 3.092,
            ic_june: 2.558,
            ic_july: 2.898,
            ic_august: 3.716,
            ic_september: 4.0,
            ic_october: 5.195,
            ic_november: 6.193,
            ic_december: 6.611,
            ic_yearly: 4.651,
          },
          {
            ic_uuid: "fcedb42d-41e0-4ad8-9c6b-c51673c5b084",
            ic_lat: -67385,
            ic_lon: -375687,
            ic_city: "Vista Serrana",
            ic_states: "PARAÍBA",
            ic_january: 6.075,
            ic_february: 6.219,
            ic_march: 6.284,
            ic_april: 5.881,
            ic_may: 5.306,
            ic_june: 4.834,
            ic_july: 5.145,
            ic_august: 5.903,
            ic_september: 6.441,
            ic_october: 6.602,
            ic_november: 6.661,
            ic_december: 6.316,
            ic_yearly: 5.972,
          },
          {
            ic_uuid: "dec02e35-cf83-4ea0-8c90-a49517b5c5ca",
            ic_lat: -268786,
            ic_lon: -498332,
            ic_city: "Vitor Meireles",
            ic_states: "SANTA CATARINA",
            ic_january: 5.345,
            ic_february: 5.109,
            ic_march: 4.575,
            ic_april: 3.582,
            ic_may: 2.835,
            ic_june: 2.374,
            ic_july: 2.571,
            ic_august: 3.41,
            ic_september: 3.547,
            ic_october: 4.152,
            ic_november: 5.303,
            ic_december: 5.523,
            ic_yearly: 4.027,
          },
          {
            ic_uuid: "0d836d33-e973-46bf-b191-2e40a39bbbc4",
            ic_lat: -203164,
            ic_lon: -403135,
            ic_city: "Vitória",
            ic_states: "ESPÍRITO SANTO",
            ic_january: 6.199,
            ic_february: 6.543,
            ic_march: 5.401,
            ic_april: 4.606,
            ic_may: 3.931,
            ic_june: 3.626,
            ic_july: 3.723,
            ic_august: 4.445,
            ic_september: 4.902,
            ic_october: 5.03,
            ic_november: 5.117,
            ic_december: 5.959,
            ic_yearly: 4.957,
          },
          {
            ic_uuid: "1b102f12-c1dc-4aaa-b71f-4eeec78ec52d",
            ic_lat: -20197,
            ic_lon: -504839,
            ic_city: "Vitória Brasil",
            ic_states: "SÃO PAULO",
            ic_january: 5.798,
            ic_february: 5.889,
            ic_march: 5.323,
            ic_april: 4.902,
            ic_may: 4.225,
            ic_june: 3.942,
            ic_july: 4.182,
            ic_august: 5.115,
            ic_september: 5.1,
            ic_october: 5.642,
            ic_november: 5.903,
            ic_december: 6.204,
            ic_yearly: 5.186,
          },
          {
            ic_uuid: "f9428976-0651-4758-b0c8-195e9ee64f50",
            ic_lat: -148619,
            ic_lon: -408445,
            ic_city: "Vitória da Conquista",
            ic_states: "BAHIA",
            ic_january: 6.067,
            ic_february: 6.0,
            ic_march: 5.417,
            ic_april: 4.75,
            ic_may: 4.097,
            ic_june: 3.708,
            ic_july: 3.914,
            ic_august: 4.504,
            ic_september: 5.451,
            ic_october: 5.607,
            ic_november: 5.387,
            ic_december: 6.071,
            ic_yearly: 5.081,
          },
          {
            ic_uuid: "eeb055bc-cb74-403c-b422-6bc71f3da1cb",
            ic_lat: -28351,
            ic_lon: -544959,
            ic_city: "Vitória das Missões",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.554,
            ic_february: 6.113,
            ic_march: 5.192,
            ic_april: 4.051,
            ic_may: 3.064,
            ic_june: 2.508,
            ic_july: 2.818,
            ic_august: 3.596,
            ic_september: 4.02,
            ic_october: 5.135,
            ic_november: 6.358,
            ic_december: 6.759,
            ic_yearly: 4.681,
          },
          {
            ic_uuid: "40f4acd1-a53b-417c-9136-6992a8b1dfc1",
            ic_lat: -81286,
            ic_lon: -352979,
            ic_city: "Vitória de Santo Antão",
            ic_states: "PERNAMBUCO",
            ic_january: 5.727,
            ic_february: 5.77,
            ic_march: 5.775,
            ic_april: 5.058,
            ic_may: 4.416,
            ic_june: 4.096,
            ic_july: 4.194,
            ic_august: 4.881,
            ic_september: 5.53,
            ic_october: 5.731,
            ic_november: 5.938,
            ic_december: 6.012,
            ic_yearly: 5.261,
          },
          {
            ic_uuid: "72562b69-d871-424c-b63f-e0093c9e4020",
            ic_lat: -9296,
            ic_lon: -524255,
            ic_city: "Vitória do Jari",
            ic_states: "AMAPÁ",
            ic_january: 4.444,
            ic_february: 4.309,
            ic_march: 4.333,
            ic_april: 4.338,
            ic_may: 4.544,
            ic_june: 4.671,
            ic_july: 4.745,
            ic_august: 5.009,
            ic_september: 5.098,
            ic_october: 5.076,
            ic_november: 4.93,
            ic_december: 4.5,
            ic_yearly: 4.667,
          },
          {
            ic_uuid: "cd9543eb-3ed8-40da-a9c0-ab516e252400",
            ic_lat: -34516,
            ic_lon: -448647,
            ic_city: "Vitória do Mearim",
            ic_states: "MARANHÃO",
            ic_january: 4.775,
            ic_february: 4.949,
            ic_march: 4.946,
            ic_april: 4.867,
            ic_may: 4.861,
            ic_june: 5.04,
            ic_july: 5.21,
            ic_august: 5.738,
            ic_september: 6.016,
            ic_october: 5.687,
            ic_november: 5.347,
            ic_december: 5.111,
            ic_yearly: 5.212,
          },
          {
            ic_uuid: "c32f476e-b3e2-4b4c-99d5-750854c35fe5",
            ic_lat: -28868,
            ic_lon: -520146,
            ic_city: "Vitória do Xingu",
            ic_states: "PARÁ",
            ic_january: 4.344,
            ic_february: 4.421,
            ic_march: 4.407,
            ic_april: 4.516,
            ic_may: 4.459,
            ic_june: 4.631,
            ic_july: 4.68,
            ic_august: 4.902,
            ic_september: 4.926,
            ic_october: 4.89,
            ic_november: 4.743,
            ic_december: 4.38,
            ic_yearly: 4.608,
          },
          {
            ic_uuid: "a0f39ffb-dd07-479d-bdc8-d9fb64789c02",
            ic_lat: -262688,
            ic_lon: -527847,
            ic_city: "Vitorino",
            ic_states: "PARANÁ",
            ic_january: 6.23,
            ic_february: 5.65,
            ic_march: 5.123,
            ic_april: 4.185,
            ic_may: 3.253,
            ic_june: 2.821,
            ic_july: 3.089,
            ic_august: 4.046,
            ic_september: 4.219,
            ic_october: 5.154,
            ic_november: 6.105,
            ic_december: 6.327,
            ic_yearly: 4.683,
          },
          {
            ic_uuid: "45db8313-d2a7-48e1-9057-cff541077abf",
            ic_lat: -42891,
            ic_lon: -452461,
            ic_city: "Vitorino Freire",
            ic_states: "MARANHÃO",
            ic_january: 4.625,
            ic_february: 4.798,
            ic_march: 4.908,
            ic_april: 4.849,
            ic_may: 4.776,
            ic_june: 4.915,
            ic_july: 5.147,
            ic_august: 5.545,
            ic_september: 5.823,
            ic_october: 5.4,
            ic_november: 5.05,
            ic_december: 4.851,
            ic_yearly: 5.057,
          },
          {
            ic_uuid: "621f7496-8ee4-4851-b2c9-3fc2f4163c59",
            ic_lat: -217676,
            ic_lon: -425379,
            ic_city: "Volta Grande",
            ic_states: "MINAS GERAIS",
            ic_january: 5.874,
            ic_february: 6.081,
            ic_march: 5.105,
            ic_april: 4.435,
            ic_may: 3.672,
            ic_june: 3.409,
            ic_july: 3.557,
            ic_august: 4.289,
            ic_september: 4.663,
            ic_october: 4.959,
            ic_november: 4.937,
            ic_december: 5.743,
            ic_yearly: 4.727,
          },
          {
            ic_uuid: "2ddc4462-40e0-46c6-996e-31f7923ca106",
            ic_lat: -225207,
            ic_lon: -440999,
            ic_city: "Volta Redonda",
            ic_states: "RIO DE JANEIRO",
            ic_january: 5.631,
            ic_february: 5.952,
            ic_march: 4.903,
            ic_april: 4.345,
            ic_may: 3.596,
            ic_june: 3.388,
            ic_july: 3.429,
            ic_august: 4.35,
            ic_september: 4.563,
            ic_october: 4.964,
            ic_november: 5.005,
            ic_december: 5.7,
            ic_yearly: 4.652,
          },
          {
            ic_uuid: "d13c7b17-cb96-40d6-b440-badb659de9a6",
            ic_lat: -235451,
            ic_lon: -474392,
            ic_city: "Votorantim",
            ic_states: "SÃO PAULO",
            ic_january: 5.532,
            ic_february: 5.731,
            ic_march: 4.969,
            ic_april: 4.429,
            ic_may: 3.616,
            ic_june: 3.362,
            ic_july: 3.463,
            ic_august: 4.442,
            ic_september: 4.628,
            ic_october: 5.213,
            ic_november: 5.61,
            ic_december: 6.108,
            ic_yearly: 4.758,
          },
          {
            ic_uuid: "0d12e642-5f3b-4ea4-ac5d-63d4635b2c98",
            ic_lat: -204241,
            ic_lon: -499785,
            ic_city: "Votuporanga",
            ic_states: "SÃO PAULO",
            ic_january: 5.872,
            ic_february: 5.911,
            ic_march: 5.29,
            ic_april: 4.916,
            ic_may: 4.191,
            ic_june: 3.937,
            ic_july: 4.195,
            ic_august: 5.047,
            ic_september: 5.097,
            ic_october: 5.607,
            ic_november: 5.958,
            ic_december: 6.168,
            ic_yearly: 5.182,
          },
          {
            ic_uuid: "a9303e70-62a8-4066-9473-31d4b06bef41",
            ic_lat: -122791,
            ic_lon: -411668,
            ic_city: "Wagner",
            ic_states: "BAHIA",
            ic_january: 5.751,
            ic_february: 5.652,
            ic_march: 5.449,
            ic_april: 4.571,
            ic_may: 3.968,
            ic_june: 3.672,
            ic_july: 3.794,
            ic_august: 4.488,
            ic_september: 5.343,
            ic_october: 5.366,
            ic_november: 5.326,
            ic_december: 5.653,
            ic_yearly: 4.919,
          },
          {
            ic_uuid: "b21bb19d-a30e-473d-b509-6b31f09b2a95",
            ic_lat: -72319,
            ic_lon: -419054,
            ic_city: "Wall Ferraz",
            ic_states: "PIAUÍ",
            ic_january: 5.615,
            ic_february: 5.474,
            ic_march: 5.545,
            ic_april: 5.346,
            ic_may: 5.325,
            ic_june: 5.287,
            ic_july: 5.618,
            ic_august: 6.195,
            ic_september: 6.695,
            ic_october: 6.515,
            ic_november: 6.357,
            ic_december: 5.922,
            ic_yearly: 5.824,
          },
          {
            ic_uuid: "7c8779b4-43d9-43af-8b99-acdfb4466be3",
            ic_lat: -68532,
            ic_lon: -479605,
            ic_city: "Wanderlândia",
            ic_states: "TOCANTINS",
            ic_january: 4.603,
            ic_february: 4.706,
            ic_march: 4.634,
            ic_april: 4.693,
            ic_may: 4.805,
            ic_june: 5.162,
            ic_july: 5.377,
            ic_august: 5.914,
            ic_september: 5.793,
            ic_october: 5.039,
            ic_november: 4.62,
            ic_december: 4.528,
            ic_yearly: 4.99,
          },
          {
            ic_uuid: "63faf7c2-79d0-4565-8ee8-a016c509fa0f",
            ic_lat: -121121,
            ic_lon: -438987,
            ic_city: "Wanderley",
            ic_states: "BAHIA",
            ic_january: 6.522,
            ic_february: 6.313,
            ic_march: 5.935,
            ic_april: 5.667,
            ic_may: 5.317,
            ic_june: 5.043,
            ic_july: 5.299,
            ic_august: 5.891,
            ic_september: 6.2,
            ic_october: 6.282,
            ic_november: 5.989,
            ic_december: 6.399,
            ic_yearly: 5.905,
          },
          {
            ic_uuid: "ad626f9c-ead2-4a3d-8916-783158781ca8",
            ic_lat: -225373,
            ic_lon: -45363,
            ic_city: "Wenceslau Braz",
            ic_states: "MINAS GERAIS",
            ic_january: 5.347,
            ic_february: 5.633,
            ic_march: 4.886,
            ic_april: 4.51,
            ic_may: 3.82,
            ic_june: 3.593,
            ic_july: 3.797,
            ic_august: 4.697,
            ic_september: 4.951,
            ic_october: 5.399,
            ic_november: 5.336,
            ic_december: 5.694,
            ic_yearly: 4.805,
          },
          {
            ic_uuid: "3b6017d9-de68-4127-a154-d47d3fc2b701",
            ic_lat: -238747,
            ic_lon: -498036,
            ic_city: "Wenceslau Braz",
            ic_states: "PARANÁ",
            ic_january: 5.702,
            ic_february: 5.726,
            ic_march: 5.099,
            ic_april: 4.475,
            ic_may: 3.548,
            ic_june: 3.191,
            ic_july: 3.433,
            ic_august: 4.406,
            ic_september: 4.618,
            ic_october: 5.205,
            ic_november: 5.973,
            ic_december: 6.217,
            ic_yearly: 4.799,
          },
          {
            ic_uuid: "ab904801-d9df-4a42-8ba4-6ca1a7d03329",
            ic_lat: -136869,
            ic_lon: -394785,
            ic_city: "Wenceslau Guimarães",
            ic_states: "BAHIA",
            ic_january: 5.898,
            ic_february: 5.846,
            ic_march: 5.53,
            ic_april: 4.462,
            ic_may: 3.829,
            ic_june: 3.436,
            ic_july: 3.705,
            ic_august: 4.184,
            ic_september: 4.887,
            ic_october: 5.089,
            ic_november: 5.337,
            ic_december: 5.924,
            ic_yearly: 4.844,
          },
          {
            ic_uuid: "9d073dfe-3918-4fb7-9e16-c6885fa74643",
            ic_lat: -294268,
            ic_lon: -517649,
            ic_city: "Westfália",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.212,
            ic_february: 5.714,
            ic_march: 4.808,
            ic_april: 3.775,
            ic_may: 2.807,
            ic_june: 2.334,
            ic_july: 2.589,
            ic_august: 3.298,
            ic_september: 3.567,
            ic_october: 4.643,
            ic_november: 6.103,
            ic_december: 6.45,
            ic_yearly: 4.358,
          },
          {
            ic_uuid: "7e13a32d-3d6a-4976-bf57-b7312bc0b7c6",
            ic_lat: -26928,
            ic_lon: -497951,
            ic_city: "Witmarsum",
            ic_states: "SANTA CATARINA",
            ic_january: 5.407,
            ic_february: 5.173,
            ic_march: 4.555,
            ic_april: 3.608,
            ic_may: 2.847,
            ic_june: 2.358,
            ic_july: 2.556,
            ic_august: 3.424,
            ic_september: 3.532,
            ic_october: 4.174,
            ic_november: 5.318,
            ic_december: 5.593,
            ic_yearly: 4.045,
          },
          {
            ic_uuid: "3f97e09b-3e10-42fc-aa9f-d7a9fbf0ed7a",
            ic_lat: -64145,
            ic_lon: -485324,
            ic_city: "Xambioá",
            ic_states: "TOCANTINS",
            ic_january: 4.671,
            ic_february: 4.841,
            ic_march: 4.72,
            ic_april: 4.779,
            ic_may: 4.882,
            ic_june: 5.16,
            ic_july: 5.244,
            ic_august: 5.737,
            ic_september: 5.619,
            ic_october: 5.057,
            ic_november: 4.722,
            ic_december: 4.587,
            ic_yearly: 5.002,
          },
          {
            ic_uuid: "5e65aa96-6777-40b1-a253-229e480b3d71",
            ic_lat: -237369,
            ic_lon: -534889,
            ic_city: "Xambrê",
            ic_states: "PARANÁ",
            ic_january: 6.113,
            ic_february: 5.857,
            ic_march: 5.33,
            ic_april: 4.486,
            ic_may: 3.529,
            ic_june: 3.152,
            ic_july: 3.374,
            ic_august: 4.331,
            ic_september: 4.576,
            ic_october: 5.393,
            ic_november: 6.138,
            ic_december: 6.522,
            ic_yearly: 4.9,
          },
          {
            ic_uuid: "96e48352-0cae-465e-9566-8417e64ca301",
            ic_lat: -29801,
            ic_lon: -500438,
            ic_city: "Xangri-lá",
            ic_states: "RIO GRANDE DO SUL",
            ic_january: 6.257,
            ic_february: 5.594,
            ic_march: 4.731,
            ic_april: 3.886,
            ic_may: 2.909,
            ic_june: 2.417,
            ic_july: 2.616,
            ic_august: 3.233,
            ic_september: 3.573,
            ic_october: 4.608,
            ic_november: 6.019,
            ic_december: 6.425,
            ic_yearly: 4.356,
          },
          {
            ic_uuid: "7d19b20a-935b-4aa3-a548-4ff91dc52740",
            ic_lat: -268752,
            ic_lon: -52404,
            ic_city: "Xanxerê",
            ic_states: "SANTA CATARINA",
            ic_january: 6.222,
            ic_february: 5.66,
            ic_march: 5.083,
            ic_april: 4.108,
            ic_may: 3.161,
            ic_june: 2.703,
            ic_july: 2.989,
            ic_august: 3.858,
            ic_september: 4.07,
            ic_october: 5.095,
            ic_november: 6.104,
            ic_december: 6.376,
            ic_yearly: 4.619,
          },
          {
            ic_uuid: "cb1caf52-e821-43e6-bd0d-8733e89674bf",
            ic_lat: -10652,
            ic_lon: -684973,
            ic_city: "Xapuri",
            ic_states: "ACRE",
            ic_january: 4.629,
            ic_february: 4.586,
            ic_march: 4.281,
            ic_april: 4.578,
            ic_may: 3.962,
            ic_june: 4.125,
            ic_july: 4.332,
            ic_august: 4.894,
            ic_september: 5.068,
            ic_october: 5.071,
            ic_november: 5.077,
            ic_december: 4.686,
            ic_yearly: 4.607,
          },
          {
            ic_uuid: "557ea294-d041-43bc-a308-3d053b469b05",
            ic_lat: -270672,
            ic_lon: -523434,
            ic_city: "Xavantina",
            ic_states: "SANTA CATARINA",
            ic_january: 6.091,
            ic_february: 5.634,
            ic_march: 5.084,
            ic_april: 4.07,
            ic_may: 3.132,
            ic_june: 2.651,
            ic_july: 2.948,
            ic_august: 3.8,
            ic_september: 4.04,
            ic_october: 5.085,
            ic_november: 6.138,
            ic_december: 6.404,
            ic_yearly: 4.59,
          },
          {
            ic_uuid: "5fd80a18-6b98-4c6a-80d3-b9facdd46f03",
            ic_lat: -269601,
            ic_lon: -525379,
            ic_city: "Xaxim",
            ic_states: "SANTA CATARINA",
            ic_january: 6.208,
            ic_february: 5.723,
            ic_march: 5.123,
            ic_april: 4.094,
            ic_may: 3.138,
            ic_june: 2.676,
            ic_july: 2.95,
            ic_august: 3.809,
            ic_september: 4.049,
            ic_october: 5.142,
            ic_november: 6.177,
            ic_december: 6.463,
            ic_yearly: 4.629,
          },
          {
            ic_uuid: "3aea4ab8-f809-400e-885e-e5db01ab08db",
            ic_lat: -8805,
            ic_lon: -356215,
            ic_city: "Xexéu",
            ic_states: "PERNAMBUCO",
            ic_january: 5.634,
            ic_february: 5.591,
            ic_march: 5.647,
            ic_april: 5.002,
            ic_may: 4.223,
            ic_june: 3.88,
            ic_july: 3.917,
            ic_august: 4.547,
            ic_september: 5.288,
            ic_october: 5.496,
            ic_november: 5.926,
            ic_december: 5.894,
            ic_yearly: 5.087,
          },
          {
            ic_uuid: "e3f38471-4313-4e23-a38c-6571a695f664",
            ic_lat: -70987,
            ic_lon: -499441,
            ic_city: "Xinguara",
            ic_states: "PARÁ",
            ic_january: 4.723,
            ic_february: 4.79,
            ic_march: 4.666,
            ic_april: 4.667,
            ic_may: 4.85,
            ic_june: 5.044,
            ic_july: 5.039,
            ic_august: 5.641,
            ic_september: 5.385,
            ic_october: 4.93,
            ic_november: 4.745,
            ic_december: 4.645,
            ic_yearly: 4.927,
          },
          {
            ic_uuid: "1cfc1701-dc3c-422f-8a24-e651cff666a5",
            ic_lat: -108234,
            ic_lon: -427249,
            ic_city: "Xique-Xique",
            ic_states: "BAHIA",
            ic_january: 6.439,
            ic_february: 6.281,
            ic_march: 6.23,
            ic_april: 5.638,
            ic_may: 5.293,
            ic_june: 5.119,
            ic_july: 5.351,
            ic_august: 6.059,
            ic_september: 6.479,
            ic_october: 6.533,
            ic_november: 6.385,
            ic_december: 6.441,
            ic_yearly: 6.021,
          },
          {
            ic_uuid: "9a0eb40b-3cb8-4f20-873b-105d378abaf7",
            ic_lat: -8076,
            ic_lon: -370993,
            ic_city: "Zabelê",
            ic_states: "PARAÍBA",
            ic_january: 6.187,
            ic_february: 6.042,
            ic_march: 5.992,
            ic_april: 5.513,
            ic_may: 4.737,
            ic_june: 4.314,
            ic_july: 4.463,
            ic_august: 5.219,
            ic_september: 6.166,
            ic_october: 6.425,
            ic_november: 6.725,
            ic_december: 6.447,
            ic_yearly: 5.686,
          },
          {
            ic_uuid: "4d11190d-f438-4dc8-813b-de36dd91a3e7",
            ic_lat: -21052,
            ic_lon: -500509,
            ic_city: "Zacarias",
            ic_states: "SÃO PAULO",
            ic_january: 5.895,
            ic_february: 5.936,
            ic_march: 5.348,
            ic_april: 4.882,
            ic_may: 4.046,
            ic_june: 3.723,
            ic_july: 3.992,
            ic_august: 4.862,
            ic_september: 4.955,
            ic_october: 5.601,
            ic_november: 6.03,
            ic_december: 6.275,
            ic_yearly: 5.129,
          },
          {
            ic_uuid: "ca8be31e-4da3-4b2e-9019-a80d6f9d2465",
            ic_lat: -32705,
            ic_lon: -456557,
            ic_city: "Zé Doca",
            ic_states: "MARANHÃO",
            ic_january: 4.617,
            ic_february: 4.807,
            ic_march: 4.825,
            ic_april: 4.842,
            ic_may: 4.773,
            ic_june: 4.911,
            ic_july: 5.015,
            ic_august: 5.396,
            ic_september: 5.7,
            ic_october: 5.386,
            ic_november: 5.052,
            ic_december: 4.828,
            ic_yearly: 5.013,
          },
          {
            ic_uuid: "70fad861-57d5-475b-85f6-6449ebaefc66",
            ic_lat: -274525,
            ic_lon: -515524,
            ic_city: "Zortéa",
            ic_states: "SANTA CATARINA",
            ic_january: 6.192,
            ic_february: 5.693,
            ic_march: 5.018,
            ic_april: 4.061,
            ic_may: 3.067,
            ic_june: 2.64,
            ic_july: 2.9,
            ic_august: 3.722,
            ic_september: 3.964,
            ic_october: 5.017,
            ic_november: 6.196,
            ic_december: 6.472,
            ic_yearly: 4.579,
          },
        ]
  export default citiesData;