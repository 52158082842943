// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 700px){
    #mobile{
        width: 100vw;
        height: 100vh;
        display: flex;
        background-color: red;
        justify-content: center;
        align-items: center;
    }
    #mobileNavigation{
        flex-direction: row;
    }
    #outlet{
        width: 100vw;
        height: 100vh;
        padding-left: 20px;
    }
}

@media screen and (max-width: 390px) {
    
    #mobileNavigation{
        flex-direction: column;
        gap: 8px
    }
}`, "",{"version":3,"sources":["webpack://./src/components/mobile-navigation/mobileNavigation.css"],"names":[],"mappings":"AAAA;IACI;QACI,YAAY;QACZ,aAAa;QACb,aAAa;QACb,qBAAqB;QACrB,uBAAuB;QACvB,mBAAmB;IACvB;IACA;QACI,mBAAmB;IACvB;IACA;QACI,YAAY;QACZ,aAAa;QACb,kBAAkB;IACtB;AACJ;;AAEA;;IAEI;QACI,sBAAsB;QACtB;IACJ;AACJ","sourcesContent":["@media screen and (max-width: 700px){\n    #mobile{\n        width: 100vw;\n        height: 100vh;\n        display: flex;\n        background-color: red;\n        justify-content: center;\n        align-items: center;\n    }\n    #mobileNavigation{\n        flex-direction: row;\n    }\n    #outlet{\n        width: 100vw;\n        height: 100vh;\n        padding-left: 20px;\n    }\n}\n\n@media screen and (max-width: 390px) {\n    \n    #mobileNavigation{\n        flex-direction: column;\n        gap: 8px\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
